import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'runden'
})
export class RundenPipe implements PipeTransform {

    transform(value: number, ...args: unknown[]): unknown {
        if(value < 2000) {
            value = Number(value + '0');
        }
        return Number((value / 1000).toFixed(2));
    }
}
