import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {map} from 'rxjs/operators';
import * as firebase from 'firebase';

@Injectable({
    providedIn: 'root'
})
export class FirebaseService {

    constructor(private fire: AngularFirestore) {
    }

    setDataInDB(collection, doc, obj) {
        return this.fire
        .collection(collection)
        .doc(doc.toString())
        .set(obj);
    }

    addDataInDB(collection, obj) {
        return this.fire
        .collection(collection)
        .add(obj);
    }

    addSubDataInDBAutoKey(collection, doc, subcollection, obj) {
        return this.fire
        .collection(collection)
        .doc(doc.toString())
        .collection(subcollection)
        .add(obj);
    }

    addSubSubDataInDBAutoKey(col1, doc1, col2, doc2, sub2, obj) {
        return this.fire
        .collection(col1)
        .doc(doc1)
        .collection(col2)
        .doc(doc2)
        .collection(sub2)
        .add(obj);
    }

    deleteGeneralItem(collection, doc) {
        return this.fire
        .collection(collection)
        .doc(doc)
        .delete();
    }

    deleteItemFromDatabase(collection, doc, subcollection, subdoc) {
        return this.fire
        .collection(collection)
        .doc(doc)
        .collection(subcollection)
        .doc(subdoc)
        .delete();
    }

    getBeforeDocumentId(collection): string {
        const db = firebase.default.firestore();
        const doc = db.collection(collection).doc();
        return doc.id;
    }

    getDataFromDatabaseSortedByDate(collection: string) {
        return this.fire
        .collection(collection, ref => {
            return ref.orderBy('date', 'desc');
        }).valueChanges();
        // return ref.orderBy('date','desc').limit(3)
    }

    getDataWithDocId(collection: string) {
        return this.fire
        .collection(collection)
        .snapshotChanges().pipe(map(actions => {
            return actions.map(a => {
                const data = a.payload.doc.data();
                const id = a.payload.doc.id;
                return {id, data};
            });
        }));
    }

    getDataFromDatabaseWithDocIdSortedByParam(collection: string, searchTyp: string, sortTyp) {
        return this.fire
        .collection(collection, (ref) => {
            return ref.orderBy(searchTyp, sortTyp);
        })
        .snapshotChanges().pipe(map(actions => {
            return actions.map(a => {
                const data = a.payload.doc.data();
                const id = a.payload.doc.id;
                return {id, data};
            });
        }));
    }

    getDataWithDocIdFilteredByProperty(collection: string, propertyKey: string, propertyValue: string) {
        return this.fire
        .collection(collection, (ref) => {
            return ref.where(propertyKey, '==', propertyValue).orderBy('date', 'desc');
        })
        .snapshotChanges().pipe(map(actions => {
            return actions.map(a => {
                const data = a.payload.doc.data();
                const id = a.payload.doc.id;
                return {id, data};
            });
        }));
    }

    getReferenceSubCollectionReference(collection: string, docId: string, subcollection: string) {
        return this.fire
        .collection(collection)
        .doc(docId)
        .collection(subcollection)
        .get();
    }

    getSpecificDocument(collection, doc) {
        return this.fire
        .collection(collection)
        .doc(doc)
        .ref.get();
    }

    getDataFromDatabase(col: string) {
        return this.fire
        .collection(col)
        .valueChanges();
    }

    getDataFromDatabaseWithQuery(key: string, value: string) {
        return this.fire
        .collection('feeds', ref => ref
        .where(key, '==', value))
        .snapshotChanges()
        .pipe(map(actions => {
            return actions.map(a => {
                const data = a.payload.doc.data();
                const id = a.payload.doc.id;
                return {id, data};
            });
        }));
    }

    getDocumentById(collection, doc) {
        return this.fire
        .collection(collection)
        .doc(doc)
        .valueChanges();
    }

    getSubCollection(collection, doc, subcollection) {
        return this.fire
        .collection(collection)
        .doc(doc)
        .collection(subcollection)
        .valueChanges();
    }

    getSubCollectionWithDocIdOrderByDate(collection, doc, subcollection) {
        return this.fire
        .collection(collection)
        .doc(doc)
        .collection(subcollection, ref => {
            return ref.orderBy('datum', 'desc');
        })
        .snapshotChanges()
        .pipe(map(actions => {
            return actions.map(a => {
                const data = a.payload.doc.data();
                const id = a.payload.doc.id;
                return {id, data};
            });
        }));
    }

    getSubCollectionOrderByDate(collection, doc, subcollection) {
        return this.fire
        .collection(collection)
        .doc(doc)
        .collection(subcollection, ref => {
            return ref.orderBy('datum', 'desc');
        })
        .valueChanges();
    }

    getSubCollectionWithDocId(collection, doc, subcollection) {
        return this.fire
        .collection(collection)
        .doc(doc)
        .collection(subcollection)
        .snapshotChanges()
        .pipe(map(actions => {
            return actions.map(a => {
                const data = a.payload.doc.data();
                const id = a.payload.doc.id;
                return {id, data};
            });
        }));
    }

    getCollectionWithQuery(col, param1, param2) {
        return this.fire
        .collection(col, ref => {
            return ref.where(param1.trim(), '==', param2.trim());
        }).valueChanges();
    }

    workAroundToPreventItalicFlagInDB(collection, falohoId: string) {
        return this.fire
        .collection(collection)
        .doc(falohoId)
        .set({workaroud: ''});
    }

    setServerTimestamp() {
        return firebase.default.firestore.FieldValue.serverTimestamp();
    }

    updateGeneralData(collection, doc, obj) {
        return this.fire
        .collection(collection)
        .doc(doc)
        .update(obj);
    }

    updatePropertyInDB(collection: string, doc: string, property: string, value: any) {
        return this.fire
        .collection(collection)
        .doc(doc)
        .update({
            [property]: value
        });
    }

    updateSubcollection(collection: string, doc: string,
                        subcollection: string, subDocId: string,
                        property: string, value: boolean | string | number) {
        return this.fire
        .collection(collection)
        .doc(doc)
        .collection(subcollection)
        .doc(subDocId)
        .update({
            [property]: value
        });
    }

    // FINDINGS - ES SIND NUR BEISPIELE UND DIENEN ZUR INSPIRATTIONS
    post = async (doc) => {
        const docRef = await this.fire.collection('xxx').add(doc);
        return docRef.id;
    };
}


// addWithFalohoId() {
// 	this.falohe.forEach((faloho, id) => {
// 		id++;
// 		let falohoId = id.toString();
// 		this._fire.collection('falohe')
// 			.doc(falohoId)
// 			.set(faloho);
//
// 		faloho['filialen'].forEach((filiale, filialeId) => {
// 			filialeId++;
// 			this._fire.collection('falohe')
// 				.doc(falohoId)
// 				.collection('filialen')
// 				.doc('filialeId_'+filialeId)
// 				.set(filiale);
// 		});
// 	});
// }

// addInDatabaseGeneratedAutoID() {
// 	this.falohe.forEach((userObjuserObj) => {
// 		this._fire
// 			.collection('falohe')
// 			.add(userObjuserObj);
// 	});
// }

// getLengthOfDocuments() {
// 	return this._fire.collection('feeds')
// 		.doc('1534581625684_2332')
// 		.collection('comments')
// 		.snapshotChanges()
// 		.subscribe((doc) => console.log(doc.length));
// }

// getAllCommentsFromDatabase() {
// 	return this._fire.collection('feeds')
// 		.snapshotChanges()
// 		.pipe(map(actions => {
// 			return actions.map(a => {
// 				const data = a.payload.doc.data();
// 				const id = a.payload.doc.id;
// 				return {id, data};
// 			})
// 		});
//
// }

// return this._fire.collection('feeds').doc().ref.firestore valueChanges();

// 	addComment(feedId) {
// 		return this._fire
// 			.collection('feeds').doc(feedId)
// 			.collection('comments').add({'text': 'commentText'});
// 	}
