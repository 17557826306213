import {Component, Input, OnInit} from '@angular/core';
import {FirebaseService} from "../../services/firebase.service";
import * as _ from 'lodash-es';

@Component({
  selector: 'app-audio-general',
  templateUrl: './audio-general.component.html',
  styleUrls: ['./audio-general.component.scss'],
})
export class AudioGeneralComponent {
    @Input() path: string;
    @Input() id: string;
    generalSounds = [];

    constructor(
        private fire: FirebaseService
    ) {
        const sub1$ = this.fire.getDocumentById('sounds', 'game').subscribe((res) => {
            this.generalSounds = res['basic'].concat(res['generalPos']).concat(res['generalNeg']);
            sub1$.unsubscribe();
            // this.fire.updatePropertyInDB('sounds', 'game', 'basic', _.sortBy(this.generalSounds))
        });
    }
}
