import {Component, Input } from '@angular/core';
import * as _ from 'lodash-es';

@Component({
    selector: 'app-spielstatus',
    templateUrl: './spielstatus.component.html',
    styleUrls: ['./spielstatus.component.scss'],
})
export class SpielstatusComponent {
    lodash = _;
    @Input() full;
    @Input() empty;
}
