import {Component, Input} from '@angular/core';
import {CommonService} from '../../services/common.service';

@Component({
    selector: 'app-audio',
    templateUrl: './audio.component.html',
    styleUrls: ['./audio.component.scss'],
})
export class AudioComponent {
    @Input() filename: string;
    @Input() index: number;
    @Input() audioId: string;

    constructor(
        public common: CommonService
    ) {
    }
}
