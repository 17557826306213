import {Injectable} from '@angular/core';
import {PopoverController} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class PopoverService {

  constructor(
      private popoverController: PopoverController
  ) {}

  async showPopover(ev: any, component) {
    const popover = await this.popoverController.create({
      component,
      event: ev,
      translucent: true
    });

    await popover.present();
    return  await popover.onDidDismiss();
  }
}
