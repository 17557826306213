import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-iconbig',
  templateUrl: './icon-big.component.html',
  styleUrls: ['./icon-big.component.scss'],
})
export class IconBigComponent {
  @Input() name: string;
  @Input() text: string;
}
