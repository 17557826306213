import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AngularFireAnalytics} from "@angular/fire/analytics";

@Component({
    selector: 'app-showpdf',
    templateUrl: './showpdf.page.html',
    styleUrls: ['./showpdf.page.scss'],
})
export class ShowpdfPage implements OnInit {
    url = '';

    constructor(
        private analytics: AngularFireAnalytics,
        private route: ActivatedRoute
    ) {
    }

    ngOnInit() {
        this.route.params.subscribe((param) => {
            this.url = param.url;
            this.googleAnalytics(param.url);
        });
    }

    googleAnalytics(paramUrl) {
        // Google Analytics
        this.analytics.logEvent('Tab Mehr: Bücher: ' + paramUrl);
    }

}
