import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
    name: 'sanitizer'
})
export class SanitizerPipe implements PipeTransform {

    constructor(
        protected sanitizer: DomSanitizer
    ) {
    }

    transform(htmlString: string): any {
        return this.sanitizer.bypassSecurityTrustHtml(htmlString);
        // return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}
