import {Component, OnInit} from '@angular/core';
import {MenuController, Platform} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {GLOBALS} from './data/global';
import {PushService} from './services/push.service';
import {VORNAMEN} from './data/vornamen';
import {WORDS} from './data/words';
import {Storage} from '@ionic/storage-angular';
import {FirebaseService} from "./services/firebase.service";

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit{
    GLOBALS = GLOBALS;

    constructor(
        private fire: FirebaseService,
        private menu: MenuController,
        private platform: Platform,
        private push: PushService,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private storage: Storage
    ) {
        this.initializeApp();
    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.push.initPushNotification();
            this.statusBar.styleDefault();
            this.splashScreen.hide();
            this.GLOBALS.SFARMELE = WORDS;
            this.GLOBALS.NAMEN = VORNAMEN;
            this.fire.getDataFromDatabase('konfigurationen').subscribe(res => {
                GLOBALS.konfigurationen = res ;
            });
        });
    }

    async ngOnInit() {
        // If using a custom driver:
        // await this.storage.defineDriver(MyCustomDriver)
        await this.storage.create();
    }

    closeMenu() {
        this.menu.close();
    }
}
