import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
        // loadChildren: () => import('./pages/backoffice/deleteword/deleteword.module').then(m => m.DeletewordPageModule)
    },
    {
        path: 'insert',
        loadChildren: () => import('./pages/backoffice/addword/addword.module').then(m => m.AddwordPageModule)
    },
    {
        path: 'update',
        loadChildren: () => import('./pages/backoffice/deleteword/deleteword.module').then(m => m.DeletewordPageModule)
    },
    {
        path: 'bibel',
        loadChildren: () => import('./tab5/bibel.module').then(m => m.BibelPageModule)
    },
    {
        path: 'paypal',
        loadChildren: () => import('./pages/paypal/paypal.module').then(m => m.PaypalPageModule)
    },
    {
        path: 'sponsoren',
        loadChildren: () => import('./pages/sponsoren/sponsoren.module').then(m => m.SponsorenPageModule)
    },
    {
        path: 'impressum',
        loadChildren: () => import('./pages/menu/impressum/impressum.module').then(m => m.ImpressumPageModule)
    },
    {
        path: 'datenschutz',
        loadChildren: () => import('./pages/menu/datenschutz/datenschutz.module').then(m => m.DatenschutzPageModule)
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
