import {Injectable} from '@angular/core';
import {OneSignal} from '@ionic-native/onesignal/ngx';
import { HttpHeaders} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class PushService {
    oneSignalId = 'dbfa514e-9573-42c7-a24e-42a4a3310613';
    restApiKey = 'MTdmNmJjNjctNDYyMi00NmNhLTllZjUtN2ZkNTkzMTRlM2Ux';
    firebaseSenderId = '661408601213';
    postUrl = 'https://onesignal.com/api/v1/notifications';
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'Basic ' + this.restApiKey
        })
    };

    constructor(
        private oneSignal: OneSignal
    ) {}

    initPushNotification() {
        this.oneSignal.startInit(this.oneSignalId, this.firebaseSenderId);
        this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.InAppAlert);
        this.oneSignal.getIds().then((deviceData) => {
            console.log(deviceData.userId); // z.B. 68e9912d-6b39-4e47-aa47-6ccc6f3b8cf0
        });

        // do something when notification is received
        // Notifcation was received in general
        this.oneSignal.handleNotificationReceived().subscribe((data) => {
            const title = data.payload.title;
            const msg = data.payload.body;
            const additionalData = JSON.stringify(data.payload.additionalData);
        });

        // Wenn die App nicht aktiv ist, dann wird eine echte Push Nachricht oben in der Handy Leiste angezeigt
        // Notification was really clicked/opened
        this.oneSignal. handleNotificationOpened().subscribe((data) => {
            // Just a note that the data is a different place here!
            const title = data.notification.payload.title;
            const msg = data.notification.payload.body;
            const additionalData = data.notification.payload.additionalData;
        });

        this.oneSignal.endInit();
    }
}
