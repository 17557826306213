import {Component, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-formbutton',
  templateUrl: './formbutton.component.html',
  styleUrls: ['./formbutton.component.scss'],
})
export class FormbuttonComponent{
  @Input() myForm: FormGroup;
  @Input() buttonText: string;
}
