import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-smiley',
    templateUrl: './smiley.component.html',
    styleUrls: ['./smiley.component.scss'],
})
export class SmileyComponent implements OnInit {
    @Input() show;
    @Input() imageName;
    constructor() {
    }

    ngOnInit() {
    }

}
