import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ModalController, NavController} from '@ionic/angular';
import {Storage} from '@ionic/storage';
import * as _ from 'lodash-es';
import {ToastService} from '../../services/toast.service';
import {GLOBALS} from '../../data/global';
import {AlertService} from '../../services/alert.service';
import {AngularFireAnalytics} from "@angular/fire/analytics";

@Component({
    selector: 'app-wortverlauf',
    templateUrl: './wortverlauf.component.html',
    styleUrls: ['./wortverlauf.component.scss'],
})
export class WortverlaufComponent implements OnInit, AfterViewInit{
    GLOBALS = GLOBALS;
    verlaufWords = [];
    storageHistoryWords = [];

    constructor(
        private analytics: AngularFireAnalytics,
        private alert: AlertService,
        private modalCtrl: ModalController,
        private navCtrl: NavController,
        private storage: Storage,
        private toast: ToastService
    ) {
    }

    ngOnInit() {
        this.GLOBALS.isWordSearchedPerSpeach = false;
        this.retrieveStorageHistoryWords();
    }

    ngAfterViewInit() {
        // Google Analytics
        this.analytics.logEvent('Wortverlauf');
    }

    retrieveStorageHistoryWords() {
        this.storage.get('VERLAUF').then((res) => {
            if (res && res.length) {
                this.storageHistoryWords = res;
                const tmp = [];
                res.forEach((obj) => {
                    tmp.push([obj.dt1, obj.sy5, obj.lt10, obj.id]);
                });
                this.verlaufWords = tmp;
            } else {
                this.verlaufWords = [];
            }
        });
    }

    removeWord(wordId: string) {
        const storageDataWithDeletedItem = _.reject(this.storageHistoryWords, ['id', wordId]);

        this.storage.set('VERLAUF', storageDataWithDeletedItem).then(() => {
            this.retrieveStorageHistoryWords();
            this.toast.toast('Erfolgreich gelöscht', 'neutral');
        });
    }

    alleloeschen() {
        const msg = 'Möchten Sie wirklich alle löschen?';
        this.alert.alertConfirm(msg).then((res: { data: object, role: string }) => {
            if (res && res.role === 'ja') {
                this.verlaufWords = [];
                this.toast.toast('Alle gelöscht', 'neutral');
                this.storage.set('VERLAUF', this.verlaufWords);
            }
        });
    }

    dismiss() {
        this.modalCtrl.dismiss({
            dismissed: true
        });
    }
}
