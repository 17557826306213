import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SanitizerPipe} from './sanitizer.pipe';
import { OrderbydatePipe } from './orderbydate.pipe';
import { RundenPipe } from './runden.pipe';
import { RankingtabellePipe } from './rankingtabelle.pipe';

const PIPE = [
    SanitizerPipe,
    OrderbydatePipe,
    RundenPipe,
    RankingtabellePipe
];

// @ts-ignore
@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        PIPE
    ],
    exports: [
        PIPE
    ]
})
export class PipesModule {}
