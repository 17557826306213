import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ShowpdfPageRoutingModule } from './showpdf-routing.module';

import { ShowpdfPage } from './showpdf.page';
import {NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';
import {HeadertabsComponent} from '../../components/headertabs/headertabs.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        ShowpdfPageRoutingModule,
        NgxExtendedPdfViewerModule
    ],
    exports: [
        HeadertabsComponent
    ],
    declarations: [ShowpdfPage, HeadertabsComponent]
})
export class ShowpdfPageModule {}
