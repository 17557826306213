import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage';
import {FirebaseService} from './firebase.service';
import {GLOBALS} from '../data/global';
import {ModalController, Platform} from '@ionic/angular/';
import {NavController} from '@ionic/angular';
import {INamen, ISfarmele} from "../data/intefaces";
import * as _ from 'lodash-es';

@Injectable({
    providedIn: 'root'
})
export class CommonService {
    GLOBALS = GLOBALS;
    scaleCssArray = [];

    constructor(
        private modalCtrl: ModalController,
        private fire: FirebaseService,
        private navCtrl: NavController,
        private platform: Platform,
        private storage: Storage
    ) {
    }

    audio(el) {
        // this.audioPlayer.nativeElement.play();
        const element = document.getElementById(el) as HTMLAudioElement;
        element.load();
        element.play();
    }

    dismiss() {
        this.modalCtrl.dismiss({
            dismissed: true
        });
    }

    isNativePlatform() {
        // return (this.platform.is('android') || this.platform.is('ios'));
        return this.platform.is('cordova');
    }

    isSfarmeleDataLocal() {
        return new Promise((resolve) => {
            if (this.GLOBALS.SFARMELE && this.GLOBALS.SFARMELE.length) {
                resolve([true, this.GLOBALS.SFARMELE]);
            } else {
                this.storage.get('SFARMELE').then((res: Array<ISfarmele>) => {
                    if (res && res.length) {
                        this.GLOBALS.SFARMELE = res;
                        resolve([true, this.GLOBALS.SFARMELE]);
                    } else {
                        resolve([false, []]);
                    }
                });
            }
        });
    }

    getSfarmeleData() {
        return new Promise((resolve) => {
            if (this.GLOBALS.SFARMELE && this.GLOBALS.SFARMELE.length) {
                resolve(this.GLOBALS.SFARMELE);
            } else {
                this.storage.get('SFARMELE').then((res: Array<ISfarmele>) => {
                    if (res && res.length) {
                        this.GLOBALS.SFARMELE = res;
                        resolve(res);
                    } else {
                        // this.fire.getDataFromDatabase('de').subscribe((data: Array<ISfarmele>) => {
                        //     if (data && data.length) {
                        //         this.GLOBALS.SFARMELE = data;
                        //         this.storage.set('SFARMELE', data);
                        //         resolve(data);
                        //     }
                        // });
                    }
                });
            }
        });
    }

    getNamen() {
        return new Promise((resolve) => {
            if (this.GLOBALS.NAMEN && this.GLOBALS.NAMEN.length) {
                resolve(this.GLOBALS.NAMEN);
            } else {
                this.storage.get('NAMEN').then((res: Array<ISfarmele>) => {
                    if (res && res.length) {
                        this.GLOBALS.NAMEN = res;
                        resolve(res);
                    } else {
                        this.fire.getDataFromDatabase('namen').subscribe((data: Array<INamen>) => {
                            if (data && data.length) {
                                this.GLOBALS.NAMEN = data;
                                this.storage.set('NAMEN', data);
                                resolve(data);
                            }
                        });
                    }
                });
            }
        });
    }

    getSfarmeleDataWithDocumentId() {
        return new Promise((resolve) => {
            if (this.GLOBALS.SFARMELEDOCID && this.GLOBALS.SFARMELEDOCID.length) {
                resolve(this.GLOBALS.SFARMELEDOCID);
            } else {
                this.storage.get('SFARMELEDOCID').then((res: Array<ISfarmele>) => {
                    if (res && res.length) {
                        this.GLOBALS.SFARMELEDOCID = res;
                        resolve(res);
                    } else {
                        this.fire.getDataWithDocId('de').subscribe((data) => {
                            if (data && data.length) {
                                this.GLOBALS.SFARMELEDOCID = data;
                                this.storage.set('SFARMELEDOCID', data);
                                resolve(data);
                            }
                        });
                    }
                });
            }
        });
    }

    replaceSonderzeichen(audioWord) {
        if (audioWord) {
            return audioWord.replace('ĥ', 'h').replace('ț', 't').replace('đ', 'd').replace('ĉ', 'c').replace('ğ', 'g').replace('ŝ', 's');
        }
    }

    scaleFocusKachel(arrayLength, index) {
        this.scaleCssArray = [];
        this.scaleCssArray = _.fill(arrayLength, false);
        this.scaleCssArray[index] = true;
        setTimeout(()=> {
            this.scaleCssArray = _.fill(arrayLength, false);
        },300);
    }

    back() {
        this.navCtrl.back();
    }

    public blockedCss = true;
    public verzoegerungBlockTime = 850;
    blockedGame(boolean, time) {
        setTimeout(()=> {
            this.blockedCss = boolean;
        }, time);
    }
}
