import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FabComponent} from './fab/fab.component';
import {IonicModule} from '@ionic/angular';
import {HeaderComponent} from './header/header.component';
import {IconBigComponent} from './icon-big/icon-big.component';
import {WordDisplayComponent} from './word-display/word-display.component';
import {ButtonComponent} from './button/button.component';
import {FormbuttonComponent} from './formbutton/formbutton.component';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {AudioComponent} from './audio/audio.component';
import {HeadermodalComponent} from './headermodal/headermodal.component';
import {TableComponent} from './table/table.component';
import {CardwithimageComponent} from './cardwithimage/cardwithimage.component';
import {RouterModule} from '@angular/router';
import {PipesModule} from '../pipes/pipes.module';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {HeadereasyComponent} from "./headereasy/headereasy.component";
import {AudioGeneralComponent} from "./audio2/audio-general.component";
import {LiveComponent} from "./live/live.component";
import {SmileyComponent} from "./smiley/smiley.component";
import {ButtongameComponent} from "./buttongame/buttongame.component";
import {SpielstatusComponent} from "./spielstatus/spielstatus.component";

const PAGES_COMPONENTS = [
    AudioComponent,
    AudioGeneralComponent,
    ButtonComponent,
    CardwithimageComponent,
    FabComponent,
    FormbuttonComponent,
    HeadermodalComponent,
    HeaderComponent,
    HeadereasyComponent,
    IconBigComponent,
    TableComponent,
    WordDisplayComponent,
    LiveComponent,
    SmileyComponent,
    ButtongameComponent,
    SpielstatusComponent
];

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        ClipboardModule,
        RouterModule,
        PipesModule,
        MatIconModule,
        MatFormFieldModule,
        MatSelectModule
    ],
    declarations: [PAGES_COMPONENTS],
    exports: [PAGES_COMPONENTS],
    entryComponents: []
})
export class ComponentsModule {
}
