import {Component, Input} from '@angular/core';

@Component({
  selector: '[app-fab]',
  templateUrl: './fab.component.html',
  styleUrls: ['./fab.component.scss'],
})
export class FabComponent {
  @Input() name;
  @Input() vertical;   // "bottom" | "center" | "top" | undefined
  @Input() horizontal; // "center" | "end" | "start" | undefine
  @Input() color;
  @Input() opacity;
}
