import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-buttongame',
    templateUrl: './buttongame.component.html',
    styleUrls: ['./buttongame.component.scss'],
})
export class ButtongameComponent {
    @Input() text;
    @Input() color;
}
