import {Pipe, PipeTransform} from '@angular/core';
import * as _ from 'lodash-es';
import {orderBy} from "lodash";

@Pipe({
    name: 'rankingtabelle'
})
export class RankingtabellePipe implements PipeTransform {

    transform(obj: Array<object>, art: string, anzahl: string): unknown {
        let filtered = _.filter(obj, {
            art: art,
            bilderanzahl: Number(anzahl)
        });
        return _.orderBy(filtered, 'zeit')
    }
}
