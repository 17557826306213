import {Component, Input} from '@angular/core';
import {CommonService} from '../../services/common.service';

@Component({
    selector: 'app-headermodal',
    templateUrl: './headermodal.component.html',
    styleUrls: ['./headermodal.component.scss'],
})
export class HeadermodalComponent {
    @Input() title: string;

    constructor(
        public common: CommonService
    ) {
    }
}
