import {Component, Input} from '@angular/core';
import {CommonService} from '../../services/common.service';
import * as _ from 'lodash-es';

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
})
export class TableComponent {
    @Input() items: any = [];
    @Input() audioId;

    constructor(public common: CommonService) {}
}
