import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SpeechRecognition} from '@ionic-native/speech-recognition/ngx';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AngularFireModule} from '@angular/fire';
import {environment} from '../environments/environment';
import {AngularFireDatabaseModule} from '@angular/fire/database';
import {AngularFireStorageModule} from '@angular/fire/storage';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {MaterialModule} from './modules/material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {SettingComponent} from './popovers/setting/setting.component';
import {FormsModule} from '@angular/forms';
import {WortverlaufComponent} from './tab1/wortverlauf/wortverlauf.component';
import {ComponentsModule} from './components/components.module';
import {SocialSharing} from '@ionic-native/social-sharing/ngx';
import {OneSignal} from '@ionic-native/onesignal/ngx';
import {HttpClientModule} from '@angular/common/http';
import {PipesModule} from './pipes/pipes.module';
import {BibelLesezeichenComponent} from './tab5/bibel-lesezeichen/bibel-lesezeichen.component';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {Device} from '@ionic-native/device/ngx';
import {PayPal} from '@ionic-native/paypal/ngx';
import {IonicStorageModule} from '@ionic/storage-angular';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {AykoyomodalComponent} from "./tabmehr/gamesworld/aykoyo/aykoyomodal/aykoyomodal.component";
import {ShowpdfPageModule} from "./tabmehr/showpdf/showpdf.module";

const PAGES_COMPONENTS = [
    WortverlaufComponent,
    BibelLesezeichenComponent,
    AykoyomodalComponent
];
@NgModule({
    declarations: [
        AppComponent,
        SettingComponent,
        PAGES_COMPONENTS,
    ],
    entryComponents: [
        SettingComponent,
        PAGES_COMPONENTS
    ],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        IonicStorageModule.forRoot(),
        AppRoutingModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFirestoreModule,
        AngularFireModule,
        AngularFireDatabaseModule,
        AngularFireStorageModule,
        MaterialModule,
        BrowserAnimationsModule,
        FormsModule,
        ComponentsModule,
        HttpClientModule,
        PipesModule,
        ClipboardModule,
        ShowpdfPageModule
    ],
    providers: [
        OneSignal,
        StatusBar,
        SplashScreen,
        SpeechRecognition,
        SocialSharing,
        InAppBrowser,
        Device,
        PayPal,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy}
    ],
    exports: [
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
