import {Pipe, PipeTransform} from '@angular/core';
import * as _ from 'lodash-es';

@Pipe({
    name: 'orderbydate'
})
export class OrderbydatePipe implements PipeTransform {

    transform(value: unknown, ...args: unknown[]): unknown {
        return _.orderBy(value,['date']);
    }
}
