export const WORDS = [
        {
            lt12: "",
            sy7: "",
            dt1: "Augenbrauen",
            lt11: "",
            sy4: "ܬܠܝܦܐ",
            dt2: "",
            dt3: "",
            sy5: "ܬܠܺܝܦܶܐ",
            sy6: "",
            sy9: "",
            lt10: "tlife",
            id: "00Y9HIWmb01Bt9ttHhDh",
            sy8: ""
        },
        {
            lt11: "",
            lt12: "",
            sy9: "",
            dt1: "Haushalter",
            lt10: "farnoso",
            sy6: "",
            dt3: "",
            sy5: "ܦܰܪܢܳܣܳܐ",
            dt2: "",
            sy7: "",
            sy4: "ܦܪܢܣܐ",
            id: "1903",
            sy8: ""
        },
        {
            sy8: "",
            lt10: "ܩܠܬܡܪܐ",
            dt1: "Kalitmara",
            lt11: "ܩܠܬܡܪܐ",
            sy9: "",
            dt3: "",
            sy7: "",
            sy6: "",
            lt12: "",
            sy5: "ܩܶܠܶܬܡܰܖܰܐ",
            sy4: "ܩܠܬܡܖܐ",
            dt2: "",
            id: "2391"
        },
        {
            lt10: "gelyono",
            dt3: "",
            dt2: "",
            lt11: "galyutho",
            sy4: "ܓܠܝܢܐ",
            sy5: "ܓܶܠܝܳܢܳܐ",
            lt12: "methgalyonutho",
            sy7: "ܓܰܠܝܽܘܬ݂ܳܐ",
            sy9: "ܡܶܬ݂ܓܰܠܝܳܢܽܘܬ݂ܳܐ",
            dt1: "Offenbarung",
            sy6: "ܓܠܝܘܬܐ",
            id: "3347",
            sy8: "ܡܬܓܠܝܢܘܬܐ"
        },
        {
            sy9: "",
            id: "631",
            lt10: "ĉdamo lanshamtho ĥroyto",
            dt1: "bis zum letzten Atemzug",
            sy8: "",
            dt3: "",
            sy4: "ܥܕܡܐ ܠܢܫܡܬܐ ܐܚܪܝܬܐ",
            lt11: "",
            sy5: "ܥܰܕܡܳܐ ܠܰܢܫܰܡܬ݂ܳܐ ܐ̱ܚܪܳܝܬܳܐ",
            dt2: "",
            sy6: "",
            sy7: "",
            lt12: ""
        },
        {
            sy8: "",
            dt2: "trocknen",
            dt3: "",
            lt12: "",
            sy4: "ܝܒܫ",
            sy6: "",
            lt11: "",
            lt10: "yibesh",
            sy9: "",
            sy7: "",
            dt1: "austrocknen",
            id: "392",
            sy5: "ܝܺܒܶܫ"
        },
        {
            sy6: "ܐܦܣܩܘܦܐ",
            sy8: "",
            id: "632",
            lt11: "efesqufo",
            dt2: "",
            dt3: "",
            sy4: "ܚܣܝܐ",
            lt12: "",
            sy5: "ܚܰܣܝܳܐ",
            sy9: "",
            lt10: "ĥasyo",
            sy7: "ܐܶܦܶܣܩܽܘܦܳܐ",
            dt1: "Bischof"
        },
        {
            sy5: "ܚܶܙܰܩܝܳܐ",
            id: "2024",
            lt11: "ܚܙܩܝܐ",
            dt2: "",
            sy6: "ܚܙܩܝܐ",
            sy4: "ܚܙܩܝܐ",
            dt3: "",
            dt1: "Hiskija",
            lt10: "ܚܙܩܝܐ",
            lt12: "ܚܙܩܝܐ",
            sy8: "",
            sy9: "",
            sy7: "ܚܺܙܩܺܝܰܐ"
        },
        {
            lt12: "",
            id: "5352",
            sy5: "ܪܶܓ݂ܠܳܐܝܺܬ݂",
            dt3: "",
            sy4: "ܪܓܠܐܝܬ",
            lt11: "mhalkhoith",
            sy7: "ܡܗܰܠܟ݂ܳܐܝܺܬ݂",
            sy9: "",
            dt1: "zu Fuß",
            sy8: "",
            dt2: "",
            lt10: "reğloith",
            sy6: "ܡܗܠܟܐܝܬ"
        },
        {
            dt1: "Brüder",
            dt3: "",
            sy4: "ܐܚܐ",
            lt11: "aĥunone",
            sy7: "ܐܰܚܽܘܢ̈ܳܢܶܐ",
            sy9: "",
            sy8: "",
            lt10: "aĥe",
            dt2: "",
            sy5: "ܐܰܚ̈ܶܐ",
            lt12: "",
            sy6: "ܐܚܘܢܢܐ",
            id: "717"
        },
        {
            sy7: "",
            dt1: "wackeln",
            sy9: "",
            lt11: "",
            lt10: "bađel",
            sy5: "ܒܰܕܶܠ",
            sy6: "",
            sy8: "",
            id: "4922",
            dt2: "",
            sy4: "ܒܝܕܠ",
            dt3: "",
            lt12: ""
        },
        {
            sy8: "",
            lt11: "",
            lt10: "haymonutho",
            id: "1651",
            dt2: "",
            sy5: "ܗܰܝܡܳܢܽܘܬ݂ܳܐ",
            dt3: "",
            sy7: "",
            dt1: "Glaube",
            sy4: "ܗܝܡܢܘܬܐ",
            sy6: "",
            sy9: "",
            lt12: ""
        },
        {
            lt12: "",
            lt11: "ĉaqeb",
            dt1: "erforschen",
            lt10: "fatesh",
            dt2: "aufsuchen",
            sy4: "ܦܬܫ",
            sy5: "ܦܰܬܶܫ",
            sy9: "",
            sy8: "",
            sy6: "ܥܩܒ",
            id: "1148",
            sy7: "ܥܰܩܶܒ",
            dt3: ""
        },
        {
            lt12: "",
            sy6: "",
            sy7: "",
            dt1: "Tirza",
            lt10: "ܬܝܪܙܐ",
            dt3: "",
            sy8: "",
            dt2: "",
            id: "4496",
            lt11: "ܬܝܪܙܐ",
            sy5: "ܬܺܝܪܙܰܐ",
            sy4: "ܬܝܪܙܐ",
            sy9: ""
        },
        {
            dt2: "",
            dt3: "",
            lt11: "",
            sy8: "",
            dt1: "z",
            lt10: "zay",
            sy7: "",
            sy4: "ܙ",
            sy6: "",
            lt12: "",
            sy9: "",
            id: "5216",
            sy5: "ܙ"
        },
        {
            lt10: "aubel qđom dayono",
            sy8: "",
            dt2: "",
            sy7: "",
            dt1: "vor den Richter bringen",
            lt12: "",
            lt11: "",
            sy5: "ܐܰܘܒܶܠ ܩܕܳܡ ܕܰܝܳܢܳܐ",
            id: "4892",
            sy6: "",
            sy9: "",
            sy4: "ܐܘܒܠ ܩܕܡ ܕܝܢܐ",
            dt3: ""
        },
        {
            sy8: "",
            sy9: "",
            dt1: "Freitag der Bekenner",
            sy4: "ܥܪܘܒܬܐ ܕܡܘܕܝܝܐ",
            dt2: "Freitag der Weißen Woche",
            dt3: "Erste Woche nach Ostern",
            sy5: "ܥܪܽܘܒܬܳܐ ܕܡܰܘܕܝܳܝܶܐ",
            lt10: "ĉrubto dmaudyoye",
            lt12: "",
            sy6: "ܡܘܕܝܝܐ",
            sy7: "ܡܰܘܕܝܳܝܶܐ",
            lt11: "maudyoye",
            id: "6192"
        },
        {
            sy5: "ܦܰܪܽܘܕܶܐ",
            sy7: "",
            sy4: "ܦܪܘܕܐ",
            dt1: "Wechselgeld",
            dt2: "Kleingeld",
            sy9: "",
            lt11: "",
            dt3: "",
            id: "5016",
            sy6: "",
            sy8: "",
            lt12: "",
            lt10: "faruđe"
        },
        {
            id: "3713",
            sy9: "",
            dt2: "",
            sy6: "",
            dt1: "Rhimo",
            lt12: "",
            sy8: "",
            sy5: "ܪܚܺܝܡܳܐ",
            dt3: "",
            lt11: "ܪܚܝܡܐ",
            sy4: "ܪܚܝܡܐ",
            lt10: "ܪܚܝܡܐ",
            sy7: ""
        },
        {
            sy6: "",
            lt12: "",
            sy4: "ܪܚܡܬ ܚܝܪܘܬܐ",
            lt11: "",
            sy8: "",
            lt10: "reĥmath ĥirutho",
            dt3: "",
            dt1: "Freiheitsliebe",
            sy9: "",
            dt2: "",
            id: "6102",
            sy7: "",
            sy5: "ܪܶܚܡܰܬ݂ ܚܺܝܪܽܘܬ݂ܳܐ"
        },
        {
            id: "3467",
            dt2: "",
            sy4: "ܨܘܨܦܬܐ",
            dt3: "",
            sy5: "ܨܽܘܨܶܦܬܳܐ",
            sy8: "",
            dt1: "Pinzette",
            sy9: "",
            sy7: "",
            lt12: "",
            lt11: "",
            lt10: "ŝuŝefto",
            sy6: ""
        },
        {
            sy4: "ܝܘܚܢܢ ܕܘܠܒܐܢܝ",
            sy7: "",
            lt11: "ܝܘܚܢܢ ܕܘܠܒܐܢܝ",
            sy6: "",
            dt3: "",
            dt2: "Johannes Dolabani",
            sy9: "",
            sy5: "ܝܘܚܢܢ ܕܘܠܒܐܢܝ",
            id: "5214",
            lt12: "",
            lt10: "ܝܘܚܢܢ ܕܘܠܒܐܢܝ",
            dt1: "Yuhanon Dolabani",
            sy8: ""
        },
        {
            lt10: "kul medem dgodesh bazban lyuthronokh hu howe",
            sy7: "",
            dt2: "",
            sy5: "ܟܽܠ ܡܶܕܶܡ ܕܓܳܕܶܫ ܒܰܙܒܰܢ ܠܝܽܘܬ݂ܪܳܢܳܟ ܗܽܘ ܗܳܘܶܐ",
            sy4: "ܟܠ ܡܕܡ ܕܓܕܫ ܒܙܒܢ ܠܝܘܬܪܢܟ ܗܘ ܗܘܐ",
            dt3: "",
            sy9: "",
            id: "188",
            dt1: "alles was passiert kommt dir mal zu Gute",
            sy8: "",
            sy6: "",
            lt11: "",
            lt12: ""
        },
        {
            sy8: "",
            lt11: "",
            dt2: "",
            sy7: "",
            sy4: "ܪܝܫܐ ܕܢܘܢܐ",
            id: "1321",
            sy6: "",
            dt3: "",
            lt10: "risho d`nuno",
            sy5: "ܪܺܝܫܳܐ ܕܢܽܘܢܳܐ",
            dt1: "Fischkopf",
            lt12: "",
            sy9: ""
        },
        {
            id: "6317",
            sy7: "",
            lt12: "",
            sy4: "ܗܘܠܐ",
            dt3: "Substanz",
            lt11: "",
            sy5: "ܗܽܘܠܳܐ",
            dt1: "Materie",
            sy9: "",
            dt2: "Stoff",
            sy8: "",
            lt10: "hulo",
            sy6: ""
        },
        {
            id: "1742",
            lt12: "",
            lt10: "tagoro rabo",
            dt3: "",
            sy6: "",
            dt1: "Großhändler",
            sy9: "",
            sy4: "ܬܓܪܐ ܪܒܐ",
            sy7: "",
            sy5: "ܬܰܓܳܪܳܐ ܪܰܒܳܐ",
            sy8: "",
            lt11: "",
            dt2: ""
        },
        {
            dt2: "",
            dt3: "",
            dt1: "strafbar",
            sy9: "",
            id: "4288",
            sy7: "ܡܶܬ݂ܥܰܕܠܳܢܳܐ",
            lt12: "",
            lt10: "methrashyono",
            lt11: "methĉadlono",
            sy6: "ܡܶܬ݂ܥܕܠܢܐ",
            sy8: "",
            sy4: "ܡܶܬ݂ܪܰܫܝܳܢܳܐ",
            sy5: "ܡܶܬ݂ܪܰܫܝܳܢܳܐ"
        },
        {
            dt2: "",
            dt3: "",
            sy5: "ܟܰܬܦܳܐ",
            sy8: "",
            sy7: "",
            sy9: "",
            lt11: "",
            lt10: "katfo",
            id: "4052",
            lt12: "",
            dt1: "Schulter",
            sy4: "ܟܬܦܐ",
            sy6: ""
        },
        {
            dt1: "Nagellack",
            sy6: "",
            sy9: "",
            sy8: "",
            dt2: "",
            lt11: "",
            id: "3179",
            lt12: "",
            sy4: "ܕܪܡܢܐ ܕܛܦܪܐ",
            lt10: "darmono d`țefre",
            sy5: "ܕܰܪܡܳܢܳܐ ܕܛܶܦܪܶܐ",
            dt3: "",
            sy7: ""
        },
        {
            sy4: "ܒܫܠ",
            lt12: "rthaĥ",
            lt10: "bashel",
            dt3: "",
            sy5: "ܒܰܫܶܠ",
            id: "2550",
            lt11: "arthaĥ",
            sy7: "ܐܰܪܬ݂ܰܚ",
            dt2: "",
            sy6: "ܐܪܬܚ",
            dt1: "kochen",
            sy9: "ܪܬ݂ܰܚ",
            sy8: "ܪܬܚ"
        },
        {
            dt2: "",
            dt1: "abstürzen",
            sy5: "ܢܦܰܠ ܥܰܠ ܐܰܪܥܳܐ",
            sy7: "ܓܰܪܓܰܚ",
            lt10: "nfal ĉal arĉo",
            sy8: "ܓܝܕ",
            lt11: "gargaĥ",
            sy4: "ܢܦܠ ܥܠ ܐܪܥܐ",
            lt12: "gayed",
            sy9: "ܓܰܝܶܕ",
            sy6: "ܓܪܓܚ",
            dt3: "",
            id: "5678"
        },
        {
            dt1: "Arbeitslosin",
            sy8: "",
            sy9: "",
            lt10: "bațolto",
            sy7: "ܒܰܛܺܝܠܬܳܐ",
            sy4: "ܒܛܠܬܐ",
            dt2: "",
            dt3: "",
            sy6: "ܒܛܝܠܬܐ",
            lt12: "",
            sy5: "ܒܰܛܳܠܬܳܐ",
            lt11: "bațilto",
            id: "283"
        },
        {
            dt2: "",
            sy9: "",
            lt12: "",
            sy5: "ܐܰܪܺܝܡ ܐ̱ܢܳܐ ܐܺܝܕܳܐ ܕܝܰܡܺܝܢܳܐ",
            lt10: "arim no iđo d`yamino",
            sy7: "",
            sy6: "",
            sy4: "ܐܪܝܡ ܐܢܐ ܐܝܕܐ ܕܝܡܝܢܐ",
            id: "2116",
            sy8: "",
            lt11: "",
            dt1: "Ich hebe den rechten Arm hoch",
            dt3: ""
        },
        {
            sy5: "ܣܳܒܳܐ",
            dt1: "Alter Mann",
            sy7: "",
            dt2: "",
            sy4: "ܣܒܐ",
            sy9: "",
            id: "197",
            sy6: "",
            lt11: "",
            dt3: "",
            lt12: "",
            sy8: "",
            lt10: "sobo"
        },
        {
            lt12: "",
            lt11: "ܐܘܪܝܠ",
            sy8: "",
            sy5: "ܐܽܘܪܺܝܶܠ",
            dt1: "Uriel",
            lt10: "ܐܘܪܝܠ",
            dt2: "",
            sy4: "ܐܘܪܝܠ",
            sy9: "",
            sy6: "",
            sy7: "",
            dt3: "",
            id: "4689"
        },
        {
            lt12: "ܚܙܝܐ",
            sy5: "ܚܰܙܰܝܰܐ",
            sy7: "ܚܰܙܳܝܳܐ",
            sy9: "",
            dt1: "Hasaja",
            dt2: "",
            id: "1877",
            lt11: "ܚܙܝܐ",
            sy4: "ܚܙܝܐ",
            sy6: "ܚܙܝܐ",
            lt10: "ܚܙܝܐ",
            dt3: "",
            sy8: ""
        },
        {
            lt12: "",
            dt2: "",
            sy4: "ܗܠܟ",
            id: "5956",
            sy7: "",
            dt3: "",
            lt11: "",
            sy5: "ܗܰܠܶܟ݂",
            sy6: "",
            sy9: "",
            sy8: "",
            lt10: "halekh",
            dt1: "anlaufen"
        },
        {
            lt12: "",
            dt2: "Tröstender",
            lt11: "mbayono",
            sy6: "ܡܒܐܝܢܐ",
            dt1: "Tröster",
            lt10: "mbayono",
            sy9: "",
            sy7: "ܡܒܰܐܝܳܢܳܐ",
            sy8: "",
            id: "4582",
            sy4: "ܡܒܝܐܢܐ",
            sy5: "ܡܒܰܝܳܐܢܳܐ",
            dt3: "Trostspender"
        },
        {
            sy4: "ܨܒܥܐ",
            sy7: "",
            dt2: "",
            sy5: "ܨܶܒܥܳܐ",
            lt12: "",
            dt3: "",
            sy6: "",
            dt1: "Finger",
            id: "1313",
            sy8: "",
            lt10: "ŝebĉo",
            lt11: "",
            sy9: ""
        },
        {
            lt11: "",
            id: "5875",
            dt3: "",
            lt12: "",
            sy5: "ܙܥܽܘܪ ܒܟܽܠ",
            dt1: "am kleinsten",
            sy9: "",
            sy6: "",
            sy8: "",
            lt10: "zĉur bkul",
            dt2: "",
            sy4: "ܙܥܘܪ ܒܟܠ",
            sy7: ""
        },
        {
            lt11: "",
            sy7: "",
            dt3: "",
            dt2: "",
            dt1: "Fahrradhelm",
            lt12: "",
            id: "1236",
            sy6: "",
            sy8: "",
            sy4: "ܣܢܘܪܬܐ ܕܕܪܓܬܐ",
            sy5: "ܣܰܢܘܰܪܬܳܐ ܕܕܰܪܳܓ݂ܬܳܐ",
            sy9: "",
            lt10: "sanwarto d`daroğto"
        },
        {
            sy7: "ܢܡ",
            dt1: "rr1333",
            dt2: "rr2",
            sy5: "ܕ",
            lt10: "ğğğ",
            sy9: "ܕܕܕܕ",
            sy6: "ܢܡ",
            sy4: "ܕ",
            lt12: "țțț",
            sy8: "ܕܕܕܕ",
            id: "0NktJElPI3agogUFKwCv",
            lt11: "ŝŝŝ",
            dt3: "rr3"
        },
        {
            lt11: "",
            sy6: "",
            sy4: "ܐܒܐ ܫܐܠܐ",
            sy9: "",
            id: "4269",
            dt1: "Stiefvater",
            sy7: "",
            lt12: "",
            dt2: "",
            sy8: "",
            sy5: "ܐܰܒܳܐ ܫܺܐܠܳܐ",
            lt10: "abo shilo",
            dt3: ""
        },
        {
            sy6: "",
            sy5: "ܚܙܽܘܩܝܳܐ ܕܩܛܳܪܳܐ",
            dt2: "",
            dt1: "Zugfahrt",
            lt12: "",
            lt10: "ĥzuqyo d`qțoro",
            sy4: "ܚܙܘܩܝܐ ܕܩܛܪܐ",
            sy8: "",
            id: "5363",
            lt11: "",
            sy7: "",
            sy9: "",
            dt3: ""
        },
        {
            id: "3090",
            sy6: "",
            dt3: "",
            sy8: "",
            lt12: "",
            dt2: "",
            sy9: "",
            sy4: "ܡܪܝ ܓܘܪܓܝܣ ܣܗܕܐ",
            sy7: "",
            sy5: "ܡܳܪܝ̱ ܓܶܘܰܪܓܺܝܣ ܣܳܗܕܳܐ",
            dt1: "Mor Gewargis Sohdo",
            lt11: "",
            lt10: "mor gewargis sohdo"
        },
        {
            sy9: "",
            dt1: "Fohlen",
            sy8: "",
            sy5: "ܥܺܝܠܳܐ",
            lt12: "",
            lt11: "",
            dt3: "",
            sy7: "",
            sy4: "ܥܝܠܐ",
            id: "1347",
            lt10: "ĉilo",
            sy6: "",
            dt2: ""
        },
        {
            dt1: "Eintrittsgeld",
            id: "6412",
            sy8: "",
            sy4: "ܙܘܙܐ ܕܡܥܠܐ",
            dt3: "",
            sy9: "",
            sy7: "ܙܽܘ̈ܙܶܐ ܕܥܳܠܽܘܠܽܘܬ݂ܳܐ",
            dt2: "",
            sy6: "ܙܘܙܐ ܕܥܠܘܠܘܬܐ",
            lt11: "zuze d`ĉolulutho",
            lt12: "",
            sy5: "ܙܽܘ̈ܙܶܐ ܕܡܰܥܠܳܐ",
            lt10: "zuze d`maĉlo"
        },
        {
            sy7: "",
            dt3: "",
            sy4: "ܦܢܡܘܐ",
            lt12: "",
            sy5: "ܦܰܢܰܡܘܰܐ",
            sy9: "",
            dt1: "Panamwa",
            lt10: "ܦܢܡܘܐ",
            id: "3413",
            lt11: "ܦܢܡܘܐ",
            sy6: "",
            sy8: "",
            dt2: ""
        },
        {
            dt3: "",
            sy7: "ܦܰܠܶܣܬܺܝܢܳܝܳܐ",
            dt1: "Palästinenser",
            sy4: "ܦܠܫܬܝܐ",
            sy5: "ܦܠܶܫܬܳܝܳܐ",
            lt11: "falesținoyo",
            lt10: "fleshtoyo",
            sy8: "",
            dt2: "",
            sy6: "ܦܠܣܬܝܢܝܐ",
            lt12: "",
            id: "3412",
            sy9: ""
        },
        {
            sy8: "",
            dt2: "",
            lt12: "",
            sy7: "",
            dt3: "",
            id: "5609",
            sy9: "",
            lt10: "urĥo dĥad maqerbono",
            lt11: "",
            dt1: "Weg eines Kriegers",
            sy5: "ܐܽܘܪܚܳܐ ܕܚܰܕ ܡܰܩܶܪܒܳܢܳܐ",
            sy6: "",
            sy4: "ܐܘܪܚܐ ܕܚܕ ܡܩܪܒܢܐ"
        },
        {
            lt12: "",
            sy8: "",
            dt2: "",
            id: "2002",
            sy7: "",
            dt3: "",
            sy5: "ܩܽܘܒܰܬ݂ ܫܡܰܝܳܐ",
            dt1: "Himmelsgewölbe",
            lt11: "",
            sy9: "",
            sy6: "",
            sy4: "ܩܘܒܬ ܫܡܝܐ",
            lt10: "qubath shmayo"
        },
        {
            sy5: "ܩܰܘܡܳܐ",
            sy6: "",
            sy8: "",
            sy7: "",
            lt12: "",
            lt11: "ܩܘܡܐ",
            sy9: "",
            dt3: "",
            dt2: "",
            id: "3560",
            lt10: "ܩܘܡܐ",
            sy4: "ܩܘܡܐ",
            dt1: "Qaumo"
        },
        {
            dt2: "",
            sy7: "",
            sy8: "",
            lt10: "b`ruĥqo",
            lt11: "",
            dt1: "in der Ferne",
            id: "2177",
            sy6: "",
            sy4: "ܒܪܘܚܩܐ",
            lt12: "",
            dt3: "",
            sy5: "ܒܪܽܘܚܩܳܐ",
            sy9: ""
        },
        {
            dt3: "",
            lt10: "marnitho",
            dt2: "",
            sy5: "ܡܰܪܢܺܝܬ݂ܳܐ",
            sy7: "",
            sy4: "ܡܪܢܝܬܐ",
            dt1: "Denken",
            lt11: "",
            id: "859",
            sy9: "",
            sy6: "",
            sy8: "",
            lt12: ""
        },
        {
            sy5: "ܡܰܠܦܳܢ ܗܶܢܝܽܘܟ݂ܽܘܬ݂ܳܐ",
            sy4: "ܡܠܦܢ ܗܢܝܘܟܘܬܐ",
            dt2: "",
            lt11: "malfon d`dubor rađoyto",
            sy9: "",
            dt1: "Fahrschullehrer",
            id: "1239",
            dt3: "",
            sy7: "ܡܰܠܦܳܢ ܕܕܽܘܒܳܪ ܪܰܕܳܝܬܳܐ",
            lt10: "malfon henyukhutho",
            lt12: "",
            sy8: "",
            sy6: "ܡܠܦܢ ܕܕܘܒܪ ܪܕܝܬܐ"
        },
        {
            lt11: "",
            lt10: "emwotho mĥaydotho",
            sy7: "",
            sy9: "",
            dt1: "Vereinte Nationen",
            id: "4727",
            dt2: "",
            sy8: "",
            sy4: "ܐܡܘܬܐ ܡܚܝܕܬܐ",
            dt3: "",
            lt12: "",
            sy6: "",
            sy5: "ܐܶܡܘ̈ܳܬ݂ܳܐ ܡܚܰܝܕܳܬ݂ܳܐ"
        },
        {
            dt1: "Volksfest",
            sy8: "",
            sy9: "",
            lt12: "",
            sy4: "ܥܐܕܐ ܐܘܡܬܢܝܐ",
            lt10: "ĉiđo umthonoyo",
            lt11: "",
            sy7: "",
            id: "4854",
            dt3: "",
            sy6: "",
            dt2: "",
            sy5: "ܥܺܐܕܳܐ ܐܽܘܡܬ݂ܳܢܳܝܳܐ"
        },
        {
            sy7: "",
            sy5: "ܩܽܘܕܰܡܬ݂ܳܐ",
            sy8: "",
            lt11: "",
            dt3: "",
            sy4: "ܩܘܕܡܬܐ",
            sy6: "",
            dt2: "",
            dt1: "Abschlagszahlung",
            lt10: "qudamtho",
            sy9: "",
            id: "5623",
            lt12: ""
        },
        {
            sy4: "ܐܝܣܚܩ",
            lt11: "ܐܝܣܚܩ",
            sy8: "",
            sy6: "ܐܝܣܚܩ",
            dt3: "",
            lt10: "ܐܝܣܚܩ",
            dt2: "",
            sy5: "ܐܺܝܣܚܰܩ",
            sy9: "",
            sy7: "ܐܺܝܣܚܳܩ",
            lt12: "ܐܝܣܚܩ",
            id: "2246",
            dt1: "Ishaak"
        },
        {
            sy5: "ܙܳܢܝܽܘܬ݂ܳܐ ܕܰܒܓ݂ܰܘ ܝܺܩܰܪܬܳܐ",
            lt11: "",
            id: "2234",
            dt1: "Inzest",
            lt12: "",
            sy9: "",
            dt2: "Blutschande",
            sy4: "ܙܢܝܘܬܐ ܕܒܓܘ ܝܩܪܬܐ",
            sy8: "",
            sy6: "",
            lt10: "zonyutho dab`ğau iqartho",
            dt3: "",
            sy7: ""
        },
        {
            sy4: "ܩܕܡܝܬܐ",
            sy7: "",
            dt1: "Erste",
            sy5: "ܩܰܕܡܳܝܬܳܐ",
            dt2: "",
            id: "1173",
            sy9: "",
            lt10: "qađmoyto",
            lt11: "",
            lt12: "",
            sy6: "",
            sy8: "",
            dt3: ""
        },
        {
            id: "4749",
            dt3: "",
            sy6: "",
            dt1: "Verkäufer",
            sy9: "",
            dt2: "",
            sy5: "ܡܙܰܒܳܢܳܐ",
            sy4: "ܡܙܒܢܐ",
            lt11: "",
            lt10: "mzabono",
            lt12: "",
            sy7: "",
            sy8: ""
        },
        {
            dt2: "",
            lt10: "ܓܒܪ",
            sy5: "ܓܰܒܳܪ",
            sy7: "ܓܰܐܒܰܪ",
            sy4: "ܓܒܪ",
            dt3: "",
            sy9: "",
            sy8: "",
            sy6: "ܓܐܒܪ",
            id: "1453",
            lt11: "ܓܒܪ",
            dt1: "Gabor",
            lt12: "ܓܐܒܪ"
        },
        {
            sy7: "",
            sy8: "",
            id: "540",
            sy4: "ܢܒܚܐ",
            sy5: "ܢܒܳܚܳܐ",
            dt3: "",
            sy6: "",
            dt2: "",
            lt11: "",
            lt10: "nboĥo",
            dt1: "Bellen",
            sy9: "",
            lt12: ""
        },
        {
            sy5: "ܢܟ݂ܺܝܠܳܐ",
            dt2: "",
            sy9: "",
            lt11: "nokhulo",
            sy7: "ܢܳܟ݂ܽܘܠܳܐ",
            sy6: "ܢܟܘܠܐ",
            dt3: "",
            lt12: "",
            sy8: "",
            dt1: "Betrüger",
            lt10: "nkhilo",
            id: "589",
            sy4: "ܢܟܝܠܐ"
        },
        {
            sy4: "ܡܫܛ",
            dt1: "abhäuten",
            sy9: "ܩܠܰܦ",
            lt10: "meshaț",
            lt11: "nshaț",
            lt12: "qlaf",
            sy6: "ܢܫܛ",
            dt3: "",
            sy7: "ܢܫܰܛ",
            sy8: "ܩܠܦ",
            sy5: "ܡܶܫܰܛ",
            dt2: "",
            id: "5571"
        },
        {
            sy8: "",
            lt10: "dabar",
            sy6: "",
            lt12: "",
            dt1: "leiten",
            dt2: "führen",
            id: "2759",
            lt11: "",
            sy7: "",
            sy9: "",
            sy4: "ܕܒܪ",
            dt3: "",
            sy5: "ܕܰܒܰܪ"
        },
        {
            dt2: "",
            lt10: "kthub ĥađ ĥuqo",
            dt1: "Schreibe eine Zeile",
            dt3: "",
            sy4: "ܟܬܘܒ ܚܕ ܚܘܩܐ",
            sy5: "ܟܬ݂ܽܘܒ ܚܰܕ ܚܽܘܩܳܐ",
            lt12: "",
            sy6: "",
            lt11: "",
            sy9: "",
            sy8: "",
            sy7: "",
            id: "4025"
        },
        {
            sy9: "",
            lt10: "ŝayoro",
            dt1: "Zeichner",
            id: "5282",
            sy6: "",
            sy5: "ܨܰܝܳܪܳܐ",
            sy8: "",
            dt2: "",
            lt12: "",
            sy7: "",
            dt3: "",
            lt11: "",
            sy4: "ܨܝܪܐ"
        },
        {
            dt1: "Abwischen",
            id: "5715",
            sy4: "ܠܚܝܐ",
            dt2: "",
            lt12: "",
            lt11: "lțofo",
            sy6: "ܠܛܦܐ",
            lt10: "lĥoyo",
            dt3: "",
            sy5: "ܠܚܳܝܳܐ",
            sy8: "",
            sy9: "",
            sy7: "ܠܛܳܦܳܐ"
        },
        {
            id: "2534",
            dt3: "",
            sy9: "",
            sy7: "",
            lt10: "dayro d`mor gabriel",
            lt12: "",
            dt2: "",
            dt1: "Kloster Mor Gabriel",
            sy8: "",
            sy6: "",
            sy5: "ܕܰܝܪܳܐ ܕܡܳܪ ܓܰܒܪܺܐܝܶܠ",
            lt11: "",
            sy4: "ܕܝܪܐ ܕܡܪ ܓܒܪܐܝܠ"
        },
        {
            dt3: "",
            dt1: "Pinguin",
            id: "3465",
            sy7: "",
            sy8: "",
            sy9: "",
            dt2: "",
            lt12: "",
            sy5: "ܦܺܝܢܓܽܘܺܝܢ",
            sy6: "",
            lt11: "",
            lt10: "finguin",
            sy4: "ܦܝܢܓܘܝܢ"
        },
        {
            sy4: "ܢܣܒ ܛܥܢܐ",
            sy5: "ܢܣܰܒ ܛܰܥܢܳܐ",
            dt3: "",
            id: "5733",
            lt11: "bțel",
            dt1: "achtgeben",
            sy8: "",
            sy7: "ܒܛܶܠ",
            sy9: "",
            lt10: "nsab țaĉno",
            sy6: "ܒܛܠ",
            dt2: "",
            lt12: ""
        },
        {
            lt10: "qritho sare",
            sy6: "ܣܐܪܝ",
            lt12: "",
            sy9: "",
            sy4: "ܩܪܝܬܐ ܣܐܪܝ",
            dt3: "",
            lt11: "sare",
            dt2: "Sare",
            dt1: "Dorf Sare",
            sy7: "ܣܰܐܪܶܝ",
            sy5: "ܩܪܺܝܬ݂ܳܐ ܣܰܐܪܝ",
            id: "943",
            sy8: ""
        },
        {
            lt10: "ethțayab",
            sy4: "ܐܬܛܝܒ",
            dt3: "gerüstet sein",
            sy8: "",
            id: "4896",
            dt2: "bereit sein",
            sy6: "",
            sy5: "ܐܶܬ݂ܛܰܝܰܒ",
            dt1: "vorbereitet sein",
            sy9: "",
            lt11: "",
            sy7: "",
            lt12: ""
        },
        {
            dt2: "Pflicht",
            dt3: "",
            sy5: "ܘܳܠܺܝܬ݂ܳܐ",
            sy7: "",
            sy4: "ܘܠܝܬܐ",
            dt1: "Aufgabe",
            sy6: "",
            id: "351",
            lt12: "",
            sy9: "",
            lt11: "",
            lt10: "wolitho",
            sy8: ""
        },
        {
            sy7: "ܠܶܘܝܳܬܰܢ",
            dt2: "",
            dt1: "Seemonster",
            lt10: "tarbsonoyo",
            sy5: "ܬܰܪܒܣܳܢܳܝܳܐ",
            id: "6215",
            sy6: "ܠܘܝܬܢ",
            dt3: "",
            sy9: "",
            lt11: "lewyotan",
            sy8: "",
            lt12: "",
            sy4: "ܬܪܒܣܢܝܐ"
        },
        {
            sy7: "",
            lt10: "ܥܡܝܙܕܝܩܐ",
            lt11: "ܥܡܝܙܕܝܩܐ",
            sy8: "",
            sy6: "",
            lt12: "",
            dt2: "Amisadok ",
            sy5: "ܥܰܡܺܝܙܰܕܝܺܩܳܐ",
            dt3: "",
            sy4: "ܥܡܝܙܕܝܩܐ",
            dt1: "Amisadiko",
            sy9: "",
            id: "213"
        },
        {
            sy5: "ܪܰܟܳܠܽܘܬ݂ܳܐ",
            dt3: "",
            id: "261",
            sy8: "",
            sy7: "",
            dt1: "Apotheke",
            lt11: "",
            dt2: "",
            sy9: "",
            sy6: "",
            lt12: "",
            lt10: "rakolutho",
            sy4: "ܪܟܠܘܬܐ"
        },
        {
            lt12: "",
            id: "1377",
            sy9: "",
            sy5: "ܡܰܪܳܚܳܐ",
            lt11: "ĥarbo",
            sy4: "ܡܪܚܐ",
            sy8: "",
            sy6: "ܚܪܒܐ",
            sy7: "ܚܰܪܒܳܐ",
            dt1: "frech",
            dt3: "",
            dt2: "",
            lt10: "maroĥo"
        },
        {
            dt2: "unschön",
            sy6: "",
            lt11: "",
            id: "1884",
            sy4: "ܫܟܝܪܐ",
            dt3: "",
            sy9: "",
            sy5: "ܫܟ݂ܺܝܪܳܐ",
            sy7: "",
            lt12: "",
            dt1: "hässlich",
            lt10: "shkhiro",
            sy8: ""
        },
        {
            sy8: "",
            dt2: "",
            lt11: "",
            id: "5834",
            lt10: "yidiĉ bkul duk",
            dt1: "allbekannt",
            sy9: "",
            sy7: "",
            lt12: "",
            sy6: "",
            sy5: "ܝܺܝܕܺܝܥ ܒܟܽܠ ܕܽܘܟ",
            sy4: "ܝܝܕܝܥ ܒܟܘܠ ܕܘܟ",
            dt3: ""
        },
        {
            sy8: "",
            lt10: "sĉoyo",
            lt11: "",
            dt3: "",
            sy4: "ܣܥܝܐ",
            dt1: "Offensive",
            sy5: "ܣܥܳܝܳܐ",
            sy7: "",
            id: "6130",
            sy9: "",
            dt2: "",
            sy6: "",
            lt12: ""
        },
        {
            sy4: "ܩܒܠ",
            dt2: "entgegentreten",
            sy8: "",
            sy6: "",
            lt10: "qbal",
            id: "1115",
            lt11: "",
            lt12: "",
            sy9: "",
            dt3: "opponieren",
            sy7: "",
            dt1: "entgegensetzen",
            sy5: "ܩܒܰܠ"
        },
        {
            sy9: "",
            dt1: "absteigen",
            lt11: "",
            sy7: "",
            sy6: "",
            sy4: "ܢܚܬ",
            dt3: "",
            lt10: "nĥeth",
            dt2: "",
            id: "5663",
            lt12: "",
            sy8: "",
            sy5: "ܢܚܶܬ݂"
        },
        {
            dt2: "",
            lt11: "ܐܒܫܠܘܡ",
            sy4: "ܐܒܫܠܘܡ",
            lt10: "ܐܒܫܠܘܡ",
            dt3: "",
            lt12: "",
            id: "113",
            sy8: "",
            dt1: "Abschalom",
            sy7: "",
            sy6: "",
            sy5: "ܐܰܒܫܰܠܳܡ",
            sy9: ""
        },
        {
            sy4: "ܕܪܥܐ ܕܣܡܠܐ",
            sy6: "",
            id: "2839",
            sy5: "ܕܪܳܥܳܐ ܕܣܶܡܳܠܳܐ",
            lt11: "",
            sy7: "",
            dt3: "",
            sy9: "",
            lt12: "",
            dt1: "linker Arm",
            sy8: "",
            lt10: "droĉo d`semolo",
            dt2: ""
        },
        {
            sy6: "",
            sy9: "",
            dt1: "Bürste",
            sy4: "ܦܪܓܘܢܐ",
            id: "746",
            sy8: "",
            lt11: "",
            dt2: "Zahnbürste",
            lt12: "",
            dt3: "",
            sy5: "ܦܰܪܓܽܘܢܳܐ",
            lt10: "farguno",
            sy7: ""
        },
        {
            sy7: "",
            lt12: "",
            dt2: "",
            sy6: "",
            id: "3376",
            dt1: "Operation",
            sy4: "ܬܥܒܕܬܐ",
            sy8: "",
            sy9: "",
            lt10: "taĉbedtho",
            lt11: "",
            sy5: "ܬܰܥܒܶܕܬ݂ܳܐ",
            dt3: ""
        },
        {
            sy4: "ܐܪܝܚ",
            lt10: "ariĥ",
            lt12: "",
            sy5: "ܐܰܪܝܺܚ",
            sy7: "",
            sy9: "",
            dt2: "",
            dt1: "stinken",
            sy6: "",
            id: "4278",
            sy8: "",
            lt11: "",
            dt3: ""
        },
        {
            lt12: "",
            sy4: "ܡܓܪܥܬܐ",
            dt1: "Rasierer",
            lt11: "magruĉitho",
            sy5: "ܡܰܓܪܰܥܬܳܐ",
            sy9: "",
            dt2: "Rasiermesser",
            lt10: "magraĉto ",
            id: "3606",
            dt3: "Rasierklinge",
            sy6: "ܡܓܪܘܥܝܬܐ",
            sy7: "ܡܰܓܪܽܘܥܽܝܬ݂ܳܐ",
            sy8: ""
        },
        {
            lt10: "ĉayno",
            lt12: "",
            sy7: "",
            sy5: "ܥܰܝܢܰܐ",
            sy6: "",
            dt3: "",
            id: "364",
            lt11: "",
            sy8: "",
            sy4: "ܥܝܢܐ",
            dt2: "",
            sy9: "",
            dt1: "Auge"
        },
        {
            dt1: "Prostituierte",
            sy4: "ܙܠܝܠܬܐ",
            id: "3527",
            sy5: "ܙܰܠܺܝܠܬܳܐ",
            sy9: "",
            lt10: "zalilto",
            lt11: "",
            sy6: "",
            lt12: "",
            sy7: "",
            sy8: "",
            dt2: "Hure",
            dt3: "Schlampe"
        },
        {
            dt2: "",
            sy6: "",
            lt12: "",
            sy5: "ܢܳܩܰܚ ܐ̱ܢܳܐ ܒܝܰܕ ܢܚܺܝܪܳܐ",
            sy9: "",
            id: "2132",
            sy4: "ܢܩܚ ܐܢܐ ܒܝܕ ܢܚܝܪܐ",
            sy8: "",
            sy7: "",
            lt10: "noqaĥ no byađ nĥiro",
            dt3: "",
            dt1: "Ich rieche mit der Nase",
            lt11: ""
        },
        {
            lt12: "",
            sy8: "",
            sy5: "ܝܶܫܘܽܥ ܡܫܺܝܚܳܐ",
            dt1: "Jesus Christus",
            sy9: "",
            sy6: "",
            sy4: "ܝܫܘܥ ܡܫܝܚܐ",
            dt2: "",
            dt3: "",
            id: "2320",
            sy7: "",
            lt11: "",
            lt10: "yeshuĉ mshiĥo"
        },
        {
            lt12: "",
            dt2: "Rezeption",
            lt11: "",
            lt10: "beth qubolo",
            sy8: "",
            sy7: "",
            dt3: "",
            sy5: "ܒܝܬ݂ ܩܽܘܒܳܠܳܐ",
            id: "5532",
            sy6: "",
            sy9: "",
            dt1: "Empfangsraum",
            sy4: "ܒܝܬ ܩܘܒܠܐ"
        },
        {
            sy8: "",
            dt1: "Thoma",
            dt3: "",
            id: "4466",
            sy5: "ܬܳܐܘܡܰܐ",
            sy7: "",
            dt2: "",
            sy9: "",
            sy4: "ܬܐܘܡܐ",
            lt10: "ܬܐܘܡܐ",
            sy6: "",
            lt12: "",
            lt11: "ܬܐܘܡܐ"
        },
        {
            dt2: "",
            sy9: "",
            lt11: "qay",
            dt3: "",
            sy6: "ܩܝ",
            lt12: "",
            sy4: "ܩܝܐ",
            dt1: "Warum",
            sy8: "",
            id: "4986",
            sy7: "ܩܰܝ",
            lt10: "qayo",
            sy5: "ܩܰܝܳܐ"
        },
        {
            sy4: "ܣܢܬܪܟ",
            lt11: "ܣܢܬܪܟ",
            id: "3830",
            sy6: "ܨܢܬܪܟ",
            lt12: "ܨܢܬܪܟ",
            sy9: "",
            dt1: "Sanatrak",
            sy5: "ܣܰܢܰܬܪܰܟ",
            sy7: "ܨܰܢܰܬܪܰܟ",
            dt3: "",
            sy8: "",
            dt2: "",
            lt10: "ܣܢܬܪܟ"
        },
        {
            id: "2805",
            sy5: "ܐܰܕܥܶܟ݂ ܒܰܗܪܳܐ",
            lt11: "adĉekh l`nuhro",
            sy9: "",
            lt12: "",
            dt1: "Licht ausmachen",
            lt10: "adĉekh bahro",
            dt2: "",
            sy8: "",
            sy7: "ܐܰܕܥܶܟ݂ ܠܢܽܘܗܪܳܐ",
            sy6: "ܐܕܥܟ ܠܢܘܗܪܐ",
            sy4: "ܐܕܥܟ ܒܗܪܐ",
            dt3: ""
        },
        {
            sy5: "ܐܘ ܓܰܒܪܝـܝܶܠ ܣܬܶܐ ܛܰܘܘܐ ܝܐ",
            lt12: "",
            "date": {
                "seconds": 1587245985,
                "nanoseconds": 64000000
            },
            sy9: "",
            dt2: "",
            dt1: "Gabriyel geht es auch gut.",
            dt3: "",
            lt10: "U Gabriyel ste ṭ­awwo yo.",
            sy6: "",
            sy4: "ܐܘ ܓܒܪܝـܝܠ ܣܬܐ ܛܘܘܐ ܝܐ",
            sy8: "",
            sy7: "",
            lt11: "",
            id: "0tdpumD5OfORNnfzThtO"
        },
        {
            lt12: "",
            lt11: "",
            dt3: "",
            sy9: "",
            sy8: "",
            sy6: "",
            id: "1240",
            dt1: "Fahrschulunterricht",
            lt10: "hergo d`dubor rađoyto",
            sy5: "ܗܶܪܓܳܐ ܕܕܽܘܒܳܪ ܪܰܕܳܝܬܳܐ",
            dt2: "",
            sy7: "",
            sy4: "ܗܪܓܐ ܕܕܘܒܪ ܪܕܝܬܐ"
        },
        {
            dt2: "ernähren",
            lt11: "",
            id: "3185",
            sy5: "ܬܰܪܣܺܝ",
            sy7: "",
            dt3: "speisen",
            sy6: "",
            sy4: "ܬܪܣܝ",
            lt12: "",
            dt1: "nähren",
            sy8: "",
            sy9: "",
            lt10: "tarsi"
        },
        {
            sy4: "ܐܘܒܕܘܬܐ",
            dt1: "Zerstörung",
            id: "6385",
            lt11: "",
            sy6: "",
            dt2: "Vernichtung",
            lt10: "ubodutho",
            sy5: "ܐܽܘܒܳܕܽܘܬ݂ܳܐ",
            dt3: "Destruktion",
            sy8: "",
            lt12: "",
            sy7: "",
            sy9: ""
        },
        {
            dt1: "Metropolit",
            sy4: "ܡܦܪܝܢܐ",
            lt10: "mafryono",
            sy5: "ܡܰܦܪܝܳܢܳܐ",
            id: "3010",
            lt12: "",
            lt11: "",
            dt3: "",
            sy6: "",
            sy8: "",
            dt2: "Katholikos",
            sy9: "",
            sy7: ""
        },
        {
            sy4: "ܦܩܘܕ",
            lt12: "",
            sy7: "",
            lt10: "ܦܩܘܕ",
            dt2: "",
            id: "3428",
            sy5: "ܦܶܩܳܘܕ",
            sy8: "",
            sy6: "",
            dt3: "",
            lt11: "ܦܩܘܕ",
            sy9: "",
            dt1: "Pekod"
        },
        {
            sy6: "",
            sy9: "",
            sy8: "",
            sy5: "ܡܰܡܠܰܟ ܪܽܘܚܳܟ݂",
            dt2: "",
            sy7: "",
            dt1: "Beherrsche deinen Geist",
            lt10: "mamlakh ruĥokh",
            sy4: "ܡܡܠܟ ܪܘܚܟ",
            lt12: "",
            id: "531",
            lt11: "",
            dt3: ""
        },
        {
            dt3: "",
            sy8: "",
            sy4: "ܚܢܢܝܐ",
            lt11: "ܚܢܢܝܐ",
            id: "1844",
            lt10: "ܚܢܢܝܐ",
            lt12: "",
            dt2: "Hananayel",
            dt1: "Hananja",
            sy9: "",
            sy6: "",
            sy5: "ܚܰܢܰܢܝܰܐ",
            sy7: ""
        },
        {
            dt2: "",
            sy4: "ܙܠܓܐ",
            id: "5252",
            lt12: "",
            sy8: "",
            sy7: "",
            lt10: "ܙܠܓܐ",
            sy6: "",
            dt3: "",
            dt1: "Zalgo",
            sy9: "",
            lt11: "ܙܠܓܐ",
            sy5: "ܙܰܠܓܳܐ"
        },
        {
            id: "1501",
            sy7: "ܠܽܘܚܺܝܬ݂ ܫܽܘܘܕܳܥܳܐ",
            lt11: "luĥith shudoĉo",
            lt10: "shudoĉ ĥushoĥo",
            sy5: "ܫܘܽܘܕܳܥ ܚܽܘܫܳܚܳܐ",
            sy4: "ܫܘܘܕܥ ܚܘܫܚܐ",
            sy8: "",
            sy9: "",
            dt2: "",
            dt1: "Gebrauchsanweisung",
            lt12: "",
            sy6: "ܠܘܚܝܬ ܫܘܘܕܥܐ",
            dt3: ""
        },
        {
            dt3: "",
            sy9: "",
            lt10: "nshab",
            sy8: "",
            sy4: "ܢܫܒ",
            sy5: "ܢܫܰܒ",
            dt1: "atmen",
            sy6: "",
            lt12: "",
            sy7: "",
            dt2: "",
            lt11: "",
            id: "334"
        },
        {
            lt12: "",
            lt11: "ܟܬܪ",
            lt10: "ܟܬܪ",
            dt2: "",
            sy6: "",
            sy7: "",
            dt3: "",
            id: "2429",
            sy4: "ܟܬܖ",
            sy8: "",
            dt1: "Katar",
            sy9: "",
            sy5: "ܟܰܬܰܖ"
        },
        {
            id: "2397",
            sy9: "",
            dt3: "",
            sy6: "",
            lt10: "gamolo",
            sy8: "",
            lt12: "",
            lt11: "",
            sy7: "",
            dt1: "Kameltreiber",
            dt2: "Kamelhirte",
            sy4: "ܓܡܠܐ",
            sy5: "ܓܰܡܳܠܳܐ"
        },
        {
            lt11: "",
            sy7: "",
            sy4: "ܩܛܪܓ",
            lt10: "qațreğ",
            sy6: "",
            sy8: "",
            sy9: "",
            dt2: "",
            lt12: "",
            dt1: "anprangern",
            dt3: "",
            id: "5985",
            sy5: "ܩܰܛܪܶܓ݂"
        },
        {
            sy8: "",
            sy9: "",
            lt12: "",
            sy7: "",
            dt2: "",
            sy6: "",
            id: "6364",
            dt3: "",
            lt10: "sedro meŝĉoyo",
            lt11: "",
            dt1: "Mittelstand",
            sy5: "ܣܶܕܪܳܐ ܡܶܨܥܳܝܳܐ",
            sy4: "ܣܕܪܐ ܡܨܥܝܐ"
        },
        {
            id: "4380",
            sy5: "ܪܶܩܕܳܐ",
            lt12: "",
            lt10: "reqđo",
            lt11: "rqođo",
            sy7: "ܪܩܳܕܳܐ",
            sy4: "ܪܩܕܐ",
            sy9: "",
            sy8: "",
            dt2: "Tanzen",
            sy6: "ܪܩܕܐ",
            dt3: "",
            dt1: "Tanz"
        },
        {
            sy4: "ܨܒܬܐ",
            lt12: "",
            sy6: "",
            sy8: "",
            id: "4086",
            dt1: "Sebto",
            lt11: "ܨܒܬܐ",
            dt3: "",
            sy9: "",
            dt2: "",
            sy7: "",
            sy5: "ܨܶܒܬܳܐ",
            lt10: "ܨܒܬܐ"
        },
        {
            lt12: "",
            dt2: "Wassernot",
            sy7: "",
            sy5: "ܣܢܺܝܩܽܘܬ݂ ܡܰܝܳܐ",
            sy8: "",
            sy6: "",
            dt1: "Wasserbedarf",
            sy9: "",
            dt3: "",
            sy4: "ܣܢܝܩܘܬ ܡܝܐ",
            lt10: "sniquth mayo",
            lt11: "",
            id: "5002"
        },
        {
            lt11: "",
            lt12: "",
            dt3: "",
            id: "4512",
            sy4: "ܛܒ ܡܘܬܐ",
            dt2: "",
            lt10: "țeb mauto",
            sy9: "",
            sy6: "",
            sy8: "",
            sy7: "",
            dt1: "Todesnachricht",
            sy5: "ܛܶܒ ܡܰܘܬܳܐ"
        },
        {
            sy8: "",
            sy7: "",
            lt10: "ܥܦܪܘܢ",
            id: "143",
            sy9: "",
            sy4: "ܥܦܪܘܢ",
            lt12: "",
            dt3: "",
            sy5: "ܥܰܦܪܳܘܢ",
            dt2: "",
            lt11: "ܥܦܪܘܢ",
            dt1: "Afron",
            sy6: ""
        },
        {
            sy6: "ܚܝܝܟ ܐܢܘܢ",
            dt2: "",
            dt3: "",
            sy7: "ܚܰܝܰܝܟ ܐܶܢܽܘܢ",
            id: "5601",
            sy8: "",
            lt12: "",
            sy9: "",
            sy4: "ܥܘܡܪܟ ܐܝܬܘܗܝ",
            sy5: "ܥܽܘܡܪܳܟ ܐܺܝܬܰܘܗܝ",
            dt1: "Es ist dein Leben",
            lt10: "ĉumrokh ithau",
            lt11: "ĥayayk enun"
        },
        {
            sy6: "",
            sy9: "",
            lt10: "falțutho",
            dt3: "",
            sy4: "ܦܠܛܘܬܐ",
            id: "1482",
            dt2: "",
            sy8: "",
            lt11: "",
            sy5: "ܦܰܠܛܽܘܬ݂ܳܐ",
            sy7: "",
            lt12: "",
            dt1: "Gasse"
        },
        {
            sy6: "",
            sy9: "",
            dt1: "Spionage",
            lt12: "",
            lt10: "goshushutho",
            id: "4220",
            dt2: "",
            sy8: "",
            dt3: "",
            lt11: "",
            sy7: "",
            sy4: "ܓܫܘܫܘܬܐ",
            sy5: "ܓܳܫܽܘܫܽܘܬ݂ܳܐ"
        },
        {
            sy4: "ܐܘܪܗܝ",
            sy5: "ܐܽܘܪܗܳܝ",
            sy7: "",
            dt2: "",
            sy9: "",
            dt1: "Urhoy",
            lt12: "",
            sy6: "",
            id: "4687",
            sy8: "",
            lt11: "ܐܘܪܗܝ",
            lt10: "ܐܘܪܗܝ",
            dt3: ""
        },
        {
            dt3: "",
            sy4: "ܟܠ ܝܘܡ",
            dt1: "jeden Tag",
            sy5: "ܟܽܠ ܝܽܘܡ",
            dt2: "",
            sy6: "",
            sy9: "",
            sy7: "",
            lt10: "kul yum",
            id: "2291",
            sy8: "",
            lt11: "",
            lt12: ""
        },
        {
            sy7: "",
            lt12: "",
            id: "4551",
            lt10: "ĥashishto",
            dt3: "",
            sy6: "",
            sy5: "ܚܰܫܺܝܫܬܳܐ",
            sy9: "",
            sy8: "",
            sy4: "ܚܫܝܫܬܐ",
            dt2: "",
            lt11: "",
            dt1: "traurige Frau"
        },
        {
            dt1: "altmodisch",
            sy9: "ܩܰܕܺܝܡܳܐ",
            id: "5870",
            lt10: "ĉatiq dumyo",
            lt12: "qadimo",
            dt2: "",
            sy7: "ܥܰܬܺܝܩ ܛܽܘܦܣܳܐ",
            sy6: "ܥܬܝܩ ܛܘܦܣܐ",
            sy5: "ܥܰܬܺܝܩ ܕܽܘܡܝܳܐ",
            sy8: "ܩܕܝܡܐ",
            lt11: "ĉatiq țufso",
            dt3: "",
            sy4: "ܥܬܝܩ ܕܘܡܝܐ"
        },
        {
            sy9: "ܐܰܡܺܝܢܳܐ",
            lt11: "ĉolam",
            id: "1208",
            dt2: "für immer",
            dt3: "",
            sy5: "ܠܥܳܠܰܡ",
            dt1: "ewig",
            sy4: "ܠܥܠܡ",
            lt12: "amino",
            sy6: "ܥܠܡ",
            sy8: "ܐܡܝܢܐ",
            lt10: "lĉolam",
            sy7: "ܥܳܠܰܡ"
        },
        {
            sy4: "ܡܢ ܡܪܚܡܢܘܬܐ ܐܫܬܘܙܒ",
            lt10: "men mraĥmonutho eshtauzab",
            lt12: "",
            lt11: "",
            sy5: "ܡܶܢ ܡܪܰܚܡܳܢܽܘܬ݂ܳܐ ܐܶܫܬܰܘܙܰܒ",
            dt1: "Aus Gnade gerettet",
            sy6: "",
            id: "373",
            sy9: "",
            dt2: "",
            sy7: "",
            dt3: "",
            sy8: ""
        },
        {
            sy7: "ܡܰܦܣܳܢܽܘܬ݂ܳܐ",
            sy6: "ܡܦܣܢܘܬܐ",
            lt12: "meshtabqonutho",
            sy5: "ܦܣܳܣܳܐ",
            dt2: "",
            sy4: "ܦܣܣܐ",
            sy8: "ܡܫܬܒܩܢܘܬܐ",
            lt10: "fsoso",
            dt3: "",
            lt11: "mafsonutho",
            id: "5762",
            dt1: "Admission",
            sy9: "ܡܶܫܬܰܒܩܳܢܽܘܬ݂ܳܐ"
        },
        {
            sy7: "",
            id: "3630",
            lt12: "",
            dt2: "",
            sy9: "",
            sy5: "ܡܰܫܪܳܢܽܘܬ݂ܳܐ",
            sy6: "",
            dt1: "Realisierung",
            sy8: "",
            lt10: "mashronutho",
            sy4: "ܡܫܪܢܘܬܐ",
            lt11: "",
            dt3: ""
        },
        {
            lt11: "",
            id: "5001",
            sy9: "",
            dt3: "",
            lt10: "rethĥo d`mayo",
            lt12: "",
            sy7: "",
            dt2: "",
            dt1: "Wasser kochen",
            sy5: "ܪܶܬ݂ܚܳܐ ܕܡܰܝܳܐ",
            sy8: "",
            sy6: "",
            sy4: "ܪܬܚܐ ܕܡܝܐ"
        },
        {
            dt3: "",
            dt1: "mittlerer",
            id: "6374",
            sy4: "ܡܨܥܝ ܒܟܘܠ",
            sy7: "",
            sy5: "ܡܶܨܥܳܝ ܒܟܽܘܠ",
            lt10: "meŝĉoy b'kul",
            lt11: "",
            sy8: "",
            sy6: "",
            lt12: "",
            dt2: "",
            sy9: ""
        },
        {
            id: "5169",
            dt2: "",
            sy8: "",
            dt1: "Wortführer",
            lt10: "mmallono",
            sy7: "",
            sy9: "",
            sy6: "",
            lt12: "",
            sy4: "ܡܡܠܠܢܐ",
            sy5: "ܡܰܡܠܳܠܢܳܐ",
            lt11: "",
            dt3: ""
        },
        {
            sy5: "ܟܳܣܳܐ ܕܪܺܝܫܳܝܽܘܬ݂ܳܐ",
            id: "3479",
            sy8: "",
            lt12: "",
            sy6: "",
            lt11: "",
            dt2: "",
            lt10: "koso d`rishoyutho",
            sy4: "ܟܣܐ ܕܪܝܫܝܘܬܐ",
            dt1: "Pokal",
            sy7: "",
            sy9: "",
            dt3: ""
        },
        {
            sy5: "ܐܳܣܝܳܐ ܕܢܶܫ̈ܶܐ",
            dt2: "",
            sy4: "ܐܣܝܐ ܕܢܫܐ",
            dt3: "",
            lt12: "",
            lt11: "",
            sy7: "",
            dt1: "Frauenarzt",
            sy6: "",
            id: "1366",
            sy9: "",
            sy8: "",
            lt10: "osyo d`neshe"
        },
        {
            sy5: "ܡܰܪܓܳܢܺܝܬ݂ܳܐ",
            sy7: "",
            dt2: "",
            sy6: "",
            lt11: "",
            sy8: "",
            lt12: "",
            dt3: "",
            sy9: "",
            sy4: "ܡܪܓܢܝܬܐ",
            lt10: "margonitho",
            id: "6151",
            dt1: "Perle"
        },
        {
            lt11: "",
            dt1: "Kopf",
            sy9: "",
            dt2: "",
            sy5: "ܪܺܝܫܳܐ",
            sy7: "",
            lt10: "risho",
            sy4: "ܪܝܫܐ",
            lt12: "",
            id: "2602",
            sy8: "",
            dt3: "",
            sy6: ""
        },
        {
            lt11: "",
            lt10: "shariroith",
            sy6: "",
            dt2: "",
            sy5: "ܫܰܪܺܝܪܳܐܺܝܬ݂",
            sy4: "ܫܪܝܪܐܝܬ",
            dt1: "wahrhaftig",
            lt12: "",
            sy9: "",
            dt3: "",
            sy8: "",
            id: "4957",
            sy7: ""
        },
        {
            sy9: "",
            lt10: "ĥamro",
            sy6: "",
            sy4: "ܚܡܪܐ",
            dt1: "Wein",
            dt2: "",
            id: "5027",
            lt11: "",
            sy5: "ܚܰܡܪܳܐ",
            lt12: "",
            sy8: "",
            dt3: "",
            sy7: ""
        },
        {
            sy7: "ܛܰܝܳܐ",
            dt2: "Verräter",
            sy8: "",
            lt11: "țayo",
            sy6: "ܛܝܐ",
            id: "3113",
            dt1: "Moslem",
            dt3: "",
            sy9: "",
            lt10: "mashlmono",
            sy4: "ܡܫܠܡܢܐ",
            lt12: "",
            sy5: "ܡܰܫܠܡܳܢܳܐ"
        },
        {
            dt3: "",
            sy7: "",
            sy8: "",
            lt12: "",
            id: "295",
            dt1: "Armband",
            sy9: "",
            dt2: "",
            lt10: "shero",
            lt11: "",
            sy4: "ܫܐܪܐ",
            sy6: "",
            sy5: "ܫܶܐܪܳܐ"
        },
        {
            lt10: "boĉyo at dtezdawağ ĉam",
            sy9: "",
            id: "5112",
            sy8: "",
            sy5: "ܒܳܥܝܳܐ ܐܰܢ̱ܬܝ ܕܬܶܙܕܰܘܰܓ݂ ܥܰܡܝ",
            dt2: "Möchtest Du mich heiraten",
            dt3: "",
            sy4: "ܒܥܝܐ ܐܢܬܝ ܕܬܙܕܘܓ ܥܡܝ",
            sy6: "ܒܥܐ ܐܢܬ ܕܬܙܕܘܓ ܥܡܝ",
            sy7: "ܒܳܥܶܐ ܐܰܢ̱ܬ ܕܬܶܙܕܰܘܰܓ݂ ܥܰܡܝ",
            dt1: "Willst du mich heiraten",
            lt12: "",
            lt11: "boĉe at dtezdawağ ĉam"
        },
        {
            lt10: "warqo d`surgođo",
            dt1: "Kalenderblatt",
            lt12: "",
            lt11: "",
            dt3: "",
            sy8: "",
            sy5: "ܘܰܪܩܳܐ ܣܽܘܪܓܳܕܳܐ",
            sy9: "",
            id: "2389",
            sy7: "",
            sy4: "ܘܪܩܐ ܕܣܘܪܓܕܐ",
            dt2: "",
            sy6: ""
        },
        {
            lt12: "",
            dt2: "",
            sy8: "",
            id: "4562",
            sy5: "ܪܦܰܣ",
            sy9: "",
            sy6: "ܪܦܣ",
            lt11: "rofes",
            sy4: "ܪܦܣ",
            lt10: "rfas",
            dt3: "",
            sy7: "ܪܳܦܶܣ",
            dt1: "treten"
        },
        {
            sy4: "ܦܣܬܐ ܕܠܩܢܐ",
            sy6: "",
            sy5: "ܦܰܣܬܳܐ ܕܠܰܩܢܶܐ",
            id: "5697",
            sy8: "",
            lt10: "fasto dlaqne",
            sy7: "",
            lt12: "",
            sy9: "",
            dt3: "",
            dt2: "",
            dt1: "Abwaschtuch",
            lt11: ""
        },
        {
            sy4: "ܒܐܝܕܐ",
            sy5: "ܒܺܐܝܕܳܐ",
            sy8: "",
            sy7: "",
            id: "2178",
            dt3: "",
            sy6: "",
            sy9: "",
            dt2: "",
            lt10: "b`iđo",
            dt1: "in der Hand",
            lt11: "",
            lt12: ""
        },
        {
            dt2: "",
            lt10: "ĥezwo",
            sy7: "ܡܰܕܺܝܩܽܘܬ݂ܳܐ",
            lt11: "madiqutho",
            sy4: "ܚܙܘܐ",
            lt12: "ĥzayto",
            dt3: "",
            sy8: "ܚܙܝܬܐ",
            sy6: "ܡܕܝܩܘܬܐ",
            id: "388",
            dt1: "Aussicht",
            sy9: "ܚܙܰܝܬܳܐ",
            sy5: "ܚܶܙܘܳܐ"
        },
        {
            id: "2330",
            sy4: "ܝܘܐܝܠ",
            lt11: "ܝܘܐܝܠ",
            sy5: "ܝܽܘܐܺܝܠ",
            lt10: "ܝܘܐܝܠ",
            sy6: "",
            dt2: "Youil",
            dt3: "",
            sy9: "",
            sy7: "",
            sy8: "",
            lt12: "",
            dt1: "Joel"
        },
        {
            sy7: "ܩܰܝܶܡ",
            dt2: "",
            sy5: "ܫܰܬܶܐܣ",
            lt11: "qayem",
            sy6: "ܩܝܡ",
            dt3: "",
            sy4: "ܫܬܐܣ",
            sy9: "",
            dt1: "konstituieren",
            lt10: "shates",
            id: "6068",
            lt12: "",
            sy8: ""
        },
        {
            lt12: "",
            lt10: "dabrono",
            dt3: "",
            sy6: "ܡܕܒܪܢܐ",
            sy5: "ܕܰܒܪܳܢܳܐ",
            sy8: "",
            dt1: "Vertreter",
            lt11: "mdabrono",
            sy9: "",
            sy4: "ܕܒܪܢܐ",
            sy7: "ܡܕܰܒܪܳܢܳܐ",
            dt2: "",
            id: "4822"
        },
        {
            sy7: "ܦܰܪܕܰܝܣܳܐ",
            lt11: "fardayso",
            sy4: "ܡܠܟܘܬܐ",
            sy9: "",
            dt1: "Paradies",
            sy6: "ܦܪܕܝܣܐ",
            lt12: "",
            sy5: "ܡܰܠܟܽܘܬ݂ܳܐ",
            sy8: "",
            dt2: "Garten Eden",
            id: "3418",
            dt3: "Königreich",
            lt10: "malkutho"
        },
        {
            lt10: "yauno",
            sy5: "ܝܰܘܢܳܐ",
            dt3: "",
            sy9: "",
            sy4: "ܝܘܢܐ",
            lt11: "",
            dt1: "Taube",
            id: "4398",
            sy6: "",
            dt2: "",
            sy8: "",
            lt12: "",
            sy7: ""
        },
        {
            lt11: "",
            sy4: "ܣܗܕܘܬ ܫܘܡܠܝܐ",
            dt2: "Abschlußzeugnis",
            sy8: "",
            lt12: "",
            lt10: "sohduth shumloyo",
            dt1: "Abschlusszeugnis",
            sy7: "",
            dt3: "",
            id: "5629",
            sy5: "ܣܳܗܕܽܘܬ݂ ܫܽܘܡܠܳܝܳܐ",
            sy6: "",
            sy9: ""
        },
        {
            sy5: "ܐܶܢܳܐ ܣܽܘܪܝܳܝܳܐ ܐ̱ܢܳܐ",
            lt10: "eno suryoyo no",
            dt2: "Ich bin Aramäer",
            id: "2099",
            sy4: "ܐܢܐ ܣܘܪܝܝܐ ܐܢܐ",
            dt3: "",
            dt1: "Ich bin ein Aramäer",
            sy8: "",
            sy9: "",
            lt11: "",
            lt12: "",
            sy6: "",
            sy7: ""
        },
        {
            dt2: "Prüfung",
            dt3: "",
            lt11: "",
            id: "4672",
            sy4: "ܥܘܩܒܐ",
            lt12: "",
            sy7: "",
            sy5: "ܥܽܘܩܳܒܳܐ",
            lt10: "ĉuqobo",
            dt1: "Untersuchung",
            sy6: "",
            sy9: "",
            sy8: ""
        },
        {
            sy5: "ܚܰܒܪܳܐ ܒܺܝܫܳܐ",
            dt2: "",
            sy7: "",
            dt1: "böser Freund",
            lt11: "",
            sy9: "",
            lt10: "ĥabro bisho",
            sy6: "",
            sy8: "",
            lt12: "",
            dt3: "",
            sy4: "ܚܒܪܐ ܒܝܫܐ",
            id: "6201"
        },
        {
            lt10: "kul shato",
            sy4: "ܟܠ ܫܢܬܐ",
            dt2: "",
            lt12: "",
            sy5: "ܟܽܠ ܫܰܢ̱ܬܳܐ",
            lt11: "shno ba`shno",
            id: "2294",
            sy7: "ܫܢܳܐ ܒܰܫܢܳܐ",
            dt3: "",
            sy8: "",
            dt1: "jedes Jahr",
            sy6: "ܫܢܐ ܒܫܢܐ",
            sy9: ""
        },
        {
            sy7: "",
            sy4: "ܐܢܘܫ",
            sy9: "",
            id: "242",
            lt10: "ܐܢܘܫ",
            lt12: "",
            lt11: "ܐܢܘܫ",
            sy6: "",
            dt2: "Anusch",
            sy8: "",
            sy5: "ܐܰܢܳܘܫ",
            dt3: "",
            dt1: "Anosch"
        },
        {
            id: "4935",
            sy8: "",
            sy5: "ܡܰܡܠܳܐ ܕܓܽܘܒܳܝܳܐ",
            lt11: "",
            dt2: "",
            lt10: "mamlo d`guboyo",
            sy6: "",
            dt3: "",
            dt1: "Wahlansprache",
            sy4: "ܡܡܠܐ ܕܓܘܒܝܐ",
            sy7: "",
            lt12: "",
            sy9: ""
        },
        {
            sy6: "",
            sy4: "ܚܛܐ",
            sy5: "ܚܛܳܐ",
            id: "4317",
            sy7: "",
            lt12: "",
            lt11: "",
            dt3: "",
            dt2: "",
            sy9: "",
            lt10: "ĥțo",
            sy8: "",
            dt1: "sündigen"
        },
        {
            lt11: "",
            sy6: "",
            sy5: "ܥܺܐܕܳܐ ܕܩܽܘܪܒܳܢܳܐ",
            dt1: "Opferfest",
            sy4: "ܥܐܕܐ ܕܩܘܪܒܢܐ",
            dt3: "",
            sy8: "",
            lt12: "",
            sy7: "",
            id: "3379",
            dt2: "",
            lt10: "ĉiđo d`qurbono",
            sy9: ""
        },
        {
            lt11: "ܓܐܝܐ",
            sy6: "",
            sy9: "",
            dt2: "",
            sy4: "ܓܐܝܐ",
            sy8: "",
            id: "1490",
            lt12: "",
            lt10: "ܓܐܝܐ",
            dt1: "Gayo",
            dt3: "",
            sy5: "ܓܰܐܝܳܐ",
            sy7: ""
        },
        {
            sy7: "ܒܶܝܬ݂ ܡܰܘܬܒܳܐ ܕܰܘܰܙܺܝ̈ܪܶܐ",
            sy9: "",
            dt2: "",
            dt1: "Parlament",
            lt11: "beth mautbo d`wazire",
            sy4: "ܡܘܬܒܐ ܕܫܘܠܛܢܐ",
            sy5: "ܡܰܘܬܒܳܐ ܕܫܽܘܠܛܳܢܳܐ",
            lt10: "mautbo d`shulțono",
            lt12: "",
            dt3: "",
            sy6: "ܒܝܬ ܡܘܬܒܐ ܕܘܙܝܪܐ",
            id: "3419",
            sy8: ""
        },
        {
            sy8: "",
            sy9: "",
            id: "3777",
            sy4: "ܫܪܥܬܐ",
            sy7: "",
            lt10: "sharĉtho",
            dt3: "",
            dt1: "Rutschen",
            lt11: "",
            sy5: "ܫܰܪܥܬ݂ܳܐ",
            lt12: "",
            sy6: "",
            dt2: ""
        },
        {
            lt12: "",
            lt10: "tshaĉmo",
            dt1: "neunhundert",
            sy8: "",
            sy6: "",
            sy5: "ܬܫܰܥܡܳܐ",
            sy7: "",
            dt3: "",
            id: "3255",
            dt2: "900",
            lt11: "",
            sy4: "ܬܫܥܡܐ",
            sy9: ""
        },
        {
            sy6: "",
            lt12: "",
            sy4: " ܡܷܢ ܟܣܝܡܝܬܘ ܗܪܟܐ؟",
            sy9: "",
            lt10: "Mën kosaymitu harke?",
            sy8: "",
            sy7: "",
            dt3: "",
            sy5: " ܡܷܢ ܟܳܣܰܝܡܝܬܘ ܗܰܪܟܶܐ؟",
            dt1: "Was macht ihr hier?",
            dt2: "",
            lt11: "",
            id: "6455"
        },
        {
            lt10: "ܣܒܝܚܐ",
            sy9: "",
            sy7: "",
            dt3: "",
            sy6: "",
            dt1: "Sabiha",
            sy4: "ܣܒܝܚܐ",
            dt2: "",
            sy8: "",
            lt11: "ܣܒܝܚܐ",
            lt12: "",
            id: "3786",
            sy5: "ܣܰܒܺܝܚܰܐ"
        },
        {
            sy4: "ܪܗܛ",
            sy6: "ܗܠܟ",
            lt12: "",
            sy9: "",
            lt10: "rheț",
            lt11: "halekh",
            dt3: "",
            sy7: "ܗܰܠܶܟ݂",
            id: "2719",
            dt2: "",
            sy8: "",
            sy5: "ܪܗܶܛ",
            dt1: "laufen"
        },
        {
            sy4: "ܫܠܡܐ",
            sy8: "",
            dt2: "Viele Grüße",
            sy6: "",
            sy9: "",
            sy7: "",
            id: "5754",
            sy5: "ܫܠܳܡ̈ܶܐ",
            lt12: "",
            dt1: "Grüße",
            lt10: "shlome",
            dt3: "",
            lt11: ""
        },
        {
            sy6: "",
            sy4: "ܐܝܪܕ",
            sy9: "",
            dt1: "Irad",
            sy8: "",
            lt12: "",
            sy7: "",
            lt10: "ܐܝܪܕ",
            dt3: "",
            sy5: "ܐܺܝܪܰܕ",
            dt2: "",
            id: "2235",
            lt11: "ܐܝܪܕ"
        },
        {
            sy9: "",
            dt2: "",
            lt11: "",
            id: "2736",
            lt12: "",
            sy7: "",
            sy6: "",
            sy8: "",
            sy4: "ܦܐܪܐ ܒܣܝܡܐ",
            lt10: "firo basimo",
            dt3: "",
            sy5: "ܦܺܐܪܳܐ ܒܰܣܺܝܡܳܐ",
            dt1: "leckere Frucht"
        },
        {
            sy4: "ܬܨܒܝܬܐ",
            dt3: "",
            lt10: "taŝbitho",
            dt2: "",
            dt1: "Dekoration",
            sy8: "",
            sy9: "",
            sy5: "ܬܰܨܒܺܝܬ݂ܳܐ",
            lt11: "ŝebtho",
            sy7: "ܨܶܒܬ݂ܳܐ",
            lt12: "",
            sy6: "ܨܒܬܐ",
            id: "849"
        },
        {
            id: "3051",
            sy4: "ܡܝܪܙܐ",
            sy5: "ܡܺܝܪܙܰܐ",
            dt2: "Mirsa",
            dt3: "",
            lt10: "ܡܝܪܙܐ",
            sy8: "",
            lt11: "ܡܝܪܙܐ",
            sy6: "",
            dt1: "Mirza",
            sy9: "",
            lt12: "",
            sy7: ""
        },
        {
            sy5: "ܠܽܘܡܳܕܳܐ",
            lt12: "mamlalto",
            lt11: "knushyo",
            sy4: "ܠܘܡܕܐ",
            dt1: "Kongress",
            dt2: "Kongreß",
            lt10: "lumodo",
            id: "6083",
            sy9: "ܡܰܡܠܰܠܬܳܐ",
            sy8: "ܡܡܠܠܬܐ",
            sy6: "ܟܢܘܫܝܐ",
            dt3: "Gipfeltreffen",
            sy7: "ܟܢܽܘܫܝܳܐ"
        },
        {
            sy6: "",
            id: "6111",
            sy9: "",
            lt12: "",
            sy7: "",
            sy8: "",
            lt11: "",
            lt10: "qđom qalil",
            sy4: "ܩܕܡ ܩܠܝܠ",
            dt3: "",
            sy5: "ܩܕܳܡ ܩܰܠܺܝܠ",
            dt2: "",
            dt1: "gerade eben"
        },
        {
            sy7: "",
            sy5: "ܕܠܳܐ ܒܶܣܪܳܐ",
            sy8: "",
            id: "85",
            lt10: "d`lo besro",
            sy9: "",
            sy4: "ܕܠܐ ܒܣܪܐ",
            lt11: "",
            lt12: "",
            dt3: "",
            dt2: "",
            sy6: "",
            dt1: "abgemagert"
        },
        {
            sy5: "ܦܩܰܕ",
            lt11: "faqed",
            sy4: "ܦܩܕ",
            sy6: "ܦܩܕ",
            dt2: "kommandieren",
            sy7: "ܦܰܩܶܕ",
            id: "524",
            dt1: "befehlen",
            sy9: "",
            dt3: "",
            sy8: "",
            lt10: "fqad",
            lt12: ""
        },
        {
            sy8: "",
            dt2: "Wassersport",
            lt12: "",
            sy7: "ܕܽܘܪܳܫܳܐ ܕܡܰܝܳܐ",
            lt10: "daroshuth mayo",
            sy6: "ܕܘܪܫܐ ܕܡܝܐ",
            sy4: "ܕܪܘܫܘܬ ܡܝܐ",
            id: "5013",
            dt3: "",
            sy5: "ܕܰܪܳܘܫܽܘܬ ܡܰܝܳܐ",
            sy9: "",
            lt11: "durosho d`mayo",
            dt1: "Wassertraining"
        },
        {
            sy7: "",
            dt2: "",
            lt10: "qliđo",
            sy9: "",
            sy4: "ܩܠܝܕܐ",
            id: "3978",
            dt3: "",
            sy5: "ܩܠܺܝܕܳܐ",
            lt11: "",
            sy8: "",
            dt1: "Schlüssel",
            lt12: "",
            sy6: ""
        },
        {
            id: "3184",
            sy8: "",
            lt10: "ܢܚܘܪ",
            lt12: "",
            dt3: "",
            sy5: "ܢܰܚܽܘܪ",
            dt1: "Nahor",
            sy4: "ܢܚܘܪ",
            lt11: "ܢܚܘܪ",
            dt2: "",
            sy7: "",
            sy9: "",
            sy6: ""
        },
        {
            sy4: "ܓܠܝܟ",
            sy9: "",
            dt3: "",
            lt10: "ܓܠܝܟ",
            sy7: "",
            lt11: "ܓܠܝܟ",
            dt2: "",
            dt1: "Celik",
            id: "759",
            sy8: "",
            sy5: "ܓܶܠܺܝܟ",
            sy6: "",
            lt12: ""
        },
        {
            sy9: "",
            sy8: "",
            lt10: "men shuroyo",
            sy6: "ܡܢ ܪܝܫ ܠܪܝܫ",
            sy4: "ܡܢ ܫܘܪܝܐ",
            sy7: "ܡܶܢ ܪܺܝܫ ܠܪܺܝܫ",
            lt12: "",
            lt11: "men rish l`rish",
            dt2: "",
            dt1: "von Anfang an",
            dt3: "",
            id: "4882",
            sy5: "ܡܶܢ ܫܽܘܪܳܝܳܐ"
        },
        {
            dt2: "",
            sy4: "ܚܒܪܐ ܛܒܐ",
            sy5: "ܚܰܒܪܳܐ ܛܳܒܳܐ",
            lt10: "ĥabro țobo",
            sy9: "",
            sy8: "",
            sy7: "",
            id: "6200",
            lt11: "",
            dt3: "",
            dt1: "guter Freund",
            sy6: "",
            lt12: ""
        },
        {
            dt1: "Tur Izlo",
            sy7: "",
            dt2: "Turo d`Izlo",
            lt11: "",
            lt12: "",
            lt10: "țur d´izlo",
            sy9: "",
            sy8: "",
            sy5: "ܛܽܘܪ ܕܐܺܝܙܠܳܐ",
            id: "4596",
            dt3: "",
            sy4: "ܛܘܪ ܐܝܙܠܐ",
            sy6: ""
        },
        {
            lt12: "",
            sy4: "ܢܩܫܐ",
            dt1: "Klopfen",
            sy8: "",
            sy9: "",
            lt11: "noqusho",
            dt3: "",
            id: "2531",
            sy6: "ܢܩܘܫܐ",
            lt10: "nqosho",
            sy5: "ܢܩܳܫܳܐ",
            dt2: "",
            sy7: "ܢܳܩܽܘܫܳܐ"
        },
        {
            sy4: "ܛܒ ܒܟܠ",
            dt1: "allerbest",
            sy9: "",
            sy6: "",
            sy5: "ܛܳܒ ܒܟܽܠ",
            sy8: "",
            lt10: "țob bkul",
            sy7: "",
            lt11: "",
            dt2: "",
            dt3: "",
            id: "5840",
            lt12: ""
        },
        {
            dt3: "",
            sy5: "ܐܰܠܳܗܳܝܳܐ",
            dt2: "",
            sy9: "",
            sy8: "",
            lt12: "",
            dt1: "göttlich",
            lt11: "alohoith",
            sy7: "ܐܰܠܳܗܳܐܝܺܬ݂",
            id: "1708",
            lt10: "alohoyo",
            sy6: "ܐܠܗܐܝܬ",
            sy4: "ܐܠܗܝܐ"
        },
        {
            sy4: "ܚܝܐ",
            sy9: "",
            id: "2727",
            lt12: "",
            sy5: "ܚܰܝ̈ܶܐ",
            lt10: "ĥaye",
            dt3: "",
            lt11: "",
            sy8: "",
            dt1: "Leben",
            sy7: "",
            dt2: "",
            sy6: ""
        },
        {
            dt1: "Kadmiel",
            sy4: "ܩܕܡܝܐܝܠ",
            dt3: "",
            sy5: "ܩܰܕܡܺܝܐܝܶܠ",
            sy8: "",
            lt11: "ܩܕܡܝܐܝܠ",
            dt2: "",
            lt12: "ܩܕܡܐܝܠ",
            sy7: "ܩܰܕܡܺܐܝܶܠ",
            sy6: "ܩܕܡܐܝܠ",
            lt10: "ܩܕܡܝܐܝܠ",
            id: "2373",
            sy9: ""
        },
        {
            sy6: "ܫܓܐ",
            lt10: "shaz",
            sy7: "ܫܓܳܐ",
            sy5: "ܫܰܙ",
            id: "5704",
            dt3: "",
            sy4: "ܫܙ",
            lt12: "",
            sy9: "",
            sy8: "",
            dt2: "",
            lt11: "shgo",
            dt1: "abweichen"
        },
        {
            sy7: "",
            sy6: "",
            lt10: "ܡܘܣܐ",
            lt11: "ܡܘܣܐ",
            dt1: "Musa",
            sy5: "ܡܽܘܣܰܐ",
            sy8: "",
            sy9: "",
            sy4: "ܡܘܣܐ",
            id: "3127",
            lt12: "",
            dt3: "",
            dt2: "Mousa"
        },
        {
            sy9: "ܚܶܣܢܳܐ",
            lt11: "mağdlo",
            sy6: "ܡܓܕܠܐ",
            dt2: "Burg",
            lt12: "ĥesno",
            id: "4602",
            sy4: "ܒܘܪܓܐ",
            dt3: "Festung",
            sy5: "ܒܽܘܪܓܳܐ",
            dt1: "Turm",
            lt10: "burgo",
            sy8: "ܚܣܢܐ",
            sy7: "ܡܰܓ݂ܕܠܳܐ"
        },
        {
            sy9: "",
            lt10: "mitho",
            id: "4531",
            sy6: "",
            sy5: "ܡܺܐܝܬ݂ܳܐ",
            lt12: "",
            dt1: "tot",
            lt11: "",
            sy8: "",
            sy7: "",
            dt2: "",
            sy4: "ܡܐܝܬܐ",
            dt3: ""
        },
        {
            sy8: "",
            dt1: "Dekorateur",
            sy5: "ܪܳܨܽܘܦܳܐ",
            dt2: "",
            sy4: "ܪܨܘܦܐ",
            lt10: "roŝufo",
            dt3: "",
            lt11: "",
            sy9: "",
            sy7: "",
            sy6: "",
            id: "847",
            lt12: ""
        },
        {
            dt3: "",
            sy4: "ܡܝܩܪܢܝܬܐ",
            sy6: "",
            lt12: "",
            id: "5917",
            lt11: "",
            sy9: "",
            dt1: "Anbeterin",
            sy8: "",
            dt2: "",
            lt10: "myaqronitho",
            sy7: "",
            sy5: "ܡܝܰܩܪܳܢܺܝܬ݂ܳܐ"
        },
        {
            sy6: "",
            sy8: "",
            id: "5884",
            dt2: "",
            sy5: "ܚܪܺܝܡ ܡܶܢ ܫܶܕܬ݂ܳܐ",
            lt12: "",
            sy7: "",
            lt11: "",
            sy4: "ܚܪܝܡ ܡܢ ܫܕܬܐ",
            sy9: "",
            dt1: "amtsenthoben",
            lt10: "ĥrim men shedtho",
            dt3: ""
        },
        {
            sy8: "",
            dt3: "",
            dt2: "Habibo",
            sy5: "ܚܰܒܺܝܒ",
            sy4: "ܚܒܝܒ",
            lt11: "ܚܒܝܒ",
            sy6: "ܚܒܝܒܐ",
            dt1: "Habib",
            lt10: "ܚܒܝܒ",
            lt12: "ܚܒܝܒܐ",
            sy7: "ܚܰܒܺܝܒܳܐ",
            sy9: "",
            id: "1794"
        },
        {
            dt2: "Fünf",
            lt10: "ĥamsho",
            lt12: "he",
            dt1: "5",
            id: "6",
            sy9: "ܗ",
            sy5: "ܚܰܡܫܳܐ",
            lt11: "ĥamesh",
            sy6: "ܚܡܫ",
            sy8: "ܗ",
            sy4: "ܚܡܫܐ",
            dt3: "",
            sy7: "ܚܰܡܶܫ"
        },
        {
            lt10: "shdoyo",
            sy8: "",
            sy6: "",
            sy4: "ܫܕܝܐ",
            sy7: "",
            dt3: "",
            lt12: "",
            lt11: "",
            sy9: "",
            dt2: "Schiessen",
            sy5: "ܫܕܳܝܳܐ",
            id: "3946",
            dt1: "Schießen"
        },
        {
            dt3: "",
            dt2: "",
            dt1: "Alterung",
            sy5: "ܣܰܝܒܽܘܬ݂ܳܐ",
            lt11: "ĉatiqutho",
            lt12: "",
            id: "5866",
            sy8: "",
            sy6: "ܥܬܝܩܘܬܐ",
            sy7: "ܥܰܬܺܝܩܽܘܬ݂ܳܐ",
            sy9: "",
            sy4: "ܣܝܒܘܬܐ",
            lt10: "saybutho"
        },
        {
            dt3: "",
            dt1: "schießen",
            sy9: "",
            lt10: "shdo",
            sy8: "",
            sy7: "",
            sy5: "ܫܕܰܐ",
            sy6: "",
            lt11: "",
            sy4: "ܫܕܐ",
            dt2: "schiessen",
            lt12: "",
            id: "3945"
        },
        {
            dt1: "Turnier",
            sy6: "",
            sy4: "ܡܘܪܝܐ",
            sy9: "",
            lt11: "",
            dt2: "Wettkampf",
            sy7: "",
            lt12: "",
            sy8: "",
            sy5: "ܡܽܘܪܳܝܳܐ",
            dt3: "",
            id: "4604",
            lt10: "muroyo"
        },
        {
            sy4: "ܐܬܐ ܕܫܘܐܠܐ",
            id: "1361",
            lt12: "",
            dt2: "",
            sy8: "",
            dt3: "",
            lt11: "",
            sy7: "",
            sy6: "",
            dt1: "Fragezeichen",
            lt10: "otho d`shuwolo",
            sy9: "",
            sy5: "ܐܳܬ݂ܳܐ ܕܫܽܘܐܳܠܳܐ"
        },
        {
            lt12: "",
            sy9: "",
            sy5: "ܢܽܘܫܳܛܳܐ",
            sy4: "ܢܘܫܛܐ",
            sy7: "",
            lt11: "",
            id: "5572",
            dt2: "",
            lt10: "nushoțo",
            dt1: "Abhäutung",
            dt3: "",
            sy8: "",
            sy6: ""
        },
        {
            id: "3452",
            lt10: "gero",
            sy7: "",
            dt3: "",
            sy6: "",
            dt2: "",
            dt1: "Pfeil",
            sy5: "ܓܶܐܪܳܐ",
            sy8: "",
            sy4: "ܓܐܪܐ",
            sy9: "",
            lt11: "",
            lt12: ""
        },
        {
            id: "1216",
            sy9: "",
            sy6: "",
            sy5: "ܡܰܚܪܰܡܬܳܐ",
            lt11: "",
            sy4: "ܡܚܪܡܬܐ",
            lt10: "maĥramto",
            sy7: "",
            sy8: "",
            lt12: "",
            dt1: "Exkommunizierte",
            dt3: "",
            dt2: ""
        },
        {
            sy5: "ܡܚܳܐ",
            id: "4785",
            lt12: "",
            lt10: "mĥo",
            sy6: "",
            sy7: "",
            dt2: "hauen",
            dt3: "",
            sy4: "ܡܚܐ",
            dt1: "verprügeln",
            sy9: "",
            lt11: "",
            sy8: ""
        },
        {
            lt11: "",
            sy6: "",
            sy4: "ܟܘܪܟܡܐ",
            sy8: "",
            lt12: "",
            lt10: "kurkmo",
            sy9: "",
            id: "3798",
            dt1: "Safran",
            dt3: "",
            dt2: "Krokus",
            sy7: "",
            sy5: "ܟܽܘܪܟܡܳܐ"
        },
        {
            dt1: "angefeuchtet",
            sy9: "",
            sy6: "ܪܛܝܒܐ",
            lt11: "rațibo",
            dt3: "",
            sy8: "",
            sy4: "ܬܪܝܐ",
            dt2: "",
            sy5: "ܬܰܪܝܳܐ",
            id: "5924",
            lt12: "",
            sy7: "ܪܰܛܺܝܒܳܐ",
            lt10: "taryo"
        },
        {
            sy9: "",
            dt2: "",
            dt1: "rechtzeitig",
            sy5: "ܥܶܕܳܢܳܝܳܐ",
            sy8: "",
            dt3: "",
            id: "3648",
            sy4: "ܥܕܢܝܐ",
            sy6: "",
            lt10: "ĉedonoyo",
            sy7: "",
            lt12: "",
            lt11: ""
        },
        {
            dt3: "",
            sy5: "ܡܰܕܪܰܫܬܳܐ ܪܰܡܫܳܝܬܳܐ",
            lt12: "",
            lt10: "madrashto ramshoyto",
            sy4: "ܡܕܪܫܬܐ ܪܡܫܝܬܐ",
            dt2: "",
            sy6: "",
            sy8: "",
            id: "5562",
            sy9: "",
            sy7: "",
            lt11: "",
            dt1: "Abendschule"
        },
        {
            lt10: "ܐܕܪܐ",
            sy4: "ܐܕܪܐ",
            lt11: "ܐܕܪܐ",
            sy5: "ܐܳܕܪܳܐ",
            sy8: "",
            sy9: "",
            dt1: "Odro",
            id: "3342",
            dt2: "",
            sy7: "",
            lt12: "",
            dt3: "",
            sy6: ""
        },
        {
            lt10: "ܐܗܪܘܢ",
            dt1: "Aaron",
            sy4: "ܐܗܪܘܢ",
            sy6: "",
            lt11: "ܐܗܪܘܢ",
            sy8: "",
            id: "54",
            dt2: "Ahrun",
            sy9: "",
            dt3: "Aron",
            sy7: "",
            sy5: "ܐܰܗܪܽܘܢ",
            lt12: ""
        },
        {
            lt12: "",
            dt3: "",
            id: "4493",
            dt2: "",
            lt11: "ܬܝܢܐ",
            sy7: "",
            lt10: "ܬܝܢܐ",
            sy6: "",
            sy9: "",
            sy8: "",
            sy5: "ܬܺܝܢܰܐ",
            sy4: "ܬܝܢܐ",
            dt1: "Tina"
        },
        {
            dt2: "",
            sy9: "",
            lt11: "",
            sy5: "ܒܶܣܪܳܐ",
            dt3: "",
            lt12: "",
            sy6: "",
            sy8: "",
            dt1: "Fleisch",
            id: "1328",
            sy7: "",
            lt10: "besro",
            sy4: "ܒܣܪܐ"
        },
        {
            sy9: "ܐܶܠܡܳܐ",
            id: "6301",
            sy7: "ܣܰܢܝܽܘܬ݂ܳܐ",
            sy4: "ܣܢܝܐ",
            lt12: "elmo",
            dt1: "Zorn",
            dt2: "",
            sy8: "ܐܠܡܐ",
            sy5: "ܣܢܳܝܳܐ",
            sy6: "ܣܢܝܘܬܐ",
            lt10: "snoyo",
            dt3: "",
            lt11: "sanyutho"
        },
        {
            sy4: "ܐܓܘܢܐ",
            sy9: "",
            lt10: "ağuno",
            sy7: "",
            sy8: "",
            dt1: "Wettkampf",
            lt11: "",
            dt3: "Wettstreit",
            sy6: "",
            sy5: "ܐܰܓ݂ܽܘܢܳܐ",
            lt12: "",
            dt2: "Wettbewerb",
            id: "5080"
        },
        {
            sy4: "ܐܘܝܘܬܐ",
            lt10: "ܐܘܝܘܬܐ",
            sy6: "",
            sy7: "",
            dt2: "",
            dt1: "Auyutha",
            sy9: "",
            sy5: "ܐܰܘܝܽܘܬܰܐ",
            dt3: "",
            lt11: "ܐܘܝܘܬܐ",
            lt12: "",
            sy8: "",
            id: "400"
        },
        {
            dt3: "",
            sy9: "",
            sy4: "ܡܦܓܕܢܐ",
            dt1: "Bremser",
            lt10: "mfagđono",
            id: "700",
            lt11: "",
            sy7: "",
            sy6: "",
            dt2: "",
            lt12: "",
            sy5: "ܡܦܰܓܕܳܢܳܐ",
            sy8: ""
        },
        {
            sy8: "",
            sy9: "",
            id: "53",
            sy7: "",
            sy6: "",
            lt11: "",
            lt12: "",
            dt2: "",
            sy4: "ܐ",
            lt10: "ftoĥo",
            dt1: "a",
            sy5: "ܐܰ",
            dt3: ""
        },
        {
            sy9: "",
            lt11: "",
            sy6: "",
            sy4: "ܒܝܫܘܬܐ",
            sy8: "",
            dt2: "Böses",
            sy7: "",
            lt12: "",
            dt1: "Bosheit",
            sy5: "ܒܺܝܫܽܘܬ݂ܳܐ",
            lt10: "bishutho",
            id: "683",
            dt3: "Schlechtigkeit"
        },
        {
            sy9: "",
            dt1: "Esau",
            dt3: "Isu",
            lt12: "",
            lt11: "ܥܝܣܘ",
            lt10: "ܥܝܣܘ",
            sy7: "",
            sy6: "",
            sy5: "ܥܺܝܣܽܘ",
            sy8: "",
            sy4: "ܥܝܣܘ",
            dt2: "Esav",
            id: "1187"
        },
        {
            sy9: "",
            dt3: "",
            id: "4507",
            lt10: "ܛܒܘܬܐ",
            sy6: "",
            sy4: "ܛܒܘܬܐ",
            dt1: "Tobuta",
            sy8: "",
            lt11: "ܛܒܘܬܐ",
            dt2: "",
            sy7: "",
            lt12: "",
            sy5: "ܛܳܒܽܘܬܰܐ"
        },
        {
            sy4: "ܘܟܝܠܐ ܕܡܬܠܚܡܢܘܬܐ",
            id: "2219",
            dt1: "Integrationsbeauftragter",
            lt11: "",
            dt3: "",
            lt10: "wakilo d`methlaĥmonutho",
            sy6: "",
            sy9: "",
            sy8: "",
            lt12: "",
            sy5: "ܘܰܟܝܠܳܐ ܕܡܶܬ݂ܠܰܚܡܳܢܽܘܬ݂ܳܐ",
            dt2: "",
            sy7: ""
        },
        {
            lt11: "",
            id: "919",
            sy7: "",
            dt1: "Dorf",
            sy6: "",
            sy4: "ܩܪܝܬܐ",
            sy8: "",
            sy9: "",
            dt2: "",
            lt10: "qritho",
            lt12: "",
            dt3: "",
            sy5: "ܩܪܺܝܬ݂ܳܐ"
        },
        {
            sy6: "",
            dt3: "",
            sy4: "ܪܟܒܐ",
            lt11: "",
            dt2: "",
            sy5: "ܪܟ݂ܳܒܳܐ",
            sy7: "",
            dt1: "Reiten",
            sy8: "",
            sy9: "",
            id: "3682",
            lt10: "rkhobo",
            lt12: ""
        },
        {
            dt1: "Ministerpräsident",
            dt3: "",
            sy9: "",
            lt10: "rish waziro",
            lt12: "",
            sy5: "ܪܺܝܫ ܘܰܙܺܝܪܳܐ",
            id: "3046",
            sy7: "",
            sy8: "",
            sy6: "",
            lt11: "",
            sy4: "ܪܝܫ ܘܙܝܪܐ",
            dt2: "Präsident"
        },
        {
            dt1: "Edip",
            sy6: "ܐܕܝܒ",
            dt3: "Adib",
            sy4: "ܐܕܝܒ",
            lt12: "ܐܕܝܒ",
            lt11: "ܐܕܝܒ",
            sy7: "ܐܶܕܺܝܒ",
            lt10: "ܐܕܝܒ",
            sy9: "",
            sy5: "ܐܰܕܺܝܒ",
            id: "1000",
            sy8: "",
            dt2: "Adip"
        },
        {
            lt11: "",
            lt12: "",
            id: "352",
            sy6: "",
            sy9: "",
            sy8: "",
            dt3: "",
            sy7: "",
            sy5: "ܕܶܢܚܳܐ",
            lt10: "denĥo",
            dt2: "Erscheinung",
            sy4: "ܕܢܚܐ",
            dt1: "Aufgang"
        },
        {
            lt11: "",
            sy4: "ܐܒܕܢܐ",
            sy9: "",
            lt12: "",
            dt1: "Verdammnis",
            dt3: "Verderben",
            dt2: "ewige Verdammnis",
            sy7: "",
            lt10: "abdono",
            sy6: "",
            sy8: "",
            sy5: "ܐܰܒܕܳܢܳܐ",
            id: "6383"
        },
        {
            dt2: "",
            sy8: "",
            sy9: "",
            dt1: "Berufe",
            sy7: "",
            dt3: "",
            lt11: "",
            sy5: "ܐܽܘܡܳܢ̈ܶܐ",
            id: "558",
            lt10: "umone",
            sy6: "",
            lt12: "",
            sy4: "ܐܘܡܢܐ"
        },
        {
            lt11: "",
            sy4: "ܫܥܝܐ ܕܩܘܩܐ",
            sy7: "",
            sy5: "ܫܶܥܝܳܐ ܕܩܽܘܩܰܐ",
            dt2: "",
            lt10: "sheĉyo d`quqa",
            lt12: "",
            dt3: "",
            id: "2444",
            sy8: "",
            sy9: "",
            dt1: "Kegelspiel",
            sy6: ""
        },
        {
            sy7: "",
            id: "4527",
            sy8: "",
            sy4: "ܣܪܛܐ ܕܢܘܦܐ",
            dt1: "Torlinie",
            dt3: "",
            sy9: "",
            lt11: "",
            sy5: "ܣܶܪܛܳܐ ܕܢܰܘܦܳܐ",
            lt10: "serțo d`naufo",
            dt2: "",
            sy6: "",
            lt12: ""
        },
        {
            sy4: "ܐܪܢܒܐ",
            lt11: "",
            dt1: "Hase",
            sy8: "",
            dt3: "",
            dt2: "Kaninchen",
            lt12: "",
            sy7: "",
            sy5: "ܐܰܪܢܒܳܐ",
            sy6: "",
            sy9: "",
            lt10: "arnbo",
            id: "1879"
        },
        {
            dt3: "",
            id: "5727",
            sy7: "",
            sy5: "ܫܘܳܪܳܐ",
            sy6: "",
            sy8: "",
            dt1: "Achswelle",
            sy4: "ܫܘܪܐ",
            lt10: "shworo",
            lt12: "",
            dt2: "",
            sy9: "",
            lt11: ""
        },
        {
            sy6: "ܬܩ",
            lt11: "taw-qof",
            dt1: "500",
            dt3: "",
            lt12: "",
            id: "42",
            sy9: "",
            sy5: "ܚܰܡܶܫܡܳܐܐ",
            sy4: "ܚܡܫܡܐܐ",
            dt2: "Fünfhundert",
            lt10: "ĥameshmo",
            sy8: "",
            sy7: "ܬܩ"
        },
        {
            sy8: "",
            id: "756",
            sy9: "",
            sy7: "",
            dt2: "Feldlager",
            lt10: "mashritho",
            sy4: "ܡܫܪܝܬܐ",
            dt1: "Camping",
            sy5: "ܡܰܫܪܺܝܬ݂ܳܐ",
            dt3: "",
            sy6: "",
            lt11: "",
            lt12: ""
        },
        {
            dt2: "Feryal",
            lt11: "ܦܪܝܐܠ",
            sy9: "",
            sy8: "",
            sy7: "",
            dt1: "Ferjal",
            sy5: "ܦܶܪܝܰܐܠ",
            sy4: "ܦܪܝܐܠ",
            id: "1289",
            dt3: "",
            lt12: "",
            sy6: "",
            lt10: "ܦܪܝܐܠ"
        },
        {
            dt1: "Frohe Ostern euch allen",
            sy7: "ܥܺܐܕܳܐ ܒܪܺܝܟ݂ܳܐ ܠܟܽܠܟ݂ܽܘܢ",
            dt3: "Frohes Fest euch allen",
            lt11: "ĉiđo brikho  lkulkhun",
            lt12: "",
            id: "1404",
            lt10: "ĉiđo brikho ĉal lkulkhun",
            sy9: "",
            dt2: "Frohe Weihnachten euch allen",
            sy5: "ܥܺܐܕܳܐ ܒܪܺܝܟ݂ܳܐ ܥܰܠ ܠܟܽܠܟ݂ܽܘܢ",
            sy8: "",
            sy6: "ܥܐܕܐ ܒܪܝܟ݂ܐ ܠܟܠܟ݂ܘܢ",
            sy4: "ܥܐܕܐ ܒܪܝܟ݂ܐ ܥܠ ܠܟܠܟ݂ܘܢ"
        },
        {
            lt12: "",
            sy6: "",
            sy8: "",
            dt3: "",
            sy7: "",
            dt1: "Militärdiktatur",
            lt11: "",
            sy4: "ܫܘܠܛܢ ܦܠܚܘܬܐ",
            lt10: "shulțon folĥutho",
            id: "3030",
            sy9: "",
            sy5: "ܫܽܘܠܛܳܢ ܦܳܠܚܽܘܬ݂ܳܐ",
            dt2: "Militärregierung"
        },
        {
            sy9: "",
            lt11: "ܨܒܝܬܐ",
            id: "3860",
            sy4: "ܨܒܝܬܐ",
            sy8: "",
            sy7: "",
            dt2: "",
            dt1: "Sbita",
            lt12: "",
            sy6: "",
            dt3: "",
            lt10: "ܨܒܝܬܐ",
            sy5: "ܨܒܺܝܬܰܐ"
        },
        {
            sy6: "ܡܬܬܒܪܢܐ",
            dt2: "",
            sy8: "ܡܬܦܟܟܢܐ",
            lt11: "methtabrono",
            sy4: "ܡܬܓܙܙܢܐ",
            lt10: "methgazzono",
            sy7: "ܡܶܬ݂ܬܰܒܪܳܢܳܐ",
            sy9: "ܡܶܬ݂ܦܰܟܟܳܢܳܐ",
            id: "6274",
            dt3: "",
            sy5: "ܡܶܬ݂ܓܰܙܙܳܢܳܐ",
            dt1: "zerbrechlich",
            lt12: "methfakkono"
        },
        {
            dt3: "",
            lt10: "ĥabrutho",
            sy9: "",
            id: "1395",
            dt1: "Freundschaft",
            sy7: "",
            sy5: "ܚܰܒܪܽܘܬ݂ܳܐ",
            lt11: "",
            sy8: "",
            dt2: "",
            lt12: "",
            sy6: "",
            sy4: "ܚܒܪܘܬܐ"
        },
        {
            sy6: "ܐܪܥܐ",
            sy4: "ܥܦܪܐ",
            sy7: "ܐܰܪܥܳܐ",
            id: "1140",
            lt12: "",
            lt10: "ĉafro",
            sy8: "",
            sy5: "ܥܰܦܪܳܐ",
            dt2: "",
            lt11: "arĉo",
            dt1: "Erde",
            sy9: "",
            dt3: ""
        },
        {
            sy4: "ܬܐܚܐ ܣܘܪܝܝܐ",
            sy8: "",
            id: "4488",
            dt1: "Tiĥe Suryoye",
            sy9: "",
            sy6: "ܬܐܚܐ ܣܘܪܝܝܐ",
            lt10: "Lang lebe die Suryoye",
            sy7: "ܬܺܐܚܶܐ ܣܽܘܪܝܳܝ̈ܶܐ",
            lt11: "Hoch lebe die Suryoye",
            dt3: "",
            lt12: "",
            dt2: "Tihe Suryoye",
            sy5: "ܬܺܐܚܶܐ ܣܽܘܪܝܳܝ̈ܶܐ"
        },
        {
            sy6: "",
            lt12: "",
            dt2: "",
            dt3: "",
            sy7: "",
            sy8: "",
            sy9: "",
            sy5: "ܗܺܝ ܐܺܝܬ݂ ܠܳܗ ܦܠܶܦܠܳܐ",
            lt10: "hi ith lo fleflo",
            dt1: "Sie hat eine Paprika",
            sy4: "ܗܝ ܐܝܬ ܠܗ ܦܠܦܠܐ",
            lt11: "",
            id: "4145"
        },
        {
            sy9: "",
            dt2: "",
            sy7: "",
            dt1: "Tornade",
            dt3: "",
            sy5: "ܬܳܘܪܢܰܕܳܐ",
            sy8: "",
            sy4: "ܬܘܪܢܕܐ",
            lt10: "tornado",
            id: "4528",
            lt11: "",
            sy6: "",
            lt12: ""
        },
        {
            dt1: "abzweigen",
            lt10: "sarĉef",
            sy7: "",
            sy5: "ܣܰܪܥܶܦ",
            sy8: "",
            lt12: "",
            dt2: "",
            id: "5723",
            dt3: "",
            sy4: "ܣܪܥܦ",
            lt11: "",
            sy6: "",
            sy9: ""
        },
        {
            sy7: "",
            dt3: "",
            sy4: "ܝܒܠ",
            lt10: "ܝܒܠ",
            sy9: "",
            sy8: "",
            lt12: "",
            lt11: "ܝܒܠ",
            dt1: "Jabal",
            sy5: "ܝܰܒܰܠ",
            sy6: "",
            id: "2261",
            dt2: ""
        },
        {
            lt11: "arğuzo",
            dt1: "Zorn",
            id: "5349",
            lt10: "ruğzo",
            sy8: "ܣܢܐܬܐ",
            sy9: "ܣܶܢܶܐܬ݂ܳܐ",
            sy4: "ܪܘܓܙܐ",
            sy5: "ܪܽܘܓ݂ܙܳܐ",
            sy6: "ܐܪܓܘܙܐ",
            dt2: "Wut",
            sy7: "ܐܰܪܓ݂ܽܘܙܳܐ",
            dt3: "",
            lt12: "ßenetho"
        },
        {
            sy7: "ܐܰܚܫܶܡ",
            sy6: "ܐܚܫܡ",
            sy5: "ܚܫܶܡ",
            dt1: "zu Abend essen",
            id: "5350",
            lt10: "ĥshem",
            sy8: "ܐܟܠ",
            dt3: "",
            dt2: "essen",
            sy9: "ܐܳܟ݂ܶܠ",
            lt12: "okhel",
            sy4: "ܚܫܡ",
            lt11: "aĥshem"
        },
        {
            dt3: "",
            lt11: "",
            id: "3802",
            sy7: "",
            dt1: "Säge",
            lt12: "",
            sy5: "ܡܰܣܳܪܳܐ",
            sy4: "ܡܣܪܐ",
            sy6: "",
            dt2: "",
            sy8: "",
            sy9: "",
            lt10: "masoro"
        },
        {
            sy9: "",
            sy5: "ܓܰܝܳܣܳܐ",
            lt10: "gayoso",
            dt2: "Plünderer",
            sy4: "ܓܝܣܐ",
            sy8: "",
            dt1: "Räuber",
            sy6: "",
            id: "3619",
            lt11: "",
            lt12: "",
            dt3: "Bandit",
            sy7: ""
        },
        {
            sy5: "ܛܽܘܒܰܢܰܐ",
            lt12: "",
            sy6: "",
            dt1: "Tubana",
            lt11: "ܛܘܒܢܐ",
            id: "4588",
            sy4: "ܛܘܒܢܐ",
            dt3: "",
            sy7: "",
            dt2: "",
            sy8: "",
            sy9: "",
            lt10: "ܛܘܒܢܐ"
        },
        {
            dt3: "",
            lt11: "",
            lt10: "qol gabro",
            dt1: "Männerstimme",
            sy5: "ܩܳܠ ܓܰܒܪܳܐ",
            sy9: "",
            sy6: "",
            sy7: "",
            sy4: "ܩܠ ܓܒܪܐ",
            lt12: "",
            sy8: "",
            id: "2921",
            dt2: ""
        },
        {
            sy9: "",
            sy8: "",
            sy7: "",
            dt2: "",
            dt3: "",
            lt12: "",
            lt10: "kursyo d`makthbo",
            sy5: "ܟܽܘܪܣܝܳܐ ܕܡܰܟܬ݂ܒܳܐ",
            id: "745",
            sy6: "",
            dt1: "Bürostuhl",
            lt11: "",
            sy4: "ܟܘܪܣܝܐ ܕܡܟܬܒܐ"
        },
        {
            sy8: "",
            dt2: "",
            id: "4396",
            sy5: "ܕܽܘܓܳܐ",
            lt11: "",
            sy6: "",
            dt1: "taub",
            sy9: "",
            sy4: "ܕܘܓܐ",
            lt10: "dugo",
            dt3: "",
            lt12: "",
            sy7: ""
        },
        {
            lt11: "",
            lt10: "methraĉlono",
            sy7: "",
            id: "6296",
            sy4: "ܡܬܪܥܠܢܐ",
            sy9: "",
            sy8: "",
            sy6: "",
            lt12: "",
            dt2: "",
            sy5: "ܡܶܬ݂ܪܰܥܠܳܢܳܐ",
            dt3: "",
            dt1: "zittrig"
        },
        {
            sy6: "",
            lt10: "lbushe rushmoye",
            id: "5887",
            sy9: "",
            dt2: "",
            sy8: "",
            lt12: "",
            lt11: "",
            dt1: "Amtsgewand",
            dt3: "",
            sy5: "ܠܒܽܘܫܶܐ ܪܽܘܫܡܳܝܶܐ",
            sy4: "ܠܒܘܫܐ ܪܘܫܡܝܐ",
            sy7: ""
        },
        {
            sy4: "ܡܕܝܬܐ",
            sy8: "",
            lt11: "",
            id: "4235",
            sy6: "",
            sy9: "",
            dt3: "",
            sy5: "ܡܕܺܝܬ݂ܳܐ",
            lt10: "mditho",
            dt1: "Stadt",
            sy7: "",
            dt2: "",
            lt12: ""
        },
        {
            id: "4625",
            dt1: "Uhrmacher repariert Uhren",
            dt2: "",
            sy8: "",
            lt11: "",
            sy5: "ܫܳܥܬ݂ܳܢܳܝܳܐ ܡܬܰܪܶܨ ܫܳܥ̈ܶܐ",
            sy7: "",
            lt10: "shoĉtonoyo mtareŝ shoĉe",
            lt12: "",
            dt3: "",
            sy4: "ܫܥܬܢܝܐ ܡܬܪܨ ܫܥܐ",
            sy6: "",
            sy9: ""
        },
        {
            id: "2358",
            sy8: "",
            lt10: "ܝܘܠܝܢܐ",
            dt1: "Juliana",
            sy5: "ܝܘܠܝܰܢܰܐ",
            dt3: "Julyana",
            sy9: "",
            sy7: "",
            sy6: "",
            dt2: "Yulyana",
            lt11: "ܝܘܠܝܢܐ",
            lt12: "",
            sy4: "ܝܘܠܝܢܐ"
        },
        {
            sy4: "ܫܬܝܐ ܡܦܝܓܢܐ",
            sy5: "ܫܬܳܝܳܐ ܡܦܰܝܓܳܢܳܐ",
            dt1: "alkoholfreies Getränk",
            sy8: "",
            sy7: "",
            lt11: "",
            sy6: "",
            dt3: "",
            id: "5825",
            lt12: "",
            sy9: "",
            dt2: "",
            lt10: "shtoyo mfaygono"
        },
        {
            sy7: "",
            lt10: "nosubo",
            dt1: "Nehmer",
            id: "3238",
            sy8: "",
            sy4: "ܢܣܘܒܐ",
            sy5: "ܢܳܣܽܘܒܳܐ",
            sy6: "",
            lt12: "",
            dt2: "",
            dt3: "",
            lt11: "",
            sy9: ""
        },
        {
            dt1: "Gleichheit",
            sy9: "",
            lt11: "",
            lt10: "shauyutho",
            sy8: "",
            lt12: "",
            sy5: "ܫܰܘܝܽܘܬ݂ܳܐ",
            dt2: "",
            dt3: "",
            sy4: "ܫܘܝܘܬܐ",
            sy7: "",
            sy6: "",
            id: "1658"
        },
        {
            sy9: "",
            dt3: "",
            sy6: "",
            lt12: "",
            id: "6421",
            dt1: "versöhnbar",
            lt11: "",
            sy7: "",
            sy8: "",
            lt10: "meshtaynono",
            sy4: "ܡܫܬܝܢܢܐ",
            sy5: "ܡܶܫܬܰܝܢܳܢܳܐ",
            dt2: ""
        },
        {
            sy8: "",
            sy7: "",
            id: "2506",
            sy4: "ܕܒܘܩܐ",
            sy9: "",
            lt11: "",
            sy5: "ܕܰܒܽܘܩܳܐ",
            sy6: "",
            lt12: "",
            dt1: "klebrig",
            dt3: "",
            dt2: "",
            lt10: "dabuqo"
        },
        {
            dt2: "",
            lt11: "",
            dt1: "wer im Licht wandelt",
            dt3: "",
            sy6: "",
            sy5: "ܟܽܠ ܐܰܝܢܳܐ ܕܒܢܽܘܗܪܳܐ ܡܗܰܠܶܟ݂",
            lt12: "",
            sy7: "",
            sy4: "ܟܠ ܐܝܢܐ ܕܒܢܘܗܪܐ ܡܗܠܟ",
            sy9: "",
            id: "5068",
            lt10: "kul ayno da`bnuhro malekh",
            sy8: ""
        },
        {
            lt10: "mekhlo u`mashtyo",
            lt11: "",
            sy7: "",
            dt2: "",
            sy5: "ܡܶܐܟ݂ܠܳܐ ܘܡܰܫܬܝܳܐ",
            id: "1195",
            dt3: "",
            lt12: "",
            sy6: "",
            sy8: "",
            sy9: "",
            dt1: "Essen und Trinken",
            sy4: "ܡܐܟܠܐ ܘܡܫܬܝܐ"
        },
        {
            sy6: "",
            sy4: "ܡܬܪܐ ܡܪܒܥܐ",
            sy7: "",
            lt11: "",
            id: "3566",
            lt12: "",
            sy5: "ܡܶܬܪܳܐ ܡܪܰܒܥܳܐ",
            dt2: "",
            dt1: "Quadratmeter",
            sy8: "",
            dt3: "",
            lt10: "metro mrabĉo",
            sy9: ""
        },
        {
            lt11: "ܢܩܕܘܬܐ",
            sy9: "",
            lt12: "",
            id: "3201",
            sy6: "",
            dt1: "Naqduta",
            sy7: "",
            dt3: "",
            sy4: "ܢܩܕܘܬܐ",
            sy5: "ܢܰܩܕܽܘܬܰܐ",
            dt2: "",
            sy8: "",
            lt10: "ܢܩܕܘܬܐ"
        },
        {
            sy6: "",
            lt12: "",
            sy5: "ܨܶܨܳܐ",
            sy7: "",
            sy9: "",
            dt2: "",
            lt11: "",
            sy4: "ܨܨܐ",
            sy8: "",
            dt1: "Nagel",
            lt10: "ŝeŝo",
            dt3: "",
            id: "3178"
        },
        {
            dt3: "",
            dt2: "",
            dt1: "Besatzung",
            id: "564",
            sy7: "",
            sy5: "ܟܒܳܫܳܐ",
            lt12: "",
            sy6: "",
            sy4: "ܟܒܫܐ",
            sy9: "",
            sy8: "",
            lt11: "",
            lt10: "kbosho"
        },
        {
            sy8: "ܚܪܝܒܐ",
            sy4: "ܬܒܝܪܐ",
            lt11: "ĥariwo",
            sy9: "ܚܰܪܺܝܒܳܐ",
            id: "2409",
            dt1: "kaputt",
            sy6: "ܚܪܝܐܘܐ",
            sy5: "ܬܒܺܝܪܳܐ",
            sy7: "ܚܰܪܺܝܐܘܳܐ",
            lt10: "tbiro",
            lt12: "ĥaribo",
            dt3: "",
            dt2: "defekt"
        },
        {
            sy8: "",
            lt12: "",
            sy9: "",
            lt11: "odshoyo",
            dt2: "",
            sy7: "ܐܳܕܫܳܝܳܐ",
            sy4: "ܡܬܟܪܟܢܐ",
            id: "5699",
            sy6: "ܐܕܫܝܐ",
            dt3: "",
            dt1: "abwechselnd",
            lt10: "methkarkhono",
            sy5: "ܡܶܬ݂ܟܰܪܟ݂ܳܢܳܐ"
        },
        {
            sy7: "",
            sy6: "",
            sy4: "ܡܩܪܒܢܐ",
            sy8: "",
            sy9: "",
            dt2: "",
            lt10: "maqrbono",
            sy5: "ܡܰܩܪܒܳܢܳܐ",
            lt11: "",
            dt1: "Krieger",
            dt3: "",
            id: "2647",
            lt12: ""
        },
        {
            dt3: "",
            sy9: "",
            id: "528",
            sy6: "",
            lt10: "mlaoyono",
            sy7: "",
            sy4: "ܡܠܘܝܢܐ",
            lt12: "",
            lt11: "",
            sy5: "ܡܠܰܘܝܳܢܳܐ",
            dt2: "Anhänger",
            sy8: "",
            dt1: "Begleiter"
        },
        {
            sy4: "ܕܢܝܣ",
            lt11: "ܕܢܝܣ",
            id: "862",
            sy5: "ܕܶܢܺܝܣ",
            sy7: "",
            sy9: "",
            sy6: "",
            lt12: "",
            dt1: "Dennis",
            dt3: "",
            lt10: "ܕܢܝܣ",
            dt2: "Denis",
            sy8: ""
        },
        {
            sy7: "",
            lt10: "ĥziron",
            lt12: "",
            sy4: "ܚܙܝܪܢ",
            sy6: "",
            sy8: "",
            dt2: "",
            sy5: "ܚܙܺܝܪܳܢ",
            dt1: "Juni",
            sy9: "",
            dt3: "",
            lt11: "",
            id: "2366"
        },
        {
            dt1: "anbraten",
            lt10: "aqli",
            sy7: "",
            dt3: "",
            lt11: "",
            sy5: "ܐܰܩܠܺܝ",
            sy8: "",
            dt2: "",
            sy6: "",
            id: "5935",
            lt12: "",
            sy9: "",
            sy4: "ܐܩܠܝ"
        },
        {
            sy9: "",
            lt12: "",
            lt11: "manyono",
            dt2: "",
            sy5: "ܡܰܢܳܝܳܐ",
            lt10: "manoyo",
            sy8: "",
            dt1: "Zähler",
            sy4: "ܡܢܝܐ",
            id: "5229",
            sy6: "ܡܢܝܢܐ",
            sy7: "ܡܰܢܝܳܢܳܐ",
            dt3: ""
        },
        {
            sy5: "ܚܰܝܠܳܐ ܕܰܟܝܳܢܳܝܳܐ",
            sy8: "",
            lt12: "",
            dt3: "",
            lt10: "ĥaylo da`kyonoyo",
            sy9: "",
            sy4: "ܚܝܠܐ ܕܟܝܢܝܐ",
            lt11: "",
            sy7: "",
            id: "3223",
            sy6: "",
            dt2: "",
            dt1: "Naturgewalt"
        },
        {
            lt11: "ĉobuduth țobotho",
            lt10: "mraĥmonutho",
            dt1: "Wohltat",
            sy4: "ܡܪܚܡܢܘܬܐ",
            sy7: "ܥܳܒܽܘܕܽܘܬ݂ ܛܳܒܳܬ݂ܳܐ",
            sy6: "ܥܒܘܕܘܬ ܛܒܬܐ",
            id: "5156",
            sy5: "ܡܪܰܚܡܳܢܽܘܬ݂ܳܐ",
            dt2: "",
            sy9: "",
            sy8: "",
            dt3: "",
            lt12: ""
        },
        {
            lt10: "ܐܣܬܪ",
            lt11: "ܐܣܬܪ",
            sy5: "ܐܶܣܬܶܪ",
            sy4: "ܐܣܬܪ",
            sy7: "",
            dt2: "Ester",
            dt3: "",
            lt12: "",
            id: "1197",
            sy6: "",
            dt1: "Esther",
            sy9: "",
            sy8: ""
        },
        {
            lt11: "abne d`aloho",
            sy7: "ܐܰܒ̈ܢܶܐ ܕܰܐܠܳܗܳܐ",
            lt10: "țloye d`aloho",
            sy5: "ܛܠܳܝ̈ܶܐ ܕܰܐܠܳܗܳܐ",
            sy9: "",
            dt2: "",
            sy6: "ܐܒܢܐ ܕܐܠܗܐ",
            lt12: "",
            id: "2473",
            sy4: "ܛܠܝܐ ܕܐܠܗܐ",
            dt1: "Kinder Gottes",
            sy8: "",
            dt3: ""
        },
        {
            sy7: "",
            sy5: "ܫܽܘܒܚܳܐ",
            sy8: "",
            dt2: "",
            sy4: "ܫܘܒܚܐ",
            dt3: "",
            sy6: "",
            lt10: "ܫܘܡܚܐ",
            sy9: "",
            lt12: "",
            dt1: "Schubho",
            lt11: "ܫܘܡܚܐ",
            id: "4034"
        },
        {
            sy9: "",
            sy4: "ܢܨܝܚ",
            id: "3210",
            lt12: "",
            sy7: "",
            dt2: "Nasiho",
            sy8: "",
            lt11: "ܢܨܝܚ",
            sy5: "ܢܰܨܝܺܚ",
            sy6: "",
            lt10: "ܢܨܝܚ",
            dt3: "",
            dt1: "Nasih"
        },
        {
            id: "1678",
            dt1: "Gnade",
            dt2: "Güte",
            dt3: "Barmherzigkeit",
            sy4: "ܛܝܒܘܬܐ",
            sy5: "ܛܰܝܒܽܘܬ݂ܳܐ",
            sy8: "",
            lt12: "",
            lt10: "țaybutho",
            sy7: "",
            lt11: "",
            sy9: "",
            sy6: ""
        },
        {
            sy4: "ܡܟܝܟܐ",
            lt11: "",
            sy7: "",
            lt12: "",
            dt1: "demütig",
            sy6: "",
            lt10: "makikho",
            dt3: "",
            dt2: "",
            sy8: "",
            sy5: "ܡܰܟܺܝܟ݂ܳܐ",
            sy9: "",
            id: "857"
        },
        {
            dt1: "Gesundheitszustand",
            id: "1608",
            sy6: "",
            lt11: "",
            lt10: "aykanoyuth ĥulmono",
            sy8: "",
            sy7: "",
            dt3: "",
            lt12: "",
            sy4: "ܐܝܟܢܝܘܬ ܚܘܠܡܢܐ",
            dt2: "",
            sy5: "ܐܰܝܟܰܢܳܝܽܘܬ݂ ܚܽܘܠܡܳܢܳܐ",
            sy9: ""
        },
        {
            sy8: "",
            sy6: "",
            sy9: "",
            lt12: "",
            lt10: "ĥur l`qudmo",
            sy4: "ܚܘܪ ܠܩܘܕܡܐ",
            dt3: "",
            dt2: "",
            sy7: "",
            dt1: "guck nach vorne",
            sy5: "ܚܽܘܪ ܠܩܽܘܕܡܳܐ",
            lt11: "",
            id: "1759"
        },
        {
            dt3: "",
            sy9: "",
            sy5: "ܚܰܒܺܝܒܬܰܐ",
            sy7: "",
            sy8: "",
            sy4: "ܚܒܝܒܬܐ",
            lt12: "",
            lt11: "ܚܒܝܒܬܐ",
            id: "1795",
            dt1: "Habibta",
            dt2: "Habibto",
            lt10: "ܚܒܝܒܬܐ",
            sy6: ""
        },
        {
            sy6: "",
            id: "5065",
            sy4: "ܩܠܝܠ",
            sy7: "",
            lt12: "",
            dt3: "",
            lt11: "",
            sy8: "",
            sy5: "ܩܰܠܺܝܠ",
            lt10: "qalil",
            dt1: "wenig",
            sy9: "",
            dt2: "bißchen"
        },
        {
            lt11: "kautho",
            dt2: "die Fenster",
            lt12: "kawe",
            dt1: "Fenster",
            id: "1286",
            sy4: "ܟܘܐ",
            sy7: "ܟܰܘܬ݂ܳܐ",
            dt3: "",
            sy5: "ܟܰܘܳܐ",
            sy9: "ܟܰܘ̈ܶܐ",
            lt10: "kawo",
            sy6: "ܟܘܬܐ",
            sy8: "ܟܘ̈ܐ"
        },
        {
            sy5: "ܦܶܬܓ݂ܳܡܳܐ ܫܽܘܐܳܠܳܝܳܐ",
            dt3: "",
            id: "1360",
            sy9: "",
            sy4: "ܦܬܓܡܐ ܫܘܐܠܝܐ",
            sy6: "",
            lt10: "fetğomo shuwoloyo",
            lt12: "",
            sy8: "",
            lt11: "",
            sy7: "",
            dt2: "",
            dt1: "Fragesatz"
        },
        {
            sy6: "",
            id: "3968",
            dt2: "Sleymun",
            sy8: "",
            sy4: "ܫܠܝܡܘܢ",
            lt10: "ܫܠܝܡܘܢ",
            sy9: "",
            lt12: "",
            sy5: "ܫܠܶܝܡܽܘܢ",
            lt11: "ܫܠܝܡܘܢ",
            sy7: "",
            dt3: "",
            dt1: "Schleymun"
        },
        {
            lt10: "lamfido",
            lt12: "",
            id: "2717",
            sy6: "ܫܪܓܐ",
            dt1: "Laterne",
            sy7: "ܫܪܳܓ݂ܳܐ",
            lt11: "shroğo",
            sy4: "ܠܡܦܐܕܐ",
            dt2: "",
            dt3: "",
            sy5: "ܠܰܡܦܺܐܕܳܐ",
            sy9: "",
            sy8: ""
        },
        {
            dt1: "Vergleich",
            sy7: "ܡܦܰܚܡܽܘܬ݂ܳܐ",
            lt12: "methfaĥmonutho",
            sy4: "ܦܘܚܡܐ",
            sy8: "ܡܬܦܚܡܢܘܬܐ",
            dt2: "",
            id: "4740",
            lt11: "mfaĥmutho",
            sy9: "ܡܶܬ݂ܦܰܚܡܳܢܽܘܬ݂ܳܐ",
            sy6: "ܡܦܚܡܘܬܐ",
            dt3: "",
            sy5: "ܦܽܘܚܳܡܳܐ",
            lt10: "fuĥomo"
        },
        {
            lt10: "asirutho",
            id: "4721",
            sy9: "",
            sy7: "ܐܶܣܳܪܳܐ",
            sy6: "ܐܣܪܐ",
            dt1: "Verbindung",
            dt3: "",
            sy8: "",
            dt2: "",
            lt11: "esoro",
            sy4: "ܐܣܝܪܘܬܐ",
            lt12: "",
            sy5: "ܐܰܣܺܝܪܽܘܬ݂ܳܐ"
        },
        {
            sy4: "ܥܡܪ ܥܡ ܚܕܕܐ",
            dt3: "",
            dt2: "Zusammensein",
            lt10: "ĉmar ĉam ĥđođe",
            sy6: "",
            sy9: "",
            sy8: "",
            sy5: "ܥܡܰܪ ܥܰܡ ܚܕܳܕܶܐ",
            id: "5381",
            lt12: "",
            lt11: "",
            sy7: "",
            dt1: "Zusammenleben"
        },
        {
            lt12: "",
            id: "2520",
            sy6: "",
            sy4: "ܬܓܪܐ ܙܥܘܪܐ",
            sy9: "",
            sy5: "ܬܰܓܳܪܳܐ ܙܥܽܘܪܳܐ",
            lt10: "tagoro sĉuro",
            dt3: "",
            lt11: "",
            dt1: "Kleinunternehmer",
            sy7: "",
            sy8: "",
            dt2: ""
        },
        {
            dt2: "",
            dt3: "",
            lt12: "",
            id: "3098",
            sy5: "ܡܳܪܝ̱ ܝܽܘܚܰܢܳܢ ܐܰܒܪܳܗܳܡ",
            lt10: "ܡܪܝ ܝܘܚܢܢ ܐܒܪܗܡ",
            sy8: "",
            sy6: "",
            sy9: "",
            sy7: "",
            dt1: "Mor Yuhanon Abrohom",
            lt11: "ܡܪܝ ܝܘܚܢܢ ܐܒܪܗܡ",
            sy4: "ܡܪܝ ܝܘܚܢܢ ܐܒܪܗܡ"
        },
        {
            lt12: "",
            lt10: "nahiro",
            id: "2221",
            sy8: "",
            sy9: "",
            dt1: "intelligent",
            sy6: "ܚܟܝܡܐ",
            dt2: "schlau",
            sy7: "ܚܰܟܺܝܡܳܐ",
            lt11: "ĥakimo",
            sy4: "ܢܗܝܪܐ",
            sy5: "ܢܰܗܺܝܪܳܐ   ",
            dt3: ""
        },
        {
            lt10: "sweden",
            dt2: "",
            sy5: "ܣܘܶܝܕܶܝܢ",
            sy6: "",
            sy4: "ܣܘܝܕܝܢ",
            sy7: "",
            lt12: "",
            lt11: "",
            dt1: "Schweden",
            sy9: "",
            dt3: "",
            id: "4069",
            sy8: ""
        },
        {
            dt2: "",
            lt10: "shoraĉ",
            sy6: "",
            id: "3776",
            sy4: "ܫܪܥ",
            sy7: "",
            sy8: "",
            sy9: "",
            lt12: "",
            dt1: "rutschen",
            sy5: "ܫܳܪܰܥ",
            dt3: "",
            lt11: ""
        },
        {
            lt11: "",
            dt2: "Vermögen",
            lt12: "",
            id: "3672",
            sy4: "ܥܬܝܪܘܬܐ",
            sy6: "",
            sy5: "ܥܰܬܺܝܪܽܘܬ݂ܳܐ",
            dt1: "Reichtum",
            sy7: "",
            sy9: "",
            lt10: "ĉatirutho",
            sy8: "",
            dt3: ""
        },
        {
            sy5: "ܪܳܡܝܶܫܽܘܥ",
            sy7: "",
            lt11: "ܪܡܝܫܘܥ",
            sy9: "",
            dt1: "Romjeschu",
            lt12: "",
            id: "3742",
            sy8: "",
            lt10: "ܪܡܝܫܘܥ",
            dt2: "",
            dt3: "",
            sy4: "ܪܡܝܫܘܥ",
            sy6: ""
        },
        {
            lt12: "",
            sy9: "",
            sy7: "",
            dt1: "August",
            sy8: "",
            id: "370",
            dt3: "",
            dt2: "",
            lt11: "",
            sy4: "ܐܒ",
            sy6: "",
            lt10: "ob",
            sy5: "ܐܳܒ"
        },
        {
            sy6: "",
            lt11: "ܐܕܡ",
            id: "3341",
            sy9: "",
            sy5: "ܐܳܕܳܡ",
            dt2: "",
            sy7: "",
            sy8: "",
            lt12: "",
            dt3: "",
            lt10: "ܐܕܡ",
            sy4: "ܐܕܡ",
            dt1: "Odom"
        },
        {
            sy4: "ܩܪܒܐ",
            lt11: "takhtusho",
            sy8: "",
            sy9: "",
            id: "2645",
            lt12: "",
            sy5: "ܩܪܳܒܳܐ",
            dt3: "",
            dt2: "Kampf",
            sy7: "ܬܰܟ݂ܬܽܘܫܳܐ",
            dt1: "Krieg",
            lt10: "qrobo",
            sy6: "ܬܟܬܘܫܐ"
        },
        {
            id: "2705",
            dt1: "Länge",
            sy6: "ܐܘܪܟܐ",
            dt3: "",
            lt12: "",
            lt10: "arikhutho",
            sy9: "",
            sy4: "ܐܪܝܟܘܬܐ",
            sy8: "",
            lt11: "urkho",
            dt2: "",
            sy7: "ܐܽܘܪܟ݂ܳܐ",
            sy5: "ܐܰܪܺܝܟ݂ܽܘܬܳܐ"
        },
        {
            sy8: "",
            sy5: "ܦܽܘܪܫܳܢ ܛܺܝܡܶܐ",
            lt11: "",
            lt10: "furshon țime",
            id: "3508",
            sy9: "",
            sy4: "ܦܘܪܫܢ ܛܝܡܐ",
            dt2: "",
            dt3: "",
            dt1: "Preisunterschied",
            sy6: "",
            sy7: "",
            lt12: ""
        },
        {
            lt11: "ܠܘܝܬܐ",
            sy5: "ܠܶܘܺܝܬܰܐ",
            lt12: "",
            sy8: "",
            sy4: "ܠܘܝܬܐ",
            dt3: "",
            sy6: "",
            dt2: "",
            dt1: "Levita",
            id: "2797",
            sy9: "",
            sy7: "",
            lt10: "ܠܘܝܬܐ"
        },
        {
            lt11: "beth knushto",
            dt2: "",
            sy4: "ܟܢܘܫܬܐ",
            id: "4333",
            dt1: "Synagoge",
            dt3: "",
            lt10: "knushto",
            sy5: "ܟܢܽܘܫܬܳܐ",
            sy7: "ܒܶܝܬ݂ ܟܢܽܘܫܬܳܐ",
            sy9: "",
            sy6: "ܒܝܬ ܟܢܘܫܬܐ",
            lt12: "",
            sy8: ""
        },
        {
            id: "369",
            lt11: "",
            sy6: "",
            sy7: "",
            dt2: "",
            lt10: "augsburg",
            sy5: "ܐܰܘܓܣܒܽܘܪܓ",
            sy9: "",
            dt1: "Augsburg",
            lt12: "",
            sy4: "ܐܘܓܣܒܘܪܓ",
            dt3: "",
            sy8: ""
        },
        {
            sy8: "",
            sy6: "",
            sy7: "",
            lt12: "",
            lt10: "bar shmo",
            sy9: "",
            lt11: "",
            id: "3199",
            dt1: "Namensvetter",
            sy5: "ܒܰܪ ܫܡܰܐ",
            dt3: "",
            dt2: "",
            sy4: "ܒܪ ܫܡܐ"
        },
        {
            lt10: "duktho",
            id: "3473",
            dt3: "",
            sy6: "",
            lt11: "",
            dt2: "",
            sy8: "",
            sy9: "",
            sy5: "ܕܽܘܟܬ݂ܳܐ",
            lt12: "",
            sy4: "ܕܘܟܬܐ",
            sy7: "",
            dt1: "Platz"
        },
        {
            sy9: "",
            sy8: "",
            id: "2351",
            sy6: "",
            dt3: "",
            dt2: "",
            sy7: "",
            sy5: "ܥܠܰܝܡܳܐ",
            lt11: "",
            dt1: "Jugendlicher",
            sy4: "ܥܠܝܡܐ",
            lt12: "",
            lt10: "ĉlaymo"
        },
        {
            sy5: "ܚܰܝܰܐ",
            lt12: "",
            id: "1826",
            dt1: "Haja",
            sy7: "",
            sy6: "",
            sy9: "",
            sy4: "ܚܝܐ",
            lt10: "ܚܝܐ",
            dt3: "",
            lt11: "ܚܝܐ",
            dt2: "",
            sy8: ""
        },
        {
            dt3: "",
            sy6: "",
            lt12: "",
            dt1: "Privatadresse",
            lt11: "",
            dt2: "Privatanschrift",
            id: "3514",
            lt10: "farshağno dilonoyo",
            sy4: "ܦܪܫܓܢܐ ܕܝܠܢܝܐ",
            sy8: "",
            sy7: "",
            sy9: "",
            sy5: "ܦܰܪܫܰܓ݂ܢܳܐ ܕܺܝܠܳܢܳܝܳܐ"
        },
        {
            dt2: "",
            dt1: "Lexikon",
            lt12: "",
            sy5: "ܠܶܟܣܺܝܩܳܘܢ",
            sy7: "",
            dt3: "",
            sy4: "ܠܟܣܝܩܘܢ",
            sy6: "",
            id: "2798",
            lt11: "",
            sy8: "",
            lt10: "leksikon",
            sy9: ""
        },
        {
            dt2: "",
            sy4: "ܡܫܬܡܗܢܐ",
            lt12: "",
            lt11: "simo",
            dt3: "",
            lt10: "meshtamhono",
            id: "6234",
            sy6: "ܙܝܡܐ",
            sy5: "ܡܶܫܬܰܡܗܳܢܳܐ",
            sy7: "ܙܺܝܡܳܐ",
            dt1: "Subjekt",
            sy9: "",
            sy8: ""
        },
        {
            sy9: "",
            sy5: "ܬܽܘܡܰܐ",
            lt10: "ܬܘܡܐ",
            lt11: "ܬܘܡܐ",
            id: "4592",
            sy8: "",
            dt1: "Tuma",
            dt2: "",
            sy7: "",
            dt3: "",
            lt12: "",
            sy4: "ܬܘܡܐ",
            sy6: ""
        },
        {
            lt10: "ܢܝܢܘܪܬܐ",
            dt3: "",
            sy4: "ܢܝܢܘܪܬܐ",
            id: "3281",
            sy8: "",
            sy7: "ܢܺܝܢܽܘܪܬܰܐ",
            lt12: "ܢܝܢܘܪܬܐ",
            sy6: "ܢܝܢܘܪܬܐ",
            lt11: "ܢܝܢܘܪܬܐ",
            sy9: "",
            dt1: "Ninorta",
            sy5: "ܢܺܝܢܳܘܪܬܰܐ",
            dt2: ""
        },
        {
            lt12: "",
            sy6: "",
            sy8: "",
            dt1: "Kalenderjahr",
            sy7: "",
            lt10: "shato d`surgođo",
            id: "2390",
            sy4: "ܫܬܐ ܕܣܘܪܓܕܐ",
            dt2: "",
            sy9: "",
            dt3: "",
            lt11: "",
            sy5: "ܫܬܳܐ ܕܣܽܘܪܓܕܐ"
        },
        {
            sy9: "",
            sy5: "ܝܽܘܐܳܪܳܫ",
            lt12: "ܝܐܝܪܘܣ",
            sy8: "",
            lt11: "ܝܘܐܪܫ",
            sy7: "ܝܰܐܺܝܪܽܘܣ",
            sy6: "ܝܐܝܪܘܣ",
            dt2: "",
            sy4: "ܝܘܐܪܫ",
            dt1: "Jairus",
            lt10: "ܝܘܐܪܫ",
            dt3: "",
            id: "2282"
        },
        {
            sy8: "",
            lt10: "maĥruqitho",
            dt1: "Hundehalsband",
            sy9: "",
            id: "6035",
            sy4: "ܡܚܪܘܩܝܬܐ",
            dt2: "",
            sy6: "",
            dt3: "",
            sy7: "",
            lt11: "",
            sy5: "ܡܰܚܪܽܘܩܺܝܬ݂ܳܐ",
            lt12: ""
        },
        {
            lt10: "țobo no",
            lt12: "",
            lt11: "",
            sy7: "",
            dt2: "",
            sy5: "ܛܳܒܳܐ ܐ̱ܢܳܐ",
            id: "3050",
            sy9: "",
            dt3: "",
            dt1: "Mir geht es gut",
            sy8: "",
            sy4: "ܛܒܐ ܐܢܐ",
            sy6: ""
        },
        {
            lt12: "",
            sy9: "",
            lt11: "",
            dt3: "",
            sy4: "ܙܪܥܐ ܐܝܬܘܗܝ ܡܠܬܐ",
            sy5: "ܙܰܪܥܳܐ ܐܺܝܬ݂ܰܘܗܝ ܡܶܠܬ݂ܳܐ",
            lt10: "zarĉo ithaw meltho",
            sy7: "",
            sy8: "",
            dt2: "",
            sy6: "",
            id: "821",
            dt1: "Das Saatgut ist das Wort"
        },
        {
            lt10: "nolo",
            lt12: "",
            sy9: "",
            sy6: "",
            lt11: "",
            sy5: "ܢܳܐܠܳܐ",
            dt2: "",
            sy8: "",
            dt3: "",
            sy7: "",
            id: "192",
            sy4: "ܢܐܠܐ",
            dt1: "Alptraum"
        },
        {
            lt10: "mshadrono",
            sy9: "ܫܳܠܽܘܚܳܐ",
            dt2: "Abgesandter",
            dt3: "Sender",
            sy4: "ܡܫܕܪܢܐ",
            lt11: "meshtadrono",
            sy8: "ܫܠܘܚܐ",
            dt1: "Absender",
            id: "117",
            sy6: "ܡܫܬܕܪܢܐ",
            sy7: "ܡܶܫܬܰܕܪܳܢܳܐ",
            sy5: "ܡܫܰܕܪܳܢܳܐ",
            lt12: "sholuĥo"
        },
        {
            sy5: "ܟܳܗܢܳܐ",
            sy7: "ܩܰܫܳܐ",
            sy6: "ܩܫܐ",
            sy4: "ܟܗܢܐ",
            dt3: "",
            dt1: "Priester",
            sy9: "",
            sy8: "",
            dt2: "Pastor",
            id: "3509",
            lt12: "",
            lt11: "qasho",
            lt10: "kohno"
        },
        {
            dt3: "",
            id: "1172",
            lt11: "",
            sy9: "",
            dt2: "",
            lt10: "kđirutho",
            sy7: "",
            lt12: "",
            sy8: "",
            dt1: "Erschöpfung",
            sy5: "ܟܕܺܝܪܽܘܬ݂ܳܐ",
            sy4: "ܟܕܝܪܘܬܐ",
            sy6: ""
        },
        {
            lt12: "",
            sy5: "ܩܰܬܺܝܬܽܘܬ݂ܳܐ",
            sy7: "",
            lt10: "qatitutho",
            dt2: "",
            lt11: "",
            sy6: "",
            id: "5964",
            sy8: "",
            sy9: "",
            dt1: "Anmeldung",
            sy4: "ܩܬܝܬܘܬܐ",
            dt3: ""
        },
        {
            sy9: "",
            sy6: "",
            dt2: "",
            sy8: "",
            dt1: "Yadido",
            sy7: "",
            sy4: "ܝܕܝܕܐ",
            lt11: "ܝܕܝܕܐ",
            lt12: "",
            id: "5185",
            sy5: "ܝܰܕܺܝܕܳܐ",
            lt10: "ܝܕܝܕܐ",
            dt3: ""
        },
        {
            sy7: "ܝܰܒܫܳܢܳܐ",
            sy8: "",
            dt2: "Dürre",
            lt12: "",
            sy6: "ܝܒܫܢܐ",
            lt11: "yabshono",
            id: "4573",
            sy9: "",
            sy5: "ܝܰܒܺܝܫܽܘܬ݂ܳܐ",
            dt1: "Trockenheit",
            lt10: "yabishutho",
            dt3: "",
            sy4: "ܝܒܝܫܘܬܐ"
        },
        {
            sy5: "ܒܕܽܘܟ",
            sy8: "",
            lt12: "",
            lt11: "",
            sy6: "",
            dt3: "",
            sy4: "ܒܕܘܟ",
            sy9: "",
            id: "5899",
            sy7: "",
            dt1: "an einer Stelle",
            dt2: "",
            lt10: "bduk"
        },
        {
            sy9: "",
            dt2: "",
            lt10: "turto",
            lt11: "",
            id: "2657",
            sy5: "ܬܽܘܪܬܳܐ",
            dt3: "",
            sy4: "ܬܘܪܬܐ",
            sy8: "",
            lt12: "",
            dt1: "Kuh",
            sy7: "",
            sy6: ""
        },
        {
            lt10: "ܩܝܠܠܝܬܐ",
            lt12: "",
            dt3: "",
            sy7: "",
            dt1: "Killita",
            lt11: "ܩܝܠܠܝܬܐ",
            sy5: "ܩܺܝܠܠܺܝܬܰܐ",
            sy4: "ܩܝܠܠܝܬܐ",
            id: "2468",
            sy9: "",
            sy6: "",
            dt2: "",
            sy8: ""
        },
        {
            sy5: "ܡܓܰܕܦܳܢܽܘܬ݂ܳܐ",
            dt3: "",
            sy6: "ܓܘܕܦܐ",
            sy9: "",
            lt11: "gudofo",
            dt2: "",
            id: "1705",
            sy4: "ܡܓܕܦܢܘܬܐ",
            lt10: "mgadfonutho",
            lt12: "",
            dt1: "Gotteslästerung",
            sy7: "ܓܽܘܕܳܦܳܐ",
            sy8: ""
        },
        {
            id: "974",
            sy5: "ܐܰܢ̱ܬ ܟܳܬ݂ܶܒ ܐܰܢ̱ܬ",
            sy6: "ܐܢܬܝ ܟܬܒܐ ܐܢܬܝ",
            dt3: "",
            dt1: "Du schreibst",
            lt11: "at kothbo at",
            sy9: "",
            sy7: "ܐܰܢ̱ܬܝ̱ ܟܳܬ݂ܒܳܐ ܐܰܢ̱ܬܝ̱",
            sy8: "",
            sy4: "ܐܢܬ ܟܬܒ ܐܢܬ",
            lt12: "",
            dt2: "Du bist am schreiben",
            lt10: "at kotheb at"
        },
        {
            lt10: "dekhronoyo",
            lt12: "",
            dt2: "maskulin",
            dt1: "männlich",
            sy9: "",
            sy8: "",
            dt3: "",
            sy4: "ܕܟܪܢܝܐ",
            sy5: "ܕܶܟ݂ܪܳܢܳܝܳܐ",
            lt11: "",
            sy7: "",
            sy6: "",
            id: "2922"
        },
        {
            dt1: "Gehe zur Tafel",
            sy6: "",
            lt12: "",
            sy7: "",
            dt2: "",
            dt3: "",
            sy4: "ܙܠ ܠܘܬ ܠܘܚܐ",
            lt10: "sel lwoth luĥo",
            sy8: "",
            id: "1529",
            lt11: "",
            sy5: "ܙܶܠ ܠܳܘܬ ܠܽܘܚܳܐ",
            sy9: ""
        },
        {
            lt11: "",
            sy4: "ܡܨܷܬ ܐܘܥܕܐ ܥܠ ܐܘ ܡܡܠܐ ܕܗܘܝ ܒܝܢܬܝـܝܗ",
            dt2: "",
            lt12: "",
            sy7: "",
            sy9: "",
            sy5: "ܡܰܨܷܬ݂ ܐܘܥܕܐ ܥܰܠ ܐܘ ܡܰܡܠܐ ܕܗܰܘܝ ܒܰܝܢܳܬ݂ܰܝـܝܶܗ",
            "date": {
                "seconds": 1587244707,
                "nanoseconds": 230000000
            },
            sy8: "",
            sy6: "",
            dt1: "Hören Sie nun das Gespräch zwischen ihnen",
            lt10: "Maṣëṯ ucdo cal u mamlo d hawi baynoṯayye",
            id: "3HusdmiZWNwBdmSlzdOB",
            dt3: ""
        },
        {
            sy5: "ܫܳܥ̈ܶܐ",
            id: "4624",
            sy4: "ܫܥܐ",
            sy9: "",
            dt3: "",
            sy8: "",
            dt2: "Stunden",
            lt11: "",
            sy6: "",
            sy7: "",
            dt1: "Uhren",
            lt12: "",
            lt10: "shoĉe"
        },
        {
            sy4: "ܦܬܘܪܝܬܐ",
            lt11: "",
            sy8: "",
            sy9: "",
            dt2: "",
            dt1: "Pilze",
            dt3: "",
            sy6: "",
            sy7: "",
            id: "3463",
            lt12: "",
            lt10: "faturyotho",
            sy5: "ܦܰܬܽܘܪܝܳܬ݂ܳܐ"
        },
        {
            sy8: "",
            sy9: "",
            dt3: "",
            lt12: "",
            sy7: "",
            id: "969",
            dt2: "",
            lt10: "at ithayk bleb",
            sy6: "",
            lt11: "",
            sy5: "ܐܰܢ̱ܬܝ ܐܺܝܬ݂ܰܝܟܝ ܒܠܶܒܝ",
            dt1: "Du bist in meinem Herzen",
            sy4: "ܐܢܬܝ ܐܝܬܝܟܝ ܒܠܒܝ"
        },
        {
            id: "1788",
            sy4: "ܚܒܩܘܩ",
            sy5: "ܚܰܒܰܩܽܘܩ",
            dt3: "",
            sy8: "",
            lt10: "ܚܒܩܘܩ",
            lt11: "ܚܒܩܘܩ",
            sy9: "",
            lt12: "ܚܒܩܘܩ",
            dt1: "Habakuk",
            sy6: "ܚܒܩܘܩ",
            dt2: "",
            sy7: "ܚܰܒܩܽܘܩ"
        },
        {
            sy5: "ܐܺܝܠܳܢ ܐܰܪܙܳܐ",
            dt2: "",
            sy4: "ܐܝܠܢ ܐܪܙܐ",
            sy8: "",
            lt11: "",
            sy9: "",
            sy6: "",
            dt1: "Zederbaum",
            lt12: "",
            sy7: "",
            dt3: "",
            lt10: "ilon arzo",
            id: "5271"
        },
        {
            dt3: "",
            lt11: "",
            sy4: "ܡܟܘܝܢܐ",
            lt12: "",
            lt10: "mkauwyono",
            dt1: "Bügler",
            dt2: "",
            sy7: "",
            sy5: "ܡܟܰܘܝܳܢܳܐ",
            id: "737",
            sy9: "",
            sy6: "",
            sy8: ""
        },
        {
            sy8: "",
            dt3: "",
            sy6: "",
            sy9: "",
            sy5: "ܦܰܪܫܰܓ݂ܢܳܐ",
            sy4: "ܦܪܫܓܢܐ",
            dt1: "Adresse",
            lt11: "",
            lt10: "farshağno",
            lt12: "",
            dt2: "Anschrift",
            sy7: "",
            id: "138"
        },
        {
            id: "6351",
            sy8: "",
            dt1: "Mittelafrika",
            lt10: "afriqi meŝĉoyto",
            lt12: "",
            lt11: "",
            sy7: "",
            sy9: "",
            sy6: "",
            sy5: "ܐܰܦܪܺܝܩܺܝ ܡܶܨܥܳܝܬܳܐ",
            dt3: "",
            sy4: "ܐܦܪܝܩܝ ܡܨܥܝܬܐ",
            dt2: "Zentralafrika"
        },
        {
            dt3: "",
            lt12: "",
            sy4: "ܟܬܒ ܦܪܫܓܢܐ",
            sy9: "",
            dt2: "",
            id: "5757",
            sy7: "",
            sy8: "",
            dt1: "adressieren",
            sy6: "",
            lt10: "kthab farshagno",
            sy5: "ܟܬ݂ܰܒ ܦܰܪܫܰܓܢܳܐ",
            lt11: ""
        },
        {
            sy9: "",
            dt2: "",
            lt11: "fulĥon arĉo",
            id: "2703",
            sy4: "ܙܪܥܘܬܐ",
            sy7: "ܦܽܘܠܚܳܢ ܐܰܪܥܳܐ",
            dt3: "",
            sy5: "ܙܰܪܳܥܽܘܬ݂ܳܐ",
            sy6: "ܦܘܠܚܢ ܐܪܥܐ",
            lt10: "saroĉutho",
            sy8: "",
            lt12: "",
            dt1: "Landwirtschaft"
        },
        {
            sy4: "ܝܪܘܚܡ",
            lt12: "ܝܪܚܘܡ",
            dt1: "Jeroham",
            dt3: "",
            sy6: "ܝܪܚܘܡ",
            sy5: "ܝܰܪܽܘܚܰܡ",
            sy9: "",
            lt11: "ܝܪܘܚܡ",
            sy7: "ܝܰܪܚܽܘܡ",
            id: "2305",
            dt2: "",
            sy8: "",
            lt10: "ܝܪܘܚܡ"
        },
        {
            sy6: "ܬܫ",
            id: "44",
            lt12: "",
            dt1: "700",
            sy8: "",
            sy9: "",
            sy4: "ܫܒܥܡܐܐ",
            lt11: "taw-shin",
            sy7: "ܬܫ",
            dt3: "",
            dt2: "Siebenhundert",
            sy5: "ܫܒܰܥܡܳܐܐ ",
            lt10: "shbaĉmo"
        },
        {
            lt12: "qurdĥono",
            dt1: "Hündchen",
            sy8: "ܩܘܪܕܚܢܐ",
            sy5: "ܟܰܠܒܽܘܢܳܐ",
            sy7: "ܟܰܠܒܽܘܣܳܐ",
            sy6: "ܟܠܒܘܣܐ",
            id: "6034",
            dt3: "",
            lt10: "kalbuno",
            lt11: "kalbuso",
            sy9: "ܩܽܘܪܕܚܳܢܳܐ",
            dt2: "",
            sy4: "ܟܠܒܘܢܐ"
        },
        {
            lt11: "",
            sy4: "ܝܕܘܥܐ",
            sy7: "",
            lt10: "yođuĉo",
            sy6: "",
            sy5: "ܝܳܕܽܘܥܳܐ",
            dt3: "",
            dt1: "Rationalist",
            sy8: "",
            dt2: "",
            lt12: "",
            sy9: "",
            id: "6140"
        },
        {
            sy5: "ܪܡܺܝܣܬܰܐ",
            lt10: "ܪܡܝܣܬܐ",
            sy6: "",
            sy7: "",
            lt11: "ܪܡܝܣܬܐ",
            id: "3729",
            dt1: "Rmista",
            sy9: "",
            sy8: "",
            sy4: "ܪܡܝܣܬܐ",
            dt3: "",
            lt12: "",
            dt2: ""
        },
        {
            dt3: "",
            id: "2467",
            lt12: "ܟܝܠܡܘܐ",
            sy5: "ܟܺܝܠܰܡܘܰܐ",
            sy6: "ܟܝܠܡܘܐ",
            sy4: "ܟܝܠܡܘܐ",
            dt1: "Kilamwa",
            lt10: "ܟܝܠܡܘܐ",
            dt2: "",
            sy8: "",
            sy7: "ܟܺܝܠܰܡܽܘܰܐ",
            sy9: "",
            lt11: "ܟܝܠܡܘܐ"
        },
        {
            dt3: "",
            id: "3571",
            lt10: "ܩܘܪܠܘܢܐ",
            sy9: "",
            lt12: "",
            sy4: "ܩܘܪܠܘܢܐ",
            sy7: "",
            lt11: "ܩܘܪܠܘܢܐ",
            dt2: "",
            sy6: "",
            sy8: "",
            sy5: "ܩܽܘܪܠܽܘܢܰܐ",
            dt1: "Qurlona"
        },
        {
            lt12: "",
            sy7: "",
            sy5: "ܐܶܢܳܐ ܐ̱ܢܳܐ ܩܝܳܡܬܳܐ ܘܚܰܝܶܐ",
            sy9: "",
            sy6: "",
            dt2: "",
            sy8: "",
            lt11: "",
            sy4: "ܐܢܐ ܐܢܐ ܩܝܡܬܐ ܘܚܝܐ",
            dt1: "Ich bin die Auferstehung und das Leben",
            dt3: "",
            id: "2098",
            lt10: "eno no qyomto u ĥaye"
        },
        {
            lt10: "mshiĥoyo",
            sy9: "",
            dt2: "",
            sy8: "",
            dt1: "Christ",
            sy6: "",
            lt11: "",
            id: "766",
            sy4: "ܡܫܝܚܝܐ",
            sy7: "",
            dt3: "",
            sy5: "ܡܫܺܝܚܳܝܳܐ",
            lt12: ""
        },
        {
            sy4: "ܠܩܢܐ",
            id: "4430",
            sy9: "",
            sy7: "ܣܦܳܩܳܐ",
            dt1: "Teller",
            dt3: "",
            lt12: "",
            sy6: "ܣܦܩܐ",
            dt2: "",
            sy5: "ܠܰܩܢܳܐ",
            lt11: "sfoqo",
            lt10: "laqno",
            sy8: ""
        },
        {
            dt2: "",
            id: "1171",
            sy8: "",
            dt3: "",
            lt11: "",
            sy9: "",
            sy6: "",
            dt1: "erschöpft",
            sy7: "",
            lt10: "kđiro",
            sy5: "ܟܕܺܝܪܳܐ",
            sy4: "ܟܕܝܪܐ",
            lt12: ""
        },
        {
            dt1: "Merdina",
            dt3: "",
            lt12: "ܡܪܕܝܢ",
            id: "3002",
            lt10: "ܡܪܕܝܢܐ",
            sy7: "ܡܶܪܕܺܝܢ",
            lt11: "ܡܪܕܝܢܐ",
            dt2: "",
            sy8: "",
            sy5: "ܡܶܪܕܺܝܢܰܐ",
            sy9: "",
            sy6: "ܡܪܕܝܢ",
            sy4: "ܡܪܕܝܢܐ"
        },
        {
            sy7: "",
            lt10: "shato d`nesyono",
            sy9: "",
            dt1: "Probezeit",
            sy4: "ܫܢܬܐ ܕܢܣܝܢܐ",
            lt12: "",
            sy5: "ܫܰܢ̱ܬܳܐ ܕܢܶܣܝܳܢܳܐ",
            dt2: "Testzeit",
            id: "3517",
            sy8: "",
            sy6: "",
            dt3: "",
            lt11: ""
        },
        {
            sy7: "",
            sy9: "",
            dt3: "",
            lt12: "",
            lt10: "gantho",
            dt2: "",
            lt11: "",
            sy4: "ܓܢܬܐ",
            sy5: "ܓܰܢܬ݂ܳܐ",
            id: "1478",
            sy6: "",
            sy8: "",
            dt1: "Garten"
        },
        {
            sy5: "ܠܒܺܝܫܽܘܬ݂ܳܐ",
            sy8: "ܬܘܠܒܫܐ",
            sy9: "ܬܽܘܠܒܳܫܳܐ",
            sy4: "ܠܒܝܫܘܬܐ",
            lt10: "lbishutho",
            dt2: "Bekleidung",
            lt11: "ĉțifutho",
            dt3: "",
            sy7: "ܥܛܺܝܦܽܘܬ݂ܳܐ",
            sy6: "ܥܛܝܦܘܬܐ",
            dt1: "Ankleidung",
            id: "5961",
            lt12: "tulbosho"
        },
        {
            sy6: "",
            lt12: "",
            lt10: "ĉaqrutho",
            dt3: "",
            lt11: "",
            dt1: "Unfruchtbarkeit",
            sy5: "ܥܰܩܪܽܘܬ݂ܳܐ",
            sy4: "ܥܩܪܘܬܐ",
            sy7: "",
            id: "4645",
            sy8: "",
            dt2: "",
            sy9: ""
        },
        {
            id: "6436",
            sy6: "",
            lt11: "",
            dt3: "",
            lt12: "",
            dt2: "",
            sy4: "ܢܘܪܬ",
            sy7: "",
            dt1: "nevert",
            lt10: "newert",
            sy9: "",
            sy8: "",
            sy5: "ܢܶܘܶܪܬ"
        },
        {
            sy5: "ܛܽܘܦܣܳܐ ܕܪܳܫܽܘܡܽܘܬ݂ܳܐ",
            dt1: "Zeichenmodell",
            dt3: "",
            sy9: "",
            sy6: "",
            lt10: "țufso d`roshumutho",
            sy7: "",
            id: "5279",
            lt12: "",
            lt11: "",
            sy8: "",
            sy4: "ܛܘܦܣܐ ܕܪܫܘܡܘܬܐ",
            dt2: ""
        },
        {
            sy9: "",
            sy8: "",
            lt12: "",
            dt2: "",
            sy4: "ܢܘܟܪܝܘܬܐ",
            dt1: "Fremde",
            lt10: "nukhroyutho",
            id: "1386",
            dt3: "",
            sy5: "ܢܽܘܟ݂ܪܳܝܽܘܬ݂ܳܐ",
            sy6: "",
            sy7: "",
            lt11: ""
        },
        {
            sy9: "",
            sy4: "ܫܢܕܐ ܦܠܝܓܐ ܐܝܬܘܗܝ ܦܠܓܘܬ ܫܢܕܐ",
            id: "1612",
            dt1: "Geteiltes Leid ist halbes Leid",
            lt10: "shendo fliğo ithau felguth ğhendo",
            dt3: "",
            sy6: "",
            sy7: "",
            dt2: "",
            sy8: "",
            lt12: "",
            lt11: "",
            sy5: "ܫܶܢܕܳܐ ܦܠܺܝܓ݂ܳܐ ܐܺܝܬ݂ܰܘܗܝ̱ ܦܶܠܓܽܘܬ݂ ܫܶܢܕܳܐ"
        },
        {
            id: "123",
            dt2: "Auslöscher",
            dt1: "Abwischer",
            sy7: "",
            sy5: "ܠܳܚܽܘܝܳܐ",
            lt10: "loĥuyo",
            sy8: "",
            sy6: "",
            lt12: "",
            sy9: "",
            lt11: "",
            dt3: "Reiniger",
            sy4: "ܠܚܘܝܐ"
        },
        {
            lt12: "",
            id: "3563",
            sy6: "",
            lt11: "ܩܝܬܪܐ",
            lt10: "ܩܝܬܪܐ",
            dt2: "",
            sy9: "",
            sy4: "ܩܝܬܪܐ",
            dt3: "",
            dt1: "Qitora",
            sy7: "",
            sy5: "ܩܺܝܬܳܪܰܐ",
            sy8: ""
        },
        {
            sy9: "",
            dt3: "",
            lt10: "birotho",
            sy6: "",
            sy8: "",
            sy4: "ܒܐܪܬܐ",
            lt11: "",
            dt2: "",
            sy5: "ܒܺܐܪܳܬ݂ܳܐ",
            dt1: "Paläste",
            id: "3410",
            sy7: "",
            lt12: ""
        },
        {
            dt1: "Walnussbaum",
            sy5: "ܐܺܝܠܳܢܳܐ ܓܰܘܙܳܐ",
            sy4: "ܐܝܠܢܐ ܓܘܙܐ",
            lt11: "",
            sy6: "",
            sy7: "",
            dt2: "Walnußbaum",
            sy9: "",
            lt12: "",
            id: "4974",
            lt10: "ilono gauzo",
            sy8: "",
            dt3: ""
        },
        {
            dt3: "",
            lt11: "",
            sy7: "",
            sy8: "",
            sy4: "ܣܝܩܘܡ ܡܫܠܡܢܘܬܐ",
            lt12: "",
            id: "2826",
            dt2: "",
            lt10: "siqum mashlmonutho",
            sy6: "",
            sy9: "",
            sy5: "ܣܺܝܩܽܘܡ ܡܰܫܠܡܳܢܽܘܬ݂ܳܐ",
            dt1: "Lieferdatum"
        },
        {
            sy5: "ܣܶܦܘܳܬ݂ܳܐ",
            lt10: "sefwotho",
            sy8: "",
            id: "2842",
            dt2: "",
            sy4: "ܣܦܘܬܐ",
            sy7: "",
            lt11: "",
            dt1: "Lippe",
            sy9: "",
            dt3: "",
            lt12: "",
            sy6: ""
        },
        {
            sy5: "ܓܳܙܰܪܬܳܐ   ",
            sy4: "ܓܙܪܬܐ",
            lt12: "",
            sy9: "",
            id: "2210",
            dt2: "",
            lt10: "gozarto",
            sy8: "",
            sy6: "",
            dt1: "Insel",
            lt11: "",
            dt3: "",
            sy7: ""
        },
        {
            sy5: "ܐܳܣܝܳܐ",
            sy4: "ܐܣܝܐ",
            lt11: "ܐܣܝܐ",
            lt10: "ܐܣܝܐ",
            dt2: "",
            sy9: "",
            dt3: "",
            lt12: "",
            sy7: "",
            dt1: "Osyo",
            sy6: "",
            id: "3403",
            sy8: ""
        },
        {
            sy8: "",
            dt2: "",
            dt3: "",
            sy5: "ܠܽܘܣܶܐ",
            sy9: "",
            lt11: "ܠܘܣܐ",
            sy4: "ܠܘܣܐ",
            lt10: "ܠܘܣܐ",
            sy6: "ܠܘܙܐ",
            dt1: "Luse",
            sy7: "ܠܽܘܙܶܐ",
            id: "2877",
            lt12: "ܠܘܙܐ"
        },
        {
            sy6: "",
            sy8: "",
            lt12: "",
            sy5: "ܐܶܢܳܐ ܐ̱ܢܳܐ ܒܰܗܪܳܐ ܕܒܪܺܝܬ݂ܳܐ",
            lt11: "",
            sy9: "",
            dt3: "",
            sy7: "",
            sy4: "ܐܢܐ ܐܢܐ ܒܗܪܐ ܕܒܪܝܬܐ",
            dt2: "",
            dt1: "ich bin das Licht der Welt",
            id: "2093",
            lt10: "eno no bahro d`britho"
        },
        {
            sy6: "",
            dt3: "",
            sy7: "",
            id: "1933",
            lt10: "ĥubo d`motho",
            lt12: "",
            dt2: "",
            sy4: "ܚܘܒܐ ܕܡܬܐ",
            lt11: "",
            dt1: "Heimatliebe",
            sy9: "",
            sy5: "ܚܽܘܒܳܐ ܕܡܳܬ݂ܳܐ",
            sy8: ""
        },
        {
            lt10: "ܬܐܘܦܘܪܐ",
            id: "4460",
            dt2: "",
            lt12: "",
            sy5: "ܬܶܐܘܳܦܳܘܪܰܐ",
            dt3: "",
            sy8: "",
            sy9: "",
            sy4: "ܬܐܘܦܘܪܐ",
            sy6: "",
            lt11: "ܬܐܘܦܘܪܐ",
            sy7: "",
            dt1: "Theophora"
        },
        {
            sy7: "",
            lt10: "grofo d`talgo",
            sy6: "",
            id: "5476",
            lt12: "",
            dt1: "Schnee schippen",
            sy9: "",
            lt11: "",
            sy8: "",
            dt2: "",
            sy4: "ܓܪܳܦܳܐ ܕܬܰܠܓܳܐ",
            dt3: "",
            sy5: "ܓܪܳܦܳܐ ܕܬܰܠܓܳܐ"
        },
        {
            lt12: "",
            dt2: "",
            sy6: "",
            sy8: "",
            sy7: "",
            sy5: "ܒܶܛܠܳܢܳܐ ܕܰܩܝܳܡܬܳܐ",
            dt1: "Osterferien",
            lt10: "bețlono da`qyomto",
            lt11: "",
            sy4: "ܒܛܠܢܐ ܕܩܝܡܬܐ",
            dt3: "",
            id: "3400",
            sy9: ""
        },
        {
            dt2: "",
            lt12: "",
            lt10: "shemso donĥo",
            lt11: "",
            sy4: "ܫܡܫܐ ܕܢܚܐ",
            dt3: "",
            sy6: "",
            sy7: "",
            sy8: "",
            id: "355",
            dt1: "aufgehende Sonne",
            sy9: "",
            sy5: "ܫܶܡܫܶܐ ܕܳܢܚܳܐ"
        },
        {
            id: "3289",
            dt1: "nochmal",
            sy4: "ܙܒܬܐ ܐܚܪܬܐ",
            lt12: "",
            sy7: "",
            dt3: "",
            sy8: "",
            dt2: "nochmals",
            sy6: "",
            sy9: "",
            sy5: "ܙܒܰܬܳܐ ܐ̱ܚܪܺܬܳܐ",
            lt11: "",
            lt10: "zbato ĥrito"
        },
        {
            lt10: "sĉuro",
            sy6: "",
            sy8: "",
            sy4: "ܙܥܘܪܐ",
            dt1: "klein",
            sy7: "",
            dt3: "",
            lt11: "",
            lt12: "",
            id: "2512",
            sy9: "",
            sy5: "ܙܥܽܘܪܳܐ",
            dt2: "winzig"
        },
        {
            lt10: "nsokho",
            dt2: "Abbild",
            sy6: "ܢܘܣܟܐ",
            sy7: "ܢܽܘܣܟ݂ܳܐ",
            dt3: "Exemplar",
            sy8: "ܨܘܪܬܐ",
            sy4: "ܢܣܟܐ",
            lt12: "ŝurto",
            sy5: "ܢܣܳܟ݂ܳܐ",
            dt1: "Kopie",
            id: "2604",
            lt11: "nuskho",
            sy9: "ܨܽܘܪܬܳܐ"
        },
        {
            sy8: "",
            id: "982",
            lt10: "ŝahwo",
            dt1: "Durst",
            sy5: "ܨܰܗܘܳܐ",
            sy7: "ܨܰܗܝܽܘܬ݂ܳܐ",
            lt11: "ŝahyutho",
            dt3: "",
            dt2: "",
            sy9: "",
            sy6: "ܨܗܝܘܬܐ",
            lt12: "",
            sy4: "ܨܗܘܐ"
        },
        {
            sy8: "",
            sy5: "ܕܰܝܳܢܳܐ ܓܰܒܳܝܳܐ",
            dt3: "",
            lt12: "",
            sy9: "",
            sy6: "",
            sy4: "ܕܝܢܐ ܓܒܝܐ",
            lt11: "",
            lt10: "dayono gaboyo",
            dt2: "",
            dt1: "Linienrichter",
            sy7: "",
            id: "2838"
        },
        {
            lt10: "mshifoith",
            sy7: "",
            sy9: "",
            dt1: "klar und deutlich",
            id: "2496",
            sy5: "ܡܫܺܝܦܳܐܝܺܬ݂",
            dt3: "",
            lt11: "",
            sy6: "",
            dt2: "",
            sy4: "ܡܫܝܦܐܝܬ",
            sy8: "",
            lt12: ""
        },
        {
            sy9: "",
            sy7: "ܙܟ݂ܰܪܝܳܐ",
            lt10: "ܙܟ݂ܪܝܐ",
            dt3: "",
            sy4: "ܙܟ݂ܪܝܐ",
            lt12: "ܙܟ݂ܪܝܐ",
            dt1: "Zacharia",
            dt2: "",
            sy8: "",
            sy6: "ܙܟܪܝܐ",
            lt11: "ܙܟ݂ܪܝܐ",
            id: "5219",
            sy5: "ܙܟ݂ܰܪܝܰܐ"
        },
        {
            sy6: "ܕܝܢܐ ܕܥܠܝܡܐ",
            id: "2353",
            sy4: "ܕܝܢܐ ܕܛܠܝܐ",
            dt3: "",
            lt10: "dayono da´țloye",
            sy5: "ܕܰܝܳܢܳܐ ܕܰܛܠܳܝ̈ܶܐ",
            lt12: "",
            sy7: "ܕܰܝܳܢܳܐ ܕܰܥܠܰܝ̈ܡܶܐ",
            dt2: "",
            lt11: "dayono da`ĉlayme",
            sy9: "",
            sy8: "",
            dt1: "Jugendrichter"
        },
        {
            lt12: "",
            dt3: "",
            lt10: "shuĥlof nomuso",
            id: "1598",
            sy7: "",
            lt11: "",
            sy8: "",
            sy6: "",
            sy9: "",
            dt2: "",
            dt1: "Gesetzänderung",
            sy5: "ܫܽܘܚܠܳܦ ܢܳܡܽܘܣܳܐ",
            sy4: "ܫܘܚܠܦ ܢܡܘܣܐ"
        },
        {
            sy6: "",
            dt2: "Text interpretieren",
            dt3: "Textkommentar",
            id: "4448",
            lt12: "",
            sy5: "ܦܽܘܫܳܩܳܐ ܕܰܟܬ݂ܺܝܒܬܳܐ",
            sy7: "",
            dt1: "Textauslegung",
            sy8: "",
            lt10: "fushoqo da`kthibto",
            lt11: "",
            sy9: "",
            sy4: "ܦܘܫܩܐ ܕܟܬܝܒܬܐ"
        },
        {
            sy5: "ܨܰܠܺܝ",
            sy9: "",
            id: "582",
            lt12: "",
            sy4: "ܨܠܝ",
            lt10: "ŝali",
            sy7: "ܡܨܰܠܶܐ",
            dt3: "",
            dt1: "beten",
            lt11: "mŝale",
            dt2: "",
            sy8: "",
            sy6: "ܡܨܠܐ"
        },
        {
            id: "4739",
            dt3: "",
            sy6: "",
            sy7: "",
            lt11: "",
            sy8: "",
            sy9: "",
            lt12: "",
            lt10: "sumomo",
            dt2: "",
            sy4: "ܣܘܡܡܐ",
            dt1: "Vergiftung",
            sy5: "ܣܽܘܡܳܡܳܐ"
        },
        {
            dt2: "Tara",
            sy5: "ܬܰܪܰܚ",
            sy6: "ܬܪܚ",
            sy9: "",
            sy8: "",
            lt12: "ܬܪܚ",
            id: "4388",
            sy4: "ܬܪܚ",
            dt1: "Tarah",
            dt3: "",
            sy7: "ܬܳܪܳܚ",
            lt10: "ܬܪܚ",
            lt11: "ܬܪܚ"
        },
        {
            sy6: "ܓܒܝܐ",
            sy8: "",
            id: "4938",
            sy5: "ܡܓܰܒܝܳܢܳܐ",
            lt12: "",
            sy4: "ܡܓܒܝܢܐ",
            lt11: "gaboyo",
            sy9: "",
            dt1: "Wähler",
            lt10: "mgabyono",
            dt3: "",
            sy7: "ܓܰܒܳܝܳܐ",
            dt2: ""
        },
        {
            dt3: "",
            sy6: "",
            lt12: "",
            lt10: "gishro",
            sy4: "ܓܝܫܪܐ",
            sy9: "",
            lt11: "",
            dt2: "",
            sy5: "ܓܺܝܫܪܳܐ",
            dt1: "Brücke",
            sy7: "",
            id: "715",
            sy8: ""
        },
        {
            sy6: "",
            sy5: "ܐܺܝܙܠܰܐ",
            lt12: "",
            dt3: "",
            sy9: "",
            sy7: "",
            sy4: "ܐܝܙܠܐ",
            dt1: "Izla",
            lt10: "ܐܝܙܠܐ",
            sy8: "",
            dt2: "Isla",
            id: "2258",
            lt11: "ܐܝܙܠܐ"
        },
        {
            dt3: "",
            sy9: "",
            dt2: "",
            sy6: "ܥܠܝܡܬܐ",
            lt12: "",
            dt1: "Mädchen",
            id: "2882",
            sy4: "ܛܠܝܬܐ",
            sy8: "",
            lt10: "țlitho",
            sy7: "ܥܠܰܝܡܬ݂ܳܐ",
            sy5: "ܛܠܺܝܬ݂ܳܐ",
            lt11: "ĉlaymtho"
        },
        {
            lt11: "",
            sy7: "",
            dt3: "Bonbon",
            dt1: "Schokolade",
            sy5: "ܚܰܠܝܽܘܬ݂ܳܐ",
            id: "4006",
            sy9: "",
            lt10: "ĥalyutho",
            lt12: "",
            dt2: "Süßigkeiten",
            sy4: "ܚܠܝܘܬܐ",
            sy8: "",
            sy6: ""
        },
        {
            sy7: "ܓܡܺܝܪܳܐܝܺܬ݂",
            dt2: "perfekt",
            id: "4874",
            lt10: "gmiro",
            sy8: "",
            sy4: "ܓܡܝܪܐ",
            sy6: "ܓܡܝܪܐܝܬ",
            lt12: "",
            dt3: "ideal",
            sy5: "ܓܡܺܝܪܳܐ",
            lt11: "gmiroith",
            sy9: "",
            dt1: "vollkommen"
        },
        {
            sy5: "ܫܺܝܫܰܠܬܳܐ",
            sy8: "",
            id: "2461",
            sy4: "ܫܝܫܠܬܐ",
            lt10: "shishalto",
            lt12: "",
            sy7: "",
            lt11: "",
            dt2: "",
            sy6: "",
            sy9: "",
            dt3: "",
            dt1: "Kette"
        },
        {
            lt11: "balbuse",
            sy9: "",
            id: "3488",
            sy6: "ܒܠܒܘܣܐ",
            sy8: "",
            sy5: "ܦܽܘܡܶܣ",
            lt10: "fumes",
            dt2: "",
            sy7: "ܒܰܠܒܽܘ̈ܣܳܐ",
            sy4: "ܦܘܡܣ",
            lt12: "",
            dt1: "Pommes",
            dt3: ""
        },
        {
            lt10: "ĥbolo d`ĉolmo",
            dt3: "",
            sy9: "",
            lt11: "suĥofo da`britho",
            dt2: "",
            lt12: "",
            sy4: "ܚܒܠܐ ܕܥܠܡܐ",
            id: "5061",
            dt1: "Weltuntergang",
            sy5: "ܚܒܳܠܳܐ ܕܥܳܠܡܳܐ",
            sy6: "ܣܘܚܦܐ ܕܒܪܝܬܐ",
            sy8: "",
            sy7: "ܣܽܘܚܳܦܳܐ ܕܰܒܪܺܝܬ݂ܳܐ"
        },
        {
            sy4: "ܟܘܢܫ ܬܪܥܝܬܐ",
            lt11: "",
            id: "2598",
            sy8: "",
            sy7: "",
            dt1: "Konzentration",
            dt3: "",
            sy9: "",
            sy6: "",
            sy5: "ܟܽܘܢܳܫ ܬܰܪܥܺܝܬ݂ܳܐ",
            dt2: "",
            lt12: "",
            lt10: "kunosh tarĉitho"
        },
        {
            sy5: "ܩܽܘܡܰܪ",
            sy6: "ܫܥܝܐ ܕܓܕܐ",
            sy4: "ܩܘܡܪ",
            dt1: "Glücksspiel",
            lt11: "sheĉyo d`gado",
            sy7: "ܫܶܥܝܳܐ ܕܓܰܕܳܐ",
            dt3: "Losspiel",
            lt10: "qumar",
            sy9: "ܫܶܥܝܳܐ ܕܩܽܘܦܣܶܐ",
            lt12: "sheĉyo d`qufse",
            sy8: "ܫܥܝܐ ܕܩܘܦܣܐ",
            dt2: "Casino",
            id: "1671"
        },
        {
            id: "5732",
            sy4: "ܬܡܢܝܐ ܥܐܦܝܢ",
            sy5: "ܬܡܳܢܝܳܐ ܥܶܐܦܺܝ̈ܢ",
            lt10: "tmonyo ĉefin",
            dt1: "achtfach",
            lt12: "",
            sy9: "",
            dt2: "",
            sy8: "",
            dt3: "",
            sy6: "",
            sy7: "",
            lt11: ""
        },
        {
            sy4: "ܥܪܕܐ",
            lt12: "",
            dt2: "Wildesel",
            sy8: "",
            sy7: "",
            sy9: "",
            lt10: "ĉrodo",
            lt11: "",
            dt3: "",
            id: "5267",
            sy5: "ܥܪܳܕܳܐ",
            dt1: "Zebra",
            sy6: ""
        },
        {
            dt2: "",
            sy5: "ܐܰܦܪܺܝܩܺܝ ܓܰܪܒܝܳܝܬܳܐ",
            sy8: "",
            lt11: "",
            sy6: "",
            sy4: "ܐܦܪܝܩܝ ܓܪܒܝܝܬܐ",
            lt12: "",
            lt10: "afriqi garbyoyto",
            dt3: "",
            sy7: "",
            id: "3291",
            sy9: "",
            dt1: "Nordafrika"
        },
        {
            dt2: "",
            sy6: "ܟܢܘܢ ܩܕܡܝܐ",
            id: "872",
            lt12: "",
            sy7: "ܟܳܢܽܘܢ ܩܰܕܡܳܝܳܐ",
            sy4: "ܟܢܘܢ ܩܕܡ",
            lt10: "konun qđem",
            dt3: "",
            sy5: "ܟܳܢܽܘܢ ܩܕܶܡ",
            dt1: "Dezember",
            sy8: "",
            lt11: "konun qadmoyo",
            sy9: ""
        },
        {
            id: "5628",
            dt1: "Abschlußexamen",
            lt11: "",
            lt10: "buĥrono ĥroyo",
            dt3: "Abschlussprüfung",
            sy7: "",
            lt12: "",
            sy6: "",
            sy5: "ܒܽܘܚܪܳܢܳܐ ܐ̱ܚܪܳܝܳܐ",
            sy8: "",
            dt2: "Abschlussexamen",
            sy4: "ܒܘܚܪܢܐ ܚܪܝܐ",
            sy9: ""
        },
        {
            sy4: "ܝܘܡ ܡܘܠܕܐ ܒܪܝܟܐ",
            lt10: "yum maulodo brikho",
            sy6: "ܝܘܡܐ ܕܡܘܠܕܐ ܒܪܝܟܐ",
            dt1: "Happy Birthday",
            sy7: "ܝܰܘܡܳܐ ܕܡܰܘܠܳܕܳܐ ܗܳܘܶܐ ܒܪܺܝܟ݂ܳܐ",
            dt2: "Herzlichen Glückwunsch zum Geburtstag",
            sy8: "",
            dt3: "",
            sy5: "ܝܽܘܡ ܡܰܘܠܳܕܳܐ ܒܪܺܝܟ݂ܳܐ",
            sy9: "",
            lt12: "",
            lt11: "yaumo dmaulodo howe brikho",
            id: "1871"
        },
        {
            sy9: "",
            lt10: "mmashĥonutho",
            dt2: "",
            sy5: "ܡܡܰܫܚܳܢܽܘܬ݂ܳܐ",
            sy7: "",
            sy4: "ܡܡܫܚܢܘܬܐ",
            dt1: "Wirtschaftlichkeit",
            lt12: "",
            sy8: "",
            lt11: "",
            id: "5134",
            dt3: "",
            sy6: ""
        },
        {
            lt11: "",
            dt2: "",
            sy5: "ܣܳܥܽܘܪܽܘܬ݂ܳܐ ܪܰܡܫܳܝܬܳܐ",
            lt12: "",
            sy6: "",
            sy9: "",
            dt1: "Abendbesuch",
            dt3: "",
            lt10: "ŝoĉurutho ramshoyto",
            sy7: "",
            sy4: "ܣܥܘܪܘܬܐ ܪܡܫܝܬܐ",
            id: "5555",
            sy8: ""
        },
        {
            sy5: "ܐܰܠܦܳܐ",
            sy7: "ܐܼ",
            lt10: "alfo",
            dt3: "tausend",
            sy6: "ܐܼ",
            dt1: "1000",
            lt11: "olaf u nuqso",
            sy9: "",
            sy4: "ܐܠܦܐ",
            dt2: "eintausend",
            sy8: "",
            id: "47",
            lt12: ""
        },
        {
            sy9: "",
            sy7: "",
            lt12: "",
            sy5: "ܫܺܝܡܳܐ",
            dt2: "",
            id: "4116",
            sy8: "",
            dt3: "",
            lt11: "",
            lt10: "shimo",
            sy4: "ܫܝܡܐ",
            dt1: "semitisch",
            sy6: ""
        },
        {
            lt11: "ܡܝܟܐܝܠ",
            sy9: "",
            dt2: "",
            dt3: "",
            dt1: "Michael",
            sy6: "ܡܝܟܐܝܠ",
            sy7: "ܡܺܝܟ݂ܳܐܝܶܠ",
            lt10: "ܡܝܟܐܝܠ",
            sy8: "",
            lt12: "ܡܝܟ݂ܐܝܠ",
            sy4: "ܡܝܟܐܝܠ",
            id: "3016",
            sy5: "ܡܺܝܟ݂ܰܐܝܶܠ"
        },
        {
            sy5: "ܫܽܘܫܶܐ",
            sy6: "ܣܘܣܝ",
            sy7: "ܣܽܘܣܺܝ",
            id: "4327",
            sy4: "ܫܘܫܐ",
            dt1: "Susi",
            lt12: "ܣܘܣܝ",
            sy8: "",
            dt2: "Schusche",
            lt11: "ܫܘܫܐ",
            sy9: "",
            lt10: "ܫܘܫܐ",
            dt3: ""
        },
        {
            lt12: "",
            dt3: "",
            dt1: "Syrisch Maronitische Kirche von Antiochien",
            sy4: "ܥܕܬܐ ܣܘܪܝܝܬܐ ܡܪܘܢܝܬܐ ܕܐܢܛܝܘܟܝܐ",
            sy5: "ܥܺܕܬܳܐ ܣܽܘܪܝܳܝܬܳܐ ܡܰܪܽܘܢܳܝܬܳܐ ܕܰܐܢܛܝܳܘܟ݂ܝܰܐ",
            sy7: "",
            id: "4338",
            lt10: "ĉito suryoyto marunayto d`antiokya",
            sy9: "",
            sy8: "",
            lt11: "",
            dt2: "",
            sy6: ""
        },
        {
            sy8: "",
            lt11: "ܟܪܝܣܬܝܢܐ",
            sy7: "ܟܪܺܝܣܛܺܝܢܰܐ",
            sy5: "ܟܪܺܝܣܬܺܝܢܰܐ",
            lt10: "ܟܪܝܣܬܝܢܐ",
            sy4: "ܟܪܝܣܬܝܢܐ",
            dt3: "",
            sy6: "ܟܪܝܣܛܝܢܐ",
            lt12: "ܟܪܝܣܛܝܢܐ",
            dt1: "Kristina",
            id: "2648",
            dt2: "Christina",
            sy9: ""
        },
        {
            sy5: "ܐܶܫܬܥܺܝ",
            sy8: "",
            lt12: "",
            lt10: "eshtĉi",
            dt1: "spielen",
            sy9: "",
            sy4: "ܐܫܬܥܝ",
            lt11: "meshtĉe",
            dt3: "",
            sy6: "ܡܫܬܥܐ",
            id: "4212",
            dt2: "",
            sy7: "ܡܶܫܬܥܶܐ"
        },
        {
            sy9: "",
            sy6: "",
            dt2: "Falle",
            lt12: "",
            sy8: "",
            id: "6394",
            dt3: "Hinterhalt",
            sy4: "ܓܘܡܨܐ",
            lt10: "ğumoŝo",
            dt1: "Grube",
            sy7: "",
            lt11: "",
            sy5: "ܓ݂ܽܘܡܳܨܳܐ"
        },
        {
            sy9: "",
            dt2: "",
            dt1: "andererseits",
            lt11: "",
            sy8: "",
            dt3: "",
            sy4: "ܡܐ ܓܒܐ ܚܪܢܐ",
            lt10: "me gabo ĥreno",
            sy5: "ܡܶܐ ܓܰܒܳܐ ܚܪܶܢܳܐ",
            lt12: "",
            sy7: "",
            id: "227",
            sy6: ""
        },
        {
            sy7: "",
            sy8: "",
            sy6: "",
            lt10: "kthobe",
            dt2: "",
            dt1: "Bücher",
            lt11: "",
            sy4: "ܟܬܒܐ",
            id: "5619",
            dt3: "",
            sy5: "ܟܬ݂ܳܒܶܐ",
            sy9: "",
            lt12: ""
        },
        {
            dt1: "Martina",
            dt2: "",
            sy5: "ܡܰܪܬܺܝܢܰܐ",
            sy7: "",
            id: "2948",
            lt12: "",
            sy9: "",
            sy6: "",
            dt3: "",
            lt11: "ܡܪܬܝܢܐ",
            sy4: "ܡܪܬܝܢܐ",
            lt10: "ܡܪܬܝܢܐ",
            sy8: ""
        },
        {
            lt12: "",
            sy7: "",
            lt10: "ܬܐܘܦܘܪܘܣ",
            sy9: "",
            sy6: "",
            sy4: "ܬܐܘܦܘܪܘܣ",
            sy5: "ܬܶܐܘܳܦܳܘܪܳܘܣ",
            dt3: "",
            id: "4461",
            dt1: "Theophoros",
            lt11: "ܬܐܘܦܘܪܘܣ",
            dt2: "",
            sy8: ""
        },
        {
            id: "3011",
            sy6: "",
            sy7: "",
            sy9: "",
            dt1: "Metzger verkauft Fleisch",
            sy4: "ܩܨܒܐ ܡܙܒܢ ܒܣܪܐ",
            sy5: "ܩܰܨܳܒܳܐ ܡܙܰܒܶܢ ܒܶܣܪܳܐ",
            lt12: "",
            dt2: "Fleischer verkauft Fleisch",
            lt11: "",
            sy8: "",
            dt3: "",
            lt10: "qaŝobo mzaben besro"
        },
        {
            id: "1561",
            dt2: "genauso",
            dt3: "",
            dt1: "genau wie",
            sy9: "ܟ݂ܝܺܕ",
            lt11: "hokhan",
            lt10: "akhsno",
            lt12: "khid",
            sy4: "ܐܟܙܢܐ",
            sy6: "ܗܟܢ",
            sy5: "ܐܰܟ݂ܙܢܳܐ",
            sy7: "ܗܳܟ݂ܰܢ",
            sy8: "ܟܝܕ"
        },
        {
            sy5: "ܡܰܠܟܽܘܬ݂ܳܐ ܕܰܠܳܗܳܐ ܠܓ݂ܰܘ ܕܺܝܠܟ݂ܽܘܢ ܗܺܝ̱",
            id: "5602",
            sy9: "",
            dt1: "Das Reich Gottes ist inwendig in euch",
            lt10: "malkutho daloho lğau dilkhun hi",
            sy4: "ܡܠܟܘܬܐ ܕܠܗܐ ܠܓܘ ܕܝܠܟܘܢ ܗܝ",
            dt2: "",
            sy7: "",
            sy6: "",
            sy8: "",
            dt3: "",
            lt11: "",
            lt12: ""
        },
        {
            sy9: "",
            dt1: "Sommer",
            sy7: "",
            dt2: "",
            lt10: "qayțo",
            sy8: "",
            lt11: "",
            id: "4188",
            lt12: "",
            dt3: "",
            sy5: "ܩܰܝܛܳܐ",
            sy4: "ܩܝܛܐ",
            sy6: ""
        },
        {
            sy9: "",
            dt1: "interessiert",
            lt10: "lhiqo",
            dt3: "",
            sy8: "",
            dt2: "neugierig",
            lt11: "",
            sy6: "",
            id: "2225",
            sy5: "ܠܗܺܝܩܳܐ",
            sy4: "ܠܗܝܩܐ",
            lt12: "",
            sy7: ""
        },
        {
            id: "2103",
            sy9: "",
            dt3: "",
            sy5: "ܒܰܛܺܝܠܳܐ ܐ̱ܢܳܐ",
            sy6: "",
            sy8: "",
            lt11: "",
            lt10: "bațilo no",
            dt2: "",
            lt12: "",
            sy4: "ܒܛܝܠܐ ܐ̱ܢܐ",
            dt1: "Ich bin müde",
            sy7: ""
        },
        {
            sy8: "",
            lt11: "ܝܘܣܐ",
            sy7: "",
            dt2: "",
            sy4: "ܝܘܣܐ",
            dt1: "Yause",
            dt3: "",
            sy6: "",
            sy9: "",
            lt10: "ܝܘܣܐ",
            id: "5204",
            lt12: "",
            sy5: "ܝܰܘܣܶܐ"
        },
        {
            lt12: "ܐܪܡܝܐ",
            lt10: "ܐܪܡܝܐ",
            id: "2303",
            sy6: "ܐܪܡܝܐ",
            sy8: "",
            sy7: "ܐܶܪܰܡܝܰܐ",
            lt11: "ܐܪܡܝܐ",
            dt2: "",
            dt3: "",
            sy5: "ܐܶܪܰܡܝܳܐ",
            dt1: "Jeremia",
            sy4: "ܐܪܡܝܐ",
            sy9: ""
        },
        {
            id: "139",
            sy7: "",
            lt10: "ܐܕܘܪ",
            sy8: "",
            lt11: "ܐܕܘܪ",
            sy4: "ܐܕܘܪ",
            sy9: "",
            dt1: "Advar",
            dt3: "",
            sy5: "ܐܰܕܘܰܪ",
            sy6: "",
            lt12: "",
            dt2: "Adwar"
        },
        {
            dt3: "",
            sy8: "",
            sy5: "ܐܰܢ̱ܬܬ݂ܳܐ",
            lt12: "",
            sy7: "",
            dt2: "Weib",
            lt10: "at-tho",
            dt1: "Frau",
            sy9: "",
            sy6: "",
            lt11: "",
            sy4: "ܐܢܬܬܐ",
            id: "1364"
        },
        {
            sy9: "",
            lt10: "ܡܚܙܝܬܐ",
            sy8: "",
            lt11: "ܡܚܙܝܬܐ",
            dt2: "",
            lt12: "",
            sy4: "ܡܚܙܝܬܐ",
            dt3: "",
            sy6: "",
            sy5: "ܡܰܚܙܺܝܬܰܐ",
            id: "2888",
            dt1: "Mahzita",
            sy7: ""
        },
        {
            dt1: "Vollmond",
            sy9: "",
            sy4: "ܣܗܪܐ ܡܠܝܐ",
            id: "4878",
            dt2: "",
            lt11: "",
            lt12: "",
            sy7: "",
            lt10: "sahro malyo",
            sy8: "",
            sy6: "",
            sy5: "ܣܰܗܳܪܐ ܡܰܠܝܳܐ",
            dt3: ""
        },
        {
            dt2: "",
            dt1: "Inhaltsverzeichnis",
            sy9: "",
            sy6: "",
            sy4: "ܡܚܘܝܢܐ ܕܟܬܒܐ",
            sy5: "ܡܚܰܘܝܳܢܳܐ ܕܟܬ݂ܳܒܳܐ",
            lt11: "",
            lt12: "",
            sy7: "",
            lt10: "mĥauwyono da`kthobo",
            id: "2204",
            dt3: "",
            sy8: ""
        },
        {
            lt10: "kiso",
            sy7: "",
            sy5: "ܟܺܝܣܳܐ",
            dt3: "",
            sy9: "",
            dt2: "",
            lt11: "",
            id: "3793",
            lt12: "",
            sy4: "ܟܝܣܐ",
            sy8: "",
            dt1: "Sack",
            sy6: ""
        },
        {
            sy4: "ܟܘܬܝܢܐ ܕܠܠܝܐ",
            sy6: "",
            lt10: "kutino d`lelyo",
            lt12: "",
            dt1: "Nachthemd",
            sy7: "",
            sy5: "ܟܽܘܬܺܝܢܳܐ ܕܠܶܠܝܳܐ",
            sy8: "",
            sy9: "",
            dt3: "",
            lt11: "",
            id: "3169",
            dt2: ""
        },
        {
            sy8: "",
            lt10: "masraĥyothe",
            sy9: "",
            lt12: "",
            sy4: "ܡܣܪܚܝܬܐ",
            id: "4451",
            dt1: "Theaterstücke",
            sy7: "",
            lt11: "",
            dt2: "",
            dt3: "",
            sy5: "ܡܰܣܪܰܚܝ̈ܳܬܳܐ",
            sy6: ""
        },
        {
            sy6: "",
            sy9: "",
            sy5: "ܡܠܺܝܠܽܘܬ݂ܳܐ",
            sy7: "",
            dt3: "",
            dt1: "Vernunft",
            lt12: "",
            lt10: "mlilutho",
            sy4: "ܡܠܝܠܘܬܐ",
            dt2: "",
            sy8: "",
            lt11: "",
            id: "4771"
        },
        {
            lt10: "bthulutho",
            dt3: "",
            dt1: "Jungfräulichkeit",
            id: "2364",
            lt11: "",
            sy5: "ܒܬ݂ܽܘܠܽܘܬ݂ܳܐ",
            sy7: "",
            sy6: "",
            dt2: "",
            sy8: "",
            lt12: "",
            sy4: "ܒܬܘܠܘܬܐ",
            sy9: ""
        },
        {
            lt12: "",
            sy8: "",
            dt2: "",
            dt3: "",
            lt11: "",
            sy5: "ܐܶܓܰܪܬ݂ܳܐ ܒܽܘܝܳܐܳܐ",
            sy7: "",
            sy4: "ܐܓܪܬܐ ܕܒܘܝܐܐ",
            sy6: "",
            dt1: "Trostbrief",
            lt10: "egartho d`buyoyo",
            id: "4581",
            sy9: ""
        },
        {
            dt1: "Weltmeisterschaft",
            sy9: "",
            lt10: "athlițuth ĉolmo",
            dt2: "",
            sy7: "",
            lt12: "",
            sy8: "",
            id: "5056",
            sy4: "ܐܬܠܝܛܘܬ ܥܠܡܐ",
            sy5: "ܐܰܬ݂ܠܺܝܛܽܘܬ݂ ܥܳܠܡܳܐ",
            dt3: "",
            lt11: "",
            sy6: ""
        },
        {
            sy6: "",
            sy9: "",
            lt12: "",
            dt1: "Gamaliel",
            sy7: "",
            dt2: "",
            sy5: "ܓܰܡܰܠܺܐܝܶܠ",
            lt10: "ܓܡܠܐܝܠ",
            lt11: "ܓܡܠܐܝܠ",
            sy4: "ܓܡܠܐܝܠ",
            id: "1467",
            sy8: "",
            dt3: ""
        },
        {
            sy9: "",
            sy5: "ܒܶܬܽܘܐܝܺܠ",
            sy8: "",
            sy7: "",
            sy4: "ܒܬܘܐܝܠ",
            dt1: "Betuel",
            id: "597",
            lt11: "ܒܬܘܐܝܠ",
            lt12: "",
            dt3: "",
            dt2: "",
            sy6: "",
            lt10: "ܒܬܘܐܝܠ"
        },
        {
            lt12: "",
            dt2: "",
            dt1: "Lehrgang",
            lt11: "",
            sy5: "ܫܒܺܝܠ ܝܽܘܠܦܳܢܳܐ",
            sy9: "",
            sy6: "",
            sy4: "ܫܒܝܠ ܝܘܠܦܢܐ",
            sy7: "",
            id: "2743",
            dt3: "",
            lt10: "shbil yulfono",
            sy8: ""
        },
        {
            lt10: "treyn alfin",
            sy8: "ܒܼ",
            sy5: "ܬܪܶܝܢ ܐܰܠܦܝܺܢ",
            dt1: "2000",
            dt2: "zweitausend",
            lt11: "tarteyn alfin",
            sy6: "ܬܪܬܝܢ ܐܠܦܝܢ",
            sy4: "ܬܪܝܢ ܐܠܦܝܢ",
            id: "48",
            lt12: "beth u nuqso",
            dt3: "",
            sy9: "ܒܼ",
            sy7: "ܬܰܪܬܶܝܢ ܐܰܠܦܺܝܢ"
        },
        {
            lt10: "morunoyo",
            sy9: "",
            dt2: "",
            sy6: "ܣܘܪܝܝܐ ܣܪܘܢܝܐ",
            id: "2944",
            sy8: "",
            sy7: "ܣܽܘܪܝܳܝܳܐ ܡܳܪܽܘܢܳܝܳܐ",
            dt1: "Maronit",
            lt11: "suryoyo morunoyo",
            sy4: "ܡܪܘܢܝܐ",
            sy5: "ܡܳܪܽܘܢܳܝܳܐ",
            dt3: "",
            lt12: ""
        },
        {
            lt10: "malfon reqđo",
            sy6: "",
            sy4: "ܡܠܦܢ ܪܩܕܐ",
            dt2: "",
            sy7: "",
            sy5: "ܡܰܠܦܳܢ ܪܶܩܕܳܐ",
            id: "4384",
            sy8: "",
            lt12: "",
            lt11: "",
            sy9: "",
            dt1: "Tanzlehrer",
            dt3: ""
        },
        {
            lt11: "kafo d`iđo",
            sy7: "ܟܰܦܳܐ ܕܐܺܝܳܕܐ",
            lt12: "",
            dt3: "",
            sy5: "ܟܰܦܳܐ",
            sy6: "ܟܦܐ ܕܐܝܕܐ",
            lt10: "kafo",
            dt1: "Handfläche",
            dt2: "",
            id: "1854",
            sy9: "",
            sy8: "",
            sy4: "ܟܦܐ"
        },
        {
            sy5: "ܓܳܙܠܳܐ",
            sy7: "",
            lt10: "goslo , guslo",
            dt2: "",
            sy6: "",
            dt1: "Linkshänder",
            dt3: "",
            lt11: "",
            sy4: "ܓܙܠܐ",
            sy9: "",
            id: "2841",
            sy8: "",
            lt12: ""
        },
        {
            sy9: "",
            sy7: "",
            sy6: "",
            dt1: "Maß",
            sy4: "ܥܘܠܒܐ",
            id: "2959",
            sy8: "",
            lt11: "",
            dt2: "Gewicht",
            dt3: "",
            sy5: "ܥܽܘܠܒܳܐ",
            lt10: "ĉulbo",
            lt12: ""
        },
        {
            dt3: "",
            lt12: "",
            sy8: "",
            sy7: "ܡܰܚܝܳܢܳܐ",
            sy4: "ܡܚܝܐ",
            sy5: "ܡܰܚܳܝܳܐ",
            dt2: "Prügler",
            lt10: "maĥoyo",
            sy6: "ܡܚܝܢܐ",
            lt11: "maĥyono",
            id: "3964",
            sy9: "",
            dt1: "Schläger"
        },
        {
            lt11: "akhmo d",
            id: "5083",
            dt1: "wie",
            sy5: "ܐܰܝܟܰܢܳܐ",
            sy9: "",
            sy6: "ܐܟܡܐ ܕ",
            dt2: "damit",
            lt10: "aykano",
            lt12: "",
            sy8: "",
            sy4: "ܐܝܟܢܐ",
            dt3: "",
            sy7: "ܐܰܟ݂ܡܳܐ ܕ"
        },
        {
            sy9: "",
            id: "4351",
            sy6: "",
            dt1: "Tabelle",
            lt11: "",
            lt12: "",
            lt10: "silidon",
            dt2: "",
            sy4: "ܣܝܠܝܕܘܢ",
            sy5: "ܣܺܝܠܺܝܕܳܘܢ",
            sy7: "",
            dt3: "",
            sy8: ""
        },
        {
            dt2: "",
            sy5: "ܪܶܟܳܒ",
            lt12: "ܪܟܘܒ",
            sy4: "ܪܟܒ",
            dt1: "Rekob",
            dt3: "",
            sy6: "ܪܟܘܒ",
            lt10: "ܪܟܒ",
            sy7: "ܪܰܟܽܘܒ",
            lt11: "ܪܟܒ",
            sy8: "",
            sy9: "",
            id: "3684"
        },
        {
            lt12: "",
            sy6: "ܕܠܐ ܦܘܠܚܢܐ",
            sy7: "ܕܠܳܐ ܦܽܘܠܚܳܢܳܐ",
            sy5: "ܕܠܳܐ ܐܽܘܡܳܢܽܘܬ݂ܳܐ",
            dt2: "",
            sy9: "",
            sy8: "",
            dt3: "",
            lt11: "d`lo fulĥono",
            dt1: "ohne Beruf",
            sy4: "ܕܠܐ ܐܘܡܢܘܬܐ",
            lt10: "d`lo umonutho",
            id: "3354"
        },
        {
            id: "2253",
            sy6: "",
            dt3: "",
            sy7: "",
            sy9: "",
            sy5: "ܐܺܝܬ݂",
            dt1: "ist",
            lt12: "",
            lt10: "ith",
            lt11: "",
            dt2: "es gibt",
            sy8: "",
            sy4: "ܐܝܬ"
        },
        {
            lt10: "qoqo",
            lt12: "",
            sy5: "ܩܳܩܳܐ",
            id: "3430",
            dt2: "",
            sy9: "",
            dt3: "",
            lt11: "",
            sy4: "ܩܩܐ",
            sy6: "",
            sy7: "",
            dt1: "Pelikan",
            sy8: ""
        },
        {
            dt2: "",
            sy6: "ܡܕܚܠܢܘܬܐ",
            id: "5638",
            sy9: "",
            sy7: "ܡܕܰܚܠܳܢܽܘܬ݂ܳܐ",
            dt1: "Abschreckung",
            sy4: "ܡܙܝܥܢܘܬܐ",
            lt12: "",
            sy5: "ܡܙܺܝܥܳܢܽܘܬ݂ܳܐ",
            lt11: "mdaĥlonutho",
            lt10: "mziĉonutho",
            sy8: "",
            dt3: ""
        },
        {
            lt12: "",
            lt10: "fe",
            sy8: "",
            dt1: "f",
            sy4: "ܦ",
            dt2: "",
            sy6: "",
            dt3: "",
            id: "1222",
            lt11: "",
            sy9: "",
            sy7: "",
            sy5: "ܦ"
        },
        {
            sy8: "",
            sy7: "",
            dt1: "Zeichentrickfilm",
            sy4: "ܦܝܠܡܐ ܕܪܘܫܡܐ",
            dt3: "",
            sy6: "",
            lt12: "",
            lt10: "filmo d`rushmo",
            lt11: "",
            id: "5280",
            dt2: "Zeichentrick",
            sy5: "ܦܺܝܠܡܳܐ ܕܪܽܘܫܡܳܐ",
            sy9: ""
        },
        {
            lt11: "ܦܪܢܟ",
            dt3: "",
            sy5: "ܦܪܰܢܟ",
            dt2: "",
            lt10: "ܦܪܢܟ",
            dt1: "Frank",
            id: "1362",
            sy6: "",
            sy4: "ܦܪܢܟ",
            sy9: "",
            sy7: "",
            lt12: "",
            sy8: ""
        },
        {
            lt10: "ruĥqo",
            lt12: "",
            dt2: "",
            sy7: "",
            dt1: "Distanz",
            sy5: "ܪܽܘܚܩܳܐ",
            sy8: "",
            lt11: "",
            id: "905",
            dt3: "",
            sy4: "ܪܘܚܩܐ",
            sy9: "",
            sy6: ""
        },
        {
            lt12: "",
            lt10: "qeloyto da`dmokho",
            dt1: "Schlafzimmer",
            sy5: "ܩܶܠܳܝܬܳܐ ܕܰܕܡܳܟ݂ܳܐ",
            sy8: "",
            id: "3962",
            sy9: "",
            dt3: "",
            sy4: "ܩܠܝܬܐ ܕܕܡܟ݂ܐ",
            dt2: "",
            sy6: "",
            lt11: "",
            sy7: ""
        },
        {
            sy5: "ܦܪܰܥ",
            dt3: "",
            sy7: "ܫܪܳܐ",
            lt10: "fraĉ",
            dt2: "",
            id: "5717",
            sy4: "ܦܪܥ",
            sy8: "",
            sy6: "ܫܪܐ",
            dt1: "abzahlen",
            sy9: "",
            lt12: "",
            lt11: "shro"
        },
        {
            dt1: "Ingenieur",
            sy8: "",
            sy7: "",
            id: "2203",
            lt12: "",
            lt10: "ardikhlo",
            sy9: "",
            dt3: "",
            sy6: "",
            sy5: "ܐܰܪܕܺܝܟ݂ܠܳܐ",
            sy4: "ܐܪܕܝܟܠܐ",
            dt2: "",
            lt11: ""
        },
        {
            lt12: "",
            sy9: "",
            sy5: "ܥܰܪܣܳܐ ܕܒܽܘܓ݂ܪܳܐ",
            sy4: "ܥܪܣܐ ܕܒܘܓܪܐ",
            sy6: "",
            lt10: "ĉarso d`buğre",
            id: "2465",
            sy8: "",
            dt1: "Kieselbett",
            sy7: "",
            dt2: "",
            lt11: "",
            dt3: ""
        },
        {
            dt2: "versteckt",
            lt11: "taljo",
            sy7: "ܬܰܠܝܳܐ",
            sy5: "ܟܰܣܝܳܐ",
            sy9: "",
            lt10: "kasyo",
            id: "1530",
            sy8: "",
            dt1: "geheim",
            sy6: "ܬܠܝܐ",
            dt3: "",
            lt12: "",
            sy4: "ܟܣܝܐ"
        },
        {
            dt1: "Empfänger",
            sy5: "ܢܳܣܽܘܒܳܐ",
            sy6: "",
            lt11: "",
            lt10: "nosubo",
            sy8: "",
            sy7: "",
            dt3: "",
            sy4: "ܢܣܘܒܐ",
            lt12: "",
            dt2: "",
            sy9: "",
            id: "1098"
        },
        {
            lt12: "",
            dt2: "Zugabe",
            sy4: "ܐܩܦܬܐ",
            dt1: "Zusatz",
            sy9: "",
            sy7: "ܡܰܩܦܳܢܽܘܬ݂ܳܐ",
            sy5: "ܐܰܩܶܦܬ݂ܳܐ",
            sy6: "ܡܩܦܢܘܬܐ",
            lt10: "aqeftho",
            lt11: "maqfonutho",
            id: "5383",
            dt3: "",
            sy8: ""
        },
        {
            dt1: "hoch",
            lt10: "ĉeloyo",
            lt11: "",
            sy9: "",
            sy4: "ܥܠܝܐ",
            sy6: "",
            id: "2029",
            lt12: "",
            sy5: "ܥܶܠܶܝܳܐ",
            sy7: "",
            dt3: "",
            sy8: "",
            dt2: ""
        },
        {
            lt11: "shuro",
            lt12: "syoğo",
            sy7: "ܫܽܘܪܳܐ",
            dt3: "",
            sy9: "ܣܝܳܓ݂ܳܐ",
            sy8: "ܣܝܓܐ",
            dt2: "",
            id: "4976",
            sy5: "ܚܨܳܪܳܐ",
            sy4: "ܚܨܪܐ",
            sy6: "ܫܘܪܐ",
            lt10: "ĥŝoro",
            dt1: "Wand"
        },
        {
            sy6: "",
            dt3: "",
            lt10: "ksaĥ",
            sy5: "ܟܣܰܚ",
            sy4: "ܟܣܚ",
            sy9: "",
            lt11: "",
            lt12: "",
            sy8: "",
            sy7: "",
            dt2: "",
            dt1: "abholzen",
            id: "5573"
        },
        {
            sy6: "",
            dt1: "Möhre",
            sy9: "",
            sy8: "",
            lt12: "",
            sy4: "ܓܙܪܐ",
            sy7: "",
            id: "3076",
            lt10: "gesoro",
            dt2: "Karotte",
            sy5: "ܓܙܳܪܳܐ",
            dt3: "Mohrrübe",
            lt11: ""
        },
        {
            sy5: "ܢܽܘܩܙܳܐ ܕܰܓܠܺܝܕܽܘܬ݂ܳܐ",
            lt11: "",
            sy4: "ܢܘܩܙܐ ܕܓܠܝܕܘܬܐ",
            dt2: "",
            id: "1521",
            lt10: "nuqso da`gliđutho",
            sy9: "",
            sy6: "",
            dt3: "",
            dt1: "Gefrierpunkt",
            sy8: "",
            lt12: "",
            sy7: ""
        },
        {
            sy4: "ܚܝܐ ܕܦܠܚܐ",
            lt12: "",
            lt10: "ĥaye d`folĥo",
            id: "4187",
            dt2: "",
            dt3: "",
            dt1: "Soldatenleben",
            sy5: "ܚܰܝ̈ܶܐ ܕܦܳܠܚܳܐ",
            sy6: "",
            sy8: "",
            lt11: "",
            sy7: "",
            sy9: ""
        },
        {
            dt3: "",
            sy9: "",
            lt10: "sathwoyo",
            id: "5118",
            sy6: "",
            lt12: "",
            dt1: "winterlich",
            dt2: "",
            sy5: "ܣܰܬ݂ܘܳܝܳܐ",
            lt11: "",
            sy8: "",
            sy4: "ܣܬܘܝܐ",
            sy7: ""
        },
        {
            sy9: "",
            sy8: "",
            dt1: "nachteilig",
            sy7: "",
            id: "3168",
            lt12: "",
            dt2: "",
            sy4: "ܡܚܣܪܢܐ",
            lt11: "",
            sy5: "ܡܰܚܣܪܳܢܳܐ",
            dt3: "",
            lt10: "maĥsrono",
            sy6: ""
        },
        {
            lt12: "",
            sy7: "",
            dt2: "",
            id: "3691",
            sy5: "ܪܶܗܛܳܐ",
            lt11: "",
            sy6: "",
            sy8: "",
            sy4: "ܪܗܛܐ",
            lt10: "rehțo",
            dt3: "",
            sy9: "",
            dt1: "Rennen"
        },
        {
            id: "252",
            sy6: "",
            sy8: "",
            dt3: "",
            sy7: "",
            lt11: "",
            sy5: "ܦܽܘܢܳܝܳܐ",
            lt10: "funoyo",
            dt1: "Antwort",
            lt12: "",
            sy4: "ܦܘܢܝܐ",
            sy9: "",
            dt2: "Reaktion"
        },
        {
            sy8: "",
            sy5: "ܕܰܠܬ݂ܰܚܬ ܒܟܽܠ",
            dt2: "",
            dt1: "am untersten",
            lt10: "dalthaĥt bkul",
            sy6: "",
            sy9: "",
            sy7: "",
            dt3: "",
            lt12: "",
            sy4: "ܕܠܬܚܬ ܒܟܠ",
            id: "5879",
            lt11: ""
        },
        {
            sy4: "ܒܡܰܘܕܝܳܢܽܘܬ݂ܳܐ ܡܬ݂ܽܘܡܳܝܬܳܐ",
            dt2: "",
            sy7: "",
            lt10: "bmaudyonutho mthumoyto",
            dt3: "",
            dt1: "In ewiger Dankbarkeit",
            sy8: "",
            sy9: "",
            sy5: "ܒܡܰܘܕܝܳܢܽܘܬ݂ܳܐ ܡܬ݂ܽܘܡܳܝܬܳܐ",
            sy6: "",
            lt12: "",
            lt11: "",
            id: "5440"
        },
        {
            sy8: "",
            sy5: "ܒܶܝܬ݂ ܚܶܙܘܳܐ",
            dt3: "",
            sy4: "ܒܝܬ ܚܙܘܐ",
            sy9: "",
            lt11: "",
            dt1: "Kino",
            dt2: "",
            lt12: "",
            lt10: "beth ĥezwo",
            sy6: "",
            id: "2483",
            sy7: ""
        },
        {
            lt11: "ܟܪܚܐ",
            sy4: "ܟܪܚܐ",
            lt10: "ܟܪܚܐ",
            sy7: "ܟܽܘܪܚܳܐ",
            dt1: "Kerho",
            id: "2457",
            dt2: "",
            sy8: "",
            sy9: "",
            sy6: "ܟܘܪܚܐ",
            lt12: "ܟܘܪܚܐ",
            dt3: "",
            sy5: "ܟܶܪܚܳܐ"
        },
        {
            dt2: "stattdessen",
            lt11: "mĥlof",
            sy9: "ܒܕܽܘܟܰܬ݂",
            lt10: "ĥlof",
            lt12: "bdukath",
            sy5: "ܚܠܳܦ",
            sy7: "ܡܚܠܳܦ",
            sy4: "ܚܠܦ",
            dt3: "an Stelle von",
            sy8: "ܒܕܘܟܬ",
            sy6: "ܡܚܠܦ",
            dt1: "anstatt",
            id: "244"
        },
        {
            id: "4914",
            sy9: "",
            sy5: "ܘ",
            dt3: "",
            sy4: "ܘ",
            sy6: "",
            lt11: "",
            sy7: "",
            sy8: "",
            lt10: "wauw",
            lt12: "",
            dt2: "",
            dt1: "w"
        },
        {
            dt1: "Knochen",
            dt3: "",
            lt11: "",
            sy5: "ܓܰܪܡܳܐ",
            sy8: "",
            sy7: "",
            lt10: "garmo",
            lt12: "",
            sy9: "",
            sy6: "",
            id: "2547",
            dt2: "",
            sy4: "ܓܪܡܐ"
        },
        {
            sy4: "ܦܚܡܐ ܫܚܡܐ",
            lt11: "",
            sy9: "",
            dt2: "",
            dt3: "",
            lt12: "",
            sy8: "",
            id: "692",
            lt10: "faĥmo shoĥmo",
            sy6: "",
            sy5: "ܦܰܚܡܳܐ ܫܳܚܡܳܐ",
            dt1: "Braunkohle",
            sy7: ""
        },
        {
            sy5: "ܣܰܥܺܝܕ",
            sy4: "ܣܥܝܕ",
            dt1: "Sahid",
            lt12: "",
            id: "3806",
            sy8: "",
            sy9: "",
            lt11: "ܣܥܝܕ",
            sy7: "",
            dt3: "",
            dt2: "",
            lt10: "ܣܥܝܕ",
            sy6: ""
        },
        {
            sy6: "",
            lt12: "",
            sy4: "ܒܣܝܒܪܝܢܐ",
            id: "487",
            sy5: "ܒܶܝܬܣܒܺܝܪܺܝܢܰܐ",
            sy7: "",
            lt10: "ܒܣܝܒܪܝܢܐ",
            sy9: "",
            dt1: "Basibrina",
            dt3: "",
            dt2: "",
            sy8: "",
            lt11: "ܒܣܝܒܪܝܢܐ"
        },
        {
            sy6: "",
            lt11: "",
            dt1: "Hotelbesitzer",
            sy5: "ܦܽܘܬܩܳܝܳܐ",
            sy7: "",
            id: "2058",
            sy9: "",
            dt3: "",
            lt10: "futqoyo",
            lt12: "",
            sy8: "",
            sy4: "ܦܘܬܩܝܐ",
            dt2: "Hotelier"
        },
        {
            id: "4014",
            lt12: "",
            dt2: "wünsche Dir einen schönen Schlaf",
            sy4: "ܫܢܬܐ ܚܠܝܬܐ",
            sy5: "ܫܰܢܬ݂ܳܐ ܚܠܺܝܬ݂ܳܐ",
            sy8: "",
            sy7: "",
            sy9: "",
            lt10: "shantho ĥlitho",
            sy6: "",
            dt1: "schönen Schlaf",
            dt3: "angenehmen Schlaf",
            lt11: ""
        },
        {
            dt1: "Agrarwissenschaftler",
            id: "5774",
            dt2: "",
            dt3: "",
            sy5: "ܝܳܕܽܘܥܳܐ ܕܙܰܪܳܥܽܘܬ݂ܳܐ",
            sy7: "",
            sy6: "",
            lt12: "",
            sy8: "",
            lt11: "",
            lt10: "yoduĉo dzaroĉutho",
            sy4: "ܝܕܘܥܐ ܕܙܪܥܘܬܐ",
            sy9: ""
        },
        {
            id: "2687",
            sy8: "",
            lt10: "ܠܒܢ",
            sy4: "ܠܒܢ",
            sy9: "",
            sy5: "ܠܰܒܰܢ",
            sy7: "",
            dt2: "",
            dt3: "",
            lt12: "",
            dt1: "Laban",
            lt11: "ܠܒܢ",
            sy6: ""
        },
        {
            lt12: "",
            sy6: "",
            sy4: "ܒܝܬ ܪܕܝܬܐ",
            lt10: "beth rađoyto",
            sy7: "",
            dt2: "",
            sy8: "",
            sy9: "",
            dt3: "",
            id: "1471",
            lt11: "",
            dt1: "Garage",
            sy5: "ܒܶܝܬ݂ ܪܰܕܳܝܬܳܐ"
        },
        {
            sy7: "",
            sy5: "ܣܢܺܝܩܽܘܬ݂ܳܐ",
            dt3: "",
            sy6: "",
            id: "3301",
            lt10: "sniqutho",
            lt11: "",
            dt1: "Notwendigkeit",
            dt2: "Bedürfnis",
            sy4: "ܣܢܝܩܘܬܐ",
            lt12: "",
            sy9: "",
            sy8: ""
        },
        {
            dt1: "verängstigen",
            lt10: "maziĉ",
            sy4: "ܡܐܙܝܥ",
            lt12: "",
            sy9: "",
            sy8: "",
            sy6: "",
            dt2: "",
            lt11: "",
            id: "4717",
            sy7: "",
            dt3: "",
            sy5: "ܡܰܐܙܺܝܥ"
        },
        {
            id: "5023",
            sy4: "ܙܒܢܐ ܕܥܐܕܐ ܕܡܘܠܕܐ",
            dt2: "",
            sy9: "",
            lt10: "zabno d`ĉiđo d`mauwlodo",
            sy8: "",
            sy7: "",
            sy5: "ܙܰܒܢܳܐ ܕܥܺܐܕܳܐ ܕܡܰܘܠܳܕܳܐ",
            dt3: "",
            sy6: "",
            lt11: "",
            dt1: "Weihnachtszeit",
            lt12: ""
        },
        {
            sy8: "",
            dt3: "",
            sy5: "ܓܰܪܡܳܐ ܢܰܫܺܝܦܳܐ",
            lt10: "garmo nashifo",
            sy6: "",
            dt1: "harter Knochen",
            dt2: "",
            sy7: "",
            lt11: "",
            sy4: "ܓܪܡܐ ܢܫܝܦܐ",
            id: "6184",
            lt12: "",
            sy9: ""
        },
        {
            id: "499",
            sy7: "",
            dt2: "",
            dt3: "",
            lt10: "ܒܪܬܐܝܠ",
            sy8: "",
            sy6: "",
            lt12: "",
            sy9: "",
            lt11: "ܒܪܬܐܝܠ",
            sy4: "ܒܪ̱ܬܐܝܠ",
            sy5: "ܒܰܪ̱ܬܐܝܺܠ",
            dt1: "Bathil"
        },
        {
            sy4: "ܪܡܫܐ ܕܥܐܕܐ ܕܡܘܠܕܐ",
            lt11: "",
            sy6: "",
            lt10: "ramsho d`ĉiđo d`maolodo",
            lt12: "",
            sy5: "ܪܰܡܫܳܐ ܕܥܺܐܕܳܐ ܕܡܘܠܕܐ",
            sy7: "",
            dt1: "Heiligabend",
            dt3: "",
            id: "1925",
            sy8: "",
            dt2: "",
            sy9: ""
        },
        {
            sy9: "",
            dt1: "Mitternacht",
            sy8: "",
            id: "6348",
            dt3: "",
            dt2: "",
            sy7: "ܦܶܠܓܽܘܬ݂ ܕܠܶܠܝܳܐ",
            sy4: "ܦܠܓܗ ܕܠܠܝܐ",
            lt12: "",
            sy6: "ܦܠܓܘܬ ܕܠܠܝܐ",
            lt10: "felgeh d'lelyo",
            lt11: "felguth d'lelyo",
            sy5: "ܦܶܠܓܶܗ ܕܠܶܠܝܳܐ"
        },
        {
            lt11: "shahro",
            id: "1295",
            sy5: "ܚܰܓܳܐ",
            sy8: "ܥܕܥܐܕܐ",
            dt2: "Feier",
            dt1: "Fest",
            sy4: "ܚܓܐ",
            lt10: "ĥago",
            sy7: "ܫܰܗܪܳܐ",
            sy6: "ܫܗܪܐ",
            lt12: "ĉadĉido",
            sy9: "ܥܰܕܥܺܐܕܳܐ",
            dt3: "Party"
        },
        {
            sy7: "",
            sy6: "",
            lt11: "",
            sy5: "ܒܪ̱ܰܬ݂ ܙܰܘܓܳܐ",
            dt1: "Ehegattin",
            dt2: "Ehefrau",
            id: "1009",
            sy8: "",
            lt12: "",
            dt3: "",
            sy9: "",
            lt10: "bath zaugo",
            sy4: "ܒܪܬ ܙܘܓܐ"
        },
        {
            sy8: "",
            sy4: "ܚܡܬܐ",
            sy7: "",
            sy6: "",
            dt2: "",
            sy5: "ܚܡܳܬ݂ܳܐ",
            id: "4080",
            lt10: "ĥmotho",
            lt12: "",
            sy9: "",
            dt1: "Schwiegermutter",
            dt3: "",
            lt11: ""
        },
        {
            lt11: "sofeq",
            dt3: "",
            lt10: "shtuq",
            sy5: "ܫܬܽܘܩ ",
            sy4: "ܫܬܘܩ ",
            dt2: "Sei still",
            id: "4099",
            sy6: "ܣܦܩ",
            sy7: "ܣܳܦܶܩ",
            sy8: "",
            sy9: "",
            dt1: "Sei ruhig",
            lt12: ""
        },
        {
            lt12: "",
            sy7: "ܢܰܟܦܽܘܬ݂ܳܐ",
            dt3: "",
            dt2: "Scham",
            lt10: "nukofo",
            id: "3932",
            sy8: "",
            lt11: "nakfutho",
            sy6: "ܢܟܦܘܬܐ",
            dt1: "Scheu",
            sy4: "ܢܘܟܦܐ",
            sy9: "",
            sy5: "ܢܽܘܟܳܦܳܐ"
        },
        {
            dt2: "",
            sy4: "ܩܠܝܡܐ",
            lt11: "",
            sy6: "",
            id: "2525",
            sy5: "ܩܠܺܝܡܳܐ",
            sy9: "",
            sy7: "",
            sy8: "",
            dt1: "Klima",
            lt12: "",
            lt10: "qlimo",
            dt3: ""
        },
        {
            sy4: "ܚܪܕܠܐ",
            sy6: "",
            sy5: "ܚܰܪܕܠܳܐ",
            dt3: "",
            dt2: "",
            sy9: "",
            dt1: "Senf",
            id: "4118",
            lt10: "ĥardlo",
            sy8: "",
            sy7: "",
            lt12: "",
            lt11: ""
        },
        {
            sy4: "ܠܦܦܐ",
            sy8: "",
            sy6: "",
            id: "708",
            dt2: "Umschlag",
            lt12: "",
            sy9: "",
            lt10: "lfofo",
            sy5: "ܠܦܳܦܳܐ",
            sy7: "",
            dt3: "",
            lt11: "",
            dt1: "Briefumschlag"
        },
        {
            sy5: "ܒܢܳܝܳܐ",
            sy8: "ܥܡܪܐ",
            sy4: "ܒܢܝܐ",
            lt12: "ĉmoro",
            dt1: "Bau",
            sy6: "ܫܬܐܣܬܐ",
            sy7: "ܫܶܬܶܐܣܬܳܐ",
            dt2: "Bauen",
            id: "506",
            lt11: "shetesto",
            lt10: "bnoyo",
            dt3: "",
            sy9: "ܥܡܳܪܳܐ"
        },
        {
            sy7: "ܢܶܣܝܳܢܳܐ",
            lt11: "nesyono",
            id: "1145",
            sy4: "ܢܣܝܘܢܐ",
            lt10: "nesyuno",
            sy9: "",
            lt12: "",
            dt1: "Erfahrung",
            sy5: "ܢܶܣܝܽܘܢܳܐ",
            sy8: "",
            sy6: "ܢܣܝܢܐ",
            dt2: "Versuchung",
            dt3: ""
        },
        {
            lt10: "malel",
            sy8: "",
            dt3: "diskutieren",
            lt12: "",
            lt11: "",
            sy6: "",
            sy5: "ܡܰܠܶܠ",
            dt2: "sprechen",
            sy9: "",
            sy4: "ܡܠܠ",
            dt1: "reden",
            sy7: "",
            id: "3650"
        },
        {
            dt2: "",
            sy9: "",
            dt3: "",
            sy6: "",
            sy7: "",
            sy4: "ܦܪܣܐ ܕܙܪܥܘܬܐ",
            lt10: "froso dzaroĉutho",
            sy5: "ܦܪܳܣܳܐ ܕܙܰܪܳܥܽܘܬ݂ܳܐ",
            lt11: "",
            lt12: "",
            dt1: "Agrarausstellung",
            sy8: "",
            id: "5785"
        },
        {
            sy9: "ܥܰܡܶܠ",
            dt2: "",
            lt10: "rabeb",
            lt12: "ĉamel",
            sy5: "ܪܰܒܶܒ",
            id: "6306",
            sy8: "ܥܡܠ",
            lt11: "falaĥ",
            sy4: "ܪܒܒ",
            sy6: "ܦܠܚ",
            dt1: "züchten",
            sy7: "ܦܰܠܰܚ",
            dt3: ""
        },
        {
            sy9: "",
            sy8: "",
            sy7: "",
            dt3: "",
            dt1: "Kahinta",
            sy4: "ܟܗܝܢܬܐ",
            id: "2379",
            dt2: "",
            lt11: "ܟܗܝܢܬܐ",
            lt12: "",
            sy5: "ܟܰܗܺܝܢܬܰܐ",
            sy6: "",
            lt10: "ܟܗܝܢܬܐ"
        },
        {
            id: "3784",
            dt1: "Sabar",
            sy9: "",
            dt3: "",
            sy5: "ܣܰܒܰܪ",
            sy4: "ܣܒܪ",
            lt12: "",
            sy6: "",
            dt2: "",
            sy7: "",
            lt10: "ܣܒܪ",
            sy8: "",
            lt11: "ܣܒܪ"
        },
        {
            lt10: "msharro",
            sy8: "",
            sy6: "",
            sy4: "ܡܫܪܪܐ",
            sy5: "ܡܫܰܪܪܳܐ",
            dt1: "sicher",
            lt12: "",
            sy7: "",
            id: "4139",
            dt3: "",
            lt11: "",
            dt2: "",
            sy9: ""
        },
        {
            sy6: "ܕܪܐ",
            sy8: "",
            sy9: "",
            lt10: "ܕܘܪܐ",
            sy5: "ܕܰܘܪܰܐ",
            sy4: "ܕܘܪܐ",
            id: "918",
            dt2: "",
            lt12: "ܕܪܐ",
            dt3: "",
            sy7: "ܕܳܪܰܐ",
            dt1: "Dora",
            lt11: "ܕܘܪܐ"
        },
        {
            lt12: "kasyoith",
            sy4: "ܒܪܐܙܐ",
            sy8: "ܟܣܝܐܝܬ",
            sy6: "ܟܣܝܐ",
            lt11: "kasyo",
            dt2: "",
            sy7: "ܟܰܣܝܳܐ",
            dt3: "",
            sy5: "ܒܪܳܐܙܳܐ",
            id: "1938",
            lt10: "brozo",
            dt1: "heimlich",
            sy9: "ܟܰܣܝܳܐܝܺܬ"
        },
        {
            dt1: "Hasael",
            dt2: "",
            sy6: "",
            sy5: "ܚܰܙܰܐܝܶܠ",
            lt12: "",
            lt11: "ܚܙܐܝܠ",
            lt10: "ܚܙܐܝܠ",
            id: "1876",
            sy4: "ܚܙܐܝܠ",
            sy8: "",
            sy7: "",
            sy9: "",
            dt3: ""
        },
        {
            lt11: "",
            lt12: "",
            dt2: "",
            id: "3123",
            dt1: "Mühlenspiel",
            sy6: "",
            sy9: "",
            dt3: "",
            sy5: "ܫܶܥܝܳܐ ܕܰܬܫܰܥ ܟܺܐܦܶܐ",
            sy7: "",
            sy8: "",
            sy4: "ܫܥܝܐ ܕܬܫܥ ܟܐܦܐ",
            lt10: "sheĉyo da`tshaĉ kife"
        },
        {
            sy7: "",
            sy5: "ܩܰܫܺܝܫܽܘܬ݂ܳܐ",
            id: "3511",
            dt2: "",
            lt12: "",
            lt10: "qashishutho",
            sy8: "",
            dt3: "",
            sy6: "",
            sy9: "",
            lt11: "",
            dt1: "Priestertum",
            sy4: "ܩܫܝܫܘܬܐ"
        },
        {
            id: "1209",
            sy7: "",
            sy4: "ܠܥܠܡ ܕܝܠܟ ܠܥܠܡ ܕܝܠܝ ܠܥܠܡ ܕܝܠܢ",
            sy5: "ܠܥܳܠܰܡ ܕܺܝܠܳܟ݂ ܠܥܳܠܰܡ ܕܺܝܠܝ̱ ܠܥܳܠܰܡ ܕܺܝܠܰܢ",
            dt1: "ewig dein ewig mein ewig uns",
            sy8: "",
            dt3: "",
            lt11: "",
            dt2: "",
            lt12: "",
            sy9: "",
            sy6: "",
            lt10: "lĉolam dilokh lĉolam dil , lĉolam dilan"
        },
        {
            sy4: "ܕܠܐ ܓܕܐ",
            sy8: "",
            sy7: "",
            dt1: "ohne Glück",
            sy5: "ܕܠܳܐ ܓܰܕܳܐ",
            sy6: "",
            sy9: "",
            dt2: "",
            lt10: "d`lo gado",
            dt3: "",
            id: "3355",
            lt11: "",
            lt12: ""
        },
        {
            sy6: "ܬܟܝܠܐ",
            sy9: "ܡܶܬ݂ܢܰܩܦܳܢܳܐ",
            lt12: "methnaqfono",
            dt1: "intim",
            lt10: "ftiĥ lebo",
            sy4: "ܦܬܝܚ ܠܒܐ",
            dt2: "",
            dt3: "",
            sy7: "ܬܟ݂ܺܝܠܳܐ",
            sy8: "ܡܬܢܩܦܢܐ",
            sy5: "ܦܬܺܝܚ ܠܶܒܳܐ",
            id: "6174",
            lt11: "tkhilo"
        },
        {
            dt2: "",
            dt3: "",
            sy9: "",
            lt10: " habusho dodom",
            sy4: "ܗܒܘܫܐ ܕܐܕܡ",
            sy6: "",
            sy5: "ܗܰܒܽܘܫܳܐ ܕܐܳܕܳܡ",
            lt12: "",
            id: "5744",
            sy8: "",
            dt1: "Adamsapfel",
            sy7: "",
            lt11: ""
        },
        {
            sy6: "ܣܒܪܐ",
            id: "2038",
            dt2: "Geduld",
            dt1: "Hoffnung",
            sy9: "",
            sy7: "ܣܰܒܪܳܐ",
            sy5: "ܡܣܰܒܪܳܢܽܘܬ݂ܳܐ",
            lt10: "msabronutho",
            dt3: "Langmut",
            lt12: "",
            sy4: "ܡܣܒܪܢܘܬܐ",
            lt11: "sabro",
            sy8: ""
        },
        {
            dt3: "",
            dt2: "vermögend",
            sy4: "ܥܬܝܪܐ",
            sy7: "",
            sy5: "ܥܰܬܺܝܪܳܐ",
            sy8: "",
            sy9: "",
            dt1: "reich",
            id: "3667",
            sy6: "",
            lt10: "ĉatiro",
            lt11: "",
            lt12: ""
        },
        {
            sy7: "",
            lt11: "",
            sy5: "ܚܰܒܠܳܐ ܚܠܺܝܡܳܐ",
            sy4: "ܚܒܠܐ ܚܠܝܡܐ",
            lt10: "ĥablo ĥlimo",
            sy8: "",
            dt3: "",
            lt12: "",
            sy9: "",
            dt2: "",
            id: "4240",
            dt1: "starkes Seil",
            sy6: ""
        },
        {
            sy6: "",
            sy5: "ܙܰܒܢܳܐ ܐܰܪܺܝܟ݂ܳܐ",
            dt3: "",
            dt2: "",
            sy7: "",
            sy8: "",
            sy9: "",
            dt1: "lange Zeit",
            sy4: "ܙܒܢܐ ܐܪܝܟܐ",
            id: "2706",
            lt10: "zabno arikho",
            lt11: "",
            lt12: ""
        },
        {
            sy8: "",
            lt10: "ܕܐܝܢܐ",
            sy5: "ܕܺܐܝܰܢܳܐ",
            lt11: "ܕܐܝܢܐ",
            dt1: "Diana",
            sy4: "ܕܐܝܢܐ",
            lt12: "ܕܝܢܐ",
            id: "878",
            dt2: "",
            dt3: "",
            sy6: "ܕܝܢܐ",
            sy9: "",
            sy7: "ܕܝܰܢܰܐ"
        },
        {
            sy7: "",
            sy8: "",
            dt1: "Wirt",
            dt3: "",
            sy6: "",
            sy4: "ܡܕܢܝܐ",
            lt10: "madonoyo",
            lt11: "",
            id: "5130",
            sy5: "ܡܰܕܳܢܳܝܳܐ",
            sy9: "",
            lt12: "",
            dt2: ""
        },
        {
            dt2: "",
            lt11: "",
            sy4: "ܩܠܪܐ",
            lt12: "",
            sy5: "ܩܺܠܶܪܰܐ",
            sy6: "",
            dt1: "Cholera",
            sy7: "",
            id: "764",
            sy9: "",
            dt3: "",
            lt10: "qolera",
            sy8: ""
        },
        {
            lt12: "",
            id: "190",
            sy8: "",
            sy4: "ܚܘܝܕܐ",
            lt10: "ĥuyodo",
            lt11: "",
            dt1: "Allianz",
            sy7: "",
            sy5: "ܚܽܘܝܳܕܳܐ",
            dt2: "Bündnis",
            sy9: "",
            dt3: "Vereinigung",
            sy6: ""
        },
        {
            dt1: "Obstkern",
            dt3: "",
            sy9: "ܩܫܺܝܬ݂ܳܐ",
            sy5: "ܓܪܽܘܡܳܐ",
            sy6: "ܦܪܨܢܐ",
            lt10: "grumo",
            dt2: "",
            sy7: "ܦܰܪܨܳܢܳܐ",
            id: "6125",
            lt11: "farŝono",
            sy8: "ܩܫܝܬܐ",
            lt12: "qshitho",
            sy4: "ܓܪܘܡܐ"
        },
        {
            dt3: "",
            sy8: "",
            lt12: "",
            lt11: "",
            lt10: "knushyo urifiya",
            sy6: "",
            sy9: "",
            sy7: "",
            sy4: "ܟܢܘܫܝܐ ܐܘܪܝܦܝܐ",
            dt2: "Europäische Union",
            id: "1198",
            dt1: "EU",
            sy5: "ܟܢܽܘܫܝܳܐ ܐܽܘܪܺܝܦܺܝܰܐ"
        },
        {
            lt12: "",
            dt1: "Technik",
            sy5: "ܬܶܟܢܺܝܟ",
            id: "4414",
            sy6: "",
            lt11: "",
            lt10: "teknik",
            sy4: "ܬܟܢܝܟ",
            dt2: "",
            sy9: "",
            sy8: "",
            sy7: "",
            dt3: ""
        },
        {
            sy9: "",
            lt11: "",
            dt1: "Kabel",
            lt10: "qablo",
            sy6: "",
            sy5: "ܩܰܒܠܳܐ",
            sy8: "",
            lt12: "",
            sy7: "",
            dt2: "",
            dt3: "",
            id: "2371",
            sy4: "ܩܒܠܐ"
        },
        {
            sy4: "ܪܒܝ",
            sy6: "",
            lt10: "rabi",
            lt12: "",
            id: "1181",
            sy7: "",
            sy5: "ܪܰܒܺܝ",
            sy9: "",
            lt11: "",
            dt1: "erziehen",
            sy8: "",
            dt3: "",
            dt2: ""
        },
        {
            lt11: "",
            sy5: "ܟܽܠ ܝܰܪܚܳܐ",
            lt12: "",
            lt10: "kul yarĥo",
            sy8: "",
            sy6: "",
            sy7: "",
            id: "2290",
            dt2: "",
            sy9: "",
            dt3: "",
            sy4: "ܟܠ ܝܪܚܐ",
            dt1: "jeden Monat"
        },
        {
            lt10: "biĉto",
            id: "1026",
            lt12: "",
            sy4: "ܒܝܥܬܐ",
            dt1: "Ei",
            dt2: "",
            lt11: "",
            sy6: "",
            dt3: "",
            sy8: "",
            sy5: "ܒܺܝܥܬܳܐ",
            sy9: "",
            sy7: ""
        },
        {
            sy8: "",
            lt10: "ĥubo",
            sy7: "",
            dt2: "",
            sy9: "",
            id: "2807",
            sy4: "ܚܘܒܐ",
            dt1: "Liebe",
            lt12: "",
            sy6: "",
            dt3: "",
            sy5: "ܚܽܘܒܳܐ",
            lt11: ""
        },
        {
            sy6: "",
            lt12: "",
            id: "1908",
            dt3: "",
            sy5: "ܓܶܠܕܳܐ",
            sy7: "",
            lt10: "geldo",
            lt11: "",
            sy8: "",
            sy9: "",
            dt1: "Haut",
            dt2: "",
            sy4: "ܓܠܕܐ"
        },
        {
            sy4: "ܐܟܪܬܐ",
            dt1: "Bäuerin",
            sy5: "ܐܰܟܳܪܬܳܐ",
            sy6: "",
            sy8: "",
            dt3: "",
            lt12: "",
            lt11: "",
            dt2: "",
            sy7: "",
            id: "511",
            sy9: "",
            lt10: "akorto"
        },
        {
            sy9: "",
            sy8: "",
            dt3: "",
            sy7: "",
            id: "3583",
            sy5: "ܪܰܒܫܶܡ",
            lt11: "ܪܒܫܡ",
            dt1: "Rabschem",
            lt10: "ܪܒܫܡ",
            sy4: "ܪܒܫܡ",
            lt12: "",
            dt2: "",
            sy6: ""
        },
        {
            sy4: "ܝܘܡܐ ܫܒܘܥܐ",
            lt11: "",
            dt3: "",
            sy8: "",
            sy5: "ܝܰܘ̈ܡܳܐ ܫܳܒܽܘܥܶܐ",
            dt1: "Wochentage",
            sy6: "",
            id: "5149",
            sy7: "",
            lt10: "yauwme shobuĉo",
            lt12: "",
            dt2: "",
            sy9: ""
        },
        {
            sy9: "",
            dt2: "",
            sy6: "",
            dt3: "",
            dt1: "Radio",
            id: "3592",
            lt12: "",
            lt11: "",
            sy7: "",
            sy8: "",
            sy4: "ܦܪܘܣ ܩܠܐ",
            lt10: "frus qolo",
            sy5: "ܦܪܽܘܣ ܩܳܠܳܐ"
        },
        {
            sy5: "ܐܳܣܝܳܐ ܕܚܰܝ̈ܘܳܬ݂ܳܐ",
            sy6: "",
            lt11: "",
            dt1: "Tierarzt",
            sy8: "",
            dt2: "",
            lt10: "osyo d`ĥaywotho",
            dt3: "",
            id: "4478",
            lt12: "",
            sy9: "",
            sy4: "ܐܣܝܐ ܕܚܝܘܬܐ",
            sy7: ""
        },
        {
            sy6: "",
            sy8: "",
            sy9: "",
            sy4: "ܬܕܐܐ",
            sy7: "",
            id: "4359",
            sy5: "ܬܰܕܐܳܐ",
            dt2: "Tadwo",
            lt12: "",
            lt10: "ܬܕܐܐ",
            lt11: "ܬܕܐܐ",
            dt3: "",
            dt1: "Tadoo"
        },
        {
            id: "3960",
            dt1: "Schlafen",
            sy6: "",
            sy7: "",
            dt2: "",
            sy9: "",
            sy4: "ܕܡܟܐ",
            lt10: "dmokho",
            lt12: "",
            sy5: "ܕܡܳܟ݂ܳܐ",
            dt3: "",
            sy8: "",
            lt11: ""
        },
        {
            sy4: "ܚܐܪܘܬ ܬܘܕܝܬܐ",
            dt3: "",
            lt11: "",
            sy6: "",
            sy5: "ܚܺܐܪܽܘܬ݂ ܬܰܘܕܺܝܬ݂ܳܐ",
            lt10: "ĥiruth tauditho",
            lt12: "",
            sy9: "",
            id: "3686",
            dt2: "",
            sy7: "",
            sy8: "",
            dt1: "Religionsfreiheit"
        },
        {
            sy5: "ܥܰܫܺܝܢܳܐ",
            lt10: "ĉashino",
            sy9: "",
            dt2: "robust",
            sy4: "ܥܫܝܢܐ",
            sy6: "ܒܚܝܠܐ",
            dt3: "mächtig",
            sy7: "ܒܚܰܝܠܳܐ",
            dt1: "kräftig",
            id: "2628",
            lt11: "bĥaylo",
            sy8: "",
            lt12: ""
        },
        {
            sy8: "",
            dt2: "",
            lt12: "",
            sy5: "ܐܳ",
            dt3: "",
            lt10: "zqofo",
            id: "3328",
            sy9: "",
            dt1: "o",
            sy4: "ܐ",
            sy7: "",
            lt11: "",
            sy6: ""
        },
        {
            sy8: "",
            sy9: "",
            dt2: "",
            sy4: "ܦܗܪܐ",
            sy5: "ܦܰܗܳܪܳܐ",
            dt1: "Gähner",
            dt3: "",
            lt11: "",
            lt10: "fahoro",
            lt12: "",
            sy6: "",
            sy7: "",
            id: "1463"
        },
        {
            dt1: "Schneiderin",
            sy7: "",
            dt2: "",
            sy5: "ܚܰܝܳܛܬ݂ܳܐ",
            sy8: "",
            lt11: "",
            sy4: "ܚܝܛܬܐ",
            sy9: "",
            lt10: "ĥayoțtho",
            sy6: "",
            dt3: "",
            lt12: "",
            id: "4001"
        },
        {
            dt3: "",
            dt2: "Weiber",
            lt10: "neshe",
            id: "1365",
            sy5: "ܢܶܫ̈ܶܐ",
            sy6: "",
            sy8: "",
            sy9: "",
            sy4: "ܢܫܐ",
            sy7: "",
            lt11: "",
            lt12: "",
            dt1: "Frauen"
        },
        {
            dt1: "Guten Appetit",
            sy8: "",
            id: "1772",
            dt3: "",
            sy5: "ܗܰܢܺܝܐܝܽܘܬ݂ܳܐ",
            lt10: "haniyutho",
            sy4: "ܗܢܝܐܝܘܬܐ",
            sy7: "",
            sy6: "",
            dt2: "",
            lt11: "",
            sy9: "",
            lt12: ""
        },
        {
            dt3: "",
            sy4: "ܣܟܬܐ ܕܠܒܘܫܐ",
            dt1: "Kleiderbügel",
            sy6: "",
            lt11: "",
            sy7: "",
            sy5: "ܣܶܟ݂ܬ݂ܳܐ ܕܠܒܽܘܫܳܐ",
            sy8: "",
            sy9: "",
            lt10: "sekhtho d`lbushe",
            dt2: "",
            id: "2509",
            lt12: ""
        },
        {
            dt1: "u",
            dt3: "",
            sy8: "",
            sy6: "",
            sy4: "ܐ",
            lt11: "",
            sy9: "",
            dt2: "",
            sy5: "ܐܽ",
            sy7: "",
            lt12: "",
            lt10: "ĥsoso",
            id: "4610"
        },
        {
            sy7: "",
            sy4: "ܓܡܘܪܬܐ",
            lt10: "gmurto",
            lt11: "",
            sy8: "",
            sy6: "",
            dt3: "",
            sy5: "ܓܡܽܘܪܬܳܐ",
            dt1: "brennendes Kohlenstück",
            dt2: "",
            id: "704",
            sy9: "",
            lt12: ""
        },
        {
            dt1: "Küche",
            sy6: "",
            sy8: "",
            dt3: "",
            sy5: "ܒܶܝܬ݂ ܒܽܘܫܳܠܳܐ",
            id: "2653",
            lt10: "beth busholo",
            sy4: "ܒܝܬ ܒܘܫܠܐ",
            sy7: "",
            lt12: "",
            sy9: "",
            lt11: "",
            dt2: ""
        },
        {
            id: "3634",
            dt2: "",
            lt11: "",
            sy8: "",
            sy6: "",
            sy5: "ܕܪܳܥܳܐ ܕܝܰܡܺܝܢܳܐ",
            lt10: "droĉo d`yamino",
            dt3: "",
            sy4: "ܕܪܥܐ ܕܝܡܝܢܐ",
            dt1: "rechter Arm",
            sy9: "",
            sy7: "",
            lt12: ""
        },
        {
            sy4: "ܐܪܥܢܐ",
            sy5: "ܐܰܪܥܳܢܳܐ",
            sy7: "ܐܰܪܥܳܝܳܐ",
            id: "5054",
            sy6: "ܐܪܥܝܐ",
            dt2: "irdisch",
            lt12: "",
            sy9: "",
            lt10: "arĉono",
            dt1: "weltlich",
            dt3: "",
            lt11: "arĉoyo",
            sy8: ""
        },
        {
            sy8: "",
            sy7: "",
            dt2: "",
            sy5: "ܬܶܫܘܺܝܬ݂ ܬܶܒܢܶܐ",
            sy9: "",
            lt10: "teshwith tebne",
            sy4: "ܬܫܘܝܬ ܬܒܢܐ",
            sy6: "",
            dt3: "",
            dt1: "Strohmatratze",
            id: "4299",
            lt12: "",
            lt11: ""
        },
        {
            lt11: "",
            sy8: "",
            dt3: "",
            sy9: "",
            sy7: "",
            dt2: "Kneifzange",
            sy5: "ܟܰܠܒܬܳܐ",
            id: "5254",
            sy6: "",
            lt12: "",
            lt10: "kalobto",
            dt1: "Zange",
            sy4: "ܟܠܒܬܐ"
        },
        {
            sy7: "",
            sy4: "ܝܪܚܐ",
            id: "3078",
            dt1: "Monat",
            sy6: "",
            lt12: "",
            sy5: "ܝܰܪܚܳܐ",
            dt3: "",
            sy9: "",
            sy8: "",
            lt11: "",
            dt2: "",
            lt10: "yarĥo"
        },
        {
            sy6: "",
            sy7: "",
            dt3: "",
            lt10: "sagiyonoyo",
            lt11: "",
            sy9: "",
            id: "2990",
            lt12: "",
            dt1: "Mehrzahl",
            dt2: "Plural",
            sy8: "",
            sy4: "ܣܓܝܐܢܝܐ",
            sy5: "ܣܰܓܝܳܐܢܳܝܳܐ"
        },
        {
            lt10: "rubĉo",
            sy6: "",
            dt3: "",
            lt12: "",
            sy4: "ܪܘܒܥܐ",
            sy7: "",
            id: "4839",
            sy9: "",
            lt11: "",
            sy8: "",
            sy5: "ܪܽܘܒܥܳܐ",
            dt1: "Viertel",
            dt2: ""
        },
        {
            dt2: "Erkenntnis",
            sy7: "",
            dt3: "",
            lt12: "",
            sy4: "ܐܝܕܥܬܐ",
            dt1: "Ahnung",
            sy6: "",
            sy5: "ܐܺܝܕܰܥܬ݂ܳܐ",
            id: "5771",
            sy9: "",
            lt11: "",
            sy8: "",
            lt10: "idaĉtho"
        },
        {
            sy4: "ܥܘܬܡܢܝܐ",
            sy7: "",
            lt11: "",
            sy5: "ܥܽܘܬܡܰܢܳܝܳܐ",
            dt3: "",
            dt2: "",
            id: "3397",
            dt1: "Osmane",
            lt10: "ĉutmanoyo",
            sy6: "",
            sy9: "",
            lt12: "",
            sy8: ""
        },
        {
            lt10: "maĥwe no lokh",
            sy7: "",
            sy6: "",
            sy8: "",
            sy5: "ܡܚܘܐ ܐ̱ܢܳܐ ܠܳܟ݂",
            lt12: "",
            dt3: "",
            sy4: "ܡܚܘܐ ܐܢܐ ܠܟ",
            dt1: "ich zeige Dir",
            id: "2145",
            sy9: "",
            dt2: "",
            lt11: ""
        },
        {
            dt2: "",
            dt3: "",
            lt10: "meltho",
            sy6: "",
            sy7: "",
            sy8: "",
            lt11: "",
            lt12: "",
            dt1: "Wort",
            sy5: "ܡܶܠܬ݂ܳܐ",
            id: "5164",
            sy9: "",
            sy4: "ܡܠܬܐ"
        },
        {
            id: "5487",
            sy6: "",
            lt12: "",
            dt1: "Familie ist wo das Leben beginnt und die Liebe niemals endet",
            sy9: "",
            sy7: "",
            sy8: "",
            sy4: " ܐܺܝܩܰܪܬܳܐ ܐܺܝܬܶܝܗ ܐܰܝܟܳܐ ܕܚܰܝܶܐ ܡܫܰܪܶܝܢ ܘܚܽܘܒܳܐ ܣܳܟ ܠܳܐ ܫܳܠܡܳܐ",
            lt10: "iqartho iytheh ayko dĥaye msharen u ĥubo sokh lo sholmo",
            lt11: "",
            sy5: " ܐܺܝܩܰܪܬܳܐ ܐܺܝܬܶܝܗ ܐܰܝܟܳܐ ܕܚܰܝܶܐ ܡܫܰܪܶܝܢ ܘܚܽܘܒܳܐ ܣܳܟ ܠܳܐ ܫܳܠܡܳܐ",
            dt3: "",
            dt2: ""
        },
        {
            id: "3072",
            dt2: "",
            sy7: "",
            sy5: "ܕܳܪܳܢܳܝܽܘܬ݂ܳܐ",
            sy8: "",
            sy4: "ܕܪܢܝܘܬܐ",
            dt3: "",
            lt11: "",
            dt1: "Modernismus",
            lt12: "",
            sy9: "",
            sy6: "",
            lt10: "doronoyutho"
        },
        {
            sy9: "",
            sy4: "ܪܝܫܡܠܝܐ",
            lt10: "rishmoloyo",
            sy6: "",
            sy5: "ܪܺܝܫܡܳܠܳܝܳܐ",
            dt3: "",
            id: "2407",
            sy8: "",
            dt1: "Kapitalist",
            lt11: "",
            dt2: "kapitalistisch",
            lt12: "",
            sy7: ""
        },
        {
            dt3: "",
            sy4: "ܛܘܣܐ",
            sy5: "ܛܰܘܣܳܐ",
            dt1: "Flug",
            sy6: "ܛܘܝܣܐ",
            dt2: "",
            lt12: "",
            sy7: "ܛܽܘܝܳܣܳܐ",
            id: "1341",
            lt10: "țauso",
            lt11: "țuyoso",
            sy8: "",
            sy9: ""
        },
        {
            sy6: "ܢܩܫ",
            dt1: "musizieren",
            id: "3131",
            lt10: "nqash",
            sy7: "ܢܳܩܶܫ",
            sy8: "",
            dt3: "",
            dt2: "",
            lt11: "noqesh",
            sy9: "",
            sy5: "ܢܩܰܫ",
            sy4: "ܢܩܫ",
            lt12: ""
        },
        {
            id: "6328",
            sy5: "ܡܢܰܘܓܽܘܬ݂ܳܐ",
            sy9: "",
            sy7: "",
            sy6: "",
            dt3: "",
            dt1: "Schiffbruch",
            sy4: "ܡܢܘܓܘܬܐ",
            lt12: "",
            sy8: "",
            lt11: "",
            lt10: "mnaugutho",
            dt2: ""
        },
        {
            sy8: "",
            lt10: "mulsoyutho",
            sy5: "ܡܽܘܠܣܳܝܽܘܬ݂ܳܐ",
            id: "6047",
            sy7: "ܣܕܽܘܡܳܝܽܘܬ݂ܳܐ",
            dt3: "",
            lt12: "",
            lt11: "sdumoyutho",
            sy6: "ܣܕܘܡܝܘܬܐ",
            dt1: "Homosexualität",
            sy9: "",
            dt2: "",
            sy4: "ܡܘܠܣܝܘܬܐ"
        },
        {
            dt1: "Kohle",
            dt3: "",
            id: "2554",
            sy6: "",
            sy4: "ܦܚܡܐ",
            lt12: "",
            dt2: "Steinkohle",
            lt10: "faĥmo",
            sy7: "",
            sy9: "",
            sy8: "",
            lt11: "",
            sy5: "ܦܰܚܡܳܐ"
        },
        {
            sy5: "ܡܶܬ݂ܪܰܓܫܳܢܽܘܬ݂ܳܐ",
            dt1: "Allergie",
            lt10: "methragshonutho",
            dt3: "",
            sy8: "",
            sy7: "",
            id: "183",
            lt11: "",
            sy4: "ܡܬܪܓܫܢܘܬܐ",
            sy9: "",
            dt2: "",
            sy6: "",
            lt12: ""
        },
        {
            dt3: "",
            sy9: "",
            lt11: "",
            sy4: "ܩܘܩܡܐ",
            dt1: "Kessel",
            lt10: "quqmo",
            sy8: "",
            id: "2460",
            sy6: "",
            lt12: "",
            sy5: "ܩܽܘܩܡܳܐ",
            dt2: "",
            sy7: ""
        },
        {
            sy8: "",
            dt1: "tsh",
            id: "4586",
            dt2: "",
            sy6: "",
            dt3: "",
            lt12: "",
            lt11: "",
            sy7: "",
            lt10: "tsh",
            sy9: "",
            sy5: "ܔ",
            sy4: "ܓ"
        },
        {
            dt1: "Brita",
            sy6: "ܒܪܝܬܐ",
            lt11: "ܒܪܝܬܐ",
            sy5: "ܒܪܺܝܬܰܐ",
            id: "713",
            sy8: "",
            lt10: "ܒܪܝܬܐ",
            dt2: "",
            sy7: "ܒܶܪܺܝܬܰܐ",
            lt12: "ܒܪܝܬܐ",
            sy4: "ܒܪܝܬܐ",
            sy9: "",
            dt3: ""
        },
        {
            sy6: "",
            lt11: "",
            sy7: "",
            dt3: "",
            lt12: "",
            sy9: "",
            sy5: "ܟܰܠܳܐ",
            id: "4082",
            lt10: "kalo",
            dt2: "",
            sy4: "ܟܠܐ",
            sy8: "",
            dt1: "Schwiegertochter"
        },
        {
            lt11: "",
            lt10: "furoĥ zabno",
            dt3: "",
            sy5: "ܦܽܘܪܳܚ ܙܰܒܢܳܐ",
            id: "6254",
            sy6: "",
            sy8: "",
            dt2: "",
            sy9: "",
            lt12: "",
            dt1: "Zeitverschwendung",
            sy4: "ܦܘܪܚ ܙܒܢܐ",
            sy7: ""
        },
        {
            sy9: "",
            sy5: "ܡܪܰܚܡܳܢܳܐ",
            lt12: "",
            dt2: "gnädig",
            sy8: "",
            lt11: "mraĥmonoith",
            dt1: "barmherzig",
            id: "459",
            sy6: "ܡܪܚܡܢܐܝܬ",
            lt10: "mraĥmono",
            sy7: "ܡܪܰܚܡܳܢܳܐܝܺܬ݂",
            sy4: "ܡܪܚܡܢܐ",
            dt3: "liebevoll"
        },
        {
            id: "2938",
            dt3: "",
            dt1: "Marina",
            sy9: "",
            sy8: "",
            lt11: "ܡܪܝܢܐ",
            sy4: "ܡܪܝܢܐ",
            sy7: "",
            dt2: "",
            lt10: "ܡܪܝܢܐ",
            sy5: "ܡܰܪܺܝܢܰܐ",
            sy6: "",
            lt12: ""
        },
        {
            sy4: "ܩܠܝܠ",
            dt2: "ein bißchen",
            sy8: "",
            sy5: "ܩܰܠܺܝܠ",
            sy7: "",
            dt1: "ein wenig",
            dt3: "",
            sy9: "",
            lt12: "",
            id: "1036",
            lt10: "qalil",
            lt11: "",
            sy6: ""
        },
        {
            sy4: "ܝܬܘܬܐ",
            id: "4167",
            dt3: "",
            sy5: "ܝܬܰܘܬܳܐ",
            dt2: "",
            lt11: "",
            lt12: "",
            sy6: "",
            sy7: "",
            lt10: "ytauto",
            dt1: "Sitzung",
            sy8: "",
            sy9: ""
        },
        {
            lt12: "",
            sy5: "ܣܰܕܰܪ",
            sy7: "",
            lt10: "ܣܕܪ",
            lt11: "ܣܕܪ",
            dt1: "Sadar",
            dt3: "",
            sy8: "",
            dt2: "",
            id: "3794",
            sy4: "ܣܕܪ",
            sy6: "",
            sy9: ""
        },
        {
            lt12: "",
            sy5: "ܐܽܘܪܡܰܬܰܐ",
            dt1: "Urmata",
            sy6: "",
            sy8: "",
            dt3: "",
            sy4: "ܐܘܪܡܬܐ",
            sy7: "",
            dt2: "",
            lt11: "ܐܘܪܡܬܐ",
            id: "4695",
            sy9: "",
            lt10: "ܐܘܪܡܬܐ"
        },
        {
            dt1: "kostbar",
            dt3: "",
            dt2: "",
            sy8: "",
            sy9: "",
            sy4: "ܝܰܩܺܝܪ ܛܺܝܡܳܐ",
            lt12: "",
            lt10: "yaqir țimo",
            lt11: "",
            sy7: "",
            id: "5431",
            sy6: "",
            sy5: "ܝܰܩܺܝܪ ܛܺܝܡܳܐ"
        },
        {
            sy9: "",
            lt12: "",
            sy4: "ܡܫܬܘܬܦܢܐ",
            sy6: "ܡܫܘܬܦܐ",
            sy7: "ܡܫܰܘܬܦܳܐ",
            lt10: "meshtautfono",
            sy8: "",
            dt3: "",
            id: "1596",
            lt11: "mshautfo",
            dt2: "",
            dt1: "Gesellschafter",
            sy5: "ܡܶܫܬܰܘܬܦܳܢܳܐ"
        },
        {
            dt1: "dreiviertel",
            id: "960",
            dt3: "",
            sy4: "ܬܠܬܐ ܪܘܒܥܐ",
            sy5: "ܬܠܳܬ݂ܳܐ ܪ̈ܘܒܥܶܐ",
            lt10: "tlotho rubĉe",
            sy8: "",
            lt12: "",
            dt2: "",
            sy9: "",
            sy6: "",
            lt11: "",
            sy7: ""
        },
        {
            sy5: "ܗܘܳܐ ܐ̱ܚܪܳܝܳܐ",
            lt11: "",
            lt12: "",
            dt3: "",
            dt1: "letzter geworden",
            sy4: "ܗܘܐ ܐ̱ܚܪܝܐ",
            id: "2786",
            sy9: "",
            lt10: "hwo ĥroyo",
            sy7: "",
            sy8: "",
            sy6: "",
            dt2: ""
        },
        {
            id: "4841",
            dt1: "Vision",
            dt3: "",
            sy7: "",
            sy8: "",
            sy9: "",
            sy6: "",
            lt11: "",
            dt2: "Illusion",
            lt12: "",
            sy5: "ܗܰܓܓܳܐ",
            lt10: "haggo",
            sy4: "ܗܓܓܐ"
        },
        {
            dt2: "",
            sy8: "",
            sy9: "",
            sy7: "",
            sy4: "ܨܒܘ̈ܬ݂ܐ",
            lt11: "",
            lt10: "ŝebwotho",
            sy5: "ܨܶܒܘ̈ܬ݂ܳܐ",
            sy6: "",
            lt12: "",
            dt1: "Themen",
            dt3: "",
            id: "4453"
        },
        {
            sy4: "ܨܘܢܥܝܐ",
            dt1: "industriell",
            lt10: "ŝunoĉoyo",
            lt11: "",
            sy9: "",
            sy6: "",
            sy7: "",
            dt3: "",
            lt12: "",
            dt2: "",
            sy5: "ܨܽܘܢܳܥܳܝܳܐ",
            sy8: "",
            id: "2199"
        },
        {
            sy4: "ܢܐܪܒܐ",
            dt2: "",
            sy9: "",
            sy5: "ܢܺܐܪܒܳܐ",
            lt11: "ܢܐܪܒܐ",
            lt12: "",
            dt3: "",
            dt1: "Nirbo",
            id: "3284",
            sy8: "",
            sy6: "",
            lt10: "ܢܐܪܒܐ",
            sy7: ""
        },
        {
            sy6: "",
            sy4: "ܫܪܳܝܳܐ ܕܚܶܠ̈ܡܶܐ",
            lt10: "shroyo dĥelme",
            sy7: "",
            lt12: "",
            sy5: "ܫܪܳܝܳܐ ܕܚܶܠ̈ܡܶܐ",
            id: "5473",
            dt3: "",
            dt1: "Traumdeuter",
            sy9: "",
            dt2: "Traumdeutung",
            sy8: "",
            lt11: ""
        },
        {
            sy4: "ܬܡܪܐ",
            dt2: "",
            sy6: "",
            lt11: "",
            lt10: "tamre",
            sy9: "",
            dt3: "",
            sy5: "ܬܰܡܪ̈ܐ",
            lt12: "",
            dt1: "Datteln",
            sy7: "",
            id: "829",
            sy8: ""
        },
        {
            dt1: "Edessa",
            sy8: "",
            dt2: "",
            lt10: "urhoy",
            sy7: "",
            dt3: "",
            lt12: "",
            sy9: "",
            sy6: "",
            sy4: "ܐܘܪܗܝ",
            id: "999",
            sy5: "ܐܽܘܪܗܳܝ",
            lt11: ""
        },
        {
            sy8: "",
            lt12: "",
            dt3: "",
            sy9: "",
            lt11: "",
            dt2: "",
            sy7: "",
            sy6: "",
            sy5: "ܐܺܘܪܩܺܝܢܳܘܣ",
            lt10: "urqinos",
            sy4: "ܐܘܪܩܝܢܘܣ",
            dt1: "Thunfisch",
            id: "4472"
        },
        {
            dt1: "zu teuer",
            sy9: "",
            dt2: "",
            dt3: "",
            lt12: "",
            sy6: "",
            sy8: "",
            sy4: "ܝܩܝܪ ܣܓܝ",
            lt10: "yaqir sagi",
            lt11: "",
            sy7: "",
            id: "6311",
            sy5: "ܝܰܩܺܝܪ ܣܰܓܺܝ"
        },
        {
            sy9: "",
            lt11: "",
            dt2: "",
            dt3: "",
            id: "1653",
            sy8: "",
            lt12: "",
            lt10: "qonuno d`haymonutho",
            sy4: "ܩܢܘܢܐ ܕܗܝܡܢܘܬܐ",
            sy6: "",
            sy5: "ܩܳܢܽܘܢܳܐ ܕܗܰܝܡܳܢܽܘܬ݂ܳܐ",
            dt1: "Glaubensbekenntnis",
            sy7: ""
        },
        {
            sy6: "ܣܝܦܝܠ",
            sy5: "ܥܰܡܳܝܳܐ",
            sy7: "ܣܺܝܦܺܝܠ",
            sy9: "",
            dt3: "",
            sy4: "ܥܡܝܐ",
            dt2: "",
            lt10: "ĉamoyo",
            dt1: "zivil",
            sy8: "",
            lt12: "",
            id: "6293",
            lt11: "sifil"
        },
        {
            sy8: "",
            sy6: "",
            lt12: "",
            id: "6064",
            dt3: "",
            sy9: "",
            dt1: "fett",
            dt2: "",
            sy7: "",
            lt10: "tarbo",
            sy5: "ܬܰܪܒܳܐ",
            lt11: "",
            sy4: "ܬܪܒܐ"
        },
        {
            sy5: "ܒܪܺܝܡܬܳܐ ܐܰܢ̱ܬܝ̱",
            lt11: "",
            dt1: "Du bist lesbisch",
            dt2: "",
            lt10: "brimto at",
            sy7: "",
            dt3: "",
            sy4: "ܒܪܝܡܬܐ ܐܢܬܝ",
            sy9: "",
            sy8: "",
            id: "970",
            lt12: "",
            sy6: ""
        },
        {
            lt11: "",
            sy7: "",
            sy8: "",
            sy4: "ܚܦܪܐ",
            lt10: "ĥforo",
            id: "1721",
            sy5: "ܚܦܳܪܳܐ",
            sy9: "",
            dt2: "",
            lt12: "",
            sy6: "",
            dt1: "Graben",
            dt3: ""
        },
        {
            dt2: "",
            dt1: "Gesetzgeber",
            sy8: "",
            sy4: "ܡܢܘܡܣܢܐ",
            sy5: "ܡܢܰܘܡܣܳܢܳܐ",
            id: "6330",
            lt11: "",
            sy7: "",
            lt10: "mnaumsono",
            sy6: "",
            sy9: "",
            dt3: "",
            lt12: ""
        },
        {
            sy8: "ܡܫܝܢܢܘܬܐ",
            sy9: "ܡܫܰܝܢܳܢܽܘܬ݂ܳܐ",
            dt1: "Versöhnung",
            dt2: "",
            lt12: "mshaynonutho",
            lt11: "mĥasyonutho",
            sy5: "ܡܪܰܥܝܳܢܽܘܬ݂ܳܐ",
            lt10: "mraĉyonutho",
            sy4: "ܡܪܥܝܢܘܬܐ",
            id: "6419",
            sy7: "ܡܚܰܣܝܳܢܽܘܬ݂ܳܐ",
            dt3: "",
            sy6: "ܡܚܣܝܢܘܬܐ"
        },
        {
            dt1: "Abspülen",
            lt11: "fuyoĉo",
            lt10: "furfoĉo",
            dt3: "",
            sy7: "ܦܽܘܝܳܥܳܐ",
            sy5: "ܦܽܘܪܦܳܥܳܐ",
            sy9: "",
            sy6: "ܦܘܝܥܐ",
            id: "5659",
            lt12: "",
            sy4: "ܦܘܪܦܥܐ",
            sy8: "",
            dt2: ""
        },
        {
            sy6: "",
            dt2: "Heimatvertriebener",
            lt10: "mshausbo",
            sy9: "",
            sy5: "ܡܫܰܘܫܒܳܐ",
            sy7: "",
            dt1: "Heimatloser",
            id: "1935",
            dt3: "",
            sy8: "",
            sy4: "ܡܫܘܙܒܐ",
            lt11: "",
            lt12: ""
        },
        {
            sy9: "",
            id: "5595",
            dt2: "",
            dt3: "",
            sy4: "ܡܒܠܩܢܐ",
            lt10: "mbalqono",
            sy8: "",
            sy5: "ܡܒܰܠܩܳܢܳܐ",
            lt11: "",
            lt12: "",
            dt1: "abrupt",
            sy7: "",
            sy6: ""
        },
        {
            sy9: "",
            sy5: "ܐܰܟܺܝܦܰܐ",
            sy4: "ܐܟܝܦܐ",
            dt2: "Akif",
            lt12: "",
            sy6: "",
            id: "169",
            lt11: "ܐܟܝܦܐ",
            sy8: "",
            dt1: "Akifa",
            dt3: "",
            lt10: "ܐܟܝܦܐ",
            sy7: ""
        },
        {
            sy8: "",
            sy5: "ܩܪܳܒܳܐ ܬܺܒܶܝܠܳܝܳܐ",
            dt2: "",
            lt10: "qrobo tibeloyo",
            id: "5053",
            dt1: "Weltkrieg",
            sy7: "ܩܪܳܒܳܐ ܕܒܪܺܝܬ݂ܳܐ",
            dt3: "",
            lt11: "qrobo d`britho",
            sy9: "",
            sy6: "ܩܪܒܐ ܕܒܪܝܬܐ",
            sy4: "ܩܪܒܐ ܬܒܝܠܝܐ",
            lt12: ""
        },
        {
            sy6: "",
            sy9: "",
            lt11: "",
            lt10: "gabo tibeloyo d`ĥuyodo suryoyo",
            dt3: "",
            dt1: "Universal Syriac Union Party",
            id: "4646",
            dt2: "",
            sy5: "ܓܰܒܳܐ ܬܺܒܶܠܳܝܳܐ ܕܚܽܘܝܳܕܳܐ ܣܽܘܪܝܳܝܳܐ",
            sy7: "",
            sy4: "ܓܒܐ ܬܒܠܝܐ ܕܚܘܝܕܐ ܣܘܪܝܝܐ",
            lt12: "",
            sy8: ""
        },
        {
            lt11: "",
            dt3: "",
            sy4: "ܠܫܢܐ ܐܡܗܝܐ",
            sy7: "",
            lt10: "leshono emhoyo",
            sy5: "ܠܶܫܳܢܳܐ ܐܡܗܝܳܐ",
            sy8: "",
            lt12: "",
            dt1: "Muttersprache",
            sy9: "",
            dt2: "",
            sy6: "",
            id: "3140"
        },
        {
            lt11: "skhar fumokh",
            dt1: "Halt den Mund",
            lt10: "skhar femokh",
            sy9: "",
            dt3: "Halt die Klappe",
            lt12: "",
            sy4: "ܣܟܪ ܦܡܟ",
            sy5: "ܣܟ݂ܰܪ ܦܶܡܳܟ݂",
            dt2: "Halt die Fresse",
            sy6: "ܣܟܪ ܦܘܡܟ",
            id: "1833",
            sy8: "",
            sy7: "ܣܟ݂ܰܪ ܦܽܘܡܳܟ݂"
        },
        {
            sy7: "",
            dt2: "",
            id: "171",
            dt1: "Aktan",
            sy4: "ܐܟܬܢ",
            lt11: "ܐܟܬܢ",
            sy9: "",
            lt12: "",
            sy8: "",
            lt10: "ܐܟܬܢ",
            sy6: "",
            sy5: "ܐܰܟܬܰܢ",
            dt3: ""
        },
        {
            lt10: "d`lo rebitho",
            sy9: "",
            lt11: "",
            dt3: "",
            sy6: "",
            sy5: "ܕܠܳܐ ܪܶܒܺܝܬ݂ܳܐ",
            dt1: "zinslos",
            dt2: "",
            sy4: "ܕܠܐ ܪܒܝܬܐ",
            sy8: "",
            sy7: "",
            lt12: "",
            id: "5330"
        },
        {
            dt1: "Stillstand",
            lt10: "shalyutho",
            sy7: "ܫܰܠܝܳܐ",
            sy9: "",
            dt3: "",
            sy4: "ܫܠܝܘܬܐ",
            sy6: "ܫܠܝܐ",
            dt2: "Stille",
            lt12: "",
            sy8: "",
            id: "4276",
            sy5: "ܫܰܠܝܽܘܬ݂ܳܐ",
            lt11: "shalyo"
        },
        {
            dt2: "",
            sy5: "ܣܺܐܡܳܐ",
            sy6: "ܣܐܡܐ",
            sy8: "",
            dt1: "Silber",
            lt10: "simo",
            lt11: "semo",
            sy7: "ܣܶܐܡܶܐ",
            sy4: "ܣܐܡܐ",
            id: "4155",
            lt12: "",
            dt3: "",
            sy9: ""
        },
        {
            dt1: "Faulenzer",
            dt2: "faul",
            sy7: "",
            id: "1268",
            sy5: "ܚܒܰܢܳܢܳܐ",
            sy8: "",
            sy9: "",
            sy6: "",
            sy4: "ܚܒܢܢܐ",
            dt3: "",
            lt10: "ĥbanono",
            lt11: "",
            lt12: ""
        },
        {
            lt12: "",
            sy9: "",
            sy4: "ܝܫܘܥܣܒܪܢ",
            dt1: "Jeschusabran",
            sy7: "",
            dt3: "",
            sy8: "",
            id: "2313",
            sy5: "ܝܶܫܽܘܥܣܰܒܪܰܢ",
            sy6: "",
            dt2: "",
            lt11: "ܝܫܘܥܣܒܪܢ",
            lt10: "ܝܫܘܥܣܒܪܢ"
        },
        {
            sy6: "ܙܟܘܝܐ",
            id: "1627",
            sy8: "",
            sy5: "ܙܰܟܳܝܳܐ",
            dt3: "",
            dt2: "Sieger",
            lt10: "zakoyo",
            sy4: "ܙܟܝܐ",
            lt11: "zokuyo",
            sy7: "ܙܳܟ݂ܽܘܝܳܐ",
            dt1: "Gewinner",
            lt12: "",
            sy9: ""
        },
        {
            sy4: "ܐܘܢܓܠܝܣܛܐ",
            lt11: "msabrono",
            sy7: "ܡܣܰܒܪܳܢܳܐ",
            dt1: "Evangelist",
            sy6: "ܡܣܒܪܢܐ",
            dt3: "",
            lt10: "ewangelisțo",
            sy8: "",
            sy5: "ܐܶܘܰܢܓܶܠܺܝܣܛܳܐ",
            id: "1206",
            lt12: "",
            dt2: "",
            sy9: ""
        },
        {
            dt2: "",
            sy8: "",
            sy6: "",
            lt12: "",
            sy7: "",
            dt3: "",
            sy4: "ܫܡ ܫܩܩܐ",
            dt1: "Straßenname",
            sy5: "ܫܶܡ ܫܩܳܩܳܐ",
            sy9: "",
            lt11: "",
            id: "4292",
            lt10: "shem shqoqo"
        },
        {
            dt3: "",
            sy8: "",
            sy4: "ܢܬܪ ܣܥܪܐ",
            dt1: "Haare ausfallen",
            lt11: "",
            sy5: "ܢܬ݂ܰܪ ܣܰܥܪܳܐ",
            dt2: "",
            sy6: "",
            lt10: "nthar saĉro",
            sy9: "",
            id: "1783",
            sy7: "",
            lt12: ""
        },
        {
            dt2: "",
            sy9: "",
            id: "1305",
            sy4: "ܢܣܝܢ ܢܘܪܐ",
            sy8: "",
            dt1: "Feuerprobe",
            lt10: "nesyon nuro",
            sy5: "ܢܶܣܝܳܢ ܢܽܘܪܳܐ",
            sy6: "",
            lt11: "",
            dt3: "",
            lt12: "",
            sy7: ""
        },
        {
            sy4: "ܐܠܗܐ ܐܝܬܘܗܝ ܪܥܝܐ ܕܝܠܝ ܡܕܡ ܠܐ ܡܬܒܨܪ ܠܝ",
            lt11: "moryo mraĉeyn u medem lo nĥasar li",
            sy8: "",
            sy5: "ܐܰܠܳܗܳܐ ܐܺܝܬ݂ܰܘܗܝ̱ ܪܳܥܝܳܐ ܕܺܝܠܝ̱ ܡܶܕܶܡ ܠܳܐ ܡܶܬ݂ܒܨܰܪ ܠܺܝ",
            dt3: "",
            lt12: "",
            sy9: "",
            dt1: "Gott ist mein Hirte mir wird nichts mangeln",
            dt2: "",
            id: "1693",
            sy6: "ܡܪܝܐ ܢܪܥܝܢܝ ܘܡܕܡ ܠܐ ܢܚܣܪ ܠܝ",
            sy7: "ܡܳܪܝܳܐ ܢܪܰܥܶܝܢܝ̱ ܘܡܶܕܶܡ ܠܳܐ ܢܚܰܣܰܪ ܠܺܝ",
            lt10: "Aloho ithau roĉyo dil medem lo methbŝar li"
        },
        {
            dt3: "",
            sy7: "",
            id: "2089",
            dt1: "Ich allein",
            sy9: "",
            lt10: "eno balĥud",
            sy8: "",
            dt2: "",
            lt11: "",
            sy5: "ܐܶܢܳܐ ܒܰܠܚܽܘܕ",
            sy6: "",
            lt12: "",
            sy4: "ܐܢܐ ܒܠܚܘܕ"
        },
        {
            sy6: "ܣܛܝܦܐܢ",
            lt12: "ܣܛܝܦܐܢ",
            sy9: "",
            sy4: "ܣܛܦܐܢ",
            sy8: "",
            sy5: "ܣܛܶܦܰܐܢ",
            sy7: "ܣܛܶܝܦܰܐܢ",
            dt1: "Stefan",
            dt3: "",
            lt10: "ܣܛܦܐܢ",
            id: "4247",
            dt2: "Stephan",
            lt11: "ܣܛܦܐܢ"
        },
        {
            lt11: "teshmeshto d`folĥutho",
            dt1: "Militärdienst",
            sy5: "ܦܳܠܚܽܘܬ݂ܳܐ",
            sy9: "",
            sy6: "ܬܫܡܫܬܐ ܕܦܠܚܘܬܐ",
            dt2: "Armeedienst",
            sy7: "ܬܶܫܡܶܫܬܳܐ ܕܦܳܠܚܽܘܬ݂ܳܐ",
            dt3: "",
            sy8: "",
            lt10: "folĥutho",
            sy4: "ܦܠܚܘܬܐ",
            lt12: "",
            id: "3029"
        },
        {
            sy7: "",
            dt2: "Kasten",
            sy6: "",
            sy4: "ܣܢܕܘܩܐ",
            lt10: "sanduqo",
            dt1: "Kiste",
            sy8: "",
            id: "2494",
            lt11: "",
            sy9: "",
            sy5: "ܣܰܢܕܽܘܩܳܐ",
            lt12: "",
            dt3: ""
        },
        {
            sy7: "ܡܶܬ݂ܟܰܪܝܳܢܽܘܬ݂ܳܐ",
            dt2: "Einfachheit",
            sy8: "",
            lt10: "qfisutho",
            sy5: "ܩܦܺܝܣܽܘܬ݂ܳܐ",
            lt12: "",
            id: "102",
            lt11: "methqaryonutho",
            dt3: "",
            dt1: "Abkürzung",
            sy6: "ܡܬܟܪܝܢܘܬܐ",
            sy4: "ܩܦܝܣܘܬܐ",
            sy9: ""
        },
        {
            sy5: "ܪܰܚܶܝܠ",
            sy9: "",
            sy4: "ܪܚܝܠ",
            dt3: "",
            sy6: "ܪܚܝܠ",
            lt10: "ܪܚܝܠ",
            dt1: "Rahel",
            id: "3596",
            sy8: "",
            lt12: "ܪܚܝܠ",
            dt2: "Rohel",
            lt11: "ܪܚܝܠ",
            sy7: "ܪܳܚܶܝܠ"
        },
        {
            sy7: "",
            dt2: "",
            sy9: "",
            dt3: "",
            lt11: "",
            sy5: "ܡܢܳܬ݂ ܐܰܪܝܳܐ",
            lt12: "",
            dt1: "Löwenanteil",
            sy6: "",
            sy8: "",
            sy4: "ܡܢܬ ܐܪܝܐ",
            lt10: "mnoth aryo",
            id: "6346"
        },
        {
            dt1: "Klosterleben",
            sy7: "",
            sy6: "",
            sy4: "ܚܝܐ ܕܕܝܪܐ",
            sy8: "",
            lt10: "ĥaye d`dayro",
            id: "2535",
            dt3: "",
            sy9: "",
            lt11: "",
            lt12: "",
            sy5: "ܚܰܝ̈ܶܐ ܕܕܰܝܪܳܐ",
            dt2: ""
        },
        {
            dt3: "",
            sy4: "ܪܡܐܝܠ",
            sy6: "",
            id: "3741",
            lt12: "",
            lt10: "ܪܡܐܝܠ",
            lt11: "ܪܡܐܝܠ",
            sy7: "",
            dt2: "",
            sy5: "ܪܳܡܐܺܝܠ",
            dt1: "Romil",
            sy9: "",
            sy8: ""
        },
        {
            sy6: "ܡܬܛܥܡܢܘܬܐ",
            sy5: "ܛܥܳܡܳܐ",
            id: "2175",
            sy7: "ܡܶܬ݂ܛܰܥܡܳܢܽܘܬ݂ܳܐ",
            lt11: "methțaĉmonutho",
            sy4: "ܛܥܡܐ",
            lt12: "mĥoy mĥațo",
            sy9: "ܡܚܳܝ ܡܚܰܛܳܐ",
            dt1: "Impfung",
            sy8: "ܡܚܝ ܡܚܛܐ",
            dt2: "",
            lt10: "țĉomo",
            dt3: ""
        },
        {
            sy7: "ܩܰܨܩܶܨ",
            lt12: "flash",
            sy5: "ܩܰܨܶܨ",
            dt3: "",
            sy8: "ܦܠܫ",
            sy9: "ܦܠܰܫ",
            sy6: "ܩܨܩܨ",
            lt10: "qaŝeŝ",
            lt11: "qaŝqeŝ",
            sy4: "ܩܨܨ",
            id: "6286",
            dt2: "",
            dt1: "zerteilen"
        },
        {
            sy6: "",
            sy4: "ܕܝܨܢ",
            dt3: "",
            sy9: "",
            sy7: "",
            id: "785",
            sy8: "",
            dt2: "Daysan",
            lt10: "ܕܝܨܢ",
            lt11: "ܕܝܨܢ",
            lt12: "",
            dt1: "Daisan",
            sy5: "ܕܰܝܨܳܢ"
        },
        {
            lt11: "ܬܚܢ",
            id: "4368",
            sy7: "ܬܳܚܳܢ",
            dt1: "Tahan",
            dt3: "",
            lt12: "ܬܚܢ",
            sy8: "",
            sy9: "",
            dt2: "",
            sy5: "ܬܰܚܰܢ",
            sy4: "ܬܚܢ",
            lt10: "ܬܚܢ",
            sy6: "ܬܚܢ"
        },
        {
            dt3: "",
            sy6: "ܐܣܟܐ",
            sy4: "ܝܣܝܟܐ",
            lt11: "ܝܣܝܟܐ",
            lt12: "ܐܣܟܐ",
            dt1: "Jessica",
            lt10: "ܝܣܝܟܐ",
            dt2: "",
            sy7: "ܐܰܣܶܟܰܐ",
            sy9: "",
            sy5: "ܝܰܣܺܝܟܰܐ",
            sy8: "",
            id: "2317"
        },
        {
            id: "4748",
            sy7: "",
            sy5: "ܙܰܒܶܢ",
            sy4: "ܙܒܢ",
            dt1: "verkaufen",
            sy6: "",
            lt10: "zaben",
            lt12: "",
            dt3: "",
            sy9: "",
            dt2: "",
            lt11: "",
            sy8: ""
        },
        {
            dt1: "Ich faste",
            id: "2108",
            sy6: "ܟܨܐܡ ܐܢܐ",
            sy8: "",
            sy9: "",
            lt12: "",
            dt3: "",
            sy7: "ܟܳܨܳܐܰܡ ܐ̱ܢܳܐ",
            sy5: "ܐܶܢܳܐ ܨܰܝܳܡܳܐ ܐ̱ܢܳܐ",
            lt10: "eno ŝayomo no",
            lt11: "ko ŝoyam no",
            sy4: "ܐܢܐ ܨܝܡܐ ܐܢܐ",
            dt2: ""
        },
        {
            dt2: "",
            sy5: "ܚܶܫܠܳܐ",
            sy7: "",
            sy4: "ܚܫܠܐ",
            lt11: "",
            sy9: "",
            sy6: "",
            dt1: "Bier",
            id: "613",
            lt10: "ĥeshlo",
            lt12: "",
            sy8: "",
            dt3: ""
        },
        {
            lt12: "",
            sy4: "ܐܩܢܝ",
            dt1: "verschaffen",
            dt2: "",
            sy5: "ܐܰܩܢܺܝ",
            id: "4804",
            lt11: "",
            dt3: "",
            sy9: "",
            sy6: "",
            lt10: "aqni",
            sy8: "",
            sy7: ""
        },
        {
            sy6: "",
            dt2: "interpretieren",
            lt12: "",
            sy7: "",
            id: "2571",
            sy5: "ܦܰܫܶܩ",
            sy4: "ܦܫܩ",
            dt1: "kommentieren",
            lt11: "",
            dt3: "",
            sy9: "",
            sy8: "",
            lt10: "fasheq"
        },
        {
            lt12: "",
            lt11: "",
            dt2: "",
            dt1: "Platz reservieren",
            sy6: "",
            sy8: "",
            id: "3475",
            dt3: "",
            lt10: "frash duktho",
            sy9: "",
            sy7: "",
            sy4: "ܦܪܫ ܕܘܟܬܐ",
            sy5: "ܦܪܰܫ ܕܘܟܬܳܐ"
        },
        {
            id: "1632",
            sy7: "",
            sy4: "ܚܘܪܪ ܬܐܪܬܐ",
            lt12: "",
            sy8: "",
            lt10: "ĥuror tirtho",
            dt1: "Gewissensfreiheit",
            sy5: "ܚܽܘܪܳܪ ܬܺܐܪܬ݂ܳܐ",
            sy6: "",
            dt2: "",
            sy9: "",
            lt11: "",
            dt3: ""
        },
        {
            sy5: "ܥܰܡܳܘܢ",
            dt2: "",
            lt12: "",
            sy6: "",
            lt10: "ܥܡܘܢ",
            sy4: "ܥܡܘܢ",
            id: "216",
            dt3: "",
            dt1: "Ammon",
            sy8: "",
            lt11: "ܥܡܘܢ",
            sy7: "",
            sy9: ""
        },
        {
            dt3: "",
            dt1: "abstimmen",
            lt12: "",
            sy9: "",
            sy6: "",
            sy8: "",
            dt2: "",
            sy5: "ܩܰܘܶܠ",
            sy7: "",
            lt11: "",
            sy4: "ܩܘܠ",
            lt10: "qawel",
            id: "5668"
        },
        {
            dt1: "einbrechen",
            dt2: "",
            sy9: "",
            sy5: "ܓܢܰܒ",
            sy6: "",
            sy8: "",
            sy7: "",
            dt3: "",
            sy4: "ܓܢܒ",
            lt10: "gnab",
            lt12: "",
            id: "1037",
            lt11: ""
        },
        {
            lt11: "",
            id: "5011",
            dt3: "",
            sy9: "",
            lt10: "hidroshen",
            sy6: "",
            sy4: "ܗܝܕܪܫܝܢ",
            dt1: "Wasserstoff",
            dt2: "",
            sy5: "ܗܺܝܕܪܳܫܶܝܢ",
            sy7: "",
            sy8: "",
            lt12: ""
        },
        {
            sy9: "",
            lt12: "",
            dt2: "",
            dt1: "öffne das Fenster",
            sy5: "ܦܬܰܚ ܠܟܰܘܬ݂ܳܐ",
            lt11: "",
            lt10: "ftaĥ l`kautho",
            sy7: "",
            sy8: "",
            sy4: "ܦܬܚ ܠܟܘܬܐ",
            dt3: "",
            sy6: "",
            id: "3351"
        },
        {
            sy7: "",
            dt1: "Feuerglut",
            id: "1304",
            sy6: "",
            lt10: "gumro",
            sy5: "ܓܽܘܡܪ̈ܐ",
            dt3: "",
            lt12: "",
            sy9: "",
            sy4: "ܓܘܡܪܐ",
            sy8: "",
            lt11: "",
            dt2: ""
        },
        {
            id: "2625",
            sy7: "",
            lt12: "",
            sy4: "ܨܦܕܐ",
            lt10: "ŝefdo",
            sy8: "",
            dt2: "",
            dt1: "Koteletten",
            dt3: "",
            lt11: "",
            sy5: "ܨܶܦܕܳܐ",
            sy6: "",
            sy9: ""
        },
        {
            sy8: "ܡܫܪܝܐ",
            id: "4242",
            dt1: "Station",
            dt3: "Zentral",
            sy9: "ܡܰܫܪܝܳܐ",
            lt11: "qențrun",
            sy6: "ܩܢܬܪܘܢ",
            lt12: "mashryo",
            lt10: "nuqazto",
            sy4: "ܢܘܩܙܬܐ",
            dt2: "Zentrum",
            sy7: "ܩܶܢܬܪܽܘܢ",
            sy5: "ܢܽܘܩܰܙܬܳܐ"
        },
        {
            lt10: "eman",
            sy9: "",
            id: "6088",
            lt11: "",
            sy5: "ܐܶܡܰܢ",
            dt2: "",
            lt12: "",
            sy4: "ܐܡܢ",
            dt1: "fortsetzen",
            sy6: "",
            sy7: "",
            dt3: "",
            sy8: ""
        },
        {
            dt1: "Madenho",
            dt3: "",
            id: "2884",
            lt11: "ܡܕܢܚܐ",
            sy7: "",
            lt10: "ܡܕܢܚܐ",
            lt12: "",
            sy4: "ܡܕܢܚܐ",
            sy9: "",
            sy8: "",
            dt2: "",
            sy6: "",
            sy5: "ܡܰܕܶܢܚܳܐ"
        },
        {
            id: "2347",
            sy4: "ܝܗܘܕܐܝܬ",
            dt3: "",
            sy9: "",
            lt10: "ihuđoith",
            sy5: "ܝܺܗܺܘܕܳܐܝܺܬ݂",
            sy7: "",
            lt12: "",
            lt11: "",
            dt1: "jüdisch",
            dt2: "",
            sy8: "",
            sy6: ""
        },
        {
            dt1: "absurd",
            lt10: "daywo",
            sy9: "",
            dt2: "",
            sy7: "ܒܕܳܝܳܐ",
            lt12: "",
            lt11: "bdoyo",
            sy6: "ܒܕܝܐ",
            sy8: "",
            sy5: "ܕܰܝܘܳܐ",
            sy4: "ܕܝܘܐ",
            dt3: "",
            id: "5679"
        },
        {
            lt10: "ܡܠܟܝܪܡ",
            sy8: "",
            sy4: "ܡܠܟܝܪܡ",
            lt12: "",
            dt1: "Malkiram",
            lt11: "ܡܠܟܝܪܡ",
            dt3: "",
            sy9: "",
            sy7: "",
            sy6: "",
            dt2: "",
            sy5: "ܡܰܠܟܺܝܪܰܡ",
            id: "2904"
        },
        {
            sy9: "",
            lt11: "",
            sy4: "ܩܘܡ",
            dt2: "Steh auf",
            lt12: "",
            lt10: "qum",
            dt3: "",
            sy7: "",
            sy6: "",
            sy5: "ܩܽܘܡ",
            dt1: "aufstehen",
            sy8: "",
            id: "358"
        },
        {
            dt2: "",
            sy8: "",
            sy7: "",
            sy5: "ܦܽܘܪܢܳܣܳܝܳܐ",
            sy6: "",
            dt1: "administrativ",
            lt10: "furnosoyo",
            sy4: "ܦܘܪܢܣܝܐ",
            dt3: "",
            id: "5764",
            lt12: "",
            lt11: "",
            sy9: ""
        },
        {
            sy9: "",
            dt1: "Pyramide",
            id: "5464",
            dt2: "",
            sy7: "",
            lt11: "",
            sy5: "ܗܰܪܡܳܐ",
            sy4: "ܗܰܪܡܳܐ",
            lt10: "harmo",
            dt3: "",
            lt12: "",
            sy8: "",
            sy6: ""
        },
        {
            lt11: "",
            id: "1122",
            sy8: "",
            dt3: "",
            sy5: "ܠܳܐ ܐܳܟ݂ܠܶ ܘܠܳܐ ܫܳܬܶܐ",
            lt10: "lo okhel u lo shote",
            lt12: "",
            sy9: "",
            sy6: "",
            sy4: "ܠܐ ܐܟܠ ܘܠܐ ܫܬܐ",
            dt1: "Er isst und trinkt nicht",
            dt2: "",
            sy7: ""
        },
        {
            dt2: "",
            sy6: "",
            id: "2955",
            sy9: "",
            sy7: "",
            sy4: "ܐܕܪ",
            lt11: "",
            lt12: "",
            dt1: "März",
            sy5: "ܐܳܕܳܪ",
            dt3: "",
            lt10: "ođor",
            sy8: ""
        },
        {
            dt1: "hoffnungslos",
            sy6: "",
            sy9: "",
            sy4: "ܕܠܐ ܣܒܪܐ",
            sy7: "",
            sy5: "ܕܠܳܐ ܣܰܒܪܳܐ",
            dt3: "",
            sy8: "",
            lt10: "dlo sabro",
            lt11: "",
            dt2: "",
            id: "2039",
            lt12: ""
        },
        {
            dt1: "Papagei",
            sy7: "",
            id: "3415",
            lt10: "bobğo",
            lt11: "",
            dt2: "",
            sy9: "",
            dt3: "",
            sy8: "",
            lt12: "",
            sy4: "ܒܒܓܐ",
            sy5: "ܒܳܒܓ݂ܳܐ",
            sy6: ""
        },
        {
            dt2: "",
            id: "3896",
            dt1: "Schamo",
            lt12: "",
            sy9: "",
            sy7: "",
            lt10: "ܫܡܐ",
            lt11: "ܫܡܐ",
            sy4: "ܫܡܐ",
            sy5: "ܫܰܡܳܐ",
            sy6: "",
            sy8: "",
            dt3: ""
        },
        {
            dt1: "Ich möchte sterben",
            lt12: "",
            id: "5435",
            sy6: "",
            sy5: "ܒܳܥܝܳܐ ܐ̱ܢܳܐ ܕܡܳܝܬ݂ܳܐ ܐ̱ܢܳܐ",
            dt2: "",
            lt11: "",
            sy7: "",
            sy9: "",
            dt3: "",
            sy8: "",
            lt10: "boĉyono dmoythono",
            sy4: "ܒܳܥܝܳܐ ܐ̱ܢܳܐ ܕܡܳܝܬ݂ܳܐ ܐ̱ܢܳܐ"
        },
        {
            sy4: "ܙܠܓܐ",
            sy8: "",
            dt2: "",
            sy9: "",
            lt10: "zalgo",
            sy5: "ܙܰܠܓܳܐ",
            dt3: "",
            sy6: "",
            lt12: "",
            dt1: "Strahl",
            lt11: "",
            id: "4289",
            sy7: ""
        },
        {
            dt2: "",
            sy7: "ܡܳܫ",
            id: "5997",
            lt11: "mosh",
            sy6: "ܡܫ",
            lt10: "gosh",
            lt12: "shgam",
            dt1: "antasten",
            sy8: "ܫܓܡ",
            sy9: "ܫܓܰܡ",
            dt3: "",
            sy4: "ܓܫ",
            sy5: "ܓܳܫ"
        },
        {
            sy8: "",
            id: "4124",
            dt2: "sonderbar",
            sy4: "ܕܝܠܢܝܐ",
            sy6: "ܕܝܠܢܐܝܬ",
            lt10: "dilonoyo",
            sy7: "ܕܺܝܠܳܢܳܐܝܺܬ݂",
            sy5: "ܕܺܝܠܳܢܳܝܳܐ",
            lt12: "",
            lt11: "dilonoith",
            dt1: "separat",
            sy9: "",
            dt3: ""
        },
        {
            lt12: "",
            sy8: "",
            dt2: "",
            dt1: "Malakhanna",
            lt11: "ܡܠܟܚܢܢܐ",
            lt10: "ܡܠܟܚܢܢܐ",
            sy5: "ܡܰܠܰܟܚܰܢܢܰܐ",
            id: "2897",
            sy4: "ܡܠܟܚܢܢܐ",
            sy7: "",
            dt3: "",
            sy6: "",
            sy9: ""
        },
        {
            sy5: "ܐܶܣܦܺܝܪ ܐܺܝܕܳܐ",
            sy9: "",
            sy8: "",
            lt12: "",
            dt1: "Volleyball",
            sy7: "",
            dt3: "",
            id: "4869",
            lt10: "esfir iđo",
            lt11: "",
            dt2: "",
            sy6: "",
            sy4: "ܐܣܦܝܪ ܐܝܕܐ"
        },
        {
            dt1: "Jahr",
            sy9: "",
            lt12: "",
            id: "2272",
            sy8: "",
            lt11: "shno",
            sy6: "ܫܢܐ",
            lt10: "shato",
            dt3: "",
            sy4: "ܫܢܬܐ",
            dt2: "",
            sy7: "ܫܢܳܐ",
            sy5: "ܫܰܢ̱ܬܳܐ"
        },
        {
            dt2: "",
            lt12: "",
            sy9: "",
            lt10: "Mțayli maye",
            sy5: "ܡܛܰܝܠܺܝ ܡܰܝ̈ ܐ",
            sy8: "",
            sy7: "",
            sy4: "ܡܛܝܠܝ ܡܝ ܐ",
            "date": {
                "seconds": 1590849106,
                "nanoseconds": 761000000
            },
            lt11: "",
            dt1: "Bring mir Wasser",
            dt3: "",
            sy6: "",
            id: "6Zj80ZMVhjiG3PcvjskX"
        },
        {
            sy4: "ܩܠܚܠܚܐ",
            lt11: "shamoro",
            sy9: "ܨܽܘܢܕܳܐ",
            sy5: "ܩܠܰܚܠܳܚܳܐ",
            sy6: "ܫܡܪܐ",
            id: "1285",
            lt10: "qlaĥloĥo",
            dt1: "Fenchel",
            dt2: "Fenchel Anis",
            sy7: "ܫܰܡܪܳܐ",
            sy8: "ܨܘܢܕܐ",
            dt3: "",
            lt12: "ŝundo"
        },
        {
            lt10: "abro",
            sy8: "",
            dt2: "",
            dt1: "Sohn",
            sy6: "ܒܪ",
            dt3: "",
            sy9: "",
            id: "4182",
            lt11: "bar",
            sy5: "ܐܰܒܪܳܐ",
            sy7: "ܒܰܪ",
            lt12: "",
            sy4: "ܐܒܪܐ"
        },
        {
            sy4: "ܦܚܡܝܐ",
            dt1: "ähnlich",
            dt3: "",
            sy6: "ܦܚܡܢܐܝܬ",
            lt11: "feĥmonoith",
            id: "161",
            lt12: "",
            lt10: "feĥmoyo",
            sy7: "ܦܶܚܡܳܢܳܐܝܺܬ݂",
            sy8: "",
            sy9: "",
            dt2: "ebenbildlich",
            sy5: "ܦܶܚܡܳܝܳܐ"
        },
        {
            lt12: "",
            sy5: "ܠܰܛ",
            dt3: "",
            id: "1338",
            sy7: "",
            sy6: "",
            sy4: "ܠܛ",
            sy8: "",
            dt1: "fluchen",
            dt2: "",
            lt11: "",
            lt10: "laț",
            sy9: ""
        },
        {
            sy8: "",
            sy7: "ܫܘܰܪ",
            lt12: "",
            sy9: "",
            lt11: "shwar",
            dt2: "hüpfen",
            lt10: "qwaz",
            sy6: "ܫܘܪ",
            id: "4224",
            dt3: "",
            dt1: "springen",
            sy4: "ܩܘܙ",
            sy5: "ܩܘܰܙ"
        },
        {
            sy4: "ܨܝܒܐ",
            sy5: "ܨܺܝܒܳܐ",
            sy8: "",
            lt12: "",
            id: "6050",
            sy6: "",
            lt11: "",
            sy9: "",
            dt1: "Holzsplitter",
            lt10: "ŝibo",
            dt2: "",
            dt3: "",
            sy7: ""
        },
        {
            lt12: "",
            lt11: "",
            sy9: "",
            dt1: "Eigenschaft",
            sy5: "ܩܢܽܘܡܳܝܽܘܬ݂ܳܐ",
            dt3: "",
            dt2: "Persönlichkeit",
            lt10: "qnumoyutho",
            sy6: "",
            sy8: "",
            sy4: "ܩܢܘܡܝܘܬܐ",
            id: "6441",
            sy7: ""
        },
        {
            lt11: "",
            sy7: "",
            dt1: "Korrektur",
            dt2: "Verbesserung",
            sy6: "",
            sy8: "",
            sy9: "",
            sy4: "ܬܪܘܨܘܬܐ",
            id: "2619",
            sy5: "ܬܳܪܽܘܨܽܘܬ݂ܳܐ",
            lt12: "",
            dt3: "",
            lt10: "toruŝutho"
        },
        {
            sy5: "ܟܶܒܫܳܐ",
            lt12: "kbosho",
            sy8: "ܟܒܫܐ",
            lt11: "ĉorwoyto",
            id: "5725",
            sy7: "ܥܳܪܘܳܝܬܳܐ",
            sy4: "ܟܒܫܐ",
            sy6: "ܥܪܘܝܬܐ",
            dt3: "",
            dt2: "",
            sy9: "ܟܒܳܫܳܐ",
            dt1: "Achse",
            lt10: "kebsho"
        },
        {
            lt10: "tamimo",
            id: "1874",
            lt11: "tamimo",
            lt12: "",
            sy6: "ܬܡܝܡܐ",
            dt3: "ungefährlich",
            dt1: "harmlos",
            sy8: "",
            sy7: "ܬܰܡܺܝܡܳܐ",
            dt2: "unschuldig",
            sy5: "ܬܐܡܝܡܐ",
            sy9: "",
            sy4: "ܬܐܡܝܡܐ"
        },
        {
            sy9: "",
            lt12: "",
            sy7: "",
            lt10: "ܡܠܝܘܬܐ",
            sy5: "ܡܰܠܝܽܘܬܰܐ",
            dt1: "Malyuta",
            sy8: "",
            dt3: "",
            dt2: "",
            lt11: "ܡܠܝܘܬܐ",
            sy6: "",
            id: "2908",
            sy4: "ܡܠܝܘܬܐ"
        },
        {
            sy5: "ܩܽܘܠܳܥܳܐ",
            sy4: "ܩܘܠܥܐ",
            sy6: "",
            lt12: "",
            id: "1723",
            dt1: "Granate",
            sy9: "",
            sy8: "",
            sy7: "",
            dt2: "",
            lt10: "quloĉo",
            lt11: "",
            dt3: ""
        },
        {
            dt2: "",
            dt1: "Verboten",
            sy7: "",
            sy9: "",
            id: "5488",
            sy8: "",
            sy6: "",
            sy5: "ܟܰܠܝܳܐ",
            lt11: "",
            lt10: "kalyo",
            sy4: "ܟܠܝܐ",
            lt12: "",
            dt3: ""
        },
        {
            sy7: "",
            sy4: "ܛܘܟܣ ܚܕܝܘܬܐ ܐܦܪܝܩܝܬܐ",
            lt10: "țukos ĥđoyutho afriqoyto",
            dt1: "Organisation für die Einheit Afrikas",
            sy9: "",
            sy5: "ܛܽܘܟܳܣ ܚܕܳܝܽܘܬ݂ܳܐ ܐܰܦܪܺܝܩܳܝܬܳܐ",
            id: "3387",
            sy8: "",
            sy6: "",
            lt12: "",
            dt2: "",
            dt3: "",
            lt11: ""
        },
        {
            lt11: "",
            lt12: "",
            dt1: "Auftrag",
            sy5: "ܦܽܘܩܕܳܢܳܐ",
            sy8: "",
            dt2: "Befehl",
            id: "362",
            lt10: "fuqdono",
            sy9: "",
            sy7: "",
            sy4: "ܦܘܩܕܢܐ",
            dt3: "",
            sy6: ""
        },
        {
            id: "1789",
            lt12: "",
            sy6: "",
            dt3: "",
            lt11: "ܗܒܐܠܗܐ",
            sy4: "ܗܒܐܠܗܐ",
            sy5: "ܗܰܒܰܐܠܰܗܰܐ",
            dt2: "",
            sy7: "",
            sy9: "",
            sy8: "",
            dt1: "Habalaha",
            lt10: "ܗܒܐܠܗܐ"
        },
        {
            lt11: "",
            sy7: "",
            lt12: "",
            sy5: "ܒܰܟ݂ܳܝܬܳܐ",
            dt3: "",
            id: "5032",
            lt10: "bakhoyto",
            sy8: "",
            sy4: "ܒܟܝܬܐ",
            sy6: "",
            dt1: "Weinende",
            dt2: "",
            sy9: ""
        },
        {
            lt11: "",
            sy9: "",
            dt3: "",
            sy7: "",
            lt12: "",
            sy4: "ܡܣܘܪܩܐ",
            dt2: "",
            id: "2398",
            lt10: "masurqo",
            sy8: "",
            sy5: "ܡܰܣܽܘܪܩܳܐ",
            dt1: "Kamm",
            sy6: ""
        },
        {
            sy8: "",
            sy4: "ܐܣܬܘܐ ܕܟܢܘܫܝܐ",
            sy5: "ܐܶܣܬܘܳܐ ܕܰܟܢܽܘܫܝܳܐ",
            lt10: "esțwo d`knushyo",
            dt2: "",
            sy6: "",
            lt12: "",
            id: "4799",
            lt11: "",
            dt3: "",
            sy9: "",
            sy7: "",
            dt1: "Versammlungshalle"
        },
        {
            dt2: "",
            sy5: "ܬܝܳܟ݂ܳܐ",
            dt3: "",
            sy4: "ܬܝܟܐ",
            dt1: "Zaunpfahl",
            lt11: "",
            lt12: "",
            sy9: "",
            sy7: "",
            id: "6267",
            sy6: "",
            sy8: "",
            lt10: "tyokho"
        },
        {
            dt2: "",
            dt1: "lügen",
            sy9: "",
            lt12: "",
            sy5: "ܕܓܰܠ",
            sy4: "ܕܓܠ",
            id: "2870",
            dt3: "",
            sy8: "",
            lt10: "dagel",
            sy6: "",
            lt11: "",
            sy7: ""
        },
        {
            lt11: "",
            dt2: "Auftakt",
            id: "5913",
            sy7: "",
            sy4: "ܒܪܝܫܐܝܬ",
            dt1: "Anbeginn",
            sy9: "",
            sy5: "ܒܪܺܝܫܝܺܬ݂",
            sy8: "",
            lt10: "brishith",
            lt12: "",
            sy6: "",
            dt3: ""
        },
        {
            id: "1609",
            dt2: "erschaffen",
            sy8: "",
            sy9: "",
            sy7: "",
            sy4: "ܥܒܝܕܐ",
            dt3: "kreiert",
            dt1: "getan",
            sy6: "",
            lt11: "",
            lt12: "",
            sy5: "ܥܒܽܝܕܳܐ",
            lt10: "ĉbido"
        },
        {
            lt12: "",
            lt11: "",
            sy7: "",
            sy8: "",
            dt2: "",
            sy5: "ܣܰܘܟܳܐ",
            id: "318",
            dt1: "Ast",
            dt3: "",
            lt10: "sauko",
            sy6: "",
            sy4: "ܣܘܟܐ",
            sy9: ""
        },
        {
            sy8: "",
            lt11: "",
            dt3: "",
            sy6: "",
            lt10: "țablo",
            id: "4575",
            sy7: "",
            sy4: "ܛܒܠܐ",
            sy9: "",
            lt12: "",
            dt1: "Trommel",
            sy5: "ܛܰܒܠܳܐ",
            dt2: ""
        },
        {
            sy5: "ܬܶܫܪܺܢ ܩܰܕܡܳܝܳܐ",
            sy6: "",
            lt10: "teshrin qadmoyo",
            lt12: "",
            dt1: "Oktober",
            sy9: "",
            dt2: "",
            lt11: "",
            dt3: "",
            sy7: "",
            id: "3365",
            sy4: "ܬܫܪܢ ܩܕܡ",
            sy8: ""
        },
        {
            sy6: "",
            sy4: "ܣܡܝܘܬܐ",
            sy5: "ܣܰܡܝܽܘܬ݂ܳܐ",
            lt10: "samyutho",
            dt1: "Blindheit",
            sy8: "",
            id: "650",
            sy9: "",
            sy7: "",
            lt12: "",
            dt3: "",
            lt11: "",
            dt2: ""
        },
        {
            dt2: "außen",
            sy4: "ܒܪܝܐ",
            lt12: "",
            lt11: "lbar",
            sy6: "ܠܒܪ",
            sy5: "ܒܰܪܳܝܳܐ",
            dt1: "außerhalb",
            lt10: "baroyo",
            sy7: "ܠܒܰܪ",
            id: "387",
            sy9: "",
            sy8: "",
            dt3: "draußen"
        },
        {
            sy6: "",
            sy9: "",
            sy5: "ܪܚܺܝܡܳܐ",
            dt3: "",
            sy8: "",
            sy4: "ܪܚܝܡܐ",
            lt12: "",
            id: "2811",
            dt1: "liebenswert",
            lt10: "rĥimo",
            dt2: "",
            sy7: "",
            lt11: ""
        },
        {
            sy7: "",
            sy8: "",
            lt11: "",
            sy4: "ܒܒܪܝܬܐ",
            dt2: "",
            lt10: "b´britho",
            id: "2180",
            sy9: "",
            dt3: "",
            dt1: "In der Welt",
            lt12: "",
            sy6: "",
            sy5: "ܒܒܪܺܝܬ݂ܳܐ"
        },
        {
            lt12: "",
            dt3: "",
            dt2: "",
            sy7: "ܫܰܪܒܬ݂ܳܐ",
            sy8: "",
            lt10: "sharbthonoyutho",
            id: "4861",
            sy5: "ܫܰܪܒܬ݂ܳܢܳܝܽܘܬ݂ܳܐ",
            lt11: "sharbtho",
            dt1: "Volksstamm",
            sy4: "ܫܪܒܬܢܝܘܬܐ",
            sy6: "ܫܪܒܬܐ",
            sy9: ""
        },
        {
            lt12: "",
            sy4: "ܡܕܝܢܝܘܬܐ",
            sy6: "",
            sy8: "",
            sy5: "ܡܕܺܝܢܳܝܽܘܬ݂ܳܐ",
            lt11: "",
            id: "6297",
            dt2: "",
            dt1: "Zivilregierung",
            lt10: "mđinoyutho",
            sy9: "",
            sy7: "",
            dt3: ""
        },
        {
            id: "5096",
            dt3: "",
            dt1: "Wieder gedenken wir",
            sy9: "",
            sy5: "ܬܽܘܒ ܕܶܝܢ ܡܶܬ݂ܰܥܗܕܺܝܢܰܢ",
            lt12: "",
            sy4: "ܬܘܒ ܕܝܢ ܡܬܥܗܕܝܢܢ",
            lt10: "tub deyn methaĉdinan",
            dt2: "",
            lt11: "",
            sy8: "",
            sy6: "",
            sy7: ""
        },
        {
            sy6: "",
            sy4: "ܟ",
            dt2: "",
            dt3: "",
            lt11: "",
            id: "2462",
            sy9: "",
            sy7: "",
            lt12: "",
            sy5: "ܟ݂",
            dt1: "kh",
            sy8: "",
            lt10: "kh"
        },
        {
            id: "2918",
            sy7: "",
            dt3: "",
            sy8: "",
            lt10: "gețo",
            dt1: "Mann mit einer Hode",
            sy6: "",
            sy4: "ܓܛܐ",
            sy5: "ܓܶܛܳܐ",
            sy9: "",
            lt12: "",
            dt2: "",
            lt11: ""
        },
        {
            id: "233",
            sy5: "ܨܶܢܳܪܬܳܐ",
            sy6: "",
            dt2: "",
            sy7: "",
            lt12: "",
            sy9: "",
            dt3: "",
            lt11: "",
            sy8: "",
            sy4: "ܨܢܪܬܐ",
            lt10: "ŝenorto",
            dt1: "Angel"
        },
        {
            dt1: "Kilopreis",
            lt10: "țime d`kilo",
            sy6: "",
            lt11: "",
            id: "2469",
            sy8: "",
            sy7: "",
            dt3: "",
            lt12: "",
            sy5: "ܛܺܝܡܶܐ ܕܟܺܝܠܳܐ",
            sy4: "ܛܝܡܐ ܕܟܝܠܐ",
            sy9: "",
            dt2: ""
        },
        {
            id: "1088",
            sy6: "",
            lt12: "",
            lt11: "ܐܠܫܡܫ",
            dt2: "",
            lt10: "ܐܠܫܡܫ",
            sy4: "ܐܠܫܡܫ",
            dt1: "Elschamasch",
            sy9: "",
            sy7: "",
            sy8: "",
            sy5: "ܐܶܠܫܰܡܰܫ",
            dt3: ""
        },
        {
            lt11: "",
            dt2: "",
            id: "1427",
            sy5: "ܐܰܡܺܝܢܳܐܺܝܬ݂ ܒܠܶܒܝ̱",
            lt12: "",
            dt1: "Für immer in meinem Herz",
            sy9: "",
            sy8: "",
            lt10: "aminoith bleb",
            sy6: "",
            dt3: "",
            sy7: "",
            sy4: "ܐܡܝܢܐܝܬ ܒܠܒܝ"
        },
        {
            id: "103",
            dt1: "Ablahad",
            sy5: "ܐܰܒܠܰܚܰܕ",
            dt3: "",
            sy8: "",
            sy4: "ܐܒܠܚܕ",
            lt10: "ܐܒܠܚܕ",
            sy9: "",
            sy6: "",
            lt11: "ܐܒܠܚܕ",
            dt2: "",
            sy7: "",
            lt12: ""
        },
        {
            lt12: "",
            sy9: "",
            id: "5695",
            sy7: "",
            sy8: "",
            sy5: "ܦܰܣܬܳܐ ܕܒܶܝܬ݂ ܒܽܘܫܳܠܳܐ",
            lt10: "fasto dbeth busholo",
            sy4: "ܦܣܬܐ ܕܒܬ ܒܘܫܠܐ",
            sy6: "",
            dt3: "",
            lt11: "",
            dt2: "",
            dt1: "Abwaschlappen"
        },
        {
            lt11: "",
            sy7: "",
            sy8: "",
            dt3: "",
            sy6: "",
            sy9: "",
            sy5: "ܒܳܬ݂ܰܪ ܝܰܘܡܳܐ",
            lt10: "bothar yaumo",
            lt12: "",
            dt2: "",
            id: "3165",
            dt1: "nächster Tag",
            sy4: "ܒܬܪ ܝܘܡܐ"
        },
        {
            sy4: "ܙܘܥܬܐ",
            lt12: "dĥilutho",
            dt2: "Furcht",
            sy5: "ܙܽܘܥܬ݂ܳܐ",
            sy6: "ܕܚܠܬܐ",
            dt1: "Angst",
            lt10: "zuĉtho",
            lt11: "deĥltho",
            dt3: "",
            id: "237",
            sy7: "ܕܶܚܠܬ݂ܳܐ",
            sy8: "ܕܚܝܠܘܬܐ",
            sy9: "ܕܚܺܝܠܽܘܬ݂ܳܐ"
        },
        {
            sy8: "",
            sy9: "",
            id: "2917",
            lt12: "",
            sy7: "",
            lt11: "",
            sy5: "ܓܰܒܪܳܐ ܕܰܐܠܳܗܳܐ",
            dt1: "Mann Gottes",
            sy6: "",
            dt3: "",
            dt2: "",
            lt10: "gabro d`aloho",
            sy4: "ܓܒܪܐ ܕܐܠܗܐ"
        },
        {
            id: "1190",
            dt3: "",
            sy9: "",
            lt12: "",
            lt11: "",
            dt1: "Esel ruft",
            dt2: "",
            sy7: "",
            sy4: "ܚܡܪܐ ܢܥܪ",
            sy8: "",
            lt10: "ĥmoro noĉar",
            sy6: "",
            sy5: "ܚܡܳܪܳܐ ܢܳܥܰܪ"
        },
        {
            sy8: "",
            sy5: "ܥܰܡܳܘܣ",
            lt11: "ܥܡܘܣ",
            sy6: "",
            lt12: "",
            sy4: "ܥܡܘܣ",
            sy9: "",
            dt2: "",
            dt3: "",
            lt10: "ܥܡܘܣ",
            dt1: "Amos",
            sy7: "",
            id: "218"
        },
        {
            sy4: "ܡܬܩܪܒܢܐ",
            sy9: "",
            lt12: "",
            lt10: "methqarbono",
            lt11: "",
            sy6: "",
            sy8: "",
            id: "5968",
            sy7: "",
            sy5: "ܡܶܬ݂ܩܰܪܒܳܢܳܐ",
            dt1: "annähernd",
            dt3: "",
            dt2: ""
        },
        {
            sy4: "ܩܡܝܣܬܐ",
            sy7: "ܟܽܘܬܺܝܢܳܐ",
            lt10: "qomisto",
            id: "1959",
            lt12: "marțuțo",
            dt1: "Hemd",
            lt11: "kutino",
            sy5: "ܩܳܡܺܝܣܬܳܐ",
            sy6: "ܟܘܬܝܢܐ",
            sy9: "ܡܰܪܛܽܘܛܳܐ",
            dt2: "Bluse",
            dt3: "Oberhemd",
            sy8: "ܡܪܛܘܛܐ"
        },
        {
            sy4: "ܒܛܝܢܘܬܐ",
            sy7: "",
            sy6: "",
            lt10: "bținutho",
            id: "4066",
            lt12: "",
            sy5: "ܒܛܺܝܢܽܘܬ݂ܳܐ",
            lt11: "",
            sy9: "",
            dt3: "",
            dt2: "",
            sy8: "",
            dt1: "Schwangerschaft"
        },
        {
            dt1: "Wecker",
            sy6: "",
            dt3: "",
            lt11: "",
            sy9: "",
            sy7: "",
            lt12: "",
            sy5: "ܡܰܩܺܝܡܳܢܳܐ",
            id: "5467",
            sy4: "ܡܰܩܺܝܡܳܢܳܐ",
            dt2: "",
            sy8: "",
            lt10: "maqimono"
        },
        {
            dt1: "Ermäßigung",
            sy7: "",
            sy6: "",
            lt10: "qufoso",
            dt3: "",
            sy5: "ܩܽܘܦܳܣܳܐ",
            id: "1162",
            sy8: "",
            sy4: "ܩܘܦܣܐ",
            dt2: "Nachlass",
            lt12: "",
            lt11: "",
            sy9: ""
        },
        {
            sy9: "",
            id: "1528",
            sy5: "ܙܶܠ ܠܳܘܬ݂ ܟܽܘܪܣܝܳܐ",
            dt3: "",
            lt10: "sel lwoth kursyo",
            sy8: "",
            sy4: "ܙܠ ܠܘܬ ܟܘܪܣܝܐ",
            sy6: "",
            lt12: "",
            dt2: "",
            lt11: "",
            sy7: "",
            dt1: "gehe zum Stuhl"
        },
        {
            sy9: "",
            lt12: "ܝܗܘܫܥ",
            dt2: "",
            sy8: "",
            sy5: "ܝܶܗܽܘܫܳܥ",
            dt3: "",
            id: "2299",
            sy7: "ܝܶܗܽܘܫܰܥ",
            lt10: "ܝܗܘܫܥ",
            lt11: "ܝܗܘܫܥ",
            dt1: "Jehuschuah",
            sy6: "ܝܗܘܫܥ",
            sy4: "ܝܗܘܫܥ"
        },
        {
            id: "3688",
            dt2: "",
            sy7: "",
            sy9: "",
            sy5: "ܪܶܡܙܳܐ",
            lt12: "",
            dt1: "Remzo",
            lt10: "ܪܡܙܐ",
            dt3: "",
            sy6: "",
            sy8: "",
            lt11: "ܪܡܙܐ",
            sy4: "ܪܡܙܐ"
        },
        {
            sy5: "ܝܰܒܶܫ",
            sy7: "",
            sy8: "",
            lt10: "yabesh",
            sy4: "ܝܒܫ",
            dt1: "trocknen lassen",
            dt3: "",
            lt12: "",
            sy6: "",
            lt11: "",
            sy9: "",
            dt2: "",
            id: "4574"
        },
        {
            dt2: "",
            sy9: "",
            dt3: "",
            sy8: "",
            sy7: "",
            sy5: "ܦܳܠܚ̈ܶܐ ܕܫܽܘܠܛܳܢܽܘܬ݂ܳܐ",
            sy6: "",
            lt12: "",
            sy4: "ܦܠܚܐ  ܕܫܘܠܛܢܘܬܐ",
            lt11: "",
            dt1: "Regierungstruppe",
            id: "3661",
            lt10: "folĥe d`shulțonutho"
        },
        {
            lt11: "ܢܫܪܬ",
            sy6: "",
            lt10: "ܢܫܪܬ",
            sy4: "ܢܫܪܬ",
            sy5: "ܢܶܫܪܰܬ",
            dt2: "",
            dt3: "",
            lt12: "",
            sy7: "",
            sy9: "",
            sy8: "",
            dt1: "Neschrat",
            id: "3247"
        },
        {
            dt1: "Weltereignis",
            lt12: "",
            sy7: "",
            sy4: "ܨܒܘܬܐ ܕܥܠܡܐ",
            sy6: "",
            lt10: "ŝbutho d`ĉolmo",
            id: "5050",
            sy8: "",
            sy9: "",
            lt11: "",
            dt3: "",
            sy5: "ܨܒܽܘܬ݂ܳܐ ܕܥܳܠܡܳܐ",
            dt2: ""
        },
        {
            sy4: "ܦܪܫܘܬܝܐ",
            sy7: "",
            id: "1246",
            sy9: "",
            sy6: "",
            dt1: "Fallschirmjäger",
            lt10: "farashutoyo",
            dt2: "",
            lt12: "",
            lt11: "",
            dt3: "",
            sy5: "ܦܰܪܰܫܽܘܬܳܝܳܐ",
            sy8: ""
        },
        {
            id: "4875",
            lt12: "",
            sy7: "",
            dt3: "",
            lt10: "mshamlyoith",
            sy6: "",
            lt11: "",
            dt2: "",
            sy8: "",
            sy9: "",
            sy5: "ܡܫܰܡܠܝܳܐܝܺܬ݂",
            sy4: "ܡܫܡܠܝܐܝܬ",
            dt1: "vollkommen"
        },
        {
            id: "1106",
            sy4: "ܡܠܐܟܐ",
            dt1: "Engel",
            lt10: "malakho",
            sy8: "",
            sy6: "",
            lt12: "",
            sy9: "",
            sy7: "",
            dt3: "",
            dt2: "Überbringer",
            sy5: "ܡܰܠܰܐܟ݂ܳܐ",
            lt11: ""
        },
        {
            dt1: "Fülle den Stift mit Tinte",
            sy9: "",
            sy4: "ܡܠܝ ܩܢܝܐ ܕܝܘܬܐ",
            lt11: "",
            id: "1424",
            sy6: "",
            dt2: "",
            sy8: "",
            sy7: "",
            lt10: "mli qanyo dyutho",
            lt12: "",
            sy5: "ܡܠܺܝ ܩܰܢܝܳܐ ܕܝܽܘܬ݂ܳܐ",
            dt3: ""
        },
        {
            sy9: "",
            sy5: "ܓܽܘܡܕܳܢܳܝܳܐ",
            sy4: "ܓܘܡܕܢܝܐ",
            id: "4678",
            sy6: "ܓܘܡܕܢܐܝܬ",
            lt10: "gumdonoyo",
            sy8: "",
            dt3: "",
            dt2: "schamlos",
            sy7: "ܓܽܘܡܕܳܢܳܐܝܺܬ݂",
            lt12: "",
            dt1: "unverschämt",
            lt11: "gumdonoith"
        },
        {
            dt2: "",
            sy5: "ܐܰܫܺܝܡ",
            lt11: "",
            lt12: "",
            sy6: "",
            id: "5731",
            sy4: "ܐܫܝܡ",
            dt1: "ächten",
            sy9: "",
            sy7: "",
            lt10: "ashim",
            dt3: "",
            sy8: ""
        },
        {
            lt10: "tuyoro",
            sy6: "",
            sy5: "ܬܽܘܝܳܪܳܐ",
            sy7: "",
            dt1: "Sensation",
            lt12: "",
            dt2: "",
            id: "4121",
            dt3: "",
            lt11: "",
            sy8: "",
            sy4: "ܬܘܝܪܐ",
            sy9: ""
        },
        {
            sy5: "ܫܶܬܶܐܣܬܳܐ",
            lt10: "shetesto",
            sy6: "ܫܪܫܐ",
            sy7: "ܫܶܪܫܳܐ",
            dt1: "Grundlage",
            lt11: "shersho",
            dt2: "Basis",
            dt3: "",
            sy9: "",
            sy8: "",
            id: "1751",
            sy4: "ܫܬܐܣܬܐ",
            lt12: ""
        },
        {
            sy9: "",
            sy6: "",
            sy8: "",
            id: "5305",
            sy5: "ܢܽܘܩܰܙܬܳܢܽܘܬ݂ܳܐ",
            sy4: "ܢܘܩܙܬܢܘܬܐ",
            dt1: "Zentralismus",
            lt12: "",
            dt2: "",
            lt11: "",
            dt3: "",
            lt10: "nuqaztonutho",
            sy7: ""
        },
        {
            lt12: "",
            lt11: "",
            dt3: "",
            dt1: "Schild",
            lt10: "luĥo",
            sy7: "",
            sy5: "ܠܽܘܚܳܐ",
            id: "3949",
            sy6: "",
            sy8: "",
            sy9: "",
            dt2: "Tafel",
            sy4: "ܠܘܚܐ"
        },
        {
            dt1: "Feuchtigkeit",
            sy4: "ܬܠܝܠܘܬܐ",
            dt2: "Nässe",
            sy7: "ܪܶܛܒܳܐ",
            sy5: "ܬܰܠܺܝܠܽܘܬ݂ܳܐ",
            lt11: "rețbo",
            id: "1301",
            sy8: "ܪܛܝܒܘܬܐ",
            sy6: "ܪܛܒܐ",
            lt12: "rațibutho",
            dt3: "",
            lt10: "talilutho",
            sy9: "ܪܰܛܺܝܒܽܘܬ݂ܳܐ"
        },
        {
            sy8: "ܚܓܪ",
            sy4: "ܚܓܝܪܐ",
            sy7: "ܚܰܓܰܪ",
            lt12: "ĥgar",
            sy5: "ܚܓܺܝܪܳܐ",
            dt2: "hinken",
            dt3: "Krüppel",
            lt10: "ĥgiro",
            id: "4753",
            sy9: "ܚܓܰܪ",
            dt1: "verkrüppelt",
            lt11: "ĥagar",
            sy6: "ܚܓܪ"
        },
        {
            dt2: "",
            dt3: "",
            lt12: "",
            sy5: "ܩܽܡ ܘܬܰܟ݂ܬܶܫ",
            lt11: "",
            sy8: "",
            id: "5453",
            sy6: "",
            dt1: "Steh auf und kämpfe",
            lt10: "qum u takhtesh",
            sy4: "ܩܽܡ ܘܬܰܟܬܶܫ",
            sy7: "",
            sy9: ""
        },
        {
            lt12: "",
            sy8: "",
            id: "4993",
            dt3: "Becken",
            dt2: "Taufbecken",
            sy9: "",
            sy6: "",
            dt1: "Waschbecken",
            lt10: "wazno",
            sy5: "ܘܰܙܢܳܐ",
            lt11: "",
            sy4: "ܘܙܢܐ",
            sy7: ""
        },
        {
            lt10: "shakhronutho",
            dt3: "",
            dt2: "",
            sy4: "ܫܟܪܢܘܬܐ",
            sy5: "ܫܰܟ݂ܪܳܢܽܘܬ݂ܳܐ",
            dt1: "Alkoholismus",
            sy7: "",
            id: "5830",
            lt11: "",
            sy8: "",
            sy9: "",
            lt12: "",
            sy6: ""
        },
        {
            lt10: "korusuth ithiqun",
            sy7: "",
            dt3: "",
            dt1: "Moralpredigt",
            sy9: "",
            lt12: "",
            id: "3102",
            sy8: "",
            lt11: "",
            sy4: "ܟܪܘܙܘܬ ܐܝܬܝܩܘܢ",
            sy5: "ܟܳܪܽܘܙܽܘܬ݂ ܐܺܝܬ݂ܺܝܩܽܘܢ",
            dt2: "",
            sy6: ""
        },
        {
            dt1: "Jerobeam",
            id: "2304",
            sy9: "",
            dt3: "",
            lt11: "ܝܘܪܒܥܡ",
            sy6: "ܝܘܪܒܥܡ",
            sy4: "ܝܘܪܒܥܡ",
            sy5: "ܝܽܘܪܒܰܥܰܡ",
            lt12: "ܝܘܪܒܥܡ",
            sy8: "",
            dt2: "",
            sy7: "ܝܽܘܪܰܒܥܰܡ",
            lt10: "ܝܘܪܒܥܡ"
        },
        {
            id: "211",
            sy6: "",
            dt3: "",
            sy7: "",
            dt2: "",
            sy9: "",
            sy4: "ܐܡܝܪ",
            lt12: "",
            sy8: "",
            sy5: "ܐܰܡܺܝܪ",
            lt11: "ܐܡܝܪ",
            lt10: "ܐܡܝܪ",
            dt1: "Amir"
        },
        {
            sy5: "ܒܰܪܢܶܡܪܳܐ",
            sy7: "",
            sy9: "",
            lt11: "ܒܪܢܡܪܐ",
            lt10: "ܒܪܢܡܪܐ",
            id: "466",
            sy4: "ܒܪܢܡܪܐ",
            dt1: "Barnemro",
            dt2: "",
            lt12: "",
            sy8: "",
            sy6: "",
            dt3: ""
        },
        {
            sy8: "",
            sy9: "",
            dt2: "",
            dt1: "Geheimdienst",
            sy6: "",
            lt10: "mshatasto d`nuhore",
            id: "1531",
            dt3: "",
            sy5: "ܡܫܰܬܰܐܣܬܳܐ ܕܢܽܘܗܳܪܶܐ",
            sy4: "ܡܫܬܐܣܬܐ ܕܢܘܗܪܐ",
            sy7: "",
            lt11: "",
            lt12: ""
        },
        {
            sy8: "",
            dt2: "",
            sy6: "",
            sy9: "",
            sy5: "ܦܰܪܳܟ݂ܬܳܐ",
            dt1: "Masseuse",
            dt3: "",
            sy4: "ܦܪܟܬܐ",
            lt11: "",
            lt10: "farokhto",
            id: "2963",
            sy7: "",
            lt12: ""
        },
        {
            sy7: "",
            lt11: "",
            lt10: "ĥlizuno",
            id: "3991",
            sy4: "ܚܠܝܙܘܢܐ",
            lt12: "",
            sy9: "",
            dt1: "Schnecke",
            sy8: "",
            dt3: "",
            sy5: "ܚܠܺܝܙܽܘܢܳܐ",
            dt2: "",
            sy6: ""
        },
        {
            id: "4186",
            dt2: "",
            lt10: "folĥe",
            dt3: "",
            sy8: "",
            lt11: "ĉaskar",
            lt12: "",
            sy7: "ܥܰܣܟܰܪ",
            sy4: "ܦܠܚܐ",
            sy5: "ܦܳܠ̈ܚܶܐ",
            dt1: "Soldaten",
            sy6: "ܥܣܟܪ",
            sy9: ""
        },
        {
            sy6: "",
            sy9: "",
            lt11: "",
            sy5: "ܒܺܝܟܺܝܢܺܒܝ",
            id: "614",
            lt12: "",
            sy4: "ܒܝܟܝܢܝ",
            dt1: "Bikini",
            sy7: "",
            dt3: "",
            lt10: "bikini",
            sy8: "",
            dt2: ""
        },
        {
            id: "3831",
            dt1: "Sand",
            lt11: "",
            lt10: "ĥelo",
            dt3: "",
            sy7: "",
            sy4: "ܚܠܐ",
            sy8: "",
            dt2: "",
            sy9: "",
            lt12: "",
            sy6: "",
            sy5: "ܚܶܠܳܐ"
        },
        {
            dt1: "Akar",
            lt12: "",
            dt3: "",
            sy5: "ܐܰܟܰܪ",
            sy7: "",
            sy6: "",
            id: "167",
            sy9: "",
            sy4: "ܐܟܪ",
            sy8: "",
            lt11: "ܐܟܪ",
            lt10: "ܐܟܪ",
            dt2: ""
        },
        {
            sy5: "ܨܰܝܕܳܐ",
            sy6: "",
            lt10: "ŝayodo",
            dt1: "Angler",
            sy8: "",
            sy4: "ܨܝܕܐ",
            lt12: "",
            sy9: "",
            id: "235",
            dt2: "",
            sy7: "",
            dt3: "",
            lt11: ""
        },
        {
            sy5: "ܢܶܒܥܳܐ",
            sy7: "",
            lt12: "",
            sy4: "ܢܒܥܐ",
            sy9: "",
            sy8: "",
            lt11: "",
            lt10: "nebĉo",
            id: "5007",
            sy6: "",
            dt1: "Wasserquelle",
            dt3: "",
            dt2: ""
        },
        {
            dt3: "",
            sy4: "ܢܟܝܠܐ",
            sy9: "ܠܰܚܟܽܘܫܳܐ",
            lt12: "laĥkusho",
            sy7: "ܟܳܝܽܘܡܳܐ",
            sy8: "ܠܚܟܘܫܐ",
            id: "6177",
            dt1: "Intrigant",
            sy5: "ܢܟ݂ܺܝܠܳܐ",
            dt2: "",
            lt11: "koyumo",
            lt10: "nkhilo",
            sy6: "ܟܝܘܡܐ"
        },
        {
            sy6: "",
            id: "5775",
            dt3: "",
            dt1: "Agrarwissenschaft",
            lt12: "",
            sy8: "",
            sy9: "",
            lt10: "mmalluth zaroĉutho",
            dt2: "",
            sy4: "ܡܡܠܠܘܬ ܙܪܥܘܬܐ",
            sy7: "",
            lt11: "",
            sy5: "ܡܡܰܠܠܽܘܬ݂ ܙܰܪܳܥܽܘܬ݂ܳܐ"
        },
        {
            dt2: "",
            dt1: "Seron",
            lt10: "ܣܪܘܢ",
            dt3: "",
            sy6: "",
            sy9: "",
            sy7: "",
            sy4: "ܣܪܘܢ",
            lt12: "",
            lt11: "ܣܪܘܢ",
            sy8: "",
            sy5: "ܣܶܪܳܘܢ",
            id: "4127"
        },
        {
            dt1: "Gottloser",
            sy7: "ܚܰܢܦܳܐ",
            dt3: "Ungläubiger",
            dt2: "Frevler",
            lt10: "rashiĉo",
            sy4: "ܪܫܝܥܐ",
            sy6: "ܚܢܦܐ",
            lt11: "ĥanfo",
            sy5: "ܪܰܫܺܝܥܳܐ",
            lt12: "",
            sy8: "",
            sy9: "",
            id: "1711"
        },
        {
            sy4: "ܐܪܒܝܐ",
            sy8: "ܥܪܒ",
            dt2: "",
            dt3: "",
            sy6: "ܥܪܒܝܐ",
            sy9: "ܥܰܪܰܒ",
            dt1: "Araber",
            sy7: "ܥܰܪܰܒܳܝܳܐ",
            lt12: "ĉarab",
            sy5: "ܐܰܪܰܒܳܝܳܐ",
            lt10: "araboyo",
            lt11: "ĉaraboyo",
            id: "267"
        },
        {
            sy9: "",
            sy7: "",
            dt3: "",
            dt1: "Eislauf",
            sy4: "ܫܪܥܐ",
            sy6: "",
            dt2: "",
            lt12: "",
            id: "1064",
            lt10: "shroĉo",
            lt11: "",
            sy8: "",
            sy5: "ܫܪܳܥܳܐ"
        },
        {
            lt10: "mele ĥaroye",
            id: "2781",
            dt3: "",
            sy7: "",
            sy9: "",
            dt2: "",
            sy6: "",
            sy5: "ܡ̈ܶܠܶܐ ܚܰܪܳܝܶܐ",
            lt12: "",
            sy8: "",
            sy4: "ܡܠܐ ܚܪܝܐ",
            lt11: "",
            dt1: "Letzte Worte"
        },
        {
            sy4: "ܐܬܠܝܛܐ ܕܥܠܡܐ",
            dt2: "",
            sy7: "",
            id: "5055",
            lt12: "",
            dt1: "Weltmeister",
            sy9: "",
            lt10: "athlițo d`ĉolmo",
            lt11: "",
            sy8: "",
            sy6: "",
            dt3: "",
            sy5: "ܐܰܬ݂ܠܺܝܛܳܐ ܕܥܳܠܡܳܐ"
        },
        {
            sy5: "ܚܰܘܩܳܐ",
            lt12: "",
            sy9: "",
            sy8: "",
            lt10: "ĥauqo",
            dt2: "Flächenteil bzgl Landwirtschaft",
            dt3: "",
            dt1: "Hektarzeile",
            lt11: "",
            sy7: "",
            sy4: "ܚܘܩܐ",
            id: "1949",
            sy6: ""
        },
        {
            sy9: "",
            lt11: "",
            sy6: "",
            dt2: "",
            sy7: "",
            sy8: "",
            dt1: "Transparenz",
            lt12: "",
            lt10: "qriĥutho",
            id: "4542",
            dt3: "",
            sy4: "ܩܪܝܚܘܬܐ",
            sy5: "ܩܪܺܝܚܽܘܬ݂ܳܐ"
        },
        {
            dt3: "pensioniert",
            sy4: "ܡܫܘܬܒܐ",
            sy9: "",
            dt1: "Rentner",
            sy6: "",
            sy8: "",
            dt2: "Pensionär",
            lt10: "mshautbo",
            sy7: "",
            lt12: "",
            lt11: "",
            sy5: "ܡܫܰܘܬܒܳܐ",
            id: "3695"
        },
        {
            id: "4484",
            sy5: "ܒܪܽܝܬ݂ܳܐ ܚܰܝ̈ܘܳܬ݂ܳܐ",
            dt3: "",
            dt2: "",
            lt12: "",
            dt1: "Tierwelt",
            sy8: "",
            sy9: "",
            sy6: "",
            sy7: "",
            lt10: "britho d`ĥaywotho",
            sy4: "ܒܪܝܬܐ ܕܚܝܘܬܐ",
            lt11: ""
        },
        {
            dt1: "Haustür",
            sy7: "ܬܰܪܥܳܐ ܕܒܰܝܬܳܐ",
            sy9: "",
            sy4: "ܬܪܥܐ ܒܪܝܐ",
            dt2: "",
            dt3: "",
            id: "1907",
            lt12: "",
            sy6: "ܬܪܥܐ ܕܒܝܬܐ",
            lt11: "tarĉo d`bayto",
            sy8: "",
            sy5: "ܬܰܪܥܳܐ ܒܰܪܳܝܳܐ",
            lt10: "tarĉo baroyo"
        },
        {
            dt2: "",
            dt3: "",
            lt12: "",
            sy8: "",
            sy5: "ܝܽܘܝܳܟ݂ܺܝܢ",
            id: "2329",
            sy4: "ܝܘܝܟ݂ܝܢ",
            sy9: "",
            lt11: "ܝܘܝܟ݂ܝܢ",
            lt10: "ܝܘܝܟ݂ܝܢ",
            dt1: "Joachin",
            sy7: "",
            sy6: ""
        },
        {
            dt1: "dekorieren",
            sy5: "ܨܰܒܶܬ݂",
            sy4: "ܨܒܬ",
            sy9: "",
            lt12: "",
            dt3: "",
            sy8: "",
            sy7: "",
            id: "851",
            dt2: "",
            lt11: "",
            lt10: "ŝabeth",
            sy6: ""
        },
        {
            sy4: "ܚܕܒܫܒܐ",
            dt2: "",
            lt11: "ܚܕܒܫܒܐ",
            dt3: "",
            id: "1808",
            sy8: "",
            sy9: "",
            lt10: "ܚܕܒܫܒܐ",
            sy6: "ܚܘܫܒܐ",
            sy7: "ܚܽܘܫܰܒܳܐ",
            dt1: "Hadbschabo",
            sy5: "ܚܰܕܒܫܰܒܳܐ",
            lt12: ""
        },
        {
            sy5: "ܪܽܘܡܪܳܡܳܐ",
            dt2: "Erhebung",
            dt3: "",
            sy4: "ܪܘܡܪܡܐ",
            id: "1151",
            lt10: "rumromo",
            lt12: "",
            lt11: "",
            dt1: "Erhöhung",
            sy6: "",
            sy9: "",
            sy7: "",
            sy8: ""
        },
        {
            id: "5910",
            lt11: "nŝab",
            dt2: "bepflanzen",
            lt10: "zraĉ",
            dt1: "anbauen",
            sy4: "ܙܪܥ",
            sy9: "ܟܪܰܒ",
            sy6: "ܢܨܒ",
            lt12: "krab",
            sy5: "ܙܪܰܥ",
            sy7: "ܢܨܰܒ",
            dt3: "säen",
            sy8: "ܟܪܒ"
        },
        {
            sy9: "",
            dt2: "",
            sy7: "",
            dt3: "",
            dt1: "Regen",
            lt12: "",
            sy8: "",
            lt11: "",
            sy5: "ܡܰܛܪܳܐ",
            id: "3657",
            sy6: "",
            lt10: "mațro",
            sy4: "ܡܛܪܐ"
        },
        {
            lt10: "qațo",
            lt11: "",
            id: "2433",
            sy7: "",
            dt2: "",
            lt12: "",
            dt1: "Katze",
            sy8: "",
            sy6: "",
            sy5: "ܩܰܛܳܐ",
            sy4: "ܩܛܐ",
            sy9: "",
            dt3: ""
        },
        {
            dt3: "",
            id: "5238",
            sy6: "",
            lt12: "",
            sy9: "",
            sy7: "",
            sy8: "",
            dt2: "Zahncreme",
            dt1: "Zahnpasta",
            lt10: "darmono d`ĉarshe",
            sy4: "ܕܪܡܢܐ ܕܥܪܫܐ",
            lt11: "",
            sy5: "ܕܰܪܡܳܢܳܐ ܕܥܰܪܫܳܐ"
        },
        {
            dt2: "",
            lt12: "",
            sy7: "",
            lt10: "bramsho",
            sy4: "ܒܪܡܫܐ",
            sy9: "",
            id: "5872",
            sy8: "",
            sy6: "",
            dt3: "",
            sy5: "ܒܪܰܡܫܳܐ",
            dt1: "Am Abend",
            lt11: ""
        },
        {
            lt10: "ܡܬܝܐ",
            sy6: "",
            dt3: "",
            lt11: "ܡܬܝܐ",
            sy5: "ܡܰܬܺܝܰܐ",
            id: "2967",
            sy4: "ܡܬܝܐ",
            sy7: "",
            sy9: "",
            lt12: "",
            sy8: "",
            dt2: "",
            dt1: "Matia"
        },
        {
            dt2: "",
            lt11: "",
            sy9: "",
            lt12: "",
            sy4: "ܠܒܐ",
            dt3: "",
            sy5: "ܠܶܒܳܐ",
            sy6: "",
            sy8: "",
            dt1: "Herz",
            sy7: "",
            id: "1977",
            lt10: "lebo"
        },
        {
            sy7: "ܡܙܰܘܓܳܐܺܝܬ݂",
            sy6: "ܡܙܘܓܐܝܬ",
            sy4: "ܙܘܓܐ ܙܘܓܐ",
            sy9: "",
            lt10: "zaugo zaugo",
            dt3: "",
            lt12: "",
            sy8: "",
            sy5: "ܙܰܘܓܳܐ ܙܰܘܓܳܐ",
            id: "3408",
            dt2: "",
            lt11: "mzaugoith",
            dt1: "paarweise"
        },
        {
            sy6: "",
            lt12: "",
            sy9: "",
            dt3: "",
            lt11: "",
            sy5: "ܬܰܪܬܶܝܢ ܙܰܒܢܺܝܢ",
            dt2: "zwei Mal",
            sy8: "",
            dt1: "zweimal",
            id: "5393",
            lt10: "tarteyn zabnin",
            sy4: "ܬܪܬܝܢ ܙܒܢܝܢ",
            sy7: ""
        },
        {
            sy9: "",
            sy4: "ܫܠܝܛܐ",
            sy7: "",
            sy8: "",
            lt12: "",
            sy6: "",
            id: "2381",
            lt10: "shalițo",
            dt2: "Monarch",
            dt3: "Herrscher",
            dt1: "Kaiser",
            lt11: "",
            sy5: "ܫܰܠܽܝܛܳܐ"
        },
        {
            sy6: "",
            sy4: "ܡܣܐܢܐ",
            lt11: "",
            sy7: "",
            lt12: "",
            dt2: "",
            lt10: "msone",
            dt3: "",
            sy8: "",
            id: "4040",
            sy5: "ܡܣܳܐܢܳܐ",
            sy9: "",
            dt1: "Schuh"
        },
        {
            dt1: "Gründung",
            lt11: "mshatsonutho",
            sy4: "ܫܘܬܣܐ",
            sy8: "",
            dt3: "",
            id: "1752",
            dt2: "Stiftung",
            lt10: "shutoso",
            sy6: "ܡܫܬܣܢܘܬ݂ܐ",
            sy7: "ܡܫܰܬܣܳܢܽܘܬ݂ܳܐ",
            lt12: "",
            sy5: "ܫܽܘܬܳܣܳܐ",
            sy9: ""
        },
        {
            sy7: "",
            sy5: "ܩܪܳܒܳܐ ܕܰܨܠܺܝ̈ܒܳܝܶܐ",
            sy4: "ܩܪܒܐ ܕܨܠܝܒܝܐ",
            lt12: "",
            sy9: "",
            dt3: "",
            dt2: "",
            sy6: "",
            sy8: "",
            dt1: "Kreuzzug",
            id: "6196",
            lt10: "qrobo da`ŝliboye",
            lt11: ""
        },
        {
            sy9: "",
            sy5: "ܐܰܬܰܐܢܰܣܺܝܽܘܣ",
            sy6: "",
            sy8: "",
            id: "326",
            dt3: "Athanasius",
            dt1: "Athanatius",
            lt11: "ܐܬܐܢܐܣܝܘܣ",
            sy4: "ܐܬܐܢܐܣܝܘܣ",
            sy7: "",
            lt10: "ܐܬܐܢܐܣܝܘܣ",
            dt2: "Atanatius",
            lt12: ""
        },
        {
            id: "495",
            sy4: "ܒܣܠܝܐ",
            lt10: "ܒܣܠܝܐ",
            lt12: "",
            sy7: "",
            lt11: "ܒܣܠܝܐ",
            dt3: "",
            sy8: "",
            sy6: "",
            dt2: "",
            sy9: "",
            dt1: "Basselya",
            sy5: "ܒܰܣܶܠܝܰܐ"
        },
        {
            dt2: "",
            sy5: "ܘܰܗܕܳܐ",
            sy6: "",
            lt12: "",
            dt3: "",
            lt10: "wahdo",
            sy4: "ܘܗܕܐ",
            sy8: "",
            sy7: "",
            sy9: "",
            id: "4439",
            dt1: "Terrain",
            lt11: ""
        },
        {
            sy4: "ܫܘܥܝܬܐ",
            id: "3651",
            sy7: "",
            lt10: "shuĉitho",
            lt11: "",
            dt2: "Sprechen",
            sy8: "",
            sy6: "",
            sy9: "",
            lt12: "",
            dt1: "Reden",
            sy5: "ܫܽܘܥܺܝܬ݂ܳܐ",
            dt3: ""
        },
        {
            id: "690",
            sy4: "ܫܚܡܐ",
            sy8: "",
            sy6: "",
            sy7: "",
            lt10: "shoĥmo",
            dt2: "",
            dt1: "braun",
            lt11: "",
            dt3: "",
            sy5: "ܫܳܚܡܳܐ",
            lt12: "",
            sy9: ""
        },
        {
            sy9: "",
            lt11: "",
            lt12: "",
            dt3: "",
            lt10: "krobo",
            id: "5742",
            sy7: "",
            sy5: "ܟܪܳܒܳܐ",
            sy6: "",
            sy4: "ܟܪܒܐ",
            dt2: "",
            dt1: "Ackern",
            sy8: ""
        },
        {
            id: "6289",
            sy4: "ܚܣܢܐ ܕܡܕܝܢܬܐ",
            sy5: "ܚܶܣܢܳܐ ܕܰܡܕܺܝܢ̱ܬܳܐ",
            lt11: "",
            sy9: "",
            sy7: "",
            lt10: "ĥesno da'mđito",
            sy6: "",
            dt3: "",
            dt2: "",
            dt1: "Zitadelle",
            lt12: "",
            sy8: ""
        },
        {
            sy9: "",
            lt11: "kibe",
            sy5: "ܟܐܺܒܳܐ",
            id: "3981",
            sy6: "",
            dt1: "Schmerz",
            sy8: "",
            dt3: "",
            sy4: "ܟܐܒܐ",
            sy7: "",
            dt2: "Schmerzen",
            lt12: "",
            lt10: "kibo"
        },
        {
            sy7: "ܥܝܳܕܳܢܳܝܳܐ",
            sy6: "ܥܝܕܢܝܐ",
            sy9: "",
            dt2: "gewohnt",
            id: "3298",
            sy8: "",
            lt10: "mĉaydo",
            dt3: "gebräuchlich",
            lt11: "ĉyodonoyo",
            sy4: "ܡܥܝܕܐ",
            dt1: "normal",
            lt12: "",
            sy5: "ܡܥܰܝܕܳܐ"
        },
        {
            dt3: "",
            sy7: "ܕܰܐܢܺܝܐܝܶܠܰܗ",
            sy8: "",
            lt12: "ܕܢܝܐܝܠܗ",
            lt11: "ܕܢܝܐܝܠܐ",
            sy9: "",
            sy6: "ܕܐܢܝܐܝܠܗ",
            id: "800",
            dt1: "Daniela",
            sy4: "ܕܢܝܐܝܠܐ",
            lt10: "ܕܢܝܐܝܠܐ",
            sy5: "ܕܰܢܺܝܐܶܝܠܰܐ",
            dt2: ""
        },
        {
            id: "2350",
            sy4: "ܥܠܝܡܬܐ",
            lt10: "ĉlaymtho",
            lt11: "",
            dt2: "",
            sy8: "",
            dt1: "Jugendliche",
            sy7: "",
            sy9: "",
            lt12: "",
            sy5: "ܥܠܰܝܡܬ݂ܳܐ",
            sy6: "",
            dt3: ""
        },
        {
            sy7: "",
            dt2: "Grundregel",
            sy8: "",
            dt3: "",
            sy6: "",
            dt1: "Grundgesetz",
            sy5: "ܩܳܢܽܘܢܳܐ ܫܶܪܫܳܢܳܝܳܐ",
            sy9: "",
            lt12: "",
            lt11: "",
            id: "1750",
            sy4: "ܩܢܘܢܐ ܫܪܫܢܝܐ",
            lt10: "qonuno shershonoyo"
        },
        {
            sy8: "",
            id: "338",
            sy4: "ܙܝܢܐ ܢܬܪܢܝܐ",
            sy5: "ܙܰܝܢ̈ܐ ܢܶܬ݂ܪ̈ܢܳܝܶܐ",
            lt10: "zayne nethronoye",
            dt1: "Atomwaffen",
            sy7: "",
            sy6: "",
            lt11: "",
            lt12: "",
            sy9: "",
            dt2: "",
            dt3: ""
        },
        {
            sy6: "ܝܘܡܐ ܡܘܠܕܐ",
            dt1: "Geburtstag",
            sy8: "",
            sy9: "",
            lt11: "yaumo dmaulodo",
            dt3: "",
            id: "1505",
            sy7: "ܝܰܘܡܳܐ ܕܡܰܘܠܳܕܳܐ",
            dt2: "",
            lt12: "",
            lt10: "yum maulodo",
            sy5: "ܝܽܘܡ ܡܰܘܠܳܕܳܐ",
            sy4: "ܝܘܡ ܡܘܠܕܐ"
        },
        {
            dt2: "",
            lt12: "ܓܐܙܐ",
            lt10: "ܓܙܐ",
            sy4: "ܓܙܐ",
            sy7: "ܓܰܐܙܳܐ",
            sy5: "ܓܰܙܳܐ",
            sy8: "",
            sy9: "",
            dt3: "",
            id: "1492",
            sy6: "ܓܐܙܐ",
            lt11: "ܓܙܐ",
            dt1: "Gazo"
        },
        {
            lt12: "",
            sy9: "",
            dt3: "",
            sy8: "",
            lt11: "",
            dt2: "Irreführung",
            sy6: "",
            sy5: "ܛܳܥܝܽܘܬ݂ܳܐ",
            lt10: "țoĉyutho",
            id: "4409",
            sy7: "",
            dt1: "Täuschung",
            sy4: "ܛܥܝܘܬܐ"
        },
        {
            id: "6377",
            sy6: "",
            dt1: "Möbelschreiner",
            sy5: "ܪܳܨܽܘܦܳܐ",
            lt11: "",
            dt3: "",
            lt10: "roŝufo",
            lt12: "",
            dt2: "",
            sy7: "",
            sy4: "ܪܨܘܦܐ",
            sy9: "",
            sy8: ""
        },
        {
            dt2: "",
            sy9: "",
            dt3: "",
            dt1: "zehnstündige Zeit",
            sy8: "",
            sy5: "ܣܶܣܳܐ",
            sy6: "",
            id: "6271",
            sy4: "ܣܣܐ",
            lt12: "",
            lt10: "seso",
            lt11: "",
            sy7: ""
        },
        {
            lt10: "ĥirutho u țubthonutho",
            sy9: "",
            sy7: "",
            dt1: "Freiheit und Glückseligkeit",
            sy5: "ܚܺܝܪܽܘܬ݂ܳܐ ܘܛܽܘܒܬ݂ܳܢܽܘܬ݂ܳܐ",
            lt12: "",
            sy8: "",
            sy6: "",
            dt3: "",
            sy4: "ܚܝܪܘܬܐ ܘܛܘܒܬܢܘܬܐ",
            dt2: "",
            lt11: "",
            id: "1382"
        },
        {
            sy7: "",
            sy4: "ܐܪܥܐ",
            sy8: "",
            sy9: "",
            sy6: "",
            id: "671",
            dt1: "Boden",
            lt12: "",
            lt10: "arĉo",
            dt2: "Erdboden",
            dt3: "",
            sy5: "ܐܰܪܥܳܐ",
            lt11: ""
        },
        {
            sy8: "",
            dt1: "Titus",
            sy9: "",
            dt3: "",
            sy5: "ܛܺܝܛܽܘܣ",
            sy6: "ܛܝܛܘܣ",
            sy4: "ܛܝܛܘܣ",
            lt12: "ܛܝܛܘܣ",
            id: "4501",
            sy7: "ܛܺܝܛܳܘܣ",
            dt2: "",
            lt10: "ܛܝܛܘܣ",
            lt11: "ܛܝܛܘܣ"
        },
        {
            sy9: "",
            id: "4983",
            sy5: "ܘܰܪܕܶܐ",
            dt3: "",
            sy8: "",
            sy7: "",
            lt12: "",
            sy6: "",
            dt2: "",
            lt10: "ܘܪܕܐ",
            lt11: "ܘܪܕܐ",
            sy4: "ܘܪܕܐ",
            dt1: "Warde"
        },
        {
            sy9: "",
            dt3: "",
            lt10: "dukath ĥyoțo",
            sy7: "ܕܰܪܙܺܝܩܽܘܬ݂ܳܐ",
            sy4: "ܕܘܟܬ ܚܝܛܐ",
            dt1: "Schneiderei",
            dt2: "Änderungsschneiderei",
            sy6: "ܕܪܙܝܩܘܬܐ",
            id: "3999",
            lt12: "",
            sy5: "ܕܽܘܟܰܬ݂ ܚܰܝܳܛܳܐ",
            lt11: "darziqutho",
            sy8: ""
        },
        {
            dt3: "",
            lt10: "beth yolufe",
            sy8: "",
            lt11: "",
            lt12: "",
            sy6: "",
            id: "6244",
            dt2: "",
            sy4: "ܒܝܬ ܝܠܘܦܐ",
            sy9: "",
            sy5: "ܒܶܝܬ݂ ܝܳܠܽܘ̈ܦܶܐ",
            dt1: "Studentenheim",
            sy7: ""
        },
        {
            dt1: "Henonjeschu",
            sy9: "",
            sy8: "",
            lt12: "",
            sy5: "ܚܢܳܢܝܶܫܽܘܥ",
            sy6: "",
            lt11: "ܚܢܢܝܫܘܥ",
            sy7: "",
            dt2: "",
            dt3: "",
            id: "1963",
            sy4: "ܚܢܢܝܫܘܥ",
            lt10: "ܚܢܢܝܫܘܥ"
        },
        {
            lt12: "",
            dt3: "",
            id: "5299",
            sy5: "ܒܶܝܬ݂ ܦܽܘܠܚܳܢ ܫܶܡܶܢܬܳܐ",
            sy9: "",
            sy6: "",
            dt2: "",
            lt10: "beth fulĥon shemento",
            dt1: "Zementfabrik",
            sy8: "",
            sy4: "ܒܝܬ ܦܘܠܚܢ ܫܡܢܬܐ",
            lt11: "",
            sy7: ""
        },
        {
            sy5: "ܟܽܘܠܗܽܘܢ",
            id: "5835",
            lt11: "kul",
            dt3: "jedermann",
            dt2: "jeder",
            lt12: "kul nosh",
            sy6: "ܟܘܠ",
            sy9: "ܟܽܠ ܐ̱ܢܳܫ",
            sy7: "ܟܽܘܠ",
            lt10: "kulhun",
            sy4: "ܟܘܠܗܘܢ",
            sy8: "ܟܠ ܐܢܫ",
            dt1: "alle"
        },
        {
            id: "2297",
            sy5: "ܝܽܘܚܳܝܶܠ",
            lt11: "ܝܘܚܝܠ",
            sy8: "",
            lt10: "ܝܘܚܝܠ",
            sy4: "ܝܘܚܝܠ",
            sy7: "",
            dt1: "Jehiel",
            sy6: "",
            lt12: "",
            dt3: "",
            dt2: "Juhoel",
            sy9: ""
        },
        {
            sy5: "ܣܛܳܐ",
            lt12: "",
            sy7: "ܦܬܰܠ",
            id: "5548",
            lt11: "ftal",
            dt2: "",
            sy6: "ܦܬܠ",
            sy9: "",
            lt10: "sțo",
            dt3: "",
            sy8: "",
            dt1: "abbiegen",
            sy4: "ܣܛܐ"
        },
        {
            sy8: "",
            sy6: "",
            lt11: "ܫܡܫ",
            sy7: "",
            dt3: "",
            sy9: "",
            dt2: "",
            sy4: "ܫܡܫ",
            id: "3892",
            lt10: "ܫܡܫ",
            sy5: "ܫܰܡܰܫ",
            dt1: "Schamasch",
            lt12: ""
        },
        {
            dt2: "",
            lt12: "",
            id: "6305",
            sy5: "ܛܽܘܝܳܒܳܐ",
            sy8: "",
            dt3: "",
            sy7: "ܡܰܕܝܶܟ݂",
            sy6: "ܡܕܝܟ",
            dt1: "zubereiten",
            lt10: "țuyobo",
            sy9: "",
            lt11: "madyekh",
            sy4: "ܛܘܝܒܐ"
        },
        {
            sy7: "",
            lt12: "",
            sy6: "",
            dt1: "Dionysos",
            dt2: "Dionosyius",
            sy9: "",
            sy5: "ܕܺܝܳܘܢܢܽܘܣܺܝܽܘܣ",
            id: "898",
            sy8: "",
            sy4: "ܕܝܘܢܢܘܣܝܘܣ",
            lt10: "ܕܝܘܢܢܘܣܝܘܣ",
            lt11: "ܕܝܘܢܢܘܣܝܘܣ",
            dt3: "Dionysius"
        },
        {
            dt2: "",
            sy4: "ܦܠܚܐ ܨܠܝܒܝܐ",
            lt12: "",
            sy8: "",
            lt10: "folĥo ŝliboyo",
            sy9: "",
            sy7: "",
            dt3: "",
            sy5: "ܦܳܠܚܳܐ ܨܠܺܝܒܳܝܳܐ",
            lt11: "",
            sy6: "",
            id: "6197",
            dt1: "Kreuzzügler"
        },
        {
            sy4: "ܚܪܛܘܡܐ ܕܦܝܠܐ",
            lt10: "ĥarțumo d`filo",
            sy9: "",
            sy8: "",
            sy5: "ܚܰܪܛܽܘܡܳܐ ܕܦܺܝܠܳܐ",
            sy6: "",
            lt12: "",
            sy7: "",
            dt3: "",
            dt1: "Elefantenrüssel",
            dt2: "",
            id: "1068",
            lt11: ""
        },
        {
            sy9: "",
            sy8: "",
            id: "809",
            dt3: "",
            sy6: "ܕܪܝܐܝܘܣ",
            lt11: "ܕܪܝܝܘܣ",
            sy5: "ܕܰܪܺܝܝܳܘܣ",
            sy7: "ܕܰܪܺܝܐܝܳܘܣ",
            lt12: "ܕܪܝܐܝܘܣ",
            dt2: "Darijus",
            lt10: "ܕܪܝܝܘܣ",
            sy4: "ܕܪܝܝܘܣ",
            dt1: "Darijos"
        },
        {
            sy7: "",
            sy5: "ܒܳܬ݂ܰܪ",
            lt12: "",
            id: "797",
            dt2: "",
            dt1: "danach",
            sy8: "",
            lt11: "",
            dt3: "",
            sy4: "ܒܬܪ",
            sy6: "",
            sy9: "",
            lt10: "bothar"
        },
        {
            sy6: "",
            lt11: "",
            lt12: "",
            dt2: "Angabe",
            id: "5919",
            dt3: "Information",
            sy8: "",
            dt1: "Info",
            lt10: "maudĉonutho",
            sy5: "ܡܰܘܕܥܳܢܽܘܬ݂ܳܐ",
            sy4: "ܡܘܕܥܢܘܬܐ",
            sy7: "",
            sy9: ""
        },
        {
            sy6: "",
            sy9: "",
            lt10: "athro",
            sy4: "ܐܰܬ݂ܪܳܐ",
            lt11: "",
            sy5: "ܐܰܬ݂ܪܳܐ",
            dt1: "Land",
            id: "2700",
            dt2: "",
            lt12: "",
            sy7: "",
            dt3: "",
            sy8: ""
        },
        {
            sy4: "ܡܠܦܢ ܣܕܪܐ",
            sy7: "",
            dt3: "",
            lt11: "",
            dt1: "Klassenlehrer",
            sy5: "ܡܰܠܦܳܢ ܣܶܕܪܳܐ",
            lt10: "malfon sedro",
            lt12: "",
            dt2: "",
            sy6: "",
            sy9: "",
            sy8: "",
            id: "2499"
        },
        {
            sy8: "",
            sy4: "ܕܩܪܩܐ",
            sy6: "",
            id: "2476",
            lt10: "daqroqo",
            dt2: "",
            sy7: "",
            sy9: "",
            dt3: "",
            dt1: "Kinderbett",
            lt12: "",
            lt11: "",
            sy5: "ܕܰܩܪܳܩܳܐ"
        },
        {
            dt2: "",
            sy6: "",
            sy8: "",
            sy9: "",
            sy4: "ܒܝܬ ܚܘܦܐ",
            lt12: "",
            lt10: "beth ĥaufe",
            lt11: "",
            id: "418",
            dt3: "",
            dt1: "Badezimmer",
            sy7: "",
            sy5: "ܒܶܝܬ݂ ܚܰܘ̈ܦܳܐ"
        },
        {
            dt3: "",
            lt10: "mağdlo d`bobel",
            dt2: "",
            sy4: "ܡܓܕܠܐ ܕܒܒܝܠ",
            sy5: "ܡܰܓ݂ܕܠܳܐ ܕܒܳܒܶܝܠ",
            lt12: "",
            lt11: "burgo d`bobel",
            dt1: "Turm von Babel",
            sy9: "",
            sy6: "ܒܘܪܓܐ ܕܒܒܝܠ",
            id: "4603",
            sy7: "ܒܽܘܪܓܳܐ ܕܒܳܒܶܝܠ",
            sy8: ""
        },
        {
            sy8: "",
            lt11: "",
            dt1: "rechtprechen",
            id: "3637",
            sy4: "ܥܒܕ ܕܝܢܐ",
            dt3: "",
            sy5: "ܥܒܰܕ ܕܺܝܢܳܐ",
            dt2: "",
            lt10: "ĉbađ dino",
            sy9: "",
            lt12: "",
            sy7: "",
            sy6: ""
        },
        {
            sy6: "",
            dt2: "",
            sy5: "ܒܰܗܪܳܐ ܕܥܒܳܪܳܐ",
            sy8: "",
            id: "4751",
            sy9: "",
            lt12: "",
            lt11: "",
            dt3: "",
            sy4: "ܒܗܪܐ ܕܥܒܪܐ",
            sy7: "",
            lt10: "bahro d`ĉboro",
            dt1: "Verkehrsampel"
        },
        {
            lt10: "ܡܪܝ ܐܦܪܝܡ",
            id: "3088",
            dt1: "Mor Afrem",
            dt2: "St. Efrim",
            sy6: "",
            sy4: "ܡܪܝ ܐܦܪܝܡ",
            dt3: "",
            sy5: "ܡܳܪ ܐܰܦܪܶܝܡ",
            sy9: "",
            sy8: "",
            sy7: "",
            lt12: "",
            lt11: "ܡܪܝ ܐܦܪܝܡ"
        },
        {
            sy6: "",
            sy9: "",
            sy8: "",
            lt12: "",
            dt2: "",
            dt1: "Mittelwelle",
            lt11: "",
            lt10: "galo meŝĉoyo",
            sy5: "ܓܰܠ̱ܠܳܐ ܡܶܨܥܳܝܳܐ",
            sy4: "ܓܠܠܐ ܡܨܥܝܐ",
            id: "6369",
            dt3: "",
            sy7: ""
        },
        {
            sy5: "ܡܰܠܟܽܘܢܳܐ",
            sy6: "",
            dt2: "",
            dt1: "Königlein",
            lt12: "",
            sy8: "",
            lt11: "",
            dt3: "",
            lt10: "malkuno",
            sy4: "ܡܰܠܟܽܘܢܳܐ",
            id: "5434",
            sy9: "",
            sy7: ""
        },
        {
            dt3: "",
            dt1: "Kundin",
            lt10: "sobunto",
            id: "2670",
            lt12: "",
            sy8: "",
            sy6: "",
            sy7: "",
            dt2: "",
            sy4: "ܙܒܘܢܬܐ",
            lt11: "",
            sy5: "ܙܳܒܽܘܢܬܳܐ",
            sy9: ""
        },
        {
            id: "5108",
            dt2: "",
            lt12: "",
            sy4: "ܚܪܡܢܐ",
            sy7: "ܚܰܪܡܳܐ",
            sy8: "",
            lt10: "ĥarmono",
            sy5: "ܚܰܪܡܳܢܳܐ",
            lt11: "ĥarmo",
            sy9: "",
            sy6: "ܚܪܡܐ",
            dt3: "",
            dt1: "Wilder"
        },
        {
            dt2: "",
            sy6: "",
            sy5: "ܐܰܣܽܘܢܰܐ",
            sy9: "",
            lt12: "",
            dt1: "Asuna",
            dt3: "",
            id: "319",
            sy7: "",
            lt11: "ܐܣܘܢܐ",
            sy4: "ܐܣܘܢܐ",
            sy8: "",
            lt10: "ܐܣܘܢܐ"
        },
        {
            sy8: "",
            lt11: "",
            lt12: "",
            dt2: "Beule",
            dt3: "",
            sy4: "ܢܦܝܚܘܬܐ",
            sy5: "ܢܦܺܝܚܽܘܬ݂ܳܐ",
            id: "4075",
            sy7: "",
            sy9: "",
            lt10: "nfiĥutho",
            sy6: "",
            dt1: "Schwellung"
        },
        {
            dt3: "",
            sy4: "ܘܪܩܠܐ",
            lt12: "",
            lt11: "",
            id: "6443",
            dt1: "Stimmzettel",
            sy6: "",
            sy7: "",
            sy9: "",
            sy8: "",
            dt2: "",
            sy5: "ܘܰܪܩܳܠܳܐ",
            lt10: "warqolo"
        },
        {
            sy8: "",
            sy5: "ܡܰܕܪܰܫܬܳܐ ܦܳܠܚܳܝܬܳܐ",
            dt3: "",
            lt12: "",
            lt10: "madrashto folĥoyto",
            sy6: "",
            sy9: "",
            dt1: "Kriegsschule",
            sy4: "ܡܕܪܫܬܐ ܦܠܚܝܬܐ",
            dt2: "",
            id: "6194",
            sy7: "",
            lt11: ""
        },
        {
            sy9: "",
            sy4: "ܢܛܪ ܢܘܦܐ",
            dt2: "Torwart",
            sy8: "",
            lt12: "",
            dt1: "Torhüter",
            lt10: "noțar naufo",
            id: "4526",
            sy6: "",
            sy5: "ܢܳܛܰܪ ܢܰܘܦܳܐ",
            sy7: "",
            dt3: "",
            lt11: ""
        },
        {
            sy8: "",
            lt11: "",
            sy5: "ܫܽܘܬܳܐܣܳܐ",
            sy6: "",
            sy7: "",
            sy9: "",
            dt3: "",
            id: "2216",
            dt1: "Installation",
            lt10: "shutoso",
            dt2: "",
            sy4: "ܫܘܬܐܣܐ",
            lt12: ""
        },
        {
            lt12: "",
            sy6: "",
            dt1: "Projekte",
            id: "3521",
            sy4: "ܬܪܡܝܬܐ",
            sy8: "",
            dt3: "",
            lt10: "tarmyotho",
            sy5: "ܬܰܪܡܝ̈ܳܬ݂ܳܐ",
            sy9: "",
            sy7: "",
            dt2: "",
            lt11: ""
        },
        {
            dt3: "",
            dt2: "",
            lt12: "",
            sy8: "",
            id: "673",
            sy7: "",
            sy6: "",
            sy5: "ܢܩܰܒ",
            lt11: "",
            sy9: "",
            sy4: "ܢܩܒ",
            lt10: "nqab",
            dt1: "bohren"
        },
        {
            sy6: "",
            sy8: "",
            sy5: "ܟܰܠܒܳܐ ܡܥܰܩܒܳܢܳܐ",
            dt1: "Suchhund",
            sy7: "",
            id: "4307",
            lt10: "kalbo mĉaqbono",
            lt11: "",
            dt2: "",
            lt12: "",
            sy4: "ܟܠܒܐ ܡܥܩܒܢܐ",
            dt3: "",
            sy9: ""
        },
        {
            dt3: "",
            dt2: "",
            sy8: "",
            dt1: "Kondolenzbesuch",
            id: "6078",
            lt12: "",
            sy4: "ܒܘܣܡ ܪܝܫܐ",
            lt11: "",
            sy7: "",
            sy5: "ܒܽܘܣܳܡ ܪܺܝܫܳܐ",
            sy6: "",
            sy9: "",
            lt10: "busom risho"
        },
        {
            sy6: "",
            lt12: "",
            sy9: "",
            dt1: "Minderwertigkeitskomplex",
            id: "3040",
            sy7: "",
            dt2: "",
            dt3: "",
            sy5: "ܪܶܓ݂ܫܳܐ ܕܬܰܚܬܳܝܽܘܬ݂ܳܐ",
            sy8: "",
            lt10: "reğsho d`taĥtoyutho",
            lt11: "",
            sy4: "ܪܓܫܐ ܕܬܚܬܝܘܬܐ"
        },
        {
            sy8: "",
            sy4: "ܒܪܚܝܠܐ",
            sy5: "ܒܰܪܚܰܝܠܳܐ",
            dt3: "Barhajlo",
            sy6: "",
            id: "450",
            lt11: "ܒܪܚܝܠܐ",
            lt10: "ܒܪܚܝܠܐ",
            lt12: "",
            dt1: "Barhailo",
            sy7: "",
            dt2: "Barhaylo",
            sy9: ""
        },
        {
            sy9: "",
            dt2: "",
            dt3: "",
            dt1: "Kommando",
            sy6: "",
            lt10: "fqođo",
            lt12: "",
            sy8: "",
            sy4: "ܦܩܕܐ",
            id: "2567",
            sy7: "",
            sy5: "ܦܩܳܕܳܐ",
            lt11: ""
        },
        {
            sy4: "ܗܕܪܘܠܐ",
            sy7: "",
            sy8: "",
            lt10: "ܗܕܪܘܠܐ",
            id: "1919",
            sy9: "",
            sy5: "ܗܶܕܪܽܘܠܳܐ",
            dt2: "",
            dt1: "Hedrulo",
            dt3: "",
            lt11: "ܗܕܪܘܠܐ",
            sy6: "",
            lt12: ""
        },
        {
            dt3: "",
            lt12: "",
            sy8: "",
            lt10: "ܒܪܬܢܗܪܐ",
            sy5: "ܒܰܪ̱ܬܢܰܗܪܳܐ",
            sy7: "",
            sy6: "",
            sy4: "ܒܪ̱ܬܢܗܪܐ",
            lt11: "ܒܪܬܢܗܪܐ",
            sy9: "",
            dt1: "Bathnahro",
            dt2: "",
            id: "503"
        },
        {
            sy5: "ܚܰܪܺܝܦܽܘܬ݂ܳܐ",
            sy4: "ܚܪܝܦܘܬܐ",
            sy9: "",
            sy7: "",
            sy6: "",
            lt12: "",
            dt1: "Schärfe",
            dt3: "",
            dt2: "",
            id: "3904",
            sy8: "",
            lt10: "ĥarifutho",
            lt11: ""
        },
        {
            lt11: "",
            sy7: "",
            sy6: "",
            lt10: "ĥađ aloho shariro",
            sy8: "",
            sy4: "ܚܕ ܐܠܗܐ ܫܪܝܪܐ",
            lt12: "",
            sy9: "",
            sy5: "ܚܰܕ ܐܰܠܳܗܳܐ ܫܰܪܺܝܪܳܐ",
            dt3: "",
            dt1: "ein wahrer Gott",
            id: "1035",
            dt2: ""
        },
        {
            lt10: "ܫܘܡܬܐ",
            sy9: "",
            sy6: "",
            lt11: "ܫܘܡܬܐ",
            sy4: "ܫܘܡܬܐ",
            sy5: "ܫܽܘܡܬܰܐ",
            id: "4055",
            sy7: "",
            dt1: "Schumta",
            lt12: "",
            dt2: "",
            dt3: "",
            sy8: ""
        },
        {
            sy5: "ܚܫܰܚܬܳܐ",
            dt1: "Gebrauch",
            lt10: "ĥshaĥto",
            lt12: "methĥashĥonutho",
            dt2: "",
            lt11: "ĥushoĥo",
            sy9: "ܡܶܬ݂ܚܰܫܚܳܢܽܘܬ݂ܳܐ",
            sy7: "ܚܽܘܫܳܚܳܐ",
            dt3: "",
            sy6: "ܚܘܫܚܐ",
            sy8: "ܡܬܚܫܚܢܘܬܐ",
            id: "1500",
            sy4: "ܚܫܚܬܐ"
        },
        {
            id: "382",
            sy8: "",
            lt10: "gayes",
            sy4: "ܓܝܣ",
            lt12: "",
            dt2: "ausplündern",
            dt3: "berauben",
            sy7: "",
            sy5: "ܓܰܝܶܣ",
            lt11: "",
            dt1: "ausrauben",
            sy6: "",
            sy9: ""
        },
        {
            lt12: "",
            sy5: "ܩܽܘܪܳܒܳܐ",
            id: "2846",
            dt1: "Liturgie",
            dt3: "",
            lt11: "",
            sy7: "",
            lt10: "qurobo",
            sy9: "",
            sy6: "",
            sy4: "ܩܘܪܒܐ",
            sy8: "",
            dt2: ""
        },
        {
            dt1: "abwerten",
            sy4: "ܥܕܠ",
            lt10: "ĉadel",
            dt3: "",
            sy9: "",
            sy6: "ܒܨܪ ܛܝܡܐ",
            sy8: "",
            sy5: "ܥܰܕܶܠ",
            dt2: "",
            lt12: "",
            id: "5709",
            lt11: "baŝar țime",
            sy7: "ܒܰܨܰܪ ܛܺܝܡܶܐ"
        },
        {
            sy4: "ܡܬܩܦܕܢܐ",
            sy5: "ܡܶܬ݂ܩܰܦܕܳܢܳܐ",
            id: "1755",
            dt1: "gruselig",
            sy9: "",
            dt2: "",
            sy6: "ܡܐܙܝܥܢܝܐ",
            lt10: "methqafdono",
            sy7: "ܡܰܐܙܺܝܥܳܢܳܝܳܐ",
            lt11: "maziĉonoyo",
            sy8: "",
            lt12: "",
            dt3: ""
        },
        {
            sy9: "",
            sy5: "ܛܰܝܒܽܘܬܰܐ",
            sy8: "",
            id: "4369",
            sy4: "ܛܝܒܘܬܐ",
            dt3: "",
            lt11: "ܛܝܡܘܬܐ",
            sy7: "",
            dt2: "",
            lt10: "ܛܝܡܘܬܐ",
            dt1: "Taibuta",
            lt12: "",
            sy6: ""
        },
        {
            lt12: "",
            sy7: "",
            dt1: "Erntearbeiter",
            dt2: "",
            sy6: "",
            sy5: "ܚܰܨܽܘܕܳܐ",
            sy9: "",
            id: "1165",
            lt11: "",
            dt3: "",
            lt10: "ĥaŝudo",
            sy8: "",
            sy4: "ܚܨܘܕܐ"
        },
        {
            sy8: "",
            sy7: "",
            lt10: "ܒܢܝܒܐܠ",
            lt12: "",
            dt3: "",
            lt11: "ܒܢܝܒܐܠ",
            dt1: "Banibal",
            sy9: "",
            sy6: "",
            dt2: "",
            sy5: "ܒܰܢܺܝܒܰܐܠ",
            id: "434",
            sy4: "ܒܢܝܒܐܠ"
        },
        {
            sy5: "ܥܕܰܡܳܐ ܠܗܳܪܟܳܐ",
            sy7: "",
            id: "629",
            lt10: "ĉđamo l`horko",
            sy9: "",
            sy4: "ܥܕܡܐ ܠܗܪܟܐ",
            sy8: "",
            sy6: "",
            dt2: "bis hier",
            dt1: "bis hierher",
            lt11: "",
            lt12: "",
            dt3: ""
        },
        {
            sy9: "",
            lt11: "",
            dt2: "",
            lt12: "",
            sy5: "ܦܶܠܓܳܐ",
            sy4: "ܦܠܓܐ",
            dt3: "",
            sy7: "",
            id: "1828",
            sy6: "",
            sy8: "",
            lt10: "felgo",
            dt1: "halb"
        },
        {
            lt10: "ܫܟ݂ܢܝܐ",
            sy9: "",
            dt3: "",
            lt12: "ܫܟ݂ܢܝܐ",
            sy6: "ܫܟܢܝܐ",
            lt11: "ܫܟ݂ܢܝܐ",
            sy5: "ܫܶܟ݂ܰܢܝܰܐ",
            sy4: "ܫܟ݂ܢܝܐ",
            id: "3916",
            sy7: "ܫܟ݂ܰܢܝܳܐ",
            dt2: "",
            dt1: "Schechanja",
            sy8: ""
        },
        {
            sy8: "",
            id: "838",
            sy5: "ܕܰܝܨܳܐ",
            sy6: "",
            lt11: "ܕܝܨܐ",
            lt12: "",
            sy9: "",
            sy7: "",
            dt3: "",
            sy4: "ܕܝܨܐ",
            dt1: "Dayso",
            lt10: "ܕܝܨܐ",
            dt2: ""
        },
        {
            dt1: "edel",
            lt10: "frish genso",
            sy7: "",
            lt11: "",
            dt3: "",
            sy6: "",
            sy5: "ܦܪܺܝܫ ܓܶܢܣܳܐ",
            sy4: "ܦܪܺܝܫ ܓܶܢܣܳܐ",
            sy9: "",
            id: "5432",
            sy8: "",
            dt2: "",
            lt12: ""
        },
        {
            lt12: "",
            sy8: "",
            sy4: "ܦܘܚܡܐ",
            sy5: "ܦܽܘܚܳܡܳܐ",
            sy6: "",
            sy7: "",
            sy9: "",
            lt10: "fuĥomo",
            dt2: "",
            lt11: "",
            id: "3299",
            dt3: "",
            dt1: "Note"
        },
        {
            sy5: "ܫܰܪܒܰܬ",
            sy4: "ܫܪܒܬ",
            id: "3899",
            lt10: "ܫܪܒܬ",
            lt12: "",
            dt1: "Scharbat",
            sy6: "",
            dt3: "",
            lt11: "ܫܪܒܬ",
            dt2: "",
            sy7: "",
            sy9: "",
            sy8: ""
        },
        {
            lt10: "dayro",
            sy5: "ܕܰܝܪܳܐ",
            dt1: "Kloster",
            sy7: "",
            lt12: "",
            dt2: "",
            sy6: "",
            sy8: "",
            dt3: "",
            id: "2533",
            sy4: "ܕܝܪܐ",
            sy9: "",
            lt11: ""
        },
        {
            sy8: "",
            id: "1937",
            sy9: "",
            lt12: "",
            sy7: "",
            sy4: "ܦܘܢܝܐ",
            lt11: "",
            dt1: "Heimkehr",
            dt3: "",
            sy6: "",
            sy5: "ܦܽܘܢܳܝܳܐ",
            lt10: "funoyo",
            dt2: ""
        },
        {
            dt1: "Barat",
            dt3: "",
            sy7: "",
            sy6: "",
            sy8: "",
            sy5: "ܒܰܪܐܰܬ",
            sy4: "ܒܪܐܬ",
            lt12: "",
            dt2: "",
            lt10: "ܒܪܐܬ",
            lt11: "ܒܪܐܬ",
            sy9: "",
            id: "443"
        },
        {
            sy6: "",
            lt12: "",
            lt10: "ܓܘܪܓܝ",
            sy9: "",
            dt3: "",
            dt1: "Gewargi",
            id: "1617",
            sy5: "ܓܶܘܪܓܺܝ",
            lt11: "ܓܘܪܓܝ",
            sy4: "ܓܘܪܓܝ",
            sy7: "",
            dt2: "",
            sy8: ""
        },
        {
            dt3: "",
            sy5: "ܐܰܡܬܰܐ",
            id: "220",
            sy9: "",
            sy6: "",
            dt1: "Amta",
            lt11: "ܐܡܬܐ",
            lt10: "ܐܡܬܐ",
            sy7: "",
            sy4: "ܐܡܬܐ",
            sy8: "",
            lt12: "",
            dt2: ""
        },
        {
            lt11: "",
            id: "6258",
            sy7: "",
            sy9: "",
            lt12: "",
            sy4: "ܫܘܘܕܥ ܙܒܢܐ",
            dt2: "",
            sy6: "",
            sy8: "",
            sy5: "ܫܽܘܘܕܳܥ ܙܰܒܢܳܐ",
            lt10: "shudoĉ zabno",
            dt3: "",
            dt1: "Zeitzeichen"
        },
        {
            sy7: "",
            lt10: "abun d`bashmayo",
            sy6: "",
            dt1: "Himmlischer Vater",
            id: "2005",
            sy4: "ܐܒܘܢ ܕܒܫܡܝܐ",
            lt11: "abun dbashmayo",
            dt3: "",
            sy5: "ܐܰܒܽܘܢ ܕܒܰܫܡܰܝܳܐ",
            sy8: "",
            dt2: "Vater im Himmel",
            lt12: "",
            sy9: ""
        },
        {
            lt10: "ܝܗܘܫܥ",
            sy4: "ܝܗܘܫܥ",
            id: "2333",
            dt1: "Johuschuah",
            dt3: "",
            sy9: "",
            dt2: "",
            sy7: "",
            sy8: "",
            lt12: "",
            lt11: "ܝܗܘܫܥ",
            sy5: "ܝܳܗܽܘܫܰܥ",
            sy6: ""
        },
        {
            sy7: "",
            sy9: "",
            dt3: "",
            id: "414",
            sy5: "ܥܰܪ̈ܫܶܐ ܛܰܚܳܢ̈ܬ݂ܳܐ",
            dt2: "",
            lt12: "",
            lt10: "ĉarshe țaĥonotho",
            lt11: "",
            sy8: "",
            sy4: "ܥܪܫܐ ܛܚܢܬܐ",
            dt1: "Backenzähne",
            sy6: ""
        },
        {
            dt2: "",
            sy5: "ܦܰܪܢܳܣܳܐ",
            sy9: "",
            sy4: "ܦܪܢܣܐ",
            lt11: "mfarnsono",
            id: "5763",
            lt12: "",
            dt3: "",
            lt10: "farnoso",
            dt1: "Administrator",
            sy6: "ܡܦܪܢܣܢܐ",
            sy8: "",
            sy7: "ܡܦܰܪܢܣܳܢܳܐ"
        },
        {
            sy9: "",
            dt3: "",
            lt11: "",
            dt2: "",
            sy6: "",
            sy5: "ܦܳܐܝܫ ܐ̱ܢܳܐ",
            sy7: "",
            lt12: "",
            sy4: "ܦܐܝܫ ܐܢܐ",
            lt10: "foyash no",
            dt1: "ich bleibe",
            id: "2105",
            sy8: ""
        },
        {
            id: "2975",
            sy7: "",
            lt10: "banoyo",
            sy8: "",
            sy4: "ܒܢܝܐ",
            lt11: "",
            dt2: "Architekt",
            sy6: "",
            lt12: "",
            sy5: "ܒܰܢܳܝܳܐ",
            sy9: "",
            dt3: "",
            dt1: "Maurer"
        },
        {
            sy6: "",
            dt1: "Jeschudod",
            lt11: "ܝܫܘܥܕܢ",
            dt3: "",
            sy8: "",
            sy7: "",
            id: "2311",
            sy5: "ܝܶܫܽܘܥܕܳܢ",
            lt12: "",
            dt2: "",
            sy9: "",
            sy4: "ܝܫܘܥܕܢ",
            lt10: "ܝܫܘܥܕܢ"
        },
        {
            sy4: "ܚܠܝܡܘܬܐ",
            lt11: "",
            lt12: "",
            dt3: "",
            sy9: "",
            sy7: "",
            dt1: "Stabilität",
            sy5: "ܚܠܺܝܡܽܘܬ݂ܳܐ",
            lt10: "ĥlimutho",
            sy6: "",
            id: "4234",
            dt2: "",
            sy8: ""
        },
        {
            id: "2587",
            sy5: "ܐܺܝܙܓܰܕܳܐ",
            lt12: "",
            sy9: "",
            sy6: "ܩܢܨܠ",
            dt1: "Konsul",
            sy4: "ܐܝܙܓܕܐ",
            lt11: "qonŝol",
            dt2: "",
            sy7: "ܩܰܢܨܳܠ",
            sy8: "",
            lt10: "isgado",
            dt3: ""
        },
        {
            sy9: "",
            lt10: "nuĥ",
            lt11: "",
            dt2: "",
            sy6: "",
            sy8: "",
            id: "3288",
            lt12: "",
            dt1: "Noah",
            sy4: "ܢܘܚ",
            dt3: "",
            sy5: "ܢܽܘܚ",
            sy7: ""
        },
        {
            sy9: "",
            dt2: "",
            sy5: "ܦܶܛܪܰܐ",
            lt10: "ܦܛܪܐ",
            sy4: "ܦܛܪܐ",
            id: "3442",
            dt3: "",
            dt1: "Petra",
            lt12: "",
            sy8: "",
            sy6: "",
            sy7: "",
            lt11: "ܦܛܪܐ"
        },
        {
            sy9: "",
            lt10: "ĥabibo d`lebi",
            sy4: "ܚܒܝܒܐ ܕܠܒܝ",
            lt12: "",
            sy7: "",
            dt2: "Geliebter Schatz",
            dt3: "",
            sy5: "ܚܰܒܺܝܒܳܐ ܕܠܶܒܺܝ",
            id: "2812",
            sy8: "",
            sy6: "",
            lt11: "",
            dt1: "Lieber Schatz"
        },
        {
            dt2: "erlangen",
            dt3: "gelangen",
            sy8: "",
            sy9: "",
            sy4: "ܡܛܐ",
            sy5: "ܡܛܳܐ",
            sy6: "ܡܛܐ",
            id: "1167",
            sy7: "ܡܳܛܶܐ",
            dt1: "erreichen",
            lt10: "mțo",
            lt11: "moțe",
            lt12: ""
        },
        {
            dt2: "",
            sy6: "ܚܠܐ",
            sy5: "ܢܦܰܨ",
            sy8: "ܥܦܪ",
            lt12: "ĉafar",
            lt11: "ĥelo",
            lt10: "nfaŝ",
            dt1: "abstauben",
            sy4: "ܢܦܨ",
            id: "5662",
            sy9: "ܥܰܦܰܪ",
            sy7: "ܚܶܠܳܐ",
            dt3: ""
        },
        {
            sy5: "ܙܰܒܢܳܐ",
            dt3: "",
            lt10: "zabno",
            lt12: "",
            dt2: "",
            sy4: "ܙܒܢܐ",
            lt11: "ĉedono",
            sy6: "ܥܕܢܐ",
            sy7: "ܥܶܕܳܢܳܐ",
            id: "5287",
            sy8: "",
            dt1: "Zeit",
            sy9: ""
        },
        {
            lt10: "musiqi",
            sy4: "ܡܘܣܝܩܝ",
            sy6: "ܙܡܪܐ",
            sy7: "ܙܡܳܪܳܐ",
            sy8: "ܙܡܪܘܬܐ",
            dt3: "",
            lt12: "zamorutho",
            dt2: "",
            lt11: "zmoro",
            sy5: "ܡܽܘܣܺܝܩܺܝ",
            dt1: "Musik",
            sy9: "ܙܰܡܳܪܽܘܬ݂ܳܐ",
            id: "3129"
        },
        {
            dt3: "",
            lt10: "ĥroyath",
            sy8: "ܣܟܢܐܝܬ",
            sy6: "ܣܟܝܐ",
            sy9: "ܣܳܟ݂ܳܢܳܐܝܺܬ݂",
            dt1: "letzlich",
            dt2: "",
            sy7: "ܣܳܟ݂ܳܝܳܐ",
            id: "2776",
            lt12: "sokhonoith",
            sy5: "ܐ̱ܚܪܳܝܰܬ݂",
            lt11: "sokhoyo",
            sy4: "ܐܚܪܝܬ"
        },
        {
            lt10: "gayorutho",
            sy9: "ܦܪܺܝܫܽܘܬ݂ܳܐ",
            dt3: "",
            id: "1015",
            dt2: "Ehebrechung",
            sy6: "ܙܢܝܘܬܐ",
            sy7: "ܙܳܢܝܽܘܬ݂ܳܐ",
            sy8: "ܦܪܝܫܘܬܐ",
            sy4: "ܓܝܪܘܬܐ",
            lt11: "zonyutho",
            lt12: "frishutho",
            dt1: "Ehescheidung",
            sy5: "ܓܰܝܳܪܽܘܬ݂ܳܐ"
        },
        {
            lt11: "ܡܬܐ",
            id: "2964",
            sy8: "",
            sy6: "ܡܬܐ",
            lt12: "ܡܬܐ",
            sy9: "",
            lt10: "ܡܬܐ",
            dt1: "Mata",
            sy4: "ܡܬܐ",
            sy5: "ܡܰܬܰܐ",
            dt2: "",
            dt3: "",
            sy7: "ܡܳܬܳܐ"
        },
        {
            sy7: "",
            sy5: "ܒܶܪܽܘܠܰܐ",
            sy4: "ܒܪܘܠܐ",
            id: "561",
            dt1: "Berula",
            lt12: "",
            lt10: "ܒܪܘܠܐ",
            sy8: "",
            lt11: "ܒܪܘܠܐ",
            sy9: "",
            dt2: "",
            dt3: "",
            sy6: ""
        },
        {
            lt11: "ܓܒܝܐ",
            sy5: "ܓܰܒܝܳܐ",
            dt2: "",
            id: "1457",
            sy4: "ܓܒܝܐ",
            lt10: "ܓܒܝܐ",
            sy8: "",
            sy6: "",
            dt1: "Gabyo",
            sy7: "",
            sy9: "",
            lt12: "",
            dt3: ""
        },
        {
            lt11: "",
            sy4: "ܪܚܝܩ ܣܓܝ",
            sy5: "ܪܰܚܺܝܩ ܣܰܓܺܝ",
            lt12: "",
            dt2: "",
            sy6: "",
            id: "6315",
            lt10: "raĥiq sagi",
            sy7: "",
            sy9: "",
            dt1: "zu weit",
            dt3: "",
            sy8: ""
        },
        {
            sy4: "ܦܝܟܬܪܝܐ",
            lt11: "ܦܝܟܬܪܐܝܐ",
            sy5: "ܦܺܝܟܬܳܪܝܰܐ",
            lt12: "",
            dt2: "Viktoria",
            id: "4828",
            sy6: "",
            dt3: "",
            lt10: "ܦܝܟܬܪܐܝܐ",
            sy8: "",
            sy9: "",
            sy7: "",
            dt1: "Victoria"
        },
        {
            sy6: "",
            lt12: "",
            lt10: "qețmono",
            id: "1726",
            sy7: "",
            lt11: "",
            sy5: "ܩܶܛܡܳܢܳܐ",
            dt2: "",
            sy9: "",
            dt1: "grau",
            dt3: "",
            sy8: "",
            sy4: "ܩܛܡܢܐ"
        },
        {
            lt12: "",
            lt10: "shuĥoțo",
            dt1: "Abwegigkeit",
            sy5: "ܫܽܘܚܳܛܳܐ",
            sy8: "",
            dt3: "",
            dt2: "",
            sy9: "",
            sy7: "",
            sy6: "",
            lt11: "",
            id: "5702",
            sy4: "ܫܘܚܛܐ"
        },
        {
            sy9: "",
            sy5: "ܩܶܠܳܝܬܳܐ",
            dt2: "Raum",
            dt1: "Zimmer",
            lt12: "",
            dt3: "",
            lt10: "qeloyto",
            sy6: "",
            sy7: "",
            id: "5325",
            lt11: "",
            sy4: "ܩܠܝܬܐ",
            sy8: ""
        },
        {
            lt12: "",
            lt10: "fsiso",
            sy6: "",
            lt11: "",
            dt3: "zulässig",
            dt1: "erlaubt",
            sy5: "ܦܣܺܝܣܳܐ",
            sy4: "ܦܣܝܣܐ",
            dt2: "gestattet",
            sy9: "",
            sy7: "",
            id: "1153",
            sy8: ""
        },
        {
            sy9: "",
            sy4: "ܐܙܕܘܓ",
            id: "4744",
            lt12: "",
            sy5: "ܐܶܙܕܰܘܰܓ݂",
            dt1: "verheiraten",
            sy7: "ܒܥܶܠ",
            dt3: "",
            sy6: "ܒܥܠ",
            lt10: "ezdawağ",
            dt2: "",
            lt11: "bĉel",
            sy8: ""
        },
        {
            lt10: "dilonoyo",
            dt1: "speziell",
            sy6: "ܕܝܠܢܐܝܬ",
            sy8: "",
            dt2: "besonders",
            sy9: "",
            lt12: "",
            id: "4209",
            sy4: "ܕܝܠܢܝܐ",
            dt3: "individuell",
            sy5: "ܕܺܝܠܳܢܳܝܳܐ",
            lt11: "dilonoith",
            sy7: "ܕܝܺܠܳܢܳܐܝܺܬ݂"
        },
        {
            sy6: "",
            sy9: "",
            lt11: "",
            id: "2890",
            dt2: "",
            sy8: "",
            dt3: "",
            sy7: "",
            dt1: "Mais",
            lt10: "duĥno",
            sy4: "ܕܘܚܢܐ",
            sy5: "ܕܽܘܚܢܳܐ",
            lt12: ""
        },
        {
            lt12: "",
            dt1: "Matay",
            sy4: "ܡܬܝ",
            lt11: "ܡܬܝ",
            sy5: "ܡܰܬܰܝ",
            sy8: "",
            id: "2966",
            dt2: "Matai",
            sy9: "",
            dt3: "",
            sy7: "",
            sy6: "",
            lt10: "ܡܬܝ"
        },
        {
            sy5: "ܡܽܘܛܳܝܳܐ",
            sy7: "",
            dt1: "Ankunft",
            sy8: "",
            sy4: "ܡܘܛܝܐ",
            dt2: "",
            sy6: "",
            id: "241",
            sy9: "",
            lt10: "muțoyo",
            lt11: "",
            lt12: "",
            dt3: ""
        },
        {
            sy6: "",
            sy9: "",
            lt10: "beth nauĥo",
            dt1: "Ruheort",
            dt2: "",
            sy5: "ܒܶܝܬ݂ ܢܰܘܚܳܐ",
            id: "3765",
            sy7: "",
            lt11: "",
            lt12: "",
            sy4: "ܒܝܬ ܢܘܚܐ",
            dt3: "",
            sy8: ""
        },
        {
            sy4: "ܩܠܝܬܐ ܕܫܘܢܕܐ",
            lt11: "",
            sy9: "",
            lt12: "",
            dt2: "",
            sy8: "",
            sy6: "",
            sy7: "",
            dt1: "Folterkammer",
            id: "1348",
            dt3: "",
            sy5: "ܩܶܠܳܝܬܳܐ ܕܫܽܘܢܳܕܳܐ",
            lt10: "qeloyto d`shunođo"
        },
        {
            sy7: "",
            id: "2721",
            sy9: "",
            lt10: "ĉud",
            lt11: "",
            dt3: "",
            sy5: "ܥܽܘܕ",
            dt2: "",
            dt1: "Laute",
            sy6: "",
            sy4: "ܥܘܕ",
            lt12: "",
            sy8: ""
        },
        {
            sy5: "ܙܚܰܦ",
            lt12: "dĥaq",
            lt11: "dĥo",
            sy9: "ܕܚܰܩ",
            dt1: "abstossen",
            sy6: "ܕܚܐ",
            sy7: "ܕܚܳܐ",
            sy4: "ܙܚܦ",
            dt3: "",
            lt10: "zĥaf",
            sy8: "ܕܚܩ",
            id: "5671",
            dt2: ""
        },
        {
            dt3: "",
            dt1: "geräuchert",
            sy8: "",
            sy4: "ܫܡܪܢܝܐ",
            sy5: "ܫܶܡܪܳܢܳܝܳܐ",
            lt11: "",
            dt2: "",
            lt12: "",
            lt10: "shemronoyo",
            id: "6114",
            sy7: "",
            sy6: "",
            sy9: ""
        },
        {
            dt2: "",
            sy6: "",
            lt11: "",
            sy5: "ܥܳܡܽܘܪܳܐ ܕܡܰܫܟܢܳܐ",
            sy9: "",
            sy7: "",
            dt3: "",
            sy4: "ܥܡܘܪܐ ܕܡܫܟܢܐ",
            id: "6247",
            dt1: "Zeltbewohner",
            sy8: "",
            lt12: "",
            lt10: "ĉomuro d'mashkno"
        },
        {
            sy5: "ܟܪܳܦ̈ܳܬܳܐ",
            sy7: "",
            sy6: "",
            id: "3067",
            dt3: "",
            dt1: "Möbel",
            sy8: "",
            lt11: "",
            lt12: "",
            sy9: "",
            lt10: "krofotho",
            sy4: "ܟܪܦܬܐ",
            dt2: ""
        },
        {
            sy7: "",
            lt11: "",
            sy6: "",
            sy5: "ܒܝܰܕ ܡܳܢܳܐ ܠܳܚܶܐ ܐ̱ܢܳܐ ܠܽܘܚܳܐ",
            id: "3058",
            dt1: "Mit was soll ich die Tafel abwischen",
            sy8: "",
            lt12: "",
            dt2: "",
            lt10: "byađ mono loĥe no luĥo",
            sy4: "ܒܝܕ ܡܢܐ ܠܚܐ ܐܢܐ ܠܘܚܐ",
            dt3: "",
            sy9: ""
        },
        {
            dt1: "Blutdruck",
            lt12: "",
            id: "662",
            sy9: "",
            lt11: "zrubyo da`dmo",
            sy4: "ܬܢܣܝܘܢ",
            sy8: "",
            sy6: "ܙܪܘܒܝܐ ܕܕܡܐ",
            dt2: "",
            sy5: "ܬܰܢܣܝܳܘܢ",
            dt3: "",
            lt10: "tansiyon",
            sy7: "ܙܪܽܘܒܳܝܳܐ ܕܰܕܡܳܐ"
        },
        {
            sy4: "ܡܘܓܐ",
            lt11: "",
            lt12: "",
            dt1: "Schornstein",
            sy7: "",
            sy6: "",
            dt2: "",
            sy9: "",
            id: "4020",
            dt3: "",
            sy8: "",
            lt10: "mugo",
            sy5: "ܡܽܘܓܳܐ"
        },
        {
            sy6: "",
            sy9: "",
            dt2: "",
            dt3: "",
            lt11: "",
            id: "5751",
            lt12: "",
            lt10: "kurhoy gyode",
            dt1: "Adernkrankheit",
            sy5: "ܟܽܘܪܗܳܝ ܓܝܳܕܶܐ",
            sy8: "",
            sy4: "ܟܘܪܗܝ ܓܝܕܐ",
            sy7: ""
        },
        {
            sy5: "ܛܰܠܝܳܐ",
            dt1: "Junge",
            lt11: "ĉlaymo",
            lt10: "țalyo",
            sy4: "ܛܠܝܐ",
            id: "2361",
            dt3: "",
            sy8: "",
            sy9: "",
            lt12: "",
            sy6: "ܥܠܝܡܐ",
            sy7: "ܥܠܰܝܡܳܐ",
            dt2: ""
        },
        {
            lt11: "",
            id: "4191",
            sy4: "ܠܒܘܫܐ ܩܝܛܝܐ",
            sy8: "",
            dt2: "",
            dt1: "Sommerkleidung",
            sy9: "",
            lt10: "lbushe qayțoye",
            sy7: "",
            sy5: "ܠܒܽܘ̈ܫܳܐ ܩܰܝܛܳܝܶܐ",
            lt12: "",
            dt3: "",
            sy6: ""
        },
        {
            dt2: "",
            dt3: "",
            sy4: "ܥܠܬܐ",
            lt12: "",
            sy5: "ܥܶܠܬ݂ܳܐ",
            lt10: "ĉeltho",
            sy8: "",
            lt11: "",
            sy9: "",
            id: "4699",
            dt1: "Ursache",
            sy7: "",
            sy6: ""
        },
        {
            sy6: "ܡܘܠܣܐ",
            sy5: "ܐܰܟ݂ܙܢܳܝܽܘܬ݂ܳܐ",
            lt10: "akhznoyutho",
            id: "6048",
            sy8: "",
            dt1: "homogen",
            dt2: "",
            lt12: "",
            sy7: "ܡܽܘܠܣܳܐ",
            lt11: "mulso",
            sy4: "ܐܟܙܢܝܘܬܐ",
            dt3: "",
            sy9: ""
        },
        {
            sy7: "",
            sy9: "",
            dt3: "",
            dt1: "Viehhändler",
            sy4: "ܬܓܪܐ ܕܩܢܝܢܐ",
            sy8: "",
            sy5: "ܬܰܓܳܪܳܐ ܕܩܶܢܝܳܢܶܐ",
            dt2: "",
            id: "4829",
            lt12: "",
            lt11: "",
            lt10: "tagoro d`qenyone",
            sy6: ""
        },
        {
            sy8: "",
            sy4: "ܫܠܡܐ",
            dt3: "",
            id: "1396",
            sy6: "ܫܝܢܐ",
            dt2: "",
            lt10: "shlomo",
            lt12: "",
            sy9: "",
            sy7: "ܫܶܝܢܳܐ",
            sy5: "ܫܠܳܡܳܐ",
            dt1: "Frieden",
            lt11: "sheyno"
        },
        {
            lt12: "",
            lt10: "nafoĥto țoyusto",
            sy8: "",
            sy4: "ܢܦܚܬܐ ܛܝܘܣܬܐ",
            sy9: "",
            dt3: "",
            sy6: "ܛܘܣܐ ܕܢܦܝܚܬܐ",
            id: "2865",
            sy5: "ܢܦܚܬܐ ܛܳܝܽܘܣܬܳܐ",
            sy7: "ܛܰܘܣܳܐ ܕܰܢܦܺܝܚܬܳܐ",
            lt11: "țauso da`nfiĥto",
            dt2: "",
            dt1: "Luftballon"
        },
        {
            lt11: "budro",
            sy4: "ܦܘܕܪܐ",
            sy8: "ܕܪܕܐ",
            sy7: "ܒܽܘܕܪܳܐ",
            dt1: "Aasgeier",
            id: "5544",
            dt2: "",
            sy5: "ܦܽܘܕܪܳܐ",
            sy6: "ܒܘܕܪܐ",
            sy9: "ܕܰܪܕܳܐ",
            dt3: "",
            lt10: "fudro",
            lt12: "dardo"
        },
        {
            sy6: "",
            dt2: "",
            dt1: "Hausfriede",
            lt10: "shayno d`bayto",
            lt11: "",
            dt3: "",
            sy5: "ܫܰܝܢܳܐ ܕܒܰܝܬܳܐ",
            lt12: "",
            sy7: "",
            sy8: "",
            sy9: "",
            id: "1900",
            sy4: "ܫܝܢܐ ܕܒܝܬܐ"
        },
        {
            lt12: "",
            sy8: "",
            dt2: "Blinzeln",
            lt10: "remzo d`ĉayne",
            id: "5408",
            dt3: "",
            dt1: "Zwinkern",
            lt11: "rfof ĉayno",
            sy7: "ܪܦܳܦ ܥܰܝܢܳܐ",
            sy6: "ܪܦܦ ܥܝܢܐ",
            sy5: "ܪܶܡܙܳܐ ܕܥܰܝ̈ܢܶܐ",
            sy9: "",
            sy4: "ܪܡܙܐ ܕܥܝܢܐ"
        },
        {
            sy7: "ܬܰܘܕܺܝ ܣܰܓܺܝ",
            dt1: "Vielen Dank",
            lt11: "taudi sagi",
            lt12: "",
            lt10: "taudi sagi",
            sy9: "",
            id: "4833",
            sy5: "ܬܰܘܕܺܝ ܣܰܓܺܝ",
            sy6: "ܬܘܕܝ ܣܓܝ",
            sy4: "ܬܘܕܝ ܣܓܝ",
            dt3: "",
            dt2: "",
            sy8: ""
        },
        {
            dt3: "",
            sy7: "",
            dt1: "kündigen",
            sy5: "ܒܰܛܶܠ",
            sy9: "",
            lt11: "",
            sy4: "ܒܛܠ",
            lt12: "",
            id: "2668",
            sy6: "",
            dt2: "",
            lt10: "bațel",
            sy8: ""
        },
        {
            dt3: "",
            lt11: "",
            sy9: "",
            sy5: "ܣܰܩܶܡ",
            lt10: "saqem",
            sy6: "",
            sy7: "",
            dt1: "datieren",
            lt12: "",
            id: "825",
            sy8: "",
            dt2: "",
            sy4: "ܣܩܡ"
        },
        {
            id: "6119",
            dt1: "hartherzig",
            sy7: "",
            sy6: "",
            lt11: "",
            sy8: "",
            dt3: "",
            sy9: "",
            dt2: "",
            sy4: "ܩܫܐ ܠܒܐ",
            lt12: "",
            sy5: "ܩܫܶܐ ܠܶܒܳܐ",
            lt10: "qshe lebo"
        },
        {
            sy7: "ܫܰܪܺܝܪܽܘܬ݂ܳܐ",
            lt11: "sharirutho",
            sy8: "ܟܐܢܘܬܐ",
            dt3: "Ehrlichkeit",
            lt10: "zadiqutho",
            lt12: "kinutho",
            sy6: "ܫܪܝܪܘܬܐ",
            sy9: "ܟܺܐܢܽܘܬ݂ܳܐ",
            dt2: "Rechtschaffenheit",
            sy4: "ܙܕܝܩܘܬܐ",
            id: "1571",
            dt1: "Gerechtigkeit",
            sy5: "ܙܰܕܺܝܩܽܘܬ݂ܳܐ"
        },
        {
            sy6: "",
            dt3: "",
            lt11: "",
            sy4: "ܡܬܪܢܝܢܐ",
            sy9: "",
            sy5: "ܡܶܬ݂ܪܰܢܝܳܢܳܐ",
            lt12: "",
            sy8: "",
            dt1: "denkbar",
            id: "858",
            lt10: "methranyono",
            sy7: "",
            dt2: ""
        },
        {
            dt1: "Michaela",
            sy4: "ܡܝܟܐܝܠܐ",
            sy7: "",
            dt2: "",
            lt12: "",
            sy6: "",
            lt11: "ܡܝܟܐܝܠܐ",
            dt3: "",
            sy9: "",
            id: "3017",
            lt10: "ܡܝܟܐܝܠܐ",
            sy8: "",
            sy5: "ܡܺܝܟ݂ܰܐܝܶܠܰܐ"
        },
        {
            sy9: "",
            sy6: "",
            lt11: "",
            lt12: "",
            id: "5478",
            sy8: "",
            sy5: "ܛܶܒ̈ܶܐ ܡܰܥܶܪܒܳܝܶܐ",
            sy7: "",
            sy4: "ܛܶܒ̈ܶܐ ܡܰܥܶܪܒܳܝܶܐ",
            dt2: "News auf westarmäisch",
            dt3: "",
            lt10: "țebe maĉerboye",
            dt1: "Nachrichten auf westaramäisch"
        },
        {
            sy8: "",
            sy9: "",
            lt10: "shlado",
            id: "2749",
            sy4: "ܫܠܕܐ",
            lt11: "",
            lt12: "",
            dt2: "",
            sy6: "",
            sy5: "ܫܠܰܕܳܐ",
            sy7: "",
            dt1: "Leiche",
            dt3: ""
        },
        {
            lt10: "mathen",
            dt3: "",
            lt12: "",
            sy5: "ܡܰܬ݂ܶܢ ",
            lt11: "rish ",
            dt2: "Zweihundert",
            sy7: "ܪ",
            dt1: "200",
            sy8: "",
            sy4: "ܡܐܬܝܢ",
            id: "39",
            sy9: "",
            sy6: "ܪ"
        },
        {
            sy9: "",
            dt3: "Vergeblichkeit",
            sy4: "ܣܪܝܩܘܬܐ",
            id: "3327",
            dt2: "Nichtigkeit",
            lt10: "sriqutho",
            dt1: "Nutzlosigkeit",
            sy8: "",
            lt12: "",
            sy7: "",
            sy6: "",
            lt11: "",
            sy5: "ܣܪܺܝܩܽܘܬ݂ܳܐ"
        },
        {
            sy8: "",
            lt10: "ܛܘܒܝܛ",
            sy9: "",
            sy7: "",
            dt2: "",
            lt12: "",
            sy6: "",
            dt3: "",
            sy5: "ܛܳܘܒܺܝܛ",
            id: "4505",
            lt11: "ܛܘܒܝܛ",
            dt1: "Tobit",
            sy4: "ܛܘܒܝܛ"
        },
        {
            sy6: "",
            sy4: "ܫܩܐ",
            sy9: "",
            dt3: "",
            lt10: "shoqo",
            sy8: "",
            id: "3944",
            dt1: "Schienbein",
            sy7: "",
            lt12: "",
            sy5: "ܫܳܩܳܐ",
            lt11: "",
            dt2: ""
        },
        {
            lt12: "ܪܝܡܐ",
            sy8: "",
            lt11: "ܪܝܡܐ",
            sy5: "ܪܰܝܡܰܐ",
            dt2: "",
            lt10: "ܪܝܡܐ",
            id: "3627",
            sy4: "ܪܝܡܐ",
            sy9: "",
            dt1: "Rayma",
            sy7: "ܪܺܝܡܰܐ",
            sy6: "ܪܝܡܐ",
            dt3: ""
        },
        {
            dt1: "Adjutant",
            sy7: "",
            sy8: "",
            sy5: "ܬܰܥܕܺܝܪܳܐ",
            dt3: "",
            lt11: "",
            sy9: "",
            lt10: "taĉdiro",
            id: "5767",
            sy4: "ܬܥܕܝܪܐ",
            sy6: "",
            dt2: "",
            lt12: ""
        },
        {
            dt1: "Dorf Kafro tahtayto",
            sy7: "ܟܰܦܪܳܐ ܬܰܚܬܳܝܬܳܐ",
            lt12: "",
            lt11: "kafro tahtoyto",
            sy9: "",
            sy8: "",
            dt2: "Kafro tahtayto",
            sy4: "ܩܪܝܬܐ ܟܦܪܐ ܬܚܬܝܬܐ",
            sy5: "ܩܪܺܝܬ݂ܳܐ ܟܰܦܪܳܐ ܬܰܚܬܳܝܬܳܐ",
            lt10: "qritho kafro tahtoyto",
            sy6: "ܟܦܪܐ ܬܚܬܝܬܐ",
            id: "937",
            dt3: ""
        },
        {
            sy5: "ܟܶܬܳܢܳܐ ܕܫܰܟܰܪ",
            sy6: "",
            id: "6444",
            lt12: "",
            lt10: "ketono d`shakar",
            dt2: "",
            sy7: "",
            sy8: "",
            dt3: "",
            dt1: "Zuckerwatte",
            sy9: "",
            sy4: "ܟܬܢܐ ܕܫܟܪ",
            lt11: ""
        },
        {
            sy8: "",
            sy6: "",
            sy5: "ܩܛܺܝܪܬ݂ܳܢܽܘܬ݂ܳܐ",
            dt1: "Verpflichtung",
            dt2: "",
            sy9: "",
            lt11: "",
            lt12: "",
            sy7: "",
            lt10: "qțirthonutho",
            sy4: "ܩܛܝܪܬܢܘܬܐ",
            dt3: "",
            id: "4784"
        },
        {
            sy4: "ܒܪܟܐܢܐ",
            lt11: "ܒܪܟܐܢܐ",
            sy7: "",
            dt2: "",
            sy9: "",
            lt10: "ܒܪܟܐܢܐ",
            dt3: "",
            sy5: "ܒܰܪܟܺܐܢܳܐ",
            lt12: "",
            dt1: "Barkino",
            sy6: "",
            id: "456",
            sy8: ""
        },
        {
            lt11: "",
            sy4: "ܩܒܐ",
            sy6: "",
            dt3: "",
            sy8: "",
            sy7: "",
            dt1: "Einzäunung",
            lt10: "qabo",
            id: "6405",
            sy5: "ܩܰܒܳܐ",
            lt12: "",
            dt2: "",
            sy9: ""
        },
        {
            sy8: "",
            sy9: "",
            dt1: "Aramäer",
            lt12: "",
            sy6: "ܣܘܪܝܝܐ ܐܪܡܝܐ",
            sy7: "ܣܽܘܪܝܳܝܳܐ ܐܳܪܳܡܳܝܳܐ",
            dt2: "aramäisch",
            id: "271",
            lt10: "oromoyo",
            sy4: "ܐܪܡܝܐ",
            dt3: "",
            lt11: "suryoyo oromoyo",
            sy5: "ܐܳܪܳܡܳܝܳܐ"
        },
        {
            lt11: "ܥܒܕܐܠܚܕ",
            id: "70",
            lt12: "",
            lt10: "ܥܒܕܐܠܚܕ",
            sy6: "",
            sy7: "",
            sy4: "ܥܒܕܐܠܚܕ",
            dt2: "",
            sy9: "",
            dt3: "",
            sy5: "ܥܰܒܶܕܰܐܠܰܚܰܕ",
            sy8: "",
            dt1: "Abdullahad"
        },
        {
            sy4: "ܡܕܝܢܬ ܠܡܐܢܐ",
            sy8: "",
            lt12: "",
            sy9: "",
            dt2: "",
            id: "1817",
            sy7: "",
            dt3: "",
            lt11: "",
            sy5: "ܡܕܺܝܢܰܬ݂ ܠܡܺܐܢܳܐ",
            sy6: "",
            lt10: "mđinath lmino",
            dt1: "Hafenstadt"
        },
        {
            sy6: "",
            id: "1388",
            lt12: "",
            sy4: "ܣܢܐܬ ܐܟܣܢܝܐ",
            lt10: "senath akhsnoye",
            lt11: "",
            sy8: "",
            dt1: "Fremdenfeindlichkeit",
            sy7: "",
            dt2: "",
            sy5: "ܣܶܢܰܐܬ݂ ܐܰܟ݂ܣܢ̈ܳܝܶܐ",
            dt3: "",
            sy9: ""
        },
        {
            dt2: "",
            sy6: "",
            dt1: "Wohnzimmer",
            lt12: "",
            lt11: "",
            sy5: "ܒܝܬ݂ ܣܡܳܟ݂ܳܐ",
            dt3: "",
            sy4: "ܒܝܬ ܣܡܟܐ",
            sy7: "",
            sy9: "",
            lt10: "beth smokho",
            sy8: "",
            id: "5529"
        },
        {
            sy7: "",
            dt3: "",
            dt1: "Frauenrock",
            sy9: "",
            dt2: "",
            lt12: "",
            lt10: "tenurto d`neshe",
            sy4: "ܬܢܘܪܬܐ ܕܢܫܐ",
            id: "1375",
            sy8: "",
            sy5: "ܬܶܢܽܘܪܬܳܐ ܕܢܶܫ̈ܶܐ",
            sy6: "",
            lt11: ""
        },
        {
            lt12: "",
            id: "5805",
            sy9: "",
            sy5: "ܡܡܰܬ݂ܠܳܢܳܐ",
            dt2: "",
            sy8: "",
            sy6: "ܛܪܝܓܘܕܐ",
            sy4: "ܡܡܬܠܢܐ",
            lt10: "mmathlono",
            dt3: "",
            dt1: "Akteur",
            sy7: "ܛܪܺܝܓܽܘܕܳܐ",
            lt11: "țrigudo"
        },
        {
            id: "3878",
            sy9: ":ܫܝܢܐ",
            lt11: "",
            dt2: "",
            lt12: "",
            lt10: "ܫܝܢܐ",
            dt1: "Schaino",
            sy8: "",
            dt3: "",
            sy4: "ܫܝܢܐ",
            sy6: "",
            sy5: "ܫܰܝܢܳܐ",
            sy7: ""
        },
        {
            lt11: "",
            lt10: "sheĉyo d`kalusotho",
            sy8: "",
            sy6: "",
            dt1: "Puppenspiel",
            dt3: "",
            id: "3550",
            sy9: "",
            lt12: "",
            sy7: "",
            sy5: "ܫܶܥܝܳܐ ܕܟܰܠܽܘܣܳܬ݂ܳܐ",
            sy4: "ܫܥܝܐ ܕܟܠܘܣܬܐ",
            dt2: ""
        },
        {
            sy6: "",
            sy4: "ܐܘܪܒ",
            sy5: "ܐܰܘܪܶܒ",
            dt2: "",
            lt12: "",
            lt10: "ܐܘܪܒ",
            sy7: "",
            sy9: "",
            dt3: "",
            sy8: "",
            id: "401",
            dt1: "Awreb",
            lt11: "ܐܘܪܒ"
        },
        {
            sy9: "",
            dt3: "",
            sy4: "ܟܫܠ",
            sy5: "ܟܫܶܠ",
            sy8: "",
            sy7: "ܐܶܬ݂ܟܫܶܠ",
            lt11: "ethkshel",
            dt2: "",
            id: "5392",
            sy6: "ܐܬܟܫܠ",
            lt10: "kshel",
            lt12: "",
            dt1: "zweifeln"
        },
        {
            dt1: "Dimat",
            lt10: "ܕܝܡܛ",
            sy8: "",
            lt11: "ܕܝܡܛ",
            sy9: "",
            sy7: "ܕܺܝܡܶܬ",
            sy4: "ܕܝܡܛ",
            lt12: "ܕܝܡܬ",
            dt2: "Dimet",
            dt3: "",
            sy5: "ܕܺܝܡܶܛ",
            id: "895",
            sy6: "ܕܝܡܬ"
        },
        {
            sy5: "ܠܰܝܡܽܘܢܳܐ",
            dt2: "",
            lt12: "",
            id: "5337",
            dt3: "",
            sy9: "",
            sy6: "",
            lt11: "",
            sy8: "",
            lt10: "laymuno",
            sy4: "ܠܝܡܘܢܐ",
            dt1: "Zitrone",
            sy7: ""
        },
        {
            lt12: "",
            sy5: "ܪ",
            sy8: "",
            dt1: "r",
            dt3: "",
            sy7: "",
            lt10: "rish",
            dt2: "",
            sy9: "",
            sy4: "ܪ",
            id: "3573",
            sy6: "",
            lt11: ""
        },
        {
            lt10: "gyodo",
            sy8: "ܫܪܚܐ",
            lt12: "sharĥo",
            id: "5747",
            lt11: "warido",
            sy9: "ܫܰܪܚܳܐ",
            dt3: "",
            sy7: "ܘܰܪܺܝܕܳܐ",
            dt2: "",
            sy6: "ܘܪܝܕܐ",
            sy4: "ܓܝܕܐ",
            sy5: "ܓܝܳܕܳܐ",
            dt1: "Ader"
        },
        {
            sy7: "",
            sy5: "ܕܳܕܝܶܫܽܘܥ",
            lt11: "ܕܕܝܫܘܥ",
            lt10: "ܕܕܝܫܘܥ",
            lt12: "",
            id: "909",
            sy8: "",
            dt2: "",
            dt1: "Dodjeschu",
            dt3: "",
            sy4: "ܕܕܝܫܘܥ",
            sy9: "",
            sy6: ""
        },
        {
            dt3: "Koalition",
            dt2: "Einigkeit",
            sy7: "ܐܰܘܝܽܘܬ݂ܳܐ",
            lt10: "ĥđoyutho",
            lt12: "",
            lt11: "auyutho",
            id: "1039",
            sy4: "ܚܕܝܘܬܐ",
            sy8: "",
            dt1: "Einheit",
            sy6: "ܐܘܝܘܬܐ",
            sy9: "",
            sy5: "ܚܕܳܝܽܘܬ݂ܳܐ"
        },
        {
            dt1: "Barniro",
            sy8: "",
            dt3: "",
            lt12: "",
            dt2: "",
            sy7: "",
            sy5: "ܒܰܪܢܺܝܪܳܐ",
            lt10: "ܒܪܢܝܪܐ",
            id: "467",
            sy6: "",
            lt11: "ܒܪܢܝܪܐ",
            sy4: "ܒܪܢܝܪܐ",
            sy9: ""
        },
        {
            dt3: "Gevriye",
            lt11: "ܓܘܪܝܗ",
            sy4: "ܓܘܪܝܗ",
            lt10: "ܓܘܪܝܗ",
            id: "1489",
            sy5: "ܓܰܘܪܺܝܶܗ",
            sy9: "",
            sy8: "",
            sy7: "ܓܰܘܪܺܝܶܐ",
            sy6: "ܓܘܪܝܐ",
            lt12: "ܓܘܪܝܐ",
            dt1: "Gawriye",
            dt2: "Gewriye"
        },
        {
            dt1: "Wandbrett",
            dt2: "Regal",
            sy6: "",
            sy7: "",
            sy8: "",
            sy5: "ܣܕܽܘܪܒܳܐ",
            id: "4977",
            sy9: "",
            sy4: "ܣܕܘܪܒܐ",
            lt11: "",
            lt10: "sdurbo",
            dt3: "",
            lt12: ""
        },
        {
            sy8: "",
            sy7: "",
            sy9: "",
            lt12: "",
            dt1: "Diyarbakir",
            id: "908",
            sy6: "",
            sy4: "ܐܡܝܕ",
            lt10: "omid",
            lt11: "",
            dt2: "",
            dt3: "",
            sy5: "ܐܳܡܺܝܕ"
        },
        {
            dt2: "",
            sy7: "ܡܶܐܙܰܠܬܳܐ",
            dt1: "Abflug",
            sy9: "ܡܰܦܰܩܬܳܐ",
            sy8: "ܡܦܩܬܐ",
            sy5: "ܡܶܐܙܠܳܐ",
            sy6: "ܡܐܙܠܬܐ",
            dt3: "",
            lt12: "mafaqto",
            id: "5563",
            lt10: "meslo",
            lt11: "mesalto",
            sy4: "ܡܐܙܠܐ"
        },
        {
            sy8: "",
            lt10: "shahuwo",
            sy4: "ܫܗܘܘܐ",
            sy6: "",
            sy5: "ܫܰܗܽܘܘܳܐ",
            sy7: "",
            sy9: "",
            dt2: "salzlos",
            id: "3816",
            dt1: "salzarm",
            lt11: "",
            dt3: "",
            lt12: ""
        },
        {
            dt1: "Alkohol",
            lt10: "shakhro",
            lt11: "",
            id: "179",
            sy7: "",
            sy4: "ܫܟܪܐ",
            sy8: "",
            lt12: "",
            sy9: "",
            dt3: "",
            sy5: "ܫܰܟ݂ܪܳܐ",
            sy6: "",
            dt2: ""
        },
        {
            sy4: "ܢܫܩ",
            id: "2680",
            sy6: "ܢܫܩ",
            lt10: "nshaq",
            dt3: "",
            lt12: "",
            lt11: "nosheq",
            dt2: "",
            dt1: "küssen",
            sy8: "",
            sy7: "ܢܳܫܶܩ",
            sy5: "ܢܫܰܩ",
            sy9: ""
        },
        {
            sy6: "ܡܒܫܠܢܝܬܐ",
            sy5: "ܛܰܒܳܚܬܳܐ",
            sy4: "ܛܒܚܬܐ",
            id: "2552",
            lt11: "mbashlonitho",
            sy9: "",
            dt1: "Köchin",
            lt10: "țaboĥto",
            lt12: "",
            dt3: "",
            dt2: "",
            sy8: "",
            sy7: "ܡܒܰܫܠܳܢܺܝܬ݂ܳܐ"
        },
        {
            dt3: "",
            lt11: "garoĉutho",
            sy6: "ܓܪܥܘܬܐ",
            dt1: "Rasieren",
            lt10: "groĉo",
            sy9: "",
            lt12: "",
            sy8: "",
            sy7: "ܓܰܪܳܥܽܘܬ݂ܳܐ",
            dt2: "",
            sy5: "ܓܪܳܥܳܐ",
            sy4: "ܓܪܥܐ",
            id: "3605"
        },
        {
            sy4: "ܟܕܒܘܬܐ",
            sy8: "",
            dt3: "",
            sy9: "",
            sy6: "ܕܓܠܘܬܐ",
            sy5: "ܟܰܕܳܒܽܘܬ݂ܳܐ",
            dt2: "",
            lt12: "",
            lt10: "kađobutho",
            id: "2795",
            sy7: "ܕܰܓܳܠܽܘܬ݂ܳܐ",
            dt1: "Leugnung",
            lt11: "dagolutho"
        },
        {
            dt1: "Apotheke mit Nachtdienst",
            id: "262",
            sy7: "",
            sy6: "",
            sy8: "",
            lt12: "",
            dt2: "",
            lt10: "rakolutho noțurto",
            sy9: "",
            dt3: "",
            lt11: "",
            sy5: "ܪܰܟܳܠܽܘܬ݂ܳܐ ܢܳܛܽܘܪܬܳܐ",
            sy4: "ܪܟܠܘܬܐ ܢܛܘܪܬܐ"
        },
        {
            sy8: "",
            dt2: "",
            sy7: "",
            lt10: "rish ĉallotho",
            lt12: "",
            dt3: "",
            sy5: "ܪܺܝܫ ܥܰܠܠܳܬ݂ܳܐ",
            dt1: "Frühernte",
            lt11: "",
            sy9: "",
            sy4: "ܪܝܫ ܥܠܠܬܐ",
            id: "6107",
            sy6: ""
        },
        {
            lt10: "teshmeshto d`imomo",
            lt11: "",
            sy7: "",
            dt1: "Tagesdienst",
            sy4: "ܬܫܡܫܬܐ ܕܐܝܡܡܐ",
            sy6: "",
            sy8: "",
            id: "4364",
            dt2: "Tagesgebet",
            sy5: "ܬܶܫܡܶܫܬܳܐ ܕܐܺܝܡܳܡܳܐ",
            sy9: "",
            dt3: "",
            lt12: ""
        },
        {
            lt10: "ruĥo",
            dt3: "",
            sy7: "ܢܰܦܫܳܐ",
            dt2: "Geist",
            sy9: "",
            sy8: "",
            dt1: "Seele",
            lt11: "nafsho",
            sy6: "ܢܦܫܐ",
            sy5: "ܪܽܘܚܳܐ",
            id: "4089",
            lt12: "",
            sy4: "ܪܘܚܐ"
        },
        {
            lt12: "",
            sy8: "",
            sy5: "ܪܒܺܝܥܳܝܳܐ",
            lt10: "rbiĉoyo",
            dt1: "vierter",
            dt2: "",
            dt3: "",
            sy4: "ܪܒܝܥܝܐ",
            id: "4840",
            lt11: "",
            sy7: "",
            sy9: "",
            sy6: ""
        },
        {
            lt11: "shbiĥoith",
            sy8: "",
            sy4: "ܡܫܒܚܐ",
            id: "1667",
            lt12: "",
            sy7: "ܫܒܺܝܚܳܐܝܺܬ݂",
            dt3: "",
            sy9: "",
            sy6: "ܫܒܝܚܐܝܬ",
            dt2: "",
            sy5: "ܡܫܰܒܚܳܐ",
            lt10: "mshabĥo",
            dt1: "glorreich"
        },
        {
            dt3: "",
            sy4: "ܥܒܕܘܬܐ",
            dt2: "",
            lt10: "ĉabdutho",
            sy8: "",
            dt1: "Knechtschaft",
            sy6: "",
            sy5: "ܥܰܒܕܽܘܬ݂ܳܐ",
            sy7: "",
            lt12: "",
            id: "2538",
            sy9: "",
            lt11: ""
        },
        {
            sy6: "",
            sy5: "ܒܰܪܒܰܗܠܽܘܠ",
            sy9: "",
            lt11: "ܒܪܒܗܠܘܠ",
            sy4: "ܒܪܒܗܠܘܠ",
            lt10: "ܒܪܒܗܠܘܠ",
            id: "436",
            dt2: "",
            lt12: "",
            sy7: "",
            sy8: "",
            dt1: "Bar Bahlul",
            dt3: ""
        },
        {
            sy8: "",
            dt2: "",
            sy9: "",
            lt10: "kuloyo",
            id: "5497",
            lt11: "",
            sy4: "ܟܘܠܝܐ",
            lt12: "",
            dt1: "Disqualifikation",
            sy7: "",
            sy6: "",
            dt3: "",
            sy5: "ܟܽܘܠܳܝܳܐ"
        },
        {
            sy8: "",
            id: "1194",
            dt1: "Essen",
            sy4: "ܡܐܟܠܐ",
            dt3: "",
            lt12: "",
            lt10: "mekhlo",
            sy6: "ܡܐܟܘܠܬܐ",
            sy5: "ܡܶܐܟ݂ܠܳܐ",
            dt2: "",
            sy7: "ܡܶܐܟ݂ܽܘܠܬܳܐ",
            lt11: "mekhulto",
            sy9: ""
        },
        {
            sy6: "",
            sy9: "",
            dt1: "Kaiserin",
            sy7: "",
            dt2: "",
            lt10: "shalițtho",
            sy5: "ܫܰܠܺܝܛܬ݂ܳܐ",
            sy8: "",
            lt11: "",
            dt3: "",
            id: "2382",
            sy4: "ܫܠܝܛܬܐ",
            lt12: ""
        },
        {
            id: "3782",
            sy7: "",
            dt3: "",
            lt11: "",
            sy4: "ܙܪܥܐ",
            lt12: "",
            sy9: "",
            lt10: "zarĉo",
            dt2: "Korn",
            sy8: "",
            sy5: "ܙܰܪܥܳܐ",
            sy6: "",
            dt1: "Saat"
        },
        {
            sy5: "ܓܙܝܺܪܽܘܬ݂ܳܐ",
            lt12: "mniğutho",
            dt1: "Beschneidung",
            lt10: "gzirutho",
            sy6: "ܓܙܘܪܬܐ",
            sy9: "ܡܢܺܝܓ݂ܽܘܬ݂ܳܐ",
            sy8: "ܡܢܝܓܘܬܐ",
            sy7: "ܓܙܽܘܪܬܳܐ",
            dt2: "",
            lt11: "gzurto",
            id: "570",
            sy4: "ܓܙܝܪܘܬܐ",
            dt3: ""
        },
        {
            lt11: "midun",
            sy5: "ܩܪܺܝܬ݂ܳܐ ܕܡܺܝܕܺܢ",
            dt1: "Dorf Midin",
            sy9: "",
            sy6: "ܡܝܕܘܢ",
            dt2: "Midin",
            dt3: "",
            id: "940",
            sy7: "ܡܺܝܕܽܘܢ",
            sy4: "ܩܪܝܬܐ ܕܡܝܕܢ",
            lt10: "qritho d`midin",
            sy8: "",
            lt12: ""
        },
        {
            sy7: "ܠܚܽܘܕ",
            dt1: "nur",
            sy6: "ܠܚܘܕ",
            id: "3314",
            dt3: "",
            sy8: "",
            lt10: "balĥuđ",
            lt11: "lĥuđ",
            dt2: "bloß",
            sy4: "ܒܠܚܘܕ",
            lt12: "",
            sy5: "ܒܰܠܚܽܘܕ",
            sy9: ""
        },
        {
            sy5: "ܒܳܬܶܐ",
            dt2: "",
            lt10: "bote",
            sy7: "",
            dt1: "Häuser",
            lt11: "",
            id: "5756",
            dt3: "",
            sy4: "ܒܬܐ",
            sy9: "",
            sy8: "",
            lt12: "",
            sy6: ""
        },
        {
            sy4: "ܢܦܚܐ",
            dt1: "Blasen",
            sy5: "ܢܦܳܚܳܐ",
            sy6: "",
            id: "641",
            lt10: "nfoĥo",
            sy7: "",
            dt2: "Pusten",
            sy9: "",
            lt12: "",
            dt3: "",
            lt11: "",
            sy8: ""
        },
        {
            sy8: "ܫܓܘܫܝܐ",
            sy4: "ܒܘܠܒܠܐ",
            lt10: "bulbolo",
            sy6: "ܫܘܫܐ",
            sy5: "ܒܽܘܠܒܳܠܳܐ",
            dt1: "Konfusion",
            lt11: "shausho",
            sy7: "ܫܰܘܫܳܐ",
            id: "6082",
            dt2: "Verwirrung",
            lt12: "shgushyo",
            sy9: "ܫܓܽܘܫܝܳܐ",
            dt3: ""
        },
        {
            dt3: "",
            sy8: "",
            lt12: "",
            sy6: "",
            sy7: "",
            lt10: "ܐܚܙܝܐ",
            dt2: "",
            sy4: "ܐܚܙܝܐ",
            dt1: "Abasja",
            sy5: "ܐܰܚܰܙܝܰܐ",
            sy9: "",
            id: "58",
            lt11: "ܐܚܙܝܐ"
        },
        {
            sy5: "ܨܶܗܝܽܘܢ",
            sy7: "",
            lt12: "",
            sy8: "",
            id: "5333",
            dt2: "",
            lt11: "",
            sy9: "",
            sy4: "ܨܗܝܘܢ",
            lt10: "ŝehyun",
            dt3: "",
            dt1: "Zion",
            sy6: ""
        },
        {
            lt10: "ĥasho",
            sy8: "ܟܡܝܪܘܬܐ",
            sy5: "ܚܰܫܳܐ",
            lt12: "kmirutho",
            dt2: "Kummer",
            lt11: "abilutho",
            dt3: "",
            dt1: "Trauer",
            sy4: "ܚܫܐ",
            sy6: "ܐܒܝܠܘܬܐ",
            sy7: "ܐܰܒܺܝܠܽܘܬ݂ܳܐ",
            id: "4546",
            sy9: "ܟܡܺܝܪܽܘܬ݂ܳܐ"
        },
        {
            sy4: "ܐܦܨܚ",
            sy9: "",
            lt10: "afsaĥ",
            sy5: "ܐܰܦܨܰܚ",
            sy7: "",
            sy8: "",
            lt11: "",
            lt12: "",
            dt1: "freuen",
            sy6: "",
            id: "1391",
            dt2: "",
            dt3: ""
        },
        {
            lt11: "suryoye mshiĥoye",
            id: "4342",
            sy9: "",
            dt3: "",
            lt10: "oromoye",
            lt12: "",
            sy6: "ܣܘܪܝܝܐ ܡܫܝܚܝܐ",
            dt2: "",
            sy7: "ܣܽܘܪܝܳܝ̈ܶܐ ܡܫܺܝܚܳܝ̈ܶܐ",
            sy8: "",
            sy5: "ܐܪܳܡܳܝ̈ܶܐ",
            sy4: "ܐܪܡܝܐ",
            dt1: "Syrische Christen"
        },
        {
            sy7: "",
            lt11: "",
            dt2: "",
            lt12: "",
            sy6: "",
            sy5: "ܛܰܒܳܠܳܐ",
            sy4: "ܛܒܠܐ",
            sy8: "",
            sy9: "",
            dt3: "",
            lt10: "țabolo",
            dt1: "Trommler",
            id: "4576"
        },
        {
            sy5: "ܐܶܬ݂ܚܒܰܢܰܢ",
            sy6: "",
            lt10: "ethĥbanan",
            dt3: "",
            sy8: "",
            lt11: "",
            dt2: "",
            lt12: "",
            id: "2155",
            dt1: "ignorieren",
            sy7: "",
            sy4: "ܐܬܚܒܢܢ",
            sy9: ""
        },
        {
            lt11: "yaĉno",
            sy5: "ܩܽܘܨܦܳܐ",
            dt1: "Geizhals",
            sy7: "ܝܰܥܢܳܐ",
            lt10: "quŝfo",
            sy8: "",
            sy4: "ܩܘܨܦܐ",
            sy6: "ܝܥܢܐ",
            sy9: "",
            id: "1541",
            dt2: "Geiz",
            dt3: "Habsüchtiger",
            lt12: ""
        },
        {
            sy9: "",
            lt11: "",
            sy6: "",
            sy4: "ܒܛܠ ܥܠ ܕܥܒܪ ܙܒܢܗ",
            dt1: "verjähren",
            lt10: "bțel ĉal daĉbar zabne",
            dt2: "",
            lt12: "",
            id: "4747",
            sy8: "",
            sy7: "",
            sy5: "ܒܛܶܠ ܥܰܠ ܕܰܥܒܰܪ ܙܰܒܢܶܗ",
            dt3: ""
        },
        {
            sy6: "",
            lt11: "ܐܒܪܡ",
            sy5: "ܐܰܒܪܳܡ",
            sy4: "ܐܒܪܡ",
            sy9: "",
            lt10: "ܐܒܪܡ",
            lt12: "",
            dt1: "Abrom",
            sy8: "",
            dt2: "",
            dt3: "",
            id: "111",
            sy7: ""
        },
        {
            sy8: "",
            sy9: "",
            sy4: "ܬܝܳܒܘܬ̣ܳܐ",
            dt2: "Bußbereitschaft",
            id: "5418",
            sy5: "ܬܝܳܒܘܬ̣ܳܐ",
            lt11: "",
            sy6: "",
            sy7: "",
            dt1: "Bekehrung",
            lt10: "tyobutho",
            dt3: "",
            lt12: ""
        },
        {
            id: "1524",
            sy9: "",
            sy6: "ܡܩܒܠ",
            dt2: "",
            dt1: "gegenüber",
            sy7: "ܡܩܰܒܶܠ",
            lt10: "lqublo",
            sy8: "",
            sy4: "ܠܩܘܒܠܐ",
            lt11: "mqabel",
            lt12: "",
            sy5: "ܠܩܽܘܒܠܳܐ",
            dt3: ""
        },
        {
            sy8: "",
            sy9: "",
            lt10: "ĥđo zbato",
            sy6: "",
            sy7: "",
            sy4: "ܚܕܐ ܙܒܬܐ",
            sy5: "ܚܕܳܐ ܙܒܰܬܳܐ",
            lt11: "",
            dt2: "",
            dt3: "",
            lt12: "",
            dt1: "einmal",
            id: "1045"
        },
        {
            id: "6086",
            sy8: "",
            lt10: "mshaushțo",
            sy9: "",
            sy5: "ܡܫܰܘܫܛܳܐ",
            dt3: "",
            lt11: "",
            sy4: "ܡܫܘܫܛܐ",
            dt2: "weiterentwickelt",
            sy6: "",
            lt12: "",
            sy7: "",
            dt1: "fortgeschritten"
        },
        {
            sy6: "ܓܕܠܐ",
            lt10: "gđulo",
            id: "1519",
            sy5: "ܓܕܽܘܠܳܐ",
            sy8: "ܩܥܝܠܬܐ",
            sy4: "ܓܕܘܠܐ",
            lt11: "gđolo",
            dt3: "",
            dt2: "Zopf",
            dt1: "Geflecht",
            sy9: "ܩܥܺܝܠܬܳܐ",
            sy7: "ܓܕܳܠܳܐ",
            lt12: "qĉilto"
        },
        {
            lt11: "mshamhutho",
            dt3: "",
            sy9: "",
            sy4: "ܫܘܡܗܐ",
            dt2: "Image",
            sy8: "",
            id: "5975",
            lt10: "shumoho",
            lt12: "",
            sy5: "ܫܽܘܡܳܗܳܐ",
            sy7: "ܡܫܰܡܗܽܘܬ݂ܳܐ",
            sy6: "ܡܫܡܗܘܬܐ",
            dt1: "Ansehen"
        },
        {
            dt1: "Androhung",
            sy4: "ܕܠܘܚܝܐ",
            dt2: "Drohung",
            id: "5942",
            lt12: "",
            dt3: "",
            sy9: "",
            sy8: "",
            lt11: "",
            sy7: "",
            lt10: "dluĥyo",
            sy5: "ܕܠܽܘܚܝܳܐ",
            sy6: ""
        },
        {
            sy8: "",
            sy5: "ܩܰܫܺܝܫܳܐ ܕܰܐܒܳܐ",
            sy6: "ܩܫܐ ܕܐܒܐ",
            dt2: "",
            sy9: "",
            lt12: "",
            dt3: "",
            id: "4682",
            lt10: "qashisho d`abo",
            dt1: "Urgroßvater",
            sy4: "ܩܫܝܫܐ ܕܐܒܐ",
            sy7: "ܩܰܫܳܐ ܕܰܐܒܳܐ",
            lt11: "qasho d`abo"
        },
        {
            lt11: "",
            dt1: "Alant",
            sy8: "",
            sy5: "ܦܶܠܓܺܝܢܳܐ",
            sy4: "ܦܠܓܝܢܐ",
            lt12: "",
            dt3: "",
            sy7: "",
            sy9: "",
            dt2: "",
            lt10: "felgino",
            id: "5814",
            sy6: ""
        },
        {
            sy8: "",
            dt2: "",
            dt3: "",
            sy6: "ܦܨܝܚܐ",
            dt1: "zufrieden",
            lt10: "ĥadyo",
            sy5: "ܚܰܕܝܳܐ",
            lt11: "fŝiĥo",
            sy9: "",
            sy7: "ܦܨܺܝܚܳܐ",
            sy4: "ܚܕܝܐ",
            id: "5360",
            lt12: ""
        },
        {
            sy5: "ܨܽܘܢܳܥܳܐ ܕܙܰܝܢܳܐ",
            sy7: "",
            lt10: "ŝunoĉo d`zayno",
            lt12: "",
            sy4: "ܨܘܢܥܐ ܕܙܝܢܐ",
            lt11: "",
            dt2: "",
            sy6: "",
            id: "4930",
            dt1: "Waffenindustrie",
            sy9: "",
            sy8: "",
            dt3: ""
        },
        {
            sy9: "",
            id: "1306",
            sy6: "",
            dt2: "",
            sy7: "",
            sy5: "ܪܰܕܳܝܬܳܐ ܕܝܽܘܩܕܳܢܳܐ",
            lt12: "",
            dt1: "Feuerwehrauto",
            dt3: "",
            sy4: "ܪܕܝܬܐ ܕܝܘܩܕܢܐ",
            lt10: "rađoyto d`yuqđono",
            lt11: "",
            sy8: ""
        },
        {
            sy8: "",
            sy7: "",
            lt12: "",
            id: "3947",
            sy9: "",
            dt3: "",
            sy6: "",
            lt11: "",
            dt2: "Schiesser",
            lt10: "shoduyo",
            sy4: "ܫܕܘܝܐ",
            dt1: "Schießer",
            sy5: "ܫܳܕܽܘܝܳܐ"
        },
        {
            sy7: "",
            sy8: "",
            lt12: "",
            sy6: "",
            dt3: "",
            sy4: "ܠ",
            lt10: "lomad",
            id: "2685",
            lt11: "",
            sy9: "",
            dt1: "l",
            sy5: "ܠ",
            dt2: ""
        },
        {
            lt11: "",
            lt10: "ĉboro d`urĥ qțoro",
            sy9: "",
            sy8: "",
            dt2: "",
            lt12: "",
            sy4: "ܥܒܪܐ ܕܐܘܪܚ ܩܛܪܐ",
            sy7: "",
            dt3: "",
            sy6: "",
            sy5: "ܥܒܳܪܳܐ ܕܐܽܘܪܚ ܩܛܳܪܳܐ",
            dt1: "Eisenbahnverkehr",
            id: "6399"
        },
        {
            sy5: "ܡܶܬ݂ܢܰܩܡܳܢܽܘܬ݂ܳܐ",
            id: "3588",
            dt3: "",
            lt10: "methnaqmonutho",
            sy8: "",
            dt1: "Racheakt",
            sy7: "",
            sy4: "ܡܬܢܩܡܢܘܬܐ",
            lt12: "",
            sy9: "",
            lt11: "",
            sy6: "",
            dt2: ""
        },
        {
            lt12: "",
            sy7: "",
            sy6: "",
            sy9: "",
            dt2: "Kinderwagen",
            dt1: "Wagen",
            sy5: "ܡܰܪܟܰܒܬ݂ܳܐ",
            lt11: "",
            lt10: "markabtho",
            id: "4932",
            dt3: "Stubenwagen",
            sy8: "",
            sy4: "ܡܪܟܒܬܐ"
        },
        {
            lt12: "basubrino",
            sy7: "ܒܰܣܺܒܪܺܝܢܳܐ",
            dt3: "Basibrin",
            sy8: "ܒܣܘܒܪܝܢܐ",
            lt10: "qritho basibrino",
            sy4: "ܩܪܝܬܐ ܕܒܣܒܪܝܢܐ",
            dt1: "Dorf Bsorino",
            dt2: "Bsorino",
            sy6: "ܒܣܒܪܝܢܐ",
            id: "929",
            lt11: "basibrino",
            sy5: "ܩܪܺܝܬ݂ܳܐ ܕܒܰܣܒܪܺܝܢܳܐ",
            sy9: "ܒܰܣܽܘܒܪܺܝܢܳܐ"
        },
        {
            dt1: "akademisch",
            id: "5799",
            sy8: "",
            lt11: "akademikoyo",
            lt10: "methkathbono",
            sy7: "ܐܰܟܰܕܶܡܺܝܟܳܝܳܐ",
            lt12: "",
            sy4: "ܡܬܟܬܒܢܐ",
            sy6: "ܐܟܕܡܝܟܝܐ",
            sy5: "ܡܶܬ݂ܟܰܬ݂ܒܳܢܳܐ",
            dt3: "",
            sy9: "",
            dt2: ""
        },
        {
            sy6: "ܪܟܒ",
            lt10: "rkheb",
            lt12: "",
            sy9: "",
            dt3: "",
            sy4: "ܪܟܒ",
            dt2: "",
            sy7: "ܪܳܟ݂ܶܒ",
            id: "3681",
            sy8: "",
            dt1: "reiten",
            sy5: "ܪܟ݂ܶܒ",
            lt11: "rokheb"
        },
        {
            sy4: "ܓܪܡ ܦܝܠܐ",
            lt10: "grem filo",
            sy7: "",
            dt2: "Elefantenbein",
            sy5: "ܓܪܶܡ ܦܺܝܠܳܐ",
            id: "1067",
            lt12: "",
            dt1: "Elefantenknochen",
            sy6: "",
            sy9: "",
            dt3: "",
            sy8: "",
            lt11: ""
        },
        {
            lt11: "",
            dt3: "",
            sy7: "",
            sy9: "",
            dt1: "trennen",
            sy6: "",
            lt10: "frash",
            id: "4557",
            sy4: "ܦܪܫ",
            lt12: "",
            sy8: "",
            dt2: "separieren",
            sy5: "ܦܪܰܫ"
        },
        {
            dt1: "Zeche",
            sy6: "",
            sy7: "",
            lt12: "",
            sy4: "ܢܦܩܐ",
            lt10: "nefqo",
            dt2: "",
            sy8: "",
            dt3: "",
            sy9: "",
            id: "6268",
            sy5: "ܢܶܦܩܳܐ",
            lt11: ""
        },
        {
            dt1: "Ohrläppchen",
            sy4: "ܛܪܦܐ ܕܐܕܢܐ",
            dt2: "",
            lt10: "țarfo d`eđno",
            sy5: "ܛܰܪܦܳܐ ܕܐܶܕܢܳܐ",
            lt12: "",
            sy7: "",
            sy6: "",
            lt11: "",
            dt3: "",
            sy8: "",
            id: "3362",
            sy9: ""
        },
        {
            sy7: "",
            lt12: "",
            lt11: "ܣܘܠܩܐ",
            dt2: "",
            dt3: "",
            sy8: "",
            id: "4311",
            sy9: "",
            lt10: "ܣܘܠܩܐ",
            sy6: "",
            sy5: "ܣܽܘܠܳܩܳܐ",
            sy4: "ܣܘܠܩܐ",
            dt1: "Suloqo"
        },
        {
            lt12: "",
            sy6: "",
            sy5: "ܩܰܠܶܣ",
            sy7: "",
            sy9: "",
            dt2: "",
            lt10: "qales",
            lt11: "",
            sy4: "ܩܠܣ",
            dt1: "loben",
            sy8: "",
            dt3: "",
            id: "2848"
        },
        {
            sy9: "ܐܺܝܢܰܩܰܐ",
            lt12: "inaqa",
            lt11: "bazban",
            dt3: "wenn",
            id: "4953",
            sy8: "ܐܝܢܩܐ",
            sy6: "ܒܙܒܢ",
            lt10: "kad",
            sy4: "ܟܕ",
            sy5: "ܟܰܕ",
            dt2: "währenddessen",
            dt1: "während",
            sy7: "ܒܰܙܒܰܢ"
        },
        {
            lt12: "",
            sy7: "ܟܽܘܣܺܝܬ݂ܳܐ",
            dt1: "Hut",
            lt10: "qubĉo",
            sy6: "ܟܘܣܝܬܐ",
            sy5: "ܩܽܘܒܥܳܐ",
            dt2: "Mütze",
            sy4: "ܩܘܒܥܐ",
            lt11: "kusitho",
            sy8: "",
            sy9: "",
            dt3: "",
            id: "2082"
        },
        {
            sy7: "",
            sy4: "ܥܩܪܒܐ",
            dt3: "",
            lt10: "ĉeqarbo",
            dt1: "Skorpion",
            lt12: "",
            sy6: "",
            dt2: "",
            sy8: "",
            sy9: "",
            id: "6381",
            lt11: "",
            sy5: "ܥܶܩܰܪܒܳܐ"
        },
        {
            sy4: "ܗܕܕܡܠܟܐ",
            sy7: "ܚܰܕܰܕܡܰܠܟܳܐ",
            sy6: "ܚܕܕܡܠܟܐ",
            dt3: "",
            lt10: "ܗܕܕܡܠܟܐ",
            lt12: "",
            dt1: "Hadadmalko",
            sy8: "",
            dt2: "",
            sy5: "ܗܰܕܰܕܡܰܠܟܳܐ",
            id: "1806",
            lt11: "ܗܕܕܡܠܟܐ",
            sy9: ""
        },
        {
            id: "2526",
            lt11: "mafuĥitho",
            sy7: "ܡܰܦܽܘܚܺܝܬ̣ܳܐ",
            sy6: "ܡܰܦܽܘܚܺܝܬ̣ܳܐ",
            dt1: "Klimaanlage",
            lt10: "mayknono",
            dt3: "",
            lt12: "",
            sy9: "",
            dt2: "",
            sy4: "ܡܝܟܢܢܐ",
            sy5: "ܡܰܝܟܢܳܢܳܐ",
            sy8: ""
        },
        {
            lt11: "kbido",
            lt10: "kabđono",
            sy9: "ܙܥܺܝܦܳܐ",
            sy4: "ܟܒܕܢܐ",
            sy6: "ܟܒܝܕܐ",
            id: "6303",
            dt1: "zornig",
            sy5: "ܟܰܒܕܳܢܳܐ",
            lt12: "zĉifo",
            sy8: "ܙܥܝܦܐ",
            sy7: "ܟܒܺܝܕܳܐ",
            dt3: "",
            dt2: ""
        },
        {
            sy5: "ܬܪܶܥܣܰܪ",
            sy7: "ܬܰܪܬܰܥܶܣ̈ܪܶܐ",
            id: "13",
            dt3: "",
            dt2: "Zwölf",
            lt12: "juth-beth",
            dt1: "12",
            sy4: "ܬܪܥܣܪ",
            sy6: "ܬܪܬܥܣܪܐ",
            lt10: "treĉsar",
            lt11: "tartaĉesre",
            sy9: "ܝܒ",
            sy8: "ܝܒ"
        },
        {
            lt11: "",
            dt3: "",
            lt10: "eno",
            sy7: "",
            dt1: "Ich",
            sy6: "",
            sy8: "",
            lt12: "",
            dt2: "",
            sy4: "ܐܢܐ",
            sy9: "",
            sy5: "ܐܶܢܳܐ",
            id: "2088"
        },
        {
            sy4: "ܓܚܪ",
            sy6: "",
            lt11: "",
            sy8: "",
            lt12: "",
            dt3: "",
            sy7: "",
            sy5: "ܓܚܰܪ",
            id: "6225",
            sy9: "",
            lt10: "gĥar",
            dt1: "spucken",
            dt2: ""
        },
        {
            dt2: "",
            lt12: "",
            sy7: "",
            sy9: "",
            sy5: "ܫܒܳܛ",
            dt3: "",
            dt1: "Februar",
            sy8: "",
            lt11: "",
            sy4: "ܫܒܛ",
            sy6: "",
            lt10: "shboț",
            id: "1272"
        },
        {
            lt12: "",
            sy6: "",
            sy8: "",
            lt11: "ܐܘܡܬ݂ܐ",
            sy5: "ܐܽܘܡܬ݂ܰܐ",
            sy7: "",
            id: "4631",
            sy4: "ܐܘܡܬ݂ܐ",
            dt1: "Umtha",
            dt3: "",
            sy9: "",
            lt10: "ܐܘܡܬ݂ܐ",
            dt2: ""
        },
        {
            lt12: "",
            dt2: "",
            sy6: "",
            sy5: "ܬܽܘܟ݂ܠܳܢܳܐ ܕܥܰܠ ܐܰܠܳܗܳܐ",
            lt11: "",
            dt3: "",
            sy7: "",
            sy4: "ܬܘܟܠܢܐ ܕܥܠ ܐܠܗܐ",
            dt1: "Gottvertrauen ",
            lt10: "tukhlono dĉal aloho",
            sy9: "",
            id: "1714",
            sy8: ""
        },
        {
            sy8: "ܩܡܨܐ",
            lt10: "ŝeŝro",
            dt3: "",
            sy7: "ܨܰܪܨܽܘܪܳܐ",
            sy4: "ܨܨܪܐ",
            dt2: "Heuschrecke",
            sy9: "ܩܰܡܨܳܐ",
            id: "1732",
            sy6: "ܨܪܨܘܪܐ",
            lt11: "ŝarŝuro",
            dt1: "Grille",
            sy5: "ܨܶܨܪܳܐ",
            lt12: "qamŝo"
        },
        {
            sy9: "",
            lt12: "",
            sy8: "",
            sy5: "ܫܳܝܽܘܛܽܘܬ݂ܳܐ",
            lt11: "",
            sy7: "",
            id: "6061",
            dt3: "",
            lt10: "shoyuțutho",
            sy6: "",
            sy4: "ܫܝܘܛܘܬܐ",
            dt2: "",
            dt1: "Herabsetzen"
        },
        {
            id: "927",
            sy8: "",
            lt12: "",
            sy5: "ܩܪܺܝܬ݂ܳܐ ܕܒܶܝܬ݂ ܩܽܘܣܛܰܢ",
            sy4: "ܩܪܝܬܐ ܕܒܝܬ ܩܘܣܛܢ",
            dt1: "Dorf Beth Kustan",
            sy6: "ܒܝܬ ܩܘܣܛܢ",
            sy9: "",
            dt2: "Beth Kustan",
            lt11: "beth qusțan",
            sy7: "ܒܶܝܬ݂ ܩܽܘܣܛܰܢ",
            dt3: "",
            lt10: "qritho d`beth qusțan"
        },
        {
            lt12: "",
            dt2: "aussagen",
            dt3: "Zeugnis ablegen",
            sy5: "ܣܗܶܕ",
            sy8: "",
            dt1: "bezeugen",
            sy4: "ܣܗܕ",
            sy9: "",
            sy7: "",
            lt10: "shed",
            id: "609",
            lt11: "",
            sy6: ""
        },
        {
            lt10: "geldo baroyo",
            sy8: "",
            sy5: "ܓܶܠܕܳܐ ܒܰܪܳܝܳܐ",
            sy9: "",
            lt12: "",
            sy6: "",
            id: "385",
            lt11: "",
            dt2: "Vorderhaut",
            dt3: "",
            sy4: "ܓܠܕܐ ܒܪܝܐ",
            dt1: "Außenhaut",
            sy7: ""
        },
        {
            sy7: "ܡܕܰܠܶܩ ܐ̱ܢܳܐ ܠܢܽܘܗܪܳܐ",
            dt2: "",
            lt11: "madleq no l`nuhro",
            sy4: "ܡܕܠܩ ܐܢܐ ܒܗܪܐ",
            dt3: "",
            id: "2131",
            lt10: "madleq no bahro",
            sy6: "ܡܕܠܩ ܐܢܐ ܠܢܘܗܪܐ",
            lt12: "",
            dt1: "Ich öffne das Licht",
            sy9: "",
            sy5: "ܡܰܕܠܶܩ ܐ̱ܢܳܐ ܒܰܗܪܳܐ",
            sy8: ""
        },
        {
            lt11: "",
            sy4: "ܚܝܟܐ",
            id: "2638",
            lt10: "ĥyoko",
            sy6: "",
            sy7: "",
            sy8: "",
            dt3: "",
            sy5: "ܚܝܳܟܳܐ",
            dt2: "",
            dt1: "Kratzen",
            lt12: "",
            sy9: ""
        },
        {
            lt11: "ܟܪܝܢܐ",
            sy7: "",
            sy8: "",
            dt2: "",
            sy4: "ܟܪܝܢܐ",
            sy6: "",
            lt10: "ܟܪܝܢܐ",
            sy5: "ܟܰܪܺܝܢܰܐ",
            sy9: "",
            dt1: "Karina",
            lt12: "",
            id: "2415",
            dt3: ""
        },
        {
            sy7: "",
            sy8: "",
            sy5: "ܡܰܠܳܠܰܐ",
            id: "3652",
            dt3: "Ansager",
            dt1: "Redner",
            lt11: "",
            dt2: "Sprecher",
            sy6: "",
            lt10: "malolo",
            sy9: "",
            sy4: "ܡܠܠܐ",
            lt12: ""
        },
        {
            sy5: "ܕܳܢ",
            dt1: "aburteilen",
            sy8: "",
            dt2: "",
            dt3: "",
            sy7: "",
            id: "5691",
            lt11: "",
            lt10: "don",
            sy9: "",
            sy6: "",
            lt12: "",
            sy4: "ܕܢ"
        },
        {
            sy6: "",
            lt12: "",
            sy9: "",
            dt1: "Thrombose",
            dt2: "Trombose",
            sy5: "ܫܽܠܬܳܐ",
            sy7: "",
            sy4: "ܫܠܬܐ",
            lt10: "shelto",
            lt11: "",
            sy8: "",
            dt3: "",
            id: "4469"
        },
        {
            lt10: "manhronuth shqoqo",
            dt2: "",
            sy6: "",
            id: "6326",
            dt1: "Straßenbeleuchtung",
            sy8: "",
            lt12: "",
            sy4: "ܡܢܗܪܢܘܬ ܫܩܩܐ",
            sy5: "ܡܰܢܗܪܳܢܽܘܬ݂ ܫܩܳܩܳܐ",
            lt11: "",
            dt3: "",
            sy9: "",
            sy7: ""
        },
        {
            sy9: "",
            lt12: "",
            sy5: "ܫܠܺܝܚܳܝܳܐ",
            sy7: "",
            dt3: "",
            lt11: "",
            id: "6014",
            sy6: "",
            lt10: "shliĥoyo",
            dt2: "",
            dt1: "apostolisch",
            sy4: "ܫܠܝܚܝܐ",
            sy8: ""
        },
        {
            dt3: "",
            sy7: "",
            dt2: "Abwehr",
            sy5: "ܣܽܘܬܳܪܳܐ",
            lt12: "",
            sy8: "",
            id: "4816",
            dt1: "Verteidigung",
            lt11: "",
            sy4: "ܣܘܬܪܐ",
            sy6: "",
            lt10: "sutoro",
            sy9: ""
        },
        {
            dt2: "",
            lt12: "",
            sy9: "",
            sy4: "ܢܝܢܘܣ",
            lt11: "ܢܝܢܘܣ",
            lt10: "ܢܝܢܘܣ",
            sy6: "",
            sy7: "",
            dt1: "Ninos",
            dt3: "",
            sy5: "ܢܺܝܢܳܘܣ",
            sy8: "",
            id: "3282"
        },
        {
            lt11: "",
            lt10: "ebro",
            sy7: "",
            sy6: "",
            lt12: "",
            dt1: "Feder",
            dt3: "",
            sy5: "ܐܶܒܪܳܐ",
            sy8: "",
            sy9: "",
            dt2: "",
            sy4: "ܐܒܪܐ",
            id: "1273"
        },
        {
            dt1: "Nervosität",
            sy5: "ܓܝܳܕܳܢܳܝܽܘܬ݂ܳܐ",
            lt12: "",
            sy9: "",
            dt3: "",
            sy8: "",
            sy4: "ܓܝܕܢܝܘܬܐ",
            lt10: "gyodonoyutho",
            dt2: "",
            id: "3246",
            sy6: "",
            sy7: "",
            lt11: ""
        },
        {
            sy7: "ܓܶܕܪܳܝܬܳܐ",
            lt12: "nuno ĥewyoyo",
            lt11: "gedroyto",
            sy8: "ܢܘܢܐ ܚܘܝܝܐ",
            id: "5543",
            sy9: "ܢܽܘܢܳܐ ܚܶܘܝܳܝܳܐ",
            sy6: "ܓܕܪܝܬܐ",
            sy5: "ܓܪܳܕܬ݂ܳܐ",
            lt10: "grodtho",
            dt1: "Aal",
            dt2: "",
            sy4: "ܓܪܕܬܐ",
            dt3: ""
        },
        {
            sy7: "",
            dt3: "",
            sy8: "",
            id: "1435",
            dt1: "Fürstentum",
            sy5: "ܐܰܡܺܝܪܽܘܬ݂ܳܐ",
            sy6: "",
            sy4: "ܐܡܝܪܘܬܐ",
            lt10: "amirutho",
            dt2: "",
            lt11: "",
            sy9: "",
            lt12: ""
        },
        {
            sy7: "",
            dt3: "",
            lt12: "",
            sy9: "",
            dt2: "",
            sy8: "",
            dt1: "Kinutha",
            sy4: "ܟܐܢܘܬܐ",
            id: "2487",
            lt11: "ܟܐܢܘܬܐ",
            lt10: "ܟܐܢܘܬܐ",
            sy5: "ܟܺܐܢܽܘܬܳܐ",
            sy6: ""
        },
        {
            sy5: "ܡܫܰܥܒܳܢܳܐ",
            lt12: "",
            sy6: "",
            lt11: "",
            sy9: "",
            sy7: "",
            dt1: "Kolonist",
            dt2: "",
            dt3: "",
            sy8: "",
            sy4: "ܡܫܥܒܢܐ",
            id: "2558",
            lt10: "mshaĉbono"
        },
        {
            sy9: "ܙܶܕܰܩܬ݂ܳܐ",
            sy7: "ܙܶܕܩܳܐ",
            lt10: "zudoqo",
            dt2: "",
            sy6: "ܙܕܩܐ",
            sy8: "ܙܕܩܬܐ",
            dt1: "Almosen",
            sy4: "ܙܘܕܩܐ",
            lt11: "zedqo",
            id: "5851",
            lt12: "zedaqtho",
            dt3: "",
            sy5: "ܙܽܘܕܳܩܳܐ"
        },
        {
            id: "2158",
            lt11: "atun",
            dt2: "",
            dt1: "Ihr",
            sy8: "",
            lt12: "",
            sy9: "",
            sy6: "ܐܢܬܘܢ",
            sy7: "ܐܰܢ̱ܬܽܘܢ",
            sy4: "ܐܢܬܝܢ",
            lt10: "aten",
            dt3: "",
            sy5: "ܐܰܢ̱ܬܶܝܢ"
        },
        {
            lt10: "ܐܢܛܘܢ",
            sy9: "",
            dt3: "",
            sy8: "",
            dt2: "",
            sy5: "ܐܰܢܛܽܘܢ",
            dt1: "Anton",
            sy6: "",
            lt11: "ܐܢܛܘܢ",
            id: "251",
            sy7: "",
            lt12: "",
            sy4: "ܐܢܛܘܢ"
        },
        {
            dt3: "",
            sy6: "ܫܩܩܐ",
            dt1: "Straße",
            sy8: "ܕܪܒܐ",
            sy9: "ܕܰܪܒܳܐ",
            sy5: "ܐܽܘܪܚܳܐ",
            lt12: "darbo",
            dt2: "Weg",
            lt11: "shqoqo",
            sy7: "ܫܩܳܩܳܐ",
            sy4: "ܐܘܪܚܐ",
            lt10: "urĥo",
            id: "4290"
        },
        {
            id: "6454",
            sy9: "",
            sy5: "ܗܰܬ ܡܰܝܟܐ ܟܐܬ݂ܰܬ",
            sy8: "",
            sy6: "",
            dt1: "Woher kommst du?",
            sy7: "",
            lt10: "Hat mayko koṯat?",
            dt2: "",
            lt12: "",
            lt11: "",
            dt3: "",
            sy4: "ܗܬ ܡܝܟܐ ܟܐܬܬ"
        },
        {
            sy9: "",
            sy7: "",
            sy5: "ܚܰܙܽܘܪ ܐܰܦܶܐ",
            lt11: "",
            sy6: "",
            dt3: "",
            sy8: "",
            id: "4980",
            lt10: "ĥazur afe",
            sy4: "ܚܙܘܪ ܐܦܐ",
            lt12: "",
            dt2: "Backe",
            dt1: "Wange"
        },
        {
            sy4: "ܓܪܫ",
            dt1: "ziehen",
            dt2: "",
            sy9: "",
            lt10: "grash",
            sy7: "ܓܳܪܶܫ",
            id: "5318",
            lt11: "goresh",
            sy8: "",
            dt3: "",
            sy6: "ܓܪܫ",
            sy5: "ܓܪܰܫ",
            lt12: ""
        },
        {
            sy4: "ܬܒܪ ܓܠܠܐ",
            sy7: "",
            sy6: "",
            id: "5044",
            sy8: "",
            dt1: "Wellenbrecher",
            sy5: "ܬܳܒܰܪ ܓܰܠܠܳܐ",
            sy9: "",
            lt10: "tobar galo",
            lt12: "",
            dt3: "",
            lt11: "",
            dt2: ""
        },
        {
            lt12: "",
            dt2: "",
            id: "607",
            lt10: "ethefraĉ",
            sy7: "",
            sy9: "",
            sy6: "",
            dt3: "",
            sy8: "",
            lt11: "",
            sy5: "ܐܶܬ݂ܦܪܰܥ",
            dt1: "bezahlt",
            sy4: "ܐܬܦܪܥ"
        },
        {
            dt3: "",
            sy7: "",
            dt2: "",
            sy4: "ܠܝܕܝܐ",
            sy5: "ܠܺܝܕܺܝܰܐ",
            dt1: "Lydia",
            sy6: "",
            id: "2878",
            lt12: "",
            lt10: "ܠܝܕܝܐ",
            lt11: "ܠܝܕܝܐ",
            sy8: "",
            sy9: ""
        },
        {
            sy8: "",
            sy4: "ܒܝܢܬ",
            lt10: "beynoth",
            sy7: "",
            sy6: "",
            sy9: "",
            lt12: "",
            dt1: "zwischen",
            dt2: "dazwischen",
            dt3: "inter",
            sy5: "ܒܶܝܢܳܬ݂",
            lt11: "",
            id: "5409"
        },
        {
            lt10: "ܗܕܝܘܬܐ",
            dt3: "",
            sy4: "ܗܕܝܘܬܐ",
            sy5: "ܗܰܕܝܽܘܬܰܐ",
            sy9: "",
            lt12: "",
            sy6: "",
            sy8: "",
            dt2: "",
            sy7: "",
            id: "1812",
            dt1: "Hadoyuta",
            lt11: "ܗܕܝܘܬܐ"
        },
        {
            dt2: "Tennisschläger",
            lt12: "",
            lt10: "țafțofo",
            dt3: "",
            lt11: "",
            sy7: "",
            sy6: "",
            sy8: "",
            id: "4500",
            sy5: "ܛܰܦܛܳܦܳܐ",
            dt1: "Tischtennisschläger",
            sy4: "ܛܦܛܦܐ",
            sy9: ""
        },
        {
            dt3: "",
            sy5: "ܡܰܠܦܳܢܺܝܬ݂ܳܐ",
            sy6: "",
            lt10: "malfonitho",
            sy7: "",
            sy4: "ܡܠܦܢܝܬܐ",
            lt12: "",
            dt2: "",
            id: "2741",
            sy9: "",
            sy8: "",
            lt11: "",
            dt1: "Lehrerin"
        },
        {
            sy5: "ܥܒܰܕ ܡܰܫܪܝܳܐ",
            sy9: "",
            dt2: "",
            lt11: "",
            sy8: "",
            lt10: "ĉbad mashryo",
            id: "5296",
            sy7: "",
            dt3: "",
            dt1: "zelten",
            sy4: "ܥܒܕ ܡܫܪܝܐ",
            lt12: "",
            sy6: ""
        },
        {
            lt12: "qadesh",
            dt2: "",
            id: "4093",
            lt10: "barekh",
            sy6: "ܡܒܪܟ",
            lt11: "mbarekh",
            sy8: "ܩܕܫ",
            sy7: "ܡܒܰܪܶܟ݂",
            dt3: "",
            dt1: "segnen",
            sy5: "ܒܰܪܶܟ",
            sy4: "ܒܪܟ",
            sy9: "ܩܰܕܶܫ"
        },
        {
            lt12: "",
            sy5: "ܗܰܠܟ݂ܳܐ",
            id: "2718",
            dt1: "Laufen",
            lt10: "halkho",
            sy8: "",
            lt11: "rahțo",
            sy9: "",
            dt2: "",
            sy7: "ܪܰܗܛܳܐ",
            sy4: "ܗܠܟܐ",
            sy6: "ܪܗܛܐ",
            dt3: ""
        },
        {
            id: "2582",
            dt1: "Konkurrenz",
            sy4: "ܡܡܪܝܢܘܬܐ",
            dt2: "",
            sy9: "ܕܰܠܩܽܘܒܠܳܝܳܐ",
            sy8: "ܕܠܩܘܒܠܝܐ",
            sy7: "ܣܰܩܽܘܒܠܳܝܽܘܬ݂ܳܐ",
            lt12: "dalqubloyo",
            lt11: "saqubloyutho",
            sy6: "ܣܩܘܒܠܝܘܬܐ",
            sy5: "ܡܡܰܪܝܳܢܽܘܬ݂ܳܐ",
            dt3: "",
            lt10: "mmaryonutho"
        },
        {
            sy6: "",
            id: "2115",
            lt10: "kafino no",
            dt3: "",
            sy7: "",
            lt11: "",
            lt12: "",
            sy9: "",
            sy8: "",
            sy4: "ܟܦܝܢܐ ܐܢܐ",
            dt1: "Ich habe Hunger",
            dt2: "",
            sy5: "ܟܦܝܢܐ ܐ̱ܢܳܐ"
        },
        {
            sy6: "",
            lt12: "",
            lt10: "nagiro",
            sy5: "ܢܰܓܺܝܪܳܐ",
            dt1: "langwierig",
            lt11: "",
            sy9: "",
            dt3: "",
            id: "2712",
            sy7: "",
            dt2: "andauernd",
            sy8: "",
            sy4: "ܢܓܝܪܐ"
        },
        {
            lt12: "",
            dt3: "",
            dt1: "kombinieren",
            sy6: "",
            lt11: "",
            sy8: "",
            dt2: "",
            sy4: "ܚܝܕ",
            id: "2561",
            sy7: "",
            lt10: "ĥayed",
            sy5: "ܚܰܝܶܕ",
            sy9: ""
        },
        {
            dt1: "reinigen",
            id: "3675",
            lt12: "",
            sy7: "ܕܰܟ݂ܺܝ",
            dt3: "",
            sy5: "ܟܰܦܰܪ",
            sy4: "ܟܦܪ",
            sy9: "",
            lt11: "dakhi",
            lt10: "kafar",
            dt2: "säubern",
            sy8: "",
            sy6: "ܕܟܝ"
        },
        {
            sy8: "",
            dt2: "",
            dt3: "",
            lt12: "",
            dt1: "für",
            sy7: "ܡܶܛܽܠ",
            id: "1426",
            sy5: "ܠܰܔܰܢ",
            lt10: "ladgan",
            sy6: "ܡܛܠ",
            sy9: "",
            lt11: "mețul",
            sy4: "ܠܓܢ"
        },
        {
            sy5: "ܢܰܟܦܽܘܬܰܐ",
            dt1: "Nakfuta",
            lt10: "ܢܟܦܘܬܐ",
            dt2: "",
            dt3: "",
            sy4: "ܢܟܦܘܬܐ",
            sy6: "",
            lt12: "",
            sy9: "",
            lt11: "ܢܟܦܘܬܐ",
            sy8: "",
            sy7: "",
            id: "3195"
        },
        {
            sy6: "",
            dt1: "Prostitution",
            dt3: "",
            lt10: "zalilutho",
            sy4: "ܙܠܝܠܘܬܐ",
            sy7: "",
            lt11: "",
            id: "3528",
            sy9: "",
            sy8: "",
            dt2: "Hurerei",
            sy5: "ܙܰܠܺܝܠܽܘܬ݂ܳܐ",
            lt12: ""
        },
        {
            sy6: "",
            dt1: "Debüt",
            sy7: "",
            sy8: "",
            lt10: "mafaqto qađmoyto",
            lt12: "",
            sy9: "",
            id: "840",
            dt3: "",
            sy4: "ܡܦܩܬܐ ܩܕܡܝܬܐ",
            lt11: "",
            sy5: "ܡܰܦܰܩܬܳܐ ܩܰܕܡܳܝܬܳܐ",
            dt2: ""
        },
        {
            lt12: "",
            sy7: "ܫܳܩܽܠܬܳܐ",
            sy9: "",
            sy4: "ܙܒܘܢܬܐ",
            sy5: "ܙܳܒܽܘܢܬܳܐ",
            dt1: "Käuferin",
            lt11: "shoqulto",
            sy8: "",
            sy6: "ܫܩܠܬܐ",
            dt2: "",
            id: "2438",
            dt3: "",
            lt10: "zobunto"
        },
        {
            sy6: "",
            sy4: "ܫܘܚܠܦ ܙܒܢܐ",
            lt11: "",
            dt2: "",
            lt10: "shuĥlof zabno",
            sy9: "",
            sy5: "ܫܽܘܚܠܳܦ ܙܰܒܢܳܐ",
            sy8: "",
            lt12: "",
            dt3: "",
            id: "6256",
            sy7: "",
            dt1: "Zeitwechsel"
        },
        {
            sy9: "",
            lt12: "",
            sy4: "ܚܘܚܐ",
            dt1: "Pfirsich",
            sy7: "",
            dt3: "",
            sy5: "ܚܰܘܚܳܐ",
            sy6: "",
            lt11: "",
            lt10: "ĥauĥo",
            dt2: "",
            id: "3456",
            sy8: ""
        },
        {
            sy4: "ܬܪܝܨܐ",
            sy8: "",
            dt3: "",
            lt11: "",
            dt2: "korrekt",
            dt1: "richtig",
            lt10: "triŝo",
            sy9: "",
            id: "3717",
            sy5: "ܬܪܺܝܨܳܐ",
            sy7: "",
            lt12: "",
            sy6: ""
        },
        {
            dt2: "",
            id: "2601",
            dt1: "Konzil",
            lt10: "sunedos",
            sy7: "ܟܢܽܘܫܝܳܐ ܕܡܰܘܬܒܳܐ ܥܺܕܬܳܢܳܝܳܐ",
            sy4: "ܣܘܢܗܕܘܣ",
            dt3: "",
            sy8: "",
            sy5: "ܣܽܘܢܶܗܕܳܘܣ",
            sy6: "ܟܢܘܫܝܐ ܕܡܘܬܒܐ ܥܕܬܢܝܐ",
            sy9: "",
            lt12: "",
            lt11: "knushyo d`mautbo ĉitonoyo"
        },
        {
            dt3: "",
            sy4: "ܐܢܬ ܐܝܬܝܟ ܚܘܒܐ ܕܚܝܝ",
            dt2: "",
            dt1: "Du bist die Liebe meines Lebens",
            sy8: "",
            lt11: "",
            sy6: "",
            sy9: "",
            sy7: "",
            lt12: "",
            lt10: "at ithayk ĥubo d'ĥayay",
            sy5: "ܐܰܢ̱ܬ ܐܺܝܬ݂ܰܝܟ ܚܽܘܒܳܐ ܕܚܰܝܰܝ",
            id: "967"
        },
        {
            dt3: "",
            lt10: "ܦܪܢܣܝܣܟܐ",
            dt1: "Franziska",
            sy9: "",
            sy8: "",
            lt11: "ܦܪܢܣܝܣܟܐ",
            sy6: "",
            sy7: "",
            lt12: "",
            dt2: "",
            sy5: "ܦܪܰܢܣܺܝܣܟܰܐ",
            sy4: "ܦܪܢܣܝܣܟܐ",
            id: "1363"
        },
        {
            dt1: "Embryologie",
            dt3: "",
            lt10: "ĉulutho",
            sy7: "",
            id: "1095",
            dt2: "",
            lt11: "",
            sy5: "ܥܽܘܠܽܘܬܳܐ",
            sy4: "ܥܘܠܘܬܐ",
            sy8: "",
            sy6: "",
            sy9: "",
            lt12: ""
        },
        {
            id: "5017",
            lt12: "",
            lt10: "shdo",
            sy6: "ܫܕܐ",
            dt2: "werfen",
            sy5: "ܫܕܳܐ",
            lt11: "shode",
            sy8: "",
            sy4: "ܫܕܐ",
            dt3: "",
            dt1: "wegwerfen",
            sy7: "ܫܳܕܶܐ",
            sy9: ""
        },
        {
            sy6: "",
            dt1: "Rabuni",
            sy9: "",
            sy8: "",
            lt10: "ܪܒܘܢܝ",
            lt11: "ܪܒܘܢܝ",
            lt12: "",
            dt3: "",
            dt2: "",
            sy7: "",
            id: "3585",
            sy4: "ܪܒܘܢܝ",
            sy5: "ܪܰܒܽܘܢܺܝ"
        },
        {
            sy5: "ܓܽܘܥܠܳܢܳܐ",
            dt1: "Hinterlegungssumme",
            dt3: "",
            sy6: "",
            sy4: "ܓܘܥܠܢܐ",
            lt11: "",
            sy8: "",
            id: "2014",
            lt10: "guĉlono",
            sy9: "",
            dt2: "Kaution",
            lt12: "",
            sy7: ""
        },
        {
            id: "2895",
            sy4: "ܣܡܣܪܐ",
            lt11: "safsoro",
            dt2: "",
            sy8: "",
            sy5: "ܣܰܡܣܳܪܳܐ",
            lt12: "",
            sy6: "ܣܦܣܪܐ",
            dt1: "Makler",
            sy7: "ܣܰܦܣܳܪܳܐ",
            sy9: "",
            lt10: "samsoro",
            dt3: ""
        },
        {
            sy8: "",
            sy7: "",
            id: "5528",
            sy4: "ܒܐܺܝܕܰܝܟ ܡܰܫܠܶܡ ܐܢܳܐ ܚܰܝܰܝ",
            sy6: "",
            sy5: "ܒܐܺܝܕܰܝܟ ܡܰܫܠܶܡ ܐܢܳܐ ܚܰܝܰܝ",
            lt10: "Biđayk mashlem no ĥayay",
            dt2: "",
            lt12: "",
            lt11: "",
            dt3: "",
            sy9: "",
            dt1: "In deinen Händen lege ich mein Leben"
        },
        {
            lt10: "ܫܡܝܪܡ",
            id: "3824",
            sy8: "",
            sy5: "ܫܰܡܺܝܪܰܐܡ",
            dt3: "",
            sy6: "ܫܡܝܪܡ",
            dt1: "Samiram",
            sy4: "ܫܡܝܪܐܡ",
            lt12: "ܫܡܝܪܡ",
            sy9: "",
            sy7: "ܫܰܡܺܝܪܰܡ",
            lt11: "ܫܡܝܪܡ",
            dt2: "Schamiram"
        },
        {
            lt12: "",
            lt11: "ĥlaț",
            sy5: "ܡܙܰܓ݂",
            sy7: "ܚܠܰܛ",
            dt2: "",
            sy4: "ܡܙܓ",
            sy6: "ܚܠܛ",
            sy8: "",
            dt3: "",
            dt1: "mischen",
            lt10: "msağ",
            id: "3052",
            sy9: ""
        },
        {
            lt10: "baĉoyo",
            dt2: "",
            dt3: "",
            lt12: "",
            sy8: "",
            dt1: "Bittender",
            sy9: "",
            sy6: "",
            id: "636",
            sy5: "ܒܰܥܳܝܳܐ",
            sy4: "ܒܥܝܐ",
            lt11: "",
            sy7: ""
        },
        {
            sy5: "ܗܰܕܳܡ̈ܶܐ ܕܓܽܘܫܡܳܐ",
            sy6: "",
            dt1: "Körperteile",
            sy8: "",
            sy7: "",
            lt11: "",
            lt10: "hadome d`gushmo",
            id: "2618",
            sy4: "ܗܕܡܐ ܕܓܘܫܡܐ",
            dt3: "",
            lt12: "",
            dt2: "",
            sy9: ""
        },
        {
            lt12: "ܚܐܘܬܐ",
            sy5: "ܚܶܘܬܰܐ",
            sy6: "ܚܐܘܬܐ",
            sy9: "",
            dt1: "Hewtha",
            dt2: "",
            sy7: "ܚܶܐܘܬܰܐ",
            sy4: "ܚܘܬܐ",
            sy8: "",
            dt3: "",
            lt10: "ܚܘܬܐ",
            id: "1993",
            lt11: "ܚܘܬܐ"
        },
        {
            lt11: "",
            dt1: "Akkumulation",
            sy6: "",
            sy4: "ܟܫܝܐ",
            dt3: "",
            id: "5803",
            lt12: "",
            sy7: "",
            lt10: "kshoyo",
            sy8: "",
            sy5: "ܟܫܳܝܳܐ",
            sy9: "",
            dt2: ""
        },
        {
            sy4: "ܓܕܐ ܒܝܫܐ",
            lt11: "",
            sy8: "",
            sy9: "",
            sy6: "",
            sy7: "",
            dt2: "Unglück",
            lt10: "gado bisho",
            lt12: "",
            dt3: "",
            id: "3427",
            dt1: "Pech",
            sy5: "ܓܰܕܳܐ ܒܺܝܫܳܐ"
        },
        {
            lt11: "ܝܡܢܗܪ",
            lt10: "ܝܡܢܗܪ",
            sy4: "ܝܡܢܗܪ",
            dt1: "Yam Nahar",
            sy8: "",
            sy7: "",
            sy9: "",
            sy6: "",
            dt3: "",
            dt2: "",
            sy5: "ܝܰܡܢܰܗܰܪ",
            id: "5196",
            lt12: ""
        },
        {
            lt12: "",
            lt10: "țebe madnĥoye",
            dt1: "Nachrichten auf ostaramäisch",
            id: "5477",
            dt2: "News auf ostaramäisch",
            lt11: "",
            sy4: "ܛܶܒ̈ܶܐ ܡܰܕܢܚܳܝܶܐ",
            sy5: "ܛܶܒ̈ܶܐ ܡܰܕܢܚܳܝܶܐ",
            sy9: "",
            sy6: "",
            dt3: "",
            sy7: "",
            sy8: ""
        },
        {
            lt11: "",
            dt2: "",
            sy7: "",
            lt10: "ĥulyonoyo",
            sy5: "ܚܽܘܠܝܳܢܳܝܳܐ",
            dt1: "Konditor",
            sy4: "ܚܘܠܝܢܝܐ",
            dt3: "",
            sy9: "",
            sy8: "",
            sy6: "",
            id: "6081",
            lt12: ""
        },
        {
            id: "2156",
            dt2: "",
            lt12: "",
            sy6: "",
            dt1: "ihm",
            sy8: "",
            lt10: "leh",
            sy4: "ܠܗ",
            sy7: "",
            dt3: "",
            sy9: "",
            lt11: "",
            sy5: "ܠܶܗ"
        },
        {
            dt2: "Rente",
            sy7: "",
            id: "3432",
            lt11: "",
            sy5: "ܡܫܰܘܬܒܽܘܬ݂ܳܐ",
            sy6: "",
            dt1: "Pension",
            sy8: "",
            dt3: "Ruhestand",
            sy9: "",
            lt10: "mshautbutho",
            lt12: "",
            sy4: "ܡܫܘܬܒܘܬܐ"
        },
        {
            lt12: "",
            sy5: "ܨܒܳܐ",
            lt10: "ŝbo",
            sy8: "",
            dt1: "wollen",
            sy9: "",
            sy6: "",
            dt2: "",
            lt11: "",
            sy4: "ܨܒܐ",
            dt3: "",
            id: "5163",
            sy7: ""
        },
        {
            dt1: "Minister",
            lt11: "",
            sy5: "ܘܰܙܺܝܪܳܐ",
            lt10: "waziro",
            sy6: "",
            sy8: "",
            lt12: "",
            sy4: "ܘܙܝܪܐ",
            sy7: "",
            dt2: "",
            sy9: "",
            id: "3043",
            dt3: ""
        },
        {
            sy9: "",
            sy6: "",
            sy5: "ܡܶܬ݂ܪܰܥܠܳܢܳܐ",
            dt3: "",
            lt12: "",
            lt10: "methraĉlono",
            lt11: "",
            sy8: "",
            id: "5342",
            dt1: "zittrig",
            sy4: "ܡܬܪܥܠܢܐ",
            sy7: "",
            dt2: "zitternd"
        },
        {
            sy4: "ܥܗܢܘܬܐ",
            sy8: "",
            sy7: "ܫܰܠܡܽܘܬ݂ܳܐ",
            lt12: "",
            dt3: "Adäquanz",
            id: "5930",
            dt2: "Verhältnismäßigkeit",
            lt11: "shalmutho",
            lt10: "ĉohnutho",
            sy9: "",
            sy5: "ܥܳܗܢܽܘܬ݂ܳܐ",
            sy6: "ܫܠܡܘܬܐ",
            dt1: "Angemessenheit"
        },
        {
            id: "2466",
            lt11: "",
            lt12: "",
            sy9: "",
            lt10: "ĥŝoŝo",
            sy6: "",
            sy8: "",
            dt3: "",
            dt2: "",
            sy5: "ܚܨܳܨܳܐ",
            sy4: "ܚܨܨܐ",
            dt1: "Kieselstein",
            sy7: ""
        },
        {
            sy5: "ܨܒܽܘܬ݂ܳܐ ܕܰܡܣܳܡܒܪܺܝܫܳܐ",
            sy9: "",
            lt10: "ŝbutho da`msombrisho",
            id: "4286",
            sy7: "",
            sy6: "",
            dt3: "",
            lt11: "",
            sy8: "",
            dt2: "",
            lt12: "",
            dt1: "Strafangelegenheit",
            sy4: "ܨܒܽܘܬ݂ܳܐ ܕܰܡܣܳܡܒܪܺܝܫܳܐ"
        },
        {
            sy8: "",
            lt10: "nthoro",
            dt3: "",
            sy5: "ܢܬ݂ܳܪܳܐ",
            sy6: "",
            sy9: "",
            lt11: "",
            sy7: "",
            id: "377",
            dt2: "Ausfallen",
            sy4: "ܢܬܪܐ",
            dt1: "Ausfall",
            lt12: ""
        },
        {
            dt3: "",
            dt2: "Schatz",
            sy6: "ܪܚܝܡܐ",
            sy5: "ܚܰܒܺܝܒܳܐ",
            sy7: "ܪܚܺܝܡܳܐ",
            lt11: "rĥimo",
            sy8: "",
            sy9: "",
            lt10: "ĥabibo",
            dt1: "Geliebter",
            sy4: "ܚܒܝܒܐ",
            lt12: "",
            id: "1554"
        },
        {
            lt10: "qarkedno",
            dt1: "Achat",
            sy9: "",
            sy5: "ܩܰܪܟܶܕܢܳܐ",
            lt11: "",
            sy4: "ܩܪܟܕܢܐ",
            id: "5724",
            sy7: "",
            lt12: "",
            dt2: "",
            sy8: "",
            sy6: "",
            dt3: ""
        },
        {
            lt12: "",
            dt1: "Rechtsanwältin",
            dt3: "Verteidigerin",
            lt10: "sniğarto",
            sy8: "",
            sy6: "",
            dt2: "Anwältin",
            id: "3641",
            sy7: "",
            sy9: "",
            sy5: "ܣܢܺܐܓ݂ܰܪܬܳܐ",
            lt11: "",
            sy4: "ܣܢܐܓܪܬܐ"
        },
        {
            lt10: "ܥܘܡܪܝ",
            id: "4630",
            sy5: "ܥܽܘܡܪܺܝ",
            dt2: "",
            sy6: "",
            dt1: "Umri",
            lt11: "ܥܘܡܪܝ",
            sy7: "",
            sy8: "",
            dt3: "",
            sy4: "ܥܘܡܪܝ",
            lt12: "",
            sy9: ""
        },
        {
            sy5: "ܒܳܪܽܘܝܽܘܬ݂ܳܐ",
            dt1: "Schöpfung",
            lt11: "",
            sy9: "",
            lt12: "",
            sy4: "ܒܪܘܝܘܬܐ",
            dt3: "",
            sy7: "",
            id: "4019",
            sy6: "",
            lt10: "boruyutho",
            sy8: "",
            dt2: ""
        },
        {
            sy7: "",
            dt1: "Glücksseligkeit",
            sy4: "ܛܘܒܬܢܘܬܐ",
            dt3: "",
            sy5: "ܛܽܘܒܬ݂ܳܢܽܘܬ݂ܳܐ",
            sy8: "",
            id: "1670",
            sy9: "",
            sy6: "",
            lt11: "",
            lt10: "țubthonutho",
            dt2: "",
            lt12: ""
        },
        {
            lt11: "bhefchonoyo",
            sy7: "ܒܗܶܦܟ݂ܳܢܳܝܳܐ",
            sy9: "",
            dt2: "",
            lt10: "bhefkho",
            dt3: "",
            sy6: "ܒܗܦܟܢܝܐ",
            sy4: "ܒܗܦܟܐ",
            sy8: "",
            dt1: "andersherum",
            id: "228",
            sy5: "ܒܗܶܦܟ݂ܳܐ",
            lt12: ""
        },
        {
            lt11: "",
            lt10: "nuțolo",
            dt3: "",
            id: "6269",
            sy7: "",
            sy9: "",
            lt12: "",
            sy5: "ܢܽܘܛܳܠܳܐ",
            sy6: "",
            sy4: "ܢܘܛܠܐ",
            dt1: "Zaudern",
            sy8: "",
            dt2: ""
        },
        {
            dt3: "",
            sy7: "",
            lt11: "ܨܗܝܬܐ",
            sy4: "ܨܗܝܬܐ",
            sy6: "",
            sy8: "",
            lt12: "",
            sy9: "",
            lt10: "ܨܗܝܬܐ",
            dt2: "",
            id: "4134",
            sy5: "ܨܗܺܝܬܰܐ",
            dt1: "Shita"
        },
        {
            sy9: "",
            dt3: "",
            sy8: "",
            lt12: "",
            id: "4525",
            sy7: "ܓܳܘܠ",
            dt1: "Tor",
            lt11: "gol",
            sy4: "ܢܘܦܐ",
            dt2: "",
            sy5: "ܢܰܘܦܳܐ",
            lt10: "naufo",
            sy6: "ܓܘܠ"
        },
        {
            dt3: "",
            lt12: "",
            sy5: "ܒܳܩܳܐ",
            sy4: "ܒܩܐ",
            sy7: "",
            dt2: "",
            sy8: "",
            id: "3120",
            lt11: "",
            sy9: "",
            sy6: "",
            lt10: "boqo",
            dt1: "Mücke"
        },
        {
            dt3: "",
            dt1: "Maler",
            sy5: "ܪܳܫܽܘܡܳܐ",
            lt10: "roshumo",
            sy7: "ܨܰܝܳܪܳܐ",
            sy8: "ܨܒܘܥܐ",
            dt2: "",
            lt11: "ŝayoro",
            sy9: "ܨܳܒܽܘܥܳܐ",
            sy6: "ܨܝܪܐ",
            id: "2900",
            sy4: "ܪܫܘܡܐ",
            lt12: "ŝobuĉo"
        },
        {
            dt1: "hier",
            lt11: "harke",
            dt2: "",
            sy7: "ܗܰܪܟܶܐ",
            sy6: "ܗܪܟܐ",
            id: "1997",
            sy8: "",
            sy5: "ܗܳܪܟܳܐ",
            dt3: "",
            sy4: "ܗܪܟܐ",
            sy9: "",
            lt12: "",
            lt10: "horko"
        },
        {
            lt11: "",
            sy9: "",
            sy5: "ܐܳܬ݂ܳܐ ܕܕܽܘܡܳܪܳܐ",
            lt12: "",
            dt2: "",
            dt3: "",
            lt10: "otho d`dumoro",
            sy4: "ܐܬܐ ܕܕܘܡܪܐ",
            dt1: "Wunderzeichen",
            sy7: "",
            sy8: "",
            id: "5174",
            sy6: ""
        },
        {
            lt11: "lo ĥamiĉo",
            id: "6433",
            dt2: "",
            sy5: "ܦܰܛܺܝܪܳܐ",
            lt12: "",
            sy7: "ܠܳܐ ܚܰܡܺܝܥܳܐ",
            lt10: "fațiro",
            sy4: "ܦܛܝܪܐ",
            dt3: "",
            sy8: "",
            sy9: "",
            dt1: "ungesäuert",
            sy6: "ܠܐ ܚܡܝܥܐ"
        },
        {
            dt3: "",
            dt2: "Vertreter",
            sy4: "ܡܡܬܠܢܐ",
            lt10: "mmathlono",
            id: "5797",
            lt12: "",
            sy8: "",
            dt1: "Agent",
            lt11: "",
            sy9: "",
            sy5: "ܡܡܰܬ݂ܠܳܢܳܐ",
            sy6: "",
            sy7: ""
        },
        {
            dt1: "Trompete",
            lt12: "",
            dt3: "",
            sy6: "",
            sy8: "",
            id: "4577",
            sy7: "",
            lt11: "",
            sy9: "",
            sy4: "ܫܝܦܘܪܐ",
            sy5: "ܫܺܝܦܽܘܪܳܐ",
            lt10: "shifuro",
            dt2: "Posaune"
        },
        {
            sy7: "",
            sy8: "",
            sy6: "",
            sy9: "",
            lt11: "",
            lt12: "",
            lt10: "ĥayutho",
            sy5: "ܚܝܽܘܬ݂ܳܐ",
            dt2: "",
            sy4: "ܚܝܘܬܐ",
            dt3: "",
            dt1: "Tier",
            id: "4477"
        },
        {
            sy9: "",
            sy4: "ܟܠܒܐ",
            lt12: "",
            id: "2069",
            dt1: "Hund",
            lt10: "kalbo",
            sy8: "",
            dt2: "",
            dt3: "",
            sy6: "",
            lt11: "",
            sy5: "ܟܰܠܒܳܐ",
            sy7: ""
        },
        {
            lt12: "",
            lt11: "",
            lt10: "yuqro meŝĉoyo",
            sy4: "ܝܘܩܪܐ ܡܨܥܝܐ",
            id: "6357",
            sy5: "ܝܽܘܩܪܳܐ ܡܶܨܥܳܝܳܐ",
            sy7: "",
            dt1: "Mittelgewicht",
            dt2: "",
            sy6: "",
            dt3: "",
            sy8: "",
            sy9: ""
        },
        {
            sy6: "",
            lt10: "oruĉutho rushmoyo",
            sy5: "ܐܳܪܽܘܥܽܘܬ݂ܳܐ ܪܘܫܡܝܐ",
            lt12: "",
            sy9: "",
            id: "3349",
            sy4: "ܐܪܘܥܘܬܐ ܪܘܫܡܝܐ",
            dt3: "",
            sy8: "",
            lt11: "",
            dt1: "offizielle Begegnung",
            sy7: "",
            dt2: ""
        },
        {
            lt10: "tren bshabo",
            lt11: "",
            sy8: "",
            sy6: "",
            sy9: "",
            sy5: "ܬܪܶܝܢ ܒܫܰܒܳܐ",
            sy7: "",
            id: "3087",
            lt12: "",
            sy4: "ܬܪܝܢ ܒܫܒܐ",
            dt1: "Montag",
            dt2: "",
            dt3: ""
        },
        {
            sy6: "",
            sy7: "",
            dt3: "",
            sy4: "ܕܘܓܐ ܘ ܚܪܫܐ",
            lt11: "",
            sy9: "",
            lt12: "",
            sy8: "",
            lt10: "dugo u ĥarsho",
            sy5: "ܕܽܘܓܳܐ ܘ ܚܰܪܫܳܐ",
            dt1: "taub und stumm",
            id: "4397",
            dt2: "taubstumm"
        },
        {
            dt2: "",
            dt3: "",
            sy6: "",
            lt12: "",
            sy5: "ܡܰܡܠܳܐ",
            id: "3649",
            sy8: "",
            lt10: "mamlo",
            dt1: "Rede",
            lt11: "",
            sy9: "",
            sy4: "ܡܡܠܐ",
            sy7: ""
        },
        {
            dt2: "",
            sy4: "ܡܪܝܗܒ",
            sy5: "ܡܳܪܝܰܗܒ",
            sy8: "",
            sy6: "ܡܪܝܗܒ",
            dt3: "",
            id: "3109",
            dt1: "Morjab",
            lt12: "ܡܪܝܗܒ",
            sy7: "ܡܰܪܝܰܗܒ",
            lt11: "ܡܪܝܗܒ",
            sy9: "",
            lt10: "ܡܪܝܗܒ"
        },
        {
            sy7: "",
            sy4: "ܩܠܝܬܐ ܕܡܠܦܢܐ",
            sy8: "",
            lt11: "",
            id: "2742",
            dt2: "",
            lt12: "",
            sy5: "ܩܶܠܳܝܬܳܐ ܕܡܰܠܦܳܢ̈ܶܐ",
            lt10: "qeloyto d`malfone",
            sy9: "",
            dt1: "Lehrerzimmer",
            dt3: "",
            sy6: ""
        },
        {
            sy7: "",
            dt2: "Achikar",
            sy5: "ܐܰܚܺܝܩܰܪ",
            dt3: "Ahiqar",
            sy4: "ܐܚܝܩܪ",
            sy8: "",
            sy9: "",
            id: "156",
            lt10: "ܐܚܝܩܪ",
            dt1: "Ahikar",
            lt11: "ܐܚܝܩܪ",
            sy6: "",
            lt12: ""
        },
        {
            dt2: "WC",
            sy6: "",
            dt1: "Toilette",
            sy8: "",
            dt3: "",
            id: "4517",
            sy4: "ܒܝܬ ܣܠܝܐ",
            lt11: "",
            sy9: "",
            lt10: "beth selyo",
            sy5: "ܒܶܝܬ݂ ܣܶܠܝܳܐ",
            sy7: "",
            lt12: ""
        },
        {
            sy8: "",
            sy9: "",
            lt12: "",
            sy6: "",
            dt1: "Schreibtafel",
            sy7: "",
            dt2: "",
            sy4: "ܣܦܪ ܡܠܐ",
            sy5: "ܣܦܰܪ ܡܶܠܶܐ",
            lt11: "",
            lt10: "sfar mele",
            dt3: "",
            id: "5795"
        },
        {
            lt10: "arken",
            sy8: "",
            id: "4720",
            lt11: "marken",
            sy5: "ܐܰܪܟܶܢ",
            sy4: "ܐܪܟܢ",
            sy6: "ܡܪܟܢ",
            lt12: "",
            dt3: "",
            sy7: "ܡܰܪܟܶܢ",
            sy9: "",
            dt1: "verbeugen",
            dt2: ""
        },
        {
            sy7: "",
            sy9: "",
            sy8: "",
            lt10: "dubor fulĥono",
            dt3: "",
            lt11: "",
            sy5: "ܕܽܘܒܳܪ ܦܽܘܠܚܳܢܳܐ",
            sy4: "ܕܘܒܪ ܦܘܠܚܢܐ",
            id: "6018",
            lt12: "",
            dt2: "",
            sy6: "",
            dt1: "Arbeitsführung"
        },
        {
            dt1: "Makel",
            lt10: "mumo",
            sy4: "ܡܘܡܐ",
            sy7: "",
            sy6: "",
            id: "2892",
            dt2: "",
            lt11: "",
            dt3: "",
            sy8: "",
            lt12: "",
            sy5: "ܡܽܘܡܳܐ",
            sy9: ""
        },
        {
            sy5: "ܟܢܽܘܫܶܪܳܐ",
            lt12: "",
            id: "508",
            sy9: "",
            sy7: "",
            sy8: "",
            sy4: "ܟܢܘܫܪܐ",
            lt10: "knushero",
            dt3: "",
            dt2: "Nabel",
            lt11: "",
            dt1: "Bauchnabel",
            sy6: ""
        },
        {
            dt3: "",
            lt10: "rebubitho",
            lt12: "",
            lt11: "",
            sy7: "",
            dt1: "Hüftbein",
            id: "6027",
            sy4: "ܪܒܘܒܝܬܐ",
            sy5: "ܪܶܒܽܘܒܺܝܬ݂ܳܐ",
            sy9: "",
            dt2: "",
            sy8: "",
            sy6: ""
        },
        {
            lt10: "țime d`futqo",
            id: "2059",
            lt11: "",
            dt3: "",
            sy8: "",
            sy9: "",
            sy7: "",
            sy4: "ܛܝܡܐ ܕܦܘܬܩܐ",
            dt2: "",
            sy5: "ܛܺܝܡܶܐ ܕܦܽܘܬܩܳܐ",
            lt12: "",
            sy6: "",
            dt1: "Hotelkosten"
        },
        {
            sy9: "",
            lt11: "",
            sy4: "ܚܪܫܬܐ",
            dt3: "",
            sy5: "ܚܰܪܳܫܬܳܐ",
            sy6: "",
            dt1: "Zauberin",
            sy7: "",
            lt12: "",
            sy8: "",
            id: "5260",
            dt2: "",
            lt10: "ĥaroshto"
        },
        {
            dt2: "",
            sy4: "ܕܘܟܝ ܡܕܝܢܬܐ",
            lt10: "dukoy mđito",
            lt11: "",
            sy5: "ܕܽܘܟܳܝ ܡܕܺܝܢ̱ܬܳܐ",
            sy9: "",
            dt1: "Stadtreinigung",
            dt3: "",
            sy8: "",
            lt12: "",
            sy6: "",
            id: "6228",
            sy7: ""
        },
        {
            sy5: "ܣܽܘܢܶܗܕܺܝܰܐ",
            sy7: "",
            sy8: "",
            sy9: "",
            id: "2572",
            dt2: "Mitinhaberschaft",
            dt1: "Kompaniegeschäft",
            sy6: "",
            lt11: "",
            dt3: "",
            sy4: "ܣܘܢܗܕܝܐ",
            lt12: "",
            lt10: "sunehdiya"
        },
        {
            dt1: "Esel",
            id: "1189",
            sy4: "ܚܡܪܐ",
            sy6: "",
            lt10: "ĥmoro",
            dt2: "",
            lt12: "",
            lt11: "",
            sy5: "ܚܡܳܪܳܐ",
            sy7: "",
            sy9: "",
            dt3: "",
            sy8: ""
        },
        {
            sy8: "",
            sy5: "ܡܰܙܪܰܐ",
            dt2: "Mazra",
            sy4: "ܡܙܪܐ",
            lt10: "ܡܙܪܐ",
            sy6: "",
            dt1: "Masra",
            sy7: "",
            id: "2958",
            dt3: "",
            lt11: "ܡܙܪܐ",
            sy9: "",
            lt12: ""
        },
        {
            dt3: "",
            id: "4161",
            sy8: "",
            sy4: "ܛܘܦܢܐ",
            lt11: "",
            sy9: "",
            lt10: "țaufono",
            sy7: "",
            sy6: "",
            dt1: "Sintflut",
            dt2: "",
            sy5: "ܛܰܘܦܳܢܳܐ",
            lt12: ""
        },
        {
            id: "4511",
            lt10: "deĥlath mauto",
            dt1: "Todesangst",
            sy7: "ܙܽܘܥܬ݂ܳܐ ܕܡܰܘܬܳܐ",
            dt2: "",
            lt12: "",
            sy4: "ܕܚܠܬ ܡܘܬܐ",
            sy9: "",
            lt11: "zuĉtho d`mauto",
            dt3: "",
            sy6: "ܙܘܥܬܐ ܕܡܘܬܐ",
            sy5: "ܕܶܚܠܰܬ݂ ܡܰܘܬܳܐ",
            sy8: ""
        },
        {
            lt10: "foțuĥo",
            sy7: "ܦܳܬܽܘܚܳܐ",
            lt11: "fotuĥo",
            id: "1615",
            sy5: "ܦܳܛܽܘܚܳܐ",
            lt12: "",
            sy4: "ܦܛܘܚܐ",
            dt1: "getrockneter Kuhmist",
            dt3: "",
            sy6: "ܦܬܘܚܐ",
            dt2: "",
            sy8: "",
            sy9: ""
        },
        {
            lt10: "kathauto",
            sy6: "ܬܰܩܕܺܝܠܳܐ",
            sy9: "",
            dt2: "Keyboard",
            id: "5465",
            lt12: "",
            sy4: "ܟܰܬ̣ܰܘܬܳܐ",
            sy5: "ܟܰܬ̣ܰܘܬܳܐ",
            lt11: "taqđilo",
            sy7: "ܬܰܩܕܺܝܠܳܐ",
            dt1: "Tastatur",
            dt3: "",
            sy8: ""
        },
        {
            dt2: "",
            sy8: "",
            lt12: "",
            sy4: "ܩܦܠܐ",
            dt3: "",
            sy5: "ܩܶܦܠܳܐ",
            lt10: "qeflo",
            sy6: "",
            dt1: "Ablage",
            sy7: "",
            sy9: "",
            id: "5582",
            lt11: ""
        },
        {
            sy5: "ܡܳܠܽܘܟ݂ܳܐ",
            lt12: "",
            sy7: "ܡܶܠܟܳܐ",
            lt10: "molukho",
            sy9: "",
            dt1: "Ratgeber",
            sy8: "",
            dt3: "",
            sy4: "ܡܠܘܟܐ",
            dt2: "Berater",
            lt11: "melko",
            sy6: "ܡܠܟܐ",
            id: "3616"
        },
        {
            lt11: "ܐܢܗܪ",
            id: "238",
            dt3: "",
            sy8: "",
            lt12: "",
            sy4: "ܐܢܗܪ",
            dt1: "Anhar",
            sy6: "",
            dt2: "",
            sy9: "",
            sy7: "",
            sy5: "ܐܰܢܗܰܪ",
            lt10: "ܐܢܗܪ"
        },
        {
            id: "3031",
            sy4: "ܦܕܪܐ",
            lt10: "fdaro",
            sy7: "",
            lt12: "",
            dt1: "Militärgeistlicher",
            dt2: "",
            sy6: "",
            sy8: "",
            sy9: "",
            lt11: "",
            dt3: "",
            sy5: "ܦܕܰܪܳܐ"
        },
        {
            sy4: "ܒܛܠܢܐ",
            lt11: "",
            sy7: "",
            sy5: "ܒܶܛܠܳܢܳܐ",
            sy6: "",
            dt3: "",
            dt1: "Ferien",
            lt12: "",
            id: "1287",
            sy8: "",
            dt2: "Urlaub",
            lt10: "bețlono",
            sy9: ""
        },
        {
            dt3: "",
            sy7: "",
            lt12: "",
            id: "324",
            lt10: "ܐܛܒ",
            dt2: "",
            sy4: "ܐܛܒ",
            sy6: "",
            sy5: "ܐܰܛܶܒ",
            sy8: "",
            sy9: "",
            dt1: "Ateb",
            lt11: "ܐܛܒ"
        },
        {
            lt10: "mshautafto",
            id: "1593",
            sy7: "ܡܫܰܘܬܦܽܘܬ݂ܳܐ",
            dt2: "",
            sy4: "ܡܫܘܬܐܦܬܐ",
            lt12: "",
            dt3: "",
            dt1: "Gesellschaft",
            sy6: "ܡܫܘܬܦܘܬܐ",
            sy8: "",
            lt11: "mshautfutho",
            sy9: "",
            sy5: "ܡܫܰܘܬܰܐܦܬܳܐ"
        },
        {
            lt10: "mađbĥo",
            sy8: "",
            sy5: "ܡܰܕܒܚܳܐ",
            lt12: "",
            dt3: "",
            sy7: "",
            sy9: "",
            id: "195",
            lt11: "",
            dt2: "",
            sy6: "",
            sy4: "ܡܕܒܚܐ",
            dt1: "Altar"
        },
        {
            sy9: "",
            lt10: "hode",
            lt11: "",
            dt1: "diese",
            sy5: "ܗܳܕܶܐ",
            dt2: "",
            dt3: "",
            sy6: "",
            id: "889",
            sy8: "",
            sy7: "",
            lt12: "",
            sy4: "ܗܕܐ"
        },
        {
            sy5: "ܓܰܠܓܶܠ",
            lt12: "",
            sy9: "",
            lt10: "galgel",
            dt3: "",
            sy6: "",
            id: "5624",
            sy8: "",
            dt1: "abschleifen",
            lt11: "",
            dt2: "",
            sy7: "",
            sy4: "ܓܠܓܠ"
        },
        {
            sy7: "",
            sy8: "",
            dt1: "Liegestuhl",
            lt12: "",
            dt2: "",
            sy6: "",
            sy4: "ܟܘܪܣܝܐ ܫܛܝܚܐ",
            sy9: "",
            dt3: "",
            id: "2831",
            lt10: "kursyo shțiĥo",
            sy5: "ܟܽܘܪܣܝܳܐ ܫܛܺܝܚܳܐ",
            lt11: ""
        },
        {
            lt12: "",
            id: "1249",
            sy6: "",
            sy4: "ܢܒܝܐ ܕܓܠܐ",
            lt11: "",
            sy7: "",
            dt2: "",
            lt10: "nbiyo dagolo",
            dt1: "falscher Prophet",
            sy8: "",
            dt3: "",
            sy9: "",
            sy5: "ܢܒܺܝܳܐ ܕܰܓܳܠܳܐ"
        },
        {
            dt3: "",
            lt11: "",
            dt2: "",
            sy5: "ܐܰܘܕܺܝ",
            sy8: "",
            lt12: "",
            dt1: "beichten",
            sy9: "",
            sy7: "",
            lt10: "audi",
            id: "6425",
            sy4: "ܐܘܕܝ",
            sy6: ""
        },
        {
            sy9: "",
            dt2: "",
            lt11: "",
            dt1: "Fürchte Dich nicht",
            sy4: "ܠܐ ܬܕܚܠ",
            sy8: "",
            lt12: "",
            sy6: "",
            dt3: "",
            sy5: "ܠܳܐ ܬܶܕܚܰܠ",
            sy7: "",
            id: "5604",
            lt10: "lo tedĥal"
        },
        {
            sy6: "",
            sy4: "ܡܪܝܗܒ",
            dt1: "Maryahb",
            sy7: "",
            sy5: "ܡܰܪܝܰܗܒ",
            id: "2952",
            dt3: "",
            lt11: "ܡܪܝܗܒ",
            dt2: "",
            sy8: "",
            lt12: "",
            sy9: "",
            lt10: "ܡܪܝܗܒ"
        },
        {
            sy7: "",
            dt3: "",
            dt1: "Rückstand",
            sy8: "",
            sy5: "ܡܶܫܬܰܘܚܪܳܢܽܘܬ݂ܳܐ",
            sy9: "",
            id: "3761",
            sy6: "",
            lt11: "",
            lt12: "",
            lt10: "meshtauĥronutho",
            sy4: "ܡܫܬܘܚܪܢܘܬܐ",
            dt2: ""
        },
        {
            id: "5526",
            dt2: "",
            sy9: "",
            sy8: "",
            sy4: "ܫܓܨܐ",
            lt11: "",
            dt3: "",
            dt1: "Wasserfall",
            lt10: "shegŝo",
            lt12: "",
            sy7: "",
            sy5: "ܫܶܓܨܳܐ",
            sy6: ""
        },
        {
            lt10: "sobuno",
            lt12: "",
            sy7: "",
            dt3: "",
            lt11: "",
            dt1: "Kunde",
            sy9: "",
            sy4: "ܙܒܘܢܐ",
            sy6: "",
            sy5: "ܙܺܒܽܘܢܳܐ",
            dt2: "",
            id: "2667",
            sy8: ""
        },
        {
            dt1: "Irrgarten",
            lt10: "methĉarzlonutho",
            sy6: "",
            sy9: "",
            sy5: "ܡܶܬ݂ܥܰܪܙܠܳܢܽܘܬ݂ܳܐ",
            sy4: "ܡܬܥܪܙܠܢܘܬܐ",
            sy7: "",
            dt3: "",
            id: "6167",
            dt2: "",
            sy8: "",
            lt12: "",
            lt11: ""
        },
        {
            dt2: "",
            sy9: "",
            id: "3977",
            sy4: "ܣܪܘܦܬܐ",
            sy8: "",
            dt3: "",
            sy6: "",
            sy7: "",
            sy5: "ܣܳܪܽܘܦܬܳܐ",
            lt11: "",
            dt1: "Schlucken",
            lt10: "sorufto",
            lt12: ""
        },
        {
            sy4: "ܐܟܣܝܫܢ",
            lt11: "",
            dt3: "",
            sy9: "",
            dt1: "Sauerstoff",
            dt2: "",
            lt12: "",
            id: "3856",
            sy6: "",
            sy7: "",
            sy5: "ܐܳܟܣܺܝܫܰܢ",
            sy8: "",
            lt10: "oksishan"
        },
        {
            id: "2417",
            sy7: "",
            dt3: "",
            lt11: "",
            sy6: "",
            lt10: "skambil",
            sy8: "",
            dt1: "Kartenspiel",
            lt12: "",
            sy9: "",
            sy4: "ܣܟܡܒܝܠ",
            dt2: "",
            sy5: "ܣܟܰܡܒܺܝܠ"
        },
        {
            dt3: "",
            sy5: "ܚܕܳܢܳܝܳܐ",
            dt1: "einzigartig",
            sy8: "",
            lt11: "",
            sy6: "",
            lt12: "",
            lt10: "ĥđonoyo",
            sy9: "",
            dt2: "einmalig",
            sy7: "",
            sy4: "ܚܕܢܝܐ",
            id: "1058"
        },
        {
            lt11: "",
            id: "4213",
            sy4: "ܫܥܝܢܐܝܬ",
            sy8: "",
            dt2: "",
            sy5: "ܫܶܥܝܳܢܳܐܝܺܬ݂",
            sy7: "",
            dt1: "spielend",
            dt3: "",
            sy9: "",
            lt12: "",
            sy6: "",
            lt10: "sheĉyonoith"
        },
        {
            dt1: "Bezahlung",
            lt10: "furĉono",
            dt3: "",
            sy9: "",
            id: "608",
            sy8: "",
            lt12: "",
            dt2: "",
            sy4: "ܦܘܪܥܢܐ",
            lt11: "",
            sy6: "",
            sy7: "",
            sy5: "ܦܽܘܪܥܳܢܳܐ"
        },
        {
            sy8: "",
            sy7: "",
            sy5: "ܣܽܘܪܚܳܢܳܐ",
            sy9: "",
            sy6: "",
            lt10: "surĥono",
            lt11: "",
            dt3: "",
            id: "6396",
            dt2: "",
            dt1: "Kriminalität",
            lt12: "",
            sy4: "ܣܘܪܚܢܐ"
        },
        {
            sy5: "ܫܶܪܫܳܐ",
            dt1: "Stamm",
            id: "6392",
            sy6: "ܩܘܪܡܐ",
            sy8: "ܛܘܗܡܐ",
            lt11: "qurmo",
            dt2: "",
            sy7: "ܩܽܘܪܡܳܐ",
            lt12: "țuhmo",
            sy9: "ܛܽܘܗܡܳܐ",
            dt3: "",
            lt10: "shersho",
            sy4: "ܫܪܫܐ"
        },
        {
            dt2: "Iwardo",
            sy9: "ܡܕܺܝܢܰܬ݂ ܓܰܢ̱ܒ̈ܳܪܶܐ",
            sy4: "ܩܪܝܬܐ ܥܝܘܪܕܐ",
            lt12: "mdinath gabore",
            sy5: "ܩܪܺܝܬ݂ܳܐ ܥܺܝܘܰܪܕܳܐ",
            sy6: "ܥܝܘܪܕܐ",
            dt1: "Dorf Iwardo",
            lt11: "ĉiwardo",
            sy7: "ܥܺܝܘܰܪܕܳܐ",
            dt3: "",
            id: "936",
            lt10: "qritho ĉiwardo",
            sy8: "ܡܕܝܢܬ ܓܢܒܪܐ"
        },
        {
            lt12: "ܒܫܪܐ",
            sy4: "ܒܫܪܝܢ",
            id: "572",
            dt2: "Beschra",
            lt10: "ܒܫܪܝܢ",
            sy9: "",
            sy5: "ܒܶܫܪܺܝܢ",
            lt11: "ܒܫܪܝܢ",
            sy8: "",
            sy6: "ܒܫܪܐ",
            dt3: "",
            sy7: "ܒܶܫܪܰܐ",
            dt1: "Beschrin"
        },
        {
            sy4: "ܢܒܘܢܐܝܕ",
            id: "3231",
            dt1: "Nebunaid",
            lt11: "ܢܒܘܢܐܝܕ",
            sy6: "",
            sy7: "",
            sy5: "ܢܰܒܽܘܢܰܐܝܺܕ",
            sy8: "",
            dt3: "",
            lt10: "ܢܒܘܢܐܝܕ",
            dt2: "",
            sy9: "",
            lt12: ""
        },
        {
            dt2: "",
            id: "3483",
            lt12: "",
            lt11: "",
            lt10: "daĥshe",
            sy4: "ܕܚܫܐ",
            sy9: "",
            dt1: "Polizei",
            sy7: "",
            sy5: "ܕܰܚܫܳܐ",
            sy8: "",
            dt3: "",
            sy6: ""
        },
        {
            sy9: "",
            lt11: "",
            dt3: "",
            lt10: "noqef",
            lt12: "",
            dt1: "Fortsetzung folgt",
            sy5: "ܢܳܩܶܦ",
            id: "6087",
            sy7: "",
            dt2: "",
            sy8: "",
            sy4: "ܢܩܦ",
            sy6: ""
        },
        {
            sy5: "ܥܰܪܳܒܽܘܢܳܐ",
            lt12: "",
            sy6: "ܒܬܐ",
            sy4: "ܥܪܒܘܢܐ",
            dt2: "",
            sy9: "",
            sy8: "",
            dt1: "Immobilien",
            dt3: "",
            lt11: "bote",
            id: "2172",
            lt10: "ĉarobuno",
            sy7: "ܒܳܬܶܐ"
        },
        {
            lt12: "",
            dt2: "",
            sy6: "",
            lt11: "ܥܡܝܫܕܝ",
            lt10: "ܥܡܝܫܕܝ",
            dt1: "Ammischaddai",
            sy7: "",
            sy9: "",
            dt3: "",
            sy8: "",
            id: "215",
            sy5: "ܥܰܡܺܝܫܰܕܰܝ",
            sy4: "ܥܡܝܫܕܝ"
        },
        {
            sy4: "ܨܦܢܝܐ",
            sy9: "",
            sy7: "",
            sy5: "ܨܶܦܰܢܝܰܐ",
            sy8: "",
            dt1: "Zephanja",
            dt3: "Zephanya",
            sy6: "",
            id: "5307",
            dt2: "Sephanja",
            lt10: "ܨܦܢܝܐ",
            lt12: "",
            lt11: "ܨܦܢܝܐ"
        },
        {
            dt1: "Im Geist Gottes existiert alles und all das ist perfekt",
            lt12: "",
            sy5: "ܒܪܽܘܚܳܐ ܕܰܐܠܳܗܳܐ ܫܟܺܝܚ ܗܽܘ ܟܽܠ ܡܶܕܶܡ ܘܟܽܠܗܽܘܢ ܗܳܠܶܝܢ ܓܡܺܝܪܺܝܢ",
            sy9: "",
            sy6: "",
            sy8: "",
            sy7: "",
            id: "2168",
            dt3: "",
            dt2: "",
            sy4: "ܒܪܘܚܐ ܕܐܠܗܐ ܫܟܝܚ ܗܘ ܟܠ ܡܕܡ ܘܟܠܗܘܢ ܗܠܝܢ ܓܡܝܪܝܢ",
            lt11: "",
            lt10: "bruĥo d'aloho shkiĥ hu kul medem u kulhun holen gmirin"
        },
        {
            dt3: "",
            sy9: "",
            sy6: "",
            lt10: "ܠܚܘܕ",
            lt11: "ܠܚܘܕ",
            dt2: "",
            dt1: "Lahhoud",
            sy4: "ܠܚܘܕ",
            sy5: "ܠܚܘܕ",
            sy8: "",
            lt12: "",
            id: "2693",
            sy7: ""
        },
        {
            dt3: "",
            lt11: "",
            dt2: "",
            sy9: "",
            id: "2615",
            sy5: "ܡܥܰܘܟ݂ܽܘܬܳܐ",
            lt10: "mĉaukhutho",
            sy6: "",
            sy4: "ܡܥܘܟܘܬܐ",
            lt12: "",
            sy8: "",
            dt1: "Körperbehinderung",
            sy7: ""
        },
        {
            dt1: "Abendbrot essen",
            sy8: "",
            lt10: "aĥshem",
            sy5: "ܐܰܚܫܶܡ",
            lt11: "",
            lt12: "",
            sy4: "ܐܚܫܡ",
            id: "5557",
            sy6: "",
            dt3: "",
            sy7: "",
            sy9: "",
            dt2: ""
        },
        {
            dt3: "",
            sy4: "ܝܦܐܢܝܐ",
            lt11: "",
            sy9: "",
            sy6: "",
            lt12: "",
            sy7: "",
            dt1: "Japaner",
            lt10: "yapanoyo",
            sy5: "ܝܰܦܰܐܢܳܝܳܐ",
            dt2: "japanisch",
            id: "2287",
            sy8: ""
        },
        {
            sy9: "",
            dt3: "",
            sy7: "",
            sy8: "",
            id: "2611",
            dt2: "",
            lt11: "",
            lt12: "",
            lt10: "qurintoyo",
            sy5: "ܩܽܘܪܺܝܢܬܳܝܳܐ",
            sy4: "ܩܘܪܝܢܬܝܐ",
            sy6: "",
            dt1: "Korinther"
        },
        {
            lt12: "",
            dt3: "",
            sy8: "",
            sy5: "ܪܰܕܳܝܬܳܐ ܕܐܰܓ݂ܪܳܐ",
            lt10: "rađoyto d`ağro",
            sy4: "ܪܕܝܬܐ ܕܐܓܪܐ",
            dt2: "Mietauto",
            sy7: "",
            id: "3022",
            lt11: "",
            sy6: "",
            dt1: "Mietwagen",
            sy9: ""
        },
        {
            sy5: "ܚܳܫܽܘܠܳܐ",
            sy8: "",
            sy6: "",
            lt11: "",
            sy7: "",
            sy4: "ܚܫܘܠܐ",
            lt10: "ĥoshulo",
            lt12: "",
            dt2: "Goldschmied",
            dt3: "",
            sy9: "",
            id: "2368",
            dt1: "Juwelier"
        },
        {
            sy6: "",
            sy7: "",
            lt12: "",
            lt10: "gorufto",
            dt2: "",
            sy4: "ܓܪܘܦܬܐ",
            id: "2446",
            lt11: "",
            dt1: "Kehrschaufel",
            dt3: "",
            sy8: "",
            sy5: "ܓܳܪܘܽܦܬܳܐ",
            sy9: ""
        },
        {
            id: "276",
            sy5: "ܦܠܰܚ",
            sy7: "ܥܡܰܠ",
            dt1: "arbeiten",
            sy9: "ܫܓ݂ܰܠ",
            dt3: "",
            lt12: "shğal",
            sy4: "ܦܠܚ",
            lt10: "flaĥ",
            sy6: "ܥܡܠ",
            sy8: "ܫܓܠ",
            dt2: "",
            lt11: "ĉmal"
        },
        {
            dt1: "morgen früh",
            sy7: "",
            sy9: "",
            sy6: "",
            lt11: "",
            sy4: "ܠܡܚܪ ܨܦܪܐ",
            dt3: "",
            id: "3107",
            sy8: "",
            sy5: "ܠܰܡܚܳܪ ܨܰܦܪܳܐ",
            lt12: "",
            lt10: "lamĥor ŝafro",
            dt2: ""
        },
        {
            dt1: "Es schneit auf dem Berg",
            lt10: "noĥet talgo ĉal țuro",
            lt11: "",
            dt2: "",
            sy4: "ܢܚܬ ܬܠܓܐ ܥܠ ܛܘܪܐ",
            sy5: "ܢܳܚܶܬ ܬܰܠܓܳܐ ܥܰܠ ܛܽܘܪܳܐ",
            sy9: "",
            dt3: "",
            sy8: "",
            sy6: "",
            id: "1186",
            sy7: "",
            lt12: ""
        },
        {
            dt3: "",
            sy8: "",
            sy9: "",
            lt10: "ܥܒܕܝܫܘܥ",
            sy4: "ܥܒܕܝܫܘܥ",
            id: "72",
            sy6: "",
            dt1: "Abedjeschu",
            sy5: "ܥܒܶܕܝܶܫܘܥ",
            lt11: "ܥܒܕܝܫܘܥ",
            lt12: "",
            sy7: "",
            dt2: ""
        },
        {
            sy8: "",
            id: "2840",
            sy5: "ܣܶܡܳܠܳܐ",
            lt12: "",
            sy9: "",
            sy4: "ܣܡܠܐ",
            dt1: "links",
            sy7: "",
            dt2: "",
            dt3: "",
            lt10: "semolo",
            lt11: "",
            sy6: ""
        },
        {
            sy8: "",
            id: "1228",
            sy7: "",
            sy9: "",
            lt11: "ܦܗܕܐ",
            lt12: "",
            lt10: "ܦܗܕܐ",
            dt1: "Fahdo",
            dt3: "",
            sy6: "",
            sy4: "ܦܗܕܐ",
            dt2: "",
            sy5: "ܦܰܗܕܳܐ"
        },
        {
            dt3: "",
            sy7: "",
            lt11: "ܙܢܘܒܝܐ",
            sy9: "",
            dt1: "Zenobia",
            id: "5300",
            sy6: "",
            sy8: "",
            dt2: "",
            lt12: "",
            sy5: "ܙܶܢܳܘܒܺܝܰܐ",
            lt10: "ܙܢܘܒܝܐ",
            sy4: "ܙܢܘܒܝܐ"
        },
        {
            lt10: "shbaq zayno",
            lt11: "",
            lt12: "",
            sy8: "",
            dt2: "",
            dt3: "",
            id: "3033",
            dt1: "militärisch abrüsten",
            sy6: "",
            sy9: "",
            sy4: "ܫܒܩ ܙܝܢܐ",
            sy7: "",
            sy5: "ܫܒܰܩ ܙܰܝܢܳܐ"
        },
        {
            id: "1940",
            sy4: "ܕܪܒܐ ܦܬܝܚܐ",
            dt3: "Freie Fahrt",
            lt11: "",
            dt2: "Gute Rückfahrt",
            lt10: "darbo ftiĥo",
            lt12: "",
            sy6: "",
            sy9: "",
            dt1: "Heimweg ohne Stau",
            sy8: "",
            sy7: "",
            sy5: "ܕܰܪܒܳܐ ܦܬܺܝܚܳܐ"
        },
        {
            sy5: "ܡܛܰܠܥܳܐ",
            lt11: "d`lo reğsho",
            lt10: "mțalĉo",
            sy8: "",
            lt12: "",
            id: "602",
            sy9: "",
            sy4: "ܡܛܠܥܐ",
            dt1: "bewusstlos",
            sy6: "ܕܠܐ ܪܓܫܐ",
            dt3: "",
            sy7: "ܕܠܳܐ ܪܶܓ݂ܫܳܐ",
            dt2: ""
        },
        {
            lt10: "nasi",
            lt11: "nso",
            dt3: "",
            id: "4443",
            dt1: "testen",
            lt12: "",
            sy5: "ܢܰܣܺܝ",
            sy7: "ܢܣܳܐ",
            sy9: "",
            sy8: "",
            dt2: "prüfen",
            sy6: "ܢܣܐ",
            sy4: "ܢܣܝ"
        },
        {
            id: "3829",
            sy4: "ܨܡܘܐܝܠ",
            sy7: "ܫܡܘܐܝܠ",
            sy8: "",
            sy9: "",
            dt1: "Samuel",
            lt10: "ܨܡܘܐܠ",
            dt2: "",
            lt11: "ܨܡܘܐܠ",
            dt3: "",
            lt12: "ܨܡܘܐܝܠ",
            sy6: "ܒܡܘܐܝܠ",
            sy5: "ܨܰܡܽܘܐܝܶܠ"
        },
        {
            sy7: "ܟܢܳܫܳܐ",
            lt10: "kuforo",
            lt12: "",
            sy9: "",
            sy6: "ܟܢܫܐ",
            lt11: "knosho",
            sy8: "",
            sy4: "ܟܘܦܪܐ",
            id: "3551",
            dt3: "",
            sy5: "ܟܽܘܦܳܪܳܐ",
            dt1: "Putzen",
            dt2: ""
        },
        {
            lt12: "",
            sy6: "",
            sy9: "",
            sy5: "ܕܒܳܪܳܐ",
            dt1: "Fahren",
            dt3: "",
            lt11: "",
            sy4: "ܕܒܪܐ",
            sy8: "",
            sy7: "",
            id: "1232",
            dt2: "",
            lt10: "dboro"
        },
        {
            lt10: "shoțurutho",
            lt11: "",
            sy6: "",
            dt1: "Humbug",
            dt3: "",
            dt2: "absurd",
            sy9: "",
            sy8: "",
            sy4: "ܫܛܘܪܘܬܐ",
            id: "6036",
            lt12: "",
            sy5: "ܫܳܛܽܘܪܽܘܬ݂ܳܐ",
            sy7: ""
        },
        {
            dt3: "",
            sy8: "",
            lt11: "",
            dt1: "frei",
            lt12: "",
            dt2: "",
            sy9: "",
            id: "1379",
            sy6: "",
            sy4: "ܚܝܪܐ",
            sy7: "",
            lt10: "ĥiro",
            sy5: "ܚܺܝܪܳܐ"
        },
        {
            sy7: "",
            sy4: "ܠܒܺܝܒܳܐ",
            lt10: "lbibo",
            id: "5433",
            sy6: "",
            dt3: "",
            lt11: "",
            dt1: "mutig",
            sy9: "",
            dt2: "",
            sy8: "",
            sy5: "ܠܒܺܝܒܳܐ",
            lt12: ""
        },
        {
            sy9: "",
            dt1: "Amüsement",
            lt11: "",
            id: "5892",
            dt2: "",
            sy7: "",
            sy4: "ܡܒܣܡܢܘܬܐ",
            sy6: "",
            lt10: "mbasmonutho",
            sy5: "ܡܒܰܣܡܳܢܽܘܬ݂ܳܐ",
            lt12: "",
            dt3: "",
            sy8: ""
        },
        {
            sy7: "ܚܰܬܽܘܢ",
            dt1: "Hatun",
            dt3: "",
            sy8: "",
            sy6: "ܚܬܘܢ",
            sy4: "ܗܬܘܢ",
            sy5: "ܚܬܘܢ",
            dt2: "",
            sy9: "",
            lt11: "ܗܬܘܢ",
            id: "1888",
            lt10: "ܗܬܘܢ",
            lt12: "ܚܬܘܢ"
        },
        {
            lt12: "",
            sy9: "",
            sy4: "ܐܪܝܒ",
            dt3: "",
            lt11: "ܐܪܝܒ",
            dt2: "",
            lt10: "ܐܪܝܒ",
            sy8: "",
            dt1: "Arib",
            sy6: "",
            sy5: "ܐܰܪܺܝܒ",
            id: "288",
            sy7: ""
        },
        {
            dt2: "Fehlen",
            sy4: "ܒܨܝܪܘܬܐ",
            lt11: "nuqŝaniye",
            sy7: "ܢܽܘܩܨܰܢܺܝܶܐ",
            lt12: "",
            sy9: "",
            dt3: "",
            lt10: "bŝirutho",
            sy8: "",
            sy6: "ܢܘܩܨܢܝܐ",
            dt1: "Mangel",
            id: "2913",
            sy5: "ܒܨܺܝܪܽܘܬ݂ܳܐ"
        },
        {
            sy7: "",
            dt2: "",
            sy6: "",
            dt1: "Ich komme vom Dorf",
            id: "2120",
            sy8: "",
            lt11: "",
            lt10: "kothe no mi qritho",
            sy5: "ܟܳܐܬ݂ܶܐ ܐ̱ܢܳܐ ܡܺܝ ܩܪܺܝܬ݂ܳܐ",
            dt3: "",
            sy9: "",
            lt12: "",
            sy4: "ܟܐܬ݂ܐ ܐ̱ܢܐ ܡܝ ܩܪܝܬ݂ܐ"
        },
        {
            lt11: "ܐܠܝܥܙܪ",
            dt1: "Eliozor",
            sy4: "ܐܠܝܥܙܪ",
            id: "1078",
            dt3: "",
            sy9: "",
            sy5: "ܐܶܠܺܝܥܰܙܰܪ",
            sy7: "",
            sy6: "",
            lt10: "ܐܠܝܥܙܪ",
            sy8: "",
            dt2: "Eleasar",
            lt12: ""
        },
        {
            dt1: "Barsalibi",
            sy6: "ܒܪܨܠܝܒܐ",
            sy7: "ܒܰܪܨܠܺܝܒܳܐ",
            sy9: "",
            lt10: "ܒܪܨܠܝܒܝ",
            dt3: "",
            sy8: "",
            dt2: "",
            lt11: "ܒܪܨܠܝܒܝ",
            id: "474",
            sy4: "ܒܪܨܠܝܒܝ",
            sy5: "ܒܰܪܨܰܠܺܝܒܺܝ",
            lt12: "ܒܪܨܠܝܒܐ"
        },
        {
            lt10: "sefthono",
            dt2: "verbal",
            id: "3125",
            dt1: "mündlich",
            lt11: "sefthonoyo",
            dt3: "",
            sy9: "ܣܶܦܬ݂ܳܢܳܐܝܺܬ݂",
            lt12: "sefthonoith",
            sy7: "ܣܶܦܬ݂ܳܢܳܝܳܐ",
            sy5: "ܣܶܦܬ݂ܳܢܳܐ",
            sy6: "ܣܦܬܢܝܐ",
            sy8: "ܣܦܬܢܐܝܬ",
            sy4: "ܣܦܬܢܐ"
        },
        {
            dt3: "",
            sy7: "",
            lt11: "",
            sy6: "",
            dt2: "",
            lt12: "",
            sy8: "",
            dt1: "Lügnerin",
            lt10: "mdaglonitho",
            sy9: "",
            sy5: "ܡܕܰܓܠܳܢܺܝܬ݂ܳܐ",
            id: "2872",
            sy4: "ܡܕܓܠܢܝܬܐ"
        },
        {
            lt10: "bar zaugo",
            dt3: "verheirateter Mann",
            lt12: "",
            sy4: "ܒܪ ܙܘܓܐ",
            dt1: "Ehemann",
            dt2: "Ehegatte",
            sy9: "",
            sy5: "ܒܰܪ ܙܰܘܓܳܐ",
            sy7: "ܒܰܥܠܳܐ",
            lt11: "baĉlo",
            id: "1013",
            sy6: "ܒܥܠܐ",
            sy8: ""
        },
        {
            id: "5988",
            lt12: "methĥauyonutho",
            sy7: "ܚܙܳܬ݂ܳܐ",
            dt3: "",
            lt10: "ĥezwo",
            sy9: "ܡܶܬ݂ܚܰܘܝܳܢܽܘܬ݂ܳܐ",
            sy4: "ܚܙܘܐ",
            sy8: "ܡܬܚܘܝܢܘܬܐ",
            sy6: "ܚܙܬܐ",
            dt2: "",
            sy5: "ܚܶܙܘܳܐ",
            dt1: "Anschein",
            lt11: "ĥzotho"
        },
        {
            lt10: "osyo mĉayen krihe",
            sy4: "ܐܣܝܐ ܡܥܝܢ ܟܪܝܗܐ",
            sy5: "ܐܳܣܝܳܐ ܡܥܰܝܶܢ ܟܪ̈ܝܺܗܶܐ",
            sy6: "",
            dt3: "",
            dt1: "Doktor kontrolliert Patienten",
            sy9: "",
            lt12: "",
            sy7: "",
            id: "911",
            lt11: "",
            dt2: "Arzt kontrolliert Patienten",
            sy8: ""
        },
        {
            dt3: "Fenchel",
            sy9: "",
            sy6: "ܚܪܦܪܘܦܐ",
            id: "6395",
            sy4: "ܨܘܢܕܐ",
            sy5: "ܨܽܘܢܕܳܐ",
            sy8: "",
            lt12: "",
            sy7: "ܚܶܪܰܦܪܽܘܦܳܐ",
            dt2: "Dill",
            dt1: "Anis",
            lt10: "ŝundo",
            lt11: "ĥerafrufo"
        },
        {
            sy5: "ܒܶܝܬ݂ ܣܰܝܒܽܘܬ݂ܳܐ",
            dt1: "Altenheim",
            dt3: "",
            dt2: "Altersheim",
            lt12: "beth mashryo",
            sy7: "ܒܶܝܬ݂ ܣܳܒܶܐ",
            sy9: "ܒܶܝܬ݂ ܡܰܫܪܝܳܐ",
            sy6: "ܒܝܬ ܣܒܐ",
            id: "5859",
            lt11: "beth sobe",
            sy4: "ܒܝܬ ܣܝܒܘܬܐ",
            lt10: "beth saybutho",
            sy8: "ܒܝܬ ܡܫܪܝܐ"
        },
        {
            dt3: "",
            sy4: "ܡܕܝܢܬܐ",
            sy9: "",
            sy5: "ܡܕܺܝ̈ܢܳܬܳܐ",
            lt10: "mdinotho",
            sy7: "",
            id: "4236",
            dt1: "Städte",
            sy6: "",
            sy8: "",
            lt11: "",
            dt2: "",
            lt12: ""
        },
        {
            sy7: "",
            lt10: "ܬܐܘܦܝܠܘܣ",
            lt11: "ܬܐܘܦܝܠܘܣ",
            lt12: "",
            dt3: "",
            sy6: "",
            sy9: "",
            sy4: "ܬܐܘܦܝܠܘܣ",
            id: "4459",
            sy5: "ܬܶܐܳܘܦܺܝܠܳܘܣ",
            dt1: "Theophilos",
            dt2: "",
            sy8: ""
        },
        {
            id: "3073",
            sy9: "",
            dt1: "Mögen",
            dt2: "Lieben",
            dt3: "",
            sy5: "ܪܚܳܡܳܐ",
            lt11: "",
            lt10: "rĥomo",
            sy6: "",
            sy7: "",
            sy4: "ܪܚܡܐ",
            lt12: "",
            sy8: ""
        },
        {
            sy8: "",
            id: "2980",
            sy7: "",
            dt3: "",
            lt12: "",
            sy9: "",
            sy5: "ܕܰܪܡܳܢܳܐ",
            sy6: "",
            lt11: "",
            dt1: "Medikament",
            sy4: "ܕܪܡܢܐ",
            lt10: "darmono",
            dt2: ""
        },
        {
            dt2: "",
            sy5: "ܦܰܪܣܽܘܥܳܐ",
            lt10: "farsuĉo",
            sy4: "ܦܪܣܘܥܐ",
            dt1: "Huf",
            lt11: "ĉeqbo",
            lt12: "fshido",
            id: "6026",
            sy8: "ܦܫܝܕܐ",
            sy7: "ܥܶܩܒܳܐ",
            sy6: "ܥܩܒܐ",
            dt3: "",
            sy9: "ܦܫܺܝܕܳܐ"
        },
        {
            lt11: "ܐܚܘܢܝ",
            id: "166",
            sy4: "ܐܚܘܢܝ",
            dt1: "Ahuni",
            dt3: "",
            lt10: "ܐܚܘܢܝ",
            sy6: "",
            dt2: "",
            sy7: "",
            lt12: "",
            sy9: "",
            sy5: "ܐܰܚܽܘܢܺܝ",
            sy8: ""
        },
        {
            sy8: "",
            lt10: "methțayab no",
            id: "2090",
            lt12: "",
            sy4: "ܡܬܛܝܒ ܐܢܐ",
            sy5: "ܡܶܬ݂ܛܰܝܰܒ ܐ̱ܢܳܐ",
            sy7: "",
            dt2: "",
            sy9: "",
            dt3: "",
            dt1: "Ich bereite mich vor",
            sy6: "",
            lt11: ""
        },
        {
            sy9: "",
            lt11: "",
            dt3: "",
            sy8: "",
            id: "1771",
            lt10: "brikh ramsho",
            lt12: "",
            sy6: "",
            sy7: "",
            dt2: "",
            dt1: "Guten Abend",
            sy5: "ܒܪܺܝܟ݂ ܪܰܡܫܳܐ",
            sy4: "ܒܪܝܟ ܪܡܫܐ"
        },
        {
            dt1: "Abendrot",
            lt11: "",
            id: "77",
            dt3: "",
            sy8: "",
            dt2: "Abendleuchten",
            sy6: "",
            sy7: "",
            sy9: "",
            sy4: "ܢܘܨܦܐ",
            sy5: "ܢܽܘܨܦܳܐ",
            lt12: "",
            lt10: "nuŝfo"
        },
        {
            sy6: "",
            lt10: "ܐܒܪܗܡ",
            sy4: "ܐܒܪܗܡ",
            sy9: "",
            sy8: "",
            sy5: "ܐܰܒܪܳܗܳܡ",
            sy7: "",
            lt12: "",
            dt1: "Abrohom",
            dt2: "Abraham",
            dt3: "Ibrahim",
            lt11: "ܐܒܪܗܡ",
            id: "110"
        },
        {
            sy6: "",
            sy7: "",
            dt2: "",
            lt11: "",
            dt3: "",
            sy5: "ܢܰܗܪܳܐ",
            sy9: "",
            lt10: "nahro",
            sy8: "",
            id: "1346",
            dt1: "Fluss",
            sy4: "ܢܗܪܐ",
            lt12: ""
        },
        {
            id: "665",
            lt10: "siĉto da`dmo",
            dt1: "Blutgruppe",
            sy9: "",
            sy4: "ܣܝܥܬܐ ܕܕܡܐ",
            lt12: "",
            dt3: "",
            sy6: "",
            lt11: "",
            sy7: "",
            sy5: "ܣܺܝܥܬܳܐ ܕܰܕܡܰܐ",
            dt2: "",
            sy8: ""
        },
        {
            sy5: "ܒܶܝܬ݂ ܒܺܝܠܕܰܪܳܐ",
            sy9: "",
            lt11: "",
            dt3: "",
            sy8: "",
            sy4: "ܒܝܬ ܒܝܠܕܪܐ",
            id: "3498",
            lt12: "",
            dt2: "",
            sy7: "",
            dt1: "Postamt",
            sy6: "",
            lt10: "beth bildaro"
        },
        {
            sy4: "ܙܡܪ",
            sy9: "",
            lt10: "zmar",
            sy5: "ܙܡܰܪ",
            dt1: "singen",
            sy6: "",
            lt11: "",
            dt3: "",
            lt12: "",
            id: "4160",
            sy8: "",
            dt2: "",
            sy7: ""
        },
        {
            sy8: "",
            id: "3008",
            dt2: "",
            sy9: "",
            sy7: "",
            lt11: "",
            sy4: "ܡܟܝܠܢܘܬܐ",
            dt1: "Messen",
            lt10: "mkilonutho",
            sy5: "ܡܟܺܝܠܳܢܽܘܬ݂ܳܐ",
            sy6: "",
            lt12: "",
            dt3: ""
        },
        {
            lt12: "",
            id: "5346",
            sy4: "ܙܟܘܬܐ",
            sy8: "",
            sy9: "",
            sy7: "",
            sy5: "ܙܳܟܽܘܬܰܐ",
            dt3: "",
            sy6: "",
            lt11: "ܙܟܘܬܐ",
            lt10: "ܙܟܘܬܐ",
            dt1: "Zokuta",
            dt2: ""
        },
        {
            sy5: "ܫܽܘܡܺܝܪܳܝܳܐ",
            lt11: "",
            id: "4312",
            lt10: "shumiroyo",
            sy4: "ܫܘܡܝܪܝܐ",
            dt3: "",
            sy6: "",
            sy9: "",
            sy8: "",
            dt2: "",
            sy7: "",
            lt12: "",
            dt1: "Sumerer"
        },
        {
            dt3: "",
            sy8: "",
            lt11: "ܟܬܝܐ",
            sy9: "",
            sy5: "ܟܰܬܝܰܐ",
            sy6: "",
            dt2: "",
            sy4: "ܟܬܝܐ",
            sy7: "",
            lt10: "ܟܬܝܐ",
            lt12: "",
            id: "2432",
            dt1: "Katja"
        },
        {
            lt12: "",
            id: "1582",
            sy9: "",
            lt11: "",
            sy5: "ܡܰܐܟܺܝܢܰܐ ܕܰܡܫܺܝܓ݂ܳܐ ܠܰܩ̈ܢܶܐ",
            sy4: "ܡܐܟܝܢܐ ܕܡܫܝܓܐ ܠܩܢܐ",
            sy6: "",
            sy8: "",
            lt10: "makina da`mshiğo laqne",
            dt1: "Geschirrspülmaschine",
            sy7: "",
            dt2: "Spülmaschine",
            dt3: ""
        },
        {
            sy8: "",
            sy4: "ܒܟܠ ܙܒܢ",
            sy9: "",
            lt12: "",
            sy5: "ܒܟܽܠ ܙܒܰܢ",
            dt2: "allezeit",
            lt10: "bkul zban",
            id: "5839",
            lt11: "",
            dt1: "allemal",
            sy6: "",
            dt3: "",
            sy7: ""
        },
        {
            dt3: "",
            id: "2167",
            lt10: "ܐܝܠܣܒܪܐ",
            sy5: "ܐܺܝܠܣܰܒܪܳܐ",
            sy6: "",
            sy9: "",
            sy7: "",
            dt1: "Ilsabro",
            sy8: "",
            sy4: "ܐܝܠܣܒܪܐ",
            lt11: "ܐܝܠܣܒܪܐ",
            lt12: "",
            dt2: ""
        },
        {
            lt10: "qoruyto",
            dt3: "",
            sy9: "",
            sy4: "ܩܪܘܝܬܐ",
            lt12: "",
            dt1: "Leserin",
            sy7: "",
            lt11: "",
            sy8: "",
            dt2: "",
            sy6: "",
            sy5: "ܩܳܪܽܘܝܬܳܐ",
            id: "2770"
        },
        {
            lt10: "aykan ithayk",
            sy7: "ܐܰܝܟܰܢ ܐܺܝܬ݂ܰܝܟܝ̱",
            sy5: "ܐܰܝܟܰܢ ܐܺܝܬ݂ܰܝܟ",
            sy8: "ܐܝܟܢܐ",
            sy9: "ܐܰܝܟܰܢܳܐ",
            sy6: "ܐܝܟܢ ܐܝܬܝܟܝ",
            sy4: "ܐܝܟܢ ܐܝܬܝܟ",
            lt11: "aykan ithayk",
            dt1: "wie geht’s dir",
            id: "5085",
            lt12: "aykano",
            dt3: "wie steht's",
            dt2: "wie geht es dir"
        },
        {
            dt1: "Orhan",
            id: "3388",
            dt2: "",
            lt10: "ܐܪܗܢ",
            lt12: "",
            sy8: "",
            lt11: "ܐܪܗܢ",
            sy7: "",
            sy9: "",
            dt3: "",
            sy6: "",
            sy4: "ܐܪܗܢ",
            sy5: "ܐܳܪܗܰܢ"
        },
        {
            dt3: "",
            lt10: "tumo",
            lt11: "",
            lt12: "",
            dt2: "",
            id: "2546",
            sy5: "ܬܽܘܡܳܐ",
            sy4: "ܬܘܡܐ",
            sy6: "",
            sy8: "",
            sy9: "",
            dt1: "Knoblauch",
            sy7: ""
        },
        {
            lt12: "",
            sy8: "",
            dt3: "",
            sy9: "",
            dt1: "Verständnis",
            sy6: "",
            sy4: "ܦܘܗܡܐ",
            dt2: "",
            id: "4811",
            lt10: "fuhomo",
            sy7: "",
            sy5: "ܦܽܘܗܳܡܳܐ",
            lt11: ""
        },
        {
            id: "3643",
            sy8: "",
            dt3: "",
            sy4: "ܐܘܪܚܐ ܢܡܘܣܝܬܐ",
            lt10: "urĥo nomusoyto",
            sy5: "ܐܽܘܪܚܳܐ ܢܳܡܽܘܣܳܝܬܳܐ",
            lt12: "",
            sy6: "",
            sy7: "",
            dt2: "",
            sy9: "",
            dt1: "Rechtsmittel",
            lt11: ""
        },
        {
            sy7: "",
            lt12: "",
            sy6: "",
            dt3: "",
            sy5: "ܡܶܛܽܘܠ",
            dt2: "wegen",
            lt11: "",
            dt1: "weil",
            sy9: "",
            sy4: "ܡܛܘܠ",
            lt10: "mețul",
            id: "5026",
            sy8: ""
        },
        {
            sy5: "ܟܽܘܪ̈ܕܳܝܶܐ",
            dt2: "",
            lt10: "kurdoye",
            id: "2673",
            dt1: "Kurden",
            lt11: "",
            sy9: "",
            lt12: "",
            dt3: "",
            sy4: "ܟܘܪܕܝܐ",
            sy8: "",
            sy7: "",
            sy6: ""
        },
        {
            sy6: "",
            dt2: "",
            lt11: "",
            sy5: "ܡܶܠܬ݂ܳܐ ܕܰܐܠܳܗܳܐ",
            lt12: "",
            sy9: "",
            sy7: "",
            dt3: "",
            lt10: "meltho d`aloho",
            id: "5165",
            sy4: "ܡܠܬܐ ܕܐܠܗܐ",
            dt1: "Wort Gottes",
            sy8: ""
        },
        {
            lt12: "ܗܕܘܪܡ",
            dt2: "",
            sy7: "ܗܰܕܽܘܪܰܡ",
            sy6: "ܗܕܘܪܡ",
            dt1: "Hadoram",
            lt11: "ܗܕܘܪܡ",
            sy9: "",
            sy4: "ܗܕܘܪܡ",
            dt3: "",
            sy8: "",
            id: "1811",
            lt10: "ܗܕܘܪܡ",
            sy5: "ܗܰܕܳܘܪܰܡ"
        },
        {
            dt3: "",
            dt1: "kastrieren",
            sy4: "ܣܪܣ",
            sy7: "ܥܒܰܕ ܡܣܰܪܣܳܐ",
            sy6: "ܥܒܕ ܡܣܪܣܐ",
            sy5: "ܣܰܪܶܣ",
            lt10: "sares",
            lt12: "",
            dt2: "",
            id: "2428",
            sy9: "",
            lt11: "ĉbađ msarso",
            sy8: ""
        },
        {
            sy9: "",
            lt10: "dnaĥ",
            sy8: "",
            dt1: "aufgehen",
            lt12: "",
            dt2: "erscheinen",
            sy5: "ܕܢܰܚ",
            dt3: "",
            sy7: "",
            lt11: "",
            sy4: "ܕܢܚ",
            sy6: "",
            id: "354"
        },
        {
            sy7: "",
            lt10: "qushoyo",
            dt1: "Härtung",
            sy4: "ܩܘܫܝܐ",
            lt11: "",
            dt3: "",
            lt12: "",
            sy6: "",
            sy5: "ܩܽܘܫܳܝܳܐ",
            sy9: "",
            id: "6121",
            sy8: "",
            dt2: ""
        },
        {
            dt1: "Gitarre",
            sy6: "",
            dt3: "",
            sy7: "",
            id: "1645",
            sy8: "",
            sy9: "",
            lt10: "qithoro",
            sy5: "ܩܺܝܬ݂ܳܪܳܐ",
            sy4: "ܩܝܬܪܐ",
            lt11: "",
            lt12: "",
            dt2: "Harfe"
        },
        {
            lt11: "",
            sy8: "",
            dt3: "",
            sy7: "",
            sy9: "",
            sy6: "",
            lt12: "",
            sy4: "ܬܓܪܘܬܐ ܠܚܘܕܝܬܐ",
            sy5: "ܬܰܓܳܪܽܘܬ݂ܳܐ ܠܚܽܘܕܳܝܬܳܐ",
            lt10: "tagorutho lĥuđoyto",
            dt2: "",
            id: "3086",
            dt1: "Monopol"
        },
        {
            id: "2551",
            sy7: "ܒܫܳܠܳܐ",
            lt10: "shloqo",
            sy9: "ܪܶܬ݂ܚܳܐ",
            sy4: "ܫܠܩܐ",
            lt11: "bsholo",
            sy8: "ܪܬܚܐ",
            dt1: "Kochen",
            dt2: "",
            dt3: "",
            sy5: "ܫܠܳܩܳܐ",
            sy6: "ܒܫܠܐ",
            lt12: "rethĥo"
        },
        {
            lt10: "mqadsho",
            id: "1620",
            sy7: "ܡܒܰܪܟ݂ܳܐ",
            sy8: "",
            lt11: "mbarkho",
            sy9: "",
            sy5: "ܡܩܰܕܫܳܐ",
            lt12: "",
            sy6: "ܡܒܪܟܐ",
            dt2: "gesegnet",
            sy4: "ܡܩܕܫܐ",
            dt3: "",
            dt1: "geweiht"
        },
        {
            sy6: "ܢܩܦܝܘܬܐ",
            sy7: "ܢܶܩܦܳܝܽܘܬ݂ܳܐ",
            sy4: "ܢܘܩܦܐ",
            sy9: "",
            lt11: "neqfoyutho",
            dt1: "Nachfolge",
            sy5: "ܢܽܘܩܳܦܳܐ",
            dt2: "",
            lt12: "",
            sy8: "",
            id: "3156",
            dt3: "",
            lt10: "nuqofo"
        },
        {
            sy4: "ܡܬܩܪܒܢܘܬܐ",
            sy9: "ܡܶܬ݂ܡܰܛܝܳܢܽܘܬ݂ܳܐ",
            sy6: "ܨܘܒܐ",
            sy8: "ܡܬܡܛܝܢܘܬܐ",
            dt2: "",
            lt12: "methmațyonutho",
            id: "5969",
            lt11: "ŝaubo",
            lt10: "methqarbonutho",
            sy7: "ܨܰܘܒܳܐ",
            dt3: "",
            sy5: "ܡܶܬ݂ܩܰܪܒܳܢܽܘܬ݂ܳܐ",
            dt1: "Annäherung"
        },
        {
            lt10: "lo triŝo",
            id: "1247",
            dt3: "",
            dt1: "falsch",
            sy6: "ܦܘܕܐ",
            lt12: "",
            sy8: "",
            sy5: "ܠܳܐ ܬܪܺܝܨܳܐ",
            dt2: "",
            sy7: "ܦܰܘܕܳܐ",
            lt11: "faudo",
            sy4: "ܠܐ ܬܪܝܨܐ",
            sy9: ""
        },
        {
            sy6: "",
            lt10: "aylo",
            sy4: "ܐܝܠܐ",
            sy9: "",
            dt3: "",
            sy5: "ܐܰܝܠܳܐ",
            lt12: "",
            dt1: "Hirsch",
            sy7: "",
            lt11: "",
            id: "2019",
            dt2: "",
            sy8: ""
        },
        {
            dt3: "",
            sy4: "ܟܘܢܝܐ",
            id: "3162",
            sy5: "ܟܽܘܢܳܝܳܐ",
            lt11: "shmo ĥroyo",
            sy7: "ܫܡܳܐ ܐ̱ܚܪܳܝܳܐ",
            lt10: "kunoyo",
            sy9: "",
            dt2: "Zunahme",
            sy6: "ܫܡܐ ܐܚܪܝܐ",
            dt1: "Nachname",
            sy8: "",
            lt12: ""
        },
        {
            id: "1417",
            sy6: "",
            dt3: "",
            sy4: "ܪܒܝܥܐ",
            sy7: "",
            sy5: "ܪܒܺܝܥܳܐ",
            lt11: "",
            sy8: "",
            lt12: "",
            dt2: "",
            sy9: "",
            dt1: "Frühling",
            lt10: "rbiĉo"
        },
        {
            dt2: "",
            sy5: "ܡܰܣܬܰܪ ܠܟܽܠ ܢܳܫ̈ܳܐ ܕܟܳܪܳܚܰܡܢܳܐ",
            dt1: "Schütze die die ich liebe",
            sy6: "",
            lt12: "",
            dt3: "",
            sy8: "",
            lt11: "",
            sy4: "ܡܣܬܪ ܠܟܠ ܢܫܐ ܕܟܪܚܡܢܐ",
            sy9: "",
            sy7: "",
            id: "4058",
            lt10: "mastar lkul nosho dkoroĥamno"
        },
        {
            sy8: "ܚܓܐ",
            lt11: "ĉido",
            sy7: "ܥܺܐܕܳܐ",
            dt2: "",
            sy6: "ܥܐܕܐ",
            sy9: "ܚܰܓܳܐ",
            sy4: "ܩܘܕܫܐ",
            id: "6248",
            dt1: "Zelebration",
            lt10: "qudosho",
            dt3: "",
            lt12: "ĥago",
            sy5: "ܩܽܘܕܳܫܳܐ"
        },
        {
            sy7: "",
            sy6: "",
            sy4: "ܫܥܬ ܚܠܐ",
            sy5: "ܫܳܥܰܬ݂ ܚܶܠܐܳ",
            sy9: "",
            sy8: "",
            lt11: "",
            id: "3836",
            dt3: "",
            dt2: "",
            dt1: "Sanduhr",
            lt10: "shoĉath ĥelo",
            lt12: ""
        },
        {
            sy5: "ܒܶܝܬ݂ ܠܒܽܘ̈ܫܶܐ",
            dt1: "Garderobe",
            sy6: "",
            sy9: "",
            dt2: "",
            sy8: "",
            lt10: "beth lbushe",
            id: "1475",
            dt3: "",
            lt11: "",
            sy7: "",
            sy4: "ܒܝܬ ܠܒܘܫܐ",
            lt12: ""
        },
        {
            lt12: "ܫܒܘ",
            sy8: "",
            dt1: "Schabo",
            id: "3863",
            sy4: "ܫܒܐ",
            dt3: "",
            sy5: "ܫܰܒܳܐ",
            sy7: "ܫܰܒܳܘ",
            sy6: "ܫܒܘ",
            lt11: "ܫܒܐ",
            sy9: "",
            lt10: "ܫܒܐ",
            dt2: ""
        },
        {
            dt1: "Amtsstab",
            dt3: "",
            sy9: "",
            sy4: "ܡܩܝܪ",
            sy5: "ܡܰܐܩܺܝܪ",
            lt12: "",
            lt11: "",
            sy8: "",
            lt10: "maqir",
            id: "5889",
            sy6: "",
            dt2: "",
            sy7: ""
        },
        {
            dt1: "zu spät",
            sy9: "ܠܩܺܝܫܳܝܳܐ",
            sy5: "ܡܥܰܘܟ݂ܳܐ",
            lt11: "lqisho",
            id: "5354",
            lt10: "mĉaukho",
            dt3: "",
            sy7: "ܠܩܺܝܫܳܐ",
            lt12: "lqishoyo",
            dt2: "",
            sy4: "ܡܥܘܟܐ",
            sy6: "ܠܩܝܫܐ",
            sy8: "ܠܩܝܫܝܐ"
        },
        {
            sy6: "ܥܪܒܐ",
            sy7: "ܥܰܪܒܳܐ",
            lt10: "maĉerbo",
            sy4: "ܡܥܪܒܐ",
            dt2: "Abendland",
            dt1: "Westen",
            id: "5076",
            sy5: "ܡܰܥܶܪܒܳܐ",
            lt12: "",
            sy9: "",
            lt11: "ĉarbo",
            dt3: "West",
            sy8: ""
        },
        {
            sy9: "",
            sy6: "",
            dt1: "Sonne",
            sy7: "",
            id: "4193",
            lt11: "",
            lt10: "shemsho",
            sy8: "",
            dt2: "",
            dt3: "",
            sy5: "ܫܶܡܫܶܐ",
            lt12: "",
            sy4: "ܫܡܫܐ"
        },
        {
            dt2: "",
            lt12: "",
            lt10: "zedqo d`guboyo",
            sy8: "",
            sy9: "",
            sy5: "ܙܶܕܩܳܐ ܕܓܽܘܒܳܝܳܐ",
            dt3: "",
            lt11: "",
            sy4: "ܙܕܩܐ ܕܓܘܒܝܐ",
            sy6: "",
            dt1: "Wahlrecht",
            id: "4944",
            sy7: ""
        },
        {
            sy9: "",
            sy6: "",
            lt12: "",
            lt10: "dunbo d`ĉeqarbo",
            lt11: "",
            sy7: "",
            sy4: "ܕܘܢܒܐ ܕܥܩܪܒܐ",
            dt3: "",
            sy8: "",
            id: "6382",
            sy5: "ܕܽܘܢܒܳܐ ܕܥܶܩܰܪܒܳܐ",
            dt1: "Skorpionenschwanz",
            dt2: ""
        },
        {
            sy7: "",
            dt3: "",
            lt11: "",
            id: "3130",
            lt12: "",
            lt10: "musiqoro",
            sy5: "ܡܽܘܣܺܝܩܳܪܳܐ",
            sy8: "",
            sy6: "",
            sy9: "",
            dt2: "",
            sy4: "ܡܘܣܝܩܪܐ",
            dt1: "Musiker"
        },
        {
            dt1: "Diener",
            lt11: "mshamshono",
            sy8: "",
            lt12: "",
            dt2: "Diakon",
            id: "887",
            sy5: "ܫܰܡܳܫܳܐ",
            sy9: "",
            sy7: "ܡܫܰܡܫܳܢܳܐ",
            dt3: "",
            sy4: "ܫܡܫܐ",
            lt10: "shamosho",
            sy6: "ܡܫܡܫܢܐ"
        },
        {
            sy5: "ܛܽܘܦܣܳܐ",
            dt1: "Idol",
            sy6: "",
            sy8: "",
            dt3: "Symbol",
            lt11: "",
            sy7: "",
            lt12: "",
            lt10: "țufso",
            sy4: "ܛܘܦܣܐ",
            sy9: "",
            id: "2151",
            dt2: "Vorbild"
        },
        {
            sy8: "",
            dt3: "",
            lt10: "ܓܪܝܓܘܪܝܘܣ",
            dt1: "Georgius",
            sy9: "",
            dt2: "",
            lt12: "",
            lt11: "ܓܪܝܓܘܪܝܘܣ",
            sy7: "",
            sy5: "ܓܪܝܓܘܪܝܘܣ",
            sy4: "ܓܪܝܓܘܪܝܘܣ",
            id: "1567",
            sy6: ""
        },
        {
            dt2: "schuhlos",
            sy8: "",
            id: "447",
            dt1: "barfuss",
            sy4: "ܫܡܝܛܐ",
            sy7: "ܫܡܺܝܛܳܝܳܐ",
            lt10: "shmițo",
            dt3: "ohne Schuhe",
            sy6: "ܫܡܝܛܝܐ",
            sy5: "ܫܡܺܝܛܳܐ",
            lt12: "",
            lt11: "shmițoyo",
            sy9: ""
        },
        {
            lt12: "",
            dt3: "",
            sy8: "",
            sy7: "",
            dt1: "Fehler",
            lt10: "faudo",
            sy5: "ܦܰܘܕܳܐ",
            dt2: "Irrtum",
            lt11: "",
            sy6: "",
            id: "1275",
            sy4: "ܦܘܕܐ",
            sy9: ""
        },
        {
            dt2: "Meinung",
            sy5: "ܪܶܢܝܳܐ",
            lt10: "renyo",
            dt1: "Gedanke",
            sy6: "ܚܘܫܒܐ",
            lt11: "ĥushubo",
            lt12: "reĉyono",
            id: "1507",
            dt3: "Idee",
            sy4: "ܪܢܝܐ",
            sy9: "ܪܶܥܝܳܢܳܐ",
            sy7: "ܚܽܘܫܳܒܳܐ",
            sy8: "ܪܥܝܢܐ"
        },
        {
            dt2: "",
            id: "2451",
            sy8: "",
            dt1: "Kellnerin",
            sy4: "ܡܕܝܠܢܝܬܐ",
            sy9: "",
            dt3: "",
            lt10: "mdaylonitho",
            sy5: "ܡܕܰܝܠܳܢܺܝܬ݂ܳܐ",
            lt12: "",
            sy6: "",
            sy7: "",
            lt11: ""
        },
        {
            dt3: "",
            dt1: "anbellen",
            sy9: "",
            id: "5915",
            sy5: "ܢܒܰܚ",
            sy7: "",
            lt12: "",
            sy8: "",
            lt10: "nbaĥ",
            lt11: "",
            dt2: "",
            sy4: "ܢܒܚ",
            sy6: ""
        },
        {
            sy4: "ܗܘܪܝܙܘܢ",
            lt12: "",
            id: "2052",
            lt11: "urizon",
            dt1: "Horizont",
            sy9: "",
            lt10: "hurizon",
            dt2: "",
            sy6: "ܐܘܪܝܙܘܢ",
            sy7: "ܐܽܘܪܺܝܙܳܘܢ",
            sy5: "ܗܽܘܪܺܝܙܳܘܢ",
            sy8: "",
            dt3: ""
        },
        {
            sy8: "",
            dt2: "",
            sy9: "",
            lt10: "ܛܘܪܐ",
            dt3: "",
            sy5: "ܛܽܘܪܳܐ",
            sy6: "",
            dt1: "Turo",
            sy4: "ܛܘܪܐ",
            lt12: "",
            lt11: "ܛܘܪܐ",
            id: "4605",
            sy7: ""
        },
        {
            dt1: "Eckball",
            lt12: "qurnitho",
            dt2: "Eckstoß",
            sy5: "ܡܚܽܘܬܳܐ ܕܩܽܘܪܢܺܝܬ݂ܳܐ",
            sy9: "ܩܽܘܪܢܺܝܬ݂ܳܐ",
            sy6: "ܡܚܘܬܐ ܕܙܘܝܬܐ",
            dt3: "",
            lt11: "mĥuto d`zowitho",
            id: "993",
            sy4: "ܡܚܘܬܐ ܕܩܘܪܢܝܬܐ",
            lt10: "mĥuto d`qurnitho",
            sy8: "ܩܘܪܢܝܬܐ",
            sy7: "ܡܚܽܘܬܳܐ ܕܙܳܘܺܝܬ݂ܳܐ"
        },
        {
            sy5: "ܡܺܝܠܝܽܘܢ",
            id: "3035",
            dt2: "",
            sy6: "",
            lt12: "",
            sy4: "ܡܝܠܝܘܢ",
            lt11: "",
            sy7: "",
            dt1: "Million",
            lt10: "milyun",
            dt3: "",
            sy8: "",
            sy9: ""
        },
        {
            dt3: "Schweiss",
            sy5: "ܕܽܘܥܬ݂ܳܐ",
            lt11: "",
            dt1: "Schweiß",
            id: "4073",
            dt2: "Schwitzen",
            sy6: "",
            sy9: "",
            lt10: "duĉtho",
            sy4: "ܕܘܥܬܐ",
            sy8: "",
            lt12: "",
            sy7: ""
        },
        {
            lt10: "zuze d`armlutho",
            sy5: "ܙܽܘ̈ܙܶܐ ܕܐܰܪܡܠܽܘܬ݂ܳܐ",
            dt2: "Witwenrente",
            dt1: "Witwengeld",
            sy6: "",
            lt11: "",
            lt12: "",
            sy8: "",
            sy7: "",
            dt3: "",
            sy4: "ܙܘܙܐ ܕܐܪܡܠܘܬܐ",
            sy9: "",
            id: "5138"
        },
        {
            sy9: "",
            sy5: "ܟܺܐܦܽܘܢܳܝܳܐ",
            sy7: "ܫܳܚܽܘܪܳܐ",
            sy6: "ܫܚܘܪܐ",
            dt2: "",
            sy8: "",
            id: "4252",
            lt12: "",
            lt10: "kifunoyo ",
            dt3: "",
            sy4: "ܟܐܦܘܢܝܐ",
            dt1: "steinig",
            lt11: "shoĥuro"
        },
        {
            dt2: "",
            sy9: "",
            dt1: "Du hast Dich sehr verändert",
            dt3: "",
            sy8: "",
            id: "972",
            sy5: "ܐܰܢ̱ܬ ܫܰܚܠܶܦܬ ܢܰܦܫܳܟ ܣܰܓܺܝ",
            sy6: "",
            lt10: "at shaĥleft nafshokh sagi",
            lt12: "",
            sy4: "ܐܢܬ ܫܚܠܦܬ ܢܦܫܟ ܣܓܝ",
            lt11: "",
            sy7: ""
        },
        {
            dt2: "",
            sy4: "ܥܩܒܬܢܐ",
            sy9: "",
            sy7: "",
            dt3: "",
            lt10: "ĉeqbthono",
            lt11: "",
            sy5: "ܥܶܩܒܬ݂ܳܢܳܐ",
            sy8: "",
            lt12: "",
            id: "1350",
            sy6: "",
            dt1: "Forscher"
        },
        {
            lt10: "methgalglonutho",
            dt2: "",
            sy8: "",
            sy5: "ܡܶܬ݂ܓܰܠܓܠܳܢܽܘܬ݂ܳܐ",
            sy4: "ܡܬܓܠܓܠܢܘܬܐ",
            dt1: "Abrundung",
            id: "5646",
            lt11: "",
            dt3: "",
            sy7: "",
            lt12: "",
            sy9: "",
            sy6: ""
        },
        {
            sy4: "ܗܢܘܢ ܟܬܒܝܢ",
            dt1: "Sie schreiben",
            sy5: "ܗܶܢܽܘܢ ܟܳܬ݂ܒܺܝܢ",
            sy9: "",
            lt12: "",
            sy6: "ܗܢܝܢ ܟܬܒܢ",
            id: "4147",
            dt3: "",
            lt11: "henen kothbon",
            sy7: "ܗܶܢܶܝܢ ܟܳܬ݂ܒܳܢ",
            dt2: "Sie sind am schreiben",
            lt10: "henun kothbin",
            sy8: ""
        },
        {
            sy8: "",
            sy4: "ܩܕܡܐܝܬ",
            sy7: "ܩܰܕܡܳܝܰܬ݂",
            dt1: "zuallererst",
            sy6: "ܩܕܡܝܬ",
            sy5: "ܩܰܕܡܳܐܝܺܬ݂",
            id: "6316",
            dt3: "",
            sy9: "",
            lt10: "qađmoith",
            dt2: "zu aller erst",
            lt12: "",
            lt11: "qađmoyath"
        },
        {
            dt3: "",
            sy9: "",
            lt12: "",
            lt11: "",
            dt2: "",
            sy8: "",
            sy6: "",
            id: "2122",
            lt10: "koqoreno kthowo",
            sy4: "ܟܩܪܐ ܐ̱ܢܐ ܟܬ݂ܘܐ",
            sy5: "ܟܩܪܐ ܐ̱ܢܳܐ ܟܬ݂ܘܐ",
            dt1: "Ich lese ein Buch",
            sy7: ""
        },
        {
            id: "612",
            sy8: "",
            lt11: "",
            sy7: "",
            sy6: "",
            lt12: "",
            lt10: "dabeshto",
            dt3: "",
            sy9: "",
            dt1: "Biene",
            sy5: "ܕܰܒܶܫܬܳܐ",
            dt2: "",
            sy4: "ܕܒܫܬܐ"
        },
        {
            dt2: "Kameramann",
            lt11: "",
            id: "1353",
            lt12: "",
            sy9: "",
            dt1: "Fotograf",
            sy8: "",
            sy6: "",
            sy7: "",
            sy4: "ܨܝܪܐ",
            lt10: "ŝayoro",
            dt3: "",
            sy5: "ܨܰܝܳܪܳܐ"
        },
        {
            dt1: "abends",
            sy9: "",
            sy6: "",
            dt3: "",
            dt2: "",
            sy5: "ܒܪܰܡܫܳܐ",
            sy7: "",
            sy4: "ܒܪܡܫܐ",
            lt10: "b`ramsho",
            sy8: "",
            lt11: "",
            lt12: "",
            id: "78"
        },
        {
            sy9: "",
            lt11: "ܥܒܕܝܫܘܥ",
            lt10: "ܥܒܕܝܫܘܥ",
            sy6: "",
            sy7: "",
            dt1: "Ebedjeschu",
            sy5: "ܥܶܒܶܕܝܶܫܽܘܥ",
            sy4: "ܥܒܕܝܫܘܥ",
            dt2: "",
            sy8: "",
            id: "987",
            lt12: "",
            dt3: ""
        },
        {
            dt1: "schöne Jungen",
            id: "4009",
            sy8: "",
            sy6: "",
            sy4: "ܛܠܝ̈ܐ ܫܦܝܪ̈ܐ",
            dt3: "",
            sy7: "",
            sy9: "",
            dt2: "",
            lt11: "",
            lt10: "țloye shafire",
            sy5: "ܛܠܳܝ̈ܶܐ ܫܰܦܺܝܪܶ̈ܐ",
            lt12: ""
        },
        {
            sy8: "",
            sy6: "ܡܬܝܢ",
            lt10: "ܡܬܝܢ",
            id: "2947",
            lt11: "ܡܬܝܢ",
            dt3: "",
            lt12: "ܡܬܝܢ",
            dt1: "Martin",
            sy9: "",
            sy7: "ܡܶܬܺܝܢ",
            sy4: "ܡܬܝܢ",
            dt2: "Metin",
            sy5: "ܡܰܬܺܝܢ"
        },
        {
            sy7: "",
            dt3: "",
            sy4: "ܒܪܩܘܩܝܐ",
            sy8: "",
            lt10: "barquqyo",
            lt12: "",
            sy9: "",
            dt2: "",
            id: "265",
            lt11: "",
            dt1: "Aprikose",
            sy5: "ܒܰܪܩܽܘܩܝܳܐ",
            sy6: ""
        },
        {
            dt1: "Holzspähne",
            dt3: "",
            sy5: "ܨܳܠܺܝܚܳܐ",
            sy9: "",
            lt10: "ŝoliĥo",
            dt2: "",
            lt12: "",
            id: "6051",
            sy7: "ܓܪܳܕܬ݂ܳܐ",
            sy8: "",
            sy4: "ܨܠܝܚܐ",
            lt11: "grodtho",
            sy6: "ܓܪܕܬܐ"
        },
        {
            lt11: "lĥud aloho mŝe dantaras li",
            sy7: "ܠܚܽܘܕ ܐܰܠܳܗܳܐ ܡܨܶܐ ܕܰܢܬܰܪܰܣ ܠܺܝ",
            dt3: "",
            lt12: "",
            sy5: "ܠܚܽܘܕ ܡܳܪܝܳܐ ܡܨܶܐ ܕܢܶܗܘܳܐ ܠܺܝ ܕܰܝܳܢܳܐ",
            dt2: "",
            lt10: "lĥud moryo mŝe dnehwe li dayono",
            sy9: "",
            dt1: "Nur Gott kann mich richten",
            sy6: "ܠܚܘܕ ܐܠܗܐ ܡܨܐ ܕܢܬܪܣ ܠܝ",
            sy8: "",
            sy4: "ܠܚܘܕ ܡܪܝܐ ܡܨܐ ܕܢܗܘܐ ܠܝ ܕܝܢܐ",
            id: "3317"
        },
        {
            sy9: "",
            lt10: "fulĥon da`msombrisho",
            lt11: "",
            lt12: "",
            sy6: "",
            dt1: "Strafarbeit",
            dt3: "",
            sy8: "",
            id: "4287",
            dt2: "",
            sy7: "",
            sy4: "ܦܽܘܠܚܳܢ ܡܣܳܡܒܪܺܝܫܳܐ",
            sy5: "ܦܽܘܠܚܳܢ ܡܣܳܡܒܪܺܝܫܳܐ"
        },
        {
            sy8: "",
            sy4: "ܢܣܝܘܢܐ",
            lt12: "",
            sy7: "",
            id: "3516",
            sy9: "",
            sy6: "",
            lt10: "nesyuno",
            sy5: "ܢܶܣܝܽܘܢܳܐ",
            dt2: "",
            dt1: "Probe",
            dt3: "",
            lt11: ""
        },
        {
            id: "2818",
            lt11: "krihuth reĥmtho",
            dt2: "",
            sy7: "ܟܪܺܝܗܽܘܬ݂ ܪܶܚܡܬ݂ܳܐ",
            sy9: "",
            sy8: "",
            dt1: "Liebeskummer",
            sy6: "ܟܪܝܗܘܬ ܪܚܡܬܐ",
            sy4: "ܟܪܝܗܘܬ ܚܘܒܐ",
            lt12: "",
            lt10: "krihuth ĥubo",
            sy5: "ܟܪܺܝܗܽܘܬ݂ ܚܽܘܒܳܐ",
            dt3: ""
        },
        {
            sy8: "",
            lt12: "",
            sy7: "",
            dt3: "",
            sy4: "ܩܡܝܫܘܥ",
            lt10: "ܩܡܝܫܘܥ",
            sy5: "ܩܳܡܝܶܫܽܘܥ",
            sy9: "",
            lt11: "ܩܡܝܫܘܥ",
            dt1: "Qomjeschu",
            sy6: "",
            id: "3564",
            dt2: ""
        },
        {
            dt1: "fahren",
            sy5: "ܪܕܳܐ",
            lt11: "rođe",
            lt12: "dbar",
            dt2: "",
            sy6: "ܪܕܐ",
            sy4: "ܪܕܐ",
            id: "1233",
            sy7: "ܪܳܕܶܐ",
            sy8: "ܕܒܪ",
            sy9: "ܕܒܰܪ",
            dt3: "",
            lt10: "rđo"
        },
        {
            dt3: "",
            lt12: "",
            id: "1948",
            sy7: "ܡܫܰܚܢܳܢܳܐ",
            sy6: "ܡܫܚܢܢܐ",
            dt1: "Heizung",
            lt11: "mshaĥnono",
            sy9: "",
            sy5: "ܡܫܰܚܢܳܢܺܝܬ݂ܳܐ",
            sy4: "ܡܫܚܢܢܝܬܐ",
            lt10: "mshaĥnonitho",
            sy8: "",
            dt2: ""
        },
        {
            lt10: "soğo",
            dt2: "",
            dt1: "Küken",
            lt12: "",
            sy7: "",
            lt11: "",
            sy4: "ܙܓܐ",
            sy9: "",
            sy6: "",
            sy5: "ܙܳܓ݂ܳܐ",
            id: "2662",
            dt3: "",
            sy8: ""
        },
        {
            sy9: "",
            id: "6214",
            sy4: "ܫܬܐ ܥܐܦܝܢ",
            sy7: "",
            dt1: "sechsmal",
            dt3: "",
            dt2: "",
            lt10: "shto ĉefin",
            sy8: "",
            sy6: "",
            sy5: "ܫܬܳܐ ܥܶܐܦܺܝ̈ܢ",
            lt12: "",
            lt11: ""
        },
        {
            dt2: "Länge",
            sy9: "",
            sy6: "ܢܓܪܐ",
            lt12: "",
            id: "831",
            dt3: "",
            dt1: "Dauer",
            sy7: "ܢܓܳܪܳܐ",
            lt11: "ngoro",
            sy8: "",
            sy4: "ܢܓܝܪܘܬܐ",
            sy5: "ܢܰܓܺܝܪܽܘܬ݂ܳܐ",
            lt10: "nagirutho"
        },
        {
            sy8: "",
            sy7: "ܡܬܰܪܓܡܳܢܳܐ",
            dt3: "",
            dt1: "Dolmetscher",
            lt11: "mtargmono",
            sy9: "",
            lt10: "targmono",
            sy5: "ܬܰܪܓܡܳܢܳܐ",
            id: "913",
            dt2: "Übersetzer",
            sy6: "ܡܬܪܓܡܢܐ",
            sy4: "ܬܪܓܡܢܐ",
            lt12: ""
        },
        {
            lt11: "",
            lt10: "shael",
            dt2: "",
            dt1: "anfragen",
            sy8: "",
            lt12: "",
            dt3: "",
            sy6: "",
            sy4: "ܫܐܠ",
            id: "5931",
            sy7: "",
            sy5: "ܫܰܐܶܠ",
            sy9: ""
        },
        {
            lt11: "",
            sy7: "",
            dt3: "",
            sy5: "ܢܟܳܬܳܐ",
            dt2: "Beißen",
            lt12: "",
            sy9: "",
            lt10: "nkoto",
            dt1: "Beissen",
            sy6: "",
            sy4: "ܢܟܬܐ",
            sy8: "",
            id: "536"
        },
        {
            sy4: "ܕܪܓܐ",
            sy7: "",
            lt11: "",
            lt10: "darğo",
            dt3: "",
            lt12: "",
            sy8: "",
            dt1: "Treppe",
            id: "4559",
            sy9: "",
            sy5: "ܕܰܪܓ݂ܳܐ",
            sy6: "",
            dt2: ""
        },
        {
            dt3: "",
            sy4: "ܫܥܒܕ",
            lt12: "",
            sy8: "",
            dt1: "kolonialisieren",
            dt2: "kolonisieren",
            sy9: "",
            lt10: "shaĉbeđ",
            sy5: "ܫܰܥܒܶܕ",
            id: "2556",
            sy7: "ܫܰܥܡܰܪ",
            sy6: "ܫܥܡܪ",
            lt11: "shaĉmar"
        },
        {
            sy6: "",
            lt10: "ܙܟ݂ܐ",
            sy4: "ܙܟ݂ܐ",
            id: "5248",
            sy7: "",
            sy8: "",
            dt3: "",
            lt11: "ܙܟ݂ܐ",
            sy5: "ܙܰܟ݂ܳܐ",
            dt1: "Zakho",
            lt12: "",
            sy9: "",
            dt2: ""
        },
        {
            dt3: "",
            sy6: "",
            lt11: "",
            lt12: "",
            dt2: "Ozeane",
            dt1: "Meere",
            sy4: "ܝܡܡܐ",
            sy7: "",
            id: "2983",
            sy9: "",
            sy5: "ܝܰܡ̈ܡܶܐ",
            lt10: "yamme",
            sy8: ""
        },
        {
            sy9: "",
            dt1: "Du bist schwul",
            sy8: "",
            id: "971",
            lt10: "brimo at",
            lt11: "",
            lt12: "",
            sy6: "",
            sy4: "ܒܪܝܡܐ ܐܢܬ",
            sy7: "",
            sy5: "ܒܪܺܝܡܳܐ ܐܰܢ̱ܬ",
            dt2: "",
            dt3: ""
        },
        {
            lt11: "shafirto at",
            dt2: "Du bist schön",
            sy9: "",
            dt1: "Du bist hübsch",
            sy7: "ܫܰܦܺܝܪܬܳܐ ܐܰܢ̱ܬܝ̱",
            dt3: "",
            sy6: "ܫܦܝܪܬܐ ܐܢܬܝ",
            sy8: "",
            id: "968",
            lt10: "shafiro at",
            lt12: "",
            sy5: "ܫܰܦܺܝܪܳܐ ܐܰܢ̱ܬ",
            sy4: "ܫܦܝܪܐ ܐܢܬ"
        },
        {
            lt11: "",
            dt2: "",
            sy9: "",
            dt1: "Krankenbett",
            sy8: "",
            sy4: "ܥܪܣܐ ܕܟܪܝܗܐ",
            id: "2630",
            sy7: "",
            lt12: "",
            dt3: "",
            lt10: "ĉarso da`kriho",
            sy5: "ܥܰܪܣܳܐ ܕܰܟܪܺܝܗܳܐ",
            sy6: ""
        },
        {
            lt12: "",
            lt10: "mŝalto",
            sy5: "ܡܨܰܠܬܳܐ",
            id: "2656",
            dt1: "Küchensieb",
            dt3: "",
            sy7: "",
            sy8: "",
            lt11: "",
            dt2: "",
            sy4: "ܡܨܠܬܐ",
            sy9: "",
            sy6: ""
        },
        {
            sy8: "",
            sy6: "",
            dt2: "",
            sy7: "",
            dt1: "Aba",
            lt10: "ܐܒܐ",
            dt3: "",
            sy9: "",
            lt12: "",
            lt11: "ܐܒܐ",
            sy4: "ܐܒܐ",
            id: "56",
            sy5: "ܐܰܒܰܐ"
        },
        {
            dt3: "",
            lt12: "",
            sy8: "",
            lt11: "",
            sy4: "ܛܝܡܐ ܕܪܒܝܬܐ",
            dt1: "Zinssatz",
            lt10: "țime rebitho",
            dt2: "",
            id: "5332",
            sy6: "",
            sy7: "",
            sy9: "",
            sy5: "ܛܺܝܡܶܐ ܕܪܶܒܺܝܬ݂ܳܐ"
        },
        {
            id: "5469",
            sy7: "",
            sy5: "ܩܽܘܢܳܥܳܐ",
            sy4: "ܩܽܘܢܳܥܳܐ",
            sy6: "",
            lt11: "",
            sy9: "",
            dt2: "",
            lt12: "",
            sy8: "",
            dt1: "Maske",
            lt10: "qunoĉo",
            dt3: ""
        },
        {
            dt2: "",
            lt10: "shkhiro",
            sy8: "",
            lt12: "",
            sy9: "",
            sy4: "ܫܟܝܪܐ",
            sy7: "",
            id: "6128",
            sy5: "ܫܟ݂ܺܝܪܳܐ",
            dt3: "",
            sy6: "",
            dt1: "obszön",
            lt11: ""
        },
        {
            sy5: "ܡܓܰܪܕܳܐ",
            dt2: "",
            lt10: "mgardo",
            lt11: "lo shariro",
            sy9: "",
            sy4: "ܡܓܪܕܐ",
            sy6: "ܠܐ ܫܪܝܪܐ",
            lt12: "",
            dt1: "abstrakt",
            sy7: "ܠܳܐ ܫܰܪܺܝܪܳܐ",
            sy8: "",
            dt3: "",
            id: "5673"
        },
        {
            lt10: "fulițiqoyo",
            dt1: "Politiker",
            sy7: "",
            sy8: "",
            sy9: "",
            lt12: "",
            sy6: "",
            id: "3482",
            sy4: "ܦܘܠܝܛܝܩܝܐ",
            sy5: "ܦܽܘܠܺܝܛܺܝܩܳܝܳܐ",
            lt11: "",
            dt3: "",
            dt2: "politisch"
        },
        {
            sy9: "",
            sy6: "",
            dt1: "Barschlomo",
            sy8: "",
            id: "478",
            lt11: "ܒܪܫܠܡܐ",
            dt2: "",
            dt3: "",
            lt10: "ܒܪܫܠܡܐ",
            lt12: "",
            sy4: "ܒܪܫܠܡܐ",
            sy5: "ܒܰܪܫܠܳܡܳܐ",
            sy7: ""
        },
        {
            id: "1072",
            sy7: "",
            sy9: "",
            dt1: "Elhanan",
            lt11: "ܐܠܚܢܢ",
            sy5: "ܐܶܠܰܚܢܰܢ",
            lt12: "",
            dt2: "Ilhanan",
            sy8: "",
            sy4: "ܐܠܚܢܢ",
            sy6: "",
            lt10: "ܐܠܚܢܢ",
            dt3: ""
        },
        {
            sy7: "",
            dt1: "Agonie",
            sy8: "",
            lt12: "",
            sy4: "ܬܟܬܘܫ ܡܘܬܐ",
            sy6: "",
            sy5: "ܬܰܟ݂ܬܽܘܫ ܡܰܘܬܳܐ",
            id: "5784",
            lt11: "",
            dt3: "",
            dt2: "",
            sy9: "",
            lt10: "takhtush mauto"
        },
        {
            sy5: "ܒܝܰܕ",
            sy4: "ܒܝܕ",
            sy7: "ܥܰܡ",
            sy8: "",
            lt10: "byađ",
            sy9: "",
            dt2: "",
            sy6: "ܥܡ",
            id: "3056",
            lt11: "ĉam",
            lt12: "",
            dt3: "",
            dt1: "mit"
        },
        {
            lt11: "ܡܗܠܠܐܝܠ",
            dt2: "",
            sy6: "ܡܗܠܠܐܝܠ",
            dt3: "",
            id: "2887",
            sy7: "ܡܰܗܠܰܠܰܐܝܶܠ",
            lt12: "ܡܗܠܠܐܝܠ",
            sy5: "ܡܰܗܠܳܠܳܐܝܶܠ",
            sy9: "",
            dt1: "Mahalalel",
            sy4: "ܡܗܠܠܐܝܠ",
            lt10: "ܡܗܠܠܐܝܠ",
            sy8: ""
        },
        {
            lt10: "tub",
            dt2: "nochmal",
            dt1: "wieder",
            sy7: "",
            lt11: "",
            sy8: "",
            dt3: "",
            id: "5095",
            sy9: "",
            sy5: "ܬܽܘܒ",
            sy4: "ܬܘܒ",
            lt12: "",
            sy6: ""
        },
        {
            sy5: "ܢܰܚܫܺܝܪܬܳܢܳܐ",
            dt3: "",
            sy9: "",
            lt11: "ܢܚܫܝܪܬܢܐ",
            dt2: "",
            lt10: "ܢܚܫܝܪܬܢܐ",
            sy8: "",
            id: "3190",
            lt12: "",
            sy7: "",
            sy4: "ܢܚܫܝܪܬܢܐ",
            dt1: "Nahschirtono",
            sy6: ""
        },
        {
            lt12: "",
            sy7: "",
            sy6: "",
            dt2: "",
            sy5: "ܫܰܢ̱ܬܳܐ ܘܦܶܠܓܳܐ",
            lt11: "",
            sy9: "",
            sy8: "",
            id: "5940",
            sy4: "ܫܢܬܐ ܘܦܠܓܐ",
            dt1: "anderthalbjährig",
            dt3: "",
            lt10: "shato wfelgo"
        },
        {
            id: "212",
            sy5: "ܐܰܡܺܝܪܬܰܐ",
            sy6: "",
            lt12: "",
            lt11: "ܐܡܝܪܬܐ",
            dt1: "Amirta",
            sy9: "",
            sy4: "ܐܡܝܪܬܐ",
            lt10: "ܐܡܝܪܬܐ",
            sy7: "",
            dt3: "",
            dt2: "",
            sy8: ""
        },
        {
            lt11: "ܒܪܢܗܪܐ",
            lt12: "",
            lt10: "ܒܪܢܗܪܐ",
            sy8: "",
            dt2: "",
            sy5: "ܒܰܪܢܰܗܪܰܐ",
            dt1: "Barnahro",
            sy6: "",
            sy9: "",
            sy4: "ܒܪܢܗܪܐ",
            id: "464",
            sy7: "",
            dt3: ""
        },
        {
            sy4: "ܢܒܓܢܝܐ",
            sy6: "",
            lt11: "",
            id: "5813",
            sy7: "",
            dt2: "",
            lt10: "nebgonoyo",
            sy8: "",
            dt1: "akzidentiell",
            dt3: "",
            lt12: "",
            sy5: "ܢܶܒܓܳܢܳܝܳܐ",
            sy9: ""
        },
        {
            lt10: "soyumutho",
            sy8: "",
            sy9: "",
            sy7: "",
            sy5: "ܣܳܝܽܘܡܽܘܬ݂ܳܐ",
            lt12: "",
            sy6: "",
            dt3: "",
            sy4: "ܣܝܘܡܘܬܐ",
            lt11: "",
            dt2: "Positivität",
            id: "3496",
            dt1: "Positivismus"
        },
        {
            sy4: "ܫܚܬ",
            dt1: "rosten",
            lt10: "shĥeth",
            sy7: "",
            id: "3746",
            lt11: "",
            sy5: "ܫܚܶܬ݂",
            sy9: "",
            sy6: "",
            dt3: "",
            sy8: "",
            dt2: "",
            lt12: ""
        },
        {
            sy5: "ܙܰܐܙܰܒܽܘܚܰܐ",
            sy4: "ܙܐܙܒܘܚܐ",
            sy6: "",
            dt1: "Zazabuha",
            sy9: "",
            lt10: "ܙܐܙܒܘܚܐ",
            sy7: "",
            sy8: "",
            dt3: "",
            id: "5266",
            lt11: "ܙܐܙܒܘܚܐ",
            lt12: "",
            dt2: ""
        },
        {
            lt10: "ܒܪܫܢܬܐ",
            sy8: "",
            lt12: "",
            sy6: "",
            dt3: "",
            dt2: "",
            lt11: "ܒܪܫܢܬܐ",
            sy7: "",
            sy5: "ܒܰܪܫܰܢ̱ܬܳܐ",
            sy9: "",
            id: "476",
            dt1: "Barschato",
            sy4: "ܒܪܫܢ̱ܬܐ"
        },
        {
            lt12: "",
            sy9: "",
            dt1: "Versäumnis",
            id: "4803",
            sy7: "ܡܫܳܟܽܘܬ݂ܳܐ",
            sy6: "ܡܫܟܘܬܐ",
            sy8: "",
            dt2: "",
            sy5: "ܡܫܳܟܳܐ",
            lt11: "mshokutho",
            lt10: "mshoko",
            sy4: "ܡܫܟܐ",
            dt3: ""
        },
        {
            dt1: "anziehen",
            sy4: "ܠܒܫ",
            sy7: "ܠܳܒܶܫ",
            sy5: "ܠܒܶܫ",
            sy9: "",
            sy8: "",
            dt2: "",
            lt12: "",
            sy6: "ܠܒܫ",
            id: "254",
            dt3: "",
            lt11: "lobesh",
            lt10: "lbesh"
        },
        {
            lt12: "",
            id: "6169",
            dt1: "Investition",
            lt11: "",
            sy8: "",
            dt2: "",
            sy5: "ܬܰܦܪܺܝܬ݂ܳܐ",
            sy6: "",
            dt3: "",
            sy7: "",
            sy4: "ܬܦܪܝܬܐ",
            sy9: "",
            lt10: "tafritho"
        },
        {
            sy6: "",
            lt10: "saqubloith",
            dt3: "",
            lt12: "",
            sy8: "",
            sy4: "ܣܩܘܒܠܐܝܬ",
            sy9: "",
            sy7: "",
            id: "3382",
            sy5: "ܣܰܩܽܘܒܠܳܐܝܺܬ݂",
            dt2: "",
            lt11: "",
            dt1: "oppositionell"
        },
        {
            sy8: "",
            dt1: "Patenschaft",
            lt10: "shaushbinutho",
            dt3: "",
            sy5: "ܫܰܘܫܒܺܝܢܽܘܬ݂ܳܐ",
            id: "6160",
            sy6: "",
            lt12: "",
            sy4: "ܫܘܫܒܝܢܘܬܐ",
            sy7: "",
            lt11: "",
            sy9: "",
            dt2: ""
        },
        {
            sy7: "",
            lt12: "",
            sy5: "ܒܰܝܢܳܬ݂",
            sy9: "",
            lt10: "baynoth",
            dt2: "",
            sy4: "ܒܝܢܬ",
            lt11: "",
            sy8: "",
            dt3: "",
            id: "6370",
            dt1: "mitten",
            sy6: ""
        },
        {
            sy5: "ܒܰܣܶܠܠܺܝܽܘܣ",
            sy7: "",
            lt11: "ܒܣܝܠܝܘܣ",
            sy9: "",
            dt1: "Baselius",
            dt2: "Basselius",
            lt12: "",
            id: "486",
            sy8: "",
            sy4: "ܒܣܝܠܝܘܣ",
            sy6: "",
            dt3: "Basilius",
            lt10: "ܒܣܝܠܝܘܣ"
        },
        {
            lt12: "",
            sy5: "ܫܶܡܫܳܐ ܚܠܺܝܬ݂ܳܐ",
            dt2: "süße Sonne",
            sy4: "ܫܡܫܐ ܚܠܝܬܐ",
            sy6: "",
            lt10: "shemsho ĥlitho",
            sy8: "",
            lt11: "",
            id: "6187",
            dt3: "",
            dt1: "süsse Sonne",
            sy9: "",
            sy7: ""
        },
        {
            lt12: "",
            lt11: "",
            lt10: "mettaqlonoyo",
            dt3: "",
            dt2: "",
            dt1: "abwiegbar",
            sy5: "ܡܶܬܬܰܩܠܳܢܳܝܳܐ",
            sy6: "",
            sy7: "",
            sy9: "",
            sy4: "ܡܬܬܩܠܢܝܐ",
            sy8: "",
            id: "5711"
        },
        {
            sy5: "ܝܰܡܳܐ ܕܬܰܝܡܢܳܐ ܫܰܠܝܳܐ",
            sy7: "",
            dt2: "Ozean Antarktis",
            id: "247",
            sy4: "ܝܡܐ ܕܬܝܡܢܐ ܫܠܝܐ",
            dt3: "Antarktis",
            dt1: "Antarktischer Ozean",
            sy9: "",
            lt11: "",
            lt10: "yamo d`taymno shalyo",
            sy8: "",
            lt12: "",
            sy6: ""
        },
        {
            lt10: "țakso dagyode",
            sy8: "",
            id: "5753",
            lt12: "",
            lt11: "",
            sy7: "",
            dt1: "Adersystem",
            sy5: "ܛܰܟܙܳܐ ܕܰܓܝܳܕܶܐ",
            sy6: "",
            sy9: "",
            dt3: "",
            dt2: "",
            sy4: "ܛܟܙܐ ܕܓܝܕܐ"
        },
        {
            lt10: "baqbeq",
            sy7: "",
            id: "5630",
            sy8: "",
            sy4: "ܒܩܒܩ",
            dt2: "",
            dt3: "",
            sy9: "",
            sy5: "ܒܰܩܒܶܩ",
            lt12: "",
            sy6: "",
            dt1: "abschmelzen",
            lt11: ""
        },
        {
            sy7: "",
            lt10: "gdonfo",
            sy8: "",
            id: "428",
            lt12: "",
            sy6: "",
            dt2: "",
            dt1: "Balkon",
            sy9: "",
            dt3: "",
            lt11: "",
            sy4: "ܓܕܢܦܐ",
            sy5: "ܓܕܳܢܦܳܐ"
        },
        {
            sy4: "ܨܠܘܬܐ ܕܡܗܝܡܢܐ",
            dt1: "Gebet des Gläubigen",
            sy8: "",
            lt12: "",
            sy5: "ܨܽܠܘܬ݂ܳܐ ܕܰܡܗܰܝܡܢܳܐ",
            dt2: "",
            lt11: "",
            sy6: "",
            id: "1495",
            dt3: "",
            sy9: "",
            sy7: "",
            lt10: "ŝlutho da`mhaymno"
        },
        {
            lt11: "ĥuroro",
            id: "1093",
            lt10: "ĉuđoyo",
            dt2: "",
            sy8: "",
            lt12: "",
            sy5: "ܥܽܘܕܳܝܳܐ",
            sy4: "ܥܘܕܝܐ",
            dt1: "Emanzipation",
            sy7: "ܚܽܘܪܳܪܳܐ",
            sy9: "",
            sy6: "ܚܘܪܪܐ",
            dt3: ""
        },
        {
            dt1: "Abfluss",
            lt12: "shfukhyo",
            sy5: "ܫܦܳܥܳܐ",
            id: "5564",
            lt10: "shfoĉo",
            sy7: "ܫܶܓ݂ܪܳܐ",
            lt11: "sheğro",
            dt3: "",
            sy6: "ܫܓܪܐ",
            dt2: "Abfluß",
            sy4: "ܫܦܥܐ",
            sy8: "ܫܦܘܟܝܐ",
            sy9: "ܫܦܽܘܟ݂ܝܳܐ"
        },
        {
            sy5: "ܡܓܚܟܢܐ",
            sy7: "",
            lt10: "mgaĥkono",
            dt1: "Komiker",
            sy9: "",
            lt12: "",
            dt3: "",
            id: "2563",
            dt2: "",
            sy4: "ܡܓܚܟܢܐ",
            sy6: "",
            sy8: "",
            lt11: ""
        },
        {
            sy9: "",
            sy4: "ܫܬܐ ܡܝܐ",
            lt10: "shote mayo",
            lt11: "",
            dt1: "Er trinkt Wasser",
            sy5: "ܫܳܬܶܐ ܡܰܝ̈ܳܐ",
            sy6: "",
            id: "1127",
            dt2: "",
            lt12: "",
            dt3: "",
            sy7: "",
            sy8: ""
        },
        {
            sy4: "ܨܠܡܐ",
            id: "1715",
            sy5: "ܨܰܠܡ̈ܶܐ",
            lt10: "ŝalmo",
            dt1: "Götzen",
            sy8: "",
            lt11: "ftakhro",
            lt12: "",
            sy7: "ܦܬܰܟ݂ܪܳܐ",
            dt3: "",
            sy6: "ܦܬܟܪܐ",
            sy9: "",
            dt2: "Götze"
        },
        {
            dt2: "",
            sy4: "ܥܡܐ",
            lt10: "ĉamo",
            sy7: "",
            lt12: "",
            id: "3542",
            dt1: "Publikum",
            dt3: "",
            lt11: "",
            sy5: "ܥܰܡܳܐ",
            sy6: "",
            sy8: "",
            sy9: ""
        },
        {
            sy7: "",
            sy6: "",
            sy9: "",
            dt3: "",
            lt10: "ܢܦܬܠܝ",
            sy4: "ܢܦܬܠܝ",
            lt12: "",
            sy8: "",
            dt1: "Naftali",
            lt11: "ܢܦܬܠܝ",
            id: "3176",
            dt2: "",
            sy5: "ܢܰܦܬܰܠܺܝ"
        },
        {
            lt10: "ܐܚܙ",
            sy4: "ܐܚܙ",
            sy8: "",
            dt2: "",
            dt3: "",
            lt12: "",
            id: "151",
            sy7: "",
            dt1: "Ahas",
            sy5: "ܐܳܚܳܙ",
            sy6: "",
            sy9: "",
            lt11: "ܐܚܙ"
        },
        {
            id: "2326",
            dt3: "",
            lt11: "ܝܘܐܒ",
            sy6: "",
            dt2: "",
            lt10: "ܝܘܐܒ",
            sy5: "ܝܽܘܐܳܒ",
            sy8: "",
            lt12: "",
            sy7: "",
            dt1: "Joab",
            sy4: "ܝܘܐܒ",
            sy9: ""
        },
        {
            id: "3381",
            dt1: "Opposition",
            sy6: "ܣܰܰܩܽܘܒܠܳܝܽܘܬܳܐ",
            lt11: "saqubloyutho",
            sy4: "ܣܩܘܒܠܐ",
            sy5: "ܣܰܩܽܘܒܠܳܐ",
            dt2: "",
            lt10: "saqublo",
            dt3: "",
            sy7: "ܣܰܰܩܽܘܒܠܳܝܽܘܬܳܐ",
            lt12: "",
            sy8: "",
            sy9: ""
        },
        {
            sy8: "",
            sy4: "ܩܘܫܘ ܘ ܢܬܦܬܚ ܠܟܘܢ ",
            lt11: "",
            id: "2532",
            sy6: "",
            sy5: "ܩܽܘܫܽܘ ܘ ܢܶܬ݂ܶܦܬܰܚ ܠܟ݂ܽܘܢ ",
            dt3: "",
            dt1: "klopft und euch wird aufgetan",
            sy9: "",
            lt10: "qushun u netheftaĥ lkuhn",
            dt2: "",
            lt12: "",
            sy7: ""
        },
        {
            dt3: "",
            dt1: "Nadelöhr",
            sy8: "",
            sy5: "ܚܪܽܘܪܳܐ ܕܡܚܰܛܳܐ",
            sy4: "ܚܪܘܪܐ ܕܡܚܛܐ",
            lt12: "",
            dt2: "",
            sy6: "",
            id: "3174",
            sy7: "",
            lt11: "",
            lt10: "ĥruro d`mĥațo",
            sy9: ""
        },
        {
            sy7: "ܫܳܐܘܽܠܳܐ",
            sy8: "ܫܝܘܠܐ",
            id: "1359",
            dt1: "Fragender",
            sy4: "ܡܫܝܠܢܐ",
            lt12: "shoyulo",
            dt2: "",
            sy9: "ܫܳܝܽܘܠܳܐ",
            sy5: "ܡܫܰܝܠܳܢܳܐ",
            sy6: "ܫܘܠܐ",
            lt11: "shoulo",
            lt10: "mshaylono",
            dt3: ""
        },
        {
            id: "4412",
            sy4: "ܬܝܪ",
            sy5: "ܬܰܝܰܪ",
            sy9: "",
            lt11: "ܬܝܪ",
            sy7: "",
            lt12: "",
            dt2: "",
            sy6: "",
            dt1: "Tayar",
            lt10: "ܬܝܪ",
            sy8: "",
            dt3: ""
        },
        {
            sy6: "",
            sy9: "",
            id: "6097",
            lt12: "",
            lt10: "furĉon shayno",
            dt3: "",
            dt1: "Friedenspreis",
            sy5: "ܦܽܘܪܥܳܢ ܫܰܝܢܳܐ",
            sy4: "ܦܘܪܥܢ ܫܝܢܐ",
            lt11: "",
            sy8: "",
            sy7: "",
            dt2: ""
        },
        {
            sy4: "ܟܠܒܐ ܕܡܝܐ",
            sy7: "",
            sy8: "",
            sy6: "",
            lt12: "",
            sy5: "ܟܰܠܒܳܐ ܕܡܰܝܳܐ",
            lt11: "",
            dt1: "Robbe",
            dt2: "Seehund",
            dt3: "",
            sy9: "",
            id: "3730",
            lt10: "kalbo d`mayo"
        },
        {
            dt1: "Korb",
            dt2: "",
            sy6: "",
            dt3: "",
            sy9: "",
            lt10: "salo",
            id: "2609",
            lt12: "",
            sy8: "",
            sy5: "ܣܰܠܳܐ",
            sy7: "",
            sy4: "ܣܠܐ",
            lt11: ""
        },
        {
            sy4: "ܙܩܪܝܬܐ",
            dt1: "Spinne",
            dt2: "Spinnenwebe",
            sy7: "",
            id: "4218",
            lt10: "zaqoritho",
            sy9: "",
            sy5: "ܙܰܩܳܪܺܝܬ݂ܳܐ",
            lt12: "",
            dt3: "Spinnennetz",
            sy6: "",
            lt11: "",
            sy8: ""
        },
        {
            id: "6052",
            dt1: "Holzkeil",
            sy4: "ܐܣܦܝܢܐ",
            dt2: "Holzklotz",
            dt3: "",
            lt11: "",
            lt12: "",
            sy7: "",
            sy6: "",
            lt10: "esfino",
            sy9: "",
            sy5: "ܐܶܣܦܺܝܢܳܐ",
            sy8: ""
        },
        {
            sy5: "ܦܶܬܝܽܘܢܳܐ",
            dt3: "",
            dt1: "Wasserhahn",
            lt12: "",
            sy8: "",
            lt11: "",
            sy9: "",
            sy6: "",
            id: "5003",
            sy4: "ܦܬܝܘܢܐ",
            sy7: "",
            dt2: "",
            lt10: "fetyuno"
        },
        {
            sy9: "",
            sy4: "ܫܦܝܪ ܓܢܣܐ",
            sy7: "ܫܰܦܺܝܪ ܛܽܘܗܡܳܐ",
            dt2: "",
            lt10: "shafir genso",
            sy8: "",
            dt3: "",
            dt1: "adelig",
            lt11: "shafir țuhmo",
            sy6: "ܫܦܝܪ ܛܘܗܡܐ",
            sy5: "ܫܰܦܺܝܪ ܓܶܢܣܳܐ",
            lt12: "",
            id: "5746"
        },
        {
            sy8: "",
            sy7: "",
            lt11: "",
            lt10: "amiro sĉuro",
            id: "2516",
            sy6: "",
            lt12: "",
            sy4: "ܐܡܝܪܐ ܙܥܘܪܐ",
            dt2: "",
            sy9: "",
            dt1: "Kleiner Prinz",
            sy5: "ܐܰܡܝܺܪܳܐ ܙܥܽܘܪܳܐ",
            dt3: ""
        },
        {
            dt2: "",
            id: "4348",
            dt1: "Tabak",
            lt11: "",
            sy5: "ܬܽܘܬܽܘܢ",
            sy6: "",
            sy9: "",
            sy7: "",
            lt10: "tutun",
            sy8: "",
            sy4: "ܬܘܬܘܢ",
            dt3: "",
            lt12: ""
        },
        {
            sy6: "",
            dt2: "",
            sy5: "ܬܳܓܰܬ",
            lt10: "ܬܓܬ",
            dt1: "Togat",
            lt11: "ܬܓܬ",
            sy8: "",
            sy4: "ܬܓܬ",
            sy9: "",
            sy7: "",
            id: "4514",
            dt3: "",
            lt12: ""
        },
        {
            lt11: "",
            sy7: "",
            id: "3037",
            dt2: "",
            sy9: "",
            lt12: "",
            dt3: "",
            sy5: "ܛܚܳܠܳܐ",
            sy8: "",
            sy6: "",
            dt1: "Milz",
            lt10: "țĥolo",
            sy4: "ܛܚܠܐ"
        },
        {
            lt10: "malkutho shbobto",
            sy8: "",
            sy7: "",
            dt1: "Anliegerstaat",
            lt11: "",
            sy5: "ܡܰܠܟܽܘܬ݂ܳܐ ܫܒܳܒܬܳܐ",
            lt12: "",
            dt2: "Nachbarstaat",
            dt3: "",
            id: "5963",
            sy9: "",
            sy4: "ܡܠܟܘܬܐ ܫܒܒܬܐ",
            sy6: ""
        },
        {
            sy5: "ܐܰܪܒܥܺܝܢ",
            dt3: "",
            lt12: "",
            sy7: "ܡ",
            lt11: "mim",
            sy6: "ܡ",
            dt1: "40",
            lt10: "arbĉin",
            dt2: "Vierzig",
            id: "32",
            sy8: "",
            sy9: "",
            sy4: "ܐܪܒܥܝܢ"
        },
        {
            lt10: "bakhoyo",
            sy4: "ܒܟܝܐ",
            lt11: "",
            sy5: "ܒܰܟ݂ܳܝܳܐ",
            id: "5031",
            sy8: "",
            dt2: "Weinender",
            dt1: "weinend",
            sy6: "",
            dt3: "",
            lt12: "",
            sy7: "",
            sy9: ""
        },
        {
            dt1: "heiraten",
            sy9: "",
            lt12: "",
            sy7: "ܡܶܙܕܰܘܰܓ݂",
            dt2: "",
            sy8: "",
            dt3: "",
            sy4: "ܙܘܓ",
            lt11: "mezdawağ",
            sy6: "ܡܙܕܘܓ",
            sy5: "ܙܰܘܶܓ݂",
            lt10: "zaweğ",
            id: "1942"
        },
        {
            id: "3631",
            sy9: "",
            dt3: "",
            sy4: "ܪܝܚܐ",
            dt1: "Recha",
            lt10: "ܪܝܚܐ",
            lt12: "",
            sy8: "",
            lt11: "ܪܝܚܐ",
            dt2: "",
            sy6: "",
            sy7: "",
            sy5: "ܪܺܝܚܰܐ"
        },
        {
            id: "3320",
            sy4: "ܢܘܪܝ",
            lt10: "ܢܘܪܝ",
            dt2: "",
            sy9: "",
            sy8: "",
            dt3: "",
            sy5: "ܢܽܘܪܺܝ",
            sy6: "",
            lt12: "",
            dt1: "Nuri",
            sy7: "",
            lt11: "ܢܘܪܝ"
        },
        {
            lt11: "",
            lt12: "",
            sy8: "",
            dt2: "",
            lt10: "abubo naqfoyo",
            sy6: "",
            dt1: "Verlängerungskabel",
            sy9: "",
            sy5: "ܐܰܒܽܘܒܳܐ ܢܰܩܦܳܝܳܐ",
            sy4: "ܐܒܘܒܐ ܢܩܦܝܐ",
            id: "4756",
            sy7: "",
            dt3: ""
        },
        {
            sy5: "ܣܽܘܪܓܳܕܳܐ",
            id: "2388",
            dt3: "",
            sy8: "",
            sy4: "ܣܘܪܓܕܐ",
            dt1: "Kalender",
            sy9: "",
            dt2: "",
            sy6: "",
            lt10: "surgođo",
            sy7: "",
            lt11: "",
            lt12: ""
        },
        {
            lt10: "qri b`qolo rkino",
            lt11: "",
            sy7: "",
            id: "2765",
            dt1: "lese mit ruhiger Stimme",
            sy8: "",
            sy5: "ܩܪܺܝ ܒܩܳܠܳܐ ܪܟܺܝܢܳܐ",
            dt2: "",
            sy6: "",
            sy9: "",
            lt12: "",
            sy4: "ܩܪܝ ܒܩܠܐ ܪܟܝܢܐ",
            dt3: ""
        },
        {
            sy9: "",
            lt12: "",
            sy7: "",
            dt1: "sich wehren",
            sy5: "ܩܳܡ ܠܽܘܩܒܰܠ",
            sy6: "",
            dt2: "Widerstand leisten",
            sy4: "ܩܡ ܠܘܩܒܠ",
            lt11: "",
            lt10: "qom luqbal",
            id: "4136",
            sy8: "",
            dt3: ""
        },
        {
            sy4: "ܥܒܕ ܫܘܬܦܐ",
            lt10: "ĉbad shautofo",
            dt1: "mitwirken",
            sy6: "ܫܬܦ",
            dt3: "",
            lt11: "shtaf",
            sy5: "ܥܒܰܕ ܫܰܘܬܳܦܳܐ",
            id: "6349",
            sy8: "",
            lt12: "",
            sy7: "ܫܬܰܦ",
            sy9: "",
            dt2: ""
        },
        {
            dt1: "Einjähriger",
            lt11: "",
            dt3: "",
            sy5: "ܒܰܪ ܫܰܢ̱ܬܳܐ",
            lt12: "",
            sy6: "",
            sy4: "ܒܪ ܫܢܬܐ",
            id: "1040",
            sy8: "",
            dt2: "",
            lt10: "bar shato",
            sy7: "",
            sy9: ""
        },
        {
            sy7: "ܣܰܛܠܳܐ",
            lt10: "sițlo",
            id: "1032",
            sy9: "",
            sy6: "ܣܛܠܐ",
            sy8: "",
            lt11: "sațlo",
            lt12: "",
            sy5: "ܣܺܝܛܠܳܐ",
            sy4: "ܣܝܛܠܐ",
            dt3: "",
            dt1: "Eimer",
            dt2: ""
        },
        {
            dt2: "",
            sy8: "",
            lt10: "shabtho d`ĥasho",
            sy9: "",
            sy7: "",
            sy6: "",
            lt12: "",
            id: "2419",
            dt3: "",
            sy4: "ܫܒܬܐ ܕܚܫܐ",
            lt11: "",
            dt1: "Karwoche",
            sy5: "ܫܰܒܬ݂ܳܐ ܕܚܰܫܳܐ"
        },
        {
            sy5: "ܩܪܳܐ",
            dt3: "",
            lt11: "",
            sy8: "",
            lt10: "qro",
            lt12: "",
            sy4: "ܩܪܐ",
            sy9: "",
            sy6: "",
            dt2: "",
            id: "6015",
            dt1: "appellieren",
            sy7: ""
        },
        {
            sy6: "ܩܠ ܥܡܐ",
            sy5: "ܦܽܘܡ ܥܰܡܳܐ",
            sy9: "",
            lt12: "",
            dt3: "",
            sy7: "ܩܰܠ ܥܰܡܳܐ",
            sy8: "",
            dt2: "Volksstimme",
            lt11: "qol ĉamo",
            dt1: "Volksmund",
            lt10: "fum ĉamo",
            sy4: "ܦܘܡ ܥܡܐ",
            id: "4858"
        },
        {
            sy5: "ܝܶܫܽܘܥܕܶܢܚܳܐ",
            lt11: "ܝܫܘܥܕܢܚܐ",
            sy9: "",
            sy6: "",
            sy8: "",
            dt1: "Jeschudenho",
            dt2: "",
            id: "2310",
            lt10: "ܝܫܘܥܕܢܚܐ",
            dt3: "",
            lt12: "",
            sy7: "",
            sy4: "ܝܫܘܥܕܢܚܐ"
        },
        {
            lt11: "",
            lt12: "",
            sy9: "",
            dt3: "",
            sy7: "",
            dt2: "Genuß",
            sy5: "ܗܶܢܝܳܢܳܐ",
            sy8: "",
            sy4: "ܗܢܝܢܐ",
            lt10: "henyono",
            dt1: "Genuss",
            id: "1565",
            sy6: ""
        },
        {
            lt11: "",
            dt2: "",
            lt10: "raqmo",
            sy5: "ܪܰܩܡܳܐ",
            id: "3312",
            dt3: "",
            sy4: "ܪܩܡܐ",
            sy7: "",
            lt12: "",
            sy9: "",
            sy6: "",
            sy8: "",
            dt1: "Nummer"
        },
        {
            lt11: "ĥasumto",
            sy7: "ܚܰܣܽܘܡܬܳܐ",
            id: "5459",
            sy9: "",
            sy6: "ܚܣܘܡܬܐ ",
            dt2: "",
            sy8: "",
            lt10: "ĥasumo",
            dt1: "eifersüchtig",
            dt3: "",
            lt12: "",
            sy5: "ܚܰܣܽܘܡܳܐ",
            sy4: "ܚܣܘܡܐ"
        },
        {
            lt10: "boduqo",
            sy9: "ܡܥܰܩܒܳܢܳܐ",
            sy6: "ܒܕܘܩܐ",
            lt12: "mĉaqbono",
            dt2: "",
            sy5: "ܒܳܕܽܘܩܳܐ",
            lt11: "boduqo",
            sy4: "ܒܕܘܩܐ",
            id: "3445",
            sy7: "ܒܳܕܽܘܩܳܐ",
            sy8: "ܡܥܩܒܢܐ",
            dt3: "",
            dt1: "Pfadfinder"
        },
        {
            sy6: "",
            lt10: "marĥeq no men horko",
            lt11: "",
            sy8: "",
            dt1: "Ich entferne mich von hier",
            lt12: "",
            dt2: "",
            sy9: "",
            sy7: "",
            sy5: "ܡܰܪܚܶܩ ܐ̱ܢܳܐ ܡܶܢ ܗܳܪܟܳܐ",
            dt3: "",
            id: "2107",
            sy4: "ܡܪܚܩ ܐܢܐ ܡܢ ܗܪܟܐ"
        },
        {
            dt2: "",
            dt1: "ab und zu",
            sy8: "",
            lt10: "zban zban",
            dt3: "",
            sy4: "ܙܒܢ ܙܒܢ",
            sy6: "ܒܙܒܢ ܙܒܢ",
            sy5: "ܙܒܰܢ ܙܒܰܢ",
            lt11: "bazban zban",
            sy9: "",
            sy7: "ܒܰܙܒܰܢ ܙܒܰܢ",
            id: "55",
            lt12: ""
        },
        {
            lt11: "",
            lt10: "ĥushoĥ zayno",
            sy4: "ܚܘܫܚ ܙܝܢܐ",
            sy7: "",
            dt3: "",
            sy5: "ܚܽܘܫܳܚ ܙܰܝܢܳܐ",
            id: "4928",
            dt1: "Waffengebrauch",
            sy6: "",
            dt2: "",
            lt12: "",
            sy8: "",
            sy9: ""
        },
        {
            dt2: "",
            sy8: "",
            lt12: "",
            sy5: "ܟܰܠܰܡܽܘ",
            sy7: "",
            sy4: "ܟܠܡܘ",
            sy9: "",
            dt3: "",
            lt10: "ܟܠܡܘ",
            sy6: "",
            dt1: "Kalamu",
            id: "2384",
            lt11: "ܟܠܡܘ"
        },
        {
            dt1: "Natali",
            id: "3212",
            sy8: "",
            lt10: "ܢܐܬܠܝ",
            lt12: "",
            sy9: "",
            lt11: "ܢܐܬܠܝ",
            sy6: "",
            sy5: "ܢܰܐܬܰܠܺܝ",
            sy4: "ܢܐܬܠܝ",
            dt3: "",
            sy7: "",
            dt2: ""
        },
        {
            lt11: "mĥorto",
            lt12: "ŝafro",
            dt3: "",
            sy5: "ܪܰܡܚܶܠ",
            lt10: "ramĥel",
            dt1: "Morgen",
            sy9: "ܨܰܦܪܳܐ",
            dt2: "",
            sy8: "ܨܦܪܐ",
            sy6: "ܡܚܪܬܐ",
            sy4: "ܪܡܚܠ",
            id: "3105",
            sy7: "ܡܚܳܪܬܳܐ"
        },
        {
            dt1: "motorisiert",
            sy5: "ܡܙܺܝܥܳܢܳܝܳܐ",
            id: "3115",
            sy6: "",
            sy9: "",
            lt12: "",
            lt11: "",
            lt10: "msiĉonoyo",
            sy8: "",
            dt3: "",
            dt2: "",
            sy4: "ܡܙܝܥܢܝܐ",
            sy7: ""
        },
        {
            id: "6366",
            sy9: "",
            lt11: "",
            dt3: "",
            lt12: "",
            sy7: "",
            sy4: "ܨܪܘܚܐ ܨܠܝܒܝܐ",
            sy6: "",
            sy8: "",
            dt2: "",
            dt1: "Mittelstreckenrakete",
            sy5: "ܨܳܪܽܘܚܳܐ ܨܠܺܝܒܳܝܳܐ",
            lt10: "ŝoruĥo ŝliboyo"
        },
        {
            sy7: "",
            sy6: "",
            lt10: "maĥrmo",
            id: "1217",
            sy4: "ܡܚܪܡܐ",
            dt3: "",
            lt12: "",
            sy5: "ܡܰܚܪܡܳܐ",
            dt1: "Exkommunizierter",
            dt2: "exkommuniziert",
            sy9: "",
            sy8: "",
            lt11: ""
        },
        {
            sy6: "",
            sy9: "",
            lt11: "",
            dt3: "",
            lt10: "nfaŝ ĥelo",
            lt12: "",
            dt1: "Staub wischen",
            id: "4245",
            sy8: "",
            sy5: "ܢܦܰܨ ܚܶܠܳܐ",
            sy4: "ܢܦܨ ܚܠܐ",
            dt2: "",
            sy7: ""
        },
        {
            dt1: "Perlmutt",
            sy9: "",
            dt3: "",
            sy5: "ܙܠܰܦܬܳܐ",
            sy4: "ܙܠܦܬܐ",
            lt10: "zlafto",
            sy7: "",
            lt12: "",
            lt11: "",
            sy8: "",
            sy6: "",
            dt2: "",
            id: "6163"
        },
        {
            dt1: "Fluch",
            sy4: "ܠܝܛܐ",
            sy9: "",
            lt10: "lyoțo",
            dt3: "",
            id: "1337",
            sy7: "",
            sy5: "ܠܝܳܛܳܐ",
            lt11: "",
            dt2: "",
            sy6: "",
            sy8: "",
            lt12: ""
        },
        {
            id: "4424",
            sy7: "ܦܰܠܶܓ",
            dt1: "teilen",
            lt12: "",
            dt3: "",
            dt2: "",
            sy6: "ܦܠܓ",
            sy5: "ܡܦܰܠܶܓ݂",
            sy4: "ܡܦܠܓ",
            lt10: "mfaleğ",
            sy9: "",
            lt11: "faleğ",
            sy8: ""
        },
        {
            lt12: "mĥasyonutho",
            dt1: "Verzeihung",
            sy6: "ܚܘܣܝܐ",
            id: "4827",
            sy9: "ܡܚܰܣܝܳܢܽܘܬ݂ܳܐ",
            sy8: "ܡܚܣܝܢܘܬܐ",
            lt11: "ĥusoyo",
            sy5: "ܫܽܘܒܩܳܢܳܐ",
            lt10: "shubqono",
            dt2: "Entschuldigung",
            sy7: "ܚܽܘܣܳܝܳܐ",
            sy4: "ܫܘܒܩܢܐ",
            dt3: "Vergebung"
        },
        {
            dt2: "",
            sy6: "",
            id: "2788",
            dt1: "letztes Mal",
            sy4: "ܙܒܢܬܐ ܐܚܪܝܬܐ",
            sy7: "",
            sy9: "",
            sy8: "",
            lt10: "zbato ĥroyto",
            lt12: "",
            lt11: "",
            sy5: "ܙܒܰܢ̱ܬܳܐ ܐ̱ܚܪܳܝܬܳܐ",
            dt3: ""
        },
        {
            dt2: "",
            sy5: "ܪܓ݂ܰܡ",
            lt11: "",
            sy6: "",
            lt10: "rğam",
            sy8: "",
            sy9: "",
            sy4: "ܪܓܡ",
            lt12: "",
            dt1: "steinigen",
            dt3: "",
            id: "4253",
            sy7: ""
        },
        {
            dt3: "Jeftah",
            sy8: "",
            id: "2324",
            lt10: "ܝܦܬܚ",
            sy5: "ܝܰܦܬܰܚ",
            sy7: "ܢܰܦܬܰܚ",
            dt2: "Naftah",
            lt12: "ܢܦܬܚ",
            dt1: "Jiftach",
            sy6: "ܢܦܬܚ",
            sy9: "",
            lt11: "ܝܦܬܚ",
            sy4: "ܝܦܬܚ"
        },
        {
            sy5: "ܬܰܚܠܽܘܦܳܐ",
            id: "4258",
            dt3: "Autorisierter",
            lt12: "",
            dt2: "Vize",
            lt11: "",
            lt10: "taĥlufo",
            sy6: "",
            sy9: "",
            sy8: "",
            sy4: "ܬܚܠܘܦܐ",
            dt1: "Stellvertreter",
            sy7: ""
        },
        {
            dt3: "",
            lt11: "",
            lt10: "mume",
            lt12: "",
            sy6: "",
            id: "5500",
            sy8: "",
            sy7: "",
            sy5: "ܡܽܘܡܶ̈ܐ",
            sy9: "",
            dt1: "Flecken",
            dt2: "",
            sy4: "ܡܘܡܐ"
        },
        {
            sy7: "ܐܰܘܝܽܘܬ݂ܳܐ",
            lt11: "auyutho",
            lt10: "shauyutho",
            lt12: "",
            dt3: "",
            sy6: "ܐܘܝܘܬܐ",
            sy9: "",
            dt1: "Übereinstimmung",
            id: "4613",
            dt2: "Konsens",
            sy8: "",
            sy5: "ܫܰܘܝܽܘܬ݂ܳܐ",
            sy4: "ܫܘܝܘܬܐ"
        },
        {
            lt10: "fargun groĉo",
            sy7: "",
            dt1: "Rasierpinsel",
            id: "3608",
            lt11: "",
            sy8: "",
            lt12: "",
            sy6: "",
            dt3: "",
            sy5: "ܦܰܪܓܽܘܢ ܓܪܳܥܳܐ",
            sy4: "ܦܪܓܘܢ ܓܪܥܐ",
            dt2: "",
            sy9: ""
        },
        {
            sy4: "ܫܟܝܚܘܬܐ",
            dt3: "",
            lt12: "",
            sy6: "",
            sy7: "",
            lt10: "shkiĥutho",
            dt1: "Existenz",
            sy5: "ܫܟܺܝܚܽܘܬ݂ܳܐ",
            lt11: "",
            sy9: "",
            id: "1213",
            sy8: "",
            dt2: "Anwesenheit"
        },
        {
            lt11: "furosho",
            dt1: "Unterscheidung",
            id: "4668",
            dt2: "unterscheiden",
            sy8: "",
            sy4: "ܦܘܪܫܢܐ",
            sy5: "ܦܽܘܪܫܳܢܳܐ",
            lt10: "furshono",
            sy7: "ܦܽܘܪܳܫܳܐ",
            sy6: "ܦܘܪܫܐ",
            dt3: "Unterschied",
            sy9: "",
            lt12: ""
        },
        {
            sy7: "",
            sy9: "",
            sy6: "",
            id: "2171",
            sy5: "ܓܰܠܘܳܝܽܘܬ݂ܳܐ",
            sy8: "",
            dt3: "",
            dt2: "",
            lt12: "",
            dt1: "Immigration",
            sy4: "ܓܠܘܝܘܬܐ",
            lt10: "galwoyutho",
            lt11: ""
        },
        {
            lt12: "",
            dt1: "Gali",
            sy9: "",
            lt10: "ܓܐܠܝ",
            dt3: "",
            sy8: "",
            sy5: "ܓܰܐܠܺܝ",
            sy7: "",
            sy6: "",
            dt2: "",
            id: "1465",
            sy4: "ܓܐܠܝ",
            lt11: "ܓܐܠܝ"
        },
        {
            dt1: "unschuldiges Lamm",
            id: "4655",
            sy9: "",
            lt12: "",
            sy8: "",
            sy5: "ܐܶܡܪܳܐ ܬܰܡܺܝܡܳܐ",
            sy6: "",
            sy7: "",
            lt10: "emro tamimo",
            lt11: "",
            dt2: "",
            sy4: "ܐܡܪܐ ܬܡܝܡܐ",
            dt3: ""
        },
        {
            sy8: "",
            lt11: "ܗܕܕܥܙܪ",
            sy7: "ܚܰܕܰܕܥܰܙܰܪ",
            sy4: "ܗܕܕܥܙܪ",
            dt1: "Hadadeser",
            sy9: "",
            sy5: "ܗܰܕܰܕܥܰܕܰܪ",
            dt2: "",
            lt10: "ܗܕܕܥܙܪ",
            sy6: "ܚܕܕܥܙܪ",
            id: "1805",
            dt3: "",
            lt12: "ܚܕܕܥܙܪ"
        },
        {
            dt3: "",
            sy7: "",
            sy5: "ܩܽܘܪܝܰܩܳܘܣ",
            lt11: "ܩܘܪܝܩܘܣ",
            lt10: "ܩܘܪܝܩܘܣ",
            lt12: "",
            dt2: "",
            id: "2684",
            sy8: "",
            sy6: "",
            sy9: "",
            dt1: "Kyriakos",
            sy4: "ܩܘܪܝܩܘܣ"
        },
        {
            lt10: "trayonoy leshono",
            sy6: "",
            lt12: "",
            sy4: "ܬܪܝܢܝ ܠܫܢܐ",
            sy9: "",
            lt11: "",
            id: "5395",
            sy5: "ܬܪܰܝܳܢܰܝ ܠܶܫܳܢܳܐ",
            dt1: "zweisprachig",
            dt2: "",
            sy7: "",
            dt3: "",
            sy8: ""
        },
        {
            sy8: "",
            dt2: "Öl",
            id: "1141",
            lt10: "nafțo",
            sy6: "",
            dt3: "öl",
            lt12: "",
            sy7: "",
            dt1: "Erdöl",
            sy9: "",
            sy4: "ܢܦܛܐ",
            lt11: "",
            sy5: "ܢܰܦܛܳܐ"
        },
        {
            lt10: "da`shkoĥte da`ŝlibo",
            dt3: "",
            sy9: "",
            dt1: "Kreuzauffindungsfest",
            sy8: "",
            lt11: "",
            sy5: "ܕܰܫܟܳܚܬܝ ܕܨܠܺܝܒܳܐ",
            id: "2643",
            dt2: "",
            sy6: "",
            lt12: "",
            sy4: "ܕܫܟܚܬܝ ܕܨܠܝܒܐ",
            sy7: ""
        },
        {
            dt3: "",
            id: "997",
            sy8: "",
            sy6: "",
            dt1: "Eckzahn",
            dt2: "",
            lt12: "",
            lt11: "",
            sy9: "",
            sy7: "",
            lt10: "nibo",
            sy5: "ܢܺܝܒܳܐ",
            sy4: "ܢܝܒܐ"
        },
        {
            dt2: "Möge Gott mit Dir sein",
            sy5: "ܐܰܠܳܗܳܐ ܐܰܡܳܟ݂",
            dt3: "Gott mit Dir",
            id: "1697",
            lt11: "moryo nehwe ĉamokh",
            sy8: "",
            lt10: "aloho amokh",
            sy4: "ܐܠܗܐ ܐܡܟ",
            lt12: "",
            sy9: "",
            sy6: "ܡܪܝܐ ܢܗܘܐ ܥܡܟ",
            sy7: "ܡܳܪܝܳܐ ܢܶܗܘܶܐ ܥܰܡܳܟ݂",
            dt1: "Gott sei mit Dir"
        },
        {
            sy6: "",
            dt3: "",
            sy9: "",
            sy7: "",
            id: "404",
            dt2: "",
            lt12: "",
            sy4: "ܐܝܟܢ",
            sy5: "ܐܰܝܟܰܢ",
            sy8: "",
            dt1: "Aykan",
            lt10: "ܐܝܟܢ",
            lt11: "ܐܝܟܢ"
        },
        {
            sy4: "ܠܐ ܡܬܦܨܝܢܘܬܐ",
            sy6: "ܫܩܛܐ",
            dt2: "",
            dt1: "Ratlosigkeit",
            id: "6141",
            dt3: "",
            sy5: "ܠܳܐ ܡܶܬ݂ܦܰܨܝܳܢܽܘܬ݂ܳܐ",
            lt10: "lo methfaŝyonutho",
            sy9: "",
            sy8: "",
            sy7: "ܫܩܳܛܳܐ",
            lt11: "shqoțo",
            lt12: ""
        },
        {
            dt1: "Kamel",
            lt11: "",
            sy5: "ܓܰܡܠܳܐ",
            sy4: "ܓܡܠܐ",
            sy6: "",
            lt12: "",
            dt3: "",
            dt2: "",
            id: "2396",
            sy8: "",
            lt10: "gamlo",
            sy7: "",
            sy9: ""
        },
        {
            id: "2973",
            lt10: "bağlo",
            sy4: "ܒܓܠܐ",
            dt2: "",
            sy8: "",
            sy7: "",
            lt11: "",
            dt3: "",
            sy6: "",
            sy5: "ܒܰܓ݂ܠܳܐ",
            lt12: "",
            dt1: "Maultier",
            sy9: ""
        },
        {
            dt3: "",
            dt2: "",
            sy4: "ܢܡܘܣܐ ܕܝܪܬܘܬܐ",
            lt12: "",
            sy7: "",
            sy8: "",
            dt1: "Erbrecht",
            sy9: "",
            lt11: "",
            lt10: "nomuso d`yortutho",
            id: "1133",
            sy6: "",
            sy5: "ܢܳܡܽܘܣܳܐ ܕܝܳܪܬܽܘܬ݂ܳܐ"
        },
        {
            lt11: "uĥdotho",
            sy9: "ܠܓܳܙܳܐ",
            dt2: "",
            sy8: "ܠܓܙܐ",
            dt1: "Rätsel",
            lt10: "uĥadtho",
            sy6: "ܐܘܚܕܬܐ",
            sy5: "ܐܽܘܚܰܕܬ݂ܳܐ",
            id: "3617",
            lt12: "lgozo",
            sy7: "ܐܽܘܚܕܳܬ݂ܳܐ",
            sy4: "ܐܘܚܕܬܐ",
            dt3: ""
        },
        {
            dt1: "Nurta",
            lt12: "",
            sy5: "ܢܽܘܪܬܰܐ",
            sy4: "ܢܘܪܬܐ",
            sy8: "",
            dt3: "",
            lt10: "ܢܘܪܬܐ",
            dt2: "",
            sy7: "",
            sy9: "",
            lt11: "ܢܘܪܬܐ",
            id: "3324",
            sy6: ""
        },
        {
            sy6: "ܦܐܪܬܝܩܝ",
            sy5: "ܡܰܓܥܠܳܢܽܘܬ݂ܳܐ",
            sy7: "ܦܰܐܪܰܬܺܝܩܺܝ",
            dt3: "",
            lt12: "",
            lt11: "faratiqi",
            lt10: "magĉlonutho",
            dt1: "Anvertrauen",
            sy8: "",
            id: "5995",
            dt2: "",
            sy9: "",
            sy4: "ܡܓܥܠܢܘܬܐ"
        },
        {
            lt12: "",
            lt11: "",
            dt3: "",
            sy4: "ܚܝܪܐ ܓܘܢܝܐ",
            sy7: "",
            sy6: "",
            dt1: "Überblick",
            dt2: "überblick",
            lt10: "ĥyoro gawonoyo",
            sy5: "ܚܝܳܪܳܐ ܓܰܘܢܳܝܳܐ",
            sy9: "",
            id: "4612",
            sy8: ""
        },
        {
            lt11: "",
            dt1: "absichtlich",
            lt12: "",
            sy8: "",
            id: "118",
            sy7: "",
            dt2: "",
            sy6: "",
            sy9: "",
            sy5: "ܒܝܺܕܰܥܬ݂ܳܐ",
            dt3: "",
            lt10: "biđaĉtho",
            sy4: "ܒܝܕܥܬܐ"
        },
        {
            sy7: "",
            sy4: "ܡܪܝܐܠܗܐ",
            sy9: "",
            lt11: "ܡܪܝܐܠܗܐ",
            dt3: "",
            lt12: "",
            sy8: "",
            dt2: "",
            id: "2953",
            dt1: "Maryalaha",
            sy5: "ܡܰܪܝܰܐܠܰܗܰܐ",
            sy6: "",
            lt10: "ܡܪܝܐܠܗܐ"
        },
        {
            sy7: "ܦܰܥܠܳܐ",
            sy9: "ܐܰܓܺܝܪܬܳܐ",
            id: "2852",
            sy5: "ܐܰܓ݂ܪܳܐ",
            dt1: "Lohn",
            lt12: "agirto",
            lt10: "ağro",
            lt11: "faĉlo",
            dt2: "Verdienst",
            sy6: "ܦܥܠܐ",
            sy4: "ܐܓܪܐ",
            dt3: "",
            sy8: "ܐܓܝܪܬܐ"
        },
        {
            sy8: "",
            dt2: "",
            sy4: "ܙܘܝܚܐ",
            lt11: "zauĥo",
            lt12: "",
            sy9: "",
            dt3: "",
            dt1: "Zeremonie",
            sy7: "ܙܰܘܚܳܐ",
            lt10: "zuyoĥo",
            sy6: "ܙܘܚܐ",
            id: "5309",
            sy5: "ܙܽܘܝܳܚܳܐ"
        },
        {
            dt2: "",
            sy5: "ܥܽܘܩܰܒܪܳܐ ܕܡܰܝܳܐ",
            sy6: "",
            dt1: "Wasserratte",
            sy9: "",
            sy7: "",
            sy4: "ܥܘܩܒܪܐ ܕܡܝܐ",
            id: "5008",
            dt3: "",
            sy8: "",
            lt11: "",
            lt10: "ĉuqabro d`mayo",
            lt12: ""
        },
        {
            dt1: "Jael",
            sy9: "",
            id: "2267",
            dt3: "",
            sy4: "ܝܐܝܠ",
            lt10: "ܝܐܝܠ",
            sy6: "",
            dt2: "",
            sy8: "",
            lt11: "ܝܐܝܠ",
            sy7: "",
            lt12: "",
            sy5: "ܝܰܐܝܶܠ"
        },
        {
            id: "419",
            lt10: "ܒܚܝܪܐ",
            lt11: "ܒܚܝܪܐ",
            dt3: "",
            sy6: "ܒܗܝܪܐ",
            sy7: "ܒܰܗܺܝܪܰܐ",
            sy5: "ܒܰܚܺܝܪܰܐ",
            lt12: "ܒܗܝܪܐ",
            sy4: "ܒܚܝܪܐ",
            dt1: "Bahira",
            sy9: "",
            sy8: "",
            dt2: ""
        },
        {
            dt1: "Cousin",
            sy6: "ܒܪ ܚܠܐ",
            lt11: "bar ĥolo",
            id: "777",
            sy7: "ܒܰܪ ܚܳܠܳܐ",
            sy9: "",
            sy8: "",
            sy4: "ܒܪ ܕܕܐ",
            lt12: "",
            dt2: "",
            sy5: "ܒܰܪ ܕܳܕܳܐ",
            lt10: "bar dodo",
            dt3: ""
        },
        {
            lt10: "hadomo",
            sy6: "",
            sy9: "",
            sy7: "",
            sy4: "ܗܕܡܐ",
            lt12: "",
            lt11: "",
            dt2: "Glied",
            id: "3062",
            sy5: "ܗܰܕܳܡܳܐ",
            dt3: "",
            sy8: "",
            dt1: "Mitglied"
        },
        {
            sy5: "ܩܽܘܒܳܐ",
            sy7: "",
            id: "6158",
            lt12: "",
            dt3: "",
            sy8: "",
            sy6: "",
            sy9: "",
            sy4: "ܩܘܒܐ",
            dt1: "Pavillon",
            lt10: "qubo",
            lt11: "",
            dt2: ""
        },
        {
            sy6: "",
            dt1: "Peleg",
            dt3: "",
            id: "3429",
            sy7: "",
            sy9: "",
            lt11: "ܦܠܓ",
            lt10: "ܦܠܓ",
            dt2: "",
            lt12: "",
            sy8: "",
            sy4: "ܦܠܓ",
            sy5: "ܦܶܠܶܓ"
        },
        {
            sy7: "",
            sy8: "",
            dt3: "",
            lt10: "abne d`ĉamo",
            dt1: "Söhne des Volkes",
            dt2: "",
            lt12: "",
            id: "4183",
            sy6: "",
            sy5: "ܐܰܒܢ̈ܐ ܕܥܰܡܳܐ",
            sy4: "ܐܒܢ̈ܐ ܕܥܡܐ",
            sy9: "",
            lt11: ""
        },
        {
            sy4: "ܢܝܫܐ",
            lt11: "",
            sy8: "",
            dt2: "Zielsetzung",
            lt12: "",
            dt3: "Intention",
            sy6: "",
            sy9: "",
            sy5: "ܢܺܝܫܳܐ",
            id: "5321",
            sy7: "",
            dt1: "Ziel",
            lt10: "nisho"
        },
        {
            lt12: "",
            sy9: "",
            id: "3186",
            dt2: "",
            lt11: "ܢܗܪܝܢ",
            lt10: "ܢܗܪܝܢ",
            sy6: "",
            sy5: "ܢܰܗܪܺܝܢ",
            dt1: "Nahrin",
            sy4: "ܢܗܪܝܢ",
            sy8: "",
            dt3: "",
            sy7: ""
        },
        {
            id: "1872",
            lt10: "kenoro",
            sy9: "",
            sy7: "ܠܽܘܪܳܐ",
            sy6: "ܠܘܪܐ",
            sy4: "ܟܢܪܐ",
            lt11: "luro",
            lt12: "",
            dt3: "",
            dt2: "",
            sy8: "",
            sy5: "ܟܶܢܳܪܳܐ",
            dt1: "Harfe"
        },
        {
            sy8: "",
            dt1: "Josef",
            id: "2338",
            lt11: "ܝܘܣܦ",
            dt2: "Yausef",
            lt12: "",
            lt10: "ܝܘܣܦ",
            sy4: "ܝܘܣܦ",
            sy6: "",
            dt3: "Joseph",
            sy5: "ܝܰܘܣܶܦ",
            sy9: "",
            sy7: ""
        },
        {
            lt12: "b`lebi at",
            sy5: "ܓܪܳܚܰܡܢܳܐ ܠܰܟ݂ܝ̱",
            lt10: "groĥamno lach",
            id: "2125",
            sy6: "ܓܪܚܡܢܐܟ",
            sy7: "ܓܪܳܚܰܡܢܰܐܟ݂",
            dt1: "Ich liebe Dich",
            sy9: "ܒܠܶܒܺܐܝ ܐܰܢ̱ܬ",
            dt2: "Ich mag Dich",
            sy8: "ܒܠܒܐܝ ܐܢܬ",
            sy4: "ܓܪܚܡܢܐ ܠܟܝ",
            dt3: "",
            lt11: "groĥamnakh"
        },
        {
            sy6: "",
            sy7: "",
            dt3: "",
            dt1: "Schuma",
            sy4: "ܫܘܡܐ",
            dt2: "",
            sy8: "",
            lt11: "ܫܘܡܐ",
            lt10: "ܫܘܡܐ",
            sy9: "",
            id: "4053",
            sy5: "ܫܽܘܡܰܐ",
            lt12: ""
        },
        {
            sy5: "ܕܰܪ̈ܓܶܐ",
            sy9: "",
            lt12: "",
            dt1: "Treppen",
            sy8: "",
            dt2: "",
            id: "4560",
            dt3: "",
            sy4: "ܕܪܓܐ",
            sy7: "",
            lt11: "",
            sy6: "",
            lt10: "darğe"
        },
        {
            lt11: "",
            dt3: "",
            sy9: "",
            dt1: "Amtstracht",
            sy5: "ܣܽܘܓܺܝܢܬܳܐ",
            dt2: "",
            id: "5890",
            sy8: "",
            sy6: "",
            sy7: "",
            lt12: "",
            lt10: "suginto",
            sy4: "ܣܘܓܝܢܬܐ"
        },
        {
            id: "6411",
            sy6: "",
            lt12: "",
            sy4: "ܛܝܡܐ ܕܡܥܠܐ",
            sy5: "ܛܺܝ̈ܡܶܐ ܕܡܰܥܠܳܐ",
            sy8: "",
            sy9: "",
            dt3: "",
            sy7: "",
            lt10: "țime d`maĉlo",
            lt11: "",
            dt1: "Eintrittskosten",
            dt2: ""
        },
        {
            dt1: "Schneider näht Kleider",
            sy9: "",
            lt10: "ĥayoțo ĥoyeț lbushe",
            dt2: "",
            dt3: "",
            sy6: "",
            sy8: "",
            sy5: "ܚܰܝܳܛܳܐ ܚܳܐܶܛ ܠܒܽܘܫܳܐ",
            sy7: "",
            lt11: "",
            id: "3998",
            sy4: "ܚܝܛܐ ܚܐܛ ܠܒܘܫܐ",
            lt12: ""
        },
        {
            sy8: "",
            lt11: "",
            sy4: "ܦܐܬܐ",
            sy7: "",
            sy9: "",
            lt12: "",
            lt10: "fatho",
            sy5: "ܦܰܐܬ݂ܳܐ",
            id: "4105",
            sy6: "",
            dt2: "Buchseite",
            dt3: "",
            dt1: "Seite "
        },
        {
            dt1: "Gott führt zusammen was zusammen gehört ",
            sy7: "ܐܰܠܳܗܳܐ ܡܚܰܝܶܕ ܠܟܽܠ ܡܶܕܶܡ ܕܐܺܝܬ݂ܰܘܗܝ̱ ܕܰܚܕܳܕܶܐ",
            sy5: "ܡܶܕܶܡܶܐ ܕܐܺܝܬ݂ܰܝܗܽܘܢ ܕܰܚܕܳܕܶܐ ܐܰܠܳܗܳܐ ܡܚܰܝܶܕ ܠܗܽܘܢ",
            sy9: "",
            id: "1689",
            dt3: "",
            dt2: "",
            lt12: "",
            sy6: "ܐܠܗܐ ܡܚܝܕ ܠܟܠ ܡܕܡ ܕܐܝܬܘܗܝ ܕܚܕܕܐ",
            sy4: "ܡܕܡܐ ܕܐܝܬܝܗܘܢ ܕܚܕܕܐ ܐܠܗܐ ܡܚܝܕ ܠܗܘܢ",
            lt11: "aloho mĥayed lkul medem dithau daĥđođe",
            lt10: "medeme ditheyhun daĥđođe aloho mĥayed lhun",
            sy8: ""
        },
        {
            sy5: "ܡܫܰܘܓ݂ܪܳܢܳܐ",
            dt1: "Abwerfer",
            dt3: "",
            lt11: "",
            sy9: "",
            lt10: "mshauğrono",
            sy4: "ܡܫܘܓܪܢܐ",
            sy7: "",
            dt2: "",
            sy6: "",
            id: "5708",
            sy8: "",
            lt12: ""
        },
        {
            sy5: "ܨܰܘܡܳܐ ܕܐܰܪܒܥܳܐ ܘ ܕܥܪܽܘܒܬܳܐ",
            sy8: "",
            lt12: "",
            dt3: "",
            sy9: "",
            lt10: "ŝaumo d`arbĉo u d`ĉrubto",
            dt1: "Fasten von Mittwoch und Freitag",
            sy4: "ܨܘܡܐ ܕܐܪܒܥܐ ܘ ܕܥܪܘܒܬܐ",
            sy6: "",
            sy7: "",
            lt11: "",
            dt2: "",
            id: "1264"
        },
        {
            lt12: "",
            sy4: "ܟܪܝܗ ܢܦܫܐ",
            lt10: "krih nafsho",
            id: "3540",
            sy8: "",
            dt3: "",
            sy6: "",
            sy5: "ܟܪܺܝܗ ܢܰܦܫܳܐ",
            lt11: "",
            sy9: "",
            dt1: "Psychopath",
            sy7: "",
            dt2: ""
        },
        {
            lt11: "",
            sy5: "ܟܢܽܘܫܝܳܐ",
            lt12: "",
            id: "4166",
            lt10: "knushyo",
            dt3: "",
            sy6: "",
            dt1: "Sitzung",
            sy7: "",
            sy8: "",
            sy9: "",
            dt2: "Besprechung",
            sy4: "ܟܢܘܫܝܐ"
        },
        {
            sy7: "",
            dt1: "Hawsho",
            sy8: "",
            id: "1911",
            sy4: "ܚܘܫܐ",
            lt11: "ܚܘܫܐ",
            sy6: "",
            dt3: "",
            sy5: "ܚܰܘܫܳܐ",
            sy9: "",
            lt12: "",
            dt2: "",
            lt10: "ܚܘܫܐ"
        },
        {
            sy8: "",
            sy4: "ܚܠܒܐ",
            sy5: "ܚܰܠܒܳܐ",
            id: "3025",
            lt11: "",
            dt1: "Milch",
            lt12: "",
            lt10: "ĥalbo",
            dt2: "",
            sy6: "",
            sy9: "",
            sy7: "",
            dt3: ""
        },
        {
            lt10: "ܓܒܪܐܝܠ",
            lt12: "",
            lt11: "ܓܒܪܐܝܠ",
            sy8: "",
            sy5: "ܓܰܒܪܺܐܝܶܠ",
            sy9: "",
            dt3: "",
            sy4: "ܓܒܪܐܝܠ",
            id: "1454",
            sy7: "",
            sy6: "",
            dt2: "",
            dt1: "Gabriel"
        },
        {
            sy6: "ܡܬܡܚܫܠܢܘܬܐ",
            dt1: "Ablenkung",
            lt11: "methmaĥshlonutho",
            dt3: "",
            lt10: "ĉußĉoßo",
            sy4: "ܥܘܣܥܣܐ",
            sy8: "",
            sy9: "",
            sy5: "ܥܽܘܣܥܳܣܳܐ",
            sy7: "ܡܶܬ݂ܡܰܚܫܠܳܢܽܘܬ݂ܳܐ",
            id: "5587",
            dt2: "",
            lt12: ""
        },
        {
            dt2: "",
            dt1: "Allgemeinheit",
            id: "5844",
            sy8: "",
            lt10: "gawonoyutho",
            sy6: "ܟܘܠܢܝܘܬܐ",
            sy9: "",
            lt11: "kulonoyutho",
            lt12: "",
            sy5: "ܓܰܘܳܢܳܝܽܘܬ݂ܳܐ",
            dt3: "",
            sy7: "ܟܽܘܠܳܢܳܝܽܘܬ݂ܳܐ",
            sy4: "ܓܘܢܝܘܬܐ"
        },
        {
            lt10: "ŝroro",
            id: "4782",
            dt1: "Verpackung",
            sy8: "",
            lt11: "",
            sy6: "",
            sy5: "ܨܪܳܪܳܐ",
            lt12: "",
            sy7: "",
            sy9: "",
            dt2: "",
            dt3: "",
            sy4: "ܨܪܪܐ"
        },
        {
            sy6: "",
            sy5: "ܒܓ݂ܰܘ ܫܳܒܽܘܥܳܐ",
            dt2: "",
            lt11: "",
            dt1: "In der Woche",
            sy7: "",
            lt12: "",
            sy4: "ܒܓܘ ܫܒܘܥܐ",
            sy9: "",
            sy8: "",
            dt3: "",
            id: "2181",
            lt10: "bğao shobuĉo"
        },
        {
            sy9: "",
            lt10: "shĥolo",
            dt2: "",
            sy7: "",
            sy8: "",
            sy5: "ܫܚܳܠܳܐ",
            sy4: "ܫܚܠܐ",
            lt12: "",
            sy6: "",
            id: "6446",
            lt11: "",
            dt3: "",
            dt1: "Entladung"
        },
        {
            dt2: "Danke dem Herrn",
            lt12: "",
            lt11: "",
            sy5: "ܬܰܘܕܺܝ ܠܡܳܪܝܳܐ",
            sy7: "",
            sy9: "",
            lt10: "taudi l`moryo",
            dt3: "",
            sy6: "",
            sy8: "",
            id: "1696",
            sy4: "ܬܘܕܝ ܠܡܪܝܐ",
            dt1: "Gott sei Dank"
        },
        {
            id: "4570",
            lt11: "",
            dt2: "",
            sy6: "",
            lt12: "",
            lt10: "nŝaĥ",
            sy4: "ܢܨܚ",
            sy7: "",
            dt1: "triumphieren",
            dt3: "",
            sy5: "ܢܨܰܚ",
            sy8: "",
            sy9: ""
        },
        {
            dt1: "Delphin",
            sy6: "",
            id: "853",
            lt10: "dulfino",
            dt3: "",
            lt11: "",
            sy5: "ܕܽܘܠܦܺܝܢܳܐ",
            sy9: "",
            sy8: "",
            lt12: "",
            dt2: "Delfin",
            sy4: "ܕܘܠܦܝܢܐ",
            sy7: ""
        },
        {
            dt3: "",
            lt12: "",
            sy9: "",
            sy4: "ܟܘܪܟ ܕܡܐ",
            dt2: "",
            sy5: "ܟܽܘܪܳܟ݂ ܕܡܳܐ",
            sy8: "",
            id: "666",
            dt1: "Blutkreislauf",
            sy7: "",
            lt10: "kurokh dmo",
            sy6: "",
            lt11: ""
        },
        {
            id: "2858",
            sy5: "ܣܰܥܪܳܐ ܫܪܺܝܛܳܐ",
            lt11: "",
            sy8: "",
            lt12: "",
            sy6: "",
            sy4: "ܣܥܪܐ ܫܪܝܛܐ",
            lt10: "saĉro shrițo",
            dt3: "",
            dt1: "loses Haar",
            sy7: "",
            dt2: "knotenfreies Haar",
            sy9: ""
        },
        {
            id: "5686",
            dt1: "abtreten",
            sy5: "ܫܢܰܙ",
            sy4: "ܫܢܙ",
            sy9: "",
            dt2: "",
            lt10: "shnaz",
            dt3: "",
            lt11: "",
            sy7: "",
            sy8: "",
            lt12: "",
            sy6: ""
        },
        {
            sy9: "",
            dt2: "MakeUp",
            lt11: "",
            sy7: "",
            lt12: "",
            sy4: "ܚܠܫܐ",
            sy6: "",
            id: "2891",
            lt10: "ĥalsho",
            dt3: "",
            sy5: "ܚܰܠܫܳܐ",
            sy8: "",
            dt1: "Make Up"
        },
        {
            dt2: "",
            sy7: "",
            lt12: "",
            lt11: "",
            sy8: "",
            sy4: "ܦܪܙܘܡܐ",
            lt10: "farzumo",
            sy6: "",
            dt3: "",
            dt1: "Unterhose",
            sy5: "ܦܰܪܙܽܘܡܳܐ",
            sy9: "",
            id: "4662"
        },
        {
            lt12: "",
            sy4: "ܫܠܡ",
            sy7: "",
            sy8: "",
            dt2: "",
            sy5: "ܫܰܠܶܡ",
            lt10: "ܫܠܡ",
            dt1: "Schalem",
            lt11: "ܫܠܡ",
            sy9: "",
            id: "3882",
            dt3: "",
            sy6: ""
        },
        {
            sy6: "",
            dt1: "Tobiel",
            sy5: "ܛܳܘܒܺܝܐܝܶܠ",
            lt12: "",
            id: "4504",
            lt11: "ܛܘܒܝܐܝܠ",
            sy9: "",
            sy8: "",
            lt10: "ܛܘܒܝܐܝܠ",
            sy7: "",
            sy4: "ܛܘܒܝܐܝܠ",
            dt2: "",
            dt3: ""
        },
        {
            dt1: "Temperaturunterschied",
            lt11: "",
            dt2: "",
            sy5: "ܦܽܘܪܫܳܢ ܚܰܡܺܝܡܽܘܬ݂ܳܐ",
            sy7: "",
            sy9: "",
            lt10: "furshon ĥamimutho",
            sy6: "",
            id: "4435",
            sy4: "ܦܘܪܫܢ ܚܡܝܡܘܬܐ",
            lt12: "",
            sy8: "",
            dt3: ""
        },
        {
            sy4: "ܫܪܐ",
            sy7: "",
            lt12: "",
            dt1: "lösen",
            lt11: "",
            id: "2857",
            lt10: "shro",
            dt2: "",
            sy8: "",
            sy9: "",
            dt3: "",
            sy5: "ܫܪܳܐ",
            sy6: ""
        },
        {
            sy7: "",
            lt10: "bokhe",
            dt3: "",
            id: "5029",
            sy4: "ܒܟܐ",
            sy5: "ܒܳܟ݂ܶܐ",
            sy8: "",
            sy9: "",
            dt2: "",
            lt12: "",
            lt11: "",
            sy6: "",
            dt1: "weinen"
        },
        {
            sy8: "",
            sy7: "ܡܚܰܕܬ݂ܳܢܽܘܬ݂ܳܐ",
            lt11: "mĥadthonutho",
            lt12: "",
            dt1: "Reparatur",
            dt3: "",
            dt2: "",
            id: "3697",
            sy5: "ܬܽܘܪܳܨܳܐ",
            sy6: "ܡܚܕܬܢܘܬܐ",
            lt10: "turoŝo",
            sy4: "ܬܘܪܨܐ",
            sy9: ""
        },
        {
            id: "4649",
            lt12: "",
            lt10: "tibel",
            sy5: "ܬܺܒܶܝܠ",
            dt2: "",
            dt3: "",
            dt1: "Universum",
            lt11: "",
            sy6: "",
            sy8: "",
            sy9: "",
            sy7: "",
            sy4: "ܬܒܝܠ"
        },
        {
            sy8: "",
            sy7: "",
            sy5: "ܩܰܘܡܳܝܳܐ",
            lt12: "",
            lt11: "",
            id: "3711",
            dt2: "",
            sy4: "ܩܘܡܝܐ",
            sy9: "",
            dt3: "",
            lt10: "qaumoyo",
            dt1: "Revolutionär",
            sy6: ""
        },
        {
            sy5: "ܪܰܚܺܝܩ ܒܟܽܠ",
            dt1: "am fernsten",
            sy4: "ܪܚܝܩ ܒܟܠ",
            dt2: "",
            lt10: "raĥiq bkul",
            lt12: "",
            id: "5874",
            sy9: "",
            lt11: "",
            dt3: "",
            sy8: "",
            sy6: "",
            sy7: ""
        },
        {
            dt3: "",
            lt12: "",
            sy6: "ܐܬܪܢܝܐ",
            lt10: "mbaytonoyo",
            id: "1936",
            sy9: "",
            sy4: "ܡܒܝܬܢܝܐ",
            sy7: "ܐܰܬ݂ܪܳܢܳܝܳܐ",
            dt1: "heimisch",
            lt11: "athronoyo",
            dt2: "",
            sy8: "",
            sy5: "ܡܒܰܝܬܳܢܳܝܳܐ"
        },
        {
            sy7: "",
            sy4: "ܡܫ",
            sy8: "",
            lt12: "",
            id: "3110",
            dt1: "Mosch",
            sy5: "ܡܳܫ",
            lt10: "ܡܫ",
            sy9: "",
            sy6: "",
            dt3: "",
            lt11: "ܡܫ",
            dt2: ""
        },
        {
            lt12: "",
            sy8: "",
            sy7: "",
            lt10: "ܣܦܪܘܬܐ",
            sy6: "",
            lt11: "ܣܦܪܘܬܐ",
            sy4: "ܣܦܪܘܬܐ",
            dt3: "",
            dt1: "Safruta",
            id: "3800",
            dt2: "",
            sy5: "ܣܰܦܪܽܘܬܰܐ",
            sy9: ""
        },
        {
            sy4: "ܢܒܘܟ݂ܕܢܨܪ",
            id: "3147",
            lt10: "ܢܒܘܟ݂ܕܢܨܪ",
            lt11: "ܢܒܘܟ݂ܕܢܨܪ",
            sy7: "",
            sy9: "",
            dt3: "",
            lt12: "",
            sy5: "ܢܰܒܽܘܟ݂ܰܕܢܰܨܰܪ",
            dt1: "Nabochadnasar",
            sy6: "",
            sy8: "",
            dt2: "Nebukadnezzar"
        },
        {
            dt2: "Gastwirt",
            sy9: "",
            id: "1486",
            sy4: "ܡܪܐ ܡܛܥܡܐ",
            lt11: "moro mațĉmo",
            lt10: "more mațĉmo",
            lt12: "",
            dt3: "Restaurantinhaber",
            sy5: "ܡܳܪܳܐ ܡܰܛܥܡܳܐ",
            sy6: "",
            sy8: "",
            sy7: "",
            dt1: "Gastronom"
        },
        {
            sy4: "ܚܘܒܐ ܕܐܒܗܐ",
            id: "1091",
            sy7: "",
            sy8: "",
            lt10: "ĥubo d`abohe",
            dt1: "Elternliebe",
            sy9: "",
            dt3: "",
            dt2: "",
            lt12: "",
            lt11: "",
            sy5: "ܚܽܘܒܳܐ ܕܰܐܒܳܗ̈ܶܐ",
            sy6: ""
        },
        {
            dt2: "",
            sy6: "",
            dt1: "Wohin gehst du",
            id: "5154",
            lt11: "",
            sy8: "",
            sy4: "ܠܐܝܟܐ ܐܙܠ ܐܢܬ",
            sy5: "ܠܰܐܝܟܳܐ ܐܳܙܶܠ ܐܰܢ̱ܬ",
            dt3: "",
            sy9: "",
            lt12: "",
            lt10: "layko ozel at",
            sy7: ""
        },
        {
            sy9: "",
            sy4: "ܝܒܒ",
            lt11: "ܝܒܒ",
            dt2: "",
            id: "5181",
            lt10: "ܝܒܒ",
            sy5: "ܝܰܒܶܒ",
            sy7: "",
            sy8: "",
            sy6: "",
            dt1: "Yabeb",
            dt3: "",
            lt12: ""
        },
        {
            sy4: "ܡܫܬܐܣܬܐ ܕܝܘܠܦܢܐ",
            dt3: "",
            dt2: "",
            id: "619",
            sy9: "",
            lt12: "",
            lt10: "mshatasto d`yulfono",
            lt11: "",
            sy8: "",
            sy5: "ܡܫܰܬܰܐܣܬܳܐ ܕܝܽܘܠܦܳܢܳܐ",
            sy7: "",
            dt1: "Bildungsanstalt",
            sy6: ""
        },
        {
            sy6: "",
            sy4: "ܡܣܟܝܢܐ",
            dt3: "",
            lt12: "",
            id: "293",
            sy5: "ܡܶܣܟܺܝܢܳܐ",
            sy7: "",
            dt2: "besitzlos",
            sy8: "",
            lt11: "",
            sy9: "",
            dt1: "arm",
            lt10: "meskino"
        },
        {
            lt10: "ܐܒܝܡܠܟܐ",
            lt12: "",
            sy4: "ܐܒܝܡܠܟܐ",
            sy6: "",
            dt2: "",
            sy7: "",
            lt11: "ܐܒܝܡܠܟܐ",
            dt1: "Abimalko",
            id: "95",
            sy8: "",
            sy5: "ܐܰܒܺܝܡܰܠܟܳܐ",
            sy9: "",
            dt3: ""
        },
        {
            sy4: "ܦܪܣܘܥܢܐ",
            dt2: "",
            id: "6029",
            sy8: "",
            lt11: "farsono",
            sy6: "ܦܪܣܢܐ",
            sy5: "ܦܰܪܣܽܘܥܳܢܳܐ",
            lt12: "",
            sy9: "",
            dt3: "",
            lt10: "farsuĉono",
            sy7: "ܦܰܪܣܳܢܳܐ",
            dt1: "Huftier"
        },
        {
            sy7: "",
            sy4: "ܨܘܪܬܐ",
            lt10: "ŝurotho",
            id: "616",
            sy8: "",
            lt12: "",
            sy6: "",
            dt2: "Fotos",
            lt11: "",
            dt3: "",
            dt1: "Bilder",
            sy5: "ܨܽܘ̈ܪܳܬ݂ܳܐ",
            sy9: ""
        },
        {
            sy7: "",
            sy9: "",
            sy5: "ܡܰܪܰܐܘܓܶܐ",
            lt11: "ܡܪܐܘܓܐ",
            lt12: "",
            sy4: "ܡܪܐܘܓܐ",
            id: "2926",
            dt1: "Marauge",
            sy8: "",
            lt10: "ܡܪܐܘܓܐ",
            sy6: "",
            dt3: "",
            dt2: "Marauwge"
        },
        {
            sy7: "ܪܥܶܠ",
            dt1: "zittern",
            dt3: "",
            lt12: "",
            sy9: "",
            sy8: "",
            sy6: "ܪܥܠ",
            lt11: "rĉel",
            id: "5340",
            sy4: "ܐܪܥܠ",
            dt2: "",
            lt10: "arĉel",
            sy5: "ܐܰܪܥܶܠ"
        },
        {
            sy5: "ܢܳܦܽܘܩܽܘܬ݂ ܡܶܠܬ݂ܳܐ",
            dt3: "",
            sy7: "",
            sy6: "",
            lt12: "",
            dt2: "Redekunst",
            sy9: "",
            lt11: "",
            sy4: "ܢܦܘܩܘܬ ܡܠܬܐ",
            id: "6144",
            lt10: "nofuquth meltho",
            sy8: "",
            dt1: "Eloquenz"
        },
        {
            sy8: "",
            dt2: "",
            lt10: "ĥaye da'mđito",
            sy5: "ܚܰܝܶ̈ܐ ܕܰܡܕܺܝܢ̱ܬܳܐ",
            dt1: "Städteleben",
            id: "6230",
            sy6: "",
            sy7: "",
            lt11: "",
            sy4: "ܚܝܐ ܕܡܕܝܢܬܐ",
            dt3: "",
            sy9: "",
            lt12: ""
        },
        {
            sy4: "ܐܠܝܕܥ",
            lt11: "ܐܠܝܕܥ",
            dt3: "",
            sy6: "",
            sy5: "ܐܶܠܝܰܕܰܥ",
            sy8: "",
            sy7: "",
            sy9: "",
            lt10: "ܐܠܝܕܥ",
            dt2: "",
            id: "1082",
            lt12: "",
            dt1: "Eljada"
        },
        {
            id: "1729",
            lt11: "",
            sy7: "",
            sy9: "",
            sy4: "ܬܚܘܡܐ",
            dt3: "",
            lt12: "",
            dt2: "",
            sy6: "",
            sy5: "ܬܚܽܘܡܳܐ",
            sy8: "",
            lt10: "tĥumo",
            dt1: "Grenze"
        },
        {
            sy8: "",
            lt11: "",
            sy4: "ܛܘܗܡܢܝܘܬܐ",
            sy5: "ܛܽܘܗܡܳܢܳܝܽܘܬ݂ܳܐ",
            dt1: "Rassismus",
            sy7: "",
            sy6: "",
            lt12: "",
            dt3: "",
            lt10: "țuhmonoyutho",
            dt2: "",
            sy9: "",
            id: "3612"
        },
        {
            sy4: "ܫܠܝܚܐ",
            sy7: "ܬܰܠܡܺܝܕܳܐ",
            lt11: "talmiđo",
            lt10: "shliĥo",
            id: "259",
            dt1: "Apostel",
            sy6: "ܬܠܡܝܕܐ",
            sy9: "",
            dt3: "",
            sy8: "",
            dt2: "",
            sy5: "ܫܺܠܝܚܳܐ",
            lt12: ""
        },
        {
            lt10: "ĥđonoyuth attho",
            sy7: "",
            lt11: "",
            sy9: "",
            id: "3085",
            dt1: "Monogamie",
            sy4: "ܚܕܢܝܘܬ ܐܢܬܬܐ",
            lt12: "",
            sy8: "",
            dt3: "",
            dt2: "",
            sy5: "ܚܕܳܢܳܝܽܘܬ݂ ܐܰܢ̱ܬܬ݂ܳܐ",
            sy6: ""
        },
        {
            sy9: "",
            sy8: "",
            dt3: "",
            dt2: "Eucharastie",
            lt11: "qurobo",
            sy7: "ܩܽܘܪܳܒܳܐ",
            dt1: "Abendmahl",
            sy6: "ܩܘܪܒܐ",
            sy4: "ܐܘܟܪܝܣܛܝܐ",
            sy5: "ܐܽܘܟ݂ܰܪܺܝܣܛܺܝܰܐ",
            lt10: "ukharistiya",
            lt12: "",
            id: "76"
        },
        {
            lt11: "marțuț fulĥono",
            sy7: "ܡܰܪܛܽܘܛ ܦܽܘܠܚܳܢܳܐ",
            dt2: "",
            id: "279",
            sy5: "ܡܰܪܛܽܘܛ ܦܽܘܠܚܳܢܳܐ",
            sy4: "ܡܪܛܘܛ ܦܘܠܚܢܐ",
            sy8: "",
            sy6: "ܡܪܛܘܛ ܦܘܠܚܢܐ",
            lt10: "marțuț fulĥono",
            sy9: "",
            dt3: "",
            dt1: "Arbeitshemd",
            lt12: ""
        },
        {
            sy7: "",
            sy8: "",
            dt2: "",
            sy4: "ܢܣܟ",
            sy9: "",
            dt3: "",
            sy5: "ܢܣܰܟ݂",
            lt12: "",
            id: "2605",
            lt10: "nsakh",
            dt1: "kopieren",
            lt11: "",
            sy6: ""
        },
        {
            sy8: "",
            lt10: "ܙܕܝܩܐ",
            id: "5220",
            lt11: "ܙܕܝܩܐ",
            dt3: "",
            sy7: "",
            sy6: "",
            dt1: "Zadiqa",
            dt2: "",
            sy5: "ܙܰܕܺܝܩܰܐ",
            sy4: "ܙܕܝܩܐ",
            lt12: "",
            sy9: ""
        },
        {
            lt11: "yađuĉthono",
            dt3: "Fachmann",
            dt1: "Erfahrener",
            lt10: "yođuĉo",
            sy9: "ܡܢܰܣܝܳܢܳܐ",
            lt12: "mnasyono",
            sy6: "ܝܕܘܥܬܢܐ",
            sy7: "ܝܰܕܽܘܥܬ݂ܳܢܳܐ",
            sy5: "ܝܳܕܽܘܥܳܐ",
            dt2: "Profi",
            sy8: "ܡܢܣܝܢܐ",
            id: "1144",
            sy4: "ܝܕܘܥܐ"
        },
        {
            dt2: "",
            sy8: "",
            dt3: "",
            sy4: "ܐܝܠܘܠ",
            sy5: "ܐܺܝܠܽܘܠ",
            lt11: "",
            lt10: "ilul",
            sy6: "",
            lt12: "",
            dt1: "September",
            id: "4125",
            sy9: "",
            sy7: ""
        },
        {
            lt10: "ܪܠܦ",
            dt2: "Ralph",
            sy5: "ܪܰܠܦ",
            sy4: "ܪܠܦ",
            sy8: "",
            sy9: "",
            id: "3599",
            lt12: "ܪܐܠܦ",
            sy6: "ܪܐܠܦ",
            sy7: "ܪܰܐܠܦ",
            dt3: "",
            dt1: "Ralf",
            lt11: "ܪܠܦ"
        },
        {
            id: "4773",
            sy6: "ܗܘܢܢܐ",
            dt2: "",
            dt1: "vernünftig",
            lt12: "",
            sy4: "ܢܗܝܪ ܡܕܥܐ",
            sy7: "ܗܰܘܢܳܢܳܐ",
            lt10: "nahir mađĉo",
            sy9: "",
            dt3: "",
            sy5: "ܢܰܗܺܝܪ ܡܰܕܥܳܐ",
            lt11: "haunono",
            sy8: ""
        },
        {
            sy5: "ܐܰܪܒܰܥ ܦܶܢܝܳܢ",
            dt3: "",
            lt12: "",
            sy6: "ܐܪܒܥ ܦܢܝܬܐ",
            id: "4835",
            sy7: "ܐܰܪܒܰܥ ܦܶܢܝ̈ܳܬ݂ܳܐ",
            sy4: "ܐܪܒܥ ܦܢܝܢ",
            lt10: "arbaĉ fenyon",
            sy8: "",
            sy9: "",
            dt2: "",
            lt11: "arbaĉ fenyotho",
            dt1: "Vier Himmelsrichtungen"
        },
        {
            sy9: "",
            lt12: "",
            id: "1077",
            dt3: "Elimelekh",
            sy8: "",
            sy5: "ܐܶܠܺܝܡܰܠܟܳܐ",
            lt10: "ܠܠܝܡܠܟܐ",
            lt11: "ܠܠܝܡܠܟܐ",
            dt2: "Elimelech",
            sy4: "ܐܠܝܡܠܟܐ",
            dt1: "Elimalko",
            sy7: "",
            sy6: ""
        },
        {
            lt10: "shuroyo",
            dt2: "beginnen",
            sy6: "ܫܪܝ",
            lt12: "",
            sy9: "",
            lt11: "shari",
            sy8: "",
            id: "5943",
            sy4: "ܫܘܪܝܐ",
            sy7: "ܫܰܪܺܝ",
            dt3: "in Gang setzen",
            dt1: "anfangen",
            sy5: "ܫܽܘܪܳܝܳܐ"
        },
        {
            sy5: "ܚܕܳܝܽܘܬ݂ܳܐ",
            sy8: "",
            sy6: "ܐܣܝܪܘܬܐ",
            dt1: "Zusammenhalt",
            sy7: "ܐܰܣܺܝܪܽܘܬ݂ܳܐ",
            dt3: "",
            sy4: "ܚܕܝܘܬܐ",
            lt11: "asirutho",
            dt2: "",
            id: "5379",
            lt10: "ĥđoyutho",
            lt12: "",
            sy9: ""
        },
        {
            lt11: "țayro",
            dt3: "",
            sy8: "",
            dt2: "",
            sy5: "ܢܶܫܪܳܐ",
            lt12: "",
            sy9: "",
            sy7: "ܛܰܝܪܳܐ",
            dt1: "Adler",
            lt10: "neshro",
            sy4: "ܢܫܪܐ",
            sy6: "ܛܝܪܐ",
            id: "132"
        },
        {
            sy5: "ܓܰܠܝܳܐ ܝܳܐ",
            dt1: "Er ist nackt",
            id: "FKe3j6WC4BQWrgFph056",
            dt3: "",
            "date": {
                "seconds": 1590850408,
                "nanoseconds": 157000000
            },
            lt11: "zalțono yo",
            sy8: "",
            lt12: "",
            dt2: "",
            sy6: "ܝܠܛܢܐ ܝܐ",
            sy7: "ܝܰܠܛܳܢܳܐ ܝܳܐ",
            sy9: "",
            lt10: "galyo yo",
            sy4: "ܓܠܝܐ ܝܐ"
        },
        {
            sy8: "",
            sy7: "",
            sy9: "",
            sy6: "",
            lt12: "",
            lt10: "ܝܝܩܪ",
            sy4: "ܝܝܩܪ",
            dt2: "Jiqar",
            sy5: "ܝܺܝܩܰܪ",
            dt1: "Yiqar",
            lt11: "ܝܝܩܪ",
            id: "5209",
            dt3: ""
        },
        {
            sy6: "",
            lt11: "",
            sy9: "",
            id: "5304",
            dt3: "",
            sy8: "",
            dt1: "Zentralheizung",
            dt2: "",
            lt12: "",
            lt10: "mshaĥnono nuqaztonoyo",
            sy7: "",
            sy4: "ܡܫܚܢܢܐ ܢܘܩܙܬܢܝܐ",
            sy5: "ܡܫܰܚܢܳܢܳܐ ܢܽܘܩܰܙܬܳܢܳܝܳܐ"
        },
        {
            id: "2755",
            sy5: "ܪܳܥܽܘܡܳܐܝܺܬ݂",
            dt2: "stürmisch",
            lt12: "",
            lt11: "",
            dt1: "leidenschaftlich",
            sy9: "",
            dt3: "",
            sy7: "",
            sy8: "",
            sy6: "",
            sy4: "ܪܥܘܡܐܝܬ",
            lt10: "roĉumoith"
        },
        {
            dt1: "rein",
            lt10: "ŝafyo",
            sy4: "ܨܦܝܐ",
            lt11: "naqdo",
            sy5: "ܨܰܦܝܳܐ",
            id: "3673",
            sy9: "ܢܰܕܺܝܦܳܐ",
            sy6: "ܢܩܕܐ",
            dt2: "sauber",
            lt12: "nadifo",
            dt3: "",
            sy8: "ܢܕܝܦܐ",
            sy7: "ܢܰܩܕܳܐ"
        },
        {
            sy8: "",
            sy6: "ܗܟܢ ܢܗܘܐ",
            id: "5617",
            sy7: "ܗܳܟ݂ܰܢ ܢܶܗܘܶܐ",
            dt1: "So ist es",
            sy4: "ܗܟܢ ܐܝܬܘ",
            lt11: "hokhan nehwe",
            dt2: "So soll es sein",
            dt3: "",
            sy9: "",
            lt10: "hokhan ithau",
            lt12: "",
            sy5: "ܗܳܟ݂ܰܢ ܐܺܝܬܰܘܗܝ"
        },
        {
            dt3: "",
            lt10: "ܟܘܟܝܬܐ",
            id: "2663",
            lt12: "",
            lt11: "ܟܘܟܝܬܐ",
            sy4: "ܟܘܟܝܬܐ",
            sy5: "ܟܽܘܟܺܝܬܰܐ",
            sy9: "",
            sy8: "",
            sy6: "",
            sy7: "",
            dt2: "",
            dt1: "Kukita"
        },
        {
            sy4: "ܢܘܢܐ",
            sy6: "",
            lt10: "ܢܘܢܐ",
            sy8: "",
            lt12: "",
            lt11: "ܢܘܢܐ",
            dt2: "",
            dt1: "Nuno",
            dt3: "",
            sy7: "",
            sy9: "",
            id: "3313",
            sy5: "ܢܽܘܢܳܐ"
        },
        {
            sy9: "",
            sy8: "",
            id: "4552",
            sy5: "ܟܡܺܝܪܳܐܝܺܬ݂",
            lt12: "",
            dt1: "traurigerweise",
            lt10: "kmiroith",
            sy7: "ܚܰܫܳܐܝܺܬ݂",
            dt3: "",
            sy6: "ܚܫܐܝܬ",
            lt11: "ĥashoith",
            sy4: "ܟܡܝܪܐܝܬ",
            dt2: ""
        },
        {
            dt1: "Samira",
            dt2: "",
            sy9: "",
            sy6: "",
            lt10: "ܣܡܝܪܐ",
            dt3: "",
            sy7: "",
            sy4: "ܣܡܝܪܐ",
            lt12: "",
            id: "3823",
            sy8: "",
            sy5: "ܣܰܡܺܝܪܰܐ",
            lt11: "ܣܡܝܪܐ"
        },
        {
            sy8: "",
            dt1: "Bar Hadad",
            id: "437",
            sy4: "ܒܪܗܕܕ",
            sy6: "ܒܪܚܕܕ",
            dt2: "",
            sy9: "",
            dt3: "",
            lt11: "ܒܪܗܕܕ",
            sy7: "ܒܰܪܚܰܕܰܕ",
            lt10: "ܒܪܗܕܕ",
            sy5: "ܒܰܪܗܰܕܰܕ",
            lt12: "ܒܪܚܕܕ"
        },
        {
            dt1: "lecker",
            lt12: "",
            id: "2735",
            dt2: "schmeckt",
            sy9: "",
            sy8: "",
            sy5: "ܒܰܣܺܝܡܳܐ",
            sy7: "ܛܥܺܝܡܳܐ",
            sy6: "ܛܥܝܡܐ",
            lt11: "țĉimo",
            lt10: "basimo",
            dt3: "köstlich",
            sy4: "ܒܣܝܡܐ"
        },
        {
            sy7: "",
            sy5: "ܙܰܒܢܳܐ ܕܙܰܪܥܳܐ",
            dt3: "",
            id: "3783",
            sy6: "",
            lt10: "zabno da`zroĉo",
            sy8: "",
            lt12: "",
            lt11: "",
            dt1: "Saatzeit",
            dt2: "",
            sy9: "",
            sy4: "ܙܒܢܐ ܕܙܪܥܐ"
        },
        {
            sy4: "ܕܪܓ",
            dt2: "",
            dt3: "",
            sy6: "ܕܪܟ",
            lt12: "",
            lt10: "drağ",
            id: "5675",
            lt11: "drakh",
            sy8: "",
            sy9: "",
            sy7: "ܕܪܰܟ݂",
            sy5: "ܕܪܰܓ݂",
            dt1: "abstufen"
        },
        {
            dt2: "",
            dt1: "Sumpf",
            sy4: "ܐܓܡܐ",
            lt11: "",
            id: "6388",
            lt12: "",
            sy8: "",
            sy5: "ܐܰܓܡܳܐ",
            sy9: "",
            sy7: "",
            lt10: "agmo",
            sy6: "",
            dt3: ""
        },
        {
            sy8: "",
            sy6: "",
            dt3: "",
            sy7: "",
            dt2: "Mariya",
            lt11: "ܡܐܪܝܐ",
            dt1: "Maria",
            sy4: "ܡܐܪܝܐ",
            lt10: "ܡܐܪܝܐ",
            sy9: "",
            sy5: "ܡܰܪܐܝܰܐ",
            id: "2933",
            lt12: ""
        },
        {
            sy5: "ܡܳܡܽܘܢܳܝܳܐ",
            sy7: "",
            lt12: "",
            sy4: "ܡܡܘܢܝܐ",
            id: "1311",
            dt3: "",
            dt2: "",
            lt10: "momunoye",
            sy6: "",
            sy8: "",
            dt1: "finanziell",
            lt11: "",
            sy9: ""
        },
        {
            sy5: "ܡܪܰܟܟܳܢܳܐ",
            sy6: "ܡܢܦܣܢܐ",
            sy8: "",
            lt11: "mnafŝono",
            dt1: "Abführmittel",
            sy7: "ܡܢܰܦܣܳܢܳܐ",
            sy9: "",
            lt10: "mrakkhono",
            id: "5565",
            sy4: "ܡܪܟܟܢܐ",
            dt3: "",
            lt12: "",
            dt2: ""
        },
        {
            lt11: "ĉabițo",
            lt10: "ĉabuțo",
            sy4: "ܥܒܘܛܐ",
            sy7: "ܥܰܒܺܝܛܳܐ",
            dt2: "",
            dt1: "dick",
            sy8: "",
            id: "881",
            sy5: "ܥܰܒܽܘܛܳܐ",
            lt12: "",
            dt3: "",
            sy9: "",
            sy6: "ܥܒܝܛܐ"
        },
        {
            sy4: "ܚܬܢܐ",
            sy5: "ܚܰܬ݂ܢܳܐ",
            lt12: "",
            dt3: "",
            sy8: "",
            dt2: "",
            sy9: "",
            lt11: "",
            dt1: "Bräutigam",
            lt10: "ĥathno",
            sy7: "",
            id: "694",
            sy6: ""
        },
        {
            lt10: "reğsho",
            sy7: "",
            sy5: "ܪܶܓ݂ܫܳܐ",
            lt11: "",
            sy4: "ܪܓܫܐ",
            sy6: "",
            sy8: "",
            dt3: "",
            sy9: "",
            dt1: "Empfindung",
            lt12: "",
            dt2: "",
            id: "1100"
        },
        {
            id: "1699",
            dt2: "Gotteswille",
            lt11: "ŝebyono d`aloho",
            sy7: "ܨܶܒܝܳܢܳܐ ܕܰܐܠܳܗܳܐ",
            dt3: "",
            sy9: "",
            sy6: "ܨܒܝܢܐ ܕܐܠܗܐ",
            sy4: "ܨܒܝܢܗ ܕܡܪܝܐ",
            sy8: "",
            dt1: "Gottes Wille",
            lt10: "ŝebyone d`moryo",
            lt12: "",
            sy5: "ܨܶܒܝܳܢܶܗ ܕܡܳܪܝܳܐ"
        },
        {
            lt12: "",
            sy7: "ܩܰܪܦܫܽܘܫܳܢܳܝܳܐ",
            dt1: "Althändler",
            sy6: "ܩܪܦܫܘܫܢܝܐ",
            lt10: "mzaben ĉatiqotho",
            dt3: "",
            sy4: "ܡܙܒܢ ܥܬܝܩܬܐ",
            sy5: "ܡܙܰܒܶܢ ܥܰܬܺܝܩܳܬ݂ܳܐ",
            id: "5868",
            lt11: "qarfshushonoyo",
            sy9: "",
            sy8: "",
            dt2: ""
        },
        {
            lt11: "",
            sy5: "ܐܶܡܗܳܝܳܐ",
            sy7: "",
            lt12: "",
            sy4: "ܐܡܗܝܐ",
            lt10: "emhoyo",
            sy8: "",
            sy6: "",
            dt1: "mütterlich",
            dt2: "",
            sy9: "",
            id: "3137",
            dt3: ""
        },
        {
            dt2: "Du hast zugenommen",
            lt11: "",
            sy8: "",
            sy6: "",
            sy7: "",
            lt12: "",
            sy4: "ܬܩܝܢܬ",
            dt3: "Du hast mollig geworden",
            sy5: "ܬܰܩܺܝܢܰܬ",
            "date": {
                "seconds": 1590849625,
                "nanoseconds": 741000000
            },
            lt10: "Taqinat",
            sy9: "",
            dt1: "Du bist dick geworden",
            id: "FRsOoTY44HVxCVReYztr"
        },
        {
            dt3: "",
            dt2: "",
            sy4: "ܥܕܬܢܝܐ",
            sy9: "",
            sy7: "",
            sy8: "",
            id: "2522",
            sy5: "ܥܺܕܬܳܢܳܝܳܐ",
            lt11: "",
            lt12: "",
            dt1: "klerikal",
            sy6: "",
            lt10: "ĉitonoyo"
        },
        {
            id: "5136",
            lt10: "kafar",
            lt11: "mkafar",
            sy8: "",
            dt1: "wischen",
            dt3: "",
            sy9: "",
            dt2: "",
            lt12: "",
            sy5: "ܟܰܦܰܪ",
            sy4: "ܟܦܪ",
            sy7: "ܡܟܰܦܰܪ",
            sy6: "ܡܟܦܪ"
        },
        {
            lt11: "",
            dt2: "",
            sy6: "",
            sy4: "ܫܓܘܪܐ",
            sy7: "",
            dt1: "Heizer",
            lt10: "shoğuro",
            lt12: "",
            id: "1946",
            sy5: "ܫܳܓ݂ܽܘܪܳܐ",
            sy8: "",
            dt3: "",
            sy9: ""
        },
        {
            dt2: "",
            lt11: "ܢܒܘܢܝܕ",
            sy7: "",
            lt12: "",
            id: "3148",
            sy8: "",
            sy9: "",
            dt3: "",
            sy5: "ܢܰܒܽܘܢܺܝܕ",
            dt1: "Nabonid",
            lt10: "ܢܒܘܢܝܕ",
            sy6: "",
            sy4: "ܢܒܘܢܝܕ"
        },
        {
            dt3: "",
            lt12: "",
            lt10: "țauwuthayđe",
            sy4: "ܛܘܘܬܝܕܗ",
            id: "2191",
            sy8: "",
            sy5: "ܛܰܘܽܘܬ݂ܰܝܕܶܗ",
            sy7: "",
            sy6: "",
            dt2: "",
            sy9: "",
            dt1: "in seiner Güte",
            lt11: ""
        },
        {
            id: "5580",
            sy8: "",
            sy5: "ܐܰܟܪܺܝ",
            lt11: "gdam",
            sy6: "ܓܕܡ",
            sy4: "ܐܟܪܝ",
            dt2: "",
            lt12: "",
            lt10: "akri",
            dt1: "abkürzen",
            sy7: "ܓܕܰܡ",
            sy9: "",
            dt3: ""
        },
        {
            lt10: "ĉudrono zedqoyo",
            dt2: "",
            lt12: "",
            sy8: "",
            sy5: "ܥܽܘܕܪܳܢܳܐ ܙܶܕܩܳܝܳܐ",
            sy4: "ܥܘܕܪܢܐ ܙܕܩܝܐ",
            dt1: "Rechtsbeistand",
            lt11: "",
            id: "3642",
            dt3: "",
            sy7: "",
            sy6: "",
            sy9: ""
        },
        {
            lt12: "",
            sy9: "",
            sy4: "ܐܣܦܝܪ ܪܓܠܐ",
            lt10: "esfir reğlo",
            sy8: "",
            dt3: "",
            id: "1437",
            sy7: "",
            sy6: "",
            lt11: "",
            dt2: "",
            dt1: "Fussball",
            sy5: "ܐܶܣܦܺܝܪ ܪܶܓ݂ܠܳܐ"
        },
        {
            lt12: "mthum",
            sy6: "ܡܬܘܡܐܝܬ",
            lt10: "kul ĉedon",
            lt11: "mthumoith",
            dt2: "",
            sy7: "ܡܬ݂ܽܘܡܳܐܺܝܬ݂",
            sy5: "ܟܽܠ ܥܶܕܳܢ",
            dt1: "allzeit",
            dt3: "",
            sy9: "ܡܬ݂ܽܘܡ",
            sy4: "ܟܠ ܥܕܢ",
            sy8: "ܡܬܘܡ",
            id: "5850"
        },
        {
            dt3: "",
            sy8: "",
            dt2: "",
            sy7: "",
            sy4: "ܟܘܬܠܐ ܕܬܠܓܐ",
            lt10: "kutlo d`talgo",
            id: "3993",
            sy6: "",
            sy5: "ܟܽܘܬܠܳܐ ܕܬܰܠܓܳܐ",
            sy9: "",
            lt12: "",
            lt11: "",
            dt1: "Schneeball"
        },
        {
            lt10: "lamfido",
            sy7: "",
            lt12: "",
            dt1: "Glühbirne",
            sy4: "ܠܡܦܐܕܐ",
            sy8: "",
            sy5: "ܠܰܡܦܺܐܕܳܐ",
            id: "1674",
            sy9: "",
            sy6: "",
            lt11: "",
            dt3: "",
            dt2: ""
        },
        {
            dt1: "Klebeband",
            sy6: "",
            sy7: "",
            id: "2503",
            sy5: "ܡܕܰܒܩܳܢܳܐ",
            sy8: "",
            lt11: "",
            lt12: "",
            sy9: "",
            lt10: "mdabqono",
            dt2: "",
            dt3: "",
            sy4: "ܡܕܒܩܢܐ"
        },
        {
            dt1: "Abedallat",
            lt12: "",
            sy8: "",
            id: "71",
            lt11: "ܥܒܕܐܠܬ",
            lt10: "ܥܒܕܐܠܬ",
            sy9: "",
            sy4: "ܥܒܕܐܠܬ",
            dt2: "",
            sy5: "ܥܰܒܶܕܰܐܠܰܬ",
            sy7: "",
            sy6: "",
            dt3: ""
        },
        {
            sy9: "",
            dt2: "",
            lt10: "men shel",
            lt11: "men shelyo",
            sy8: "",
            sy7: "ܡܶܢ ܫܶܠܝܳܐ",
            sy6: "ܡܢ ܫܠܝܐ",
            lt12: "",
            sy4: "ܡܢ ܫܠ",
            dt3: "",
            dt1: "alsbald",
            id: "5857",
            sy5: "ܡܶܢ ܫܶܠܝ̱"
        },
        {
            sy7: "",
            sy6: "",
            id: "3187",
            dt1: "Nahro",
            lt11: "ܢܗܪܐ",
            dt2: "",
            dt3: "",
            lt10: "ܢܗܪܐ",
            sy4: "ܢܗܪܐ",
            sy9: "",
            sy5: "ܢܰܗܪܳܐ",
            lt12: "",
            sy8: ""
        },
        {
            sy8: "",
            id: "4852",
            lt10: "golutho d`ĉamo",
            sy4: "ܓܠܘܬܐ ܕܥܡܐ",
            dt2: "",
            sy5: "ܓܳܠܽܘܬ݂ܳܐ ܕܥܰܡܳܐ",
            sy7: "",
            dt1: "Volksauswanderung",
            dt3: "",
            sy9: "",
            lt12: "",
            sy6: "",
            lt11: ""
        },
        {
            lt12: "",
            sy9: "",
            dt2: "Ostern",
            sy8: "",
            sy7: "ܥܺܐܕܳܐ ܪܰܒܳܐ",
            dt3: "",
            lt11: "ĉiđo rabo",
            id: "3401",
            sy6: "ܥܐܕܐ ܪܒܐ",
            dt1: "Osterfest",
            lt10: "ĉiđo da`qyomto",
            sy4: "ܥܐܕܐ ܕܩܝܡܬܐ",
            sy5: "ܥܺܐܕܳܐ ܕܰܩܝܳܡܬܳܐ"
        },
        {
            sy5: "ܙܟܺܝܬܰܐ",
            lt11: "ܙܟܝܬܐ",
            dt1: "Zkita",
            sy9: "",
            dt3: "",
            sy8: "",
            lt12: "",
            lt10: "ܙܟܝܬܐ",
            sy4: "ܙܟܝܬܐ",
            id: "5345",
            sy6: "",
            dt2: "",
            sy7: ""
        },
        {
            lt10: "busarĉe",
            sy8: "",
            sy6: "",
            lt12: "",
            dt2: "Sonnenblumenkerne",
            sy7: "",
            dt1: "Körner",
            lt11: "",
            id: "2612",
            sy9: "",
            dt3: "",
            sy4: "ܒܘܙܪܥܐ",
            sy5: "ܒܽܘܙܰܪ̈ܥܶܐ"
        },
        {
            sy6: "",
            id: "4709",
            sy5: "ܐܰܒܳܗܳܝܳܐ",
            sy8: "",
            lt12: "",
            dt3: "",
            lt11: "",
            sy7: "",
            sy9: "",
            dt2: "",
            lt10: "abohoyo",
            sy4: "ܐܒܗܝܐ",
            dt1: "väterlich"
        },
        {
            sy4: "ܡܫܝܚܙܟ݂ܐ",
            dt3: "",
            sy9: "",
            sy6: "ܡܫܝܚܐ ܙܟܐ",
            sy7: "ܡܫܺܝܚܳܐ ܙܟ݂ܳܐ",
            dt1: "Mschihozkho",
            id: "3119",
            lt10: "ܡܫܝܚܙܟ݂ܐ",
            dt2: "",
            sy8: "",
            lt12: "ܡܫܝܚܐ ܙܟ݂ܐ",
            sy5: "ܡܫܺܝܚܳܙܟ݂ܳܐ",
            lt11: "ܡܫܝܚܙܟ݂ܐ"
        },
        {
            dt3: "",
            lt11: "",
            sy9: "",
            lt12: "",
            id: "876",
            sy4: "ܠܥܙܢܝܐ",
            sy8: "",
            sy6: "",
            lt10: "leĉsonoyo",
            sy5: "ܠܶܥܙܳܢܳܝܳܐ",
            dt1: "dialektisch",
            sy7: "",
            dt2: ""
        },
        {
            id: "1558",
            sy8: "",
            sy4: "ܡܪܥܝܬܐ",
            sy6: "ܟܢܫܐ",
            lt12: "",
            lt11: "kensho",
            dt1: "Gemeinde",
            sy5: "ܡܰܪܥܺܝܬ݂ܳܐ",
            dt3: "",
            dt2: "Bistum",
            sy7: "ܟܶܢܫܳܐ",
            sy9: "",
            lt10: "marĉitho"
        },
        {
            sy9: "",
            sy7: "",
            dt1: "Werbung",
            sy4: "ܦܝܠܡܐ ܕܪܟܠܡܐ",
            dt2: "Werbefilm",
            dt3: "",
            lt11: "",
            sy6: "",
            sy5: "ܦܺܝܠܡܳܐ ܕܪܶܟܠܰܡ̈ܶܐ",
            lt10: "filmo d`reklame",
            lt12: "",
            id: "5071",
            sy8: ""
        },
        {
            dt1: "unbezahlte Schuld",
            sy4: "ܚܘܒܐ ܡܫܘܚܪܐ",
            lt12: "",
            sy6: "",
            lt11: "",
            sy5: "ܚܰܘܒܳܐ ܡܫܰܘܚܪܳܐ",
            dt2: "",
            dt3: "",
            sy9: "",
            sy8: "",
            id: "4641",
            lt10: "ĥaubo mshauĥro",
            sy7: ""
        },
        {
            sy5: "ܡܫܰܠܡܳܢܳܐ",
            lt10: "mshalmono",
            lt11: "",
            dt3: "",
            sy8: "",
            id: "4876",
            lt12: "",
            dt2: "",
            dt1: "Vollkommener",
            sy9: "",
            sy4: "ܡܫܠܡܢܐ",
            sy6: "",
            sy7: ""
        },
        {
            sy9: "",
            sy8: "",
            dt2: "",
            dt3: "",
            sy6: "",
            sy4: "ܐܦܪܝ",
            sy5: "ܐܰܦܪܺܝ",
            lt12: "",
            lt10: "ܐܦܪܝ",
            lt11: "ܐܦܪܝ",
            dt1: "Afri",
            id: "141",
            sy7: ""
        },
        {
            sy9: "",
            sy8: "",
            id: "2255",
            dt3: "",
            lt11: "ܐܝܬܐܠܗܐ",
            sy5: "ܐܺܝܬܐܰܠܳܗܳܐ",
            sy7: "ܐܺܝܬܐܰܠܰܗܰܐ",
            dt2: "",
            dt1: "Ithaloho",
            lt10: "ܐܝܬܐܠܗܐ",
            sy4: "ܐܝܬܐܠܗܐ",
            sy6: "ܐܝܬܐܠܗܐ",
            lt12: ""
        },
        {
            dt3: "",
            sy7: "",
            sy5: "ܐܽܘܪܟܐ ܕܓܰܠܠܳܐ",
            sy8: "",
            sy4: "ܐܘܪܟܐ ܕܓܠܠܐ",
            lt10: "urkho d`galo",
            sy9: "",
            dt2: "",
            sy6: "",
            lt12: "",
            id: "5045",
            lt11: "",
            dt1: "Wellenlänge"
        },
        {
            lt11: "ܐܬܪܐ",
            dt2: "",
            sy7: "",
            lt12: "",
            sy6: "",
            dt1: "Atra",
            sy8: "",
            dt3: "",
            lt10: "ܐܬܪܐ",
            sy5: "ܐܰܬܪܰܐ",
            sy9: "",
            id: "339",
            sy4: "ܐܬܪܐ"
        },
        {
            sy9: "",
            sy4: "ܡܪܕܘܬܐ",
            dt2: "",
            sy5: "ܡܰܪܕܽܘܬ݂ܳܐ",
            dt3: "",
            dt1: "Kultur",
            sy8: "",
            sy6: "",
            id: "2664",
            lt11: "",
            lt10: "mardutho",
            lt12: "",
            sy7: ""
        },
        {
            sy7: "ܦܪܺܝܫܽܘܬ݂ܳܐ",
            dt2: "",
            lt11: "frishutho",
            lt12: "mfarshutho",
            dt3: "",
            lt10: "mfarshonutho",
            sy4: "ܡܦܪܫܢܘܬܐ",
            id: "5496",
            sy9: "ܡܦܰܪܫܽܘܬ݂ܳܐ",
            sy6: "ܦܪܝܫܘܬܐ",
            sy5: "ܡܦܰܪܫܳܢܽܘܬ݂ܳܐ",
            sy8: "ܡܦܪܫܘܬܐ",
            dt1: "Diskriminierung"
        },
        {
            dt3: "",
            lt12: "",
            sy5: "ܢܟ݂ܺܝܠܳܐܝܺܬ݂",
            sy6: "",
            id: "4788",
            sy4: "ܢܟܝܠܐܝܬ",
            lt11: "",
            dt2: "betrügerisch",
            sy9: "",
            sy7: "",
            dt1: "verräterisch",
            lt10: "nkhiloith",
            sy8: ""
        },
        {
            sy7: "ܩܘܪܢܝܬܐ",
            lt11: "qurnitho",
            dt1: "Ecke",
            dt3: "",
            sy9: "",
            lt12: "",
            dt2: "Winkel",
            lt10: "qornitho",
            sy8: "",
            sy6: "ܩܘܪܢܝܬܐ",
            sy4: "ܩܪܢܝܬܐ",
            sy5: "ܩܳܪܢܺܝܬ݂ܳܐ",
            id: "995"
        },
        {
            sy7: "",
            id: "3572",
            lt10: "ܩܘܫܬܐ",
            dt3: "",
            sy8: "",
            sy4: "ܩܘܫܬܐ",
            dt1: "Quschta",
            sy5: "ܩܽܘܫܬܰܐ",
            sy9: "",
            lt12: "",
            dt2: "",
            lt11: "ܩܘܫܬܐ",
            sy6: ""
        },
        {
            lt11: "",
            sy5: "ܡܶܬ݂ܩܰܪܝܳܢܳܐ",
            lt12: "",
            dt3: "",
            id: "2771",
            dt1: "leserlich",
            sy8: "",
            sy9: "",
            sy6: "",
            lt10: "methqaryono",
            sy4: "ܡܬܩܪܝܢܐ",
            sy7: "",
            dt2: ""
        },
        {
            sy6: "",
            sy9: "",
            sy8: "",
            lt11: "ܠܘܝ",
            lt12: "",
            id: "2796",
            dt2: "",
            dt3: "",
            sy4: "ܠܘܝ",
            lt10: "ܠܘܝ",
            sy5: "ܠܶܘܺܝ",
            dt1: "Levi",
            sy7: ""
        },
        {
            lt11: "",
            lt12: "",
            dt1: "gratulieren",
            dt3: "",
            lt10: "hani",
            sy7: "",
            sy9: "",
            sy6: "",
            sy8: "",
            dt2: "",
            sy4: "ܗܢܝ",
            id: "1725",
            sy5: "ܗܰܢܺܝ"
        },
        {
            sy4: "ܟܦܢܐ",
            dt3: "",
            id: "2072",
            lt11: "kafnutho",
            sy7: "ܟܰܦܢܽܘܬ݂ܳܐ",
            sy6: "ܟܦܢܘܬܐ",
            sy8: "ܬܦܢܐ",
            dt2: "",
            lt12: "tfono",
            sy5: "ܟܰܦܢܳܐ",
            sy9: "ܬܦܳܢܳܐ",
            lt10: "kafno",
            dt1: "Hunger"
        },
        {
            lt12: "mauĉo",
            id: "5636",
            sy8: "ܡܘܥܐ",
            dt3: "",
            dt2: "",
            sy5: "ܢܽܘܦܳܨܳܐ",
            sy4: "ܢܘܦܨܐ",
            dt1: "Abschütteln",
            sy9: "ܡܰܘܥܳܐ",
            sy6: "ܦܘܪܦܥܐ",
            lt11: "furfoĉo",
            lt10: "nufoŝo",
            sy7: "ܦܽܘܪܦܳܥܳܐ"
        },
        {
            dt2: "",
            sy9: "",
            sy4: "ܬܚܪܙܬܐ ܕܓܘܒܝܐ",
            id: "4943",
            sy5: "ܬܰܚܪܶܙܬܳܐ ܕܓܽܘܒܳܝܳܐ",
            dt3: "",
            lt12: "",
            sy8: "",
            sy7: "",
            dt1: "Wahlprogramm",
            sy6: "",
            lt10: "taĥrezto d`guboyo",
            lt11: ""
        },
        {
            sy8: "",
            dt2: "Helikopter",
            sy4: "ܛܝܦܬܐ",
            dt3: "",
            lt11: "",
            sy7: "",
            sy9: "",
            dt1: "Hubschrauber",
            sy5: "ܛܰܝܳܦܬܳܐ",
            lt12: "",
            id: "2062",
            lt10: "țayofto",
            sy6: ""
        },
        {
            sy9: "",
            dt1: "Altersversicherung",
            dt3: "",
            sy4: "ܥܪܘܒܘܬ ܣܝܒܘܬܐ",
            dt2: "",
            lt11: "",
            sy6: "",
            id: "5863",
            sy5: "ܥܰܪܽܘܒܽܘܬ݂ ܣܰܝܒܽܘܬ݂ܳܐ",
            lt12: "",
            sy7: "",
            lt10: "ĉarubuth saybutho",
            sy8: ""
        },
        {
            dt3: "",
            sy5: "ܨܳܬ݂ ܟܰܣܝܳܐܺܝܬ݂",
            sy8: "",
            sy7: "",
            dt2: "",
            dt1: "abhören",
            sy6: "",
            sy4: "ܨܬ ܟܣܝܐܝܬ",
            lt10: "ŝoth kasyoith",
            lt11: "",
            sy9: "",
            lt12: "",
            id: "5574"
        },
        {
            dt1: "Wildschwein",
            sy6: "ܕܝܙܐ",
            lt10: "warzo",
            lt11: "dizo",
            sy7: "ܕܺܝܙܳܐ",
            sy4: "ܘܪܙܐ",
            lt12: "",
            dt3: "",
            sy5: "ܘܰܪܙܳܐ",
            dt2: "",
            id: "5109",
            sy9: "",
            sy8: ""
        },
        {
            sy9: "",
            sy6: "",
            sy7: "",
            dt1: "Götzendienst",
            id: "1717",
            sy5: "ܦܬܰܟ݂ܪܽܘܬ݂ܳܐ",
            lt11: "",
            dt2: "",
            dt3: "",
            sy4: "ܦܬܟܪܘܬܐ",
            lt10: "ftakhrutho",
            sy8: "",
            lt12: ""
        },
        {
            id: "5066",
            dt3: "",
            lt10: "man",
            dt1: "Wer",
            dt2: "",
            lt11: "",
            sy4: "ܡܢ",
            sy8: "",
            sy5: "ܡܰܢ",
            sy6: "",
            sy9: "",
            sy7: "",
            lt12: ""
        },
        {
            dt2: "",
            sy6: "",
            id: "3747",
            sy4: "ܠܐ ܡܫܚܬܢܐ",
            sy7: "",
            lt12: "",
            sy9: "",
            sy5: "ܠܳܐ ܡܰܫܚܬܳܢܳܐ",
            dt3: "",
            sy8: "",
            lt10: "lo mashĥtono",
            lt11: "",
            dt1: "rostfrei"
        },
        {
            sy8: "",
            lt12: "",
            sy9: "",
            id: "998",
            lt10: "nibe",
            sy6: "",
            sy5: "ܢܺܝܒ̈ܶܐ",
            dt3: "",
            dt2: "",
            lt11: "",
            sy7: "",
            dt1: "Eckzähne",
            sy4: "ܢܝܒܐ"
        },
        {
            lt11: "",
            sy5: "ܩܳܠܳܐ ܕܟܶܢܳܪܳܐ",
            lt10: "qolo d`kenoro",
            sy9: "",
            dt2: "Harfenstimme",
            sy6: "",
            dt1: "Die Stimme der Harfe",
            sy4: "ܩܠܐ ܕܟܢܪܐ",
            sy8: "",
            sy7: "",
            lt12: "",
            dt3: "",
            id: "885"
        },
        {
            sy5: "ܐܶܒܪܳܐ",
            sy7: "",
            sy4: "ܐܒܪܐ",
            id: "991",
            sy9: "",
            dt2: "",
            lt10: "ܐܒܪܐ",
            lt12: "",
            sy8: "",
            dt1: "Ebro",
            lt11: "ܐܒܪܐ",
            dt3: "",
            sy6: ""
        },
        {
            sy4: "ܒܕܪܘܣ",
            id: "519",
            sy6: "",
            sy8: "",
            sy9: "",
            lt12: "",
            sy7: "",
            dt2: "Budrus",
            lt11: "ܒܕܪܘܣ",
            lt10: "ܒܕܪܘܣ",
            sy5: "ܒܶܕܪܳܘܣ",
            dt1: "Bedrus",
            dt3: ""
        },
        {
            sy9: "",
            sy8: "",
            dt2: "",
            lt12: "",
            sy7: "",
            sy5: "ܫܽܘܩܳܐ",
            lt11: "",
            sy6: "",
            id: "5837",
            sy4: "ܫܘܩܐ",
            dt3: "",
            dt1: "Allee",
            lt10: "shuqo"
        },
        {
            sy7: "ܡܳܪܽܘ̈ܢܳܝܶܐ",
            sy8: "",
            lt10: "kaldoye",
            id: "4341",
            sy6: "ܡܪܘܢܝܐ",
            sy9: "",
            dt3: "",
            sy5: "ܟܰܠ̈ܕܳܝܶܐ",
            lt11: "morunoye",
            dt1: "Syrische Christen",
            sy4: "ܟܠܕܝܐ",
            dt2: "",
            lt12: ""
        },
        {
            lt10: "ܪܚܡܬ",
            sy9: "",
            lt11: "ܪܚܡܬ",
            dt3: "",
            sy5: "ܪܳܚܡܰܬ",
            sy8: "",
            lt12: "",
            sy7: "",
            sy4: "ܪܚܡܬ",
            dt2: "",
            dt1: "Rohmat",
            id: "3737",
            sy6: ""
        },
        {
            sy6: "",
            id: "3586",
            lt10: "ܪܒܘܬܐ",
            dt3: "",
            lt12: "",
            sy5: "ܪܰܒܽܘܬܰܐ",
            sy8: "",
            lt11: "ܪܒܘܬܐ",
            sy7: "",
            dt2: "",
            sy4: "ܪܒܘܬܐ",
            dt1: "Rabuta",
            sy9: ""
        },
        {
            dt3: "",
            dt2: "",
            lt10: "shniğo d`neshe",
            sy5: "ܫܢܺܝܓ݂ܳܐ ܕܢܶܫ̈ܶܐ",
            id: "1376",
            sy7: "",
            lt12: "",
            sy6: "",
            dt1: "Frauenverrückter",
            sy4: "ܫܢܝܓܐ ܕܢܫܐ",
            sy8: "",
            sy9: "",
            lt11: ""
        },
        {
            dt2: "",
            dt1: "Lebenserfahrung",
            sy5: "ܢܶܣܝܳܢ ܚܰܝ̈ܶܐ",
            lt11: "",
            lt12: "",
            lt10: "nesyon ĥaye",
            id: "2731",
            sy9: "",
            sy6: "",
            sy7: "",
            sy4: "ܢܣܝܢ ܚܝܐ",
            sy8: "",
            dt3: ""
        },
        {
            dt1: "Toilettenpapier",
            sy6: "",
            sy9: "",
            sy7: "",
            lt10: "warqo d`beth selyo",
            dt3: "",
            sy5: "ܘܰܪܩܳܐ ܕܒܶܝܬ݂ ܣܶܠܝܳܐ",
            lt11: "",
            sy4: "ܘܪܩܐ ܕܒܝܬ ܣܠܝܐ",
            id: "4518",
            dt2: "",
            lt12: "",
            sy8: ""
        },
        {
            sy5: "ܐܳܣܝܳܐ ܢܰܦܫܳܢܳܝܳܐ",
            id: "3537",
            dt3: "",
            sy8: "",
            dt1: "Psychiater",
            sy4: "ܐܣܝܐ ܢܦܫܢܝܐ",
            lt11: "",
            sy7: "",
            dt2: "",
            lt12: "",
            lt10: "osyo nafshonoyo",
            sy6: "",
            sy9: ""
        },
        {
            lt10: "ŝanduq krofotho",
            id: "6379",
            dt2: "",
            dt3: "",
            sy8: "",
            sy4: "ܨܢܕܘܩ ܟܪܦܬܐ",
            sy7: "",
            sy5: "ܨܰܢܕܽܘܩ ܟܪܳܦܳܬ݂ܳܐ",
            dt1: "Möbelkiste",
            lt12: "",
            sy9: "",
            sy6: "",
            lt11: ""
        },
        {
            sy6: "",
            sy8: "",
            dt3: "",
            sy5: "ܟܬ݂ܳܒܳܐ ܕܛܠܳܝ̈ܶܐ",
            lt10: "kthobo d`țloye",
            sy9: "",
            sy4: "ܟܬܒܐ ܕܛܠܝܐ",
            dt1: "Kinderbuch",
            sy7: "",
            dt2: "",
            lt12: "",
            lt11: "",
            id: "2477"
        },
        {
            sy4: "ܣܩܘܒܠܐ",
            dt2: "",
            id: "2597",
            sy6: "",
            sy7: "",
            dt3: "",
            sy9: "",
            dt1: "kontro",
            lt12: "",
            sy8: "",
            lt11: "",
            sy5: "ܣܰܩܽܘܒܠܳܐ",
            lt10: "saqublo"
        },
        {
            sy7: "ܒܽܘܪܳܟ݂ܳܐ",
            dt1: "Fruchtbarkeit",
            id: "1412",
            sy9: "",
            dt2: "Segen",
            sy6: "ܒܘܪܟܐ",
            dt3: "",
            lt12: "",
            sy5: "ܒܽܘܪܟܬ݂ܳܐ",
            sy8: "",
            lt10: "burktho",
            sy4: "ܒܘܪܟܬܐ",
            lt11: "burokho"
        },
        {
            dt3: "",
            sy9: "",
            sy6: "",
            lt10: "dolath",
            dt1: "d",
            lt11: "",
            lt12: "",
            sy4: "ܕ",
            sy7: "",
            dt2: "D",
            sy5: "ܕ",
            sy8: "",
            id: "780"
        },
        {
            dt2: "",
            id: "566",
            sy5: "ܥܡܺܝܠܳܐ",
            lt11: "shğilo",
            sy9: "",
            dt1: "beschäftigt",
            lt10: "ĉmilo",
            dt3: "",
            sy8: "",
            sy6: "ܫܓܝܠܐ",
            sy4: "ܥܡܝܠܐ",
            lt12: "",
            sy7: "ܫܓ݂ܺܝܠܳܐ"
        },
        {
            id: "5836",
            lt12: "",
            sy9: "",
            sy5: "ܟܽܠ ܓܢܶܣ",
            lt10: "kul gnes",
            dt2: "",
            dt3: "",
            sy4: "ܟܠ ܓܢܣ",
            lt11: "",
            sy8: "",
            dt1: "alle Arten",
            sy7: "",
            sy6: ""
        },
        {
            lt10: "ܒܢܝܡܝܢ",
            lt11: "ܒܢܝܡܝܢ",
            lt12: "ܒܢܝܡܝܢ",
            dt2: "Benyamin",
            sy4: "ܒܢܝܐܡܢ",
            sy9: "",
            sy6: "ܒܢܝܡܝܢ",
            sy8: "",
            sy7: "ܒܶܢܝܳܡܶܝܢ",
            id: "550",
            dt3: "Benyomin",
            sy5: "ܒܶܢܝܰܡܺܝܢ",
            dt1: "Benjamin"
        },
        {
            sy6: "",
            dt3: "",
            sy8: "",
            lt12: "",
            lt11: "",
            id: "2606",
            sy9: "",
            sy4: "ܘܪܩܐ ܕܢܣܟܐ",
            sy7: "",
            dt2: "",
            sy5: "ܘܰܪܩܳܐ ܕܰܢܣܳܟ݂ܳܐ",
            lt10: "warqo da`nsokho",
            dt1: "Kopierpapier"
        },
        {
            sy4: "ܡܥܘܟܐ",
            sy7: "ܡܥܰܘܟ݂ܽܘܬ݂ܳܐ",
            sy8: "",
            id: "2233",
            lt12: "",
            sy9: "",
            dt2: "Behinderung",
            sy6: "ܡܥܘܟܘܬܐ",
            lt11: "mĉaukhutho",
            lt10: "mĉaukho",
            dt1: "invalide",
            dt3: "",
            sy5: "ܡܥܰܘܟ݂ܳܐ"
        },
        {
            lt10: "mashruqitho",
            sy5: "ܡܰܫܪܽܘܩܺܝܬ݂ܳܐ",
            lt12: "",
            id: "3449",
            sy8: "",
            sy9: "",
            sy7: "ܡܰܫܪܽܘܩܳܐ",
            sy6: "ܡܫܪܘܩܐ",
            sy4: "ܡܫܪܘܩܝܬܐ",
            dt3: "",
            lt11: "mashruqo",
            dt2: "",
            dt1: "Pfeife"
        },
        {
            lt11: "ܪܫܦ",
            dt3: "",
            dt2: "",
            lt12: "",
            sy7: "",
            lt10: "ܪܫܦ",
            sy6: "",
            sy8: "",
            sy9: "",
            sy5: "ܪܶܫܶܦ",
            id: "3699",
            sy4: "ܪܫܦ",
            dt1: "Reschef"
        },
        {
            lt12: "",
            lt11: "",
            dt1: "Telefon",
            sy4: "ܬܠܝܦܘܢ",
            sy7: "",
            dt2: "",
            id: "4428",
            sy8: "",
            sy5: "ܬܶܠܺܝܦܽܘܢ",
            lt10: "telifun",
            dt3: "",
            sy9: "",
            sy6: ""
        },
        {
            lt11: "ܬܘܥ",
            sy8: "",
            lt10: "ܬܘܥ",
            sy4: "ܬܘܥ",
            dt3: "",
            dt1: "Toi",
            sy9: "",
            sy5: "ܬܳܥܽܘ",
            sy7: "ܬܳܥܺܝ",
            sy6: "ܬܥܝ",
            lt12: "ܬܥܝ",
            id: "4516",
            dt2: ""
        },
        {
            sy5: "ܕܳܪܳܐ ܟܺܐܦܳܢܳܝܳܐ",
            sy8: "",
            dt3: "",
            lt12: "",
            sy9: "",
            id: "4256",
            lt10: "doro kifonoyo",
            dt1: "Steinzeit",
            dt2: "",
            sy6: "",
            sy7: "",
            lt11: "",
            sy4: "ܕܪܐ ܟܐܦܢܝܐ"
        },
        {
            dt2: "",
            sy9: "",
            sy8: "",
            lt10: "rĥoqo",
            dt1: "Entfernung",
            sy6: "ܪܘܚܩܐ",
            sy7: "ܪܽܘܚܩܳܐ",
            sy4: "ܪܚܩܐ",
            lt12: "",
            sy5: "ܪܚܳܩܳܐ",
            lt11: "ruĥqo",
            dt3: "",
            id: "1114"
        },
        {
            id: "733",
            lt10: "makwayto",
            sy9: "",
            dt2: "",
            lt12: "",
            sy7: "",
            sy5: "ܡܰܟܘܰܝܬܳܐ",
            dt3: "",
            dt1: "Bügeleisen",
            sy4: "ܡܟܘܝܬܐ",
            sy8: "",
            sy6: "",
            lt11: ""
        },
        {
            lt10: "toruŝo",
            id: "2620",
            lt11: "",
            dt2: "Korrektor",
            sy4: "ܬܪܘܨܐ",
            sy7: "",
            dt3: "Verbesserer",
            lt12: "",
            sy9: "",
            sy6: "",
            sy8: "",
            sy5: "ܬܳܪܽܘܨܳܐ",
            dt1: "Korrigierer"
        },
        {
            lt10: "mayko at",
            sy4: "ܡܝܟܐ ܐܢܬ",
            sy5: "ܡܰܝܟܳܐ ܐܰܢ̱ܬ",
            lt11: "",
            id: "5152",
            dt3: "",
            lt12: "",
            sy7: "",
            sy6: "",
            sy9: "",
            dt2: "",
            sy8: "",
            dt1: "Woher kommst Du"
        },
        {
            sy5: "ܝܳܬܶܒ ܐ̱ܢܳܐ ܥܰܠ ܡܰܘܬܒܳܐ",
            lt11: "",
            id: "2139",
            sy8: "",
            sy7: "",
            lt10: "yoteb no ĉal mautbo",
            dt1: "Ich sitze auf der Bank",
            dt3: "",
            sy9: "",
            lt12: "",
            sy6: "",
            sy4: "ܝܬܒ ܐܢܐ ܥܠ ܡܘܬܒܐ",
            dt2: ""
        },
        {
            sy9: "",
            dt2: "",
            sy4: "ܘ",
            sy8: "",
            sy7: "",
            sy5: "ܘ",
            dt3: "",
            lt11: "",
            lt10: "u",
            lt12: "",
            dt1: "und",
            sy6: "",
            id: "4642"
        },
        {
            dt2: "",
            sy6: "",
            sy7: "",
            dt1: "Gesetzesübertretung",
            sy9: "",
            id: "1600",
            lt10: "dyosh nomuso",
            sy8: "",
            sy5: "ܕܝܳܫ ܢܳܡܽܘܣܳܐ",
            lt11: "",
            sy4: "ܕܝܫ ܢܡܘܣܐ",
            dt3: "",
            lt12: ""
        },
        {
            lt12: "",
            lt10: "ĉomure d`arĉo",
            sy4: "ܥܡܘܪܐ ܕܐܪܥܐ",
            dt2: "",
            dt3: "",
            sy8: "",
            sy9: "",
            sy7: "",
            lt11: "",
            dt1: "Erdbewohner",
            sy6: "",
            id: "1139",
            sy5: "ܥܳܡܽܘܪ̈ܐ ܕܐܰܪܥܳܐ"
        },
        {
            lt12: "",
            dt3: "",
            sy4: "ܓܢܬ ܦܝܪܐ",
            dt1: "Fruchtgarten",
            lt10: "ganath fire",
            lt11: "",
            sy5: "ܓܰܢܰܬ݂ ܦܺܐܪܶܐ",
            sy8: "",
            dt2: "",
            sy9: "",
            sy6: "",
            sy7: "",
            id: "6110"
        },
        {
            sy6: "ܨܐܬ",
            dt1: "hören",
            sy5: "ܫܡܰܥ",
            sy4: "ܫܡܥ",
            dt2: "zuhören",
            sy9: "ܐܰܨܶܬ݂",
            sy7: "ܨܳܐܶܬ݂",
            lt10: "shmaĉ",
            sy8: "ܐܨܬ",
            id: "2050",
            lt12: "aŝeth",
            dt3: "",
            lt11: "ŝoeth"
        },
        {
            sy4: "ܒܥܐ",
            sy8: "",
            sy6: "",
            lt12: "",
            sy7: "",
            lt11: "",
            dt2: "",
            sy9: "",
            dt3: "",
            lt10: "bĉo",
            dt1: "suchen",
            sy5: "ܒܥܳܐ",
            id: "4305"
        },
        {
            id: "5192",
            sy7: "",
            lt10: "ܝܠܕܐ",
            sy5: "ܝܰܠܕܰܐ",
            dt2: "",
            sy8: "",
            sy4: "ܝܠܕܐ",
            lt12: "",
            lt11: "ܝܠܕܐ",
            dt3: "",
            sy9: "",
            sy6: "",
            dt1: "Yalda"
        },
        {
            sy6: "ܬܠܬܥܣܪܐ",
            sy9: "ܝܓ",
            dt1: "13",
            lt10: "tlothaĉsar",
            lt11: "tlothaĉesre",
            lt12: "juth-gomal",
            sy8: "ܝܓ",
            id: "14",
            sy7: "ܬܠܳܬ݂ܰܥܶܣ̈ܪܶܐ",
            dt3: "",
            sy4: "ܬܠܬܥܣܪ",
            dt2: "Dreizehn",
            sy5: "ܬܠܳܬ݂ܰܥܣܰܪ"
        },
        {
            sy7: "",
            id: "4578",
            sy9: "",
            sy8: "",
            dt3: "",
            lt12: "",
            sy6: "",
            lt10: "dlaf",
            dt2: "",
            sy4: "ܕܠܦ",
            sy5: "ܕܠܰܦ",
            lt11: "",
            dt1: "tropfen"
        },
        {
            dt2: "Er ist am schreiben",
            dt1: "Er schreibt",
            dt3: "",
            lt11: "hi kothbo",
            sy7: "ܗܺܝ ܟܳܬ݂ܒܳܐ",
            sy5: "ܗܽܘ ܟܳܬ݂ܶܒ",
            sy8: "",
            sy9: "",
            sy6: "ܗܝ ܟܬܒܐ",
            id: "1125",
            lt12: "",
            sy4: "ܗܘ ܟܬܒ",
            lt10: "hu kotheb"
        },
        {
            dt3: "",
            id: "3054",
            sy8: "",
            sy4: "ܫܚܛ",
            dt2: "",
            lt12: "",
            lt11: "",
            sy5: "ܫܰܚܶܛ",
            sy7: "",
            dt1: "missbrauchen",
            lt10: "shaĥeț",
            sy6: "",
            sy9: ""
        },
        {
            sy9: "",
            lt12: "",
            sy5: "ܒܺܝܠܶܢ",
            dt1: "Bilen",
            lt10: "ܒܝܠܢ",
            sy6: "",
            sy8: "",
            dt2: "",
            lt11: "ܒܝܠܢ",
            id: "622",
            sy7: "",
            sy4: "ܒܝܠܢ",
            dt3: ""
        },
        {
            sy8: "",
            dt2: "",
            dt1: "sandig",
            sy5: "ܚܶܠܳܝܳܐ",
            dt3: "",
            sy7: "",
            id: "3834",
            sy9: "",
            sy4: "ܚܠܝܐ",
            lt10: "ĥeloyo",
            lt12: "",
            sy6: "",
            lt11: ""
        },
        {
            dt2: "",
            sy9: "",
            dt3: "",
            lt10: "fahar",
            sy5: "ܦܰܗܰܪ",
            sy7: "",
            dt1: "gähnen",
            sy8: "",
            sy6: "",
            lt11: "",
            lt12: "",
            id: "1461",
            sy4: "ܦܗܪ"
        },
        {
            sy4: "ܥܦܪܐ",
            sy8: "",
            dt3: "",
            lt11: "ܥܦܪܐ",
            lt10: "ܥܦܪܐ",
            dt1: "Afro",
            dt2: "",
            sy9: "",
            sy5: "ܥܰܦܪܳܐ",
            sy7: "",
            sy6: "",
            id: "142",
            lt12: ""
        },
        {
            sy4: "ܐܒܪܩ",
            dt2: "",
            sy5: "ܐܰܒܪܶܩ",
            dt3: "",
            lt12: "azleğ",
            sy7: "ܨܡܰܚ",
            lt10: "abreq",
            sy8: "ܐܙܠܓ",
            sy6: "ܨܡܚ",
            dt1: "anscheinen",
            sy9: "ܐܰܙܠܶܓ݂",
            id: "5989",
            lt11: "ŝmaĥ"
        },
        {
            dt2: "",
            sy7: "ܒܰܪ̱ܬ݂ ܚܳܬ݂ܳܐ",
            sy9: "",
            sy6: "ܒܪܬ ܚܬܐ",
            dt1: "Nichte",
            lt10: "bath aĥo",
            sy5: "ܒܰܪ̱ܬ݂ ܐܰܚܳܐ",
            sy8: "",
            sy4: "ܒܪܬ ܐܚܐ",
            lt12: "",
            lt11: "bath ĥotho",
            id: "3265",
            dt3: ""
        },
        {
            sy5: "ܗܰܕܳܕܳܐ",
            sy8: "",
            sy7: "",
            id: "1810",
            dt3: "",
            lt12: "",
            sy9: "",
            lt10: "ܗܕܕܐ",
            sy4: "ܗܕܕܐ",
            dt1: "Hadodo",
            lt11: "ܗܕܕܐ",
            sy6: "",
            dt2: ""
        },
        {
            sy4: "ܒܠܝ",
            lt11: "ܒܠܝ",
            sy7: "",
            id: "427",
            sy6: "",
            dt3: "",
            dt1: "Balai",
            lt12: "",
            sy5: "ܒܰܠܰܝ",
            lt10: "ܒܠܝ",
            dt2: "",
            sy8: "",
            sy9: ""
        },
        {
            dt1: "Schakal",
            sy8: "",
            dt3: "",
            sy4: "ܝܪܘܪܐ",
            id: "3879",
            dt2: "",
            sy5: "ܝܰܪܽܘܪܳܐ",
            lt10: "yaruro",
            lt12: "",
            sy7: "",
            sy6: "",
            sy9: "",
            lt11: ""
        },
        {
            dt2: "Vollender",
            sy4: "ܓܡܘܪܐ",
            lt11: "mshamlyono",
            sy9: "",
            id: "4866",
            sy5: "ܓܳܡܽܘܪܳܐ",
            sy7: "ܡܫܰܡܠܝܳܢܳܐ",
            lt10: "gomuro",
            dt1: "Vollbringer",
            lt12: "",
            dt3: "",
            sy6: "ܡܫܡܠܝܢܐ",
            sy8: ""
        },
        {
            lt12: "",
            dt3: "",
            sy5: "ܐܰܪܕܺܝܕܳܐ",
            sy6: "",
            dt2: "",
            dt1: "Brautschleier",
            lt10: "ardido",
            lt11: "",
            id: "697",
            sy9: "",
            sy8: "",
            sy4: "ܐܪܕܝܕܐ",
            sy7: ""
        },
        {
            sy6: "",
            lt12: "",
            dt2: "",
            dt1: "Besatzer",
            dt3: "",
            sy4: "ܟܒܘܫܐ",
            lt10: "kobusho",
            sy7: "",
            sy8: "",
            sy5: "ܟܳܒܽܘܫܳܐ",
            id: "563",
            lt11: "",
            sy9: ""
        },
        {
            sy4: "ܓܘܡܕܐ",
            sy9: "",
            lt12: "",
            sy5: "ܓܽܘܡܕܳܐ",
            lt11: "gumdonutho",
            sy8: "",
            lt10: "gumdo",
            dt2: "",
            dt3: "",
            id: "4680",
            dt1: "Unverschämtheit",
            sy7: "ܓܽܘܡܕܳܢܽܘܬ݂ܳܐ",
            sy6: "ܓܘܡܕܢܘܬܐ"
        },
        {
            sy6: "",
            sy9: "",
            id: "983",
            sy7: "",
            lt10: "ŝho",
            sy5: "ܨܗܳܐ",
            dt1: "dürsten",
            sy4: "ܨܗܐ",
            sy8: "",
            lt12: "",
            lt11: "",
            dt3: "",
            dt2: ""
        },
        {
            id: "5227",
            dt1: "zahlen lassen",
            sy5: "ܐܰܦܪܰܥ",
            sy7: "",
            sy8: "",
            dt2: "",
            lt12: "",
            dt3: "",
            lt10: "afraĉ",
            sy6: "",
            sy4: "ܐܦܪܥ",
            lt11: "",
            sy9: ""
        },
        {
            sy9: "",
            lt12: "",
            dt3: "",
            lt11: "",
            sy6: "",
            sy7: "",
            lt10: "lĥud hu",
            sy4: "ܠܚܘܕ ܗܘ",
            id: "3316",
            sy8: "",
            sy5: "ܠܚܽܘܕ ܗܽܘ",
            dt2: "",
            dt1: "nur er"
        },
        {
            sy6: "",
            lt10: "dayonto",
            lt12: "",
            lt11: "",
            sy9: "",
            sy8: "",
            sy4: "ܕܝܢܬܐ",
            id: "3716",
            dt2: "",
            sy7: "",
            dt3: "",
            dt1: "Richterin",
            sy5: "ܕܰܝܳܢܬܳܐ"
        },
        {
            lt11: "ܡܠܟܐ",
            sy8: "",
            lt12: "",
            dt2: "",
            sy6: "",
            sy9: "",
            lt10: "ܡܠܟܐ",
            id: "2903",
            dt1: "Malke",
            sy5: "ܡܰܠܟܶܐ",
            sy4: "ܡܠܟܐ",
            sy7: "",
            dt3: ""
        },
        {
            id: "2143",
            dt2: "",
            sy5: "ܡܰܪܟܶܢ ܐ̱ܢܳܐ ܪܺܝܫܳܐ",
            sy8: "",
            sy9: "",
            sy6: "",
            sy7: "",
            lt12: "",
            lt10: "marken no risho",
            dt1: "Ich verbeuge meinen Kopf",
            lt11: "",
            dt3: "",
            sy4: "ܡܪܟܢ ܐܢܐ ܪܝܫܐ"
        },
        {
            dt3: "",
            sy6: "ܟܕܝܢܐ",
            lt10: "kbilo",
            sy4: "ܟܒܝܠܐ",
            dt1: "angebunden",
            sy5: "ܟܒܺܝܠܳܐ",
            sy9: "",
            id: "5923",
            lt12: "",
            dt2: "",
            lt11: "kdino",
            sy8: "",
            sy7: "ܟܕܺܝܢܳܐ"
        },
        {
            sy4: "ܟܘܠܝܬܐ",
            id: "3270",
            lt11: "kulyo",
            lt10: "kulitho",
            sy9: "",
            dt3: "",
            sy8: "",
            sy6: "ܟܘܠܝܐ",
            sy7: "ܟܽܘܠܝܳܐ",
            dt1: "Niere",
            sy5: "ܟܽܘܠܺܝܬ݂ܳܐ",
            lt12: "",
            dt2: ""
        },
        {
            sy8: "",
            sy6: "",
            sy4: "ܡܟܣܝܟܝܐ",
            sy7: "",
            sy9: "",
            lt12: "",
            dt1: "Mexikaner",
            lt10: "maksikoyo",
            dt3: "",
            dt2: "",
            id: "3012",
            lt11: "",
            sy5: "ܡܰܟܣܺܝܟܳܝܳܐ"
        },
        {
            sy9: "",
            dt2: "",
            dt3: "",
            sy5: "ܣܰܓܺܝ ܚܰܠܝܳܐ",
            lt10: "sagi ĥalyo",
            sy8: "",
            sy4: "ܣܓܝ ܚܠܝܐ",
            lt11: "sagi ĥlitho",
            sy7: "ܣܰܓܺܝ ܚܠܺܝܬ݂ܳܐ",
            lt12: "",
            dt1: "sehr süß",
            id: "4098",
            sy6: "ܣܓܝ ܚܠܝܬܐ"
        },
        {
            dt2: "",
            dt3: "",
            dt1: "Maria Himmelfahrt",
            id: "2934",
            sy4: "ܫܘܢܝܗ ܕܝܠܕܬ ܐܠܗܐ ܡܪܝܡ",
            lt10: "shunoyo d`yoldath aloho maryam",
            lt11: "",
            sy9: "",
            lt12: "",
            sy7: "",
            sy5: "ܫܽܘܢܳܝܳܗ ܕܝܳܠܕܰܬ݂ ܐܰܠܳܗܳܐ ܡܰܪܝܰܡ",
            sy8: "",
            sy6: ""
        },
        {
            dt2: "",
            lt12: "",
            lt11: "ܫܟ݂ܝܢܬܐ",
            id: "3956",
            sy6: "",
            dt3: "",
            sy7: "",
            sy9: "",
            lt10: "ܫܟ݂ܝܢܬܐ",
            sy5: "ܫܟ݂ܺܝܢܬܰܐ",
            sy4: "ܫܟ݂ܝܢܬܐ",
            sy8: "",
            dt1: "Schkinta"
        },
        {
            lt11: "kutmo",
            sy5: "ܫܽܘܡܬ݂ܳܐ",
            dt2: "",
            lt12: "shĥoțo",
            dt3: "",
            lt10: "shumtho",
            sy6: "ܟܘܬܡܐ",
            dt1: "Wunde",
            sy8: "ܫܚܛܐ",
            sy9: "ܫܚܳܛܳܐ",
            id: "5608",
            sy4: "ܫܘܡܬܐ",
            sy7: "ܟܽܘܬܡܳܐ"
        },
        {
            sy9: "",
            dt1: "islamischer Jurist",
            dt3: "",
            dt2: "",
            lt10: "faqiho",
            sy4: "ܦܩܝܗܐ",
            sy5: "ܦܰܩܺܝܗܳܐ",
            sy7: "",
            id: "2249",
            sy8: "",
            lt12: "",
            sy6: "",
            lt11: ""
        },
        {
            lt12: "",
            sy5: "ܗܰܘܢܳܐ",
            sy7: "ܚܶܟܡܳܐ",
            sy6: "ܚܟܡܐ",
            dt2: "",
            lt11: "ĥekmo",
            sy4: "ܗܘܢܐ",
            lt10: "hauno",
            sy8: "",
            dt1: "Vernunft",
            dt3: "",
            id: "4770",
            sy9: ""
        },
        {
            sy4: "ܥܪܣܐ",
            dt2: "",
            sy5: "ܥܰܪܣܳܐ",
            sy7: "",
            lt10: "ĉarso",
            id: "591",
            dt3: "",
            lt12: "",
            lt11: "",
            sy6: "",
            dt1: "Bett",
            sy8: "",
            sy9: ""
        },
        {
            dt2: "",
            lt11: "",
            sy5: "ܒܶܢܝܳܢ ܓܽܘܫܡܳܐ",
            sy7: "",
            sy6: "",
            sy9: "",
            id: "2614",
            lt10: "benyon gushmo",
            lt12: "",
            sy8: "",
            sy4: "ܒܢܝܢ ܓܘܫܡܐ",
            dt3: "",
            dt1: "Körperbau"
        },
        {
            sy4: "ܡܫܠܝܘܢܐ",
            sy7: "",
            lt11: "",
            sy6: "",
            id: "1450",
            lt10: "mashlyuno",
            sy8: "",
            lt12: "",
            sy9: "",
            sy5: "ܡܰܫܠܝܽܘܢܳܐ",
            dt2: "",
            dt3: "",
            dt1: "Gabel"
        },
        {
            sy8: "",
            sy7: "",
            sy4: "ܒܪ̱ܬܚܘܒܐ",
            sy6: "",
            id: "498",
            dt2: "",
            dt1: "Bathhoubo",
            dt3: "",
            sy9: "",
            lt12: "",
            sy5: "ܒܰܪ̱ܬܚܽܘܒܰܐ",
            lt10: "ܒܪܬܚܘܒܐ",
            lt11: "ܒܪܬܚܘܒܐ"
        },
        {
            dt1: "Adonai",
            sy6: "",
            sy7: "",
            id: "133",
            lt10: "ܐܕܘܢܝ",
            dt2: "",
            sy5: "ܐܰܕܳܘܢܰܝ",
            sy4: "ܐܕܘܢܝ",
            lt12: "",
            sy8: "",
            lt11: "ܐܕܘܢܝ",
            sy9: "",
            dt3: ""
        },
        {
            lt12: "",
            id: "4189",
            lt10: "shuroy qayțo",
            sy5: "ܫܽܘܪܳܝ ܩܰܝܛܳܐ",
            sy6: "",
            dt2: "",
            lt11: "",
            sy9: "",
            dt3: "",
            sy4: "ܫܘܪܝ ܩܝܬܐ",
            sy8: "",
            sy7: "",
            dt1: "Sommeranfang"
        },
        {
            sy7: "",
            dt3: "",
            id: "3907",
            lt10: "țelolo",
            dt2: "",
            sy6: "",
            sy5: "ܛܶܠܳܠܳܐ",
            sy9: "",
            dt1: "Schatten",
            lt12: "",
            lt11: "",
            sy4: "ܛܠܠܐ",
            sy8: ""
        },
        {
            sy4: "ܡܚܛܝܢܘܬܐ",
            sy5: "ܡܚܰܛܝܳܢܽܘܬ݂ܳܐ",
            sy8: "",
            id: "4316",
            lt10: "mĥațyonutho",
            sy6: "",
            dt1: "Sündhaftigkeit",
            sy9: "",
            lt11: "",
            dt3: "",
            dt2: "",
            sy7: "",
            lt12: ""
        },
        {
            sy6: "",
            sy8: "",
            lt10: "ܪܚܡܐ",
            sy7: "",
            dt2: "",
            dt1: "Rohmo",
            dt3: "",
            sy5: "ܪܳܚܡܳܐ",
            lt12: "",
            id: "3738",
            lt11: "ܪܚܡܐ",
            sy9: "",
            sy4: "ܪܚܡܐ"
        },
        {
            dt1: "Dorf Urnus",
            dt3: "Arnas",
            sy7: "ܥܽܘܪܕܢܳܣ",
            sy6: "ܥܘܪܕܢܣ",
            sy5: "ܩܪܺܝܬ݂ܳܐ ܥܽܘܽܪܕܢܳܣ",
            sy4: "ܩܪܝܬܐ ܥܘܪܕܢܣ",
            id: "944",
            lt12: "",
            lt10: "qritho ĉurnus",
            sy9: "",
            dt2: "Dorf Arnas",
            sy8: "",
            lt11: "ĉurnus"
        },
        {
            sy7: "",
            sy6: "",
            sy8: "",
            dt2: "",
            id: "5412",
            dt1: "zwitschern",
            sy4: "ܨܘܨܝ",
            sy9: "",
            dt3: "",
            lt11: "",
            lt10: "ŝauŝi",
            lt12: "",
            sy5: "ܨܰܘܨܺܝ"
        },
        {
            lt10: "shakhronoyo",
            lt11: "",
            sy6: "",
            id: "5829",
            dt2: "",
            sy7: "",
            dt3: "",
            sy4: "ܫܟܪܢܝܐ",
            sy5: "ܫܰܟ݂ܪܳܢܳܝܳܐ",
            sy9: "",
            dt1: "alkoholisch",
            lt12: "",
            sy8: ""
        },
        {
            id: "579",
            lt10: "soĉurto",
            dt3: "",
            lt11: "",
            sy8: "",
            sy4: "ܣܥܘܪܬܐ",
            sy7: "",
            dt2: "",
            sy6: "",
            lt12: "",
            sy9: "",
            sy5: "ܣܳܥܽܘܪܬܳܐ",
            dt1: "Besucherin"
        },
        {
            sy5: "ܐܳܪܬܳܕܽܘܟܣܳܝܳܐ",
            dt2: "",
            sy4: "ܐܪܬܕܘܟܣܝܐ",
            sy9: "",
            sy7: "",
            dt1: "orthodox",
            lt12: "",
            dt3: "",
            id: "3394",
            lt10: "ortoduksoyo",
            sy8: "",
            lt11: "",
            sy6: ""
        },
        {
            sy7: "",
            dt1: "Volljähriger",
            sy4: "ܓܒܪܐ ܫܠܡܐ",
            lt11: "",
            sy9: "",
            id: "4872",
            sy6: "",
            dt2: "",
            sy8: "",
            lt12: "",
            lt10: "gabro shalmo",
            sy5: "ܓܰܒܪܳܐ ܫܰܠܡܳܐ",
            dt3: ""
        },
        {
            id: "1815",
            dt2: "",
            sy5: "ܦܰܠܳܚܳܐ ܕܰܠܡܺܐܢܳܐ",
            dt1: "Hafenarbeiter",
            sy9: "",
            dt3: "",
            sy7: "",
            sy4: "ܦܠܚܐ ܕܠܡܐܢܐ",
            sy8: "",
            sy6: "",
            lt10: "faloĥo da`lmino",
            lt11: "",
            lt12: ""
        },
        {
            lt12: "",
            dt3: "",
            dt1: "Kirche",
            sy5: "ܥܺܕܬܳܐ",
            lt10: "ĉito",
            id: "2488",
            lt11: "",
            sy6: "",
            sy9: "",
            sy4: "ܥܕܬܐ",
            dt2: "",
            sy8: "",
            sy7: ""
        },
        {
            sy8: "",
            sy4: "ܡܟܠܝܢܘܬܐ",
            sy7: "",
            sy6: "",
            lt12: "",
            dt1: "Boykott",
            sy5: "ܡܰܟܠܝܳܢܽܘܬ݂ܳܐ",
            sy9: "",
            lt10: "maklyonutho",
            dt2: "",
            id: "687",
            lt11: "",
            dt3: ""
        },
        {
            dt3: "Erlass",
            lt10: "fuqođo",
            sy6: "",
            dt1: "Bestellung",
            sy4: "ܦܘܩܕܐ",
            sy5: "ܦܽܘܩܳܕܳܐ",
            sy7: "",
            sy8: "",
            dt2: "Auftrag",
            id: "577",
            lt11: "",
            lt12: "",
            sy9: ""
        },
        {
            id: "H0mKIU95CiHkP80m3ccj",
            sy7: "",
            dt3: "",
            sy5: "ܝܰܪܺܝܟ݂ܳܐ ܝܳܐ",
            dt1: "Er ist lang",
            lt11: "",
            sy8: "",
            "date": {
                "seconds": 1590849281,
                "nanoseconds": 148000000
            },
            sy6: "",
            lt10: "Yarikho yo",
            sy9: "",
            dt2: "Er ist zu lang",
            lt12: "",
            sy4: "ܝܪܝܟܐ ܝܐ"
        },
        {
            sy6: "ܕܠܐ ܛܝܡܐ",
            sy4: "ܫܘܝܐ",
            sy9: "ܐܰܪܙܰܢ",
            id: "624",
            lt11: "dlo țimo",
            dt1: "billig",
            lt12: "arzan",
            dt3: "preiswert",
            sy5: "ܫܰܘܝܳܐ",
            sy8: "ܐܪܙܢ",
            lt10: "shawyo",
            sy7: "ܕܠܳܐ ܛܺܝܡܳܐ",
            dt2: "günstig"
        },
        {
            sy8: "",
            sy5: "ܥܰܩܶܒ",
            sy9: "",
            id: "3159",
            sy7: "",
            lt12: "",
            sy4: "ܥܩܒ",
            lt10: "ĉaqeb",
            dt2: "",
            sy6: "",
            lt11: "",
            dt1: "nachforschen",
            dt3: ""
        },
        {
            dt3: "",
            lt12: "men hosho",
            sy9: "ܡܶܢ ܗܳܫܳܐ",
            sy6: "ܒܝܢܝ",
            sy8: "ܡܢ ܗܫܐ",
            dt2: "währenddessen",
            lt11: "baynay",
            dt1: "inzwischen",
            sy7: "ܒܰܝܢܰܝ",
            sy4: "ܒܗܢ ܡܬܚܐ",
            lt10: "bhon methĥo",
            id: "6168",
            sy5: "ܒܗܳܢ ܡܶܬ݂ܚܳܐ"
        },
        {
            sy5: "ܐܶܫܰܕ ܕܡܳܐ",
            lt12: "",
            sy9: "",
            sy6: "",
            sy8: "",
            dt1: "Blut vergießen",
            sy4: "ܐܫܕ ܕܡܐ",
            id: "660",
            dt3: "",
            lt10: "eshađ dmo",
            sy7: "",
            lt11: "",
            dt2: ""
        },
        {
            dt3: "",
            sy4: "ܚܢܢܐ",
            sy6: "ܡܪܚܡܢܐ",
            lt10: "ĥanono",
            dt1: "Barmherziger",
            sy9: "",
            sy5: "ܚܰܢܳܢܳܐ",
            lt12: "",
            sy8: "",
            sy7: "ܡܪܰܚܡܳܢܳܐ",
            id: "460",
            dt2: "",
            lt11: "mraĥmono"
        },
        {
            dt2: "",
            dt1: "Yahbil",
            id: "5188",
            dt3: "",
            lt12: "",
            sy6: "",
            sy9: "",
            lt10: "ܝܗܒܐܝܠ",
            lt11: "ܝܗܒܐܝܠ",
            sy4: "ܝܗܒܐܝܠ",
            sy8: "",
            sy5: "ܝܰܗܒܐܺܝܠ",
            sy7: ""
        },
        {
            lt10: "buyoyo",
            dt1: "Trost",
            sy6: "",
            id: "4580",
            sy7: "",
            sy9: "",
            lt12: "",
            dt2: "",
            sy8: "",
            sy4: "ܒܘܝܐܐ",
            sy5: "ܒܽܘܝܳܐܳܐ",
            dt3: "",
            lt11: ""
        },
        {
            lt10: "ĉiđo d`yoldath aloho ĉal sheble",
            sy9: "",
            sy4: "ܥܐܕܐ ܕܝܠܕܬ ܐܠܗܐ ܥܠ ܫܒܠܐ",
            lt11: "",
            sy6: "",
            dt1: "Fest Marias von den Ähren",
            lt12: "",
            sy7: "",
            id: "1297",
            dt2: "",
            sy8: "",
            dt3: "",
            sy5: "ܥܺܐܕܳܐ ܕܝܳܠܕܰܬ݂ ܐܰܠܳܗܳܐ ܥܰܠ ܫܶܒ̈ܠܶܐ"
        },
        {
            sy9: "",
            sy8: "",
            lt12: "",
            lt11: "",
            sy4: "ܛܟܣܐ ܕܟܘܟܒܐ",
            sy5: "ܛܰܟܣܳܐ ܕܟܰܘܟܒܳܐ",
            dt2: "",
            sy6: "",
            dt3: "",
            lt10: "țakso d`kaukbe",
            sy7: "",
            dt1: "Planetensystem",
            id: "3472"
        },
        {
            lt12: "",
            id: "1584",
            dt1: "Geschlechtskrankheit",
            sy4: "ܟܘܪܗܢܐ ܓܢܣܝܐ",
            sy7: "",
            sy8: "",
            lt10: "kurhono gensoyo",
            lt11: "",
            sy5: "ܟܽܘܪܗܳܢܳܐ ܓܶܢܣܳܝܳܐ",
            dt2: "",
            sy6: "",
            sy9: "",
            dt3: ""
        },
        {
            dt2: "",
            sy6: "",
            lt12: "",
            sy5: "ܪܶܦܣܳܐ ܒܛܺܝܙܳܐ",
            dt3: "",
            lt11: "",
            sy8: "",
            dt1: "Arsch treten",
            sy9: "",
            lt10: "refso bțizo",
            sy4: "ܪܦܣܐ ܒܛܝܙܐ",
            sy7: "",
            id: "303"
        },
        {
            dt2: "",
            sy7: "",
            sy8: "",
            sy6: "",
            lt11: "",
            dt3: "",
            lt12: "",
            lt10: "lgez",
            dt1: "puzzeln",
            sy9: "",
            sy5: "ܠܓܶܙ",
            id: "3554",
            sy4: "ܠܓܙ"
        },
        {
            dt2: "",
            lt11: "",
            id: "5355",
            sy6: "",
            lt12: "",
            sy7: "",
            dt1: "Zucchini",
            dt3: "",
            sy9: "",
            sy5: "ܩܰܐܪܳܐܐ",
            sy4: "ܩܐܪܐܐ",
            sy8: "",
            lt10: "qar`o"
        },
        {
            lt12: "",
            sy4: "ܫܪܥܐ",
            sy9: "",
            dt3: "",
            lt10: "shroĉo",
            id: "3774",
            lt11: "shoruĉutho",
            sy5: "ܫܪܳܥܳܐ",
            sy8: "",
            dt1: "Rutsch",
            dt2: "",
            sy6: "ܫܪܘܥܘܬܐ",
            sy7: "ܫܳܪܽܘܥܽܘܬ݂ܳܐ"
        },
        {
            sy5: "ܣܰܬܳܐ",
            sy7: "ܣܰܬܳܐ ܕܥܶܢ̈ܒܶܐ",
            sy9: "",
            lt11: "sato d`ĉenbe",
            id: "5033",
            lt12: "",
            sy6: "ܣܬܐ ܕܥܢܒܐ",
            sy8: "",
            sy4: "ܣܬܐ",
            dt1: "Weinstock",
            dt2: "",
            lt10: "sato",
            dt3: ""
        },
        {
            sy8: "",
            dt3: "",
            dt2: "Comeback",
            lt12: "",
            sy4: "ܩܡ ܡܢܕܪܝܫ",
            sy6: "",
            id: "5097",
            lt10: "qom mendrish",
            dt1: "wiederaufleben",
            sy7: "",
            lt11: "",
            sy5: "ܩܳܡ ܡܶܢܕܪܺܝܫ",
            sy9: ""
        },
        {
            sy9: "",
            dt2: "Hebamme",
            id: "2633",
            lt10: "ĥaytho",
            lt11: "ositho",
            sy5: "ܚܰܝܬ݂ܳܐ",
            sy6: "ܐܣܝܬܐ",
            dt1: "Krankenschwester",
            lt12: "",
            sy8: "",
            sy4: "ܚܝܬܐ",
            sy7: "ܐܳܣܺܝܬ݂ܳܐ",
            dt3: ""
        },
        {
            lt12: "",
            sy6: "",
            sy7: "",
            lt11: "",
            dt3: "",
            sy4: "ܥܠ ܚܘܠܡܢܟ",
            dt2: "",
            sy5: "ܥܰܠ ܚܽܘܠܡܳܢܳܟ݂",
            sy8: "",
            id: "345",
            dt1: "Auf Deine Gesundheit",
            sy9: "",
            lt10: "ĉal ĥulmonokh"
        },
        {
            dt3: "",
            sy9: "",
            lt12: "",
            id: "1899",
            sy4: "ܡܫܡܫܢܐ ܕܒܝܬܐ",
            lt10: "mshamshono d`bayto",
            sy5: "ܡܫܰܡܫܳܢܳܐ ܕܒܰܝܬܳܐ",
            dt2: "",
            sy7: "",
            dt1: "Hausdiener",
            lt11: "",
            sy6: "",
            sy8: ""
        },
        {
            sy4: "ܚܘܛܐ",
            id: "4003",
            lt12: "",
            dt2: "",
            dt1: "Schnur",
            sy5: "ܚܽܘܛܳܐ",
            sy6: "",
            sy8: "",
            lt11: "",
            lt10: "ĥuțo",
            sy9: "",
            sy7: "",
            dt3: ""
        },
        {
            id: "2637",
            dt3: "",
            dt2: "",
            sy4: "ܚܐܟ",
            sy9: "",
            sy7: "ܚܳܝܟܳܐ",
            sy5: "ܚܳܐܶܟ",
            lt10: "ĥoek",
            lt12: "",
            sy8: "",
            sy6: "ܚܝܟܐ",
            lt11: "ĥoyko",
            dt1: "kratzen"
        },
        {
            sy9: "",
            sy7: "",
            sy5: "ܫܰܪܰܪ",
            lt10: "shashar",
            sy4: "ܫܪܪ",
            lt11: "",
            dt1: "realisieren",
            dt3: "",
            sy8: "",
            lt12: "",
            sy6: "",
            dt2: "",
            id: "3629"
        },
        {
            lt10: "shalem",
            sy6: "",
            lt12: "",
            dt2: "",
            dt1: "grüßen",
            sy9: "",
            lt11: "",
            sy7: "",
            id: "1758",
            dt3: "",
            sy4: "ܫܠܡ",
            sy8: "",
            sy5: "ܫܰܠܶܡ"
        },
        {
            lt10: "boĉuyo",
            id: "6241",
            dt3: "",
            dt1: "Suchender",
            sy7: "ܒܰܥܳܝܳܐ",
            sy8: "",
            sy4: "ܒܥܘܝܐ",
            lt12: "",
            sy6: "ܒܥܝܐ",
            dt2: "",
            lt11: "baĉoyo",
            sy9: "",
            sy5: "ܒܳܥܽܘܝܳܐ"
        },
        {
            lt10: "shnaye",
            sy7: "ܐܺܫ̈ܢܶܐ",
            sy6: "ܐܫܢܐ",
            dt1: "Jahre",
            id: "2273",
            sy4: "ܫܢܝܐ",
            sy8: "",
            sy9: "",
            lt12: "",
            dt2: "",
            dt3: "",
            lt11: "ishne",
            sy5: "ܫܢܰܝ̈ܶܐ"
        },
        {
            sy8: "",
            sy7: "",
            lt11: "",
            dt3: "",
            sy5: "ܓܳܙܽܘܪܳܐ",
            dt1: "Beschneider",
            dt2: "",
            lt10: "gozuro",
            lt12: "",
            sy9: "",
            id: "569",
            sy4: "ܓܙܘܪܐ",
            sy6: ""
        },
        {
            sy8: "",
            sy5: "ܠܶܒܰܬ",
            sy4: "ܠܒܬ",
            lt12: "ܠܒܐܬ",
            dt2: "",
            sy9: "",
            lt11: "ܠܒܬ",
            dt3: "",
            sy6: "ܠܒܐܬ",
            dt1: "Lebat",
            id: "2726",
            sy7: "ܠܶܒܐܰܬ",
            lt10: "ܠܒܬ"
        },
        {
            lt11: "",
            dt2: "Schall",
            sy5: "ܡܪܺܝܒܳܢܽܘܬ݂ܳܐ",
            lt12: "",
            sy4: "ܡܪܝܒܢܘܬܐ",
            sy9: "",
            sy6: "",
            dt3: "Widerhall",
            sy7: "",
            sy8: "",
            id: "992",
            lt10: "mribonutho",
            dt1: "Echo"
        },
        {
            sy5: "ܬܡܳܢܺܝܢ",
            sy9: "",
            sy6: "ܦ",
            dt1: "80",
            sy8: "",
            lt12: "",
            dt3: "",
            lt11: "fe",
            sy7: "ܦ",
            dt2: "Achtzig",
            id: "36",
            lt10: "tmonin",
            sy4: "ܬܡܢܝܢ"
        },
        {
            sy9: "",
            dt3: "",
            lt12: "",
            lt10: "duyoro",
            sy6: "ܫܘܥܡܪܐ",
            dt1: "Ansiedlung",
            sy4: "ܕܘܝܪܐ",
            dt2: "",
            sy5: "ܕܽܘܝܳܪܳܐ",
            lt11: "shuĉmoro",
            sy8: "",
            id: "5990",
            sy7: "ܫܽܘܥܡܳܪܳܐ"
        },
        {
            dt3: "",
            lt10: "ܢܗܝܪ",
            sy8: "",
            sy9: "",
            lt12: "",
            sy6: "",
            lt11: "ܢܗܝܪ",
            sy4: "ܢܗܝܪ",
            dt2: "Nahiro",
            dt1: "Nahir",
            id: "3183",
            sy7: "",
            sy5: "ܢܰܗܺܝܪ"
        },
        {
            dt1: "Bergbewohner",
            sy6: "",
            sy9: "",
            sy4: "ܛܘܪܝܐ",
            lt10: "țuroyo",
            sy7: "",
            lt11: "",
            sy5: "ܛܽܘܪܳܝܳܐ",
            lt12: "",
            dt3: "Dialekt des Aramäischen",
            dt2: "Bergmann",
            id: "555",
            sy8: ""
        },
        {
            dt2: "",
            sy5: "ܛܝܳܫܳܐ",
            id: "4297",
            dt3: "",
            sy9: "",
            dt1: "Streichen",
            lt11: "",
            sy4: "ܛܝܫܐ",
            sy7: "",
            sy6: "",
            sy8: "",
            lt10: "țyosho",
            lt12: ""
        },
        {
            dt1: "verletzt",
            sy6: "ܡܚܝܐ",
            sy8: "ܡܔܪܚܐ",
            sy9: "ܡܔܰܪܚܳܐ",
            dt3: "",
            dt2: "Verletzter",
            sy7: "ܡܰܚܝܳܐ",
            lt12: "mjarĥo",
            lt11: "maĥyo",
            sy5: "ܐܰܦܺܝܦܳܐ",
            id: "4758",
            sy4: "ܐܦܝܦܐ",
            lt10: "afifo"
        },
        {
            sy5: "ܐܰܝܟܰܢܳܝܽܘܬ݂ܳܐ ",
            dt1: "Zustand ",
            lt12: "",
            sy4: "ܐܝܟܢܝܘܬܐ ",
            id: "5385",
            sy7: "",
            lt11: "",
            lt10: "aykanoyutho",
            dt2: "",
            dt3: "",
            sy9: "",
            sy8: "",
            sy6: ""
        },
        {
            lt12: "",
            dt1: "Glückwünsche",
            id: "1673",
            sy8: "",
            sy4: "ܬܗܢܝܬܐ",
            sy5: "ܬܰܗܢܝ̈ܬ݂ܳܐ",
            sy6: "",
            dt2: "",
            sy7: "",
            lt11: "",
            sy9: "",
            lt10: "tahnyotho",
            dt3: ""
        },
        {
            sy9: "",
            sy4: "ܒܨܐ",
            lt12: "",
            dt2: "Omnibus",
            sy6: "",
            sy5: "ܒܰܨܳܐ",
            dt3: "",
            dt1: "Bus",
            lt11: "",
            sy8: "",
            lt10: "baŝo",
            id: "747",
            sy7: ""
        },
        {
            dt3: "",
            sy5: "ܓܽܘܥܠܳܢܳܐ",
            dt2: "Pfand",
            dt1: "Sicherheit",
            lt11: "",
            id: "4140",
            sy6: "",
            sy7: "",
            sy4: "ܓܘܥܠܢܐ",
            lt12: "",
            lt10: "guĉlono",
            sy9: "",
            sy8: ""
        },
        {
            dt2: "",
            sy4: "ܪܘܫܡܐ ܕܙܒܢܐ",
            lt12: "",
            sy6: "",
            lt10: "rushmo d'zabno",
            sy9: "",
            sy5: "ܪܽܘܫܡܳܐ ܕܙܰܒܢܳܐ",
            sy7: "",
            dt1: "Zeitplan",
            id: "6261",
            lt11: "",
            sy8: "",
            dt3: ""
        },
        {
            sy8: "",
            sy5: "ܟ݂ܰܪܝܺܬܰܐ",
            sy7: "",
            sy4: "ܟܪܝܬܐ",
            dt3: "",
            sy6: "",
            dt1: "Atlas",
            sy9: "",
            lt10: "kharita",
            lt12: "",
            dt2: "Straßenkarte",
            id: "333",
            lt11: ""
        },
        {
            lt10: "shoĉtho ĥroyto",
            dt1: "Letzte Stunde",
            sy4: "ܫܥܬܐ ܐ̱ܚܪܝܬܐ",
            sy5: "ܫܳܥܬ݂ܳܐ ܐ̱ܚܪܳܝܬܳܐ",
            sy9: "",
            dt2: "",
            id: "2779",
            lt12: "",
            sy7: "",
            sy8: "",
            dt3: "",
            lt11: "",
            sy6: ""
        },
        {
            sy7: "",
            id: "308",
            sy4: "ܐܣܦ",
            sy9: "",
            sy6: "",
            sy8: "",
            dt2: "Asaph",
            sy5: "ܐܰܣܰܦ",
            lt12: "",
            dt3: "",
            dt1: "Asaf",
            lt11: "ܐܣܦ",
            lt10: "ܐܣܦ"
        },
        {
            sy9: "",
            sy4: "ܒܪܐܝܠ",
            dt3: "",
            sy7: "",
            lt10: "ܒܪܐܝܠ",
            dt1: "Baril",
            sy8: "",
            id: "454",
            lt12: "",
            lt11: "ܒܪܐܝܠ",
            sy6: "",
            dt2: "Bar-Il",
            sy5: "ܒܰܪܐܺܝܠ"
        },
        {
            sy7: "",
            lt10: "nqal",
            lt12: "",
            sy5: "ܢܩܰܠ",
            sy4: "ܢܩܠ",
            id: "4544",
            dt1: "transportieren",
            sy9: "",
            dt2: "",
            lt11: "",
            sy8: "",
            dt3: "",
            sy6: ""
        },
        {
            sy5: "ܟܬܰܫ",
            sy8: "",
            lt12: "",
            sy6: "ܡܟܬܫ",
            lt10: "ktash",
            dt3: "",
            sy9: "",
            dt1: "kämpfen",
            sy7: "ܡܟܰܬܶܫ",
            sy4: "ܟܬܫ",
            id: "2401",
            lt11: "mkatesh",
            dt2: ""
        },
        {
            sy6: "",
            lt11: "",
            sy7: "",
            lt10: "ĉudyoith",
            sy4: "ܥܘܕܝܐܝܬ",
            id: "5592",
            dt1: "abnormal",
            dt3: "",
            sy8: "",
            sy9: "",
            lt12: "",
            dt2: "",
            sy5: "ܥܽܘܕܝܳܐܺܝܬ݂"
        },
        {
            id: "6217",
            dt1: "seelische Trauer",
            sy4: "ܚܫܐ ܪܘܚܢܝܐ",
            sy9: "",
            sy7: "",
            sy6: "",
            dt3: "",
            dt2: "",
            sy5: "ܚܰܫܳܐ ܪܽܘܚܳܢܳܝܳܐ",
            sy8: "",
            lt10: "ĥasho ruĥonoyo",
            lt11: "",
            lt12: ""
        },
        {
            dt1: "täglich",
            id: "4366",
            sy6: "ܝܘܡܢܐܝܬ",
            dt2: "",
            sy7: "ܝܰܘܡܳܢܳܐܝܺܬ݂",
            lt10: "yaumoyo",
            sy4: "ܝܘܡܝܐ",
            lt11: "yaumonoith",
            sy8: "ܝܘܡ ܒܝܘܡ",
            lt12: "yum b`yum",
            sy9: "ܝܽܘܡ ܒܝܽܘܡ",
            dt3: "",
            sy5: "ܝܰܘܡܳܝܳܐ"
        },
        {
            dt3: "",
            lt11: "",
            sy5: "ܫܽܘܒܩܳܢܳܐ ܠܰܝܬܰܘܗܝ̱ ܡܟܳܢܳܐ",
            sy7: "",
            lt12: "",
            sy9: "",
            sy4: "ܫܘܒܩܢܐ ܠܝܬܘܗܝ ܡܟܢܐ",
            lt10: "shubqono laytau mkono",
            sy8: "",
            id: "353",
            dt1: "Aufgeben ist keine Option",
            sy6: "",
            dt2: ""
        },
        {
            sy4: "ܠܚܘܕܝܘܬܐ",
            dt3: "",
            sy9: "",
            sy8: "",
            id: "1051",
            dt1: "Einsamkeit",
            sy5: "ܠܚܽܘܕܳܝܽܘܬ݂ܳܐ",
            sy7: "ܝܺܚܺܝܕܳܝܽܘܬ݂ܳܐ",
            lt10: "lĥuđoyutho",
            dt2: "",
            lt12: "",
            sy6: "ܝܚܝܕܝܘܬܐ",
            lt11: "iĥiđoyutho"
        },
        {
            lt10: "hono mun ithauw",
            dt2: "",
            id: "4991",
            dt3: "",
            sy5: "ܗܳܢܳܐ ܡܽܘܢ ܐܺܝܬ݂ܰܘܗܝ̱",
            sy7: "",
            sy6: "",
            lt12: "",
            dt1: "Was ist das",
            sy9: "",
            lt11: "",
            sy8: "",
            sy4: "ܗܢܐ ܡܘܢ ܐܝܬܘܗܝ"
        },
        {
            sy5: "ܓܰܘܢܳܐ ܐܽܘܟܳܡܳܐ ܐܰܝܟ ܠܶܠܝܳܐ",
            sy9: "",
            id: "4068",
            sy8: "",
            lt11: "",
            lt12: "",
            dt2: "",
            dt3: "",
            lt10: "gauno ukomo ayk lelyo",
            dt1: "schwarze Farbe wie die Nacht",
            sy4: "ܓܘܢܐ ܐܘܟܡܐ ܐܝܟ ܠܠܝܐ",
            sy7: "",
            sy6: ""
        },
        {
            sy4: "ܟܘܟܬܐ",
            dt1: "Kuchen",
            dt2: "",
            sy5: "ܟܽܘܟܬ݂ܳܐ",
            sy9: "",
            dt3: "",
            sy6: "",
            sy8: "",
            lt12: "",
            lt10: "kuktho",
            id: "2654",
            sy7: "",
            lt11: ""
        },
        {
            sy5: "ܡܶܬܦܺܝܪܳܢܽܘܬ݂ܳܐ",
            sy7: "ܡܶܬ݂ܚܰܡܬ݂ܳܢܽܘܬ݂ܳܐ",
            dt2: "",
            sy4: "ܡܬܦܝܪܢܘܬܐ",
            dt1: "Zorn",
            sy6: "ܡܬܚܡܬܢܘܬܐ",
            dt3: "",
            sy9: "ܡܥܰܝܛܳܢܽܘܬ݂ܳܐ",
            id: "6299",
            sy8: "ܡܥܝܛܢܘܬܐ",
            lt12: "mĉayțonutho",
            lt11: "methĥamthonutho",
            lt10: "metfironutho"
        },
        {
            sy6: "",
            sy8: "",
            sy9: "",
            sy7: "",
            dt2: "",
            dt3: "",
            sy5: "ܫܥܺܝܪܰܝܶܐ",
            lt11: "",
            id: "3305",
            lt12: "",
            dt1: "Nudel",
            lt10: "shĉiraye",
            sy4: "ܫܥܝܪܝܐ"
        },
        {
            lt11: "",
            sy5: "ܬܺܐܢ̈ܶܐ",
            lt12: "",
            lt10: "tine",
            sy9: "",
            dt1: "Feigen",
            sy6: "",
            sy7: "",
            id: "1278",
            dt2: "",
            sy8: "",
            dt3: "",
            sy4: "ܬܐܢܐ"
        },
        {
            dt2: "",
            sy5: "ܠܰܝܬ ܘܰܪܕܳܐ ܕܠܳܐ ܟܽܘ̈ܒܶܐ",
            dt1: "Keine rose ohne Dornen",
            lt11: "",
            lt12: "",
            sy4: "ܠܝܬ ܘܪܕܐ ܕܠܐ ܟܘܒܐ",
            sy7: "",
            sy6: "",
            id: "2447",
            sy8: "",
            sy9: "",
            dt3: "",
            lt10: "layt wardo dlo kube"
        },
        {
            sy6: "",
            lt10: "furus",
            sy9: "",
            id: "3493",
            dt1: "Pore",
            dt3: "",
            sy5: "ܦܽܘܪܽܘܣ",
            sy4: "ܦܘܪܘܣ",
            sy7: "",
            sy8: "",
            lt12: "",
            dt2: "kleine Öffnung",
            lt11: ""
        },
        {
            id: "4742",
            sy7: "",
            lt12: "",
            lt11: "",
            sy8: "",
            sy9: "",
            sy5: "ܦܰܚܶܡ",
            dt1: "vergleichen",
            sy6: "",
            lt10: "faĥem",
            dt2: "",
            sy4: "ܦܚܡ",
            dt3: ""
        },
        {
            lt10: "țrađ",
            sy9: "",
            sy4: "ܛܪܕ",
            lt11: "",
            sy6: "",
            id: "4820",
            dt2: "verjagen",
            lt12: "",
            dt3: "",
            dt1: "vertreiben",
            sy8: "",
            sy5: "ܛܪܰܕ",
            sy7: ""
        },
        {
            sy6: "ܫܡ",
            sy5: "ܫܺܝܡ",
            dt1: "Schim",
            sy8: "",
            lt10: "ܫܝܡ",
            dt2: "Sem",
            dt3: "",
            lt12: "ܫܡ",
            sy4: "ܫܝܡ",
            lt11: "ܫܝܡ",
            sy7: "ܫܶܡ",
            sy9: "",
            id: "3951"
        },
        {
            lt11: "ܕܢܚܐ",
            lt10: "ܕܢܚܐ",
            id: "798",
            lt12: "",
            dt1: "Danho",
            sy7: "",
            sy8: "",
            sy9: "",
            dt3: "",
            sy4: "ܕܢܚܐ",
            dt2: "",
            sy6: "",
            sy5: "ܕܰܢܚܳܐ"
        },
        {
            dt3: "",
            lt11: "",
            sy9: "",
            id: "6004",
            lt12: "",
            dt1: "Anzettelung",
            sy6: "",
            sy7: "",
            sy4: "ܡܓܪܝܢܘܬܐ",
            lt10: "mgaryonutho",
            dt2: "",
            sy8: "",
            sy5: "ܡܓܰܪܝܳܢܽܘܬ݂ܳܐ"
        },
        {
            dt1: "Eisläufer",
            sy8: "",
            dt2: "",
            lt11: "",
            sy4: "ܫܪܘܥܐ",
            sy7: "",
            lt12: "",
            sy9: "",
            id: "1065",
            sy6: "",
            dt3: "",
            sy5: "ܫܳܪܽܘܥܳܐ",
            lt10: "shoruĉo"
        },
        {
            id: "3204",
            sy8: "",
            sy4: "ܢܪܣܝ",
            dt1: "Narsai",
            dt3: "",
            dt2: "",
            lt12: "",
            sy6: "",
            sy7: "",
            sy5: "ܢܰܪܣܰܝ",
            sy9: "",
            lt10: "ܢܪܣܝ",
            lt11: "ܢܪܣܝ"
        },
        {
            sy8: "",
            id: "5270",
            lt12: "",
            lt11: "",
            sy9: "",
            lt10: "arzo",
            sy4: "ܐܪܙܐ",
            sy5: "ܐܰܪܙܳܐ",
            sy6: "",
            sy7: "",
            dt2: "Libanonzeder",
            dt1: "Zeder",
            dt3: "Nadelbaum"
        },
        {
            sy7: "ܪܽܘܡܳܢܳܐ",
            sy4: "ܪܝܡܘܢܐ",
            dt1: "Granatapfel",
            lt12: "",
            sy6: "ܪܘܡܢܐ",
            lt10: "raymuno",
            sy9: "",
            id: "1722",
            sy5: "ܪܰܝܡܽܘܢܳܐ",
            dt3: "",
            dt2: "",
            sy8: "",
            lt11: "rumono"
        },
        {
            sy4: "ܬܝܓܪܝܣ",
            sy8: "",
            sy9: "",
            lt10: "ܬܝܓܪܝܣ",
            sy7: "ܕܶܩܠܰܬ݂",
            id: "4487",
            lt11: "deqlath",
            sy5: "ܬܺܝܓܪܺܝܣ",
            sy6: "ܕܩܠܬ",
            dt1: "Tigris",
            lt12: "",
            dt2: "",
            dt3: ""
        },
        {
            lt11: "mțabbonutho",
            sy8: "ܡܙܥܩܢܘܬܐ",
            id: "5957",
            sy7: "ܡܛܰܒܒܳܢܽܘܬ݂ܳܐ",
            sy5: "ܡܰܘܕܥܳܢܽܘܬ݂ܳܐ",
            lt10: "mauđĉonutho",
            sy4: "ܡܘܕܥܢܘܬܐ",
            dt1: "Ankündigung",
            sy9: "ܡܰܙܥܩܳܢܽܘܬ݂ܳܐ",
            dt3: "Meldung",
            dt2: "Durchsage",
            lt12: "mazĉqonutho",
            sy6: "ܡܛܒܒܢܘܬܐ"
        },
        {
            lt12: "",
            lt10: "țuroyo",
            sy8: "",
            dt1: "Mann aus den Bergen",
            id: "2916",
            sy4: "ܛܘܪܝܐ",
            dt3: "",
            sy7: "",
            sy5: "ܛܽܘܪܳܝܳܐ",
            sy6: "",
            sy9: "",
            lt11: "",
            dt2: ""
        },
        {
            sy7: "ܩܶܣܳܪܳܐ",
            sy8: "",
            sy6: "ܩܣܪܐ",
            lt10: "susgauno",
            sy4: "ܣܘܣܓܘܢܐ",
            dt1: "hellblau",
            lt11: "qesoro",
            sy5: "ܣܽܘܣܓܰܘܢܳܐ",
            lt12: "",
            dt3: "",
            dt2: "",
            id: "6062",
            sy9: ""
        },
        {
            id: "4521",
            sy6: "ܪܟܢܐ",
            sy8: "",
            dt2: "Melodie",
            lt12: "",
            sy5: "ܢܥܡܬܳܐ",
            dt1: "Ton",
            dt3: "",
            lt11: "rekno",
            sy7: "ܪܶܟܢܳܐ",
            lt10: "neĉmtho",
            sy9: "",
            sy4: "ܢܥܡܬܐ"
        },
        {
            sy4: "ܬܪܝܢ",
            lt12: "beth",
            dt2: "Zwei",
            id: "3",
            sy8: "ܒ",
            dt1: "2",
            lt10: "treyn",
            sy7: "ܬܰܪܬܶܝܢ",
            lt11: "tarteyn",
            sy6: "ܬܪܬܝܢ",
            sy5: "ܬܪܶܝܢ",
            dt3: "",
            sy9: "ܒ"
        },
        {
            sy4: "ܐܕܘܢܝܩܡ",
            sy5: "ܐܰܕܳܘܢܺܝܩܰܡ",
            id: "135",
            sy6: "",
            dt3: "",
            dt1: "Adonikam",
            lt12: "",
            sy7: "",
            sy8: "",
            sy9: "",
            dt2: "",
            lt10: "ܐܕܘܢܝܩܡ",
            lt11: "ܐܕܘܢܝܩܡ"
        },
        {
            sy9: "",
            dt3: "",
            dt1: "Messdiener",
            sy7: "ܡܫܰܡܫܳܢܳܐ",
            sy8: "",
            lt10: "shamosho",
            lt12: "",
            lt11: "mshamshono",
            sy6: "ܡܫܡܫܢܐ",
            sy5: "ܫܰܡܳܫܳܐ",
            sy4: "ܫܡܫܐ",
            dt2: "",
            id: "3006"
        },
        {
            sy7: "ܡܦܰܪܫܳܐܝܺܬ݂",
            id: "1613",
            lt11: "mfarshoith",
            sy5: "ܦܪܺܝܫܳܐ",
            dt2: "",
            dt1: "getrennt",
            sy4: "ܦܪܝܫܐ",
            lt10: "frisho",
            sy9: "",
            sy6: "ܡܦܪܫܐܝܬ",
            lt12: "",
            sy8: "",
            dt3: ""
        },
        {
            sy9: "",
            sy4: "ܡܪܘܢ",
            id: "2950",
            sy8: "",
            dt1: "Marun",
            lt11: "ܡܪܘܢ",
            dt2: "Morun",
            dt3: "",
            sy6: "ܡܪܘܢ",
            sy7: "ܡܳܪܽܘܢ",
            lt10: "ܡܪܘܢ",
            lt12: "ܡܪܘܢ",
            sy5: "ܡܰܪܽܘܢ"
        },
        {
            lt12: "",
            sy9: "",
            dt3: "",
            sy7: "",
            id: "1996",
            dt2: "",
            sy4: "ܚܝܕܐܪܝ",
            sy6: "",
            lt11: "ܚܝܕܐܪܝ",
            sy5: "ܚܺܝܕܰܐܪܺܝ",
            sy8: "",
            lt10: "ܚܝܕܐܪܝ",
            dt1: "Hidari"
        },
        {
            sy7: "",
            sy9: "",
            lt12: "",
            lt10: "qandilo",
            sy4: "ܩܢܕܝܠܐ",
            dt3: "Kerzenständer",
            sy8: "",
            dt2: "Öllampe",
            id: "2778",
            lt11: "",
            sy5: "ܩܰܢܕܺܝܠܳܐ",
            dt1: "Letzte Ölung",
            sy6: ""
        },
        {
            lt12: "",
            sy4: "ܣܟܝܪܐ",
            dt3: "",
            id: "1586",
            sy9: "",
            sy5: "ܣܟ݂ܺܝܪܳܐ",
            dt1: "geschlossen",
            dt2: "verschlossen",
            sy7: "",
            lt11: "",
            sy8: "",
            lt10: "skhiro",
            sy6: ""
        },
        {
            lt11: "",
            lt12: "",
            dt1: "Kollaboration",
            dt3: "",
            sy5: "ܦܽܘܠܚܳܢܳܐ ܕܥܰܡ ܒܥܶܠܕܒܳܒܳܐ",
            sy9: "",
            dt2: "",
            lt10: "fulĥono d`ĉam bĉeleđbobo",
            sy4: "ܦܘܠܚܢܐ ܕܥܡ ܒܥܠܕܒܒܐ",
            sy7: "",
            sy6: "",
            id: "2555",
            sy8: ""
        },
        {
            sy5: "ܛܽܘܦܬ݂ܳܐ ܕܡܶܛܪܳܐ",
            id: "3660",
            sy6: "",
            sy4: "ܛܘܦܬܐ ܕܡܛܪܐ",
            lt10: "țuftho d`mețro",
            dt2: "",
            sy9: "",
            lt12: "",
            dt3: "",
            lt11: "",
            dt1: "Regentropfen",
            sy7: "",
            sy8: ""
        },
        {
            lt11: "",
            sy7: "",
            sy6: "",
            id: "1113",
            sy5: "ܐܰܪܚܶܩ ܡܶܢ ܗܳܪܟܳܐ",
            dt1: "entferne Dich von hier",
            dt2: "",
            lt10: "arĥeq men horko",
            sy9: "",
            lt12: "",
            sy4: "ܐܪܚܩ ܡܢ ܗܪܟܐ",
            dt3: "",
            sy8: ""
        },
        {
            sy8: "",
            sy6: "",
            lt10: "ܙܟܪܝܝܐ",
            dt2: "Zakaria",
            sy9: "",
            id: "5247",
            sy4: "ܙܟܪܝܝܐ",
            lt11: "ܙܟܪܝܝܐ",
            dt3: "Sacharja",
            lt12: "",
            sy7: "",
            dt1: "Zakariya",
            sy5: "ܙܟܪܝܐܣ"
        },
        {
            lt10: "ayko domekh at",
            id: "5145",
            sy9: "",
            sy8: "",
            lt11: "",
            sy4: "ܐܝܟܐ ܕܡܟ ܐܬ",
            lt12: "",
            sy7: "",
            sy5: "ܐܰܝܟܳܐ ܕܳܡܶܟ݂ ܐܰܢ̱ܬ",
            dt3: "",
            sy6: "",
            dt2: "",
            dt1: "Wo schläfst Du"
        },
        {
            sy5: "ܡܚܺܝܠܳܐ",
            dt2: "kraftlos",
            sy9: "",
            lt10: "mĥilo",
            lt11: "",
            sy7: "",
            lt12: "",
            dt3: "",
            sy6: "",
            sy4: "ܡܚܝܠܐ",
            id: "4060",
            sy8: "",
            dt1: "schwach"
        },
        {
            dt1: "Feuerzeug",
            sy6: "",
            lt12: "",
            id: "1307",
            sy8: "",
            lt10: "maqdĥo",
            dt2: "",
            sy7: "",
            lt11: "",
            sy5: "ܡܰܩܕܚܳܐ",
            sy9: "",
            dt3: "",
            sy4: "ܡܩܕܚܐ"
        },
        {
            lt12: "",
            dt3: "",
            sy6: "ܥܒܕ ܕܘܟܪܢܐ",
            sy5: "ܥܰܕܥܶܕ",
            lt10: "ĉađĉeđ",
            id: "6259",
            sy8: "",
            dt1: "zelebrieren",
            sy4: "ܥܕܥܕ",
            sy7: "ܥܒܰܕ ܕܽܘܟ݂ܪܳܢܳܐ",
            sy9: "",
            dt2: "",
            lt11: "ĉbad dukhrono"
        },
        {
            sy5: "ܢܰܚܳܘܡ",
            dt2: "",
            sy8: "",
            id: "3191",
            dt3: "",
            lt10: "ܢܚܘܡ",
            dt1: "Nahum",
            sy6: "ܢܚܘܡ",
            lt12: "ܢܚܘܡ",
            sy7: "ܢܳܚܽܘܡ",
            sy4: "ܢܚܘܡ",
            sy9: "",
            lt11: "ܢܚܘܡ"
        },
        {
            dt3: "",
            id: "4128",
            sy4: "ܫܠܡܐ",
            dt2: "Peace",
            sy9: "",
            lt10: "shlomo",
            lt12: "",
            lt11: "",
            dt1: "Servus",
            sy6: "",
            sy8: "",
            sy7: "",
            sy5: "ܫܠܳܡܳܐ"
        },
        {
            lt12: "tnonoyo",
            dt3: "",
            sy4: "ܗܫܝܐ",
            dt1: "aktuell",
            lt11: "yaumoyo",
            sy5: "ܗܳܫܳܝܳܐ",
            id: "174",
            sy7: "ܝܰܘܡܳܝܳܐ",
            sy6: "ܝܘܡܝܐ",
            lt10: "hoshoyo",
            dt2: "",
            sy9: "ܬܢܳܢܳܝܳܐ",
            sy8: "ܬܢܢܝܐ"
        },
        {
            lt11: "halekh",
            sy5: "ܪܗܶܛ",
            sy9: "",
            sy4: "ܪܗܛ",
            dt3: "",
            sy8: "",
            dt1: "rennen",
            lt12: "",
            sy6: "ܗܠܟ",
            id: "3690",
            lt10: "rheț",
            sy7: "ܗܰܠܶܟ݂",
            dt2: ""
        },
        {
            sy4: "ܣܘܪܕܐ",
            dt1: "Terror",
            sy5: "ܣܽܘܪܳܕܳܐ",
            lt10: "surodo",
            sy7: "",
            lt12: "",
            sy6: "",
            dt3: "",
            dt2: "",
            lt11: "",
            id: "4440",
            sy9: "",
            sy8: ""
        },
        {
            sy6: "ܫܓܢܝ",
            dt1: "ändern",
            dt2: "korrigieren",
            id: "5933",
            dt3: "modifizieren",
            sy5: "ܫܰܚܠܶܦ",
            lt11: "shağni",
            lt10: "shaĥlef",
            sy4: "ܫܚܠܦ",
            lt12: "",
            sy7: "ܫܰܓ݂ܢܺܝ",
            sy9: "",
            sy8: ""
        },
        {
            dt1: "Buße",
            sy9: "",
            sy4: "ܬܝܒܘܬܐ",
            sy7: "",
            lt11: "",
            lt12: "",
            id: "6429",
            dt3: "",
            dt2: "Buse",
            sy6: "",
            sy8: "",
            lt10: "tyobutho",
            sy5: "ܬܝܳܒܽܘܬ݂ܳܐ"
        },
        {
            sy6: "ܣܢܐܓܪܐ",
            lt12: "",
            sy5: "ܣܢܺܝܓ݂ܪܳܐ",
            sy7: "ܣܢܺܐܓ݂ܪܳܐ",
            lt10: "sniğro",
            sy8: "",
            dt3: "Verteidiger",
            dt1: "Rechtsanwalt",
            dt2: "Anwalt",
            lt11: "sniğro",
            sy9: "",
            sy4: "ܣܢܝܓܪܐ",
            id: "3640"
        },
        {
            lt11: "ĥțoho",
            id: "4313",
            lt12: "ĥaubo",
            sy9: "ܚܰܘܒܳܐ",
            sy5: "ܚܛܺܝܬ݂ܳܐ",
            sy7: "ܚܛܳܗܳܐ",
            dt2: "Schuld",
            lt10: "ĥțitho",
            sy6: "ܚܛܗܐ",
            dt3: "",
            sy8: "ܚܘܒܐ",
            dt1: "Sünde",
            sy4: "ܚܛܝܬܐ"
        },
        {
            dt3: "",
            dt1: "gelb",
            sy5: "ܫܰܥܽܘܬܳܐ",
            id: "1545",
            lt10: "shaĉutho",
            sy7: "",
            lt11: "",
            sy9: "",
            sy4: "ܫܥܘܬܐ",
            dt2: "",
            sy6: "",
            sy8: "",
            lt12: ""
        },
        {
            sy9: "",
            dt3: "",
            dt2: "",
            lt10: "kmo ishne at",
            sy8: "",
            sy4: "ܟܡܐ ܐܫܢܐ ܐܢܬ",
            sy5: "ܟܡܳܐ ܐܺܫ̈ܢܶܐ ܐܰܢ̱ܬ",
            id: "5084",
            sy6: "",
            dt1: "Wie alt bis du",
            sy7: "",
            lt11: "",
            lt12: ""
        },
        {
            dt1: "Debora",
            sy5: "ܕܶܒܽܘܪܰܐ",
            sy4: "ܕܒܘܪܐ",
            sy7: "ܕܶܒܳܘܪܰܐ",
            sy8: "",
            lt11: "ܕܒܘܪܐ",
            lt10: "ܕܒܘܪܐ",
            id: "839",
            lt12: "ܕܒܘܪܐ",
            dt2: "Debura",
            dt3: "",
            sy9: "",
            sy6: "ܕܒܘܪܐ"
        },
        {
            sy6: "",
            lt10: "shyoğto d`muĥo",
            id: "5421",
            dt3: "",
            sy5: "ܫܝܳܓ̣ܬܳܐ ܕܡܽܘܚܳܐ",
            lt12: "",
            dt1: "Gehirnwäsche",
            sy9: "",
            lt11: "",
            dt2: "",
            sy7: "",
            sy4: "ܫܝܳܓ̣ܬܳܐ ܕܡܽܘܚܳܐ",
            sy8: ""
        },
        {
            lt12: "",
            dt1: "blau",
            lt11: "",
            sy5: "ܙܰܪܩܳܐ",
            sy6: "",
            sy7: "",
            dt2: "",
            sy8: "",
            sy4: "ܙܪܩܐ",
            id: "643",
            sy9: "",
            lt10: "zarqo",
            dt3: ""
        },
        {
            lt12: "",
            sy5: "ܫܶܢܬ݂ܳܐ ܣܰܬ݂ܘܳܝܬܳܐ",
            dt1: "Winterschlaf",
            id: "5119",
            sy7: "",
            sy4: "ܫܢܬܐ ܣܬܘܝܬܐ",
            sy9: "",
            lt11: "",
            lt10: "shentho sathwoyto",
            sy8: "",
            dt3: "",
            dt2: "",
            sy6: ""
        },
        {
            sy5: "ܫܠܰܩ",
            sy4: "ܫܠܩ",
            dt2: "",
            sy9: "",
            lt10: "shlaq",
            lt11: "",
            sy8: "",
            lt12: "",
            sy7: "",
            id: "101",
            dt3: "",
            sy6: "",
            dt1: "abkochen"
        },
        {
            sy5: "ܕܘܡܳܪܳܐ",
            lt10: "dumoro",
            dt2: "",
            dt1: "Wunder",
            lt11: "tedmurto",
            sy9: "",
            dt3: "",
            id: "5173",
            sy4: "ܕܘܡܪܐ",
            sy6: "ܬܕܡܘܪܬܐ",
            sy7: "ܬܶܕܡܽܘܪܬܳܐ",
            lt12: "",
            sy8: ""
        },
        {
            lt11: "",
            sy8: "",
            sy9: "",
            dt3: "",
            sy4: "ܫܓܘܡܝܐ",
            lt12: "",
            sy6: "",
            lt10: "shğumyo",
            sy5: "ܫܓ݂ܽܘܡܝܳܐ",
            dt1: "Subtilität",
            sy7: "",
            id: "6238",
            dt2: ""
        },
        {
            sy6: "",
            dt3: "",
            sy8: "",
            lt11: "",
            dt2: "",
            sy9: "",
            sy5: "ܒܰܢܳܝܳܐ ܒܳܢܶܐ ܘܡܰܥܡܰܪ ܒ̈ܳܬܶܐ",
            dt1: "Maurer baut Häuser",
            lt12: "",
            sy7: "",
            lt10: "banoyo bone auw maĉmar bote",
            sy4: "ܒܢܝܐ ܒܢܐ ܘܡܥܡܪ ܒܬܐ",
            id: "2976"
        },
        {
            lt12: "",
            lt10: "galwoyto",
            sy5: "ܓܰܠܽܘܳܝܬܳܐ",
            id: "320",
            sy7: "",
            dt1: "Asylantin",
            dt2: "Emigrantin",
            sy8: "",
            sy6: "",
            sy9: "",
            dt3: "Auswanderin",
            sy4: "ܓܠܘܝܬܐ",
            lt11: ""
        },
        {
            lt12: "",
            lt10: "tagorto",
            sy6: "",
            dt2: "Kauffrau",
            sy5: "ܬܰܓܳܪܬܳܐ",
            id: "1856",
            sy7: "",
            sy9: "",
            sy8: "",
            lt11: "",
            sy4: "ܬܓܪܬܐ",
            dt1: "Händlerin",
            dt3: "Geschäftsfrau"
        },
        {
            sy7: "",
            lt12: "",
            dt1: "Licht",
            sy5: "ܒܰܗܪܳܐ",
            dt2: "",
            id: "2804",
            lt10: "bahro",
            lt11: "",
            sy8: "",
            sy4: "ܒܗܪܐ",
            sy6: "",
            sy9: "",
            dt3: ""
        },
        {
            lt11: "",
            dt2: "",
            lt12: "",
            sy5: "ܫܒܺܝܠ ܨܶܒܥܳܐ",
            id: "1314",
            sy6: "",
            sy8: "",
            sy9: "",
            dt3: "",
            lt10: "shbil ŝebĉo",
            dt1: "Fingerabdruck",
            sy7: "",
            sy4: "ܫܒܝܠ ܨܒܥܐ"
        },
        {
            lt12: "shaĥțonutho",
            sy9: "ܫܰܚܛܳܢܽܘܬ݂ܳܐ",
            lt10: "raqutho",
            sy8: "ܫܚܛܢܘܬܐ",
            sy4: "ܪܩܘܬܐ",
            dt3: "",
            sy7: "ܫܽܘܓ݂ܳܪܳܐ",
            dt1: "Abscheulichkeit",
            id: "5639",
            sy5: "ܪܰܩܽܘܬ݂ܳܐ",
            dt2: "",
            lt11: "shuğoro",
            sy6: "ܫܘܓܪܐ"
        },
        {
            id: "3680",
            sy9: "",
            sy6: "",
            lt11: "",
            lt12: "",
            dt2: "",
            dt3: "",
            lt10: "zabno țuyolo",
            sy7: "",
            dt1: "Reisezeit",
            sy5: "ܙܰܒܢܳܐ ܛܽܘܝܳܠܳܐ",
            sy8: "",
            sy4: "ܙܒܢܐ ܛܘܝܠܐ"
        },
        {
            lt11: "",
            dt2: "",
            sy8: "",
            dt1: "Herr der Stärke",
            dt3: "",
            sy4: "ܡܪܝܐ ܚܝܠܬܢܐ",
            sy7: "",
            id: "1968",
            sy6: "",
            sy9: "",
            lt10: "moryo ĥaylthono",
            lt12: "",
            sy5: "ܡܳܪܝܳܐ ܚܰܝܠܬ݂ܳܢܳܐ"
        },
        {
            lt11: "",
            lt10: "shuroy ramsho",
            sy8: "",
            sy4: "ܫܘܪܝ ܪܡܫܐ",
            sy7: "",
            sy9: "",
            sy6: "",
            dt2: "",
            id: "5554",
            dt3: "",
            lt12: "",
            sy5: "ܫܽܘܪܳܝ ܪܰܡܫܳܐ",
            dt1: "Abendbeginn"
        },
        {
            sy7: "ܡܶܫܬܰܡܥܳܢܳܐ",
            sy9: "",
            lt10: "shemĉonoyo",
            sy6: "ܡܫܬܡܥܢܐ",
            sy5: "ܫܶܡܥܳܢܳܝܳܐ",
            sy8: "",
            dt1: "hörbar",
            dt3: "",
            lt12: "",
            lt11: "meshtamĉono",
            sy4: "ܫܡܥܢܝܐ",
            id: "6044",
            dt2: ""
        },
        {
            sy7: "",
            sy6: "",
            id: "1498",
            sy4: "ܝܠܕܐ",
            lt10: "yaldo",
            dt1: "Geborener",
            sy8: "",
            dt2: "",
            lt12: "",
            sy5: "ܝܰܠܕܳܐ",
            lt11: "",
            dt3: "",
            sy9: ""
        },
        {
            lt10: "ŝin",
            lt12: "",
            sy7: "",
            sy9: "",
            id: "763",
            sy6: "",
            sy4: "ܨܝܢ",
            dt1: "China",
            dt3: "",
            dt2: "",
            lt11: "",
            sy5: "ܨܺܝܢ",
            sy8: ""
        },
        {
            sy6: "",
            dt3: "",
            dt2: "Hosea",
            sy8: "",
            sy7: "",
            lt10: "ܗܘܫܥ",
            sy9: "",
            sy5: "ܗܽܘܫܳܥ",
            dt1: "Hoschea",
            lt11: "ܗܘܫܥ",
            lt12: "",
            id: "2055",
            sy4: "ܗܘܫܥ"
        },
        {
            lt11: "daĉbubo",
            id: "6155",
            sy9: "",
            sy7: "ܕܰܥܒܽܘܒܳܐ",
            dt1: "Pauke",
            sy5: "ܐܰܪܕܰܒܩܳܐ",
            lt12: "",
            sy4: "ܐܪܕܒܩܐ",
            sy8: "",
            dt2: "",
            lt10: "ardabqo",
            sy6: "ܕܥܒܘܒܐ",
            dt3: ""
        },
        {
            sy4: "ܡܙܘܓܘܬܐ",
            dt3: "",
            sy9: "",
            lt12: "",
            dt1: "Paarung",
            dt2: "",
            lt10: "mzaugutho",
            sy8: "",
            sy5: "ܡܙܰܘܓܽܘܬ݂ܳܐ",
            id: "3407",
            sy6: "",
            lt11: "",
            sy7: ""
        },
        {
            lt11: "taw-taw-qof",
            sy4: "ܬܫܥܡܐܐ",
            sy7: "ܬܬܩ",
            dt2: "Neunhundert",
            lt12: "",
            dt3: "",
            sy9: "",
            sy6: "ܬܬܩ",
            sy5: "ܬܫܰܥܡܳܐܐ",
            dt1: "900",
            lt10: "tshaĉmo",
            sy8: "",
            id: "46"
        },
        {
            id: "458",
            lt10: "ܒܪܠܒܐ",
            sy7: "",
            sy5: "ܒܰܪܠܶܒܳܐ",
            dt1: "Barlebo",
            lt12: "",
            sy6: "",
            sy4: "ܒܪܠܒܐ",
            sy8: "",
            dt3: "",
            dt2: "",
            lt11: "ܒܪܠܒܐ",
            sy9: ""
        },
        {
            sy8: "",
            id: "2690",
            lt12: "",
            dt1: "lachen",
            lt11: "goĥekh",
            sy7: "ܓܳܚܶܟ݂",
            sy9: "",
            sy5: "ܓܚܶܟ",
            sy4: "ܓܚܟ",
            lt10: "gĥek",
            dt2: "",
            dt3: "",
            sy6: "ܓܚܟ"
        },
        {
            sy7: "",
            sy9: "",
            dt3: "",
            sy6: "",
            sy8: "",
            id: "1052",
            lt11: "",
            dt1: "einsatzbereit",
            dt2: "",
            lt10: "mțaybo",
            sy5: "ܡܛܰܝܒܳܐ",
            sy4: "ܡܛܝܒܐ",
            lt12: ""
        },
        {
            lt12: "",
            dt1: "Maler malt Bilder",
            id: "2901",
            sy5: "ܨܰܝܳܪܳܐ ܨܳܐܰܪ ܨܽܘ̈ܪܳܬ݂ܳܐ",
            sy9: "",
            dt2: "",
            sy8: "",
            dt3: "",
            sy6: "",
            sy4: "ܨܝܪܐ ܨܐܪ ܨܘܪܬܐ",
            lt11: "",
            lt10: "ŝayoro ŝoyar ŝurotho",
            sy7: ""
        },
        {
            id: "5869",
            sy7: "",
            sy4: "ܠܫܢܐ ܥܒܪܝܐ ܥܬܝܩܐ",
            lt10: "leshono ĉebroyo ĉatiqo",
            dt2: "",
            sy6: "",
            dt1: "althebräische Sprache",
            dt3: "",
            lt12: "",
            lt11: "",
            sy9: "",
            sy8: "",
            sy5: "ܠܶܫܳܢܳܐ ܥܶܒܪܳܝܳܐ ܥܰܬܺܝܩܳܐ"
        },
        {
            sy5: "ܕܶܒܽܘܪܳܐ",
            sy4: "ܕܒܘܪܐ",
            lt10: "deburo",
            lt12: "",
            lt11: "",
            id: "5073",
            sy7: "",
            sy6: "",
            dt1: "Wespe",
            dt2: "",
            sy9: "",
            dt3: "",
            sy8: ""
        },
        {
            dt2: "",
            lt11: "ܐܚܙܝܐ",
            id: "152",
            sy4: "ܐܚܙܝܐ",
            lt10: "ܐܚܙܝܐ",
            dt1: "Ahasja",
            dt3: "",
            sy6: "",
            sy5: "ܐܰܚܰܙܝܰܐ",
            lt12: "",
            sy9: "",
            sy8: "",
            sy7: ""
        },
        {
            id: "5687",
            dt3: "",
            sy7: "",
            sy4: "ܫܢܙ",
            lt12: "",
            dt2: "",
            dt1: "abtreten lassen",
            lt10: "shanez",
            lt11: "",
            sy9: "",
            sy8: "",
            sy5: "ܫܰܢܶܙ",
            sy6: ""
        },
        {
            sy6: "",
            sy4: "ܬܓܪܘܬ ܚܝܘܬܐ",
            dt3: "",
            dt1: "Tierhandel",
            sy7: "",
            lt11: "",
            id: "4482",
            lt12: "",
            lt10: "tagoruth ĥaywotho",
            dt2: "",
            sy8: "",
            sy9: "",
            sy5: "ܬܰܓܳܪܽܘܬ݂ ܚܰܝ̈ܘܳܬ݂ܳܐ"
        },
        {
            lt11: "",
            sy6: "",
            lt12: "",
            dt2: "",
            lt10: "ŝorumyotho",
            sy7: "",
            id: "4900",
            sy5: "ܨܳܪܽܘܡܝܳܬ݂ܳܐ",
            dt3: "",
            sy4: "ܨܪܘܡܝܬܐ",
            sy9: "",
            dt1: "Vorderzähne",
            sy8: ""
        },
        {
            sy6: "ܒܨܪ ܛܝܡܝ ܕܙܘܙܐ",
            dt2: "",
            sy9: "",
            lt12: "",
            dt3: "",
            sy5: "ܒܽܘܨܳܪ ܛܺܝ̈ܡܶܐ ܕܙܽܘ̈ܙܶܐ",
            id: "5710",
            dt1: "Abwertung",
            lt11: "baŝar țimay dzuze",
            sy8: "",
            sy7: "ܒܰܨܰܪ ܛܺܝ̈ܡܰܝ ܕܙܽܘ̈ܙܶܐ",
            lt10: "buŝor țime dzuze",
            sy4: "ܒܘܨܪ ܛܝܡܐ ܕܙܘܙܐ"
        },
        {
            id: "1978",
            sy6: "ܢܘܩܫ ܠܒܐ",
            dt3: "",
            lt10: "nqash lebo",
            lt11: "nuqosh lebo",
            sy7: "ܢܽܘܩܳܫ ܠܶܒܳܐ",
            dt1: "Herz klopfen",
            dt2: "Herzklopfen",
            sy8: "ܕܝܩ ܠܒܐ",
            sy4: "ܢܩܫ ܠܒܐ",
            sy5: "ܢܩܰܫ ܠܶܒܳܐ",
            lt12: "dyoq lebo",
            sy9: "ܕܝܳܩ ܠܶܒܳܐ"
        },
        {
            lt11: "mĉauko",
            sy9: "ܡܥܰܘܟܽܘܬ݂ܳܐ",
            dt2: "",
            sy5: "ܟܶܠܝܳܢܳܐ",
            sy7: "ܡܥܰܘܟܳܐ",
            dt1: "Hemmnis",
            dt3: "",
            id: "6063",
            lt12: "mĉaukutho",
            lt10: "kelyono",
            sy8: "ܡܥܘܟܘܬܐ",
            sy4: "ܟܠܝܢܐ",
            sy6: "ܡܥܘܟܐ"
        },
        {
            sy7: "",
            dt3: "",
            lt10: "giğlo",
            id: "3590",
            sy8: "",
            dt1: "Rad",
            sy9: "",
            sy5: "ܓܺܝܓ݂ܠܳܐ",
            dt2: "Reifen",
            sy6: "",
            lt12: "",
            sy4: "ܓܝܓܠܐ",
            lt11: ""
        },
        {
            sy9: "",
            sy6: "ܐܦ",
            sy4: "ܐܘ",
            dt1: "oder",
            lt12: "",
            dt3: "",
            dt2: "",
            sy7: "ܐܳܦ",
            id: "3338",
            lt10: "auw",
            sy5: "ܐܰܘ",
            sy8: "",
            lt11: "of"
        },
        {
            dt2: "",
            dt1: "wahnsinnig",
            sy5: "ܫܰܢܝܳܐܝܺܬ݂",
            sy9: "",
            id: "4949",
            sy7: "ܫܰܢܳܝܳܐ",
            dt3: "",
            lt10: "shanyoith",
            sy8: "",
            sy6: "ܫܢܝܐ",
            lt11: "shanoyo",
            lt12: "",
            sy4: "ܫܢܝܐܝܬ"
        },
        {
            sy6: "",
            dt2: "",
            lt11: "ܠܒܐ",
            sy4: "ܠܒܐ",
            lt10: "ܠܒܐ",
            dt1: "Lebo",
            lt12: "",
            sy7: "",
            sy5: "ܠܶܒܳܐ",
            id: "2734",
            sy9: "",
            sy8: "",
            dt3: ""
        },
        {
            dt2: "illusionär",
            sy8: "",
            dt3: "",
            dt1: "fiktiv",
            id: "1309",
            sy5: "ܗܰܓܳܓܳܝܳܐ",
            sy9: "",
            lt12: "",
            sy6: "",
            sy4: "ܗܓܓܝܐ",
            lt11: "",
            lt10: "haggogoyo",
            sy7: ""
        },
        {
            sy9: "",
            sy6: "",
            lt12: "",
            dt3: "",
            lt10: "kiso d`ĥelo",
            id: "3835",
            sy5: "ܟܺܝܣܳܐ ܕܚܶܠܳܐ",
            dt2: "",
            lt11: "",
            dt1: "Sandsack",
            sy7: "",
            sy8: "",
            sy4: "ܟܝܣܐ ܕܚܠܐ"
        },
        {
            lt12: "",
            dt2: "",
            dt3: "",
            dt1: "vollführen",
            lt11: "shalem",
            sy4: "ܓܡܪ",
            lt10: "gamar",
            sy6: "ܫܠܡ",
            sy8: "",
            id: "4870",
            sy9: "",
            sy5: "ܓܰܡܰܪ",
            sy7: "ܫܰܠܶܡ"
        },
        {
            dt2: "",
            sy7: "",
            sy6: "",
            sy4: "ܐܢܢܝܘܬܐ",
            sy9: "",
            dt3: "",
            lt12: "",
            id: "1004",
            sy8: "",
            sy5: "ܐܶܢܳܢܳܝܽܘܬ݂ܳܐ",
            dt1: "Egoismus",
            lt11: "",
            lt10: "enonoyutho"
        },
        {
            sy6: "ܪܚܡ",
            sy9: "",
            lt12: "",
            sy7: "ܪܳܚܶܡ",
            dt2: "mögen",
            sy5: "ܪܚܰܡ",
            dt1: "lieben",
            lt11: "roĥem",
            sy8: "",
            lt10: "rĥam",
            dt3: "",
            id: "2809",
            sy4: "ܪܚܡ"
        },
        {
            sy8: "",
            sy5: "ܫܢܰܝ ܝܽܘܠܦܳܢܳܐ",
            sy6: "",
            lt10: "shnay yulfono",
            dt3: "",
            sy9: "",
            sy7: "",
            dt2: "",
            id: "2744",
            dt1: "Lehrjahre",
            sy4: "ܫܢܝ ܝܘܠܦܢܐ",
            lt11: "",
            lt12: ""
        },
        {
            lt12: "",
            sy8: "",
            lt10: "ith",
            sy5: "ܐܺܝܬ݂",
            dt1: "haben",
            lt11: "",
            dt3: "",
            sy7: "",
            sy4: "ܐܝܬ",
            sy6: "",
            sy9: "",
            dt2: "",
            id: "1790"
        },
        {
            lt12: "",
            sy4: "ܫܠܡܐ",
            id: "4871",
            sy7: "ܒܰܪ ܥܽܘܙܳܐ",
            dt2: "erwachsen",
            dt3: "",
            sy8: "",
            lt11: "bar ĉuzo",
            sy5: "ܫܰܠܡܳܐ",
            lt10: "shalmo",
            dt1: "volljährig",
            sy6: "ܒܪ ܥܘܙܐ",
            sy9: ""
        },
        {
            id: "5405",
            sy9: "",
            lt10: "aĥo tomo",
            sy6: "",
            sy7: "",
            lt12: "",
            sy5: "ܐܰܚܳܐ ܬܳܐܡܳܐ",
            lt11: "",
            dt1: "Zwillingsbruder",
            dt3: "",
            sy4: "ܐܚܐ ܬܐܡܐ",
            dt2: "",
            sy8: ""
        },
        {
            lt12: "",
            dt1: "Haleluya",
            sy9: "",
            sy7: "",
            sy5: "ܗܰܠܶܠܽܘܝܰܗ",
            sy4: "ܗܠܠܘܝܗ",
            sy6: "",
            id: "1829",
            sy8: "",
            dt3: "Halleluja",
            lt11: "",
            lt10: "haleluya",
            dt2: "Haleluja"
        },
        {
            lt11: "othuthonoith",
            sy8: "",
            dt3: "",
            sy9: "",
            lt10: "melthonoith",
            sy6: "ܐܬܘܬܢܐܝܬ",
            id: "5170",
            dt1: "wörtlich",
            lt12: "",
            sy4: "ܡܠܬܢܐܝܬ",
            sy7: "ܐܳܬ݂ܽܘܬ݂ܳܢܳܐܝܺܬ݂",
            dt2: "",
            sy5: "ܡܶܠܬ݂ܢܳܐܝܺܬ݂"
        },
        {
            sy5: "ܣܰܒܰܪ",
            sy9: "ܐܰܟ݂ܪܶܙ",
            lt11: "audaĉ",
            dt1: "ankündigen",
            dt3: "bekanntgeben",
            sy6: "ܐܘܕܥ",
            id: "5958",
            lt10: "sabar",
            sy4: "ܣܒܪ",
            sy7: "ܐܰܘܕܰܥ",
            lt12: "akhrez",
            sy8: "ܐܟܪܙ",
            dt2: "andeuten"
        },
        {
            lt11: "ܬܕܝ",
            dt2: "",
            sy4: "ܬܕܝ",
            sy5: "ܬܰܕܰܝ",
            lt10: "ܬܕܝ",
            dt3: "",
            sy6: "",
            lt12: "",
            id: "4358",
            sy8: "",
            dt1: "Taddai",
            sy7: "",
            sy9: ""
        },
        {
            dt1: "Vers",
            sy4: "ܚܘܩܐ",
            sy8: "",
            sy7: "ܬܶܒܪܳܐ",
            dt2: "Bibelvers",
            lt11: "tebro",
            id: "4794",
            sy6: "ܬܒܪܐ",
            sy5: "ܚܽܘܩܳܐ",
            lt10: "ĥuqo",
            sy9: "",
            lt12: "",
            dt3: ""
        },
        {
            sy5: "ܐܰܟܺܝܦܽܘܬܰܐ",
            sy7: "",
            lt10: "ܐܟܝܦܘܬܐ",
            sy4: "ܐܟܝܦܘܬܐ",
            dt2: "",
            dt3: "",
            sy6: "",
            lt11: "ܐܟܝܦܘܬܐ",
            id: "170",
            sy9: "",
            dt1: "Akifuta",
            sy8: "",
            lt12: ""
        },
        {
            sy6: "ܫܘܘܕܝܐ",
            lt10: "nedro",
            lt12: "",
            dt2: "",
            dt1: "Versprechen",
            sy5: "ܢܶܕܪܳܐ",
            lt11: "shudoyo",
            sy4: "ܢܕܪܐ",
            sy9: "",
            id: "4809",
            dt3: "",
            sy7: "ܫܽܘܘܕܳܝܳܐ",
            sy8: ""
        },
        {
            lt10: "țaĉem",
            dt2: "",
            sy7: "ܡܚܳܐ ܡܚܰܛܳܐ",
            dt3: "",
            lt12: "",
            sy5: "ܛܰܥܶܡ",
            sy4: "ܛܥܡ",
            sy9: "",
            lt11: "mĥo mĥațo",
            dt1: "impfen",
            sy8: "",
            sy6: "ܡܚܐ ܡܚܛܐ",
            id: "2174"
        },
        {
            sy4: "ܫܢܬܐ",
            lt11: "dmokho",
            sy5: "ܫܶܢܬ݂ܳܐ",
            sy8: "",
            sy6: "ܕܡܟܐ",
            dt3: "",
            dt1: "Schlaf",
            dt2: "",
            sy9: "",
            lt10: "shentho",
            sy7: "ܕܡܳܟ݂ܳܐ",
            id: "3958",
            lt12: ""
        },
        {
            sy8: "",
            sy7: "",
            sy6: "",
            lt11: "ܣܡܝܪ",
            lt12: "",
            sy5: "ܣܰܡܺܝܪ",
            lt10: "ܣܡܝܪ",
            dt1: "Samir",
            sy4: "ܣܡܝܪ",
            dt3: "",
            dt2: "",
            sy9: "",
            id: "3822"
        },
        {
            id: "3825",
            sy6: "",
            sy8: "",
            dt2: "",
            sy5: "ܣܰܡܝܰܐ",
            sy7: "",
            lt11: "ܣܡܝܐ",
            sy4: "ܣܡܝܐ",
            dt1: "Samja",
            lt12: "",
            sy9: "",
            dt3: "",
            lt10: "ܣܡܝܐ"
        },
        {
            dt1: "th",
            sy8: "",
            sy4: "ܬ",
            dt3: "",
            lt10: "th",
            id: "4449",
            dt2: "",
            sy5: "ܬ݂",
            sy9: "",
            sy6: "",
            lt12: "",
            lt11: "",
            sy7: ""
        },
        {
            sy5: "ܣܶܒܠܬܳܐ",
            sy9: "",
            lt10: "seblto",
            id: "2761",
            sy4: "ܣܒܠܬܐ",
            sy6: "",
            dt3: "",
            dt1: "Leiter",
            sy8: "",
            dt2: "",
            lt11: "",
            sy7: "",
            lt12: ""
        },
        {
            dt2: "",
            sy4: "ܣܢܘܪܬܐ ",
            lt10: "sanwarto",
            lt11: "",
            id: "1957",
            dt1: "Helm",
            lt12: "",
            sy6: "",
            sy8: "",
            sy7: "",
            sy5: "ܣܰܢܘܰܪܬܳܐ ",
            sy9: "",
            dt3: ""
        },
        {
            dt1: "Karin",
            lt11: "ܟܪܝܢ",
            lt12: "",
            sy5: "ܟܰܪܺܝܢ",
            sy9: "",
            id: "2414",
            lt10: "ܟܪܝܢ",
            dt2: "",
            dt3: "",
            sy8: "",
            sy7: "",
            sy6: "",
            sy4: "ܟܪܝܢ"
        },
        {
            dt3: "",
            sy5: "ܗܺܝܝܽܘܬܰܐ",
            dt1: "Hiyuta",
            sy9: "",
            lt12: "",
            lt11: "ܗܝܝܘܬܐ",
            sy8: "",
            id: "2026",
            dt2: "",
            lt10: "ܗܝܝܘܬܐ",
            sy4: "ܗܝܝܘܬܐ",
            sy6: "",
            sy7: ""
        },
        {
            id: "1973",
            sy8: "",
            lt11: "",
            sy5: "ܠܒܽܘܫܳܐ ܕܓܰܒܪ̈ܐ",
            sy7: "",
            dt3: "",
            dt1: "Herrenanzug",
            sy6: "",
            sy4: "ܠܒܘܫܐ ܕܓܒܪܐ",
            sy9: "",
            lt10: "lbusho d`gabre",
            dt2: "",
            lt12: ""
        },
        {
            lt10: "fanera",
            dt3: "",
            sy4: "ܦܢܪܐ",
            lt11: "",
            sy5: "ܦܰܢܶܪܰܐ",
            sy7: "",
            lt12: "",
            dt1: "Trikot",
            sy9: "",
            id: "4564",
            sy8: "",
            sy6: "",
            dt2: ""
        },
        {
            sy9: "",
            sy6: "",
            dt1: "Urhay",
            sy4: "ܐܘܪܗܝ",
            sy7: "",
            sy5: "ܐܽܘܪܗܰܝ",
            lt10: "ܐܘܪܗܝ",
            sy8: "",
            dt3: "",
            dt2: "Urhai",
            lt11: "ܐܘܪܗܝ",
            lt12: "",
            id: "4684"
        },
        {
            sy7: "",
            lt11: "",
            sy9: "",
            sy4: "ܚܣܝܪܘܬ ܙܒܢܐ",
            sy5: "ܚܰܣܺܝܪܽܘܬ݂ ܙܰܒܢܳܐ",
            sy8: "",
            sy6: "",
            dt3: "",
            lt12: "",
            id: "6262",
            dt2: "",
            lt10: "ĥasiruth zabno",
            dt1: "Zeitnot"
        },
        {
            lt12: "",
            sy5: "ܗܰܘ ܕܰܠܡܰܠܟܽܘܬ݂ܶܗ ܫܽܘܠܳܡܶܐ ܠܳܐ ܐܺܝܬ݂",
            sy9: "",
            sy7: "",
            sy6: "",
            lt10: "hau dal`malkutho shulome lo ith",
            dt3: "",
            dt2: "",
            sy4: "ܗܘ ܕܠܡܠܟܘܬܗ ܫܘܠܡܐ ܠܐ ܐܝܬ",
            dt1: "Seiner Herrschaft wird kein Ende sein",
            id: "4103",
            lt11: "",
            sy8: ""
        },
        {
            sy9: "",
            lt12: "",
            sy5: "ܪܫܰܡ",
            dt1: "malen",
            sy6: "ܨܒܥ",
            dt2: "zeichnen",
            sy8: "",
            sy4: "ܪܫܡ",
            lt10: "rsham",
            sy7: "ܨܒܰܥ",
            id: "2899",
            lt11: "ŝbaĉ",
            dt3: ""
        },
        {
            sy4: "ܡܓܠܠܢܐ",
            sy6: "",
            lt11: "",
            dt1: "Ebbe und Flut",
            lt10: "mgallono",
            dt3: "",
            lt12: "",
            sy5: "ܡܓܰܠܳܠܢܳܐ",
            dt2: "",
            sy9: "",
            sy8: "",
            id: "986",
            sy7: ""
        },
        {
            dt2: "",
            id: "1488",
            lt10: "ganobo",
            dt1: "Gauner",
            sy7: "",
            sy5: "ܓܰܢܳܒܳܐ",
            sy8: "",
            dt3: "",
            sy9: "",
            lt11: "",
            sy6: "",
            sy4: "ܓܢܒܐ",
            lt12: ""
        },
        {
            sy7: "",
            dt1: "Angelegenheit",
            sy5: "ܨܒܽܘܬ݂ܳܐ",
            dt2: "Sachverhalt",
            sy6: "",
            lt11: "",
            sy9: "",
            lt10: "ŝbutho",
            lt12: "",
            sy8: "",
            dt3: "Anliegen",
            sy4: "ܨܒܘܬܐ",
            id: "5927"
        },
        {
            dt2: "",
            sy4: "ܩܛܝܪܐ",
            lt11: "",
            sy5: "ܩܰܛܺܝܪܳܐ",
            sy8: "",
            dt1: "Joghurt",
            lt10: "qațiro",
            sy9: "",
            dt3: "",
            id: "2331",
            sy7: "",
            sy6: "",
            lt12: ""
        },
        {
            dt3: "",
            sy7: "",
            sy5: "ܫܰܦܝܳܐ",
            dt1: "Schafyo",
            lt10: "ܫܦܝܐ",
            sy4: "ܫܦܝܐ",
            id: "3874",
            sy9: "",
            dt2: "",
            lt12: "",
            lt11: "ܫܦܝܐ",
            sy8: "",
            sy6: ""
        },
        {
            sy7: "",
            dt1: "Nasenloch",
            sy5: "ܒܶܙܥܳܐ ܕܢܚܺܝܪܳܐ",
            sy9: "",
            sy4: "ܒܙܥܐ ܕܢܚܝܪܐ",
            sy6: "",
            dt3: "",
            lt11: "",
            id: "3208",
            sy8: "",
            dt2: "",
            lt10: "bezĉo d`nĥiro",
            lt12: ""
        },
        {
            sy7: "",
            sy8: "",
            sy4: "ܫܒܠܐ",
            sy9: "",
            dt1: "Ähre",
            lt10: "sheblo",
            lt11: "",
            dt3: "",
            lt12: "",
            sy6: "",
            dt2: "",
            sy5: "ܫܶܒܠܳܐ",
            id: "164"
        },
        {
            sy8: "",
            sy5: "ܓܡܺܝܪܳܢ",
            sy4: "ܓܡܝܪܢ",
            sy6: "",
            lt11: "ܓܡܝܪܢ",
            dt1: "Gmiron",
            dt3: "",
            lt12: "",
            sy7: "",
            sy9: "",
            dt2: "",
            id: "1675",
            lt10: "ܓܡܝܪܢ"
        },
        {
            lt11: "",
            sy9: "",
            dt3: "",
            sy4: "ܡܕܐܬܐ",
            sy8: "",
            lt10: "madatho",
            dt1: "Steuer",
            lt12: "",
            dt2: "",
            sy5: "ܡܰܕܰܐܬ݂ܳܐ",
            sy6: "",
            sy7: "",
            id: "4265"
        },
        {
            dt1: "berühmt",
            dt2: "bekannt",
            lt12: "țbibo",
            id: "560",
            sy7: "ܝܺܕܺܝܥܳܐ",
            sy6: "ܝܕܝܥܐ",
            lt10: "shmiho",
            sy4: "ܫܡܝܗܐ",
            sy5: "ܫܡܺܝܗܳܐ",
            sy8: "ܛܒܝܒܐ",
            dt3: "",
            lt11: "iđiĉo",
            sy9: "ܛܒܺܝܒܳܐ"
        },
        {
            sy5: "ܒܰܪܣܺܝܢ",
            sy4: "ܒܪܣܝܢ",
            dt1: "Barsin",
            sy9: "",
            lt12: "",
            lt11: "ܒܪܣܝܢ",
            dt3: "",
            lt10: "ܒܪܣܝܢ",
            sy7: "",
            dt2: "",
            sy6: "",
            sy8: "",
            id: "479"
        },
        {
            lt10: "nqosh kafe",
            sy7: "",
            sy4: "ܢܩܫ ܟܦܐ",
            dt1: "Applaus",
            sy8: "",
            sy5: "ܢܩܳܫ ܟܰܦ̈ܶܐ",
            sy9: "",
            sy6: "",
            lt12: "",
            id: "264",
            dt2: "",
            lt11: "",
            dt3: ""
        },
        {
            dt2: "",
            sy6: "",
            sy7: "",
            sy4: "ܫܦܝܐ",
            sy5: "ܫܰܦܝܳܐ",
            dt1: "geradlinig",
            id: "6113",
            sy8: "",
            lt12: "",
            lt10: "shafyo",
            dt3: "",
            sy9: "",
            lt11: ""
        },
        {
            lt10: "shoĉath orĉutho",
            sy6: "",
            sy5: "ܫܳܥܰܬ݂ ܐܳܪܥܽܘܬ݂ܳܐ",
            sy8: "",
            id: "526",
            dt1: "Begegnungszeit",
            dt3: "Besprechungsstunde",
            sy7: "",
            dt2: "Verhandlungstunde",
            lt12: "",
            lt11: "",
            sy9: "",
            sy4: "ܫܥܬ ܐܪܥܘܬܐ"
        },
        {
            id: "310",
            dt3: "",
            sy6: "",
            dt1: "Asche",
            sy7: "",
            sy5: "ܩܶܛܡܳܐ",
            lt10: "qețmo",
            lt12: "",
            lt11: "",
            sy4: "ܩܛܡܐ",
            sy8: "",
            sy9: "",
            dt2: ""
        },
        {
            lt12: "",
            sy7: "",
            sy6: "",
            lt11: "",
            dt1: "zeitweilige Regierung",
            sy5: "ܩܽܘܛܢܽܘܬ݂ܳܐ ܙܰܒܢܳܢܳܝܬܳܐ",
            lt10: "quțnutho zabnonoyto",
            dt3: "",
            dt2: "",
            sy9: "",
            sy4: "ܩܘܛܢܘܬܐ ܙܒܢܢܝܬܐ",
            sy8: "",
            id: "6257"
        },
        {
            dt1: "liegen",
            sy7: "ܫܳܬܰܚ",
            sy8: "",
            sy9: "",
            lt10: "shțaĥ",
            dt2: "",
            sy6: "ܫܬܚ",
            id: "2830",
            dt3: "",
            sy5: "ܫܬܰܚ",
            sy4: "ܫܬܚ",
            lt12: "",
            lt11: "shoțaĥ"
        },
        {
            lt12: "fuțqo",
            dt2: "",
            lt10: "futqo",
            sy6: "ܒܝܬ ܒܘܬܐ",
            sy8: "ܦܘܛܩܐ",
            sy5: "ܦܽܘܬܩܳܐ",
            dt1: "Hotel",
            sy4: "ܦܘܬܩܐ",
            lt11: "beth bauto",
            id: "2057",
            dt3: "",
            sy7: "ܒܶܝܬ݂ ܒܰܘܬܳܐ",
            sy9: "ܦܽܘܛܩܳܐ"
        },
        {
            sy9: "",
            id: "3500",
            dt1: "Postpaket",
            sy6: "",
            dt3: "",
            sy7: "",
            sy8: "",
            dt2: "",
            lt12: "",
            sy5: "ܨܪܳܪܬܳܐ ܕܒܺܝܠܕܰܪܳܐ",
            lt11: "",
            lt10: "ŝrrorto d`bildaro",
            sy4: "ܨܪܪܬܐ ܕܒܝܠܕܪܐ"
        },
        {
            lt10: "fleflo",
            dt2: "",
            dt3: "",
            id: "3417",
            sy6: "",
            sy9: "",
            sy4: "ܦܠܦܠܐ",
            lt11: "",
            dt1: "Paprika",
            sy5: "ܦܠܶܦܠܳܐ",
            sy8: "",
            lt12: "",
            sy7: ""
        },
        {
            id: "1512",
            dt2: "",
            sy7: "ܝܰܩܺܝܪܳܐ",
            lt11: "yaqiro",
            sy4: "ܡܝܩܪܐ",
            dt1: "Geehrter",
            sy6: "ܝܩܝܪܐ",
            sy8: "",
            sy5: "ܡܰܝܩܪܳܐ",
            dt3: "",
            sy9: "",
            lt12: "",
            lt10: "myaqro"
        },
        {
            dt2: "",
            dt3: "",
            lt10: "shaĉuthonoyo",
            sy8: "",
            sy5: "ܫܰܥܽܘܬ݂ܳܢܳܝܳܐ",
            sy6: "",
            sy7: "",
            sy9: "",
            dt1: "blond",
            lt11: "",
            lt12: "",
            sy4: "ܫܥܘܬܢܝܐ",
            id: "652"
        },
        {
            sy9: "",
            dt3: "",
            sy8: "",
            lt11: "ܥܒܘܫܬܐ",
            sy7: "",
            lt10: "ܥܒܘܫܬܐ",
            sy4: "ܥܒܘܫܬܐ",
            dt2: "",
            id: "62",
            lt12: "",
            sy6: "",
            sy5: "ܥܰܒܽܘܫܬܰܐ",
            dt1: "Abbuschta"
        },
        {
            lt11: "ĉiđo d`fațiro",
            sy6: "ܥܐܝܕܐ ܕܦܛܝܪܐ",
            sy9: "ܝܰܘ̈ܡܳܬ݂ܳܐ ܕܦܰܬܺܝ̈ܪܶܐ",
            dt2: "",
            sy8: "ܝܘܡܬܐ ܕܦܬܝܪܐ",
            dt1: "Fest der ungesäuerten Brote",
            dt3: "",
            sy4: "ܥܝܕܐ ܕܠܚܡܐ ܦܛܝܪܐ",
            lt12: "yaumotho d`fatire",
            id: "6435",
            sy7: "ܥܐܺܝܕܳܐ ܕܦܰܛܺܝܪܳܐ",
            sy5: "ܥܺܝܕܳܐ ܕܠܰܚܡܳܐ ܦܰܛܺܝܪܳܐ",
            lt10: "ĉido d`laĥmo fațiro"
        },
        {
            lt11: "ܒܪܨܘܪ",
            sy7: "",
            sy9: "",
            sy8: "",
            sy4: "ܒܪܨܘܪ",
            lt10: "ܒܪܨܘܪ",
            lt12: "",
            dt3: "",
            dt2: "",
            dt1: "Barsur",
            sy5: "ܒܰܪܨܽܘܪ",
            sy6: "",
            id: "480"
        },
        {
            lt11: "",
            sy5: "ܩܰܙܡܳܐ",
            lt10: "qazmo",
            dt1: "Ampulle",
            dt3: "",
            sy6: "",
            lt12: "",
            id: "5881",
            dt2: "",
            sy8: "",
            sy7: "",
            sy9: "",
            sy4: "ܩܙܡܐ"
        },
        {
            sy5: "ܩܰܪ̈ܢܶܐ",
            lt12: "",
            dt1: "Hörner",
            dt2: "",
            sy7: "",
            sy8: "",
            lt11: "",
            sy4: "ܩܰܪ̈ܢܶܐ",
            sy9: "",
            lt10: "qarne",
            sy6: "",
            id: "5474",
            dt3: ""
        },
        {
            sy9: "",
            sy5: "ܘܰܙܺܝܪܳܐ ܕܡܰܪܕܽܘܬ݂ܳܐ",
            dt1: "Kulturminister",
            sy4: "ܘܙܝܪܐ ܕܡܪܕܘܬܐ",
            dt2: "",
            sy8: "",
            lt11: "",
            lt10: "waziro d`mardutho",
            lt12: "",
            sy6: "",
            sy7: "",
            dt3: "",
            id: "2666"
        },
        {
            sy9: "",
            lt11: "",
            id: "4036",
            dt1: "schüchtern",
            sy8: "",
            dt2: "",
            sy5: "ܢܰܟܽܘܦܬ݂ܳܢܳܐ",
            dt3: "",
            sy7: "",
            lt12: "",
            lt10: "nakufthono",
            sy6: "",
            sy4: "ܢܟܘܦܬܢܐ"
        },
        {
            sy9: "",
            lt11: "",
            dt2: "",
            sy8: "",
            sy5: "ܚܶܒܳܐ",
            sy7: "",
            dt1: "Gaumen",
            sy6: "",
            sy4: "ܚܒܐ",
            id: "1487",
            dt3: "",
            lt10: "ĥebo",
            lt12: ""
        },
        {
            dt1: "einsalben",
            sy5: "ܡܫܰܚ",
            dt2: "",
            id: "1046",
            sy9: "",
            sy6: "",
            sy8: "",
            lt10: "mshaĥ",
            sy4: "ܡܫܚ",
            dt3: "",
            lt11: "",
            sy7: "",
            lt12: ""
        },
        {
            sy9: "",
            sy5: "ܒܽܘܙܰܢܛܺܝܰܐ",
            id: "753",
            lt10: "busanțiya",
            dt2: "",
            sy4: "ܒܘܙܢܛܝܐ",
            sy7: "",
            lt11: "",
            sy8: "",
            sy6: "",
            lt12: "",
            dt3: "",
            dt1: "Byzanz"
        },
        {
            dt3: "",
            sy4: "ܫܛܪ",
            sy6: "",
            dt1: "abschweifen",
            sy5: "ܫܛܰܪ",
            sy7: "",
            lt10: "shțar",
            lt12: "",
            id: "5634",
            sy9: "",
            lt11: "",
            dt2: "",
            sy8: ""
        },
        {
            sy8: "",
            sy4: "ܒܕܘܟܬܐ ܐܚܪܬܐ",
            dt2: "",
            sy9: "",
            lt10: "bduktho ĥrito",
            sy7: "",
            lt11: "",
            dt1: "woanders",
            sy5: "ܒܕܽܘܟܬ݂ܳܐ ܐ̱ܚܪܺܬܳܐ",
            id: "5146",
            dt3: "",
            lt12: "",
            sy6: ""
        },
        {
            lt11: "othyo no men fulĥono",
            lt12: "",
            dt1: "Ich komme von der Arbeit",
            dt3: "",
            lt10: "othe no men fulĥuno",
            sy6: "ܐܳܬ݂ܝܳܐ ܐ̱ܢܳܐ ܡܶܢ ܦܽܘܠܚܳܢܳܐ",
            sy9: "",
            sy7: "ܐܳܬ݂ܝܳܐ ܐ̱ܢܳܐ ܡܶܢ ܦܽܘܠܚܳܢܳܐ",
            sy8: "",
            sy5: "ܐܳܬ݂ܶܐ ܐ̱ܢܳܐ ܡܶܢ ܦܽܘܠܚܳܢܳܐ",
            sy4: "ܐܳܬ݂ܶܐ ܐ̱ܢܳܐ ܡܶܢ ܦܽܘܠܚܳܢܳܐ",
            dt2: "",
            id: "5444"
        },
        {
            id: "4377",
            sy5: "ܡܠܳܐ ܒܶܢܙܺܝܢ",
            lt12: "",
            dt3: "",
            sy4: "ܡܠܐ ܒܢܙܝܢ",
            dt1: "tanken",
            lt11: "",
            sy6: "",
            sy8: "",
            lt10: "mlo benzin",
            sy7: "",
            dt2: "",
            sy9: ""
        },
        {
            sy6: "",
            sy9: "",
            sy4: "ܡܓܪܝܢܐ",
            dt1: "Anstifter",
            dt3: "",
            sy5: "ܡܓܰܪܝܳܢܳܐ",
            lt12: "",
            sy8: "",
            lt11: "",
            dt2: "",
            lt10: "mgaryono",
            id: "5998",
            sy7: ""
        },
        {
            lt11: "methdarğonutho",
            lt10: "duroğo",
            dt2: "",
            sy5: "ܕܽܘܪܳܓ݂ܳܐ",
            sy9: "",
            lt12: "",
            sy4: "ܕܘܪܓܐ",
            sy8: "",
            id: "5677",
            dt1: "Abstufung",
            sy6: "ܡܬܕܪܓܢܘܬܐ",
            sy7: "ܡܶܬ݂ܕܰܪܓ݂ܳܢܽܘܬ݂ܳܐ",
            dt3: ""
        },
        {
            sy4: "ܡܬܬܘܕܝܢܘܬܐ",
            sy9: "",
            lt12: "",
            lt10: "mettaudyonutho",
            dt2: "",
            sy6: "ܡܫܬܘܕܥܢܘܬܐ",
            dt1: "Agnition",
            sy7: "ܡܶܫܬܰܘܕܥܳܢܽܘܬ݂ܳܐ",
            dt3: "",
            lt11: "meshtaudĉonutho",
            sy8: "",
            sy5: "ܡܶܬܬܰܘܕܝܳܢܽܘܬ݂ܳܐ",
            id: "5783"
        },
        {
            lt12: "",
            dt1: "töten",
            lt10: "qțal",
            sy6: "",
            dt2: "killen",
            sy8: "",
            dt3: "",
            sy9: "",
            sy4: "ܩܛܠ",
            lt11: "",
            sy7: "",
            id: "4533",
            sy5: "ܩܛܰܠ"
        },
        {
            sy8: "",
            sy7: "",
            sy5: "ܥܶܢܕܳܐ",
            sy4: "ܥܢܕܐ",
            id: "1103",
            lt11: "ܥܢܕܐ",
            lt10: "ܥܢܕܐ",
            dt1: "Endo",
            dt3: "",
            sy6: "",
            lt12: "",
            dt2: "",
            sy9: ""
        },
        {
            sy9: "",
            lt11: "ܪܒܒܝܬܐ",
            dt2: "",
            lt12: "",
            sy5: "ܪܰܒܒܰܝܬܳܐ",
            sy4: "ܪܒܒܝܬܐ",
            dt3: "",
            sy8: "",
            dt1: "Rabbaito",
            id: "3575",
            sy6: "",
            lt10: "ܪܒܒܝܬܐ",
            sy7: ""
        },
        {
            sy5: "ܓܰܒܪܳܐ",
            lt11: "",
            id: "2915",
            dt1: "Mann",
            dt2: "",
            sy6: "",
            sy4: "ܓܒܪܐ",
            sy7: "",
            lt12: "",
            sy9: "",
            dt3: "",
            sy8: "",
            lt10: "gabro"
        },
        {
            dt2: "",
            sy9: "",
            lt12: "",
            lt10: "nakfutho",
            sy4: "ܢܟܦܘܬܐ",
            dt3: "",
            sy7: "",
            dt1: "Schüchternheit",
            lt11: "",
            sy6: "",
            id: "4037",
            sy8: "",
            sy5: "ܢܰܟܦܽܘܬ݂ܳܐ"
        },
        {
            sy8: "",
            sy4: "ܦܒܪܘܢܝܐ",
            id: "1226",
            sy5: "ܦܰܒܪܽܘܢܝܰܐ",
            sy9: "",
            sy6: "",
            lt10: "ܦܒܪܘܢܝܐ",
            sy7: "",
            dt3: "",
            dt1: "Fabronya",
            dt2: "",
            lt11: "ܦܒܪܘܢܝܐ",
            lt12: ""
        },
        {
            sy9: "",
            lt11: "",
            dt1: "Abendländer",
            lt10: "maĉrboyo",
            dt2: "",
            sy7: "",
            dt3: "",
            lt12: "",
            sy6: "",
            sy5: "ܡܰܥܪܒܳܝܳܐ",
            sy8: "",
            sy4: "ܡܥܪܒܝܐ",
            id: "5560"
        },
        {
            lt11: "maqfoso",
            id: "6028",
            sy6: "ܡܩܦܣܐ",
            sy4: "ܩܦܣܐ",
            dt1: "Hüfte",
            lt10: "qefso",
            sy5: "ܩܶܦܣܳܐ",
            dt3: "",
            lt12: "rebukitho",
            sy9: "ܪܶܒܽܘܟܺܝܬ݂ܳܐ",
            sy7: "ܡܰܩܦܳܣܳܐ",
            dt2: "",
            sy8: "ܪܒܘܟܝܬܐ"
        },
        {
            sy7: "ܙܰܕܺܝܩܳܐ",
            dt1: "fromm",
            dt2: "gläubig",
            dt3: "",
            id: "1408",
            sy9: "",
            lt12: "",
            sy5: "ܡܗܰܝܡܢܳܐ",
            lt11: "zadiqo",
            lt10: "mhaymno",
            sy4: "ܡܗܝܡܢܐ",
            sy8: "",
            sy6: "ܙܕܝܩܐ"
        },
        {
            dt1: "Tehrat",
            sy5: "ܬܶܗܪܰܬ",
            id: "4420",
            sy8: "",
            sy9: "",
            sy7: "",
            lt11: "ܬܗܪܬ",
            sy4: "ܬܗܪܬ",
            lt10: "ܬܗܪܬ",
            lt12: "",
            sy6: "",
            dt3: "",
            dt2: ""
        },
        {
            sy5: "ܫܰܠܘܳܐ",
            lt12: "",
            dt2: "",
            sy6: "",
            sy7: "",
            dt3: "",
            id: "4370",
            sy8: "",
            sy9: "",
            lt11: "",
            dt1: "Tal",
            lt10: "shalwo",
            sy4: "ܫܠܘܐ"
        },
        {
            dt3: "",
            id: "5106",
            dt2: "",
            sy5: "ܟܡܳܐ ܝܰܘ̈ܡܶܐ",
            sy4: "ܟܡܐ ܝܘܡܐ",
            sy9: "",
            lt12: "",
            sy8: "",
            sy6: "",
            lt11: "",
            dt1: "Wieviele Tage",
            sy7: "",
            lt10: "kmo yauwme"
        },
        {
            sy4: "ܪܒܝܐܠ",
            lt10: "ܪܒܝܐܠ",
            sy6: "ܪܘܒܝܠ",
            dt3: "",
            sy7: "ܪܳܘܒܺܝܠ",
            sy5: "ܪܒܝܠ",
            id: "3733",
            lt11: "ܪܒܝܐܠ",
            lt12: "ܪܘܒܝܠ",
            dt2: "Rubil",
            sy9: "",
            dt1: "Robil",
            sy8: ""
        },
        {
            lt11: "ܫܡܥܘܢ",
            sy4: "ܫܡܥܘܢ",
            sy9: "",
            sy7: "",
            id: "3924",
            sy8: "",
            dt1: "Schemun",
            lt12: "",
            lt10: "ܫܡܥܘܢ",
            dt3: "",
            sy6: "",
            sy5: "ܫܶܡܥܽܘܢ",
            dt2: ""
        },
        {
            dt2: "Epiphanie",
            id: "1170",
            sy9: "",
            sy6: "",
            dt1: "Erscheinung des Herrn",
            lt11: "",
            sy5: "ܕܶܢܚܶܗ ܕܡܳܪܰܢ",
            lt10: "denĥe d`moran",
            dt3: "",
            sy4: "ܕܢܚܗ ܕܡܪܢ",
            sy8: "",
            sy7: "",
            lt12: ""
        },
        {
            dt1: "Liebe Deinen Nächsten wie Dich selbst",
            sy4: "ܪܚܡ ܠܫܒܒܟ ܐܟ ܢܦܫܟ",
            id: "2808",
            sy5: "ܪܚܰܡ ܠܰܫܒܳܒܳܟ݂ ܐܰܝܟ݂ ܢܰܦܫܳܟ݂",
            sy7: "ܪܚܰܡ ܠܽܘ ܩܰܪܺܝܘܶܝܕܳܟ݂ ܟ݂ܘܽܕ ܢܰܦܫܳܟ݂",
            dt3: "",
            lt12: "",
            dt2: "",
            sy8: "",
            sy6: "ܪܚܡ ܠܘ ܩܪܝܘܝܕܟ ܟܘܕ ܢܦܫܟ",
            lt11: "rĥam lu qariweyđukh khud nafshokh",
            sy9: "",
            lt10: "rĥam lashbobokh akh nafshokh"
        },
        {
            sy5: "ܒܽܘܣܳܡ ܪܺܝܫܳܐ",
            dt3: "",
            sy9: "",
            dt2: "",
            sy7: "",
            lt11: "",
            dt1: "Kondolenz",
            lt10: "busom risho",
            sy6: "",
            sy8: "",
            sy4: "ܒܘܣܡ ܪܝܫܐ",
            lt12: "",
            id: "6077"
        },
        {
            sy4: "ܡܚܛܐ",
            sy9: "",
            sy5: "ܡܚܰܛܳܐ",
            dt1: "Nadel",
            lt11: "",
            lt10: "mĥațo",
            sy7: "",
            sy8: "",
            dt2: "",
            sy6: "",
            lt12: "",
            id: "3173",
            dt3: ""
        },
        {
            sy8: "ܐܬܘܪܝܐ",
            sy7: "ܐܳܪܳܡܳܝܳܐ",
            lt11: "oromoyo ",
            id: "4323",
            sy5: "ܣܽܘܪܝܳܝܳܐ",
            lt10: "suryoyo",
            dt3: "",
            lt12: "othuroyo ",
            sy4: "ܣܘܪܝܝܐ ",
            dt2: "Suroyo",
            sy9: "ܐܳܬ݂ܽܘܪܳܝܳܐ",
            dt1: "Suryoyo",
            sy6: "ܐܪܡܝܐ"
        },
        {
            id: "6115",
            sy8: "",
            lt10: "ĥakimo",
            dt3: "",
            sy6: "",
            lt12: "",
            sy4: "ܚܟܝܡܐ",
            sy9: "",
            dt1: "genial",
            lt11: "",
            dt2: "",
            sy7: "",
            sy5: "ܚܰܟܺܝܡܳܐ"
        },
        {
            dt3: "",
            lt10: "ܐܝܙܪܥܠ",
            dt1: "Jesreel",
            sy4: "ܐܝܙܪܥܠ",
            sy6: "",
            sy9: "",
            lt11: "ܐܝܙܪܥܠ",
            sy7: "",
            id: "2316",
            sy8: "",
            lt12: "",
            dt2: "",
            sy5: "ܐܺܝܙܰܪܥܶܠ"
        },
        {
            lt10: "shemĉoyo",
            id: "6045",
            sy7: "",
            sy8: "",
            dt1: "hörig",
            lt12: "",
            sy5: "ܫܶܡܥܳܝܳܐ",
            dt3: "",
            lt11: "",
            sy6: "",
            dt2: "",
            sy9: "",
            sy4: "ܫܡܥܝܐ"
        },
        {
            dt2: "",
            dt1: "Jesus",
            sy6: "",
            sy7: "",
            lt11: "",
            id: "2318",
            sy8: "",
            lt12: "",
            sy4: "ܝܫܘܥ",
            lt10: "yeshuĉ",
            sy9: "",
            sy5: "ܝܶܫܘܽܥ",
            dt3: ""
        },
        {
            sy9: "",
            dt1: "Abo",
            lt12: "",
            sy4: "ܐܒܐ",
            sy8: "",
            id: "105",
            lt11: "ܐܒܐ",
            sy5: "ܐܰܒܳܐ",
            sy7: "",
            dt2: "",
            sy6: "",
            dt3: "",
            lt10: "ܐܒܐ"
        },
        {
            dt3: "",
            lt12: "",
            sy9: "",
            dt1: "Guthaben",
            id: "1778",
            sy6: "",
            sy7: "",
            lt11: "",
            sy8: "",
            sy5: "ܫܟܳܢܳܐ",
            lt10: "shkono",
            sy4: "ܫܟܢܐ",
            dt2: ""
        },
        {
            dt2: "",
            sy4: "ܚܢܢ ܐܝܬ ܠܢ ܥܢܒܐ",
            id: "5122",
            dt1: "Wir haben Weintrauben",
            sy5: "ܚܢܰܢ ܐܺܝܬ݂ ܠܰܢ ܥܶܢܒ̈ܶܐ",
            sy8: "",
            dt3: "",
            lt12: "",
            sy9: "",
            lt10: "ĥnan ith lan ĉenbe",
            sy6: "",
            sy7: "",
            lt11: ""
        },
        {
            id: "3997",
            sy8: "",
            dt2: "",
            lt11: "",
            sy6: "",
            sy7: "",
            lt12: "",
            sy9: "",
            lt10: "ĥayoțo",
            dt1: "Schneider",
            dt3: "",
            sy5: "ܚܰܝܳܛܳܐ",
            sy4: "ܚܝܛܐ"
        },
        {
            sy4: "ܐܘܪܝܬܐ",
            dt2: "",
            id: "4692",
            sy7: "",
            sy8: "",
            sy6: "",
            lt10: "ܐܘܪܝܬܐ",
            lt12: "",
            dt1: "Urita",
            sy5: "ܐܽܘܪܺܝܬܰܐ",
            lt11: "ܐܘܪܝܬܐ",
            dt3: "",
            sy9: ""
        },
        {
            sy9: "",
            lt11: "",
            sy8: "",
            dt2: "",
            sy7: "",
            id: "2785",
            lt12: "",
            dt1: "letzter geworden",
            lt10: "hwo ĥroyo",
            sy5: "ܗܘܳܐ ܐ̱ܚܪܳܝܳܐ",
            dt3: "",
            sy6: "",
            sy4: "ܗܘܐ ܐܚܪܝܐ"
        },
        {
            lt12: "",
            sy4: "ܦܬܓܡܐ",
            lt10: "fetğomo",
            sy5: "ܦܶܬܓ݂ܳܡܳܐ",
            id: "3854",
            sy7: "",
            sy9: "",
            dt1: "Satz",
            sy6: "",
            sy8: "",
            dt2: "",
            dt3: "",
            lt11: ""
        },
        {
            sy8: "",
            lt10: "arikho",
            lt11: "",
            lt12: "",
            dt2: "",
            sy9: "",
            dt1: "lang",
            sy5: "ܐܰܪܺܝܟ݂ܳܐ",
            sy6: "",
            id: "2704",
            sy7: "",
            sy4: "ܐܪܝܟܐ",
            dt3: ""
        },
        {
            sy8: "",
            sy9: "",
            sy5: "ܩܽܘܕܫ̈ܶܐ ܠܩܰܕܺܝܫ̈ܶܐ ܘܠܕܟ݂ܰܝ̈ܶܐ",
            sy7: "",
            dt3: "",
            lt12: "",
            dt1: "Das Heilige den Heiligen und Reinen",
            sy6: "",
            sy4: "ܩܘܕܫܐ ܠܩܕܝܫܐ ܘܠܕܟܝܐ",
            lt10: "qudshe l`qadishe u la`dkhayo",
            dt2: "",
            id: "817",
            lt11: ""
        },
        {
            sy5: "ܐܶܫܰܥܝܳܐ",
            dt2: "",
            dt1: "Jesaja",
            sy9: "",
            sy7: "ܐܶܫܰܥܝܰܐ",
            lt11: "ܐܫܥܐ",
            dt3: "",
            sy6: "ܐܫܥܐ",
            id: "2308",
            lt12: "ܐܫܥܝܐ",
            lt10: "ܐܫܥܐ",
            sy4: "ܐܫܥܝܐ",
            sy8: ""
        },
        {
            sy4: "ܡܕܢܚܐ",
            sy8: "",
            sy5: "ܡܰܕܢܚܳܐ   ",
            sy9: "",
            dt3: "",
            lt11: "",
            lt10: "madnĥo",
            lt12: "",
            sy7: "",
            id: "3399",
            sy6: "",
            dt2: "Ost",
            dt1: "Osten"
        },
        {
            dt2: "Sleman",
            sy7: "",
            dt3: "",
            lt10: "ܣܠܝܡܐܢ",
            sy9: "",
            lt12: "",
            sy4: "ܣܠܝܡܐܢ",
            sy5: "ܣܠܶܝܡܐܰܢ",
            dt1: "Suleyman",
            sy8: "",
            id: "4310",
            lt11: "ܣܠܝܡܐܢ",
            sy6: ""
        },
        {
            dt1: "Mokso",
            lt11: "ܡܟܣܐ",
            sy8: "",
            sy6: "",
            sy9: "",
            sy4: "ܡܟܣܐ",
            dt3: "",
            dt2: "",
            lt10: "ܡܟܣܐ",
            sy7: "",
            lt12: "",
            id: "3077",
            sy5: "ܡܳܟܣܳܐ"
        },
        {
            sy5: "ܣܝܳܡ ܣܺܝܩܽܘܡܳܐ",
            lt12: "",
            id: "826",
            sy8: "",
            sy4: "ܣܝܡ ܣܝܩܘܡܐ",
            sy6: "",
            lt11: "",
            sy9: "",
            lt10: "syom siqumo",
            dt3: "",
            dt1: "Datierung",
            dt2: "",
            sy7: ""
        },
        {
            dt2: "Adday",
            id: "126",
            lt12: "",
            dt1: "Adai",
            sy9: "",
            lt11: "ܐܕܝ",
            sy4: "ܐܕܝ",
            dt3: "Addai",
            sy8: "",
            sy5: "ܐܰܕܰܝ",
            sy6: "",
            lt10: "ܐܕܝ",
            sy7: ""
        },
        {
            dt2: "",
            sy5: "ܙܰܕܺܝܩܳܐ",
            sy8: "",
            lt11: "",
            sy9: "",
            sy7: "",
            lt12: "",
            dt1: "Gerechter",
            sy4: "ܙܕܝܩܐ",
            id: "1570",
            dt3: "",
            lt10: "zadiqo",
            sy6: ""
        },
        {
            id: "JSC71L8Bsxja07wGU4G8",
            "date": {
                "seconds": 1590847984,
                "nanoseconds": 622000000
            },
            lt10: "an emothe",
            sy7: "",
            dt3: "",
            dt2: "",
            sy4: "ܐܢ ܐܡܬܐ",
            dt1: "Die Mütter",
            lt12: "",
            sy8: "",
            lt11: "",
            sy5: "ܐܰܢ ܐܶܡܳܬ݂ܶܐ",
            sy6: "",
            sy9: ""
        },
        {
            sy7: "",
            lt12: "",
            sy9: "",
            lt11: "ܚܠܦܝ",
            sy8: "",
            id: "1830",
            dt3: "",
            dt2: "",
            sy4: "ܚܠܦܝ",
            sy5: "ܚܰܠܦܰܝ",
            dt1: "Halfai",
            sy6: "",
            lt10: "ܚܠܦܝ"
        },
        {
            sy8: "",
            sy4: "ܥܘܪܒܐ",
            lt12: "",
            id: "3577",
            sy9: "",
            dt1: "Rabe",
            sy5: "ܥܽܘܪܒܳܐ",
            sy7: "",
            dt2: "Schwarzer Rabe",
            sy6: "",
            lt11: "",
            lt10: "ĉurbo",
            dt3: ""
        },
        {
            dt1: "Suros",
            sy6: "",
            sy9: "",
            lt10: "ܣܘܪܘܣ",
            lt12: "",
            id: "4321",
            dt2: "",
            sy8: "",
            dt3: "",
            sy5: "ܣܽܘܪܳܘܣ",
            lt11: "ܣܘܪܘܣ",
            sy7: "",
            sy4: "ܣܘܪܘܣ"
        },
        {
            sy6: "",
            lt10: "more ĥushbono",
            dt3: "",
            sy9: "",
            sy4: "ܡܪܐ ܚܘܫܒܢܐ",
            sy7: "",
            dt1: "Kontoinhaber",
            id: "2594",
            dt2: "",
            sy5: "ܡܳܪܳܐ ܚܽܘܫܒܳܢܳܐ",
            lt12: "",
            sy8: "",
            lt11: ""
        },
        {
            sy4: "ܫܡܝܐ ܙܪܩܐ",
            dt2: "",
            sy5: "ܫܡܰܝܳܐ ܙܰܪܩܳܐ",
            lt12: "",
            sy7: "",
            lt11: "",
            id: "645",
            sy8: "",
            lt10: "shmayo zarqo",
            dt1: "blauer Himmel",
            dt3: "",
            sy6: "",
            sy9: ""
        },
        {
            sy4: "ܫܘܚܠܦܐ",
            id: "5545",
            dt2: "",
            dt1: "Abänderung",
            sy6: "ܫܘܓܢܝܐ",
            sy5: "ܫܽܘܚܠܳܦܳܐ",
            sy9: "",
            lt11: "shuğnoyo",
            sy8: "",
            dt3: "",
            lt10: "shuĥlofo",
            lt12: "",
            sy7: "ܫܽܘܓ݂ܢܳܝܳܐ"
        },
        {
            lt12: "",
            dt3: "",
            id: "4030",
            lt10: "faudo d`kthibto",
            sy9: "",
            sy7: "",
            sy5: "ܦܰܘܕܳܐ ܕܟܬ݂ܺܝܒܬܳܐ",
            sy4: "ܦܘܕܐ ܕܟܬܝܒܬܐ",
            sy6: "",
            sy8: "",
            dt1: "Schreibfehler",
            lt11: "",
            dt2: "Rechtschreibfehler"
        },
        {
            sy4: "ܣܘܦܝܐ",
            sy7: "",
            lt10: "ܣܘܦܝܐ",
            dt2: "Sofia",
            id: "4199",
            lt11: "ܣܘܦܝܐ",
            sy5: "ܣܳܘܦܺܝܰܐ",
            lt12: "",
            sy6: "",
            sy9: "",
            dt1: "Sophia",
            dt3: "",
            sy8: ""
        },
        {
            sy6: "",
            sy8: "",
            lt10: "mdarshono",
            dt1: "Trainer",
            dt2: "Übungsleiter",
            sy9: "",
            sy5: "ܡܕܰܪܫܳܢܳܐ",
            id: "4537",
            sy7: "",
            lt11: "",
            sy4: "ܡܕܪܫܢܐ",
            lt12: "",
            dt3: ""
        },
        {
            sy6: "",
            sy7: "",
            dt3: "",
            dt2: "",
            sy9: "",
            id: "1646",
            sy4: "ܓܝܘܠܐ",
            dt1: "Giyula",
            lt12: "",
            sy5: "ܓܺܝܽܘܠܰܐ",
            sy8: "",
            lt10: "ܓܝܘܠܐ",
            lt11: "ܓܝܘܠܐ"
        },
        {
            sy9: "",
            dt2: "Gelächter",
            sy6: "",
            sy5: "ܡܓܰܚܟ݂ܳܢܽܘܬ݂ܳܐ",
            dt3: "",
            id: "2562",
            sy4: "ܡܓܚܟܢܘܬܐ",
            lt11: "",
            dt1: "Komik",
            lt10: "magĥkhonutho",
            sy7: "",
            sy8: "",
            lt12: ""
        },
        {
            sy5: "ܕܶܩܠܰܬ݂",
            lt10: "ܕܩܠܬ݂",
            sy7: "",
            sy8: "",
            dt1: "Deqlath",
            sy6: "",
            dt2: "Deqlat",
            lt12: "",
            lt11: "ܕܩܠܬ݂",
            id: "863",
            sy9: "",
            dt3: "",
            sy4: "ܕܩܠܬ݂"
        },
        {
            sy5: "ܡܠܰܒܕܳܢܽܘܬ݂ܳܐ",
            sy8: "ܩܘܛܪܐ",
            sy9: "ܩܽܘܛܳܪܳܐ",
            sy7: "ܩܛܺܝܪܽܘܬ݂ܳܐ",
            lt11: "qțirutho",
            dt2: "",
            sy4: "ܡܠܒܕܢܘܬܐ",
            dt1: "Kondensation",
            dt3: "",
            lt10: "mlabdonutho",
            id: "6079",
            lt12: "quțoro",
            sy6: "ܩܛܝܪܘܬܐ"
        },
        {
            sy6: "",
            lt12: "",
            sy9: "",
            lt10: "tkholo",
            sy8: "",
            sy7: "",
            id: "6109",
            dt3: "",
            dt1: "Fruchtlosigkeit",
            lt11: "",
            dt2: "",
            sy4: "ܬܟܠܐ",
            sy5: "ܬܟ݂ܳܠܳܐ"
        },
        {
            lt11: "",
            sy4: "ܡܘܣܐ",
            sy6: "",
            sy5: "ܡܽܘܣܳܐ",
            dt3: "",
            dt2: "",
            sy8: "",
            id: "6120",
            dt1: "hartnäckiger Mensch",
            lt12: "",
            sy7: "",
            sy9: "",
            lt10: "muso"
        },
        {
            sy9: "",
            lt10: "qul",
            sy8: "",
            lt12: "",
            id: "5583",
            dt1: "Ablagerung",
            sy7: "ܫܽܘܟܬ݂ܳܐ",
            sy5: "ܩܽܘܠ",
            sy4: "ܩܘܠ",
            dt2: "",
            lt11: "shuktho",
            sy6: "ܫܘܟܬܐ",
            dt3: ""
        },
        {
            sy4: "ܨܒܬ",
            sy5: "ܨܰܒܶܬ݂",
            lt11: "shafar",
            sy6: "ܫܦܪ",
            sy7: "ܫܰܦܰܪ",
            sy9: "ܡܨܰܒܶܬ݂",
            dt2: "",
            id: "3985",
            lt10: "ŝabeth",
            sy8: "ܡܨܒܬ",
            dt1: "schmücken",
            dt3: "",
            lt12: "mŝabeth"
        },
        {
            sy6: "",
            sy8: "",
            lt12: "",
            sy9: "",
            dt3: "",
            id: "4955",
            dt2: "",
            sy4: "ܚܒܪܐ ܫܪܝܪܐ",
            sy7: "",
            lt11: "",
            lt10: "ĥabro shariro",
            sy5: "ܚܰܒܪܳܐ ܫܰܪܺܝܪܳܐ",
            dt1: "wahrer Freund"
        },
        {
            dt3: "",
            lt12: "",
            dt2: "",
            sy5: "ܡܫܰܕܠܳܢܳܐ",
            dt1: "intrigant",
            sy7: "",
            sy9: "",
            id: "6176",
            lt10: "mshadlono",
            sy4: "ܡܫܕܠܢܐ",
            sy6: "",
            lt11: "",
            sy8: ""
        },
        {
            sy4: "ܛܘܪܝ",
            sy8: "",
            id: "4598",
            dt1: "Turi",
            dt3: "",
            lt12: "",
            lt11: "ܛܘܪܝ",
            sy9: "",
            sy7: "",
            lt10: "ܛܘܪܝ",
            sy6: "",
            dt2: "",
            sy5: "ܛܽܘܪܺܝ"
        },
        {
            lt10: "ĥekhmo gensonoyo",
            sy6: "",
            sy7: "",
            lt12: "",
            sy5: "ܚܶܟ݂ܡܳܐ ܓܶܢܣܳܢܳܝܳܐ",
            dt2: "Sexualverkehr",
            sy4: "ܚܟܡܐ ܓܢܣܢܝܐ",
            id: "1585",
            sy9: "",
            dt3: "",
            lt11: "",
            sy8: "",
            dt1: "Geschlechtsverkehr"
        },
        {
            dt2: "Lobpreis",
            lt10: "shubĥo",
            sy7: "ܬܶܫܒܽܘܚܬܳܐ",
            id: "1974",
            sy8: "",
            lt11: "teshbuĥto",
            sy5: "ܫܽܘܒܚܳܐ",
            sy4: "ܫܘܒܚܐ",
            sy6: "ܬܫܒܘܚܬܐ",
            lt12: "",
            dt3: "",
            sy9: "",
            dt1: "Herrlichkeit"
        },
        {
            dt1: "Maacha",
            sy7: "ܡܰܥܟܳܐ",
            lt11: "ܡܥܟܐ",
            dt2: "",
            dt3: "",
            id: "2880",
            lt12: "ܡܥܟܐ",
            sy6: "ܡܥܟܐ",
            sy5: "ܡܰܥܟܰܐ",
            lt10: "ܡܥܟܐ",
            sy9: "",
            sy4: "ܡܥܟܐ",
            sy8: ""
        },
        {
            lt12: "",
            sy8: "",
            sy6: "",
            dt3: "",
            lt10: "ba'mŝaĉtho",
            sy7: "",
            sy9: "",
            dt1: "mittendrin",
            sy4: "ܒܡܨܥܬܐ",
            sy5: "ܒܰܡܨܰܥܬ݂ܳܐ",
            lt11: "",
            dt2: "",
            id: "6371"
        },
        {
            lt10: "nshoqo",
            dt3: "Küsschen",
            sy6: "ܢܘܫܩܬܐ",
            sy9: "",
            dt2: "Bussi",
            dt1: "Kuss",
            lt12: "",
            sy5: "ܢܫܳܩܳܐ",
            sy4: "ܢܫܩܐ",
            sy7: "ܢܽܘܫܰܩܬܳܐ",
            id: "2679",
            sy8: "",
            lt11: "nushaqtho"
        },
        {
            lt11: "",
            lt12: "",
            id: "5729",
            sy9: "",
            dt1: "Achtel",
            dt3: "",
            sy6: "",
            sy5: "ܬܽܘܡܢܳܐ",
            dt2: "",
            sy8: "",
            sy7: "",
            sy4: "ܬܘܡܢܐ",
            lt10: "tumno"
        },
        {
            sy5: "ܒܟ݂ܺܝܪܳܝܳܐ",
            lt11: "",
            dt2: "",
            lt12: "",
            sy8: "",
            lt10: "bkhiroyo",
            sy7: "",
            sy6: "",
            id: "1421",
            dt1: "frühzeitig",
            dt3: "",
            sy4: "ܒܟܝܪܝܐ",
            sy9: ""
        },
        {
            lt12: "",
            sy8: "",
            dt2: "",
            id: "5437",
            sy6: "",
            sy9: "",
            lt10: "at ithayk d'ܽțob men kul",
            sy4: "ܐܰܢ̱ܬܝ ܐܽܝܬ݂ܰܝܟܝ̱ ܕܛܳܒ ܡܶܢ ܟܽܘܠ",
            dt1: "Du bist die Beste",
            sy5: "ܐܰܢ̱ܬܝ ܐܽܝܬ݂ܰܝܟܝ̱ ܕܛܳܒ ܡܶܢ ܟܽܘܠ",
            dt3: "",
            lt11: "",
            sy7: ""
        },
        {
            sy5: "ܐܰܘܒܶܕ ܟܽܠ ܡܶܕܶܡ",
            sy7: "",
            sy8: "",
            sy4: "ܐܘܒܕ ܟܠ ܡܕܡ",
            id: "5843",
            lt10: "aubed kul medem",
            lt12: "",
            sy6: "",
            dt3: "",
            dt2: "",
            lt11: "",
            sy9: "",
            dt1: "alles verlieren"
        },
        {
            sy9: "ܠܚܳܡܳܐ",
            sy8: "ܠܚܡܐ",
            dt1: "Integration",
            sy6: "ܠܚܘܡܬܐ",
            sy7: "ܠܳܚܽܘܡܽܘܬ݂ܳܐ",
            lt11: "loĥumutho",
            dt3: "Flexibilität",
            lt12: "lĥomo",
            id: "2218",
            dt2: "Anpassung",
            sy5: "ܡܶܬ݂ܠܰܚܡܳܢܽܘܬ݂ܳܐ",
            sy4: "ܡܬܠܚܡܢܘܬܐ",
            lt10: "methlaĥmonutho"
        },
        {
            sy8: "",
            sy9: "",
            dt2: "",
            id: "3094",
            lt12: "",
            sy7: "",
            sy5: "ܡܳܪܝ̱ ܝܽܘܠܝܽܘܣ ܝܶܫܽܘܥ ܔܺܝܔܶܟ",
            lt10: "ܡܪܝ ܝܘܠܝܘܣ ܝܫܘܥ ܓܝܓܟ",
            dt1: "Mor Julius Yeshu Cicek",
            dt3: "",
            sy6: "",
            lt11: "ܡܪܝ ܝܘܠܝܘܣ ܝܫܘܥ ܓܝܓܟ",
            sy4: "ܡܪܝ ܝܘܠܝܘܣ ܝܫܘܥ ܓܝܓܟ"
        },
        {
            sy8: "",
            id: "4862",
            lt10: "reqđo ĉamoyo",
            dt2: "",
            sy9: "",
            lt12: "",
            sy6: "",
            dt1: "Volkstanz",
            sy7: "",
            dt3: "",
            sy4: "ܪܩܕܐ ܥܡܝܐ",
            lt11: "",
            sy5: "ܪܶܩܕܳܐ ܥܰܡܳܝܳܐ"
        },
        {
            sy8: "",
            lt10: "zamziqo",
            sy5: "ܙܰܡܙܺܝܩܳܐ",
            lt12: "",
            id: "2022",
            dt2: "Schalmei",
            sy9: "",
            dt3: "",
            sy7: "",
            sy4: "ܙܡܙܝܩܐ",
            sy6: "",
            lt11: "",
            dt1: "Hirtenflöte"
        },
        {
            lt10: "gelyunoyutho",
            sy9: "",
            sy6: "",
            sy5: "ܓܶܠܝܽܘܢܳܝܽܘܬ݂ܳܐ",
            lt11: "",
            sy4: "ܓܠܝܘܢܝܘܬܐ",
            sy8: "",
            dt1: "Zeitungswesen",
            dt2: "",
            sy7: "",
            dt3: "",
            id: "6252",
            lt12: ""
        },
        {
            lt12: "",
            lt10: "zebno",
            sy7: "ܫܩܳܠܰܐ",
            id: "2436",
            sy4: "ܙܒܢܐ",
            sy9: "",
            dt3: "",
            sy8: "",
            sy5: "ܙܶܒܢܳܐ",
            dt2: "",
            lt11: "shqolo",
            dt1: "Kaufen",
            sy6: "ܫܩܠܐ"
        },
        {
            sy5: "ܚܽܘܠܡܳܢܳܐ",
            id: "1605",
            lt11: "",
            sy7: "",
            sy8: "",
            lt12: "",
            sy9: "",
            dt3: "Gensesung",
            dt1: "Gesundheit",
            sy4: "ܚܘܠܡܢܐ",
            lt10: "ĥulmono",
            sy6: "",
            dt2: "Heilung"
        },
        {
            sy7: "",
            dt3: "flexibel",
            dt1: "integrieren",
            lt11: "",
            sy6: "",
            sy9: "",
            sy4: "ܡܬܠܚܡܢܐ",
            id: "2220",
            dt2: "anpassen",
            sy8: "",
            sy5: "ܡܶܬ݂ܠܰܚܡܳܢܳܐ",
            lt10: "methlaĥmono",
            lt12: ""
        },
        {
            sy9: "",
            sy6: "",
            lt12: "",
            sy5: "ܚܽܘܠܝܳܐ",
            dt1: "Torte",
            sy8: "",
            id: "4530",
            sy4: "ܚܘܠܝܐ",
            sy7: "",
            dt3: "",
            lt10: "ĥulyo",
            lt11: "",
            dt2: ""
        },
        {
            id: "4172",
            lt11: "ܨܠܝܒܙܟ݂ܐ",
            lt12: "ܨܠܝܒܐ ܙܟ݂ܐ",
            sy9: "",
            dt1: "Slibozkho",
            dt2: "",
            sy7: "ܨܠܺܝܒܳܐ ܙܟ݂ܳܐ",
            sy6: "ܨܠܝܒܐ ܙܟܐ",
            lt10: "ܨܠܝܒܙܟ݂ܐ",
            dt3: "",
            sy4: "ܨܠܝܒܙܟ݂ܐ",
            sy5: "ܨܠܺܝܒܳܙܟ݂ܳܐ",
            sy8: ""
        },
        {
            dt1: "still",
            lt12: "",
            lt10: "shalyo",
            sy6: "",
            id: "4272",
            sy7: "",
            lt11: "",
            dt3: "",
            sy5: "ܫܰܠܝܳܐ",
            sy8: "",
            sy4: "ܫܠܝܐ",
            dt2: "",
            sy9: ""
        },
        {
            sy7: "ܝܶܫܽܘܥ ܡܫܺܝܚܳܐ ܘ ܡܳܪܰܝܕܺܝ",
            id: "2321",
            sy8: "",
            lt11: "yeshuĉ mshiĥo u moreyđi",
            sy9: "",
            sy4: "ܝܫܘܥ ܡܫܝܚܐ ܐܝܬܗܘܝ ܡܪܝ",
            dt1: "Jesus Christus ist mein Herr",
            lt12: "",
            sy5: "ܝܶܫܽܘܥ ܡܫܺܝܚܳܐ ܐܺܝܬ݂ܰܗܘܝ ܡܳܪܝ̱",
            dt2: "",
            sy6: "ܝܫܘܥ ܡܫܝܚܐ ܘ ܡܪܝܕܝ",
            dt3: "",
            lt10: "yeshuĉ mshiĥo ithau mor"
        },
        {
            sy6: "",
            id: "1664",
            sy8: "",
            lt12: "",
            sy5: "ܚܰܒܠܳܐ ܕܢܳܩܽܘܫܳܐ",
            dt2: "",
            sy9: "",
            sy7: "",
            sy4: "ܚܒܠܐ ܕܢܩܘܫܐ",
            dt3: "",
            lt11: "",
            dt1: "Glockenseil",
            lt10: "ĥablo d`noqusho"
        },
        {
            lt11: "",
            dt1: "Schaukelstuhl",
            id: "3913",
            sy8: "",
            sy7: "",
            sy5: "ܟܽܘܪܣܝܳܐ ܡܶܬ݂ܕܰܥܙܳܢܳܐ",
            lt10: "kursyo methdaĉzono",
            sy6: "",
            lt12: "",
            dt3: "",
            sy4: "ܟܘܪܣܝܐ ܡܬܕܥܙܢܐ",
            dt2: "",
            sy9: ""
        },
        {
            lt10: "ܚܙܢܝ",
            sy9: "",
            dt3: "",
            id: "1914",
            lt11: "ܚܙܢܝ",
            sy4: "ܚܙܢܝ",
            lt12: "",
            sy5: "ܚܰܙܢܺܝ",
            dt1: "Hazni",
            sy6: "",
            sy8: "",
            sy7: "",
            dt2: ""
        },
        {
            sy4: "ܩܪܝܐ",
            sy6: "",
            lt11: "",
            dt3: "",
            sy7: "",
            lt10: "qroyo",
            sy8: "",
            dt2: "",
            id: "2768",
            lt12: "",
            dt1: "Lesen",
            sy5: "ܩܪܳܝܳܐ",
            sy9: ""
        },
        {
            sy5: "ܢܳܥܡܰܢ",
            sy9: "",
            lt11: "ܢܥܡܢ",
            dt1: "Numan",
            dt2: "Neman",
            dt3: "Nouman",
            sy6: "",
            sy7: "",
            sy8: "",
            sy4: "ܢܥܡܢ",
            lt10: "ܢܥܡܢ",
            lt12: "",
            id: "3311"
        },
        {
            sy9: "",
            dt3: "",
            lt12: "",
            lt11: "shalfo",
            sy7: "ܫܰܠܦܳܐ",
            sy6: "ܫܠܦܐ",
            dt1: "Messer",
            id: "3009",
            sy4: "ܣܟܝܢܐ",
            lt10: "sakino",
            dt2: "Taschenmesser",
            sy5: "ܣܰܟܺܝܢܳܐ",
            sy8: ""
        },
        {
            lt10: "mdabrono",
            dt2: "Organisator",
            id: "2760",
            lt11: "",
            dt3: "",
            lt12: "",
            dt1: "Leiter",
            sy6: "",
            sy8: "",
            sy9: "",
            sy7: "",
            sy5: "ܡܕܰܒܪܳܢܳܐ",
            sy4: "ܡܕܒܪܢܐ"
        },
        {
            sy6: "ܛܒܝܐ",
            id: "4352",
            lt10: "ܛܒܝܐ",
            lt11: "ܛܒܝܐ",
            dt1: "Tabia",
            lt12: "ܛܒܝܐ",
            dt2: "Tabea",
            sy8: "",
            dt3: "",
            sy7: "ܛܰܒܝܰܐ",
            sy5: "ܛܰܒܺܝܰܐ",
            sy4: "ܛܒܝܐ",
            sy9: ""
        },
        {
            lt10: "ܢܦܛܝܪ",
            dt1: "Naftir",
            sy7: "",
            sy4: "ܢܦܛܝܪ",
            sy5: "ܢܰܦܛܺܝܪ",
            lt11: "ܢܦܛܝܪ",
            sy9: "",
            id: "3177",
            dt3: "",
            sy6: "",
            lt12: "",
            sy8: "",
            dt2: "Naphtir"
        },
        {
            id: "549",
            sy9: "",
            lt10: "shumoho",
            lt12: "",
            dt1: "Benennung",
            sy6: "",
            sy5: "ܫܽܘܡܳܗܳܐ",
            sy8: "",
            dt2: "Bezeichnung",
            sy4: "ܫܘܡܗܐ",
            sy7: "",
            dt3: "",
            lt11: ""
        },
        {
            sy9: "",
            sy8: "",
            sy4: "ܡܫܬܡܗܢܘܬܐ",
            id: "6235",
            sy7: "",
            dt2: "",
            lt12: "",
            dt3: "",
            lt10: "meshtamhonutho",
            dt1: "Subjektivität",
            lt11: "",
            sy6: "",
            sy5: "ܡܶܫܬܰܡܗܳܢܽܘܬ݂ܳܐ"
        },
        {
            lt12: "",
            sy9: "",
            dt1: "Unterwäsche",
            dt2: "",
            sy6: "",
            lt10: "lbushe gawoye",
            dt3: "",
            lt11: "",
            sy7: "",
            id: "4674",
            sy8: "",
            sy5: "ܠܒܽܘ̈ܫܶܐ ܓܰܘܳܝܶܐ   ",
            sy4: "ܠܒܘܫܐ ܓܘܝܐ"
        },
        {
            sy6: "",
            id: "5779",
            lt12: "",
            sy5: "ܨܳܦܽܘܚܳܝܳܐ",
            lt10: "ŝofuĥoyo",
            dt1: "aggressiv",
            sy9: "",
            lt11: "",
            dt3: "",
            sy4: "ܨܦܘܚܝܐ",
            sy7: "",
            sy8: "",
            dt2: ""
        },
        {
            dt3: "",
            dt1: "Sosi",
            lt11: "ܣܘܣܝ",
            sy6: "ܙܘܙܝ",
            sy7: "ܙܽܘܙܺܝ",
            sy9: "",
            sy8: "",
            lt12: "ܙܘܙܝ",
            id: "4203",
            sy5: "ܣܳܘܣܺܝ",
            sy4: "ܣܘܣܝ",
            lt10: "ܣܘܣܝ",
            dt2: "Susi"
        },
        {
            dt3: "",
            dt2: "",
            sy6: "",
            dt1: "unbewohnt",
            lt10: "shahyo",
            sy4: "ܫܗܝܐ",
            sy7: "",
            sy8: "",
            lt12: "",
            sy5: "ܫܰܗܝܳܐ",
            sy9: "",
            id: "4639",
            lt11: ""
        },
        {
            lt11: "",
            lt10: "fushoqo",
            dt1: "Interpretation",
            dt2: "",
            sy7: "",
            lt12: "",
            sy4: "ܦܘܫܩܐ",
            dt3: "",
            sy8: "",
            sy6: "",
            sy5: "ܦܽܘܫܳܩܳܐ",
            id: "2231",
            sy9: ""
        },
        {
            dt1: "Fahne",
            dt2: "Flagge",
            sy9: "",
            lt12: "",
            lt10: "otho",
            lt11: "",
            sy8: "",
            dt3: "Zahne",
            sy5: "ܐܳܬ݂ܳܐ",
            sy4: "ܐܬܐ",
            sy7: "",
            id: "1231",
            sy6: ""
        },
        {
            sy5: "ܐܰܚܠܳܐ",
            sy6: "ܐܘܚܠܐ",
            dt1: "alkalische Pflanze",
            sy7: "ܐܽܘܚܠܳܐ",
            sy8: "",
            id: "5823",
            sy9: "",
            lt11: "uĥlo",
            lt12: "",
            dt2: "",
            lt10: "aĥlo",
            dt3: "",
            sy4: "ܐܚܠܐ"
        },
        {
            sy7: "",
            dt3: "",
            sy8: "",
            id: "2845",
            sy9: "",
            lt12: "",
            sy5: "ܠܽܘܚܺܝܬ݂ܳܐ",
            sy6: "",
            dt2: "",
            sy4: "ܠܘܚܝܬܐ",
            lt10: "luĥitho",
            lt11: "",
            dt1: "Liste"
        },
        {
            sy7: "",
            sy5: "ܚܽܘܫܰܡ",
            dt3: "",
            sy9: "",
            lt12: "",
            dt2: "",
            id: "2078",
            lt10: "ܚܘܫܡ",
            dt1: "Huscham",
            sy4: "ܚܘܫܡ",
            sy8: "",
            sy6: "",
            lt11: "ܚܘܫܡ"
        },
        {
            dt3: "",
            sy6: "",
            lt12: "",
            dt2: "",
            lt11: "",
            id: "827",
            lt10: "tamro",
            sy8: "",
            sy7: "",
            sy4: "ܬܡܪܐ",
            sy5: "ܬܰܡܪܳܐ",
            sy9: "",
            dt1: "Dattel"
        },
        {
            sy6: "",
            sy8: "",
            dt2: "",
            lt12: "",
            dt3: "",
            sy7: "",
            sy9: "",
            lt10: "ܢܝܢܝܒ",
            sy5: "ܢܺܝܢܺܝܒ",
            id: "3280",
            sy4: "ܢܝܢܝܒ",
            dt1: "Ninib",
            lt11: "ܢܝܢܝܒ"
        },
        {
            sy9: "ܟܖ",
            sy8: "ܟܖ",
            id: "28",
            lt12: "kof-sayn",
            dt1: "27",
            sy7: "ܥܶܣܪܺܝܢ ܘܰܫܒܰܥ",
            dt3: "",
            lt10: "ĉesrin w`shabĉo",
            lt11: "ĉesrin wa`shbaĉ",
            dt2: "Siebenundzwanzig",
            sy4: "ܥܣܪܝܢ ܘܫܒܥܐ",
            sy5: "ܥܶܣܪܺܝܢ ܘܫܰܒܥܳܐ",
            sy6: "ܥܣܪܝܢ ܘܫܒܥ"
        },
        {
            sy5: "ܦܽܘܠܽܝܛܽܝܩܺܝ ܕܐܰܓ݂ܪܳܐ",
            sy7: "ܦܽܘܠܺܝܛܺܝܩܺܝ ܦܽܘܪܥܳܢܳܐ",
            lt10: "fulițiqi d`ağro",
            dt1: "Lohnpolitik",
            sy6: "ܦܘܠܝܛܝܩܝ ܦܘܪܥܢܐ",
            dt3: "",
            sy9: "",
            sy4: "ܦܘܠܝܛܝܩܝ ܕܐܓܪܐ",
            dt2: "",
            lt11: "fulițiqi furĉono",
            id: "2853",
            sy8: "",
            lt12: ""
        },
        {
            lt10: "ܨܠܝܠܬܐ",
            lt12: "",
            id: "4173",
            sy8: "",
            dt3: "",
            sy7: "",
            sy6: "",
            sy5: "ܨܠܝܺܠܬܰܐ",
            sy4: "ܨܠܝܠܬܐ",
            dt1: "Slita",
            lt11: "ܨܠܝܠܬܐ",
            sy9: "",
            dt2: ""
        },
        {
            dt3: "",
            lt11: "",
            lt12: "",
            id: "4737",
            sy7: "",
            dt2: "",
            sy4: "ܣܡܡ",
            sy9: "",
            sy6: "",
            dt1: "vergiften",
            lt10: "samem",
            sy5: "ܣܰܡܶܡ",
            sy8: ""
        },
        {
            dt3: "",
            sy5: "ܛܰܒܶܒ",
            sy8: "",
            sy9: "",
            lt11: "fras",
            sy4: "ܛܒܒ",
            dt1: "publizieren",
            id: "3543",
            sy6: "ܦܪܣ",
            lt12: "",
            dt2: "veröffentlichen",
            sy7: "ܦܪܰܣ",
            lt10: "țabeb"
        },
        {
            lt11: "",
            sy9: "",
            dt1: "Das Wetter ist heute trüb",
            sy4: "ܡܘܙܟܐ ܕܝܘܡܢ ܥܪܦܠܝܐ ܐܝܬܗܘܝ",
            dt3: "",
            lt10: "muzokho d'yaumon ĉarfeloyo ithau ",
            sy6: "",
            sy8: "",
            sy5: "ܡܽܘܙܳܟ݂ܳܐ ܕܝܰܘܡܳܢ ܥܰܪܦܶܠܳܝܳܐ ܐܺܝܬ݂ܰܗܘܝ",
            dt2: "",
            lt12: "",
            id: "823",
            sy7: ""
        },
        {
            sy5: "ܡܣܐܳܢܳܐ ܕܰܛܠܳܝܶܐ",
            sy4: "ܡܣܐܢܐ ܕܛܠܝܐ",
            lt10: "msono da'țloye",
            sy8: "",
            sy7: "",
            sy9: "",
            dt2: "",
            id: "6338",
            dt1: "Kinderschuh",
            dt3: "",
            lt11: "",
            lt12: "",
            sy6: ""
        },
        {
            sy9: "",
            dt3: "",
            sy5: "ܡܪܺܝܡܳܢܳܐ",
            id: "3233",
            sy6: "",
            lt10: "mrimono",
            lt11: "",
            sy7: "",
            dt1: "negativ",
            lt12: "",
            sy4: "ܡܪܝܡܢܐ",
            dt2: "",
            sy8: ""
        },
        {
            dt1: "Nordeuropa",
            sy4: "ܐܘܪܝܦܝ ܓܪܒܝܝܬܐ",
            lt12: "",
            sy5: "ܐܽܘܪܺܝܦܺܝ ܓܰܪܒܝܳܝܬܳܐ",
            sy6: "",
            sy9: "",
            lt10: "urifi garbyoyto",
            sy7: "",
            lt11: "",
            dt2: "",
            dt3: "",
            sy8: "",
            id: "3294"
        },
        {
            sy6: "",
            id: "914",
            dt1: "Domita",
            dt2: "",
            sy5: "ܕܳܡܺܝܬܰܐ",
            lt11: "ܕܡܝܬܐ",
            dt3: "",
            lt10: "ܕܡܝܬܐ",
            sy7: "",
            lt12: "",
            sy8: "",
            sy4: "ܕܡܝܬܐ",
            sy9: ""
        },
        {
            sy6: "ܬܝܒܐ",
            sy7: "ܬܰܝܳܒܳܐ",
            sy8: "",
            lt10: "toyubo",
            dt1: "Büßer",
            dt2: "Büsser",
            lt11: "tayobo",
            sy4: "ܬܝܘܒܐ",
            id: "6430",
            dt3: "",
            lt12: "",
            sy9: "",
            sy5: "ܬܳܝܽܘܒܳܐ"
        },
        {
            dt2: "",
            sy6: "ܙܓܪ",
            lt10: "shargel",
            sy7: "ܙܓ݂ܰܪ",
            sy9: "ܥܰܟܰܪ",
            lt12: "ĉakar",
            sy8: "ܥܟܪ",
            sy4: "ܫܪܓܠ",
            sy5: "ܫܰܪܓܶܠ",
            dt3: "",
            id: "5568",
            dt1: "abhalten",
            lt11: "zğar"
        },
        {
            lt11: "ܣܦܪܐ",
            lt12: "",
            dt3: "",
            sy8: "",
            sy9: "",
            dt1: "Safre",
            id: "3799",
            sy4: "ܣܦܪܐ",
            lt10: "ܣܦܪܐ",
            sy6: "",
            dt2: "",
            sy7: "",
            sy5: "ܣܰܦܪܶܐ"
        },
        {
            lt10: "ܓܕܥܘܢ",
            dt1: "Gideon",
            lt11: "ܓܕܥܘܢ",
            sy7: "",
            sy6: "",
            sy4: "ܓܕܥܘܢ",
            sy5: "ܓܶܕܥܽܘܢ",
            dt3: "",
            lt12: "",
            id: "1637",
            sy9: "",
            sy8: "",
            dt2: ""
        },
        {
            sy4: "ܝܶܫܽܘܥ ܪܳܚܡܳܐ ܐ̱ܢܳܐ ܠܳܟ݂",
            sy7: "ܝܶܫܽܘܥ ܪܳܚܶܡ ܐ̱ܢܳܐ ܠܳܟ݂",
            sy6: "ܝܶܫܽܘܥ ܪܳܚܶܡ ܐ̱ܢܳܐ ܠܳܟ݂",
            lt10: "jeshua roĥmo no lokh",
            sy9: "",
            sy5: "ܝܶܫܽܘܥ ܪܳܚܡܳܐ ܐ̱ܢܳܐ ܠܳܟ݂",
            dt1: "Jeshua ich liebe Dich",
            id: "5448",
            lt11: "joshua roĥem no lokh",
            lt12: "",
            dt3: "",
            sy8: "",
            dt2: ""
        },
        {
            sy4: "ܨܘܢܥܐ",
            lt12: "",
            dt3: "",
            sy5: "ܨܽܘܢܳܥܳܐ",
            lt11: "",
            dt2: "Gewerbe",
            sy9: "",
            sy6: "",
            id: "2198",
            lt10: "ŝunoĉo",
            sy7: "",
            sy8: "",
            dt1: "Industrie"
        },
        {
            dt1: "18",
            lt12: "juth-ĥeth",
            sy6: "ܬܡܢܥܣܪܐ",
            lt11: "tmonaĉesre",
            sy4: "ܬܡܢܥܣܪ",
            id: "19",
            lt10: "tmonaĉsar",
            sy9: "ܝܚ",
            sy8: "ܝܚ",
            sy7: "ܬܡܳܢܥܶܣ̈ܪܶܐ",
            dt2: "Achtzehn",
            dt3: "",
            sy5: "ܬܡܳܢܰܥܣܰܪ"
        },
        {
            sy6: "",
            dt1: "Vogelnest",
            sy5: "ܩܶܢܳܐ ܕܨܶܦܪܳܐ",
            id: "4844",
            sy9: "",
            dt2: "",
            dt3: "",
            sy7: "",
            sy8: "",
            lt10: "qeno d`ŝefro",
            lt11: "",
            lt12: "",
            sy4: "ܩܢܐ ܕܨܦܪܐ"
        },
        {
            dt2: "",
            sy7: "",
            lt12: "",
            id: "2153",
            lt10: "ܐܝܓ݂ܢܐܛܝܘܣ",
            sy9: "",
            dt3: "",
            lt11: "ܐܝܓ݂ܢܐܛܝܘܣ",
            sy5: "ܐܺܝܓ݂ܢܰܐܛܺܝܽܘܣ",
            dt1: "Ignatius",
            sy4: "ܐܝܓܢܐܛܝܘܣ",
            sy6: "",
            sy8: ""
        },
        {
            lt10: "sofeq",
            id: "1564",
            lt11: "",
            sy9: "",
            sy8: "",
            sy7: "",
            dt1: "genug",
            lt12: "",
            dt2: "",
            sy5: "ܣܳܦܶܩ",
            sy4: "ܣܦܩ",
            dt3: "",
            sy6: ""
        },
        {
            id: "3092",
            sy9: "",
            sy5: "ܡܳܪܝ̱ ܐܺܝܓ݂ܢܰܐܛܺܝܽܘܣ ܙܰܟܰܝ ܩܰܕܡܳܝܳܐ",
            lt12: "",
            sy4: "ܡܪܝ ܐܝܓܢܐܛܝܘܣ ܙܟܝ ܩܕܡܝܐ",
            sy8: "",
            dt1: "Mor Ignatius Zakay qadmoyo",
            dt3: "",
            sy7: "",
            dt2: "",
            lt10: " ܡܪܝ ܐܝܓܢܐܛܝܘܣ ܙܟܝ ܩܕܡܝܐ",
            lt11: " ܡܪܝ ܐܝܓܢܐܛܝܘܣ ܙܟܝ ܩܕܡܝܐ",
            sy6: ""
        },
        {
            dt2: "entsetzlich",
            sy5: "ܓܢܺܝܚܳܐ",
            dt1: "schrecklich",
            sy7: "",
            sy9: "",
            sy4: "ܓܢܝܚܐ",
            id: "4023",
            lt12: "",
            sy6: "",
            dt3: "unglücklich",
            sy8: "",
            lt10: "gniĥo",
            lt11: ""
        },
        {
            sy9: "",
            dt1: "Schainat",
            id: "3877",
            dt3: "",
            lt12: "",
            dt2: "",
            sy5: "ܫܰܝܢܰܬ",
            lt10: "ܫܝܢܬ",
            lt11: "ܫܝܢܬ",
            sy6: "",
            sy8: "",
            sy7: "",
            sy4: "ܫܝܢܬ"
        },
        {
            sy4: "ܣܡܟ݂ܝܐ",
            lt11: "ܣܡܟ݂ܝܐ",
            sy6: "ܣܡܟܝܐ",
            id: "4113",
            sy5: "ܣܡܰܟ݂ܝܳܐ",
            sy9: "",
            lt12: "ܣܡܟ݂ܝܐ",
            dt1: "Semachja",
            sy7: "ܣܡܰܟ݂ܝܰܐ",
            dt2: "",
            lt10: "ܣܡܟ݂ܝܐ",
            sy8: "",
            dt3: ""
        },
        {
            lt10: "ܐܝܕܐܝܢ",
            sy4: "ܐܝܕܐܝܢ",
            dt2: "",
            sy5: "ܐܰܝܕܐܺܝܢ",
            sy8: "",
            sy6: "",
            dt1: "Aydin",
            dt3: "",
            lt12: "ܐܝܕܝܢ",
            id: "403",
            lt11: "ܐܝܕܐܝܢ",
            sy7: "ܐܰܝܕܺܝܢ",
            sy9: ""
        },
        {
            dt2: "Weintraube",
            sy8: "",
            sy5: "ܥܶܢܒܳܐ",
            sy7: "",
            lt12: "",
            sy4: "ܥܢܒܐ",
            sy6: "",
            id: "4545",
            dt3: "",
            dt1: "Traube",
            lt11: "",
            sy9: "",
            lt10: "ĉenbo"
        },
        {
            sy4: "ܥܢܢܝܐ",
            sy9: "",
            sy8: "",
            lt12: "",
            lt10: "ĉnonoyo",
            dt3: "",
            id: "5162",
            sy6: "",
            dt2: "",
            sy7: "",
            lt11: "",
            sy5: "ܥܢܳܢܳܝܳܐ",
            dt1: "wolkig"
        },
        {
            dt1: "Ernennung",
            dt3: "",
            id: "1163",
            sy8: "",
            sy6: "",
            lt12: "",
            sy9: "",
            sy4: "ܫܘܡܝܐ",
            lt11: "",
            sy7: "",
            sy5: "ܫܽܘܡܳܝܳܐ",
            dt2: "",
            lt10: "shumoyo"
        },
        {
            sy8: "",
            sy4: "ܦܠܝܓܐ",
            sy5: "ܦܠܺܝܓ݂ܳܐ",
            dt2: "",
            lt11: "fsiqo",
            lt10: "fliğo",
            dt1: "geteilt",
            sy9: "",
            sy7: "ܦܣܺܝܩܳܐ",
            id: "1611",
            lt12: "",
            sy6: "ܦܣܝܩܐ",
            dt3: ""
        },
        {
            sy5: "ܥܶܓܰܠܬ݂ܳܐ ܕܢܰܥܺܝܡ̈ܶܐ",
            lt12: "",
            dt3: "",
            lt10: "ĉegaltho dnaĉime",
            sy8: "",
            dt1: "Kinderwagen",
            sy7: "",
            id: "5425",
            lt11: "",
            sy4: "ܥܓܠܬܐ ܕܢܥܝܡ̈ܶܐ",
            sy6: "",
            dt2: "",
            sy9: ""
        },
        {
            sy6: "",
            sy5: "ܡܬܺܝܪܳܢܳܐ",
            sy4: "ܡܬܝܪܢܐ",
            dt2: "",
            dt3: "",
            lt12: "",
            sy9: "",
            lt10: "mtirono",
            sy8: "",
            dt1: "sensationell",
            lt11: "",
            sy7: "",
            id: "4122"
        },
        {
            sy6: "",
            sy4: "ܛܦܪܐ",
            dt3: "",
            sy8: "",
            id: "4419",
            sy9: "",
            dt1: "Tefra",
            sy5: "ܛܶܦܪܰܐ",
            sy7: "",
            lt10: "ܛܦܪܐ",
            dt2: "",
            lt12: "",
            lt11: "ܛܦܪܐ"
        },
        {
            lt10: "ĉadĉed",
            sy5: "ܥܰܕܥܶܕ",
            sy4: "ܥܕܥܕ",
            dt2: "",
            sy7: "",
            dt1: "feiern",
            sy9: "",
            lt11: "",
            dt3: "",
            sy6: "",
            id: "1276",
            sy8: "",
            lt12: ""
        },
        {
            lt10: "ĉmar",
            lt11: "",
            dt2: "",
            sy4: "ܥܡܪ",
            sy5: "ܥܡܰܪ",
            lt12: "",
            id: "5157",
            sy8: "",
            sy9: "",
            dt3: "",
            dt1: "wohnen",
            sy6: "",
            sy7: ""
        },
        {
            sy7: "",
            lt10: "qubtho",
            id: "2671",
            lt12: "",
            sy8: "",
            sy5: "ܩܽܘܒܬ݂ܳܐ",
            dt3: "",
            sy9: "",
            sy6: "",
            dt2: "Gewölbe",
            dt1: "Kuppel",
            sy4: "ܩܘܒܬܐ",
            lt11: ""
        },
        {
            dt1: "28",
            sy6: "ܥܣܪܝܢ ܘܬܡܢܐ",
            sy9: "ܟܚ",
            sy4: "ܥܣܪܝܢ ܘܬܡܢܝܐ",
            dt3: "",
            lt11: "ĉesrin wa`tmone",
            lt10: "ĉesrin wa`tmonyo",
            id: "29",
            sy7: "ܥܶܣܪܺܝܢ ܘܰܬܡܢܶܐ",
            sy5: "ܥܶܣܪܺܝܢ ܘܬܡܳܢܝܳܐ",
            lt12: "kof-ĥeth",
            sy8: "ܟܚ",
            dt2: "Achtundzwanzig"
        },
        {
            sy9: "",
            sy6: "",
            id: "6132",
            sy5: "ܡܶܬ݂ܥܰܒܕܳܢܽܘܬ݂ܳܐ",
            lt12: "",
            lt11: "",
            sy8: "",
            dt1: "Objektivität",
            sy4: "ܡܬܥܒܕܢܘܬܐ",
            dt3: "",
            sy7: "",
            lt10: "methĉabđonutho",
            dt2: ""
        },
        {
            lt10: "mĥablo",
            dt1: "abwegig",
            sy6: "",
            id: "5701",
            sy8: "",
            lt12: "",
            dt3: "",
            dt2: "",
            sy4: "ܡܚܒܠܐ",
            sy5: "ܡܚܰܒܠܳܐ",
            sy9: "",
            lt11: "",
            sy7: ""
        },
        {
            sy6: "ܡܦܝܣܢܘܬܐ",
            sy9: "ܬܰܟܫܶܦܬܳܐ",
            lt12: "takhshefto",
            lt10: "taĥnanto",
            dt2: "Fürbitte",
            id: "1433",
            lt11: "mfisonutho",
            dt3: "",
            sy5: "ܬܰܚܢܰܢܬܳܐ",
            dt1: "Fürsprache",
            sy8: "ܬܟܫܦܬܐ",
            sy4: "ܬܚܢܢܬܐ",
            sy7: "ܡܦܺܝܣܳܢܽܘܬ݂ܳܐ"
        },
        {
            dt3: "",
            sy4: "ܪܡܫܐ ܚܓܢܝܐ",
            lt12: "",
            sy7: "",
            sy6: "",
            id: "5559",
            sy8: "",
            lt10: "ramsho ĥagonoyo",
            lt11: "",
            sy5: "ܪܰܡܫܳܐ ܚܰܓܳܢܳܝܳܐ",
            sy9: "",
            dt1: "Abendfest",
            dt2: ""
        },
        {
            lt12: "",
            sy8: "",
            id: "3702",
            sy6: "",
            lt11: "ܪܨܢ",
            lt10: "ܪܨܢ",
            sy4: "ܪܨܢ",
            dt1: "Reson",
            dt3: "",
            sy7: "",
            sy5: "ܪܶܨܳܢ",
            dt2: "",
            sy9: ""
        },
        {
            lt10: "ܣܐܘܝܪܐ",
            dt1: "Sevire",
            id: "4131",
            sy5: "ܣܶܐܘܺܝܪܶܐ",
            dt3: "Severos",
            sy6: "ܣܐܘܪܝܘܣ",
            sy8: "",
            sy7: "ܣܶܐܘܺܪܝܳܘܣ",
            lt11: "ܣܐܘܝܪܐ",
            dt2: "Severios",
            lt12: "ܣܐܘܪܝܘܣ",
            sy9: "",
            sy4: "ܣܐܘܝܪܐ"
        },
        {
            lt12: "",
            dt1: "Passivität",
            sy9: "",
            sy4: "ܫܠܝܘܬܐ",
            sy5: "ܫܰܠܝܽܘܬ݂ܳܐ",
            lt11: "",
            sy6: "",
            lt10: "shalyutho",
            sy8: "",
            dt2: "",
            dt3: "",
            id: "6159",
            sy7: ""
        },
        {
            dt2: "",
            sy5: "ܦܶܟܪܺܝ",
            dt3: "",
            id: "1308",
            sy9: "",
            lt11: "ܦܟܪܝ",
            dt1: "Fikri",
            sy4: "ܦܝܟܪܝ",
            lt12: "",
            sy8: "",
            sy6: "",
            lt10: "ܦܟܪܝ",
            sy7: ""
        },
        {
            sy6: "ܡܪܚܡܢܐܝܬ",
            sy4: "ܡܪܚܡܢܐ",
            sy9: "",
            lt10: "mraĥmono",
            lt11: "mraĥmonoith",
            dt3: "mitleidvoll",
            sy5: "ܡܪܰܚܡܳܢܳܐ",
            sy7: "ܡܪܰܚܡܳܢܳܐܝܺܬ݂",
            lt12: "",
            dt2: "wohltätig",
            dt1: "mitfühlend",
            id: "3059",
            sy8: ""
        },
        {
            sy8: "",
            id: "5302",
            sy7: "",
            dt2: "",
            dt1: "Zentner",
            lt11: "",
            sy9: "",
            dt3: "",
            sy5: "ܩܰܢܛܺܝܪܳܐ",
            sy4: "ܩܢܛܝܪܐ",
            lt12: "",
            lt10: "qanțiro",
            sy6: ""
        },
        {
            lt10: "ĉiđo fanțiqusți",
            id: "3455",
            dt3: "",
            lt12: "",
            lt11: "",
            sy6: "",
            sy8: "",
            dt2: "",
            sy5: "ܥܺܐܕܳܐ ܦܰܢܛܺܝܩܽܘܣܛܺܝ",
            sy4: "ܥܐܕܐ ܦܢܛܝܩܘܣܛܝ",
            sy9: "",
            dt1: "Pfingstfest",
            sy7: ""
        },
        {
            lt11: "mnafshoith",
            sy9: "ܢܦܺܝܫܳܐ",
            lt10: "mnafsho",
            sy6: "ܡܢܦܫܐܝܬ",
            sy5: "ܡܢܰܦܫܳܐ",
            lt12: "nfisho",
            dt2: "lebend",
            sy7: "ܡܢܰܦܫܳܐܝܺܬ݂",
            sy8: "ܢܦܝܫܐ",
            id: "2730",
            dt3: "lebhaft",
            sy4: "ܡܢܦܫܐ",
            dt1: "lebendig"
        },
        {
            sy6: "",
            sy7: "",
            id: "2492",
            sy9: "",
            sy4: "ܩܪܣܘܣ",
            sy8: "",
            sy5: "ܩܶܪܰܣܽܘܣ",
            lt10: "qerasus",
            lt11: "",
            dt3: "",
            dt2: "",
            dt1: "Kirschbaum",
            lt12: ""
        },
        {
            id: "4653",
            dt2: "",
            sy7: "",
            lt10: "lo mkono",
            lt12: "",
            sy4: "ܠܐ ܡܟܢܐ",
            dt3: "",
            sy6: "",
            sy9: "",
            dt1: "unmöglich",
            sy5: "ܠܳܐ ܡܟܳܢܳܐ",
            sy8: "",
            lt11: ""
        },
        {
            dt1: "Gabil",
            sy6: "",
            sy8: "",
            lt12: "",
            sy4: "ܓܒܐܝܠ",
            sy5: "ܓܰܒܐܺܝܠ",
            sy9: "",
            id: "1452",
            lt11: "ܓܒܐܝܠ",
            dt3: "",
            lt10: "ܓܒܐܝܠ",
            sy7: "",
            dt2: ""
        },
        {
            sy7: "ܚܳܫܶܒ",
            sy8: "",
            dt2: "berechnend",
            lt11: "ĥosheb",
            sy9: "",
            lt10: "ĥshab",
            sy5: "ܚܫܰܒ",
            id: "3632",
            lt12: "",
            dt1: "rechnen",
            sy6: "ܚܫܒ",
            sy4: "ܚܫܒ",
            dt3: "vorsätzlich"
        },
        {
            sy5: "ܣܰܪܓܺܝܣ",
            sy9: "",
            lt10: "ܣܪܟܝܣ",
            sy7: "ܣܰܪܟܺܝܣ",
            id: "3845",
            dt2: "Sarkis",
            dt1: "Sargis",
            lt12: "ܣܪܟܝܣ",
            dt3: "Sergius",
            sy8: "",
            lt11: "ܣܪܟܝܣ",
            sy6: "ܣܪܟܝܒ",
            sy4: "ܣܪܟܝܣ"
        },
        {
            dt1: "Sbartha",
            sy4: "ܣܒܪܬܐ",
            sy8: "",
            lt11: "ܣܒܪܬܐ",
            sy5: "ܣܒܰܪܬܰܐ",
            sy7: "",
            dt2: "",
            dt3: "",
            id: "3859",
            lt10: "ܣܒܪܬܐ",
            sy6: "",
            lt12: "",
            sy9: ""
        },
        {
            sy8: "",
            lt10: "yoriĉto",
            sy5: "ܝܳܪܺܝܥܬܳܐ",
            sy9: "",
            dt1: "Zelt",
            id: "5295",
            sy4: "ܝܪܝܥܬܐ",
            sy6: "",
            lt11: "",
            lt12: "",
            sy7: "",
            dt3: "",
            dt2: "Campingzelt"
        },
        {
            lt12: "",
            id: "440",
            dt2: "",
            sy8: "",
            dt3: "",
            sy9: "",
            lt10: "ܒܪܐܒܐ",
            sy5: "ܒܰܪܐܰܒܰܐ",
            dt1: "Barabba",
            lt11: "ܒܪܐܒܐ",
            sy6: "",
            sy7: "",
            sy4: "ܒܪܐܒܐ"
        },
        {
            lt10: "gyodonoyo",
            sy8: "",
            dt1: "nervös",
            sy6: "",
            sy4: "ܓܝܕܢܝܐ",
            dt3: "",
            id: "3245",
            sy7: "",
            dt2: "",
            sy5: "ܓܝܳܕܳܢܳܝܳܐ",
            sy9: "",
            lt12: "",
            lt11: ""
        },
        {
            sy8: "",
            dt3: "",
            sy9: "",
            dt1: "Schälen",
            sy6: "ܡܬܩܦܠܢܘܬܐ",
            lt10: "qlofo",
            lt12: "",
            sy4: "ܩܠܦܐ",
            sy5: "ܩܠܳܦܳܐ",
            sy7: "ܡܶܬ݂ܩܰܦܠܳܢܽܘܬ݂ܳܐ",
            lt11: "methqaflonutho",
            dt2: "",
            id: "3884"
        },
        {
            dt1: "Mixtur",
            dt3: "",
            id: "6375",
            lt12: "",
            lt11: "qraso",
            lt10: "muzoğo",
            sy4: "ܡܘܙܓܐ",
            sy8: "",
            sy5: "ܡܽܘܙܳܓ݂ܳܐ",
            sy7: "ܩܪܰܣܳܐ",
            dt2: "",
            sy9: "",
            sy6: "ܩܪܣܐ"
        },
        {
            sy7: "",
            sy4: "ܐܘܟܝܬ",
            dt2: "",
            sy5: "ܐܰܘܟܺܝܬ",
            id: "372",
            dt1: "Aukit",
            lt11: "ܐܘܟܝܬ",
            sy8: "",
            sy6: "",
            lt12: "",
            sy9: "",
            dt3: "",
            lt10: "ܐܘܟܝܬ"
        },
        {
            sy4: "ܦܛܪܝܪܟܐ ܕܐܢܛܝܘܟܝܐ",
            sy6: "",
            sy9: "",
            sy8: "",
            lt12: "",
            dt2: "",
            sy5: "ܦܰܛܶܪܝܰܪܟ݂ܳܐ ܕܰܐܢܛܝܳܘܟ݂ܝܰܐ",
            sy7: "",
            lt10: "fațeryarkho d`antiochya",
            dt3: "",
            dt1: "Patriarch von Antiochien",
            id: "3422",
            lt11: ""
        },
        {
            sy6: "ܬܫܒܘܚܬܐ",
            sy7: "ܬܶܫܒܽܘܚܬܳܐ",
            id: "1666",
            sy9: "",
            lt10: "shubĥo",
            lt12: "",
            sy8: "",
            dt1: "Glorie",
            dt3: "",
            lt11: "teshbuĥto",
            dt2: "",
            sy4: "ܫܘܒܚܐ",
            sy5: "ܫܽܘܒܚܳܐ"
        },
        {
            lt10: "ܟܫܝܪܬܐ",
            lt12: "",
            sy8: "",
            sy6: "",
            dt2: "",
            id: "2422",
            dt1: "Kaschirta",
            lt11: "ܟܫܝܪܬܐ",
            sy4: "ܟܫܝܪܬܐ",
            sy7: "",
            sy5: "ܟܰܫܺܝܪܬܰܐ",
            sy9: "",
            dt3: ""
        },
        {
            lt12: "",
            sy7: "",
            id: "3644",
            sy5: "ܠܶܫܳܢ ܢܳܡܽܘܣܳܐ",
            sy8: "",
            dt3: "",
            dt1: "Rechtssprache",
            dt2: "",
            lt11: "",
            sy6: "",
            sy4: "ܠܫܢ ܢܡܘܣܐ",
            sy9: "",
            lt10: "leshon nomuso"
        },
        {
            dt1: "Öffne die Flasche",
            sy7: "",
            lt11: "",
            sy6: "",
            id: "KX88ixbr6CfZXIEzJfsg",
            lt12: "",
            sy8: "",
            lt10: "ftaĥ i shoțifto",
            sy5: "ܦܬܰܚ ܐܺܝ ܫܳܛܺܝܦܬܳܐ",
            sy9: "",
            sy4: "ܦܬܚ ܐܝ ܫܛܝܦܬܐ",
            dt3: "",
            dt2: "",
            "date": {
                "seconds": 1590850223,
                "nanoseconds": 478000000
            }
        },
        {
            id: "1551",
            dt2: "",
            sy9: "",
            dt3: "",
            sy4: "ܓܠܦܢܐ",
            lt12: "",
            sy7: "",
            sy6: "",
            dt1: "Gelfono",
            sy5: "ܓܶܠܦܳܢܳܐ",
            sy8: "",
            lt11: "ܓܠܦܢܐ",
            lt10: "ܓܠܦܢܐ"
        },
        {
            lt11: "",
            sy8: "",
            dt3: "",
            dt1: "Minute",
            sy4: "ܩܛܝܢܬܐ",
            sy7: "",
            lt10: "qaținto",
            id: "3049",
            dt2: "",
            sy6: "",
            sy9: "",
            sy5: "ܩܰܛܺܝܢܬܳܐ",
            lt12: ""
        },
        {
            dt1: "Wolke",
            dt2: "",
            sy7: "ܥܰܝܡܳܐ",
            lt11: "ĉaymo",
            lt12: "",
            sy6: "ܥܝܡܐ",
            sy9: "",
            sy8: "",
            sy5: "ܥܢܳܢܳܐ",
            lt10: "ĉnono",
            id: "5161",
            dt3: "",
            sy4: "ܥܢܢܐ"
        },
        {
            sy5: "ܡܡܰܬ݂ܠܳܢܳܐ ܕܥܰܡܳܐ",
            sy4: "ܡܡܬܠܢܐ ܕܥܡܐ",
            dt1: "Abgeordneter",
            lt11: "buluțo",
            lt12: "",
            sy7: "ܒܽܘܠܳܘܛܳܐ",
            sy6: "ܒܘܠܘܛܐ",
            sy8: "",
            dt3: "",
            dt2: "",
            sy9: "",
            id: "87",
            lt10: "mathlono d`ĉamo"
        },
        {
            sy9: "",
            lt11: "ܐܡܕܐ",
            lt10: "ܐܡܕܐ",
            sy6: "",
            dt2: "",
            sy5: "ܐܰܡܶܕܰܐ",
            sy8: "",
            sy7: "",
            dt1: "Ameda",
            lt12: "",
            id: "206",
            sy4: "ܐܡܕܐ",
            dt3: ""
        },
        {
            sy5: "ܢܽܘܩܕܳܐ",
            lt10: "nuqđo",
            sy8: "",
            lt12: "",
            id: "3547",
            dt1: "Punkt",
            sy6: "",
            lt11: "",
            dt3: "",
            sy7: "",
            dt2: "",
            sy4: "ܢܘܩܕܐ",
            sy9: ""
        },
        {
            sy6: "",
            sy5: "ܩܶܢܢܶܫܪܺܝܢ",
            lt11: "ܩܢܢܫܪܝܢ",
            id: "3561",
            sy4: "ܩܢܢܫܪܝܢ",
            lt10: "ܩܢܢܫܪܝܢ",
            dt2: "",
            sy8: "",
            sy7: "",
            dt1: "Qenneschrin",
            dt3: "",
            lt12: "",
            sy9: ""
        },
        {
            lt10: "kul yum",
            lt11: "ĥaye yaumonoye",
            dt2: "",
            sy4: "ܟܠ ܝܘܡ",
            sy6: "ܚܝܐ ܝܘܡܢܝܐ",
            lt12: "",
            id: "5847",
            sy9: "",
            dt1: "Alltag",
            sy8: "",
            dt3: "",
            sy7: "ܚܰܝܶܐ ܝܰܘܡܳܢܳܝܶܐ",
            sy5: "ܟܽܠ ܝܽܘܡ"
        },
        {
            lt11: "ĥrabemishka",
            sy4: "ܩܪܝܬܐ ܟܪܐܒܗܡܫܟܗ",
            sy7: "ܚܪܰܒܶܗܡܺܫܟܰܗ",
            sy5: "ܩܪܺܝܬ݂ܳܐ ܟ݂ܪܰܐܒܶܗܡܺܫܟܰܗ",
            dt3: "harbtho",
            sy6: "ܟܪܒܗܡܫܟܗ",
            id: "935",
            lt12: "ĥarbtho",
            sy9: "ܚܰܪܒܬ݂ܳܐ",
            lt10: "qritho ĥrabemishka",
            sy8: "ܚܪܒܬܐ",
            dt1: "Dorf Harabemiska",
            dt2: "Harabemiska"
        },
        {
            lt12: "",
            lt11: "ܕܡܝ",
            lt10: "ܕܡܝ",
            sy5: "ܕܰܡܺܝ",
            sy4: "ܕܡܝ",
            dt1: "Dami",
            id: "793",
            sy6: "",
            sy7: "",
            sy8: "",
            dt3: "",
            dt2: "",
            sy9: ""
        },
        {
            sy5: "ܕܥܳܪܳܐ",
            sy9: "",
            lt12: "",
            sy4: "ܕܥܪܐ",
            dt3: "",
            lt10: "dĉoro",
            lt11: "hfukhyo",
            sy8: "",
            id: "3758",
            dt1: "Rückkehr",
            dt2: "",
            sy7: "ܗܦܽܘܟ݂ܝܳܐ",
            sy6: "ܗܦܘܟܝܐ"
        },
        {
            lt12: "",
            dt3: "Problemlösung",
            sy5: "ܫܪܳܝܳܐ",
            dt2: "Problem beheben",
            sy8: "",
            id: "2859",
            sy4: "ܫܪܝܐ",
            sy7: "ܫܪܝܐ ܕܩܛܪܐ",
            lt11: "shroyo d`qețro",
            sy6: "ܫܪܝܐ ܕܩܛܪܐ",
            sy9: "",
            lt10: "shroyo",
            dt1: "Lösung"
        },
        {
            dt1: "Postwesen",
            sy4: "ܒܝܠܕܪܘܬܐ",
            lt12: "",
            dt3: "",
            id: "3501",
            sy8: "",
            sy5: "ܒܺܝܠܕܰܪܽܘܬ݂ܳܐ",
            sy6: "",
            lt11: "",
            dt2: "",
            lt10: "bildarutho",
            sy7: "",
            sy9: ""
        },
        {
            dt1: "Aras",
            sy4: "ܐܪܣ",
            id: "274",
            sy5: "ܐܰܪܰܣ",
            dt2: "",
            sy9: "",
            lt10: "ܐܪܣ",
            sy6: "",
            lt12: "",
            sy7: "",
            dt3: "",
            sy8: "",
            lt11: "ܐܪܣ"
        },
        {
            dt2: "",
            id: "3785",
            sy6: "",
            lt12: "",
            dt1: "Sabarjeschu",
            sy9: "",
            lt10: "ܣܒܪܝܫܘܥ",
            dt3: "",
            lt11: "ܣܒܪܝܫܘܥ",
            sy8: "",
            sy4: "ܣܒܪܝܫܘܥ",
            sy7: "",
            sy5: "ܣܰܒܰܪܝܶܫܽܘܥ"
        },
        {
            sy9: "",
            sy6: "",
            lt12: "",
            sy8: "",
            dt2: "",
            sy4: "ܦܪܙܠܐ ܥܬܝܩܐ",
            sy7: "",
            id: "5858",
            dt3: "",
            dt1: "Alteisen",
            sy5: "ܦܰܪܙܠܳܐ ܥܰܬܺܝܩܳܐ",
            lt10: "farzlo ĉatiqo",
            lt11: ""
        },
        {
            sy9: "",
            sy6: "ܡܥܦܩ",
            id: "4626",
            dt2: "",
            dt1: "umarmen",
            sy4: "ܥܦܩ",
            lt12: "",
            lt10: "ĉafeq",
            sy5: "ܥܰܦܶܩ",
            sy8: "",
            lt11: "mĉafeq",
            dt3: "",
            sy7: "ܡܥܰܦܶܩ"
        },
        {
            sy9: "",
            id: "4059",
            sy7: "ܢܰܛܰܪ",
            sy6: "ܢܛܪ",
            sy4: "ܢܛܪ",
            dt1: "schützen",
            dt2: "",
            sy5: "ܢܛܰܪ",
            lt12: "",
            lt11: "națar",
            dt3: "",
            lt10: "nțar",
            sy8: ""
        },
        {
            sy4: "ܦܬܘܪܬܐ",
            dt2: "",
            sy7: "",
            dt3: "",
            sy5: "ܦܰܬܽܘܪܬܳܐ",
            id: "3462",
            sy6: "",
            lt12: "",
            sy8: "",
            sy9: "",
            dt1: "Pilz",
            lt11: "",
            lt10: "faturto"
        },
        {
            dt3: "",
            dt2: "",
            sy7: "",
            sy8: "",
            sy4: "ܥܘܠܐ",
            lt10: "ĉawolo",
            lt11: "",
            id: "6415",
            sy5: "ܥܰܘܳܠܳܐ",
            sy9: "",
            sy6: "",
            dt1: "Gesetzloser",
            lt12: ""
        },
        {
            sy5: "ܐܰܬܪܳܐ",
            sy4: "ܐܬܪܐ",
            sy8: "",
            lt12: "",
            sy9: "",
            lt11: "ܐܬܪܐ",
            sy6: "",
            lt10: "ܐܬܪܐ",
            sy7: "",
            id: "340",
            dt3: "",
            dt1: "Atro",
            dt2: ""
        },
        {
            sy9: "",
            lt10: "ܐܝܒܠܐ",
            sy8: "",
            sy7: "",
            lt11: "ܐܝܒܠܐ",
            sy5: "ܐܺܝܒܠܰܐ",
            sy4: "ܐܝܒܠܐ",
            id: "2087",
            sy6: "",
            lt12: "",
            dt1: "Ibla",
            dt3: "",
            dt2: ""
        },
        {
            sy9: "",
            dt3: "nichts",
            lt11: "",
            dt2: "keine Ursache",
            sy6: "",
            dt1: "nicht zu danken",
            id: "3264",
            lt10: "lo medem",
            sy5: "ܠܳܐ ܡܶܕܶܡ",
            sy8: "",
            lt12: "",
            sy7: "",
            sy4: "ܠܐ ܡܕܡ"
        },
        {
            sy7: "",
            sy5: "ܓܰܒܪܳܐ",
            lt10: "ܓܒܪܐ",
            sy6: "",
            sy4: "ܓܒܪܐ",
            dt3: "",
            dt2: "Gabro",
            sy9: "",
            dt1: "Gebro",
            lt12: "",
            lt11: "ܓܒܪܐ",
            sy8: "",
            id: "1502"
        },
        {
            sy5: "ܥܰܒܽܘܕ",
            id: "107",
            sy4: "ܥܒܘܕ",
            dt3: "",
            lt12: "",
            dt2: "Abud",
            sy6: "",
            sy7: "",
            dt1: "Aboud",
            lt10: "ܥܒܘܕ",
            lt11: "ܥܒܘܕ",
            sy9: "",
            sy8: ""
        },
        {
            lt11: "",
            dt1: "flehen",
            dt3: "erbitten",
            sy8: "",
            id: "1325",
            dt2: "bitten",
            sy6: "",
            sy7: "",
            sy5: "ܐܶܬ݂ܟܰܫܰܦ",
            lt12: "",
            sy4: "ܐܬܟܫܦ",
            lt10: "ethkashaf",
            sy9: ""
        },
        {
            sy4: "ܡܢܝܐ",
            lt10: "manoyo",
            sy8: "",
            sy6: "",
            sy5: "ܡܰܢܳܝܳܐ",
            dt3: "",
            sy9: "",
            id: "4393",
            lt12: "",
            sy7: "",
            dt2: "",
            lt11: "",
            dt1: "Taschenrechner"
        },
        {
            sy9: "",
            sy6: "",
            lt10: "fraĉ ĥaubo",
            sy4: "ܦܪܥ ܚܘܒܐ",
            sy7: "",
            lt11: "",
            lt12: "",
            dt3: "",
            dt2: "",
            sy5: "ܦܪܰܥ ܚܰܘܒܳܐ",
            dt1: "Schulden tilgen",
            sy8: "",
            id: "4046"
        },
        {
            sy6: "ܡܫܝܚܝܐ ܣܘܪܝ̣ܝܐ",
            sy9: "ܐܳܬ݂ܺܘܪܳܝ̈ܶܐ",
            sy7: "ܡܫܺܝܚ̈ܳܝܶܐ ܣܽܘܪ̈ܝ̣ܳܝܶܐ",
            sy8: "ܐܬܘܪ̈ܝܐ",
            lt11: "mshiĥoye suryoye",
            dt1: "Syrische Christen",
            dt2: "",
            dt3: "",
            sy4: "ܣܘܪܝ̣ܝܐ",
            lt12: "othuroye",
            sy5: "ܣܽܘܪ̈ܝ̣ܳܝܶܐ",
            id: "4340",
            lt10: "suryoye"
        },
        {
            id: "3984",
            dt3: "",
            dt1: "schminken",
            sy4: "ܟܚܠ",
            dt2: "",
            sy8: "",
            sy6: "ܟܚܠܐ",
            lt10: "koĥel",
            sy7: "ܟܳܚܠܳܐ",
            sy5: "ܟܳܚܶܠ",
            lt12: "",
            lt11: "koĥlo",
            sy9: ""
        },
        {
            sy7: "ܓܪܶܝܡܺܝܪܐ",
            sy9: "",
            lt10: "qritho gremira",
            sy5: "ܩܪܺܝܬ݂ܳܐ ܓܪܺܝܡܝܺܪܰܐ",
            sy4: "ܩܪܝܬܐ ܓܪܝܡܝܪܐ",
            sy6: "ܓܪܝܡܝܪܐ",
            id: "932",
            dt1: "Dorf Gremira",
            sy8: "",
            dt2: "Gremira",
            lt11: "gremira",
            lt12: "",
            dt3: ""
        },
        {
            id: "1380",
            dt3: "FSA",
            sy7: "ܓܰܝܣܳܐ ܚܺܝܪܳܐ ܕܣܽܘܪܺܝܰܐ",
            sy8: "",
            dt1: "Freie Armee",
            lt11: "gayso ĥiro d`suriya",
            sy4: "ܓܝܣܐ ܚܝܪܐ",
            sy9: "",
            dt2: "Freie syrische Armee",
            sy5: "ܓܰܝܣܳܐ ܚܺܝܪܳܐ",
            sy6: "ܓܝܣܐ ܚܝܪܐ ܕܣܘܪܝܐ",
            lt12: "",
            lt10: "gayso ĥiro"
        },
        {
            sy9: "",
            sy6: "",
            dt1: "Kopte",
            sy7: "",
            sy5: "ܩܽܘܒܛܳܝܳܐ",
            sy4: "ܩܘܒܛܝܐ",
            dt2: "koptisch",
            lt11: "",
            lt10: "qubțoyo",
            id: "2607",
            sy8: "",
            lt12: "",
            dt3: ""
        },
        {
            id: "4153",
            sy8: "",
            lt12: "",
            sy6: "",
            dt1: "Sifura",
            sy9: "",
            lt10: "ܨܝܦܘܪܐ",
            sy4: "ܨܝܦܘܪܐ",
            lt11: "ܨܝܦܘܪܐ",
            dt3: "Sipura",
            dt2: "Zippora",
            sy5: "ܨܺܝܦܽܘܪܰܐ",
            sy7: ""
        },
        {
            lt11: "",
            dt1: "Geschmack",
            id: "1587",
            sy7: "",
            sy5: "ܛܰܥܡܳܐ",
            sy8: "",
            sy6: "",
            sy9: "",
            lt10: "țaĉmo",
            sy4: "ܛܥܡܐ",
            dt3: "",
            lt12: "",
            dt2: ""
        },
        {
            lt12: "",
            id: "4906",
            dt2: "",
            sy6: "",
            lt11: "",
            dt3: "",
            sy9: "",
            sy8: "",
            sy4: "ܪܝܫܢܘܬܐ",
            sy7: "",
            lt10: "rishonutho",
            sy5: "ܪܝܺܫܳܢܽܘܬ݂ܳܐ",
            dt1: "Vorsitz"
        },
        {
            dt3: "",
            lt10: "ܫܪܒܝܠ",
            dt2: "",
            lt11: "ܫܪܒܝܠ",
            sy5: "ܫܰܪܒܶܝܠ",
            id: "3900",
            sy6: "",
            sy9: "",
            sy8: "",
            dt1: "Scharbel",
            sy4: "ܫܪܒܝܠ",
            sy7: "",
            lt12: ""
        },
        {
            sy8: "",
            dt2: "",
            sy7: "ܙܪܰܥܠܳܐ",
            lt11: "zraĉlo",
            lt12: "",
            sy5: "ܩܽܘܪܩܣܳܐ",
            sy6: "ܙܪܥܠܐ",
            sy9: "",
            id: "2548",
            dt1: "Knopf",
            dt3: "",
            lt10: "qurqso",
            sy4: "ܩܘܪܩܣܐ"
        },
        {
            dt2: "",
            sy9: "",
            lt11: "ܣܘܬܐ",
            sy4: "ܣܘܬܐ",
            id: "4331",
            sy6: "",
            sy5: "ܣܘܰܬܰܐ",
            lt12: "",
            dt3: "",
            lt10: "ܣܘܬܐ",
            sy7: "",
            sy8: "",
            dt1: "Swata"
        },
        {
            lt12: "",
            id: "3523",
            dt3: "",
            lt10: "nbiyo",
            dt1: "Prophet",
            sy4: "ܢܒܝܐ",
            sy9: "",
            sy8: "",
            sy6: "",
            dt2: "",
            sy7: "",
            lt11: "",
            sy5: "ܢܒܺܝܳܐ"
        },
        {
            sy5: "ܘܳܠܺܝܬ݂ ܒܰܝܬܳܐ",
            lt12: "",
            id: "1896",
            dt2: "",
            lt10: "wolith bayto",
            sy9: "",
            lt11: "",
            sy4: "ܘܠܝܬ ܒܝܬܐ",
            sy6: "",
            dt3: "",
            sy7: "",
            sy8: "",
            dt1: "Hausaufgabe"
        },
        {
            sy9: "",
            lt10: "fushoqo da`kthobo",
            dt2: "Buch interpretieren",
            lt11: "",
            sy6: "",
            id: "725",
            lt12: "",
            sy5: "ܦܽܘܫܳܩܳܐ ܕܟܬ݂ܳܒܳܐ",
            sy4: "ܦܘܫܩܐ ܕܟܬܒܐ",
            dt1: "Buchauslegung",
            sy7: "",
            dt3: "",
            sy8: ""
        },
        {
            id: "8",
            sy8: "ܖ",
            dt3: "",
            dt1: "7",
            sy6: "ܫܒܥ",
            dt2: "Sieben",
            lt12: "sayn",
            sy4: "ܫܒܥܐ",
            sy5: "ܫܰܒܥܳܐ ",
            lt11: "shbaĉ",
            sy9: "ܖ",
            sy7: "ܫܒܰܥ",
            lt10: "shabĉo"
        },
        {
            sy9: "",
            sy4: "ܡܫܚ ܙܒܢܐ",
            dt2: "",
            sy7: "ܟܪܳܢܳܡܶܬܪܳܐ",
            dt3: "",
            id: "6263",
            sy5: "ܡܳܫܰܚ ܙܰܒܢܳܐ",
            sy6: "ܟܪܢܡܬܪܐ",
            dt1: "Zeitmesser",
            lt11: "kronometro",
            lt12: "",
            sy8: "",
            lt10: "moshaĥ zabno"
        },
        {
            sy7: "",
            sy5: "ܢܺܝܣܰܢܶܐ",
            id: "3285",
            sy4: "ܢܝܣܐܢܐ",
            dt1: "Nisane",
            sy6: "",
            sy9: "",
            dt3: "",
            lt10: "ܢܝܣܢܐ",
            lt11: "ܢܝܣܢܐ",
            dt2: "Nissane",
            lt12: "",
            sy8: ""
        },
        {
            sy6: "ܡܫܬܘܬܐ",
            dt2: "Trauung",
            sy9: "ܒܥܺܝܠܽܘܬ݂ܳܐ",
            dt1: "Heirat",
            sy5: "ܙܺܘܘܳܓ݂ܳܐ",
            dt3: "",
            lt12: "bĉilutho",
            sy7: "ܡܶܫܬܽܘܬ݂ܳܐ",
            sy8: "ܒܥܝܠܘܬܐ",
            id: "1941",
            lt11: "meshtutho",
            lt10: "zuwoğo",
            sy4: "ܙܘܘܓܐ"
        },
        {
            sy4: "ܙܘܥܐ ܕܢܫܐ",
            id: "1367",
            dt2: "",
            dt3: "",
            lt11: "",
            lt10: "zauĉo d`neshe",
            dt1: "Frauenbewegung",
            lt12: "",
            sy5: "ܙܰܘܥܳܐ ܕܢܶܫ̈ܶܐ",
            sy6: "",
            sy7: "",
            sy8: "",
            sy9: ""
        },
        {
            dt1: "Gastgeber",
            dt3: "",
            sy6: "ܡܙܡܢܢܐ",
            dt2: "Empfang",
            sy4: "ܡܩܒܠܢܐ",
            sy5: "ܡܰܩܒܠܳܢܳܐ",
            sy9: "",
            lt10: "mqablono",
            sy7: "ܡܙܰܡܢܳܢܳܐ",
            id: "1485",
            sy8: "",
            lt11: "mzamnono",
            lt12: ""
        },
        {
            lt12: "",
            lt11: "mkono",
            sy5: "ܡܟܳܢܳܝܳܐ",
            sy8: "",
            dt3: "",
            id: "5921",
            lt10: "mkonoyo",
            dt2: "von Geburt an vorhanden",
            sy9: "",
            sy4: "ܡܟܢܝܐ",
            dt1: "angeboren",
            sy6: "ܡܟܢܐ",
            sy7: "ܡܟܳܢܳܐ"
        },
        {
            dt2: "",
            sy5: "ܗܽܘܓܳܝܳܐ",
            sy7: "ܡܰܡܠܰܠܬܳܐ",
            sy4: "ܗܘܓܝܐ",
            sy6: "ܡܡܠܠܬܐ",
            lt10: "hugoyo",
            sy8: "",
            id: "2775",
            lt12: "",
            lt11: "mamlalto",
            sy9: "",
            dt3: "",
            dt1: "Lesung"
        },
        {
            lt11: "",
            lt12: "",
            dt3: "",
            dt1: "Friseur",
            sy5: "ܓܳܪܽܘܥܳܐ ",
            dt2: "",
            sy6: "",
            sy9: "",
            lt10: "goruĉo  ",
            sy7: "",
            sy8: "",
            sy4: "ܓܪܘܥܐ ",
            id: "1399"
        },
        {
            sy7: "",
            lt10: "ܓܝܕܘܬܐ",
            dt3: "",
            sy5: "ܓܺܝܕܽܘܬܰܐ",
            sy4: "ܓܝܕܘܬܐ",
            lt11: "ܓܝܕܘܬܐ",
            lt12: "",
            sy8: "",
            id: "1638",
            dt2: "",
            dt1: "Giduta",
            sy6: "",
            sy9: ""
        },
        {
            sy8: "",
            dt1: "Tischdecke",
            dt2: "",
            lt12: "",
            sy9: "",
            lt10: "ksoyo d`țeblitho",
            sy7: "",
            sy4: "ܟܣܝܐ ܕܛܒܠܝܬܐ",
            lt11: "",
            sy5: "ܟܣܳܝܳܐ ܕܛܶܒܠܺܝܬ݂ܳܐ",
            dt3: "",
            id: "4498",
            sy6: ""
        },
        {
            sy9: "",
            sy6: "",
            dt3: "",
            lt11: "",
            sy4: "ܦܠܚܐ ܕܒܝܬ ܦܘܠܚܢܐ",
            lt12: "",
            id: "1224",
            sy8: "",
            sy7: "",
            dt1: "Fabrikarbeiter",
            lt10: "faloĥo d`beth fulĥono",
            dt2: "",
            sy5: "ܦܰܠܳܚܳܐ ܕܒܶܝܬ݂ ܦܽܘܠܚܳܢܳܐ"
        },
        {
            sy5: "ܟܽܘܦܳܪܳܐ",
            sy7: "ܟܢܳܫܳܐ",
            id: "3676",
            dt2: "Säuberung",
            dt1: "Reinigung",
            lt11: "knosho",
            sy8: "ܡܢܩܕܘܬܐ",
            sy9: "ܡܢܰܩܕܽܘܬ݂ܳܐ",
            sy4: "ܟܘܦܪܐ",
            lt12: "mnaqdutho",
            lt10: "kuforo",
            dt3: "",
            sy6: "ܟܢܫܐ"
        },
        {
            sy7: "ܒܽܘܩܳܪܳܐ",
            lt12: "",
            lt10: "ĉuqobo",
            sy4: "ܥܘܩܒܐ",
            dt1: "Inspektion",
            dt2: "̤Kontrolle",
            sy8: "",
            sy5: "ܥܽܘܩܳܒܳܐ",
            sy6: "ܒܘܩܪܐ",
            lt11: "buqoro",
            dt3: "",
            sy9: "",
            id: "2214"
        },
        {
            dt2: "Beschnittener",
            sy5: "ܓܙܺܝܪܳܐ",
            id: "571",
            sy9: "ܡܢܺܝܓ݂ܳܐ",
            dt1: "beschnitten",
            lt12: "mniğo",
            lt10: "gziro",
            dt3: "",
            lt11: "gliĉo",
            sy4: "ܓܙܝܪܐ",
            sy8: "ܡܢܝܓܐ",
            sy7: "ܓܠܺܝܥܳܐ",
            sy6: "ܓܠܝܥܐ"
        },
        {
            id: "5014",
            lt12: "",
            dt3: "",
            dt1: "Wassertropfen",
            sy5: "ܛܽܘܦܬ݂ܳܐ ܕܡܰܝܳܐ",
            lt11: "dlofo d`mayo",
            lt10: "țuftho d`mayo",
            sy8: "",
            dt2: "",
            sy4: "ܛܘܦܬܐ ܕܡܝܐ",
            sy9: "",
            sy6: "ܕܠܦܐ ܕܡܝܐ",
            sy7: "ܕܠܳܦܳܐ ܕܡܰܝܳܐ"
        },
        {
            lt11: "",
            sy8: "",
            lt10: "ĥad wfelgo",
            sy4: "ܚܕ ܘܦܠܓܐ",
            sy7: "",
            dt3: "",
            lt12: "",
            sy5: "ܚܰܕ ܘܦܶܠܓܳܐ",
            dt1: "anderthalb",
            sy9: "",
            dt2: "",
            sy6: "",
            id: "5939"
        },
        {
            sy9: "ܠܳܐ ܡܶܕܶܡ",
            sy4: "ܦܩܘܕ",
            sy7: "ܒܒܳܥܽܘ",
            sy5: "ܦܩܽܘܕ",
            dt3: "Bitte sehr",
            sy8: "ܠܐ ܡܕܡ",
            lt10: "fquđ",
            sy6: "ܒܒܥܘ",
            lt11: "b`boĉu",
            id: "638",
            lt12: "lo medem",
            dt1: "Bitteschön",
            dt2: "Bitte"
        },
        {
            sy6: "",
            dt3: "",
            dt1: "Hand",
            sy7: "",
            lt10: "iđo",
            dt2: "",
            sy9: "",
            lt11: "",
            sy4: "ܐܝܕܐ",
            sy5: "ܐܺܝܕܳܐ",
            lt12: "",
            sy8: "",
            id: "1847"
        },
        {
            dt2: "",
            sy4: "ܣܝܒܪ",
            lt12: "",
            sy5: "ܣܰܝܒܰܪ",
            id: "3807",
            sy8: "",
            lt11: "ܣܝܒܪ",
            lt10: "ܣܝܒܪ",
            sy6: "",
            sy7: "",
            dt3: "",
            dt1: "Saibar",
            sy9: ""
        },
        {
            lt10: "lĥuđoyo",
            sy4: "ܠܚܘܕܝܐ",
            dt1: "einsam",
            sy7: "ܒܰܠܚܽܘܕ",
            dt3: "",
            dt2: "alleine",
            lt11: "balĥuđ",
            sy8: "",
            sy6: "ܒܠܚܘܕ",
            sy9: "",
            sy5: "ܠܚܽܘܕܳܝܳܐ",
            lt12: "",
            id: "1049"
        },
        {
            sy8: "",
            dt2: "",
            id: "4407",
            lt11: "",
            sy5: "ܚܶܠܦܳܐ",
            lt12: "",
            sy4: "ܚܠܦܐ",
            sy9: "",
            dt3: "",
            lt10: "ĥelfo",
            dt1: "Tausch",
            sy7: "",
            sy6: ""
        },
        {
            sy6: "",
            lt11: "",
            dt1: "Rauchwolke",
            sy8: "",
            dt2: "",
            sy5: "ܥܢܳܢܳܐ ܕܬܶܢܳܢܳܐ",
            id: "3623",
            sy4: "ܥܢܢܐ ܕܬܢܢܐ",
            sy9: "",
            lt12: "",
            dt3: "",
            lt10: "cnono d`tenono",
            sy7: ""
        },
        {
            sy7: "",
            dt2: "rechtmäßig",
            lt12: "",
            sy9: "",
            sy6: "",
            id: "2367",
            dt1: "juristisch",
            lt10: "qonunoith",
            sy8: "",
            sy4: "ܩܢܘܢܐܝܬ",
            dt3: "kanonisch",
            sy5: "ܩܳܢܽܘܢܳܐܝܺܬ݂",
            lt11: ""
        },
        {
            sy4: "ܦܪܝܫܘܬܐ",
            sy9: "",
            dt2: "Scheidung",
            sy5: "ܦܪܺܝܫܽܘܬ݂ܳܐ",
            lt11: "fuloğo",
            dt1: "Trennung",
            id: "4558",
            sy8: "",
            lt10: "frishutho",
            dt3: "Spaltung",
            sy7: "ܦܽܘܠܳܓ݂ܳܐ",
            lt12: "",
            sy6: "ܦܘܠܓܐ"
        },
        {
            sy4: "ܢܚܬ ܬܠܓܐ ܥܠ ܛܘܪܐ",
            lt12: "",
            sy6: "",
            dt1: "Der Schnee fiel auf den Berg",
            lt11: "",
            id: "865",
            sy7: "",
            lt10: "nĥet talgo ĉal țuro",
            sy8: "",
            dt2: "",
            sy9: "",
            sy5: "ܢܚܶܬ ܬܰܠܓܳܐ ܥܰܠ ܛܽܘܪܳܐ",
            dt3: ""
        },
        {
            sy6: "",
            sy7: "",
            id: "1056",
            lt10: "ĥđonoyo",
            dt3: "",
            sy4: "ܚܕܢܝܐ",
            sy8: "",
            sy5: "ܚܕܳܢܳܝܳܐ",
            lt12: "",
            sy9: "",
            dt1: "Einzahl",
            dt2: "Singular",
            lt11: ""
        },
        {
            sy7: "",
            dt2: "",
            lt10: "dagel",
            id: "5962",
            sy4: "ܕܓܠ",
            dt1: "anlügen",
            lt12: "",
            sy9: "",
            sy8: "",
            sy6: "",
            dt3: "",
            sy5: "ܕܰܓܶܠ",
            lt11: ""
        },
        {
            dt1: "vorgestern",
            sy6: "",
            sy9: "",
            dt2: "",
            dt3: "",
            sy7: "",
            sy5: "ܡܢܳܬ݂ܡܳܠܝ",
            id: "4902",
            lt11: "",
            sy4: "ܡܢܬܡܠܝ",
            sy8: "",
            lt10: "mnothmol",
            lt12: ""
        },
        {
            sy4: "ܚܪܝܦ ܣܓܝ",
            sy8: "",
            sy7: "",
            lt11: "",
            dt1: "zu scharf",
            sy5: "ܚܰܪܺܝܦ ܣܰܓܺܝ",
            sy9: "",
            id: "6309",
            sy6: "",
            dt3: "",
            lt10: "ĥarif sagi",
            lt12: "",
            dt2: ""
        },
        {
            sy4: "ܡܫܬܘܬܐ",
            sy9: "",
            dt1: "Hochzeit",
            sy7: "",
            lt12: "",
            id: "2036",
            lt10: "meshtutho",
            dt3: "",
            dt2: "",
            sy8: "",
            sy6: "",
            sy5: "ܡܶܫܬܽܘܬ݂ܳܐ",
            lt11: ""
        },
        {
            sy6: "ܬܢ",
            id: "3622",
            sy5: "ܬܰܢܶܢ",
            lt10: "tanen",
            sy4: "ܬܢܢ",
            lt12: "mtanen",
            sy9: "ܡܬܰܢܶܢ",
            dt2: "qualmen",
            sy7: "ܬܰܢ",
            sy8: "ܡܬܢܢ",
            dt1: "rauchen",
            lt11: "tan",
            dt3: ""
        },
        {
            sy7: "ܡܰܓܕܽܘܢܳܐ",
            sy4: "ܡܓܙܘܢܐ",
            lt10: "magzuno",
            sy6: "ܡܓܕܘܢܐ",
            dt2: "",
            dt3: "",
            sy9: "",
            lt12: "",
            sy5: "ܡܰܓܙܽܘܢܳܐ",
            id: "4138",
            lt11: "magđuno",
            sy8: "",
            dt1: "Sichel"
        },
        {
            dt3: "",
            dt2: "",
            sy8: "",
            lt11: "",
            sy7: "",
            lt12: "",
            sy9: "",
            sy5: "ܪܰܡܫܳܐ ܕܰܟܢܽܘܫܝܳܐ ܕܰܐܒܳܗ̈ܶܐ",
            lt10: "ramsho da`knushyo d`abohe",
            sy6: "",
            id: "1090",
            sy4: "ܪܡܫܐ ܕܟܢܘܫܝܐ ܕܐܒܗܐ",
            dt1: "Elternabend"
        },
        {
            dt1: "Joach",
            dt3: "",
            id: "2327",
            lt11: "ܝܘܐܚ",
            dt2: "",
            lt12: "",
            sy8: "",
            lt10: "ܝܘܐܚ",
            sy4: "ܝܘܐܚ",
            sy7: "",
            sy6: "",
            sy9: "",
            sy5: "ܝܽܘܐܳܚ"
        },
        {
            lt10: "ĉaroboyo",
            sy5: "ܥܰܪܳܒܳܝܳܐ",
            sy8: "",
            dt1: "garantiert",
            id: "1474",
            sy9: "",
            sy6: "",
            sy7: "",
            lt12: "",
            sy4: "ܥܪܒܝܐ",
            dt3: "",
            dt2: "",
            lt11: ""
        },
        {
            sy5: "ܡܶܫܬܰܥܝܳܢܳܐ ܕܐܶܣܦܺܝܪ ܪܶܓ݂ܠܳܐ",
            sy7: "",
            dt2: "Fussballspieler",
            dt3: "",
            sy9: "",
            lt12: "",
            sy6: "",
            lt11: "",
            sy8: "",
            lt10: "meshtaĉyono d`esfir reğlo",
            sy4: "ܡܫܬܥܝܢܐ ܕܐܣܦܝܪ ܪܓܠܐ",
            id: "1439",
            dt1: "Fußballspieler"
        },
        {
            dt2: "",
            sy4: "ܪܟܝܒܐܝܠ",
            lt12: "",
            id: "3598",
            sy9: "",
            sy8: "",
            lt10: "ܪܟܝܒܐܝܠ",
            sy5: "ܪܰܟܺܝܒܺܐܝܠ",
            sy6: "",
            dt1: "Rakibel",
            lt11: "ܪܟܝܒܐܝܠ",
            dt3: "",
            sy7: ""
        },
        {
            sy9: "",
            lt10: "ĥeworo",
            sy4: "ܚܘܪܐ",
            dt1: "weiß",
            dt3: "",
            sy6: "",
            lt11: "",
            lt12: "",
            dt2: "weiss",
            sy8: "",
            sy7: "",
            id: "5034",
            sy5: "ܚܶܘܳܪܳܐ"
        },
        {
            sy6: "",
            sy4: "ܙܘܒܢ ܩܝܣܐ",
            sy9: "",
            dt1: "Holzverkauf",
            dt3: "",
            sy5: "ܙܽܘܒܳܢ ܩܰܝ̈ܣܶܐ",
            lt10: "zubon qayse",
            lt12: "",
            sy7: "",
            id: "6049",
            dt2: "",
            sy8: "",
            lt11: ""
        },
        {
            id: "2458",
            sy5: "ܐܺܝܬ݂ܽܘܬܳܐ",
            lt11: "",
            sy4: "ܐܝܬܘܬܐ",
            dt3: "",
            sy8: "",
            sy7: "",
            lt12: "",
            dt2: "Essenz",
            lt10: "ithutho",
            sy9: "",
            sy6: "",
            dt1: "Kern"
        },
        {
            dt1: "genau",
            sy7: "",
            dt3: "ebenbürtig",
            lt12: "",
            id: "6343",
            sy4: "ܡܣܐܬܢܝܐ",
            lt10: "masathonoyo",
            lt11: "",
            sy8: "",
            sy9: "",
            sy6: "",
            sy5: "ܡܰܣܰܐܬ݂ܳܢܳܝܳܐ",
            dt2: "exakt"
        },
        {
            sy7: "",
            lt11: "ܢܥܡܢ",
            dt3: "",
            lt10: "ܢܥܡܢ",
            sy6: "",
            id: "3143",
            sy5: "ܢܰܥܡܰܢ",
            sy8: "",
            lt12: "",
            dt2: "",
            sy4: "ܢܥܡܢ",
            dt1: "Naaman",
            sy9: ""
        },
        {
            sy5: "ܒܰܝܬܽܘܢܳܐ",
            dt3: "",
            sy4: "ܒܝܬܘܢܐ",
            lt10: "baytuno",
            lt12: "",
            dt2: "",
            dt1: "Häuschen",
            sy6: "",
            lt11: "",
            id: "1898",
            sy9: "",
            sy7: "",
            sy8: ""
        },
        {
            dt3: "",
            sy9: "",
            sy7: "",
            id: "2148",
            sy6: "",
            dt1: "identifizieren",
            sy4: "ܚܘܝ ܗܝܝܘܬܗ",
            sy5: "ܚܰܘܺܝ ܗܺܝܝܽܘܬ݂ܶܗ",
            sy8: "",
            dt2: "",
            lt12: "",
            lt11: "",
            lt10: "ĥawi hiyuthe"
        },
        {
            sy5: "ܡܰܘܗܰܒܬ݂ܳܐ",
            dt3: "Talent",
            lt12: "",
            dt1: "Gabe",
            dt2: "Gabe Gottes",
            lt11: "mauhabtho d`aloho",
            lt10: "mauhabtho",
            sy7: "ܡܰܘܗܰܒܬ݂ܳܐ ܕܰܐܠܳܗܳܐ",
            sy6: "ܡܘܗܒܬܐ ܕܐܠܗܐ",
            sy4: "ܡܘܗܒܬܐ",
            sy9: "",
            id: "1449",
            sy8: ""
        },
        {
            sy4: "ܙܒܬܐ ܐܚܪܬܐ",
            dt3: "",
            sy9: "",
            dt2: "",
            lt11: "",
            sy7: "",
            sy5: "ܙܒܰܬܳܐ ܐ̱ܚܪܺܬܳܐ",
            lt12: "",
            sy8: "",
            lt10: "zbato ĥrito",
            sy6: "",
            id: "3166",
            dt1: "nächstes Mal"
        },
        {
            lt10: "wiqifedya",
            id: "5107",
            sy9: "",
            sy5: "ܘܺܝܩܺܝܦܶܕܝܰܐ",
            dt3: "",
            sy7: "",
            dt2: "",
            sy8: "",
            lt11: "",
            sy6: "",
            lt12: "",
            dt1: "Wikipedia",
            sy4: "ܘܝܩܝܦܕܝܐ"
        },
        {
            sy9: "",
            dt2: "mittags",
            dt1: "so gehen mittag",
            lt10: "ĥdor țahro",
            lt12: "",
            sy5: "ܚܕܳܪ ܛܰܗܪܳܐ",
            sy8: "",
            sy4: "ܚܕܪ ܛܗܪܐ",
            id: "5618",
            dt3: "gegen mittag",
            sy7: "ܦܰܠܓܶܐ ܕܝܰܘܡܳܐ",
            lt11: "falge dyaumo",
            sy6: "ܦܠܓܐ ܕܝܘܡܐ"
        },
        {
            lt11: "",
            dt1: "unterhalten",
            lt12: "",
            id: "4661",
            dt3: "",
            sy4: "ܡܣܬܘܕ",
            sy6: "",
            sy7: "",
            lt10: "mestawad",
            sy5: "ܡܶܣܬܰܘܰܕ",
            sy8: "",
            dt2: "",
            sy9: ""
        },
        {
            dt3: "",
            sy8: "",
            sy4: "ܚܙܝܐ",
            dt2: "",
            lt10: "ĥzoyo",
            lt12: "",
            lt11: "ĥyoro",
            id: "4095",
            sy6: "ܚܝܪܐ",
            dt1: "Sehen",
            sy5: "ܚܙܳܝܳܐ",
            sy9: "",
            sy7: "ܚܝܳܪܳܐ"
        },
        {
            sy6: "ܒܢܚܕܕ",
            sy8: "",
            dt1: "Ben Hadad",
            lt11: "ܒܢܗܕܕ",
            sy9: "",
            dt3: "",
            lt10: "ܒܢܗܕܕ",
            dt2: "",
            sy7: "ܒܶܢܚܰܕܰܕ",
            sy4: "ܒܢܗܕܕ",
            id: "546",
            lt12: "ܒܢܚܕܕ",
            sy5: "ܒܶܢܗܰܕܰܕ"
        },
        {
            lt10: "ܢܪܘܙ",
            dt1: "Narwez",
            dt3: "",
            sy5: "ܢܰܪܘܶܙ",
            sy6: "",
            dt2: "",
            sy8: "",
            sy9: "",
            lt11: "ܢܪܘܙ",
            lt12: "",
            id: "3205",
            sy7: "",
            sy4: "ܢܪܘܙ"
        },
        {
            lt10: "ܡܪܝܢ",
            sy8: "",
            dt1: "Marin",
            sy6: "",
            lt12: "",
            sy4: "ܡܪܝܢ",
            id: "2937",
            sy9: "",
            dt2: "",
            sy7: "",
            lt11: "ܡܪܝܢ",
            sy5: "ܡܰܪܺܝܢ",
            dt3: ""
        },
        {
            sy9: "",
            sy5: "ܝܰܒܪܽܘܕܺܝܰܐ",
            lt11: "ܝܒܪܘܕܝܐ",
            lt10: "ܝܒܪܘܕܝܐ",
            dt2: "",
            id: "2263",
            dt3: "",
            sy7: "",
            sy4: "ܝܒܪܘܕܝܐ",
            dt1: "Jabrudia",
            sy6: "",
            lt12: "",
            sy8: ""
        },
        {
            sy6: "",
            lt10: "ܐܝܠܚܘܒܐ",
            dt1: "Ilhuba",
            sy7: "",
            sy8: "",
            sy9: "",
            lt12: "",
            id: "2164",
            dt3: "",
            sy5: "ܐܺܝܠܚܽܘܒܰܐ",
            sy4: "ܐܝܠܚܘܒܐ",
            lt11: "ܐܝܠܚܘܒܐ",
            dt2: ""
        },
        {
            dt2: "eifrig",
            sy6: "",
            id: "172",
            lt12: "",
            sy7: "",
            sy5: "ܚܦܺܝܛܳܐ",
            dt3: "",
            lt11: "",
            dt1: "aktiv",
            sy4: "ܚܦܝܛܐ",
            sy9: "",
            sy8: "",
            lt10: "ĥfițo"
        },
        {
            dt1: "Wespennest",
            lt10: "qeno d`debure",
            sy7: "",
            sy9: "",
            lt12: "",
            sy8: "",
            sy4: "ܩܢܐ ܕܒܘܪܐ",
            lt11: "",
            id: "5074",
            sy6: "",
            sy5: "ܩܶܢܳܐ ܕܶܒܽܘ̈ܪܶܐ",
            dt3: "",
            dt2: ""
        },
        {
            sy4: "ܠܙܘܒܢܐ",
            dt3: "",
            sy9: "",
            dt2: "",
            sy7: "",
            sy6: "",
            lt12: "",
            dt1: "zu verkaufen",
            sy5: "ܠܙܽܘܒܳܢܳܐ",
            sy8: "",
            id: "6313",
            lt10: "l'zubono",
            lt11: ""
        },
        {
            lt11: "uĉdo",
            sy6: "ܐܘܥܕܐ",
            sy8: "",
            dt3: "",
            sy5: "ܗܳܫܳܐ",
            lt12: "",
            dt2: "",
            sy7: "ܐܽܘܥܕܳܐ",
            sy9: "",
            id: "2322",
            lt10: "hosho",
            dt1: "jetzt",
            sy4: "ܗܫܐ"
        },
        {
            lt10: "guĥko",
            dt1: "Lachen",
            lt11: "",
            sy9: "",
            sy7: "",
            dt3: "",
            sy5: "ܓܽܘܚܟܳܐ",
            dt2: "",
            sy6: "",
            sy8: "",
            lt12: "",
            sy4: "ܓܘܚܟܐ",
            id: "2689"
        },
        {
            dt3: "",
            lt11: "ܥܡܝܢܕܒ",
            sy8: "",
            lt10: "ܥܡܝܢܕܒ",
            sy9: "",
            sy6: "",
            sy5: "ܥܰܡܺܝܢܰܕܰܒ",
            dt2: "Amminodob",
            sy4: "ܥܡܝܢܕܒ",
            sy7: "",
            dt1: "Amminadab",
            lt12: "",
            id: "214"
        },
        {
            sy4: "ܗܕܐܝܙܝ",
            dt2: "",
            dt1: "Hadajisi",
            dt3: "",
            id: "1807",
            lt10: "ܗܕܐܝܙܝ",
            sy9: "",
            lt12: "",
            sy7: "ܚܰܕܰܐܺܝܙܺܝ",
            sy5: "ܗܰܕܰܐܺܝܙܺܝ",
            sy6: "ܚܕܐܝܙܝ",
            sy8: "",
            lt11: "ܗܕܐܝܙܝ"
        },
        {
            sy9: "",
            id: "575",
            lt11: "",
            sy6: "",
            sy5: "ܟܰܒܶܫ",
            sy8: "",
            lt10: "kabesh",
            dt3: "",
            dt1: "besetzen",
            lt12: "",
            dt2: "",
            sy4: "ܟܒܫ",
            sy7: ""
        },
        {
            id: "843",
            lt12: "",
            dt3: "",
            dt1: "Dein Name sei geheiligt",
            dt2: "geheiligt sei Dein Name",
            lt11: "",
            sy8: "",
            sy5: "ܢܶܬ݂ܩܰܕܰܫ ܫܡܳܟ݂",
            sy6: "",
            lt10: "nethqadash shmokh",
            sy4: "ܢܬܩܕܫ ܫܡܟ",
            sy9: "",
            sy7: ""
        },
        {
            sy9: "",
            sy8: "",
            lt11: "",
            lt10: "dahbo",
            sy4: "ܕܗܒܐ",
            id: "1680",
            lt12: "",
            sy5: "ܕܰܗܒܳܐ",
            sy6: "",
            dt3: "",
            dt2: "",
            dt1: "Gold",
            sy7: ""
        },
        {
            sy6: "",
            dt3: "",
            sy9: "",
            dt2: "",
            lt12: "",
            lt10: "muroyo d`esfir reğlo",
            id: "1440",
            dt1: "Fußballturnier",
            sy7: "",
            lt11: "",
            sy4: "ܡܘܪܝܐ ܕܐܣܦܝܪ ܪܓܠܐ",
            sy5: "ܡܽܘܪܳܝܳܐ ܕܐܶܣܦܺܝܪ ܪܶܓ݂ܠܳܐ",
            sy8: ""
        },
        {
            dt2: "abwesend",
            id: "4768",
            sy4: "ܐܒܝܕܐ",
            sy7: "",
            sy9: "",
            dt3: "",
            lt10: "abido",
            sy8: "",
            dt1: "vermisst",
            sy6: "",
            sy5: "ܐܰܒܺܝܕܳܐ",
            lt12: "",
            lt11: ""
        },
        {
            lt11: "ܝܗܘܕܝܬ",
            sy6: "ܝܗܘܕܝܬ",
            id: "2348",
            sy4: "ܝܗܘܕܝܬ",
            lt12: "ܝܗܘܕܝܬ",
            sy8: "",
            sy7: "ܝܶܗܽܘܕܺܝܬ",
            lt10: "ܝܗܘܕܝܬ",
            sy5: "ܝܽܗܘܕܺܝܬ",
            sy9: "",
            dt1: "Judith",
            dt3: "",
            dt2: ""
        },
        {
            sy4: "ܠܝܬ ܫܦܝܪ ܡܢܗ",
            lt10: "layt shafir mene",
            sy8: "",
            dt1: "keiner ist so schön wie er",
            lt11: "",
            sy5: "ܠܰܝܬ ܫܰܦܺܝܪ ܡܶܢܶܗ",
            dt3: "",
            id: "2448",
            dt2: "",
            sy6: "",
            sy9: "",
            lt12: "",
            sy7: ""
        },
        {
            sy4: "ܐܚܝܝܐ",
            lt11: "ܐܚܝܝܐ",
            dt1: "Ahija",
            sy8: "",
            lt12: "",
            sy9: "",
            dt3: "",
            sy7: "",
            dt2: "",
            id: "154",
            sy5: "ܐܰܚܺܝܝܰܐ",
            lt10: "ܐܚܝܝܐ",
            sy6: ""
        },
        {
            sy7: "ܠܽܘܩܳܐ",
            sy6: "ܠܘܩܐ",
            sy5: "ܠܽܘܩܰܐ",
            dt1: "Lukas",
            sy9: "",
            dt2: "",
            lt10: "ܠܘܩܐܣ",
            lt12: "ܠܘܩܐ",
            sy8: "",
            sy4: "ܠܘܩܐܣ",
            dt3: "",
            id: "2873",
            lt11: "ܠܘܩܐ"
        },
        {
            sy6: "ܫܪܒܬܐ",
            lt10: "shersho",
            sy8: "",
            sy9: "",
            sy5: "ܫܶܪܫܳܐ",
            dt1: "Herkunft",
            id: "1967",
            lt12: "",
            sy4: "ܫܪܫܐ",
            dt2: "",
            lt11: "sharbtho",
            dt3: "",
            sy7: "ܫܰܪܒܬ݂ܳܐ"
        },
        {
            sy8: "",
            sy6: "",
            id: "2495",
            sy7: "",
            sy5: "ܟܠܰܡܺܝܕܰܐ",
            dt3: "",
            lt10: "ܟܠܡܝܕܐ",
            dt2: "",
            sy4: "ܟܠܡܝܕܐ",
            dt1: "Klamida",
            lt11: "ܟܠܡܝܕܐ",
            sy9: "",
            lt12: ""
        },
        {
            sy9: "",
            dt1: "Frohes Fest",
            lt11: "brikh maulodo d`moran",
            dt2: "Frohe Weihnachten",
            sy7: "ܒܪܺܝܟ݂ ܡܰܘܠܳܕܳܐ ܕܡܳܪܰܢ",
            sy4: "ܥܐܕܐ ܒܪܝܟܐ",
            sy6: "ܒܪܝܟ݂ ܡܘܠܕܐ ܕܡܪܢ",
            dt3: "Fröhliche Weihnachten",
            sy8: "",
            id: "1405",
            lt12: "",
            lt10: "ĉiđo brikho",
            sy5: "ܥܺܐܕܳܐ ܒܪܺܝܟ݂ܳܐ"
        },
        {
            sy7: "ܙܢܝܐ",
            sy4: "ܣܢܝܐ",
            lt12: "ܙܢܝܐ",
            dt1: "Sonja",
            id: "4192",
            sy8: "",
            sy5: "ܣܢܝܐ",
            lt10: "ܣܢܝܐ",
            dt2: "",
            dt3: "",
            lt11: "ܣܢܝܐ",
            sy9: "",
            sy6: "ܙܢܝܐ"
        },
        {
            lt11: "ܕܢܝܐܝܠ",
            sy4: "ܕܢܝܐܝܠ",
            sy9: "",
            dt2: "Danyel",
            sy8: "",
            id: "799",
            lt12: "ܕܢܝܐܝܠ",
            sy6: "ܕܢܝܐܝܠ",
            sy7: "ܕܰܢܺܝܐܝܶܠ",
            sy5: "ܕܳܢܺܝܐܝܶܠ",
            dt1: "Daniel",
            dt3: "Doniel",
            lt10: "ܕܢܝܐܝܠ"
        },
        {
            lt12: "",
            lt11: "ܗܠܢܐ",
            sy4: "ܗܠܢܐ",
            id: "1952",
            dt1: "Helena",
            sy9: "",
            sy6: "",
            sy8: "",
            dt2: "",
            sy7: "",
            sy5: "ܗܶܠܶܢܰܐ",
            lt10: "ܗܠܢܐ",
            dt3: ""
        },
        {
            sy6: "ܚܘܕܐ",
            sy7: "ܚܳܘܕܰܐ",
            dt1: "Huda",
            lt11: "ܗܘܕܐ",
            dt2: "",
            sy5: "ܗܽܘܕܰܐ",
            id: "2063",
            sy8: "",
            sy4: "ܗܘܕܐ",
            lt12: "ܚܘܕܐ",
            dt3: "",
            sy9: "",
            lt10: "ܗܘܕܐ"
        },
        {
            sy8: "",
            dt2: "verbannen",
            sy6: "ܐܚܪܡ",
            lt12: "",
            sy4: "ܚܪܡ",
            lt10: "ĥram",
            dt3: "",
            sy5: "ܚܪܰܡ",
            sy7: "ܐܰܚܪܶܡ",
            sy9: "",
            id: "1215",
            dt1: "exkommunizieren",
            lt11: "aĥrem"
        },
        {
            lt11: "ܪܒܪܬܐ",
            lt12: "",
            sy5: "ܪܳܒܶܪܬܰܐ",
            dt3: "",
            sy4: "ܪܒܪܬܐ",
            id: "3732",
            sy7: "",
            sy9: "",
            sy6: "",
            sy8: "",
            lt10: "ܪܒܪܬܐ",
            dt2: "",
            dt1: "Roberta"
        },
        {
            sy4: "ܒܢܝܐ",
            dt2: "",
            sy7: "",
            dt3: "",
            lt12: "",
            sy6: "",
            sy8: "",
            lt11: "ܒܢܝܐ",
            sy9: "",
            id: "548",
            dt1: "Benaja",
            lt10: "ܒܢܝܐ",
            sy5: "ܒܶܢܰܝܰܐ"
        },
        {
            lt11: "ܣܒܪܬ",
            lt12: "",
            dt1: "Sabrat",
            sy9: "",
            sy8: "",
            sy6: "",
            dt3: "",
            sy7: "",
            sy4: "ܣܒܪܬ",
            sy5: "ܣܰܒܪܰܬ",
            lt10: "ܣܒܪܬ",
            dt2: "",
            id: "3788"
        },
        {
            sy5: "ܐܰܬ݂ܛܰܝܒ",
            sy4: "ܐܬܛܝܒ",
            lt10: "athțayb",
            id: "553",
            sy7: "ܡܚܰܕܶܪ ܪܽܘܚܳܟ݂",
            lt12: "",
            dt3: "",
            lt11: "mĥađer ruĥokh",
            dt2: "sei vorbereitet",
            sy6: "ܡܚܰܕܶܪ ܪܽܘܚܳܟ݂",
            sy9: "",
            sy8: "",
            dt1: "bereite dich vor"
        },
        {
            dt1: "abtrünnig",
            sy9: "",
            sy5: "ܡܰܪܚܩܳܢܳܐ",
            dt2: "",
            id: "5690",
            sy7: "",
            sy8: "",
            sy4: "ܡܪܚܩܢܐ",
            lt10: "marĥqono",
            lt12: "",
            dt3: "",
            lt11: "",
            sy6: ""
        },
        {
            dt1: "Leser",
            sy6: "",
            id: "2769",
            sy5: "ܩܳܪܽܘܝܳܐ",
            sy9: "",
            lt11: "",
            sy4: "ܩܪܘܝܐ",
            dt3: "",
            sy7: "",
            sy8: "",
            lt10: "qoruyo",
            dt2: "Lesender",
            lt12: ""
        },
        {
            sy6: "",
            dt3: "",
            sy7: "",
            dt2: "",
            sy4: "ܫܢܬ ܡܘܠܕܐ",
            lt12: "",
            lt11: "",
            lt10: "shnath maulodo",
            dt1: "Geburtsjahr",
            sy9: "",
            sy5: "ܫܢܰܬ݂ ܡܰܘܠܳܕܳܐ",
            id: "1504",
            sy8: ""
        },
        {
            dt1: "Floruta",
            lt10: "ܦܠܪܘܬܐ",
            sy4: "ܦܠܪܘܬܐ",
            dt3: "",
            sy9: "",
            dt2: "",
            sy8: "",
            lt12: "",
            id: "1334",
            sy5: "ܦܠܳܪܽܘܬܰܐ",
            sy7: "",
            lt11: "ܦܠܪܘܬܐ",
            sy6: ""
        },
        {
            dt2: "Asis",
            lt11: "ܥܙܝܙ",
            dt1: "Aziz",
            sy6: "",
            lt10: "ܥܙܝܙ",
            sy8: "",
            sy9: "",
            sy5: "ܥܰܙܺܝܙ",
            dt3: "",
            id: "408",
            lt12: "",
            sy7: "",
            sy4: "ܥܙܝܙ"
        },
        {
            id: "6106",
            sy5: "ܫܰܦܪܳܝܳܐ",
            dt1: "Frühaufsteher",
            sy4: "ܫܦܪܝܐ",
            lt12: "",
            lt10: "shafroyo",
            dt2: "",
            sy6: "ܒܟܝܪܝܐ",
            lt11: "bkhiroyo",
            sy9: "",
            sy7: "ܒܟ݂ܺܝܪܳܝܳܐ",
            dt3: "",
            sy8: ""
        },
        {
            lt10: "ĉuqabro",
            sy4: "ܥܘܩܒܪܐ",
            id: "2977",
            dt2: "",
            lt12: "",
            sy6: "ܥܒܘܓܪܐ",
            sy7: "ܥܳܒܽܘܓܪܳܐ",
            dt3: "",
            sy9: "",
            dt1: "Maus",
            lt11: "ĉobugro",
            sy5: "ܥܽܘܩܰܒܪܳܐ",
            sy8: ""
        },
        {
            sy9: "",
            sy6: "",
            id: "637",
            dt2: "sauer",
            lt12: "",
            dt1: "bitter",
            lt10: "mariro",
            lt11: "",
            sy7: "",
            sy8: "",
            sy4: "ܡܪܝܪܐ",
            sy5: "ܡܰܪܺܝܪܳܐ",
            dt3: ""
        },
        {
            sy7: "",
            sy6: "",
            lt11: "",
            dt1: "konjugieren",
            id: "6072",
            dt2: "",
            sy4: "ܣܪܥܦ",
            sy5: "ܣܰܪܥܶܦ",
            lt12: "",
            dt3: "",
            sy9: "",
            sy8: "",
            lt10: "sarĉef"
        },
        {
            lt11: "ܒܪܝܘܢܐ",
            dt1: "Barjawno",
            lt12: "",
            lt10: "ܒܪܝܘܢܐ",
            sy9: "",
            dt2: "Barjona",
            sy8: "",
            dt3: "",
            id: "455",
            sy7: "",
            sy6: "",
            sy4: "ܒܪܝܘܢܐ",
            sy5: "ܒܰܪܝܰܘܢܳܐ"
        },
        {
            lt10: "shfiĉutho",
            sy6: "",
            dt1: "Großzügigkeit",
            dt2: "Reichlichkeit",
            sy4: "ܫܦܝܥܘܬܐ",
            sy7: "",
            id: "1744",
            lt11: "",
            sy9: "",
            sy8: "",
            dt3: "",
            sy5: "ܫܦܽܝܥܽܘܬ݂ܳܐ",
            lt12: ""
        },
        {
            lt10: "makrukhto",
            sy8: "",
            sy4: "ܡܟܪܘܟܬܐ",
            id: "3880",
            dt1: "Schal",
            lt12: "",
            sy6: "",
            dt3: "",
            sy5: "ܡܰܟܪܽܘܟ݂ܬܳܐ",
            sy9: "",
            lt11: "",
            sy7: "",
            dt2: "Halstuch"
        },
        {
            sy8: "",
            lt10: "rozo d'malkutho",
            sy6: "",
            sy5: "ܐ̱ܪܳܙܳܐ ܕܡܰܠܟܽܘܬ݂ܳܐ",
            lt12: "",
            sy7: "",
            dt1: "Staatsgeheimnis",
            dt2: "Staatsgeheimnis",
            dt3: "",
            sy9: "",
            id: "6208",
            sy4: "ܐܪܙܐ ܕܡܠܟܘܬܐ",
            lt11: ""
        },
        {
            sy4: "ܪܕܝܐܝܬ",
            sy5: "ܪܰܕܝܳܐܝܺܬ݂",
            lt12: "",
            id: "3100",
            sy6: "ܐܝܬܝܩܝ",
            sy9: "",
            dt3: "",
            dt2: "",
            dt1: "moralisch",
            lt10: "rađyoith",
            sy8: "",
            sy7: "ܐܺܝܬ݂ܺܝܩܺܝ",
            lt11: "ithiqi"
        },
        {
            sy4: "ܡܪܝ ܬܐܘܡܐ ܫܠܝܚܐ",
            sy9: "",
            sy7: "",
            lt12: "",
            sy5: "ܡܳܪܝ̱ ܬܳܐܘܡܰܐ ܫܠܺܝܚܳܐ",
            sy8: "",
            dt1: "Apostel Mor Toma",
            dt2: "",
            lt10: "mor toma shliĥo",
            dt3: "",
            id: "260",
            lt11: "",
            sy6: ""
        },
        {
            sy6: "ܐܝܟܢ ܐܝܬܝܢ ܚܢܢ",
            sy8: "ܐܝܟܢܐ",
            dt2: "wie geht es uns",
            sy5: "ܐܰܝܟܰܢ ܐܺܝܬ݂ܰܝܢ ܚܢܰܢ",
            id: "5090",
            lt11: "aykan ithayn hnan",
            lt12: "aykano",
            dt3: "",
            sy4: "ܐܝܟܢ ܐܝܬܝܢ ܚܢܢ",
            sy7: "ܐܰܝܟܰܢ ܐܺܝܬ݂ܰܝܢ ܚܢܰܢ",
            sy9: "ܐܰܝܟܰܢܳܐ",
            lt10: "aykan ithayn hnan",
            dt1: "wie geht’s uns"
        },
        {
            dt3: "",
            dt1: "Kleister",
            lt11: "teto",
            sy6: "ܬܬܐ",
            sy4: "ܕܒܩܐ",
            dt2: "Uhu",
            sy9: "",
            sy7: "ܬܶܬܳܐ",
            sy8: "",
            lt10: "debqo",
            sy5: "ܕܶܒܩܳܐ",
            id: "2521",
            lt12: ""
        },
        {
            sy4: "ܕܝܠܟܝ",
            lt10: "dilekh",
            dt3: "dein",
            sy8: "",
            sy7: "ܕܺܝܠܳܟ݂",
            sy5: "ܕܺܝܠܶܟ݂ܝ̱",
            lt11: "dilokh",
            lt12: "",
            dt1: "deine",
            dt2: "deiner",
            id: "846",
            sy6: "ܕܝܠܟ",
            sy9: ""
        },
        {
            dt3: "",
            dt1: "siehe dort",
            sy6: "",
            sy9: "",
            lt10: "ĥur tamon",
            sy7: "",
            dt2: "",
            sy5: "ܚܽܘܪ ܬܰܡܳܢ",
            sy4: "ܚܘܪ ܬܡܢ",
            sy8: "",
            lt12: "",
            id: "4151",
            lt11: ""
        },
        {
            dt3: "",
            lt11: "foquĉutho",
            id: "676",
            sy6: "ܦܩܘܥܘܬܐ",
            sy9: "",
            dt2: "Miene",
            lt12: "",
            lt10: "foquĉto",
            sy5: "ܦܳܩܽܘܥܬܳܐ",
            sy4: "ܦܩܘܥܬܐ",
            sy7: "ܦܳܩܽܘܥܽܘܬ݂ܳܐ",
            sy8: "",
            dt1: "Bombe"
        },
        {
            id: "1629",
            dt3: "",
            sy7: "",
            lt10: "tirtho",
            sy6: "",
            lt11: "",
            sy8: "",
            sy4: "ܬܐܪܬܐ",
            dt1: "Gewissen",
            lt12: "",
            sy9: "",
            sy5: "ܬܺܐܪܬ݂ܳܐ",
            dt2: ""
        },
        {
            sy8: "",
            sy9: "",
            dt2: "",
            sy6: "",
            dt1: "Besuch",
            sy5: "ܣܳܥܽܘܪܽܘܬ݂ܳܐ",
            sy4: "ܣܥܘܪܘܬܐ",
            lt12: "",
            id: "578",
            lt11: "",
            lt10: "soĉurutho",
            sy7: "",
            dt3: ""
        },
        {
            dt2: "",
            lt11: "ܥܬܪܬܗ",
            dt1: "Atarateh",
            dt3: "",
            id: "322",
            sy8: "",
            sy7: "",
            lt10: "ܥܬܪܬܗ",
            sy4: "ܥܬܪܬܗ",
            lt12: "",
            sy9: "",
            sy6: "",
            sy5: "ܥܰܬܰܪܰܬܶܗ"
        },
        {
            dt1: "Murat",
            dt2: "",
            dt3: "",
            sy7: "",
            sy8: "",
            sy4: "ܡܘܪܐܕ",
            lt10: "ܡܘܪܐܕ",
            lt12: "",
            sy5: "ܡܽܘܪܰܐܕ",
            sy6: "",
            id: "3126",
            sy9: "",
            lt11: "ܡܘܪܐܕ"
        },
        {
            sy6: "ܦܨܝܚܘܬܐ",
            dt1: "Freude ",
            sy8: "",
            dt2: "Vergnügen",
            sy9: "",
            lt12: "",
            lt11: "fŝiĥutho",
            dt3: "",
            sy7: "ܦܨܺܝܚܽܘܬ݂ܳܐ",
            sy5: "ܚܰܕܽܘܬ݂ܳܐ",
            sy4: "ܚܕܘܬܐ",
            lt10: "ĥadutho",
            id: "1390"
        },
        {
            sy9: "",
            lt10: "sharirutho",
            sy7: "",
            sy4: "ܫܪܝܪܘܬܐ",
            dt1: "Wahrhaftigkeit",
            dt2: "",
            sy6: "",
            id: "4958",
            lt12: "",
            sy5: "ܫܰܪܺܝܪܽܘܬ݂ܳܐ",
            lt11: "",
            dt3: "",
            sy8: ""
        },
        {
            dt2: "",
            sy8: "",
            sy7: "ܡܰܘܗܰܒܬ݂ ܒܽܘܝܳܐܳܐ",
            sy5: "ܦܽܘܪܥܳܢ ܒܽܘܝܳܐܳܐ",
            id: "4584",
            sy9: "",
            dt3: "",
            lt10: "furĉon buyoyo",
            dt1: "Trostpreis",
            sy4: "ܦܘܪܥܢ ܒܘܝܐܐ",
            lt11: "mauhbath buyoyo",
            lt12: "",
            sy6: "ܡܘܗܒܬ ܒܘܝܐܐ"
        },
        {
            dt3: "",
            sy8: "",
            lt11: "",
            sy5: "ܦܽܘܪܫܳܢ ܙܰܒܢܳܐ",
            sy4: "ܦܘܪܫܢ ܙܒܢܐ",
            sy7: "",
            sy9: "",
            dt2: "Zeiteinteilung",
            sy6: "",
            dt1: "Zeitverteilung",
            lt10: "furshon zabno",
            lt12: "",
            id: "6255"
        },
        {
            lt10: "srofto",
            dt3: "",
            sy4: "ܣܪܦܬܐ",
            sy7: "",
            dt1: "Suppe",
            sy5: "ܣܪܳܦܬܳܐ",
            id: "4318",
            lt12: "",
            sy9: "",
            dt2: "",
            lt11: "",
            sy8: "",
            sy6: ""
        },
        {
            id: "3870",
            lt12: "ܫܐܦܝ",
            dt1: "Schafi",
            dt2: "",
            sy5: "ܫܰܦܺܝ",
            lt10: "ܫܦܝ",
            sy8: "",
            sy7: "ܫܰܐܦܺܝ",
            lt11: "ܫܦܝ",
            sy9: "",
            dt3: "",
            sy6: "ܫܐܦܝ",
            sy4: "ܫܦܝ"
        },
        {
            id: "488",
            dt3: "",
            sy4: "ܫܪܫ",
            sy6: "ܫܬܣ",
            dt2: "gründen auf",
            lt10: "sharesh",
            sy8: "",
            sy9: "",
            dt1: "basieren auf",
            sy5: "ܫܰܪܶܫ",
            lt11: "shates",
            lt12: "",
            sy7: "ܫܰܬܶܣ"
        },
        {
            dt1: "Förderer",
            id: "6089",
            sy9: "ܡܰܛܶܐܒܳܢܳܐ",
            dt2: "",
            lt12: "mațebono",
            dt3: "",
            sy4: "ܡܠܒܒܢܐ",
            lt11: "mnaĥmono",
            lt10: "mlabbono",
            sy8: "ܡܛܐܒܢܐ",
            sy5: "ܡܠܰܒܒܳܢܳܐ",
            sy6: "ܡܢܚܡܢܐ",
            sy7: "ܡܢܰܚܡܳܢܳܐ"
        },
        {
            sy5: "ܡܰܓ݂ܕܰܠܰܐ",
            lt12: "ܡܓܕܠܐ",
            lt10: "ܡܓܕܠܢܐ",
            dt1: "Magdalena",
            sy9: "",
            sy6: "ܡܓܕܠܐ",
            id: "2885",
            sy4: "ܡܓ݂ܕܠܢܐ",
            dt2: "",
            dt3: "",
            sy7: "ܡܰܓ݂ܶܕܠܰܐ",
            lt11: "ܡܓܕܠܢܐ",
            sy8: ""
        },
        {
            sy5: "ܡܶܠܰܬ݂ ܐܺܝܩܳܪܳܐ",
            sy8: "",
            dt1: "Ehrenwort",
            sy7: "",
            lt10: "melath iqoro",
            dt3: "",
            sy4: "ܡܠܬ ܐܝܩܪܐ",
            lt11: "",
            sy9: "",
            sy6: "",
            id: "1020",
            lt12: "",
            dt2: ""
        },
        {
            sy7: "ܢܰܦܥܳܐ",
            id: "963",
            sy4: "ܣܦܝܐ",
            sy5: "ܣܶܦܳܝܳܐ",
            dt2: "",
            dt3: "",
            sy6: "ܢܦܥܐ",
            sy8: "",
            dt1: "Droge",
            lt11: "nafĉo",
            sy9: "",
            lt12: "",
            lt10: "sefoyo"
        },
        {
            lt10: "masrĥo",
            lt12: "",
            sy9: "",
            dt3: "",
            sy5: "ܡܰܣܪܚܳܐ",
            sy6: "",
            id: "738",
            sy8: "",
            sy4: "ܡܣܪܚܐ",
            lt11: "",
            dt2: "Theaterbühne",
            dt1: "Bühne",
            sy7: ""
        },
        {
            lt10: "ܟܘܙܐ",
            lt12: "",
            sy6: "ܟܘܙܐ",
            sy9: "",
            sy5: "ܟܳܘܙܰܐ",
            dt3: "",
            sy7: "ܟܽܘܙܳܐ",
            sy8: "",
            lt11: "ܟܘܙܐ",
            dt2: "",
            dt1: "Kuza",
            id: "2683",
            sy4: "ܟܘܙܐ"
        },
        {
            dt3: "Kaufsucht",
            sy6: "ܠܗܝܩܘܬ ܕܫܩܠܐ",
            sy5: "ܠܗܺܝܩܽܘܬ݂ ܕܙܶܒܢܳܐ",
            id: "2440",
            sy9: "ܟܪܺܝܗܽܘܬ݂ܳܐ ܕܙܶܒܢܳܐ",
            sy8: "ܟܪܝܗܘܬܐ ܕܙܒܢܐ",
            sy4: "ܠܗܝܩܘܬ ܕܙܒܢܐ",
            dt1: "Kauflust",
            lt11: "lhiquth d`shqolo",
            lt10: "lhiquth d`zebno",
            lt12: "krihutho d`zebno",
            dt2: "Kaufrausch",
            sy7: "ܠܗܺܝܩܽܘܬ݂ ܕܫܩܳܠܳܐ"
        },
        {
            sy9: "",
            lt12: "",
            sy5: "ܐܰܘܓܳܘܣܛܳܘܣ",
            lt11: "ܐܓܘܣܛܘܣ",
            dt2: "",
            sy8: "",
            dt3: "",
            sy4: "ܐܓܘܣܛܘܣ",
            lt10: "ܐܓܘܣܛܘܣ",
            sy7: "",
            sy6: "",
            id: "371",
            dt1: "Augustos"
        },
        {
            dt1: "überraschen",
            sy9: "",
            sy6: "",
            sy7: "",
            lt11: "",
            sy5: "ܥܪܰܨ",
            sy8: "",
            lt10: "ĉraŝ",
            id: "4616",
            dt2: "",
            lt12: "",
            sy4: "ܥܪܨ",
            dt3: ""
        },
        {
            sy5: "ܛܥܳܐ",
            sy9: "",
            sy8: "",
            dt3: "",
            lt11: "",
            lt10: "țĉo",
            sy7: "",
            sy6: "",
            dt1: "vergessen",
            id: "4734",
            dt2: "",
            sy4: "ܛܥܐ",
            lt12: ""
        },
        {
            sy9: "",
            lt10: "mshakhrutho",
            sy4: "ܡܫܟܪܘܬܐ",
            sy5: "ܡܫܰܟ݂ܪܽܘܬ݂ܳܐ",
            lt12: "",
            dt2: "",
            sy6: "ܡܝܡܣܘܬܐ",
            sy7: "ܡܺܝܡܣܽܘܬ݂ܳܐ",
            id: "6129",
            lt11: "mimsutho",
            dt1: "Obszönität",
            sy8: "",
            dt3: ""
        },
        {
            lt11: "ܙܠܓܬ",
            sy7: "",
            sy5: "ܙܰܠܓܰܬ",
            id: "5251",
            dt1: "Zalgat",
            lt12: "",
            lt10: "ܙܠܓܬ",
            sy9: "",
            sy4: "ܙܠܓܬ",
            sy8: "",
            dt2: "",
            sy6: "",
            dt3: ""
        },
        {
            lt11: "",
            dt1: "zählbar",
            dt3: "",
            lt12: "",
            lt10: "methmanyono",
            sy6: "",
            sy4: "ܡܬܡܢܝܢܐ",
            sy9: "",
            dt2: "",
            id: "5224",
            sy5: "ܡܶܬ݂ܡܰܢܝܳܢܳܐ",
            sy8: "",
            sy7: ""
        },
        {
            sy4: "ܠܡܘܢ",
            dt1: "weswegen",
            sy8: "",
            id: "5078",
            lt10: "lmun",
            lt11: "",
            dt3: "",
            dt2: "weshalb",
            sy9: "",
            sy6: "",
            sy7: "",
            lt12: "",
            sy5: "ܠܡܽܘܢ"
        },
        {
            sy9: "ܢܶܩܒܳܐ",
            sy4: "ܒܙܥܐ",
            lt11: "naqwo",
            sy7: "ܢܰܩܘܳܐ",
            lt12: "neqbo",
            dt3: "",
            sy6: "ܢܩܘܐ",
            id: "2850",
            sy8: "ܢܩܒܐ",
            lt10: "besĉo",
            dt1: "Loch",
            dt2: "Schlitz",
            sy5: "ܒܶܙܥܳܐ"
        },
        {
            lt11: "",
            sy9: "",
            sy6: "",
            dt2: "",
            dt3: "",
            sy7: "",
            lt12: "",
            sy5: "ܒܰܨܠܳܐ",
            sy4: "ܒܨܠܐ",
            dt1: "Zwiebel",
            id: "5400",
            lt10: "baŝlo",
            sy8: ""
        },
        {
            lt10: "taĥlufo",
            dt1: "Delegierter",
            id: "6442",
            sy5: "ܬܰܚܠܽܘܦܳܐ",
            sy6: "",
            sy8: "",
            dt2: "Abgeordneter",
            sy9: "",
            sy7: "",
            sy4: "ܬܚܠܘܦܐ",
            dt3: "",
            lt12: "",
            lt11: ""
        },
        {
            dt1: "Julia",
            dt3: "",
            sy4: "ܝܘܠܝܐ",
            lt11: "ܝܘܠܝܐ",
            lt12: "",
            sy5: "ܝܽܘܠܝܰܐ",
            sy9: "",
            dt2: "",
            lt10: "ܝܘܠܝܐ",
            sy8: "",
            sy6: "",
            sy7: "",
            id: "2356"
        },
        {
            lt10: "ܝܘܠܝܐܢ",
            sy5: "ܝܽܘܠܝܰܐܢ",
            dt3: "",
            id: "2357",
            sy8: "",
            dt2: "",
            dt1: "Julian",
            sy4: "ܝܘܠܝܐܢ",
            sy7: "",
            lt11: "ܝܘܠܝܐܢ",
            sy6: "",
            sy9: "",
            lt12: ""
        },
        {
            dt3: "",
            lt12: "",
            dt1: "sitzen",
            dt2: "",
            sy7: "",
            lt10: "yoteb",
            sy4: "ܝܬܒ",
            sy6: "",
            id: "4165",
            sy8: "",
            sy9: "",
            sy5: "ܝܳܬܶܒ",
            lt11: ""
        },
        {
            lt10: "madĉekh",
            dt1: "löschen",
            sy6: "",
            lt12: "",
            lt11: "",
            id: "2854",
            sy5: "ܡܰܕܥܶܟ݂",
            dt2: "",
            dt3: "",
            sy9: "",
            sy7: "",
            sy4: "ܡܕܥܟ",
            sy8: ""
        },
        {
            dt1: "Hiobsgeduld",
            lt11: "",
            sy6: "",
            sy4: "ܡܣܝܒܪܢܘܬܐ ܕܝܝܘܒ",
            dt3: "",
            sy8: "",
            id: "6320",
            lt12: "",
            sy9: "",
            dt2: "",
            sy7: "",
            lt10: "msaybronutho d'iyub",
            sy5: "ܡܣܰܝܒܪܳܢܽܘܬ݂ܳܐ ܕܺܝܝܽܘܒ"
        },
        {
            sy6: "",
            sy9: "",
            sy4: "ܟܢܘܫܝܐ",
            lt12: "",
            id: "5855",
            lt10: "knushoyo",
            sy7: "",
            dt3: "",
            sy8: "",
            dt2: "",
            dt1: "als Ergebnis",
            lt11: "",
            sy5: "ܟܢܽܘܫܳܝܳܐ"
        },
        {
            id: "3290",
            lt10: "dayroyto",
            sy7: "",
            dt3: "",
            sy9: "",
            sy4: "ܕܝܪܝܬܐ",
            sy5: "ܕܰܝܪܳܝܬܳܐ",
            lt11: "",
            dt2: "",
            sy6: "",
            sy8: "",
            lt12: "",
            dt1: "Nonne"
        },
        {
            dt1: "Tabakdose",
            id: "4349",
            sy8: "",
            lt12: "",
            lt10: "sanduq d`tutun",
            sy9: "",
            sy5: "ܣܰܢܕܽܘܩ ܬܽܘܬܽܘܢ",
            lt11: "",
            dt3: "",
            dt2: "",
            sy4: "ܣܢܕܘܩ ܬܘܬܘܢ",
            sy6: "",
            sy7: ""
        },
        {
            dt3: "",
            lt11: "",
            sy6: "",
            id: "2544",
            sy8: "",
            sy5: "ܒܪܳܟܳܐ",
            sy4: "ܒܪܟܐ",
            sy9: "",
            sy7: "",
            dt1: "Kniebeuge",
            lt10: "broko",
            dt2: "",
            lt12: ""
        },
        {
            lt12: "",
            dt1: "Reparateur",
            dt2: "",
            sy7: "ܡܬܰܩܢܳܢܳܐ",
            sy4: "ܡܚܕܬܢܐ",
            sy6: "ܡܬܩܢܢܐ",
            lt11: "mtaqnono",
            sy5: "ܡܚܰܕܬ݂ܳܢܳܐ",
            sy8: "",
            lt10: "mĥadthono",
            dt3: "",
            sy9: "",
            id: "3696"
        },
        {
            dt1: "Betteln",
            sy9: "",
            sy5: "ܚܳܕܽܘܪܽܘܬ݂ܳܐ",
            sy4: "ܚܕܘܪܘܬܐ",
            lt12: "",
            dt3: "",
            dt2: "Bettlerei",
            id: "593",
            sy8: "",
            lt10: "ĥođurutho",
            lt11: "",
            sy7: "",
            sy6: ""
        },
        {
            lt11: "",
            sy6: "",
            dt1: "Trauzeuge",
            sy8: "",
            sy4: "ܫܘܫܒܝܢܐ",
            dt3: "",
            lt10: "shaushbino",
            sy7: "",
            lt12: "",
            sy5: "ܫܰܘܫܒܺܝܢܳܐ",
            id: "4554",
            dt2: "Pate",
            sy9: ""
        },
        {
            sy6: "",
            sy9: "",
            id: "2470",
            lt12: "",
            lt11: "ܟܐܡܘܢܐ",
            dt2: "",
            sy5: "ܟܺܐܡܽܘܢܳܐ",
            dt3: "",
            lt10: "ܟܐܡܘܢܐ",
            sy8: "",
            sy4: "ܟܐܡܘܢܐ",
            dt1: "Kimuno",
            sy7: ""
        },
        {
            sy5: "ܙܟ݂ܰܪܝܰܐ",
            sy9: "",
            lt12: "ܙܟ݂ܪܝܐ",
            dt3: "",
            sy8: "",
            sy4: "ܙܟ݂ܪܝܐ",
            sy7: "ܙܰܟ݂ܰܪܝܰܐ",
            lt11: "ܙܟ݂ܪܝܐ",
            dt2: "Sacharija",
            lt10: "ܙܟ݂ܪܝܐ",
            id: "3792",
            dt1: "Sacharia",
            sy6: "ܙܟܪܝܐ"
        },
        {
            sy4: "ܡܘܕܥܢܘܬܐ",
            sy8: "",
            lt11: "",
            lt12: "",
            sy5: "ܡܰܘܕܥܳܢ̈ܽܘܬ݂ܳܐ",
            sy6: "",
            sy9: "",
            dt1: "Daten",
            sy7: "",
            dt2: "",
            lt10: "mauđĉonwotho",
            id: "824",
            dt3: ""
        },
        {
            sy7: "ܫܽܘܥܺܝܬ݂ܳܐ",
            sy8: "",
            lt12: "",
            lt10: "swodo",
            lt11: "shuĉitho",
            dt2: "Unterhaltung",
            sy6: "ܫܘܥܝܬܐ",
            sy9: "",
            dt3: "",
            sy5: "ܣܘܳܕܳܐ",
            dt1: "Gespräch",
            id: "1602",
            sy4: "ܣܘܕܐ"
        },
        {
            sy4: "ܥܝܕܐ ܕܡܛܠܠܐ",
            sy9: "",
            lt12: "",
            dt2: "",
            sy6: "",
            id: "6418",
            sy5: "ܥܺܐܕܳܐ ܕܰܡ̈ܛܰܠ̱ܠܶܐ",
            lt10: "ĉiđo da`mțalle",
            dt3: "",
            sy7: "",
            sy8: "",
            dt1: "Laubhüttenfest",
            lt11: ""
        },
        {
            sy4: "ܡܝܟ݂ܐ",
            sy9: "",
            sy7: "ܡܺܝܟ݂ܰܐ",
            dt1: "Micha",
            sy6: "ܡܝܟܐ",
            lt11: "ܡܝܟ݂ܐ",
            dt3: "",
            sy5: "ܡܺܝܟ݂ܳܐ",
            lt12: "ܡܝܟ݂ܐ",
            dt2: "",
            id: "3015",
            lt10: "ܡܝܟ݂ܐ",
            sy8: ""
        },
        {
            dt2: "",
            sy7: "",
            sy8: "",
            lt10: "meshĥo d`zeyte",
            sy6: "",
            lt11: "",
            sy4: "ܡܫܚܐ ܕܙܝܬܐ",
            lt12: "",
            sy5: "ܡܶܫܚܳܐ ܕܙܶܝܬܶܐ",
            sy9: "",
            dt3: "",
            dt1: "Olivenöl",
            id: "3371"
        },
        {
            sy5: "ܫܓ݂ܳܪܳܐ",
            id: "6008",
            sy4: "ܫܓܪܐ",
            lt10: "shğoro",
            lt12: "",
            dt2: "",
            lt11: "",
            sy8: "",
            sy6: "",
            dt1: "Anzündholz",
            sy9: "",
            sy7: "",
            dt3: ""
        },
        {
            id: "4888",
            dt3: "",
            dt2: "",
            lt11: "",
            lt10: "bazban zban",
            sy6: "",
            sy7: "",
            dt1: "von Zeit zu Zeit",
            sy8: "",
            lt12: "",
            sy4: "ܒܙܒܢ ܙܒܢ",
            sy9: "",
            sy5: "ܒܰܙܒܰܢ ܙܒܰܢ"
        },
        {
            sy9: "",
            sy5: "ܐܶܬ݂ܺܝܠܶܕ",
            lt12: "",
            id: "1497",
            dt3: "",
            dt1: "geboren werden",
            sy6: "",
            dt2: "geboren",
            sy4: "ܐܬܝܠܕ",
            lt11: "",
            lt10: "ethileđ",
            sy8: "",
            sy7: ""
        },
        {
            sy7: "",
            dt3: "",
            sy6: "",
            id: "6206",
            dt1: "Mutmaßung",
            lt10: "shğimutho",
            lt12: "",
            sy8: "",
            sy4: "ܫܓܝܡܘܬܐ",
            sy9: "",
            lt11: "",
            sy5: "ܫܓ݂ܺܝܡܽܘܬ݂ܳܐ",
            dt2: "Vermutung"
        },
        {
            sy7: "",
            sy5: "ܡܰܪܓܳܢܳܝܳܐ",
            sy6: "",
            sy4: "ܡܪܓܢܝܐ",
            lt11: "",
            dt1: "perlförmig",
            dt2: "perlig",
            sy9: "",
            lt10: "margonoyo",
            lt12: "",
            id: "6162",
            dt3: "",
            sy8: ""
        },
        {
            sy5: "ܒܶܝܬ݂ ܡܥܰܘ̈ܟܶܐ",
            lt10: "beth mĉauke",
            dt2: "",
            lt12: "",
            sy9: "",
            dt3: "",
            sy6: "",
            id: "6171",
            dt1: "Invalidenhaus",
            lt11: "",
            sy8: "",
            sy7: "",
            sy4: "ܒܝܬ ܡܥܘܟܐ"
        },
        {
            lt12: "",
            sy8: "",
            sy7: "",
            lt11: "",
            sy6: "",
            lt10: "arbubyo",
            id: "2640",
            dt2: "",
            sy5: "ܐܰܪܒܽܘܒܝܳܐ",
            sy4: "ܐܪܒܘܒܝܐ",
            sy9: "",
            dt3: "",
            dt1: "Kreide"
        },
        {
            dt3: "",
            sy4: "ܡܟܝܟܘܬܐ",
            sy9: "",
            sy5: "ܡܰܟܺܝܟ݂ܽܘܬ݂ܳܐ",
            sy7: "",
            sy8: "",
            lt12: "",
            lt11: "",
            dt1: "Demut",
            id: "856",
            lt10: "makikhutho",
            dt2: "Bescheidenheit",
            sy6: ""
        },
        {
            sy6: "",
            id: "527",
            sy5: "ܚܶܫܬܳܐ",
            dt3: "",
            dt2: "",
            sy7: "",
            lt10: "ĥeshto",
            sy8: "",
            lt12: "",
            sy9: "",
            lt11: "",
            sy4: "ܚܫܬܐ",
            dt1: "Begierde"
        },
        {
            sy8: "",
            dt3: "",
            dt2: "",
            lt11: "",
            lt12: "",
            id: "1428",
            sy9: "",
            sy5: "ܕܐܶܨܛܠܶܒܬ ܚܠܳܦܰܝܢ",
            dt1: "Für uns gekreuzigt",
            sy4: "ܕܐܨܛܠܒܬ ܚܠܦܝܢ",
            sy7: "",
            lt10: "d`eŝțlebt ĥlofayn",
            sy6: ""
        },
        {
            dt3: "",
            sy9: "",
            sy8: "",
            lt11: "maĥwe",
            dt2: "",
            dt1: "zeigen",
            lt12: "",
            sy5: "ܚܰܘܺܝ",
            sy7: "ܡܰܚܘܶܐ",
            id: "5285",
            sy4: "ܚܘܝ",
            lt10: "ĥawi",
            sy6: "ܡܚܘܐ"
        },
        {
            sy7: "ܫܽܘܟܳܢܳܐ",
            dt3: "",
            lt11: "shukono",
            sy5: "ܕܳܫܢܳܐ",
            dt2: "",
            sy8: "",
            lt10: "doshno",
            sy6: "ܫܘܟܢܐ",
            id: "1579",
            dt1: "Geschenk",
            sy4: "ܕܫܢܐ",
            lt12: "",
            sy9: ""
        },
        {
            lt12: "",
            sy7: "",
            sy4: "ܐܣܝܘܬܐ ܢܦܫܢܝܬܐ",
            sy5: "ܐܳܣܝܽܘܬ݂ܳܐ ܢܰܦܫܳܢܳܝܬܳܐ",
            dt2: "",
            sy8: "",
            dt1: "Psychiatrie",
            sy6: "",
            id: "3538",
            sy9: "",
            dt3: "",
            lt10: "osyutho nafshonoyto",
            lt11: ""
        },
        {
            sy8: "",
            lt10: "yorthutho da`ĥțitho",
            dt3: "",
            dt1: "Erbsünde",
            sy6: "",
            sy9: "",
            lt11: "",
            lt12: "",
            id: "1135",
            sy4: "ܝܪܬܘܬܐ ܕܚܛܝܬܐ",
            sy5: "ܝܳܪܬܽܘܬ݂ܳܐ ܕܰܚܛܺܝܬ݂ܳܐ",
            sy7: "",
            dt2: "Erbschuld"
        },
        {
            sy8: "",
            lt11: "ܣܪܘܓ",
            sy6: "",
            dt2: "",
            dt1: "Sarug",
            id: "3849",
            lt12: "",
            sy4: "ܣܪܘܓ",
            sy5: "ܣܰܪܽܘܓ",
            sy7: "",
            sy9: "",
            lt10: "ܣܪܘܓ",
            dt3: ""
        },
        {
            sy6: "ܫܦܝܪܐ",
            sy7: "ܫܰܦܺܝܪܰܐ",
            id: "3841",
            sy9: "",
            sy8: "",
            lt11: "ܨܦܝܪܐ",
            lt10: "ܨܦܝܪܐ",
            dt1: "Saphira",
            sy5: "ܨܰܦܺܝܪܰܐ",
            dt2: "Safira",
            sy4: "ܨܦܝܪܐ",
            lt12: "ܫܦܝܪܐ",
            dt3: "Zafira"
        },
        {
            lt11: "",
            sy6: "",
            dt3: "",
            "date": {
                "seconds": 1591181749,
                "nanoseconds": 405000000
            },
            sy7: "",
            sy5: "ܐܽܘ ܝܳܠܘܽܦܳܐ",
            id: "MQwEPyfdFlRH86cHtbjx",
            sy8: "",
            sy4: "ܐܘ ܝܠܘܦܐ",
            lt12: "",
            dt2: "",
            lt10: "U yolufo",
            dt1: "Der Student",
            sy9: ""
        },
        {
            lt10: "hau d`malel ba`nbiye u ba`shliĥe",
            lt11: "",
            sy4: "ܗܘ ܕܡܠܠ ܒܢܒܝܐ ܘ ܒܫܠܝܚܐ",
            lt12: "",
            sy5: "ܗܰܘ ܕܡܰܠܶܠ ܒܰܢܒܺܝ̈ܶܐ ܘ ܒܰܫܠܺܝ̈ܚܶܐ",
            sy9: "",
            id: "5042",
            sy7: "",
            sy8: "",
            dt3: "",
            dt2: "",
            sy6: "",
            dt1: "welcher durch die Propheten gesprochen hat"
        },
        {
            sy6: "ܡܪܬܐ",
            sy7: "ܡܳܪܬܳܐ",
            id: "2946",
            sy8: "",
            sy9: "",
            lt12: "ܡܪܬܐ",
            dt1: "Marta",
            dt3: "",
            sy5: "ܡܰܪܬܰܐ",
            lt11: "ܡܪܬܐ",
            dt2: "Martha",
            lt10: "ܡܪܬܐ",
            sy4: "ܡܪܬܐ"
        },
        {
            dt3: "",
            sy7: "",
            lt11: "",
            sy8: "",
            sy5: "ܣܽܘܒܳܠܳܐ",
            sy6: "",
            lt10: "subolo",
            lt12: "",
            sy9: "",
            sy4: "ܣܘܒܠܐ",
            dt1: "Anteil",
            dt2: "Beitrag",
            id: "248"
        },
        {
            sy5: "ܒܫܶܠܝܳܐ",
            sy9: "",
            lt12: "",
            dt1: "in Ruhe",
            dt2: "in Stille",
            id: "2190",
            sy6: "",
            sy4: "ܒܫܠܝܐ",
            lt11: "",
            lt10: "b`shelyo",
            sy8: "",
            sy7: "",
            dt3: ""
        },
        {
            dt2: "",
            id: "4790",
            sy4: "ܫܢܝܓܐܝܬ",
            lt11: "",
            sy9: "",
            sy8: "",
            sy6: "",
            sy5: "ܫܢܺܝܓ݂ܳܐܝܺܬ݂",
            lt10: "shniğoith",
            dt1: "verrückt",
            sy7: "",
            lt12: "",
            dt3: ""
        },
        {
            dt3: "",
            sy7: "",
            sy8: "",
            lt11: "",
            lt12: "",
            sy6: "",
            sy4: "ܥܪܦܠ",
            dt2: "",
            lt10: "ĉarfel",
            sy9: "",
            id: "4769",
            dt1: "vernebeln",
            sy5: "ܥܰܪܦܶܠ"
        },
        {
            sy8: "",
            id: "2728",
            sy9: "",
            dt3: "",
            lt10: "ĥyo",
            dt1: "leben",
            lt11: "",
            lt12: "",
            sy7: "",
            sy5: "ܚܝܳܐ",
            sy6: "",
            dt2: "",
            sy4: "ܚܝܐ"
        },
        {
            sy7: "",
            sy9: "",
            sy8: "",
            sy5: "ܥܳܡܽܘܪܶܐ ܕܫܰܘܬ݂ܳܐ",
            sy4: "ܥܡܘܪܐ ܕܫܘܬܐ",
            dt1: "Stadteinwohner",
            lt10: "ĉomure d'shautho",
            id: "6229",
            lt11: "",
            sy6: "",
            dt3: "",
            lt12: "",
            dt2: "Stadtbewohner"
        },
        {
            sy8: "",
            dt2: "Steigung",
            sy7: "",
            lt10: "suloqo",
            id: "361",
            sy4: "ܣܘܠܩܐ",
            lt11: "",
            sy6: "",
            sy9: "",
            dt3: "",
            lt12: "",
            sy5: "ܣܽܘܠܳܩܳܐ",
            dt1: "Aufsteigung"
        },
        {
            dt2: "",
            dt3: "",
            sy6: "",
            lt12: "",
            lt11: "",
            id: "5669",
            sy7: "",
            sy4: "ܢܙܝܪܐ",
            sy8: "",
            sy5: "ܢܙܺܝܪܳܐ",
            sy9: "",
            dt1: "abstinent",
            lt10: "nziro"
        },
        {
            dt3: "",
            lt12: "",
            dt1: "Volgesa",
            sy4: "ܦܘܠܓܣܐ",
            dt2: "",
            lt10: "ܦܘܠܓܣܐ",
            sy5: "ܦܳܘܠܓܶܣܰܐ",
            id: "4847",
            sy6: "",
            sy7: "",
            sy8: "",
            sy9: "",
            lt11: "ܦܘܠܓܣܐ"
        },
        {
            sy6: "",
            lt12: "",
            dt1: "Lüdya",
            sy9: "",
            dt3: "",
            sy4: "ܠܘܕܝܐ",
            id: "2862",
            sy7: "",
            dt2: "Lüdja",
            lt11: "ܠܘܕܝܐ",
            sy8: "",
            lt10: "ܠܘܕܝܐ",
            sy5: "ܠܽܘܕܝܰܐ"
        },
        {
            dt2: "",
            id: "6007",
            lt12: "",
            dt3: "",
            sy8: "",
            lt11: "",
            sy9: "",
            dt1: "Anzünder",
            sy6: "",
            lt10: "shağoro",
            sy4: "ܫܓܪܐ",
            sy7: "",
            sy5: "ܫܰܓ݂ܳܪܳܐ"
        },
        {
            dt1: "Barsaba",
            sy8: "",
            lt10: "ܒܪܣܒܐ",
            sy6: "ܒܪܫܬܐ",
            sy7: "ܒܰܪܫܰܒܬܳܐ",
            lt12: "ܒܪܫܒܬܐ",
            sy9: "",
            sy5: "ܒܰܪܫܰܒܐ",
            id: "472",
            sy4: "ܒܪܣܒܐ",
            dt2: "",
            lt11: "ܒܪܣܒܐ",
            dt3: ""
        },
        {
            sy8: "",
            sy5: "ܐܶܢܳܢܳܝܳܐ",
            lt11: "",
            dt3: "",
            lt10: "enonoyo",
            sy7: "",
            sy9: "",
            sy6: "",
            lt12: "",
            id: "1005",
            sy4: "ܐܢܢܝܐ",
            dt2: "arrogant",
            dt1: "Egoist"
        },
        {
            sy5: "ܡܰܢܩܠܳܐ",
            sy7: "",
            id: "5423",
            dt1: "Grill",
            sy9: "",
            lt11: "",
            lt12: "",
            dt2: "",
            sy6: "",
            sy4: "ܡܢܩܠܐ",
            lt10: "manqlo",
            dt3: "",
            sy8: ""
        },
        {
            lt10: "ĉurlutho",
            id: "4636",
            dt3: "",
            sy4: "ܥܘܪܠܘܬܐ",
            sy5: "ܥܽܘܪܠܽܘܬ݂ܳܐ",
            sy6: "",
            sy7: "",
            sy9: "",
            dt1: "Unbeschnittenheit",
            dt2: "",
            lt11: "",
            lt12: "",
            sy8: ""
        },
        {
            sy5: "ܝܰܟܺܝܢ",
            lt11: "ܝܟܝܢ",
            sy6: "ܝܩܝܢ",
            dt1: "Yakin",
            lt12: "ܝܩܝܢ",
            sy9: "",
            dt2: "",
            lt10: "ܝܟܝܢ",
            sy8: "",
            dt3: "",
            id: "5190",
            sy7: "ܝܰܩܺܝܢ",
            sy4: "ܝܟܝܢ"
        },
        {
            sy7: "",
            dt2: "wie auch",
            sy5: "ܐܰܝܟܰܢܳܐ",
            lt10: "aykano",
            lt11: "",
            sy6: "",
            sy8: "",
            dt3: "",
            sy4: "ܐܝܟܢܐ",
            sy9: "",
            id: "193",
            lt12: "",
            dt1: "als auch"
        },
        {
            lt11: "ĥauronitho",
            sy7: "ܚܰܘܪܳܢܺܝܬ݂ܳܐ",
            sy4: "ܫܩܡܐ",
            dt2: "",
            dt3: "",
            sy8: "",
            lt10: "sheqmo",
            sy5: "ܫܶܩܡܳܐ",
            sy6: "ܚܘܪܢܝܬܐ",
            id: "5769",
            lt12: "",
            sy9: "",
            dt1: "Ahorn"
        },
        {
            dt2: "Vielehe",
            lt11: "",
            sy7: "",
            lt10: "sagiyuth zuwoğo",
            sy8: "",
            sy6: "",
            sy5: "ܣܰܓܝܽܐܘܬ ܙܽܘܘܳܓ݂ܳܐ",
            dt1: "Polygamie",
            id: "3487",
            sy9: "",
            dt3: "",
            lt12: "",
            sy4: "ܣܓܝܐܘܬ ܙܘܘܓܐ"
        },
        {
            dt2: "",
            dt3: "",
            sy8: "",
            lt11: "",
            lt12: "",
            lt10: "mnaugutho",
            sy5: "ܡܢܰܘܓܽܘܬ݂ܳܐ",
            sy9: "",
            id: "6327",
            sy4: "ܡܢܘܓܘܬܐ",
            sy6: "",
            sy7: "",
            dt1: "Schiffbruch"
        },
        {
            sy6: "",
            id: "1053",
            sy5: "ܡܠܳܐ",
            sy8: "",
            lt12: "",
            sy9: "",
            lt10: "mlo",
            lt11: "",
            dt2: "",
            sy7: "",
            dt3: "",
            dt1: "einschenken",
            sy4: "ܡܠܐ"
        },
        {
            lt12: "anțigrafun",
            dt1: "Abschrift",
            sy4: "ܡܨܚܢܘܬܐ",
            sy8: "ܐܢܛܝܓܪܦܘܢ",
            lt10: "maŝĥonutho",
            dt2: "",
            sy5: "ܡܰܨܚܳܢܽܘܬ݂ܳܐ",
            id: "5637",
            sy6: "ܢܘܣܟܬܐ",
            lt11: "nusakhto",
            dt3: "",
            sy9: "ܐܰܢܛܺܝܓܪܰܦܽܘܢ",
            sy7: "ܢܽܘܣܰܟ݂ܬܳܐ"
        },
        {
            sy8: "",
            sy6: "",
            dt2: "",
            lt10: "rufso",
            sy9: "",
            id: "5289",
            sy4: "ܪܘܦܣܐ",
            lt12: "",
            dt1: "Zeiteinheit",
            sy7: "",
            sy5: "ܪܽܘܦܣܳܐ",
            dt3: "",
            lt11: ""
        },
        {
            lt11: "iĥiđoyo",
            id: "1057",
            sy4: "ܝܚܝܕܐ",
            sy6: "ܝܚܝܕܝܐ",
            sy7: "ܝܺܚܺܝܕܳܝܳܐ",
            sy8: "",
            dt3: "",
            dt2: "",
            lt12: "",
            lt10: "iĥiđo",
            dt1: "Einzelkind",
            sy9: "",
            sy5: "ܝܺܚܺܝܕܳܐ"
        },
        {
            sy9: "",
            dt2: "",
            sy4: "ܡܫܡܫܢܘܬܐ",
            sy8: "",
            dt3: "",
            sy5: "ܡܫܰܡܫܳܢܽܘܬ݂ܳܐ",
            sy6: "",
            dt1: "Amtsführung",
            id: "5886",
            lt11: "",
            sy7: "",
            lt10: "mshamshonutho",
            lt12: ""
        },
        {
            sy6: "ܫܠܡܝܐ",
            lt10: "mshayno",
            sy7: "ܫܠܳܡܳܝܳܐ",
            id: "1398",
            sy4: "ܡܫܝܢܐ",
            sy9: "",
            sy5: "ܡܫܰܝܢܳܐ",
            lt12: "",
            sy8: "",
            dt1: "friedlich",
            lt11: "shlomoyo",
            dt2: "",
            dt3: ""
        },
        {
            sy5: "ܡܰܕܪܰܫܬܳܐ ܬܟܢܝܟܝܬܳܐ",
            dt3: "",
            sy8: "",
            sy7: "",
            id: "4416",
            dt1: "Technische Hochschule",
            lt10: "madrashto teknikoyto",
            sy9: "",
            sy6: "",
            lt11: "",
            sy4: "ܡܕܪܫܬܐ ܬܟܢܝܟܝܬܐ",
            lt12: "",
            dt2: "Technische Schule"
        },
        {
            lt12: "",
            id: "2909",
            dt1: "Mandarine",
            lt10: "ețruğ malko",
            dt2: "",
            sy8: "",
            dt3: "",
            sy5: "ܐܶܛܪܽܘܓ݂ ܡܰܠܟܳܐ",
            lt11: "mandalin",
            sy7: "ܡܰܢܕܰܠܺܝܢ",
            sy4: "ܐܛܪܘܓ ܡܠܟܐ",
            sy9: "",
            sy6: "ܡܢܕܠܝܢ"
        },
        {
            dt1: "Giraffe",
            lt11: "",
            lt12: "",
            sy7: "",
            id: "1643",
            dt2: "",
            dt3: "",
            sy4: "ܙܪܝܦܐ",
            sy8: "",
            sy5: "ܙܳܪܺܝܦܳܐ",
            sy6: "",
            sy9: "",
            lt10: "zorifo"
        },
        {
            lt11: "",
            id: "1719",
            sy5: "ܩܰܒܪܳܐ",
            sy8: "",
            sy9: "",
            lt12: "",
            sy6: "",
            lt10: "qabro",
            dt3: "",
            dt2: "",
            sy7: "",
            dt1: "Grab",
            sy4: "ܩܒܪܐ"
        },
        {
            lt10: "shuĥtono",
            id: "3748",
            dt2: "",
            sy7: "",
            lt12: "",
            sy8: "",
            dt3: "",
            sy4: "ܫܘܚܬܢܐ",
            sy6: "",
            sy5: "ܫܽܘܚܬܳܢܳܐ",
            lt11: "",
            sy9: "",
            dt1: "rostig"
        },
        {
            sy6: "",
            id: "2923",
            dt1: "Manu",
            sy4: "ܡܥܢܘ",
            sy8: "",
            sy5: "ܡܰܥܰܢܽܘ",
            sy7: "",
            sy9: "",
            dt2: "",
            dt3: "",
            lt12: "",
            lt10: "ܡܥܢܘ",
            lt11: "ܡܥܢܘ"
        },
        {
            sy8: "",
            lt11: "ܙܘܙܝ",
            id: "4328",
            sy5: "ܙܽܘܙܺܝ",
            sy9: "",
            sy4: "ܙܘܙܝ",
            lt10: "ܙܘܙܝ",
            dt2: "",
            dt1: "Susi",
            sy7: "",
            lt12: "",
            sy6: "",
            dt3: ""
        },
        {
            dt3: "",
            id: "1743",
            sy5: "ܡܳܬ݂ܳܐ ܥܰܫܺܝܢܬܳܐ",
            sy6: "",
            lt12: "",
            sy4: "ܡܬܐ ܥܫܝܢܬܐ",
            lt10: "motho ĉashinto",
            dt1: "Großmacht",
            sy7: "",
            sy9: "",
            dt2: "",
            sy8: "",
            lt11: ""
        },
        {
            lt10: "ĥaye da`qritho",
            sy8: "",
            sy4: "ܚܝܐ ܕܩܪܝܬܐ",
            sy6: "",
            id: "948",
            sy5: "ܚܰܝ̈ܶܐ ܕܩܪܺܝܬ݂ܳܐ",
            sy7: "",
            dt3: "",
            dt1: "Dorfleben",
            lt12: "",
            lt11: "",
            dt2: "",
            sy9: ""
        },
        {
            lt12: "",
            sy5: "ܢܳܨܪܰܬ݂",
            dt1: "Nazareth",
            sy4: "ܢܨܪܬ",
            id: "3227",
            sy7: "",
            sy9: "",
            lt10: "noŝrath",
            dt3: "",
            dt2: "",
            sy6: "",
            sy8: "",
            lt11: ""
        },
        {
            dt1: "Schaol",
            id: "3898",
            sy8: "",
            dt3: "",
            lt10: "ܫܐܘܠ",
            sy7: "ܫܰܐܘܽܠ",
            lt11: "ܫܐܘܠ",
            sy6: "ܫܐܘܠ",
            dt2: "Saul",
            sy9: "",
            sy5: "ܫܰܐܘܳܠ",
            lt12: "ܫܐܘܠ",
            sy4: "ܫܐܘܠ"
        },
        {
            sy8: "",
            dt3: "",
            sy9: "",
            id: "1769",
            sy4: "ܚܘܠܡܢܐ",
            sy6: "",
            lt11: "",
            dt1: "Gute Besserung",
            lt12: "",
            lt10: "ĥulmono",
            sy7: "",
            sy5: "ܚܽܘܠܡܳܢܳܐ",
            dt2: ""
        },
        {
            sy5: "ܟܫܰܛ",
            sy9: "ܫܕܳܐ",
            id: "5621",
            dt1: "abschießen",
            sy4: "ܟܫܛ",
            dt2: "abschiessen",
            lt12: "shdo",
            lt10: "kshaț",
            sy6: "ܩܫܛ",
            sy8: "ܫܕܐ",
            dt3: "",
            sy7: "ܩܫܰܛ",
            lt11: "qshaț"
        },
        {
            lt12: "",
            dt1: "Praktikum",
            sy7: "",
            sy5: "ܢܶܣܝܳܢܳܐ ܥܒܳܕܳܢܳܝܳܐ",
            lt11: "",
            sy9: "",
            sy8: "",
            sy6: "",
            lt10: "nesyono ĉbodonoyo",
            dt2: "praktische Ausbildung",
            dt3: "",
            id: "3502",
            sy4: "ܢܣܝܢܐ ܥܒܕܢܝܐ"
        },
        {
            dt1: "vor",
            dt3: "zu",
            sy5: "ܠܘܳܬ݂",
            sy9: "",
            lt10: "lwoth",
            sy7: "",
            sy8: "",
            dt2: "daneben",
            lt12: "",
            id: "4889",
            lt11: "",
            sy6: "",
            sy4: "ܠܘܬ"
        },
        {
            dt3: "vollbringen",
            sy6: "ܐܬܓܡܪ",
            sy4: "ܐܫܬܡܠܝ",
            lt10: "eshtamli",
            sy8: "ܫܠܡ",
            lt12: "shlem",
            sy5: "ܐܶܫܬܰܡܠܺܝ",
            dt1: "vollenden",
            dt2: "beendet sein",
            sy7: "ܐܶܬ݂ܓܰܡܰܪ",
            lt11: "ethgamar",
            sy9: "ܫܠܶܡ",
            id: "4867"
        },
        {
            sy5: "ܡܰܚܙܳܢܺܝܬ݂ܳܐ",
            dt1: "Spiegel",
            lt12: "nauwro",
            lt11: "maĥzitho",
            sy4: "ܡܚܙܢܝܬܐ",
            sy6: "ܡܚܙܝܬܐ",
            sy9: "ܢܰܘܪܳܐ",
            dt3: "",
            dt2: "",
            sy8: "ܢܘܪܐ",
            lt10: "maĥzonitho",
            sy7: "ܡܰܚܙܺܝܬ݂ܳܐ",
            id: "4210"
        },
        {
            sy4: "ܦܘܠܚܢܐ",
            lt12: "shuğlo",
            sy9: "ܫܽܘܓ݂ܠܳܐ",
            dt2: "",
            lt11: "ĉbođo",
            id: "275",
            lt10: "fulĥono",
            sy6: "ܥܒܕܐ",
            sy8: "ܫܘܓܠܐ",
            dt3: "",
            dt1: "Arbeit",
            sy7: "ܥܒܳܕܳܐ",
            sy5: "ܦܽܘܠܚܳܢܳܐ"
        },
        {
            sy8: "",
            sy4: "ܥܡܝܕܬܐ",
            sy6: "",
            dt2: "Getauftes Baby mädchen",
            lt10: "ĉmiđta",
            id: "1610",
            sy5: "ܥܡܺܝܕܬܰܐ",
            sy9: "",
            lt11: "",
            dt3: "",
            dt1: "Getaufte",
            lt12: "",
            sy7: ""
        },
        {
            lt11: "ܐܝܫܡܥܝܐ",
            dt3: "",
            sy8: "",
            sy7: "",
            sy6: "",
            sy5: "ܐܝܫܡܥܝܐ",
            sy4: "ܐܝܫܡܥܝܐ",
            dt2: "",
            sy9: "",
            id: "2325",
            lt10: "ܐܝܫܡܥܝܐ",
            lt12: "",
            dt1: "Jischmaja"
        },
        {
            lt12: "",
            dt1: "Malkuta",
            sy4: "ܡܠܟܘܬܐ",
            sy8: "",
            sy5: "ܡܰܠܟܽܘܬܰܐ",
            id: "2907",
            sy9: "",
            lt11: "ܡܠܟܘܬܐ",
            dt3: "",
            dt2: "",
            sy7: "",
            lt10: "ܡܠܟܘܬܐ",
            sy6: ""
        },
        {
            sy6: "",
            lt10: "qđolo",
            dt3: "",
            sy8: "",
            sy4: "ܩܕܠܐ",
            lt12: "",
            sy7: "",
            lt11: "",
            sy5: "ܩܕܠܳܐ",
            dt2: "Nacken",
            sy9: "",
            dt1: "Hals",
            id: "1832"
        },
        {
            dt2: "",
            sy4: "ܐܘܝܘܬܐ",
            sy5: "ܐܰܘܝܽܘܬ݂ܳܐ",
            sy6: "ܥܩܕܐ",
            sy7: "ܥܶܩܕܳܐ",
            dt1: "Abkommen",
            id: "5575",
            lt10: "auyutho",
            sy9: "ܫܛܳܪ ܐܰܘܝܽܘܬ݂ܳܐ",
            lt12: "shțor auyutho",
            sy8: "ܫܛܪ ܐܘܝܘܬܐ",
            dt3: "",
            lt11: "ĉeqdo"
        },
        {
            dt1: "Vergib uns",
            sy9: "",
            dt3: "",
            sy8: "",
            sy4: "ܐܬܪܚܡܥܠܝܢ",
            lt12: "",
            dt2: "",
            lt10: "ethraĥameĉlayn",
            sy6: "",
            lt11: "",
            sy5: "ܐܶܬ݂ܪܰܚܰܡܶܥܠܰܝܢ",
            sy7: "",
            id: "4735"
        },
        {
            sy4: "ܠܟ",
            lt12: "",
            dt2: "",
            sy7: "ܠܶܟ݂ܝ̱",
            sy9: "",
            dt1: "Dir",
            lt11: "lekh",
            dt3: "",
            id: "903",
            lt10: "lokh",
            sy5: "ܠܳܟ",
            sy8: "",
            sy6: "ܠܟܝ"
        },
        {
            sy6: "",
            id: "3847",
            sy5: "ܣܰܪܗܶܒ",
            sy7: "",
            sy4: "ܣܪܗܒ",
            lt11: "ܣܪܗܒ",
            sy9: "",
            sy8: "",
            dt2: "",
            dt1: "Sarheb",
            dt3: "",
            lt10: "ܣܪܗܒ",
            lt12: ""
        },
        {
            lt11: "ܙܝܠܘܝܐ",
            dt2: "",
            lt10: "ܙܝܠܘܝܐ",
            sy8: "",
            sy7: "ܣܝܠܘܝܐ",
            sy4: "ܙܝܠܘܝܐ",
            sy5: "ܙܝܠܘܐܝܐ",
            dt3: "",
            sy6: "ܣܝܠܘܝܐ",
            dt1: "Silvia",
            sy9: "",
            lt12: "ܣܝܠܘܝܐ",
            id: "4156"
        },
        {
            lt10: "ܢܡܪܐ",
            sy6: "",
            sy5: "ܢܶܡܪܳܐ",
            sy7: "",
            sy9: "",
            dt1: "Nemro",
            dt3: "",
            dt2: "",
            sy4: "ܢܡܪܐ",
            sy8: "",
            id: "3242",
            lt11: "ܢܡܪܐ",
            lt12: ""
        },
        {
            lt10: "noßubo",
            dt2: "",
            sy4: "ܢܣܘܒܐ",
            lt12: "",
            sy9: "",
            sy5: "ܢܳܣܽܘܒܳܐ",
            dt1: "Abnehmer",
            dt3: "",
            sy8: "",
            sy6: "ܡܛܝܒܢܐ",
            sy7: "ܡܛܰܝܒܳܢܳܐ",
            lt11: "mțaybono",
            id: "5591"
        },
        {
            sy8: "",
            id: "3253",
            dt2: "",
            lt11: "",
            sy5: "ܕܺܝܰܬ݂ܺܝܩܺܐ ܚܰܕܬ݂ܳܐ",
            lt10: "diyathiqi ĥatho",
            lt12: "",
            sy4: "ܕܝܬܝܩܐ ܚܕܬܐ",
            dt3: "",
            dt1: "Neues Testament",
            sy6: "",
            sy7: "",
            sy9: ""
        },
        {
            sy4: "ܐܪܡ",
            sy8: "",
            sy9: "",
            id: "3391",
            sy7: "",
            dt2: "",
            sy5: "ܐܳܪܳܡ",
            lt10: "ܐܪܡ",
            dt1: "Orom",
            sy6: "",
            lt11: "ܐܪܡ",
            lt12: "",
            dt3: ""
        },
        {
            sy4: "ܠܝܠܐ",
            sy6: "ܠܝܠܗ",
            dt1: "̱Leyla",
            id: "2799",
            sy8: "",
            sy9: "",
            lt10: "ܠܝܠܐ",
            lt11: "ܠܝܠܐ",
            sy7: "ܠܰܝܠܰܗ",
            sy5: "ܠܰܝܠܰܐ",
            dt3: "",
            lt12: "ܠܝܠܗ",
            dt2: "Layla"
        },
        {
            lt11: "",
            sy8: "",
            dt2: "",
            sy4: "ܦܘܣܩܢ ܕܝܢܐ",
            sy6: "",
            sy5: "ܦܽܘܣܩܳܢ ܕܰܝܳܢܳܐ",
            id: "3939",
            dt3: "",
            dt1: "Schiedsspruch",
            lt10: "fusqon dayono",
            sy7: "",
            lt12: "",
            sy9: ""
        },
        {
            sy4: "ܙܘܒܕܐ",
            sy9: "",
            sy6: "",
            sy8: "",
            lt11: "",
            lt10: "zubdo",
            sy5: "ܙܽܘܒܕܳܐ",
            dt2: "",
            dt1: "Butter",
            id: "748",
            lt12: "",
            dt3: "",
            sy7: ""
        },
        {
            sy4: "ܢܛܘܪܐ ܕܩܪܝܬܐ",
            dt2: "Dorfbeschützer",
            sy7: "",
            sy5: "ܢܛܽܘܪܳܐ ܕܩܪܺܝܬ݂ܳܐ",
            dt3: "",
            lt10: "noțuro d`qritho",
            dt1: "Dorfwächter",
            lt12: "",
            lt11: "",
            sy6: "",
            sy8: "",
            sy9: "",
            id: "949"
        },
        {
            sy6: "ܓܒܝܬܐ",
            sy9: "",
            dt1: "Wählerin",
            sy8: "",
            lt11: "gbitho",
            sy5: "ܡܓܰܒܝܳܢܺܝܬ݂ܳܐ",
            dt2: "",
            lt12: "",
            id: "4939",
            sy4: "ܡܓܒܝܢܝܬܐ",
            sy7: "ܓܒܽܝܬ݂ܳܐ",
            lt10: "mgabyonitho",
            dt3: ""
        },
        {
            sy9: "",
            lt11: "",
            dt1: "schmutzig",
            sy5: "ܨܰܥܠܳܐ",
            id: "3988",
            lt12: "",
            dt3: "",
            lt10: "ŝaĉlo",
            sy7: "",
            sy8: "",
            sy6: "",
            dt2: "",
            sy4: "ܨܥܠܐ"
        },
        {
            dt3: "",
            sy8: "",
            lt10: "ܐܒܝܫܐ",
            sy9: "",
            lt12: "",
            sy6: "",
            lt11: "ܐܒܝܫܐ",
            dt1: "Abischo",
            sy4: "ܐܒܝܫܐ",
            sy5: "ܐܰܒܺܝܫܳܐ",
            dt2: "",
            id: "96",
            sy7: ""
        },
        {
            sy6: "",
            lt10: "ĥashaĥ",
            lt12: "",
            sy9: "",
            dt1: "benutzen",
            id: "551",
            sy5: "ܚܰܫܰܚ",
            dt3: "",
            sy7: "",
            sy4: "ܚܫܚ",
            sy8: "",
            dt2: "anwenden",
            lt11: ""
        },
        {
            dt1: "Waffenschmied",
            sy9: "",
            dt3: "",
            lt10: "qaynoyo d`zayno",
            sy7: "",
            sy6: "",
            lt12: "",
            id: "4931",
            sy4: "ܩܝܢܝܐ ܕܙܝܢܐ",
            lt11: "",
            sy5: "ܩܰܝܢܳܝܳܐ ܕܙܰܝܢܳܐ",
            dt2: "",
            sy8: ""
        },
        {
            sy8: "",
            sy9: "",
            lt11: "",
            dt3: "",
            lt12: "",
            sy7: "",
            sy5: "ܒܰܝܬܳܐ ܪܰܒܳܐ",
            dt2: "",
            sy6: "",
            dt1: "Großes Haus",
            id: "1738",
            lt10: "bayto rabo",
            sy4: "ܒܝܬܐ ܪܒܐ"
        },
        {
            sy6: "ܬܠܝܦܘܢ",
            sy7: "ܬܶܠܺܝܦܽܘܢ",
            lt12: "",
            dt3: "",
            lt10: "handy",
            id: "1860",
            sy4: "ܗܢܕܝ",
            dt1: "Handy",
            lt11: "telifun",
            dt2: "",
            sy8: "",
            sy5: "ܗܶܢܕܺܝ",
            sy9: ""
        },
        {
            lt10: "zadiqoith",
            dt1: "gerecht",
            lt12: "kino",
            id: "1569",
            lt11: "mzadqo",
            sy9: "ܟܺܐܢܳܐ",
            dt2: "gerechtfertig",
            sy4: "ܙܕܝܩܐܝܬ",
            sy6: "ܡܙܕܩܐ",
            dt3: "rechtschaffen",
            sy7: "ܡܙܰܕܩܳܐ",
            sy8: "ܟܐܢܐ",
            sy5: "ܙܰܕܺܝܩܳܐܺܝܬ݂"
        },
        {
            sy8: "",
            sy9: "",
            dt3: "",
            sy6: "",
            lt12: "",
            lt11: "",
            sy7: "",
            lt10: "dauwmo d`mauwlodo",
            id: "5021",
            sy5: "ܕܰܘܡܳܐ ܕܡܰܘܠܳܕܳܐ",
            dt2: "",
            sy4: "ܕܘܡܐ ܕܡܘܠܕܐ",
            dt1: "Weihnachtsbaum"
        },
        {
            lt10: "tagoruth zayno",
            sy8: "",
            id: "4929",
            sy6: "",
            sy7: "",
            sy4: "ܬܓܪܘܬ ܙܝܢܐ",
            sy5: "ܬܰܓܳܪܽܘܬ݂ ܙܰܝܢܳܐ",
            dt1: "Waffengeschäft",
            lt12: "",
            dt2: "",
            dt3: "",
            lt11: "",
            sy9: ""
        },
        {
            dt2: "",
            lt12: "",
            dt3: "",
            sy7: "ܕܺܝܠܳܢܳܐܝܺܬ݂",
            id: "4890",
            dt1: "vor allem",
            lt11: "dilonoith",
            lt10: "dilonoyo",
            sy4: "ܕܝܠܢܝܐ",
            sy8: "",
            sy5: "ܕܝܺܠܳܢܳܝܳܐ",
            sy9: "",
            sy6: "ܕܝܠܢܐܝܬ"
        },
        {
            dt2: "",
            lt12: "",
            sy4: "ܘܙܝܪܐ ܕܝܘܠܦܢܐ",
            dt3: "",
            sy6: "",
            lt11: "",
            sy9: "",
            dt1: "Bildungsminister",
            sy7: "",
            sy8: "",
            lt10: "waziro d`yulfono",
            sy5: "ܘܰܙܺܝܪܳܐ ܕܝܽܘܠܦܳܢܳܐ",
            id: "620"
        },
        {
            sy8: "",
            sy9: "",
            lt11: "",
            dt2: "Portemonnaie",
            lt10: "kiso d`zuze",
            sy5: "ܟܽܝܣܳܐ ܕܙܽܘܙ̈ܐ",
            dt3: "",
            sy4: "ܟܝܣܐ ܕܙܘܙܐ",
            id: "1549",
            sy7: "",
            dt1: "Geldbeutel",
            lt12: "",
            sy6: ""
        },
        {
            dt3: "",
            sy4: "ܟܡܢܝܐ",
            sy7: "",
            dt1: "Quantität",
            sy8: "",
            lt10: "kmonoyo",
            lt11: "",
            sy6: "",
            id: "3569",
            lt12: "",
            sy5: "ܟܡܳܢܳܝܳܐ",
            dt2: "",
            sy9: ""
        },
        {
            lt12: "",
            id: "4456",
            lt10: "ܬܐܘܕܘܣܝܘܣ",
            sy5: "ܬܶܐܘܳܕܳܘܣܺܝܳܘܣ",
            sy6: "",
            dt3: "",
            dt1: "Theodosius",
            sy7: "",
            sy9: "",
            sy4: "ܬܐܘܕܘܣܝܘܣ",
            sy8: "",
            dt2: "",
            lt11: "ܬܐܘܕܘܣܝܘܣ"
        },
        {
            dt3: "",
            lt11: "ܙܟܝ",
            sy4: "ܙܟܝ",
            lt10: "ܙܟܝ",
            dt1: "Zaki",
            dt2: "",
            sy8: "",
            id: "5249",
            lt12: "",
            sy9: "",
            sy5: "ܙܰܟܺܝ",
            sy7: "",
            sy6: ""
        },
        {
            dt1: "heisere Stimme",
            sy8: "",
            lt11: "",
            sy5: "ܚܶܪܽܘܫܳܝܳܐ",
            sy7: "",
            lt12: "",
            dt2: "",
            lt10: "ĥerushoyo",
            sy6: "",
            dt3: "",
            sy4: "ܚܪܘܫܝܐ",
            id: "1944",
            sy9: ""
        },
        {
            sy7: "",
            sy5: "ܨܒܽܘܬ݂ܳܐ ܐܳܠܨܳܝܬܳܐ",
            sy9: "",
            id: "1892",
            sy4: "ܨܒܘܬܐ ܐܠܨܝܬܐ",
            sy8: "",
            lt10: "ŝbutho olŝoyto",
            lt12: "",
            lt11: "",
            dt2: "",
            sy6: "",
            dt3: "",
            dt1: "Hauptthema"
        },
        {
            dt2: "Ishok",
            lt12: "ܐܝܣܚܩ",
            sy6: "ܐܝܣܚܩ",
            sy4: "ܐܝܣܚܩ",
            lt10: "ܐܝܣܚܩ",
            sy5: "ܐܺܝܣܚܰܩ",
            sy9: "",
            id: "2247",
            dt1: "Ishak",
            sy7: "ܐܺܝܣܚܳܩ",
            dt3: "Isaak",
            sy8: "",
            lt11: "ܐܝܣܚܩ"
        },
        {
            dt3: "",
            lt12: "",
            dt1: "Zeitbegrenzung",
            id: "5288",
            lt10: "tuĥom zabno",
            sy4: "ܬܘܚܡ ܙܒܢܐ",
            sy8: "",
            sy7: "",
            sy9: "",
            dt2: "Zeitlimit",
            lt11: "",
            sy5: "ܬܽܘܚܳܡ ܙܰܒܢܳܐ",
            sy6: ""
        },
        {
            lt10: "shĉal",
            sy6: "",
            sy4: "ܫܥܠ",
            dt3: "",
            lt11: "",
            id: "2080",
            dt2: "",
            sy5: "ܫܥܰܠ",
            sy9: "",
            sy8: "",
            dt1: "husten",
            sy7: "",
            lt12: ""
        },
        {
            dt2: "",
            sy5: "ܙܰܒܢܳܐ ܐ̱ܚܪܺܝܢܳܐ",
            dt3: "",
            dt1: "andermal",
            sy6: "",
            sy8: "",
            lt11: "",
            sy7: "",
            sy4: "ܙܒܢܐ ܐܚܪܝܢܐ",
            lt12: "",
            lt10: "zabno ĥrino",
            id: "5932",
            sy9: ""
        },
        {
            lt11: "",
            sy5: "ܗܦܳܟ ܠܰܩܛܳܐ",
            dt1: "Rülpsen",
            lt12: "",
            sy4: "ܗܦܟ ܠܩܛܐ",
            sy8: "",
            sy7: "",
            dt3: "",
            sy9: "",
            sy6: "",
            dt2: "",
            id: "5613",
            lt10: "hfokh laqțo"
        },
        {
            sy5: "ܡܙܰܗܪܳܢܳܐ",
            lt11: "",
            sy7: "",
            lt10: "msahrono",
            sy9: "",
            lt12: "",
            dt1: "Ermahner",
            dt3: "",
            id: "1160",
            dt2: "",
            sy8: "",
            sy4: "ܡܙܗܪܢܐ",
            sy6: ""
        },
        {
            sy5: "ܡܶܐܙܰܠܬܳܐ",
            sy9: "",
            sy7: "",
            dt1: "Wallfahrt",
            sy6: "",
            dt3: "",
            lt10: "mezalto",
            lt12: "",
            id: "4972",
            dt2: "",
            sy4: "ܡܐܙܠܬܐ",
            lt11: "",
            sy8: ""
        },
        {
            dt1: "Naima",
            sy5: "ܢܰܥܺܝܡܰܐ",
            sy4: "ܢܥܝܡܐ",
            sy6: "",
            sy9: "",
            id: "3192",
            sy7: "",
            lt10: "ܢܥܝܡܐ",
            sy8: "",
            dt2: "Nayma",
            lt12: "",
            lt11: "ܢܥܝܡܐ",
            dt3: ""
        },
        {
            lt12: "",
            lt10: "nosho",
            sy9: "",
            sy7: "",
            sy5: "ܐ̱ܢܳܫܳܐ",
            sy4: "ܐܢܫܐ",
            dt1: "jemand",
            lt11: "",
            id: "2300",
            dt2: "",
            dt3: "",
            sy6: "",
            sy8: ""
        },
        {
            lt10: "esar",
            sy8: "",
            dt1: "anschnallen",
            lt11: "",
            sy5: "ܐܶܣܰܪ",
            sy6: "",
            dt3: "anbinden",
            sy7: "",
            id: "5977",
            sy4: "ܐܣܪ",
            lt12: "",
            sy9: "",
            dt2: "festschnallen"
        },
        {
            lt12: "",
            sy5: "ܝܰܨܽܘܦܽܘܬ݂ܳܐ",
            dt1: "Sorge",
            dt2: "",
            sy4: "ܝܨܘܦܘܬܐ",
            sy8: "",
            lt11: "",
            id: "4201",
            sy7: "",
            sy9: "",
            lt10: "yaŝufutho",
            sy6: "",
            dt3: ""
        },
        {
            sy9: "",
            dt1: "Seligkeit",
            sy7: "",
            id: "4112",
            sy5: "ܛܽܘܒܳܐ",
            sy6: "",
            dt3: "",
            lt12: "",
            sy4: "ܛܘܒܐ",
            dt2: "",
            sy8: "",
            lt10: "țubo",
            lt11: ""
        },
        {
            sy5: "ܒܽܘܠܒܳܠܳܝܽܘܬ݂ܳܐ",
            lt10: "bulboloyutho",
            sy7: "ܕܽܘܘܳܕܳܐ",
            lt12: "",
            lt11: "duwodo",
            id: "5909",
            sy8: "",
            sy6: "ܕܘܘܕܐ",
            dt3: "Chaos",
            dt2: "Gesetzlosigkeit",
            sy4: "ܒܘܠܒܠܝܘܬܐ",
            sy9: "",
            dt1: "Anarchie"
        },
        {
            lt11: "",
            sy9: "",
            sy5: "ܛܰܢܳܢܽܘܬ݂ܳܐ",
            lt10: "țanonutho",
            dt1: "Ehrgeiz",
            sy4: "ܛܢܢܘܬܐ",
            sy8: "",
            sy7: "",
            dt3: "",
            lt12: "",
            id: "1023",
            sy6: "",
            dt2: ""
        },
        {
            dt2: "",
            sy9: "",
            sy4: "ܟܘܪܣܝܐ ܕܡܘܕܝܢܘܬܐ",
            lt10: "kursyo d`maudyonutho",
            lt12: "",
            dt1: "Beichtstuhl",
            sy5: "ܟܽܘܪܣܝܳܐ ܕܡܰܘܕܝܳܢܽܘܬ݂ܳܐ",
            id: "6427",
            lt11: "",
            sy6: "",
            sy8: "",
            sy7: "",
            dt3: ""
        },
        {
            id: "6426",
            sy7: "",
            sy8: "",
            lt10: "rozo d`maudyonutho",
            lt11: "",
            sy6: "",
            lt12: "",
            sy4: "ܐܪܙܐ ܕܡܘܕܝܢܘܬܐ",
            dt2: "",
            dt3: "",
            sy9: "",
            sy5: "ܐ̱ܪܳܙܳܐ ܕܡܰܘܕܝܳܢܽܘܬ݂ܳܐ",
            dt1: "Beichtgeheimnis"
        },
        {
            id: "1985",
            sy4: "ܒܫܝܢܐ ܒܫܠܡܐ",
            sy6: "",
            sy7: "",
            sy8: "",
            sy5: "ܒܫܰܝܢܳܐ ܒܰܫܠܳܡܳܐ",
            lt11: "",
            dt3: "",
            sy9: "",
            lt12: "",
            dt1: "Herzlich Willkommen",
            dt2: "",
            lt10: "bsheyno ba`shlomo"
        },
        {
            sy9: "",
            dt1: "Ursabro",
            sy8: "",
            lt11: "ܐܘܪܣܒܪܐ",
            lt10: "ܐܘܪܣܒܪܐ",
            sy4: "ܐܘܪܣܒܪܐ",
            lt12: "",
            sy7: "",
            sy5: "ܐܽܘܪܣܰܒܪܳܐ",
            id: "4698",
            dt2: "",
            sy6: "",
            dt3: ""
        },
        {
            sy6: "ܚܕܥܣܪܐ",
            sy7: "ܚܕܰܥܶܣ̈ܪܶܐ",
            lt12: "juth-olaf",
            sy8: "ܝܐ",
            lt11: "ĥđaĉesre",
            dt3: "",
            dt2: "Elf",
            sy4: "ܚܕܥܣܪ",
            sy9: "ܝܐ",
            id: "12",
            dt1: "11",
            lt10: "ĥđaĉsar",
            sy5: "ܚܕܰܥܣܰܪ"
        },
        {
            lt11: "",
            sy5: "ܥܶܙܳܐ",
            id: "5314",
            sy6: "",
            dt2: "",
            sy9: "",
            lt12: "",
            sy8: "",
            sy4: "ܥܙܐ",
            lt10: "ĉezo",
            dt1: "Ziege",
            dt3: "",
            sy7: ""
        },
        {
            dt2: "",
            sy5: "ܪܰܡܺܐܝܠ",
            dt3: "",
            lt10: "ܪܡܐܝܠ",
            lt12: "ܪܡܐܝܠ",
            sy6: "ܪܡܐܝܠ",
            sy7: "ܪܳܡܺܐܝܠ",
            sy9: "",
            dt1: "Ramil",
            sy4: "ܪܡܐܝܠ",
            id: "3602",
            sy8: "",
            lt11: "ܪܡܐܝܠ"
        },
        {
            dt1: "Lamha",
            sy4: "ܠܡܥܐ",
            sy9: "",
            sy5: "ܠܰܡܥܰܐ",
            lt12: "",
            sy6: "",
            lt11: "ܠܡܥܐ",
            dt3: "",
            sy7: "",
            lt10: "ܠܡܥܐ",
            sy8: "",
            dt2: "",
            id: "2698"
        },
        {
            sy7: "",
            lt12: "",
            sy6: "",
            lt10: "tashĉith ganobe",
            sy8: "",
            dt1: "Räubergeschichte",
            id: "6139",
            dt3: "",
            lt11: "",
            sy9: "",
            dt2: "",
            sy5: "ܬܰܫܥܝܺܬ݂ ܓܰܢܳܒܶܐ",
            sy4: "ܬܫܥܝܬ ܓܢܒܐ"
        },
        {
            id: "2245",
            sy4: "ܥܫܬܪ",
            dt2: "Ishtar",
            dt3: "",
            sy6: "",
            dt1: "Ischtar",
            lt12: "",
            sy9: "",
            sy7: "",
            lt10: "ĉashtar",
            lt11: "",
            sy8: "",
            sy5: "ܥܰܫܬܰܪ"
        },
        {
            lt11: "",
            id: "4848",
            sy5: "ܥܰܡܳܐ",
            dt1: "Volk",
            sy9: "",
            sy7: "",
            lt12: "",
            sy4: "ܥܡܐ",
            sy6: "",
            dt2: "",
            sy8: "",
            dt3: "",
            lt10: "ĉamo"
        },
        {
            sy4: "ܗܘܝܐ ",
            dt2: "",
            sy6: "",
            lt11: "",
            sy8: "",
            id: "4102",
            lt10: "hwoyo",
            dt3: "",
            dt1: "sein",
            sy9: "",
            sy7: "",
            sy5: "ܗܘܳܝܳܐ ",
            lt12: ""
        },
        {
            sy5: "ܦܬܺܝܚܳܐ",
            sy6: "",
            dt2: "offen",
            dt1: "geöffnet",
            sy8: "",
            lt10: "ftiĥo",
            sy9: "",
            sy4: "ܦܬܝܚܐ",
            sy7: "",
            id: "1566",
            lt12: "",
            dt3: "vakant",
            lt11: ""
        },
        {
            sy9: "",
            lt12: "",
            sy4: "ܡܫܐܠܢܘܬܐ",
            dt3: "",
            sy6: "ܡܘܙܦܢܘܬܐ",
            sy5: "ܡܰܫܶܐܠܳܢܽܘܬ݂ܳܐ",
            id: "5955",
            sy8: "",
            lt10: "mashelonutho",
            dt2: "",
            dt1: "Anleihe",
            lt11: "mauzfonutho",
            sy7: "ܡܰܘܙܦܳܢܽܘܬ݂ܳܐ"
        },
        {
            dt3: "",
            sy4: "ܟܪܟܕ",
            dt1: "abstempeln",
            lt12: "",
            sy7: "",
            sy9: "",
            id: "5666",
            sy6: "",
            lt11: "",
            sy5: "ܟܰܪܟܶܕ",
            dt2: "",
            lt10: "karked",
            sy8: ""
        },
        {
            sy7: "ܦܪܰܚ",
            dt1: "fliegen",
            sy8: "ܦܪܚ",
            dt2: "",
            lt10: "țos",
            sy4: "ܛܣ",
            lt11: "fraĥ",
            sy6: "ܦܪܚ",
            sy5: "ܛܳܣ",
            lt12: "foraĥ",
            id: "1333",
            sy9: "ܦܳܪܰܚ",
            dt3: ""
        },
        {
            sy5: "ܕܠܳܐ ܫܡܳܐ",
            lt11: "mkasyo",
            sy9: "",
            lt10: "d`lo shmo",
            dt3: "pseudonym",
            sy8: "",
            dt1: "anonym",
            sy6: "ܡܟܣܝܐ",
            sy7: "ܡܟܰܣܝܳܐ",
            sy4: "ܕܠܐ ܫܡܐ",
            lt12: "",
            id: "5973",
            dt2: "inkognito"
        },
        {
            sy7: "",
            sy5: "ܩܪܳܒܳܐ ܕܰܬܚܽܘܡܳܐ",
            dt2: "",
            sy6: "",
            lt10: "qrobo da`tĥumo",
            lt12: "",
            id: "1730",
            sy8: "",
            dt3: "",
            lt11: "",
            dt1: "Grenzkrieg",
            sy9: "",
            sy4: "ܩܪܒܐ ܕܬܚܘܡܐ"
        },
        {
            lt10: "ܓܝܕܪܝܐ",
            sy5: "ܓܺܝܕܰܪܝܰܐ",
            sy8: "",
            lt12: "ܓܝܕܪܐ",
            lt11: "ܓܝܕܪܝܐ",
            dt2: "",
            sy4: "ܓܝܕܪܝܐ",
            sy9: "",
            dt1: "Gidarja",
            sy7: "ܓܺܝܕܰܪܰܐ",
            id: "1636",
            dt3: "",
            sy6: "ܓܝܕܪܐ"
        },
        {
            dt1: "Militärübung",
            sy5: "ܕܽܘܪܳܫ ܦܳܠܚܽܘܬ݂ܳܐ",
            lt10: "durosh folĥutho",
            lt11: "",
            dt2: "",
            sy9: "",
            sy8: "",
            id: "3034",
            sy4: "ܕܘܪܫ ܦܠܚܘܬܐ",
            sy7: "",
            dt3: "",
            sy6: "",
            lt12: ""
        },
        {
            sy4: "ܦܪܫܓܢܐ ܕܒܝܬܐ",
            lt10: "farshağno d`bayto",
            id: "1894",
            sy7: "",
            sy6: "",
            sy9: "",
            dt3: "",
            sy8: "",
            sy5: "ܦܰܪܫܰܓ݂ܢܳܐ ܕܒܰܝܬܳܐ",
            lt12: "",
            lt11: "",
            dt1: "Hausadresse",
            dt2: "Hausanschrift"
        },
        {
            dt3: "",
            dt1: "unberührt",
            id: "4633",
            sy8: "",
            lt11: "",
            dt2: "",
            sy6: "",
            sy4: "ܢܟܦܐ",
            lt10: "nakhfo",
            sy9: "",
            lt12: "",
            sy5: "ܢܰܟ݂ܦܳܐ",
            sy7: ""
        },
        {
            sy7: "",
            dt1: "Gouverneur",
            lt11: "",
            sy8: "",
            sy4: "ܩܝܘܡܐ",
            sy5: "ܩܳܝܽܘܡܳܐ",
            id: "1718",
            lt12: "",
            dt3: "Direktor",
            sy9: "",
            dt2: "Präfekt",
            lt10: "qoyumo",
            sy6: ""
        },
        {
            lt10: "yorutho d`trunus",
            lt11: "",
            dt2: "",
            sy8: "",
            dt1: "Thronfolger",
            sy5: "ܝܽܪܽܘܬ݂ܳܐ ܕܬܪܽܘܢܽܘܣ",
            dt3: "",
            sy4: "ܝܪܘܬܐ ܕܬܪܘܢܘܣ",
            id: "4471",
            sy7: "",
            lt12: "",
            sy9: "",
            sy6: ""
        },
        {
            dt2: "",
            lt10: "eno mshiĥoyo no",
            dt3: "",
            sy7: "",
            sy4: "ܐܢܐ ܡܫܝܚܝܐ ܐܢܐ",
            dt1: "Ich bin Christ",
            sy8: "",
            sy5: "ܐܶܢܳܐ ܡܫܺܝܚܳܝܳܐ ܐ̱ܢܳܐ",
            lt12: "",
            sy6: "",
            id: "2091",
            sy9: "",
            lt11: ""
        },
        {
            sy8: "",
            dt3: "",
            lt10: "ܐܠܝܟܝܡ",
            sy7: "ܐܶܠܝܰܩܺܝܡ",
            id: "1083",
            dt1: "Eljakim",
            dt2: "Eljaqim",
            sy6: "ܐܠܝܩܝܡ",
            sy4: "ܐܠܝܟܝܡ",
            lt12: "ܐܠܝܩܝܡ",
            sy5: "ܐܶܠܝܰܟܺܝܡ",
            lt11: "ܐܠܝܟܝܡ",
            sy9: ""
        },
        {
            sy7: "",
            sy5: "ܡܶܬ݂ܰܐܟ݂ܠܳܢܳܐ",
            id: "6147",
            sy8: "",
            sy6: "",
            lt10: "methakhlono",
            lt11: "",
            dt2: "",
            sy9: "",
            dt3: "",
            dt1: "essbar",
            lt12: "",
            sy4: "ܡܬܐܟܠܢܐ"
        },
        {
            dt2: "",
            sy5: "ܐܰܕܡܶܟ݂ ܙܽܘܙ̈ܐ",
            lt10: "admekh zuze",
            dt1: "Geld anlegen",
            lt12: "",
            sy7: "",
            sy9: "",
            sy8: "",
            lt11: "",
            id: "1548",
            sy4: "ܐܕܡܟ ܙܘܙܐ",
            dt3: "",
            sy6: ""
        },
        {
            id: "3636",
            lt10: "qonunoyo",
            lt11: "zodqoyo",
            sy6: "ܙܕܩܝܐ",
            dt2: "",
            dt3: "",
            sy9: "",
            sy7: "ܙܰܕܩܳܝܳܐ",
            sy5: "ܩܳܢܽܘܢܳܝܳܐ",
            lt12: "",
            sy8: "",
            sy4: "ܩܢܘܢܝܐ",
            dt1: "rechtmäßig"
        },
        {
            id: "2675",
            sy6: "ܙܥܘܪܐ",
            dt2: "",
            dt1: "kurz",
            sy4: "ܟܪܝܐ",
            sy5: "ܟܰܪܝܳܐ",
            lt12: "",
            lt10: "karyo",
            dt3: "",
            lt11: "zĉuro",
            sy9: "",
            sy8: "",
            sy7: "ܙܥܽܘܪܳܐ"
        },
        {
            dt1: "Bücherei",
            sy6: "",
            lt10: "beth kthobe",
            sy8: "",
            lt11: "",
            lt12: "",
            sy7: "",
            sy9: "",
            sy4: "ܒܝܬ ܟܬܒܐ",
            dt3: "",
            id: "726",
            sy5: "ܒܶܝܬ݂ ܟܬ݂ܳܒ̈ܶܐ",
            dt2: "Buchhandlung"
        },
        {
            dt3: "",
            sy5: "ܥܳܡܪܳܐ",
            id: "1055",
            lt11: "ĉomuro",
            lt12: "",
            sy7: "ܥܳܡܽܘܪܳܐ",
            sy8: "",
            dt2: "Bewohner",
            sy9: "",
            lt10: "ĉomro",
            sy6: "ܥܡܘܪܐ",
            dt1: "Einwohner",
            sy4: "ܥܡܪܐ"
        },
        {
            dt3: "",
            sy6: "",
            dt1: "Start",
            sy9: "",
            dt2: "Beginn",
            sy4: "ܫܘܪܝܐ",
            lt10: "shuroyo",
            id: "4241",
            sy7: "",
            lt12: "",
            lt11: "",
            sy8: "",
            sy5: "ܫܽܘܪܳܝܳܐ"
        },
        {
            dt2: "",
            dt3: "",
            sy7: "",
            sy9: "",
            lt12: "",
            sy5: "ܪܰܩܳܕܳܐ",
            lt11: "",
            sy6: "",
            id: "4382",
            dt1: "Tänzer",
            sy4: "ܪܩܕܐ",
            sy8: "",
            lt10: "raqođo"
        },
        {
            sy5: "ܛܶܒܳܐ ܡܬܺܝܪܳܢܳܐ",
            lt11: "",
            dt1: "Sensationsbericht",
            dt2: "Sensationsnachricht",
            sy8: "",
            lt10: "țebo mtirono",
            lt12: "",
            sy7: "",
            dt3: "",
            sy6: "",
            id: "4123",
            sy9: "",
            sy4: "ܛܒܐ ܡܬܝܪܢܐ"
        },
        {
            sy8: "",
            sy5: "ܛܽܘܦܣܳܢܳܐܝܺܬ݂",
            dt2: "typisch",
            lt10: "țufsonoith",
            dt1: "symbolisch",
            sy6: "",
            lt11: "",
            sy7: "",
            id: "4332",
            sy9: "",
            dt3: "",
            lt12: "",
            sy4: "ܛܘܦܣܢܐܝܬ"
        },
        {
            dt2: "erlöschen",
            sy5: "ܐܰܕܥܶܟ݂",
            lt11: "",
            dt3: "auslöschen",
            sy8: "",
            sy7: "",
            lt12: "",
            sy4: "ܐܕܥܟ",
            lt10: "adĉekh",
            id: "381",
            sy9: "",
            dt1: "ausmachen",
            sy6: ""
        },
        {
            sy9: "",
            sy8: "",
            sy7: "ܝܺܡܳܐ",
            id: "4084",
            dt1: "schwören",
            lt10: "yome",
            sy4: "ܝܡܐ",
            lt11: "yimo",
            lt12: "",
            sy6: "ܝܡܐ",
            dt2: "",
            dt3: "",
            sy5: "ܝܳܡܶܐ"
        },
        {
            id: "1956",
            dt1: "hell",
            sy4: "ܢܘܗܪܐ",
            lt12: "",
            lt10: "nuhro",
            sy7: "ܒܰܗܽܘܪܳܐ",
            sy9: "",
            lt11: "bahuro",
            dt2: "",
            sy8: "",
            sy5: "ܢܽܘܗܪܳܐ",
            dt3: "",
            sy6: "ܒܗܘܪܐ"
        },
        {
            id: "4275",
            sy8: "",
            sy7: "",
            dt2: "aufhören",
            sy6: "",
            lt10: "bațel",
            sy4: "ܒܛܠ",
            dt1: "stilllegen",
            sy5: "ܒܰܛܶܠ",
            dt3: "anhalten",
            lt11: "",
            sy9: "",
            lt12: ""
        },
        {
            sy5: "ܦܽܘܠܚܳܢ ܢܶܫܶ̈ܐ",
            lt12: "",
            sy9: "",
            sy8: "",
            dt1: "Frauenarbeit",
            lt10: "fulĥon neshe",
            sy7: "",
            dt2: "",
            lt11: "",
            sy4: "ܦܘܠܚܢ ܢܫܐ",
            id: "6084",
            sy6: "",
            dt3: ""
        },
        {
            sy7: "",
            dt3: "",
            dt2: "",
            lt12: "",
            sy5: "ܨܠܺܝܒܳܐ",
            id: "2642",
            sy9: "",
            dt1: "Kreuz",
            lt11: "",
            sy8: "",
            lt10: "ŝlibo",
            sy6: "",
            sy4: "ܨܠܝܒܐ"
        },
        {
            dt2: "",
            lt10: "iqartho lĉal men kulmedem",
            lt11: "",
            sy9: "",
            dt3: "",
            dt1: "Familie über alles",
            sy4: "ܐܝܩܪܬܐ ܠܥܠ ܡܢ ܟܠܡܕܡ",
            lt12: "",
            sy5: "ܐܺܝܩܰܪܬ݂ܳܐ ܠܥܰܠ ܡܶܢ ܟܽܠܡܶܕܶܡ",
            sy7: "",
            id: "51",
            sy6: "",
            sy8: ""
        },
        {
            dt3: "",
            lt10: "knushyo",
            id: "4798",
            sy6: "ܒܝܬ ܘܥܕܐ",
            lt11: "beth waĉdo",
            lt12: "",
            sy8: "",
            sy9: "",
            dt1: "Versammlung",
            sy5: "ܟܢܽܘܫܝܳܐ",
            dt2: "Zusammenkunft",
            sy7: "ܒܶܝܬ݂ ܘܰܥܕܳܐ",
            sy4: "ܟܢܘܫܝܐ"
        },
        {
            sy8: "",
            sy6: "",
            sy7: "",
            lt11: "",
            lt10: "ĥolumo",
            sy5: "ܚܳܠܘܽܡܳܐ",
            dt2: "",
            sy4: "ܚܠܘܡܐ",
            sy9: "",
            lt12: "",
            dt1: "Träumer",
            id: "4549",
            dt3: ""
        },
        {
            sy6: "ܡܫܬܘܬܦܢܐ",
            lt10: "shautofo",
            dt3: "",
            sy9: "",
            lt11: "mshtautfono",
            lt12: "",
            id: "4426",
            sy7: "ܡܫܬܰܘܬܦܳܢܳܐ",
            sy4: "ܫܘܬܦܐ",
            sy5: "ܫܰܘܬܳܦܳܐ",
            sy8: "",
            dt2: "Partner",
            dt1: "Teilnehmer"
        },
        {
            sy9: "",
            lt11: "",
            dt3: "Feiertagsgeschenk",
            id: "3402",
            sy8: "",
            lt12: "",
            lt10: "doshno d`ĉiđo",
            dt1: "Ostergeschenk",
            sy6: "",
            sy5: "ܕܳܫܢܳܐ ܕܥܺܐܕܳܐ",
            dt2: "Weihnachtsgeschenk",
            sy4: "ܕܫܢܐ ܕܥܐܕܐ",
            sy7: ""
        },
        {
            sy5: "ܩܰܪܩܽܘܪܳܐ",
            lt12: "",
            lt11: "",
            id: "679",
            sy8: "",
            sy9: "",
            dt2: "Segelboot",
            sy4: "ܩܪܩܘܪܐ",
            dt3: "",
            sy7: "",
            sy6: "",
            lt10: "qarquro",
            dt1: "Boot"
        },
        {
            lt12: "",
            sy5: "ܪܶܡܙܳܐ",
            lt10: "remzo",
            dt3: "",
            sy4: "ܪܡܙܐ",
            sy7: "",
            lt11: "",
            sy8: "",
            id: "4154",
            sy9: "",
            dt1: "Signal",
            dt2: "Zeichen",
            sy6: ""
        },
        {
            dt3: "",
            sy5: "ܚܰܣܺܝܢܬܰܐ",
            id: "1880",
            sy4: "ܚܣܝܢܬܐ",
            sy9: "",
            sy8: "",
            lt11: "ܚܣܝܢܬܐ",
            lt10: "ܚܣܝܢܬܐ",
            lt12: "",
            sy6: "",
            sy7: "",
            dt2: "",
            dt1: "Hasinta"
        },
        {
            dt3: "",
            sy6: "ܟܐܒ ܟܘܠܝܬܐ",
            sy4: "ܟܘܪܗܢ ܟܘܠܝܬܐ",
            lt12: "",
            lt11: "kib kulyotho",
            sy7: "ܟܺܐܒ ܟܽܘܠܝܳܬ݂ܳܐ",
            dt2: "",
            lt10: "kurhon kulyotho",
            sy9: "",
            sy5: "ܟܽܘܪܗܳܢ ܟܽܘܠܺܝܬ݂ܳܐ",
            sy8: "",
            id: "3272",
            dt1: "Nierenkrankheit"
        },
        {
            dt2: "Teodora",
            dt3: "",
            sy4: "ܬܐܘܕܘܪܐ",
            sy9: "",
            lt12: "",
            sy6: "",
            dt1: "Theodora",
            sy5: "ܬܶܐܘܳܕܳܘܪܰܐ",
            lt10: "ܬܐܘܕܘܪܐ",
            sy7: "",
            sy8: "",
            id: "4455",
            lt11: "ܬܐܘܕܘܪܐ"
        },
        {
            lt12: "",
            dt2: "schliessen",
            sy6: "ܣܟܪ",
            dt3: "",
            sy5: "ܐܰܩܠܶܕ",
            lt10: "aqleđ",
            sy4: "ܐܩܠܕ",
            sy8: "",
            dt1: "schließen",
            sy9: "",
            id: "3970",
            lt11: "skhar",
            sy7: "ܣܟ݂ܰܪ"
        },
        {
            id: "4761",
            sy7: "",
            sy8: "",
            sy6: "",
            sy4: "ܡܟܝܪܬܐ",
            sy5: "ܡܟ݂ܺܝܪܬܳܐ",
            lt11: "",
            lt12: "",
            dt3: "",
            lt10: "mkhirto",
            sy9: "",
            dt2: "",
            dt1: "Verlobte"
        },
        {
            sy5: "ܪܰܒܐܺܝܠ",
            sy6: "",
            sy8: "",
            lt12: "",
            dt3: "",
            sy9: "",
            sy4: "ܪܒܐܝܠ",
            dt2: "",
            lt11: "ܪܒܐܝܠ",
            lt10: "ܪܒܐܝܠ",
            id: "3580",
            dt1: "Rabil",
            sy7: ""
        },
        {
            sy9: "",
            lt11: "",
            lt10: "ĥemyono",
            dt3: "",
            sy8: "",
            sy6: "",
            dt2: "",
            sy7: "",
            id: "4083",
            sy4: "ܚܡܝܢܐ",
            dt1: "Schwiegervater",
            lt12: "",
            sy5: "ܚܶܡܝܳܢܳܐ"
        },
        {
            lt12: "tabar",
            sy5: "ܫܰܩܶܩ",
            dt3: "",
            lt10: "shaqeq",
            dt2: "",
            id: "6283",
            sy4: "ܫܩܩ",
            lt11: "shaq",
            sy8: "ܬܒܪ",
            dt1: "zertrümmern",
            sy7: "ܫܰܩ",
            sy6: "ܫܩ",
            sy9: "ܬܰܒܰܪ"
        },
        {
            lt11: "",
            lt10: "yaŝilo",
            sy4: "ܝܨܝܠܐ",
            sy5: "ܝܰܨܺܝܠܳܐ",
            id: "1085",
            dt3: "",
            lt12: "",
            dt2: "",
            sy8: "",
            sy7: "",
            sy6: "",
            sy9: "",
            dt1: "Ellenbogen"
        },
        {
            dt3: "",
            dt2: "",
            lt12: "",
            lt11: "",
            sy8: "",
            sy5: "ܫܒܳܒܽܘܬ݂ܳܐ",
            dt1: "Nachbarschaft",
            sy4: "ܫܒܒܘܬܐ",
            lt10: "shbobutho",
            id: "3153",
            sy9: "",
            sy6: "",
            sy7: ""
        },
        {
            sy9: "",
            sy6: "",
            sy4: "ܛܝܙܐ ܢܩܘܐ",
            lt12: "",
            sy7: "",
            sy8: "",
            dt3: "",
            dt1: "Arschloch",
            sy5: "ܛܺܝܙܳܐ ܢܰܩܘܳܐ",
            lt11: "",
            lt10: "țizo naqwo",
            id: "304",
            dt2: ""
        },
        {
            sy6: "ܚܟܝܡܐ",
            dt2: "",
            sy7: "ܚܰܟܺܝܡܳܐ",
            dt1: "klug",
            lt11: "ĥakimo",
            sy9: "",
            sy4: "ܢܐܗܝܪܐ",
            id: "2537",
            sy5: "ܢܰܐܗܺܝܪܳܐ",
            sy8: "",
            lt10: "nahiro",
            lt12: "",
            dt3: ""
        },
        {
            dt1: "Heizöl",
            sy8: "",
            dt3: "",
            sy7: "",
            sy5: "ܢܰܦܛܳܐ ܕܝܽܘܩܕܳܢܳܐ",
            id: "1947",
            sy9: "",
            sy4: "ܢܦܛܐ ܕܝܘܩܕܢܐ",
            lt12: "",
            lt10: "nafțo d`yuqđono",
            dt2: "",
            lt11: "",
            sy6: ""
        },
        {
            sy5: "ܫܳܛܺܝܦܬܳܐ",
            dt2: "",
            sy7: "ܫܺܝܫܬܳܐ",
            lt11: "shishto",
            sy4: "ܫܛܝܦܬܐ",
            sy8: "",
            sy9: "",
            sy6: "ܫܝܫܬܐ",
            dt1: "Flasche",
            id: "1323",
            lt10: "shotifțo",
            lt12: "",
            dt3: ""
        },
        {
            lt10: "ethgasham men ruĥo qadisho",
            lt11: "",
            lt12: "",
            sy4: "ܐܬܓܫܡ ܡܢ ܪܘܚܐ ܩܕܝܫܐ",
            sy8: "",
            dt1: "Hat Fleisch angenommen durch den heiligen Geist",
            dt3: "",
            dt2: "",
            sy5: "ܐܶܬ݂ܓܰܫܰܡ ܡܶܢ ܪܽܘܚܳܐ ܩܰܕܺܝܫܳܐ",
            sy9: "",
            sy6: "",
            id: "1885",
            sy7: ""
        },
        {
            lt12: "",
            sy4: "ܫܘܟܢܐ",
            sy5: "ܫܽܘܟܳܢܳܐ",
            sy6: "",
            dt2: "",
            dt1: "Schukono",
            dt3: "",
            sy7: "",
            sy9: "",
            sy8: "",
            lt10: "ܫܘܟܢܐ",
            id: "4043",
            lt11: "ܫܘܟܢܐ"
        },
        {
            lt12: "",
            dt3: "",
            sy7: "",
            sy4: "ܩܠܝܬܐ ܕܣܥܘܪ̈ܐ",
            dt2: "",
            sy9: "",
            sy6: "",
            sy8: "",
            lt10: "qeloyto d`soĉure",
            dt1: "Gästezimmer",
            sy5: "ܩܶܠܳܝܬܳܐ ܕܣܳܥܽܘܪ̈ܐ",
            id: "1484",
            lt11: ""
        },
        {
            dt2: "",
            sy9: "",
            lt10: "yum  shabtho",
            lt12: "",
            sy7: "",
            dt3: "",
            sy6: "",
            sy5: "ܝܽܘܡ ܫܰܒܬ݂ܳܐ",
            dt1: "Samstag",
            sy8: "",
            sy4: "ܝܘܡ ܫܒܬܐ",
            id: "3828",
            lt11: ""
        },
        {
            sy7: "ܡܠܶܐ",
            sy9: "",
            dt2: "",
            id: "4865",
            sy4: "ܡܠܝܐ",
            lt10: "malyo",
            sy6: "ܡܠܐ",
            lt11: "mle",
            dt1: "voll",
            sy5: "ܡܰܠܝܳܐ",
            sy8: "",
            lt12: "",
            dt3: ""
        },
        {
            lt11: "ŝud",
            id: "2269",
            sy6: "ܨܘܕ",
            sy8: "",
            dt2: "",
            sy9: "",
            sy7: "ܨܽܘܕ",
            lt12: "",
            lt10: "ŝod",
            dt3: "",
            dt1: "jagen",
            sy4: "ܨܕ",
            sy5: "ܨܳܕ"
        },
        {
            lt11: "ܫܡܫܘܢ",
            sy6: "ܫܡܥܘܢ",
            sy5: "ܫܶܡܫܽܘܢ",
            lt12: "ܫܡܥܘܢ",
            lt10: "ܫܡܫܘܢ",
            id: "3923",
            dt2: "Schamun",
            sy8: "",
            dt1: "Schemschun",
            dt3: "",
            sy9: "",
            sy4: "ܫܡܫܘܢ",
            sy7: "ܫܰܡܥܽܘܢ"
        },
        {
            dt2: "",
            lt12: "",
            id: "1694",
            sy4: "ܐܠܗܐ ܥܡܢ ܗܘ ܒܟܠ ܙܒܢ ܘܒܟܠ ܕܘܟ",
            sy9: "",
            lt11: "",
            sy7: "",
            sy8: "",
            sy6: "",
            dt1: "gott ist mit uns immer und überall",
            dt3: "",
            sy5: "ܐܰܠܳܗܳܐ ܥܰܡܰܢ ܗܽܘ ܒܟܽܠ ܙܒܰܢ ܘܰܒܟܽܠ ܕܽܘܟ",
            lt10: "aloho ĉaman hu bkul zban wab kul duk"
        },
        {
            dt1: "Kerze",
            lt12: "",
            sy6: "",
            lt10: "qeryuno",
            sy5: "ܩܶܪܽܝܘܢܳܐ",
            sy7: "",
            sy4: "ܩܪܝܘܢܐ",
            lt11: "",
            sy9: "",
            dt3: "",
            id: "2459",
            dt2: "",
            sy8: ""
        },
        {
            dt2: "",
            lt10: "sharbolo",
            lt12: "",
            sy8: "",
            sy5: "ܫܰܪܒܳܠܳܐ",
            id: "2056",
            sy4: "ܫܪܒܠܐ",
            sy6: "ܒܢܛܠܐ",
            lt11: "banțlo",
            sy9: "",
            sy7: "ܒܰܢܛܠܳܐ",
            dt1: "Hose",
            dt3: ""
        },
        {
            dt2: "Verkünder",
            sy9: "ܡܣܰܒܪܳܢܳܐ",
            lt12: "msabrono",
            sy7: "ܡܰܟ݂ܪܙܳܢܳܐ",
            lt11: "makhrzono",
            sy6: "ܡܟܪܙܢܐ",
            lt10: "koruzo",
            dt3: "Verkündiger",
            sy4: "ܟܪܘܙܐ",
            id: "3505",
            sy8: "ܡܣܒܪܢܐ",
            dt1: "Prediger",
            sy5: "ܟܳܪܽܘܙܳܐ"
        },
        {
            dt1: "herzlich begegnen",
            lt11: "",
            sy6: "",
            id: "1984",
            sy9: "",
            dt3: "",
            dt2: "friedlich begegnen",
            sy7: "",
            lt12: "",
            sy5: "ܐܳܩܒܶܠ ܒܫܰܝܢܳܐ",
            sy4: "ܐܩܒܠ ܒܫܝܢܐ",
            sy8: "",
            lt10: "aqbel bsheyno"
        },
        {
            dt2: "",
            dt1: "Essig",
            lt11: "muryo",
            sy5: "ܚܰܠܳܐ",
            sy4: "ܚܠܐ",
            sy8: "ܣܩ",
            id: "6146",
            sy7: "ܡܽܘܪܝܳܐ",
            sy9: "ܣܰܩ",
            dt3: "",
            lt12: "saq",
            lt10: "ĥalo",
            sy6: "ܡܘܪܝܐ"
        },
        {
            id: "3258",
            sy7: "",
            sy8: "",
            lt12: "",
            sy4: "ܠܝܬ",
            dt3: "kein",
            sy9: "",
            lt11: "",
            sy6: "",
            dt1: "nicht",
            lt10: "layt",
            dt2: "es gibt nicht",
            sy5: "ܠܰܝܬ"
        },
        {
            dt1: "Spielzeug",
            lt12: "",
            dt2: "",
            sy8: "",
            lt10: "sheĉyono",
            sy4: "ܫܥܝܢܐ",
            lt11: "",
            sy5: "ܫܥܰܝܢܳܐ",
            sy7: "",
            sy6: "",
            id: "4217",
            dt3: "",
            sy9: ""
        },
        {
            sy9: "",
            sy7: "",
            dt3: "",
            sy4: "ܝܡܐ",
            sy5: "ܝܰܡܳܐ",
            lt11: "ܝܡܐ",
            sy6: "",
            lt10: "ܝܡܐ",
            lt12: "",
            id: "5198",
            sy8: "",
            dt1: "Yamo",
            dt2: "Jamo"
        },
        {
            sy9: "",
            lt12: "",
            sy7: "",
            dt3: "",
            id: "2182",
            sy6: "",
            lt11: "",
            sy4: "ܒܗܠܝܢ ܝܘܡܬܐ",
            dt2: "",
            sy5: "ܒܗܳܠܶܝܢ ܝܰܘ̈ܡܳܬ݂ܳܐ",
            dt1: "in diesen Tagen",
            lt10: "b`holen yaumotho",
            sy8: ""
        },
        {
            sy6: "",
            dt1: "Baruch",
            lt12: "",
            dt3: "",
            lt10: "ܒܪܘܟ݂",
            sy5: "ܒܰܪܽܘܟ݂",
            id: "484",
            sy8: "",
            sy4: "ܒܪܘܟ݂",
            sy7: "",
            dt2: "",
            sy9: "",
            lt11: "ܒܪܘܟ݂"
        },
        {
            dt2: "Akku",
            sy8: "",
            sy6: "ܒܛܪܝܝܬܐ",
            sy4: "ܒܛܪܝܐ",
            sy5: "ܒܰܛܰܪܝܰܐ",
            lt12: "",
            id: "505",
            dt3: "Akkumulator",
            sy9: "",
            dt1: "Batterie",
            lt10: "bațarya",
            lt11: "bațritho",
            sy7: "ܒܰܛܪܺܝܝܬ݂ܳܐ"
        },
        {
            lt11: "",
            sy5: "ܩܛܳܠܳܐ ܢܰܦܫܳܝܳܐ",
            sy8: "",
            dt3: "",
            lt10: "qețlo nafshoyo",
            sy4: "ܩܛܠܐ ܢܦܫܝܐ",
            lt12: "",
            dt1: "Freitod",
            dt2: "",
            id: "1385",
            sy9: "",
            sy7: "",
            sy6: ""
        },
        {
            id: "3804",
            lt10: "nsar",
            sy9: "",
            dt3: "",
            sy7: "ܢܳܣܰܪ",
            lt11: "nosar",
            dt2: "absägen",
            sy8: "",
            lt12: "",
            sy4: "ܢܣܪ",
            dt1: "sägen",
            sy5: "ܢܣܰܪ",
            sy6: "ܢܣܪ"
        },
        {
            sy8: "",
            lt10: "aykano d`bashmayo of b`arĉo",
            dt3: "",
            lt12: "",
            sy6: "",
            sy9: "",
            id: "5092",
            dt2: "",
            sy7: "",
            lt11: "",
            dt1: "Wie im Himmel so auch auf Erden",
            sy5: "ܐܰܝܟܰܢܳܐ ܕܒܰܫܡܰܝܳܐ ܐܳܦ ܒܰܐܪܥܳܐ",
            sy4: "ܐܝܟܢܐ ܕܒܫܡܝܐ ܐܦ ܒܐܪܥܐ"
        },
        {
            dt1: "Konjugation",
            sy4: "ܣܘܪܥܦܐ",
            sy7: "",
            sy6: "",
            lt10: "surĉofo",
            sy8: "",
            sy5: "ܣܽܘܪܥܳܦܳܐ",
            dt3: "Verbalform",
            sy9: "",
            lt12: "",
            dt2: "Abzweigung",
            id: "2580",
            lt11: ""
        },
        {
            id: "3633",
            dt2: "Rechte",
            sy9: "",
            sy5: "ܙܶܕܩܳܐ",
            dt3: "",
            sy6: "ܙܕܩܐ",
            lt10: "zedqo",
            sy7: "ܙܶܕ̈ܩܶܐ",
            dt1: "Recht",
            sy8: "",
            lt11: "zedqe",
            lt12: "",
            sy4: "ܙܕܩܐ"
        },
        {
            sy5: "ܐܶܬܬܦܺܝܪ",
            lt12: "",
            sy6: "",
            id: "3764",
            sy9: "",
            sy4: "ܐܬܬܦܝܪ",
            dt2: "tadeln",
            sy7: "",
            dt3: "",
            dt1: "rügen",
            lt10: "ettfir",
            lt11: "",
            sy8: ""
        },
        {
            dt3: "",
            dt2: "",
            dt1: "Vorname",
            sy5: "ܫܡܳܐ ܩܰܕܡܳܝܳܐ",
            sy8: "",
            id: "4903",
            lt12: "",
            lt11: "",
            sy6: "",
            sy9: "",
            sy7: "",
            lt10: "shmo qadmoyo",
            sy4: "ܫܡܐ ܩܕܡܝܐ"
        },
        {
            sy7: "",
            sy8: "",
            sy4: "ܛܘܪܩܝܐ",
            sy9: "",
            sy5: "ܛܽܘܪܩܺܝܰܐ",
            id: "4600",
            dt3: "",
            lt10: "turqiya",
            dt2: "",
            sy6: "",
            lt11: "",
            dt1: "Türkei",
            lt12: ""
        },
        {
            sy9: "",
            lt11: "ܦܗܝܡܐ",
            sy6: "",
            sy7: "",
            sy4: "ܦܗܝܡܐ",
            id: "1229",
            dt1: "Fahima",
            lt12: "",
            lt10: "ܦܗܝܡܐ",
            sy5: "ܦܰܗܺܝܡܰܐ",
            dt2: "",
            dt3: "",
            sy8: ""
        },
        {
            lt10: "methfasqono",
            sy7: "",
            sy9: "",
            dt3: "",
            dt1: "zerteilbar",
            sy5: "ܡܶܬ݂ܦܰܣܩܳܢܳܐ",
            lt12: "",
            id: "6285",
            sy4: "ܡܬܦܣܩܢܐ",
            lt11: "",
            sy8: "",
            sy6: "",
            dt2: ""
        },
        {
            dt3: "",
            sy6: "",
            sy5: "ܒܪܳܘܟܬܰܐ",
            id: "718",
            sy9: "",
            sy8: "",
            lt11: "ܒܪܘܟܬܐ",
            sy7: "",
            dt1: "Brukta",
            lt10: "ܒܪܘܟܬܐ",
            sy4: "ܒܪܘܟܬܐ",
            lt12: "",
            dt2: ""
        },
        {
            lt10: "ܒܟܘܣ",
            lt12: "",
            lt11: "ܒܟܘܣ",
            dt1: "Bakos",
            dt2: "",
            id: "425",
            sy7: "",
            dt3: "",
            sy8: "",
            sy9: "",
            sy5: "ܒܰܟܳܘܣ",
            sy4: "ܒܟܘܣ",
            sy6: ""
        },
        {
            sy7: "ܟܰܪܛܳܐ ܕܥܳܠܡܳܐ",
            dt3: "Atlas",
            sy9: "",
            sy6: "ܟܪܛܐ ܕܥܠܡܐ",
            lt10: "ŝurath ĉolmo",
            dt1: "Weltkarte",
            id: "5052",
            lt11: "karțo d`ĉolmo",
            lt12: "",
            sy5: "ܨܽܘܪܰܬ݂ ܥܳܠܡܳܐ",
            sy8: "",
            dt2: "Weltatlas",
            sy4: "ܨܘܪܬ ܥܠܡܐ"
        },
        {
            lt11: "",
            dt1: "an jemanden Stelle treten",
            lt10: "qom ĥlofau",
            id: "5901",
            sy7: "",
            sy4: "ܩܡ ܚܠܦܘܗܝ",
            sy6: "",
            sy5: "ܩܳܡ ܚܠܳܦܰܘܗ̱ܝ",
            sy8: "",
            dt3: "",
            dt2: "",
            lt12: "",
            sy9: ""
        },
        {
            dt2: "",
            sy7: "",
            sy8: "",
            sy4: "ܐܪܡܠܬܐ",
            sy6: "",
            lt11: "",
            sy9: "",
            sy5: "ܐܰܪܡܰܠܬܳܐ",
            lt10: "armalto",
            id: "5137",
            dt3: "",
            lt12: "",
            dt1: "Witwe"
        },
        {
            lt12: "",
            dt1: "Dein Reich komme",
            sy4: "ܬܐܬܐ ܡܠܟܘܬܟ",
            sy5: "ܬܺܐܬ݂ܶܐ ܡܰܠܟܽܘܬ݂ܳܟ݂",
            lt11: "",
            sy8: "",
            sy6: "",
            dt3: "",
            id: "844",
            lt10: "tithe malkuthokh",
            sy9: "",
            sy7: "",
            dt2: ""
        },
        {
            sy7: "",
            sy9: "",
            lt12: "",
            dt3: "",
            sy5: "ܡܽܘܫܶܐ",
            lt11: "ܡܘܫܐ",
            dt1: "Moses",
            id: "3112",
            lt10: "ܡܘܫܐ",
            sy8: "",
            dt2: "Musche",
            sy4: "ܡܘܫܐ",
            sy6: ""
        },
        {
            lt11: "",
            sy6: "",
            lt12: "",
            lt10: "ĉarțeloyo",
            sy5: "ܥܰܪܛܶܠܳܝܳܐ",
            sy4: "ܥܪܛܠܝܐ",
            dt2: "",
            dt1: "Nudist",
            sy9: "",
            dt3: "",
            sy8: "",
            id: "3306",
            sy7: ""
        },
        {
            dt1: "Beispiel",
            sy9: "",
            sy7: "",
            lt12: "",
            lt10: "mathlo",
            dt2: "Redewendung",
            dt3: "",
            sy6: "",
            sy4: "ܡܬܠܐ",
            sy5: "ܡܰܬ݂ܠܳܐ",
            lt11: "",
            sy8: "",
            id: "534"
        },
        {
            sy4: "ܢܦܫܐ",
            dt2: "Lebewesen",
            lt10: "nafsho",
            lt11: "",
            dt1: "Selbst",
            sy5: "ܢܰܦܫܳܐ",
            sy9: "",
            lt12: "",
            dt3: "",
            sy7: "",
            sy8: "",
            id: "4108",
            sy6: ""
        },
        {
            sy6: "",
            id: "5880",
            lt11: "",
            lt10: "shaĥruro",
            sy5: "ܫܰܚܪܽܘܪܳܐ",
            dt1: "Amsel",
            dt2: "",
            sy9: "",
            lt12: "",
            dt3: "",
            sy4: "ܫܚܪܘܪܐ",
            sy7: "",
            sy8: ""
        },
        {
            sy8: "",
            lt12: "",
            id: "4778",
            sy4: "ܐܓܘܪܘܬܐ",
            lt10: "oğurutho",
            dt1: "Verpachtung",
            lt11: "",
            dt2: "",
            sy5: "ܐܳܓ݂ܽܘܪܽܘܬ݂ܳܐ",
            sy7: "",
            dt3: "",
            sy6: "",
            sy9: ""
        },
        {
            lt12: "qoŝumutho",
            dt1: "Prophezeiung",
            sy9: "ܩܳܨܽܘܡܽܘܬ݂ܳܐ",
            sy6: "ܡܬܢܒܝܢܘܬܐ",
            sy5: "ܢܒܝܽܘܬ݂ܳܐ",
            sy8: "ܩܨܘܡܘܬܐ",
            dt3: "",
            id: "3526",
            lt10: "nbiyutho",
            sy7: "ܡܶܬ݂ܢܰܒܝܳܢܽܘܬ݂ܳܐ",
            sy4: "ܢܒܝܘܬܐ",
            dt2: "",
            lt11: "methnabyonutho"
        },
        {
            dt1: "Obstsaft",
            sy7: "",
            dt3: "",
            dt2: "",
            lt11: "",
            sy4: "ܐܘܦܣܐ",
            sy6: "",
            lt10: "ufse",
            sy9: "",
            sy8: "",
            id: "6127",
            lt12: "",
            sy5: "ܐܽܘܦܣܶܐ"
        },
        {
            id: "10",
            sy6: "ܬܫܥ",
            lt10: "teshĉo",
            sy7: "ܬܫܰܥ",
            sy8: "ܜ",
            dt1: "9",
            dt2: "Neun",
            sy5: "ܬܶܫܥܳܐ ",
            lt12: "țeth",
            lt11: "tshaĉ",
            sy9: "ܜ",
            sy4: "ܬܫܥܐ",
            dt3: ""
        },
        {
            lt11: "",
            id: "4513",
            sy8: "",
            sy5: "ܦܽܘܣܩܳܢ ܡܰܘܬܳܐ",
            dt1: "Todesurteil",
            dt3: "",
            lt10: "fusqon mauto",
            dt2: "",
            sy6: "",
            sy9: "",
            sy4: "ܦܘܣܩܢ ܡܘܬܐ",
            lt12: "",
            sy7: ""
        },
        {
            lt12: "",
            id: "1652",
            dt3: "",
            lt11: "",
            dt2: "",
            lt10: "mhaymen u maĉqab",
            sy4: "ܡܗܝܡܢ ܘ ܡܥܩܒ",
            sy9: "",
            sy5: "ܡܗܰܝܡܶܢ ܘ ܡܰܥܩܰܒ",
            dt1: "Glaube und Forsche",
            sy6: "",
            sy8: "",
            sy7: ""
        },
        {
            sy6: "",
            sy8: "",
            dt3: "",
            sy9: "",
            dt1: "hinten bleiben",
            lt10: "fosh ĥroyo",
            id: "2009",
            lt11: "",
            sy7: "",
            sy5: "ܦܳܫ ܐ̱ܚܪܳܝܳܐ",
            lt12: "",
            sy4: "ܦܫ ܐܚܪܝܐ",
            dt2: ""
        },
        {
            id: "104",
            dt1: "Abmachung",
            sy8: "",
            sy7: "",
            dt2: "",
            lt11: "",
            sy5: "ܬܰܢܘܰܝ ",
            sy9: "",
            lt10: "tanway",
            sy4: "ܬܢܘܝ ",
            dt3: "",
            sy6: "",
            lt12: ""
        },
        {
            sy8: "ܢܥܙܘܪܐ",
            lt10: "maĥitho",
            sy9: "ܢܰܥܙܽܘܪܳܐ",
            lt12: "naĉzuro",
            dt3: "achtsam",
            sy7: "ܢܰܥܽܘܙܳܐ",
            dt1: "wach",
            sy6: "ܢܥܘܙܐ",
            sy4: "ܡܚܝܬܐ",
            lt11: "naĉuzo",
            id: "4916",
            dt2: "wachsam",
            sy5: "ܡܰܚܺܝܬ݂ܳܐ"
        },
        {
            lt10: "tyobutho",
            sy6: "",
            sy7: "",
            sy9: "",
            sy5: "ܬܝܳܒܽܘܬ݂ܳܐ",
            lt12: "",
            lt11: "",
            dt1: "Reue",
            sy8: "",
            id: "3706",
            dt2: "Sühne",
            dt3: "",
            sy4: "ܬܝܒܘܬܐ"
        },
        {
            sy8: "",
            lt12: "",
            lt11: "",
            sy9: "",
            sy6: "",
            dt3: "Festungsmauer",
            sy4: "ܐܣܬܐ",
            dt2: "Stadtmauer",
            lt10: "esto",
            id: "4975",
            sy5: "ܐܶܣܬܳܐ",
            sy7: "",
            dt1: "Wand"
        },
        {
            sy6: "ܝܬܒ",
            dt3: "",
            lt11: "yatheb",
            dt2: "",
            sy5: "ܥܰܡܰܪ",
            id: "5992",
            sy4: "ܥܡܪ",
            lt10: "ĉamar",
            dt1: "ansiedeln",
            sy9: "ܐܰܫܪܺܝ",
            sy7: "ܝܰܬ݂ܶܒ",
            lt12: "ashri",
            sy8: "ܐܫܪܝ"
        },
        {
            sy8: "",
            dt1: "leider",
            sy9: "",
            lt12: "",
            lt11: "",
            dt2: "schade",
            id: "2756",
            sy7: "",
            sy4: "ܥܡ ܚܒܠ",
            dt3: "",
            sy6: "",
            sy5: "ܥܰܡ ܚܒܳܠ",
            lt10: "ĉam ĥbol"
        },
        {
            id: "4437",
            lt11: "mauĉodo",
            sy4: "ܘܥܕܐ",
            sy8: "ܡܘܥܕܢܘܬܐ",
            dt3: "Abmachung",
            dt1: "Termin",
            lt12: "mauĉdonutho",
            sy6: "ܡܘܥܕܐ",
            lt10: "waĉdo",
            sy7: "ܡܰܘܥܳܕܳܐ",
            sy9: "ܡܰܘܥܕܳܢܽܘܬ݂ܳܐ",
            sy5: "ܘܰܥܕܳܐ",
            dt2: "Verabredung"
        },
        {
            dt1: "Überstunden",
            dt3: "",
            dt2: "",
            sy4: "ܫܥܐ ܝܬܝܪܐ ܕܦܘܠܚܢܐ",
            sy8: "",
            lt10: "shoĉe yatire d`fulĥono",
            lt12: "",
            sy7: "",
            sy6: "",
            lt11: "",
            sy5: "ܫܳܥܶܐ ܝܰܬܺܝܪܶܐ ܕܦܽܘܠܚܳܢܳܐ",
            id: "4621",
            sy9: ""
        },
        {
            lt12: "",
            sy7: "",
            sy8: "",
            lt11: "",
            dt3: "",
            id: "2560",
            dt1: "Kombination",
            sy9: "",
            sy5: "ܡܚܰܝܕܳܢܽܘܬ݂ܳܐ",
            lt10: "mĥaydonutho",
            sy6: "",
            dt2: "",
            sy4: "ܡܚܝܕܢܘܬܐ"
        },
        {
            sy5: "ܒܰܨܳܐ ܕܡܰܕܪܰܫܬܳܐ",
            lt11: "",
            lt10: "baŝo d`madrashto",
            sy6: "",
            dt3: "",
            sy7: "",
            dt2: "",
            lt12: "",
            sy8: "",
            id: "4044",
            sy9: "",
            dt1: "Schulbus",
            sy4: "ܒܨܐ ܕܡܕܪܫܬܐ"
        },
        {
            sy9: "",
            sy5: "ܝܶܫܽܘܥ",
            sy4: "ܝܫܘܥ",
            sy7: "",
            lt10: "ܝܫܘܥ",
            id: "2244",
            sy6: "",
            sy8: "",
            dt3: "",
            dt1: "Ischo",
            lt12: "",
            lt11: "ܝܫܘܥ",
            dt2: ""
        },
        {
            sy9: "",
            lt12: "",
            id: "4671",
            dt2: "",
            dt3: "",
            lt10: "tarsi",
            sy8: "",
            sy5: "ܬܰܪܣܺܝ",
            sy4: "ܬܪܣܝ",
            sy6: "",
            sy7: "",
            dt1: "unterstützen",
            lt11: ""
        },
        {
            id: "977",
            dt3: "",
            lt11: "ܕܘܡܐ",
            sy9: "",
            dt1: "Duma",
            sy6: "",
            sy5: "ܕܽܘܡܰܐ",
            sy4: "ܕܘܡܐ",
            sy8: "",
            sy7: "",
            lt12: "",
            dt2: "",
            lt10: "ܕܘܡܐ"
        },
        {
            lt12: "",
            sy5: "ܥܰܙܺܝܪܳܐ",
            id: "407",
            sy8: "",
            lt10: "ܥܙܝܪܐ",
            sy4: "ܥܙܝܪܐ",
            lt11: "ܥܙܝܪܐ",
            dt2: "",
            sy6: "",
            sy7: "",
            dt3: "",
            dt1: "Aziro",
            sy9: ""
        },
        {
            sy6: "",
            dt1: "Einsalbung",
            id: "1047",
            lt12: "",
            dt3: "",
            lt11: "",
            dt2: "",
            sy7: "",
            sy4: "ܡܫܝܚܘܬܐ",
            sy8: "",
            lt10: "mshiĥutho",
            sy5: "ܡܫܝܚܘܬܳܐ",
            sy9: ""
        },
        {
            sy4: "ܥܗܢܘܬ ܙܒܢܐ",
            sy9: "",
            lt12: "",
            dt1: "adäquate Zeit",
            sy8: "",
            id: "5745",
            sy5: "ܥܳܗܢܽܘܬ݂ ܙܰܒܢܳܐ",
            dt3: "",
            lt10: "ĉohnuth zabno",
            sy7: "",
            dt2: "",
            sy6: "",
            lt11: ""
        },
        {
            lt11: "",
            lt12: "",
            id: "1930",
            sy8: "",
            dt2: "",
            sy6: "",
            lt10: "qadishutho",
            dt3: "",
            sy7: "",
            sy4: "ܩܕܝܫܘܬܐ",
            sy9: "",
            sy5: "ܩܰܕܺܝܫܽܘܬ݂ܳܐ",
            dt1: "Heiligkeit"
        },
        {
            sy5: "ܝܰܠܕܳܐ ܐ̱ܚܪܳܝܳܐ",
            sy6: "ܫܚܪܩܢܐ",
            dt1: "Letztgeborener",
            sy4: "ܝܠܕܐ ܐܚܪܝܐ",
            id: "2789",
            dt2: "",
            lt11: "shĥarqeno",
            sy9: "",
            dt3: "",
            lt10: "yaldo ĥroyo",
            lt12: "",
            sy8: "",
            sy7: "ܫܚܰܪܩܶܢܳܐ"
        },
        {
            id: "5193",
            lt12: "ܝܐܠܓܝܢ",
            lt11: "ܝܐܠܓܢ",
            dt2: "",
            dt1: "Yalgin",
            dt3: "",
            sy6: "ܝܐܠܓܝܢ",
            sy7: "ܝܰܐܠܓܺܝܢ",
            sy9: "",
            sy8: "",
            sy5: "ܝܰܐܠܓܺܢ",
            lt10: "ܝܐܠܓܢ",
            sy4: "ܝܐܠܓܢ"
        },
        {
            sy5: "ܡܺܝܛܰܟܣܳܐ",
            dt3: "",
            lt11: "",
            dt2: "",
            sy8: "",
            dt1: "Seitengewand",
            sy4: "ܡܝܛܟܣܐ",
            sy9: "",
            sy7: "",
            sy6: "",
            lt12: "",
            id: "6221",
            lt10: "mițakso"
        },
        {
            dt2: "",
            sy7: "ܡܢܰܓ݂ܕܳܢܳܐ",
            dt1: "Peiniger",
            id: "6161",
            lt11: "mnağđono",
            sy5: "ܡܫܰܢܩܳܢܳܐ",
            dt3: "",
            lt12: "meshtandono",
            lt10: "mshanqono",
            sy9: "ܡܶܫܬܰܢܕܳܢܳܐ",
            sy8: "ܡܫܬܢܕܢܐ",
            sy4: "ܡܫܢܩܢܐ",
            sy6: "ܡܢܓܕܢܐ"
        },
        {
            dt1: "andere Seite",
            sy8: "",
            lt12: "",
            sy7: "",
            sy9: "",
            sy5: "ܓܰܒܳܐ ܐ̱ܚܪܺܝܢܳܐ",
            lt10: "gabo ĥrino",
            id: "5936",
            sy4: "ܓܒܐ ܐܚܪܝܢܐ",
            sy6: "",
            dt2: "",
            dt3: "",
            lt11: ""
        },
        {
            lt12: "",
            sy6: "ܐܣܝܪܘܬ ܪܚܡܬܐ",
            id: "2813",
            lt10: "asirutho d`ĥubo",
            lt11: "asiruth reĥmtho",
            dt2: "",
            sy7: "ܐܰܣܺܝܪܽܘܬ݂ ܪܶܚܡܬ݂ܳܐ",
            sy8: "",
            dt1: "Liebesbeziehung",
            sy4: "ܐܣܝܪܘܬ ܕܚܘܒܐ",
            dt3: "",
            sy9: "",
            sy5: "ܐܰܣܺܝܪܽܘܬ݂ ܕܚܽܘܒܳܐ"
        },
        {
            id: "5376",
            sy7: "ܐܰܟ݂ܚܕܳܐ",
            sy8: "ܐܟܚܕܕܐ",
            dt1: "zusammen",
            sy5: "ܥܰܡ ܚܕܳܕܶܐ",
            lt12: "akhĥđođe",
            dt2: "gemeinsam",
            lt10: "ĉam ĥđođe",
            lt11: "akhĥđo",
            dt3: "miteinander",
            sy6: "ܐܟܚܕܐ",
            sy9: "ܐܰܟ݂ܚܕܳܕܶܐ",
            sy4: "ܥܡ ܚܕܕܐ"
        },
        {
            sy8: "",
            dt1: "Yayuta",
            lt12: "",
            sy6: "",
            sy7: "",
            dt3: "",
            sy4: "ܝܐܝܘܬܐ",
            sy9: "",
            lt10: "ܝܐܝܘܬܐ",
            id: "5206",
            dt2: "",
            sy5: "ܝܰܐܝܽܘܬܰܐ",
            lt11: "ܝܐܝܘܬܐ"
        },
        {
            dt1: "Sohdo",
            sy9: "",
            id: "4181",
            lt10: "ܣܗܕܐ",
            sy4: "ܣܗܕܐ",
            dt2: "",
            sy6: "",
            dt3: "",
            sy8: "",
            lt12: "",
            lt11: "ܣܗܕܐ",
            sy5: "ܣܳܗܕܳܐ",
            sy7: ""
        },
        {
            dt3: "Gebrechen",
            sy9: "",
            lt11: "",
            lt10: "mnaflutho",
            sy5: "ܡܢܰܦܠܽܘܬ݂ܳܐ",
            dt2: "Schwachheit",
            sy7: "",
            id: "6323",
            sy6: "",
            sy8: "",
            dt1: "Kraftlosigkeit",
            lt12: "",
            sy4: "ܡܢܦܠܘܬܐ"
        },
        {
            lt11: "ܙܝܬܐ",
            sy4: "ܙܝܬܐ",
            id: "5264",
            sy6: "",
            lt12: "",
            sy7: "",
            sy9: "",
            dt3: "",
            dt1: "Zayto",
            sy8: "",
            lt10: "ܙܝܬܐ",
            dt2: "",
            sy5: "ܙܰܝܬܳܐ"
        },
        {
            dt3: "",
            sy8: "",
            sy4: "ܚܪܝܒܘܬܐ",
            lt12: "",
            lt11: "",
            lt10: "ĥaributho",
            dt2: "",
            dt1: "Defekt",
            sy7: "",
            sy9: "",
            id: "842",
            sy5: "ܚܰܪܺܝܒܽܘܬ݂ܳܐ",
            sy6: ""
        },
        {
            dt3: "",
            lt10: "ܝܪܒ",
            sy8: "",
            id: "5210",
            sy4: "ܝܪܒ",
            sy5: "ܝܺܪܰܒ",
            sy7: "",
            dt2: "Jirab",
            lt12: "",
            dt1: "Yirab",
            sy9: "",
            lt11: "ܝܪܒ",
            sy6: ""
        },
        {
            sy6: "",
            sy9: "",
            sy4: "ܣܪܣܛ",
            lt10: "sarseț",
            id: "6398",
            sy7: "",
            sy5: "ܣܰܪܣܶܛ",
            sy8: "",
            lt12: "",
            dt3: "",
            dt2: "",
            lt11: "",
            dt1: "kritzeln"
        },
        {
            dt2: "",
            sy6: "",
            sy5: "ܒܰܝܰܐ",
            sy9: "",
            dt3: "",
            dt1: "Baya",
            id: "515",
            lt11: "ܒܝܐ",
            sy8: "",
            sy7: "",
            lt12: "",
            sy4: "ܒܝܐ",
            lt10: "ܒܝܐ"
        },
        {
            sy6: "ܚܙܘܩܝܐ ܬܒܝܠܝܐ",
            id: "5058",
            lt11: "ĥzuqyo tibeloyo",
            dt1: "Weltreise",
            sy7: "ܚܙܽܘܩܝܳܐ ܬܺܒܶܝܠܳܝܳܐ",
            sy9: "",
            sy5: "ܚܙܽܘܩܝܳܐ ܕܒܪܺܝܬ݂ܳܐ",
            sy4: "ܚܙܘܩܝܐ ܕܒܪܝܬܐ",
            lt12: "",
            dt3: "",
            sy8: "",
            dt2: "",
            lt10: "ĥzuqyo d`britho"
        },
        {
            dt2: "",
            id: "4361",
            sy9: "",
            lt10: "yum b`yum",
            sy5: "ܝܽܘܡ ܒܝܽܘܡ",
            lt11: "",
            sy7: "",
            sy4: "ܝܘܡ ܒܝܘܡ",
            dt3: "",
            dt1: "Tag für Tag",
            lt12: "",
            sy6: "",
            sy8: ""
        },
        {
            lt10: "l'iqoro d'aloho u dil ",
            lt12: "",
            sy7: "",
            dt2: "",
            dt1: "Zur Ehre Gottes und mir selbst",
            dt3: "",
            sy6: "",
            sy8: "",
            lt11: "",
            sy5: "ܠܺܐܝܩܳܪܳܐ ܕܰܐܠܳܗܳܐ ܘܕܺܝܠܝ",
            id: "5374",
            sy9: "",
            sy4: "ܠܐܝܩܪܐ ܕܐܠܗܐ ܘܕܝܠܝ"
        },
        {
            sy6: "",
            dt1: "Urnahra",
            lt12: "",
            sy7: "",
            lt10: "ܐܘܪܢܗܪܐ",
            sy8: "",
            dt2: "",
            dt3: "",
            sy9: "",
            sy5: "ܐܽܘܪܢܰܗܪܰܐ",
            sy4: "ܐܘܪܢܗܪܐ",
            id: "4697",
            lt11: "ܐܘܪܢܗܪܐ"
        },
        {
            lt10: "shakar țĥino",
            sy9: "",
            sy4: "ܫܟܪ ܛܚܝܢܐ",
            lt11: "",
            sy8: "",
            dt3: "",
            lt12: "",
            sy6: "",
            id: "3544",
            sy5: "ܫܰܟܰܪ ܛܚܺܝܢܳܐ",
            dt1: "Puderzucker",
            sy7: "",
            dt2: ""
        },
        {
            sy8: "ܡܥܕܠܢܐ",
            sy4: "ܪܫܘܝܐ",
            sy7: "ܡܬܰܟܣܳܢܳܐ",
            dt2: "",
            dt3: "",
            dt1: "Zensor",
            sy9: "ܡܥܰܕܠܳܢܳܐ",
            lt12: "mĉadlono",
            id: "6275",
            sy6: "ܡܬܟܣܢܐ",
            lt11: "mtaksono",
            lt10: "roshuyo",
            sy5: "ܪܳܫܽܘܝܳܐ"
        },
        {
            lt12: "",
            dt1: "tierisch",
            lt11: "ĥayuthonoyo",
            sy5: "ܚܰܝܽܘܬ݂ܳܢܳܐ",
            sy9: "",
            dt3: "",
            lt10: "ĥayuthono",
            sy6: "ܚܝܘܬܢܝܐ",
            sy4: "ܚܝܘܬܢܐ",
            dt2: "",
            sy7: "ܚܰܝܽܘܬ݂ܳܢܳܝܳܐ",
            sy8: "",
            id: "4483"
        },
        {
            id: "5569",
            lt10: "ĉațmo dțuro",
            sy6: "ܪܟܢܐ",
            dt3: "",
            dt2: "",
            sy7: "ܪܟܳܢܳܐ",
            dt1: "Abhang",
            lt12: "",
            lt11: "rkono",
            sy5: "ܥܰܛܡܳܐ ܕܛܽܘܪܳܐ",
            sy4: "ܥܛܡܐ ܕܛܘܪܐ",
            sy9: "",
            sy8: ""
        },
        {
            dt1: "Wackeln",
            sy9: "",
            lt11: "",
            sy7: "",
            sy5: "ܕܽܘܠܕܳܠܳܐ",
            sy6: "",
            lt12: "",
            dt3: "",
            sy4: "ܕܘܠܕܠܐ",
            sy8: "",
            dt2: "",
            lt10: "duldolo",
            id: "4923"
        },
        {
            sy4: "ܐܒܠܐ",
            sy8: "",
            sy6: "",
            lt11: "ܐܒܠܐ",
            id: "990",
            dt1: "Eblo",
            sy7: "",
            dt2: "",
            dt3: "",
            sy9: "",
            sy5: "ܐܶܒܠܳܐ",
            lt10: "ܐܒܠܐ",
            lt12: ""
        },
        {
            dt2: "",
            dt3: "",
            id: "2081",
            sy9: "",
            sy5: "ܫܥܳܠܳܐ",
            dt1: "Husten",
            sy8: "",
            sy6: "ܫܥܘܠܐ",
            lt10: "shĉolo",
            lt11: "shĉulo",
            sy4: "ܫܥܠܐ",
            lt12: "",
            sy7: "ܫܥܽܘܠܳܐ"
        },
        {
            lt10: "mnoth yuthrono",
            sy5: "ܡܢܳܬ݂ ܝܽܘܬ݂ܪܳܢܳܐ",
            sy7: "",
            id: "1624",
            dt1: "Gewinnanteil",
            sy4: "ܡܢܬ ܝܘܬܪܢܐ",
            dt2: "",
            sy9: "",
            sy6: "",
            sy8: "",
            dt3: "",
            lt12: "",
            lt11: ""
        },
        {
            sy4: "ܚܠܬܐ",
            lt10: "ĥolto",
            sy5: "ܚܳܠܬܳܐ",
            dt3: "",
            lt11: "ĉamtho",
            id: "4379",
            sy9: "ܕܳܕܬ݂ܳܐ",
            sy6: "ܥܡܬܐ",
            sy8: "ܕܕܬܐ",
            lt12: "dodtho",
            dt2: "",
            dt1: "Tante",
            sy7: "ܥܰܡܬ݂ܳܐ"
        },
        {
            lt10: "ܐܚܒ",
            dt3: "",
            sy6: "ܐܚܒ",
            sy8: "",
            lt12: "ܐܚܒ",
            lt11: "ܐܚܒ",
            sy9: "",
            sy4: "ܐܚܒ",
            sy5: "ܐܰܚܰܒ",
            sy7: "ܐܳܚܳܒ",
            dt1: "Ahab",
            dt2: "",
            id: "150"
        },
        {
            sy6: "",
            sy5: "ܠܰܝܬ ܟܳܠܺܝܬ݂ܳܐ",
            id: "1183",
            sy8: "",
            lt11: "",
            lt10: "layt kolitho",
            sy9: "",
            dt2: "",
            sy4: "ܠܝܬ ܟܠܝܬܐ",
            dt1: "es gibt kein Hindernis",
            lt12: "",
            sy7: "",
            dt3: ""
        },
        {
            dt3: "",
            dt2: "tatsächlich",
            id: "5128",
            sy4: "ܒܫܪܠܐ",
            lt10: "b`shrolo",
            dt1: "wirklich",
            sy6: "ܫܪܝܪܐܝܬ",
            sy8: "ܩܘܫܬܢܝܐ",
            lt12: "qushtonoyo",
            sy5: "ܒܫܪܳܠܳܐ",
            sy9: "ܩܽܘܫܬܳܢܳܝܳܐ",
            lt11: "shariroith",
            sy7: "ܫܰܪܺܝܪܳܐܝܺܬ݂"
        },
        {
            sy5: "ܕܽܘܕܳܐ",
            dt2: "Kessel",
            dt3: "Teekännchen",
            sy6: "",
            sy9: "",
            lt10: "dudo",
            dt1: "Glasgefäß",
            sy8: "",
            sy7: "",
            id: "1647",
            lt11: "",
            lt12: "",
            sy4: "ܕܘܕܐ"
        },
        {
            sy5: "ܡܰܫܽܘܚܬܳܢܳܝܳܐ",
            sy7: "",
            sy4: "ܡܫܘܚܬܢܝܐ",
            sy9: "",
            dt2: "Ökonomon",
            sy8: "",
            lt10: "mashuĥtonoyo",
            dt3: "",
            id: "5132",
            lt11: "",
            sy6: "",
            dt1: "Wirtschaftler",
            lt12: ""
        },
        {
            sy6: "ܩܛܘܪܐ",
            dt2: "",
            sy4: "ܨܪܘܪܐ",
            sy7: "ܩܳܛܽܘܪܳܐ",
            sy8: "",
            id: "4781",
            dt1: "Verpacker",
            dt3: "",
            lt11: "qoțuro",
            sy5: "ܨܳܪܽܘܪܳܐ",
            lt12: "",
            sy9: "",
            lt10: "ŝoruro"
        },
        {
            dt2: "",
            id: "2660",
            lt10: "maqrono",
            sy9: "",
            sy5: "ܡܰܩܪܳܢܳܐ",
            dt1: "Kühlschrank",
            sy7: "",
            sy4: "ܡܩܪܢܐ",
            sy6: "",
            sy8: "",
            lt12: "",
            lt11: "",
            dt3: ""
        },
        {
            lt10: "ĥelme basime",
            dt2: "",
            lt11: "",
            sy7: "",
            sy4: "ܚܠܡܐ ܒܣܝܡܐ",
            dt3: "",
            sy8: "",
            sy6: "",
            lt12: "",
            sy5: "ܚܶܠܡ̈ܶܐ ܒܰܣܺܝܡܶܐ",
            dt1: "Schöne Träume",
            id: "4013",
            sy9: ""
        },
        {
            dt1: "böse Absicht",
            lt10: "renyo bisho",
            lt11: "",
            sy6: "",
            sy8: "",
            lt12: "",
            sy5: "ܪܶܢܝܳܐ ܒܺܝܫܳܐ",
            id: "681",
            sy7: "",
            sy4: "ܪܢܝܐ ܒܝܫܐ",
            dt3: "böser Gedanke",
            dt2: "schlechte Absicht",
            sy9: ""
        },
        {
            id: "1041",
            sy7: "ܫܩܰܠ",
            sy5: "ܙܒܰܢ",
            lt12: "",
            dt2: "kaufen",
            sy4: "ܙܒܢ",
            lt11: "shqal",
            dt3: "",
            dt1: "einkaufen",
            lt10: "sban",
            sy9: "",
            sy6: "ܫܩܠ",
            sy8: ""
        },
        {
            sy5: "ܥܒܳܪܳܐ",
            lt10: "ĉboro",
            sy8: "",
            dt2: "",
            id: "4750",
            sy7: "",
            lt12: "",
            sy4: "ܥܒܪܐ",
            lt11: "",
            dt1: "Verkehr ",
            sy9: "",
            sy6: "",
            dt3: ""
        },
        {
            lt10: "yuthrono",
            lt12: "",
            dt1: "Gewinn",
            dt3: "",
            sy8: "",
            dt2: "Vorteil",
            sy9: "",
            sy7: "",
            sy6: "",
            sy5: "ܝܽܘܬ݂ܪܳܢܳܐ",
            id: "1622",
            sy4: "ܝܘܬܪܢܐ",
            lt11: ""
        },
        {
            lt10: "ܓܒܝܬܐ",
            dt3: "",
            sy6: "",
            sy9: "",
            dt2: "",
            sy5: "ܓܒܺܝܬܰܐ",
            sy7: "",
            sy4: "ܓܒܝܬܐ",
            lt11: "ܓܒܝܬܐ",
            sy8: "",
            id: "1493",
            lt12: "",
            dt1: "Gbita"
        },
        {
            sy7: "ܦܺܝܢܓ ܦܽܘܢܓ",
            dt1: "Tischtennis",
            sy8: "",
            dt2: "",
            sy6: "ܦܝܢܓ ܦܘܢܓ",
            sy5: "ܐܶܣܦܺܝܪ ܛܶܒܠܺܝܬ݂ܳܐ",
            lt12: "",
            dt3: "",
            sy4: "ܐܣܦܝܪ ܛܒܠܝܬܐ",
            lt10: "esfir țeblitho",
            lt11: "ping pung",
            sy9: "",
            id: "4499"
        },
        {
            dt2: "Dreihundert",
            sy5: "ܬܠܳܬ݂ܡܳܐܐ",
            id: "40",
            sy9: "",
            sy8: "",
            sy4: "ܬܠܬܡܐܐ",
            sy7: "ܫ ",
            lt11: "shin",
            dt3: "",
            sy6: "ܫ ",
            lt12: "",
            lt10: "tlothmo",
            dt1: "300"
        },
        {
            sy7: "",
            id: "481",
            sy5: "ܕܰܩܢܳܐ",
            sy9: "",
            dt3: "",
            sy4: "ܕܩܢܐ",
            sy8: "",
            lt11: "",
            dt2: "",
            lt10: "daqno",
            dt1: "Bart",
            lt12: "",
            sy6: ""
        },
        {
            dt3: "",
            id: "3038",
            lt10: "ܡܐܡܪܐ",
            lt11: "ܡܐܡܪܐ",
            sy6: "",
            lt12: "",
            dt1: "Mimro",
            sy7: "",
            sy8: "",
            sy4: "ܡܐܡܪܐ",
            dt2: "",
            sy9: "",
            sy5: "ܡܺܐܡܪܳܐ"
        },
        {
            lt12: "",
            dt2: "",
            sy5: "ܙܰܒܢܳܐ ܕܩܳܐܶܡ",
            sy9: "",
            lt10: "zabno d`qoyem",
            sy8: "",
            id: "1525",
            sy7: "",
            sy4: "ܙܒܢܐ ܕܩܐܡ",
            sy6: "",
            dt1: "Gegenwart",
            lt11: "",
            dt3: ""
        },
        {
            lt10: "țime",
            sy6: "ܛܝܡܐ",
            dt1: "Kosten",
            sy8: "",
            id: "2622",
            lt11: "țimo",
            lt12: "",
            sy7: "ܛܽܝܡܳܐ",
            sy9: "",
            dt3: "",
            sy5: "ܛܺܝ̈ܡܶܐ",
            sy4: "ܛܝܡܐ",
            dt2: "Preis"
        },
        {
            lt10: "rushmo d'arĉo",
            sy7: "",
            dt1: "Stadtplan",
            sy9: "",
            lt11: "",
            lt12: "",
            dt2: "",
            dt3: "",
            sy6: "",
            sy8: "",
            id: "6227",
            sy5: "ܪܽܘܫܡܳܐ ܕܐܰܪܥܳܐ",
            sy4: "ܪܘܫܡܐ ܕܐܪܥܐ"
        },
        {
            sy6: "",
            dt2: "",
            lt12: "",
            sy7: "",
            sy5: "ܣܽܘܪܝܳܝܳܐ ܣܰܬ",
            lt11: "",
            sy8: "",
            dt1: "Suryoyo Sat",
            id: "4325",
            dt3: "",
            lt10: "suryoyo sat",
            sy4: "ܣܘܪܝܝܐ ܣܬ",
            sy9: ""
        },
        {
            sy9: "ܢܚܳܛܳܐ",
            id: "3989",
            sy5: "ܢܽܘܚܳܪܳܐ",
            lt10: "nuĥoro",
            lt12: "nĥoțo",
            dt2: "",
            sy4: "ܢܘܚܪܐ",
            lt11: "nĥoro",
            sy6: "ܢܚܪܐ",
            dt3: "",
            sy8: "ܢܚܛܐ",
            sy7: "ܢܚܳܪܳܐ",
            dt1: "Schnarchen"
        },
        {
            dt1: "Waschmittel",
            lt10: "darmono da`shyoğo",
            dt2: "",
            lt12: "",
            sy6: "",
            sy9: "",
            sy8: "",
            dt3: "",
            id: "4999",
            lt11: "",
            sy5: "ܕܰܪܡܳܢܳܐ ܕܰܫܝܳܓ݂ܳܐ",
            sy7: "",
            sy4: "ܕܪܡܢܐ ܕܫܝܓܐ"
        },
        {
            id: "6172",
            lt10: "mŝaĉtho",
            dt1: "Intervall",
            sy9: "",
            lt11: "rauĥo",
            sy5: "ܡܨܰܥܬ݂ܳܐ",
            sy4: "ܡܨܥܬܐ",
            sy6: "ܪܘܚܐ",
            dt3: "",
            sy7: "ܪܰܘܚܳܐ",
            lt12: "",
            dt2: "",
            sy8: ""
        },
        {
            sy5: "ܓܰܐܒܺܝ",
            sy4: "ܓܐܒܝ",
            lt10: "ܓܐܒܝ",
            dt2: "",
            sy9: "",
            sy6: "",
            dt1: "Gabi",
            dt3: "",
            id: "1451",
            lt11: "ܓܐܒܝ",
            sy8: "",
            lt12: "",
            sy7: ""
        },
        {
            dt2: "",
            lt11: "qaumo",
            sy4: "ܢܘܩܙܐ ܡܨܥܝܐ",
            sy7: "ܩܰܘܡܳܐ",
            id: "6362",
            sy6: "ܩܘܡܐ",
            sy9: "ܩܶܢܛܪܽܘܢ",
            lt10: "nuqzo meŝĉoyo",
            sy8: "ܩܢܛܪܘܢ",
            lt12: "qențrun",
            dt3: "",
            sy5: "ܢܽܘܩܙܳܐ ܡܶܨܥܳܝܳܐ",
            dt1: "Mittelpunkt"
        },
        {
            dt3: "",
            sy4: "ܫܕܪ ܒܐܠܦܐ",
            sy9: "",
            id: "5713",
            sy8: "",
            sy6: "",
            lt12: "",
            lt11: "",
            dt2: "",
            sy5: "ܫܰܕܰܪ ܒܶܐܠܦܳܐ",
            lt10: "shadar belfo",
            dt1: "abwimmeln",
            sy7: ""
        },
        {
            lt12: "",
            sy7: "",
            dt2: "",
            dt1: "Hebe einen Stein auf oder spalte ein Holz und du wirst mich finden",
            lt11: "",
            dt3: "",
            sy8: "",
            id: "5615",
            sy4: "ܐܪܝܡ ܚܕܐ ܟܐܦܐ ܐܘ ܬܒܪ ܚܕ ܩܝܣܐ ܐܘܬܡܢ ܚܙܐ ܐܬ ܠܝ",
            lt10: "arim ĥdo kifo au tbar ĥad qayso utamon ĥose at li",
            sy9: "",
            sy6: "",
            sy5: "ܐܰܪܺܝܡ ܚܕܳܐ ܟܺܐܦܐ ܐܰܘ ܬܒܰܪ ܚܰܕ ܩܰܝܣܳܐ ܐܽܘܬܰܡܳܢ ܚܳܙܶܐ ܐܰܬ ܠܺܝ"
        },
        {
            sy7: "",
            sy6: "",
            sy8: "",
            dt3: "",
            dt1: "Viehtransport",
            lt10: "nqolo d`qenyone",
            dt2: "",
            sy9: "",
            lt12: "",
            sy4: "ܢܩܠܐ ܕܩܢܝܢܐ",
            id: "4830",
            sy5: "ܢܩܳܠܳܐ ܕܩܳܢܝܳܢܶܐ",
            lt11: ""
        },
        {
            dt2: "Ozean Pazifik",
            sy7: "",
            lt11: "",
            sy8: "",
            lt12: "",
            dt3: "Pazifik",
            id: "3426",
            lt10: "yamo rabo shalyo",
            dt1: "Pazifischer Ozean",
            sy9: "",
            sy5: "ܝܰܡܳܐ ܪܰܒܳܐ ܫܰܠܝܳܐ",
            sy6: "",
            sy4: "ܝܡܐ ܪܒܐ ܫܠܝܐ"
        },
        {
            lt10: "syomoyo",
            dt3: "",
            lt11: "shiloith",
            id: "5920",
            dt2: "anscheinend",
            sy9: "ܫܽܘܡܳܗܳܐܺܝܬ݂",
            sy5: "ܣܝܳܡܳܝܳܐ",
            sy7: "ܫܺܝܠܳܐܺܝܬ݂",
            sy8: "ܫܘܡܗܐܝܬ",
            sy4: "ܣܝܡܝܐ",
            sy6: "ܫܝܠܐܝܬ",
            dt1: "angeblich",
            lt12: "shumohoith"
        },
        {
            dt3: "",
            lt12: "ܒܪܘܬܝ",
            id: "556",
            sy5: "ܒܶܪܳܘܬܰܐ",
            dt2: "",
            lt11: "ܒܪܘܬܐ",
            sy8: "",
            dt1: "Berota",
            sy7: "ܒܪܽܘܬܺܝ",
            sy6: "ܒܪܘܬܝ",
            sy9: "",
            sy4: "ܒܪܘܬܐ",
            lt10: "ܒܪܘܬܐ"
        },
        {
            dt3: "",
            sy6: "",
            sy7: "",
            sy8: "",
            sy4: "ܨܝܡܐ",
            lt11: "",
            lt12: "",
            id: "1265",
            dt2: "",
            sy5: "ܨܰܝܳܡܳܐ",
            dt1: "Fastender",
            sy9: "",
            lt10: "ŝayomo"
        },
        {
            lt12: "",
            dt3: "",
            sy7: "",
            dt1: "Blutspender",
            sy8: "",
            sy4: "ܝܗܘܒܐ ܕܕܡܐ",
            sy6: "",
            lt10: "yohubo da`dmo",
            sy5: "ܝܳܗܽܘܒܳܐ ܕܰܕܡܳܐ",
            dt2: "",
            sy9: "",
            id: "669",
            lt11: ""
        },
        {
            dt1: "Haare",
            sy5: "ܣܰܥܪܳܐ",
            sy8: "",
            sy4: "ܣܥܪܐ",
            lt12: "",
            dt3: "",
            id: "1782",
            lt11: "",
            lt10: "saĉro",
            sy6: "",
            dt2: "",
            sy9: "",
            sy7: ""
        },
        {
            sy4: "ܝܡܬ݂ܐ",
            dt2: "",
            lt11: "ܝܡܬܐ",
            lt12: "",
            dt1: "Yamtha",
            sy5: "ܝܰܡܬ݂ܰܐ",
            sy8: "",
            dt3: "",
            sy7: "",
            lt10: "ܝܡܬܐ",
            sy9: "",
            id: "5199",
            sy6: ""
        },
        {
            sy8: "",
            sy9: "",
            id: "3480",
            dt2: "",
            sy4: "ܡܪܩܐ",
            lt12: "",
            lt10: "mroqo",
            sy7: "",
            sy6: "",
            sy5: "ܡܪܳܩܳܐ",
            lt11: "",
            dt3: "",
            dt1: "Polieren"
        },
        {
            sy4: "ܩܪܝܬܐ ܕܥܝܢܘܪܕܐ",
            dt1: "Dorf Ayn Wardo",
            dt3: "",
            lt10: "qritho ĉayn wardo",
            id: "924",
            dt2: "Ayn Wardo",
            lt11: "ĉayn wardo",
            sy5: "ܩܪܺܝܬ݂ܳܐ ܕܥܰܝܢܘܰܪܕܳܐ",
            lt12: "",
            sy6: "ܥܝܢܘܪܕܐ",
            sy9: "",
            sy7: "ܥܰܝܢܘܰܪܕܳܐ",
            sy8: ""
        },
        {
            sy9: "",
            dt2: "",
            sy8: "",
            lt10: "mĉaukho",
            dt3: "",
            lt11: "methĉaukhono",
            sy6: "ܡܬܥܘܟܢܐ",
            id: "4206",
            lt12: "",
            sy4: "ܡܥܘܟܐ",
            sy7: "ܡܶܬ݂ܥܰܘܟ݂ܳܢܳܐ",
            sy5: "ܡܥܰܘܟ݂ܳܐ",
            dt1: "spät"
        },
        {
            lt12: "",
            lt11: "shatayto",
            sy9: "",
            sy4: "ܫܟܪܢܝܬܐ",
            lt10: "shakhronitho",
            id: "5828",
            sy6: "ܫܬܝܬܐ",
            dt1: "Alkoholikerin",
            sy7: "ܫܰܬܰܝܬܳܐ",
            sy5: "ܫܰܟ݂ܪܳܢܺܝܬ݂ܳܐ",
            sy8: "",
            dt2: "",
            dt3: ""
        },
        {
            dt3: "",
            lt12: "",
            id: "4529",
            sy9: "",
            sy6: "",
            dt2: "",
            sy4: "ܥܡܘܕ ܕܢܘܦܐ",
            sy7: "",
            lt11: "",
            sy8: "",
            lt10: "ĉamud d`naufo",
            dt1: "Torpfosten",
            sy5: "ܥܰܡܽܘܕ ܕܢܰܘܦܳܐ"
        },
        {
            sy8: "",
            dt3: "",
            dt1: "Abfallen",
            dt2: "Ausfallen ",
            sy4: "ܢܬܪܐ",
            lt12: "",
            lt10: "nthoro",
            sy6: "",
            sy5: "ܢܬ݂ܳܪܳܐ",
            sy9: "",
            id: "82",
            lt11: "",
            sy7: ""
        },
        {
            dt2: "permanent",
            id: "2590",
            lt10: "amino",
            sy8: "",
            sy9: "",
            lt11: "",
            sy4: "ܐܡܝܢܐ",
            dt3: "",
            sy5: "ܐܰܡܺܝܢܳܐ",
            dt1: "kontinuierlich",
            lt12: "",
            sy6: "",
            sy7: ""
        },
        {
            sy4: "ܢܫܡܐ",
            id: "325",
            dt3: "",
            sy9: "",
            sy7: "ܢܰܦܰܨ",
            sy5: "ܢܶܫܡܳܐ",
            sy6: "ܢܦܨ",
            dt1: "Atem",
            lt12: "",
            lt10: "neshmo",
            sy8: "",
            lt11: "nafaŝ",
            dt2: ""
        },
        {
            sy6: "",
            sy9: "",
            id: "4403",
            dt3: "",
            lt12: "",
            dt1: "Tauffest",
            sy4: "ܥܐܕܐ ܕܥܡܕܐ",
            sy8: "",
            lt11: "",
            dt2: "",
            sy7: "",
            sy5: "ܥܺܐܕܳܐ ܕܥܡܳܕܳܐ",
            lt10: "ĉiđo da`ĉmođo"
        },
        {
            sy4: "ܦܬܪܘܣ",
            sy9: "",
            id: "3444",
            lt11: "",
            dt3: "",
            lt12: "",
            lt10: "fetrus",
            dt1: "̇Petrus",
            sy7: "",
            sy8: "",
            dt2: "",
            sy5: "ܦܶܬܪܽܘܣ",
            sy6: ""
        },
        {
            sy6: "",
            dt2: "",
            sy5: "ܡܰܣܰܐܬ݂ܳܐ ܕܐܶܓܪܳܬ݂ܳܐ",
            id: "6342",
            lt10: "masatho d'egrotho",
            lt12: "",
            sy8: "",
            sy7: "",
            sy4: "ܡܣܐܬܐ ܕܐܓܪܬܐ",
            sy9: "",
            dt3: "",
            lt11: "",
            dt1: "Briefwaage"
        },
        {
            sy6: "",
            dt1: "Zitronenbaum",
            sy4: "ܡܕܝܩܐ",
            dt2: "",
            sy7: "",
            sy9: "",
            lt11: "",
            sy5: "ܡܶܕܺܝܩܳܐ",
            lt10: "mediqo",
            sy8: "",
            id: "5338",
            dt3: "",
            lt12: ""
        },
        {
            lt11: "",
            id: "900",
            sy7: "",
            dt1: "Diözese",
            sy4: "ܡܪܥܝܬܐ",
            lt10: "marĉitho",
            sy8: "",
            sy9: "",
            sy5: "ܡܰܪܥܺܝܬ݂ܳܐ",
            lt12: "",
            dt2: "",
            dt3: "",
            sy6: ""
        },
        {
            dt2: "",
            lt11: "ܫܘܫܢܐ",
            sy9: "",
            dt1: "Schuschane",
            sy4: "ܫܘܫܢܐ",
            id: "4056",
            sy7: "",
            sy8: "",
            dt3: "",
            lt10: "ܫܘܫܢܐ",
            lt12: "",
            sy5: "ܫܽܘܫܰܢܰܐ",
            sy6: ""
        },
        {
            lt11: "qarishutho",
            dt1: "Abkühlung",
            sy9: "ܡܦܺܝܚܳܢܽܘܬ݂ܳܐ",
            sy6: "ܩܪܝܫܘܬܐ",
            dt2: "",
            id: "5579",
            lt10: "quroro",
            dt3: "",
            sy7: "ܩܰܪܺܝܫܽܘܬ݂ܳܐ",
            sy8: "ܡܦܝܚܢܘܬܐ",
            sy5: "ܩܽܘܪܳܪܳܐ",
            lt12: "mfiĥonutho",
            sy4: "ܩܘܪܪܐ"
        },
        {
            sy9: "",
            sy7: "ܦܽܘܗܳܪܳܐ",
            dt1: "Gähnen",
            sy8: "",
            id: "1462",
            dt2: "",
            lt11: "fuhoro",
            lt12: "",
            lt10: "fehoro",
            sy4: "ܦܗܪܐ",
            sy6: "ܦܘܗܪܐ",
            dt3: "",
            sy5: "ܦܶܗܳܪܳܐ"
        },
        {
            id: "3593",
            lt11: "ܪܦܐܝܠ",
            sy4: "ܪܦܐܝܠ",
            sy9: "",
            sy6: "",
            dt1: "Rafael",
            sy7: "",
            sy8: "",
            sy5: "ܪܰܦܰܐܝܶܠ",
            lt12: "",
            dt2: "Raphael",
            lt10: "ܪܦܐܝܠ",
            dt3: ""
        },
        {
            dt2: "",
            sy9: "",
            sy4: "ܡܙܕܩܐ ܕܩܠܐ",
            sy5: "ܡܙܰܕܩܳܐ ܕܩܳܠܳܐ",
            sy6: "ܡܙܕܩܐ ܕܓܘܒܝܐ",
            lt11: "mzadqo d`guboyo",
            id: "4936",
            sy7: "ܡܙܰܕܩܳܐ ܕܓܽܘܒܳܝܳܐ",
            lt12: "",
            dt1: "wahlberechtigt",
            dt3: "",
            lt10: "mzadqo d`qolo",
            sy8: ""
        },
        {
            sy9: "",
            lt10: "ŝayodo ŝoyed aylo",
            sy8: "",
            dt2: "",
            id: "2271",
            lt11: "",
            sy7: "",
            dt3: "",
            lt12: "",
            dt1: "Jäger fängt Hirsch",
            sy5: "ܨܰܝܳܕܳܐ ܨܳܐܶܕ ܐܰܝܠܳܐ",
            sy6: "",
            sy4: "ܨܝܕܐ ܨܐܕ ܐܝܠܐ"
        },
        {
            sy5: "ܡܰܥܬܪܳܢܽܘܬ݂ܳܐ",
            lt11: "",
            lt12: "",
            dt2: "",
            sy6: "",
            id: "5980",
            dt3: "",
            sy4: "ܡܥܬܪܢܘܬܐ",
            lt10: "maĉtronutho",
            dt1: "Anreicherung",
            sy9: "",
            sy7: "",
            sy8: ""
        },
        {
            dt2: "Schmutz",
            dt1: "Dreck",
            sy8: "",
            sy5: "ܛܺܝܢܳܐ",
            sy9: "",
            id: "954",
            sy6: "",
            dt3: "",
            lt10: "țino",
            lt11: "",
            sy7: "",
            lt12: "",
            sy4: "ܛܝܢܐ"
        },
        {
            sy8: "",
            sy7: "",
            dt1: "abc",
            lt11: "",
            sy9: "",
            dt3: "",
            sy4: "ܐ ܒ ܓ",
            id: "64",
            sy6: "",
            lt12: "",
            lt10: "olaf beth gomal",
            dt2: "",
            sy5: "ܐ ܒ ܓ"
        },
        {
            sy9: "",
            dt1: "Ackerwalze",
            dt2: "",
            lt11: "quflo",
            sy6: "ܩܘܦܠܐ",
            id: "5743",
            lt10: "ŝafdo",
            sy4: "ܨܦܕܐ",
            sy5: "ܨܰܦܕܳܐ",
            lt12: "",
            dt3: "",
            sy7: "ܩܽܘܦܠܳܐ",
            sy8: ""
        },
        {
            dt3: "",
            sy5: "ܦܽܘܚܳܡܳܐ ܕܬܰܚܘܺܝܬ݂ܳܐ",
            dt2: "",
            sy7: "",
            lt10: "fuĥomo d`taĥwitho",
            sy6: "",
            sy9: "",
            sy4: "ܦܘܚܡܐ ܕܬܚܘܝܬܐ",
            sy8: "",
            lt12: "",
            id: "5313",
            dt1: "Zeugnisnote",
            lt11: ""
        },
        {
            sy6: "",
            sy9: "",
            sy5: "ܟܰܪܡܳܐ",
            lt11: "ܟܪܡܐ",
            id: "2416",
            dt2: "",
            dt1: "Karmo",
            sy7: "",
            dt3: "",
            sy4: "ܟܪܡܐ",
            sy8: "",
            lt12: "",
            lt10: "ܟܪܡܐ"
        },
        {
            sy5: "ܟܠܳܝܬܳܐ",
            lt10: "kloyto",
            sy6: "",
            sy8: "",
            lt12: "",
            sy9: "",
            dt1: "Stau",
            dt2: "",
            lt11: "kleyto",
            sy7: "",
            id: "4243",
            dt3: "",
            sy4: "ܟܠܝܬܐ"
        },
        {
            id: "6152",
            sy6: "ܡܙܬܐ",
            dt3: "",
            sy8: "",
            sy4: "ܥܒܝܬܐ",
            lt10: "ĉboyto",
            lt11: "mezto",
            dt2: "",
            sy9: "",
            sy5: "ܥܒܳܝܬܳܐ",
            dt1: "Pelz",
            lt12: "",
            sy7: "ܡܶܙܬܳܐ"
        },
        {
            dt2: "",
            lt11: "",
            sy9: "",
            sy4: "ܩܘܕܫܐ",
            dt3: "",
            sy7: "",
            id: "1931",
            sy6: "",
            lt12: "",
            dt1: "Heiligung",
            lt10: "qudosho",
            sy8: "",
            sy5: "ܩܽܘܕܫܳܐ"
        },
        {
            sy4: "ܢܘܩܙܐ ܕܫܘܪܝܐ",
            sy6: "",
            sy8: "",
            dt3: "",
            sy9: "",
            id: "5947",
            sy5: "ܢܽܘܩܙܳܐ ܕܫܽܘܪܳܝܳܐ",
            lt12: "",
            lt11: "",
            sy7: "",
            dt1: "Anfangspunkt",
            dt2: "Startpunkt",
            lt10: "nuqzo d`shuroyo"
        },
        {
            dt3: "",
            sy8: "",
            sy9: "",
            id: "3269",
            lt11: "",
            lt12: "",
            dt1: "niedrig",
            lt10: "rkino",
            sy4: "ܪܟܝܢܐ",
            sy7: "",
            dt2: "",
            sy6: "",
            sy5: "ܪܟܺܝܢܳܐ"
        },
        {
            id: "6350",
            sy9: "",
            lt10: "qfalfolo",
            dt3: "",
            lt12: "",
            dt1: "Mitwisser",
            dt2: "Vertrauter",
            lt11: "",
            sy8: "",
            sy6: "",
            sy7: "",
            sy5: "ܩܦܰܠܦܳܠܳܐ",
            sy4: "ܩܦܠܦܠܐ"
        },
        {
            lt12: "",
            dt1: "Heilig bist du der Starke",
            sy9: "",
            lt11: "",
            sy8: "",
            sy6: "",
            sy7: "",
            sy5: "ܩܰܕܺܝܫܰܬ ܚܰܝܠܬ݂ܳܢܳܐ",
            dt3: "",
            lt10: "qadishat ĥaylthono",
            sy4: "ܩܕܝܫܬ ܚܝܠܬܢܐ",
            id: "1922",
            dt2: ""
        },
        {
            sy6: "ܩܒܝܒܐ",
            sy7: "ܩܰܒܺܝܒܳܐ",
            lt12: "",
            sy9: "",
            dt3: "",
            sy8: "",
            id: "6074",
            lt11: "qabibo",
            dt1: "konkav",
            sy4: "ܢܚܝܪܝܐ",
            sy5: "ܢܚܺܝܪܳܝܳܐ",
            lt10: "nĥiroyo",
            dt2: ""
        },
        {
            id: "1074",
            dt3: "",
            sy9: "",
            lt11: "ܐܠܝܐܬܐ",
            sy7: "",
            lt10: "ܐܠܝܐܬܐ",
            dt2: "",
            sy6: "",
            dt1: "Eliata",
            lt12: "",
            sy4: "ܐܠܝܐܬܐ",
            sy8: "",
            sy5: "ܐܶܠܺܝܐܰܬܰܐ"
        },
        {
            lt10: "ܙܝܘܐ",
            sy4: "ܙܝܘܐ",
            sy5: "ܙܺܝܘܳܐ",
            dt2: "",
            dt1: "Ziwo",
            dt3: "",
            sy7: "",
            sy8: "",
            sy9: "",
            sy6: "",
            lt11: "ܙܝܘܐ",
            lt12: "",
            id: "5343"
        },
        {
            sy4: "ܛܘܗܡܢܐ",
            sy8: "",
            sy5: "ܛܽܘܗܡܳܢܳܐ",
            sy9: "",
            id: "3613",
            sy7: "",
            dt1: "Rassist",
            dt3: "",
            lt11: "",
            dt2: "",
            lt10: "țuhmono",
            sy6: "",
            lt12: ""
        },
        {
            sy4: "ܐܛܪܘܓܐ",
            id: "3384",
            lt12: "",
            sy5: "ܐܶܛܪܽܘܓ݂ܳܐ",
            sy7: "",
            dt3: "",
            sy9: "",
            sy6: "",
            lt10: "etruğo",
            lt11: "",
            dt2: "",
            sy8: "",
            dt1: "Orange"
        },
        {
            sy7: "",
            sy6: "",
            sy8: "",
            lt12: "",
            lt11: "ܦܠܓ",
            sy4: "ܦܠܓ",
            dt2: "",
            dt3: "",
            dt1: "Polog",
            sy9: "",
            id: "3486",
            lt10: "ܦܠܓ",
            sy5: "ܦܳܠܳܓ"
        },
        {
            lt12: "",
            dt1: "Radiergummi",
            lt10: "laĥoyto",
            sy7: "",
            dt2: "",
            id: "3591",
            dt3: "",
            sy5: "ܠܰܚܳܝܬܳܐ",
            sy4: "ܠܚܝܬܐ",
            sy6: "",
            sy8: "",
            lt11: "",
            sy9: ""
        },
        {
            sy4: "ܥܘܪܬܐ",
            sy6: "",
            sy9: "",
            dt3: "",
            lt12: "",
            lt10: "ĉworotho",
            lt11: "",
            id: "5871",
            dt1: "Altwaren",
            sy7: "",
            dt2: "",
            sy5: "ܥܘܳܪܳܬ݂ܳܐ",
            sy8: ""
        },
        {
            lt11: "",
            id: "4990",
            sy7: "",
            sy9: "",
            sy8: "",
            sy5: "ܟܽܠ ܡܶܕܶܡ ܕܰܐܠܳܗܳܐ ܥܳܒܶܕ ܠܳܐ ܥܳܒܶܕ ܠܶܗ ܕܠܳܐ ܥܶܠܬ݂ܳܐ",
            dt2: "",
            dt1: "Was immer Gott auch tut er tut es nicht ohne Grund",
            lt12: "",
            lt10: "Kul medem d aloho ĉobed  lo ĉobed le dlo ĉeltho",
            dt3: "",
            sy4: "ܟܠ ܡܕܡ ܕܐܠܗܐ ܥܒܕ ܠܐ ܥܒܕ ܠܗ ܕܠܐ ܥܠܬܐ",
            sy6: ""
        },
        {
            sy4: "ܒܟܝܪܝܘܬܐ",
            sy9: "",
            sy7: "",
            dt2: "",
            id: "1416",
            sy5: "ܒܟ݂ܺܝܪܳܝܽܘܬ݂ܳܐ",
            dt1: "Früh",
            lt10: "bkhiroyutho",
            dt3: "",
            lt11: "",
            sy8: "",
            lt12: "",
            sy6: ""
        },
        {
            dt1: "Dorf Derkube",
            sy9: "",
            lt12: "",
            sy6: "ܕܪܩܘܒܐ",
            dt3: "",
            lt10: "qritho derqube",
            sy5: "ܩܪܺܝܬ݂ܳܐ ܕܶܪܩܽܘܒܶܐ",
            dt2: "Derkube",
            sy8: "",
            id: "931",
            sy4: "ܩܪܝܬܐ ܕܪܩܘܒܐ",
            lt11: "derqube",
            sy7: "ܕܶܪܩܽܘܒܶܐ"
        },
        {
            dt1: "anreden",
            sy9: "",
            sy4: "ܡܠܠ",
            dt2: "anquatschen",
            dt3: "",
            id: "5982",
            lt11: "",
            sy6: "",
            sy7: "",
            sy8: "",
            lt10: "malel",
            lt12: "",
            sy5: "ܡܰܠܶܠ"
        },
        {
            id: "469",
            sy5: "ܒܰܪܩܳܐ",
            sy8: "",
            lt12: "",
            lt11: "ܒܪܩܐ",
            sy4: "ܒܪܩܐ",
            lt10: "ܒܪܩܐ",
            dt2: "",
            sy6: "",
            sy9: "",
            sy7: "",
            dt1: "Barqo",
            dt3: ""
        },
        {
            dt1: "Nieselregen",
            lt10: "rzofo",
            lt11: "rezfo",
            sy9: "",
            id: "3274",
            dt2: "Sprühregen",
            dt3: "",
            lt12: "",
            sy6: "ܪܙܦܐ",
            sy8: "",
            sy5: "ܪܙܳܦܳܐ",
            sy7: "ܪܶܙܦܳܐ",
            sy4: "ܪܙܦܐ"
        },
        {
            dt1: "Arm",
            dt2: "",
            sy4: "ܕܪܥܐ",
            lt10: "droĉo",
            id: "294",
            sy8: "",
            sy9: "",
            dt3: "",
            lt11: "",
            sy7: "",
            lt12: "",
            sy5: "ܕܪܳܥܳܐ",
            sy6: ""
        },
        {
            sy9: "",
            sy7: "",
            lt11: "",
            dt1: "Missbrauch",
            dt3: "",
            id: "3053",
            lt10: "shĥoțo",
            sy8: "",
            sy4: "ܫܚܛܐ",
            dt2: "",
            lt12: "",
            sy6: "",
            sy5: "ܫܚܳܛܳܐ"
        },
        {
            sy7: "",
            lt12: "",
            id: "4415",
            sy8: "",
            sy6: "",
            dt2: "",
            lt10: "teknikoyo",
            sy9: "",
            sy4: "ܬܟܢܝܟܝܐ",
            dt1: "Techniker",
            dt3: "",
            lt11: "",
            sy5: "ܬܶܟܢܺܝܟܳܝܳܐ"
        },
        {
            lt10: "ܕܫܢܐ",
            sy4: "ܕܫܢܐ",
            id: "951",
            lt11: "ܕܫܢܐ",
            sy8: "",
            dt3: "",
            dt2: "",
            sy9: "",
            sy6: "",
            sy7: "",
            lt12: "",
            sy5: "ܕܳܫܢܳܐ",
            dt1: "Doschno"
        },
        {
            dt2: "",
            sy5: "ܪܺܝܫܳܐ ܕܐܺܝܩܳܪܳܐ",
            lt12: "",
            lt11: "",
            lt10: "rishono d`iqoro",
            dt1: "Ehrenvorsitzender",
            id: "1019",
            sy8: "",
            sy4: "ܪܝܫܐ ܕܐܝܩܪܐ",
            sy9: "",
            dt3: "",
            sy7: "",
            sy6: ""
        },
        {
            dt3: "",
            id: "5269",
            sy4: "ܙܕܩܝܐ",
            lt11: "ܙܕܩܝܐ",
            sy6: "",
            sy7: "",
            lt12: "",
            dt1: "Zedakija",
            lt10: "ܙܕܩܝܐ",
            sy8: "",
            sy9: "",
            sy5: "ܙܶܕܰܩܝܰܐ",
            dt2: ""
        },
        {
            sy7: "",
            dt3: "",
            sy6: "",
            dt1: "renovieren",
            sy5: "ܚܰܕܶܬ݂",
            sy8: "",
            id: "3694",
            lt11: "",
            sy4: "ܚܕܬ",
            dt2: "erneuern",
            lt10: "ĥadeth",
            lt12: "",
            sy9: ""
        },
        {
            id: "2986",
            sy9: "",
            sy6: "",
            lt12: "",
            dt2: "",
            sy8: "",
            lt11: "",
            sy4: "ܝܬܝܪ ܡܢ",
            lt10: "yatir men",
            sy5: "ܝܰܬܝܺܪ ܡܶܢ",
            dt3: "",
            dt1: "mehr als",
            sy7: ""
        },
        {
            sy6: "",
            dt3: "",
            sy4: "ܩܠܩ",
            id: "3942",
            sy7: "",
            dt2: "",
            lt10: "qlaq",
            lt11: "",
            sy9: "",
            sy8: "",
            sy5: "ܩܠܰܩ",
            dt1: "schielen",
            lt12: ""
        },
        {
            sy4: "ܒܪܢܫܐ ܚܨܕ ܗܘ ܡܕܡ ܕܙܪܥ",
            sy5: "ܒܰܪܢܳܫܳܐ ܚܳܨܶܕ ܗܰܘ ܡܶܕܶܡ ܕܙܳܪܰܥ",
            dt2: "",
            lt10: "barnosho ĥosed hau medem dzoraĉ",
            dt1: "Was der Mensch sät wird er ernten",
            lt11: "",
            lt12: "",
            id: "4989",
            sy9: "",
            sy7: "",
            sy8: "",
            dt3: "",
            sy6: ""
        },
        {
            id: "920",
            dt3: "",
            sy4: "ܩܪܝܬܐ ܕܐܚܘܐ",
            lt10: "qritho aĥwo",
            dt1: "Dorf Ahwo",
            sy5: "ܩܪܺܝܬ݂ܳܐ ܕܐܚܘܐ",
            sy9: "",
            sy7: "ܐܰܚܘܳܐ",
            lt11: "aĥwo",
            dt2: "Ahwo",
            lt12: "",
            sy8: "",
            sy6: "ܐܚܘܐ"
        },
        {
            lt11: "",
            lt10: "wazirutho",
            sy6: "",
            dt3: "",
            sy9: "",
            sy5: "ܘܰܙܺܝܪܽܘܬ݂ܳܐ",
            dt1: "Ministerium",
            dt2: "",
            sy8: "",
            id: "3045",
            sy7: "",
            lt12: "",
            sy4: "ܘܙܝܪܘܬܐ"
        },
        {
            dt1: "letztendlich",
            sy4: "ܩܨܢܝܐ",
            dt2: "",
            sy8: "",
            sy9: "",
            sy6: "",
            lt10: "qeŝonoyo",
            dt3: "",
            sy7: "",
            lt12: "",
            id: "2782",
            sy5: "ܩܶܨܳܢܳܝܳܐ",
            lt11: ""
        },
        {
            lt12: "",
            sy6: "",
            sy9: "",
            sy7: "",
            sy4: "ܟܬܘܒܬܐ",
            sy8: "",
            dt3: "",
            id: "4029",
            dt1: "Schreiberin",
            lt10: "kothubto",
            dt2: "",
            lt11: "",
            sy5: "ܟܬ݂ܽܘܒܬܳܐ"
        },
        {
            sy8: "",
            id: "1757",
            lt11: "",
            dt2: "",
            sy4: "ܫܠܡܐ",
            dt3: "",
            lt12: "",
            sy7: "",
            sy9: "",
            sy6: "",
            sy5: "ܫܠܳܡܳܐ",
            lt10: "shlomo",
            dt1: "Gruß"
        },
        {
            dt3: "Ambivalenz",
            lt10: "trayonay sukole",
            lt11: "",
            dt1: "Zweideutigkeit",
            sy5: "ܬܪܰܝܳܢܰܝ ܣܽܘ̈ܟܠܶܐ",
            sy4: "ܬܪܝܢܝ ܣܘܟܠܐ",
            sy8: "",
            dt2: "Doppeldeutigkeit",
            sy6: "",
            sy9: "",
            id: "5389",
            sy7: "",
            lt12: ""
        },
        {
            sy9: "",
            dt1: "Gaddi",
            lt10: "ܓܕܝ",
            dt2: "",
            sy8: "",
            lt11: "ܓܕܝ",
            sy4: "ܓܕܝ",
            sy6: "ܓܐܕܝ",
            sy5: "ܓܰܕܺܝ",
            sy7: "ܓܰܐܕܺܝ",
            lt12: "ܓܐܕܝ",
            dt3: "",
            id: "1459"
        },
        {
            sy6: "",
            lt11: "",
            sy7: "",
            dt1: "Luftangriff",
            lt12: "",
            sy4: "ܗܔܡܐ ܐܐܪܝܐ ",
            sy9: "",
            sy8: "",
            lt10: "hjome oyaroyo",
            dt2: "",
            dt3: "",
            id: "2864",
            sy5: "ܗܔܳܡܳܐ ܐܳܐܰܪܳܝܳܐ "
        },
        {
            lt10: "mim",
            id: "2879",
            dt3: "",
            sy6: "",
            dt1: "m",
            sy7: "",
            sy9: "",
            lt11: "",
            lt12: "",
            dt2: "",
            sy8: "",
            sy5: "ܡ",
            sy4: "ܡ"
        },
        {
            sy7: "",
            sy4: "ܐܘܕܝ",
            dt1: "Audi",
            sy9: "",
            sy6: "",
            sy8: "",
            sy5: "ܐܰܘܕܺܝ",
            dt2: "Awdi",
            id: "343",
            lt11: "ܐܘܕܝ",
            lt12: "",
            lt10: "ܐܘܕܝ",
            dt3: ""
        },
        {
            lt10: "yoneq krotho",
            sy8: "",
            sy5: "ܝܳܢܶܩ ܟܪܳܬ݂ܳܐ",
            sy7: "",
            id: "835",
            sy9: "",
            sy4: "ܝܢܩ ܟܪܬܐ",
            dt2: "",
            lt11: "",
            dt1: "Daumenlutscher",
            lt12: "",
            dt3: "",
            sy6: ""
        },
        {
            sy7: "",
            dt3: "",
            sy8: "",
            sy5: "ܬܰܡܰܢܝܰܐ",
            lt10: "ܬܡܢܝܐ",
            lt11: "ܬܡܢܝܐ",
            id: "4373",
            sy6: "",
            sy9: "",
            dt2: "",
            dt1: "Tamania",
            sy4: "ܬܡܢܝܐ",
            lt12: ""
        },
        {
            dt2: "",
            dt1: "Brautkleid",
            sy8: "",
            lt12: "",
            id: "695",
            sy9: "",
            lt11: "",
            dt3: "",
            lt10: "lbush kaltho",
            sy5: "ܠܒܽܘܫ ܟܰܠܬ݂ܳܐ",
            sy7: "",
            sy6: "",
            sy4: "ܠܒܘܫ ܟܠܬܐ"
        },
        {
            lt12: "",
            sy7: "",
            sy9: "",
            sy5: "ܨܠܽܘܬ݂ܳܐ",
            sy8: "",
            dt2: "Gebet",
            lt10: "ŝlutho",
            dt1: "Beten",
            sy4: "ܨܠܘܬܐ",
            lt11: "",
            sy6: "",
            id: "581",
            dt3: ""
        },
        {
            sy5: "ܒܰܪ ܡܰܠܟܳܐ",
            sy8: "",
            lt10: "bar malko",
            dt1: "Königssohn",
            sy6: "",
            dt2: "",
            lt11: "",
            dt3: "",
            sy9: "",
            id: "6076",
            lt12: "",
            sy4: "ܒܪ ܡܠܟܐ",
            sy7: ""
        },
        {
            dt2: "trivial",
            sy9: "",
            sy4: "ܦܫܝܛܐ",
            lt11: "",
            id: "1038",
            sy6: "",
            lt12: "",
            sy8: "",
            dt1: "einfach",
            lt10: "fshițo",
            sy5: "ܦܫܺܝܛܳܐ",
            dt3: "leicht",
            sy7: ""
        },
        {
            sy5: "ܫܽܘܚܠܳܦ ܩܠܺܝܡܳܐ",
            dt3: "",
            lt11: "",
            sy9: "",
            sy4: "ܫܘܚܠܦ ܩܠܝܡܐ",
            sy6: "",
            dt2: "",
            sy7: "",
            lt10: "shuĥlof qlimo",
            dt1: "Klimawechsel",
            id: "2527",
            sy8: "",
            lt12: ""
        },
        {
            sy7: "",
            sy4: "ܬܚܪܙܬܐ ܕܝܘܡܐ",
            dt2: "",
            sy9: "",
            sy8: "",
            lt10: "taĥrezto d`yaumo",
            sy5: "ܬܰܚܪܶܙܬܳܐ ܕܝܰܘܡܳܐ",
            sy6: "",
            lt12: "",
            dt1: "Tagesprogramm",
            dt3: "",
            lt11: "",
            id: "4365"
        },
        {
            lt11: "ܠܝܫܡܫ",
            sy7: "",
            dt1: "Lischmasch",
            sy6: "",
            dt2: "",
            lt10: "ܠܝܫܡܫ",
            id: "2844",
            sy5: "ܠܺܝܫܡܰܫ",
            lt12: "",
            sy4: "ܠܝܫܡܫ",
            dt3: "",
            sy8: "",
            sy9: ""
        },
        {
            lt12: "",
            lt11: "ܐܟܝ ܦܝܟܪܝ",
            sy4: "ܐܟܝ ܦܝܟܪܝ",
            sy9: "",
            sy5: "ܐܰܟܰܝ ܦܺܝܟܪܺܝ",
            dt2: "",
            sy8: "",
            sy7: "",
            dt1: "Akay Fikri",
            sy6: "",
            dt3: "",
            lt10: "ܐܟܝ ܦܝܟܪܝ",
            id: "168"
        },
        {
            lt12: "",
            sy7: "",
            id: "2296",
            lt11: "ܝܓܪܣܗܕܘܬܐ",
            sy6: "",
            sy5: "ܝܰܓܰܪܣܰܗܰܕܽܘܬܰܐ",
            sy9: "",
            dt1: "Jegar Sahaduta",
            sy4: "ܝܓܪܣܗܕܘܬܐ",
            sy8: "",
            dt3: "",
            dt2: "",
            lt10: "ܝܓܪܣܗܕܘܬܐ"
        },
        {
            sy7: "",
            sy9: "",
            dt2: "Schatztruhe",
            sy6: "",
            id: "3908",
            lt10: "beth gazo",
            dt3: "",
            lt11: "",
            lt12: "",
            dt1: "Schatzkammer",
            sy5: "ܒܶܝܬ݂ ܓܰܙܳܐ",
            sy4: "ܒܝܬ ܓܙܐ",
            sy8: ""
        },
        {
            dt2: "",
            sy9: "",
            lt11: "",
            sy8: "",
            sy6: "",
            id: "874",
            sy5: "ܟܪܺܝܗ ܫܰܟܰܪ",
            lt12: "",
            dt3: "",
            lt10: "krih shakar",
            dt1: "Diabetiker",
            sy4: "ܟܪܝܗ ܫܟܪ",
            sy7: ""
        },
        {
            sy4: "ܐܬܐ",
            sy8: "",
            sy5: "ܐܰܬܰܐ",
            sy7: "",
            dt2: "",
            lt12: "",
            lt10: "ܐܬܐ",
            sy6: "",
            dt1: "Ata",
            id: "321",
            dt3: "",
            lt11: "ܐܬܐ",
            sy9: ""
        },
        {
            sy8: "",
            sy9: "",
            lt10: "Mzauğo hat",
            sy6: "",
            id: "Ps5rTiEf9GLmc29AzPI5",
            sy4: "ܡܙܘܓ̣ܐ ܗܬ",
            dt3: "",
            lt12: "",
            sy5: "ܡܙܰܘܓ̣ܳܐ ܗܰܬ",
            dt1: "Bist du verheiratet?",
            sy7: "",
            dt2: "",
            "date": {
                "seconds": 1590848634,
                "nanoseconds": 997000000
            },
            lt11: ""
        },
        {
            dt2: "",
            sy8: "",
            id: "6222",
            sy4: "ܡܢ ܫܘܪܝ ܒܪܝܬܐ",
            dt3: "",
            dt1: "seit Schöpfung",
            sy7: "",
            lt12: "",
            sy6: "",
            lt10: "men shuroy britho",
            sy5: "ܡܶܢ ܫܽܘܪܳܝ ܒܪܺܝܬ݂ܳܐ",
            sy9: "",
            lt11: ""
        },
        {
            lt10: "yarib",
            sy6: "ܪܒ",
            lt12: "rabi",
            dt1: "groß werden",
            lt11: "rab",
            sy8: "ܪܒܝ",
            sy9: "ܪܰܒܺܝ",
            sy7: "ܪܰܒ",
            sy5: "ܝܰܪܺܝܒ",
            dt3: "",
            dt2: "",
            sy4: "ܝܪܝܒ",
            id: "1735"
        },
        {
            sy9: "",
            dt3: "",
            id: "3701",
            sy6: "",
            dt1: "Resin",
            sy5: "ܪܳܨܳܢ",
            sy7: "",
            sy4: "ܪܨܢ",
            sy8: "",
            dt2: "Roson",
            lt11: "ܪܨܢ",
            lt12: "",
            lt10: "ܪܨܢ"
        },
        {
            sy9: "",
            sy6: "",
            dt1: "Waschmaschine",
            dt2: "",
            sy4: "ܡܫܝܓܢܝܬܐ",
            sy5: "ܡܫܺܝܓ݂ܳܢܺܝܬ݂ܳܐ",
            id: "4998",
            lt10: "mshiğonitho",
            lt11: "",
            sy7: "",
            dt3: "",
            lt12: "",
            sy8: ""
        },
        {
            lt11: "",
            sy5: "ܡܰܥܒܰܪ ܬܚܽܘܡܳܐ",
            id: "397",
            sy8: "",
            lt10: "maĉbar tĥumo",
            dt3: "",
            sy4: "ܡܥܒܪ ܬܚܘܡܐ",
            sy7: "",
            sy6: "",
            dt1: "Ausweis",
            sy9: "",
            lt12: "",
            dt2: "Passport"
        },
        {
            lt11: "ܩܘܣܛܢܛܝܢ",
            lt12: "",
            sy6: "",
            dt1: "Konstantin",
            id: "2586",
            sy4: "ܩܘܣܛܢܛܝܢ",
            lt10: "ܩܘܣܛܢܛܝܢ",
            sy5: "ܩܽܘܣܛܰܢܛܺܝܢ",
            dt3: "",
            sy7: "",
            dt2: "Constantin",
            sy8: "",
            sy9: ""
        },
        {
            sy8: "",
            dt3: "",
            sy7: "ܦܨܺܝܚܳܐ",
            lt10: "ĥadyo",
            dt2: "glücklich",
            id: "1402",
            sy5: "ܚܰܕܝܳܐ",
            lt12: "",
            sy9: "",
            sy6: "ܦܨܝܚܐ",
            sy4: "ܚܕܝܐ",
            lt11: "fŝiĥo",
            dt1: "froh"
        },
        {
            dt3: "",
            sy4: "ܒܪܚܠܐ",
            sy5: "ܒܰܪܚܳܠܳܐ",
            id: "451",
            lt10: "ܒܪܚܠܐ",
            dt2: "",
            dt1: "Barholo",
            sy8: "",
            lt11: "ܒܪܚܠܐ",
            sy6: "",
            lt12: "",
            sy7: "",
            sy9: ""
        },
        {
            sy8: "",
            id: "3683",
            dt2: "",
            sy9: "",
            sy7: "",
            sy6: "",
            dt1: "Reklam",
            lt11: "",
            lt12: "",
            dt3: "",
            lt10: "reklam",
            sy5: "ܪܶܟܠܰܐܡ",
            sy4: "ܪܟܠܐܡ"
        },
        {
            sy9: "",
            sy5: "ܕܰܝܘܳܢܺܝܬ݂ܳܐ",
            sy4: "ܕܝܘܢܝܬܐ",
            dt1: "Verrückte",
            dt3: "",
            sy8: "",
            lt12: "",
            sy6: "",
            dt2: "",
            sy7: "",
            id: "4792",
            lt11: "",
            lt10: "daywonitho"
        },
        {
            dt2: "Stroh",
            id: "1988",
            lt12: "",
            lt11: "",
            sy8: "",
            sy4: "ܬܒܢܐ",
            sy5: "ܬܶܒܢܶܐ",
            dt1: "Heu",
            dt3: "",
            sy6: "",
            lt10: "tebno",
            sy9: "",
            sy7: ""
        },
        {
            dt3: "",
            id: "5558",
            lt12: "",
            sy4: "ܢܘܨܦܐ",
            dt2: "",
            lt11: "",
            sy7: "",
            sy5: "ܢܽܘܨܦܳܐ",
            dt1: "Abbenddämmerung",
            lt10: "nuŝfo",
            sy8: "",
            sy6: "",
            sy9: ""
        },
        {
            lt12: "",
            sy5: "ܫܽܘܠܛܳܢܳܐ ܝܳܬ݂ܳܝܳܐ",
            sy7: "",
            sy6: "",
            sy9: "",
            lt10: "shulțono yothoyo",
            lt11: "",
            dt1: "Autonomie",
            sy8: "",
            dt2: "Selbstverwaltung",
            sy4: "ܫܽܘܠܛܳܢܳܐ ܝܳܬܳܝܳܐ",
            id: "5463",
            dt3: ""
        },
        {
            sy5: "ܗܳܢܰܘ ܦܰܓ݂ܪܺܝ",
            dt3: "",
            lt11: "",
            sy9: "",
            sy4: "ܗܢܘ ܦܓܪܝ",
            sy8: "",
            lt10: "honau fağri",
            lt12: "",
            dt1: "Das ist mein Leib",
            id: "819",
            sy7: "",
            sy6: "",
            dt2: ""
        },
        {
            dt2: "Mittelstandunternehmer",
            sy6: "",
            dt1: "Mittelständer",
            sy9: "",
            sy7: "",
            lt11: "",
            sy5: "ܡܳܪܶܐ ܫܶܕܬ݂ܳܐ ܡܶܨܥܳܝܬܳܐ",
            id: "6365",
            sy4: "ܡܪܐ ܫܕܬܐ ܡܨܥܝܬܐ",
            lt10: "more shedtho meŝĉoyto",
            dt3: "",
            lt12: "",
            sy8: ""
        },
        {
            sy9: "",
            dt3: "",
            lt11: "",
            lt12: "",
            sy6: "",
            lt10: "gunĥo d'yamo",
            sy8: "",
            sy7: "",
            dt2: "",
            sy4: "ܓܘܢܚܐ ܕܝܡܐ",
            dt1: "Seekatastrophe",
            sy5: "ܓܽܘܢܚܳܐ ܕܝܰܡܳܐ",
            id: "6219"
        },
        {
            dt1: "Dorf Anhil",
            sy8: "",
            lt11: "anĥil",
            dt2: "Enhil",
            sy4: "ܩܪܝܬܐ ܕܐܢܚܝܠ",
            id: "921",
            sy6: "ܐܢܚܝܠ",
            sy9: "",
            sy7: "ܐܰܢܚܺܝܠ",
            lt10: "qritho d`anĥil",
            dt3: "Anhil",
            lt12: "",
            sy5: "ܩܪܺܝܬ݂ܳܐ ܕܰܐܢܚܺܝܠ"
        },
        {
            dt1: "Spielzeit",
            lt12: "",
            sy4: "ܙܒܢܐ ܕܐܫܬܥܢܝܐ",
            sy9: "",
            sy5: "ܙܰܒܢܳܐ ܕܶܐܫܬܥܶܢܝܳܐ",
            sy7: "",
            lt11: "",
            lt10: "zabno d`eshtĉenyo",
            dt3: "",
            sy8: "",
            dt2: "",
            sy6: "",
            id: "4216"
        },
        {
            sy9: "",
            sy7: "ܝܰܡܳܐ ܡܶܨܥܳܝܳܐ",
            lt12: "",
            dt2: "",
            id: "6361",
            sy4: "ܝܡܐ ܚܘܪܐ",
            dt3: "",
            sy6: "ܝܡܐ ܡܨܥܝܐ",
            lt10: "yamo ĥeworo",
            lt11: "yamo meŝĉoyo",
            sy5: "ܝܰܡܳܐ ܚܶܘܳܪܳܐ",
            dt1: "Mittelmeer",
            sy8: ""
        },
        {
            sy9: "",
            sy8: "",
            sy4: "ܫܠܝܡܘܢ",
            sy6: "ܣܠܡܐ",
            sy7: "ܣܰܠܳܡܳܐ",
            sy5: "ܫܠܶܝܡܽܘܢ",
            id: "3813",
            dt2: "",
            lt11: "ܣܠܡܐ",
            lt10: "ܣܠܡܐ",
            lt12: "ܣܠܡܐ",
            dt1: "Salomo",
            dt3: ""
        },
        {
            sy4: "ܩܡܘܐܝܠ",
            dt2: "",
            lt10: "ܩܡܘܐܝܠ",
            sy5: "ܩܰܡܽܘܐܝܶܠ",
            dt3: "",
            lt12: "ܩܡܘܐܝܠ",
            lt11: "ܩܡܘܐܝܠ",
            sy8: "",
            sy9: "",
            id: "2452",
            sy7: "ܩܡܽܘܐܝܶܠ",
            sy6: "ܩܡܘܐܝܠ",
            dt1: "Kemuel"
        },
        {
            dt2: "",
            lt12: "",
            sy8: "",
            sy9: "",
            lt11: "",
            sy6: "",
            lt10: "ĥash u mith wethqbar",
            dt3: "",
            dt1: "Hat gelitten und ist begraben worden",
            id: "1886",
            sy7: "",
            sy4: "ܚܫ ܘ ܡܝܬ ܘܐܬܩܒܪ",
            sy5: "ܚܰܫ ܘ ܡܺܝܬ݂ ܘܶܐܬ݂ܩܒܰܪ"
        },
        {
            sy8: "",
            dt1: "Faden",
            sy5: "ܚܽܘܛܳܐ",
            lt11: "",
            sy6: "",
            dt2: "",
            sy7: "",
            dt3: "",
            sy9: "",
            lt12: "",
            sy4: "ܚܘܛܐ",
            lt10: "ĥuțo",
            id: "1227"
        },
        {
            sy6: "ܪܚܒ",
            sy4: "ܪܚܒ",
            id: "3595",
            lt10: "ܪܚܒ",
            sy5: "ܪܰܚܰܒ",
            sy8: "",
            sy7: "ܪܳܚܳܒ",
            lt12: "ܪܚܒ",
            lt11: "ܪܚܒ",
            sy9: "",
            dt1: "Rahab",
            dt3: "",
            dt2: ""
        },
        {
            sy5: "ܓܰܓܰܪܬ݂ܳܐ",
            id: "2445",
            sy9: "",
            sy7: "",
            dt3: "",
            sy8: "",
            dt1: "Kehle",
            lt12: "",
            sy4: "ܓܓܪܬܐ",
            lt11: "",
            dt2: "",
            lt10: "gagartho",
            sy6: ""
        },
        {
            dt1: "Institution",
            lt12: "mshatasto",
            sy6: "ܫܬܐܣܬܐ",
            dt3: "",
            sy9: "ܡܫܰܬܰܐܣܬܳܐ",
            lt10: "mțacasto",
            sy7: "ܫܶܬܶܐܣܬܳܐ",
            sy8: "ܡܫܬܐܣܬܐ",
            dt2: "",
            sy4: "ܡܛܟܣܬܐ",
            lt11: "shetesto",
            id: "6432",
            sy5: "ܡܛܰܟܰܣܬܳܐ"
        },
        {
            lt10: "tarsitho",
            dt2: "Nahrungsmittel",
            sy6: "",
            sy9: "",
            sy7: "",
            sy8: "",
            sy4: "ܬܪܣܝܬܐ",
            sy5: "ܬܰܪܣܺܝܬ݂ܳܐ",
            id: "2732",
            dt3: "Proviant",
            lt12: "",
            dt1: "Lebensmittel",
            lt11: ""
        },
        {
            lt12: "",
            dt3: "",
            dt1: "Joram",
            sy9: "",
            lt10: "ܝܘܪܡ",
            sy7: "",
            lt11: "ܝܘܪܡ",
            sy8: "",
            sy5: "ܝܽܘܪܳܡ",
            sy4: "ܝܘܪܡ",
            id: "2337",
            dt2: "",
            sy6: ""
        },
        {
            dt2: "",
            id: "1703",
            sy5: "ܣܽܘܡܥܳܠܳܐ",
            sy7: "",
            sy4: "ܣܘܡܥܠܐ",
            lt12: "",
            sy9: "",
            lt10: "sumĉolo",
            lt11: "",
            dt1: "Gottesfürchtigkeit",
            dt3: "",
            sy6: "",
            sy8: ""
        },
        {
            dt1: "Frequenz",
            dt2: "",
            id: "6104",
            sy6: "ܪܨܝܦܘܬܐ",
            lt10: "tkhobo",
            sy9: "",
            sy5: "ܬܟ݂ܳܒܳܐ",
            dt3: "",
            sy4: "ܬܟܒܐ",
            lt12: "",
            lt11: "rŝifutho",
            sy8: "",
            sy7: "ܪܨܺܝܦܽܘܬ݂ܳܐ"
        },
        {
            dt1: "Darios",
            sy7: "ܕܰܪܝܳܘܣ",
            sy4: "ܕܪܝܘܣ",
            dt3: "",
            lt11: "ܕܪܝܘܣ",
            sy5: "ܕܰܪܝܽܘܣ",
            lt12: "ܕܪܝܘܣ",
            sy9: "",
            dt2: "Darius",
            lt10: "ܕܪܝܘܣ",
            sy8: "",
            id: "811",
            sy6: "ܕܪܝܘܣ"
        },
        {
            dt3: "",
            lt10: "ܝܩܪ",
            sy5: "ܝܰܩܰܪ",
            lt12: "",
            lt11: "ܝܩܪ",
            sy7: "",
            sy4: "ܝܩܪ",
            dt1: "Yaqar",
            sy8: "",
            id: "5200",
            sy6: "",
            dt2: "",
            sy9: ""
        },
        {
            sy6: "",
            sy7: "",
            lt12: "",
            sy8: "",
            sy4: "ܓܪ",
            dt1: "Ehe brechen",
            lt11: "",
            id: "1006",
            sy9: "",
            dt3: "",
            sy5: "ܓܳܪ",
            dt2: "ehebrechen",
            lt10: "gor"
        },
        {
            sy8: "",
            sy7: "",
            dt3: "",
            lt11: "",
            sy9: "",
            dt2: "vertraut",
            id: "4819",
            sy4: "ܬܟܝܠܐ",
            lt12: "",
            lt10: "tkhilo",
            dt1: "vertrauensvoll",
            sy5: "ܬܟ݂ܺܝܠܳܐ",
            sy6: ""
        },
        {
            lt10: "besro d`fire",
            dt1: "Fruchtfleisch",
            sy7: "",
            id: "1414",
            sy4: "ܒܣܪܐ ܕܦܐܪܐ",
            lt11: "",
            sy8: "",
            sy5: "ܒܶܣܪܳܐ ܕܦܳܐܪ̈ܐ",
            sy9: "",
            dt3: "",
            dt2: "",
            lt12: "",
            sy6: ""
        },
        {
            sy4: "ܝܥܩܘܒ ܒܟܝܪ",
            lt10: "ܝܥܩܘܒ ܒܟܝܪ",
            sy6: "",
            dt2: "",
            sy7: "",
            lt11: "ܝܥܩܘܒ ܒܟܝܪ",
            sy8: "",
            lt12: "",
            dt1: "Yakub Bakir",
            sy5: "ܝܰܥܩܺܘܒ ܒܰܟܺܝܪ",
            dt3: "",
            sy9: "",
            id: "5191"
        },
        {
            dt2: "",
            sy8: "",
            lt12: "",
            dt3: "",
            sy9: "",
            lt10: "folĥo",
            sy5: "ܦܳܠܚܳܐ",
            dt1: "Soldat",
            lt11: "ĉaskar",
            sy6: "ܥܣܟܪ",
            sy4: "ܦܠܚܐ",
            sy7: "ܥܰܣܟܰܪ",
            id: "4185"
        },
        {
            sy5: "ܒܩܳܠܳܐ ܪܳܡܳܐ",
            sy6: "",
            sy4: "ܒܩܠܐ ܪܡܐ",
            sy9: "",
            id: "2720",
            sy8: "",
            lt10: "bqolo romo",
            lt11: "",
            sy7: "",
            lt12: "",
            dt3: "",
            dt2: "",
            dt1: "laut"
        },
        {
            dt1: "Bremse",
            lt11: "",
            id: "699",
            sy4: "ܦܓܕܐ",
            lt12: "",
            sy6: "",
            dt2: "",
            sy8: "",
            sy5: "ܦܰܓܕܳܐ",
            dt3: "",
            sy9: "",
            lt10: "fagđo",
            sy7: ""
        },
        {
            sy9: "",
            lt10: "ĥfițutho",
            lt12: "",
            sy4: "ܚܦܝܛܘܬܐ",
            lt11: "maĉbdonutho",
            dt1: "Aktivität",
            sy6: "ܡܥܒܕܢܘܬܐ",
            sy8: "",
            sy5: "ܚܦܺܝܛܽܘܬ݂ܳܐ",
            dt2: "",
            id: "5806",
            sy7: "ܡܰܥܒܕܳܢܽܘܬ݂ܳܐ",
            dt3: ""
        },
        {
            id: "750",
            lt12: "",
            sy9: "",
            dt3: "",
            dt2: "",
            sy5: "ܟܽܘܕܚܳܐ",
            sy6: "",
            lt10: "kudĥo",
            sy7: "",
            dt1: "Buttermilch",
            sy4: "ܟܘܕܚܐ",
            lt11: "",
            sy8: ""
        },
        {
            sy7: "",
            sy6: "",
            dt2: "",
            dt3: "",
            sy4: "ܢܩܘܦܐ",
            lt12: "",
            sy9: "",
            sy8: "",
            id: "3157",
            sy5: "ܢܳܩܽܘܦܳܐ",
            dt1: "Nachfolger",
            lt10: "noqufo",
            lt11: ""
        },
        {
            sy6: "ܐܓܪܐ",
            sy7: "ܐܰܓ݂ܪܳܐ",
            sy5: "ܐܰܓ݂ܺܝܪܽܘܬ݂ܳܐ",
            sy8: "",
            dt2: "Pacht",
            lt10: "ağirutho",
            sy4: "ܐܓܝܪܘܬܐ",
            id: "3019",
            lt12: "",
            lt11: "ağro",
            sy9: "",
            dt1: "Miete",
            dt3: ""
        },
        {
            sy4: "ܒܰܪܐܢܳܫܳܐ ܚܳܙܶܐ ܡܶܕܶܡ ܕܐܺܝܬܰܘܗܝ ܩܕܳܡ ܥܰܝܢܰܘܗܝ ܒܪܰܡ ܡܳܪܝܳܐ ܚܳܝܰܪ ܒܠܶܒܳܐ",
            dt2: "",
            sy6: "",
            sy9: "",
            lt11: "",
            lt10: "barnosho ĥose medem dithau kđom ĉaynau, bram moryo ĥoyar blebo",
            sy5: "ܒܰܪܐܢܳܫܳܐ ܚܳܙܶܐ ܡܶܕܶܡ ܕܐܺܝܬܰܘܗܝ ܩܕܳܡ ܥܰܝܢܰܘܗܝ ܒܪܰܡ ܡܳܪܝܳܐ ܚܳܝܰܪ ܒܠܶܒܳܐ",
            dt3: "",
            sy7: "",
            id: "5457",
            dt1: "Ein Mensch sieht, was vor seinem Auge ist,der Herr aber sieht das Herz an",
            sy8: "",
            lt12: ""
        },
        {
            sy4: "ܚܙܝܪܐ",
            lt10: "ĥziro",
            sy7: "",
            dt2: "",
            sy9: "",
            lt12: "",
            sy8: "",
            dt1: "Schwein",
            dt3: "",
            sy5: "ܚܙܺܝܪܳܐ",
            id: "4072",
            sy6: "",
            lt11: ""
        },
        {
            dt1: "Bürgschaft",
            dt3: "",
            sy9: "",
            sy6: "",
            dt2: "",
            id: "741",
            lt12: "",
            lt10: "shaushbinutho",
            sy7: "",
            lt11: "",
            sy5: "ܫܰܘܫܒܺܝܢܽܘܬ݂ܳܐ",
            sy8: "",
            sy4: "ܫܘܫܒܝܢܘܬܐ"
        },
        {
            dt2: "",
            dt3: "",
            sy8: "",
            sy5: "ܩܰܡܚܳܐ",
            sy9: "",
            lt12: "",
            lt11: "",
            lt10: "qamĥo",
            sy4: "ܩܡܚܐ",
            dt1: "Mehl",
            id: "2984",
            sy6: "",
            sy7: ""
        },
        {
            dt1: "Gefängnis",
            sy6: "ܒܝܬ ܐܣܝܪܐ",
            sy7: "ܒܶܝܬ݂ ܐܰܣܺܝܪ̈ܐ",
            sy8: "",
            lt10: "ĥbushyo",
            lt12: "",
            sy5: "ܚܒܽܘܫܝܳܐ",
            id: "1518",
            sy4: "ܚܒܘܫܝܐ",
            dt2: "Haft",
            lt11: "beth asire",
            dt3: "",
            sy9: ""
        },
        {
            lt12: "",
            sy5: "ܝܰܡܬ݂ܳܐ",
            sy6: "",
            sy7: "",
            id: "4088",
            sy9: "",
            dt1: "See",
            lt10: "yamtho",
            dt3: "",
            dt2: "",
            sy4: "ܝܡܬܐ",
            sy8: "",
            lt11: ""
        },
        {
            sy4: "ܬܘܫܒܬܐ",
            lt12: "sĉoyo",
            dt1: "Aggression",
            sy5: "ܬܰܘܫܶܒܬܳܐ",
            lt10: "taushebto",
            sy7: "ܟܒܽܘܫܝܳܐ",
            sy6: "ܟܒܘܫܝܐ",
            id: "5778",
            sy8: "ܣܥܝܐ",
            sy9: "ܣܥܳܝܳܐ",
            lt11: "kbushyo",
            dt2: "",
            dt3: ""
        },
        {
            id: "305",
            sy6: "",
            dt3: "",
            lt12: "",
            sy4: "ܐܣܝܐ",
            sy7: "",
            sy5: "ܐܳܣܝܳܐ",
            dt1: "Arzt",
            lt11: "",
            sy8: "",
            sy9: "",
            dt2: "Doktor",
            lt10: "osyo"
        },
        {
            lt11: "",
            dt2: "Sachverstand",
            dt3: "",
            lt10: "mnafqutho",
            sy8: "",
            sy5: "ܡܢܰܦܩܽܘܬ݂ܳܐ",
            dt1: "Kompetenz",
            lt12: "",
            sy7: "",
            id: "2573",
            sy4: "ܡܢܦܩܘܬܐ",
            sy6: "",
            sy9: ""
        },
        {
            dt3: "",
            sy9: "",
            lt12: "",
            dt2: "",
            sy5: "ܠܽܘܡܳܕܳܐ",
            sy4: "ܠܘܡܕܐ",
            id: "2576",
            lt10: "lumodo",
            sy8: "",
            sy7: "",
            lt11: "",
            dt1: "Konferenz",
            sy6: ""
        },
        {
            sy6: "ܛܒܚܐ",
            lt10: "mbashlono",
            sy8: "",
            lt12: "",
            sy9: "",
            id: "2549",
            dt2: "",
            sy4: "ܡܒܫܠܢܐ",
            dt1: "Koch",
            sy7: "ܛܰܒܳܚܳܐ",
            lt11: "țaboĥo",
            sy5: "ܡܒܰܫܠܳܢܳܐ",
            dt3: ""
        },
        {
            lt10: "shaĥlef",
            sy6: "ܚܠܦ",
            lt12: "",
            dt1: "wandeln",
            id: "4979",
            sy9: "",
            dt3: "",
            dt2: "",
            sy5: "ܫܰܚܠܶܦ",
            sy4: "ܫܚܠܦ",
            sy8: "",
            sy7: "ܚܰܠܶܦ",
            lt11: "ĥalef"
        },
        {
            dt3: "",
            lt11: "ܕܪܝܝܐ",
            sy5: "ܕܰܪܺܝܝܳܐ",
            sy6: "ܕܪܝܐܝܐ",
            lt12: "ܕܪܝܐܝܐ",
            dt1: "Darijo",
            id: "808",
            lt10: "ܕܪܝܝܐ",
            sy8: "",
            dt2: "",
            sy9: "",
            sy4: "ܕܪܝܝܐ",
            sy7: "ܕܰܪܺܝܐܝܳܐ"
        },
        {
            sy8: "",
            sy6: "",
            lt12: "",
            sy7: "",
            dt3: "",
            dt2: "",
            sy5: "ܘܰܙܺܝܪܽܘܬ݂ܳܐ ܕܝܽܘܠܦܳܢܳܐ",
            id: "621",
            lt11: "",
            lt10: "wazirutho d`yulfono",
            dt1: "Bildungsministerium",
            sy9: "",
            sy4: "ܘܙܝܪܘܬܐ ܕܝܘܠܦܢܐ"
        },
        {
            dt1: "Diözesenhaus",
            sy9: "",
            sy5: "ܒܶܝܬ݂ ܡܰܪܥܺܝܬ݂ܳܐ",
            sy6: "",
            lt11: "",
            dt2: "",
            lt12: "",
            sy8: "",
            lt10: "beth marĉitho",
            id: "901",
            sy7: "",
            dt3: "",
            sy4: "ܒܝܬ ܡܪܥܝܬܐ"
        },
        {
            dt2: "",
            sy5: "ܢܽܘܪܺܐܝܠ",
            sy8: "",
            lt10: "ܢܘܪܐܝܠ",
            dt1: "Nuril",
            dt3: "",
            lt11: "ܢܘܪܐܝܠ",
            id: "3321",
            lt12: "",
            sy4: "ܢܘܪܐܝܠ",
            sy7: "",
            sy9: "",
            sy6: ""
        },
        {
            dt3: "",
            sy5: "ܕܳܪܳܐ",
            sy6: "",
            lt12: "",
            lt10: "doro",
            dt2: "",
            sy7: "",
            sy8: "",
            dt1: "Jahrhundert",
            lt11: "",
            id: "2277",
            sy9: "",
            sy4: "ܕܪܐ"
        },
        {
            sy9: "",
            sy4: "ܩܦܺܝܣܽܘܬ̣ܳܐ",
            sy5: "ܩܦܺܝܣܽܘܬ̣ܳܐ",
            id: "5462",
            sy8: "",
            sy7: "",
            dt1: "Zusammenfassung",
            dt3: "",
            sy6: "",
            lt10: "qfisutho",
            dt2: "",
            lt12: "",
            lt11: ""
        },
        {
            id: "124",
            sy6: "",
            sy9: "",
            dt3: "",
            dt2: "",
            lt12: "",
            sy8: "",
            lt11: "",
            dt1: "Achsel",
            sy4: "ܫܚܬܐ",
            lt10: "shĥotho",
            sy5: "ܫܚܳܬ݂ܳܐ",
            sy7: ""
        },
        {
            sy4: "ܥܘܩܪܐ",
            lt12: "",
            dt1: "Abziehung",
            sy5: "ܥܽܘܩܳܪܳܐ",
            id: "5722",
            sy8: "",
            sy6: "",
            sy9: "",
            dt3: "",
            lt11: "",
            dt2: "",
            sy7: "",
            lt10: "ĉuqoro"
        },
        {
            id: "4850",
            dt3: "",
            sy8: "",
            lt10: "zedqe d`ĉame",
            lt12: "",
            sy7: "",
            sy5: "ܙܶܕܩܳܐ ܕܥܰܡ̈ܶܐ",
            lt11: "",
            sy9: "",
            dt1: "Völkerrecht",
            sy6: "",
            sy4: "ܙܕܩܐ ܕܥܡܐ",
            dt2: ""
        },
        {
            dt3: "",
            sy6: "",
            id: "2208",
            dt1: "Insekt",
            sy4: "ܪܘܫܦܐ",
            sy8: "",
            sy7: "",
            lt11: "",
            lt12: "",
            sy5: "ܪܽܘܫܦܳܐ",
            lt10: "rushfo",
            dt2: "",
            sy9: ""
        },
        {
            lt11: "gargumo",
            lt10: "qaqbo",
            sy8: "ܦܝܢܟܐ",
            sy6: "ܓܪܓܘܡܐ",
            id: "841",
            sy5: "ܩܰܩܒܳܐ",
            dt3: "",
            sy9: "ܦܺܝܢܟܳܐ",
            dt1: "Deckel",
            lt12: "finkho",
            sy7: "ܓܰܪܓܽܘܡܳܐ",
            dt2: "",
            sy4: "ܩܩܒܐ"
        },
        {
            dt2: "TurAbdin",
            id: "4595",
            sy4: "ܛܘܪܥܒܕܝܢ",
            dt3: "",
            sy8: "",
            lt11: "",
            lt12: "",
            sy5: "ܛܽܘܪܥܰܒܕܺܝܢ",
            lt10: "tur ĉabdin",
            sy7: "",
            sy6: "",
            sy9: "",
            dt1: "Tur Abdin"
        },
        {
            lt12: "",
            id: "1918",
            sy8: "",
            sy4: "ܗܕܪܐ",
            sy5: "ܗܶܕܪܳܐ",
            dt2: "",
            sy7: "",
            sy9: "",
            lt11: "ܗܕܪܐ",
            lt10: "ܗܕܪܐ",
            dt1: "Hedro",
            dt3: "",
            sy6: ""
        },
        {
            dt2: "Überblick",
            lt12: "",
            dt3: "",
            sy4: "ܡܬܚܙܝܢܘܬܐ",
            sy6: "",
            sy8: "",
            dt1: "Sicht",
            sy9: "",
            lt10: "methĥazyonutho",
            lt11: "",
            id: "4142",
            sy5: "ܡܶܬ݂ܚܰܙܝܳܢܽܘܬ݂ܳܐ",
            sy7: ""
        },
        {
            lt11: "ܒܣܢܐ",
            sy6: "ܒܣܢܐ",
            dt2: "Besna",
            sy9: "",
            sy7: "ܒܶܣܢܰܐ",
            id: "494",
            lt12: "ܒܣܢܐ",
            sy4: "ܒܣܢܐ",
            dt3: "",
            dt1: "Basna",
            sy8: "",
            sy5: "ܒܰܣܢܰܐ",
            lt10: "ܒܣܢܐ"
        },
        {
            dt1: "Elihu",
            dt3: "",
            sy9: "",
            lt12: "",
            sy7: "",
            lt11: "ܐܠܝܗܘ",
            sy6: "",
            sy8: "",
            id: "1075",
            dt2: "",
            sy4: "ܐܠܝܗܘ",
            lt10: "ܐܠܝܗܘ",
            sy5: "ܐܶܠܺܝܗܽܘ"
        },
        {
            dt2: "",
            lt11: "",
            dt1: "Ahnenreihe",
            sy8: "",
            lt10: "myablonutho",
            id: "5773",
            dt3: "",
            sy7: "",
            sy6: "",
            sy9: "",
            sy5: "ܡܝܰܒܠܳܢܽܘܬ݂ܳܐ",
            sy4: "ܡܝܒܠܢܘܬܐ",
            lt12: ""
        },
        {
            lt11: "ܡܪܝ ܐܝܘܢܢܝܣ ܐܦܪܝܡ ܒܝܠܓܝܓ",
            sy9: "",
            dt2: "",
            id: "3093",
            lt10: "ܡܪܝ ܐܝܘܢܢܝܣ ܐܦܪܝܡ ܒܝܠܓܝܓ",
            sy4: "ܡܪܝ ܐܝܘܢܢܝܣ ܐܦܪܝܡ ܒܝܠܓܝܓ",
            sy8: "",
            dt3: "",
            dt1: "Mor Iyawanis Afrem Bilgic",
            sy6: "",
            sy5: "ܡܳܪܝ̱ ܐܺܝܘܰܢܰܢܺܝܣ ܐܰܦܪܶܝܡ ܒܺܝܠܔܝܺܔ",
            lt12: "",
            sy7: ""
        },
        {
            sy6: "",
            sy8: "",
            lt10: "romutho",
            dt1: "Hochmut",
            sy7: "",
            id: "2035",
            sy5: "ܪܳܡܽܘܬ݂ܳܐ",
            sy9: "",
            lt11: "",
            sy4: "ܪܡܘܬܐ",
            lt12: "",
            dt2: "",
            dt3: ""
        },
        {
            sy9: "",
            lt10: "ŝemdo",
            dt3: "",
            sy7: "",
            sy4: "ܨܡܕܐ",
            sy5: "ܨܶܡܕܳܐ",
            dt1: "Tasche",
            sy6: "",
            dt2: "",
            lt12: "",
            sy8: "",
            lt11: "",
            id: "4392"
        },
        {
            dt2: "",
            sy9: "",
            sy5: "ܕܰܝܘܳܢܽܘܬ݂ܳܐ",
            lt11: "shnoyo",
            lt12: "",
            id: "4793",
            sy6: "ܫܢܝܐ",
            sy7: "ܫܢܳܝܳܐ",
            sy4: "ܕܝܘܢܘܬܐ",
            dt3: "",
            dt1: "Verrücktheit",
            lt10: "daywonutho",
            sy8: ""
        },
        {
            sy7: "",
            dt1: "Absatzmark",
            sy9: "",
            lt10: "tarĉtho",
            lt11: "",
            sy5: "ܬܰܪܥܬ݂ܳܐ",
            id: "5642",
            sy4: "ܬܪܥܬܐ",
            dt2: "",
            sy8: "",
            dt3: "",
            sy6: "",
            lt12: ""
        },
        {
            sy8: "",
            dt1: "Zusammenschluss",
            sy4: "ܚܘܝܕܐ",
            sy5: "ܚܽܘܝܳܕܳܐ",
            lt11: "",
            sy7: "",
            lt10: "ĥuyodo",
            dt3: "",
            sy6: "",
            sy9: "",
            id: "5382",
            lt12: "",
            dt2: ""
        },
        {
            sy5: "ܛܽܘܦܣܳܐ ܩܰܕܡܳܝܳܐ",
            dt1: "Prototyp",
            lt11: "",
            lt10: "țufso qađmoyo",
            sy4: "ܛܘܦܣܐ ܩܕܡܝܐ",
            id: "3531",
            sy7: "",
            sy6: "",
            sy8: "",
            lt12: "",
            dt3: "Erstentwurf",
            dt2: "Muster",
            sy9: ""
        },
        {
            sy7: "",
            dt1: "Heilig bist du Gott",
            id: "1924",
            lt11: "",
            lt10: "qadishat aloho",
            dt3: "",
            sy6: "",
            dt2: "",
            sy8: "",
            lt12: "",
            sy5: "ܩܰܕܺܝܫܰܬ ܐܰܠܳܗܳܐ",
            sy4: "ܩܕܝܫܬ ܐܠܗܐ",
            sy9: ""
        },
        {
            sy6: "",
            dt1: "Absenderin",
            dt2: "",
            sy4: "ܡܫܕܪܢܝܬܐ",
            lt10: "mshadronitho",
            sy7: "",
            id: "5647",
            lt11: "",
            sy8: "",
            sy5: "ܡܫܰܕܪܳܢܺܝܬ݂ܳܐ",
            dt3: "",
            lt12: "",
            sy9: ""
        },
        {
            sy5: "ܐܰܒܪܰܡ",
            dt3: "",
            lt10: "ܐܒܪܡ",
            sy9: "",
            dt1: "Abram",
            id: "109",
            sy4: "ܐܒܪܡ",
            sy7: "",
            sy6: "",
            dt2: "",
            sy8: "",
            lt12: "",
            lt11: "ܐܒܪܡ"
        },
        {
            lt11: "",
            sy9: "",
            sy7: "",
            dt1: "Kirche St. Petrus und Paulus",
            id: "2489",
            dt3: "",
            sy8: "",
            lt12: "",
            sy6: "",
            sy5: "ܥܺܕܬܳܐ ܕܡܳܪܝ̱ ܦܶܛܪܽܘܣ ܘ ܦܰܘܠܽܘܣ",
            lt10: "ĉito d`mor fetrus u faulus",
            sy4: "ܥܕܬܐ ܕܡܪܝ ܦܛܪܘܣ ܘ ܦܘܠܘܣ",
            dt2: ""
        },
        {
            sy6: "",
            sy9: "",
            sy4: "ܝܕܥ",
            lt10: "ܝܕܥ",
            dt3: "",
            sy7: "",
            lt12: "",
            lt11: "ܝܕܥ",
            dt1: "Jada",
            dt2: "",
            sy5: "ܝܰܕܰܥ",
            sy8: "",
            id: "2265"
        },
        {
            sy8: "",
            dt3: "",
            sy9: "",
            lt10: "shbiĉoyo",
            sy7: "",
            dt1: "siebter",
            dt2: "",
            lt11: "",
            lt12: "",
            sy6: "",
            id: "4150",
            sy5: "ܫܒܺܝܥܳܝܳܐ",
            sy4: "ܫܒܝܥܝܐ"
        },
        {
            sy9: "",
            sy8: "",
            sy6: "",
            lt12: "",
            sy4: "ܦܒܪܐ",
            lt11: "ܦܒܪܐ",
            dt3: "",
            id: "1271",
            sy5: "ܦܰܒܪܳܐ",
            dt2: "Fabro",
            lt10: "ܦܒܪܐ",
            sy7: "",
            dt1: "Febro"
        },
        {
            sy6: "",
            lt10: "fham",
            lt12: "",
            sy5: "ܦܗܰܡ",
            sy8: "",
            sy4: "ܦܗܡ",
            lt11: "",
            dt1: "verstehen",
            dt2: "",
            sy7: "",
            sy9: "",
            dt3: "",
            id: "4812"
        },
        {
            sy7: "",
            lt10: "ozel no buraĥ",
            id: "2109",
            lt12: "",
            sy6: "",
            dt3: "",
            dt1: "Ich gehe meinen Weg",
            dt2: "",
            sy8: "",
            sy9: "",
            sy5: "ܐܳܙܶܠ ܐ̱ܢܳܐ ܒܽܐܘܪܰܚܝ",
            sy4: "ܐܙܠ ܐܢܐ ܒܐܘܪܚܝ",
            lt11: ""
        },
        {
            sy4: "ܐܠܗܐ ܐܡܪ",
            sy5: "ܐܰܠܳܗܳܐ ܐܳܡܰܪ",
            lt11: "",
            sy7: "",
            sy8: "",
            sy6: "",
            dt1: "Gott sagt",
            sy9: "",
            dt2: "",
            id: "1695",
            dt3: "",
            lt12: "",
            lt10: "aloho omar"
        },
        {
            sy9: "",
            id: "6341",
            lt12: "",
            sy5: "ܡܣܐܳܢܳܐ ܕܬܰܠܓܳܐ",
            lt10: "msono d'talgo",
            sy4: "ܡܣܐܢܐ ܕܬܠܓܐ",
            dt1: "Schneeschuh",
            dt3: "",
            lt11: "",
            sy8: "",
            sy7: "",
            sy6: "",
            dt2: ""
        },
        {
            sy7: "ܢܺܐ",
            dt3: "",
            dt1: "mich",
            lt11: "ni",
            sy4: "ܠܝ",
            sy8: "ܢܝ",
            id: "3014",
            sy9: "ܢܺܝ",
            lt12: "ni",
            sy6: "ܢܐ",
            sy5: "ܠܺܝ",
            lt10: "li",
            dt2: ""
        },
        {
            sy9: "",
            dt2: "Presse",
            lt12: "",
            sy8: "",
            sy4: "ܓܠܝܘܢܪܘܬܐ",
            dt3: "",
            lt10: "gelyunorutho",
            id: "2979",
            sy7: "ܛܶܒ̈ܶܐ",
            sy5: "ܓܶܠܝܽܘܢܳܪܽܘܬ݂ܳܐ",
            lt11: "țebe",
            dt1: "Medien",
            sy6: "ܛܒܐ"
        },
        {
            dt3: "Kinderlose Frau",
            lt10: "ĉqartho",
            dt2: "Unfruchtbare Frau",
            sy7: "",
            sy4: "ܥܩܪܬܐ",
            lt12: "",
            sy9: "",
            sy8: "",
            lt11: "",
            sy6: "",
            id: "4263",
            dt1: "Sterile Frau",
            sy5: "ܥܩܰܪܬ݂ܳܐ"
        },
        {
            lt10: "ܐܛܫ",
            sy5: "ܐܰܛܰܫ",
            lt11: "ܐܛܫ",
            sy9: "",
            dt3: "",
            sy8: "",
            sy6: "",
            id: "323",
            lt12: "",
            sy4: "ܐܛܫ",
            dt2: "",
            sy7: "",
            dt1: "Atash"
        },
        {
            sy5: "ܐܰܚ̈ܘܳܬ݂ܳܐ",
            sy7: "",
            sy9: "",
            lt10: "aĥwotho",
            lt12: "",
            dt2: "",
            sy8: "",
            sy4: "ܐܚܘܬܐ",
            sy6: "",
            dt1: "Geschwister",
            id: "1590",
            dt3: "",
            lt11: ""
        },
        {
            lt12: "",
            sy4: "ܡܣܬܝܢܘܬܐ",
            dt2: "",
            dt3: "",
            sy9: "",
            sy5: "ܡܰܣܬܝܳܢܽܘܬ݂ܳܐ",
            sy8: "",
            lt11: "tyobutho",
            sy6: "ܬܝܒܘܬܐ",
            dt1: "Apostasie",
            lt10: "mastyonutho",
            sy7: "ܬܝܳܒܽܘܬ݂ܳܐ",
            id: "6013"
        },
        {
            sy5: "ܐܰܒܳܐ",
            id: "4706",
            sy4: "ܐܒܐ",
            lt10: "abo",
            dt1: "Vater",
            sy9: "",
            dt3: "",
            sy6: "",
            sy7: "",
            sy8: "",
            lt12: "",
            dt2: "Papa",
            lt11: ""
        },
        {
            lt10: "tlothin",
            dt3: "",
            lt11: "lomath",
            dt1: "30",
            sy9: "",
            sy8: "",
            sy4: "ܬܠܬܝܢ",
            sy5: "ܬܠܳܬ݂ܺܝܢ",
            lt12: "",
            dt2: "Dreißig",
            id: "31",
            sy6: "ܠ",
            sy7: "ܠ"
        },
        {
            sy6: "",
            sy7: "",
            lt12: "",
            dt1: "Tablette",
            sy4: "ܚܒܬܐ",
            sy9: "",
            dt2: "Pille",
            lt10: "ĥabtho",
            id: "4354",
            sy5: "ܚܰܒܬ݂ܳܐ",
            dt3: "",
            lt11: "",
            sy8: ""
        },
        {
            sy4: "ܝܕܝܥ ܒܥܠܡܐ",
            sy5: "ܝܺܕܺܝܥ ܒܥܳܠܡܳܐ",
            dt3: "",
            sy6: "",
            lt10: "iđiĉ b`ĉolmo",
            sy8: "",
            dt1: "weltberühmt",
            sy9: "",
            sy7: "",
            lt11: "",
            lt12: "",
            dt2: "weltbekannt",
            id: "5048"
        },
        {
            lt10: "ĉam",
            sy7: "ܒܝܰܕ",
            sy8: "",
            sy6: "ܒܝܕ",
            sy5: "ܥܰܡ",
            sy9: "",
            dt1: "per",
            dt2: "",
            sy4: "ܥܡ",
            lt11: "byađ",
            id: "6164",
            dt3: "",
            lt12: ""
        },
        {
            sy7: "ܡܰܫܠܡܳܢܽܘܬ݂ܳܐ",
            dt2: "",
            dt1: "Islam",
            sy9: "",
            lt10: "țayoyutho",
            sy8: "",
            sy5: "ܛܰܝܳܝܽܘܬ݂ܳܐ",
            lt12: "",
            id: "2248",
            dt3: "",
            lt11: "mashlmonutho",
            sy6: "ܡܫܠܡܢܘܬܐ",
            sy4: "ܛܝܝܘܬܐ"
        },
        {
            dt1: "Smartphone",
            sy6: "ܬܠܝܦܘܢ",
            sy8: "",
            lt12: "",
            sy9: "",
            dt3: "",
            lt11: "telifun",
            sy5: "ܣܡܰܪܬܦܳܢ",
            dt2: "",
            id: "4174",
            sy4: "ܣܡܪܬܦܢ",
            sy7: "ܬܶܠܺܝܦܽܘܢ",
            lt10: "smartfon"
        },
        {
            sy7: "",
            lt11: "",
            sy5: "ܒܽܘܠܳܩܳܐ",
            sy4: "ܒܘܠܩܐ",
            sy8: "",
            lt10: "buloqo",
            dt2: "",
            dt3: "",
            sy9: "",
            sy6: "",
            dt1: "Treffen",
            id: "4555",
            lt12: ""
        },
        {
            lt10: "qenț",
            dt3: "",
            lt11: "qnaț",
            sy7: "ܩܢܰܛ",
            dt2: "",
            lt12: "",
            sy4: "ܩܢܛ",
            dt1: "riskieren",
            sy8: "",
            sy6: "ܩܢܛ",
            sy9: "",
            id: "3727",
            sy5: "ܩܶܢܛ"
        },
        {
            sy4: "ܡܚܘܬܐ ܚܐܪܬܐ",
            lt11: "",
            lt10: "mĥuto ĥirto",
            id: "1383",
            sy7: "",
            sy6: "",
            dt3: "",
            dt1: "Freistoß",
            lt12: "",
            sy5: "ܡܚܽܘܬܳܐ ܚܺܐܪܬܳܐ",
            dt2: "Freistoss",
            sy9: "",
            sy8: ""
        },
        {
            sy5: "ܙܰܟܺܝܪ",
            lt11: "ܙܟܝܪ",
            lt10: "ܙܟܝܪ",
            sy8: "",
            dt1: "Zakir",
            dt3: "",
            sy4: "ܙܟܝܪ",
            sy6: "",
            sy7: "",
            sy9: "",
            lt12: "",
            id: "5250",
            dt2: ""
        },
        {
            lt11: "",
            lt12: "",
            sy9: "",
            id: "1369",
            lt10: "tagoruth d`neshe",
            sy5: "ܬܰܓܳܪܽܘܬ݂ ܢܶܫ̈ܶܐ",
            dt3: "",
            dt1: "Frauenhandel",
            dt2: "",
            sy6: "",
            sy8: "",
            sy4: "ܬܓܪܘܬ ܢܫܐ",
            sy7: ""
        },
        {
            dt3: "",
            dt1: "Mittwoch",
            id: "3066",
            sy4: "ܐܪܒܥܐ ܒܫܒܐ",
            sy7: "",
            lt11: "",
            lt12: "",
            lt10: "arbĉo bshabo",
            sy6: "",
            dt2: "",
            sy5: "ܐܰܪܒܥܳܐ ܒܫܰܒܳܐ",
            sy9: "",
            sy8: ""
        },
        {
            sy6: "ܐܓܝܪܬܐ",
            lt11: "agirto",
            sy5: "ܐܰܓ݂ܪܳܐ",
            dt3: "",
            lt12: "",
            sy4: "ܐܓܪܐ",
            sy7: "ܐܰܓܺܝܪܬܳܐ",
            dt2: "Gage",
            lt10: "ağro",
            id: "1527",
            dt1: "Gehalt",
            sy9: "",
            sy8: ""
        },
        {
            lt11: "",
            sy9: "",
            dt3: "",
            dt2: "",
            sy4: "ܟܘܪܣܝܐ",
            sy7: "",
            sy6: "",
            lt12: "",
            sy8: "",
            dt1: "Stuhl",
            sy5: "ܟܽܘܪܣܝܳܐ",
            lt10: "kursyo",
            id: "4301"
        },
        {
            sy9: "",
            lt11: "ܒܪܕܝܨܢ",
            id: "445",
            sy8: "",
            dt1: "Bardaisan",
            sy5: "ܒܰܪܕܰܝܨܰܢ",
            sy6: "",
            dt2: "",
            sy4: "ܒܪܕܝܨܢ",
            lt10: "ܒܪܕܝܨܢ",
            lt12: "",
            sy7: "",
            dt3: ""
        },
        {
            sy9: "",
            sy7: "",
            sy5: "ܢܶܒܗܳܢܳܝܳܐ",
            dt1: "originell",
            lt12: "",
            sy8: "",
            sy4: "ܢܒܗܢܝܐ",
            lt10: "nebhonoyo",
            lt11: "",
            sy6: "",
            dt3: "",
            dt2: "",
            id: "3390"
        },
        {
            id: "2758",
            sy7: "",
            lt10: "arbubitho",
            sy4: "ܐܪܒܘܒܝܬܐ",
            dt1: "Leiste",
            lt11: "",
            sy9: "",
            lt12: "",
            dt3: "",
            sy5: "ܐܰܪܒܽܘܒܺܝܬ݂ܳܐ",
            dt2: "menschliche Leiste",
            sy6: "",
            sy8: ""
        },
        {
            dt2: "",
            lt11: "ܗܕܝ",
            lt12: "",
            sy5: "ܗܰܕܺܝ",
            id: "1809",
            lt10: "ܗܕܝ",
            sy9: "",
            sy4: "ܗܕܝ",
            dt3: "",
            sy8: "",
            sy6: "",
            sy7: "",
            dt1: "Hadi"
        },
        {
            id: "4270",
            dt3: "",
            lt12: "",
            dt1: "Stier",
            sy9: "",
            sy6: "",
            dt2: "Ochse",
            sy4: "ܬܘܪܐ",
            sy5: "ܬܰܘܪܳܐ",
            lt11: "",
            sy7: "",
            lt10: "tauro",
            sy8: ""
        },
        {
            sy7: "",
            lt11: "",
            lt10: "yah",
            lt12: "",
            sy5: "ܝܰܗ",
            sy6: "",
            dt2: "",
            sy8: "",
            dt1: "Jahwe",
            sy4: "ܝܗ",
            id: "2281",
            sy9: "",
            dt3: ""
        },
        {
            sy9: "",
            lt12: "",
            dt3: "",
            sy4: "ܩܘܪܝܐ",
            lt11: "qura",
            dt1: "Fräulein",
            id: "6096",
            sy8: "",
            sy6: "ܩܘܪܐ",
            sy5: "ܩܽܘܪܺܝܰܐ",
            sy7: "ܩܽܘܪܰܐ",
            lt10: "qurya",
            dt2: ""
        },
        {
            lt11: "",
            sy7: "",
            dt2: "",
            dt3: "",
            sy5: "ܦܰܓܶܕ",
            sy4: "ܦܓܕ",
            id: "5551",
            sy6: "",
            sy8: "",
            sy9: "",
            lt10: "faged",
            dt1: "abbremsen",
            lt12: ""
        },
        {
            lt10: "doĥel men mayo",
            lt12: "",
            dt3: "",
            id: "5009",
            sy7: "ܙܳܐܝܰܥ ܡܶܢ ܡܰܝܳܐ",
            lt11: "zoyaĉ men mayo",
            sy8: "",
            sy4: "ܕܚܠ ܡܢ ܡܝܐ",
            sy9: "",
            dt1: "wasserscheu",
            sy6: "ܙܐܝܥ ܡܢ ܡܝܐ",
            dt2: "",
            sy5: "ܕܳܚܶܠ ܡܶܢ ܡܰܝܳܐ"
        },
        {
            sy7: "",
            sy4: "ܦܠܟܐܢܝܐ",
            sy6: "",
            dt2: "",
            sy9: "",
            dt1: "vulkanisch",
            sy5: "ܦܳܠܟܰܐܢܳܝܳܐ",
            dt3: "",
            lt11: "",
            id: "4913",
            lt10: "folkanoyo",
            lt12: "",
            sy8: ""
        },
        {
            sy7: "ܩܶܢܛܳܝܽܘܬ݂ܳܐ",
            dt2: "Risiko",
            sy6: "ܩܢܛܝܘܬܐ",
            lt11: "qențoyutho",
            sy5: "ܩܶܢܛܳܐ",
            id: "1513",
            sy4: "ܩܢܛܐ",
            lt12: "",
            dt1: "Gefahr",
            sy9: "",
            lt10: "qențo",
            dt3: "",
            sy8: ""
        },
        {
            sy9: "",
            dt3: "",
            sy5: "ܨܰܦܝܽܘܬܰܐ",
            dt1: "Safyuta",
            lt12: "",
            id: "3801",
            lt10: "ܨܦܝܘܬܐ",
            sy8: "",
            sy4: "ܨܦܝܘܬܐ",
            sy7: "",
            dt2: "",
            lt11: "ܨܦܝܘܬܐ",
            sy6: ""
        },
        {
            lt12: "",
            sy4: "ܒܝܬܐ",
            sy7: "",
            dt1: "Haus",
            sy8: "",
            dt3: "",
            sy9: "",
            sy5: "ܒܰܝܬܳܐ",
            dt2: "",
            lt10: "bayto",
            id: "1893",
            sy6: "",
            lt11: ""
        },
        {
            sy6: "",
            sy5: "ܥܰܠ ܚܕܳܕܳܐ",
            dt1: "nacheinander",
            id: "3155",
            lt10: "ĉal ĥđođe",
            lt11: "",
            dt2: "",
            sy4: "ܥܠ ܚܕܕܐ",
            sy8: "",
            sy9: "",
            lt12: "",
            dt3: "",
            sy7: ""
        },
        {
            dt1: "in jener Zeit",
            lt10: "b`hau zabno",
            sy6: "",
            lt11: "",
            sy5: "ܒܗܰܘ ܙܰܒܢܳܐ",
            id: "2185",
            sy9: "",
            sy4: "ܒܗܘ ܙܒܢܐ",
            dt2: "",
            dt3: "",
            sy8: "",
            lt12: "",
            sy7: ""
        },
        {
            sy5: "ܩܪܺܝܬ݂ܳܐ ܟܶܪܒܽܘܪܰܢ",
            lt11: "kerburan",
            dt1: "Dorf Kerburan",
            sy7: "ܟܶܪܒܽܘܪܰܢ",
            lt10: "qritho kerburan",
            lt12: "",
            sy4: "ܩܪܝܬܐ ܟܪܒܘܪܢ",
            dt2: "Kerburan",
            dt3: "",
            sy9: "",
            sy6: "ܟܪܒܘܪܢ",
            sy8: "",
            id: "939"
        },
        {
            sy7: "",
            sy9: "",
            sy8: "",
            sy4: "ܡܨܕܪܢܐ",
            dt3: "",
            sy6: "",
            dt2: "",
            lt10: "maŝdrono",
            lt11: "",
            dt1: "schwindelerregend",
            sy5: "ܡܰܨܕܪܳܢܳܐ",
            id: "6212",
            lt12: ""
        },
        {
            lt10: "wa`shbuqlan ĥaubeyn wa`ĥțohayn",
            sy9: "",
            sy7: "",
            dt2: "",
            sy5: "ܘܰܫܒܽܘܩܠܰܢ ܚܰܘܒܶܝ̈ܢ ܘܰܚܛܳܗܰܝ̈ܢ",
            dt1: "Vergib uns unsere Sünden",
            sy4: "ܘܫܒܘܩܠܢ ܚܘܒܝܢ ܘܚܛܗܝܢ",
            sy6: "",
            lt12: "",
            id: "4736",
            lt11: "",
            sy8: "",
            dt3: ""
        },
        {
            lt10: "nuqzo d`ĥyoro",
            lt11: "",
            dt3: "",
            sy6: "",
            sy5: "ܢܽܘܩܙܳܐ ܕܚܝܳܪܳܐ",
            sy8: "",
            sy4: "ܢܘܩܙܐ ܕܚܝܪܐ",
            sy7: "",
            dt2: "",
            dt1: "Blickpunkt",
            sy9: "",
            id: "647",
            lt12: ""
        },
        {
            id: "1945",
            dt2: "warm werden",
            sy4: "ܚܡ",
            sy7: "",
            lt10: "ĥam",
            dt3: "",
            sy8: "",
            lt11: "",
            lt12: "",
            dt1: "heiß werden",
            sy9: "",
            sy5: "ܚܰܡ",
            sy6: ""
        },
        {
            id: "4222",
            sy5: "ܓܠܽܘܦ ܩܰܢܝܳܐ",
            dt3: "",
            lt11: "",
            sy6: "",
            dt1: "Spitze den Stift an",
            sy8: "",
            sy4: "ܓܠܘܦ ܩܢܝܐ",
            dt2: "",
            lt12: "",
            lt10: "gluf qanyo",
            sy7: "",
            sy9: ""
        },
        {
            sy9: "",
            sy4: "ܕܰܘܩܳܐ",
            dt1: "Compact Disc",
            dt2: "CD",
            sy8: "",
            dt3: "",
            sy6: "",
            sy5: "ܕܰܘܩܳܐ",
            lt10: "dauqo",
            lt11: "",
            lt12: "",
            sy7: "",
            id: "5416"
        },
        {
            sy4: "ܙܘܥܐ",
            dt1: "Zeichen",
            sy7: "",
            sy5: "ܙܰܘܥܳܐ",
            dt2: "",
            dt3: "",
            id: "5277",
            lt10: "zauĉo",
            sy8: "",
            lt11: "",
            lt12: "",
            sy6: "",
            sy9: ""
        },
        {
            sy7: "ܕܶܠܦܳܐ",
            lt12: "țuftho",
            sy5: "ܕܠܳܦܳܐ",
            dt3: "",
            lt10: "dlofo",
            dt2: "",
            id: "4579",
            sy8: "ܛܘܦܬܐ",
            sy6: "ܕܠܦܐ",
            sy4: "ܕܠܦܐ",
            dt1: "Tropfen",
            lt11: "delfo",
            sy9: "ܛܽܘܦܬ݂ܳܐ"
        },
        {
            dt3: "",
            dt2: "",
            lt11: "",
            sy6: "",
            sy8: "",
            lt12: "",
            dt1: "Arbeitserklärung",
            lt10: "shudoĉ shedtho",
            sy7: "",
            id: "6019",
            sy5: "ܫܽܘܘܕܳܥ ܫܶܕܬ݂ܳܐ",
            sy4: "ܫܘܕܥ ܫܕܬܐ",
            sy9: ""
        },
        {
            sy5: "ܕܰܒܺܝܩܳܝܳܐ",
            lt12: "",
            dt1: "klebend",
            sy8: "",
            sy9: "",
            sy7: "",
            id: "2505",
            dt3: "",
            lt10: "dabiqoyo",
            lt11: "",
            sy6: "",
            sy4: "ܕܒܝܩܝܐ",
            dt2: ""
        },
        {
            lt12: "",
            dt3: "Gratuliere",
            sy7: "ܒܪܺܝܟ݂ܳܐ",
            lt10: "tahnitho",
            lt11: "brikho",
            dt1: "Glückwunsch",
            dt2: "Gratulation",
            sy6: "ܒܪܝܟܐ",
            sy9: "",
            sy8: "",
            sy4: "ܬܗܢܝܬܐ",
            id: "1672",
            sy5: "ܬܰܗܢܺܝܬ݂ܳܐ"
        },
        {
            lt11: "",
            id: "2863",
            dt3: "",
            sy6: "",
            lt10: "oyar",
            sy5: "ܐܳܐܰܪ",
            sy8: "",
            lt12: "",
            dt2: "",
            sy4: "ܐܐܪ",
            dt1: "Luft",
            sy7: "",
            sy9: ""
        },
        {
            sy9: "ܟܪܳܒܳܐ",
            lt11: "nŝobo",
            sy7: "ܢܨܳܒܳܐ",
            dt3: "Feldbestellung",
            id: "5911",
            sy5: "ܙܰܪܳܥܽܘܬ݂ܳܐ",
            dt1: "Anbau",
            sy6: "ܢܨܒܐ",
            lt10: "zaroĉutho",
            sy4: "ܙܪܥܘܬܐ",
            dt2: "Feldbau",
            lt12: "krobo",
            sy8: "ܟܪܒܐ"
        },
        {
            sy9: "",
            lt12: "",
            dt3: "",
            sy5: "ܕܝܳܕܰܥ ܟܽܘܠ",
            dt1: "allwissend",
            sy4: "ܕܝܕܥ ܟܘܠ",
            lt11: "",
            sy6: "",
            dt2: "",
            sy7: "",
            lt10: "dyodaĉ kul",
            sy8: "",
            id: "5849"
        },
        {
            lt12: "",
            sy4: "ܐܫܬܥܢܝܐ",
            sy5: "ܐܶܫܬܥܶܢܝܳܐ",
            sy6: "",
            sy7: "",
            id: "4211",
            dt3: "",
            dt2: "",
            sy9: "",
            lt11: "",
            lt10: "eshtĉenyo",
            sy8: "",
            dt1: "Spiel"
        },
        {
            sy8: "",
            sy9: "",
            id: "2239",
            lt11: "",
            sy7: "",
            lt12: "",
            dt3: "",
            dt1: "irgendwo",
            sy5: "ܒܕܽܘܟܬ݂ܳܐ",
            sy6: "",
            sy4: "ܒܕܘܟܬܐ",
            lt10: "b`duktho",
            dt2: ""
        },
        {
            sy9: "",
            lt10: "ܒܪܬܫܢܬܐ",
            lt11: "ܒܪܬܫܢܬܐ",
            sy6: "",
            sy5: "ܒܰܪ̱ܬܫܰܢ̱ܬܳܐ",
            dt2: "",
            dt1: "Bathschato",
            sy7: "",
            sy4: "ܒܪ̱ܬܫܢ̱ܬܐ",
            sy8: "",
            lt12: "",
            dt3: "",
            id: "504"
        },
        {
            sy9: "",
            sy4: "ܒܪ ܐܠܗܐ",
            dt3: "",
            sy5: "ܒܰܪ ܐܰܠܳܗܳܐ",
            sy7: "ܒܪܳܐ ܕܰܐܠܳܗܳܐ",
            lt10: "bar aloho",
            dt1: "Gottessohn",
            lt11: "bro d`aloho",
            sy8: "",
            dt2: "Gottes Sohn",
            lt12: "",
            sy6: "ܒܪܐ ܕܐܠܗܐ",
            id: "1706"
        },
        {
            sy6: "",
            sy4: "ܡܓܕܠܝܬܐ",
            lt12: "",
            sy8: "",
            dt1: "Magdalena",
            sy9: "",
            sy7: "",
            lt11: "ܡܓܕܠܢܐ",
            id: "2886",
            dt2: "",
            dt3: "",
            lt10: "ܡܓܕܠܢܐ",
            sy5: "ܡܓܕܠܝܬܐ"
        },
        {
            sy6: "ܕܠܝܠܐ",
            dt2: "Delila",
            dt3: "",
            sy4: "ܕܠܝܠܐ",
            sy7: "ܕܶܠܺܝܠܰܐ",
            sy5: "ܕܰܠܺܝܠܰܐ",
            lt10: "ܕܠܝܠܐ",
            sy8: "",
            dt1: "Dalila",
            lt11: "ܕܠܝܠܐ",
            lt12: "ܕܠܝܠܐ",
            sy9: "",
            id: "789"
        },
        {
            id: "1878",
            sy8: "",
            lt11: "ܚܫܒܝܐ",
            sy6: "",
            dt1: "Haschabja",
            dt3: "",
            dt2: "",
            lt12: "",
            sy7: "",
            lt10: "ܚܫܒܝܐ",
            sy5: "ܚܰܫܰܒܝܰܐ",
            sy9: "",
            sy4: "ܚܫܒܝܐ"
        },
        {
            lt12: "",
            sy4: "ܒܥܡܪܝ",
            id: "2187",
            sy5: "ܒܥܶܡܺܪܝ",
            dt3: "",
            sy8: "",
            lt11: "",
            dt1: "in meinem Alter",
            lt10: "b`ĉemri",
            dt2: "",
            sy9: "",
            sy6: "",
            sy7: ""
        },
        {
            sy8: "ܦܬܚܐ",
            sy5: "ܦܬܰܚ",
            dt1: "öffnen",
            lt12: "ftoĥo",
            sy4: "ܦܬܚ",
            dt3: "",
            dt2: "",
            lt10: "ftaĥ",
            sy7: "ܦܳܬܰܚ",
            sy6: "ܦܬܚ",
            lt11: "fotaĥ",
            id: "3353",
            sy9: "ܦܬܳܚܳܐ"
        },
        {
            lt12: "",
            dt2: "",
            sy8: "",
            id: "3333",
            sy4: "ܦܢܬܐ",
            lt11: "",
            sy5: "ܦܰܢܬ݂ܳܐ",
            sy7: "",
            dt3: "",
            sy9: "",
            lt10: "fantho",
            sy6: "",
            dt1: "Oberteil des Fußes"
        },
        {
            dt3: "",
            lt11: "",
            sy8: "",
            dt2: "",
            sy6: "",
            sy9: "",
            dt1: "treu",
            lt12: "",
            sy7: "",
            sy5: "ܫܰܪܺܝܪܳܐ",
            id: "4563",
            sy4: "ܫܪܝܪܐ",
            lt10: "shariro"
        },
        {
            lt11: "țaruzutho",
            lt12: "",
            dt2: "",
            sy6: "ܛܪܘܙܘܬܐ",
            sy5: "ܛܰܪܽܘܕܽܘܬ݂ܳܐ",
            sy7: "ܛܰܪܽܘܙܽܘܬ݂ܳܐ",
            lt10: "țarudutho",
            sy9: "",
            dt1: "Schielauge",
            id: "3940",
            sy4: "ܛܪܘܕܘܬܐ",
            sy8: "",
            dt3: ""
        },
        {
            sy7: "",
            lt11: "",
            sy9: "",
            lt10: "nomuso d`ağro",
            id: "3020",
            dt3: "",
            dt2: "",
            dt1: "Mietrecht",
            sy8: "",
            sy5: "ܢܳܡܽܘܣܳܐ ܕܐܰܓ݂ܪܳܐ",
            sy6: "",
            lt12: "",
            sy4: "ܢܡܘܣܐ ܕܐܓܪܐ"
        },
        {
            lt11: "",
            sy7: "",
            sy4: "ܡܥܕܠܢܐ",
            lt12: "",
            id: "6277",
            dt2: "",
            sy8: "",
            sy5: "ܡܥܰܕܠܳܢܳܐ",
            dt3: "",
            lt10: "mĉadlono",
            sy9: "",
            dt1: "Zensurbeamter",
            sy6: ""
        },
        {
            sy9: "",
            sy7: "",
            sy6: "",
            id: "3144",
            sy5: "ܢܰܥܡܳܢ",
            sy4: "ܢܥܡܢ",
            dt3: "",
            lt10: "ܢܥܡܢ",
            lt12: "",
            dt2: "",
            lt11: "ܢܥܡܢ",
            sy8: "",
            dt1: "Naamon"
        },
        {
            sy9: "",
            dt1: "abwenden",
            sy8: "",
            sy7: "ܕܥܰܪ",
            lt10: "hfakh",
            sy6: "ܕܥܪ",
            id: "122",
            lt12: "",
            lt11: "dĉar",
            sy5: "ܗܦܰܟ݂",
            dt3: "zurückkehren",
            sy4: "ܗܦܟ",
            dt2: "abkehren"
        },
        {
            sy8: "",
            lt12: "",
            dt1: "Raupe",
            dt2: "",
            sy5: "ܡܳܫܽܘܛܳܐ",
            lt11: "",
            lt10: "moshuțo",
            dt3: "",
            sy7: "",
            sy6: "",
            id: "3624",
            sy4: "ܡܫܘܛܐ",
            sy9: ""
        },
        {
            sy8: "",
            lt11: "rethmo",
            lt10: "mlabkhono",
            dt2: "",
            dt3: "",
            id: "5809",
            lt12: "",
            sy4: "ܡܠܒܟܢܐ",
            sy7: "ܪܶܬ݂ܡܳܐ",
            sy5: "ܡܠܰܒܟ݂ܳܢܳܐ",
            sy9: "",
            sy6: "ܪܬܡܐ",
            dt1: "Akzent"
        },
        {
            sy6: "ܫܘܪܛܐ",
            sy7: "ܫܽܘܪܛܳܐ",
            sy4: "ܐܦܝܪܡܐ",
            sy9: "",
            dt1: "Hülsenfrucht",
            sy8: "",
            lt11: "shurțo",
            id: "6038",
            dt3: "",
            dt2: "",
            lt12: "",
            sy5: "ܐܰܦܺܝܪܡܳܐ",
            lt10: "afirmo"
        },
        {
            lt12: "",
            dt3: "",
            dt2: "",
            dt1: "nicht vom Menschen",
            sy7: "",
            sy4: "ܠܬܝܐ ܡܐ ܐܢܫܐ",
            sy8: "",
            sy5: "ܠܰܬܝܳܐ ܡܶܐ ܐ̱ܢܳܫܳܐ",
            sy6: "",
            lt10: "latyo me nosho",
            lt11: "",
            id: "3263",
            sy9: ""
        },
        {
            dt1: "Gabar",
            id: "1446",
            lt11: "ܓܒܪ",
            sy9: "",
            dt3: "",
            dt2: "",
            sy6: "ܓܒܪ",
            sy7: "ܓܰܒܳܪ",
            sy8: "",
            sy4: "ܓܒܪ",
            lt12: "ܓܒܪ",
            lt10: "ܓܒܪ",
            sy5: "ܓܰܒܰܪ"
        },
        {
            sy4: "ܗܝܒܐ",
            sy5: "ܗܺܝܒܰܐ",
            sy7: "",
            sy6: "",
            lt10: "ܗܝܒܐ",
            id: "1995",
            dt3: "",
            dt2: "",
            dt1: "Hiba",
            sy9: "",
            sy8: "",
            lt11: "ܗܝܒܐ",
            lt12: ""
        },
        {
            sy8: "",
            lt10: "ܢܫܪܝܬܐ",
            dt2: "",
            sy6: "",
            sy9: "",
            sy5: "ܢܶܫܪܰܝܬܰܐ",
            lt11: "ܢܫܪܝܬܐ",
            sy7: "",
            lt12: "",
            sy4: "ܢܫܪܝܬܐ",
            dt3: "",
            dt1: "Neschrayta",
            id: "3248"
        },
        {
            sy9: "",
            id: "3903",
            lt11: "",
            dt1: "scharf",
            sy8: "",
            sy6: "",
            dt3: "",
            dt2: "",
            sy5: "ܚܰܪܺܝܦܳܐ",
            lt12: "",
            lt10: "ĥarifo",
            sy7: "",
            sy4: "ܚܪܝܦܐ"
        },
        {
            lt11: "",
            sy7: "",
            sy5: "ܕܩܰܠܺܝܠ ܒܟܽܠ",
            dt2: "",
            sy9: "",
            id: "185",
            dt3: "",
            sy8: "",
            dt1: "allerwenigste",
            sy6: "",
            lt12: "",
            sy4: "ܕܩܠܝܠ ܒܟܠ",
            lt10: "d`qalil b`kul"
        },
        {
            dt3: "",
            sy8: "",
            sy6: "",
            dt1: "Vorderzahn",
            sy9: "",
            dt2: "",
            lt12: "",
            id: "4899",
            sy4: "ܨܪܘܡܬܐ",
            lt11: "",
            lt10: "ŝorumto",
            sy5: "ܨܳܪܽܘܡܬܳܐ",
            sy7: ""
        },
        {
            lt10: "ĉamonoyo",
            dt2: "",
            dt3: "",
            sy7: "",
            sy6: "",
            lt12: "",
            id: "3492",
            sy9: "",
            lt11: "",
            sy8: "",
            dt1: "Populist",
            sy5: "ܥܰܡܡܳܢܳܝܳܐ",
            sy4: "ܥܡܡܢܝܐ"
        },
        {
            dt2: "Elischama",
            sy4: "ܐܠܝܫܡܥ",
            lt11: "ܐܠܝܫܡܥ",
            sy5: "ܐܶܠܺܝܫܰܡܰܥ",
            sy7: "ܐܶܠܺܝܫܡܰܥ",
            sy6: "ܐܠܝܫܡܥ",
            dt1: "Elischamah",
            sy8: "",
            lt12: "ܐܠܝܫܡܥ",
            id: "1081",
            lt10: "ܐܠܝܫܡܥ",
            sy9: "",
            dt3: ""
        },
        {
            sy6: "",
            sy7: "",
            sy5: "ܓܰܒܳܐ ܕܐܰܒܳܐ",
            dt2: "",
            sy9: "",
            lt11: "",
            sy4: "ܓܒܐ ܕܐܒܐ",
            dt3: "",
            lt12: "",
            lt10: "gabo d`abo",
            sy8: "",
            dt1: "Vaterseite",
            id: "4711"
        },
        {
            dt1: "Balthasar",
            sy6: "ܒܠܛܫܨܪ",
            sy4: "ܒܠܬܨܪ",
            sy5: "ܒܰܠܬܰܨܰܪ",
            lt12: "ܒܠܛܫܨܪ",
            lt11: "ܒܠܬܨܪ",
            dt3: "",
            sy9: "",
            lt10: "ܒܠܬܨܪ",
            sy7: "ܒܶܠܛܫܰܨܰܪ",
            sy8: "",
            dt2: "",
            id: "431"
        },
        {
            lt10: "gauzo",
            dt1: "Nuss",
            sy5: "ܓܰܘܙܳܐ",
            dt3: "",
            sy9: "",
            sy7: "",
            sy8: "",
            id: "3325",
            sy6: "",
            dt2: "Nuß",
            lt11: "",
            lt12: "",
            sy4: "ܓܘܙܐ"
        },
        {
            sy8: "",
            dt1: "schlagen",
            dt3: "",
            sy5: "ܡܚܳܐ",
            sy6: "ܡܚܐ",
            dt2: "",
            sy7: "ܡܳܚܶܐ",
            sy4: "ܡܚܐ",
            sy9: "",
            lt10: "mĥo",
            lt11: "moĥe",
            lt12: "",
            id: "3963"
        },
        {
            dt2: "",
            lt11: "",
            sy6: "",
            sy8: "",
            lt12: "",
            dt3: "",
            sy5: "ܬܳܐܘܳܪܺܝܰܐ ܘ ܣܳܥܽܘܪܽܘܬ݂ܳܐ",
            dt1: "Theorie und Praxis",
            id: "4463",
            sy9: "",
            sy4: "ܬܐܘܪܝܐ ܘ ܣܥܘܪܘܬܐ",
            lt10: "teworiya u soĉurutho",
            sy7: ""
        },
        {
            lt10: "melath ĥuthomo",
            dt1: "Schlusswort",
            id: "3979",
            sy9: "",
            dt3: "",
            lt12: "",
            sy7: "",
            sy5: "ܡܶܠܰܬ݂ ܚܽܘܬܳܡܳܐ",
            dt2: "Schlußwort",
            sy8: "",
            lt11: "",
            sy6: "",
            sy4: "ܡܠܬ ܚܘܬܡܐ"
        },
        {
            sy5: "ܙܗܺܝܪܽܘܬ݂ܳܐ",
            id: "4904",
            dt1: "Vorsicht",
            sy6: "",
            sy9: "",
            dt3: "",
            sy7: "",
            sy4: "ܙܗܝܪܘܬܐ",
            lt10: "zhirutho",
            sy8: "",
            lt11: "",
            dt2: "",
            lt12: ""
        },
        {
            dt1: "Bügeltisch",
            sy6: "",
            dt3: "",
            sy5: "ܛܶܒܠܽܝܬ݂ ܡܰܟܘܰܝܬܳܐ",
            sy8: "",
            lt11: "",
            lt10: "țeblith makwayto",
            sy7: "",
            id: "736",
            sy4: "ܛܒܠܝܬ ܡܟܘܝܬܐ",
            dt2: "",
            lt12: "",
            sy9: ""
        },
        {
            sy6: "",
            sy9: "",
            sy5: "ܫܝܽܘܠ",
            lt12: "",
            lt10: "shyul",
            sy8: "",
            id: "3926",
            dt2: "",
            dt1: "Scheol",
            sy4: "ܫܝܘܠ",
            sy7: "",
            dt3: "",
            lt11: ""
        },
        {
            sy4: "ܣܓܝ",
            sy6: "ܓܠܒܐ",
            id: "4831",
            lt12: "",
            sy8: "",
            dt1: "viel",
            sy7: "ܓ݂ܰܠܰܒܶܐ",
            sy9: "",
            dt3: "mehrere",
            dt2: "viele",
            sy5: "ܣܰܓܺܝ",
            lt10: "sagi",
            lt11: "ğalabe"
        },
        {
            id: "RgKJUna5VJNkwuYNJ756",
            sy4: "ܟܪܝܐ ܝܐ",
            sy7: "",
            dt1: "Er ist zu kurz",
            "date": {
                "seconds": 1590849197,
                "nanoseconds": 582000000
            },
            sy5: "ܟܰܪܝܳܐ ܝܳܐ",
            sy9: "",
            sy6: "",
            lt11: "",
            sy8: "",
            lt12: "",
            dt3: "",
            dt2: "",
            lt10: "Karyo yo"
        },
        {
            lt11: "henyukho",
            dt2: "Chauffeur",
            id: "1234",
            sy6: "ܗܢܝܘܟܐ",
            sy9: "",
            dt3: "",
            sy7: "ܗܶܢܝܽܘܟ݂ܳܐ",
            sy4: "ܕܒܘܪܐ",
            sy8: "",
            dt1: "Fahrer",
            sy5: "ܕܳܒܽܘܪܳܐ",
            lt12: "",
            lt10: "doburo"
        },
        {
            lt12: "",
            dt1: "zukünftig",
            sy6: "",
            dt3: "",
            sy7: "",
            lt10: "daĉtiđ",
            sy4: "ܕܥܬܝܕ",
            id: "5367",
            sy8: "",
            dt2: "demnächst",
            lt11: "",
            sy9: "",
            sy5: "ܕܰܥܬܺܝܕ"
        },
        {
            sy5: "ܗܶܠܶܢ",
            lt10: "ܗܠܢ",
            lt11: "ܗܠܢ",
            sy8: "",
            id: "1951",
            sy7: "",
            sy6: "",
            dt3: "",
            sy9: "",
            dt1: "Helen",
            dt2: "",
            sy4: "ܗܠܢ",
            lt12: ""
        },
        {
            sy4: "ܬܠܦܢ",
            lt11: "mtalfen",
            sy6: "ܡܬܠܦܢ",
            dt3: "",
            lt10: "talfen",
            lt12: "",
            sy5: "ܬܰܠܦܶܢ",
            sy7: "ܡܬܰܠܦܶܢ",
            sy9: "",
            id: "4429",
            sy8: "",
            dt1: "telefonieren",
            dt2: "anrufen"
        },
        {
            dt3: "",
            sy6: "",
            lt11: "ܚܬܬܐܢܐ",
            sy4: "ܚܬܬܐܢܐ",
            sy9: "",
            id: "1887",
            dt1: "Hattana",
            lt12: "",
            dt2: "",
            lt10: "ܚܬܬܐܢܐ",
            sy5: "ܚܰܬܬܰܐܢܰܐ",
            sy7: "",
            sy8: ""
        },
        {
            sy7: "ܒܽܘܛܳܠܳܐ",
            sy8: "",
            sy4: "ܩܘܛܥܐ",
            lt10: "quțoĉo",
            sy5: "ܩܽܘܛܳܥܳܐ",
            dt2: "",
            dt1: "Abbruch",
            lt12: "",
            dt3: "",
            sy6: "ܒܘܛܠܐ",
            id: "61",
            lt11: "buțolo",
            sy9: ""
        },
        {
            lt12: "",
            sy8: "",
            lt11: "waso",
            sy7: "ܘܰܣܳܐ",
            sy9: "",
            lt10: "gurbo",
            sy5: "ܓܽܘܪܒܳܐ",
            sy6: "ܘܣܐ",
            dt1: "Socke",
            id: "4179",
            sy4: "ܓܘܪܒܐ",
            dt2: "Strumpf",
            dt3: ""
        },
        {
            sy8: "",
            sy4: "ܥܠ",
            id: "3330",
            sy5: "ܥܰܠ",
            dt1: "oben",
            sy7: "ܠܥܶܠ",
            dt2: "auf",
            dt3: "",
            lt10: "ĉal",
            lt11: "lĉel",
            sy9: "",
            lt12: "",
            sy6: "ܠܥܠ"
        },
        {
            dt3: "",
            lt11: "methfarĉonutho",
            sy6: "ܡܬܦܪܥܢܘܬܐ",
            sy7: "ܡܶܬ݂ܦܰܪܥܳܢܽܘܬ݂ܳܐ",
            sy5: "ܦܽܘܪܥܳܢܳܐ ܡܶܬ݂ܚܳܢܳܝܳܐ ܒܺܝܙܶܦܬܳܐ",
            dt1: "Abzahlung",
            dt2: "",
            sy8: "",
            sy9: "",
            lt12: "",
            lt10: "furĉono methĥonoyo bizefto",
            id: "5719",
            sy4: "ܦܘܪܥܢܐ ܡܬܚܢܝܐ ܒܝܙܦܬܐ"
        },
        {
            sy9: "",
            sy5: "ܚܽܘܛܪܳܐ",
            sy6: "",
            sy8: "",
            lt11: "",
            dt1: "Stock",
            lt10: "ĥuțro",
            id: "4280",
            sy7: "",
            sy4: "ܚܘܛܪܐ",
            lt12: "",
            dt3: "",
            dt2: ""
        },
        {
            id: "5077",
            dt2: "abendländisch",
            sy9: "",
            sy4: "ܡܥܪܒܝܐ",
            lt11: "",
            dt1: "westlich",
            lt12: "",
            sy6: "",
            sy5: "ܡܰܥܶܪܒܳܝܳܐ",
            lt10: "maĉerboyo",
            dt3: "",
            sy8: "",
            sy7: ""
        },
        {
            dt3: "",
            sy7: "ܩܰܪܺܝܫܶܐ",
            dt1: "Gürtel",
            lt10: "esor haŝo",
            lt12: "",
            dt2: "",
            sy8: "",
            sy5: "ܐܶܣܳܪ ܚܰܨܳܐ",
            sy6: "ܩܪܝܫܐ",
            sy4: "ܐܣܪ ܚܨܐ",
            id: "1766",
            sy9: "",
            lt11: "qarishe"
        },
        {
            lt11: "",
            sy9: "",
            dt2: "Acker",
            dt3: "",
            lt12: "",
            sy6: "",
            id: "1282",
            sy8: "",
            sy7: "",
            sy5: "ܚܰܩܠܳܐ",
            lt10: "ĥaqlo",
            dt1: "Feld",
            sy4: "ܚܩܠܐ"
        },
        {
            lt10: "nham",
            sy7: "",
            sy5: "ܢܗܰܡ",
            dt2: "",
            sy9: "",
            lt11: "",
            dt1: "brüllen",
            id: "719",
            dt3: "",
            sy4: "ܢܗܡ",
            lt12: "",
            sy8: "",
            sy6: ""
        },
        {
            lt11: "ܡܗܕܝܢܐ",
            sy7: "",
            dt2: "",
            sy4: "ܡܗܕܝܢܐ",
            dt3: "",
            sy9: "",
            sy6: "",
            sy5: "ܡܗܰܕܝܳܢܳܐ",
            lt12: "",
            id: "3013",
            dt1: "Mhadyono",
            lt10: "ܡܗܕܝܢܐ",
            sy8: ""
        },
        {
            sy4: "ܐܘܪܫܝܢܐ",
            sy6: "",
            sy8: "",
            sy9: "",
            dt3: "",
            lt12: "",
            sy7: "",
            lt10: "ܐܘܪܫܝܢܐ",
            dt1: "Urschaina",
            dt2: "",
            lt11: "ܐܘܪܫܝܢܐ",
            id: "4700",
            sy5: "ܐܽܘܪܫܰܝܢܳܐ"
        },
        {
            sy4: "ܣܒܐ",
            lt12: "",
            dt2: "",
            dt3: "",
            sy9: "",
            sy5: "ܣܳܒܳܐ",
            sy7: "",
            id: "4178",
            sy6: "",
            lt10: "ܣܒܐ",
            dt1: "Sobo",
            lt11: "ܣܒܐ",
            sy8: ""
        },
        {
            sy6: "ܡܪܩܘܣ",
            id: "2940",
            sy8: "",
            sy9: "",
            dt2: "",
            lt12: "ܡܪܩܘܣ",
            sy4: "ܡܪܩܘܣ",
            dt3: "",
            lt11: "ܡܪܩܘܣ",
            lt10: "ܡܪܩܘܣ",
            sy7: "ܡܰܪܩܳܘܣ",
            dt1: "Markus",
            sy5: "ܡܰܪܩܽܘܣ"
        },
        {
            id: "1420",
            sy8: "",
            lt10: "ekhal qroțin",
            sy7: "ܐܶܟ݂ܰܠ ܫܳܪܽܘܬ݂ܳܐ",
            sy5: "ܐܟܠ ܩܪܛܝܢ",
            sy4: "ܐܶܟ݂ܰܠ ܩܪܳܛܺܝܢ",
            dt1: "frühstücken",
            lt11: "ekhal shorutho",
            dt2: "",
            lt12: "",
            sy6: "ܐܟܠ ܫܪܘܬܐ",
            sy9: "",
            dt3: ""
        },
        {
            dt3: "",
            sy5: "ܨܰܦܽܘܢ ܓܪܳܥܳܐ",
            id: "3609",
            sy9: "",
            sy7: "",
            dt2: "",
            sy8: "",
            dt1: "Rasierseife",
            lt11: "",
            lt10: "ŝafun groĉo",
            lt12: "",
            sy4: "ܨܦܘܢ ܓܪܥܐ",
            sy6: ""
        },
        {
            lt11: "",
            sy4: "ܡܟܬܒܙܒܢܐ ܕܥܠܡܐ",
            sy7: "",
            dt2: "",
            sy6: "",
            sy9: "",
            sy8: "",
            sy5: "ܡܰܟܬܰܒܙܰܒܢܳܐ ܕܥܳܠܡܳܐ",
            dt3: "",
            lt12: "",
            id: "4856",
            dt1: "Volksgeschichte",
            lt10: "maktabzabno d`ĉolmo"
        },
        {
            sy6: "ܦܣܡܐ",
            sy5: "ܙܶܦܶܐ",
            id: "4004",
            lt11: "fesme",
            dt3: "",
            lt12: "",
            dt2: "Schnauzbart",
            sy9: "",
            sy4: "ܙܦܐ",
            lt10: "zefe",
            sy7: "ܦܶܣܡܶܐ",
            dt1: "Schnurrbart",
            sy8: ""
        },
        {
            lt11: "",
            sy6: "",
            dt2: "Lager",
            sy8: "",
            sy5: "ܐܰܙܓܳܐ",
            id: "2449",
            sy7: "",
            dt1: "Keller",
            lt10: "asgo",
            lt12: "",
            dt3: "",
            sy9: "",
            sy4: "ܐܙܓܐ"
        },
        {
            sy6: "",
            dt1: "Abai",
            sy7: "",
            sy9: "",
            lt11: "ܐܒܝ",
            sy5: "ܐܰܒܰܝ",
            sy8: "",
            id: "57",
            sy4: "ܐܒܝ",
            dt2: "Abay",
            lt12: "",
            dt3: "",
            lt10: "ܐܒܝ"
        },
        {
            sy7: "",
            sy6: "",
            dt2: "",
            sy5: "ܩܺܒܽܘܬ݂ܳܐ ܕܺܝܰܬܺܝܩܺܐ",
            lt12: "",
            sy8: "",
            lt11: "",
            id: "6438",
            sy4: "ܩܒܘܬܐ ܕܝܬܝܩܐ",
            dt1: "Bundeslade",
            sy9: "",
            dt3: "",
            lt10: "qibutho diyatiqi"
        },
        {
            sy7: "",
            lt10: "at ith lokh kumatro",
            sy6: "",
            sy5: "ܐܰܢ̱ܬ ܐܺܝܬ݂ ܠܳܟ݂ ܟܽܘܡܰܬ݂ܪܳܐ",
            lt12: "",
            dt3: "",
            dt1: "Du hast eine Birne",
            sy8: "",
            sy9: "",
            dt2: "",
            id: "973",
            sy4: "ܐܢܬ ܐܝܬ ܠܟ ܟܘܡܬܪܐ",
            lt11: ""
        },
        {
            sy9: "",
            id: "5831",
            sy6: "",
            dt1: "Alkoholverbot",
            sy4: "ܟܠܝܢ ܫܟܪܐ",
            sy8: "",
            lt11: "",
            sy5: "ܟܶܠܝܳܢ ܫܰܟ݂ܪܳܐ",
            lt10: "kelyon shakhro",
            sy7: "",
            dt3: "",
            lt12: "",
            dt2: ""
        },
        {
            sy4: "ܦܣܥܬܐ ܕܪܩܕܐ",
            sy7: "",
            lt12: "",
            id: "4385",
            dt2: "",
            lt11: "",
            sy8: "",
            lt10: "fsoĉto d`reqđo",
            sy9: "",
            dt1: "Tanzschritt",
            sy6: "",
            sy5: "ܦܣܳܥܬܳܐ ܕܪܶܩܕܳܐ",
            dt3: ""
        },
        {
            lt11: "",
            sy6: "",
            sy9: "",
            lt10: "mozuno",
            id: "5822",
            sy8: "",
            lt12: "",
            dt3: "",
            dt1: "Alimentation",
            sy7: "",
            sy4: "ܡܙܘܢܐ",
            sy5: "ܡܳܙܽܘܢܳܐ",
            dt2: ""
        },
        {
            lt10: "tagoruth ĉatiqotho",
            sy4: "ܬܓܪܘܬ ܥܬܝܩܬܐ",
            sy8: "",
            lt12: "",
            sy7: "",
            sy5: "ܬܰܓܳܪܽܘܬ݂ ܥܰܬܺܝܩܳܬ݂ܳܐ",
            dt1: "Althandel",
            sy6: "",
            lt11: "",
            dt3: "",
            sy9: "",
            id: "5867",
            dt2: ""
        },
        {
            sy9: "",
            lt11: "",
            sy8: "",
            lt10: "țebo da",
            sy4: "ܛܒܐ ܕܣܦܪ ܝܘܡܐ",
            sy5: "ܛܶܒܳܐ ܕܰܣܦܳܪ ܝܰܘܡܳܐ",
            dt3: "",
            sy7: "",
            sy6: "",
            lt12: "",
            id: "6250",
            dt1: "Zeitschriftenbericht",
            dt2: "Zeitungsartikel"
        },
        {
            sy7: "ܡܫܰܟ",
            dt2: "",
            sy6: "ܡܫܟ",
            lt12: "",
            dt1: "abtrocknen",
            sy9: "",
            lt10: "kafar",
            lt11: "mshak",
            dt3: "",
            id: "5689",
            sy5: "ܟܰܦܰܪ",
            sy8: "",
            sy4: "ܟܦܪ"
        },
        {
            sy9: "",
            lt12: "",
            lt11: "",
            id: "5643",
            sy6: "",
            dt3: "",
            sy7: "",
            dt1: "absatzfähig",
            dt2: "",
            lt10: "mezdabnono",
            sy4: "ܡܙܕܒܢܢܐ",
            sy8: "",
            sy5: "ܡܶܙܕܰܒܢܳܢܳܐ"
        },
        {
            dt3: "",
            id: "1110",
            sy6: "",
            dt1: "Enonjeschu",
            dt2: "Hnonjeschu",
            sy9: "",
            sy7: "",
            sy8: "",
            sy5: "ܥܢܳܢܝܶܫܽܘܥ",
            sy4: "ܥܢܢܝܫܘܥ",
            lt11: "ܥܢܢܝܫܘܥ",
            lt12: "",
            lt10: "ܥܢܢܝܫܘܥ"
        },
        {
            sy6: "ܥܩܪܒܐ",
            sy5: "ܥܰܩܳܪܳܐ",
            sy8: "",
            sy4: "ܥܩܪܐ",
            dt2: "",
            lt10: "ĉaqoro",
            lt12: "",
            dt3: "",
            sy7: "ܥܶܩܰܪܒܳܐ",
            lt11: "ĉeqarbo",
            sy9: "",
            id: "6449",
            dt1: "Entwurzeler"
        },
        {
            dt2: "",
            sy4: "ܪܕܝܘܬܐ",
            sy7: "ܐܺܝܬ݂ܺܝܩܽܘܢ",
            dt3: "",
            lt12: "",
            sy5: "ܪܰܕܝܽܘܬ݂ܳܐ",
            sy8: "",
            lt11: "ithiqun",
            id: "3099",
            dt1: "Moral",
            sy9: "",
            sy6: "ܐܝܬܝܩܘܢ",
            lt10: "rađyutho"
        },
        {
            sy5: "ܒܺܝܠܕܰܪܳܐ",
            dt3: "Postbeamter",
            sy9: "",
            sy7: "",
            lt10: "bildaro",
            lt12: "",
            sy4: "ܒܝܠܕܪܐ",
            lt11: "",
            dt2: "Postbote",
            dt1: "Post",
            sy8: "",
            id: "3497",
            sy6: ""
        },
        {
            dt3: "",
            sy8: "",
            sy7: "",
            id: "5186",
            dt1: "Yadidta",
            lt11: "ܝܕܝܕܬܐ",
            lt10: "ܝܕܝܕܬܐ",
            sy4: "ܝܕܝܕܬܐ",
            dt2: "",
            sy5: "ܝܰܕܺܝܕܬܰܐ",
            lt12: "",
            sy9: "",
            sy6: ""
        },
        {
            sy5: "ܦܢܺܝܬ݂  ܙܽܘܠܙܳܠܳܐ",
            dt1: "Erdbebengebiet",
            id: "1137",
            dt2: "",
            sy6: "",
            sy7: "",
            sy4: "ܦܢܝܬ  ܙܘܠܙܠܐ",
            sy8: "",
            lt11: "",
            sy9: "",
            lt12: "",
            dt3: "",
            lt10: "fnith sulsolo"
        },
        {
            dt1: "Arktischer Ozean",
            sy7: "",
            sy4: "ܝܡܐ ܓܠܝܕܝܐ ܕܓܪܒܝܐ",
            sy5: "ܝܰܡܳܐ ܓܠܺܝܕܳܝܳܐ ܕܓܰܪܒܝܳܐ",
            lt12: "",
            sy8: "",
            sy9: "",
            id: "292",
            lt10: "yamo gliđoyo d`garbyo",
            lt11: "",
            dt3: "Arktik",
            dt2: "Ozean Arktik",
            sy6: ""
        },
        {
            lt12: "",
            dt2: "",
            sy5: "ܨܽܘܪܬܳܐ ܕܡܰܪܝܰܡ",
            sy9: "",
            lt10: "ŝurto d`maryam",
            dt3: "",
            sy8: "",
            sy6: "",
            dt1: "Marienbild",
            sy4: "ܨܘܪܬܐ ܕܡܪܝܡ",
            id: "2936",
            lt11: "",
            sy7: ""
        },
        {
            sy4: "ܦܓܪܢܝܐ",
            dt2: "",
            sy8: "",
            sy7: "ܓܽܘܫܡܳܢܳܝܳܐ",
            sy9: "",
            sy5: "ܦܰܓ݂ܪܳܢܳܝܳܐ",
            dt1: "leiblich",
            lt11: "gushmonoyo",
            lt10: "fağronoyo",
            id: "2747",
            sy6: "ܓܘܫܡܢܝܐ",
            lt12: "",
            dt3: ""
        },
        {
            sy9: "",
            sy8: "",
            dt2: "",
            lt12: "",
            dt1: "Amtskollege",
            sy5: "ܒܰܪ ܣܺܝܥܬܳܐ",
            lt11: "",
            dt3: "",
            sy7: "",
            sy4: "ܒܪ ܣܝܥܬܐ",
            lt10: "bar siĉto",
            sy6: "",
            id: "5888"
        },
        {
            sy8: "",
            sy7: "ܟܒܽܘܫܝܳܐ",
            dt1: "Invasion",
            sy6: "ܟܒܘܫܝܐ",
            lt10: "kbosho",
            lt11: "kbushyo",
            sy9: "",
            lt12: "",
            sy5: "ܟܒܳܫܳܐ",
            dt3: "",
            sy4: "ܟܒܫܐ",
            id: "6170",
            dt2: ""
        },
        {
            sy7: "",
            lt10: "mbaryo",
            sy4: "ܡܒܪܝܐ",
            dt2: "",
            sy8: "",
            lt11: "",
            dt3: "",
            sy9: "",
            dt1: "fremd",
            lt12: "",
            sy6: "",
            id: "6101",
            sy5: "ܡܒܰܪܝܳܐ"
        },
        {
            sy5: "ܕܰܝܪܳܝܳܐ",
            lt12: "",
            sy7: "ܥܳܢܘܳܝܳܐ",
            dt1: "Mönch",
            sy4: "ܕܝܪܝܐ",
            dt2: "Asket",
            lt11: "ĉonwoyo",
            id: "3079",
            lt10: "dayroyo",
            sy8: "",
            sy6: "ܥܢܘܝܐ",
            dt3: "",
            sy9: ""
        },
        {
            sy9: "",
            sy7: "",
            lt11: "",
            id: "4033",
            dt2: "häppchenweise",
            dt1: "Schritt für Schritt",
            sy4: "ܕܪܓܐ ܒܕܪܓܐ",
            lt12: "",
            sy6: "",
            lt10: "darğo b`darğo",
            dt3: "schrittweise",
            sy8: "",
            sy5: "ܕܰܪܓ݂ܳܐ ܒܕܰܪܓ݂ܳܐ"
        },
        {
            dt3: "",
            id: "491",
            lt11: "ܒܣܝܡܐ",
            sy6: "",
            dt1: "Basimo",
            lt10: "ܒܣܝܡܐ",
            sy5: "ܒܰܣܺܝܡܳܐ",
            sy7: "",
            sy9: "",
            sy4: "ܒܣܝܡܐ",
            lt12: "",
            dt2: "",
            sy8: ""
        },
        {
            lt12: "",
            lt10: "menyone",
            sy6: "ܡܢܝܢܐ",
            dt3: "",
            sy9: "",
            lt11: "menyono",
            dt1: "Zahlen",
            sy8: "",
            dt2: "Anzahl",
            id: "5225",
            sy7: "ܡܶܢܝܳܢܳܐ",
            sy4: "ܡܢܝܢܐ",
            sy5: "ܡܶܢܝ̈ܳܢܶܐ"
        },
        {
            dt2: "",
            sy5: "ܐܺܝܠܗܰܒ",
            lt10: "ܐܝܠܗܒ",
            dt1: "Ilhab",
            sy9: "",
            dt3: "",
            sy8: "",
            lt11: "ܐܝܠܗܒ",
            lt12: "ܐܠܗܒ",
            sy7: "ܐܶܠܗܰܒ",
            id: "2163",
            sy4: "ܐܝܠܗܒ",
            sy6: "ܐܠܗܒ"
        },
        {
            lt11: "",
            dt2: "",
            sy6: "",
            id: "2646",
            sy5: "ܩܪܳܒܳܐ ܕܟܽܘܟܒ̈ܶܐ",
            dt1: "Krieg der Sterne",
            lt12: "",
            lt10: "qrobo d`kukbe",
            dt3: "",
            sy9: "",
            sy8: "",
            sy4: "ܩܪܒܐ ܕܟܘܟܒܐ",
            sy7: ""
        },
        {
            id: "2061",
            lt10: "ܚܘܒܒܐ",
            dt3: "",
            lt11: "ܚܘܒܒܐ",
            sy6: "",
            dt2: "",
            sy4: "ܚܘܒܒܐ",
            sy5: "ܚܽܘܒܳܒܳܐ",
            sy8: "",
            sy9: "",
            lt12: "",
            sy7: "",
            dt1: "Hubobo"
        },
        {
            dt3: "",
            sy4: "ܐܦܫܬܐ",
            sy9: "",
            sy8: "",
            lt12: "",
            sy5: "ܐܰܦܫܳܬ݂ܳܐ",
            id: "3744",
            dt1: "Rosine",
            sy6: "",
            sy7: "",
            lt10: "afshotho",
            dt2: "Rosinen",
            lt11: ""
        },
        {
            lt10: "ܦܘܐܕ",
            sy8: "",
            sy5: "ܦܽܘܐܰܕ",
            sy7: "",
            sy9: "",
            sy6: "",
            dt3: "",
            sy4: "ܦܘܐܕ",
            dt2: "Fuat",
            id: "1422",
            lt11: "ܦܘܐܕ",
            dt1: "Fuad",
            lt12: ""
        },
        {
            sy9: "",
            dt1: "Christi Himmelfahrt",
            lt12: "",
            dt2: "",
            sy7: "",
            dt3: "",
            lt10: "suloqo d`moran lashmayo",
            sy8: "",
            sy6: "",
            sy4: "ܣܘܠܩܐ ܕܡܪܢ ܠܫܡܝܐ",
            lt11: "",
            sy5: "ܣܽܘܠܳܩܳܐ ܕܡܳܪܰܢ ܠܰܫܡܰܝܳܐ",
            id: "769"
        },
        {
            sy8: "",
            sy4: "ܡܪܝܢܟܐ",
            sy5: "ܡܰܪܺܝܢܟܳܐ               ",
            sy7: "",
            lt12: "",
            dt3: "",
            lt10: "ܡܪܝܢܟܐ",
            dt2: "",
            id: "2939",
            lt11: "ܡܪܝܢܟܐ",
            sy6: "",
            dt1: "Marinko",
            sy9: ""
        },
        {
            sy5: "ܠܳܒܳܢ",
            sy8: "",
            sy4: "ܠܒܢ",
            lt11: "ܠܒܢ",
            lt10: "ܠܒܢ",
            lt12: "",
            dt3: "",
            sy7: "",
            sy9: "",
            sy6: "",
            dt1: "Lobon",
            dt2: "",
            id: "2849"
        },
        {
            sy9: "",
            lt10: "tamon",
            sy7: "ܬܰܡܳܐ",
            sy4: "ܬܡܢ",
            sy5: "ܬܰܡܳܢ",
            lt12: "",
            id: "950",
            dt3: "",
            dt1: "dort",
            lt11: "tamo",
            dt2: "",
            sy8: "",
            sy6: "ܬܡܐ"
        },
        {
            lt12: "",
            dt2: "",
            sy7: "ܥܳܠܡܳܐ",
            sy6: "ܥܠܡܐ",
            lt10: "ĉolminoyutho",
            dt3: "",
            sy9: "",
            sy4: "ܥܠܡܝܢܝܘܬܐ",
            lt11: "ĉolmo",
            sy5: "ܥܳܠܡܺܝܢܳܝܽܘܬ݂ܳܐ",
            id: "1211",
            sy8: "",
            dt1: "Ewigkeit"
        },
        {
            sy7: "ܘܰܪܩܳܐ",
            sy4: "ܛܪܦܐ",
            lt10: "țarfo",
            id: "642",
            lt11: "warqo",
            dt1: "Blatt",
            sy8: "",
            sy5: "ܛܰܪܦܳܐ",
            sy6: "ܘܪܩܐ",
            dt2: "",
            dt3: "",
            sy9: "",
            lt12: ""
        },
        {
            lt12: "",
            sy6: "",
            dt2: "Ebenbild",
            id: "3069",
            sy8: "",
            lt10: "dumyo",
            dt1: "Modell",
            lt11: "",
            sy5: "ܕܽܘܡܝܳܐ",
            sy9: "",
            sy7: "",
            dt3: "",
            sy4: "ܕܘܡܝܐ"
        },
        {
            sy4: "ܫܝܦܘܪܐ",
            lt11: "ܫܝܦܘܪܐ",
            sy5: "ܫܺܝܦܽܘܪܰܐ",
            lt12: "",
            sy7: "",
            dt3: "",
            sy8: "",
            dt1: "Schipura",
            sy9: "",
            id: "3955",
            sy6: "",
            dt2: "Schifura",
            lt10: "ܫܝܦܘܪܐ"
        },
        {
            dt1: "Streicheln",
            lt10: "shyofo",
            sy7: "ܠܽܘܚܟܳܫܳܐ",
            lt11: "luĥkosho",
            sy5: "ܫܝܳܦܳܐ",
            sy6: "ܠܘܚܟܫܐ",
            sy8: "",
            sy9: "",
            dt3: "",
            lt12: "",
            dt2: "",
            sy4: "ܫܝܦܐ",
            id: "4295"
        },
        {
            id: "1538",
            sy8: "",
            dt2: "",
            sy4: "ܒܚܝܫܐ",
            sy7: "",
            lt11: "",
            sy9: "",
            dt1: "geil",
            sy6: "",
            dt3: "",
            lt10: "bĥisho",
            lt12: "",
            sy5: "ܒܚܽܝܫܳܐ"
        },
        {
            dt1: "anderer",
            sy5: "ܐ̱ܚܪܺܢܳܐ",
            lt10: "ĥrino",
            lt11: "",
            id: "226",
            lt12: "",
            dt2: "",
            sy4: "ܐܚܪܢܐ",
            sy8: "",
            sy7: "",
            sy9: "",
            dt3: "",
            sy6: ""
        },
        {
            lt11: "",
            lt10: "adĉekh nuro",
            sy6: "",
            sy4: "ܐܕܥܟ ܢܘܪܐ",
            dt1: "Feuer löschen",
            sy8: "",
            sy9: "",
            id: "1302",
            sy5: "ܐܰܕܥܶܟ݂ ܢܽܘܪܳܐ",
            dt2: "",
            sy7: "",
            dt3: "",
            lt12: ""
        },
        {
            id: "4823",
            dt1: "verwalten",
            sy8: "",
            dt3: "",
            lt12: "",
            lt11: "rođe",
            dt2: "",
            sy5: "ܕܰܒܳܪ",
            sy6: "ܪܕܐ",
            sy4: "ܕܒܪ",
            sy7: "ܪܳܕܶܐ",
            lt10: "dabar",
            sy9: ""
        },
        {
            lt12: "",
            sy5: "ܛܳܒܳܐ",
            sy7: "",
            dt1: "Tobo",
            sy6: "",
            lt11: "ܛܒܐ",
            id: "4506",
            sy8: "",
            lt10: "ܛܒܐ",
            sy4: "ܛܒܐ",
            dt2: "",
            dt3: "",
            sy9: ""
        },
        {
            id: "1002",
            sy8: "",
            sy6: "",
            sy5: "ܥܶܕܳܢ",
            dt2: "",
            dt1: "Edon",
            sy7: "",
            lt11: "ܥܕܢ",
            lt10: "ܥܕܢ",
            sy9: "",
            sy4: "ܥܕܢ",
            dt3: "",
            lt12: ""
        },
        {
            dt2: "",
            dt1: "Transport",
            lt12: "",
            sy5: "ܢܩܳܠܳܐ",
            sy7: "",
            lt10: "nqolo",
            sy6: "",
            sy8: "",
            sy9: "",
            lt11: "",
            dt3: "",
            id: "4543",
            sy4: "ܢܩܠܐ"
        },
        {
            dt1: "Buchstabe",
            sy9: "",
            sy4: "ܐܬܘܬܐ",
            sy7: "",
            lt11: "",
            sy5: "ܐܳܬ݂ܽܘ݂ܬ݂ܳܐ",
            sy6: "",
            sy8: "",
            dt3: "",
            lt12: "",
            id: "729",
            dt2: "",
            lt10: "othutho"
        },
        {
            sy9: "",
            sy6: "",
            dt1: "Täufer",
            lt12: "",
            lt10: "maĉmđono",
            sy8: "",
            sy7: "",
            dt2: "",
            sy4: "ܡܥܡܕܢܐ",
            id: "4402",
            dt3: "",
            sy5: "ܡܰܥܡܕܳܢܳܐ",
            lt11: ""
        },
        {
            sy9: "",
            dt2: "",
            lt11: "",
            id: "4752",
            dt1: "Verkehrsregeln",
            sy4: "ܩܢܘܢܐ ܕܥܒܪܐ",
            sy8: "",
            lt10: "qonuno da`ĉbar",
            sy7: "",
            lt12: "",
            sy6: "",
            dt3: "",
            sy5: "ܩܳܢܽܘܢܳܐ ܕܰܥܒܳܪܳܐ"
        },
        {
            sy5: "ܩܰܪܡܺܝܕܳܐ",
            sy7: "ܩܽܘܦܣܳܐ",
            dt2: "Keramik",
            sy4: "ܩܪܡܝܕܐ",
            lt11: "qufso",
            lt10: "qarmiđo",
            sy6: "ܩܘܦܣܐ",
            dt3: "",
            dt1: "Kachel",
            sy8: "",
            sy9: "",
            lt12: "",
            id: "2372"
        },
        {
            sy6: "",
            sy8: "",
            sy9: "",
            sy7: "",
            sy5: "ܠܚܽܘܕܳܝܳܐ",
            lt11: "",
            dt3: "",
            sy4: "ܠܚܘܕܝܐ",
            dt2: "",
            lt10: "lĥuđoyo",
            id: "182",
            dt1: "alleinstehend",
            lt12: ""
        },
        {
            dt2: "",
            sy7: "ܠܳܐ ܙܰܒܢܳܝܳܐ",
            dt3: "",
            sy8: "",
            lt10: "d'lo zabno",
            lt12: "",
            lt11: "lo zabnoyo",
            id: "6264",
            sy4: "ܕܠܐ ܙܒܢܐ",
            dt1: "zeitlos",
            sy5: "ܕܠܳܐ ܙܰܒܢܳܐ",
            sy6: "ܠܐ ܙܒܢܝܐ",
            sy9: ""
        },
        {
            sy6: "",
            sy5: "ܒܰܩܪܰܐ",
            sy8: "",
            lt12: "",
            sy4: "ܒܩܪܐ",
            sy9: "",
            lt10: "ܒܩܪܐ",
            lt11: "ܒܩܪܐ",
            dt3: "",
            sy7: "",
            dt1: "Bakra",
            dt2: "",
            id: "426"
        },
        {
            sy7: "",
            sy6: "",
            id: "653",
            lt12: "",
            dt3: "",
            sy4: "ܫܥܘܬܢܝܬܐ",
            sy8: "",
            dt2: "",
            lt11: "",
            dt1: "Blondine",
            sy9: "",
            sy5: "ܫܰܥܽܘܬ݂ܳܢܳܝܬܳܐ",
            lt10: "shaĉuthonoyto"
        },
        {
            sy5: "ܓܽܘܕܳܐ",
            sy4: "ܓܘܕܐ",
            sy7: "",
            lt12: "",
            dt1: "Gruppe",
            lt11: "",
            id: "1754",
            lt10: "gudo",
            sy9: "",
            dt3: "",
            sy6: "",
            dt2: "Truppe",
            sy8: ""
        },
        {
            dt2: "Schluss jetzt",
            sy4: "ܣܦܩ",
            sy8: "",
            lt11: "basyo",
            lt10: "sofeq",
            lt12: "",
            sy6: "ܒܣܝܐ",
            sy5: "ܣܳܦܶܩ",
            dt1: "es reicht",
            dt3: "Basta",
            sy7: "ܒܰܣܝܳܐ",
            sy9: "",
            id: "1185"
        },
        {
            lt11: "bote",
            sy4: "ܩܪܝܬܐ ܕܒܬܐ",
            sy9: "",
            sy7: "ܒܳܬܶܐ",
            dt2: "Bote",
            lt10: "qritho bote",
            sy6: "ܒܬܐ",
            sy8: "",
            dt3: "",
            lt12: "",
            sy5: "ܩܪܺܝܬ݂ܳܐ ܕܒܳܬܶܐ",
            dt1: "Dorf Bote",
            id: "928"
        },
        {
            sy4: "ܪܨܕ",
            lt10: "rŝad",
            sy8: "",
            sy7: "ܡܬܰܪܶܨ",
            sy6: "ܡܬܪܨ",
            lt11: "mtareŝ",
            sy5: "ܪܨܰܕ",
            dt2: "",
            id: "3698",
            dt1: "reparieren",
            lt12: "",
            sy9: "",
            dt3: ""
        },
        {
            lt11: "ܩܕܫ",
            dt3: "",
            lt12: "",
            dt2: "Kadesch",
            lt10: "ܩܕܫ",
            sy8: "",
            sy9: "",
            id: "3558",
            sy6: "",
            sy4: "ܩܕܫ",
            sy5: "ܩܰܕܶܫ",
            dt1: "Qadesch",
            sy7: ""
        },
        {
            lt11: "rawodo",
            sy9: "",
            lt10: "tagoro d`warde",
            sy6: "ܪܘܕܐ",
            lt12: "",
            dt3: "",
            sy8: "",
            sy5: "ܬܰܓܳܪܳܐ ܕܘܰܪ̈ܕܶܐ",
            dt2: "",
            id: "655",
            sy7: "ܪܰܘܳܕܳܐ",
            sy4: "ܬܓܪܐ ܕܘܪܕܐ",
            dt1: "Blumenhändler"
        },
        {
            sy5: "ܩܳܐܝܶܢ",
            sy6: "ܩܐܝܢ",
            dt1: "Kain",
            lt10: "ܩܐܝܢ",
            sy4: "ܩܐܝܢ",
            sy8: "",
            lt12: "ܩܐܝܢ",
            lt11: "ܩܐܝܢ",
            sy9: "",
            id: "2380",
            dt2: "",
            dt3: "",
            sy7: "ܩܰܐܝܺܢ"
        },
        {
            sy5: "ܥܰܪܢܽܘܬ݂ܳܐ",
            sy9: "",
            sy7: "",
            lt11: "",
            dt3: "",
            sy8: "",
            sy6: "",
            dt2: "",
            id: "4627",
            dt1: "Umbarmherzigkeit",
            sy4: "ܥܪܢܘܬܐ",
            lt10: "ĉarnutho",
            lt12: ""
        },
        {
            dt1: "schnarchen",
            sy5: "ܢܚܰܪ",
            sy8: "",
            dt2: "",
            lt11: "nĥaț",
            sy6: "ܢܚܛ",
            sy7: "ܢܚܰܛ",
            dt3: "",
            lt10: "nĥar",
            id: "3990",
            sy9: "",
            sy4: "ܢܚܪ",
            lt12: ""
        },
        {
            sy5: "ܓܺܝܪܓܺܝܣ",
            sy6: "",
            sy7: "",
            sy9: "",
            lt10: "ܓܪܓܝܣ",
            id: "1644",
            dt1: "Girgis",
            dt2: "",
            sy8: "",
            dt3: "",
            sy4: "ܓܪܓܝܣ",
            lt11: "ܓܪܓܝܣ",
            lt12: ""
        },
        {
            sy7: "ܡܰܕܳܢܳܝܳܐ",
            dt2: "",
            lt11: "madonoyo",
            dt1: "Alkoholverkäufer",
            sy5: "ܡܰܕܳܢܳܐ",
            dt3: "",
            lt10: "madono",
            sy9: "",
            sy4: "ܡܕܢܐ",
            id: "5832",
            sy6: "ܡܕܢܝܐ",
            lt12: "",
            sy8: ""
        },
        {
            sy6: "",
            lt12: "",
            sy8: "",
            id: "3826",
            dt3: "",
            lt11: "",
            dt2: "",
            sy7: "",
            dt1: "Sammler",
            sy4: "ܡܟܢܫܢܐ",
            sy9: "",
            sy5: "ܡܟܰܢܫܳܢܳܐ",
            lt10: "mkanshono"
        },
        {
            sy9: "",
            dt3: "",
            sy5: "ܚܰܒܪܽܘܬܰܐ",
            dt2: "Habrutha",
            lt12: "",
            sy7: "",
            lt10: "ܚܒܪܘܬܐ",
            sy6: "",
            id: "1799",
            lt11: "ܚܒܪܘܬܐ",
            dt1: "Habruta",
            sy8: "",
            sy4: "ܚܒܪܘܬܐ"
        },
        {
            dt2: "",
            dt1: "Fastenzeit",
            sy4: "ܙܒܢܐ ܕܨܘܡܐ",
            lt12: "",
            id: "1266",
            sy9: "",
            sy7: "",
            sy6: "",
            lt11: "",
            sy8: "",
            sy5: "ܙܰܒܢܳܐ ܕܨܰܘܡܳܐ",
            dt3: "",
            lt10: "zabno d`ŝaumo"
        },
        {
            sy9: "",
            sy4: "ܢܫܟ",
            lt11: "",
            lt12: "",
            dt1: "abbeissen",
            sy7: "",
            sy5: "ܢܫܶܟ݂",
            id: "5546",
            sy6: "",
            lt10: "nshekh",
            dt2: "abbeißen",
            sy8: "",
            dt3: ""
        },
        {
            sy8: "",
            dt1: "abschlachten",
            lt12: "",
            sy6: "ܛܒܚ",
            lt10: "țbaĥ",
            sy5: "ܛܒܰܚ",
            sy7: "ܛܰܒܰܚ",
            id: "5622",
            lt11: "țabaĥ",
            sy9: "",
            dt2: "",
            dt3: "",
            sy4: "ܛܒܚ"
        },
        {
            sy7: "",
            dt1: "Agrarschule",
            sy5: "ܡܰܕܪܰܫܬܳܐ ܙܰܪܳܥܽܘܬ݂ܳܐ",
            lt10: "madrashto zaroĉutho",
            sy8: "",
            id: "5776",
            sy4: "ܡܕܪܫܬܐ ܙܪܥܘܬܐ",
            lt11: "",
            dt2: "",
            sy9: "",
            dt3: "",
            sy6: "",
            lt12: ""
        },
        {
            lt10: "ganono",
            lt11: "",
            sy6: "",
            dt1: "Gärtner",
            sy4: "ܓܢܢܐ",
            dt2: "",
            sy5: "ܓܰܢܳܢܳܐ",
            id: "1480",
            dt3: "",
            sy8: "",
            sy7: "",
            lt12: "",
            sy9: ""
        },
        {
            dt3: "",
            sy8: "",
            sy6: "",
            lt12: "",
            sy7: "",
            sy9: "",
            sy5: "ܠܽܘܠܺܝܬܰܐ",
            dt1: "Lulita",
            sy4: "ܠܘܠܝܬܐ",
            lt11: "ܠܘܠܝܬܐ",
            dt2: "",
            lt10: "ܠܘܠܝܬܐ",
            id: "2875"
        },
        {
            dt2: "",
            id: "5283",
            sy7: "ܪܶܫܡܳܐ",
            lt12: "",
            sy4: "ܨܝܪܐ",
            lt11: "reshmo",
            sy9: "",
            sy8: "",
            dt3: "",
            sy5: "ܨܝܳܪܳܐ",
            lt10: "ŝyoro",
            dt1: "Zeichnung",
            sy6: "ܪܫܡܐ"
        },
        {
            sy6: "ܨܘܨܝܐ",
            sy5: "ܡܨܰܘܨܳܐ",
            sy8: "",
            dt1: "Zwitschern",
            id: "5411",
            lt11: "ŝuŝoyo",
            sy7: "ܨܽܘܨܳܝܳܐ",
            lt12: "",
            sy4: "ܡܨܘܨܐ",
            lt10: "mŝauŝo",
            sy9: "",
            dt2: "",
            dt3: ""
        },
        {
            dt2: "",
            lt10: "qaĥfo gulo",
            lt12: "",
            sy8: "",
            sy4: "ܩܚܦܐ ܓܘܠܐ",
            sy9: "",
            sy7: "ܓܳܠܳܐ",
            sy5: "ܩܰܚܦܳܐ ܓܽܘܠܳܐ",
            id: "3950",
            dt3: "",
            sy6: "ܓܠܐ",
            dt1: "Schildkröte",
            lt11: "golo"
        },
        {
            lt12: "",
            sy5: "ܐܰܢܬܰܐܢܝܽܘܣ",
            sy9: "",
            lt11: "ܐܢܬܐܢܝܘܣ",
            lt10: "ܐܢܬܐܢܝܘܣ",
            sy6: "",
            dt3: "",
            dt1: "Antanijos ",
            sy7: "",
            sy8: "",
            id: "245",
            sy4: "ܐܢܬܐܢܝܘܣ",
            dt2: ""
        },
        {
            id: "348",
            dt1: "Auf keinen Fall",
            sy4: "ܡܢ ܠܐ ܦܪܘܣ",
            sy6: "",
            sy5: "ܡܶܢ ܠܳܐ ܦܪܽܘܣ",
            lt10: "men lo frus",
            sy7: "",
            dt2: "",
            sy8: "",
            lt12: "",
            dt3: "",
            lt11: "",
            sy9: ""
        },
        {
            id: "4031",
            lt10: "azĉeq",
            sy8: "ܩܥܐ",
            sy7: "ܙܥܰܩ",
            dt1: "schreien",
            dt3: "",
            sy9: "ܩܥܳܐ",
            sy4: "ܐܙܥܩ",
            sy5: "ܐܰܙܥܶܩ",
            lt12: "qĉo",
            dt2: "rufen",
            sy6: "ܙܥܩ",
            lt11: "zĉaq"
        },
        {
            sy9: "",
            sy5: "ܣܡܳܐ",
            dt3: "",
            dt2: "",
            sy6: "",
            lt11: "",
            lt12: "",
            id: "649",
            sy8: "",
            lt10: "smo",
            sy4: "ܣܡܐ",
            dt1: "blind werden",
            sy7: ""
        },
        {
            sy8: "",
            sy4: "ܚܢܦܘܬܐ",
            lt12: "",
            sy6: "ܟܦܘܪܘܬܐ",
            lt10: "ĥanfutho",
            sy5: "ܚܰܢܦܽܘܬ݂ܳܐ",
            sy9: "",
            dt1: "Gottlosigkeit",
            dt3: "Unglaube",
            id: "1712",
            lt11: "kofurutho",
            dt2: "Religionslosigkeit",
            sy7: "ܟܳܦܽܘܪܽܘܬ݂ܳܐ"
        },
        {
            dt3: "",
            lt10: "qhutho",
            sy9: "",
            id: "2375",
            sy4: "ܩܗܘܬܐ",
            sy8: "",
            lt12: "",
            sy5: "ܩܗܽܘܬ݂ܳܐ",
            dt2: "",
            dt1: "Kaffee",
            sy6: "",
            sy7: "",
            lt11: ""
        },
        {
            sy7: "",
            dt2: "",
            dt1: "ț",
            sy5: "ܛ",
            lt10: "țeth",
            sy9: "",
            dt3: "",
            lt11: "",
            sy8: "",
            id: "4346",
            lt12: "",
            sy4: "ܛ",
            sy6: ""
        },
        {
            sy8: "",
            sy9: "",
            dt3: "",
            lt11: "",
            sy6: "",
            lt12: "",
            dt2: "",
            sy5: "ܡܰܠܳܠܳܐ",
            id: "4909",
            sy4: "ܡܠܠܐ",
            sy7: "",
            lt10: "malolo",
            dt1: "Vorsprecher"
        },
        {
            sy8: "",
            lt11: "",
            sy5: "ܐܰܒܽܘܒ ܡܰܝܳܐ",
            sy7: "",
            sy9: "",
            lt12: "",
            id: "5010",
            sy4: "ܐܒܘܒ ܡܝܐ",
            dt1: "Wasserschlauch",
            sy6: "",
            lt10: "abub mayo",
            dt3: "",
            dt2: ""
        },
        {
            sy9: "",
            lt10: "adiq",
            sy6: "",
            dt3: "",
            sy8: "",
            sy5: "ܐܰܕܺܝܩ",
            id: "1939",
            sy4: "ܐܕܝܩ",
            lt11: "",
            sy7: "",
            lt12: "",
            dt1: "heimlich beobachten",
            dt2: ""
        },
        {
            lt12: "furtoko",
            dt3: "",
            dt1: "Zertrümmerung",
            sy9: "ܦܽܘܪܬܳܟܳܐ",
            lt10: "țuboĥo",
            sy5: "ܛܽܘܒܳܚܳܐ",
            sy8: "ܦܘܪܬܟܐ",
            sy7: "ܟܣܳܣܳܐ",
            lt11: "ksoso",
            id: "6279",
            dt2: "",
            sy6: "ܟܣܣܐ",
            sy4: "ܛܘܒܚܐ"
        },
        {
            sy9: "",
            sy4: "ܐܠܗܐ ܐܝܬܘܗܝ ܚܘܒܐ",
            lt12: "",
            dt1: "Gott ist Liebe",
            sy8: "",
            dt2: "",
            sy7: "",
            id: "1692",
            sy5: "ܐܰܠܳܗܳܐ ܐܺܝܬ݂ܰܘܗܝ̱ ܚܽܘܒܳܐ",
            lt10: "aloho ithau ĥubo",
            lt11: "",
            sy6: "",
            dt3: ""
        },
        {
            sy8: "",
            sy6: "",
            lt10: "ܥܙܪܐ",
            dt2: "",
            sy4: "ܥܙܪܐ",
            lt11: "ܥܙܪܐ",
            sy7: "",
            sy9: "",
            id: "1221",
            lt12: "",
            dt1: "Ezra",
            sy5: "ܥܶܙܪܰܐ",
            dt3: ""
        },
        {
            lt12: "",
            sy7: "",
            dt1: "Pfeifen",
            dt3: "Zischen",
            sy6: "",
            lt10: "shroqo",
            id: "3450",
            lt11: "",
            dt2: "Pfiff",
            sy9: "",
            sy5: "ܫܪܳܩܳܐ",
            sy8: "",
            sy4: "ܫܪܩܐ"
        },
        {
            sy8: "ܪܚܫܘܢܐ",
            dt2: "",
            id: "2209",
            sy9: "ܪܰܚܫܽܘܢܳܐ",
            sy4: "ܪܚܫܐ",
            sy7: "ܪܰܚܫܶܐ",
            sy5: "ܪܰܚܫܳܐ",
            sy6: "ܪܚܫܐ",
            lt12: "raĥshuno",
            dt1: "Insekten",
            lt11: "raĥshe",
            dt3: "",
            lt10: "raĥsho"
        },
        {
            id: "1331",
            dt2: "fleissig",
            sy5: "ܟܰܫܺܝܪܳܐ",
            dt3: "",
            sy7: "",
            sy8: "",
            sy4: "ܟܫܝܪܐ",
            lt12: "",
            lt11: "",
            sy9: "",
            sy6: "",
            lt10: "kashiro",
            dt1: "fleißig"
        },
        {
            sy8: "",
            lt11: "",
            lt10: "galwoyo",
            dt3: "",
            sy9: "",
            dt2: "",
            sy6: "",
            sy7: "",
            id: "2170",
            sy4: "ܓܠܘܝܐ",
            lt12: "",
            dt1: "Immigrant",
            sy5: "ܓܰܠܘܳܝܳܐ"
        },
        {
            sy6: "",
            sy4: "ܣܝܘܩܘܬܐ",
            id: "3719",
            sy7: "",
            lt11: "",
            lt12: "",
            dt2: "",
            lt10: "soyuqutho",
            dt3: "",
            dt1: "Riechen",
            sy8: "",
            sy9: "",
            sy5: "ܣܳܝܽܘܩܽܘܬ݂ܳܐ"
        },
        {
            dt1: "Chor",
            lt11: "",
            sy5: "ܓܽܘܕܳܐ ܕܙܰܡܳܪ̈ܐܶ",
            sy4: "ܓܘܕܐ ܕܙܡܪܐ",
            sy9: "",
            dt3: "",
            lt10: "gudo d`zamore",
            id: "765",
            sy7: "",
            lt12: "",
            sy8: "",
            sy6: "",
            dt2: ""
        },
        {
            dt2: "",
            sy4: "ܡܣܟܪܐ",
            sy6: "",
            id: "471",
            sy7: "",
            sy8: "",
            sy9: "",
            dt1: "Barriere",
            sy5: "ܡܰܣܟ݂ܳܪܳܐ",
            lt10: "maskhoro",
            lt12: "",
            dt3: "",
            lt11: ""
        },
        {
            sy7: "",
            lt11: "",
            dt3: "",
            dt1: "Fledermaus",
            lt12: "",
            sy4: "ܦܪܚܕܘܕܐ",
            id: "1324",
            sy8: "",
            sy5: "ܦܪܰܚܕܽܘܕܳܐ",
            lt10: "fraĥdudo",
            dt2: "",
            sy9: "",
            sy6: ""
        },
        {
            dt1: "Tabaksteuer",
            dt2: "",
            sy5: "ܡܰܕܰܐܬ݂ܳܐ ܕܬܽܘܬܽܘܢ",
            sy7: "",
            lt12: "",
            lt10: "madatho d`tutun",
            sy8: "",
            id: "4350",
            dt3: "",
            sy9: "",
            sy6: "",
            lt11: "",
            sy4: "ܡܕܐܬܐ ܕܬܘܬܘܢ"
        },
        {
            sy8: "",
            id: "2097",
            lt10: "eno no shbilo shroro ĥaye",
            lt11: "eno no urĥo shroro u ĥaye",
            sy5: "ܐܶܢܳܐ ܐ̱ܢܳܐ ܫܒܺܝܠܳܐ ܫܪܳܪܳܐ ܘܚܰܝܶܐ",
            dt3: "",
            dt1: "Ich bin der Weg die Wahrheit und das Leben",
            sy9: "",
            sy4: "ܐܢܐ ܐܢܐ ܫܒܝܠܐ ܫܪܪܐ ܘܚܝܐ",
            lt12: "",
            dt2: "",
            sy7: "ܐܶܢܳܐ ܐ̱ܢܳܐ ܐܽܘܪܚܳܐ ܫܪܳܪܳܐ ܘܚܰܝܶܐ",
            sy6: "ܐܢܐ ܐܢܐ ܐܘܪܚܐ ܫܪܪܐ ܘܚܝܐ"
        },
        {
            sy4: "ܣܠܩ",
            lt11: "soleq",
            id: "2524",
            sy6: "ܣܠܩ",
            lt10: "slaq",
            sy7: "ܣܳܠܶܩ",
            lt12: "",
            dt3: "",
            dt2: "",
            dt1: "klettern",
            sy9: "",
            sy5: "ܣܠܰܩ",
            sy8: ""
        },
        {
            sy7: "",
            sy5: "ܪܰܬ݂ܳܚܳܐ ܕܡܰܝܳܐ",
            dt3: "",
            dt2: "",
            lt10: "rathoĥo d`mayo",
            sy8: "",
            sy9: "",
            sy4: "ܪܬܚܐ ܕܡܝܐ",
            sy6: "",
            id: "5004",
            lt12: "",
            lt11: "",
            dt1: "Wasserkocher"
        },
        {
            sy4: "ܛܪܦܣܩܐ",
            sy6: "ܩܝܪܘܬܐ",
            lt10: "țarfosqo",
            lt12: "",
            sy9: "",
            id: "6040",
            sy5: "ܛܰܪܦܳܣܩܳܐ",
            sy7: "ܩܺܝܪܽܘܬ݂ܳܐ",
            dt2: "",
            dt1: "Honigkerze",
            sy8: "",
            lt11: "qirutho",
            dt3: ""
        },
        {
            sy8: "",
            sy7: "",
            dt1: "Kuriakos",
            lt11: "ܩܘܪܝܩܘܣ",
            id: "2674",
            sy5: "ܩܽܘܪܝܰܩܳܘܣ",
            lt12: "",
            lt10: "ܩܘܪܝܩܘܣ",
            sy9: "",
            dt3: "Quryakos",
            dt2: "Kuryakos",
            sy4: "ܩܘܪܝܩܘܣ",
            sy6: ""
        },
        {
            sy5: "ܕܰܟܝܽܘܬܰܐ",
            id: "788",
            lt11: "ܕܟܝܘܬܐ",
            sy7: "",
            dt2: "",
            sy9: "",
            sy6: "",
            lt10: "ܕܟܝܘܬܐ",
            dt3: "",
            dt1: "Dakyouta",
            lt12: "",
            sy4: "ܕܟܝܘܬܐ",
            sy8: ""
        },
        {
            dt3: "",
            sy4: "ܩܘܪܒܐ ܐܠܗܝܐ",
            lt12: "",
            dt1: "Heilige Messe",
            sy8: "",
            sy5: "ܩܘܪܰܒܳܐ ܐܰܠܳܗܳܝܳܐ",
            dt2: "",
            lt11: "",
            lt10: "qurobo alohoyo",
            sy6: "",
            sy7: "",
            sy9: "",
            id: "1926"
        },
        {
            sy5: "ܩܰܡܪܳܐ ܕܫܰܝܢܳܐ",
            lt10: "qamro d`shayno",
            sy8: "",
            sy7: "",
            dt1: "Sicherheitsgurt",
            lt11: "",
            sy6: "",
            dt3: "",
            id: "4141",
            dt2: "",
            sy4: "ܩܡܪܐ ܕܫܝܢܐ",
            lt12: "",
            sy9: ""
        },
        {
            dt3: "",
            dt2: "",
            sy8: "",
            sy9: "",
            id: "4016",
            dt1: "schönes Mädchen",
            lt10: "țlitho shafirto",
            sy7: "",
            lt11: "",
            lt12: "",
            sy4: "ܛܠܝܬܐ ܫܦܝܪܬܐ",
            sy6: "",
            sy5: "ܛܠܺܝܬ݂ܳܐ ܫܰܦܺܝܪܬܳܐ"
        },
        {
            id: "3647",
            lt11: "",
            sy8: "",
            sy7: "",
            dt1: "Rechtswissenschaft",
            lt12: "",
            dt2: "",
            sy9: "",
            sy5: "ܝܽܘܠܦܳܢ ܢܳܡܽܘܣܳܐ",
            lt10: "yulfon nomuso",
            sy6: "",
            dt3: "",
            sy4: "ܝܘܠܦܢ ܢܡܘܣܐ"
        },
        {
            lt11: "ܐܘܪܡܝܐ",
            dt3: "",
            sy7: "ܐܽܘܪܡܺܝ",
            dt2: "",
            sy4: "ܐܘܪܡܝܐ",
            sy9: "",
            lt10: "ܐܘܪܡܝܐ",
            lt12: "ܐܘܪܡܝ",
            sy6: "ܐܘܪܡܝ",
            sy5: "ܐܽܘܪܡܺܝܰܐ",
            dt1: "Urmiya",
            id: "4696",
            sy8: ""
        },
        {
            sy7: "ܥܶܣܪܺܝܢ ܘܐܰܪܒܰܥ",
            sy9: "ܟܕ",
            dt1: "24",
            sy5: "ܥܶܣܪܺܝܢ ܘܐܰܪܒܥܳܐ",
            id: "25",
            sy8: "ܟܕ",
            dt3: "",
            sy6: "ܥܣܪܝܢ ܘܐܪܒܥ",
            sy4: "ܥܣܪܝܢ ܘܐܪܒܥܐ",
            lt12: "kof-dolađ",
            lt11: "ĉesrin wa`rbaĉ",
            lt10: "ĉesrin wa`arbĉo",
            dt2: "Vierundzwanzig"
        },
        {
            dt3: "",
            lt10: "ܦܘܠܘܣ",
            lt11: "ܦܘܠܘܣ",
            sy7: "ܦܰܘܠܳܘܣ",
            sy9: "",
            sy5: "ܦܰܘܠܽܘܣ",
            dt2: "Paul",
            id: "3424",
            sy8: "",
            lt12: "ܦܘܠܘܣ",
            dt1: "Paulus",
            sy6: "ܦܘܠܘܣ",
            sy4: "ܦܘܠܘܣ"
        },
        {
            lt12: "",
            id: "3809",
            dt3: "",
            dt1: "Saide",
            lt10: "ܣܝܕܐ",
            dt2: "Sayde",
            sy5: "ܣܰܝܕܶܐ",
            lt11: "ܣܝܕܐ",
            sy8: "",
            sy4: "ܣܝܕܐ",
            sy6: "",
            sy7: "",
            sy9: ""
        },
        {
            sy9: "",
            sy7: "ܬܽܘܪܓܳܡܳܐ",
            sy5: "ܬܰܪܓܡܳܢܽܘܬ݂ܳܐ",
            dt3: "",
            sy8: "",
            sy4: "ܬܪܓܡܢܘܬܐ",
            sy6: "ܬܘܪܓܡܐ",
            id: "4620",
            dt1: "Übersetzung",
            dt2: "",
            lt11: "turgomo",
            lt12: "",
            lt10: "targmonutho"
        },
        {
            lt11: "",
            lt12: "",
            sy7: "",
            id: "2206",
            sy6: "",
            sy8: "",
            dt3: "",
            dt2: "",
            sy9: "",
            sy4: "ܚܙܘܐ ܓܘܝܐ",
            sy5: "ܚܶܙܘܳܐ ܓܰܘܝܳܐ   ",
            dt1: "Innenansicht",
            lt10: "ĥezwo gawoyo"
        },
        {
            sy4: "ܡܪܕܘܬܐ",
            dt2: "",
            dt1: "Marduta",
            id: "2929",
            sy7: "",
            sy8: "",
            lt12: "",
            sy6: "",
            sy5: "ܡܰܪܕܽܘܬܰܐ",
            dt3: "",
            lt11: "ܡܪܕܘܬܐ",
            sy9: "",
            lt10: "ܡܪܕܘܬܐ"
        },
        {
            dt2: "",
            sy7: "",
            sy5: "ܒܣܽܘܪܗܳܒܳܐ",
            sy8: "",
            dt1: "akut",
            lt11: "",
            dt3: "",
            sy9: "",
            id: "5808",
            sy4: "ܒܣܘܪܗܒܐ",
            sy6: "",
            lt10: "bsurhobo",
            lt12: ""
        },
        {
            dt1: "Taschenuhr",
            sy6: "",
            sy8: "",
            dt3: "",
            sy5: "ܫܳܥܰܬ݂ ܕܟܺܝܣܳܐ",
            sy9: "",
            lt10: "shoĉath d`kiso",
            id: "4394",
            lt11: "",
            dt2: "",
            sy7: "",
            sy4: "ܫܥܬ ܕܟܝܣܐ",
            lt12: ""
        },
        {
            sy6: "ܝܗܘܕܝܐ",
            sy5: "ܝܽܘܕܳܝܳܐ",
            sy8: "",
            lt10: "yuđoyo",
            id: "2345",
            lt11: "ihuđoyo",
            sy7: "ܝܽܗܽܘܕܳܝܳܐ",
            lt12: "",
            sy4: "ܝܘܕܝܐ",
            sy9: "",
            dt1: "Jude",
            dt3: "",
            dt2: ""
        },
        {
            sy5: "ܚܣܳܡܳܐ",
            id: "3239",
            sy4: "ܚܣܡܐ",
            lt10: "ĥsomo",
            sy7: "",
            dt1: "Neid",
            dt2: "",
            dt3: "",
            sy6: "",
            sy9: "",
            lt12: "",
            sy8: "",
            lt11: ""
        },
        {
            sy6: "",
            lt11: "",
            sy8: "",
            dt3: "",
            lt12: "",
            sy5: "ܨܳܐܬ݂ܳܐ",
            lt10: "ŝotho",
            id: "5603",
            sy9: "",
            dt1: "dreckig",
            dt2: "",
            sy7: "",
            sy4: "ܨܐܬܐ"
        },
        {
            sy4: "ܥܣܩܐ",
            lt11: "yaquro",
            dt3: "",
            lt12: "",
            dt2: "mühselig",
            sy8: "",
            sy7: "ܝܰܩܽܘܪܳܐ",
            sy5: "ܥܰܣܩܳܐ",
            lt10: "ĉasqo",
            sy9: "",
            id: "4076",
            dt1: "schwer",
            sy6: "ܝܩܘܪܐ"
        },
        {
            sy8: "",
            sy4: "ܓܪܝܥܐ",
            dt2: "",
            lt10: "griĉo",
            lt12: "",
            sy6: "",
            dt1: "rasiert",
            sy7: "",
            sy5: "ܓܪܺܝܥܳܐ",
            dt3: "",
            lt11: "",
            sy9: "",
            id: "3610"
        },
        {
            sy8: "",
            lt12: "",
            lt10: "ethĥzi",
            dt3: "",
            sy7: "",
            dt2: "",
            dt1: "gesehen werden",
            sy9: "",
            sy6: "",
            id: "1592",
            sy5: "ܐܶܬ݂ܚܙܺܝ",
            sy4: "ܐܬܚܙܝ",
            lt11: ""
        },
        {
            sy9: "",
            sy7: "",
            sy6: "",
            lt11: "",
            lt12: "",
            dt2: "",
            sy5: "ܡܷܢ ܟܳܥܰܘܕܰܬ؟",
            lt10: "Mën kocawdat?",
            dt1: "Was arbeitest du ?",
            sy4: "ܡܷܢ ܟܥܘܕܬ؟",
            "date": {
                "seconds": 1587246379,
                "nanoseconds": 243000000
            },
            dt3: "",
            id: "T87HL5N6ImbFPnxC1pq7",
            sy8: ""
        },
        {
            lt10: "ĥayoțutho",
            id: "4000",
            sy6: "",
            sy8: "",
            lt12: "",
            lt11: "",
            dt1: "Schneiderhandwerk",
            dt3: "",
            dt2: "",
            sy9: "",
            sy5: "ܚܰܝܳܛܽܘܬ݂ܳܐ",
            sy4: "ܚܝܛܘܬܐ",
            sy7: ""
        },
        {
            dt3: "",
            dt2: "",
            sy8: "ܫܪܚ",
            lt11: "fasheq",
            sy7: "ܦܰܫܶܩ",
            sy6: "ܦܫܩ",
            sy9: "ܫܪܰܚ",
            sy5: "ܬܰܪܓܶܡ",
            lt12: "shraĥ",
            id: "4619",
            dt1: "übersetzen",
            sy4: "ܬܪܓܡ",
            lt10: "targem"
        },
        {
            sy9: "",
            lt10: "fargun rshomo",
            sy5: "ܦܰܪܓܽܘܢ ܪܫܳܡܳܐ",
            id: "3466",
            sy4: "ܦܪܓܘܢ ܪܫܡܐ",
            sy7: "",
            sy8: "",
            lt11: "",
            sy6: "",
            lt12: "",
            dt3: "",
            dt1: "Pinsel",
            dt2: ""
        },
        {
            lt11: "mațĉonoith",
            lt12: "",
            sy9: "",
            sy5: "ܡܫܰܕܠܳܢܳܐ",
            sy6: "ܡܛܥܢܐܝܬ",
            dt1: "irreführend",
            dt3: "",
            lt10: "mshadlono",
            id: "6165",
            sy4: "ܡܫܕܠܢܐ",
            sy8: "",
            dt2: "trügerisch",
            sy7: "ܡܰܛܥܳܢܳܐܝܺܬ݂"
        },
        {
            id: "5391",
            lt10: "d`lo keshlo",
            dt1: "zweifellos",
            sy7: "",
            dt2: "",
            sy5: "ܕܠܳܐ ܟܶܫܠܳܐ",
            sy4: "ܕܠܐ ܟܫܠܐ",
            sy8: "",
            sy6: "",
            dt3: "",
            lt12: "",
            sy9: "",
            lt11: ""
        },
        {
            sy9: "",
            lt12: "",
            sy5: "ܗܰܠܳܟ݂ܳܐ",
            dt3: "",
            sy8: "",
            dt2: "Läufer",
            sy7: "ܪܰܗܳܛܳܐ",
            lt11: "rahoțo",
            id: "3692",
            sy6: "ܪܗܛܐ",
            dt1: "Renner",
            sy4: "ܗܠܟܐ",
            lt10: "halokho"
        },
        {
            dt1: "Schlita",
            id: "3971",
            sy4: "ܫܠܝܬܐ",
            sy5: "ܫܠܺܝܬܰܐ",
            sy7: "",
            lt11: "ܫܠܝܬܐ",
            dt3: "",
            dt2: "",
            sy9: "",
            sy8: "",
            sy6: "",
            lt12: "",
            lt10: "ܫܠܝܬܐ"
        },
        {
            dt2: "flehen",
            lt12: "",
            lt11: "",
            sy8: "",
            dt1: "anflehen",
            id: "232",
            sy4: "ܬܟܫܦܐܝܬ",
            lt10: "takhshfoith",
            sy9: "",
            sy6: "",
            sy5: "ܬܰܟܫܳܦܳܐܺܝܬ݂",
            sy7: "",
            dt3: ""
        },
        {
            sy9: "",
            sy7: "ܐܳܣܳܪܳܐ",
            sy5: "ܐܰܣܺܝܪܽܘܬ݂ܳܐ",
            sy8: "",
            sy4: "ܐܣܝܪܘܬܐ",
            dt2: "Bindung",
            id: "2588",
            lt11: "esoro",
            lt12: "",
            sy6: "ܐܣܪܐ",
            lt10: "asirutho",
            dt3: "Beziehung",
            dt1: "Kontakt"
        },
        {
            dt3: "",
            sy8: "",
            dt2: "",
            lt11: "",
            id: "959",
            sy9: "",
            sy6: "",
            lt10: "daroğto da`tloth giğle",
            sy4: "ܕܪܓܬܐ ܕܬܠܬ ܓܝܓܠܐ",
            sy7: "",
            lt12: "",
            dt1: "Dreirad",
            sy5: "ܕܰܪܓ݂ܬܳܐ ܕܰܬܠܳܬ݂ ܓܺܝ̈ܓ݂ܠܶܐ"
        },
        {
            sy9: "",
            sy4: "ܡܬܠܝܐ",
            sy5: "ܡܰܬ݂ܠܳܝܳܐ",
            sy7: "",
            lt12: "",
            dt2: "",
            dt3: "",
            sy8: "",
            sy6: "",
            dt1: "rätselhaft",
            id: "6142",
            lt10: "mathloyo",
            lt11: ""
        },
        {
            lt12: "",
            sy9: "",
            lt11: "",
            dt2: "",
            lt10: "yamo rabo",
            sy8: "",
            id: "1740",
            sy4: "ܝܡܐ ܪܒܐ",
            dt1: "großes Meer",
            sy6: "",
            sy5: "ܝܰܡܳܐ ܪܰܒܳܐ",
            sy7: "",
            dt3: ""
        },
        {
            dt3: "Mirjam",
            dt1: "Maryam",
            sy9: "",
            lt10: "ܡܪܝܡ",
            lt11: "ܡܪܝܡ",
            lt12: "ܡܝܪܝܡ",
            sy6: "ܡܝܪܝܡ",
            sy7: "ܡܺܝܪܝܰܡ",
            sy4: "ܡܪܝܡ",
            sy8: "",
            dt2: "Marjam",
            sy5: "ܡܰܪܝܰܡ",
            id: "2954"
        },
        {
            dt3: "",
            sy4: "ܫܢܐ",
            lt11: "",
            id: "4791",
            dt2: "",
            sy5: "ܫܢܳܐ",
            sy9: "",
            lt12: "",
            dt1: "verrückt sein",
            sy8: "",
            lt10: "shno",
            sy6: "",
            sy7: ""
        },
        {
            lt12: "",
            dt3: "",
            dt1: "Nebelscheinwerfer",
            sy5: "ܠܰܡܦܺܝܐܕ ܥܰܪܦܶܠܳܐ",
            id: "3229",
            lt10: "lamfid ĉarfelo",
            sy4: "ܠܡܦܝܐܕ ܥܪܦܠܐ",
            lt11: "",
            sy8: "",
            sy7: "",
            dt2: "Nebellicht",
            sy9: "",
            sy6: ""
        },
        {
            dt2: "Kosmetiktücher",
            dt1: "Tücher",
            sy7: "",
            sy6: "",
            sy4: "ܡܢܕܝܠܐ",
            lt12: "",
            lt10: "mandilo",
            dt3: "Taschentuch",
            lt11: "",
            id: "4589",
            sy9: "",
            sy5: "ܡܰܢܕܺܝܠܳܐ",
            sy8: ""
        },
        {
            lt10: "fŝiĥoyo",
            sy7: "",
            dt2: "",
            lt12: "",
            sy9: "",
            lt11: "",
            sy4: "ܦܨܝܚܝܐ",
            sy8: "",
            id: "1669",
            dt3: "",
            sy6: "",
            sy5: "ܦܨܺܝܚܳܝܳܐ",
            dt1: "Glücklicher"
        },
        {
            sy5: "ܢܶܨܠܳܐ",
            lt12: "",
            id: "4395",
            lt11: "",
            dt1: "Tasse",
            sy9: "",
            sy7: "",
            dt3: "",
            lt10: "neŝlo",
            sy4: "ܢܨܠܐ",
            sy8: "",
            dt2: "",
            sy6: ""
        },
        {
            sy4: "ܦܢܛܝܘܣ ܦܝܠܛܘܣ",
            sy5: "ܦܢܛܝܘܣ ܦܝܠܛܘܣ",
            dt2: "Pilatus",
            dt1: "Pontius Pilatus",
            sy8: "",
            lt12: "",
            dt3: "",
            lt11: "",
            sy7: "",
            lt10: "fentius filatus",
            id: "3489",
            sy6: "",
            sy9: ""
        },
        {
            sy7: "ܫܡܽܘܟ݂",
            sy5: "ܫܽܘܡܰܟ",
            lt12: "ܫܡܘܟ",
            id: "4005",
            sy4: "ܫܘܒܟ",
            lt11: "ܫܘܒܟ",
            sy6: "ܫܡܘܟ",
            dt1: "Schobakh",
            lt10: "ܫܘܒܟ",
            sy8: "",
            sy9: "",
            dt2: "",
            dt3: ""
        },
        {
            sy4: "ܦܬܫ",
            lt11: "",
            sy8: "",
            sy6: "",
            id: "981",
            lt10: "fatesh",
            dt2: "",
            sy7: "",
            sy9: "",
            dt3: "",
            sy5: "ܦܰܬܶܫ",
            lt12: "",
            dt1: "durchsuchen"
        },
        {
            sy7: "",
            dt3: "",
            dt1: "Abbildung",
            sy8: "",
            sy9: "",
            sy5: "ܡܛܰܦܣܳܢܽܘܬ݂ܳܐ",
            lt12: "",
            lt11: "",
            id: "5550",
            dt2: "",
            lt10: "mțafŝonutho",
            sy6: "",
            sy4: "ܡܛܦܣܢܘܬܐ"
        },
        {
            sy8: "",
            dt2: "",
            sy7: "",
            sy6: "",
            id: "3611",
            dt1: "Rassentrennung",
            lt12: "",
            sy9: "",
            lt11: "",
            sy4: "ܦܪܝܫܘܬ ܓܢܣܐ",
            sy5: "ܦܪܺܝܫܽܘܬ݂ ܓܶܢܣܳܐ",
            lt10: "frishuth genso",
            dt3: ""
        },
        {
            sy6: "",
            sy7: "",
            id: "3108",
            sy9: "",
            sy4: "ܠܡܚܪ ܛܗܪܐ",
            lt12: "",
            dt3: "",
            lt11: "",
            dt2: "",
            sy5: "ܠܰܡܚܳܪ ܛܰܗܪܳܐ",
            sy8: "",
            lt10: "lamĥor țahro",
            dt1: "morgen mittag"
        },
        {
            sy6: "",
            sy9: "",
            sy5: "ܦܪܽܘܛܳܝܽܘܬ݂ܳܐ",
            dt2: "",
            lt10: "fruțoyutho",
            lt12: "",
            id: "3530",
            sy4: "ܦܪܘܛܝܘܬܐ",
            sy8: "",
            sy7: "",
            lt11: "",
            dt3: "",
            dt1: "Protestantismus"
        },
        {
            dt3: "",
            sy4: "ܒܨܝܪܘܬܐ",
            sy9: "ܢܽܘܩܨܰܢܺܝܶܐ",
            sy5: "ܒܨܺܝܪܽܘܬ݂ܳܐ",
            lt12: "nuqŝaniye",
            dt2: "Reduktion",
            sy6: "ܒܘܨܪܐ",
            lt11: "buŝoro",
            sy8: "ܢܘܩܨܢܝܐ",
            id: "3654",
            dt1: "Reduzierung",
            lt10: "bŝirutho",
            sy7: "ܒܽܘܨܳܪܳܐ"
        },
        {
            sy6: "",
            sy5: "ܣܽܘܒܳܪܳܐ",
            lt11: "",
            dt1: "Advent",
            id: "5786",
            dt2: "",
            sy7: "",
            sy4: "ܣܘܒܪܐ",
            lt10: "suboro",
            sy9: "",
            lt12: "",
            sy8: "",
            dt3: ""
        },
        {
            dt1: "Rest",
            sy9: "",
            sy8: "",
            sy7: "ܘ ܫܰܪܟܳܐ",
            sy4: "ܫܪܟܐ",
            id: "3704",
            lt12: "",
            dt3: "",
            lt11: "u sharko",
            dt2: "und so weiter",
            lt10: "sharko",
            sy6: "ܘ ܫܪܟܐ",
            sy5: "ܫܰܪܟܳܐ"
        },
        {
            sy8: "",
            sy5: "ܫܠܳܡܳܐ",
            lt10: "ܫܠܡܐ",
            sy7: "",
            sy9: "",
            sy4: "ܫܠܡܐ",
            sy6: "",
            lt11: "ܫܠܡܐ",
            dt2: "",
            lt12: "",
            id: "3973",
            dt1: "Schlomo",
            dt3: ""
        },
        {
            id: "5838",
            dt3: "",
            dt1: "Alleinstehen",
            lt11: "bulĥodo",
            sy7: "ܒܽܘܠܚܳܕܳܐ",
            sy5: "ܠܚܽܘܕܳܝܽܘܬ݂ܳܐ",
            sy9: "",
            sy4: "ܠܚܘܕܝܘܬܐ",
            dt2: "",
            lt10: "lĥudoyutho",
            sy6: "ܒܘܠܚܕܐ",
            lt12: "",
            sy8: ""
        },
        {
            lt12: "",
            sy5: "ܕܰܘܡܳܐ",
            dt2: "",
            sy7: "ܐܺܝܠܳܢܳܐ",
            sy4: "ܕܘܡܐ",
            lt10: "daumo",
            id: "513",
            lt11: "ilono",
            sy8: "",
            sy9: "",
            dt3: "",
            dt1: "Baum",
            sy6: "ܐܝܠܢܐ"
        },
        {
            sy7: "",
            dt1: "Gabriyel geht es auch gut",
            lt12: "",
            sy5: "ܐܘ ܓܰܒܪܝـܝܶܠ ܣܬܶܐ ܛܰܘܘܐ ܝܐ",
            sy4: "ܐܘ ܓܒܪܝـܝܠ ܣܬܐ ܛܘܘܐ ܝܐ",
            id: "6452",
            sy6: "",
            sy8: "",
            lt11: "",
            lt10: "U Gabriyel ste ṭ­awwo yo",
            dt2: "",
            sy9: "",
            dt3: ""
        },
        {
            sy7: "ܟܪܺܝܣܬܳܦܳܪܽܘܣ",
            lt10: "ܟܪܝܣܛܦܪܘܣ",
            sy8: "",
            sy5: "ܟܪܺܝܣܛܳܦܳܪܽܘܣ",
            lt11: "ܟܪܝܣܛܦܪܘܣ",
            sy6: "ܟܪܝܬܦܪܘܣ",
            sy9: "",
            dt2: "Christoforus",
            dt3: "",
            dt1: "Christophorus",
            id: "772",
            sy4: "ܟܪܝܣܛܦܪܘܣ",
            lt12: "ܟܪܣܬܦܪܘܣ"
        },
        {
            dt2: "Magazin",
            dt3: "",
            sy4: "ܡܓܠܬܐ",
            id: "5290",
            sy6: "",
            lt11: "",
            sy9: "",
            sy7: "",
            sy5: "ܡܓ݂ܠܬܳܐ",
            lt10: "mğalto",
            sy8: "",
            lt12: "",
            dt1: "Zeitschrift"
        },
        {
            sy6: "ܢܢ",
            sy8: "",
            sy5: "ܚܰܡܫܺܝܢ ",
            sy4: "ܚܡܫܝܢ",
            lt10: "ĥamshin",
            sy9: "",
            dt1: "50",
            lt11: "nun",
            sy7: "ܢܢ",
            lt12: "",
            id: "33",
            dt3: "",
            dt2: "Fünfzig"
        },
        {
            lt11: "",
            id: "4257",
            dt2: "",
            dt1: "stellvertretender Bürgermeister",
            sy5: "ܬܰܚܠܽܘܦ ܪܺܝܫ ܟܰܪܟ݂ܽܘܬ݂ܳܐ",
            sy6: "",
            dt3: "",
            sy7: "",
            sy9: "",
            lt12: "",
            sy8: "",
            sy4: "ܬܚܠܘܦ ܪܝܫ ܟܪܟܘܬܐ",
            lt10: "taĥluf rish karkutho"
        },
        {
            dt3: "",
            sy5: "ܡܰܘܬܒܳܐ",
            sy7: "",
            dt1: "Couch",
            sy9: "",
            sy6: "",
            sy8: "",
            lt10: "mautbo",
            lt12: "",
            dt2: "Sitzbank",
            id: "776",
            lt11: "",
            sy4: "ܡܘܬܒܐ"
        },
        {
            sy5: "ܣܰܢܕܽܘܩ ܒܺܝܠܕܰܪܳܐ",
            lt12: "",
            lt10: "sanduq bildaro",
            lt11: "",
            sy6: "",
            sy8: "",
            sy9: "",
            sy7: "",
            sy4: "ܣܢܕܘܩ ܒܝܠܕܪܐ",
            dt1: "Postfach",
            id: "3499",
            dt2: "",
            dt3: ""
        },
        {
            sy9: "",
            lt12: "",
            dt2: "",
            sy6: "",
            dt1: "zwicken",
            lt11: "",
            sy8: "",
            dt3: "",
            id: "5399",
            lt10: "qraŝ",
            sy7: "",
            sy5: "ܩܪܰܨ",
            sy4: "ܩܪܨ"
        },
        {
            lt12: "",
            sy8: "",
            dt1: "schenken",
            dt2: "",
            sy6: "ܡܕܫܢܐ",
            dt3: "",
            lt10: "mdashen",
            id: "3925",
            sy4: "ܡܕܫܢ",
            sy9: "",
            sy5: "ܡܕܰܫܶܢ",
            lt11: "mdashno",
            sy7: "ܡܕܰܫܢܳܐ"
        },
        {
            dt2: "",
            dt3: "",
            id: "4666",
            dt1: "unterrichten",
            sy7: "",
            sy5: "ܡܰܠܶܦ",
            lt11: "",
            sy6: "",
            sy8: "",
            sy4: "ܡܠܦ",
            lt10: "malef",
            sy9: "",
            lt12: ""
        },
        {
            sy9: "",
            dt3: "",
            sy5: "ܡܦܰܩܰܥܬܳܐ ܢܶܬ݂ܪܳܢܳܝܬܳܐ",
            dt1: "Atombombe",
            lt10: "mfaqaĉto nethronoyto",
            dt2: "",
            lt11: "",
            sy6: "",
            sy4: "ܡܦܩܥܬܐ ܢܬܪܢܝܬܐ",
            sy8: "",
            sy7: "",
            id: "335",
            lt12: ""
        },
        {
            sy7: "",
            id: "5291",
            sy4: "ܣܦܪ ܝܘܡܐ",
            dt2: "",
            sy6: "",
            sy8: "",
            dt3: "",
            lt10: "sfar yaumo",
            sy9: "",
            dt1: "Zeitung",
            lt12: "",
            sy5: "ܣܦܰܪ ܝܰܘܡܳܐ",
            lt11: ""
        },
        {
            sy5: "ܡܪܰܝܡܳܐ",
            dt3: "",
            sy7: "ܪܳܡܳܐ",
            lt10: "mraymo",
            sy8: "",
            sy4: "ܡܪܝܡܐ",
            sy6: "ܪܡܐ",
            lt12: "",
            id: "6236",
            dt1: "sublim",
            sy9: "",
            lt11: "romo",
            dt2: ""
        },
        {
            sy7: "ܛܰܒܥܽܘܢܳܐ",
            lt11: "țabĉuno",
            sy6: "ܛܒܥܘܢܐ",
            dt3: "",
            dt1: "Hostie",
            sy9: "",
            sy5: "ܛܰܒܥܳܐ",
            lt12: "",
            id: "6025",
            lt10: "țabĉo",
            sy4: "ܛܒܥܐ",
            dt2: "",
            sy8: ""
        },
        {
            lt12: "",
            lt10: "abdonoyo",
            sy5: "ܐܰܒܕܳܢܳܝܳܐ",
            lt11: "",
            sy9: "",
            sy7: "",
            dt2: "schädlich",
            sy4: "ܐܒܕܢܝܐ",
            sy6: "",
            dt3: "",
            id: "6384",
            sy8: "",
            dt1: "verderblich"
        },
        {
            dt2: "Lederhaut des Auges",
            lt11: "",
            sy4: "ܘܪܘܪܐ",
            lt12: "",
            sy7: "",
            sy8: "",
            sy9: "",
            dt1: "Sklera",
            sy5: "ܘܰܪܘܳܪܳܐ",
            sy6: "",
            dt3: "Umhüllung des Auges",
            id: "4171",
            lt10: "warworo"
        },
        {
            sy4: "ܥܒܐ",
            lt12: "",
            lt10: "ĉobo",
            sy8: "",
            sy7: "",
            dt1: "Wald",
            dt3: "",
            sy5: "ܥܳܒܳܐ",
            sy9: "",
            id: "4970",
            sy6: "",
            lt11: "",
            dt2: ""
        },
        {
            sy7: "ܡܳܪܶܐ ܦܽܘܠܚܳܢܳܐ",
            sy5: "ܝܳܗܶܒ ܦܽܘܠܚܳܢܳܐ",
            sy8: "",
            lt11: "more fulĥono",
            sy9: "",
            lt10: "yoheb fulĥono",
            id: "278",
            sy4: "ܝܗܒ ܦܘܠܚܢܐ",
            dt1: "Arbeitgeber",
            dt3: "",
            lt12: "",
            sy6: "ܡܪܐ ܦܘܠܚܢܐ",
            dt2: ""
        },
        {
            sy5: "ܬܰܟ݂ܬܽܘܫ ܡ̈ܶܠܶܐ",
            dt3: "",
            lt12: "",
            id: "5172",
            lt11: "",
            sy4: "ܬܟܬܘܫ ܡܠܐ",
            sy6: "",
            lt10: "takhtush mele",
            sy9: "",
            dt2: "",
            sy8: "",
            dt1: "Wortwechsel",
            sy7: ""
        },
        {
            lt10: "tenurto",
            sy5: "ܬܶܢܽܘܪܬܳܐ",
            lt11: "tenuro",
            dt3: "",
            sy9: "",
            id: "3735",
            sy8: "",
            lt12: "",
            sy6: "ܬܢܘܪܐ",
            sy7: "ܬܶܢܽܘܪܳܐ",
            dt2: "Minirock",
            dt1: "Rock",
            sy4: "ܬܢܘܪܬܐ"
        },
        {
            sy9: "",
            sy6: "ܐܠܦܐ",
            sy4: "ܣܦܝܬܐ",
            sy5: "ܣܦܺܝܬ݂ܳܐ",
            dt3: "",
            id: "3948",
            lt11: "elfo",
            sy7: "ܐܶܠܦܳܐ",
            lt12: "",
            sy8: "",
            dt2: "",
            dt1: "Schiff",
            lt10: "sfitho"
        },
        {
            sy9: "",
            sy8: "",
            sy4: "ܐܦܢ",
            id: "3335",
            sy7: "ܘܳܐܦܶܢ",
            sy5: "ܐܳܦܶܢ",
            sy6: "ܘܐܦܢ",
            dt1: "obwohl",
            dt3: "",
            lt11: "wofen",
            lt10: "ofen",
            lt12: "",
            dt2: ""
        },
        {
            sy9: "",
            sy7: "",
            sy5: "ܡܙܺܝܓ݂ܠܳܐ",
            sy4: "ܡܙܝܓܠܐ",
            lt10: "msiğlo",
            sy8: "",
            sy6: "",
            dt1: "Motorrad",
            id: "3116",
            dt2: "Moped",
            dt3: "",
            lt11: "",
            lt12: ""
        },
        {
            sy9: "ܩܰܕܺܝܡܽܘܬ݂ ܣܝܳܡܳܐ",
            dt2: "",
            sy4: "ܡܚܫܒܬܐ",
            sy7: "ܥܶܠܳܐ",
            sy5: "ܡܰܚܫܰܒܬ݂ܳܐ",
            sy6: "ܥܠܐ",
            sy8: "ܩܕܝܡܘܬ ܣܝܡܐ",
            dt3: "",
            dt1: "Absicht",
            lt12: "qadimuth syomo",
            lt11: "ĉelo",
            id: "5649",
            lt10: "maĥshabtho"
        },
        {
            dt1: "gleich",
            sy9: "",
            dt3: "",
            lt11: "",
            dt2: "äquivalent",
            sy6: "",
            sy4: "ܫܘܝܐ",
            lt12: "",
            sy7: "",
            sy8: "",
            lt10: "shauwyo",
            id: "1655",
            sy5: "ܫܰܘܝܳܐ"
        },
        {
            dt3: "",
            sy9: "",
            lt10: "mĥuto d`galo",
            sy5: "ܡܚܽܘܬܳܐ ܕܓܰܠܠܳܐ",
            lt12: "",
            sy4: "ܡܚܘܬܐ ܕܓܠܠܐ",
            lt11: "",
            dt1: "Wellenschlag",
            sy8: "",
            sy6: "",
            sy7: "",
            dt2: "",
            id: "5046"
        },
        {
            id: "2425",
            sy9: "",
            lt10: "yamo ĥroyo qasfiyon",
            sy7: "",
            sy5: "ܝܰܡܳܐ ܐ̱ܚܪܳܝܳܐ ܩܣܦܝܘܢ",
            sy4: "ܝܡܐ ܐܚܪܝܐ ܩܣܦܝܘܢ",
            lt12: "",
            dt1: "Kaspisches Meer",
            sy6: "",
            sy8: "",
            lt11: "",
            dt3: "",
            dt2: ""
        },
        {
            dt1: "Rathaus",
            sy7: "",
            lt11: "",
            sy8: "",
            sy5: "ܟܰܪܟ݂ܘܽܬ̣ܳܐ",
            id: "5414",
            sy4: "ܟܪܟ̣ܘܬ̣ܐ",
            lt12: "",
            sy6: "",
            dt3: "",
            lt10: "karkhutho",
            sy9: "",
            dt2: "Stadthaus"
        },
        {
            id: "89",
            sy9: "",
            sy6: "",
            lt10: "tuĥomo",
            lt12: "",
            dt1: "Abgrenzung",
            sy5: "ܬܽܘܚܳܡܳܐ",
            dt3: "",
            sy7: "",
            dt2: "",
            sy8: "",
            sy4: "ܬܘܚܡܐ",
            lt11: ""
        },
        {
            sy7: "ܒܶܝܬ݂ ܕܐܰܟܳ̈ܪܶܐ",
            dt2: "",
            sy4: "ܒܝܬ ܐܟܪܐ",
            id: "512",
            lt11: "beth d`akore",
            lt12: "",
            sy5: "ܒܶܝܬ݂ ܐܰܟܳ̈ܪܶܐ",
            sy9: "",
            dt1: "Bauernhof",
            sy8: "",
            lt10: "beth akore",
            sy6: "ܒܝܬ ܕܐܟܪܐ",
            dt3: ""
        },
        {
            sy7: "",
            lt12: "",
            sy4: "ܚܙܐ ܕܘܟܬܐ",
            sy6: "",
            sy5: "ܚܙܳܐ ܕܽܘܟܬ݂ܳܐ",
            sy8: "",
            lt10: "ĥzo duktho",
            dt3: "",
            lt11: "",
            sy9: "",
            dt2: "Stelle finden",
            id: "3474",
            dt1: "Platz finden"
        },
        {
            dt1: "Unterlippe",
            sy5: "ܣܶܦܬܳܐ ܬܰܚܬܳܝܬܳܐ",
            sy6: "",
            lt12: "",
            sy7: "",
            dt2: "",
            id: "4664",
            sy8: "",
            lt10: "sefto taĥtoyto",
            lt11: "",
            sy4: "ܣܦܬܐ ܬܚܬܝܬܐ",
            dt3: "",
            sy9: ""
        },
        {
            lt12: "",
            sy6: "",
            sy4: "ܡܘܠܕܐ",
            sy5: "ܡܰܘܠܳܕܳܐ",
            sy7: "",
            dt2: "",
            id: "1503",
            dt1: "Geburt",
            sy9: "",
            dt3: "",
            sy8: "",
            lt11: "",
            lt10: "maulodo"
        },
        {
            lt10: "rebithonoyo",
            id: "5329",
            sy7: "",
            sy8: "",
            sy4: "ܪܒܝܬܢܝܐ",
            dt2: "",
            dt3: "",
            sy9: "",
            lt11: "",
            lt12: "",
            dt1: "zinsbringend",
            sy6: "",
            sy5: "ܪܶܒܺܝܬ݂ܳܢܳܝܳܐ"
        },
        {
            dt1: "rechts herum",
            sy4: "ܠܝܡܝܢܐ",
            dt3: "",
            lt12: "",
            dt2: "",
            id: "3639",
            lt10: "l`yamino",
            lt11: "",
            sy9: "",
            sy8: "",
            sy7: "",
            sy6: "",
            sy5: "ܠܝܰܡܺܝܢܳܐ"
        },
        {
            sy8: "",
            sy4: "ܐܣܝܐ ܓܘܝܐ",
            dt3: "",
            dt1: "Internist",
            lt10: "osyo gawoyo",
            sy6: "",
            lt12: "",
            lt11: "",
            dt2: "",
            sy7: "",
            id: "2230",
            sy9: "",
            sy5: "ܐܳܣܝܳܐ ܓܰܘܳܝܳܐ   "
        },
        {
            sy4: "ܝܡܐ ܕܐܛܠܢܛܝܩܝ",
            sy8: "",
            sy9: "",
            dt3: "Atlantik",
            lt12: "",
            sy6: "",
            lt10: "yamo d`ațlanțiqi",
            lt11: "",
            dt1: "Atlantischer Ozean",
            dt2: "Ozean Atlantik",
            sy5: "ܝܳܡܳܐ ܕܰܐܛܰܠܢܛܺܝܩܺܝ",
            id: "332",
            sy7: ""
        },
        {
            lt12: "",
            sy5: "ܒܗܳܢ ܣܰܦܪܳܐ",
            id: "5897",
            sy7: "",
            sy4: "ܒܗܢ ܣܦܪܐ",
            sy9: "",
            sy6: "",
            sy8: "",
            dt1: "an diesem Morgen",
            lt11: "",
            dt2: "",
            lt10: "bhon safro",
            dt3: ""
        },
        {
            lt11: "",
            sy7: "",
            id: "4693",
            sy5: "ܙܽܘ̈ܙܶܐ ܕܒܶܛܠܳܢܳܐ",
            sy8: "",
            dt2: "",
            dt1: "Urlaubsgeld",
            dt3: "",
            sy9: "",
            sy4: "ܙܘܙܐ ܕܒܛܠܢܐ",
            lt12: "",
            sy6: "",
            lt10: "zuze d`bețlono"
        },
        {
            sy8: "",
            sy7: "",
            sy5: "ܒܥܽܘܗܕܳܢܳܐ ܠܙܰܒܢܳܐ ܥܰܬܺܝܩܳܐ",
            id: "5452",
            dt1: "In Erinnerungen an die alte Zeit",
            dt3: "",
            lt12: "",
            sy4: "ܒܥܽܘܗܕܳܢܳܐ ܠܙܰܒܢܳܐ ܥܰܬܺܝܩܳܐ",
            dt2: "",
            lt11: "",
            sy9: "",
            sy6: "",
            lt10: "bĉudono lzabno ĉatiqo"
        },
        {
            dt1: "Eva",
            sy9: "",
            lt12: "",
            sy6: "",
            dt3: "",
            dt2: "Hawo",
            sy8: "",
            sy5: "ܚܰܘܳܐ",
            id: "1204",
            lt11: "ܚܘܐ",
            lt10: "ܚܘܐ",
            sy7: "",
            sy4: "ܚܘܐ"
        },
        {
            dt3: "",
            lt11: "yaluđo",
            sy6: "ܝܠܘܕܐ",
            dt1: "Kind",
            lt10: "shabro",
            dt2: "Knabe",
            lt12: "yaldo",
            sy8: "ܝܠܕܐ",
            id: "2471",
            sy9: "ܝܰܠܕܳܐ",
            sy7: "ܝܰܠܽܘܕܳܐ",
            sy4: "ܫܒܪܐ",
            sy5: "ܫܰܒܪܳܐ"
        },
        {
            dt3: "Schrei",
            dt1: "Rufen",
            id: "3763",
            lt12: "qĉoyo",
            lt10: "mazĉqonutho",
            dt2: "Ruf",
            sy8: "ܩܥܝܐ",
            sy4: "ܡܙܥܩܢܘܬܐ",
            sy5: "ܡܰܙܥܩܳܢܽܘܬ݂ܳܐ",
            lt11: "zĉoqo",
            sy9: "ܩܥܳܝܳܐ",
            sy6: "ܙܥܩܐ",
            sy7: "ܙܥܳܩܳܐ"
        },
        {
            lt10: "ĉezaqtho d`zuwoğo",
            dt3: "",
            dt2: "Ehering",
            sy7: "",
            dt1: "Trauring",
            sy4: "ܥܙܩܬܐ ܕܙܘܘܓܐ",
            lt12: "",
            sy6: "",
            lt11: "",
            sy5: "ܥܶܙܩܰܬ݂ܐ ܕܙܽܘܘܳܓ݂ܳܐ",
            sy8: "",
            sy9: "",
            id: "4553"
        },
        {
            sy5: "ܪܽܘܫܡܳܝܳܐ",
            sy6: "ܪܘܫܡܢܝܐ",
            dt1: "offiziell",
            dt3: "",
            lt10: "rushmoyo",
            lt12: "dußfuțiqo",
            sy8: "ܕܘܣܦܘܛܝܩܐ",
            lt11: "rushmonoyo",
            dt2: "",
            id: "3348",
            sy4: "ܪܘܫܡܝܐ",
            sy9: "ܕܽܘܣܦܽܘܛܺܝܩܳܐ",
            sy7: "ܪܽܘܫܡܳܢܳܝܳܐ"
        },
        {
            dt2: "",
            sy9: "",
            sy6: "",
            lt12: "",
            sy8: "",
            lt11: "",
            sy7: "",
            id: "1130",
            lt10: "ethraĥameĉlayn",
            sy4: "ܐܬܪܚܡܥܠܝܢ",
            dt3: "",
            sy5: "ܐܶܬ݂ܪܰܚܰܡܶܥܰܠܰܝܢ",
            dt1: "erbarme dich unser"
        },
        {
            dt1: "Adliger",
            sy8: "ܐܘܛܪܢܐ",
            id: "5765",
            sy4: "ܙܗܝܐ",
            sy7: "ܦܰܛܪܺܝܩܳܐ",
            lt11: "fațriqo",
            sy6: "ܦܛܪܝܩܐ",
            dt3: "",
            dt2: "",
            sy9: "ܐܽܘܛܪܳܢܳܐ",
            lt10: "zahyo",
            sy5: "ܙܰܗܝܳܐ",
            lt12: "uțrono"
        },
        {
            dt1: "Kochtopf",
            id: "2553",
            lt12: "",
            lt10: "dudo",
            dt2: "Küchentopf",
            dt3: "Topf",
            sy4: "ܕܘܕܐ",
            sy6: "",
            sy9: "",
            sy5: "ܕܽܘܕܳܐ",
            sy8: "",
            sy7: "",
            lt11: ""
        },
        {
            dt3: "Erbschaft",
            lt11: "",
            sy8: "",
            sy5: "ܝܳܪܬܽܘܬ݂ܳܐ",
            dt1: "Erbe",
            sy7: "",
            lt10: "yortutho",
            id: "1131",
            sy9: "",
            sy4: "ܝܪܬܘܬܐ",
            sy6: "",
            lt12: "",
            dt2: "Vererbung"
        },
        {
            sy4: "ܝܪܬ",
            sy8: "",
            sy9: "",
            sy5: "ܝܺܪܶܬ݂",
            dt2: "erben",
            sy6: "",
            id: "521",
            lt12: "",
            sy7: "",
            dt3: "",
            lt10: "jireth",
            lt11: "",
            dt1: "beerben"
        },
        {
            sy8: "",
            sy6: "",
            id: "3457",
            lt12: "",
            dt3: "",
            lt11: "",
            sy7: "",
            sy5: "ܫܽܘܡܢܳܐ ܕܓܶܠܳܐ",
            dt1: "Pflanzenfett",
            sy9: "",
            lt10: "shumno d`gelo",
            sy4: "ܫܘܡܢܐ ܕܓܠܐ",
            dt2: ""
        },
        {
            sy4: "ܐܪܙ",
            sy9: "",
            dt1: "anleiten",
            lt10: "arez",
            lt12: "",
            dt3: "",
            sy7: "",
            sy5: "ܐܰܪܶܙ",
            lt11: "",
            sy8: "",
            id: "5954",
            sy6: "",
            dt2: ""
        },
        {
            id: "4363",
            sy8: "",
            lt11: "",
            sy4: "ܟܬܒܐ ܕܥܘܗܕܢܐ",
            sy7: "",
            dt3: "",
            sy5: "ܟܬ݂ܳܒܳܐ ܕܥܽܘܗܕܳܢܶܐ",
            lt12: "",
            sy9: "",
            dt1: "Tagebuch",
            dt2: "",
            lt10: "kthobo d`ĉuhdone",
            sy6: ""
        },
        {
            lt12: "",
            id: "4868",
            sy9: "",
            lt10: "gumoro",
            lt11: "shulomo",
            dt1: "Vollendung",
            dt3: "",
            sy6: "ܫܘܠܡܐ",
            dt2: "",
            sy7: "ܫܽܘܠܳܡܳܐ",
            sy5: "ܓܽܘܡܳܪܳܐ",
            sy8: "",
            sy4: "ܓܘܡܪܐ"
        },
        {
            sy6: "",
            dt3: "",
            sy4: "ܫܠܚ",
            sy5: "ܫܠܰܚ",
            id: "398",
            dt2: "",
            sy7: "",
            lt12: "",
            sy8: "",
            lt11: "",
            dt1: "ausziehen",
            lt10: "shlaĥ",
            sy9: ""
        },
        {
            lt10: "ortoduksiya",
            sy9: "",
            lt12: "",
            id: "3396",
            sy5: "ܐܳܪܬܳܕܽܘܟܣܺܝܰܐ",
            sy6: "",
            sy7: "",
            dt2: "",
            dt3: "",
            sy8: "",
            lt11: "",
            dt1: "Orthodoxie",
            sy4: "ܐܪܬܕܘܟܣܝܐ"
        },
        {
            id: "752",
            sy6: "ܙܠ ܒܫܠܡܐ",
            lt11: "zel bashlomo",
            lt10: "fush bashlomo",
            dt2: "Ciao",
            sy8: "",
            sy4: "ܦܘܫ ܒܫܠܡܐ",
            dt3: "",
            lt12: "",
            sy9: "",
            sy5: "ܦܽܘܫ ܒܰܫܠܳܡܳܐ",
            sy7: "ܙܶܠ ܒܰܫܠܳܡܳܐ",
            dt1: "Bye"
        },
        {
            sy9: "",
            sy5: "ܛܽܘܟܳܣ ܦܪܳܥܳܐ",
            sy8: "",
            dt1: "Tarifregelung",
            lt12: "",
            dt3: "",
            lt10: "țukos froĉo",
            sy7: "",
            sy4: "ܛܘܟܣ ܦܪܥܐ",
            lt11: "",
            sy6: "",
            dt2: "Tarifordnung",
            id: "4389"
        },
        {
            sy8: "",
            sy7: "ܢܽܘܪܗܰܕܰܕ",
            sy5: "ܢܽܘܪܚܰܕܰܕ",
            dt3: "",
            id: "3318",
            lt12: "ܢܘܪܗܕܕ",
            sy6: "ܢܘܪܗܕܕ",
            lt11: "ܢܘܪܕܕ",
            dt1: "Nuradad",
            lt10: "ܢܘܪܕܕ",
            dt2: "Nurhadad",
            sy4: "ܢܘܪܕܕ",
            sy9: ""
        },
        {
            dt3: "",
            lt11: "",
            sy7: "",
            dt1: "König",
            sy9: "",
            sy5: "ܡܰܠܟܳܐ",
            dt2: "Alleinherrscher",
            lt10: "malko",
            id: "2578",
            sy4: "ܡܠܟܐ",
            sy6: "",
            lt12: "",
            sy8: ""
        },
        {
            dt1: "Hamschin",
            sy7: "",
            sy5: "ܚܰܡܫܺܝܢ",
            lt10: "ܚܡܫܝܢ",
            sy9: "",
            lt11: "ܚܡܫܝܢ",
            sy4: "ܚܡܫܝܢ",
            lt12: "",
            sy6: "",
            dt2: "",
            sy8: "",
            id: "1838",
            dt3: ""
        },
        {
            dt3: "",
            lt12: "",
            lt11: "",
            sy9: "",
            dt1: "Schäferhund",
            sy8: "",
            sy4: "ܟܠܒܐ ܕܪܥܝܐ",
            sy6: "",
            sy7: "",
            sy5: "ܟܰܠܒܳܐ ܕܪܳܥܝܳܐ",
            lt10: "kalbo d`roĉyo",
            dt2: "",
            id: "3869"
        },
        {
            lt10: "ܡܪܢܙܟ݂ܐ",
            sy8: "",
            lt11: "ܡܪܢܙܟ݂ܐ",
            sy7: "ܡܳܪܰܢ ܙܟ݂ܳܐ",
            sy9: "",
            id: "3104",
            sy5: "ܡܳܪܰܢܙܟ݂ܳܐ",
            dt2: "",
            dt3: "",
            sy4: "ܡܪܢܙܟ݂ܐ",
            lt12: "ܡܪܢ ܙܟ݂ܐ",
            dt1: "Moranzkho",
            sy6: "ܡܪܢ ܙܟܐ"
        },
        {
            lt10: "rakikho",
            sy9: "",
            sy7: "",
            sy8: "",
            sy5: "ܪܰܟܺܝܟ݂ܳܐ",
            dt3: "",
            sy4: "ܪܟܝܟܐ",
            dt1: "weich",
            id: "5019",
            sy6: "",
            lt12: "",
            dt2: "",
            lt11: ""
        },
        {
            lt12: "",
            id: "1161",
            dt3: "",
            dt1: "Ermahnung",
            dt2: "Achtung",
            sy4: "ܙܘܗܪܐ",
            sy5: "ܙܽܘܗܳܪܳܐ",
            sy8: "",
            lt11: "",
            sy6: "",
            sy7: "",
            sy9: "",
            lt10: "suhoro"
        },
        {
            dt3: "",
            id: "4766",
            sy5: "ܡܙܺܝܓ݂ܳܐ",
            sy6: "",
            lt11: "",
            lt10: "mziğo",
            sy7: "",
            sy9: "",
            sy4: "ܡܙܝܓܐ",
            lt12: "",
            sy8: "",
            dt1: "vermischt",
            dt2: "gemischt"
        },
        {
            sy7: "",
            sy5: "ܗܰܦܟܳܝܳܐ",
            sy6: "",
            lt11: "",
            dt2: "reaktionär",
            sy8: "",
            id: "6090",
            sy4: "ܗܦܟܝܐ",
            dt1: "fortschrittsfeindlich",
            lt12: "",
            dt3: "",
            lt10: "hafkoyo",
            sy9: ""
        },
        {
            id: "2701",
            dt1: "Landsfrau",
            dt2: "",
            lt10: "bath athro",
            lt12: "",
            sy9: "",
            sy5: "ܒܰܪ̱ܬ݂ ܐܰܬ݂ܪܳܐ",
            sy6: "",
            sy4: "ܒܪܬ ܐܬܪܐ",
            sy7: "",
            sy8: "",
            dt3: "",
            lt11: ""
        },
        {
            dt1: "langsam",
            lt12: "",
            lt10: "ĉam zabno",
            dt3: "",
            sy6: "ܗܕܐܝ ܗܕܐܝ",
            sy5: "ܥܰܡ ܙܰܒܢܳܐ",
            lt11: "hedi hedi",
            dt2: "",
            sy8: "",
            sy9: "",
            sy4: "ܥܡ ܙܒܢܐ",
            id: "2710",
            sy7: "ܗܶܕܐܺܝ ܗܶܕܐܺܝ"
        },
        {
            sy9: "",
            sy6: "",
            dt1: "Oberrichter",
            sy4: "ܪܒ ܕܝܢܐ",
            dt2: "Hauptrichter",
            dt3: "",
            lt10: "rab dayone",
            sy8: "",
            lt11: "",
            sy7: "",
            id: "3331",
            lt12: "",
            sy5: "ܪܰܒ ܕܰܝ̈ܳܢܶܐ"
        },
        {
            sy5: "ܣܰܡܝܳܐ",
            sy4: "ܣܡܝܐ",
            id: "648",
            sy6: "",
            dt1: "blind",
            sy8: "",
            dt3: "",
            sy7: "",
            lt11: "",
            dt2: "Blinder",
            lt10: "samyo",
            lt12: "",
            sy9: ""
        },
        {
            id: "2362",
            sy4: "ܓܘܪܝܐ",
            dt1: "Junger Löwe",
            sy5: "ܓܽܘܪܝܳܐ",
            sy8: "",
            sy7: "ܓܽܘܪܝܳܐ ܕܐܰܪܝܳܐ",
            dt3: "",
            sy6: "ܓܘܪܝܐ ܕܐܪܝܐ",
            lt10: "guryo",
            dt2: "",
            lt12: "",
            lt11: "guryo d`aryo",
            sy9: ""
        },
        {
            sy9: "",
            id: "255",
            dt1: "Anzug",
            lt10: "lbusho",
            lt11: "",
            sy4: "ܠܒܘܫܐ",
            sy7: "",
            sy8: "",
            lt12: "",
            dt3: "",
            sy5: "ܠܒܽܘܫܳܐ",
            dt2: "Smoking",
            sy6: ""
        },
        {
            sy5: "ܓܽܘܕܳܦܳܐ",
            sy7: "",
            lt10: "gudofo",
            dt1: "Lästern",
            dt3: "",
            id: "2716",
            sy4: "ܓܘܕܦܐ",
            lt11: "",
            lt12: "",
            sy8: "",
            sy6: "",
            sy9: "",
            dt2: ""
        },
        {
            sy9: "",
            sy6: "",
            dt1: "Meinungsverschiedenheit",
            sy8: "",
            dt3: "",
            id: "2993",
            sy4: "ܫܘܚܠܦ ܪܢܝܐ",
            sy7: "",
            sy5: "ܫܽܘܚܠܳܦ ܪܶܢܝܳܐ",
            dt2: "Meinungsunterschied",
            lt10: "shuĥlof renyo",
            lt11: "",
            lt12: ""
        },
        {
            sy4: "ܦܙܕܦܙܕ",
            dt1: "fasdfa",
            dt2: "",
            lt12: "",
            sy9: "",
            sy5: "ܦܰܙܕܦܰܙܕ",
            lt11: "",
            id: "5918",
            sy8: "",
            sy6: "",
            sy7: "",
            dt3: "",
            lt10: "fasdfasd"
        },
        {
            sy6: "",
            sy9: "",
            lt10: "meshtaĉyono",
            id: "4214",
            dt1: "Spieler",
            sy8: "",
            dt3: "",
            sy4: "ܡܫܬܥܝܢܐ",
            sy5: "ܡܶܫܬܰܥܝܳܢܳܐ",
            sy7: "",
            lt11: "",
            lt12: "",
            dt2: ""
        },
        {
            dt3: "",
            sy5: "ܬܰܪܡܳܠܳܐ",
            lt10: "tarmolo",
            sy9: "",
            lt12: "",
            lt11: "",
            sy4: "ܬܪܡܠܐ",
            sy7: "",
            dt1: "Koffer",
            id: "5490",
            sy6: "",
            dt2: "",
            sy8: ""
        },
        {
            sy5: "ܦܽܘܡܳܐ",
            lt11: "",
            sy9: "",
            id: "3124",
            dt1: "Mund",
            dt3: "",
            lt10: "fumo",
            sy7: "",
            sy4: "ܦܘܡܐ",
            dt2: "",
            sy8: "",
            sy6: "",
            lt12: ""
        },
        {
            sy6: "",
            lt10: "nehwe ŝebyonokh",
            dt1: "Dein Wille geschehe",
            sy9: "",
            sy8: "",
            sy5: "ܢܶܗܘܶܐ ܨܶܒܝܳܢܳܟ݂",
            sy7: "",
            id: "845",
            dt2: "",
            lt12: "",
            dt3: "",
            lt11: "",
            sy4: "ܢܗܘܐ ܨܒܝܢܟ"
        },
        {
            sy5: "ܟܰܦܪܳܐ",
            lt10: "ܟܦܪܐ",
            id: "2378",
            sy7: "",
            sy6: "",
            dt1: "Kafro ",
            dt3: "",
            sy4: "ܟܦܪܐ",
            dt2: "",
            lt11: "ܟܦܪܐ",
            lt12: "",
            sy8: "",
            sy9: ""
        },
        {
            lt12: "",
            sy5: "ܥܳܒܽܘܕܽܘܬ݂ ܟܽܠ",
            sy8: "",
            dt1: "Allmacht",
            sy9: "",
            sy6: "",
            lt11: "",
            id: "5845",
            sy7: "",
            dt2: "",
            dt3: "",
            lt10: "ĉobuduth kul",
            sy4: "ܥܒܘܕܘܬ ܟܠ"
        },
        {
            sy7: "ܐܰܡܺܝܢܳܐܝܺܬ݂",
            sy5: "ܐܰܡܺܝܢܳܐ",
            sy4: "ܐܡܝܢܐ",
            id: "832",
            dt1: "dauern",
            sy8: "",
            lt11: "aminoith",
            dt2: "regelmäßig",
            dt3: "konstant",
            sy9: "",
            lt10: "amino",
            lt12: "",
            sy6: "ܐܡܝܢܐܝܬ"
        },
        {
            sy6: "",
            sy8: "",
            dt1: "Berg",
            lt10: "țuro",
            sy5: "ܛܽܘܪܳܐ",
            lt11: "",
            dt2: "Gebirge",
            dt3: "",
            sy4: "ܛܘܪܐ",
            sy9: "",
            id: "554",
            lt12: "",
            sy7: ""
        },
        {
            sy5: "ܡܠܳܐ",
            dt1: "füllen",
            lt11: "",
            id: "1425",
            sy7: "",
            sy6: "",
            sy8: "",
            dt3: "",
            lt10: "mlo",
            sy9: "",
            sy4: "ܡܠܐ",
            lt12: "",
            dt2: ""
        },
        {
            sy5: "ܟܰܗܪܳܒܳܐ",
            id: "1069",
            lt12: "",
            sy8: "",
            sy4: "ܟܗܪܒܐ",
            lt11: "",
            lt10: "kahrobo",
            dt3: "",
            dt2: "",
            sy9: "",
            sy7: "",
            sy6: "",
            dt1: "Elektrik"
        },
        {
            lt12: "",
            sy5: "ܕܶܩܠܳܐ",
            sy4: "ܕܩܠܐ",
            id: "828",
            dt2: "Palme",
            sy7: "",
            dt3: "",
            lt10: "deqlo",
            sy9: "",
            lt11: "",
            sy8: "",
            sy6: "",
            dt1: "Dattelbaum"
        },
        {
            dt1: "absolut",
            dt3: "",
            sy7: "ܓܪܺܝܡܳܐܺܝܬ݂",
            sy4: "ܡܣܟܝܐ",
            lt10: "msakhyo",
            sy8: "ܡܫܠܡܢܐܝܬ",
            sy5: "ܡܣܰܟ݂ܝܳܐ",
            sy9: "ܡܫܰܠܡܳܢܳܐܺܝܬ݂",
            lt11: "grimoith",
            lt12: "mshalmonoith",
            sy6: "ܓܪܝܡܐܝܬ",
            dt2: "",
            id: "5650"
        },
        {
            sy9: "",
            sy8: "",
            sy5: "ܦܰܩܽܘܣܳܐ",
            lt11: "",
            dt1: "Wassermelone",
            id: "5006",
            dt3: "",
            lt10: "faquso",
            dt2: "Melone",
            sy7: "",
            sy6: "",
            sy4: "ܦܩܘܣܐ",
            lt12: ""
        },
        {
            sy8: "",
            sy6: "",
            lt12: "",
            sy5: "ܐܶܫܬܥܺܝ ܥܕܰܡܳܐ ܠܚܰܪܬ݂ܳܐ",
            lt10: "eshtĉi ĉđamo l'ĥartho",
            lt11: "",
            dt1: "zu Ende spielen",
            sy7: "",
            id: "6307",
            sy9: "",
            dt2: "",
            sy4: "ܐܫܬܥܝ ܥܕܡܐ ܠܚܪܬܐ",
            dt3: ""
        },
        {
            sy7: "ܐܶܠܺܝܥܳܙܳܪ",
            lt12: "ܐܠܝܥܙܪ",
            lt10: "ܠܥܙܪ",
            dt2: "",
            sy6: "ܐܠܝܥܙܪ",
            sy5: "ܠܰܥܰܙܰܖ",
            dt1: "Laazar",
            dt3: "",
            sy4: "ܠܥܙܪ",
            lt11: "ܠܥܙܪ",
            sy9: "",
            id: "2686",
            sy8: ""
        },
        {
            dt1: "Ticket",
            sy5: "ܦܶܬܩܳܐ",
            dt3: "",
            sy7: "",
            dt2: "Eintrittskarte",
            lt11: "",
            sy8: "",
            lt10: "fetqo",
            sy6: "",
            id: "4474",
            sy4: "ܦܬܩܐ",
            sy9: "",
            lt12: ""
        },
        {
            lt12: "",
            dt1: "Fernita",
            lt11: "ܦܪܢܝܬܐ",
            sy4: "ܦܪܢܝܬܐ",
            sy8: "",
            dt2: "",
            dt3: "",
            lt10: "ܦܪܢܝܬܐ",
            sy6: "",
            id: "1291",
            sy9: "",
            sy5: "ܦܶܪܢܺܝܬܰܐ",
            sy7: ""
        },
        {
            lt12: "",
            lt11: "",
            sy7: "",
            lt10: "teto d`qayso",
            sy5: "ܬܶܬܳܐ ܕܩܰܝܣܳܐ",
            sy4: "ܬܬܐ ܕܩܝܣܐ",
            sy6: "",
            dt3: "",
            dt2: "",
            id: "2046",
            sy9: "",
            sy8: "",
            dt1: "Holzleim"
        },
        {
            dt3: "",
            sy6: "",
            dt1: "Ahnentafel",
            sy7: "",
            lt12: "",
            sy8: "",
            sy4: "ܠܘܚܐ ܕܡܝܒܠܢܘܬܐ",
            sy5: "ܠܽܘܚܳܐ ܕܰܡܝܰܒܠܳܢܽܘܬ݂ܳܐ",
            id: "5772",
            lt11: "",
            dt2: "",
            lt10: "luĥo damyablonutho",
            sy9: ""
        },
        {
            sy9: "",
            dt1: "salzig",
            sy5: "ܡܰܠܽܘܚܳܐ",
            dt3: "",
            sy4: "ܡܠܘܚܐ",
            lt10: "maluĥo",
            id: "3817",
            sy6: "",
            sy8: "",
            sy7: "",
            lt11: "",
            dt2: "",
            lt12: ""
        },
        {
            dt2: "vorig",
            dt1: "vergangen",
            lt10: "daĉbar",
            sy5: "ܕܰܥܒܰܪ",
            sy4: "ܕܥܒܪ",
            dt3: "",
            sy7: "",
            id: "4729",
            lt11: "",
            sy6: "",
            lt12: "",
            sy8: "",
            sy9: ""
        },
        {
            lt11: "ܐܟܝܬ",
            sy8: "",
            sy7: "",
            dt3: "",
            sy5: "ܐܳܟܺܝܬ",
            sy4: "ܐܟܝܬ",
            dt1: "Okit",
            lt12: "",
            sy9: "",
            sy6: "",
            id: "3364",
            lt10: "ܐܟܝܬ",
            dt2: ""
        },
        {
            sy6: "",
            sy8: "",
            sy4: "ܒܪܝܘܢܩܐ",
            lt10: "bar yunqo",
            dt2: "",
            sy5: "ܒܰܪܝܽܘܢܩܳܐ",
            lt11: "",
            id: "4775",
            sy9: "",
            dt3: "",
            sy7: "",
            lt12: "",
            dt1: "Veronika"
        },
        {
            sy4: "ܡܢ ܝܘܡܐ ܠܝܘܡܐ",
            lt11: "",
            sy6: "",
            sy5: "ܡܶܢ ܝܰܘܡܳܐ ܠܝܰܘܡܳܐ",
            lt10: "men yaumo l`yaumo",
            id: "4886",
            sy9: "",
            sy8: "",
            dt1: "von Tag zu Tag",
            dt3: "",
            sy7: "",
            dt2: "",
            lt12: ""
        },
        {
            lt10: "ܓܠܓܐܡܝܫ",
            dt3: "",
            lt12: "",
            id: "1552",
            dt2: "Gilgamesch",
            sy6: "",
            sy5: "ܓܠܓܐܡܝܫ",
            sy7: "",
            lt11: "ܓܠܓܐܡܝܫ",
            sy8: "",
            dt1: "Gelgamesch",
            sy9: "",
            sy4: "ܓܠܓܐܡܝܫ"
        },
        {
            sy4: "ܠܥܒܘܬܐ",
            lt10: "laĉbutho",
            dt3: "Habsucht",
            sy9: "",
            sy7: "ܝܰܥܢܽܘܬ݂ܳܐ",
            sy6: "ܝܥܢܘܬܐ",
            lt12: "",
            id: "1791",
            dt2: "Gier",
            lt11: "yaĉnutho",
            sy8: "",
            sy5: "ܠܰܥܒܽܘܬ݂ܳܐ",
            dt1: "Habgier"
        },
        {
            sy8: "ܝܬܪ",
            sy9: "ܝܳܬ݂ܰܪ",
            dt3: "",
            lt12: "yothar",
            sy7: "ܙܳܟ݂ܶܐ",
            sy6: "ܙܟܐ",
            dt2: "siegen",
            dt1: "gewinnen",
            sy5: "ܙܟ݂ܳܐ",
            sy4: "ܙܟܐ",
            lt10: "zkho",
            id: "1626",
            lt11: "zokhe"
        },
        {
            sy9: "ܬܶܬܳܐ",
            dt3: "",
            lt11: "dobuqo",
            sy6: "ܕܒܘܩܐ",
            dt1: "Klebstoff",
            sy7: "ܕܳܒܽܘܩܳܐ",
            sy4: "ܕܒܩܐ",
            id: "2507",
            lt10: "debqo",
            dt2: "Leim",
            lt12: "teto",
            sy5: "ܕܶܒܩܳܐ",
            sy8: "ܬܬܐ"
        },
        {
            sy4: "ܥܕܬܢܝܐ",
            lt12: "",
            id: "2491",
            sy6: "",
            sy7: "",
            lt11: "",
            sy9: "",
            sy8: "",
            lt10: "ĉitonoyo",
            dt1: "kirchlich",
            dt3: "",
            dt2: "",
            sy5: "ܥܺܕܬܳܢܳܝܳܐ"
        },
        {
            lt11: "",
            sy6: "",
            sy4: "ܫܠܛ",
            lt12: "",
            sy9: "",
            dt3: "",
            lt10: "shaleț",
            sy5: "ܫܰܠܶܛ",
            dt2: "",
            sy8: "",
            id: "5895",
            sy7: "",
            dt1: "an der Macht sein"
        },
        {
            lt12: "",
            sy7: "ܫܰܦܺܝܪܬܳܐ",
            lt11: "shafirto",
            id: "4008",
            dt2: "hübsch",
            sy8: "",
            sy5: "ܫܰܦܺܝܪܳܐ",
            sy9: "",
            lt10: "shafiro",
            dt3: "",
            sy6: "ܫܦܝܪܬܐ",
            dt1: "schön",
            sy4: "ܫܦܝܪܐ"
        },
        {
            dt3: "",
            sy8: "",
            lt12: "",
            sy5: "ܒܶܛܠܳܢܳܐ ܕܬܶܫܪܳܝܳܬ݂ܳܐ",
            dt2: "",
            lt11: "",
            lt10: "bețlono d`teshroyotho",
            sy4: "ܒܛܠܢܐ ܕܬܫܪܝܬܐ",
            id: "1966",
            sy6: "",
            sy9: "",
            dt1: "Herbstferien",
            sy7: ""
        },
        {
            sy4: "ܒܢܝ",
            dt1: "Bani",
            sy6: "",
            sy5: "ܒܰܢܺܝ",
            id: "433",
            sy7: "",
            sy9: "",
            lt11: "ܒܢܝ",
            lt12: "",
            lt10: "ܒܢܝ",
            dt3: "",
            dt2: "",
            sy8: ""
        },
        {
            sy8: "",
            dt3: "",
            lt11: "tekso d`dayonutho",
            id: "3645",
            lt12: "",
            sy4: "ܬܟܣܐ ܕܙܕܩܐ",
            sy7: "ܬܶܟܣܳܐ ܕܕܰܝܳܢܽܘܬ݂ܳܐ",
            sy6: "ܬܟܣܐ ܕܕܝܢܘܬܐ",
            sy5: "ܬܶܟܣܳܐ ܕܙܶܕܩܶܐ",
            dt2: "Rechtswesen",
            lt10: "tekso d`zedqe",
            dt1: "Rechtssystem",
            sy9: ""
        },
        {
            sy6: "ܫܥܥܝܐ",
            dt3: "glitschig",
            lt10: "shoruĉo",
            sy7: "ܫܥܳܥܳܝܳܐ",
            dt2: "rutschig",
            sy5: "ܫܳܪܽܘܥܳܐ",
            id: "1648",
            dt1: "glatt",
            lt11: "shĉoĉoyo",
            sy4: "ܫܪܘܥܐ",
            lt12: "",
            sy8: "",
            sy9: ""
        },
        {
            dt2: "",
            id: "5215",
            lt11: "ܝܘܩܢܬܐ",
            lt10: "ܝܘܩܢܬܐ",
            sy6: "",
            sy7: "",
            sy9: "",
            sy5: "ܝܽܘܩܰܢܬܳܐ",
            sy8: "",
            dt3: "",
            sy4: "ܝܘܩܢܬܐ",
            lt12: "",
            dt1: "Yuqanto"
        },
        {
            lt10: "malfon tauditho",
            sy6: "",
            dt1: "Religionslehrer",
            sy8: "",
            sy5: "ܡܰܠܦܳܢ ܬܰܘܕܺܝܬ݂ܳܐ",
            dt3: "",
            lt11: "",
            lt12: "",
            id: "3687",
            dt2: "",
            sy9: "",
            sy7: "",
            sy4: "ܡܠܦܢ ܬܘܕܝܬܐ"
        },
        {
            id: "1553",
            sy6: "",
            dt3: "",
            sy9: "",
            sy5: "ܡܗܰܝܡ̈ܢܶܐ ܪ̈ܚܺܝܡܶܐ",
            sy7: "",
            lt12: "",
            lt11: "",
            sy4: "ܡܗܝܡܢܐ ܪܚܝܡܐ",
            lt10: "mhaymne rĥime",
            dt2: "",
            dt1: "Geliebte Gläubige",
            sy8: ""
        },
        {
            sy7: "ܩܽܘܦܠܳܐ",
            lt11: "quflo",
            lt12: "",
            id: "3974",
            dt3: "",
            sy4: "ܚܣܢܐ",
            sy9: "",
            lt10: "ĥesno",
            sy6: "ܩܘܦܠܐ",
            sy5: "ܚܶܣܢܳܐ",
            sy8: "",
            dt2: "Schloß",
            dt1: "Schloss"
        },
        {
            lt10: "imomo",
            sy7: "",
            lt11: "",
            id: "4367",
            sy5: "ܐܺܝܡܳܡܳܐ",
            sy4: "ܐܝܡܡܐ",
            sy6: "",
            dt2: "am Tage",
            dt3: "",
            dt1: "tagsüber",
            lt12: "",
            sy9: "",
            sy8: ""
        },
        {
            dt3: "",
            lt12: "eno roĥmo no lokh",
            id: "2124",
            sy4: "ܓܪܚܡܢܐ ܠܟ",
            sy9: "ܐܶܢܳܐ ܪܳܚܡܳܐ ܐ̱ܢܳܐ ܠܳܟ݂",
            sy6: "ܐܢܐ ܪܚܡ ܐܢܐ ܠܟܝ",
            sy7: "ܐܶܢܳܐ ܪܳܚܶܡ ܐ̱ܢܳܐ ܠܰܟ݂ܝ̱",
            dt2: "Ich mag Dich",
            dt1: "Ich liebe Dich",
            sy8: "ܐܢܐ ܪܚܡܐ ܐܢܐ ܠܟ",
            lt11: "eno roĥem no lakh",
            lt10: "groĥmono loch",
            sy5: "ܓܪܳܚܡܳܢܳܐ ܠܳܟ݂"
        },
        {
            sy7: "",
            dt3: "",
            sy9: "",
            sy5: "ܚܶܠܡܳܐ",
            lt12: "",
            dt2: "",
            lt11: "ܚܠܡܐ",
            dt1: "Helmo",
            sy4: "ܚܠܡܐ",
            sy8: "",
            id: "1958",
            sy6: "",
            lt10: "ܚܠܡܐ"
        },
        {
            sy7: "",
            dt2: "",
            sy9: "",
            sy6: "",
            lt10: "blaĉ",
            sy4: "ܒܠܥ",
            id: "3976",
            dt3: "",
            lt11: "",
            dt1: "schlucken",
            lt12: "",
            sy8: "",
            sy5: "ܒܠܰܥ"
        },
        {
            lt10: "ܙܟ݂ܐܝܫܘܥ",
            lt11: "ܙܟ݂ܐܝܫܘܥ",
            sy6: "",
            dt3: "",
            sy5: "ܙܟ݂ܳܐܝܶܫܽܘܥ",
            sy9: "",
            lt12: "",
            sy4: "ܙܟ݂ܐܝܫܘܥ",
            sy7: "",
            sy8: "",
            dt1: "Zkhojeschu",
            dt2: "",
            id: "5344"
        },
        {
            sy4: "ܛܘܗܡܐ ܕܟܠܒܐ",
            sy6: "",
            sy7: "",
            dt3: "",
            sy5: "ܛܽܘܗܡܳܐ ܕܟܰܠܒܳܐ",
            dt2: "",
            lt10: "țuhmo d`kalbo",
            id: "2071",
            lt11: "",
            lt12: "",
            sy9: "",
            sy8: "",
            dt1: "Hunderasse"
        },
        {
            dt2: "",
            sy4: "ܫܒܘܥܐ ܕܥܒܪ",
            sy9: "",
            lt11: "",
            sy8: "",
            sy5: "ܫܳܒܽܘܥܳܐ ܕܰܥܒܰܪ",
            lt12: "",
            id: "2780",
            dt3: "",
            dt1: "Letzte Woche",
            sy7: "",
            lt10: "shobuĉo da`ĉbar",
            sy6: ""
        },
        {
            lt11: "",
            sy5: "ܒܰܛܶܠ",
            sy4: "ܒܛܠ",
            lt10: "bațel",
            lt12: "",
            dt2: "annulieren",
            sy6: "",
            sy8: "",
            dt3: "beenden",
            dt1: "stoppen",
            sy7: "",
            sy9: "",
            id: "4284"
        },
        {
            id: "544",
            dt3: "",
            sy4: "ܒܠܫܨܪ",
            dt1: "Belschassar",
            sy9: "",
            lt11: "ܒܠܫܨܪ",
            sy6: "",
            sy5: "ܒܶܠܫܰܨܰܪ",
            sy8: "",
            dt2: "",
            lt10: "ܒܠܫܨܪ",
            sy7: "",
            lt12: ""
        },
        {
            id: "4468",
            sy4: "ܐܘܪܝܬܐ",
            sy5: "ܐܽܘܪܳܝܬܳܐ",
            lt10: "uroyto",
            lt11: "",
            dt2: "",
            sy9: "",
            dt3: "",
            sy6: "",
            sy8: "",
            lt12: "",
            dt1: "Thora",
            sy7: ""
        },
        {
            sy8: "",
            sy6: "",
            sy7: "",
            dt3: "",
            id: "2114",
            lt10: "eno ith li ĥabusho",
            dt2: "",
            lt11: "",
            sy4: "ܐܢܐ ܐܝܬ ܠܝ ܚܒܘܫܐ",
            dt1: "Ich habe ein Apfel",
            sy9: "",
            lt12: "",
            sy5: "ܐܶܢܳܐ ܐܺܝܬ݂ ܠܝ ܚܒܘܫܐ"
        },
        {
            sy6: "",
            lt11: "",
            lt10: "nshobo",
            sy5: "ܢܫܳܒܳܐ",
            dt1: "Sturm",
            sy8: "",
            sy9: "",
            sy4: "ܢܫܒܐ",
            dt3: "",
            id: "4302",
            dt2: "Wind",
            sy7: "",
            lt12: ""
        },
        {
            sy4: "ܡܠܟܬܐ",
            lt10: "malktho",
            sy6: "",
            sy8: "",
            dt2: "",
            sy9: "",
            lt11: "",
            dt3: "",
            lt12: "",
            id: "5449",
            dt1: "Königin",
            sy5: "ܡܰܠܟܬ݂ܳܐ",
            sy7: ""
        },
        {
            lt11: "",
            sy9: "",
            id: "3520",
            sy5: "ܬܰܪܡܺܝܬ݂ܳܐ",
            dt3: "",
            sy7: "",
            lt12: "",
            lt10: "tarmitho",
            dt2: "",
            sy8: "",
            sy6: "",
            dt1: "Projekt",
            sy4: "ܬܪܡܝܬܐ"
        },
        {
            sy8: "",
            sy5: "ܣܪܺܝܩܳܐ",
            id: "4732",
            lt12: "",
            sy9: "",
            sy4: "ܣܪܝܩܐ",
            dt2: "vergebens",
            lt10: "sriqo",
            dt3: "nutzlos",
            lt11: "sriqoith",
            sy7: "ܣܪܺܝܩܳܐܝܺܬ݂",
            dt1: "vergeblich",
            sy6: "ܣܪܝܩܐܝܬ"
        },
        {
            lt10: "ܥܒܕܐܠܠܗ",
            sy9: "",
            lt11: "ܥܒܕܐܠܠܗ",
            id: "66",
            sy4: "ܥܒܕܐܠܠܗ",
            sy6: "",
            sy7: "",
            dt2: "",
            dt1: "Abdallah",
            sy8: "",
            sy5: "ܥܰܒܕܰܐܠܠܰܗ",
            dt3: "",
            lt12: ""
        },
        {
            sy8: "ܫܠܚ",
            dt1: "liefern",
            lt10: "ashlem",
            lt12: "shlaĥ",
            sy5: "ܐܰܫܠܶܡ",
            sy6: "ܫܕܪ",
            dt2: "schicken",
            dt3: "senden",
            sy9: "ܫܠܰܚ",
            id: "2827",
            sy7: "ܫܰܕܰܪ",
            lt11: "shadar",
            sy4: "ܐܫܠܡ"
        },
        {
            dt3: "",
            sy4: "ܪܩܝܩܐ",
            sy9: "ܪܰܦܺܝܥܳܐ",
            dt2: "",
            sy5: "ܪܰܩܺܝܩܳܐ",
            sy7: "ܣܰܚܺܝܚܳܐ",
            id: "979",
            lt11: "saĥiĥo",
            sy8: "ܪܦܝܥܐ",
            sy6: "ܣܚܝܚܐ",
            dt1: "dünn",
            lt10: "raqiqo",
            lt12: "rafiĉo"
        },
        {
            sy6: "",
            dt2: "",
            sy5: "ܟܬ݂ܰܘ ܬܠܳܬ݂ܳܐ ܦܶܬܓܳܡ̈ܶܐ",
            dt1: "schreibe drei Sätze",
            lt10: "kthauw tlotho fetğome",
            id: "4024",
            sy8: "",
            sy9: "",
            lt11: "",
            dt3: "",
            lt12: "",
            sy7: "",
            sy4: "ܟܬܘ ܬܠܬܐ ܦܬܓܡ̈ܐ"
        },
        {
            sy5: "ܥܰܪܛܶܠܳܝܽܘܬ݂ܳܐ",
            id: "3172",
            dt2: "Bloßheit",
            dt1: "Nacktheit",
            dt3: "Nudität",
            sy9: "",
            lt12: "",
            lt11: "",
            lt10: "ĉarțeloyutho",
            sy4: "ܥܪܛܠܝܘܬܐ",
            sy8: "",
            sy7: "",
            sy6: ""
        },
        {
            sy5: "ܓܰܙܳܐ ܕܟܬ݂ܳܒ̈ܶܐ",
            lt10: "gazo da`kthobe",
            sy7: "",
            sy9: "",
            id: "727",
            dt3: "",
            sy6: "",
            dt1: "Bücherregal",
            lt11: "",
            lt12: "",
            sy4: "ܓܙܐ ܕܟܬܒܐ",
            dt2: "",
            sy8: ""
        },
        {
            sy6: "",
            sy4: "ܦܪܥ",
            lt12: "",
            dt1: "belohnen",
            dt3: "",
            dt2: "",
            lt10: "fraĉ",
            id: "541",
            sy9: "",
            sy5: "ܦܪܰܥ",
            lt11: "",
            sy7: "",
            sy8: ""
        },
        {
            sy5: "ܪܶܓ݂ܠܳܝܳܐ",
            sy8: "",
            sy7: "ܡܗܰܠܟ݂ܳܢܳܐ",
            dt1: "Fußgänger",
            dt3: "",
            sy9: "",
            id: "1441",
            sy6: "ܡܗܠܟ݂ܢܐ",
            lt12: "",
            lt11: "mhalkhono",
            sy4: "ܪܓܠܝܐ",
            lt10: "reğloyo",
            dt2: ""
        },
        {
            sy7: "ܢܽܘܟ݂ܪܳܝܽܘܬ݂ܳܐ",
            sy5: "ܐܰܟ݂ܣܢܳܝܽܘܬ݂ܳܐ",
            lt10: "akhsnoyutho",
            dt2: "",
            dt1: "Fremdheit",
            sy8: "",
            sy4: "ܐܟܣܢܝܘܬܐ",
            sy6: "ܢܘܟܪܝܘܬܐ",
            dt3: "",
            lt12: "",
            sy9: "",
            id: "6105",
            lt11: "nukhroyutho"
        },
        {
            lt11: "ĥabilo",
            sy9: "",
            id: "5471",
            dt3: "",
            lt10: "saqublo",
            sy4: "ܣܰܩܽܘܒܠܳܐ",
            sy7: "ܚܰܒܺܝܠܳܐ",
            dt1: "contra",
            sy6: "ܚܰܒܺܝܠܳܐ",
            sy5: "ܣܰܩܽܘܒܠܳܐ",
            dt2: "gegensätzlich",
            lt12: "",
            sy8: ""
        },
        {
            sy9: "",
            dt3: "",
            dt2: "",
            sy8: "",
            lt11: "",
            lt12: "",
            dt1: "während sie beten",
            sy7: "",
            sy4: "ܟܕ ܡܨܠܝܢ",
            lt10: "kad mŝalyn",
            id: "4954",
            sy6: "",
            sy5: "ܟܰܕ ܡܨܰܠܺܝܢ"
        },
        {
            dt2: "",
            id: "4184",
            lt10: "bnaye w`abnotho",
            dt1: "Söhne und Töchter",
            lt11: "",
            lt12: "",
            sy6: "",
            sy9: "",
            sy7: "",
            dt3: "",
            sy5: "ܒܢܰܝ̈ܶܐ ܘܰܒ̈ܢܳܬ݂ܳܐ",
            sy4: "ܒܢܝܐ ܘܒܢܬܐ",
            sy8: ""
        },
        {
            sy5: "ܣܳܗܕܳܐ ܕܰܩܪܳܒܳܐ",
            lt12: "",
            lt11: "",
            dt3: "",
            lt10: "sohdo da'qrobo",
            id: "6198",
            sy8: "",
            dt2: "",
            sy9: "",
            dt1: "Kriegsopfer",
            sy4: "ܣܗܕܐ ܕܩܪܒܐ",
            sy7: "",
            sy6: ""
        },
        {
            id: "6321",
            sy4: "ܐܠܨܝ",
            dt1: "muss",
            lt10: "olŝoy",
            dt3: "",
            sy6: "ܙܕܩ",
            lt12: "",
            sy7: "ܙܳܕܶܩ",
            sy8: "",
            dt2: "muß",
            sy5: "ܐܳܠܨܳܝ",
            lt11: "zodeq",
            sy9: ""
        },
        {
            lt10: "ĉesrin we`shto",
            sy8: "ܟܘ",
            sy4: "ܥܣܪܝܢ ܘܫܬܐ",
            id: "27",
            dt3: "",
            lt12: "kof-waw",
            sy5: "ܥܶܣܪܺܝܢ ܘܫܬܳܐ",
            sy7: "ܥܶܣܪܺܝܢ ܘܰܫܶܬ݂",
            sy9: "ܟܘ",
            dt2: "Sechsundzwanzig",
            dt1: "26",
            lt11: "ĉesrin w`sheth",
            sy6: "ܥܣܪܝܢ ܘܫܬ"
        },
        {
            dt1: "Plan",
            sy6: "",
            sy7: "",
            sy4: "ܪܢܝܐ",
            dt2: "",
            dt3: "",
            lt12: "",
            lt11: "",
            sy8: "",
            id: "3469",
            lt10: "renyo",
            sy5: "ܪܶܢܝܳܐ",
            sy9: ""
        },
        {
            lt12: "",
            sy5: "ܚܰܝ̈ܘܳܬ݂ܳܐ",
            sy6: "",
            dt2: "",
            dt1: "Tiere",
            dt3: "",
            sy7: "",
            lt11: "",
            sy9: "",
            id: "4479",
            sy8: "",
            sy4: "ܚܝܘܬܐ",
            lt10: "ĥaywotho"
        },
        {
            lt12: "",
            dt2: "",
            sy8: "",
            sy9: "",
            sy4: "ܢܟܠ",
            lt11: "",
            sy5: "ܢܟ݂ܰܠ",
            sy6: "",
            id: "4787",
            lt10: "nkhal",
            dt3: "",
            dt1: "verraten",
            sy7: ""
        },
        {
            dt3: "",
            dt2: "",
            dt1: "Abilta",
            sy6: "",
            lt12: "",
            lt11: "ܐܒܝܠܬܐ",
            sy8: "",
            sy7: "",
            id: "94",
            sy9: "",
            sy5: "ܐܰܒܺܝܠܬܰܐ",
            sy4: "ܐܒܝܠܬܐ",
            lt10: "ܐܒܝܠܬܐ"
        },
        {
            sy7: "",
            dt1: "zwinkern",
            sy8: "",
            sy4: "ܪܡܙ ܒܥܝܢܐ",
            lt10: "rmaz b`ĉayne",
            id: "5407",
            dt2: "blinzeln",
            lt11: "",
            sy5: "ܪܡܰܙ ܒܥܰܝ̈ܢܶܐ",
            sy9: "",
            sy6: "",
            lt12: "",
            dt3: ""
        },
        {
            lt12: "",
            sy9: "",
            sy6: "",
            lt11: "",
            dt3: "",
            sy8: "",
            lt10: "kole no",
            id: "2141",
            dt2: "",
            sy7: "",
            dt1: "Ich stehe",
            sy4: "ܟܠܐ ܐܢܐ",
            sy5: "ܟܳܠܶܐ ܐ̱ܢܳܐ"
        },
        {
            sy8: "",
            id: "2767",
            lt11: "",
            dt3: "",
            lt10: "qro",
            sy6: "",
            dt2: "",
            sy9: "",
            sy7: "",
            sy4: "ܩܪܐ",
            dt1: "lesen",
            lt12: "",
            sy5: "ܩܪܳܐ"
        },
        {
            sy4: "ܩܪܝܬܐ ܕܐܙܟ",
            sy8: "ܒܝܬ ܕܙܒܕܝ",
            sy5: "ܩܪܺܝܬ݂ܳܐ ܕܰܐܙܶܟ݂",
            dt3: "Hazak",
            lt10: "qritho azekh",
            sy6: "ܐܙܟ",
            sy7: "ܐܰܙܶܟ݂",
            dt2: "Beth Zabday",
            dt1: "Dorf Azech",
            sy9: "ܒܶܝܬ݂ ܙܰܒܕܰܝ",
            lt11: "azekh",
            id: "925",
            lt12: "beth zabday"
        },
        {
            lt11: "ܐܒܚܝ",
            sy5: "ܐܰܒܚܰܝ",
            sy4: "ܐܒܚܝ",
            lt10: "ܐܒܚܝ",
            sy7: "",
            dt1: "Abhai",
            dt2: "",
            dt3: "",
            sy6: "",
            sy9: "",
            lt12: "",
            sy8: "",
            id: "90"
        },
        {
            dt3: "",
            sy9: "",
            sy7: "ܣܦܰܪ ܢܽܘܗܳܪܶܐ",
            sy5: "ܟܶܪܟܳܐ ܕܬܰܚܪܰܙܬܳܐ",
            id: "5794",
            lt12: "",
            dt1: "Agenda",
            sy6: "ܣܦܪ ܢܘܗܪܐ",
            lt10: "kerko dtaĥrazto",
            sy4: "ܟܪܟܐ ܕܬܚܪܙܬܐ",
            lt11: "sfar nuhore",
            dt2: "",
            sy8: ""
        },
        {
            lt12: "",
            id: "3057",
            dt2: "Freundliche Grüße",
            sy6: "",
            lt11: "",
            lt10: "ĉam shlome iqore",
            sy5: "ܥܰܡ ܫܠܳܡܶܐ ܝܺܩܳܪܳܐ",
            dt1: "Mit freundlichen Grüßen",
            sy7: "",
            sy8: "",
            sy9: "",
            sy4: "ܥܡ ܫܠܡܐ ܝܩܪܐ",
            dt3: ""
        },
        {
            sy4: "ܦܗܕܐ",
            lt10: "fahdo",
            dt1: "Panther",
            sy9: "",
            sy6: "",
            sy5: "ܦܰܗܕܳܐ",
            sy8: "",
            id: "3414",
            lt12: "",
            sy7: "",
            dt2: "",
            dt3: "",
            lt11: ""
        },
        {
            sy8: "",
            lt11: "",
            dt2: "",
            sy7: "",
            lt12: "",
            sy6: "",
            sy5: "ܡܶܫܬܰܚܠܦܳܢܽܘܬ݂ܳܐ",
            sy4: "ܡܫܬܚܠܦܢܘܬܐ",
            sy9: "",
            dt1: "Wandel",
            dt3: "",
            lt10: "meshtaĥlfonutho",
            id: "4978"
        },
        {
            dt3: "",
            lt12: "",
            lt10: "akh ĉyodo",
            dt1: "wie immer",
            sy5: "ܐܰܝܟ ܥܝܳܕܳܐ",
            sy4: "ܐܝܟ ܥܝܕܐ",
            sy7: "",
            dt2: "",
            id: "5093",
            sy9: "",
            sy8: "",
            lt11: "",
            sy6: ""
        },
        {
            sy6: "",
            lt11: "",
            sy7: "",
            sy4: "ܫܬܝܐ",
            lt12: "",
            lt10: "shatoyo",
            id: "3857",
            dt1: "Säufer",
            dt2: "Trinker",
            sy5: "ܫܰܬܳܝܳܐ",
            dt3: "",
            sy8: "",
            sy9: ""
        },
        {
            id: "1913",
            lt10: "ܚܙܢܐ",
            sy5: "ܚܰܙܢܶܐ",
            sy8: "",
            lt12: "",
            sy6: "",
            sy4: "ܚܙܢܐ",
            sy7: "",
            lt11: "ܚܙܢܐ",
            sy9: "",
            dt3: "",
            dt1: "Hazne",
            dt2: ""
        },
        {
            sy9: "",
            sy4: "ܩܘܪܐ",
            sy6: "",
            sy5: "ܩܽܘܪܳܐ",
            sy8: "",
            dt1: "kalt",
            id: "2394",
            dt3: "",
            sy7: "",
            lt10: "quro",
            lt12: "",
            dt2: "",
            lt11: ""
        },
        {
            lt10: "nakhfutho",
            dt3: "",
            sy5: "ܢܰܟ݂ܦܽܘܬ݂ܳܐ",
            sy8: "",
            lt11: "",
            lt12: "",
            dt2: "",
            sy7: "",
            dt1: "Unberührtheit",
            sy6: "",
            id: "4634",
            sy9: "",
            sy4: "ܢܟܦܘܬܐ"
        },
        {
            sy4: "ܕܚܠ",
            sy8: "ܡܐܙܝܥ",
            sy7: "ܐܰܙܺܝܥ",
            dt1: "fürchten",
            lt12: "maziĉ",
            dt3: "",
            sy5: "ܕܚܶܠ",
            sy6: "ܐܙܝܥ",
            id: "1430",
            lt11: "aziĉ",
            dt2: "ängstigen",
            sy9: "ܡܰܐܙܺܝܥ",
            lt10: "dĥel"
        },
        {
            id: "3007",
            dt3: "",
            sy5: "ܐܰܟܺܝܠ",
            sy4: "ܐܟܝܠ",
            sy7: "ܡܫܰܚ",
            sy9: "ܡܳܫܰܚ",
            lt11: "mshaĥ",
            sy6: "ܡܫܚ",
            dt2: "",
            sy8: "ܡܫܚ",
            lt12: "moshaĥ",
            dt1: "messen",
            lt10: "akil"
        },
        {
            sy8: "",
            id: "6231",
            dt2: "",
            sy4: "ܬܝܡܢ ܡܕܢܚܐ",
            dt1: "Südost",
            sy6: "",
            lt12: "",
            sy5: "ܬܰܝܡܰܢ ܡܰܕܢܚܳܐ",
            lt11: "",
            sy7: "",
            lt10: "tayman madnĥo",
            sy9: "",
            dt3: ""
        },
        {
            sy9: "",
            id: "83",
            sy4: "ܦܘܪܥܢܐ ܝܬܝܪܐ",
            dt2: "",
            sy8: "",
            sy5: "ܦܘܽܪܥܳܢܳܐ ܝܰܬܺܝܪܳܐ",
            sy7: "",
            lt10: "furĉono yatiro",
            sy6: "",
            dt1: "Abfindung",
            dt3: "",
            lt11: "",
            lt12: ""
        },
        {
            sy4: "ܟܪܦܣܐ",
            lt12: "",
            dt3: "",
            sy5: "ܟܪܰܦܣܳܐ",
            sy8: "",
            id: "3441",
            sy6: "",
            lt11: "",
            dt2: "",
            dt1: "Petersilie",
            sy7: "",
            sy9: "",
            lt10: "krafso"
        },
        {
            id: "870",
            dt1: "despotisch",
            sy6: "",
            sy4: "ܚܪܡܢܐܝܬ",
            sy9: "",
            sy7: "",
            lt12: "",
            lt10: "ĥarmonoith",
            sy5: "ܚܰܪܡܳܢܳܐܝܺܬ݂",
            sy8: "",
            dt2: "tyrannisch",
            lt11: "",
            dt3: ""
        },
        {
            sy4: "ܦܫܛܐ",
            sy8: "",
            id: "390",
            dt1: "Ausstrecken",
            dt2: "Ausstreckung",
            dt3: "Strecken",
            sy7: "",
            sy5: "ܦܫܳܛܳܐ",
            lt12: "",
            sy9: "",
            lt10: "fshoto",
            sy6: "",
            lt11: ""
        },
        {
            id: "1544",
            sy8: "",
            dt3: "",
            lt10: "sqifoyo",
            sy4: "ܙܩܝܦܝܐ",
            sy6: "",
            dt1: "Gekreuzigter",
            lt11: "",
            lt12: "",
            sy5: "ܙܩܽܝܦܳܝܳܐ",
            dt2: "",
            sy7: "",
            sy9: ""
        },
        {
            sy9: "",
            id: "2073",
            dt3: "",
            sy7: "ܬܦܶܢ",
            sy8: "",
            dt2: "",
            sy6: "ܬܦܢ",
            lt10: "kfen",
            lt12: "",
            dt1: "hungern",
            lt11: "tfen",
            sy5: "ܟܦܶܢ",
            sy4: "ܟܦܢ"
        },
        {
            dt2: "",
            sy6: "",
            id: "153",
            lt12: "",
            sy8: "",
            sy9: "",
            sy5: "ܐܰܚܶܒ",
            lt10: "ܐܚܒ",
            sy7: "",
            lt11: "ܐܚܒ",
            dt3: "",
            sy4: "ܐܚܒ",
            dt1: "Aheb"
        },
        {
            sy5: "ܙܥܽܘܪ ܡܶܢ",
            sy6: "",
            dt3: "",
            sy4: "ܙܥܘܪ ܡܢ",
            sy8: "",
            lt10: "sĉur men",
            dt1: "kleiner als",
            dt2: "",
            lt12: "",
            sy7: "",
            id: "2514",
            sy9: "",
            lt11: ""
        },
        {
            sy5: "ܒܰܪ̱ܬܙܰܒܒܰܝ",
            lt12: "",
            id: "496",
            dt3: "",
            sy4: "ܒܪ̱ܬܙܒܝ",
            sy9: "",
            dt1: "Bath Zabbai",
            sy6: "",
            lt11: "ܒܪܬܙܒܝ",
            dt2: "",
            lt10: "ܒܪܬܙܒܝ",
            sy8: "",
            sy7: ""
        },
        {
            lt12: "",
            sy7: "",
            sy9: "",
            sy4: "ܝܠܐ",
            dt1: "Yalo",
            sy8: "",
            dt2: "",
            dt3: "",
            lt11: "ܝܠܐ",
            lt10: "ܝܠܐ",
            id: "5195",
            sy6: "",
            sy5: "ܝܰܠܳܐ"
        },
        {
            sy4: "ܠܐ ܡܙܝܢܐ",
            sy7: "",
            dt1: "unbewaffnet",
            lt10: "lo mzayno",
            sy5: "ܠܳܐ ܡܙܰܝܢܳܐ",
            sy9: "",
            dt3: "",
            sy6: "",
            id: "4638",
            dt2: "",
            sy8: "",
            lt12: "",
            lt11: ""
        },
        {
            dt2: "",
            sy9: "",
            lt11: "",
            id: "850",
            sy4: "ܡܨܒܬܐ",
            dt1: "dekorativ",
            lt10: "mŝabtho",
            lt12: "",
            dt3: "",
            sy8: "",
            sy6: "",
            sy5: "ܡܨܰܒܬ݂ܳܐ",
            sy7: ""
        },
        {
            lt10: "mshalutho",
            sy7: "ܡܶܫܬܰܐܠܳܢܽܘܬ݂ܳܐ",
            sy8: "",
            sy6: "ܡܫܬܐܠܢܘܬܐ",
            dt1: "Verantwortung",
            dt2: "",
            sy4: "ܡܫܐܠܘܬܐ",
            dt3: "",
            lt11: "meshtalonutho",
            sy5: "ܡܫܰܐܠܽܘܬ݂ܳܐ",
            lt12: "",
            sy9: "",
            id: "4718"
        },
        {
            sy8: "",
            sy5: "ܠܝܳܫܳܐ",
            sy6: "",
            dt2: "",
            lt10: "lyosho",
            sy4: "ܠܝܫܐ",
            dt1: "Kneten",
            dt3: "",
            lt12: "",
            lt11: "",
            id: "2540",
            sy7: "",
            sy9: ""
        },
        {
            sy6: "",
            sy8: "",
            sy9: "",
            dt2: "",
            lt10: "luĥith shudoro",
            sy5: "ܠܽܘܚܺܝܬ݂ ܫܽܘܕܳܪܳܐ",
            sy4: "ܠܘܚܝܬ ܫܘܕܪܐ",
            lt12: "",
            dt3: "",
            lt11: "",
            id: "4802",
            sy7: "",
            dt1: "Versandliste"
        },
        {
            lt10: "taĥem",
            dt1: "abgrenzen",
            sy5: "ܬܰܚܶܡ",
            sy4: "ܬܚܡ",
            sy9: "",
            lt12: "",
            lt11: "",
            dt2: "",
            sy8: "",
            sy7: "",
            dt3: "",
            sy6: "",
            id: "88"
        },
        {
            sy5: "ܙܽܘܢܓܳܪܳܐ",
            dt1: "Rost",
            sy9: "ܫܰܚܽܘܬ݂ܳܐ",
            id: "3745",
            dt3: "",
            lt10: "zungoro",
            dt2: "",
            sy7: "ܫܽܘܚܬ݂ܳܐ",
            sy8: "ܫܚܘܬܐ",
            lt11: "shuĥto",
            sy4: "ܙܘܢܓܪܐ",
            sy6: "ܫܘܚܬܐ",
            lt12: "shaĥutho"
        },
        {
            sy9: "",
            sy4: "ܐܪܒܘܒܐ",
            sy6: "",
            lt12: "",
            dt2: "",
            id: "286",
            lt11: "ܐܪܒܘܒܐ",
            sy7: "",
            dt1: "Arbuba",
            lt10: "ܐܪܒܘܒܐ",
            dt3: "",
            sy8: "",
            sy5: "ܐܰܪܒܽܘܒܰܐ"
        },
        {
            lt10: "gedsho d`qțoro",
            sy7: "",
            lt11: "",
            sy6: "",
            sy8: "",
            sy4: "ܓܕܫܐ ܕܩܛܪܐ",
            dt1: "Zugunglück",
            dt3: "",
            id: "5364",
            dt2: "Zugunfall",
            sy5: "ܓܶܕܫܳܐ ܕܩܛܳܪܳܐ",
            lt12: "",
            sy9: ""
        },
        {
            dt3: "",
            dt2: "",
            dt1: "absorbieren",
            sy7: "",
            sy8: "",
            sy9: "",
            sy4: "ܡܨܐ",
            sy6: "",
            id: "5653",
            sy5: "ܡܨܳܐ",
            lt12: "",
            lt11: "",
            lt10: "mŝo"
        },
        {
            sy6: "",
            lt12: "",
            dt1: "ziellos",
            id: "5322",
            dt3: "",
            sy7: "",
            lt10: "d`lo nisho",
            sy9: "",
            sy5: "ܕܠܳܐ ܢܺܝܫܳܐ",
            sy8: "",
            dt2: "",
            lt11: "",
            sy4: "ܕܠܐ ܢܝܫܐ"
        },
        {
            lt10: "ܣܪܐ",
            id: "3844",
            sy7: "",
            sy5: "ܣܰܪܶܐ",
            dt3: "",
            sy4: "ܣܪܐ",
            sy9: "",
            lt11: "ܣܪܐ",
            lt12: "",
            sy6: "",
            sy8: "",
            dt1: "Sare",
            dt2: ""
        },
        {
            sy6: "",
            lt12: "",
            dt3: "",
            sy5: "ܪܳܡ ܒܟܽܠ",
            sy4: "ܪܡ ܒܟܠ",
            lt11: "",
            sy8: "",
            dt2: "",
            id: "2978",
            lt10: "rom bkul",
            sy7: "",
            dt1: "maximal",
            sy9: ""
        },
        {
            dt3: "",
            id: "778",
            dt1: "Cousine",
            lt10: "bath dodo",
            sy6: "ܒܪܬ ܚܠܐ",
            sy5: "ܒܰܪ̱ܬ݂ ܕܳܕܳܐ",
            sy7: "ܒܰܪ̱ܬ݂ ܚܳܠܳܐ",
            lt11: "bath ĥolo",
            sy4: "ܒܪܬ ܕܕܐ",
            dt2: "",
            sy9: "",
            sy8: "",
            lt12: ""
        },
        {
            dt1: "putzen",
            dt2: "",
            sy8: "",
            sy4: "ܟܢܫ",
            id: "3552",
            sy9: "",
            lt11: "konesh",
            sy7: "ܟܳܢܶܫ",
            sy5: "ܟܢܰܫ",
            lt12: "",
            sy6: "ܟܢܫ",
            lt10: "knash",
            dt3: ""
        },
        {
            sy5: "ܐܺܝܪܰܚܡܶܠ",
            dt2: "",
            sy6: "",
            sy4: "ܐܝܪܚܡܠ",
            sy7: "",
            lt10: "ܐܝܪܚܡܠ",
            id: "2302",
            lt11: "ܐܝܪܚܡܠ",
            dt1: "Jerachmeel",
            sy9: "",
            dt3: "",
            sy8: "",
            lt12: ""
        },
        {
            sy5: "ܚܽܘܕܪܳܐ ܕܒܶܝܬ݂ ܢܰܗܪܺܝܢ",
            dt2: "",
            dt1: "Mesopotamien Verein",
            sy8: "",
            sy6: "",
            id: "3004",
            sy4: "ܚܘܕܪܐ ܕܒܝܬ ܢܗܪܝܢ",
            sy9: "",
            lt10: "ĥuđro d`beth nahrin",
            lt12: "",
            lt11: "",
            sy7: "",
            dt3: ""
        },
        {
            dt1: "Simon",
            sy9: "",
            dt3: "Semun",
            sy5: "ܫܡܥܘܢ",
            sy4: "ܫܡܥܘܢ",
            sy6: "ܣܝܡܘܢ",
            lt12: "ܣܝܡܝܘܢ",
            dt2: "Simyon",
            lt10: "ܫܡܥܘܢ",
            sy8: "",
            id: "4157",
            lt11: "ܫܡܥܘܢ",
            sy7: "ܣܝܡܘܢ"
        },
        {
            sy5: "ܚܰܬ݂ܢܳܐ ܘ ܟܰܠܬ݂ܳܐ",
            sy4: "ܚܬܢܐ ܘ ܟܠܬܐ",
            id: "696",
            sy6: "",
            dt3: "",
            dt2: "",
            lt11: "",
            lt12: "",
            lt10: "ĥathno u kaltho",
            sy8: "",
            sy9: "",
            sy7: "",
            dt1: "Brautpaar"
        },
        {
            lt12: "",
            sy7: "",
            lt11: "",
            dt1: "siehe hier",
            sy9: "",
            dt3: "",
            dt2: "",
            sy6: "",
            lt10: "ĥur horko",
            sy5: "ܚܽܘܪ ܗܳܪܟܳܐ",
            id: "4152",
            sy8: "",
            sy4: "ܚܘܪ ܗܪܟܐ"
        },
        {
            lt12: "shunoyo",
            id: "4510",
            sy6: "ܥܘܢܕܢܐ",
            sy8: "ܫܘܢܝܐ",
            lt10: "mauto",
            sy5: "ܡܰܘܬܳܐ",
            sy9: "ܫܽܘܢܳܝܳܐ",
            sy4: "ܡܘܬܐ",
            dt3: "Ableben",
            dt1: "Tod",
            sy7: "ܥܽܘܢܕܳܢܳܐ",
            dt2: "Sterben",
            lt11: "ĉundono"
        },
        {
            sy5: "ܟܦܽܘܪܝܳܐ",
            dt1: "Leugnung",
            id: "2794",
            sy6: "ܟܦܘܪܘܬܐ",
            dt3: "",
            sy9: "",
            sy7: "ܟܳܦܽܘܪܽܘܬ݂ܳܐ",
            sy4: "ܟܦܘܪܝܐ",
            lt11: "kofurutho",
            sy8: "",
            dt2: "Atheismus",
            lt10: "kfuryo",
            lt12: ""
        },
        {
            lt11: "methnasbonutho",
            lt12: "ĉolulutho",
            lt10: "nosubutho",
            sy9: "ܥܳܠܽܘܠܽܘܬ݂ܳܐ",
            sy4: "ܢܣܘܒܘܬܐ",
            sy5: "ܢܳܣܽܘܒܽܘܬ݂ܳܐ",
            dt2: "",
            sy8: "ܥܠܘܠܘܬܐ",
            sy6: "ܡܬܢܣܒܢܘܬܐ",
            sy7: "ܡܶܬ݂ܢܰܣܒܳܢܽܘܬ݂ܳܐ",
            dt3: "",
            dt1: "Annahme",
            id: "5970"
        },
        {
            sy8: "",
            sy5: "ܠܰܒܶܒ",
            sy9: "",
            lt11: "ܠܒܒ",
            lt12: "",
            dt2: "",
            dt3: "",
            dt1: "Labeb",
            id: "2688",
            lt10: "ܠܒܒ",
            sy6: "",
            sy4: "ܠܒܒ",
            sy7: ""
        },
        {
            dt2: "",
            sy7: "",
            id: "4452",
            sy6: "",
            lt11: "",
            sy8: "",
            lt12: "",
            lt10: "ŝbutho",
            sy9: "",
            dt1: "Thema",
            dt3: "",
            sy5: "ܨܒܽܘܬ݂ܳܐ",
            sy4: "ܨܒܘܬܐ"
        },
        {
            dt3: "",
            sy8: "",
            sy9: "",
            sy6: "",
            id: "5104",
            dt2: "Alm",
            lt11: "",
            sy7: "",
            sy5: "ܡܰܪܓܳܐ",
            lt12: "",
            sy4: "ܡܪܓܐ",
            dt1: "Wiese",
            lt10: "margo"
        },
        {
            lt12: "",
            dt2: "Schwan",
            sy9: "",
            dt1: "Gans",
            sy6: "ܘܙܐ",
            sy7: "ܘܰܙܳܐ",
            lt11: "wazo",
            sy8: "",
            sy4: "ܩܙܐ",
            dt3: "",
            id: "1468",
            lt10: "qazo",
            sy5: "ܩܰܙܳܐ"
        },
        {
            dt2: "",
            id: "1517",
            lt10: "ĥbischo",
            sy4: "ܚܒܝܫܐ",
            dt3: "",
            sy5: "ܚܒܺܝܫܳܐ",
            lt11: "ĥbishoyo",
            sy6: "ܚܒܝܫܝܐ",
            dt1: "Gefangener",
            sy9: "ܐܰܣܺܝܪܳܐ",
            sy7: "ܚܒܺܝܫܳܝܳܐ",
            lt12: "asiro",
            sy8: "ܐܣܝܪܐ"
        },
        {
            dt2: "",
            lt12: "",
            lt10: "ܕܫܐ",
            sy6: "",
            sy8: "",
            sy7: "",
            id: "952",
            sy5: "ܕܳܫܳܐ",
            sy9: "",
            dt1: "Doscho",
            sy4: "ܕܫܐ",
            dt3: "",
            lt11: "ܕܫܐ"
        },
        {
            lt10: "ĉurĉoro",
            sy5: "ܥܽܘܪܥܳܪܳܐ",
            dt2: "",
            dt1: "Gurgeln",
            lt11: "",
            sy4: "ܥܘܪܥܪܐ",
            sy7: "",
            id: "1763",
            sy8: "",
            dt3: "",
            sy6: "",
            sy9: "",
            lt12: ""
        },
        {
            id: "4659",
            sy4: "ܬܚܝܬ",
            dt2: "",
            sy7: "ܠܬܰܚܬ",
            dt3: "",
            lt12: "",
            sy8: "",
            dt1: "unten",
            lt10: "tĥith",
            sy6: "ܠܬܚܬ",
            sy5: "ܬܚܺܝܬ݂",
            lt11: "ltaĥt",
            sy9: ""
        },
        {
            sy4: "ܬܪܘܢܘܣ",
            sy6: "",
            dt2: "",
            sy8: "",
            dt1: "Thron",
            lt11: "",
            dt3: "",
            sy7: "",
            sy9: "",
            sy5: "ܬܪܽܘܢܽܘܣ",
            lt12: "",
            id: "4470",
            lt10: "trunus"
        },
        {
            sy8: "",
            sy9: "",
            dt3: "Keine Einigung",
            sy4: "ܠܐ ܫܘܝܘܬܐ",
            lt11: "lo auyutho",
            sy5: "ܠܳܐ ܫܰܘܝܽܘܬ݂ܳܐ",
            lt12: "",
            sy6: "ܠܐ ܐܘܝܘܬܐ",
            sy7: "ܠܳܐ ܐܰܘܝܽܘܬ݂ܳܐ",
            dt2: "Dissens",
            dt1: "Nichtübereinstimmung",
            lt10: "lo shauyutho",
            id: "3267"
        },
        {
            sy8: "",
            sy5: "ܩܛܳܠܳܐ ܢܰܦܫܳܝܳܐ",
            dt3: "",
            lt10: "qețlo nafsho",
            id: "4109",
            dt2: "Suizid",
            lt11: "",
            sy7: "",
            sy6: "",
            sy4: "ܩܛܠܐ ܢܦܫܝܐ",
            lt12: "",
            sy9: "",
            dt1: "Selbstmord"
        },
        {
            id: "6205",
            dt3: "",
            sy5: "ܐܰܬ݂ܪܳܐ ܕܡܰܘܠܳܕܳܐ",
            sy8: "",
            sy4: "ܐܬܪܐ ܕܡܘܠܕܐ",
            dt1: "Mutterland",
            sy7: "ܐܰܬ݂ܰܪ ܝܺܠܺܝܕܽܘܬ݂ܳܐ",
            sy6: "ܐܬܪ ܝܠܝܕܘܬܐ",
            sy9: "",
            dt2: "",
            lt12: "",
            lt11: "athar ilidutho",
            lt10: "athro d'maulodo"
        },
        {
            id: "4647",
            dt3: "",
            dt2: "",
            sy4: "ܬܒܝܠܝܐ",
            lt11: "",
            sy8: "",
            sy5: "ܬܺܒܶܝܠܳܝܳܐ",
            lt10: "tibeloyo",
            sy6: "",
            lt12: "",
            sy9: "",
            sy7: "",
            dt1: "universell"
        },
        {
            id: "783",
            sy5: "ܚܫܺܝܡܳܐ",
            dt2: "",
            sy9: "",
            sy8: "",
            sy6: "",
            dt1: "Dachboden",
            sy4: "ܚܫܝܡܐ",
            lt11: "",
            sy7: "",
            lt10: "ĥshimo",
            dt3: "",
            lt12: ""
        },
        {
            sy8: "",
            dt3: "",
            dt2: "",
            lt10: "mĉadronutho knushoyto",
            sy9: "",
            lt11: "",
            sy4: "ܡܥܕܪܢܘܬܐ ܟܢܘܫܝܝܬܐ",
            lt12: "",
            sy5: "ܡܥܰܕܪܳܢܽܘܬ݂ܳܐ ܟܢܽܘܫܳܝܬܳܐ",
            sy7: "",
            dt1: "Sozialhilfe",
            id: "4204",
            sy6: ""
        },
        {
            lt11: "",
            dt3: "",
            sy7: "",
            sy9: "",
            lt10: "eno kotheb no",
            sy4: "ܐܢܐ ܟܬܒ ܐܢܐ",
            sy8: "",
            lt12: "",
            dt1: "Ich schreibe",
            sy6: "",
            id: "2135",
            dt2: "Ich bin am schreiben",
            sy5: "ܐܶܢܳܐ ܟܳܬ݂ܶܒ ܐ̱ܢܳܐ"
        },
        {
            lt11: "",
            lt12: "",
            id: "4480",
            dt2: "",
            lt10: "ĉmar shelĥo",
            sy6: "",
            sy9: "",
            sy4: "ܥܡܪ ܫܠܚܐ",
            sy7: "",
            sy8: "",
            sy5: "ܥܡܰܪ ܫܠܶܚܳܐ",
            dt1: "Tierfell",
            dt3: ""
        },
        {
            sy7: "",
            sy4: "ܪܡܡܬܐ",
            lt10: "ܪܡܡܬܐ",
            sy8: "",
            lt12: "",
            sy9: "",
            dt1: "Rommata",
            dt3: "",
            sy6: "",
            sy5: "ܪܳܡܡܰܬܰܐ",
            dt2: "",
            id: "3743",
            lt11: "ܪܡܡܬܐ"
        },
        {
            dt1: "augenblicklich",
            sy4: "ܪܦܦܝܐ",
            lt12: "",
            sy6: "",
            sy5: "ܪܦܳܦܳܝܳܐ",
            sy7: "",
            lt10: "rfofoyo",
            id: "365",
            dt2: "",
            lt11: "",
            sy8: "",
            sy9: "",
            dt3: ""
        },
        {
            sy6: "",
            dt1: "Kommender",
            lt11: "",
            sy7: "",
            sy8: "",
            lt12: "",
            id: "2569",
            lt10: "othuyo",
            sy9: "",
            dt2: "",
            sy4: "ܐܬܘܝܐ",
            sy5: "ܐܳܬ݂ܽܘܝܳܐ",
            dt3: ""
        },
        {
            dt2: "temporäre Regierung",
            sy8: "",
            lt10: "quțnutho zabnonoyto",
            id: "5293",
            dt1: "zeitweilige Regierung",
            dt3: "",
            lt11: "",
            lt12: "",
            sy9: "",
            sy4: "ܩܘܛܢܘܬܐ ܙܒܢܢܝܬܐ",
            sy5: "ܩܽܘܛܢܽܘܬ݂ܳܐ ܙܰܒܢܳܢܳܝܬܳܐ",
            sy6: "",
            sy7: ""
        },
        {
            sy7: "ܬܰܡܰܪ",
            sy6: "ܬܡܪ",
            lt12: "ܬܡܪ",
            sy9: "",
            sy5: "ܬܰܡܰܪܰܐ",
            dt2: "",
            lt10: "ܬܡܪܐ",
            lt11: "ܬܡܪܐ",
            sy8: "",
            dt1: "Tamara",
            id: "4375",
            dt3: "",
            sy4: "ܬܡܪܐ"
        },
        {
            dt1: "Stellvertretung",
            id: "4260",
            sy5: "ܬܰܚܠܽܘܦܽܘܬ݂ܳܐ",
            sy8: "",
            sy9: "",
            lt12: "",
            dt3: "",
            sy6: "",
            lt10: "taĥlufutho",
            sy4: "ܬܚܠܘܦܘܬܐ",
            lt11: "",
            sy7: "",
            dt2: ""
        },
        {
            sy7: "",
            sy5: "ܕܒܶܚ",
            dt3: "",
            sy8: "",
            id: "3380",
            lt12: "",
            lt11: "",
            lt10: "dbeĥ",
            sy9: "",
            sy4: "ܕܒܚ",
            sy6: "",
            dt1: "opfern",
            dt2: ""
        },
        {
            sy9: "",
            lt11: "ܡܟܣܐ",
            sy6: "",
            sy8: "",
            sy5: "ܡܰܟܣܳܐ",
            dt3: "",
            dt2: "",
            lt12: "",
            dt1: "Makso",
            id: "2896",
            sy4: "ܡܟܣܐ",
            sy7: "",
            lt10: "ܡܟܣܐ"
        },
        {
            dt2: "Tibelya",
            sy8: "",
            sy9: "",
            dt3: "Tibelja",
            id: "4473",
            sy6: "ܬܐܒܠܝܐ",
            lt11: "ܬܐܒܝܠܝܐ",
            lt10: "ܬܐܒܝܠܝܐ",
            dt1: "Tibelia",
            lt12: "ܬܐܒܠܝܐ",
            sy7: "ܬܺܐܒܶܠܝܰܐ",
            sy5: "ܬܺܐܒܺܝܠܝܰܐ",
            sy4: "ܬܐܒܝܠܝܐ"
        },
        {
            sy7: "ܡܰܟܺܐܒܳܢܳܐ",
            sy6: "ܡܟܐܒܢܐ",
            lt11: "makibono",
            sy9: "ܡܰܚܫܳܢܳܐ",
            dt3: "",
            id: "4757",
            lt12: "maĥshono",
            sy5: "ܡܰܐܟܝܳܢܳܐ",
            sy4: "ܡܐܟܝܢܐ",
            sy8: "ܡܚܫܢܐ",
            dt2: "schmerzhaft",
            dt1: "verletzend",
            lt10: "makyono"
        },
        {
            dt2: "",
            sy7: "",
            sy6: "",
            dt1: "operieren",
            lt10: "ĉabed taĉbedtho",
            lt11: "",
            sy8: "",
            sy4: "ܥܒܕ ܬܥܒܕܬܐ",
            id: "3377",
            dt3: "",
            lt12: "",
            sy9: "",
            sy5: "ܥܰܒܶܕ ܬܰܥܒܶܕܬ݂ܳܐ"
        },
        {
            sy4: "ܫܪܝܪܐ",
            sy8: "",
            dt1: "wahr ",
            lt10: "shariro",
            sy6: "ܬܪܝܨܐ",
            sy9: "",
            sy5: "ܫܰܪܺܝܪܳܐ",
            lt11: "triŝo",
            id: "4950",
            sy7: "ܬܪܺܝܨܳܐ",
            lt12: "",
            dt2: "",
            dt3: ""
        },
        {
            id: "6003",
            sy5: "ܚܽܘܦܳܛܳܐ",
            sy9: "ܠܽܘܒܳܛܳܐ",
            sy6: "ܠܒܛܐ",
            lt11: "lboțo",
            lt10: "ĥufoțo",
            dt1: "Antrieb",
            sy8: "ܠܘܒܛܐ",
            lt12: "luboțo",
            dt2: "Ansporn",
            sy4: "ܚܘܦܛܐ",
            sy7: "ܠܒܳܛܳܐ",
            dt3: "Anreiz"
        },
        {
            sy9: "",
            sy6: "",
            sy7: "",
            sy4: "ܡܪܐ ܡܢܬܐ",
            sy8: "",
            sy5: "ܡܳܪܶܐ ܡܢܳܬ݂ܳܐ",
            dt1: "Anteilseigner",
            lt11: "",
            id: "6002",
            lt10: "more mnotho",
            dt2: "",
            dt3: "",
            lt12: ""
        },
        {
            sy7: "",
            dt2: "",
            dt1: "Simta",
            sy5: "ܣܺܝܡܬܰܐ",
            lt10: "ܣܝܡܬܐ",
            lt12: "",
            sy8: "",
            sy9: "",
            sy6: "",
            lt11: "ܣܝܡܬܐ",
            id: "4158",
            dt3: "",
            sy4: "ܣܝܡܬܐ"
        },
        {
            sy9: "",
            id: "5475",
            sy4: "ܩܪܢܐ",
            dt3: "",
            sy8: "",
            sy7: "ܒܽܘܩܺܝܢܳܐ",
            dt1: "Horn",
            lt11: "buqino",
            sy5: "ܩܰܪܢܳܐ",
            lt12: "",
            lt10: "qarno",
            sy6: "ܒܘܩܝܢܐ",
            dt2: ""
        },
        {
            dt2: "",
            sy8: "",
            dt3: "",
            dt1: "Holz",
            sy6: "",
            sy5: "ܩܰܝܣܳܐ",
            sy7: "",
            sy4: "ܩܝܣܐ",
            lt10: "qayso",
            lt12: "",
            sy9: "",
            id: "2045",
            lt11: ""
        },
        {
            sy8: "",
            sy5: "ܩܪܺܝܬ݂ܳܐ ܕܒܶܝܬ݂ ܣܒܺܝܪܺܝܢܳܐ",
            lt11: "beth sbirino",
            sy7: "ܒܶܝܬ݂ ܣܒܺܝܪܺܝܢܳܐ",
            sy9: "",
            lt10: "qritho d`beth sbirino",
            dt1: "Dorf Bsorino",
            lt12: "",
            sy4: "ܩܪܝܬܐ ܕܒܝܬ ܣܒܝܪܝܢܐ",
            dt3: "",
            id: "930",
            sy6: "ܒܝܬ ܣܒܝܪܝܢܐ",
            dt2: "Bsorino"
        },
        {
            sy4: "ܕܢܚܐ",
            sy6: "",
            sy7: "",
            dt3: "",
            sy9: "",
            sy5: "ܕܶܢܚܳܐ",
            lt11: "",
            lt12: "",
            dt1: "Fest der Erscheinung Jesu Christi",
            sy8: "",
            dt2: "",
            id: "1296",
            lt10: "denĥo"
        },
        {
            sy7: "",
            id: "3635",
            lt11: "",
            sy4: "ܕܠܐ ܒܙܕܩܐ",
            dt1: "rechtlos",
            lt10: "d`lo bzedqo",
            sy9: "",
            sy6: "",
            dt2: "",
            lt12: "",
            dt3: "",
            sy8: "",
            sy5: "ܕܠܳܐ ܒܙܕܩܐ"
        },
        {
            sy6: "",
            sy5: "ܚܶܘܳܪܳܐ",
            sy7: "",
            sy8: "",
            dt3: "",
            id: "1992",
            lt12: "",
            sy9: "",
            lt10: "ܚܘܪܐ",
            lt11: "ܚܘܪܐ",
            dt1: "Heworo",
            sy4: "ܚܘܪܐ",
            dt2: ""
        },
        {
            dt1: "schöne Stimme",
            dt3: "",
            dt2: "",
            lt10: "qolo basimo",
            id: "4012",
            sy4: "ܩܠܐ ܒܣܝܡܐ",
            sy9: "",
            sy5: "ܩܳܠܳܐ ܒܰܣܺܝܡܳܐ",
            sy6: "",
            lt12: "",
            sy8: "",
            sy7: "",
            lt11: ""
        },
        {
            sy7: "ܗܳܢܳܐ ܗܽܘ",
            lt11: "hono hu",
            id: "818",
            lt10: "honau",
            sy8: "",
            dt1: "Das ist er",
            sy9: "",
            dt3: "",
            dt2: "",
            sy6: "ܗܢܐ ܗܘ",
            sy5: "ܗܳܢܰܘ",
            lt12: "",
            sy4: "ܗܢܘ"
        },
        {
            lt11: "",
            sy7: "",
            sy8: "",
            lt12: "",
            dt3: "befestigen",
            sy9: "",
            dt1: "binden",
            sy4: "ܐܣܪ",
            id: "626",
            lt10: "esar",
            sy6: "",
            dt2: "zubinden",
            sy5: "ܐܶܣܰܪ"
        },
        {
            sy5: "ܢܽܘܗܪܰܬ",
            sy9: "",
            lt12: "",
            sy6: "",
            dt1: "Nuhrat",
            lt11: "ܢܘܗܪܬ",
            sy7: "",
            dt3: "",
            lt10: "ܢܘܗܪܬ",
            id: "3309",
            dt2: "",
            sy8: "",
            sy4: "ܢܘܗܪܬ"
        },
        {
            lt10: "gauno shaĉutho ayk shemsho",
            sy5: "ܓܰܘܢܳܐ ܫܰܥܽܘܬܳܐ ܐܰܝܟ ܫܶܡܫܶܐ",
            dt1: "gelbe Farbe wie die Sonne",
            lt12: "",
            sy8: "",
            sy9: "",
            dt2: "",
            sy4: "ܓܘܢܐ ܫܥܘܬܐ ܐܝܟ ܫܡܫܐ",
            id: "1546",
            sy6: "",
            sy7: "",
            dt3: "",
            lt11: ""
        },
        {
            lt10: "fsaq saĉro",
            sy4: "ܦܣܩ ܙܥܪܐ",
            sy5: "ܦܣܰܩ ܙܰܥܪܳܐ",
            dt1: "abschneiden",
            sy9: "ܓܪܰܥ",
            sy8: "ܓܪܥ",
            lt11: "fsa",
            id: "5631",
            sy7: "ܦܣܰܐ",
            lt12: "graĉ",
            dt2: "",
            dt3: "",
            sy6: "ܦܣܐ"
        },
        {
            sy9: "",
            dt3: "",
            sy6: "",
            id: "1665",
            sy8: "",
            lt11: "",
            lt12: "",
            sy4: "ܒܘܪܓܐ ܕܢܩܘܫܐ",
            sy5: "ܒܽܘܪܓܳܐ ܕܢܳܩܽܘܫܳܐ",
            sy7: "",
            lt10: "burgo d`noqusho",
            dt1: "Glockenturm",
            dt2: ""
        },
        {
            lt11: "",
            dt1: "n",
            sy5: "ܢ",
            lt12: "",
            id: "3142",
            dt2: "",
            sy9: "",
            sy4: "ܢ",
            dt3: "",
            lt10: "nun",
            sy8: "",
            sy7: "",
            sy6: ""
        },
        {
            lt11: "drash",
            lt10: "ilef",
            dt1: "üben",
            dt3: "",
            sy4: "ܝܠܦ",
            id: "4611",
            sy8: "",
            sy9: "",
            lt12: "",
            sy7: "ܕܪܰܫ",
            sy5: "ܝܺܠܶܦ",
            sy6: "ܕܪܫ",
            dt2: "lernen"
        },
        {
            sy5: "ܡܠܳܝܳܐ ܕܥܰܪܫܳܐ",
            lt12: "",
            dt2: "",
            sy4: "ܡܠܝܐ ܕܥܪܫܐ",
            sy7: "",
            id: "5236",
            lt10: "mloyo d`ĉarsho",
            sy8: "",
            dt1: "Zahnfüllung",
            sy9: "",
            lt11: "",
            sy6: "",
            dt3: ""
        },
        {
            sy8: "",
            sy7: "",
            sy9: "",
            lt10: "ܪܒܝܥܐ",
            dt2: "",
            sy5: "ܪܰܒܺܝܥܰܐ",
            sy6: "",
            sy4: "ܪܒܝܥܐ",
            dt1: "Rabia",
            lt11: "ܪܒܝܥܐ",
            dt3: "",
            lt12: "",
            id: "3579"
        },
        {
            sy7: "ܚܰܒܣܶܣ",
            lt10: "qritho ĥabses",
            dt3: "",
            id: "934",
            dt2: "Hapsus",
            lt12: "",
            sy9: "",
            lt11: "ĥabses",
            sy8: "",
            dt1: "Dorf Hapsus",
            sy4: "ܩܪܝܬܐ ܚܒܣܣ",
            sy5: "ܩܪܺܝܬ݂ܳܐ ܚܰܒܣܶܣ",
            sy6: "ܚܒܣܣ"
        },
        {
            sy4: "ܪܚܘܒ",
            sy7: "",
            dt2: "",
            sy6: "",
            lt10: "ܪܚܘܒ",
            dt3: "",
            id: "3739",
            dt1: "Rohub",
            lt12: "",
            sy8: "",
            sy9: "",
            sy5: "ܪܳܚܽܘܒ",
            lt11: "ܪܚܘܒ"
        },
        {
            dt2: "",
            dt3: "",
            id: "1762",
            sy7: "",
            sy9: "",
            sy8: "",
            sy4: "ܓܘܪܒܘܙ",
            sy5: "ܓܽܘܪܒܽܘܙ",
            sy6: "",
            lt11: "ܓܘܪܒܘܙ",
            dt1: "Gürbüz",
            lt10: "ܓܘܪܒܘܙ",
            lt12: ""
        },
        {
            sy9: "",
            sy5: "ܒܰܠܫܺܝܩܰܐ",
            lt11: "",
            id: "538",
            sy6: "",
            sy8: "",
            dt1: "Belgien",
            sy4: "ܒܠܫܝܩܐ",
            lt10: "balshiqa",
            dt2: "",
            lt12: "",
            dt3: "",
            sy7: ""
        },
        {
            lt11: "",
            dt3: "",
            lt10: "mbaqronitho",
            sy6: "",
            sy7: "",
            sy5: "ܡܒܰܩܪܳܢܝܺܬ݂ܳܐ",
            dt1: "Inspekteurin",
            sy8: "",
            sy4: "ܡܒܩܪܢܝܬܐ",
            lt12: "",
            dt2: "",
            id: "2213",
            sy9: ""
        },
        {
            dt1: "steht auf und setzt euch hierher",
            dt3: "",
            sy7: "",
            sy6: "",
            lt11: "",
            lt10: "qumun u tawun l`horko",
            dt2: "",
            sy5: "ܩܽܘܡܽܘܢ ܘ ܬܰܘܘܽܢ ܠܗܳܪܟܳܐ",
            sy9: "",
            sy4: "ܩܘܡܘܢ ܘ ܬܘܘܢ ܠܗܪܟܐ",
            sy8: "",
            lt12: "",
            id: "4249"
        },
        {
            sy9: "",
            sy6: "ܠܦ",
            sy4: "ܨܪ",
            sy8: "",
            lt11: "laf",
            dt3: "",
            id: "4780",
            dt2: "",
            sy5: "ܨܰܪ",
            sy7: "ܠܰܦ",
            lt12: "",
            dt1: "verpacken",
            lt10: "ŝar"
        },
        {
            sy6: "",
            sy8: "",
            lt10: "bținto",
            sy4: "ܒܛܝܢܬܐ",
            lt11: "",
            id: "4065",
            dt3: "",
            lt12: "",
            sy5: "ܒܛܺܝܢܬܳܐ",
            sy9: "",
            sy7: "",
            dt1: "schwanger",
            dt2: ""
        },
        {
            dt3: "",
            sy4: "ܪܚܝܠ",
            lt11: "ܪܚܝܠ",
            sy5: "ܪܰܚܶܝܠ",
            sy7: "",
            dt2: "",
            sy6: "",
            lt10: "ܪܚܝܠ",
            sy9: "",
            sy8: "",
            id: "3589",
            lt12: "",
            dt1: "Rachel"
        },
        {
            sy4: "ܒܝܬ ܙܝܢܐ",
            sy6: "ܐܠܨܝܬܐ ܕܦܠܚܘܬܐ",
            sy7: "ܐܳܠܨܳܝܳܬ݂ܳܐ ܕܦܳܠܚܽܘܬ݂ܳܐ",
            sy5: "ܒܶܝܬ݂ ܙܰܝܢܳܐ",
            lt12: "",
            sy9: "",
            lt10: "beth zayno",
            lt11: "olŝoyotho d'folĥutho",
            dt3: "",
            id: "6207",
            dt2: "",
            sy8: "",
            dt1: "Munition"
        },
        {
            sy9: "",
            sy7: "",
            sy5: "ܚܙܺܝ",
            dt1: "schau",
            sy6: "",
            lt10: "ĥzi",
            lt11: "",
            id: "3909",
            dt2: "guck",
            dt3: "",
            sy4: "ܚܙܝ",
            lt12: "",
            sy8: ""
        },
        {
            sy6: "ܐܝܙܒܠ",
            sy5: "ܥܝܣܐܒܠ",
            id: "2241",
            lt10: "ܐܝܙܒܠ",
            dt2: "",
            dt3: "",
            dt1: "Isabell",
            sy4: "ܐܝܙܒܠ",
            lt12: "ܐܝܙܒܠ",
            sy7: "ܐܺܝܙܰܒܶܠ",
            sy9: "",
            sy8: "",
            lt11: "ܐܝܙܒܠ"
        },
        {
            lt12: "",
            sy5: "ܐܽܘܫܰܥܢܳܐ",
            lt11: "ushaĉno bamraume",
            sy8: "",
            id: "2054",
            sy4: "ܐܘܫܥܢܐ",
            sy9: "",
            sy6: "ܐܘܫܥܢܐ ܒܡܪܘܡܐ",
            dt1: "Hosanna",
            dt3: "Hosianna",
            lt10: "ushaĉno",
            dt2: "Gib Segen in der Höhe",
            sy7: "ܐܽܘܫܰܥܢܳܐ ܒܰܡܪܰܘܡܶܐ"
        },
        {
            lt10: "floțwotho",
            sy9: "",
            dt1: "Planeten",
            id: "3471",
            dt2: "",
            dt3: "",
            sy7: "ܦܰܠܺܝ̈ܛܶܐ",
            lt12: "",
            sy4: "ܦܠܛܘܬܐ",
            lt11: "falițe",
            sy6: "ܦܠܝܛܐ",
            sy8: "",
            sy5: "ܦܠܳܛܘ̈ܳܬ݂ܳܐ"
        },
        {
            dt2: "Gewöhnung",
            sy8: "",
            dt1: "Gewohnheit",
            id: "1633",
            dt3: "",
            sy4: "ܥܝܕܐ",
            lt11: "ĉuyodo",
            sy5: "ܥܝܳܕܳܐ",
            lt10: "ĉyodo",
            sy7: "ܥܽܘܝܳܕܳܐ",
            sy6: "ܥܘܝܕܐ",
            lt12: "",
            sy9: ""
        },
        {
            id: "6126",
            lt11: "",
            dt3: "",
            sy6: "",
            sy7: "",
            sy4: "ܚܢܘܬ ܦܐܪܐ",
            lt12: "",
            sy5: "ܚܳܢܽܘܬ݂ ܦܺܐܪܶܐ",
            dt1: "Obstladen",
            dt2: "",
            sy9: "",
            lt10: "ĥonuth fire",
            sy8: ""
        },
        {
            id: "1456",
            dt1: "Gabriela",
            lt10: "ܓܒܪܐܝܠܐ",
            sy9: "",
            lt12: "",
            sy6: "",
            sy7: "",
            lt11: "ܓܒܪܐܝܠܐ",
            sy5: "ܓܰܒܪܺܐܝܶܠܰܐ",
            dt2: "",
            dt3: "",
            sy4: "ܓܒܪܐܝܠܐ",
            sy8: ""
        },
        {
            dt1: "langsam",
            sy4: "ܡܬܝܢܐ",
            sy8: "",
            id: "2709",
            lt12: "",
            dt3: "",
            lt11: "matinoith",
            sy5: "ܡܰܬܺܝܢܳܐ",
            sy6: "ܡܬܝܢܐܝܬ",
            lt10: "matino",
            sy9: "",
            dt2: "träge",
            sy7: "ܡܰܬܺܝܢܳܐܝܺܬ݂"
        },
        {
            sy7: "",
            sy4: "ܡܠܐ",
            sy5: "ܡ̈ܶܠܶܐ",
            sy8: "",
            lt12: "",
            dt2: "",
            dt3: "",
            sy6: "",
            dt1: "Wörter",
            id: "5166",
            lt11: "",
            lt10: "mele",
            sy9: ""
        },
        {
            sy6: "ܟܫܝܠܘܬܐ",
            sy8: "",
            sy5: "ܟܶܫܠܳܐ",
            lt11: "kshilutho",
            dt2: "Verdacht",
            sy9: "",
            dt1: "Zweifel",
            lt10: "keshlo",
            dt3: "",
            sy4: "ܟܫܠܐ",
            id: "5390",
            sy7: "ܟܫܺܝܠܽܘܬ݂ܳܐ",
            lt12: ""
        },
        {
            id: "1419",
            lt10: "qroțin",
            sy9: "ܚܫܳܡܺܝܬ݂ܳܐ",
            dt3: "Abendessen",
            sy4: "ܩܪܛܝܢ",
            dt1: "Frühstück",
            dt2: "Mittagessen",
            sy8: "ܚܫܡܝܬܐ",
            sy5: "ܩܪܳܛܺܝܢ",
            sy7: "ܥܪܳܝܬܳܐ",
            lt12: "ĥshomitho",
            lt11: "ĉroyto",
            sy6: "ܥܪܝܬܐ"
        },
        {
            sy9: "",
            lt10: "toqulo",
            sy6: "ܡܣܐܬܐ",
            sy7: "ܡܰܣܰܐܬ݂ܳܐ",
            sy5: "ܬܳܩܽܘܠܳܐ",
            id: "4915",
            dt1: "Waage",
            sy4: "ܬܩܘܠܐ",
            lt12: "",
            sy8: "",
            lt11: "masatho",
            dt2: "",
            dt3: ""
        },
        {
            lt12: "",
            dt2: "",
            lt11: "maqfonutho",
            dt3: "",
            sy4: "ܐܩܦܬܐ",
            sy7: "ܡܰܩܦܳܢܽܘܬ݂ܳܐ",
            sy6: "ܡܩܦܢܘܬܐ",
            sy9: "",
            id: "678",
            sy8: "",
            sy5: "ܐܰܩܶܦܬ݂ܳܐ",
            dt1: "Bonus",
            lt10: "aqeftho"
        },
        {
            lt12: "",
            sy9: "",
            lt11: "",
            dt1: "Wiege",
            dt3: "",
            sy8: "",
            sy5: "ܕܰܪܓܽܘܫܬܳܐ",
            sy7: "",
            dt2: "",
            sy6: "",
            lt10: "dargushto",
            id: "5102",
            sy4: "ܕܪܓܘܫܬܐ"
        },
        {
            sy4: "ܡܐܟܝܢܐ ܕܠܝܫܐ",
            sy8: "",
            dt1: "Knetmaschine",
            sy6: "",
            sy5: "ܡܰܐܟܺܝܢܰܐ ܕܠܰܝܫܳܐ",
            sy9: "",
            sy7: "",
            dt2: "",
            lt11: "",
            lt10: "makina d`leysho",
            lt12: "",
            dt3: "",
            id: "2542"
        },
        {
            sy5: "ܡܳܐ ",
            sy6: "ܩ",
            sy9: "",
            lt12: "",
            dt3: "",
            id: "38",
            sy4: "ܡܐܐ",
            dt1: "100",
            dt2: "Hundert",
            lt11: "qof",
            lt10: "mo ",
            sy8: "",
            sy7: "ܩ"
        },
        {
            sy6: "",
            dt1: "Hand Gottes",
            lt10: "iđo d`alloho",
            sy8: "",
            lt11: "",
            lt12: "",
            sy7: "",
            id: "1848",
            dt3: "",
            sy9: "",
            sy5: "ܐܺܝܕܳܐ ܕܰܐܠܳܗܳܐ",
            dt2: "",
            sy4: "ܐܝܕܐ ܕܐܠܗܐ"
        },
        {
            dt2: "",
            sy7: "ܬܳܪܶܦ",
            id: "5648",
            sy5: "ܛܡܰܫ",
            lt10: "țmash",
            sy4: "ܛܡܫ",
            dt3: "",
            lt11: "toref",
            sy6: "ܬܪܦ",
            dt1: "absenken",
            lt12: "",
            sy8: "",
            sy9: ""
        },
        {
            lt10: "ܐܠܪܝ",
            sy6: "",
            sy8: "",
            sy7: "",
            dt2: "Elroi",
            lt11: "ܐܠܪܝ",
            id: "1087",
            dt3: "",
            lt12: "",
            sy5: "ܐܶܠܪܳܝ",
            dt1: "Elroy",
            sy4: "ܐܠܪܝ",
            sy9: ""
        },
        {
            sy8: "",
            sy5: "ܒܨܺܝܪ ܩܰܠܺܝܠ",
            id: "5370",
            sy7: "",
            sy9: "",
            dt3: "",
            lt11: "",
            sy4: "ܒܨܝܪ ܩܠܝܠ",
            sy6: "",
            lt10: "bŝir qalil",
            dt1: "zumindest",
            lt12: "",
            dt2: ""
        },
        {
            id: "1861",
            dt2: "anhängen",
            sy5: "ܥܰܠܶܩ",
            sy7: "",
            dt3: "aufhängen",
            sy6: "",
            dt1: "hängen",
            lt11: "",
            sy8: "",
            sy4: "ܥܠܩ",
            sy9: "",
            lt10: "ĉaleq",
            lt12: ""
        },
        {
            dt2: "",
            id: "2661",
            sy7: "",
            lt10: "ܟܘܟܠܬܐ",
            sy9: "",
            sy5: "ܟܽܘܟܰܠܬܰܐ",
            sy4: "ܟܘܟܠܬܐ",
            lt11: "ܟܘܟܠܬܐ",
            dt1: "Kukaltha",
            dt3: "",
            sy8: "",
            sy6: "",
            lt12: ""
        },
        {
            id: "2288",
            lt12: "",
            sy4: "ܝܪܝܒܐ",
            sy9: "",
            sy7: "",
            lt10: "ܝܪܝܒܐ",
            lt11: "ܝܪܝܒܐ",
            dt2: "",
            sy6: "",
            sy8: "",
            dt1: "Jariba",
            dt3: "",
            sy5: "ܝܰܪܺܝܒܰܐ"
        },
        {
            lt12: "",
            lt11: "",
            dt1: "Konkursantrag",
            lt10: "boĉuth ĥusrono",
            sy9: "",
            dt2: "",
            sy6: "",
            sy8: "",
            sy4: "ܒܥܘܬ ܚܘܣܪܢܐ",
            id: "6069",
            sy5: "ܒܳܥܽܘܬ݂ ܚܽܘܣܪܳܢܳܐ",
            dt3: "",
            sy7: ""
        },
        {
            dt3: "",
            dt1: "Absonderung",
            id: "5652",
            lt11: "mfalgutho",
            sy5: "ܦܽܘܪܳܫܳܐ",
            dt2: "",
            sy8: "ܡܦܪܫܢܘܬܐ",
            lt10: "furosho",
            sy4: "ܦܘܪܫܐ",
            sy9: "ܡܦܰܪܫܳܢܽܘܬ݂ܳܐ",
            sy7: "ܡܦܰܠܓܽܘܬ݂ܳܐ",
            lt12: "mfarshonutho",
            sy6: "ܡܦܠܓܘܬܐ"
        },
        {
            sy6: "",
            sy7: "",
            sy8: "",
            id: "1577",
            dt2: "",
            lt12: "",
            lt10: "mshadro",
            dt1: "Gesandter",
            lt11: "",
            sy4: "ܡܫܕܪܐ",
            sy5: "ܡܫܰܕܪܳܐ",
            sy9: "",
            dt3: ""
        },
        {
            lt11: "ŝoyutho",
            sy9: "",
            id: "5365",
            dt1: "Zuhörer",
            dt3: "",
            sy5: "ܫܳܡܽܘܥܳܐ",
            sy7: "ܨܽܝܽܘܬ݂ܳܐ",
            lt10: "shomuĉo",
            sy8: "",
            sy4: "ܫܡܘܥܐ",
            lt12: "",
            sy6: "ܨܝܘܬܐ",
            dt2: ""
        },
        {
            id: "4946",
            sy6: "",
            sy4: "ܣܢܕܘܩ ܓܘܒܝܐ",
            sy8: "",
            dt3: "",
            lt12: "",
            sy5: "ܣܰܢܕܽܘܩ ܓܽܘܒܳܝܳܐ",
            dt1: "Wahlurne",
            lt10: "sanduq guboyo",
            sy9: "",
            sy7: "",
            lt11: "",
            dt2: ""
        },
        {
            sy6: "",
            sy4: "ܬܚܘܝܬ ܡܕܐܬܐ",
            lt10: "taĥwith d`madatho",
            id: "4267",
            dt3: "",
            dt1: "Steuerbescheid",
            dt2: "",
            sy9: "",
            sy8: "",
            lt11: "",
            lt12: "",
            sy5: "ܬܰܚܘܺܝܬ݂ ܡܰܕܰܐܬ݂ܳܐ",
            sy7: ""
        },
        {
            lt10: "laĥmo fațiro",
            sy9: "",
            sy7: "ܦܰܛܺܝܪܳܐ",
            dt1: "ungesäuertes Brot",
            sy5: "ܠܰܚܡܳܐ ܦܰܛܺܝܪܳܐ",
            id: "6434",
            dt2: "",
            sy6: "ܦܛܝܪܐ",
            sy8: "",
            lt11: "fațiro",
            sy4: "ܠܚܡܐ ܦܛܝܪܐ",
            lt12: "",
            dt3: ""
        },
        {
            id: "3158",
            dt2: "",
            sy7: "",
            dt1: "Nachfolgerin",
            sy4: "ܢܩܘܦܬܐ",
            lt10: "noqufto",
            sy6: "",
            dt3: "",
            lt11: "",
            sy5: "ܢܳܩܽܘܦܬܳܐ",
            sy8: "",
            lt12: "",
            sy9: ""
        },
        {
            lt12: "",
            sy7: "",
            lt10: "barđo",
            dt3: "",
            sy5: "ܒܰܪܕܳܐ",
            dt1: "Hagel",
            sy8: "",
            lt11: "",
            id: "1819",
            sy9: "",
            dt2: "",
            sy6: "",
            sy4: "ܒܪܕܐ"
        },
        {
            lt12: "",
            id: "3141",
            sy8: "",
            sy7: "",
            dt3: "",
            sy4: "ܝܘܡ ܐܡܗܬܐ",
            sy5: "ܝܽܘܡ ܐܶܡܗܳܬ݂ܳܐ",
            sy6: "",
            dt2: "",
            lt10: "yum emhotho",
            lt11: "",
            dt1: "Muttertag",
            sy9: ""
        },
        {
            lt10: "shorito",
            sy7: "",
            id: "2426",
            sy4: "ܫܪܝܬܐ",
            lt12: "",
            lt11: "",
            sy9: "",
            sy5: "ܫܳܪܺܝܬ݂ܳܐ ",
            sy6: "",
            dt3: "",
            dt1: "Kassette",
            dt2: "",
            sy8: ""
        },
        {
            sy4: "ܩܠܘܠܐ",
            sy8: "",
            lt10: "qalulo",
            sy9: "",
            dt2: "rasch",
            sy6: "ܟ݂ܐܝܦܐ",
            lt11: "khayfo",
            id: "1336",
            dt3: "schnell",
            dt1: "flott",
            sy5: "ܩܰܠܽܘܠܳܐ",
            lt12: "",
            sy7: "ܟ݂ܰܐܺܝܦܳܐ"
        },
        {
            dt2: "Kohl",
            sy5: "ܩܽܘܦ̈ܛܶܐ",
            sy9: "",
            sy6: "",
            dt3: "Endivie",
            sy4: "ܩܘܦܛܐ",
            id: "656",
            lt11: "",
            lt12: "",
            sy7: "",
            dt1: "Blumenkohl",
            sy8: "",
            lt10: "qufțe"
        },
        {
            lt11: "farfaĉ",
            dt1: "abspülen",
            lt10: "fayaĉ",
            sy8: "",
            lt12: "",
            sy9: "",
            sy4: "ܦܝܥ",
            sy6: "ܦܪܦܥ",
            dt3: "",
            sy7: "ܦܰܪܦܰܥ",
            id: "119",
            sy5: "ܦܰܝܰܥ",
            dt2: ""
        },
        {
            lt11: "",
            dt3: "",
            id: "6292",
            dt2: "",
            lt12: "",
            lt10: "lo mzauğo",
            sy9: "",
            sy6: "",
            sy5: "ܠܳܐ ܡܙܰܘܓ݂ܳܐ",
            dt1: "Zolibatär",
            sy4: "ܠܐ ܡܙܘܓܐ",
            sy7: "",
            sy8: ""
        },
        {
            sy7: "ܣܳܥܽܘܪܳܐ",
            lt10: "orĥo",
            dt2: "Besucher",
            lt12: "",
            sy6: "ܣܥܘܪܐ",
            sy9: "",
            sy4: "ܐܪܚܐ",
            sy5: "ܐܳܪܚܳܐ",
            sy8: "",
            dt3: "",
            lt11: "soĉuro",
            dt1: "Gast",
            id: "1483"
        },
        {
            id: "6408",
            dt2: "",
            dt3: "",
            sy9: "",
            sy5: "ܙܽܘܒܳܢܳܐ ܠܚܽܘܕܳܝܳܐ",
            dt1: "Einzelverkäufer",
            lt12: "",
            lt11: "",
            lt10: "zubono l`ĥudoyo",
            sy7: "",
            sy8: "",
            sy4: "ܙܘܒܢܐ ܠܚܘܕܝܐ",
            sy6: ""
        },
        {
            sy4: "ܨܘܕ",
            sy7: "ܨܳܕ",
            lt12: "",
            dt1: "fangen",
            sy9: "",
            lt10: "ŝud",
            id: "1253",
            sy8: "",
            lt11: "ŝod",
            dt2: "",
            sy5: "ܨܽܘܕ",
            sy6: "ܨܕ",
            dt3: ""
        },
        {
            lt12: "",
            sy9: "",
            sy7: "",
            sy5: "ܓܪܳܚܰܡܢܳܐ ܝܶܫܘܽܥ ܡܫܺܝܚܳܐ",
            dt1: "Ich liebe Jesus Christus",
            dt2: "",
            dt3: "",
            id: "2126",
            sy6: "",
            lt10: "groĥamno yeshuĉ mshiĥo",
            sy4: "ܓܪܚܡܢܐ ܝܫܘܥ ܡܫܝܚܐ",
            sy8: "",
            lt11: ""
        },
        {
            sy5: "ܡܕܰܟܝܳܢܽܘܬ݂ ܕܡܳܐ",
            sy6: "",
            lt12: "",
            dt1: "Blutreinigung",
            lt10: "mdakyonuth dmo",
            sy9: "",
            sy8: "",
            dt2: "",
            sy4: "ܡܕܟܝܢܘܬ ܕܡܐ",
            lt11: "",
            sy7: "",
            dt3: "",
            id: "668"
        },
        {
            sy6: "",
            lt12: "",
            sy9: "",
            sy4: "ܐܕܫܐ",
            sy7: "",
            dt1: "Adescha",
            dt2: "",
            dt3: "",
            lt11: "ܐܕܫܐ",
            sy5: "ܐܰܕܶܫܰܐ",
            sy8: "",
            id: "130",
            lt10: "ܐܕܫܐ"
        },
        {
            sy7: "ܙܽܘܡܳܪܳܐ",
            id: "4159",
            sy9: "ܙܡܺܝܪܬܳܐ",
            lt10: "zmoro",
            sy5: "ܙܡܳܪܳܐ",
            sy8: "ܙܡܝܪܬܐ",
            sy4: "ܙܡܪܐ",
            dt1: "Singen",
            dt2: "Lied",
            sy6: "ܙܘܡܪܐ",
            lt11: "zumoro",
            dt3: "Song",
            lt12: "zmirto"
        },
        {
            dt3: "",
            lt11: "",
            sy8: "",
            sy6: "",
            dt2: "",
            sy4: "ܡܣܒܥܢܐ",
            sy9: "",
            lt12: "",
            lt10: "msabĉono",
            dt1: "satt",
            sy7: "",
            id: "3852",
            sy5: "ܡܣܰܒܥܳܢܳܐ"
        },
        {
            sy6: "",
            sy9: "",
            sy8: "",
            lt10: "eno ĥoye no bgermanya",
            lt12: "",
            dt1: "Ich wohne in Deutschland",
            dt2: "",
            sy4: "ܐܢܐ ܚܝܐ ܐܢܐ ܒܓܪܡܢܝܐ",
            sy5: "ܐܶܢܐܳ ܚܳܝܶܐ ܐ̱ܢܳܐ ܒܓܶܪܡܰܢܰܐ",
            lt11: "",
            dt3: "",
            sy7: "",
            id: "5458"
        },
        {
            sy7: "",
            sy6: "",
            lt10: "yamo hendwoyo",
            sy8: "",
            lt12: "",
            sy5: "ܝܰܡܳܐ ܗܶܢܕܘܳܝܳܐ",
            dt1: "Indischer Ozean",
            id: "2197",
            lt11: "",
            dt2: "Ozean Indien",
            dt3: "",
            sy9: "",
            sy4: "ܝܡܐ ܗܢܕܘܝܐ"
        },
        {
            sy5: "ܣܶܦܬܳܐ ܥܶܠܳܝܬܳܐ",
            sy6: "",
            sy9: "",
            lt12: "",
            dt2: "",
            sy7: "",
            sy4: "ܣܦܬܐ ܥܠܝܬܐ",
            lt10: "sefto ĉeloyto",
            dt1: "Vorderlippe",
            id: "4898",
            dt3: "",
            lt11: "",
            sy8: ""
        },
        {
            sy6: "ܡܥܘܬܐ",
            id: "813",
            sy8: "",
            dt2: "",
            dt3: "",
            sy9: "",
            lt11: "mĉutho",
            lt10: "maĉyo",
            sy7: "ܡܥܽܘܬ݂ܳܐ",
            sy5: "ܡܰܥܝܳܐ",
            sy4: "ܡܥܝܐ",
            dt1: "Darm",
            lt12: ""
        },
        {
            sy7: "",
            id: "1034",
            lt12: "",
            dt2: "",
            sy4: "ܗܦܟ ܝܠܘܦܐ ܡܢ ܒܝܬ ܣܦܪܐ",
            lt10: "hfakh yolufo men beth sefro",
            lt11: "",
            dt1: "Ein Schüler ging zur Schule",
            sy9: "",
            sy6: "",
            dt3: "",
            sy8: "",
            sy5: "ܗܦܰܟ݂ ܝܳܠܽܘܦܳܐ ܡܶܢ ܒܶܝܬ݂ ܣܶܦܪܳܐ"
        },
        {
            sy9: "",
            lt11: "bĉauun u methiheb lkhun",
            sy6: "ܒܥܘܘܢ ܘܡܐܬܝܗܒ ܠܟܘܢ",
            lt10: "bĉau u nethiheb lkhun",
            sy7: "ܒܥܰܘܽܘܢ ܘܡܽܐܬ݂ܺܝܗܶܒ ܠܟ݂ܽܘܢ",
            sy5: "ܒܥܰܘ ܘ ܢܶܬ݂ܺܝܗܶܒ ܠܟ݂ܽܘܢ",
            id: "50",
            sy8: "",
            sy4: "ܒܥܘ ܘ ܢܬܝܗܒ ܠܟܘܢ",
            lt12: "",
            dt2: "",
            dt1: " bittet und euch wird gegeben",
            dt3: ""
        },
        {
            lt12: "",
            sy6: "",
            dt2: "",
            dt1: "Nationalismus",
            sy9: "",
            lt10: "umthonoyutho",
            sy8: "",
            lt11: "",
            sy4: "ܐܘܡܬܢܝܘܬܐ",
            dt3: "",
            sy7: "",
            id: "3218",
            sy5: "ܐܽܘܡܬ݂ܳܢܳܝܽܘܬ݂ܳܐ"
        },
        {
            sy5: "ܥܶܣܪܺܝܢ ܘܬܶܫܥܳܐ",
            sy4: "ܥܣܪܝܢ ܘܬܫܥܐ",
            sy7: "ܥܶܣܪܺܝܢ ܘܰܬܫܰܥ",
            lt10: "ĉesrin w`teshĉo",
            dt2: "Neunundzwanzig",
            dt1: "29",
            lt12: "kof-țeth",
            lt11: "ĉesrin wa`tshaĉ",
            sy9: "ܟܛ",
            id: "30",
            dt3: "",
            sy6: "ܥܣܪܝܢ ܘܬܫܥ",
            sy8: "ܟܛ"
        },
        {
            lt10: "maroĥutho",
            lt11: "ĥarbutho",
            sy8: "",
            id: "1378",
            sy9: "",
            sy6: "ܚܪܒܘܬܐ",
            sy5: "ܡܰܪܳܚܽܘܬ݂ܳܐ",
            sy4: "ܡܪܚܘܬܐ",
            dt2: "",
            lt12: "",
            dt3: "",
            dt1: "Frechheit",
            sy7: "ܚܰܪܒܽܘܬ݂ܳܐ"
        },
        {
            sy9: "",
            lt12: "",
            sy5: "ܫܶܥܝܳܐ ܕܕܰܐܡܰܐ",
            sy7: "",
            sy6: "",
            dt3: "",
            sy4: "ܫܥܝܐ ܕܕܐܡܐ",
            dt1: "Damespiel",
            dt2: "",
            lt10: "sheĉyo d`dama",
            id: "792",
            sy8: "",
            lt11: ""
        },
        {
            dt3: "",
            dt1: "20",
            lt12: "",
            lt11: "kof",
            id: "21",
            sy6: "ܟܟ",
            sy8: "",
            lt10: "ĉesrin",
            sy4: "ܥܣܪܝܢ",
            sy9: "",
            dt2: "Zwanzig",
            sy5: "ܥܶܣܪܺܝܢ",
            sy7: "ܟܟ"
        },
        {
            id: "4230",
            sy4: "ܡܰܠܟܽܘܬ݂ܳܐ",
            sy7: "",
            sy8: "",
            lt12: "",
            sy9: "",
            dt2: "Länder mit Monarchie",
            lt10: "malkutho",
            sy5: "ܡܰܠܟܽܘܬ݂ܳܐ",
            dt1: "Staaten mit Monarchie",
            lt11: "",
            dt3: "Monarchischer Staat",
            sy6: ""
        },
        {
            lt12: "mĉas",
            id: "6282",
            sy5: "ܦܰܪܟܶܣ",
            dt1: "zertrümmern",
            sy7: "ܬܒܰܪ",
            lt10: "farkes",
            sy4: "ܦܪܟܣ",
            sy9: "ܡܥܰܣ",
            sy8: "ܡܥܣ",
            lt11: "tbar",
            dt3: "",
            dt2: "",
            sy6: "ܬܒܪ"
        },
        {
            sy7: "ܐܰܝܟܰܢ  ܐܺܝܬ݂ܰܝ",
            sy4: "ܐܝܟܢ ܐܝܬܝ",
            id: "5089",
            dt2: "wie geht es mir",
            sy8: "ܐܝܟܢܐ",
            lt11: "aykan ithay",
            lt10: "aykan ithay",
            lt12: "aykano",
            sy9: "ܐܰܝܟܰܢܳܐ",
            dt1: "wie geht’s mir",
            sy6: "ܐܝܟܢ  ܐܝܬܝ",
            sy5: "ܐܰܝܟܰܢ ܐܺܝܬ݂ܰܝ",
            dt3: ""
        },
        {
            sy4: "ܫܪܪ",
            dt1: "affimieren",
            dt2: "",
            sy6: "",
            sy7: "",
            id: "5792",
            sy5: "ܫܰܪܰܪ",
            lt10: "sharar",
            dt3: "",
            sy8: "",
            sy9: "",
            lt12: "",
            lt11: ""
        },
        {
            dt2: "",
            sy4: "ܡܬܦܠܚܢܐ",
            dt3: "",
            lt12: "",
            sy7: "",
            sy6: "",
            sy9: "",
            id: "6017",
            dt1: "arbeitsfähig",
            lt11: "",
            lt10: "methfalĥono",
            sy8: "",
            sy5: "ܡܶܬ݂ܦܰܠܚܳܢܳܐ"
        },
        {
            sy8: "",
            lt10: "ayko yiteb sargon",
            sy9: "",
            lt11: "",
            id: "5144",
            sy7: "",
            sy4: "ܐܝܟܐ ܝܬܒ ܣܪܓܘܢ",
            dt1: "Wo saß Sargon",
            sy5: "ܐܰܝܟܳܐ ܝܺܬܶܒ ܣܰܪܓܳܘܢ",
            dt2: "",
            lt12: "",
            sy6: "",
            dt3: ""
        },
        {
            dt2: "",
            sy4: "ܪܓܘܡܐ",
            lt12: "",
            sy6: "",
            sy7: "",
            id: "4254",
            lt11: "",
            lt10: "roğumo",
            sy9: "",
            sy8: "",
            dt3: "",
            dt1: "Steiniger",
            sy5: "ܪܳܓ݂ܽܘܡܳܐ"
        },
        {
            sy5: "ܩܽܘܛܢܽܘܬ݂ܳܐ ܒܽܘܠܺܝܛܳܝܬܳܐ ܐܺܝܣܪܳܐܝܶܠ",
            sy6: "",
            lt10: "quțnutho bulițoyto disroel",
            dt1: "Parlamentarische Republik Israel ",
            sy7: "",
            dt3: "",
            lt12: "",
            sy4: "ܩܘܛܢܘܬܐ ܒܘܠܝܛܝܬܐ ܕܐܝܝܣܪܐܠ",
            lt11: "",
            sy9: "",
            sy8: "",
            dt2: "",
            id: "3420"
        },
        {
            sy9: "",
            sy6: "ܡܨܥ",
            sy4: "ܡܨܥ",
            lt12: "",
            dt1: "intervenieren",
            sy5: "ܡܨܰܥ",
            dt2: "",
            lt11: "maŝaĉ",
            lt10: "mŝaĉ",
            sy7: "ܡܰܨܰܥ",
            sy8: "",
            dt3: "",
            id: "6173"
        },
        {
            lt12: "",
            lt10: "țĥen",
            dt3: "malmen",
            sy6: "ܛܚܢ",
            dt1: "zermalmen",
            sy5: "ܛܚܶܢ",
            sy4: "ܛܚܢ",
            id: "5311",
            lt11: "țaĥen",
            sy8: "",
            dt2: "zerreiben",
            sy9: "",
            sy7: "ܛܰܚܶܢ"
        },
        {
            sy5: "ܚܰܡܽܘܛܰܠ",
            lt11: "ܚܡܘܛܠ",
            lt12: "",
            sy7: "",
            dt2: "",
            lt10: "ܚܡܘܛܠ",
            dt3: "",
            sy6: "",
            id: "1840",
            sy4: "ܚܡܘܛܠ",
            sy9: "",
            dt1: "Hamutal",
            sy8: ""
        },
        {
            sy5: "ܗܳܟ݂ܰܢܳܐ",
            lt12: "",
            dt1: "derartig",
            sy4: "ܗܟܢܐ",
            sy6: "",
            dt3: "so ein",
            lt11: "",
            sy8: "",
            dt2: "solch",
            lt10: "hokhano",
            sy7: "",
            sy9: "",
            id: "867"
        },
        {
            lt12: "",
            sy7: "ܠܺܝܡܢܳܐ",
            sy4: "ܠܡܐܢܐ",
            sy6: "ܠܝܡܢܐ",
            sy9: "",
            lt11: "limno",
            sy5: "ܠܡܺܐܢܳܐ",
            sy8: "",
            dt3: "",
            dt2: "",
            lt10: "lmino",
            dt1: "Hafen",
            id: "1814"
        },
        {
            dt2: "",
            dt3: "",
            sy9: "",
            lt11: "",
            dt1: "Liebesgedicht",
            id: "2816",
            lt12: "",
            sy7: "",
            sy6: "",
            lt10: "mimro d`ĥubo",
            sy4: "ܡܐܡܪܐ ܕܚܘܒܐ",
            sy8: "",
            sy5: "ܡܺܐܡܪܳܐ ܕܚܽܘܒܳܐ"
        },
        {
            lt10: "bezo",
            sy4: "ܒܙܐ",
            sy9: "",
            lt12: "",
            dt1: "Brustwarze",
            lt11: "",
            dt2: "",
            dt3: "",
            id: "723",
            sy7: "",
            sy8: "",
            sy5: "ܒܶܙܳܐ",
            sy6: ""
        },
        {
            dt2: "",
            sy6: "",
            sy8: "",
            dt1: "Bathlebo",
            sy7: "",
            sy9: "",
            lt11: "ܒܪܬܠܒܐ",
            dt3: "",
            sy5: "ܒܰܪ̱ܬܠܶܒܰܐ",
            sy4: "ܒܪ̱ܬܠܒܐ",
            lt10: "ܒܪܬܠܒܐ",
            id: "500",
            lt12: ""
        },
        {
            id: "4572",
            sy7: "",
            lt11: "",
            sy5: "ܝܰܒܺܝܫܳܐ",
            dt2: "dürre",
            sy9: "",
            sy4: "ܝܒܝܫܐ",
            dt1: "trocken",
            lt12: "",
            lt10: "yabisho",
            dt3: "",
            sy6: "",
            sy8: ""
        },
        {
            lt10: "ܢܡܪܬ",
            dt2: "",
            sy4: "ܢܡܪܬ",
            lt11: "ܢܡܪܬ",
            sy7: "",
            dt1: "Nemrat",
            dt3: "",
            sy8: "",
            sy9: "",
            sy5: "ܢܶܡܪܰܬ",
            sy6: "",
            id: "3241",
            lt12: ""
        },
        {
            id: "277",
            dt2: "",
            dt1: "Arbeiter",
            sy6: "ܥܡܠܐ",
            sy5: "ܦܰܠܳܚܳܐ",
            sy9: "ܐܰܓܺܝܪܳܐ",
            lt10: "faloĥo",
            dt3: "",
            sy7: "ܥܳܡܠܳܐ",
            sy8: "ܐܓܝܪܐ",
            lt11: "ĉomlo",
            lt12: "agiro",
            sy4: "ܦܠܚܐ"
        },
        {
            id: "4815",
            lt10: "satar",
            dt3: "",
            dt2: "beschützen",
            sy7: "",
            sy4: "ܣܬܪ",
            lt11: "",
            sy5: "ܣܰܬܰܪ",
            sy6: "",
            sy8: "",
            lt12: "",
            dt1: "verteidigen",
            sy9: ""
        },
        {
            lt10: "kafruno",
            sy5: "ܟܰܦܪܽܘܢܳܐ",
            dt2: "",
            sy9: "",
            sy7: "",
            dt3: "",
            sy4: "ܟܦܪܘܢܐ",
            id: "946",
            sy6: "",
            sy8: "",
            lt11: "",
            dt1: "Dörfchen",
            lt12: ""
        },
        {
            id: "5960",
            dt1: "ankleiden",
            dt2: "kleiden",
            lt12: "ĉțaf",
            sy4: "ܐܠܒܫ",
            sy9: "ܥܛܰܦ",
            sy8: "ܥܛܦ",
            dt3: "bekleiden",
            sy7: "ܣܰܛܶܠ",
            sy5: "ܐܰܠܒܶܫ",
            lt10: "albesh",
            sy6: "ܣܛܠ",
            lt11: "sațel"
        },
        {
            dt3: "",
            lt11: "ܩܝܡܝ",
            sy7: "",
            lt12: "",
            sy8: "",
            sy9: "",
            dt2: "",
            id: "3559",
            dt1: "Qaimi",
            lt10: "ܩܝܡܝ",
            sy5: "ܩܰܝܡܺܝ",
            sy4: "ܩܝܡܝ",
            sy6: ""
        },
        {
            id: "5556",
            dt2: "",
            dt3: "",
            sy9: "",
            sy4: "ܡܓܠܬܐ ܪܡܫܝܬܐ",
            lt10: "mğaltho ramshoyto",
            sy6: "",
            sy5: "ܡܓ݂ܰܠܬ݂ܳܐ ܪܰܡܫܳܝܬܳܐ",
            sy8: "",
            sy7: "",
            lt11: "",
            lt12: "",
            dt1: "Abendblatt"
        },
        {
            dt3: "",
            sy7: "",
            id: "6092",
            dt1: "Fragestellung",
            sy5: "ܡܫܰܐܠܳܢܽܘܬ݂ܳܐ",
            lt11: "",
            lt12: "",
            sy4: "ܡܫܐܠܢܘܬܐ",
            dt2: "",
            sy8: "",
            sy9: "",
            sy6: "",
            lt10: "mshalonutho"
        },
        {
            lt10: "ĉlaymutho",
            sy7: "ܥܠܰܝܡܳܐ",
            dt1: "Jugend",
            sy5: "ܥܠܰܝܡܽܘܬ݂ܳܐ",
            sy4: "ܥܠܝܡܘܬܐ",
            id: "2349",
            lt12: "",
            lt11: "ĉlaymo",
            dt2: "",
            sy6: "ܥܠܝܡܐ",
            dt3: "",
            sy8: "",
            sy9: ""
        },
        {
            sy9: "",
            lt10: "tyobo",
            dt3: "",
            lt12: "",
            id: "5101",
            lt11: "",
            sy4: "ܬܝܒܐ",
            dt2: "",
            sy7: "",
            dt1: "Wiederkehr",
            sy5: "ܬܝܳܒܳܐ",
            sy8: "",
            sy6: ""
        },
        {
            sy6: "",
            sy4: "ܐܝܪܦܢ",
            sy8: "",
            sy7: "",
            lt11: "ܐܝܪܦܢ",
            sy9: "",
            dt3: "",
            lt12: "",
            sy5: "ܐܺܝܪܦܰܢ",
            lt10: "ܐܝܪܦܢ",
            dt2: "",
            dt1: "Irfan",
            id: "2238"
        },
        {
            sy7: "",
            lt10: "qrobo nethronoyo",
            sy6: "",
            lt11: "",
            lt12: "",
            sy4: "ܩܪܒܐ ܢܬܪܢܝܐ",
            sy9: "",
            sy8: "",
            sy5: "ܩܪܳܒܳܐ ܢܶܬ݂ܪܳܢܳܝܳܐ",
            dt1: "Atomkrieg",
            dt2: "",
            id: "336",
            dt3: ""
        },
        {
            sy5: "ܚܽܘܠܕܳܐ",
            sy8: "",
            lt12: "",
            sy6: "",
            dt3: "",
            dt2: "",
            lt10: "ĥuldo",
            sy4: "ܚܘܠܕܐ",
            sy9: "",
            lt11: "",
            id: "2974",
            dt1: "Maulwurf",
            sy7: ""
        },
        {
            lt12: "",
            sy4: "ܢܩܕܐ",
            lt10: "noqdo",
            sy6: "",
            lt11: "",
            sy9: "",
            sy7: "",
            dt3: "",
            dt1: "Schäfer",
            id: "3868",
            sy5: "ܢܳܩܕܳܐ",
            sy8: "",
            dt2: ""
        },
        {
            sy8: "",
            sy4: "ܡܢܝܢܐ",
            dt2: "",
            dt3: "",
            lt10: "menyono",
            lt11: "",
            sy9: "",
            sy5: "ܡܶܢܝܳܢܳܐ",
            dt1: "Zahl",
            id: "5223",
            lt12: "",
            sy6: "",
            sy7: ""
        },
        {
            dt3: "",
            dt2: "",
            id: "768",
            dt1: "Christentum",
            sy9: "",
            lt12: "",
            sy8: "",
            sy4: "ܡܫܝܚܝܘܬܐ",
            lt11: "khristyonutho",
            sy5: "ܡܫܺܝܚܳܝܽܘܬ݂ܶܐ",
            sy7: "ܟܪܺܝܣܛܝܳܢܽܘܬ݂ܳܐ",
            lt10: "mshiĥoyutho",
            sy6: "ܟܪܝܣܛܝܢܘܬܐ"
        },
        {
            id: "2068",
            lt10: "qlofo",
            dt1: "Hülse",
            sy5: "ܩܠܳܦܳܐ",
            sy6: "ܩܠܦܬܐ",
            sy8: "",
            dt3: "",
            lt11: "qlofto",
            sy9: "",
            dt2: "Schale",
            lt12: "",
            sy7: "ܩܠܳܦܬܳܐ",
            sy4: "ܩܠܦܐ"
        },
        {
            sy6: "ܦܪܚܐ",
            dt1: "Gurke",
            dt3: "",
            sy7: "ܦܰܪܚܳܐ",
            sy4: "ܒܘܨܝܢܐ",
            id: "1765",
            lt11: "farĥo",
            lt10: "boŝino",
            sy5: "ܒܳܘܨܺܝܢܳܐ",
            sy9: "ܟ݂ܝܳܪܳܐ",
            lt12: "khyoro",
            dt2: "",
            sy8: "ܟܝܪܐ"
        },
        {
            lt12: "",
            lt11: " ĉatiq sagi",
            sy4: "ܥܬܝܩܐ",
            sy8: "",
            sy7: "ܥܰܬܺܝܩ ܣܰܓܺܝ",
            sy9: "",
            dt2: "aus alter Zeit stammend",
            sy6: "ܥܬܝܩ ܣܓܝ",
            id: "5999",
            sy5: "ܥܰܬܺܝܩܳܐ",
            dt1: "antik",
            dt3: "alt",
            lt10: "ĉatiqo"
        },
        {
            lt11: "ܓܐܢ ܐܝܫܠܪ",
            dt1: "Can Isler",
            dt3: "",
            sy7: "",
            lt10: "ܓܐܢ ܐܝܫܠܪ",
            sy8: "",
            id: "758",
            dt2: "",
            sy5: "ܓܰܐܢ ܐܺܝܫܠܶܪ",
            lt12: "",
            sy6: "",
            sy4: "ܓܐܢ ܐܝܫܠܪ",
            sy9: ""
        },
        {
            sy5: "ܕܠܳܐ ܒܺܝܕܰܥܬ݂ܳܐ",
            sy8: "",
            id: "4681",
            lt12: "",
            sy4: "ܕܠܐ ܒܝܕܥܬܐ",
            sy9: "",
            dt2: "unbewusst",
            dt1: "unwissend",
            sy6: "",
            sy7: "",
            lt11: "",
            dt3: "",
            lt10: "d`lo biđaĉtho"
        },
        {
            dt2: "",
            sy8: "",
            sy4: "ܡܢܝܐ ܕܥܡܐ",
            dt1: "Volkszählung",
            sy5: "ܡܢܳܝܳܐ ܕܥܰܡܘܐ",
            sy9: "",
            lt10: "mnoyo d`ĉamo",
            lt12: "",
            id: "4864",
            sy7: "",
            lt11: "",
            sy6: "",
            dt3: ""
        },
        {
            sy4: "ܩܨܡ",
            dt1: "wahrsagen",
            lt11: "",
            sy8: "",
            dt2: "",
            dt3: "",
            lt12: "",
            sy6: "",
            lt10: "qŝam",
            sy5: "ܩܨܰܡ",
            sy7: "",
            sy9: "",
            id: "4960"
        },
        {
            id: "6006",
            dt2: "",
            sy4: "ܡܫܘܕܥܢܐ",
            sy7: "",
            lt10: "mshaudĉono",
            dt1: "Anzeiger",
            lt12: "",
            lt11: "",
            sy8: "",
            sy6: "",
            sy9: "",
            sy5: "ܡܫܰܘܕܥܳܢܳܐ",
            dt3: ""
        },
        {
            sy8: "",
            lt10: "ܚܒܘܪܐ",
            sy9: "",
            sy4: "ܚܒܘܪܐ",
            lt12: "",
            dt3: "",
            sy7: "",
            lt11: "ܚܒܘܪܐ",
            sy5: "ܚܰܒܽܘܪܳܐ",
            sy6: "",
            id: "1801",
            dt1: "Haburo",
            dt2: ""
        },
        {
            sy4: "ܬܫܝܥܝܐ",
            id: "3256",
            sy8: "",
            lt12: "",
            sy7: "",
            lt11: "",
            sy6: "",
            dt3: "",
            sy5: "ܬܫܺܝܥܳܝܳܐ",
            dt1: "neunter",
            sy9: "",
            dt2: "",
            lt10: "tshiĉoyo"
        },
        {
            dt3: "",
            sy5: "ܪܳܚܳܒ",
            sy6: "ܪܚܘܒ",
            lt11: "ܪܚܒ",
            dt2: "",
            lt12: "ܪܚܘܒ",
            sy8: "",
            sy7: "ܪܳܚܽܘܒ",
            dt1: "Rehob",
            sy4: "ܪܚܒ",
            sy9: "",
            id: "3666",
            lt10: "ܪܚܒ"
        },
        {
            sy8: "",
            dt1: "Ärztin",
            sy9: "",
            sy4: "ܐܣܝܬܐ",
            sy5: "ܐܳܣܺܝܬ݂ܳܐ",
            dt2: "Doktorin",
            lt10: "ositho",
            sy6: "",
            sy7: "",
            dt3: "",
            lt11: "",
            id: "307",
            lt12: ""
        },
        {
            lt10: "țaybutho",
            sy4: "ܛܝܒܘܬܐ",
            sy9: "",
            sy7: "",
            id: "4091",
            lt11: "",
            lt12: "",
            dt1: "Segen",
            dt2: "Gunst",
            dt3: "",
            sy6: "",
            sy5: "ܛܰܝܒܽܘܬ݂ܳܐ",
            sy8: ""
        },
        {
            sy8: "",
            sy5: "ܕܽܠܰܒܰܐܢܺܝ",
            sy4: "ܕܘܠܒܐܢܝ",
            sy7: "",
            sy9: "",
            lt12: "",
            lt10: "ܕܘܠܒܐܢܝ",
            sy6: "",
            id: "975",
            dt3: "",
            dt2: "Dolabani",
            lt11: "ܕܘܠܒܐܢܝ",
            dt1: "Dulabani"
        },
        {
            dt3: "",
            sy4: "ܓܒܝܐ",
            sy9: "",
            dt1: "Auserwählte",
            sy8: "",
            lt12: "",
            lt10: "gabyo",
            sy5: "ܓܰܒܝܳܐ",
            sy7: "",
            sy6: "",
            id: "374",
            lt11: "",
            dt2: "Erwählte"
        },
        {
            sy8: "",
            dt1: "wohlerzogen",
            dt3: "",
            sy7: "ܟܢܺܝܟ݂ܳܐ",
            sy9: "",
            lt11: "knikho",
            dt2: "",
            sy6: "ܟܢܝܟܐ",
            lt10: "rađyo",
            lt12: "",
            sy4: "ܪܕܝܐ",
            sy5: "ܪܰܕܝܳܐ",
            id: "5155"
        },
        {
            sy4: "ܒܥܐ",
            dt2: "",
            id: "6240",
            sy8: "",
            dt3: "",
            sy6: "ܐܬܒܩܝ",
            sy7: "ܐܶܬ݂ܒܰܩܺܝ",
            sy5: "ܒܥܳܐ",
            lt12: "",
            sy9: "",
            lt10: "bĉo",
            lt11: "ethbaqi",
            dt1: "suchen"
        },
        {
            sy9: "",
            dt3: "",
            lt10: "aloho rĥimo ĥalem li",
            sy4: "ܐܠܗܐ ܪܚܝܡܐ ܚܠܡ ܠܝ",
            sy7: "",
            sy8: "",
            lt12: "",
            lt11: "",
            sy6: "",
            id: "5605",
            dt2: "",
            dt1: "Lieber Gott bitte mach mich gesund",
            sy5: "ܐܰܠܳܗܳܐ ܪܚܺܝܡܳܐ ܚܰܠܶܡ ܠܺܝ"
        },
        {
            dt1: "Kalonita",
            sy4: "ܟܠܢܝܬܐ",
            dt2: "",
            lt12: "ܟܠܘܢܝܬܐ",
            sy9: "",
            lt11: "ܟܠܢܝܬܐ",
            sy8: "",
            lt10: "ܟܠܢܝܬܐ",
            sy6: "ܟܠܘܢܝܬܐ",
            sy5: "ܟܰܠܳܢܺܝܬܰܐ",
            id: "2393",
            dt3: "",
            sy7: "ܟܰܠܽܘܢܺܝܬܰܐ"
        },
        {
            sy8: "",
            sy9: "",
            sy5: "ܟܰܦܺܝܢܬܳܐ",
            lt11: "",
            dt2: "",
            dt3: "",
            sy7: "",
            lt12: "",
            id: "2074",
            lt10: "kafinto",
            sy4: "ܟܦܝܢܬܐ",
            sy6: "",
            dt1: "Hungernde"
        },
        {
            sy8: "",
            sy7: "",
            lt12: "",
            lt10: "shabro",
            id: "2518",
            dt3: "",
            lt11: "",
            sy9: "",
            sy6: "",
            dt1: "Kleinkind",
            sy4: "ܫܒܪܐ",
            dt2: "",
            sy5: "ܫܰܒܪܳܐ"
        },
        {
            sy4: "ܦܪܨܘܦܐ",
            sy8: "",
            dt3: "",
            sy5: "ܦܰܪܨܽܘܦܳܐ",
            lt12: "",
            sy9: "",
            lt11: "",
            sy7: "",
            sy6: "",
            id: "3438",
            dt1: "Person",
            dt2: "",
            lt10: "farŝufo"
        },
        {
            sy7: "",
            dt3: "",
            lt10: "shemshonoyo",
            sy5: "ܫܶܡܫܳܢܳܝܳܐ",
            lt11: "",
            sy4: "ܫܡܫܢܝܐ",
            sy8: "",
            sy9: "",
            dt1: "sonnig",
            id: "4197",
            dt2: "solar",
            sy6: "",
            lt12: ""
        },
        {
            lt10: "bashnaye",
            sy8: "",
            id: "2274",
            sy6: "",
            lt12: "",
            sy4: "ܒܫܢܝܐ",
            sy7: "",
            dt3: "",
            dt2: "",
            sy9: "",
            sy5: "ܒܰܫܢܰܝ̈ܳܐ",
            lt11: "",
            dt1: "jahrelang"
        },
        {
            dt1: "Basim",
            lt10: "ܒܣܝܡ",
            lt11: "ܒܣܝܡ",
            sy8: "",
            sy6: "ܒܣܣܝܡ",
            sy9: "",
            sy7: "ܒܰܣܣܺܝܡ",
            lt12: "ܒܣܣܝܡ",
            dt3: "",
            dt2: "Bassim",
            sy5: "ܒܰܣܺܝܡ",
            id: "489",
            sy4: "ܒܣܝܡ"
        },
        {
            lt11: "",
            sy4: "ܐܝܚܝܕܝܐ",
            lt10: "iĥiđoyo",
            dt1: "Einziggeborener",
            dt3: "",
            sy8: "",
            id: "1059",
            sy7: "",
            dt2: "Eingeborener",
            sy5: "ܐܺܝܚܺܝܕܳܝܳܐ",
            sy9: "",
            sy6: "",
            lt12: ""
        },
        {
            lt11: "",
            sy6: "",
            dt2: "",
            sy9: "",
            lt10: "ĥushbono",
            lt12: "",
            id: "2593",
            dt1: "Konto",
            sy8: "",
            dt3: "",
            sy7: "",
            sy4: "ܚܘܫܒܢܐ",
            sy5: "ܚܽܘܫܒܳܢܳܐ"
        },
        {
            sy5: "ܦܽܘܚܳܐ",
            dt1: "Kühle",
            lt12: "",
            sy4: "ܦܘܚܐ",
            dt3: "",
            lt11: "",
            sy9: "",
            sy6: "",
            id: "2659",
            dt2: "Frische Brise",
            sy8: "",
            sy7: "",
            lt10: "fuĥo"
        },
        {
            id: "1851",
            lt12: "",
            lt10: "tagorutho",
            sy7: "ܕܽܘܟܰܢܳܐ",
            dt1: "Handel",
            dt3: "",
            sy6: "ܕܘܟܢܐ",
            lt11: "dukano",
            sy9: "",
            sy5: "ܬܰܓܳܪܽܘܬ݂ܳܐ",
            dt2: "Geschäft",
            sy8: "",
            sy4: "ܬܓܪܘܬܐ"
        },
        {
            lt11: "",
            dt1: "Schöpfer",
            sy7: "",
            dt3: "",
            sy8: "",
            lt10: "boruyo",
            dt2: "",
            sy9: "",
            sy4: "ܒܪܘܝܐ",
            id: "4018",
            lt12: "",
            sy5: "ܒܳܪܽܘܝܳܐ",
            sy6: ""
        },
        {
            dt2: "Leopard",
            sy5: "ܢܶܡܪܳܐ",
            lt10: "nemro",
            dt3: "",
            sy9: "",
            sy7: "",
            lt12: "",
            lt11: "",
            sy8: "",
            sy6: "",
            sy4: "ܢܡܪܐ",
            id: "4485",
            dt1: "Tiger"
        },
        {
            sy5: "ܝܰܩܺܝܪܬܰܐ",
            sy4: "ܝܩܝܪܬܐ",
            dt2: "",
            sy8: "",
            sy6: "",
            id: "5201",
            dt3: "",
            sy7: "",
            dt1: "Yaqirta",
            lt12: "",
            lt10: "ܝܩܝܪܬܐ",
            lt11: "ܝܩܝܪܬܐ",
            sy9: ""
        },
        {
            dt2: "",
            dt3: "",
            sy7: "",
            lt10: "țur gliđo",
            sy6: "",
            dt1: "Eisberg",
            sy8: "",
            lt12: "",
            sy4: "ܛܘܪ ܓܠܝܕܐ",
            sy5: "ܛܽܘܪ ܓܠܺܝܕܳܐ",
            id: "1061",
            sy9: "",
            lt11: ""
        },
        {
            sy7: "",
            dt2: "",
            sy4: "ܩܠܦܬܐ ܕܒܝܥܬܐ",
            sy8: "",
            dt1: "Eierschale",
            id: "1030",
            lt10: "qlofto d`biĉto",
            sy5: "ܩܠܳܦܬܳܐ ܕܒܺܝܥܬܳܐ",
            sy9: "",
            lt11: "",
            lt12: "",
            sy6: "",
            dt3: ""
        },
        {
            lt11: "",
            lt10: "teshrin ĥaroyo",
            sy9: "",
            sy5: "ܬܶܫܪܺܢ ܐ̱ܚܰܪܳܝܳܐ",
            dt1: "November",
            dt2: "",
            dt3: "",
            sy7: "",
            sy4: "ܬܫܪܢ ܐܚܪܝ",
            sy6: "",
            lt12: "",
            sy8: "",
            id: "3302"
        },
        {
            sy6: "",
            lt11: "",
            id: "2810",
            sy8: "",
            sy9: "",
            sy4: "ܪܚܡܐ",
            dt2: "",
            sy7: "",
            lt12: "",
            dt3: "",
            dt1: "Lieben",
            lt10: "rĥomo",
            sy5: "ܪܚܳܡܳܐ"
        },
        {
            sy7: "",
            sy6: "",
            sy8: "",
            dt1: "Nschamta",
            sy4: "ܢܫܡܬܐ",
            dt2: "",
            sy5: "ܢܫܰܡܬܰܐ",
            id: "3303",
            sy9: "",
            dt3: "",
            lt12: "",
            lt11: "ܢܫܡܬܐ",
            lt10: "ܢܫܡܬܐ"
        },
        {
            sy9: "",
            sy8: "",
            dt2: "",
            sy6: "",
            lt11: "",
            sy4: "ܓܪܫܐ",
            sy7: "",
            sy5: "ܓܪܳܫܳܐ",
            lt12: "",
            lt10: "grosho",
            dt1: "Ziehen",
            dt3: "",
            id: "5319"
        },
        {
            id: "1676",
            lt11: "ܓܡܝܪܬܐ",
            dt1: "Gmirta",
            lt10: "ܓܡܝܪܬܐ",
            sy4: "ܓܡܝܪܬܐ",
            dt3: "",
            sy7: "",
            sy9: "",
            sy5: "ܓܡܺܝܪܬܰܐ",
            sy6: "",
            dt2: "",
            lt12: "",
            sy8: ""
        },
        {
            lt10: "bațolo",
            id: "281",
            sy7: "",
            sy6: "",
            sy5: "ܒܰܛܶܠܳܐ",
            sy4: "ܒܛܠܐ",
            dt2: "",
            sy8: "",
            sy9: "",
            lt11: "",
            dt3: "",
            dt1: "Arbeitsloser",
            lt12: ""
        },
        {
            sy7: "",
            sy5: "ܛܳܒ ܫܰܦܺܝܪ",
            id: "4097",
            sy9: "",
            dt1: "sehr schön",
            sy8: "",
            sy6: "",
            dt2: "grandios",
            sy4: "ܛܒ ܫܦܝܪ",
            lt12: "",
            lt11: "",
            lt10: "țob shafir",
            dt3: "super"
        },
        {
            lt12: "",
            dt2: "",
            id: "3322",
            lt10: "ܢܘܪܡܬܐ",
            sy8: "",
            sy4: "ܢܘܪܡܬܐ",
            dt1: "Nurmata",
            dt3: "",
            sy7: "",
            sy6: "",
            sy5: "ܢܽܘܪܡܰܬܰܐ",
            lt11: "ܢܘܪܡܬܐ",
            sy9: ""
        },
        {
            sy6: "",
            id: "2751",
            lt11: "",
            lt10: "beth shlade",
            lt12: "",
            sy9: "",
            dt3: "",
            sy8: "",
            sy7: "",
            sy4: "ܒܝܬ ܫܠܕܐ",
            dt1: "Leichenhaus",
            sy5: "ܒܶܝܬ݂ ܫܠ̈ܰܕܶܐ",
            dt2: "Leichenhalle"
        },
        {
            sy7: "",
            sy5: "ܒܥܳܐ",
            lt11: "",
            dt1: "wünschen",
            id: "5177",
            dt3: "",
            dt2: "",
            lt10: "bĉo",
            sy6: "",
            sy4: "ܒܥܐ",
            sy9: "",
            sy8: "",
            lt12: ""
        },
        {
            lt11: "",
            dt3: "",
            sy4: "ܥܡܕ",
            dt1: "taufen",
            sy9: "",
            sy5: "ܥܡܰܕ",
            sy8: "",
            lt10: "ĉmađ",
            sy7: "",
            sy6: "",
            id: "4401",
            lt12: "",
            dt2: ""
        },
        {
            dt2: "diabolisch",
            sy6: "ܫܐܕܢܝܐ",
            lt12: "",
            lt10: "soțonoyo",
            dt3: "",
            id: "4447",
            sy5: "ܣܳܛܳܢܳܝܳܐ",
            sy4: "ܣܛܢܝܐ",
            sy9: "",
            sy8: "",
            sy7: "ܫܺܐܕܳܢܳܝܳܐ",
            dt1: "teuflisch",
            lt11: "shiđonoyo"
        },
        {
            sy4: "ܐܪܝܐ",
            sy9: "",
            lt12: "",
            lt10: "aryo",
            id: "2860",
            lt11: "",
            sy7: "",
            sy6: "",
            dt1: "Löwe",
            sy8: "",
            dt2: "",
            dt3: "",
            sy5: "ܐܰܪܝܳܐ"
        },
        {
            sy9: "",
            lt12: "",
            dt1: "Händler",
            sy5: "ܬܰܓܳܪܳܐ",
            dt2: "Geschäftsmann",
            id: "1855",
            sy4: "ܬܓܪܐ",
            dt3: "Kaufmann",
            sy6: "",
            sy8: "",
            lt10: "tagoro",
            sy7: "",
            lt11: ""
        },
        {
            sy5: "ܐܰܠܡܰܢܳܝܳܐ",
            lt12: "",
            sy4: "ܐܠܡܢܝܐ",
            lt11: "",
            lt10: "almanoyo",
            dt3: "",
            dt1: "Deutscher",
            dt2: "Deutsch",
            id: "871",
            sy6: "",
            sy8: "",
            sy9: "",
            sy7: ""
        },
        {
            dt3: "",
            dt1: "Obadja",
            sy4: "ܥܘܒܕܝܐ",
            sy9: "",
            sy7: "",
            sy6: "",
            sy8: "",
            id: "3329",
            dt2: "",
            lt11: "ܥܘܒܕܝܐ",
            lt12: "",
            lt10: "ܥܘܒܕܝܐ",
            sy5: "ܥܽܘܒܰܕܝܳܐ"
        },
        {
            sy5: "ܚܰܪܰܝܬܳܐ",
            sy7: "ܚܰܪܬ݂ܳܐ",
            dt1: "Ende",
            sy9: "ܚܽܘܬ݂ܳܡܳܐ",
            dt2: "Schluss",
            lt10: "ĥarayto",
            sy6: "ܚܪܬܐ",
            dt3: "Schluß",
            sy8: "ܚܘܬܡܐ",
            lt12: "ĥuthomo",
            sy4: "ܚܪܝܬܐ",
            id: "1101",
            lt11: "ĥartho"
        },
        {
            dt3: "",
            sy4: "ܐܢܟܝܕܐ",
            lt11: "ܐܢܟܝܕܐ",
            lt10: "ܐܢܟܝܕܐ",
            sy8: "",
            dt1: "Ankido",
            id: "240",
            dt2: "",
            sy5: "ܐܰܢܟܺܕܳܐ",
            sy6: "",
            lt12: "",
            sy9: "",
            sy7: ""
        },
        {
            lt12: "",
            dt3: "",
            sy6: "",
            sy4: "ܩܠܝܬܐ ܕܩܪܝܢܐ",
            dt2: "",
            id: "2774",
            sy5: "ܩܶܠܳܝܬܳܐ ܕܩܶܪܝܳܢܳܐ",
            sy7: "",
            sy9: "",
            dt1: "Lesezimmer",
            lt11: "",
            sy8: "",
            lt10: "qeloyto d`qeryono"
        },
        {
            sy7: "",
            sy9: "",
            lt12: "",
            sy4: "ܦܫܛ ܐܢܐ ܐܝܕܐ",
            dt1: "Ich strecke meinen Arm aus",
            sy5: "ܦܳܫܶܛ ܐ̱ܢܳܐ ܐܺܝܕܳܐ",
            dt2: "",
            dt3: "",
            sy8: "",
            sy6: "",
            lt10: "fosheț no iđo",
            id: "2142",
            lt11: ""
        },
        {
            lt12: "",
            dt3: "",
            sy6: "",
            sy4: "ܬܐܘܪܝܐ",
            sy7: "",
            sy8: "",
            sy9: "",
            lt11: "",
            lt10: "teworiya",
            dt1: "Theorie",
            dt2: "",
            id: "4462",
            sy5: "ܬܳܐܘܳܪܺܝܰܐ"
        },
        {
            lt10: "ܐܓܝܒ",
            sy9: "",
            id: "146",
            sy8: "",
            sy5: "ܐܰܓܺܝܒ",
            lt12: "",
            dt2: "",
            sy4: "ܐܓܝܒ",
            lt11: "ܐܓܝܒ",
            sy6: "",
            dt3: "",
            sy7: "",
            dt1: "Agib"
        },
        {
            sy4: "ܡܠܠܘܬܐ",
            sy7: "ܡܰܡܠ̱ܳܠܐ",
            id: "5983",
            sy6: "ܡܡܠܠܐ",
            dt3: "",
            sy5: "ܡܰܠܳܠܽܘܬ݂ܳܐ",
            lt12: "shelath shlomo",
            dt2: "",
            lt10: "malolutho",
            sy9: "ܫܶܐܠܰܬ݂ ܫܠܳܡܳܐ",
            dt1: "Anrede",
            sy8: "ܫܐܠܬ ܫܠܡܐ",
            lt11: "mamlo"
        },
        {
            lt10: "meltho mĥarshonitho",
            lt11: "",
            sy5: "ܡܶܠܬ݂ܳܐ ܡܚܰܪܫܳܢܺܝܬܳܐ",
            sy9: "",
            sy6: "",
            sy4: "ܡܠܬܐ ܡܚܪܫܢܝܬܐ",
            dt3: "",
            sy7: "",
            dt1: "Zauberwort",
            lt12: "",
            id: "5262",
            sy8: "",
            dt2: ""
        },
        {
            lt10: "ba`ĉtidutho",
            sy9: "",
            sy8: "",
            sy5: "ܒܰܥܬܺܝܕܽܘܬ݂ܳܐ",
            id: "2192",
            lt12: "",
            lt11: "",
            dt2: "",
            sy6: "",
            dt3: "",
            sy4: "ܒܥܬܝܕܘܬܐ",
            dt1: "in Zukunft",
            sy7: ""
        },
        {
            sy7: "ܫܽܘܠܛܳܢܽܘܬ݂ܳܐ",
            dt1: "Herrschaft",
            dt3: "Autorität",
            sy5: "ܫܽܘܠܛܳܢܳܐ",
            lt10: "shulțono",
            sy4: "ܫܘܠܛܢܐ",
            sy6: "ܫܘܠܛܢܘܬܐ",
            id: "1975",
            dt2: "Regierung",
            sy9: "",
            lt11: "shulțonutho",
            sy8: "",
            lt12: ""
        },
        {
            sy4: "ܪܘܚܐ ܩܕܝܫܐ",
            sy7: "",
            dt1: "Heiliger Geist",
            lt11: "",
            sy9: "",
            id: "1929",
            lt12: "",
            dt3: "",
            dt2: "",
            lt10: "ruĥo qadisho",
            sy6: "",
            sy5: "ܪܽܘܚܳܐ ܩܰܕܺܝܫܳܐ",
            sy8: ""
        },
        {
            lt10: "gauwno d`geldo",
            dt1: "Hautfarbe",
            dt2: "Lederfarbe",
            dt3: "",
            id: "1909",
            lt11: "",
            sy5: "ܓܰܘܢܳܐ ܕܓܶܠܕܳܐ",
            sy4: "ܓܘܢܐ ܕܓܠܕܐ",
            sy6: "",
            sy8: "",
            sy7: "",
            lt12: "",
            sy9: ""
        },
        {
            id: "1628",
            dt2: "",
            sy7: "",
            sy8: "",
            sy5: "ܐܰܡܺܝܢ",
            dt3: "",
            sy4: "ܐܡܝܢ",
            sy9: "",
            dt1: "gewiss",
            lt12: "",
            lt10: "amin",
            sy6: "",
            lt11: ""
        },
        {
            sy9: "",
            id: "5284",
            sy8: "",
            sy6: "",
            lt11: "",
            lt12: "",
            dt3: "",
            sy4: "ܡܛܘܚܬܐ",
            dt2: "",
            sy5: "ܡܳܛܽܘܚܬܳܐ",
            dt1: "Zeigefinger",
            sy7: "",
            lt10: "moțuĥto"
        },
        {
            sy5: "ܐܰܟ݂ܡܰܢ ܕ...",
            sy4: "ܐܟܡܢ ܕ",
            sy8: "",
            lt11: "",
            sy7: "",
            lt10: "akhman d...",
            lt12: "",
            dt2: "",
            id: "5856",
            dt3: "",
            sy6: "",
            sy9: "",
            dt1: "als ob..."
        },
        {
            sy9: "",
            id: "6030",
            lt10: "telulto",
            sy7: "",
            dt2: "",
            sy5: "ܬܶܠܽܘܠܬܳܐ",
            dt3: "",
            lt12: "",
            sy8: "",
            dt1: "Hügelchen",
            sy6: "",
            lt11: "",
            sy4: "ܬܠܘܠܬܐ"
        },
        {
            lt10: "ܣܪܦܝܘܢ",
            sy7: "",
            lt12: "",
            sy5: "ܣܰܪܦܺܝܳܘܢ",
            sy9: "",
            lt11: "ܣܪܦܝܘܢ",
            sy6: "",
            dt1: "Sarafiyon",
            dt2: "",
            sy8: "",
            dt3: "",
            id: "3843",
            sy4: "ܣܪܦܝܘܢ"
        },
        {
            sy8: "",
            sy5: "ܘܰܙܺܝܪܳܐ ܕܚܽܘܠܡܳܢܳܐ",
            id: "1607",
            sy9: "",
            sy6: "",
            sy7: "",
            dt1: "Gesundheitsminister",
            sy4: "ܘܙܝܪܐ ܕܚܘܠܡܢܐ",
            lt10: "waziro d`ĥulmono",
            lt11: "",
            dt3: "",
            dt2: "",
            lt12: ""
        },
        {
            sy8: "",
            dt1: "Alicia",
            sy5: "ܐܰܠܝܣܝܰܐ",
            sy6: "",
            lt11: "ܐܠܝܣܝܐ",
            id: "178",
            lt12: "",
            sy4: "ܐܠܝܣܝܐ",
            dt3: "",
            sy7: "",
            sy9: "",
            lt10: "ܐܠܝܣܝܐ",
            dt2: "Alicja"
        },
        {
            id: "5791",
            sy9: "ܡܣܺܝܡܳܢܳܐ",
            lt12: "msimono",
            lt10: "shuroyo",
            lt11: "msharrono",
            sy4: "ܫܘܪܝܐ",
            sy5: "ܫܽܘܪܳܝܳܐ",
            sy8: "ܡܣܝܡܢܐ",
            sy7: "ܡܫܰܪܪܳܢܳܐ",
            sy6: "ܡܫܪܪܢܐ",
            dt1: "affirmativ",
            dt2: "",
            dt3: ""
        },
        {
            sy4: "ܥܡܕܐ",
            dt2: "",
            id: "4400",
            dt1: "Taufe",
            lt11: "maĉmuditho",
            sy7: "ܡܰܥܡܽܘܕܺܝܬ݂ܳܐ",
            sy9: "",
            sy5: "ܥܡܳܕܳܐ",
            sy6: "ܡܥܡܘܕܝܬܐ",
            lt10: "ĉmođo",
            dt3: "",
            lt12: "",
            sy8: ""
        },
        {
            dt2: "eventuell",
            sy8: "ܒܠܟܝ",
            lt10: "kbar",
            id: "4834",
            dt1: "vielleicht",
            sy9: "ܒܰܠܟܺܝ",
            lt12: "balki",
            sy7: "ܕܰܟܒܰܪ",
            dt3: "",
            sy5: "ܟܒܰܪ",
            sy6: "ܕܟܒܪ",
            lt11: "dakbar",
            sy4: "ܟܒܪ"
        },
        {
            sy4: "ܩܝܘܡܐ ܦܛܪܝܪܟܝܐ",
            sy5: "ܩܳܝܽܘܡܳܐ ܦܰܛܶܪܝܰܪܟ݂ܳܝܳܐ",
            lt12: "",
            dt1: "Stellvertreter des Patriarchen",
            sy9: "",
            dt3: "",
            sy7: "",
            sy8: "",
            lt11: "",
            lt10: "qoyumo fațeryarkhoyo",
            dt2: "",
            id: "4259",
            sy6: ""
        },
        {
            sy5: "ܐܺ",
            dt1: "i",
            sy9: "",
            lt10: "ĥboso",
            sy7: "",
            sy6: "",
            sy8: "",
            lt12: "",
            sy4: "ܐ",
            dt3: "",
            dt2: "",
            id: "2086",
            lt11: ""
        },
        {
            sy5: "ܫܽܘܚܠܳܦܳܐ ܕܫܰܢ̱ܬܳܐ",
            sy6: "",
            lt10: "shuĥlofe d`shato",
            sy4: "ܫܘܚܠܦܐ ܕܫܢܬܐ",
            lt11: "",
            sy9: "",
            lt12: "",
            sy8: "",
            dt1: "Jahreszeiten",
            sy7: "",
            id: "2276",
            dt3: "",
            dt2: ""
        },
        {
            dt3: "",
            sy7: "ܦܰܝܢܳܐ",
            dt1: "Jacke",
            lt11: "fayno",
            sy6: "ܦܝܢܐ",
            id: "2264",
            sy4: "ܫܠܛܐ",
            sy5: "ܫܰܠܛܳܐ",
            sy9: "",
            dt2: "Mantel",
            lt12: "",
            sy8: "",
            lt10: "shalțo"
        },
        {
            dt2: "steif",
            sy6: "",
            lt11: "",
            lt10: "qashyo",
            sy8: "",
            sy5: "ܩܰܫܝܳܐ",
            dt1: "hart",
            sy7: "",
            dt3: "stur",
            id: "1875",
            sy9: "",
            lt12: "",
            sy4: "ܩܫܝܐ"
        },
        {
            dt2: "",
            dt3: "",
            dt1: "Babel",
            id: "411",
            sy4: "ܒܒܝܠ",
            sy5: "ܒܰܒܶܝܠ",
            sy7: "",
            sy6: "",
            lt10: "ܒܒܝܠ",
            sy8: "",
            lt12: "",
            sy9: "",
            lt11: "ܒܒܝܠ"
        },
        {
            sy5: "ܝܰܘܡܳܢܳܐ",
            id: "1989",
            lt10: "yaumono",
            sy9: "",
            lt11: "adyaumo",
            sy4: "ܝܘܡܢܐ",
            sy6: "ܐܕܝܘܡܐ",
            dt2: "",
            lt12: "",
            sy8: "",
            dt3: "",
            sy7: "ܐܰܕܝܰܘܡܳܐ",
            dt1: "heute"
        },
        {
            sy7: "",
            id: "4818",
            sy6: "",
            sy4: "ܬܘܟܠܢܐ",
            dt3: "",
            dt2: "",
            dt1: "Vertrauen",
            lt12: "",
            lt10: "tukhlono",
            lt11: "",
            sy9: "",
            sy8: "",
            sy5: "ܬܽܘܟ݂ܠܳܢܳܐ"
        },
        {
            sy7: "",
            sy9: "",
            lt12: "",
            sy8: "",
            dt2: "",
            lt11: "",
            dt1: "Eheleben",
            sy5: "ܚܰܝ̈ܶܐ ܕܙܽܘܘܳܓ݂ܳܐ",
            dt3: "",
            id: "1010",
            lt10: "ĥaye d`zuwoğo",
            sy4: "ܚܝܐ ܕܙܘܘܓܐ",
            sy6: ""
        },
        {
            dt1: "Glätte",
            sy4: "ܫܘܪܥܐ",
            sy9: "ܫܳܪܽܘܥܽܘܬ݂ܳܐ",
            sy5: "ܫܽܘܪܥܳܐ",
            lt12: "shoruĉutho",
            sy7: "ܫܥܳܥܳܐ",
            lt11: "shĉoĉo",
            dt2: "",
            lt10: "shurĉo",
            sy6: "ܫܥܥܐ",
            dt3: "",
            sy8: "ܫܪܘܥܘܬܐ",
            id: "1649"
        },
        {
            sy7: "",
            dt2: "",
            lt11: "",
            sy9: "",
            dt1: "Verbund",
            sy8: "",
            lt12: "",
            sy4: "ܚܘܝܕܐ",
            lt10: "ĥuyodo",
            sy5: "ܚܽܘܝܳܕܳܐ",
            dt3: "",
            id: "4722",
            sy6: ""
        },
        {
            sy4: "ܒܝܬ ܓܠܚܐ",
            dt2: "Galerie",
            sy7: "ܒܶܝܬ݂ ܓܠܳܚ̈ܶܐ ܕܨܽܘܪ̈ܬܳܐ",
            dt1: "Ausstellung",
            sy9: "",
            lt10: "beth gloĥo",
            dt3: "",
            sy8: "",
            sy6: "ܒܝܬ ܓܠܚܐ ܕܨܘܪܬܐ",
            id: "389",
            sy5: "ܒܶܝܬ݂ ܓܠܳܚܳܐ",
            lt12: "",
            lt11: "beth gloĥe d`ŝurotho"
        },
        {
            sy5: "ܥܽܘܩܒܰܪܬ݂ܳܐ",
            dt1: "Muskel",
            dt3: "",
            sy9: "",
            sy6: "ܘܪܝܕܐ",
            sy7: "ܘܰܪܺܝܕܳܐ",
            id: "3132",
            dt2: "",
            sy8: "",
            lt11: "wariđo",
            lt10: "ĉuqbartho",
            sy4: "ܥܘܩܒܪܬܐ",
            lt12: ""
        },
        {
            dt3: "zuvor",
            sy8: "ܩܘܕܡܬ",
            sy6: "ܡܢ ܩܕܡ",
            dt2: "zuerst",
            sy7: "ܡܶܢ ܩܕܳܡ",
            lt12: "quđmath",
            id: "600",
            lt10: "qđom",
            lt11: "men qđom",
            sy4: "ܩܕܡ",
            sy5: "ܩܕܳܡ",
            dt1: "bevor",
            sy9: "ܩܽܘܕܡܰܬ݂"
        },
        {
            dt3: "",
            lt10: "shuĥlof shmo",
            sy4: "ܫܘܚܠܦ ܫܡܐ",
            sy7: "",
            sy5: "ܫܽܘܚܠܳܦ ܫܡܳܐ",
            sy9: "",
            dt2: "",
            sy6: "",
            lt12: "",
            dt1: "Namensänderung",
            lt11: "",
            id: "3198",
            sy8: ""
        },
        {
            sy8: "",
            sy6: "",
            dt2: "Andenken",
            lt11: "",
            sy7: "",
            lt10: "dukhrono",
            dt3: "Erinnerung",
            lt12: "",
            dt1: "Gedenken",
            id: "1508",
            sy9: "",
            sy5: "ܕܽܘܟ݂ܪܳܢܳܐ",
            sy4: "ܕܘܟܪܢܐ"
        },
        {
            dt2: "ruhig",
            dt1: "leise",
            sy7: "ܪܟܺܝܢܳܐ",
            dt3: "",
            lt11: "rkino",
            sy6: "ܪܟܝܢܐ",
            id: "2757",
            sy8: "",
            sy9: "",
            lt12: "",
            sy5: "ܫܰܠܝܳܐ",
            lt10: "shalyo",
            sy4: "ܫܠܝܐ"
        },
        {
            lt10: "nfoqo",
            dt1: "raus",
            sy7: "ܢܦܰܩ",
            dt2: "hinaus",
            sy6: "ܢܦܩ",
            id: "3625",
            sy4: "ܢܦܩܐ",
            lt11: "nfaq",
            dt3: "raus gehen",
            sy9: "",
            sy5: "ܢܦܳܩܳܐ",
            sy8: "",
            lt12: ""
        },
        {
            sy4: "ܡܰܕܒܪܳܐ",
            sy5: "ܡܰܕܒܪܳܐ",
            sy9: "",
            lt10: "mađbro",
            dt3: "",
            sy7: "",
            dt2: "",
            id: "5427",
            sy6: "",
            dt1: "Wüste",
            lt12: "",
            lt11: "",
            sy8: ""
        },
        {
            dt1: "Absteiger",
            sy9: "",
            lt10: "noĥuto",
            dt2: "",
            lt11: "",
            sy6: "",
            sy8: "",
            sy7: "",
            sy4: "ܢܚܘܬܐ",
            sy5: "ܢܳܚܽܘܬܳܐ",
            id: "5665",
            lt12: "",
            dt3: ""
        },
        {
            lt11: "",
            dt2: "",
            dt1: "Mein Schatz",
            sy7: "",
            id: "2991",
            sy8: "",
            lt12: "",
            lt10: "rĥimto d`lebi",
            sy5: "ܪܚܺܝܡܬܳܐ ܕܠܶܒܺܝ",
            sy6: "",
            sy4: "ܪܚܝܡܬܐ ܕܠܒܝ",
            dt3: "",
            sy9: ""
        },
        {
            sy4: "ܚܢܢܐ",
            dt2: "",
            lt10: "ܚܢܢܐ",
            sy5: "ܚܰܢܳܢܳܐ",
            sy9: "",
            lt11: "ܚܢܢܐ",
            dt1: "Hanono",
            id: "1868",
            lt12: "",
            dt3: "",
            sy6: "",
            sy8: "",
            sy7: ""
        },
        {
            dt1: "Schulen",
            lt11: "",
            id: "4049",
            dt2: "",
            sy4: "ܡܕܪܫܬܐ",
            sy9: "",
            sy7: "",
            sy5: "ܡܰܕܪܰܫܳܬ݂ܳܐ",
            sy6: "",
            lt10: "madrashotho",
            sy8: "",
            dt3: "",
            lt12: ""
        },
        {
            sy6: "ܫܪܘܝܬܐ",
            sy8: "",
            lt12: "",
            lt10: "msharyonitho",
            dt3: "Amateurin",
            sy5: "ܡܫܰܪܝܳܢܺܝܬ݂ܳܐ",
            id: "5945",
            dt1: "Anfängerin",
            sy4: "ܡܫܪܝܢܝܬܐ",
            lt11: "sharwoyto",
            dt2: "Beginnerin",
            sy9: "",
            sy7: "ܫܰܪܘܳܝܬܳܐ"
        },
        {
            sy8: "",
            sy9: "",
            dt3: "",
            dt1: "Ansicht",
            dt2: "",
            sy5: "ܚܙܳܝܬܳܐ",
            sy6: "ܡܚܙܝܐ",
            id: "5974",
            lt12: "",
            lt11: "maĥzyo",
            sy7: "ܡܰܚܙܝܳܐ",
            lt10: "ĥzoyto",
            sy4: "ܚܙܝܬܐ"
        },
        {
            sy9: "",
            sy8: "",
            lt11: "ܐܝܣܪܐܝܠ",
            lt12: "",
            id: "2252",
            dt1: "Israel",
            sy4: "ܐܝܣܪܐܝܠ",
            lt10: "ܐܝܣܪܐܝܠ",
            dt3: "",
            sy5: "ܐܺܝܣܪܳܐܝܶܠ",
            sy7: "ܐܺܝܣܪܰܐܝܶܠ",
            sy6: "ܐܝܣܪܐܝܠ",
            dt2: "Isroel"
        },
        {
            lt12: "",
            dt3: "",
            sy6: "ܟܬܒܐ ܕܡܠܐ",
            lt10: "sfar mele",
            sy7: "ܟܬ݂ܳܒܳܐ ܕܡ̈ܶܠܶܐ",
            sy5: "ܣܦܰܪ ܡ̈ܶܠܶܐ",
            sy8: "",
            dt1: "Wörterbuch",
            id: "5167",
            lt11: "kthobo d`mele",
            sy4: "ܣܦܪ ܡܠܐ",
            sy9: "",
            dt2: ""
        },
        {
            sy5: "ܓܰܕܳܐ ܡܬܰܚܶܡ ܠܚܰܝ̈ܶܐ ܕܺܝܠܳܟ݂",
            dt3: "",
            lt11: "",
            sy7: "",
            sy6: "",
            sy8: "",
            lt12: "",
            dt2: "",
            id: "5438",
            dt1: "Das Schicksal bestimmt Dein Leben",
            sy4: "ܓܰܕܳܐ ܡܬܰܚܶܡ ܠܚܰܝ̈ܶܐ ܕܺܝܠܳܟ݂",
            lt10: "gado mtaĥem l'ĥaye dilokh",
            sy9: ""
        },
        {
            sy7: "ܐܶܬ݂ܝܰܥܰܢ",
            lt11: "ethyaĉan",
            lt12: "",
            sy8: "",
            sy5: "ܠܥܰܒ",
            lt10: "lĉab",
            dt3: "",
            dt1: "habgierig sein",
            id: "1792",
            sy9: "",
            dt2: "gierig sein",
            sy4: "ܠܥܒ",
            sy6: "ܐܬܝܥܢ"
        },
        {
            sy4: "ܪܘܚܢܝܐ",
            lt12: "",
            sy5: "ܪܽܘܚܳܢܳܝܳܐ",
            dt1: "psychisch",
            sy6: "ܢܦܫܢܝܐ",
            sy9: "",
            id: "3539",
            lt11: "nafshonoyo",
            dt3: "",
            sy8: "",
            sy7: "ܢܰܦܫܳܢܳܝܳܐ",
            dt2: "",
            lt10: "ruĥonoyo"
        },
        {
            sy5: "ܐܰܓ݂ܽܘܢܳܐ ܐܳܐܰܪܳܝܳܐ",
            dt2: "Luftstreit",
            sy9: "",
            lt10: "ağuno oyaroyo",
            id: "2867",
            sy8: "",
            lt12: "",
            sy4: "ܐܓܘܢܐ ܐܐܪܝܐ",
            dt1: "Luftkampf",
            lt11: "",
            sy6: "",
            sy7: "",
            dt3: ""
        },
        {
            dt2: "",
            sy6: "ܫܡܥܝܠ",
            sy8: "",
            sy5: "ܐܺܝܫܡܳܥܺܝܠ",
            id: "2243",
            sy9: "",
            dt1: "Ischmoel",
            dt3: "",
            lt10: "ܐܝܫܡܥܝܠ",
            lt11: "ܐܝܫܡܥܝܠ",
            sy7: "ܫܡܳܥܺܝܠ",
            sy4: "ܐܝܫܡܥܝܠ",
            lt12: "ܫܡܥܝܠ"
        },
        {
            dt1: "zweistöckig",
            dt2: "",
            sy7: "",
            sy8: "",
            dt3: "",
            sy4: "ܬܪܝܢܝ ܡܕܝܪܐ",
            sy5: "ܬܪܰܝܳܢܰܝ ܡܶܕܝ̈ܳܪܶܐ",
            lt10: "trayonay međyore",
            lt11: "",
            lt12: "",
            sy9: "",
            id: "5396",
            sy6: ""
        },
        {
            sy9: "",
            sy8: "",
            dt1: "Marduk",
            sy7: "",
            dt2: "",
            sy5: "ܡܪܕܘܟ",
            lt12: "",
            dt3: "",
            sy4: "ܡܪܕܘܟ",
            sy6: "",
            id: "2928",
            lt10: "ܡܪܕܘܟ",
            lt11: "ܡܪܕܘܟ"
        },
        {
            dt3: "",
            sy5: "ܓܪܺܝܓܳܘܪ",
            sy7: "",
            sy9: "",
            dt1: "Gregor",
            sy8: "",
            id: "1727",
            dt2: "Grigor",
            lt10: "ܓܪܝܓܘܪ",
            sy6: "",
            sy4: "ܓܪܝܓܘܪ",
            lt11: "ܓܪܝܓܘܪ",
            lt12: ""
        },
        {
            sy8: "",
            id: "3065",
            dt3: "",
            dt1: "Mittelohr",
            sy4: "ܐܕܢܐ ܡܨܥܝܬܐ",
            lt10: "eđno meŝĉoyto",
            sy9: "",
            sy6: "",
            lt11: "",
            sy5: "ܐܶܕܢܳܐ ܡܶܨܥܳܝܬܳܐ",
            dt2: "",
            lt12: "",
            sy7: ""
        },
        {
            sy7: "",
            lt11: "",
            sy6: "",
            sy4: "ܢܩܘܠܐ",
            dt2: "",
            sy5: "ܢܳܩܽܘܠܳܐ",
            lt10: "noqulo",
            sy9: "",
            dt3: "",
            id: "6095",
            dt1: "Frachtführer",
            sy8: "",
            lt12: ""
        },
        {
            id: "3906",
            sy5: "ܫܰܪܺܝܪ",
            lt12: "",
            sy8: "",
            dt2: "",
            dt3: "",
            sy6: "",
            sy9: "",
            dt1: "Scharir",
            lt10: "ܫܪܝܪ",
            sy4: "ܫܪܝܪ",
            lt11: "ܫܪܝܪ",
            sy7: ""
        },
        {
            dt2: "",
            sy7: "",
            sy6: "",
            sy9: "",
            sy4: "ܒܪܚܘܒܐ",
            id: "452",
            dt1: "Barhubo",
            lt10: "ܒܪܚܘܒܐ",
            lt12: "",
            sy8: "",
            lt11: "ܒܪܚܘܒܐ",
            dt3: "",
            sy5: "ܒܰܪܚܽܘܒܳܐ"
        },
        {
            sy7: "",
            dt3: "",
            sy4: "ܛܒܥܐ",
            sy8: "",
            id: "964",
            lt11: "",
            sy9: "",
            sy5: "ܛܰܒܥܳܐ",
            lt10: "țabĉo",
            dt1: "Drucker",
            lt12: "",
            dt2: "",
            sy6: ""
        },
        {
            sy4: "ܢܩܘܫܐ",
            lt12: "",
            sy8: "",
            sy5: "ܢܳܩܽܘܫܳܐ",
            dt2: "Kirchenglocke",
            sy9: "",
            sy6: "",
            dt1: "Glocke",
            sy7: "",
            dt3: "",
            lt11: "",
            id: "1662",
            lt10: "noqusho"
        },
        {
            dt2: "",
            sy9: "",
            lt10: "ĥalbo d`qhutho",
            sy6: "",
            lt11: "",
            sy8: "",
            sy5: "ܚܰܠܒܳܐ ܕܩܗܽܘܬ݂ܳܐ",
            sy4: "ܚܠܒܐ ܕܩܗܘܬܐ",
            dt3: "",
            lt12: "",
            dt1: "Kaffeemilch",
            sy7: "",
            id: "2377"
        },
        {
            sy9: "",
            sy4: "ܝܝܬܪ",
            lt10: "ܝܝܬܪ",
            lt11: "ܝܝܬܪ",
            dt3: "",
            sy8: "",
            sy7: "",
            dt1: "Yitar",
            sy5: "ܝܺܝܬܰܪ",
            id: "5211",
            dt2: "Jitar",
            sy6: "",
            lt12: ""
        },
        {
            sy5: "ܟܶܪܟܳܐ ܕܣܶܕܪܳܐ",
            dt1: "Klassenheft",
            sy9: "",
            dt2: "Klassenbuch",
            lt10: "kerko d`sedro",
            sy4: "ܟܖܪܟܐ ܕܣܕܪܐ",
            lt12: "",
            id: "2498",
            sy7: "",
            sy8: "",
            lt11: "",
            dt3: "",
            sy6: ""
        },
        {
            dt2: "",
            dt1: "Fahrrad",
            id: "1235",
            sy9: "",
            lt10: "daroğto",
            sy7: "ܬܶܢܓܺܝܓ݂ܠܳܐ",
            sy5: "ܕܰܪܳܓ݂ܬܳܐ",
            sy6: "ܬܢܓܝܓܠܐ",
            dt3: "",
            sy4: "ܕܪܓܬܐ",
            lt11: "tengiğlo",
            sy8: "",
            lt12: ""
        },
        {
            lt11: "",
            sy8: "",
            sy9: "",
            dt3: "",
            sy7: "",
            sy4: "ܚܠܩܐ",
            sy5: "ܚܶܠܩܳܐ",
            sy6: "",
            dt2: "",
            lt10: "ĥelqo",
            id: "1709",
            dt1: "Göttliche Vorsehnung",
            lt12: ""
        },
        {
            id: "3308",
            sy4: "ܢܘܚܡܐ",
            sy7: "",
            dt2: "",
            lt10: "ܢܘܚܡܐ",
            dt3: "",
            sy5: "ܢܽܘܚܳܡܳܐ",
            lt11: "ܢܘܚܡܐ",
            sy6: "",
            sy9: "",
            sy8: "",
            lt12: "",
            dt1: "Nuhomo"
        },
        {
            sy8: "",
            sy4: "ܬܟܝܠܘܬܐ",
            dt1: "Glaubwürdigkeit",
            dt3: "",
            sy5: "ܬܟ݂ܝܺܠܽܘܬ݂ܳܐ",
            lt10: "tkhilutho",
            sy6: "ܡܬܗܝܡܢܢܘܬܐ",
            lt11: "methhaymnonutho",
            sy9: "",
            id: "1654",
            dt2: "",
            sy7: "ܡ݂ܶܬܗܰܝܡܳܢܳܢܽܘܬ݂ܳܐ",
            lt12: ""
        },
        {
            dt2: "",
            sy8: "",
            sy9: "",
            dt1: "Grieche",
            lt12: "",
            sy7: "",
            id: "1731",
            sy5: "ܝܰܘܢܳܝܳܐ",
            lt11: "",
            sy6: "",
            lt10: "yaunoyo",
            dt3: "",
            sy4: "ܝܘܢܝܐ"
        },
        {
            dt2: "",
            lt12: "",
            lt10: "dayŝo",
            sy8: "",
            lt11: "",
            sy7: "",
            dt1: "Wildziege",
            dt3: "",
            sy6: "",
            sy9: "",
            id: "5110",
            sy4: "ܕܝܨܐ",
            sy5: "ܕܰܝܨܳܐ"
        },
        {
            sy9: "",
            dt1: "wir alle heben unsere Hände hoch",
            sy7: "ܟܽܘܠܰܢ ܡܰܥܠܺܝܢܰܢ ܐܺܝ̈ܕܳܬ݂ܶܐ",
            dt2: "",
            lt12: "",
            sy8: "",
            sy4: "ܟܘܠܢ ܡܪܝܡܝܢܢ ܐܝܕܐ",
            sy5: "ܟܽܘܠܰܢ ܡܪܺܝܡܺܝܢܰܢ ܐܺܝܕܳܐ",
            dt3: "",
            lt10: "kulan mriminan iđe",
            id: "5121",
            sy6: "ܟܘܠܢ ܡܥܠܝܢܢ ܐܝܕܬܐ",
            lt11: "kulan maĉlinan iđothe"
        },
        {
            sy8: "",
            id: "2565",
            lt11: "",
            sy6: "",
            lt12: "",
            sy9: "",
            sy5: "ܬܽܘܒ ܗܦܽܘܟ݂ܺܝܢ ܩܰܪܺܝܒܳܐܺܝܬ݂",
            sy7: "",
            dt1: "Komm bald wieder zurück",
            sy4: "ܬܘܒ ܗܦܘܟܝܢ ܩܪܝܒܐܝܬ",
            lt10: "tub hfukhin qariboith",
            dt3: "",
            dt2: ""
        },
        {
            sy5: "ܗܳܟ݂ܶܝܠ",
            lt12: "",
            sy8: "",
            sy9: "",
            sy7: "",
            lt10: "hokhel",
            dt3: "",
            sy6: "",
            sy4: "ܗܟܝܠ",
            id: "868",
            dt1: "deshalb",
            lt11: "",
            dt2: "daher"
        },
        {
            sy5: "ܛܺܝܡܶܐ ܡܬܰܚܡܶܐ",
            sy7: "",
            dt3: "",
            sy9: "",
            sy4: "ܛܝܡܐ ܡܬܚܡܐ",
            lt12: "",
            lt10: "țime mtaĥme",
            sy6: "",
            id: "4238",
            dt2: "",
            sy8: "",
            dt1: "Standardpreis",
            lt11: ""
        },
        {
            sy7: "ܢܚܰܡܝܳܐ",
            lt12: "ܢܚܡܝܐ",
            id: "3234",
            sy4: "ܢܚܡܝܐ",
            lt11: "ܢܚܡܝܐ",
            dt2: "Nehemya",
            sy5: "ܢܶܚܰܡܝܰܐ",
            sy6: "ܢܚܡܝܐ",
            dt1: "Nehemia",
            lt10: "ܢܚܡܝܐ",
            sy9: "",
            sy8: "",
            dt3: ""
        },
        {
            sy5: "ܩܪܺܝܬ݂ܳܐ ܕܒܶܝܬ݂ ܕܶܒ̈ܶܐ",
            dt3: "Badebe",
            sy4: "ܩܪܝܬܐ ܕܒܝܬ ܕܒܐ",
            sy9: "",
            lt12: "",
            sy8: "",
            lt11: "beth debe",
            dt2: "Badibe",
            sy6: "ܒܝܬ ܕܒܐ",
            lt10: "qritho beth debe",
            id: "926",
            dt1: "Dorf Badibe",
            sy7: "ܒܶܝܬ݂ ܕܶܒ̈ܶܐ"
        },
        {
            dt2: "",
            id: "595",
            sy6: "",
            sy4: "ܚܕܘܪܐ",
            sy8: "",
            lt10: "ĥođuro",
            sy9: "",
            sy7: "",
            sy5: "ܚܳܕܽܘܪܳܐ",
            dt3: "",
            lt11: "",
            lt12: "",
            dt1: "Bettler"
        },
        {
            dt3: "",
            sy6: "ܡܫܝܚܐ",
            sy8: "",
            dt1: "Erretter",
            sy4: "ܦܪܘܩܐ",
            sy5: "ܦܳܪܽܘܩܳܐ",
            id: "1168",
            sy7: "ܡܫܺܝܚܳܐ",
            lt12: "",
            lt11: "mshiĥo",
            lt10: "foruqo",
            dt2: "Messias",
            sy9: ""
        },
        {
            sy7: "",
            sy9: "",
            dt3: "",
            dt1: "Käse",
            id: "2423",
            lt12: "",
            sy4: "ܓܒܬܐ",
            dt2: "",
            sy6: "",
            sy8: "",
            lt11: "",
            lt10: "gbeto",
            sy5: "ܓܒܶܬܳܐ"
        },
        {
            dt2: "",
            sy9: "",
            lt11: "mĉartho",
            lt10: "mĉaro",
            sy6: "ܡܥܪܬܐ",
            sy7: "ܡܥܰܪܬ݂ܳܐ",
            sy5: "ܡܥܰܪܳܐ",
            lt12: "",
            id: "2042",
            dt1: "Höhle",
            dt3: "",
            sy4: "ܡܥܪܐ",
            sy8: ""
        },
        {
            dt1: "Irak",
            sy7: "",
            lt12: "",
            sy4: "ܥܝܪܩ",
            id: "2236",
            sy9: "",
            lt10: "ĉiraq",
            dt2: "",
            sy8: "",
            dt3: "",
            sy5: "ܥܺܝܪܰܩ",
            lt11: "",
            sy6: ""
        },
        {
            dt3: "",
            sy9: "",
            id: "525",
            dt1: "Befreiung",
            dt2: "",
            lt11: "",
            sy8: "",
            sy6: "",
            lt10: "ĥuroro",
            sy7: "",
            lt12: "",
            sy4: "ܚܘܪܪܐ",
            sy5: "ܚܽܘܪܳܪܳܐ"
        },
        {
            sy4: "ܚܽܘܒܳܐ ܠܳܐ ܐܺܝܬ݂ܝܗ ܗܺܝ ܪܶܓ݂ܫܳܐ ܕܰܪܦܳܦܥܰܝܢܳܐ , ܒܪܰܡ ܦܽܘܣܩܳܢܳܐ ܕܒܺܝܕܰܥܬ݂ܳܐ ܠܥܽܘܡܪܳܐ ܟܽܘܠܶܗ",
            lt11: "",
            sy9: "",
            id: "5429",
            sy6: "",
            sy8: "",
            lt12: "",
            sy7: "",
            dt1: "Liebe ist nicht das Gefühl des Augenblicks sondern die bewusste Entscheidung für das ganze Leben",
            lt10: "ĥubo lo ithe darfof ĉayno bram fusqono dbiđaĉtho l`ĉumre kule",
            dt3: "",
            dt2: "",
            sy5: "ܚܽܘܒܳܐ ܠܳܐ ܐܺܝܬ݂ܝܗ ܗܺܝ ܪܶܓ݂ܫܳܐ ܕܰܪܦܳܦܥܰܝܢܳܐ , ܒܪܰܡ ܦܽܘܣܩܳܢܳܐ ܕܒܺܝܕܰܥܬ݂ܳܐ ܠܥܽܘܡܪܳܐ ܟܽܘܠܶܗ"
        },
        {
            sy9: "",
            dt2: "",
            sy7: "",
            dt1: "Holzwurm",
            sy4: "ܬܘܠܥܐ ܕܐܝܠܢܐ",
            sy5: "ܬܰܘܠܥܳܐ ܕܐܺܝܠܳܢܳܐ",
            lt10: "taulĉo d`ilono",
            dt3: "",
            lt11: "",
            lt12: "",
            sy8: "",
            id: "2047",
            sy6: ""
        },
        {
            sy4: "ܐܓܘܓܝܘܬܐ",
            lt11: "",
            lt12: "",
            lt10: "agugoyutho",
            dt1: "Kanalisation",
            dt3: "",
            sy8: "",
            id: "2404",
            dt2: "",
            sy9: "",
            sy5: "ܐܰܓܽܘܓܳܝܽܘܬ݂ܳܐ",
            sy7: "",
            sy6: ""
        },
        {
            id: "1687",
            sy4: "ܚܣ",
            lt10: "ĥos",
            sy5: "ܚܳܣ",
            dt1: "Gott bewahre",
            sy8: "",
            dt2: "",
            sy7: "",
            sy6: "",
            dt3: "",
            lt12: "",
            sy9: "",
            lt11: ""
        },
        {
            lt11: "",
            dt1: "unüblich",
            id: "4676",
            sy4: "ܠܐ ܥܝܕܝܐ",
            sy7: "",
            sy6: "",
            lt12: "",
            dt3: "",
            sy9: "",
            sy5: "ܠܳܐ ܥܝܳܕܳܝܳܐ",
            lt10: "lo ĉyodoyo",
            sy8: "",
            dt2: ""
        },
        {
            lt11: "aqeftho",
            sy9: "ܢܶܩܦܳܝܳܐ",
            sy4: "ܢܘܩܦܐ",
            sy5: "ܢܽܘܩܳܦܳܐ",
            sy7: "ܐܰܩܶܦܬ݂ܳܐ",
            id: "6232",
            dt3: "",
            dt1: "Suffix",
            sy6: "ܐܩܦܬܐ",
            dt2: "",
            lt10: "nuqofo",
            lt12: "neqfoyo",
            sy8: "ܢܩܦܝܐ"
        },
        {
            sy4: "ܢܘܪܒܐ",
            id: "5586",
            sy5: "ܢܽܘܪܒܳܐ",
            sy9: "",
            dt3: "",
            lt11: "",
            lt10: "nurbo",
            sy6: "",
            dt1: "Ableger",
            sy7: "",
            dt2: "",
            sy8: "",
            lt12: ""
        },
        {
            sy6: "",
            dt3: "",
            lt12: "",
            sy8: "",
            sy7: "",
            sy9: "",
            lt11: "ܛܠܐ",
            sy5: "ܛܰܠܳܐ",
            id: "4372",
            sy4: "ܛܠܐ",
            lt10: "ܛܠܐ",
            dt1: "Talo",
            dt2: ""
        },
        {
            dt2: "",
            sy9: "ܡܦܰܚܡܽܘܬ݂ܳܐ",
            sy5: "ܡܣܰܒܗܽܘܬ݂ܳܐ",
            dt1: "Analogie",
            lt12: "mfaĥmutho",
            sy8: "ܡܦܚܡܘܬܐ",
            sy4: "ܡܣܒܗܘܬܐ",
            sy6: "ܕܡܝܘܬܐ",
            lt10: "msabhutho",
            lt11: "domyutho",
            sy7: "ܕܳܡܝܽܘܬ݂ܳܐ",
            id: "5903",
            dt3: ""
        },
        {
            sy6: "",
            sy8: "",
            sy7: "",
            lt10: "mnotho",
            lt12: "",
            dt1: "Teil",
            dt2: "Portion",
            dt3: "",
            id: "4423",
            sy5: "ܡܢܳܬ݂ܳܐ",
            sy9: "",
            lt11: "",
            sy4: "ܡܢܬܐ"
        },
        {
            dt1: "möglich",
            dt2: "machbar",
            sy8: "",
            lt11: "mkono",
            id: "3074",
            sy6: "ܡܟܢܐ",
            sy9: "",
            dt3: "",
            lt12: "",
            lt10: "mkonoyo",
            sy7: "ܡܟܳܢܳܐ",
            sy5: "ܡܟܳܢܳܝܳܐ",
            sy4: "ܡܟܢܝܐ"
        },
        {
            sy9: "",
            lt11: "kumro",
            dt2: "Vater der Väter",
            sy8: "",
            dt3: "",
            id: "3421",
            sy4: "ܦܛܪܝܪܟܐ",
            sy6: "ܟܘܡܪܐ",
            lt10: "fațeryarkho",
            lt12: "",
            sy5: "ܦܰܛܶܪܝܰܪܟ݂ܳܐ",
            dt1: "Patriarch",
            sy7: "ܟܽܘܡܪܳܐ"
        },
        {
            lt11: "",
            dt2: "",
            sy8: "",
            sy9: "",
            sy5: "ܚܰܪܳܫܽܘܬ݂ܳܐ",
            id: "5257",
            sy4: "ܚܪܫܘܬܐ",
            sy7: "",
            dt1: "Zauberei",
            lt10: "ĥaroshutho",
            lt12: "",
            sy6: "",
            dt3: ""
        },
        {
            sy9: "",
            lt12: "",
            id: "5272",
            lt11: "",
            sy8: "",
            sy4: "ܩܝܣܐ ܕܐܪܙܐ",
            dt3: "",
            lt10: "qayso d`arzo",
            sy7: "",
            sy5: "ܩܝܣܐ ܕܐܰܪܙܳܐ",
            dt1: "Zedernholz",
            sy6: "",
            dt2: ""
        },
        {
            lt12: "",
            lt10: "mimro",
            id: "1509",
            sy6: "ܩܦܝܬܐ",
            lt11: "qofitho",
            sy7: "ܩܳܦܺܝܬ݂ܳܐ",
            sy8: "",
            sy9: "",
            sy4: "ܡܐܡܪܐ",
            dt2: "Reim",
            dt1: "Gedicht",
            sy5: "ܡܺܐܡܪܳܐ",
            dt3: "Dichtung"
        },
        {
            lt12: "",
            sy5: "ܕܰܠܺܝܠܬܰܐ",
            sy9: "",
            id: "790",
            sy6: "",
            lt10: "ܕܠܝܠܬܐ",
            dt1: "Dalilta",
            sy4: "ܕܠܝܠܬܐ",
            dt2: "",
            lt11: "ܕܠܝܠܬܐ",
            sy7: "",
            sy8: "",
            dt3: ""
        },
        {
            sy5: "ܢܺܝܗܰܬ",
            sy7: "ܢܺܐܗܰܬ",
            lt11: "ܢܝܗܬ",
            lt12: "ܢܐܗܬ",
            sy9: "",
            lt10: "ܢܝܗܬ",
            sy6: "ܢܐܗܬ",
            dt3: "",
            dt2: "",
            sy8: "",
            dt1: "Nihat",
            sy4: "ܢܝܗܬ",
            id: "3277"
        },
        {
            dt2: "Großvater",
            sy4: "ܩܫܐ",
            sy9: "ܣܳܒܳܐ",
            lt10: "qasho",
            dt3: "Senior",
            sy5: "ܩܰܫܳܐ",
            sy6: "ܩܫܝܫܐ",
            id: "3375",
            sy7: "ܩܰܫܺܝܫܳܐ",
            lt12: "sobo",
            dt1: "Opa",
            lt11: "qashisho",
            sy8: "ܣܒܐ"
        },
        {
            sy9: "",
            dt2: "",
            dt3: "",
            dt1: "Urhoy",
            id: "4686",
            lt12: "",
            sy7: "",
            lt10: "urhoy",
            sy4: "ܐܘܪܗܝ",
            sy6: "",
            sy8: "",
            sy5: "ܐܽܘܪܗܳܝ",
            lt11: ""
        },
        {
            id: "5598",
            sy4: "ܐܠܗܐ ܢܣܬܪ ܠܢ",
            lt11: "",
            sy9: "",
            dt1: "Gott beschütze uns",
            dt2: "",
            lt10: "Aloho nsatar lan",
            sy5: "ܐܰܠܳܗܳܐ ܢܣܰܬܰܪ ܠܰܢ",
            sy8: "",
            sy6: "",
            lt12: "",
            sy7: "",
            dt3: ""
        },
        {
            sy7: "",
            lt10: "turto roĉyo",
            sy8: "",
            sy4: "ܬܘܪܬܐ ܪܥܝܐ",
            lt12: "",
            id: "2658",
            dt2: "",
            sy9: "",
            dt1: "Kuh weidet",
            sy5: "ܬܽܘܪܬܳܐ ܪܳܥܝܳܐ",
            sy6: "",
            dt3: "",
            lt11: ""
        },
        {
            sy7: "",
            dt2: "",
            lt11: "ܥܕܪ",
            sy4: "ܥܕܪ",
            dt1: "Adar",
            lt10: "ܥܕܪ",
            dt3: "",
            id: "128",
            sy6: "",
            lt12: "",
            sy8: "",
            sy5: "ܥܰܕܰܪ",
            sy9: ""
        },
        {
            lt10: "ĥiro",
            id: "2803",
            sy8: "",
            lt12: "",
            dt2: "",
            sy5: "ܚܺܐܪܳܐ",
            sy4: "ܚܐܪܐ",
            sy7: "ܠܺܝܒܶܪܰܠܳܝܳܐ",
            sy6: "ܠܝܒܪܠܝܐ",
            dt1: "liberal",
            dt3: "",
            lt11: "liberaloyo",
            sy9: ""
        },
        {
            sy6: "",
            sy4: "ܐܪܫܟܝܬܐ",
            dt1: "Hauptstadt",
            lt10: "areshkoyto",
            dt2: "",
            lt11: "",
            dt3: "",
            sy9: "",
            sy7: "",
            sy8: "",
            id: "1891",
            sy5: "ܐܰܪܶܫܟܳܝܬܳܐ",
            lt12: ""
        },
        {
            sy5: "ܚܰܒܫܽܘܫܳܐ",
            dt3: "",
            sy8: "",
            lt11: "",
            sy9: "",
            lt12: "",
            id: "2374",
            dt2: "",
            lt10: "ĥabshusho",
            sy6: "",
            sy7: "",
            dt1: "Käfer",
            sy4: "ܚܒܫܘܫܐ"
        },
        {
            lt11: "ܬܘܠܡܝ",
            lt10: "ܬܘܠܡܝ",
            sy7: "",
            sy4: "ܬܘܠܡܝ",
            dt1: "Tulmai",
            sy5: "ܬܽܘܠܡܰܝ",
            lt12: "",
            sy8: "",
            sy6: "",
            id: "4591",
            sy9: "",
            dt2: "",
            dt3: ""
        },
        {
            sy4: "ܬܒ",
            lt11: "",
            sy7: "",
            dt2: "",
            sy9: "",
            id: "5633",
            lt12: "",
            dt1: "abschwören",
            sy6: "",
            sy5: "ܬܳܒ",
            sy8: "",
            dt3: "",
            lt10: "tob"
        },
        {
            sy8: "",
            sy9: "",
            dt1: "Hörer",
            sy6: "ܨܝܘܬܐ",
            lt11: "ŝoyutho",
            dt2: "",
            dt3: "",
            lt12: "",
            lt10: "shomuĉo",
            sy5: "ܫܳܡܽܘܥܳܐ",
            sy4: "ܫܡܘܥܐ",
            sy7: "ܨܳܝܽܘܬ݂ܳܐ",
            id: "2051"
        },
        {
            sy6: "ܪܣܣ",
            lt10: "ros",
            lt11: "rases",
            sy7: "ܪܰܣܶܣ",
            sy5: "ܪܳܣ",
            sy4: "ܪܣ",
            lt12: "",
            dt3: "",
            sy9: "",
            id: "1639",
            dt1: "gießen",
            dt2: "",
            sy8: ""
        },
        {
            sy4: "ܦܣܣܐ",
            dt3: "Genehmigung",
            sy5: "ܦܣܳܣܳܐ",
            sy9: "",
            dt2: "Erlaubnis",
            id: "5368",
            lt10: "fsoso",
            sy7: "ܦܣܺܝܣܽܘܬ݂ܳܐ",
            sy6: "ܦܣܝܣܘܬܐ",
            lt11: "fsisutho",
            sy8: "",
            dt1: "Zulassung",
            lt12: ""
        },
        {
            sy6: "",
            dt1: "Drehung",
            sy7: "",
            lt10: "bromo",
            dt2: "",
            sy4: "ܒܪܡܐ",
            lt12: "",
            id: "956",
            sy8: "",
            lt11: "",
            sy9: "",
            dt3: "",
            sy5: "ܒܪܳܡܳܐ"
        },
        {
            dt2: "",
            sy5: "ܩܽܘܪܒܰܢܰܐ",
            lt10: "ܩܘܪܒܢܐ",
            lt11: "ܩܘܪܒܢܐ",
            sy6: "",
            lt12: "",
            sy4: "ܩܘܪܒܢܐ",
            sy9: "",
            sy7: "",
            dt3: "",
            id: "2610",
            dt1: "Korbana",
            sy8: ""
        },
        {
            lt12: "",
            sy4: "ܩܠܢܝܐ",
            sy5: "ܩܳܠܳܢܳܝܳܐ",
            lt11: "",
            dt1: "Vokal",
            lt10: "qolonoyo",
            id: "4846",
            dt3: "",
            sy6: "",
            sy9: "",
            dt2: "",
            sy7: "",
            sy8: ""
        },
        {
            sy5: "ܡܠܺܝ",
            id: "378",
            lt11: "",
            sy4: "ܡܠܝ",
            sy6: "",
            dt3: "",
            lt12: "",
            dt2: "",
            lt10: "mli",
            sy7: "",
            sy9: "",
            sy8: "",
            dt1: "ausfüllen"
        },
        {
            dt2: "",
            lt11: "",
            dt3: "",
            sy6: "",
            id: "2150",
            sy5: "ܣܰܟ݂ܠܳܐ",
            sy7: "",
            lt10: "sakhlo",
            sy9: "",
            lt12: "",
            sy4: "ܣܟܠܐ",
            sy8: "",
            dt1: "Idiot"
        },
        {
            lt12: "",
            id: "4508",
            dt3: "",
            sy4: "ܒܪܬܐ",
            sy8: "",
            sy9: "",
            lt10: "bartho",
            dt2: "",
            sy6: "",
            dt1: "Tochter",
            sy5: "ܒܰܪܬ݂ܳܐ",
            lt11: "",
            sy7: ""
        },
        {
            sy8: "",
            lt12: "",
            lt10: "shates",
            sy9: "",
            sy5: "ܫܰܬܶܐܣ",
            id: "2217",
            sy7: "ܫܰܬܶܣ",
            dt3: "",
            sy4: "ܫܬܐܣ",
            dt1: "installieren",
            lt11: "shates",
            sy6: "ܫܬܣ",
            dt2: ""
        },
        {
            dt2: "",
            sy9: "",
            lt12: "",
            sy4: "ܢܒܘܒܠܘܨܪ",
            dt3: "",
            sy6: "",
            sy8: "",
            id: "3149",
            dt1: "Nabopolassar",
            sy5: "ܢܰܒܳܘܒܳܠܳܘܨܰܪ",
            lt11: "ܢܒܘܒܠܘܨܪ",
            sy7: "",
            lt10: "ܢܒܘܒܠܘܨܪ"
        },
        {
            lt12: "",
            dt2: "",
            sy9: "",
            sy7: "",
            lt11: "",
            sy4: "ܫܘܒܗܪܐ",
            dt1: "Stolz",
            sy6: "",
            sy5: "ܫܽܘܒܗܳܪܳܐ",
            lt10: "shubhoro",
            dt3: "",
            sy8: "",
            id: "4283"
        },
        {
            lt12: "",
            sy4: "ܒܠܒܘܣܐ",
            sy7: "",
            sy9: "",
            id: "2418",
            dt3: "",
            lt11: "",
            dt2: "",
            lt10: "balbuso",
            sy5: "ܒܰܠܒܽܘܣܳܐ",
            sy6: "",
            sy8: "",
            dt1: "Kartoffel"
        },
        {
            sy8: "",
            dt2: "",
            sy6: "",
            lt11: "",
            sy9: "",
            lt12: "",
            sy7: "",
            dt3: "",
            dt1: "Beisetzung",
            sy4: "ܥܘܦܝܐ",
            id: "533",
            lt10: "ĉufoyo",
            sy5: "ܥܽܘܦܳܝܳܐ"
        },
        {
            dt1: "Alarmzeichen",
            lt12: "",
            dt2: "",
            lt10: "nish qențo",
            sy8: "",
            sy4: "ܢܝܫ ܩܢܛܐ",
            sy6: "",
            sy5: "ܢܺܝܫ ܩܶܢܛܳܐ",
            sy7: "",
            sy9: "",
            dt3: "",
            lt11: "",
            id: "5819"
        },
        {
            sy8: "",
            dt3: "",
            dt2: "",
            sy6: "",
            lt11: "",
            sy9: "",
            sy5: "ܨܰܗܝܳܐ ܐ̱ܢܳܐ",
            sy4: "ܨܗܝܐ ܐܢܐ",
            lt12: "",
            sy7: "",
            lt10: "ŝahyo no",
            dt1: "Ich habe Durst",
            id: "2113"
        },
        {
            sy5: "ܢܙܺܝܪܽܘܬ݂ܳܐ",
            dt1: "Zölibat",
            lt10: "nzirutho",
            sy8: "ܩܝܡܐ",
            sy4: "ܢܙܝܪܘܬܐ",
            sy7: "ܠܳܐ ܡܙܰܘܓ݂ܽܘܬ݂ܳܐ",
            id: "6290",
            sy9: "ܩܝܳܡܳܐ",
            lt12: "qyomo",
            lt11: "lo mzauğutho",
            dt2: "",
            sy6: "ܠܐ ܡܙܘܓܘܬܐ",
            dt3: ""
        },
        {
            sy8: "",
            sy9: "",
            dt3: "",
            id: "4777",
            sy4: "ܐܓܪ",
            lt11: "auğar",
            lt12: "",
            sy5: "ܐܰܓ݂ܰܪ",
            dt2: "",
            sy7: "ܐܰܘܓ݂ܰܪ",
            sy6: "ܐܘܓܪ",
            dt1: "verpachten",
            lt10: "ağar"
        },
        {
            lt10: "otho d`sheyalto",
            sy8: "",
            sy6: "",
            lt11: "",
            dt1: "Fragezeichen",
            dt3: "",
            id: "5481",
            dt2: "",
            sy5: "ܐܳܬ̣ܳܐ ܕܫܶܝܰܠܬܳܐ",
            sy7: "",
            sy4: "ܐܳܬ̣ܳܐ ܕܫܶܝܰܠܬܳܐ",
            sy9: "",
            lt12: ""
        },
        {
            lt10: "fatho d`naulo",
            lt12: "",
            sy7: "ܕܰܦܳܐ ܕܢܰܘܠܳܐ",
            sy8: "",
            dt3: "",
            sy9: "",
            sy4: "ܦܬܐ ܕܢܘܠܐ",
            id: "2229",
            sy5: "ܦܰܬ݂ܳܐ ܕܢܰܘܠܳܐ",
            sy6: "ܕܦܐ ܕܢܘܠܐ",
            dt1: "Internetseite",
            lt11: "dafo d`naulo",
            dt2: ""
        },
        {
            sy4: "ܕܓܠܐ",
            sy9: "ܫܽܘܡܪܳܐ",
            sy5: "ܕܓܳܠܳܐ",
            dt2: "",
            dt1: "Grippe",
            sy8: "ܫܘܡܪܐ",
            lt12: "shumro",
            sy7: "ܫܰܘܒܳܐ",
            lt10: "dgolo",
            sy6: "ܫܘܒܐ",
            id: "1733",
            lt11: "shauwbo",
            dt3: ""
        },
        {
            dt1: "Rotes Meer",
            sy7: "",
            sy4: "ܝܡܐ ܕܣܘܦ",
            lt11: "",
            sy5: "ܝܰܡܳܐ ܕܣܽܘܦ",
            dt2: "",
            lt10: "yamo d`suf",
            dt3: "",
            sy9: "",
            id: "3752",
            sy6: "",
            sy8: "",
            lt12: ""
        },
        {
            sy8: "",
            dt1: "Hass",
            dt3: "",
            lt11: "senyutho",
            sy6: "ܣܢܝܘܬܐ",
            lt10: "snoyo",
            dt2: "",
            sy4: "ܣܢܝܐ",
            sy5: "ܣܢܳܝܳܐ",
            id: "1883",
            sy7: "ܣܶܢܝܽܘܬ݂ܳܐ",
            lt12: "",
            sy9: ""
        },
        {
            sy5: "ܫܰܢ̱ܬܳܐ ܒܪܺܝܟ݂ܬܳܐ",
            lt12: "",
            lt11: "shato d`ĥađwotho",
            sy6: "ܫܢܬܐ ܕܚܕܘܬܐ",
            sy9: "",
            lt10: "shato brikhto",
            sy8: "",
            id: "1406",
            dt3: "",
            dt2: "Alles Gute im neuen Jahr",
            sy4: "ܫܢܬܐ ܒܪܝܟܬܐ",
            dt1: "Frohes neues Jahr",
            sy7: "ܫܰܢ̱ܬܳܐ ܕܚܰܕܘܳܬ݂ܳܐ"
        },
        {
            sy5: "ܢܶܫܪܳܐ",
            dt3: "",
            sy6: "",
            sy7: "",
            sy9: "",
            id: "3249",
            lt12: "",
            lt11: "ܢܫܪܐ",
            lt10: "ܢܫܪܐ",
            sy8: "",
            dt2: "",
            sy4: "ܢܫܪܐ",
            dt1: "Neschro"
        },
        {
            id: "5171",
            dt1: "Wortstamm",
            dt2: "",
            sy6: "",
            sy7: "",
            lt12: "",
            sy8: "",
            lt10: "shersho d`meltho",
            sy5: "ܫܶܪܫܳܐ ܕܡܶܠܬ݂ܳܐ",
            sy9: "",
            sy4: "ܫܪܫܐ ܕܡܠܬܐ",
            lt11: "",
            dt3: ""
        },
        {
            sy4: "ܚܡܪܐ ܣܘܡܩܐ",
            lt11: "",
            sy9: "",
            sy6: "",
            lt12: "",
            dt2: "",
            sy5: "ܚܰܡܪܳܐ ܣܽܘܡܳܩܳܐ",
            sy8: "",
            dt3: "",
            dt1: "Rotwein",
            id: "3753",
            sy7: "",
            lt10: "ĥamro sumoqo"
        },
        {
            dt3: "",
            dt1: "Naqija",
            dt2: "",
            sy9: "",
            sy6: "",
            lt12: "",
            lt10: "ܢܩܝܝܐ",
            id: "3202",
            lt11: "ܢܩܝܝܐ",
            sy7: "",
            sy8: "",
            sy5: "ܢܰܩܺܝܝܳܐ",
            sy4: "ܢܩܝܝܐ"
        },
        {
            dt2: "",
            lt12: "",
            sy8: "",
            sy4: "ܒܝܪܬܐ",
            lt10: "birto",
            dt3: "",
            sy5: "ܒܺܝܪܬܳܐ",
            sy7: "ܩܽܘܨܪܳܐ",
            dt1: "Palast",
            lt11: "quŝro",
            sy9: "",
            id: "3409",
            sy6: "ܩܽܘܨܪܳܐ"
        },
        {
            dt3: "",
            sy7: "",
            sy4: "ܬܘܕܝ، ܛܘܘܐ ܢܐ.",
            sy9: "",
            "date": {
                "seconds": 1587245776,
                "nanoseconds": 41000000
            },
            sy8: "",
            lt10: "Tawdi, ṭ­awwe na.",
            sy6: "",
            sy5: "ܬܰܘܕܝ، ܛܰܘܘܶܐ ܢܰܐ.",
            dt1: "Danke, uns geht es gut.",
            lt11: "",
            id: "YVCYU1Awp1akA0YYxe1m",
            dt2: "",
            lt12: ""
        },
        {
            sy4: "ܛܘܪ ܠܒܢܢ",
            lt12: "",
            id: "2802",
            sy7: "",
            dt1: "Libanon Gebirge",
            sy6: "",
            lt11: "",
            dt2: "",
            sy9: "",
            sy5: "ܛܽܘܪ ܠܶܒܢܳܢ",
            dt3: "",
            lt10: "țur lebnon",
            sy8: ""
        },
        {
            lt12: "",
            id: "3453",
            lt10: "susyo",
            sy5: "ܣܽܘܣܝܳܐ",
            lt11: "susto",
            sy6: "ܣܘܣܬܐ",
            sy9: "",
            dt2: "",
            sy4: "ܣܘܣܝܐ",
            dt1: "Pferd",
            dt3: "",
            sy8: "",
            sy7: "ܣܽܘܣܬܳܐ"
        },
        {
            lt11: "sheth",
            dt2: "Sechs",
            sy7: "ܫܶܬ݂",
            id: "7",
            sy9: "ܘ",
            lt12: "waw",
            dt1: "6",
            dt3: "",
            sy6: "ܫܬ",
            sy4: "ܫܬܐ ",
            sy5: "ܫܬܳܐ",
            sy8: "ܘ",
            lt10: "shto"
        },
        {
            lt10: "yoldutho",
            dt1: "Mutterschaft",
            lt12: "",
            sy7: "ܐܶܡܗܳܝܽܘܬ݂ܳܐ",
            sy8: "",
            sy5: "ܝܳܠܕܽܘܬ݂ܳܐ",
            dt3: "",
            sy9: "",
            sy4: "ܝܠܕܘܬܐ",
            lt11: "emhoyutho",
            id: "3139",
            sy6: "ܐܡܗܝܘܬܐ",
            dt2: ""
        },
        {
            lt12: "",
            sy8: "",
            dt3: "Linie",
            sy4: "ܚܘܩܐ",
            lt10: "ĥuqo",
            sy7: "",
            sy9: "",
            sy5: "ܚܽܘܩܳܐ",
            sy6: "",
            dt2: "Reihe",
            lt11: "",
            dt1: "Zeile",
            id: "5286"
        },
        {
            dt1: "verkürzt",
            lt10: "qfiso",
            dt2: "kurz",
            sy6: "",
            id: "4755",
            sy4: "ܩܦܝܣܐ",
            lt12: "",
            sy8: "",
            sy7: "",
            dt3: "knapp",
            sy5: "ܩܦܺܝܣܳܐ",
            sy9: "",
            lt11: ""
        },
        {
            sy9: "",
            id: "1677",
            sy4: "ܓܡܝܪܘܬܐ",
            dt2: "",
            sy6: "",
            lt12: "",
            lt11: "ܓܡܝܪܘܬܐ",
            sy7: "",
            dt1: "Gmiruta",
            dt3: "",
            lt10: "ܓܡܝܪܘܬܐ",
            sy5: "ܓܡܺܝܪܽܘܬܰܐ",
            sy8: ""
        },
        {
            dt2: "Interpret",
            sy6: "ܦܫܩܐ",
            sy4: "ܡܦܫܩܢܐ",
            id: "2570",
            sy8: "",
            dt3: "",
            sy7: "ܦܰܫܳܩܳܐ",
            sy5: "ܡܦܰܫܩܳܢܳܐ",
            lt11: "fashoqo",
            lt12: "",
            dt1: "Kommentator",
            sy9: "",
            lt10: "mfashqono"
        },
        {
            sy4: "ܐܢܘܫ",
            id: "1111",
            dt3: "",
            dt1: "Ensosch",
            sy8: "",
            lt12: "",
            sy9: "",
            sy7: "",
            lt10: "ܐܢܘܫ",
            sy6: "",
            lt11: "ܐܢܘܫ",
            dt2: "",
            sy5: "ܐܶܢܳܘܫ"
        },
        {
            id: "5758",
            lt10: "sğed",
            dt1: "adorieren",
            sy7: "",
            sy8: "",
            lt11: "",
            sy6: "",
            dt2: "",
            sy9: "",
            dt3: "",
            lt12: "",
            sy4: "ܣܓܕ",
            sy5: "ܣܓ݂ܶܕ"
        },
        {
            sy5: "ܬܰܪܢܳܓ݂ܽܘܠܬܳܐ",
            id: "1961",
            dt2: "",
            dt1: "Henne",
            dt3: "",
            lt12: "",
            sy7: "",
            sy4: "ܬܪܢܓܘܠܬܐ",
            lt11: "",
            sy9: "",
            lt10: "tarnoğulto",
            sy8: "",
            sy6: ""
        },
        {
            sy9: "",
            id: "6122",
            lt12: "",
            sy5: "ܫܽܘܠܛܳܢܳܐ",
            dt3: "",
            sy6: "",
            sy8: "",
            dt1: "Obrigkeit",
            sy4: "ܫܘܠܛܢܐ",
            lt10: "shulțono",
            dt2: "",
            sy7: "",
            lt11: ""
        },
        {
            lt11: "",
            dt2: "",
            dt3: "",
            id: "5925",
            lt12: "",
            dt1: "angefügt",
            sy8: "",
            sy5: "ܢܰܩܺܝܦܳܐ",
            sy7: "",
            lt10: "naqifo",
            sy9: "",
            sy4: "ܢܩܝܦܐ",
            sy6: ""
        },
        {
            dt1: "Josefina",
            sy6: "ܝܙܦܝܢܐ",
            dt2: "",
            sy5: "ܝܰܘܣܶܦܺܝܢܰܐ",
            sy9: "",
            lt10: "ܝܘܣܦܝܢܐ",
            id: "2339",
            sy7: "ܝܳܙܶܦܺܝܢܰܐ",
            sy8: "",
            sy4: "ܝܘܣܦܝܢܐ",
            lt11: "ܝܘܣܦܝܢܐ",
            dt3: "",
            lt12: "ܝܙܦܝܢܐ"
        },
        {
            sy9: "",
            sy8: "",
            dt2: "",
            lt11: "",
            lt10: "dyutho",
            sy7: "",
            sy4: "ܕܝܘܬܐ",
            sy6: "",
            sy5: "ܕܝܽܘܬ݂ܳܐ",
            dt3: "",
            lt12: "",
            dt1: "Tinte",
            id: "4494"
        },
        {
            lt11: "",
            dt2: "Glas",
            dt3: "Kelch",
            sy7: "",
            lt12: "",
            sy9: "",
            lt10: "koso",
            id: "516",
            dt1: "Becher",
            sy4: "ܟܣܐ",
            sy6: "",
            sy5: "ܟܳܣܳܐ",
            sy8: ""
        },
        {
            sy7: "ܐܰܫܰܪ",
            dt1: "achten",
            id: "5730",
            sy5: "ܝܰܩܰܪ",
            lt10: "yaqar",
            dt2: "",
            sy4: "ܝܩܪ",
            dt3: "",
            lt12: "bayen",
            sy6: "ܐܫܪ",
            lt11: "ashar",
            sy8: "ܒܝܢ",
            sy9: "ܒܰܝܶܢ"
        },
        {
            sy9: "",
            lt11: "",
            dt2: "ruhmreich",
            sy4: "ܓܐܝܐ",
            dt3: "",
            dt1: "stolz",
            sy5: "ܓܰܐܝܳܐ",
            sy7: "",
            id: "4282",
            sy8: "",
            lt10: "gayo",
            lt12: "",
            sy6: ""
        },
        {
            lt12: "fahoyo",
            sy9: "ܦܰܗܳܝܳܐ",
            sy6: "ܕܚܝܩܐ",
            dt1: "Strolch",
            id: "6143",
            lt10: "ĥođuro",
            dt3: "",
            dt2: "",
            lt11: "dĥiqo",
            sy4: "ܚܕܘܪܐ",
            sy5: "ܚܳܕܽܘܪܳܐ",
            sy7: "ܕܚܺܝܩܳܐ",
            sy8: "ܦܗܝܐ"
        },
        {
            dt3: "",
            sy5: "ܡܬܰܗܪܳܢܳܐ",
            dt2: "",
            dt1: "witzig",
            sy8: "",
            sy7: "",
            lt10: "mtahrono",
            lt12: "",
            sy6: "",
            id: "5141",
            sy4: "ܡܬܗܪܢܐ",
            lt11: "",
            sy9: ""
        },
        {
            sy7: "",
            sy4: "ܚܒܚܝ",
            dt3: "",
            lt11: "ܚܒܚܝ",
            sy8: "",
            id: "1793",
            lt10: "ܚܒܚܝ",
            lt12: "",
            dt2: "",
            sy9: "",
            sy5: "ܚܰܒܚܺܝ",
            sy6: "",
            dt1: "Habhi"
        },
        {
            lt12: "ܕܝܐܠܐ",
            sy4: "ܕܝܠܐ",
            sy5: "ܕܺܝܰܠܰܐ",
            lt11: "ܕܝܠܐ",
            sy9: "",
            dt3: "",
            lt10: "ܕܝܠܐ",
            id: "907",
            sy8: "",
            sy6: "ܕܝܐܠܐ",
            dt2: "",
            sy7: "ܕܺܝܰܐܠܰܐ",
            dt1: "Diyala"
        },
        {
            dt1: "geschmackvoll",
            sy8: "",
            lt11: "",
            sy5: "ܛܰܥܡܳܢܳܝܳܐ",
            dt3: "",
            id: "1588",
            sy9: "",
            sy6: "",
            sy4: "ܛܥܡܢܝܐ",
            dt2: "",
            lt12: "",
            sy7: "",
            lt10: "țaĉmonoyo"
        },
        {
            sy9: "",
            sy4: "ܒܝܬܠܚܡ",
            sy5: "ܒܶܝܬ݂ܠܚܶܡ",
            id: "584",
            sy7: "ܐܳܦܰܪܬ݂ܳܐ",
            lt12: "",
            lt10: "bethlĥem",
            lt11: "ofartho",
            dt1: "Bethlehem",
            sy6: "ܐܦܪܬܐ",
            dt2: "",
            sy8: "",
            dt3: ""
        },
        {
            lt12: "",
            lt11: "",
            sy8: "",
            lt10: "abohe qadmoye",
            sy4: "ܐܒܗܐ ܩܕܡܝܐ",
            dt2: "",
            dt1: "Vorfahren",
            sy6: "",
            dt3: "",
            sy7: "",
            sy9: "",
            id: "4901",
            sy5: "ܐܰܒܳܗ̈ܶܐ ܩܰܕܡܳܝܶܐ"
        },
        {
            dt3: "",
            id: "3911",
            sy6: "",
            sy9: "",
            sy4: "ܪܦܫܐ",
            sy7: "",
            lt11: "",
            lt12: "",
            sy8: "",
            sy5: "ܪܰܦܫܳܐ",
            dt1: "Schaufel",
            dt2: "Schippe",
            lt10: "rafsho"
        },
        {
            sy5: "ܫܳܥܬ݂ܳܐ",
            lt11: "",
            lt12: "",
            sy4: "ܫܥܬܐ",
            sy8: "",
            dt3: "Uhrzeit",
            sy6: "",
            dt2: "Stunde",
            dt1: "Uhr",
            id: "4623",
            sy9: "",
            lt10: "shoĉtho",
            sy7: ""
        },
        {
            lt12: "",
            sy8: "",
            dt1: "Verwandschaft",
            dt3: "",
            sy9: "",
            id: "4824",
            sy7: "",
            dt2: "Nähe",
            sy6: "",
            sy5: "ܩܰܪܺܝܒܽܘܬ݂ܳܐ",
            lt10: "qaributho",
            lt11: "",
            sy4: "ܩܪܝܒܘܬܐ"
        },
        {
            sy5: "ܬܶܡܪܳܐ",
            lt12: "",
            sy6: "",
            dt3: "",
            sy9: "",
            sy8: "",
            lt11: "",
            sy7: "",
            sy4: "ܬܡܪܐ",
            dt1: "Wimper",
            id: "5114",
            lt10: "temro",
            dt2: "Augenwimper"
        },
        {
            sy7: "",
            dt3: "Eliya",
            dt1: "Elyo",
            lt11: "ܐܠܝܐ",
            lt12: "",
            id: "1092",
            dt2: "Eliyo",
            sy4: "ܐܠܝܐ",
            sy5: "ܐܶܠܺܝܳܐ",
            sy9: "",
            sy6: "",
            lt10: "ܐܠܝܐ",
            sy8: ""
        },
        {
            sy9: "",
            sy6: "",
            sy5: "ܐܺܝܠܳܢ ܚܰܒܽܘܫܳܐ",
            sy4: "ܐܝܠܢ ܚܒܘܫܐ",
            lt11: "",
            dt2: "",
            dt3: "",
            lt10: "ilon ĥabusho",
            id: "6011",
            dt1: "Apfelbaum",
            sy8: "",
            lt12: "",
            sy7: ""
        },
        {
            sy4: "ܣܥܪܐ ܥܩܣܩܣܐ",
            id: "833",
            sy9: "",
            sy5: "ܣܰܥܪܳܐ ܥܩܰܣܩܣܳܐ",
            dt2: "Locken",
            dt3: "",
            sy7: "",
            dt1: "Dauerwelle",
            sy6: "",
            sy8: "",
            lt12: "",
            lt10: "saĉro ĉqasqso",
            lt11: ""
        },
        {
            dt1: "Mittelalter",
            sy4: "ܕܪܐ ܡܨܥܝܐ",
            id: "6352",
            lt11: "",
            lt10: "dore meŝĉoye",
            lt12: "",
            sy9: "",
            sy6: "",
            dt2: "",
            dt3: "",
            sy7: "",
            sy5: "ܕܳܪܶ̈ܐ ܡܶܨܥܳܝܶ̈ܐ",
            sy8: ""
        },
        {
            sy4: "ܪܡܫ",
            id: "5552",
            lt11: "",
            sy6: "",
            sy5: "ܪܡܰܫ",
            dt3: "",
            dt1: "Abend werden",
            lt10: "rmash",
            sy8: "",
            sy9: "",
            lt12: "",
            dt2: "",
            sy7: ""
        },
        {
            dt1: "Feuer spucken",
            sy9: "",
            sy5: "ܪܰܩ ܢܽܘܪܳܐ",
            id: "1303",
            lt12: "",
            sy8: "",
            dt2: "",
            lt11: "",
            lt10: "raq nuro",
            sy7: "",
            sy6: "",
            sy4: "ܪܩ ܢܘܪܐ",
            dt3: ""
        },
        {
            sy6: "",
            dt2: "",
            sy8: "",
            sy4: "ܪܓܫܐ ܕܚܛܝܬܐ",
            dt3: "",
            sy5: "ܪܶܓ݂ܫܳܐ ܕܰܚܛܺܝܬ݂ܳܐ",
            lt12: "",
            sy7: "",
            lt10: "reğsho da`ĥțitho",
            sy9: "",
            dt1: "Schuldgefühl",
            id: "4047",
            lt11: ""
        },
        {
            dt2: "unterschiedlich",
            dt1: "anders",
            sy6: "ܡܫܓܢܝܐ",
            lt12: "mshagnoith",
            id: "5934",
            sy4: "ܡܫܚܠܦܐܝܬ",
            sy5: "ܡܫܰܚܠܦܳܐܝܺܬ݂",
            sy8: "ܡܫܓܢܐܝܬ",
            dt3: "verschieden",
            lt11: "mshagnoyo",
            sy7: "ܡܫܰܓܢܳܝܳܐ",
            sy9: "ܡܫܰܓܢܳܐܝܺܬ݂",
            lt10: "mshaĥlfoith"
        },
        {
            sy5: "ܥܰܒܽܘܫܬܳܐ",
            lt10: "ܥܒܘܫܬܐ",
            sy7: "",
            sy8: "",
            id: "63",
            dt1: "Abbuschto",
            lt12: "",
            sy4: "ܥܒܘܫܬܐ",
            dt3: "",
            sy6: "",
            dt2: "",
            lt11: "ܥܒܘܫܬܐ",
            sy9: ""
        },
        {
            lt12: "shuloĥo",
            id: "5667",
            dt2: "",
            sy7: "ܫܟܳܢܬܳܐ",
            sy5: "ܢܰܚܬܽܘܬ݂ܳܐ",
            lt11: "shkonto",
            dt1: "Abstieg",
            sy8: "ܫܘܠܚܐ",
            dt3: "",
            sy9: "ܫܽܘܠܳܚܳܐ",
            sy6: "ܫܟܢܬܐ",
            lt10: "naĥtutho",
            sy4: "ܢܚܬܘܬܐ"
        },
        {
            sy7: "",
            sy9: "",
            sy4: "ܡܨܥܝܬܐ",
            id: "6356",
            sy8: "",
            lt11: "",
            sy6: "",
            lt10: "meŝĉoyto",
            lt12: "",
            dt1: "Mittelfinger",
            sy5: "ܡܶܨܥܳܝܬܳܐ",
            dt3: "",
            dt2: ""
        },
        {
            sy5: "ܫܰܪܛܳܐ",
            lt12: "",
            sy4: "ܫܪܛܐ",
            id: "518",
            lt10: "sharțo",
            dt1: "Bedingung",
            sy9: "",
            dt2: "Voraussetzung",
            dt3: "",
            sy8: "",
            sy7: "",
            lt11: "",
            sy6: ""
        },
        {
            sy5: "ܪܓ݂ܰܫ ܠܚܽܘܒܳܐ",
            dt2: "",
            lt12: "",
            sy7: "",
            dt3: "",
            id: "884",
            sy9: "",
            sy4: "ܪܓܫ ܠܚܘܒܐ",
            lt10: "rğash l'ĥubo",
            dt1: "die Liebe fühlen",
            lt11: "",
            sy6: "",
            sy8: ""
        },
        {
            sy6: "",
            dt1: "Tee",
            id: "4417",
            lt12: "",
            sy7: "",
            lt11: "",
            sy4: "ܬܐܝܐ",
            sy9: "",
            sy5: "ܬܺܐܝܳܐ",
            sy8: "",
            dt3: "",
            dt2: "",
            lt10: "tiyo"
        },
        {
            dt2: "Besitzer",
            sy9: "",
            dt1: "Eigentümer",
            id: "1031",
            lt11: "",
            sy8: "",
            sy6: "",
            sy7: "",
            dt3: "",
            sy5: "ܡܳܪܳܐ",
            sy4: "ܡܪܐ",
            lt12: "",
            lt10: "moro"
        },
        {
            id: "5946",
            sy5: "ܩܰܕܺܝܡܳܐ",
            lt12: "",
            sy6: "ܪܝܫܢܐܝܬ",
            sy8: "",
            lt10: "qadimo",
            dt1: "anfänglich",
            dt2: "anfangs",
            dt3: "zu Anfang",
            sy7: "ܪܺܝܫܳܢܳܐܝܺܬ݂",
            sy9: "",
            lt11: "rishonoith",
            sy4: "ܩܕܝܡܐ"
        },
        {
            id: "706",
            sy8: "",
            dt2: "",
            sy7: "",
            lt12: "",
            lt11: "",
            sy6: "",
            lt10: "egartho",
            sy4: "ܐܓܪܬܐ",
            sy5: "ܐܶܓܰܪܬ݂ܳܐ",
            dt3: "",
            dt1: "Brief",
            sy9: ""
        },
        {
            dt2: "großenteils",
            dt3: "",
            dt1: "Großteil",
            sy4: "ܡܢܬܐ ܪܒܬܐ",
            sy8: "",
            id: "6347",
            lt11: "",
            sy9: "",
            sy6: "",
            lt12: "",
            sy5: "ܡܢܳܬ݂ܳܐ ܪܰܒܬ݂ܳܐ",
            sy7: "",
            lt10: "mnotho rabtho"
        },
        {
            sy9: "",
            sy8: "",
            sy7: "",
            sy6: "",
            id: "2007",
            dt1: "Hindernis",
            sy5: "ܟܳܠܺܝܬ݂ܳܐ",
            lt10: "kolitho",
            sy4: "ܟܠܝܬܐ",
            dt2: "Verbot",
            lt12: "",
            dt3: "",
            lt11: ""
        },
        {
            id: "4743",
            sy6: "",
            sy7: "",
            sy9: "",
            sy4: "ܬܓܪ ",
            sy8: "",
            dt2: "",
            sy5: "ܬܓܰܪ ",
            lt11: "",
            dt1: "verhandeln",
            lt10: "tgar",
            lt12: "",
            dt3: ""
        },
        {
            dt1: "Rabo",
            lt12: "",
            dt3: "",
            sy4: "ܪܒܐ",
            sy5: "ܪܰܒܳܐ",
            sy9: "",
            lt10: "ܪܒܐܝܠ",
            sy7: "",
            dt2: "",
            sy6: "",
            sy8: "",
            id: "3582",
            lt11: "ܪܒܐܝܠ"
        },
        {
            sy6: "",
            dt3: "",
            sy7: "",
            lt10: "qonuno d`eshtĉenyo",
            sy8: "",
            lt11: "",
            id: "4215",
            dt1: "Spielregeln",
            sy4: "ܩܢܘܢܐ ܕܐܫܬܥܢܝܐ",
            sy5: "ܩܳܢܽܘܢܳܐ ܕܐܶܫܬܥܶܢܝܳܐ",
            lt12: "",
            dt2: "",
            sy9: ""
        },
        {
            lt11: "",
            dt3: "",
            sy8: "",
            sy7: "",
            id: "4170",
            sy4: "ܓܪܝܥܘܬܐ",
            sy5: "ܓܪܺܝܥܽܘܬ݂ܳܐ",
            dt1: "Sklaverei",
            lt12: "",
            lt10: "griĉutho",
            sy9: "",
            dt2: "",
            sy6: ""
        },
        {
            sy4: "ܥܒܕܐ",
            sy6: "",
            sy7: "",
            dt2: "",
            lt12: "",
            lt10: "ܥܒܕܐ",
            dt3: "",
            lt11: "ܥܒܕܐ",
            sy5: "ܥܰܒܕܳܐ",
            sy8: "",
            sy9: "",
            dt1: "Abdo",
            id: "68"
        },
        {
            sy8: "",
            dt1: "Heft",
            id: "1920",
            lt12: "",
            sy9: "",
            dt3: "",
            sy6: "",
            lt11: "",
            sy4: "ܟܪܟܐ",
            sy7: "",
            lt10: "kerko",
            dt2: "Schreibheft",
            sy5: "ܟܶܪܟܳܐ"
        },
        {
            sy6: "",
            lt12: "",
            sy7: "",
            sy9: "",
            sy8: "",
            lt10: "maye mqadshe",
            dt2: "",
            sy5: "ܡܰܝܳܐ ܡܩܰܕܫܳܐ",
            id: "5025",
            lt11: "",
            dt3: "",
            dt1: "Weihwasser",
            sy4: "ܡܝܐ ܡܩܕܫܐ"
        },
        {
            sy5: "ܠܶܒܝ ܪܳܚܡܳܐ ܕܺܝܠܝ",
            sy4: "ܠܶܒܝ ܪܳܚܡܳܐ ܕܺܝܠܝ",
            sy7: "",
            dt1: "Mein Herz mein Liebling",
            lt11: "",
            sy9: "",
            dt3: "",
            sy6: "",
            dt2: "",
            id: "5489",
            lt12: "",
            lt10: "lebi roĥmo dil",
            sy8: ""
        },
        {
            lt11: "",
            sy4: "ܫܘܚܠܦܐ",
            sy7: "",
            sy9: "",
            dt2: "Variation",
            dt3: "Wechsel",
            lt10: "shuĥlofo",
            sy5: "ܫܽܘܚܠܳܦܳܐ",
            lt12: "",
            sy6: "",
            dt1: "Austausch",
            id: "391",
            sy8: ""
        },
        {
            sy6: "",
            sy7: "",
            dt2: "",
            lt12: "",
            sy4: "ܪܡܫܐ ܚܓܢܝܐ",
            lt10: "ramsho ĥagonoyo",
            id: "1464",
            sy9: "",
            sy5: "ܪܰܡܫܳܐ ܚܰܓܳܢܳܝܳܐ",
            dt3: "",
            lt11: "",
            dt1: "Galaabend",
            sy8: ""
        },
        {
            lt12: "",
            dt2: "",
            sy9: "",
            dt1: "Angelschein",
            lt11: "",
            sy4: "ܬܚܘܝܬ ܨܝܕܐ ܕܢܘܢܐ",
            lt10: "taĥwith ŝaydo dnune",
            dt3: "",
            id: "5929",
            sy8: "",
            sy7: "",
            sy5: "ܬܰܚܘܺܝܬ݂ ܨܰܝܕܳܐ ܕܢܽܘܢܶܐ",
            sy6: ""
        },
        {
            sy5: "ܦܺܐܪ̈ܐ",
            lt12: "",
            dt3: "",
            dt2: "",
            lt10: "fire",
            id: "1413",
            sy9: "",
            sy8: "",
            sy4: "ܦܐܪܐ",
            sy6: "ܐܒܒܢܐ",
            dt1: "Früchte",
            sy7: "ܐܶܒܒ̈ܳܢܳܐ",
            lt11: "ebone"
        },
        {
            lt10: "ܐܒܪܐܣ",
            dt3: "",
            lt12: "ܒܪ ܐܒܪܐܣ",
            lt11: "ܐܒܪܐܣ",
            sy7: "ܒܪ ܐܒܪܐܣ",
            sy6: "ܒܪ ܐܒܪܐܣ",
            dt1: "Hebaeus",
            sy5: "ܐܒܪܐܣ",
            id: "1916",
            dt2: "Hebräus",
            sy8: "",
            sy4: "ܐܒܪܐܣ",
            sy9: ""
        },
        {
            dt2: "",
            lt12: "",
            sy4: "ܙܡܙܝܥܐ",
            dt1: "Schaukel",
            sy5: "ܙܰܡܙܺܝܥܳܐ",
            lt10: "zamziĉo",
            sy8: "",
            sy6: "ܙܡܙܝܩܐ",
            lt11: "zamziqo",
            sy9: "",
            id: "3912",
            dt3: "",
            sy7: "ܙܰܡܙܺܝܩܳܐ"
        },
        {
            dt1: "Reicher",
            sy5: "ܥܰܬܺܝܪܳܐ",
            sy4: "ܥܬܝܪܐ",
            lt12: "",
            sy6: "",
            lt10: "ĉatiro",
            id: "3669",
            lt11: "",
            sy9: "",
            sy7: "",
            dt2: "Vermögender",
            dt3: "",
            sy8: ""
        },
        {
            lt12: "",
            lt11: "",
            sy7: "",
            sy4: "ܟܐܦܐ",
            dt2: "",
            dt3: "",
            sy9: "",
            sy8: "",
            dt1: "Stein",
            sy6: "",
            lt10: "kifo",
            id: "4251",
            sy5: "ܟܺܐܦܳܐ"
        },
        {
            sy8: "",
            sy7: "",
            lt11: "",
            sy5: "ܡܰܪܕܽܘܬ݂ܳܢܳܝܳܐ",
            lt12: "",
            id: "2665",
            sy4: "ܡܪܕܘܬܢܝܐ",
            dt2: "",
            dt3: "",
            dt1: "kulturell",
            sy9: "",
            lt10: "marduthonoyo",
            sy6: ""
        },
        {
            sy6: "ܒܪ ܡܬ݂ܐ",
            sy7: "ܒܰܪ ܡܳܬ݂ܳܐ",
            lt11: "bar motho",
            sy4: "ܒܰܪ ܐܰܬ݂ܪܳܐ",
            sy5: "ܒܰܪ ܐܰܬ݂ܪܳܐ",
            lt10: "bar athro",
            dt1: "Staatsbürger",
            dt2: "",
            dt3: "",
            sy8: "",
            sy9: "",
            lt12: "",
            id: "4231"
        },
        {
            dt1: "Humanist",
            dt3: "",
            sy4: "ܐܢܫܝܐ",
            lt10: "noshoyo",
            id: "6022",
            sy5: "ܐ̱ܢܳܫܳܝܳܐ",
            sy9: "",
            dt2: "human",
            lt11: "",
            lt12: "",
            sy7: "",
            sy6: "",
            sy8: ""
        },
        {
            sy6: "ܚܪܝܬܐ",
            lt11: "ܚܪܝܐܬܐ",
            lt12: "ܚܪܝܬܐ",
            dt3: "",
            id: "1873",
            sy5: "ܚܰܪܝܰܐܬܰܐ",
            dt2: "",
            dt1: "Hariata",
            sy7: "ܚܰܪܰܝܬܰܐ",
            lt10: "ܚܪܝܐܬܐ",
            sy8: "",
            sy4: "ܚܪܝܐܬܐ",
            sy9: ""
        },
        {
            sy9: "",
            lt11: "",
            sy5: "ܟܰܠܒܳܐ ܡܥܰܩܒܳܢܳܐ",
            lt12: "",
            dt3: "",
            sy7: "",
            sy8: "",
            dt1: "Suchhund",
            sy6: "",
            sy4: "ܟܠܒܐ ܡܥܩܒܢܐ",
            lt10: "kalbo mĉaqbono",
            dt2: "Spürhund",
            id: "6242"
        },
        {
            sy5: "ܐܺܝܢ",
            lt11: "",
            id: "2260",
            sy9: "",
            lt12: "",
            lt10: "in",
            dt2: "",
            dt1: "ja",
            sy7: "",
            dt3: "",
            sy4: "ܐܝܢ",
            sy6: "",
            sy8: ""
        },
        {
            dt2: "kurzerhand",
            sy7: "",
            sy6: "",
            sy4: "ܩܦܝܣܐܝܬ",
            lt11: "",
            lt12: "",
            dt3: "",
            dt1: "kurzum",
            sy5: "ܩܦܺܝܣܳܐܝܺܬ݂",
            id: "2678",
            sy9: "",
            lt10: "qfisoith",
            sy8: ""
        },
        {
            id: "3524",
            lt11: "",
            sy4: "ܢܒܝܬܐ",
            sy7: "",
            dt1: "Prophetin",
            sy8: "",
            dt3: "",
            sy6: "",
            sy9: "",
            lt10: "nbitho",
            dt2: "",
            sy5: "ܢܒܺܝܬ݂ܳܐ",
            lt12: ""
        },
        {
            dt1: "Teig",
            sy7: "",
            sy4: "ܠܝܫܐ",
            id: "4422",
            lt10: "laysho",
            sy6: "",
            sy5: "ܠܰܝܫܳܐ",
            lt11: "",
            dt2: "",
            sy8: "",
            dt3: "",
            sy9: "",
            lt12: ""
        },
        {
            lt10: "ebone",
            lt11: "",
            sy5: "ܐܶܒܳܒܢ̈ܶܐ",
            sy8: "",
            id: "3334",
            dt2: "",
            sy9: "",
            lt12: "",
            dt1: "Obst",
            dt3: "",
            sy7: "",
            sy6: "",
            sy4: "ܐܒܒܢܐ"
        },
        {
            lt11: "",
            lt12: "",
            dt2: "",
            dt3: "",
            dt1: "Ich bin ein Lehrer",
            "date": {
                "seconds": 1591015758,
                "nanoseconds": 375000000
            },
            sy7: "",
            sy4: "ܡܠܦܢܐ ܢܐ",
            sy5: "ܡܰܠܦܳܢܳܐ ܢܳܐ",
            lt10: "Malfono no",
            id: "ZIWwkkniaxNTn0VN3i2L",
            sy9: "",
            sy6: "",
            sy8: ""
        },
        {
            sy5: "ܐܶܨܛܠܶܒ ܚܠܳܦܰܝܢ ܒܝܰܘܡ̈ܰܝ ܦܶܢܛܝܽܘܣ ܦܺܝܠܰܛܘܽܣ",
            sy9: "",
            dt1: "Für uns gekreuzigt unter Pentius Pilatus",
            sy8: "",
            id: "1429",
            lt10: "eŝțleb ĥlofayn byaumay fentyos filatos",
            lt11: "",
            dt2: "",
            sy4: "ܐܨܛܠܒ ܚܠܦܝܢ ܒܝܘܡܝ ܦܢܛܝܘܣ ܦܝܠܛܘܣ",
            lt12: "",
            sy7: "",
            dt3: "",
            sy6: ""
        },
        {
            dt1: "Urlaubszeit",
            sy4: "ܙܒܢܐ ܕܒܛܠܢܐ",
            lt10: "zabno d`bețlono",
            dt3: "",
            id: "4694",
            dt2: "",
            lt12: "",
            sy8: "",
            sy9: "",
            lt11: "",
            sy6: "",
            sy7: "",
            sy5: "ܙܰܒܢܳܐ ܕܒܶܛܠܳܢܳܐ"
        },
        {
            lt12: "",
            sy4: "ܟܠܒܐ ܢܒܚ",
            lt11: "",
            dt3: "",
            id: "2070",
            sy8: "",
            dt2: "bellender Hund",
            lt10: "kalbo nobaĥ",
            sy9: "",
            sy7: "",
            dt1: "Hund bellt",
            sy5: "ܟܰܠܒܳܐ ܢܳܒܰܚ",
            sy6: ""
        },
        {
            lt10: "methyo",
            sy8: "ܐܬܐ",
            lt12: "othe",
            sy6: "ܡܐܬܝܬܐ",
            sy7: "ܡܶܐܬ݂ܺܝܬ݂ܳܐ",
            sy4: "ܡܐܬܝܐ",
            dt3: "",
            id: "2568",
            dt1: "Kommen",
            sy9: "ܐܳܬ݂ܶܐ",
            lt11: "methitho",
            sy5: "ܡܶܐܬ݂ܝܳܐ",
            dt2: "Ankunft"
        },
        {
            dt3: "",
            sy4: "ܚܛܐ",
            sy8: "",
            sy6: "",
            sy7: "",
            lt11: "",
            lt12: "",
            dt1: "Weizen",
            id: "5040",
            sy9: "",
            dt2: "",
            sy5: "ܚܶܛܳܐ",
            lt10: "ĥețo"
        },
        {
            sy9: "",
            sy6: "ܫܠܝܚܐ ܓܒܝܐ",
            dt2: "Ausgewählte Apostel",
            sy5: "ܫܠܺܝܚ̈ܐ ܓܒܰܝ̈ܳܐ",
            dt3: "",
            sy4: "ܫܠܝܚܐ ܓܒܝܐ",
            sy8: "",
            sy7: "ܫܠܺܝܚ̈ܶܐ ܓܒܰܝ̈ܶܐ",
            lt10: "shliĥe gbayo",
            dt1: "Auserwählte Apostel",
            lt11: "shliĥe gbaye",
            id: "375",
            lt12: ""
        },
        {
            sy4: "ܝܘܬܪܢܝܐ",
            sy8: "",
            dt1: "gewinnbringend",
            sy5: "ܝܽܘܬ݂ܪܳܢܳܝܳܐ",
            dt2: "",
            id: "1625",
            lt11: "",
            sy7: "",
            sy6: "",
            sy9: "",
            dt3: "",
            lt10: "yuthronoyo",
            lt12: ""
        },
        {
            sy6: "",
            lt11: "",
            dt1: "Psalm",
            id: "3535",
            lt10: "mazmuro",
            lt12: "",
            sy7: "",
            sy9: "",
            sy4: "ܡܙܡܘܪܐ",
            dt3: "",
            sy8: "",
            sy5: "ܡܰܙܡܽܘܪܳܐ",
            dt2: ""
        },
        {
            lt11: "",
            sy8: "",
            dt3: "",
            sy7: "",
            sy6: "",
            sy5: "ܠܳܐ ܡܦܳܣܽܘܬܳܐ",
            lt12: "",
            sy9: "",
            lt10: "lo mfosutho",
            dt2: "",
            dt1: "Tabu",
            sy4: "ܠܐ ܡܦܣܘܬܐ",
            id: "4357"
        },
        {
            id: "3230",
            lt11: "",
            sy6: "",
            sy7: "",
            sy8: "",
            dt2: "trübe",
            dt3: "getrübt",
            lt12: "",
            sy4: "ܥܪܦܠܝܐ",
            lt10: "ĉarfeloyo",
            sy5: "ܥܰܪܦܶܠܳܝܳܐ",
            dt1: "neblig",
            sy9: ""
        },
        {
            sy7: "",
            sy5: "ܚܰܙܩܺܐܝܶܠ",
            dt2: "",
            lt12: "",
            lt11: "ܚܙܩܐܝܠ",
            id: "1881",
            sy8: "",
            sy6: "",
            lt10: "ܚܙܩܐܝܠ",
            sy9: "",
            dt1: "Haskiel",
            sy4: "ܚܙܩܐܝܠ",
            dt3: ""
        },
        {
            sy9: "",
            sy4: "ܛܒܠܝܬܐ",
            lt11: "",
            dt3: "",
            sy6: "",
            sy5: "ܛܶܒܠܺܝܬ݂ܳܐ",
            lt10: "țeblitho",
            sy7: "",
            dt1: "Tisch",
            id: "4497",
            sy8: "",
            lt12: "",
            dt2: ""
        },
        {
            id: "3244",
            sy8: "",
            dt1: "Neriglissar",
            lt12: "",
            sy5: "ܢܶܪܺܝܓܠܺܝܣܰܪ",
            lt10: "ܢܪܝܓܠܝܣܪ",
            sy6: "",
            dt2: "",
            lt11: "ܢܪܝܓܠܝܣܪ",
            sy7: "",
            sy9: "",
            sy4: "ܢܪܝܓܠܝܣܪ",
            dt3: ""
        },
        {
            dt2: "",
            dt1: "Kompliment",
            sy9: "",
            lt12: "",
            sy6: "",
            lt11: "",
            sy5: "ܩܽܘܠܳܣܳܐ",
            sy4: "ܩܘܠܣܐ",
            sy7: "",
            sy8: "",
            lt10: "quloso",
            dt3: "",
            id: "2574"
        },
        {
            dt3: "",
            sy7: "",
            sy6: "",
            lt10: "Hab lan yaumon ĥulmono ĥubo idaĉtho niĥutho u ĥaylo",
            lt12: "",
            dt1: "Schenke uns heute Gesundheit Liebe Weisheit Gelassenheit und Kraft",
            sy4: "ܗܒ ܠܢ ܝܘܡܢ ܚܘܠܡܢܐ ܚܘܒܐ ܐܝܕܥܬܐ ܢܝܚܘܬܐ ܘܚܝܠܐ",
            dt2: "",
            id: "5597",
            lt11: "",
            sy8: "",
            sy5: "ܗܰܒ ܠܰܢ ܝܰܘܡܳܢ ܚܽܘܠܡܳܢܳܐ ܚܽܘܒܳܐ ܐܺܝܕܰܥܬ݂ܳܐ ܢܺܝܚܽܘܬ݂ܳܐ ܘܚܰܝܠܳܐ",
            sy9: ""
        },
        {
            dt2: "",
            lt11: "",
            dt3: "",
            lt12: "",
            sy6: "",
            sy4: "ܚܘܒܐ ܫܪܝܪܬܐ",
            sy8: "",
            sy7: "",
            dt1: "wahre Liebe",
            id: "4952",
            sy9: "",
            lt10: "ĥubo sharirto",
            sy5: "ܚܽܘܒܳܐ ܫܰܪܺܝܪܬܳܐ"
        },
        {
            sy9: "",
            sy6: "",
            dt2: "",
            sy8: "",
            sy7: "",
            lt12: "",
            dt3: "",
            lt10: "ܫܡܪ",
            lt11: "ܫܡܪ",
            id: "3891",
            dt1: "Schamar",
            sy5: "ܫܰܡܰܪ",
            sy4: "ܫܡܪ"
        },
        {
            sy9: "",
            sy5: "ܙܰܗܺܝܬܰܐ",
            lt12: "",
            sy8: "",
            sy4: "ܙܗܝܬܐ",
            sy7: "",
            sy6: "",
            lt10: "ܙܗܝܬܐ",
            id: "5222",
            dt1: "Zahita",
            lt11: "ܙܗܝܬܐ",
            dt3: "",
            dt2: ""
        },
        {
            dt3: "Mitleid",
            sy8: "",
            sy7: "ܪܰܚܡܶܐ",
            lt11: "raĥme",
            sy9: "",
            dt2: "Mitgefühl",
            id: "461",
            sy6: "ܪܚܡܐ",
            lt12: "",
            dt1: "Barmherzigkeit",
            sy4: "ܡܪܚܡܢܘܬܐ",
            lt10: "mraĥmonutho",
            sy5: "ܡܪܰܚܡܳܢܽܘܬ݂ܳܐ"
        },
        {
            id: "4176",
            lt12: "",
            sy4: "ܐܡܝܢ",
            sy7: "",
            dt3: "",
            lt11: "",
            sy9: "",
            lt10: "amin",
            sy8: "",
            sy5: "ܐܰܡܺܝܢ",
            dt1: "So sei es",
            dt2: "wahrlich",
            sy6: ""
        },
        {
            lt10: "zmirto umthonoyto",
            sy6: "",
            sy9: "",
            lt11: "",
            dt3: "",
            dt2: "",
            lt12: "",
            sy8: "",
            sy5: "ܙܡܺܝܪܬܳܐ ܐܽܘܡܬ݂ܢܳܝܬܳܐ",
            dt1: "Volkslied",
            sy4: "ܙܡܝܪܬܐ ܐܘܡܬܢܝܬܐ",
            sy7: "",
            id: "4857"
        },
        {
            dt2: "",
            sy8: "ܐܩܝܡ",
            dt1: "agitieren",
            sy9: "ܐܰܩܺܝܡ",
            sy6: "ܒܠܒܠ",
            sy7: "ܒܰܠܒܶܠ",
            id: "5782",
            lt11: "balbel",
            lt10: "ĥabekh",
            sy4: "ܚܒܟ",
            lt12: "aqim",
            sy5: "ܚܰܒܶܟ݂",
            dt3: ""
        },
        {
            lt12: "",
            sy5: "ܟܰܪܺܝܡ",
            sy9: "",
            sy8: "",
            id: "2413",
            dt1: "Karim",
            lt11: "ܟܪܝܡ",
            lt10: "ܟܪܝܡ",
            sy6: "",
            sy7: "",
            dt2: "",
            dt3: "",
            sy4: "ܟܪܝܡ"
        },
        {
            lt11: "",
            lt10: "shulțon nafsho",
            dt1: "Selbstständigkeit",
            sy4: "ܫܘܠܛܢ ܢܦܫܐ",
            sy5: "ܫܽܘܠܳܛܳܢ ܢܰܦܫܳܐ",
            sy7: "",
            sy6: "",
            dt3: "",
            dt2: "",
            sy9: "",
            lt12: "",
            id: "4110",
            sy8: ""
        },
        {
            sy7: "ܐܰܣܝܳܐ ܕܫܰܒ̈ܪܳܐ",
            id: "2475",
            lt12: "",
            sy8: "",
            sy5: "ܐܳܣܝܳܐ ܛܠܳܝ̈ܶܐ",
            lt10: "osyo d`țloye",
            sy4: "ܐܣܝܐ ܕܛܠܝܐ",
            sy9: "",
            dt1: "Kinderarzt",
            lt11: "osyo d`shabre",
            dt2: "",
            sy6: "ܐܣܝܐ ܕܫܒܪܐ",
            dt3: ""
        },
        {
            sy7: "ܚܽܘܠܳܦܳܐ",
            sy8: "",
            lt10: "mshaĥlfutho",
            dt3: "",
            sy5: "ܡܫܰܚܠܦܽܘܬ݂ܳܐ",
            sy6: "ܚܘܠܦܐ",
            dt2: "Vielfalt",
            id: "5015",
            lt11: "ĥulofo",
            lt12: "",
            dt1: "Wechsel",
            sy4: "ܡܫܚܠܦܘܬܐ",
            sy9: ""
        },
        {
            dt2: "",
            lt10: "shoĉath soĉurutho",
            lt11: "",
            sy6: "",
            id: "580",
            sy5: "ܫܳܥܰܬ݂ ܣܳܥܽܘܪܽܘܬ݂ܳܐ",
            sy7: "",
            lt12: "",
            sy4: "ܫܥܬ ܣܥܘܪܘܬܐ",
            sy9: "",
            dt3: "",
            dt1: "Besuchsstunde",
            sy8: ""
        },
        {
            lt10: "seğedtho",
            sy9: "",
            lt12: "",
            sy4: "ܣܓܕܬܐ",
            id: "225",
            dt2: "",
            dt1: "Anbetung",
            sy7: "",
            sy8: "",
            sy6: "",
            sy5: "ܣܶܓ݂ܕܬ݂ܳܐ",
            lt11: "",
            dt3: ""
        },
        {
            sy9: "",
            sy6: "",
            dt1: "Blutfluss",
            sy4: "ܕܘܡܕܡܐ",
            dt2: "",
            sy5: "ܕܽܘܡܕܳܡܳܐ",
            dt3: "",
            id: "663",
            lt12: "",
            lt11: "",
            sy7: "",
            sy8: "",
            lt10: "dumdomo"
        },
        {
            dt1: "15",
            sy7: "ܚܡܫܰܥܶܣ̈ܪܶܐ",
            sy4: "ܚܡܫܥܣܪ",
            lt11: "ĥamshaĉesre",
            sy6: "ܚܡܫܥܣܪܐ",
            id: "16",
            dt2: "Fünfzehn",
            dt3: "",
            sy9: "ܝܗ",
            lt10: "ĥamshaĉsar",
            sy5: "ܚܰܡܫܰܥܣܰܪ",
            sy8: "ܝܗ",
            lt12: "juth-he"
        },
        {
            lt10: "mautbo",
            sy5: "ܡܰܘܬܒܳܐ",
            sy7: "",
            dt1: "Sitz",
            lt11: "",
            lt12: "",
            sy8: "",
            sy9: "",
            dt2: "Bank",
            id: "4164",
            sy6: "",
            dt3: "",
            sy4: "ܡܘܬܒܐ"
        },
        {
            dt3: "",
            dt1: "Nazarener",
            id: "3226",
            sy8: "",
            lt12: "",
            sy9: "",
            dt2: "",
            sy7: "",
            sy4: "ܢܨܪܝܐ",
            sy5: "ܢܳܨܪܳܝܳܐ",
            sy6: "",
            lt11: "",
            lt10: "noŝroyo"
        },
        {
            id: "3534",
            dt2: "Schlagen",
            sy8: "",
            sy7: "",
            lt11: "",
            sy9: "",
            lt12: "",
            lt10: "mĥoyo",
            sy5: "ܡܚܳܝܳܐ",
            sy4: "ܡܚܝܐ",
            dt1: "Prügel",
            dt3: "",
            sy6: ""
        },
        {
            lt12: "",
            sy4: "ܚܒܪܐ",
            sy7: "",
            sy9: "",
            dt1: "Freund",
            dt2: "",
            id: "1392",
            lt10: "ĥabro",
            sy5: "ܚܰܒܪܳܐ",
            lt11: "",
            dt3: "",
            sy8: "",
            sy6: ""
        },
        {
            dt2: "",
            sy6: "",
            id: "86",
            dt1: "abgemessen",
            sy8: "",
            lt11: "",
            sy5: "ܡܟܺܝܠܳܢܳܐ",
            sy7: "",
            lt10: "mkilono",
            dt3: "",
            sy4: "ܡܟܝܠܢܐ",
            lt12: "",
            sy9: ""
        },
        {
            sy5: "ܠܰܝܬ ܥܶܕܠܳܐ",
            sy9: "",
            sy6: "",
            sy4: "ܠܝܬ ܥܕܠܐ",
            dt2: "macht nichts",
            lt12: "",
            lt11: "",
            lt10: "layt ĉedlo",
            id: "1184",
            sy7: "",
            dt1: "es macht nichts",
            sy8: "",
            dt3: ""
        },
        {
            sy6: "",
            dt1: "Naher Osten",
            dt3: "",
            sy5: "ܡܰܕܢܚܳܐ ܩܰܪܺܝܒܳܐ",
            lt11: "",
            lt12: "",
            sy7: "",
            id: "3181",
            sy8: "",
            lt10: "madnĥo qaribo",
            sy9: "",
            dt2: "",
            sy4: "ܡܕܢܚܐ ܩܪܝܒܐ"
        },
        {
            sy8: "",
            sy6: "",
            sy5: "ܓܶܕܫܳܐ",
            lt10: "gedsho",
            sy4: "ܓܕܫܐ",
            dt2: "Vorfall",
            lt12: "",
            sy7: "",
            lt11: "",
            dt1: "Ereignis",
            id: "1143",
            sy9: "",
            dt3: ""
        },
        {
            lt10: "kafino",
            id: "2075",
            sy6: "",
            sy8: "",
            lt11: "",
            dt3: "",
            sy5: "ܟܰܦܺܝܢܳܐ",
            dt1: "Hungernder",
            lt12: "",
            sy4: "ܟܦܝܢܐ",
            sy9: "",
            dt2: "hungrig",
            sy7: ""
        },
        {
            lt10: "msadronitho",
            sy5: "ܡܣܰܕܪܳܢܺܝܬ݂ܳܐ",
            sy4: "ܡܣܕܪܢܝܬܐ",
            lt11: "",
            lt12: "",
            id: "3386",
            dt3: "",
            sy8: "",
            sy7: "",
            dt1: "Ordner",
            dt2: "Mappe",
            sy6: "",
            sy9: ""
        },
        {
            sy7: "",
            lt11: "",
            dt2: "",
            dt1: "Identifikation",
            sy6: "",
            lt10: "tahwith hiyutho",
            sy4: "ܬܚܘܝܬ ܗܝܝܘܬܐ",
            lt12: "",
            dt3: "",
            sy9: "",
            sy5: "ܬܰܚܘܺܝܬ݂ ܗܺܝܝܽܘܬ݂ܳܐ",
            id: "2147",
            sy8: ""
        },
        {
            sy6: "",
            lt12: "",
            sy7: "",
            dt1: "Nazar",
            sy9: "",
            dt3: "",
            sy8: "",
            id: "3225",
            lt11: "ܢܙܪ",
            sy5: "ܢܰܙܰܪ",
            sy4: "ܢܙܪ",
            lt10: "ܢܙܪ",
            dt2: "Nassar"
        },
        {
            sy6: "",
            sy5: "ܟܰܪܟܕܳܢܳܐ",
            sy4: "ܟܪܟܕܢܐ",
            dt1: "Nashorn",
            dt3: "",
            sy8: "",
            sy7: "",
            sy9: "",
            lt12: "",
            lt11: "",
            lt10: "karkđono",
            id: "3209",
            dt2: ""
        },
        {
            sy9: "",
            sy7: "ܟܶܐܦܰܐ",
            lt10: "ܟܐܦܐ",
            sy6: "ܟܐܦܐ",
            lt12: "ܟܐܦܐ",
            dt3: "Kifo",
            sy4: "ܟܐܦܐ",
            sy8: "",
            id: "2456",
            dt1: "Kepha",
            sy5: "ܟܺܐܦܳܐ",
            lt11: "ܟܐܦܐ",
            dt2: "Kipho"
        },
        {
            sy9: "",
            dt2: "",
            sy5: "ܝܰܬܺܝܪ ܙܥܽܘܪ",
            sy6: "",
            dt3: "",
            sy7: "",
            lt11: "",
            dt1: "kleiner",
            sy4: "ܝܬܝܪ ܙܥܘܪ",
            lt12: "",
            id: "2513",
            sy8: "",
            lt10: "yatir sĉur"
        },
        {
            sy7: "",
            sy5: "ܡܡܰܬ݂ܠܳܢܽܘܬ݂ܳܐ",
            lt12: "",
            sy9: "",
            dt2: "",
            sy6: "",
            id: "5777",
            sy8: "",
            lt10: "mmathlonutho",
            dt1: "Agentur",
            dt3: "",
            sy4: "ܡܡܬܠܢܘܬܐ",
            lt11: ""
        },
        {
            sy9: "",
            lt11: "",
            dt3: "",
            id: "3027",
            sy4: "ܥܪܫܐ ܕܚܠܒܐ",
            sy6: "",
            dt2: "",
            lt10: "ĉarsho d`ĥalbo",
            sy5: "ܥܰܪܫܳܐ ܕܚܰܠܒܳܐ",
            sy7: "",
            lt12: "",
            dt1: "Milchzahn",
            sy8: ""
        },
        {
            sy4: "ܙܡܪܬܐ",
            dt3: "",
            id: "3839",
            sy8: "",
            sy6: "",
            lt12: "",
            dt2: "",
            lt10: "zamorto",
            sy9: "",
            lt11: "",
            sy5: "ܙܰܡܳܪܬܳܐ",
            sy7: "",
            dt1: "Sängerin"
        },
        {
            lt11: "ܪܐܙܐ",
            dt1: "Raza",
            id: "3628",
            dt2: "",
            sy6: "",
            sy4: "ܪܐܙܐ",
            lt12: "",
            sy9: "",
            sy7: "",
            dt3: "",
            sy5: "ܪܰܐܙܰܐ",
            sy8: "",
            lt10: "ܪܐܙܐ"
        },
        {
            id: "1780",
            dt3: "",
            lt11: "",
            sy5: "ܚ",
            lt10: "ĥeth",
            dt1: "ĥ",
            dt2: "",
            lt12: "",
            sy4: "ܚ",
            sy8: "",
            sy9: "",
            sy7: "",
            sy6: ""
        },
        {
            sy9: "",
            sy6: "ܫܠܐ",
            dt2: "",
            sy7: "ܫܠܳܐ",
            lt11: "shlo",
            lt10: "bațel",
            id: "60",
            sy4: "ܒܛܠ",
            sy5: "ܒܰܛܶܠ",
            dt3: "",
            dt1: "abbrechen",
            lt12: "",
            sy8: ""
        },
        {
            sy6: "ܐܝܟܢ ܐܝܬܗܝܢ ܗܢܝܢ",
            dt3: "",
            sy5: "ܐܰܝܟܰܢ ܐܺܝܬ݂ܰܝܗܽܘܢ ܗܶܢܽܘܢ",
            sy9: "ܐܰܝܟܰܢܳܐ",
            sy7: "ܐܰܝܟܰܢ ܐܺܝܬ݂ܰܗܶܝܢ ܗܶܢܶܝܢ",
            dt1: "wie geht’s ihnen",
            lt10: "aykan ithayhun henun",
            id: "5088",
            lt11: "aykan ithayhen henen",
            lt12: "aykano",
            sy8: "ܐܝܟܢܐ",
            dt2: "wie geht es ihnen",
            sy4: "ܐܝܟܢ ܐܝܬܝܗܘܢ ܗܢܘܢ"
        },
        {
            sy4: "ܩܛܝܪܬܢܝܐ",
            sy8: "",
            sy7: "",
            dt2: "",
            sy9: "",
            sy5: "ܩܛܺܝܪܬ݂ܳܢܳܝܳܐ",
            id: "4783",
            dt3: "",
            sy6: "",
            dt1: "verpflichtet",
            lt10: "qțirthonoyo",
            lt11: "",
            lt12: ""
        },
        {
            lt10: "leshono rushmoyo",
            id: "3350",
            sy4: "ܠܫܢܐ ܪܘܫܡܝܐ ",
            sy8: "",
            lt12: "",
            dt2: "",
            sy9: "",
            lt11: "",
            dt3: "",
            dt1: "offizielle Sprache",
            sy6: "",
            sy5: "ܠܶܫܳܢܳܐ ܪܽܘܫܡܳܝܳܐ ",
            sy7: ""
        },
        {
            dt2: "",
            sy6: "",
            sy4: "ܒܪܝܡܝܢ",
            dt3: "",
            lt12: "",
            sy5: "ܒܰܪܝܰܡܺܝܢ",
            sy9: "",
            lt10: "ܒܪܝܡܝܢ",
            dt1: "Baryamin",
            sy7: "",
            id: "485",
            lt11: "ܒܪܝܡܝܢ",
            sy8: ""
        },
        {
            dt1: "Mschablono",
            lt12: "ܡܫܒܠܢ",
            id: "3117",
            sy7: "ܡܫܰܒܠܳܢ",
            sy4: "ܡܫܒܠܢܐ",
            lt10: "ܡܫܒܠܢܐ",
            sy5: "ܡܫܰܒܠܳܢܳܐ",
            sy8: "",
            lt11: "ܡܫܒܠܢܐ",
            sy9: "",
            dt3: "",
            sy6: "ܡܫܒܠܢ",
            dt2: ""
        },
        {
            sy7: "ܩܽܘܒܽܘܣ",
            sy4: "ܦܣܦܣܐ",
            lt10: "fesfso",
            lt11: "qubus",
            sy5: "ܦܶܣܦܣܳܐ",
            sy9: "",
            lt12: "",
            sy8: "",
            dt1: "Würfel",
            dt2: "Spielwürfel",
            id: "5178",
            sy6: "ܩܘܒܘܣ",
            dt3: "Kubus"
        },
        {
            dt1: "Du bist der einzige für mich",
            dt2: "",
            sy7: "",
            sy9: "",
            sy5: "ܠܚܽܘܕ ܐܰܢ̱ܬ ܐܺܝܬ݂ܰܝܟ ܠܺܝ",
            lt11: "",
            id: "966",
            lt10: "lĥud at iythayk li",
            sy4: "ܠܚܘܕ ܐܢܬ ܐܝܬܝܟ ܠܝ",
            sy6: "",
            dt3: "",
            lt12: "",
            sy8: ""
        },
        {
            dt2: "",
            lt11: "esoro",
            lt10: "neqfoyo",
            lt12: "ĉal meltho",
            sy5: "ܢܶܩܦܳܝܳܐ",
            sy9: "ܥܰܠ ܡܶܠܬ݂ܳܐ",
            sy6: "ܐܣܪܐ",
            sy7: "ܐܶܣܳܪܳܐ",
            id: "5787",
            dt3: "",
            dt1: "Adverb",
            sy4: "ܢܩܦܝܐ",
            sy8: "ܥܠ ܡܠܬܐ"
        },
        {
            lt10: "beth gauso",
            sy8: "",
            sy5: "ܒܶܝܬ݂ ܓܰܘܣܳܐ",
            lt12: "",
            dt1: "Zuflucht",
            dt3: "Zufluchtsstätte",
            sy4: "ܒܝܬ ܓܘܣܐ",
            dt2: "Herberge",
            id: "5358",
            sy6: "",
            sy7: "",
            sy9: "",
            lt11: ""
        },
        {
            sy7: "ܥܕܰܡܳܐ ܠܗܳܫܳܐ",
            sy5: "ܥܕܰܟܺܝܠ",
            dt2: "",
            sy6: "ܥܕܡܐ ܠܗܫܐ",
            lt11: "ĉđamo l`hosho",
            sy9: "",
            id: "630",
            sy4: "ܥܕܟܝܠ",
            lt12: "",
            sy8: "",
            dt1: "bis jetzt",
            lt10: "ĉđakil",
            dt3: ""
        },
        {
            sy7: "",
            id: "4130",
            lt10: "teb ĉal kursyo",
            lt11: "",
            sy6: "",
            dt3: "",
            sy9: "",
            dt1: "setze dich auf den Stuhl",
            sy4: "ܬܒ ܥܠ ܟܘܪܣܝܐ",
            sy8: "",
            sy5: "ܬܶܒ ܥܰܠ ܟܽܘܪܣܝܳܐ",
            lt12: "",
            dt2: ""
        },
        {
            lt11: "",
            sy5: "ܩܪܺܝ ܒܩܳܠܳܐ ܪܳܡܳܐ",
            sy9: "",
            sy8: "",
            id: "2764",
            lt10: "qri b`qolo romo",
            lt12: "",
            dt2: "",
            sy6: "",
            sy7: "",
            sy4: "ܩܪܝ ܒܩܠܐ ܪܡܐ",
            dt3: "",
            dt1: "lese mit lauter Stimme"
        },
        {
            sy4: "ܐܬܓܥܠ",
            sy9: "",
            id: "2408",
            sy5: "ܐܶܬ݂ܓܥܶܠ",
            dt1: "kapitulieren",
            lt12: "",
            sy7: "",
            lt11: "",
            sy6: "",
            sy8: "",
            dt2: "überliefern",
            lt10: "ethgĉel",
            dt3: ""
        },
        {
            sy4: "ܬܠܝܬܝܘܬܐ",
            sy7: "",
            dt1: "Dreieinigkeit",
            sy6: "",
            dt2: "Trinität",
            sy5: "ܬܠܺܝܬ݂ܳܝܽܘܬ݂ܳܐ",
            dt3: "Dreifaltigkeit",
            lt11: "",
            lt12: "",
            sy8: "",
            lt10: "tlithoyutho",
            sy9: "",
            id: "958"
        },
        {
            dt1: "städtisch",
            dt2: "",
            sy6: "",
            lt12: "",
            lt10: "mđinoyo",
            sy8: "",
            lt11: "",
            sy4: "ܡܕܝܢܝܐ",
            sy7: "",
            id: "6065",
            dt3: "",
            sy5: "ܡܕܺܝܢܳܝܳܐ",
            sy9: ""
        },
        {
            sy7: "",
            sy8: "",
            lt12: "",
            sy6: "",
            dt1: "Esstisch",
            id: "1196",
            sy4: "ܦܬܘܪܐ",
            lt11: "",
            sy9: "",
            sy5: "ܦܳܬ݂ܽܘܪܳܐ",
            dt3: "",
            lt10: "fothuro",
            dt2: "Gedeckter Tisch"
        },
        {
            dt1: "Bekenner",
            sy6: "ܡܘܕܝܢܘܬܐ",
            lt10: "maudyono",
            sy7: "ܡܰܘܕܳܝܢܽܘܬ݂ܳܐ",
            sy4: "ܡܘܕܝܢܐ",
            dt3: "Bekennung",
            lt12: "",
            dt2: "Bekenntnis",
            sy8: "",
            sy5: "ܡܰܘܕܳܝܢܳܐ",
            id: "537",
            lt11: "maudyonutho",
            sy9: ""
        },
        {
            dt2: "untersuchen",
            lt12: "fatesh",
            lt10: "baqar",
            dt1: "inspizieren",
            sy8: "ܦܬܫ",
            sy7: "ܥܰܩܶܒ",
            sy9: "ܦܰܬܶܫ",
            id: "2215",
            sy5: "ܒܰܩܰܪ",
            lt11: "ĉaqeb",
            sy4: "ܒܩܪ",
            dt3: "",
            sy6: "ܥܩܒ"
        },
        {
            dt3: "",
            sy4: "ܪܝܫ ܫܬܐ",
            sy9: "ܫܰܢ̱ܬܳܐ ܚܰܕܬܳܐ",
            lt12: "shato ĥađto",
            id: "3254",
            dt1: "Neujahr",
            sy5: "ܪܺܝܫ ܫܰܢ̱ܬܳܐ",
            sy6: "ܫܢܬܐ ܚܕܬܐ",
            sy7: "ܫܰܢ̱ܬܳܐ ܚܕܰܬܳܐ",
            lt10: "rish shato",
            sy8: "ܫܢܬܐ ܚܕܬܐ",
            lt11: "shato ĥđato",
            dt2: ""
        },
        {
            dt3: "Desinteresse",
            dt1: "Apathie",
            sy6: "",
            id: "6009",
            sy9: "",
            lt11: "",
            dt2: "Gleichgültigkeit",
            sy8: "",
            lt10: "tanibutho",
            lt12: "",
            sy5: "ܬܰܢܺܝܒܽܘܬ݂ܳܐ",
            sy7: "",
            sy4: "ܬܢܝܒܘܬܐ"
        },
        {
            id: "883",
            lt11: "",
            sy8: "",
            sy9: "",
            sy6: "",
            dt3: "",
            sy5: "ܛܶܟܣܳܐ ܕܩܽܘܪܳܒܳܐ ܐܰܠܳܗܳܝܳܐ",
            dt1: "Die Feier der heiligen Eucharistie",
            sy7: "",
            lt12: "",
            dt2: "",
            sy4: "ܛܟܣܐ ܕܩܘܪܒܐ ܐܠܗܝܐ",
            lt10: "țekso d`qurobo alohoyo"
        },
        {
            lt10: "mĉaqbonutho",
            sy4: "ܡܥܩܒܢܘܬܐ",
            sy5: "ܡܥܰܩܒܳܢܽܘܬ݂ܳܐ",
            dt1: "Nachforschung",
            sy8: "",
            sy9: "",
            dt3: "",
            sy6: "ܡܒܚܢܢܘܬܐ",
            lt12: "",
            dt2: "",
            sy7: "ܡܒܰܚܢܳܢܽܘܬ݂ܳܐ",
            lt11: "mbaĥnonutho",
            id: "3160"
        },
        {
            id: "5212",
            sy6: "",
            dt2: "Jonan",
            sy8: "",
            lt10: "ܝܘܢܢ",
            sy4: "ܝܘܢܢ",
            lt11: "ܝܘܢܢ",
            sy9: "",
            dt1: "Yonan",
            dt3: "",
            sy7: "",
            lt12: "",
            sy5: "ܝܳܘܢܳܢ"
        },
        {
            sy6: "",
            dt1: "Raubkatze",
            lt10: "shunoro",
            lt12: "",
            dt2: "",
            lt11: "",
            id: "6138",
            sy8: "",
            dt3: "",
            sy4: "ܫܘܢܪܐ",
            sy7: "",
            sy9: "",
            sy5: "ܫܽܘܢܳܪܳܐ"
        },
        {
            sy4: "ܐܘܡܢܝܐ",
            sy5: "ܐܽܘܡܳܢܳܝܳܐ",
            id: "559",
            dt1: "beruflich",
            sy8: "",
            dt3: "",
            lt12: "",
            sy6: "ܐܘܡܢܐܝܬ",
            dt2: "",
            lt11: "umonoith",
            sy7: "ܐܽܘܡܳܢܳܐܝܺܬ݂",
            sy9: "",
            lt10: "umonoyo"
        },
        {
            lt12: "",
            dt1: "Problem",
            id: "3518",
            sy9: "",
            sy4: "ܩܛܪܐ",
            sy8: "",
            dt3: "",
            sy5: "ܩܶܛܪܳܐ",
            sy6: "",
            lt11: "",
            lt10: "qețro",
            dt2: "Knoten",
            sy7: ""
        },
        {
            sy6: "",
            sy7: "",
            lt10: "maudyonutho d`baĥțohe",
            id: "4045",
            dt2: "",
            lt11: "",
            dt1: "Schuldbekenntnis",
            lt12: "",
            sy5: "ܡܰܘܕܝܳܢܽܘܬ݂ܳܐ ܕܒܰܚ̈ܛܳܗܶܐ",
            dt3: "",
            sy9: "",
            sy4: "ܡܘܕܝܢܘܬܐ ܕܒܚܛܗܐ",
            sy8: ""
        },
        {
            dt2: "",
            sy5: "ܫܰܘܬܳܦܽܘܬ݂ܳܐ ܫܰܢ̱ܬܳܢܳܝܬܳܐ",
            sy4: "ܫܘܬܦܘܬܐ ܫܢܬܢܝܬܐ",
            dt1: "Jahresabonnement",
            dt3: "",
            lt12: "",
            sy6: "",
            lt10: "shautofutho shatonoyto",
            sy8: "",
            lt11: "",
            sy9: "",
            sy7: "",
            id: "2275"
        },
        {
            sy8: "",
            sy5: "ܐܰܓܽܘܓܳܐ ܕܐܰܓ݂ܪܳܐ",
            sy9: "",
            dt2: "",
            sy7: "",
            dt1: "Dachrinne",
            id: "784",
            dt3: "",
            lt11: "",
            sy6: "",
            lt12: "",
            lt10: "agugo d`egoro",
            sy4: "ܐܓܘܓܐ ܕܐܓܪܐ"
        },
        {
            sy6: "",
            dt2: "",
            sy8: "",
            lt10: "ܢܝܨܝܒܢܐ",
            sy7: "",
            lt12: "",
            lt11: "ܢܝܨܝܒܢܐ",
            sy9: "",
            id: "3287",
            dt3: "",
            sy4: "ܢܝܨܝܒܢܐ",
            dt1: "Nisibena",
            sy5: "ܢܺܝܨܺܝܒܶܢܰܐ"
        },
        {
            lt11: "",
            id: "1690",
            sy6: "",
            dt2: "",
            dt3: "",
            sy7: "",
            sy9: "",
            lt10: "l`baytokh aloho ĉeleth",
            sy8: "",
            lt12: "",
            sy4: "ܠܒܝܬܟ ܐܠܗܐ ܥܠܬ",
            dt1: "Gott in Deinem Haus bin ich eingetreten",
            sy5: "ܠܒܰܝܬܳܟ݂ ܐܰܠܳܗܳܐ ܥܶܠܶܬ݂"
        },
        {
            dt2: "Klang",
            sy9: "ܩܳܠܳܐ",
            dt3: "",
            lt10: "qinto",
            sy7: "ܙܡܺܝܪܬܳܐ",
            sy6: "ܙܡܝܪܬܐ",
            id: "2996",
            sy5: "ܩܺܝܢܬܳܐ",
            sy4: "ܩܝܢܬܐ",
            sy8: "ܩܠܐ",
            lt11: "zmirtho",
            dt1: "Melodie",
            lt12: "qolo"
        },
        {
            sy7: "",
            dt2: "",
            lt10: "nukhronoyo",
            sy6: "",
            id: "6099",
            dt1: "fremdländisch",
            sy9: "",
            sy8: "",
            sy5: "ܢܽܘܟ݂ܪܳܢܳܝܳܐ",
            sy4: "ܢܘܟܪܢܝܐ",
            dt3: "",
            lt11: "",
            lt12: ""
        },
        {
            lt11: "ܗܢܝܢܐ",
            sy6: "",
            sy8: "",
            dt3: "",
            sy5: "ܗܶܢܝܳܢܳܐ",
            lt12: "",
            sy7: "",
            dt2: "",
            sy4: "ܗܢܝܢܐ",
            id: "1964",
            lt10: "ܗܢܝܢܐ",
            sy9: "",
            dt1: "Henyono"
        },
        {
            dt2: "",
            dt1: "Mandelbaum",
            lt10: "qoțarqo",
            sy4: "ܩܛܪܩܐ",
            sy5: "ܩܳܛܰܪܩܳܐ",
            sy7: "ܫܶܓ݂ܕܳܐ",
            dt3: "",
            id: "2911",
            lt11: "sheğdo",
            sy9: "",
            sy6: "ܫܓܕܐ",
            lt12: "",
            sy8: ""
        },
        {
            lt12: "",
            sy5: "ܛܰܠܝ̈ܳܬ݂ܳܐ ܫܰܦܺܝܪܳܬ݂ܳܐ",
            sy9: "",
            sy7: "",
            lt11: "",
            dt1: "schöne Mädchen",
            id: "4010",
            dt3: "",
            lt10: "țalyotho shafirotho",
            dt2: "",
            sy4: "ܛܠܝ̈ܬܐ ܫܦܝܪܬܐ",
            sy8: "",
            sy6: ""
        },
        {
            lt11: "ܗܘܫܥ",
            lt10: "ܗܘܫܥ",
            dt1: "Huschoh",
            lt12: "",
            sy8: "",
            sy9: "",
            sy7: "",
            id: "2079",
            dt3: "",
            sy4: "ܗܘܫܥ",
            dt2: "",
            sy5: "ܗܽܘܫܳܥ",
            sy6: ""
        },
        {
            lt12: "",
            sy6: "",
            lt10: "țuhmonoyo",
            sy4: "ܛܘܗܡܢܝܐ",
            dt2: "",
            sy8: "",
            lt11: "",
            id: "3614",
            sy9: "",
            sy5: "ܛܽܘܗܡܳܢܳܝܳܐ",
            dt3: "",
            dt1: "rassistisch",
            sy7: ""
        },
        {
            lt10: "shmayo",
            dt2: "",
            dt3: "",
            lt12: "",
            sy6: "",
            sy4: "ܫܡܝܐ",
            sy9: "",
            sy8: "",
            sy7: "",
            sy5: "ܫܡܰܝܳܐ",
            lt11: "",
            id: "1999",
            dt1: "Himmel"
        },
        {
            sy7: "",
            dt1: "Vegetarier",
            sy6: "",
            sy8: "",
            id: "4712",
            lt12: "",
            sy5: "ܠܳܐ ܐܳܟܶܠ ܒܶܣܪܳܐ",
            sy9: "",
            lt11: "",
            sy4: "ܠܐ ܐܟܠ ܒܣܪܐ",
            dt2: "",
            lt10: "lo okhel besro",
            dt3: ""
        },
        {
            dt1: "Damenschuh",
            id: "6339",
            dt3: "",
            lt10: "msono neshoyo",
            sy7: "",
            sy4: "ܡܣܐܢܐ ܢܫܝܐ",
            sy9: "",
            dt2: "Frauenschuh",
            lt11: "",
            sy8: "",
            lt12: "",
            sy5: "ܡܣܐܳܢܳܐ ܢܶܫܳܝܳܐ",
            sy6: ""
        },
        {
            lt12: "",
            lt11: "ܡܠܟܬܐ",
            sy4: "ܡܠܟܬܐ",
            sy8: "",
            id: "2906",
            sy5: "ܡܰܠܟܬܰܐ",
            dt1: "Malktha",
            dt2: "",
            sy7: "",
            lt10: "ܡܠܟܬܐ",
            dt3: "",
            sy6: "",
            sy9: ""
        },
        {
            sy9: "",
            lt12: "",
            sy5: "ܕܽܘܓܠܳܐ",
            dt1: "Lüge",
            lt10: "duglo",
            sy4: "ܕܘܓܠܐ",
            lt11: "mdaglonutho",
            sy7: "ܡܕܰܓܠܳܢܽܘܬ݂ܳܐ",
            id: "2869",
            sy8: "",
            sy6: "ܡܕܓܠܢܘܬܐ",
            dt2: "",
            dt3: ""
        },
        {
            sy5: "ܦܰܩܺܝܪܳܐ",
            sy9: "ܥܳܢܘܳܝܳܐ",
            sy7: "ܨܪܺܝܓ݂ܳܐ",
            lt11: "ŝriğo",
            sy8: "ܥܢܘܝܐ",
            sy4: "ܦܩܝܪܐ",
            dt1: "mittelos",
            lt10: "faqiro",
            lt12: "ĉonwoyo",
            dt3: "",
            dt2: "",
            id: "6358",
            sy6: "ܨܪܝܓܐ"
        },
        {
            dt1: "Bartolmai",
            lt10: "ܒܪܬܘܠܡܝ",
            lt11: "ܒܪܬܘܠܡܝ",
            sy8: "",
            sy4: "ܒܪܬܘܠܡܝ",
            sy9: "",
            sy6: "",
            lt12: "",
            dt3: "",
            sy7: "",
            dt2: "",
            id: "483",
            sy5: "ܒܰܪܬܳܘܠܡܰܝ"
        },
        {
            dt2: "",
            lt10: "naĥeb",
            sy5: "ܢܰܚܶܒ",
            lt12: "",
            sy8: "",
            lt11: "gshaĥ",
            sy7: "ܓܫܰܚ",
            sy4: "ܢܚܒ",
            dt1: "abnutzen",
            sy9: "",
            dt3: "",
            sy6: "ܓܫܚ",
            id: "5593"
        },
        {
            dt3: "",
            lt12: "",
            sy4: "ܠܚܽܘܕ ܐܰܢܬ ܚܳܝܶܐ ܐܰܢܬ ܚܰܝܰܝܟ",
            sy5: "ܠܚܽܘܕ ܐܰܢܬ ܚܳܝܶܐ ܐܰܢܬ ܚܰܝܰܝܟ",
            dt2: "",
            id: "5451",
            sy8: "",
            sy9: "",
            sy6: "",
            sy7: "",
            lt11: "",
            dt1: "Dein leben lebst nur du",
            lt10: "lĥud at ĥoye at ĥayayk"
        },
        {
            id: "4567",
            lt11: "",
            sy7: "",
            sy8: "",
            sy6: "",
            sy4: "ܫܬܝܐ",
            lt10: "shtoyo",
            sy9: "",
            dt1: "Trinken",
            lt12: "",
            sy5: "ܫܬܳܝܳܐ",
            dt3: "",
            dt2: ""
        },
        {
            id: "5682",
            lt10: "bĥan",
            sy8: "",
            sy5: "ܒܚܰܢ",
            dt2: "",
            lt12: "",
            sy7: "ܒܰܚܶܢ",
            lt11: "baĥen",
            sy6: "ܒܚܢ",
            dt3: "",
            sy4: "ܒܚܢ",
            sy9: "",
            dt1: "abtasten"
        },
        {
            sy4: "ܢܫܪܐ",
            dt1: "Neschro",
            lt12: "",
            sy9: "",
            sy6: "",
            sy5: "ܢܶܫܪܳܐ",
            lt11: "Bulut",
            sy7: "",
            dt3: "",
            lt10: "ܢܫܪܐ:\r\n\r\n",
            sy8: "",
            id: "740",
            dt2: ""
        },
        {
            sy7: "",
            sy8: "",
            id: "2843",
            sy6: "",
            sy9: "",
            sy5: "ܠܺܝܫܡܰܥ",
            lt12: "",
            lt10: "ܠܝܫܡܥ",
            lt11: "ܠܝܫܡܥ",
            dt2: "",
            dt1: "Lischmah",
            sy4: "ܠܝܫܡܥ",
            dt3: ""
        },
        {
            sy9: "",
            sy5: "ܙܰܝ̈ܬܶܐ",
            sy7: "ܙܰܝܬܽܘ̈ܢܶܐ",
            lt12: "",
            sy8: "",
            id: "3370",
            dt1: "Oliven",
            lt11: "zaytune",
            sy4: "ܙܝܬܐ",
            sy6: "ܙܝܬܘܢܐ",
            dt3: "",
            dt2: "",
            lt10: "zeyte"
        },
        {
            lt11: "ܐܦܬܘܢܝܐ",
            dt3: "",
            lt12: "",
            sy8: "",
            sy9: "",
            sy6: "",
            sy5: "ܐܰܦܬܽܘܢܝܰܐ",
            dt1: "Aftunya",
            id: "144",
            sy4: "ܐܦܬܘܢܝܐ",
            dt2: "",
            sy7: "",
            lt10: "ܐܦܬܘܢܝܐ"
        },
        {
            lt12: "",
            lt10: "ܦܪܬ",
            lt11: "ܦܪܬ",
            id: "1410",
            dt3: "",
            sy6: "",
            sy8: "",
            sy5: "ܦܪܳܬ݂",
            dt1: "Froth",
            dt2: "",
            sy7: "",
            sy4: "ܦܪܬ݂",
            sy9: ""
        },
        {
            sy6: "",
            id: "3207",
            dt3: "",
            dt2: "Nasenknorpel",
            lt11: "",
            sy9: "",
            sy5: "ܛܰܪܦܳܐ ܕܢܚܺܝܪܳܐ",
            lt12: "",
            lt10: "țarfo d`nĥiro",
            dt1: "Nasenbein",
            sy7: "",
            sy4: "ܛܪܦܐ ܕܢܚܝܪܐ",
            sy8: ""
        },
        {
            lt11: "",
            lt12: "",
            sy4: "ܓܒܝܢܐ",
            dt2: "",
            dt3: "",
            sy8: "",
            id: "4279",
            dt1: "Stirn",
            sy9: "",
            sy7: "",
            sy6: "",
            lt10: "gbino",
            sy5: "ܓܒܺܝܢܳܐ"
        },
        {
            lt11: "mlilo",
            id: "4772",
            sy7: "ܡܠܺܝܠܳܐ",
            lt10: "mliloith",
            dt2: "",
            sy6: "ܡܠܝܠܐ",
            sy5: "ܡܠܺܝܠܳܐܝܺܬ݂",
            dt1: "vernünftig",
            dt3: "",
            sy9: "",
            sy8: "",
            sy4: "ܡܠܝܠܐܝܬ",
            lt12: ""
        },
        {
            sy7: "",
            lt10: "hablan laĥmo dsunqonan yaumono",
            dt1: "Unser tägliches Brot gib uns heute",
            sy6: "",
            sy8: "",
            id: "4656",
            lt12: "",
            dt2: "",
            sy5: "ܗܰܒܠܰܢ ܠܰܚܡܳܐ ܕܣܽܘܢܩܳܢܰܢ ܝܽܘܡܳܢܳܐ",
            lt11: "",
            dt3: "",
            sy4: "ܗܒܠܢ ܠܚܡܐ ܕܣܘܢܩܢܢ ܝܘܡܢܐ",
            sy9: ""
        },
        {
            lt10: "maĉrboyto",
            dt3: "",
            sy9: "",
            lt12: "",
            id: "5561",
            dt2: "",
            sy8: "",
            sy7: "",
            lt11: "",
            sy4: "ܡܥܪܒܝܬܐ",
            sy6: "",
            sy5: "ܡܰܥܪܒܳܝܬܳܐ",
            dt1: "Abendländerin"
        },
        {
            sy9: "",
            lt11: "",
            dt2: "Sommerurlaub",
            sy7: "",
            id: "4190",
            lt10: "bețlono d`qayțo",
            dt3: "",
            lt12: "",
            sy8: "",
            sy5: "ܒܶܛܠܳܢܳܐ ܕܩܰܝܛܳܐ",
            sy6: "",
            dt1: "Sommerferien",
            sy4: "ܒܛܠܢܐ ܕܩܝܛܐ"
        },
        {
            dt2: "",
            sy9: "",
            lt11: "ܡܪܓܐ",
            lt12: "",
            sy7: "",
            sy6: "",
            sy5: "ܡܰܪܓܳܐ",
            id: "2930",
            dt3: "",
            sy8: "",
            lt10: "ܡܪܓܐ",
            dt1: "Margo",
            sy4: "ܡܪܓܐ"
        },
        {
            dt2: "",
            dt3: "",
            sy9: "",
            sy7: "",
            lt10: "ܛܠܢܝܬܐ",
            lt11: "ܛܠܢܝܬܐ",
            id: "4431",
            sy4: "ܛܠܢܝܬܐ",
            sy8: "",
            sy6: "",
            dt1: "Telonita",
            sy5: "ܛܶܠܳܢܺܝܬܰܐ",
            lt12: ""
        },
        {
            lt12: "",
            lt11: "",
            id: "773",
            sy6: "",
            sy7: "",
            dt1: "Christus",
            sy4: "ܡܫܝܚܐ",
            sy9: "",
            sy8: "",
            sy5: "ܡܫܺܝܚܳܐ",
            lt10: "mshiĥo",
            dt2: "",
            dt3: ""
        },
        {
            sy4: "ܩܠܝܬܐ ܕܬܪܬܝܢ ܢܦ̈ܫܬ݂ܐ",
            lt12: "",
            sy6: "",
            sy5: "ܩܶܠܳܝܬܳܐ ܕܬܰܪܬܶܝܢ ܢܰܦ̈ܫܳܬ݂ܳܐ",
            dt2: "",
            dt1: "Doppelzimmer",
            id: "917",
            lt11: "",
            sy9: "",
            lt10: "qeloyto d`tarten nafshotho",
            sy8: "",
            dt3: "",
            sy7: ""
        },
        {
            sy6: "ܢܩܫ",
            lt11: "noqesh",
            sy5: "ܢܩܳܫ",
            sy7: "ܢܳܩܶܫ",
            id: "2529",
            dt3: "",
            sy4: "ܢܩܫ",
            lt12: "",
            lt10: "nqosh",
            dt1: "klingeln",
            sy8: "",
            sy9: "",
            dt2: "läuten"
        },
        {
            sy8: "ܡܫܬܓܢܝܢܘܬܐ",
            lt12: "meshtagnyonutho",
            dt1: "Kontrast",
            dt2: "",
            sy5: "ܩܽܘܒܠܳܐ",
            lt10: "qublo",
            sy9: "ܡܶܫܬܰܓܢܝܳܢܽܘܬ݂ܳܐ",
            id: "2596",
            sy6: "ܣܩܘܒܠܝܘܬܐ",
            sy7: "ܣܰܩܽܘܒܠܳܝܽܘܬ݂ܳܐ",
            lt11: "saqubloyutho",
            sy4: "ܩܘܒܠܐ",
            dt3: ""
        },
        {
            id: "6431",
            sy8: "ܡܕܐܬܐ",
            sy5: "ܩܰܢܒܫܳܐ",
            dt2: "Bussgeld",
            dt3: "",
            lt11: "neqmtho",
            sy6: "ܢܩܡܬܐ",
            lt10: "qanbsho",
            sy7: "ܢܶܩܡܬ݂ܳܐ",
            sy4: "ܩܢܒܫܐ",
            lt12: "madatho",
            dt1: "Bußgeld",
            sy9: "ܡܰܕܰܐܬ݂ܳܐ"
        },
        {
            sy7: "",
            dt3: "",
            dt1: "Respekt",
            sy6: "",
            lt10: "iqoro",
            sy9: "",
            sy5: "ܐܺܝܩܳܪܳܐ",
            lt12: "",
            sy8: "",
            sy4: "ܐܝܩܪܐ",
            lt11: "",
            id: "3703",
            dt2: "Huldigung"
        },
        {
            lt10: "moryo aloho",
            dt2: "",
            sy5: "ܡܳܪܝܳܐ ܐܰܠܳܗܳܐ",
            dt3: "",
            dt1: "Herr Gott",
            sy7: "",
            id: "1970",
            lt11: "",
            lt12: "",
            sy4: "ܡܪܝܐ ܐܠܗܐ",
            sy9: "",
            sy6: "",
            sy8: ""
        },
        {
            lt10: "ܥܝܣܐ",
            lt11: "ܥܝܣܐ",
            sy4: "ܥܝܣܐ",
            dt3: "",
            dt1: "Isa",
            sy6: "",
            id: "2240",
            dt2: "",
            lt12: "",
            sy7: "",
            sy9: "",
            sy8: "",
            sy5: "ܥܺܝܣܰܐ"
        },
        {
            dt2: "",
            sy9: "",
            dt3: "",
            sy7: "",
            lt12: "",
            sy6: "",
            sy5: "ܪܒܺܝܥܳܐܝܺܬ݂",
            lt11: "",
            sy8: "",
            dt1: "vierfach",
            lt10: "rbiĉoith",
            sy4: "ܪܒܝܥܐܝܬ",
            id: "4838"
        },
        {
            dt1: "Habsuno",
            sy7: "ܚܰܒܣܽܘܢܳܐ",
            sy4: "ܚܒܨܘܢܐ",
            sy5: "ܚܰܒܨܽܘܢܳܐ",
            id: "1800",
            lt11: "ܚܒܨܘܢܐ",
            dt2: "Habzuno",
            lt10: "ܚܒܨܘܢܐ",
            lt12: "ܚܒܣܘܢܐ",
            sy9: "",
            dt3: "",
            sy6: "ܚܒܣܘܢܐ",
            sy8: ""
        },
        {
            dt1: "anfassen",
            sy6: "ܥܕܐ",
            sy9: "ܡܳܫ",
            lt10: "qfo",
            sy7: "ܥܕܳܐ",
            sy8: "ܡܫ",
            id: "5949",
            sy4: "ܩܦܐ",
            dt3: "betasten",
            sy5: "ܩܦܳܐ",
            lt11: "ĉđo",
            lt12: "mosh",
            dt2: "berühren"
        },
        {
            sy9: "",
            sy7: "ܡܶܐܙܠܳܐ",
            sy4: "ܗܠܟܐ",
            dt2: "Gang",
            sy6: "ܡܐܙܠܐ",
            dt1: "Gehen",
            sy8: "",
            lt10: "helkho",
            dt3: "",
            lt12: "",
            lt11: "mezlo",
            sy5: "ܗܶܠܟ݂ܳܐ",
            id: "1534"
        },
        {
            dt2: "",
            sy4: "ܐܦܝܐ",
            sy9: "",
            sy5: "ܐܰܦܳܝܳܐ",
            id: "415",
            lt10: "afoyo",
            lt11: "kabzo",
            sy6: "ܟܒܙܐ",
            sy8: "",
            dt3: "",
            sy7: "ܟܰܒܙܳܐ",
            lt12: "",
            dt1: "Bäcker"
        },
        {
            sy7: "ܕܶܒܫܳܐ",
            sy9: "",
            sy5: "ܕܰܘܫܳܐ",
            sy8: "",
            sy6: "ܕܒܫܐ",
            lt10: "ܕܘܫܐ",
            lt11: "ܕܘܫܐ",
            sy4: "ܕܘܫܐ",
            dt2: "Debscho",
            dt1: "Dauscho",
            lt12: "ܕܒܫܐ",
            dt3: "Debscho",
            id: "836"
        },
        {
            dt2: "Heiliges Buch",
            lt10: "kthobo qadisho",
            sy9: "",
            sy7: "",
            lt12: "",
            sy8: "",
            sy4: "ܟܬܒܐ ܩܕܝܫܐ",
            sy6: "",
            sy5: "ܟܬ݂ܳܒܳܐ ܩܰܕܺܝܫܳܐ",
            dt1: "Heilige Schrift",
            lt11: "",
            id: "1927",
            dt3: ""
        },
        {
            dt3: "",
            lt10: "yamo",
            sy4: "ܝܡܐ",
            sy5: "ܝܰܡܳܐ",
            id: "2982",
            sy6: "",
            dt2: "Ozean",
            sy8: "",
            lt11: "",
            lt12: "",
            sy9: "",
            dt1: "Meer",
            sy7: ""
        },
        {
            dt1: "weit entfernt",
            dt2: "",
            sy6: "",
            dt3: "",
            sy9: "",
            sy4: "ܪܚܝܩ ܣܓܝ",
            sy5: "ܪܰܚܺܝܩ ܣܰܓܺܝ",
            lt10: "raĥiq sagi",
            sy8: "",
            lt11: "",
            lt12: "",
            id: "5039",
            sy7: ""
        },
        {
            dt1: "Hose ankleiden",
            lt10: "sharbel",
            sy4: "ܫܪܒܠ",
            sy6: "",
            sy5: "ܫܰܪܒܶܠ",
            sy7: "",
            lt11: "",
            lt12: "",
            dt3: "",
            sy8: "",
            dt2: "",
            id: "6024",
            sy9: ""
        },
        {
            dt3: "habsüchtig",
            sy5: "ܠܰܥܒܳܐ",
            sy6: "ܝܥܢܐ",
            lt12: "qaluțoyo",
            lt10: "laĉbo",
            sy4: "ܠܥܒܐ",
            sy9: "ܩܰܠܽܘܛܳܝܳܐ",
            sy8: "ܩܠܘܛܝܐ",
            sy7: "ܝܰܥܢܳܐ",
            dt1: "geizig",
            lt11: "yaĉno",
            dt2: "gierig",
            id: "1542"
        },
        {
            dt2: "",
            sy5: "ܪܓ݂ܳܫܳܐ",
            lt12: "",
            dt1: "Bewusstsein",
            lt11: "",
            sy6: "",
            sy9: "",
            id: "604",
            lt10: "rğosho",
            dt3: "",
            sy7: "",
            sy8: "",
            sy4: "ܪܓܫܐ"
        },
        {
            sy4: "ܐܡܝܪܐ",
            sy6: "",
            dt1: "Prinz",
            id: "3512",
            sy7: "",
            sy5: "ܐܰܡܺܝܪܳܐ",
            sy9: "",
            sy8: "",
            lt11: "",
            dt3: "",
            lt12: "",
            lt10: "amiro",
            dt2: ""
        },
        {
            dt3: "",
            sy4: "ܢܘܗܪܐ ܕܦܓܕܐ",
            lt12: "",
            sy7: "ܒܰܗܪܳܐ ܕܦܰܓ݂ܕܳܐ",
            sy8: "",
            sy9: "",
            lt11: "bahro d`fagđo",
            dt1: "Bremslicht",
            sy5: "ܢܽܘܗܪܳܐ ܕܦܰܓܕܳܐ",
            sy6: "ܒܗܪܐ ܕܦܓܕܐ",
            id: "701",
            dt2: "",
            lt10: "nuhro d`fagđo"
        },
        {
            id: "2485",
            lt10: "ܟܢܪܐ",
            dt1: "Kinoro",
            sy4: "ܟܢܪܐ",
            sy5: "ܟܶܢܳܪܳܐ",
            dt3: "",
            dt2: "",
            lt11: "ܟܢܪܐ",
            sy6: "ܟܝܢܪܐ",
            sy8: "",
            lt12: "ܟܝܢܪܐ",
            sy7: "ܟܺܝܢܳܪܳܐ",
            sy9: ""
        },
        {
            dt3: "",
            lt12: "",
            sy9: "",
            dt1: "Forderung",
            id: "6085",
            lt11: "sheltho",
            lt10: "bĉotho",
            sy4: "ܒܥܬܐ",
            sy6: "ܫܠܬܐ",
            sy5: "ܒܥܳܬ݂ܳܐ",
            sy7: "ܫܶܐܠܬ݂ܳܐ",
            sy8: "",
            dt2: ""
        },
        {
            sy7: "",
            sy9: "",
            sy8: "",
            dt2: "Übereinstimmung",
            lt11: "",
            lt10: "auyutho",
            sy4: "ܐܘܝܘܬܐ",
            dt1: "Konsens",
            sy5: "ܐܰܘܝܽܘܬ݂ܳܐ",
            sy6: "",
            lt12: "",
            dt3: "",
            id: "2585"
        },
        {
            sy6: "ܡܣܒܪ",
            sy9: "",
            sy8: "",
            lt10: "sabar",
            dt1: "verkünden",
            dt3: "mitteilen",
            dt2: "verkündigen",
            sy7: "ܡܣܰܒܰܪ",
            lt11: "msabar",
            lt12: "",
            sy5: "ܣܰܒܰܪ",
            id: "4754",
            sy4: "ܣܒܪ"
        },
        {
            lt12: "",
            sy8: "",
            dt3: "",
            sy9: "",
            sy5: "ܦܰܣܰܪ",
            sy6: "ܢܟܪ",
            lt10: "fasar",
            id: "5705",
            dt2: "",
            sy4: "ܦܣܪ",
            lt11: "nakar",
            sy7: "ܢܰܟܰܪ",
            dt1: "abweisen"
        },
        {
            dt1: "Abstoß",
            lt11: "",
            lt10: "mĥuto dnoțar naufo",
            sy9: "",
            sy5: "ܡܚܽܘܬܳܐ ܕܢܳܛܰܪ ܢܰܘܦܳܐ",
            sy4: "ܡܚܘܬܐ ܕܢܛܪ ܢܘܦܐ",
            sy7: "",
            dt2: "",
            id: "5672",
            dt3: "",
            lt12: "",
            sy8: "",
            sy6: ""
        },
        {
            id: "743",
            dt2: "",
            dt3: "",
            sy9: "",
            sy4: "ܦܘܠܚܢ ܡܟܬܒܐ",
            sy6: "",
            dt1: "Büroarbeit",
            sy8: "",
            sy5: "ܦܽܘܠܚܳܢ ܡܰܟܬ݂ܒܳܐ",
            lt11: "",
            sy7: "",
            lt10: "fulĥon makthbo",
            lt12: ""
        },
        {
            lt12: "",
            dt3: "",
            sy8: "",
            lt10: "aminutho",
            sy7: "",
            sy9: "",
            dt1: "Kontinuität",
            sy5: "ܐܰܡܺܝܢܽܘܬ݂ܳܐ",
            lt11: "",
            dt2: "Fortdauer",
            sy4: "ܐܡܝܢܘܬܐ",
            id: "2592",
            sy6: ""
        },
        {
            id: "1739",
            sy4: "ܒܝܬܐ ܪܒܐ",
            lt12: "",
            sy5: "ܒܰܝܬܳܐ ܪܰܒܳܐ",
            sy8: "",
            dt1: "großes Haus",
            sy7: "",
            lt11: "",
            lt10: "bayto rabo",
            sy6: "",
            dt2: "",
            sy9: "",
            dt3: ""
        },
        {
            lt10: "mashruĉitho",
            dt1: "Rutsche",
            sy7: "",
            dt2: "Rutschbahn",
            lt11: "",
            sy9: "",
            lt12: "",
            sy4: "ܡܫܪܘܥܝܬܐ",
            sy6: "",
            dt3: "",
            id: "3775",
            sy5: "ܡܰܫܪܽܘܥܺܝܬ݂ܳܐ",
            sy8: ""
        },
        {
            lt12: "ܟܦܦܪܐ",
            sy4: "ܓܒܒܪܐ",
            lt10: "ܓܒܒܪܐ",
            sy5: "ܓܰܒܒܰܪܰܐ",
            dt3: "",
            dt1: "Gabbara",
            lt11: "ܓܒܒܪܐ",
            sy7: "ܟܰܦܦܰܪܰܐ",
            sy9: "",
            sy8: "",
            id: "1448",
            dt2: "",
            sy6: "ܟܦܦܪܐ"
        },
        {
            sy8: "",
            sy6: "",
            id: "4708",
            sy4: "ܦܛܪܝܝܪܟܐ",
            dt3: "",
            lt12: "",
            dt2: "",
            lt10: "fațeryarkho",
            dt1: "Vater der Väter",
            sy9: "",
            lt11: "",
            sy7: "",
            sy5: "ܦܰܛܶܪܝܰܝܪܟ݂ܳܐ"
        },
        {
            sy4: "ܥܪܨܐ",
            dt3: "",
            sy8: "",
            dt2: "Zufall",
            lt10: "ĉerŝo",
            id: "4617",
            sy6: "ܥܪܨܘܬܐ",
            dt1: "Überraschung",
            lt11: "ĉorŝutho",
            sy7: "ܥܳܪܨܽܘܬ݂ܳܐ",
            lt12: "",
            sy5: "ܥܶܪܨܳܐ",
            sy9: ""
        },
        {
            id: "5827",
            lt12: "shotuyo",
            sy9: "ܫܳܬܽܘܝܳܐ",
            lt10: "shakhrono",
            dt3: "",
            sy7: "ܫܰܬܳܝܳܐ",
            dt2: "",
            sy8: "ܫܬܘܝܐ",
            sy4: "ܫܟܪܢܐ",
            sy6: "ܫܬܝܐ",
            dt1: "Alkoholiker",
            lt11: "shatoyo",
            sy5: "ܫܰܟ݂ܪܳܢܳܐ"
        },
        {
            sy7: "",
            sy9: "",
            lt11: "",
            dt2: "sanftmütig",
            dt1: "bescheiden",
            sy6: "",
            sy5: "ܡܰܟܺܝܟ݂ܳܐ",
            lt10: "makikho",
            dt3: "warmherzig",
            sy4: "ܡܟܝܟܐ",
            lt12: "",
            id: "567",
            sy8: ""
        },
        {
            dt2: "",
            lt11: "",
            sy8: "",
            sy9: "",
            dt1: "April",
            sy4: "ܢܝܣܢ",
            sy6: "",
            dt3: "",
            sy7: "",
            lt12: "",
            id: "266",
            sy5: "ܢܺܝܣܳܢ",
            lt10: "nison"
        },
        {
            sy6: "",
            lt10: "farshağno d`bețlono",
            dt1: "Ferienadresse",
            sy5: "ܦܰܪܫܰܓ݂ܢܳܐ ܕܒܶܛܠܳܢܳܐ",
            sy7: "",
            dt3: "",
            dt2: "Urlaubsadresse",
            sy8: "",
            lt11: "",
            sy4: "ܦܪܫܓܢܐ ܕܒܛܠܢܐ",
            lt12: "",
            id: "1288",
            sy9: ""
        },
        {
            id: "1042",
            sy6: "ܡܪܟܒܬܐ ܕܙܒܢܐ",
            sy5: "ܡܰܪܟܰܒܬ݂ܳܐ ܕܰܫܩܳܠܳܐ",
            lt11: "markabtho d`zebno",
            sy4: "ܡܪܟܒܬܐ ܕܫܩܠܐ",
            sy7: "ܡܰܪܟܰܒܬ݂ܳܐ ܕܙܶܒܢܳܐ",
            lt12: "",
            dt2: "",
            sy9: "",
            sy8: "",
            lt10: "markabtho da`shqolo",
            dt3: "",
            dt1: "Einkaufswagen"
        },
        {
            lt10: "nauwlo emwothonoyo",
            lt11: "",
            sy5: "ܢܰܘܠܳܐ ܐܶܡܘܳܬ݂ܢܳܝܳܐ",
            dt2: "",
            sy6: "",
            dt1: "Internet",
            dt3: "",
            sy9: "",
            id: "2228",
            lt12: "",
            sy4: "ܢܘܠܐ ܐܡܘܬܢܝܐ",
            sy7: "",
            sy8: ""
        },
        {
            sy4: "ܐܬܠܗܩܬ ܣܓܝ ܠܚܙܝܬܟ",
            sy9: "",
            lt10: "ethlahqeth sagi laĥzoytokh",
            lt11: "ethlahqeth sagi laĥzoytekh",
            id: "2112",
            sy5: "ܐܬ݂ܶܠܗܩܶܬ݂ ܣܰܓܺܝ ܠܰܚܙܰܝܬܳܟ݂",
            dt2: "",
            dt3: "",
            sy7: "ܐܬ݂ܶܠܗܩܶܬ݂ ܣܓܝ ܠܰܚܙܰܝܬܶܟ݂ܝ ",
            lt12: "",
            dt1: "Ich habe dich sehr vermisst",
            sy8: "",
            sy6: "ܐܬܠܗܩܬ ܣܓܝ ܠܚܙܝܬܟܝ "
        },
        {
            sy9: "",
            lt12: "",
            sy4: "ܕܪܐ",
            sy8: "",
            dt3: "Zeitalter",
            sy7: "",
            dt1: "Generation",
            sy6: "",
            lt10: "doro",
            id: "1562",
            sy5: "ܕܳܪܳܐ",
            dt2: "Epoche",
            lt11: ""
        },
        {
            sy9: "",
            sy4: "ܐܚܝܪܡ",
            sy5: "ܐܰܚܺܝܪܰܡ",
            lt10: "ܐܚܝܪܡ",
            sy7: "",
            sy8: "",
            dt1: "Ahiram",
            lt12: "",
            id: "158",
            dt3: "",
            lt11: "ܐܚܝܪܡ",
            dt2: "",
            sy6: ""
        },
        {
            id: "3918",
            sy7: "",
            dt2: "",
            sy5: "ܫܶܐܠܶܬܰܐ",
            dt1: "Schelata",
            sy9: "",
            lt11: "ܫܐܠܬܐ",
            sy6: "",
            lt10: "ܫܐܠܬܐ",
            sy4: "ܫܐܠܬܐ",
            lt12: "",
            dt3: "",
            sy8: ""
        },
        {
            lt11: "",
            sy8: "",
            lt10: "kshar",
            id: "6451",
            sy9: "",
            dt2: "",
            sy5: "ܟܫܰܪ",
            sy4: "ܟܫܪ",
            sy6: "",
            dt3: "",
            sy7: "",
            dt1: "erfolgreich bestehen",
            lt12: ""
        },
        {
            sy8: "",
            dt3: "",
            lt12: "",
            sy9: "",
            sy4: "ܙܘܠܙܠܐ",
            sy5: "ܙܽܘܠܙܳܠܳܐ",
            lt10: "sulsolo",
            id: "1136",
            sy6: "",
            sy7: "",
            dt1: "Erdbeben",
            lt11: "",
            dt2: ""
        },
        {
            lt10: "haymonutho bațolto",
            sy7: "",
            lt11: "",
            dt1: "Aberglaube",
            id: "80",
            sy8: "",
            sy9: "",
            sy4: "ܗܝܡܢܘܬܐ ܒܛܠܬܐ",
            dt2: "",
            sy5: "ܗܰܝܡܳܢܘܽܬ݂ܳܐ ܒܰܛܳܠܬܳܐ",
            sy6: "",
            dt3: "",
            lt12: ""
        },
        {
            sy8: "",
            sy9: "",
            dt2: "",
            sy5: "ܨ",
            sy6: "",
            lt11: "",
            lt12: "",
            sy7: "",
            lt10: "ŝode",
            id: "3779",
            dt3: "",
            dt1: "ŝ",
            sy4: "ܨ"
        },
        {
            sy7: "",
            lt12: "",
            sy6: "",
            sy9: "",
            dt3: "",
            dt2: "Walfisch",
            lt11: "",
            sy4: "ܩܐܛܘܣ",
            dt1: "Wal",
            sy5: "ܩܺܐܛܳܘܣ",
            id: "4968",
            lt10: "qițos",
            sy8: ""
        },
        {
            lt10: "ĉafro sumoqo",
            sy7: "",
            dt3: "",
            sy6: "",
            sy8: "",
            sy5: "ܥܰܦܪܳܐ ܣܽܘܡܳܩܳܐ",
            dt1: "rote Erde",
            lt12: "",
            lt11: "",
            id: "3750",
            sy9: "",
            sy4: "ܥܦܪܐ ܣܘܡܩܐ",
            dt2: ""
        },
        {
            sy9: "ܐܶܫܛܳܪܳܐ",
            sy7: "ܡܣܰܕܪܳܢܺܝܬ݂ܳܐ",
            lt11: "msadronitho",
            id: "5804",
            sy5: "ܟܬ݂ܺܝܒܬܳܐ",
            lt10: "kthibto",
            dt3: "",
            dt1: "Akte",
            dt2: "",
            sy6: "ܡܣܕܪܢܝܬܐ",
            sy4: "ܟܬܝܒܬܐ",
            lt12: "eshțoro",
            sy8: "ܐܫܛܪܐ"
        },
        {
            sy7: "",
            lt11: "",
            lt12: "",
            id: "342",
            sy6: "",
            dt2: "",
            sy5: "ܒܢܳܬ݂ ܓܰܢ̈ܶܐ",
            dt3: "",
            sy8: "",
            sy9: "",
            lt10: "bnoth gane",
            dt1: "Auberginen",
            sy4: "ܒܢܬ ܓܢܐ"
        },
        {
            dt2: "",
            dt1: "Ich öffne das Fenster",
            lt11: "",
            sy6: "",
            lt10: "eno fotaĥ no l`kautho",
            sy7: "",
            sy5: "ܐܶܢܳܐ ܦܳܬܰܚ ܐ̱ܢܳܐ ܠܟܰܘܬ݂ܳܐ",
            id: "2130",
            sy9: "",
            sy8: "",
            dt3: "",
            lt12: "",
            sy4: "ܐܢܐ ܦܬܚ ܐܢܐ ܠܟܘܬܐ"
        },
        {
            dt3: "",
            sy7: "",
            lt11: "ܢܪܓܝܠ",
            sy4: "ܢܪܓܝܠ",
            id: "3203",
            lt12: "",
            sy8: "",
            sy5: "ܢܰܪܓܺܝܠ",
            dt2: "Nargilo",
            dt1: "Nargil",
            lt10: "ܢܪܓܝܠ",
            sy9: "",
            sy6: ""
        },
        {
            sy4: "ܩܝܢܬܐ",
            sy6: "",
            sy9: "",
            sy7: "",
            lt10: "ܩܝܢܬܐ",
            sy8: "",
            dt1: "Qinta",
            id: "3562",
            lt12: "",
            dt2: "",
            lt11: "ܩܝܢܬܐ",
            sy5: "ܩܺܝܢܬܰܐ",
            dt3: ""
        },
        {
            sy8: "",
            dt2: "",
            sy4: "ܐܕܢܐ",
            sy6: "",
            dt3: "",
            sy5: "ܐܶܕܢܳܐ",
            dt1: "Ohr",
            sy7: "",
            sy9: "",
            lt10: "edno",
            id: "3360",
            lt11: "",
            lt12: ""
        },
        {
            lt12: "",
            lt10: "ĥotho tomtho",
            sy8: "",
            sy6: "",
            id: "5406",
            dt2: "",
            sy5: "ܚܳܬ݂ܳܐ ܬܳܐܡܬ݂ܳܐ",
            dt1: "Zwillingsschwester",
            sy4: "ܚܬܐ ܬܐܡܬܐ",
            sy9: "",
            lt11: "",
            dt3: "",
            sy7: ""
        },
        {
            lt11: "ܝܘܝܕܥ",
            lt10: "ܝܘܝܕܥ",
            sy9: "",
            sy6: "ܝܘܝܕܥ",
            sy8: "",
            dt3: "",
            sy5: "ܝܽܘܝܳܕܳܥ",
            dt1: "Jojada",
            dt2: "",
            sy7: "ܝܽܘܝܰܕܰܥ",
            lt12: "ܝܘܝܕܥ",
            sy4: "ܝܘܝܕܥ",
            id: "2334"
        },
        {
            sy7: "",
            sy8: "",
            dt1: "flüchten",
            lt12: "",
            id: "1339",
            dt2: "fliehen",
            sy4: "ܓܠܘܝ",
            lt11: "",
            lt10: "galwi",
            sy9: "",
            sy6: "",
            dt3: "emigrieren",
            sy5: "ܓܰܠܘܺܝ"
        },
        {
            id: "1048",
            sy6: "",
            lt11: "mlaĥ",
            dt3: "",
            sy4: "ܡܠܚ",
            sy8: "",
            sy5: "ܡܰܠܰܚ",
            dt1: "einsalzen",
            sy7: "",
            sy9: "",
            dt2: "",
            lt12: "",
            lt10: "malaĥ"
        },
        {
            lt11: "ܪܘܡܐ",
            dt2: "Rume",
            lt10: "ܪܘܡܐ",
            sy7: "",
            dt3: "Ruma",
            sy8: "",
            id: "3709",
            sy6: "",
            lt12: "",
            dt1: "Reuma",
            sy4: "ܪܘܡܐ",
            sy5: "ܪܽܘܡܰܐ",
            sy9: ""
        },
        {
            sy4: "ܗܢܝܘܟܐ ܕܬܩܣܝ",
            dt1: "Taxifahrer",
            dt2: "",
            sy8: "",
            lt10: "henyukho d`taqsi",
            sy5: "ܗܶܢܝܽܘܟ݂ܳܐ ܕܬܰܩܣܺܝ",
            sy6: "",
            lt11: "",
            lt12: "",
            id: "4411",
            sy9: "",
            dt3: "",
            sy7: ""
        },
        {
            sy9: "",
            dt3: "",
            dt1: "Liebeserklärung",
            id: "2815",
            sy5: "ܡܰܘܕܥܳܢܽܘܬ݂ ܚܽܘܒܳܐ",
            sy6: "",
            lt11: "",
            sy8: "",
            dt2: "",
            lt10: "maudĉonuth d`ĥubo",
            sy7: "",
            sy4: "ܡܘܕܥܢܘܬ ܚܘܒܐ",
            lt12: ""
        },
        {
            sy6: "",
            sy5: "ܡܰܩܪܚܳܢܽܘܬ݂ܳܐ",
            dt1: "Anmerkung",
            dt3: "",
            lt12: "",
            sy9: "",
            lt10: "maqrĥonutho",
            id: "5966",
            sy4: "ܡܩܪܚܢܘܬܐ",
            lt11: "",
            sy8: "",
            sy7: "",
            dt2: ""
        },
        {
            dt1: "Entscheidung",
            sy4: "ܦܘܣܩܢܐ",
            sy6: "",
            sy7: "",
            dt2: "Entschluss",
            id: "1116",
            dt3: "",
            lt11: "",
            lt12: "",
            sy9: "",
            lt10: "fusqono",
            sy8: "",
            sy5: "ܦܽܘܣܩܳܢܳܐ"
        },
        {
            dt1: "Anbeter",
            sy6: "",
            sy4: "ܡܝܩܪܢܐ",
            sy5: "ܡܝܰܩܪܳܢܳܐ",
            lt11: "",
            id: "5916",
            lt10: "myaqrono",
            sy8: "",
            sy9: "",
            dt2: "",
            lt12: "",
            sy7: "",
            dt3: ""
        },
        {
            sy6: "",
            sy5: "ܙܶܢܳܘܒܺܝܳܐ",
            dt1: "Zenobio",
            lt12: "",
            lt10: "ܙܢܘܒܝܐ",
            sy9: "",
            id: "5301",
            lt11: "ܙܢܘܒܝܐ",
            sy8: "",
            dt3: "",
            sy4: "ܙܢܘܒܝܐ",
            dt2: "",
            sy7: ""
        },
        {
            dt2: "",
            dt1: "Humor",
            lt10: "mĉaydutho",
            lt11: "fuyoğo",
            id: "6037",
            sy6: "ܦܘܝܓܐ",
            sy4: "ܡܥܝܕܘܬܐ",
            sy7: "ܦܽܘܝܳܓ݂ܳܐ",
            dt3: "",
            sy5: "ܡܥܰܝܕܽܘܬ݂ܳܐ",
            sy8: "ܐܟܣܝܣ",
            sy9: "ܐܶܟܣܺܝܣ",
            lt12: "eksis"
        },
        {
            id: "4457",
            sy7: "ܡܡܰܠܶܠ ܐܰܠܳܗܳܝܬܳܐ",
            lt10: "tewoloğos",
            dt2: "",
            sy9: "",
            sy6: "ܡܡܠܠ ܐܠܗܝܬܐ",
            sy8: "",
            dt1: "Theologe",
            sy4: "ܬܐܘܠܘܓܘܣ",
            lt12: "",
            sy5: "ܬܳܐܘܳܠܳܘܓܳܘܣ",
            lt11: "mmalel alohoytho",
            dt3: ""
        },
        {
            sy7: "",
            dt3: "",
            lt10: "gĥoro",
            lt12: "",
            sy8: "",
            sy9: "",
            sy4: "ܓܚܪܐ",
            sy6: "",
            lt11: "",
            dt2: "",
            sy5: "ܓܚܳܪܳܐ",
            id: "6224",
            dt1: "Spucken"
        },
        {
            dt3: "",
            sy9: "",
            sy6: "",
            lt11: "",
            dt1: "Glockenläuten",
            sy8: "",
            lt10: "qulqosho",
            sy7: "",
            lt12: "",
            dt2: "",
            sy5: "ܩܽܘܠܩܳܫܳܐ",
            sy4: "ܩܘܠܩܫܐ",
            id: "1663"
        },
        {
            dt1: "Goluta",
            lt11: "ܓܠܘܬܐ",
            lt10: "ܓܠܘܬܐ",
            sy5: "ܓܳܠܽܘܬܰܐ",
            sy8: "",
            dt3: "",
            sy4: "ܓܠܘܬܐ",
            id: "1683",
            sy6: "",
            sy9: "",
            lt12: "",
            dt2: "",
            sy7: ""
        },
        {
            sy8: "",
            lt11: "",
            sy7: "",
            sy9: "",
            lt12: "",
            sy5: "ܠܽܘܙܳܐ",
            dt1: "Mandel",
            id: "2910",
            dt3: "",
            sy4: "ܠܘܙܐ",
            dt2: "",
            sy6: "",
            lt10: "luso"
        },
        {
            dt2: "",
            id: "2589",
            dt3: "",
            lt11: "",
            lt12: "",
            dt1: "Kontinent",
            sy7: "",
            sy9: "",
            lt10: "fnitho",
            sy8: "",
            sy6: "",
            sy4: "ܦܢܝܬܐ",
            sy5: "ܦܢܺܝܬ݂ܳܐ"
        },
        {
            sy7: "",
            sy9: "",
            sy6: "",
            lt12: "",
            sy5: "ܟܪܺܝܟܳܐ",
            dt2: "",
            dt3: "",
            lt10: "kriko",
            lt11: "",
            dt1: "Wagenheber",
            sy4: "ܟܪܝܟܐ",
            sy8: "",
            id: "4933"
        },
        {
            sy7: "",
            sy6: "",
            dt2: "",
            lt10: "țizo",
            dt3: "",
            sy5: "ܛܺܝܙܳܐ",
            sy4: "ܛܝܙܐ",
            sy8: "",
            dt1: "Popo",
            id: "3490",
            lt12: "",
            lt11: "",
            sy9: ""
        },
        {
            lt10: "laĥoyto",
            sy5: "ܠܰܚܳܝܬܳܐ",
            sy7: "ܡܟܰܦܪܳܢܺܝܬ݂ܳܐ",
            sy4: "ܠܚܝܬܐ",
            dt3: "",
            sy8: "",
            sy9: "",
            dt1: "Abwischtuch",
            lt12: "",
            id: "5716",
            lt11: "mkafronitho",
            dt2: "",
            sy6: "ܡܟܦܪܢܝܬܐ"
        },
        {
            id: "341",
            lt11: "ܥܬܬܐ",
            lt10: "ܥܬܬܐ",
            sy4: "ܥܬܬܐ",
            dt2: "",
            lt12: "",
            sy6: "",
            sy7: "",
            dt3: "",
            dt1: "Atto",
            sy8: "",
            sy9: "",
            sy5: "ܥܰܬܬܳܐ"
        },
        {
            lt12: "",
            dt2: "",
            sy5: "ܓܰܘܢܳܐ",
            sy4: "ܓܘܢܐ",
            lt10: "gauno",
            sy9: "",
            lt11: "",
            sy8: "",
            sy7: "",
            sy6: "",
            dt1: "Farbe",
            id: "1255",
            dt3: ""
        },
        {
            sy5: "ܐܶܫܬܩܰܕܝ̱",
            lt12: "",
            sy7: "",
            sy4: "ܐܫܬܩܕܝ",
            dt1: "letztes Jahr",
            lt10: "eshtqađ",
            sy6: "",
            sy8: "",
            sy9: "",
            dt2: "",
            lt11: "",
            id: "2787",
            dt3: ""
        },
        {
            dt3: "",
            sy7: "",
            id: "5148",
            sy5: "ܚܰܪܬ݂ܳܐ ܕܫܳܒܽܘܥܳܐ",
            sy8: "",
            dt1: "Wochenende",
            sy9: "",
            lt10: "ĥartho d`shobuĉo",
            sy4: "ܚܪܬܐ ܕܫܒܘܥܐ",
            lt11: "",
            dt2: "",
            lt12: "",
            sy6: ""
        },
        {
            lt11: "ܝܫܘܥ",
            sy7: "",
            sy9: "",
            sy8: "",
            sy4: "ܝܫܘܥ",
            dt1: "Jeschu",
            dt3: "",
            sy6: "",
            lt12: "",
            sy5: "ܝܶܫܽܘܥ",
            id: "2309",
            dt2: "",
            lt10: "ܝܫܘܥ"
        },
        {
            sy7: "ܨܶܒܝܳܢܳܐ",
            dt2: "Sebjon",
            sy6: "ܨܒܝܢܐ",
            sy4: "ܨܒܝܘܢ",
            dt3: "Sebyono",
            dt1: ":Sebyon",
            id: "4087",
            sy8: "",
            lt10: "ܨܒܝܘܢ",
            lt12: "ܨܒܝܢܐ",
            sy5: "ܨܶܒܝܳܘܢ",
            sy9: "",
            lt11: "ܨܒܝܘܢ"
        },
        {
            lt12: "",
            dt1: "Rakete",
            sy6: "",
            sy9: "",
            sy5: "ܨܳܪܽܘܚܳܐ",
            dt3: "",
            sy7: "",
            lt10: "ŝoruĥo",
            sy8: "",
            lt11: "",
            dt2: "",
            id: "3597",
            sy4: "ܨܪܘܚܐ"
        },
        {
            sy5: "ܒܰܪܩܳܐ",
            dt1: "Blitz",
            sy8: "",
            id: "651",
            sy6: "",
            lt10: "barqo",
            sy7: "",
            dt3: "",
            lt11: "",
            sy9: "",
            lt12: "",
            sy4: "ܒܪܩܐ",
            dt2: "Donner"
        },
        {
            sy8: "",
            sy5: "ܟܰܠܕܳܝܳܐ",
            dt1: "Suryoyo",
            lt11: "morunoyo ",
            dt3: "",
            sy9: "",
            dt2: "Suroyo",
            sy7: "ܡܳܪܽܘܢܳܝܳܐ",
            id: "4324",
            sy6: "ܡܪܘܢܝܐ",
            sy4: "ܟܠܕܝܐ",
            lt10: "kaldoyo ",
            lt12: ""
        },
        {
            sy9: "",
            lt12: "",
            dt1: "entwickelt",
            dt2: "",
            sy7: "",
            id: "6447",
            sy4: "ܡܫܘܫܛܐ",
            lt10: "mshaushțo",
            sy5: "ܡܫܰܘܫܛܳܐ",
            sy6: "",
            sy8: "",
            dt3: "",
            lt11: ""
        },
        {
            id: "5182",
            lt10: "ܝܒܠ",
            sy4: "ܝܒܠ",
            sy7: "",
            dt1: "Yabel",
            sy5: "ܝܰܒܶܠ",
            dt2: "",
            lt12: "",
            sy6: "",
            sy9: "",
            lt11: "ܝܒܠ",
            dt3: "",
            sy8: ""
        },
        {
            dt1: "Süden",
            dt3: "",
            id: "4308",
            sy7: "",
            lt11: "",
            sy6: "",
            lt12: "",
            sy9: "",
            dt2: "Süd",
            sy5: "ܬܰܝܡܢܳܐ",
            sy8: "",
            sy4: "ܬܝܡܢܐ",
            lt10: "taymno"
        },
        {
            sy7: "",
            lt12: "",
            sy4: "ܗܪܓܐ ܩܕܡܝܐ",
            sy6: "",
            dt2: "",
            dt1: "Erste Lektion",
            lt11: "",
            dt3: "",
            sy8: "",
            sy5: "ܗܶܪܓܳܐ ܩܰܕܡܝܳܐ",
            id: "1175",
            lt10: "hergo qađmoyo",
            sy9: ""
        },
        {
            sy8: "",
            lt12: "",
            dt1: "Gottheit",
            dt3: "",
            sy4: "ܐܠܗܘܬܐ",
            id: "1707",
            lt11: "",
            sy7: "",
            sy9: "",
            sy6: "",
            sy5: "ܐܰܠܳܗܽܘܬ݂ܳܐ",
            lt10: "alohutho",
            dt2: ""
        },
        {
            sy8: "",
            lt12: "",
            dt2: "",
            lt11: "țloye",
            lt10: "shabre",
            sy9: "",
            dt3: "",
            sy4: "ܫܒܪܐ",
            id: "2472",
            dt1: "Kinder",
            sy7: "ܛܠܳܝ̈ܶܐ",
            sy6: "ܛܠܝܐ",
            sy5: "ܫܰܒ̈ܪܶܐ"
        },
        {
            sy6: "",
            sy5: "ܙܩܺܝܦܽܘܬ݂ܳܐ",
            id: "2644",
            lt10: "sqifutho",
            sy9: "",
            dt2: "",
            sy8: "",
            lt12: "",
            lt11: "",
            sy7: "",
            dt1: "Kreuzigung",
            dt3: "",
            sy4: "ܙܩܝܦܘܬܐ"
        },
        {
            dt1: "Abwasserkanal",
            lt11: "",
            sy4: "ܫܝܓܪܐ ܕܡܝܐ",
            dt3: "",
            sy9: "",
            id: "5698",
            lt10: "shigro dmayo",
            sy8: "",
            sy5: "ܫܺܝܓܪܳܐ ܕܡܰܝܳܐ",
            sy7: "",
            lt12: "",
            dt2: "",
            sy6: ""
        },
        {
            sy5: "ܡܣܺܝܡܳܢܳܐ",
            sy9: "",
            dt3: "",
            dt2: "",
            lt10: "msimono",
            dt1: "positiv",
            sy8: "",
            lt11: "",
            lt12: "",
            sy6: "",
            sy4: "ܡܣܝܡܢܐ",
            sy7: "",
            id: "3495"
        },
        {
            dt1: "Vorwort",
            lt10: "ĉutodo",
            sy4: "ܥܘܬܕܐ",
            sy8: "",
            sy5: "ܥܽܘܬܳܕܳܐ",
            sy6: "",
            dt3: "Einführung",
            lt12: "",
            id: "4911",
            sy7: "",
            lt11: "",
            sy9: "",
            dt2: "Einleitung"
        },
        {
            dt2: "",
            dt3: "",
            lt11: "shriĥo",
            sy7: "ܫܪܺܝܚܳܐ",
            sy8: "",
            lt10: "brimo",
            id: "4085",
            sy5: "ܒܪܺܝܡܳܐ",
            dt1: "schwul",
            sy6: "ܫܪܝܚܐ",
            sy9: "",
            sy4: "ܒܪܝܡܐ",
            lt12: ""
        },
        {
            lt11: "",
            dt3: "",
            dt2: "",
            sy8: "",
            lt12: "",
            sy9: "",
            sy6: "",
            lt10: "sathwo",
            dt1: "Winter",
            id: "5117",
            sy7: "",
            sy4: "ܣܬܘܐ",
            sy5: "ܣܰܬ݂ܘܳܐ"
        },
        {
            lt12: "henen",
            id: "4143",
            sy4: "ܗܝ",
            lt10: "hi",
            sy6: "ܗܢܘܢ",
            sy9: "ܗܶܢܶܝܢ",
            lt11: "honun",
            dt1: "Sie",
            sy5: "ܗܺܝ",
            dt2: "Jene",
            sy8: "ܗܢܝܢ",
            sy7: "ܗܳܢܽܘܢ",
            dt3: ""
        },
        {
            dt2: "",
            dt3: "",
            lt11: "",
            sy7: "",
            dt1: "Garantie",
            sy5: "ܥܰܪܳܒܽܘܬ݂ܳܐ",
            sy6: "",
            id: "1472",
            sy8: "",
            sy4: "ܥܪܒܘܬܐ",
            lt12: "",
            sy9: "",
            lt10: "ĉarobutho"
        },
        {
            sy4: "ܫܥܬ ܛܗܪܐ",
            dt2: "",
            lt12: "",
            id: "3063",
            sy8: "",
            sy6: "",
            dt3: "",
            sy9: "",
            lt10: "shoĉath țahro",
            sy5: "ܫܳܥܰܬ݂ ܛܰܗܪܳܐ",
            dt1: "Mittagsstunde",
            sy7: "",
            lt11: ""
        },
        {
            lt11: "",
            dt3: "",
            sy9: "",
            sy7: "",
            dt1: "heiser",
            lt10: "ĥarusho",
            id: "1943",
            sy6: "",
            lt12: "",
            sy8: "",
            sy5: "ܚܰܪܽܘܫܳܐ",
            sy4: "ܚܪܘܫܐ",
            dt2: ""
        },
        {
            dt3: "",
            sy8: "",
            sy6: "",
            lt10: "fasto",
            sy9: "",
            lt11: "",
            sy5: "ܦܰܣܬܳܐ",
            sy4: "ܦܣܬܐ",
            lt12: "",
            dt2: "Tuch",
            sy7: "",
            dt1: "Schwamm",
            id: "4064"
        },
        {
            dt3: "",
            dt1: "Imperium",
            dt2: "",
            sy7: "",
            sy8: "",
            sy5: "ܫܽܘܠܛܳܢܽܘܬ݂ܳܐ",
            lt12: "",
            lt11: "",
            sy4: "ܫܘܠܛܢܘܬܐ",
            id: "2173",
            lt10: "shulțonutho",
            sy6: "",
            sy9: ""
        },
        {
            sy6: "ܡܬܩܢܛܝܐ",
            sy5: "ܩܰܢܛܝܳܐ",
            lt10: "qanțoyo",
            sy7: "ܡܶܬ݂ܩܰܢܛܳܝܳܐ",
            dt1: "gefährlich",
            id: "1514",
            lt12: "",
            sy8: "",
            dt3: "",
            sy9: "",
            dt2: "risikoreich",
            sy4: "ܩܢܛܝܐ",
            lt11: "methqanțoyo"
        },
        {
            sy6: "ܬ",
            sy5: "ܐܰܪܒܰܥܡܳܐܐ ",
            sy9: "",
            dt3: "",
            lt11: "taw",
            lt12: "",
            dt2: "Vierhundert",
            id: "41",
            sy8: "",
            dt1: "400",
            sy7: "ܬ",
            sy4: "ܐܪܒܥܡܐܐ",
            lt10: "arbaĉmo"
        },
        {
            sy6: "",
            dt2: "",
            sy7: "",
            sy4: "ܢܝܚܐ ܥܠܡܝܢܝܐ",
            lt10: "nyoĥo ĉolminoyo",
            dt1: "ewige Ruhe",
            sy5: "ܢܝܳܚܳܐ ܥܳܠܡܺܝܢܳܝܳܐ",
            lt11: "",
            id: "1210",
            lt12: "",
            dt3: "",
            sy9: "",
            sy8: ""
        },
        {
            sy9: "",
            dt2: "",
            sy8: "",
            dt3: "",
            id: "6295",
            dt1: "zivilisieren",
            sy4: "ܥܒܕ ܡܕܝܢܝܐ",
            sy5: "ܥܒܰܕ ܡܕܺܝܢܳܝܳܐ",
            lt11: "",
            sy6: "",
            sy7: "",
            lt12: "",
            lt10: "ĉbađ mđinoyo"
        },
        {
            sy5: "ܫܳܢܝܽܘܬ݂ܳܐ",
            sy9: "",
            lt11: "",
            sy4: "ܫܢܝܘܬܐ",
            sy6: "",
            id: "4948",
            sy7: "",
            dt1: "Wahnsinn",
            dt2: "",
            lt12: "",
            dt3: "",
            lt10: "shonyutho",
            sy8: ""
        },
        {
            sy9: "",
            sy8: "",
            sy4: "ܥܕܠ",
            id: "2650",
            lt10: "ĉdal",
            lt11: "",
            lt12: "",
            dt3: "",
            dt1: "kritisieren",
            sy7: "",
            dt2: "",
            sy5: "ܥܕܰܠ",
            sy6: ""
        },
        {
            sy6: "",
            lt12: "",
            lt11: "ܚܡܫܐ",
            sy7: "",
            sy9: "",
            dt2: "",
            lt10: "ܚܡܫܐ",
            dt1: "Hamscho",
            sy5: "ܚܰܡܫܳܐ",
            dt3: "",
            id: "1839",
            sy8: "",
            sy4: "ܚܡܫܐ"
        },
        {
            dt3: "",
            dt1: "Jonathan",
            lt12: "ܝܘܢܬܢ",
            sy8: "",
            lt10: "ܝܘܢܘܢ",
            dt2: "Yonathan",
            sy9: "",
            sy5: "ܝܳܘܢܰܬܰܢ",
            id: "2335",
            sy6: "ܝܘܢܬܢ",
            lt11: "ܝܘܢܘܢ",
            sy4: "ܝܘܢܘܢ",
            sy7: "ܝܽܘܢܳܬܳܢ"
        },
        {
            sy9: "",
            lt10: "qoyminan u othin l`horko",
            sy4: "ܩܝܡܝܢܢ ܘܐܬܝܢܢ ܠܗܪܟܐ",
            dt3: "",
            sy5: "ܩܳܝܡܺܝܢܰܢ ܘܐܳܬ݂ܺܝܢܰܢ ܠܗܳܪܟܳܐ",
            sy8: "",
            dt1: "wir stehen auf und kommen hierher",
            lt12: "",
            lt11: "",
            sy6: "",
            dt2: "",
            sy7: "",
            id: "5125"
        },
        {
            dt2: "Bodyguard",
            lt12: "noțuro d`nosho",
            lt10: "noțar fağro",
            sy8: "ܢܛܘܪܐ ܕܢܫܐ",
            sy7: "ܢܳܛܰܪ ܓܽܘܫܡܳܐ",
            dt1: "Leibwächter",
            lt11: "noțar gushmo",
            sy6: "ܢܛܪ ܓܘܫܡܐ",
            sy9: "ܢܳܛܽܘܪܳܐ ܕܢܳܫܳܐ",
            id: "2748",
            sy4: "ܢܛܪ ܦܓܪܐ",
            dt3: "Personenschützer",
            sy5: "ܢܳܛܰܪ ܦܰܓ݂ܪܳܐ"
        },
        {
            sy8: "",
            sy7: "",
            lt12: "",
            sy9: "",
            dt1: "Himmelsfahrt",
            lt10: "suloqo d`shmayo",
            dt2: "",
            id: "2001",
            lt11: "",
            sy5: "ܣܽܘܠܳܩܳܐ ܕܫܡܰܝܳܐ",
            dt3: "",
            sy6: "",
            sy4: "ܣܘܠܩܐ ܕܫܡܝܐ"
        },
        {
            sy6: "",
            lt10: "gauno shoĥmo akh qhutho",
            dt3: "",
            dt2: "",
            sy5: "ܓܰܘܢܳܐ ܫܳܚܡܳܐ ܐܰܝܟ ܩܗܽܘܬ݂ܳܐ",
            sy4: "ܓܘܢܐ ܫܚܡܐ ܐܝܟ ܩܗܘܬܐ",
            dt1: "braune Farbe wie der Kaffee",
            sy9: "",
            sy8: "",
            lt12: "",
            id: "691",
            lt11: "",
            sy7: ""
        },
        {
            sy6: "",
            dt2: "",
            sy8: "",
            lt12: "",
            dt1: "Lichtjahr",
            id: "2806",
            sy9: "",
            sy7: "",
            lt10: "shato nahirto",
            sy5: "ܫܢ̱ܰܬܳܐ ܢܰܗܺܝܪܬܳܐ",
            lt11: "",
            dt3: "",
            sy4: "ܫܢ̱ܬܐ ܢܗܝܪܬܐ"
        },
        {
            sy6: "",
            sy9: "",
            sy5: "ܬܡܳܢܺܝܢܳܝܳܐ",
            sy4: "ܬܡܢܝܢܝܐ",
            dt3: "",
            lt12: "",
            dt2: "",
            sy8: "",
            sy7: "",
            id: "5738",
            lt11: "",
            lt10: "tmoninoyo",
            dt1: "achtzigster"
        },
        {
            lt11: "ܡܪܓܢܝܬܐ",
            sy9: "",
            id: "2931",
            sy6: "",
            lt10: "ܡܪܓܢܝܬܐ",
            sy4: "ܡܪܓܢܝܬܐ",
            sy5: "ܡܰܪܓܳܢܺܝܬܰܐ",
            sy8: "",
            sy7: "",
            dt2: "",
            dt3: "",
            lt12: "",
            dt1: "Margonita"
        },
        {
            sy6: "",
            lt12: "",
            id: "3395",
            sy7: "",
            dt3: "",
            sy8: "",
            sy5: "ܥܺܕܬܳܐ ܐܳܪܬܳܕܽܘܟܣܶܝܬܳܐ",
            dt1: "Orthodoxe Kirche",
            dt2: "",
            lt10: "ĉito ortudukseyto",
            sy4: "ܥܕܬܐ ܐܪܬܘܕܘܟܣܝܬܐ",
            sy9: "",
            lt11: ""
        },
        {
            dt1: "kleben",
            sy4: "ܕܒܩ",
            dt2: "",
            lt10: "dbeq",
            sy9: "",
            sy8: "",
            sy6: "ܡܕܒܩ",
            sy7: "ܡܕܰܒܶܩ",
            dt3: "",
            lt12: "",
            sy5: "ܕܒܶܩ",
            id: "2504",
            lt11: "mdabeq"
        },
        {
            sy4: "ܢܟܦܘܬܐ",
            sy7: "",
            dt3: "",
            lt10: "nakhfutho",
            sy5: "ܢܰܟ݂ܦܽܘܬ݂ܳܐ",
            dt2: "",
            sy8: "",
            sy9: "",
            lt12: "",
            lt11: "",
            sy6: "",
            dt1: "Reinheit",
            id: "3674"
        },
        {
            lt12: "",
            sy8: "",
            dt3: "",
            dt2: "Schwimmbad",
            dt1: "Schwimmhalle",
            lt10: "beth saĥwo",
            sy4: "ܒܝܬ ܣܚܘܐ",
            sy5: "ܒܶܝܬ݂ ܣܰܚܘܳܐ",
            sy9: "",
            sy7: "ܒܶܝܬ݂ ܡܰܣܚܝܳܐ",
            sy6: "ܒܝܬ ܡܣܚܝܐ",
            id: "6211",
            lt11: "beth masĥyo"
        },
        {
            id: "3340",
            sy8: "",
            lt11: "ܐܕܝ",
            dt1: "Odi",
            lt12: "",
            lt10: "ܐܕܝ",
            sy6: "",
            sy4: "ܐܕܝ",
            dt3: "",
            sy7: "",
            sy9: "",
            dt2: "",
            sy5: "ܐܳܕܺܝ"
        },
        {
            sy9: "",
            sy8: "",
            sy4: "ܙܒܕܝ",
            lt10: "ܙܒܕܝ",
            dt3: "",
            dt1: "Zabdai",
            sy6: "",
            sy7: "",
            dt2: "",
            lt11: "ܙܒܕܝ",
            sy5: "ܙܰܒܕܰܝ",
            id: "5218",
            lt12: ""
        },
        {
            sy7: "ܡܫܰܠܚܳܐ",
            sy9: "ܓܰܠܝܳܐ",
            id: "639",
            sy5: "ܥܰܪܛܶܠܳܝܳܐ",
            dt1: "blank",
            lt10: "ĉarțeloyo",
            sy6: "ܡܫܠܚܐ",
            sy8: "ܓܠܝܐ",
            lt11: "mshalĥo",
            sy4: "ܥܪܛܠܝܐ",
            dt2: "entblößt",
            lt12: "galyo",
            dt3: "nackt"
        },
        {
            id: "5896",
            dt2: "",
            sy6: "",
            lt12: "",
            sy7: "",
            sy9: "",
            dt1: "an diesem Abend",
            lt11: "",
            sy8: "",
            sy5: "ܒܗܳܢ ܪܰܡܫܳܐ",
            lt10: "bhon ramsho",
            sy4: "ܒܗܢ ܪܡܫܐ",
            dt3: ""
        },
        {
            sy8: "",
            dt2: "veränderbar",
            sy5: "ܡܫܰܚܠܦܳܐ",
            sy7: "ܡܶܫܬܰܚܠܦܳܢܳܐ",
            id: "4704",
            sy4: "ܡܫܚܠܦܐ",
            sy9: "",
            sy6: "ܡܫܬܚܠܦܢܐ",
            lt12: "",
            lt10: "mshaĥlfo",
            dt1: "variabel",
            dt3: "",
            lt11: "meshtaĥlfono"
        },
        {
            sy8: "",
            sy9: "",
            dt1: "Schule",
            dt3: "",
            lt12: "",
            dt2: "",
            sy4: "ܡܕܪܫܬܐ",
            id: "4048",
            sy7: "ܒܶܝܬ݂ ܣܶܦܪܳܐ",
            sy6: "ܒܝܬ ܣܦܪܐ",
            lt11: "beth sefro",
            sy5: "ܡܰܕܪܰܫܬܳܐ",
            lt10: "madrashto"
        },
        {
            lt12: "",
            sy5: "ܠܳܐ ܡܶܬ݂ܡܰܠܳܠܢܳܐ",
            dt3: "",
            lt11: "",
            id: "4637",
            sy4: "ܠܐ ܡܬܡܠܠܢܐ",
            sy7: "",
            lt10: "lo methmallono",
            sy9: "",
            dt1: "unbeschreiblich",
            sy8: "",
            sy6: "",
            dt2: ""
        },
        {
            id: "5226",
            dt1: "zählen",
            dt2: "",
            lt11: "ĥshab",
            lt10: "mno",
            sy8: "",
            sy9: "",
            sy6: "ܚܫܒ",
            lt12: "",
            dt3: "",
            sy7: "ܚܫܰܒ",
            sy5: "ܡܢܳܐ",
            sy4: "ܡܢܐ"
        },
        {
            dt3: "",
            dt1: "Brett",
            dt2: "",
            sy9: "",
            lt12: "",
            lt11: "",
            lt10: "dafo",
            sy8: "",
            sy6: "",
            sy4: "ܕܦܐ",
            id: "705",
            sy7: "",
            sy5: "ܕܰܦܳܐ"
        },
        {
            lt10: "qatițo",
            dt3: "",
            sy8: "",
            sy6: "",
            dt2: "Senkrechte",
            dt1: "senkrecht",
            sy4: "ܩܐܬܝܛܐ",
            lt12: "",
            lt11: "",
            id: "4119",
            sy7: "",
            sy9: "",
            sy5: "ܩܰܐܬܺܝܛܳܐ"
        },
        {
            sy4: "ܐܚܬ",
            sy8: "",
            lt12: "",
            sy5: "ܐܰܚܶܬ",
            id: "5581",
            sy6: "ܐܛܥܢ",
            sy9: "",
            dt2: "",
            sy7: "ܐܰܛܥܶܢ",
            dt3: "",
            dt1: "abladen",
            lt10: "aĥet",
            lt11: "ațĉen"
        },
        {
            dt2: "",
            sy7: "",
            sy8: "",
            dt1: "Barhadbschabo",
            lt10: "ܒܪܚܕܒܫܒܐ",
            id: "449",
            dt3: "",
            sy6: "",
            sy4: "ܒܪܚܕܒܫܒܐ",
            sy9: "",
            lt12: "",
            lt11: "ܒܪܚܕܒܫܒܐ",
            sy5: "ܒܰܪܚܰܕܒܫܰܒܳܐ"
        },
        {
            sy4: "ܥܐܕܐ ܕܡܘܠܕܐ",
            sy8: "",
            sy9: "",
            lt10: "ĉiđo d`mauwlodo",
            dt2: "Geburtsfest",
            lt12: "",
            sy6: "",
            id: "5020",
            sy5: "ܥܺܐܕܳܐ ܕܡܰܘܠܳܕܳܐ",
            sy7: "",
            dt1: "Weihnachten",
            lt11: "",
            dt3: ""
        },
        {
            sy8: "",
            sy7: "",
            lt12: "",
            sy5: "ܙܳܟ݂ܽܘܬ݂ܳܐ",
            dt3: "",
            lt11: "",
            sy9: "",
            lt10: "sokhutho",
            sy6: "",
            id: "1623",
            dt2: "Sieg",
            sy4: "ܙܟܘܬܐ",
            dt1: "Gewinn "
        },
        {
            sy8: "",
            sy9: "",
            dt2: "Bin mir nicht sicher",
            lt11: "latno mamno",
            id: "2102",
            sy5: "ܠܰܬܢܳܐ ܐܰܡܢܳܐ",
            sy4: "ܠܬܢܐ ܐܡܢܐ",
            sy6: "ܠܬܢܐ ܡܡܢܐ",
            dt3: "",
            lt10: "latno amno",
            dt1: "Ich bin mir nicht sicher",
            sy7: "ܠܰܬܢܳܐ ܡܰܡܢܳܐ",
            lt12: ""
        },
        {
            sy6: "ܬܬ",
            sy5: "ܬܡܳܢܶܡܳܐܐ ",
            dt3: "",
            dt1: "800",
            sy9: "",
            lt12: "",
            sy8: "",
            dt2: "Achthundert",
            lt10: "tmonemo",
            sy7: "ܬܬ",
            id: "45",
            lt11: "taw-taw",
            sy4: "ܬܡܢܡܐܐ"
        },
        {
            dt3: "Gefügigkeit",
            sy5: "ܡܰܫܡܰܥܬܳܐ",
            lt10: "mashmaĉto",
            id: "1537",
            lt11: "",
            dt1: "Gehorsam",
            sy7: "",
            sy9: "",
            dt2: "Folgsamkeit",
            sy4: "ܡܫܡܥܬܐ",
            lt12: "",
            sy6: "",
            sy8: ""
        },
        {
            lt12: "",
            sy9: "",
            sy7: "ܕܠܰܐ ܡܶܚܳܐ",
            id: "978",
            sy5: "ܕܠܳܐ ܗܰܘܢܳܐ",
            sy8: "",
            dt2: "",
            sy6: "ܕܠܐ ܡܚܐ",
            sy4: "ܕܠܐ ܗܘܢܐ",
            dt1: "dumm",
            lt11: "d`lo meĥo",
            lt10: "d`lo hauno",
            dt3: ""
        },
        {
            sy9: "",
            sy5: "ܚܰܕܝܳܐ ܕܥܶܓܠܳܐ",
            lt12: "",
            sy6: "",
            dt1: "Kalbsbrust",
            sy4: "ܚܕܝܐ ܕܥܓܠܐ",
            id: "2386",
            sy7: "",
            sy8: "",
            lt10: "ĥadyo d`ĉeglo",
            lt11: "",
            dt3: "",
            dt2: ""
        },
        {
            dt1: "Zahn",
            sy6: "",
            sy4: "ܥܪܫܐ",
            dt3: "",
            lt12: "",
            lt10: "ĉarsho",
            id: "5231",
            sy8: "",
            dt2: "",
            sy5: "ܥܰܪܫܳܐ",
            lt11: "",
            sy7: "",
            sy9: ""
        },
        {
            lt11: "țuro yoquđo",
            dt1: "Vulkan",
            sy7: "ܛܽܘܪܳܐ ܝܳܩܽܘܕܳܐ",
            id: "4912",
            sy9: "",
            lt10: "folkano",
            sy8: "",
            lt12: "",
            sy6: "ܛܘܪܐ ܝܩܘܕܐ",
            sy5: "ܦܳܠܟܰܐܢܳܐ",
            dt3: "",
            sy4: "ܦܠܟܐܢܐ",
            dt2: ""
        },
        {
            id: "6220",
            sy8: "",
            sy7: "",
            sy6: "",
            dt3: "",
            sy4: "ܪܚܝܡ ܣܓܝ",
            sy9: "",
            lt10: "rĥim sagi",
            lt11: "",
            lt12: "",
            dt1: "sehr sympathisch",
            sy5: "ܪܚܺܝܡ ܣܰܓܺܝ",
            dt2: ""
        },
        {
            dt2: "",
            sy6: "",
            dt1: "Fahmi",
            dt3: "",
            sy8: "",
            sy7: "",
            lt10: "ܦܗܡܝ",
            sy5: "ܦܰܗܡܺܝ",
            lt12: "",
            sy9: "",
            sy4: "ܦܗܡܝ",
            id: "1230",
            lt11: "ܦܗܡܝ"
        },
        {
            sy4: "ܐܠܗܐ",
            sy7: "ܡܳܪܝܳܐ",
            id: "1686",
            lt12: "moran",
            dt1: "Gott",
            sy5: "ܐܰܠܳܗܳܐ",
            sy8: "ܡܪܢ",
            dt2: "Herr",
            lt10: "aloho",
            lt11: "moryo",
            sy9: "ܡܳܪܰܢ",
            sy6: "ܡܪܝܐ",
            dt3: ""
        },
        {
            id: "4387",
            sy8: "",
            sy7: "",
            sy9: "",
            dt3: "",
            lt12: "",
            sy4: "ܗܪܓܐ ܕܪܩܕܐ",
            dt1: "Tanzunterricht",
            dt2: "",
            lt11: "",
            sy6: "",
            lt10: "hergo d`reqđo",
            sy5: "ܗܶܪܓܳܐ ܕܪܶܩܕܳܐ"
        },
        {
            sy7: "ܦܰܩܺܝܪܽܘܬ݂ܳܐ",
            lt12: "",
            dt2: "",
            sy6: "ܦܩܝܪܘܬܐ",
            sy9: "",
            dt1: "Armut",
            lt10: "meskinutho",
            dt3: "",
            sy5: "ܡܶܣܟܺܢܽܘܬ݂ܳܐ",
            id: "297",
            sy4: "ܡܣܟܢܘܬܐ",
            sy8: "",
            lt11: "faqirutho"
        },
        {
            sy6: "",
            sy7: "",
            id: "693",
            lt11: "",
            dt1: "Braut",
            lt10: "kaltho",
            sy4: "ܟܠܬܐ",
            dt2: "",
            dt3: "",
            sy9: "",
            sy8: "",
            sy5: "ܟܰܠܬ݂ܳܐ",
            lt12: ""
        },
        {
            sy6: "ܢܦܫܗ",
            dt2: "",
            lt10: "bnafshe",
            lt11: "nafshe",
            id: "4107",
            lt12: "",
            dt1: "selber",
            sy5: "ܒܢܰܦܫܶܗ",
            dt3: "",
            sy7: "ܢܰܦܫܶܗ",
            sy4: "ܒܢܦܫܗ",
            sy9: "",
            sy8: ""
        },
        {
            dt3: "",
            sy7: "",
            sy8: "",
            dt2: "",
            id: "290",
            dt1: "Arim",
            sy9: "",
            lt11: "ܐܪܝܡ",
            lt10: "ܐܪܝܡ",
            lt12: "",
            sy5: "ܐܰܪܺܝܡ",
            sy6: "",
            sy4: "ܐܪܝܡ"
        },
        {
            id: "4615",
            dt3: "Eminenz",
            sy5: "ܡܝܰܬܪܽܘܬ݂ܳܐ",
            sy4: "ܡܝܬܪܘܬܐ",
            sy6: "ܡܥܠܝܘܬܐ",
            lt10: "myatrutho",
            sy7: "ܡܥܰܠܝܽܘܬ݂ܳܐ",
            lt12: "",
            dt2: "Exzellenz",
            sy8: "",
            dt1: "Überragung",
            sy9: "",
            lt11: "mĉalyutho"
        },
        {
            sy5: "ܩܰܪܰܪ",
            sy4: "ܩܪܪ",
            dt3: "",
            sy6: "ܟܪܪ",
            lt12: "ܟܪܪ",
            lt10: "ܩܪܪ",
            sy8: "",
            dt2: "",
            sy7: "ܟܰܪܰܪ",
            lt11: "ܩܪܪ",
            sy9: "",
            id: "2412",
            dt1: "Karar"
        },
        {
            sy8: "",
            lt11: "",
            dt1: "Tagesordnung",
            sy6: "",
            sy5: "ܬܰܚܪܰܙܬܳܐ ܕܝܰܘܡܳܐ",
            sy4: "ܬܚܪܙܬܐ ܕܝܘܡܐ",
            dt3: "",
            sy7: "",
            lt12: "",
            id: "5796",
            dt2: "",
            sy9: "",
            lt10: "taĥrazto dyaumo"
        },
        {
            lt11: "ܒܪܢܡܪܐ",
            dt1: "Barnemre",
            sy8: "",
            sy4: "ܒܪܢܡܪܐ",
            dt2: "",
            sy7: "",
            sy6: "",
            lt10: "ܒܪܢܡܪܐ",
            sy5: "ܒܰܪܢܶܡܪܶܐ",
            sy9: "",
            lt12: "",
            id: "465",
            dt3: ""
        },
        {
            lt10: "biĉto d`lo qlofe",
            sy9: "",
            dt2: "",
            sy7: "",
            sy4: "ܒܝܥܬܐ ܕܠܐ ܩܠܦܐ",
            dt1: "Ei ohne Schale",
            sy8: "",
            lt12: "",
            lt11: "",
            dt3: "",
            sy6: "",
            sy5: "ܒܺܝܥܬܳܐ ܕܠܳܐ ܩܠܳܦ̈ܶܐ",
            id: "1027"
        },
        {
            sy4: "ܒܛܠ ܦܘܩܕܐ",
            dt2: "",
            sy5: "ܒܰܛܶܠ ܦܽܘܩܳܕܳܐ",
            sy7: "",
            sy6: "",
            dt1: "Abbestellung",
            sy8: "",
            id: "5547",
            lt11: "",
            lt10: "bațel fuqodo",
            lt12: "",
            sy9: "",
            dt3: ""
        },
        {
            dt3: "",
            lt10: "sagi",
            sy5: "ܣܰܓܺܝ",
            lt11: "țob",
            sy9: "",
            sy4: "ܣܓܝ",
            id: "4096",
            sy6: "ܛܒ",
            lt12: "",
            dt1: "sehr",
            dt2: "",
            sy7: "ܛܳܒ",
            sy8: ""
        },
        {
            dt3: "",
            dt1: "Wachdienst",
            sy4: "ܢܛܘܪܘܬܐ",
            sy7: "",
            dt2: "",
            sy6: "",
            id: "4917",
            lt11: "",
            sy9: "",
            lt12: "",
            lt10: "nțurutho",
            sy8: "",
            sy5: "ܢܛܽܘܪܽܘܬ݂ܳܐ"
        },
        {
            sy7: "",
            dt2: "Auskunft",
            sy6: "",
            sy5: "ܡܰܘܕܥܳܢܽܘܬ݂ܳܐ",
            sy8: "",
            dt3: "",
            lt11: "",
            sy4: "ܡܘܕܥܢܘܬܐ",
            dt1: "Information",
            lt12: "",
            id: "2201",
            lt10: "mauđĉonutho",
            sy9: ""
        },
        {
            dt2: "",
            sy9: "",
            sy5: "ܒܰܝܬܳܐ ܕܦܰܠܳܚܳܐ",
            lt11: "beth folĥe",
            sy4: "ܒܝܬܐ ܕܦܠܚܐ",
            id: "2424",
            sy6: "ܒܝܬ ܦܠܚܐ",
            dt1: "Kaserne",
            sy7: "ܒܶܝܬ݂ ܦܳܠܚܶܐ",
            sy8: "",
            dt3: "",
            lt10: "bayto d`folĥe",
            lt12: ""
        },
        {
            dt1: "in Klammern",
            lt10: "beth qeshțothe",
            sy4: "ܒܝܬ ܩܫܬܬܐ",
            sy9: "",
            sy6: "",
            lt11: "",
            sy7: "",
            sy5: "ܒܶܝܬ݂ ܩܶܫܬܳܬ݂ܶܐ",
            sy8: "",
            id: "2186",
            dt2: "",
            dt3: "",
            lt12: ""
        },
        {
            dt2: "multilingual",
            id: "2989",
            sy4: "ܒܠܫܢܐ ܡܫܚܠܦܐ",
            dt3: "",
            lt10: "b`leshone mshaĥlfe",
            dt1: "mehrsprachig",
            sy7: "",
            sy6: "",
            sy5: "ܒܠܶܫܳܢܳܐ ܡܫܰܚܠܦܶܐ",
            sy9: "",
            lt12: "",
            sy8: "",
            lt11: ""
        },
        {
            sy6: "",
            lt11: "",
            dt3: "",
            lt10: "shalțo arikho",
            sy8: "",
            id: "2707",
            sy5: "ܫܰܠܛܳܐ ܐܰܪܺܝܟ݂ܳܐ",
            sy7: "",
            dt1: "langer Mantel",
            dt2: "",
            sy4: "ܫܠܛܐ ܐܪܝܟܐ",
            lt12: "",
            sy9: ""
        },
        {
            lt10: "ĉomure d`arĉo",
            sy4: "ܥܡܘܪܐ ܕܐܪܥܐ",
            id: "5049",
            sy6: "",
            lt12: "",
            sy9: "",
            dt1: "Weltbevölkerung",
            sy7: "",
            dt2: "",
            lt11: "",
            sy8: "",
            sy5: "ܥܳܡܽܘ̈ܪܶܐ ܕܐܰܪܥܳܐ",
            dt3: ""
        },
        {
            sy6: "ܦܪܐ",
            sy8: "",
            dt3: "",
            id: "2699",
            sy5: "ܐܶܡܪܳܐ",
            lt10: "emro",
            dt2: "",
            sy7: "ܦܰܪܳܐ",
            sy4: "ܐܡܪܐ",
            lt11: "faro",
            sy9: "",
            dt1: "Lamm",
            lt12: ""
        },
        {
            lt10: "ܢܬܢܐܝܠ",
            sy9: "",
            sy7: "",
            sy8: "",
            sy4: "ܢܬܢܐܝܠ",
            lt11: "ܢܬܢܐܝܠ",
            id: "3215",
            sy5: "ܢܰܬܰܢܰܐܝܶܠ",
            dt2: "",
            sy6: "",
            dt3: "",
            dt1: "Nathanael",
            lt12: ""
        },
        {
            sy5: "ܝܺܗܽܘܕܳܝܽܘܬ݂ܳܐ",
            dt2: "",
            sy6: "",
            id: "2346",
            dt3: "",
            dt1: "Judentum",
            sy4: "ܝܗܘܕܝܘܬܐ",
            lt10: "ihuđoyutho",
            sy7: "",
            sy8: "",
            lt11: "",
            sy9: "",
            lt12: ""
        },
        {
            lt10: "ĉam iqoro",
            sy5: "ܥܰܡ ܐܺܝܩܳܪܳܐ",
            sy9: "",
            dt2: "respektvoll",
            sy6: "",
            lt11: "",
            lt12: "",
            id: "2031",
            sy8: "",
            sy4: "ܥܡ ܐܝܩܪܐ",
            sy7: "",
            dt3: "",
            dt1: "hochachtungsvoll"
        },
        {
            sy8: "",
            sy9: "",
            dt3: "",
            sy4: "ܙܘܟܝܐ",
            dt2: "",
            lt12: "",
            sy5: "ܙܽܘܟ݂ܳܝܳܐ",
            lt11: "neŝĥono",
            dt1: "Triumph",
            sy6: "ܢܨܚܢܐ",
            lt10: "zukoyo",
            sy7: "ܢܶܨܚܳܢܳܐ",
            id: "4569"
        },
        {
            dt1: "Bohne",
            lt10: "gumo",
            sy4: "ܓܘܡܐ",
            lt11: "",
            sy8: "",
            sy5: "ܓܽܘܡܳܐ",
            lt12: "",
            sy9: "",
            id: "672",
            sy7: "",
            dt2: "",
            dt3: "",
            sy6: ""
        },
        {
            lt12: "",
            dt1: "Reservierung",
            lt10: "shumoro",
            id: "3700",
            sy5: "ܫܘܡܳܪܳܐ",
            sy8: "",
            sy9: "",
            sy4: "ܫܘܡܪܐ",
            dt2: "",
            lt11: "",
            sy7: "",
            dt3: "",
            sy6: ""
        },
        {
            sy8: "",
            sy5: "ܬܠܺܝܬ݂ܳܝܳܐ",
            sy4: "ܬܠܝܬܝܐ",
            sy6: "",
            sy7: "",
            dt1: "Dritter",
            lt12: "",
            lt10: "tlithoyo",
            sy9: "",
            dt2: "",
            id: "962",
            lt11: "",
            dt3: ""
        },
        {
            dt1: "Einladung",
            sy4: "ܙܡܝܢܘܬܐ",
            sy7: "",
            dt3: "",
            lt11: "",
            lt10: "sminutho",
            id: "1044",
            lt12: "",
            sy6: "",
            sy8: "",
            dt2: "",
            sy9: "",
            sy5: "ܙܡܺܝܢܽܘܬ݂ܳܐ"
        },
        {
            dt2: "vergeßlich",
            sy9: "",
            dt3: "",
            sy4: "ܡܢܫܝܢܐ",
            lt11: "",
            dt1: "vergesslich",
            sy6: "",
            lt10: "mnashyono",
            id: "6345",
            lt12: "",
            sy7: "",
            sy8: "",
            sy5: "ܡܢܰܫܝܳܢܳܐ"
        },
        {
            lt12: "",
            sy4: "ܡܚܘܬܐ ܩܕܡܝܬܐ",
            dt3: "",
            sy7: "",
            id: "1178",
            sy8: "",
            dt1: "Erstschlag",
            sy5: "ܡܚܽܘܬܳܐ ܩܰܕܡܳܝܬܳܐ",
            sy6: "",
            lt10: "mĥuto qađmoyto",
            sy9: "",
            dt2: "",
            lt11: ""
        },
        {
            dt3: "",
            lt11: "meshtaĥlfonutho",
            dt2: "",
            lt12: "ĉurfoyo",
            sy4: "ܡܫܚܠܦܘܬܐ",
            sy7: "ܡܶܫܬܰܚܠܦܳܢܽܘܬ݂ܳܐ",
            sy5: "ܡܫܰܚܠܦܽܘܬ݂ܳܐ",
            dt1: "Abwechslung",
            sy6: "ܡܫܬܚܠܦܢܘܬܐ",
            lt10: "mshaĥlfutho",
            sy9: "ܥܽܘܪܦܳܝܳܐ",
            id: "5700",
            sy8: "ܥܘܪܦܝܐ"
        },
        {
            lt11: "",
            sy6: "",
            id: "3693",
            lt12: "",
            dt1: "Rennwagen",
            sy5: "ܪܰܕܳܝܬܐ ܕܡܽܘܪܳܝܳܐ",
            sy4: "ܪܕܝܬܐ ܕܡܘܪܝܐ",
            sy7: "",
            dt3: "",
            dt2: "Rennauto",
            sy9: "",
            lt10: "rađoyto d`muroyo",
            sy8: ""
        },
        {
            lt12: "",
            sy6: "",
            lt11: "",
            dt1: "in Richtung Osten",
            id: "2189",
            dt3: "",
            sy7: "",
            sy5: "ܠܡܰܕܢܚܳܐ",
            dt2: "",
            sy4: "ܠܡܕܢܚܐ",
            sy9: "",
            sy8: "",
            lt10: "l`madnĥo"
        },
        {
            lt10: "fruțoyo",
            sy7: "",
            lt12: "",
            sy5: "ܦܪܽܘܛܳܝܳܐ",
            sy9: "",
            sy4: "ܦܪܘܛܝܐ",
            lt11: "",
            sy8: "",
            dt2: "",
            id: "3529",
            sy6: "",
            dt1: "Protestant",
            dt3: ""
        },
        {
            sy8: "",
            sy5: "ܟܰܗܪܳܒܳܝܳܐ",
            id: "1070",
            sy7: "",
            sy4: "ܟܗܪܒܝܐ",
            lt11: "",
            sy9: "",
            dt1: "elektrisch",
            lt12: "",
            sy6: "",
            dt3: "",
            dt2: "",
            lt10: "kahroboyo"
        },
        {
            sy6: "",
            id: "432",
            sy9: "",
            dt3: "",
            lt10: "muzo",
            sy8: "",
            lt11: "",
            lt12: "",
            sy5: "ܡܽܘܙܳܐ",
            dt1: "Banane",
            sy4: "ܡܘܙܐ",
            sy7: "",
            dt2: ""
        },
        {
            id: "4988",
            dt1: "Was",
            sy9: "",
            dt2: "",
            sy7: "ܡܳܢ",
            dt3: "",
            sy6: "ܡܢ",
            sy4: "ܡܢܐ",
            lt10: "mono",
            sy5: "ܡܳܢܳܐ",
            sy8: "",
            lt12: "",
            lt11: "mon"
        },
        {
            dt2: "",
            lt11: "ܐܘܫܥܢܐ",
            sy6: "",
            sy9: "",
            lt12: "",
            dt3: "",
            dt1: "Uschano",
            sy8: "",
            sy7: "",
            lt10: "ܐܘܫܥܢܐ",
            sy4: "ܐܘܫܥܢܐ",
            id: "4703",
            sy5: "ܐܽܘܫܰܥܢܳܐ"
        },
        {
            sy4: "ܠܘܩܐ",
            sy6: "",
            sy5: "ܠܽܘܟܰܣ",
            dt3: "",
            sy8: "",
            dt1: "Lukas",
            id: "2874",
            sy9: "",
            lt12: "",
            lt10: "ܠܘܩܐ",
            dt2: "",
            lt11: "ܠܘܟܣ",
            sy7: ""
        },
        {
            dt2: "Dringlichkeit",
            sy4: "ܐܳܠܨܳܝܽܘܬ̣ܳܐ",
            dt3: "",
            sy5: "ܐܳܠܨܳܝܽܘܬ̣ܳܐ",
            sy8: "",
            sy6: "",
            id: "5479",
            lt11: "",
            sy7: "",
            lt10: "olŝoyutho",
            sy9: "",
            dt1: "Wichtigkeit",
            lt12: ""
        },
        {
            id: "587",
            sy8: "",
            dt3: "",
            dt2: "",
            lt12: "",
            sy9: "",
            dt1: "Betriebsgeheimnis",
            sy6: "",
            sy5: "ܐ̱ܪܳܙܳܐ ܕܒܶܝܬ݂ ܦܽܘܠܚܳܢܳܐ",
            sy7: "",
            sy4: "ܐܪܙܐ ܕܒܝܬ ܦܘܠܚܢܐ",
            lt10: "rozo d`beth fulĥono",
            lt11: ""
        },
        {
            dt2: "",
            lt12: "",
            sy8: "",
            dt3: "",
            sy5: "ܩܕܳܡ ܒܶܝܬ݂ ܕܳܝܢܳܐ",
            sy4: "ܩܕܡ ܒܝܬ ܕܝܢܐ",
            dt1: "vor dem Gericht",
            lt10: "qđom beth dino",
            sy7: "",
            lt11: "",
            sy9: "",
            id: "4891",
            sy6: ""
        },
        {
            lt12: "ܡܕܝܕ",
            lt11: "ܡܬܝܬܐ",
            dt2: "Medyad",
            dt3: "",
            sy6: "ܡܕܝܕ",
            sy4: "ܡܬܝܬܐ",
            sy9: "",
            sy7: "ܡܶܕܝܰܕ",
            sy5: "ܡܰܬܺܝܰܬܶܗ",
            sy8: "",
            dt1: "Matiate",
            id: "2968",
            lt10: "ܡܬܝܬܐ"
        },
        {
            sy7: "ܩܰܢܳܝܽܘܬ݂ܳܐ",
            dt3: "",
            sy6: "ܩܢܝܘܬܐ",
            sy5: "ܙܽܘܘܳܕܳܐ",
            lt12: "maqnoyutho",
            sy8: "ܡܩܢܝܘܬܐ",
            lt11: "qanoyutho",
            sy4: "ܙܘܘܕܐ",
            dt2: "",
            lt10: "zuwodo",
            sy9: "ܡܰܩܢܳܝܽܘܬ݂ܳܐ",
            id: "5987",
            dt1: "Anschaffung"
        },
        {
            lt12: "",
            id: "223",
            dt1: "Ananas",
            lt11: "",
            dt3: "",
            sy5: "ܐܰܢܰܢܰܣ",
            sy8: "",
            sy6: "",
            sy4: "ܐܢܢܣ",
            sy9: "",
            dt2: "",
            lt10: "Ananas",
            sy7: ""
        },
        {
            sy8: "",
            lt11: "țanonoith",
            lt12: "",
            dt2: "",
            lt10: "țanono",
            sy5: "ܛܰܢܳܢܳܐ",
            sy7: "ܛܰܢܳܢܳܐܝܺܬ݂",
            dt3: "",
            sy4: "ܛܢܢܐ",
            dt1: "ehrgeizig",
            sy9: "",
            sy6: "ܛܢܢܐܝܬ",
            id: "1024"
        },
        {
            lt11: "ܢܪܓܝܣ",
            dt2: "Nergis",
            sy7: "",
            id: "3243",
            sy5: "ܢܰܪܓܝܣ",
            sy9: "",
            sy4: "ܢܪܓܝܣ",
            sy8: "",
            lt12: "",
            sy6: "",
            lt10: "ܢܪܓܝܣ",
            dt1: "Nergiz",
            dt3: ""
        },
        {
            dt3: "",
            lt12: "",
            sy9: "",
            sy7: "",
            dt2: "Fügsamkeit",
            lt10: "mashmaĉto",
            sy5: "ܡܰܫܡܰܥܬܳܐ",
            sy6: "",
            id: "239",
            sy8: "",
            lt11: "",
            dt1: "Anhörung",
            sy4: "ܡܫܡܥܬܐ"
        },
        {
            sy8: "",
            sy6: "",
            sy9: "",
            dt3: "",
            lt10: "laĥmo",
            lt11: "",
            dt2: "",
            id: "714",
            lt12: "",
            sy4: "ܠܚܡܐ",
            sy7: "",
            dt1: "Brot",
            sy5: "ܠܰܚܡܳܐ"
        },
        {
            dt1: "Rihane",
            dt2: "",
            sy5: "ܪܺܝܚܰܢܶܐ",
            sy7: "",
            id: "3722",
            dt3: "",
            sy4: "ܪܝܚܢܐ",
            lt10: "ܪܝܚܢܐ",
            lt12: "",
            lt11: "ܪܝܚܢܐ",
            sy9: "",
            sy8: "",
            sy6: ""
        },
        {
            sy6: "",
            lt10: "țobo",
            dt1: "gut",
            dt2: "",
            sy5: "ܛܳܒܳܐ",
            sy9: "",
            lt12: "",
            sy4: "ܛܒܐ",
            sy7: "",
            lt11: "",
            id: "1768",
            dt3: "",
            sy8: ""
        },
        {
            lt12: "",
            sy7: "",
            sy6: "",
            dt2: "",
            lt10: "at",
            sy4: "ܐܢܬ",
            id: "965",
            sy9: "",
            sy8: "",
            lt11: "",
            dt1: "Du",
            dt3: "",
            sy5: "ܐܰܢ̱ܬ"
        },
        {
            lt11: "",
            dt2: "",
            dt1: "Hand küssen",
            id: "1850",
            sy9: "",
            sy7: "",
            sy4: "ܢܫܩ ܐܝܕܐ",
            lt10: "nshaq iđo",
            lt12: "",
            sy5: "ܢܫܰܩ ܐܺܝܕܳܐ",
            dt3: "",
            sy8: "",
            sy6: ""
        },
        {
            sy9: "",
            lt11: "ܣܬܐ",
            dt2: "Sitto",
            dt1: "Setto",
            dt3: "",
            sy7: "",
            sy5: "ܣܬܐ",
            sy4: "ܣܬܐ",
            lt12: "",
            sy6: "",
            id: "4129",
            sy8: "",
            lt10: "ܣܬܐ"
        },
        {
            sy8: "",
            sy5: "ܝܽܘܡ ܓܽܘܒܳܝܳܐ",
            sy7: "",
            sy6: "",
            id: "4945",
            lt10: "yum guboyo",
            sy9: "",
            lt11: "",
            sy4: "ܝܘܡ ܓܘܒܝܐ",
            dt3: "",
            dt1: "Wahltag",
            lt12: "",
            dt2: ""
        },
        {
            sy9: "",
            lt10: "ĉqoro",
            lt11: "",
            sy8: "",
            sy5: "ܥܩܳܪܳܐ",
            sy6: "",
            id: "6450",
            sy4: "ܥܩܪܐ",
            lt12: "",
            sy7: "",
            dt2: "",
            dt1: "Entwurzelung",
            dt3: ""
        },
        {
            lt10: "țĥar",
            dt3: "",
            dt1: "gequält werden",
            id: "6112",
            sy6: "ܐܬܢܘܠ",
            lt12: "",
            sy4: "ܛܚܪ",
            sy7: "ܐܶܬ݂ܢܰܘܰܠ",
            dt2: "",
            sy5: "ܛܚܰܪ",
            sy8: "",
            lt11: "ethnawal",
            sy9: ""
        },
        {
            sy7: "",
            sy9: "",
            id: "2677",
            sy6: "",
            sy5: "ܙܰܒܢܳܐ ܟܰܪܝܳܐ",
            dt3: "",
            sy8: "",
            lt12: "",
            dt2: "",
            lt11: "",
            lt10: "zabno karyo",
            dt1: "kurze Zeit",
            sy4: "ܙܒܢܐ ܟܪܝܐ"
        },
        {
            dt2: "",
            sy5: "ܛܰܝܒܽܘܬ݂ܳܐ ܕܰܐܠܳܗܳܐ",
            lt11: "",
            dt3: "",
            lt12: "",
            id: "1679",
            dt1: "Gnade Gottes",
            sy7: "",
            lt10: "țaybutho d`aloho",
            sy4: "ܛܝܒܘܬܐ ܕܐܠܗܐ",
            sy8: "",
            sy6: "",
            sy9: ""
        },
        {
            sy8: "",
            sy9: "",
            lt10: "ܛܝܡܝ",
            lt12: "",
            dt2: "",
            sy7: "",
            sy6: "",
            id: "4491",
            sy4: "ܛܝܡܝ",
            dt3: "",
            lt11: "ܛܝܡܝ",
            dt1: "Timai",
            sy5: "ܛܺܝܡܰܝ"
        },
        {
            lt11: "",
            sy8: "",
            lt12: "",
            sy7: "",
            dt1: "Russe",
            dt3: "",
            id: "3770",
            sy4: "ܪܘܣܝܐ",
            lt10: "rusoyo",
            dt2: "",
            sy5: "ܪܽܘܣܳܝܳܐ",
            sy9: "",
            sy6: ""
        },
        {
            dt2: "",
            lt12: "",
            id: "1821",
            dt1: "Hagelkörner",
            sy6: "ܦܪܕܐ ܒܪܕܢܝܐ",
            lt11: "ferde barđonoye",
            sy5: "ܟܺܐܦ̈ܶܐ ܕܒܰܪܕܳܐ",
            sy8: "",
            lt10: "kife d`barđo",
            sy9: "",
            sy4: "ܟܐܦܐ ܕܒܪܕܐ",
            sy7: "ܦܶܪ̈ܕܶܐ ܒܰܪ̈ܕܳܢܳܝܶܐ",
            dt3: ""
        },
        {
            sy4: "ܨܠܘܬܐ ܕܪܡܫܐ",
            dt3: "",
            lt11: "teshmeshto ramshoyto",
            lt10: "ŝlutho d`ramsho",
            lt12: "",
            sy6: "ܬܫܡܫܬܐ ܪܡܫܝܬܐ",
            dt1: "Abendgebet",
            sy7: "ܬܶܫܡܶܫܬܳܐ ܪܰܡܫܳܝܬܳܐ",
            id: "75",
            sy9: "",
            sy5: "ܨܠܽܘܬ݂ܳܐ ܕܪܰܡܫܳܐ",
            sy8: "",
            dt2: ""
        },
        {
            sy9: "",
            sy8: "",
            dt3: "",
            sy7: "",
            lt10: "rushmoyo",
            dt2: "",
            sy5: "ܪܽܘܫܡܳܝܳܐ",
            dt1: "amtlich",
            sy6: "",
            sy4: "ܪܘܫܡܝܐ",
            id: "5882",
            lt11: "",
            lt12: ""
        },
        {
            lt11: "",
            id: "5790",
            sy8: "",
            dt2: "",
            dt1: "Verwandtschaft durch Heiratsschwägerschaft",
            sy7: "",
            lt10: "ĥyonutho",
            sy4: "ܚܝܢܘܬܐ",
            dt3: "",
            sy9: "",
            lt12: "",
            sy6: "",
            sy5: "ܐ̱ܚܝܳܢܽܘܬ݂ܳܐ"
        },
        {
            dt1: "Lara",
            lt12: "",
            sy6: "",
            dt2: "",
            dt3: "",
            lt10: "ܠܪܐ",
            sy8: "",
            sy9: "",
            sy5: "ܠܰܪܰܐ",
            lt11: "ܠܪܐ",
            id: "2713",
            sy7: "",
            sy4: "ܠܪܐ"
        },
        {
            sy4: "ܓܝܓܠܐ ܝܬܝܪܐ",
            lt11: "",
            dt2: "Ersatzreifen",
            sy5: "ܓܺܝܓ݂ܠܳܐ ܝܰܬܽܝܪܳܐ",
            id: "1169",
            dt3: "",
            sy8: "",
            sy6: "",
            dt1: "Ersatzrad",
            lt10: "giğlo yatiro",
            lt12: "",
            sy7: "",
            sy9: ""
        },
        {
            lt12: "",
            dt1: "Hammer",
            sy4: "ܐܟܠܐ",
            sy9: "",
            id: "1836",
            sy8: "",
            sy5: "ܐܰܟ݂ܠܳܐ",
            dt3: "",
            sy6: "",
            dt2: "",
            lt10: "akhlo",
            lt11: "",
            sy7: ""
        },
        {
            dt3: "",
            sy7: "",
            lt11: "",
            lt10: "țakso d`ĉarshe",
            lt12: "",
            sy4: "ܛܟܣܐ ܕܥܪܫܐ",
            sy5: "ܛܰܟܣܳܐ ܕܥܰܪܫܳܐ",
            dt1: "Zahnspange",
            id: "5239",
            sy6: "",
            sy9: "",
            dt2: "",
            sy8: ""
        },
        {
            dt2: "",
            lt12: "",
            id: "5492",
            sy7: "ܩܽܘܪܛܒܳܐ",
            sy5: "ܣܰܢܝܳܐ",
            lt10: "sanyo",
            lt11: "qurțbo",
            sy6: "ܩܘܪܛܒܐ",
            dt1: "Dornbusch",
            sy4: "ܣܢܝܐ",
            dt3: "",
            sy9: "",
            sy8: ""
        },
        {
            lt10: "qriĥo",
            sy6: "",
            dt2: "offen",
            sy9: "",
            lt12: "",
            sy5: "ܩܪܺܝܚܳܐ",
            dt3: "",
            sy8: "",
            sy4: "ܩܪܝܚܐ",
            sy7: "",
            dt1: "transparent",
            id: "4541",
            lt11: ""
        },
        {
            sy6: "",
            dt1: "Väter",
            sy5: "ܐܰܒ̈ܳܗܶܐ",
            id: "4707",
            sy4: "ܐܒܗܐ",
            dt2: "",
            sy9: "",
            lt11: "",
            lt10: "abohe",
            sy8: "",
            dt3: "",
            sy7: "",
            lt12: ""
        },
        {
            dt3: "",
            id: "5043",
            dt2: "Wasserwelle",
            sy6: "ܫܠܩܐ",
            lt11: "shalqo",
            dt1: "Welle",
            sy4: "ܓܠܠܐ",
            lt10: "galo",
            sy7: "ܫܰܠܩܳܐ",
            sy5: "ܓܰܠܠܳܐ",
            lt12: "",
            sy8: "",
            sy9: ""
        },
        {
            dt2: "stets",
            sy8: "",
            id: "4239",
            sy7: "ܐܰܡܺܝܢܳܐܝܺܬ݂",
            sy6: "ܐܡܝܢܐܝܬ",
            dt1: "ständig",
            sy5: "ܐܰܡܺܝܢܳܐ",
            sy4: "ܐܡܝܢܐ",
            lt11: "aminoith",
            lt12: "",
            dt3: "",
            lt10: "amino",
            sy9: ""
        },
        {
            sy7: "",
            sy8: "",
            sy5: "ܣܽܘܪܝܳܝܳܐ ܡܰܕܢܚܳܝܳܐ",
            id: "3398",
            lt11: "",
            dt1: "Ostaramäer",
            sy9: "",
            sy4: "ܣܘܪܝܝܐ ܡܕܢܚܝܐ",
            lt12: "",
            dt2: "Ostassyrer",
            sy6: "",
            lt10: "suryoyo madnĥoyo",
            dt3: ""
        },
        {
            sy9: "",
            lt12: "",
            sy4: "ܣܘܥܕ",
            sy5: "ܣܽܘܥܰܕ",
            sy8: "",
            sy6: "",
            dt2: "",
            dt3: "",
            lt11: "ܣܘܥܕ",
            id: "4303",
            dt1: "Suad",
            lt10: "ܣܘܥܕ",
            sy7: ""
        },
        {
            id: "1710",
            lt12: "",
            sy7: "",
            dt1: "gottlos",
            dt3: "Atheist",
            sy8: "",
            lt11: "",
            sy4: "ܚܢܦܐ",
            dt2: "Heide",
            lt10: "ĥanfo",
            sy6: "",
            sy9: "",
            sy5: "ܚܰܢܦܳܐ"
        },
        {
            sy9: "",
            sy7: "",
            sy5: "ܡܪܰܒܝܳܢܳܐ",
            dt1: "Erzieher",
            lt12: "",
            sy6: "",
            id: "1182",
            lt10: "mrabyono",
            sy4: "ܡܪܒܝܢܐ",
            lt11: "",
            dt2: "",
            sy8: "",
            dt3: ""
        },
        {
            lt12: "",
            lt10: "amerika garbyoyto",
            sy5: "ܐܰܡܶܪܺܝܟܰܐ ܓܰܪܒܝܳܝܬܳܐ",
            sy6: "",
            sy9: "",
            dt3: "",
            sy4: "ܐܡܪܝܟܐ ܓܪܒܝܝܬܐ",
            lt11: "",
            id: "3292",
            sy8: "",
            dt2: "",
            sy7: "",
            dt1: "Nordamerika"
        },
        {
            lt11: "",
            sy7: "",
            id: "5094",
            lt10: "kmo kthobe ith ĉal țablitho",
            sy9: "",
            sy8: "",
            dt2: "",
            sy5: "ܟܡܳܐ ܟܬ݂ܳܒ̈ܶܐ ܐܺܝܬ݂ ܥܰܠ ܛܰܒܠܺܝܬ݂ܳܐ",
            sy6: "",
            dt3: "",
            dt1: "Wie wieviele Bücher sind auf dem Tisch",
            sy4: "ܟܡܐ ܟܬܒܐ ܐܝܬ ܥܠ ܛܒܠܝܬܐ",
            lt12: ""
        },
        {
            lt10: " eno roĥem no lğobul",
            sy5: "ܐܶܢܳܐ ܪܳܚܶܡ ܐ̱ܢܳܐ ܠܓ݂ܳܒܽܘܠܝ",
            sy6: "",
            dt3: "",
            lt12: "",
            id: "2127",
            sy7: "",
            dt2: "",
            sy4: "ܐܢܐ ܪܚܡ ܐܢܐ ܠܓܒܘܠܝ",
            lt11: "",
            sy8: "",
            sy9: "",
            dt1: "Ich liebe meinen Schöpfer"
        },
        {
            lt11: "shtaĉesre",
            dt3: "",
            sy5: "ܫܬܰܥܣܰܪ",
            dt1: "16",
            sy4: "ܫܬܥܣܪ",
            lt12: "juth-waw",
            dt2: "Sechzehn",
            id: "17",
            sy7: "ܫܬܰܥܶܣ̈ܪܶܐ",
            sy6: "ܫܬܥܣܪܐ",
            sy9: "ܝܘ",
            sy8: "ܝܘ",
            lt10: "shtaĉsar"
        },
        {
            sy8: "",
            sy9: "",
            lt11: "ܕܡܝܪ",
            sy6: "",
            sy7: "",
            dt3: "",
            sy5: "ܕܶܡܺܝܪ",
            sy4: "ܕܡܝܪ",
            dt2: "",
            lt12: "",
            lt10: "ܕܡܝܪ",
            id: "854",
            dt1: "Demir"
        },
        {
            sy5: "ܙܰܒܢܳܐ ܕܕܡܳܟ݂ܳܐ",
            sy7: "ܙܰܒܢܳܐ ܕܫܶܢܬ݂ܳܐ",
            sy9: "",
            sy4: "ܙܒܢܐ ܕܕܡܟܐ",
            lt12: "",
            dt1: "Schlafenszeit",
            dt2: "",
            lt11: "zabno d`shentho",
            id: "3961",
            dt3: "",
            sy6: "ܙܒܢܐ ܕܫܢܬܐ",
            lt10: "zabno d`dmokho",
            sy8: ""
        },
        {
            sy5: "ܦܳܪܰܚܬܳܐ",
            lt12: "ŝefre",
            sy6: "ܦܪܚܬܐ",
            sy4: "ܦܪܚܬܐ",
            sy9: "ܨܶܦܪܶܐ",
            id: "4843",
            sy8: "ܨܦܪܐ",
            dt1: "Vögel",
            dt3: "",
            sy7: "ܦܪܳܚ̈ܳܬ݂ܳܐ",
            lt11: "froĥotho",
            lt10: "foraĥto",
            dt2: "Fliegende Tiere"
        },
        {
            sy7: "",
            sy6: "",
            sy8: "",
            id: "4797",
            sy9: "",
            dt2: "sammeln",
            sy4: "ܟܘܢܫ",
            lt12: "",
            sy5: "ܟܽܘܢܳܫ",
            lt10: "kunosh",
            dt3: "",
            dt1: "versammeln",
            lt11: ""
        },
        {
            dt2: "",
            id: "2724",
            lt10: "ܠܒܘܢܬܐ",
            sy4: "ܠܒܘܢܬܐ",
            sy5: "ܠܒܽܘܢܬܰܐ",
            dt1: "Lbunta",
            sy6: "",
            lt12: "",
            sy7: "",
            sy8: "",
            sy9: "",
            lt11: "ܠܒܘܢܬܐ",
            dt3: ""
        },
        {
            sy8: "",
            dt3: "",
            lt10: "som bnayo",
            id: "5761",
            sy5: "ܣܳܡ ܒܢܰܝܳܐ",
            sy7: "ܢܣܰܒ ܒܢܰܝܳܐ",
            dt2: "",
            dt1: "adoptieren",
            sy9: "",
            lt12: "",
            sy6: "ܢܣܒ ܒܢܝܐ",
            lt11: "nsab bnayo",
            sy4: "ܣܡ ܒܢܝܐ"
        },
        {
            sy8: "",
            dt3: "",
            sy4: "ܣܪܘܢܐ",
            dt1: "Saruna",
            lt11: "ܣܪܘܢܐ",
            sy9: "",
            lt12: "ܨܪܘܢܐ",
            id: "3850",
            sy7: "ܨܰܪܽܘܢܰܐ",
            lt10: "ܣܪܘܢܐ",
            sy6: "ܨܪܘܢܐ",
            dt2: "",
            sy5: "ܣܰܪܽܘܢܰܐ"
        },
        {
            id: "5179",
            lt11: "",
            lt12: "",
            lt10: "taulĉo",
            sy9: "",
            dt2: "Larve",
            sy8: "",
            sy4: "ܬܘܠܥܐ",
            dt1: "Wurm",
            sy6: "",
            sy7: "",
            sy5: "ܬܰܘܠܥܳܐ",
            dt3: ""
        },
        {
            dt2: "",
            sy8: "",
            sy4: "ܐܢܐ ܐܝܬܝ ܗܘ ܕܐܝܬܝ",
            lt12: "",
            dt1: "Ich bin der der ich bin",
            dt3: "",
            sy9: "",
            id: "2095",
            sy6: "",
            lt11: "",
            lt10: "eno ithay hau dithay",
            sy5: "ܐܶܢܳܐ ܐܺܝܬ݂ܝ ܗܰܘ ܕܺܐܝܬ݂ܰܝ",
            sy7: ""
        },
        {
            lt11: "",
            id: "891",
            lt12: "",
            sy8: "",
            dt1: "Diktat",
            sy9: "",
            sy7: "",
            sy4: "ܐܨܚܬܐ",
            dt3: "",
            sy5: "ܐܰܨܰܚܬܳܐ",
            lt10: "aŝaĥto",
            sy6: "",
            dt2: "Orthographie"
        },
        {
            sy9: "",
            dt3: "",
            lt10: "lo tbațel sokh",
            sy8: "",
            sy5: "ܠܳܐ ܬܒܰܛܶܠ ܣܳܟ݂",
            sy6: "",
            lt11: "",
            id: "5454",
            lt12: "",
            dt1: "Gib niemals auf",
            dt2: "",
            sy7: "",
            sy4: "ܠܳܐ ܬܒܰܛܶܠ ܣܳܟ"
        },
        {
            lt11: "",
            sy5: "ܟܠܺܝ",
            sy4: "ܟܠܝ",
            sy6: "",
            sy8: "",
            lt12: "",
            dt2: "",
            dt3: "",
            sy7: "",
            dt1: "bleib stehen",
            lt10: "kli",
            id: "646",
            sy9: ""
        },
        {
            sy8: "",
            dt2: "",
            sy9: "",
            sy4: "ܛܟܣܐ ܕܡܢܝܢܐ",
            lt11: "",
            dt1: "Zahlensystem",
            sy7: "",
            dt3: "",
            lt10: "țakso d`menyono",
            sy5: "ܛܰܟܣܳܐ ܕܡܶܢܝܳܢܳܐ",
            id: "5228",
            lt12: "",
            sy6: ""
        },
        {
            dt2: "Rasen",
            sy8: "",
            sy7: "",
            sy9: "",
            sy5: "ܓܶܠܳܐ",
            sy6: "",
            dt1: "Gras",
            sy4: "ܓܠܐ",
            lt11: "",
            lt10: "gelo",
            id: "1724",
            lt12: "",
            dt3: ""
        },
        {
            dt3: "",
            lt12: "",
            sy6: "",
            dt2: "",
            sy7: "",
            sy4: "ܚܡܫܐ ܒܫܒܐ",
            sy5: "ܚܰܡܫܳܐ ܒܫܰܒܳܐ",
            lt11: "",
            lt10: "ĥamsho bshabo",
            sy9: "",
            id: "915",
            sy8: "",
            dt1: "Donnerstag"
        },
        {
            sy7: "",
            sy6: "",
            dt3: "",
            lt11: "",
            lt12: "",
            id: "3707",
            sy9: "",
            dt2: "bereuen",
            dt1: "Reue empfinden",
            lt10: "tob",
            sy4: "ܬܒ",
            sy8: "",
            sy5: "ܬܳܒ"
        },
        {
            dt2: "",
            lt11: "",
            sy8: "",
            id: "2306",
            dt3: "",
            sy9: "",
            sy4: "ܐܘܪܫܠܝܡ",
            sy7: "",
            sy6: "",
            sy5: "ܐܽܘܪܶܫܠܶܝܡ",
            lt10: "ureshlem",
            lt12: "",
            dt1: "Jerusalem"
        },
        {
            sy9: "",
            lt10: "molukhutho",
            lt12: "",
            sy7: "",
            sy8: "",
            sy4: "ܡܠܘܟܘܬܐ",
            dt2: "",
            id: "4495",
            sy5: "ܡܳܠܽܘܟ݂ܽܘܬܳܐ",
            dt3: "",
            lt11: "",
            dt1: "Tipp",
            sy6: ""
        },
        {
            sy7: "",
            id: "3423",
            lt11: "ܦܘܠܐ",
            sy4: "ܦܘܠܐ",
            dt1: "Paula",
            lt12: "",
            dt3: "",
            sy5: "ܦܰܘܠܰܐ",
            sy9: "",
            lt10: "ܦܘܠܐ",
            sy6: "",
            dt2: "",
            sy8: ""
        },
        {
            dt1: "Schnee",
            sy9: "",
            dt2: "",
            lt11: "",
            sy8: "",
            id: "3992",
            sy4: "ܬܠܓܐ",
            sy5: "ܬܰܠܓܳܐ",
            dt3: "",
            sy7: "",
            lt10: "talgo",
            lt12: "",
            sy6: ""
        },
        {
            id: "2159",
            sy7: "",
            dt1: "Ihr habt Bananen",
            sy6: "",
            lt11: "",
            dt3: "",
            lt10: "atun ith lkhun muze",
            sy9: "",
            dt2: "",
            sy4: "ܐܢܬܘܢ ܐܝܬ ܠܟܘܢ ܡܘܙܐ",
            sy8: "",
            sy5: "ܐܰܢ̱ܬܽܘܢ ܐܺܝܬ݂ ܠܟ݂ܽܘܢ ܡܽܘܙܳܐ",
            lt12: ""
        },
        {
            lt12: "",
            lt10: "klilo",
            sy4: "ܟܠܝܠܐ",
            sy6: "",
            sy8: "",
            dt1: "Kranz",
            sy9: "",
            sy5: "ܟܠܺܝܠܳܐ",
            dt3: "",
            id: "2636",
            sy7: "",
            lt11: "",
            dt2: "Krone"
        },
        {
            sy9: "ܐܰܙܪܶܩ",
            sy5: "ܬܘܰܢ",
            sy6: "ܬܢ",
            sy8: "ܐܙܪܩ",
            lt12: "azreq",
            id: "4691",
            dt3: "pissen",
            lt10: "twan",
            dt2: "pinkeln",
            sy7: "ܬܳܢ",
            sy4: "ܬܘܢ",
            dt1: "urinieren",
            lt11: "ton"
        },
        {
            sy9: "",
            dt3: "",
            lt10: "ܓܘܕܐ",
            sy6: "",
            sy5: "ܓܽܘܕܰܐ",
            sy4: "ܓܘܕܐ",
            lt11: "ܓܘܕܐ",
            dt1: "Guda",
            sy7: "",
            sy8: "",
            lt12: "",
            dt2: "",
            id: "1761"
        },
        {
            lt10: "mathlono d`ĉamo",
            lt11: "buluțo",
            sy8: "",
            lt12: "",
            sy9: "",
            sy7: "ܒܽܘܠܽܘܛܳܐ",
            sy6: "ܒܘܠܘܛܐ",
            sy4: "ܡܡܬܠܢܐ ܕܥܡܐ",
            dt1: "Volksvertreter",
            dt3: "",
            id: "4863",
            sy5: "ܡܡܰܬ݂ܠܳܢܳܐ ܕܥܰܡܳܐ",
            dt2: "Parlamentarier"
        },
        {
            sy4: "ܪܟܒ",
            id: "6066",
            dt3: "",
            sy7: "ܬܰܩܶܢ",
            lt10: "rakeb",
            lt12: "",
            sy9: "",
            sy8: "",
            sy5: "ܪܰܟܶܒ",
            dt1: "konstruieren",
            sy6: "ܬܩܢ",
            dt2: "",
            lt11: "taqen"
        },
        {
            sy4: "ܡܬܐܝܠܘ",
            lt12: "",
            dt3: "",
            sy8: "",
            id: "2969",
            dt1: "Matilu",
            lt11: "ܡܬܐܝܠܘ",
            sy7: "",
            sy9: "",
            dt2: "",
            lt10: "ܡܬܐܝܠܘ",
            sy5: "ܡܰܬܐܺܝܠܽܘ",
            sy6: ""
        },
        {
            sy7: "",
            lt11: "",
            lt12: "",
            sy9: "",
            sy4: "ܐܣܝܪܘܬ ܙܘܘܓܐ",
            lt10: "asiruth zuwoğo",
            dt2: "",
            sy6: "",
            sy5: "ܐܰܣܝܺܪܽܘܬ݂ ܙܽܘܘܳܓ݂ܳܐ",
            dt3: "",
            id: "1011",
            dt1: "eheliche Gemeinschaft",
            sy8: ""
        },
        {
            sy9: "",
            dt2: "",
            sy6: "",
            sy8: "",
            dt1: "Verstorbener",
            dt3: "",
            lt11: "",
            lt12: "",
            sy4: "ܡܢܚܐ",
            sy7: "",
            id: "4814",
            sy5: "ܡܢܳܚܳܐ",
            lt10: "mnoĥo"
        },
        {
            sy4: "ܫܘܬܦܘܬܐ",
            dt1: "Teilnahme",
            sy6: "",
            lt10: "shautofutho",
            dt2: "",
            sy7: "",
            lt12: "",
            id: "4425",
            dt3: "",
            sy5: "ܫܰܘܬܳܦܽܘܬ݂ܳܐ",
            lt11: "",
            sy8: "",
            sy9: ""
        },
        {
            lt11: "",
            dt3: "Zwinkern",
            dt2: "Augenblick",
            sy8: "",
            lt10: "rfofo",
            sy4: "ܪܦܦܐ",
            sy7: "",
            dt1: "Sekunde",
            sy9: "",
            lt12: "",
            sy6: "",
            sy5: "ܪܦܳܦܳܐ",
            id: "4106"
        },
        {
            id: "1322",
            lt11: "shalhebitho",
            lt12: "",
            sy7: "ܫܰܠܗܶܒܺܝܬ݂ܳܐ",
            dt1: "Flamme",
            dt2: "Feuerflamme",
            sy4: "ܫܓܪܬܐ",
            dt3: "",
            sy5: "ܫܓ݂ܳܪܬܳܐ",
            sy6: "ܫܠܗܒܝܬܐ",
            sy9: "",
            sy8: "",
            lt10: "shğorto"
        },
        {
            sy6: "",
            sy8: "",
            dt3: "",
            sy7: "",
            id: "4262",
            dt1: "Stephanus",
            sy4: "ܣܛܝܦܢܘܣ",
            lt12: "",
            lt11: "ܣܛܝܦܢܘܣ",
            dt2: "",
            sy9: "",
            sy5: "ܣܛܶܝܦܰܢܽܘܣ",
            lt10: "ܣܛܝܦܢܘܣ"
        },
        {
            id: "5430",
            dt3: "",
            dt1: "wertvoll",
            lt10: "yaqiro",
            lt12: "",
            sy4: "ܝܰܩܺܝܪܳܐ",
            sy9: "",
            sy5: "ܝܰܩܺܝܪܳܐ",
            sy8: "",
            lt11: "",
            sy6: "",
            sy7: "",
            dt2: ""
        },
        {
            sy5: "ܡܓܰܚܟ݂ܳܢܳܐ",
            sy4: "ܡܓܚܟܢܐ",
            sy6: "",
            sy9: "",
            lt10: "mgaĥkhono",
            lt12: "",
            dt1: "lächerlich",
            sy8: "",
            dt3: "lustig",
            id: "2691",
            sy7: "",
            dt2: "komisch",
            lt11: ""
        },
        {
            lt12: "",
            sy8: "",
            dt2: "",
            id: "6253",
            lt11: "",
            sy6: "",
            sy5: "ܐܰܒܕܳܢ ܙܰܒܢܳܐ",
            sy7: "",
            lt10: "abđon zabno",
            sy9: "",
            dt1: "Zeitverlust",
            sy4: "ܐܒܕܢ ܙܒܢܐ",
            dt3: ""
        },
        {
            sy6: "",
            dt1: "Zalo",
            dt2: "",
            lt10: "ܙܠܐ",
            dt3: "",
            lt11: "ܙܠܐ",
            sy8: "",
            sy5: "ܙܰܠܳܐ",
            id: "5253",
            sy4: "ܙܠܐ",
            sy9: "",
            lt12: "",
            sy7: ""
        },
        {
            sy8: "",
            id: "2999",
            lt10: "bnaynosho",
            sy7: "",
            sy6: "",
            lt11: "",
            dt1: "Menschen",
            lt12: "",
            dt2: "",
            dt3: "",
            sy9: "",
            sy4: "ܒܢܝܢܫܐ",
            sy5: "ܒܢܰܝ̈ܢܳܫܳܐ"
        },
        {
            sy4: "ܓܡܪܝܐ",
            sy5: "ܓܶܡܰܪܝܰܐ",
            id: "1557",
            sy9: "",
            lt12: "",
            dt3: "",
            lt10: "ܓܡܪܝܐ",
            sy6: "",
            sy8: "",
            lt11: "ܓܡܪܝܐ",
            sy7: "",
            dt1: "Gemarja",
            dt2: ""
        },
        {
            dt2: "",
            dt3: "",
            sy8: "",
            sy4: "ܟܝܣܐ",
            sy7: "",
            dt1: "Beutel",
            sy5: "ܟܺܝܣܳܐ",
            lt12: "",
            lt10: "kiso",
            id: "598",
            sy9: "",
            lt11: "",
            sy6: ""
        },
        {
            dt1: "Wer ist das",
            lt11: "man hu",
            sy4: "ܡܢܘ",
            lt10: "manu",
            sy9: "",
            sy5: "ܡܰܢܽܘ",
            lt12: "",
            id: "5069",
            dt2: "",
            dt3: "",
            sy6: "ܡܢ ܗܘ",
            sy7: "ܡܶܢ ܗܽܘ",
            sy8: ""
        },
        {
            dt3: "",
            dt2: "",
            sy9: "",
            lt10: "mshautfo",
            sy7: "ܡܫܰܘܬܦܳܢܳܐ",
            lt12: "",
            sy5: "ܡܫܰܘܬܦܳܐ",
            dt1: "Abonennt",
            id: "5590",
            lt11: "mshautfono",
            sy4: "ܡܫܘܬܦܐ",
            sy6: "ܡܫܘܬܦܢܐ",
            sy8: ""
        },
        {
            sy5: "ܦܽܘܪܥܳܢܳܐ",
            sy8: "",
            lt10: "furĉono",
            dt2: "Preis",
            sy9: "",
            dt3: "",
            sy7: "ܦܽܘܪܥܳܢܽܘܬ݂ܳܐ",
            lt12: "",
            dt1: "Belohnung",
            sy6: "ܦܘܪܥܢܘܬܐ",
            id: "543",
            lt11: "furĉonutho",
            sy4: "ܦܘܪܥܢܐ"
        },
        {
            dt3: "",
            lt11: "",
            sy6: "",
            id: "3434",
            sy7: "",
            sy8: "",
            dt2: "Iraner",
            lt10: "forsoyo",
            lt12: "",
            sy4: "ܦܪܣܝܐ",
            sy9: "",
            dt1: "Perser",
            sy5: "ܦܳܪܣܳܝܳܐ"
        },
        {
            dt2: "",
            sy5: "ܡܶܢ ܟܽܠ ܦܪܽܘܣ",
            dt1: "in jeder Hinsicht",
            sy9: "",
            lt12: "",
            lt10: "men kul frus",
            sy4: "ܡܢ ܟܠ ܦܪܘܣ",
            sy7: "",
            lt11: "",
            dt3: "",
            sy6: "",
            id: "2184",
            sy8: ""
        },
        {
            sy8: "",
            dt2: "deutlich",
            sy7: "",
            id: "3345",
            sy5: "ܓܰܠܝܳܐ",
            lt12: "",
            sy4: "ܓܠܝܐ",
            lt11: "",
            dt1: "offen",
            sy9: "",
            dt3: "",
            lt10: "galyo",
            sy6: ""
        },
        {
            sy7: "",
            lt11: "",
            sy6: "",
            sy5: "ܢܓܰܪ",
            dt2: "dauern",
            dt3: "",
            id: "380",
            dt1: "ausharren",
            sy9: "",
            sy4: "ܢܓܪ",
            sy8: "",
            lt12: "",
            lt10: "ngar"
        },
        {
            dt3: "",
            sy6: "ܚܝܪܢ",
            dt2: "",
            sy4: "ܚܝܪܢ",
            sy5: "ܚܺܝܪܰܢ",
            lt12: "ܚܝܪܢ",
            dt1: "Hiran",
            sy7: "ܚܰܝܪܰܢ",
            sy9: "",
            id: "2018",
            lt10: "ܚܝܪܢ",
            sy8: "",
            lt11: "ܚܝܪܢ"
        },
        {
            sy8: "",
            dt3: "",
            sy7: "",
            dt2: "Luftpumpe",
            lt10: "nofuĥto",
            sy5: "ܢܳܦܽܘܚܬܳܐ",
            lt11: "",
            sy9: "",
            lt12: "",
            sy4: "ܢܦܘܚܬܐ",
            id: "3546",
            dt1: "Pumpe",
            sy6: ""
        },
        {
            sy7: "",
            dt2: "",
            sy8: "",
            id: "4937",
            sy4: "ܓܘܒܝܐ",
            lt10: "guboye",
            sy9: "",
            lt11: "",
            dt3: "",
            dt1: "Wahlen",
            sy6: "",
            lt12: "",
            sy5: "ܓܽܘ̈ܒܳܝܶܐ"
        },
        {
            sy8: "",
            dt1: "weiblich",
            sy9: "",
            dt2: "",
            sy6: "",
            dt3: "",
            lt12: "",
            id: "5018",
            lt11: "",
            lt10: "neqbthonoyo",
            sy7: "",
            sy4: "ܢܩܒܬܢܝܐ",
            sy5: "ܢܶܩܒܬ݂ܳܢܳܝܳܐ"
        },
        {
            lt12: "",
            sy7: "",
            lt11: "",
            sy9: "",
            sy4: "ܛܟܣܐ ܕܙܘܙܐ",
            dt2: "",
            lt10: "țakso d`zuze",
            id: "4965",
            dt1: "Währungssystem",
            sy8: "",
            dt3: "",
            sy6: "",
            sy5: "ܛܰܟܣܳܐ ܕܙܽܘ̈ܙܶܐ"
        },
        {
            dt1: "Gleichberechtigung",
            lt11: "",
            sy9: "",
            dt2: "",
            sy7: "",
            sy6: "",
            sy8: "",
            lt12: "",
            sy5: "ܡܶܫܬܰܘܝܳܢܽܘܬ݂ܳܐ",
            id: "1656",
            lt10: "meshtauyonutho",
            dt3: "",
            sy4: "ܡܫܬܘܝܢܘܬܐ"
        },
        {
            sy9: "",
            sy8: "",
            dt2: "",
            dt1: "Fallschirm",
            dt3: "",
            sy4: "ܦܪܫܘܬ",
            lt12: "",
            id: "1245",
            sy6: "",
            lt10: "farashut",
            lt11: "",
            sy5: "ܦܰܪܰܫܽܘܬ",
            sy7: ""
        },
        {
            dt1: "In ewiger Liebe",
            dt2: "",
            sy6: "",
            sy9: "",
            sy4: "ܒܚܘܒܐ ܐܡܝܢܬܐ",
            lt12: "",
            lt10: "bĥubo aminto",
            lt11: "",
            sy8: "",
            dt3: "",
            sy5: "ܒܚܽܘܒܳܐ ܐܰܡܺܝܢܬܳܐ",
            id: "2183",
            sy7: ""
        },
        {
            sy8: "",
            id: "6310",
            lt12: "",
            sy7: "",
            dt3: "",
            sy5: "ܥܣܶܩ ܙܰܓܺܝ",
            dt1: "zu schwierig",
            lt11: "",
            sy9: "",
            sy4: "ܥܣܩ ܙܓܝ",
            lt10: "ĉseq sagi",
            sy6: "",
            dt2: ""
        },
        {
            dt1: "Zeitsparung",
            sy4: "ܡܘܫܚ ܙܒܢܐ",
            lt12: "",
            sy9: "",
            dt3: "",
            sy6: "",
            dt2: "",
            lt11: "",
            sy5: "ܡܽܘܫܳܚ ܙܰܒܢܳܐ",
            sy8: "",
            lt10: "mushoĥ zabno",
            id: "6251",
            sy7: ""
        },
        {
            lt10: "ܫܡܪܒ",
            dt3: "",
            sy6: "",
            dt2: "",
            sy8: "",
            sy9: "",
            id: "3921",
            lt11: "ܫܡܪܒ",
            sy5: "ܫܶܡܪܰܒ",
            sy4: "ܫܡܪܒ",
            lt12: "",
            dt1: "Schemrab",
            sy7: ""
        },
        {
            dt3: "",
            lt12: "",
            dt2: "",
            sy5: "ܥܬܰܪ",
            sy9: "",
            lt10: "ĉtar",
            sy8: "",
            sy6: "",
            dt1: "reich werden",
            lt11: "",
            sy4: "ܥܬܪ",
            sy7: "",
            id: "3668"
        },
        {
            sy6: "ܒܪܚܐ",
            lt11: "barĥo",
            sy4: "ܟܒܫܐ",
            dt1: "Widder",
            sy7: "ܒܰܪܚܳܐ",
            sy9: "",
            sy5: "ܟܰܒܫܳܐ",
            sy8: "",
            lt12: "",
            dt2: "Schafbock",
            lt10: "kabsho",
            dt3: "",
            id: "5081"
        },
        {
            sy9: "",
            dt1: "alphabetisch",
            lt12: "",
            dt3: "",
            sy5: "ܒܣܶܕܪܳܐ ܕܳܐܠܰܦ ܒܶܝܬ݂",
            dt2: "",
            sy6: "",
            lt11: "",
            sy4: "ܒܣܕܪܐ ܕܐܠܦ ܒܝܬ",
            lt10: "bsedro dolaf beth",
            sy8: "",
            sy7: "",
            id: "5852"
        },
        {
            sy5: "ܐܶܢܳܐ ܡܶܢ ܚܐܰܚ ܐ̱ܢܳܐ",
            lt10: "ono men ĥaĥ no",
            sy7: "",
            sy6: "",
            sy9: "",
            sy4: "ܐܢܐ ܡܢ ܚܐܚ ܐ̱ܢܐ",
            dt3: "",
            sy8: "",
            id: "2119",
            dt2: "",
            lt12: "",
            dt1: "Ich komme aus Hah",
            lt11: ""
        },
        {
            sy4: "ܡܙܝܓܘܬܐ",
            lt11: "muzoğo",
            lt10: "msiğutho",
            dt3: "",
            sy8: "",
            sy5: "ܡܙܺܝܓ݂ܽܘܬ݂ܳܐ",
            sy7: "ܡܽܘܙܳܓ݂ܳܐ",
            sy6: "ܡܘܙܓܐ",
            dt2: "Vermengung",
            id: "1559",
            dt1: "Gemisch",
            lt12: "",
            sy9: ""
        },
        {
            dt3: "",
            sy5: "ܫܳܪܺܝܬ݂ܳܐ",
            sy7: "",
            lt12: "",
            dt1: "Gelenk",
            lt10: "shoritho",
            lt11: "",
            sy8: "",
            sy4: "ܫܪܝܬܐ",
            id: "1550",
            sy9: "",
            sy6: "",
            dt2: "menschliches Gelenk"
        },
        {
            sy8: "",
            sy5: "ܡܰܚܝܳܢܳܐ",
            lt12: "",
            dt1: "Erlöser",
            sy4: "ܡܚܝܢܐ",
            id: "1157",
            dt2: "Retter",
            sy7: "ܦܳܪܽܘܩܳܐ",
            sy6: "ܦܪܘܩܐ",
            dt3: "",
            sy9: "",
            lt10: "maĥyono",
            lt11: "foruqo"
        },
        {
            dt2: "",
            sy7: "",
            sy4: "ܐܢܐ ܐܢܐ ܢܘܗܪܐ ܫܪܝܪܐ",
            lt10: "eno no nuhro shariro",
            id: "2094",
            sy8: "",
            sy9: "",
            lt11: "",
            lt12: "",
            sy5: "ܐܶܢܳܐ ܐ̱ܢܳܐ ܢܽܘܗܪܳܐ ܫܰܪܺܝܪܳܐ",
            dt3: "",
            sy6: "",
            dt1: "Ich bin das wahre Licht"
        },
        {
            dt1: "In der Ruhe liegt die Kraft",
            dt3: "",
            sy7: "",
            sy9: "",
            lt10: "bniĥutho ithau ĥaylo",
            dt2: "",
            sy5: "ܒܢܺܝܚܘܽܬ݂ܳܐ ܐܺܝܬ݂ܰܘܗܝ̱ ܚܰܝܠܳܐ",
            sy4: "ܒܢܝܚܘܬܐ ܐܝܬܘܗܝ ܚܝܠܐ",
            lt11: "",
            lt12: "",
            sy6: "",
            id: "2179",
            sy8: ""
        },
        {
            dt1: "Thermometer",
            sy8: "",
            dt2: "",
            sy7: "ܡܫܽܘܚܬܳܐ ܕܚܰܡܺܝܡܽܘܬ݂ܳܐ",
            lt10: "mshuĥto d`muzoğo",
            dt3: "",
            sy6: "ܡܫܘܚܬܐ ܕܚܡܝܡܘܬܐ",
            lt12: "",
            sy9: "",
            sy4: "ܡܫܘܚܬܐ ܕܡܘܙܓܐ",
            lt11: "mshuĥto d`ĥamimutho",
            id: "4464",
            sy5: "ܡܫܽܘܚܬܳܐ ܕܡܽܘܙܳܓ݂ܳܐ"
        },
        {
            sy6: "",
            sy7: "",
            lt11: "ܐܦܪܬܐ",
            sy4: "ܐܦܪܬܐ",
            lt12: "",
            lt10: "ܐܦܪܬܐ",
            sy9: "",
            sy5: "ܐܳܦܰܪܬܰܐ",
            dt2: "",
            dt3: "",
            id: "3343",
            sy8: "",
            dt1: "Ofarta"
        },
        {
            lt10: "ramrem",
            dt3: "",
            sy7: "",
            id: "6237",
            lt11: "",
            sy4: "ܪܡܪܡ",
            dt1: "sublimieren",
            sy5: "ܪܰܡܪܶܡ",
            lt12: "",
            sy9: "",
            dt2: "",
            sy8: "",
            sy6: ""
        },
        {
            lt11: "ĉesrin w`ĥamesh",
            sy9: "ܟܗ",
            dt3: "",
            sy5: "ܥܶܣܪܺܝܢ ܘܚܰܡܫܳܐ",
            sy7: "ܥܶܣܪܺܝܢ ܘܚܰܡܶܫ",
            lt12: "kof-he",
            sy6: "ܥܣܪܝܢ ܘܚܡܫ",
            sy8: "ܟܗ",
            lt10: "ĉesrin w`ĥamsho",
            dt1: "25",
            id: "26",
            dt2: "Fünfundzwanzig",
            sy4: "ܥܣܪܝܢ ܘܚܡܫܐ"
        },
        {
            sy8: "",
            lt10: "ܗܕܕ",
            sy5: "ܚܰܕܰܕ",
            sy9: "",
            dt1: "Hadad",
            lt12: "ܗܕܕ",
            lt11: "ܗܕܕ",
            dt2: "",
            dt3: "",
            sy6: "ܗܕܕ",
            id: "1804",
            sy7: "ܗܳܕܳܕ",
            sy4: "ܗܕܕ"
        },
        {
            sy5: "ܐܶܬ݂ܛܰܝܰܒ ܠܩܪܳܒܳܐ",
            sy9: "",
            dt3: "",
            lt10: "ethțayab la`qrobo",
            dt2: "",
            sy7: "",
            sy8: "",
            id: "3068",
            lt12: "",
            sy6: "",
            dt1: "mobilisieren",
            sy4: "ܐܬܛܝܒ ܠܩܪܒܐ",
            lt11: ""
        },
        {
            sy6: "ܥܡܠ",
            sy8: "ܬܥܫ",
            lt11: "ĉmal",
            dt3: "",
            id: "5991",
            lt12: "tĉash",
            sy7: "ܥܡܰܠ",
            sy9: "ܬܥܰܫ",
            sy4: "ܫܚܩ",
            sy5: "ܫܚܰܩ",
            dt2: "",
            lt10: "shĥaq",
            dt1: "anspannen"
        },
        {
            lt11: "",
            dt1: "antworten",
            lt12: "",
            sy4: "ܦܢܝ",
            lt10: "fani",
            id: "253",
            sy9: "",
            dt2: "",
            sy7: "",
            sy6: "",
            dt3: "",
            sy5: "ܦܰܢܝ",
            sy8: ""
        },
        {
            lt10: "ܡܪܠܢ",
            sy8: "",
            dt2: "",
            sy6: "",
            sy7: "",
            dt3: "",
            lt11: "ܡܪܠܢ",
            dt1: "Marlen",
            lt12: "",
            sy5: "ܡܰܪܠܶܢ",
            sy9: "",
            id: "2941",
            sy4: "ܡܪܠܢ"
        },
        {
            id: "2510",
            dt3: "",
            lt12: "",
            lt10: "gazo d`lbushe",
            sy5: "ܓܰܙܳܐ ܕܠܒܽܘܫܳܐ",
            sy4: "ܓܙܐ ܕܠܒܘܫܐ",
            sy7: "",
            dt1: "Kleiderschrank",
            dt2: "",
            sy6: "",
            lt11: "",
            sy8: "",
            sy9: ""
        },
        {
            sy5: "ܐܰܦܝܢ ܒܰܐܦܺܝܢ",
            sy9: "",
            sy7: "",
            lt12: "",
            lt10: "afin bafin",
            dt3: "",
            sy4: "ܐܦܝܢ ܒܐܦܝܢ",
            dt1: "von Angesicht zu Angesicht",
            lt11: "",
            sy8: "",
            sy6: "",
            id: "4883",
            dt2: ""
        },
        {
            id: "2523",
            sy6: "",
            dt1: "Kletterer",
            sy7: "",
            dt3: "",
            lt12: "",
            dt2: "",
            sy5: "ܣܳܠܽܘܩܳܐ",
            sy8: "",
            lt11: "",
            sy4: "ܣܠܘܩܐ",
            sy9: "",
            lt10: "soluqo"
        },
        {
            lt11: "",
            dt3: "",
            lt12: "",
            sy9: "",
            sy7: "",
            sy5: "ܐܰܪܗܶܒ",
            id: "5816",
            lt10: "arheb",
            sy6: "",
            sy8: "",
            dt2: "",
            sy4: "ܐܪܗܒ",
            dt1: "alarmieren"
        },
        {
            sy4: "ܩܕܡܐ",
            sy8: "",
            dt2: "",
            id: "5371",
            sy9: "",
            sy5: "ܩܰܕܡܳܐ",
            lt10: "qadmo",
            lt11: "qudmonoith",
            dt3: "",
            lt12: "",
            dt1: "zunächst",
            sy6: "ܩܘܕܡܢܐܝܬ",
            sy7: "ܩܽܘܕܡܳܢܳܐܝܺܬ݂"
        },
        {
            lt12: "",
            lt10: "shabuqo d`farzlo",
            dt3: "",
            lt11: "",
            sy7: "",
            sy9: "",
            sy4: "ܫܒܘܩܐ ܕܦܪܙܠܐ",
            sy6: "",
            dt2: "",
            dt1: "Eisenstange",
            sy8: "",
            id: "1063",
            sy5: "ܫܳܒܽܘܩܳܐ ܕܦܰܪܙܠܳܐ"
        },
        {
            dt3: "",
            lt12: "",
            sy9: "",
            dt2: "",
            id: "5798",
            sy4: "ܐܦܛܪܘܦܐ",
            sy8: "",
            lt10: "efețrufo",
            sy7: "ܡܫܰܠܛܳܢܳܐ",
            dt1: "Beauftragter",
            sy6: "ܡܫܠܛܢܐ",
            sy5: "ܐܶܦܶܛܪܽܘܦܳܐ",
            lt11: "mshalțono"
        },
        {
            dt1: "Dorf Hah",
            sy7: "ܚܐܰܚ",
            sy6: "ܚܐܚ",
            sy9: "",
            lt12: "",
            dt2: "Hah",
            sy4: "ܩܪܝܬܐ ܚܐܚ",
            dt3: "",
            lt11: "ĥaĥ",
            id: "933",
            sy8: "",
            lt10: "qritho ĥaĥ",
            sy5: "ܩܪܺܝܬ݂ܳܐ ܚܐܰܚ"
        },
        {
            lt12: "",
            sy4: "ܪܓܘܡܐ",
            dt2: "",
            sy9: "",
            dt1: "Steinigung",
            lt11: "rğomo",
            id: "4255",
            sy6: "ܪܓܡܐ",
            lt10: "rğumyo",
            sy8: "",
            sy5: "ܪܳܓ݂ܽܘܡܳܐ",
            sy7: "ܪܓ݂ܳܡܳܐ",
            dt3: ""
        },
        {
            sy7: "",
            lt10: "ܬܓ݂ܐ",
            sy9: "",
            id: "4515",
            dt2: "",
            dt3: "",
            sy5: "ܬܳܓ݂ܳܐ",
            sy6: "",
            lt11: "ܬܓ݂ܐ",
            dt1: "Togo",
            lt12: "",
            sy4: "ܬܓ݂ܐ",
            sy8: ""
        },
        {
            sy4: "ܫܠܗܒ",
            dt1: "Schalheb",
            sy5: "ܫܰܠܗܶܒ",
            sy8: "",
            lt10: "ܫܠܗܒ",
            lt12: "",
            sy7: "",
            dt2: "",
            lt11: "ܫܠܗܒ",
            sy6: "",
            dt3: "",
            sy9: "",
            id: "3885"
        },
        {
            sy7: "ܚܳܐܰܪ",
            lt11: "ĥoyar",
            lt10: "ĥur",
            dt1: "schauen",
            sy6: "ܚܐܪ",
            id: "3910",
            dt3: "",
            sy4: "ܚܘܪ",
            sy5: "ܚܽܘܪ",
            sy9: "",
            lt12: "",
            dt2: "",
            sy8: ""
        },
        {
            sy8: "",
            sy4: "ܟܬܒ",
            sy7: "",
            dt3: "",
            lt11: "",
            dt1: "schreiben",
            id: "4026",
            sy9: "",
            lt12: "",
            dt2: "",
            sy5: "ܟܳܬ݂ܶܒ",
            sy6: "",
            lt10: "kotheb"
        },
        {
            dt1: "ehrfurchtvoll",
            lt12: "",
            sy9: "",
            sy4: "ܡܝܩܪܐܝܬ",
            lt11: "",
            sy8: "",
            sy6: "",
            lt10: "myaqroith",
            dt2: "",
            id: "1022",
            sy7: "",
            sy5: "ܡܝܰܩܪܳܐܺܝܬ݂",
            dt3: ""
        },
        {
            dt1: "Blutrache",
            sy4: "ܢܩܡܬܐ ܕܕܡܐ",
            lt12: "",
            sy9: "",
            sy5: "ܢܩܰܡܬ݂ܳܐ ܕܰܕܡܳܐ",
            dt3: "",
            sy8: "",
            sy7: "ܥܝܳܪܰܬ݂ ܕܡܳܐ",
            lt11: "ĉyorath dmo",
            dt2: "",
            id: "667",
            sy6: "ܥܝܪܬ ܕܡܐ",
            lt10: "nqamtho da`dmo"
        },
        {
            sy6: "",
            sy4: "ܡܓܢ",
            lt10: "magon",
            dt3: "",
            lt11: "",
            sy5: "ܡܰܓܳܢ",
            lt12: "",
            sy9: "",
            id: "2623",
            dt2: "umsonst",
            dt1: "kostenlos",
            sy8: "",
            sy7: ""
        },
        {
            dt1: "Gad",
            lt12: "ܓܕܐ",
            lt10: "ܓܕ",
            sy5: "ܓܰܕ",
            dt3: "",
            sy4: "ܓܕ",
            sy6: "ܓܕܐ",
            sy8: "",
            dt2: "",
            sy7: "ܓܰܕܳܐ",
            lt11: "ܓܕ",
            sy9: "",
            id: "1458"
        },
        {
            dt1: "Amram",
            sy9: "",
            dt3: "",
            sy6: "",
            lt10: "ܥܡܪܡ",
            sy7: "",
            sy8: "",
            sy4: "ܥܡܪܡ",
            dt2: "",
            lt11: "ܥܡܪܡ",
            sy5: "ܥܰܡܪܰܡ",
            id: "219",
            lt12: ""
        },
        {
            sy9: "",
            sy4: "ܐܚܐ",
            dt2: "",
            lt11: "aĥuno",
            sy5: "ܐܰܚܳܐ",
            id: "716",
            sy7: "ܐܰܚܽܘܢܳܐ",
            lt10: "aĥo",
            sy6: "ܐܚܘܢܐ",
            sy8: "",
            dt1: "Bruder",
            lt12: "",
            dt3: ""
        },
        {
            dt2: "",
            dt1: "Zivilisation",
            lt11: "mđinoyutho",
            sy8: "",
            sy5: "ܡܰܪܕܽܘܬ݂ܳܐ",
            sy4: "ܡܪܕܘܬܐ",
            dt3: "",
            lt12: "",
            sy6: "ܡܕܝܢܝܘܬܐ",
            id: "6294",
            sy9: "",
            lt10: "mardutho",
            sy7: "ܡܕܺܝܢܳܝܽܘܬ݂ܳܐ"
        },
        {
            sy4: "ܙܒܕܐ",
            lt11: "",
            id: "3061",
            lt10: "zebdo",
            dt3: "",
            dt2: "",
            lt12: "",
            dt1: "Mitgift des Vaters für seine Tochter",
            sy7: "",
            sy9: "",
            sy6: "",
            sy8: "",
            sy5: "ܙܶܒܕܳܐ"
        },
        {
            sy6: "ܬܓܪܘܬܐ ܙܥܘܪܬܐ",
            sy8: "",
            sy4: "ܬܓܪܘܗܬܐ ܠܚܘܕܝܬܐ",
            id: "6402",
            lt10: "tagoruhto lĥudoyto",
            sy7: "ܬܰܓܳܪܽܘܬ݂ܳܐ ܙܥܽܘܪܬܳܐ",
            sy5: "ܬܰܓܳܪܽܘܗܬܳܐ ܠܚܽܘܕܳܝܬܳܐ",
            dt3: "",
            dt1: "Einzelhandel",
            dt2: "",
            lt11: "tagorutho zĉurto",
            sy9: "",
            lt12: ""
        },
        {
            sy7: "",
            lt11: "",
            id: "2474",
            dt3: "",
            dt1: "Kinderarbeit",
            dt2: "",
            lt12: "",
            lt10: "fulĥon țloye",
            sy8: "",
            sy9: "",
            sy6: "",
            sy5: "ܦܽܘܠܚܳܢ ܛܠܳܝ̈ܶܐ",
            sy4: "ܦܘܠܚܢ ܛܠܝܐ"
        },
        {
            sy4: "ܚܰܝܠܳܐ ܕܨܶܒܝܳܢܳܐ",
            lt12: "",
            dt2: "",
            sy9: "",
            sy6: "",
            sy8: "",
            dt3: "",
            sy7: "",
            sy5: "ܚܰܝܠܳܐ ܕܨܶܒܝܳܢܳܐ",
            lt11: "",
            dt1: "Willenskraft",
            lt10: "ĥaylo d`ŝebyono",
            id: "5426"
        },
        {
            sy4: "ܟܢܫ",
            dt1: "zusammenfegen",
            sy9: "",
            lt10: "knash",
            sy7: "",
            sy8: "",
            dt2: "",
            sy6: "",
            sy5: "ܟܢܰܫ",
            dt3: "",
            lt11: "",
            lt12: "",
            id: "5378"
        },
        {
            sy9: "",
            lt12: "",
            lt11: "",
            sy5: "ܟܺܝܪܰܬ݂ ܐܺܝܕܳܐ",
            sy8: "",
            id: "4670",
            sy4: "ܟܝܪܬ ܐܕܐ",
            dt1: "Unterschrift",
            dt3: "",
            sy7: "",
            sy6: "",
            dt2: "",
            lt10: "kirat"
        },
        {
            dt1: "Klasse",
            lt11: "",
            sy7: "",
            id: "2497",
            dt3: "",
            lt12: "",
            sy8: "",
            sy6: "",
            sy5: "ܣܶܕܪܳܐ",
            sy4: "ܣܕܪܐ",
            sy9: "",
            dt2: "Schulklasse",
            lt10: "sedro"
        },
        {
            sy8: "",
            sy4: "ܝܕܘܥܬܢ ܒܢܝܢܫܐ",
            sy5: "ܝܰܕܽܘܥܬ݂ܳܢ ܒܢܰܝ̈ܢܳܫܳܐ",
            dt2: "",
            lt12: "",
            lt10: "yađuĉthon bnaynosho",
            dt1: "Menschenkenner",
            id: "3001",
            sy9: "",
            dt3: "",
            sy7: "",
            lt11: "",
            sy6: ""
        },
        {
            sy8: "",
            dt1: "Andreas",
            lt12: "",
            sy7: "",
            sy4: "ܐܢܕܪܐܘܣ",
            lt10: "ܐܢܕܪܐܘܣ",
            id: "229",
            sy5: "ܐܰܢܕܪܶܐܘܳܣ",
            dt3: "Andrawos",
            sy6: "",
            lt11: "ܐܢܕܪܐܘܣ",
            sy9: "",
            dt2: "Andreos"
        },
        {
            sy4: "ܠܚܐ",
            dt2: "",
            id: "5714",
            lt11: "lțaf",
            lt12: "nqad",
            sy5: "ܠܚܳܐ",
            sy6: "ܠܛܦ",
            dt3: "",
            sy9: "ܢܩܰܕ",
            sy8: "ܢܩܕ",
            sy7: "ܠܛܰܦ",
            lt10: "lĥo",
            dt1: "abwischen"
        },
        {
            lt12: "",
            sy6: "ܐܝܕܐ ܒܐܝܕܐ",
            sy8: "",
            lt10: "bido bido",
            sy9: "",
            dt3: "",
            id: "5846",
            lt11: "ido bido",
            sy5: "ܒܺܐܝܕܳܐ ܒܺܐܝܕܳܐ",
            dt2: "",
            sy7: "ܐܺܝܕܳܐ ܒܺܐܝܕܳܐ",
            sy4: "ܒܐܝܕܐ ܒܐܝܕܐ",
            dt1: "allmählich"
        },
        {
            lt10: "yoŝufuth ĥulmono",
            dt2: "Gesundheitspflege",
            sy8: "",
            sy6: "",
            dt1: "Gesundheitsfürsorge",
            id: "1606",
            sy4: "ܝܨܘܦܘܬ ܚܘܠܡܢܐ",
            lt11: "",
            sy7: "",
            sy9: "",
            dt3: "",
            sy5: "ܝܳܨܽܘܦܽܘܬ݂ ܚܽܘܠܡܳܢܳܐ",
            lt12: ""
        },
        {
            lt10: "dkhar",
            lt11: "lbolo",
            id: "1152",
            dt3: "",
            sy9: "",
            dt2: "",
            sy5: "ܕܟ݂ܰܪ",
            sy7: "ܠܒܳܠܳܐ",
            lt12: "",
            sy6: "ܠܒܠܐ",
            dt1: "erinnern",
            sy4: "ܕܟܪ",
            sy8: ""
        },
        {
            sy5: "ܛܳܒ ܒܟܽܠ",
            lt11: "",
            sy6: "",
            dt1: "am besten",
            sy9: "",
            sy4: "ܛܒ ܒܟܠ",
            dt3: "",
            dt2: "",
            sy7: "",
            lt12: "",
            sy8: "",
            id: "5873",
            lt10: "țob bkul"
        },
        {
            sy9: "",
            dt1: "Weinberg",
            sy6: "",
            id: "5028",
            lt12: "",
            sy7: "",
            lt11: "",
            sy8: "",
            lt10: "karmo",
            sy4: "ܟܪܡܐ",
            dt2: "",
            dt3: "",
            sy5: "ܟܰܪܡܳܐ"
        },
        {
            sy9: "",
            lt11: "ܚܙܩܝܐ",
            lt12: "",
            sy7: "",
            sy8: "",
            dt1: "Hesaqjo",
            id: "1986",
            dt2: "",
            lt10: "ܚܙܩܝܐ",
            dt3: "",
            sy4: "ܚܙܩܝܐ",
            sy5: "ܚܶܙܰܩܝܳܐ",
            sy6: ""
        },
        {
            dt3: "",
            lt12: "ܒܪܢܒܐ",
            sy8: "",
            id: "463",
            lt10: "ܒܪܢܐܒܐ",
            lt11: "ܒܪܢܐܒܐ",
            sy4: "ܒܪܢܐܒܐ",
            sy5: "ܒܰܪܢܰܐܒܰܐ",
            dt2: "Barnabas",
            dt1: "Barnaba",
            sy6: "ܒܪܢܒܐ",
            sy7: "ܒܰܪܢܰܒܰܐ",
            sy9: ""
        },
        {
            dt3: "",
            dt1: "altertümlich",
            lt12: "",
            sy5: "ܥܰܬܺܝܩܳܐܺܝܬ݂",
            lt11: "",
            dt2: "",
            sy6: "",
            sy4: "ܥܬܝܩܐܝܬ",
            lt10: "ĉatiqoith",
            id: "5865",
            sy7: "",
            sy8: "",
            sy9: ""
        },
        {
            lt12: "",
            id: "4982",
            sy9: "",
            sy7: "",
            sy8: "",
            sy5: "ܘܰܪܒܽܘܪܓ",
            dt1: "Warburg",
            dt2: "",
            sy4: "ܘܪܒܘܪܓ",
            sy6: "",
            lt11: "",
            lt10: "ܘܪܒܘܪܓ",
            dt3: ""
        },
        {
            sy9: "",
            id: "3470",
            dt3: "",
            sy8: "",
            lt12: "",
            lt11: "",
            sy5: "ܦܰܠܺܝܛܳܐ",
            sy7: "",
            lt10: "falițo",
            dt2: "",
            sy6: "",
            sy4: "ܦܠܝܛܐ",
            dt1: "Planet"
        },
        {
            sy7: "",
            id: "1460",
            sy5: "ܓܰܕܕܺܐܝܶܠ",
            sy4: "ܓܕܐܝܠ",
            dt1: "Gaddiel",
            sy8: "",
            sy9: "",
            dt3: "",
            lt10: "ܓܕܐܝܠ",
            lt11: "ܓܕܐܝܠ",
            dt2: "",
            sy6: "",
            lt12: ""
        },
        {
            sy4: "ܣܥܝܕ",
            sy6: "",
            dt2: "",
            dt3: "",
            lt11: "ܣܥܝܕ",
            lt12: "",
            sy7: "",
            sy9: "",
            dt1: "Said",
            lt10: "ܣܥܝܕ",
            id: "3808",
            sy8: "",
            sy5: "ܣܰܥܺܝܕ"
        },
        {
            lt12: "",
            sy6: "",
            lt11: "ܐܫܝܡܐ",
            sy8: "",
            sy7: "",
            sy5: "ܐܰܫܺܝܡܰܐ",
            id: "312",
            dt1: "Aschima",
            dt2: "",
            dt3: "",
            lt10: "ܐܫܝܡܐ",
            sy4: "ܐܫܝܡܐ",
            sy9: ""
        },
        {
            sy8: "ܠܚܘܕ",
            lt11: "îĥiđoith",
            dt1: "allein",
            sy6: "ܚܝܕܐܝܬ",
            dt3: "",
            id: "180",
            sy5: "ܒܰܠܚܽܘܕ",
            lt12: "lĥuđ",
            lt10: "balĥuđ",
            sy9: "ܠܚܽܘܕ",
            sy4: "ܒܠܚܘܕ",
            sy7: "ܚܺܝܕܳܐܝܺܬ݂",
            dt2: "einzig"
        },
        {
            sy7: "ܚܰܝܳܪܳܐ",
            sy6: "ܚܝܪܐ",
            sy5: "ܚܰܙܳܝܳܐ",
            dt1: "Zuschauer",
            dt3: "",
            lt10: "ĥazoyo",
            sy4: "ܚܙܝܐ",
            id: "5384",
            dt2: "",
            lt11: "ĥayoro",
            lt12: "",
            sy9: "",
            sy8: ""
        },
        {
            dt2: "Einundzwanzig",
            dt3: "",
            sy7: "ܥܶܣܪܺܝܢ ܘܰܚܕܳܐ",
            lt11: "ĉesrin wa`ĥđo",
            dt1: "21",
            sy8: "ܟܐ",
            sy5: "ܥܶܣܪܺܝܢ ܘܚܰܕ",
            sy4: "ܥܣܪܝܢ ܘܚܕ",
            id: "22",
            lt12: "kof-olaf",
            sy9: "ܟܐ",
            lt10: "ĉesrin w`ĥađ",
            sy6: "ܥܣܪܝܢ ܘܚܕܐ"
        },
        {
            lt12: "",
            sy4: "ܥܫܪܘܬܐ",
            sy8: "",
            dt2: "Zollwesen",
            sy6: "ܡܟܣܐ",
            dt1: "Zoll",
            lt10: "ĉashorutho",
            id: "5347",
            dt3: "",
            sy7: "ܡܰܟ݂ܣܳܐ",
            sy5: "ܥܰܫܳܪܽܘܬ݂ܳܐ",
            lt11: "makhso",
            sy9: ""
        },
        {
            sy6: "",
            dt2: "",
            dt1: "systemlos",
            lt11: "",
            sy7: "",
            sy9: "",
            lt10: "lo mțakso",
            dt3: "",
            sy8: "",
            sy5: "ܠܳܐ ܡܛܰܟܣܳܐ",
            lt12: "",
            sy4: "ܠܐ ܡܛܟܣܐ",
            id: "4344"
        },
        {
            sy8: "",
            dt1: "Abschluß",
            lt11: "muloyo",
            id: "5627",
            lt12: "",
            dt3: "",
            sy6: "ܡܘܠܝܐ",
            sy5: "ܡܶܫܬܰܠܡܳܢܽܘܬ݂ܳܐ",
            sy4: "ܡܫܬܠܡܢܘܬܐ",
            sy9: "",
            sy7: "ܡܽܘܠܳܝܳܐ",
            lt10: "meshtalmonutho",
            dt2: "Abschluss"
        },
        {
            id: "5553",
            lt11: "qațraŝ",
            sy7: "ܫܪܳܐ",
            dt3: "",
            sy6: "ܫܪܐ",
            sy9: "ܐܰܦܶܠ ܐ̱ܢܳܫ ܡܶܢ ܐܰܩܡܳܐ",
            sy8: "ܐܦܠ ܐܢܫ ܡܢ ܐܩܡܐ",
            dt1: "abdanken",
            sy5: "ܩܰܬܪܰܣ",
            sy4: "ܩܬܪܣ",
            lt12: "shro",
            dt2: "",
            lt10: "ܐܰܦܶܠ ܐ̱ܢܳܫ ܡܶܢ ܐܰܩܡܳܐ"
        },
        {
            lt10: "men",
            dt3: "",
            sy6: "",
            dt2: "",
            id: "4881",
            lt12: "",
            sy4: "ܡܢ",
            sy8: "",
            sy9: "",
            sy7: "",
            sy5: "ܡܶܢ",
            lt11: "",
            dt1: "von"
        },
        {
            sy6: "",
            sy9: "",
            sy4: "ܟܬܝܢܐ",
            dt2: "",
            lt10: "ܟܬܝܢܐ",
            dt3: "",
            dt1: "Katina",
            lt12: "",
            sy7: "",
            sy8: "",
            id: "2431",
            lt11: "ܟܬܝܢܐ",
            sy5: "ܟܰܬܺܝܢܰܐ"
        },
        {
            dt2: "",
            dt1: "Was wünscht Du Dir",
            id: "4992",
            sy7: "ܡܽܘܢ ܒܳܥܶܐ ܐܰܢ̱ܬ",
            sy8: "",
            sy5: "ܡܽܘܢ ܒܳܥܝܳܐ ܐܰܢ̱ܬܝ",
            lt10: "mun boĉyo at",
            dt3: "",
            sy6: "ܡܘܢ ܒܥܐ ܐܢܬ",
            sy9: "",
            lt12: "",
            sy4: "ܡܘܢ ܒܥܝܐ ܐܢܬܝ",
            lt11: "mun boĉe at"
        },
        {
            lt10: "ܣܒܪܝ",
            dt1: "Sabri",
            sy4: "ܣܒܪܝ",
            dt2: "",
            dt3: "",
            sy9: "",
            sy5: "ܣܰܒܪܺܝ",
            sy6: "ܨܒܪܝ",
            lt11: "ܣܒܪܝ",
            lt12: "ܨܒܪܝ",
            sy8: "",
            sy7: "ܨܰܒܪܺܝ",
            id: "3789"
        },
        {
            sy5: "ܢܰܣܡܶܐ",
            id: "3211",
            sy8: "",
            sy4: "ܢܣܡܐ",
            sy6: "",
            sy7: "",
            lt12: "",
            dt1: "Nasme",
            dt2: "",
            lt10: "ܢܣܡܐ",
            sy9: "",
            lt11: "ܢܣܡܐ",
            dt3: ""
        },
        {
            sy9: "",
            dt3: "",
            lt12: "",
            id: "5952",
            sy5: "ܚܰܪܶܦ",
            dt2: "",
            sy8: "",
            sy4: "ܚܪܦ",
            lt11: "gari",
            dt1: "anfeuern",
            sy7: "ܓܰܪܺܝ",
            lt10: "ĥaref",
            sy6: "ܓܪܝ"
        },
        {
            sy4: "ܢܘܠܐ",
            lt11: "",
            dt2: "",
            sy8: "",
            sy7: "",
            sy9: "",
            id: "3251",
            sy6: "",
            dt3: "",
            lt12: "",
            sy5: "ܢܰܘܠܳܐ",
            lt10: "naulo",
            dt1: "Netz"
        },
        {
            sy6: "ܐܘܪܝܐ",
            sy4: "ܛܘܒܪܩܐ",
            id: "6193",
            sy5: "ܛܽܘܒܰܪܩܳܐ",
            lt11: "uryo",
            lt12: "",
            sy7: "ܐܽܘܪܝܳܐ",
            dt1: "Krippe",
            sy9: "",
            lt10: "țubarqo",
            dt3: "",
            dt2: "",
            sy8: ""
        },
        {
            dt1: "Lieferung",
            dt3: "",
            lt12: "",
            sy5: "ܡܰܫܠܡܳܢܽܘܬ݂ܳܐ",
            id: "2828",
            dt2: "",
            sy4: "ܡܫܠܡܢܘܬܐ",
            lt10: "mashlmonutho",
            sy6: "",
            lt11: "",
            sy7: "",
            sy8: "",
            sy9: ""
        },
        {
            sy9: "ܚܳܛܰܪܬ݂ܳܐ",
            sy5: "ܬܶܠܳܐ",
            sy7: "ܬܶܠܠܳܐ",
            sy8: "ܚܛܪܬܐ",
            sy4: "ܬܠܐ",
            lt11: "tello",
            lt10: "telo",
            lt12: "ĥoțartho",
            dt3: "",
            id: "6031",
            sy6: "ܬܠܠܐ",
            dt2: "",
            dt1: "Hügel"
        },
        {
            dt1: "Ilona",
            sy4: "ܐܝܠܢܐ",
            sy9: "",
            sy5: "ܐܺܝܠܳܢܰܐ",
            sy8: "",
            lt11: "ܐܝܠܢܐ",
            sy6: "",
            sy7: "",
            dt2: "",
            dt3: "",
            lt10: "ܐܝܠܢܐ",
            lt12: "",
            id: "2166"
        },
        {
            sy4: "ܐܓܝܪܐ",
            lt10: "agiro",
            sy6: "",
            sy7: "",
            dt1: "Söldner",
            lt11: "",
            sy8: "",
            sy5: "ܐܰܓܺܝܪܳܐ",
            lt12: "",
            dt2: "",
            sy9: "",
            dt3: "",
            id: "6390"
        },
        {
            lt10: "tamuz",
            sy4: "ܬܡܘܙ",
            sy8: "",
            lt11: "",
            lt12: "",
            sy7: "",
            sy9: "",
            sy6: "",
            id: "2355",
            sy5: "ܬܰܡܽܘܙ",
            dt1: "Juli",
            dt3: "",
            dt2: ""
        },
        {
            dt2: "",
            dt3: "",
            dt1: "Zauberflöte",
            id: "5259",
            sy4: "ܡܫܪܘܩܝܬܐ ܡܚܪܫܢܝܬܐ",
            sy9: "",
            sy6: "",
            sy7: "",
            lt12: "",
            sy8: "",
            lt10: "mashruqitho mĥarshonitho",
            sy5: "ܡܰܫܪܽܘܩܺܝܬ݂ܳܐ ܡܚܰܪܫܳܢܺܝܬ݂ܳܐ",
            lt11: ""
        },
        {
            id: "4731",
            dt2: "",
            sy6: "",
            lt10: "zabno daĉbar",
            sy7: "",
            dt3: "",
            sy8: "",
            lt12: "",
            sy4: "ܙܒܢܐ ܕܥܒܪ",
            dt1: "Vergangenheit",
            sy5: "ܙܰܒܢܳܐ ܕܰܥܒܰܪ",
            sy9: "",
            lt11: ""
        },
        {
            sy5: "ܐܰܪܕܰܫ",
            sy6: "",
            lt12: "",
            sy7: "",
            lt11: "ܐܪܕܫ",
            lt10: "ܐܪܕܫ",
            sy4: "ܐܪܕܫ",
            dt3: "",
            sy9: "",
            sy8: "",
            id: "287",
            dt2: "",
            dt1: "Ardash"
        },
        {
            sy4: "ܚܨܐ",
            sy5: "ܚܰܨܳܐ",
            dt2: "",
            sy6: "",
            lt10: "ĥaŝo",
            dt1: "Rücken",
            lt12: "",
            lt11: "",
            dt3: "",
            id: "3754",
            sy8: "",
            sy7: "",
            sy9: ""
        },
        {
            dt1: "sorgen",
            lt12: "",
            sy7: "ܝܰܨܶܦ",
            lt11: "yaŝef",
            sy4: "ܝܨܦ",
            sy6: "ܝܨܦ",
            sy5: "ܝܺܨܶܦ",
            sy9: "",
            dt3: "",
            sy8: "",
            lt10: "iŝef",
            id: "4202",
            dt2: ""
        },
        {
            sy4: "ܟܘܙܐ",
            lt12: "",
            dt2: "",
            sy8: "",
            dt1: "Vase",
            sy9: "",
            sy6: "",
            lt11: "",
            dt3: "",
            sy5: "ܟܽܘܙܳܐ",
            sy7: "",
            id: "4705",
            lt10: "kuzo"
        },
        {
            sy6: "",
            lt12: "",
            sy7: "",
            dt3: "",
            lt10: "qom latlotho yaumin",
            dt2: "",
            dt1: "Ist am dritten Tag auferstanden",
            id: "2254",
            sy4: "ܩܡ ܠܬܠܬܐ ܝܘܡܝܢ",
            sy5: "ܩܳܡ ܠܰܬܠܳܬ݂ܳܐ ܝܰܘܡܺܝܢ",
            lt11: "",
            sy9: "",
            sy8: ""
        },
        {
            lt11: "",
            dt2: "",
            sy6: "",
            sy7: "",
            sy4: "ܣܗܕܐ ܠܐ ܡܝܬܝܢ",
            id: "2949",
            dt3: "",
            dt1: "Märtyrer sterben nicht",
            sy5: "ܣܳܗ̈ܕܶܐ ܠܳܐ ܡܳܝܬܺܝܢ",
            sy8: "",
            lt12: "",
            sy9: "",
            lt10: "sohde lo moytin"
        },
        {
            sy6: "",
            dt2: "",
            sy4: "ܚܩܠܐ ܕܚܨܕܐ",
            lt10: "ĥaqlo da`ĥŝodo",
            dt3: "",
            sy9: "",
            dt1: "Erntefeld",
            id: "1166",
            lt11: "",
            sy5: "ܚܰܩܠܳܐ ܕܰܚܨܳܕܳܐ",
            sy8: "",
            sy7: "",
            lt12: ""
        },
        {
            lt10: "ܫܡܐܝܠ",
            id: "3920",
            sy4: "ܫܡܐܝܠ",
            dt2: "",
            sy5: "ܫܶܡܺܐܝܠ",
            sy6: "",
            sy7: "",
            sy9: "",
            dt3: "",
            dt1: "Schemil",
            lt11: "ܫܡܐܝܠ",
            lt12: "",
            sy8: ""
        },
        {
            id: "5584",
            dt2: "",
            lt10: "mloĉo",
            sy4: "ܡܠܥܐ",
            lt11: "mluĉitho",
            sy6: "ܡܠܘܥܝܬܐ",
            lt12: "mlaĥkutho",
            sy5: "ܡܠܳܥܳܐ",
            dt1: "Ablecken",
            sy7: "ܡܠܽܘܥܺܝܬ݂ܳܐ",
            sy9: "ܡܠܰܚܟܽܘܬ݂ܳܐ",
            dt3: "",
            sy8: "ܡܠܚܟܘܬܐ"
        },
        {
            sy6: "",
            sy9: "",
            dt3: "",
            sy7: "",
            sy8: "",
            sy5: "ܡܢܰܦܚܳܢܺܝܬ݂ܳܐ",
            lt11: "",
            dt2: "",
            lt12: "",
            id: "2868",
            dt1: "Lüftung",
            sy4: "ܡܢܦܚܢܝܬܐ",
            lt10: "mnafĥonitho"
        },
        {
            dt1: "Rabi",
            lt10: "ܪܒܝ",
            sy8: "",
            lt12: "ܪܒܒܝ",
            sy4: "ܪܒܝ",
            lt11: "ܪܒܝ",
            dt3: "",
            sy9: "",
            dt2: "",
            sy5: "ܪܰܒܺܝ",
            id: "3578",
            sy7: "ܪܰܒܒܺܝ",
            sy6: "ܪܒܒܝ"
        },
        {
            sy8: "",
            sy4: "ܝܡܬ",
            sy6: "ܝܡܐܬ",
            lt11: "ܝܡܬ",
            sy9: "",
            id: "5197",
            dt1: "Yamat",
            sy7: "ܝܰܡܐܰܬ",
            lt10: "ܝܡܬ",
            lt12: "ܝܡܐܬ",
            dt2: "",
            sy5: "ܝܰܡܰܬ",
            dt3: ""
        },
        {
            lt11: "b`gedsho",
            dt2: "plötzlich",
            id: "5357",
            sy9: "",
            dt3: "unerwartet",
            sy6: "ܒܓܕܫܐ",
            sy7: "ܒܓܶܕܫܳܐ",
            sy4: "ܥܪܨܐܝܬ",
            sy8: "",
            dt1: "zufällig",
            lt10: "ĉorŝoith",
            lt12: "",
            sy5: "ܥܳܪܨܳܐܝܺܬ݂"
        },
        {
            sy5: "ܙܰܒܰܝ",
            id: "5217",
            dt1: "Zabai",
            lt11: "ܙܒܝ",
            dt2: "",
            dt3: "",
            sy7: "",
            lt12: "",
            sy8: "",
            lt10: "ܙܒܝ",
            sy4: "ܙܒܝ",
            sy9: "",
            sy6: ""
        },
        {
            id: "1205",
            sy7: "",
            dt3: "",
            sy5: "ܡܰܠܦܽܢܽܘܬ݂ ܐܶܘܰܢܓܶܠܝܽܘܢ",
            dt2: "",
            sy6: "",
            sy8: "",
            dt1: "Evangelisation",
            sy4: "ܡܠܦܢܘܬ ܐܘܢܓܠܝܘܢ",
            sy9: "",
            lt10: "malfonuth ewangelyun",
            lt11: "",
            lt12: ""
        },
        {
            sy4: "ܐܘܢܓܠܝܘܢ",
            lt12: "",
            sy8: "",
            dt3: "",
            lt10: "ewangelyun qadisho",
            id: "1207",
            sy5: "ܐܶܘܰܢܓܶܠܝܽܘܢ",
            sy9: "",
            lt11: "kthobo qadisho",
            sy6: "ܟܬܒܐ ܩܕܝܫܐ",
            dt2: "Bibel",
            sy7: "ܟܬ݂ܳܒܳܐ ܩܰܕܺܝܫܳܐ",
            dt1: "Evangelium"
        },
        {
            sy4: "ܡܢ ܡܪܝܡ ܒܬܘܠܬܐ ܝܠܕܬ ܐܠܗܐ",
            dt2: "",
            sy7: "",
            lt10: "men maryam bthulto yoldath aloho",
            sy9: "",
            lt11: "",
            id: "4884",
            sy5: "ܡܶܢ ܡܰܪܝܰܡ ܒܬ݂ܽܘܠܬܳܐ ܝܳܠܕܰܬ݂ ܐܰܠܳܗܳܐ",
            sy8: "",
            lt12: "",
            dt1: "Von der Jungfrau Maria",
            sy6: "",
            dt3: ""
        },
        {
            sy8: "ܣܪܥܦ",
            sy4: "ܦܪܣ",
            lt11: "shareb",
            lt12: "sarĉef",
            sy6: "ܫܪܒ",
            lt10: "fras",
            dt2: "",
            sy7: "ܫܰܪܶܒ",
            sy5: "ܦܪܰܣ",
            sy9: "ܣܰܪܥܶܦ",
            dt3: "",
            dt1: "herausgeben",
            id: "6060"
        },
        {
            id: "2835",
            dt2: "",
            dt3: "",
            sy8: "",
            lt12: "",
            dt1: "Limonade",
            sy5: "ܠܺܝܡܽܘܢܳܐ",
            lt11: "",
            sy9: "",
            lt10: "limuno",
            sy4: "ܠܝܡܘܢܐ",
            sy7: "",
            sy6: ""
        },
        {
            dt3: "",
            lt12: "",
            sy6: "",
            sy5: "ܫܰܝܢܳܐ ܠܚ̈ܰܝܶܐ",
            id: "5442",
            sy7: "",
            sy9: "",
            lt10: "shayno l'ĥaye",
            sy8: "",
            lt11: "",
            dt1: "Frieden für das Leben",
            dt2: "",
            sy4: "ܫܰܝܢܳܐ ܠܚ̈ܰܝܶܐ"
        },
        {
            sy9: "",
            sy5: "ܬܽܘܠܬܰܬ݂",
            dt1: "vor zwei Jahren",
            sy7: "",
            dt2: "",
            sy6: "",
            id: "4895",
            sy8: "",
            lt12: "",
            lt11: "",
            dt3: "",
            lt10: "tultath",
            sy4: "ܬܘܠܬܬ"
        },
        {
            sy4: "ܚܝܠܘܬܐ ܕܟܒܘܫܝܐ",
            dt3: "",
            lt10: "ĥaylawotho da`kbushyo",
            sy9: "",
            sy6: "",
            sy5: "ܚܰܝ̈ܠܰܘܳܬ݂ܳܐ ܕܰܟܒܽܘܫܝܳܐ",
            sy7: "",
            id: "565",
            sy8: "",
            dt2: "",
            lt11: "",
            dt1: "Besatzungsmächte",
            lt12: ""
        },
        {
            lt11: "",
            sy7: "",
            dt3: "",
            sy8: "",
            dt2: "",
            sy9: "",
            sy5: "ܐܶܢܳܐ ܘܐܳܒܝ ܚܰܕ ܚܢܰܢ ",
            sy6: "",
            lt12: "",
            lt10: "eno u ob ĥađ ĥnan",
            dt1: "Ich und der Vater sind eins",
            id: "5456",
            sy4: "ܐܶܢܳܐ ܘܐܳܒܝ ܚܰܕ ܚܢܰܢ "
        },
        {
            lt12: "",
            sy6: "",
            dt1: "schlafen",
            sy9: "",
            sy5: "ܕܳܡܶܟ݂",
            sy4: "ܕܡܟ",
            lt10: "domekh",
            sy8: "",
            dt3: "",
            lt11: "",
            dt2: "",
            id: "3959",
            sy7: ""
        },
        {
            sy8: "",
            sy4: "ܐܪܘܥܘܬܐ ܪܘܫܡܝܬܐ",
            dt1: "Offizielle Begegnung",
            id: "6131",
            lt11: "",
            sy9: "",
            dt2: "",
            sy5: "ܐܳܪܽܘܥܽܘܬ݂ܳܐ ܪܽܘܫܡܳܝܬܳܐ",
            dt3: "",
            sy7: "",
            sy6: "",
            lt12: "",
            lt10: "oruĉutho rushmoyto"
        },
        {
            sy4: "ܛܘܣܐ",
            dt3: "",
            sy7: "",
            sy8: "",
            lt12: "",
            sy9: "",
            lt11: "",
            dt2: "",
            id: "3447",
            dt1: "Pfau",
            sy5: "ܛܰܘܣܳܐ",
            lt10: "țauso",
            sy6: ""
        },
        {
            sy6: "ܩܠܝܕܝܐ",
            sy4: "ܩܢܩܠܪܐ",
            sy9: "ܬܰܪܥܳܝܳܐ",
            lt12: "tarĉoyo",
            lt11: "qliđoyo",
            id: "1905",
            sy5: "ܩܰܢܩܶܠܳܪܳܐ",
            dt3: "",
            dt2: "Pförtner",
            sy8: "ܬܪܥܝܐ",
            sy7: "ܩܠܺܝܕܳܝܳܐ",
            dt1: "Hausmeister",
            lt10: "qanqeloro"
        },
        {
            lt10: "ܡܠܟܝܙܕܩ",
            sy7: "",
            sy8: "",
            sy5: "ܡܰܠܟܺܝܙܰܕܶܩ",
            id: "2905",
            dt1: "Malkisadek",
            sy4: "ܡܠܟܝܙܕܩ",
            dt2: "",
            lt11: "ܡܠܟܝܙܕܩ",
            dt3: "",
            sy9: "",
            lt12: "",
            sy6: ""
        },
        {
            lt10: "neshyonoyo",
            dt1: "achtlos",
            dt2: "",
            lt12: "methrahțono",
            sy7: "ܡܫܳܟܳܐܺܝܬ݂",
            sy6: "ܡܫܟܐܝܬ",
            id: "5735",
            sy9: "ܡܶܬ݂ܪܰܗܛܳܢܳܐ",
            sy5: "ܢܶܫܝܳܢܳܝܳܐ",
            lt11: "mshokoith",
            dt3: "",
            sy4: "ܢܫܝܢܝܐ",
            sy8: "ܡܬܪܗܛܢܐ"
        },
        {
            sy6: "",
            sy7: "",
            sy9: "",
            dt2: "dieses",
            sy8: "",
            sy4: "ܗܢܐ",
            sy5: "ܗܳܢܳܐ",
            dt1: "dieser",
            dt3: "",
            lt12: "",
            id: "890",
            lt11: "",
            lt10: "hono"
        },
        {
            dt3: "",
            sy5: "ܣܽܘܓܺܝܬܰܐ",
            sy6: "",
            sy9: "",
            lt11: "ܣܘܓܝܬܐ",
            dt2: "",
            lt10: "ܣܘܓܝܬܐ",
            dt1: "Sugita",
            lt12: "",
            sy4: "ܣܘܓܝܬܐ",
            sy7: "",
            sy8: "",
            id: "4309"
        },
        {
            lt12: "",
            sy7: "",
            dt2: "",
            sy4: "ܥܠ ܚܘܒܟ",
            dt3: "",
            lt11: "",
            sy5: "ܥܰܠ ܚܽܘܒܳܟ݂",
            lt10: "ĉal ĥubokh",
            sy9: "",
            sy6: "",
            id: "346",
            dt1: "Auf deine Liebe",
            sy8: ""
        },
        {
            sy8: "",
            sy6: "",
            lt12: "",
            lt10: "ܫܪܒܠܝܐ",
            sy9: "",
            sy5: "ܫܰܪܒܶܠܝܰܐ",
            dt2: "Scharbelia",
            dt3: "",
            sy7: "",
            dt1: "Scharbelya",
            sy4: "ܫܪܒܠܝܐ",
            lt11: "ܫܪܒܠܝܐ",
            id: "3902"
        },
        {
            sy5: "ܐܝܫܠܪ",
            dt3: "",
            lt10: "ܐܝܫܠܪ",
            lt11: "ܐܝܫܠܪ",
            lt12: "",
            sy4: "ܐܝܫܠܪ",
            sy6: "",
            id: "2250",
            sy7: "",
            dt2: "",
            sy9: "",
            dt1: "Isler",
            sy8: ""
        },
        {
            sy8: "",
            dt3: "Aula",
            sy6: "",
            sy5: "ܐܶܣܛܘܳܐ",
            lt10: "estwo",
            lt11: "",
            sy7: "",
            dt2: "Halle",
            lt12: "",
            sy9: "",
            sy4: "ܐܣܛܘܐ",
            id: "3781",
            dt1: "Saal"
        },
        {
            sy6: "",
            lt10: "ܨܝܨܠܐ",
            lt12: "",
            sy8: "",
            sy9: "",
            id: "4162",
            dt2: "",
            dt1: "Sislo",
            sy4: "ܨܝܨܠܐ",
            dt3: "",
            sy7: "",
            lt11: "ܨܝܨܠܐ",
            sy5: "ܨܺܝܨܠܳܐ"
        },
        {
            sy5: "ܡܟ݂ܝܺܪܰܐ",
            dt2: "",
            dt1: "Verlobter",
            lt10: "mkhiro",
            sy9: "",
            sy8: "",
            id: "4762",
            sy7: "",
            sy6: "",
            dt3: "",
            sy4: "ܡܟܝܪܐ",
            lt11: "",
            lt12: ""
        },
        {
            sy8: "ܠܘܝ",
            dt3: "",
            dt1: "begraben",
            sy6: "ܥܦܝ",
            dt2: "beerdigen",
            sy9: "ܠܰܘܺܝ",
            lt11: "ĉafi",
            lt12: "lawi",
            id: "529",
            sy4: "ܩܒܐܪ",
            lt10: "qbar",
            sy5: "ܩܒܰܐܪ",
            sy7: "ܥܰܦܺܝ"
        },
        {
            dt1: "Dimta",
            lt11: "ܕܝܡܬܐ",
            lt10: "ܕܝܡܬܐ",
            sy7: "",
            dt3: "",
            lt12: "",
            sy9: "",
            dt2: "Dimtha",
            sy8: "",
            id: "896",
            sy4: "ܕܝܡܬܐ",
            sy5: "ܕܺܝܡܬܰܐ",
            sy6: ""
        },
        {
            dt1: "Abwehrstoff",
            sy9: "",
            sy6: "",
            dt2: "",
            lt11: "",
            sy7: "",
            lt12: "",
            sy4: "ܡܠܘܐ ܕܣܘܬܪܐ",
            lt10: "mluo dsutoro",
            sy8: "",
            dt3: "",
            id: "5703",
            sy5: "ܡܠܽܘܐܳܐ ܕܣܽܘܬܳܪܳܐ"
        },
        {
            lt11: "ܓܦܬܐ",
            sy4: "ܓܦܬܐ",
            lt10: "ܓܦܬܐ",
            dt1: "Gfeta",
            sy7: "ܓܦܶܐܬܰܐ",
            dt2: "",
            sy6: "ܓܦܐܬܐ",
            sy9: "",
            sy5: "ܓܦܶܬܰܐ",
            id: "1635",
            lt12: "ܓܦܐܬܐ",
            dt3: "",
            sy8: ""
        },
        {
            dt2: "",
            lt10: "ܗܪܘܡܐ",
            dt1: "Herumo",
            sy4: "ܗܪܘܡܐ",
            sy6: "",
            dt3: "",
            sy5: "ܗܶܪܽܘܡܐ",
            sy8: "",
            sy7: "",
            id: "1976",
            lt12: "",
            lt11: "ܗܪܘܡܐ",
            sy9: ""
        },
        {
            id: "5953",
            dt3: "",
            dt1: "Anleitung",
            sy7: "",
            sy5: "ܡܰܪܙܳܢܽܘܬ݂ܳܐ",
            sy4: "ܡܪܙܢܘܬܐ",
            lt11: "",
            dt2: "",
            sy9: "",
            lt10: "marzonutho",
            lt12: "",
            sy8: "",
            sy6: ""
        },
        {
            sy4: "ܡܫܠܡܢܘܬܐ",
            sy6: "",
            id: "4877",
            sy5: "ܡܫܰܠܡܳܢܽܘܬ݂ܳܐ",
            sy8: "",
            lt12: "",
            dt1: "Vollkommenheit",
            dt3: "",
            lt11: "",
            lt10: "mshalmonutho",
            sy9: "",
            dt2: "",
            sy7: ""
        },
        {
            sy7: "ܣܕܰܩ",
            sy5: "ܐܶܬ݂ܦܰܠܰܓ݂",
            dt2: "",
            id: "5654",
            sy6: "ܣܕܩ",
            lt11: "sdaq",
            lt12: "",
            lt10: "ethfalağ",
            dt3: "",
            sy4: "ܐܬܦܠܓ",
            dt1: "abspalten",
            sy8: "",
            sy9: ""
        },
        {
            lt11: "",
            dt3: "Hospital",
            sy8: "",
            sy9: "",
            sy7: "",
            sy4: "ܒܝܬ ܟܪܝܗܐ",
            sy6: "",
            dt1: "Krankenhaus",
            lt12: "",
            dt2: "Spital",
            id: "2631",
            sy5: "ܒܶܝܬ݂ ܟܪܺܝܗܶܐ",
            lt10: "beth krihe"
        },
        {
            id: "2402",
            dt3: "",
            sy5: "ܡܩܰܪܒܳܢܺܝܬ݂ܳܐ ܕܰܡܟܰܬܫܳܐ",
            lt10: "mqarbonitho damkatsho",
            lt11: "",
            sy8: "",
            lt12: "",
            sy9: "",
            sy7: "",
            sy4: "ܡܩܪܒܢܝܬܐ ܕܡܟܬܫܐ",
            dt2: "",
            dt1: "kämpferische Kämpferin",
            sy6: ""
        },
        {
            sy5: "ܡܶܐܟ݂ܠܳܐ ܒܰܣܝܺܡܳܐ",
            id: "852",
            sy9: "",
            sy7: "",
            lt10: "mekhlo basimo",
            lt11: "",
            dt2: "",
            sy8: "",
            dt3: "",
            sy4: "ܡܐܟܠܐ ܒܣܝܡܐ",
            dt1: "Delikatesse",
            sy6: "",
            lt12: ""
        },
        {
            lt11: "mzizaĥ",
            sy4: "ܩܪܝܬܐ ܡܙܝܙܚ",
            dt2: "Mzizah",
            lt10: "qritho mzizaĥ",
            id: "942",
            dt3: "",
            sy6: "ܡܙܝܙܚ",
            sy9: "",
            sy7: "ܡܙܺܝܙܰܚ",
            sy8: "",
            sy5: "ܩܪܺܝܬ݂ܳܐ ܡܙܺܝܙܰܚ",
            dt1: "Dorf Mzizah",
            lt12: ""
        },
        {
            sy8: "",
            dt1: "friedfertig",
            sy7: "",
            sy9: "",
            lt10: "roĥem shayno",
            dt3: "",
            sy4: "ܪܚܡ ܫܝܢܐ",
            sy5: "ܪܳܚܶܡ ܫܰܝܢܳܐ",
            lt11: "",
            dt2: "",
            lt12: "",
            sy6: "",
            id: "6098"
        },
        {
            id: "1409",
            sy6: "",
            lt12: "",
            lt11: "",
            sy5: "ܐܽܘܪܕܥܳܐ",
            lt10: "urdĉo",
            dt2: "",
            dt1: "Frosch",
            sy7: "",
            sy4: "ܐܘܪܕܥܐ",
            sy8: "",
            sy9: "",
            dt3: ""
        },
        {
            sy7: "ܐܰܪܒܳܐ",
            dt3: "",
            lt10: "qritho arbo",
            lt11: "arbo",
            sy9: "",
            dt1: "Dorf Arbo",
            sy8: "",
            id: "922",
            sy6: "ܐܪܒܐ",
            dt2: "Arbo",
            sy4: "ܩܪܝܬܐ ܕܐܪܒܐ",
            sy5: "ܩܪܺܝܬ݂ܳܐ ܕܰܐܪܒܳܐ",
            lt12: ""
        },
        {
            id: "5158",
            lt10: "medyoro",
            dt2: "Unterkunft",
            dt1: "Wohnung",
            sy5: "ܡܶܕܝܳܪܳܐ",
            sy4: "ܡܕܝܪܐ",
            lt11: "awono",
            sy7: "ܐܰܘܳܢܳܐ",
            sy8: "ܐܘܢܬܐ",
            sy6: "ܐܘܢܐ",
            sy9: "ܐܰܘܳܢܬܳܐ",
            dt3: "",
            lt12: "awonto"
        },
        {
            dt2: "Mitte",
            sy8: "",
            sy6: "",
            lt10: "meso",
            sy9: "",
            id: "6335",
            sy5: "ܡܶܣܳܐ",
            sy7: "",
            dt3: "",
            lt12: "",
            lt11: "",
            dt1: "Zentrum",
            sy4: "ܡܣܐ"
        },
        {
            lt11: "ܡܬܝܢܐ",
            dt1: "Matina",
            lt10: "ܡܬܝܢܐ",
            dt2: "",
            sy5: "ܡܰܬܺܝܢܰܐ",
            sy9: "",
            sy4: "ܡܬܝܢܐ",
            dt3: "",
            sy6: "",
            sy7: "",
            lt12: "",
            sy8: "",
            id: "2970"
        },
        {
            lt12: "",
            lt11: "abohe",
            sy8: "",
            dt3: "",
            id: "1089",
            dt1: "Eltern",
            sy7: "ܐܰܒܳܗ̈ܶܐ",
            dt2: "",
            sy9: "",
            lt10: "abo u emo",
            sy6: "ܐܒܗܐ",
            sy5: "ܐܰܒܳܐ ܘܐܶܡܳܐ",
            sy4: "ܐܒܐ ܘܐܡܐ"
        },
        {
            sy7: "",
            id: "5105",
            lt12: "",
            dt1: "Wieviel",
            lt11: "",
            sy9: "",
            sy5: "ܟܡܳܐ",
            lt10: "kmo",
            sy6: "",
            dt2: "Wieviele",
            sy4: "ܟܡܐ",
            sy8: "",
            dt3: ""
        },
        {
            dt2: "Widersacher",
            lt10: "saqubloyo",
            sy5: "ܣܰܩܽܘܒܠܳܝܳܐ",
            sy8: "",
            lt11: "",
            sy6: "",
            sy4: "ܣܩܘܒܠܝܐ",
            sy9: "",
            dt1: "Antagonist",
            id: "5996",
            sy7: "",
            dt3: "",
            lt12: ""
        },
        {
            lt12: "",
            id: "605",
            sy9: "",
            sy6: "",
            dt3: "",
            sy5: "ܡܶܬ݂ܦܰܪܥܳܢܳܐ",
            sy4: "ܡܬܦܪܥܢܐ",
            lt10: "methfarĉono",
            dt1: "bezahlbar",
            sy8: "",
            dt2: "",
            sy7: "",
            lt11: ""
        },
        {
            dt1: "Theologie",
            dt2: "",
            lt10: "tewoloğiya",
            sy5: "ܬܳܐܘܳܠܳܘܓܺܝܰܐ",
            id: "4458",
            sy6: "ܡܡܠܐ ܐܠܗܝܐ",
            lt12: "mmalluth alohutho",
            sy7: "ܡܰܡܠܳܐ ܐܰܠܳܗܳܝܳܐ",
            sy8: "ܡܡܠܠܘܬ ܐܠܗܘܬܐ",
            sy4: "ܬܐܘܠܘܓܝܐ",
            lt11: "mamlo alohoyo",
            dt3: "",
            sy9: "ܡܡܰܠܠܽܘܬ݂ ܐܰܠܳܗܽܘܬ݂ܳܐ"
        },
        {
            dt2: "",
            sy6: "",
            dt3: "",
            sy7: "",
            sy9: "",
            lt12: "",
            dt1: "kneifen",
            sy4: "ܩܪܨ",
            id: "2539",
            sy8: "",
            sy5: "ܩܪܰܨ",
            lt11: "",
            lt10: "qraŝ"
        },
        {
            sy5: "ܫܳܡܰܥ ܐ̱ܢܳܐ ܒܝܰܕ ܐܶܕܢܳܐ",
            sy7: "",
            dt1: "Ich höre mit den Ohren",
            id: "2117",
            sy6: "",
            lt12: "",
            sy8: "",
            dt2: "",
            sy9: "",
            lt11: "",
            lt10: "shomaĉ`no byađ eđne",
            dt3: "",
            sy4: "ܫܡܥ ܐܢܐ ܒܝܕ ܐܕܢܐ"
        },
        {
            sy5: "ܐܶܣܦܺܝܪ ܐܰܪܥܳܐ",
            sy7: "ܐܰܪܥܳܐ",
            lt12: "",
            sy4: "ܐܣܦܝܪ ܐܪܥܐ",
            sy9: "",
            sy6: "ܐܪܥܐ",
            dt3: "",
            dt1: "Globus",
            sy8: "",
            dt2: "Erdkugel",
            lt11: "arĉo",
            lt10: "esfir arĉo",
            id: "1661"
        },
        {
            id: "1147",
            sy5: "ܣܢܺܝܩܳܝܳܐ",
            lt12: "",
            lt11: "sniqo",
            lt10: "sniqoyo",
            sy4: "ܣܢܝܩܝܐ",
            sy7: "ܣܢܺܝܩܳܐ",
            sy8: "",
            sy9: "",
            sy6: "ܣܢܝܩܐ",
            dt1: "erforderlich",
            dt2: "",
            dt3: ""
        },
        {
            lt10: "ܚܢܢܝܫܘܥ",
            id: "1845",
            sy9: "",
            sy5: "ܚܰܢܰܢܝܶܫܽܘܥ",
            lt12: "ܚܢܢܝܫܘܥ",
            sy7: "ܚܢܳܢܝܶܫܽܘܥ",
            sy8: "",
            sy6: "ܚܢܢܝܫܘܥ",
            dt2: "",
            lt11: "ܚܢܢܝܫܘܥ",
            sy4: "ܚܢܢܝܫܘܥ",
            dt1: "Hananjeschu",
            dt3: ""
        },
        {
            dt1: "Ahnungslosigkeit",
            sy6: "",
            sy7: "",
            lt12: "",
            sy5: "ܫܰܦܝܽܘܬ݂ܳܐ",
            sy9: "",
            sy4: "ܫܦܝܘܬܐ",
            id: "5770",
            lt10: "shafyutho",
            lt11: "",
            dt3: "",
            dt2: "",
            sy8: ""
        },
        {
            dt2: "",
            id: "3708",
            sy9: "",
            sy8: "",
            sy4: "ܬܝܒܐ",
            lt12: "",
            lt11: "",
            sy5: "ܬܰܝܳܒܳܐ",
            sy6: "",
            lt10: "tayobo",
            dt3: "",
            dt1: "reuiger Sünder",
            sy7: ""
        },
        {
            sy7: "ܠܽܘܥܳܐ",
            dt3: "",
            lt10: "lugmo",
            lt11: "luĉo",
            id: "2463",
            sy6: "ܠܘܥܐ",
            sy4: "ܠܘܓܡܐ",
            dt1: "Kiefer",
            lt12: "",
            sy8: "",
            dt2: "",
            sy9: "",
            sy5: "ܠܽܘܓܡܳܐ"
        },
        {
            sy7: "",
            sy5: "ܘܰܪܺܝܕܳܐ ܕܠܶܒܳܐ",
            lt10: "warido d`lebo",
            lt11: "",
            sy6: "",
            id: "1979",
            lt12: "",
            sy9: "",
            sy4: "ܘܪܝܕܐ ܕܠܒܐ",
            sy8: "",
            dt3: "",
            dt1: "Herzader",
            dt2: "Herzvene"
        },
        {
            dt2: "Substanz",
            sy6: "",
            sy9: "",
            dt3: "Hypostase",
            sy5: "ܩܢܽܘܡܳܐ",
            lt12: "",
            dt1: "Wesen",
            sy7: "",
            sy4: "ܩܢܘܡܐ",
            lt10: "qnumo",
            sy8: "",
            id: "6439",
            lt11: ""
        },
        {
            lt10: "gaybo",
            sy8: "",
            id: "1716",
            lt12: "",
            dt2: "",
            sy9: "",
            sy6: "",
            dt3: "",
            sy4: "ܓܝܒܐ",
            dt1: "Götzenanbetung",
            sy7: "",
            sy5: "ܓܰܝܒܳܐ",
            lt11: ""
        },
        {
            sy4: "ܐܠܦ",
            lt12: "",
            dt2: "Alaf",
            sy8: "",
            dt3: "Olaf",
            lt10: "ܐܠܦ",
            id: "175",
            dt1: "Alef",
            sy9: "",
            sy6: "",
            sy7: "",
            sy5: "ܐܰܠܶܦ",
            lt11: "ܐܠܦ"
        },
        {
            lt10: "bahro d`ĉayno",
            sy5: "ܒܰܗܪܳܐ ܕܥܰܝܢܳܐ",
            lt11: "",
            sy6: "",
            dt1: "Augenlicht",
            sy4: "ܒܗܪܐ ܕܥܝܢܐ",
            id: "367",
            sy9: "",
            sy7: "",
            lt12: "",
            dt3: "",
            dt2: "",
            sy8: ""
        },
        {
            sy6: "",
            lt11: "ܙܗܝܐ",
            lt10: "ܙܗܝܐ",
            dt2: "",
            dt1: "Zahyo",
            dt3: "",
            sy9: "",
            lt12: "",
            sy8: "",
            id: "5244",
            sy7: "",
            sy4: "ܙܗܝܐ",
            sy5: "ܙܰܗܝܳܐ"
        },
        {
            dt3: "",
            dt1: "Seife",
            lt11: "",
            sy8: "",
            lt12: "",
            sy4: "ܨܦܘܢܐ",
            sy9: "",
            id: "4100",
            lt10: "ŝafuno",
            sy6: "",
            dt2: "Shampoo",
            sy5: "ܨܰܦܽܘܢܳܐ",
            sy7: ""
        },
        {
            sy5: "ܕܰܒܶܫܬܳܐ",
            dt3: "",
            lt12: "",
            sy7: "ܟܰܟܳܪܺܝܬ݂ܳܐ",
            lt11: "kakoritho",
            dt2: "",
            lt10: "dabeshto",
            sy8: "",
            sy9: "",
            sy6: "ܟܟܪܝܬܐ",
            sy4: "ܕܒܫܬܐ",
            dt1: "Honigbiene",
            id: "6039"
        },
        {
            dt2: "",
            id: "5883",
            lt12: "",
            sy6: "",
            sy4: "ܐܪܘܥܘܬܐ ܪܘܫܡܝܬܐ",
            sy7: "",
            sy8: "",
            lt11: "",
            dt1: "amtliche Unterredung",
            dt3: "",
            lt10: "oruĉutho rushmoyto",
            sy9: "",
            sy5: "ܐܳܪܽܘܥܽܘܬ݂ܳܐ ܪܽܘܫܡܳܝܬܳܐ"
        },
        {
            sy8: "",
            sy6: "",
            dt2: "",
            id: "1014",
            lt11: "",
            sy9: "",
            dt3: "",
            lt10: "baĉltho",
            dt1: "Ehepaar",
            sy7: "",
            sy4: "ܒܥܠܬܐ",
            lt12: "",
            sy5: "ܒܰܥܰܠܬ݂ܳܐ"
        },
        {
            sy9: "",
            lt12: "",
            sy7: "",
            lt11: "",
            dt1: "Russland",
            dt3: "",
            sy6: "",
            dt2: "",
            lt10: "rusya",
            sy8: "",
            id: "3772",
            sy4: "ܪܘܣܝܐ",
            sy5: "ܪܽܘܣܝܰܐ"
        },
        {
            lt10: "ܚܘܒܐ",
            id: "2060",
            lt12: "ܚܘܒܐ",
            sy4: "ܚܘܒܐ",
            sy7: "ܚܽܘܒܰܐ",
            dt1: "Hubo",
            dt2: "",
            sy9: "",
            lt11: "ܚܘܒܐ",
            dt3: "",
            sy5: "ܚܽܘܒܳܐ",
            sy8: "",
            sy6: "ܚܘܒܐ"
        },
        {
            lt10: "shmoĉo d'sohdo",
            dt3: "",
            sy9: "",
            id: "6278",
            sy5: "ܫܡܳܥܳܐ ܕܣܳܗܕܳܐ",
            dt2: "",
            lt11: "mauđĉonuth sohde",
            dt1: "Zeugenaussage",
            sy8: "",
            sy7: "ܡܰܘܕܥܳܢܽܘܬ݂ ܣܳܗ̈ܕܶܐ",
            sy4: "ܫܡܥܐ ܕܣܗܕܐ",
            sy6: "ܡܘܕܥܢܘܬ ܣܗܕܐ",
            lt12: ""
        },
        {
            lt11: "",
            sy4: "ܠܫ",
            sy9: "",
            dt2: "",
            sy5: "ܠܶܫ",
            lt10: "lesh",
            id: "2541",
            sy7: "",
            sy8: "",
            dt1: "kneten",
            sy6: "",
            lt12: "",
            dt3: ""
        },
        {
            sy4: "ܡܘܢ ܐܝܬ ܠܟ",
            sy7: "",
            lt10: "mun ith lokh",
            sy8: "",
            sy5: "ܡܽܘܢ ܐܺܝܬ݂ ܠܳܟ",
            id: "5611",
            lt11: "",
            lt12: "",
            dt3: "",
            dt1: "Was hast du",
            sy9: "",
            sy6: "",
            dt2: ""
        },
        {
            sy5: "ܡܶܢܝܳܢܳܐ ܙܰܘܓܳܝܳܐ",
            dt3: "",
            lt11: "",
            lt12: "",
            dt1: "Doppelzahl",
            sy7: "",
            sy8: "",
            sy9: "",
            lt10: "menyono zaugoyo",
            sy4: "ܡܢܝܢܐ ܙܘܓܝܐ",
            id: "916",
            sy6: "",
            dt2: ""
        },
        {
            dt3: "",
            dt2: "",
            sy6: "",
            lt10: "mafqono",
            sy7: "",
            dt1: "Ausgang",
            sy9: "",
            lt11: "",
            lt12: "",
            sy5: "ܡܰܦܩܳܢܳܐ",
            id: "5530",
            sy4: "ܡܦܩܢܐ",
            sy8: ""
        },
        {
            lt10: "mshautafto tagoroyto",
            sy8: "",
            sy9: "",
            dt1: "Konzern",
            id: "2600",
            dt2: "",
            sy7: "",
            lt11: "",
            sy4: "ܡܫܘܬܦܬܐ ܬܓܪܝܬܐ",
            dt3: "",
            sy6: "",
            lt12: "",
            sy5: "ܡܫܰܘܬܰܐܦܬܳܐ ܬܰܓܳܪܳܝܬܳܐ"
        },
        {
            lt11: "",
            sy9: "",
            id: "5965",
            dt2: "",
            sy7: "",
            sy4: "ܡܬܓܘܙܠܢܘܬܐ",
            dt1: "Anmachholz",
            sy5: "ܡܶܬ݂ܓܰܘܙܠܳܢܽܘܬ݂ܳܐ",
            sy6: "",
            dt3: "",
            sy8: "",
            lt12: "",
            lt10: "methgauzlonutho"
        },
        {
            sy9: "",
            dt1: "b",
            lt12: "",
            lt11: "",
            sy6: "",
            dt2: "B",
            sy4: "ܒ",
            sy5: "ܒ",
            sy7: "",
            sy8: "",
            id: "409",
            dt3: "",
            lt10: "beth"
        },
        {
            sy4: "ܘܠܝܬ݂ܐ",
            dt3: "",
            sy6: "",
            lt10: "ܘܠܝܬ݂ܐ",
            lt11: "ܘܠܝܬ݂ܐ",
            sy8: "",
            dt2: "",
            sy9: "",
            lt12: "",
            dt1: "Wolitha",
            sy7: "",
            id: "5160",
            sy5: "ܘܳܠܺܝܬ݂ܰܐ"
        },
        {
            dt2: "",
            dt1: "Ganta",
            sy4: "ܓܢܬܐ",
            sy5: "ܓܰܢܬܰܐ",
            lt11: "ܓܢܬܐ",
            lt12: "",
            lt10: "ܓܢܬܐ",
            sy8: "",
            sy7: "",
            sy9: "",
            dt3: "",
            sy6: "",
            id: "1470"
        },
        {
            dt1: "Erlösung",
            id: "1158",
            sy5: "ܦܳܪܽܘܩܽܘܬ݂ܳܐ",
            dt3: "",
            lt10: "foruqutho",
            dt2: "Rettung",
            lt11: "furqono",
            sy4: "ܦܪܘܩܘܬܐ",
            sy7: "ܦܽܘܪܩܳܢܳܐ",
            lt12: "",
            sy9: "",
            sy6: "ܦܽܘܪܩܳܢܳܐ",
            sy8: ""
        },
        {
            sy5: "ܒܳܒܬܰܐ",
            sy9: "",
            sy4: "ܒܒܬܐ",
            lt12: "",
            sy8: "",
            dt2: "",
            dt3: "",
            lt10: "ܒܒܬܐ",
            sy7: "",
            dt1: "Bobta",
            lt11: "ܒܒܬܐ",
            id: "670",
            sy6: ""
        },
        {
            sy6: "",
            id: "5278",
            dt2: "",
            dt1: "Zeichen geben",
            sy5: "ܝܰܗܒ ܐܳܬ݂ܳܐ",
            lt11: "",
            sy9: "",
            sy8: "",
            sy4: "ܝܗܒ ܐܬܐ",
            dt3: "",
            lt12: "",
            lt10: "yab otho",
            sy7: ""
        },
        {
            id: "5316",
            sy4: "ܩܪܡܝܕܐ",
            dt1: "Ziegelstein",
            lt12: "",
            dt2: "Fliese",
            sy8: "",
            sy5: "ܩܰܪܡܺܝܕܳܐ",
            lt11: "",
            sy6: "",
            sy7: "",
            dt3: "",
            sy9: "",
            lt10: "qarmiđo"
        },
        {
            dt1: "Gift",
            sy4: "ܣܡܐ",
            lt10: "samo",
            sy5: "ܣܰܡܳܐ",
            sy6: "",
            sy7: "",
            lt11: "",
            lt12: "",
            dt3: "",
            id: "1641",
            dt2: "",
            sy8: "",
            sy9: ""
        },
        {
            lt10: "reğshe",
            lt11: "",
            sy5: "ܪܶܓ݂̈ܫܶܐ",
            sy6: "",
            dt2: "Emotionen",
            id: "5439",
            sy7: "",
            dt3: "",
            sy8: "",
            lt12: "",
            sy4: "ܪܶܓ݂̈ܫܶܐ",
            sy9: "",
            dt1: "Gefühle"
        },
        {
            id: "5748",
            dt1: "Aderbruch",
            lt12: "",
            sy5: "ܒܽܘܙܳܥ ܓܝܳܕܳܐ",
            lt10: "buzoĉ gyodo",
            dt3: "",
            sy8: "",
            sy7: "",
            sy9: "",
            dt2: "",
            sy4: "ܒܘܙܥ ܓܝܕܐ",
            lt11: "",
            sy6: ""
        },
        {
            lt10: "ĥyo l`ĥaye",
            dt2: "",
            sy4: "ܚܝܐ ܠܚܝܐ",
            lt11: "",
            sy5: "ܚܝܳܐ ܠܚܰܝ̈ܶܐ",
            sy9: "",
            sy7: "",
            dt1: "das Leben leben",
            dt3: "",
            id: "820",
            sy6: "",
            sy8: "",
            lt12: ""
        },
        {
            sy6: "",
            dt1: "Handelskammer",
            sy8: "",
            sy4: "ܩܠܝܬܐ ܕܬܓܪܘܬ݂ܐ",
            dt2: "",
            sy5: "ܩܶܠܳܝܬܳܐ ܕܬܰܓܳܪܽܘܬ݂ܳܐ",
            dt3: "",
            lt12: "",
            sy7: "",
            lt11: "",
            lt10: "qeloyto d`tagorutho",
            sy9: "",
            id: "1852"
        },
        {
            sy7: "",
            dt2: "",
            id: "2000",
            lt12: "",
            sy4: "ܡܠܟܘܬܐ ܚܝܠܐ ܘ ܬܫܒܘܚܬܐ",
            lt11: "",
            sy5: "ܡܰܠܟܽܘܬ݂ܳܐ ܚܰܝܠܳܐ ܘ ܬܶܫܒܽܘܚܬܳܐ",
            sy8: "",
            lt10: "malkutho ĥaylo u teshbuĥto",
            dt1: "Himmelreich Kraft und Herrlichkeit",
            sy6: "",
            dt3: "",
            sy9: ""
        },
        {
            dt2: "",
            sy4: "ܡܷܢ ܟܥܘܕܬ",
            dt3: "",
            sy9: "",
            sy6: "",
            dt1: "Was arbeitest du?",
            lt11: "",
            sy5: "ܡܷܢ ܟܳܥܰܘܕܰܬ",
            sy8: "",
            lt10: "Mën kocawdat?",
            lt12: "",
            sy7: "",
            id: "6453"
        },
        {
            sy5: "ܟܦܰܪ",
            sy8: "",
            lt12: "",
            dt1: "leugnen",
            lt10: "kfar",
            id: "2792",
            lt11: "",
            dt2: "verleugnen",
            sy6: "",
            sy4: "ܟܦܪ",
            sy7: "",
            dt3: "",
            sy9: ""
        },
        {
            id: "2033",
            lt12: "",
            sy7: "ܐܰܪܺܝܡ ܠܥܶܠ",
            sy6: "ܐܪܝܡ ܠܥܠ",
            dt3: "erheben",
            sy4: "ܐܪܝܡ",
            sy9: "",
            lt10: "arim",
            sy8: "",
            lt11: "arim lĉel",
            dt2: "emporheben",
            sy5: "ܐܰܪܺܝܡ",
            dt1: "hochheben"
        },
        {
            dt3: "",
            lt11: "țoĉen",
            sy5: "ܛܥܰܢ",
            dt1: "tragen",
            lt10: "țĉan",
            sy4: "ܛܥܢ",
            sy9: "",
            lt12: "",
            sy7: "ܛܳܥܶܢ",
            sy8: "",
            dt2: "schleppen",
            sy6: "ܛܥܢ",
            id: "4536"
        },
        {
            sy7: "",
            dt2: "",
            sy4: "ܒܥܝܐ ܐܢܐ ܠܟ ܠܚܘܕ ܟܠ ܕܛܒ",
            sy6: "",
            sy8: "",
            dt1: "wünsche dir nur das beste",
            sy9: "",
            lt11: "",
            id: "5176",
            lt12: "",
            sy5: "ܒܳܥܶܐ ܐ̱ܢܳܐ ܠܳܟ݂ ܠܚܽܘܕ ܟܽܠ ܕܛܳܒ",
            lt10: "boĉeno lokh lĥud kul dțob",
            dt3: ""
        },
        {
            dt2: "",
            sy7: "",
            dt3: "",
            sy5: "ܚܰܡܽܘܪܰܒܺܝ",
            sy9: "",
            lt11: "ܚܡܘܪܒܝ",
            id: "1837",
            sy4: "ܚܡܘܪܒܝ",
            lt12: "",
            dt1: "Hammurabi",
            sy6: "",
            lt10: "ܚܡܘܪܒܝ",
            sy8: ""
        },
        {
            sy7: "",
            sy8: "",
            sy9: "",
            lt10: "arnğo",
            sy6: "",
            dt3: "",
            id: "3618",
            sy5: "ܐܰܪܢܓ݂ܳܐ",
            dt2: "",
            lt11: "",
            lt12: "",
            sy4: "ܐܪܢܓܐ",
            dt1: "Ratte"
        },
        {
            dt1: "brennend",
            dt2: "flammend",
            lt12: "",
            sy4: "ܫܠܗܒܬܢܝܐ",
            dt3: "Feuer lodert",
            lt11: "",
            sy8: "",
            sy7: "",
            lt10: "shalhebthonoyo",
            sy9: "",
            sy6: "",
            sy5: "ܫܰܠܗܶܒܬ݂ܳܢܳܝܳܐ",
            id: "702"
        },
        {
            lt10: "nțar lholen droĥem no lhun aminoith ",
            sy6: "",
            dt1: "Wache über die die ich immer lieben werde",
            sy7: "",
            dt2: "",
            sy4: "ܢܛܪ ܠܗܠܝܢ ܕܪܚܡ ܐܢܐ ܠܗܘܢ ܐܡܝܢܐܝܬ",
            lt11: "",
            dt3: "",
            sy9: "",
            lt12: "",
            sy5: "ܢܛܰܪ ܠܗܳܠܶܝܢ ܕܪܳܚܶܡ ܐ̱ܢܳܐ ܠܗܽܘܢ ܐܰܡܺܝܢܳܐܺܝܬ݂",
            sy8: "",
            id: "4918"
        },
        {
            id: "2943",
            sy5: "ܡܰܪܢܺܝܬܰܐ",
            lt10: "ܡܪܢܝܬܐ",
            sy8: "",
            dt2: "",
            sy6: "",
            sy7: "",
            lt12: "",
            lt11: "ܡܪܢܝܬܐ",
            sy4: "ܡܪܢܝܬܐ",
            dt3: "",
            dt1: "Marnita",
            sy9: ""
        },
        {
            dt1: "Nachbar",
            sy8: "",
            sy7: "",
            dt2: "",
            lt10: "shbobo",
            sy6: "",
            sy9: "",
            dt3: "",
            lt11: "",
            lt12: "",
            sy5: "ܫܒܳܒܳܐ",
            sy4: "ܫܒܒܐ",
            id: "3151"
        },
        {
            sy8: "",
            sy5: "ܝܰܡܺܝܢܳܐ",
            sy9: "",
            lt11: "",
            lt12: "",
            dt3: "",
            lt10: "yamino",
            sy4: "ܝܡܝܢܐ",
            dt2: "",
            id: "3638",
            dt1: "rechts",
            sy6: "",
            sy7: ""
        },
        {
            lt12: "",
            sy5: "ܢܙܺܝܪܳܐ",
            dt2: "",
            lt10: "nziro",
            sy7: "",
            sy4: "ܢܙܝܪܐ",
            id: "6291",
            dt1: "zolibatär",
            dt3: "",
            sy8: "",
            sy6: "",
            lt11: "",
            sy9: ""
        },
        {
            dt2: "",
            lt12: "ĉam aqeftho",
            sy6: "ܒܗܕܐ",
            sy7: "ܒܗܳܕܶܐ",
            sy9: "ܥܰܡ ܐܰܩܶܦܬ݂ܳܐ",
            sy4: "ܥܡ ܗܕܐ",
            lt10: "ĉam hode",
            sy5: "ܥܰܡ ܗܳܕܶܐ",
            lt11: "bhode",
            dt3: "",
            id: "5914",
            sy8: "ܥܡ ܐܩܦܬܐ",
            dt1: "anbei"
        },
        {
            sy4: "ܕܒܪ",
            id: "781",
            sy7: "",
            sy6: "",
            dt1: "Dabar",
            dt3: "",
            sy9: "",
            sy8: "",
            sy5: "ܕܰܒܰܪ",
            lt12: "",
            dt2: "",
            lt10: "ܕܒܪ",
            lt11: "ܕܒܪ"
        },
        {
            sy5: "ܚܒܰܫ",
            lt10: "ĥbash",
            sy9: "",
            lt12: "",
            id: "1054",
            dt1: "einsperren",
            sy7: "ܚܳܒܶܫ",
            dt3: "",
            sy8: "",
            lt11: "ĥobesh",
            sy4: "ܚܒܫ",
            dt2: "gefangen",
            sy6: "ܚܒܫ"
        },
        {
            dt3: "",
            sy8: "",
            sy5: "ܐܺܝܣܪܳܐܝܶܠ",
            id: "2251",
            sy6: "",
            sy7: "",
            lt11: "",
            lt12: "",
            dt2: "",
            lt10: "isroel",
            dt1: "Israel",
            sy9: "",
            sy4: "ܐܝܣܪܐܝܠ"
        },
        {
            sy5: "ܙܡܺܝܪܬܳܐ",
            id: "2824",
            sy6: "",
            lt12: "",
            sy9: "",
            dt3: "",
            sy4: "ܙܡܝܪܬܐ",
            dt2: "Gesänge",
            lt11: "",
            sy8: "",
            sy7: "",
            dt1: "Lieder",
            lt10: "zmirotho"
        },
        {
            sy6: "ܡܟܫܪܢܐ",
            sy9: "",
            id: "1146",
            lt12: "",
            lt10: "kashiroith",
            dt1: "erfolgreich",
            sy8: "",
            sy4: "ܟܫܝܪܐܝܬ",
            dt2: "",
            lt11: "makshrono",
            sy7: "ܡܰܟܫܪܳܢܳܐ",
            sy5: "ܟܰܫܺܝܪܳܐܺܝܬ݂",
            dt3: ""
        },
        {
            dt1: "Leugner",
            sy5: "ܕܰܓܳܠܳܐ",
            sy7: "ܡܕܳܓܠܳܢܳܐ",
            sy8: "ܟܕܒܐ",
            sy6: "ܡܕܓܠܢܐ",
            dt2: "",
            lt11: "mdaglono",
            sy9: "ܟܰܕܳܒܳܐ",
            id: "2793",
            lt10: "dagolo",
            dt3: "",
            sy4: "ܕܓܠܐ",
            lt12: "kađobo"
        },
        {
            sy9: "",
            lt10: "galwoyo",
            dt3: "Emigrant",
            lt12: "",
            sy8: "",
            sy6: "ܬܘܬܒܐ",
            sy4: "ܓܠܘܝܐ",
            id: "1340",
            dt1: "Flüchtling",
            lt11: "tautobo",
            dt2: "Asylant",
            sy5: "ܓܰܠܘܳܝܳܐ",
            sy7: "ܬܰܘܬܒܳܐ"
        },
        {
            id: "686",
            lt12: "",
            dt3: "",
            lt10: "izgado",
            sy7: "",
            dt2: "",
            dt1: "Botschafter",
            sy4: "ܐܝܙܓܕܐ",
            sy9: "",
            sy6: "",
            lt11: "",
            sy5: "ܐܺܝܙܓܰܕܳܐ",
            sy8: ""
        },
        {
            sy7: "",
            sy4: "ܩܛܪܐ",
            sy5: "ܩܛܳܪܳܐ",
            lt11: "",
            dt3: "",
            lt12: "",
            dt2: "Eisenbahn",
            sy8: "",
            sy9: "",
            sy6: "",
            id: "5362",
            dt1: "Zug",
            lt10: "qțoro"
        },
        {
            sy4: "ܒܙܒܢܐ ܩܪܝܒܐ",
            lt10: "b`zabno qaribo",
            sy8: "",
            sy7: "",
            lt11: "",
            sy9: "",
            sy5: "ܒܙܰܒܢܳܐ ܩܰܪܝܒܳܐ",
            dt3: "",
            id: "2188",
            lt12: "",
            dt2: "",
            dt1: "in naher Zukunft",
            sy6: ""
        },
        {
            dt2: "",
            sy9: "",
            sy6: "",
            dt1: "Zahi",
            sy4: "ܙܗܝ",
            lt10: "ܙܗܝ",
            sy5: "ܙܰܗܺܝ",
            id: "5221",
            sy8: "",
            sy7: "",
            dt3: "",
            lt11: "ܙܗܝ",
            lt12: ""
        },
        {
            lt12: "",
            dt3: "Ahisamek",
            sy6: "",
            dt2: "Ahisemech",
            sy8: "",
            lt11: "ܐܚܝܣܡܟܐ",
            id: "159",
            sy5: "ܐܰܚܝܣܰܡܟܳܐ",
            sy7: "",
            lt10: "ܐܚܝܣܡܟܐ",
            dt1: "Ahisamko",
            sy9: "",
            sy4: "ܐܚܝܣܡܟܐ"
        },
        {
            lt11: "ܐܪܝܐܝܠ",
            lt10: "ܐܪܝܐܝܠ",
            dt3: "",
            sy9: "",
            sy7: "",
            id: "289",
            dt1: "Ariel",
            sy5: "ܐܰܪܺܝܐܝܶܠ",
            lt12: "",
            sy4: "ܐܪܝܐܝܠ",
            sy8: "",
            dt2: "",
            sy6: ""
        },
        {
            sy5: "ܩܰܪ",
            dt1: "abkühlen",
            lt12: "",
            sy7: "ܫܰܠܗܺܝ",
            dt3: "",
            sy9: "",
            lt11: "shalhi",
            sy4: "ܩܪ",
            lt10: "qar",
            dt2: "",
            sy8: "",
            id: "5577",
            sy6: "ܫܠܗܝ"
        },
        {
            id: "4713",
            lt11: "",
            sy4: "ܠܐ ܒܣܪܢܝܐ",
            dt1: "vegetarisch",
            dt2: "",
            sy7: "",
            lt10: "lo besronoyo",
            sy5: "ܠܳܐ ܒܶܣܪܳܢܳܝܳܐ",
            sy6: "",
            sy9: "",
            sy8: "",
            lt12: "",
            dt3: ""
        },
        {
            sy6: "",
            id: "2737",
            lt12: "",
            sy9: "",
            sy7: "",
            lt11: "",
            lt10: "fire basime",
            dt2: "",
            dt1: "Leckere Früchte",
            sy8: "",
            sy5: "ܦܺܐܪ̈ܐ ܒܰܣܺܝܡܶܐ",
            dt3: "",
            sy4: "ܦܐܪܐ ܒܣܝܡܐ"
        },
        {
            sy8: "",
            lt11: "ĥzotho ĥarifto",
            dt3: "",
            lt10: "ĥzotho dneshre",
            sy4: "ܚܙܬܐ ܕܢܫܪܐ",
            lt12: "",
            dt1: "Adlerblick",
            sy7: "ܚܙܳܬ݂ܳܐ ܚܰܪܺܝܦܬܳܐ",
            dt2: "",
            id: "5766",
            sy9: "",
            sy5: "ܚܙܳܬ݂ܳܐ ܕܢܶܫܪܶܐ",
            sy6: "ܚܙܬܐ ܚܪܝܦܬܐ"
        },
        {
            dt3: "stürzen",
            sy8: "",
            sy7: "ܢܦܰܠ",
            sy6: "ܢܦܠ",
            lt12: "",
            sy9: "",
            dt1: "fallen",
            dt2: "runterfallen",
            sy5: "ܢܦܳܠܳܐ",
            lt11: "nfal",
            sy4: "ܢܦܠܐ",
            id: "1243",
            lt10: "nfolo"
        },
        {
            sy7: "",
            dt3: "Vesuch",
            lt12: "",
            sy8: "",
            sy5: "ܢܶܣܝܽܘܢܳܐ",
            sy9: "",
            lt11: "",
            dt2: "Prüfung",
            id: "4442",
            dt1: "Test",
            sy6: "",
            sy4: "ܢܣܝܘܢܐ",
            lt10: "nesyuno"
        },
        {
            lt11: "baĉoyo",
            sy8: "",
            dt2: "",
            sy4: "ܒܥܘܝܐ",
            lt10: "boĉuyo",
            dt1: "Suchender",
            sy7: "ܒܰܥܳܝܳܐ",
            sy6: "ܒܥܝܐ",
            dt3: "",
            lt12: "",
            id: "4306",
            sy5: "ܒܳܥܽܘܝܳܐ",
            sy9: ""
        },
        {
            sy9: "",
            dt2: "glorifizieren",
            sy4: "ܫܒܚ",
            lt11: "",
            id: "3507",
            sy8: "",
            lt10: "shbaĥ",
            dt1: "preisen",
            sy6: "",
            sy7: "",
            dt3: "verherrlichen",
            sy5: "ܫܒܰܚ",
            lt12: ""
        },
        {
            lt11: "",
            id: "1928",
            dt1: "Heiliger",
            sy8: "",
            dt2: "",
            sy7: "",
            sy5: "ܩܰܕܺܝܫܳܐ",
            sy6: "",
            sy4: "ܩܕܝܫܐ",
            lt10: "qadisho",
            lt12: "",
            sy9: "",
            dt3: ""
        },
        {
            dt3: "",
            sy4: "ܠܒܢܢ",
            sy5: "ܠܶܒܢܳܢ",
            lt12: "",
            dt1: "Libanon",
            lt11: "",
            sy7: "",
            id: "2801",
            sy8: "",
            sy6: "",
            sy9: "",
            lt10: "lebnon",
            dt2: ""
        },
        {
            lt11: "",
            dt1: "Lohn an den Lohnarbeiter",
            sy9: "",
            dt3: "",
            dt2: "",
            sy8: "",
            sy6: "",
            sy7: "",
            lt12: "",
            sy5: "ܦܰܥܠܳܐ",
            sy4: "ܦܥܠܐ",
            id: "5491",
            lt10: "faĉlo"
        },
        {
            sy4: "ܠܐ ܝܕܥ",
            dt2: "",
            dt1: "nicht kennen",
            dt3: "",
            sy7: "",
            lt12: "",
            sy6: "",
            sy5: "ܠܳܐ ܝܺܕܰܥ",
            sy8: "",
            lt11: "",
            lt10: "lo iđaĉ",
            id: "3262",
            sy9: ""
        },
        {
            sy6: "",
            sy9: "",
            id: "207",
            sy8: "",
            lt12: "",
            dt1: "Amedya",
            lt10: "ܐܡܕܝܐ",
            dt3: "",
            sy7: "",
            sy5: "ܐܰܡܶܕܝܰܐ",
            dt2: "",
            lt11: "ܐܡܕܝܐ",
            sy4: "ܐܡܕܝܐ"
        },
        {
            lt11: "",
            lt12: "",
            sy9: "",
            lt10: "shushmono faloĥo",
            dt2: "",
            sy6: "",
            id: "6020",
            dt3: "",
            dt1: "Arbeitsameise",
            sy7: "",
            sy8: "",
            sy5: "ܫܽܘܫܡܳܢܳܐ ܦܰܠܳܚܳܐ",
            sy4: "ܫܘܫܡܢܐ ܦܠܚܐ"
        },
        {
            lt12: "",
            sy4: "ܒܝܬ ܐܪܟܐ",
            lt10: "beth arke",
            lt11: "",
            dt3: "",
            sy5: "ܒܶܝܬ݂ ܐܰܪܟܶܐ",
            sy8: "",
            sy9: "",
            dt1: "Bibliothek",
            sy7: "",
            dt2: "Archiv",
            id: "611",
            sy6: ""
        },
        {
            sy8: "",
            lt12: "",
            dt1: "guter Hirte",
            sy4: "ܪܥܝܐ ܛܒܐ",
            dt2: "",
            sy5: "ܪܥܝܳܐ ܛܳܒܳܐ",
            lt11: "",
            lt10: "roĉyo țobo",
            dt3: "",
            id: "1776",
            sy6: "",
            sy7: "",
            sy9: ""
        },
        {
            dt2: "",
            id: "2753",
            dt3: "",
            sy9: "",
            lt12: "",
            sy4: "ܓܙܪ ܕܝܢܐ ܩܠܝܠܐ",
            sy5: "ܓܙܳܪ ܕܺܝܢܳܐ ܩܰܠܺܝܠܳܐ",
            lt11: "",
            sy6: "",
            dt1: "leichte Strafe",
            lt10: "gzor dino qalilo",
            sy8: "",
            sy7: ""
        },
        {
            id: "2227",
            sy8: "",
            sy9: "",
            dt2: "",
            dt1: "Internationale Gemeinschaft",
            lt12: "",
            lt11: "",
            lt10: "kensho emwothoyo",
            sy6: "",
            sy5: "ܟܶܢܫܳܐ ܐܶܡܘ̈ܳܬ݂ܳܝܳܐ",
            sy7: "",
            dt3: "",
            sy4: "ܟܢܫܐ ܐܡܘ̈ܬ݂ܝܐ"
        },
        {
            dt1: "Phillippos",
            sy9: "",
            sy5: "ܦܺܝܠܺܝܦܳܘܣ",
            id: "3460",
            sy4: "ܦܝܠܝܦܘܣ",
            lt12: "ܦܝܠܝܦܘܣ",
            sy8: "",
            lt10: "ܦܝܠܝܦܘܣ",
            sy6: "ܦܝܠܝܦܘܣ",
            lt11: "ܦܝܠܝܦܘܣ",
            dt2: "",
            dt3: "",
            sy7: "ܦܺܝܠܺܝܦܽܘܣ"
        },
        {
            dt2: "",
            dt3: "",
            lt10: "rishmolo",
            lt12: "",
            sy5: "ܪܺܝܫܡܳܠܳܐ",
            id: "2405",
            lt11: "",
            sy8: "",
            sy6: "",
            sy4: "ܪܝܫܡܠܐ",
            sy9: "",
            dt1: "Kapital",
            sy7: ""
        },
        {
            sy4: "ܚܙܐ ܐܢܐ ܒܝܕ ܥܝܢܐ",
            dt1: "Ich sehe mit den Augen",
            sy5: "ܚܳܙܶܐ ܐ̱ܢܳܐ ܒܝܰܕ ܥܰܝ̈ܢܶܐ",
            lt12: "",
            lt10: "ĥoze no byađ ĉayne",
            lt11: "",
            sy7: "",
            dt3: "",
            sy6: "",
            sy9: "",
            id: "2137",
            sy8: "",
            dt2: ""
        },
        {
            dt3: "",
            dt2: "",
            sy9: "",
            sy6: "",
            lt10: "ܡܕܝܢܬܐ",
            sy4: "ܡܕܝܢ̱ܬܐ",
            sy5: "ܡܶܕܺܝܢ̱ܬܰܐ",
            lt11: "ܡܕܝܢܬܐ",
            id: "2981",
            sy8: "",
            dt1: "Medita",
            sy7: "",
            lt12: ""
        },
        {
            id: "1863",
            sy8: "",
            dt1: "Haniyuta",
            sy5: "ܗܰܢܺܝܐܽܘܬܰܐ",
            sy4: "ܗܢܝܐܘܬܐ",
            sy6: "",
            sy7: "",
            dt2: "",
            sy9: "",
            lt12: "",
            lt11: "ܗܢܝܐܘܬܐ",
            lt10: "ܗܢܝܐܘܬܐ",
            dt3: ""
        },
        {
            sy8: "",
            sy9: "",
            lt12: "",
            sy5: "ܡܰܓܳܢ ܒܺܝܠܕܰܪܳܐ",
            lt10: "magon bildaro",
            dt1: "portofrei",
            sy7: "",
            id: "3494",
            dt2: "",
            lt11: "",
            dt3: "",
            sy6: "",
            sy4: "ܡܓܢ ܒܝܠܕܪܐ"
        },
        {
            sy4: "ܟܣܢܐ",
            lt10: "kesone",
            lt12: "",
            sy9: "",
            sy7: "",
            dt1: "getrocknete Früchte",
            dt3: "",
            dt2: "",
            sy6: "",
            lt11: "",
            id: "1614",
            sy8: "",
            sy5: "ܟܶܣܳܢ̈ܶܐ"
        },
        {
            lt11: "sayofo",
            dt2: "",
            dt1: "Henker",
            sy4: "ܩܛܘܠܐ",
            sy8: "ܩܣܛܘܢܪܐ",
            sy7: "ܣܰܝܳܦܳܐ",
            lt12: "qesțunoro",
            sy9: "ܩܶܣܛܽܘܢܳܪܳܐ",
            sy6: "ܣܝܦܐ",
            sy5: "ܩܳܛܽܘܠܳܐ",
            id: "6056",
            dt3: "",
            lt10: "qoțulo"
        },
        {
            id: "3866",
            sy6: "",
            dt3: "",
            lt10: "makyono",
            dt2: "",
            lt11: "",
            lt12: "",
            dt1: "schädlich",
            sy8: "",
            sy4: "ܡܟܝܢܐ",
            sy9: "",
            sy5: "ܡܰܟܝܳܢܳܐ",
            sy7: ""
        },
        {
            sy5: "ܩܢܳܝܳܐ",
            lt10: "qnoyo",
            sy4: "ܩܢܝܐ",
            dt2: "",
            sy6: "",
            lt12: "",
            lt11: "",
            sy7: "",
            sy8: "",
            dt1: "Rendite",
            sy9: "",
            dt3: "",
            id: "3689"
        },
        {
            lt10: "ܬܗܪܐ",
            id: "4421",
            sy5: "ܬܶܗܪܳܐ",
            sy7: "",
            dt3: "",
            lt12: "",
            dt2: "",
            sy4: "ܬܗܪܐ",
            sy9: "",
            sy8: "",
            dt1: "Tehro",
            sy6: "",
            lt11: "ܬܗܪܐ"
        },
        {
            sy6: "",
            sy9: "",
            dt2: "Steyfo",
            dt1: "Stayfo",
            sy8: "",
            sy5: "ܣܛܰܝܦܳܐ",
            lt11: "ܣܛܝܦܐ",
            sy7: "",
            lt10: "ܣܛܝܦܐ",
            id: "4246",
            lt12: "",
            sy4: "ܣܛܝܦܐ",
            dt3: ""
        },
        {
            sy6: "",
            sy8: "",
            lt12: "",
            dt1: "Ehrenmitglied",
            sy9: "",
            lt10: "hadomo d`iqoro",
            id: "1018",
            lt11: "",
            dt2: "",
            sy4: "ܗܕܡܐ ܕܐܝܩܪܐ",
            sy5: "ܗܰܕܳܡܳܐ ܕܐܺܝܩܳܪܳܐ",
            dt3: "",
            sy7: ""
        },
        {
            lt11: "",
            sy7: "",
            sy6: "",
            lt10: "mafuĥitho",
            sy8: "",
            lt12: "",
            dt1: "Ventilator",
            sy9: "",
            dt2: "Lüfter",
            sy4: "ܡܦܘܚܝܬܐ",
            id: "4714",
            sy5: "ܡܰܦܽܘܚܺܝܬ݂ܳܐ",
            dt3: ""
        },
        {
            dt1: "Umkehr",
            sy6: "ܗܦܟܢܘܬܐ",
            sy9: "",
            sy7: "ܗܰܦܟ݂ܳܢܽܘܬ݂ܳܐ",
            sy8: "",
            sy5: "ܗܶܦܟ݂ܳܐ",
            sy4: "ܗܦܟܐ",
            lt11: "hafkhonutho",
            lt10: "hefkho",
            dt2: "Rückschritt",
            id: "4628",
            lt12: "",
            dt3: ""
        },
        {
            sy4: "ܨܘܪܬܐ",
            lt10: "ŝurto",
            lt12: "",
            sy8: "",
            id: "615",
            dt2: "Foto",
            sy7: "",
            dt1: "Bild",
            lt11: "",
            sy5: "ܨܽܘܪܬܳܐ",
            dt3: "",
            sy6: "",
            sy9: ""
        },
        {
            id: "3787",
            sy9: "",
            lt11: "ܙܒܝܢܐ",
            sy4: "ܙܒܝܢܐ",
            sy8: "",
            sy5: "ܙܰܒܺܝܢܰܐ",
            dt2: "Sabina",
            dt1: "Sabine",
            dt3: "Zabina",
            lt10: "ܙܒܝܢܐ",
            lt12: "",
            sy6: "",
            sy7: ""
        },
        {
            dt1: "Gewächs",
            id: "1616",
            lt11: "ĉesbo",
            dt3: "",
            lt10: "ĉufyo",
            sy4: "ܥܘܦܝܐ",
            sy5: "ܥܽܘܦܝܳܐ",
            sy8: "",
            sy9: "",
            lt12: "",
            sy7: "ܥܶܣܒܳܐ",
            dt2: "",
            sy6: "ܥܣܒܐ"
        },
        {
            dt1: "Käufer",
            sy9: "",
            lt10: "zobuno",
            dt2: "",
            dt3: "",
            id: "2437",
            lt12: "",
            sy6: "ܫܩܠܐ",
            sy4: "ܙܒܘܢܐ",
            lt11: "shaqolo",
            sy5: "ܙܰܒܽܘܢܳܐ",
            sy7: "ܫܰܩܳܠܳܐ",
            sy8: ""
        },
        {
            sy6: "",
            dt1: "Rafqa",
            dt2: "",
            sy4: "ܪܦܩܐ",
            sy7: "",
            id: "3594",
            lt11: "ܪܦܩܐ",
            sy5: "ܪܰܦܩܰܐ",
            sy8: "",
            lt12: "",
            dt3: "",
            sy9: "",
            lt10: "ܪܦܩܐ"
        },
        {
            lt12: "ܟܣܪ",
            lt11: "ܟܣܕ",
            sy5: "ܟܶܣܶܕ",
            sy7: "ܟܳܣܳܪ",
            sy4: "ܟܣܕ",
            dt1: "Kosod",
            lt10: "ܟܣܕ",
            dt3: "",
            sy8: "",
            sy6: "ܟܣܪ",
            sy9: "",
            id: "2621",
            dt2: ""
        },
        {
            sy8: "",
            dt1: "Wir",
            sy7: "",
            id: "5120",
            sy6: "",
            lt12: "",
            sy4: "ܚܢܢ",
            lt11: "",
            sy9: "",
            sy5: "ܚܢܰܢ",
            dt2: "",
            lt10: "ĥnan",
            dt3: ""
        },
        {
            id: "1572",
            dt1: "Gericht",
            sy4: "ܒܝܬ ܕܝܢܐ",
            lt12: "",
            dt3: "",
            sy9: "",
            sy7: "",
            lt11: "",
            lt10: "beth dino",
            sy5: "ܒܶܝܬ݂ ܕܺܝܢܳܐ",
            sy6: "",
            dt2: "",
            sy8: ""
        },
        {
            sy8: "",
            lt11: "",
            sy7: "",
            lt12: "",
            id: "1923",
            sy5: "ܩܰܕܺܝܫܰܬ ܠܳܐ ܡܝܽܘܬ݂ܳܐ",
            sy9: "",
            dt1: "Heilig bist du der Unsterbliche",
            dt3: "",
            sy6: "",
            sy4: "ܩܕܝܫܬ ܠܐ ܡܝܘܬܐ",
            dt2: "",
            lt10: "qadishat lo moyutho"
        },
        {
            lt11: "",
            lt12: "",
            sy5: "ܐܳܡܽܘܪܳܐ",
            sy6: "",
            sy9: "",
            sy4: "ܐܡܘܪܐ",
            sy8: "",
            id: "880",
            dt2: "Poet",
            dt1: "Dichter",
            dt3: "",
            lt10: "omuro",
            sy7: ""
        },
        {
            sy8: "",
            dt3: "",
            lt11: "",
            lt10: "muĉitho d`saĉro",
            dt1: "Haarwuchs",
            dt2: "",
            id: "1786",
            sy9: "",
            sy6: "",
            sy5: "ܡܽܘܥܽܝܬ݂ܳܐ ܕܣܰܥܪܳܐ",
            sy4: "ܡܘܥܝܬܐ ܕܣܥܪܐ",
            sy7: "",
            lt12: ""
        },
        {
            lt10: "mraurbono",
            id: "2876",
            dt3: "",
            sy8: "",
            sy6: "",
            dt2: "Mikroskop",
            sy4: "ܡܪܘܪܒܢܐ",
            dt1: "Lupe",
            lt11: "",
            sy5: "ܡܪܰܘܪܒܳܢܳܐ",
            lt12: "",
            sy9: "",
            sy7: ""
        },
        {
            dt3: "",
            sy8: "",
            lt12: "",
            sy6: "ܚܙܝܬܐ",
            dt1: "Brille",
            dt2: "",
            sy9: "",
            sy5: "ܚܰܙܳܝܬܳܐ",
            sy4: "ܚܙܝܬܐ",
            sy7: "ܚܰܙܳܝܳܬ݂ܳܐ",
            lt11: "ĥazoyotho",
            id: "711",
            lt10: "ĥazoyto"
        },
        {
            sy8: "",
            sy5: "ܒܶܣܰܕܝܳܐ",
            sy4: "ܒܣܕܝܐ",
            lt12: "",
            dt2: "Kissen",
            sy9: "",
            lt10: "besadyo",
            dt3: "",
            dt1: "Kopfkissen",
            lt11: "",
            id: "2603",
            sy6: "",
            sy7: ""
        },
        {
            sy6: "",
            sy5: "ܡܶܛܪܽܘܦܳܘܠܺܝܣ",
            dt1: "Weltstadt",
            dt2: "",
            dt3: "",
            sy7: "",
            id: "5060",
            lt12: "",
            lt11: "",
            sy9: "",
            sy4: "ܡܛܪܘܦܘܠܝܣ",
            sy8: "",
            lt10: "mețrofolis"
        },
        {
            sy7: "",
            lt12: "",
            sy5: "ܫܰܒܳܠܳܐ",
            sy9: "",
            sy6: "",
            lt10: "shabolo",
            id: "2762",
            lt11: "",
            dt3: "",
            sy8: "",
            dt1: "Lenker",
            sy4: "ܫܒܠܐ",
            dt2: ""
        },
        {
            sy9: "",
            sy8: "",
            sy4: "ܦܘܠܝܛܝܩܝ ܕܬܓܪܘܬܐ",
            dt2: "",
            sy6: "",
            lt12: "",
            sy5: "ܦܽܘܠܺܝܛܺܝܩܺܝ ܕܬܰܓܳܪܽܘܬ݂ܳܐ",
            lt11: "",
            dt1: "Handelspolitik",
            lt10: "fulițiqi d`tagorutho",
            id: "1853",
            dt3: "",
            sy7: ""
        },
        {
            lt11: "",
            dt3: "",
            dt1: "Muskelkrampf",
            sy9: "",
            sy6: "",
            id: "3133",
            lt10: "qafso d`wariđo",
            sy5: "ܩܰܦܣܳܐ ܕܘܰܪܺܝܕܳܐ",
            sy4: "ܩܦܣܐ ܕܘܪܝܕܐ",
            sy7: "",
            lt12: "",
            dt2: "",
            sy8: ""
        },
        {
            sy6: "",
            dt3: "",
            lt12: "",
            lt11: "",
            sy8: "",
            lt10: "eshmi",
            dt1: "Mein Name ist",
            sy4: "ܐܫܡܝ",
            dt2: "",
            sy7: "",
            id: "6391",
            sy5: "ܐܶܫܡܺܝ",
            sy9: ""
        },
        {
            sy4: "ܒܪܒܪܐ",
            lt12: "",
            dt3: "",
            sy5: "ܒܰܪܒܰܪܰܐ",
            sy8: "",
            lt11: "ܒܪܒܪܐ",
            lt10: "ܒܪܒܪܐ",
            sy6: "",
            id: "444",
            sy7: "",
            dt1: "Barbara",
            sy9: "",
            dt2: ""
        },
        {
            dt3: "",
            sy7: "",
            sy4: "ܬܫܥܝܬܢܐܝܬ",
            dt1: "erzählend",
            dt2: "erzählerisch",
            sy9: "",
            lt10: "tashĉithonoith",
            sy8: "",
            lt12: "",
            sy5: "ܬܰܫܥܺܝܬ݂ܳܢܳܐܺܝܬ",
            lt11: "",
            sy6: "",
            id: "1180"
        },
        {
            lt12: "",
            dt2: "Kanne",
            dt1: "Teekanne",
            sy8: "",
            id: "4418",
            sy5: "ܩܽܘܩܡܳܐ",
            lt11: "",
            sy9: "",
            sy6: "",
            dt3: "",
            sy4: "ܩܘܩܡܐ",
            lt10: "quqmo",
            sy7: ""
        },
        {
            sy8: "",
            lt12: "",
            sy6: "",
            dt1: "Zuweisung",
            lt11: "",
            lt10: "mtaĥmonutho",
            sy7: "",
            sy9: "",
            sy4: "ܡܬܚܡܢܘܬܐ",
            sy5: "ܡܬܰܚܡܳܢܽܘܬ݂ܳܐ",
            id: "6199",
            dt2: "",
            dt3: ""
        },
        {
            sy4: "ܪܡܝ",
            sy6: "",
            dt3: "",
            id: "3601",
            sy7: "",
            dt2: "",
            lt11: "ܪܡܝ",
            sy9: "",
            lt10: "ܪܡܝ",
            sy5: "ܪܰܡܺܝ",
            dt1: "Rami",
            lt12: "",
            sy8: ""
        },
        {
            lt11: "ܝܬܪܐ",
            dt1: "Yatro",
            sy9: "",
            lt12: "",
            sy4: "ܝܬܪܐ",
            lt10: "ܝܬܪܐ",
            sy7: "",
            sy5: "ܝܰܬܪܳܐ",
            sy6: "",
            dt2: "",
            dt3: "",
            sy8: "",
            id: "5203"
        },
        {
            sy9: "",
            lt10: "ܣܪܦ",
            dt1: "Seraph",
            lt12: "",
            sy4: "ܣܪܦ",
            id: "4126",
            dt2: "",
            sy7: "",
            lt11: "ܣܪܦ",
            dt3: "",
            sy5: "ܣܶܪܰܦ",
            sy6: "",
            sy8: ""
        },
        {
            lt12: "",
            dt3: "",
            id: "fpIzhDbmNR2yx2t6D7Hs",
            sy5: "ܐܰܝܕܰܪܒܳܐ ܗܰܬܽܘ",
            lt11: "",
            sy8: "",
            lt10: "Aydarbo hatu",
            sy7: "",
            sy6: "",
            "date": {
                "seconds": 1590848339,
                "nanoseconds": 505000000
            },
            sy4: "ܐܝܕܪܒܐ ܗܬܘ",
            dt2: "",
            dt1: "Wie geht es euch?",
            sy9: ""
        },
        {
            dt3: "",
            sy7: "",
            sy5: "ܒܠܶܠܝܳܐ ܘܒܳܐܝܡܳܡܳܐ",
            lt11: "",
            lt10: "b`lelyo u bimomo",
            lt12: "",
            id: "4362",
            sy8: "",
            sy9: "",
            dt2: "",
            dt1: "Tag und Nacht",
            sy6: "",
            sy4: "ܒܠܠܝܐ ܘܒܐܝܡܡܐ"
        },
        {
            sy4: "ܡܕܪܫܬܐ ܕܗܢܝܘܟܘܬܐ",
            lt10: "madrashto d`henyukhutho",
            lt11: "madrashto d`dubor rađoyto",
            dt1: "Fahrschule",
            sy6: "ܡܕܪܫܬܐ ܕܕܘܒܪ ܪܕܝܬܐ",
            sy9: "",
            dt2: "",
            dt3: "",
            lt12: "",
            sy7: "ܡܰܕܪܰܫܬܳܐ ܕܕܺܘܒܳܪ ܪܰܕܳܝܬܳܐ",
            sy8: "",
            sy5: "ܡܰܕܪܰܫܬܳܐ ܕܗܶܢܝܽܘܟ݂ܽܘܬ݂ܳܐ",
            id: "1238"
        },
        {
            sy8: "",
            dt3: "",
            sy4: "ܙܓܐ",
            sy9: "",
            sy5: "ܙܰܓܳܐ",
            sy6: "",
            id: "2528",
            lt12: "",
            dt2: "",
            sy7: "",
            lt11: "",
            dt1: "Klingel",
            lt10: "sago"
        },
        {
            sy4: "ܠܒܘܫܐ ܕܓܒܪܐ",
            dt2: "",
            dt1: "Männerkleidung",
            dt3: "",
            lt10: "lbushe d`gabre",
            lt11: "",
            sy7: "",
            lt12: "",
            sy9: "",
            id: "2920",
            sy6: "",
            sy8: "",
            sy5: "ܠܒܽܘ̈ܫܶܐ ܕܓܰܒܪܳܐ"
        },
        {
            lt12: "",
            sy5: "ܡܩܰܕܫܳܐ",
            sy7: "",
            sy4: "ܡܩܕܫܐ",
            id: "2307",
            sy9: "",
            dt2: "",
            sy6: "",
            lt10: "maqdsho",
            sy8: "",
            dt3: "",
            dt1: "Jerusalempilger",
            lt11: ""
        },
        {
            dt3: "",
            lt10: "sahro ĥatho",
            sy7: "",
            sy9: "",
            sy6: "",
            dt2: "",
            sy4: "ܣܗܪܐ ܚܕܬܐ",
            id: "3082",
            dt1: "Mondsichel",
            lt12: "",
            sy5: "ܣܰܗܪܳܐ ܚܰܕܬ݂ܳܐ",
            lt11: "",
            sy8: ""
        },
        {
            dt3: "",
            sy4: "ܒܗܡܡܘܬ",
            sy5: "ܒܶܗܡܡܽܘܬ",
            sy7: "ܣܽܘܣܬܳܐ ܢܰܗܪܳܝܬܳܐ",
            lt11: "susto nahroyto",
            lt12: "",
            id: "3279",
            sy6: "ܣܘܣܬܐ ܢܗܪܝܬܐ",
            lt10: "behmut",
            dt2: "",
            dt1: "Nilpferd",
            sy8: "",
            sy9: ""
        },
        {
            dt2: "Neunzig",
            dt1: "90",
            lt11: "ŝode",
            sy8: "",
            lt10: "teshĉin",
            id: "37",
            sy9: "",
            sy6: "ܨ",
            sy4: "ܬܫܥܝܢ",
            sy7: "ܨ",
            lt12: "",
            sy5: "ܬܶܫܥܺܝܢ ",
            dt3: ""
        },
        {
            sy8: "",
            sy7: "",
            sy6: "",
            dt3: "",
            sy4: "ܩܠܝܐ",
            sy9: "",
            id: "1803",
            dt2: "",
            lt10: "qalyo",
            lt12: "",
            sy5: "ܩܰܠܝܳܐ",
            dt1: "Hackfleisch",
            lt11: ""
        },
        {
            sy8: "",
            sy5: "ܒܳܥܽܘ ܗܰܘ ܠܺܝ ܒܰܪܬ݂ܳܟ ܬܽܘܝܳܐ ܠܺܝ ܐܢ̱ܰܬܬ݂ܳܐ",
            sy6: "ܒܒܥܘ ܗܒ ܠܝ ܒܪܬܟ ܕܬܗܘܝ ܠܝ ܒܪܬܙܘܓܐ",
            lt11: "boĉu hab li barthokh dtehwe li bathzaugo",
            dt3: "",
            dt2: "",
            sy7: "ܒܒܳܥܽܘ ܗܰܒ ܠܺܝ ܒܰܪܬ݂ܳܟ݂ ܕܬܶܗܘܝ ܠܺܝ ܒܪ̱ܰܬ݂ܙܰܘܓܳܐ",
            sy9: "",
            sy4: "ܒܥܘ ܗܘ ܠܝ ܒܪܬܟ ܬܘܝܐ ܠܝ ܐܢܬܬܐ",
            id: "635",
            lt12: "",
            dt1: "Bitte gib mir deine Tochter zur Frau",
            lt10: "boĉu hau li barthokh tuyo li attho"
        },
        {
            sy6: "ܢܘܚܬܐ",
            sy4: "ܢܦܝܠܘܬܐ",
            lt11: "nuĥoto",
            lt10: "nafilutho",
            sy7: "ܢܽܘܚܳܬܳܐ",
            id: "4120",
            lt12: "mafulto",
            dt2: "",
            dt3: "",
            sy9: "ܡܰܦܽܘܠܬܳܐ",
            dt1: "Senkung",
            sy5: "ܢܰܦܺܝܠܽܘܬ݂ܳܐ",
            sy8: "ܡܦܘܠܬܐ"
        },
        {
            sy6: "ܡܪܒܥܐܝܬ",
            id: "3565",
            lt12: "",
            lt11: "mrabĉoith",
            dt1: "quadratisch",
            dt2: "",
            lt10: "rbiĉo",
            sy4: "ܪܒܝܥܐ",
            sy9: "",
            sy8: "",
            dt3: "",
            sy5: "ܪܒܺܝܥܳܐ",
            sy7: "ܡܪܰܒܥܳܐܝܺܬ݂"
        },
        {
            lt10: "ܣܘܪܝܐ",
            sy7: "",
            lt11: "ܣܘܪܝܐ",
            dt3: "",
            sy6: "",
            sy5: "ܣܽܘܪܺܝܰܐ",
            dt1: "Suriya",
            lt12: "",
            id: "4320",
            sy9: "",
            dt2: "",
            sy4: "ܣܘܪܝܐ",
            sy8: ""
        },
        {
            lt11: "",
            dt3: "",
            sy7: "",
            sy5: "ܣܰܓܝܳܐܢܳܝܽܘܬ݂ܳܐ",
            sy8: "",
            dt2: "",
            lt10: "sagiyonoyutho",
            dt1: "Pluralismus",
            sy6: "",
            id: "3478",
            sy4: "ܣܓܝܐܢܝܘܬܐ",
            sy9: "",
            lt12: ""
        },
        {
            sy5: "ܢܺܝܢܘܰܐ",
            dt1: "Ninva",
            sy4: "ܢܝܢܘܐ",
            lt10: "ܢܝܢܘܐ",
            dt2: "Ninwa",
            sy8: "",
            id: "3283",
            lt11: "ܢܝܢܘܐ",
            sy7: "ܢܺܝܢܘܶܐ",
            dt3: "Ninve",
            sy9: "",
            lt12: "ܢܝܢܘܐ",
            sy6: "ܢܝܢܘܐ"
        },
        {
            lt12: "",
            sy9: "",
            lt11: "",
            lt10: "ĉsar ĉefin",
            id: "6265",
            sy4: "ܥܣܪ ܥܐܦܝܢ",
            sy8: "",
            dt2: "",
            sy6: "",
            dt1: "zehnfach",
            sy7: "",
            sy5: "ܥܣܰܪ ܥܶܐܦܺܝ̈ܢ",
            dt3: ""
        },
        {
            dt3: "",
            sy7: "",
            id: "250",
            lt12: "",
            dt2: "",
            sy5: "ܐܰܢܛܝܽܘܟ݂ܝܰܐ",
            sy6: "",
            sy8: "",
            dt1: "Antiochien",
            sy4: "ܐܢܛܝܘܟܝܐ",
            lt10: "anțyukhya",
            lt11: "",
            sy9: ""
        },
        {
            sy9: "",
            sy7: "ܟ݂ܰܠܝܳܐ",
            id: "2738",
            dt1: "leer",
            lt12: "",
            sy5: "ܣܦܺܝܩܳܐ",
            lt11: "khalyo",
            sy8: "",
            sy6: "ܟܠܝܐ",
            sy4: "ܣܦܝܩܐ",
            dt3: "",
            lt10: "sfiqo",
            dt2: "unbesetzt"
        },
        {
            dt3: "",
            sy6: "",
            lt10: "ܣܪܓܢ",
            sy8: "",
            sy5: "ܣܰܪܓܳܢ",
            lt12: "",
            id: "3846",
            lt11: "ܣܪܓܢ",
            dt1: "Sargon",
            sy9: "",
            dt2: "",
            sy7: "",
            sy4: "ܣܪܓܢ"
        },
        {
            sy8: "",
            sy4: "ܩܪܡܒܐ",
            dt2: "Weißkraut",
            sy9: "",
            id: "5037",
            lt12: "",
            sy6: "",
            dt3: "",
            sy5: "ܩܰܪܡܒܳܐ",
            dt1: "Weißkohl",
            lt11: "",
            lt10: "qarmbo",
            sy7: ""
        },
        {
            sy6: "",
            dt3: "",
            sy8: "",
            lt10: "brikh țahro",
            dt1: "Guten Mittag",
            id: "1773",
            dt2: "",
            sy7: "",
            sy4: "ܒܪܝܟ ܛܗܪܐ",
            lt11: "",
            sy5: "ܒܪܺܝܟ݂ ܛܰܗܪܳܐ",
            lt12: "",
            sy9: ""
        },
        {
            dt2: "anders klären",
            sy6: "",
            lt10: "țakes bazno ĥrino",
            dt1: "anders regeln",
            id: "5937",
            sy5: "ܛܰܟܶܣ ܒܰܙܢܳܐ ܐ̱ܚܪܺܝܢܳܐ",
            sy7: "",
            lt11: "",
            sy4: "ܛܟܣ ܒܙܢܐ ܐܚܪܝܢܐ",
            sy9: "",
            dt3: "",
            lt12: "",
            sy8: ""
        },
        {
            sy5: "ܪܶܓܬܰܐ",
            sy7: "",
            dt2: "",
            sy6: "",
            sy8: "",
            lt11: "ܪܓܬܐ",
            lt12: "",
            sy4: "ܪܓܬܐ",
            dt3: "",
            dt1: "Regta",
            id: "3663",
            sy9: "",
            lt10: "ܪܓܬܐ"
        },
        {
            sy4: "ܢܡܘܣܐ ܕܐܝܬܝܩܘܢ",
            dt2: "",
            sy6: "",
            dt3: "",
            sy7: "",
            sy5: "ܢܳܡܽܘܣܳܐ ܐܺܝܬ݂ܺܝܩܽܘܢ",
            sy8: "",
            lt10: "nomuso d`ithiqun",
            dt1: "Moralrecht",
            lt12: "",
            sy9: "",
            id: "3103",
            lt11: ""
        },
        {
            sy6: "",
            sy7: "",
            id: "439",
            sy9: "",
            dt3: "",
            sy4: "ܒܪܥܐܕܐ",
            sy5: "ܒܰܪܥܺܐܕܳܐ",
            dt2: "",
            lt10: "ܒܪܥܐܕܐ",
            sy8: "",
            dt1: "Bar Ido",
            lt12: "",
            lt11: "ܒܪܥܐܕܐ"
        },
        {
            dt2: "",
            lt11: "",
            sy8: "",
            sy4: "ܩܪܨܢܐ",
            dt1: "Schneeregen",
            lt10: "qarŝono",
            id: "3995",
            dt3: "",
            sy6: "",
            lt12: "",
            sy5: "ܩܰܪܨܳܢܳܐ",
            sy9: "",
            sy7: ""
        },
        {
            dt2: "rasch",
            sy5: "ܣܽܘܪܗܳܒܳܐ",
            dt1: "schnell",
            sy8: "ܟܐܝܦܐ",
            lt12: "khayfo",
            lt11: "bsarhbo",
            sy9: "ܟ݂ܰܐܝܦܳܐ",
            dt3: "",
            id: "4002",
            sy6: "ܒܣܪܗܒܐ",
            lt10: "surhobo",
            sy4: "ܣܘܪܗܒܐ",
            sy7: "ܒܣܰܪܗܒܳܐ"
        },
        {
            sy4: "ܦܪܨܘܦܐܝܬ",
            dt1: "persönlich",
            lt11: "",
            dt2: "",
            sy7: "",
            sy6: "",
            id: "3439",
            dt3: "",
            sy8: "",
            lt12: "",
            sy5: "ܦܰܪܨܽܘܦܳܐܝܺܬ݂",
            sy9: "",
            lt10: "farŝufoith"
        },
        {
            sy7: "",
            lt11: "",
            sy4: "ܩܚܦܐ",
            id: "4523",
            sy9: "",
            sy6: "",
            sy5: "ܩܰܚܦܳܐ",
            dt1: "Topf",
            dt3: "",
            lt12: "",
            sy8: "",
            dt2: "",
            lt10: "qaĥfo"
        },
        {
            lt10: "debo da`gliđo",
            lt11: "",
            sy6: "",
            dt3: "",
            dt2: "",
            dt1: "Eisbär",
            sy9: "",
            id: "6409",
            lt12: "",
            sy4: "ܕܒܐ ܕܓܠܝܕܐ",
            sy7: "",
            sy8: "",
            sy5: "ܕܶܒܳܐ ܕܰܓܠܺܝܕܳܐ"
        },
        {
            sy5: "ܓܳܫܽܘܫܳܐ",
            lt11: "",
            sy7: "",
            dt1: "Spion",
            dt2: "",
            sy6: "",
            sy4: "ܓܫܘܫܐ",
            lt10: "goshusho",
            id: "4219",
            lt12: "",
            sy8: "",
            dt3: "",
            sy9: ""
        },
        {
            id: "2100",
            sy9: "",
            lt11: "",
            sy6: "",
            lt12: "",
            sy5: "ܐܶܢܳܐ ܐܺܝܬ݂ܝ ܛܰܠܝܳܐ ܕܰܐܠܳܗܳܐ",
            dt1: "Ich bin ein Kind Gottes",
            dt2: "",
            dt3: "",
            sy4: "ܐܢܐ ܐܝܬܝ ܛܠܝܐ ܕܐܠܗܐ",
            sy8: "",
            sy7: "",
            lt10: "eno ithay țalyo d'aloho"
        },
        {
            sy7: "",
            id: "1126",
            sy6: "",
            lt10: "yitheb men yamino d`abuy",
            sy8: "",
            dt3: "",
            sy5: "ܝܺܬ݂ܶܒ ܡܶܢ ܝܰܡܺܝܢܳܐ ܕܰܐܒܽܘܗܝ",
            lt11: "",
            lt12: "",
            dt1: "Er sitzt zur Rechten des Vaters",
            dt2: "",
            sy9: "",
            sy4: "ܝܬܒ ܡܢ ܝܡܝܢܐ ܕܐܒܘܗܝ"
        },
        {
            sy5: "ܫܠܳܡܳܐ ܠܟܽܘܠܟ݂ܽܘܢ",
            dt1: "Ich grüße euch alle",
            lt12: "",
            lt11: "",
            dt3: "",
            sy4: "ܫܠܡܐ ܠܟܘܠܟܘܢ",
            sy6: "",
            sy7: "",
            id: "2110",
            sy8: "",
            sy9: "",
            lt10: "shlomo l`kulkhun",
            dt2: "Seid gegrüßt"
        },
        {
            dt3: "",
            sy9: "",
            sy4: "ܦܘܠܝܛܝܩܝ",
            dt1: "Politik",
            lt10: "fulițiqi",
            id: "3481",
            sy6: "",
            sy5: "ܦܽܘܠܺܝܛܺܝܩܺܝ",
            lt12: "",
            dt2: "",
            lt11: "",
            sy8: "",
            sy7: ""
        },
        {
            lt10: "ĉesro fuqdone",
            id: "5274",
            lt12: "",
            sy4: "ܥܣܪܐ ܦܘܩܕܢܐ",
            sy8: "",
            sy7: "",
            dt2: "10 Gebote",
            lt11: "",
            dt1: "Zehn Gebote",
            dt3: "",
            sy6: "",
            sy5: "ܥܶܣܪܳܐ ܦܽܘ̈ܩܕܳܢܶܐ",
            sy9: ""
        },
        {
            sy7: "",
            lt11: "ܒܗܪܐ",
            lt10: "ܒܗܪܐ",
            sy4: "ܒܗܪܐ",
            id: "423",
            dt3: "",
            dt1: "Bahro",
            sy8: "",
            sy9: "",
            lt12: "",
            sy5: "ܒܰܗܪܳܐ",
            dt2: "",
            sy6: ""
        },
        {
            id: "4353",
            dt1: "Tabita",
            dt2: "",
            sy6: "",
            sy4: "ܛܒܝܬܐ",
            dt3: "",
            sy5: "ܛܰܒܺܝܬܰܐ",
            lt12: "",
            lt11: "ܛܒܝܬܐ",
            sy8: "",
            lt10: "ܛܒܝܬܐ",
            sy7: "",
            sy9: ""
        },
        {
            sy6: "",
            dt2: "Abdelmassieh",
            sy5: "ܥܰܒܕܶܠܡܰܣܺܝܚ",
            sy4: "ܥܒܕܠܡܣܝܚ",
            id: "67",
            lt10: "ܥܒܕܠܡܣܝܚ",
            lt11: "ܥܒܕܠܡܣܝܚ",
            sy7: "",
            sy8: "",
            dt1: "Abdelmassih",
            sy9: "",
            dt3: "",
            lt12: ""
        },
        {
            lt10: "lo ĥiro",
            dt3: "",
            sy5: "ܠܳܐ ܚܺܝܪܳܐ",
            id: "5566",
            sy4: "ܠܐ ܚܝܪܐ",
            lt11: "kbilo",
            dt1: "abhängig",
            sy7: "ܟܒܺܝܠܳܐ",
            dt2: "",
            sy9: "ܟܕܺܝܢܳܐ",
            lt12: "kdino",
            sy6: "ܟܒܝܠܐ",
            sy8: "ܟܕܝܢܐ"
        },
        {
            sy6: "",
            sy4: "ܒܘܠܒܠܐ",
            lt10: "bulbolo",
            sy8: "",
            dt1: "Chaos",
            dt3: "",
            sy5: "ܒܽܘܠܒܳܠܳܐ",
            id: "762",
            sy7: "",
            dt2: "Wirrwarr",
            lt12: "",
            sy9: "",
            lt11: ""
        },
        {
            sy9: "ܛܰܘܒܳܐ",
            lt10: "ĥulofo",
            sy4: "ܚܘܠܦܐ",
            sy7: "ܡܟܳܢܳܐ ܐ̱ܚܪܺܝܢܳܐ",
            sy6: "ܡܟܢܐ ܚܪܝܢܐ",
            id: "5860",
            lt11: "mkono ĥrino",
            dt1: "Alternative",
            dt3: "",
            lt12: "țaubo",
            sy5: "ܚܽܘܠܳܦܳܐ",
            dt2: "",
            sy8: "ܛܘܒܐ"
        },
        {
            lt11: "",
            dt1: "Rückenschwimmen",
            sy5: "ܣܰܚܘܳܐ ܕܥܰܠ ܚܰܨܳܐ",
            sy4: "ܣܚܘܐ ܕܥܠ ܚܨܐ",
            id: "3757",
            dt2: "",
            lt10: "saĥwo d`ĉal ĥaŝo",
            sy9: "",
            lt12: "",
            sy6: "",
            sy7: "",
            sy8: "",
            dt3: ""
        },
        {
            sy5: "ܡܰܪܒܺܝܬܰܐ",
            dt2: "",
            sy9: "",
            dt1: "Marbita",
            lt12: "",
            sy8: "",
            id: "2927",
            lt10: "ܡܪܒܝܬܐ",
            sy4: "ܡܪܒܝܬܐ",
            sy6: "",
            sy7: "",
            lt11: "ܡܪܒܝܬܐ",
            dt3: ""
        },
        {
            sy5: "ܬܰܦܪܺܝܬ݂ܳܐ",
            lt12: "",
            sy6: "ܫܘܦܥܐ",
            lt11: "shufoĉo",
            sy9: "",
            sy7: "ܫܽܘܦܳܥܳܐ",
            dt1: "Einzahlung",
            dt3: "",
            dt2: "",
            id: "6403",
            lt10: "tafritho",
            sy8: "",
            sy4: "ܬܦܪܝܬܐ"
        },
        {
            sy4: "ܘܪܩܐ ܕܡܘܕܥܢܘܬܐ",
            lt12: "",
            sy9: "",
            lt11: "",
            id: "3257",
            sy8: "",
            lt10: "warqo d`mauđĉonutho",
            dt2: "Informationsblatt",
            sy5: "ܘܰܪܩܳܐ ܕܡܰܘܕܥܳܢܽܘܬ݂ܳܐ",
            sy7: "",
            dt3: "Flyer",
            dt1: "Newsletter",
            sy6: ""
        },
        {
            dt1: "Emilia",
            lt10: "ܐܡܝܠܝܐ",
            lt11: "ܐܡܝܠܝܐ",
            id: "1096",
            sy9: "",
            sy7: "",
            lt12: "",
            sy6: "",
            dt2: "Emilya",
            sy5: "ܐܶܡܺܝܠܝܰܐ",
            dt3: "Emilja",
            sy4: "ܐܡܝܠܝܐ",
            sy8: ""
        },
        {
            dt1: "Handy",
            lt12: "",
            dt2: "Mobiltelefon",
            sy7: "",
            id: "5424",
            sy5: "ܬܶܠ ܐܺܝܕܳܐ",
            sy6: "",
            sy9: "",
            lt10: "Tel iđo",
            dt3: "Smartphone",
            lt11: "",
            sy8: "",
            sy4: "ܬܠ ܐܝܕܐ"
        },
        {
            sy4: "ܩܘܦܕܐ",
            id: "2152",
            lt10: "qufdo",
            sy9: "",
            lt12: "",
            dt1: "Igel",
            sy8: "",
            sy7: "",
            sy6: "",
            dt3: "",
            lt11: "",
            dt2: "",
            sy5: "ܩܽܘܦܕܳܐ"
        },
        {
            lt12: "",
            sy6: "",
            lt10: "yabruĥo",
            dt1: "Tomate",
            dt2: "",
            sy7: "",
            dt3: "",
            sy5: "ܝܰܒܪܽܘܚܳܐ",
            sy8: "",
            id: "4520",
            sy4: "ܝܒܪܘܚܐ",
            lt11: "",
            sy9: ""
        },
        {
            lt11: "",
            sy5: "ܫܦܳܢܳܐ",
            sy8: "",
            sy6: "",
            lt12: "",
            dt2: "",
            dt1: "Tilgung",
            sy7: "",
            sy9: "",
            id: "4490",
            sy4: "ܫܦܢܐ",
            dt3: "",
            lt10: "shfono"
        },
        {
            lt11: "ܝܗܒܐܠܗܐ",
            sy4: "ܝܗܒܐܠܗܐ",
            dt3: "",
            lt10: "ܝܗܒܐܠܗܐ",
            lt12: "",
            sy9: "",
            dt2: "",
            sy7: "",
            id: "5187",
            dt1: "Yahbalaha",
            sy8: "",
            sy6: "",
            sy5: "ܝܰܗܒܰܐܠܰܗܰܐ"
        },
        {
            dt1: "unbezahlbar",
            lt10: "lo methfarĉono",
            lt11: "",
            sy8: "",
            sy5: "ܠܳܐ ܡܶܬ݂ܦܰܪܥܳܢܳܐ",
            sy7: "",
            lt12: "",
            sy4: "ܠܐ ܡܬܦܪܥܢܐ",
            sy9: "",
            sy6: "",
            dt2: "insolvent",
            dt3: "",
            id: "4640"
        },
        {
            dt3: "",
            sy9: "",
            id: "280",
            lt12: "",
            lt11: "",
            sy7: "",
            dt1: "Arbeitshose",
            dt2: "",
            lt10: "banțlo d`fulĥono",
            sy6: "",
            sy8: "",
            sy4: "ܒܢܬܠܐ ܕܦܘܠܚܢܐ",
            sy5: "ܒܰܢܛܠܳܐ ܕܦܽܘܠܚܳܢܳܐ"
        },
        {
            sy8: "",
            dt1: "Nabo",
            sy6: "ܢܒܘ",
            id: "3146",
            lt12: "ܢܒܘ",
            sy4: "ܢܒܐ",
            dt2: "",
            lt11: "ܢܒܐ",
            sy5: "ܢܰܒܳܐ",
            dt3: "",
            sy9: "",
            lt10: "ܢܒܐ",
            sy7: "ܢܰܒܽܘ"
        },
        {
            dt1: "Fußballmannschaft",
            lt11: "",
            lt10: "gudo d`esfir reğlo",
            dt3: "",
            sy5: "ܓܽܘܕܳܐ ܕܐܶܣܦܺܝܪ ܪܶܓ݂ܠܳܐ",
            sy7: "",
            sy8: "",
            dt2: "Fussballmannschaft",
            sy9: "",
            sy4: "ܓܘܕܐ ܕܐܣܦܝܪ ܪܓܠܐ",
            id: "1438",
            lt12: "",
            sy6: ""
        },
        {
            sy7: "ܬܺܚܶܐ",
            dt3: "hurra",
            sy8: "",
            lt10: "tiĥe",
            lt12: "",
            dt2: "es lebe",
            sy9: "",
            sy5: "ܬܺܐܚܶܐ",
            sy6: "ܬܚܐ",
            dt1: "hoch lebe",
            id: "2030",
            sy4: "ܬܐܚܐ",
            lt11: "tiĥe"
        },
        {
            id: "5256",
            sy9: "",
            sy7: "",
            lt10: "kthobo d`ĥarosho",
            sy4: "ܟܬܒܐ ܕܚܪܫܐ",
            sy8: "",
            sy6: "",
            sy5: "ܟܬ݂ܳܒܳܐ ܕܚܰܪܳܫܳܐ",
            dt3: "",
            dt2: "",
            lt11: "",
            lt12: "",
            dt1: "Zauberbuch"
        },
        {
            dt1: "Absamja",
            sy9: "",
            lt12: "",
            dt3: "",
            id: "112",
            sy5: "ܐܰܒܣܰܡܝܰܐ",
            lt10: "ܐܒܣܡܝܐ",
            sy4: "ܐܒܣܡܝܐ",
            sy8: "",
            sy6: "",
            dt2: "",
            lt11: "ܐܒܣܡܝܐ",
            sy7: ""
        },
        {
            lt12: "",
            sy8: "",
            sy4: "ܐܒܪܗܛ",
            dt3: "",
            sy9: "",
            sy7: "",
            lt10: "ܐܒܪܗܛ",
            lt11: "ܐܒܪܗܛ",
            dt2: "",
            dt1: "Abrahat",
            sy5: "ܐܰܒܪܰܗܰܛ",
            id: "108",
            sy6: ""
        },
        {
            sy4: "ܥܙܪܐ",
            lt11: "ܥܙܪܐ",
            sy8: "",
            lt12: "ܐܙܪܐ",
            sy9: "",
            id: "1193",
            dt1: "Esra",
            sy7: "ܐܰܙܪܰܐ",
            dt3: "Asra",
            sy5: "ܥܶܙܪܰܐ",
            sy6: "ܐܙܪܐ",
            lt10: "ܥܙܪܐ",
            dt2: "Esro"
        },
        {
            sy8: "",
            lt10: "fusqono",
            lt11: "",
            sy4: "ܦܘܣܩܢܐ",
            lt12: "",
            dt3: "",
            dt2: "Entscheidung",
            dt1: "Beschluss",
            sy5: "ܦܽܘܣܩܳܢܳܐ",
            sy9: "",
            sy7: "",
            id: "568",
            sy6: ""
        },
        {
            sy7: "",
            sy9: "",
            sy8: "",
            dt2: "",
            lt11: "",
            lt12: "",
            lt10: "armlo",
            sy4: "ܐܪܡܠܐ",
            dt3: "",
            id: "5140",
            sy6: "",
            dt1: "Witwer",
            sy5: "ܐܰܪܡܠܳܐ"
        },
        {
            dt2: "",
            lt10: "ĉtidutho",
            sy8: "",
            dt3: "",
            dt1: "Zukunft",
            sy5: "ܥܬܺܝܕܽܘܬ݂ܳܐ",
            lt12: "",
            lt11: "zabno daĉtiđ",
            id: "5366",
            sy6: "ܙܒܢܐ ܕܥܬܝܕ",
            sy9: "",
            sy4: "ܥܬܝܕܘܬܐ",
            sy7: "ܙܰܒܢܳܐ ܕܰܥܬܺܝܕ"
        },
        {
            sy8: "",
            lt12: "",
            dt2: "",
            dt3: "",
            lt10: "sayfo",
            sy7: "ܣܰܦܣܺܝܪܳܐ",
            id: "4077",
            dt1: "Schwert",
            sy9: "",
            sy6: "ܣܦܣܝܪܐ",
            lt11: "safsiro",
            sy5: "ܣܰܝܦܳܐ",
            sy4: "ܣܝܦܐ"
        },
        {
            lt10: "aloho",
            sy8: "ܡܪܐ ܕܟܠ",
            dt1: "Allmächtiger",
            id: "191",
            lt11: "il",
            dt2: "Gott",
            sy9: "ܡܳܪܳܐ ܕܟܽܠ",
            sy6: "ܐܝܠ",
            lt12: "moro d`kul",
            sy4: "ܐܠܗܐ",
            sy7: "ܐܺܝܠ",
            sy5: "ܐܰܠܳܗܳܐ",
            dt3: ""
        },
        {
            id: "4079",
            lt10: "ĥemyono w`aĥmotho",
            sy9: "",
            sy7: "",
            lt12: "",
            sy4: "ܚܡܝܢܐ ܘܚܡܬܐ",
            dt3: "",
            sy5: "ܚܶܡܝܳܢܳܐ ܘܰܚܡܳܬ݂ܳܐ",
            lt11: "",
            sy6: "",
            dt1: "Schwiegereltern",
            dt2: "",
            sy8: ""
        },
        {
            dt3: "",
            sy7: "ܝܰܩܕܳܐ",
            sy9: "ܢܽܘܪܳܐ",
            id: "688",
            dt2: "Feuer",
            sy4: "ܝܩܕܢܐ",
            lt12: "nuro",
            sy5: "ܝܰܩܕܳܢܳܐ",
            sy6: "ܝܩܕܐ",
            lt11: "yaqdo",
            lt10: "yaqdono",
            sy8: "ܢܘܪܐ",
            dt1: "Brand"
        },
        {
            lt12: "",
            sy5: "ܡܶܣܥܽܘܕ",
            lt11: "ܡܣܥܘܕ",
            dt1: "Mashud",
            id: "2957",
            sy9: "",
            sy7: "",
            sy4: "ܡܣܥܘܕ",
            lt10: "ܡܣܥܘܕ",
            dt3: "",
            sy6: "",
            dt2: "Mesut",
            sy8: ""
        },
        {
            lt10: "nqosh kafo",
            lt11: "",
            sy9: "",
            sy6: "",
            id: "5800",
            dt3: "",
            dt2: "",
            dt1: "Akklamation",
            lt12: "",
            sy5: "ܢܩܳܫ ܟܰܦܳܐ",
            sy8: "",
            sy4: "ܢܩܫ ܟܦܐ",
            sy7: ""
        },
        {
            sy7: "",
            sy6: "",
            dt1: "Hübsche Frau",
            sy8: "",
            dt2: "",
            lt12: "",
            sy4: "ܐܢܬܬܐ ܫܦܝܪܬܐ",
            sy9: "",
            sy5: "ܐܰܢ̱ܬܬ݂ܳܐ ܫܰܦܺܝܪܬܳܐ",
            dt3: "",
            lt11: "",
            id: "5460",
            lt10: "at-tho shafirto"
        },
        {
            sy8: "",
            sy5: "ܫܰܡܠܺܝ",
            id: "3895",
            sy9: "",
            sy7: "",
            dt3: "",
            sy4: "ܫܡܠܝ",
            sy6: "",
            dt2: "",
            dt1: "Schamli",
            lt11: "ܫܡܠܝ",
            lt10: "ܫܡܠܝ",
            lt12: ""
        },
        {
            sy6: "ܪܝܫܐ",
            lt12: "rishoyutho",
            dt1: "Anfang",
            lt10: "shuroyo",
            sy7: "ܪܺܝܫܳܐ",
            sy5: "ܫܘܽܪܳܝܳܐ",
            sy4: "ܫܘܪܝܐ",
            dt2: "Start",
            sy9: "ܪܺܝܫܳܝܽܘܬ݂ܳܐ",
            id: "230",
            lt11: "risho",
            dt3: "Auftakt",
            sy8: "ܪܝܫܝܬܐ"
        },
        {
            sy7: "",
            sy6: "",
            id: "93",
            dt2: "",
            lt12: "",
            sy5: "ܐܰܒܺܝܠܳܐ",
            sy4: "ܐܒܝܠܐ",
            dt1: "Abilo",
            sy8: "",
            sy9: "",
            dt3: "",
            lt11: "ܐܒܝܠܐ",
            lt10: "ܐܒܝܠܐ"
        },
        {
            sy4: "ܒܰܢܳܝܽܘܬ݂ܳܐ",
            id: "5484",
            lt11: "",
            dt1: "Baukunde",
            lt10: "banoyutho",
            dt2: "",
            sy7: "",
            sy9: "",
            sy8: "",
            dt3: "",
            sy6: "",
            lt12: "",
            sy5: "ܒܰܢܳܝܽܘܬ݂ܳܐ"
        },
        {
            sy5: "ܠܺܝܠܝܰܐ",
            dt2: "",
            sy6: "",
            lt11: "ܠܝܠܝܐ",
            sy9: "",
            sy7: "",
            sy8: "",
            sy4: "ܠܝܠܝܐ",
            lt10: "ܠܝܠܝܐ",
            id: "2833",
            dt3: "",
            dt1: "Lilya",
            lt12: ""
        },
        {
            dt3: "",
            lt12: "",
            sy9: "",
            sy7: "ܫܰܝܢܳܐ ܕܥܳܠܡܳܐ",
            id: "5051",
            sy8: "",
            sy6: "ܫܝܢܐ ܕܥܠܡܐ",
            sy5: "ܫܠܳܡܳܐ ܕܥܳܠܡܳܐ",
            lt11: "shayno d`ĉolmo",
            dt1: "Weltfrieden",
            lt10: "shlomo d`ĉolmo",
            sy4: "ܫܠܡܐ ܕܥܠܡܐ",
            dt2: ""
        },
        {
            sy8: "",
            lt12: "",
            dt3: "",
            sy5: "ܡܰܘܪܶܒ ܩܳܠܳܐ",
            id: "3023",
            sy7: "",
            sy6: "",
            lt10: "maureb qolo",
            dt1: "Mikrophon",
            sy9: "",
            lt11: "",
            dt2: "Mikrofon",
            sy4: "ܡܘܪܒ ܩܠܐ"
        },
        {
            id: "geoXqplHy1QeO4oR55qr",
            dt1: "Ist sie hübsch?",
            sy7: "",
            lt11: "",
            dt3: "",
            sy9: "",
            lt12: "",
            dt2: "",
            sy4: "ܫܦܝܪܬܐ ܝܐ",
            lt10: "shafirto yo?",
            sy5: "ܫܰܦܺܝܪܬܳܐ ܝܳܐ",
            "date": {
                "seconds": 1590848761,
                "nanoseconds": 672000000
            },
            sy6: "",
            sy8: ""
        },
        {
            id: "3180",
            lt11: "",
            lt10: "qaribo",
            sy9: "",
            sy5: "ܩܰܐܪܺܝܒܳܐ",
            lt12: "",
            sy4: "ܩܐܪܝܒܐ",
            dt1: "nah",
            sy8: "",
            dt3: "",
            sy7: "",
            dt2: "",
            sy6: ""
        },
        {
            dt3: "",
            lt10: "burkthonoyo",
            sy8: "",
            id: "4092",
            dt2: "",
            lt12: "",
            sy9: "",
            sy4: "ܒܘܪܟܬܢܝܐ",
            sy7: "",
            lt11: "",
            dt1: "segensreich",
            sy6: "",
            sy5: "ܒܽܘܪܟܬ݂ܳܢܳܝܳܐ"
        },
        {
            lt11: "",
            sy8: "",
            dt1: "ohne runterzufallen",
            lt10: "lo nfolo",
            sy5: "ܠܳܐ ܢܦܳܠܳܐ",
            sy9: "",
            dt2: "",
            id: "3357",
            dt3: "",
            sy7: "",
            sy4: "ܠܐ ܢܦܠܐ",
            sy6: "",
            lt12: ""
        },
        {
            sy6: "",
            dt3: "",
            sy4: "ܠܒܫܐ ܕܠܒܘܫܐ",
            sy5: "ܠܒܳܫܳܐ ܕܰܠܒܽܘ̈ܫܶܐ",
            dt2: "",
            sy9: "",
            lt10: "lbosho da`lbushe",
            sy8: "",
            dt1: "Anprobe",
            lt11: "",
            id: "5984",
            lt12: "",
            sy7: ""
        },
        {
            sy4: "ܟܐܦܐ ܕܟܘܠܝܬܐ",
            sy8: "",
            dt2: "",
            sy9: "",
            sy7: "",
            lt11: "",
            sy6: "",
            dt3: "",
            lt10: "kifo d`kulyotho",
            lt12: "",
            dt1: "Nierenstein",
            sy5: "ܟܺܐܦܳܐ ܕܟܽܘܠܺܝܬ݂ܳܐ",
            id: "3273"
        },
        {
            lt12: "",
            dt1: "richten",
            sy4: "ܕܢ",
            dt2: "urteilen",
            sy8: "",
            lt10: "don",
            lt11: "",
            dt3: "bewerten",
            id: "3714",
            sy6: "",
            sy7: "",
            sy9: "",
            sy5: "ܕܳܢ"
        },
        {
            lt10: "qufso",
            dt1: "Kiesel",
            sy6: "",
            sy4: "ܩܘܦܣܐ",
            sy7: "",
            dt3: "",
            sy5: "ܩܽܘܦܣܳܐ",
            lt11: "",
            sy9: "",
            lt12: "",
            id: "2464",
            dt2: "",
            sy8: ""
        },
        {
            lt10: "qaumoyo",
            sy7: "",
            sy4: "ܩܘܡܝܐ",
            id: "6001",
            sy8: "",
            dt3: "",
            sy6: "",
            dt2: "Anti",
            sy5: "ܩܰܘܡܳܝܳܐ",
            dt1: "anti",
            lt11: "",
            sy9: "",
            lt12: ""
        },
        {
            lt12: "",
            sy7: "",
            lt10: "shautho d`guboyo",
            sy8: "",
            sy9: "",
            dt2: "",
            sy5: "ܫܰܘܬ݂ܳܐ ܕܓܽܘܒܳܝܳܐ",
            sy6: "",
            sy4: "ܫܘܬܐ ܕܓܘܒܝܐ",
            id: "4941",
            lt11: "",
            dt1: "Wahlkreis",
            dt3: ""
        },
        {
            dt1: "Farida",
            id: "1258",
            lt12: "",
            dt2: "",
            sy8: "",
            lt11: "ܦܪܝܕܐ",
            dt3: "",
            sy4: "ܦܪܝܕܐ",
            sy7: "",
            lt10: "ܦܪܝܕܐ",
            sy5: "ܦܰܪܺܝܕܰܐ",
            sy9: "",
            sy6: ""
        },
        {
            lt11: "ܫܘܡܪܝܢ",
            sy9: "",
            sy7: "",
            sy6: "",
            dt2: "",
            lt10: "ܫܘܡܪܝܢ",
            id: "4054",
            dt3: "",
            sy8: "",
            lt12: "",
            dt1: "Schumrin",
            sy4: "ܫܘܡܪܝܢ",
            sy5: "ܫܽܘܡܪܺܝܢ"
        },
        {
            sy4: "ܪܒܢ",
            lt10: "ܪܒܢ",
            lt11: "ܪܒܢ",
            sy9: "",
            sy5: "ܪܰܒܰܢ",
            lt12: "",
            dt3: "",
            id: "3574",
            sy8: "",
            sy6: "",
            dt2: "",
            dt1: "Raban",
            sy7: ""
        },
        {
            dt2: "Kontrolleur",
            sy7: "ܒܳܕܽܘܩܳܐ",
            sy5: "ܡܒܰܩܪܳܢܳܐ",
            dt3: "",
            sy4: "ܡܒܩܪܢܐ",
            sy9: "",
            dt1: "Inspekteur",
            lt11: "boduqo",
            sy6: "ܒܕܘܩܐ",
            lt10: "mbaqrono",
            sy8: "",
            id: "2212",
            lt12: ""
        },
        {
            lt10: "ĉesrin wa`tlotho",
            dt2: "Dreiundzwanzig",
            sy9: "ܟܓ",
            dt1: "23",
            sy6: "ܥܣܪܥܢ ܘܬܠܬ",
            sy8: "ܟܓ",
            sy7: "ܥܶܣܪܺܝܢ ܘܰܬܠܳܬ݂",
            sy4: "ܥܣܪܝܢ ܘܬܠܬܐ",
            lt11: "ĉesrin wa`tloth",
            id: "24",
            dt3: "",
            sy5: "ܥܶܣܪܺܝܢ ܘܬܠܳܬ݂ܳܐ",
            lt12: "kof-gomal"
        },
        {
            dt3: "",
            sy7: "ܨܳܦܽܘܚܳܐ",
            lt10: "saĉoyo",
            id: "5780",
            sy6: "ܨܦܘܚܐ",
            lt11: "ŝofuĥo",
            sy9: "",
            sy5: "ܣܰܥܳܝܳܐ",
            lt12: "",
            dt1: "Aggressor",
            dt2: "",
            sy8: "",
            sy4: "ܣܥܝܐ"
        },
        {
            dt1: "Humanismus",
            lt10: "noshoyutho",
            sy5: "ܐ̱ܢܳܫܳܝܽܘܬ݂ܳܐ",
            sy6: "",
            sy7: "",
            sy4: "ܐܢܫܝܘܬܐ",
            dt2: "",
            id: "6023",
            lt12: "",
            dt3: "",
            lt11: "",
            sy9: "",
            sy8: ""
        },
        {
            id: "5493",
            dt3: "",
            lt11: "marzono",
            sy7: "ܡܰܐܪܙܳܢܳܐ",
            lt10: "mtarono",
            sy5: "ܡܬܰܐܪܳܢܳܐ",
            sy4: "ܡܬܐܪܢܐ",
            sy8: "ܡܬܠܡܕܢܐ",
            dt2: "",
            lt12: "methlamđono",
            sy6: "ܡܐܪܙܢܐ",
            sy9: "ܡܶܬ݂ܠܰܡܕܳܢܳܐ",
            dt1: "Dozent"
        },
        {
            sy6: "",
            sy7: "",
            dt3: "",
            sy4: "ܬܩܢܕܝܐ",
            lt11: "",
            sy8: "",
            sy9: "",
            dt2: "",
            id: "5862",
            dt1: "altersschwach",
            sy5: "ܬܰܩܢܕܳܝܳܐ",
            lt10: "taqndoyo",
            lt12: ""
        },
        {
            lt11: "",
            sy9: "",
            dt2: "",
            dt1: "Inder",
            sy7: "",
            lt10: "hendwoyo",
            lt12: "",
            sy5: "ܗܶܢܕܘܳܝܳܐ",
            sy6: "",
            id: "2195",
            sy4: "ܗܢܕܘܝܐ",
            sy8: "",
            dt3: ""
        },
        {
            lt11: "",
            sy6: "",
            dt2: "",
            sy4: "ܬܫܒܘܚܬܐ ܒܡܪܘܡܐ",
            sy5: "ܬܶܫܒܽܘܚܬܳܐ ܒܰܡܪܰܘܡܳܐ",
            dt3: "",
            sy7: "",
            dt1: "Ruhm in der Höhe",
            id: "3766",
            sy8: "",
            lt10: "teshbuĥto bamraume",
            sy9: "",
            lt12: ""
        },
        {
            lt11: "",
            sy6: "",
            id: "5861",
            sy4: "ܬܚܘܡ ܥܘܡܪܐ",
            sy7: "",
            lt12: "",
            lt10: "tĥum ĉumro",
            dt1: "Altersgrenze",
            sy8: "",
            dt3: "",
            sy9: "",
            sy5: "ܬܚܽܘܡ ܥܽܘܡܪܳܐ",
            dt2: ""
        },
        {
            sy6: "ܟܪܙ",
            lt12: "",
            sy9: "",
            lt11: "kraz",
            dt3: "",
            sy5: "ܟܰܪܶܙ",
            sy8: "",
            dt1: "predigen",
            dt2: "vortragen",
            lt10: "karez",
            sy4: "ܟܪܙ",
            id: "3504",
            sy7: "ܟܪܰܙ"
        },
        {
            sy5: "ܡܚܰܩܝܳܢܳܐ",
            lt12: "qaysuso d`ĉarshe",
            dt1: "Zahnstocher",
            sy9: "ܩܰܝܣܽܘܣܳܐ ܕܥܰܪ̈ܫܶܐ",
            lt11: "ĥoŝuyo",
            dt2: "",
            dt3: "",
            sy8: "ܩܝܣܘܣܐ ܕܥܪܫܐ",
            sy7: "ܚܳܨܽܘܝܳܐ",
            sy6: "ܚܨܘܝܐ",
            id: "5240",
            sy4: "ܡܚܩܝܢܐ",
            lt10: "mĥaqyono"
        },
        {
            sy9: "",
            dt2: "",
            dt3: "",
            lt11: "",
            dt1: "Stempel",
            sy8: "",
            sy6: "",
            sy5: "ܛܰܒܥܳܐ",
            sy4: "ܛܒܥܐ",
            lt10: "țabĉo",
            id: "4261",
            lt12: "",
            sy7: ""
        },
        {
            lt11: "halekh",
            sy5: "ܐܳܙܶܠ",
            id: "1535",
            lt10: "ozel",
            sy6: "ܗܠܟ",
            dt3: "",
            lt12: "",
            sy9: "",
            sy4: "ܐܙܠ",
            dt1: "gehen",
            sy7: "ܗܰܠܶܟ݂",
            sy8: "",
            dt2: ""
        },
        {
            sy4: "ܓܠܠܐ ܩܪܝܪܐ",
            dt3: "",
            sy7: "",
            lt12: "",
            dt2: "Kälteeinbruch",
            sy5: "ܓܰܠܳܠܐ ܩܰܪܺܝܪܳܐ",
            sy6: "",
            lt11: "",
            dt1: "Kältewelle",
            sy8: "",
            sy9: "",
            id: "6150",
            lt10: "galo qariro"
        },
        {
            sy5: "ܚܢܽܘܟ݂",
            dt1: "Henoch",
            sy7: "",
            sy4: "ܚܢܘܟ݂",
            dt3: "",
            lt12: "",
            dt2: "",
            sy9: "",
            sy6: "",
            lt10: "ܚܢܘܟ݂",
            id: "1962",
            sy8: "",
            lt11: "ܚܢܘܟ݂"
        },
        {
            id: "1915",
            sy7: "",
            dt3: "",
            sy6: "",
            dt2: "",
            lt11: "ܚܙܘܪܬܐ",
            sy9: "",
            sy8: "",
            dt1: "Hazurta",
            sy5: "ܚܰܙܽܘܪܬܰܐ",
            lt12: "",
            sy4: "ܚܙܘܪܬܐ",
            lt10: "ܚܙܘܪܬܐ"
        },
        {
            sy4: "ܫܒܗܪ",
            sy8: "",
            sy6: "",
            lt11: "ܫܒܗܪ",
            dt3: "",
            lt10: "ܫܒܗܪ",
            lt12: "",
            sy9: "",
            sy7: "",
            dt2: "",
            id: "3862",
            dt1: "Schabhar",
            sy5: "ܫܰܒܗܰܪ"
        },
        {
            dt1: "schweigen",
            sy7: "",
            sy9: "",
            lt10: "shtaq",
            sy4: "ܫܬܩ ",
            sy5: "ܫܬܰܩ ",
            lt12: "",
            sy6: "",
            sy8: "",
            lt11: "",
            dt3: "",
            id: "4071",
            dt2: ""
        },
        {
            id: "5194",
            lt10: "ܝܠܝܦ",
            sy4: "ܝܠܝܦ",
            dt1: "Yalif",
            sy7: "",
            dt3: "",
            sy5: "ܝܰܠܺܝܦ",
            lt12: "",
            dt2: "",
            sy8: "",
            sy9: "",
            sy6: "",
            lt11: "ܝܠܝܦ"
        },
        {
            lt10: "ܚܐܢܢ",
            sy6: "ܚܢܢ",
            dt2: "",
            dt3: "",
            sy5: "ܚܰܐܢܳܢ",
            dt1: "Hanan",
            sy8: "",
            sy7: "ܚܰܢܰܢ",
            lt12: "ܚܢܢ",
            id: "1842",
            lt11: "ܚܐܢܢ",
            sy4: "ܚܐܢܢ",
            sy9: ""
        },
        {
            sy8: "",
            dt2: "",
            lt11: "ܫܡܫܓܪܡ",
            dt1: "Schamschagram",
            sy4: "ܫܡܫܓܪܡ",
            sy7: "ܫܰܡܶܫܓܪܰܡ",
            sy5: "ܫܰܡܫܰܓܪܰܡ",
            id: "3897",
            lt10: "ܫܡܫܓܪܡ",
            sy6: "ܫܡܫܓܪܡ",
            lt12: "ܫܡܫܓܪܡ",
            sy9: "",
            dt3: ""
        },
        {
            sy9: "",
            id: "6424",
            sy6: "",
            sy7: "",
            dt2: "",
            sy4: "ܡܘܕܝܢܘܬܐ",
            lt11: "",
            lt10: "maudyonutho",
            lt12: "",
            sy8: "",
            dt3: "",
            sy5: "ܡܰܘܕܝܳܢܽܘܬ݂ܳܐ",
            dt1: "Beichte"
        },
        {
            dt1: "Niesen",
            sy9: "",
            id: "3275",
            dt2: "",
            sy5: "ܥܽܘܛܳܫܳܐ",
            dt3: "",
            sy6: "",
            sy4: "ܥܘܛܫܐ",
            sy7: "",
            sy8: "",
            lt11: "",
            lt10: "ĉuțosho",
            lt12: ""
        },
        {
            lt12: "",
            lt10: "shamoyo",
            sy6: "",
            sy8: "",
            id: "791",
            dt2: "",
            dt3: "",
            dt1: "Damaszener",
            sy9: "",
            lt11: "",
            sy4: "ܫܡܝܐ",
            sy5: "ܫܰܡܳܝܳܐ",
            sy7: ""
        },
        {
            lt10: "eno yoteb no ĉal kursyo",
            lt11: "",
            sy7: "",
            lt12: "",
            sy4: "ܐܢܐ ܝܬܒ ܐܢܐ ܥܠ ܟܘܪܣܝܐ",
            id: "2138",
            sy6: "",
            sy8: "",
            dt2: "",
            sy9: "",
            dt3: "",
            dt1: "Ich setze mich auf den Stuhl",
            sy5: "ܐܶܢܳܐ ܝܳܬܶܒ ܐ̱ܢܳܐ ܥܰܠ ܟܽܘܪܣܝܳܐ"
        },
        {
            sy7: "",
            lt11: "",
            lt10: "may fuyoĉo",
            dt2: "",
            dt3: "",
            dt1: "Spülwasser",
            sy5: "ܡܰܝ̈ ܦܽܘܝܳܥܳܐ",
            id: "6210",
            lt12: "",
            sy8: "",
            sy6: "",
            sy4: "ܡܝ ܦܘܝܥܐ",
            sy9: ""
        },
        {
            lt11: "",
            sy5: "ܩܶܠܳܝܬܳܐ ܕܫܽܘܚܠܳܦ ܠܒܽܘ̈ܫܶܐ",
            sy4: "ܩܠܝܬܐ ܕܫܘܚܠܦ ܠܒܘ̈ܫܐ",
            sy7: "",
            dt2: "Kleiderkabine",
            lt10: "qeloyto d`shuĥlof lbushe",
            sy9: "",
            id: "4629",
            sy6: "",
            sy8: "",
            lt12: "",
            dt3: "",
            dt1: "Umkleidekabine"
        },
        {
            lt10: "ܫܠܡܬ",
            lt11: "ܫܠܡܬ",
            sy9: "",
            sy5: "ܫܰܠܡܰܬ",
            lt12: "",
            id: "3888",
            dt1: "Schalmat",
            sy4: "ܫܠܡܬ",
            sy8: "",
            sy6: "",
            dt2: "",
            sy7: "",
            dt3: ""
        },
        {
            sy4: "ܙܘܙܐ ܕܛܠܝܐ",
            dt3: "",
            sy9: "",
            sy6: "",
            lt11: "",
            sy8: "",
            sy5: "ܙܽܘ̈ܙܶܐ ܕܛܠܳܝ̈ܶܐ",
            lt12: "",
            lt10: "zuze da`țloye",
            id: "2479",
            dt1: "Kindergeld",
            sy7: "",
            dt2: ""
        },
        {
            dt3: "",
            sy8: "",
            sy5: "ܠܡܺܐܢ ܩܰܝ̈ܣܶܐ",
            lt11: "",
            dt1: "Holzhafen",
            id: "6054",
            lt10: "lmin qayse",
            lt12: "",
            sy9: "",
            sy4: "ܠܡܝܢ ܩܝܣܐ",
            sy6: "",
            dt2: "",
            sy7: ""
        },
        {
            lt12: "",
            dt1: "Karyuta",
            sy4: "ܟܪܝܘܬܐ",
            sy9: "",
            sy8: "",
            sy5: "ܟܰܪܝܽܘܬܰܐ",
            id: "2420",
            sy6: "",
            dt2: "",
            dt3: "",
            lt11: "ܟܪܝܘܬܐ",
            lt10: "ܟܪܝܘܬܐ",
            sy7: ""
        },
        {
            id: "1955",
            dt1: "Helfer",
            lt12: "",
            dt3: "Beschützer",
            sy6: "",
            sy8: "",
            lt10: "mĉadrono",
            dt2: "Unterstützer",
            sy4: "ܡܥܕܪܢܐ",
            sy7: "",
            lt11: "",
            sy5: "ܡܥܰܕܪܳܢܳܐ",
            sy9: ""
        },
        {
            dt3: "",
            dt2: "",
            sy4: "ܩܠܐ",
            sy7: "",
            sy9: "",
            sy6: "",
            lt12: "",
            sy8: "",
            sy5: "ܩܳܠܳܐ",
            lt11: "",
            lt10: "qolo",
            id: "4277",
            dt1: "Stimme"
        },
        {
            sy7: "",
            sy9: "",
            sy5: "ܫܰܬܺܝܩܽܘܬ݂ܳܐ",
            dt2: "",
            lt10: "shatiqutho",
            sy8: "",
            dt3: "",
            lt11: "",
            lt12: "",
            sy6: "",
            id: "4070",
            sy4: "ܫܬܝܩܘܬܐ",
            dt1: "Schweigen"
        },
        {
            dt3: "",
            lt10: "mlifonutho",
            sy5: "ܡܠܺܝܦܳܢܽܘܬ݂ܳܐ",
            dt2: "",
            dt1: "Verpacken",
            sy8: "",
            lt12: "",
            lt11: "",
            sy9: "",
            sy4: "ܡܠܝܦܢܘܬܐ",
            sy7: "",
            id: "4779",
            sy6: ""
        },
        {
            id: "2519",
            lt10: "sĉuroith",
            lt12: "",
            sy4: "ܙܥܘܪܐܝܬ",
            sy6: "",
            lt11: "",
            dt3: "",
            dt1: "kleinlich",
            dt2: "",
            sy7: "",
            sy5: "ܙܥܽܘܪܳܐܝܺܬ݂",
            sy9: "",
            sy8: ""
        },
        {
            id: "5749",
            dt3: "",
            lt12: "",
            sy4: "ܫܒܩ ܕܡܐ",
            lt11: "",
            dt1: "Aderlaß",
            sy8: "",
            lt10: "shboq dmo",
            sy6: "",
            sy9: "",
            sy7: "",
            dt2: "",
            sy5: "ܫܒܳܩ ܕܡܳܐ"
        },
        {
            sy8: "",
            sy4: "ܪܒܪܬ",
            dt2: "",
            sy6: "",
            dt3: "",
            dt1: "Robert",
            lt11: "ܪܒܪܬ",
            sy7: "",
            lt12: "",
            sy9: "",
            id: "3731",
            lt10: "ܪܒܪܬ",
            sy5: "ܪܰܒܶܪܬ"
        },
        {
            id: "6005",
            sy5: "ܓܪܳܐ",
            sy9: "",
            dt1: "anzetteln",
            lt12: "",
            sy8: "",
            sy7: "",
            lt10: "gro",
            sy6: "",
            dt3: "",
            dt2: "",
            lt11: "",
            sy4: "ܓܪܐ"
        },
        {
            sy6: "",
            sy5: "ܚܰܕܝܳܐ",
            lt10: "ĥadyo",
            sy8: "",
            sy9: "",
            sy7: "",
            id: "722",
            dt1: "Brust",
            lt11: "",
            dt2: "",
            dt3: "",
            lt12: "",
            sy4: "ܚܕܝܐ"
        },
        {
            id: "285",
            lt12: "",
            lt10: "ܐܪܒܝܠܐ",
            sy8: "",
            dt1: "Arbila",
            sy9: "",
            sy5: "ܐܰܪܒܺܝܠܰܐ",
            dt3: "",
            sy7: "",
            lt11: "ܐܪܒܝܠܐ",
            sy4: "ܐܪܒܝܠܐ",
            dt2: "Arbela",
            sy6: ""
        },
        {
            lt10: "mțalĉutho",
            dt3: "",
            sy5: "ܡܛܰܠܥܽܘܬ݂ܳܐ",
            sy4: "ܡܛܠܥܘܬܐ",
            sy6: "",
            dt1: "Bewusstlosigkeit",
            id: "603",
            lt11: "",
            sy7: "",
            lt12: "",
            sy8: "",
            sy9: "",
            dt2: ""
        },
        {
            lt10: "ĥađ",
            sy4: "ܚܕ",
            id: "2",
            dt1: "1",
            lt12: "yy",
            sy6: "ܚܕܐ",
            dt3: "",
            sy8: "ܐ",
            lt11: "ĥđo",
            sy7: "ܚܕܳܐ",
            dt2: "Eins",
            sy5: "ܚܰܕ",
            sy9: "ܐ"
        },
        {
            lt12: "",
            sy7: "",
            dt2: "",
            id: "5443",
            sy9: "",
            dt1: "Ich heirate",
            sy4: "ܐܶܢܳܐ ܡܶܙܕܰܘܰܓ݂ ܐ̱ܢܳܐ",
            lt10: "eno mesdawağ no",
            sy8: "",
            sy6: "",
            dt3: "",
            sy5: "ܐܶܢܳܐ ܡܶܙܕܰܘܰܓ݂ ܐ̱ܢܳܐ",
            lt11: ""
        },
        {
            id: "5905",
            lt10: "buro",
            sy6: "",
            sy8: "",
            dt1: "Analphabet",
            dt3: "Schreibunkundiger",
            sy4: "ܒܘܪܐ",
            sy5: "ܒܽܘܪܳܐ",
            dt2: "Leseunkundiger",
            lt12: "",
            sy9: "",
            sy7: "",
            lt11: ""
        },
        {
            sy6: "",
            dt2: "",
            sy8: "",
            id: "5759",
            sy7: "",
            lt10: "seğdtho",
            dt1: "Adoration",
            lt12: "",
            sy4: "ܣܓܕܬܐ",
            lt11: "",
            sy9: "",
            sy5: "ܣܶܓ݂ܕܬ݂ܳܐ",
            dt3: ""
        },
        {
            dt1: "Fragestellerin",
            id: "6091",
            lt10: "mshalonitho",
            sy9: "",
            dt2: "Fragende",
            sy5: "ܡܫܰܐܠܳܢܺܝܬ݂ܳܐ",
            lt11: "",
            sy7: "",
            sy6: "",
            sy8: "",
            lt12: "",
            dt3: "",
            sy4: "ܡܫܠܢܝܬܐ"
        },
        {
            dt3: "",
            dt2: "",
            lt11: "",
            sy4: "ܬܰܗܢܺܝܬ݂ܳܐ ܠܶܒܳܢܳܝܬܳܐ",
            dt1: "Herzlichen Glückwunsch",
            lt10: "tahnitho lebonoyto",
            sy8: "",
            sy5: "ܬܰܗܢܺܝܬ݂ܳܐ ܠܶܒܳܢܳܝܬܳܐ",
            sy9: "",
            id: "5445",
            sy7: "",
            lt12: "",
            sy6: ""
        },
        {
            lt10: "hu ith le ețruğo",
            dt3: "",
            sy8: "",
            lt11: "",
            sy4: "ܗܘ ܐܝܬ ܠܗ ܐܛܪܘܓܐ",
            sy9: "",
            dt2: "",
            dt1: "Er hat eine Orange",
            sy7: "",
            id: "1120",
            lt12: "",
            sy6: "",
            sy5: "ܗܽܘ ܐܺܝܬ݂ ܠܶܗ ܐܶܛܪܽܘܓ݂ܳܐ"
        },
        {
            sy5: "ܚܪܰܡ",
            sy8: "",
            dt3: "",
            lt12: "",
            sy6: "ܐܚܪܡ",
            sy7: "ܐܰܚܪܶܡ",
            lt10: "ĥram",
            dt1: "verfluchen",
            dt2: "",
            sy4: "ܚܪܡ",
            sy9: "",
            lt11: "aĥrem",
            id: "4728"
        },
        {
            sy9: "",
            id: "4390",
            lt10: "ܛܪܩܐ",
            sy6: "",
            dt2: "",
            dt1: "Tarqo",
            sy4: "ܛܪܩܐ",
            sy7: "",
            sy5: "ܛܰܪܩܳܐ",
            dt3: "",
            lt11: "ܛܪܩܐ",
            lt12: "",
            sy8: ""
        },
        {
            dt3: "",
            sy4: "ܠܥܣ",
            lt10: "lĉes",
            sy6: "",
            lt12: "",
            dt2: "",
            sy7: "",
            dt1: "kauen",
            sy9: "",
            sy8: "",
            sy5: "ܠܥܶܣ",
            id: "2435",
            lt11: ""
        },
        {
            dt3: "",
            dt1: "Volksgemeinschaft",
            sy6: "",
            sy7: "",
            lt10: "shautofoyutho",
            id: "4855",
            sy8: "",
            dt2: "",
            lt11: "",
            sy4: "ܫܘܬܦܝܘܬܐ",
            sy9: "",
            sy5: "ܫܰܘܬܳܦܳܝܽܘܬ݂ܳܐ",
            lt12: ""
        },
        {
            sy6: "",
            lt12: "",
            id: "59",
            lt11: "",
            sy7: "",
            dt3: "",
            dt1: "abbestellen",
            sy5: "ܒܰܛܶܠ ܦܽܘܩܳܕܳܐ",
            dt2: "",
            sy8: "",
            lt10: "bațel fuqođo",
            sy4: "ܒܛܠ ܦܘܩܕܐ",
            sy9: ""
        },
        {
            lt11: "mmashĥono",
            dt2: "",
            dt3: "",
            sy5: "ܡܰܫܘܽܚܬܳܢܳܐ",
            sy7: "ܡܡܰܫܚܳܢܳܐ",
            sy6: "ܡܡܫܚܢܐ",
            dt1: "wirtschaftlich",
            lt10: "mashuĥtono",
            sy4: "ܡܫܘܚܬܢܐ",
            sy9: "",
            id: "5133",
            sy8: "",
            lt12: ""
        },
        {
            sy6: "",
            lt11: "",
            sy8: "",
            sy9: "",
            lt12: "",
            dt3: "",
            lt10: "gomusho",
            sy7: "",
            sy5: "ܓܳܡܽܘܫܳܐ",
            sy4: "ܓܡܘܫܐ",
            dt2: "",
            dt1: "Büffel",
            id: "731"
        },
        {
            sy8: "",
            dt2: "",
            dt1: "Kamin",
            sy9: "",
            sy4: "ܟܢܘܢܐ",
            sy6: "",
            sy7: "",
            sy5: "ܟܳܢܽܘܢܳܐ",
            lt10: "konuno",
            id: "6149",
            lt11: "",
            lt12: "",
            dt3: ""
        },
        {
            sy4: "ܓܪܒܝܐ",
            dt3: "",
            lt10: "garboyo",
            id: "3295",
            sy9: "",
            sy6: "",
            lt11: "",
            dt2: "",
            lt12: "",
            sy8: "",
            sy5: "ܓܰܪܒܳܝܳܐ",
            sy7: "",
            dt1: "nördlich"
        },
        {
            lt12: "",
            lt11: "shaushbinto",
            sy5: "ܩܰܪܺܝܒܬܳܐ",
            sy9: "",
            dt3: "",
            sy6: "ܫܘܫܒܝܢܬܐ",
            sy4: "ܩܪܝܒܬܐ",
            sy7: "ܫܰܘܫܒܺܝܢܬܳܐ",
            lt10: "qaribto",
            dt1: "Taufpatin",
            id: "4406",
            sy8: "",
            dt2: "Patentante"
        },
        {
            dt2: "",
            id: "1094",
            dt1: "Embryo",
            sy9: "",
            sy4: "ܥܘܠܐ",
            sy5: "ܥܽܘܠܳܐ",
            sy7: "",
            sy6: "",
            lt10: "ĉulo",
            dt3: "",
            sy8: "",
            lt12: "",
            lt11: ""
        },
        {
            dt1: "Schubholjeschu",
            lt11: "ܫܘܒܚܐܠܝܫܘܥ",
            sy7: "",
            dt2: "",
            sy9: "",
            sy5: "ܫܽܘܒܚܳܐܠܝܶܫܽܘܥ",
            sy8: "",
            sy6: "",
            id: "4035",
            lt12: "",
            dt3: "",
            sy4: "ܫܘܒܚܐܠܝܫܘܥ",
            lt10: "ܫܘܒܚܐܠܝܫܘܥ"
        },
        {
            sy9: "",
            sy8: "",
            sy4: "ܐܢܬܬܐ ܩܨܘܡܬܐ",
            dt3: "",
            lt10: "attho qoŝumto",
            lt12: "",
            sy6: "ܩܨܘܡܬܐ",
            dt2: "Hellseherin",
            sy7: "ܩܳܨܽܘܡܬܳܐ",
            lt11: "qoŝumto",
            sy5: "ܐܰܢ̱݂ܬܬ݂ܳܐ ܩܰܨܽܘܡܬܳܐ",
            id: "4963",
            dt1: "Wahrsagerin"
        },
        {
            lt11: "",
            sy4: "ܒܛܐ",
            sy8: "",
            sy5: "ܒܰܛܳܐ",
            dt3: "",
            id: "1112",
            sy7: "",
            sy9: "",
            dt2: "",
            sy6: "",
            dt1: "Ente",
            lt12: "",
            lt10: "bațo"
        },
        {
            sy6: "",
            dt2: "",
            dt1: "Harnblase",
            lt10: "shalfuĥto",
            sy5: "ܫܰܠܦܽܘܚܬܳܐ",
            id: "6118",
            lt11: "",
            lt12: "",
            sy9: "",
            sy4: "ܫܠܦܘܚܬܐ",
            sy7: "",
            sy8: "",
            dt3: ""
        },
        {
            sy5: "ܦܳܫ ܐ̱ܚܪܳܝܳܐ",
            lt12: "",
            sy8: "",
            sy6: "",
            id: "2784",
            sy9: "",
            sy7: "",
            dt3: "",
            dt1: "letzter bleiben",
            lt11: "",
            lt10: "fosh ĥroyo",
            dt2: "",
            sy4: "ܦܫ ܐܚܪܝܐ"
        },
        {
            dt1: "Alkanna",
            sy6: "ܫܟܪܐ",
            dt2: "",
            sy9: "ܣܶܦܳܝܳܐ",
            lt12: "sefoyo",
            sy8: "ܣܦܝܐ",
            sy7: "ܫܰܟ݂ܪܳܐ",
            sy5: "ܚܠܰܡܬܳܐ",
            lt10: "ĥlamto",
            id: "5824",
            lt11: "shakhro",
            sy4: "ܚܠܡܬܐ",
            dt3: ""
        },
        {
            sy4: "ܩܘܛܐ",
            sy5: "ܩܽܘܛܳܐ",
            sy6: "",
            lt11: "",
            dt1: "Scheide",
            dt3: "",
            lt10: "quțo",
            sy8: "",
            sy7: "",
            sy9: "",
            id: "3917",
            dt2: "Vagina",
            lt12: ""
        },
        {
            dt1: "Kastration",
            lt12: "",
            sy8: "",
            sy7: "",
            lt11: "",
            sy5: "ܣܽܘܪܳܣܳܐ",
            sy4: "ܣܘܪܣܐ",
            sy9: "",
            dt2: "",
            sy6: "",
            dt3: "",
            lt10: "surozo",
            id: "2427"
        },
        {
            dt3: "",
            lt12: "",
            sy9: "",
            lt10: "mayo",
            sy5: "ܡܰܝܳܐ",
            dt1: "Wasser",
            sy8: "",
            lt11: "",
            id: "5000",
            dt2: "",
            sy7: "",
            sy4: "ܡܝܐ",
            sy6: ""
        },
        {
            id: "4334",
            dt1: "Syrer",
            lt10: "suryoyo",
            dt3: "",
            sy6: "",
            lt11: "",
            sy4: "ܣܘܪܝܝܐ",
            sy7: "",
            sy8: "",
            sy9: "",
            sy5: "ܣܽܘܪܝܳܝܳܐ",
            lt12: "",
            dt2: ""
        },
        {
            sy8: "",
            dt1: "Hausmann",
            lt11: "",
            dt3: "",
            sy5: "ܓܰܒܪܳܐ ܕܒܰܝܬܳܐ",
            id: "1904",
            sy7: "",
            sy9: "",
            lt12: "",
            lt10: "gabro d`bayto",
            sy6: "",
            sy4: "ܓܒܪܐ ܕܒܝܬܐ",
            dt2: ""
        },
        {
            sy5: "ܝܰܗܒ ܫܠܳܡܳܐ",
            dt3: "",
            lt12: "",
            sy7: "",
            sy4: "ܝܗܒ ܫܠܡܐ",
            dt2: "willkommen",
            sy9: "",
            sy6: "",
            sy8: "",
            lt10: "yab shlomo",
            id: "530",
            lt11: "",
            dt1: "begrüßen"
        },
        {
            lt11: "ܫܠܝܐ",
            dt3: "",
            sy7: "",
            dt2: "",
            sy8: "",
            sy4: "ܫܠܝܐ",
            lt10: "ܫܠܝܐ",
            sy6: "",
            id: "3889",
            dt1: "Schalyo",
            sy5: "ܫܰܠܝܳܐ",
            sy9: "",
            lt12: ""
        },
        {
            sy7: "ܟܪܺܝܗ ܙܶܒܢܳܐ",
            lt12: "",
            sy8: "",
            lt10: "lhiq zebno",
            dt2: "",
            sy9: "",
            sy4: "ܠܗܝܩ ܙܒܢܐ",
            sy5: "ܠܗܺܝܩ ܙܶܒܢܳܐ",
            dt3: "",
            lt11: "krih zebno",
            id: "2442",
            sy6: "ܟܪܝܗ ܙܒܢܐ",
            dt1: "kaufsüchtig"
        },
        {
            sy8: "",
            sy7: "",
            dt2: "",
            lt10: "martyono",
            sy4: "ܡܪܬܝܢܐ",
            dt1: "Monitor",
            lt11: "",
            sy5: "ܡܰܪܬܝܳܢܳܐ",
            dt3: "",
            lt12: "",
            sy6: "",
            id: "3083",
            sy9: ""
        },
        {
            lt11: "",
            sy6: "",
            sy8: "",
            sy9: "",
            lt10: "ĥyo rĥem ĥash",
            sy5: "ܚܝܳܐ ܪܚܶܡ ܚܰܫ",
            dt2: "",
            dt1: "leben lieben leiden",
            sy4: "ܚܝܐ ܪܚܡ ܚܫ",
            lt12: "",
            id: "2729",
            dt3: "",
            sy7: ""
        },
        {
            sy6: "",
            sy5: "ܚܙܩܝܐܝܠ",
            sy9: "",
            lt12: "",
            lt11: "ܚܙܩܝܐܝܠ",
            sy4: "ܚܙܩܝܐܝܠ",
            dt3: "",
            lt10: "ܚܙܩܝܐܝܠ",
            dt1: "Hezekiel",
            id: "1994",
            sy7: "",
            sy8: "",
            dt2: ""
        },
        {
            sy4: "ܨܘܨܠܐ",
            dt2: "",
            lt10: "ŝuŝlo",
            id: "4607",
            sy5: "ܨܽܘܨܠܳܐ",
            sy8: "",
            lt11: "",
            dt3: "",
            lt12: "",
            sy9: "",
            sy7: "",
            sy6: "",
            dt1: "Turteltaube"
        },
        {
            sy9: "",
            sy4: "ܒܘܕܡܟܐ",
            id: "3556",
            lt10: "budemkho",
            lt11: "",
            sy7: "",
            dt1: "Pyjama",
            dt3: "",
            sy8: "",
            dt2: "Schlafanzug",
            sy6: "",
            sy5: "ܒܽܘܕܶܡܟ݂ܳܐ",
            lt12: ""
        },
        {
            sy7: "",
            sy6: "",
            lt10: "luqbal mun",
            sy9: "",
            lt11: "",
            dt3: "",
            dt1: "wogegen",
            sy5: "ܠܽܘܩܒܰܠ ܡܽܘܢ",
            id: "5150",
            lt12: "",
            sy4: "ܠܘܩܒܠ ܡܘܢ",
            dt2: "",
            sy8: ""
        },
        {
            id: "4561",
            dt3: "",
            sy7: "",
            sy5: "ܕܝܳܫܳܐ",
            sy8: "",
            dt2: "",
            lt11: "",
            lt10: "dyoscho",
            sy6: "",
            sy9: "",
            dt1: "Treten",
            sy4: "ܕܝܫܐ",
            lt12: ""
        },
        {
            sy6: "ܫܘܫܒܝܢܐ",
            lt11: "shaushbino",
            lt12: "",
            sy4: "ܩܪܝܒܐ",
            sy7: "ܫܰܘܫܒܺܝܢܳܐ",
            id: "4405",
            sy5: "ܩܰܪܺܝܒܳܐ",
            sy9: "",
            dt3: "",
            sy8: "",
            dt2: "Patenonkel",
            lt10: "qaribo",
            dt1: "Taufpate"
        },
        {
            dt1: "Ahudemeh",
            sy9: "",
            sy7: "",
            sy4: "ܐܚܘܕܡܗ",
            dt3: "",
            sy5: "ܐܰܚܽܘܕܶܡܶܗ",
            id: "165",
            lt11: "ܐܚܘܕܡܗ",
            dt2: "",
            sy8: "",
            lt10: "ܐܚܘܕܡܗ",
            sy6: "",
            lt12: ""
        },
        {
            lt11: "",
            dt1: "Zahnkrone",
            sy7: "",
            sy6: "",
            id: "5237",
            sy4: "ܬܓܐ ܕܥܪܫܐ",
            lt10: "toğo d`ĉarsho",
            dt2: "",
            sy8: "",
            lt12: "",
            sy9: "",
            sy5: "ܬܳܓ݂ܳܐ ܕܥܰܪܫܳܐ",
            dt3: ""
        },
        {
            sy5: " ܛܶܒ̈ܶܐ ܡܰܥܶܪ̈ܒܳܝܶܐ",
            sy7: "",
            id: "5537",
            dt2: "",
            sy9: "",
            sy4: " ܛܒܐ ܡܥܪܒܝܐ",
            sy6: "",
            dt1: "westliche Nachrichten",
            sy8: "",
            dt3: "",
            lt12: "",
            lt10: "țebe maĉerboye",
            lt11: ""
        },
        {
            sy4: "ܣܪܩܐ",
            dt1: "Kämmen",
            lt11: "",
            dt3: "",
            sy9: "",
            lt12: "",
            sy7: "",
            lt10: "sroqo",
            sy8: "",
            dt2: "",
            sy6: "",
            sy5: "ܣܪܳܩܳܐ",
            id: "2400"
        },
        {
            dt1: "Heimat",
            lt12: "",
            sy8: "",
            sy4: "ܡܬܐ",
            id: "1932",
            dt2: "Vaterland",
            dt3: "",
            sy6: "ܐܬܪܐ ܕܡܘܠܕܐ",
            lt11: "athro d`maulodo",
            lt10: "motho",
            sy5: "ܡܳܬ݂ܳܐ",
            sy7: "ܐܰܬ݂ܪܳܐ ܕܡܰܘܠܳܕܳܐ",
            sy9: ""
        },
        {
            lt11: "tobar",
            sy4: "ܬܒܪ",
            dt3: "kaputt machen",
            sy8: "",
            lt12: "",
            sy5: "ܬܒܰܪ",
            dt2: "zerbrechen",
            sy7: "ܬܳܒܰܪ",
            id: "698",
            dt1: "brechen",
            sy9: "",
            lt10: "tbar",
            sy6: "ܬܒܪ"
        },
        {
            id: "4905",
            sy5: "ܙܗܺܝܪܳܐ",
            dt1: "vorsichtig",
            sy9: "",
            sy7: "",
            lt11: "",
            sy8: "",
            dt3: "",
            sy4: "ܙܗܝܪܐ",
            lt10: "zhiro",
            lt12: "",
            dt2: "",
            sy6: ""
        },
        {
            sy9: "",
            id: "6000",
            dt2: "",
            dt1: "Antike",
            sy5: "ܫܺܝܬ݂ܳܢܳܝܽܘܬ݂ܳܐ",
            dt3: "",
            lt11: "",
            lt12: "",
            sy7: "",
            lt10: "shithonoyutho",
            sy8: "",
            sy6: "",
            sy4: "ܫܝܬܢܝܘܬܐ"
        },
        {
            sy4: "ܡܬܘܥܕܢܘܬܐ",
            sy8: "",
            sy7: "ܩܛܳܪ ܩܝܳܡܳܐ",
            sy6: "ܩܛܪ ܩܝܡܐ",
            dt1: "Absprache",
            lt11: "qțor qyomo",
            dt2: "",
            id: "5656",
            lt10: "methwaĉdonutho",
            sy9: "",
            dt3: "",
            sy5: "ܡܶܬ݂ܘܰܥܕܳܢܽܘܬ݂ܳܐ",
            lt12: ""
        },
        {
            sy4: "ܪܒ",
            id: "3887",
            lt12: "",
            sy7: "",
            sy6: "",
            sy9: "",
            dt1: "schallen",
            sy8: "",
            dt3: "",
            dt2: "",
            lt10: "rob",
            lt11: "",
            sy5: "ܪܳܒ"
        },
        {
            sy6: "",
            dt1: "exzellent",
            lt10: "myatro",
            sy7: "",
            lt11: "",
            lt12: "",
            sy9: "",
            dt2: "überragend",
            sy5: "ܡܝܰܬܪܳܐ",
            id: "1219",
            dt3: "",
            sy8: "",
            sy4: "ܡܝܬܪܐ"
        },
        {
            dt2: "",
            sy9: "",
            dt1: "Pullover",
            sy7: "",
            sy5: "ܦܰܢܶܪܰܐ",
            id: "3545",
            sy8: "",
            lt12: "",
            lt10: "fanera",
            sy4: "ܦܢܪܐ",
            sy6: "",
            dt3: "",
            lt11: ""
        },
        {
            dt1: "Storch",
            dt2: "Strauß",
            sy9: "",
            sy5: "ܐܰܣܺܝܕܳܐ",
            lt10: "asido",
            sy8: "",
            id: "4285",
            lt11: "",
            sy6: "",
            dt3: "",
            sy7: "",
            sy4: "ܐܣܝܕܐ",
            lt12: ""
        },
        {
            sy6: "",
            sy5: "ܐܶܠܺܝܗܰܒ",
            sy4: "ܐܠܝܗܒ",
            lt12: "",
            dt3: "",
            id: "1073",
            sy9: "",
            dt2: "Elihab",
            dt1: "Eliab",
            lt11: "ܐܠܝܗܒ",
            sy7: "",
            lt10: "ܐܠܝܗܒ",
            sy8: ""
        },
        {
            sy7: "ܪܰܒܽܘܠܰܐ",
            id: "3576",
            sy5: "ܪܰܒܒܽܘܠܰܐ",
            dt3: "",
            sy4: "ܪܒܒܘܠܐ",
            lt10: "ܪܒܒܘܠܐ",
            sy8: "",
            dt2: "",
            sy6: "ܪܒܘܠܐ",
            sy9: "",
            lt12: "ܪܒܘܠܐ",
            dt1: "Rabbula",
            lt11: "ܪܒܒܘܠܐ"
        },
        {
            lt12: "",
            dt1: "lesbisch",
            sy6: "ܒܥܝܬܐ",
            sy5: "ܒܪܺܝܡܬܳܐ",
            dt2: "",
            sy7: "ܒܰܥܳܝܬܳܐ",
            sy8: "",
            id: "2763",
            dt3: "",
            sy9: "",
            sy4: "ܒܪܝܡܬܐ",
            lt11: "baĉoyto",
            lt10: "brimto"
        },
        {
            sy4: "ܩܡܚܝܐ",
            dt2: "",
            lt11: "",
            lt12: "",
            dt1: "mehlig",
            sy5: "ܩܰܡܚܳܝܳܐ",
            sy6: "",
            dt3: "",
            sy7: "",
            lt10: "qamĥoyo",
            id: "2985",
            sy8: "",
            sy9: ""
        },
        {
            sy9: "",
            sy5: "ܫܶܡܫܶܐ ܥܳܪܒܳܐ",
            sy7: "ܥܪܳܒܳܐ ܕܫܶܡܫܶܐ",
            sy6: "ܥܪܒܐ ܕܫܡܫܐ",
            id: "4196",
            lt10: "shemso ĉorbo",
            dt2: "untergehende Sonne",
            sy4: "ܫܡܫܐ ܥܪܒܐ",
            lt12: "",
            dt1: "Sonnenuntergang",
            dt3: "",
            sy8: "",
            lt11: "ĉrobo d`shemsho"
        },
        {
            lt10: "ĉirune",
            sy5: "ܥܺܝܪܽܘܢܶܐ",
            id: "5793",
            lt11: "",
            dt3: "",
            sy9: "",
            sy7: "",
            dt1: "Affodill",
            sy4: "ܥܝܪܘܢܐ",
            lt12: "",
            dt2: "",
            sy8: "",
            sy6: ""
        },
        {
            lt10: "bitsa",
            sy6: "",
            dt3: "",
            lt12: "",
            sy5: "ܒܺܝܬܣܰܐ",
            lt11: "",
            sy8: "",
            id: "3468",
            dt1: "Pizza",
            sy4: "ܒܝܬܣܐ",
            sy9: "",
            sy7: "",
            dt2: ""
        },
        {
            sy8: "",
            sy6: "",
            id: "3853",
            dt3: "",
            sy9: "",
            lt10: "sargo",
            sy7: "",
            dt2: "",
            lt11: "",
            lt12: "",
            sy5: "ܣܰܪܓܳܐ",
            sy4: "ܣܪܓܐ",
            dt1: "Sattel"
        },
        {
            sy5: "ܣܽܘܪܺܝ",
            sy6: "",
            lt12: "",
            id: "4319",
            dt1: "Suri",
            lt11: "ܣܘܪܝ",
            lt10: "ܣܘܪܝ",
            dt3: "",
            sy7: "",
            sy4: "ܣܘܪܝ",
            sy8: "",
            dt2: "",
            sy9: ""
        },
        {
            lt12: "salo d`zeblo",
            sy8: "ܣܠܐ ܕܙܒܠܐ",
            sy9: "ܣܰܠܳܐ ܕܙܶܒܠܳܐ",
            lt11: "sețlo d`zeblo",
            dt2: "Mülleimer",
            sy4: "ܣܝܛܠܐ ܕܙܒܠܐ",
            dt3: "",
            sy6: "ܣܛܠܐ ܕܙܒܠܐ",
            dt1: "Abfalleimer",
            sy7: "ܣܶܛܠܳܐ ܕܙܶܒܠܳܐ",
            lt10: "sițlo d`zeblo",
            id: "81",
            sy5: "ܣܺܝܛܠܳܐ ܕܙܶܒܠܳܐ"
        },
        {
            sy4: "ܡܬܪܓܡܢܐ",
            dt3: "",
            sy6: "",
            lt11: "",
            dt2: "",
            sy5: "ܡܬܰܪܓܡܳܢܳܐ",
            sy7: "",
            sy8: "",
            dt1: "Referent",
            lt10: "mtargmono",
            id: "3656",
            lt12: "",
            sy9: ""
        },
        {
            sy6: "",
            sy9: "",
            dt1: "hinter sich blicken",
            sy8: "",
            sy5: "ܚܳܪ ܒܳܬ݂ܪܶܗ",
            lt12: "",
            lt11: "",
            sy4: "ܚܪ ܒܬܪܗ",
            lt10: "ĥor bothre",
            dt3: "",
            id: "2011",
            dt2: "",
            sy7: ""
        },
        {
            lt12: "ܣܡܣܘܢ",
            dt3: "",
            sy7: "ܣܰܡܣܳܘܢ",
            sy9: "",
            lt11: "ܫܡܫܘܢ",
            dt2: "",
            id: "3827",
            sy5: "ܫܶܡܫܽܘܢ",
            sy6: "ܣܡܣܘܢ",
            dt1: "Samson",
            lt10: "ܫܡܫܘܢ",
            sy4: "ܫܡܫܘܢ",
            sy8: ""
        },
        {
            lt11: "",
            sy8: "",
            sy4: "ܐܘܪܝܦܝܐ",
            dt1: "Europäer",
            sy5: "ܐܺܘܪܺܝܦܳܝܳܐ",
            dt3: "",
            sy9: "",
            lt10: "urifoyo",
            sy6: "",
            dt2: "",
            id: "1203",
            sy7: "",
            lt12: ""
        },
        {
            sy5: "ܐܰܪܝܳܐ ܢܳܗܶܡ",
            lt10: "aryo nohem",
            sy6: "",
            sy7: "",
            sy9: "",
            id: "2861",
            dt1: "Löwe brüllt",
            lt11: "",
            dt3: "",
            dt2: "brüllender Löwe",
            lt12: "",
            sy8: "",
            sy4: "ܐܪܝܐ ܢܗܡ"
        },
        {
            lt11: "shnorto",
            id: "1028",
            lt10: "quso",
            sy8: "",
            sy7: "ܫܢܳܪܬܳܐ",
            lt12: "",
            dt2: "",
            dt3: "",
            sy5: "ܩܽܘܙܳܐ",
            sy9: "",
            dt1: "Eichhörnchen",
            sy4: "ܩܘܙܐ",
            sy6: "ܫܢܪܬܐ"
        },
        {
            sy5: "ܚܽܘܝܳܕܳܐ ܥܰܪܰܒܳܝܰܐ",
            sy9: "",
            lt11: "ĥuyodo araboyo",
            dt2: "",
            sy8: "",
            id: "269",
            lt10: "ĥuyodo ĉaraboyo",
            lt12: "",
            sy4: "ܚܘܝܕܐ ܥܪܒܝܐ",
            dt1: "Arabische Liga",
            sy6: "ܚܘܝܕܐ ܐܪܒܝܐ",
            dt3: "",
            sy7: "ܚܽܘܝܳܕܳܐ ܐܰܪܰܒܳܝܳܐ"
        },
        {
            sy9: "",
            dt1: "schwarz",
            sy5: "ܐܽܘܟܳܡܳܐ",
            lt12: "",
            sy6: "",
            dt3: "",
            sy7: "",
            lt10: "ukomo",
            lt11: "",
            sy8: "",
            sy4: "ܐܘܟܡܐ",
            dt2: "",
            id: "4067"
        },
        {
            id: "4074",
            dt3: "",
            dt1: "Schweißtropfen",
            sy4: "ܛܘܦܬܐ ܕܕܘܥܬܐ",
            sy9: "",
            sy5: "ܛܽܘܦܬ݂ܳܐ ܕܕܽܘܥܬ݂ܳܐ",
            lt11: "",
            lt10: "țuftho d`duĉtho",
            dt2: "",
            sy8: "",
            sy6: "",
            sy7: "",
            lt12: ""
        },
        {
            id: "1293",
            lt10: "ĉeqbo",
            sy6: "",
            lt12: "",
            dt3: "",
            lt11: "",
            dt2: "Fussferse",
            sy4: "ܥܩܒܐ",
            sy8: "",
            dt1: "Ferse",
            sy7: "",
            sy5: "ܥܶܩܒܳܐ",
            sy9: ""
        },
        {
            sy7: "",
            sy8: "",
            id: "2783",
            dt2: "",
            sy6: "",
            dt1: "Letzter",
            sy9: "",
            lt10: "ĥaroyo",
            lt12: "",
            lt11: "",
            sy4: "ܐܚܪܝܐ",
            dt3: "",
            sy5: "ܐ̱ܚܰܪܳܝܳܐ"
        },
        {
            sy9: "",
            sy4: "ܡܟܢܝܫܬܐ",
            lt12: "",
            sy6: "",
            sy7: "",
            sy5: "ܡܰܟܢܺܝܫܬܳܐ",
            dt3: "",
            lt11: "",
            lt10: "makneshto",
            dt1: "Besen",
            id: "574",
            dt2: "Feger",
            sy8: ""
        },
        {
            sy7: "",
            sy8: "",
            dt2: "",
            sy5: "ܬܰܪܘܳܕܳܐ ܕܙܽܘܒܕܳܐ",
            sy6: "",
            sy4: "ܬܪܘܕܐ ܕܙܘܒܕܐ",
            sy9: "",
            dt1: "Butterlöffel",
            lt10: "tarwođo d`zubdo",
            lt12: "",
            dt3: "",
            lt11: "",
            id: "749"
        },
        {
            sy4: "ܐܘܪܗܝܢ",
            sy7: "",
            sy5: "ܐܽܘܪܗܺܝܢ",
            id: "4685",
            dt2: "",
            lt10: "ܐܘܪܗܝܢ",
            dt1: "Urhin",
            lt12: "",
            sy9: "",
            sy6: "",
            sy8: "",
            lt11: "ܐܘܪܗܝܢ",
            dt3: ""
        },
        {
            dt2: "lachhaft",
            sy7: "",
            lt10: "mgaĥkhono",
            sy9: "",
            id: "1745",
            sy6: "",
            lt12: "",
            dt1: "grotesk",
            dt3: "ulkig",
            lt11: "",
            sy8: "",
            sy5: "ܡܓܰܚܟ݂ܳܢܳܐ",
            sy4: "ܡܓܚܟܢܐ"
        },
        {
            dt2: "",
            sy6: "",
            lt12: "",
            id: "3855",
            dt1: "Sätze",
            lt11: "",
            sy4: "ܦܬܓ̈ܡܐ",
            sy8: "",
            sy9: "",
            lt10: "fetğome",
            dt3: "",
            sy7: "",
            sy5: "ܦܶܬܓ݂̈ܳܡܶܐ"
        },
        {
            id: "4984",
            lt11: "ܘܪܕܐ",
            dt3: "",
            sy8: "",
            dt1: "Wardo",
            sy4: "ܘܪܕܐ",
            lt12: "",
            dt2: "",
            sy9: "",
            sy7: "",
            lt10: "ܘܪܕܐ",
            sy5: "ܘܰܪܕܳܐ",
            sy6: ""
        },
        {
            dt3: "",
            dt1: "frankieren",
            lt10: "dabeq țabĉo",
            lt11: "",
            sy7: "",
            sy4: "ܕܒܩ ܛܒܥܐ",
            sy5: "ܕܰܒܶܩ ܛܰܒܥܳܐ",
            sy9: "",
            lt12: "",
            id: "6093",
            dt2: "",
            sy8: "",
            sy6: ""
        },
        {
            sy8: "",
            lt10: "zayno",
            dt2: "Waffen",
            sy9: "",
            dt3: "",
            sy5: "ܙܰܝܢܳܐ",
            dt1: "Waffe",
            id: "4925",
            lt12: "",
            sy4: "ܙܝܢܐ",
            sy6: "",
            sy7: "",
            lt11: ""
        },
        {
            lt12: "",
            lt10: "nfoqo",
            sy9: "",
            lt11: "mafaqto",
            sy5: "ܢܦܳܩܳܐ",
            sy6: "ܡܦܩܬܐ",
            dt3: "",
            sy7: "ܡܰܦܰܩܬܳܐ",
            id: "376",
            dt2: "Abfahrt",
            sy4: "ܢܦܩܐ",
            sy8: "",
            dt1: "Ausfahrt"
        },
        {
            dt1: "Kalb",
            id: "2385",
            sy5: "ܥܶܓܠܳܐ",
            lt12: "",
            lt11: "",
            sy4: "ܥܓܠܐ",
            sy8: "",
            sy7: "",
            dt3: "",
            sy6: "",
            lt10: "ĉeglo",
            sy9: "",
            dt2: ""
        },
        {
            sy8: "",
            sy5: "ܐܶܢܳܐ ܐ̱ܢܳܐ ܪܳܥܝܳܐ ܛܳܒܳܐ",
            lt12: "",
            sy6: "",
            lt11: "",
            id: "2096",
            sy4: "ܐܢܐ ܐܢܐ ܪܥܝܐ ܛܒܐ",
            sy9: "",
            dt2: "",
            dt3: "",
            dt1: "Ich bin der gute Hirte",
            sy7: "",
            lt10: "eno no roĉyo țobo"
        },
        {
            dt3: "",
            lt11: "ܛܪܝܡܐ",
            sy6: "",
            id: "4565",
            sy5: "ܛܪܺܝܡܰܐ",
            sy9: "",
            dt1: "Trima",
            sy7: "",
            lt12: "",
            dt2: "",
            lt10: "ܛܪܝܡܐ",
            sy8: "",
            sy4: "ܛܪܝܡܐ"
        },
        {
            sy4: "ܟܠܒܐ ܢܛܘܪܐ",
            dt2: "",
            sy6: "",
            sy7: "",
            sy9: "",
            sy8: "",
            id: "4919",
            lt12: "",
            sy5: "ܟܰܠܒܳܐ ܢܳܛܽܘܪܳܐ",
            lt10: "kalbo noțuro",
            lt11: "",
            dt1: "Wachhund",
            dt3: ""
        },
        {
            sy9: "ܗܡܳܐ",
            sy8: "ܗܡܐ",
            sy5: "ܬܗܳܐ",
            dt2: "",
            dt3: "",
            sy7: "ܐܰܗܡܺܝ",
            sy4: "ܬܗܐ",
            lt12: "hmo",
            id: "6270",
            lt10: "tho",
            dt1: "zaudern",
            lt11: "ahmi",
            sy6: "ܐܗܡܝ"
        },
        {
            lt10: "msono da'shroĉo",
            sy5: "ܡܣܐܳܢܳܐ ܕܰܫܪܳܥܳܐ",
            sy9: "",
            dt2: "",
            dt1: "Schlittschuh",
            id: "6340",
            lt12: "",
            dt3: "",
            sy4: "ܡܣܐܢܐ ܕܫܪܥܐ",
            sy7: "",
            sy6: "",
            lt11: "",
            sy8: ""
        },
        {
            sy9: "",
            dt1: "schimmeln",
            sy6: "",
            sy7: "",
            lt12: "",
            sy4: "ܩܡܠ",
            id: "3954",
            dt2: "",
            sy8: "",
            lt11: "",
            dt3: "",
            lt10: "qmal",
            sy5: "ܩܡܰܠ"
        },
        {
            dt1: "feucht",
            sy4: "ܬܠܝܠܐ",
            sy7: "ܬܰܪܝܳܐ",
            sy9: "ܪܰܛܺܝܒܳܐ",
            dt3: "",
            lt10: "talilo",
            lt11: "taryo",
            lt12: "rațibo",
            sy5: "ܬܰܠܺܝܠܳܐ",
            id: "1300",
            sy8: "ܪܛܝܒܐ",
            sy6: "ܬܪܝܐ",
            dt2: "nass"
        },
        {
            dt1: "Milad",
            sy9: "",
            sy4: "ܡܝܠܕ",
            sy5: "ܡܺܝܠܰܕ",
            dt2: "",
            lt12: "",
            id: "3024",
            lt10: "ܡܝܠܕ",
            lt11: "ܡܝܠܕ",
            dt3: "",
            sy8: "",
            sy6: "",
            sy7: ""
        },
        {
            lt12: "",
            sy6: "",
            sy7: "",
            sy4: "ܫܘܠܐ ܘ ܦܘܢܝܐ",
            sy9: "",
            lt10: "shuolo u funoyo",
            id: "6094",
            dt2: "",
            sy5: "ܫܽܘܳܠܳܐ ܘ ܦܽܘܢܳܝܳܐ",
            sy8: "",
            dt3: "",
            lt11: "",
            dt1: "Frage und Antwort"
        },
        {
            sy9: "",
            lt11: "ܐܬܪܐ",
            lt12: "",
            dt1: "Athra",
            id: "328",
            sy7: "",
            sy4: "ܐܬܪܐ",
            sy8: "",
            dt3: "",
            sy5: "ܐܰܬ݂ܪܰܐ",
            dt2: "",
            sy6: "",
            lt10: "ܐܬܪܐ"
        },
        {
            dt3: "",
            sy9: "",
            lt10: "ĥoze hwith leh lsoțono danfal akh barqo men shmayo",
            sy7: "",
            sy4: "ܚܙܐ ܗܘܝܬ ܠܗ ܠܣܬܢܐ ܕܢܦܠ ܐܝܟ ܒܪܩܐ ܡܢ ܫܡܝܐ",
            id: "2133",
            dt2: "",
            lt12: "",
            sy8: "",
            sy6: "",
            sy5: "ܚܳܙܶܐ ܗܘܺܝܬ݂ ܠܶܗ ܠܣܳܬܳܢܳܐ ܕܰܢܦܰܠ ܐܰܝܟ݂ ܒܰܪܩܳܐ ܡܶܢ ܫܡܰܝܳܐ",
            lt11: "",
            dt1: "Ich sah den Satan wie ein Blitz vom Himmel fallen"
        },
        {
            sy5: "ܒܪܰܡ",
            id: "955",
            sy4: "ܒܪܡ",
            sy9: "",
            sy7: "",
            lt11: "",
            dt3: "",
            lt12: "",
            lt10: "bram",
            dt2: "",
            dt1: "drehen",
            sy8: "",
            sy6: ""
        },
        {
            lt11: "",
            sy9: "",
            sy6: "",
            lt12: "",
            lt10: "ĉumro yarikho",
            dt2: "langes Leben",
            id: "1033",
            sy7: "",
            sy5: "ܥܽܘܡܪܳܐ ܝܰܪܝܺܟ݂ܳܐ",
            sy8: "",
            dt3: "",
            dt1: "Ein langes Leben",
            sy4: "ܥܘܡܪܐ ܝܪܝܟ݂ܐ"
        },
        {
            lt12: "",
            dt2: "",
            sy4: "ܝܪܚܐ ܕܥܒܪ",
            sy7: "",
            dt3: "",
            dt1: "vergangener Monat",
            sy8: "",
            lt11: "",
            sy9: "",
            lt10: "yarĥo daĉbar",
            sy5: "ܝܰܪܚܳܐ ܕܰܥܒܰܪ",
            id: "4730",
            sy6: ""
        },
        {
            sy8: "",
            sy6: "",
            dt2: "",
            lt11: "",
            sy4: "ܠܡܚܪ ܪܡܫܐ",
            lt10: "lamĥor ramsho",
            dt3: "",
            sy7: "",
            sy5: "ܠܰܡܚܳܪ ܪܰܡܫܳܐ",
            dt1: "morgen abend",
            lt12: "",
            sy9: "",
            id: "3106"
        },
        {
            sy9: "",
            dt2: "",
            dt1: "Ehelosigkeit",
            id: "1012",
            sy4: "ܠܐ ܡܙܘܓܘܬܐ",
            lt10: "lo mzaugutho",
            lt11: "",
            sy5: "ܠܳܐ ܡܙܰܘܓܽܘܬ݂ܳܐ",
            sy8: "",
            dt3: "",
            sy6: "",
            sy7: "",
            lt12: ""
        },
        {
            lt10: "aqar",
            sy5: "ܐܰܩܰܪ",
            dt3: "",
            dt2: "",
            sy8: "",
            sy7: "",
            sy4: "ܐܩܪ",
            sy6: "",
            dt1: "abkühlen lassen",
            id: "5578",
            lt11: "",
            sy9: "",
            lt12: ""
        },
        {
            sy4: "ܚܒܝܒܝ ܐܢܬ ܐܝܬܝܟ ܚܘܒܐ ܕܚܝܝ",
            lt12: "",
            dt2: "",
            lt10: "ĥabibi at ithayk ĥubo dĥayay",
            sy9: "",
            sy5: "ܚܰܒܺܝܒܺܝ ܐܰܢ̱ܬ ܐܺܝܬ݂ܰܝܟ ܚܽܘܒܳܐ ܕܚܰܝܰܝ",
            dt3: "",
            sy6: "",
            sy8: "",
            lt11: "",
            id: "5599",
            dt1: "Habibi du bist die Liebe meines Lebens",
            sy7: ""
        },
        {
            sy7: "",
            sy6: "",
            dt1: "Anfangszeichen",
            lt12: "",
            lt10: "yađĉo d`shuroyo",
            lt11: "",
            dt2: "Startzeichen",
            sy5: "ܝܰܕܥܳܐ ܕܫܽܘܪܳܝܳܐ",
            sy4: "ܝܕܥܐ ܕܫܘܪܝܐ",
            id: "5948",
            sy8: "",
            sy9: "",
            dt3: ""
        },
        {
            sy7: "",
            dt1: "Ebenbild Gottes",
            lt10: "feĥmo d`aloho",
            dt3: "",
            sy6: "",
            lt12: "",
            lt11: "",
            dt2: "",
            sy8: "",
            sy5: "ܦܶܚܡܳܐ ܕܰܐܠܳܗܳܐ",
            id: "989",
            sy9: "",
            sy4: "ܦܚܡܐ ܕܐܠܗܐ"
        },
        {
            sy5: "ܐܰܪܟܶܢ ܪܺܝܫܳܐ",
            lt10: "arken risho",
            sy7: "",
            dt1: "Verbeuge den Kopf",
            dt3: "",
            dt2: "",
            sy6: "",
            id: "4719",
            lt11: "",
            lt12: "",
            sy8: "",
            sy4: "ܐܪܟܢ ܪܝܫܐ",
            sy9: ""
        },
        {
            sy9: "",
            dt3: "Elischa",
            dt1: "Elischah",
            id: "1080",
            lt10: "ܐܠܝܫܥ",
            lt11: "ܐܠܝܫܥ",
            sy8: "",
            sy5: "ܐܶܠܺܝܫܳܥ",
            sy4: "ܐܠܝܫܥ",
            sy6: "ܐܠܝܫܥ",
            dt2: "Elisa",
            sy7: "ܐܶܠܺܝܫܰܥ",
            lt12: "ܐܠܝܫܥ"
        },
        {
            sy4: "ܨܝܕܐ ܕܢܘܢܐ",
            id: "1320",
            sy8: "",
            dt3: "",
            lt11: "",
            dt1: "Fischer",
            sy5: "ܨܰܝܳܕܳܐ ܕܢܽܘܢ̈ܶܐ",
            sy7: "",
            lt10: "ŝayodo d`nune",
            dt2: "Fischfänger",
            lt12: "",
            sy9: "",
            sy6: ""
        },
        {
            lt11: "",
            id: "1280",
            sy7: "",
            lt12: "",
            dt3: "",
            dt2: "Raspel",
            dt1: "Feil",
            sy8: "",
            sy4: "ܫܘܦܝܢܐ",
            sy9: "",
            sy5: "ܫܽܘܦܺܝܢܳܐ",
            lt10: "shufino",
            sy6: ""
        },
        {
            sy9: "",
            dt2: "Goliath",
            sy5: "ܓܽܘܠܝܰܕ",
            sy4: "ܓܘܠܝܕ",
            dt3: "Guljad",
            lt10: "ܓܘܠܝܕ",
            sy7: "",
            sy8: "",
            dt1: "Goliat",
            lt12: "",
            lt11: "ܓܘܠܝܕ",
            sy6: "",
            id: "1682"
        },
        {
            dt2: "morgens",
            lt10: "ŝafroyo",
            sy6: "ܨܦܪܝܬܐ",
            sy7: "ܨܰܦܪܳܝܬܳܐ",
            dt3: "",
            id: "203",
            sy8: "",
            dt1: "am Morgen",
            sy5: "ܨܰܦܪܳܝܳܐ",
            lt11: "safroyto",
            sy9: "",
            sy4: "ܨܦܪܝܐ",
            lt12: ""
        },
        {
            lt11: "ܒܪܡܬ݂ܐ",
            sy7: "",
            lt10: "ܒܪܡܬ݂ܐ",
            dt1: "Barmotho",
            sy8: "",
            lt12: "",
            sy5: "ܒܰܪܡܳܬ݂ܳܐ",
            sy6: "",
            id: "462",
            dt3: "",
            dt2: "",
            sy4: "ܒܪܡܬ݂ܐ",
            sy9: ""
        },
        {
            id: "3101",
            lt10: "yulfon ithiqun",
            sy6: "ܡܡܠܠܘܬ ܐܝܬܝܩܘܢ",
            sy5: "ܝܽܘܠܦܳܢ ܐܺܝܬ݂ܺܝܩܽܘܢ",
            lt11: "mmalluth ithiqun",
            dt3: "",
            lt12: "",
            sy9: "",
            sy4: "ܝܘܠܦܢ ܐܝܬܝܩܘܢ",
            dt1: "Morallehre",
            sy8: "",
            sy7: "ܡܡܰܠܽܠܘܬ݂ ܐܺܝܬ݂ܺܝܩܽܘܢ",
            dt2: ""
        },
        {
            dt3: "",
            dt2: "",
            sy6: "",
            sy8: "",
            sy9: "",
            sy7: "",
            dt1: "Mor Philoksinos Hanna Dolabani",
            sy5: "ܡܳܪܝ̱ ܦܺܝܠܠܽܘܟܣܺܝܢܽܘܣ ܝܽܘܚܰܢܳܢ ܕܽܘܠܰܒܰܐܢܺܝ",
            id: "3096",
            lt10: "ܡܪܝ ܦܝܠܠܘܟܣܝܢܘܣ ܝܘܚܢܢ ܕܘܠܒܐܢܝ",
            lt11: "ܡܪܝ ܦܝܠܠܘܟܣܝܢܘܣ ܝܘܚܢܢ ܕܘܠܒܐܢܝ",
            sy4: "ܡܪܝ ܦܝܠܠܘܟܣܝܢܘܣ ܝܘܚܢܢ ܕܘܠܒܐܢܝ",
            lt12: ""
        },
        {
            dt2: "pachten",
            sy5: "ܐܶܓܰܪ",
            lt12: "",
            lt10: "egar",
            sy8: "",
            lt11: "nogur",
            sy7: "ܢܳܐܓܽܘܪ",
            dt3: "",
            id: "6387",
            sy6: "ܢܐܓܘܪ",
            sy4: "ܐܓܪ",
            sy9: "",
            dt1: "mieten"
        },
        {
            sy6: "",
            lt10: "nthoro d`saĉro",
            dt3: "",
            lt11: "",
            sy5: "ܢܬ݂ܳܪܳܐ ܕܣܰܥܪܳܐ",
            lt12: "",
            sy8: "",
            sy7: "",
            dt2: "",
            sy4: "ܢܬܪܐ ܕܣܥܪܐ",
            id: "1781",
            sy9: "",
            dt1: "Haarausfall"
        },
        {
            dt1: "Abwäscher",
            dt3: "",
            sy4: "ܠܚܘܝܐ",
            lt10: "loĥuyo",
            sy5: "ܠܳܚܽܘܝܳܐ",
            lt12: "",
            sy9: "",
            dt2: "",
            sy6: "ܡܫܝܓܢܐ",
            lt11: "mshiğono",
            sy8: "",
            id: "5694",
            sy7: "ܡܫܺܝܓ݂ܳܢܳܐ"
        },
        {
            lt11: "",
            dt1: "Erleuchtung",
            dt2: "",
            dt3: "",
            sy6: "",
            lt12: "",
            lt10: "nahirutho",
            sy9: "",
            sy8: "",
            sy5: "ܢܰܗܺܝܪܽܘܬ݂ܳܐ",
            sy7: "",
            id: "1155",
            sy4: "ܢܗܝܪܘܬܐ"
        },
        {
            sy8: "",
            lt12: "",
            dt1: "Räuberbande",
            dt3: "Bataillon",
            dt2: "Kompanie",
            sy9: "",
            sy7: "",
            sy4: "ܓܝܣܐ",
            sy5: "ܓܰܝܣܳܐ",
            sy6: "",
            id: "3620",
            lt10: "gayso",
            lt11: ""
        },
        {
            sy9: "",
            dt3: "",
            sy6: "ܢܘܪܐ ܝܩܕܬܐ",
            sy4: "ܢܘܪ ܝܩܕܐ",
            sy8: "",
            dt2: "",
            dt1: "brennendes Feuer",
            lt10: "nur yoqđo",
            sy7: "ܢܽܘܪܳܐ ܝܳܩܶܕܬ݂ܳܐ",
            id: "703",
            lt12: "",
            sy5: "ܢܽܘܪ ܝܳܩܕܳܐ",
            lt11: "nuro yoqeđtho"
        },
        {
            sy9: "",
            dt2: "",
            id: "3851",
            sy6: "",
            lt10: "soțono rĥaq lokh men",
            sy7: "",
            sy5: "ܣܳܛܳܢܳܐ ܪܚܰܩ ܠܳܟ݂ ܡܶܢܝ̱",
            lt12: "",
            sy4: "ܣܛܢܐ ܪܚܩ ܠܟ ܡܢܝ",
            sy8: "",
            lt11: "",
            dt1: "Satan weiche von mir",
            dt3: ""
        },
        {
            lt10: "nagoro",
            lt12: "",
            dt3: "",
            dt2: "Tischler",
            sy9: "",
            lt11: "",
            sy6: "",
            sy7: "",
            sy5: "ܢܰܓܳܪܳܐ",
            dt1: "Zimmermann",
            id: "5326",
            sy4: "ܢܓܪܐ",
            sy8: ""
        },
        {
            sy6: "",
            id: "1150",
            dt2: "Ergun",
            sy9: "",
            dt3: "",
            lt10: "ܐܪܓܘܢ",
            dt1: "Ergün",
            sy7: "",
            sy4: "ܐܪܓܘܢ",
            lt12: "",
            sy5: "ܐܶܪܓܽܘܢ",
            lt11: "ܐܪܓܘܢ",
            sy8: ""
        },
        {
            sy8: "",
            lt11: "",
            sy7: "",
            lt12: "",
            sy4: "ܡܕܪܫܬܐ ܕܪܩܕܐ",
            sy9: "",
            sy5: "ܡܰܕܪܰܫܬܐ ܕܪܶܩܕܳܐ",
            lt10: "madrashto d`reqđo",
            dt3: "",
            sy6: "",
            dt1: "Tanzschule",
            dt2: "",
            id: "4386"
        },
        {
            sy6: "",
            sy5: "ܫܰܦܝܰܬ",
            lt12: "",
            id: "3873",
            dt1: "Schafyat",
            lt10: "ܫܦܝܬ",
            dt2: "",
            sy8: "",
            lt11: "ܫܦܝܬ",
            sy7: "",
            dt3: "",
            sy9: "",
            sy4: "ܫܦܝܬ"
        },
        {
            dt1: "Zehnjähriger",
            sy9: "",
            dt2: "",
            sy4: "ܒܪ ܥܣܪ ܫܢܝܢ",
            lt12: "",
            sy8: "",
            id: "6266",
            lt10: "bar ĉsar shnin",
            lt11: "",
            dt3: "",
            sy7: "",
            sy5: "ܒܰܪ ܥܣܰܪ ܫܢܺܝ̈ܢ",
            sy6: ""
        },
        {
            sy8: "",
            id: "315",
            lt10: "ܐܣܝܪ",
            sy9: "",
            dt2: "",
            sy7: "",
            dt3: "",
            lt11: "ܐܣܝܪ",
            dt1: "Assir",
            sy4: "ܐܣܝܪ",
            lt12: "",
            sy6: "",
            sy5: "ܐܰܣܺܝܪ"
        },
        {
            sy6: "ܡܫܝܓܐ",
            sy9: "",
            sy8: "",
            sy5: "ܡܫܺܝܓ݂",
            id: "4997",
            lt11: "mshiğo",
            lt10: "mshiğ",
            dt2: "",
            lt12: "",
            sy4: "ܡܫܝܓ",
            sy7: "ܡܫܺܝܓ݂ܳܐ",
            dt1: "waschen",
            dt3: ""
        },
        {
            sy7: "",
            sy6: "",
            dt1: "gleichmachen",
            sy8: "",
            lt10: "shwo",
            dt3: "",
            id: "1659",
            sy9: "",
            dt2: "gleichstellen",
            lt11: "",
            sy4: "ܫܘܐ",
            lt12: "",
            sy5: "ܫܘܳܐ"
        },
        {
            sy8: "",
            dt1: "ehrlich",
            sy5: "ܫܰܪܺܝܪܳܐ",
            sy6: "",
            dt2: "",
            lt11: "",
            id: "1025",
            lt12: "",
            sy7: "",
            lt10: "shariro",
            dt3: "",
            sy4: "ܫܪܝܪܐ",
            sy9: ""
        },
        {
            sy6: "",
            lt11: "",
            lt10: "shoĥwotho",
            sy8: "",
            lt12: "",
            dt3: "",
            sy7: "",
            id: "125",
            sy9: "",
            dt2: "",
            dt1: "Achseln",
            sy4: "ܫܚܘܬܐ",
            sy5: "ܫܳܚ̈ܘܳܬ݂ܳܐ"
        },
        {
            lt12: "",
            sy5: "ܫܰܦܺܝܪ",
            sy7: "",
            dt1: "Schafir",
            id: "3871",
            dt2: "",
            sy8: "",
            lt10: "ܫܦܝܪ",
            dt3: "",
            sy4: "ܫܦܝܪ",
            sy6: "",
            sy9: "",
            lt11: "ܫܦܝܪ"
        },
        {
            id: "1543",
            sy9: "",
            lt12: "",
            sy8: "",
            lt10: "rathiĥo",
            dt3: "",
            sy7: "ܫܠܺܝܩܳܐ",
            lt11: "shliqo",
            sy5: "ܪܰܬ݂ܺܝܚܳܐ",
            sy6: "ܫܠܝܩܐ",
            sy4: "ܪܬܝܚܐ",
            dt1: "gekocht",
            dt2: ""
        },
        {
            lt10: "shafyo",
            sy5: "ܫܰܦܝܳܐ",
            sy9: "",
            dt3: "",
            sy4: "ܫܦܝܐ",
            id: "3193",
            dt2: "",
            dt1: "naiv",
            lt11: "",
            lt12: "",
            sy7: "",
            sy8: "",
            sy6: ""
        },
        {
            lt12: "",
            dt2: "",
            id: "6223",
            sy8: "",
            dt1: "Seilsprung",
            sy5: "ܫܘܳܪܳܐ ܕܚܰܒܠܳܐ",
            sy9: "",
            sy6: "",
            lt10: "shworo d'ĥablo",
            lt11: "",
            sy4: "ܫܘܪܐ ܕܚܒܠܐ",
            dt3: "",
            sy7: ""
        },
        {
            dt3: "etwas",
            sy4: "ܡܕܡ",
            dt2: "Sache",
            dt1: "Ding",
            sy7: "",
            lt11: "",
            id: "897",
            sy5: "ܡܶܕܶܡ",
            sy8: "",
            lt10: "medem",
            sy6: "",
            lt12: "",
            sy9: ""
        },
        {
            lt12: "",
            dt2: "",
            lt11: "tațlilo shoruĉo",
            sy6: "ܬܛܠܝܠܐ ܫܪܘܥܐ",
            sy4: "ܟܘܬܐ ܫܪܘܥܬܐ",
            dt1: "Schiebedach",
            sy5: "ܟܰܘܬ݂ܳܐ ܫܳܪܽܘܥܬܳܐ",
            lt10: "kautho shoruĉto",
            id: "3935",
            sy9: "",
            dt3: "",
            sy7: "ܬܰܛܠܺܝܠܳܐ ܫܳܪܽܘܥܳܐ",
            sy8: ""
        },
        {
            sy6: "",
            sy8: "",
            dt3: "",
            sy5: "ܬܰܘܕܐܝܺܠ",
            lt12: "",
            dt1: "Taudil",
            sy4: "ܬܘܕܐܝܠ",
            sy9: "",
            id: "4399",
            lt11: "ܬܘܕܐܝܠ",
            dt2: "",
            sy7: "",
            lt10: "ܬܘܕܐܝܠ"
        },
        {
            dt1: "jährlich",
            lt11: "shno ba`shno",
            sy7: "ܫܢܳܐ ܒܰܫܢܳܐ",
            sy8: "",
            id: "2280",
            sy9: "",
            lt10: "kul shato",
            sy5: "ܟܽܠ ܫܰܢ̱ܬܳܐ",
            sy6: "ܫܢܐ ܒܫܢܐ",
            dt3: "",
            sy4: "ܟܠ ܫܢܬܐ",
            dt2: "",
            lt12: ""
        },
        {
            lt11: "ܐܪܢܐܣܝܐ",
            dt1: "Arnasia",
            lt10: "ܐܪܢܐܣܝܐ",
            sy9: "",
            dt2: "Arnasja",
            dt3: "",
            sy4: "ܐܪܢܐܣܝܐ",
            lt12: "",
            sy6: "",
            sy5: "ܐܰܪܢܰܐܣܺܝܰܐ",
            sy8: "",
            sy7: "",
            id: "298"
        },
        {
            lt12: "",
            sy9: "",
            lt10: "ĥabre",
            sy4: "ܚܒܪܐ",
            dt2: "",
            lt11: "",
            sy6: "",
            sy8: "",
            id: "1393",
            sy7: "",
            dt3: "",
            dt1: "Freunde",
            sy5: "ܚܰܒܪ̈ܐ"
        },
        {
            sy6: "",
            lt10: "ܕܟܝܐ",
            sy8: "",
            id: "787",
            dt2: "",
            lt12: "",
            sy4: "ܕܟܝܐ",
            dt3: "",
            lt11: "ܕܟܝܐ",
            sy9: "",
            dt1: "Dakya",
            sy7: "",
            sy5: "ܕܰܟܝܰܐ"
        },
        {
            dt3: "",
            lt10: "ferĉun",
            id: "3459",
            dt2: "",
            lt12: "",
            sy4: "ܦܪܥܘܢ",
            sy5: "ܦܶܪܥܽܘܢ",
            sy7: "",
            dt1: "Pharao",
            sy8: "",
            sy6: "",
            lt11: "",
            sy9: ""
        },
        {
            lt12: "mshosho",
            sy6: "ܡܝܫܐ",
            dt3: "",
            sy9: "ܡܫܳܫܳܐ",
            dt2: "Berührung",
            sy4: "ܡܝܘܫܘܬܐ",
            sy8: "ܡܫܫܐ",
            sy5: "ܡܳܝܽܘܫܽܘܬ݂ܳܐ",
            lt11: "myosho",
            sy7: "ܡܝܳܫܳܐ",
            id: "5950",
            lt10: "moyushutho",
            dt1: "Anfassung"
        },
        {
            sy9: "",
            lt11: "",
            sy5: "ܠܶܒܢܳܢܳܝܳܐ",
            dt1: "Libanese",
            sy7: "",
            sy8: "",
            dt2: "",
            dt3: "",
            sy6: "",
            lt12: "",
            id: "2800",
            sy4: "ܠܒܢܢܝܐ",
            lt10: "lebnonoyo"
        },
        {
            dt2: "",
            sy7: "",
            sy4: "ܟܠܡܕܡ",
            id: "186",
            sy5: "ܟܽܠܡܶܕܶܡ",
            lt12: "",
            lt10: "kulmedem",
            sy6: "",
            dt3: "",
            sy8: "",
            sy9: "",
            lt11: "",
            dt1: "alles"
        },
        {
            sy4: "ܟܘܠ",
            sy5: "ܟܽܘܠ",
            id: "4879",
            sy9: "",
            sy8: "",
            lt12: "",
            sy7: "ܟܽܘܠܳܢܳܐܝܺܬ݂",
            dt3: "",
            sy6: "ܟܘܠܢܐܝܬ",
            lt10: "kul",
            dt2: "",
            lt11: "kulonoith",
            dt1: "vollständig"
        },
        {
            dt1: "Auswanderung",
            sy8: "",
            dt3: "Emigration",
            sy4: "ܓܠܘܬܐ",
            dt2: "Exil",
            sy5: "ܓܳܠܽܘܬ݂ܳܐ",
            sy6: "ܬܘܬܒܘܬܐ",
            sy7: "ܬܰܘܬܳܒܽܘܬ݂ܳܐ",
            sy9: "",
            lt11: "tautobutho",
            lt12: "",
            lt10: "golutho",
            id: "395"
        },
        {
            sy4: "ܫܩܝܦܝܐ",
            lt12: "",
            sy6: "",
            sy5: "ܫܩܺܝܦܳܝܳܐ",
            lt10: "shqifoyo",
            id: "5676",
            dt3: "",
            sy7: "",
            sy9: "",
            lt11: "",
            dt1: "abstrus",
            sy8: "",
            dt2: ""
        },
        {
            lt10: "gauno zarqo akh yamo",
            sy4: "ܓܘܢܐ ܙܪܩܐ ܐܝܟ ܝܡܐ",
            sy5: "ܓܰܘܢܳܐ ܙܰܪܩܳܐ ܐܝܟ ܝܰܡܳܐ",
            dt3: "",
            dt2: "",
            sy9: "",
            dt1: "blaue Farbe wie das Meer",
            lt11: "",
            id: "644",
            sy6: "",
            lt12: "",
            sy7: "",
            sy8: ""
        },
        {
            lt11: "",
            sy6: "",
            sy9: "",
            dt3: "",
            sy7: "",
            dt2: "Fleiss",
            lt10: "kushoro",
            sy4: "ܟܘܫܪܐ",
            lt12: "",
            dt1: "Fleiß",
            sy5: "ܟܽܘܫܳܪܳܐ",
            id: "1330",
            sy8: ""
        },
        {
            id: "1802",
            sy9: "",
            dt3: "",
            sy4: "ܚܒܘܫܬܐ",
            lt11: "ܚܒܘܫܬܐ",
            lt12: "",
            sy6: "",
            dt2: "",
            dt1: "Habuschta",
            sy5: "ܚܰܒܽܘܫܬܰܐ",
            lt10: "ܚܒܘܫܬܐ",
            sy7: "",
            sy8: ""
        },
        {
            lt11: "manhar",
            dt2: "",
            dt3: "",
            sy9: "ܢܽܘܗܪܳܐ",
            lt12: "nuhro",
            dt1: "leuchten",
            lt10: "anhar",
            sy5: "ܐܰܢܗܰܪ",
            sy8: "ܢܘܗܪܐ",
            sy7: "ܡܰܢܗܰܪ",
            sy4: "ܐܢܗܪ",
            id: "2791",
            sy6: "ܡܢܗܪ"
        },
        {
            dt2: "Klugheit",
            sy8: "",
            sy9: "",
            dt1: "Intelligenz",
            lt11: "nahirutho",
            sy4: "ܚܟܝܡܘܬܐ",
            id: "2222",
            sy7: "ܢܰܗܺܝܪܽܘܬ݂ܳܐ",
            sy5: "ܚܰܟܺܝܡܽܘܬ݂ܳܐ",
            dt3: "",
            lt12: "",
            lt10: "ĥakimutho",
            sy6: "ܢܗܝܪܘܬܐ"
        },
        {
            lt11: "ç",
            sy5: "ܔ",
            sy8: "",
            dt1: "ç",
            sy9: "",
            lt12: "",
            lt10: "dsh",
            id: "755",
            sy6: "",
            sy7: "",
            dt2: "dsh",
            dt3: "",
            sy4: "ܓ"
        },
        {
            dt2: "Scheba",
            sy7: "ܫܶܒܰܥ",
            lt12: "ܫܒܐ",
            id: "3915",
            dt3: "",
            sy5: "ܫܶܒܳܐ",
            lt11: "ܫܒܐ",
            sy4: "ܫܒܐ",
            sy6: "ܫܒܥ",
            dt1: "Schebo",
            sy9: "",
            sy8: "",
            lt10: "ܫܒܐ"
        },
        {
            sy7: "ܬܰܩܕܳܐ",
            sy5: "ܫܰܒܛܳܐ",
            sy4: "ܫܒܛܐ",
            id: "5308",
            sy8: "ܡܐܩܝܪ",
            lt10: "shabțo",
            sy9: "ܡܰܐܩܺܝܪ",
            lt11: "taqdo",
            dt2: "",
            sy6: "ܬܩܕܐ",
            dt3: "",
            dt1: "Zepter",
            lt12: "maqir"
        },
        {
            id: "5151",
            sy9: "",
            lt12: "",
            sy5: "ܡܶܢ ܐܰܝܟܳܐ",
            sy7: "ܐܰܝܡܶܟܳܐ",
            sy6: "ܐܝܡܟܐ",
            dt2: "",
            lt11: "aymeko",
            dt3: "",
            sy4: "ܡܢ ܐܝܟܐ",
            lt10: "men ayko",
            sy8: "",
            dt1: "woher"
        },
        {
            lt10: "rab roĉawotho",
            dt2: "",
            lt11: "",
            sy6: "",
            sy4: "ܪܒ ܪܥܘܬܐ",
            dt1: "Oberhirte",
            lt12: "",
            dt3: "",
            sy9: "",
            sy7: "",
            sy5: "ܪܰܒ ܪܳܥܰܘܳܬ݂ܳܐ",
            sy8: "",
            id: "6135"
        },
        {
            lt10: "ܐܚܝܩܡ",
            sy9: "",
            sy5: "ܐܰܚܺܝܩܰܡ",
            dt2: "Ahiqam",
            lt11: "ܐܚܝܩܡ",
            sy6: "",
            lt12: "",
            sy4: "ܐܚܝܩܡ",
            sy8: "",
            dt1: "Ahikam",
            id: "155",
            dt3: "",
            sy7: ""
        },
        {
            sy9: "",
            sy6: "ܫܢܝ",
            sy4: "ܓܠܘܝ",
            dt3: "",
            sy7: "ܫܰܢܺܝ",
            lt12: "",
            lt10: "galwi",
            dt1: "abwandern",
            sy8: "",
            dt2: "",
            id: "5692",
            lt11: "shani",
            sy5: "ܓܰܠܘܺܝ"
        },
        {
            lt10: "mashryo ĥroyo",
            sy5: "ܡܫܰܪܝܳܐ ܐ̱ܚܪܳܝܳܐ",
            sy4: "ܡܫܪܝܐ ܐܚܪܝܐ",
            lt11: "",
            sy7: "",
            dt2: "",
            sy6: "",
            id: "1104",
            sy9: "",
            dt1: "Endstation",
            lt12: "",
            dt3: "",
            sy8: ""
        },
        {
            sy5: "ܩܺܘܛܢܽܘܬ݂ܳܐ ܥܰܡܳܝܬܳܐ",
            sy6: "",
            id: "4860",
            dt1: "Volksrepublik",
            sy8: "",
            sy7: "",
            sy9: "",
            sy4: "ܩܘܛܢܘܬܐ ܥܡܝܬܐ",
            lt12: "",
            lt10: "quțnutho ĉamoyto",
            dt3: "",
            dt2: "",
            lt11: ""
        },
        {
            sy9: "",
            id: "3224",
            lt10: "ܢܝܠܐ",
            dt3: "",
            lt12: "ܢܐܝܠܐ",
            sy5: "ܢܐܝܠܐ",
            sy4: "ܢܝܠܐ",
            sy8: "",
            sy7: "ܢܰܐܝܠܰܐ",
            dt2: "",
            dt1: "Nayla",
            sy6: "ܢܐܝܠܐ",
            lt11: "ܢܝܠܐ"
        },
        {
            dt3: "",
            sy6: "",
            lt11: "ܕܘܫܪܐ",
            dt2: "",
            sy8: "",
            sy7: "",
            id: "984",
            lt10: "ܕܘܫܪܐ",
            sy5: "ܕܽܘܫܪܳܐ",
            dt1: "Duschro",
            sy4: "ܕܘܫܪܐ",
            sy9: "",
            lt12: ""
        },
        {
            lt10: "ܟܐܢܬܐ",
            lt12: "",
            lt11: "ܟܐܢܬܐ",
            sy6: "",
            sy8: "",
            dt2: "",
            sy5: "ܟܺܐܢܬܰܐ",
            id: "2486",
            sy9: "",
            dt3: "",
            sy4: "ܟܐܢܬܐ",
            sy7: "",
            dt1: "Kinta"
        },
        {
            dt3: "",
            sy6: "",
            sy5: "ܠܳܐ ܡܶܬ݂ܚܰܙܝܳܢܳܐ",
            sy4: "ܠܐ ܡܬܚܙܝܢܐ",
            sy9: "",
            lt12: "",
            id: "4657",
            dt2: "",
            lt10: "lo methĥazyono",
            sy8: "",
            lt11: "",
            sy7: "",
            dt1: "unsichtbar"
        },
        {
            lt11: "",
            lt12: "",
            sy9: "",
            dt3: "",
            sy5: "ܝ",
            sy7: "",
            sy6: "",
            sy4: "ܝ",
            dt2: "y",
            lt10: "yuth",
            dt1: "j",
            id: "2259",
            sy8: ""
        },
        {
            sy5: "ܣܰܬܰܪ ܠܗܳܠܶܢ ܕܪܳܚܶܡ ܐܢܳܐ",
            dt3: "",
            sy9: "",
            sy6: "",
            lt12: "",
            dt1: "Beschütze die die ich Liebe",
            id: "5461",
            sy7: "",
            sy8: "",
            dt2: "",
            lt10: "satar lholen droĥem no",
            sy4: "ܣܰܬܰܪ ܠܗܳܠܶܢ ܕܪܳܚܶܡ ܐܢܳܐ",
            lt11: ""
        },
        {
            sy8: "",
            lt12: "",
            dt3: "",
            sy9: "",
            sy6: "",
            dt1: "Flugzeug",
            lt11: "",
            dt2: "",
            sy7: "",
            lt10: "țayosto",
            id: "1345",
            sy4: "ܛܝܣܬܐ",
            sy5: "ܛܰܝܳܣܬܳܐ"
        },
        {
            lt11: "gulbo",
            sy5: "ܓܳܨܽܘܨܳܐ",
            dt3: "",
            sy9: "",
            dt2: "",
            sy4: "ܓܨܘܨܐ",
            dt1: "Erbse",
            id: "1134",
            sy6: "ܓܘܠܒܐ",
            sy7: "ܓܽܘܠܒܳܐ",
            lt10: "goŝuŝo",
            sy8: "",
            lt12: ""
        },
        {
            sy6: "",
            sy8: "",
            sy9: "",
            sy4: "ܬܫܥܝܬ ܚܘܒܐ",
            lt12: "",
            dt1: "Liebesgeschichte",
            id: "2817",
            dt2: "",
            sy5: "ܬܰܫܥܺܝܬ݂ ܚܽܘܒܳܐ",
            lt11: "",
            sy7: "",
            lt10: "tashĉith ĥubo",
            dt3: ""
        },
        {
            lt12: "",
            lt10: "debo",
            sy5: "ܕܶܒܳܐ",
            sy8: "",
            dt2: "",
            sy9: "",
            sy4: "ܕܒܐ",
            dt3: "",
            sy6: "",
            dt1: "Bär",
            sy7: "",
            id: "435",
            lt11: ""
        },
        {
            id: "2883",
            lt12: "",
            dt1: "Mädchenname",
            sy8: "",
            lt10: "shem țlitho",
            sy9: "",
            sy7: "",
            lt11: "",
            sy5: "ܫܶܡ ܛܠܺܝܬ݂ܳܐ",
            sy6: "",
            dt3: "",
            dt2: "",
            sy4: "ܫܡ ܛܠܝܬܐ"
        },
        {
            sy5: "ܟܝܳܢܳܐ",
            lt12: "",
            dt1: "Natur",
            sy7: "",
            sy6: "",
            lt11: "",
            sy4: "ܟܝܢܐ",
            sy9: "",
            dt3: "",
            sy8: "",
            id: "3220",
            lt10: "kyono",
            dt2: ""
        },
        {
            lt11: "",
            lt10: "Malakho u soturo dil",
            sy5: "ܡܰܠܰܐܟܳܐ ܘܣܳܬܽܘܪܳܐ ܕܺܝܠܝ",
            dt3: "",
            sy8: "",
            dt2: "",
            sy9: "",
            dt1: "Mein Engel und Mein Beschützer",
            lt12: "",
            id: "5512",
            sy4: "ܡܠܐܟܐ ܘܣܬܘܪܐ ܕܝܠܝ",
            sy7: "",
            sy6: ""
        },
        {
            sy5: "ܐܰܠܳܗܳܐ ܨܳܒܶܗ",
            lt12: "",
            sy4: "ܐܠܗܐ ܨܒܗ",
            lt11: "aloho d'ŝobe",
            sy7: "ܐܰܠܳܗܳܐ ܕܨܳܒܶܗ",
            sy8: "",
            lt10: "aloho ŝobe",
            dt1: "So Gott will",
            sy9: "",
            sy6: "ܐܰܠܳܗܳܐ ܕܨܳܒܶܗ",
            dt3: "",
            id: "4175",
            dt2: "Wenn Gott will"
        },
        {
            sy7: "ܐܰܪܒܰܥܶܣ̈ܪܶܐ",
            sy4: "ܐܪܒܥܣܪ",
            lt10: "arbaĉsar",
            lt12: "juth-dolađ",
            lt11: "arbaĉesre",
            dt2: "Vierzehn",
            dt3: "",
            sy8: "ܝܕ",
            dt1: "14",
            sy9: "ܝܕ",
            id: "15",
            sy6: "ܐܪܒܥܣܪܐ",
            sy5: "ܐܰܪܒܰܥܣܰܪ "
        },
        {
            lt12: "",
            id: "830",
            dt3: "",
            sy6: "",
            sy4: "ܣܝܩܘܡܐ",
            dt1: "Datum",
            sy5: "ܣܺܝܩܽܘܡܳܐ",
            lt11: "",
            lt10: "siqumo",
            sy8: "",
            sy9: "",
            dt2: "",
            sy7: ""
        },
        {
            sy5: "ܩܳܢܽܘܢܳܐ ܕܕܰܝܪܳܐ",
            dt3: "",
            sy7: "",
            id: "2536",
            dt2: "",
            sy6: "",
            lt12: "",
            lt10: "qonuno d`dayro",
            sy9: "",
            sy4: "ܩܢܘܢܐ ܕܕܝܪܐ",
            lt11: "",
            dt1: "Klosterregeln",
            sy8: ""
        },
        {
            dt3: "",
            id: "1823",
            dt1: "Hahija",
            sy7: "",
            sy5: "ܚܰܒܚܺܝ",
            lt11: "ܚܐܚܝܐ",
            sy4: "ܚܐܚܝܐ",
            dt2: "",
            lt10: "ܚܐܚܝܐ",
            sy8: "",
            sy9: "",
            lt12: "",
            sy6: ""
        },
        {
            lt10: "makina d`manoyutho",
            sy9: "",
            dt1: "Zählergerät",
            sy8: "",
            sy7: "",
            id: "5230",
            dt2: "Zählermaschine",
            lt12: "",
            sy6: "",
            dt3: "",
            sy4: "ܡܐܟܢܐ ܕܡܢܝܘܬܐ",
            sy5: "ܡܰܐܟܺܢܰܐ ܕܡܰܢܳܝܽܘܬ݂ܳܐ",
            lt11: ""
        },
        {
            id: "6059",
            sy5: "ܦܰܪܳܣܳܐ",
            dt3: "",
            dt2: "",
            dt1: "Herausgeber",
            lt10: "faroso",
            sy7: "",
            sy8: "",
            lt12: "",
            sy4: "ܦܪܣܐ",
            sy6: "",
            sy9: "",
            lt11: ""
        },
        {
            sy6: "",
            dt2: "Schwachheit",
            dt3: "",
            lt12: "",
            sy8: "",
            sy4: "ܡܚܝܠܘܬܐ",
            id: "4061",
            dt1: "Schwäche",
            sy5: "ܡܚܺܝܠܽܘܬ݂ܳܐ",
            lt11: "",
            sy9: "",
            sy7: "",
            lt10: "mĥilutho"
        },
        {
            dt3: "",
            id: "1016",
            sy7: "",
            sy6: "",
            lt11: "",
            sy8: "",
            sy5: "ܫܽܘܒܚܳܐ ܠܳܟ݂",
            lt12: "",
            sy9: "",
            sy4: "ܫܘܒܚܐ ܠܟ",
            dt1: "Ehre sei Dir",
            dt2: "",
            lt10: "shubĥo lokh"
        },
        {
            sy9: "",
            lt10: "shuĥlof farshağno",
            dt3: "",
            sy4: "ܫܘܚܠܦ ܦܪܫܓܢܐ",
            id: "137",
            sy6: "",
            dt2: "",
            lt11: "",
            sy5: "ܫܽܘܚܠܳܦ ܦܰܪܫܰܓ݂ܢܳܐ",
            sy7: "",
            dt1: "Adressänderung",
            lt12: "",
            sy8: ""
        },
        {
            sy9: "",
            lt12: "",
            dt3: "",
            sy8: "",
            sy6: "",
            sy7: "",
            sy5: "ܕܠܳܐ ܩܶܛܪܳܐ",
            dt1: "problemlos",
            sy4: "ܕܠܐ ܩܛܪܐ",
            dt2: "",
            lt11: "",
            lt10: "d`lo qețro",
            id: "3519"
        },
        {
            lt10: "ܦܝܠܕܫ",
            sy5: "ܦܺܝܠܰܕܫ",
            dt2: "",
            sy8: "",
            sy9: "",
            sy7: "",
            dt3: "",
            lt12: "",
            sy4: "ܦܝܠܕܫ",
            sy6: "",
            id: "3461",
            dt1: "Pildasch",
            lt11: "ܦܝܠܕܫ"
        },
        {
            dt3: "",
            id: "1225",
            dt2: "Fabrikgebäude",
            sy6: "",
            sy8: "",
            sy4: "ܒܢܝܢ ܒܝܬ ܦܘܠܚܢܐ",
            sy7: "",
            sy5: "ܒܶܢܝܳܢ ܒܶܝܬ݂ ܦܽܘܠܚܳܢܳܐ",
            lt10: "benyon beth fulĥono",
            sy9: "",
            lt12: "",
            lt11: "",
            dt1: "Fabrikbau"
        },
        {
            sy6: "",
            sy8: "",
            lt12: "",
            lt10: "meso da'mđito",
            sy9: "",
            dt1: "Stadtmitte",
            dt2: "Stadtzentrum",
            dt3: "",
            id: "6336",
            sy5: "ܡܶܣܳܐ ܕܰܡܕܺܝܢ̱ܬܳܐ",
            sy7: "",
            lt11: "",
            sy4: "ܡܣܐ ܕܡܕܝܢܬܐ"
        },
        {
            lt12: "",
            sy9: "",
            dt2: "",
            sy7: "",
            id: "2128",
            dt1: "ich lüge nicht",
            sy4: "ܠܐ ܟܡܕܓܠ ܐܢܐ",
            lt10: "lo komdagal no",
            sy8: "",
            sy6: "",
            sy5: "ܠܳܐ ܟܡܕܓܠ ܐ̱ܢܳܐ",
            lt11: "",
            dt3: ""
        },
        {
            sy4: "ܙܝܥܐ",
            dt3: "Feigling",
            sy7: "",
            lt12: "",
            lt10: "sayoĉo",
            dt2: "Ängstlicher",
            sy9: "",
            sy5: "ܙܰܝܳܥܳܐ",
            dt1: "Feiger",
            lt11: "",
            id: "1279",
            sy8: "",
            sy6: ""
        },
        {
            sy6: "",
            id: "3458",
            sy7: "",
            lt12: "",
            sy9: "",
            dt2: "Fürsorger",
            lt11: "",
            dt3: "",
            dt1: "Pfleger",
            sy4: "ܝܨܝܦܐ",
            sy8: "",
            lt10: "yaŝifo",
            sy5: "ܝܰܨܺܝܦܳܐ"
        },
        {
            sy8: "",
            sy4: "ܟܪܬܐ",
            sy5: "ܟܪܳܬ݂ܳܐ",
            sy7: "",
            id: "834",
            sy9: "",
            lt12: "",
            dt3: "",
            lt10: "krotho",
            dt2: "",
            lt11: "",
            dt1: "Daumen",
            sy6: ""
        },
        {
            dt3: "Vorschrift",
            sy9: "",
            lt12: "",
            lt11: "qonuno",
            dt2: "Regel",
            sy5: "ܢܳܡܽܘܣܳܐ",
            lt10: "nomuso",
            sy6: "ܩܢܘܢܐ",
            sy4: "ܢܡܘܣܐ",
            sy7: "ܩܳܢܽܘܢܳܐ",
            id: "1597",
            sy8: "",
            dt1: "Gesetz"
        },
        {
            sy7: "",
            dt1: "Adoniram",
            sy8: "",
            sy6: "",
            lt10: "ܐܕܘܢܝܪܡ",
            sy9: "",
            dt3: "",
            lt11: "ܐܕܘܢܝܪܡ",
            id: "136",
            lt12: "",
            sy4: "ܐܕܘܢܝܪܡ",
            dt2: "",
            sy5: "ܐܰܕܳܘܢܺܝܪܰܡ"
        },
        {
            lt10: "ܝܡܝܢ",
            dt3: "",
            lt11: "ܝܡܝܢ",
            dt2: "",
            sy7: "",
            sy9: "",
            sy8: "",
            sy6: "",
            lt12: "",
            id: "2284",
            sy4: "ܝܡܝܢ",
            dt1: "Jamin",
            sy5: "ܝܰܡܺܝܢ"
        },
        {
            sy6: "",
            sy7: "",
            lt10: "mbaryutho",
            dt2: "",
            dt3: "",
            sy4: "ܡܒܪܝܘܬܐ",
            sy9: "",
            dt1: "Abstraktion",
            lt12: "",
            sy8: "",
            id: "5674",
            sy5: "ܡܒܰܪܝܽܘܬ݂ܳܐ",
            lt11: ""
        },
        {
            lt11: "",
            sy5: "ܡܶܐܙܠܳܐ ܘ ܡܶܐܬ݂ܝܳܐ",
            sy8: "",
            lt10: "meslo u methyo",
            id: "2006",
            dt3: "",
            sy9: "",
            dt2: "",
            sy6: "",
            sy7: "",
            dt1: "Hin und Rückfahrt",
            sy4: "ܡܐܙܠܐ ܩ ܡܐܬܝܐ",
            lt12: ""
        },
        {
            lt12: "",
            sy4: "ܦܪܣ",
            sy6: "",
            dt3: "",
            dt1: "Persien",
            sy9: "",
            sy8: "",
            id: "3435",
            dt2: "Iran",
            sy5: "ܦܳܪܶܣ",
            sy7: "",
            lt10: "fores",
            lt11: ""
        },
        {
            dt2: "",
            id: "3484",
            sy6: "ܡܫܪܝܐ ܕܕܚܫܐ",
            sy9: "",
            sy7: "ܡܰܫܪܝܳܐ ܕܰܕܰܚ̈ܫܶܐ",
            sy8: "",
            lt12: "",
            sy5: "ܢܽܘܩܰܙܬܳܐ ܕܰܕܰܚ̈ܫܶܐ",
            sy4: "ܢܘܩܙܬܐ ܕܕܚܫܐ",
            lt11: "mashryo da`daĥshe",
            lt10: "nuqazto da`daĥshe",
            dt3: "",
            dt1: "Polizeistation"
        },
        {
            sy8: "",
            sy5: "ܡܰܢܺܝܫܟܰܐ",
            lt11: "ܡܢܝܫܟܐ",
            sy4: "ܡܢܝܫܟܐ",
            sy6: "ܡܢܝܫܟܐ",
            dt2: "Manischko",
            lt10: "ܡܢܝܫܟܐ",
            dt3: "Mnischko",
            id: "2914",
            sy7: "ܡܰܢܺܝܫܟܳܐ",
            dt1: "Manischka",
            lt12: "ܡܢܝܫܟܐ",
            sy9: ""
        },
        {
            lt12: "",
            sy8: "",
            dt2: "",
            dt1: "Koran",
            sy7: "",
            sy4: "ܩܘܪܢ",
            sy9: "",
            sy5: "ܩܽܘܪܰܢ",
            dt3: "",
            sy6: "",
            lt11: "",
            id: "2608",
            lt10: "quran"
        },
        {
            sy4: "ܐܘ ܝܘܡܐ ܒܣܝܡܐ ܝܐ",
            lt11: "",
            sy6: "",
            sy8: "",
            sy9: "",
            dt3: "",
            lt10: "u yaumo bassimo yo",
            id: "jActjshs8Zs50NtD4hRs",
            "date": {
                "seconds": 1590848917,
                "nanoseconds": 440000000
            },
            lt12: "",
            sy7: "",
            dt1: "Der Tag ist schön",
            dt2: "",
            sy5: "ܐܽܘ ܝܰܘܡܳܐ ܒܰܣܺܝܡܳܐ ܝܳܐ"
        },
        {
            sy4: "ܡܪܘܬܐ",
            dt2: "",
            id: "2951",
            sy9: "",
            sy8: "",
            dt3: "",
            sy7: "",
            lt11: "ܡܪܘܬܐ",
            sy6: "",
            lt10: "ܡܪܘܬܐ",
            lt12: "",
            sy5: "ܡܰܪܘܬܰܐ",
            dt1: "Marutha"
        },
        {
            sy4: "ܐܫܘܪ",
            sy7: "",
            sy8: "",
            sy6: "",
            dt1: "Aschur",
            sy9: "",
            sy5: "ܐܰܫܽܘܪ",
            id: "314",
            lt10: "ܐܫܘܪ",
            lt12: "",
            lt11: "ܐܫܘܪ",
            dt2: "Ashur",
            dt3: "Assur"
        },
        {
            sy8: "",
            sy6: "",
            lt10: "guŝo",
            lt11: "",
            sy7: "",
            dt1: "Blutgerinsel",
            id: "664",
            sy4: "ܓܘܨܐ",
            dt2: "",
            sy5: "ܓܽܘܨܳܐ",
            dt3: "",
            lt12: "",
            sy9: ""
        },
        {
            sy6: "",
            dt3: "",
            sy7: "",
            lt11: "ܐܛܝܡ",
            sy5: "ܐܰܛܺܝܡ",
            lt12: "",
            sy4: "ܐܛܝܡ",
            sy8: "",
            dt2: "",
            sy9: "",
            lt10: "ܐܛܝܡ",
            dt1: "Atim",
            id: "329"
        },
        {
            dt2: "besprengen",
            sy8: "",
            lt12: "",
            sy5: "ܪܙܰܦ",
            sy9: "",
            dt1: "spritzen",
            lt11: "",
            sy6: "",
            id: "4225",
            sy7: "",
            lt10: "rzaf",
            dt3: "hervorsprudeln",
            sy4: "ܪܙܦ"
        },
        {
            lt11: "",
            dt2: "",
            lt12: "",
            sy4: "ܩܠܦܬܐ ܕܒܨܠܐ",
            dt3: "",
            sy5: "ܩܠܳܦܬܳܐ ܕܒܰܨܠܳܐ",
            sy9: "",
            sy7: "",
            dt1: "Zwiebelschale",
            sy8: "",
            lt10: "qlofto d`baŝlo",
            id: "5401",
            sy6: ""
        },
        {
            id: "4959",
            lt11: "",
            lt12: "",
            lt10: "shroro",
            sy6: "",
            sy9: "",
            dt3: "",
            sy7: "",
            dt1: "Wahrheit",
            sy5: "ܫܪܳܪܳܐ",
            dt2: "",
            sy4: "ܫܪܪܐ",
            sy8: ""
        },
        {
            lt10: "naĥto",
            sy5: "ܢܰܚܬܳܐ",
            dt3: "",
            sy6: "",
            lt12: "",
            sy8: "",
            dt2: "",
            sy9: "",
            lt11: "",
            dt1: "Kleid",
            id: "2508",
            sy4: "ܢܚܬܐ",
            sy7: ""
        },
        {
            lt11: "",
            sy9: "",
            lt10: "mshiĥoyto",
            dt3: "",
            dt1: "Christin",
            id: "771",
            dt2: "",
            sy5: "ܡܫܺܝܚܳܝܬܳܐ",
            sy4: "ܡܫܝܚܝܬܐ",
            lt12: "",
            sy7: "",
            sy8: "",
            sy6: ""
        },
        {
            sy5: "ܐܶܬ݂ܟܰܫܰܦ",
            lt11: "",
            dt2: "",
            sy8: "",
            sy7: "",
            lt10: "ethkashaf",
            dt1: "flehentlich ersuchen",
            dt3: "",
            id: "1327",
            sy4: "ܐܬܟܫܦ",
            lt12: "",
            sy6: "",
            sy9: ""
        },
        {
            sy5: "ܓܽܘܙܥܳܐ ܕܐܺܝܠܳܢܳܐ",
            dt1: "Baumstamm",
            sy4: "ܓܘܙܥܐ ܕܐܝܠܢܐ",
            dt2: "",
            sy6: "",
            sy8: "",
            lt10: "guzĉo d`ilono",
            lt12: "",
            dt3: "",
            id: "6393",
            sy7: "",
            lt11: "",
            sy9: ""
        },
        {
            dt2: "",
            sy5: "ܪܽܘܒܳܝܳܐ",
            lt12: "",
            lt10: "ruboyo",
            sy8: "",
            sy7: "ܪܶܒܺܝܬ݂ܳܐ",
            sy4: "ܪܘܒܝܐ",
            sy9: "",
            id: "5328",
            dt1: "Zins",
            sy6: "ܪܒܝܬܐ",
            dt3: "",
            lt11: "rebitho"
        },
        {
            sy5: "ܠܳܐ ܡܦܳܣ",
            sy4: "ܠܐ ܡܦܣ",
            lt11: "",
            sy7: "",
            lt10: "lo mfos",
            dt2: "",
            lt12: "",
            dt1: "tabu",
            id: "4356",
            sy9: "",
            sy8: "",
            sy6: "",
            dt3: ""
        },
        {
            dt3: "",
            sy6: "",
            "date": {
                "seconds": 1590838736,
                "nanoseconds": 213000000
            },
            lt12: "",
            lt10: "U shlomo d'aloho yo",
            lt11: "",
            id: "jHGQvlDNfLG5cxNBlE9H",
            sy9: "",
            sy4: "ܐܘ ܫܠܡܐ ܐܘ ܫܠܡܐ ܕܐܠܗܐ ܝܐ",
            sy7: "",
            sy8: "",
            dt2: "",
            dt1: "Ein Gruß ist Gottes Gruß",
            sy5: "ܐܽܘ ܫܠܳܡܳܐ ܐܽܘ ܫܠܳܡܳܐ ܕܰܐܠܳܗܳܐ ܝܳܐ"
        },
        {
            sy8: "",
            lt12: "",
            dt1: "Saado",
            dt2: "",
            sy4: "ܣܥܕܐ",
            sy9: "",
            id: "3780",
            sy5: "ܣܰܥܕܳܐ",
            sy6: "",
            sy7: "",
            lt10: "ܣܥܕܐ",
            lt11: "ܣܥܕܐ",
            dt3: ""
        },
        {
            sy9: "",
            sy5: "ܚܰܒܽܘܫܳܐ",
            dt3: "",
            dt2: "",
            lt12: "",
            sy7: "ܚܰܙܽܘܪܳܐ",
            id: "257",
            dt1: "Apfel",
            sy4: "ܚܒܘܫܐ",
            sy6: "ܚܙܘܪܐ",
            sy8: "",
            lt11: "ĥazuro",
            lt10: "ĥabusho"
        },
        {
            id: "2370",
            dt2: "",
            dt1: "k",
            lt10: "kof",
            sy5: "ܟ",
            sy7: "",
            sy6: "",
            lt11: "",
            sy9: "",
            dt3: "",
            lt12: "",
            sy4: "ܟ",
            sy8: ""
        },
        {
            dt1: "Krone",
            sy7: "ܚܽܘܕܳܐ",
            sy8: "",
            id: "2652",
            lt12: "",
            dt2: "",
            sy6: "ܚܘܕܐ",
            sy4: "ܬܓܐ",
            sy5: "ܬܳܓ݂ܳܐ",
            sy9: "",
            lt11: "ĥudo",
            dt3: "",
            lt10: "toğo"
        },
        {
            lt12: "",
            dt2: "",
            dt1: "stillen",
            id: "4273",
            sy4: "ܐܝܢܩ",
            sy5: "ܐܰܝܢܶܩ",
            sy8: "",
            sy6: "ܡܘܢܩ",
            sy7: "ܡܰܘܢܶܩ",
            lt10: "ayneq",
            lt11: "mauneq",
            sy9: "",
            dt3: ""
        },
        {
            dt2: "runterkommen",
            lt12: "",
            sy4: "ܢܚܬ",
            dt1: "runter",
            sy9: "",
            sy6: "",
            lt10: "noĥet",
            id: "3769",
            sy7: "",
            sy8: "",
            sy5: "ܢܳܚܶܬ",
            dt3: "",
            lt11: ""
        },
        {
            sy4: "ܡܢܐ",
            dt3: "",
            lt10: "mno",
            id: "5718",
            sy7: "ܚܫܰܒ",
            lt11: "ĥshab",
            sy5: "ܡܢܳܐ",
            sy8: "",
            dt1: "abzählen",
            sy9: "",
            lt12: "",
            dt2: "",
            sy6: "ܚܫܒ"
        },
        {
            sy8: "",
            dt2: "",
            sy5: "ܟܪܺܝܗ ܟܽܘܠܺܝܬ݂ܳܐ",
            dt1: "nierenkrank",
            lt12: "",
            sy4: "ܟܪܝܗ ܟܘܠܝܬܐ",
            lt11: "",
            lt10: "krih kulyotho",
            id: "3271",
            sy6: "",
            sy7: "",
            dt3: "",
            sy9: ""
        },
        {
            sy4: "ܐܠܢܬܢ",
            lt11: "ܐܠܢܬܢ",
            lt12: "",
            sy6: "",
            dt3: "",
            dt1: "Elnatan",
            sy8: "",
            sy9: "",
            dt2: "",
            id: "1086",
            sy7: "",
            sy5: "ܐܶܠܢܰܬܰܢ",
            lt10: "ܐܠܢܬܢ"
        },
        {
            sy4: "ܛܝܢܐ",
            lt11: "",
            sy9: "",
            sy5: "ܛܺܝܢܳܐ",
            id: "2739",
            sy7: "",
            sy8: "",
            lt10: "țino",
            sy6: "",
            lt12: "",
            dt3: "",
            dt2: "",
            dt1: "Lehm"
        },
        {
            sy9: "",
            dt2: "",
            sy6: "",
            sy8: "",
            lt12: "",
            dt3: "",
            id: "3336",
            lt11: "ܐܕܪ",
            sy4: "ܐܕܪ",
            lt10: "ܐܕܪ",
            sy5: "ܐܳܕܰܪ",
            dt1: "Odar",
            sy7: ""
        },
        {
            dt1: "Konjunktion",
            sy7: "ܐܶܣܳܪܳܐ",
            lt11: "esoro",
            dt3: "",
            sy4: "ܡܬܐܡܘܬܐ",
            sy8: "",
            sy5: "ܡܰܬܶܐܡܽܘܬ݂ܳܐ",
            sy9: "",
            dt2: "",
            sy6: "ܐܣܪܐ",
            lt12: "",
            lt10: "matemutho",
            id: "6073"
        },
        {
            dt3: "",
            dt1: "Schrank",
            sy6: "",
            sy9: "",
            sy5: "ܓܰܙܳܐ",
            lt12: "",
            sy7: "",
            id: "4022",
            lt10: "gazo",
            sy8: "",
            sy4: "ܓܙܐ",
            dt2: "",
            lt11: ""
        },
        {
            sy6: "ܕܘܫܐ",
            id: "2048",
            sy7: "ܕܰܘܫܳܐ",
            dt3: "",
            dt1: "Honig",
            lt10: "debsho",
            sy8: "",
            sy9: "",
            sy4: "ܕܒܫܐ",
            lt12: "",
            dt2: "",
            sy5: "ܕܶܒܫܳܐ",
            lt11: "dauwsho"
        },
        {
            id: "866",
            sy7: "",
            lt10: "ezal yolufo lbeth sefro",
            sy4: "ܐܙܠ ܝܠܘܦܐ ܠܒܝܬ ܣܦܪܐ",
            lt11: "",
            dt2: "",
            sy9: "",
            dt1: "Der Schüler kam von der Schule nach Hause",
            dt3: "",
            sy6: "",
            lt12: "",
            sy5: "ܐܶܙܰܠ ܝܳܠܽܘܦܳܐ ܠܒܶܝܬ݂ ܣܶܦܪܳܐ",
            sy8: ""
        },
        {
            sy5: "ܥܠܰܝܡܳܐ ܣܽܘܪܝܳܝܳܐ",
            sy9: "",
            sy6: "ܥܠܝܡܐ ܐܪܡܝܐ",
            dt1: "Aramäischer Jugendlicher",
            sy4: "ܥܠܝܡܐ ܣܘܪܝܝܐ",
            lt11: "ĉlaymo oromoyo",
            id: "273",
            dt3: "",
            lt12: "",
            sy7: "ܥܠܰܝܡܳܐ ܐܳܪܳܡܳܝܳܐ",
            lt10: "ĉlaymo suryoyo",
            dt2: "",
            sy8: ""
        },
        {
            sy7: "",
            sy8: "",
            lt11: "",
            dt2: "",
            sy9: "",
            dt3: "",
            sy5: "ܘܰܪܕܳܐ ܕܫܶܡܫܶܐ",
            lt12: "",
            id: "4195",
            sy4: "ܘܪܕܐ ܕܫܡܫܐ",
            sy6: "",
            dt1: "Sonnenblume",
            lt10: "wardo d`shemsho"
        },
        {
            dt1: "Konkurs",
            sy4: "ܡܓܪܕܝܘܬܐ",
            lt12: "",
            dt3: "",
            sy8: "",
            sy5: "ܡܓܰܪܕܝܽܘܬ݂ܳܐ",
            sy6: "",
            dt2: "",
            sy9: "",
            lt10: "mgardyutho",
            sy7: "",
            id: "6071",
            lt11: ""
        },
        {
            sy9: "",
            sy4: "ܡܢܦܩܐ",
            dt3: "",
            id: "1218",
            dt1: "Experte",
            lt11: "",
            lt12: "",
            sy5: "ܡܢܰܦܩܳܐ",
            sy8: "",
            dt2: "Spezialist",
            sy7: "",
            lt10: "mnafqo",
            sy6: ""
        },
        {
            sy7: "",
            lt12: "",
            sy9: "",
            sy4: "ܚܘܓܬܐ",
            dt1: "Kreis",
            dt3: "",
            sy6: "",
            dt2: "",
            id: "2641",
            lt10: "ĥugtho",
            lt11: "",
            sy5: "ܚܽܘܓܬ݂ܳܐ",
            sy8: ""
        },
        {
            lt11: "",
            sy7: "",
            lt12: "",
            sy6: "",
            dt3: "",
            lt10: "nkhilutho",
            sy5: "ܢܟ݂ܺܝܠܽܘܬ݂ܳܐ",
            dt1: "Betrug",
            id: "588",
            sy8: "",
            dt2: "",
            sy9: "",
            sy4: "ܢܟܝܠܘܬܐ"
        },
        {
            sy6: "ܥܝܪܘܬ ܗܘܢܐ",
            sy5: "ܡܰܪܢܺܝܬ݂ܳܐ",
            lt12: "ĉiruth nafsho",
            sy9: "ܥܺܝܪܽܘܬ݂ ܢܰܦܫܳܐ",
            id: "5736",
            lt10: "marnitho",
            dt2: "",
            sy4: "ܡܪܢܝܬܐ",
            lt11: "ĉiruth hauno",
            dt1: "Achtsamkeit",
            sy7: "ܥܺܝܪܽܘܬ݂ ܗܰܘܢܳܐ",
            dt3: "",
            sy8: "ܥܝܪܘܬ ܢܦܫܐ"
        },
        {
            lt12: "",
            dt2: "",
            sy4: "ܓ",
            sy5: "ܓ݂",
            id: "1445",
            sy8: "",
            lt11: "",
            sy7: "",
            sy9: "",
            sy6: "",
            lt10: "ğ",
            dt1: "ğ",
            dt3: ""
        },
        {
            sy8: "",
            lt12: "",
            sy7: "",
            lt11: "",
            dt2: "",
            lt10: "maliĥo",
            sy5: "ܡܰܠܺܝܚܳܐ",
            dt1: "versalzen",
            sy6: "",
            dt3: "",
            id: "4796",
            sy9: "",
            sy4: "ܡܠܝܚܐ"
        },
        {
            dt3: "",
            lt12: "",
            lt11: "neqbo d`ĥzoyo",
            lt10: "neqbo d`ĥyoro",
            id: "1760",
            dt1: "Guckloch",
            sy8: "",
            sy6: "ܢܩܒܐ ܕܚܙܝܐ",
            dt2: "",
            sy5: "ܢܶܩܒܳܐ ܕܚܝܳܪܳܐ",
            sy7: "ܢܶܩܒܳܐ ܕܚܙܳܝܳܐ",
            sy9: "",
            sy4: "ܢܩܒܐ ܕܚܝܪܐ"
        },
        {
            lt10: "shakofo",
            sy4: "ܫܟܦܐ",
            sy7: "ܫܟܰܦܳܝܳܐ",
            dt2: "Schuhmacher",
            lt12: "msonoyo",
            sy8: "ܡܣܐܢܝܐ",
            sy6: "ܫܟܦܝܐ",
            dt3: "",
            id: "4057",
            sy9: "ܡܣܳܐܢܳܝܳܐ",
            dt1: "Schuster",
            lt11: "shkafoyo",
            sy5: "ܫܰܟܳܦܳܐ"
        },
        {
            dt1: "wählerisch",
            sy7: "",
            sy4: "ܥܣܩܘܬ ܓܒܝܐ",
            lt10: "ĉasquth gboyo",
            sy6: "",
            dt2: "",
            lt11: "",
            sy8: "",
            sy5: "ܥܰܣܩܽܘܬ݂ ܓܒܳܝܳܐ",
            sy9: "",
            lt12: "",
            id: "4940",
            dt3: ""
        },
        {
            sy9: "",
            lt10: "mzawağto",
            dt3: "",
            id: "4746",
            lt12: "",
            sy8: "",
            sy4: "ܡܙܘܓܬܐ",
            sy7: "ܒܥܺܝܠܬܳܐ",
            sy6: "ܒܥܝܠܬܐ",
            dt1: "verheiratete Frau",
            dt2: "",
            lt11: "bĉilto",
            sy5: "ܡܙܰܘܰܓ݂ܬܳܐ"
        },
        {
            sy8: "",
            lt12: "",
            lt11: "",
            sy5: "ܓܶܕܫܳܐ",
            dt2: "Zwischenfall",
            lt10: "gedsho",
            sy7: "",
            id: "4643",
            sy6: "",
            sy4: "ܓܕܫܐ",
            dt1: "Unfall",
            dt3: "",
            sy9: ""
        },
        {
            lt10: "qțoyo",
            sy5: "ܩܛܳܝܳܐ",
            sy6: "ܬܗܠܠܬܐ",
            sy4: "ܩܛܝܐ",
            dt2: "Witz",
            sy7: "ܬܰܗܠܰܠܬܳܐ",
            sy9: "ܫܶܥܝܳܐ",
            sy8: "ܫܥܝܐ",
            lt12: "sheĉyo",
            id: "3929",
            dt1: "Scherz",
            lt11: "tahlalto",
            dt3: ""
        },
        {
            dt1: "Fünfeck",
            dt2: "Pentagon",
            dt3: "",
            lt11: "",
            id: "5472",
            lt12: "",
            lt10: "mĥamsho",
            sy9: "",
            sy8: "",
            sy5: "ܡܚܰܡܫܳܐ",
            sy7: "",
            sy6: "",
            sy4: "ܡܚܰܡܫܳܐ"
        },
        {
            sy9: "",
            lt11: "ܐܝܘܒ",
            sy4: "ܐܝܘܒ",
            sy7: "ܐܰܝܽܘܒ",
            dt3: "",
            dt1: "Ayub",
            sy8: "",
            sy5: "ܐܺܝܽܘܒ",
            lt12: "ܐܝܘܒ",
            lt10: "ܐܝܘܒ",
            sy6: "",
            dt2: "Ayyub",
            id: "406"
        },
        {
            lt12: "",
            sy5: "ܩ",
            dt3: "",
            sy4: "ܩ",
            sy6: "",
            sy9: "",
            sy7: "",
            lt11: "",
            dt2: "",
            id: "3557",
            sy8: "",
            dt1: "q",
            lt10: "qof"
        },
        {
            id: "3876",
            sy7: "ܫܰܟ݂ܠܽܘܦܰܐ",
            dt2: "",
            dt1: "Schahlupa",
            lt12: "ܫܟ݂ܠܘܦܐ",
            sy6: "ܫܟܠܘܦܐ",
            sy5: "ܫܰܚܠܽܘܦܰܐ",
            lt11: "ܫܚܠܘܦܐ",
            sy9: "",
            sy8: "",
            lt10: "ܫܚܠܘܦܐ",
            dt3: "",
            sy4: "ܫܚܠܘܦܐ"
        },
        {
            dt3: "Uterus",
            dt1: "Mutterleib",
            lt12: "",
            sy8: "",
            sy6: "ܟܪܣܐ ܕܐܡܐ",
            sy5: "ܟܰܪܣܳܐ",
            lt10: "karso",
            sy7: "ܟܰܪܣܳܐ ܕܐܶܡܳܐ",
            id: "3136",
            sy4: "ܟܪܣܐ",
            lt11: "karso d`emo",
            dt2: "Gebärmutter",
            sy9: ""
        },
        {
            sy5: "ܡܕܰܒܪܳܢܳܐ",
            sy9: "",
            dt1: "Vorsitzender",
            lt11: "rish mautbo",
            sy7: "ܪܺܝܫ ܡܰܘܬܒܳܐ",
            lt12: "",
            sy6: "ܪܝܫ ܡܘܬܒܐ",
            lt10: "mdabrono",
            id: "4907",
            dt3: "",
            sy8: "",
            sy4: "ܡܕܒܪܢܐ",
            dt2: ""
        },
        {
            lt10: "henun ith lhun ĥauĥe",
            sy6: "",
            dt1: "Sie haben Pfirsiche",
            dt2: "",
            id: "4144",
            dt3: "",
            lt12: "",
            sy5: "ܗܶܢܽܘܢ ܐܺܝܬ݂ ܠܗܽܘܢ ܚܰܘܚܳܐ",
            lt11: "",
            sy8: "",
            sy4: "ܗܢܘܢ ܐܝܬ ܠܗܘܢ ܚܘܚܐ",
            sy7: "",
            sy9: ""
        },
        {
            lt11: "",
            id: "794",
            lt10: "maskhoro",
            sy6: "",
            lt12: "",
            dt3: "",
            sy9: "",
            dt1: "Damm",
            sy8: "",
            dt2: "Staudamm",
            sy4: "ܡܣܟܪܐ",
            sy5: "ܡܰܣܟ݂ܳܪܳܐ",
            sy7: ""
        },
        {
            sy9: "",
            dt2: "",
            sy7: "",
            lt11: "",
            lt10: "bar ĥire",
            dt3: "",
            sy6: "",
            lt12: "",
            dt1: "Ritter",
            sy5: "ܒܰܪ ܚܺܐܪܶܐ",
            sy4: "ܒܪ ܚܐܪܐ",
            id: "3728",
            sy8: ""
        },
        {
            lt12: "",
            lt10: "yođaĉ",
            sy8: "",
            lt11: "yolef",
            dt3: "kennen",
            sy4: "ܝܕܥ",
            sy6: "ܝܠܦ",
            dt1: "kennenlernen",
            sy5: "ܝܳܕܰܥ",
            id: "2454",
            sy7: "ܝܳܠܶܦ",
            dt2: "wissen",
            sy9: ""
        },
        {
            lt10: "tagoro d`neshe",
            sy6: "",
            lt11: "",
            lt12: "",
            dt3: "",
            dt1: "Frauenhändler",
            dt2: "",
            sy7: "",
            sy4: "ܬܓܪܐ ܕܢܫܐ",
            id: "1370",
            sy9: "",
            sy5: "ܬܰܓܳܪܳܐ ܕܢܶܫ̈ܶܐ",
            sy8: ""
        },
        {
            sy8: "",
            id: "120",
            sy9: "",
            sy6: "ܛܘܗܡܐ",
            lt10: "sharbtho",
            sy4: "ܫܪܒܬܐ",
            sy7: "ܛܽܘܗܡܳܐ",
            dt2: "Rasse",
            sy5: "ܫܰܪܒܬ݂ܳܐ",
            lt11: "țuhmo",
            lt12: "",
            dt1: "Abstammung",
            dt3: "Sippe"
        },
        {
            sy6: "",
            sy9: "",
            dt2: "",
            lt10: "ܚܟܝܡܘܬܐ",
            id: "1827",
            sy4: "ܚܟܝܡܘܬܐ",
            sy7: "",
            dt1: "Hakimuta",
            dt3: "",
            lt12: "",
            sy5: "ܚܰܟܺܝܡܽܘܬܰܐ",
            lt11: "ܚܟܝܡܘܬܐ",
            sy8: ""
        },
        {
            sy9: "",
            dt3: "",
            sy6: "",
            id: "2104",
            dt2: "",
            sy5: "ܦܨܺܝܚܳܝܳܐ ܐ̱ܢܳܐ",
            lt11: "",
            sy4: "ܦܨܝܚܝܐ ܐܢܐ",
            dt1: "Ich bin zufrieden",
            lt12: "",
            lt10: "fŝiĥoyo no",
            sy8: "",
            sy7: ""
        },
        {
            sy6: "",
            lt10: "shuĉmoro",
            sy8: "",
            id: "2557",
            sy7: "",
            sy5: "ܫܽܘܥܡܳܪܳܐ",
            sy9: "",
            dt1: "Kolonialismus",
            lt11: "",
            sy4: "ܫܘܥܡܪܐ",
            lt12: "",
            dt2: "Kolonie",
            dt3: ""
        },
        {
            sy9: "",
            lt10: "nafiqo",
            sy7: "",
            dt2: "",
            dt3: "",
            sy8: "",
            sy4: "ܢܦܝܩܐ",
            lt12: "",
            sy5: "ܢܰܦܺܝܩܳܐ",
            id: "4789",
            sy6: "",
            dt1: "verrenkt",
            lt11: ""
        },
        {
            lt10: "fosulo",
            sy5: "ܦܳܣܽܘܠܳܐ",
            lt12: "",
            dt3: "",
            sy6: "",
            sy9: "",
            lt11: "",
            id: "617",
            sy7: "",
            sy8: "",
            dt1: "Bildhauer",
            sy4: "ܦܣܘܠܐ",
            dt2: "Meißel"
        },
        {
            id: "5468",
            sy6: "",
            lt12: "",
            sy8: "",
            lt11: "",
            dt3: "",
            dt2: "",
            sy9: "",
            lt10: "ŝule",
            sy7: "",
            dt1: "Sandalen",
            sy4: "ܨܘܠܐ",
            sy5: "ܨܽܘ̈ܠܶܐ"
        },
        {
            lt10: "qalil",
            sy5: "ܩܰܠܺܝܠ",
            dt1: "leicht",
            lt12: "",
            lt11: "",
            dt3: "",
            id: "2752",
            sy6: "",
            sy8: "",
            sy7: "",
            sy9: "",
            dt2: "",
            sy4: "ܩܠܝܠ"
        },
        {
            lt11: "țauro",
            dt2: "",
            sy6: "ܛܘܪܐ",
            sy9: "ܡܶܬ݂ܚܳܐ",
            dt3: "",
            sy8: "ܡܬܚܐ",
            lt10: "methĥo d'zabno",
            sy5: "ܡܶܬ݂ܚܳܐ ܕܙܰܒܢܳܐ",
            dt1: "Zeitraum",
            sy7: "ܛܰܘܪܳܐ",
            lt12: "methĥo",
            sy4: "ܡܬܚܐ ܕܙܒܢܐ",
            id: "6249"
        },
        {
            lt11: "",
            sy6: "",
            dt2: "",
            sy5: "ܡܕܰܒܪܳܢܽܘܬ݂ܳܐ ܪܺܝܫܳܢܳܝܬܳܐ",
            sy9: "",
            dt3: "",
            lt12: "",
            id: "5306",
            sy4: "ܡܕܒܪܢܘܬܐ ܪܝܫܢܝܬܐ",
            sy8: "",
            lt10: "mdabronutho rishonoyto",
            dt1: "Zentralregierung",
            sy7: ""
        },
        {
            dt3: "",
            dt1: "Assur",
            sy6: "",
            lt12: "",
            sy9: "",
            sy5: "ܐܳܬ݂ܘܽܪ",
            lt10: "othur",
            sy8: "",
            sy7: "",
            lt11: "",
            dt2: "",
            sy4: "ܐܬܘܪ",
            id: "316"
        },
        {
            sy9: "ܪܺܝܫ ܚܰܝܠܳܐ",
            lt10: "rish folĥutho",
            sy7: "ܪܰܒ ܚܰܝܠܳܐ",
            sy8: "ܪܝܫ ܚܝܠܐ",
            dt3: "",
            dt2: "",
            lt11: "rab ĥaylo",
            lt12: "rish ĥaylo",
            sy4: "ܪܝܫ ܦܠܚܘܬܐ",
            sy6: "ܪܒ ܚܝܠܐ",
            id: "6134",
            dt1: "Oberfehlshaber",
            sy5: "ܪܺܝܫ ܦܳܠܚܽܘܬ݂ܳܐ"
        },
        {
            lt12: "",
            lt10: "rqađ",
            dt3: "",
            dt1: "hopsen",
            sy8: "",
            sy7: "ܫܘܰܪ",
            sy9: "",
            sy4: "ܪܩܕ",
            dt2: "",
            id: "6042",
            sy6: "ܫܘܪ",
            sy5: "ܪܩܰܕ",
            lt11: "shwar"
        },
        {
            sy5: "ܝܳܕܰܥ",
            dt1: "informieren",
            lt10: "yođaĉ",
            sy6: "ܡܘܕܥ",
            sy9: "",
            lt12: "",
            dt3: "",
            sy7: "ܡܰܘܕܰܥ",
            id: "2202",
            sy8: "",
            lt11: "mauđaĉ",
            sy4: "ܝܕܥ",
            dt2: ""
        },
        {
            sy5: "ܫܦܺܝܥܳܐܝܺܬ݂",
            sy6: "ܝܬܝܪܐܝܬ",
            sy8: "",
            dt3: "",
            lt12: "",
            id: "3671",
            sy9: "",
            dt1: "reichlich",
            sy4: "ܫܦܝܥܐܝܬ",
            lt11: "yatiroith",
            dt2: "großzügig",
            lt10: "shfiĉoith",
            sy7: "ܝܰܬܺܝܪܳܐܺܝܬ݂"
        },
        {
            lt10: "meshtalmono",
            lt12: "",
            sy8: "",
            sy4: "ܡܫܬܠܡܢܐ",
            dt1: "lieferbar",
            dt3: "",
            id: "2825",
            lt11: "",
            sy9: "",
            sy6: "",
            sy7: "",
            sy5: "ܡܶܫܬܰܠܡܳܢܳܐ",
            dt2: ""
        },
        {
            sy7: "ܛܶܒܳܐ",
            dt2: "Verkündigung",
            sy5: "ܣܽܘܒܳܪܳܐ",
            sy8: "ܐܝܙܓܕܘܬܐ",
            dt3: "",
            lt10: "suboro",
            lt12: "izgadutho",
            sy6: "ܛܒܐ",
            sy4: "ܣܘܒܪܐ",
            lt11: "țebo",
            dt1: "Botschaft",
            id: "684",
            sy9: "ܐܺܝܙܓܰܕܽܘܬ݂ܳܐ"
        },
        {
            sy4: "ܥܦ",
            lt12: "",
            sy5: "ܥܳܦ",
            sy8: "",
            dt1: "abspannen",
            id: "5655",
            sy9: "",
            lt11: "ĉmal",
            sy7: "ܥܡܰܠ",
            dt2: "",
            dt3: "",
            lt10: "ĉof",
            sy6: "ܥܡܠ"
        },
        {
            sy6: "",
            dt1: "Stern",
            sy7: "",
            dt3: "",
            sy5: "ܟܽܘܟܒܳܐ",
            sy9: "",
            sy8: "",
            lt12: "",
            lt10: "kukwo",
            sy4: "ܟܘܟܒܐ",
            id: "4264",
            lt11: "",
            dt2: ""
        },
        {
            dt3: "Fetrus",
            id: "3443",
            sy9: "",
            dt2: "Petrus",
            sy5: "ܦܶܛܪܽܘܣ",
            sy8: "",
            lt10: "ܦܛܪܘܣ",
            lt12: "",
            sy7: "",
            dt1: "Petros",
            sy6: "",
            lt11: "ܦܛܪܘܣ",
            sy4: "ܦܛܪܘܣ"
        },
        {
            sy7: "",
            lt12: "",
            sy5: "ܢܟܰܬ",
            sy6: "",
            dt2: "",
            dt1: "beißen",
            lt10: "nkat",
            id: "535",
            sy4: "ܢܟܬ",
            lt11: "",
            sy8: "",
            dt3: "",
            sy9: ""
        },
        {
            dt3: "",
            lt11: "",
            sy5: "ܫܽܘܫܳܛܳܐ",
            sy4: "ܫܘܫܛܐ",
            sy7: "",
            sy8: "",
            id: "1351",
            sy9: "",
            lt12: "",
            lt10: "shushoțo",
            sy6: "",
            dt2: "Entwicklung",
            dt1: "Fortschritt"
        },
        {
            sy6: "ܟܢܬܪܐ",
            id: "627",
            sy9: "",
            lt11: "kantro",
            sy8: "",
            sy4: "ܟܘܡܬܪܐ",
            sy7: "ܟܰܢܬܪܳܐ",
            sy5: "ܟܽܘܡܰܬ݂ܪܳܐ",
            lt10: "kumathro",
            dt3: "",
            dt1: "Birne",
            lt12: "",
            dt2: ""
        },
        {
            sy7: "",
            lt10: "ܝܫܘܥ",
            sy6: "",
            dt3: "Jeschua",
            id: "5207",
            lt12: "",
            sy9: "",
            sy8: "",
            sy4: "ܝܫܘܥ",
            dt1: "Yeschua",
            dt2: "Joschua",
            sy5: "ܝܶܫܽܘܥ",
            lt11: "ܝܫܘܥ"
        },
        {
            dt3: "",
            dt2: "",
            sy6: "ܩܘܦܪܐ",
            sy5: "ܚܶܢܳܐ",
            sy9: "ܟܽܘܦܪܳܐ",
            lt10: "ĥeno",
            dt1: "Henna",
            id: "1960",
            lt12: "kufro",
            sy4: "ܚܢܐ",
            lt11: "qufro",
            sy8: "ܟܘܦܪܐ",
            sy7: "ܩܽܘܦܪܳܐ"
        },
        {
            sy8: "",
            sy9: "",
            sy4: "ܪܟܠܐ",
            lt12: "",
            id: "263",
            lt10: "rakolo",
            lt11: "rakoloyo",
            sy6: "ܪܟܠܝܐ",
            dt1: "Apotheker",
            sy7: "ܪܰܟܳܠܳܝܳܐ",
            dt2: "",
            sy5: "ܪܰܟܳܠܳܐ",
            dt3: ""
        },
        {
            lt10: "ܐܘܪܚܐ",
            sy4: "ܐܘܪܚܐ",
            sy5: "ܐܽܘܪܚܰܐ",
            sy7: "",
            lt11: "ܐܘܪܚܐ",
            sy9: "",
            lt12: "",
            sy8: "",
            sy6: "",
            id: "4683",
            dt1: "Urha",
            dt3: "",
            dt2: ""
        },
        {
            dt3: "",
            sy9: "",
            sy7: "",
            lt10: "ܫܡܥܝܐ",
            lt11: "ܫܡܥܝܐ",
            lt12: "",
            sy8: "",
            sy5: "ܫܡܰܥܝܳܐ",
            dt2: "",
            dt1: "Schemaja",
            sy4: "ܫܡܥܝܐ",
            id: "3919",
            sy6: ""
        },
        {
            sy4: "ܡܫ",
            lt12: "",
            dt3: "",
            lt10: "ܡܫ",
            sy6: "",
            sy7: "",
            dt1: "Masch",
            lt11: "ܡܫ",
            sy5: "ܡܰܫ",
            dt2: "",
            sy8: "",
            sy9: "",
            id: "2956"
        },
        {
            dt3: "",
            lt11: "",
            sy5: "ܢܽܘܫܰܩܬܳܐ ܕܰܕܡܳܟ݂ܳܐ",
            sy9: "",
            lt10: "nushaqto da`dmokho",
            sy8: "",
            id: "3170",
            sy4: "ܢܘܫܩܬܐ ܕܕܡܟܐ",
            lt12: "",
            dt1: "Nachtkuss",
            dt2: "",
            sy7: "",
            sy6: ""
        },
        {
            id: "3440",
            dt2: "",
            sy6: "",
            dt1: "Persönlichkeit",
            dt3: "",
            sy7: "",
            lt11: "",
            sy5: "ܦܰܪܨܽܘܦܳܝܽܘܬ݂ܳܐ",
            sy8: "",
            sy4: "ܦܪܣܘܦܝܘܬܐ",
            lt10: "farsufoyutho",
            sy9: "",
            lt12: ""
        },
        {
            lt10: "ܒܟܝܪ",
            dt2: "",
            id: "424",
            sy5: "ܒܰܟܺܝܪ",
            dt1: "Bakir",
            sy7: "",
            sy4: "ܒܟܝܪ",
            dt3: "",
            lt12: "",
            lt11: "ܒܟܝܪ",
            sy6: "",
            sy9: "",
            sy8: ""
        },
        {
            sy8: "",
            sy5: "ܩܰܢܝܳܐ ܘ ܘܰܪܩܳܐ",
            lt11: "",
            dt3: "",
            lt10: "qanyo u warqo",
            dt1: "Stift und Papier",
            lt12: "",
            dt2: "Stift und Zettel",
            sy4: "ܩܰܢܝܳܐ ܘ ܘܰܪܩܳܐ",
            sy9: "",
            sy6: "",
            sy7: "",
            id: "5470"
        },
        {
            sy6: "",
            sy9: "",
            sy8: "",
            lt10: "ܗܒܒܬܐ",
            sy4: "ܗܒܒܬܐ",
            lt11: "ܗܒܒܬܐ",
            sy5: "ܗܰܒܳܒܬܰܐ",
            lt12: "",
            id: "1798",
            sy7: "",
            dt1: "Habobta",
            dt3: "",
            dt2: ""
        },
        {
            id: "4601",
            sy8: "",
            lt12: "",
            sy9: "",
            dt1: "türkisch",
            sy5: "ܬܽܘܪܟܳܝܳܐ",
            lt11: "țurkoyo",
            sy6: "ܛܘܪܟܝܐ",
            sy7: "ܛܘܪܟܝܐ",
            dt2: "",
            lt10: "turkoyo",
            dt3: "",
            sy4: "ܬܘܪܟܝܐ"
        },
        {
            sy5: "ܓܶܫܬܺܝܰܢܰܐ",
            lt11: "ܓܫܬܝܢܐ",
            sy8: "",
            lt12: "",
            sy4: "ܓܫܬܝܢܐ",
            sy9: "",
            sy7: "",
            sy6: "",
            lt10: "ܓܫܬܝܢܐ",
            dt3: "",
            dt2: "",
            dt1: "Geschtiyana",
            id: "1589"
        },
        {
            sy7: "",
            sy6: "",
            sy4: "ܛܘܪܐ ܪܡܐ",
            sy8: "",
            lt12: "",
            dt2: "",
            lt11: "",
            sy5: "ܛܽܘܪܳܐ ܪܳܡܳܐ",
            dt1: "hoher Berg",
            lt10: "țuro romo",
            dt3: "",
            sy9: "",
            id: "2041"
        },
        {
            sy9: "",
            sy6: "",
            lt11: "",
            dt2: "",
            sy5: "ܓܰܢܰܬ݂ ܫܰܒ̈ܪܶܐ",
            id: "2478",
            sy7: "",
            sy4: "ܓܢܬ ܫܒܪܐ",
            dt3: "",
            lt12: "",
            lt10: "ganath shabre",
            sy8: "",
            dt1: "Kindergarten"
        },
        {
            sy8: "",
            sy6: "ܐܝܣܪܬܐ",
            sy7: "ܐܺܝܣܳܪܬܳܐ",
            sy9: "",
            lt11: "isorto",
            dt2: "",
            dt3: "",
            id: "1619",
            sy5: "ܓܠܽܘܠܺܝܬ݂ܳܐ",
            lt12: "",
            dt1: "Gewehr",
            sy4: "ܓܠܘܠܝܬܐ",
            lt10: "glulitho"
        },
        {
            sy7: "",
            lt12: "",
            sy9: "",
            id: "2161",
            dt3: "",
            dt1: "ihretwillen",
            sy8: "",
            sy6: "",
            sy5: "ܡܶܛܽܠܳܬ݂ܳܗ",
            sy4: "ܡܛܠܬܗ",
            dt2: "",
            lt11: "",
            lt10: "mețulothoh"
        },
        {
            dt2: "",
            sy6: "",
            dt3: "",
            sy7: "",
            sy5: "ܬܪܰܥ ܘܰܪܺܝܕܳܐ",
            lt11: "",
            id: "5752",
            sy9: "",
            sy4: "ܬܪܥ ܘܪܝܕܐ",
            lt10: "traĉ warido",
            dt1: "Aderöffnung",
            lt12: "",
            sy8: ""
        },
        {
            dt1: "Seelenruhe",
            dt3: "",
            dt2: "",
            lt11: "",
            sy8: "",
            sy9: "",
            sy7: "",
            lt10: "nyoĥo ĉolminoyo",
            sy6: "",
            lt12: "",
            id: "6218",
            sy5: "ܢܝܳܚܳܐ ܥܳܠܡܺܝܢܳܝܳܐ",
            sy4: "ܢܝܚܐ ܥܠܡܝܢܝܐ"
        },
        {
            dt2: "",
            id: "1358",
            sy5: "ܫܰܐܶܠ",
            lt12: "",
            sy7: "ܡܫܰܐܠܶ",
            dt3: "",
            lt10: "shayel",
            sy6: "ܡܫܐܠ",
            sy9: "",
            sy4: "ܫܐܠ",
            dt1: "fragen",
            sy8: "",
            lt11: "mshayel"
        },
        {
            dt3: "",
            lt10: "yapan",
            dt2: "",
            dt1: "Japan",
            lt11: "",
            sy8: "",
            sy5: "ܝܰܦܰܐܢ",
            id: "2286",
            sy6: "",
            sy9: "",
            sy7: "",
            lt12: "",
            sy4: "ܝܦܐܢ"
        },
        {
            sy9: "",
            lt11: "",
            sy5: "ܫܰܟ݂ܪܳܢܳܝܳܐ",
            lt10: "shakhronoyo",
            lt12: "",
            sy8: "",
            sy6: "",
            id: "5826",
            dt2: "",
            dt3: "",
            sy7: "",
            dt1: "alkoholhaltig",
            sy4: "ܫܟܪܢܝܐ"
        },
        {
            sy7: "ܛܠܽܘܡܝܳܐ",
            lt12: "",
            lt10: "țlumutho",
            sy6: "ܛܠܘܡܝܐ",
            sy8: "",
            dt1: "Unrecht",
            dt3: "Ungerechtigkeit",
            lt11: "țlumyo",
            sy9: "",
            dt2: "Grausamkeit",
            id: "4654",
            sy4: "ܛܠܘܡܘܬܐ",
            sy5: "ܛܠܽܘܡܽܘܬ݂ܳܐ"
        },
        {
            sy8: "",
            sy9: "",
            sy6: "",
            dt3: "",
            dt2: "",
            sy4: "ܕܟܝ",
            lt10: "ܕܟܝ",
            sy5: "ܕܰܟܺܝ",
            lt12: "",
            sy7: "",
            lt11: "ܕܟܝ",
            dt1: "Daki",
            id: "786"
        },
        {
            sy4: "ܬܫܡܫܬܐ ܕܩܘܛܢܘܬܐ",
            sy7: "",
            sy8: "",
            dt2: "",
            id: "4232",
            dt3: "",
            sy6: "",
            sy5: "ܬܶܫܡܶܫܬܳܐ ܕܩܽܘܛܢܽܘܬ݂ܳܐ",
            lt12: "",
            dt1: "Staatsdienst",
            lt11: "",
            sy9: "",
            lt10: "teshemsto d`quțnutho"
        },
        {
            dt2: "Fall",
            sy7: "",
            dt3: "",
            sy9: "",
            sy6: "",
            lt10: "qufoso",
            dt1: "Absturz",
            sy5: "ܩܽܘܦܳܣܳܐ",
            id: "121",
            lt11: "",
            lt12: "",
            sy8: "",
            sy4: "ܩܘܦܣܐ"
        },
        {
            dt2: "",
            sy5: "ܬܳܐܡ̈ܶܐ",
            sy4: "ܬܐܡܐ",
            id: "5403",
            lt11: "",
            dt3: "",
            sy9: "",
            lt10: "tome",
            sy6: "",
            sy8: "",
            dt1: "Zwillinge",
            sy7: "",
            lt12: ""
        },
        {
            dt3: "",
            sy4: "ܩܪܒܐ ܕܝܡܐ",
            sy6: "",
            lt10: "qrobo d",
            id: "6213",
            lt11: "",
            sy7: "",
            sy5: "ܩܪܳܒܳܐ ܕܝܰܡܳܐ",
            sy8: "",
            dt2: "Seekampf",
            dt1: "Seekrieg",
            lt12: "",
            sy9: ""
        },
        {
            sy6: "ܦܓܪܐ",
            dt3: "",
            id: "2613",
            sy7: "ܦܰܓ݂ܪܳܐ",
            sy9: "",
            sy8: "",
            dt1: "Körper",
            lt12: "",
            sy4: "ܓܘܫܡܐ",
            lt11: "fağro",
            lt10: "gushmo",
            sy5: "ܓܽܘܫܡܳܐ",
            dt2: "Leib"
        },
        {
            dt3: "",
            sy7: "",
            sy9: "",
            dt1: "Belti",
            lt12: "",
            id: "545",
            sy4: "ܒܠܬܝ",
            sy8: "",
            lt11: "ܒܠܬܝ",
            sy6: "",
            dt2: "",
            lt10: "ܒܠܬܝ",
            sy5: "ܒܶܠܬܺܝ"
        },
        {
            sy7: "",
            sy8: "",
            dt1: "Zinsrechnung",
            dt2: "",
            lt10: "ĥushbon rebitho",
            sy4: "ܚܘܫܒܢ ܪܒܝܬܐ",
            sy5: "ܚܽܘܫܒܳܢ ܪܶܒܺܝܬ݂ܳܐ",
            lt12: "",
            lt11: "",
            id: "5331",
            sy6: "",
            dt3: "",
            sy9: ""
        },
        {
            dt3: "",
            sy5: "ܡܶܨܪܶܝܢ",
            sy7: "",
            dt2: "",
            id: "148",
            lt11: "",
            lt10: "meŝren",
            sy8: "",
            dt1: "Ägypten",
            sy6: "",
            sy9: "",
            lt12: "",
            sy4: "ܡܨܪܝܢ"
        },
        {
            lt12: "",
            sy6: "ܩܝܨܐ",
            dt3: "",
            sy5: "ܩܳܐܶܨ",
            sy7: "ܩܳܝܨܳܐ",
            sy4: "ܩܐܨ",
            id: "3996",
            sy8: "",
            dt2: "",
            sy9: "",
            lt10: "qoeŝ",
            lt11: "qoyŝo",
            dt1: "schneiden"
        },
        {
            sy7: "",
            sy9: "",
            id: "2032",
            lt10: "benyono ĉeloyo",
            lt11: "",
            dt1: "Hochhaus",
            dt3: "",
            dt2: "",
            sy4: "ܒܢܝܢܐ ܥܠܝܐ",
            sy5: "ܒܶܢܝܳܢܳܐ ܥܶܠܳܝܳܐ",
            sy8: "",
            lt12: "",
            sy6: ""
        },
        {
            id: "5898",
            sy6: "",
            dt3: "",
            sy4: "ܒܚܕ ܝܘܡ",
            sy5: "ܒܚܰܕ ܝܽܘܡ",
            sy9: "",
            lt12: "",
            dt1: "an diesem Tag",
            lt10: "bĥad yum",
            lt11: "",
            sy7: "",
            sy8: "",
            dt2: ""
        },
        {
            sy9: "",
            id: "35",
            lt10: "shabĉin",
            dt2: "Siebzig",
            sy5: "ܫܰܒܥܺܝܢ ",
            dt3: "",
            lt12: "",
            dt1: "70",
            sy4: "ܫܒܥܝܢ",
            sy7: "ܥ",
            sy6: "ܥ",
            sy8: "",
            lt11: "ĉe"
        },
        {
            lt12: "",
            sy4: "ܥ",
            sy9: "",
            sy6: "",
            lt11: "",
            sy5: "ܥ",
            dt2: "",
            lt10: "ĉe",
            sy8: "",
            dt3: "",
            id: "754",
            dt1: "ĉ",
            sy7: ""
        },
        {
            dt1: "Nischo",
            lt12: "",
            id: "3286",
            dt2: "",
            lt11: "ܢܝܫܐ",
            sy5: "ܢܺܝܫܳܐ",
            sy4: "ܢܝܫܐ",
            lt10: "ܢܝܫܐ",
            sy8: "",
            sy9: "",
            dt3: "",
            sy7: "",
            sy6: ""
        },
        {
            lt11: "",
            sy7: "",
            sy9: "",
            sy6: "",
            sy8: "",
            dt2: "",
            lt10: "telefonoith",
            lt12: "",
            dt3: "",
            id: "6414",
            sy5: "ܬܶܠܶܦܳܢܳܐܝܺܬ݂",
            sy4: "ܬܠܦܢܐܝܬ",
            dt1: "telefonisch"
        },
        {
            sy8: "",
            lt10: "rabo",
            lt12: "",
            lt11: "",
            id: "1734",
            sy9: "",
            dt2: "gross",
            sy4: "ܪܒܐ",
            sy7: "",
            dt3: "",
            sy6: "",
            sy5: "ܪܰܒܳܐ",
            dt1: "groß"
        },
        {
            lt10: "qufoyo",
            lt11: "mrimonuth",
            sy5: "ܩܽܘܦܳܝܳܐ",
            sy4: "ܩܘܦܝܐ",
            dt3: "",
            dt2: "",
            sy8: "",
            sy7: "ܡܪܺܝܡܳܢܽܘܬ݂",
            sy9: "",
            id: "5594",
            lt12: "",
            dt1: "Abrüstung",
            sy6: "ܡܪܝܡܢܘܬ"
        },
        {
            lt10: "ܐܪܡ",
            sy9: "",
            id: "270",
            sy8: "",
            sy5: "ܐܰܪܰܡ",
            lt12: "ܐܪܡ",
            sy7: "ܐܳܪܳܡ",
            dt1: "Aram",
            sy4: "ܐܪܡ",
            sy6: "ܐܪܡ",
            dt2: "Orom",
            lt11: "ܐܪܡ",
            dt3: ""
        },
        {
            sy9: "",
            dt2: "Witwentum",
            sy4: "ܐܪܡܠܘܬܐ",
            dt1: "Witwenschaft",
            lt11: "",
            sy5: "ܐܰܪܡܠܽܘܬ݂ܳܐ",
            sy6: "",
            id: "5139",
            lt10: "armlutho",
            lt12: "",
            sy7: "",
            sy8: "",
            dt3: ""
        },
        {
            dt2: "Bleistiftspitzer",
            dt1: "Anspitzer",
            sy4: "ܓܠܘܦܬܐ",
            sy9: "",
            lt10: "golufto",
            id: "243",
            sy6: "",
            sy5: "ܓܳܠܽܘܦܬܳܐ",
            dt3: "",
            sy8: "",
            sy7: "",
            lt12: "",
            lt11: ""
        },
        {
            lt10: "afto",
            sy8: "",
            dt3: "",
            sy4: "ܐܦܬܐ",
            dt2: "",
            sy9: "",
            lt12: "",
            dt1: "Pause",
            lt11: "",
            sy6: "",
            sy5: "ܐܰܦܬܳܐ",
            id: "3425",
            sy7: ""
        },
        {
            sy8: "",
            lt10: "tamem",
            id: "5651",
            sy6: "ܡܠܐ",
            lt11: "mlo",
            sy4: "ܬܡܡ",
            dt1: "absolvieren",
            sy7: "ܡܠܳܐ",
            sy5: "ܬܰܡܶܡ",
            dt3: "",
            dt2: "",
            lt12: "",
            sy9: ""
        },
        {
            sy7: "",
            lt12: "",
            dt2: "",
            sy5: "ܪܳܚܽܘܒ",
            lt11: "ܪܚܘܒ",
            dt3: "",
            sy4: "ܪܚܘܒ",
            sy8: "",
            sy6: "",
            sy9: "",
            dt1: "Rohut",
            id: "3740",
            lt10: "ܪܚܘܒ"
        },
        {
            id: "5501",
            lt12: "",
            sy8: "",
            sy9: "",
            lt11: "",
            dt1: "Fell",
            dt3: "",
            sy6: "",
            dt2: "",
            lt10: "shelĥo",
            sy7: "",
            sy5: "ܫܶܠܚܳܐ",
            sy4: "ܫܠܚܐ"
        },
        {
            lt12: "",
            dt2: "",
            lt11: "",
            sy4: "ܩܪܝ ܘܟܬܘܒ",
            sy7: "",
            lt10: "qri u wakthub",
            sy9: "",
            sy5: "ܩܪܺܝ ܘܰܟܬܽܘܒ",
            dt3: "",
            id: "2766",
            sy8: "",
            sy6: "",
            dt1: "lese und schreibe"
        },
        {
            sy4: "ܐܘܒܠ ܩܕܡ ܬܪܥܐ",
            sy5: "ܐܰܘܒܶܠ ܩܕܳܡ ܬܰܪܥܳܐ",
            sy6: "",
            dt2: "",
            dt1: "vor die Tür bringen",
            sy7: "",
            lt12: "",
            lt11: "",
            sy8: "",
            id: "4893",
            sy9: "",
            lt10: "aubel qđom tarĉo",
            dt3: ""
        },
        {
            id: "2997",
            dt3: "",
            dt1: "Menasche",
            lt12: "",
            sy9: "",
            sy4: "ܡܢܫܐ",
            lt11: "ܡܢܫܐ",
            sy7: "",
            sy8: "",
            sy5: "ܡܶܢܰܫܶܐ",
            dt2: "",
            sy6: "",
            lt10: "ܡܢܫܐ"
        },
        {
            lt11: "ܐܫܪ",
            sy7: "",
            sy9: "",
            sy8: "",
            sy5: "ܐܰܫܰܪ",
            dt1: "Aschar",
            dt2: "",
            id: "309",
            lt12: "",
            lt10: "ܐܫܪ",
            dt3: "",
            sy4: "ܐܫܪ",
            sy6: ""
        },
        {
            sy4: "ܬܫܪܝܬܐ",
            sy5: "ܬܶܫܪܳܝܳܬ݂ܳܐ",
            lt11: "",
            lt10: "teshroyotho",
            sy9: "",
            sy7: "",
            dt1: "Herbst",
            sy6: "",
            dt2: "",
            lt12: "",
            sy8: "",
            id: "1965",
            dt3: ""
        },
        {
            sy5: "ܡܕܰܓܠܳܢܳܐ",
            dt3: "",
            sy6: "",
            lt12: "",
            dt2: "",
            lt11: "",
            sy9: "",
            dt1: "Lügner",
            sy7: "",
            lt10: "mdaglono",
            sy4: "ܡܕܓܠܢܐ",
            id: "2871",
            sy8: ""
        },
        {
            dt3: "",
            lt12: "",
            sy9: "",
            id: "4880",
            sy4: "ܢܚܬ ܡܢ ܫܡܝܐ",
            sy8: "",
            dt1: "Vom Himmel hinabgestiegen",
            sy7: "",
            lt10: "nĥet men shmayo",
            dt2: "",
            sy6: "",
            lt11: "",
            sy5: "ܢܚܶܬ ܡܶܢ ܫܡܰܝܳܐ"
        },
        {
            dt1: "Hallo, mein Name ist Aday. Das ist Saro, meine Frau.",
            sy5: "ܒܫܰܝܢܐ، ܐܳܢܐ ܐܷܫܡܝ ܐܰܕܰܝ ܝܐ. ܗܰܬ݂ܶܐ ܐܝ ܣܰܪܐ ܝܐ، ܐܝ ܐܰܬ݂ܬܰܝܕ݂ܝ.",
            sy8: null,
            sy9: null,
            lt10: "B šayno, ono ëšmi Aday yo. Haṯe i Saro yo, i aṯtayḏi.",
            sy7: null,
            dt2: null,
            id: "kT8woEUk3aoUz7uGwUJB",
            dt3: null,
            sy6: null,
            "date": {
                "seconds": 1587245276,
                "nanoseconds": 552000000
            },
            sy4: "ܒܫܝܢܐ، ܐܢܐ ܐܷܫܡܝ ܐܕܝ ܝܐ. ܗܬܐ ܐܝ ܣܪܐ ܝܐ، ܐܝ ܐܬܬܝܕܝ.",
            lt11: null,
            lt12: null
        },
        {
            sy9: "",
            sy6: "",
            id: "3392",
            sy5: "ܐܳܪܳܡ ܓܰܒܪܺܐܝܶܠ",
            sy8: "",
            lt12: "",
            sy7: "",
            dt2: "Aram Gabriel",
            sy4: "ܐܪܡ ܓܒܪܐܝܠ",
            lt10: "ܐܪܡ ܓܒܪܐܝܠ",
            lt11: "ܐܪܡ ܓܒܪܐܝܠ",
            dt1: "Orom Gabriel",
            dt3: ""
        },
        {
            sy5: "ܐܰܪܺܝܟ݂ ܥܽܘܡܪܳܐ",
            sy6: "",
            lt11: "",
            sy4: "ܐܪܝܟ ܥܘܡܪܐ",
            id: "2708",
            dt2: "langlebig",
            lt12: "",
            dt1: "langjährig",
            lt10: "arikh ĉumro",
            dt3: "",
            sy9: "",
            sy8: "",
            sy7: ""
        },
        {
            id: "1003",
            lt12: "",
            dt2: "Afrem",
            sy6: "",
            sy9: "",
            dt1: "Efrim",
            lt10: "ܐܦܪܝܡ",
            sy4: "ܐܦܪܝܡ",
            sy5: "ܐܰܦܪܶܝܡ",
            sy8: "",
            sy7: "",
            dt3: "Ephram",
            lt11: "ܐܦܪܝܡ"
        },
        {
            lt12: "",
            sy9: "",
            dt1: "absenden",
            id: "116",
            sy4: "ܫܕܪ",
            sy7: "",
            sy6: "",
            sy5: "ܫܰܕܰܪ",
            dt3: "",
            sy8: "",
            lt11: "",
            lt10: "shadar",
            dt2: ""
        },
        {
            sy9: "",
            lt10: "ayko",
            sy4: "ܐܝܟܐ",
            dt3: "",
            sy8: "",
            dt1: "Wo",
            lt11: "",
            sy7: "",
            id: "5142",
            dt2: "",
            lt12: "",
            sy5: "ܐܰܝܟܳܐ",
            sy6: ""
        },
        {
            dt1: "Malerin",
            lt10: "mŝayronitho",
            dt3: "",
            dt2: "",
            sy8: "ܪܫܘܡܬܐ",
            sy4: "ܡܨܝܪܢܝܬܐ",
            sy6: "ܨܝܘܪܬܐ",
            sy5: "ܡܨܰܝܪܳܢܺܝܬ݂ܳܐ",
            sy9: "ܪܳܫܽܘܡܬܳܐ",
            sy7: "ܨܳܝܽܘܪܬܳܐ",
            id: "2902",
            lt11: "ŝoyurto",
            lt12: "roshumto"
        },
        {
            sy5: "ܕܐܺܒܳܐ",
            lt11: "",
            lt12: "",
            id: "5159",
            sy6: "",
            dt1: "Wolf",
            sy8: "",
            lt10: "dibo",
            dt2: "",
            dt3: "",
            sy9: "",
            sy7: "",
            sy4: "ܕܐܒܐ"
        },
        {
            dt1: "Rückkehr nach Hause",
            sy4: "ܗܦܘܟܝܐ ܠܒܝܬܐ",
            sy7: "",
            lt12: "",
            sy8: "",
            dt2: "",
            dt3: "",
            id: "3759",
            sy5: "ܗܦܽܘܟ݂ܝܳܐ ܠܒܰܝܬܳܐ",
            lt11: "",
            sy6: "",
            sy9: "",
            lt10: "hfukhyo l`bayto"
        },
        {
            dt2: "dunkel",
            lt10: "ĥeshukho",
            dt3: "",
            dt1: "finster",
            lt12: "ghiro",
            lt11: "ĥeshukhoyo",
            sy4: "ܚܫܘܟܐ",
            sy7: "ܚܶܫܽܘܟ݂ܳܝܳܐ",
            sy6: "ܚܫܘܟܝܐ",
            sy8: "ܓܗܝܪܐ",
            sy9: "ܓܗܺܝܪܳܐ",
            sy5: "ܚܶܫܽܘܟ݂ܳܐ",
            id: "1316"
        },
        {
            sy6: "",
            lt12: "",
            dt1: "allergisch",
            sy5: "ܡܶܬ݂ܪܰܓܫܳܢܳܐ",
            sy8: "",
            sy9: "",
            sy4: "ܡܬܪܓܫܢܐ",
            dt3: "",
            dt2: "",
            lt10: "methragshono",
            sy7: "",
            id: "184",
            lt11: ""
        },
        {
            sy6: "",
            lt10: "ܫܪܫܬ",
            dt3: "",
            sy8: "",
            sy5: "ܫܶܪܫܰܬ",
            sy7: "",
            sy9: "",
            lt11: "ܫܪܫܬ",
            id: "3928",
            dt1: "Scherschat",
            sy4: "ܫܪܫܬ",
            lt12: "",
            dt2: ""
        },
        {
            sy5: "ܡܰܣܓܕܳܐ",
            sy9: "",
            dt3: "",
            lt10: "masgđo",
            sy6: "",
            sy8: "",
            lt11: "",
            dt1: "Moschee",
            dt2: "",
            sy7: "",
            id: "3111",
            lt12: "",
            sy4: "ܡܣܓܕܐ"
        },
        {
            sy5: "ܛܶܓ݂ܢܳܐ",
            sy4: "ܛܓܢܐ",
            sy7: "",
            id: "3446",
            dt2: "",
            sy9: "",
            lt11: "",
            lt12: "",
            dt1: "Pfanne",
            dt3: "",
            sy8: "",
            sy6: "",
            lt10: "țeğno"
        },
        {
            dt1: "Freitag",
            id: "1384",
            sy6: "ܥܪܘܒܬܐ",
            dt2: "",
            lt11: "ĉrubto",
            sy4: "ܝܘܡ ܥܪܘܒܬܐ",
            lt10: "yum ĉrubto",
            dt3: "",
            sy8: "",
            sy7: "ܥܪܽܘܒܬܳܐ",
            lt12: "",
            sy5: "ܝܽܘܡ ܥܪܽܘܒܬܳܐ",
            sy9: ""
        },
        {
            sy4: "ܢܙܝܪܘܬܐ",
            sy8: "",
            lt12: "",
            dt2: "",
            lt10: "nzirutho",
            sy5: "ܢܙܺܝܪܽܘܬ݂ܳܐ",
            sy9: "",
            sy7: "ܢܰܟ݂ܦܽܘܬ݂ܳܐ",
            lt11: "nakhfutho",
            dt3: "",
            id: "5670",
            dt1: "Abstinenz",
            sy6: "ܢܟܦܘܬܐ"
        },
        {
            dt1: "Jugendorganisation",
            dt3: "",
            sy9: "",
            sy8: "",
            lt10: "mțakasto da`ĉlaymutho",
            dt2: "",
            sy6: "",
            sy7: "",
            id: "2352",
            lt11: "",
            sy5: "ܡܛܰܟܰܣܬܳܐ ܕܰܥܠܰܝܡܽܘܬ݂ܳܐ",
            sy4: "ܡܛܟܣܬܐ ܕܥܠܝܡܘܬܐ",
            lt12: ""
        },
        {
            dt1: "Semho",
            id: "4114",
            sy5: "ܨܶܡܚܳܐ",
            sy8: "",
            dt3: "",
            sy7: "",
            dt2: "",
            sy9: "",
            lt11: "ܨܡܚܐ",
            lt12: "",
            sy4: "ܨܡܚܐ",
            sy6: "",
            lt10: "ܨܡܚܐ"
        },
        {
            lt11: "",
            sy8: "",
            sy9: "",
            id: "1547",
            sy5: "ܙܽܘܙ̈ܐ",
            sy4: "ܙܘܙܐ",
            dt2: "Währung",
            dt1: "Geld",
            lt10: "zuze",
            dt3: "",
            sy7: "",
            lt12: "",
            sy6: ""
        },
        {
            sy6: "",
            lt12: "",
            dt2: "Anhänger",
            sy5: "ܢܳܩܽܘܦܳܐ",
            lt10: "noqufo",
            lt11: "",
            sy7: "",
            sy4: "ܢܩܘܦܐ",
            sy9: "",
            id: "1252",
            dt1: "Fan",
            dt3: "",
            sy8: ""
        },
        {
            lt11: "qinto",
            lt10: "mazmuro",
            sy7: "ܩܺܝܢܬܳܐ",
            lt12: "qolo",
            sy9: "ܩܳܠܳܐ",
            dt2: "",
            sy5: "ܡܰܙܡܽܘܪܳܐ",
            sy6: "ܩܝܢܬܐ",
            sy4: "ܡܙܡܘܪܐ",
            id: "2085",
            dt1: "Hymne",
            dt3: "",
            sy8: "ܩܠܐ"
        },
        {
            sy8: "",
            sy9: "",
            lt12: "",
            dt3: "",
            lt10: "qurŝlo",
            sy4: "ܩܘܪܨܠܐ",
            sy5: "ܩܽܘܪܨܠܳܐ",
            sy6: "",
            lt11: "",
            dt2: "",
            dt1: "Fußgelenk",
            sy7: "",
            id: "1442"
        },
        {
            id: "357",
            sy5: "ܐܶܬܬܥܺܝܪ",
            sy8: "",
            sy4: "ܐܬܬܥܝܪ",
            dt3: "",
            sy9: "",
            lt12: "",
            sy7: "",
            lt11: "",
            lt10: "ettĉir",
            dt1: "aufgeweckt",
            sy6: "",
            dt2: "aufgestanden"
        },
        {
            dt2: "",
            sy4: "ܥܡܝܩ ܣܓܝ",
            dt3: "",
            sy5: "ܥܰܡܺܝܩ ܣܰܓܺܝ",
            lt12: "",
            sy9: "",
            lt11: "",
            lt10: "ĉamiq sagi",
            sy7: "",
            sy8: "",
            dt1: "zu tief",
            id: "6312",
            sy6: ""
        },
        {
            sy9: "",
            dt2: "applaudieren",
            sy5: "ܢܩܳܫ ܟܰܦܳܐ",
            sy7: "",
            sy6: "",
            dt3: "",
            sy4: "ܢܩܫ ܟܦܐ",
            lt10: "nqosh kafo",
            id: "2500",
            lt11: "",
            lt12: "",
            dt1: "klatschen",
            sy8: ""
        },
        {
            dt1: "Quqojo",
            sy8: "",
            dt3: "",
            sy7: "",
            sy4: "ܩܘܩܝܐ",
            lt10: "ܩܘܩܝܐ",
            sy5: "ܩܽܘܩܳܝܳܐ",
            lt12: "",
            sy9: "",
            id: "3570",
            lt11: "ܩܘܩܝܐ",
            sy6: "",
            dt2: ""
        },
        {
            dt2: "",
            sy5: "ܠܳܐ ܒܰܠܚܽܘܕ",
            lt11: "",
            sy8: "",
            sy6: "",
            lt12: "",
            lt10: "lo balĥuđ",
            sy9: "",
            dt1: "nicht allein",
            id: "3259",
            dt3: "",
            sy4: "ܠܐ ܒܠܚܘܕ",
            sy7: ""
        },
        {
            id: "3260",
            sy9: "",
            lt10: "lo shkiĥo",
            dt3: "",
            sy5: "ܠܳܐ ܫܟܺܝܚܳܐ",
            dt2: "",
            sy6: "",
            lt11: "",
            lt12: "",
            sy4: "ܠܐ ܫܟܝܚܐ",
            dt1: "nicht anwesend",
            sy7: "",
            sy8: ""
        },
        {
            sy8: "",
            sy4: "ܥܢܬܪ",
            sy7: "",
            dt3: "",
            sy5: "ܥܰܢܬܰܪ",
            lt11: "ܥܢܬܪ",
            lt12: "",
            dt2: "",
            lt10: "ܥܢܬܪ",
            sy6: "",
            id: "246",
            sy9: "",
            dt1: "Antar"
        },
        {
            sy4: "ܬܢܝܒܐ",
            sy5: "ܬܰܢܺܝܒܳܐ",
            sy9: "",
            lt11: "",
            lt12: "",
            dt3: "teilnahmslos",
            dt1: "apathisch",
            dt2: "desinteressiert",
            sy8: "",
            sy7: "",
            id: "6010",
            sy6: "",
            lt10: "tanibo"
        },
        {
            dt1: "Fallen",
            sy5: "ܡܰܦܠܳܐ",
            lt12: "",
            sy6: "ܢܦܠܐ",
            dt2: "Sturz",
            sy7: "ܢܦܳܠܳܐ",
            lt11: "nfolo",
            dt3: "",
            sy4: "ܡܦܠܐ",
            sy9: "",
            lt10: "maflo",
            id: "1244",
            sy8: ""
        },
        {
            dt2: "",
            sy6: "",
            sy4: "ܣܝܪܟܘܣ",
            sy7: "",
            sy5: "ܣܺܝܪܟܽܘܣ",
            sy8: "",
            lt12: "",
            dt3: "",
            lt10: "sirkus",
            sy9: "",
            dt1: "Zirkus",
            id: "5336",
            lt11: ""
        },
        {
            sy5: "ܕܰܝܪܳܐ ܕܡܳܪܝ̱ ܐܰܦܪܶܝܡ ܣܽܘܪܝܳܝܳܐ",
            lt11: "",
            id: "4229",
            sy9: "",
            dt3: "",
            sy4: "ܕܝܪܐ ܕܡܪܝ ܐܦܪܝܡ ܣܘܪܝܝܐ",
            lt12: "",
            sy8: "",
            sy7: "",
            sy6: "",
            dt1: "St. Eprem der Syrer Kloster",
            lt10: "dayro d`mor afrem suryoyo",
            dt2: ""
        },
        {
            dt2: "",
            sy8: "",
            dt1: "makeln",
            lt12: "",
            sy9: "",
            sy5: "ܣܰܦܣܶܛ",
            lt10: "safseț",
            sy4: "ܣܦܣܛ",
            id: "2894",
            dt3: "",
            sy7: "",
            lt11: "",
            sy6: ""
        },
        {
            lt10: "renyo ĥiro",
            lt12: "",
            sy8: "",
            sy6: "",
            lt11: "",
            sy7: "",
            dt2: "",
            sy5: "ܪܶܢܝܳܐ ܚܺܝܪܳܐ",
            id: "5415",
            sy4: "ܪܶܢܝܳܐ ܚܺܝܪܳܐ",
            dt1: "Freier Gedanke",
            dt3: "",
            sy9: ""
        },
        {
            lt10: "nuqzo d'ĉudrono",
            id: "6233",
            sy8: "",
            sy9: "",
            dt3: "",
            sy7: "",
            sy4: "ܢܘܩܙܐ ܕܥܘܕܪܢܐ",
            sy5: "ܢܽܘܩܙܳܐ ܕܥܽܘܕܪܳܢܳܐ",
            lt11: "",
            lt12: "",
            dt2: "",
            dt1: "Stützpunkt",
            sy6: ""
        },
        {
            sy5: "ܚܦܳܪܳܐ",
            dt3: "",
            sy9: "",
            id: "379",
            lt12: "",
            dt1: "Ausgrabung",
            lt10: "ĥforo",
            sy4: "ܚܦܪܐ",
            sy8: "",
            sy7: "",
            sy6: "",
            lt11: "",
            dt2: "Schaufeln"
        },
        {
            lt11: "beth nahroyo",
            sy4: "ܢܗܪܝܐ",
            id: "3005",
            sy6: "ܒܝܬ ܢܗܪܝܐ",
            lt10: "nahroyo",
            sy8: "",
            dt3: "",
            dt2: "",
            lt12: "",
            dt1: "Mesopotamier",
            sy7: "ܒܶܝܬ݂ ܢܰܗܪܳܝܳܐ",
            sy5: "ܢܰܗܪܳܝܳܐ",
            sy9: ""
        },
        {
            id: "5485",
            sy8: "",
            lt11: "",
            sy5: "ܩܰܛܳܢܳܐ",
            lt12: "",
            sy4: "ܩܰܛܳܢܳܐ",
            dt2: "",
            dt3: "",
            sy6: "",
            sy7: "",
            dt1: "Baumwollenhänder",
            lt10: "qațono",
            sy9: ""
        },
        {
            dt2: "",
            lt11: "",
            id: "3864",
            sy9: "",
            sy5: "ܫܶܥܝܳܐ ܕܰܛܠܰܦܣܳܢܶܐ",
            sy6: "",
            dt3: "",
            sy7: "",
            sy8: "",
            sy4: "ܫܥܝܐ ܕܛܠܦܣܢܐ",
            dt1: "Schachspiel",
            lt12: "",
            lt10: "sheĉyo da`țlafsone"
        },
        {
            sy6: "",
            dt3: "",
            id: "6057",
            lt12: "",
            sy5: "ܪܰܚܽܘܫܬܳܐ",
            dt1: "Herpes",
            lt11: "",
            sy9: "",
            dt2: "",
            sy4: "ܪܚܘܫܬܐ",
            lt10: "raĥushto",
            sy8: "",
            sy7: ""
        },
        {
            sy8: "",
            dt2: "",
            lt12: "",
            lt10: "esțlo",
            sy7: "",
            dt3: "",
            dt1: "Priestergewand",
            lt11: "",
            sy6: "",
            sy5: "ܐܶܣܛܠܳܐ",
            id: "3510",
            sy9: "",
            sy4: "ܐܣܛܠܐ"
        },
        {
            sy4: "ܣܢܝܓܪܐ",
            dt1: "Advokat",
            lt10: "sniğro",
            sy5: "ܣܢܺܐܓ݂ܪܳܐ",
            lt12: "",
            sy6: "",
            dt3: "",
            lt11: "",
            sy9: "",
            sy7: "",
            id: "5788",
            sy8: "",
            dt2: ""
        },
        {
            sy7: "",
            sy6: "",
            id: "3214",
            sy4: "ܢܐܬܢ",
            dt3: "",
            sy9: "",
            lt10: "ܢܐܬܢ",
            sy5: "ܢܰܐܬܰܢ",
            dt1: "Natan",
            lt11: "ܢܐܬܢ",
            sy8: "",
            dt2: "Nathan",
            lt12: ""
        },
        {
            dt2: "",
            sy8: "",
            sy9: "",
            id: "4383",
            dt1: "Tänzerin",
            sy7: "",
            sy6: "",
            lt11: "",
            sy5: "ܪܰܩܳܕܬ݂ܳܐ",
            lt10: "raqođto",
            dt3: "",
            lt12: "",
            sy4: "ܪܩܕܬܐ"
        },
        {
            dt3: "Alte Frau",
            sy9: "ܣܳܒܬܳܐ",
            dt1: "Oma",
            id: "3373",
            lt11: "qashishto",
            lt12: "sobto",
            dt2: "Großmutter",
            sy7: "ܩܰܫܺܝܫܬܳܐ",
            lt10: "qashto",
            sy6: "ܩܫܝܫܬܐ",
            sy4: "ܩܫܬܐ",
            sy5: "ܩܰܫܬܳܐ",
            sy8: "ܣܒܬܐ"
        },
        {
            sy4: "ܐܚܐܕܐܒܘܗܝ",
            id: "163",
            sy9: "",
            dt1: "Ahodabuy",
            dt3: "",
            sy5: "ܐܰܚܳܐܕܰܐܒܽܘܗܝ",
            sy7: "",
            sy6: "",
            lt12: "",
            lt10: "ܐܚܐܕܐܒܘܗܝ",
            dt2: "Ahadabuhi",
            lt11: "ܐܚܐܕܐܒܘܗܝ",
            sy8: ""
        },
        {
            id: "5498",
            sy7: "",
            lt12: "",
            dt2: "",
            lt11: "",
            lt10: "ĥewtho",
            sy4: "ܚܐܘܬܐ",
            sy9: "",
            sy5: "ܚܶܐܘܬ݂ܳܐ",
            dt3: "",
            sy6: "",
            sy8: "",
            dt1: "fette Milch"
        },
        {
            sy5: "ܓܰܕܳܐ",
            dt1: "Glück",
            dt3: "",
            lt12: "",
            sy9: "",
            id: "1668",
            lt11: "",
            sy6: "",
            sy8: "",
            dt2: "Schicksal",
            sy7: "",
            lt10: "gado",
            sy4: "ܓܕܐ"
        },
        {
            sy8: "",
            dt3: "",
            lt11: "",
            sy9: "",
            sy7: "",
            lt12: "",
            lt10: "furshon duktho",
            dt2: "",
            sy5: "ܦܘܪܫܢ ܕܽܘܟܬܳܐ",
            dt1: "Platzreservierung",
            id: "3476",
            sy4: "ܦܘܪܫܢ ܕܘܟܬܐ",
            sy6: ""
        },
        {
            lt10: "ĉabdo",
            sy4: "ܥܒܕܐ",
            sy8: "",
            sy9: "",
            sy7: "ܓܪܺܝܥܳܐ",
            lt11: "griĉo",
            sy5: "ܥܰܒܕܳܐ",
            dt3: "",
            sy6: "ܓܪܝܥܐ",
            dt2: "Knecht",
            lt12: "",
            id: "4169",
            dt1: "Sklave"
        },
        {
            id: "344",
            lt11: "",
            sy4: "ܥܠ",
            sy8: "",
            dt1: "auf",
            sy7: "",
            dt3: "",
            sy5: "ܥܰܠ",
            lt10: "ĉal",
            lt12: "",
            sy6: "",
            dt2: "",
            sy9: ""
        },
        {
            sy9: "",
            dt3: "",
            lt10: "fsalțutho",
            dt1: "Psalmengesang",
            dt2: "",
            sy5: "ܦܣܰܠܛܽܘܬ݂ܳܐ",
            sy7: "",
            id: "3536",
            sy6: "",
            sy4: "ܦܣܠܛܘܬܐ",
            lt11: "",
            sy8: "",
            lt12: ""
        },
        {
            id: "3513",
            dt3: "",
            sy4: "ܐܡܝܪܬܐ",
            sy8: "",
            lt11: "",
            lt12: "",
            lt10: "amirto",
            dt1: "Prinzessin",
            dt2: "",
            sy6: "",
            sy7: "",
            sy5: "ܐܰܡܺܝܪܬܳܐ",
            sy9: ""
        },
        {
            lt11: "",
            id: "742",
            sy7: "",
            sy4: "ܡܟܬܒܐ",
            dt3: "",
            sy6: "",
            dt1: "Büro",
            lt10: "makthbo",
            sy9: "",
            sy5: "ܡܰܟܬ݂ܒܳܐ",
            dt2: "",
            lt12: "",
            sy8: ""
        },
        {
            sy8: "",
            dt3: "",
            sy5: "ܚܽܘܒܳܐ ܕܺܝܩܰܪܬ݂ܳܐ",
            dt1: "Familienliebe",
            sy9: "",
            lt12: "",
            sy4: "ܚܘܒܐ ܕܝܩܐܪܬܐ",
            lt11: "",
            sy6: "",
            lt10: "ĥubo d`iqartho",
            sy7: "",
            dt2: "",
            id: "1251"
        },
        {
            dt2: "",
            sy7: "ܩܽܘܡܠܳܐ",
            lt11: "qumlo",
            sy5: "ܩܳܡܽܘܠܽܘܬ݂ܳܐ",
            id: "3952",
            lt10: "qomulutho",
            lt12: "",
            sy4: "ܩܡܘܠܘܬܐ",
            dt1: "Schimmel",
            sy9: "",
            dt3: "",
            sy6: "ܩܘܡܠܐ",
            sy8: ""
        },
        {
            dt1: "Ahimalko",
            sy9: "",
            lt10: "ܐܚܝܡܠܟܐ",
            lt11: "ܐܚܝܡܠܟܐ",
            id: "157",
            sy5: "ܐܰܚܺܝܡܰܠܟܐ",
            dt2: "Ahimelech",
            sy7: "",
            sy6: "",
            sy4: "ܐܚܝܡܠܟܐ",
            dt3: "Ahimelek",
            lt12: "",
            sy8: ""
        },
        {
            lt12: "",
            dt1: "Zeitunterschied",
            sy4: "ܦܘܪܫܢ ܙܒܢܐ",
            sy9: "",
            id: "5292",
            dt2: "",
            lt11: "",
            sy5: "ܦܽܘܪܫܳܢ ܙܰܒܢܳܐ",
            sy6: "",
            lt10: "furshon zabno",
            dt3: "",
            sy7: "",
            sy8: ""
        },
        {
            sy6: "",
            lt12: "",
            sy4: "ܩܡܚܐ ܕܟܘ̈ܢܬ݂ܐ",
            sy5: "ܩܰܡܚܳܐ ܕܟܽܘ̈ܢܳܬ݂ܳܐ",
            dt3: "",
            sy8: "",
            lt10: "qamĥo d`kunotho",
            id: "3736",
            sy9: "",
            dt2: "",
            dt1: "Roggenmehl",
            sy7: "",
            lt11: ""
        },
        {
            sy4: "ܚܦܟܬܐ",
            sy8: "",
            dt1: "Kotzen",
            lt10: "ĥfakhto",
            sy7: "ܚܦܳܟ݂ܐܳ",
            sy6: "ܚܦܟܐ",
            lt11: "ĥfokho",
            sy5: "ܚܦܰܟ݂ܬܳܐ",
            dt2: "Kotze",
            id: "2626",
            sy9: "",
            dt3: "",
            lt12: ""
        },
        {
            dt3: "",
            dt2: "freundlich",
            sy8: "",
            sy7: "ܦܨܺܝܚܳܝܳܐ",
            sy4: "ܚܕܝܐ",
            sy5: "ܚܰܕܝܳܐ",
            sy9: "",
            lt10: "ĥadyo",
            id: "1407",
            sy6: "ܦܨܝܚܝܐ",
            lt11: "fŝiĥoyo",
            lt12: "",
            dt1: "fröhlich"
        },
        {
            sy8: "",
            id: "2129",
            lt11: "lo groĥamno lokh",
            sy6: "ܠܐ ܓܪܚܡܢܐ ܠܟ",
            sy4: "ܠܐ ܓܪܚܡܢܐ ܠܟܝ",
            lt10: "lo groĥamno lakh",
            sy7: "ܠܳܐ ܓܪܳܚܰܡܢܳܐ ܠܟ݂",
            dt2: "Ich liebe Dich nicht",
            lt12: "",
            sy5: "ܠܳܐ ܓܪܳܚܰܡܢܳܐ ܠܰܟ݂ܝ̱",
            dt3: "",
            sy9: "",
            dt1: "Ich mag Dich nicht"
        },
        {
            lt11: "",
            dt1: "Zweiter Weltkrieg",
            sy9: "",
            id: "5398",
            sy7: "",
            sy6: "",
            sy8: "",
            sy4: "ܩܪܒܐ ܬܒܝܠܝܐ ܬܪܝܢܐ",
            lt10: "qrobo tibeloyo trayono",
            dt2: "2 Weltkrieg",
            sy5: "ܩܪܳܒܳܐ ܬܺܒܶܝܠܳܝܳܐ ܬܪܰܝܳܢܳܐ",
            lt12: "",
            dt3: ""
        },
        {
            dt2: "Ohnmacht",
            lt10: "țuloĉo",
            sy9: "",
            sy5: "ܛܽܘܠܳܥܳܐ",
            dt3: "",
            sy7: "ܡܛܰܠܥܽܘܬ݂ܳܐ",
            lt12: "",
            id: "2559",
            lt11: "mțalĉutho",
            sy8: "",
            sy6: "ܡܛܠܥܘܬܐ",
            sy4: "ܛܘܠܥܐ",
            dt1: "Koma"
        },
        {
            sy7: "",
            sy5: "ܢܒܰܚ",
            lt10: "nbaĥ",
            lt12: "",
            sy8: "",
            lt11: "",
            sy6: "",
            sy9: "",
            dt1: "bellen",
            sy4: "ܢܒܚ",
            id: "539",
            dt3: "",
            dt2: ""
        },
        {
            sy4: "ܚܕܪ",
            dt2: "ungefähr",
            lt12: "",
            sy5: "ܚܕܳܪ",
            sy9: "",
            lt10: "ĥđor",
            dt3: "",
            id: "775",
            sy6: "ܚܕܪܝ",
            sy8: "",
            sy7: "ܚܕܳܪܰܝ",
            lt11: "ĥđoray",
            dt1: "circa"
        },
        {
            sy7: "",
            sy4: "ܣܘܪܝܝܬܐ ܗܐܢܬܝ",
            sy8: "",
            dt2: "",
            dt1: "Bist Du eine Aramäerin",
            lt10: "suryeyto hat",
            lt11: "",
            dt3: "",
            id: "634",
            lt12: "",
            sy6: "",
            sy5: "ܣܽܘܪܝܝܬܳܐ ܗܐܰܢ̱ܬܝ",
            sy9: ""
        },
        {
            id: "1318",
            lt10: "ܦܝܪܝܐܢ",
            dt3: "",
            sy5: "ܦܺܝܪܝܰܐܢ",
            dt1: "Firian",
            dt2: "",
            sy4: "ܦܝܪܝܐܢ",
            lt11: "ܦܝܪܝܐܢ",
            sy7: "ܦܶܝܪܝܰܐܢ",
            lt12: "ܦܝܪܝܐܢ",
            sy8: "",
            sy6: "ܦܝܪܝܐܢ",
            sy9: ""
        },
        {
            lt10: "ŝebyono",
            sy9: "",
            id: "5111",
            dt2: "",
            sy7: "",
            dt1: "Wille",
            sy5: "ܨܶܒܝܳܢܳܐ",
            sy4: "ܨܒܝܢܐ",
            sy8: "",
            lt11: "",
            sy6: "",
            dt3: "",
            lt12: ""
        },
        {
            id: "4774",
            sy6: "ܫܪܘܒܘܬܐ",
            dt2: "",
            dt3: "",
            sy4: "ܫܪܒܐ",
            sy8: "",
            sy9: "",
            dt1: "Veröffentlichung",
            lt12: "",
            lt10: "shrobo",
            sy5: "ܫܪܳܒܳܐ",
            sy7: "ܫܳܪܽܘܒܽܘܬ݂ܳܐ",
            lt11: "shorubutho"
        },
        {
            lt12: "",
            sy5: "ܢܽܘܩܳܡܳܐ",
            sy7: "",
            sy8: "",
            lt10: "nuqomo",
            lt11: "",
            sy9: "",
            dt3: "",
            dt2: "",
            dt1: "Rache",
            id: "3587",
            sy4: "ܢܘܩܡܐ",
            sy6: ""
        },
        {
            sy8: "",
            sy4: "ܬܚܘܝܬܐ",
            lt11: "",
            dt1: "Alibi",
            id: "5821",
            lt10: "taĥwitho",
            sy7: "",
            sy9: "",
            dt3: "",
            lt12: "",
            dt2: "",
            sy5: "ܬܰܚܘܺܝܬ݂ܳܐ",
            sy6: ""
        },
        {
            sy7: "ܣܽܘܝܳܥܳܐ ܕܡܰܠܟܽܘܬ݂ܳܐ",
            sy5: "ܥܽܘܕܪܳܢܳܐ ܕܡܰܠܟܽܘܬ݂ܳܐ",
            lt10: "ĉudrono d'malkutho",
            dt3: "",
            lt12: "",
            sy9: "",
            dt1: "Staatshilfe",
            lt11: "suyoĉo d'malkutho",
            sy8: "",
            sy6: "ܣܘܝܥܐ ܕܡܠܟܘܬܐ",
            id: "6209",
            dt2: "",
            sy4: "ܥܘܕܪܢܐ ܕܡܠܟܘܬܐ"
        },
        {
            sy4: "ܒܪܥܡܐ",
            sy9: "",
            dt2: "",
            lt10: "ܒܪܥܡܐ",
            id: "442",
            sy6: "",
            lt11: "ܒܪܥܡܐ",
            lt12: "",
            dt3: "",
            sy5: "ܒܰܪܥܰܡܳܐ",
            sy7: "",
            dt1: "Baramo",
            sy8: ""
        },
        {
            lt10: "shuloqo",
            lt11: "",
            sy4: "ܫܘܠܩܐ",
            sy8: "",
            dt3: "",
            sy7: "",
            sy5: "ܫܽܘܠܳܩܳܐ",
            lt12: "",
            sy9: "",
            id: "100",
            dt1: "Abkochen",
            dt2: "Auskochen",
            sy6: ""
        },
        {
            lt10: "ܚܐܪܘܬܐ",
            sy6: "",
            sy8: "",
            id: "2023",
            sy4: "ܚܐܪܘܬܐ",
            dt3: "",
            dt2: "",
            sy5: "ܚܺܐܪܽܘܬܰܐ",
            sy9: "",
            sy7: "",
            dt1: "Hiruta",
            lt11: "ܚܐܪܘܬܐ",
            lt12: ""
        },
        {
            sy7: "",
            lt11: "",
            lt12: "",
            sy6: "",
            sy4: "ܣ",
            sy8: "",
            dt3: "",
            sy5: "ܣ",
            id: "3778",
            dt1: "s",
            lt10: "semkath",
            sy9: "",
            dt2: ""
        },
        {
            sy6: "",
            dt1: "Flugprobe",
            dt3: "",
            id: "1344",
            lt11: "",
            sy8: "",
            lt10: "nesyon țauso",
            lt12: "",
            sy5: "ܢܣܝܢ ܛܰܘܣܳܐ",
            sy4: "ܢܣܝܢ ܛܘܣܐ",
            sy7: "",
            sy9: "",
            dt2: ""
        },
        {
            lt11: "",
            sy8: "",
            sy7: "",
            sy4: "ܙܪܥ",
            sy6: "",
            dt1: "säen",
            dt3: "",
            sy9: "",
            lt10: "zraĉ",
            lt12: "",
            id: "3795",
            sy5: "ܙܪܰܥ",
            dt2: ""
        },
        {
            sy6: "",
            sy5: "ܒܠܶܠܝܳܐ",
            sy7: "",
            lt11: "",
            dt3: "",
            sy8: "",
            dt1: "nachts",
            dt2: "",
            sy4: "ܒܠܠܝܐ",
            id: "3171",
            sy9: "",
            lt12: "",
            lt10: "blelyo"
        },
        {
            sy9: "",
            dt3: "",
            sy6: "",
            lt11: "",
            lt12: "",
            id: "1354",
            sy5: "ܨܰܝܳܪܳܐ ܡܨܰܝܰܪ ܨܽܘܪ̈ܬ݂ܳܐ",
            sy7: "",
            sy8: "",
            sy4: "ܨܝܪܐ ܡܨܝܪ ܨܘܪܬܐ",
            dt2: "",
            dt1: "Fotograf macht Fotos",
            lt10: "ŝayoro msayar ŝurotho"
        },
        {
            lt12: "",
            sy7: "",
            dt1: "Kaufmännische Schule",
            dt3: "",
            sy6: "",
            sy4: "ܡܕܪܫܬܐ ܬܓܪܝܬܐ",
            sy9: "",
            sy5: "ܡܰܕܪܰܫܬܳܐ ܬܰܓܳܪܳܝܬܳܐ",
            lt11: "",
            dt2: "",
            id: "2441",
            lt10: "madrashro tagoroyto",
            sy8: ""
        },
        {
            sy4: "ܣܠܩ",
            dt1: "steigen",
            sy7: "ܣܳܠܶܩ",
            id: "4250",
            sy6: "ܣܠܩ",
            dt2: "hinauf",
            sy5: "ܣܠܶܩ",
            dt3: "aufsteigen",
            lt10: "sleq",
            sy8: "",
            lt12: "",
            sy9: "",
            lt11: "soleq"
        },
        {
            sy7: "",
            lt11: "",
            sy5: "ܐܰܚܳܐ ܪܰܒܳܐ",
            lt12: "",
            sy8: "",
            sy6: "",
            sy9: "",
            sy4: "ܐܚܐ ܪܒܐ",
            dt3: "",
            dt1: "Großbruder",
            lt10: "aĥo rabo",
            dt2: "",
            id: "1736"
        },
        {
            sy4: "ܐܕܡ",
            dt1: "Adam",
            sy5: "ܐܰܕܰܡ",
            sy7: "ܐܳܕܳܡ",
            lt12: "ܐܕܡ",
            sy9: "",
            dt2: "",
            id: "127",
            dt3: "",
            sy8: "",
            lt10: "ܐܕܡ",
            sy6: "ܐܕܡ",
            lt11: "ܐܕܡ"
        },
        {
            dt3: "",
            dt2: "Hesekiel",
            sy9: "",
            lt12: "",
            lt10: "ܚܙܩܝܐܝܠ",
            sy8: "",
            sy6: "",
            lt11: "ܚܙܩܝܐܝܠ",
            sy7: "",
            sy5: "ܚܙܩܝܐܠ",
            dt1: "Ezekiel",
            id: "1220",
            sy4: "ܚܙܩܝܐܝܠ"
        },
        {
            dt1: "Brunnen",
            lt12: "biro",
            dt3: "",
            lt10: "gubo",
            lt11: "biro",
            sy7: "ܒܺܐܪܳܐ",
            id: "721",
            sy8: "ܒܝܪܐ",
            sy5: "ܓܽܘܒܳܐ",
            sy4: "ܓܘܒܐ",
            sy9: "ܒܺܝܪܳܐ",
            dt2: "",
            sy6: "ܒܐܪܐ"
        },
        {
            sy4: "ܐܝܚܛ",
            lt11: "",
            sy5: "ܝܺܚܶܛ",
            sy7: "",
            sy8: "",
            dt3: "",
            id: "5683",
            sy9: "",
            sy6: "",
            lt12: "",
            dt1: "abtreiben",
            dt2: "",
            lt10: "iĥeț"
        },
        {
            lt12: "",
            sy9: "",
            dt1: "mittelmäßig",
            sy8: "",
            sy4: "ܡܨܥܝܬܐ",
            sy5: "ܡܶܨܥܳܝܬܳܐ",
            lt11: "shĥimo",
            dt2: "",
            sy7: "ܫܚܺܝܡܳܐ",
            lt10: "meŝĉoyto",
            sy6: "ܫܚܝܡܐ",
            dt3: "",
            id: "6360"
        },
        {
            dt1: "Reis",
            sy8: "",
            sy9: "",
            lt10: "ruzo",
            dt2: "",
            sy6: "",
            sy7: "",
            lt11: "",
            id: "3677",
            sy5: "ܪܽܘܙܳܐ",
            dt3: "",
            sy4: "ܪܘܙܐ",
            lt12: ""
        },
        {
            dt3: "",
            sy9: "",
            sy5: "ܣܺܝܡܰܬ ܒܢܰܝܳܐ",
            sy6: "ܣܝܘܡܘܬ ܒܢܝܐ",
            dt2: "",
            sy7: "ܣܳܝܽܘܡܽܘܬ݂ ܒܢܰܝܳܐ",
            lt10: "simat bnayo",
            dt1: "Adoption",
            sy8: "",
            id: "5760",
            lt11: "soyumuth bnayo",
            lt12: "",
            sy4: "ܣܝܡܬ ܒܢܝܐ"
        },
        {
            dt3: "Brüste",
            sy8: "",
            sy5: "ܬܕܰܝܳܐ",
            sy4: "ܬܕܝܐ",
            sy9: "",
            lt10: "tđayo",
            lt11: "",
            sy7: "",
            lt12: "",
            dt1: "Mutterbrust",
            dt2: "Busen",
            sy6: "",
            id: "3135"
        },
        {
            id: "6410",
            sy6: "",
            dt2: "",
            sy4: "ܬܒܪ ܓܠܝܕܐ",
            sy9: "",
            sy7: "",
            lt11: "",
            sy8: "",
            sy5: "ܬܳܒܰܪ ܓܠܺܝܕܳܐ",
            dt3: "",
            dt1: "Eisbrecher",
            lt10: "tobar gliđo",
            lt12: ""
        },
        {
            sy7: "",
            dt1: "Flöte",
            lt11: "",
            sy5: "ܡܰܫܪܽܘܩܳܐ",
            id: "1335",
            sy4: "ܡܫܪܘܩܐ",
            sy9: "",
            sy6: "",
            dt3: "",
            sy8: "",
            lt12: "",
            lt10: "mashruqo",
            dt2: "Blockflöte"
        },
        {
            lt10: "țeloro",
            dt1: "Sandale",
            sy9: "",
            lt11: "",
            dt2: "",
            id: "3832",
            sy7: "",
            lt12: "",
            sy8: "",
            sy4: "ܛܠܪܐ",
            sy6: "",
            sy5: "ܛܶܠܳܪܳܐ",
            dt3: ""
        },
        {
            sy9: "",
            dt2: "Sechshundert",
            lt12: "",
            sy6: "ܬܪ",
            lt11: "taw-rish",
            dt3: "",
            dt1: "600",
            id: "43",
            sy4: "ܫܬܡܐܐ",
            sy7: "ܬܪ",
            sy5: "ܫܶܬ݂ܡܳܐܐ ",
            sy8: "",
            lt10: "shethmo"
        },
        {
            sy6: "",
            sy8: "",
            lt11: "",
            sy5: "ܡܬܰܠܰܝܬܳܐ",
            lt10: "mtalayto",
            sy9: "",
            dt1: "Bügel",
            dt3: "",
            dt2: "Haken",
            sy4: "ܡܬܠܝܬܐ",
            lt12: "",
            sy7: "",
            id: "732"
        },
        {
            dt3: "",
            sy4: "ܩܡܪܐ ܕܠܛܫܐ",
            lt11: "",
            sy7: "",
            lt10: "qamro da´lțosho",
            sy5: "ܩܰܡܪܳܐ ܕܰܠܛܳܫܳܐ",
            sy8: "",
            sy6: "",
            dt2: "",
            lt12: "",
            sy9: "",
            dt1: "Schleifriemen",
            id: "3967"
        },
        {
            sy7: "",
            dt3: "",
            id: "3252",
            dt2: "",
            lt10: "ĥatho",
            lt12: "",
            sy5: "ܚܰܕܬ݂ܳܐ",
            lt11: "",
            dt1: "neu",
            sy4: "ܚܕܬܐ",
            sy9: "",
            sy6: "",
            sy8: ""
        },
        {
            sy5: "ܣܽܘܪܳܕܳܝܳܐ",
            id: "4441",
            sy9: "",
            sy6: "",
            dt1: "Terrorist",
            dt2: "",
            lt12: "",
            sy4: "ܣܘܪܕܝܐ",
            dt3: "",
            lt10: "surodoyo",
            sy7: "",
            lt11: "",
            sy8: ""
        },
        {
            lt11: "",
            sy8: "",
            dt2: "",
            sy6: "",
            id: "3567",
            sy5: "ܡܶܢܝܳܢܳܐ ܕܡܪܰܒܥܽܘܬ݂ܳܐ",
            lt10: "menyono da`mrabĉutho",
            sy7: "",
            lt12: "",
            dt1: "Quadratzahl",
            sy4: "ܡܢܝܢܐ ܕܡܪܒܥܘܬܐ",
            sy9: "",
            dt3: ""
        },
        {
            lt11: "ܫܠܝܘܬܐ",
            lt12: "",
            dt2: "",
            dt1: "Schalyuta",
            sy4: "ܫܠܝܘܬܐ",
            lt10: "ܫܠܝܘܬܐ",
            sy9: "",
            sy6: "",
            dt3: "",
            sy5: "ܫܰܠܝܽܘܬܰܐ",
            id: "3890",
            sy8: "",
            sy7: ""
        },
        {
            sy7: "",
            sy6: "",
            sy9: "",
            lt12: "",
            dt3: "",
            dt2: "",
            lt11: "ܐܣܪܬܐ",
            sy5: "ܐܶܣܳܪܬܰܐ",
            dt1: "Esorta",
            sy4: "ܐܣܪܬܐ",
            sy8: "",
            lt10: "ܐܣܪܬܐ",
            id: "1192"
        },
        {
            sy6: "ܕܟܘܡܐ",
            lt11: "dokumo",
            id: "1269",
            sy7: "ܕܳܟܽܘܡܳܐ",
            sy9: "",
            dt1: "Faust",
            lt12: "",
            dt2: "",
            lt10: "doquro",
            dt3: "",
            sy4: "ܕܩܘܪܐ",
            sy8: "",
            sy5: "ܕܳܩܽܘܪܳܐ"
        },
        {
            sy7: "",
            lt12: "",
            sy8: "",
            dt2: "Pferdekutsche",
            sy5: "ܥܳܓܰܠܬ݂ܳܐ",
            lt11: "",
            sy6: "",
            id: "2682",
            sy9: "",
            dt3: "",
            sy4: "ܥܓܠܬܐ",
            dt1: "Kutsche",
            lt10: "ĉogaltho"
        },
        {
            id: "6287",
            dt2: "",
            lt11: "sdaq",
            sy9: "ܣܰܕܶܩ",
            dt3: "",
            lt12: "sadeq",
            sy6: "ܣܕܩ",
            lt10: "farem",
            dt1: "zerteilen",
            sy4: "ܦܪܡ",
            sy8: "ܣܕܩ",
            sy5: "ܦܰܪܶܡ",
            sy7: "ܣܕܰܩ"
        },
        {
            sy4: "ܩܘܡܐ",
            dt1: "Revolution",
            lt12: "",
            sy6: "ܩܘܡܘܬܐ",
            dt3: "",
            sy9: "",
            lt11: "qauwmutho",
            id: "3710",
            lt10: "qauwmo",
            sy5: "ܩܰܘܡܳܐ",
            sy8: "",
            dt2: "Aufstand",
            sy7: "ܩܰܘܡܽܘܬ݂ܳܐ"
        },
        {
            sy7: "",
            sy4: "ܥܕ",
            lt12: "",
            dt3: "",
            lt10: "çad",
            sy8: "",
            sy5: "ܥܰܕ",
            dt1: "solange als",
            sy6: "",
            lt11: "",
            sy9: "",
            id: "5538",
            dt2: ""
        },
        {
            dt2: "Moderator",
            sy8: "",
            sy7: "",
            lt12: "",
            lt11: "",
            sy9: "",
            lt10: "maudĉono",
            sy6: "",
            sy5: "ܡܰܘܕܥܳܢܳܐ   ",
            dt3: "",
            id: "2200",
            sy4: "ܡܘܕܥܢܐ",
            dt1: "Informant"
        },
        {
            lt12: "tanwi",
            lt10: "waĉed",
            dt3: "",
            sy4: "ܘܥܕ",
            lt11: "sharar",
            id: "5657",
            dt1: "absprechen",
            dt2: "",
            sy5: "ܘܰܥܶܕ",
            sy6: "ܫܪܪ",
            sy7: "ܫܰܪܰܪ",
            sy8: "ܬܢܘܝ",
            sy9: "ܬܰܢܘܺܝ"
        },
        {
            id: "5495",
            lt11: "",
            dt1: "Dividende",
            sy7: "",
            lt12: "",
            sy8: "",
            lt10: "mnoth yuthrono",
            dt3: "",
            dt2: "",
            sy4: "ܡܢܬ ܝܘܬܪܢܐ",
            sy9: "",
            sy6: "",
            sy5: "ܡܢܳܬ݂ ܝܽܘܬ݂ܪܳܢܳܐ"
        },
        {
            sy5: "ܐܰܒܓܰܪ",
            sy6: "",
            lt12: "",
            dt1: "Abgar",
            lt11: "ܐܒܓܪ",
            lt10: "ܐܒܓܪ",
            dt3: "",
            dt2: "",
            id: "84",
            sy8: "",
            sy7: "",
            sy9: "",
            sy4: "ܐܒܓܪ"
        },
        {
            sy9: "",
            id: "2511",
            sy7: "",
            dt1: "Kleidung",
            lt11: "",
            dt3: "Klamotten",
            sy5: "ܠܒܽܘ̈ܫܶܐ",
            lt12: "",
            sy6: "",
            lt10: "lbushe",
            sy4: "ܠܒܘܫܐ",
            dt2: "Kleider",
            sy8: ""
        },
        {
            sy7: "",
            sy4: "ܡܐܟܢܐ ܕܓܪܥܐ",
            dt3: "",
            lt12: "",
            sy8: "",
            dt1: "Rasiermaschine",
            sy9: "",
            id: "3607",
            dt2: "",
            sy6: "",
            lt10: "makina d`groĉo",
            sy5: "ܡܰܐܟܺܢܰܐ ܕܓܪܳܥܳܐ",
            lt11: ""
        },
        {
            sy9: "",
            sy4: "ܓܠܐ",
            dt1: "Gallo",
            lt10: "ܓܠܐ",
            sy8: "",
            id: "1466",
            dt2: "",
            sy6: "ܓܠܠܐ",
            dt3: "",
            lt11: "ܓܠܐ",
            lt12: "ܓܠܠܐ",
            sy5: "ܓܰܠܳܐ",
            sy7: "ܓܰܠܠܳܐ"
        },
        {
            sy7: "",
            sy6: "",
            lt11: "",
            dt1: "Volljährigkeit",
            dt2: "",
            dt3: "",
            sy8: "",
            lt10: "shalmutho",
            sy9: "",
            id: "4873",
            lt12: "",
            sy4: "ܫܠܡܘܬܐ",
            sy5: "ܫܰܠܡܽܘܬ݂ܳܐ"
        },
        {
            sy5: "ܓܶܠܝܽܘܢܳܪܳܐ",
            lt12: "",
            sy6: "",
            sy8: "",
            lt10: "gelyonoro",
            lt11: "",
            dt2: "Redakteur",
            dt3: "",
            id: "2342",
            dt1: "Journalist",
            sy4: "ܓܠܝܘܢܪܐ",
            sy7: "",
            sy9: ""
        },
        {
            sy8: "",
            dt3: "",
            sy4: "ܡܟܝܟܘܬܐ",
            sy9: "",
            sy5: "ܡܰܟܺܝܟ݂ܽܘܬ݂ܳܐ",
            lt11: "",
            id: "3837",
            sy7: "",
            sy6: "",
            lt10: "makikhutho",
            lt12: "",
            dt2: "",
            dt1: "Sanftmut"
        },
        {
            id: "1650",
            sy4: "ܩܪܚܐ",
            dt2: "haarlos",
            sy6: "",
            lt10: "qroĥo",
            lt12: "",
            sy5: "ܩܪܳܚܳܐ",
            sy7: "",
            lt11: "",
            dt3: "",
            dt1: "Glatze",
            sy9: "",
            sy8: ""
        },
        {
            dt2: "",
            sy5: "ܫܰܘܫܺܝ",
            lt11: "",
            sy4: "ܫܘܫܝ",
            lt12: "",
            sy8: "",
            id: "5739",
            sy9: "",
            sy7: "",
            dt1: "ächzen",
            dt3: "",
            lt10: "shaushi",
            sy6: ""
        },
        {
            dt2: "",
            lt10: "kthobo",
            sy8: "",
            sy5: "ܟܬ݂ܳܒܳܐ",
            sy4: "ܟܬܒܐ",
            id: "724",
            sy6: "ܦܢܩܝܬܐ",
            dt3: "",
            lt11: "fenqitho",
            sy9: "",
            lt12: "",
            dt1: "Buch",
            sy7: "ܦܶܢܩܺܝܬ݂ܳܐ"
        },
        {
            sy4: "ܥܡܢ",
            lt10: "ܥܡܢ",
            lt12: "",
            sy7: "",
            dt1: "Aman",
            sy5: "ܥܰܡܰܢ",
            sy6: "",
            id: "204",
            sy9: "",
            lt11: "ܥܡܢ",
            dt3: "",
            sy8: "",
            dt2: ""
        },
        {
            id: "2821",
            sy8: "",
            lt10: "ĥabibo",
            sy4: "ܚܒܝܒܐ",
            dt2: "Liebster",
            sy7: "ܪܚܺܝܡܳܐ",
            dt1: "Liebling",
            sy9: "",
            dt3: "",
            sy6: "ܪܚܝܡܐ",
            lt12: "",
            lt11: "rĥimo",
            sy5: "ܚܰܒܺܝܒܳܐ"
        },
        {
            sy4: "ܓܠܝܪܘܬܐ",
            lt12: "",
            lt10: "gelyorutho",
            dt1: "Mob",
            dt2: "",
            sy8: "",
            lt11: "",
            sy7: "",
            sy6: "",
            sy9: "",
            sy5: "ܓܶܠܝܳܪܽܘܬ݂ܳܐ",
            id: "6376",
            dt3: ""
        },
        {
            dt1: "Inakzeptanz",
            id: "2194",
            sy4: "ܠܐ ܡܩܒܠܘܬܐ",
            sy6: "",
            sy8: "",
            dt3: "",
            sy5: "ܠܳܐ ܡܩܰܒܠܽܘܬ݂ܳܐ",
            lt10: "lo mqablutho",
            sy9: "",
            sy7: "",
            dt2: "",
            lt11: "",
            lt12: ""
        },
        {
            dt2: "",
            sy4: "ܚܬܐ",
            sy8: "",
            lt12: "",
            lt11: "",
            lt10: "ĥotho",
            sy7: "",
            sy9: "",
            sy5: "ܚܳܬ݂ܳܐ",
            dt3: "",
            dt1: "Schwester",
            id: "4078",
            sy6: ""
        },
        {
            dt2: "bedecken",
            dt1: "verdecken",
            lt11: "",
            sy9: "",
            lt12: "",
            sy4: "ܐܡܠ",
            lt10: "amel",
            sy5: "ܐܰܡܶܠ",
            sy7: "",
            sy8: "",
            dt3: "verschleiern",
            sy6: "",
            id: "4723"
        },
        {
            sy8: "",
            lt11: "",
            sy7: "",
            sy6: "",
            sy5: "ܫܒܽܘܩ ܠܺܝ ܕܶܐܗܘܳܐ ܡܰܛܝܳܐ",
            dt2: "",
            sy4: "ܫܒܽܘܩ ܠܺܝ ܕܶܐܗܘܳܐ ܡܰܛܝܳܐ",
            dt1: "Lass mich reif werden",
            lt12: "",
            sy9: "",
            id: "5446",
            dt3: "",
            lt10: "shbuq li dehwe mațyo"
        },
        {
            sy8: "",
            lt10: "ܫܡܘܐܝܠ",
            sy7: "",
            dt3: "",
            lt11: "ܫܡܘܐܝܠ",
            lt12: "",
            id: "3986",
            dt1: "Schmuel",
            sy9: "",
            sy6: "",
            dt2: "",
            sy5: "ܫܡܽܘܐܝܶܠ",
            sy4: "ܫܡܘܐܝܠ"
        },
        {
            lt11: "tani",
            dt3: "",
            sy6: "ܬܢܝ",
            sy8: "",
            sy5: "ܬܢܳܐ",
            dt2: "",
            lt10: "tno",
            lt12: "",
            id: "1179",
            sy9: "",
            sy7: "ܬܰܢܺܝ",
            sy4: "ܬܢܐ",
            dt1: "erzählen"
        },
        {
            id: "782",
            sy9: "",
            sy4: "ܬܛܠܝܠܐ",
            dt3: "",
            lt12: "",
            sy6: "ܐܓܪܐ",
            lt10: "tațlilo",
            dt1: "Dach",
            sy5: "ܬܰܛܠܺܝܠܳܐ",
            dt2: "Hausdach",
            sy8: "",
            sy7: "ܐܶܓܳܪܳܐ",
            lt11: "egoro"
        },
        {
            id: "5979",
            lt10: "mțoyo",
            lt12: "methyo",
            lt11: "muțoĉo",
            sy8: "ܡܐܬܝܐ",
            sy4: "ܡܛܝܐ",
            sy7: "ܡܽܘܛܳܥܳܐ",
            sy6: "ܡܘܛܥܐ",
            dt3: "",
            dt1: "Anreise",
            sy5: "ܡܛܳܝܳܐ",
            dt2: "",
            sy9: "ܡܶܐܬ݂ܝܳܐ"
        },
        {
            lt12: "",
            lt11: "",
            sy9: "",
            id: "1784",
            sy5: "ܡܶܫܚܳܐ ܕܣܰܥܪܳܐ",
            dt3: "",
            sy4: "ܡܫܚܐ ܕܣܥܪܐ",
            dt2: "",
            lt10: "meshĥo d`saĉro",
            sy7: "",
            sy6: "",
            sy8: "",
            dt1: "Haaröl"
        },
        {
            dt1: "gutes Resultat",
            id: "1777",
            lt11: "",
            sy8: "",
            lt10: "ĥartho țobtho",
            lt12: "",
            sy6: "",
            dt3: "",
            sy5: "ܚܰܪܬ݂ܳܐ ܛܳܒܬ݂ܳܐ",
            sy7: "",
            sy9: "",
            dt2: "gutes Ergebnis",
            sy4: "ܚܪܬܐ ܛܒܬܐ"
        },
        {
            sy9: "",
            sy6: "ܕܚܠ",
            sy8: "",
            sy4: "ܠܚܡ",
            sy5: "ܠܰܚܶܡ",
            lt10: "laĥem",
            lt12: "",
            dt1: "androhen",
            id: "5941",
            dt2: "",
            dt3: "",
            sy7: "ܕܰܚܶܠ",
            lt11: "daĥel"
        },
        {
            lt12: "",
            sy5: "ܥܽܘܦܳܝܳܐ",
            sy8: "",
            id: "522",
            dt3: "",
            sy9: "",
            lt10: "ĉufoyo",
            lt11: "lewoyo",
            dt1: "Beerdigung",
            dt2: "Begräbnis",
            sy4: "ܥܘܦܝܐ",
            sy6: "ܠܘܝܐ",
            sy7: "ܠܶܘܳܝܳܐ"
        },
        {
            sy4: "ܬܓܠܝܬܐ",
            lt10: "taglitho",
            lt12: "",
            sy7: "",
            sy5: "ܬܰܓܠܺܝܬ݂ܳܐ",
            dt1: "Demonstration",
            sy9: "",
            sy6: "",
            dt2: "",
            id: "855",
            dt3: "",
            sy8: "",
            lt11: ""
        },
        {
            sy8: "",
            dt1: "Meine Seele ist meine Waffe",
            sy4: "ܪܘܚܐ ܕܝܠܝ ܐܝܬܝܗ ܙܝܢܐ ܕܝܠܝ",
            sy7: "",
            lt11: "",
            sy9: "",
            lt10: "ruĥo dil ithe zayno dil",
            lt12: "",
            sy6: "",
            sy5: "ܪܽܘܚܳܐ ܕܺܝܠܝ̱ ܐܺܝܬ݂ܶܝܗ ܙܰܝܢܳܐ ܕܺܝܠܝ̱",
            dt3: "",
            dt2: "",
            id: "2992"
        },
        {
            lt11: "skhar",
            sy9: "",
            id: "114",
            sy7: "ܣܟ݂ܰܪ",
            dt3: "",
            lt10: "aqleđ",
            sy4: "ܐܩܠܕ",
            dt2: "abschliessen",
            lt12: "",
            sy8: "",
            dt1: "abschließen",
            sy6: "ܣܟܪ",
            sy5: "ܐܰܩܠܶܕ"
        },
        {
            dt3: "",
            lt11: "ܕܕܐ",
            lt12: "",
            lt10: "ܕܕܐ",
            id: "910",
            sy7: "",
            sy5: "ܕܳܕܳܐ",
            dt1: "Dodo",
            sy6: "",
            dt2: "",
            sy9: "",
            sy8: "",
            sy4: "ܕܕܐ"
        },
        {
            dt2: "",
            id: "5242",
            sy7: "",
            lt11: "ܙܗܪܝܪܐ",
            sy9: "",
            sy6: "",
            sy4: "ܙܗܪܝܪܐ",
            sy8: "",
            dt1: "Zahriro",
            lt10: "ܙܗܪܝܪܐ",
            lt12: "",
            dt3: "",
            sy5: "ܙܰܗܪܺܝܪܳܐ"
        },
        {
            dt1: "Sofro",
            sy5: "ܣܳܦܪܳܐ",
            sy7: "",
            id: "4180",
            dt3: "",
            sy9: "",
            sy4: "ܣܦܪܐ",
            dt2: "",
            lt11: "ܣܦܪܐ",
            sy8: "",
            lt10: "ܣܦܪܐ",
            sy6: "",
            lt12: ""
        },
        {
            dt2: "Yeside",
            lt11: "",
            sy9: "",
            lt12: "",
            sy6: "",
            dt1: "Jeside",
            dt3: "",
            lt10: "tshalkoyo",
            sy5: "ܬܫܰܠܟܳܝܳܐ",
            sy4: "ܬܫܠܟܝܐ",
            sy8: "",
            id: "2315",
            sy7: ""
        },
        {
            sy6: "ܪܝܚܐܢ",
            id: "3721",
            sy5: "ܪܺܝܚܰܢ",
            dt3: "",
            dt1: "Rihan",
            lt10: "ܪܝܚܢ",
            sy7: "ܪܺܝܚܐܰܢ",
            sy4: "ܪܝܚܢ",
            lt11: "ܪܝܚܢ",
            sy8: "",
            lt12: "ܪܝܚܐܢ",
            dt2: "",
            sy9: ""
        },
        {
            dt2: "",
            sy6: "",
            sy5: "ܡܫܺܝܚ̈ܝܶܐ",
            lt11: "",
            sy8: "",
            sy7: "",
            sy9: "",
            dt1: "Christen",
            lt10: "mshiĥoye",
            id: "767",
            lt12: "",
            dt3: "",
            sy4: "ܡܫܝܚܝܐ"
        },
        {
            sy9: "",
            sy5: "ܩܳܢܽܘܢܳܐ ܪܺܝܫܳܝܳܐ",
            lt12: "",
            sy6: "",
            dt1: "Hauptregel",
            sy8: "",
            lt10: "qonuno rishoyo",
            id: "1890",
            lt11: "",
            sy4: "ܩܢܘܢܐ ܪܝܫܝܐ",
            dt2: "",
            dt3: "",
            sy7: ""
        },
        {
            id: "2434",
            sy9: "",
            sy7: "",
            sy4: "ܠܘܥܣܐ",
            dt3: "",
            sy6: "",
            lt11: "",
            lt12: "",
            sy8: "",
            sy5: "ܠܽܘܥܳܣܳܐ",
            dt2: "",
            lt10: "luĉoso",
            dt1: "Kauen"
        },
        {
            dt1: "Dekorateurin",
            lt10: "roŝufto",
            sy5: "ܪܳܨܽܘܦܬܳܐ",
            sy9: "",
            lt11: "",
            sy6: "",
            lt12: "",
            id: "848",
            sy8: "",
            dt2: "",
            dt3: "",
            sy7: "",
            sy4: "ܪܨܘܦܬܐ"
        },
        {
            sy9: "",
            sy7: "",
            lt11: "",
            sy4: "ܥܪܒ",
            sy8: "",
            sy6: "",
            lt10: "ĉareb",
            sy5: "ܥܰܪܶܒ",
            id: "1473",
            dt2: "",
            lt12: "",
            dt1: "garantieren",
            dt3: ""
        },
        {
            lt12: "",
            sy7: "",
            sy9: "",
            "date": {
                "seconds": 1590849390,
                "nanoseconds": 302000000
            },
            sy4: "ܫܦܝܪܐ ܝܐ",
            lt11: "",
            dt3: "",
            dt2: "Er ist schön",
            sy5: "ܫܰܦܺܝܪܳܐ ܝܳܐ",
            dt1: "Er ist hübsch",
            sy8: "",
            lt10: "Shafiro yo",
            sy6: "",
            id: "m2mj4GtCzASfxPEid8Rm"
        },
        {
            sy4: "ܫܟܘܟܐ",
            dt3: "",
            lt11: "",
            sy6: "",
            lt10: "shakuko",
            dt2: "",
            sy5: "ܫܰܟܽܘܟܳܐ",
            dt1: "Wäscheklammer",
            sy8: "",
            sy9: "",
            sy7: "",
            lt12: "",
            id: "4994"
        },
        {
            lt10: "murmoro",
            dt2: "",
            sy5: "ܡܽܘܪܡܳܪܳܐ",
            sy7: "ܥܰܝܛܳܐ",
            lt12: "fuyoro",
            sy8: "ܦܘܝܪܐ",
            lt11: "ĉayțo",
            dt1: "Zorn",
            sy9: "ܦܽܘܝܳܪܳܐ",
            sy4: "ܡܘܪܡܪܐ",
            dt3: "",
            sy6: "ܥܝܛܐ",
            id: "6300"
        },
        {
            sy6: "",
            lt12: "",
            dt2: "",
            sy8: "",
            lt11: "",
            dt1: "Zollamt",
            id: "6298",
            sy4: "ܡܟܬܒ ܡܟܣܐ",
            dt3: "",
            lt10: "maktab mokhse",
            sy5: "ܡܰܟܬܰܒ ܡܳܟ݂̈ܣܶܐ",
            sy9: "",
            sy7: ""
        },
        {
            lt11: "",
            lt12: "",
            id: "2211",
            sy9: "",
            sy8: "",
            sy7: "",
            dt1: "Insolvenz",
            sy4: "ܠܐ ܡܬܦܪܥܢܘܬܐ",
            dt3: "",
            lt10: "lo methfarĉonutho",
            dt2: "",
            sy6: "",
            sy5: "ܠܳܐ ܡܶܬ݂ܦܪܥܳܢܽܘܬ݂ܳܐ"
        },
        {
            sy7: "ܓܰܘܳܢܳܐܝܺܬ݂",
            sy4: "ܓܘܢܝܐ",
            sy8: "",
            lt10: "gawonoyo",
            lt11: "gawonoith",
            lt12: "",
            sy6: "ܓܘܢܐܝܬ",
            dt2: "",
            dt3: "",
            id: "189",
            dt1: "allgemein",
            sy5: "ܓܰܘܳܢܳܝܳܐ",
            sy9: ""
        },
        {
            sy7: "",
            dt2: "",
            sy4: "ܕܒܫܢܐ",
            sy9: "",
            id: "2049",
            dt3: "",
            lt11: "",
            sy8: "",
            sy6: "",
            dt1: "honigsüß",
            lt10: "debshono",
            sy5: "ܕܶܒܫܳܢܳܐ",
            lt12: ""
        },
        {
            dt3: "",
            lt10: "",
            sy9: "",
            sy7: "",
            sy8: "",
            sy4: "",
            dt2: "",
            lt11: "",
            dt1: "ܢܫܪܝܬܐ",
            sy6: "",
            lt12: "",
            id: "739",
            sy5: ""
        },
        {
            sy5: "ܣܰܒܪܺܝܢܰܐ",
            dt3: "",
            lt10: "ܣܒܪܝܢܐ",
            sy4: "ܣܒܪܝܢܐ",
            sy9: "",
            dt1: "Sabrina",
            id: "3790",
            dt2: "",
            lt12: "ܙܒܪܝܢܐ",
            sy6: "ܙܒܪܝܢܐ",
            sy8: "",
            sy7: "ܙܰܒܪܺܝܢܰܐ",
            lt11: "ܣܒܪܝܢܐ"
        },
        {
            lt11: "",
            dt2: "",
            id: "1701",
            sy9: "",
            sy4: "ܕܚܠܬ ܐܠܗܐ",
            sy8: "",
            dt3: "",
            sy5: "ܕܶܚܠܰܬ݂ ܐܰܠܳܗܳܐ",
            sy6: "",
            lt10: "deĥlath aloho",
            sy7: "",
            lt12: "",
            dt1: "Gottesfurcht"
        },
        {
            lt10: "shmuĉto",
            id: "1576",
            lt11: "",
            sy9: "",
            dt2: "Meldung",
            sy5: "ܫܡܽܘܥܬܳܐ",
            dt1: "Gerücht",
            sy4: "ܫܡܘܥܬܐ",
            dt3: "",
            lt12: "",
            sy6: "",
            sy7: "",
            sy8: ""
        },
        {
            sy7: "ܩܽܘܪܳܒܳܐ",
            dt2: "Dienst",
            sy8: "",
            lt12: "",
            sy5: "ܬܶܫܡܶܫܬܳܐ",
            lt11: "qurobo",
            dt3: "",
            id: "1700",
            dt1: "Gottesdienst",
            lt10: "teshmeshto",
            sy6: "ܩܘܪܒܐ",
            sy4: "ܬܫܡܫܬܐ",
            sy9: ""
        },
        {
            lt11: "ܕܪܐ",
            id: "816",
            sy6: "",
            lt12: "",
            sy7: "",
            sy9: "",
            dt2: "",
            dt3: "",
            lt10: "ܕܪܐ",
            sy8: "",
            sy4: "ܕܪܐ",
            dt1: "Daro",
            sy5: "ܕܰܪܳܐ"
        },
        {
            lt10: "țiloyto",
            sy4: "ܛܝܠܝܬܐ",
            sy7: "",
            dt2: "",
            lt12: "",
            lt11: "",
            sy9: "",
            sy5: "ܛܺܝܠܳܝܬܳܐ",
            dt1: "Schmetterling",
            sy8: "",
            id: "3982",
            sy6: "",
            dt3: ""
        },
        {
            id: "860",
            sy5: "ܪܢܳܐ",
            sy4: "ܪܢܐ",
            sy6: "",
            sy9: "",
            dt1: "denken",
            lt12: "",
            lt10: "rno",
            lt11: "",
            dt2: "",
            dt3: "",
            sy7: "",
            sy8: ""
        },
        {
            dt3: "",
            sy4: "ܝܩܝܕܐ",
            lt11: "ŝafifo",
            lt10: "yaqido",
            dt1: "angebrannt",
            id: "5922",
            sy7: "ܨܰܦܺܝܦܳܐ",
            sy8: "",
            sy6: "ܨܦܝܦܐ",
            sy9: "",
            sy5: "ܝܰܩܺܝܕܳܐ",
            lt12: "",
            dt2: ""
        },
        {
            dt2: "",
            sy6: "ܐܟܪܐ",
            lt10: "akoro",
            sy5: "ܐܰܟܳܪܳܐ",
            sy7: "ܐܰܟܳܪܳܐ",
            sy4: "ܐܟܪܐ",
            dt3: "",
            lt12: "",
            sy8: "",
            sy9: "",
            lt11: "akoro",
            dt1: "Bauer",
            id: "510"
        },
        {
            sy8: "",
            lt11: "",
            lt10: "bar sheğmo",
            lt12: "",
            id: "5877",
            sy7: "",
            dt1: "Amateur",
            dt2: "",
            sy6: "",
            dt3: "",
            sy4: "ܒܪ ܫܓܡܐ",
            sy9: "",
            sy5: "ܒܰܪ ܫܶܓ݂ܡܳܐ"
        },
        {
            dt2: "",
            sy4: "ܫܘܪܝܢܢ",
            sy9: "",
            sy6: "",
            id: "5124",
            sy7: "",
            dt1: "Wir springen",
            sy5: "ܫܳܘܪܺܝܢܰܢ",
            dt3: "",
            lt11: "",
            lt10: "shorinan",
            lt12: "",
            sy8: ""
        },
        {
            lt10: "bisho",
            sy7: "",
            sy9: "",
            id: "680",
            sy5: "ܒܺܝܫܳܐ",
            sy8: "",
            lt12: "",
            dt3: "",
            sy4: "ܒܝܫܐ",
            dt1: "böse",
            lt11: "",
            sy6: "",
            dt2: "schlecht"
        },
        {
            lt10: "ĥđonoy attho",
            id: "3084",
            sy4: "ܚܕܢܝ ܐܢܬܬܐ",
            sy8: "",
            sy9: "",
            sy5: "ܚܕܳܢܳܝ ܐܰܢ̱ܬܬ݂ܳܐ",
            sy6: "",
            lt11: "",
            dt2: "",
            dt3: "",
            sy7: "",
            dt1: "monogam",
            lt12: ""
        },
        {
            dt2: "",
            sy7: "",
            sy8: "",
            lt12: "",
            dt3: "",
            lt10: "semkath",
            lt11: "",
            sy5: "ܣ",
            sy6: "",
            dt1: "ss",
            sy9: "",
            sy4: "ܣ",
            id: "4228"
        },
        {
            id: "4973",
            dt3: "",
            sy9: "",
            sy8: "",
            dt1: "Walnuss",
            sy7: "",
            sy5: "ܓܰܘܙܳܐ",
            lt11: "",
            lt10: "gauzo",
            lt12: "",
            sy6: "",
            dt2: "Walnuß",
            sy4: "ܓܘܙܐ"
        },
        {
            sy9: "",
            lt12: "",
            dt2: "",
            sy8: "",
            dt3: "",
            sy4: "ܐܘܡܬܐ",
            dt1: "Nation",
            lt10: "umtho",
            sy7: "",
            sy6: "",
            id: "3216",
            sy5: "ܐܽܘܡܬ݂ܳܐ",
            lt11: ""
        },
        {
            sy9: "",
            lt11: "",
            dt2: "",
            dt3: "",
            sy7: "",
            sy4: "ܚܒܪܬܐ ܫܪܝܪܬܐ",
            sy6: "",
            id: "4951",
            dt1: "wahre Freundin",
            lt10: "ĥbartho sharirto",
            lt12: "",
            sy5: "ܚܒܰܪܬ݂ܳܐ ܫܰܪܺܝܪܬܳܐ",
            sy8: ""
        },
        {
            dt2: "",
            dt1: "Wachs",
            sy7: "",
            sy8: "",
            sy9: "",
            id: "4920",
            sy4: "ܫܡܥܐ",
            sy5: "ܫܰܡܥܳܐ",
            sy6: "",
            lt11: "",
            lt10: "shamĉo",
            lt12: "",
            dt3: ""
        },
        {
            id: "5959",
            lt12: "",
            dt3: "",
            lt10: "nqash",
            sy9: "",
            sy7: "ܐܶܬ݂ܟܰܫܰܦ",
            sy6: "ܐܬܟܫܦ",
            dt2: "",
            sy5: "ܢܩܰܫ",
            dt1: "anklopfen",
            lt11: "ethkashaf",
            sy8: "",
            sy4: "ܢܩܫ"
        },
        {
            id: "2266",
            sy6: "ܝܕܥܝܠ",
            lt11: "ܝܕܥܐܝܠ",
            sy7: "ܝܰܕܥܺܝܶܠ",
            lt10: "ܝܕܥܐܝܠ",
            dt3: "",
            lt12: "ܝܕܥܝܠ",
            dt2: "",
            sy4: "ܝܕܥܐܝܠ",
            dt1: "Jadiael",
            sy5: "ܝܰܕܥܳܐܝܶܠ",
            sy8: "",
            sy9: ""
        },
        {
            sy8: "",
            sy9: "",
            lt10: "froth",
            dt3: "",
            id: "1200",
            sy7: "",
            lt12: "",
            lt11: "",
            sy4: "ܦܪܬ",
            dt2: "Eufrat",
            sy5: "ܦܪܳܬ݂",
            dt1: "Euphrat",
            sy6: ""
        },
        {
            lt11: "",
            sy5: "ܓܠܰܣ",
            dt3: "",
            sy6: "",
            sy7: "",
            lt12: "",
            dt1: "annullieren",
            sy8: "",
            id: "5971",
            dt2: "",
            sy9: "",
            lt10: "glas",
            sy4: "ܓܠܣ"
        },
        {
            dt2: "",
            dt3: "",
            sy6: "",
            lt10: "man ezal lĉito",
            sy4: "ܡܢ ܐܙܠ ܠܥܕܬܐ",
            lt11: "",
            sy5: "ܡܰܢ ܐܶܙܰܠ ܠܥܺܕܬܳܐ",
            lt12: "",
            sy8: "",
            sy9: "",
            dt1: "Wer ging zur Kirche",
            id: "5067",
            sy7: ""
        },
        {
            dt3: "",
            sy7: "",
            sy9: "",
            lt12: "",
            id: "2383",
            sy6: "",
            lt11: "ܟܟܪܝܬܐ",
            sy4: "ܟܟܪܝܬܐ",
            lt10: "ܟܟܪܝܬܐ",
            dt2: "",
            sy5: "ܟܰܟܳܪܺܝܬܰܐ",
            sy8: "",
            dt1: "Kakorita"
        },
        {
            dt3: "",
            sy9: "",
            sy5: "ܡܶܬ݂ܩܰܒܠܳܢܳܐ",
            sy6: "",
            lt10: "methqablono",
            id: "5810",
            dt1: "akzeptabel",
            sy7: "",
            sy4: "ܡܬܩܒܠܢܐ",
            lt12: "",
            dt2: "",
            lt11: "",
            sy8: ""
        },
        {
            dt3: "",
            dt1: "Abrieb",
            lt10: "shaufo",
            id: "5645",
            dt2: "",
            lt11: "",
            sy4: "ܫܘܦܐ",
            sy8: "",
            lt12: "",
            sy7: "",
            sy9: "",
            sy6: "",
            sy5: "ܫܰܘܦܳܐ"
        },
        {
            dt1: "fürsorglich",
            lt11: "",
            sy8: "",
            dt3: "",
            sy6: "",
            sy9: "",
            dt2: "",
            sy5: "ܝܰܨܺܝܦܳܐܺܝܬ݂",
            lt12: "",
            lt10: "yaŝifoith",
            id: "1432",
            sy4: "ܝܨܝܦܐܝܬ",
            sy7: ""
        },
        {
            lt12: "shrolo",
            sy8: "ܫܪܠܐ",
            sy9: "ܫܪܳܠܳܐ",
            sy4: "ܩܘܫܬܐ",
            lt11: "fono",
            sy7: "ܦܳܢܳܐ",
            id: "5129",
            dt1: "Wirklichkeit",
            dt2: "Echtheit",
            sy6: "ܦܢܐ",
            sy5: "ܩܽܘܫܬܳܐ",
            lt10: "qushto",
            dt3: ""
        },
        {
            lt12: "",
            sy7: "",
            dt3: "",
            sy8: "",
            dt1: "Abstammungslehre",
            lt10: "mmalluth dore",
            sy4: "ܡܡܠܠܘܬ ܕܪܐ",
            dt2: "",
            lt11: "",
            sy5: "ܡܡܰܠܠܽܘܬ݂ ܕܳܪܶܐ",
            sy9: "",
            id: "5661",
            sy6: ""
        },
        {
            sy8: "",
            lt10: "taĥfitho",
            dt2: "",
            sy4: "ܬܚܦܝܬܐ",
            dt1: "Überzug",
            lt11: "",
            sy7: "",
            sy5: "ܬܰܚܦܺܝܬ݂ܳܐ",
            sy9: "",
            lt12: "",
            dt3: "",
            sy6: "",
            id: "4622"
        },
        {
            sy9: "",
            sy5: "ܠܶܘܳܝܳܐ",
            lt11: "",
            dt3: "",
            sy6: "",
            sy4: "ܠܘܝܐ",
            dt1: "zu Grabe tragen",
            sy8: "",
            lt12: "",
            dt2: "",
            sy7: "",
            lt10: "lewoyo",
            id: "5353"
        },
        {
            lt12: "",
            id: "3819",
            lt11: "maluĥto",
            lt10: "maloĥto",
            sy5: "ܡܰܠܳܚܬܳܐ",
            sy7: "ܡܰܠܽܘܚܬܳܐ",
            sy4: "ܡܠܚܬܐ",
            sy8: "",
            dt3: "",
            dt1: "Salzstreuer",
            sy9: "",
            dt2: "",
            sy6: "ܡܠܘܚܬܐ"
        },
        {
            sy7: "",
            lt11: "",
            dt3: "",
            dt1: "gerade Zahl",
            dt2: "",
            sy9: "",
            lt10: "menyono zaugoyo",
            sy8: "",
            id: "6116",
            lt12: "",
            sy5: "ܡܶܢܝܳܢܳܐ ܙܰܘܓܳܝܳܐ",
            sy4: "ܡܢܝܢܐ ܙܘܓܝܐ",
            sy6: ""
        },
        {
            sy4: "ܕܝܬܝܩܐ",
            sy9: "",
            dt2: "Vertrag",
            sy6: "",
            dt3: "Testament",
            id: "6437",
            sy8: "",
            sy5: "ܕܺܝܰܬܺܝܩܺܐ",
            lt12: "",
            lt11: "",
            dt1: "Bund",
            sy7: "",
            lt10: "diyatiqi"
        },
        {
            sy9: "",
            lt11: "",
            lt12: "",
            lt10: "fanțiqusți",
            dt3: "",
            id: "3454",
            sy5: "ܦܰܢܛܺܝܩܽܘܣܛܺܝ",
            dt1: "Pfingsten",
            sy4: "ܦܢܛܝܩܘܣܛܝ",
            sy6: "",
            sy8: "",
            dt2: "",
            sy7: ""
        },
        {
            lt12: "",
            lt10: "wilo",
            dt3: "",
            id: "1477",
            lt11: "",
            sy6: "",
            sy5: "ܘܺܐܠܳܐ",
            sy7: "",
            dt2: "Vorhang",
            sy9: "",
            sy8: "",
            sy4: "ܘܐܠܐ",
            dt1: "Gardine"
        },
        {
            sy9: "",
            sy7: "",
            dt3: "",
            lt11: "",
            sy4: "ܫܘܫܛ",
            id: "1118",
            lt10: "shausheț",
            dt2: "fortschreiten",
            sy8: "",
            sy5: "ܫܰܘܫܶܛ",
            lt12: "",
            sy6: "",
            dt1: "entwickeln"
        },
        {
            lt12: "",
            id: "200",
            sy6: "",
            sy8: "",
            lt10: "brishith ithauw wo meltho",
            sy5: "ܒܪܝܫܝܬ ܐܺܝܬ݂ܘܗܝ ܗܘܐ ܡܶܠܬ݂ܳܐ",
            sy7: "",
            lt11: "",
            sy9: "",
            dt2: "",
            dt3: "",
            dt1: "Am Anfang war das Wort",
            sy4: "ܒܪܝܫܝܬ ܐܝܬܘܗܝ ܗܘܐ ܡܠܬܐ"
        },
        {
            sy7: "",
            dt1: "Friseurladen",
            lt11: "",
            sy4: "ܒܝܬ ܓܪܥܐ",
            sy8: "",
            lt10: "beth groĉo",
            sy5: "ܒܶܝܬ݂ ܓܪܳܥܳܐ",
            id: "1401",
            sy9: "",
            lt12: "",
            sy6: "",
            dt3: "",
            dt2: ""
        },
        {
            dt2: "",
            sy5: "ܓܰܘܢܳܐ ܝܰܪܳܘܩܳܐ ܐܰܝܟ ܓܶܠܳܐ",
            lt12: "",
            dt3: "",
            lt10: "gauno yaroqo ayk gelo",
            sy9: "",
            sy8: "",
            dt1: "grüne Farbe wie der Rasen",
            sy6: "",
            sy4: "ܓܘܢܐ ܝܪܘܩܐ ܐܝܟ ܓܠܐ",
            lt11: "",
            sy7: "",
            id: "1753"
        },
        {
            dt2: "Augenwimpern",
            sy8: "",
            sy9: "",
            dt1: "Wimpern",
            sy4: "ܬܡܪܐ",
            lt10: "temre",
            sy7: "",
            lt11: "",
            id: "5115",
            sy5: "ܬܶܡ̈ܪܶܐ",
            lt12: "",
            sy6: "",
            dt3: ""
        },
        {
            lt10: "krihutho",
            sy7: "",
            dt1: "Krankheit",
            id: "2635",
            dt3: "",
            sy5: "ܟܪܺܝܗܽܘܬ݂ܳܐ",
            lt12: "",
            sy6: "",
            dt2: "Gebrechlichkeit",
            lt11: "",
            sy9: "",
            sy4: "ܟܪܝܗܘܬܐ",
            sy8: ""
        },
        {
            sy9: "ܥܶܕܠܳܝܳܐ",
            id: "2649",
            sy5: "ܪܶܫܝܳܢܳܐ",
            lt12: "ĉedloyo",
            sy8: "ܥܶܕܠܳܝܳܐ",
            sy4: "ܪܫܝܢܐ",
            sy6: "ܥܕܠܐ",
            dt3: "",
            dt2: "",
            sy7: "ܥܶܕܠܳܐ",
            lt10: "reshyono",
            lt11: "ĉedlo",
            dt1: "Kritik"
        },
        {
            sy5: "ܐܰܬ݂ܪܳܐ ܫܒܳܒܳܝܳܐ",
            dt3: "",
            lt11: "",
            sy7: "",
            dt2: "",
            sy4: "ܐܬܪܐ ܫܒܒܝܐ",
            sy6: "",
            lt10: "athro shboboyo",
            dt1: "Nachbarland",
            lt12: "",
            id: "3152",
            sy9: "",
            sy8: ""
        },
        {
            lt12: "",
            sy7: "ܓܳܡܽܘܕܳܐ",
            id: "4679",
            lt10: "gumdono",
            dt2: "Frecher",
            dt3: "Schamloser",
            sy5: "ܓܽܘܡܕܳܢܳܐ",
            lt11: "gomudo",
            sy8: "",
            dt1: "Unverschämter",
            sy6: "ܓܡܘܕܐ",
            sy9: "",
            sy4: "ܓܘܡܕܢܐ"
        },
        {
            sy8: "",
            dt1: "fotografieren",
            lt12: "",
            dt3: "",
            sy9: "",
            sy4: "ܨܪ",
            lt10: "ŝor",
            lt11: "",
            sy5: "ܨܳܪ",
            sy7: "",
            sy6: "",
            dt2: "",
            id: "1355"
        },
        {
            sy9: "",
            dt2: "",
            lt10: "shakar",
            sy8: "",
            id: "5356",
            sy7: "",
            dt1: "Zucker",
            sy5: "ܫܰܟܰܪ",
            lt11: "",
            sy4: "ܫܟܪ",
            dt3: "",
            lt12: "",
            sy6: ""
        },
        {
            id: "1516",
            sy5: "ܥܡܺܝܡܳܐ",
            lt10: "ĉmimo",
            sy7: "",
            sy9: "",
            sy6: "",
            sy8: "",
            lt12: "",
            sy4: "ܥܡܝܡܐ",
            dt2: "",
            dt3: "",
            dt1: "gefälscht",
            lt11: ""
        },
        {
            sy6: "",
            dt3: "",
            sy8: "",
            dt2: "Anfangsgehalt",
            sy7: "",
            sy4: "ܐܓܪܐ ܫܘܪܝܐ",
            id: "231",
            lt11: "",
            sy9: "",
            lt10: "ağro shuroyo",
            dt1: "Anfangslohn",
            lt12: "",
            sy5: "ܐܰܓ݂ܪܳܐ ܫܽܘܪܳܝܳܐ"
        },
        {
            sy6: "",
            sy8: "",
            sy7: "",
            dt1: "Mesopotamien",
            dt2: "Zweistromland",
            sy5: "ܒܶܝܬ݂ ܢܰܗܪܺܝܢ",
            lt11: "",
            lt10: "beth nahrin",
            dt3: "",
            sy9: "",
            lt12: "",
            id: "3003",
            sy4: "ܒܝܬ ܢܗܪܝܢ"
        },
        {
            lt12: "",
            sy6: "",
            dt1: "Zufriedenheit",
            dt3: "",
            sy5: "ܦܨܺܝܚܽܘܬ݂ܳܐ",
            lt10: "fŝiĥutho",
            sy4: "ܦܨܝܚܘܬܐ",
            sy9: "",
            sy8: "",
            sy7: "",
            dt2: "",
            lt11: "",
            id: "5361"
        },
        {
            lt10: "iŝef gantho",
            sy7: "",
            sy8: "",
            sy5: "ܝܺܨܶܦ ܓܰܢܬ݂ܳܐ",
            dt3: "",
            sy4: "ܝܨܦ ܓܢܬܐ",
            sy6: "",
            sy9: "",
            id: "1479",
            dt2: "",
            lt11: "",
            dt1: "Garten pflegen",
            lt12: ""
        },
        {
            dt1: "Jubiläum",
            sy6: "",
            sy8: "",
            sy4: "ܝܘܒܝܠܝܐ",
            id: "2344",
            sy7: "",
            lt12: "",
            dt3: "",
            sy5: "ܝܽܘܒܺܝܠܳܝܳܐ",
            lt10: "yubiloyo",
            lt11: "",
            sy9: "",
            dt2: ""
        },
        {
            sy5: "ܚܰܙܡܶܐ",
            sy4: "ܚܙܡܐ",
            lt11: "ܚܙܡܐ",
            dt2: "Hasme",
            lt10: "ܚܙܡܐ",
            dt1: "Hazme",
            lt12: "",
            sy7: "",
            id: "1912",
            sy6: "",
            dt3: "",
            sy9: "",
            sy8: ""
        },
        {
            sy9: "",
            lt11: "",
            dt1: "Amboss",
            lt10: "sadono",
            dt2: "Amboß",
            sy6: "",
            id: "5876",
            sy8: "",
            sy5: "ܣܰܕܳܢܳܐ",
            lt12: "",
            dt3: "",
            sy4: "ܣܕܢܐ",
            sy7: ""
        },
        {
            sy6: "",
            sy9: "",
            dt3: "",
            dt1: "Eljasaf",
            sy4: "ܐܠܝܣܦ",
            dt2: "",
            sy8: "",
            sy5: "ܐܶܠܝܰܣܰܦ",
            id: "1084",
            sy7: "",
            lt10: "ܐܠܝܣܦ",
            lt11: "ܐܠܝܣܦ",
            lt12: ""
        },
        {
            lt12: "",
            sy6: "ܢܘܩܙܬܢܝܐ",
            lt11: "nuqaztonoyo",
            dt3: "",
            sy8: "",
            sy4: "ܓܘܝܐ",
            sy5: "ܓܰܘܳܝܳܐ   ",
            dt1: "zentral",
            sy7: "ܢܰܘܩܰܙܬܳܢܳܝܳܐ",
            lt10: "gawoyo",
            sy9: "",
            id: "5303",
            dt2: ""
        },
        {
            sy8: "",
            dt2: "Gazelle",
            sy7: "",
            sy5: "ܐܰܝܠܳܐ",
            sy6: "",
            sy9: "",
            id: "3664",
            dt1: "Reh",
            lt11: "",
            dt3: "",
            lt10: "aylo",
            lt12: "",
            sy4: "ܐܝܠܐ"
        },
        {
            lt11: "",
            sy4: "ܠܐ ܡܬܩܒܠܢܐ",
            sy5: "ܠܳܐ ܡܶܬ݂ܩܰܒܠܳܢܳܐ",
            lt12: "",
            sy7: "",
            sy9: "",
            dt2: "",
            dt3: "",
            id: "2193",
            lt10: "lo methqablono",
            dt1: "inakzeptabel",
            sy6: "",
            sy8: ""
        },
        {
            sy7: "ܩܶܪܕܳܐ",
            sy9: "",
            dt1: "Affe",
            lt12: "",
            dt2: "",
            lt10: "qufo",
            id: "140",
            dt3: "",
            sy5: "ܩܽܘܦܳܐ",
            sy6: "ܩܪܕܐ",
            sy4: "ܩܘܦܐ",
            sy8: "",
            lt11: "qerdo"
        },
        {
            lt11: "",
            sy6: "",
            dt2: "",
            lt10: "shomroyo",
            sy8: "",
            lt12: "",
            sy9: "",
            id: "3821",
            dt1: "Samariter",
            sy5: "ܫܳܡܪܳܝܳܐ",
            sy4: "ܫܡܪܝܐ",
            dt3: "",
            sy7: ""
        },
        {
            sy5: "ܬܳܢܺܝ",
            lt12: "",
            sy6: "",
            dt3: "",
            id: "4522",
            lt10: "ܬܢܝ",
            lt11: "ܬܢܝ",
            sy9: "",
            sy7: "",
            sy8: "",
            dt1: "Toni",
            dt2: "Tony",
            sy4: "ܬܢܝ"
        },
        {
            dt1: "Elijo",
            sy5: "ܐܶܠܺܝܳܐ",
            sy8: "",
            sy7: "",
            sy4: "ܐܠܝܐ",
            lt12: "",
            lt10: "ܐܠܝܐ",
            lt11: "ܐܠܝܐ",
            sy9: "",
            dt3: "Elija",
            dt2: "Elias",
            sy6: "",
            id: "1076"
        },
        {
            sy6: "",
            lt11: "",
            dt2: "",
            sy9: "",
            dt3: "",
            sy8: "",
            lt12: "",
            sy4: "ܨܒܥܐ ܕܪܓܠܐ",
            dt1: "Zehe",
            lt10: "ŝebĉe d`reğlo",
            sy5: "ܨܶܒܥ̈ܶܐ ܕܪܶܓ݂ܠܳܐ",
            id: "5273",
            sy7: ""
        },
        {
            sy7: "ܫܰܥܒܶܕ",
            sy9: "ܟܕܰܢ",
            lt11: "shaĉbeđ",
            sy6: "ܫܥܒܕ",
            dt3: "",
            id: "6422",
            sy4: "ܫܥܡܪ",
            dt2: "",
            sy8: "ܟܕܢ",
            sy5: "ܫܰܥܡܰܪ",
            lt12: "kđan",
            lt10: "shaĉmar",
            dt1: "versklaven"
        },
        {
            dt3: "",
            lt10: "bqeshto",
            sy6: "",
            sy4: "ܒܩܶܫܬܳܐ",
            sy9: "",
            lt12: "",
            sy5: "ܒܩܶܫܬܳܐ",
            dt1: "In Klammern",
            dt2: "",
            lt11: "",
            id: "5480",
            sy8: "",
            sy7: ""
        },
        {
            sy7: "",
            dt2: "",
            dt3: "",
            lt11: "",
            sy5: "ܓܺܝܫܪܳܐ ܕܐܽܘܪܚ ܩܛܳܪܳܐ",
            sy4: "ܓܝܫܪܐ ܕܐܘܪܚ ܩܛܪܐ",
            sy9: "",
            sy6: "",
            lt10: "gishro d`urĥ qțoro",
            dt1: "Eisenbahnbrücke",
            lt12: "",
            sy8: "",
            id: "6400"
        },
        {
            dt2: "zielmäßig",
            dt1: "zielgetreu",
            id: "6288",
            lt12: "",
            sy5: "ܢܺܝܫܳܢܳܝܳܐ",
            sy8: "",
            lt11: "",
            sy7: "",
            sy4: "ܢܝܫܢܝܐ",
            sy9: "",
            sy6: "",
            dt3: "zielgerichtet",
            lt10: "nishonoyo"
        },
        {
            lt11: "",
            sy5: "ܒܳܥܽܘܬ݂ܳܐ",
            sy4: "ܒܥܘܬܐ",
            lt10: "boĉutho",
            sy8: "",
            dt2: "",
            sy9: "",
            id: "5175",
            lt12: "",
            dt1: "Wunsch",
            sy6: "",
            sy7: "",
            dt3: ""
        },
        {
            dt3: "",
            lt10: "ܒܪܝܟ݂ܬܐ",
            sy8: "",
            sy9: "",
            lt11: "ܒܪܝܟ݂ܬܐ",
            id: "710",
            dt2: "Brichto",
            sy4: "ܒܪܟ݂ܬܐ",
            sy5: "ܒܪܺܟ݂ܬܰܐ",
            dt1: "Brikhta",
            sy7: "",
            lt12: "",
            sy6: ""
        },
        {
            lt12: "",
            sy7: "ܒܥܶܠܕܒܳܒܳܐ",
            sy5: "ܒܥܶܠܕܒܳܒܽܘܬ݂ܳܐ",
            dt1: "Feindschaft",
            sy8: "",
            sy6: "ܒܥܠܕܒܒܐ",
            sy9: "",
            lt11: "bĉel dbobo",
            id: "1281",
            lt10: "bĉel dbobutho",
            dt3: "",
            dt2: "Feind",
            sy4: "ܒܥܠܕܒܒܘܬܐ"
        },
        {
            dt3: "",
            sy9: "",
            sy7: "",
            lt11: "",
            sy6: "",
            sy8: "",
            id: "3555",
            dt1: "Puzzle",
            lt10: "lgozo",
            dt2: "",
            sy5: "ܠܓܳܙܳܐ",
            sy4: "ܠܓܙܐ",
            lt12: ""
        },
        {
            dt2: "G",
            dt1: "g",
            lt12: "",
            sy7: "",
            lt11: "",
            sy6: "",
            sy5: "ܓ",
            lt10: "gomal",
            id: "1444",
            sy9: "",
            dt3: "",
            sy8: "",
            sy4: "ܓ"
        },
        {
            id: "1397",
            sy7: "",
            sy5: "ܒܶܝܬ݂ ܩܒܽܘܪ̈ܐ",
            lt11: "",
            sy6: "",
            dt3: "",
            sy9: "",
            lt10: "beth qbure",
            dt1: "Friedhof",
            sy4: "ܒܝܬ ܩܒܘܪܐ",
            lt12: "",
            dt2: "Grabstätte",
            sy8: ""
        },
        {
            sy5: "ܥܰܛܺܝܰܗ",
            sy6: "",
            sy9: "",
            lt10: "ܥܛܝܗ",
            dt1: "Atiyah",
            id: "331",
            lt12: "",
            sy7: "",
            lt11: "ܥܛܝܗ",
            dt2: "",
            dt3: "",
            sy8: "",
            sy4: "ܥܛܝܗ"
        },
        {
            sy9: "",
            id: "3036",
            lt11: "",
            sy4: "ܡܝܠܝܘܢܪ",
            sy8: "",
            dt3: "",
            sy5: "ܡܺܝܠܝܽܘܢܶܪ",
            dt1: "Millionär",
            sy7: "",
            dt2: "",
            lt12: "",
            sy6: "",
            lt10: "milyuner"
        },
        {
            lt12: "",
            sy5: "ܡܰܙܥܩܳܢܳܐ",
            dt2: "",
            dt3: "",
            dt1: "Anrufer",
            sy7: "",
            lt11: "",
            sy8: "",
            id: "5986",
            sy4: "ܡܙܥܩܢܐ",
            sy9: "",
            lt10: "mazĉqono",
            sy6: ""
        },
        {
            sy8: "",
            dt1: "Absatzgebiet",
            sy7: "",
            lt12: "",
            dt3: "",
            sy9: "",
            sy6: "",
            dt2: "",
            lt10: "tarĉuno",
            sy5: "ܬܰܪܥܽܘܢܳܐ",
            lt11: "",
            sy4: "ܬܪܥܘܢܐ",
            id: "5644"
        },
        {
            lt11: "",
            dt2: "",
            sy6: "",
            dt1: "Milchreis",
            sy5: "ܕܰܫܺܝܫܬܳܐ",
            sy7: "",
            sy4: "ܕܫܝܫܬܐ",
            lt10: "dashishto",
            sy9: "",
            sy8: "",
            dt3: "",
            lt12: "",
            id: "3026"
        },
        {
            lt12: "",
            sy7: "",
            sy4: "ܛܪܘܢܘܬܐ",
            lt10: "țrunutho",
            sy6: "",
            sy5: "ܛܪܽܘܢܽܘܬ݂ܳܐ",
            sy8: "",
            sy9: "",
            lt11: "",
            id: "893",
            dt3: "",
            dt2: "",
            dt1: "Diktatur"
        },
        {
            lt12: "",
            dt1: "Herr erbarme dich unser",
            sy6: "ܩܘܪܝܠܝܣܘܢ",
            lt11: "quryelayson",
            sy8: "",
            lt10: "moran ethraĥemeĉlayn",
            sy4: "ܡܪܢ ܐܬܪܚܡܥܠܝܢ",
            sy9: "",
            sy5: "ܡܳܪܰܢ ܐܶܬ݂ܪܰܚܰܡܶܥܠܰܝܢ",
            dt2: "Herr hab erbarmen mit uns",
            sy7: "ܩܽܘܪܝܶܠܰܝܣܽܘܢ",
            dt3: "",
            id: "1969"
        },
        {
            sy6: "ܦܘܠܘܣ ܓܪܒܝܝܐ",
            lt12: "",
            dt2: "",
            sy4: "ܩܘܛܒܐ ܓܪܒܝܝܐ",
            sy7: "ܦܳܘܠܳܘܣ ܓܰܪܒܝܳܝܳܐ",
            sy8: "",
            sy9: "",
            lt11: "folos garbyoyo",
            dt3: "",
            lt10: "quțbo garbyoyo",
            id: "3296",
            dt1: "Nordpol",
            sy5: "ܩܽܘܛܒܳܐ ܓܰܪܒܝܳܝܳܐ"
        },
        {
            dt2: "",
            "date": {
                "seconds": 1590848512,
                "nanoseconds": 871000000
            },
            sy9: "",
            dt1: "Wie geht's Dir?",
            sy4: "ܐܝܕܪܒܐ ܗܬ",
            dt3: "",
            lt11: "",
            lt10: "Aydarbo hat",
            lt12: "",
            sy5: "ܐܰܝܕܰܪܒܳܐ ܗܰܬ",
            sy7: "",
            sy8: "",
            sy6: "",
            id: "mqjRLdnVvz2X48Hpm9Gd"
        },
        {
            lt10: "ܦܪܝܘܬܐ",
            sy5: "ܦܰܪܝܽܘܬܰܐ",
            lt12: "",
            sy8: "",
            sy9: "",
            dt2: "",
            dt1: "Faryuta",
            sy4: "ܦܪܝܘܬܐ",
            id: "1260",
            sy7: "",
            dt3: "",
            sy6: "",
            lt11: "ܦܪܝܘܬܐ"
        },
        {
            sy7: "ܪܒܺܝܥܬܳܐ",
            sy6: "ܪܒܝܥܬܐ",
            sy5: "ܡܪܰܒܥܳܐ",
            lt12: "",
            dt3: "",
            id: "4836",
            sy8: "",
            sy4: "ܡܪܒܥܐ",
            sy9: "",
            dt2: "Quadrat",
            lt11: "rbiĉto",
            dt1: "Viereck",
            lt10: "mrabĉo"
        },
        {
            sy9: "",
            lt11: "",
            lt10: "țalyo shafiro",
            id: "4015",
            sy7: "",
            dt1: "schöner Junge",
            lt12: "",
            sy6: "",
            sy4: "ܛܠܝܐ ܫܦܝܪܐ",
            sy8: "",
            dt3: "",
            dt2: "",
            sy5: "ܛܰܠܝܳܐ ܫܰܦܺܝܪܳܐ"
        },
        {
            id: "5811",
            sy7: "ܩܽܘܒܳܠܳܐ",
            sy9: "",
            dt3: "",
            sy5: "ܢܳܣܽܘܒܽܘܬ݂ܳܐ",
            lt10: "nosubutho",
            sy4: "ܢܣܘܒܘܬܐ",
            dt1: "Akzeptanz",
            lt11: "qubolo",
            dt2: "",
            sy6: "ܩܘܒܠܐ",
            sy8: "",
            lt12: ""
        },
        {
            sy8: "",
            sy6: "ܣܐܩ",
            lt10: "suq",
            id: "3718",
            dt1: "riechen",
            sy4: "ܣܘܩ",
            sy5: "ܣܽܘܩ",
            dt2: "",
            lt11: "soeq",
            sy7: "ܣܳܐܶܩ",
            sy9: "",
            lt12: "",
            dt3: ""
        },
        {
            sy8: "",
            sy4: "ܡܪܩ ܫܢܐ",
            dt3: "",
            id: "5234",
            dt2: "",
            sy5: "ܡܳܪܩ ܫܶܢ̈ܶܐ",
            lt12: "",
            dt1: "Zähne putzen",
            sy6: "",
            lt10: "morq shene",
            sy7: "",
            sy9: "",
            lt11: ""
        },
        {
            lt12: "",
            sy5: "ܟܪܺܝܗܳܐ",
            sy8: "",
            sy7: "ܡܰܪܥܳܐ",
            dt1: "krank",
            dt2: "",
            lt10: "kriho",
            id: "2629",
            sy6: "ܡܪܥܐ",
            sy9: "",
            sy4: "ܟܪܝܗܐ",
            lt11: "marĉo",
            dt3: ""
        },
        {
            lt10: "molukho d`madatho",
            sy9: "",
            lt11: "",
            id: "4266",
            sy8: "",
            sy5: "ܡܳܠܽܘܟ݂ܳܐ ܕܡܰܕܰܐܬ݂ܳܐ",
            dt1: "Steuerberater",
            sy7: "",
            dt2: "",
            lt12: "",
            sy6: "",
            dt3: "",
            sy4: "ܡܠܘܟܐ ܕܡܕܐܬܐ"
        },
        {
            dt3: "",
            sy8: "",
            sy4: "ܩܠܝܡܐ",
            id: "3532",
            sy5: "ܩܠܺܝܡܳܐ",
            dt1: "Provinz",
            lt11: "",
            sy9: "",
            dt2: "Region",
            sy7: "",
            lt10: "qlimo",
            lt12: "",
            sy6: ""
        },
        {
            sy6: "ܬܫܬܥܢܝܐ ܒܪܝܐ",
            sy7: "ܬܶܫܬܥܶܢܝܳܐ ܒܰܪܳܝܳܐ",
            lt11: "teshtĉenyo baroyo",
            lt10: "sheĉyo baroyo",
            sy9: "",
            sy5: "ܫܶܥܝܳܐ ܒܰܪܳܝܳܐ",
            dt3: "",
            sy8: "",
            dt1: "Auswärtsspiel",
            lt12: "",
            id: "396",
            sy4: "ܫܥܝܐ ܒܪܝܐ",
            dt2: ""
        },
        {
            sy9: "",
            sy4: "ܛܘܝܒܐ",
            sy7: "ܡܽܘܕܳܟ݂ܳܐ",
            lt11: "mudokho",
            lt12: "",
            id: "6304",
            dt2: "",
            sy6: "ܡܘܕܟܐ",
            sy8: "",
            dt1: "Zubereitung",
            dt3: "",
            lt10: "țuyobo",
            sy5: "ܛܽܘܝܳܒܳܐ"
        },
        {
            sy6: "",
            lt12: "",
            id: "413",
            sy4: "ܬܦܐ",
            dt3: "",
            sy8: "",
            sy5: "ܬܰܦܳܐ",
            dt2: "",
            sy7: "",
            lt10: "tafo",
            dt1: "Bach",
            sy9: "",
            lt11: ""
        },
        {
            dt2: "erfreuen",
            sy9: "",
            lt10: "basem",
            sy6: "ܐܦܨܚ",
            lt11: "afŝaĥ",
            sy4: "ܒܣܡ",
            dt1: "amüsieren",
            sy8: "",
            id: "5893",
            dt3: "vergnügen",
            lt12: "",
            sy5: "ܒܰܣܶܡ",
            sy7: "ܐܰܦܨܰܚ"
        },
        {
            sy7: "",
            sy8: "",
            sy4: "ܟܠܙܘܙܐ",
            lt12: "",
            sy9: "",
            id: "2482",
            sy5: "ܟܰܠܙܽܘܙܳܐ",
            dt2: "",
            lt11: "",
            dt3: "",
            sy6: "",
            lt10: "kalsuso",
            dt1: "Kinn"
        },
        {
            sy9: "",
            sy7: "",
            sy6: "",
            lt12: "",
            dt2: "",
            sy8: "",
            lt10: "shkiĥ bkulduk",
            id: "5842",
            lt11: "",
            sy4: "ܫܟܝܚ ܒܟܘܠܕܘܟ",
            dt3: "",
            dt1: "allgegenwärtig",
            sy5: "ܫܟܺܝܚ ܒܟܽܘܠܕܽܘܟ"
        },
        {
            id: "2343",
            sy8: "",
            sy7: "ܝܽܘܙܳܕܳܩ",
            sy6: "ܝܘܙܕܩ",
            sy4: "ܝܘܙܕܩ",
            lt11: "ܝܘܙܕܩ",
            lt10: "ܝܘܙܕܩ",
            dt1: "Jozadak",
            lt12: "ܝܘܙܕܩ",
            sy5: "ܝܺܘܙܰܕܰܩ",
            dt3: "",
            dt2: "",
            sy9: ""
        },
        {
            sy4: "ܡܥܒܪܬܐ",
            sy8: "",
            lt12: "",
            lt11: "",
            dt2: "Passage",
            id: "980",
            sy9: "",
            sy5: "ܡܰܥܒܰܪܬܳܐ",
            sy7: "",
            sy6: "",
            lt10: "maĉbarto",
            dt3: "",
            dt1: "Durchgang"
        },
        {
            sy9: "ܚܰܫܳܐܝܺܬ݂",
            lt10: "ĥashishoith",
            lt11: "kmiro",
            sy4: "ܚܫܝܫܐܝܬ",
            sy6: "ܟܡܝܪܐ",
            sy5: "ܚܰܫܺܝܫܳܐܝܺܬ݂",
            lt12: "ĥashoith",
            sy8: "ܚܫܐܝܬ",
            id: "4550",
            dt1: "traurig",
            dt2: "",
            sy7: "ܟܡܺܝܪܳܐ",
            dt3: ""
        },
        {
            lt12: "",
            sy7: "",
            sy5: "ܩܰܨܳܒܳܐ",
            id: "1329",
            sy9: "",
            dt3: "",
            sy6: "",
            dt1: "Fleischer",
            lt10: "qaŝobo",
            sy4: "ܩܨܒܐ",
            lt11: "",
            dt2: "Metzger",
            sy8: ""
        },
        {
            sy9: "",
            lt10: "muĥo",
            sy5: "ܡܽܘܚܳܐ",
            sy4: "ܡܘܚܐ",
            lt12: "",
            dt3: "",
            id: "1536",
            sy6: "ܪܝܫܐ ܥܠܝܐ",
            sy7: "ܪܺܝܫܳܐ ܥܶܠܳܝܳܐ",
            dt1: "Gehirn",
            lt11: "risho ĉeloyo",
            sy8: "",
            dt2: "Hirn"
        },
        {
            sy8: "",
            lt11: "",
            dt3: "",
            sy9: "",
            lt12: "",
            sy5: "ܡܫܰܕܪܳܢܽܘܬ݂ܳܐ",
            lt10: "mshadronutho",
            dt1: "Versand",
            id: "4800",
            dt2: "",
            sy6: "",
            sy4: "ܡܫܕܪܢܘܬܐ",
            sy7: ""
        },
        {
            id: "3662",
            lt11: "",
            sy5: "ܩܠܺܝܡܳܝܳܐ",
            sy7: "",
            sy6: "",
            dt2: "",
            dt1: "regional",
            lt12: "",
            sy8: "",
            dt3: "",
            sy4: "ܩܠܝܡܝܐ",
            lt10: "qlimoyo",
            sy9: ""
        },
        {
            sy5: "ܠܳܐ",
            sy9: "",
            sy8: "",
            sy6: "",
            dt3: "",
            dt2: "",
            sy7: "",
            lt10: "lo",
            sy4: "ܠܐ",
            lt12: "",
            id: "3240",
            dt1: "nein",
            lt11: ""
        },
        {
            lt11: "d`lo tirtho",
            sy4: "ܫܪܘܥܐ",
            lt10: "shoruĉo ",
            dt1: "gewissenlos",
            dt2: "",
            sy9: "",
            dt3: "",
            id: "1631",
            sy8: "",
            sy5: "ܫܳܪܽܘܥܳܐ",
            sy6: "ܕܠܐ ܬܐܪܬܐ",
            lt12: "",
            sy7: "ܕܠܳܐ ܬܺܐܪܬ݂ܳܐ"
        },
        {
            sy8: "",
            lt12: "",
            dt3: "",
            dt2: "",
            sy5: "ܬܽܘܟ݂ܠܳܢ",
            sy4: "ܬܘܟ݂ܠܢ",
            lt10: "ܬܘܟ݂ܠܢ",
            dt1: "Tuchlon",
            id: "4590",
            sy6: "",
            sy7: "",
            lt11: "ܬܘܟ݂ܠܢ",
            sy9: ""
        },
        {
            sy6: "",
            dt1: "Zeuge",
            dt3: "",
            id: "5312",
            lt10: "sohdo",
            sy9: "",
            sy4: "ܣܗܕܐ",
            lt12: "",
            sy5: "ܣܳܗܕܳܐ",
            sy7: "",
            sy8: "",
            dt2: "Märtyrer",
            lt11: ""
        },
        {
            lt11: "",
            dt2: "Nikolaus",
            lt10: "mor zoğe",
            sy7: "",
            sy8: "",
            sy4: "ܡܪ ܙܓܐ",
            sy6: "",
            dt3: "",
            dt1: "Weihnachtsmann",
            sy5: "ܡܺܪ ܙܳܓ݂ܶܐ",
            id: "5022",
            sy9: "",
            lt12: ""
        },
        {
            sy5: "ܐܶܣܛܘܳܐ ܕܡܶܫܬܽܘܬ݂ܳܐ",
            sy4: "ܐܣܛܘܐ ܕܡܫܬܘܬܐ",
            lt12: "",
            id: "2037",
            sy8: "",
            lt11: "",
            sy6: "",
            dt2: "",
            sy7: "",
            lt10: "estwo d`meshtutho",
            dt1: "Hochzeitssaal",
            sy9: "",
            dt3: ""
        },
        {
            sy6: "ܓܣܐ",
            sy9: "",
            dt3: "sich übergeben müssen",
            sy8: "",
            sy4: "ܚܦܟ",
            lt12: "",
            lt11: "gso",
            id: "1132",
            sy7: "ܓܣܳܐ",
            dt1: "erbrechen",
            lt10: "ĥfakh",
            dt2: "kotzen",
            sy5: "ܚܦܰܟ"
        },
        {
            lt10: "tshaĉsar",
            sy7: "ܬܫܰܥܶܣ̈ܪܶܐ",
            dt3: "",
            dt2: "Neunzehn",
            sy8: "ܝܛ",
            lt12: "juth-țeth",
            sy5: "ܬܫܰܥܣܰܪ",
            dt1: "19",
            sy6: "ܬܫܥܣܪܐ",
            sy4: "ܬܫܥܣܪ",
            id: "20",
            lt11: "tshaĉesre",
            sy9: "ܝܛ"
        },
        {
            lt12: "",
            sy7: "",
            sy4: "ܥܡܝܕܐ",
            lt11: "",
            dt2: "Getaufter",
            dt3: "",
            id: "4404",
            sy8: "",
            sy6: "",
            dt1: "Täufling",
            sy9: "",
            lt10: "ĉmiđo",
            sy5: "ܥܡܺܝܕܳܐ"
        },
        {
            lt10: "bğao",
            id: "2205",
            sy4: "ܒܓܘ",
            sy5: "ܒܓ݂ܰܘ",
            lt12: "",
            dt2: "drin",
            sy9: "",
            lt11: "",
            sy6: "",
            sy8: "",
            dt3: "",
            sy7: "",
            dt1: "innen"
        },
        {
            sy9: "",
            sy5: "ܐܽܘܪܚܳܐ ܕܚܰܝ̈ܶܐ",
            sy8: "",
            sy4: "ܐܘܪܚܐ ܕܚܝܐ",
            dt3: "",
            lt11: "",
            dt1: "Lebensweg",
            lt12: "",
            lt10: "urĥo d`ĥaye",
            sy7: "",
            id: "2733",
            sy6: "",
            dt2: ""
        },
        {
            id: "4223",
            sy7: "",
            sy8: "",
            lt11: "",
            sy6: "",
            lt10: "qonuno d`leshono",
            lt12: "",
            dt3: "",
            dt2: "",
            sy4: "ܩܢܘܢܐ ܕܠܫܢܐ",
            sy5: "ܩܳܢܽܘܢܳܐ ܕܠܶܫܳܢܳܐ",
            dt1: "Sprachregeln",
            sy9: ""
        },
        {
            id: "1164",
            lt12: "",
            dt3: "",
            dt2: "",
            sy8: "",
            sy6: "",
            sy4: "ܚܨܕܐ",
            lt11: "",
            sy7: "",
            dt1: "Ernte",
            lt10: "ĥŝodo",
            sy5: "ܚܨܳܕܳܐ",
            sy9: ""
        },
        {
            sy9: "ܡܶܬܬܰܠܡܕܳܢܳܐ",
            dt2: "Beginner",
            id: "5944",
            sy5: "ܡܫܰܪܝܳܢܳܐ",
            lt12: "mettalmđono",
            dt1: "Anfänger",
            lt11: "sharwoyo",
            lt10: "msharyono",
            sy8: "ܡܬܬܠܡܕܢܐ",
            sy4: "ܡܫܪܝܢܐ",
            dt3: "Amateur",
            sy6: "ܫܪܘܝܐ",
            sy7: "ܫܰܪܘܳܝܳܐ"
        },
        {
            sy4: "ܣܒ ܠܩܢܝܐ ܡܢ ܡܠܦܢܐ",
            dt1: "Nehme ein Stift vom Lehrer",
            lt10: "sab l`qanyo men malfono",
            dt2: "",
            lt11: "",
            sy9: "",
            dt3: "",
            lt12: "",
            sy7: "",
            id: "3235",
            sy6: "",
            sy5: "ܣܰܒ ܠܩܰܢܝܳܐ ܡܶܢ ܡܰܠܦܳܢܳܐ",
            sy8: ""
        },
        {
            lt11: "bĉilo",
            sy9: "",
            sy8: "",
            lt12: "",
            dt1: "verheiratet",
            dt3: "",
            sy5: "ܡܙܰܘܓ݂ܳܐ",
            lt10: "mzauğo",
            sy6: "ܒܥܝܠܐ",
            sy4: "ܡܙܘܓܐ",
            sy7: "ܒܥܺܝܠܳܐ",
            id: "4745",
            dt2: ""
        },
        {
            sy5: "ܐܰܪܬܠܳܝܳܐ",
            dt1: "Küchenlöffel",
            lt11: "",
            sy9: "",
            id: "2655",
            lt12: "",
            sy8: "",
            lt10: "artloyo",
            dt3: "",
            dt2: "Kochlöffel",
            sy7: "",
            sy4: "ܐܪܬܠܝܐ",
            sy6: ""
        },
        {
            lt10: "ܓܐܢ",
            id: "757",
            sy6: "",
            sy5: "ܓܰܐܢ",
            dt3: "",
            lt12: "",
            sy7: "",
            sy8: "",
            dt2: "",
            sy4: "ܓܐܢ",
            lt11: "ܓܐܢ",
            dt1: "Can",
            sy9: ""
        },
        {
            lt10: "suryoyo hat",
            sy4: "ܣܘܪܝܝܐ ܗܐܢܬ",
            lt11: "",
            lt12: "",
            dt2: "Bist Du ein Suryoyo",
            sy6: "",
            dt1: "Bist Du ein Aramäer",
            id: "633",
            sy9: "",
            sy5: "ܣܽܘܪܝܳܝܳܐ ܗܐܰܢ̱ܬ",
            sy7: "",
            sy8: "",
            dt3: ""
        },
        {
            sy5: "ܓܳܬܳܪ",
            id: "1685",
            sy4: "ܓܬܪ",
            lt11: "ܓܬܪ",
            sy8: "",
            lt10: "ܓܬܪ",
            dt1: "Gotor",
            sy7: "",
            sy6: "",
            sy9: "",
            dt3: "",
            dt2: "",
            lt12: ""
        },
        {
            sy9: "",
            sy7: "ܪܶܓ݂ܫܳܢܳܐܝܺܬ݂",
            lt10: "reğshonoyo",
            sy6: "ܪܓܫܢܐܝܬ",
            lt11: "reğshonoith",
            dt1: "empfindlich",
            dt2: "sensibel",
            id: "1099",
            dt3: "",
            sy4: "ܪܓܫܢܝܐ",
            sy5: "ܪܶܓ݂ܫܳܢܳܝܳܐ",
            lt12: "",
            sy8: ""
        },
        {
            dt3: "",
            sy5: "ܡܬܛܒܥܢܘܬܳܐ",
            sy9: "",
            lt12: "",
            dt1: "Abdruck",
            lt10: "methțabĉonutho",
            dt2: "",
            sy4: "ܡܬܛܒܥܢܘܬܐ",
            sy8: "",
            id: "69",
            lt11: "",
            sy7: "",
            sy6: ""
        },
        {
            sy9: "",
            dt2: "",
            sy6: "",
            sy4: "ܐܫܬܘܬܦ",
            lt12: "",
            dt3: "",
            lt10: "eshtautaf",
            sy8: "",
            sy7: "",
            id: "5589",
            dt1: "abonnieren",
            lt11: "",
            sy5: "ܐܶܫܬܰܘܬܰܦ"
        },
        {
            sy4: "ܢܘܪܫܝܢܐ",
            dt3: "",
            sy8: "",
            sy7: "",
            dt2: "",
            sy9: "",
            sy6: "",
            dt1: "Nurschayno",
            id: "3323",
            lt10: "ܢܘܪܫܝܢܐ",
            sy5: "ܢܽܘܪܫܰܝܢܳܐ",
            lt11: "ܢܘܪܫܝܢܐ",
            lt12: ""
        },
        {
            sy9: "",
            id: "208",
            lt10: "shushmono",
            dt3: "",
            lt11: "",
            lt12: "",
            dt2: "",
            sy7: "",
            sy4: "ܫܘܫܡܢܐ",
            sy8: "",
            dt1: "Ameise",
            sy6: "",
            sy5: "ܫܽܘܫܡܳܢܳܐ"
        },
        {
            lt11: "",
            lt12: "",
            lt10: "shruro",
            dt3: "Sprößling",
            dt2: "Pflanzling",
            sy4: "ܫܪܘܪܐ",
            sy6: "",
            sy5: "ܫܪܽܘܪܳܐ",
            id: "4226",
            dt1: "Spross",
            sy9: "",
            sy7: "",
            sy8: ""
        },
        {
            dt1: "Amel",
            sy5: "ܐܰܡܶܠ",
            lt10: "ܐܡܠ",
            sy9: "",
            sy7: "",
            dt2: "Amal ",
            id: "209",
            sy6: "",
            sy8: "",
            lt11: "ܐܡܠ",
            dt3: "",
            lt12: "",
            sy4: "ܐܡܠ"
        },
        {
            sy7: "",
            lt11: "",
            dt2: "",
            dt3: "",
            lt10: "bobo",
            dt1: "Baby",
            sy6: "",
            sy9: "",
            id: "412",
            sy8: "",
            sy4: "ܒܒܐ",
            lt12: "",
            sy5: "ܒܳܒܳܐ"
        },
        {
            sy4: "ܡܢ ܒܬܪ",
            sy5: "ܡܶܢ ܒܳܬ݂ܰܪ",
            id: "3154",
            dt3: "",
            lt10: "men bothar",
            sy9: "",
            sy8: "",
            lt12: "",
            sy7: "",
            sy6: "",
            dt2: "",
            lt11: "",
            dt1: "nachdem"
        },
        {
            dt3: "",
            sy9: "",
            sy6: "",
            dt1: "Suroyo",
            sy5: "ܣܽܘܪܳܝܳܐ",
            lt11: "ܣܘܪܝܐ",
            sy7: "",
            lt10: "ܣܘܪܝܐ",
            sy8: "",
            id: "4322",
            sy4: "ܣܘܪܝܐ",
            dt2: "",
            lt12: ""
        },
        {
            sy9: "",
            sy7: "ܦܳܪܰܥ",
            sy6: "ܦܪܥ",
            dt2: "",
            lt11: "foraĉ",
            lt10: "fraĉ",
            sy8: "",
            lt12: "",
            dt1: "bezahlen",
            id: "606",
            sy5: "ܦܪܰܥ",
            dt3: "",
            sy4: "ܦܪܥ"
        },
        {
            dt2: "",
            sy6: "ܐܪܥܘܬܐ",
            lt12: "",
            sy9: "",
            dt1: "Interview",
            sy5: "ܡܩܰܒܠܳܢܽܘܬ݂ܳܐ",
            lt11: "orĉutho",
            dt3: "",
            sy7: "ܐܳܪܥܽܘܬ݂ܳܐ",
            sy4: "ܡܩܒܠܢܘܬܐ",
            id: "2232",
            sy8: "",
            lt10: "mqablonutho"
        },
        {
            id: "1657",
            lt12: "taqnutho",
            sy6: "ܡܬܩܠܘܬܐ",
            sy5: "ܬܳܩܠܽܘܬ݂ܳܐ",
            dt3: "",
            sy8: "ܬܩܢܘܬܐ",
            sy7: "ܡܰܬܩܳܠܽܘܬ݂ܳܐ",
            sy4: "ܬܩܠܘܬܐ",
            dt1: "Gleichgewicht",
            sy9: "ܬܰܩܢܽܘܬ݂ܳܐ",
            lt10: "toqlutho",
            lt11: "matqolutho",
            dt2: "Balance"
        },
        {
            sy7: "",
            lt11: "ܘܐܠ",
            sy8: "",
            dt2: "",
            lt12: "",
            lt10: "ܘܐܠ",
            sy4: "ܘܐܠ",
            id: "4924",
            sy9: "",
            dt3: "",
            sy6: "",
            dt1: "Wael",
            sy5: "ܘܰܐܶܠ"
        },
        {
            dt2: "",
            lt10: "ܒܪܕܝܢܐ",
            sy4: "ܒܪܕܝܢܐ",
            sy7: "",
            sy6: "",
            lt12: "",
            sy8: "",
            lt11: "ܒܪܕܝܢܐ",
            sy9: "",
            dt1: "Bardino",
            sy5: "ܒܰܪܕܝܺܢܳܐ",
            id: "446",
            dt3: ""
        },
        {
            sy8: "",
            sy9: "",
            id: "938",
            dt1: "Dorf Keferze",
            sy6: "ܟܦܪܙܐ",
            lt10: "qritho d`kferze",
            dt2: "Keferze",
            lt12: "",
            sy7: "ܟܦܶܪܙܶܐ",
            sy4: "ܩܪܝܬܐ ܕܟܦܪܙܐ",
            sy5: "ܩܪܺܝܬ݂ܳܐ ܕܟܦܶܪܙܶܐ",
            lt11: "kferze",
            dt3: "Kferze"
        },
        {
            dt2: "",
            id: "2822",
            sy6: "",
            sy4: "ܕܠܐ ܚܘܒܐ",
            lt11: "",
            sy9: "",
            lt12: "",
            sy5: "ܕܠܳܐ ܚܽܘܒܳܐ",
            dt3: "",
            sy8: "",
            dt1: "lieblos",
            sy7: "",
            lt10: "d`lo ĥubo"
        },
        {
            sy9: "",
            sy6: "",
            sy5: "ܝܺܩܰܪ",
            sy8: "",
            id: "1511",
            dt3: "",
            dt1: "geehrt",
            lt11: "",
            lt12: "",
            sy7: "",
            sy4: "ܝܩܪ",
            lt10: "iqar",
            dt2: ""
        },
        {
            sy5: "ܥܺܐܕܳܐ ܒܪܺܝܟ݂ܳܐ",
            lt10: "ĉiđo brikho",
            id: "1403",
            dt1: "Frohe Ostern",
            sy6: "ܒܪܝܟ݂ ܡܘܠܕܐ ܕܡܪܢ",
            lt12: "",
            sy8: "",
            sy7: "ܒܪܺܝܟ݂ ܡܰܘܠܳܕܳܐ ܕܡܳܪܰܢ",
            sy4: "ܥܐܕܐ ܒܪܝܟܐ",
            sy9: "",
            dt2: "",
            lt11: "brikh maulodo d`moran",
            dt3: ""
        },
        {
            dt3: "",
            lt11: "siqum mashlmonutho",
            lt12: "",
            sy4: "ܙܒܢܐ ܡܫܠܡܢܘܬܐ",
            id: "2829",
            dt1: "Lieferzeit",
            sy6: "ܣܝܩܘܡ ܡܫܠܡܢܘܬܐ",
            sy5: "ܙܰܒܢܳܐ ܡܫܰܠܡܳܢܽܘܬ݂ܳܐ",
            sy8: "",
            dt2: "",
            lt10: "zabno mashlmonutho",
            sy9: "",
            sy7: "ܣܺܝܩܽܘܡ ܡܫܰܠܡܳܢܽܘܬ݂ܳܐ"
        },
        {
            sy4: "ܥܣܪܝܢ ܘܬܪܝܢ",
            dt3: "",
            sy5: "ܥܶܣܪܺܝܢ ܘܬܪܶܝܢ",
            lt11: "ĉesrin w`tarteyn",
            id: "23",
            dt1: "22",
            sy8: "ܟܒ",
            dt2: "Zweiundzwanzig",
            sy6: "ܥܣܪܝܢ ܘܬܪܬܝܢ",
            sy9: "ܟܒ",
            lt10: "ĉesrin wa`treyn",
            sy7: "ܥܶܣܪܺܝܢ ܘܬܰܪܬܶܝܢ",
            lt12: "kof-beth"
        },
        {
            dt2: "",
            sy9: "",
            sy8: "",
            lt12: "",
            dt1: "anpflanzen",
            lt10: "nŝab",
            lt11: "",
            sy7: "",
            sy6: "",
            sy5: "ܢܨܰܒ",
            dt3: "",
            id: "5854",
            sy4: "ܢܨܒ"
        },
        {
            lt11: "",
            sy8: "",
            lt10: "ĥashe bishe",
            sy7: "",
            sy5: "ܚܰܫ̈ܶܐ ܒܺܝܫ̈ܶܐ",
            dt1: "böse Begierden",
            sy4: "ܚܫܐ ܒܝܫܐ",
            id: "682",
            dt2: "schlechte Begierden",
            dt3: "",
            sy9: "",
            lt12: "",
            sy6: ""
        },
        {
            dt1: "Blut spucken",
            sy6: "",
            sy7: "",
            sy9: "",
            dt2: "",
            id: "659",
            sy5: "ܪܩܳܩ ܕܡܳܐ",
            lt10: "rqoq dmo",
            lt12: "",
            sy4: "ܪܩܩ ܕܡܐ",
            lt11: "",
            sy8: "",
            dt3: ""
        },
        {
            dt1: "aufwachen",
            dt3: "",
            sy7: "",
            id: "363",
            lt10: "ettĉir",
            lt11: "",
            sy8: "",
            sy6: "",
            sy9: "",
            lt12: "",
            sy5: "ܐܶܬܬܥܺܝܪ",
            dt2: "aufwecken",
            sy4: "ܐܬܬܥܝܪ"
        },
        {
            dt3: "",
            id: "523",
            dt1: "Beere",
            sy6: "",
            lt11: "",
            sy7: "",
            lt10: "tutho",
            sy5: "ܬܽܘܬ݂ܳܐ",
            sy4: "ܬܘܬܐ",
            sy8: "",
            sy9: "",
            dt2: "Beerenbaum",
            lt12: ""
        },
        {
            sy7: "",
            sy4: "ܫܬܝܐ",
            sy5: "ܫܰܬܳܝܳܐ",
            lt10: "shatoyo",
            lt12: "",
            lt11: "",
            sy8: "",
            sy9: "",
            id: "590",
            dt3: "",
            dt1: "Betrunkener",
            dt2: "Besoffener",
            sy6: ""
        },
        {
            lt11: "",
            dt1: "Nicken",
            sy8: "",
            dt2: "",
            dt3: "",
            sy5: "ܟܦܳܦ ܪܺܝܫܳܐ",
            sy6: "",
            lt10: "kfof risho",
            sy7: "",
            lt12: "",
            id: "3268",
            sy9: "",
            sy4: "ܟܦܦ ܪܝܫܐ"
        },
        {
            sy9: "",
            sy4: "ܦܐܝܘܬܐ",
            sy5: "ܦܰܐܝܽܘܬܰܐ",
            dt2: "",
            dt1: "Fayuta",
            sy8: "",
            sy6: "",
            lt12: "",
            dt3: "",
            id: "1270",
            lt10: "ܦܐܝܘܬܐ",
            sy7: "",
            lt11: "ܦܐܝܘܬܐ"
        },
        {
            lt12: "",
            lt10: "nufus d`athro",
            sy5: "ܢܽܘܦܽܘܣ ܕܰܐܬ݂ܪܳܐ",
            sy6: "",
            sy8: "",
            sy4: "ܢܘܦܘܣ ܕܐܬܪܐ",
            sy7: "",
            lt11: "",
            dt1: "Bevölkerungszahl",
            id: "599",
            sy9: "",
            dt3: "",
            dt2: ""
        },
        {
            lt12: "",
            lt10: "fuloĥo shautofoyo",
            dt3: "",
            dt2: "",
            sy9: "",
            sy4: "ܦܘܠܚܢܐ ܫܘܬܦܝܐ",
            dt1: "Zusammenarbeit",
            sy6: "",
            sy5: "ܦܽܘܠܚܳܢܳܐ ܫܰܘܬܳܦܳܝܳܐ",
            lt11: "",
            sy8: "",
            sy7: "",
            id: "5377"
        },
        {
            sy6: "",
            id: "1121",
            sy8: "",
            sy7: "",
            sy5: "ܡܰܘܕܰܥܠܶܗ",
            dt1: "er hat informiert",
            sy4: "ܡܘܕܥܠܗ",
            dt3: "",
            lt10: "mauđaĉle",
            sy9: "",
            dt2: "",
            lt12: "",
            lt11: ""
        },
        {
            sy5: "ܢܰܒܺܝ",
            dt3: "",
            sy4: "ܢܒܝ",
            lt10: "nabi",
            lt12: "",
            dt1: "prophezeien",
            id: "3525",
            lt11: "",
            sy7: "",
            dt2: "",
            sy9: "",
            sy8: "",
            sy6: ""
        },
        {
            sy4: "ܢܓܪܘܬܐ",
            id: "1859",
            dt2: "Tischlerei",
            dt1: "Handwerk",
            lt12: "",
            sy8: "",
            sy7: "",
            lt10: "nagorutho",
            dt3: "",
            sy9: "",
            sy5: "ܢܰܓܳܪܽܘܬ݂ܳܐ",
            lt11: "",
            sy6: ""
        },
        {
            sy5: "ܓܙܳܪܳܐ",
            sy4: "ܓܙܪܐ",
            lt12: "ĉudodo",
            id: "6058",
            lt11: "sheĥro",
            sy8: "ܥܘܕܕܐ",
            lt10: "gzoro",
            dt2: "",
            sy7: "ܫܶܚܪܳܐ",
            sy6: "ܫܚܪܐ",
            sy9: "ܥܽܘܕܳܕܳܐ",
            dt3: "",
            dt1: "Herde"
        },
        {
            sy7: "",
            lt10: "debobo",
            lt12: "",
            lt11: "",
            id: "1332",
            sy9: "",
            sy5: "ܕܶܒܳܒܳܐ",
            dt1: "Fliege",
            sy6: "",
            dt2: "",
            dt3: "",
            sy8: "",
            sy4: "ܕܒܒܐ"
        },
        {
            sy6: "",
            id: "405",
            sy5: "ܐܰܝܠܰܐ",
            sy4: "ܐܝܠܐ",
            sy7: "",
            lt11: "ܐܝܠܐ",
            dt2: "",
            sy9: "",
            lt10: "ܐܝܠܐ",
            dt1: "Ayla",
            lt12: "",
            dt3: "",
            sy8: ""
        },
        {
            lt12: "",
            dt1: "Unterkiefer",
            sy5: "ܠܽܘܥܳܐ ܬܰܚܬܳܝܳܐ",
            id: "4663",
            lt11: "",
            sy6: "",
            sy8: "",
            lt10: "luĉo taĥtoyo",
            sy4: "ܠܘܥܐ ܬܚܬܝܐ",
            dt2: "",
            sy7: "",
            dt3: "",
            sy9: ""
        },
        {
            dt3: "",
            sy7: "",
            id: "4765",
            sy6: "",
            sy5: "ܝܳܪܬܽܘܬ݂ܳܐ",
            sy4: "ܝܪܬܘܬܐ",
            sy8: "",
            dt2: "",
            lt10: "yortutho",
            sy9: "",
            lt12: "",
            dt1: "Vermächtnis",
            lt11: ""
        },
        {
            dt3: "",
            sy4: "ܐܬܝܒܠ",
            sy7: "",
            id: "5660",
            sy5: "ܐܶܬ݂ܝܰܒܰܠ",
            lt10: "ethyabal",
            sy6: "",
            sy8: "",
            dt2: "",
            lt12: "",
            sy9: "",
            lt11: "",
            dt1: "abstammen"
        },
        {
            id: "162",
            sy8: "",
            lt11: "ܐܚܐ",
            sy9: "",
            lt10: "ܐܚܐ",
            sy5: "ܐܰܚܳܐ",
            dt3: "",
            sy7: "",
            sy4: "ܐܚܐ",
            sy6: "",
            dt2: "",
            dt1: "Aho",
            lt12: ""
        },
        {
            sy8: "",
            sy4: "ܫܡܢܬܐ",
            lt12: "",
            sy7: "ܡܠܳܛܳܐ",
            lt11: "mloțo",
            dt3: "",
            sy5: "ܫܡܳܢܬܳܐ",
            lt10: "shmonto",
            dt1: "Zement",
            sy9: "",
            id: "5298",
            dt2: "",
            sy6: "ܡܠܛܐ"
        },
        {
            lt10: "maĥel",
            sy9: "ܐܰܘܚܶܠ",
            lt11: "yiĥel",
            dt2: "",
            dt3: "",
            lt12: "auĥel",
            sy6: "ܝܝܚܠ",
            id: "5635",
            dt1: "abschwächen",
            sy4: "ܡܚܠ",
            sy7: "ܝܺܝܚܶܠ",
            sy5: "ܡܰܚܶܠ",
            sy8: "ܐܘܚܠ"
        },
        {
            lt11: "",
            sy6: "",
            dt1: "Schuhmacher repariert Schuhe",
            id: "4042",
            sy9: "",
            sy8: "",
            sy7: "",
            sy5: "ܫܟܰܦܳܝܳܐ ܡܬܶܪܶܨ ܡܣܳܐܢ̈ܶܐ",
            dt2: "",
            lt12: "",
            lt10: "shkafoyo mtareŝ msone",
            sy4: "ܫܟܦܝܐ ܡܬܪܨ ܡܣܐܢܐ",
            dt3: ""
        },
        {
            sy9: "",
            dt1: "Chantal",
            sy4: "ܫܢܬܠ",
            dt2: "",
            sy7: "ܫܰܢܬܰܠ",
            sy6: "ܫܢܬܠ",
            sy8: "",
            sy5: "ܫܰܢܬܰܐܠ",
            lt11: "ܫܢܬܠ",
            dt3: "",
            id: "761",
            lt12: "ܫܢܬܠ",
            lt10: "ܫܢܬܠ"
        },
        {
            sy9: "",
            dt1: "Fett",
            sy6: "",
            lt10: "tarbo",
            dt3: "",
            sy5: "ܬܰܪܒܳܐ",
            lt11: "",
            sy7: "",
            sy8: "",
            lt12: "",
            sy4: "ܬܪܒܐ",
            dt2: "",
            id: "1299"
        },
        {
            dt3: "",
            sy6: "",
            lt12: "",
            sy7: "",
            sy8: "",
            sy5: "ܫܶܥܝܳܐ ܕܡ̈ܶܠܶܐ",
            id: "5168",
            lt11: "",
            lt10: "sheĉyo d`mele",
            dt2: "",
            dt1: "Wörterspiel",
            sy9: "",
            sy4: "ܫܥܝܐ ܕܡܠܐ"
        },
        {
            id: "5320",
            lt12: "",
            lt10: "ĥaylo d`grosho",
            sy9: "",
            lt11: "",
            sy4: "ܚܝܠܐ ܕܓܪܫܐ",
            sy7: "",
            sy5: "ܚܰܝܠܳܐ ܕܓܪܳܫܳܐ",
            dt1: "Ziehkraft",
            sy8: "",
            dt2: "",
            dt3: "",
            sy6: ""
        },
        {
            dt2: "Wärme",
            sy7: "ܚܰܡܺܝܡܽܘܬ݂ܳܐ",
            sy4: "ܫܚܢܬܐ",
            dt3: "",
            dt1: "Hitze",
            lt12: "ĥumo",
            sy5: "ܫܚܰܢܬ݂ܳܐ",
            lt11: "ĥamimutho",
            sy9: "ܚܽܘܡܳܐ",
            lt10: "shĥantho",
            sy6: "ܚܡܝܡܘܬܐ",
            sy8: "ܚܘܡܐ",
            id: "2025"
        },
        {
            id: "2403",
            dt1: "Kanal",
            lt11: "",
            sy5: "ܐܰܓܽܘܓܳܐ",
            lt10: "agugo",
            dt3: "",
            sy4: "ܐܓܘܓܐ",
            dt2: "",
            sy6: "",
            sy7: "",
            sy8: "",
            sy9: "",
            lt12: ""
        },
        {
            sy6: "",
            dt2: "",
            sy9: "",
            sy8: "",
            sy4: "ܬܪܝܢܐ",
            lt11: "",
            dt3: "",
            lt12: "",
            sy7: "",
            dt1: "Zweiter",
            lt10: "trayono",
            sy5: "ܬܪܰܝܳܢܳܐ",
            id: "5397"
        },
        {
            sy7: "",
            lt11: "",
            id: "3937",
            lt10: "zĉofo",
            dt1: "Schieben",
            dt2: "",
            sy8: "",
            dt3: "",
            sy4: "ܙܥܦܐ",
            sy9: "",
            lt12: "",
            sy6: "",
            sy5: "ܙܥܳܦܳܐ"
        },
        {
            sy4: "ܫܘܫ",
            sy8: "",
            lt11: "mshawesh",
            lt10: "shawesh",
            sy7: "ܡܫܰܘܶܫ",
            sy9: "",
            dt3: "",
            lt12: "",
            id: "4825",
            dt1: "verwechseln",
            dt2: "",
            sy5: "ܫܰܘܶܫ",
            sy6: "ܡܫܘܫ"
        },
        {
            dt1: "objektiv",
            id: "6133",
            dt3: "",
            lt11: "maĉbđonutho",
            dt2: "",
            lt10: "msimono",
            lt12: "",
            sy4: "ܡܣܝܡܢܐ",
            sy8: "",
            sy6: "ܡܥܒܕܢܘܬܐ",
            sy5: "ܡܣܺܝܡܳܢܳܐ",
            sy7: "ܡܰܥܒܕܳܢܽܘܬ݂ܳܐ",
            sy9: ""
        },
        {
            sy4: "ܒܝܬ ܦܘܠܚܢܐ",
            dt3: "",
            lt10: "beth fulĥono",
            lt12: "",
            dt2: "",
            sy8: "",
            sy9: "",
            sy7: "ܡܰܥܡܠܳܐ",
            lt11: "maĉmlo",
            sy6: "ܡܥܡܠܐ",
            id: "1223",
            sy5: "ܒܶܝܬ݂ ܦܽܘܠܚܳܢܳܐ",
            dt1: "Fabrik"
        },
        {
            id: "1129",
            dt1: "Eramja",
            sy8: "",
            sy5: "ܐܶܪܰܡܝܰܐ",
            sy6: "",
            sy4: "ܐܪܡܝܐ",
            lt11: "ܐܪܡܝܐ",
            sy7: "",
            dt2: "",
            sy9: "",
            lt12: "",
            dt3: "",
            lt10: "ܐܪܡܝܐ"
        },
        {
            lt12: "",
            sy5: "ܕܰܪܚܺܝܡ ܒܟܽܠ",
            dt3: "",
            id: "199",
            sy6: "",
            sy4: "ܕܪܚܝܡ ܒܟܠ",
            dt1: "am allerliebsten",
            dt2: "",
            lt11: "",
            sy9: "",
            lt10: "da`rĥim bkul",
            sy8: "",
            sy7: ""
        },
        {
            lt12: "",
            sy8: "",
            sy6: "ܬܢܝܢܐ",
            id: "5100",
            sy9: "",
            lt11: "tenyono",
            sy7: "ܬܶܢܝܳܢܳܐ",
            dt2: "",
            sy5: "ܬܢܳܝܳܐ",
            sy4: "ܬܢܝܐ",
            lt10: "tnoyo",
            dt3: "",
            dt1: "Wiederholung"
        },
        {
            lt12: "",
            id: "5123",
            sy4: "ܚܢܢ ܟܬܒܝܢܢ",
            dt1: "Wir schreiben",
            sy8: "",
            lt11: "ĥnan kothbonan",
            sy6: "ܚܢܢ ܟܬܒܢܢ",
            dt3: "",
            sy7: "ܚܢܰܢ ܟܳܬ݂ܒܳܢܰܢ",
            sy5: "ܚܢܰܢ ܟܳܬ݂ܒܺܝܢܰܢ",
            lt10: "ĥnan kothbinan",
            dt2: "Wir sind am schreiben",
            sy9: ""
        },
        {
            lt11: "",
            sy9: "",
            sy4: "ܬܓܪܘܬ ܩܝܣܐ",
            sy5: "ܬܰܓܳܪܽܘܬ݂ ܩܰܝ̈ܣܶܐ",
            sy8: "",
            dt2: "",
            id: "6053",
            sy6: "",
            sy7: "",
            dt3: "",
            dt1: "Holzhandel",
            lt12: "",
            lt10: "tagoruth qayse"
        },
        {
            id: "2359",
            sy4: "ܝܘܠܝܘܣ",
            lt12: "",
            sy9: "",
            lt11: "ܝܘܠܝܘܣ",
            sy7: "",
            dt1: "Julius",
            sy6: "",
            lt10: "ܝܘܠܝܘܣ",
            dt2: "",
            sy5: "ܝܽܘܠܺܝܳܘܣ",
            sy8: "",
            dt3: ""
        },
        {
            id: "5802",
            lt11: "ĉayed laqlimo",
            sy4: "ܐܬܥܝܕ ܠܩܠܝܡܐ",
            dt3: "",
            dt2: "",
            sy8: "",
            lt12: "",
            sy9: "",
            sy7: "ܥܰܝܶܕ ܠܰܩܠܺܝܡܳܐ",
            dt1: "akklimatisieren",
            sy5: "ܐܰܬ݂ܥܰܝܰܕ ܠܰܩܠܺܝܡܳܐ",
            sy6: "ܥܝܕ ܠܩܠܝܡܐ",
            lt10: "athĉayad laqlimo"
        },
        {
            dt2: "",
            lt12: "bahbah",
            sy4: "ܒܠܗܝ",
            sy6: "ܠܗܠܗ",
            lt10: "balhi",
            sy9: "ܒܰܗܒܰܗ",
            sy8: "ܒܗܒܗ",
            dt1: "irremachen",
            lt11: "lahlah",
            id: "6166",
            sy7: "ܠܰܗܠܰܗ",
            sy5: "ܒܰܠܗܺܝ",
            dt3: ""
        },
        {
            sy6: "",
            sy5: "ܫܰܪܶܓ݂",
            lt12: "",
            sy8: "",
            id: "2176",
            dt2: "",
            sy4: "ܫܪܓ",
            lt11: "",
            dt3: "",
            dt1: "in Brand setzen",
            lt10: "shareğ",
            sy9: "",
            sy7: ""
        },
        {
            sy5: "ܫܰܢܬ݂ܳܐ",
            sy6: "ܒܛܝܠܘܬܐ",
            id: "3122",
            lt10: "shantho",
            dt2: "",
            lt12: "ĉayifuto",
            lt11: "bațilutho",
            dt1: "Müdigkeit",
            dt3: "",
            sy7: "ܒܰܛܺܝܠܽܘܬ݂ܳܐ",
            sy9: "ܥܰܝܝܺܦܽܘܬ݂ܳܐ",
            sy8: "ܥܝܝܦܘܬܐ",
            sy4: "ܫܢܬܐ"
        },
        {
            sy9: "",
            lt12: "",
            dt1: "Schreiber",
            sy5: "ܟܬ݂ܽܘܒܳܐ",
            lt10: "kothubo",
            sy8: "",
            dt2: "Autor",
            sy6: "",
            id: "4028",
            sy7: "",
            lt11: "",
            sy4: "ܟܬܘܒܐ",
            dt3: ""
        },
        {
            lt10: "ܙܝܠܐ",
            sy6: "",
            dt1: "Zilla",
            lt12: "",
            sy9: "",
            id: "5324",
            sy4: "ܙܝܠܐ",
            sy7: "",
            sy5: "ܙܺܝܠܰܐ",
            dt2: "",
            dt3: "",
            lt11: "ܙܝܠܐ",
            sy8: ""
        },
        {
            dt2: "",
            dt1: "Nadin",
            sy9: "",
            id: "3175",
            sy8: "",
            sy4: "ܢܐܕܝܢ",
            sy5: "ܢܰܐܕܺܝܢ",
            sy7: "",
            lt11: "ܢܐܕܝܢ",
            sy6: "",
            dt3: "",
            lt12: "",
            lt10: "ܢܐܕܝܢ"
        },
        {
            sy4: "ܛܠܘܡܘܬܐ",
            id: "4609",
            dt2: "Folter",
            lt10: "țlumutho",
            sy7: "ܛܠܽܘܡܝܳܐ",
            sy6: "ܛܠܘܡܝܐ",
            sy5: "ܛܠܽܘܡܽܘܬ݂ܳܐ",
            sy9: "",
            lt11: "țlumyo",
            sy8: "",
            dt3: "",
            lt12: "",
            dt1: "Tyrannei"
        },
        {
            dt3: "",
            lt10: "meŝĉath ĥaqlo",
            sy6: "",
            sy8: "",
            lt12: "",
            dt2: "",
            dt1: "Mittelfeld",
            lt11: "",
            id: "6354",
            sy9: "",
            sy5: "ܡܶܨܥܰܬ݂ ܚܰܩܠܳܐ",
            sy4: "ܡܨܥܬ ܚܩܠܐ",
            sy7: ""
        },
        {
            dt3: "",
            dt1: "ergänzen",
            sy6: "",
            dt2: "",
            sy7: "",
            sy4: "ܫܡܠܝ",
            lt10: "shamli",
            lt11: "",
            lt12: "",
            sy8: "",
            sy9: "",
            id: "1149",
            sy5: "ܫܰܡܠܺܝ"
        },
        {
            sy9: "",
            sy8: "",
            lt12: "",
            dt3: "",
            sy7: "",
            lt10: "ŝaumo da shliĥe",
            sy6: "",
            sy5: "ܨܰܘܡܳܐ ܕܰܫܠܺܝܚ̈ܶܐ",
            dt1: "Fasten der Apostel",
            dt2: "",
            sy4: "ܨܘܡܐ ܕܫܠܝܚܐ",
            id: "1263",
            lt11: ""
        },
        {
            sy9: "",
            lt10: "yabsho",
            sy8: "",
            dt3: "",
            sy4: "ܝܒܫܐ",
            sy6: "",
            sy7: "",
            sy5: "ܝܰܒܫܳܐ",
            dt1: "Festland",
            dt2: "Kontinent",
            id: "1298",
            lt12: "",
            lt11: ""
        },
        {
            sy6: "",
            dt2: "",
            sy5: "ܒܶܝܬ݂ ܨܰܘܒܳܐ",
            lt12: "",
            id: "4648",
            sy9: "",
            sy7: "",
            lt11: "",
            dt1: "Universität",
            lt10: "beth ŝaubo",
            dt3: "",
            sy4: "ܒܝܬ ܨܘܒܐ",
            sy8: ""
        },
        {
            sy7: "ܡܶܫܬܰܘܚܪܳܢܳܐ",
            lt11: "meshtauĥrono",
            dt2: "",
            sy8: "",
            sy6: "ܡܫܬܘܚܪܢܐ",
            lt12: "",
            lt10: "methĉaukono",
            dt1: "Verspäteter",
            sy9: "",
            dt3: "",
            id: "6423",
            sy5: "ܡܶܬ݂ܥܰܘܟܳܢܳܐ",
            sy4: "ܡܬܥܘܟܢܐ"
        },
        {
            lt12: "",
            lt10: "yuqro",
            sy9: "",
            sy4: "ܝܘܩܪܐ",
            dt3: "Schwerpunkt",
            dt2: "Last",
            lt11: "țaĉno",
            sy8: "",
            sy6: "ܛܥܢܐ",
            sy7: "ܛܰܥܢܳܐ",
            dt1: "Gewicht",
            id: "1621",
            sy5: "ܝܽܘܩܪܳܐ"
        },
        {
            id: "1796",
            sy4: "ܗܒܐܝܠ",
            sy5: "ܗܰܒܐܝܺܠ",
            dt3: "",
            dt2: "",
            lt11: "ܗܒܐܝܠ",
            sy6: "",
            lt10: "ܗܒܐܝܠ",
            sy8: "",
            sy9: "",
            lt12: "",
            dt1: "Habil",
            sy7: ""
        },
        {
            lt11: "",
            dt1: "Mittelfeldspieler",
            lt12: "",
            sy9: "",
            lt10: "meshtaĉyono da'mŝaĉtho",
            dt2: "",
            dt3: "",
            sy8: "",
            sy5: "ܡܶܫܬܰܥܝܳܢܳܐ ܕܰܡܨܰܥܬ݂ܳܐ",
            sy7: "",
            sy6: "",
            id: "6355",
            sy4: "ܡܫܬܥܝܢܐ ܕܡܨܥܬܐ"
        },
        {
            sy8: "",
            sy6: "",
            lt12: "",
            dt2: "",
            lt10: "men mŝaĉtho",
            sy4: "ܡܢ ܡܨܥܬܐ",
            dt1: "mittendurch",
            sy5: "ܡܶܢ ܡܨܰܥܬ݂ܳܐ",
            id: "6372",
            dt3: "",
            lt11: "",
            sy9: "",
            sy7: ""
        },
        {
            dt1: "Nuh",
            dt3: "Noach",
            sy8: "",
            sy7: "",
            sy6: "",
            sy5: "ܢܽܘܚ",
            sy9: "",
            dt2: "Noah",
            lt10: "ܢܘܚ",
            id: "3307",
            lt11: "ܢܘܚ",
            lt12: "",
            sy4: "ܢܘܚ"
        },
        {
            lt12: "",
            dt1: "Ich lese mit lauter Stimme",
            sy9: "",
            dt2: "",
            sy4: "ܩܪܐ ܐܢܐ ܒܩܠܐ ܪܡܐ",
            id: "2123",
            sy8: "",
            sy6: "",
            dt3: "",
            sy5: "ܩܳܪܶܐ ܐ̱ܢܳܐ ܒܩܳܠܳܐ ܪܳܡܳܐ",
            lt10: "qore no b`qolo romo",
            sy7: "",
            lt11: ""
        },
        {
            sy6: "",
            dt1: "Sängerin singt Lieder",
            sy7: "",
            sy9: "",
            lt11: "",
            lt12: "",
            id: "3840",
            sy4: "ܙܡܪܬܐ ܙܡܪܐ ܙܡܝܪܬܐ",
            dt3: "",
            sy8: "",
            dt2: "",
            lt10: "zamorto zomro zmirotho",
            sy5: "ܙܰܡܪܳܬܐ ܙܳܡܪܳܐ ܙܡܺܝܪܳܬ݂ܳܐ"
        },
        {
            sy9: "",
            dt3: "",
            id: "1971",
            sy5: "ܡܳܪܝ̱ ܝܶܫܽܘܥ ܡܫܺܝܚܳܐ ܒܪܳܐ ܕܰܐܠܳܗܳܐ ܐܶܬ݂ܪܰܚܰܡ ܥܠܰܝ",
            sy8: "",
            lt10: "Mor Yeshuĉ Mshiĥo bro d'aloho ethraĥam ĉlay ",
            lt12: "",
            sy7: "",
            dt2: "",
            dt1: "Herr Jesus Christus  Sohn Gottes erbarme dich meiner",
            lt11: "",
            sy6: "",
            sy4: "ܡܪܝ ܝܫܘܥ ܡܫܝܚܐ ܒܪܐ ܕܐܠܗܐ ܐܬܪܚܡ ܥܠܝ"
        },
        {
            sy6: "",
            dt3: "",
            sy5: "ܡܶܠܶܟ",
            dt2: "Malak",
            sy9: "",
            lt11: "ܡܠܟ",
            id: "2994",
            lt12: "",
            dt1: "Melek",
            sy7: "",
            sy8: "",
            lt10: "ܡܠܟ",
            sy4: "ܡܠܟ"
        },
        {
            id: "3626",
            sy5: "ܪܰܘܝܽܘܬܰܐ",
            sy7: "",
            sy9: "",
            sy6: "",
            dt1: "Rauyuta",
            lt11: "ܪܘܝܘܬܐ",
            dt3: "",
            lt10: "ܪܘܝܘܬܐ",
            dt2: "",
            sy8: "",
            lt12: "",
            sy4: "ܪܘܝܘܬܐ"
        },
        {
            dt1: "Dorf Arkah",
            dt2: "Arkah",
            sy4: "ܩܪܝܬܐ ܕܐܪܟܚ",
            dt3: "",
            id: "923",
            lt10: "qritho d`arkaĥ",
            sy8: "",
            sy5: "ܩܪܺܝܬ݂ܳܐ ܕܰܐܪܟܰܚ",
            lt11: "arkaĥ",
            sy9: "",
            sy6: "ܐܪܟܚ",
            lt12: "",
            sy7: "ܐܰܪܟܰܚ"
        },
        {
            sy7: "",
            dt3: "",
            sy6: "",
            sy8: "",
            lt10: "he",
            dt1: "h",
            sy4: "ܗ",
            sy5: "ܗ",
            id: "1779",
            lt12: "",
            dt2: "",
            lt11: "",
            sy9: ""
        },
        {
            lt12: "",
            lt10: "bothar hurizon",
            dt3: "",
            sy6: "ܒܬ݂ܪ ܗܘܪܝܙܘܢ",
            dt1: "hinter dem Horizont",
            lt11: "bothar hurizon",
            sy9: "",
            id: "2010",
            sy8: "",
            sy7: "ܒܳܬ݂ܰܪ ܗܽܘܪܺܝܙܳܘܢ",
            sy5: "ܒܳܬ݂ܰܪ ܗܽܘܪܺܝܙܳܘܢ",
            sy4: "ܒܬ݂ܪ ܗܘܪܝܙܘܢ",
            dt2: ""
        },
        {
            lt11: "",
            sy7: "",
            sy6: "",
            sy5: "ܒܟ݂ܺܝܪܳܐ ",
            sy9: "",
            lt12: "",
            dt1: "zu früh",
            sy8: "",
            id: "5351",
            sy4: "ܒܟܝܪܐ ",
            dt2: "",
            lt10: "bkhiro",
            dt3: ""
        },
        {
            dt3: "",
            lt10: "mĥaylono d`qolo",
            dt2: "",
            sy6: "",
            sy4: "ܡܚܝܠܢܐ ܕܩܠܐ",
            sy9: "",
            sy8: "",
            dt1: "Lautsprecher",
            lt11: "",
            id: "6417",
            lt12: "",
            sy5: "ܡܚܰܝܠܳܢܳܐ ܕܩܳܠܳܐ",
            sy7: ""
        },
        {
            lt10: "niqoțin",
            sy6: "",
            sy9: "",
            sy7: "",
            dt1: "Nikotin",
            lt12: "",
            dt3: "",
            dt2: "",
            sy5: "ܢܺܩܳܘܛܺܝܢ",
            sy4: "ܢܩܘܛܝܢ",
            sy8: "",
            lt11: "",
            id: "3278"
        },
        {
            dt3: "",
            lt11: "ܐܕܢܬܐ",
            id: "3337",
            sy7: "",
            sy4: "ܐܕܢܬܐ",
            dt2: "",
            sy5: "ܐܳܕܶܢܰܬܰܐ",
            lt10: "ܐܕܢܬܐ",
            sy9: "",
            sy6: "",
            lt12: "",
            dt1: "Odenata",
            sy8: ""
        },
        {
            dt3: "",
            lt11: "",
            sy9: "",
            sy7: "",
            sy8: "",
            lt12: "",
            sy6: "",
            sy5: "ܕܺܝܰܬ݂ܺܝܩܺܐ ܥܰܬܺܝܩܳܐ",
            lt10: "diyathiqi ĉatiqo",
            dt1: "Altes Testament",
            dt2: "",
            id: "198",
            sy4: "ܕܝܬܝܩܐ ܥܬܝܩܐ"
        },
        {
            sy5: "ܓܰܒܪܺܐܝܶܠ ܝܽܘܣܶܦ",
            sy6: "",
            lt11: "ܓܒܪܐܝܠ ܝܘܣܦ",
            id: "1455",
            sy8: "",
            sy7: "",
            lt10: "ܓܒܪܐܝܠ ܝܘܣܦ",
            lt12: "",
            dt2: "",
            sy4: "ܓܒܪܝܐܝܠ ܝܘܣܦ",
            dt3: "",
            dt1: "Gabriel Joseph",
            sy9: ""
        },
        {
            sy5: "ܩܰܘܡܬ݂ܳܐ",
            lt12: "",
            sy8: "",
            dt2: "",
            sy7: "ܩܽܘܡܬ݂ܳܐ",
            sy6: "ܩܘܡܬܐ",
            lt11: "qumtho",
            dt3: "",
            sy4: "ܩܘܡܬܐ",
            dt1: "Körpergröße",
            sy9: "",
            lt10: "qaumtho",
            id: "2616"
        },
        {
            dt3: "",
            lt12: "shmiho",
            dt2: "beliebt",
            sy5: "ܥܰܡܡܳܝܳܐ",
            sy6: "ܪܚܝܡܝܐ",
            dt1: "populär",
            sy4: "ܥܡܡܝܐ",
            lt11: "rĥimoyo",
            id: "3491",
            sy9: "ܫܡܺܝܗܳܐ",
            sy7: "ܪܚܺܝܡܳܝܳܐ",
            sy8: "ܫܡܝܗܐ",
            lt10: "ĉamoyo"
        },
        {
            sy8: "",
            lt11: "",
            lt10: "waziro d`baroyutho",
            dt2: "",
            dt1: "Außenminister",
            sy6: "",
            dt3: "",
            lt12: "",
            sy9: "",
            id: "386",
            sy7: "",
            sy5: "ܘܰܙܺܝܪܳܐ ܕܒܰܪܳܝܽܘܬ݂ܳܐ",
            sy4: "ܘܙܝܪܐ ܕܒܪܝܘܬܐ"
        },
        {
            dt1: "Trista",
            dt3: "",
            sy4: "ܬܪܝܨܬܐ",
            sy6: "",
            sy5: "ܬܪܺܝܨܬܰܐ",
            sy7: "",
            lt10: "ܬܪܝܨܬܐ",
            lt12: "",
            dt2: "",
            lt11: "ܬܪܝܨܬܐ",
            id: "4568",
            sy9: "",
            sy8: ""
        },
        {
            sy7: "",
            sy8: "",
            sy5: "ܦܰܪܳܐ",
            dt3: "",
            lt12: "",
            sy4: "ܦܪܐ",
            id: "1259",
            lt11: "ܦܪܐ",
            sy9: "",
            dt1: "Faro",
            dt2: "",
            lt10: "ܦܪܐ",
            sy6: ""
        },
        {
            sy7: "",
            dt1: "Guryo",
            sy8: "",
            dt2: "",
            sy4: "ܓܘܪܝܐ",
            lt10: "ܓܘܪܝܐ",
            lt12: "",
            sy5: "ܓܽܘܪܝܳܐ",
            id: "1767",
            dt3: "",
            sy6: "",
            lt11: "ܓܘܪܝܐ",
            sy9: ""
        },
        {
            sy9: "",
            dt2: "kurdisch",
            lt10: "kurdoyo",
            sy6: "ܩܘܪܕܝܐ",
            dt3: "",
            dt1: "Kurde",
            sy5: "ܟܽܘܪܕܳܝܳܐ",
            sy4: "ܟܘܪܕܝܐ",
            lt12: "",
            id: "2672",
            sy7: "ܩܳܘܪܕܳܝܳܐ",
            lt11: "qordoyo",
            sy8: ""
        },
        {
            id: "9",
            sy6: "ܬܡܢܐ",
            sy4: "ܬܡܢܝܐ",
            sy8: "ܚ",
            dt2: "Acht",
            dt3: "",
            sy5: "ܬܡܳܢܝܳܐ",
            lt11: "tmone",
            sy7: "ܬܡܳܢܶܐ",
            sy9: "ܚ",
            lt12: "ĥeth",
            lt10: "tmonyo",
            dt1: "8"
        },
        {
            dt2: "",
            dt1: "Fisch",
            sy4: "ܢܘܢܐ",
            sy6: "",
            dt3: "",
            lt11: "",
            sy9: "",
            sy5: "ܢܽܘܢܳܐ",
            id: "1319",
            lt10: "nuno",
            lt12: "",
            sy7: "",
            sy8: ""
        },
        {
            sy4: "ܩܡ",
            lt10: "qom",
            id: "360",
            lt11: "qoem",
            sy9: "",
            dt1: "aufstehen",
            sy5: "ܩܳܡ",
            sy6: "ܩܐܡ",
            dt2: "",
            sy7: "ܩܳܐܶܡ",
            dt3: "",
            sy8: "",
            lt12: ""
        },
        {
            sy5: "ܫܰܠܺܝܛܳܐ",
            sy8: "",
            dt1: "Schalito",
            lt11: "ܫܠܝܛܐ",
            dt3: "",
            dt2: "",
            lt12: "",
            id: "3886",
            sy7: "",
            sy6: "",
            sy9: "",
            sy4: "ܫܠܝܛܐ",
            lt10: "ܫܠܝܛܐ"
        },
        {
            sy6: "",
            sy7: "",
            lt11: "",
            lt10: "malfono",
            dt1: "Lehrer",
            lt12: "",
            id: "2740",
            sy4: "ܡܠܦܢܐ",
            dt3: "",
            sy8: "",
            sy5: "ܡܰܠܦܳܢܳܐ",
            sy9: "",
            dt2: ""
        },
        {
            dt3: "",
            lt10: "waĉed",
            sy6: "",
            sy5: "ܘܰܥܶܕ",
            sy7: "",
            sy9: "",
            sy8: "",
            lt12: "",
            sy4: "ܘܥܕ",
            dt1: "verabreden",
            dt2: "",
            id: "4715",
            lt11: ""
        },
        {
            dt2: "",
            id: "1756",
            sy9: "",
            sy5: "ܩܦܰܕ",
            sy7: "",
            lt10: "qfad",
            sy6: "",
            lt11: "",
            sy4: "ܩܦܕ",
            sy8: "",
            dt3: "",
            lt12: "",
            dt1: "gruseln"
        },
        {
            sy4: "ܡܢܚܛܢܐ",
            sy5: "ܡܢܰܚܛܳܢܳܐ",
            dt3: "",
            dt2: "",
            lt12: "",
            sy8: "",
            id: "6331",
            dt1: "Schnarcher",
            sy7: "",
            lt10: "mnaĥțono",
            lt11: "",
            sy9: "",
            sy6: ""
        },
        {
            sy5: "ܥܰܠܶܩ",
            dt1: "haften",
            dt2: "anhaften",
            lt10: "ĉaleq",
            sy7: "",
            sy9: "",
            sy4: "ܥܠܩ",
            lt11: "",
            dt3: "festhängen",
            sy6: "",
            id: "1818",
            sy8: "",
            lt12: ""
        },
        {
            sy4: "ܫܪܫܐ",
            sy7: "",
            lt11: "",
            lt12: "",
            dt2: "",
            sy8: "",
            dt1: "Ursprung",
            dt3: "",
            sy9: "",
            sy6: "",
            sy5: "ܫܶܪܫܳܐ",
            lt10: "shersho",
            id: "4701"
        },
        {
            dt3: "",
            sy8: "",
            sy6: "",
            dt2: "",
            dt1: "Jeep",
            lt12: "",
            sy4: "ܓܝܒ",
            sy5: "ܔܺܝܒ",
            sy7: "",
            lt10: "shib",
            lt11: "",
            id: "2295",
            sy9: ""
        },
        {
            sy8: "ܝܼ",
            lt12: "juth u nuqso",
            sy5: "ܥܶܣܪܳܐ ܐܠܦܝܢ",
            sy6: "ܥܣܪ ܐܠܦܝܢ",
            dt1: "10000",
            dt2: "zehntausend",
            sy7: "ܥܣܰܪ ܐܰܠܦܺܝܢ",
            dt3: "",
            sy9: "ܝܼ",
            id: "49",
            sy4: "ܥܣܪܐ ܐܠܦܝܢ",
            lt11: "ĉsar alfin",
            lt10: "ĉesro alfin"
        },
        {
            dt3: "",
            lt10: "mfisono",
            lt12: "",
            id: "1434",
            dt1: "Fürsprecher",
            sy9: "",
            sy5: "ܡܦܺܝܣܳܢܳܐ",
            sy4: "ܡܦܝܣܢܐ",
            sy7: "ܡܶܬ݂ܟܰܫܦܳܢܳܐ",
            sy6: "ܡܬܟܫܦܢܐ",
            lt11: "methkashfono",
            dt2: "",
            sy8: ""
        },
        {
            id: "4849",
            lt11: "qțul ĉamo",
            lt12: "",
            sy7: "ܩܛܽܘܠ ܥܰܡܳܐ",
            sy5: "ܣܰܝܦܳܐ",
            sy4: "ܣܝܦܐ",
            sy9: "",
            sy8: "",
            dt3: "",
            dt2: "Genozid",
            lt10: "sayfo",
            dt1: "Völkermord",
            sy6: "ܩܛܘܠ ܥܡܐ"
        },
        {
            sy4: "ܝܕܠܦ",
            id: "2323",
            sy5: "ܝܺܕܠܰܦ",
            dt1: "Jidlaf",
            sy6: "ܝܪܠܦ",
            lt10: "ܝܕܠܦ",
            dt2: "",
            lt11: "ܝܕܠܦ",
            dt3: "",
            sy7: "ܝܰܪܠܰܦ",
            sy9: "",
            sy8: "",
            lt12: "ܝܪܠܦ"
        },
        {
            lt12: "",
            lt10: "shin",
            sy9: "",
            dt1: "sch",
            sy5: "ܫ",
            sy6: "",
            id: "3861",
            dt2: "",
            sy7: "",
            sy4: "ܫ",
            dt3: "",
            lt11: "",
            sy8: ""
        },
        {
            id: "5243",
            dt3: "",
            sy5: "ܙܰܗܪܳܐ",
            sy4: "ܙܗܪܐ",
            dt1: "Zahro",
            lt11: "ܙܗܪܐ",
            sy6: "",
            lt12: "",
            sy8: "",
            lt10: "ܙܗܪܐ",
            sy9: "",
            sy7: "",
            dt2: ""
        },
        {
            lt11: "",
            id: "6070",
            dt1: "Konkurserklärung",
            dt3: "",
            sy6: "",
            sy9: "",
            sy5: "ܦܽܘܣܩܳܢ ܚܽܘܣܪܳܢܳܐ",
            sy4: "ܦܘܣܩܢ ܚܘܣܪܢܐ",
            sy8: "",
            lt10: "fusqon ĥusrono",
            sy7: "",
            lt12: "",
            dt2: ""
        },
        {
            dt3: "",
            sy7: "",
            lt10: "maudyonutho",
            dt1: "Dankbarkeit",
            sy8: "",
            lt11: "",
            sy5: "ܡܰܘܕܝܳܢܽܘܬ݂ܳܐ",
            id: "801",
            dt2: "",
            sy4: "ܡܘܕܝܢܘܬܐ",
            sy6: "",
            sy9: "",
            lt12: ""
        },
        {
            sy4: "ܣܕܪܐ ܥܠܝܐ",
            sy6: "",
            dt3: "",
            id: "6136",
            lt10: "sedre ĉeloye",
            lt11: "",
            sy5: "ܣܶܕܪܶܐ ܥܶܠܳܝܶܐ",
            lt12: "",
            sy8: "",
            sy7: "",
            sy9: "",
            dt2: "",
            dt1: "Oberklassen"
        },
        {
            sy7: "",
            dt3: "",
            sy5: "ܡܛܰܠܥܳܐ",
            sy8: "",
            lt11: "",
            sy4: "ܡܛܠܥܐ",
            sy6: "",
            sy9: "",
            dt1: "ohnmächtig",
            id: "3358",
            lt12: "",
            lt10: "mțalĉo",
            dt2: ""
        },
        {
            lt12: "",
            id: "1343",
            lt11: "",
            dt2: "",
            sy9: "",
            sy5: "ܒܶܝܬ݂ ܛܰܘܣܳܐ",
            lt10: "beth țauso",
            dt1: "Flughafen",
            sy7: "",
            dt3: "",
            sy6: "",
            sy8: "",
            sy4: "ܒܝܬ ܛܘܣܐ"
        },
        {
            sy6: "",
            sy5: "ܒܰܪ̱ܬܕܺܝܢܰܐ",
            sy4: "ܒܪ̱ܬܕܝܢܐ",
            dt1: "Bathdina",
            sy7: "",
            dt3: "",
            sy9: "",
            lt12: "",
            sy8: "",
            dt2: "",
            id: "497",
            lt11: "ܒܪܬܕܝܢܐ",
            lt10: "ܒܪܬܕܝܢܐ"
        },
        {
            sy7: "",
            lt12: "",
            lt10: "mashrith yoriĉto",
            dt3: "",
            sy8: "",
            sy4: "ܡܫܪܝܬ ܝܪܝܥܬܐ",
            sy5: "ܡܰܫܪܺܝܬ݂ ܝܳܪܺܝܥܬܳܐ",
            id: "5297",
            sy6: "",
            sy9: "",
            dt1: "Zeltlager",
            lt11: "",
            dt2: ""
        },
        {
            sy8: "",
            sy5: "ܫܠܰܕܳܐ",
            lt12: "",
            sy9: "",
            dt2: "",
            dt3: "",
            sy6: "",
            sy7: "",
            lt11: "",
            sy4: "ܫܠܕܐ",
            lt10: "shlado",
            dt1: "Aas",
            id: "5542"
        },
        {
            dt2: "",
            dt3: "",
            lt11: "",
            lt12: "",
            lt10: "maqemli lokh",
            sy5: "ܡܰܩܶܡܠܺܝ ܠܳܟ݂",
            dt1: "ich habe dich aufgeweckt",
            sy9: "",
            id: "2111",
            sy6: "",
            sy7: "",
            sy8: "",
            sy4: "ܡܩܡܠܝ ܠܟ"
        },
        {
            lt12: "agĥekh",
            dt3: "",
            lt11: "gĥekh",
            sy6: "ܓܚܟ",
            sy9: "ܐܰܓܚܶܟ݂",
            id: "3930",
            sy7: "ܓܚܶܟ݂",
            lt10: "ahel",
            sy4: "ܐܗܠ",
            sy8: "ܐܓܚܟ",
            sy5: "ܐܰܗܶܠ",
            dt1: "scherzen",
            dt2: ""
        },
        {
            sy5: "ܗܽܘܠܳܢܳܝܽܘܬ݂ܳܐ",
            lt11: "",
            sy6: "",
            dt3: "",
            dt2: "Stofflichkeit",
            sy4: "ܗܘܠܢܝܘܬܐ",
            sy7: "",
            sy8: "",
            lt12: "",
            sy9: "",
            lt10: "hulonoyutho",
            id: "6318",
            dt1: "Materialismus"
        },
        {
            sy7: "",
            dt2: "",
            sy4: "ܡܪܝ ܛܝܡܬܐܘܣ ܫܡܘܐܝܠ ܐܩܛܫ",
            sy5: "ܡܳܪܝ̱ ܛܺܝܡܳܬܶܐܽܘܣ ܫܡܘܐܶܝܠ ܐܰܩܛܰܫ",
            lt10: "ܡܪܝ ܛܝܡܬܐܘܣ ܫܡܘܐܝܠ ܐܩܛܫ",
            lt11: "ܡܪܝ ܛܝܡܬܐܘܣ ܫܡܘܐܝܠ ܐܩܛܫ",
            dt1: "Mor Timotheos Samuel Aktas",
            sy9: "",
            sy6: "",
            lt12: "",
            id: "3097",
            dt3: "",
            sy8: ""
        },
        {
            sy7: "ܐܶܣܟܰܢܕܰܪ",
            dt1: "Alexander",
            sy8: "",
            sy5: "ܣܟܰܢܕܰܪ",
            sy9: "",
            lt12: "ܐܣܟܢܕܪ",
            dt2: "Iskender",
            lt10: "ܣܟܢܕܪ",
            dt3: "Skandar",
            id: "177",
            sy6: "ܐܣܟܢܕܪ",
            sy4: "ܣܟܢܕܪ",
            lt11: "ܣܟܢܕܪ"
        },
        {
            id: "3359",
            dt1: "Ohnmächtigkeit",
            sy9: "",
            dt2: "",
            dt3: "",
            sy4: "ܛܘܠܥܐ",
            sy6: "ܡܛܠܥܘܬܐ",
            lt12: "",
            sy5: "ܛܽܘܠܳܥܳܐ",
            sy8: "",
            sy7: "ܡܛܰܠܥܽܘܬ݂ܳܐ",
            lt11: "mțalĉutho",
            lt10: "țuloĉo"
        },
        {
            dt1: "Deqlo",
            id: "864",
            sy9: "",
            sy8: "",
            sy4: "ܕܩܠܐ",
            sy7: "",
            sy5: "ܕܶܩܠܳܐ",
            sy6: "",
            lt12: "",
            dt3: "",
            lt11: "ܕܩܠܐ",
            dt2: "",
            lt10: "ܕܩܠܐ"
        },
        {
            sy8: "",
            dt2: "",
            lt10: "ܥܬܗ",
            sy6: "",
            dt3: "",
            sy7: "",
            dt1: "Athe",
            id: "327",
            lt12: "",
            sy4: "ܥܬܗ",
            lt11: "ܥܬܗ",
            sy9: "",
            sy5: "ܥܰܬܶܗ"
        },
        {
            sy9: "",
            dt2: "Bricho",
            dt3: "",
            sy5: "ܒܪܺܟ݂ܳܐ",
            lt11: "ܒܪܟ݂ܐ",
            sy4: "ܒܪܟ݂ܐ",
            lt12: "",
            sy6: "",
            id: "709",
            sy8: "",
            lt10: "ܒܪܟ݂ܐ",
            sy7: "",
            dt1: "Brikho"
        },
        {
            lt12: "",
            lt11: "națar",
            sy7: "ܢܰܛܰܪ",
            lt10: "nțar",
            id: "601",
            sy8: "",
            sy6: "ܢܛܪ",
            dt2: "behüten",
            dt1: "bewachen",
            sy9: "",
            sy4: "ܢܛܪ",
            sy5: "ܢܛܰܪ",
            dt3: "warten"
        },
        {
            sy7: "ܢܳܪܓܳܐ",
            id: "402",
            sy6: "ܢܪܓܐ",
            sy4: "ܥܫܦܐ",
            sy5: "ܥܰܫܦܰܐ",
            dt2: "Beil",
            sy9: "ܢܰܪܓܳܐ",
            dt1: "Axt",
            lt11: "norgo",
            dt3: "",
            lt12: "nargo",
            sy8: "ܢܪܓܐ",
            lt10: "ĉashfo"
        },
        {
            id: "2084",
            dt3: "",
            dt2: "",
            sy5: "ܥܺܐܕܳܐ ܕܰܡܛܠܰܠܶܐ",
            lt11: "",
            sy7: "",
            lt10: "ĉiđo damțlale",
            sy4: "ܥܐܕܐ ܕܡܛܠܠܐ",
            sy6: "",
            lt12: "",
            sy8: "",
            sy9: "",
            dt1: "Hüttenfest"
        },
        {
            dt3: "",
            sy4: "ܫܦܝܪܬܐ",
            lt11: "ܫܦܝܪܬܐ",
            sy5: "ܫܰܦܺܝܪܬܰܐ",
            lt12: "",
            id: "3872",
            dt1: "Schafirta",
            sy7: "",
            sy9: "",
            lt10: "ܫܦܝܪܬܐ",
            sy6: "",
            sy8: "",
            dt2: ""
        },
        {
            lt10: "amin",
            lt12: "",
            sy4: "ܐܡܝܢ",
            sy5: "ܐܰܡܺܝܢ",
            dt2: "Amen",
            dt3: "",
            lt11: "",
            sy8: "",
            sy6: "",
            dt1: "Amin",
            id: "210",
            sy7: "",
            sy9: ""
        },
        {
            id: "4650",
            dt2: "",
            sy7: "",
            dt3: "",
            sy4: "ܙܠܝܡܐ",
            sy8: "",
            sy5: "ܙܺܠܝܳܡܐ",
            sy9: "",
            lt10: "zlimo",
            lt12: "",
            lt11: "",
            sy6: "",
            dt1: "unkeusch"
        },
        {
            sy4: "ܛܘܒ ܠܫܠܝܚܐ ܩܕܝܫܐ",
            sy8: "",
            lt10: "țub lashliĥe qadishe",
            sy9: "",
            sy7: "",
            lt12: "",
            lt11: "",
            sy6: "",
            dt2: "",
            sy5: "ܛܽܘܒ ܠܰܫܠܺܝܚ̈ܐ ܩܰܕܺܝܫ̈ܐ",
            dt1: "Gepriesen sind die heiligen Apostel",
            id: "1568",
            dt3: ""
        },
        {
            sy7: "",
            dt1: "Ich bin das Brot des Lebens",
            id: "2092",
            sy5: "ܐܶܢܳܐ ܐ̱ܢܳܐ ܠܰܚܡܳܐ ܕܚܰܝܶܐ",
            sy8: "",
            lt10: "eno no laĥmo d'ĥaye",
            sy6: "",
            lt12: "",
            sy9: "",
            lt11: "",
            dt2: "",
            sy4: "ܐܢܐ ܐܢܐ ܠܚܡܐ ܕܚܝܐ",
            dt3: ""
        },
        {
            id: "3894",
            sy4: "ܫܡܝܪ",
            dt2: "",
            sy8: "",
            lt10: "ܫܡܝܪ",
            sy7: "",
            sy6: "",
            dt1: "Schamir",
            sy5: "ܫܰܡܺܝܪ",
            sy9: "",
            lt11: "ܫܡܝܪ",
            lt12: "",
            dt3: ""
        },
        {
            sy7: "",
            lt10: "ܥܬܝܪ",
            lt11: "ܥܬܝܪ",
            id: "330",
            sy9: "",
            dt1: "Atir",
            dt3: "",
            lt12: "",
            sy5: "ܥܰܬܺܝܪ",
            dt2: "",
            sy8: "",
            sy6: "",
            sy4: "ܥܬܝܪ"
        },
        {
            lt12: "",
            sy8: "",
            dt1: "Geschichtshistoriker",
            sy9: "",
            id: "1581",
            sy5: "ܡܒܰܚܢܳܢܳܐ ܕܡܰܟܬܰܒ ܙܰܒܢܳܐ",
            sy4: "ܡܒܚܢܢܐ ܕܡܟܬܒ ܙܒܢܐ",
            sy6: "",
            dt3: "Geschichtsforscher",
            lt11: "",
            lt10: "mbaĥnono d`maktab zabno",
            sy7: "",
            dt2: "Historiker"
        },
        {
            sy7: "",
            lt10: "tmonaĉsiroyo",
            sy5: "ܬܡܳܢܰܥܣܺܝܪܳܝܳܐ",
            dt2: "",
            sy9: "",
            lt12: "",
            sy8: "",
            sy4: "ܬܡܢܥܣܝܪܝܐ",
            dt3: "",
            lt11: "",
            dt1: "achtzehnter",
            sy6: "",
            id: "5737"
        },
        {
            lt10: "yaruqutho",
            sy5: "ܝܰܪܽܘܩܽܘܬ݂ܳܐ",
            dt2: "Salat",
            sy8: "",
            dt3: "",
            lt11: "",
            lt12: "",
            sy4: "ܝܪܘܩܘܬܐ",
            sy9: "",
            sy6: "",
            sy7: "",
            dt1: "Gemüse",
            id: "1560"
        },
        {
            sy4: "ܡܣܦܪܐ",
            dt3: "",
            lt10: "masforo",
            dt1: "Schere",
            sy9: "",
            lt12: "",
            sy7: "",
            dt2: "",
            lt11: "",
            sy6: "",
            sy5: "ܡܰܣܦܳܪܳܐ",
            sy8: "",
            id: "3927"
        },
        {
            dt3: "",
            lt10: "ܫܠܐܬܝܠ",
            dt2: "Schelatiel",
            sy4: "ܫܠܐܬܝܠ",
            id: "3914",
            sy6: "",
            sy9: "",
            sy5: "ܫܶܠܰܐܬܺܝܶܠ",
            sy7: "",
            dt1: "Schealtiel",
            sy8: "",
            lt11: "ܫܠܐܬܝܠ",
            lt12: ""
        },
        {
            dt2: "",
            dt3: "",
            id: "249",
            sy7: "",
            sy5: "ܝܰܚܡܽܘܬ݂ܳܐ",
            sy8: "",
            sy6: "",
            sy9: "",
            sy4: "ܝܚܡܘܬܐ",
            lt11: "",
            lt10: "yaĥmutho",
            lt12: "",
            dt1: "Antilope"
        },
        {
            lt11: "kurhon lebo",
            lt12: "",
            sy8: "",
            sy4: "ܟܐܒ ܠܒܐ",
            id: "1981",
            sy5: "ܟܺܐܒ ܠܶܒܳܐ",
            sy7: "ܟܽܘܪܗܳܢ ܠܶܒܳܐ",
            dt3: "",
            lt10: "kib lebo",
            sy6: "ܟܘܪܗܢ ܠܒܐ",
            sy9: "",
            dt2: "",
            dt1: "Herzinfarkt"
        },
        {
            dt2: "",
            sy5: "ܓܰܒ̈ܪܶܐ",
            sy7: "",
            sy4: "ܓܒܪܐ",
            sy8: "",
            dt3: "",
            lt11: "",
            lt10: "gabre",
            sy9: "",
            sy6: "",
            dt1: "Männer",
            id: "2919",
            lt12: ""
        },
        {
            sy9: "",
            sy8: "",
            lt11: "mshamelyo",
            dt1: "fertig",
            lt10: "mțaybo",
            sy6: "ܡܫܡܠܝܐ",
            sy4: "ܡܛܝܒܐ",
            dt2: "",
            dt3: "",
            id: "1294",
            sy5: "ܡܛܰܝܒܳܐ",
            sy7: "ܡܫܰܡܶܠܝܳܐ",
            lt12: ""
        },
        {
            dt3: "",
            lt10: "ĥelqo",
            sy6: "ܚܘܠܩܐ",
            lt11: "ĥulqo",
            sy9: "",
            sy8: "",
            dt1: "Schicksal",
            sy7: "ܚܽܘܠܩܳܐ",
            dt2: "Vorherbestimmung",
            lt12: "",
            id: "3934",
            sy4: "ܚܠܩܐ",
            sy5: "ܚܶܠܩܳܐ"
        },
        {
            sy8: "",
            lt10: "ĥaylo d`gushmo",
            lt12: "",
            dt3: "",
            sy5: "ܚܰܝܠܳܐ ܕܓܽܘܫܡܳܐ",
            lt11: "",
            sy7: "",
            sy6: "",
            dt2: "",
            sy9: "",
            sy4: "ܚܝܠܐ ܕܓܘܫܡܐ",
            dt1: "Körperkraft",
            id: "2617"
        },
        {
            sy8: "",
            id: "1843",
            sy7: "",
            lt10: "ܚܢܢܐܝܠ",
            sy6: "",
            lt12: "",
            sy5: "ܚܰܢܰܢܐܝܶܠ",
            sy9: "",
            dt3: "",
            sy4: "ܚܢܢܐܝܠ",
            dt2: "",
            dt1: "Hananel",
            lt11: "ܚܢܢܐܝܠ"
        },
        {
            sy9: "",
            lt12: "ܒܝܡܐ",
            dt3: "",
            lt10: "ܒܐܡܐ",
            dt1: "Bima",
            sy4: "ܒܐܡܐ",
            sy8: "",
            id: "625",
            dt2: "Bema",
            sy5: "ܒܺܐܡܰܐ",
            sy7: "ܒܺܝܡܰܐ",
            sy6: "ܒܝܡܐ",
            lt11: "ܒܐܡܐ"
        },
        {
            sy5: "ܙܰܗܪܰܐ",
            sy4: "ܙܗܪܐ",
            sy9: "",
            id: "5241",
            sy6: "",
            dt1: "Zahra",
            lt10: "ܙܗܪܐ",
            dt3: "",
            dt2: "Sahre",
            sy8: "",
            lt11: "ܙܗܪܐ",
            lt12: "",
            sy7: ""
        },
        {
            lt12: "",
            sy8: "",
            dt2: "",
            lt10: "ĥash l'ĥasho",
            sy7: "",
            dt1: "Leid erleiden",
            sy5: "ܚܰܫ ܠܚܰܫܳܐ",
            id: "2754",
            lt11: "",
            dt3: "",
            sy6: "",
            sy4: "ܚܫ ܠܚܫܐ",
            sy9: ""
        },
        {
            lt11: "kbash",
            id: "6284",
            dt1: "zertreten",
            dt3: "",
            sy8: "",
            dt2: "",
            sy5: "ܕܳܫ",
            sy4: "ܕܫ",
            sy7: "ܟܒܰܫ",
            lt10: "dosh",
            sy6: "ܟܒܫ",
            lt12: "",
            sy9: ""
        },
        {
            dt2: "überreichen",
            sy6: "",
            sy5: "ܫܠܶܡ",
            sy9: "",
            lt12: "",
            sy7: "",
            dt1: "übergeben",
            lt10: "shlem",
            dt3: "",
            lt11: "",
            sy4: "ܫܠܡ",
            id: "4614",
            sy8: ""
        },
        {
            sy4: "ܛܠܝܬܐ",
            dt3: "",
            lt10: "ܛܠܝܬܐ",
            sy8: "",
            dt2: "",
            id: "4371",
            lt12: "",
            sy9: "",
            sy6: "",
            lt11: "ܛܠܝܬܐ",
            sy5: "ܛܰܠܺܝܬܰܐ",
            sy7: "",
            dt1: "Talita"
        },
        {
            sy8: "",
            sy7: "",
            dt2: "",
            sy6: "",
            id: "3071",
            dt1: "modernisieren",
            lt12: "",
            sy5: "ܕܰܪܶܢ",
            sy4: "ܕܪܢ",
            lt11: "",
            sy9: "",
            dt3: "",
            lt10: "daren"
        },
        {
            lt12: "mĥasyo",
            dt1: "Toter",
            lt10: "mitho",
            lt11: "ĉaniđo",
            sy5: "ܡܺܝܬ݂ܳܐ",
            sy7: "ܥܰܢܺܝܕܳܐ",
            sy6: "ܥܢܝܕܐ",
            sy9: "ܡܚܰܣܝܳܐ",
            id: "4534",
            dt2: "Verstorbener",
            sy4: "ܡܝܬܐ",
            sy8: "ܡܚܣܝܐ",
            dt3: ""
        },
        {
            lt12: "",
            sy4: "ܫܡܝܛܘܬܐ",
            sy5: "ܫܡܺܝܛܽܘܬ݂ܳܐ",
            lt10: "shmițutho",
            dt3: "",
            sy9: "",
            dt1: "Barfüssigkeit",
            sy7: "",
            sy6: "",
            dt2: "",
            lt11: "",
            sy8: "",
            id: "448"
        },
        {
            lt11: "qaŝomo",
            sy7: "ܩܰܨܳܡܳܐ",
            dt2: "Hellseher",
            dt1: "Wahrsager",
            dt3: "",
            sy6: "ܩܨܡܐ",
            lt10: "qoŝumo",
            lt12: "",
            sy5: "ܩܳܨܽܘܡܳܐ",
            sy4: "ܩܨܘܡܐ",
            sy9: "",
            id: "4961",
            sy8: ""
        },
        {
            lt12: "",
            dt3: "",
            lt11: "",
            sy5: "ܥܒܺܝܕܳܐ",
            lt10: "ĉbiđo",
            dt1: "gemacht",
            sy9: "",
            sy6: "",
            sy4: "ܥܒܝܕܐ",
            id: "1556",
            dt2: "geschaffen",
            sy7: "",
            sy8: ""
        },
        {
            lt10: "kuwoyo",
            sy4: "ܟܘܘܝܐ",
            lt12: "",
            sy5: "ܟܽܘܘܳܝܳܐ",
            id: "734",
            sy6: "",
            dt1: "Bügeln",
            sy7: "",
            sy9: "",
            sy8: "",
            dt2: "",
            lt11: "",
            dt3: ""
        },
        {
            lt12: "",
            sy5: "ܬܰܘܕܺܝ ܠܳܟ ܡܶܛܽܘܠ ܝܰܘܡܳܐ ܗܳܢܳܐ ܐ̱ܚܰܕܬ݂ܳܐ",
            dt2: "",
            lt10: "Taudi lokh mețul yaumo hono ĥatho",
            sy6: "",
            sy8: "",
            sy9: "",
            sy7: "",
            lt11: "",
            dt3: "",
            id: "5596",
            sy4: "ܬܘܕܝ ܠܟ ܡܛܘܠ ܝܘܡܐ ܗܢܐ ܚܬܐ",
            dt1: "Ich danke dir für diesen neuen Tag"
        },
        {
            sy6: "",
            lt12: "",
            sy7: "",
            sy4: "ܡܠܘܝܢܐ",
            sy9: "",
            dt2: "Genosse",
            lt10: "mlaoyono",
            lt11: "",
            dt1: "Gefährte",
            sy5: "ܡܠܰܘܝܳܢܳܐ",
            dt3: "",
            sy8: "",
            id: "1515"
        },
        {
            id: "5447",
            lt12: "",
            dt3: "",
            lt10: "barnosho ĥoze medem daqđomau bram aloho ĥoze bğau lebo",
            lt11: "",
            sy8: "",
            sy4: "ܒܰܪܢܳܫܳܐ ܚܳܙܶܐ ܡܶܕܶܡ ܕܰܩܕܳܡܰܘܗܝ̱ ܒܪܰܡ ܐܰܠܳܗܳܐ ܚܳܙܶܐ ܒܓ݂ܰܘ ܠܶܒܳܐ",
            sy6: "",
            dt1: "Der Mensch sieht was vor Augen ist Gott aber sieht das Herz an",
            sy9: "",
            sy5: "ܒܰܪܢܳܫܳܐ ܚܳܙܶܐ ܡܶܕܶܡ ܕܰܩܕܳܡܰܘܗܝ̱ ܒܪܰܡ ܐܰܠܳܗܳܐ ܚܳܙܶܐ ܒܓ݂ܰܘ ܠܶܒܳܐ",
            sy7: "",
            dt2: ""
        },
        {
            sy4: "ܐܬܓܥܠ",
            sy7: "",
            sy5: "ܐܶܬ݂ܓܥܶܠ",
            lt11: "",
            sy8: "",
            sy9: "",
            sy6: "",
            dt3: "",
            lt12: "",
            id: "4135",
            dt2: "ausliefern",
            dt1: "sich übergeben",
            lt10: "ethgĉel"
        },
        {
            sy7: "",
            dt2: "",
            sy4: "ܡܗܠܝܟ ܐܢܐ ܬܪܝܨܐܝܬ",
            sy5: "ܡܗܠܝܟ ܐ̱ܢܳܐ ܬܪܺܝܨܳܐܺܝܬ݂",
            id: "2121",
            sy8: "",
            dt1: "Ich laufe richtig",
            lt10: "mhalekh no triŝoith",
            dt3: "",
            lt11: "",
            sy6: "",
            sy9: "",
            lt12: ""
        },
        {
            sy4: "ܩܘܪܝܠܠܘܣ",
            sy8: "",
            lt12: "",
            lt10: "ܩܘܪܝܠܠܘܣ",
            lt11: "ܩܘܪܝܠܠܘܣ",
            sy7: "",
            dt1: "Cyrillus",
            sy5: "ܩܽܘܪܺܝܠܠܳܘܣ",
            dt2: "",
            dt3: "",
            sy9: "",
            id: "779",
            sy6: ""
        },
        {
            sy7: "",
            lt11: "ܪܡܘܬܐ",
            sy8: "",
            lt10: "ܪܡܘܬܐ",
            lt12: "",
            sy9: "",
            dt3: "",
            id: "3603",
            sy6: "",
            dt1: "Ramuta",
            dt2: "",
            sy5: "ܪܰܡܽܘܬܰܐ",
            sy4: "ܪܡܘܬܐ"
        },
        {
            sy5: "ܓܰܕܳܐ ܡܶܫܟܰܚ ܐܽܘܪܚܶܗ",
            sy6: "",
            dt3: "",
            dt1: "Das Schicksal findet seinen Weg",
            sy7: "",
            id: "822",
            dt2: "",
            lt11: "",
            lt12: "",
            lt10: "gado meshkaĥ urĥe",
            sy4: "ܓܕܐ ܡܫܟܚ ܐܘܪܚܗ",
            sy9: "",
            sy8: ""
        },
        {
            lt11: "ܒܪܨܒܘܢܝ",
            sy5: "ܒܰܪܨܰܒܽܘܢܺܝ",
            id: "473",
            sy4: "ܒܪܨܒܘܢܝ",
            lt12: "",
            dt1: "Barsabuni",
            sy7: "",
            sy9: "",
            sy8: "",
            dt2: "",
            lt10: "ܒܪܨܒܘܢܝ",
            sy6: "",
            dt3: ""
        },
        {
            sy8: "",
            lt12: "",
            sy9: "",
            dt2: "",
            dt1: "Aschfar",
            sy5: "ܐܰܫܦܰܪ",
            sy4: "ܐܫܦܪ",
            sy7: "",
            lt11: "ܐܫܦܪ",
            dt3: "",
            sy6: "",
            id: "311",
            lt10: "ܐܫܦܪ"
        },
        {
            sy8: "",
            sy7: "",
            sy6: "",
            sy4: "ܛܠܝܬܐ ܚܠܝܬܐ",
            id: "4330",
            sy9: "",
            sy5: "ܛܠܺܝܬ݂ܳܐ ܚܠܺܝܬ݂ܳܐ",
            lt11: "",
            dt1: "süßes Mädchen",
            lt12: "",
            dt3: "",
            dt2: "",
            lt10: "țlutho ĥlitho"
        },
        {
            id: "5232",
            sy8: "",
            sy6: "",
            sy9: "",
            dt3: "",
            lt12: "",
            dt1: "Zahnarzt",
            sy5: "ܐܳܣܝܳܐ ܕܥܰܪܫܳܐ",
            lt10: "osyo d`ĉarshe",
            sy7: "",
            dt2: "",
            lt11: "",
            sy4: "ܐܣܝܐ ܕܥܪܫܐ"
        },
        {
            sy4: "ܠܘܟܝܬܐ",
            id: "6117",
            lt10: "lukoyto",
            sy8: "ܣܘܒܝܢܐ",
            sy5: "ܠܽܘܟܳܝܬܳܐ",
            lt12: "subino",
            sy9: "ܣܽܘܒܺܝܢܳܐ",
            sy7: "ܪܽܘܡܚܳܐ",
            dt3: "",
            sy6: "ܪܘܡܚܐ",
            lt11: "rumĥo",
            dt1: "Harpune",
            dt2: ""
        },
        {
            sy4: "ܬܪܥܐ ܫܪܘܥܐ",
            dt1: "Schiebetür",
            dt3: "",
            sy9: "",
            sy5: "ܬܰܪܥܳܐ ܫܳܪܽܘܥܳܐ",
            lt12: "",
            lt10: "tarĉo shoruĉo",
            dt2: "",
            id: "3938",
            sy8: "",
            lt11: "tarĉo d`zĉofo",
            sy7: "ܬܰܪܥܳܐ ܕܙܥܳܦܳܐ",
            sy6: "ܬܪܥܐ ܕܙܥܦܐ"
        },
        {
            sy4: "ܣܩܘܒܠܝܐ",
            lt10: "saqubloyo",
            sy5: "ܣܰܩܽܘܒܠܳܝܳܐ",
            sy6: "ܡܬܕܠܩܒܢܐ",
            dt1: "Kontrahent",
            sy8: "",
            id: "2595",
            lt11: "methdalqbono",
            lt12: "",
            dt3: "Widersacher",
            sy7: "ܡܶܬ݂ܕܰܠܩܒܳܢܳܐ",
            dt2: "Gegner",
            sy9: ""
        },
        {
            sy8: "",
            lt11: "",
            dt3: "",
            lt12: "",
            sy9: "",
            sy4: "ܬܪܦܠܐ",
            sy7: "",
            lt10: "tarpelo",
            dt2: "",
            dt1: "stolpern",
            id: "4281",
            sy6: "",
            sy5: "ܬܰܪܦܶܠܳܐ"
        },
        {
            id: "3965",
            lt12: "",
            dt3: "",
            sy4: "ܚܘܝܐ",
            sy5: "ܚܶܘܝܳܐ",
            sy8: "",
            sy6: "ܟܘܪܦܐ",
            sy7: "ܟܽܘܪܦܳܐ",
            dt1: "Schlange",
            sy9: "",
            lt11: "kurfo",
            lt10: "ĥewyo",
            dt2: ""
        },
        {
            dt2: "",
            sy6: "ܓܠܘܝܘܬܐ",
            sy4: "ܫܘܢܝܐ",
            sy7: "ܓܰܠܘܳܝܽܘܬ݂ܳܐ",
            sy9: "",
            lt10: "shunoyo",
            lt12: "",
            id: "5693",
            sy5: "ܫܽܘܢܳܝܳܐ",
            sy8: "",
            lt11: "galwoyutho",
            dt1: "Abwanderung",
            dt3: ""
        },
        {
            lt12: "",
            dt3: "",
            sy6: "",
            dt1: "Babai",
            lt10: "ܒܒܝ",
            sy7: "",
            id: "410",
            sy8: "",
            sy5: "ܒܰܒܰܝ",
            sy4: "ܒܒܝ",
            sy9: "",
            lt11: "ܒܒܝ",
            dt2: "Babay"
        },
        {
            id: "814",
            sy7: "",
            sy5: "ܐܰܓܽܘܓܳܐ ܡܰܥܝܳܐ",
            sy6: "",
            lt12: "",
            sy4: "ܐܓܘܓܐ ܡܥܝܐ",
            dt1: "Darmkanal",
            sy9: "",
            lt10: "agugo mĉayo",
            lt11: "",
            dt2: "",
            dt3: "",
            sy8: ""
        },
        {
            lt11: "",
            lt10: "țebe ĥroye",
            sy5: "ܛܶܒ̈ܶܐ ܐ̱ܚܪ̈ܝܶܐ",
            sy4: "ܛܒ̈ܐ ܐ̱ܚܪ̈ܝܐ",
            lt12: "",
            dt3: "",
            sy9: "",
            sy6: "",
            sy7: "",
            dt1: "Letzte Nachrichten",
            sy8: "",
            id: "2777",
            dt2: ""
        },
        {
            sy9: "",
            sy6: "ܒܚܕ ܙܒܢܐ",
            sy5: "ܒܶܗ ܒܙܰܒܢܳܐ",
            dt1: "gleichzeitig",
            dt3: "",
            sy8: "",
            sy7: "ܒܚܰܕ ܙܰܒܢܳܐ",
            lt12: "",
            id: "1660",
            lt11: "bĥađ zabno",
            dt2: "",
            sy4: "ܒܗ ܒܙܒܢܐ",
            lt10: "beh b`zabno"
        },
        {
            id: "4826",
            sy9: "",
            dt1: "verzeihen",
            lt12: "",
            sy4: "ܚܣܝ",
            sy8: "",
            sy5: "ܚܰܣܺܝ",
            sy6: "ܫܒܩ",
            dt2: "vergeben",
            sy7: "ܫܒܰܩ",
            lt11: "shbaq",
            dt3: "",
            lt10: "ĥasi"
        },
        {
            lt10: "ܓܘܪܓܝܣ",
            sy9: "",
            sy8: "",
            sy5: "ܓܳܘܪܓܺܝܣ",
            id: "1684",
            sy7: "",
            dt2: "",
            lt12: "",
            lt11: "ܓܘܪܓܝܣ",
            sy4: "ܓܘܪܓܝܣ",
            dt3: "",
            sy6: "",
            dt1: "Gorgis"
        },
        {
            dt1: "bauen",
            lt12: "aĉmar",
            dt3: "",
            lt10: "bno",
            sy6: "ܫܬܣ",
            dt2: "",
            lt11: "shates",
            sy9: "ܐܰܥܡܰܪ",
            sy7: "ܫܰܬܶܣ",
            sy8: "ܐܥܡܪ",
            sy4: "ܒܢܐ",
            sy5: "ܒܢܳܐ",
            id: "509"
        },
        {
            dt1: "offenbaren",
            lt10: "gelyonoyo",
            dt3: "",
            lt11: "qriĥo",
            dt2: "offensichtlich",
            sy8: "ܩܪܝܚܐܝܬ",
            sy9: "ܩܪܺܝܚܳܐܝܺܬ݂",
            id: "3346",
            sy6: "ܩܪܝܚܐ",
            sy5: "ܓܶܠܝܳܢܳܝܳܐ",
            sy7: "ܩܪܺܝܚܳܐ",
            sy4: "ܓܠܝܢܝܐ",
            lt12: "qriĥoith"
        },
        {
            id: "2169",
            sy9: "",
            lt10: "bshem abo",
            sy5: "ܒܫܶܡ ܐܰܒܳܐ",
            lt11: "",
            sy6: "",
            sy8: "",
            dt3: "",
            sy7: "",
            dt2: "",
            lt12: "",
            sy4: "ܒܫܡ ܐܒܐ",
            dt1: "Im Namen des Vaters"
        },
        {
            lt11: "",
            lt12: "",
            sy7: "",
            dt3: "",
            sy5: "ܡܶܨܪܳܝܳܐ",
            dt2: "ägyptisch",
            dt1: "Ägypter",
            id: "149",
            sy8: "",
            sy6: "",
            sy4: "ܡܨܪܝܐ",
            lt10: "meŝroyo",
            sy9: ""
        },
        {
            dt3: "",
            sy5: "ܐܰܓܽܘܓܳܐ ܡܰܝܳܐ",
            lt10: "agugo mayo",
            sy8: "",
            sy7: "",
            sy9: "",
            dt1: "Wasserleitungsrohr",
            id: "5005",
            lt12: "",
            lt11: "",
            sy4: "ܐܓܘܓܐ ܡܝܐ",
            sy6: "",
            dt2: "Wasserkanal"
        },
        {
            sy6: "",
            sy4: "ܓܒܐ ܕܥܡܐ",
            lt11: "",
            dt3: "",
            sy9: "",
            sy7: "",
            id: "4859",
            sy5: "ܓܰܒܳܐ ܕܥܰܡܳܐ",
            dt1: "Volkspartei",
            dt2: "",
            lt12: "",
            lt10: "gabo d`ĉamo",
            sy8: ""
        },
        {
            sy6: "",
            id: "6281",
            lt11: "",
            sy9: "",
            sy5: "ܡܣܰܚܦܳܢܳܐ",
            dt3: "",
            dt1: "zertrümmend",
            lt12: "",
            sy8: "",
            dt2: "",
            sy4: "ܡܣܚܦܢܐ",
            sy7: "",
            lt10: "msaĥfono"
        },
        {
            sy8: "",
            sy6: "",
            lt11: "",
            dt1: "warum nur",
            dt3: "",
            sy5: "ܡܶܛܽܘܠ ܡܽܘܢ ܠܚܽܘܕ",
            sy7: "",
            dt2: "",
            lt12: "",
            sy9: "",
            sy4: "ܡܛܘܠ ܡܘܢ ܠܚܘܕ",
            lt10: "mețul mun lĥud",
            id: "4987"
        },
        {
            lt10: "tanuro",
            sy5: "ܬܰܢܽܘܪܳܐ",
            dt2: "Steinofen",
            dt1: "Ofen",
            id: "3344",
            dt3: "Backofen",
            lt12: "fyrmo",
            sy4: "ܬܢܘܪܐ",
            sy9: "ܦܺܝܪܡܳܐ",
            sy8: "ܦܝܪܡܐ",
            sy6: "ܦܘܪܢܐ",
            lt11: "furno",
            sy7: "ܦܽܘܪܢܳܐ"
        },
        {
            sy7: "",
            sy5: "ܝܶܫܽܘܥ ܐܺܝܬܰܘܗܝ ܐܰܠܳܗܳܐ",
            dt3: "",
            sy8: "",
            sy4: "ܝܫܘܥ ܐܝܬܘܗܝ ܐܠܗܐ",
            lt10: "yeshuĉ ithau aloho",
            dt1: "Jesus ist Gott",
            id: "5614",
            dt2: "",
            sy6: "",
            lt11: "",
            lt12: "",
            sy9: ""
        },
        {
            sy5: "ܡܠܰܒܛܳܢܳܐ",
            lt12: "",
            dt3: "",
            id: "5781",
            sy9: "",
            sy7: "ܕܳܠܽܘܚܳܐ",
            dt2: "",
            sy8: "",
            sy4: "ܡܠܒܛܢܐ",
            dt1: "Agitator",
            sy6: "ܕܠܘܚܐ",
            lt11: "doluĥo",
            lt10: "mlabțono"
        },
        {
            lt10: "ܫܡܝܪ",
            sy8: "",
            lt12: "",
            dt3: "",
            dt2: "",
            sy7: "",
            lt11: "ܫܡܝܪ",
            sy9: "",
            sy6: "",
            sy4: "ܫܡܝܪ",
            id: "4007",
            sy5: "ܫܳܡܺܝܪ",
            dt1: "Schomir"
        },
        {
            lt10: "ܚܘܠܡܢܐ",
            sy6: "ܚܘܠܡܢܐ",
            sy9: "",
            sy8: "",
            sy7: "ܚܽܘܠܡܰܢܰܐ",
            lt12: "ܚܘܠܡܢܐ",
            lt11: "ܚܘܠܡܢܐ",
            sy4: "ܚܘܠܡܢܐ",
            dt3: "",
            dt1: "Hulmono",
            dt2: "",
            id: "2067",
            sy5: "ܚܽܘܠܡܳܢܳܐ"
        },
        {
            dt1: "hinzufügen",
            lt12: "",
            sy6: "",
            lt11: "",
            sy9: "",
            dt3: "",
            id: "2016",
            lt10: "aqef",
            sy7: "",
            dt2: "",
            sy4: "ܐܩܦ",
            sy8: "",
            sy5: "ܐܰܩܶܦ"
        },
        {
            dt1: "Löffel",
            lt12: "",
            dt3: "",
            sy7: "",
            sy9: "",
            id: "2851",
            lt11: "",
            sy8: "",
            lt10: "tarwođo",
            sy4: "ܬܪܘܕܐ",
            dt2: "",
            sy6: "",
            sy5: "ܬܰܪܘܳܕܳܐ"
        },
        {
            lt10: "mafŝĥonutho",
            id: "4205",
            sy8: "",
            sy9: "",
            sy4: "ܡܦܨܚܢܘܬܐ",
            dt3: "",
            sy7: "ܡܚܰܕܝܳܢܽܘܬ݂ܳܐ",
            lt12: "",
            dt2: "Spaß",
            sy6: "ܡܚܕܝܢܘܬܐ",
            dt1: "Spass",
            sy5: "ܡܰܦܨܚܳܢܽܘܬ݂ܳܐ",
            lt11: "mĥadyonutho"
        },
        {
            dt3: "partiell",
            id: "4427",
            dt2: "teils",
            sy7: "ܡܢܳܬ݂ܳܝܳܐ",
            sy8: "",
            lt10: "mnothoith",
            dt1: "teilweise",
            sy4: "ܡܢܬܐܝܬ",
            sy6: "ܡܢܬܝܐ",
            lt12: "",
            sy5: "ܡܢܳܬ݂ܳܐܺܝܬ݂",
            lt11: "mnothoyo",
            sy9: ""
        },
        {
            dt3: "",
            sy8: "",
            id: "6329",
            lt12: "",
            dt1: "nächstes Jahr",
            sy7: "",
            sy4: "ܡܢܚܝ",
            lt10: "manĥay",
            lt11: "",
            sy6: "",
            sy9: "",
            sy5: "ܡܰܢܚܰܝ",
            dt2: "kommendes Jahr"
        },
        {
            lt12: "",
            id: "145",
            sy4: "ܐܓܝ",
            sy6: "",
            sy5: "ܐܰܓܰܝ",
            lt10: "ܐܓܝ",
            sy7: "",
            sy9: "",
            lt11: "ܐܓܝ",
            dt1: "Agai",
            dt3: "",
            sy8: "",
            dt2: ""
        },
        {
            sy6: "",
            lt12: "",
            sy7: "",
            sy9: "",
            lt11: "",
            sy5: "ܦܛܰܡ",
            dt3: "",
            sy8: "",
            sy4: "ܦܛܡ",
            dt1: "abgewöhnen",
            lt10: "fțam",
            dt2: "",
            id: "5588"
        },
        {
            id: "3217",
            lt11: "",
            lt10: "zmirto umthonoyo",
            dt1: "Nationalhymne",
            sy7: "",
            dt2: "",
            lt12: "",
            sy6: "",
            sy9: "",
            sy5: "ܙܡܺܝܪܬܳܐ ܐܽܘܡܬ݂ܳܢܳܝܳܐ",
            sy4: "ܙܡܝܪܬܐ ܐܘܡܬܢܝܐ",
            sy8: "",
            dt3: ""
        },
        {
            sy4: "ܓܓܘܝܬܐ",
            sy7: "ܙܰܢܳܝܬܳܐ",
            dt3: "",
            sy5: "ܓܰܓܘܳܝܬܳܐ",
            sy9: "ܙܳܢܽܝܬ݂ܳܐ",
            lt12: "zonitho",
            lt10: "gagwoyto",
            dt1: "Ehebrecherin",
            dt2: "",
            lt11: "zanoyto",
            sy8: "ܙܢܝܬܐ",
            id: "1008",
            sy6: "ܙܢܝܬܐ"
        },
        {
            sy5: "ܫܠܳܡܰܬ",
            dt3: "",
            dt1: "Schlomat",
            lt12: "",
            sy8: "",
            sy6: "",
            lt10: "ܫܠܡܬ",
            sy4: "ܫܠܡܬ",
            sy9: "",
            id: "3972",
            lt11: "ܫܠܡܬ",
            dt2: "",
            sy7: ""
        },
        {
            lt11: "ܓܪܝܓܘܪܝܘܣ",
            sy8: "",
            sy5: "ܓܪܶܝܓܳܘܪܺܝܳܘܣ",
            lt10: "ܓܪܝܓܘܪܝܘܣ",
            dt2: "",
            sy6: "",
            dt3: "",
            dt1: "Gregorius",
            lt12: "",
            sy9: "",
            id: "1728",
            sy4: "ܓܪܝܓܘܪܝܘܣ",
            sy7: ""
        },
        {
            sy9: "ܕܶܟ݂ܳܢܳܐ",
            sy8: "ܕܟܢܐ",
            sy5: "ܬܶܢܳܢܳܐ",
            sy7: "ܬܽܘܢܳܢܳܐ",
            dt1: "Rauch",
            dt2: "Qualm",
            sy4: "ܬܢܢܐ",
            lt12: "dekhono",
            dt3: "Dampf",
            id: "3621",
            lt11: "tunono",
            sy6: "ܬܘܢܢܐ",
            lt10: "tenono"
        },
        {
            dt3: "",
            lt12: "",
            sy9: "",
            sy6: "",
            sy4: "ܩܕܡܝܐ",
            lt11: "",
            sy5: "ܩܰܕܡܝܳܐ",
            sy8: "",
            lt10: "qađmoyo",
            sy7: "",
            dt2: "",
            id: "1176",
            dt1: "Erster"
        },
        {
            dt3: "",
            sy9: "",
            lt12: "",
            sy5: "ܚܰܝܳܐ",
            dt1: "Live",
            sy8: "",
            id: "2847",
            sy7: "ܚܰܝܬ݂ܳܐ",
            lt10: "ĥayo",
            lt11: "ĥaytho",
            sy6: "ܚܝܬܐ",
            sy4: "ܚܝܐ",
            dt2: ""
        },
        {
            lt10: "ܐܕܢܐ",
            dt3: "",
            lt11: "ܐܕܢܐ",
            sy5: "ܐܶܕܢܰܐ",
            sy8: "",
            sy7: "",
            sy4: "ܐܕܢܐ",
            lt12: "",
            dt1: "Edna",
            sy6: "",
            sy9: "",
            id: "1001",
            dt2: "Ednha"
        },
        {
            lt11: "methağrono",
            dt1: "zu vermieten",
            sy8: "",
            lt10: "l",
            id: "6314",
            sy6: "ܡܬܐܓܪܢܐ",
            dt2: "zu verpachten",
            sy5: "ܠܐܰܓ݂ܪܳܐ",
            lt12: "",
            dt3: "",
            sy7: "ܡܶܬ݂ܐܰܓ݂ܪܳܢܳܐ",
            sy9: "",
            sy4: "ܠܐܓܪܐ"
        },
        {
            sy7: "",
            lt12: "",
            sy5: "ܡܫܰܚ",
            sy9: "",
            dt2: "",
            sy8: "",
            lt10: "mshaĥ",
            dt3: "",
            sy4: "ܡܫܚ",
            id: "5978",
            lt11: "",
            dt1: "anschmieren",
            sy6: ""
        },
        {
            lt12: "",
            id: "pNPl2LtBHh51SUeqlyii",
            lt11: "",
            sy5: "ܫܠܳܡܐ، ܐܳܢܐ ܐܷܫܡܝ ܐܰܦܪܶܡ ܝܐ",
            sy9: "",
            sy4: "ܫܠܡܐ، ܐܢܐ ܐܷܫܡܝ ܐܦܪܡ ܝܐ",
            dt2: "",
            lt10: "Šlomo, ono ëšmi Afrem yo",
            sy8: "",
            dt1: "Hallo, ich heiße Afrem",
            "date": {
                "seconds": 1587245153,
                "nanoseconds": 682000000
            },
            sy7: "",
            sy6: "",
            dt3: ""
        },
        {
            id: "6406",
            sy8: "",
            lt10: "shelolo",
            lt11: "",
            dt2: "",
            dt3: "",
            sy7: "",
            sy9: "",
            sy5: "ܫܶܠܳܠܳܐ",
            sy4: "ܫܠܠܐ",
            dt1: "Einzelheit",
            lt12: "",
            sy6: ""
        },
        {
            lt12: "",
            sy5: "ܐܰܠܦܰܝܳܐ ܕܬܰܘܕܝܳܬ݂ܳܐ ܠܟܽܘܠ ܡܶܕܶܡ",
            sy9: "",
            lt10: "alfayo d'taudyotho lkul medem",
            id: "5436",
            lt11: "",
            sy7: "",
            sy4: "ܐܰܠܦܰܝܳܐ ܕܬܰܘܕܝܳܬ݂ܳܐ ܠܟܽܘܠ ܡܶܕܶܡ",
            dt2: "",
            sy6: "",
            dt1: "Tausend Dank für alles",
            sy8: "",
            dt3: ""
        },
        {
            sy8: "",
            lt12: "",
            sy7: "",
            lt11: "",
            sy6: "",
            lt10: "mkafronitho",
            sy5: "ܡܟܰܦܪܳܢܺܝܬ݂ܳܐ",
            sy4: "ܡܟܦܪܢܝܬܐ",
            sy9: "",
            dt2: "",
            id: "3553",
            dt1: "Putzfrau",
            dt3: ""
        },
        {
            dt1: "Alarm",
            sy9: "ܢܣܳܝܳܐ",
            sy4: "ܫܘܪܙܥܐ",
            id: "5815",
            dt2: "",
            sy6: "ܢܝܫ ܩܢܛܐ",
            sy5: "ܫܽܘܪܙܳܥܳܐ",
            dt3: "",
            sy8: "ܢܣܝܐ",
            lt11: "nish qențo",
            sy7: "ܢܺܝܫ ܩܶܢܛܳܐ",
            lt12: "nsoyo",
            lt10: "shurzoĉo"
        },
        {
            lt10: "gawo",
            sy9: "",
            sy6: "",
            lt11: "",
            id: "507",
            dt1: "Bauch",
            sy7: "",
            dt3: "",
            sy4: "ܓܘܐ",
            lt12: "",
            sy8: "",
            dt2: "",
            sy5: "ܓܰܘܳܐ"
        },
        {
            sy4: "ܙܕܩܐ ܕܢܫܐ",
            dt3: "",
            sy6: "",
            dt2: "",
            lt10: "zedqe d`neshe",
            lt12: "",
            dt1: "Frauenrechte",
            id: "1374",
            sy8: "",
            lt11: "",
            sy5: "ܙܕܩ̈ܐ ܕܢܶܫ̈ܶܐ",
            sy9: "",
            sy7: ""
        },
        {
            sy9: "",
            id: "1506",
            sy7: "",
            dt1: "Gedächtnis",
            sy4: "ܕܘܟܪܢܐ",
            sy6: "",
            sy8: "",
            dt3: "",
            lt11: "",
            lt12: "",
            sy5: "ܕܽܘܟ݂ܪܳܢܳܐ",
            dt2: "",
            lt10: "dukhrono"
        },
        {
            dt2: "",
            sy9: "ܫܒܳܐ",
            dt1: "abschleppen",
            sy7: "ܓܠܰܫ",
            id: "5625",
            lt12: "shbo",
            sy6: "ܓܠܫ",
            dt3: "",
            sy8: "ܫܒܐ",
            lt10: "gardesh",
            sy4: "ܓܪܕܫ",
            lt11: "glash",
            sy5: "ܓܰܪܕܶܫ"
        },
        {
            sy6: "",
            lt10: "ĥubo d`barnosho",
            dt3: "",
            sy7: "",
            lt11: "",
            id: "3164",
            lt12: "",
            sy9: "",
            dt1: "Nächstenliebe",
            sy4: "ܚܘܒܐ ܕܒܪܢܫܐ",
            sy5: "ܚܽܘܒܳܐ ܕܒܰܪܢܳܫܳܐ",
            dt2: "",
            sy8: ""
        },
        {
            sy8: "",
            dt2: "",
            sy6: "",
            dt1: "Bücherwurm",
            sy4: "ܬܘܠܥܐ ܕܟܬܒܐ",
            sy9: "",
            id: "728",
            lt10: "taulĉo da`kthobe",
            sy5: "ܬܰܘܠܥܳܐ ܕܟܬ݂ܳܒ̈ܶܐ",
            lt12: "",
            sy7: "",
            lt11: "",
            dt3: ""
        },
        {
            sy8: "",
            sy5: "ܩܰܪܩܰܦܬܳܐ",
            lt11: "",
            sy7: "",
            sy9: "",
            dt2: "Schädelkopf",
            sy4: "ܩܪܩܦܬܐ",
            lt10: "qarqafto",
            dt3: "",
            dt1: "Schädel",
            sy6: "",
            lt12: "",
            id: "3865"
        },
        {
            sy6: "",
            sy7: "",
            dt1: "an den Hafen bringen",
            sy8: "",
            lt11: "",
            sy9: "",
            dt2: "",
            lt12: "",
            id: "5894",
            lt10: "ayti lalmino",
            dt3: "",
            sy5: "ܐܰܝܬܺܝ ܠܰܠܡܺܐܢܳܐ",
            sy4: "ܐܝܬܝ ܠܠܡܐܢܐ"
        },
        {
            sy6: "",
            sy4: "ܦܘܪܢܣ ܒܝܬܐ",
            lt12: "",
            lt10: "furnos bayto",
            sy7: "",
            sy5: "ܦܽܘܪܢܳܣ ܒܰܝܬܳܐ",
            dt1: "Haushalt",
            dt2: "",
            dt3: "",
            sy9: "",
            lt11: "",
            id: "1901",
            sy8: ""
        },
        {
            dt1: "Nationalist",
            sy6: "",
            lt11: "",
            lt10: "umthonoyo",
            sy7: "",
            sy8: "",
            id: "3219",
            sy5: "ܐܽܘܡܬ݂ܳܢܳܝܳܐ",
            sy4: "ܐܘܡܬܢܝܐ",
            dt2: "Patriot",
            dt3: "nationalistisch",
            sy9: "",
            lt12: ""
        },
        {
            lt12: "",
            sy5: "ܢܳܒܓ݂ܳܐ",
            sy6: "",
            sy8: "",
            sy7: "",
            sy4: "ܢܒܓܐ",
            dt1: "Enkel",
            sy9: "",
            lt10: "nobğo",
            dt2: "Enkelkind",
            id: "1107",
            dt3: "",
            lt11: ""
        },
        {
            sy5: "ܡܶܨܥܰܬ݂ ܐܽܘܪܚܳܐ",
            id: "6368",
            sy9: "",
            sy6: "ܐܘܪܚܐ ܡܨܥܝܬܐ",
            dt1: "Mittelweg",
            lt12: "",
            lt10: "meŝĉath urĥo",
            sy7: "ܐܽܘܪܚܳܐ ܡܶܨܥܳܝܬܳܐ",
            dt3: "",
            dt2: "",
            sy4: "ܡܨܥܬ ܐܘܪܚܐ",
            lt11: "urĥo meŝĉoyto",
            sy8: ""
        },
        {
            sy9: "",
            lt11: "haymonutho",
            dt3: "",
            lt10: "tauditho",
            sy8: "",
            sy5: "ܬܰܘܕܺܝܬ݂ܳܐ",
            id: "3685",
            dt1: "Religion",
            sy4: "ܬܘܕܝܬܐ",
            sy7: "ܗܰܝܡܳܢܽܘܬ݂ܳܐ",
            dt2: "Konfession",
            lt12: "",
            sy6: "ܗܝܡܢܘܬܐ"
        },
        {
            sy8: "",
            dt2: "sich beeilen",
            sy4: "ܡܣܪܗܒ",
            lt10: "msarheb",
            sy9: "",
            id: "520",
            sy6: "ܣܪܗܒ",
            dt3: "",
            lt11: "sarheb",
            dt1: "beeilen",
            sy5: "ܡܣܰܪܗܶܒ",
            sy7: "ܣܰܪܗܶܒ",
            lt12: ""
        },
        {
            sy5: "ܙܰܒܢܳܐ ܕܬܰܫܕܰܪܬܳܐ",
            lt10: "zabno d`tashdarto",
            lt12: "",
            id: "4117",
            sy8: "",
            dt2: "",
            sy6: "",
            sy4: "ܙܒܢܐ ܕܬܫܕܪܬܐ",
            sy9: "",
            lt11: "",
            dt3: "",
            dt1: "Sendezeit",
            sy7: ""
        },
        {
            sy5: "ܪܳܡ ܢܰܦܫܳܐ",
            dt1: "Hochmut",
            lt12: "",
            sy7: "",
            dt3: "",
            id: "2034",
            sy6: "",
            lt10: "rom nafsho",
            sy8: "",
            sy9: "",
            sy4: "ܪܡ ܢܦܫܐ",
            dt2: "hochmütig",
            lt11: ""
        },
        {
            dt1: "Bombenflugzeug",
            sy7: "",
            lt12: "",
            sy4: "ܛܝܣܬܐ ܕܡܦܩܥܬܐ",
            dt3: "",
            lt11: "",
            sy6: "",
            id: "677",
            lt10: "țayosto da`mfaqĉotho",
            dt2: "Bombenflieger",
            sy5: "ܛܰܝܳܣܬܳܐ ܕܰܡܦܰܩ̈ܥܳܬ݂ܳܐ",
            sy9: "",
            sy8: ""
        },
        {
            lt11: "",
            id: "5063",
            sy5: "ܦܢܺܝ ܠܝܰܡܺܝܢܳܐ",
            sy4: "ܦܢܝ ܠܝܡܝܢܐ",
            sy6: "",
            sy7: "",
            dt2: "",
            sy8: "",
            dt1: "wende dich nach rechts",
            sy9: "",
            lt12: "",
            lt10: "fni l`yamino",
            dt3: ""
        },
        {
            sy7: "",
            sy8: "",
            sy5: "ܥܰܡܳܘܢܝܰܐ",
            sy4: "ܥܡܘܢܝܐ",
            lt12: "",
            dt3: "",
            lt10: "ܥܡܘܢܝܐ",
            lt11: "ܥܡܘܢܝܐ",
            dt2: "",
            dt1: "Ammonja",
            sy6: "",
            id: "217",
            sy9: ""
        },
        {
            dt2: "",
            dt3: "",
            lt12: "",
            sy6: "",
            sy9: "",
            lt10: "mĉasrono",
            id: "6272",
            sy5: "ܡܥܰܣܪܳܢܳܐ",
            sy8: "",
            sy4: "ܡܥܣܪܢܐ",
            dt1: "Zehntenabnehmer",
            sy7: "",
            lt11: ""
        },
        {
            id: "5103",
            lt11: "toqel",
            sy6: "ܬܩܠ",
            sy9: "",
            sy8: "",
            dt2: "",
            lt12: "",
            dt3: "",
            lt10: "tqal",
            sy4: "ܬܩܠ",
            sy5: "ܬܩܰܠ",
            sy7: "ܬܳܩܶܠ",
            dt1: "wiegen"
        },
        {
            sy5: "ܡܝܰܒܶܫ ܣܰܥܪܳܐ",
            dt3: "",
            id: "1785",
            dt2: "Fön",
            sy6: "ܡܐܟܢܐ ܕܡܝܒܫܐ ܣܥܪܐ",
            sy7: "ܡܰܐܟܺܢܰܐ ܕܰܡܝܰܒܫܳܐ ܣܰܥܪܳܐ",
            lt11: "makina da`myabsho saĉro",
            sy8: "",
            lt12: "",
            sy4: "ܡܝܒܫ ܣܥܪܐ",
            dt1: "Haartrockner",
            sy9: "",
            lt10: "myabesh saĉro"
        },
        {
            sy5: "ܦܰܠܶܣܛܺܝܢܺܝ",
            lt10: "falestini",
            sy7: "ܐܰܬ݂ܪܳܐ ܕܦܠܶܫ̈ܬܳܝܶܐ",
            dt2: "",
            dt3: "",
            dt1: "Palästina",
            id: "3411",
            sy6: "ܐܬܪܐ ܕܦܠܫܬܝܐ",
            sy9: "",
            sy8: "",
            lt12: "",
            sy4: "ܦܠܣܛܝܢܝ",
            lt11: "athro da`fleshțoye"
        },
        {
            lt12: "",
            sy5: "ܐܶܓܰܪܬ݂ܳܐ ܕܚܽܘܒܳܐ",
            id: "2814",
            dt2: "",
            sy9: "",
            sy4: "ܐܓܪܬܐ ܕܚܘܒܐ",
            lt11: "",
            sy6: "",
            dt1: "Liebesbrief",
            sy8: "",
            dt3: "",
            lt10: "egartho d`ĥubo",
            sy7: ""
        },
        {
            sy4: "ܙܘܥܐ ܕܥܡܐ",
            dt1: "Volksbewegung",
            lt11: "",
            lt10: "zauĉo d`ĉamo",
            id: "4853",
            sy7: "",
            sy8: "",
            dt2: "",
            sy5: "ܙܰܘܥܳܐ ܕܥܰܡܳܐ",
            sy9: "",
            sy6: "",
            lt12: "",
            dt3: ""
        },
        {
            dt3: "",
            sy7: "",
            lt12: "",
            sy5: "ܪܰܕܳܝܬܳܐ",
            id: "399",
            sy4: "ܪܕܝܬܐ",
            sy9: "",
            sy6: "",
            lt10: "rađoyto",
            dt2: "",
            sy8: "",
            dt1: "Auto",
            lt11: ""
        },
        {
            sy8: "",
            dt3: "",
            lt12: "",
            dt2: "",
            id: "3352",
            sy5: "ܐܰܕܠܶܩ ܒܰܗܪܳܐ",
            lt10: "adleq bahro",
            sy4: "ܐܕܠܩ ܒܗܪܐ",
            sy7: "ܐܰܕܠܶܩ ܠܢܽܘܗܪܳܐ",
            sy6: "ܐܕܠܩ ܠܢܘܗܪܐ",
            sy9: "",
            lt11: "adleq l`nuhro",
            dt1: "öffne das Licht"
        },
        {
            id: "586",
            lt10: "ܒܝܬܙܒܕܝ",
            sy7: "",
            sy5: "ܒܶܝܬܙܰܒܕܰܝ",
            lt11: "ܒܝܬܙܒܕܝ",
            sy4: "ܒܝܬܙܒܕܝ",
            sy6: "",
            sy8: "",
            sy9: "",
            dt3: "",
            dt1: "Bethzabdai",
            lt12: "",
            dt2: ""
        },
        {
            id: "5388",
            sy4: "ܥܣܪܝܢܝܐ",
            sy6: "",
            lt10: "ĉesrinoyo",
            dt2: "",
            sy5: "ܥܶܣܪܺܝܢܳܝܳܐ",
            sy7: "",
            dt1: "Zwanzigster",
            lt11: "",
            sy8: "",
            dt3: "",
            lt12: "",
            sy9: ""
        },
        {
            sy9: "",
            id: "92",
            sy6: "",
            lt11: "ܐܒܝܠ",
            lt10: "ܐܒܝܠ",
            dt2: "",
            sy5: "ܐܰܒܺܝܠ",
            dt1: "Abil",
            dt3: "",
            sy8: "",
            sy7: "",
            sy4: "ܐܒܝܠ",
            lt12: ""
        },
        {
            lt10: "ܚܓܝ",
            id: "1822",
            lt12: "ܚܐܓܝ",
            sy9: "",
            sy4: "ܚܓܝ",
            dt1: "Haggai",
            sy6: "ܚܐܓܝ",
            sy7: "ܚܰܐܓܰܝ",
            lt11: "ܚܓܝ",
            sy5: "ܚܰܓܰܝ",
            sy8: "",
            dt3: "",
            dt2: "Haggay"
        },
        {
            id: "1202",
            dt3: "",
            sy4: "ܐܘܪܝܦܝ",
            lt10: "urifi",
            lt11: "",
            sy9: "",
            dt1: "Europa",
            sy6: "",
            sy7: "",
            dt2: "",
            lt12: "",
            sy8: "",
            sy5: "ܐܽܘܪܺܝܦܺܝ"
        },
        {
            lt10: "farokho",
            lt12: "",
            dt1: "Masseur",
            sy6: "",
            lt11: "",
            sy4: "ܦܪܟܐ",
            sy5: "ܦܰܪܳܟ݂ܳܐ",
            sy8: "",
            dt3: "",
            sy7: "",
            sy9: "",
            id: "2962",
            dt2: ""
        },
        {
            sy5: "ܡܰܩܒܳܐ",
            id: "674",
            lt10: "maqbo",
            dt2: "Bohrmaschine",
            sy8: "",
            sy9: "",
            lt11: "maqabtho",
            dt3: "",
            dt1: "Bohrer",
            lt12: "",
            sy7: "ܡܰܩܰܒܬ݂ܳܐ",
            sy6: "ܡܩܒܬܐ",
            sy4: "ܡܩܒܐ"
        },
        {
            id: "1352",
            sy7: "ܦܳܪܽܘܩܳܐ",
            dt2: "Farruq",
            sy8: "",
            lt11: "ܦܪܘܩ",
            sy9: "",
            dt1: "Foruq",
            sy4: "ܦܪܘܩ",
            sy5: "ܦܳܪܘܽܩ",
            sy6: "ܦܪܘܩܐ",
            dt3: "",
            lt10: "ܦܪܘܩ",
            lt12: "ܦܪܘܩܐ"
        },
        {
            dt2: "",
            lt10: "ĥerqo",
            lt12: "",
            dt3: "",
            sy9: "",
            sy6: "",
            dt1: "Krawatte",
            sy7: "",
            id: "2639",
            lt11: "",
            sy4: "ܚܪܩܐ",
            sy8: "",
            sy5: "ܚܶܪܩܳܐ"
        },
        {
            sy8: "",
            sy4: "ܕܝܘܣܩܘܪܘܣ",
            lt11: "ܕܝܘܣܩܘܪܘܣ",
            sy9: "",
            lt12: "",
            sy5: "ܕܺܝܽܘܣܩܳܘܪܳܘܣ",
            lt10: "ܕܝܘܣܩܘܪܘܣ",
            dt2: "Dioskorus",
            dt3: "",
            dt1: "Dioskouros",
            sy6: "",
            sy7: "",
            id: "899"
        },
        {
            sy5: "ܣܳܥܽܘܪܺܘܬ݂ ܒܰܝܬܳܐ",
            lt12: "",
            lt10: "soĉuruth bayto",
            sy4: "ܣܥܘܪܘܬ ܒܝܬܐ",
            sy6: "",
            dt3: "",
            id: "1897",
            lt11: "",
            dt2: "",
            sy7: "",
            sy8: "",
            sy9: "",
            dt1: "Hausbesuch"
        },
        {
            lt11: "",
            sy4: "ܕܢܚ ܫܡܫܐ",
            dt1: "Sonnenaufgang",
            sy8: "",
            sy7: "",
            dt3: "",
            lt12: "",
            sy6: "",
            sy9: "",
            lt10: "dnaĥ shemsho",
            sy5: "ܕܢܰܚ ܫܶܡܫܶܐ",
            dt2: "",
            id: "4194"
        },
        {
            sy8: "",
            sy9: "",
            sy7: "",
            sy5: "ܒܙܺܝܙܽܘܬ݂ܳܐ",
            lt10: "bzizutho",
            sy4: "ܒܙܝܙܘܬܐ",
            lt11: "",
            lt12: "",
            dt3: "",
            id: "6137",
            dt1: "Raubzug",
            dt2: "",
            sy6: ""
        },
        {
            lt11: "",
            dt1: "analog",
            sy9: "",
            lt12: "",
            sy8: "",
            lt10: "msabhoith",
            sy7: "",
            dt3: "",
            id: "5902",
            sy4: "ܡܣܒܗܐܝܬ",
            dt2: "",
            sy6: "",
            sy5: "ܡܣܰܒܗܳܐܺܝܬ݂"
        },
        {
            lt11: "",
            dt2: "",
            sy7: "",
            dt3: "",
            sy6: "",
            sy9: "",
            dt1: "An einem Tag geht Afrem einkaufen. Er trifft Saro und Aday.",
            sy5: "ܒܚܰܐ ܝܰܘܡܐ ܐܘ ܐܰܦܪܶܡ ܟܳܢܳܦܰܩ ܠܝ ܫܘܩܐ. ܟܳܠܳܩܶܐ ܒܝ ܣܰܪܐ ܘܒܘ ܐܰܕܰܝ.",
            lt12: "",
            lt10: "B ḥa yawmo u Afrem konofaq li šuqo. Koloqe bi Saro w u Aday.",
            sy8: "",
            sy4: "ܒܚܐ ܝܘܡܐ ܐܘ ܐܦܪܡ ܟܢܦܩ ܠܝ ܫܘܩܐ. ܟܠܩܐ ܒܝ ܣܪܐ ܘܒܘ ܐܕܝ.",
            "date": {
                "seconds": 1587235225,
                "nanoseconds": 310000000
            },
            id: "pnJhLt6yvlqGzr7erCeM"
        },
        {
            sy6: "",
            dt2: "Pfad",
            sy8: "",
            lt10: "shbilo",
            dt3: "",
            dt1: "Spur",
            sy4: "ܫܒܝܠܐ",
            lt11: "",
            id: "4227",
            sy7: "",
            sy5: "ܫܒܺܝܠܳܐ",
            sy9: "",
            lt12: ""
        },
        {
            sy4: "ܐܒܝܫܘܥ",
            lt10: "ܐܒܝܫܘܥ",
            sy9: "",
            sy8: "",
            id: "98",
            lt12: "",
            dt2: "",
            sy5: "ܐܳܒܝܶܫܽܘܥ",
            sy6: "",
            dt1: "Abjeschu",
            lt11: "ܐܒܝܫܘܥ",
            sy7: "",
            dt3: ""
        },
        {
            dt2: "",
            dt1: "Türke",
            sy8: "",
            dt3: "",
            sy6: "ܛܘܪܟܝܐ",
            lt11: "țurkoyo",
            sy5: "ܛܽܘܪܩܳܝܳܐ",
            id: "4599",
            lt12: "",
            sy4: "ܛܘܪܩܝܐ",
            lt10: "țurqoyo",
            sy9: "",
            sy7: "ܛܽܘܪܟܳܝܳܐ"
        },
        {
            sy5: "ܚܺܝܪܽܘܬ݂ܳܐ",
            dt2: "",
            lt11: "",
            lt10: "ĥirutho",
            sy8: "",
            sy7: "",
            dt1: "Freiheit",
            id: "1381",
            sy9: "",
            sy6: "",
            sy4: "ܚܝܪܘܬܐ",
            dt3: "",
            lt12: ""
        },
        {
            sy4: "ܬܘܕܝ ܠܟ ܡܢ ܟܠܗ ܠܒܝ",
            sy5: "ܬܰܘܕܺܝ ܠܳܟ݂ ܡܶܢ ܟܽܠܶܗ ܠܶܒܝ̱",
            dt3: "",
            lt10: "taudi lokh men kule leb",
            sy7: "",
            dt1: "ich danke dir vom ganzen Herzen",
            lt11: "",
            id: "2106",
            lt12: "",
            dt2: "",
            sy8: "",
            sy9: "",
            sy6: ""
        },
        {
            sy5: "ܚܰܛܳܝܳܐ",
            dt2: "Sündiger",
            lt11: "ĥayobo",
            sy7: "ܚܰܝܳܒܳܐ",
            sy9: "",
            sy8: "",
            id: "4314",
            lt12: "",
            sy4: "ܚܛܝܐ",
            sy6: "ܚܝܒܐ",
            dt1: "Sünder",
            dt3: "",
            lt10: "ĥațoyo"
        },
        {
            lt11: "",
            lt12: "",
            sy7: "",
            lt10: "maĥrmonutho",
            id: "1214",
            sy6: "",
            sy9: "",
            sy5: "ܡܰܚܪܡܳܢܽܘܬ݂ܳܐ",
            sy4: "ܡܚܪܡܢܘܬܐ",
            sy8: "",
            dt3: "Verdammung",
            dt2: "Verbannung",
            dt1: "Exkommunikation"
        },
        {
            dt1: "Hanno",
            sy8: "",
            id: "1866",
            sy6: "",
            sy4: "ܚܢܢܐ",
            sy7: "",
            sy9: "",
            dt2: "",
            lt12: "",
            sy5: "ܚܰܢܢܳܐ",
            lt11: "ܚܢܢܐ",
            lt10: "ܚܢܢܐ",
            dt3: ""
        },
        {
            sy5: "ܡܶܟܺܝܠ ܠܳܐ ܬܽܘܒ ܬܶܫܒܽܘܩܰܝܢ ܣܳܟ݂",
            lt12: "",
            dt1: "Lass mich nie mehr los",
            sy6: "",
            lt10: "mekil lo tub teshbuqayn sokh",
            sy4: "ܡܟܝܠ ܠܐ ܬܘܒ ܬܫܒܘܩܝܢ ܣܟ",
            sy9: "",
            id: "2714",
            lt11: "",
            dt3: "",
            sy8: "",
            dt2: "",
            sy7: ""
        },
        {
            dt1: "Vogel",
            sy5: "ܨܶܦܪܳܐ",
            lt11: "",
            dt2: "",
            sy6: "",
            dt3: "",
            sy4: "ܨܦܪܐ",
            sy7: "",
            lt12: "",
            id: "4842",
            sy8: "",
            sy9: "",
            lt10: "ŝefro"
        },
        {
            sy6: "",
            lt10: "sheğlolo",
            sy7: "",
            sy5: "ܫܶܓ݂ܠܳܠܳܐ",
            dt3: "",
            id: "1816",
            sy9: "",
            lt11: "",
            dt2: "",
            sy8: "",
            sy4: "ܫܓܠܠܐ",
            dt1: "Hafendamm",
            lt12: ""
        },
        {
            sy7: "",
            sy8: "",
            lt10: "ܙܗܝܘܬܐ",
            dt2: "",
            lt11: "ܙܗܝܘܬܐ",
            sy6: "",
            sy9: "",
            sy4: "ܙܗܝܘܬܐ",
            dt1: "Zahyuta",
            lt12: "",
            dt3: "",
            sy5: "ܙܰܗܝܽܘܬܰܐ",
            id: "5245"
        },
        {
            dt2: "",
            lt10: "arĉo d`nafțo",
            sy4: "ܐܪܥܐ ܕܢܦܛܐ",
            dt1: "Ölfeld",
            dt3: "",
            sy5: "ܐܰܪܥܳܐ ܕܢܰܦܛܳܐ",
            lt12: "",
            id: "3368",
            sy6: "",
            sy7: "",
            sy9: "",
            lt11: "",
            sy8: ""
        },
        {
            dt3: "",
            sy7: "ܫܺܝܡ",
            dt1: "Sam",
            sy9: "",
            id: "3820",
            lt10: "ܣܡ",
            sy5: "ܫܶܡ",
            lt11: "ܣܡ",
            lt12: "ܫܝܡ",
            sy8: "",
            sy6: "ܫܝܢ",
            dt2: "",
            sy4: "ܣܡ"
        },
        {
            lt11: "shufro",
            sy4: "ܫܦܝܪܘܬܐ",
            lt10: "shafirutho",
            lt12: "",
            sy6: "ܫܘܦܪܐ",
            dt3: "",
            id: "4017",
            sy7: "ܫܽܘܦܪܳܐ",
            dt1: "Schönheit",
            sy8: "",
            sy5: "ܫܰܦܺܝܪܽܘܬ݂ܳܐ",
            dt2: "",
            sy9: ""
        },
        {
            dt2: "",
            dt1: "quadrieren",
            sy6: "",
            id: "3568",
            lt11: "",
            sy8: "",
            lt12: "",
            dt3: "",
            sy4: "ܪܒܥ",
            sy5: "ܪܰܒܰܥ",
            sy7: "",
            sy9: "",
            lt10: "rabaĉ"
        },
        {
            lt12: "ܚܐܢܐ",
            lt10: "ܚܢܐ",
            dt3: "",
            sy5: "ܚܰܢܰܐ",
            sy7: "ܚܰܐܢܰܐ",
            sy8: "",
            id: "1841",
            sy9: "",
            sy6: "ܚܐܢܐ",
            sy4: "ܚܢܐ",
            lt11: "ܚܢܐ",
            dt2: "",
            dt1: "Hana"
        },
        {
            sy9: "",
            sy5: "ܣܒܰܪܬ݂ܳܐ",
            dt1: "Botschaft",
            dt3: "",
            dt2: "",
            sy7: "",
            id: "685",
            sy8: "",
            lt10: "sbartho",
            sy6: "",
            sy4: "ܣܒܪܬܐ",
            lt12: "",
            lt11: ""
        },
        {
            sy7: "",
            lt11: "",
            sy9: "",
            sy4: "ܢܚܝܪܐ",
            sy8: "",
            dt1: "Nase",
            sy6: "",
            lt10: "nĥiro",
            dt3: "",
            lt12: "",
            id: "3206",
            dt2: "",
            sy5: "ܢܚܺܝܪܳܐ"
        },
        {
            sy4: "ܚܕ ܒܫܒܐ",
            dt1: "Sonntag",
            dt2: "",
            lt10: "ĥađ b`shabo",
            sy5: "ܚܰܕ ܒܫܰܒܳܐ",
            sy8: "",
            dt3: "",
            sy6: "",
            lt11: "",
            sy7: "",
            lt12: "",
            sy9: "",
            id: "4198"
        },
        {
            dt2: "",
            sy4: "ܟܠܫܐ",
            id: "2392",
            dt3: "",
            sy9: "",
            lt11: "",
            sy7: "",
            dt1: "Kalk",
            sy6: "",
            lt10: "kelsho",
            sy5: "ܟܶܠܫܳܐ",
            sy8: "",
            lt12: ""
        },
        {
            sy5: "ܓܰܢܳܒܳܐ ܕܫܽܘܩܳܐ",
            lt12: "",
            lt10: "ganobo d`shuqo",
            sy6: "",
            dt1: "Straßendieb",
            lt11: "",
            dt2: "",
            sy7: "",
            dt3: "",
            sy9: "",
            id: "4291",
            sy4: "ܓܢܒܐ ܕܫܘܩܐ",
            sy8: ""
        },
        {
            sy8: "",
            sy7: "ܡܰܣܬܝܳܢܳܐ",
            sy4: "ܬܝܒܐ",
            dt2: "Abtrünniger",
            dt3: "Ketzer",
            lt11: "mastyono",
            dt1: "Apostat",
            sy6: "ܡܣܬܝܢܐ",
            lt10: "tayobo",
            sy5: "ܬܰܝܳܒܳܐ",
            sy9: "",
            id: "6012",
            lt12: ""
        },
        {
            dt1: "sehen",
            lt10: "ĥzo",
            sy5: "ܚܙܳܐ",
            id: "4094",
            lt12: "ĥoyar",
            sy8: "ܚܐܪ",
            sy9: "ܚܳܐܰܪ",
            sy4: "ܚܙܐ",
            sy7: "ܚܳܙܶܐ",
            dt2: "gucken",
            sy6: "ܚܙܐ",
            lt11: "ĥoze",
            dt3: ""
        },
        {
            sy4: "ܡܒܗܘܬܐ",
            sy9: "",
            sy7: "",
            dt1: "Original",
            id: "3389",
            sy6: "",
            dt2: "",
            dt3: "",
            lt11: "",
            sy5: "ܡܰܒܗܽܘܬ݂ܳܐ",
            sy8: "",
            lt12: "",
            lt10: "mabhutho"
        },
        {
            sy6: "",
            sy7: "",
            sy9: "",
            lt10: "țukos helkho",
            sy8: "",
            dt2: "",
            sy4: "ܛܘܟܣ ܗܠܟܐ",
            sy5: "ܛܽܘܟܳܣ ܗܶܠܟ݂ܳܐ",
            dt3: "",
            dt1: "Marschordnung",
            lt12: "",
            lt11: "",
            id: "2945"
        },
        {
            dt2: "",
            lt12: "",
            sy6: "",
            sy4: "ܦܢܝ ܠܣܡܠܐ",
            sy9: "",
            dt3: "",
            id: "5062",
            sy5: "ܦܢܺܝ ܠܣܶܡܳܠܳܐ",
            lt10: "fni l`semolo",
            dt1: "wende dich nach links",
            sy8: "",
            sy7: "",
            lt11: ""
        },
        {
            sy4: "ܚܠܒ",
            sy8: "",
            sy9: "",
            sy7: "",
            sy5: "ܚܰܠܶܒ",
            lt10: "ĥaleb",
            dt2: "Haleb",
            lt11: "",
            lt12: "",
            dt3: "",
            sy6: "",
            id: "176",
            dt1: "Aleppo"
        },
        {
            dt3: "",
            sy7: "",
            lt12: "",
            sy4: "ܟܡܘܪܓܘ",
            sy5: "ܟܳܡܽܘܪܓܽܘ",
            lt10: "ܟܡܘܪܓܘ",
            id: "2575",
            sy8: "",
            lt11: "ܟܡܘܪܓܘ",
            dt2: "",
            sy6: "",
            sy9: "",
            dt1: "Komurcu"
        },
        {
            dt3: "",
            sy7: "",
            dt1: "Wäscheleine",
            sy5: "ܚܽܘܛܳܐ ܕܰܬܠܳܝ ܠܒܽܘ̈ܫܶܐ",
            lt10: "ĥuțo da`tloy lbushe",
            lt12: "",
            sy9: "",
            id: "4995",
            sy8: "",
            lt11: "",
            dt2: "",
            sy6: "",
            sy4: "ܚܘܛܐ ܕܬܠܝ ܠܒܘܫܐ"
        },
        {
            sy6: "",
            lt12: "",
            sy7: "",
            sy9: "",
            lt11: "",
            sy5: "ܢܺܝܫܳܐ ܐ̱ܚܪܳܝܳܐ",
            id: "1105",
            dt2: "",
            lt10: "nisho ĥaroyo",
            dt1: "Endziel",
            sy4: "ܢܝܫܐ ܐܚܪܝܐ",
            sy8: "",
            dt3: ""
        },
        {
            sy8: "",
            lt12: "",
            sy6: "",
            id: "4146",
            lt11: "",
            dt2: "",
            dt3: "",
            sy9: "",
            sy5: "ܟܳܬܽܘܥܢܳܐ ܓ݂ܰܡܳܐ",
            lt10: "kotuĉno ğamo",
            sy7: "",
            sy4: "ܟܬܘܥܢܐ ܓܡܐ",
            dt1: "sie macht sich Sorgen"
        },
        {
            sy6: "ܕܘܫܟ",
            dt3: "",
            lt12: "",
            sy7: "ܕܰܘܫܰܟ",
            lt10: "teshwitho",
            dt2: "",
            id: "2971",
            sy5: "ܬܶܫܘܺܝܬ݂ܳܐ",
            lt11: "daushak",
            dt1: "Matratze",
            sy9: "",
            sy4: "ܬܫܘܝܬܐ",
            sy8: ""
        },
        {
            sy6: "ܬܘܬܒܐ",
            lt10: "galwoyo",
            id: "394",
            sy7: "ܬܰܘܬܳܒܳܐ",
            dt3: "",
            sy4: "ܓܠܘܝܐ",
            sy9: "",
            dt2: "",
            sy5: "ܓܰܠܘܳܝܳܐ",
            sy8: "",
            dt1: "Auswanderer",
            lt12: "",
            lt11: "tautobo"
        },
        {
            sy6: "ܡܝܚܛܢܘܬܐ",
            sy5: "ܝܰܚܛܳܐ",
            id: "5684",
            sy4: "ܝܚܛܐ",
            sy8: "",
            dt2: "",
            sy9: "",
            lt10: "yaĥțo",
            dt1: "Abtreibung",
            sy7: "ܡܝܰܚܛܳܢܽܘܬ݂ܳܐ",
            dt3: "",
            lt12: "",
            lt11: "myaĥțonutho"
        },
        {
            id: "3121",
            lt10: "ĉayifo",
            sy8: "",
            sy7: "ܒܰܛܺܝܠܳܐ",
            lt12: "",
            sy4: "ܥܝܝܦܐ",
            lt11: "bațilo",
            sy9: "",
            dt2: "",
            dt1: "müde",
            dt3: "",
            sy5: "ܥܰܝܝܺܦܳܐ",
            sy6: "ܒܛܝܠܐ"
        },
        {
            sy9: "",
            sy5: "ܬܒܰܪ",
            sy7: "ܬܶܚܪܺܝܘܳܐ",
            id: "2411",
            sy6: "ܬܚܪܝܘܐ",
            lt10: "tbar",
            dt3: "",
            lt12: "",
            dt2: "",
            lt11: "teĥriwo",
            dt1: "kaputtmachen",
            sy8: "",
            sy4: "ܬܒܪ"
        },
        {
            sy8: "",
            lt10: "ܐܝܠܐ",
            sy5: "ܐܺܝܠ",
            dt2: "El",
            sy7: "ܐܶܠ",
            sy4: "ܐܝܠ",
            id: "2162",
            dt3: "",
            sy6: "ܐܠ",
            dt1: "Il",
            lt12: "ܐܠ",
            lt11: "ܐܝܠܐ",
            sy9: ""
        },
        {
            dt1: "Persischer Golf",
            sy8: "",
            lt12: "",
            lt10: "ĉubo d`fores",
            lt11: "",
            sy7: "",
            id: "3437",
            dt3: "",
            sy6: "",
            dt2: "",
            sy5: "ܥܽܘܒܳܐ ܕܦܳܪܶܣ",
            sy9: "",
            sy4: "ܥܘܒܐ ܕܦܪܣ"
        },
        {
            sy4: "ܩܠܦܐ",
            lt11: "qlaf",
            dt3: "",
            sy7: "ܩܠܰܦ",
            sy8: "",
            lt12: "",
            dt1: "schälen",
            sy6: "ܩܠܦ",
            lt10: "qlofo",
            id: "3883",
            sy5: "ܩܠܳܦܳܐ",
            dt2: "abblättern",
            sy9: ""
        },
        {
            lt11: "ܒܪܛܝܡܝ",
            id: "482",
            sy7: "",
            dt2: "",
            lt10: "ܒܪܛܝܡܝ",
            sy6: "",
            sy5: "ܒܰܪܛܺܝܡܰܝ",
            dt3: "",
            lt12: "",
            sy4: "ܒܪܛܝܡܝ",
            sy8: "",
            sy9: "",
            dt1: "Bartimai"
        },
        {
            sy6: "",
            sy8: "",
            id: "3304",
            dt1: "Nsibin",
            sy9: "",
            sy5: "ܢܨܺܝܒܺܝܢ",
            dt3: "Nuseybin",
            lt12: "",
            lt10: "nŝibin",
            sy7: "",
            lt11: "",
            sy4: "ܢܨܝܒܝܢ",
            dt2: "Nusaybin"
        },
        {
            dt1: "Elgabal",
            lt10: "ܐܠܓܒܠ",
            id: "1071",
            sy9: "",
            sy7: "",
            dt2: "",
            sy6: "",
            sy5: "ܐܶܠܰܓܒܰܠ",
            lt11: "ܐܠܓܒܠ",
            sy8: "",
            lt12: "",
            sy4: "ܐܠܓܒܠ",
            dt3: ""
        },
        {
            dt2: "",
            sy4: "ܒܝܬ ܘܥܕܐ",
            id: "4556",
            sy8: "",
            sy5: "ܒܶܝܬ݂ ܘܰܥܕܳܐ",
            lt10: "beth waĉdo",
            dt1: "Treffpunkt",
            sy9: "",
            sy6: "",
            lt12: "",
            dt3: "",
            sy7: "",
            lt11: ""
        },
        {
            lt10: "guboyo",
            dt1: "Wahl",
            sy6: "",
            sy5: "ܓܽܘܒܳܝܳܐ",
            dt2: "",
            sy8: "",
            lt11: "",
            lt12: "",
            dt3: "",
            sy4: "ܓܘܒܝܐ",
            sy9: "",
            sy7: "",
            id: "4934"
        },
        {
            sy8: "",
            dt3: "",
            sy9: "",
            lt11: "",
            dt2: "",
            lt10: "d`lo ĉudrono",
            sy5: "ܕܠܳܐ ܥܽܘܕܪܳܢܳܐ",
            sy6: "",
            id: "3356",
            dt1: "ohne Hilfe",
            sy7: "",
            sy4: "ܕܠܐ ܥܘܕܪܢܐ",
            lt12: ""
        },
        {
            sy9: "",
            dt3: "",
            lt11: "",
            id: "3332",
            sy5: "ܥܰܛܡܳܐ",
            lt12: "",
            dt2: "",
            sy6: "",
            sy8: "",
            dt1: "Oberschenkel",
            sy4: "ܥܛܡܐ",
            sy7: "",
            lt10: "ĉațmo"
        },
        {
            dt1: "Tür",
            id: "4594",
            dt2: "",
            lt10: "tarĉo",
            sy5: "ܬܰܪܥܳܐ",
            lt12: "",
            sy9: "",
            sy4: "ܬܪܥܐ",
            lt11: "",
            sy6: "",
            sy7: "",
            sy8: "",
            dt3: ""
        },
        {
            lt11: "ܘܠܓܫ",
            lt10: "ܘܠܓܫ",
            sy5: "ܘܰܠܰܓܰܫ",
            sy9: "",
            lt12: "",
            id: "4969",
            dt2: "",
            sy8: "",
            dt3: "",
            sy7: "",
            sy4: "ܘܠܓܫ",
            sy6: "",
            dt1: "Walagasch"
        },
        {
            id: "302",
            lt11: "",
            lt12: "",
            sy8: "",
            sy7: "",
            dt3: "Po",
            sy4: "ܛܝܙܐ",
            dt1: "Arsch",
            sy9: "",
            sy5: "ܛܺܝܙܳܐ",
            dt2: "Hintern",
            lt10: "țizo",
            sy6: ""
        },
        {
            lt10: "hani",
            dt1: "geniessen",
            sy4: "ܗܢܝ",
            sy5: "ܗܰܢܺܝ",
            dt2: "",
            lt12: "",
            id: "1563",
            lt11: "",
            sy6: "",
            sy7: "",
            dt3: "",
            sy8: "",
            sy9: ""
        },
        {
            dt3: "",
            lt10: "goyuro",
            sy8: "ܙܢܝܐ",
            dt2: "",
            lt11: "gayoro",
            dt1: "Ehebrecher",
            sy9: "ܙܰܢܳܝܳܐ",
            sy5: "ܓܳܝܽܘܪܳܐ",
            sy4: "ܓܝܘܪܐ",
            lt12: "zanoyo",
            id: "1007",
            sy6: "ܓܝܪܐ",
            sy7: "ܓܰܝܳܪܳܐ"
        },
        {
            id: "3943",
            lt10: "țaruđo",
            sy8: "",
            sy5: "ܛܰܪܽܘܕܳܐ",
            dt3: "",
            sy6: "ܛܪܘܙܐ",
            sy4: "ܛܪܘܕܐ",
            sy9: "",
            lt12: "",
            dt1: "Schieler",
            dt2: "",
            lt11: "țaruzo",
            sy7: "ܛܰܪܽܘܙܳܐ"
        },
        {
            lt12: "",
            lt10: "ܪܒܘܢܐ",
            id: "3584",
            dt3: "",
            dt1: "Rabuna",
            lt11: "ܪܒܘܢܐ",
            sy8: "",
            sy4: "ܪܒܘܢܐ",
            dt2: "",
            sy5: "ܪܰܒܽܘܢܰܐ",
            sy6: "",
            sy7: "",
            sy9: ""
        },
        {
            lt10: "aloho meshkaĥ bkulzban urĥo",
            sy6: "",
            lt11: "",
            dt2: "",
            sy7: "",
            sy8: "",
            sy9: "",
            lt12: "",
            id: "1688",
            dt1: "Gott findet immer einen Weg",
            sy5: "ܐܰܠܳܗܳܐ ܡܶܫܟܰܚ ܒܟܽܠܙܒܰܢ ܐܽܘܪܚܳܐ",
            sy4: "ܐܠܗܐ ܡܫܟܚ ܒܟܠܙܒܢ ܐܘܪܚܐ",
            dt3: ""
        },
        {
            sy9: "",
            sy7: "ܒܶܝܬܢܰܗܪܰܐ",
            dt3: "",
            sy6: "ܒܝܬܢܗܪܐ",
            sy5: "ܒܶܝܬܢܰܗܪܺܝܢ",
            dt1: "Bethnahrin",
            lt11: "ܒܝܬܢܗܪܝܢ",
            sy4: "ܒܝܬܢܗܪܝܢ",
            lt12: "",
            id: "585",
            lt10: "ܒܝܬܢܗܪܝܢ",
            dt2: "",
            sy8: ""
        },
        {
            lt12: "",
            dt1: "Werfen",
            sy4: "ܫܕܝܐ",
            sy5: "ܫܕܳܝܳܐ",
            sy7: "",
            lt10: "shdoyo",
            lt11: "",
            sy6: "",
            dt2: "Wurf",
            id: "5072",
            dt3: "",
            sy8: "",
            sy9: ""
        },
        {
            sy6: "",
            dt1: "Arni",
            sy4: "ܐܪܢܝ",
            lt12: "",
            lt10: "ܐܪܢܝ",
            dt2: "",
            sy9: "",
            sy8: "",
            id: "299",
            sy5: "ܐܰܪܢܺܝ",
            sy7: "",
            dt3: "",
            lt11: "ܐܪܢܝ"
        },
        {
            dt1: "Januar",
            id: "2285",
            sy7: "",
            dt2: "",
            sy5: "ܟܳܢܘܽܢ ܐ̱ܚܪܳܝ",
            sy8: "",
            sy6: "",
            dt3: "",
            lt11: "",
            lt10: "konun ĥaroyo",
            sy9: "",
            sy4: "ܟܢܘܢ ܐܚܪܝ",
            lt12: ""
        },
        {
            sy5: "ܛܳܠܽܘܡܳܐ",
            lt10: "țolumo",
            dt2: "Despot",
            id: "4608",
            lt11: "",
            sy8: "",
            sy6: "",
            sy9: "",
            lt12: "",
            dt1: "Tyrann",
            sy4: "ܛܠܘܡܐ",
            dt3: "Unterdrücker",
            sy7: ""
        },
        {
            sy9: "",
            lt11: "",
            sy5: "ܡܢܳܬ݂ܳܐ ܬܰܚܬܳܝܬܳܐ",
            lt10: "mnotho taĥtoyto",
            sy4: "ܡܢܬܐ ܬܚܬܝܬܐ",
            dt1: "Unterteil",
            sy7: "",
            id: "6333",
            sy8: "",
            sy6: "",
            dt3: "",
            lt12: "",
            dt2: ""
        },
        {
            sy9: "",
            dt1: "Schamaschnuri",
            dt2: "",
            lt11: "ܫܡܫܢܘܪܝ",
            dt3: "",
            sy8: "",
            id: "3893",
            sy4: "ܫܡܫܢܘܪܝ",
            sy7: "",
            sy6: "",
            lt10: "ܫܡܫܢܘܪܝ",
            lt12: "",
            sy5: "ܫܰܡܰܫܢܽܘܪܺܝ"
        },
        {
            sy8: "",
            sy6: "",
            sy7: "",
            sy9: "",
            id: "6416",
            sy4: "ܬܠܝܬܐ",
            lt12: "",
            lt10: "tloyto",
            dt2: "",
            dt1: "lautes Gebet",
            dt3: "",
            lt11: "",
            sy5: "ܬܠܳܝܬܳܐ"
        },
        {
            sy8: "",
            sy4: "ܒܐܘܪܚܐ",
            sy6: "ܒܕܪܒܐ",
            lt10: "b`urĥo",
            id: "4675",
            lt12: "",
            dt2: "",
            sy7: "ܒܕܰܪܒܳܐ",
            sy5: "ܒܐܽܘܪܚܳܐ",
            sy9: "",
            lt11: "b`darbo",
            dt1: "unterwegs",
            dt3: ""
        },
        {
            sy4: "ܒܪܟܡܪܝ",
            dt2: "",
            lt12: "",
            dt1: "Herr segne mich",
            sy7: "",
            sy5: "ܒܰܪܶܟ݂ܡܳܪܝ̱",
            lt10: "barekhmor",
            sy8: "",
            sy6: "",
            dt3: "",
            lt11: "",
            id: "1972",
            sy9: ""
        },
        {
            dt2: "",
            lt10: "makyonutho",
            sy6: "ܡܟܐܒܘܬܐ",
            lt12: "",
            sy7: "ܡܟܐܒܘܬܐ",
            sy5: "ܡܰܟܝܳܢܽܘܬ݂ܳܐ",
            id: "4759",
            sy8: "",
            lt11: "makebutho",
            sy9: "",
            dt3: "",
            dt1: "Verletzung",
            sy4: "ܡܟܝܢܘܬܐ"
        },
        {
            lt12: "",
            lt10: "talmiđo",
            dt2: "",
            id: "2745",
            lt11: "bar yulfono",
            sy4: "ܬܠܡܝܕܐ",
            sy5: "ܬܰܠܡܺܝܕܳܐ",
            sy7: "ܒܰܪ ܝܽܘܠܦܳܢܳܐ",
            dt3: "",
            sy6: "ܒܪ ܝܘܠܦܢܐ",
            dt1: "Lehrling",
            sy8: "",
            sy9: ""
        },
        {
            dt2: "TV",
            lt11: "fores ĥezwo",
            dt3: "Sendung",
            lt12: "fros ĥezwo",
            sy6: "ܦܪܣ ܚܙܘܐ",
            sy7: "ܦܳܪܶܣ ܚܶܙܘܳܐ",
            sy4: "ܦܪܣ ܚܙܘܐ",
            dt1: "Fernseher",
            sy8: "ܦܪܣ ܚܙܘܐ",
            sy9: "ܦܪܳܣ ܚܶܙܘܳܐ",
            id: "1292",
            lt10: "fores ĥezwo",
            sy5: "ܦܳܪܶܣ ܚܶܙܘܳܐ"
        },
        {
            sy9: "",
            dt3: "",
            dt2: "",
            sy5: "ܣܶܬܳܪܳܐ",
            sy8: "",
            id: "5527",
            lt10: "setoro",
            sy4: "ܣܬܪܐ",
            lt12: "",
            sy7: "ܣܶܬܪܳܐ",
            dt1: "Kirchengardine",
            lt11: "setro",
            sy6: "ܣܬܪܐ"
        },
        {
            sy9: "",
            dt1: "Dota",
            lt10: "ܕܬܐ",
            dt2: "",
            lt11: "ܕܬܐ",
            dt3: "",
            sy4: "ܕܬܐ",
            sy6: "",
            sy7: "",
            sy5: "ܕܳܬܰܐ",
            lt12: "",
            id: "953",
            sy8: ""
        },
        {
            sy6: "",
            lt12: "",
            lt10: "kamem",
            dt2: "",
            dt3: "",
            dt1: "addieren",
            sy5: "ܟܰܡܶܡ",
            lt11: "",
            sy4: "ܟܡܡ",
            sy8: "",
            sy9: "",
            sy7: "",
            id: "129"
        },
        {
            sy8: "",
            dt1: "Homosexueller",
            lt11: "ßdumoyo",
            dt2: "",
            sy5: "ܡܽܘܠܣܳܝܳܐ",
            sy4: "ܡܘܠܣܝܐ",
            dt3: "",
            lt12: "",
            id: "6033",
            lt10: "mulsoyo",
            sy7: "ܣܕܽܘܡܳܝܳܐ",
            sy9: "",
            sy6: "ܣܕܘܡܝܐ"
        },
        {
            sy8: "",
            sy5: "ܐܰܚܺܝܰܐ",
            lt10: "ܐܚܝܐ",
            dt2: "",
            sy9: "",
            sy7: "ܝܰܚܝܰܐ",
            lt12: "ܝܚܝܐ",
            id: "2298",
            lt11: "ܐܚܝܐ",
            sy4: "ܐܚܝܐ",
            dt3: "",
            dt1: "Jehija",
            sy6: "ܝܚܝܐ"
        },
        {
            lt10: "ettniĥ",
            sy4: "ܐܬܬܢܝܚ",
            dt1: "ausruhen",
            id: "383",
            sy7: "",
            dt3: "",
            dt2: "entspannen",
            sy8: "",
            sy6: "",
            lt11: "",
            sy5: "ܐ݂ܶܬ݂ܬ݂ܢܺܝܚ",
            lt12: "",
            sy9: ""
        },
        {
            sy5: "ܦܺܝܠܡܳܐ ܡܰܕܚܠܳܢܳܐ",
            dt3: "",
            dt1: "Horrorfilm",
            id: "2053",
            dt2: "Gruselfilm",
            sy4: "ܦܝܠܡܐ ܡܕܚܠܢܐ",
            sy8: "ܦܝܠܡܐ ܡܐܙܝܥܢܐ",
            lt12: "filmo maziĉono",
            sy7: "ܦܺܝܠܡܳܐ ܕܰܪܗܳܒܬܳܐ",
            sy9: "ܦܺܝܠܡܳܐ ܡܰܐܙܺܝܥܳܢܳܐ",
            sy6: "ܦܝܠܡܐ ܕܪܗܒܬܐ",
            lt11: "filmo da`rhobto",
            lt10: "filmo madĥlono"
        },
        {
            sy4: "ܪܚܒܥܡ",
            sy7: "ܪܶܚܰܒܥܰܡ",
            lt11: "ܪܚܒܥܡ",
            sy5: "ܪܚܰܒܥܰܡ",
            sy9: "",
            sy6: "ܪܚܒܥܡ",
            dt3: "",
            lt10: "ܪܚܒܥܡ",
            id: "3665",
            lt12: "ܪܚܒܥܡ",
            dt1: "Rehabeam",
            dt2: "",
            sy8: ""
        },
        {
            sy8: "",
            sy5: "ܪܰܡܰܐܬ",
            lt12: "ܪܡܬ",
            sy6: "ܪܡܬ",
            dt2: "",
            lt11: "ܪܡܬ",
            id: "3600",
            lt10: "ܪܡܬ",
            dt1: "Ramat",
            sy4: "ܪܡܬ",
            sy7: "ܪܳܡܰܬ",
            dt3: "",
            sy9: ""
        },
        {
            sy9: "",
            dt1: "Löschgerät",
            lt11: "",
            sy5: "ܡܕܰܥܟ݂ܳܢܳܐ",
            sy4: "ܡܕܥܟܢܐ",
            dt3: "",
            lt12: "",
            sy8: "",
            sy7: "",
            dt2: "Feuerlöscher",
            sy6: "",
            lt10: "mdaĉkhono",
            id: "2856"
        },
        {
            sy5: "ܦܺܝܠܡܳܐ ܕܡܫܺܝܚܳܐ",
            sy6: "",
            lt10: "filmo d`mshiĥo",
            lt11: "",
            sy7: "",
            lt12: "",
            id: "774",
            dt2: "Jesusfilm",
            dt1: "Christusfilm",
            sy9: "",
            sy8: "",
            dt3: "",
            sy4: "ܦܝܠܡܐ ܕܡܫܝܚܐ"
        },
        {
            sy6: "",
            sy8: "",
            dt3: "",
            id: "3404",
            dt1: "p",
            sy7: "",
            sy4: "ܦ",
            sy9: "",
            sy5: "ܦ݂",
            lt11: "",
            dt2: "",
            lt12: "",
            lt10: "p"
        },
        {
            dt1: "in eigener Person",
            sy4: "ܩܢܘܡܐܝܬ",
            lt11: "",
            lt12: "",
            sy8: "",
            sy5: "ܩܢܽܘܡܳܐܝܺܬ݂",
            sy7: "",
            dt2: "eigen",
            id: "6440",
            sy9: "",
            dt3: "selbst",
            lt10: "qnumoith",
            sy6: ""
        },
        {
            lt11: "",
            sy8: "",
            dt2: "",
            dt1: "Verordnung",
            lt12: "",
            sy4: "ܦܘܣܩܢܐ",
            sy5: "ܦܽܘܣܩܳܢܳܐ",
            sy7: "",
            id: "4776",
            sy9: "",
            sy6: "",
            dt3: "",
            lt10: "fusqono"
        },
        {
            dt1: "Midyat",
            dt3: "",
            sy5: "ܡܶܕܝܰܬ",
            sy4: "ܡܕܝܬ",
            sy7: "ܡܕܶܝܰܕ",
            sy6: "ܡܕܝܕ",
            dt2: "Medyat",
            lt11: "medyat",
            id: "3018",
            sy9: "",
            lt12: "",
            sy8: "",
            lt10: "medyat"
        },
        {
            sy7: "",
            lt10: "gihano",
            lt12: "",
            lt11: "",
            dt3: "",
            sy8: "",
            id: "2044",
            sy5: "ܓܺܝܗܰܢܳܐ",
            sy6: "",
            dt1: "Hölle",
            dt2: "",
            sy9: "",
            sy4: "ܓܝܗܢܐ"
        },
        {
            sy9: "",
            sy6: "",
            sy7: "",
            sy5: "ܚܺܐܪܬܰܐ",
            lt12: "",
            lt10: "ܚܐܪܬܐ",
            dt1: "Hirta",
            id: "2020",
            dt2: "",
            dt3: "",
            sy4: "ܚܐܪܬܐ",
            sy8: "",
            lt11: "ܚܐܪܬܐ"
        },
        {
            lt10: "krih lebo",
            lt12: "",
            sy7: "",
            sy4: "ܟܪܝܗ ܠܒܐ",
            dt1: "herzkrank",
            lt11: "",
            dt3: "",
            sy5: "ܟܪܺܝܗ ܠܶܒܳܐ",
            id: "1982",
            sy8: "",
            sy6: "",
            dt2: "",
            sy9: ""
        },
        {
            lt10: "tlotho bshabo",
            lt11: "",
            dt3: "",
            sy5: "ܬܠܳܬ݂ܳܐ ܒܫܰܒܳܐ",
            dt1: "Dienstag",
            sy7: "",
            sy4: "ܬܠܬܐ ܒܫܒܐ",
            dt2: "",
            id: "888",
            sy8: "",
            lt12: "",
            sy9: "",
            sy6: ""
        },
        {
            sy5: "ܡܬܰܠܰܝܬܳܐ",
            sy9: "",
            dt3: "",
            sy8: "",
            id: "1476",
            dt2: "",
            lt12: "",
            lt11: "",
            sy6: "",
            lt10: "mtalayto",
            sy7: "",
            dt1: "Garderobehaken",
            sy4: "ܡܬܠܝܬܐ"
        },
        {
            sy9: "ܓ",
            dt3: "",
            lt12: "gomal",
            dt2: "Drei",
            sy8: "ܓ",
            lt11: "tloth",
            sy6: "ܬܠܬ",
            lt10: "tlotho",
            dt1: "3",
            sy4: "ܬܠܬܐ",
            sy7: "ܬܠܳܬ݂",
            sy5: "ܬܠܳܬ݂ܳܐ",
            id: "4"
        },
        {
            id: "5127",
            sy8: "",
            sy7: "",
            lt12: "",
            lt10: "fonenan l`yamino ken l`semolo",
            dt1: "wir wenden uns von links nach rechts",
            lt11: "",
            sy6: "",
            dt3: "",
            dt2: "",
            sy5: "ܦܳܢܶܝܢܰܢ ܠܝܰܡܺܝܢܳܐ ܟܶܢ ܠܣܶܡܳܠܳܐ",
            sy9: "",
            sy4: "ܦܢܝܢܢ ܠܝܡܝܢܐ ܟܢ ܠܣܡܠܐ"
        },
        {
            dt1: "einzelnes Haar",
            sy6: "",
            dt3: "",
            sy5: "ܡܶܢܬ݂ܳܐ",
            sy4: "ܡܢܬܐ",
            sy7: "",
            lt10: "mentho",
            sy9: "",
            lt11: "",
            dt2: "Faser",
            id: "6334",
            lt12: "",
            sy8: ""
        },
        {
            lt12: "",
            lt11: "",
            sy5: "ܠܶܒܳܐ ܕܶܐܡܳܐ",
            id: "6204",
            sy6: "",
            dt3: "",
            lt10: "lebo d'emo",
            sy4: "ܠܒܐ ܕܐܡܐ",
            dt1: "Mutterherz",
            dt2: "",
            sy9: "",
            sy8: "",
            sy7: ""
        },
        {
            sy4: "ܣܗܕܘܬܐ ܕܓܠܬܐ",
            dt1: "Falschaussage",
            dt2: "Falschzeugnis",
            sy7: "",
            id: "1248",
            lt12: "",
            dt3: "",
            sy5: "ܣܳܗܕܽܘܬ݂ܳܐ ܕܰܓܠܬܳܐ",
            sy6: "",
            lt11: "",
            sy9: "",
            lt10: "sohdutho dagolto",
            sy8: ""
        },
        {
            dt3: "",
            sy4: "ܗܠܢܕܐ",
            sy6: "",
            sy9: "",
            lt10: "hollanda",
            sy8: "",
            dt1: "Holland",
            dt2: "Niederlande",
            sy5: "ܗܳܠܰܢܕܰܐ",
            sy7: "",
            lt12: "",
            lt11: "",
            id: "2043"
        },
        {
            lt12: "",
            dt2: "",
            sy4: "ܛܘܒܝܐ",
            lt10: "ܛܘܒܝܐ",
            sy9: "",
            dt3: "",
            lt11: "ܛܘܒܝܐ",
            sy8: "",
            sy5: "ܛܳܘܒܺܝܰܐ",
            sy7: "",
            id: "4502",
            dt1: "Tobia",
            sy6: ""
        },
        {
            sy6: "ܐܘܕܥ ܟܬܝܒܬܢܐܝܬ",
            sy7: "ܐܰܘܕܰܥ ܟܬ݂ܺܝܒܬܳܢܳܐܝܺܬ݂",
            lt12: "",
            sy5: "ܟܬ݂ܰܒ",
            dt3: "",
            dt1: "anschreiben",
            sy8: "",
            id: "5976",
            sy9: "",
            lt10: "kthab",
            sy4: "ܟܬܒ",
            dt2: "",
            lt11: "auđaĉ kthibtonoith"
        },
        {
            sy5: "ܥܰܪܦܶܠܳܐ",
            sy4: "ܥܪܦܠܐ",
            sy9: "",
            lt12: "",
            dt3: "",
            dt1: "Nebel",
            sy7: "",
            lt10: "ĉarfelo",
            sy8: "",
            sy6: "",
            lt11: "",
            id: "3228",
            dt2: ""
        },
        {
            lt12: "",
            sy9: "",
            lt11: "ܐܝܬܘܬܐ",
            sy6: "",
            dt2: "",
            sy8: "",
            sy7: "",
            sy5: "ܐܺܝܬܽܘܬܳܐ",
            id: "2256",
            lt10: "ܐܝܬܘܬܐ",
            sy4: "ܐܝܬܘܬܐ",
            dt3: "",
            dt1: "Ithutha"
        },
        {
            sy8: "",
            sy7: "ܛܽܘܝܳܠܳܐ",
            lt12: "",
            sy9: "",
            lt11: "țuyolo",
            dt2: "Ausflug",
            sy6: "ܛܘܝܠܐ",
            lt10: "ĥzuqyo",
            sy5: "ܚܙܽܘܩܝܳܐ",
            id: "3678",
            sy4: "ܚܙܘܩܝܐ",
            dt1: "Reise",
            dt3: ""
        },
        {
            sy7: "",
            lt10: "shuolo",
            dt2: "",
            lt12: "",
            sy6: "",
            dt1: "Frage",
            lt11: "",
            sy4: "ܫܘܐܠܐ",
            sy8: "",
            sy9: "",
            dt3: "",
            sy5: "ܫܽܘܐܳܠܳܐ",
            id: "1357"
        },
        {
            dt2: "Aryo",
            sy8: "",
            sy9: "",
            id: "291",
            sy4: "ܐܪܝܐ",
            lt11: "ܐܪܝܐ",
            dt1: "Arjo",
            dt3: "Ario",
            lt10: "ܐܪܝܐ",
            sy5: "ܐܰܪܝܳܐ",
            sy6: "",
            sy7: "",
            lt12: ""
        },
        {
            sy7: "",
            lt11: "ܝܘܝܩܝܡ",
            lt12: "",
            lt10: "ܝܘܝܩܝܡ",
            dt2: "",
            sy6: "",
            id: "2328",
            dt3: "",
            sy9: "",
            sy4: "ܝܘܝܩܝܡ",
            sy8: "",
            sy5: "ܝܽܘܝܰܩܺܝܡ",
            dt1: "Joachim"
        },
        {
            sy9: "",
            lt12: "",
            id: "2066",
            lt11: "ܚܘܠ",
            dt1: "Hul",
            dt2: "",
            dt3: "",
            sy5: "ܚܽܘܠ",
            sy8: "",
            sy7: "",
            sy6: "",
            sy4: "ܚܘܠ",
            lt10: "ܚܘܠ"
        },
        {
            sy5: "ܚܰܒܠܳܐ ܕܰܓܪܳܫܳܐ",
            id: "5626",
            lt11: "",
            dt2: "",
            dt1: "Abschleppseil",
            dt3: "",
            sy7: "",
            sy8: "",
            sy9: "",
            sy4: "ܚܒܠܐ ܕܓܪܫܐ",
            lt10: "ĥablo dagrosho",
            lt12: "",
            sy6: ""
        },
        {
            dt1: "nähern",
            id: "3182",
            lt12: "",
            sy4: "ܩܪܒ",
            sy7: "",
            sy8: "",
            dt3: "",
            sy5: "ܩܪܶܒ",
            sy6: "",
            lt11: "",
            lt10: "qreb",
            dt2: "",
            sy9: ""
        },
        {
            sy7: "ܫܶܕܬ݂ܳܢܽܘܬ݂ܳܐ",
            sy9: "",
            lt12: "",
            dt3: "",
            sy6: "ܫܕܬܢܘܬܐ",
            dt2: "",
            sy8: "",
            id: "5885",
            sy4: "ܫܕܬܐ",
            lt10: "shedtho",
            lt11: "shedthonutho",
            dt1: "Amt",
            sy5: "ܫܶܕܬ݂ܳܐ"
        },
        {
            lt11: "",
            sy9: "",
            dt3: "",
            sy5: "ܬܰܓܪܽܘܡܬܳܐ",
            sy7: "",
            lt10: "tagrumto",
            dt1: "Skelett",
            sy4: "ܬܓܪܘܡܬܐ",
            id: "4168",
            lt12: "",
            sy8: "",
            dt2: "Gerippe",
            sy6: ""
        },
        {
            lt12: "",
            sy5: "ܫܟܺܝܚܳܐ",
            dt1: "existent",
            dt2: "anwesend",
            id: "1212",
            sy8: "",
            sy6: "",
            sy9: "",
            dt3: "",
            sy7: "",
            lt11: "",
            sy4: "ܫܟܝܚܐ",
            lt10: "shkiĥo"
        },
        {
            lt11: "",
            sy5: "ܟܽܘܪܗܳܢ ܫܰܟܰܪ",
            id: "873",
            dt3: "",
            lt12: "",
            sy6: "",
            sy8: "",
            sy9: "",
            sy4: "ܟܘܪܗܢ ܫܟܪ",
            lt10: "kurhon shakar",
            dt2: "",
            sy7: "",
            dt1: "Diabetes"
        },
        {
            lt10: "kotoĉen ğamo",
            lt11: "",
            dt1: "er macht sich Sorgen",
            sy4: "ܟܬܥܢ ܓܡܐ",
            id: "1124",
            sy6: "",
            sy7: "",
            dt2: "",
            lt12: "",
            sy8: "",
            dt3: "",
            sy9: "",
            sy5: "ܟܳܬܳܥܶܢ ܓ݂ܰܡܳܐ"
        },
        {
            dt3: "",
            id: "5047",
            lt10: "britho",
            dt2: "Erde",
            lt11: "ĉolmo",
            sy4: "ܒܪܝܬܐ",
            sy8: "ܬܒܝܠ",
            sy6: "ܥܠܡܐ",
            sy9: "ܬܺܒܶܝܠ",
            sy5: "ܒܪܺܝܬ݂ܳܐ",
            lt12: "tibel",
            sy7: "ܥܳܠܡܳܐ",
            dt1: "Welt"
        },
        {
            dt1: "Gebiet",
            sy7: "",
            dt2: "Gegend",
            lt12: "",
            sy4: "ܦܢܝܬܐ",
            dt3: "Provinz",
            sy6: "",
            id: "1496",
            sy5: "ܦܢܺܝܬ݂ܳܐ",
            lt11: "",
            sy9: "",
            sy8: "",
            lt10: "fnitho"
        },
        {
            lt11: "",
            sy6: "",
            dt1: "Säer",
            sy7: "",
            sy8: "",
            dt3: "",
            sy4: "ܦܠܚ ܙܪܥܐ",
            dt2: "",
            lt12: "",
            lt10: "folaĥ zarĉo",
            sy9: "",
            sy5: "ܦܳܠܰܚ ܙܰܪܥܳܐ",
            id: "3796"
        },
        {
            sy5: "ܩܪܺܝܬ݂ܳܐ ܙܐܰܙ",
            sy8: "",
            dt2: "Zaz",
            lt12: "",
            sy9: "",
            sy4: "ܩܪܝܬܐ ܙܐܙ",
            sy6: "ܙܐܙ",
            dt1: "Dorf Zaz",
            dt3: "",
            lt11: "zaz",
            lt10: "qritho zaz",
            sy7: "ܙܐܰܙ",
            id: "945"
        },
        {
            lt12: "",
            id: "4535",
            sy4: "ܩܛܠܐ",
            sy7: "",
            dt2: "",
            lt11: "",
            lt10: "qețlo",
            sy5: "ܩܶܛܠܳܐ",
            dt1: "Totschlag",
            sy6: "",
            sy9: "",
            dt3: "",
            sy8: ""
        },
        {
            lt10: "msom brisho",
            dt3: "",
            sy4: "ܡܣܳܡܒܪܺܝܫܳܐ",
            sy7: "ܓܙܳܪܕܺܝܢܳܐ",
            sy6: "ܓܙܳܪܕܺܝܢܳܐ",
            dt1: "Strafe",
            sy5: "ܡܣܳܡܒܪܺܝܫܳܐ",
            lt12: "",
            dt2: "",
            sy9: "",
            lt11: "gzordino",
            id: "5486",
            sy8: ""
        },
        {
            sy6: "",
            lt10: "esfir salo",
            dt3: "",
            id: "492",
            sy4: "ܐܣܦܝܪ ܣܠܐ",
            sy7: "",
            dt2: "",
            sy8: "",
            lt12: "",
            lt11: "",
            sy9: "",
            sy5: "ܐܶܣܦܺܝܪ ܣܰܠܳܐ",
            dt1: "Basketball"
        },
        {
            lt10: "tiroyo",
            sy9: "",
            sy6: "",
            sy7: "",
            sy8: "",
            dt2: "",
            lt11: "",
            lt12: "",
            id: "1630",
            sy4: "ܬܐܪܝܐ",
            dt1: "gewissenhaft",
            dt3: "",
            sy5: "ܬܺܐܪܳܝܳܐ"
        },
        {
            lt10: "warqo",
            sy5: "ܘܰܪܩܳܐ",
            sy9: "",
            sy4: "ܘܪܩܐ",
            sy8: "",
            sy7: "",
            lt11: "",
            sy6: "",
            dt1: "Papier",
            lt12: "",
            id: "3416",
            dt2: "Zettel",
            dt3: ""
        },
        {
            sy4: "ܠܐ ܐܢܫܝܐ",
            sy6: "",
            lt12: "",
            dt2: "",
            sy7: "",
            sy5: "ܠܳܐ ܐ̱ܢܳܫܳܝܳܐ",
            sy8: "",
            lt10: "lo noshoyo",
            lt11: "",
            dt3: "",
            sy9: "",
            id: "4651",
            dt1: "Unmensch"
        },
        {
            sy6: "",
            id: "5607",
            lt12: "",
            sy4: "ܒܫܡ ܐܒܐ ܒܪܐ ܪܘܚܐ ܩܕܝܫܐ",
            lt11: "",
            sy7: "",
            dt1: "Im Namen des Vaters und des Sohnes und des heiligen Geistes",
            sy9: "",
            sy5: "ܒܫܶܡ ܐܰܒܳܐ ܒܪܳܐ ܪܽܘܚܳܐ ܩܰܕܺܝܫܳܐ",
            lt10: "bshem abo bro ruĥo qadisho",
            dt2: "",
            dt3: "",
            sy8: ""
        },
        {
            sy8: "",
            sy4: "ܥܪܣܐ ܕܒܝܬ ܟܪܝܗܐ",
            dt3: "",
            lt11: "",
            sy6: "",
            lt10: "ĉarso d`beth krihe",
            sy7: "",
            dt2: "",
            id: "2632",
            dt1: "Krankenhausbett",
            sy9: "",
            lt12: "",
            sy5: "ܥܰܪܣܳܐ ܕܒܶܝܬ݂ ܟܪܝܗܐ"
        },
        {
            sy4: "ܣܗܪܐ",
            dt3: "",
            sy9: "",
            sy8: "",
            lt10: "sahro",
            sy5: "ܣܰܗܪܳܐ",
            sy7: "ܣܺܝܢܳܐ",
            sy6: "ܣܝܢܐ",
            id: "3080",
            lt11: "sino",
            dt2: "",
            lt12: "",
            dt1: "Mond"
        },
        {
            sy7: "ܓܰܒܝ",
            dt2: "",
            lt12: "naqi",
            sy6: "ܓܒܝ",
            sy8: "ܢܩܝ",
            sy4: "ܓܒܐ",
            sy9: "ܢܰܩܝ",
            dt1: "auswählen",
            id: "393",
            lt11: "gabi",
            sy5: "ܓܒܰܐ",
            lt10: "gba",
            dt3: ""
        },
        {
            dt1: "Sieb",
            sy7: "",
            lt11: "",
            sy4: "ܡܨܠܬܐ",
            sy5: "ܡܨܰܠܬܳܐ",
            sy8: "",
            id: "4148",
            dt3: "",
            dt2: "Filter",
            lt12: "",
            sy9: "",
            sy6: "",
            lt10: "mŝalto"
        },
        {
            sy7: "",
            dt3: "",
            sy5: "ܫܰܚܠܶܦ",
            id: "4408",
            lt12: "",
            dt1: "tauschen",
            dt2: "abwechseln",
            sy6: "",
            sy9: "",
            lt10: "shaĥlef",
            sy4: "ܫܚܠܦ",
            sy8: "",
            lt11: ""
        },
        {
            dt1: "kindisch",
            sy9: "ܫܰܒܪܳܐܝܺܬ݂",
            sy4: "ܛܠܝܘܣܐܝܬ",
            sy7: "ܫܰܒܪܳܝܳܐ",
            sy6: "ܫܒܪܝܐ",
            lt12: "shabroith",
            id: "2481",
            sy8: "ܫܒܪܐܝܬ",
            lt11: "shabroyo",
            sy5: "ܛܰܠܝܽܘܣܳܐܝܺܬ݂",
            dt2: "kindlich",
            dt3: "",
            lt10: "țalyusoith"
        },
        {
            id: "875",
            dt3: "",
            sy8: "",
            sy4: "ܠܥܙܐ",
            lt11: "",
            lt10: "leĉso",
            sy7: "",
            sy9: "",
            sy6: "",
            sy5: "ܠܶܥܙܳܐ",
            dt1: "Dialekt",
            dt2: "",
            lt12: ""
        },
        {
            sy5: "ܬܽܘ̈ܬ݂ܶܐ ܦܪ̈ܢܫܳܫܶܐ",
            sy6: "ܐܩܘܢܐ",
            sy7: "ܐܳܩܽܘܢܳܐ",
            sy9: "",
            dt3: "",
            lt12: "",
            sy8: "",
            lt10: "tuthe franshoye",
            dt1: "Erdbeer",
            lt11: "oquno",
            sy4: "ܬܘܬܐ ܦܪܢܫܝܐ",
            dt2: "Erdbeere",
            id: "1138"
        },
        {
            sy5: "ܦܶܚܡܳܐ",
            sy8: "",
            dt1: "Ebenbild",
            id: "988",
            dt3: "",
            lt10: "feĥmo",
            sy6: "ܦܚܡܝܘܬܐ",
            sy4: "ܦܚܡܐ",
            lt12: "",
            sy9: "",
            sy7: "ܦܶܚܡܳܝܽܘܬ݂ܳܐ",
            lt11: "feĥmoyutho",
            dt2: "Ähnlichkeit"
        },
        {
            sy6: "",
            sy5: "ܫܡܳܐ",
            lt12: "",
            sy8: "",
            dt1: "Name",
            sy7: "",
            lt10: "shmo",
            dt2: "",
            lt11: "",
            sy9: "",
            sy4: "ܫܡܐ",
            dt3: "",
            id: "3196"
        },
        {
            sy6: "ܐܡܘܬܝܐ",
            dt2: "",
            sy9: "",
            lt11: "emwothoyo",
            sy5: "ܐܶܡܘܳܬ݂ܢܳܝܳܐ",
            dt3: "",
            lt10: "emwothonoyo",
            sy4: "ܐܡܘܬܢܝܐ",
            id: "2226",
            sy7: "ܐܶܡܘܳܬ݂ܳܝܳܐ",
            dt1: "International",
            sy8: "",
            lt12: ""
        },
        {
            lt11: "",
            dt3: "",
            dt1: "Vorspeise",
            sy4: "ܡܐܟܠܐ ܕܩܕܡ ܪܝܫܝܐ",
            sy9: "",
            id: "4908",
            lt10: "mekhlo da`qđom rishoyo",
            sy6: "",
            sy7: "",
            sy5: "ܡܶܐܟ݂ܠܳܐ ܕܰܩܕܳܡ ܪܺܝܫܳܝܳܐ",
            dt2: "",
            lt12: "",
            sy8: ""
        },
        {
            dt2: "",
            sy8: "",
            sy5: "ܓܰܫ",
            dt3: "",
            id: "4221",
            sy7: "",
            lt10: "gash",
            lt11: "",
            sy9: "",
            sy4: "ܓܫ",
            dt1: "spionieren",
            sy6: "",
            lt12: ""
        },
        {
            sy9: "",
            sy5: "ܬܺܝܒܶܠܳܝܳܐ",
            dt1: "allumfassen",
            lt12: "",
            lt10: "tibeloyo",
            sy4: "ܬܝܒܠܝܐ",
            sy8: "",
            dt2: "",
            lt11: "",
            dt3: "",
            sy7: "",
            sy6: "",
            id: "5848"
        },
        {
            dt2: "",
            sy6: "",
            lt12: "",
            lt10: "rboso",
            sy9: "",
            dt3: "",
            sy8: "",
            id: "985",
            sy4: "ܐ",
            sy5: "ܐܶ",
            dt1: "e",
            lt11: "",
            sy7: ""
        },
        {
            dt1: "Familie",
            lt10: "iqartho",
            lt11: "",
            sy7: "",
            sy9: "",
            id: "1250",
            sy4: "ܝܩܪܬܐ",
            dt2: "",
            sy8: "",
            sy6: "",
            lt12: "",
            dt3: "",
            sy5: "ܝܺܩܰܪܬܳܐ"
        },
        {
            sy5: "ܣܽܘܪܝܳܝܳܐ ܡܰܥܶܪܒܳܝܳܐ",
            id: "5075",
            dt3: "",
            sy6: "",
            sy8: "",
            lt11: "",
            dt1: "Westaramäer",
            lt12: "",
            sy9: "",
            lt10: "suryoyo maĉerboyo",
            dt2: "Westassyrer",
            sy7: "",
            sy4: "ܣܘܪܝܝܐ ܡܥܪܒܝܐ"
        },
        {
            dt2: "",
            sy9: "",
            sy5: "ܐܰܪܦܰܟ݂ܫܰܕ",
            lt11: "ܐܪܦܟ݂ܫܕ",
            dt1: "Arpachschad",
            id: "301",
            sy7: "",
            lt12: "",
            lt10: "ܐܪܦܟ݂ܫܕ",
            sy4: "ܐܪܦܟ݂ܫܕ",
            sy8: "",
            dt3: "",
            sy6: ""
        },
        {
            sy8: "",
            dt3: "",
            lt11: "ܠܡܟ݂",
            dt2: "",
            dt1: "Lamech",
            lt12: "",
            sy4: "ܠܡܟ݂",
            sy6: "",
            id: "2695",
            lt10: "ܠܡܟ݂",
            sy5: "ܠܰܡܶܟ݂",
            sy9: "",
            sy7: ""
        },
        {
            lt11: "",
            id: "1983",
            sy4: "ܠܒܢܝܐ",
            dt1: "herzlich",
            lt12: "",
            sy8: "",
            dt2: "",
            lt10: "lebonoyo",
            sy5: "ܠܶܒܳܢܳܝܳܐ",
            sy6: "",
            dt3: "",
            sy9: "",
            sy7: ""
        },
        {
            sy4: "ܬܘܕܝ",
            sy7: "",
            lt10: "taudi",
            sy8: "",
            dt3: "",
            sy5: "ܬܰܘܕܺܝ",
            lt11: "",
            dt1: "Danke",
            sy9: "",
            lt12: "",
            sy6: "",
            id: "802",
            dt2: ""
        },
        {
            lt12: "",
            dt3: "Scheitern",
            sy9: "",
            sy6: "ܫܢܙܐ",
            dt2: "Erfolglosigkeit",
            sy7: "ܫܢܳܙܳܐ",
            sy5: "ܠܳܐ ܟܽܘܫܳܪܳܐ",
            sy4: "ܠܐ ܟܘܫܪܐ",
            sy8: "",
            id: "3055",
            lt10: "lo kushoro",
            dt1: "Misserfolg",
            lt11: "shnozo"
        },
        {
            dt1: "Kalbsfleisch",
            lt10: "besro d`ĉeglo",
            lt11: "",
            dt3: "",
            sy6: "",
            sy8: "",
            sy5: "ܒܶܣܪܳܐ ܕܥܶܓܠܳܐ",
            id: "2387",
            dt2: "",
            sy4: "ܒܣܪܐ ܕܥܓܠܐ",
            lt12: "",
            sy9: "",
            sy7: ""
        },
        {
            sy5: "ܕܽܘܟܬ݂ܳܐ",
            lt11: "",
            dt2: "Stelle",
            sy9: "",
            lt10: "duktho",
            sy6: "",
            lt12: "",
            sy8: "",
            sy7: "",
            dt3: "",
            id: "3393",
            dt1: "Ort",
            sy4: "ܕܘܟܬܐ"
        },
        {
            id: "224",
            sy7: "",
            sy9: "",
            sy8: "",
            sy5: "ܣܓ݂ܶܕ",
            dt2: "",
            lt10: "sğed",
            sy4: "ܣܓܕ",
            dt1: "anbeten",
            sy6: "",
            lt11: "",
            dt3: "",
            lt12: ""
        },
        {
            lt11: "",
            lt10: "kawi",
            sy6: "",
            sy5: "ܟܰܘܺܝ",
            sy7: "",
            sy9: "",
            sy8: "",
            id: "735",
            dt2: "",
            dt1: "bügeln",
            sy4: "ܟܘܝ",
            dt3: "",
            lt12: ""
        },
        {
            sy4: "ܐܦܠܐ",
            dt3: "",
            lt11: "of lo",
            sy6: "ܐܦ ܠܐ",
            dt2: "",
            lt12: "",
            sy5: "ܐܳܦܠܳܐ",
            sy8: "",
            sy7: "ܐܳܦ ܠܳܐ",
            lt10: "oflo",
            id: "3339",
            dt1: "oder nicht",
            sy9: ""
        },
        {
            id: "4432",
            sy7: "ܒܶܝܬ݂ ܡܰܩܕܫܳܐ",
            sy8: "ܗܝܟܠܩܘܕܫܐ",
            dt3: "",
            dt2: "",
            dt1: "Tempel",
            sy4: "ܗܝܟܠܐ",
            sy6: "ܒܝܬ ܡܩܕܫܐ",
            lt10: "hayklo",
            lt12: "haykalqudsho",
            sy9: "ܗܰܝܟܰܠܩܽܘܕܫܳܐ",
            lt11: "beth maqdsho",
            sy5: "ܗܰܝܟܠܳܐ"
        },
        {
            sy9: "",
            lt12: "",
            sy8: "",
            lt10: "senath nosho",
            lt11: "",
            sy4: "ܣܢܐܬ ܢܫܐ",
            sy5: "ܣܶܢܰܐܬ݂ ܢܳܫܳܐ",
            id: "3000",
            dt2: "",
            sy7: "",
            sy6: "",
            dt3: "",
            dt1: "Menschenhass"
        },
        {
            sy9: "",
            lt11: "",
            sy4: "ܡܬܗܦܟܢܘܬܐ",
            lt10: "methhafkhonutho",
            sy5: "ܡܶܬ݂ܗܰܦܟ݂ܳܢܽܘܬ݂ܳܐ",
            lt12: "",
            sy6: "",
            dt2: "",
            sy8: "",
            id: "99",
            dt1: "Abkehr",
            dt3: "",
            sy7: ""
        },
        {
            lt12: "",
            lt10: "mmaryono",
            dt2: "",
            sy5: "ܡܡܰܪܝܳܢܳܐ",
            sy6: "ܚܣܡܐ",
            id: "2581",
            lt11: "ĥasomo",
            sy8: "",
            dt3: "",
            dt1: "Konkurrent",
            sy9: "",
            sy7: "ܚܰܣܳܡܳܐ",
            sy4: "ܡܡܪܝܢܐ"
        },
        {
            sy5: "ܠܒܘ̈ܫܐ ܕܢܶܫ̈ܶܐ",
            sy4: "ܠܒܘܫܐ ܕܢܫܐ",
            id: "1372",
            sy6: "",
            sy9: "",
            lt10: "lbushe d`neshe",
            dt3: "",
            dt2: "",
            lt11: "",
            sy8: "",
            dt1: "Frauenkleidung",
            lt12: "",
            sy7: ""
        },
        {
            sy6: "ܓܪܕܐ",
            sy8: "",
            lt11: "gordo",
            dt1: "Biber",
            sy4: "ܩܣܛܘܪ",
            lt12: "",
            dt3: "",
            dt2: "",
            sy9: "",
            sy7: "ܓܳܪܕܳܐ",
            id: "610",
            sy5: "ܩܰܣܛܽܘܪ",
            lt10: "qasțur"
        },
        {
            sy6: "",
            lt10: "ܫܘܫܐ",
            id: "4326",
            sy8: "",
            sy9: "",
            dt1: "Susan",
            lt12: "",
            lt11: "ܫܘܫܐ",
            sy7: "",
            dt3: "",
            sy5: "ܫܽܘܫܰܢ",
            dt2: "",
            sy4: "ܫܘܫܢ"
        },
        {
            sy7: "ܡܒܰܢܝܳܢܳܐ",
            lt11: "mbanyono",
            sy5: "ܡܫܰܬܣܳܢܳܐ",
            lt10: "mshatsono",
            sy6: "ܡܒܢܝܢܐ",
            lt12: "",
            sy8: "",
            dt2: "",
            sy4: "ܡܫܬܣܢܐ",
            dt1: "Gründer",
            sy9: "",
            dt3: "",
            id: "1749"
        },
        {
            lt11: "",
            sy5: "ܩܰܠܬ݂ܳܢܳܐ",
            lt12: "",
            lt10: "qalthono",
            dt2: "Mann mit einem Hoden",
            sy6: "",
            dt3: "",
            sy9: "",
            sy4: "ܩܠܬ݂ܢܐ",
            sy8: "",
            sy7: "",
            id: "2301",
            dt1: "jemand mit einem Hoden"
        },
        {
            sy9: "",
            dt3: "",
            dt2: "",
            sy7: "",
            sy8: "",
            dt1: "Indien",
            sy6: "",
            id: "2196",
            sy5: "ܗܶܢܕܽܘ",
            lt12: "",
            sy4: "ܗܢܕܘ",
            lt11: "",
            lt10: "hendu"
        },
        {
            dt1: "Ich kann nicht mehr",
            lt11: "ĉayef no",
            sy8: "",
            id: "2118",
            lt10: "bațil no",
            dt3: "",
            sy9: "",
            sy5: "ܒܰܛܺܝܠ ܐ̱ܢܳܐ",
            sy7: "ܥܰܝܶܦ ܐ̱ܢܳܐ",
            sy4: "ܒܛܝܠ ܐܢܐ",
            lt12: "",
            dt2: "Ich bin erschöpft",
            sy6: "ܥܝܶܦ ܐ̱ܢܐ"
        },
        {
            sy5: "ܦܢܳܝܳܐ",
            lt11: "",
            dt3: "",
            id: "3760",
            dt2: "",
            sy7: "",
            sy6: "",
            sy9: "",
            sy8: "",
            lt10: "fnoyo",
            sy4: "ܦܢܝܐ",
            lt12: "",
            dt1: "Rücksendung"
        },
        {
            lt12: "",
            sy8: "",
            dt1: "Standardmodell",
            sy5: "ܛܽܘܦܣܳܐ ܡܬܰܚܡܳܐ",
            lt11: "",
            sy6: "",
            dt2: "",
            dt3: "",
            sy7: "",
            lt10: "țufso mtaĥmo",
            sy9: "",
            sy4: "ܛܘܦܣܐ ܡܬܚܡܐ",
            id: "4237"
        },
        {
            dt3: "",
            lt12: "",
            sy9: "",
            lt10: "ĉoŝrath laymuno",
            lt11: "",
            sy4: "ܥܨܪܬ ܠܝܡܘܢܐ",
            id: "5339",
            sy8: "",
            sy6: "",
            sy7: "",
            dt1: "Zitronenpresse",
            sy5: "ܥܳܨܪܰܬ݂ ܠܰܝܡܽܘܢܳܐ",
            dt2: ""
        },
        {
            dt3: "",
            sy9: "ܡܚܰܣܝܳܢܽܘܬ݂ܳܐ",
            lt10: "shbuqyo",
            sy4: "ܫܒܘܩܝܐ",
            lt12: "mĥasyonutho",
            sy5: "ܫܒܽܘܩܝܳܐ",
            lt11: "mestaybronutho",
            id: "4519",
            sy7: "ܡܶܣܬܰܝܒܪܳܢܽܘܬ݂ܳܐ",
            dt1: "Toleranz",
            dt2: "",
            sy6: "ܡܣܬܝܒܪܢܘܬܐ",
            sy8: "ܡܚܣܝܢܘܬܐ"
        },
        {
            lt12: "",
            dt2: "",
            dt3: "",
            lt11: "",
            sy4: "ܠܚܘܣܪܢܐ",
            sy8: "",
            sy7: "",
            id: "5369",
            dt1: "zum Nachteil",
            lt10: "lĥusrono",
            sy6: "",
            sy9: "",
            sy5: "ܠܚܽܘܣܪܳܢܳܐ"
        },
        {
            dt1: "Sünderin",
            dt3: "",
            sy6: "",
            sy7: "",
            lt10: "ĥațoyto",
            lt11: "",
            dt2: "",
            id: "4315",
            lt12: "",
            sy4: "ܚܛܝܬܐ",
            sy5: "ܚܰܛܳܝܬܳܐ",
            sy8: "",
            sy9: ""
        },
        {
            id: "3060",
            sy9: "ܛܰܝܒܽܘܬ݂ܳܢܽܘܬ݂ܳܐ",
            dt1: "Mitgefühl",
            sy8: "ܛܝܒܘܬܢܘܬܐ",
            lt12: "țaybuthonutho",
            lt11: "ruĥofo",
            dt3: "",
            dt2: "Mitleid",
            sy4: "ܡܪܚܦܢܘܬܐ",
            sy7: "ܪܽܘܚܳܦܳܐ",
            lt10: "mraĥfonutho",
            sy6: "ܪܘܚܦܐ",
            sy5: "ܡܪܰܚܦܳܢܽܘܬ݂ܳܐ"
        },
        {
            id: "430",
            sy8: "",
            dt3: "",
            dt2: "",
            sy4: "ܫܥܝܐ ܕܐܣܦܝܪܐ",
            dt1: "Ballspiel",
            sy7: "",
            sy6: "",
            lt12: "",
            lt11: "",
            sy5: "ܫܶܥܝܳܐ ܕܐܶܣܦܺܝܪܳܐ",
            lt10: "sheĉyo d`esfiro",
            sy9: ""
        },
        {
            id: "2746",
            lt12: "",
            lt10: "taĥrezto d`yulfono",
            sy8: "",
            lt11: "",
            dt2: "",
            dt1: "Lehrplan",
            sy5: "ܬܰܚܪܶܙܬܳܐ ܕܝܽܘܠܦܳܢܳܐ",
            sy6: "",
            sy4: "ܬܚܪܙܬܐ ܕܝܘܠܦܢܐ",
            sy9: "",
            sy7: "",
            dt3: ""
        },
        {
            sy8: "",
            sy6: "ܡܟܬܒ ܙܒܢܐ",
            lt12: "",
            sy9: "",
            sy4: "ܬܫܥܝܬܐ",
            dt2: "",
            lt10: "teshĉitho",
            sy7: "ܡܰܟܬܰܒ ܙܰܒܢܳܐ",
            sy5: "ܬܶܫܥܺܝܬ݂ܳܐ",
            dt1: "Geschichte",
            lt11: "maktab zabno",
            dt3: "",
            id: "1580"
        },
        {
            id: "542",
            lt12: "",
            dt1: "Belohner",
            sy4: "ܦܪܘܥܐ",
            sy8: "",
            lt10: "foruĉo",
            dt2: "",
            sy5: "ܦܳܪܽܘܥܳܐ",
            sy6: "",
            sy9: "",
            dt3: "",
            sy7: "",
            lt11: ""
        },
        {
            dt2: "",
            sy5: "ܪܰܓܽܘܙܳܐ",
            lt12: "raguztono",
            sy4: "ܪܓܘܙܐ",
            lt10: "raguzo",
            sy6: "ܪܓܝܙܐ",
            dt3: "",
            sy8: "ܪܓܘܙܬܢܐ",
            lt11: "rgizo",
            sy9: "ܪܰܓܽܘܙܬܳܢܳܐ",
            id: "6302",
            sy7: "ܪܓܺܝܙܳܐ",
            dt1: "zornig"
        },
        {
            lt10: "yamo d`melĥo",
            sy8: "",
            dt3: "",
            id: "3818",
            dt2: "",
            sy4: "ܝܡܐ ܕܡܠܚܐ",
            lt12: "",
            sy6: "",
            dt1: "Salzmeer",
            sy5: "ܝܰܡܳܐ ܕܡܠܝܳܐ",
            sy9: "",
            sy7: "",
            lt11: ""
        },
        {
            lt12: "",
            dt1: "Brötchen",
            sy9: "",
            sy6: "ܠܝܚܝܟܐ",
            lt11: "liĥike",
            sy5: "ܠܺܝܚܟܶܐ",
            sy4: "ܠܝܚܟܐ",
            lt10: "liĥke",
            sy7: "ܠܺܝܚܺܝܟܶܐ",
            dt2: "Kleines Brot",
            dt3: "",
            sy8: "",
            id: "6148"
        },
        {
            sy4: "ܕܚܝܠܐ",
            dt1: "Versager",
            lt11: "glizo",
            dt3: "",
            id: "4795",
            lt12: "",
            sy9: "",
            sy8: "",
            dt2: "",
            sy7: "ܓܠܺܙܳܐ",
            sy6: "ܓܠܙܐ",
            lt10: "daĥilo",
            sy5: "ܕܰܚܺܝܠܳܐ"
        },
        {
            dt2: "",
            sy5: "ܒܶܝܬ݂ ܥܰܬܺܝܩܳܬ݂ܳܐ",
            dt3: "",
            lt10: "beth ĉatiqotho",
            sy4: "ܒܝܬ ܥܬܝܩܬܐ",
            sy6: "ܒܝܬ ܥܬܩܐ",
            sy8: "",
            sy9: "",
            sy7: "ܒܶܝܬ݂ ܥܶܬܩܶܐ",
            lt11: "beth ĉetqe",
            id: "3128",
            lt12: "",
            dt1: "Museum"
        },
        {
            sy9: "",
            id: "4667",
            sy4: "ܬܚܪܙܬܐ ܕܗܪܓܐ",
            lt12: "",
            sy8: "",
            sy7: "",
            dt3: "",
            dt2: "Stundenplan",
            sy5: "ܬܰܚܪܶܙܬܳܐ ܕܗܶܪܓܳܐ",
            sy6: "",
            lt10: "taĥrezto d`herge",
            dt1: "Unterrichtsplan",
            lt11: ""
        },
        {
            sy8: "",
            sy9: "",
            sy4: "ܩܡܚܐ ܕܚܛ̈ܐ",
            lt12: "",
            lt11: "",
            id: "5041",
            sy7: "",
            dt1: "Weizenmehl",
            lt10: "qamĥo d`ĥețe",
            sy6: "",
            dt2: "",
            dt3: "",
            sy5: "ܩܰܡܚܳܐ ܕܚܶܛ̈ܐ"
        },
        {
            dt3: "",
            sy4: "ܐܓܪܐ ܕܠܒܢܐ",
            id: "5315",
            lt11: "",
            sy9: "",
            sy6: "",
            sy7: "",
            lt10: "egoro d`lebne",
            dt1: "Ziegeldach",
            sy5: "ܐܶܓܳܪܳܐ ܕܠܶܒܢܶܐ",
            dt2: "",
            lt12: "",
            sy8: ""
        },
        {
            sy5: "ܟܬ݂ܳܒܳܐ ܕܢܳܡܽܘܣܳܐ",
            dt1: "Gesetzbuch",
            dt2: "",
            sy9: "",
            lt10: "kțhobo d`nomuso",
            sy6: "ܟܬܒܐ ܕܫܦܛܐ",
            lt11: "kthobo d`shofțe",
            dt3: "",
            sy8: "",
            id: "1599",
            sy4: "ܟܬܒܐ ܕܢܡܘܣܐ",
            lt12: "",
            sy7: "ܟܬ݂ܳܒܳܐ ܕܫܳܦ̈ܛܶܐ"
        },
        {
            sy6: "",
            sy7: "",
            dt1: "Barachel",
            lt11: "ܒܪܐܟ݂ܝܠ",
            dt3: "",
            id: "441",
            lt10: "ܒܪܐܟ݂ܝܠ",
            sy5: "ܒܰܪܰܐܟ݂ܶܝܠ",
            sy9: "",
            lt12: "",
            sy8: "",
            dt2: "",
            sy4: "ܒܪܐܟ݂ܝܠ"
        },
        {
            lt11: "",
            sy4: "ܒܪܝܟ ܨܦܪܐ",
            sy9: "",
            sy6: "",
            dt3: "",
            sy5: "ܒܪܺܝܟ݂ ܨܰܦܪܳܐ",
            lt12: "",
            sy8: "",
            dt2: "",
            lt10: "brikh ŝafro",
            id: "1774",
            sy7: "",
            dt1: "Guten Morgen"
        },
        {
            sy6: "",
            lt11: "",
            id: "2154",
            dt2: "",
            dt1: "Ignoranz",
            sy9: "",
            sy8: "",
            sy7: "",
            sy5: "ܓܒܺܝܢܽܘܬ݂ܳܐ",
            lt10: "gbinutho",
            lt12: "",
            sy4: "ܓܒܝܢܘܬܐ",
            dt3: ""
        },
        {
            sy7: "",
            dt2: "",
            sy5: "ܥܛܰܫ",
            lt10: "ĉțash",
            sy6: "",
            sy9: "",
            id: "3276",
            dt3: "",
            dt1: "niesen",
            sy8: "",
            sy4: "ܥܛܫ",
            lt11: "",
            lt12: ""
        },
        {
            dt2: "",
            sy9: "",
            lt11: "",
            lt12: "",
            id: "3726",
            dt1: "riskant",
            dt3: "",
            lt10: "qențoyo",
            sy4: "ܩܢܛܝܐ",
            sy8: "",
            sy6: "",
            sy5: "ܩܶܢܛܳܝܳܐ",
            sy7: ""
        },
        {
            lt12: "",
            sy8: "",
            dt3: "",
            dt1: "gottesfürchtig",
            sy5: "ܕܳܚܶܠ ܡܳܪܝܳܐ",
            sy9: "",
            lt11: "",
            dt2: "",
            sy7: "",
            sy4: "ܕܚܠ ܡܪܝܐ",
            lt10: "doĥel moryo",
            id: "1702",
            sy6: ""
        },
        {
            sy7: "",
            sy8: "",
            sy4: "ܒܨܝܪܘܬ ܕܡܐ",
            sy5: "ܒܨܺܝܪܽܘܬ݂ ܕܡܳܐ",
            sy9: "",
            sy6: "",
            dt2: "",
            lt10: "bŝiruth dmo",
            lt11: "",
            lt12: "",
            id: "661",
            dt1: "Blutarmut",
            dt3: ""
        },
        {
            sy6: "",
            sy5: "ܒܬ݂ܽܘܠܬܳܐ",
            sy4: "ܒܬܘܠܬܐ",
            dt3: "",
            sy9: "",
            sy7: "",
            lt11: "",
            id: "2363",
            dt2: "",
            lt10: "bthulto",
            sy8: "",
            dt1: "Jungfrau",
            lt12: ""
        },
        {
            id: "1857",
            lt10: "bath iđo",
            dt3: "",
            sy7: "ܒܢܳܬ݂ ܟܰܦ̈ܶܐ",
            sy4: "ܒܪܬ ܐܝܕܐ",
            dt2: "",
            lt12: "",
            sy9: "",
            sy6: "ܒܢܬ ܟܦܐ",
            dt1: "Handschuhe",
            sy8: "",
            sy5: "ܒܰܪ̱ܬ݂ ܐܺܝܕܳܐ",
            lt11: "bnoth kafe"
        },
        {
            lt12: "",
            dt2: "",
            dt3: "",
            sy5: "ܐܶܛܪܽܘܓ݂ܳܝܳܐ",
            dt1: "orange",
            sy7: "",
            sy6: "",
            lt10: "ețruğoyo",
            sy4: "ܐܛܪܘܓܝܐ",
            lt11: "",
            sy8: "",
            sy9: "",
            id: "3385"
        },
        {
            dt1: "Feueraltar",
            dt2: "",
            sy4: "ܐܕܪܘܩܐ",
            lt10: "adruqo",
            sy6: "",
            sy9: "",
            lt12: "",
            sy8: "",
            id: "6389",
            sy7: "",
            lt11: "",
            dt3: "",
            sy5: "ܐܰܕܪܽܘܩܳܐ"
        },
        {
            dt3: "",
            sy8: "",
            sy7: "",
            sy4: "ܐܕܘܢܝܐ",
            lt11: "ܐܕܘܢܝܐ",
            sy6: "",
            id: "134",
            lt12: "",
            sy5: "ܐܰܕܳܘܢܝܰܐ",
            sy9: "",
            dt2: "",
            dt1: "Adonija",
            lt10: "ܐܕܘܢܝܐ"
        },
        {
            sy7: "",
            lt10: "qanyo",
            sy4: "ܩܢܝܐ",
            sy9: "",
            lt11: "",
            dt1: "Stift",
            sy6: "",
            dt3: "",
            sy8: "",
            sy5: "ܩܰܢܝܳܐ",
            dt2: "Kugelschreiber",
            id: "4271",
            lt12: ""
        },
        {
            sy5: "ܐܰܕܡܳܐ",
            lt11: "",
            sy9: "",
            sy4: "ܐܕܡܐ",
            sy6: "",
            lt12: "",
            sy8: "",
            sy7: "",
            dt2: "",
            dt1: "Blut",
            dt3: "",
            id: "658",
            lt10: "admo"
        },
        {
            sy7: "",
            id: "657",
            lt12: "",
            sy5: "ܩܰܚܦܳܐ",
            lt11: "",
            sy4: "ܩܚܦܐ",
            sy6: "",
            dt1: "Blumentopf",
            dt2: "",
            dt3: "",
            lt10: "qaĥfo",
            sy8: "",
            sy9: ""
        },
        {
            sy6: "",
            lt11: "",
            sy7: "",
            lt10: "shroğo",
            dt3: "",
            dt2: "Lampe",
            sy8: "",
            lt12: "",
            id: "2790",
            sy9: "",
            sy5: "ܫܪܳܓ݂ܳܐ",
            dt1: "Leuchte",
            sy4: "ܫܪܓܐ"
        },
        {
            lt11: "",
            dt3: "",
            sy6: "",
            sy7: "",
            dt1: "Regenschirm",
            sy5: "ܡܛܰܠܬ݂ܳܐ",
            sy9: "",
            sy4: "ܡܛܠܬܐ",
            sy8: "",
            lt12: "",
            lt10: "mțaltho",
            id: "3659",
            dt2: "Schirm"
        },
        {
            sy6: "",
            dt3: "",
            sy5: "ܛܽܘܒܰܢ",
            lt12: "",
            dt2: "",
            lt10: "ܛܘܒܢܐ",
            sy8: "",
            sy7: "",
            dt1: "Tuban",
            sy4: "ܛܘܒܢ",
            id: "4587",
            lt11: "ܛܘܒܢܐ",
            sy9: ""
        },
        {
            id: "1770",
            sy6: "ܠܠܝܐ ܛܒܐ",
            sy7: "ܠܶܠܝܳܐ ܛܳܒܳܐ",
            sy4: "ܠܠܝܐ ܕܢܝܚܐ",
            sy9: "",
            dt3: "",
            lt11: "lelyo țobo",
            dt2: "",
            dt1: "Gute Nacht",
            sy5: "ܠܶܠܝܳܐ ܕܰܢܝܳܚܳܐ",
            lt10: "lelyo danyoĥo",
            lt12: "",
            sy8: ""
        },
        {
            sy8: "",
            id: "1953",
            dt1: "Heleni",
            dt3: "",
            lt12: "",
            sy6: "",
            lt10: "ܗܠܢܝܝ",
            lt11: "ܗܠܢܝܝ",
            sy7: "",
            sy9: "",
            sy4: "ܗܠܢܝ",
            sy5: "ܗܶܠܶܢܺܝ",
            dt2: ""
        },
        {
            dt1: "weit",
            id: "5038",
            sy4: "ܪܚܘܩܐ",
            lt10: "raĥuqo",
            sy5: "ܪܰܚܽܘܩܳܐ",
            lt12: "",
            sy6: "",
            dt3: "",
            lt11: "",
            sy8: "",
            sy7: "",
            dt2: "",
            sy9: ""
        },
        {
            sy5: "ܐܰܪܺܝܡ ܐܺܝܕܳܐ ܕܝܰܡܺܝܢܳܐ",
            sy8: "",
            lt11: "",
            lt10: "arim iđo d`yamino",
            dt1: "hebe den rechten Arm hoch",
            lt12: "",
            sy4: "ܐܪܝܡ ܐܝܕܐ ܕܝܡܝܢܐ",
            sy9: "",
            sy6: "",
            id: "1917",
            sy7: "",
            dt2: "",
            dt3: ""
        },
        {
            lt11: "ܦܩܝܕܐ",
            lt12: "",
            sy4: "ܦܩܝܕܐ",
            sy5: "ܦܩܺܝܕܳܐ",
            id: "1356",
            sy6: "",
            sy8: "",
            dt1: "Fqido",
            dt2: "",
            lt10: "ܦܩܝܕܐ",
            sy9: "",
            sy7: "",
            dt3: ""
        },
        {
            sy7: "ܡܶܬ݂ܰܝܢܳܢܽܘܬ݂ܳܐ",
            sy6: "ܡܬܝܢܢܘܬܐ",
            sy4: "ܡܠܬ ܫܡܐ",
            lt10: "melath shmo",
            id: "5768",
            sy8: "ܫܡܐ ܐܝܢܝܐ",
            lt11: "methaynonutho",
            sy5: "ܡܶܠܰܬ݂ ܫܡܳܐ",
            sy9: "ܫܡܳܐ ܐܰܝܢܳܝܳܐ",
            lt12: "shmo aynoyo",
            dt2: "",
            dt1: "Adjektiv",
            dt3: ""
        },
        {
            sy6: "ܝܘܚܢܘܢ",
            sy7: "ܝܽܘܚܰܢܽܘܢ",
            lt10: "ܝܘܚܢܢ",
            sy9: "",
            dt2: "Yohanen",
            id: "2332",
            sy8: "",
            sy5: "ܝܽܘܚܰܢܳܢ",
            lt12: "ܝܘܚܢܘܢ",
            dt1: "Johannes",
            dt3: "Johanen",
            sy4: "ܝܘܚܢܢ",
            lt11: "ܝܘܚܢܢ"
        },
        {
            lt11: "ĥeshukho",
            dt2: "Dunkelheit",
            sy7: "ܚܶܫܽܘܟ݂ܳܐ",
            sy8: "ܚܫܘܟܘܬܐ",
            dt3: "",
            lt12: "ĥeshukhutho",
            sy6: "ܚܫܘܟܐ",
            sy9: "ܚܶܫܽܘܟ݂ܽܘܬ݂ܳܐ",
            sy5: "ܓܗܺܝܪܽܘܬ݂ܳܐ",
            id: "1317",
            dt1: "Finsternis",
            sy4: "ܓܗܝܪܘܬܐ",
            lt10: "ghirutho"
        },
        {
            dt3: "",
            sy9: "",
            sy7: "",
            sy6: "",
            lt10: "ܟܠܬܐ",
            lt12: "",
            lt11: "ܟܠܬܐ",
            sy5: "ܟܰܠܬܰܐ",
            dt2: "",
            dt1: "Kaltha",
            id: "2395",
            sy8: "",
            sy4: "ܟܠܬܐ"
        },
        {
            dt3: "",
            sy8: "",
            sy7: "",
            id: "1867",
            sy9: "",
            lt12: "",
            lt10: "ܚܢܢ",
            lt11: "ܚܢܢ",
            dt2: "",
            sy6: "",
            dt1: "Hanon",
            sy4: "ܚܢܢ",
            sy5: "ܚܰܢܳܢ"
        },
        {
            sy5: "ܪܽܘܡܪܳܡܳܐ",
            lt10: "ܪܘܡܪܡܐ",
            dt3: "",
            sy6: "",
            sy7: "",
            sy8: "",
            id: "3767",
            sy9: "",
            dt1: "Rumromo",
            lt12: "",
            dt2: "",
            lt11: "ܪܘܡܪܡܐ",
            sy4: "ܪܘܡܪܡܐ"
        },
        {
            dt3: "",
            lt11: "",
            sy5: "ܡܕܰܥܟ݂ܳܢܽܘܬ݂ܳܐ",
            id: "2855",
            lt12: "",
            sy7: "",
            lt10: "mdaĉkhonutho",
            sy6: "",
            sy8: "",
            sy9: "",
            sy4: "ܡܕܥܟܢܘܬܐ",
            dt1: "Löschen",
            dt2: ""
        },
        {
            sy6: "",
            sy7: "",
            sy8: "",
            lt10: "momuno",
            dt1: "finanzstark",
            dt3: "Vermögen",
            sy4: "ܡܡܘܢܐ",
            lt11: "",
            id: "1312",
            sy9: "",
            lt12: "",
            sy5: "ܡܳܡܽܘܢܳܐ",
            dt2: "Kapital"
        },
        {
            dt1: "Minderheit",
            sy4: "ܩܠܝܠܘܬܐ",
            sy6: "",
            lt12: "",
            sy5: "ܩܰܠܺܝܠܽܘܬ݂ܳܐ",
            id: "3039",
            sy7: "",
            sy8: "",
            dt3: "",
            dt2: "Minorität",
            sy9: "",
            lt10: "qalilutho",
            lt11: ""
        },
        {
            lt11: "",
            sy5: "ܕܪܶܠܶܗ ܫܠܳܡܐ ܐܰܥܠܰܝـܝܶܗ",
            sy6: "",
            dt2: "",
            sy4: "ܕܪܠܗ ܫܠܡܐ ܐܥܠܝـܝܗ",
            sy7: "",
            sy8: "",
            sy9: "",
            id: "roQYppf6ib36XqhGp4Yv",
            dt1: "Er begrüßte sie.",
            lt12: "",
            dt3: "",
            lt10: "Drele šlomo aclayye",
            "date": {
                "seconds": 1587244964,
                "nanoseconds": 915000000
            }
        },
        {
            sy8: "",
            sy4: "ܛܘܪܝܐ",
            sy9: "",
            sy6: "",
            dt3: "",
            dt1: "Turoyo",
            id: "4606",
            lt11: "ܛܘܪܝܐ",
            lt12: "",
            lt10: "ܛܘܪܝܐ",
            sy7: "",
            dt2: "",
            sy5: "ܛܽܘܪܳܝܳܐ"
        },
        {
            sy6: "",
            sy9: "",
            lt12: "",
            dt3: "",
            id: "2960",
            sy7: "",
            lt10: "frokho",
            sy4: "ܦܪܟܐ",
            sy5: "ܦܪܳܟ݂ܳܐ",
            dt1: "Massage",
            sy8: "",
            dt2: "",
            lt11: ""
        },
        {
            sy7: "",
            dt3: "",
            sy5: "ܬܶܢܳܢܬܳܐ",
            lt11: "",
            lt10: "tenonto",
            sy4: "ܬܖܢܬܐ",
            lt12: "",
            sy8: "",
            dt2: "",
            sy6: "",
            sy9: "",
            dt1: "Zigarette",
            id: "5323"
        },
        {
            dt3: "",
            sy4: "ܫܥܬ ܩܢܛܐ",
            sy7: "",
            dt1: "Alarmuhr",
            lt12: "",
            lt10: "shoĉath qențo",
            dt2: "",
            sy8: "",
            sy5: "ܫܳܥܰܬ݂ ܩܶܢܛܳܐ",
            sy9: "",
            sy6: "",
            lt11: "",
            id: "5818"
        },
        {
            sy6: "",
            sy9: "",
            sy8: "",
            sy5: "ܗܶܪܓܳܐ",
            dt1: "Unterricht",
            lt10: "hergo",
            lt12: "",
            id: "4665",
            sy4: "ܗܪܓܐ",
            dt2: "",
            sy7: "",
            dt3: "",
            lt11: ""
        },
        {
            dt2: "",
            sy6: "ܟܪ",
            dt1: "wohin",
            sy9: "",
            dt3: "",
            sy5: "ܠܰܐܝܟܳܐ",
            lt12: "",
            sy8: "",
            id: "5153",
            sy7: "ܟܰܪ",
            lt11: "kar",
            lt10: "layko",
            sy4: "ܠܐܝܟܐ"
        },
        {
            dt3: "",
            dt2: "",
            sy8: "",
            sy5: "ܨܰܘܡܳܐ",
            dt1: "Fasten",
            lt10: "ŝaumo",
            lt11: "",
            sy9: "",
            sy7: "",
            sy6: "",
            sy4: "ܨܘܡܐ",
            id: "1262",
            lt12: ""
        },
        {
            sy7: "",
            id: "3931",
            sy5: "ܫܶܬܶܐܣܬܰܐ",
            dt1: "Schetesta",
            dt3: "",
            sy8: "",
            sy6: "",
            sy9: "",
            lt12: "",
            lt10: "ܫܬܐܣܬܐ",
            lt11: "ܫܬܐܣܬܐ",
            dt2: "",
            sy4: "ܫܬܐܣܬܐ"
        },
        {
            sy6: "",
            lt12: "",
            sy9: "",
            sy5: "ܕܰܘܺܝܕ",
            id: "837",
            sy7: "",
            dt2: "Davut",
            lt11: "ܕܘܝܕ",
            sy8: "",
            lt10: "ܕܘܝܕ",
            dt3: "Davud",
            dt1: "David",
            sy4: "ܕܘܝܕ"
        },
        {
            dt2: "",
            sy7: "ܥܽܘܝܳܕ ܩܠܺܝܡܳܐ",
            sy6: "ܥܘܝܕ ܩܠܝܡܐ",
            sy8: "",
            sy4: "ܠܚܘܡܘܬܐ",
            sy5: "ܠܳܚܽܘܡܽܘܬ݂ܳܐ",
            lt12: "",
            lt10: "loĥumutho",
            dt3: "",
            id: "5801",
            dt1: "Akklimatisation",
            sy9: "",
            lt11: "ĉuyod qlimo"
        },
        {
            dt3: "Moin",
            sy4: "ܫܠܡܐ",
            dt2: "Hi",
            sy5: "ܫܠܳܡܳܐ",
            sy9: "",
            id: "1831",
            sy6: "",
            sy7: "",
            dt1: "Hallo",
            lt10: "shlomo",
            lt11: "",
            sy8: "",
            lt12: ""
        },
        {
            lt10: "bar ĉsar shnin",
            id: "5275",
            dt2: "",
            sy6: "",
            lt11: "",
            sy8: "",
            sy4: "ܒܪ ܥܣܪ ܫܢܝܢ",
            sy5: "ܒܰܪ ܥܣܰܪ ܫܢܺܝܢ",
            sy9: "",
            sy7: "",
            lt12: "",
            dt1: "Zehnjähriger",
            dt3: ""
        },
        {
            lt12: "",
            sy7: "",
            sy5: "ܢܚܶܬ݂",
            sy4: "ܢܚܬ",
            dt2: "",
            lt10: "nĥeth",
            id: "5658",
            sy9: "",
            sy6: "",
            sy8: "",
            dt3: "",
            lt11: "",
            dt1: "abspringen"
        },
        {
            lt11: "",
            lt10: "kahrobo",
            dt3: "",
            sy4: "ܟܗܪܒܐ",
            id: "4300",
            dt1: "Strom",
            sy8: "",
            sy6: "",
            sy9: "",
            sy5: "ܟܰܗܪܳܒܳܐ",
            dt2: "Elektrizität",
            sy7: "",
            lt12: ""
        },
        {
            dt3: "",
            sy7: "",
            lt11: "",
            sy6: "",
            sy8: "",
            sy9: "",
            sy4: "ܓܘܪܢܐ",
            dt2: "Taufbecken",
            sy5: "ܓܽܘܪܢܳܐ",
            lt12: "",
            dt1: "Becken",
            lt10: "gurno",
            id: "517"
        },
        {
            sy4: "ܪܝܡܐ",
            dt1: "Rima",
            sy5: "ܪܺܝܡܰܐ",
            lt11: "ܪܝܡܐ",
            dt3: "",
            sy6: "",
            dt2: "",
            sy8: "",
            id: "3723",
            lt10: "ܪܝܡܐ",
            lt12: "",
            sy7: "",
            sy9: ""
        },
        {
            lt12: "",
            lt11: "mĉadar",
            sy4: "ܥܕܪ",
            sy6: "ܡܥܕܪ",
            dt3: "",
            dt1: "helfen",
            sy8: "",
            dt2: "",
            sy5: "ܥܰܕܰܪ",
            sy9: "",
            id: "1954",
            lt10: "ĉadar",
            sy7: "ܡܥܰܕܰܪ"
        },
        {
            dt3: "",
            dt1: "Schmelzung",
            sy9: "",
            lt10: "fushoro",
            sy7: "ܦܫܳܪܳܐ",
            lt11: "fshoro",
            sy4: "ܦܘܫܪܐ",
            sy6: "ܦܫܪܐ",
            sy8: "",
            id: "3980",
            dt2: "verschmelzen",
            sy5: "ܦܽܘܫܳܪܳܐ",
            lt12: ""
        },
        {
            lt11: "ܪܥܘܬ",
            id: "3773",
            sy5: "ܪܳܥܽܘܬ",
            sy8: "",
            sy4: "ܪܥܘܬ",
            sy9: "",
            dt1: "Ruth",
            sy6: "",
            sy7: "",
            dt2: "",
            dt3: "",
            lt10: "ܪܥܘܬ",
            lt12: ""
        },
        {
            dt1: "reisen",
            sy5: "ܚܙܰܩ",
            lt11: "ĥozeq",
            sy8: "ܛܝܠ",
            id: "3679",
            lt12: "țayel",
            dt3: "",
            dt2: "urlauben",
            sy6: "ܚܙܩ",
            sy7: "ܚܳܙܶܩ",
            sy9: "ܛܰܝܶܠ",
            sy4: "ܚܙܩ",
            lt10: "ĥzaq"
        },
        {
            dt3: "",
            sy6: "",
            sy5: "ܐܰܪܕܶܟ݂ܠܳܐ ܕܒܰܢܳܝܽܘܬ݂ܳܐ",
            sy4: "ܐܰܪܕܶܟ݂ܠܳܐ ܕܒܰܢܳܝܽܘܬ݂ܳܐ",
            dt1: "Bauingenieur",
            dt2: "",
            lt12: "",
            lt11: "",
            sy7: "",
            lt10: "ardekhlo d`banoyutho",
            sy9: "",
            sy8: "",
            id: "5483"
        },
        {
            sy9: "",
            lt12: "",
            dt1: "aufgeklärt",
            dt3: "wissend",
            id: "356",
            sy4: "ܡܢܗܪܐ",
            sy7: "",
            lt11: "",
            lt10: "manhro",
            dt2: "informiert",
            sy8: "",
            sy6: "",
            sy5: "ܡܰܢܗܪܳܐ"
        },
        {
            dt2: "",
            lt11: "",
            lt12: "",
            sy4: "ܐܣܝܐ ܕܒܝܬܐ",
            id: "1895",
            sy7: "",
            dt1: "Hausarzt",
            sy5: "ܐܳܣܝܳܐ ܕܒܰܝܬܳܐ",
            sy6: "",
            dt3: "",
            lt10: "osyo d`bayto",
            sy9: "",
            sy8: ""
        },
        {
            lt12: "",
            sy8: "",
            lt11: "ܬܐܘܕܘܪ",
            lt10: "ܬܐܘܕܘܪ",
            id: "4454",
            dt2: "",
            sy7: "",
            dt3: "",
            sy5: "ܬܶܐܘܳܕܳܘܪ",
            dt1: "Theodor",
            sy4: "ܬܐܘܕܘܪ",
            sy9: "",
            sy6: ""
        },
        {
            sy6: "",
            lt10: "ܓܐܝܘܬܐ",
            sy5: "ܓܰܐܝܽܘܬܐ",
            dt1: "Gayuta",
            lt12: "",
            sy8: "",
            sy4: "ܓܐܝܘܬܐ",
            dt3: "",
            sy9: "",
            lt11: "ܓܐܝܘܬܐ",
            sy7: "",
            id: "1491",
            dt2: ""
        },
        {
            sy4: "ܪܣܣܐ",
            dt3: "",
            sy9: "",
            sy7: "",
            lt12: "",
            lt10: "rsoso",
            sy5: "ܪܣܳܣܳܐ",
            lt11: "",
            id: "1640",
            sy6: "",
            sy8: "",
            dt1: "Gießen",
            dt2: ""
        },
        {
            sy7: "",
            lt10: "sheno",
            id: "1284",
            lt11: "",
            sy5: "ܫܶܢܳܐ",
            sy6: "",
            sy8: "",
            dt2: "Felsen",
            dt3: "",
            sy9: "",
            lt12: "",
            dt1: "Fels",
            sy4: "ܫܢܐ"
        },
        {
            sy5: "ܕܶܟ݂ܪܳܐ",
            id: "3464",
            sy6: "",
            dt1: "Pimmel",
            lt10: "dekhro",
            dt2: "",
            lt12: "",
            sy8: "",
            sy7: "",
            sy4: "ܕܟܪܐ",
            dt3: "",
            lt11: "",
            sy9: ""
        },
        {
            lt12: "",
            sy8: "",
            dt3: "",
            sy4: "ܐܒܘܒܐ",
            sy5: "ܐܰܒܽܘܒܳܐ",
            sy6: "",
            dt1: "Schlauch",
            sy9: "",
            id: "3966",
            lt10: "abubo",
            sy7: "",
            dt2: "Rohr",
            lt11: ""
        },
        {
            dt1: "trinken",
            sy4: "ܐܫܬܝ",
            sy8: "",
            sy7: "",
            sy5: "ܐܶܫܬܺܝ",
            sy6: "",
            sy9: "",
            lt11: "",
            dt3: "",
            dt2: "",
            id: "4566",
            lt12: "",
            lt10: "eshti"
        },
        {
            sy9: "",
            lt11: "",
            dt1: "Luftbild",
            sy5: "ܨܽܘܪܰܬ ܐܳܐܰܪ",
            dt3: "",
            sy4: "ܨܘܪܬ ܐܐܪ",
            sy8: "",
            dt2: "",
            sy7: "",
            sy6: "",
            id: "2866",
            lt10: "ŝurath oyar",
            lt12: ""
        },
        {
            lt10: "sharbono",
            lt12: "",
            lt11: "sharbuno",
            sy7: "ܫܰܪܒܽܘܢܳܐ",
            id: "2517",
            dt1: "Kleinigkeit",
            sy6: "ܫܪܒܘܢܐ",
            sy4: "ܫܪܒܢܐ",
            sy5: "ܫܰܪܒܳܢܳܐ",
            sy8: "",
            dt3: "",
            sy9: "",
            dt2: "unwichtige Sache"
        },
        {
            dt2: "",
            lt12: "",
            sy8: "",
            lt10: "bețlono",
            sy7: "",
            dt3: "",
            sy5: "ܒܶܛܠܳܢܳܐ",
            sy9: "",
            id: "1277",
            lt11: "",
            sy6: "",
            sy4: "ܒܛܠܢܐ",
            dt1: "Feiertag"
        },
        {
            sy9: "",
            dt3: "",
            sy8: "",
            sy4: "ܒܪܘܬܐ",
            id: "562",
            lt10: "ܒܪܘܬܐ",
            lt12: "",
            dt2: "",
            sy6: "",
            sy7: "",
            dt1: "Beruta",
            sy5: "ܒܶܪܽܘܬܰܐ",
            lt11: "ܒܪܘܬܐ"
        },
        {
            sy9: "",
            lt11: "ashreq",
            sy6: "ܐܫܪܩ",
            sy8: "",
            lt12: "",
            dt3: "",
            sy7: "ܐܰܫܪܶܩ",
            dt2: "zischen",
            sy4: "ܫܪܩ",
            lt10: "shreq",
            sy5: "ܫܪܶܩ",
            id: "3451",
            dt1: "pfeifen"
        },
        {
            sy4: "ܬܟܫܦܬܐ",
            sy9: "",
            id: "1326",
            dt3: "",
            lt11: "taĥnanto",
            lt10: "takhshefto",
            lt12: "",
            dt1: "Flehen",
            dt2: "flehentliche Bitte",
            sy5: "ܬܰܟ݂ܫܶܦܬܳܐ",
            sy6: "ܬܚܢܢܬܐ",
            sy8: "",
            sy7: "ܬܰܚܢܰܢܰܬܳܐ"
        },
        {
            id: "2480",
            dt3: "",
            sy4: "ܫܥܬ ܛܠܝܐ",
            sy6: "ܫܥܬܐ ܕܛܠܝܐ",
            dt2: "",
            lt11: "shoĉtho da`țloye",
            lt10: "shoĉath țloye",
            sy9: "",
            dt1: "Kinderuhr",
            sy7: "ܫܳܥܬ݂ܳܐ ܕܰܛܠܳܝ̈ܶܐ",
            lt12: "",
            sy5: "ܫܳܥܰܬ݂ ܛܠܳܝ̈ܶܐ",
            sy8: ""
        },
        {
            sy4: "ܐܝܫܝ",
            sy8: "",
            sy6: "",
            dt3: "",
            dt2: "",
            sy9: "",
            sy5: "ܐܺܝܫܰܝ",
            id: "2242",
            sy7: "",
            dt1: "Ischai",
            lt10: "ܐܝܫܝ",
            lt11: "ܐܝܫܝ",
            lt12: ""
        },
        {
            lt11: "",
            sy9: "",
            sy5: "ܬܰܥܠܳܐ",
            lt10: "taĉlo",
            sy8: "",
            sy4: "ܬܥܠܐ",
            dt2: "",
            dt3: "",
            id: "1423",
            dt1: "Fuchs",
            lt12: "",
            sy7: "",
            sy6: ""
        },
        {
            lt12: "",
            lt11: "ܢܒܝܠ",
            dt1: "Nabil",
            sy8: "",
            lt10: "ܢܒܝܠ",
            id: "3145",
            sy7: "",
            sy6: "",
            dt3: "",
            sy4: "ܢܒܝܠ",
            sy5: "ܢܰܒܺܝܠ",
            dt2: "",
            sy9: ""
        },
        {
            sy9: "",
            sy4: "ܡܣܬܬܢܐ",
            lt10: "msatthono",
            dt1: "Stabilisator",
            dt3: "",
            sy8: "",
            sy7: "",
            lt11: "",
            lt12: "",
            sy5: "ܡܣܰܬܬ݂ܳܢܳܐ",
            sy6: "",
            dt2: "",
            id: "6226"
        },
        {
            sy4: "ܟܢܘܢܬܐ",
            lt10: "knunto",
            sy8: "",
            dt3: "",
            sy5: "ܟܢܽܘܢܬܳܐ",
            dt1: "Lineal",
            sy9: "",
            lt11: "",
            id: "2836",
            lt12: "",
            dt2: "",
            sy6: "",
            sy7: ""
        },
        {
            id: "3075",
            lt11: "mkonutho",
            dt3: "Gegebenheit",
            sy4: "ܦܘܪܣܐ",
            sy7: "ܡܟܳܢܽܘܬ݂ܳܐ",
            sy6: "ܡܟܢܘܬܐ",
            dt1: "Möglichkeit",
            lt10: "furso",
            lt12: "aykanoyutho",
            sy8: "ܐܝܟܢܝܘܬܐ",
            sy5: "ܦܽܘܪܣܳܐ",
            dt2: "Chance",
            sy9: "ܐܰܝܟܰܢܳܝܽܘܬ݂ܳܐ"
        },
        {
            lt10: "shfan",
            sy7: "",
            sy8: "",
            sy5: "ܫܦܰܢ",
            id: "4489",
            sy4: "ܫܦܢ",
            dt2: "",
            dt1: "tilgen",
            dt3: "",
            sy9: "",
            sy6: "",
            lt11: "",
            lt12: ""
        },
        {
            id: "6353",
            sy6: "",
            sy9: "",
            dt1: "Mitteleuropa",
            lt12: "",
            sy5: "ܐܽܘܪܺܝܦܺܝ ܡܶܨܥܳܝܬܳܐ",
            sy4: "ܐܘܪܝܦܝ ܡܨܥܝܬܐ",
            dt2: "",
            sy7: "",
            dt3: "",
            lt10: "urifi meŝĉoyto",
            sy8: "",
            lt11: ""
        },
        {
            dt2: "Tyrann",
            sy7: "ܚܰܪܡܳܐ",
            lt10: "ĥarmono",
            sy8: "",
            sy6: "ܚܪܡܐ",
            dt3: "Abtrünniger",
            id: "869",
            sy4: "ܚܪܡܢܐ",
            lt12: "",
            lt11: "ĥarmo",
            dt1: "Despot",
            sy9: "",
            sy5: "ܚܰܪܡܳܢܳܐ"
        },
        {
            dt1: "Aramäische Sprache",
            sy5: "ܠܶܫܳܢܳܐ ܐܳܪܳܡܳܝܳܐ",
            sy9: "",
            sy7: "ܠܶܫܳܢܳܐ ܣܽܘܪܝܳܝܳܐ",
            lt10: "leshono oromoyo",
            lt12: "",
            dt2: "Syrische Sprache",
            dt3: "",
            sy6: "ܠܫܢܐ ܣܘܪܝܝܐ",
            lt11: "leshono suryoyo",
            sy4: "ܠܫܢܐ ܐܪܡܝܐ",
            sy8: "",
            id: "272"
        },
        {
            lt10: "sako",
            dt3: "",
            sy5: "ܣܰܟܳܐ",
            dt1: "Sakko",
            sy7: "",
            sy9: "",
            id: "3810",
            lt11: "",
            sy4: "ܣܟܐ",
            lt12: "",
            dt2: "",
            sy6: "",
            sy8: ""
        },
        {
            sy7: "",
            sy6: "",
            sy8: "",
            lt12: "",
            dt3: "",
            sy9: "",
            sy4: "ܓܢܬ ܚܝܘܬܐ",
            dt1: "Tiergarten",
            lt11: "",
            lt10: "ganath ĥaywotho",
            id: "4481",
            dt2: "Zoo",
            sy5: "ܓܰܢܰܬ݂ ܚܰܝ̈ܘܳܬ݂ܳܐ"
        },
        {
            lt11: "",
            id: "4669",
            sy8: "",
            lt10: "soyem kirath iđo",
            sy7: "",
            sy4: "ܣܐܡ ܟܪܬ ܐܝܕܐ",
            dt1: "unterschreiben",
            sy9: "",
            sy6: "",
            sy5: "ܣܳܐܶܡ ܟܺܝܪܰܬ݂ ܐܺܝܕܳܐ",
            lt12: "",
            dt3: "",
            dt2: "unterzeichnen"
        },
        {
            lt10: "yolufo",
            lt12: "",
            id: "4050",
            lt11: "talmiđo",
            sy6: "ܬܠܡܝܕܐ",
            dt3: "",
            dt2: "",
            dt1: "Schüler",
            sy9: "",
            sy8: "",
            sy4: "ܝܠܘܦܐ",
            sy5: "ܝܰܠܽܘܦܳܐ",
            sy7: "ܬܰܠܡܺܝܕܳܐ"
        },
        {
            lt10: "yaumon bsafro labkulzban lo nethețĉe",
            dt1: "heute morgen für immer unvergessen",
            sy4: "ܝܘܡܢ ܒܨܦܪܐ ܠܒܟܠܙܒܢ ܠܐ ܢܬܛܥܐ",
            lt12: "",
            dt3: "",
            lt11: "",
            dt2: "",
            sy5: "ܝܰܘܡܳܢ ܒܨܰܦܪܳܐ ܠܰܒܟܽܠܙܒܳܢ ܠܳܐ ܢܶܬ݂ܶܛܥܶܐ",
            id: "1990",
            sy6: "",
            sy9: "",
            sy8: "",
            sy7: ""
        },
        {
            lt11: "shuĥno",
            sy6: "ܫܘܚܢܐ",
            sy8: "",
            sy4: "ܫܠܘܦܚܐ",
            dt3: "",
            sy9: "",
            lt10: "shalufĥo",
            sy7: "ܫܽܘܚܢܳܐ",
            id: "5680",
            dt2: "",
            sy5: "ܫܰܠܽܘܦܚܳܐ",
            lt12: "",
            dt1: "Abszeß"
        },
        {
            lt10: "masqono",
            sy8: "",
            sy6: "",
            sy7: "",
            dt2: "Aufzug",
            sy5: "ܡܰܣܩܳܢܳܐ",
            lt12: "",
            id: "1241",
            lt11: "",
            dt3: "",
            dt1: "Fahrstuhl",
            sy9: "",
            sy4: "ܡܣܩܢܐ"
        },
        {
            dt1: "schieben",
            sy5: "ܙܥܰܦ",
            lt12: "",
            id: "3936",
            lt10: "zĉaf",
            sy9: "",
            dt2: "",
            sy6: "",
            lt11: "",
            sy7: "",
            sy8: "",
            sy4: "ܙܥܦ",
            dt3: ""
        },
        {
            dt2: "",
            sy7: "",
            sy9: "",
            dt3: "",
            dt1: "Sänger",
            lt11: "",
            sy6: "",
            sy5: "ܙܰܡܳܪܳܐ",
            id: "3838",
            sy4: "ܙܡܪܐ",
            sy8: "",
            lt12: "",
            lt10: "zamoro"
        },
        {
            lt12: "",
            sy7: "ܐ̱ܢܳܫܳܐ",
            sy6: "ܐܢܫܐ",
            lt11: "nosho",
            dt1: "Mensch",
            sy8: "",
            dt2: "Menschensohn",
            sy9: "",
            lt10: "barnosho",
            sy4: "ܒܪܢܫܐ",
            dt3: "",
            sy5: "ܒܰܪܢܳܫܳܐ",
            id: "2998"
        },
        {
            lt10: "wardo shafirto",
            lt11: "",
            dt2: "",
            sy4: "ܘܪܕܐ ܫܦܝܪܐ",
            dt3: "",
            sy5: "ܘܰܪܕܳܐ ܫܰܦܺܝܪܬܳܐ",
            sy9: "",
            id: "4011",
            lt12: "",
            sy6: "",
            dt1: "schöne Rose",
            sy8: "",
            sy7: ""
        },
        {
            dt3: "",
            lt10: "ܙܝܬܘܢ",
            lt12: "",
            sy4: "ܙܝܬܘܢ",
            sy5: "ܙܰܝܬܽܘܢ",
            sy7: "",
            sy9: "",
            sy8: "",
            sy6: "",
            lt11: "ܙܝܬܘܢ",
            dt1: "Zaytun",
            dt2: "",
            id: "5265"
        },
        {
            dt1: "Jafet",
            sy9: "",
            lt12: "ܝܦܬ",
            sy5: "ܝܰܦܶܬ",
            sy6: "ܝܦܬ",
            lt11: "ܝܦܬ",
            sy8: "",
            lt10: "ܝܦܬ",
            dt3: "",
            id: "2268",
            sy4: "ܝܦܬ",
            sy7: "ܝܰܦܬ",
            dt2: ""
        },
        {
            dt3: "",
            lt10: "nqash",
            lt11: "noqesh",
            sy8: "",
            sy4: "ܢܩܫ",
            sy6: "ܢܩܫ",
            sy7: "ܢܳܩܶܫ",
            dt2: "",
            sy5: "ܢܩܰܫ",
            id: "2530",
            dt1: "klopfen",
            sy9: "",
            lt12: ""
        },
        {
            dt3: "",
            sy4: "ܡܫܚܠܦܘܬܐ",
            dt2: "Verwechslung",
            sy6: "ܚܘܠܦܐ",
            lt10: "mshaĥlfutho",
            lt11: "ĥulofo",
            dt1: "Diversität",
            sy9: "",
            lt12: "",
            sy8: "",
            sy7: "ܚܽܘܠܳܦܳܐ",
            id: "906",
            sy5: "ܡܫܰܚܠܦܽܘܬ݂ܳܐ"
        },
        {
            dt3: "",
            sy4: "ܥܕܬܐ ܪܝܫܝܬܐ",
            sy6: "",
            sy9: "",
            sy8: "",
            lt11: "",
            dt2: "",
            sy7: "",
            id: "1889",
            sy5: "ܥܺܕܬܳܐ ܪܺܝܫܳܝܬܳܐ",
            lt10: "ĉito rishoyto",
            lt12: "",
            dt1: "Hauptkirche"
        },
        {
            sy5: "ܩܶܢܛܪܽܘܢ ܕܰܥܠܰܝܡܽܘܬ݂ܳܐ",
            sy7: "",
            sy4: "ܩܢܛܪܘܢ ܕܥܠܝܡܘܬܐ",
            id: "2354",
            dt1: "Jugendzentrum",
            sy8: "",
            lt12: "",
            sy6: "",
            dt2: "",
            lt10: "qențrun da`ĉlaymutho",
            sy9: "",
            dt3: "",
            lt11: ""
        },
        {
            id: "5233",
            sy5: "ܐܳܣܝܳܐ ܕܥܰܪܫܳܐ ܡܥܰܝܶܢ ܠܥܰܪ̈ܫܶܐ",
            lt10: "osyo d`ĉarshe mĉayen l`ĉarshe",
            lt11: "",
            sy8: "",
            sy4: "ܐܣܝܐ ܕܥܪܫܐ ܡܥܝܢ ܠܥܪܫܐ",
            sy6: "",
            sy7: "",
            dt1: "Zahnarzt kontrolliert Zähne",
            lt12: "",
            dt2: "",
            sy9: "",
            dt3: ""
        },
        {
            id: "3987",
            lt11: "ܫܡܘܢܝ",
            sy8: "",
            dt1: "Schmuni",
            sy7: "",
            dt2: "Schmouni",
            sy4: "ܫܡܘܢܝ",
            dt3: "",
            sy6: "",
            lt10: "ܫܡܘܢܝ",
            sy5: "ܫܡܽܘܢܺܝ",
            sy9: "",
            lt12: ""
        },
        {
            sy6: "ܕܠܩ",
            sy4: "ܡܕܠܩ",
            lt12: "",
            id: "256",
            sy8: "",
            dt1: "anzünden",
            lt10: "madleq",
            sy5: "ܡܰܕܠܶܩ",
            dt2: "",
            sy9: "",
            lt11: "daleq",
            dt3: "",
            sy7: "ܕܰܠܶܩ"
        },
        {
            dt1: "Eingang",
            lt12: "",
            dt3: "",
            sy4: "ܡܥܠܢܐ",
            id: "5533",
            sy6: "",
            sy5: "ܡܰܥܠܳܢܳܐ",
            sy7: "",
            lt10: "maĉlono",
            lt11: "",
            dt2: "",
            sy8: "",
            sy9: ""
        },
        {
            id: "4690",
            sy8: "ܡܙܪܘܩܐ",
            dt2: "Pisse",
            sy4: "ܬܘܢܐ",
            sy7: "ܬܳܝܢܬܳܐ",
            sy9: "ܡܰܙܪܽܘܩܶܐ",
            sy5: "ܬܽܘܢܶܐ",
            sy6: "ܬܝܢܬܐ",
            lt11: "tyonto",
            lt12: "mazruqe",
            dt1: "Urin",
            lt10: "tune",
            dt3: "Pinkel"
        },
        {
            sy6: "",
            sy8: "",
            dt1: "Ich bin gut",
            sy9: "",
            lt11: "",
            lt12: "",
            dt3: "",
            id: "sS3eGtzQ4aCILzp8rxqJ",
            sy7: "",
            sy4: "ܛܘܘܐ ܢܐ",
            "date": {
                "seconds": 1590848153,
                "nanoseconds": 91000000
            },
            dt2: "Mir geht's gut",
            sy5: "ܛܰܘܘܳܐ ܢܳܐ",
            lt10: "țawwo no"
        },
        {
            sy7: "",
            sy5: "ܟܰܪܝܳܐ ܘܚܰܠܝܳܐ",
            lt11: "",
            lt10: "karyo u ĥalyo",
            sy9: "",
            sy8: "",
            sy4: "ܟܪܝܐ ܘܚܠܝܐ",
            dt3: "",
            dt2: "",
            id: "2676",
            lt12: "",
            sy6: "",
            dt1: "kurz und gut"
        },
        {
            sy7: "ܒܨܺܝܪ ܒܟܽܠ",
            id: "5878",
            lt12: "",
            sy6: "ܒܨܝܪ ܒܟܠ",
            sy9: "",
            dt2: "am geringsten",
            sy4: "ܕܩܠܝܠ ܒܟܠ",
            lt10: "dqalil bkul",
            dt3: "",
            sy8: "",
            lt11: "bŝir bkul",
            sy5: "ܕܩܰܠܺܝܠ ܒܟܽܠ",
            dt1: "am wenigsten"
        },
        {
            lt11: "",
            lt12: "",
            sy9: "",
            lt10: "garbyo",
            dt1: "Norden",
            sy6: "",
            sy8: "",
            dt3: "",
            dt2: "Nord",
            sy5: "ܓܰܪܒܝܳܐ",
            sy7: "",
            sy4: "ܓܪܒܝܐ",
            id: "3293"
        },
        {
            lt10: "ܬܝܓܠܬ",
            sy4: "ܬܝܓܠܬ",
            sy6: "",
            lt12: "",
            sy8: "",
            sy5: "ܬܺܝܓܠܰܬ",
            sy9: "",
            id: "4486",
            sy7: "",
            dt2: "",
            dt3: "",
            lt11: "ܬܝܓܠܬ",
            dt1: "Tiglat"
        },
        {
            dt1: "Anomalie",
            lt10: "ftilutho",
            lt12: "shugnoy hauno",
            lt11: "shnuzyo",
            dt2: "Abnormität",
            sy6: "ܫܢܘܙܝܐ",
            dt3: "Deformation",
            sy8: "ܫܘܓܢܝ ܗܘܢܐ",
            sy5: "ܦܬܺܝܠܽܘܬ݂ܳܐ",
            sy4: "ܦܬܝܠܘܬܐ",
            id: "5972",
            sy7: "ܫܢܽܘܙܝܳܐ",
            sy9: "ܫܽܘܓܢܳܝ ܗܰܘܢܳܐ"
        },
        {
            dt2: "",
            lt10: "ܗܒܒܐ",
            dt1: "Habobo",
            sy6: "",
            dt3: "",
            lt12: "",
            sy7: "",
            sy9: "",
            sy4: "ܗܒܒܐ",
            id: "1797",
            sy5: "ܗܰܒܳܒܳܐ",
            lt11: "ܗܒܒܐ",
            sy8: ""
        },
        {
            lt11: "",
            dt1: "Abtrennen",
            sy5: "ܣܽܘܪܳܩܳܐ",
            lt12: "",
            dt2: "",
            sy6: "",
            lt10: "suroqo",
            sy9: "",
            dt3: "",
            id: "5685",
            sy8: "",
            sy4: "ܣܘܪܩܐ",
            sy7: ""
        },
        {
            sy5: "ܒܶܝܬ݂ ܦܽܘܠܚܳܢ ܟܪܳܦܳ̈ܬ݂ܳܐ",
            sy8: "",
            sy9: "",
            sy7: "",
            sy4: "ܒܝܬ ܦܘܠܚܢ ܟܪܦܬܐ",
            sy6: "",
            lt11: "",
            dt3: "",
            lt12: "",
            lt10: "beth fulĥon krofotho",
            dt2: "",
            dt1: "Möbelfabrik",
            id: "6378"
        },
        {
            dt3: "",
            dt1: "Nahroyo",
            id: "3188",
            sy4: "ܢܗܪܝܐ",
            sy5: "ܢܰܗܪܳܝܳܐ",
            lt12: "",
            sy8: "",
            lt10: "ܢܗܪܝܐ",
            sy9: "",
            lt11: "ܢܗܪܝܐ",
            sy6: "",
            sy7: "",
            dt2: ""
        },
        {
            lt12: "",
            sy8: "",
            dt2: "",
            lt10: "foqudtho d`ruĥqo",
            dt3: "",
            sy7: "ܦܳܩܽܘܕܬ݂ܳܐ ܕܪܽܘܚܩܳܐ",
            sy5: "ܦܳܩܽܘܕܬ݂ܳܐ ܕܪܽܘܚܩܳܐ",
            sy6: "ܦܩܘܕܬ݂ܐ ܕܪܘܚܩܐ",
            sy9: "",
            dt1: "Fernbedienung",
            sy4: "ܦܩܘܕܬܐ ܕܪܘܚܩܐ",
            lt11: "foqudtho d`ruĥqo",
            id: "1290"
        },
        {
            sy6: "ܪܥܝܬܐ",
            sy5: "ܐܰܒܽܘܒܳܐ",
            lt10: "abubo",
            dt3: "",
            dt2: "",
            sy4: "ܐܒܘܒܐ",
            lt11: "raĉitho",
            lt12: "",
            dt1: "Rüssel",
            sy8: "",
            sy7: "ܪܰܥܺܝܬ݂ܳܐ",
            id: "3771",
            sy9: ""
        },
        {
            sy4: "ܒܚܝܫܘܬܐ",
            sy8: "",
            sy6: "",
            sy5: "ܒܚܺܝܫܽܘܬ݂ܳܐ",
            dt1: "Geilheit",
            dt3: "",
            sy9: "",
            lt11: "",
            dt2: "Wollust",
            lt12: "",
            sy7: "",
            lt10: "bĥishutho",
            id: "1539"
        },
        {
            sy8: "",
            dt1: "Drehzahl",
            sy9: "",
            id: "6322",
            lt10: "menyon krokho",
            sy4: "ܡܢܝܢ ܟܪܟܐ",
            sy7: "",
            lt11: "",
            sy5: "ܡܶܢܝܳܢ ܟܪܳܟ݂ܳܐ",
            dt2: "",
            dt3: "",
            sy6: "",
            lt12: ""
        },
        {
            sy5: "ܥܰܟܽܘܒܳܐ",
            dt2: "",
            lt11: "qasubutho",
            dt3: "",
            lt12: "",
            lt10: "ĉakubo",
            id: "6046",
            sy9: "",
            sy4: "ܥܟܘܒܐ",
            sy7: "ܩܰܣܽܘܒܽܘܬ݂ܳܐ",
            sy6: "ܩܣܘܒܘܬܐ",
            dt1: "Hornhaut",
            sy8: ""
        },
        {
            dt1: "Er",
            sy7: "",
            dt2: "",
            sy5: "ܗܽܘ",
            sy9: "",
            lt11: "",
            id: "1119",
            dt3: "",
            sy8: "",
            lt12: "",
            lt10: "hu",
            sy6: "",
            sy4: "ܗܘ"
        },
        {
            sy8: "ܥܒܕ ܫܠܡܐ",
            lt11: "rayaĥ",
            lt10: "shayen",
            sy4: "ܫܝܢ",
            sy5: "ܫܰܝܶܢ",
            sy7: "ܪܰܝܰܚ",
            sy6: "ܪܝܚ",
            sy9: "ܥܒܰܕ ܫܠܳܡܳܐ",
            dt3: "",
            id: "6420",
            lt12: "ĉbad shlomo",
            dt1: "versöhnen",
            dt2: ""
        },
        {
            dt1: "Yaqunto",
            lt12: "",
            lt11: "ܝܘܘܢܬܐ",
            sy4: "ܝܩܘܢܬܐ",
            sy7: "",
            lt10: "ܝܘܘܢܬܐ",
            id: "5202",
            sy5: "ܝܰܩܽܘܢܬܳܐ",
            dt2: "",
            sy8: "",
            dt3: "",
            sy9: "",
            sy6: ""
        },
        {
            lt12: "",
            id: "4062",
            dt3: "",
            sy4: "ܒܪ ܚܡܐ",
            sy5: "ܒܰܪ ܚܡܳܐ",
            dt1: "Schwager",
            sy6: "ܝܒܡܐ",
            sy9: "",
            sy8: "",
            sy7: "ܝܰܒܡܳܐ",
            lt10: "bar ĥmo",
            lt11: "yabmo",
            dt2: ""
        },
        {
            sy5: "ܓܽܘܪܬܳܐ ܕܚܶܠܳܐ",
            sy4: "ܓܘܪܬܐ ܕܚܠܐ",
            id: "3833",
            lt11: "maĥfarto d`helo",
            sy7: "ܡܰܚܦܰܪܬܳܐ ܕܚܶܠܳܐ",
            sy8: "",
            lt10: "gurto d`ĥelo",
            dt1: "Sandgrube",
            lt12: "",
            sy9: "",
            dt2: "",
            dt3: "",
            sy6: "ܡܚܦܪܬܐ ܕܚܠܐ"
        },
        {
            dt1: "Möbelschreinerei",
            sy4: "ܒܝܬ ܪܨܦܐ",
            sy7: "",
            dt3: "",
            id: "6380",
            sy8: "",
            lt11: "",
            sy6: "",
            lt10: "beth rŝofo",
            dt2: "",
            sy5: "ܒܶܝܬ݂ ܪܨܳܦܳܐ",
            sy9: "",
            lt12: ""
        },
        {
            sy5: "ܫܰܦܝܽܘܬ݂ܳܐ",
            dt3: "",
            sy7: "",
            sy9: "",
            dt2: "",
            lt10: "shafyutho",
            sy8: "",
            id: "3194",
            dt1: "Naivität",
            sy6: "",
            sy4: "ܫܦܝܘܬܐ",
            lt12: "",
            lt11: ""
        },
        {
            sy6: "",
            lt10: "ĥathno",
            sy8: "",
            sy7: "",
            sy4: "ܚܬܢܐ",
            lt12: "",
            dt1: "Schwiegersohn",
            id: "4081",
            dt2: "",
            dt3: "",
            sy5: "ܚܰܬ݂ܢܳܐ",
            lt11: "",
            sy9: ""
        },
        {
            sy6: "",
            dt2: "",
            id: "2165",
            dt1: "Ilon",
            sy4: "ܐܝܠܢ",
            lt10: "ܐܝܠܢ",
            sy7: "",
            dt3: "",
            sy9: "",
            lt12: "",
            sy5: "ܐܺܝܠܳܢ",
            lt11: "ܐܝܠܢ",
            sy8: ""
        },
        {
            sy6: "",
            sy9: "",
            sy8: "",
            id: "97",
            sy4: "ܐܒܝܬܖ",
            lt11: "ܐܒܝܬܪ",
            dt1: "Abjatar",
            dt2: "",
            sy7: "",
            sy5: "ܐܰܒܝܰܬܰܖ",
            dt3: "",
            lt12: "",
            lt10: "ܐܒܝܬܪ"
        },
        {
            dt3: "",
            sy9: "",
            id: "3655",
            lt10: "turgomo",
            dt1: "Referat",
            sy4: "ܬܘܪܓܡܐ",
            lt12: "",
            lt11: "",
            dt2: "",
            sy7: "",
            sy6: "",
            sy8: "",
            sy5: "ܬܽܘܪܓܳܡܳܐ"
        },
        {
            sy6: "ܬܚܪܙܬܐ ܕܘܥܕܐ",
            dt1: "Terminkalender",
            sy9: "",
            lt12: "",
            lt10: "taĥrezto",
            sy7: "ܬܰܚܪܶܙܬܳܐ ܕܘܰܥܕܳܐ",
            dt2: "Programm",
            lt11: "taĥrezto d`waĉdo",
            dt3: "",
            sy4: "ܬܚܪܙܬܐ",
            sy8: "",
            id: "4438",
            sy5: "ܬܰܚܪܶܙܬܳܐ"
        },
        {
            lt10: "ܫܐܘܠ",
            id: "4021",
            dt2: "",
            sy4: "ܫܐܘܠ",
            lt11: "ܫܐܘܠ",
            dt1: "Schowol",
            sy5: "ܫܳܐܘܳܠ",
            sy9: "",
            lt12: "",
            sy8: "",
            sy7: "",
            dt3: "",
            sy6: ""
        },
        {
            dt3: "",
            sy8: "",
            id: "583",
            sy4: "ܡܨܠܝܢܐ",
            sy7: "",
            lt12: "",
            sy6: "",
            sy9: "",
            lt10: "msalyono",
            lt11: "",
            dt1: "Betender",
            sy5: "ܡܨܰܠܝܳܢܳܐ",
            dt2: ""
        },
        {
            sy8: "",
            dt3: "",
            lt12: "",
            lt11: "",
            sy4: "ܐܨܚ",
            id: "894",
            sy6: "",
            dt1: "diktieren",
            sy7: "",
            sy5: "ܐܰܨܰܚ",
            lt10: "aŝaĥ",
            dt2: "",
            sy9: ""
        },
        {
            sy4: "ܙܘܓܐ",
            lt10: "zaugo",
            sy5: "ܙܰܘܓܳܐ",
            sy7: "",
            sy6: "",
            lt11: "",
            dt3: "",
            dt1: "Paar",
            lt12: "",
            sy9: "",
            sy8: "",
            id: "3405",
            dt2: ""
        },
        {
            dt1: "Staub",
            lt11: "abqo",
            sy6: "ܐܒܩܐ",
            id: "4244",
            dt2: "",
            sy8: "",
            sy4: "ܚܠܐ",
            lt10: "ĥelo",
            sy5: "ܚܶܠܳܐ",
            lt12: "",
            sy7: "ܐܰܒܩܳܐ",
            sy9: "",
            dt3: ""
        },
        {
            sy5: "ܩܢܳܦܳܐ",
            dt2: "",
            sy8: "ܬܢܘܡܐ",
            dt3: "",
            lt10: "qnofo",
            dt1: "Hanf",
            sy7: "ܩܽܘܢܒܳܪܳܐ",
            sy6: "ܩܘܢܒܪܐ",
            lt11: "qunboro",
            lt12: "tanumo",
            sy9: "ܬܰܢܽܘܡܳܐ",
            sy4: "ܩܢܦܐ",
            id: "6319"
        },
        {
            sy6: "",
            dt3: "",
            sy8: "",
            sy5: "ܒܰܪܥܺܐܕܳܐ",
            dt1: "Bar-Ido",
            sy4: "ܒܪܥܐܕܐ",
            dt2: "Barido",
            lt11: "ܒܪܥܐܕܐ",
            lt12: "",
            lt10: "ܒܪܥܐܕܐ",
            sy9: "",
            id: "453",
            sy7: ""
        },
        {
            dt1: "gefroren",
            sy9: "",
            sy7: "",
            lt12: "",
            sy4: "ܡܓܠܕܢܐ",
            lt11: "",
            sy8: "",
            dt2: "",
            sy5: "ܡܰܓܠܕܳܢܳܐ",
            dt3: "",
            sy6: "",
            lt10: "maglđono",
            id: "1522"
        },
        {
            sy8: "",
            lt11: "",
            sy5: "ܒܪܺܝܫܐܺܝܬ݂ ܐܺܝܬ݂ܰܘܗܝ ܗܘܳܐ ܢܽܘܗܪܳܐ",
            id: "5606",
            lt10: "brishith ithau wo nuhro",
            sy7: "",
            lt12: "",
            sy9: "",
            sy6: "",
            dt2: "",
            dt1: "Am Anfang war das Licht",
            sy4: "ܒܪܝܫܐܝܬ ܐܝܬܘܗܝ ܗܘܐ ܢܘܗܪܐ",
            dt3: ""
        },
        {
            dt2: "",
            dt1: "Mondschein",
            lt11: "",
            sy8: "",
            sy6: "",
            sy4: "ܫܡܫܐ ܕܣܗܪܐ",
            lt10: "shemsho d`sahro",
            lt12: "",
            id: "3081",
            sy5: "ܫܶܡܫܳܐ ܕܣܰܗܪܳܐ",
            dt3: "",
            sy9: "",
            sy7: ""
        },
        {
            lt11: "",
            sy8: "",
            lt12: "",
            id: "5387",
            dt1: "zuviel essen",
            sy9: "",
            sy4: "ܐܘܟܠ ܣܓܝ",
            sy7: "",
            dt2: "",
            lt10: "aukhel sagi",
            sy6: "",
            sy5: "ܐܰܘܟ݂ܶܠ ܣܰܓܺܝ",
            dt3: ""
        },
        {
            sy9: "",
            sy6: "",
            lt10: "men fum l`fum",
            id: "4885",
            sy7: "",
            sy4: "ܡܢ ܦܘܡ ܠܦܘܡ",
            lt11: "",
            dt3: "",
            lt12: "",
            dt1: "von Mund zu Mund",
            sy5: "ܡܶܢ ܦܽܘܡ ܠܦܽܘܡ",
            dt2: "",
            sy8: ""
        },
        {
            id: "4503",
            sy7: "",
            lt11: "ܛܘܒܝܐܣ",
            sy6: "",
            sy9: "",
            dt2: "",
            dt1: "Tobias",
            lt12: "",
            lt10: "ܛܘܒܝܐܣ",
            dt3: "",
            sy5: "ܛܳܘܒܺܝܰܐܣ",
            sy4: "ܛܘܒܝܐܣ",
            sy8: ""
        },
        {
            lt11: "",
            sy9: "",
            dt1: "Brüllen",
            dt3: "",
            sy7: "",
            lt10: "nuhomo",
            sy5: "ܢܽܘܗܳܡܳܐ",
            lt12: "",
            sy6: "",
            dt2: "",
            sy8: "",
            id: "720",
            sy4: "ܢܘܗܡܐ"
        },
        {
            dt1: "Arabien",
            sy9: "",
            lt11: "ĉarabiya",
            dt2: "",
            id: "268",
            sy6: "ܥܪܒܝܐ",
            sy4: "ܐܪܒܝܐ",
            dt3: "",
            sy7: "ܥܰܪܰܒܺܝܰܐ",
            lt10: "arabiya",
            lt12: "",
            sy8: "",
            sy5: "ܐܰܪܰܒܺܝܰܐ"
        },
        {
            lt11: "methbaytyonutho",
            dt3: "",
            lt12: "qurbo",
            sy4: "ܡܬܢܩܦܢܘܬܐ",
            lt10: "methnaqfonutho",
            sy8: "ܩܘܪܒܐ",
            sy7: "ܡܶܬ݂ܒܰܝܬܝܳܢܽܘܬ݂ܳܐ",
            sy9: "ܩܽܘܪܒܳܐ",
            sy6: "ܡܬܒܝܬܝܢܘܬܐ",
            dt1: "Intimität",
            sy5: "ܡܶܬ݂ܢܰܩܦܳܢܽܘܬ݂ܳܐ",
            id: "6175",
            dt2: ""
        },
        {
            lt12: "",
            dt2: "",
            sy9: "",
            dt3: "",
            sy8: "",
            sy4: "ܒܘܚܢܐ ܕܪܩܝܥܐ",
            sy5: "ܒܽܘܚܳܢܳܐ ܕܰܪܩܺܝܥܳܐ",
            dt1: "Weltraumforschung",
            lt10: "buĥono d`arqiĉo",
            sy7: "",
            lt11: "",
            sy6: "",
            id: "5057"
        },
        {
            dt1: "Arbeitslosigkeit",
            sy5: "ܒܰܛܺܝܠܽܘܬ݂ܳܐ",
            sy7: "ܒܰܛܺܝܠܽܘܬ݂ ܦܽܘܠܚܳܢܳܐ",
            lt10: "bațilutho",
            dt2: "",
            sy6: "ܒܛܝܠܘܬ ܦܘܠܚܢܐ",
            lt11: "bațiluth fulĥono",
            dt3: "",
            id: "282",
            sy4: "ܒܛܝܠܘܬܐ",
            sy8: "",
            sy9: "",
            lt12: ""
        },
        {
            sy8: "",
            sy9: "",
            lt12: "",
            sy4: "ܚܪܘܬܐ",
            dt1: "Gesäß",
            sy5: "ܚܪܽܘܬ݂ܳܐ",
            sy7: "",
            dt3: "",
            lt10: "ĥrutho",
            lt11: "",
            id: "1578",
            dt2: "",
            sy6: ""
        },
        {
            dt3: "",
            sy9: "",
            lt11: "",
            sy7: "",
            dt1: "rasieren",
            dt2: "",
            id: "3604",
            sy4: "ܓܪܥ",
            lt10: "graĉ",
            sy8: "",
            sy5: "ܓܪܰܥ",
            lt12: "",
            sy6: ""
        },
        {
            lt11: "",
            sy7: "",
            lt10: "shro",
            sy6: "",
            id: "5907",
            dt1: "analysieren",
            sy9: "",
            sy8: "",
            dt3: "klären",
            dt2: "entwirren",
            lt12: "",
            sy5: "ܫܪܳܐ",
            sy4: "ܫܪܐ"
        },
        {
            sy6: "ܣܐܪܐ",
            sy4: "ܣܐܪܐ",
            dt2: "Sarah",
            sy8: "",
            sy5: "ܣܰܪܰܐ",
            dt1: "Sara",
            lt10: "ܣܐܪܐ",
            lt11: "ܣܐܪܐ",
            dt3: "",
            sy9: "",
            lt12: "ܣܐܪܐ",
            id: "3842",
            sy7: "ܣܰܐܪܰܐ"
        },
        {
            lt11: "ĉatiqo",
            lt12: "",
            dt2: "",
            dt3: "",
            sy4: "ܣܒܐ",
            lt10: "sobo",
            sy5: "ܣܳܒܳܐ",
            sy7: "ܥܰܬܺܝܩܳܐ",
            sy9: "",
            id: "194",
            sy8: "",
            dt1: "alt",
            sy6: "ܥܬܝܩܐ"
        },
        {
            lt12: "",
            dt1: "Zakai",
            sy4: "ܙܟܝ",
            lt10: "ܙܟܝ",
            sy9: "",
            lt11: "ܙܟܝ",
            sy5: "ܙܰܟܰܝ",
            dt2: "Zakay",
            id: "5246",
            sy8: "",
            dt3: "Sakai",
            sy7: "",
            sy6: ""
        },
        {
            id: "4304",
            dt3: "",
            sy7: "",
            lt11: "",
            lt12: "",
            dt2: "",
            sy9: "",
            sy6: "",
            sy4: "ܒܥܬܐ",
            lt10: "bĉotho",
            dt1: "Suche",
            sy5: "ܒܥܳܬ݂ܳܐ",
            sy8: ""
        },
        {
            lt10: "besmo",
            dt2: "",
            sy7: "ܥܶܛܪܳܐ",
            id: "5024",
            sy6: "ܥܛܪܐ",
            lt12: "",
            sy9: "",
            dt1: "Weihrauch",
            lt11: "ĉețro",
            sy8: "",
            sy4: "ܒܣܡܐ",
            dt3: "",
            sy5: "ܒܶܣܡܳܐ"
        },
        {
            dt3: "",
            dt1: "Hahn",
            sy4: "ܬܪܢܓܠܐ",
            lt11: "",
            id: "1824",
            sy8: "",
            sy9: "",
            dt2: "Huhn",
            sy7: "",
            lt12: "",
            sy6: "",
            sy5: "ܬܰܪܢܳܓ݂ܠܳܐ",
            lt10: "tarnoğlo"
        },
        {
            sy4: "ܠܐ ܐܦܣ",
            dt3: "",
            lt10: "lo afes",
            id: "3261",
            sy7: "",
            dt2: "nicht genehmigen",
            sy5: "ܠܳܐ ܐܰܦܶܣ",
            lt11: "",
            sy8: "",
            sy6: "",
            lt12: "",
            dt1: "nicht erlauben",
            sy9: ""
        },
        {
            sy4: "ܐܝܟܢ ܐܝܬܝܟܘܢ ܐܢܬܘܢ",
            sy9: "ܐܰܝܟܰܢܳܐ",
            sy6: "ܐܝܟܢ ܐܝܬܝܟܝܢ ܐܢܬܝܢ",
            id: "5086",
            lt10: "aykan ithaykun atun",
            sy7: "ܐܰܝܟܰܢ ܐܺܝܬ݂ܰܝܟܶܝܢ ܐܰܢ̱ܬܶܝܢ",
            sy5: "ܐܰܝܟܰܢ ܐܺܝܬ݂ܰܝܟܽܘܢ ܐܰܢ̱ܬܽܘܢ",
            dt2: "wie geht es euch",
            lt11: "aykan ithayken aten",
            dt1: "wie geht’s euch",
            lt12: "aykano",
            dt3: "",
            sy8: "ܐܝܟܢܐ"
        },
        {
            dt3: "",
            dt1: "erleuchten",
            lt10: "manhar",
            lt11: "",
            lt12: "",
            sy9: "",
            sy5: "ܡܰܢܗܰܪ",
            sy6: "",
            sy7: "",
            sy8: "",
            dt2: "erhellen",
            sy4: "ܡܢܗܪ",
            id: "1154"
        },
        {
            dt2: "",
            lt12: "",
            lt10: "shorubutho",
            dt1: "Propaganda",
            id: "3522",
            dt3: "",
            lt11: "",
            sy6: "",
            sy7: "",
            sy9: "",
            sy8: "",
            sy5: "ܫܳܪܽܘܒܽܘܬ݂ܳܐ",
            sy4: "ܫܪܘܒܘܬܐ"
        },
        {
            id: "3506",
            sy6: "ܡܟܪܙܢܘܬܐ",
            sy5: "ܟܳܪܽܘܙܽܘܬ݂ܳܐ",
            dt3: "",
            lt10: "koruzutho",
            dt1: "Predigt",
            sy8: "",
            sy7: "ܡܰܟ݂ܪܙܳܢܽܘܬ݂ܳܐ",
            sy9: "",
            dt2: "",
            lt12: "",
            lt11: "makhrzonutho",
            sy4: "ܟܪܘܙܘܬܐ"
        },
        {
            sy8: "ܡܠܚܒܐ",
            lt12: "malĥbo",
            sy5: "ܡܰܕܪܝܳܐ",
            dt1: "Holzgabel",
            sy7: "ܣܦܰܣܪܽܘܓ݂ܬܳܐ",
            sy9: "ܡܰܠܚܒܳܐ",
            dt2: "",
            sy6: "ܣܦܣܪܘܓܬܐ",
            lt11: "sfasruğto",
            sy4: "ܡܕܪܝܐ",
            lt10: "madryo",
            id: "6055",
            dt3: ""
        },
        {
            id: "4764",
            dt1: "Verlust",
            lt10: "ĥusrono",
            lt11: "",
            sy8: "",
            sy6: "",
            sy4: "ܚܘܣܪܢܐ",
            sy9: "",
            sy7: "",
            lt12: "",
            dt3: "",
            sy5: "ܚܽܘܣܪܳܢܳܐ",
            dt2: ""
        },
        {
            sy7: "ܠܳܝܽܘܛܳܐ",
            dt3: "",
            sy6: "ܠܝܘܛܐ",
            sy9: "ܡܓܰܕܦܳܢܳܐ",
            dt1: "Gotteslästerer",
            lt10: "lațoyo",
            lt12: "mgadfono",
            id: "1704",
            sy8: "ܡܓܕܦܢܐ",
            sy5: "ܠܰܛܳܝܳܐ",
            lt11: "loyuțo",
            sy4: "ܠܛܝܐ",
            dt2: ""
        },
        {
            sy5: "ܐܳܬ݂ܽܘܪܳܝܳܐ",
            sy7: "ܣܽܘܪܝܳܝܳܐ ܐܳܬ݂ܽܘܪܳܝܳܐ",
            dt2: "",
            id: "317",
            sy8: "",
            lt10: "othuroyo",
            sy4: "ܐܬܘܪܝܐ",
            lt11: "suryoyo othuroyo",
            dt1: "Assyrer",
            dt3: "",
            sy6: "ܣܘܪܝܝܐ ܐܬܘܪܝܐ",
            lt12: "",
            sy9: ""
        },
        {
            sy7: "",
            sy8: "",
            sy4: "ܐܫܝܢ",
            dt3: "",
            id: "313",
            lt10: "ܐܫܝܢ",
            lt12: "",
            sy9: "",
            lt11: "ܐܫܝܢ",
            sy5: "ܐܰܫܺܝܢ",
            dt1: "Aschin",
            dt2: "",
            sy6: ""
        },
        {
            sy4: "ܓܪܕܐ",
            dt1: "unfruchtbar",
            sy9: "",
            dt2: "kahl",
            lt12: "",
            sy8: "",
            sy5: "ܓܰܪܕܳܐ",
            sy7: "",
            sy6: "",
            dt3: "dürr",
            id: "4644",
            lt10: "gardo",
            lt11: ""
        },
        {
            sy9: "",
            dt3: "",
            id: "5070",
            lt11: "",
            sy4: "ܡܢ ܕܠܝܬ ܠܗ ܚܛܝܬܐ ܢܫܕܐ ܠܟܐܦܐ ܩܕܡܝܬܐ",
            sy5: "ܡܶܢ ܕܠܰܝܬ ܠܶܗ ܚܛܺܝܬ݂ܳܐ ܢܶܫܕܶܐ ܠܟܺܐܦܳܐ ܩܰܕܡܳܝܬܳܐ",
            lt12: "",
            lt10: "man dlaytle ĥtitho neshde lkifo kadmoyto",
            sy7: "",
            sy8: "",
            sy6: "",
            dt2: "",
            dt1: "Wer von Euch ohne Sünde lebt werfe den ersten Stein"
        },
        {
            sy6: "",
            lt12: "",
            sy8: "",
            sy7: "",
            lt10: "taudi yeshuĉ mshiĥo",
            sy5: "ܬܰܘܕܺܝ ܝܶܫܽܘܥ ܡܫܽܝܚܳܐ",
            sy9: "",
            lt11: "",
            sy4: "ܬܘܕܝ ܝܫܘܥ ܡܫܝܚܐ",
            dt1: "Danke Jesus Christus",
            id: "804",
            dt3: "",
            dt2: ""
        },
        {
            lt11: "",
            dt2: "Eisenbahntunnel",
            id: "4593",
            sy7: "",
            sy4: "ܚܘܠܢܐ",
            lt10: "ĥulono",
            dt3: "",
            sy5: "ܚܽܘܠܳܢܳܐ",
            dt1: "Tunnel",
            sy6: "",
            sy8: "",
            sy9: "",
            lt12: ""
        },
        {
            sy4: "ܓܘܝܐ",
            lt11: "esfiro",
            sy6: "ܐܣܦܝܪܐ",
            sy8: "",
            dt1: "Ball",
            sy5: "ܓܽܘܝܳܐ   ",
            dt3: "",
            id: "429",
            sy9: "",
            lt12: "",
            dt2: "Kugel",
            lt10: "guyo",
            sy7: "ܐܶܣܦܺܝܪܳܐ"
        },
        {
            dt1: "großes Unglück",
            sy4: "ܓܢܝܚܘܬܐ",
            dt3: "Schrecken",
            sy8: "",
            sy9: "",
            dt2: "Entsetzen",
            sy5: "ܓܢܺܝܚܽܘܬ݂ܳܐ",
            sy7: "",
            sy6: "",
            id: "1741",
            lt12: "",
            lt11: "",
            lt10: "gniĥutho"
        },
        {
            sy5: "ܡܶܫܬܰܡܪܳܢܽܘܬ݂ܳܐ",
            lt10: "meshtamronutho",
            sy4: "ܡܫܬܡܪܢܘܬܐ",
            lt11: "buțolo",
            lt12: "nufoqo",
            sy7: "ܒܽܘܛܳܠܳܐ",
            dt2: "Entlassung",
            dt1: "Kündigung",
            sy8: "ܢܘܦܩܐ",
            id: "2669",
            sy9: "ܢܽܘܦܳܩܳܐ",
            dt3: "",
            sy6: "ܒܘܛܠܐ"
        },
        {
            id: "4376",
            lt11: "ܬܡܝܡܬܐ",
            lt10: "ܬܡܝܡܬܐ",
            dt3: "",
            dt2: "",
            sy9: "",
            dt1: "Tamimta",
            sy7: "",
            sy8: "",
            sy5: "ܬܰܡܺܝܡܬܰܐ",
            sy4: "ܬܡܝܡܬܐ",
            sy6: "",
            lt12: ""
        },
        {
            id: "5812",
            dt3: "",
            dt1: "akzeptieren",
            sy7: "ܐܶܨܛܒܺܝ",
            sy8: "ܐܕܢܝ",
            dt2: "",
            sy5: "ܐܶܬ݂ܪܰܥܺܝ",
            sy9: "ܐܰܕܢܺܝ",
            lt10: "ethraĉi",
            lt12: "adni",
            lt11: "eŝțbi",
            sy6: "ܐܨܛܒܝ",
            sy4: "ܐܬܪܥܝ"
        },
        {
            sy4: "ܫܘܒܚܐ ܠܐܠܗܐ",
            lt11: "",
            lt10: "shubĥo l`aloho",
            dt2: "",
            dt1: "Ehre sei Gott",
            dt3: "",
            sy6: "",
            lt12: "",
            sy5: "ܫܽܘܒܚܳܐ ܠܰܐܠܳܗܳܐ",
            id: "1017",
            sy9: "",
            sy7: "",
            sy8: ""
        },
        {
            sy6: "",
            sy7: "",
            dt3: "",
            dt2: "",
            dt1: "Mietshaus",
            sy5: "ܒܰܝܬܳܐ ܕܐܰܓ݂ܪܳܐ",
            lt10: "bayto d`ağro",
            sy9: "",
            sy8: "",
            lt12: "",
            id: "3021",
            lt11: "",
            sy4: "ܒܝܬܐ ܕܐܓܪܐ"
        },
        {
            lt10: "tno",
            dt2: "",
            id: "5098",
            lt11: "",
            sy9: "",
            sy4: "ܬܢܐ",
            lt12: "",
            sy6: "",
            sy8: "",
            sy5: "ܬܢܳܐ",
            dt3: "",
            sy7: "",
            dt1: "wiederholen"
        },
        {
            dt3: "",
            sy8: "",
            lt11: "",
            lt10: "meshĥo d`luso",
            sy6: "",
            sy5: "ܡܶܫܚܳܐ ܕܠܽܘܙܳܐ",
            dt1: "Mandelöl",
            lt12: "",
            sy4: "ܡܫܚܐ ܕܠܘܙܐ",
            sy9: "",
            dt2: "",
            id: "2912",
            sy7: ""
        },
        {
            sy5: "ܓܶܘܰܪܓܺܝܣ",
            lt12: "",
            sy6: "",
            lt10: "ܓܘܪܓܝܣ",
            id: "1618",
            dt2: "",
            sy8: "",
            sy4: "ܓܘܪܓܝܣ",
            sy9: "",
            dt3: "",
            lt11: "ܓܘܪܓܝܣ",
            sy7: "",
            dt1: "Gewargis"
        },
        {
            dt2: "",
            lt10: "shli",
            lt11: "ettniĥ",
            id: "6156",
            sy6: "ܐܬܬܢܝܚ",
            dt3: "",
            dt1: "pausieren",
            sy8: "ܒܗܠ",
            sy7: "ܐܶܬܬܢܺܝܚ",
            sy9: "ܒܗܶܠ",
            lt12: "bhel",
            sy5: "ܫܠܺܝ",
            sy4: "ܫܠܝ"
        },
        {
            sy4: "ܢܟܣ",
            dt1: "schlachten",
            lt11: "nokhes",
            dt2: "",
            id: "3957",
            lt10: "nkhas",
            sy6: "ܢܟܣ",
            sy7: "ܢܳܟ݂ܶܣ",
            lt12: "",
            sy8: "",
            sy9: "",
            dt3: "",
            sy5: "ܢܟ݂ܰܣ"
        },
        {
            lt12: "",
            lt11: "mkhuryo",
            sy9: "",
            sy4: "ܡܟܝܪܘܬܐ",
            dt2: "",
            sy6: "ܡܟܘܪܝܐ",
            id: "4763",
            dt3: "",
            sy8: "",
            lt10: "mkhirutho",
            sy5: "ܡܟ݂ܺܝܪܽܘܬ݂ܳܐ",
            sy7: "ܡܟ݂ܽܘܪܝܳܐ",
            dt1: "Verlobung"
        },
        {
            dt1: "Scharbela",
            sy5: "ܫܰܪܒܶܠܰܐ",
            sy4: "ܫܪܒܠܐ",
            sy9: "",
            id: "3901",
            sy8: "",
            lt12: "",
            lt11: "ܫܪܒܠܐ",
            dt2: "",
            lt10: "ܫܪܒܠܐ",
            sy7: "",
            sy6: "",
            dt3: ""
        },
        {
            lt11: "ܐܒܝܐ",
            dt2: "",
            sy7: "",
            sy5: "ܐܰܒܺܝܰܐ",
            lt12: "",
            lt10: "ܐܒܝܐ",
            id: "91",
            sy4: "ܐܒܝܐ",
            sy9: "",
            dt1: "Abija",
            dt3: "",
            sy8: "",
            sy6: ""
        },
        {
            sy7: "",
            sy9: "",
            sy5: "ܫܳܥܬ݂ܳܐ ܢܶܬ݂ܪܳܢܳܝܬܳܐ",
            lt12: "",
            dt2: "",
            dt1: "Atomuhr",
            lt11: "",
            dt3: "",
            lt10: "shoĉtho nethronoyto",
            sy4: "ܫܥܬܐ ܢܬܪܢܝܬܐ",
            sy8: "",
            id: "337",
            sy6: ""
        },
        {
            lt10: "shersho d`saĉro",
            dt2: "",
            sy7: "",
            sy9: "",
            sy5: "ܫܶܪܫܳܐ ܕܣܰܥܪܳܐ",
            lt12: "",
            sy6: "",
            dt1: "Haarwurzel",
            sy8: "",
            sy4: "ܫܪܫܐ ܕܣܥܪܐ",
            dt3: "",
            id: "1787",
            lt11: ""
        },
        {
            sy8: "",
            dt1: "ölig",
            sy4: "ܕܗܝܢܐ",
            id: "3369",
            lt12: "",
            sy5: "ܕܰܗܺܝܢܳܐ",
            dt2: "fettig",
            lt11: "meshĥono",
            dt3: "",
            sy7: "ܡܶܫܚܳܢܳܐ",
            lt10: "dahino",
            sy9: "",
            sy6: "ܡܫܚܢܐ"
        },
        {
            dt3: "",
            sy8: "",
            dt2: "",
            sy4: "ܣܘܕܐ",
            sy7: "",
            lt12: "",
            id: "904",
            sy5: "ܣܽܘܳܕܳܐ",
            dt1: "Diskussion",
            sy6: "",
            lt11: "",
            sy9: "",
            lt10: "swodo"
        },
        {
            sy5: "ܝܰܬܝܺܪ ܡܶܢ ܬܠܳܬ݂ܳܐ ܪܽܘܒܥܳܐ ܕܰܒܪܺܝܬ݂ܳܐ",
            lt11: "",
            id: "2987",
            sy7: "",
            dt3: "",
            dt1: "mehr als dreiviertel der Erde",
            sy9: "",
            sy6: "",
            sy4: "ܝܬܝܪ ܡܢ ܬܠܬܐ ܪܘܒܥܐ ܕܒܪܝܬܐ",
            lt12: "",
            dt2: "",
            sy8: "",
            lt10: "yatir men tlotho rubĉo da`britho"
        },
        {
            dt2: "",
            sy7: "",
            sy5: "ܬܠܺܝܬ݂ܳܝܬܳܐ",
            dt3: "",
            lt11: "",
            sy4: "ܬܠܝܬܝܬܐ",
            sy8: "",
            dt1: "Dritte",
            lt12: "",
            lt10: "tlithoyto",
            id: "961",
            sy9: "",
            sy6: ""
        },
        {
            lt12: "",
            sy7: "",
            sy4: "ܬܚܘܡܐ ܕܡܟ",
            sy8: "",
            dt3: "",
            lt11: "",
            sy9: "",
            id: "3042",
            sy5: "ܬܚܽܘܡܳܐ ܕܡܳܟ݂",
            lt10: "tĥumo d`mokh",
            dt1: "minimum",
            dt2: "",
            sy6: ""
        },
        {
            lt12: "",
            sy5: "ܩܰܡܚܳܐ ܕܰܣܥܳܪ̈ܐ",
            dt3: "",
            sy9: "",
            sy4: "ܩܡܚܐ ܕܣܥܪ̈ܐ",
            dt2: "",
            lt10: "qamĥo da`sĉore",
            sy7: "",
            dt1: "Gerstenmehl",
            sy6: "",
            sy8: "",
            id: "1574",
            lt11: ""
        },
        {
            sy9: "",
            lt12: "",
            sy8: "",
            id: "5720",
            dt3: "",
            dt2: "",
            sy6: "ܢܫܛ",
            lt10: "mlağ",
            sy5: "ܡܠܰܓ݂",
            lt11: "nshaț",
            dt1: "abziehen",
            sy7: "ܢܫܰܛ",
            sy4: "ܡܠܓ"
        },
        {
            sy6: "ܢܛܘܪܐ",
            sy8: "",
            dt2: "Wärter",
            dt1: "Wächter",
            sy9: "",
            lt10: "noțro",
            dt3: "",
            sy4: "ܢܛܪܐ",
            sy7: "ܢܳܛܽܘܪܳܐ",
            id: "4921",
            lt12: "",
            sy5: "ܢܳܛܪܳܐ",
            lt11: "noțuro"
        },
        {
            sy6: "",
            sy9: "",
            sy8: "",
            lt10: "barqoqo",
            sy4: "ܒܪܩܩܐ",
            dt3: "",
            sy7: "",
            dt1: "Golf",
            lt11: "",
            dt2: "Meerbusen",
            id: "1681",
            sy5: "ܒܰܪܩܳܩܳܐ",
            lt12: ""
        },
        {
            id: "4964",
            lt12: "",
            dt3: "",
            sy8: "",
            sy5: "ܚܕܳܝܽܘܬ݂ ܙܽܘ̈ܙܶܐ",
            sy7: "",
            lt10: "ĥđoyuth zuze",
            sy4: "ܚܕܝܘܬ ܙܘܙܐ",
            dt2: "",
            lt11: "",
            sy6: "",
            sy9: "",
            dt1: "Währungseinheit"
        },
        {
            dt1: "Dorf Mor Bobo",
            sy9: "",
            sy7: "ܡܳܪܝ̱ ܒܐܒܐ",
            id: "941",
            dt2: "Mor Bobo",
            sy6: "ܡܪܝ ܒܐܒܐ",
            sy5: "ܩܪܺܝܬ݂ܳܐ ܡܳܪܝ̱ ܒܳܐܒܳܐ",
            sy8: "",
            lt10: "qritho mor bobo",
            lt12: "",
            dt3: "",
            lt11: "mor bobo",
            sy4: "ܩܪܝܬܐ ܡܪܝ ܒܐܒܐ"
        },
        {
            sy8: "",
            sy4: "ܩܥܝܐ",
            sy9: "",
            dt2: "Ausrufer",
            sy6: "",
            sy5: "ܩܰܥܝܳܐ",
            lt12: "",
            id: "4032",
            lt11: "",
            dt3: "",
            sy7: "",
            lt10: "qaĉoyo",
            dt1: "Schreier"
        },
        {
            lt10: "mnoth arĉo",
            dt1: "Erdteil",
            sy5: "ܡܢܳܬ݂ ܐܰܪܥܳܐ",
            sy8: "",
            sy9: "",
            lt12: "",
            dt3: "",
            id: "1142",
            sy4: "ܡܢܬ ܐܪܥܐ",
            dt2: "",
            sy6: "",
            sy7: "",
            lt11: ""
        },
        {
            lt12: "",
            sy5: "ܟܺܐܢܳܐ",
            id: "2484",
            sy9: "",
            dt3: "",
            sy4: "ܟܐܢܐ",
            sy6: "",
            sy8: "",
            lt11: "ܟܐܢܐ",
            dt1: "Kino",
            dt2: "",
            lt10: "ܟܐܢܐ",
            sy7: ""
        },
        {
            dt1: "Barscheno",
            sy6: "",
            sy8: "",
            sy9: "",
            lt10: "ܒܪܫܢܐ",
            sy7: "",
            id: "477",
            sy4: "ܒܪܫܢܐ",
            dt3: "",
            dt2: "",
            sy5: "ܒܰܪܫܶܢܳܐ",
            lt11: "ܒܪܫܢܐ",
            lt12: ""
        },
        {
            dt1: "Bettlerin",
            id: "596",
            sy6: "",
            dt3: "",
            sy5: "ܚܳܕܽܘܪܬܳܐ",
            lt10: "ĥođurto",
            sy7: "",
            sy9: "",
            lt11: "",
            dt2: "",
            sy8: "",
            sy4: "ܚܕܘܪܬܐ",
            lt12: ""
        },
        {
            sy4: "ܟܠ ܡܕܡ ܥܒܘܪܐ ܗܘ",
            id: "187",
            dt3: "",
            sy7: "",
            sy6: "",
            sy8: "",
            lt10: "kul medem ĉoburo hu",
            dt1: "Alles ist vergänglich",
            lt12: "",
            sy5: "ܟܽܠ ܡܶܕܶܡ ܥܳܒܽܘܪܳܐ ܗܽܘ",
            sy9: "",
            lt11: "",
            dt2: ""
        },
        {
            id: "2340",
            sy7: "ܝܳܗܽܘܫܳܥ",
            dt2: "Joshua",
            sy6: "ܝܗܘܫܥ",
            dt3: "Yeshua",
            sy9: "",
            dt1: "Josua",
            lt10: "ܗܘܫܥ",
            lt12: "ܝܗܘܫܥ",
            sy8: "",
            lt11: "ܗܘܫܥ",
            sy4: "ܗܘܫܥ",
            sy5: "ܝܶܫܽܘܥ"
        },
        {
            id: "6021",
            sy5: "ܦܰܠܳܚܬܳܐ",
            lt11: "",
            sy9: "",
            dt1: "Arbeiterin",
            sy6: "",
            dt2: "",
            lt12: "",
            sy8: "",
            sy4: "ܦܠܚܬܐ",
            dt3: "",
            sy7: "",
            lt10: "faloĥto"
        },
        {
            dt1: "Haphsai",
            sy6: "",
            sy9: "",
            id: "1870",
            dt3: "",
            lt12: "",
            sy4: "ܚܦܣܝ",
            sy7: "",
            lt10: "ܚܦܣܝ",
            dt2: "",
            sy5: "ܚܰܦܣܰܝ",
            lt11: "ܚܦܣܝ",
            sy8: ""
        },
        {
            sy4: "ܡܪܐ ܐܪܥܬܐ",
            lt10: "more arĉotho",
            sy8: "",
            dt1: "Grundbesitzer",
            lt11: "",
            sy5: "ܡܳܪܳܐ ܐܰܪܥܳܬ݂ܳܐ",
            dt3: "",
            id: "1748",
            sy6: "",
            dt2: "",
            sy9: "",
            lt12: "",
            sy7: ""
        },
        {
            lt10: "kul",
            sy9: "ܡܰܘܙܰܠܬܳܐ",
            dt2: "",
            sy6: "ܒܪܝܬܐ",
            sy8: "ܡܘܙܠܬܐ",
            id: "5833",
            sy7: "ܒܪܺܝܬ݂ܳܐ",
            dt1: "All",
            sy4: "ܟܠ",
            lt12: "mauzalto",
            dt3: "",
            sy5: "ܟܽܠ",
            lt11: "britho"
        },
        {
            lt10: "kaldoyo",
            sy4: "ܟܠܕܝܐ",
            sy5: "ܟܰܠܕܳܝܳܐ",
            dt2: "",
            dt1: "Chaldäer",
            sy7: "ܣܽܘܪܝܳܝܳܐ ܟܰܠܕܳܝܳܐ",
            sy6: "ܣܘܪܝܝܐ ܟܠܕܝܐ",
            dt3: "",
            sy9: "ܣܽܘܪ̈ܝ̣ܳܝܶܐ ܡܰܕܢ̈ܚܳܝܶܐ",
            lt11: "suryoyo kaldoyo",
            lt12: "suryoye madnĥoye",
            id: "760",
            sy8: "ܣܘܪ̈ܝ̣ܝܐ ܡܕܢ̈ܚܝܐ"
        },
        {
            dt2: "",
            id: "3138",
            sy7: "",
            lt11: "",
            lt12: "",
            dt3: "",
            sy8: "",
            sy5: "ܚܽܘܒܳܐ ܕܶܐܡܳܐ",
            dt1: "Mutterliebe",
            sy9: "",
            sy4: "ܚܘܒܐ ܕܐܡܐ",
            lt10: "ĥubo d`emo",
            sy6: ""
        },
        {
            dt3: "",
            lt11: "",
            sy7: "",
            dt1: "Danke sehr",
            sy4: "ܬܘܕܝ ܣܓܝ",
            sy6: "",
            lt10: "taudi sagi",
            sy5: "ܬܰܘܕܺܝ ܣܰܓܺܝ",
            dt2: "",
            sy8: "",
            id: "805",
            sy9: "",
            lt12: ""
        },
        {
            dt3: "",
            lt12: "",
            lt10: "rĥimto",
            sy7: "",
            dt1: "Liebste",
            sy9: "",
            id: "2823",
            sy6: "",
            sy5: "ܪܚܺܝܡܬܳܐ",
            dt2: "",
            sy8: "",
            sy4: "ܪܚܝܡܬܐ",
            lt11: ""
        },
        {
            dt2: "praktizieren",
            sy8: "",
            sy9: "",
            sy5: "ܥܒܳܕܳܐ",
            sy6: "",
            lt12: "",
            dt1: "Praxis",
            lt10: "ĉbodo",
            dt3: "",
            lt11: "",
            id: "3503",
            sy4: "ܥܒܕܐ",
            sy7: ""
        },
        {
            sy7: "ܥܶܘܳܢܳܐ",
            lt12: "",
            id: "1998",
            dt1: "Hilfe",
            dt2: "",
            sy8: "",
            sy5: "ܡܥܳܕܪܳܢܽܘܬ݂ܳܐ",
            lt11: "ĉewono",
            dt3: "",
            lt10: "mĉadronutho",
            sy4: "ܡܥܕܪܢܘܬܐ",
            sy6: "ܥܘܢܐ",
            sy9: ""
        },
        {
            sy8: "",
            lt11: "",
            id: "5912",
            dt2: "Hackerfeld",
            dt1: "Anbaufeld",
            sy7: "",
            lt12: "",
            sy5: "ܚܰܩܠܳܐ ܕܰܙܪܳܥܳܐ",
            sy6: "",
            sy4: "ܚܩܠܐ ܕܙܪܥܐ",
            sy9: "",
            lt10: "ĥaqlo dazroĉo",
            dt3: ""
        },
        {
            sy9: "",
            sy6: "",
            id: "807",
            sy4: "ܕܩܠܝܬܐ",
            dt1: "Daqlita",
            lt12: "",
            lt10: "ܕܩܠܝܬܐ",
            dt3: "",
            sy5: "ܕܰܩܠܺܝܬܰܐ",
            lt11: "ܕܩܠܝܬܐ",
            sy8: "",
            dt2: "",
            sy7: ""
        },
        {
            lt10: "agugo da`shmoĉo",
            sy9: "",
            sy6: "",
            sy5: "ܐܰܓܽܘܓܳܐ ܕܫܡܳܥܳܐ",
            lt12: "",
            sy8: "",
            dt1: "Ohrkanal",
            id: "3361",
            dt2: "Hörkanal",
            lt11: "",
            sy4: "ܐܓܘܓܐ ܕܫܡܥܐ",
            sy7: "",
            dt3: ""
        },
        {
            dt1: "Erste Klasse",
            id: "1174",
            lt10: "seđro qađmoyo",
            sy9: "",
            lt11: "",
            sy6: "",
            dt2: "1 Klasse",
            sy8: "",
            sy5: "ܣܶܕܪܳܐ ܩܰܕܡܳܝܳܐ",
            lt12: "",
            sy7: "",
            sy4: "ܣܕܪܐ ܩܕܡܝܐ",
            dt3: ""
        },
        {
            sy5: "ܝܺܠܺܝܕܳܐ ܘܠܳܐ ܥܒܺܝܕܳܐ",
            dt1: "Gezeugt und nicht geschaffen",
            dt2: "",
            sy6: "",
            sy9: "",
            lt11: "",
            id: "1634",
            lt10: "iliđo u lo ĉbiđo",
            lt12: "",
            sy4: "ܝܠܝܕܐ ܘܠܐ ܥܒܝܕܐ",
            sy7: "",
            sy8: "",
            dt3: ""
        },
        {
            sy8: "",
            lt12: "",
            lt10: "finko",
            sy4: "ܦܝܢܟܐ",
            dt3: "",
            id: "3881",
            sy9: "",
            lt11: "sfoqo",
            dt1: "Schale",
            sy7: "ܣܦܳܩܳܐ",
            dt2: "",
            sy5: "ܦܺܝܢܟܳܐ",
            sy6: "ܣܦܩܐ"
        },
        {
            id: "4347",
            lt11: "ܛܒܐܝܠ",
            dt1: "Tabael",
            sy9: "",
            lt12: "",
            sy5: "ܛܰܒܰܐܶܝܠ",
            sy6: "",
            sy4: "ܛܒܐܝܠ",
            dt2: "",
            sy8: "",
            lt10: "ܛܒܐܝܠ",
            sy7: "",
            dt3: ""
        },
        {
            sy7: "",
            sy8: "",
            dt3: "",
            sy6: "",
            sy4: "ܓܐܪܐ",
            sy9: "",
            dt1: "Gero",
            lt12: "",
            sy5: "ܓܶܪܳܐ",
            id: "1573",
            lt11: "ܓܐܪܐ",
            dt2: "",
            lt10: "ܓܐܪܐ"
        },
        {
            sy8: "",
            lt10: "ĥlimo",
            dt2: "heil",
            sy5: "ܚܠܺܝܡܳܐ",
            dt1: "gesund",
            sy7: "",
            lt12: "",
            dt3: "",
            sy9: "",
            lt11: "",
            id: "1604",
            sy6: "",
            sy4: "ܚܠܝܡܐ"
        },
        {
            id: "284",
            sy5: "ܩܶܠܳܝܬܳܐ ܕܦܽܘܠܚܳܢܳܐ",
            sy6: "",
            sy9: "",
            sy8: "",
            dt2: "",
            dt3: "",
            dt1: "Arbeitszimmer",
            sy7: "",
            sy4: "ܩܠܝܬܐ ܕܦܘܠܚܢܐ",
            lt10: "qeloyto d`fulĥono",
            lt12: "",
            lt11: ""
        },
        {
            lt11: "",
            dt2: "",
            lt10: "nobğe",
            sy8: "",
            lt12: "",
            sy7: "",
            dt1: "Enkelkinder",
            sy6: "",
            sy5: "ܢܳܒܓ݂̈ܶܐ",
            sy4: "ܢܒܓܐ",
            id: "1108",
            sy9: "",
            dt3: ""
        },
        {
            lt10: "ilef",
            sy7: "ܕܪܰܫ",
            lt11: "drash",
            sy5: "ܝܺܠܶܦ",
            dt1: "trainieren",
            dt3: "",
            lt12: "",
            dt2: "",
            sy8: "",
            id: "4538",
            sy4: "ܝܠܦ",
            sy6: "ܕܪܫ",
            sy9: ""
        },
        {
            lt12: "",
            sy6: "",
            sy9: "",
            sy8: "",
            id: "5402",
            lt10: "tomo",
            dt1: "Zwilling",
            sy7: "",
            sy4: "ܬܐܡܐ",
            lt11: "",
            dt2: "",
            dt3: "",
            sy5: "ܬܳܐܡܳܐ"
        },
        {
            sy4: "ܓܒܐ",
            dt3: "",
            sy6: "",
            dt1: "Seite",
            lt10: "gabo",
            lt11: "",
            sy5: "ܓܰܒܳܐ",
            sy8: "",
            id: "4104",
            dt2: "Partei",
            lt12: "",
            sy9: "",
            sy7: ""
        },
        {
            lt10: "d`lo motho",
            sy6: "",
            lt11: "",
            sy9: "",
            sy4: "ܕܠܐ ܡܬܐ",
            sy7: "",
            id: "1934",
            sy8: "",
            dt1: "heimatlos",
            sy5: "ܕܠܳܐ ܡܳܬ݂ܳܐ",
            dt3: "",
            dt2: "",
            lt12: ""
        },
        {
            sy6: "",
            sy5: "ܒܝܰܐܢܳܐ",
            sy8: "",
            dt1: "Klavier",
            dt2: "Piano",
            sy7: "",
            sy9: "",
            lt10: "byano",
            lt11: "",
            dt3: "",
            sy4: "ܒܝܐܢܐ",
            id: "2502",
            lt12: ""
        },
        {
            sy4: "ܚܦܪ",
            dt1: "graben",
            id: "1720",
            sy8: "",
            sy9: "",
            sy5: "ܚܦܰܪ",
            lt10: "ĥfar",
            dt2: "schaufeln",
            sy6: "",
            lt12: "",
            dt3: "buddeln",
            lt11: "",
            sy7: ""
        },
        {
            dt3: "",
            sy7: "",
            lt10: "bĉeldbobo d`neshe",
            lt11: "",
            sy6: "",
            sy9: "",
            id: "1368",
            sy4: "ܒܥܠܕܒܒܐ ܕܢܫܐ",
            lt12: "",
            dt1: "Frauenfeind",
            dt2: "",
            sy5: "ܒܥܶܠܕܒܳܒܳܐ ܕܢܶܫ̈ܶܐ",
            sy8: ""
        },
        {
            sy9: "",
            lt12: "",
            sy4: "ܒܗܪܬ",
            dt2: "",
            sy5: "ܒܰܗܪܰܬ",
            dt1: "Bahrat",
            id: "422",
            dt3: "",
            lt10: "ܒܗܪܬ",
            sy7: "",
            lt11: "ܒܗܪܬ",
            sy6: "",
            sy8: ""
        },
        {
            lt12: "",
            lt11: "ĥlitho",
            id: "4329",
            lt10: "ĥalyo",
            dt1: "süss",
            sy4: "ܚܠܝܐ",
            sy8: "",
            sy7: "ܚܠܺܝܬ݂ܳܐ",
            sy9: "",
            sy5: "ܚܰܠܝܳܐ",
            dt2: "",
            sy6: "ܚܠܝܬܐ",
            dt3: ""
        },
        {
            sy8: "",
            id: "4897",
            sy9: "",
            dt1: "Vorbestellung",
            sy6: "",
            lt12: "",
            lt11: "",
            dt2: "Vorausbestellung",
            lt10: "fuqođo d`menqđim",
            dt3: "",
            sy4: "ܦܘܩܕܐ ܕܡܢܩܕܝܡ",
            sy7: "",
            sy5: "ܦܽܘܩܳܕܳܐ ܕܡܶܢܩܕܺܝܡ"
        },
        {
            sy8: "",
            sy5: "ܛܽܘܪܰܝܬܰܐ",
            sy9: "",
            dt3: "",
            sy6: "",
            lt10: "ܛܘܪܝܬܐ",
            dt2: "",
            lt12: "",
            sy4: "ܛܘܪܝܬܐ",
            lt11: "ܛܘܪܝܬܐ",
            dt1: "Turayta",
            sy7: "",
            id: "4597"
        },
        {
            sy4: "ܝܗܒܐܠܗܐ",
            sy9: "",
            dt3: "",
            id: "2262",
            sy6: "ܝܗܒܐܠܗܐ",
            lt12: "ܝܗܒܐܠܗܐ",
            sy5: "ܝܰܗܒܐܰܠܳܗܳܐ",
            dt1: "Jabaloho",
            sy8: "",
            lt11: "ܝܗܒܐܠܗܐ",
            lt10: "ܝܗܒܐܠܗܐ",
            sy7: "ܝܰܗܒܐܰܠܰܗܰܐ",
            dt2: ""
        },
        {
            sy8: "",
            dt3: "",
            lt11: "",
            id: "2681",
            sy6: "",
            sy4: "ܣܦܪ ܝܡܐ",
            lt12: "",
            dt1: "Küste",
            lt10: "sfor yamo",
            dt2: "Strand",
            sy5: "ܣܦܳܪ ܝܰܡܳܐ",
            sy7: "",
            sy9: ""
        },
        {
            lt12: "ܒܐܪܝܐ",
            lt11: "ܒܪܝܐ",
            dt2: "",
            sy5: "ܒܶܪܰܝܰܐ",
            id: "552",
            sy7: "ܒܶܐܪܰܝܰܐ",
            sy6: "ܒܐܪܝܐ",
            sy9: "",
            dt3: "",
            sy8: "",
            lt10: "ܒܪܝܐ",
            dt1: "Beraja",
            sy4: "ܒܪܝܐ"
        },
        {
            dt1: "Geruch",
            dt3: "",
            lt12: "",
            sy6: "ܪܐܚܐ",
            dt2: "",
            sy9: "",
            sy5: "ܪܺܝܚܳܐ",
            sy8: "",
            lt11: "roĥo",
            id: "1575",
            sy4: "ܪܝܚܐ",
            lt10: "riĥo",
            sy7: "ܪܳܐܚܳܐ"
        },
        {
            lt12: "",
            sy7: "",
            sy4: "ܡܪܟܒܬܐ",
            dt1: "Fahrzeug",
            sy9: "",
            sy8: "",
            id: "1242",
            sy5: "ܡܰܪܟܰܒܬ݂ܳܐ",
            dt2: "",
            lt11: "",
            dt3: "",
            lt10: "markabtho",
            sy6: ""
        },
        {
            lt12: "",
            sy7: "",
            sy9: "",
            sy5: "ܝܰܪܳܩܳܐ",
            sy6: "",
            lt10: "yaroqo",
            sy8: "",
            dt3: "",
            dt2: "",
            dt1: "grün",
            id: "1746",
            lt11: "",
            sy4: "ܝܪܩܐ"
        },
        {
            dt1: "Neffe",
            sy6: "ܐܒܪܐ ܕܐܚܘܢܐ",
            sy5: "ܒܰܪ ܐܰܚܳܐ",
            lt12: "",
            sy8: "",
            sy9: "",
            dt3: "",
            dt2: "",
            id: "3232",
            sy4: "ܒܪ ܐܚܐ",
            sy7: "ܐܰܒܪܳܐ ܕܐܰܚܽܘܢܳܐ",
            lt11: "abro d`aĥuno",
            lt10: "bar aĥo"
        },
        {
            lt10: "Taudi dat iythayk ",
            dt2: "",
            sy7: "",
            sy9: "",
            sy6: "",
            dt3: "",
            id: "803",
            sy4: "ܬܘܕܝ ܕܐܢܬ ܐܝܬܝܟ",
            sy5: "ܬܰܘܕܺܝ ܕܰܐܢ̱ܬ ܐܺܝܬ݂ܰܝܟ",
            sy8: "",
            lt11: "",
            lt12: "",
            dt1: "Danke dass es dich gibt"
        },
        {
            sy4: "ܣܥܕܐ",
            dt2: "Sado",
            dt1: "Sahdo",
            sy7: "",
            lt11: "ܣܥܕܐ",
            id: "3805",
            dt3: "",
            sy8: "",
            sy5: "ܣܰܥܕܳܐ",
            lt10: "ܣܥܕܐ",
            sy6: "",
            lt12: "",
            sy9: ""
        },
        {
            dt2: "",
            sy5: "ܟܰܘܟܒܳܐ",
            lt12: "",
            dt3: "",
            sy4: "ܟܘܟܒܐ",
            sy9: "",
            sy8: "",
            id: "2443",
            lt10: "ܟܘܟܒܐ",
            sy6: "",
            lt11: "ܟܘܟܒܐ",
            sy7: "",
            dt1: "Kawkbo"
        },
        {
            sy9: "",
            dt3: "",
            sy6: "",
            lt11: "",
            sy8: "",
            sy5: "ܢܟ݂ܺܝܠܽܘܬ݂ܳܐ",
            id: "4786",
            dt1: "Verrat",
            lt12: "",
            dt2: "",
            lt10: "nkhilutho",
            sy4: "ܢܟܝܠܘܬܐ",
            sy7: ""
        },
        {
            lt10: "ܚܢܢܝܐ",
            lt11: "ܚܢܢܝܐ",
            sy5: "ܚܰܢܰܢܝܰܐ",
            id: "1846",
            sy6: "",
            dt2: "",
            dt1: "Hananya",
            sy9: "",
            sy4: "ܚܢܢܝܐ",
            sy7: "",
            sy8: "",
            lt12: "",
            dt3: ""
        },
        {
            sy7: "",
            dt1: "konkurrierend",
            id: "2584",
            sy6: "",
            sy9: "",
            sy4: "ܡܡܪܝܢܐܝܬ",
            lt12: "",
            dt3: "",
            sy5: "ܡܡܰܪܝܳܢܳܐܝܺܬ݂",
            lt10: "mmaryonith",
            dt2: "",
            lt11: "",
            sy8: ""
        },
        {
            dt2: "",
            id: "106",
            lt10: "mshautofutho",
            dt1: "Abonnement",
            sy8: "",
            lt12: "",
            dt3: "",
            sy7: "",
            sy5: "ܡܫܰܘܬܳܦܽܘܬ݂ܳܐ",
            lt11: "",
            sy6: "",
            sy4: "ܡܫܘܬܦܘܬܐ",
            sy9: ""
        },
        {
            dt2: "",
            id: "6401",
            dt1: "Einzelhändler",
            sy4: "ܬܓܪܐ ܙܥܘܪܐ",
            sy5: "ܬܰܓܳܪܳܐ ܙܥܽܘܪܳܐ",
            lt11: "",
            sy6: "",
            lt10: "tagoro zĉuro",
            sy9: "",
            lt12: "",
            sy7: "",
            sy8: "",
            dt3: ""
        },
        {
            sy9: "",
            lt11: "",
            sy5: "ܓܶܦ̈ܶܐ",
            sy8: "",
            sy6: "",
            lt12: "",
            dt2: "",
            id: "1342",
            lt10: "gefe",
            sy4: "ܓܦܐ",
            sy7: "",
            dt3: "",
            dt1: "Flügel"
        },
        {
            dt1: "Yortuta",
            dt3: "",
            sy7: "",
            sy4: "ܝܪܬܘܬܐ",
            sy5: "ܝܳܪܬܽܘܬܰܐ",
            sy9: "",
            dt2: "",
            lt10: "ܝܬܪܘܬܐ",
            sy8: "",
            sy6: "",
            lt11: "ܝܬܪܘܬܐ",
            id: "5213",
            lt12: ""
        },
        {
            id: "2012",
            sy4: "ܒܬܪ ܡܢ",
            lt11: "",
            lt10: "bothar man",
            sy9: "",
            dt1: "hinter wem",
            sy7: "",
            sy6: "",
            sy5: "ܒܳܬ݂ܰܪ ܡܢ",
            sy8: "",
            dt3: "",
            dt2: "",
            lt12: ""
        },
        {
            sy7: "",
            lt11: "",
            dt1: "Zauberkraft",
            sy4: "ܚܝܠܐ ܕܚܪܫܘܬܐ",
            id: "5261",
            sy5: "ܚܰܝܠܳܐ ܕܚܰܪܳܫܽܘܬ݂ܳܐ",
            lt12: "",
            dt2: "Zaubergewalt",
            lt10: "ĥaylo d`ĥaroshutho",
            sy8: "",
            dt3: "",
            sy9: "",
            sy6: ""
        },
        {
            dt2: "",
            id: "2834",
            lt12: "mtaĥmutho",
            lt10: "ĥartho",
            dt1: "Limit",
            sy5: "ܚܰܪܬ݂ܳܐ",
            sy8: "ܡܬܚܡܘܬ݂ܐ",
            sy6: "ܬܚܘܡܐ",
            lt11: "tĥumo",
            sy9: "ܡܬܰܚܡܽܘܬ݂ܳܐ",
            dt3: "",
            sy7: "ܬܚܽܘܡܳܐ",
            sy4: "ܚܪܬ݂ܐ"
        },
        {
            dt3: "",
            sy6: "",
            id: "2889",
            lt11: "",
            lt12: "",
            lt10: "iyor",
            dt2: "",
            sy7: "",
            dt1: "Mai",
            sy9: "",
            sy8: "",
            sy5: "ܐܺܝܳܪ",
            sy4: "ܐܝܪ"
        },
        {
            lt11: "ܛܒܪܝܡܘܢ",
            dt2: "",
            dt3: "",
            sy8: "",
            sy4: "ܛܒܪܝܡܘܢ",
            dt1: "Tabrimmon",
            sy6: "ܛܒܪܐܡܘܢ",
            sy9: "",
            lt10: "ܛܒܪܝܡܘܢ",
            id: "4355",
            sy5: "ܛܰܒܪܺܝܡܳܘܢ",
            lt12: "ܛܒܪܐܡܘܢ",
            sy7: "ܛܒܰܪܰܐܡܽܘܢ"
        },
        {
            dt1: "Unmenschlichkeit",
            lt11: "",
            lt12: "",
            dt2: "",
            dt3: "",
            lt10: "lo noshutho",
            sy8: "",
            id: "4652",
            sy6: "",
            sy7: "",
            sy4: "ܠܐ ܐܢܫܘܬܐ",
            sy9: "",
            sy5: "ܠܳܐ ܐ݂ܢܳܫܽܘܬ݂ܳܐ"
        },
        {
            sy8: "ܒܶܝܬ̣ ܢܰܦܛܳܐ",
            sy4: "ܢܘܩܙܬܐ ܕܒܢܙܝܢ",
            lt11: "mashryo d`benzin",
            id: "4378",
            dt2: "Tankstelle",
            lt12: "beth nafțo",
            dt1: "Tankstation",
            sy9: "ܒܶܝܬ̣ ܢܰܦܛܳܐ",
            sy6: "ܡܫܪܝܐ ܕܒܢܙܝܢ",
            lt10: "nuqazto d`benzin",
            dt3: "",
            sy7: "ܡܰܫܪܝܳܐ ܕܒܶܢܙܺܝܢ",
            sy5: "ܢܽܘܩܰܙܬܳܐ ܕܒܶܢܙܺܝܢ"
        },
        {
            id: "4673",
            dt2: "Aufteilung",
            dt3: "Verteilung",
            lt11: "",
            lt12: "",
            sy4: "ܦܘܠܓܐ",
            sy9: "",
            lt10: "fuloğo",
            dt1: "Unterteilung",
            sy5: "ܦܽܘܠܳܓ݂ܳܐ",
            sy8: "",
            sy7: "",
            sy6: ""
        },
        {
            sy5: "ܪܶܓ݂ܠܳܐ ܕܩܰܙܳܐ",
            sy4: "ܪܓܠܐ ܕܩܙܐ",
            dt1: "Gänsefüßchen",
            dt2: "",
            lt11: "",
            sy9: "",
            dt3: "",
            sy7: "",
            id: "1469",
            lt10: "reğlo d`qaso",
            sy6: "",
            sy8: "",
            lt12: ""
        },
        {
            sy6: "",
            sy4: "ܠܒܝܒܬܐ",
            dt3: "",
            lt12: "",
            lt10: "ܠܒܝܒܬܐ",
            sy7: "",
            lt11: "ܠܒܝܒܬܐ",
            sy8: "",
            dt1: "Lbibta",
            sy9: "",
            sy5: "ܠܒܺܝܒܬܰܐ",
            dt2: "",
            id: "2722"
        },
        {
            dt3: "",
            lt12: "",
            sy8: "",
            sy9: "",
            sy7: "ܪܒܺܝܥܳܐ",
            lt11: "rbiĉo",
            lt10: "mrabĉoith",
            sy6: "ܪܒܝܥܐ",
            dt1: "viereckig",
            dt2: "",
            id: "4837",
            sy4: "ܡܪܒܥܐܝܬ",
            sy5: "ܡܪܰܒܥܳܐܝܺܬ݂"
        },
        {
            lt11: "shuĥno",
            sy8: "ܫܚܝܢܘܬܐ",
            sy9: "ܫܰܚܺܝܢܽܘܬ݂ܳܐ",
            dt3: "",
            lt12: "shaĥinutho",
            dt1: "Temperatur",
            sy4: "ܕܪܓ ܚܡܝܡܘܬܐ",
            sy6: "ܫܘܚܢܐ",
            sy5: "ܕܪܶܓ݂ ܚܰܡܺܝܡܽܘܬ݂ܳܐ",
            sy7: "ܫܽܘܚܢܳܐ",
            lt10: "dreğ ĥamimutho",
            id: "4433",
            dt2: ""
        },
        {
            lt12: "",
            dt1: "Frauenmagazin",
            sy4: "ܡܓܠܬ ܢܫܐ",
            id: "1373",
            sy9: "",
            sy6: "",
            sy7: "",
            sy8: "",
            lt10: "mğalath neshe",
            dt3: "",
            lt11: "",
            dt2: "Frauenzeitschrift",
            sy5: "ܡܓ݂ܰܠܰܬ݂ ܢܶܫ̈ܶܐ"
        },
        {
            id: "3615",
            dt2: "Ratschlag",
            lt11: "melko",
            sy6: "ܡܠܟܐ",
            lt10: "molukhutho",
            sy9: "",
            sy7: "ܡܶܠܟܳܐ",
            sy8: "",
            dt1: "Rat",
            sy4: "ܡܠܘܟܘܬܐ",
            dt3: "",
            lt12: "",
            sy5: "ܡܳܠܽܘܟ݂ܽܘܬ݂ܳܐ"
        },
        {
            sy8: "",
            lt10: "teshmeshto aminto",
            sy6: "",
            dt1: "kontinuierlicher Dienst",
            sy5: "ܬܶܫܡܶܫܬܳܐ ܐܰܡܺܝܢܬܳܐ",
            sy4: "ܬܫܡܫܬܐ ܐܡܝܢܬܐ",
            dt3: "",
            lt12: "",
            sy7: "",
            lt11: "",
            id: "2591",
            dt2: "",
            sy9: ""
        },
        {
            lt10: "ܬܪܙܐ",
            sy4: "ܬܪܙܐ",
            lt12: "",
            sy8: "",
            sy5: "ܬܰܪܙܳܐ",
            dt1: "Tarso",
            dt2: "Tarzo",
            dt3: "Terzo",
            sy7: "",
            sy6: "",
            id: "4391",
            sy9: "",
            lt11: "ܬܪܙܐ"
        },
        {
            sy6: "",
            sy9: "",
            sy7: "",
            sy5: "ܨܒܽܘܬ݂ܳܐ",
            id: "5789",
            sy4: "ܨܒܘܬܐ",
            dt2: "",
            lt10: "ŝbutho",
            dt3: "",
            lt12: "",
            sy8: "",
            dt1: "Affäre",
            lt11: ""
        },
        {
            dt1: "Vater vergib ihnen denn sie wissen nicht was sie tun",
            sy6: "",
            lt10: "Abo shbuk lhun lo ger yođĉin mono ĉobdin",
            id: "5428",
            sy9: "",
            sy7: "",
            dt3: "",
            sy8: "",
            lt12: "",
            sy4: "ܐܰܒܳܐ ܫܒܽܘܩ ܠܗܽܘܢ ܠܳܐ ܓܶܝܪ ܝܳܕܥܺܝܢ ܡܳܢܳܐ ܥܳܒܕܺܝܢ",
            sy5: "ܐܰܒܳܐ ܫܒܽܘܩ ܠܗܽܘܢ ܠܳܐ ܓܶܝܪ ܝܳܕܥܺܝܢ ܡܳܢܳܐ ܥܳܒܕܺܝܢ",
            dt2: "",
            lt11: ""
        },
        {
            sy6: "",
            lt11: "ܬܐܘܡܐܣ",
            sy4: "ܬܐܘܡܐܣ",
            sy5: "ܬܳܐܘܡܰܐܣ",
            sy9: "",
            dt3: "",
            lt12: "",
            dt2: "Tomas",
            id: "4467",
            lt10: "ܬܐܘܡܐܣ",
            sy7: "",
            dt1: "Thomas",
            sy8: ""
        },
        {
            sy6: "ܣܦܐܝܐ",
            lt10: "ܙܦܝܐ",
            sy7: "ܣܦܐܝܐ",
            sy5: "ܣܦܝܐ",
            sy8: "",
            id: "4200",
            sy4: "ܙܦܝܐ",
            lt12: "ܣܦܐܝܐ",
            sy9: "",
            lt11: "ܙܦܝܐ",
            dt2: "Sofia",
            dt1: "Sophia",
            dt3: ""
        },
        {
            dt1: "von unten",
            dt2: "",
            sy5: "ܡܶܢ ܠܬܰܚܬ",
            lt10: "men l`taĥt",
            sy4: "ܡܢ ܠܬܚܬ",
            sy7: "",
            sy8: "",
            dt3: "",
            lt11: "",
            lt12: "",
            id: "4887",
            sy6: "",
            sy9: ""
        },
        {
            dt1: "Herzanfall",
            sy9: "",
            lt10: "shetqo d`lebo",
            sy6: "",
            sy4: "ܫܬܩܐ ܕܠܒܐ",
            sy5: "ܫܶܬܩܳܐ ܕܠܶܒܳܐ",
            dt3: "",
            sy7: "",
            lt12: "",
            lt11: "",
            id: "1980",
            sy8: "",
            dt2: ""
        },
        {
            dt1: "Schufnin",
            sy5: "ܫܽܘܦܢܺܝܢ",
            dt2: "",
            sy6: "",
            sy7: "",
            lt11: "ܫܘܦܢܝܢ",
            sy8: "",
            dt3: "",
            lt10: "ܫܘܦܢܝܢ",
            lt12: "",
            id: "4038",
            sy4: "ܫܘܦܢܝܢ",
            sy9: ""
        },
        {
            sy9: "",
            sy5: "ܐܶܫܰܥܝܳܐ",
            id: "1188",
            dt3: "",
            lt11: "ܐܫܥܝܐ",
            dt2: "",
            sy8: "",
            sy7: "",
            sy6: "",
            sy4: "ܐܫܥܝܐ",
            dt1: "Eschahjo",
            lt12: "",
            lt10: "ܐܫܥܝܐ"
        },
        {
            sy6: "ܩܠܘܩܘܬܐ",
            id: "3941",
            dt2: "",
            dt1: "Schielen",
            sy7: "ܩܰܠܽܘܩܽܘܬ݂ܳܐ",
            lt10: "țaruzutho",
            sy4: "ܛܪܘܙܘܬܐ",
            lt11: "qaluqutho",
            lt12: "",
            sy5: "ܛܰܪܽܘܙܽܘܬ݂ܳܐ",
            dt3: "",
            sy9: "",
            sy8: ""
        },
        {
            dt1: "nötig",
            dt3: "",
            sy9: "",
            dt2: "notwendig",
            sy6: "ܣܢܝܩܐ",
            sy8: "",
            sy5: "ܙܳܕܶܩ",
            lt10: "zodeq",
            lt12: "",
            id: "3300",
            lt11: "sniqo",
            sy7: "ܣܢܺܝܩܳܐ",
            sy4: "ܙܕܩ"
        },
        {
            sy6: "ܚܢܘܢܐ",
            lt10: "ܚܢܘܢ",
            dt2: "Hanun",
            sy9: "",
            lt12: "ܚܢܘܢܐ",
            id: "1869",
            lt11: "ܚܢܘܢ",
            sy4: "ܚܢܘܢ",
            dt1: "Hanoun",
            sy8: "",
            dt3: "Hanen",
            sy7: "ܚܰܢܳܘܢܳܐ",
            sy5: "ܚܰܢܽܘܢ"
        },
        {
            dt2: "",
            lt12: "",
            lt10: "hadom hadom",
            sy8: "",
            id: "6246",
            dt3: "",
            lt11: "",
            sy7: "",
            sy6: "",
            dt1: "Stück für Stück",
            sy4: "ܗܕܡ ܗܕܡ",
            sy9: "",
            sy5: "ܗܰܕܳܡ ܗܰܕܳܡ"
        },
        {
            sy8: "",
            lt11: "bartho d`ĥotho",
            sy7: "ܒܰܪܬ݂ܳܐ ܕܚܳܬ݂ܳܐ",
            sy9: "",
            sy5: "ܒܰܪܬ݂ܳܐ ܕܰܐܚܽܘܢܳܐ",
            sy4: "ܒܪܬܐ ܕܐܚܘܢܐ",
            lt12: "",
            sy6: "ܒܪܬܐ ܕܚܬܐ",
            lt10: "bartho d`aĥuno",
            dt2: "",
            id: "3266",
            dt3: "",
            dt1: "Nichte"
        },
        {
            lt11: "",
            dt1: "Geheimschrift",
            lt10: "kthibto rozonoyto",
            sy9: "",
            sy7: "",
            dt3: "",
            sy4: "ܟܬܝܒܬܐ ܐܪܙܢܝܬܐ",
            sy6: "",
            lt12: "",
            dt2: "",
            sy5: "ܟܬܝܒܬܳܐ ܐܪܙܢܝܬܳܐ",
            id: "1533",
            sy8: ""
        },
        {
            lt11: "yibamtho",
            sy8: "",
            sy6: "ܝܒܡܬܐ",
            dt2: "",
            lt12: "",
            sy4: "ܒܪܬ ܚܡܬܐ",
            dt3: "",
            sy7: "ܝܺܒܰܡܬ݂ܳܐ",
            dt1: "Schwägerin",
            sy9: "",
            lt10: "bath ĥmotho",
            sy5: "ܒܰܪ̱ܬ݂ ܚܡܳܬ݂ܳܐ",
            id: "4063"
        },
        {
            lt11: "ܝܒܠܐ",
            lt10: "ܝܒܠܐ",
            sy5: "ܝܰܒܠܳܐ",
            sy7: "",
            dt1: "Yablo",
            dt2: "",
            sy4: "ܝܒܠܐ",
            id: "5183",
            lt12: "",
            sy8: "",
            dt3: "",
            sy9: "",
            sy6: ""
        },
        {
            sy6: "",
            dt1: "dubios",
            lt10: "methkashlono",
            sy4: "ܡܬܟܫܠܢܐ",
            lt12: "",
            sy9: "",
            sy5: "ܡܶܬ݂ܟܰܫܠܳܢܳܐ",
            sy7: "",
            dt2: "",
            dt3: "",
            sy8: "",
            lt11: "",
            id: "6445"
        },
        {
            id: "4733",
            sy4: "ܡܬܛܥܝܢܘܬܐ",
            sy6: "ܛܥܝܐ",
            dt1: "Vergessen",
            dt3: "",
            sy8: "ܡܢܫܝܢܘܬܐ",
            lt12: "mnashyonutho",
            sy7: "ܛܥܳܝܳܐ",
            lt11: "țĉoyo",
            lt10: "methțaĉyonutho",
            dt2: "Vergessenheit",
            sy5: "ܡܶܬ݂ܛܰܥܝܳܢܽܘܬ݂ܳܐ",
            sy9: "ܡܢܰܫܝܳܢܽܘܬ݂ܳܐ"
        },
        {
            lt11: "ܡܛܪܬܐ",
            lt12: "",
            sy4: "ܡܛܪܬܐ",
            sy5: "ܡܰܛܰܪܬܰܐ",
            sy6: "",
            sy7: "",
            dt3: "",
            dt1: "Matarta",
            sy9: "",
            lt10: "ܡܛܪܬܐ",
            id: "2965",
            dt2: "",
            sy8: ""
        },
        {
            lt10: "kul ĉedon",
            sy9: "",
            lt11: "",
            sy6: "",
            id: "2293",
            dt2: "jedesmal",
            sy4: "ܟܠ ܥܕܢ",
            sy5: "ܟܽܠ ܥܶܕܳܢ",
            dt1: "jederzeit",
            sy8: "",
            dt3: "",
            lt12: "",
            sy7: ""
        },
        {
            dt1: "Privatleben",
            sy8: "",
            sy4: "ܚܝܐ ܕܝܠܢܝܐ",
            sy7: "",
            lt10: "ĥaye dilonoyo",
            id: "3515",
            dt2: "",
            lt11: "",
            sy9: "",
            sy6: "",
            lt12: "",
            dt3: "",
            sy5: "ܚܰܝ̈ܶܐ ܕܺܝܠܳܢܳܝܳܐ"
        },
        {
            dt2: "",
            sy8: "",
            lt10: "shdo",
            id: "5707",
            dt1: "abwerfen",
            sy9: "",
            sy7: "",
            sy6: "",
            lt11: "",
            sy5: "ܫܕܳܐ",
            dt3: "",
            sy4: "ܫܕܐ",
            lt12: ""
        },
        {
            lt10: "ethtbar",
            lt12: "",
            sy4: "ܐܬܬܒܪ",
            sy8: "",
            sy5: "ܐܶܬ݂ܬܒܰܪ",
            dt2: "",
            dt1: "kaputtgehen",
            lt11: "",
            dt3: "",
            sy6: "",
            sy7: "",
            id: "2410",
            sy9: ""
        },
        {
            sy5: "ܝܶܫܽܘܥ",
            lt12: "",
            sy7: "",
            sy8: "",
            sy4: "ܝܫܘܥ",
            dt1: "Jesus",
            lt11: "ܝܫܘܥ",
            sy9: "",
            id: "2319",
            sy6: "",
            dt2: "",
            dt3: "",
            lt10: "ܝܫܘܥ"
        },
        {
            dt1: "Student",
            sy8: "ܐܣܟܘܠܝܐ",
            dt2: "",
            sy7: "ܒܰܪ ܝܽܘܠܦܳܢܳܐ",
            sy9: "ܐܶܣܟܽܘܠܳܝܳܐ",
            dt3: "",
            id: "6245",
            lt11: "bar yulfono",
            sy5: "ܝܳܠܽܘܦܳܐ",
            lt10: "yolufo",
            lt12: "eskuloyo",
            sy6: "ܒܪ ܝܘܠܦܢܐ",
            sy4: "ܝܠܘܦܐ"
        },
        {
            dt3: "",
            sy7: "ܣ ",
            lt11: "samkath",
            dt2: "Sechzig",
            lt10: "shtin",
            dt1: "60",
            sy4: "ܫܬܝܢ",
            sy6: "ܣ ",
            lt12: "",
            id: "34",
            sy8: "",
            sy9: "",
            sy5: "ܫܬܺܝܢ "
        },
        {
            id: "2493",
            dt1: "Kirsche",
            lt12: "",
            sy5: "ܩܰܪܶܣܺܝܰܐ",
            lt10: "qaresiya",
            sy7: "",
            sy4: "ܩܪܣܝܐ",
            sy8: "",
            sy9: "",
            sy6: "",
            dt2: "",
            dt3: "",
            lt11: ""
        },
        {
            dt2: "",
            sy6: "",
            dt1: "Altertum",
            sy8: "",
            lt11: "",
            lt10: "shithonoyutho",
            sy9: "",
            id: "5864",
            sy5: "ܫܺܝܬ݂ܳܢܳܝܽܘܬ݂ܳܐ",
            dt3: "",
            sy7: "",
            lt12: "",
            sy4: "ܫܝܬܢܝܘܬܐ"
        },
        {
            id: "202",
            lt10: "yaumo ĥroyo",
            lt12: "",
            sy4: "ܝܘܡܐ ܐܚܪܝܐ",
            dt1: "am letzten Tag",
            sy6: "",
            sy8: "",
            dt2: "letzter Tag",
            sy9: "",
            sy5: "ܝܰܘܡܳܐ ܐ̱ܚܪܳܝܳܐ",
            lt11: "",
            sy7: "",
            dt3: ""
        },
        {
            id: "1820",
            lt10: "bar barđo",
            sy8: "",
            lt11: "",
            dt1: "Hagelkorn",
            sy6: "",
            sy5: "ܒܰܪ ܒܰܪܕܳܐ",
            sy9: "",
            lt12: "",
            sy7: "",
            dt3: "",
            dt2: "",
            sy4: "ܒܪ ܒܪܕܐ"
        },
        {
            lt11: "ܕܐܢ",
            sy6: "ܕܐܢ",
            sy5: "ܕܰܐܢ",
            dt1: "Dan",
            sy7: "ܕܳܐܢ",
            id: "796",
            dt2: "Don",
            sy4: "ܕܐܢ",
            lt12: "ܕܐܢ",
            sy9: "",
            sy8: "",
            dt3: "",
            lt10: "ܕܐܢ"
        },
        {
            lt11: "",
            lt10: "nukhroyto",
            sy4: "ܢܘܟܪܝܬܐ",
            dt2: "",
            id: "6100",
            sy5: "ܢܽܘܟ݂ܪܳܝܬܳܐ",
            dt1: "Fremdin",
            lt12: "",
            sy8: "",
            sy7: "",
            sy6: "",
            sy9: "",
            dt3: ""
        },
        {
            sy8: "",
            id: "5549",
            dt1: "abbilden",
            sy9: "",
            sy4: "ܦܢܟ",
            lt11: "nŝakh",
            lt12: "",
            lt10: "fanek",
            sy7: "ܢܣܰܟ݂",
            sy5: "ܦܰܢܶܟ",
            dt3: "",
            dt2: "",
            sy6: "ܢܣܟ"
        },
        {
            sy9: "",
            dt2: "",
            lt10: "ĉenbay arĉo",
            sy6: "",
            sy4: "ܥܢܒܝ ܐܪܥܐ",
            sy5: "ܥܶܢܒܰܝ ܐܰܪܥܳܐ",
            id: "5853",
            lt11: "",
            dt3: "",
            dt1: "Alraun",
            sy8: "",
            lt12: "",
            sy7: ""
        },
        {
            lt12: "",
            id: "4540",
            lt11: "",
            dt3: "",
            lt10: "demĉe",
            sy5: "ܕܶܡܥܶܐ",
            sy4: "ܕܡܥܐ",
            sy8: "",
            sy6: "",
            sy9: "",
            dt1: "Tränen",
            dt2: "",
            sy7: ""
        },
        {
            dt2: "Elishbah",
            lt11: "ܐܠܝܫܒܥ",
            sy8: "",
            sy7: "",
            dt3: "",
            dt1: "Elisabeth",
            sy5: "ܐܶܠܺܝܫܒܰܥ",
            sy9: "",
            lt10: "ܐܠܝܫܒܥ",
            id: "1079",
            sy4: "ܐܠܝܫܒܥ",
            lt12: "",
            sy6: ""
        },
        {
            lt10: "țebo",
            sy7: "ܛܶܒ̈ܶܐ",
            id: "3163",
            sy4: "ܛܒܐ",
            lt12: "",
            sy8: "",
            dt2: "Nachrichten",
            lt11: "țebe",
            sy5: "ܛܶܒܳܐ",
            dt1: "Nachricht",
            sy9: "",
            dt3: "",
            sy6: "ܛܒܐ"
        },
        {
            sy4: "ܟܬܒܐ ܩܕܡܝܐ",
            sy6: "",
            lt12: "",
            sy5: "ܟܬ݂ܳܒܳܐ ܩܰܕܡܳܝܳܐ",
            sy8: "",
            sy9: "",
            lt11: "",
            dt3: "",
            dt2: "",
            sy7: "",
            dt1: "Erstes Buch",
            lt10: "kthobo qadmoyo",
            id: "1177"
        },
        {
            lt11: "ܝܥܩܘܒ",
            dt1: "Jakob",
            sy4: "ܝܥܩܘܒ",
            sy6: "",
            lt10: "ܝܥܩܘܒ",
            dt2: "Yakub",
            id: "2283",
            sy8: "",
            sy7: "",
            dt3: "",
            sy5: "ܝܰܥܩܽܘܒ",
            lt12: "",
            sy9: ""
        },
        {
            lt11: "",
            lt10: "zquth",
            sy6: "",
            dt3: "",
            id: "1",
            sy5: "ܙܩܽܘܬ݂",
            sy4: "ܙܩܘܬ",
            dt1: "0",
            lt12: "",
            sy7: "",
            sy8: "",
            dt2: "Null",
            sy9: ""
        },
        {
            dt1: "Darlehen",
            id: "812",
            sy6: "ܡܫܐܠܢܘܬܐ",
            sy8: "",
            lt10: "mausfonutho",
            sy4: "ܡܘܙܦܢܘܬܐ",
            sy7: "ܡܰܫܶܐܠܳܢܽܘܬ݂ܳܐ",
            lt12: "",
            sy9: "",
            dt3: "",
            dt2: "",
            lt11: "mashelonutho",
            sy5: "ܡܰܘܙܦܳܢܽܘܬ݂ܳܐ"
        },
        {
            sy9: "",
            sy6: "",
            id: "3858",
            dt1: "Saume",
            sy7: "",
            sy8: "",
            lt12: "",
            dt2: "",
            lt11: "ܨܘܡܐ",
            lt10: "ܨܘܡܐ",
            dt3: "",
            sy5: "ܨܰܘܡܶܐ",
            sy4: "ܨܘܡܐ"
        },
        {
            lt10: "shadar",
            sy4: "ܫܕܪ",
            sy6: "",
            dt3: "",
            sy7: "",
            id: "5640",
            sy8: "",
            sy5: "ܫܰܕܰܪ",
            sy9: "",
            lt12: "",
            dt2: "",
            dt1: "abschicken",
            lt11: ""
        },
        {
            dt3: "",
            dt2: "",
            lt10: "ĥsar",
            lt12: "",
            sy5: "ܚܣܰܪ",
            lt11: "ĥosar",
            id: "4760",
            sy4: "ܚܣܪ",
            sy6: "ܚܣܪ",
            sy7: "ܚܳܣܰܪ",
            sy9: "",
            sy8: "",
            dt1: "verlieren"
        },
        {
            lt10: "sheĉyo baroyo",
            id: "115",
            sy7: "",
            lt11: "",
            dt1: "Abseits",
            lt12: "",
            sy9: "",
            dt2: "",
            sy6: "",
            sy4: "ܫܥܝܐ ܒܪܝܐ",
            dt3: "",
            sy5: "ܫܶܥܝܳܐ ܒܰܪܳܝܳܐ",
            sy8: ""
        },
        {
            sy7: "",
            sy9: "",
            lt12: "",
            sy5: "ܢܳܢܥܳܐ",
            sy6: "",
            dt3: "",
            dt2: "",
            sy8: "",
            lt11: "",
            id: "3448",
            lt10: "nonĉo",
            sy4: "ܢܢܥܐ",
            dt1: "Pfefferminz"
        },
        {
            sy9: "",
            id: "3134",
            lt12: "",
            lt10: "emo",
            lt11: "",
            dt1: "Mutter",
            sy5: "ܐܶܡܳܐ",
            dt3: "",
            sy4: "ܐܡܐ",
            sy8: "",
            sy6: "",
            sy7: "",
            dt2: "Mama"
        },
        {
            sy9: "",
            sy7: "",
            lt10: "qurobo ĥroyo",
            sy8: "",
            dt1: "Das letzte Abendmahl",
            dt3: "",
            id: "5441",
            sy5: "ܩܽܘܪܳܒܳܐ ܐ̱ܚܪܳܝܳܐ",
            sy4: "ܩܽܘܪܳܒܳܐ ܐ̱ܚܪܳܝܳܐ",
            lt11: "",
            dt2: "",
            lt12: "",
            sy6: ""
        },
        {
            sy4: "ܡܓܢܢܘܬܐ",
            sy5: "ܡܰܓܢܳܢܽܘܬ݂ܳܐ",
            sy8: "",
            sy6: "ܪܘܚܦܐ",
            dt2: "",
            dt3: "",
            sy7: "ܪܽܘܚܳܦܳܐ",
            lt12: "",
            lt10: "magnonutho",
            lt11: "ruĥofo",
            id: "5664",
            sy9: "",
            dt1: "Absteigen"
        },
        {
            dt2: "",
            id: "2627",
            sy6: "ܚܝܠܘܬܐ",
            lt12: "",
            sy4: "ܚܝܠܐ",
            sy9: "",
            lt11: "ĥaylawotho",
            sy5: "ܚܰܝܠܳܐ",
            dt1: "Kraft",
            lt10: "ĥaylo",
            dt3: "",
            sy8: "",
            sy7: "ܚܰܝܠܰܘ̈ܳܬ݂ܳܐ"
        },
        {
            sy8: "",
            dt1: "Bar Hebraeus",
            lt12: "",
            sy4: "ܐܒܪܐܣ",
            dt3: "Äbreyos",
            sy6: "",
            sy5: "ܒܪ ܐܒܪܐܣ",
            lt11: "ܐܒܪܐܣ",
            sy9: "",
            id: "438",
            dt2: "Bar Hebräus",
            sy7: "",
            lt10: "ܐܒܪܐܣ"
        },
        {
            dt3: "",
            sy6: "",
            sy8: "",
            id: "751",
            sy9: "",
            sy7: "",
            lt12: "",
            dt2: "Bus",
            sy4: "ܒܘܙ",
            sy5: "ܒܽܘܙ",
            lt10: "ܒܘܙ",
            lt11: "ܒܘܙ",
            dt1: "Buz"
        },
        {
            lt11: "gedsho bisho",
            lt12: "",
            sy7: "ܓܶܕܫܳܐ ܒܺܝܫܳܐ",
            dt2: "Tragödie",
            sy8: "",
            dt3: "",
            sy5: "ܓܽܘܢܚܳܐ",
            sy6: "ܓܕܫܐ ܒܝܫܐ",
            lt10: "gunĥo",
            dt1: "Katastrophe",
            sy4: "ܓܘܢܚܐ",
            sy9: "",
            id: "2430"
        },
        {
            lt12: "",
            dt3: "",
            dt1: "Vorteil ziehen",
            sy5: "ܐܰܘܬ݂ܰܪ",
            sy6: "",
            sy7: "",
            id: "4910",
            sy8: "",
            lt11: "",
            dt2: "",
            sy4: "ܐܘܬܪ",
            lt10: "authar",
            sy9: ""
        },
        {
            id: "2450",
            dt3: "",
            sy6: "",
            lt10: "mdaylono",
            lt12: "",
            sy5: "ܡܕܰܝܠܳܢܳܐ",
            sy9: "",
            lt11: "",
            dt2: "Herr Ober",
            sy7: "",
            sy4: "ܡܕܝܠܢܐ",
            dt1: "Kellner",
            sy8: ""
        },
        {
            dt2: "",
            sy4: "ܣܘܪܝܬ",
            sy8: "",
            lt11: "",
            lt10: "surayt",
            dt1: "syrisch",
            id: "4336",
            dt3: "",
            sy5: "ܣܽܘܪܰܝܬ",
            sy7: "",
            sy9: "",
            lt12: "",
            sy6: ""
        },
        {
            sy8: "",
            lt11: "",
            sy7: "",
            dt2: "",
            sy4: "ܨܗܝܘܢܝܘܬܐ",
            lt10: "ŝehyunoyutho",
            dt1: "Zionismus",
            sy5: "ܨܶܗܝܽܘܢܳܝܽܘܬ݂ܳܐ",
            sy6: "",
            lt12: "",
            id: "5334",
            dt3: "",
            sy9: ""
        },
        {
            sy5: "ܡܶܠܺܝܛܰܐ",
            lt10: "ܡܠܝܛܐ",
            dt3: "",
            sy4: "ܡܠܝܛܐ",
            sy9: "",
            lt12: "",
            dt2: "",
            id: "2995",
            sy6: "",
            sy7: "",
            sy8: "",
            lt11: "ܡܠܝܛܐ",
            dt1: "Melita"
        },
        {
            lt11: "",
            dt1: "haushalten",
            dt3: "",
            sy5: "ܦܰܪܢܶܣ ܒܰܝܬܳܐ",
            sy4: "ܦܪܢܣ ܒܝܬܐ",
            dt2: "Haus verwalten",
            sy6: "",
            sy9: "",
            id: "1902",
            lt10: "farnes bayto",
            sy8: "",
            sy7: "",
            lt12: ""
        },
        {
            sy8: "",
            sy5: "ܩܶܠܳܝܬܳܐ ܕܓܽܘܒܳܝܳܐ",
            dt3: "",
            lt10: "qeloyto d`guboyo",
            lt12: "",
            lt11: "",
            dt1: "Wahllokal",
            sy4: "ܩܠܝܬܐ ܕܓܘܒܝܐ",
            sy6: "",
            dt2: "",
            sy7: "",
            id: "4942",
            sy9: ""
        },
        {
            lt10: "manishkoyo",
            dt2: "",
            dt3: "",
            sy6: "",
            dt1: "lila",
            sy4: "ܡܢܝܫܟܝܐ",
            lt12: "",
            sy8: "",
            lt11: "",
            sy7: "",
            sy9: "",
            id: "2832",
            sy5: "ܡܰܢܺܝܫܟܳܝܳܐ"
        },
        {
            sy7: "ܣܪܺܝܡܽܘܬ݂ܳܐ",
            lt10: "usolo",
            sy5: "ܐܽܘܣܳܠܳܐ",
            sy4: "ܐܘܣܠܐ",
            lt12: "",
            dt1: "Stumpf",
            dt3: "",
            sy9: "",
            dt2: "",
            sy6: "ܣܪܝܡܘܬܐ",
            sy8: "",
            id: "6243",
            lt11: "srimutho"
        },
        {
            dt2: "",
            sy7: "",
            sy5: "ܡܰܩܬܳܢܽܘܬ݂ܳܐ",
            id: "5734",
            dt1: "Achtgebung",
            sy9: "",
            dt3: "",
            sy4: "ܡܩܬܢܘܬܐ",
            lt12: "",
            lt11: "",
            sy6: "",
            sy8: "",
            lt10: "maqtonutho"
        },
        {
            sy8: "",
            dt1: "Schwäche",
            id: "6324",
            sy7: "",
            sy6: "",
            dt3: "Kraftlosigkeit",
            lt12: "",
            sy9: "",
            sy4: "ܡܢܦܠܘܬܐ",
            dt2: "Gebrechen",
            lt10: "mnaflutho",
            sy5: "ܡܢܰܦܠܽܘܬ݂ܳܐ",
            lt11: ""
        },
        {
            dt2: "",
            sy7: "",
            dt1: "Hand in Hand",
            lt10: "iđo b`iđo",
            lt11: "",
            sy4: "ܐܝܕܐ ܒܐܝܕܐ",
            sy9: "",
            sy5: "ܐܝܺܕܳܐ ܒܺܐܝܕܳܐ",
            sy8: "",
            dt3: "",
            sy6: "",
            id: "1849",
            lt12: ""
        },
        {
            dt1: "Massaker von Simele",
            lt11: "",
            sy6: "",
            sy9: "",
            lt12: "",
            id: "2961",
            sy4: "ܩܛܠܐ ܕ ܣܝܡܠܐ",
            dt3: "",
            sy5: "ܩܶܛܠܳܐ ܕܣܺܝܡܶܠܶܐ",
            lt10: "qețlo d`simele",
            dt2: "",
            sy7: "",
            sy8: ""
        },
        {
            lt12: "",
            lt11: "zoyoyo",
            lt10: "zowithonoyo",
            dt2: "",
            sy5: "ܙܳܘܺܝܬ݂ܳܢܳܝܳܐ",
            sy9: "",
            sy6: "ܙܘܝܝܐ",
            sy8: "",
            dt1: "eckig",
            id: "996",
            sy7: "ܙܳܘܝܳܝܳܐ",
            dt3: "",
            sy4: "ܙܘܝܬܢܝܐ"
        },
        {
            sy9: "",
            sy5: "ܟܳܬ݂ܶܒ ܐ̱ܢܳܐ ܚܰܕ ܚܽܘܩܳܐ",
            id: "2136",
            dt1: "Ich schreibe eine Zeile",
            sy8: "",
            sy4: "ܟܬܒ ܐܢܐ ܚܕ ܚܘܩܐ",
            dt2: "",
            sy7: "",
            lt12: "",
            dt3: "",
            lt10: "kotheb no ĥađ ĥuqo",
            lt11: "",
            sy6: ""
        },
        {
            sy7: "",
            lt11: "",
            dt3: "",
            sy6: "",
            sy5: "ܒܰܣܺܝܡܳܐܺܝܬ݂",
            sy8: "",
            sy4: "ܒܣܝܡܐܝܬ",
            dt1: "angenehm",
            sy9: "",
            lt10: "basimoith",
            id: "234",
            lt12: "",
            dt2: ""
        },
        {
            sy8: "",
            lt11: "",
            id: "1400",
            sy6: "",
            lt12: "",
            dt2: "Friseur schneidet Haare",
            dt1: "Friseur frisiert Haare",
            dt3: "",
            sy9: "",
            sy7: "",
            sy4: "ܓܪܘܥܐ ܓܪܥ ܣܥܪܐ",
            lt10: "goruĉo goraĉ saĉro",
            sy5: "ܓܳܪܽܘܥܳܐ ܓܳܪܰܥ ܣܰܥܪܳܐ"
        },
        {
            lt10: "kayiwo no",
            id: "2101",
            dt1: "Ich bin krank",
            sy9: "",
            sy4: "ܟܰܐܝܺܘܳܐ ܐܢܐ",
            lt12: "",
            dt3: "",
            lt11: "kriho no",
            sy5: "ܟܰܐܝܺܘܳܐ ܐ̱ܢܳܐ",
            sy6: "ܟܪܝܗܐ ܐܢܐ",
            sy8: "",
            sy7: "ܟܪܺܝܗܳܐ ܐ̱ܢܳܐ",
            dt2: ""
        },
        {
            dt3: "",
            sy6: "",
            sy8: "",
            dt2: "",
            sy4: "ܩܘܦܪܘܣ",
            id: "5413",
            lt11: "",
            lt10: "qufros",
            dt1: "Zypern",
            sy5: "ܩܽܘܦܪܳܘܣ",
            sy7: "",
            sy9: "",
            lt12: ""
        },
        {
            dt2: "",
            id: "2972",
            sy8: "",
            sy7: "ܣܝܳܓ݂ܳܐ",
            sy9: "",
            lt12: "",
            dt1: "Mauer",
            sy4: "ܫܘܪܐ",
            lt10: "shuro",
            sy5: "ܫܽܘܪܳܐ",
            sy6: "ܣܝܓܐ",
            lt11: "syoğo",
            dt3: ""
        },
        {
            dt3: "",
            id: "5099",
            sy8: "",
            sy4: "ܡܬܢܝܢܐܝܬ",
            lt11: "",
            dt1: "wiederholt",
            sy7: "",
            lt10: "mtanyonoith",
            lt12: "",
            sy5: "ܡܬܰܢܝܳܢܳܐܝܺܬ݂",
            dt2: "wiederholend",
            sy9: "",
            sy6: ""
        },
        {
            sy9: "",
            id: "4962",
            sy6: "",
            sy5: "ܩܰܨܽܘܡܽܘܬ݂ܳܐ",
            lt10: "qoŝumutho",
            sy8: "",
            dt3: "",
            sy7: "",
            dt1: "Wahrsagerei",
            sy4: "ܩܨܘܡܘܬܐ",
            dt2: "Omen",
            lt11: "",
            lt12: ""
        },
        {
            dt2: "",
            sy4: "ܓܒܪܐ",
            lt12: "",
            id: "1950",
            dt1: "Held",
            sy6: "",
            dt3: "",
            sy8: "",
            lt10: "gaboro",
            sy7: "",
            lt11: "",
            sy5: "ܓܰܒܳܪܳܐ",
            sy9: ""
        },
        {
            lt11: "",
            lt10: "osyo d'nafsho",
            dt1: "Seelenarzt",
            sy9: "",
            dt3: "",
            sy6: "",
            lt12: "",
            sy4: "ܐܣܝܐ ܕܢܦܫܐ",
            dt2: "",
            sy8: "",
            id: "6216",
            sy5: "ܐܳܣܝܳܐ ܕܢܰܦܫܳܐ",
            sy7: ""
        },
        {
            sy9: "",
            sy4: "ܩܘܪܒܢܐ",
            lt11: "debĥo",
            sy5: "ܩܽܘܪܒܳܢܳܐ",
            dt1: "Opfer",
            sy6: "ܕܒܚܐ",
            dt2: "",
            dt3: "",
            sy7: "ܕܶܒܚܳܐ",
            id: "3378",
            lt12: "",
            lt10: "qurbono",
            sy8: ""
        },
        {
            lt10: "ܚܕܘܬܐ",
            sy5: "ܚܰܕܽܘܬܰܐ",
            sy7: "",
            sy6: "",
            dt1: "Haduta",
            lt11: "ܚܕܘܬܐ",
            sy4: "ܚܕܘܬܐ",
            dt3: "",
            lt12: "",
            id: "1813",
            sy9: "",
            sy8: "",
            dt2: "Hadutha"
        },
        {
            dt3: "",
            id: "2278",
            lt11: "",
            lt10: "ĥago doronoyo",
            sy5: "ܚܰܓܳܐ ܕܳܪܳܢܳܝܳܐ",
            sy8: "",
            dt2: "",
            sy7: "",
            dt1: "Jahrhundertfeier",
            sy6: "",
            lt12: "",
            sy4: "ܚܓܐ ܕܪܢܝܐ",
            sy9: ""
        },
        {
            dt1: "warm",
            lt12: "",
            sy6: "ܫܚܝܢܐ",
            sy4: "ܚܡܐ",
            sy9: "",
            dt3: "",
            sy7: "ܫܰܚܺܝܢܳܐ",
            sy5: "ܚܶܡܳܐ",
            lt10: "ĥemo",
            dt2: "",
            id: "4985",
            lt11: "shaĥino",
            sy8: ""
        },
        {
            sy5: "ܒܰܪ̱ܬܡܳܬ݂ܳܐ",
            lt10: "ܒܪܬܡܬܐ",
            sy9: "",
            sy7: "",
            lt11: "ܒܪܬܡܬܐ",
            lt12: "",
            id: "502",
            sy4: "ܒܪ̱ܬܡܬ݂ܐ",
            dt1: "Bathmotho",
            dt2: "",
            sy8: "",
            dt3: "",
            sy6: ""
        },
        {
            dt1: "untereinander",
            dt2: "",
            lt11: "",
            id: "4660",
            lt12: "",
            dt3: "",
            sy9: "",
            sy6: "",
            sy8: "",
            sy7: "",
            lt10: "baynoth ĥđođe",
            sy5: "ܒܰܝܢܳܬ݂ ܚܕܳܕܶܐ",
            sy4: "ܒܝܢܬ ܚܕܕܐ"
        },
        {
            lt12: "",
            sy9: "",
            sy6: "",
            sy4: "ܛܚܢܐ",
            id: "5310",
            sy7: "",
            lt10: "țĥono",
            sy5: "ܛܚܳܢܳܐ",
            lt11: "",
            dt3: "",
            sy8: "",
            dt2: "Zermahlen",
            dt1: "Zermalmen"
        },
        {
            dt3: "",
            sy4: "ܬܩܣܝ",
            lt11: "",
            dt2: "",
            sy7: "",
            sy9: "",
            id: "4410",
            lt12: "",
            sy5: "ܬܰܩܣܺܝ",
            lt10: "taqsi",
            sy8: "",
            dt1: "Taxi",
            sy6: ""
        },
        {
            dt3: "",
            lt10: "shoef",
            dt2: "",
            sy8: "",
            lt11: "laĥkesh",
            sy4: "ܫܐܦ",
            id: "4294",
            sy5: "ܫܳܐܶܦ",
            dt1: "streicheln",
            lt12: "",
            sy7: "ܠܰܚܟܶܫ",
            sy9: "",
            sy6: "ܠܚܟܫ"
        },
        {
            dt3: "",
            lt11: "ܙܟܝ ܝܘܣܦ",
            sy6: "",
            sy8: "",
            id: "5294",
            dt2: "",
            lt10: "ܙܟܝ ܝܘܣܦ",
            sy7: "",
            lt12: "",
            sy9: "",
            dt1: "Zeki Joseph",
            sy5: "ܙܰܟܺܝ ܝܰܘܣܶܦ",
            sy4: "ܙܟܝ ܝܘܣܦ"
        },
        {
            sy9: "",
            lt10: "ĥțuțo",
            sy6: "",
            sy8: "",
            dt1: "Linie",
            id: "2837",
            dt2: "Zeile",
            lt11: "",
            sy5: "ܚܛܽܘܛܳܐ",
            dt3: "",
            lt12: "",
            sy7: "",
            sy4: "ܚܛܘܛܐ"
        },
        {
            sy8: "",
            lt12: "",
            lt10: "ĉarqubo",
            lt11: "",
            sy9: "",
            dt1: "Fußknöchel",
            sy5: "ܥܰܪܩܽܘܒܳܐ",
            sy7: "",
            dt3: "",
            sy4: "ܥܪܩܘܒܐ",
            sy6: "",
            id: "1443",
            dt2: ""
        },
        {
            sy4: "ܪܘܒܐ",
            id: "2942",
            lt12: "",
            sy9: "",
            dt2: "",
            sy8: "",
            sy6: "",
            dt1: "Marmelade",
            sy7: "",
            sy5: "ܪܽܘܒܳܐ",
            dt3: "",
            lt10: "rubo",
            lt11: ""
        },
        {
            id: "5281",
            sy6: "ܨܝܪ",
            dt1: "zeichnen",
            sy8: "",
            sy5: "ܨܘܰܪ",
            lt12: "",
            dt2: "",
            sy7: "ܨܰܝܰܪ",
            dt3: "",
            lt11: "ŝayar",
            lt10: "ŝwar",
            sy4: "ܨܘܪ",
            sy9: ""
        },
        {
            lt11: "",
            sy8: "",
            sy4: "ܛܚܪ",
            dt3: "",
            dt1: "an Hämorrhoiden leiden",
            id: "5900",
            sy5: "ܛܚܰܪ",
            sy6: "",
            sy9: "",
            lt10: "țĥar",
            dt2: "",
            sy7: "",
            lt12: ""
        },
        {
            dt1: "Zunge",
            sy6: "",
            id: "5372",
            sy9: "",
            lt12: "",
            dt3: "",
            sy8: "",
            dt2: "Sprache",
            lt11: "",
            sy4: "ܠܫܢܐ",
            sy7: "",
            lt10: "leshono",
            sy5: "ܠܶܫܳܢܳܐ"
        },
        {
            id: "79",
            sy8: "ܡܢ ܓܝܪ",
            sy4: "ܐܠܐ",
            lt10: "elo",
            lt12: "men ger",
            sy5: "ܐܶܠܳܐ",
            dt2: "jedoch",
            dt1: "aber",
            sy6: "ܐܢ ܗܘ",
            dt3: "",
            lt11: "en hu",
            sy9: "ܡܶܢ ܓܶܝܪ",
            sy7: "ܐܶܢ ܗܽܘ"
        },
        {
            id: "4450",
            dt1: "Theater",
            sy7: "",
            lt11: "",
            sy5: "ܡܰܣܪܰܚܬܳܐ",
            sy9: "",
            lt12: "",
            sy8: "",
            sy4: "ܡܣܪܚܬܐ",
            dt2: "Schauspiel",
            lt10: "masraĥto",
            sy6: "",
            dt3: "Bühnenstück"
        },
        {
            sy8: "ܥܫܝܢܐ",
            lt10: "ĥaylo",
            dt2: "stark",
            lt11: "ĥlimo",
            sy9: "ܥܰܫܺܝܢܳܐ",
            id: "4233",
            sy5: "ܚܰܝܠܳܐ",
            lt12: "ĉashino",
            dt1: "stabil",
            sy7: "ܚܠܺܝܡܳܐ",
            sy4: "ܚܝܠܐ",
            dt3: "",
            sy6: "ܚܠܝܡܐ"
        },
        {
            lt12: "",
            dt1: "selbstverständlich",
            sy6: "",
            lt11: "",
            dt2: "",
            sy5: "ܡܶܢ ܟܽܠ ܦܪܽܘܣ",
            sy4: "ܡܢ ܟܠ ܦܪܘܣ",
            lt10: "men kul frus",
            dt3: "",
            sy9: "",
            sy7: "",
            sy8: "",
            id: "5612"
        },
        {
            sy7: "ܝܰܬܝܺܪ",
            sy5: "ܣܰܓܺܝ",
            lt10: "sagi",
            sy9: "",
            sy6: "ܝܬܝܪ",
            dt1: "zuviel",
            sy8: "",
            lt12: "",
            sy4: "ܣܓܝ",
            dt3: "",
            lt11: "yatir",
            id: "5386",
            dt2: "zu viel"
        },
        {
            dt3: "",
            dt2: "",
            sy6: "",
            sy4: "ܟܪܬܡ",
            sy9: "",
            lt10: "kartem",
            sy8: "",
            sy7: "",
            sy5: "ܟܰܪܬܶܡ",
            id: "5641",
            lt11: "",
            lt12: "",
            dt1: "Abscheu empfinden"
        },
        {
            sy9: "",
            dt2: "Damaskus",
            sy8: "",
            sy6: "ܫܐܡ",
            sy7: "ܫܐܰܡ",
            id: "4335",
            lt11: "sham",
            dt3: "",
            lt10: "suriya",
            lt12: "",
            sy5: "ܣܽܘܪܺܝܰܐ",
            sy4: "ܣܘܪܝܐ",
            dt1: "Syrien"
        },
        {
            sy5: "ܕܽܘܠܒܰܐ",
            sy8: "",
            lt10: "ܕܘܠܒܐ",
            dt3: "",
            dt1: "Dulba",
            dt2: "",
            sy9: "",
            id: "976",
            sy6: "",
            lt11: "ܕܘܠܒܐ",
            sy7: "",
            sy4: "ܕܘܠܒܐ",
            lt12: ""
        },
        {
            lt11: "",
            sy4: "ܝܘܬܪܢ",
            lt10: "yuthron",
            sy9: "",
            dt3: "",
            dt2: "",
            sy6: "",
            sy8: "",
            sy5: "ܝܽܘܬ݂ܪܳܢ",
            lt12: "",
            sy7: "",
            id: "3326",
            dt1: "Nutzen"
        },
        {
            dt2: "einsiedeln",
            id: "1050",
            sy4: "ܥܢܘܝ",
            sy6: "",
            lt11: "",
            sy5: "ܥܰܢܘܺܝ",
            sy8: "",
            lt10: "ĉanwi",
            sy9: "",
            dt1: "einsam leben",
            sy7: "",
            lt12: "",
            dt3: ""
        },
        {
            sy8: "",
            sy9: "",
            lt10: "wardo",
            sy4: "ܘܪܕܐ",
            lt11: "habobo",
            sy5: "ܘܰܪܕܳܐ",
            dt2: "Pflanze",
            lt12: "",
            dt1: "Blume",
            sy7: "ܗܰܒܳܒܳܐ",
            dt3: "Rose",
            sy6: "ܗܒܒܐ",
            id: "654"
        },
        {
            sy6: "",
            sy9: "",
            dt1: "Überraschungsgast",
            lt12: "",
            sy7: "",
            lt10: "ĉorŝo",
            dt2: "Überraschungsbesuch",
            sy5: "ܥܳܪܨܳܐ",
            id: "4618",
            lt11: "",
            sy4: "ܥܪܨܐ",
            sy8: "",
            dt3: ""
        },
        {
            lt10: "ĉamuqo",
            lt12: "",
            id: "4475",
            sy9: "",
            sy7: "ܥܰܡܺܝܩܳܐ",
            dt2: "",
            dt1: "tief",
            sy5: "ܥܰܡܽܘܩܳܐ",
            dt3: "",
            sy8: "",
            lt11: "ĉamiqo",
            sy6: "ܥܡܝܩܐ",
            sy4: "ܥܡܘܩܐ"
        },
        {
            sy6: "",
            id: "815",
            sy4: "ܬܘܠܥܐ ܕܡܥܝܐ",
            dt1: "Darmparasiten",
            dt2: "Darmwurm",
            lt11: "",
            lt10: "taulĉo da`mĉayo",
            sy8: "",
            sy7: "",
            sy5: "ܬܰܘܠܥܳܐ ܕܡܰܥܝܳܐ",
            lt12: "",
            sy9: "",
            dt3: ""
        },
        {
            lt10: "ܐܓܝܒܘܬܐ",
            sy5: "ܐܰܓܺܝܒܽܘܬܐ",
            sy7: "",
            dt1: "Agibuta",
            dt3: "",
            sy9: "",
            lt12: "",
            dt2: "",
            sy4: "ܐܓܝܒܘܬܐ",
            lt11: "ܐܓܝܒܘܬܐ",
            id: "147",
            sy6: "",
            sy8: ""
        },
        {
            sy8: "",
            dt1: "Nest",
            lt12: "",
            sy4: "ܩܢܐ",
            lt10: "qeno",
            sy7: "",
            id: "3250",
            lt11: "",
            sy9: "",
            dt2: "",
            sy5: "ܩܶܢܳܐ",
            sy6: "",
            dt3: ""
        },
        {
            sy6: "",
            id: "6080",
            sy9: "",
            sy8: "",
            dt2: "",
            dt1: "kondensieren",
            lt10: "qțar",
            lt11: "",
            sy5: "ܩܛܰܪ",
            dt3: "",
            lt12: "",
            sy4: "ܩܛܪ",
            sy7: ""
        },
        {
            sy8: "",
            dt2: "",
            dt1: "Dörfer",
            lt11: "",
            id: "947",
            dt3: "",
            sy5: "ܩܽܘܪ̈ܝܳܐ",
            sy9: "",
            lt10: "quryo",
            sy6: "",
            sy4: "ܩܘܪܝܐ",
            sy7: "",
            lt12: ""
        },
        {
            lt10: "rĉolo",
            lt12: "ruĉolo",
            dt3: "",
            sy7: "ܪܥܺܝܠܽܘܬ݂ܳܐ",
            sy9: "ܪܽܘܥܳܠܳܐ",
            dt1: "Zittern",
            sy4: "ܪܥܠܐ",
            sy8: "ܪܘܥܠܐ",
            id: "5341",
            sy6: "ܪܥܝܠܘܬܐ",
            dt2: "",
            lt11: "rĉilutho",
            sy5: "ܪܥܳܠܳܐ"
        },
        {
            sy9: "",
            id: "2270",
            sy4: "ܨܝܕܐ",
            sy7: "",
            lt10: "ŝayodo",
            dt2: "",
            sy6: "",
            sy5: "ܨܰܝܳܕܳܐ",
            dt3: "",
            sy8: "",
            dt1: "Jäger",
            lt11: "",
            lt12: ""
        },
        {
            sy5: "ܚܽܘܝܳܕܳܐ ܕܣܽܘܪܝܳܝ̈ܶܐ ܒܰܐܠܡܰܢܝܰܐ",
            sy9: "",
            sy8: "",
            dt3: "Föderation Suryoye Deutschland",
            id: "1261",
            sy7: "ܚܽܘܝܳܕܳܐ ܣܽܘܪܝܳܝܳܐ ܒܰܐܠܡܰܢܝܰܐ",
            lt10: "ܚܘܝܕܐ ܕܣܘܪ݁ܝܝܐ ܒܐܠܡܢܝܐ",
            lt11: "ܚܘܝܕܐ ܕܣܘܪ݁ܝܝܐ ܒܐܠܡܢܝܐ",
            sy4: "ܚܘܝܕܐ ܕܣܘܪܝܝܐ ܒܐܠܡܢܝܐ",
            dt2: "Föderation der Aramäer in Deutschland",
            dt1: "FASD",
            lt12: "",
            sy6: "ܚܘܝܕܐ ܕܣܘܪܝܝܐ ܒܐܠܡܢܝܐ"
        },
        {
            dt1: "Salto",
            sy9: "",
            dt3: "",
            sy4: "ܫܘܪܬܐ",
            sy8: "",
            dt2: "",
            id: "3814",
            lt11: "",
            sy5: "ܫܘܳܪܬܳܐ",
            lt10: "shworto",
            lt12: "",
            sy6: "",
            sy7: ""
        },
        {
            dt1: "Ecke",
            lt12: "qarno",
            sy6: "ܓܘܢܝܬܐ",
            sy7: "ܓܽܘܢܺܝܬ݂ܳܐ",
            sy4: "ܙܘܝܬܐ",
            lt10: "sowitho",
            lt11: "gunitho",
            dt3: "",
            sy8: "ܩܪܢܐ",
            dt2: "Kante",
            id: "994",
            sy9: "ܩܰܪܢܳܐ",
            sy5: "ܙܳܘܺܝܬ݂ܳܐ"
        },
        {
            id: "4716",
            sy6: "",
            dt1: "verabredet",
            lt10: "mauĉdo",
            lt12: "",
            dt2: "abgemacht",
            sy4: "ܡܘܥܕܐ",
            sy9: "",
            sy8: "",
            sy5: "ܡܰܘܥܕܳܐ",
            lt11: "",
            dt3: "",
            sy7: ""
        },
        {
            dt1: "Kapitalismus",
            sy5: "ܪܺܝܫܡܳܠܳܝܽܘܬ݂ܳܐ",
            sy6: "",
            lt11: "",
            dt2: "",
            sy7: "",
            lt12: "",
            id: "2406",
            sy8: "",
            sy9: "",
            lt10: "rishmoloyutho",
            sy4: "ܪܝܫܡܠܝܘܬܐ",
            dt3: ""
        },
        {
            lt10: "brek",
            dt3: "",
            lt12: "",
            sy6: "",
            sy4: "ܒܪܟ",
            sy9: "",
            sy5: "ܒܪܶܟ݂",
            sy8: "",
            dt1: "knien",
            id: "2545",
            dt2: "hinknien",
            sy7: "",
            lt11: ""
        },
        {
            sy9: "",
            sy8: "",
            sy6: "ܟܢܟ",
            sy5: "ܦܪܰܥ",
            dt1: "honorieren",
            lt12: "",
            id: "6041",
            lt10: "fraĉ",
            sy4: "ܦܪܥ",
            dt2: "",
            dt3: "",
            lt11: "kanekh",
            sy7: "ܟܰܢܶܟ݂"
        },
        {
            lt12: "",
            dt3: "",
            lt11: "ܝܫܘܥܣܟ݂ܐ",
            lt10: "ܝܫܘܥܣܟ݂ܐ",
            sy5: "ܝܶܫܽܘܥܙܟ݂ܳܐ",
            sy4: "ܝܫܘܥܙܟ݂ܐ",
            id: "2314",
            dt2: "",
            sy8: "",
            dt1: "Jeschuzkho",
            sy7: "",
            sy9: "",
            sy6: ""
        },
        {
            lt12: "",
            dt2: "",
            lt11: "",
            dt3: "",
            sy4: "ܛܪܘܢܐ",
            sy9: "",
            dt1: "Diktator",
            sy5: "ܛܪܽܘܢܳܐ",
            sy7: "",
            sy6: "",
            id: "892",
            lt10: "țruno",
            sy8: ""
        },
        {
            id: "4374",
            sy5: "ܬܰܡܰܢܺܝܬܰܐ",
            sy6: "",
            dt2: "",
            sy8: "",
            sy4: "ܬܡܢܝܬܐ",
            lt12: "",
            dt3: "",
            lt10: "ܬܡܢܝܬܐ",
            sy9: "",
            sy7: "",
            dt1: "Tamanita",
            lt11: "ܬܡܢܝܬܐ"
        },
        {
            dt1: "Journalismus",
            dt3: "",
            lt11: "",
            sy4: "ܓܠܝܘܢܪܘܬܐ",
            sy7: "",
            sy6: "",
            sy8: "",
            sy9: "",
            lt12: "",
            dt2: "",
            lt10: "gelyunorutho",
            id: "2341",
            sy5: "ܓܶܠܝܽܘܢܳܪܽܘܬ݂ܳܐ"
        },
        {
            dt2: "",
            id: "131",
            sy5: "ܐܰܕܺܝܢܰܐ",
            dt1: "Adina",
            sy8: "",
            sy7: "",
            lt12: "",
            dt3: "",
            sy6: "",
            sy9: "",
            lt11: "ܐܕܝܢܐ",
            lt10: "ܐܕܝܢܐ",
            sy4: "ܐܕܝܢܐ"
        },
        {
            lt12: "",
            dt2: "",
            id: "1591",
            sy4: "ܡܒܪܟܐ ܢܗܘܐ ܗܘ ܕܐܬܐ ܒܫܡܗ ܕܡܪܝܐ",
            sy9: "",
            dt3: "",
            lt11: "",
            sy8: "",
            dt1: "gesegnet sei der der da kommt im Namen des Herrn",
            sy7: "",
            sy5: "ܡܒܰܪܟ݂ܳܐ ܢܶܗܘܶܐ ܗܰܘ ܕܳܐܬ݂ܶܐ ܒܰܫܡܶܗ ܕܡܳܪܝܳܐ",
            sy6: "",
            lt10: "mbarkho nehwe hau dothe bashme dmoryo"
        },
        {
            sy8: "",
            id: "4807",
            lt10: "mshaynono",
            sy9: "",
            sy5: "ܡܫܰܝܢܳܢܳܐ",
            lt11: "mraĉyono",
            sy7: "ܡܪܰܥܝܳܢܳܐ",
            dt3: "",
            sy6: "ܡܪܥܝܢܐ",
            lt12: "",
            dt1: "Versöhner",
            dt2: "Friedensstifter",
            sy4: "ܡܫܝܢܢܐ"
        },
        {
            sy8: "",
            id: "4268",
            sy4: "ܣܕܠܐ",
            dt2: "",
            sy5: "ܣܶܕܠܳܐ",
            dt3: "",
            dt1: "Stiefel",
            lt12: "",
            sy7: "",
            sy6: "",
            lt11: "",
            sy9: "",
            lt10: "sedlo"
        },
        {
            dt3: "",
            lt12: "",
            sy4: "ܗܫܝܘܬܐ",
            sy9: "",
            sy7: "",
            id: "173",
            lt10: "hoshoyutho",
            sy6: "",
            dt2: "",
            lt11: "",
            sy5: "ܗܳܫܳܝܽܘܬ݂ܳܐ",
            dt1: "Aktualität",
            sy8: ""
        },
        {
            sy6: "",
            sy4: "ܪܥܝܐ ܫܪܝܪܐ",
            sy5: "ܪܳܥܝܳܐ ܫܰܪܺܝܪܳܐ",
            sy8: "",
            dt3: "",
            dt2: "",
            sy9: "",
            sy7: "",
            lt11: "",
            id: "4956",
            lt10: "roĉyo shariro",
            dt1: "wahrer Hirte",
            lt12: ""
        },
        {
            id: "2694",
            dt1: "Lahmi",
            dt3: "",
            sy8: "",
            lt11: "ܠܚܡܝ",
            sy9: "",
            lt10: "ܠܚܡܝ",
            dt2: "",
            sy6: "",
            sy7: "",
            lt12: "",
            sy4: "ܠܚܡܝ",
            sy5: "ܠܰܚܡܺܝ"
        },
        {
            sy7: "ܐܳܡܶܠܳܐ",
            sy5: "ܦܪܺܝܣܳܐ",
            dt3: "",
            sy8: "",
            lt12: "",
            dt1: "Teppich",
            lt11: "omelo",
            sy9: "",
            sy4: "ܦܪܝܣܐ",
            lt10: "friso",
            sy6: "ܐܡܠܐ",
            dt2: "",
            id: "4436"
        },
        {
            sy4: "ܚܝܠܐ",
            sy6: "",
            dt2: "",
            lt11: "ܚܝܠܐ",
            sy8: "",
            dt3: "",
            dt1: "Hailo",
            sy9: "",
            lt10: "ܚܝܠܐ",
            id: "1825",
            sy5: "ܚܰܝܠܳܐ",
            sy7: "",
            lt12: ""
        },
        {
            sy6: "ܡܰܫܠܡܳܢܳܝܳܐ",
            lt12: "",
            sy8: "",
            dt3: "",
            dt2: "",
            sy9: "",
            sy5: "ܫܶܪܫܳܢܳܝܳܐ",
            sy7: "ܡܰܫܠܡܳܢܳܝܳܐ",
            sy4: "ܫܶܪܫܳܢܳܝܳܐ",
            lt10: "shershonoyo",
            id: "5420",
            lt11: "mashlmonoyo",
            dt1: "Fundamentalist"
        },
        {
            sy8: "",
            dt3: "",
            sy7: "",
            sy5: "ܒܳܘܠܳܘܢܰܐ",
            sy9: "",
            lt11: "ܒܘܠܘܢܐ",
            dt2: "",
            sy6: "",
            dt1: "Bolona",
            sy4: "ܒܘܠܘܢܐ",
            id: "675",
            lt10: "ܒܘܠܘܢܐ",
            lt12: ""
        },
        {
            dt1: "Rednerin",
            sy5: "ܡܰܠܳܠܢܺܝܬ݂ܳܐ",
            sy8: "",
            sy7: "",
            lt12: "",
            dt3: "Ansagerin",
            id: "3653",
            lt10: "mallonitho",
            lt11: "",
            dt2: "Sprecherin",
            sy9: "",
            sy6: "",
            sy4: "ܡܠܠܢܝܬܐ"
        },
        {
            sy8: "",
            sy5: "ܚܰܘܰܐ",
            sy9: "",
            dt2: "",
            sy7: "",
            sy6: "",
            lt12: "",
            dt1: "Hawa",
            id: "1910",
            lt11: "ܚܘܐ",
            sy4: "ܚܘܐ",
            lt10: "ܚܘܐ",
            dt3: ""
        },
        {
            dt2: "",
            sy8: "",
            sy7: "",
            sy4: "ܬܐܡ",
            id: "5404",
            sy5: "ܬܰܐܶܡ",
            sy9: "",
            dt3: "",
            dt1: "Zwillinge gebären",
            lt11: "",
            sy6: "",
            lt12: "",
            lt10: "taem"
        },
        {
            sy9: "",
            id: "3953",
            dt3: "",
            sy8: "",
            sy4: "ܩܡܝܠܐ",
            sy5: "ܩܡܺܝܠܳܐ",
            dt1: "schimmelig",
            lt10: "qmilo",
            sy7: "",
            dt2: "",
            lt11: "",
            sy6: "",
            lt12: ""
        },
        {
            sy9: "",
            sy4: "ܐܢܟܝܕܐ",
            dt2: "",
            dt1: "Enkido",
            sy5: "ܐܶܢܟܺܝܕܳܐ",
            dt3: "",
            sy8: "",
            lt12: "ܐܢܟܝܕܐ",
            sy7: "ܐܰܢܟܺܝܕܰܐ",
            id: "1109",
            lt10: "ܐܢܟܝܕܐ",
            lt11: "ܐܢܟܝܕܐ",
            sy6: "ܐܢܟܝܕܐ"
        },
        {
            dt3: "",
            sy4: "ܐ",
            lt11: "",
            dt2: "",
            dt1: "olaf",
            lt10: "olaf",
            sy7: "",
            sy6: "",
            id: "3367",
            sy8: "",
            lt12: "",
            sy5: "ܐ",
            sy9: ""
        },
        {
            lt12: "",
            lt10: "ĉbađ",
            sy6: "",
            dt1: "anfertigen",
            sy9: "",
            sy4: "ܥܒܕ",
            id: "5951",
            sy8: "",
            dt2: "herstellen",
            dt3: "erstellen",
            lt11: "",
            sy7: "",
            sy5: "ܥܒܰܕ"
        },
        {
            dt3: "",
            lt10: "kul shoĉ",
            sy7: "",
            sy5: "ܟܽܠ ܫܥ",
            lt11: "",
            sy9: "",
            sy4: "ܟܠ ܫܥ",
            lt12: "",
            dt2: "",
            sy8: "",
            sy6: "",
            id: "2289",
            dt1: "jede Stunde"
        },
        {
            id: "4293",
            lt11: "",
            sy8: "",
            sy6: "",
            sy4: "ܓܝܣܐ ܡܫܠܚܢܐ",
            dt2: "",
            sy5: "ܓܰܝܳܣܳܐ ܡܰܫܠܚܳܢܳܐ",
            lt10: "gayoso  mashlĥono",
            dt3: "",
            dt1: "Straßenräuber",
            sy7: "",
            sy9: "",
            lt12: ""
        },
        {
            lt12: "",
            lt11: "",
            sy4: "ܪܫܝܥܘܬܐ",
            sy8: "",
            sy7: "",
            dt2: "",
            sy5: "ܪܰܫܺܝܥܽܘܬ݂ܳܐ",
            lt10: "rashiĉutho",
            dt3: "",
            sy6: "",
            sy9: "",
            id: "1713",
            dt1: "Gottlosigkeit"
        },
        {
            sy4: "ܡܪܝ",
            dt2: "",
            lt10: "ܡܪܝ",
            sy7: "",
            lt11: "ܡܪܝ",
            dt1: "Mari",
            sy6: "",
            sy5: "ܡܰܪܺܝ",
            sy8: "",
            lt12: "",
            id: "2932",
            dt3: "",
            sy9: ""
        },
        {
            sy7: "",
            sy9: "",
            dt2: "",
            sy4: "ܣܓܝܝܘܬܐ",
            dt3: "",
            id: "2988",
            sy6: "",
            lt10: "sagiyutho",
            dt1: "Mehrheit",
            sy5: "ܣܰܓܺܝܝܽܘܬ݂ܳܐ",
            sy8: "",
            lt11: "",
            lt12: ""
        },
        {
            sy8: "",
            dt2: "",
            sy7: "",
            sy9: "",
            sy5: "ܪܳܘܒܳܛ",
            id: "3734",
            dt3: "",
            dt1: "Roboter",
            sy6: "",
            lt11: "",
            lt12: "",
            sy4: "ܪܘܒܛ",
            lt10: "roboț"
        },
        {
            sy8: "",
            dt3: "männliches Geschlecht",
            dt1: "Penis",
            lt12: "",
            lt10: "dekhro",
            lt11: "",
            dt2: "Schwanz",
            sy7: "",
            id: "3431",
            sy4: "ܕܟܪܐ",
            sy6: "",
            sy9: "",
            sy5: "ܕܶܟ݂ܪܳܐ"
        },
        {
            sy4: "ܢܣܒܐ",
            sy5: "ܢܣܳܒܳܐ",
            sy7: "ܢܣܺܝܒܽܘܬ݂ܳܐ",
            dt2: "",
            sy9: "",
            dt1: "Nehmen",
            lt10: "nsobo",
            sy6: "ܢܣܝܒܘܬܐ",
            lt12: "",
            dt3: "",
            lt11: "nsibutho",
            id: "3237",
            sy8: ""
        },
        {
            lt12: "",
            sy4: "ܒܪ̱ܬܡܝܐ",
            sy5: "ܒܰܪ̱ܬܡܰܝܳܐ",
            sy8: "",
            sy9: "",
            lt10: "ܒܪܬܡܝܐ",
            dt3: "",
            sy7: "",
            id: "501",
            lt11: "ܒܪܬܡܝܐ",
            dt1: "Bathmayo",
            dt2: "",
            sy6: ""
        },
        {
            sy9: "",
            lt11: "afe",
            lt10: "fotho",
            sy7: "ܐܰܦܶܐ",
            sy6: "ܐܦܐ",
            dt3: "",
            sy8: "",
            id: "1601",
            lt12: "",
            dt2: "Antlitz",
            sy5: "ܦܳܬ݂ܳܐ",
            dt1: "Gesicht",
            sy4: "ܦܬܐ"
        },
        {
            sy9: "",
            dt3: "",
            sy7: "ܨܰܢܣܽܘܪ",
            sy5: "ܪܶܫܝܳܢܳܐ",
            sy4: "ܪܫܝܢܐ",
            lt10: "reshyono",
            dt1: "Zensur",
            sy6: "ܨܢܣܘܪ",
            dt2: "",
            lt12: "",
            sy8: "",
            lt11: "ŝansur",
            id: "6276"
        },
        {
            sy9: "",
            dt1: "Saro",
            dt2: "",
            sy6: "",
            lt12: "",
            sy8: "",
            dt3: "",
            lt10: "ܣܪܐ",
            sy7: "",
            id: "3848",
            sy5: "ܣܰܪܳܐ",
            sy4: "ܣܪܐ",
            lt11: "ܣܪܐ"
        },
        {
            sy7: "",
            lt11: "",
            dt1: "Polizist",
            dt3: "",
            id: "3485",
            sy9: "",
            lt10: "daĥsho",
            sy6: "",
            lt12: "",
            dt2: "Offizier",
            sy8: "",
            sy5: "ܕܰܚܫܳܐ",
            sy4: "ܕܚܫܐ"
        },
        {
            dt1: "Distrikt",
            sy4: "ܟܘܪܐ",
            dt3: "",
            dt2: "",
            sy9: "",
            sy7: "ܡܰܪܥܺܝܬ݂ܳܐ",
            lt12: "",
            sy6: "ܡܪܥܝܬܐ",
            id: "5494",
            sy5: "ܟܽܘܪܳܐ",
            lt11: "marĉitho",
            lt10: "kuro",
            sy8: ""
        },
        {
            id: "5994",
            lt10: "mfaynono",
            lt12: "",
            sy7: "ܡܓܰܝܒܳܢܳܐ",
            sy9: "",
            dt3: "",
            sy6: "ܡܓܝܒܢܐ",
            sy5: "ܡܦܰܝܢܳܢܳܐ",
            sy4: "ܡܦܝܢܢܐ",
            dt1: "Antwortender",
            sy8: "",
            dt2: "Antwortgebender",
            lt11: "mgaybono"
        },
        {
            lt11: "",
            dt1: "Langsamkeit",
            sy7: "",
            lt10: "matinutho",
            sy9: "",
            sy6: "",
            dt3: "",
            dt2: "Trägheit",
            lt12: "",
            sy5: "ܡܰܬܺܝܢܽܘܬ݂ܳܐ",
            sy8: "",
            id: "2711",
            sy4: "ܡܬܝܢܘܬܐ"
        },
        {
            sy9: "",
            sy5: "ܒܽܘܪܟܳܐ",
            lt11: "",
            sy4: "ܒܘܪܟܐ",
            sy6: "",
            sy8: "",
            id: "2543",
            lt10: "burko",
            sy7: "",
            dt3: "",
            lt12: "",
            dt1: "Knie",
            dt2: ""
        },
        {
            lt10: "shedoro",
            sy5: "ܫܶܕܳܪܳܐ",
            dt1: "Schicken",
            lt12: "",
            sy9: "",
            lt11: "",
            id: "3933",
            sy4: "ܫܕܪܐ",
            dt3: "",
            sy7: "",
            dt2: "",
            sy6: "",
            sy8: ""
        },
        {
            sy7: "ܐ̱ܪܳܙܳܐ",
            sy5: "ܪܳܐܙܳܐ",
            dt1: "Geheimnis",
            sy8: "",
            lt12: "",
            sy4: "ܪܐܙܐ",
            sy6: "ܐܪܙܐ",
            dt3: "Sakrament",
            sy9: "",
            dt2: "Mysterium",
            lt11: "rozo",
            lt10: "rozo",
            id: "1532"
        },
        {
            sy4: "ܒܒܬܐ",
            dt1: "Pupille",
            sy8: "",
            lt11: "",
            dt2: "",
            lt10: "bobtho",
            sy7: "",
            dt3: "",
            sy6: "",
            sy5: "ܒܳܒܬ݂ܳܐ",
            sy9: "",
            lt12: "",
            id: "3548"
        },
        {
            sy6: "",
            sy7: "",
            sy9: "",
            dt2: "",
            lt12: "",
            dt3: "",
            id: "5147",
            dt1: "Woche",
            lt11: "",
            sy8: "",
            lt10: "shabtho",
            sy5: "ܫܰܒܬ݂ܳܐ",
            sy4: "ܫܒܬܐ"
        },
        {
            lt11: "",
            id: "2881",
            sy4: "ܥܒܕ",
            sy9: "",
            dt3: "",
            sy6: "",
            sy5: "ܥܒܰܕ",
            sy8: "",
            sy7: "",
            lt12: "",
            lt10: "ĉbad",
            dt2: "tun",
            dt1: "machen"
        },
        {
            sy8: "",
            lt10: "shraț",
            sy7: "",
            dt3: "",
            lt12: "",
            sy5: "ܫܪܰܛ",
            sy9: "",
            dt2: "",
            sy6: "",
            sy4: "ܫܪܛ",
            lt11: "",
            id: "5079",
            dt1: "wetten"
        },
        {
            sy9: "",
            sy6: "",
            dt2: "",
            sy8: "",
            lt11: "ܫܡܫܐ",
            lt10: "ܫܡܫܐ",
            dt3: "",
            id: "3922",
            dt1: "Schemscho",
            sy5: "ܫܶܡܫܳܐ",
            sy7: "",
            lt12: "",
            sy4: "ܫܡܫܐ"
        },
        {
            dt1: "Handtuch",
            sy6: "ܟܘܦܪܐ",
            sy9: "",
            lt12: "",
            lt10: "shushefo",
            sy5: "ܫܽܘܫܶܦܳܐ",
            sy7: "ܟܽܘܦܳܪܳܐ",
            sy8: "",
            sy4: "ܫܘܫܦܐ",
            id: "1858",
            dt2: "Tuch",
            dt3: "",
            lt11: "kuforo"
        },
        {
            sy4: "ܪܓܫܐ",
            dt2: "Emotion",
            sy7: "",
            dt3: "",
            sy6: "",
            sy8: "",
            lt10: "reğsho",
            lt11: "",
            sy5: "ܪܶܓ݂ܫܳܐ",
            sy9: "",
            id: "1523",
            dt1: "Gefühl",
            lt12: ""
        },
        {
            sy6: "",
            sy5: "ܡܰܘܬܳܐ ܕܡܶܢ ܟܰܦܢܳܐ",
            sy9: "",
            sy7: "",
            sy4: "ܡܘܬܐ ܕܡܢ ܟܦܢܐ",
            id: "2076",
            lt12: "",
            lt11: "",
            sy8: "",
            dt1: "Hungerstod",
            dt3: "",
            dt2: "",
            lt10: "mauwto d`men kafno"
        },
        {
            sy5: "ܚܽܘܕܪܳܐ",
            dt3: "Verein",
            sy9: "",
            sy8: "",
            sy6: "",
            sy4: "ܚܘܕܪܐ",
            lt10: "ĥuđro",
            sy7: "",
            dt2: "Club",
            dt1: "Vereinsclub",
            lt12: "",
            id: "4726",
            lt11: ""
        },
        {
            id: "3150",
            sy5: "ܢܰܒܳܘܨܰܪܰܕܰܢ",
            lt10: "ܢܒܘܨܪܕܢ",
            sy8: "",
            lt11: "ܢܒܘܨܪܕܢ",
            lt12: "",
            dt3: "",
            dt1: "Nabosaradan",
            dt2: "",
            sy9: "",
            sy6: "",
            sy4: "ܢܒܘܨܪܕܢ",
            sy7: ""
        },
        {
            dt3: "",
            dt1: "abkratzen",
            sy9: "",
            lt12: "",
            sy7: "ܓܪܰܕ",
            id: "5576",
            sy4: "ܚܦܪ",
            sy5: "ܚܦܰܪ",
            lt11: "grad",
            lt10: "ĥfar",
            sy8: "",
            sy6: "ܓܪܕ",
            dt2: ""
        },
        {
            dt1: "sagen",
            lt10: "emar",
            id: "3803",
            sy6: "ܡܠܠ",
            sy9: "",
            sy5: "ܐܶܡܰܪ",
            sy4: "ܐܡܪ",
            sy8: "",
            lt11: "malel",
            dt2: "",
            sy7: "ܡܰܠܶܠ",
            dt3: "",
            lt12: ""
        },
        {
            lt12: "",
            sy4: "ܡܡܠܠܘܬ ܩܠܢܝܬܐ",
            id: "5807",
            dt1: "Akustik",
            sy8: "",
            dt3: "",
            sy7: "",
            sy5: "ܡܡܰܠܠܽܘܬ݂ ܩܳܠܳܢܳܝܳܬ݂ܳܐ",
            lt11: "",
            lt10: "mmalluth qolonoyotho",
            dt2: "",
            sy9: "",
            sy6: ""
        },
        {
            dt2: "",
            dt1: "Zionist",
            sy7: "",
            lt10: "ŝehyunoyo",
            id: "5335",
            lt12: "",
            lt11: "",
            sy4: "ܨܗܝܘܢܝܐ",
            dt3: "",
            sy9: "",
            sy6: "",
            sy8: "",
            sy5: "ܨܶܗܝܽܘܢܳܝܳܐ"
        },
        {
            sy8: "",
            dt3: "",
            sy5: "ܨܦܝܺܬܰܐ",
            sy9: "",
            lt10: "ܨܦܝܬܐ",
            dt2: "",
            sy4: "ܨܦܝܬܐ",
            dt1: "Sfita",
            lt11: "ܨܦܝܬܐ",
            lt12: "",
            sy6: "",
            sy7: "",
            id: "4133"
        },
        {
            dt2: "",
            lt10: "msablonutho",
            sy7: "",
            lt11: "",
            sy5: "ܡܣܰܒܠܳܢܽܘܬ݂ܳܐ",
            dt1: "Unterdrückung",
            sy4: "ܡܣܒܠܢܘܬܐ",
            id: "6332",
            lt12: "",
            sy6: "",
            sy9: "",
            sy8: "",
            dt3: ""
        },
        {
            id: "5087",
            sy8: "ܐܝܟܢܐ",
            lt10: "aykan ithaw",
            sy5: "ܐܰܝܟܰܢ ܐܺܝܬ݂ܰܘܗܝ̱",
            dt1: "wie geht’s ihm",
            lt12: "aykano",
            sy9: "ܐܰܝܟܰܢܳܐ",
            lt11: "aykan ithe",
            sy6: "ܐܝܟܢ ܐܝܬܝܗ",
            sy7: "ܐܰܝܟܰܢ ܐܺܝܬ݂ܶܝܗ",
            dt2: "wie geht es ihr",
            dt3: "",
            sy4: "ܐܝܟܢ ܐܝܬܘܗܝ"
        },
        {
            dt1: "blasen",
            sy9: "",
            sy6: "",
            sy5: "ܢܦܰܚ",
            id: "640",
            sy8: "",
            lt10: "nfaĥ",
            lt12: "",
            dt2: "pusten",
            dt3: "",
            sy4: "ܢܦܚ",
            lt11: "",
            sy7: ""
        },
        {
            lt11: "",
            sy6: "",
            dt2: "Spucke",
            sy8: "",
            id: "4208",
            sy4: "ܪܘܩܐ",
            sy9: "",
            sy5: "ܪܽܘܩܳܐ",
            lt10: "ruqo",
            dt1: "Speichel",
            sy7: "",
            dt3: "",
            lt12: ""
        },
        {
            lt11: "ܡܪܝ ܓܒܪܝܐܝܠ",
            sy7: "",
            dt3: "",
            sy6: "",
            id: "3089",
            sy4: "ܡܪܝ ܓܒܪܝܐܝܠ",
            sy5: "ܡܳܪܝ̱ ܓܰܒܪܺܝܐܶܝܠ",
            dt1: "Mor Gabriel",
            sy9: "",
            sy8: "",
            lt10: "ܡܪܝ ܓܒܪܝܐܝܠ",
            dt2: "St Gabriel",
            lt12: ""
        },
        {
            dt2: "",
            sy7: "",
            sy8: "",
            lt12: "",
            sy4: "ܥܙܩܬܐ",
            id: "3724",
            dt3: "",
            sy6: "",
            sy5: "ܥܶܙܰܩܬ݂ܳܐ",
            sy9: "",
            lt10: "ĉezaqtho",
            lt11: "",
            dt1: "Ring"
        },
        {
            sy7: "",
            sy4: "ܪܕܝܬܐ ܕܟܪܝܗܐ",
            dt2: "",
            lt12: "",
            id: "2634",
            lt10: "rađoyto da`krihe",
            dt3: "",
            sy6: "",
            sy8: "",
            dt1: "Krankenwagen",
            lt11: "",
            sy9: "",
            sy5: "ܪܰܕܳܝܬܳܐ ܕܰܟܪܺܝܗܶܐ"
        },
        {
            sy7: "",
            sy8: "",
            sy5: "ܠܰܚܡܳܐ ܚܶܘܳܪܳܐ",
            lt10: "laĥmo ĥeworo",
            lt12: "",
            sy9: "",
            id: "5035",
            dt1: "Weißbrot",
            lt11: "",
            sy4: "ܠܚܡܐ ܚܘܪܐ",
            dt2: "Weissbrot",
            sy6: "",
            dt3: ""
        },
        {
            lt12: "fardo",
            dt3: "",
            lt11: "fazgo",
            dt1: "Einzelstück",
            sy9: "ܦܰܪܕܳܐ",
            dt2: "",
            id: "6407",
            sy5: "ܦܳܪܳܐ",
            sy4: "ܦܪܐ",
            sy6: "ܦܙܓܐ",
            sy7: "ܦܰܙܓܳܐ",
            lt10: "foro",
            sy8: "ܦܪܕܐ"
        },
        {
            lt10: "beth qțoro",
            sy8: "",
            sy7: "",
            lt11: "",
            sy9: "",
            sy4: "ܒܝܬ ܩܬܪܐ",
            dt2: "",
            sy6: "",
            sy5: "ܒܶܝܬ݂ ܩܛܳܪܳܐ",
            dt1: "Bahnhof",
            dt3: "",
            lt12: "",
            id: "421"
        },
        {
            lt10: "lhiqutho",
            lt11: "",
            dt1: "Interesse",
            id: "2224",
            sy8: "",
            sy7: "",
            sy9: "",
            dt2: "Neugier",
            sy6: "",
            dt3: "",
            lt12: "",
            sy5: "ܠܗܺܝܩܽܘܬ݂ܳܐ",
            sy4: "ܠܗܝܩܘܬܐ"
        },
        {
            lt11: "",
            dt1: "Freundin",
            sy7: "",
            sy9: "",
            sy4: "ܚܒܪܬܐ",
            lt12: "",
            sy8: "",
            sy5: "ܚܒܰܪܬ݂ܳܐ",
            lt10: "ĥbartho",
            sy6: "",
            dt2: "",
            dt3: "",
            id: "1394"
        },
        {
            sy7: "",
            sy5: "ܐܰܬ݂ܪ̈ܘܳܬ݂ܳܐ ܢܽܘܟ݂ܪ̈ܝܶܐ",
            dt3: "",
            sy8: "",
            sy4: "ܐܬܪܘܬܐ ܢܘܟܪܝܐ",
            lt10: "athrawotho nukhroye",
            sy9: "",
            id: "1387",
            lt11: "",
            dt1: "fremde Länder",
            sy6: "",
            lt12: "",
            dt2: ""
        },
        {
            sy4: "ܕܝܬܝܪ ܒܟܠ",
            id: "5841",
            sy9: "",
            sy5: "ܕܝܰܬܺܝܪ ܒܟܽܠ",
            lt12: "",
            dt2: "",
            lt11: "",
            dt1: "allermeistens",
            dt3: "",
            lt10: "dyatir bkul",
            sy8: "",
            sy6: "",
            sy7: ""
        },
        {
            sy5: "ܒܚܰܕ ܡܶܢ ܝܰܘܡܳܐ ܕܩܰܝܛܳܐ",
            lt11: "",
            lt10: "bĥađ men yaumo d`qayțo",
            sy7: "",
            sy4: "ܒܚܕ ܡܢ ܝܘܡܐ ܕܩܝܛܐ",
            sy9: "",
            dt2: "",
            dt3: "",
            sy6: "",
            dt1: "An einem Sommertag",
            id: "222",
            sy8: "",
            lt12: ""
        },
        {
            dt1: "Wassertier",
            sy4: "ܚܝܘܬܐ ܕܡܝܐ",
            sy8: "",
            dt2: "",
            dt3: "",
            sy6: "",
            sy7: "",
            lt12: "",
            lt10: "ĥayutho d`mayo",
            id: "5012",
            sy9: "",
            lt11: "",
            sy5: "ܚܰܝܽܘܬ݂ܳܐ ܕܡܰܝܳܐ"
        },
        {
            sy6: "",
            dt3: "",
            sy7: "",
            sy9: "",
            lt12: "",
            id: "5410",
            lt10: "baynothhun",
            sy4: "ܒܝܢܬܗܘܢ",
            dt2: "",
            lt11: "",
            dt1: "zwischen ihnen",
            sy8: "",
            sy5: "ܒܰܝܢܳܬ݂ܗܽܘܢ"
        },
        {
            lt11: "",
            lt12: "",
            dt1: "Achselhöhle",
            sy5: "ܟܽܘܪܣܝܳܐ ܡܶܣܬܰܡܟ݂ܳܢܳܐ",
            sy7: "",
            id: "5726",
            dt3: "",
            sy6: "",
            lt10: "kursyo mestamkhono",
            sy8: "",
            sy4: "ܟܘܪܣܝܐ ܡܣܬܡܟܢܐ",
            sy9: "",
            dt2: ""
        },
        {
            id: "4274",
            dt2: "",
            lt10: "maynqutho",
            sy8: "",
            lt12: "",
            sy5: "ܡܰܝܢܩܽܘܬ݂ܳܐ",
            sy6: "",
            sy9: "",
            dt1: "Stillen",
            lt11: "",
            sy7: "",
            sy4: "ܡܝܢܩܘܬܐ",
            dt3: ""
        },
        {
            lt10: "raumo",
            sy6: "ܥܠܝܘܬܐ",
            sy8: "",
            dt3: "",
            sy7: "ܥܶܠܳܝܽܘܬ݂ܳܐ",
            sy5: "ܪܰܘܡܳܐ",
            lt11: "ĉeloyutho",
            sy9: "",
            sy4: "ܪܘܡܐ",
            dt1: "Höhe",
            id: "2040",
            dt2: "",
            lt12: ""
        },
        {
            id: "5620",
            sy9: "",
            sy5: "ܣܳܥܽܘܪܽܘܬ݂ ܦܽܘܪܫܳܢܳܐ",
            lt10: "soĉuruth furshono",
            sy6: "",
            sy8: "",
            sy7: "",
            dt2: "",
            lt12: "",
            sy4: "ܣܥܘܪܘܬ ܦܘܪܫܢܐ",
            dt1: "Abschiedsbesuch",
            dt3: "",
            lt11: ""
        },
        {
            lt11: "",
            id: "3222",
            sy6: "",
            sy4: "ܢܡܘܣܐ ܟܝܢܝܐ",
            dt1: "Naturgesetz",
            sy5: "ܢܳܡܽܘܣܳܐ ܟܝܳܢܳܝܳܐ",
            sy9: "",
            dt3: "",
            dt2: "",
            sy7: "",
            lt12: "",
            sy8: "",
            lt10: "nomuso kyonoyo"
        },
        {
            lt12: "",
            lt11: "",
            dt3: "",
            id: "1066",
            dt2: "",
            lt10: "filo",
            sy4: "ܦܝܠܐ",
            sy9: "",
            sy8: "",
            sy6: "",
            sy7: "",
            dt1: "Elefant",
            sy5: "ܦܺܝܠܳܐ"
        },
        {
            lt12: "ܢܐܘܡܝ",
            sy7: "ܢܰܐܽܘܡܺܝ",
            sy5: "ܢܰܐܳܘܡܺܝ",
            dt3: "",
            sy8: "",
            id: "3200",
            sy4: "ܢܐܘܡܝ",
            dt1: "Naomi",
            lt10: "ܢܐܘܡܝ",
            sy9: "",
            sy6: "ܢܐܘܡܝ",
            lt11: "ܢܐܘܡܝ",
            dt2: ""
        },
        {
            lt10: "qaumo d`ĉamo",
            lt12: "",
            lt11: "",
            sy6: "",
            dt3: "",
            sy7: "",
            dt1: "Volksaufstand",
            sy5: "ܩܰܘܡܳܐ ܕܥܰܡܳܐ",
            id: "4851",
            sy8: "",
            sy9: "",
            sy4: "ܩܘܡܐ ܕܥܡܐ",
            dt2: ""
        },
        {
            sy6: "ܓܢܒܐ",
            sy7: "ܓܢܳܒܳܐ",
            dt1: "klauen",
            dt3: "",
            dt2: "stehlen",
            sy8: "",
            lt12: "",
            id: "2501",
            sy9: "",
            lt11: "gnobo",
            sy5: "ܓܳܢܶܒ",
            lt10: "goneb",
            sy4: "ܓܢܒ"
        },
        {
            dt3: "Raststätte",
            lt11: "",
            dt2: "Imbiss",
            sy7: "",
            dt1: "Restaurant",
            sy6: "",
            sy4: "ܡܛܥܡܐ",
            id: "3705",
            sy8: "",
            sy9: "",
            lt10: "mațĉmo",
            sy5: "ܡܰܛܥܡܳܐ",
            lt12: ""
        },
        {
            sy9: "",
            sy7: "",
            sy8: "",
            id: "4981",
            dt2: "",
            dt3: "",
            sy6: "",
            lt10: "ܘܦܐ",
            sy4: "ܘܦܐ",
            dt1: "Wapa",
            lt12: "",
            sy5: "ܘܰܦܰܐ",
            lt11: "ܘܦܐ"
        },
        {
            sy6: "ܛܠܝܐ ܝܬܡܐ",
            id: "4967",
            sy9: "",
            dt3: "",
            lt12: "",
            sy4: "ܝܬܡܐ",
            lt10: "yatmo",
            sy5: "ܝܰܬܡܳܐ",
            dt1: "Waisenkind",
            sy7: "ܛܰܠܝܳܐ ܝܰܬܡܳܐ",
            sy8: "",
            dt2: "Waise",
            lt11: "țalyo yatmo"
        },
        {
            id: "4413",
            sy5: "ܛܒܺܝܒܬܰܐ",
            sy7: "",
            lt12: "",
            sy6: "",
            sy9: "",
            dt3: "",
            sy8: "",
            dt1: "Tbibta",
            sy4: "ܛܒܝܒܬܐ",
            lt10: "ܛܒܝܒܬܐ",
            lt11: "ܛܒܝܒܬܐ",
            dt2: ""
        },
        {
            sy8: "",
            sy6: "ܥܘܪܠܐ",
            sy5: "ܥܪܺܘܠܳܐ",
            lt12: "",
            sy7: "ܥܽܘܪܠܳܐ",
            id: "4635",
            lt11: "ĉurlo",
            sy4: "ܥܪܘܠܐ",
            dt1: "unbeschnitten",
            dt3: "",
            sy9: "",
            lt10: "ĉrulo",
            dt2: ""
        },
        {
            lt10: "gadyo",
            sy4: "ܓܕܝܐ",
            sy8: "",
            dt3: "",
            dt2: "",
            id: "5317",
            lt12: "",
            sy7: "",
            dt1: "Ziegenbock",
            sy6: "",
            sy9: "",
            lt11: "",
            sy5: "ܓܰܕܝܳܐ"
        },
        {
            dt2: "",
            id: "4927",
            lt11: "",
            sy7: "",
            dt1: "Waffendepot",
            sy6: "",
            lt10: "beth zayno",
            sy4: "ܒܝܬ ܙܝܢܐ",
            sy5: "ܒܶܝܬ݂ ܙܰܝܢܳܐ",
            lt12: "",
            sy8: "",
            dt3: "",
            sy9: ""
        },
        {
            sy5: "ܚܝܳܦܳܐ",
            id: "417",
            dt1: "Baden",
            sy7: "ܣܚܳܝܳܐ",
            sy4: "ܚܝܦܐ",
            dt3: "duschen",
            sy6: "ܣܚܝܐ",
            lt11: "sĥoyo",
            dt2: "Schwimmen",
            lt12: "",
            sy9: "",
            lt10: "ĥyofo",
            sy8: ""
        },
        {
            lt12: "",
            lt10: "ܛܝܡܬܐܘܣ",
            sy7: "",
            sy8: "",
            dt2: "Timotheus",
            id: "4492",
            dt3: "",
            sy5: "ܛܺܝܡܳܬܶܐܘܳܣ",
            sy9: "",
            sy6: "",
            lt11: "ܛܝܡܬܐܘܣ",
            dt1: "Timotheos",
            sy4: "ܛܝܡܬܐܘܣ"
        },
        {
            lt11: "",
            sy9: "",
            sy6: "",
            sy5: "ܦܰܪܙܠܳܐ",
            dt2: "Stahl",
            sy8: "",
            sy7: "",
            sy4: "ܦܪܙܠܐ",
            id: "1062",
            dt1: "Eisen",
            lt10: "farzlo",
            dt3: "",
            lt12: ""
        },
        {
            sy7: "",
            dt2: "",
            lt11: "",
            dt1: "Leseunterricht",
            sy5: "ܗܶܪܓܳܐ ܕܩܶܪܝܳܢܳܐ",
            sy9: "",
            id: "2773",
            lt12: "",
            lt10: "hergo d`qeryono",
            sy4: "ܗܪܓܐ ܕܩܪܝܢܐ",
            sy6: "",
            dt3: "",
            sy8: ""
        },
        {
            lt10: "ĉito suryoyto triŝath shubĥo",
            sy7: "",
            sy5: "ܥܺܕܬܳܐ ܣܽܘܪܝܳܝܬܳܐ ܬܪܺܝܨܰܬ݂ ܫܽܘܒܚܳܐ",
            lt12: "",
            dt2: "",
            dt3: "",
            sy9: "",
            id: "4339",
            sy4: "ܥܕܬܐ ܣܘܪܝܝܬܐ ܬܪܝܨܬ ܫܘܒܚܐ",
            dt1: "Syrisch Orthodoxe Kirche von Antiochien",
            sy8: "",
            lt11: "",
            sy6: ""
        },
        {
            dt1: "Zuflucht suchen",
            sy4: "ܐܬܓܘܣ",
            lt12: "",
            dt2: "",
            dt3: "",
            sy9: "",
            lt10: "ethgawas",
            id: "5359",
            lt11: "",
            sy8: "",
            sy5: "ܐܶܬ݂ܓܰܘܰܣ",
            sy6: "",
            sy7: ""
        },
        {
            id: "1540",
            sy5: "ܐܰܒܳܐ ܪܽܘܚܳܢܳܝܳܐ",
            dt1: "geistiger Vater",
            dt2: "",
            sy7: "",
            sy8: "",
            lt10: "abo ruĥonoyo",
            lt12: "",
            sy6: "",
            lt11: "",
            sy9: "",
            sy4: "ܐܒܐ ܪܘܚܢܝܐ",
            dt3: ""
        },
        {
            id: "4813",
            sy7: "",
            dt2: "",
            dt3: "",
            sy5: "ܡܢܳܚܬܳܐ",
            sy8: "",
            dt1: "Verstorbene",
            sy6: "",
            lt10: "mnoĥto",
            lt12: "",
            lt11: "",
            sy4: "ܡܢܚܬܐ",
            sy9: ""
        },
        {
            dt1: "Traum",
            sy7: "",
            sy4: "ܚܠܡܐ",
            sy8: "",
            sy5: "ܚܶܠܡܳܐ",
            id: "4548",
            dt2: "",
            lt11: "",
            dt3: "",
            sy9: "",
            lt12: "",
            sy6: "",
            lt10: "ĥelmo"
        },
        {
            sy7: "ܥܶܨܝܳܢܳܐ",
            sy8: "ܩܘܡܐ",
            dt3: "",
            sy4: "ܣܩܘܒܠܝܘܬܐ",
            sy9: "ܩܰܘܡܳܐ",
            sy5: "ܣܰܩܽܘܒܠܳܝܽܘܬ݂ܳܐ",
            lt12: "qaumo",
            lt11: "ĉeŝyono",
            lt10: "saqubloyutho",
            sy6: "ܥܨܝܢܐ",
            dt2: "",
            id: "5082",
            dt1: "Widerstand"
        },
        {
            sy7: "",
            sy8: "",
            lt11: "",
            sy4: "ܣܡܝܡܐ",
            lt12: "",
            id: "4738",
            dt2: "",
            dt3: "",
            sy9: "",
            sy6: "",
            lt10: "smimo",
            dt1: "vergiftet",
            sy5: "ܣܡܺܝܡܳܐ"
        },
        {
            lt11: "",
            sy9: "",
            sy8: "",
            sy4: "ܪܘܡܐ",
            dt3: "",
            id: "1642",
            dt1: "Gipfel",
            sy6: "",
            sy5: "ܪܰܘܡܳܐ",
            lt12: "",
            sy7: "",
            dt2: "Spitze",
            lt10: "raumo"
        },
        {
            sy7: "",
            dt1: "Kirchen",
            lt11: "",
            dt3: "",
            sy6: "",
            dt2: "",
            sy8: "",
            sy9: "",
            lt12: "",
            lt10: "ĉidotho",
            id: "2490",
            sy5: "ܥܺܕ̈ܳܬ݂ܳܐ",
            sy4: "ܥܕܬܐ"
        },
        {
            dt3: "",
            sy8: "",
            sy4: "ܡܣܐܢܐ",
            sy7: "",
            id: "4041",
            lt10: "msone",
            dt1: "Schuhe",
            lt11: "",
            sy5: "ܡܣܳܐܢ̈ܶܐ",
            lt12: "",
            dt2: "",
            sy9: "",
            sy6: ""
        },
        {
            sy4: "ܣܒܪܐ",
            id: "3791",
            dt1: "Sabro",
            dt2: "",
            lt12: "",
            sy6: "",
            sy9: "",
            lt10: "ܣܒܪܐ",
            sy8: "",
            lt11: "ܣܒܪܐ",
            sy5: "ܣܰܒܪܳܐ",
            sy7: "",
            dt3: ""
        },
        {
            lt12: "",
            dt2: "",
            sy7: "",
            id: "2583",
            dt1: "konkurrieren",
            sy9: "",
            lt11: "",
            sy4: "ܡܪܝ",
            sy5: "ܡܰܪܺܝ",
            lt10: "mari",
            sy6: "",
            dt3: "",
            sy8: ""
        },
        {
            dt2: "",
            sy9: "",
            dt1: "Schneemann",
            sy4: "ܓܒܪ ܬܠܓܐ",
            sy5: "ܓܒܰܪ ܬܰܠܓܳܐ",
            lt11: "",
            lt12: "",
            sy8: "",
            lt10: "gbar talgo",
            sy7: "",
            sy6: "",
            id: "3994",
            dt3: ""
        },
        {
            dt2: "",
            sy8: "",
            dt1: "Okzident",
            sy7: "",
            lt10: "maĉerbo",
            id: "3366",
            sy4: "ܡܥܪܒܐ",
            sy9: "",
            lt11: "",
            sy5: "ܡܰܥܶܪܒܳܐ",
            sy6: "",
            dt3: "",
            lt12: ""
        },
        {
            dt3: "",
            sy5: "ܓܰܢܳܢܳܐ ܙܳܪܰܥ ܘܰܪ̈ܕܶܐ",
            sy9: "",
            id: "1481",
            lt10: "ganono soraĉ warde",
            dt2: "",
            sy7: "",
            sy8: "",
            lt11: "",
            dt1: "Gärtner pflanzt Blumen ein",
            sy4: "ܓܢܢܐ ܙܪܥ ܘܪܕܐ",
            sy6: "",
            lt12: ""
        },
        {
            sy9: "",
            sy4: "ܩܘܦܐ ܟܘܫܝܐ",
            sy8: "",
            sy6: "",
            sy5: "ܩܽܘܦܳܐ ܟܽܘܫܳܝܳܐ",
            lt12: "",
            sy7: "",
            id: "6154",
            dt3: "",
            dt1: "Pavian",
            lt11: "",
            lt10: "qufo kushoyo",
            dt2: ""
        },
        {
            lt12: "",
            id: "5450",
            dt2: "",
            sy4: "ܫܢܝ",
            dt3: "",
            sy7: "ܫܒܰܩ",
            dt1: "verlassen",
            sy6: "ܫܒܩ",
            lt11: "shbaq",
            sy8: "",
            sy9: "",
            lt10: "shani",
            sy5: "ܫܰܢܺܝ "
        },
        {
            dt2: "",
            sy4: "ܩܘܪܢܐ",
            lt12: "",
            sy7: "",
            dt3: "",
            sy6: "",
            sy8: "",
            id: "2077",
            sy5: "ܩܽܘܪܢܰܐ",
            sy9: "",
            dt1: "Hupe",
            lt10: "qurna",
            lt11: ""
        },
        {
            sy9: "",
            dt1: "Farid",
            dt3: "",
            sy4: "ܦܪܝܕ",
            lt11: "ܦܪܝܕ",
            lt10: "ܦܪܝܕ",
            lt12: "",
            sy8: "",
            sy5: "ܦܰܪܺܝܕ",
            id: "1257",
            sy7: "",
            sy6: "",
            dt2: ""
        },
        {
            sy6: "ܥܡܢܘܐܝܠܐ",
            sy7: "ܥܰܡܰܢܽܘܐܶܝܠܰܐ",
            dt2: "",
            dt3: "",
            sy4: "ܥܡܢܘܐܝܠܗ",
            sy9: "",
            id: "2924",
            lt12: "ܥܡܢܘܐܝܠܐ",
            sy5: "ܥܰܡܰܢܽܘܐܶܝܠܰܗ",
            lt10: "ܥܡܢܘܐܝܠܗ",
            lt11: "ܥܡܢܘܐܝܠܗ",
            dt1: "Manuela",
            sy8: ""
        },
        {
            sy9: "",
            lt12: "",
            id: "3114",
            lt11: "",
            lt10: "msiĉono",
            sy5: "ܡܙܺܝܥܳܢܳܐ",
            dt1: "Motor",
            sy8: "",
            sy4: "ܡܙܝܥܢܐ",
            sy7: "",
            sy6: "",
            dt3: "",
            dt2: ""
        },
        {
            id: "3762",
            dt1: "rückwärts",
            sy5: "ܒܶܣܬܪܳܐܝܺܬ݂",
            sy6: "",
            sy8: "",
            lt12: "",
            sy7: "",
            sy4: "ܒܣܬܪܐܝܬ",
            sy9: "",
            dt2: "",
            lt11: "",
            lt10: "bestroith",
            dt3: ""
        },
        {
            id: "4808",
            sy9: "",
            sy6: "ܐܫܬܘܕܝ",
            sy7: "ܐܳܫܬܰܘܕܺܝ",
            sy8: "",
            lt10: "ndar",
            dt2: "",
            lt12: "",
            dt1: "versprechen",
            sy5: "ܢܕܰܪ",
            sy4: "ܢܕܪ",
            lt11: "eshtauwdi",
            dt3: ""
        },
        {
            id: "6157",
            lt12: "",
            sy6: "",
            lt11: "",
            sy9: "",
            lt10: "lo shalyo",
            sy5: "ܠܳܐ ܫܰܠܝܳܐ",
            sy7: "",
            dt3: "",
            sy8: "",
            dt1: "pausenlos",
            dt2: "",
            sy4: "ܠܐ ܫܠܝܐ"
        },
        {
            sy9: "ܩܶܢܛܺܝܡܳܐ",
            lt10: "zabno",
            sy8: "ܩܢܛܝܡܐ",
            dt1: "Zeitpunkt",
            sy4: "ܙܒܢܐ",
            lt11: "methĥo",
            dt3: "",
            dt2: "",
            sy7: "ܡܶܬ݂ܚܳܐ",
            sy6: "ܡܬܚܐ",
            sy5: "ܙܰܒܢܳܐ",
            lt12: "qențimo",
            id: "6260"
        },
        {
            sy5: "ܐܰܘܓܺܝܢ",
            dt3: "",
            sy4: "ܐܘܓܝܢ",
            sy9: "",
            sy6: "",
            lt10: "ܐܘܓܝܢ",
            sy8: "",
            lt11: "ܐܘܓܝܢ",
            lt12: "",
            dt2: "Awgin",
            dt1: "Augin",
            sy7: "",
            id: "368"
        },
        {
            lt10: "kulonoyo",
            sy4: "ܟܘܠܢܝܐ",
            sy5: "ܟܽܘܠܳܢܳܝܳܐ",
            lt12: "",
            dt1: "total",
            dt2: "",
            sy7: "ܟܽܘܠܳܢܳܐܝܺܬ݂",
            sy6: "ܟܘܠܢܐܝܬ",
            lt11: "kulonoith",
            dt3: "",
            id: "4532",
            sy9: "",
            sy8: ""
        },
        {
            dt2: "",
            sy5: "ܚܒܰܢ",
            sy7: "",
            sy4: "ܚܒܢ",
            sy8: "",
            id: "1267",
            dt3: "",
            sy9: "",
            lt11: "",
            lt10: "ĥban",
            sy6: "",
            lt12: "",
            dt1: "faulenzen"
        },
        {
            sy6: "",
            lt10: "sagi furgoyo",
            dt1: "viel Spaß",
            sy8: "",
            sy7: "",
            lt11: "",
            dt2: "",
            id: "4832",
            dt3: "",
            lt12: "",
            sy9: "",
            sy5: "ܣܰܓܺܝ ܦܽܘܪܓܳܝܳܐ",
            sy4: "ܣܓܝ ܦܘܪܓܝܐ"
        },
        {
            sy4: "ܪܢܘܝܐ",
            sy9: "",
            sy5: "ܪܳܢܽܘܝܳܐ",
            dt3: "",
            lt12: "",
            sy6: "",
            lt11: "",
            id: "861",
            sy8: "",
            sy7: "",
            dt2: "",
            dt1: "Denker",
            lt10: "ronuyo"
        },
        {
            lt12: "",
            dt1: "Trauerspiel",
            dt2: "",
            sy4: "ܫܥܝܐ ܚܫܝܫܐ",
            dt3: "",
            sy6: "",
            id: "4547",
            sy7: "",
            lt11: "",
            sy8: "",
            sy9: "",
            sy5: "ܫܶܥܝܳܐ ܚܰܫܺܝܫܳܐ",
            lt10: "sheĉyo ĥashisho"
        },
        {
            lt10: "teshmeshto ŝafroyto",
            sy5: "ܬܶܫܡܶܫܬܳܐ ܨܰܦܪܳܝܬܳܐ",
            sy7: "",
            sy8: "",
            dt1: "Frühschicht",
            lt11: "",
            dt3: "",
            sy4: "ܬܫܡܫܬܐ ܨܦܪܝܬܐ",
            id: "1418",
            lt12: "",
            dt2: "",
            sy9: "",
            sy6: ""
        },
        {
            dt3: "",
            lt11: "garĉuno",
            sy4: "ܒܪ ܩܝܡܐ",
            sy9: "ܪܺܝܫ ܕܰܝܪܳܐ",
            sy8: "ܪܝܫ ܕܝܪܐ",
            id: "5681",
            sy6: "ܓܪܥܘܢܐ",
            sy5: "ܒܰܪ ܩܝܳܡܳܐ",
            dt2: "",
            dt1: "Abt",
            lt12: "rish dayro",
            lt10: "bar qyomo",
            sy7: "ܓܰܪܥܽܘܢܳܐ"
        },
        {
            sy4: "ܛܐܫ",
            sy9: "",
            lt11: "",
            lt10: "țoyesh",
            sy5: "ܛܳܐܶܫ",
            sy6: "",
            lt12: "",
            sy7: "",
            dt3: "",
            dt1: "streichen",
            dt2: "",
            id: "4296",
            sy8: ""
        },
        {
            sy5: "ܠܒܺܝܒܽܘܬܰܐ",
            id: "2723",
            dt3: "",
            lt10: "ܠܒܝܒܘܬܐ",
            dt1: "Lbibuta",
            sy4: "ܠܒܝܒܘܬܐ",
            sy6: "",
            sy8: "",
            sy7: "",
            sy9: "",
            lt12: "",
            lt11: "ܠܒܝܒܘܬܐ",
            dt2: ""
        },
        {
            dt3: "",
            sy8: "",
            lt12: "",
            dt2: "",
            sy7: "",
            sy5: "ܕܰܒܶܩ ܒܰܚܕܳܕܶܐ",
            sy6: "",
            sy4: "ܕܒܩ ܒܚܕܕܐ",
            sy9: "",
            dt1: "zusammenkleben",
            lt10: "dabeq baĥđođe",
            id: "5380",
            lt11: ""
        },
        {
            sy9: "",
            lt11: "",
            id: "6108",
            sy7: "",
            lt10: "bunoyo",
            dt1: "Frohsinn",
            sy5: "ܒܽܘܢܳܝܳܐ",
            sy8: "",
            sy6: "",
            dt3: "",
            lt12: "",
            dt2: "",
            sy4: "ܒܘܢܝܐ"
        },
        {
            lt10: "fsoq risho",
            lt12: "",
            sy9: "",
            sy7: "ܡܰܚܢܽܘܩܺܝܬ݂ܳܐ",
            dt2: "hinrichten",
            sy4: "ܦܣܩ ܪܝܫܐ",
            dt3: "",
            lt11: "maĥnuqitho",
            id: "2008",
            sy8: "",
            sy5: "ܦܣܳܩ ܪܺܝܫܳܐ",
            sy6: "ܡܚܢܘܩܝܬܐ",
            dt1: "Hinrichtung"
        },
        {
            lt11: "",
            id: "3372",
            sy5: "ܬܰܟ݂ܬܽܘܫ ܐܳܠܡܦܺܝܰܐ",
            lt12: "",
            sy6: "",
            dt1: "Olympische Spiele",
            lt10: "takhtush olumfiya",
            sy4: "ܬܟܬܘܫ ܐܠܡܦܝܐ",
            dt3: "",
            sy9: "",
            sy8: "",
            dt2: "",
            sy7: ""
        },
        {
            dt2: "",
            sy8: "ܟܪܘܠܐ",
            sy6: "ܒܠܘܥܐ",
            sy4: "ܨܢܪܬܐ",
            dt3: "",
            sy5: "ܨܶܢܳܪܬܳܐ",
            lt10: "ŝenorto",
            sy7: "ܒܰܠܽܘܥܳܐ",
            id: "5928",
            lt11: "baluĉo",
            sy9: "ܟܪܽܘܠܳܐ",
            lt12: "krulo",
            dt1: "Angelhaken"
        },
        {
            dt1: "Mittler",
            sy5: "ܡܡܰܨܥܳܢܳܐ",
            sy9: "ܡܦܺܝܣܳܢܳܐ",
            sy7: "ܬܠܺܝܬ݂ܳܝܳܐ",
            sy4: "ܡܡܨܥܢܐ",
            dt3: "",
            lt10: "mmaŝĉono",
            lt11: "tlithoyo",
            lt12: "mfisono",
            sy6: "ܬܠܝܬܝܐ",
            sy8: "ܡܦܝܣܢܐ",
            dt2: "",
            id: "6373"
        },
        {
            sy8: "",
            sy5: "ܓܰܘܢܳܐ ܣܽܘܡܩܐ ܐܝܟ ܕܡܐ",
            sy7: "",
            dt1: "rote Farbe wie das Blut",
            lt12: "",
            sy6: "",
            dt2: "",
            id: "3751",
            sy9: "",
            sy4: "ܓܘܢܐ ܣܘܡܩܐ ܐܝܟ ܕܡܐ",
            lt10: "gauno sumoqo ayk dmo",
            lt11: "",
            dt3: ""
        },
        {
            lt10: "iđaĥtho",
            sy8: "",
            lt11: "",
            sy9: "",
            sy5: "ܝܺܕܰܥܬ݂ܳܐ",
            lt12: "",
            sy6: "",
            id: "2455",
            dt1: "Kenntnis",
            sy4: "ܝܕܥܬܐ",
            dt3: "",
            dt2: "Wissen",
            sy7: ""
        },
        {
            dt2: "",
            sy9: "",
            dt3: "",
            lt12: "",
            lt11: "",
            dt1: "Yeshua ist mein Herr Sohn meines Schöpfers",
            sy5: "ܝܶܫܘܽܥ ܗܽܘ ܡܳܪܝ̱ ܘܰܒܪܳܐ ܕܒܳܪܽܘܝܳܐ ܕܺܝܠܝ",
            lt10: "yeshuĉ hu mor wabro d boruyo dil",
            sy4: "ܝܫܘܥ ܗܘ ܡܪܝ ܘܒܪܐ ܕܒܪܘܝܐ ܕܝܠܝ",
            sy8: "",
            sy7: "",
            id: "5208",
            sy6: ""
        },
        {
            dt2: "",
            id: "3118",
            sy4: "ܡܫܝܚܪܚܡܐ",
            lt12: "ܡܫܝܚܐ ܪܚܡܐ",
            sy5: "ܡܫܺܝܚܳܪܰܚܡܶܐ",
            sy7: "ܡܫܺܝܚܳܐ ܪܰܚܡܶܐ",
            sy8: "",
            sy9: "",
            lt11: "ܡܫܝܚܪܚܡܐ",
            dt1: "Mschihorahme",
            lt10: "ܡܫܝܚܪܚܡܐ",
            dt3: "",
            sy6: "ܡܫܝܚܐ ܪܚܡܐ"
        },
        {
            sy6: "",
            sy4: "ܐܢܐ ܓܠܦ ܐܢܐ ܠܩܢܝܐ",
            sy5: "ܐܶܢܳܐ ܓܠܦ ܐ̱ܢܳܐ ܠܩܰܢܝܳܐ",
            lt12: "",
            lt11: "",
            sy9: "",
            sy7: "",
            dt1: "Ich spitze den Stift an",
            dt2: "",
            id: "2140",
            lt10: "eno golef no l`qanyo",
            sy8: "",
            dt3: ""
        },
        {
            lt12: "",
            sy6: "",
            lt10: "lo moyutho",
            sy5: "ܠܳܐ ܡܳܝܽܘܬ݂ܳܐ",
            sy7: "",
            sy9: "",
            id: "4658",
            lt11: "",
            sy8: "",
            dt2: "",
            dt3: "",
            dt1: "unsterblich",
            sy4: "ܠܐ ܡܝܘܬܐ"
        },
        {
            sy7: "",
            dt1: "Himmelskörper",
            lt11: "",
            dt3: "",
            sy4: "ܓܘܫܡܐ ܫܡܝܢܐ",
            lt10: "gushmo shmayono",
            sy5: "ܓܽܘܫܡܳܐ ܫܡܰܝܳܢܳܐ",
            sy8: "",
            id: "2003",
            dt2: "",
            sy9: "",
            sy6: "",
            lt12: ""
        },
        {
            dt1: "trostlos",
            sy8: "",
            sy6: "",
            lt12: "",
            sy5: "ܠܳܐ ܡܶܬ݂ܒܰܝܳܐܢܳܐ",
            dt2: "",
            sy9: "",
            sy7: "",
            dt3: "",
            sy4: "ܠܐ ܡܬܒܝܐܢܐ",
            id: "4583",
            lt11: "",
            lt10: "lo methbayono"
        },
        {
            dt1: "zimmern",
            sy7: "",
            sy4: "ܢܓܪ",
            id: "5327",
            sy5: "ܢܰܓܰܪ",
            sy8: "",
            lt10: "nagar",
            lt11: "",
            dt2: "hobeln",
            sy9: "",
            dt3: "tischlern",
            sy6: "",
            lt12: ""
        },
        {
            dt3: "",
            sy5: "ܡܦܰܚܡܳܢܳܐ",
            dt1: "analogisch",
            sy6: "",
            lt11: "",
            sy9: "",
            dt2: "",
            sy4: "ܡܦܚܡܢܐ",
            id: "5904",
            lt12: "",
            lt10: "mfaĥmono",
            sy8: "",
            sy7: ""
        },
        {
            sy7: "",
            dt1: "Maleachi",
            dt2: "",
            sy6: "",
            dt3: "",
            sy5: "ܡܰܠܶܐܟܺܝ",
            lt11: "ܡܠܐܟܝ",
            sy4: "ܡܠܐܟܝ",
            id: "2898",
            sy8: "",
            lt10: "ܡܠܐܟܝ",
            lt12: "",
            sy9: ""
        },
        {
            sy5: "ܬܽܘܚܳܡܽܘܬ݂ܳܐ",
            sy6: "",
            dt2: "",
            lt12: "",
            dt1: "Ablauf",
            id: "5567",
            sy8: "",
            dt3: "",
            lt10: "tuĥomutho",
            sy4: "ܬܘܚܡܘܬܐ",
            sy9: "",
            lt11: "",
            sy7: ""
        },
        {
            id: "770",
            sy6: "ܟܪܝܣܛܝܐܢ",
            sy8: "",
            dt1: "Christian",
            sy5: "ܟܪܺܝܣܬܝܰܢ",
            dt3: "",
            lt11: "ܟܪܝܣܬܝܢ",
            lt12: "ܟܪܝܣܛܝܐܢ",
            sy4: "ܟܪܝܣܬܝܢ",
            dt2: "Kristian",
            sy9: "",
            sy7: "ܟܪܺܝܣܛܝܰܐܢ",
            lt10: "ܟܪܝܣܬܝܢ"
        },
        {
            dt1: "nehmen",
            id: "3236",
            sy7: "",
            dt3: "",
            sy5: "ܢܳܣܶܒ",
            lt10: "noseb",
            sy9: "",
            lt11: "",
            sy6: "",
            dt2: "",
            sy4: "ܢܣܒ",
            sy8: "",
            lt12: ""
        },
        {
            sy4: "ܟܝܢܐܝܬ",
            lt12: "",
            dt3: "",
            dt1: "naturell",
            sy5: "ܟܝܳܢܳܐܝܺܬ݂",
            sy6: "",
            sy8: "",
            sy7: "",
            lt11: "",
            lt10: "kyonoith",
            dt2: "",
            sy9: "",
            id: "3221"
        },
        {
            lt11: "ܗܢܝ",
            sy4: "ܗܢܝ",
            sy6: "",
            dt3: "",
            sy8: "",
            dt2: "",
            dt1: "Hani",
            id: "1862",
            lt10: "ܗܢܝ",
            sy9: "",
            sy5: "ܗܰܢܺܝ",
            lt12: "",
            sy7: ""
        },
        {
            id: "882",
            sy4: "ܥܒܘܛܘܬܐ",
            dt1: "Dicke",
            sy7: "ܥܰܒܺܝܛܽܘܬ݂ܳܐ",
            sy9: "",
            lt12: "",
            dt2: "",
            dt3: "",
            sy8: "",
            sy5: "ܥܰܒܽܘܛܽܘܬ݂ܳܐ",
            lt11: "ĉabițutho",
            sy6: "ܥܒܝܛܘܬܐ",
            lt10: "ĉabuțutho"
        },
        {
            lt10: "yamo d`garbyo",
            dt2: "",
            sy4: "ܝܡܐ ܕܓܪܒܝܐ",
            sy7: "",
            id: "3297",
            sy8: "",
            sy9: "",
            dt1: "Nordsee",
            dt3: "",
            sy6: "",
            lt12: "",
            lt11: "",
            sy5: "ܝܰܡܳܐ ܕܓܰܪܒܝܳܐ"
        },
        {
            lt12: "",
            sy6: "",
            dt1: "Gott ist groß",
            dt3: "",
            sy7: "",
            sy5: "ܐܰܠܳܗܳܐ ܪܰܒܳܐ",
            lt10: "aloho rabo",
            sy9: "",
            sy8: "",
            sy4: "ܐܠܗܐ ܪܒܐ",
            lt11: "",
            dt2: "",
            id: "1691"
        },
        {
            sy8: "",
            sy7: "",
            sy6: "",
            dt3: "",
            dt2: "",
            dt1: "stehen",
            lt10: "kloyo",
            sy5: "ܟܠܳܝܳܐ",
            sy4: "ܟܠܝܐ",
            lt11: "",
            lt12: "",
            id: "4248",
            sy9: ""
        },
        {
            sy6: "",
            lt12: "",
            dt2: "",
            sy4: "ܐܬܬܟܠ ܥܠ ܐܠܗܐ",
            dt3: "",
            sy5: "ܐܶܬ݂ܬܟ݂ܰܠ ܥܰܠ ܐܰܠܳܗܳܐ",
            lt11: "",
            dt1: "Vertraue auf Gott",
            sy7: "",
            lt10: "ethtkhal ĉal aloho",
            sy8: "",
            sy9: "",
            id: "4817"
        },
        {
            dt2: "",
            dt3: "",
            sy4: "ܒܝܬ ܬܦܝܐ",
            sy9: "",
            sy8: "",
            lt10: "beth tfoyo",
            lt12: "",
            dt1: "Bäckerei",
            sy5: "ܒܶܝܬ݂ ܬܦܳܝܳܐ",
            lt11: "",
            id: "416",
            sy7: "",
            sy6: ""
        },
        {
            sy4: "ܦܘܫ ܒܫܠܡܐ",
            lt12: "",
            dt2: "Tschüss",
            dt3: "",
            sy9: "",
            sy8: "",
            sy7: "",
            sy5: "ܦܽܘܫ ܒܰܫܠܳܡܳܐ",
            lt10: "fush bashlomo",
            id: "349",
            sy6: "",
            dt1: "Auf Wiedersehen",
            lt11: ""
        },
        {
            lt11: "",
            dt1: "Jahrhundertwende",
            sy5: "ܫܽܘܚܠܳܦ ܕܳܪܳܐ",
            sy6: "",
            dt2: "",
            lt10: "shuĥlof doro",
            sy4: "ܫܘܚܠܦ ܕܪܐ",
            sy8: "",
            sy9: "",
            lt12: "",
            dt3: "",
            sy7: "",
            id: "2279"
        },
        {
            sy4: "ܛܦܪܐ",
            dt2: "",
            dt3: "",
            sy6: "",
            sy5: "ܛܰܦܪܳܐ",
            lt12: "",
            id: "1315",
            sy7: "",
            lt10: "țafro",
            dt1: "Fingernagel",
            sy8: "",
            lt11: "",
            sy9: ""
        },
        {
            id: "4509",
            dt3: "",
            dt1: "Töchter",
            sy8: "",
            lt10: "bnothe",
            sy6: "",
            sy7: "",
            sy5: "ܒܢ̈ܳܬ݂ܳܐ",
            lt12: "",
            lt11: "",
            sy9: "",
            dt2: "",
            sy4: "ܒܢܬܐ"
        },
        {
            lt10: "mshuĥto meŝĉoyto",
            sy5: "ܡܫܽܘܚܬܳܐ ܡܶܨܥܳܝܬܳܐ",
            dt1: "Mittelmaß",
            id: "6359",
            sy9: "",
            dt2: "",
            sy4: "ܡܫܘܚܬܐ ܡܨܥܝܬܐ",
            sy6: "",
            lt12: "",
            sy7: "",
            sy8: "",
            dt3: "",
            lt11: ""
        },
        {
            lt11: "",
            id: "3363",
            dt3: "",
            dt2: "",
            sy9: "",
            sy8: "",
            lt12: "",
            sy5: "ܩܕܳܫܳܐ",
            sy6: "",
            sy4: "ܩܕܫܐ",
            lt10: "qdosho",
            sy7: "",
            dt1: "Ohrring"
        },
        {
            id: "6448",
            sy9: "",
            sy6: "",
            lt12: "",
            dt1: "Entwicklungszeit",
            dt3: "",
            sy8: "",
            dt2: "",
            lt11: "",
            sy4: "ܙܒܢܐ ܕܫܘܫܛܐ",
            sy7: "",
            sy5: "ܙܰܒܢܳܐ ܕܫܽܘܫܳܛܳܐ",
            lt10: "zabno d`shushoțo"
        },
        {
            sy4: "ܦܪܣܝܐ",
            lt12: "",
            lt10: "forsoyo",
            sy7: "ܦܳܪܣܳܐܝܺܬ݂",
            lt11: "forsoith",
            sy5: "ܦܳܪܣܳܝܳܐ",
            dt2: "iranisch",
            sy6: "ܦܪܣܐܝܬ",
            sy9: "",
            sy8: "",
            id: "3436",
            dt3: "",
            dt1: "persisch"
        },
        {
            sy8: "",
            sy9: "",
            lt10: "shumno",
            sy7: "",
            sy4: "ܫܘܡܢܐ",
            sy5: "ܫܽܘܡܢܳܐ",
            lt12: "",
            dt1: "Speck von Fett",
            id: "4207",
            dt2: "Speck",
            sy6: "",
            lt11: "",
            dt3: "Fett von Speck"
        },
        {
            sy4: "ܡܘܢ ܫܡܟ",
            sy7: "",
            sy6: "",
            sy8: "",
            dt2: "",
            id: "5091",
            lt10: "mun shmokh",
            sy9: "",
            lt11: "",
            sy5: "ܡܽܘܢ ܫܡܳܟ",
            dt3: "",
            dt1: "Wie heißt Du",
            lt12: ""
        },
        {
            dt3: "",
            dt1: "Rechtsweg",
            lt11: "urĥo triŝto",
            dt2: "",
            lt10: "urĥo nomusoyto",
            sy9: "",
            sy4: "ܐܘܪܚܐ ܢܡܘܣܝܬܐ",
            id: "3646",
            sy6: "ܐܘܪܚܐ ܬܪܝܨܬܐ",
            sy7: "ܐܽܘܪܚܳܐ ܬܪܺܝܨܬܳܐ",
            lt12: "",
            sy5: "ܐܽܘܪܚܳܐ ܢܳܡܽܘܣܳܝܬܳܐ",
            sy8: ""
        },
        {
            lt10: "sohdutho",
            sy9: "",
            sy8: "",
            sy6: "",
            dt1: "Diplom",
            sy4: "ܣܗܕܘܬܐ",
            lt11: "",
            sy5: "ܣܳܗܕܽܘܬ݂ܳܐ",
            dt3: "",
            lt12: "",
            dt2: "Zeugnis",
            sy7: "",
            id: "902"
        },
        {
            sy4: "ܟܢܘܫܝܐ ܕܓܘܒܝܐ",
            lt12: "",
            dt2: "",
            id: "4947",
            sy7: "",
            sy9: "",
            sy6: "",
            dt3: "",
            dt1: "Wahlversammlung",
            sy8: "",
            lt11: "",
            sy5: "ܟܢܽܘܫܝܳܐ ܕܓܽܘܒܳܝܳܐ",
            lt10: "knushyo d`guboyo"
        },
        {
            id: "2579",
            sy7: "",
            dt1: "königlich",
            sy8: "",
            lt11: "",
            dt2: "royal",
            sy5: "ܡܰܠܟܳܝܳܐ",
            lt10: "malkoyo",
            sy9: "",
            sy6: "",
            dt3: "",
            sy4: "ܡܠܟܝܐ",
            lt12: ""
        },
        {
            dt1: "Dario",
            dt2: "",
            lt11: "ܕܪܝܐ",
            sy5: "ܕܰܪܝܳܐ",
            sy8: "",
            sy4: "ܕܪܝܐ",
            dt3: "",
            sy7: "",
            sy9: "",
            sy6: "",
            lt12: "",
            id: "810",
            lt10: "ܕܪܝܐ"
        },
        {
            sy8: "",
            sy9: "",
            sy7: "",
            sy4: "ܐܓܘܪܐ",
            sy5: "ܐܳܓܽܘܪܳܐ",
            id: "6386",
            dt2: "Pächter",
            dt3: "",
            sy6: "",
            lt11: "",
            lt12: "",
            dt1: "Mieter",
            lt10: "oguro"
        },
        {
            sy7: "",
            dt1: "Bürochef",
            dt2: "Bürovorsteher",
            sy6: "",
            id: "744",
            lt10: "rish makthbo",
            sy4: "ܪܝܫ ܡܟܬܒܐ",
            sy5: "ܪܺܝܫ ܡܰܟܬ݂ܒܳܐ",
            sy9: "",
            sy8: "",
            dt3: "",
            lt11: "",
            lt12: ""
        },
        {
            lt11: "ܚܢܢܐ",
            sy8: "",
            lt10: "ܚܢܢܐ",
            sy7: "ܚܰܢܢܶܐ",
            id: "1864",
            sy5: "ܚܰܢܢܰܐ",
            dt1: "Hanna",
            sy6: "ܚܢܢܐ",
            sy9: "",
            dt3: "",
            lt12: "ܚܢܢܐ",
            dt2: "Hanne",
            sy4: "ܚܢܢܐ"
        },
        {
            sy7: "ܓܰܝܣܳܐ ܕܨܦܳܚܳܐ",
            id: "3477",
            sy8: "",
            sy4: "ܓܝܣܐ ܕܣܥܝܐ",
            lt12: "",
            lt10: "gayso dasĉoyo",
            sy5: "ܓܰܝܣܳܐ ܕܰܣܥܳܝܳܐ",
            dt2: "",
            dt1: "Plünderung",
            sy6: "ܓܝܣܐ ܕܨܦܚܐ",
            sy9: "",
            lt11: "gayso dŝfoĥo",
            dt3: ""
        },
        {
            lt12: "",
            lt10: "methfaĥmono",
            dt3: "",
            dt1: "vergleichbar",
            sy6: "",
            sy4: "ܡܬܦܚܡܢܐ",
            sy8: "",
            sy9: "",
            sy5: "ܡܶܬ݂ܦܰܚܡܳܢܳܐ",
            lt11: "",
            id: "4741",
            dt2: "",
            sy7: ""
        },
        {
            dt1: "Ihr schreibt",
            sy9: "",
            sy7: "ܐܰܢ̱ܬܶܝܢ ܟܳܬ݂ܒܳܬܶܝܢ",
            lt10: "atun kothbitun",
            id: "2160",
            sy5: "ܐܰܢ̱ܬܽܘܢ ܟܳܬ݂ܒܺܝܬܽܘܢ",
            dt3: "",
            sy8: "",
            lt12: "",
            lt11: "aten kothbothen",
            sy4: "ܐܢܬܘܢ ܟܬܒܝܬܘܢ",
            dt2: "Ihr seid am schreiben",
            sy6: "ܐܢܬܝܢ ܟܬܒܬܝܢ"
        },
        {
            sy5: "ܟܳܩܳܪܶܐ",
            lt10: "koqore",
            sy8: "",
            id: "1123",
            sy7: "ܩܳܪܶܐ",
            lt12: "",
            dt3: "",
            dt1: "Er liest",
            sy4: "ܟܩܪܐ",
            dt2: "",
            lt11: "qore",
            sy6: "ܩܪܐ",
            sy9: ""
        },
        {
            sy8: "",
            sy4: "ܕܘܪܐ",
            lt11: "",
            sy7: "",
            id: "3768",
            lt12: "",
            sy9: "",
            dt3: "",
            lt10: "dauwro",
            dt2: "",
            dt1: "Runde",
            sy5: "ܕܰܘܪܳܐ",
            sy6: ""
        },
        {
            lt11: "",
            dt3: "",
            dt2: "Ministersitzung",
            lt10: "mautbo d`wazire",
            sy4: "ܡܘܬܒܐ ܕܘܙܝܪܐ",
            dt1: "Ministerrat",
            id: "3047",
            sy6: "",
            sy8: "",
            sy5: "ܡܰܘܬܒܳܐ ܕܘܰܙܺܝܪܳܐ",
            sy7: "",
            lt12: "",
            sy9: ""
        },
        {
            sy6: "",
            id: "52",
            sy4: "ܡܪܝܐ ܝܫܘܥ ܡܫܝܚܐ ܒܪܐ ܕܐܠܗܐ ܫܒܘܩ ܠܝ ܚܛܗܝ",
            sy9: "",
            sy7: "",
            dt3: "",
            lt10: "Moryo yeshuĉ mshiĥo bro d'aloho shbuq li ĥtohay",
            dt1: "Herr Jesus Christ Sohn Gottes erbarme dich meiner Sünder",
            dt2: "",
            lt12: "",
            lt11: "",
            sy8: "",
            sy5: "ܡܳܪܝܳܐ ܝܶܫܽܘܥ ܡܫܺܝܚܳܐ ܒܪܳܐ ܕܰܐܠܳܗܳܐ ܫܒܽܘܩ ܠܺܝ ܚܛܳܗܰܝ"
        },
        {
            dt1: "Komma",
            id: "2566",
            dt3: "",
            lt10: "farusho",
            sy8: "",
            sy6: "",
            lt11: "",
            sy5: "ܦܰܪܽܘܫܳܐ",
            sy9: "",
            sy4: "ܦܪܘܫܐ",
            dt2: "",
            sy7: "",
            lt12: ""
        },
        {
            lt10: "ĥaylo d`zebno",
            lt11: "",
            dt2: "",
            sy9: "",
            lt12: "",
            sy4: "ܚܝܠܐ ܕܙܒܢܐ",
            id: "2439",
            sy6: "",
            sy8: "",
            dt1: "Kaufkraft",
            sy7: "",
            sy5: "ܚܰܝܠܳܐ ܕܙܰܒܢܳܐ",
            dt3: ""
        },
        {
            sy4: "ܩܒܠ",
            id: "573",
            dt1: "beschuldigen",
            dt3: "widersetzen",
            sy6: "",
            lt11: "",
            sy8: "",
            sy7: "",
            sy9: "",
            sy5: "ܩܒܰܠ",
            dt2: "beschweren",
            lt12: "",
            lt10: "qbal"
        },
        {
            dt2: "",
            lt12: "",
            sy8: "",
            sy4: "ܦܨܢ ܡܢ ܒܝܫܐ",
            sy9: "",
            sy5: "ܦܰܨܳܢ ܡܶܢ ܒܺܝܫܳܐ",
            dt1: "Erlöse uns vom Bösen",
            dt3: "",
            sy6: "",
            sy7: "",
            lt11: "",
            lt10: "faŝon men bisho",
            id: "1156"
        },
        {
            sy7: "ܦܰܩܥܳܐ",
            lt10: "shanto",
            id: "5820",
            dt2: "",
            dt3: "",
            dt1: "Alge",
            sy4: "ܫܢܬܐ",
            sy6: "ܦܩܥܐ",
            sy9: "",
            lt11: "faqĉo",
            sy8: "",
            lt12: "",
            sy5: "ܫܰܢܬܳܐ"
        },
        {
            id: "1411",
            lt12: "",
            sy8: "",
            sy7: "ܐܶܒܳܐ",
            sy6: "ܐܒܐ",
            dt3: "",
            sy9: "",
            dt1: "Frucht",
            lt11: "ebo",
            dt2: "",
            lt10: "firo",
            sy4: "ܦܐܪܐ",
            sy5: "ܦܺܐܪܳܐ"
        },
        {
            dt1: "Ackerbau",
            sy5: "ܙܳܪܽܘܥܽܘܬ݂ܳܐ",
            sy9: "ܦܳܠܚܽܘܬ݂ܳܐ",
            sy4: "ܙܪܘܥܘܬܐ",
            lt10: "zoruĉutho",
            sy6: "ܦܠܚܘܬܐ",
            lt12: "folĥutho",
            dt2: "",
            sy8: "ܦܠܚܘܬܐ",
            dt3: "",
            sy7: "ܦܰܠܳܚܽܘܬ݂ܳܐ",
            id: "5740",
            lt11: "faloĥutho"
        },
        {
            dt1: "gegenwärtig",
            lt10: "bzabno d`qoyem",
            sy8: "",
            lt11: "hoshoith",
            dt2: "",
            sy7: "ܗܳܫܳܐܝܺܬ݂",
            dt3: "",
            sy9: "",
            id: "1526",
            sy5: "ܒܙܰܒܢܳܐ ܕܩܳܐܶܡ",
            sy4: "ܒܙܒܢܐ ܕܩܐܡ",
            sy6: "ܗܫܐܝܬ",
            lt12: ""
        },
        {
            lt12: "",
            lt10: "mĉoso",
            dt2: "",
            sy5: "ܡܥܳܣܳܐ",
            sy6: "",
            dt3: "",
            sy8: "",
            dt1: "Zertrümmerung",
            lt11: "",
            sy9: "",
            id: "6280",
            sy4: "ܡܥܣܐ",
            sy7: ""
        },
        {
            lt10: "ĥaryo",
            lt12: "",
            sy6: "ܚܪܘܬܐ",
            dt1: "Kot",
            dt3: "Kacke",
            lt11: "ĥrutho",
            sy7: "ܚܪܽܘܬ݂ܳܐ",
            dt2: "Scheiße",
            sy8: "",
            sy9: "",
            sy5: "ܚܰܪܝܳܐ",
            id: "2624",
            sy4: "ܚܪܝܐ"
        },
        {
            sy9: "",
            lt11: "adamas",
            dt3: "",
            dt1: "Diamant",
            sy6: "ܐܕܡܐܣ",
            sy7: "ܐܰܕܰܡܰܐܣ",
            lt12: "",
            sy5: "ܫܳܡܺܝܪܳܐ",
            sy4: "ܫܡܝܪܐ",
            dt2: "",
            id: "877",
            sy8: "",
            lt10: "shomiro"
        },
        {
            dt2: "hinter",
            lt12: "",
            lt11: "",
            sy6: "",
            sy4: "ܒܣܬܪܐ",
            dt1: "Hinterseite",
            sy9: "",
            sy5: "ܒܶܣܬܪܳܐ",
            dt3: "",
            id: "2015",
            sy8: "",
            sy7: "",
            lt10: "bestro"
        },
        {
            sy4: "ܙܡܢ",
            sy5: "ܙܰܡܶܢ",
            lt11: "",
            dt3: "",
            dt2: "",
            sy9: "",
            dt1: "einladen",
            sy6: "",
            id: "1043",
            lt10: "sameen",
            sy8: "",
            sy7: "",
            lt12: ""
        },
        {
            lt10: "mziğutho",
            lt12: "",
            sy9: "",
            sy5: "ܡܙܺܝܓ݂ܽܘܬ݂ܳܐ",
            sy7: "ܡܽܘܙܳܓ݂ܳܐ",
            sy6: "ܡܘܙܓܐ",
            lt11: "muzoğo",
            dt3: "",
            sy8: "",
            sy4: "ܡܙܝܓܘܬܐ",
            dt1: "Vermischung",
            id: "4767",
            dt2: "Mischung"
        },
        {
            sy8: "",
            sy9: "",
            sy6: "",
            sy7: "",
            lt10: "shabĉo yaume",
            dt2: "",
            lt11: "",
            sy5: "ܫܒܰܥܳܐ ܝܰܘ̈ܡܶܐ",
            id: "4149",
            dt3: "",
            sy4: "ܫܒܥܐ ܝܘܡܐ",
            lt12: "",
            dt1: "Sieben Tage"
        },
        {
            dt2: "",
            lt12: "",
            sy6: "",
            lt10: "masĥyo",
            dt1: "Schwimmbecken",
            lt11: "",
            sy4: "ܡܰܣܚܝܳܐ",
            sy7: "",
            sy5: "",
            sy9: "",
            id: "5466",
            dt3: "",
            sy8: ""
        },
        {
            dt1: "Analverkehr",
            id: "5906",
            sy9: "",
            lt12: "",
            sy7: "",
            dt3: "",
            lt10: "mĥablutho",
            dt2: "",
            sy5: "ܡܚܰܒܠܽܘܬ݂ܳܐ",
            sy6: "",
            sy4: "ܡܚܒܠܘܬܐ",
            sy8: "",
            lt11: ""
        },
        {
            sy6: "",
            dt3: "",
            id: "2134",
            sy9: "",
            lt11: "",
            sy7: "",
            sy8: "",
            sy4: "ܛܥܡ ܢܢܐ ܒܝܕ ܠܫܢܐ",
            lt12: "",
            sy5: "ܛܳܥܶܡ ܐ̱ܢܳܐ ܒܝܰܕ ܠܶܫܳܢܳܐ",
            lt10: "țoĉem`no byađ leshono",
            dt2: "",
            dt1: "Ich schmecke mit der Zunge"
        },
        {
            sy9: "",
            lt10: "trayonay gabe",
            dt3: "",
            sy8: "",
            sy6: "",
            lt11: "",
            dt2: "",
            dt1: "zweiseitig",
            sy4: "ܬܪܝܢܝ ܓܒܐ",
            sy7: "",
            lt12: "",
            id: "5394",
            sy5: "ܬܪܰܝܳܢܰܝ ܓܰܒ̈ܶܐ"
        },
        {
            sy9: "ܡܶܬ݂ܦܰܪܓܝܳܢܳܐ",
            sy8: "ܡܬܦܪܓܝܢܐ",
            sy6: "ܡܦܪܓܢܐ",
            dt2: "",
            sy4: "ܡܦܨܚܢܐ",
            dt1: "amüsant",
            dt3: "",
            sy7: "ܡܰܦܪܓܳܢܳܐ",
            id: "5891",
            lt10: "mafŝĥono",
            sy5: "ܡܰܦܨܚܳܢܳܐ",
            lt11: "mafrgono",
            lt12: "methfargyono"
        },
        {
            lt12: "",
            id: "879",
            sy8: "",
            sy6: "",
            sy4: "ܓܠܘܬܐ",
            dt1: "Diaspora",
            lt11: "",
            sy5: "ܓܳܠܽܘܬ݂ܳܐ",
            sy7: "",
            dt2: "",
            dt3: "",
            lt10: "golutho",
            sy9: ""
        },
        {
            lt10: "shuĥlof gauno",
            id: "1256",
            sy8: "",
            sy4: "ܫܘܚܠܦ ܓܘܢܐ",
            lt11: "",
            dt1: "Farbwechsel",
            sy7: "",
            sy5: "ܫܽܘܚܠܳܦ ܓܰܘܢܳܐ",
            sy6: "",
            sy9: "",
            lt12: "",
            dt3: "",
            dt2: ""
        },
        {
            sy4: "ܡܫܪܝܐ ܕܒܢܙܝܢ",
            sy6: "",
            sy8: "",
            sy7: "",
            lt12: "",
            lt10: "mashryo d`benzin",
            id: "5255",
            dt2: "",
            dt3: "",
            dt1: "Zapfsäule",
            sy9: "",
            lt11: "",
            sy5: "ܡܰܫܪܝܳܐ ܕܒܶܢܙܺܝܢ"
        },
        {
            sy4: "ܝܘܚܢܢ",
            lt12: "ܝܢܢܝ",
            dt3: "",
            sy8: "",
            dt2: "",
            dt1: "Jonny",
            lt11: "ܝܘܚܢܢ",
            sy9: "",
            lt10: "ܝܘܚܢܢ",
            id: "2336",
            sy7: "ܝܳܢܢܺܝ",
            sy6: "ܝܢܢܝ",
            sy5: "ܝܢܢܝ"
        },
        {
            sy4: "ܒܢܚܐܝܠ",
            lt12: "",
            dt1: "Ben Hajil",
            sy9: "",
            dt2: "",
            sy8: "",
            sy5: "ܒܶܢܚܰܐܝܺܠ",
            sy6: "",
            id: "547",
            lt10: "ܒܢܚܐܝܠ",
            dt3: "",
            sy7: "",
            lt11: "ܒܢܚܐܝܠ"
        },
        {
            sy6: "ܢܚܡܐ",
            lt11: "nĥomo",
            dt3: "",
            id: "350",
            dt2: "Wiedererwecken",
            sy4: "ܢܘܚܡܐ",
            sy9: "ܩܝܳܡܬܳܐ",
            lt12: "qyomto",
            sy8: "ܩܝܡܬܐ",
            sy7: "ܢܚܳܡܳܐ",
            lt10: "nuĥomo",
            dt1: "Auferstehung",
            sy5: "ܢܽܘܚܳܡܳܐ"
        },
        {
            dt1: "Us",
            lt10: "ܥܘܨ",
            lt11: "ܥܘܨ",
            sy4: "ܥܘܨ",
            sy8: "",
            dt3: "",
            sy6: "",
            sy5: "ܥܽܘܨ",
            sy9: "",
            lt12: "",
            dt2: "",
            id: "4702",
            sy7: ""
        },
        {
            sy6: "",
            dt3: "Boden",
            sy4: "ܡܕܝܪܐ",
            sy9: "",
            dt1: "Fußboden",
            id: "5531",
            sy7: "",
            lt12: "",
            sy5: "ܡܶܕܝܳܪܳܐ",
            lt11: "",
            lt10: "medyoro",
            sy8: "",
            dt2: "Fussboden"
        },
        {
            sy5: "ܚܳܫܽܘܠܳܐ ܥܳܒܶܕ ܥܰܙܩܳܬ݂ܳܐ ܕܕܰܗܒܳܐ",
            sy6: "",
            dt2: "",
            sy4: "ܚܫܘܠܐ ܥܒܕ ܥܙܩܬܐ ܕܕܗܒܐ",
            sy7: "",
            dt3: "",
            lt10: "ĥoshulo ĉobed ĉazqotho d`dahbo",
            lt11: "",
            id: "2369",
            lt12: "",
            dt1: "Juwelier macht goldene Ringe",
            sy9: "",
            sy8: ""
        },
        {
            id: "6067",
            sy6: "ܪܘܟܒܐ",
            sy8: "ܬܘܩܢܐ",
            dt1: "Konstruktion",
            sy7: "ܪܽܘܟܳܒܳܐ",
            lt11: "rukobo",
            lt12: "tuqono",
            dt3: "",
            dt2: "",
            sy9: "ܬܽܘܩܳܢܳܐ",
            sy5: "ܡܪܰܟܒܽܘܬ݂ܳܐ",
            lt10: "mracbutho",
            sy4: "ܡܪܟܒܘܬܐ"
        },
        {
            dt3: "",
            dt1: "Guten Tag",
            sy9: "",
            sy6: "ܫܠܡܐ",
            sy7: "ܫܠܳܡܳܐ",
            lt10: "yaumo țobo",
            lt12: "",
            sy4: "ܝܘܡܐ ܛܒܐ",
            dt2: "",
            id: "1775",
            sy5: "ܝܰܘܡܳܐ ܛܳܒܳܐ",
            lt11: "shlomo",
            sy8: ""
        },
        {
            sy8: "",
            lt10: "egrotho",
            dt2: "",
            id: "707",
            sy7: "ܐܶܓܰܪ̈ܝܳܬ݂ܳܐ",
            dt3: "",
            lt12: "",
            lt11: "egaryotho",
            sy6: "ܐܓܪܝܬܐ",
            sy5: "ܐܶܓܪ̈ܬ݂ܳܐ",
            dt1: "Briefe",
            sy4: "ܐܓܪܬܐ",
            sy9: ""
        },
        {
            dt3: "Lein",
            id: "514",
            dt2: "Leinen",
            lt11: "",
            lt12: "",
            sy9: "",
            sy5: "ܟܶܬܳܢܳܐ",
            sy4: "ܟܬܢܐ",
            sy7: "",
            sy6: "",
            sy8: "",
            dt1: "Baumwolle",
            lt10: "ketono"
        },
        {
            lt12: "",
            sy8: "",
            sy7: "",
            sy4: "ܦܘܩܕܢܐ",
            id: "1499",
            lt11: "",
            sy6: "",
            dt3: "",
            lt10: "fuqdono",
            dt2: "",
            dt1: "Gebot",
            sy5: "ܦܽܘܩܕܳܢܳܐ",
            sy9: ""
        },
        {
            sy7: "",
            sy9: "",
            lt11: "",
            id: "3969",
            lt10: "skhur l`kautho",
            sy5: "ܣܟ݂ܽܘܪ ܠܟܰܘܬ݂ܳܐ",
            dt3: "",
            dt2: "",
            lt12: "",
            sy6: "",
            dt1: "schließe das Fenster",
            sy8: "",
            sy4: "ܣܟܘܪ ܠܟܘܬܐ"
        },
        {
            sy4: "ܩܛܥܐ ܡܨܥܝܐ",
            id: "6367",
            sy6: "",
            sy5: "ܩܶܛܥܳܐ ܡܶܨܥܳܝܳܐ",
            dt2: "",
            sy7: "",
            lt12: "",
            lt11: "",
            dt3: "",
            sy8: "",
            dt1: "Mittelstück",
            lt10: "qețĉo meŝĉoyo",
            sy9: ""
        },
        {
            id: "2013",
            sy9: "",
            sy5: "ܒܳܬ݂ܰܪ ܚܕܕܐ",
            dt2: "",
            lt11: "",
            lt10: "bothar ĥđođe",
            sy7: "",
            sy4: "ܒܬܪ ܚܕܕܐ",
            dt3: "",
            sy6: "",
            lt12: "",
            dt1: "hintereinander",
            sy8: ""
        },
        {
            sy9: "",
            sy5: "ܘܰܙܝܺܪܬܳܐ",
            lt12: "",
            sy6: "",
            lt11: "",
            sy8: "",
            dt2: "",
            dt3: "",
            id: "3044",
            sy4: "ܘܙܝܪܬܐ",
            lt10: "wazirto",
            dt1: "Ministerin",
            sy7: ""
        },
        {
            lt12: "",
            dt1: "Hlita",
            sy5: "ܚܠܺܝܬܰܐ",
            id: "2027",
            lt10: "ܚܠܝܬܐ",
            sy9: "",
            lt11: "ܚܠܝܬܐ",
            sy4: "ܚܠܝܬܐ",
            sy7: "",
            sy8: "",
            dt2: "",
            dt3: "",
            sy6: ""
        },
        {
            sy8: "",
            id: "5419",
            dt2: "",
            sy5: "ܬܠܳܬ̣ ܓܺܓܠܳܐ",
            sy4: "ܬܠܳܬ̣ ܓܺܓܠܳܐ",
            lt10: "tloth giğlo",
            lt11: "",
            dt1: "Dreirad",
            lt12: "",
            sy7: "",
            sy6: "",
            sy9: "",
            dt3: ""
        },
        {
            sy6: "",
            sy5: "ܥܺܝܪܰܩܳܝܳܐ",
            sy7: "",
            id: "2237",
            sy8: "",
            lt12: "",
            dt2: "",
            lt10: "ĉiraqoyo",
            lt11: "",
            sy9: "",
            dt3: "",
            sy4: "ܥܝܪܩܝܐ",
            dt1: "Iraker"
        },
        {
            sy6: "",
            lt11: "ܠܝܐ",
            sy8: "",
            dt2: "",
            sy4: "ܠܝܐ",
            lt12: "",
            dt1: "Lea",
            sy5: "ܠܶܝܰܐ",
            dt3: "",
            id: "2725",
            lt10: "ܠܝܐ",
            sy9: "",
            sy7: ""
        },
        {
            dt3: "",
            sy7: "ܪܟܺܝܢܳܐ",
            sy8: "",
            dt2: "",
            sy9: "",
            lt10: "kustono",
            sy4: "ܟܘܣܬܢܐ",
            lt11: "rkino",
            sy5: "ܟܽܘܣܬܳܢܳܐ",
            id: "6032",
            sy6: "ܪܟܝܢܐ",
            dt1: "hügelig",
            lt12: ""
        },
        {
            dt2: "Siebzehn",
            sy7: "ܫܒܰܥܶܣ̈ܪܶܐ",
            dt3: "",
            lt12: "juth-sayn",
            sy5: "ܫܒܰܥܣܰܪ ",
            lt10: "shbaĉsar",
            id: "18",
            sy8: "ܝܖ",
            sy6: "ܫܒܥܣܪܐ",
            dt1: "17",
            sy4: "ܫܒܥܣܪ",
            sy9: "ܝܖ",
            lt11: "shbaĉesre"
        },
        {
            sy9: "",
            sy7: "",
            sy4: "ܒܝܕ",
            lt10: "byađ",
            id: "6363",
            dt2: "",
            lt12: "",
            dt1: "mittels",
            sy8: "",
            sy6: "",
            sy5: "ܒܝܰܕ",
            lt11: "",
            dt3: ""
        },
        {
            sy9: "",
            lt12: "",
            lt11: "",
            sy7: "",
            sy5: "ܡܰܫܩܠܳܐ",
            id: "3533",
            dt1: "Prozeß",
            sy8: "",
            dt2: "",
            lt10: "mashqlo",
            sy4: "ܡܫܩܠܐ",
            sy6: "",
            dt3: ""
        },
        {
            lt11: "",
            dt1: "Jungs",
            id: "2365",
            sy5: "ܛܠܳܝ̈ܶܐ",
            sy8: "",
            lt10: "țloye",
            sy4: "ܛܠܝܐ",
            lt12: "",
            dt2: "",
            sy6: "",
            sy7: "",
            dt3: "",
            sy9: ""
        },
        {
            lt12: "",
            id: "5967",
            lt11: "",
            dt1: "annähern",
            sy8: "",
            sy4: "ܩܪܒ",
            lt10: "qreb",
            sy5: "ܩܪܶܒ",
            sy7: "",
            sy6: "",
            dt3: "",
            sy9: "",
            dt2: "sich nähern"
        },
        {
            sy4: "ܥܝܕܐ",
            sy6: "",
            dt3: "",
            dt2: "Brauch",
            sy5: "ܥܝܳܕܳܐ",
            dt1: "Sitte",
            sy9: "",
            lt10: "ĉyodo",
            id: "4163",
            lt12: "",
            lt11: "",
            sy7: "",
            sy8: ""
        },
        {
            sy6: "ܒܪ ܐܬܪܐ",
            dt3: "",
            dt2: "",
            sy9: "",
            lt11: "bar athro",
            lt12: "",
            lt10: "bar motho",
            sy7: "ܒܰܪ ܐܰܬ݂ܪܳܐ",
            id: "2702",
            sy4: "ܒܪ ܡܬܐ",
            sy5: "ܒܰܪ ܡܳܬ݂ܳܐ",
            dt1: "Landsmann",
            sy8: ""
        },
        {
            id: "181",
            sy6: "",
            sy8: "",
            sy5: "ܡܰܠܟܳܝܽܘܬ݂ܳܐ",
            lt11: "",
            dt3: "",
            lt12: "",
            lt10: "malkoyutho",
            dt2: "",
            dt1: "Alleinherrschaft",
            sy4: "ܡܠܟܝܘܬܐ",
            sy7: "",
            sy9: ""
        },
        {
            sy8: "",
            id: "1835",
            sy7: "",
            lt10: "hamburger",
            sy9: "",
            dt1: "Hamburger",
            sy4: "ܗܐܡܒܘܪܓܪ",
            sy6: "",
            dt2: "",
            dt3: "",
            sy5: "ܗܰܐܡܒܽܘܪܓܶܪ",
            lt11: "",
            lt12: ""
        },
        {
            sy9: "",
            dt3: "",
            sy6: "",
            lt11: "ܝܒܢܐ",
            sy4: "ܝܒܢܐ",
            lt10: "ܝܒܢܐ",
            sy7: "",
            sy8: "",
            dt1: "Yabno",
            id: "5184",
            dt2: "",
            sy5: "ܝܰܒܢܳܐ",
            lt12: ""
        },
        {
            dt2: "",
            lt10: "ܫܘܦܪܐ",
            sy7: "",
            lt11: "ܫܘܦܪܐ",
            lt12: "",
            sy9: "",
            sy5: "ܫܽܘܦܪܳܐ",
            dt3: "",
            sy8: "",
            dt1: "Schufro",
            id: "4039",
            sy6: "",
            sy4: "ܫܘܦܪܐ"
        },
        {
            lt12: "",
            lt11: "",
            sy7: "",
            lt10: "besro d`ĉarshe",
            dt2: "",
            id: "5235",
            sy4: "ܒܣܪܐ ܕܥܪܫܐ",
            dt3: "",
            sy9: "",
            sy5: "ܒܶܣܪܳܐ ܕܥܰܪ̈ܫܶܐ",
            sy8: "",
            dt1: "Zahnfleisch",
            sy6: ""
        },
        {
            lt12: "",
            sy4: "ܨܠܝܚܐ",
            sy8: "",
            lt10: "ܨܠܝܚܐ",
            dt3: "",
            sy5: "ܨܰܠܺܝܚܰܐ",
            id: "3812",
            sy7: "",
            lt11: "ܨܠܝܚܐ",
            sy6: "",
            dt2: "",
            sy9: "",
            dt1: "Saliha"
        },
        {
            sy8: "",
            sy7: "",
            dt3: "",
            lt11: "",
            id: "5126",
            lt10: "ĥnan shbaqan l`ĥayobeyn",
            sy4: "ܚܢܢ ܫܒܩܢ ܠܚܝܒܝܢ",
            sy6: "",
            sy9: "",
            lt12: "",
            dt1: "Wir vergeben unseren Schuldigern",
            dt2: "",
            sy5: "ܚܢܰܢ ܫܒܰܩܰܢ ܠܚܰܝܳܒܶܝܢ"
        },
        {
            sy5: "ܓܠܽܘܠܺܝܬ݂ܳܐ",
            lt12: "",
            sy6: "",
            sy9: "",
            sy8: "",
            dt3: "",
            dt1: "Revolver",
            dt2: "Pistole",
            sy7: "",
            id: "3712",
            lt10: "glulitho",
            sy4: "ܓܠܘܠܝܬܐ",
            lt11: ""
        },
        {
            dt3: "",
            id: "5059",
            sy8: "",
            sy5: "ܠܶܫܳܢܳܐ ܕܥܳܠܡܳܐ",
            sy4: "ܠܫܢܐ ܕܥܠܡܐ",
            lt12: "",
            lt11: "leshono tibeloyo",
            sy7: "ܠܶܫܳܢܳܐ ܬܺܒܶܝܠܳܝܳܐ",
            lt10: "leshono d`ĉolmo",
            sy6: "ܠܫܢܐ ܬܒܝܠܝܐ",
            sy9: "",
            dt1: "Weltsprache",
            dt2: ""
        },
        {
            sy9: "",
            sy4: "ܢܐܬܠܝܐ",
            dt3: "",
            sy6: "",
            dt2: "Natalya",
            sy8: "",
            sy5: "ܢܰܐܬܰܠܝܰܐ",
            dt1: "Natalia",
            sy7: "",
            lt11: "ܢܐܬܠܝܐ",
            lt10: "ܢܐܬܠܝܐ",
            lt12: "",
            id: "3213"
        },
        {
            lt12: "",
            lt11: "",
            dt2: "",
            sy4: "ܪܕܝܬܐ ܕܙܟܘܬܐ",
            sy5: "ܪܰܕܳܝܬܳܐ ܕܙܳܟ݂ܽܘܬ݂ܳܐ",
            dt3: "",
            id: "4571",
            sy6: "",
            dt1: "Triumphwagen",
            sy8: "",
            lt10: "rađoyto d`zokhutho",
            sy7: "",
            sy9: ""
        },
        {
            sy9: "",
            dt3: "",
            sy5: "ܡܰܬܢܬ݂ܳܐ",
            lt10: "matntho",
            id: "3756",
            sy4: "ܡܬܢܬܐ",
            sy8: "",
            dt2: "",
            sy7: "",
            lt12: "",
            dt1: "Rückenmark",
            sy6: "",
            lt11: ""
        },
        {
            lt11: "",
            sy4: "ܪܩܕ",
            sy7: "",
            sy5: "ܪܩܰܕ",
            dt2: "",
            id: "4381",
            sy8: "",
            lt12: "",
            lt10: "rqađ",
            sy9: "",
            dt3: "",
            sy6: "",
            dt1: "tanzen"
        },
        {
            dt2: "",
            sy5: "ܡܥܰܦܝܳܢܳܐ",
            sy7: "",
            lt10: "mĉafyono",
            lt12: "",
            sy9: "",
            id: "2750",
            dt3: "",
            sy4: "ܡܥܦܝܢܐ",
            sy8: "",
            dt1: "Leichenbestatter",
            sy6: "",
            lt11: ""
        },
        {
            lt10: "dalĉel bkul",
            sy9: "",
            dt1: "am höchsten",
            dt3: "",
            id: "201",
            lt11: "",
            lt12: "",
            sy7: "",
            sy8: "",
            sy4: "ܕܠܥܠ ܒܟܠ",
            dt2: "",
            sy6: "",
            sy5: "ܕܰܠܥܶܠ ܒܟܽܠ"
        },
        {
            sy4: "ܚܘܕܬ݂ܐ",
            lt10: "ܚܘܕܬܐ",
            dt3: "",
            lt12: "",
            sy8: "",
            sy7: "",
            id: "2064",
            sy5: "ܚܽܘܕܰܬ݂ܰܐ",
            sy9: "",
            dt1: "Hudatha",
            dt2: "",
            sy6: "",
            lt11: "ܚܘܕܬܐ"
        },
        {
            lt10: "ܢܗܪܐ",
            dt3: "",
            sy7: "",
            sy8: "",
            sy5: "ܢܽܘܗܪܳܐ",
            lt11: "ܢܗܪܐ",
            id: "3310",
            sy6: "",
            sy4: "ܢܘܗܪܐ",
            lt12: "",
            sy9: "",
            dt2: "",
            dt1: "Nuhro"
        },
        {
            sy9: "",
            sy7: "",
            sy6: "",
            id: "3319",
            sy4: "ܢܘܪܒܐ",
            lt11: "ܢܘܪܒܐ",
            sy8: "",
            dt2: "",
            dt1: "Nurbo",
            lt12: "",
            lt10: "ܢܘܪܒܐ",
            sy5: "ܢܽܘܪܒܳܐ",
            dt3: ""
        },
        {
            sy5: "ܝܺܝܒܺܝܣ",
            id: "3541",
            sy8: "",
            lt11: "",
            dt1: "Pubertät",
            sy7: "",
            sy4: "ܝܝܒܝܣ",
            dt2: "",
            dt3: "",
            sy6: "",
            lt10: "yibis",
            lt12: "",
            sy9: ""
        },
        {
            lt11: "",
            sy7: "",
            lt10: "taqifutho",
            sy8: "",
            dt1: "Intensität",
            sy5: "ܬܰܩܺܝܦܽܘܬ݂ܳܐ",
            lt12: "",
            sy6: "",
            sy4: "ܬܩܝܦܘܬܐ",
            sy9: "",
            id: "2223",
            dt2: "",
            dt3: ""
        },
        {
            dt2: "",
            sy6: "",
            id: "3755",
            dt3: "",
            dt1: "Rückenkissen",
            lt12: "",
            sy8: "",
            sy7: "",
            sy5: "ܒܶܣܰܕܝܳܐ ܕܚܰܨܳܐ",
            sy9: "",
            lt11: "",
            lt10: "besađyo d`ĥaŝo",
            sy4: "ܒܣܕܝܐ ܕܚܨܐ"
        },
        {
            id: "5030",
            lt10: "bakhyo",
            dt3: "",
            sy9: "",
            dt2: "",
            sy6: "",
            sy8: "",
            sy7: "",
            lt12: "",
            dt1: "Weinen",
            lt11: "",
            sy5: "ܒܰܟ݂ܝܳܐ",
            sy4: "ܒܟܝܐ"
        },
        {
            dt1: "Umthin",
            lt12: "",
            sy8: "",
            sy6: "",
            dt2: "",
            sy4: "ܐܘܡܬ݂ܝܢ",
            lt10: "ܐܘܡܬ݂ܝܢ",
            lt11: "ܐܘܡܬ݂ܝܢ",
            sy7: "",
            sy5: "ܐܽܘܡܬ݂ܺܝܢ",
            dt3: "",
            sy9: "",
            id: "4632"
        },
        {
            sy8: "",
            lt11: "ܡܪܐ",
            dt1: "Mara",
            dt3: "",
            sy6: "",
            sy4: "ܡܪܐ",
            sy5: "ܡܰܪܰܐ",
            sy9: "",
            id: "2925",
            dt2: "",
            lt10: "ܡܪܐ",
            sy7: "",
            lt12: ""
        },
        {
            sy8: "",
            sy7: "",
            sy9: "",
            lt10: "ܐܚܝܛܒ",
            id: "160",
            dt2: "Ahitub",
            dt3: "",
            lt11: "ܐܚܝܛܒ",
            dt1: "Ahitob",
            sy4: "ܐܚܝܛܒ",
            lt12: "",
            sy6: "",
            sy5: "ܐܰܚܺܝܛܳܒ"
        },
        {
            sy5: "ܪܥܝܳܐ",
            lt12: "",
            lt11: "",
            dt3: "",
            sy7: "",
            sy6: "",
            sy8: "",
            dt1: "Hirte",
            lt10: "roĉyo",
            dt2: "",
            sy9: "",
            sy4: "ܪܥܝܐ",
            id: "2021"
        },
        {
            dt1: "Vertreibung",
            sy8: "",
            sy6: "",
            lt12: "",
            sy9: "",
            lt10: "țrođo",
            dt3: "",
            sy7: "",
            sy5: "ܛܪܳܕܳܐ",
            lt11: "",
            sy4: "ܛܪܕܐ",
            dt2: "Verjagung",
            id: "4821"
        },
        {
            lt10: "gayso dakbosho",
            sy5: "ܓܰܝܣܳܐ ܕܰܟܒܳܫܳܐ",
            sy6: "",
            dt3: "",
            dt2: "Sturmtruppe",
            id: "236",
            sy4: "ܓܝܣܐ ܕܟܒܫܐ",
            dt1: "Angriffstruppe",
            sy7: "",
            lt11: "",
            sy8: "",
            sy9: "",
            lt12: ""
        },
        {
            sy8: "",
            lt10: "shmayono",
            dt3: "",
            dt2: "",
            sy7: "",
            sy9: "",
            id: "2004",
            dt1: "himmlisch",
            sy4: "ܫܡܝܢܐ",
            lt11: "",
            sy6: "",
            sy5: "ܫܡܰܝܳܢܳܐ",
            lt12: ""
        },
        {
            dt1: "Eselschrei",
            sy4: "ܢܥܪܐ",
            lt10: "nĉoro",
            sy7: "",
            id: "1191",
            sy6: "",
            sy9: "",
            lt11: "",
            dt3: "",
            dt2: "Eselruf",
            sy5: "ܢܥܳܪܳܐ",
            sy8: "",
            lt12: ""
        },
        {
            dt1: "modern",
            id: "3070",
            sy5: "ܕܳܪܳܢܳܝܳܐ",
            lt10: "doronoyo",
            sy9: "",
            sy7: "",
            lt12: "",
            sy4: "ܕܪܢܝܐ",
            sy6: "",
            dt3: "",
            dt2: "",
            sy8: "",
            lt11: ""
        },
        {
            sy7: "",
            sy4: "ܓܠܝܢܐ",
            lt12: "",
            dt1: "Gelyono",
            sy6: "",
            sy5: "ܓܶܠܝܳܢܳܐ",
            sy9: "",
            dt3: "",
            lt11: "ܓܠܝܢܐ",
            lt10: "ܓܠܝܢܐ",
            sy8: "",
            dt2: "",
            id: "1555"
        },
        {
            dt1: "Onkel",
            sy5: "ܚܳܠܳܐ",
            sy7: "ܥܰܡܳܐ",
            sy8: "ܕܕܐ",
            lt11: "ĉammo",
            dt2: "",
            lt12: "dodo",
            lt10: "ĥolo",
            dt3: "",
            id: "3374",
            sy4: "ܚܠܐ",
            sy9: "ܕܳܕܳܐ",
            sy6: "ܥܡܐ"
        },
        {
            sy9: "",
            dt1: "anderswo",
            lt11: "",
            lt10: "bduktho ĥrito",
            dt3: "woanders",
            sy7: "",
            sy8: "",
            lt12: "",
            sy6: "",
            sy4: "ܒܕܘܟܬܐ ܐܚܪܝܬܐ",
            sy5: "ܒܕܽܘܟܬ݂ܳܐ ܐ̱ܚܪܺܝܬܳܐ",
            dt2: "sonst wo",
            id: "5938"
        },
        {
            lt11: "",
            dt2: "",
            sy4: "ܣܪܩ",
            dt3: "",
            sy8: "",
            dt1: "kämmen",
            sy9: "",
            lt12: "",
            lt10: "soreq",
            id: "2399",
            sy5: "ܣܳܪܶܩ",
            sy7: "",
            sy6: ""
        },
        {
            dt2: "frosten",
            lt12: "",
            sy7: "ܐܶܓܠܰܕ",
            sy5: "ܓܠܰܕ",
            id: "1520",
            lt11: "aglađ",
            sy4: "ܓܠܕ",
            sy9: "",
            lt10: "glađ",
            dt1: "gefrieren",
            dt3: "",
            sy6: "ܐܓܠܕ",
            sy8: ""
        },
        {
            lt11: "",
            id: "2376",
            lt10: "qhuthonoyo",
            dt1: "kaffeebraun",
            dt2: "",
            sy6: "",
            sy8: "",
            lt12: "",
            dt3: "",
            sy7: "",
            sy9: "",
            sy4: "ܩܗܘܬܢܝܐ",
            sy5: "ܩܗܽܘܬ݂ܳܢܳܝܳܐ"
        },
        {
            lt12: "",
            dt3: "",
            sy7: "",
            sy9: "",
            sy8: "",
            sy5: "ܡܬܰܠܬ݂ܳܐ",
            dt2: "",
            id: "957",
            sy6: "",
            sy4: "ܡܬܠܬܐ",
            lt10: "mthaltho",
            dt1: "Dreieck",
            lt11: ""
        },
        {
            sy5: "ܟܬ݂ܺܝܒܬܳܐ ܘ ܩܶܪܝܳܢܳܐ",
            lt10: "kthibto u qeryono",
            lt12: "",
            sy8: "",
            id: "4027",
            sy6: "",
            sy7: "",
            dt1: "Schreiben und Lesen",
            dt3: "",
            sy9: "",
            lt11: "",
            dt2: "",
            sy4: "ܟܬܝܒܬܐ ܘ ܩܪܝܢܐ"
        },
        {
            lt11: "emo d'aloho",
            dt3: "",
            sy5: "ܝܳܠܕܰܬ݂ ܐܰܠܳܗܳܐ",
            lt12: "",
            lt10: "yoldath aloho",
            sy6: "ܐܡܐ ܕܐܠܗܐ",
            sy4: "ܝܠܕܬ ܐܠܗܐ",
            sy7: "ܐܶܡܳܐ ܕܰܐܠܳܗܳܐ",
            dt2: "",
            dt1: "Gottes Mutter",
            sy8: "",
            id: "1698",
            sy9: ""
        },
        {
            lt11: "ܝܫܘܥܝܗܒ",
            dt2: "",
            sy7: "",
            dt3: "",
            sy8: "",
            sy6: "",
            dt1: "Jeschujahb",
            lt12: "",
            lt10: "ܝܫܘܥܝܗܒ",
            sy9: "",
            sy4: "ܝܫܘܥܝܗܒ",
            id: "2312",
            sy5: "ܝܶܫܽܘܥܝܰܗܒ"
        },
        {
            sy5: "ܡܫܰܚ",
            sy8: "",
            sy6: "ܐܟܝܠ",
            lt10: "mshaĥ",
            id: "5712",
            sy7: "ܐܰܟܺܝܠ",
            dt1: "abwiegen",
            sy4: "ܡܫܚ",
            lt11: "akil",
            lt12: "",
            sy9: "",
            dt3: "",
            dt2: ""
        },
        {
            sy6: "ܚܒܠܐ",
            sy5: "ܚܰܘܠܳܐ",
            dt3: "",
            dt1: "Seil",
            id: "4101",
            sy7: "ܚܰܒܠܳܐ",
            dt2: "",
            sy8: "",
            sy9: "",
            sy4: "ܚܘܠܐ",
            lt11: "ĥablo",
            lt12: "",
            lt10: "ĥauwlo"
        },
        {
            lt12: "",
            id: "2651",
            sy9: "",
            dt3: "",
            lt10: "ĥardono d`yamo",
            sy8: "",
            lt11: "ĥardono",
            sy4: "ܚܪܕܢܐ ܕܝܡܐ",
            dt1: "Krokodil",
            sy7: "ܚܰܪܕܳܢܳܐ",
            sy5: "ܚܰܪܕܳܢܳܐ ܕܝܰܡܳܐ",
            sy6: "ܚܪܕܢܐ",
            dt2: ""
        },
        {
            sy5: "ܩܝܳܡܳܐ",
            sy8: "",
            lt12: "",
            sy7: "",
            dt1: "Aufstehen",
            lt10: "qyomo",
            lt11: "",
            sy6: "",
            sy9: "",
            sy4: "ܩܝܡܐ",
            id: "359",
            dt2: "",
            dt3: ""
        },
        {
            lt12: "",
            sy5: "ܝܰܗܒ ܡܶܠܬ݂ܳܐ ܕܐܺܝܩܳܪܳܐ",
            id: "1021",
            sy8: "",
            sy9: "",
            lt11: "",
            sy7: "",
            sy4: "ܝܗܒ ܡܠܬܐ ܕܐܝܩܪܐ",
            dt1: "Ehrenwort geben",
            sy6: "",
            dt2: "",
            lt10: "yab meltho d`iqoro",
            dt3: ""
        },
        {
            sy9: "",
            sy7: "ܚܰܙܽܘ",
            sy5: "ܚܰܙܳܐ",
            dt2: "",
            lt10: "ܚܙܐ",
            lt11: "ܚܙܐ",
            dt1: "Haso",
            sy6: "ܚܙܘ",
            dt3: "",
            id: "1882",
            lt12: "ܚܙܘ",
            sy8: "",
            sy4: "ܚܙܐ"
        },
        {
            lt11: "mshoko",
            sy8: "",
            lt10: "mshokutho",
            sy4: "ܡܫܟܘܬܐ",
            dt2: "Fahrlässigkeit",
            dt3: "",
            dt1: "Nachlässigkeit",
            lt12: "",
            id: "3161",
            sy9: "",
            sy7: "ܡܫܳܟܳܐ",
            sy6: "ܡܫܟܐ",
            sy5: "ܡܫܳܟܽܘܬ݂ܳܐ"
        },
        {
            sy8: "",
            sy9: "",
            dt2: "",
            lt11: "",
            sy6: "",
            sy7: "",
            lt10: "ĉumro",
            lt12: "",
            dt1: "Alter",
            dt3: "",
            sy4: "ܥܘܡܪܐ",
            sy5: "ܥܽܘܡܪܳܐ",
            id: "196"
        },
        {
            sy8: "",
            lt12: "",
            sy4: "ܐܘܪܚܘܒܐ",
            dt3: "",
            sy9: "",
            sy5: "ܐܽܘܪܚܽܘܒܰܐ",
            dt2: "",
            id: "4688",
            lt11: "ܐܘܪܚܘܒܐ",
            lt10: "ܐܘܪܚܘܒܐ",
            sy7: "",
            dt1: "Urhuba",
            sy6: ""
        },
        {
            sy7: "ܙܰܠܙܶܠ",
            lt10: "daĥel",
            sy4: "ܕܚܠ",
            id: "5632",
            lt12: "aziĉ",
            dt3: "",
            sy9: "ܐܰܙܺܝܥ",
            dt1: "abschrecken",
            sy6: "ܙܠܙܠ",
            sy8: "ܐܙܝܥ",
            lt11: "zalzel",
            dt2: "",
            sy5: "ܕܰܚܶܠ"
        },
        {
            sy8: "",
            sy4: "ܥܡܢܘܐܝܠ",
            dt2: "Immanuel",
            sy7: "ܐܰܡܰܢܽܘܐܶܝܠ",
            id: "205",
            lt12: "ܐܡܢܘܐܝܠ",
            lt11: "ܥܡܢܘܐܝܠ",
            dt1: "Amanuel",
            dt3: "Manuel",
            sy6: "ܐܡܢܘܐܝܠ",
            sy9: "",
            lt10: "ܥܡܢܘܐܝܠ",
            sy5: "ܥܰܡܰܢܽܘܐܝܶܠ"
        },
        {
            sy4: "ܟܢܫܐ",
            sy5: "ܟܢܳܫܳܐ",
            sy7: "",
            sy6: "",
            lt11: "",
            lt10: "knosho",
            dt3: "",
            sy9: "",
            dt1: "Fegen",
            id: "1274",
            lt12: "",
            sy8: "",
            dt2: ""
        },
        {
            sy8: "",
            lt10: "qeshto d`ĉnone",
            id: "3658",
            dt3: "",
            lt11: "kashțo",
            sy9: "",
            lt12: "",
            sy5: "ܩܶܫܬܳܐ ܕܥܢܳܢܶܐ",
            sy6: "ܟܫܬܐ",
            sy7: "ܟܰܫܬܳܐ",
            dt1: "Regenbogen",
            sy4: "ܩܫܬܐ ܕܥܢܢܐ",
            dt2: ""
        },
        {
            dt1: "Verschaffung",
            lt11: "",
            sy9: "",
            lt12: "",
            id: "4806",
            sy8: "",
            sy4: "ܡܩܢܝܢܘܬܐ",
            sy7: "",
            lt10: "maqnyonutho",
            dt3: "",
            sy5: "ܡܰܩܢܝܳܢܽܘܬ݂ܳܐ",
            sy6: "",
            dt2: ""
        },
        {
            sy4: "ܥܠܬܐ",
            sy5: "ܥܶܠܬ݂ܳܐ",
            lt11: "",
            sy6: "",
            sy7: "",
            lt12: "",
            dt3: "",
            id: "1747",
            dt1: "Grund",
            sy9: "",
            dt2: "Motiv",
            lt10: "ĉeltho",
            sy8: ""
        },
        {
            sy9: "",
            dt1: "Yawno",
            sy8: "",
            sy7: "ܝܰܘܢܳܢ",
            id: "5205",
            dt3: "",
            sy5: "ܝܰܘܢܳܐ",
            sy6: "ܝܘܢܢ",
            lt10: "ܝܘܢܐ",
            lt12: "ܝܘܢܢ",
            lt11: "ܝܘܢܐ",
            sy4: "ܝܘܢܐ",
            dt2: ""
        },
        {
            dt3: "",
            lt10: "ܒܝܠܥܐ",
            sy5: "ܒܺܝܠܥܰܐ",
            sy9: "",
            dt2: "Bilhan",
            sy8: "",
            sy6: "ܒܝܠܥܐܢ",
            lt11: "ܒܝܠܥܐ",
            id: "623",
            lt12: "ܒܝܠܥܐܢ",
            sy4: "ܒܝܠܥܐ",
            dt1: "Bilha",
            sy7: "ܒܺܝܠܥܰܐܢ"
        },
        {
            lt11: "",
            sy8: "",
            sy4: "ܫܝܡܝܐ",
            lt10: "shimoyo",
            dt2: "",
            sy7: "",
            sy5: "ܫܺܝܡܳܝܳܐ",
            id: "4115",
            sy9: "",
            lt12: "",
            dt3: "",
            dt1: "Semite",
            sy6: ""
        },
        {
            dt3: "",
            lt10: "melath buyoyo",
            sy4: "ܡܠܬ ܒܘܝܐܐ",
            dt2: "",
            sy8: "",
            sy7: "",
            lt12: "",
            dt1: "Trostwort",
            id: "4585",
            sy6: "",
            lt11: "",
            sy9: "",
            sy5: "ܡܶܠܰܬ݂ ܒܽܘܝܳܐܳܐ"
        },
        {
            lt12: "",
            dt2: "",
            sy4: "ܙܡܝܪܬܐ ܕܚܘܒܐ",
            lt10: "zmirto d`ĥubo",
            lt11: "",
            sy8: "",
            dt1: "Liebeslied",
            id: "2819",
            sy6: "",
            sy5: "ܙܡܺܝܪܬܳܐ ܕܚܽܘܒܳܐ",
            sy9: "",
            sy7: "",
            dt3: ""
        },
        {
            dt3: "",
            id: "2599",
            dt2: "",
            sy9: "",
            sy6: "ܪܢܝܐ",
            sy7: "ܪܶܢܝܳܐ",
            sy4: "ܛܘܪܛܫܐ",
            lt12: "",
            dt1: "Konzept",
            lt10: "țurțosho",
            lt11: "renyo",
            sy5: "ܛܽܘܪܛܳܫܳܐ",
            sy8: ""
        },
        {
            dt2: "",
            sy6: "ܐܘܪܚܐ ܕܛܘܪܐ",
            id: "4971",
            dt1: "Waldweg",
            sy7: "ܐܽܘܪܚܳܐ ܕܛܽܘܪܳܐ",
            dt3: "",
            sy5: "ܐܽܘܪܚܳܐ ܕܥܳܒܳܐ",
            sy9: "",
            sy8: "",
            lt12: "",
            sy4: "ܐܘܪܚܐ ܕܥܒܐ",
            lt10: "urĥo d`ĉobo",
            lt11: "urĥo d`țuro"
        },
        {
            dt2: "",
            sy7: "",
            lt10: "othwotho",
            lt11: "",
            dt1: "Buchstaben",
            id: "730",
            sy8: "",
            lt12: "",
            sy9: "",
            sy4: "ܐܬܘܬܐ",
            sy5: "ܐܳܬ݂ܘ̈ܳܬ݂ܳܐ",
            sy6: "",
            dt3: ""
        },
        {
            dt2: "Wurst",
            sy8: "",
            dt1: "Bratwurst",
            lt11: "shboțo",
            sy5: "ܣܰܪܣܽܘܩܳܐ",
            lt12: "",
            sy6: "ܫܒܛܐ",
            dt3: "",
            sy4: "ܣܪܣܘܩܐ",
            sy9: "",
            lt10: "sarsuqo",
            id: "689",
            sy7: "ܫܒܳܛܳܐ"
        },
        {
            sy4: "ܙܘܓ",
            dt1: "paaren",
            sy7: "ܐܶܙܕܰܘܰܓ݂",
            lt11: "ezdawağ",
            lt10: "zaweğ",
            id: "3406",
            sy5: "ܙܰܘܶܓ݂",
            sy8: "",
            lt12: "",
            sy9: "",
            dt3: "",
            sy6: "ܐܙܕܘܓ",
            dt2: ""
        },
        {
            lt11: "ܒܪܪܟܝܒ",
            dt1: "Barrakib",
            dt2: "",
            sy7: "",
            sy9: "",
            sy4: "ܒܪܪܟܝܒ",
            id: "470",
            sy5: "ܒܰܪܪܰܟܺܝܒ",
            lt10: "ܒܪܪܟܝܒ",
            lt12: "",
            dt3: "",
            sy6: "",
            sy8: ""
        },
        {
            sy5: "ܦܳܠܚܳܢܳܝܳܐ",
            sy8: "",
            dt3: "",
            sy4: "ܦܠܚܢܝܐ",
            lt12: "",
            lt11: "qroboyo",
            dt2: "",
            dt1: "militärisch",
            sy6: "ܩܪܒܝܐ",
            id: "3032",
            lt10: "folĥonoyo",
            sy9: "",
            sy7: "ܩܪܳܒܳܝܳܐ"
        },
        {
            lt12: "",
            lt11: "",
            sy6: "",
            sy8: "",
            sy7: "",
            sy4: "ܪܓܠܐ",
            lt10: "reğlo",
            dt1: "Fuss",
            sy5: "ܪܶܓ݂ܠܳܐ",
            dt2: "Fuß",
            id: "1436",
            sy9: "",
            dt3: ""
        },
        {
            dt3: "",
            id: "3867",
            dt1: "Schaf",
            sy6: "",
            sy4: "ܥܢܐ",
            sy9: "",
            dt2: "",
            sy8: "",
            sy5: "ܥܳܢܳܐ",
            lt11: "",
            sy7: "",
            lt12: "",
            lt10: "ĉono"
        },
        {
            sy7: "",
            id: "712",
            dt2: "",
            sy6: "",
            sy5: "ܠܳܐ ܬܰܥܰܠܰܢ ܠܢܶܣܝܽܘܢܳܐ",
            sy8: "",
            dt1: "Bringe uns nicht in Versuchung",
            lt12: "",
            dt3: "",
            lt11: "",
            lt10: "lo taĉlan l`nesyuno",
            sy9: "",
            sy4: "ܠܐ ܬܥܠܢ ܠܢܣܝܘܢܐ"
        },
        {
            dt2: "böser Geist",
            sy6: "ܒܪ ܐܒܕܢܐ",
            sy8: "",
            lt12: "",
            sy5: "ܕܰܝܘܳܐ",
            dt1: "Dämon",
            sy7: "ܒܰܪ ܐܰܒܕܳܢܳܐ",
            id: "795",
            dt3: "",
            lt10: "daywo",
            lt11: "bar abđono",
            sy4: "ܕܝܘܐ",
            sy9: ""
        },
        {
            sy4: "ܐܦܪܬ",
            lt10: "ܐܦܪܬ",
            id: "1201",
            lt12: "",
            dt2: "Eufrat",
            dt1: "Euphrat",
            sy5: "ܐܳܦܪܰܬ",
            sy8: "",
            sy9: "",
            sy6: "",
            lt11: "ܐܦܪܬ",
            sy7: "",
            dt3: "Froth"
        },
        {
            lt11: "",
            lt12: "",
            sy4: "ܥܪܥܪ",
            lt10: "ĉarĉar",
            sy9: "",
            sy6: "",
            sy5: "ܥܰܪܥܰܪ",
            sy8: "",
            dt2: "",
            sy7: "",
            dt3: "",
            dt1: "gurgeln",
            id: "1764"
        },
        {
            dt2: "",
            sy4: "ܛܘܟܣ ܚܕܝܘܬܐ ܐܡܪܝܟܝܬܐ",
            dt3: "",
            lt10: "țukos ĥđoyutho amerikoyotho",
            dt1: "Vereinigten Staaten von Amerika",
            sy7: "",
            lt12: "",
            sy8: "",
            sy6: "",
            sy9: "",
            lt11: "",
            sy5: "ܛܽܘܟܳܣ ܚܕܳܝܽܘܬ݂ܳܐ ܐܰܡܶܪܺܝܟܳܝܬܳܐ",
            id: "4725"
        },
        {
            sy8: "",
            lt10: "naĉeg",
            dt2: "",
            dt3: "",
            id: "6308",
            sy9: "",
            dt1: "zu Fall bringen",
            lt11: "",
            lt12: "",
            sy4: "ܢܥܓ",
            sy5: "ܢܰܥܶܓ",
            sy6: "",
            sy7: ""
        },
        {
            sy8: "",
            sy6: "ܪܓܫܢܐܝܬ",
            sy9: "",
            dt2: "einfühlsam",
            sy7: "ܪܶܓ݂ܫܳܢܳܐܝܺܬ݂",
            sy4: "ܪܓܫܢܝܐ",
            id: "1097",
            lt11: "reğshonoith",
            lt10: "reğshonoyo",
            lt12: "",
            dt3: "fühlbar",
            sy5: "ܪܶܓ݂ܫܳܢܳܝܳܐ",
            dt1: "emotional"
        },
        {
            dt3: "",
            dt2: "",
            sy7: "",
            sy4: "ܐܓܪܐ ܕܒܝܬܐ",
            lt12: "",
            sy8: "",
            lt10: "ağro d`bayto",
            lt11: "",
            dt1: "Hausmiete",
            sy9: "",
            sy6: "",
            id: "1906",
            sy5: "ܐܰܓ݂ܪܳܐ ܕܒܰܝܬܳܐ"
        },
        {
            sy8: "",
            sy7: "",
            id: "4845",
            dt1: "Vokabel",
            lt11: "",
            sy5: "ܡܶܠܬ݂ܽܘܢܺܝܬ݂ܳܐ",
            sy9: "",
            sy6: "",
            dt2: "",
            sy4: "ܡܠܬܘܢܝܬܐ",
            lt10: "melthunitho",
            lt12: "",
            dt3: ""
        },
        {
            lt12: "fraĉ",
            sy8: "ܦܪܥ",
            dt1: "Rechenschaft ablegen",
            id: "5585",
            sy7: "ܟܫܰܪ",
            sy5: "ܐܰܟܫܰܪ",
            sy6: "ܟܫܪ",
            sy9: "ܦܪܰܥ",
            dt3: "Prüfung ablegen",
            lt11: "kshar",
            lt10: "akshar",
            dt2: "Eid ablegen",
            sy4: "ܐܟܫܪ"
        },
        {
            sy6: "",
            dt1: "Fanqita",
            lt12: "",
            sy4: "ܦܢܩܝܬܐ",
            dt3: "",
            sy9: "",
            id: "1254",
            sy8: "",
            sy5: "ܦܰܢܩܺܝܬܰܐ",
            sy7: "",
            lt11: "ܦܢܩܝܬܐ",
            lt10: "ܦܢܩܝܬܐ",
            dt2: ""
        },
        {
            sy4: "ܬܠܡܝܕܘܬܐ",
            dt2: "Jüngerschaft",
            sy5: "ܬܰܠܡܺܝܕܽܘܬ݂ܳܐ",
            sy8: "",
            lt12: "",
            lt11: "",
            sy7: "",
            sy6: "",
            id: "6413",
            lt10: "talmiđutho",
            dt1: "Unterweisung",
            dt3: "Ausbildung",
            sy9: ""
        },
        {
            sy4: "ܝܬܝܪ ܒܨܝܪ",
            sy7: "ܒܨܺܝܪ ܒܟܽܠ",
            sy5: "ܝܰܬܺܝܪ ܒܨܺܝܪ",
            sy9: "ܬܰܚܬܳܝ ܒܟܽܠ",
            lt10: "yatir bŝir",
            sy6: "ܒܨܝܪ ܒܟܠ",
            dt1: "minimal",
            lt11: "bŝir bkul",
            lt12: "taĥtoy bkul",
            dt3: "",
            sy8: "ܬܚܬܝ ܒܟܠ",
            id: "3041",
            dt2: ""
        },
        {
            dt3: "",
            dt2: "",
            dt1: "Es ist warm",
            lt11: "",
            sy9: "",
            sy6: "",
            id: "yD9rc9f9TkEjWdGdL7fy",
            "date": {
                "seconds": 1590849853,
                "nanoseconds": 905000000
            },
            sy8: "",
            lt10: "ĥemo yo",
            sy4: "ܚܡܐ ܝܐ",
            sy7: "",
            sy5: "ܚܶܡܳܐ ܝܳܐ",
            lt12: ""
        },
        {
            dt1: "Hobel",
            dt2: "Abel",
            sy7: "",
            lt11: "ܗܒܝܠ",
            dt3: "",
            sy8: "",
            id: "2028",
            sy9: "",
            sy4: "ܗܒܝܠ",
            lt10: "ܗܒܝܠ",
            lt12: "",
            sy5: "ܗܳܒܶܝܠ",
            sy6: ""
        },
        {
            sy7: "",
            dt2: "",
            lt12: "",
            lt10: "sahar",
            sy4: "ܙܗܪ",
            dt3: "",
            sy9: "",
            sy6: "",
            dt1: "ermahnen",
            id: "1159",
            sy8: "",
            lt11: "",
            sy5: "ܙܰܗܰܪ"
        },
        {
            dt1: "Pentius Pilatus",
            lt11: "",
            sy9: "",
            sy5: "ܦܶܢܛܝܺܘܣ ܦܺܝܠܰܛܽܘܣ",
            sy6: "",
            lt10: "fențius filațus",
            sy7: "",
            id: "3433",
            dt3: "",
            dt2: "",
            lt12: "",
            sy8: "",
            sy4: "ܦܢܛܝܘܣ ܦܝܠܛܘܣ"
        },
        {
            sy8: "",
            dt3: "",
            dt1: "Hanniel",
            dt2: "",
            sy6: "",
            sy5: "ܚܰܢܢܺܝܶܠ",
            id: "1865",
            sy4: "ܚܢܢܝܠ",
            sy9: "",
            lt11: "ܚܢܢܝܠ",
            sy7: "",
            lt12: "",
            lt10: "ܚܢܢܝܠ"
        },
        {
            dt1: "Dieb",
            lt12: "",
            id: "886",
            sy9: "",
            sy7: "",
            lt11: "",
            sy6: "",
            dt3: "Einbrecher",
            dt2: "Räuber",
            sy5: "ܓܰܢܳܒܳܐ",
            sy8: "",
            sy4: "ܓܢܒܐ",
            lt10: "ganobo"
        },
        {
            dt3: "",
            sy9: "ܡܓܺܝܠܳܢܽܘܬ݂ܳܐ",
            lt11: "meshtamronutho",
            sy6: "ܡܫܬܡܪܢܘܬܐ",
            sy7: "ܡܶܫܬܰܡܪܳܢܽܘܬ݂ܳܐ",
            dt1: "Abweisung",
            sy4: "ܡܕܚܩܢܘܬܐ",
            id: "5706",
            dt2: "",
            sy5: "ܡܕܰܚܩܳܢܽܘܬ݂ܳܐ",
            sy8: "ܡܓܝܠܢܘܬܐ",
            lt12: "mgilonutho",
            lt10: "mdaĥqonutho"
        },
        {
            dt1: "Vaterschaft",
            dt3: "",
            sy7: "",
            lt12: "",
            lt10: "abohutho",
            dt2: "",
            lt11: "",
            sy6: "",
            sy5: "ܐܰܒܳܗܽܘܬ݂ܳܐ",
            sy9: "",
            id: "4710",
            sy4: "ܐܒܗܘܬܐ",
            sy8: ""
        },
        {
            id: "5373",
            lt10: "lablabto",
            dt1: "Zünglein",
            dt3: "",
            dt2: "Mundzäpfchen",
            sy8: "",
            sy6: "",
            sy7: "",
            lt12: "",
            sy4: "ܠܒܠܒܬܐ",
            sy5: "ܠܰܒܠܰܒܬܳܐ",
            sy9: "",
            lt11: ""
        },
        {
            lt12: "",
            sy4: "ܛܥܡܐ ܐܚܪܝܢܐ",
            dt2: "",
            sy8: "",
            id: "6428",
            sy9: "",
            sy5: "ܛܰܥܡܳܐ ܐ̱ܚܪܺܝܢܳܐ",
            dt3: "",
            sy6: "",
            dt1: "Beigeschmack",
            sy7: "",
            lt10: "țaĉmo ĥrino",
            lt11: ""
        },
        {
            sy4: "ܪܚܡ",
            lt12: "",
            sy5: "ܪܚܶܡ",
            dt1: "liebhaben",
            dt2: "",
            sy8: "",
            sy9: "",
            lt10: "rĥem",
            sy6: "ܪܚܡ",
            dt3: "",
            lt11: "rĥam",
            sy7: "ܪܚܰܡ",
            id: "2820"
        },
        {
            dt2: "",
            sy6: "",
            id: "5135",
            sy4: "ܘܙܝܪܘܬܐ ܕܡܫܘܚܬܐ",
            dt1: "Wirtschaftsministerium",
            sy9: "",
            lt11: "",
            lt10: "wazirutho d`mashuĥto",
            lt12: "",
            dt3: "",
            sy5: "ܘܰܙܺܝܪܽܘܬ݂ܳܐ ܕܡܰܫܽܘܚܬܳܐ",
            sy7: "",
            sy8: ""
        },
        {
            sy5: "ܣܽܘܪܚܳܢ ܥܠܰܝܡܽܘܬ݂ܳܐ",
            dt1: "Jugendkriminalität",
            dt3: "",
            sy8: "",
            lt12: "",
            lt11: "",
            id: "6397",
            sy6: "",
            sy9: "",
            dt2: "",
            sy7: "",
            lt10: "surĥon ĉlaymutho",
            sy4: "ܣܘܪܚܢ ܥܠܝܡܘܬܐ"
        },
        {
            sy6: "",
            lt11: "ܐܪܘܛ",
            sy5: "ܐܰܪܽܘܛ",
            dt2: "",
            sy7: "",
            sy4: "ܐܪܘܛ",
            lt12: "",
            sy8: "",
            dt1: "Arout",
            lt10: "ܐܪܘܛ",
            id: "300",
            dt3: "",
            sy9: ""
        },
        {
            sy7: "",
            sy9: "",
            lt12: "",
            sy5: "ܢܰܝܙܟ݂ܳܐ",
            sy8: "",
            id: "912",
            dt2: "",
            lt10: "nayzkho",
            lt11: "",
            sy6: "",
            dt3: "",
            sy4: "ܢܰܝܙܟ݂ܳܐ",
            dt1: "Dolch"
        },
        {
            lt11: "",
            sy9: "",
            sy5: "ܕܶܚܠܬ݂ܳܐ ܪܰܒܬ݂ܳܐ",
            id: "1737",
            lt10: "deĥltho rabtho",
            sy6: "",
            dt1: "große Angst",
            sy8: "",
            dt2: "",
            sy7: "",
            lt12: "",
            dt3: "",
            sy4: "ܕܚܠܬܐ ܪܒܬܐ"
        },
        {
            sy7: "ܓܰܐܒܰܝ",
            dt3: "",
            sy4: "ܓܒܝ",
            lt11: "ܓܒܝ",
            dt1: "Gabay",
            dt2: "",
            sy9: "",
            id: "1447",
            sy8: "",
            lt10: "ܓܒܝ",
            sy5: "ܓܰܒܰܝ",
            lt12: "ܓܐܒܝ",
            sy6: "ܓܐܒܝ"
        },
        {
            sy7: "ܕܰܐܢܳܐ",
            sy5: "ܢܰܢܳܐ",
            sy8: "",
            id: "806",
            lt10: "ܕܢܐ",
            dt1: "Dano",
            sy6: "ܕܐܢܐ",
            lt11: "ܕܢܐ",
            sy9: "",
            dt3: "",
            lt12: "ܕܐܢܐ",
            dt2: "",
            sy4: "ܕܢܐ"
        },
        {
            sy4: "ܣܘܡܩܐ",
            sy9: "",
            sy7: "",
            lt11: "",
            dt3: "",
            lt10: "sumoqo",
            id: "3749",
            lt12: "",
            sy6: "",
            dt1: "rot",
            sy8: "",
            dt2: "",
            sy5: "ܣܽܘܡܳܩܳܐ"
        },
        {
            sy5: "ܠܚܽܘܕ ܗܰܝܡܳܢܽܘܬ݂ܳܐ ܕܒܰܐܠܳܗܳܐ ܡܶܬ݂ܚܰܫܒܳܐ",
            lt10: "Lĥud haymonutho dbaloho methĥashbo",
            sy6: "",
            sy8: "",
            sy4: "ܠܚܘܕ ܗܝܡܢܘܬܐ ܕܒܐܠܗܐ ܡܬܚܫܒܐ",
            lt11: "",
            lt12: "",
            dt2: "",
            sy9: "",
            dt1: "Nur der Glaube Gottes zählt",
            dt3: "",
            sy7: "",
            id: "3315"
        },
        {
            lt12: "",
            lt11: "ܫܘܒܟ",
            lt10: "ܫܘܒܟ",
            dt3: "",
            dt2: "",
            sy6: "",
            sy7: "",
            sy4: "ܫܘܒܟ",
            id: "4177",
            dt1: "Sobak",
            sy8: "",
            sy9: "",
            sy5: "ܫܽܘܒܰܟ"
        },
        {
            lt10: "shiđo",
            sy8: "",
            sy6: "ܣܛܢܐ",
            dt3: "",
            sy4: "ܫܐܕܐ",
            lt12: "",
            sy9: "",
            sy5: "ܫܺܐܕܳܐ",
            id: "4446",
            lt11: "soțono",
            sy7: "ܣܳܛܳܢܳܐ",
            dt1: "Teufel",
            dt2: "Satan"
        },
        {
            sy8: "",
            lt10: "ܒܗܝܐ",
            sy6: "",
            sy5: "ܒܰܗܺܝܰܐ",
            sy4: "ܒܗܝܐ",
            lt11: "ܒܗܝܐ",
            id: "420",
            lt12: "",
            dt3: "",
            sy9: "",
            dt2: "",
            dt1: "Bahiya",
            sy7: ""
        },
        {
            sy5: "ܢܺܝܚܽܘܬ݂ܳܐ",
            lt12: "",
            sy6: "",
            dt1: "Entspannung",
            dt3: "",
            lt11: "",
            id: "1117",
            sy4: "ܢܝܚܘܬܐ",
            sy9: "",
            sy8: "",
            sy7: "",
            lt10: "niĥutho",
            dt2: ""
        },
        {
            sy5: "ܥܠܰܝܡܳܐ",
            sy6: "ܢܥܝܡܐ",
            id: "2360",
            sy4: "ܥܠܝܡܐ",
            lt12: "",
            sy8: "",
            lt11: "naĉimo",
            dt2: "",
            lt10: "ĉlaymo",
            dt1: "jung",
            sy9: "",
            sy7: "ܢܰܥܺܝܡܳܐ",
            dt3: ""
        },
        {
            sy4: "ܡܠܓܐ",
            sy5: "ܡܠܳܓ݂ܳܐ",
            lt11: "",
            id: "5721",
            lt12: "",
            lt10: "mloğo",
            dt1: "Abziehen",
            sy9: "",
            sy8: "",
            dt2: "",
            sy6: "",
            dt3: "",
            sy7: ""
        },
        {
            dt3: "",
            sy5: "ܝܰܨܽܘܦܽܘܬ݂ܳܐ",
            lt12: "",
            sy7: "ܝܰܨܺܝܦܽܘܬ݂ܳܐ",
            sy9: "",
            lt10: "yaŝufutho",
            lt11: "yaŝifutho",
            sy8: "",
            dt2: "Pflege",
            dt1: "Fürsorge",
            sy6: "ܝܨܝܦܘܬܐ",
            id: "1431",
            sy4: "ܝܨܘܦܘܬܐ"
        },
        {
            id: "594",
            dt2: "",
            sy6: "ܚܕܪ",
            lt11: "ĥođar",
            dt1: "betteln",
            lt12: "",
            lt10: "ĥđar",
            sy7: "ܚܳܕܰܪ",
            dt3: "",
            sy5: "ܚܕܰܪ",
            sy4: "ܚܕܪ",
            sy9: "",
            sy8: ""
        },
        {
            sy9: "ܦܳܠܚܽܘ̈ܬ݂ܶܐ",
            dt3: "",
            sy4: "ܚܝܠܘܬܐ",
            sy7: "ܥܰܣܟܰܪ",
            lt12: "folĥuthe",
            lt11: "ĉaskar",
            dt1: "Armee",
            sy5: "ܚܰܝ̈ܠܰܘܳܬ݂ܳܐ",
            lt10: "ĥaylawotho",
            dt2: "Militär",
            sy6: "ܥܣܟܪ",
            sy8: "ܦܠܚܘܬܐ",
            id: "296"
        },
        {
            lt10: "bumo",
            sy8: "",
            sy5: "ܒܘܽܡܳܐ",
            lt12: "",
            lt11: "",
            id: "1199",
            sy6: "",
            dt1: "Eule",
            sy4: "ܒܘܡܐ",
            sy9: "",
            dt2: "",
            dt3: "",
            sy7: ""
        },
        {
            sy8: "",
            sy4: "ܠܡܦܐܕܐ",
            sy5: "ܠܰܡܦܺܐܕܳܐ",
            lt11: "ܠܡܦܐܕܐ",
            id: "2697",
            lt10: "ܠܡܦܐܕܐ",
            dt2: "",
            dt3: "",
            dt1: "Lamfido",
            sy6: "",
            sy9: "",
            sy7: "",
            lt12: ""
        },
        {
            dt1: "ihnen",
            sy5: "ܠܗܶܝܢ",
            lt12: "",
            id: "2157",
            lt11: "lhun",
            sy6: "ܠܗܘܢ",
            sy8: "",
            sy9: "",
            dt2: "",
            lt10: "lhen",
            sy7: "ܠܗܽܘܢ",
            dt3: "",
            sy4: "ܠܗܝܢ"
        },
        {
            dt3: "",
            lt10: "bkhiroith",
            sy4: "ܒܟܝܪܐܝܬ",
            sy7: "",
            dt2: "",
            dt1: "früh",
            id: "1415",
            lt11: "",
            sy8: "",
            sy9: "",
            lt12: "",
            sy5: "ܒܟ݂ܝܽܪܳܐܺܝܬ݂",
            sy6: ""
        },
        {
            sy8: "",
            id: "6195",
            sy6: "",
            dt2: "",
            dt1: "Kriegstage",
            lt11: "",
            dt3: "",
            lt12: "",
            sy9: "",
            lt10: "yaumay qrobo",
            sy5: "ܝܰܘ̈ܡܰܝ ܩܪܳܒܳܐ",
            sy7: "",
            sy4: "ܝܘܡܝ ܩܪܒܐ"
        },
        {
            sy7: "",
            sy4: "ܟܢܥܢ",
            lt10: "ܟܢܥܢ",
            sy9: "",
            dt1: "Kenan",
            dt3: "",
            sy8: "",
            dt2: "",
            id: "2453",
            lt12: "",
            lt11: "ܟܢܥܢ",
            sy5: "ܟܶܢܥܰܢ",
            sy6: ""
        },
        {
            sy6: "",
            lt12: "",
            dt2: "",
            lt10: "hauno",
            dt3: "",
            sy7: "",
            lt11: "",
            sy8: "",
            sy9: "",
            id: "4810",
            sy4: "ܗܘܢܐ",
            dt1: "Verstand",
            sy5: "ܗܰܘܢܳܐ"
        },
        {
            sy4: "ܦܢܘܘܢ ܠܝܡܝܢܐ ܟܢ ܠܣܡܠܐ",
            id: "5064",
            sy8: "",
            sy5: "ܦܢܰܘܽܘܢ ܠܝܰܡܺܝܢܳܐ ܟܶܢ ܠܣܶܡܳܠܳܐ",
            lt10: "fnawun l`yamino ken l`semolo",
            dt1: "wendet euch von rechts nach links",
            lt11: "",
            sy9: "",
            dt2: "",
            dt3: "",
            lt12: "",
            sy7: "",
            sy6: ""
        },
        {
            lt12: "",
            sy8: "",
            dt1: "Identität",
            sy6: "",
            sy9: "",
            lt10: "hiyutho",
            dt2: "",
            sy5: "ܗܺܝܝܽܘܬ݂ܳܐ",
            id: "2149",
            sy4: "ܗܝܝܘܬܐ",
            sy7: "",
            dt3: "",
            lt11: ""
        },
        {
            sy7: "",
            dt1: "Weiße Linie",
            sy6: "",
            sy5: "ܚܛܽܘܛܳܐ ܚܶܘܳܪܳܐ",
            dt3: "",
            sy4: "ܚܛܘܛܐ ܚܘܪܐ",
            lt11: "",
            sy8: "",
            lt12: "",
            sy9: "",
            lt10: "ĥțuțo ĥeworo",
            dt2: "",
            id: "5036"
        },
        {
            sy5: "ܚܳܢܘܳܝܳܐ",
            lt11: "",
            lt10: "ĥonwoyo",
            dt1: "Obsthändler",
            dt2: "",
            sy8: "",
            id: "6124",
            sy4: "ܚܢܘܝܐ",
            sy6: "",
            sy9: "",
            sy7: "",
            lt12: "",
            dt3: ""
        },
        {
            sy6: "",
            dt3: "",
            sy9: "",
            sy5: "ܡܰܟܫܰܪ",
            sy7: "",
            sy4: "ܡܟܫܪ",
            lt12: "",
            lt10: "makshar",
            lt11: "",
            dt1: "bestehen",
            dt2: "etwas bestehen",
            sy8: "",
            id: "576"
        },
        {
            sy4: "ܕܠܝܬܐ",
            dt3: "",
            sy7: "",
            lt12: "",
            lt10: "dolitho",
            sy9: "",
            sy5: "ܕܳܠܺܝܬ݂ܳܐ",
            dt2: "",
            sy6: "",
            lt11: "",
            id: "5750",
            dt1: "Adernanschwellung",
            sy8: ""
        },
        {
            sy7: "",
            sy5: "ܬܡܺܝ̈ܢܳܝܰܝ ܓܽܘ̈ܢܰܘܳܬ݂ܳܐ",
            id: "5728",
            lt10: "tminoyay gunawotho",
            lt12: "",
            sy9: "",
            dt2: "",
            sy4: "ܬܡܝܢܝܝ ܓܘܢܘܬܐ",
            sy6: "",
            dt3: "",
            dt1: "Achteck",
            sy8: "",
            lt11: ""
        },
        {
            dt2: "",
            sy5: "ܩܰܝܣܽܘܣܳܐ",
            lt11: "qaysuso d`shtoyo",
            sy8: "",
            sy9: "",
            dt1: "Strohhalm",
            sy6: "ܩܝܣܘܣ܏܏ܐ ܕܫܬܝܐ",
            lt10: "qaysuso",
            sy4: "ܩܝܣܘܣ܏܏ܐ",
            sy7: "ܩܰܝܣܽܘܣܳܐ ܕܫܬܳܝܳܐ",
            lt12: "",
            dt3: "",
            id: "4298"
        },
        {
            sy7: "",
            sy6: "",
            sy9: "",
            id: "6337",
            sy8: "",
            lt12: "",
            lt11: "",
            dt2: "",
            dt3: "",
            dt1: "Turnschuh",
            lt10: "msono d'duroshe",
            sy5: "ܡܣܐܳܢܳܐ ܕܕܽܘܪܳܫܶܐ",
            sy4: "ܡܣܐܢܐ ܕܕܘܪܫܐ"
        },
        {
            lt11: "",
            sy9: "",
            sy7: "",
            sy4: "ܡܢܢܐ",
            sy5: "ܡܰܢ̱ܢܳܐ",
            dt2: "Himmelsbrot",
            dt1: "Manna",
            id: "6325",
            sy6: "",
            lt10: "manno",
            lt12: "",
            sy8: "",
            dt3: ""
        },
        {
            lt11: "",
            dt3: "",
            dt1: "Namen",
            sy4: "ܫܡܗܐ",
            sy9: "",
            sy5: "ܫܡܳܗܶܐ",
            dt2: "",
            id: "3197",
            sy8: "",
            lt10: "shmohe",
            lt12: "",
            sy6: "",
            sy7: ""
        },
        {
            id: "5143",
            sy6: "",
            sy4: "ܐܝܟܐ ܐܢܬ",
            lt11: "",
            dt1: "Wo bist du",
            lt12: "",
            sy7: "",
            sy5: "ܐܰܝܟܳܐ ܐܰܢ̱ܬ",
            dt2: "",
            sy9: "",
            sy8: "",
            dt3: "",
            lt10: "ayko at"
        },
        {
            sy8: "",
            sy5: "ܒܶܗܢܐܰܡ",
            sy6: "ܒܗܢܐܢ",
            dt3: "",
            id: "532",
            dt2: "",
            lt12: "ܒܗܢܐܢ",
            lt11: "ܒܗܢܡ",
            sy7: "ܒܶܗܢܐܰܢ",
            dt1: "Behnam",
            lt10: "ܒܗܢܡ",
            sy9: "",
            sy4: "ܒܗܢܡ"
        },
        {
            sy6: "ܒܪ ܐܓܪܐ",
            lt11: "bar egoro",
            sy9: "",
            lt12: "",
            dt3: "",
            sy8: "",
            dt2: "Satan",
            dt1: "Teufel",
            lt10: "gorso rabo",
            sy4: "ܓܪܣܐ ܪܒܐ",
            id: "4445",
            sy5: "ܓܳܪܣܳܐ ܪܰܒܳܐ",
            sy7: "ܒܰܪ ܐܶܓܳܪܳܐ"
        },
        {
            sy7: "",
            sy9: "",
            lt10: "ܒܪܨܘܡܐ",
            dt1: "Barsaumo",
            dt3: "",
            sy8: "",
            lt11: "ܒܪܨܘܡܐ",
            id: "475",
            sy5: "ܒܰܪܨܰܘܡܳܐ",
            lt12: "",
            sy6: "",
            dt2: "Barsawmo",
            sy4: "ܒܪܨܘܡܐ"
        },
        {
            id: "6043",
            sy8: "",
            lt11: "abub shmoĉo",
            sy6: "ܐܒܘܒ ܫܡܥܐ",
            sy4: "ܡܫܡܥܢܝܬܐ",
            dt1: "Hörgerät",
            dt3: "",
            sy5: "ܡܰܫܡܥܳܢܺܝܬ݂ܳܐ",
            sy9: "",
            lt12: "",
            dt2: "Hörapparat",
            sy7: "ܐܰܒܽܘܒ ܫܡܳܥܳܐ",
            lt10: "mashmĉonitho"
        },
        {
            sy5: "ܥܕܰܡܳܐ ܠܥܳܠܰܡ",
            sy9: "",
            sy4: "ܥܕܡܐ ܠܥܠܡ",
            id: "628",
            sy7: "",
            lt12: "",
            dt1: "bis ewig",
            dt2: "",
            sy8: "",
            lt10: "ĉđamo l`ĉolam",
            lt11: "",
            sy6: "",
            dt3: ""
        },
        {
            sy5: "ܦܽܘܘܳܩܳܐ",
            sy7: "ܦܽܘܩܕܳܐ",
            lt12: "",
            dt3: "",
            dt1: "Schluckauf",
            id: "3975",
            lt11: "fuqdo",
            sy9: "",
            sy6: "ܦܘܩܕܐ",
            sy8: "",
            lt10: "fuwoqo",
            dt2: "",
            sy4: "ܦܘܘܩܐ"
        },
        {
            sy6: "ܡܨܥܝܐ",
            sy9: "",
            id: "3064",
            sy4: "ܦܠܓܐ",
            sy5: "ܦܰܠܓܳܐ",
            lt10: "falgo",
            dt3: "",
            lt11: "meŝĉoyo",
            dt1: "Mitte",
            sy7: "ܡܶܨܥܳܝܳܐ",
            lt12: "",
            sy8: "",
            dt2: ""
        },
        {
            sy6: "",
            sy8: "",
            dt1: "Willst du mich verarschen",
            dt2: "Möchtest du mich verarschen",
            lt10: "kubĉat mțaysatli",
            dt3: "",
            lt12: "",
            sy9: "",
            lt11: "",
            sy7: "",
            sy5: "ܟܽܘܒܥܰܬ ܡܛܰܝܙܰܬܠܺܝ",
            id: "5113",
            sy4: "ܟܘܒܥܬ ܡܛܝܙܬܠܝ"
        },
        {
            dt3: "",
            lt10: "facico",
            lt12: "faĥshusho",
            sy7: "ܡܬܰܒܪܳܢܳܐ",
            sy6: "ܡܬܒܪܢܐ",
            sy4: "ܦܟܝܟܐ",
            dt2: "",
            sy5: "ܦܰܟܺܝܟܳܐ",
            dt1: "zerbrechlich",
            id: "6273",
            lt11: "mtabrono",
            sy9: "ܦܰܚܫܽܘܫܳܐ",
            sy8: "ܦܚܫܘܫܐ"
        },
        {
            id: "306",
            sy4: "ܐܣܝܐ ܡܥܝܢ ܟܪܝܗܐ",
            sy6: "",
            sy5: "ܐܳܣܝܳܐ ܡܥܰܝܶܢ ܟܪܺ̈ܝܗܶܐ",
            sy8: "",
            sy7: "",
            lt12: "",
            dt1: "Arzt untersucht Patienten",
            dt3: "",
            dt2: "",
            lt11: "",
            lt10: "osyo mĉayen krihe",
            sy9: ""
        },
        {
            lt10: "siĉto",
            sy5: "ܣܺܝܥܬܳܐ",
            lt12: "",
            dt1: "Komitee",
            sy9: "",
            dt2: "Kommission",
            id: "2564",
            sy8: "",
            sy4: "ܣܝܥܬܐ",
            dt3: "",
            lt11: "",
            sy6: "",
            sy7: ""
        },
        {
            sy6: "ܣܟܣ",
            sy9: "",
            id: "4132",
            sy4: "ܓܢܣܐ",
            dt3: "",
            lt12: "",
            sy5: "ܓܶܢܣܳܐ",
            lt11: "seks",
            lt10: "genso",
            sy7: "ܣܶܟܣ",
            sy8: "",
            dt1: "Sex",
            dt2: ""
        },
        {
            lt10: "ramsho",
            lt12: "Edon",
            dt3: "",
            dt2: "",
            sy9: "",
            dt1: "Abend",
            sy7: "",
            sy8: "",
            id: "74",
            lt11: "",
            sy4: "ܪܡܫܐ",
            sy5: "ܪܰܡܫܳܐ",
            sy6: ""
        },
        {
            lt12: "",
            id: "3715",
            sy8: "",
            dt2: "Schiedsrichter",
            lt10: "dayono",
            dt1: "Richter",
            sy9: "",
            sy5: "ܕܰܝܳܢܳܐ",
            sy6: "",
            lt11: "",
            sy4: "ܕܝܢܐ",
            sy7: "",
            dt3: ""
        },
        {
            sy7: "",
            lt10: "ĉsar zabnin",
            sy5: "ܥܣܰܪ ܙܰܒܢܺܝܢ",
            lt12: "",
            sy9: "",
            sy6: "",
            dt1: "zehnmal",
            dt2: "",
            sy8: "",
            id: "5276",
            lt11: "",
            dt3: "",
            sy4: "ܥܣܪ ܙܒܢܝܢ"
        },
        {
            sy6: "",
            dt2: "",
            id: "1283",
            dt3: "",
            sy9: "",
            sy4: "ܦܠܐܬܐ",
            sy5: "ܦܶܠܶܐܬܰܐ",
            lt10: "ܦܠܐܬܐ",
            dt1: "Feleta",
            lt12: "",
            sy7: "",
            sy8: "",
            lt11: "ܦܠܐܬܐ"
        },
        {
            dt1: "Eis",
            sy4: "ܓܠܝܕܐ",
            sy9: "",
            sy6: "",
            sy5: "ܓܠܺܝܕܳܐ",
            lt12: "",
            dt3: "Eiscreme",
            lt10: "gliđo",
            sy8: "",
            lt11: "",
            sy7: "",
            dt2: "Frost",
            id: "1060"
        },
        {
            lt11: "ĉamuqutho",
            dt1: "Tiefe",
            lt10: "ĉumqo",
            dt3: "",
            dt2: "",
            sy7: "ܥܰܡܽܘܩܽܘܬ݂ܳܐ",
            sy6: "ܥܡܘܩܘܬܐ",
            lt12: "",
            sy5: "ܥܽܘܡܩܳܐ",
            sy8: "",
            sy4: "ܥܘܡܩܐ",
            sy9: "",
            id: "4476"
        },
        {
            dt3: "",
            sy5: "ܡܳܪܝ̱ ܡܰܠܟܶܐ",
            sy6: "",
            lt11: "ܡܪܝ ܡܠܟܐ",
            sy7: "",
            sy9: "",
            sy8: "",
            lt12: "",
            dt2: "",
            id: "3095",
            dt1: "Mor Malke",
            sy4: "ܡܪܝ ܡܠܟܐ",
            lt10: "ܡܪܝ ܡܠܟܐ"
        },
        {
            lt12: "",
            dt2: "",
            lt10: "dodo ĉatiro",
            sy7: "",
            sy5: "ܕܳܕܳܐ ܥܰܬܺܝܪܳܐ",
            dt1: "reicher Onkel",
            dt3: "",
            sy4: "ܕܕܐ ܥܬܝܪܐ",
            id: "3670",
            sy8: "",
            lt11: "",
            sy9: "",
            sy6: ""
        },
        {
            dt2: "",
            sy8: "",
            dt3: "",
            sy6: "",
            lt11: "",
            sy9: "",
            sy4: "ܟܠܘܣܬܐ",
            sy5: "ܟܰܠܽܘܣܬܳܐ",
            dt1: "Puppe",
            sy7: "",
            lt12: "",
            id: "3549",
            lt10: "kalusto"
        },
        {
            sy7: "",
            id: "2083",
            lt11: "ܚܘܬܪܐ",
            sy5: "ܚܽܘܬ݂ܪܳܐ",
            sy6: "",
            dt3: "",
            sy4: "ܚܘܬ݂ܪܐ",
            sy8: "",
            dt2: "",
            dt1: "Huthro",
            lt10: "ܚܘܬܪܐ",
            lt12: "",
            sy9: ""
        },
        {
            sy8: "",
            sy6: "ܫܝܓܐ",
            dt2: "",
            sy7: "ܫܝܳܓ݂ܳܐ",
            sy4: "ܡܫܓܐ",
            id: "4996",
            lt10: "mshoğo",
            dt1: "Waschen",
            lt12: "",
            lt11: "shyogo",
            sy9: "",
            dt3: "",
            sy5: "ܡܫܳܓ݂ܳܐ"
        },
        {
            dt2: "",
            dt1: "einzahlen",
            sy7: "",
            sy5: "ܐܰܕܡܶܟ݂ ܙܽܘ̈ܙܶܐ",
            lt11: "",
            id: "6404",
            lt12: "",
            sy8: "",
            sy9: "",
            sy4: "ܐܕܡܟ ܙܘܙܐ",
            sy6: "",
            lt10: "admekh zuze",
            dt3: ""
        },
        {
            lt10: "melĥo",
            sy8: "",
            lt11: "",
            dt3: "",
            sy7: "",
            sy9: "",
            dt1: "Salz",
            id: "3815",
            sy4: "ܡܠܚܐ",
            dt2: "",
            lt12: "",
            sy5: "ܡܶܠܚܳܐ",
            sy6: ""
        },
        {
            id: "1595",
            sy9: "",
            dt2: "",
            sy6: "",
            sy7: "",
            dt3: "",
            lt11: "",
            sy4: "ܡܫܘܬܦܬܐ ܐܚܝܕܬܐ",
            sy5: "ܡܫܰܘܬܰܐܦܬܳܐ ܐܰܚܺܝܕܬ݂ܳܐ",
            dt1: "Gesellschaft mit beschränkter Haftung",
            sy8: "",
            lt12: "",
            lt10: "mshautafto aĥidtho"
        },
        {
            lt12: "kulhun",
            sy8: "ܟܠܗܘܢ",
            sy7: "ܟܽܠ ܐ̱ܢܳܫ",
            sy5: "ܟܽܠ ܚܰܕ",
            dt3: "",
            sy6: "ܟܠ ܐܢܫ",
            id: "2292",
            sy4: "ܟܠ ܚܕ",
            dt2: "",
            lt11: "kul nosh",
            dt1: "jeder",
            lt10: "kul ĥađ",
            sy9: "ܟܽܠܗܽܘܢ"
        },
        {
            dt3: "",
            sy9: "ܥܳܢܽܘܕܳܐ",
            sy4: "ܢܘܟܪܝܐ",
            dt2: "",
            sy6: "ܐܟܣܢܝܐ",
            sy5: "ܢܽܘܟ݂ܪܳܝܳܐ",
            sy7: "ܐܰܟ݂ܣܢܳܝܳܐ",
            lt11: "akhßnoyo",
            dt1: "Fremder",
            sy8: "ܥܢܘܕܐ",
            lt12: "ĉonuđo",
            id: "1389",
            lt10: "nukhroyo"
        },
        {
            lt11: "ܚܙܝܢ",
            dt2: "",
            sy8: "",
            sy6: "ܚܙܝܘܢ",
            id: "1987",
            sy4: "ܚܙܝܢ",
            dt1: "Hesjon",
            lt10: "ܚܙܝܢ",
            sy5: "ܚܶܙܝܳܐܢ",
            sy7: "ܚܶܙܝܽܘܢ",
            dt3: "",
            lt12: "ܚܙܝܘܢ",
            sy9: ""
        },
        {
            sy4: "ܡܪܝܕܬܐ",
            sy5: "ܡܰܪܺܝܕܬܰܐ",
            sy9: "",
            lt12: "",
            lt11: "ܡܪܝܕܬܐ",
            sy8: "",
            id: "2935",
            dt3: "",
            dt1: "Maridta",
            sy7: "",
            sy6: "",
            dt2: "",
            lt10: "ܡܪܝܕܬܐ"
        },
        {
            sy9: "",
            sy6: "",
            dt2: "Schluss",
            lt11: "",
            id: "1102",
            dt1: "Ende",
            dt3: "",
            sy4: "ܣܘܦܐ",
            sy7: "",
            sy8: "",
            lt10: "saufo",
            sy5: "ܣܰܘܦܳܐ",
            lt12: ""
        },
        {
            sy5: "ܥܒܶܕܡܫܺܝܚܐ",
            lt12: "",
            sy8: "",
            dt2: "",
            sy7: "",
            sy4: "ܥܒܕܡܫܝܚܐ",
            sy6: "",
            sy9: "",
            lt10: "ܥܒܕܡܫܝܚܐ",
            lt11: "ܥܒܕܡܫܝܚܐ",
            dt3: "",
            id: "73",
            dt1: "Abedmschiho"
        },
        {
            sy5: "ܫܒܺܝܩܽܘܬ݂ܳܐ",
            sy4: "ܫܒܝܩܘܬܐ",
            dt3: "",
            id: "5688",
            sy7: "ܫܒܽܘܩܝܳܐ",
            lt10: "shbiqutho",
            lt12: "",
            sy6: "ܫܒܘܩܝܐ",
            sy9: "",
            dt2: "",
            dt1: "Abtretung",
            sy8: "",
            lt11: "shbuqyo"
        },
        {
            id: "5180",
            dt3: "",
            sy4: "ܘܪܝܕܐ",
            sy9: "",
            lt12: "",
            sy8: "",
            dt2: "",
            dt1: "Wurzel",
            sy6: "ܫܪܫܐ",
            sy7: "ܫܶܪܫܳܐ",
            lt11: "shersho",
            sy5: "ܘܰܪܺܝܕܳܐ",
            lt10: "wariđo"
        },
        {
            sy8: "",
            lt10: "durosho",
            lt12: "",
            dt3: "",
            sy7: "",
            lt11: "",
            sy6: "",
            sy9: "",
            id: "4539",
            sy5: "ܕܽܘܪܳܫܳܐ",
            sy4: "ܕܘܪܫܐ",
            dt1: "Training",
            dt2: "Übung"
        },
        {
            dt3: "",
            sy9: "",
            lt12: "",
            id: "493",
            sy8: "",
            lt10: "ܒܣܡܐ",
            lt11: "ܒܣܡܐ",
            sy6: "",
            dt2: "",
            dt1: "Basma",
            sy5: "ܒܰܣܡܰܐ",
            sy7: "",
            sy4: "ܒܣܡܐ"
        },
        {
            lt10: "nuno zĉuro",
            dt3: "",
            sy5: "ܢܽܘܢܳܐ ܙܥܽܘܪܳܐ",
            dt2: "",
            id: "2515",
            lt12: "",
            sy6: "",
            sy8: "",
            lt11: "",
            dt1: "kleiner Fisch",
            sy4: "ܢܘܢܐ ܙܥܘܪܐ",
            sy9: "",
            sy7: ""
        },
        {
            lt10: "shlomo nehwe ĉamokh",
            sy4: "ܫܠܡܐ ܢܗܘܐ ܥܡܟ",
            sy9: "",
            sy7: "ܫܠܳܡܳܐ ܢܶܗܘܶܐ ܥܰܡܶܟ݂",
            id: "5600",
            lt11: "shlomo nehwe ĉamekh",
            sy8: "",
            dt3: "",
            sy6: "ܫܠܡܐ ܢܗܘܐ ܥܡܟ",
            sy5: "ܫܠܳܡܳܐ ܢܶܗܘܶܐ ܥܰܡܳܟ݂",
            dt1: "Friede sei mit dir",
            lt12: "",
            dt2: ""
        },
        {
            sy9: "",
            lt10: "saqubloyo",
            dt3: "",
            sy5: "ܣܰܩܽܘܒܠܳܝܳܐ",
            sy4: "ܣܩܘܒܠܝܐ",
            sy7: "",
            dt2: "",
            sy6: "",
            dt1: "Oppositioneller",
            lt12: "",
            sy8: "",
            lt11: "",
            id: "3383"
        },
        {
            sy8: "",
            dt1: "Abwaschung",
            lt10: "dakhyutho",
            lt11: "",
            dt2: "",
            sy9: "",
            sy6: "",
            sy4: "ܕܟܝܘܬܐ",
            id: "5696",
            sy5: "ܕܰܟ݂ܝܽܘܬ݂ܳܐ",
            sy7: "",
            lt12: "",
            dt3: ""
        },
        {
            sy5: "ܪܰܒܺܝܢ",
            id: "3581",
            sy9: "",
            lt10: "ܪܒܝܢ",
            dt2: "",
            sy6: "",
            sy7: "",
            lt11: "ܪܒܝܢ",
            sy8: "",
            dt3: "",
            sy4: "ܪܒܝܢ",
            lt12: "",
            dt1: "Rabin"
        },
        {
            dt1: "Syrisch katholische Kirche",
            lt10: "ĉito suryoyto qatuliqoyto",
            sy8: "",
            sy7: "",
            dt2: "",
            sy4: "ܥܕܬܐ ܣܘܪܝܝܬܐ ܩܬܘܠܝܩܝܬܐ",
            sy6: "",
            sy5: "ܥܺܕܬܳܐ ܣܽܘܪܝܳܝܬܳܐ ܩܰܬܽܘܠܺܝܩܳܝܬܳܐ",
            sy9: "",
            id: "4337",
            lt12: "",
            lt11: "",
            dt3: ""
        },
        {
            lt11: "",
            sy6: "",
            sy8: "",
            sy5: "ܐ ܒ ܓ ܕ",
            lt12: "",
            lt10: "olaf beth gomal dolath",
            dt3: "",
            id: "65",
            sy9: "",
            dt2: "",
            sy7: "",
            dt1: "abcd",
            sy4: "ܐ ܒ ܓ ܕ"
        },
        {
            sy6: "",
            sy7: "",
            dt1: "Versandkosten",
            lt12: "",
            dt2: "",
            sy8: "",
            sy9: "",
            dt3: "",
            id: "4801",
            sy4: "ܢܦܩܬ ܫܘܕܪܐ",
            lt10: "nafqoth shudoro",
            sy5: "ܢܰܦܩܳܬ݂ ܫܽܘܕܳܪܳܐ",
            lt11: ""
        },
        {
            lt11: "",
            sy7: "",
            sy9: "",
            lt12: "",
            dt2: "",
            dt3: "",
            id: "6103",
            sy5: "ܦܶܬܩܳܐ ܡܰܓܳܢܳܝܳܐ",
            dt1: "Freikarte",
            sy4: "ܦܬܩܐ ܡܓܢܝܐ",
            sy6: "",
            lt10: "fetqo magonoyo",
            sy8: ""
        },
        {
            lt12: "",
            lt11: "ĉafuro",
            sy6: "ܥܦܘܪܐ",
            sy7: "ܥܰܦܽܘܪܳܐ",
            lt10: "taĥfitho",
            sy5: "ܬܰܚܦܺܝܬ݂ܳܐ",
            sy4: "ܬܚܦܝܬܐ",
            dt1: "Bettdecke",
            id: "592",
            sy9: "",
            dt2: "Decke",
            dt3: "",
            sy8: ""
        },
        {
            lt12: "",
            sy4: "ܥܬܪ",
            sy8: "",
            dt1: "anreichern",
            sy7: "ܐܰܥܬܰܪ",
            sy6: "ܐܥܬܪ",
            sy9: "",
            id: "5981",
            dt2: "",
            sy5: "ܥܬܰܪ",
            dt3: "",
            lt11: "aĉtar",
            lt10: "ĉtar"
        },
        {
            sy5: "ܝܰܕܥܳܐ ܕܒܽܘܨܳܪܳܐ",
            dt2: "",
            sy8: "",
            id: "3048",
            lt11: "",
            lt12: "",
            sy9: "",
            sy6: "",
            dt1: "Minuszeichen",
            sy4: "ܝܕܥܐ ܕܒܘܨܪܐ",
            dt3: "",
            sy7: "",
            lt10: "yadĉo d`buŝoro"
        },
        {
            lt12: "juth",
            sy6: "ܥܣܪ",
            sy5: "ܥܶܣܪܳܐ",
            dt2: "Zehn",
            sy8: "ܝ",
            id: "11",
            dt1: "10",
            lt10: "ĉesro",
            dt3: "",
            sy4: "ܥܣܪܐ",
            lt11: "ĉsar",
            sy7: "ܥܣܰܪ",
            sy9: "ܝ"
        },
        {
            sy5: "ܣܰܦܰܪ",
            sy7: "",
            lt12: "",
            dt3: "",
            sy6: "",
            lt10: "ܣܦܪ",
            sy9: "",
            lt11: "ܣܦܪ",
            sy8: "",
            dt2: "",
            id: "3797",
            dt1: "Safar",
            sy4: "ܣܦܪ"
        },
        {
            sy8: "",
            sy7: "",
            sy9: "",
            dt1: "lästern",
            id: "2715",
            lt12: "",
            dt2: "",
            lt10: "gadef",
            sy4: "ܓܕܦ",
            sy6: "",
            dt3: "",
            lt11: "",
            sy5: "ܓܰܕܶܦ"
        },
        {
            sy5: "ܕܠܳܐ ܡܽܘܡܳܐ",
            dt2: "",
            lt12: "",
            lt11: "",
            sy9: "",
            dt1: "makellos",
            sy8: "",
            sy7: "",
            sy4: "ܕܠܐ ܡܘܡܐ",
            id: "2893",
            sy6: "",
            lt10: "d`lo mumo",
            dt3: ""
        },
        {
            sy8: "ܫܘܪܛܐ ܕܬܚܘܡܐ",
            dt3: "",
            sy6: "ܡܟܣܐ",
            lt12: "shurțo da'tĥumo",
            lt10: "ĉashoro",
            sy7: "ܡܳܟ݂ܣܳܐ",
            sy5: "ܥܰܫܳܪܳܐ",
            sy9: "ܫܽܘܪܛܳܐ ܕܰܬܚܽܘܡܳܐ",
            dt2: "Zollbeamter",
            id: "5348",
            lt11: "mokhso",
            sy4: "ܥܫܪܐ",
            dt1: "Zöllner"
        },
        {
            sy4: "ܐܚܕ",
            lt12: "",
            dt3: "",
            sy8: "",
            id: "1834",
            sy7: "",
            sy5: "ܐܰܚܶܕ",
            sy9: "",
            dt2: "",
            dt1: "halten",
            sy6: "",
            lt10: "aĥeđ",
            lt11: ""
        },
        {
            dt1: "Yahlo",
            id: "5189",
            sy8: "",
            dt3: "",
            lt12: "",
            sy9: "",
            sy7: "",
            dt2: "",
            lt11: "ܝܗܠܐ",
            sy4: "ܝܗܠܐ",
            lt10: "ܝܗܠܐ",
            sy6: "",
            sy5: "ܝܰܗܠܳܐ"
        },
        {
            sy4: "ܐܰܒܳܐ ܫܒܽܘܩ ܠܺܝ ܡܶܛܽܠ ܕܠܳܐ ܝܳܕܰܥ ܐܢܳܐ ܡܽܘܢ ܥܳܒܶܕ ܐܢܳܐ",
            dt1: "Vater vergib mir denn ich weiß nicht was ich tue",
            sy6: "",
            dt3: "",
            id: "5455",
            dt2: "",
            lt10: "Abo shuq li mețul dlo yođaĉ no mun ĉobed no",
            sy5: "ܐܰܒܳܐ ܫܒܽܘܩ ܠܺܝ ܡܶܛܽܠ ܕܠܳܐ ܝܳܕܰܥ ܐܢܳܐ ܡܽܘܢ ܥܳܒܶܕ ܐܢܳܐ",
            sy9: "",
            lt11: "",
            lt12: "",
            sy7: "",
            sy8: ""
        },
        {
            id: "5116",
            dt1: "Windel",
            lt11: "",
            sy7: "",
            lt10: "ĉazruro",
            sy9: "",
            sy4: "ܥܙܪܘܪܐ",
            dt3: "",
            sy6: "",
            lt12: "",
            sy8: "",
            dt2: "Pampers",
            sy5: "ܥܰܙܪܽܘܪܳܐ"
        },
        {
            sy8: "",
            dt1: "gestern",
            sy4: "ܐܬܡܠ",
            sy9: "",
            lt12: "",
            lt11: "qđom yaumo",
            id: "1603",
            dt2: "",
            lt10: "athmel",
            sy5: "ܐܰܬ݂ܡܶܠ",
            dt3: "",
            sy7: "ܩܕܳܡ ܝܰܘܡܳܐ",
            sy6: "ܩܕܡ ܝܘܡܐ"
        },
        {
            sy6: "",
            dt3: "",
            sy5: "ܡܰܫܽܘܚܬܳܐ",
            lt11: "",
            lt10: "mashuĥto",
            id: "5131",
            sy9: "",
            sy7: "",
            dt1: "Wirtschaft",
            sy8: "",
            lt12: "",
            dt2: "Ökonomie",
            sy4: "ܡܫܘܚܬܐ"
        },
        {
            sy4: "ܫܠܝܬܐ",
            lt11: "othutho",
            sy9: "",
            dt3: "",
            sy6: "ܐܬܘܬܐ",
            sy7: "ܐܳܬ݂ܽܘܬ݂ܳܐ",
            dt2: "",
            lt10: "shlitho",
            lt12: "",
            id: "6075",
            dt1: "Konsonant",
            sy8: "",
            sy5: "ܫܠܺܝܬ݂ܳܐ"
        },
        {
            sy8: "",
            dt1: "Hufeisen",
            dt3: "",
            sy4: "ܢܥܠܐ",
            lt12: "",
            sy6: "",
            id: "2065",
            dt2: "",
            sy7: "",
            sy9: "",
            sy5: "ܢܰܥܠܳܐ",
            lt10: "naĉlo",
            lt11: ""
        },
        {
            sy8: "",
            lt11: "ܒܣܣܝܡܐ",
            lt10: "ܒܣܣܝܡܐ",
            dt2: "",
            dt3: "",
            dt1: "Basima",
            sy7: "",
            sy6: "",
            lt12: "",
            sy5: "ܒܰܣܣܺܝܡܰܐ",
            id: "490",
            sy4: "ܒܣܣܝܡܐ",
            sy9: ""
        },
        {
            sy9: "",
            sy7: "",
            sy6: "",
            sy5: "ܓܡܺܝܪܳܐ",
            dt2: "Idealist",
            sy4: "ܓܡܝܪܐ",
            lt10: "gmiro",
            sy8: "",
            dt3: "",
            lt12: "",
            dt1: "Ideal",
            id: "2146",
            lt11: ""
        },
        {
            sy9: "",
            lt11: "",
            lt10: "yadĉo dqențo",
            sy4: "ܝܕܥܐ ܕܩܢܛܐ",
            lt12: "",
            dt3: "",
            id: "5817",
            sy6: "",
            sy5: "ܝܰܕܥܳܐ ܕܩܶܢܛܳܐ",
            sy7: "",
            dt2: "",
            dt1: "Alarmsignal",
            sy8: ""
        },
        {
            lt11: "meshtautfonutho",
            dt3: "",
            dt1: "Anteilnahme",
            sy7: "ܡܶܫܬܰܘܬܦܳܢܽܘܬ݂ܳܐ",
            sy4: "ܚܝܘܣܬܢܘܬܐ",
            id: "5993",
            sy6: "ܡܫܬܘܬܦܢܘܬܐ",
            sy5: "ܚܰܝܽܘܣܬܳܢܽܘܬ݂ܳܐ",
            lt12: "",
            lt10: "ĥayustonutho",
            sy9: "",
            sy8: "",
            dt2: ""
        },
        {
            sy4: "ܡܩܛܪܓܐ",
            dt3: "Angeschuldigter",
            dt1: "Angeklagter",
            sy6: "ܩܒܝܠܐ",
            sy9: "ܥܕܺܝܠܳܐ",
            sy5: "ܡܩܰܛܪܓ݂ܳܐ",
            lt10: "mqațrğo",
            id: "5926",
            lt11: "qbilo",
            sy8: "ܥܕܝܠܐ",
            sy7: "ܩܒܺܝܠܳܐ",
            dt2: "Beklagter",
            lt12: "ĉdilo"
        },
        {
            lt12: "",
            id: "4894",
            dt3: "",
            sy6: "",
            sy9: "",
            sy4: "ܩܕܡܘܗܝ ܢܣܓܘܕ ܟܠ ܐܢܫ",
            sy5: "ܩܕܳܡܰܘܗܝ ܢܶܣܓ݂ܽܘܕ ܟܽܠ ܐ̱ܢܳܫ",
            sy8: "",
            dt2: "",
            sy7: "",
            dt1: "Vor ihm wird sich jedes Knie beugen",
            lt10: " qđomau nesğud kul nosh",
            lt11: ""
        },
        {
            lt12: "",
            dt1: "Sefta",
            dt3: "",
            sy7: "",
            lt10: "ܨܦܬܐ",
            dt2: "",
            sy5: "ܨܶܦܬܰܐ",
            id: "4090",
            lt11: "ܨܦܬܐ",
            sy8: "",
            sy9: "",
            sy6: "",
            sy4: "ܨܦܬܐ"
        },
        {
            sy5: "ܦܺܝܠܡܳܐ",
            lt11: "",
            sy6: "",
            sy9: "",
            sy7: "",
            sy4: "ܦܝܠܡܐ",
            dt3: "",
            dt2: "",
            id: "1310",
            sy8: "",
            dt1: "Film",
            lt10: "filmo",
            lt12: ""
        },
        {
            lt11: "",
            sy9: "",
            sy6: "",
            sy7: "",
            dt2: "Gürtel",
            lt12: "",
            sy5: "ܩܰܡܪܳܐ",
            id: "3720",
            dt3: "",
            sy8: "",
            lt10: "qamro",
            sy4: "ܩܡܪܐ",
            dt1: "Riemen"
        },
        {
            lt11: " ܡܪܝ ܐܝܓܢܐܛܝܘܣ",
            sy6: "",
            lt12: "",
            sy9: "",
            dt3: "",
            id: "3091",
            lt10: " ܡܪܝ ܐܝܓܢܐܛܝܘܣ",
            sy8: "",
            dt1: "Mor Ignatius",
            sy7: "",
            sy5: "ܡܳܪܝ̱ ܐܺܝܓ݂ܢܰܐܛܺܝܽܘܣ",
            sy4: "ܡܪܝ ܐܝܓܢܐܛܝܘܣ",
            dt2: ""
        },
        {
            sy5: "ܡܶܢ ܟܽܠ ܦܪܽܘܣ",
            lt12: "",
            sy4: "ܡܢ ܟܠ ܦܪܘܣ",
            sy9: "",
            lt10: "men kul frus",
            dt1: "Auf jeden Fall",
            dt3: "",
            id: "347",
            dt2: "",
            sy8: "",
            sy7: "",
            lt11: "",
            sy6: ""
        },
        {
            dt2: "ermitteln",
            dt3: "",
            id: "1349",
            sy9: "",
            sy4: "ܥܩܒ",
            lt11: "fatesh",
            sy5: "ܥܰܩܶܒ",
            sy6: "ܦܬܫ",
            lt12: "",
            dt1: "forschen",
            sy7: "ܦܰܬܶܫ",
            sy8: "",
            lt10: "ĉaqeb"
        },
        {
            sy6: "ܐܪܒܥ",
            lt10: "arbĉo",
            dt3: "",
            sy7: "ܐܰܪܒܰܥ",
            dt2: "Vier",
            lt11: "arbaĉ",
            lt12: "dolađ",
            dt1: "4",
            sy9: "ܕ",
            sy8: "ܕ",
            sy5: "ܐܰܪܒܥܳܐ",
            sy4: "ܐܪܒܥܐ",
            id: "5"
        },
        {
            sy6: "",
            sy8: "",
            dt1: "Christenheit",
            sy5: "ܡܫܺܝܚܳܝܽܘܬ݂ܳܐ",
            dt3: "",
            sy9: "",
            lt12: "",
            sy4: "ܡܫܝܚܝܘܬܐ",
            sy7: "",
            id: "5755",
            dt2: "",
            lt10: "mshiĥoyutho",
            lt11: ""
        },
        {
            id: "6153",
            dt3: "",
            sy6: "ܩܠܥܐ",
            lt11: "qelĉo",
            dt1: "Pendel",
            sy9: "",
            lt12: "",
            sy5: "ܡܰܦܩܰܥܬ݂ܳܐ",
            lt10: "mafqaĉtho",
            sy8: "",
            sy4: "ܡܦܩܥܬܐ",
            sy7: "ܩܶܠܥܳܐ",
            dt2: ""
        },
        {
            id: "2017",
            sy7: "ܐܰܩܶܦܬ݂ܳܐ",
            lt12: "",
            dt2: "",
            sy5: "ܡܰܩܦܳܢܽܘܬ݂ܳܐ",
            lt11: "aqeftho",
            sy4: "ܡܩܦܢܘܬܐ",
            sy9: "",
            dt1: "Hinzufügung",
            dt3: "",
            sy6: "ܐܩܦܬܐ",
            lt10: "maqfonutho",
            sy8: ""
        },
        {
            sy5: "ܡܰܬܩܰܠܬܳܐ",
            sy7: "",
            lt12: "",
            dt3: "",
            sy4: "ܡܬܩܠܬܐ",
            lt11: "",
            dt1: "Etat",
            lt10: "matqalto",
            sy8: "",
            sy9: "",
            dt2: "",
            sy6: "",
            id: "6145"
        },
        {
            sy5: "ܦܣܳܣ ܦܽܘܠܚܳܢܳܐ",
            lt11: "",
            lt12: "",
            dt3: "",
            sy4: "ܦܣܣ ܦܘܠܚܢܐ",
            dt1: "Arbeitsgenehmigung",
            sy8: "",
            lt10: "fsos fulĥono",
            sy9: "",
            dt2: "",
            sy7: "",
            sy6: "",
            id: "6016"
        },
        {
            dt3: "",
            lt10: "bĉotho",
            sy6: "",
            sy4: "ܒܥܬܐ",
            lt12: "",
            lt11: "",
            sy9: "",
            sy8: "",
            id: "6239",
            sy5: "ܒܥܳܬ݂ܳܐ",
            dt2: "",
            sy7: "",
            dt1: "Suche"
        },
        {
            sy5: "ܠܳܐ ܡܶܬ݂ܦܰܚܡܳܢܳܐ",
            dt1: "unvergleichbar",
            sy7: "ܠܳܐ ܡܶܬ݂ܕܰܡܝܳܢܳܐ",
            sy9: "",
            lt10: "lo methfaĥmono",
            sy6: "ܠܐ ܡܬܕܡܝܢܐ",
            sy4: "ܠܐ ܡܬܦܚܡܢܐ",
            id: "4677",
            dt3: "",
            lt12: "",
            sy8: "",
            dt2: "unvergleichlich",
            lt11: "lo methdamyono"
        },
        {
            sy6: "",
            dt3: "",
            sy5: "ܬܰܟ݂ܬܽܘܫܳܐ",
            lt12: "",
            lt10: "takhtusho",
            dt2: "Streit",
            dt1: "Konflikt",
            sy4: "ܬܟܬܘܫܐ",
            sy8: "",
            lt11: "",
            id: "2577",
            sy7: "",
            sy9: ""
        },
        {
            sy4: "ܫܐܗ",
            id: "3875",
            sy5: "ܫܰܐܗ",
            sy6: "",
            lt12: "",
            sy9: "",
            dt3: "",
            lt10: "shah",
            sy7: "",
            sy8: "",
            dt2: "",
            dt1: "Schah",
            lt11: "shah"
        },
        {
            sy9: "",
            sy5: "ܐܺܝܙܰܠܰܐ",
            lt11: "ܐܝܙܠܐ",
            sy6: "",
            lt12: "",
            sy4: "ܐܝܙܠܐ",
            lt10: "ܐܝܙܠܐ",
            id: "2257",
            sy8: "",
            dt1: "Izala",
            dt3: "",
            sy7: "",
            dt2: ""
        },
        {
            lt11: "ܐܦܪܗܛ",
            id: "258",
            dt3: "",
            lt12: "",
            dt2: "Afrahat",
            lt10: "ܐܦܪܗܛ",
            sy4: "ܐܦܪܗܛ",
            dt1: "Aphrahat",
            sy8: "",
            sy5: "ܐܰܦܪܰܗܰܛ",
            sy6: "",
            sy7: "",
            sy9: ""
        },
        {
            id: "4434",
            dt3: "",
            sy5: "ܪܽܘܡܪܳܡ ܚܰܡܺܝܡܽܘܬ݂ܳܐ",
            lt12: "",
            sy6: "",
            sy8: "",
            lt11: "",
            dt1: "Temperaturerhöhung",
            sy4: "ܪܘܡܪܡ ܚܡܝܡܘܬܐ",
            sy7: "",
            lt10: "rumrom ĥamimutho",
            dt2: "Temperatursteigung",
            sy9: ""
        },
        {
            dt1: "sich wenden",
            sy5: "ܦܢܳܐ",
            sy6: "",
            lt10: "fno",
            lt11: "",
            sy4: "ܦܢܐ",
            lt12: "",
            dt3: "",
            sy8: "",
            id: "4137",
            dt2: "",
            sy7: "",
            sy9: ""
        },
        {
            sy9: "",
            sy6: "",
            dt1: "Fahrradsattel",
            sy7: "",
            id: "1237",
            lt12: "",
            sy4: "ܠܘܪܐ",
            dt2: "",
            dt3: "",
            sy8: "",
            sy5: "ܠܳܘܪܳܐ",
            lt11: "",
            lt10: "lowro"
        },
        {
            sy6: "",
            sy8: "",
            sy5: "ܐܰܫܠܶܡ ܙܰܝܢܳܐ",
            lt11: "",
            id: "4926",
            dt2: "",
            lt12: "",
            lt10: "ashlem zayno",
            sy9: "",
            sy7: "",
            dt1: "Waffen niederlegen",
            dt3: "",
            sy4: "ܐܫܠܡ ܙܝܢܐ"
        },
        {
            lt10: "esțwo d`qeryono",
            sy9: "",
            sy8: "",
            id: "2772",
            dt3: "",
            sy7: "",
            sy6: "",
            sy5: "ܐܶܣܛܘܳܐ ܕܩܶܪܝܳܢܳܐ",
            lt12: "",
            lt11: "",
            dt2: "",
            sy4: "ܐܣܛܘܐ ܕܩܪܝܢܐ",
            dt1: "Lesesaal"
        },
        {
            dt1: "Gesellschaft der Rechtsanwälte",
            sy5: "ܡܫܰܘܬܰܦܬܳܐ ܕܰܣܢܺܐܓ݂ܪ̈ܐ",
            id: "1594",
            sy8: "",
            sy4: "ܡܫܘܬܦܬܐ ܕܣܢܐܓܪܐ",
            sy6: "",
            lt12: "",
            lt11: "",
            sy9: "",
            lt10: "mshautafto da`sniğre",
            dt2: "",
            sy7: "",
            dt3: ""
        },
        {
            sy6: "",
            sy4: "ܒܝܬ ܢܫܐ",
            lt10: "beth neshe",
            lt12: "",
            dt3: "",
            sy9: "",
            sy8: "",
            sy7: "",
            id: "1371",
            sy5: "ܒܶܝܬ݂ ܢܶܫ̈ܶܐ",
            dt2: "",
            lt11: "",
            dt1: "Frauenhaus"
        },
        {
            sy7: "ܛܽܘܟܳܣܳܐ",
            sy8: "ܛܟܣܢܝܘܬܐ",
            id: "4343",
            sy9: "ܛܰܟܣܳܢܳܝܽܘܬ݂ܳܐ",
            lt12: "țaksonoyutho",
            sy6: "ܛܘܟܣܐ",
            dt2: "Organisation",
            lt11: "țukoso",
            dt3: "Systematik",
            lt10: "țakso",
            sy4: "ܛܟܣܐ",
            dt1: "System",
            sy5: "ܛܰܟܣܳܐ"
        },
        {
            sy9: "",
            id: "3983",
            lt12: "",
            lt10: "qaynoyo",
            dt2: "Hufschmied",
            sy6: "",
            dt3: "Metallschmied",
            sy7: "",
            sy8: "",
            dt1: "Schmied",
            sy5: "ܩܰܝܢܳܝܳܐ",
            lt11: "",
            sy4: "ܩܝܢܝܐ"
        },
        {
            dt3: "",
            lt11: "",
            dt1: "Obstbaum",
            dt2: "",
            sy6: "",
            sy9: "",
            lt10: "ilon fire",
            id: "6123",
            sy5: "ܐܺܝܠܳܢ ܦܺܐܝܪܶܐ",
            sy8: "",
            sy4: "ܐܝܠܢ ܦܐܪܐ",
            sy7: "",
            lt12: ""
        },
        {
            sy9: "",
            lt11: "",
            dt1: "an der Stelle",
            id: "221",
            lt12: "",
            lt10: "ldukath",
            dt2: "",
            sy5: "ܠܕܽܘܟܰܬ݂",
            sy7: "",
            dt3: "",
            sy4: "ܠܕܘܟܬ",
            sy6: "",
            sy8: ""
        },
        {
            lt10: "eđno gawoyto",
            sy8: "",
            sy7: "",
            sy6: "",
            dt3: "",
            sy9: "",
            dt2: "",
            dt1: "Innenohr",
            lt12: "",
            id: "2207",
            sy4: "ܐܕܢܐ ܓܘܝܬܐ",
            sy5: "ܐܶܕܢܳܐ ܓܰܘܳܝܬܳܐ",
            lt11: ""
        },
        {
            sy5: "ܒܶܝܬ݂ ܝܰܬܡܶܐ",
            sy4: "ܒܝܬ ܝܬܡܐ",
            dt3: "",
            dt1: "Waisenhaus",
            sy8: "",
            sy7: "",
            lt12: "",
            lt10: "beth yatme",
            dt2: "",
            lt11: "",
            sy9: "",
            sy6: "",
            id: "4966"
        },
        {
            sy9: "",
            lt11: "",
            id: "5482",
            sy5: "ܒܰܪ ܒܰܪܳܐ",
            sy7: "",
            dt2: "",
            sy6: "",
            dt3: "",
            sy4: "ܒܰܪ ܒܰܪܳܐ",
            lt10: "bar baro",
            sy8: "",
            dt1: "Beduine",
            lt12: ""
        },
        {
            id: "3905",
            dt1: "Scharia",
            dt2: "",
            lt11: "ܫܪܝܥܐ",
            lt12: "ܫܪܝܥܐ",
            sy7: "ܫܪܺܝܥܰܐ",
            sy6: "ܫܪܝܥܐ",
            sy9: "",
            lt10: "ܫܪܝܥܐ",
            sy8: "",
            sy5: "ܫܰܪܺܝܥܰܐ",
            dt3: "",
            sy4: "ܫܪܝܥܐ"
        },
        {
            dt1: "Zauberer",
            sy8: "",
            lt12: "",
            dt3: "",
            lt11: "",
            sy9: "",
            sy5: "ܚܰܪܳܫܳܐ",
            sy4: "ܚܪܫܐ",
            lt10: "ĥarosho",
            dt2: "",
            sy6: "",
            id: "5258",
            sy7: ""
        },
        {
            sy4: "ܝܗܒ",
            sy8: "",
            lt12: "",
            dt2: "",
            sy5: "ܝܳܗܶܒ",
            id: "1494",
            dt3: "",
            dt1: "geben",
            sy9: "",
            lt11: "",
            sy7: "",
            sy6: "",
            lt10: "yoheb"
        },
        {
            sy6: "",
            dt3: "",
            dt1: "Tag",
            sy8: "",
            id: "4360",
            sy4: "ܝܘܡܐ",
            sy9: "",
            lt12: "",
            lt11: "",
            dt2: "",
            sy5: "ܝܰܘܡܳܐ",
            sy7: "",
            lt10: "yaumo"
        },
        {
            sy5: "ܐܰܡܢܳܐ",
            lt12: "",
            dt3: "",
            sy8: "",
            sy4: "ܐܡܢܐ",
            dt1: "Beruf",
            id: "557",
            lt11: "",
            sy9: "",
            lt10: "amno",
            dt2: "",
            sy6: "",
            sy7: ""
        },
        {
            id: "3725",
            sy9: "",
            dt1: "Ringe",
            sy4: "ܥܙܩܝܬܐ",
            sy8: "",
            dt2: "",
            lt12: "",
            sy7: "ܥܰܙܩܳܬ݂ܳܐ",
            sy5: "ܥܶܙܰܩܝܳܬ݂ܳܐ",
            sy6: "ܥܙܩܬܐ",
            lt10: "ĉezaqyotho",
            lt11: "ĉazqotho",
            dt3: ""
        },
        {
            sy9: "",
            dt1: "geduldig",
            lt12: "",
            id: "1510",
            sy8: "",
            lt11: "msaybronoith",
            lt10: "msaybrono",
            sy6: "ܡܣܝܒܪܢܐܝܬ",
            sy5: "ܡܣܰܝܒܪܳܢܳܐ",
            sy7: "ܡܣܰܝܒܪܳܢܳܐܝܺܬ",
            dt3: "",
            dt2: "",
            sy4: "ܡܣܝܒܪܢܐ"
        },
        {
            sy4: "ܬܪܣܝܬܐ",
            sy6: "",
            sy9: "",
            sy5: "ܬܰܪܣܺܝܬ݂ܳܐ",
            dt1: "Nahrung",
            lt12: "",
            sy7: "",
            sy8: "",
            id: "3189",
            dt3: "",
            lt10: "tarsitho",
            dt2: "Verpflegung",
            lt11: ""
        },
        {
            sy6: "",
            lt12: "",
            sy5: "ܙܰܒܢܳܐ ܠܚܶܠܡܶܐ",
            dt2: "",
            dt3: "",
            sy7: "",
            sy8: "",
            sy9: "",
            id: "5610",
            sy4: "ܙܒܢܐ ܠܚܠܡܐ",
            lt10: "zabno lĥelme",
            lt11: "",
            dt1: "Zeit für Träume"
        },
        {
            lt11: "lhiqoto no laĥsotokh",
            dt1: "Ich vermisse dich",
            dt2: "",
            lt10: "lhiq no laĥzotekh",
            sy9: "",
            dt3: "",
            sy8: "",
            id: "2144",
            lt12: "",
            sy4: "ܠܗܝܩ ܐܢܐ ܠܚܙܬܟܝ",
            sy6: "ܠܗܺܝܩܳܬܳܐ ܐ̱ܢܳܐ ܠܰܚܙܳܝܬܳܟ݂",
            sy5: "ܠܗܺܝܩ ܐ̱ܢܳܐ ܠܰܚܙܳܬܶܟ݂ܝ",
            sy7: "ܠܗܺܝܩܳܬܳܐ ܐ̱ܢܳܐ ܠܰܚܙܳܝܬܳܟ݂"
        },
        {
            dt3: "kehren",
            dt2: "wenden",
            sy4: "ܗܦܟ",
            lt10: "hfakh",
            dt1: "zurück",
            sy8: "ܕܥܪܐ",
            sy9: "ܕܥܳܪܳܐ",
            lt12: "dĉoro",
            sy5: "ܗܦܰܟ݂",
            lt11: "dĉar",
            sy6: "ܕܥܪ",
            sy7: "ܕܥܰܪ",
            id: "5375"
        },
        {
            dt1: "Thermosflasche",
            sy6: "",
            dt2: "",
            sy9: "",
            id: "4465",
            lt11: "",
            lt10: "tarmuso",
            dt3: "",
            sy5: "ܬܰܪܡܽܘܣܳܐ",
            lt12: "",
            sy4: "ܬܪܡܘܣܐ",
            sy7: "",
            sy8: ""
        },
        {
            dt2: "",
            lt10: "dno",
            lt12: "",
            sy9: "",
            lt11: "",
            dt1: "abhängig sein",
            sy5: "ܕܢܳܐ",
            sy8: "",
            sy7: "",
            sy6: "",
            dt3: "",
            id: "5570",
            sy4: "ܕܢܐ"
        },
        {
            sy5: "ܕܳܪܬܳܐ",
            lt10: "dorto",
            dt1: "Schulhof",
            sy7: "",
            id: "4051",
            lt11: "",
            sy9: "",
            sy6: "",
            dt2: "Hof",
            dt3: "",
            lt12: "",
            sy8: "",
            sy4: "ܕܪܬܐ"
        },
        {
            sy7: "",
            sy9: "",
            lt11: "",
            sy8: "",
            dt3: "",
            dt2: "",
            sy4: "ܦܘܫܦܫܐ",
            sy6: "",
            id: "5268",
            sy5: "ܦܽܘܫܦܳܫܳܐ",
            lt12: "",
            dt1: "Zecke",
            lt10: "fushfosho"
        },
        {
            dt1: "Anamnese",
            sy8: "",
            sy6: "ܐܗܘܕܘܬܐ",
            lt12: "",
            sy7: "ܥ̱ܳܗܽܘܕܽܘܬ݂ܳܐ",
            dt2: "",
            sy9: "",
            sy5: "ܥܗܳܕܳܐ",
            sy4: "ܥܗܕܐ",
            lt10: "ĉhodo",
            dt3: "",
            id: "5908",
            lt11: "ohudutho"
        },
        {
            dt1: "topfit",
            lt11: "",
            id: "4524",
            sy5: "ܛܳܒ ܚܠܺܝܡ",
            sy7: "",
            sy4: "ܛܒ ܚܠܝܡ",
            lt10: "țob ĥlim",
            sy9: "",
            lt12: "",
            sy6: "",
            sy8: "",
            dt2: "",
            dt3: ""
        },
        {
            id: "5741",
            lt10: "krab",
            lt11: "akar",
            sy8: "ܕܒܪ ܦܕܢܐ",
            dt2: "",
            sy5: "ܟܪܰܒ",
            dt1: "ackern",
            sy7: "ܐܰܟܰܪ",
            sy6: "ܐܟܪ",
            lt12: "dbar fadono",
            sy9: "ܕܒܰܪ ܦܰܕܳܢܳܐ",
            dt3: "",
            sy4: "ܟܪܒ"
        },
        {
            dt3: "",
            dt1: "Milidja",
            sy6: "",
            sy5: "ܡܺܝܠܺܝܕܝܰܐ",
            sy8: "",
            dt2: "",
            sy9: "",
            id: "3028",
            lt11: "ܡܝܠܝܕܝܐ",
            sy7: "",
            lt10: "ܡܝܠܝܕܝܐ",
            sy4: "ܡܝܠܝܕܝܐ",
            lt12: ""
        },
        {
            sy6: "",
            sy4: "ܛܝܡܐ",
            sy9: "",
            sy8: "",
            lt12: "",
            dt2: "",
            dt3: "",
            dt1: "teuer",
            lt11: "",
            sy5: "ܛܺܝܡܳܐ",
            sy7: "",
            id: "4444",
            lt10: "țimo"
        },
        {
            sy4: "ܒܝܥܐ",
            lt11: "beĉe",
            sy7: "ܒܶܥ̈ܶܐ",
            dt3: "",
            id: "1029",
            dt2: "",
            sy9: "",
            sy5: "ܒܺܝ̈ܥܶܐ",
            lt10: "biĉe",
            lt12: "",
            sy8: "",
            dt1: "Eier",
            sy6: "ܒܥܐ"
        },
        {
            sy8: "",
            sy5: "ܣܳܥܽܘ̈ܪܶܐ ܡܰܝܩܪܶܐ",
            dt1: "Verehrte Gäste",
            sy7: "",
            lt10: "soĉure myaqre",
            lt12: "",
            dt2: "Liebe Besucher",
            lt11: "",
            sy4: "ܣܥܘܪܐ ܡܝܩܪܐ",
            dt3: "",
            id: "4724",
            sy6: "",
            sy9: ""
        },
        {
            lt10: "tub othe b`shubĥo",
            dt1: "Er wird wiederkommen in Herrlichkeit",
            sy7: "",
            sy6: "",
            lt12: "",
            sy8: "",
            sy9: "",
            sy5: "ܬܽܘܒ ܐܳܬ݂ܶܐ ܒܫܽܘܒܚܳܐ",
            id: "1128",
            dt3: "",
            lt11: "",
            dt2: "",
            sy4: "ܬܘܒ ܐܬܐ ܒܫܘܒܚܐ"
        },
        {
            lt11: "qadishoith",
            dt1: "heilig",
            sy5: "ܡܩܰܕܫܳܐ",
            dt3: "",
            lt10: "mqadsho",
            sy9: "",
            lt12: "",
            sy4: "ܡܩܕܫܐ",
            sy8: "",
            dt2: "",
            id: "1921",
            sy7: "ܩܰܕܺܝܫܳܐܝܺܬ݂",
            sy6: "ܩܕܝܫܐܝܬ"
        },
        {
            dt2: "",
            lt12: "",
            sy6: "",
            sy5: "ܒܰܪܢܽܘܢ",
            dt3: "",
            dt1: "Barnun",
            sy4: "ܒܪܢܘܢ",
            sy8: "",
            lt11: "ܒܪܢܘܢ",
            sy7: "",
            lt10: "ܒܪܢܘܢ",
            sy9: "",
            id: "468"
        },
        {
            dt3: "",
            dt1: "Lahdo",
            id: "2692",
            sy6: "",
            lt12: "",
            sy7: "",
            lt10: "ܠܚܕܐ",
            lt11: "ܠܚܕܐ",
            sy9: "",
            sy5: "ܠܰܚܕܳܐ",
            dt2: "",
            sy4: "ܠܚܕܐ",
            sy8: ""
        },
        {
            dt3: "",
            sy7: "",
            sy5: "ܨܰܠܺܝܒܰܐ",
            dt2: "",
            lt12: "",
            id: "3811",
            dt1: "Saliba",
            sy8: "",
            sy9: "",
            lt11: "ܨܠܝܒܐ",
            sy6: "",
            sy4: "ܨܠܝܒܐ",
            lt10: "ܨܠܝܒܐ"
        },
        {
            sy8: "",
            sy4: "ܓܢܣܐ",
            lt12: "",
            id: "1583",
            sy5: "ܓܶܢܣܳܐ",
            lt11: "",
            sy6: "",
            dt2: "",
            lt10: "genso",
            sy9: "",
            dt1: "Geschlecht",
            dt3: "",
            sy7: ""
        },
        {
            dt1: "t",
            sy4: "ܬ",
            lt10: "tauw",
            id: "4345",
            dt2: "",
            sy7: "",
            sy6: "",
            dt3: "",
            sy9: "",
            sy5: "ܬ",
            lt11: "",
            sy8: "",
            lt12: ""
        },
        {
            lt12: "",
            sy4: "ܒܪܟܐܦܐ",
            sy7: "",
            id: "457",
            sy5: "ܒܰܪܟܺܐܦܳܐ",
            dt1: "Barkipho",
            dt2: "Barkepha ",
            sy8: "",
            sy9: "",
            dt3: "",
            sy6: "",
            lt10: "ܒܪܟܐܦܐ",
            lt11: "ܒܪܟܐܦܐ"
        },
        {
            dt1: "Zaun",
            dt3: "",
            lt11: "",
            lt12: "",
            sy6: "",
            sy4: "ܣܝܓܐ",
            id: "5263",
            sy7: "",
            sy5: "ܣܝܳܓ݂ܳܐ",
            sy9: "",
            sy8: "",
            dt2: "",
            lt10: "syoğo"
        },
        {
            lt10: "adlelyo",
            sy5: "ܐܰܕܠܶܠܝܳܐ",
            lt11: "",
            sy8: "",
            id: "1991",
            dt3: "",
            sy9: "",
            lt12: "",
            dt2: "heutnacht",
            sy6: "",
            sy4: "ܐܕܠܠܝܐ",
            dt1: "heute nacht",
            sy7: ""
        },
    {
        sy5: "ܝܽܘܠܦܳܢܳܐ",
        sy8: "",
        dt1: "Bildung",
        sy6: "",
        lt12: "",
        sy9: "",
        sy7: "",
        sy4: "ܝܘܠܦܢܐ",
        lt11: "",
        lt10: "yulfono",
        dt2: "",
        dt3: "",
        id: "618"
    },
    {
        lt11: "",
        sy9: "",
        lt10: "lelyo",
        sy5: "ܠܶܠܝܳܐ",
        sy8: "",
        sy4: "ܠܠܝܐ",
        dt3: "",
        dt2: "dunkel",
        lt12: "",
        dt1: "Nacht",
        sy6: "",
        id: "3167",
        sy7: ""
    },
    {
        id: "4805",
        sy6: "",
        dt2: "",
        lt12: "",
        sy8: "",
        lt10: "maqnyono",
        lt11: "",
        sy5: "ܡܰܩܢܝܳܢܳܐ",
        sy9: "",
        sy4: "ܡܩܢܝܢܐ",
        sy7: "",
        dt3: "",
        dt1: "Verschaffer"
    },
    {
        id: "4111",
        dt1: "Seliger",
        sy8: "",
        dt2: "",
        lt12: "",
        sy4: "ܛܘܒܢܐ",
        sy7: "",
        lt11: "",
        lt10: "țubono",
        dt3: "",
        sy9: "",
        sy6: "",
        sy5: "ܛܽܘܒܳܢܳܐ"
    },
    {
        lt11: "",
        dt3: "",
        sy5: "ܚܶܙܘܳܐ ܒܰܪܳܝܳܐ",
        sy7: "",
        id: "384",
        sy4: "ܚܙܘܐ ܒܪܝܐ",
        sy6: "",
        dt2: "",
        dt1: "Außenansicht",
        lt10: "ĥezwo baroyo",
        sy9: "",
        sy8: "",
        lt12: ""
    },
    {
            id: "2696",
            dt2: "",
            sy4: "ܠܡܕ",
            sy5: "ܠܰܡܶܕ",
            sy7: "",
            sy8: "",
            dt3: "",
            lt10: "ܠܡܕ",
            sy6: "",
            lt12: "",
            sy9: "",
            lt11: "ܠܡܕ",
            dt1: "Lamed"
        }
    ];
