import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CommonService} from '../../services/common.service';
import {GLOBALS} from '../../data/global';
import * as _ from 'lodash-es';

@Component({
    selector: 'app-headertabs',
    templateUrl: './headertabs.component.html',
    styleUrls: ['./headertabs.component.scss'],
})
export class HeadertabsComponent {
    @Input() title;
    @Input() color = 'primary';
    @Input() showStiftIcon;
    @Output() myEmitter: EventEmitter<any> = new EventEmitter<any>();

    GLOBALS = GLOBALS;

    constructor(
        public common: CommonService
    ) {
    }

    writeComment() {
        this.myEmitter.emit();
    }

    getCssClasses(title) {
        let urhoyCss = false;
        let fontSize = false;


        if(_.includes(this.GLOBALS.olafbeth, title.charAt(0))) {
            urhoyCss = true;
            fontSize = true;
        }

        return {
            'urhoy': urhoyCss,
            'font-size-1-5': fontSize
        };
    }
}
