import {Injectable} from '@angular/core';
import {FirebaseService} from './firebase.service';
import {Storage} from '@ionic/storage';
import * as _ from 'lodash-es';

@Injectable({
    providedIn: 'root'
})
export class DatabaseService {

    constructor(
        private fire: FirebaseService,
        private storage: Storage,
    ) {
    }

    getData(col, doc, col2) {
        return new Promise((resolve) => {
            this.fire.getSubCollection(col, doc, col2).subscribe((res) => {
                resolve(res);
            });
        });
    }

    getDataFromStorage(storageName) {
        return new Promise((resolve) => {
            this.storage.get(storageName).then((res) => {
                res ? resolve(res) : resolve([]);
            });
        });
    }

    setDataToStorage(storageName, obj) {
        return new Promise((resolve) => {
            this.storage.set(storageName, obj)
                .then(() => resolve('SUCCESS'))
                .catch(() => resolve('FAILED'));
            });
    }
}
