import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-live',
    templateUrl: './live.component.html',
    styleUrls: ['./live.component.scss'],
})
export class LiveComponent  {
    @Input() liveOutline;
    @Input() live;
}
