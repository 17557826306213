import {Injectable} from '@angular/core';
import {AlertController, NavController} from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class AlertService {

    constructor(
        private alertCtrl: AlertController,
        private navCtrl: NavController
    ) {
    }

    async alert(header = 'DefaultHeader', subHeader = 'DefaultSubheader', message= 'Defaultmessage', buttonText = 'OK') {
        const alert = await this.alertCtrl.create({
            header,
            subHeader,
            message,
            buttons: [{
                text: buttonText,
                handler: () => {
                    alert.dismiss().then(() => {
                        this.navCtrl.pop();
                    });
                    return false;
                }
            }]
        });
        await alert.present();
    }

    async alertConfirm(msg: string) {
        const alert = await this.alertCtrl.create({
            header: 'Löschen',
            message: msg,
            buttons: [
                {
                    text: 'Nein',
                    role: 'nein',
                    cssClass: 'secondary',
                    handler: () => alert.dismiss(true)
                },
                {
                    text: 'Ja',
                    role: 'ja',
                    handler: () => alert.dismiss(true)
                }
            ]
        });

        await alert.present();
        return await alert.onDidDismiss();
    }
}
