export const VORNAMEN = [
        {
            dt3: 'Noach',
            bedeutung: 'männl., “Feuer, Licht Gottes”',
            es2: 'ܢܘܚ',
            ar3: '',
            dt1: 'Nuh',
            es1: 'ܢܘܚ',
            dt2: 'Noah',
            ar1: 'ܢܘܚ',
            id: '1968',
            es3: '',
            ar2: 'ܢܽܘܚ'
        },
        {
            ar1: 'ܢܝܢܘܪܬܐ',
            es2: 'ܢܝܢܘܪܬܐ',
            ar2: 'ܢܺܝܢܳܘܪܬܰܐ',
            dt3: '',
            es3: 'ܢܝܢܘܪܬܐ',
            bedeutung: 'männl., syrischen Ursprungs; “Auferstehung, Aufstieg”',
            ar3: 'ܢܺܝܢܽܘܪܬܰܐ',
            dt2: '',
            dt1: 'Ninorta',
            es1: 'ܢܝܢܘܪܬܐ',
            id: '1960'
        },
        {
            ar1: 'ܐܘܓܝܢ',
            ar2: 'ܐܰܘܓܺܝܢ',
            es3: '',
            es1: 'ܐܘܓܝܢ',
            dt3: '',
            es2: 'ܐܘܓܝܢ',
            dt1: 'Augin',
            bedeutung: 'männl, Heiliger der syrisch-orthodoxen Kirche (†363); Festtag: 20. April; weitere Formen: Augin, Avgin, Marawge, Maravge',
            ar3: '',
            id: '128',
            dt2: 'Awgin'
        },
        {
            bedeutung: 'weibl., siehe Amed',
            ar3: '',
            id: '74',
            dt3: '',
            dt2: '',
            dt1: 'Amedya',
            es3: '',
            es1: 'ܐܡܕܝܐ',
            ar2: 'ܐܰܡܶܕܝܰܐ',
            ar1: 'ܐܡܕܝܐ',
            es2: 'ܐܡܕܝܐ'
        },
        {
            ar2: 'ܒܰܪ̱ܬܫܰܢ̱ܬܳܐ',
            dt1: 'Bathschato',
            dt2: '',
            bedeutung: 'männl., siehe Petrus',
            ar1: 'ܒܪ̱ܬܫܢ̱ܬܐ',
            id: '1391',
            es3: '',
            ar3: '',
            es1: 'ܒܪܬܫܢܬܐ',
            dt3: '',
            es2: 'ܒܪܬܫܢܬܐ'
        },
        {
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “vergessen, Vergessling”. Im AT begegnet Menasche als Sohn Josephs und Bruder Efraims, ferner als Sohn und Nachfolger des Hiskia als 14. König von Juda (696-795 v. Chr.). Weitere Formen: Manasse, Mnasche',
            es1: 'ܡܢܫܐ',
            id: '696',
            dt1: 'Menasche',
            ar3: '',
            es2: 'ܡܢܫܐ',
            dt2: '',
            dt3: '',
            ar1: 'ܡܢܫܐ',
            ar2: 'ܡܶܢܰܫܶܐ',
            es3: ''
        },
        {
            es3: '',
            ar1: 'ܐܒܝܠܐ',
            dt2: '',
            ar2: 'ܐܰܒܺܝܠܳܐ',
            id: '18',
            es2: 'ܐܒܝܠܐ',
            dt3: '',
            ar3: '',
            dt1: 'Abilo',
            bedeutung: 'männl., “jemand, der traurig ist, Trauriger”; eine Mönchsrichtung, die sog. “Abile” (die Traurigen, “die Einsiedler”)',
            es1: 'ܐܒܝܠܐ'
        },
        {
            es1: 'ܣܐܘܝܪܐ',
            es3: 'ܣܐܘܪܝܘܣ',
            bedeutung: 'männl., aus dem lat. “severus: ernsthaft, streng”; in der Kirchengeschichte ist Sevire Patriarch (†538/42) der syrisch-orthodoxen Kirche von Antiochien, der sich energisch für den orthodoxen Glauben einsetzte. Festtag: 20. Februar. Weitere Formen: Severus, Severios',
            id: '990',
            es2: 'ܣܐܘܝܪܐ',
            dt3: 'Severos',
            dt1: 'Sevire',
            ar3: 'ܣܶܐܘܺܪܝܳܘܣ',
            dt2: 'Severios',
            ar1: 'ܣܐܘܝܪܐ',
            ar2: 'ܣܶܐܘܺܝܪܶܐ'
        },
        {
            es1: 'ܐܟܝ ܦܝܟܪܝ',
            dt1: 'Akay Fikri',
            es2: 'ܐܟܝ ܦܝܟܪܝ',
            ar1: 'ܐܟܝ ܦܝܟܪܝ',
            ar2: 'ܐܰܟܰܝ ܦܺܝܟܪܺܝ',
            bedeutung: '',
            id: '1243',
            dt3: '',
            es3: '',
            dt2: '',
            ar3: ''
        },
        {
            ar1: 'ܐܠܝܣܦ',
            id: '308',
            ar3: '',
            es2: 'ܐܠܝܣܦ',
            dt3: '',
            dt1: 'Eljasaf',
            dt2: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott hat hinzugefügt”',
            es3: '',
            es1: 'ܐܠܝܣܦ',
            ar2: 'ܐܶܠܝܰܣܰܦ'
        },
        {
            es2: 'ܐܘܪܡܝܐ',
            es1: 'ܐܘܪܡܝܐ',
            dt3: '',
            dt2: '',
            ar3: 'ܐܽܘܪܡܺܝ',
            es3: 'ܐܘܪܡܝ',
            id: '1095',
            dt1: 'Urmiya',
            bedeutung: 'weibl., “Stadt des Wassers”. Urmiya oder Urmi ist eine Stadt in Aram Beth-Nahrin (= Mesopotamien, heute im Iran). Weitere Formen: Urmia, Urmi',
            ar1: 'ܐܘܪܡܝܐ',
            ar2: 'ܐܽܘܪܡܺܝܰܐ'
        },
        {
            es1: 'ܣܥܕܐ',
            id: '874',
            ar2: 'ܣܰܥܕܳܐ',
            dt1: 'Saado',
            ar1: 'ܣܥܕܐ',
            dt3: '',
            dt2: '',
            bedeutung: 'männl., siehe Said',
            ar3: '',
            es3: '',
            es2: 'ܣܥܕܐ'
        },
        {
            ar2: 'ܫܰܪܺܝܪ',
            ar1: 'ܫܪܝܪ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “kleine Sonne”. Im AT war Schemschun eine tapfere und mächtige Persönlichkeit, Ehemann von Dalila, in der Kirchengeschichte ein Bischof von Arbela (120 - 123). Weitere Formen: Samson, Simson, Schimschon, Schemschon',
            dt2: '',
            es3: '',
            dt1: 'Scharir',
            es1: 'ܫܪܝܪ',
            id: '2140',
            es2: 'ܫܪܝܪ',
            dt3: '',
            ar3: ''
        },
        {
            es1: 'ܨܠܝܚܐ',
            dt1: 'Saliha',
            ar2: 'ܨܰܠܺܝܚܰܐ',
            es2: 'ܨܠܝܚܐ',
            dt3: '',
            dt2: '',
            ar1: 'ܨܠܝܚܐ',
            bedeutung: 'männl., König von Hatra (300 - 340 n.Chr.); weitere Formen: Sanatrok',
            id: '2087',
            ar3: '',
            es3: ''
        },
        {
            id: '207',
            ar2: 'ܒܰܪ̱ܬܠܶܒܰܐ',
            dt2: '',
            es3: '',
            es2: 'ܒܪܬܠܒܐ',
            bedeutung: 'weibl., “Tochter des Herzens”; weitere Formen: Bathleba, Bathlebi',
            es1: 'ܒܪܬܠܒܐ',
            dt1: 'Bathlebo',
            ar1: 'ܒܪ̱ܬܠܒܐ',
            dt3: '',
            ar3: ''
        },
        {
            es1: 'ܐܠܝܫܡܥ',
            ar2: 'ܐܶܠܺܝܫܰܡܰܥ',
            es3: 'ܐܠܝܫܡܥ',
            ar1: 'ܐܠܝܫܡܥ',
            id: '305',
            ar3: 'ܐܶܠܺܝܫܡܰܥ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott hat gehört”; weitere Formen: Elischama',
            dt3: '',
            dt2: 'Elischama',
            dt1: 'Elischamah',
            es2: 'ܐܠܝܫܡܥ'
        },
        {
            dt2: '',
            dt1: 'Anhar',
            id: '87',
            bedeutung: 'männl., “Erleuchteter”',
            ar1: 'ܐܢܗܪ',
            ar3: '',
            es1: 'ܐܢܗܪ',
            es3: '',
            es2: 'ܐܢܗܪ',
            ar2: 'ܐܰܢܗܰܪ',
            dt3: ''
        },
        {
            ar1: 'ܬܡܝܡܬܐ',
            es2: 'ܬܡܝܡܬܐ',
            dt3: '',
            bedeutung: 'männl., “Wunder”',
            ar2: 'ܬܰܡܺܝܡܬܰܐ',
            dt2: '',
            dt1: 'Tamimta',
            ar3: '',
            es1: 'ܬܡܝܡܬܐ',
            es3: '',
            id: '2228'
        },
        {
            es3: '',
            dt2: '',
            dt1: 'Amta',
            dt3: '',
            id: '1264',
            ar1: 'ܐܡܬܐ',
            ar3: '',
            es1: 'ܐܡܬܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname griechischen Ursprungs; “der Mannhafte, der Männliche, der Tapfere”. Im NT ist Andreas einer der zwölf Apostel Jesu Christi und zugleich der Bruder des Apostels Petrus',
            ar2: 'ܐܰܡܬܰܐ',
            es2: 'ܐܡܬܐ'
        },
        {
            es1: 'ܓܠܝܟ',
            dt2: '',
            id: '243',
            es3: '',
            dt1: 'Celik',
            ar1: 'ܓܠܝܟ',
            dt3: '',
            bedeutung: '',
            ar2: 'ܓܶܠܺܝܟ',
            ar3: '',
            es2: 'ܓܠܝܟ'
        },
        {
            id: '1207',
            ar1: 'ܐܒܪܡ',
            dt3: '',
            dt1: 'Abrom',
            ar3: '',
            es3: '',
            es1: 'ܐܒܪܡ',
            ar2: 'ܐܰܒܪܳܡ',
            dt2: '',
            es2: 'ܐܒܪܡ',
            bedeutung: ''
        },
        {
            ar3: '',
            id: '139',
            es1: 'ܒܒܝ',
            es2: 'ܒܒܝ',
            ar2: 'ܒܰܒܰܝ',
            ar1: 'ܒܒܝ',
            dt2: 'Babay',
            dt3: '',
            dt1: 'Babai',
            es3: '',
            bedeutung: 'männl., “Mächtiger, Starker” oder “Liebling” männl., “mein Vater”. Babai (5_Jh.) war Katliolikos der syrisch-nestorianischen Kirche. Weitere Formen: Baboi, Bobi'
        },
        {
            ar2: 'ܪܰܚܶܝܠ',
            ar3: '',
            dt3: '',
            es1: 'ܪܚܝܠ',
            dt2: '',
            bedeutung: 'weibl., Nebenform zu Rahel',
            es3: '',
            ar1: 'ܪܚܝܠ',
            id: '833',
            es2: 'ܪܚܝܠ',
            dt1: 'Rachel'
        },
        {
            ar1: 'ܡܪܕܘܬܐ',
            ar3: '',
            ar2: 'ܡܰܪܕܽܘܬܰܐ',
            bedeutung: 'männl., aus dem Lateinischen übernommener Vorname; “marinus zum Meer gehörend; Mars: Name des Kriegsgottes” oder “Hammer”. Im NT ist Markus Beiname eines Judenchristen Johannes, Vetter des Barnabas, Mitarbeiter und Gefährte des Paulus. Markus ist der Verfasser des zweiten und ältesten Evangeliums. Weitere Formen: Marqus, Marqos',
            es1: 'ܡܪܕܘܬܐ',
            es2: 'ܡܪܕܘܬܐ',
            es3: '',
            dt3: '',
            id: '1852',
            dt1: 'Marduta',
            dt2: ''
        },
        {
            dt2: '',
            ar3: 'ܚܰܢܰܢ',
            dt1: 'Hanan',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe hat Gnade gewirkt”; weitere Formen: Hanon',
            dt3: '',
            ar1: 'ܚܐܢܢ',
            id: '432',
            es2: 'ܚܐܢܢ',
            es3: 'ܚܢܢ',
            es1: 'ܚܐܢܢ',
            ar2: 'ܚܰܐܢܳܢ'
        },
        {
            ar1: 'ܚܓܝ',
            dt3: '',
            es3: 'ܚܐܓܝ',
            es2: 'ܚܓܝ',
            dt1: 'Haggai',
            ar2: 'ܚܰܓܰܝ',
            ar3: 'ܚܰܐܓܰܝ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “[geboren an einem] Festtag“. Haggai ist im AT ein Prophet (um 520 v.Chr.) und Zeitgenosse des Sacharja. Weitere Formen: Hagay',
            id: '421',
            dt2: 'Haggay',
            es1: 'ܚܓܝ'
        },
        {
            es2: 'ܝܝܬܪ',
            dt2: 'Jitar',
            dt3: '',
            bedeutung: 'männl., “reinigen, schmücken, zieren, verzieren”',
            ar2: 'ܝܺܝܬܰܪ',
            es1: 'ܝܝܬܪ',
            id: '2330',
            es3: '',
            ar3: '',
            ar1: 'ܝܝܬܪ',
            dt1: 'Yitar'
        },
        {
            dt1: 'Toni',
            dt2: 'Tony',
            bedeutung: '',
            ar1: 'ܬܢܝ',
            ar3: '',
            es2: 'ܬܢܝ',
            dt3: '',
            es1: 'ܬܢܝ',
            es3: '',
            id: '1072',
            ar2: 'ܬܳܢܺܝ'
        },
        {
            es2: 'ܩܘܡܐ',
            dt2: '',
            ar2: 'ܩܰܘܡܳܐ',
            es3: '',
            dt3: '',
            es1: 'ܩܘܡܐ',
            bedeutung: 'männl., “Erhebung, Aufstand, Revolution, Auferstehung, Steigung, Anstieg”; weitere Formen: Qawmo',
            ar1: 'ܩܘܡܐ',
            id: '813',
            ar3: '',
            dt1: 'Qaumo'
        },
        {
            es2: 'ܪܦܐܝܠ',
            bedeutung: 'weibl., “Höhe, Hoheit, Hochmut, Stolz”; weitere Formen: Ramutha',
            dt3: '',
            ar2: 'ܪܰܦܰܐܝܶܠ',
            ar3: '',
            es1: 'ܪܦܐܝܠ',
            id: '2026',
            dt2: 'Raphael',
            es3: '',
            dt1: 'Rafael',
            ar1: 'ܪܦܐܝܠ'
        },
        {
            es2: 'ܛܒܝܒܬܐ',
            ar1: 'ܛܒܝܒܬܐ',
            id: '1042',
            dt1: 'Tbibta',
            ar3: '',
            dt3: '',
            es1: 'ܛܒܝܒܬܐ',
            bedeutung: 'weibl., “Glanzvolle, Berühmte, Glorreiche, Gefeierte”',
            dt2: '',
            ar2: 'ܛܒܺܝܒܬܰܐ',
            es3: ''
        },
        {
            es1: 'ܐܪܒܘܒܐ',
            id: '1276',
            es2: 'ܐܪܒܘܒܐ',
            dt3: '',
            dt2: '',
            dt1: 'Arbuba',
            bedeutung: 'männl., “vergrößert, erhöht”',
            ar3: '',
            ar1: 'ܐܪܒܘܒܐ',
            ar2: 'ܐܰܪܒܽܘܒܰܐ',
            es3: ''
        },
        {
            ar1: 'ܐܬܐ',
            ar3: '',
            dt1: 'Ata',
            dt3: '',
            es2: 'ܐܬܐ',
            ar2: 'ܐܰܬܰܐ',
            es3: '',
            dt2: '',
            bedeutung: '',
            id: '1294',
            es1: 'ܐܬܐ'
        },
        {
            es1: 'ܫܒܐ',
            dt3: '',
            es3: 'ܫܒܐ',
            id: '950',
            es2: 'ܫܒܐ',
            dt1: 'Schebo',
            ar1: 'ܫܒܐ',
            ar2: 'ܫܶܒܳܐ',
            ar3: 'ܫܶܒܰܥ',
            bedeutung: 'männl., “Fülle” oder “Schwur” oder “Sieben”. Enkel von Arphachschad und Eber. Weitere Formen: Scheba',
            dt2: 'Scheba'
        },
        {
            ar3: '',
            ar2: 'ܡܰܛܰܪܬܰܐ',
            ar1: 'ܡܛܪܬܐ',
            es3: '',
            es1: 'ܡܛܪܬܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “vergessen, Vergessling”. Im AT begegnet Menasche als Sohn Josephs und Bruder Efraims, ferner als Sohn und Nachfolger des Hiskia als 14. König von Juda (696-795 v. Chr.). Weitere Formen: Manasse, Mnasche',
            dt3: '',
            id: '1876',
            es2: 'ܡܛܪܬܐ',
            dt1: 'Matarta',
            dt2: ''
        },
        {
            ar3: '',
            ar2: 'ܪܡܺܝܣܬܰܐ',
            es1: 'ܪܡܝܣܬܐ',
            id: '861',
            dt1: 'Rmista',
            es2: 'ܪܡܝܣܬܐ',
            ar1: 'ܪܡܝܣܬܐ',
            dt3: '',
            bedeutung: 'weibl., “Scharfsinnige, Vernünftige, Ruhige, Nüchterne, Fleißige, Kluge”',
            dt2: '',
            es3: ''
        },
        {
            ar3: '',
            dt3: '',
            dt2: '',
            es1: 'ܓܠܦܢܐ',
            es3: '',
            dt1: 'Gelfono',
            bedeutung: 'männl., aus dem Griechischen übernommener Vorname; “der Landmann/Landwirt”. Mor Gewargis gilt in der Geschichte als Drachentöter und Schutzpatron der Waffenschmiede, Krieger und Landleute, einer der 14 Nothelfer. Weitere Formen: Gewargi, Georg, George, Corc, Circis',
            ar2: 'ܓܶܠܦܳܢܳܐ',
            es2: 'ܓܠܦܢܐ',
            ar1: 'ܓܠܦܢܐ',
            id: '1559'
        },
        {
            ar2: 'ܒܰܪܕܰܝܨܰܢ',
            bedeutung: 'männl, “Sohn des Sonntags”; weitere Formen: Barhadbschabba',
            dt2: '',
            ar3: '',
            dt3: '',
            ar1: 'ܒܪܕܝܨܢ',
            es1: 'ܒܪܕܝܨܢ',
            es2: 'ܒܪܕܝܨܢ',
            id: '1341',
            dt1: 'Bardaisan',
            es3: ''
        },
        {
            dt1: 'Hiyuta',
            dt3: '',
            es3: '',
            id: '1669',
            ar2: 'ܗܺܝܝܽܘܬܰܐ',
            es1: 'ܗܝܝܘܬܐ',
            es2: 'ܗܝܝܘܬܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Sand“. Im AT ist Hul der zweite Sohn Arams. Weitere Formen: Chul',
            ar3: '',
            ar1: 'ܗܝܝܘܬܐ',
            dt2: ''
        },
        {
            ar2: 'ܐܰܛܶܒ',
            dt2: '',
            dt1: 'Ateb',
            dt3: '',
            ar3: '',
            bedeutung: 'männl., “er hat berichtet”',
            id: '117',
            es1: 'ܐܛܒ',
            es3: '',
            es2: 'ܐܛܒ',
            ar1: 'ܐܛܒ'
        },
        {
            ar3: '',
            es1: 'ܡܘܪܐܕ',
            id: '1913',
            bedeutung: 'männl., akkadischen Ursprungs; “Nabu hat mir Nachkommenschaft gegeben”. Nabusaradan war Kommandant Nabuchadnassars II., der 587/586 v. Chr. Jerusalem eroberte und die Juden deportierte. Weitere Formen: Nebusaradan',
            dt2: '',
            ar2: 'ܡܽܘܪܰܐܕ',
            ar1: 'ܡܘܪܐܕ',
            dt1: 'Murat',
            es3: '',
            es2: 'ܡܘܪܐܕ',
            dt3: ''
        },
        {
            es2: 'ܬܐܘܦܘܪܐ',
            bedeutung: 'weibl., siehe Theophoros',
            id: '1051',
            ar3: '',
            ar1: 'ܬܐܘܦܘܪܐ',
            dt3: '',
            es1: 'ܬܐܘܦܘܪܐ',
            dt2: '',
            ar2: 'ܬܶܐܘܳܦܳܘܪܰܐ',
            dt1: 'Theophora',
            es3: ''
        },
        {
            ar3: 'ܐܶܪܰܡܝܰܐ',
            dt2: '',
            id: '534',
            ar1: 'ܐܪܡܝܐ',
            ar2: 'ܐܶܪܰܡܝܳܐ',
            dt1: 'Jeremia',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe erhöht”. Im AT ist Jeremia ein großer Prophet (2. Hälfte des 7. Jh. v. Chr.) und Verfasser eines Buches. Weitere Formen: Eramia, Eramio',
            es3: 'ܐܪܡܝܐ',
            es1: 'ܐܪܡܝܐ',
            es2: 'ܐܪܡܝܐ',
            dt3: ''
        },
        {
            ar2: 'ܚܰܣܺܝܢܬܰܐ',
            id: '450',
            ar3: '',
            bedeutung: 'weibl., syrischen Ursprungs; “tapfere(s), starke(s), mächtige(s) [Frau/Mädchen]”',
            es3: '',
            dt2: '',
            es1: 'ܚܣܝܢܬܐ',
            dt1: 'Hasinta',
            ar1: 'ܚܣܝܢܬܐ',
            es2: 'ܚܣܝܢܬܐ',
            dt3: ''
        },
        {
            es2: 'ܣܢܝܐ',
            dt1: 'Sonja',
            es1: 'ܣܢܝܐ',
            id: '1004',
            ar3: 'ܙܢܝܐ',
            dt3: '',
            bedeutung: '',
            ar1: 'ܣܢܝܐ',
            dt2: '',
            ar2: 'ܣܢܝܐ',
            es3: 'ܙܢܝܐ'
        },
        {
            ar2: 'ܓܰܡܰܠܺܐܝܶܠ',
            ar1: 'ܓܡܠܐܝܠ',
            es3: '',
            es2: 'ܓܡܠܐܝܠ',
            dt3: '',
            id: '1551',
            bedeutung: 'männl., “Flügel”, “großer Adler”',
            es1: 'ܓܡܠܐܝܠ',
            ar3: '',
            dt2: '',
            dt1: 'Gamaliel'
        },
        {
            ar3: '',
            dt3: '',
            ar2: 'ܒܺܝܠܶܢ',
            bedeutung: 'weibl., griechischen Ursprungs; “Bühne, Tnibunal, Kanzel, Stufe, Podium”; weitere Formen: Bema',
            es2: 'ܒܝܠܢ',
            dt1: 'Bilen',
            ar1: 'ܒܝܠܢ',
            id: '1409',
            es3: '',
            dt2: '',
            es1: 'ܒܝܠܢ'
        },
        {
            es1: 'ܐܪܕܫ',
            ar2: 'ܐܰܪܕܰܫ',
            es3: '',
            dt3: '',
            es2: 'ܐܪܕܫ',
            dt1: 'Ardash',
            dt2: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Löwe Gottes” oder “Herd Gottes”',
            ar1: 'ܐܪܕܫ',
            id: '1277',
            ar3: ''
        },
        {
            ar2: 'ܥܰܒܽܘܫܬܳܐ',
            id: '1183',
            es3: '',
            dt2: '',
            dt1: 'Abbuschto',
            bedeutung: '',
            dt3: '',
            ar1: 'ܥܒܘܫܬܐ',
            es2: 'ܥܒܘܫܬܐ',
            ar3: '',
            es1: 'ܥܒܘܫܬܐ'
        },
        {
            ar2: 'ܢܶܡܪܳܐ',
            dt1: 'Nemro',
            ar3: '',
            es2: 'ܢܡܪܐ',
            es3: '',
            ar1: 'ܢܡܪܐ',
            bedeutung: 'männl., “Tiger”',
            id: '760',
            dt3: '',
            es1: 'ܢܡܪܐ',
            dt2: ''
        },
        {
            ar1: 'ܦܛܪܘܣ',
            dt2: 'Petrus',
            id: '807',
            bedeutung: 'männl., aus dem Griechischen übernommener Vorname, der wiederum eine Übertragung des aramäischen Namens Kepha ist; “Stein, Felsblock”. Im NT ist Simon mit dem Beinamen Petrus ein Apostel Jesu Christi und Vorsprecher der Jünger; Petrus gilt als der Gründer und erste Bischof bzw. Patriarch der syrisch-orthodoxen Kirche von Antiochien. Weitere Formen: Fetrus, Bedros, Bedrus',
            dt3: 'Fetrus',
            es3: '',
            ar2: 'ܦܶܛܪܽܘܣ',
            dt1: 'Petros',
            es2: 'ܦܛܪܘܣ',
            ar3: '',
            es1: 'ܦܛܪܘܣ'
        },
        {
            es2: 'ܡܪܕܝܢܐ',
            dt1: 'Merdina',
            id: '697',
            ar1: 'ܡܪܕܝܢܐ',
            dt3: '',
            dt2: '',
            ar2: 'ܡܶܪܕܺܝܢܰܐ',
            es1: 'ܡܪܕܝܢܐ',
            bedeutung: 'weibl., Merdina/Merdin oder Merdo eine bedeutende aramäische Stadt in Aram Beth-Nahrin (= Mesopotamien)',
            ar3: 'ܡܶܪܕܺܝܢ',
            es3: 'ܡܪܕܝܢ'
        },
        {
            id: '1616',
            es3: '',
            es1: 'ܚܡܫܐ',
            dt2: '',
            dt1: 'Hamscho',
            es2: 'ܚܡܫܐ',
            bedeutung: 'männl., syrischen Ursprungs; “genießen, nutzen”',
            ar1: 'ܚܡܫܐ',
            dt3: '',
            ar3: '',
            ar2: 'ܚܰܡܫܳܐ'
        },
        {
            dt1: 'Rabuna',
            dt3: '',
            ar2: 'ܪܰܒܽܘܢܰܐ',
            es2: 'ܪܒܘܢܐ',
            es3: '',
            id: '2022',
            dt2: '',
            ar3: '',
            es1: 'ܪܒܘܢܐ',
            ar1: 'ܪܒܘܢܐ',
            bedeutung: ''
        },
        {
            bedeutung: 'männl., syrischen Ursprungs; “Blume, Pﬂanze, Blüte”',
            dt1: 'Habobo',
            es2: 'ܗܒܒܐ',
            ar1: 'ܗܒܒܐ',
            dt3: '',
            id: '405',
            es1: 'ܗܒܒܐ',
            ar2: 'ܗܰܒܳܒܳܐ',
            es3: '',
            ar3: '',
            dt2: ''
        },
        {
            id: '911',
            ar3: '',
            es2: 'ܣܪܗܒ',
            dt2: '',
            es1: 'ܣܪܗܒ',
            es3: '',
            dt1: 'Sarheb',
            bedeutung: 'männl., “sich beeilen”',
            ar1: 'ܣܪܗܒ',
            ar2: 'ܣܰܪܗܶܒ',
            dt3: ''
        },
        {
            dt1: 'Yamat',
            es2: 'ܝܡܬ',
            ar3: 'ܝܰܡܐܰܬ',
            ar1: 'ܝܡܬ',
            es1: 'ܝܡܬ',
            id: '1125',
            dt2: '',
            dt3: '',
            ar2: 'ܝܰܡܰܬ',
            es3: 'ܝܡܐܬ',
            bedeutung: 'männl., “du bist mein Meer” oder “du bist meine Rechte”'
        },
        {
            ar3: '',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “die Anmutige” oder “Gefallen”; weitere Formen, Terzo, Tarso, Theresa, Tirzah',
            es2: 'ܬܝܪܙܐ',
            ar1: 'ܬܝܪܙܐ',
            dt1: 'Tirza',
            ar2: 'ܬܺܝܪܙܰܐ',
            dt3: '',
            id: '1061',
            dt2: '',
            es1: 'ܬܝܪܙܐ',
            es3: ''
        },
        {
            ar1: 'ܬܕܝ',
            bedeutung: 'männl., siehe Adai',
            dt1: 'Taddai',
            dt2: '',
            ar3: '',
            es2: 'ܬܕܝ',
            es1: 'ܬܕܝ',
            ar2: 'ܬܰܕܰܝ',
            dt3: '',
            id: '1027',
            es3: ''
        },
        {
            bedeutung: 'weibl., “Pfeil, Geschoß, Spitze, Schärfe, Strom, Strombett” Deqlath oder Tigris ist ein Strom/Fluss in Aram Beth-Nahn (= Mesopotamien). Weitere Formen: Deqlat, Tigris',
            dt1: 'Dauscho',
            ar1: 'ܕܘܫܐ',
            es1: 'ܕܘܫܐ',
            id: '1447',
            dt3: 'Debscho',
            ar2: 'ܕܰܘܫܳܐ',
            es2: 'ܕܘܫܐ',
            dt2: 'Debscho',
            ar3: 'ܕܶܒܫܳܐ',
            es3: 'ܕܒܫܐ'
        },
        {
            es3: '',
            es2: 'ܐܝܠܚܘܒܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Mann Jahwes”. Im AT ist Ischai Vater des David, im NT Ahnherr des Messias Jesus. Weitere Formen: Isai, Jesse',
            dt1: 'Ilhuba',
            id: '1686',
            es1: 'ܐܝܠܚܘܒܐ',
            ar1: 'ܐܝܠܚܘܒܐ',
            dt2: '',
            ar2: 'ܐܺܝܠܚܽܘܒܰܐ',
            dt3: '',
            ar3: ''
        },
        {
            ar2: 'ܫܶܡ',
            bedeutung: 'männl., arabisierte Form zu Sem, Schem, Schim',
            dt3: '',
            dt1: 'Sam',
            dt2: '',
            ar1: 'ܣܡ',
            es3: 'ܫܝܡ',
            es2: 'ܣܡ',
            ar3: 'ܫܺܝܡ',
            id: '897',
            es1: 'ܣܡ'
        },
        {
            ar1: 'ܡܪܠܢ',
            es1: 'ܡܪܠܢ',
            dt2: '',
            dt1: 'Marlen',
            es3: '',
            ar3: '',
            es2: 'ܡܪܠܢ',
            id: '1862',
            ar2: 'ܡܰܪܠܶܢ',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Herrin, Seherin“, „widerspenstig”. Maria ist im NT die Mutter Jesu Christi, ein sehr beliebter Vorname bei den Aramäern. Weitere Formen: Mariam, Miryam, Mirjam, Maria, Marija',
            dt3: ''
        },
        {
            es3: 'ܚܙܘ',
            id: '1639',
            es2: 'ܚܙܐ',
            ar3: 'ܚܰܙܽܘ',
            ar1: 'ܚܙܐ',
            bedeutung: '',
            dt1: 'Haso',
            es1: 'ܚܙܐ',
            dt3: '',
            ar2: 'ܚܰܙܳܐ',
            dt2: ''
        },
        {
            dt2: '',
            bedeutung: 'weibl., siehe Rayma',
            es3: 'ܪܟܘܒ',
            es1: 'ܪܟܒ',
            ar1: 'ܪܟܒ',
            ar2: 'ܪܶܟܳܒ',
            dt1: 'Rekob',
            es2: 'ܪܟܒ',
            dt3: '',
            ar3: 'ܪܰܟܽܘܒ',
            id: '2043'
        },
        {
            ar1: 'ܒܪܥܡܐ',
            es1: 'ܒܪܥܡܐ',
            bedeutung: 'männl., “Sohn des Onkels” oder “Sohn des Volkes”',
            ar3: '',
            id: '158',
            dt1: 'Baramo',
            dt3: '',
            es3: '',
            es2: 'ܒܪܥܡܐ',
            ar2: 'ܒܰܪܥܰܡܳܐ',
            dt2: ''
        },
        {
            es2: 'ܪܡܐܝܠ',
            dt1: 'Romil',
            dt2: '',
            ar3: '',
            bedeutung: 'männl., “Gott ist erhaben”',
            ar2: 'ܪܳܡܐܺܝܠ',
            id: '869',
            ar1: 'ܪܡܐܝܠ',
            dt3: '',
            es3: '',
            es1: 'ܪܡܐܝܠ'
        },
        {
            ar2: 'ܐܰܕܶܫܰܐ',
            es3: '',
            ar1: 'ܐܕܫܐ',
            es2: 'ܐܕܫܐ',
            dt1: 'Adescha',
            dt2: '',
            ar3: '',
            es1: 'ܐܕܫܐ',
            id: '36',
            dt3: '',
            bedeutung: ''
        },
        {
            ar2: 'ܛܶܦܪܰܐ',
            es3: '',
            dt2: '',
            bedeutung: 'weibl., “Nagel, Klaue, Kralle, Tatze, Pranke”; weitere Formen: Tufra',
            es2: 'ܛܦܪܐ',
            dt3: '',
            dt1: 'Tefra',
            ar3: '',
            ar1: 'ܛܦܪܐ',
            id: '1043',
            es1: 'ܛܦܪܐ'
        },
        {
            dt3: '',
            ar2: 'ܢܶܫܪܳܐ',
            bedeutung: 'weibl., wohl “Gründerin, Errichterin”. Nisibin/Nisibena ist eine Stadt in Aram-Beth-Nahrin (=Mesopotamien)',
            ar1: 'ܢܫܪܐ',
            id: '1957',
            es3: '',
            es1: 'ܢܫܪܐ',
            dt2: '',
            es2: 'ܢܫܪܐ',
            ar3: '',
            dt1: 'Neschro'
        },
        {
            ar2: 'ܐܰܒܺܝܠ',
            ar3: '',
            es1: 'ܐܒܝܠ',
            es2: 'ܐܒܝܠ',
            dt2: '',
            es3: '',
            bedeutung: '',
            ar1: 'ܐܒܝܠ',
            id: '17',
            dt3: '',
            dt1: 'Abil'
        },
        {
            es2: 'ܪܒܒܘܠܐ',
            dt2: '',
            id: '823',
            bedeutung: 'männl., “Schafhirte, Hirte, Schäfer oder mein Herr, mein Lehrer”; weitere Formen: Rabula, Rabuli',
            es3: 'ܪܒܘܠܐ',
            ar3: 'ܪܰܒܽܘܠܰܐ',
            ar2: 'ܪܰܒܒܽܘܠܰܐ',
            es1: 'ܪܒܒܘܠܐ',
            dt1: 'Rabbula',
            dt3: '',
            ar1: 'ܪܒܒܘܠܐ'
        },
        {
            ar2: 'ܛܰܒܺܝܬܰܐ',
            id: '2217',
            dt2: '',
            bedeutung: 'weibl., siehe Tamania',
            es1: 'ܛܒܝܬܐ',
            ar3: '',
            dt3: '',
            dt1: 'Tabita',
            ar1: 'ܛܒܝܬܐ',
            es3: '',
            es2: 'ܛܒܝܬܐ'
        },
        {
            ar2: 'ܒܰܪܢܺܝܪܳܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Blitz”. Im AT ist Barak/ Barqo Sohn des Abinoamaus Kadesch (um 1200 v. Chr.). Weitere Formen: Baraqa, Barqa, Barak, Baraka',
            dt2: '',
            es3: '',
            ar3: '',
            ar1: 'ܒܪܢܝܪܐ',
            id: '1358',
            es2: 'ܒܪܢܝܪܐ',
            dt1: 'Barniro',
            es1: 'ܒܪܢܝܪܐ',
            dt3: ''
        },
        {
            es2: 'ܒܟܝܪ',
            ar3: '',
            id: '145',
            dt3: '',
            ar2: 'ܒܰܟܺܝܪ',
            bedeutung: '',
            es3: '',
            es1: 'ܒܟܝܪ',
            dt2: '',
            ar1: 'ܒܟܝܪ',
            dt1: 'Bakir'
        },
        {
            dt3: '',
            dt2: '',
            es3: 'ܣܡܟ݂ܝܐ',
            es1: 'ܣܡܟ݂ܝܐ',
            ar1: 'ܣܡܟ݂ܝܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe hat unterstützt”',
            es2: 'ܣܡܟ݂ܝܐ',
            id: '985',
            dt1: 'Semachja',
            ar3: 'ܣܡܰܟ݂ܝܰܐ',
            ar2: 'ܣܡܰܟ݂ܝܳܐ'
        },
        {
            ar1: 'ܐܪܢܐܣܝܐ',
            id: '102',
            bedeutung: 'weibl., Arnasia ist ein Dorf in Turabdin',
            es2: 'ܐܪܢܐܣܝܐ',
            es3: '',
            dt3: '',
            ar2: 'ܐܰܪܢܰܐܣܺܝܰܐ',
            ar3: '',
            dt2: 'Arnasja',
            dt1: 'Arnasia',
            es1: 'ܐܪܢܐܣܝܐ'
        },
        {
            bedeutung: 'männl., “Wille, Wunsch”',
            id: '2166',
            ar2: 'ܫܽܘܒܚܳܐܠܝܶܫܽܘܥ',
            es2: 'ܫܘܒܚܐܠܝܫܘܥ',
            dt3: '',
            ar1: 'ܫܘܒܚܐܠܝܫܘܥ',
            dt2: '',
            dt1: 'Schubholjeschu',
            es1: 'ܫܘܒܚܐܠܝܫܘܥ',
            ar3: '',
            es3: ''
        },
        {
            dt2: '',
            dt1: 'Nahschirtono',
            es3: '',
            ar2: 'ܢܰܚܫܺܝܪܬܳܢܳܐ',
            es1: 'ܢܚܫܝܪܬܢܐ',
            id: '1931',
            es2: 'ܢܚܫܝܪܬܢܐ',
            dt3: '',
            ar1: 'ܢܚܫܝܪܬܢܐ',
            bedeutung: 'männl., “freuen wir uns”',
            ar3: ''
        },
        {
            dt1: 'Rumromo',
            id: '2064',
            ar2: 'ܪܽܘܡܪܳܡܳܐ',
            es1: 'ܪܘܡܪܡܐ',
            es3: '',
            ar3: '',
            es2: 'ܪܘܡܪܡܐ',
            ar1: 'ܪܘܡܪܡܐ',
            dt2: '',
            bedeutung: '',
            dt3: ''
        },
        {
            ar2: 'ܚܰܠܦܰܝ',
            es3: '',
            es1: 'ܚܠܦܝ',
            ar1: 'ܚܠܦܝ',
            dt1: 'Halfai',
            es2: 'ܚܠܦܝ',
            id: '1613',
            ar3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe hat Gnade gewirkt”. Im NT begegnet Hananja/Hananias als ein Judenchrist in Damaskus, der in einem Traum die Weisung erhält, dem blinden Saulus (Paulus) durch Auflegen der Hände das Augenlicht zurückzugeben. Weitere Formen: Hananjo, Hananias',
            dt2: '',
            dt3: ''
        },
        {
            ar2: 'ܙܟ݂ܰܪܝܰܐ',
            dt2: '',
            id: '2337',
            es2: 'ܙܟ݂ܪܝܐ',
            ar3: 'ܙܟ݂ܰܪܝܳܐ',
            es1: 'ܙܟ݂ܪܝܐ',
            dt1: 'Zacharia',
            es3: 'ܙܟ݂ܪܝܐ',
            ar1: 'ܙܟ݂ܪܝܐ',
            dt3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “der Reine, der Unschuldige”. Im AT ist Zakai ein Familienoberhaupt, dessen Söhne mit Serubbabel aus dem Exil heimkehren; im NT ist Zakai ein reicher Zöllner oder Obersteuereinnehmer in Jericho. Zakai als kleine Gestalt steigt auf einen Maulbeerfeigenbaum, um Jesus beim Einzug in Jericho besser zu sehen, wobei er Jesus mit Freuden in sein Haus aufnimmt und beteuert, in Zukunft seinen Besitz mit den Annen zu teilen. Jesus erklärt ihn im Gegensatz zur jüdischen Volksmeinung, die die Zöllner verabscheute, zum vollwertigen Mitglied der jüdischen Gesellschaft. Weitere Formen: Zakkai, Zakay, Zaki, Zeki, Zachäus'
        },
        {
            bedeutung: 'männl., “Licht, Strahl, Glanz”',
            id: '2343',
            es3: '',
            dt1: 'Zahro',
            ar3: '',
            ar1: 'ܙܗܪܐ',
            dt3: '',
            ar2: 'ܙܰܗܪܳܐ',
            es1: 'ܙܗܪܐ',
            dt2: '',
            es2: 'ܙܗܪܐ'
        },
        {
            es3: '',
            ar1: 'ܬܐܘܕܘܪܐ',
            es1: 'ܬܐܘܕܘܪܐ',
            bedeutung: 'weibl., zu Theodor(os)',
            dt2: 'Teodora',
            id: '1048',
            ar3: '',
            dt3: '',
            ar2: 'ܬܶܐܘܳܕܳܘܪܰܐ',
            dt1: 'Theodora',
            es2: 'ܬܐܘܕܘܪܐ'
        },
        {
            ar2: 'ܐܶܕܢܰܐ',
            ar3: '',
            id: '1476',
            dt3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “du bist mein Gott”',
            ar1: 'ܐܕܢܐ',
            dt2: 'Ednha',
            dt1: 'Edna',
            es2: 'ܐܕܢܐ',
            es1: 'ܐܕܢܐ',
            es3: ''
        },
        {
            es3: '',
            es2: 'ܝܡܢܗܪ',
            ar2: 'ܝܰܡܢܰܗܰܪ',
            id: '1124',
            es1: 'ܝܡܢܗܪ',
            dt2: '',
            ar1: 'ܝܡܢܗܪ',
            dt1: 'Yam Nahar',
            ar3: '',
            dt3: '',
            bedeutung: 'männl., “Meer-Fluss”. Yam Nahar ist der von den vorchristlichen Aramäern verehrte Meeresgott. Weitere Formen: Yam Nahrin, Yanmahar, Jamnahar, Yamnahar'
        },
        {
            dt3: '',
            ar1: 'ܕܟܝܐ',
            es3: '',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “die Wellenlockige, Verführerin”. Im AT ist Dalila (um 1200 v.Chr.) die Geliebte von Simson',
            es1: 'ܕܟܝܐ',
            ar3: '',
            dt1: 'Dakya',
            es2: 'ܕܟܝܐ',
            id: '1431',
            dt2: '',
            ar2: 'ܕܰܟܝܰܐ'
        },
        {
            ar3: '',
            dt2: 'Tarzo',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “die Anmutige” oder “Gefallen”; weitere Formen, Terzo, Tarso, Theresa, Tirzah',
            ar1: 'ܬܪܙܐ',
            es1: 'ܬܪܙܐ',
            dt3: 'Terzo',
            id: '1039',
            es2: 'ܬܪܙܐ',
            es3: '',
            ar2: 'ܬܰܪܙܳܐ',
            dt1: 'Tarso'
        },
        {
            ar3: 'ܢܰܐܝܠܰܐ',
            dt2: '',
            ar2: 'ܢܐܝܠܐ',
            es3: 'ܢܐܝܠܐ',
            dt3: '',
            ar1: 'ܢܝܠܐ',
            es1: 'ܢܝܠܐ',
            id: '1947',
            bedeutung: 'weibl., “Adlersstein”; weitere Formen: Neschrajta',
            es2: 'ܢܝܠܐ',
            dt1: 'Nayla'
        },
        {
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs. Im, AT ist Schamir ein Levit, eine Ortschaft im Bergland Juda, eine Stadt im Gebirge Efraim. Weitere Formen: Schomir',
            ar1: 'ܫܡܝܪ',
            ar3: '',
            es2: 'ܫܡܝܪ',
            es3: '',
            id: '938',
            dt2: '',
            es1: 'ܫܡܝܪ',
            ar2: 'ܫܰܡܺܝܪ',
            dt3: '',
            dt1: 'Schamir'
        },
        {
            ar3: '',
            dt3: '',
            es1: 'ܛܘܪܝܐ',
            es2: 'ܛܘܪܝܐ',
            dt2: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott ist mein Licht”; Uriel gilt im AT als ein Erzengel',
            id: '2275',
            ar2: 'ܛܽܘܪܳܝܳܐ',
            es3: '',
            ar1: 'ܛܘܪܝܐ',
            dt1: 'Turoyo'
        },
        {
            ar3: 'ܢܰܐܽܘܡܺܝ',
            ar2: 'ܢܰܐܳܘܡܺܝ',
            dt1: 'Naomi',
            ar1: 'ܢܐܘܡܝ',
            id: '745',
            es2: 'ܢܐܘܡܝ',
            es3: 'ܢܐܘܡܝ',
            dt3: '',
            dt2: '',
            bedeutung: 'siehe Naima',
            es1: 'ܢܐܘܡܝ'
        },
        {
            es3: 'ܪܚܒ',
            dt1: 'Rahab',
            ar3: 'ܪܳܚܳܒ',
            ar1: 'ܪܚܒ',
            dt2: '',
            id: '836',
            dt3: '',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “wild, stürmisch”. Im AT ist Rahab eine Dime aus Jericho, die die Kundschafter Josuas (Bar-Nun) rettet, indem sie sie in Ihrem Haus versteckt; das NT lobt die Tat von Rahab wegen Ihres Glaubens und Ihrer Gastfreundschaft, bezeichnet sie als eine Ahnfrau Jesu. Weitere Formen: Rohob',
            es2: 'ܪܚܒ',
            es1: 'ܪܚܒ',
            ar2: 'ܪܰܚܰܒ'
        },
        {
            dt3: '',
            es1: 'ܒܪܘܟ݂',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gesegneter”. Im AT ist Baruch, Sohn des Nerija, Sekretär des Propheten Jeremia, Verfasser eines alttestamentlichen Buches. Weitere Formen: Barukh',
            dt2: '',
            ar2: 'ܒܰܪܽܘܟ݂',
            es2: 'ܒܪܘܟ݂',
            id: '193',
            ar1: 'ܒܪܘܟ݂',
            ar3: '',
            es3: '',
            dt1: 'Baruch'
        },
        {
            bedeutung: 'männl., Schüler Ephräms',
            es2: 'ܒܘܠܘܢܐ',
            ar2: 'ܒܳܘܠܳܘܢܰܐ',
            dt3: '',
            ar3: '',
            es1: 'ܒܘܠܘܢܐ',
            id: '233',
            es3: '',
            dt1: 'Bolona',
            dt2: '',
            ar1: 'ܒܘܠܘܢܐ'
        },
        {
            es2: 'ܫܠܝܐ',
            es3: '',
            es1: 'ܫܠܝܐ',
            dt3: '',
            dt2: '',
            ar2: 'ܫܰܠܝܳܐ',
            dt1: 'Schalyo',
            ar1: 'ܫܠܝܐ',
            ar3: '',
            id: '933',
            bedeutung: 'männl., “ruhig, still, gelassen, gefasst, unbesorgt, getrost“, weitere Formen: Schaljo'
        },
        {
            id: '1074',
            bedeutung: 'weibl., “die Korrekte, Fehlerfreie, Ordentliche”',
            ar2: 'ܬܪܺܝܨܬܰܐ',
            dt1: 'Trista',
            dt2: '',
            ar1: 'ܬܪܝܨܬܐ',
            es1: 'ܬܪܝܨܬܐ',
            es3: '',
            dt3: '',
            es2: 'ܬܪܝܨܬܐ',
            ar3: ''
        },
        {
            dt3: '',
            ar2: 'ܝܺܝܩܰܪ',
            dt2: 'Jiqar',
            dt1: 'Yiqar',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “der Herr hat sich meiner erinnert” oder “Jahwe war eingedenk”. Im AT ist Zacharia ein Prophet in Juda nach der Zeit des babylonischen Exils (um 520 v.Chr.); im NT dagegen gilt Zacharia als Vater von Johannes dem Täufer. Weitere Formen: Sacharia, Sacharja, Zcharjo, Zchario, Zekeriye, Zakai',
            es2: 'ܝܝܩܪ',
            ar3: '',
            es3: '',
            es1: 'ܝܝܩܪ',
            id: '2328',
            ar1: 'ܝܝܩܪ'
        },
        {
            ar1: 'ܒܘܠܘܬ',
            bedeutung: '',
            es1: 'ܒܘܠܘܬ',
            es2: 'ܒܘܠܘܬ',
            es3: '',
            id: '1419',
            dt2: '',
            dt1: 'Bulut',
            dt3: '',
            ar3: '',
            ar2: 'ܒܽܘܠܽܘܬ'
        },
        {
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Herr ist Jahwe”. Im AT ist Adonija der vierte Sohn Davids und seiner Nebenfrau Haggit',
            dt2: '',
            id: '39',
            es1: 'ܐܕܘܢܝܐ',
            ar2: 'ܐܰܕܳܘܢܝܰܐ',
            dt3: '',
            es3: '',
            ar1: 'ܐܕܘܢܝܐ',
            es2: 'ܐܕܘܢܝܐ',
            dt1: 'Adonija',
            ar3: ''
        },
        {
            es3: '',
            dt3: '',
            ar2: 'ܒܰܣܡܰܐ',
            es1: 'ܒܣܡܐ',
            ar1: 'ܒܣܡܐ',
            bedeutung: '',
            dt1: 'Basma',
            id: '200',
            ar3: '',
            es2: 'ܒܣܡܐ',
            dt2: ''
        },
        {
            bedeutung: 'männl., “kleiner Olivenbaum”',
            ar2: 'ܙܰܝܬܽܘܢ',
            ar1: 'ܙܝܬܘܢ',
            es3: '',
            id: '1164',
            es2: 'ܙܝܬܘܢ',
            dt3: '',
            es1: 'ܙܝܬܘܢ',
            dt1: 'Zaytun',
            ar3: '',
            dt2: ''
        },
        {
            es2: 'ܚܢܢܐ',
            ar2: 'ܚܰܢܢܳܐ',
            dt1: 'Hanno',
            dt3: '',
            ar3: '',
            id: '1628',
            ar1: 'ܚܢܢܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe hat Rechnung getragen”',
            es1: 'ܚܢܢܐ',
            es3: '',
            dt2: ''
        },
        {
            es3: '',
            es1: 'ܫܡܫܐ',
            dt1: 'Schemscho',
            dt2: '',
            ar3: '',
            es2: 'ܫܡܫܐ',
            ar1: 'ܫܡܫܐ',
            bedeutung: 'männl., Sonne',
            id: '956',
            ar2: 'ܫܶܡܫܳܐ',
            dt3: ''
        },
        {
            ar2: 'ܙܰܗܺܝ',
            es1: 'ܙܗܝ',
            bedeutung: 'männl., “der Sieger”; weitere Formen: Zako ',
            ar3: '',
            dt3: '',
            dt2: '',
            ar1: 'ܙܗܝ',
            es3: '',
            id: '2339',
            dt1: 'Zahi',
            es2: 'ܙܗܝ'
        },
        {
            ar1: 'ܘܦܐ',
            es2: 'ܘܦܐ',
            es1: 'ܘܦܐ',
            id: '2297',
            bedeutung: 'weibl. Form zu Yadido',
            dt2: '',
            dt1: 'Wapa',
            es3: '',
            ar2: 'ܘܰܦܰܐ',
            ar3: '',
            dt3: ''
        },
        {
            ar2: 'ܐܰܦܪܶܝܡ',
            es1: 'ܐܦܪܝܡ',
            ar3: '',
            ar1: 'ܐܦܪܝܡ',
            dt2: 'Afrem',
            es2: 'ܐܦܪܝܡ',
            id: '294',
            dt3: 'Ephram',
            dt1: 'Efrim',
            es3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “doppelt fruchtbar, fruchtbares Land”. In der Bibel ist Afrem (Ephräm) der zweite Sohn von Joseph; in der Kirchengeschichte ist Ephräm (†373) Kirchenlehrer und Heiliger der syrischen Kirche, Verfasser vieler Hynmen. Weitere Formen: Ephraim, Efraim, Aphrem, Afram, Ephram'
        },
        {
            ar1: 'ܩܘܣܛܢܛܝܢ',
            es2: 'ܩܘܣܛܢܛܝܢ',
            dt1: 'Konstantin',
            es1: 'ܩܘܣܛܢܛܝܢ',
            ar3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “eleasar: Gott ist Helfer, Gott hilft, Gott hat geholfen”. Im NT weckte Jesus den toten Laazar nach drei Tagen ins Leben. Weitere Formen: Eleasar, Lazar, Lazarus, Eliozor, Eliasar',
            ar2: 'ܩܽܘܣܛܰܢܛܺܝܢ',
            dt2: 'Constantin',
            es3: '',
            id: '1791',
            dt3: ''
        },
        {
            ar2: 'ܚܰܝܰܐ',
            es3: '',
            dt2: '',
            dt1: 'Haja',
            dt3: '',
            es1: 'ܚܝܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe hat Gnade gewirkt”; weitere Formen: Hanon',
            ar1: 'ܚܝܐ',
            ar3: '',
            es2: 'ܚܝܐ',
            id: '1611'
        },
        {
            ar3: '',
            es3: '',
            es1: 'ܐܪܝܐ',
            dt1: 'Arjo',
            dt2: 'Aryo',
            id: '1281',
            ar2: 'ܐܰܪܝܳܐ',
            ar1: 'ܐܪܝܐ',
            dt3: 'Ario',
            es2: 'ܐܪܝܐ',
            bedeutung: 'männl., “er hat gedacht”'
        },
        {
            dt3: '',
            es1: 'ܪܚܒܥܡ',
            ar2: 'ܪܚܰܒܥܰܡ',
            ar1: 'ܪܚܒܥܡ',
            dt1: 'Rehabeam',
            ar3: 'ܪܶܚܰܒܥܰܡ',
            es2: 'ܪܚܒܥܡ',
            es3: 'ܪܚܒܥܡ',
            bedeutung: 'weibl., aus dem Aramäischen übernommener Vorname; “Duft”. Weitere Formen: Recha, Riho, Rihane',
            id: '2041',
            dt2: ''
        },
        {
            bedeutung: 'männl., “Offenbarung”',
            es1: 'ܓܠܝܢܐ',
            ar3: '',
            dt3: '',
            dt2: '',
            es3: '',
            ar2: 'ܓܶܠܝܳܢܳܐ',
            es2: 'ܓܠܝܢܐ',
            dt1: 'Gelyono',
            id: '374',
            ar1: 'ܓܠܝܢܐ'
        },
        {
            dt1: 'Jesaja',
            ar3: 'ܐܶܫܰܥܝܰܐ',
            id: '537',
            es1: 'ܐܫܥܐ',
            dt3: '',
            dt2: '',
            es3: 'ܐܫܥܝܐ',
            ar1: 'ܐܫܥܝܐ',
            ar2: 'ܐܶܫܰܥܝܳܐ',
            es2: 'ܐܫܥܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott ist Heil, Gott ist Rettung”. Im AT ist Jesaja Sohn des Amoz, Prophet in Juda (765-701 v. Chr.). Weitere Formen: Jeschaja, Eschaja, Eschajo, Eschahjo'
        },
        {
            id: '1673',
            bedeutung: 'männl., syrischen Ursprungs; “Stolz”',
            es2: 'ܚܘܒܐ',
            es1: 'ܚܘܒܐ',
            dt2: '',
            ar3: 'ܚܽܘܒܰܐ',
            dt3: '',
            ar1: 'ܚܘܒܐ',
            dt1: 'Hubo',
            ar2: 'ܚܽܘܒܳܐ',
            es3: 'ܚܘܒܐ'
        },
        {
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Saphir, Edelstein”; weitere Formen: Saﬁra, Schaphira, Schaﬁra',
            dt1: 'Saphira',
            es2: 'ܨܦܝܪܐ',
            dt2: 'Safira',
            ar3: 'ܫܰܦܺܝܪܰܐ',
            es3: 'ܫܦܝܪܐ',
            ar2: 'ܨܰܦܺܝܪܰܐ',
            ar1: 'ܨܦܝܪܐ',
            es1: 'ܨܦܝܪܐ',
            dt3: 'Zafira',
            id: '905'
        },
        {
            es3: '',
            dt1: 'Jerachmeel',
            es2: 'ܐܝܪܚܡܠ',
            ar2: 'ܐܺܝܪܰܚܡܶܠ',
            ar1: 'ܐܝܪܚܡܠ',
            dt3: '',
            ar3: '',
            id: '1720',
            bedeutung: 'männl., “Jesus hat gegeben”; weitere Formen: Jeschuyahb, Jeschujab, Jeschuyab',
            dt2: '',
            es1: 'ܐܝܪܚܡܠ'
        },
        {
            id: '2044',
            dt2: '',
            bedeutung: 'weibl., “Scharfsinnige, Vernünftige, Ruhige, Nüchterne, Fleißige, Kluge”',
            es3: '',
            es2: 'ܪܡܙܐ',
            dt3: '',
            dt1: 'Remzo',
            ar3: '',
            ar2: 'ܪܶܡܙܳܐ',
            ar1: 'ܪܡܙܐ',
            es1: 'ܪܡܙܐ'
        },
        {
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Vögelchen”. Im AT ist Zippora Tochter des midianitischen Priesters Reguel oder Jitro und Frau des Mose. Weitere Formen: Sefura, Zefura, Sipura',
            dt3: 'Sipura',
            ar1: 'ܨܝܦܘܪܐ',
            ar2: 'ܨܺܝܦܽܘܪܰܐ',
            dt1: 'Sifura',
            ar3: '',
            dt2: 'Zippora',
            es3: '',
            id: '993',
            es1: 'ܨܝܦܘܪܐ',
            es2: 'ܨܝܦܘܪܐ'
        },
        {
            es3: '',
            ar3: '',
            bedeutung: 'männl., “mein Name ist groß, erhaben”',
            id: '2138',
            dt3: '',
            ar2: 'ܫܰܪܒܶܠܝܰܐ',
            dt2: 'Scharbelia',
            es2: 'ܫܪܒܠܝܐ',
            ar1: 'ܫܪܒܠܝܐ',
            es1: 'ܫܪܒܠܝܐ',
            dt1: 'Scharbelya'
        },
        {
            dt1: 'Bathil',
            es3: '',
            ar1: 'ܒܪ̱ܬܐܝܠ',
            dt3: '',
            id: '206',
            ar2: 'ܒܰܪ̱ܬܐܝܺܠ',
            es2: 'ܒܪܬܐܝܠ',
            bedeutung: 'weibl. Form zu Bar-Il; “Tochter Gottes, Gottestochter”',
            es1: 'ܒܪܬܐܝܠ',
            ar3: '',
            dt2: ''
        },
        {
            ar1: 'ܫܦܝܬ',
            dt3: '',
            es1: 'ܫܦܝܬ',
            dt1: 'Schafyat',
            ar2: 'ܫܰܦܝܰܬ',
            ar3: '',
            es3: '',
            bedeutung: 'männl., “du bist rein, fromm”',
            id: '923',
            dt2: '',
            es2: 'ܫܦܝܬ'
        },
        {
            ar1: 'ܪܒܒܝܬܐ',
            es1: 'ܪܒܒܝܬܐ',
            es3: '',
            ar2: 'ܪܰܒܒܰܝܬܳܐ',
            dt1: 'Rabbaito',
            bedeutung: 'männl., “mein Herr, Lehrer, Meister, Rabbi, Rabi”, weitere Formen: Rabbuni, Rabbuli',
            ar3: '',
            id: '2014',
            es2: 'ܪܒܒܝܬܐ',
            dt3: '',
            dt2: ''
        },
        {
            es2: 'ܐܘܪܣܒܪܐ',
            dt2: '',
            ar2: 'ܐܽܘܪܣܰܒܪܳܐ',
            ar1: 'ܐܘܪܣܒܪܐ',
            ar3: '',
            id: '2289',
            dt1: 'Ursabro',
            es3: '',
            bedeutung: 'weibl., “Blumen”',
            es1: 'ܐܘܪܣܒܪܐ',
            dt3: ''
        },
        {
            es3: 'ܫܐܘܠ',
            es1: 'ܫܐܘܠ',
            dt2: 'Saul',
            dt1: 'Schaol',
            id: '2134',
            es2: 'ܫܐܘܠ',
            ar2: 'ܫܰܐܘܳܠ',
            dt3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe hat seine Wohnung eingerichtet”',
            ar1: 'ܫܐܘܠ',
            ar3: 'ܫܰܐܘܽܠ'
        },
        {
            dt3: '',
            dt1: 'Dennis',
            bedeutung: 'männl., aus dem Griechischen übernommener Vorname; “der dem Gott Dionysos Geweihte”. In der Kirchengeschichte war Dionysius Bischof von Athen. Viele syrische Bischöfe tragen bis heute den Beinamen Dionysius',
            es1: 'ܕܢܝܣ',
            ar1: 'ܕܢܝܣ',
            ar3: '',
            ar2: 'ܕܶܢܺܝܣ',
            id: '1452',
            dt2: 'Denis',
            es3: '',
            es2: 'ܕܢܝܣ'
        },
        {
            dt3: '',
            id: '2176',
            ar2: 'ܨܶܦܬܰܐ',
            es3: '',
            es2: 'ܨܦܬܐ',
            es1: 'ܨܦܬܐ',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Vögelchen”. Im AT ist Zippora Tochter des midianitischen Priesters Reguel oder Jitro und Frau des Mose. Weitere Formen: Sefura, Zefura, Sipura',
            dt1: 'Sefta',
            ar3: '',
            dt2: '',
            ar1: 'ܨܦܬܐ'
        },
        {
            id: '1451',
            ar1: 'ܕܡܝܪ',
            es3: '',
            ar2: 'ܕܶܡܺܝܪ',
            dt2: '',
            ar3: '',
            es1: 'ܕܡܝܪ',
            bedeutung: 'weibl., “Nebel, Trübung, Dunst”; weitere Formen: Dimtha',
            dt3: '',
            dt1: 'Demir',
            es2: 'ܕܡܝܪ'
        },
        {
            ar2: 'ܝܽܘܝܳܟ݂ܺܝܢ',
            ar1: 'ܝܘܝܟ݂ܝܢ',
            es2: 'ܝܘܝܟ݂ܝܢ',
            ar3: '',
            id: '553',
            dt2: '',
            es3: '',
            es1: 'ܝܘܝܟ݂ܝܢ',
            dt3: '',
            dt1: 'Joachin',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe hat eingesetzt oder befestigt”. Im AT ist Jochachin Sohn von Joachim und König von Juda (598 - 597 v. Chr.). Weitere Formen: Jojachin, Jujochin'
        },
        {
            id: '1730',
            ar3: '',
            es3: '',
            dt3: '',
            ar1: 'ܝܫܘܥܙܟ݂ܐ',
            es2: 'ܝܫܘܥܣܟ݂ܐ',
            dt1: 'Jeschuzkho',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe ist Bruder”',
            es1: 'ܝܫܘܥܣܟ݂ܐ',
            dt2: '',
            ar2: 'ܝܶܫܽܘܥܙܟ݂ܳܐ'
        },
        {
            ar3: '',
            dt1: 'Malyuta',
            dt3: '',
            es3: '',
            ar1: 'ܡܠܝܘܬܐ',
            es2: 'ܡܠܝܘܬܐ',
            dt2: '',
            bedeutung: 'weibl., syrischen Ursprungs; “Fülle, Überﬂuss, Vollkommenheit, Vollständigkeit”; weitere Formen: Malyutha',
            es1: 'ܡܠܝܘܬܐ',
            id: '656',
            ar2: 'ܡܰܠܝܽܘܬܰܐ'
        },
        {
            ar2: 'ܚܶܙܰܩܝܳܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebrâischen Ursprungs; “meine Kraft ist Jahwe”. Im AT ist Hiskija Sohn und Nachfolger des Ahas als König von Juda (725/15 - 697/87 v. Chr.). Weitere Formen: Hesakjo, Hesakja, Hesaqjo, Hesaqja',
            dt3: '',
            dt1: 'Hiskija',
            es2: 'ܚܙܩܝܐ',
            es3: 'ܚܙܩܝܐ',
            es1: 'ܚܙܩܝܐ',
            ar1: 'ܚܙܩܝܐ',
            ar3: 'ܚܺܙܩܺܝܰܐ',
            dt2: '',
            id: '481'
        },
        {
            ar3: '',
            es1: 'ܡܠܟܘܬܐ',
            bedeutung: 'weibl., syrischen Ursprungs; “Kultur, Zivilisation, Erziehung”; weitere Formen: Mardutha',
            ar2: 'ܡܰܠܟܽܘܬܰܐ',
            dt1: 'Malkuta',
            dt3: '',
            es2: 'ܡܠܟܘܬܐ',
            dt2: '',
            id: '1843',
            es3: '',
            ar1: 'ܡܠܟܘܬܐ'
        },
        {
            dt1: 'Hazni',
            ar3: '',
            dt3: '',
            id: '458',
            es3: '',
            dt2: '',
            es2: 'ܚܙܢܝ',
            ar1: 'ܚܙܢܝ',
            bedeutung: 'männl. Form zu Hazne',
            es1: 'ܚܙܢܝ',
            ar2: 'ܚܰܙܢܺܝ'
        },
        {
            dt1: 'Nurmata',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “fruchtbar”. In der Bibel ist Ofarta/Efrata ursprünglicher Name für Bethlehem. Weitere Formen: Ephrata, Ofartho, Efrata',
            ar3: '',
            es3: '',
            dt3: '',
            id: '1978',
            ar2: 'ܢܽܘܪܡܰܬܰܐ',
            es2: 'ܢܘܪܡܬܐ',
            es1: 'ܢܘܪܡܬܐ',
            ar1: 'ܢܘܪܡܬܐ',
            dt2: ''
        },
        {
            dt2: '',
            id: '798',
            dt3: '',
            es3: '',
            ar3: '',
            ar1: 'ܐܪܡ',
            es1: 'ܐܪܡ',
            dt1: 'Orom',
            ar2: 'ܐܳܪܳܡ',
            es2: 'ܐܪܡ',
            bedeutung: 'männl., westsyr. Form zu Aram'
        },
        {
            bedeutung: '',
            dt1: 'Lebat',
            dt3: '',
            ar3: 'ܠܶܒܐܰܬ',
            ar2: 'ܠܶܒܰܬ',
            es3: 'ܠܒܐܬ',
            ar1: 'ܠܒܬ',
            es2: 'ܠܒܬ',
            dt2: '',
            id: '1815',
            es1: 'ܠܒܬ'
        },
        {
            ar1: 'ܟܬܝܢܐ',
            dt3: '',
            es2: 'ܟܬܝܢܐ',
            bedeutung: 'männl., “Wassersturm”',
            ar3: '',
            id: '1775',
            ar2: 'ܟܰܬܺܝܢܰܐ',
            es3: '',
            dt2: '',
            es1: 'ܟܬܝܢܐ',
            dt1: 'Katina'
        },
        {
            dt3: '',
            es2: 'ܣܕܪ',
            ar2: 'ܣܰܕܰܪ',
            ar1: 'ܣܕܪ',
            dt2: '',
            ar3: '',
            es3: '',
            bedeutung: 'weibl., ursprünglich männl. von saido “Kalk, Gips, Pﬂaster”; weitere Formen: Saido, Saydo, Sayde ',
            id: '2076',
            dt1: 'Sadar',
            es1: 'ܣܕܪ'
        },
        {
            es3: '',
            dt1: 'Gotor',
            es2: 'ܓܬܪ',
            dt2: '',
            ar1: 'ܓܬܪ',
            bedeutung: 'weibl., “Leben, mein Leben”. Ein Dorf in Turabdin; Weitere Formen: Hahija, Habhija',
            id: '1580',
            es1: 'ܓܬܪ',
            ar3: '',
            dt3: '',
            ar2: 'ܓܳܬܳܪ'
        },
        {
            es2: 'ܒܪܢܡܪܐ',
            es1: 'ܒܪܢܡܪܐ',
            id: '176',
            ar3: '',
            es3: '',
            bedeutung: 'männl., “Sohn der Leoparden, der Tiger”. Barnemre war ein Hauptgott der Aramäer von Haran. Weitere Formen: Barnemro',
            dt2: '',
            ar2: 'ܒܰܪܢܶܡܪܶܐ',
            dt1: 'Barnemre',
            dt3: '',
            ar1: 'ܒܪܢܡܪܐ'
        },
        {
            dt3: '',
            es3: '',
            es2: 'ܐܪܒܘܒܐ',
            dt2: '',
            ar3: '',
            dt1: 'Arbuba',
            es1: 'ܐܪܒܘܒܐ',
            ar1: 'ܐܪܒܘܒܐ',
            bedeutung: 'männl., Arbuba ist ein kranker Mann, der von Mor Schemun d-Zayte (†734) geheilt wurde',
            id: '96',
            ar2: 'ܐܰܪܒܽܘܒܰܐ'
        },
        {
            ar3: '',
            id: '1014',
            es3: '',
            ar1: 'ܣܘܠܩܐ',
            bedeutung: 'männl., “Himmelfahrt, Aufstieg”',
            dt2: '',
            es2: 'ܣܘܠܩܐ',
            ar2: 'ܣܽܘܠܳܩܳܐ',
            dt1: 'Suloqo',
            es1: 'ܣܘܠܩܐ',
            dt3: ''
        },
        {
            es2: 'ܠܚܘܕ',
            id: '616',
            ar3: '',
            dt1: 'Lahhoud',
            dt3: '',
            es1: 'ܠܚܘܕ',
            es3: '',
            ar1: 'ܠܚܘܕ',
            dt2: '',
            bedeutung: '',
            ar2: 'ܠܚܘܕ'
        },
        {
            id: '302',
            ar2: 'ܐܶܠܺܝܥܰܙܰܪ',
            dt3: '',
            dt1: 'Eliozor',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott hat geholfen” Im AT ist Eliozor Sohn Aarons und Priester. Weitere Formen: Eleasar',
            dt2: 'Eleasar',
            es1: 'ܐܠܝܥܙܪ',
            es2: 'ܐܠܝܥܙܪ',
            ar1: 'ܐܠܝܥܙܪ',
            ar3: '',
            es3: ''
        },
        {
            es1: 'ܒܣܠܝܐ',
            ar3: '',
            es2: 'ܒܣܠܝܐ',
            dt2: '',
            dt1: 'Basselya',
            es3: '',
            ar2: 'ܒܰܣܶܠܝܰܐ',
            dt3: '',
            id: '1382',
            bedeutung: 'weibl. Form zu Bardino; “Tochter des Gerichtes, Tochter des Urteils”',
            ar1: 'ܒܣܠܝܐ'
        },
        {
            dt3: '',
            ar1: 'ܡܪܠܢ',
            es1: 'ܡܪܠܢ',
            es3: '',
            dt1: 'Marlen',
            es2: 'ܡܪܠܢ',
            bedeutung: '',
            id: '674',
            dt2: '',
            ar3: '',
            ar2: 'ܡܰܪܠܶܢ'
        },
        {
            es3: 'ܪܚܒܥܡ',
            dt2: '',
            dt1: 'Rehabeam',
            dt3: '',
            id: '849',
            ar2: 'ܪܚܰܒܥܰܡ',
            es2: 'ܪܚܒܥܡ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “der Onkel hat mir Raum verschaffen” oder “das Volk hat sich ausgebreitet”. Im AT ist Rehabeam Sohn des Salomo, nach dem Tod des Vaters König von Juda (926-910 v.Chr.); nach dem NT ist Rehabeam ein Ahnherr Jesu. Weitere Formen: Rhebeam',
            es1: 'ܪܚܒܥܡ',
            ar3: 'ܪܶܚܰܒܥܰܡ',
            ar1: 'ܪܚܒܥܡ'
        },
        {
            ar1: 'ܠܒܢ',
            ar3: '',
            dt3: '',
            id: '1801',
            es3: '',
            es1: 'ܠܒܢ',
            bedeutung: '',
            es2: 'ܠܒܢ',
            ar2: 'ܠܰܒܰܢ',
            dt2: '',
            dt1: 'Laban'
        },
        {
            es3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott heilt, Gott hat geheilt”. Im AT ist Rafael einer der sieben Erzengel. Weitere Formen: Raphael, Raffael, Rafael',
            id: '2017',
            ar3: '',
            es2: 'ܪܒܝܥܐ',
            ar2: 'ܪܰܒܺܝܥܰܐ',
            dt1: 'Rabia',
            ar1: 'ܪܒܝܥܐ',
            es1: 'ܪܒܝܥܐ',
            dt3: '',
            dt2: ''
        },
        {
            ar2: 'ܒܰܪܫܠܳܡܳܐ',
            es3: '',
            bedeutung: 'männl., “Sohn des Sur”. König von Jadi-Samal-Zincirli, Vater von Panammu II. (743-732)',
            dt2: '',
            dt3: '',
            es2: 'ܒܪܫܠܡܐ',
            id: '1368',
            dt1: 'Barschlomo',
            ar3: '',
            es1: 'ܒܪܫܠܡܐ',
            ar1: 'ܒܪܫܠܡܐ'
        },
        {
            dt1: 'Darios',
            ar2: 'ܕܰܪܝܽܘܣ',
            id: '1445',
            bedeutung: '',
            es2: 'ܕܪܝܘܣ',
            dt2: 'Darius',
            es1: 'ܕܪܝܘܣ',
            dt3: '',
            ar1: 'ܕܪܝܘܣ',
            es3: 'ܕܪܝܘܣ',
            ar3: 'ܕܰܪܝܳܘܣ'
        },
        {
            es1: 'ܙܟ݂ܐܝܫܘܥ',
            dt1: 'Zkhojeschu',
            es2: 'ܙܟ݂ܐܝܫܘܥ',
            ar3: '',
            id: '2364',
            dt2: '',
            bedeutung: '',
            es3: '',
            dt3: '',
            ar1: 'ܙܟ݂ܐܝܫܘܥ',
            ar2: 'ܙܟ݂ܳܐܝܶܫܽܘܥ'
        },
        {
            dt2: 'Isla',
            ar1: 'ܐܝܙܠܐ',
            ar2: 'ܐܺܝܙܠܰܐ',
            es3: '',
            es2: 'ܐܝܙܠܐ',
            id: '1704',
            ar3: '',
            dt1: 'Izla',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs. Im NT ist Jairus Synagogenvorsteher in Kafarnaum, Vater der Tochter, die durch Jesus vom Tode erweckt wurde. Weitere Formen: Juorosch',
            dt3: '',
            es1: 'ܐܝܙܠܐ'
        },
        {
            ar3: 'ܣܰܪܟܺܝܣ',
            id: '909',
            es2: 'ܣܪܟܝܣ',
            ar1: 'ܣܪܟܝܣ',
            dt3: 'Sergius',
            ar2: 'ܣܰܪܓܺܝܣ',
            bedeutung: 'männl., aus dem Lateinischen “Sergius: altröm. Sippenname”; in der Kirchengeschichte hat es einen Heiligen unter dem Namen Sarkis/Sergius (3./4. Jh.) gegeben. Weitere Formen: Sergius, Sargis',
            es1: 'ܣܪܟܝܣ',
            es3: 'ܣܪܟܝܣ',
            dt2: 'Sarkis',
            dt1: 'Sargis'
        },
        {
            bedeutung: 'männl., aramäischer Senator von Tadmor/Palmyra (255 - 267 n.Chr.), Ehemann von Zenobia. Weitere Formen: Odenathus, Odi',
            ar1: 'ܐܕܢܬܐ',
            es3: '',
            dt2: '',
            es2: 'ܐܕܢܬܐ',
            es1: 'ܐܕܢܬܐ',
            dt1: 'Odenata',
            ar2: 'ܐܳܕܶܢܰܬܰܐ',
            ar3: '',
            id: '791',
            dt3: ''
        },
        {
            ar2: 'ܐܶܢܟܺܝܕܳܐ',
            es3: 'ܐܢܟܝܕܐ',
            dt3: '',
            bedeutung: 'weibl., “Verband, Bündnis, Vereinigung, Bund”',
            id: '1499',
            es1: 'ܐܢܟܝܕܐ',
            dt2: '',
            ar1: 'ܐܢܟܝܕܐ',
            ar3: 'ܐܰܢܟܺܝܕܰܐ',
            es2: 'ܐܢܟܝܕܐ',
            dt1: 'Enkido'
        },
        {
            dt2: 'Urhai',
            dt3: '',
            es3: '',
            bedeutung: 'weibl./männl., “Stadt des Lebens”. Urhoy ist eine Stadt in Aram Beth-Nahrin (= Mesopotamien). Urhoy oder Urhay heißt auf Griechisch Edessa, heute Urfa. Nach einer anderen Theorie soll Urhay oder Arhoy eine männliche Person gewesen sein, nach der die Stadt Urhay/Arhoy genannt wird, insofern kann es auch männlich verwendet werden. Weitere Formen: Urhoy, Urhai',
            ar3: '',
            es2: 'ܐܘܪܗܝ',
            dt1: 'Urhay',
            es1: 'ܐܘܪܗܝ',
            ar1: 'ܐܘܪܗܝ',
            ar2: 'ܐܽܘܪܗܰܝ',
            id: '1088'
        },
        {
            id: '1191',
            dt1: 'Abgar',
            es1: 'ܐܒܓܪ',
            es2: 'ܐܒܓܪ',
            ar2: 'ܐܰܒܓܰܪ',
            dt3: '',
            ar3: '',
            bedeutung: 'männl., wohl armenisch-syrischen Ursprungs; “derLahme, Invalide, Körperbehinderte” oder “Festhalter, Anhalter, Aufhalter, Verhüter”. Abgar mit dem Beinamen Ukkama/ Ukkomo “der Schwarze”, der Aramäer, war König von Edessa/ Urhoi in Aram Beth-Nahrin (4v.-50 n. Chr.). In der Kirchengeschichte geht die Christianisienıng der Stadt Edessa und ihrer Umgebung auf seine Initiative und Einladung zurück; Festtag: 2. April',
            dt2: '',
            es3: '',
            ar1: 'ܐܒܓܪ'
        },
        {
            es2: 'ܡܫܝܚܪܚܡܐ',
            ar3: 'ܡܫܺܝܚܳܐ ܪܰܚܡܶܐ',
            dt3: '',
            ar1: 'ܡܫܝܚܪܚܡܐ',
            es1: 'ܡܫܝܚܪܚܡܐ',
            dt2: '',
            bedeutung: 'männl., aramäisch-chaldäischer König (555-539 v. Chr.); weitere Formen: Nabunaid',
            id: '1911',
            ar2: 'ܡܫܺܝܚܳܪܰܚܡܶܐ',
            dt1: 'Mschihorahme',
            es3: 'ܡܫܝܚܐ ܪܚܡܐ'
        },
        {
            dt2: '',
            es3: 'ܟܝܠܡܘܐ',
            ar3: 'ܟܺܝܠܰܡܽܘܰܐ',
            ar1: 'ܟܝܠܡܘܐ',
            id: '594',
            dt3: '',
            dt1: 'Kilamwa',
            es1: 'ܟܝܠܡܘܐ',
            bedeutung: 'männl., König von Samal-Jadi (um 780 v. Chr.); Weitere Formen: Kilamua, Kalamu',
            ar2: 'ܟܺܝܠܰܡܘܰܐ',
            es2: 'ܟܝܠܡܘܐ'
        },
        {
            id: '1842',
            dt3: '',
            ar2: 'ܡܰܠܟܬܰܐ',
            ar3: '',
            bedeutung: '',
            es1: 'ܡܠܟܬܐ',
            dt1: 'Malktha',
            es2: 'ܡܠܟܬܐ',
            es3: '',
            dt2: '',
            ar1: 'ܡܠܟܬܐ'
        },
        {
            ar1: 'ܡܪܘܬܐ',
            dt3: '',
            ar3: '',
            dt1: 'Marutha',
            id: '1868',
            dt2: '',
            es2: 'ܡܪܘܬܐ',
            es1: 'ܡܪܘܬܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Geschenk, Gabe Jahwes”. Im NT ist Matai einer der 12 Jünger Jesu Christi. Matai ist der Verfasser des ersten Evangeliums. Weitere Formen: Mattai, Matthäus, Mattäus, Matay',
            es3: '',
            ar2: 'ܡܰܪܘܬܰܐ'
        },
        {
            es2: 'ܟܣܕ',
            es1: 'ܟܣܕ',
            bedeutung: 'männl., Kosod (Kosor) oder Kesed ist im AT Sohn Nahors, des Bruders Abrahams. Nach einigen Wissenschaftlern ist Kesed der Stammvater der Chaldäer und somit leitet sich ein aramäischer Stamm von ihm her. Weitere Formen: Kosor, Kesed',
            dt3: '',
            ar3: 'ܟܳܣܳܪ',
            id: '605',
            es3: 'ܟܣܪ',
            dt1: 'Kosod',
            ar2: 'ܟܶܣܶܕ',
            dt2: '',
            ar1: 'ܟܣܕ'
        },
        {
            dt1: 'Yadido',
            es3: '',
            ar3: '',
            ar2: 'ܝܰܕܺܝܕܳܐ',
            id: '1113',
            dt3: '',
            es1: 'ܝܕܝܕܐ',
            dt2: '',
            ar1: 'ܝܕܝܕܐ',
            es2: 'ܝܕܝܕܐ',
            bedeutung: 'männl., “Liebling, Schatz”; weitere Formen: Yadid, Jadid, Jadido'
        },
        {
            dt3: '',
            ar1: 'ܥܒܘܫܬܐ',
            es3: '',
            dt2: '',
            dt1: 'Abbuschto',
            es1: 'ܥܒܘܫܬܐ',
            es2: 'ܥܒܘܫܬܐ',
            ar2: 'ܥܰܒܽܘܫܬܳܐ',
            id: '6',
            bedeutung: '',
            ar3: ''
        },
        {
            ar3: '',
            ar1: 'ܫܡܐ',
            es1: 'ܫܡܐ',
            dt1: 'Schamo',
            id: '2132',
            es3: '',
            ar2: 'ܫܰܡܳܐ',
            dt2: '',
            es2: 'ܫܡܐ',
            dt3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “ich habe [ihn] erbeten von Gott” Im AT ist er Sohn des gefangenen Königs Jojachin und Onkel des Serubbabel; nach dem NT ist er Sohn des Jojachin oder Sohn des Neri, Vater des Serubbabel und ein Ahnherr Jesu. Weitere Formen: Schelathiel'
        },
        {
            ar1: 'ܐܪܡ',
            dt1: 'Aram',
            es1: 'ܐܪܡ',
            id: '93',
            ar3: 'ܐܳܪܳܡ',
            dt3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “hoch, der Erhabene?”. Im AT ist Aram der jüngste Sohn von Sem. Die Aramäer leiten sich nach dem AT von Aram ab. Die Söhne Arams sind Uz, Hul, Geter und Masch. Nach dem NT ist Aram ein Ahnherr Jesu. Die Aramäer haben vor Christus viele Stadtstaaten in Aram Beth-Nahrin (=Mesopotamien) und Syrien gegründet; sie heißen heute auch Syrer oder Suryoye',
            dt2: 'Orom',
            es3: 'ܐܪܡ',
            es2: 'ܐܪܡ',
            ar2: 'ܐܰܪܰܡ'
        },
        {
            ar3: 'ܛܺܝܛܳܘܣ',
            es3: 'ܛܝܛܘܣ',
            ar2: 'ܛܺܝܛܽܘܣ',
            ar1: 'ܛܝܛܘܣ',
            bedeutung: 'männl., König von Hamat (11. Jh. v.Chr.); Vater von Hadoram (Adoniram). Weitere Formen: Tou, Tohi, Tohu',
            es2: 'ܛܝܛܘܣ',
            dt3: '',
            dt1: 'Titus',
            dt2: '',
            es1: 'ܛܝܛܘܣ',
            id: '2254'
        },
        {
            dt3: '',
            ar3: 'ܐܶܠܺܝܥܳܙܳܪ',
            bedeutung: '',
            ar1: 'ܠܥܙܪ',
            dt1: 'Laazar',
            id: '1800',
            es2: 'ܠܥܙܪ',
            ar2: 'ܠܰܥܰܙܰܖ',
            es1: 'ܠܥܙܪ',
            dt2: '',
            es3: 'ܐܠܝܥܙܪ'
        },
        {
            dt1: 'Jiftach',
            es3: 'ܢܦܬܚ',
            id: '1735',
            es2: 'ܝܦܬܚ',
            ar3: 'ܢܰܦܬܰܚ',
            ar1: 'ܝܦܬܚ',
            es1: 'ܝܦܬܚ',
            dt2: 'Naftah',
            bedeutung: 'männl., siehe Josua',
            dt3: 'Jeftah',
            ar2: 'ܝܰܦܬܰܚ'
        },
        {
            es2: 'ܐܘܪܢܗܪܐ',
            ar2: 'ܐܽܘܪܢܰܗܪܰܐ',
            dt1: 'Urnahra',
            id: '2288',
            dt3: '',
            es3: '',
            ar1: 'ܐܘܪܢܗܪܐ',
            dt2: '',
            ar3: '',
            es1: 'ܐܘܪܢܗܪܐ',
            bedeutung: 'männl., Wapa war ein aramäischer Philosoph und Schriftteller der vorchristlichen Zeit.'
        },
        {
            es3: 'ܦܪܘܩܐ',
            ar1: 'ܦܪܘܩ',
            ar3: 'ܦܳܪܽܘܩܳܐ',
            dt1: 'Foruq',
            ar2: 'ܦܳܪܘܽܩ',
            dt3: '',
            bedeutung: 'männl., “Erlöser, Retter, Heiland”; weitere Formen: Faruk, Faruq, Foruqo',
            id: '345',
            es2: 'ܦܪܘܩ',
            dt2: 'Farruq',
            es1: 'ܦܪܘܩ'
        },
        {
            es2: 'ܓܒܪܐܝܠ',
            es1: 'ܓܒܪܐܝܠ',
            dt3: '',
            dt2: '',
            ar2: 'ܓܰܒܪܺܐܝܶܠ',
            es3: '',
            bedeutung: 'männl., siehe Gabriel',
            id: '1542',
            ar1: 'ܓܒܪܐܝܠ',
            dt1: 'Gabriel',
            ar3: ''
        },
        {
            es1: 'ܚܝܕܐܪܝ',
            dt3: '',
            dt2: '',
            ar1: 'ܚܝܕܐܪܝ',
            es2: 'ܚܝܕܐܪܝ',
            ar3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “der Herr ist Hilfe und Rettung, Jahwe rettet”. Im AT ist Hoschea ein Prophet (8. Jh. v. Chr.). Weitere Formen: Huscho, Hosea',
            dt1: 'Hidari',
            es3: '',
            ar2: 'ܚܺܝܕܰܐܪܺܝ',
            id: '1664'
        },
        {
            es1: 'ܓܒܝܬܐ',
            bedeutung: 'weibl., “Auserwählte, Erwählte”; weitere Formen: Gbitha',
            es2: 'ܓܒܝܬܐ',
            ar2: 'ܓܒܺܝܬܰܐ',
            dt1: 'Gbita',
            es3: '',
            id: '370',
            ar1: 'ܓܒܝܬܐ',
            dt3: '',
            dt2: '',
            ar3: ''
        },
        {
            ar1: 'ܥܡܢܘܐܝܠܗ',
            es3: 'ܥܡܢܘܐܝܠܐ',
            es2: 'ܥܡܢܘܐܝܠܗ',
            dt3: '',
            id: '1847',
            ar2: 'ܥܰܡܰܢܽܘܐܶܝܠܰܗ',
            bedeutung: '',
            dt1: 'Manuela',
            ar3: 'ܥܰܡܰܢܽܘܐܶܝܠܰܐ',
            dt2: '',
            es1: 'ܥܡܢܘܐܝܠܗ'
        },
        {
            id: '1833',
            ar1: 'ܡܓܕܠܝܬܐ',
            dt3: '',
            dt1: 'Magdalena',
            ar2: 'ܡܓܕܠܝܬܐ',
            ar3: '',
            es2: 'ܡܓܕܠܢܐ',
            es3: '',
            dt2: '',
            bedeutung: 'weibl., “Königin”; weitere Formen: Malktho, Malkta',
            es1: 'ܡܓܕܠܢܐ'
        },
        {
            ar1: 'ܛܠܐ',
            dt3: '',
            ar3: '',
            dt1: 'Talo',
            es3: '',
            es1: 'ܛܠܐ',
            bedeutung: 'männl., “Frost, Tau”',
            dt2: '',
            ar2: 'ܛܰܠܳܐ',
            id: '1032',
            es2: 'ܛܠܐ'
        },
        {
            id: '169',
            ar2: 'ܒܰܪܝܰܘܢܳܐ',
            ar3: '',
            es3: '',
            es2: 'ܒܪܝܘܢܐ',
            ar1: 'ܒܪܝܘܢܐ',
            es1: 'ܒܪܝܘܢܐ',
            dt3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäischen Ursprungs; “Sohn des Jonas“, “Sohn der Taube”. Im NT ist Barjawno Beiname des Simon Petrus',
            dt1: 'Barjawno',
            dt2: 'Barjona'
        },
        {
            dt3: '',
            ar1: 'ܐܠܝܗܒ',
            dt1: 'Eliab',
            es3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “mein Gott ist Vater”',
            es1: 'ܐܠܝܗܒ',
            id: '297',
            ar3: '',
            ar2: 'ܐܶܠܺܝܗܰܒ',
            dt2: 'Elihab',
            es2: 'ܐܠܝܗܒ'
        },
        {
            es3: '',
            dt3: '',
            dt2: '',
            ar2: 'ܝܰܡܺܝܢ',
            es1: 'ܝܡܝܢ',
            es2: 'ܝܡܝܢ',
            ar3: '',
            dt1: 'Jamin',
            ar1: 'ܝܡܝܢ',
            id: '1714',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “das Volk möge zunehmen”. Im AT ist Jerobeam Sohn des Nebat aus dem Stamm Ephraim/Efraim als Jerobeam I. König (926 - 907 v.Chr.) des unabhängigen Nordreiches Israel. Weitere Formen: Jurabam'
        },
        {
            id: '2266',
            es3: '',
            es2: 'ܬܪܝܨܬܐ',
            es1: 'ܬܪܝܨܬܐ',
            dt3: '',
            ar2: 'ܬܪܺܝܨܬܰܐ',
            ar1: 'ܬܪܝܨܬܐ',
            dt2: '',
            ar3: '',
            bedeutung: 'männl., “Einer aus den Bergen bzw. aus der Region des Gebirges Turabdin: Turabdiner”. “Turo” ist die Kurzbezeichnung für das mesopotamische Gebiet Turabdin',
            dt1: 'Trista'
        },
        {
            ar3: '',
            dt2: '',
            id: '1070',
            ar2: 'ܬܳܓ݂ܳܐ',
            es2: 'ܬܓ݂ܐ',
            es1: 'ܬܓ݂ܐ',
            es3: '',
            ar1: 'ܬܓ݂ܐ',
            dt3: '',
            bedeutung: 'männl., “Krone”',
            dt1: 'Togo'
        },
        {
            ar1: 'ܪܝܚܐ',
            dt1: 'Recha',
            ar2: 'ܪܺܝܚܰܐ',
            es3: '',
            es1: 'ܪܝܚܐ',
            ar3: '',
            id: '2039',
            es2: 'ܪܝܚܐ',
            dt3: '',
            dt2: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs. Im AT ist sie Nebenfrau Nahors, des Bruders Abrahams und Mutter von vier Söhnen, u.a. des Maacha, Urvaters eines aramäisches Volkes (siehe: Maacha). Weitere Formen: Roma, Ruma'
        },
        {
            es1: 'ܐܒܪܗܡ',
            id: '1206',
            es3: '',
            es2: 'ܐܒܪܗܡ',
            dt2: 'Abraham',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “der Vater liebt, Vater der Völker, Vater der Gläubigen”. Im AT gilt Abrohom als Stammvater der Israeliten, der ursprünglich Abram hieß (zw. 2000 und 1400 v.Chr.); Abrohom war ein Aramäer aus Aram-Naharaim (= Mesopotamien). Weitere Formen: Abraham, Ibrahim, Brahim',
            ar3: '',
            ar1: 'ܐܒܪܗܡ',
            dt1: 'Abrohom',
            dt3: 'Ibrahim',
            ar2: 'ܐܰܒܪܳܗܳܡ'
        },
        {
            dt2: 'Habibo',
            ar2: 'ܚܰܒܺܝܒ',
            dt1: 'Habib',
            ar3: 'ܚܰܒܺܝܒܳܐ',
            es1: 'ܚܒܝܒ',
            id: '1589',
            ar1: 'ܚܒܝܒ',
            es2: 'ܚܒܝܒ',
            dt3: '',
            es3: 'ܚܒܝܒܐ',
            bedeutung: 'weibl., von habuscha: „Apfel“'
        },
        {
            dt3: '',
            ar2: 'ܡܺܝܪܙܰܐ',
            ar3: '',
            es3: '',
            dt2: 'Mirsa',
            id: '705',
            es1: 'ܡܝܪܙܐ',
            ar1: 'ܡܝܪܙܐ',
            dt1: 'Mirza',
            bedeutung: '',
            es2: 'ܡܝܪܙܐ'
        },
        {
            dt2: '',
            es3: 'ܡܪܢ ܙܟ݂ܐ',
            es2: 'ܡܪܢܙܟ݂ܐ',
            ar2: 'ܡܳܪܰܢܙܟ݂ܳܐ',
            dt3: '',
            es1: 'ܡܪܢܙܟ݂ܐ',
            dt1: 'Moranzkho',
            id: '717',
            ar3: 'ܡܳܪܰܢ ܙܟ݂ܳܐ',
            bedeutung: 'männl., “der Herr hat gesiegt”. Moranzkho (346-375) war ein ostsyrischer Bischof von Arbela. Weitere Formen: Maranzkha',
            ar1: 'ܡܪܢܙܟ݂ܐ'
        },
        {
            es3: '',
            dt2: '',
            bedeutung: 'männl., “Bruder”. In der syrisch-orthodoxen Kirche von Antiochien ist Aho ein Heiliger (420-525); Festtag: 5. Mai.',
            id: '1238',
            ar1: 'ܐܚܐ',
            es2: 'ܐܚܐ',
            dt3: '',
            dt1: 'Aho',
            es1: 'ܐܚܐ',
            ar3: '',
            ar2: 'ܐܰܚܳܐ'
        },
        {
            es3: '',
            id: '3',
            dt2: 'Abay',
            dt3: '',
            ar1: 'ܐܒܝ',
            es2: 'ܐܒܝ',
            dt1: 'Abai',
            bedeutung: 'männl., Abai († 1. Jh.) war Schüler des Mor Addai. Sein Festtag: 1. Oktober',
            ar3: '',
            es1: 'ܐܒܝ',
            ar2: 'ܐܰܒܰܝ'
        },
        {
            id: '2012',
            dt1: 'Quschta',
            es2: 'ܩܘܫܬܐ',
            bedeutung: 'männl., “mein Name ist groß, erhaben” ',
            es1: 'ܩܘܫܬܐ',
            dt2: '',
            dt3: '',
            ar2: 'ܩܽܘܫܬܰܐ',
            ar3: '',
            ar1: 'ܩܘܫܬܐ',
            es3: ''
        },
        {
            dt1: 'Suri',
            ar1: 'ܣܘܪܝ',
            ar3: '',
            dt2: '',
            es1: 'ܣܘܪܝ',
            es2: 'ܣܘܪܝ',
            id: '1015',
            dt3: '',
            ar2: 'ܣܽܘܪܺܝ',
            bedeutung: 'weibl., Suri ist die keilinschriftliche Bezeichnung für Syrien (griech. Suroi)',
            es3: ''
        },
        {
            ar2: 'ܐܽܘܫܰܥܢܳܐ',
            dt2: '',
            dt3: '',
            ar1: 'ܐܘܫܥܢܐ',
            es1: 'ܐܘܫܥܢܐ',
            dt1: 'Uschano',
            es2: 'ܐܘܫܥܢܐ',
            es3: '',
            ar3: '',
            bedeutung: 'männl., “loben, lobsingen”; weitere Formen: Jabeb',
            id: '2292'
        },
        {
            ar1: 'ܝܫܘܥܙܟ݂ܐ',
            dt2: '',
            ar3: '',
            dt1: 'Jeschuzkho',
            bedeutung: 'männl., “Jesus hat gesiegt”',
            ar2: 'ܝܶܫܽܘܥܙܟ݂ܳܐ',
            id: '543',
            es3: '',
            es2: 'ܝܫܘܥܣܟ݂ܐ',
            es1: 'ܝܫܘܥܣܟ݂ܐ',
            dt3: ''
        },
        {
            dt3: '',
            es3: '',
            dt1: 'Amisadiko',
            es2: 'ܥܡܝܙܕܝܩܐ',
            ar2: 'ܥܰܡܺܝܙܰܕܝܺܩܳܐ',
            ar1: 'ܥܡܝܙܕܝܩܐ',
            es1: 'ܥܡܝܙܕܝܩܐ',
            id: '1257',
            ar3: '',
            dt2: 'Amisadok ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “mein Onkel ist freigebig”. Im AT ist Amminadab ein Judäer, Nachkomme des Perez und Ahnherr Davids. Im NT ist Amminadab Ahnherr Davids und Jesu'
        },
        {
            dt2: '',
            id: '1145',
            dt1: 'Zabdai',
            dt3: '',
            es2: 'ܙܒܕܝ',
            ar2: 'ܙܰܒܕܰܝ',
            ar3: '',
            ar1: 'ܙܒܕܝ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Geschenk Jahwes”. Im NT ist Zabdai Vater des Jakobus und des Johannes, der Jünger Jesu. Weitere Formen: Zebedäus',
            es1: 'ܙܒܕܝ',
            es3: ''
        },
        {
            es3: '',
            ar2: 'ܥܰܙܺܝܙ',
            es2: 'ܥܙܝܙ',
            ar3: '',
            id: '1318',
            dt2: 'Asis',
            bedeutung: 'weibl., “veıwirrt, durcheinander gebracht” oder “Tor, Pforte Gottes”; weitere Formen: Bobel',
            es1: 'ܥܙܝܙ',
            dt1: 'Aziz',
            dt3: '',
            ar1: 'ܥܙܝܙ'
        },
        {
            es3: '',
            es2: 'ܛܠܝܬܐ',
            id: '1031',
            dt2: '',
            ar3: '',
            dt3: '',
            ar2: 'ܛܰܠܺܝܬܰܐ',
            ar1: 'ܛܠܝܬܐ',
            bedeutung: 'weibl., “Mädchen”',
            es1: 'ܛܠܝܬܐ',
            dt1: 'Talita'
        },
        {
            dt2: '',
            ar3: '',
            es3: '',
            id: '734',
            dt1: 'Naftali',
            ar1: 'ܢܦܬܠܝ',
            dt3: '',
            es2: 'ܢܦܬܠܝ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “ich habe gekämpt”, Im AT ist Naﬂali Sohn Jakobs und der Bilha, der Magd Rahels. Weitere Formen: Naﬁoli, Naphtali',
            es1: 'ܢܦܬܠܝ',
            ar2: 'ܢܰܦܬܰܠܺܝ'
        },
        {
            es2: 'ܫܪܝܥܐ',
            bedeutung: 'männl., Sonne',
            ar2: 'ܫܰܪܺܝܥܰܐ',
            es3: 'ܫܪܝܥܐ',
            dt3: '',
            ar3: 'ܫܪܺܝܥܰܐ',
            es1: 'ܫܪܝܥܐ',
            id: '2139',
            dt1: 'Scharia',
            dt2: '',
            ar1: 'ܫܪܝܥܐ'
        },
        {
            bedeutung: 'männl., siehe Schmuel',
            es1: 'ܨܠܝܒܐ',
            ar2: 'ܨܰܠܺܝܒܰܐ',
            dt2: '',
            ar3: '',
            id: '2086',
            dt1: 'Saliba',
            es3: '',
            dt3: '',
            ar1: 'ܨܠܝܒܐ',
            es2: 'ܨܠܝܒܐ'
        },
        {
            es3: '',
            ar1: 'ܐܬܪܐ',
            ar2: 'ܐܰܬܪܰܐ',
            ar3: '',
            es1: 'ܐܬܪܐ',
            dt2: '',
            es2: 'ܐܬܪܐ',
            dt3: '',
            dt1: 'Atra',
            id: '1304',
            bedeutung: 'männl. Form zu Athe'
        },
        {
            es2: 'ܫܡܥܝܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe hat gehört”',
            dt2: '',
            ar3: '',
            es1: 'ܫܡܥܝܐ',
            dt3: '',
            id: '953',
            es3: '',
            ar2: 'ܫܡܰܥܝܳܐ',
            dt1: 'Schemaja',
            ar1: 'ܫܡܥܝܐ'
        },
        {
            id: '711',
            ar2: 'ܡܪܝ ܐܝܘܢܢܝܣ ܐܦܪܝܡ ܒܝܠܓܝܓ',
            dt3: '',
            es1: 'ܡܪܝ ܐܝܘܢܢܝܣ ܐܦܪܝܡ ܒܝܠܓܝܓ',
            ar3: '',
            dt2: '',
            ar1: 'ܡܪܝ ܐܝܘܢܢܝܣ ܐܦܪܝܡ ܒܝܠܓܝܓ',
            dt1: 'Mor Iyawanis Afrem Bilgic',
            bedeutung: '',
            es3: '',
            es2: 'ܡܪܝ ܐܝܘܢܢܝܣ ܐܦܪܝܡ ܒܝܠܓܝܓ'
        },
        {
            ar2: 'ܐܳܟܺܝܬ',
            dt2: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Trennung”. Im AT ist Peleg ein Nachkomme Sems, Sohn Ebers und Ahnherr Abrahams; im NT ist Peleg ein Ahnherr Jesu. Weitere Formen: Polog',
            ar1: 'ܐܟܝܬ',
            id: '1988',
            dt3: '',
            dt1: 'Okit',
            ar3: '',
            es1: 'ܐܟܝܬ',
            es2: 'ܐܟܝܬ',
            es3: ''
        },
        {
            es1: 'ܝܒܢܐ',
            dt2: '',
            ar3: '',
            es3: '',
            id: '2304',
            ar2: 'ܝܰܒܢܳܐ',
            es2: 'ܝܒܢܐ',
            ar1: 'ܝܒܢܐ',
            bedeutung: '',
            dt3: '',
            dt1: 'Yabno'
        },
        {
            ar2: 'ܟܰܪܺܝܢ',
            es1: 'ܟܪܝܢ',
            dt3: '',
            bedeutung: '',
            dt1: 'Karin',
            es2: 'ܟܪܝܢ',
            ar1: 'ܟܪܝܢ',
            es3: '',
            dt2: '',
            ar3: '',
            id: '580'
        },
        {
            es2: 'ܦܪܝܘܬܐ',
            dt1: 'Faryuta',
            ar1: 'ܦܪܝܘܬܐ',
            dt2: '',
            ar3: '',
            es3: '',
            id: '336',
            es1: 'ܦܪܝܘܬܐ',
            bedeutung: 'weibl., “Fruchtbarkeit”; weitere Formen: Faryutha',
            dt3: '',
            ar2: 'ܦܰܪܝܽܘܬܰܐ'
        },
        {
            ar1: 'ܓܕܝ',
            es2: 'ܓܕܝ',
            bedeutung: 'weibl., “Herrlichkeit, Reinheit, Pracht, Schönheit”; weitere Formen: Gayutha',
            ar2: 'ܓܰܕܺܝ',
            dt1: 'Gaddi',
            es1: 'ܓܕܝ',
            es3: 'ܓܐܕܝ',
            id: '1547',
            ar3: 'ܓܰܐܕܺܝ',
            dt2: '',
            dt3: ''
        },
        {
            ar1: 'ܝܘܢܐ',
            ar3: 'ܝܰܘܢܳܢ',
            es3: 'ܝܘܢܢ',
            es1: 'ܝܘܢܐ',
            dt1: 'Yawno',
            es2: 'ܝܘܢܐ',
            ar2: 'ܝܰܘܢܳܐ',
            dt2: '',
            id: '2325',
            bedeutung: 'männl., siehe Yaqunto',
            dt3: ''
        },
        {
            bedeutung: 'männl., “du bist rein, fromm”',
            es2: 'ܣܪܘܢܐ',
            es1: 'ܣܪܘܢܐ',
            ar2: 'ܣܰܪܽܘܢܰܐ',
            dt1: 'Saruna',
            ar3: 'ܨܰܪܽܘܢܰܐ',
            dt2: '',
            dt3: '',
            ar1: 'ܣܪܘܢܐ',
            es3: 'ܨܪܘܢܐ',
            id: '2106'
        },
        {
            es2: 'ܟܪܝܣܛܦܪܘܣ',
            dt2: 'Christoforus',
            dt1: 'Christophorus',
            es3: 'ܟܪܣܬܦܪܘܣ',
            es1: 'ܟܪܝܣܛܦܪܘܣ',
            ar3: 'ܟܪܺܝܣܬܳܦܳܪܽܘܣ',
            ar2: 'ܟܪܺܝܣܛܳܦܳܪܽܘܣ',
            dt3: '',
            ar1: 'ܟܪܝܣܛܦܪܘܣ',
            bedeutung: 'männl., heißt Christusträger und  ist ein frühchristlicher Märtyrer vermutlich des 3. oder beginnenden 4. Jahrhunderts. Er wird in der katholischen und der orthodoxen Kirche als Heiliger verehrt',
            id: '246'
        },
        {
            es1: 'ܩܘܪܝܩܘܣ',
            es2: 'ܩܘܪܝܩܘܣ',
            ar2: 'ܩܽܘܪܝܰܩܳܘܣ',
            es3: '',
            id: '1799',
            bedeutung: 'männl., syrischen Ursprungs; “Lampe, Laterne, Leuchter”',
            dt1: 'Kyriakos',
            ar3: '',
            dt3: '',
            dt2: '',
            ar1: 'ܩܘܪܝܩܘܣ'
        },
        {
            dt1: 'Aktan',
            dt3: '',
            es2: 'ܐܟܬܢ',
            es1: 'ܐܟܬܢ',
            ar2: 'ܐܰܟܬܰܢ',
            ar3: '',
            ar1: 'ܐܟܬܢ',
            dt2: '',
            id: '68',
            es3: '',
            bedeutung: ''
        },
        {
            es2: 'ܩܝܢܬܐ',
            ar2: 'ܩܺܝܢܬܰܐ',
            dt3: '',
            dt1: 'Qinta',
            es3: '',
            es1: 'ܩܝܢܬܐ',
            ar1: 'ܩܝܢܬܐ',
            dt2: '',
            id: '815',
            bedeutung: 'weibl., “Melodie, Gesang, Lied, Hymne, Ton, Note”',
            ar3: ''
        },
        {
            ar3: 'ܫܡܳܥܺܝܠ',
            es3: 'ܫܡܥܝܠ',
            es1: 'ܐܝܫܡܥܝܠ',
            ar1: 'ܐܝܫܡܥܝܠ',
            ar2: 'ܐܺܝܫܡܳܥܺܝܠ',
            es2: 'ܐܝܫܡܥܝܠ',
            dt3: '',
            dt1: 'Ischmoel',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott hört”. Im AT ist Ischmoel der Sohn von Abraham und Hagar. Weitere Formen: Ismael, Schmoil',
            id: '508',
            dt2: ''
        },
        {
            id: '1488',
            ar3: 'ܐܶܠܺܝܫܰܥ',
            dt1: 'Elischah',
            es3: 'ܐܠܝܫܥ',
            dt2: 'Elisa',
            dt3: 'Elischa',
            ar1: 'ܐܠܝܫܥ',
            es2: 'ܐܠܝܫܥ',
            es1: 'ܐܠܝܫܥ',
            ar2: 'ܐܶܠܺܝܫܳܥ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott, der nach mir schaut”'
        },
        {
            ar2: 'ܫܰܦܺܝܪܬܰܐ',
            dt1: 'Schafirta',
            ar3: '',
            id: '922',
            dt3: '',
            bedeutung: 'weibl., “schön, schöne Frau, schönes Mädchen”',
            es3: '',
            dt2: '',
            ar1: 'ܫܦܝܪܬܐ',
            es2: 'ܫܦܝܪܬܐ',
            es1: 'ܫܦܝܪܬܐ'
        },
        {
            ar2: 'ܒܰܪܨܰܘܡܳܐ',
            id: '1365',
            dt3: '',
            es3: '',
            ar1: 'ܒܪܨܘܡܐ',
            bedeutung: 'männl., “Sohn des Felses”',
            es1: 'ܒܪܨܘܡܐ',
            es2: 'ܒܪܨܘܡܐ',
            dt2: 'Barsawmo',
            dt1: 'Barsaumo',
            ar3: ''
        },
        {
            ar2: 'ܣܳܗܕܳܐ',
            bedeutung: 'weibl., “Lied, Lobgesang”',
            ar1: 'ܣܗܕܐ',
            es2: 'ܣܗܕܐ',
            ar3: '',
            es1: 'ܣܗܕܐ',
            es3: '',
            dt3: '',
            id: '2195',
            dt1: 'Sohdo',
            dt2: ''
        },
        {
            es2: 'ܝܕܥܐܝܠ',
            dt3: '',
            dt1: 'Jadiael',
            ar2: 'ܝܰܕܥܳܐܝܶܠ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott weıß”',
            ar1: 'ܝܕܥܐܝܠ',
            id: '522',
            ar3: 'ܝܰܕܥܺܝܶܠ',
            es1: 'ܝܕܥܐܝܠ',
            dt2: '',
            es3: 'ܝܕܥܝܠ'
        },
        {
            dt1: 'Dalila',
            es3: 'ܕܠܝܠܐ',
            ar2: 'ܕܰܠܺܝܠܰܐ',
            ar3: 'ܕܶܠܺܝܠܰܐ',
            es1: 'ܕܠܝܠܐ',
            bedeutung: 'männl., “ähnlich sein, vergleichen, symbolisieren”',
            id: '1433',
            ar1: 'ܕܠܝܠܐ',
            dt2: 'Delila',
            dt3: '',
            es2: 'ܕܠܝܠܐ'
        },
        {
            es3: '',
            dt1: 'Goluta',
            dt2: '',
            dt3: '',
            es1: 'ܓܠܘܬܐ',
            id: '1578',
            ar2: 'ܓܳܠܽܘܬܰܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Minze, Basilikum”. Im AT ist Habakuk ein Prophet (um 600 v. Chr.) und Verfasser eines alttestamentlichen Buches. Weitere Formen: Habkuk, Habquq',
            ar1: 'ܓܠܘܬܐ',
            ar3: '',
            es2: 'ܓܠܘܬܐ'
        },
        {
            ar2: 'ܟܰܬܰܖ',
            es2: 'ܟܬܪ',
            es1: 'ܟܬܪ',
            ar3: '',
            es3: '',
            dt1: 'Katar',
            ar1: 'ܟܬܖ',
            id: '586',
            dt2: '',
            dt3: '',
            bedeutung: 'männl., syrischen Ursprungs; “bleiben, stabil, fest, dauerhaft”'
        },
        {
            es1: 'ܣܪܐ',
            id: '912',
            es3: '',
            dt3: '',
            ar2: 'ܣܰܪܳܐ',
            dt2: '',
            dt1: 'Saro',
            bedeutung: 'weibl., Nebenform zu Sara',
            ar1: 'ܣܪܐ',
            ar3: '',
            es2: 'ܣܪܐ'
        },
        {
            es1: 'ܪܡܝ',
            dt3: '',
            ar3: '',
            ar1: 'ܪܡܝ',
            id: '2033',
            es3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “[Markt]platz”. Im AT ist Rehob König von Aram-Soba (Libanon), Vater von Hadad-Eser (11. Jh. v. Chr.). Weitere Formen: Rohob, Rohub',
            dt1: 'Rami',
            es2: 'ܪܡܝ',
            ar2: 'ܪܰܡܺܝ',
            dt2: ''
        },
        {
            ar2: 'ܥܽܘܨ',
            dt3: '',
            es3: '',
            dt2: '',
            es1: 'ܥܘܨ',
            ar3: '',
            bedeutung: 'weibl., “Aufgabe, Dienst, Pﬂicht”; weitere Formen: Wolita',
            id: '2291',
            ar1: 'ܥܘܨ',
            es2: 'ܥܘܨ',
            dt1: 'Us'
        },
        {
            ar1: 'ܕܘܡܐ',
            ar3: '',
            es3: '',
            id: '1470',
            es1: 'ܕܘܡܐ',
            es2: 'ܕܘܡܐ',
            dt3: '',
            dt1: 'Duma',
            ar2: 'ܕܽܘܡܰܐ',
            bedeutung: 'weibl., “Ohr”',
            dt2: ''
        },
        {
            ar1: 'ܐܕܡ',
            id: '793',
            bedeutung: 'männl., westsyr. Form zu Adam',
            es2: 'ܐܕܡ',
            dt2: '',
            ar3: '',
            ar2: 'ܐܳܕܳܡ',
            dt3: '',
            es1: 'ܐܕܡ',
            es3: '',
            dt1: 'Odom'
        },
        {
            ar3: '',
            id: '730',
            es1: 'ܢܒܘܢܝܕ',
            bedeutung: 'männl., aramäisch-chaldäischer König (555-539 v. Chr.); weitere Formen: Nabunaid',
            ar1: 'ܢܒܘܢܝܕ',
            es2: 'ܢܒܘܢܝܕ',
            ar2: 'ܢܰܒܽܘܢܺܝܕ',
            dt3: '',
            es3: '',
            dt2: '',
            dt1: 'Nabonid'
        },
        {
            ar3: '',
            ar2: 'ܣܶܪܰܦ',
            es3: '',
            es1: 'ܣܪܦ',
            bedeutung: 'weibl., “Schatz, Fiskus, Vorrat”',
            es2: 'ܣܪܦ',
            dt2: '',
            dt1: 'Seraph',
            ar1: 'ܣܪܦ',
            dt3: '',
            id: '2179'
        },
        {
            es3: '',
            dt2: '',
            es2: 'ܥܡܘܣ',
            dt3: '',
            ar1: 'ܥܡܘܣ',
            dt1: 'Amos',
            ar3: '',
            id: '83',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Träger, Jahwe hat dich getragen”. Im AT ist Amos ein Prophet (um 750 v.Chr.), im NT Ahnherr Jesu',
            es1: 'ܥܡܘܣ',
            ar2: 'ܥܰܡܳܘܣ'
        },
        {
            ar2: 'ܣܳܘܣܺܝ',
            es3: 'ܙܘܙܝ',
            ar1: 'ܣܘܣܝ',
            es2: 'ܣܘܣܝ',
            dt1: 'Sosi',
            ar3: 'ܙܽܘܙܺܝ',
            dt3: '',
            id: '2199',
            es1: 'ܣܘܣܝ',
            bedeutung: 'weibl., “Syrien”. Die griechische bzw. (nach)christliche Bezeichnung für Aram-Damaskus, einen aramäischen Staat',
            dt2: 'Susi'
        },
        {
            ar3: '',
            dt1: 'Qinta',
            ar1: 'ܩܝܢܬܐ',
            ar2: 'ܩܺܝܢܬܰܐ',
            es2: 'ܩܝܢܬܐ',
            bedeutung: 'männl., “mein Herr, Meister, Lehrer, Rabbi”; weitere Formen: Rabbi',
            dt2: '',
            es1: 'ܩܝܢܬܐ',
            dt3: '',
            es3: '',
            id: '2007'
        },
        {
            dt3: '',
            ar3: 'ܝܰܐܺܝܪܽܘܣ',
            dt1: 'Jairus',
            id: '525',
            ar2: 'ܝܽܘܐܳܪܳܫ',
            dt2: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs. Im NT ist Jairus Synagogenvorsteher in Kafarnaum, Vater der Tochter, die durch Jesus vom Tode erweckt wurde. Weitere Formen: Juorosch',
            es1: 'ܝܘܐܪܫ',
            ar1: 'ܝܘܐܪܫ',
            es3: 'ܝܐܝܪܘܣ',
            es2: 'ܝܘܐܪܫ'
        },
        {
            es2: 'ܚܢܢܐܝܠ',
            dt1: 'Hananel',
            es3: '',
            ar3: '',
            es1: 'ܚܢܢܐܝܠ',
            bedeutung: 'männl., siehe Hanna',
            dt2: '',
            dt3: '',
            ar2: 'ܚܰܢܰܢܐܝܶܠ',
            id: '1620',
            ar1: 'ܚܢܢܐܝܠ'
        },
        {
            es3: '',
            bedeutung: 'männl., “Hadad ist erhaben”. Er war Sohn des Tol, des Königs von Hamat. Weitere Formen: Hadurom',
            ar3: '',
            dt3: '',
            dt2: '',
            ar1: 'ܚܒܘܫܬܐ',
            id: '1597',
            es1: 'ܚܒܘܫܬܐ',
            dt1: 'Habuschta',
            es2: 'ܚܒܘܫܬܐ',
            ar2: 'ܚܰܒܽܘܫܬܰܐ'
        },
        {
            id: '2054',
            ar1: 'ܪܒܪܬ',
            ar3: '',
            dt2: '',
            es2: 'ܪܒܪܬ',
            dt1: 'Robert',
            dt3: '',
            bedeutung: 'weibl., “die Heimat sei erhaben”',
            es3: '',
            es1: 'ܪܒܪܬ',
            ar2: 'ܪܰܒܶܪܬ'
        },
        {
            bedeutung: '',
            es2: 'ܓܐܒܝ',
            dt1: 'Gabi',
            ar3: '',
            es3: '',
            dt2: '',
            id: '353',
            dt3: '',
            es1: 'ܓܐܒܝ',
            ar2: 'ܓܰܐܒܺܝ',
            ar1: 'ܓܐܒܝ'
        },
        {
            ar2: 'ܐܽܘܪܢܰܗܪܰܐ',
            dt3: '',
            ar3: '',
            bedeutung: 'weibl., “Land der Flüsse, der Ströme”',
            es1: 'ܐܘܪܢܗܪܐ',
            es2: 'ܐܘܪܢܗܪܐ',
            es3: '',
            dt1: 'Urnahra',
            dt2: '',
            id: '1096',
            ar1: 'ܐܘܪܢܗܪܐ'
        },
        {
            dt1: 'Barkino',
            dt3: '',
            ar1: 'ܒܪܟܐܢܐ',
            es1: 'ܒܪܟܐܢܐ',
            bedeutung: 'männl., “Sohn des Gerechten”',
            es3: '',
            ar3: '',
            dt2: '',
            es2: 'ܒܪܟܐܢܐ',
            ar2: 'ܒܰܪܟܺܐܢܳܐ',
            id: '170'
        },
        {
            ar1: 'ܡܝܠܕ',
            dt2: '',
            bedeutung: 'männl., „Geburt“',
            id: '702',
            ar3: '',
            es1: 'ܡܝܠܕ',
            dt3: '',
            es2: 'ܡܝܠܕ',
            es3: '',
            dt1: 'Milad',
            ar2: 'ܡܺܝܠܰܕ'
        },
        {
            ar1: 'ܥܦܪܐ',
            ar3: '',
            dt2: '',
            es1: 'ܥܦܪܐ',
            id: '1221',
            es3: '',
            es2: 'ܥܦܪܐ',
            bedeutung: 'männl., “Staub, Erde, Boden”',
            dt1: 'Afro',
            ar2: 'ܥܰܦܪܳܐ',
            dt3: ''
        },
        {
            dt2: '',
            dt1: 'Geschtiyana',
            es3: '',
            ar1: 'ܓܫܬܝܢܐ',
            id: '378',
            es2: 'ܓܫܬܝܢܐ',
            es1: 'ܓܫܬܝܢܐ',
            ar3: '',
            dt3: '',
            bedeutung: 'weibl., Geschtiyana war Schwester des Gottes Tammus/ Adonis',
            ar2: 'ܓܶܫܬܺܝܰܢܰܐ'
        },
        {
            id: '2296',
            es1: 'ܘܠܓܫ',
            dt1: 'Walagasch',
            es2: 'ܘܠܓܫ',
            dt2: '',
            bedeutung: 'männl., “Liebling, Schatz”; weitere Formen: Yadid, Jadid, Jadido',
            ar3: '',
            dt3: '',
            ar1: 'ܘܠܓܫ',
            es3: '',
            ar2: 'ܘܰܠܰܓܰܫ'
        },
        {
            dt2: '',
            dt1: 'Schafir',
            ar1: 'ܫܦܝܪ',
            es2: 'ܫܦܝܪ',
            es3: '',
            ar2: 'ܫܰܦܺܝܪ',
            ar3: '',
            dt3: '',
            id: '2113',
            bedeutung: 'männl., “ﬂammen, entﬂammen, feuern, leuchten, scheinen, liebevoll sorgen für”',
            es1: 'ܫܦܝܪ'
        },
        {
            ar3: '',
            dt2: '',
            es1: 'ܐܝܙܪܥܠ',
            dt3: '',
            id: '544',
            ar2: 'ܐܺܝܙܰܪܥܶܠ',
            es2: 'ܐܝܙܪܥܠ',
            ar1: 'ܐܝܙܪܥܠ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott sät”; weitere Formen: Isarel',
            dt1: 'Jesreel',
            es3: ''
        },
        {
            bedeutung: 'männl., “mein Brot”',
            dt1: 'Lahmi',
            dt3: '',
            id: '617',
            es2: 'ܠܚܡܝ',
            ar3: '',
            ar1: 'ܠܚܡܝ',
            es1: 'ܠܚܡܝ',
            ar2: 'ܠܰܚܡܺܝ',
            es3: '',
            dt2: ''
        },
        {
            dt3: '',
            ar3: 'ܚܶܙܝܽܘܢ',
            es3: 'ܚܙܝܘܢ',
            es2: 'ܚܙܝܢ',
            dt2: '',
            ar1: 'ܚܙܝܢ',
            bedeutung: 'männl., “Vision”. Ahnherr des aramäischen Königs Ben-Hadad I. von Aram-Damaskus (10. Jh.v.Chr.). Weitere Formen: Hezjon, Hesjun',
            id: '472',
            es1: 'ܚܙܝܢ',
            ar2: 'ܚܶܙܝܳܐܢ',
            dt1: 'Hesjon'
        },
        {
            id: '48',
            dt3: '',
            dt2: '',
            es2: 'ܐܓܝܒ',
            ar3: '',
            ar1: 'ܐܓܝܒ',
            ar2: 'ܐܰܓܺܝܒ',
            es1: 'ܐܓܝܒ',
            bedeutung: 'männl., “antworte!”',
            dt1: 'Agib',
            es3: ''
        },
        {
            bedeutung: 'weibl. Form zu Barmotho; “Tochter der Heimat”; weitere Formen: Batmata',
            dt3: '',
            id: '209',
            es3: '',
            es2: 'ܒܪܬܡܬܐ',
            ar3: '',
            ar1: 'ܒܪ̱ܬܡܬ݂ܐ',
            ar2: 'ܒܰܪ̱ܬܡܳܬ݂ܳܐ',
            dt1: 'Bathmotho',
            dt2: '',
            es1: 'ܒܪܬܡܬܐ'
        },
        {
            ar3: '',
            es1: 'ܢܥܡܢ',
            dt2: '',
            dt3: '',
            id: '726',
            ar1: 'ܢܥܡܢ',
            es2: 'ܢܥܡܢ',
            ar2: 'ܢܰܥܡܳܢ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “anmutig”. Im AT ist Naaman Sohn Benjamins; nach dem AT und NT ist Naaman ein Aramäer oder Syrer, Heerführer des Königs von Aram-Damaskus, der unter vielen Aussätzigen ausgewählt und durch den Propheten Elischa geheilt wird. Weitere Formen: Naamon, Naamo, Naim',
            es3: '',
            dt1: 'Naamon'
        },
        {
            id: '1727',
            es1: 'ܝܫܘܥܕܢ',
            es3: '',
            dt1: 'Jeschudod',
            es2: 'ܝܫܘܥܕܢ',
            dt3: '',
            ar3: '',
            dt2: '',
            ar2: 'ܝܶܫܽܘܥܕܳܢ',
            ar1: 'ܝܫܘܥܕܢ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “[Gott] öffnet, befreit”; weitere Formen: Jeftah, Naftah'
        },
        {
            es3: '',
            ar3: '',
            dt1: 'Haskiel',
            dt3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott gebe Stärke, Gott möge stärken“. Im AT ist Haskiel Priester, Prophet (6. Jh. v.Chr.) im babylonischen Exil und Verfasser eines Buches. Weitere Formen: Ezechiel, Hesekiel, Hasqiel',
            ar2: 'ܚܰܙܩܺܐܝܶܠ',
            es2: 'ܚܙܩܐܝܠ',
            es1: 'ܚܙܩܐܝܠ',
            dt2: '',
            ar1: 'ܚܙܩܐܝܠ',
            id: '451'
        },
        {
            ar2: 'ܣܰܒܪܺܝܢܰܐ',
            ar3: 'ܙܰܒܪܺܝܢܰܐ',
            id: '2073',
            bedeutung: '',
            es2: 'ܣܒܪܝܢܐ',
            es1: 'ܣܒܪܝܢܐ',
            dt2: '',
            dt3: '',
            ar1: 'ܣܒܪܝܢܐ',
            es3: 'ܙܒܪܝܢܐ',
            dt1: 'Sabrina'
        },
        {
            dt3: '',
            es3: '',
            ar1: 'ܚܒܘܪܐ',
            es2: 'ܚܒܘܪܐ',
            ar2: 'ܚܰܒܽܘܪܳܐ',
            dt1: 'Haburo',
            id: '409',
            ar3: '',
            es1: 'ܚܒܘܪܐ',
            bedeutung: 'männl., syrischen Ursprungs; “Rauch, Dampf ”',
            dt2: ''
        },
        {
            dt3: '',
            es1: 'ܢܚܘܡ',
            ar3: 'ܢܳܚܽܘܡ',
            ar2: 'ܢܰܚܳܘܡ',
            ar1: 'ܢܚܘܡ',
            bedeutung: 'männl., “siegreich, ausgezeıchnet”; weitere Formen: Nasiho',
            id: '1932',
            es2: 'ܢܚܘܡ',
            dt2: '',
            dt1: 'Nahum',
            es3: 'ܢܚܘܡ'
        },
        {
            ar3: 'ܟܪܺܝܣܛܝܰܐܢ',
            dt2: 'Kristian',
            dt1: 'Christian',
            es1: 'ܟܪܝܣܬܝܢ',
            ar1: 'ܟܪܝܣܬܝܢ',
            bedeutung: '',
            id: '245',
            ar2: 'ܟܪܺܝܣܬܝܰܢ',
            es2: 'ܟܪܝܣܬܝܢ',
            es3: 'ܟܪܝܣܛܝܐܢ',
            dt3: ''
        },
        {
            dt1: 'Turayta',
            es3: '',
            dt2: '',
            es1: 'ܛܘܪܝܬܐ',
            ar2: 'ܛܽܘܪܰܝܬܰܐ',
            bedeutung: 'weibl., “unser Urhoy-Edessa”',
            ar3: '',
            es2: 'ܛܘܪܝܬܐ',
            id: '2272',
            ar1: 'ܛܘܪܝܬܐ',
            dt3: ''
        },
        {
            ar1: 'ܝܘܣܐ',
            bedeutung: '',
            ar3: '',
            dt3: '',
            es2: 'ܝܘܣܐ',
            es1: 'ܝܘܣܐ',
            es3: '',
            dt1: 'Yause',
            dt2: '',
            ar2: 'ܝܰܘܣܶܐ',
            id: '2324'
        },
        {
            ar2: 'ܐܶܠܺܝܫܳܥ',
            ar1: 'ܐܠܝܫܥ',
            es2: 'ܐܠܝܫܥ',
            es1: 'ܐܠܝܫܥ',
            ar3: 'ܐܶܠܺܝܫܰܥ',
            dt2: 'Elisa',
            dt1: 'Elischah',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott hat geholfen”. Im AT ist Elischa ein Prophet (um 850 v.Chr.). Weitere Formen: Elischa, Elischo, Elischoh, Elisa',
            id: '304',
            es3: 'ܐܠܝܫܥ',
            dt3: 'Elischa'
        },
        {
            ar3: '',
            dt1: 'Kalitmara',
            dt2: '',
            ar1: 'ܩܠܬܡܖܐ',
            es2: 'ܩܠܬܡܪܐ',
            es1: 'ܩܠܬܡܪܐ',
            ar2: 'ܩܶܠܶܬܡܰܖܰܐ',
            dt3: '',
            id: '575',
            bedeutung: 'weibl., Kalitmara ist ein Dorf in Turabdin',
            es3: ''
        },
        {
            dt3: '',
            bedeutung: 'männl., “Onkel (väterlicherseits)”. Dodo (†609) ist ein Heiliger in der syrisch- orthodoxen Kirche; Festtag: 20. Mai',
            ar1: 'ܕܝܡܛ',
            es2: 'ܕܝܡܛ',
            ar2: 'ܕܺܝܡܶܛ',
            dt2: 'Dimet',
            dt1: 'Dimat',
            ar3: 'ܕܺܝܡܶܬ',
            id: '1456',
            es3: 'ܕܝܡܬ',
            es1: 'ܕܝܡܛ'
        },
        {
            dt1: 'Schaino',
            dt2: '',
            es3: '',
            bedeutung: 'männl., “Frieden”; weitere Formen: Schayno, Schajno',
            dt3: '',
            id: '928',
            ar3: '',
            ar1: 'ܫܝܢܐ',
            es1: 'ܫܝܢܐ',
            ar2: 'ܫܰܝܢܳܐ',
            es2: 'ܫܝܢܐ'
        },
        {
            ar2: 'ܐܶܣܬܶܪ',
            id: '323',
            dt3: '',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Myrte oder die Verborgene” (akkadisch Ischtar). Im AT ist Esther die Gattin von Xerxes I. Sie verhindert die Ausrottung der Juden in Persien, ist Hauptgestalt des nach Ihr benannten alttestamentlichen Buches',
            dt2: 'Ester',
            ar1: 'ܐܣܬܪ',
            es2: 'ܐܣܬܪ',
            es1: 'ܐܣܬܪ',
            ar3: '',
            es3: '',
            dt1: 'Esther'
        },
        {
            ar3: '',
            bedeutung: '',
            es3: '',
            dt1: 'Nakfuta',
            dt3: '',
            id: '1934',
            es1: 'ܢܟܦܘܬܐ',
            es2: 'ܢܟܦܘܬܐ',
            dt2: '',
            ar1: 'ܢܟܦܘܬܐ',
            ar2: 'ܢܰܟܦܽܘܬܰܐ'
        },
        {
            es2: 'ܓܘܪܓܝ',
            ar3: '',
            dt2: '',
            ar1: 'ܓܘܪܓܝ',
            es3: '',
            es1: 'ܓܘܪܓܝ',
            bedeutung: '',
            ar2: 'ܓܶܘܪܓܺܝ',
            dt1: 'Gewargi',
            id: '1566',
            dt3: ''
        },
        {
            es3: '',
            bedeutung: '',
            ar1: 'ܙܟܪܝܝܐ',
            dt1: 'Zakariya',
            ar3: '',
            es2: 'ܙܟܪܝܝܐ',
            dt2: 'Zakaria',
            es1: 'ܙܟܪܝܝܐ',
            ar2: 'ܙܟܪܝܐܣ',
            id: '1156',
            dt3: 'Sacharja'
        },
        {
            es1: 'ܐܘܪܫܝܢܐ',
            es2: 'ܐܘܪܫܝܢܐ',
            id: '1098',
            bedeutung: 'weibl., “Land des Friedens”; weitere Formen: Urschayna',
            ar1: 'ܐܘܪܫܝܢܐ',
            ar3: '',
            dt3: '',
            ar2: 'ܐܽܘܪܫܰܝܢܳܐ',
            dt2: '',
            es3: '',
            dt1: 'Urschaina'
        },
        {
            ar2: 'ܐܰܚܰܙܝܰܐ',
            bedeutung: '',
            dt2: '',
            ar1: 'ܐܚܙܝܐ',
            es3: '',
            id: '1181',
            es1: 'ܐܚܙܝܐ',
            dt3: '',
            dt1: 'Abasja',
            ar3: '',
            es2: 'ܐܚܙܝܐ'
        },
        {
            id: '1816',
            es3: '',
            ar2: 'ܠܶܒܳܐ',
            es1: 'ܠܒܐ',
            dt3: '',
            bedeutung: 'männl., aus dem Lateinischen übernommener Vorname; “der aus Lucania Stammende”; Mitarbeiter des Apostels Paulus, gilt als der Verfasser des dritten Evangeliums und der Apostelgeschichte. Weitere Formen: Luko, Lukos, Luka',
            dt1: 'Lebo',
            dt2: '',
            es2: 'ܠܒܐ',
            ar3: '',
            ar1: 'ܠܒܐ'
        },
        {
            dt1: 'Jabal',
            es2: 'ܝܒܠ',
            id: '1705',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Fersenhalter”. Im AT ist Jakob der jüngere Sohn Isaaks und Rebekkas, Zwillingsbıuder von Esau. Er lebte in Paddan Aram, wo elf seiner Söhne geboren wurden. Im NT tragen viele Personen (u.a. Apostel) diesen Namen. Ebenfalls gibt es viele syrische Heilige und Kirchenlehrer des gleichen Namens: Jakob von Nisibis (†338), Festtag: 11. Mai; Jakob Burd`ono (Baradäus †578), Festtag: 31. Juli; Jakob Hbischoyo von Salah in Tur Abdin (330-422), Festtag: 18. September; Jakob von Samg (451-521), Festtag: 29. November; Jakob von Edessa (†708). Weitere Formen: Yaqub, Jacub, Jakobus',
            dt2: '',
            es1: 'ܝܒܠ',
            ar3: '',
            es3: '',
            ar2: 'ܝܰܒܰܠ',
            ar1: 'ܝܒܠ',
            dt3: ''
        },
        {
            es3: '',
            bedeutung: 'männl., babylonischer Weisheitsgott; weitere Formen: Nebu, Nebo, Nabu',
            es1: 'ܡܘܫܐ',
            dt2: 'Musche',
            ar3: '',
            ar1: 'ܡܘܫܐ',
            dt3: '',
            dt1: 'Moses',
            es2: 'ܡܘܫܐ',
            id: '1909',
            ar2: 'ܡܽܘܫܶܐ'
        },
        {
            ar1: 'ܝܐܝܘܬܐ',
            ar2: 'ܝܰܐܝܽܘܬܰܐ',
            dt1: 'Yayuta',
            es3: '',
            id: '2326',
            ar3: '',
            dt3: '',
            es1: 'ܝܐܝܘܬܐ',
            es2: 'ܝܐܝܘܬܐ',
            dt2: '',
            bedeutung: 'weibl., weitere Formen: Zabbai, Zabay, Zabaj'
        },
        {
            bedeutung: 'männl., lateinischen Ursprungs; “das Feuer, das Feurige”. Ignatius (†107) war der zweite bzw. Dritte Bischof/Patriarch der syrisch-orthodoxen Kirche von Antiochien; seit 1293 gilt “Ignatius” als Beiname ﬁir die syrisch-orthodoxen Patriarchen',
            es2: 'ܐܝܓ݂ܢܐܛܝܘܣ',
            ar3: '',
            ar2: 'ܐܺܝܓ݂ܢܰܐܛܺܝܽܘܣ',
            dt2: '',
            es1: 'ܐܝܓ݂ܢܐܛܝܘܣ',
            es3: '',
            ar1: 'ܐܝܓܢܐܛܝܘܣ',
            dt3: '',
            dt1: 'Ignatius',
            id: '496'
        },
        {
            dt2: 'Safira',
            bedeutung: 'weibl., Saruna war eine Stadt in Alt-Syrien',
            ar2: 'ܨܰܦܺܝܪܰܐ',
            dt3: 'Zafira',
            dt1: 'Saphira',
            ar3: 'ܫܰܦܺܝܪܰܐ',
            ar1: 'ܨܦܝܪܐ',
            id: '2097',
            es3: 'ܫܦܝܪܐ',
            es2: 'ܨܦܝܪܐ',
            es1: 'ܨܦܝܪܐ'
        },
        {
            es3: '',
            dt1: 'Hadadmalko',
            bedeutung: 'männl., ”Hadad ist König“. Hadadmalko war ein von den Aramäern verehrter ”Wettergott“',
            ar1: 'ܗܕܕܡܠܟܐ',
            ar2: 'ܗܰܕܰܕܡܰܠܟܳܐ',
            es1: 'ܗܕܕܡܠܟܐ',
            ar3: 'ܚܰܕܰܕܡܰܠܟܳܐ',
            dt3: '',
            id: '413',
            es2: 'ܗܕܕܡܠܟܐ',
            dt2: ''
        },
        {
            dt3: '',
            ar3: '',
            dt2: 'Deqlat',
            ar1: 'ܕܩܠܬ݂',
            ar2: 'ܕܶܩܠܰܬ݂',
            bedeutung: 'weibl., “Pfeil, Geschoß, Spitze, Schärfe, Strom, Strombett” Deqlath oder Tigris ist ein Strom/Fluss in Aram Beth-Nahrin (= Mesopotamien). Weitere Formen: Deqlat, Tigris',
            es2: 'ܕܩܠܬ݂',
            es1: 'ܕܩܠܬ݂',
            dt1: 'Deqlath',
            es3: '',
            id: '269'
        },
        {
            es1: 'ܥܡܢܘܐܝܠܗ',
            bedeutung: 'weibl. Namensform von Manuel',
            dt1: 'Manuela',
            ar2: 'ܥܰܡܰܢܽܘܐܶܝܠܰܗ',
            es2: 'ܥܡܢܘܐܝܠܗ',
            id: '659',
            dt3: '',
            dt2: '',
            es3: 'ܥܡܢܘܐܝܠܐ',
            ar1: 'ܥܡܢܘܐܝܠܗ',
            ar3: 'ܥܰܡܰܢܽܘܐܶܝܠܰܐ'
        },
        {
            bedeutung: 'weibl., syrischen Ursprungs; “Rahm, Sahne”',
            dt2: '',
            ar1: 'ܚܘܬܐ',
            es2: 'ܚܘܬܐ',
            id: '474',
            ar3: 'ܚܶܐܘܬܰܐ',
            dt1: 'Hewtha',
            es3: 'ܚܐܘܬܐ',
            dt3: '',
            ar2: 'ܚܶܘܬܰܐ',
            es1: 'ܚܘܬܐ'
        },
        {
            es2: 'ܙܗܝܬܐ',
            id: '1149',
            bedeutung: 'weibl., “Glanz, Pracht, Leuchte, Glorie, Ruhm, Ehre, Hoheit, Tugend”, “die Reine“, weitere Formen: Zhita, Sahita',
            dt3: '',
            ar3: '',
            es3: '',
            dt1: 'Zahita',
            ar1: 'ܙܗܝܬܐ',
            dt2: '',
            ar2: 'ܙܰܗܺܝܬܰܐ',
            es1: 'ܙܗܝܬܐ'
        },
        {
            id: '1524',
            ar3: '',
            es3: '',
            ar2: 'ܦܶܠܶܐܬܰܐ',
            bedeutung: 'männl., “der Befehlsempfangende”. Fqido (104-114) war Schüler des Apostels Mor Addai und ostsyrischer Bischof von Arbela. Weitere Formen: Pqida',
            dt1: 'Feleta',
            dt2: '',
            ar1: 'ܦܠܐܬܐ',
            dt3: '',
            es1: 'ܦܠܐܬܐ',
            es2: 'ܦܠܐܬܐ'
        },
        {
            dt1: 'Yablo',
            ar2: 'ܝܰܒܠܳܐ',
            es2: 'ܝܒܠܐ',
            es3: '',
            bedeutung: 'männl., “Strom, Bach”; weitere Formen: Jablo',
            ar3: '',
            id: '1111',
            dt2: '',
            es1: 'ܝܒܠܐ',
            ar1: 'ܝܒܠܐ',
            dt3: ''
        },
        {
            dt3: '',
            dt2: '',
            ar2: 'ܝܰܗܒܐܺܝܠ',
            dt1: 'Yahbil',
            es2: 'ܝܗܒܐܝܠ',
            ar1: 'ܝܗܒܐܝܠ',
            es3: '',
            id: '1116',
            es1: 'ܝܗܒܐܝܠ',
            bedeutung: 'männl., “Gott hat gegeben, weitere Formen: Yabil, Jahbil, Jabil',
            ar3: ''
        },
        {
            dt3: '',
            dt2: '',
            es1: 'ܢܒܝܠ',
            id: '1917',
            es2: 'ܢܒܝܠ',
            es3: '',
            ar3: '',
            ar1: 'ܢܒܝܠ',
            bedeutung: 'männl., syrischen Ursprungs; “strahlend, leuchtend”; weitere Formen: Nahiro',
            dt1: 'Nabil',
            ar2: 'ܢܰܒܺܝܠ'
        },
        {
            dt1: 'Hananya',
            id: '436',
            dt3: '',
            ar1: 'ܚܢܢܝܐ',
            ar3: '',
            es2: 'ܚܢܢܝܐ',
            es1: 'ܚܢܢܝܐ',
            ar2: 'ܚܰܢܰܢܝܰܐ',
            es3: '',
            dt2: '',
            bedeutung: ''
        },
        {
            ar2: 'ܦܰܪܳܐ',
            ar1: 'ܦܪܐ',
            dt2: '',
            es2: 'ܦܪܐ',
            dt1: 'Faro',
            es3: '',
            es1: 'ܦܪܐ',
            id: '335',
            ar3: '',
            bedeutung: 'männl., “Lamm”',
            dt3: ''
        },
        {
            dt1: 'Warde',
            bedeutung: 'weibl., “Blumen”',
            ar1: 'ܘܪܕܐ',
            es1: 'ܘܪܕܐ',
            ar3: '',
            dt3: '',
            es2: 'ܘܪܕܐ',
            dt2: '',
            ar2: 'ܘܰܪܕܶܐ',
            es3: '',
            id: '1106'
        },
        {
            id: '1199',
            ar2: 'ܐܰܒܝܰܬܰܖ',
            es2: 'ܐܒܝܬܪ',
            dt2: '',
            ar3: '',
            dt3: '',
            es3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “der Vater hat Überﬂuß”. Im AT ist Abjatar ein Priester, Sohn des Ahimelech; weitere Formen: Abyatar',
            ar1: 'ܐܒܝܬܖ',
            es1: 'ܐܒܝܬܪ',
            dt1: 'Abjatar'
        },
        {
            ar2: 'ܫܡܽܘܢܺܝ',
            ar1: 'ܫܡܘܢܝ',
            es2: 'ܫܡܘܢܝ',
            id: '969',
            dt2: 'Schmouni',
            dt1: 'Schmuni',
            es3: '',
            dt3: '',
            bedeutung: 'weibl., “mein Name” oder “höre auf mich”. Eine Heilige (163 v.Chr.) in der syrisch-orthodoxen Kirche. Gedenktag: 1. August',
            ar3: '',
            es1: 'ܫܡܘܢܝ'
        },
        {
            ar2: 'ܘܰܐܶܠ',
            bedeutung: 'männl., König von Edessa (162-164 n.Chr.), Sohn von Sahru; weitere Formen: Wayel',
            ar3: '',
            es3: '',
            ar1: 'ܘܐܠ',
            dt2: '',
            id: '1103',
            dt1: 'Wael',
            es2: 'ܘܐܠ',
            dt3: '',
            es1: 'ܘܐܠ'
        },
        {
            dt2: '',
            ar1: 'ܓܐܒܝ',
            dt3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “mein Glück”; weitere Formen: Gadi',
            id: '1539',
            dt1: 'Gabi',
            es3: '',
            es2: 'ܓܐܒܝ',
            es1: 'ܓܐܒܝ',
            ar2: 'ܓܰܐܒܺܝ',
            ar3: ''
        },
        {
            id: '2191',
            ar1: 'ܨܠܝܠܬܐ',
            dt2: '',
            dt3: '',
            es2: 'ܨܠܝܠܬܐ',
            es1: 'ܨܠܝܠܬܐ',
            ar2: 'ܨܠܝܺܠܬܰܐ',
            es3: '',
            bedeutung: 'männl., Nebenform Stephanus',
            dt1: 'Slita',
            ar3: ''
        },
        {
            dt3: '',
            ar1: 'ܣܘܪܝܐ',
            ar2: 'ܣܽܘܪܺܝܰܐ',
            ar3: '',
            es1: 'ܣܘܪܝܐ',
            bedeutung: 'weibl., “Syrien”. Die griechische bzw. (nach)christliche Bezeichnung für Aram-Damaskus, einen aramäischen Staat',
            id: '1016',
            dt2: '',
            es3: '',
            dt1: 'Suriya',
            es2: 'ܣܘܪܝܐ'
        },
        {
            es3: '',
            ar1: 'ܨܒܝܬܐ',
            ar3: '',
            id: '2109',
            dt1: 'Sbita',
            es1: 'ܨܒܝܬܐ',
            dt2: '',
            ar2: 'ܨܒܺܝܬܰܐ',
            dt3: '',
            es2: 'ܨܒܝܬܐ',
            bedeutung: 'männl., “Wechsel, Austausch, Unterschied”. Schahlupa (258 - 273) war Bischof von Arbela. Weitere Formen: Schuhlofo, Schuhlapa'
        },
        {
            es3: 'ܚܐܢܐ',
            es1: 'ܚܢܐ',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gnade, die Gnadenreiche”. Im AT ist Hana die Mutter Samuels. Weitere Formen: Hano; siehe auch: Hanna, Johannes, Johannun',
            dt1: 'Hana',
            id: '431',
            dt3: '',
            es2: 'ܚܢܐ',
            ar1: 'ܚܢܐ',
            ar2: 'ܚܰܢܰܐ',
            ar3: 'ܚܰܐܢܰܐ',
            dt2: ''
        },
        {
            es1: 'ܫܪܒܝܠ',
            dt1: 'Scharbel',
            bedeutung: 'männl., “feiner Herr” oder“ Leben Gottes, Gottesleben” oder “jemand, der seine Hose anzieht” Heiliger aus Edessa (†115 n. Chr.)',
            es3: '',
            ar1: 'ܫܪܒܝܠ',
            es2: 'ܫܪܒܝܠ',
            dt2: '',
            ar2: 'ܫܰܪܒܶܝܠ',
            dt3: '',
            id: '944',
            ar3: ''
        },
        {
            es2: 'ܥܛܝܗ',
            id: '1303',
            es3: '',
            ar2: 'ܥܰܛܺܝܰܗ',
            bedeutung: 'männl., “Heimat, Ort, Gegend',
            es1: 'ܥܛܝܗ',
            dt1: 'Atiyah',
            ar3: '',
            ar1: 'ܥܛܝܗ',
            dt2: '',
            dt3: ''
        },
        {
            dt2: 'Morun',
            ar2: 'ܡܰܪܽܘܢ',
            es2: 'ܡܪܘܢ',
            dt1: 'Marun',
            ar1: 'ܡܪܘܢ',
            es1: 'ܡܪܘܢ',
            es3: 'ܡܪܘܢ',
            ar3: 'ܡܳܪܽܘܢ',
            id: '679',
            bedeutung: 'männl. Bedeutung unklar, vielleicht unser Herr“. Heiliger und Stifter der syrisch-maronitischen Kirche (†nach 410). Weitere Formen: Morun',
            dt3: ''
        },
        {
            ar2: 'ܡܺܝܟ݂ܳܐ',
            id: '699',
            es3: 'ܡܝܟ݂ܐ',
            dt2: '',
            es2: 'ܡܝܟ݂ܐ',
            es1: 'ܡܝܟ݂ܐ',
            ar1: 'ܡܝܟ݂ܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; abgekürzt aus Michaja(hu): “Wer ist wie Gott?”. Im AT sind mehrere Personen unter diesem Namen bekannt, u.a. ein Prophet, Verfasser eines Buches. Weitere Formen: Micho',
            dt3: '',
            ar3: 'ܡܺܝܟ݂ܰܐ',
            dt1: 'Micha'
        },
        {
            es3: '',
            bedeutung: 'männl., syrischen Ursprungs; “Gott hat gegeben“, “Gib o Gott”; Weitere Formen: Habaloho',
            dt1: 'Gorgis',
            es2: 'ܓܘܪܓܝܣ',
            es1: 'ܓܘܪܓܝܣ',
            id: '1579',
            dt2: '',
            ar1: 'ܓܘܪܓܝܣ',
            dt3: '',
            ar2: 'ܓܳܘܪܓܺܝܣ',
            ar3: ''
        },
        {
            ar1: 'ܩܘܪܝܩܘܣ',
            bedeutung: '',
            dt1: 'Kyriakos',
            ar2: 'ܩܽܘܪܝܰܩܳܘܣ',
            es3: '',
            es2: 'ܩܘܪܝܩܘܣ',
            es1: 'ܩܘܪܝܩܘܣ',
            id: '611',
            dt2: '',
            ar3: '',
            dt3: ''
        },
        {
            id: '996',
            ar1: 'ܣܝܡܬܐ',
            dt2: '',
            ar2: 'ܣܺܝܡܬܰܐ',
            dt3: '',
            es3: '',
            es2: 'ܣܝܡܬܐ',
            es1: 'ܣܝܡܬܐ',
            bedeutung: 'weibl., “Schatz, Fiskus, Vorrat”',
            dt1: 'Simta',
            ar3: ''
        },
        {
            dt2: '',
            dt1: 'Lobon',
            es2: 'ܠܒܢ',
            ar3: '',
            ar1: 'ܠܒܢ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “der Weiße”. Im AT ist Lobon Sohn des Betuèl, des Sohnes Nahors, des Aramäers, Bruder der Rebekka, Onkel des Jakob. Lobon ist zugleıch Vater von Lea und Rahel, den beiden Frauen Jakobs. Weitere Formen: Laban',
            dt3: '',
            es1: 'ܠܒܢ',
            ar2: 'ܠܳܒܳܢ',
            id: '635',
            es3: ''
        },
        {
            id: '1568',
            dt3: '',
            ar3: 'ܓܦܶܐܬܰܐ',
            es2: 'ܓܦܬܐ',
            bedeutung: 'weibl., “Vollkommenheit, Vollendung”; weitere Formen: Gmirutha',
            ar1: 'ܓܦܬܐ',
            ar2: 'ܓܦܶܬܰܐ',
            es3: 'ܓܦܐܬܐ',
            dt2: '',
            es1: 'ܓܦܬܐ',
            dt1: 'Gfeta'
        },
        {
            es2: 'ܓܘܠܝܕ',
            es3: '',
            dt1: 'Goliat',
            dt2: 'Goliath',
            es1: 'ܓܘܠܝܕ',
            ar1: 'ܓܘܠܝܕ',
            id: '390',
            bedeutung: 'männl., aus der Bibel übernommener Vorname. Im AT ist Goliat ein Riese, gewaltiger Krieger und Philister aus Gat, der von David besiegt wird. Weitere Formen: Guljad, Goljad',
            ar2: 'ܓܽܘܠܝܰܕ',
            ar3: '',
            dt3: 'Guljad'
        },
        {
            es2: 'ܡܐܪܝܐ',
            es1: 'ܡܐܪܝܐ',
            ar1: 'ܡܐܪܝܐ',
            ar3: '',
            dt3: '',
            dt1: 'Maria',
            es3: '',
            bedeutung: '',
            ar2: 'ܡܰܪܐܝܰܐ',
            id: '1856',
            dt2: 'Mariya'
        },
        {
            ar3: '',
            dt1: 'Panamwa',
            ar1: 'ܦܢܡܘܐ',
            es1: 'ܦܢܡܘܐ',
            dt2: '',
            es3: '',
            es2: 'ܦܢܡܘܐ',
            id: '801',
            ar2: 'ܦܰܢܰܡܘܰܐ',
            dt3: '',
            bedeutung: 'männl., mehrere Könige der Aramäer von Samal-Jadi (8. Jh. v. Chr.). Weitere Formen: Panamua'
        },
        {
            es1: 'ܫܪܫܬ',
            dt2: '',
            dt3: '',
            es2: 'ܫܪܫܬ',
            ar2: 'ܫܶܪܫܰܬ',
            ar1: 'ܫܪܫܬ',
            id: '959',
            dt1: 'Scherschat',
            es3: '',
            ar3: '',
            bedeutung: 'männl., “du bist meine Wurzel, Fundament”'
        },
        {
            es1: 'ܒܣܣܝܡܐ',
            ar2: 'ܒܰܣܣܺܝܡܰܐ',
            bedeutung: 'weibl. Form zu Basim',
            dt3: '',
            id: '198',
            es2: 'ܒܣܣܝܡܐ',
            dt2: '',
            dt1: 'Basima',
            ar3: '',
            ar1: 'ܒܣܣܝܡܐ',
            es3: ''
        },
        {
            dt1: 'Amminadab',
            es2: 'ܥܡܝܢܕܒ',
            ar2: 'ܥܰܡܺܝܢܰܕܰܒ',
            ar1: 'ܥܡܝܢܕܒ',
            ar3: '',
            es3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “mein Onkel ist schaddai = allmächtig”',
            es1: 'ܥܡܝܢܕܒ',
            id: '1258',
            dt3: '',
            dt2: 'Amminodob'
        },
        {
            dt2: '',
            es2: 'ܒܪܬܙܒܝ',
            es1: 'ܒܪܬܙܒܝ',
            dt1: 'Bath Zabbai',
            ar1: 'ܒܪ̱ܬܙܒܝ',
            dt3: '',
            id: '1383',
            bedeutung: 'weibl. Form Barhoubo; “Tochter der Liebe”; weitere Formen: Bathhouba',
            es3: '',
            ar3: '',
            ar2: 'ܒܰܪ̱ܬܙܰܒܒܰܝ'
        },
        {
            ar3: '',
            es3: '',
            es2: 'ܕܝܨܢ',
            es1: 'ܕܝܨܢ',
            id: '1429',
            bedeutung: 'männl., “rein, sauber”',
            ar1: 'ܕܝܨܢ',
            ar2: 'ܕܰܝܨܳܢ',
            dt3: '',
            dt2: 'Daysan',
            dt1: 'Daisan'
        },
        {
            ar3: 'ܝܰܪܚܽܘܡ',
            dt1: 'Jeroham',
            es3: 'ܝܪܚܘܡ',
            es1: 'ܝܪܘܚܡ',
            es2: 'ܝܪܘܚܡ',
            dt3: '',
            dt2: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “möge er von Mitleid ergriffen werden”; weitere Formen: Jeruham',
            ar1: 'ܝܪܘܚܡ',
            ar2: 'ܝܰܪܽܘܚܰܡ',
            id: '536'
        },
        {
            dt1: 'Bathdina',
            bedeutung: 'weibl. Form zu Bardino; “Tochter des Gerichtes, Tochter des Urteils”',
            id: '204',
            ar1: 'ܒܪ̱ܬܕܝܢܐ',
            dt2: '',
            dt3: '',
            ar2: 'ܒܰܪ̱ܬܕܺܝܢܰܐ',
            es1: 'ܒܪܬܕܝܢܐ',
            es3: '',
            ar3: '',
            es2: 'ܒܪܬܕܝܢܐ'
        },
        {
            es2: 'ܗܕܕ',
            id: '1598',
            ar1: 'ܗܕܕ',
            ar3: 'ܗܳܕܳܕ',
            ar2: 'ܚܰܕܰܕ',
            bedeutung: 'weibl., syrischen Ursprungs; “Wegweisung, Richtung, Leitung, Führung”; weitere Formen: Hadoyutha',
            es3: 'ܗܕܕ',
            dt2: '',
            es1: 'ܗܕܕ',
            dt3: '',
            dt1: 'Hadad'
        },
        {
            es3: '',
            ar3: '',
            es2: 'ܛܒܘܬܐ',
            bedeutung: 'männl., “du bist meine Hoffnung / Vertrauen”; weitere Formen: Tuchlono',
            dt1: 'Tobuta',
            ar2: 'ܛܳܒܽܘܬܰܐ',
            es1: 'ܛܒܘܬܐ',
            ar1: 'ܛܒܘܬܐ',
            id: '2260',
            dt3: '',
            dt2: ''
        },
        {
            es2: 'ܦܪܢܟ',
            dt2: '',
            es1: 'ܦܪܢܟ',
            ar3: '',
            bedeutung: '',
            es3: '',
            id: '347',
            ar2: 'ܦܪܰܢܟ',
            ar1: 'ܦܪܢܟ',
            dt3: '',
            dt1: 'Frank'
        },
        {
            es3: 'ܝܢܢܝ',
            dt2: '',
            es2: 'ܝܘܚܢܢ',
            es1: 'ܝܘܚܢܢ',
            ar1: 'ܝܘܚܢܢ',
            ar3: 'ܝܳܢܢܺܝ',
            ar2: 'ܝܢܢܝ',
            dt3: '',
            bedeutung: '',
            dt1: 'Jonny',
            id: '1746'
        },
        {
            ar2: 'ܐܰܕܳܘܢܺܝܪܰܡ',
            dt3: '',
            dt2: '',
            es1: 'ܐܕܘܢܝܪܡ',
            dt1: 'Adoniram',
            id: '1218',
            es3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “mein Herr ist erhaben”. Im AT begegnen Adoniram, Sohn des Abda, und Adoniram, Sohn des Toi, des Königs von Hamat (11. Jh.v.Clır.). Weitere Formen: Hadoram',
            es2: 'ܐܕܘܢܝܪܡ',
            ar1: 'ܐܕܘܢܝܪܡ',
            ar3: ''
        },
        {
            ar2: 'ܒܰܪܣܺܝܢ',
            ar1: 'ܒܪܣܝܢ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäischen Ursprungs; “Sohn des Timai”. Im NT ist Bartimai ein blinder Bettler, der von Jesus beim Auszug aus Jericho geheilt wird. Weitere Formen: Bartimäus',
            es3: '',
            es2: 'ܒܪܣܝܢ',
            es1: 'ܒܪܣܝܢ',
            dt2: '',
            ar3: '',
            dt1: 'Barsin',
            dt3: '',
            id: '1369'
        },
        {
            es3: '',
            ar3: '',
            ar1: 'ܐܒܠܐ',
            dt2: '',
            dt1: 'Eblo',
            dt3: '',
            ar2: 'ܐܶܒܠܳܐ',
            bedeutung: 'männl., “Gott schuf” oder “Gott des Berges”. Elgabal oder Elagabal (218-22), früher unter einem anderen Namen, übernahm später den Namen des Sonnengottes “Elagabal” und wurde Kaiser zu Rom',
            es1: 'ܐܒܠܐ',
            id: '1473',
            es2: 'ܐܒܠܐ'
        },
        {
            dt1: 'Lischmasch',
            id: '634',
            bedeutung: 'männl., “[er] hat mich bedient”',
            es1: 'ܠܝܫܡܫ',
            dt3: '',
            es2: 'ܠܝܫܡܫ',
            ar3: '',
            es3: '',
            ar1: 'ܠܝܫܡܫ',
            dt2: '',
            ar2: 'ܠܺܝܫܡܰܫ'
        },
        {
            es3: '',
            es2: 'ܐܪܡ ܓܒܪܐܝܠ',
            ar3: '',
            ar2: 'ܐܳܪܳܡ ܓܰܒܪܺܐܝܶܠ',
            id: '799',
            es1: 'ܐܪܡ ܓܒܪܐܝܠ',
            ar1: 'ܐܪܡ ܓܒܪܐܝܠ',
            dt3: '',
            bedeutung: '',
            dt1: 'Orom Gabriel',
            dt2: 'Aram Gabriel'
        },
        {
            ar1: 'ܨܡܘܐܝܠ',
            dt3: '',
            dt2: '',
            ar2: 'ܨܰܡܽܘܐܝܶܠ',
            id: '903',
            dt1: 'Samuel',
            bedeutung: 'männl., siehe Schmuel',
            es1: 'ܨܡܘܐܠ',
            es2: 'ܨܡܘܐܠ',
            ar3: 'ܫܡܘܐܝܠ',
            es3: 'ܨܡܘܐܝܠ'
        },
        {
            es3: '',
            es2: 'shah',
            ar3: '',
            dt1: 'Schah',
            dt3: '',
            ar2: 'ܫܰܐܗ',
            ar1: 'ܫܐܗ',
            dt2: '',
            id: '925',
            bedeutung: '',
            es1: 'shah'
        },
        {
            ar2: 'ܐܰܚܺܝܛܳܒ',
            es3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “mein Bruder ist gut“. Im AT ist Ahitob Enkel des Priesters Eli; Vater des Ahimelech I., Großvater des Abjatar. Weitere Formen: Ahitub',
            dt2: 'Ahitub',
            dt1: 'Ahitob',
            es2: 'ܐܚܝܛܒ',
            dt3: '',
            ar1: 'ܐܚܝܛܒ',
            id: '1237',
            es1: 'ܐܚܝܛܒ',
            ar3: ''
        },
        {
            es1: 'ܦܠܪܘܬܐ',
            dt1: 'Floruta',
            ar2: 'ܦܠܳܪܽܘܬܰܐ',
            dt2: '',
            id: '1529',
            es3: '',
            bedeutung: 'männl., “Auserwählter”; weitere Formen: Gabai, Gabbai',
            dt3: '',
            es2: 'ܦܠܪܘܬܐ',
            ar1: 'ܦܠܪܘܬܐ',
            ar3: ''
        },
        {
            ar2: 'ܫܶܬܶܐܣܬܰܐ',
            dt2: '',
            es1: 'ܫܬܐܣܬܐ',
            id: '2152',
            es2: 'ܫܬܐܣܬܐ',
            ar3: '',
            dt1: 'Schetesta',
            ar1: 'ܫܬܐܣܬܐ',
            bedeutung: 'weibl., “mein Name” oder “höre auf mich”. Eine Heilige (163 v.Chr.) in der syrisch-orthodoxen Kirche. Gedenktag: 1. August',
            dt3: '',
            es3: ''
        },
        {
            dt2: '',
            es1: 'ܝܩܪ',
            ar2: 'ܝܰܩܰܪ',
            dt3: '',
            ar1: 'ܝܩܪ',
            bedeutung: 'männl., “ehren, hochachten, bewundern, huldigen”; weitere Formen: Jaqar',
            es2: 'ܝܩܪ',
            dt1: 'Yaqar',
            id: '1128',
            ar3: '',
            es3: ''
        },
        {
            dt1: 'Ofarta',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “fruchtbar”. In der Bibel ist Ofarta/Efrata ursprünglicher Name für Bethlehem. Weitere Formen: Ephrata, Ofartho, Efrata',
            ar3: '',
            id: '795',
            es3: '',
            es1: 'ܐܦܪܬܐ',
            dt2: '',
            dt3: '',
            ar1: 'ܐܦܪܬܐ',
            es2: 'ܐܦܪܬܐ',
            ar2: 'ܐܳܦܰܪܬܰܐ'
        },
        {
            es3: '',
            dt2: '',
            ar1: 'ܐܦܪܝ',
            dt1: 'Afri',
            es1: 'ܐܦܪܝ',
            ar3: '',
            dt3: '',
            ar2: 'ܐܰܦܪܺܝ',
            id: '43',
            bedeutung: 'männl., “wurde fruchtbar”',
            es2: 'ܐܦܪܝ'
        },
        {
            ar1: 'ܪܡܐܝܠ',
            bedeutung: 'eigentlich männl., aber in der Geschichte weiblich belegt, “Lösegeld, Freilassung, Erlösung, Ankauf, Erwerb, Abfindungssumme”; weitere Formen: Sabina',
            ar3: '',
            ar2: 'ܪܳܡܐܺܝܠ',
            dt3: '',
            dt2: '',
            es3: '',
            dt1: 'Romil',
            es1: 'ܪܡܐܝܠ',
            id: '2061',
            es2: 'ܪܡܐܝܠ'
        },
        {
            ar2: 'ܚܰܢܰܢܝܶܫܽܘܥ',
            dt1: 'Hananjeschu',
            es1: 'ܚܢܢܝܫܘܥ',
            id: '1622',
            es3: 'ܚܢܢܝܫܘܥ',
            dt2: '',
            bedeutung: 'männl., syrischen Ursprungs; “Gnädiger, Barmherziger”',
            dt3: '',
            ar1: 'ܚܢܢܝܫܘܥ',
            ar3: 'ܚܢܳܢܝܶܫܽܘܥ',
            es2: 'ܚܢܢܝܫܘܥ'
        },
        {
            es3: '',
            bedeutung: 'männl., syrischen Ursprungs; “Stern, Planet”',
            id: '589',
            dt1: 'Kawkbo',
            ar2: 'ܟܰܘܟܒܳܐ',
            dt2: '',
            ar1: 'ܟܘܟܒܐ',
            es2: 'ܟܘܟܒܐ',
            dt3: '',
            ar3: '',
            es1: 'ܟܘܟܒܐ'
        },
        {
            bedeutung: 'männl., “schön, schöner Mann”. Im AT ist Schaﬁr eine Ortschaft',
            es3: '',
            dt3: '',
            es2: 'ܫܦܝܪ',
            ar1: 'ܫܦܝܪ',
            es1: 'ܫܦܝܪ',
            ar3: '',
            dt2: '',
            dt1: 'Schafir',
            id: '921',
            ar2: 'ܫܰܦܺܝܪ'
        },
        {
            es3: '',
            dt1: 'Katar',
            id: '1774',
            bedeutung: 'weibl., Killita ist ein Dorf in Turabdin; weitere Formen: Qillita, Killit',
            dt3: '',
            es2: 'ܟܬܪ',
            dt2: '',
            es1: 'ܟܬܪ',
            ar2: 'ܟܰܬܰܖ',
            ar3: '',
            ar1: 'ܟܬܖ'
        },
        {
            ar1: 'ܗܕܐܝܙܝ',
            es2: 'ܗܕܐܝܙܝ',
            ar2: 'ܗܰܕܰܐܺܝܙܺܝ',
            es3: '',
            dt2: '',
            dt1: 'Hadajisi',
            id: '1601',
            dt3: '',
            ar3: 'ܚܰܕܰܐܺܝܙܺܝ',
            bedeutung: 'weibl., “Leben, mein Leben”. Hahija ist ein Dorf in Turabdin; weitere Formen: Hah, Habhi',
            es1: 'ܗܕܐܝܙܝ'
        },
        {
            bedeutung: 'männl., “glauben, vertrauen”',
            ar1: 'ܐܪܦܟ݂ܫܕ',
            es2: 'ܐܪܦܟ݂ܫܕ',
            es1: 'ܐܪܦܟ݂ܫܕ',
            ar3: '',
            dt2: '',
            id: '1285',
            es3: '',
            dt3: '',
            ar2: 'ܐܰܪܦܰܟ݂ܫܰܕ',
            dt1: 'Arpachschad'
        },
        {
            es1: 'ܐܠܝܗܘ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “er ist mein Gott”. Im AT begegnet der Name Elihu als Ahnherr Samuels und als Bruder Davids',
            dt2: '',
            es2: 'ܐܠܝܗܘ',
            ar2: 'ܐܶܠܺܝܗܽܘ',
            ar3: '',
            dt1: 'Elihu',
            ar1: 'ܐܠܝܗܘ',
            id: '299',
            dt3: '',
            es3: ''
        },
        {
            dt1: 'Ramat',
            dt3: '',
            ar1: 'ܪܡܬ',
            id: '2032',
            dt2: '',
            es1: 'ܪܡܬ',
            es2: 'ܪܡܬ',
            ar2: 'ܪܰܡܰܐܬ',
            ar3: 'ܪܳܡܰܬ',
            es3: 'ܪܡܬ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “der Onkel hat mir Raum verschaffen” oder “das Volk hat sich ausgebreitet”. Im AT ist Rehabeam Sohn des Salomo, nach dem Tod des Vaters König von Juda (926-910 v.Chr.); nach dem NT ist Rehabeam ein Ahnherr Jesu. Weitere Formen: Rhebeam'
        },
        {
            es2: 'ܝܠܝܦ',
            dt1: 'Yalif',
            dt2: '',
            ar1: 'ܝܠܝܦ',
            es3: '',
            dt3: '',
            ar3: '',
            ar2: 'ܝܰܠܺܝܦ',
            bedeutung: 'männl., “Sehne, Muskel, Balken, Band”; weitere Formen: Jatro',
            id: '2314',
            es1: 'ܝܠܝܦ'
        },
        {
            bedeutung: 'männl., arabischen Ursprungs; “Sonntag”',
            es2: 'ܠܚܕܐ',
            dt1: 'Lahdo',
            ar2: 'ܠܰܚܕܳܐ',
            ar3: '',
            dt2: '',
            ar1: 'ܠܚܕܐ',
            id: '615',
            es1: 'ܠܚܕܐ',
            es3: '',
            dt3: ''
        },
        {
            es2: 'ܢܥܝܡܐ',
            dt2: 'Nayma',
            id: '743',
            es3: '',
            dt1: 'Naima',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “die Liebliche”. Im AT ist Naima die Schwiegermutter der Ruth. Weitere Formen: Naemi, Naema, Noomi, Naomi',
            ar2: 'ܢܰܥܺܝܡܰܐ',
            es1: 'ܢܥܝܡܐ',
            ar3: '',
            ar1: 'ܢܥܝܡܐ',
            dt3: ''
        },
        {
            ar3: '',
            es3: '',
            dt3: '',
            bedeutung: 'Nebenform zu Gabriel',
            dt1: 'Gebro',
            ar2: 'ܓܰܒܪܳܐ',
            es1: 'ܓܒܪܐ',
            ar1: 'ܓܒܪܐ',
            id: '371',
            es2: 'ܓܒܪܐ',
            dt2: 'Gabro'
        },
        {
            ar1: 'ܟܘܟܠܬܐ',
            dt1: 'Kukaltha',
            ar3: '',
            es1: 'ܟܘܟܠܬܐ',
            ar2: 'ܟܽܘܟܰܠܬܰܐ',
            bedeutung: 'weibl., syrischen Ursprungs; “Rührkuchen”; weitere Formen: Kukalta',
            es3: '',
            es2: 'ܟܘܟܠܬܐ',
            dt3: '',
            dt2: '',
            id: '607'
        },
        {
            es3: '',
            dt2: '',
            id: '1803',
            dt1: 'Lahdo',
            ar3: '',
            ar1: 'ܠܚܕܐ',
            dt3: '',
            bedeutung: 'weibl., “Mut, Tapferkeit”',
            es1: 'ܠܚܕܐ',
            es2: 'ܠܚܕܐ',
            ar2: 'ܠܰܚܕܳܐ'
        },
        {
            id: '1950',
            ar1: 'ܢܚܡܝܐ',
            es3: 'ܢܚܡܝܐ',
            bedeutung: '',
            dt3: '',
            dt1: 'Nehemia',
            ar2: 'ܢܶܚܰܡܝܰܐ',
            es2: 'ܢܚܡܝܐ',
            dt2: 'Nehemya',
            ar3: 'ܢܚܰܡܝܳܐ',
            es1: 'ܢܚܡܝܐ'
        },
        {
            es1: 'ܕܫܐ',
            ar2: 'ܕܳܫܳܐ',
            dt2: '',
            ar1: 'ܕܫܐ',
            es2: 'ܕܫܐ',
            dt3: '',
            ar3: '',
            id: '1466',
            dt1: 'Doscho',
            es3: '',
            bedeutung: 'männl., siehe Abedjeschu'
        },
        {
            ar2: 'ܥܰܬܺܝܪ',
            dt1: 'Atir',
            ar1: 'ܥܬܝܪ',
            es2: 'ܥܬܝܪ',
            ar3: '',
            es3: '',
            dt2: '',
            es1: 'ܥܬܝܪ',
            bedeutung: 'weibl., “Heimat, Ort, Gegend',
            dt3: '',
            id: '1302'
        },
        {
            es1: 'ܙܟܝ',
            ar2: 'ܙܰܟܰܝ',
            dt3: 'Sakai',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “der Reine, der Unschuldige”. Im AT ist Zakai ein Familienoberhaupt, dessen Söhne mit Serubbabel aus dem Exil heimkehren; im NT ist Zakai ein reicher Zöllner oder Obersteuereinnehmer in Jericho. Zakai als kleine Gestalt steigt auf einen Maulbeerfeigenbaum, um Jesus beim Einzug in Jericho besser zu sehen, wobei er Jesus mit Freuden in sein Haus aufnimmt und beteuert, in Zukunft seinen Besitz mit den Annen zu teilen. Jesus erklärt ihn im Gegensatz zur jüdischen Volksmeinung, die die Zöllner verabscheute, zum vollwertigen Mitglied der jüdischen Gesellschaft. Weitere Formen: Zakkai, Zakay, Zaki, Zeki, Zachäus',
            es2: 'ܙܟܝ',
            dt1: 'Zakai',
            es3: '',
            dt2: 'Zakay',
            id: '1155',
            ar1: 'ܙܟܝ',
            ar3: ''
        },
        {
            es3: '',
            ar3: '',
            es2: 'ܢܫܡܬܐ',
            ar1: 'ܢܫܡܬܐ',
            id: '775',
            dt1: 'Nschamta',
            dt3: '',
            es1: 'ܢܫܡܬܐ',
            dt2: '',
            bedeutung: 'weibl., “Atem, Atmung”; weitere Formen: Nschamtha',
            ar2: 'ܢܫܰܡܬܰܐ'
        },
        {
            dt1: 'Jehuschuah',
            ar2: 'ܝܶܗܽܘܫܳܥ',
            ar3: 'ܝܶܗܽܘܫܰܥ',
            id: '532',
            dt2: '',
            es3: 'ܝܗܘܫܥ',
            dt3: '',
            bedeutung: 'männl., siehe Josua',
            es2: 'ܝܗܘܫܥ',
            ar1: 'ܝܗܘܫܥ',
            es1: 'ܝܗܘܫܥ'
        },
        {
            dt3: '',
            es2: 'ܙܟ݂ܪܝܐ',
            ar1: 'ܙܟ݂ܪܝܐ',
            es1: 'ܙܟ݂ܪܝܐ',
            bedeutung: 'männl., “Zypergras, Schilfgras”; weitere Formen: Saado, Seedo',
            ar3: 'ܙܰܟ݂ܰܪܝܰܐ',
            dt2: 'Sacharija',
            dt1: 'Sacharia',
            id: '2075',
            es3: 'ܙܟ݂ܪܝܐ',
            ar2: 'ܙܟ݂ܰܪܝܰܐ'
        },
        {
            id: '442',
            dt1: 'Hanon',
            es2: 'ܚܢܢ',
            ar3: '',
            dt2: '',
            es1: 'ܚܢܢ',
            dt3: '',
            ar2: 'ܚܰܢܳܢ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe hat Gnade gewirkt”. Im NT ist Hanon/Hannas der Schwiegervater des Kajaphas zu Beginn des 1. Jh.; Hanon oder Hnana (510-544) war ostsyrischer Metropolit von Arbela. Weitere Formen: Hannas',
            ar1: 'ܚܢܢ',
            es3: ''
        },
        {
            id: '719',
            es2: 'ܡܘܫܐ',
            ar3: '',
            dt2: 'Musche',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Herauszieher”. Im AT ist Musche die führende Gestalt beim Auszug des Volkes Israel aus Ägypten. Weitere Formen: Moses, Moscheh, Musa',
            es3: '',
            dt3: '',
            ar2: 'ܡܽܘܫܶܐ',
            es1: 'ܡܘܫܐ',
            ar1: 'ܡܘܫܐ',
            dt1: 'Moses'
        },
        {
            dt3: '',
            es3: '',
            ar3: '',
            es1: 'ܟܪܝܢܐ',
            ar2: 'ܟܰܪܺܝܢܰܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs. Im AT ist Kemuel Sohn Nahors, des Bruders Abrahams, und Vater des Aram. Weitere Formen: Qemuel, Qemuyel',
            dt2: '',
            id: '1769',
            ar1: 'ܟܪܝܢܐ',
            dt1: 'Karina',
            es2: 'ܟܪܝܢܐ'
        },
        {
            es2: 'ܝܘܐܒ',
            es3: '',
            es1: 'ܝܘܐܒ',
            dt3: '',
            id: '550',
            dt2: '',
            dt1: 'Joab',
            ar3: '',
            ar2: 'ܝܽܘܐܳܒ',
            ar1: 'ܝܘܐܒ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe ist Vater”. Im AT ist Joab Sohn Zerujas, Bruder des Abischai und des Asael, Neffe Davids. Weitere Formen: Juob'
        },
        {
            id: '1377',
            es1: 'ܒܣܝܡ',
            dt2: 'Bassim',
            ar2: 'ܒܰܣܺܝܡ',
            dt1: 'Basim',
            es2: 'ܒܣܝܡ',
            dt3: '',
            bedeutung: 'männl., siehe Basim',
            ar3: 'ܒܰܣܣܺܝܡ',
            es3: 'ܒܣܣܝܡ',
            ar1: 'ܒܣܝܡ'
        },
        {
            ar2: 'ܡܰܠܶܐܟܺܝ',
            es3: '',
            ar1: 'ܡܠܐܟܝ',
            dt3: '',
            id: '650',
            dt1: 'Maleachi',
            es1: 'ܡܠܐܟܝ',
            es2: 'ܡܠܐܟܝ',
            ar3: '',
            dt2: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “mein Bote, mein Engel”. Im AT ist Maleachi ein Prophet (Mitte des 5. Jh. v. Chr.) und Verfasser eines Buches. Weitere Formen: Malachi, Malaki'
        },
        {
            bedeutung: 'männl. Form zu Athe',
            ar1: 'ܥܬܬܐ',
            es1: 'ܥܬܬܐ',
            ar3: '',
            es3: '',
            dt1: 'Atto',
            id: '126',
            ar2: 'ܥܰܬܬܳܐ',
            dt2: '',
            dt3: '',
            es2: 'ܥܬܬܐ'
        },
        {
            dt3: '',
            ar2: 'ܦܰܗܕܳܐ',
            id: '329',
            es2: 'ܦܗܕܐ',
            dt1: 'Fahdo',
            ar3: '',
            bedeutung: 'männl., “Panther, Leopard”',
            es3: '',
            dt2: '',
            es1: 'ܦܗܕܐ',
            ar1: 'ܦܗܕܐ'
        },
        {
            dt1: 'Habruta',
            es3: '',
            es2: 'ܚܒܪܘܬܐ',
            id: '1594',
            bedeutung: 'männl., syrischen Ursprungs; “Sonntag, ein Tag nach dem Schabtho/Sabbat, erster Tag in der Woche”. Hadbschabo ist ein Heiliger (4. Jh.); Festtag: 20. Juli. Weitere Formen: Hadbschaba, Huschabo, Huschaba, Hawschab',
            ar1: 'ܚܒܪܘܬܐ',
            ar2: 'ܚܰܒܪܽܘܬܰܐ',
            ar3: '',
            es1: 'ܚܒܪܘܬܐ',
            dt3: '',
            dt2: 'Habrutha'
        },
        {
            es3: '',
            ar1: 'ܐܘܡܬ݂ܐ',
            ar2: 'ܐܽܘܡܬ݂ܰܐ',
            dt1: 'Umtha',
            dt2: '',
            dt3: '',
            id: '1085',
            bedeutung: 'weibl., “Nation, Heimat”; weitere Formen: Umta',
            ar3: '',
            es1: 'ܐܘܡܬ݂ܐ',
            es2: 'ܐܘܡܬ݂ܐ'
        },
        {
            dt2: '',
            ar3: '',
            dt1: 'Osyo',
            id: '1992',
            bedeutung: 'männl., Pildasch ist nach dem AT Bruder Keseds, Kemuels und Betuels, Sohn Nahors. Weitere Formen: Palrasch, Pilrasch',
            es1: 'ܐܣܝܐ',
            ar1: 'ܐܣܝܐ',
            dt3: '',
            es2: 'ܐܣܝܐ',
            ar2: 'ܐܳܣܝܳܐ',
            es3: ''
        },
        {
            dt3: '',
            id: '2112',
            ar3: 'ܫܰܐܦܺܝ',
            ar2: 'ܫܰܦܺܝ',
            dt2: '',
            dt1: 'Schafi',
            ar1: 'ܫܦܝ',
            es3: 'ܫܐܦܝ',
            es2: 'ܫܦܝ',
            bedeutung: 'männl., “beenden”',
            es1: 'ܫܦܝ'
        },
        {
            dt2: '',
            ar1: 'ܙܝܬܐ',
            ar3: '',
            dt3: '',
            es1: 'ܙܝܬܐ',
            es3: '',
            bedeutung: 'männl., “Leuchte, Helligkeit, Leuchten, Glanz”, manchmal auch weiblich',
            dt1: 'Zayto',
            ar2: 'ܙܰܝܬܳܐ',
            es2: 'ܙܝܬܐ',
            id: '2354'
        },
        {
            bedeutung: 'männl., “Frieden”',
            es2: 'ܫܠܡܐ',
            es3: '',
            id: '967',
            dt2: '',
            ar1: 'ܫܠܡܐ',
            ar2: 'ܫܠܳܡܳܐ',
            ar3: '',
            dt1: 'Schlomo',
            es1: 'ܫܠܡܐ',
            dt3: ''
        },
        {
            es3: '',
            ar1: 'ܣܪܓܢ',
            ar3: '',
            ar2: 'ܣܰܪܓܳܢ',
            bedeutung: '',
            es2: 'ܣܪܓܢ',
            dt1: 'Sargon',
            es1: 'ܣܪܓܢ',
            id: '910',
            dt3: '',
            dt2: ''
        },
        {
            es3: '',
            ar1: 'ܡܪܝ ܛܝܡܬܐܘܣ ܫܡܘܐܝܠ ܐܩܛܫ',
            bedeutung: '',
            es2: 'ܡܪܝ ܛܝܡܬܐܘܣ ܫܡܘܐܝܠ ܐܩܛܫ',
            id: '715',
            dt1: 'Mor Timotheos Samuel Aktas',
            dt2: '',
            es1: 'ܡܪܝ ܛܝܡܬܐܘܣ ܫܡܘܐܝܠ ܐܩܛܫ',
            ar2: 'ܡܪܝ ܛܝܡܬܐܘܣ ܫܡܘܐܝܠ ܐܩܛܫ',
            dt3: '',
            ar3: ''
        },
        {
            es1: 'ܩܘܪܒܢܐ',
            ar3: '',
            dt1: 'Korbana',
            es2: 'ܩܘܪܒܢܐ',
            dt3: '',
            bedeutung: 'männl., aramäisch-hebräischer Ursprung; “Opfer”; weitere Formen: Qorban, Qurban, Qurbana, Kurbana, Kurbono, Qurbono',
            dt2: '',
            id: '604',
            es3: '',
            ar2: 'ܩܽܘܪܒܰܢܰܐ',
            ar1: 'ܩܘܪܒܢܐ'
        },
        {
            dt2: '',
            ar3: '',
            es1: 'ܝܗܘܫܥ',
            id: '1743',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gepriesene, Frau aus Jehud, Jüdin”. Judith ist die Hauptgestalt des nach Ihr benannten alttestamentlichen Buches. Weitere Formen: Judit, Jehudit',
            es3: '',
            es2: 'ܝܗܘܫܥ',
            ar1: 'ܝܗܘܫܥ',
            dt3: '',
            ar2: 'ܝܳܗܽܘܫܰܥ',
            dt1: 'Johuschuah'
        },
        {
            bedeutung: 'weibl., aramäische Prinzessin in Syrien (7. Jh. v. Chr.); weitere Formen: Nakija',
            dt1: 'Nahrin',
            id: '1928',
            ar2: 'ܢܰܗܪܺܝܢ',
            es2: 'ܢܗܪܝܢ',
            dt3: '',
            ar1: 'ܢܗܪܝܢ',
            es3: '',
            ar3: '',
            dt2: '',
            es1: 'ܢܗܪܝܢ'
        },
        {
            es2: 'ܥܬܪܬܗ',
            ar2: 'ܥܰܬܰܪܰܬܶܗ',
            es1: 'ܥܬܪܬܗ',
            es3: '',
            id: '115',
            dt1: 'Atarateh',
            dt2: '',
            dt3: '',
            bedeutung: 'weibl., aramäische Form des griechischen Atargatis, aus den beiden Götternamen Athtar und Anat zusammengesetzt. Atarateh war als “Dea Syria” eine syrisch-aramäische Hochgöttin der hellenistischen Zeit, wurde mit der Astarte/Ischtar gleichgesetzt',
            ar3: '',
            ar1: 'ܥܬܪܬܗ'
        },
        {
            id: '1830',
            es2: 'ܡܥܟܐ',
            dt1: 'Maacha',
            dt3: '',
            es3: 'ܡܥܟܐ',
            dt2: '',
            ar1: 'ܡܥܟܐ',
            ar3: 'ܡܰܥܟܳܐ',
            es1: 'ܡܥܟܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Könige”. Im NT sind Malki/Malke zwei Ahnherren Jesu. In der syrisch-orthodoxen Kirche von Antiochien ist Malke ein Heiliger (223-315); Festtag: 1. September',
            ar2: 'ܡܰܥܟܰܐ'
        },
        {
            es3: '',
            dt2: '',
            dt3: '',
            bedeutung: 'männl., „der König ist barmherzig“. Malakhanna war ein König der Aramäer von Hah in Turabdin',
            ar3: '',
            ar2: 'ܡܰܠܰܟܚܰܢܢܰܐ',
            id: '649',
            dt1: 'Malakhanna',
            es2: 'ܡܠܟܚܢܢܐ',
            es1: 'ܡܠܟܚܢܢܐ',
            ar1: 'ܡܠܟܚܢܢܐ'
        },
        {
            dt2: '',
            ar3: '',
            es3: '',
            dt3: '',
            ar2: 'ܡܺܝܠܺܝܕܝܰܐ',
            dt1: 'Milidja',
            es2: 'ܡܝܠܝܕܝܐ',
            es1: 'ܡܝܠܝܕܝܐ',
            bedeutung: 'weibl., Milidja ist eine frühere aramäische Stadt, sie heißt heute Malatya und liegt in der Türkei. Weitere Formen: Milidya, Militina, Melita',
            id: '703',
            ar1: 'ܡܝܠܝܕܝܐ'
        },
        {
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “mein Bruder ist erhaben”. Im AT ist Ahiram der dritte Sohn Benjamins',
            ar3: '',
            ar2: 'ܐܰܚܺܝܪܰܡ',
            ar1: 'ܐܚܝܪܡ',
            id: '1235',
            dt2: '',
            es1: 'ܐܚܝܪܡ',
            es2: 'ܐܚܝܪܡ',
            dt3: '',
            es3: '',
            dt1: 'Ahiram'
        },
        {
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Fürstin, Prinzessin, Herrin“. Im AT ist Sara die Frau Abrahams und Mutter Isaaks. Weitere Formen: Sarah, Sarai, Sare, Saro; sehr beliebt bei den Aramäern',
            ar3: 'ܣܰܐܪܰܐ',
            es1: 'ܣܐܪܐ',
            ar2: 'ܣܰܪܰܐ',
            dt1: 'Sara',
            id: '906',
            es2: 'ܣܐܪܐ',
            es3: 'ܣܐܪܐ',
            ar1: 'ܣܐܪܐ',
            dt2: 'Sarah',
            dt3: ''
        },
        {
            es3: '',
            bedeutung: 'männl., “Sohn des Gottes, Gottessohn”',
            ar2: 'ܒܰܪܚܽܘܒܳܐ',
            dt1: 'Barhubo',
            dt3: '',
            ar1: 'ܒܪܚܘܒܐ',
            es2: 'ܒܪܚܘܒܐ',
            id: '1346',
            dt2: '',
            es1: 'ܒܪܚܘܒܐ',
            ar3: ''
        },
        {
            bedeutung: '',
            ar3: '',
            es3: '',
            es2: 'ܓܢܬܐ',
            dt3: '',
            es1: 'ܓܢܬܐ',
            dt1: 'Ganta',
            id: '1552',
            dt2: '',
            ar2: 'ܓܰܢܬܰܐ',
            ar1: 'ܓܢܬܐ'
        },
        {
            id: '935',
            dt3: '',
            dt1: 'Schamar',
            es1: 'ܫܡܪ',
            ar2: 'ܫܰܡܰܪ',
            dt2: '',
            es2: 'ܫܡܪ',
            ar3: '',
            ar1: 'ܫܡܪ',
            bedeutung: 'männl., “senden, schicken, entlassen”',
            es3: ''
        },
        {
            es2: 'ܫܠܗܒ',
            es3: '',
            dt1: 'Schalheb',
            ar2: 'ܫܰܠܗܶܒ',
            dt3: '',
            es1: 'ܫܠܗܒ',
            ar3: '',
            id: '2122',
            dt2: '',
            ar1: 'ܫܠܗܒ',
            bedeutung: 'männl., “beenden, vollenden”'
        },
        {
            dt1: 'Orhan',
            ar2: 'ܐܳܪܗܰܢ',
            es2: 'ܐܪܗܢ',
            ar3: '',
            es1: 'ܐܪܗܢ',
            dt2: '',
            es3: '',
            bedeutung: 'weibl,. Form von Petrus',
            ar1: 'ܐܪܗܢ',
            id: '1989',
            dt3: ''
        },
        {
            dt2: '',
            ar2: 'ܛܰܒܰܐܶܝܠ',
            id: '1023',
            dt3: '',
            es1: 'ܛܒܐܝܠ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe/Gott ist gut”',
            es2: 'ܛܒܐܝܠ',
            dt1: 'Tabael',
            ar3: '',
            ar1: 'ܛܒܐܝܠ',
            es3: ''
        },
        {
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Knecht Jahwes”. Im AT ist Obadja ein Prophet (6. Jh. v. Chr.) und Verfasser eines Buches. Weitere Formen: Obadjo, Ubadjo',
            es3: '',
            dt2: '',
            ar1: 'ܥܘܒܕܝܐ',
            ar3: '',
            dt1: 'Obadja',
            dt3: '',
            id: '789',
            ar2: 'ܥܽܘܒܰܕܝܳܐ',
            es1: 'ܥܘܒܕܝܐ',
            es2: 'ܥܘܒܕܝܐ'
        },
        {
            dt2: '',
            ar1: 'ܐܢܟܝܕܐ',
            dt3: '',
            ar2: 'ܐܶܢܟܺܝܕܳܐ',
            ar3: 'ܐܰܢܟܺܝܕܰܐ',
            es2: 'ܐܢܟܝܕܐ',
            es1: 'ܐܢܟܝܕܐ',
            bedeutung: '',
            es3: 'ܐܢܟܝܕܐ',
            dt1: 'Enkido',
            id: '314'
        },
        {
            ar1: 'ܫܡܪ',
            ar3: '',
            dt3: '',
            id: '2127',
            es2: 'ܫܡܪ',
            dt2: '',
            ar2: 'ܫܰܡܰܪ',
            dt1: 'Schamar',
            es1: 'ܫܡܪ',
            es3: '',
            bedeutung: 'männl., “feiner Herr” oder“ Leben Gottes, Gottesleben” oder “jemand, der seine Hose anzieht” Heiliger aus Edessa (†115 n. Chr.)'
        },
        {
            ar1: 'ܡܟܣܐ',
            ar3: '',
            dt3: '',
            es1: 'ܡܟܣܐ',
            id: '1836',
            es3: '',
            dt1: 'Makso',
            ar2: 'ܡܰܟܣܳܐ',
            dt2: '',
            es2: 'ܡܟܣܐ',
            bedeutung: 'männl., syrischen Ursprungs; “Veilchen”; weitere Formen: Manischka'
        },
        {
            dt1: 'Togo',
            ar3: '',
            es2: 'ܬܓ݂ܐ',
            id: '2262',
            dt2: '',
            ar2: 'ܬܳܓ݂ܳܐ',
            bedeutung: '',
            es1: 'ܬܓ݂ܐ',
            ar1: 'ܬܓ݂ܐ',
            es3: '',
            dt3: ''
        },
        {
            es2: 'ܡܪܝ ܓܒܪܝܐܝܠ',
            ar2: 'ܡܪܝ ܓܒܪܝܐܝܠ',
            id: '1897',
            bedeutung: 'männl., “der Herr hat gesiegt”. Moranzkho (346-375) war ein ostsyrischer Bischof von Arbela. Weitere Formen: Maranzkha',
            es1: 'ܡܪܝ ܓܒܪܝܐܝܠ',
            dt3: '',
            dt2: 'St Gabriel',
            es3: '',
            ar1: 'ܡܪܝ ܓܒܪܝܐܝܠ',
            dt1: 'Mor Gabriel',
            ar3: ''
        },
        {
            id: '965',
            bedeutung: 'weibl., “die Ruhige, die Stille, Glückshaube”',
            es1: 'ܫܠܝܬܐ',
            es2: 'ܫܠܝܬܐ',
            dt3: '',
            ar3: '',
            ar1: 'ܫܠܝܬܐ',
            ar2: 'ܫܠܺܝܬܰܐ',
            dt2: '',
            es3: '',
            dt1: 'Schlita'
        },
        {
            es2: 'ܫܝܡ',
            dt3: '',
            es3: 'ܫܡ',
            ar1: 'ܫܝܡ',
            ar3: 'ܫܶܡ',
            es1: 'ܫܝܡ',
            dt1: 'Schim',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs. Im AT ist Schobach Oberbefehlshaber des aramäischen Königs von Aram-Damaskus Hadad-Eser (11. Jh. v.Chr.). Weitere Formen: Schuboch, Schbuch,',
            ar2: 'ܫܺܝܡ',
            dt2: 'Sem',
            id: '2153'
        },
        {
            dt3: '',
            es1: 'ܡܪܘܢ',
            id: '1867',
            ar2: 'ܡܰܪܽܘܢ',
            es3: 'ܡܪܘܢ',
            bedeutung: 'weibl., syrischen Ursprungs; “Wache, Obhut, Schutz, Station”',
            dt2: 'Morun',
            es2: 'ܡܪܘܢ',
            ar3: 'ܡܳܪܽܘܢ',
            dt1: 'Marun',
            ar1: 'ܡܪܘܢ'
        },
        {
            es3: '',
            bedeutung: 'männl., syrischen Ursprungs; wohl von Kurho: “Zelle, Kabine”; weitere Formen: Kurho',
            ar2: 'ܟܰܫܺܝܪ',
            dt2: '',
            dt3: '',
            dt1: 'Kaschir',
            es1: 'ܟܫܝܪ',
            id: '1772',
            es2: 'ܟܫܝܪ',
            ar3: '',
            ar1: 'ܟܫܝܪ'
        },
        {
            ar1: 'ܡܠܐܟܝ',
            es2: 'ܡܠܐܟܝ',
            es3: '',
            ar3: '',
            bedeutung: 'weibl. Namensform von Manuel',
            dt3: '',
            es1: 'ܡܠܐܟܝ',
            dt1: 'Maleachi',
            ar2: 'ܡܰܠܶܐܟܺܝ',
            dt2: '',
            id: '1838'
        },
        {
            dt1: 'Tabrimmon',
            es2: 'ܛܒܪܝܡܘܢ',
            dt2: '',
            id: '2218',
            es1: 'ܛܒܪܝܡܘܢ',
            ar1: 'ܛܒܪܝܡܘܢ',
            dt3: '',
            ar3: 'ܛܒܰܪܰܐܡܽܘܢ',
            es3: 'ܛܒܪܐܡܘܢ',
            ar2: 'ܛܰܒܪܺܝܡܳܘܢ',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Dattelpalme, Palmenbaum”. Im AT ist Tamar(a) die Schwester Abschaloms, Tochter Davids und der Maacha. Weitere Formen: Tomor'
        },
        {
            es2: 'ܪܒܘܢܝ',
            es1: 'ܪܒܘܢܝ',
            dt2: '',
            id: '831',
            ar3: '',
            es3: '',
            bedeutung: 'männl., “mein Herr, Lehrer, Meister, Rabbi, Rabi”, weitere Formen: Rabbuni, Rabbuli',
            dt3: '',
            ar1: 'ܪܒܘܢܝ',
            dt1: 'Rabuni',
            ar2: 'ܪܰܒܽܘܢܺܝ'
        },
        {
            bedeutung: 'weibl., “Betrunkenheit, Rausch”; weitere Formen: Rauyutha, Rawyuta',
            id: '844',
            ar3: '',
            dt3: '',
            ar2: 'ܪܰܘܝܽܘܬܰܐ',
            es1: 'ܪܘܝܘܬܐ',
            ar1: 'ܪܘܝܘܬܐ',
            es2: 'ܪܘܝܘܬܐ',
            es3: '',
            dt1: 'Rauyuta',
            dt2: ''
        },
        {
            ar2: 'ܐܶܠܰܓܒܰܠ',
            dt1: 'Elgabal',
            id: '1479',
            es1: 'ܐܠܓܒܠ',
            es3: '',
            ar3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “mein Gott ist König”; weitere Formen: Elimelech, Elimelekh',
            es2: 'ܐܠܓܒܠ',
            ar1: 'ܐܠܓܒܠ',
            dt3: '',
            dt2: ''
        },
        {
            dt1: 'Cyrillus',
            bedeutung: 'männl., Daisan (gr. Skirtos) war ein Fluß in Edessa. Weitere Formen: Daison, Dayson, Daysan',
            dt2: '',
            id: '1427',
            es2: 'ܩܘܪܝܠܠܘܣ',
            ar3: '',
            ar1: 'ܩܘܪܝܠܠܘܣ',
            ar2: 'ܩܽܘܪܺܝܠܠܳܘܣ',
            es1: 'ܩܘܪܝܠܠܘܣ',
            dt3: '',
            es3: ''
        },
        {
            es3: '',
            dt2: '',
            ar3: '',
            ar1: 'ܓܡܪܝܐ',
            dt1: 'Gemarja',
            id: '375',
            dt3: '',
            ar2: 'ܓܶܡܰܪܝܰܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe hat zum Ziel geführt/ erfüllt”',
            es1: 'ܓܡܪܝܐ',
            es2: 'ܓܡܪܝܐ'
        },
        {
            ar3: '',
            ar1: 'ܨܦܝܬܐ',
            dt1: 'Sfita',
            ar2: 'ܨܦܝܺܬܰܐ',
            es2: 'ܨܦܝܬܐ',
            es1: 'ܨܦܝܬܐ',
            id: '991',
            dt3: '',
            dt2: '',
            bedeutung: 'weibl., “reine, nette [Frau]”',
            es3: ''
        },
        {
            bedeutung: 'weibl., Izala ist eine Landschaﬁ in Aram Beth-Nahrin (= Mesopotamien), Teil von Turabdin. Weitere Formen: Izla',
            dt2: 'Isla',
            ar3: '',
            ar2: 'ܐܺܝܙܠܰܐ',
            es1: 'ܐܝܙܠܐ',
            id: '517',
            ar1: 'ܐܝܙܠܐ',
            es2: 'ܐܝܙܠܐ',
            dt3: '',
            es3: '',
            dt1: 'Izla'
        },
        {
            bedeutung: 'männl., aus dem Griechischen übernommener Vorname; “der Wachsame”; einer der Beinamen für syrische Bischöfe. Weitere Formen: Gregor',
            es2: 'ܓܡܝܪܢ',
            ar3: '',
            id: '1574',
            es1: 'ܓܡܝܪܢ',
            dt1: 'Gmiron',
            ar2: 'ܓܡܺܝܪܳܢ',
            es3: '',
            ar1: 'ܓܡܝܪܢ',
            dt2: '',
            dt3: ''
        },
        {
            ar1: 'ܥܙܝܪܐ',
            bedeutung: 'männl., “Mächtiger, Starker” oder “Liebling” männl., “mein Vater”. Babai (5_Jh.) war Katliolikos der syrisch-nestorianischen Kirche. Weitere Formen: Baboi, Bobi',
            dt1: 'Aziro',
            es2: 'ܥܙܝܪܐ',
            dt2: '',
            ar3: '',
            id: '1317',
            es3: '',
            es1: 'ܥܙܝܪܐ',
            dt3: '',
            ar2: 'ܥܰܙܺܝܪܳܐ'
        },
        {
            ar3: 'ܟܰܦܦܰܪܰܐ',
            ar2: 'ܓܰܒܒܰܪܰܐ',
            es1: 'ܓܒܒܪܐ',
            es2: 'ܓܒܒܪܐ',
            id: '352',
            bedeutung: 'männl., “der Tapfere, der Mannhafte, Herold, Held”. Gabbara oder Gabbar war ein aramäischer König (9. Jh. v.Chr.) von Guzana (=Tell Halaf). Weitere Formen: Gabara, Kappara',
            dt1: 'Gabbara',
            dt2: '',
            es3: 'ܟܦܦܪܐ',
            ar1: 'ܓܒܒܪܐ',
            dt3: ''
        },
        {
            dt2: 'Yonathan',
            bedeutung: 'männl., Nebenform zu Julius',
            es2: 'ܝܘܢܘܢ',
            ar1: 'ܝܘܢܘܢ',
            es3: 'ܝܘܢܬܢ',
            dt1: 'Jonathan',
            ar2: 'ܝܳܘܢܰܬܰܢ',
            ar3: 'ܝܽܘܢܳܬܳܢ',
            dt3: '',
            es1: 'ܝܘܢܘܢ',
            id: '1745'
        },
        {
            dt1: 'Berula',
            bedeutung: 'weibl., “frohe Botschaft, gute Nachricht”; weitere Formen: Beschra',
            es3: '',
            ar1: 'ܒܪܘܠܐ',
            ar2: 'ܒܶܪܽܘܠܰܐ',
            dt2: '',
            ar3: '',
            id: '1403',
            es1: 'ܒܪܘܠܐ',
            es2: 'ܒܪܘܠܐ',
            dt3: ''
        },
        {
            dt2: '',
            es1: 'ܚܦܣܝ',
            id: '445',
            ar2: 'ܚܰܦܣܰܝ',
            dt1: 'Haphsai',
            ar3: '',
            es3: '',
            ar1: 'ܚܦܣܝ',
            dt3: '',
            bedeutung: 'männl., weitere Formen: Haphsay, Hafsai, Hafsay',
            es2: 'ܚܦܣܝ'
        },
        {
            dt1: 'Hahija',
            dt3: '',
            id: '1609',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “mein Schwager ist Tau”',
            es3: '',
            es2: 'ܚܐܚܝܐ',
            es1: 'ܚܐܚܝܐ',
            dt2: '',
            ar2: 'ܚܰܒܚܺܝ',
            ar3: '',
            ar1: 'ܚܐܚܝܐ'
        },
        {
            dt1: 'Hahija',
            es3: '',
            bedeutung: 'weibl., “Leben, mein Leben”. Hahija ist ein Dorf in Turabdin; weitere Formen: Hah, Habhi',
            dt3: '',
            es2: 'ܚܐܚܝܐ',
            dt2: '',
            es1: 'ܚܐܚܝܐ',
            ar3: '',
            id: '422',
            ar2: 'ܚܰܒܚܺܝ',
            ar1: 'ܚܐܚܝܐ'
        },
        {
            dt2: 'Nebukadnezzar',
            ar1: 'ܢܒܘܟ݂ܕܢܨܪ',
            dt1: 'Nabochadnasar',
            ar3: '',
            es3: '',
            dt3: '',
            es1: 'ܢܒܘܟ݂ܕܢܨܪ',
            ar2: 'ܢܰܒܽܘܟ݂ܰܕܢܰܨܰܪ',
            id: '1919',
            es2: 'ܢܒܘܟ݂ܕܢܨܪ',
            bedeutung: 'weibl., “Flüsse, Ströme”'
        },
        {
            id: '1549',
            ar2: 'ܓܰܐܠܺܝ',
            es2: 'ܓܐܠܝ',
            es3: '',
            dt1: 'Gali',
            bedeutung: 'weibl., “Auserwählte, Erwählte”; weitere Formen: Gbitha',
            dt3: '',
            ar1: 'ܓܐܠܝ',
            dt2: '',
            ar3: '',
            es1: 'ܓܐܠܝ'
        },
        {
            ar3: '',
            bedeutung: 'männl., siehe Jesaja',
            dt2: '',
            id: '320',
            ar1: 'ܐܫܥܝܐ',
            es3: '',
            dt1: 'Eschahjo',
            dt3: '',
            es1: 'ܐܫܥܝܐ',
            es2: 'ܐܫܥܝܐ',
            ar2: 'ܐܶܫܰܥܝܳܐ'
        },
        {
            ar2: 'ܕܰܡܺܝ',
            dt3: '',
            ar1: 'ܕܡܝ',
            id: '1435',
            es2: 'ܕܡܝ',
            ar3: '',
            es3: '',
            dt1: 'Dami',
            dt2: '',
            bedeutung: 'männl., “Aufgang, Sonnenaufgang, Erscheinung”. Das Fest der “Denho/Danho” (gr. “Epiphanie”) am 6. Januar: Erscheinung bzw. Taufe Jesu Christi am Jordan',
            es1: 'ܕܡܝ'
        },
        {
            es2: 'ܐܒܐ',
            ar3: '',
            id: '1202',
            dt1: 'Abo',
            dt3: '',
            es3: '',
            ar1: 'ܐܒܐ',
            dt2: '',
            es1: 'ܐܒܐ',
            bedeutung: '',
            ar2: 'ܐܰܒܳܐ'
        },
        {
            es2: 'ܠܥܙܪ',
            dt3: '',
            dt1: 'Laazar',
            es3: 'ܐܠܝܥܙܪ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “eleasar: Gott ist Helfer, Gott hilft, Gott hat geholfen”. Im NT weckte Jesus den toten Laazar nach drei Tagen ins Leben. Weitere Formen: Eleasar, Lazar, Lazarus, Eliozor, Eliasar',
            es1: 'ܠܥܙܪ',
            ar1: 'ܠܥܙܪ',
            ar2: 'ܠܰܥܰܙܰܖ',
            ar3: 'ܐܶܠܺܝܥܳܙܳܪ',
            id: '612',
            dt2: ''
        },
        {
            ar3: '',
            id: '1762',
            es2: 'ܟܠܡܘ',
            ar1: 'ܟܠܡܘ',
            ar2: 'ܟܰܠܰܡܽܘ',
            dt3: '',
            bedeutung: 'weibl., syrischen Ursprungs; “Traurigkeit, Trübsal”; weitere Formen: Karyutha',
            es1: 'ܟܠܡܘ',
            dt1: 'Kalamu',
            dt2: '',
            es3: ''
        },
        {
            dt1: 'Dionysos',
            es1: 'ܕܝܘܢܢܘܣܝܘܣ',
            es2: 'ܕܝܘܢܢܘܣܝܘܣ',
            ar1: 'ܕܝܘܢܢܘܣܝܘܣ',
            ar2: 'ܕܺܝܳܘܢܢܽܘܣܺܝܽܘܣ',
            id: '1458',
            es3: '',
            bedeutung: 'weibl., Kurzform zu Theodora',
            ar3: '',
            dt3: 'Dionysius',
            dt2: 'Dionosyius'
        },
        {
            ar2: 'ܒܶܕܪܳܘܣ',
            ar3: '',
            ar1: 'ܒܕܪܘܣ',
            es2: 'ܒܕܪܘܣ',
            bedeutung: 'männl., siehe Petrus',
            id: '213',
            es3: '',
            es1: 'ܒܕܪܘܣ',
            dt2: 'Budrus',
            dt3: '',
            dt1: 'Bedrus'
        },
        {
            ar3: 'ܫܰܡܺܝܪܰܡ',
            bedeutung: 'männl., aus dem Lateinischen “Sergius: altröm. Sippenname”; in der Kirchengeschichte hat es einen Heiligen unter dem Namen Sarkis/Sergius (3./4. Jh.) gegeben. Weitere Formen: Sergius, Sargis',
            dt2: 'Schamiram',
            es1: 'ܫܡܝܪܡ',
            es3: 'ܫܡܝܪܡ',
            ar2: 'ܫܰܡܺܝܪܰܐܡ',
            dt1: 'Samiram',
            id: '2092',
            dt3: '',
            es2: 'ܫܡܝܪܡ',
            ar1: 'ܫܡܝܪܐܡ'
        },
        {
            ar3: '',
            ar2: 'ܒܰܪܰܐܟ݂ܶܝܠ',
            dt1: 'Barachel',
            dt2: '',
            es2: 'ܒܪܐܟ݂ܝܠ',
            id: '1337',
            dt3: '',
            ar1: 'ܒܪܐܟ݂ܝܠ',
            bedeutung: 'männl., “du bist mein Sohn”',
            es1: 'ܒܪܐܟ݂ܝܠ',
            es3: ''
        },
        {
            dt1: 'Bar-Ido',
            ar1: 'ܒܪܥܐܕܐ',
            id: '1347',
            ar2: 'ܒܰܪܥܺܐܕܳܐ',
            dt3: '',
            es2: 'ܒܪܥܐܕܐ',
            es3: '',
            dt2: 'Barido',
            ar3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäischen Ursprungs; “Sohn des Jonas“, “Sohn der Taube”. Im NT ist Barjawno Beiname des Simon Petrus',
            es1: 'ܒܪܥܐܕܐ'
        },
        {
            bedeutung: '',
            dt3: '',
            ar1: 'ܙܝܘܐ',
            dt2: '',
            es3: '',
            es2: 'ܙܝܘܐ',
            dt1: 'Ziwo',
            ar2: 'ܙܺܝܘܳܐ',
            ar3: '',
            es1: 'ܙܝܘܐ',
            id: '2363'
        },
        {
            id: '397',
            bedeutung: '',
            dt1: 'Gürbüz',
            es3: '',
            dt3: '',
            es2: 'ܓܘܪܒܘܙ',
            es1: 'ܓܘܪܒܘܙ',
            ar2: 'ܓܽܘܪܒܽܘܙ',
            ar1: 'ܓܘܪܒܘܙ',
            ar3: '',
            dt2: ''
        },
        {
            es1: 'ܚܫܒܝܐ',
            ar2: 'ܚܰܫܰܒܝܰܐ',
            id: '1636',
            dt1: 'Haschabja',
            ar3: '',
            dt2: '',
            es3: '',
            bedeutung: 'weibl., kurdischen Urspıungs; “Trauer, Kummer, die Traurige”',
            dt3: '',
            es2: 'ܚܫܒܝܐ',
            ar1: 'ܚܫܒܝܐ'
        },
        {
            id: '683',
            dt2: 'Marjam',
            dt3: 'Mirjam',
            ar1: 'ܡܪܝܡ',
            es2: 'ܡܪܝܡ',
            ar3: 'ܡܺܝܪܝܰܡ',
            es3: 'ܡܝܪܝܡ',
            ar2: 'ܡܰܪܝܰܡ',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Herrin, Seherin“, „widerspenstig”. Maria ist im NT die Mutter Jesu Christi, ein sehr beliebter Vorname bei den Aramäern. Weitere Formen: Mariam, Miryam, Mirjam, Maria, Marija',
            dt1: 'Maryam',
            es1: 'ܡܪܝܡ'
        },
        {
            dt1: 'Ithutha',
            dt2: '',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Antilope”. Im AT ist Jael Frau des Keniters Heber',
            dt3: '',
            ar2: 'ܐܺܝܬܽܘܬܳܐ',
            es3: '',
            es2: 'ܐܝܬܘܬܐ',
            es1: 'ܐܝܬܘܬܐ',
            id: '1702',
            ar1: 'ܐܝܬܘܬܐ',
            ar3: ''
        },
        {
            id: '2177',
            bedeutung: '',
            ar3: 'ܣܡܰܟ݂ܝܰܐ',
            es3: 'ܣܡܟ݂ܝܐ',
            dt3: '',
            es2: 'ܣܡܟ݂ܝܐ',
            es1: 'ܣܡܟ݂ܝܐ',
            dt1: 'Semachja',
            ar2: 'ܣܡܰܟ݂ܝܳܐ',
            dt2: '',
            ar1: 'ܣܡܟ݂ܝܐ'
        },
        {
            ar1: 'ܦܝܠܝܦܘܣ',
            es2: 'ܦܝܠܝܦܘܣ',
            dt2: '',
            ar2: 'ܦܺܝܠܺܝܦܳܘܣ',
            bedeutung: 'männl., “Jesus ist auferstanden”',
            dt1: 'Phillippos',
            dt3: '',
            es3: 'ܦܝܠܝܦܘܣ',
            es1: 'ܦܝܠܝܦܘܣ',
            ar3: 'ܦܺܝܠܺܝܦܽܘܣ',
            id: '2000'
        },
        {
            dt2: '',
            es3: '',
            ar3: '',
            id: '1109',
            es2: 'ܝܒܒ',
            ar2: 'ܝܰܒܶܒ',
            dt3: '',
            ar1: 'ܝܒܒ',
            bedeutung: 'männl., “loben, lobsingen”; weitere Formen: Jabeb',
            es1: 'ܝܒܒ',
            dt1: 'Yabeb'
        },
        {
            ar1: 'ܫܘܒܟ',
            es1: 'ܫܘܒܟ',
            es2: 'ܫܘܒܟ',
            es3: '',
            bedeutung: 'männl., Kommandant von Hadadeser (11. Jh. v. Chr.); weitere Formen: Schobach',
            id: '1000',
            dt3: '',
            ar3: '',
            dt2: '',
            ar2: 'ܫܽܘܒܰܟ',
            dt1: 'Sobak'
        },
        {
            dt2: '',
            id: '1222',
            ar3: '',
            es3: '',
            es2: 'ܥܦܪܘܢ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs. Im AT ist Afron Sohn des Zohar, des Hethiters. Weitere Formen: Afrun',
            es1: 'ܥܦܪܘܢ',
            dt1: 'Afron',
            dt3: '',
            ar2: 'ܥܰܦܪܳܘܢ',
            ar1: 'ܥܦܪܘܢ'
        },
        {
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “er gebe weiten Raum”. Im AT ist Jafet Sohn Noahs, Bruder Sems. Die Bewohner Kleinasiens und der Ägäis werden laut AT auf Jafet zuıückgeﬁJhrt. Weitere Formen: Jaft',
            dt2: '',
            es2: 'ܝܦܬ',
            dt1: 'Jafet',
            id: '524',
            es3: 'ܝܦܬ',
            ar2: 'ܝܰܦܶܬ',
            ar1: 'ܝܦܬ',
            dt3: '',
            es1: 'ܝܦܬ',
            ar3: 'ܝܰܦܬ'
        },
        {
            es2: 'ܐܝܠܢ',
            dt1: 'Ilon',
            ar3: '',
            es3: '',
            ar2: 'ܐܺܝܠܳܢ',
            dt2: '',
            es1: 'ܐܝܠܢ',
            dt3: '',
            bedeutung: 'männl., “Baum”',
            id: '500',
            ar1: 'ܐܝܠܢ'
        },
        {
            dt2: '',
            id: '1853',
            ar1: 'ܡܪܓܐ',
            es2: 'ܡܪܓܐ',
            dt3: '',
            ar2: 'ܡܰܪܓܳܐ',
            es1: 'ܡܪܓܐ',
            bedeutung: '',
            ar3: '',
            dt1: 'Margo',
            es3: ''
        },
        {
            bedeutung: 'männl., “Zöllner, Kassierer, Steuereinnehmer”',
            ar2: 'ܡܶܪܕܺܝܢܰܐ',
            id: '1886',
            dt1: 'Merdina',
            es3: 'ܡܪܕܝܢ',
            ar1: 'ܡܪܕܝܢܐ',
            dt2: '',
            es1: 'ܡܪܕܝܢܐ',
            es2: 'ܡܪܕܝܢܐ',
            ar3: 'ܡܶܪܕܺܝܢ',
            dt3: ''
        },
        {
            es3: '',
            bedeutung: 'männl., “unser Herr”',
            es2: 'ܩܝܡܝ',
            dt3: '',
            dt1: 'Qaimi',
            dt2: '',
            ar3: '',
            ar1: 'ܩܝܡܝ',
            es1: 'ܩܝܡܝ',
            id: '2004',
            ar2: 'ܩܰܝܡܺܝ'
        },
        {
            id: '1250',
            dt1: 'Aman',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott ist mit uns”',
            es1: 'ܥܡܢ',
            dt2: '',
            es2: 'ܥܡܢ',
            ar2: 'ܥܰܡܰܢ',
            ar1: 'ܥܡܢ',
            dt3: '',
            es3: '',
            ar3: ''
        },
        {
            id: '995',
            ar3: 'ܣܝܡܘܢ',
            bedeutung: '',
            dt2: 'Simyon',
            dt3: 'Semun',
            es3: 'ܣܝܡܝܘܢ',
            ar2: 'ܫܡܥܘܢ',
            es1: 'ܫܡܥܘܢ',
            dt1: 'Simon',
            es2: 'ܫܡܥܘܢ',
            ar1: 'ܫܡܥܘܢ'
        },
        {
            ar2: 'ܚܰܬܬܰܐܢܰܐ',
            dt3: '',
            es3: '',
            ar3: '',
            dt2: '',
            es1: 'ܚܬܬܐܢܐ',
            id: '1640',
            ar1: 'ܚܬܬܐܢܐ',
            bedeutung: 'männl., syrischen Ursprungs; “Glorie, Herrlichkeit, Glanz, Pracht”',
            es2: 'ܚܬܬܐܢܐ',
            dt1: 'Hattana'
        },
        {
            es1: 'ܡܬܝܬܐ',
            ar3: 'ܡܶܕܝܰܕ',
            bedeutung: 'weibl., “Land, Heimat“. Matiate ist eine Stadt in Turabdin. Weitere Formen: Matiyate, Medyad',
            es2: 'ܡܬܝܬܐ',
            es3: 'ܡܕܝܕ',
            dt1: 'Matiate',
            ar1: 'ܡܬܝܬܐ',
            dt3: '',
            ar2: 'ܡܰܬܺܝܰܬܶܗ',
            id: '691',
            dt2: 'Medyad'
        },
        {
            id: '1442',
            dt1: 'Darijo',
            ar3: 'ܕܰܪܺܝܐܝܳܐ',
            dt3: '',
            ar1: 'ܕܪܝܝܐ',
            es3: 'ܕܪܝܐܝܐ',
            es2: 'ܕܪܝܝܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “der Geliebte, Vielgeliebter, Befehlshaber”, Im AT ist König David der Besieger des Goliath und Gründer des jüdischen Staates (ca. 1004-03-965/64 v.Chr.) ',
            dt2: '',
            ar2: 'ܕܰܪܺܝܝܳܐ',
            es1: 'ܕܪܝܝܐ'
        },
        {
            bedeutung: 'männl., “Palme”',
            es1: 'ܕܩܠܐ',
            ar2: 'ܕܶܩܠܳܐ',
            dt3: '',
            es3: '',
            ar3: '',
            dt1: 'Deqlo',
            dt2: '',
            id: '270',
            es2: 'ܕܩܠܐ',
            ar1: 'ܕܩܠܐ'
        },
        {
            es3: '',
            ar3: '',
            dt3: '',
            dt2: '',
            id: '797',
            bedeutung: '',
            ar2: 'ܐܳܪܗܰܢ',
            es1: 'ܐܪܗܢ',
            es2: 'ܐܪܗܢ',
            dt1: 'Orhan',
            ar1: 'ܐܪܗܢ'
        },
        {
            es3: 'ܨܪܘܢܐ',
            dt3: '',
            bedeutung: 'weibl., Saruna war eine Stadt in Alt-Syrien',
            es2: 'ܣܪܘܢܐ',
            es1: 'ܣܪܘܢܐ',
            dt1: 'Saruna',
            ar1: 'ܣܪܘܢܐ',
            id: '914',
            ar3: 'ܨܰܪܽܘܢܰܐ',
            dt2: '',
            ar2: 'ܣܰܪܽܘܢܰܐ'
        },
        {
            ar2: 'ܐܰܘܓܺܝܢ',
            dt3: '',
            ar1: 'ܐܘܓܝܢ',
            id: '1308',
            es1: 'ܐܘܓܝܢ',
            es3: '',
            dt1: 'Augin',
            es2: 'ܐܘܓܝܢ',
            bedeutung: 'männl., siehe Okit',
            dt2: 'Awgin',
            ar3: ''
        },
        {
            bedeutung: 'männl., aramäischer Vorname; “Sohn des Hadad”; Ben-Hadad I. (oder Bar Hadad) war Sohn des Tabrimmon, des Sohnes des Hesjon, König von Aram-Damaskus (um 900 v.Chr.). Ben-Hadad II., König von Aram-Damaskus, war Sohn und Nachfolger des Ben-Hadad I, (um 875 v.Chr.), Ben-Hadad III. Sohn des Hasael, König von Aram-Damaskus (um 797-773)',
            dt3: '',
            es1: 'ܒܠܫܨܪ',
            id: '1395',
            ar1: 'ܒܠܫܨܪ',
            dt2: '',
            dt1: 'Belschassar',
            ar3: '',
            ar2: 'ܒܶܠܫܰܨܰܪ',
            es2: 'ܒܠܫܨܪ',
            es3: ''
        },
        {
            ar1: 'ܡܬܝܐ',
            id: '1878',
            es2: 'ܡܬܝܐ',
            dt1: 'Matia',
            dt2: '',
            dt3: '',
            es1: 'ܡܬܝܐ',
            ar3: '',
            ar2: 'ܡܰܬܺܝܰܐ',
            bedeutung: 'männl., syrischen Ursprungs; “Wegweiser, Führer, Leiter, Verwalter”',
            es3: ''
        },
        {
            dt1: 'Elgabal',
            es1: 'ܐܠܓܒܠ',
            dt3: '',
            es2: 'ܐܠܓܒܠ',
            bedeutung: 'männl., “Gott schuf” oder “Gott des Berges”. Elgabal oder Elagabal (218-22), früher unter einem anderen Namen, übernahm später den Namen des Sonnengottes “Elagabal” und wurde Kaiser zu Rom',
            dt2: '',
            ar2: 'ܐܶܠܰܓܒܰܠ',
            es3: '',
            id: '295',
            ar1: 'ܐܠܓܒܠ',
            ar3: ''
        },
        {
            id: '686',
            ar2: 'ܡܰܙܪܰܐ',
            bedeutung: 'männl., “der Kräftige, der Dicke”. Mazra war Bischof von Beth-Zabhdai (Hazakh-Idil, Anfang 2. Jh.). Weitere Formen: Mozro, Masra',
            dt3: '',
            ar3: '',
            dt2: 'Mazra',
            dt1: 'Masra',
            ar1: 'ܡܙܪܐ',
            es1: 'ܡܙܪܐ',
            es3: '',
            es2: 'ܡܙܪܐ'
        },
        {
            dt3: '',
            bedeutung: 'männl., “Sohn des Reiters”. Barrakib war ein König der Aramäer von Sam`al-Jadi (732-720 V. Chr.), Sohn von Panammu II. (743-732)',
            ar3: '',
            ar2: 'ܒܰܪܪܰܟܺܝܒ',
            dt1: 'Barrakib',
            es1: 'ܒܪܪܟܝܒ',
            id: '181',
            ar1: 'ܒܪܪܟܝܒ',
            es3: '',
            dt2: '',
            es2: 'ܒܪܪܟܝܒ'
        },
        {
            es2: 'ܓܝܕܪܝܐ',
            ar3: 'ܓܺܝܕܰܪܰܐ',
            es3: 'ܓܝܕܪܐ',
            ar1: 'ܓܝܕܪܝܐ',
            dt1: 'Gidarja',
            dt3: '',
            ar2: 'ܓܺܝܕܰܪܝܰܐ',
            bedeutung: 'weibl., Gidarja war eine Stadt in Alt-Syrien; weitere Formen: Gidarya, Gidara',
            id: '382',
            es1: 'ܓܝܕܪܝܐ',
            dt2: ''
        },
        {
            ar1: 'ܐܚܝܩܡ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “mein Bruder steht aufrecht”; weitere Formen: Ahiqam',
            ar3: '',
            ar2: 'ܐܰܚܺܝܩܰܡ',
            es3: '',
            id: '55',
            dt1: 'Ahikam',
            dt2: 'Ahiqam',
            es2: 'ܐܚܝܩܡ',
            es1: 'ܐܚܝܩܡ',
            dt3: ''
        },
        {
            es3: '',
            dt3: '',
            ar1: 'ܐܒܐ',
            ar2: 'ܐܰܒܰܐ',
            es2: 'ܐܒܐ',
            dt2: '',
            id: '2',
            bedeutung: 'männl., “Vater”;  weitere Formen:  Abo',
            dt1: 'Aba',
            ar3: '',
            es1: 'ܐܒܐ'
        },
        {
            ar1: 'ܙܗܝܐ',
            dt3: '',
            dt2: '',
            ar3: '',
            id: '1153',
            es2: 'ܙܗܝܐ',
            ar2: 'ܙܰܗܝܳܐ',
            es3: '',
            dt1: 'Zahyo',
            es1: 'ܙܗܝܐ',
            bedeutung: 'männl., “glänzend, ausgezeichnet, schön, Edelmann“'
        },
        {
            dt1: 'Yuqanto',
            bedeutung: 'männl., “Licht, Strahl, Leuchte, Pracht, Glanz”',
            ar3: '',
            es2: 'ܝܘܩܢܬܐ',
            es1: 'ܝܘܩܢܬܐ',
            es3: '',
            id: '2334',
            ar2: 'ܝܽܘܩܰܢܬܳܐ',
            dt2: '',
            ar1: 'ܝܘܩܢܬܐ',
            dt3: ''
        },
        {
            ar3: '',
            dt2: '',
            es1: 'ܝܘܠܝܐ',
            es2: 'ܝܘܠܝܐ',
            id: '565',
            dt3: '',
            ar1: 'ܝܘܠܝܐ',
            es3: '',
            dt1: 'Julia',
            ar2: 'ܝܽܘܠܝܰܐ',
            bedeutung: ''
        },
        {
            es1: 'ܡܪܝ ܓܒܪܝܐܝܠ',
            id: '708',
            bedeutung: '',
            dt2: 'St Gabriel',
            dt1: 'Mor Gabriel',
            es2: 'ܡܪܝ ܓܒܪܝܐܝܠ',
            ar1: 'ܡܪܝ ܓܒܪܝܐܝܠ',
            dt3: '',
            ar3: '',
            es3: '',
            ar2: 'ܡܪܝ ܓܒܪܝܐܝܠ'
        },
        {
            es2: 'ܥܒܕܐܠܚܕ',
            ar2: 'ܥܰܒܶܕܰܐܠܰܚܰܕ',
            ar3: '',
            es3: '',
            dt2: '',
            id: '1187',
            bedeutung: 'männl., syrisch-arabischen Ursprungs; “Knecht des Sonntags”;  weitere Formen: Ablahad',
            dt3: '',
            dt1: 'Abdullahad',
            es1: 'ܥܒܕܐܠܚܕ',
            ar1: 'ܥܒܕܐܠܚܕ'
        },
        {
            es2: 'ܒܢܝܐ',
            ar1: 'ܒܢܝܐ',
            dt3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe hat geschaffen”',
            dt2: '',
            ar3: '',
            es3: '',
            ar2: 'ܒܶܢܰܝܰܐ',
            dt1: 'Benaja',
            id: '1399',
            es1: 'ܒܢܝܐ'
        },
        {
            id: '1420',
            bedeutung: '',
            es2: 'ܒܘܙ',
            es3: '',
            ar1: 'ܒܘܙ',
            dt3: '',
            dt2: 'Bus',
            dt1: 'Buz',
            es1: 'ܒܘܙ',
            ar3: '',
            ar2: 'ܒܽܘܙ'
        },
        {
            dt2: '',
            es2: 'ܬܝܢܐ',
            id: '2252',
            ar2: 'ܬܺܝܢܰܐ',
            bedeutung: 'männl., “du bist meine Krone”',
            es1: 'ܬܝܢܐ',
            dt3: '',
            ar3: '',
            ar1: 'ܬܝܢܐ',
            dt1: 'Tina',
            es3: ''
        },
        {
            es1: 'ܦܐܝܘܬܐ',
            es2: 'ܦܐܝܘܬܐ',
            dt1: 'Fayuta',
            es3: '',
            id: '337',
            bedeutung: 'weibl., “Schönheit, Eleganz, Pracht”; weitere Formen: Payuta, Payutha',
            ar2: 'ܦܰܐܝܽܘܬܰܐ',
            dt2: '',
            ar1: 'ܦܐܝܘܬܐ',
            dt3: '',
            ar3: ''
        },
        {
            dt1: 'Zenobia',
            es3: '',
            ar1: 'ܙܢܘܒܝܐ',
            dt3: '',
            bedeutung: '',
            es2: 'ܙܢܘܒܝܐ',
            ar2: 'ܙܶܢܳܘܒܺܝܰܐ',
            es1: 'ܙܢܘܒܝܐ',
            id: '2359',
            ar3: '',
            dt2: ''
        },
        {
            bedeutung: 'männl., Absamja war Schwestersohn des Mor Ephräm; weitere Formen: Absamjo, Absamyo, Absamya',
            es2: 'ܐܒܣܡܝܐ',
            ar2: 'ܐܰܒܣܰܡܝܰܐ',
            ar1: 'ܐܒܣܡܝܐ',
            id: '1208',
            es1: 'ܐܒܣܡܝܐ',
            dt1: 'Absamja',
            es3: '',
            dt2: '',
            dt3: '',
            ar3: ''
        },
        {
            dt2: '',
            es1: 'ܡܓܕܠܢܐ',
            dt1: 'Magdalena',
            ar1: 'ܡܓ݂ܕܠܢܐ',
            dt3: '',
            id: '644',
            es3: 'ܡܓܕܠܐ',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “die aus Magdala Stammende”, ”Turm“. Im NT ist Maria Magdalena eine der treuesten Anhängerinnen Jesu. Weitere Formen: Magedlayta, Magedloyto',
            ar2: 'ܡܰܓ݂ܕܰܠܰܐ',
            es2: 'ܡܓܕܠܢܐ',
            ar3: 'ܡܰܓ݂ܶܕܠܰܐ'
        },
        {
            bedeutung: '',
            dt2: '',
            dt3: '',
            es2: 'ܐܕܫܐ',
            es1: 'ܐܕܫܐ',
            ar2: 'ܐܰܕܶܫܰܐ',
            es3: '',
            ar1: 'ܐܕܫܐ',
            dt1: 'Adescha',
            ar3: '',
            id: '1213'
        },
        {
            ar1: 'ܝܫܘܥ',
            es1: 'ܝܫܘܥ',
            es2: 'ܝܫܘܥ',
            dt3: '',
            bedeutung: 'weibl., Izala ist eine Landschaﬁ in Aram Beth-Nahrin (= Mesopotamien), Teil von Turabdin. Weitere Formen: Izla',
            ar3: '',
            ar2: 'ܝܶܫܽܘܥ',
            dt2: '',
            id: '1696',
            dt1: 'Ischo',
            es3: ''
        },
        {
            es3: 'ܐܝܕܝܢ',
            dt1: 'Aydin',
            es1: 'ܐܝܕܐܝܢ',
            ar2: 'ܐܰܝܕܐܺܝܢ',
            bedeutung: 'weibl., “Gazelle, Hirsch, Reh”',
            dt2: '',
            ar1: 'ܐܝܕܐܝܢ',
            dt3: '',
            es2: 'ܐܝܕܐܝܢ',
            id: '1313',
            ar3: 'ܐܰܝܕܺܝܢ'
        },
        {
            dt2: '',
            bedeutung: '',
            es3: '',
            id: '695',
            es2: 'ܡܠܝܛܐ',
            ar2: 'ܡܶܠܺܝܛܰܐ',
            dt3: '',
            dt1: 'Melita',
            es1: 'ܡܠܝܛܐ',
            ar3: '',
            ar1: 'ܡܠܝܛܐ'
        },
        {
            ar1: 'ܫܡܥܘܢ',
            ar3: 'ܣܝܡܘܢ',
            es3: 'ܣܝܡܝܘܢ',
            dt3: 'Semun',
            dt2: 'Simyon',
            ar2: 'ܫܡܥܘܢ',
            es2: 'ܫܡܥܘܢ',
            bedeutung: '',
            dt1: 'Simon',
            id: '2187',
            es1: 'ܫܡܥܘܢ'
        },
        {
            id: '2173',
            dt3: '',
            ar3: '',
            es2: 'ܫܘܫܢܐ',
            ar1: 'ܫܘܫܢܐ',
            bedeutung: 'männl., aus dem lat. “severus: ernsthaft, streng”; in der Kirchengeschichte ist Sevire Patriarch (†538/42) der syrisch-orthodoxen Kirche von Antiochien, der sich energisch für den orthodoxen Glauben einsetzte. Festtag: 20. Februar. Weitere Formen: Severus, Severios',
            dt1: 'Schuschane',
            es3: '',
            ar2: 'ܫܽܘܫܰܢܰܐ',
            es1: 'ܫܘܫܢܐ',
            dt2: ''
        },
        {
            es1: 'ܚܢܢܝܫܘܥ',
            dt2: '',
            ar1: 'ܚܢܢܝܫܘܥ',
            dt3: '',
            es2: 'ܚܢܢܝܫܘܥ',
            es3: '',
            id: '1655',
            bedeutung: 'männl., “der Gegebene”. Hiba (†457), ein Anhänger des syrisch-nestorianischen Glaubens, war Bischof von Edessa (Urhoy) und Nachfolger des Rabula. Weitere Formen: Iba, Hibai, Hibo, Yihibo, Yihiba',
            ar2: 'ܚܢܳܢܝܶܫܽܘܥ',
            dt1: 'Henonjeschu',
            ar3: ''
        },
        {
            bedeutung: 'weibl., “Glanzvolle, Berühmte, Glorreiche, Gefeierte”',
            es2: 'ܬܡܢܝܐ',
            dt1: 'Tamania',
            id: '2225',
            ar2: 'ܬܰܡܰܢܝܰܐ',
            es1: 'ܬܡܢܝܐ',
            ar1: 'ܬܡܢܝܐ',
            dt2: '',
            ar3: '',
            es3: '',
            dt3: ''
        },
        {
            ar3: 'ܟܪܺܝܣܬܳܦܳܪܽܘܣ',
            id: '1426',
            ar2: 'ܟܪܺܝܣܛܳܦܳܪܽܘܣ',
            dt1: 'Christophorus',
            ar1: 'ܟܪܝܣܛܦܪܘܣ',
            es1: 'ܟܪܝܣܛܦܪܘܣ',
            dt3: '',
            es3: 'ܟܪܣܬܦܪܘܣ',
            es2: 'ܟܪܝܣܛܦܪܘܣ',
            dt2: 'Christoforus',
            bedeutung: 'männl., “führen, leiten, verwalten”'
        },
        {
            es1: 'ܣܬܐ',
            ar1: 'ܣܬܐ',
            es2: 'ܣܬܐ',
            ar2: 'ܣܬܐ',
            ar3: '',
            bedeutung: 'männl., “das Kreuz hat gesiegt”',
            dt2: 'Sitto',
            dt3: '',
            es3: '',
            dt1: 'Setto',
            id: '2181'
        },
        {
            ar3: '',
            bedeutung: 'männl., chaldäisch-aramäischer König von Babylonien (559-556 v.Chr.); weitere Formen: Niriglassar',
            ar2: 'ܢܰܐܬܰܢ',
            dt3: '',
            dt1: 'Natan',
            es1: 'ܢܐܬܢ',
            es3: '',
            ar1: 'ܢܐܬܢ',
            dt2: 'Nathan',
            id: '1945',
            es2: 'ܢܐܬܢ'
        },
        {
            dt1: 'Tamanita',
            es1: 'ܬܡܢܝܬܐ',
            bedeutung: 'weibl., “Nagel, Klaue, Kralle, Tatze, Pranke”; weitere Formen: Tufra',
            dt3: '',
            id: '2226',
            es2: 'ܬܡܢܝܬܐ',
            ar1: 'ܬܡܢܝܬܐ',
            dt2: '',
            ar3: '',
            es3: '',
            ar2: 'ܬܰܡܰܢܺܝܬܰܐ'
        },
        {
            es3: '',
            dt2: '',
            es2: 'ܢܬܢܐܝܠ',
            bedeutung: 'männl., “du bist mein Adler”',
            es1: 'ܢܬܢܐܝܠ',
            ar2: 'ܢܰܬܰܢܰܐܝܶܠ',
            dt1: 'Nathanael',
            ar1: 'ܢܬܢܐܝܠ',
            ar3: '',
            dt3: '',
            id: '1946'
        },
        {
            es3: '',
            dt3: '',
            dt2: '',
            es2: 'ܡܗܕܝܢܐ',
            ar3: '',
            dt1: 'Mhadyono',
            bedeutung: 'männl., syrischen Ursprungs; “Wegweiser, Führer, Leiter, Verwalter”',
            ar1: 'ܡܗܕܝܢܐ',
            ar2: 'ܡܗܰܕܝܳܢܳܐ',
            id: '698',
            es1: 'ܡܗܕܝܢܐ'
        },
        {
            es1: 'ܒܠܬܝ',
            id: '216',
            es2: 'ܒܠܬܝ',
            es3: '',
            ar2: 'ܒܶܠܬܺܝ',
            bedeutung: 'weibl., “Venus, Morgenstern” oder “Kupfer”; weitere Formen: Bilati',
            dt3: '',
            ar3: '',
            dt1: 'Belti',
            dt2: '',
            ar1: 'ܒܠܬܝ'
        },
        {
            es3: '',
            es1: 'ܫܡܫ',
            bedeutung: 'weibl. Form von Scharbel',
            ar3: '',
            dt2: '',
            dt1: 'Schamasch',
            ar1: 'ܫܡܫ',
            ar2: 'ܫܰܡܰܫ',
            id: '2128',
            dt3: '',
            es2: 'ܫܡܫ'
        },
        {
            ar3: '',
            dt3: '',
            es1: 'ܫܘܒܟ',
            ar2: 'ܫܽܘܒܰܟ',
            ar1: 'ܫܘܒܟ',
            bedeutung: 'männl., Nebenform zu Stephanus',
            dt1: 'Sobak',
            dt2: '',
            id: '2192',
            es2: 'ܫܘܒܟ',
            es3: ''
        },
        {
            dt3: '',
            id: '1459',
            es3: '',
            ar2: 'ܕܺܝܽܘܣܩܳܘܪܳܘܣ',
            es2: 'ܕܝܘܣܩܘܪܘܣ',
            ar1: 'ܕܝܘܣܩܘܪܘܣ',
            dt2: 'Dioskorus',
            ar3: '',
            bedeutung: 'männl., “Geschenk, Gabe”',
            dt1: 'Dioskouros',
            es1: 'ܕܝܘܣܩܘܪܘܣ'
        },
        {
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäischen Ursprungs; syrisch-aramäische Göttin, deren Kult aus Hamat nach Samarien eingeführt wurde',
            es3: '',
            ar3: '',
            dt3: '',
            ar2: 'ܐܰܫܰܪ',
            dt2: '',
            ar1: 'ܐܫܪ',
            dt1: 'Aschar',
            es2: 'ܐܫܪ',
            id: '1287',
            es1: 'ܐܫܪ'
        },
        {
            dt1: 'Wolitha',
            es2: 'ܘܠܝܬ݂ܐ',
            bedeutung: 'männl., “Gemeinschaft, Verbundenheit, Gruppe, Menge”; weitere Formen: Yahla, Jahlo, Jahla',
            dt3: '',
            ar1: 'ܘܠܝܬ݂ܐ',
            id: '2300',
            es1: 'ܘܠܝܬ݂ܐ',
            es3: '',
            ar2: 'ܘܳܠܺܝܬ݂ܰܐ',
            dt2: '',
            ar3: ''
        },
        {
            id: '155',
            es1: 'ܒܪܥܐܕܐ',
            ar2: 'ܒܰܪܥܺܐܕܳܐ',
            dt3: '',
            ar3: '',
            bedeutung: '',
            es3: '',
            ar1: 'ܒܪܥܐܕܐ',
            es2: 'ܒܪܥܐܕܐ',
            dt2: '',
            dt1: 'Bar Ido'
        },
        {
            dt3: '',
            ar3: 'ܚܰܒܣܽܘܢܳܐ',
            ar1: 'ܚܒܨܘܢܐ',
            dt1: 'Habsuno',
            dt2: 'Habzuno',
            id: '1595',
            bedeutung: 'männl., syrischen Ursprungs; “leiten, führen, wegweisen, unterweisen”',
            es1: 'ܚܒܨܘܢܐ',
            es3: 'ܚܒܣܘܢܐ',
            ar2: 'ܚܰܒܨܽܘܢܳܐ',
            es2: 'ܚܒܨܘܢܐ'
        },
        {
            ar1: 'ܝܐܝܠ',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Antilope”. Im AT ist Jael Frau des Keniters Heber',
            dt1: 'Jael',
            id: '523',
            ar2: 'ܝܰܐܝܶܠ',
            dt3: '',
            dt2: '',
            es2: 'ܝܐܝܠ',
            es1: 'ܝܐܝܠ',
            es3: '',
            ar3: ''
        },
        {
            ar3: '',
            es1: 'ܣܡܝܪܐ',
            dt3: '',
            dt2: '',
            dt1: 'Samira',
            es2: 'ܣܡܝܪܐ',
            bedeutung: '',
            ar2: 'ܣܰܡܺܝܪܰܐ',
            ar1: 'ܣܡܝܪܐ',
            es3: '',
            id: '899'
        },
        {
            es2: 'ܦܗܡܝ',
            dt2: '',
            ar1: 'ܦܗܡܝ',
            ar3: '',
            id: '1516',
            es3: '',
            dt1: 'Fahmi',
            ar2: 'ܦܰܗܡܺܝ',
            dt3: '',
            bedeutung: 'weibl., Kurzform zu Fabronya',
            es1: 'ܦܗܡܝ'
        },
        {
            dt3: '',
            dt2: '',
            bedeutung: '',
            ar1: 'ܙܟܝ ܝܘܣܦ',
            dt1: 'Zeki Joseph',
            es1: 'ܙܟܝ ܝܘܣܦ',
            es2: 'ܙܟܝ ܝܘܣܦ',
            es3: '',
            ar2: 'ܙܰܟܺܝ ܝܰܘܣܶܦ',
            id: '2358',
            ar3: ''
        },
        {
            es2: 'ܐܪܦܟ݂ܫܕ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs. Im AT ist Arpachschad Sohn des Sem und Bruder des Aram; im NT ist er Ahnherr Jesu. Die Arpachschäer, die auch Chaldäer heißen, gehören zum aramäischen Stamm',
            id: '105',
            ar2: 'ܐܰܪܦܰܟ݂ܫܰܕ',
            dt2: '',
            es3: '',
            ar3: '',
            dt1: 'Arpachschad',
            dt3: '',
            es1: 'ܐܪܦܟ݂ܫܕ',
            ar1: 'ܐܪܦܟ݂ܫܕ'
        },
        {
            es3: '',
            dt3: '',
            es2: 'ܡܕܢܚܐ',
            dt1: 'Madenho',
            id: '1831',
            ar1: 'ܡܕܢܚܐ',
            dt2: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “mein König ist erhaben”. Im AT ist Malkiram Sohn des Königs Jojachin',
            ar3: '',
            es1: 'ܡܕܢܚܐ',
            ar2: 'ܡܰܕܶܢܚܳܐ'
        },
        {
            ar3: '',
            dt2: '',
            bedeutung: '',
            id: '512',
            dt1: 'Isler',
            ar1: 'ܐܝܫܠܪ',
            es3: '',
            ar2: 'ܐܝܫܠܪ',
            es2: 'ܐܝܫܠܪ',
            es1: 'ܐܝܫܠܪ',
            dt3: ''
        },
        {
            ar1: 'ܟܢܪܐ',
            bedeutung: '',
            dt1: 'Kinora',
            ar3: '',
            ar2: 'ܟܶܢܳܪܰܐ',
            id: '2368',
            dt3: '',
            es3: '',
            dt2: '',
            es1: 'ܟܢܪܐ',
            es2: 'ܟܢܪܐ'
        },
        {
            dt3: '',
            bedeutung: 'weibl., ”Haus, Ort, Land der Hoffnung“. Basibrina ist ein Dorf in Turabdin. Weitere Formen: Bethsibirina, Betsbirina',
            es3: '',
            id: '196',
            ar2: 'ܒܶܝܬܣܒܺܝܪܺܝܢܰܐ',
            dt2: '',
            dt1: 'Basibrina',
            es2: 'ܒܣܝܒܪܝܢܐ',
            es1: 'ܒܣܝܒܪܝܢܐ',
            ar1: 'ܒܣܝܒܪܝܢܐ',
            ar3: ''
        },
        {
            dt3: '',
            dt2: '',
            ar1: 'ܚܒܚܝ',
            ar2: 'ܚܰܒܚܺܝ',
            dt1: 'Habhi',
            es3: '',
            id: '401',
            ar3: '',
            es1: 'ܚܒܚܝ',
            bedeutung: 'weibl., “Leben, mein Leben”. Ein Dorf in Turabdin; Weitere Formen: Hahija, Habhija',
            es2: 'ܚܒܚܝ'
        },
        {
            ar3: '',
            ar2: 'ܐܰܒܪܳܡ',
            es3: '',
            dt3: '',
            bedeutung: '',
            ar1: 'ܐܒܪܡ',
            dt2: '',
            es1: 'ܐܒܪܡ',
            es2: 'ܐܒܪܡ',
            dt1: 'Abrom',
            id: '30'
        },
        {
            dt1: 'Ahab',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Bruder des Vaters”. Im AT ist Ahab König des Nordreiches Israel (871-852 v.Chr.). Weitere Formen: Ohob',
            es1: 'ܐܚܒ',
            ar1: 'ܐܚܒ',
            es2: 'ܐܚܒ',
            id: '1227',
            dt2: '',
            ar2: 'ܐܰܚܰܒ',
            es3: 'ܐܚܒ',
            dt3: '',
            ar3: 'ܐܳܚܳܒ'
        },
        {
            dt1: 'Mor Julius Yeshu Cicek',
            ar2: 'ܡܪܝ ܝܘܠܝܘܣ ܝܫܘܥ ܓܝܓܟ',
            dt3: '',
            bedeutung: 'männl., syrischen Ursprungs; °“Wegweiser, Führer, Direktor”',
            es2: 'ܡܪܝ ܝܘܠܝܘܣ ܝܫܘܥ ܓܝܓܟ',
            es1: 'ܡܪܝ ܝܘܠܝܘܣ ܝܫܘܥ ܓܝܓܟ',
            es3: '',
            ar1: 'ܡܪܝ ܝܘܠܝܘܣ ܝܫܘܥ ܓܝܓܟ',
            ar3: '',
            dt2: '',
            id: '1901'
        },
        {
            ar3: '',
            dt3: '',
            bedeutung: 'männl., ”Honig“; weitere Formen: Dawscho, Dauscho',
            ar1: 'ܕܫܐ',
            ar2: 'ܕܳܫܳܐ',
            dt2: '',
            es3: '',
            dt1: 'Doscho',
            es1: 'ܕܫܐ',
            es2: 'ܕܫܐ',
            id: '282'
        },
        {
            dt2: '',
            bedeutung: 'männl., Kosod (Kosor) oder Kesed ist im AT Sohn Nahors, des Bruders Abrahams. Nach einigen Wissenschaftlern ist Kesed der Stammvater der Chaldäer und somit leitet sich ein aramäischer Stamm von ihm her. Weitere Formen: Kosor, Kesed',
            id: '1784',
            dt3: '',
            dt1: 'Kimuno',
            es2: 'ܟܐܡܘܢܐ',
            es3: '',
            ar3: '',
            es1: 'ܟܐܡܘܢܐ',
            ar2: 'ܟܺܐܡܽܘܢܳܐ',
            ar1: 'ܟܐܡܘܢܐ'
        },
        {
            dt2: '',
            bedeutung: 'männl., “Begeisterung, Exaltation, Lob, Erhöhung, Verherrlichung”',
            es3: '',
            ar3: '',
            dt1: 'Rumromo',
            id: '872',
            es1: 'ܪܘܡܪܡܐ',
            es2: 'ܪܘܡܪܡܐ',
            ar1: 'ܪܘܡܪܡܐ',
            ar2: 'ܪܽܘܡܪܳܡܳܐ',
            dt3: ''
        },
        {
            bedeutung: '',
            ar2: 'ܠܰܡܥܰܐ',
            id: '621',
            dt1: 'Lamha',
            dt2: '',
            es1: 'ܠܡܥܐ',
            es2: 'ܠܡܥܐ',
            ar3: '',
            es3: '',
            dt3: '',
            ar1: 'ܠܡܥܐ'
        },
        {
            es1: 'ܫܡܘܢܝ',
            es3: '',
            ar2: 'ܫܡܽܘܢܺܝ',
            ar1: 'ܫܡܘܢܝ',
            es2: 'ܫܡܘܢܝ',
            id: '2161',
            dt2: 'Schmouni',
            ar3: '',
            dt1: 'Schmuni',
            dt3: '',
            bedeutung: 'männl., Nebenform von schumtha “Wunde”'
        },
        {
            ar1: 'ܚܟܝܡܘܬܐ',
            es3: '',
            ar2: 'ܚܰܟܺܝܡܽܘܬܰܐ',
            ar3: '',
            es1: 'ܚܟܝܡܘܬܐ',
            id: '425',
            dt1: 'Hakimuta',
            bedeutung: 'weibl., syrischen Ursprungs; “Weisheit, Genialität”; weitere Formen: Hakimutha',
            dt3: '',
            dt2: '',
            es2: 'ܚܟܝܡܘܬܐ'
        },
        {
            dt3: '',
            ar2: 'ܣܰܪܦܺܝܳܘܢ',
            es1: 'ܣܪܦܝܘܢ',
            dt1: 'Sarafiyon',
            es3: '',
            dt2: '',
            es2: 'ܣܪܦܝܘܢ',
            ar1: 'ܣܪܦܝܘܢ',
            id: '907',
            bedeutung: 'männl., “Seraph, Seraphim, Engelschar”',
            ar3: ''
        },
        {
            es2: 'ܪܠܦ',
            es3: 'ܪܐܠܦ',
            ar1: 'ܪܠܦ',
            ar2: 'ܪܰܠܦ',
            ar3: 'ܪܰܐܠܦ',
            id: '839',
            bedeutung: '',
            dt2: 'Ralph',
            dt3: '',
            dt1: 'Ralf',
            es1: 'ܪܠܦ'
        },
        {
            es3: '',
            ar2: 'ܨܶܦܬܰܐ',
            ar1: 'ܨܦܬܐ',
            dt2: '',
            es1: 'ܨܦܬܐ',
            bedeutung: 'weibl., “Matte, Sorge, Besorgtheit, Ornament, Interesse”; weitere Formen: Seftha',
            ar3: '',
            dt3: '',
            id: '984',
            es2: 'ܨܦܬܐ',
            dt1: 'Sefta'
        },
        {
            ar2: 'ܝܰܦܬܰܚ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “[Gott] öffnet, befreit”; weitere Formen: Jeftah, Naftah',
            id: '548',
            es1: 'ܝܦܬܚ',
            ar3: 'ܢܰܦܬܰܚ',
            ar1: 'ܝܦܬܚ',
            dt3: 'Jeftah',
            dt1: 'Jiftach',
            es2: 'ܝܦܬܚ',
            es3: 'ܢܦܬܚ',
            dt2: 'Naftah'
        },
        {
            ar3: '',
            dt1: 'Osyo',
            ar2: 'ܐܳܣܝܳܐ',
            dt3: '',
            es1: 'ܐܣܝܐ',
            ar1: 'ܐܣܝܐ',
            id: '800',
            es3: '',
            es2: 'ܐܣܝܐ',
            bedeutung: 'männl., “Arzt, Doktor, Heiler”',
            dt2: ''
        },
        {
            es3: '',
            id: '306',
            es2: 'ܐܠܝܕܥ',
            dt2: '',
            es1: 'ܐܠܝܕܥ',
            dt3: '',
            ar2: 'ܐܶܠܝܰܕܰܥ',
            ar3: '',
            ar1: 'ܐܠܝܕܥ',
            bedeutung: 'männl., aramäischen Ursprungs; “Gott weiß“. In der Geschichte war Eljada Vater des aramäischen Königs Reson von Aram-Damaskus (10. Jh. v.Chr.)',
            dt1: 'Eljada'
        },
        {
            dt3: 'Addai',
            dt1: 'Adai',
            es2: 'ܐܕܝ',
            bedeutung: 'männl., aus der Bibel übernommener Vomame aramäisch-hebräischen Ursprungs; “der Mutige”. Adai ist einer der 70 bzw. 72 Missionare und gilt in der Traditon als der Patron der Aramäer bzw. Christusverkündiger unter den Aramäem Aram Beth-Nahrins (= Mesopotamien). Weitere Formen: Addai, Taddai, Thaddäus',
            dt2: 'Adday',
            es3: '',
            ar1: 'ܐܕܝ',
            id: '33',
            ar2: 'ܐܰܕܰܝ',
            ar3: '',
            es1: 'ܐܕܝ'
        },
        {
            ar2: 'ܓܰܒܐܺܝܠ',
            dt1: 'Gabil',
            bedeutung: 'männl., “Seite/Partei Gottes” oder “die Nähe zu Gott”',
            ar3: '',
            es3: '',
            id: '354',
            es2: 'ܓܒܐܝܠ',
            ar1: 'ܓܒܐܝܠ',
            dt2: '',
            es1: 'ܓܒܐܝܠ',
            dt3: ''
        },
        {
            es3: '',
            bedeutung: 'weibl., “die Unschuldige, die Vollkommene”',
            ar3: '',
            es1: 'ܬܕܝ',
            ar2: 'ܬܰܕܰܝ',
            ar1: 'ܬܕܝ',
            dt2: '',
            dt1: 'Taddai',
            id: '2219',
            dt3: '',
            es2: 'ܬܕܝ'
        },
        {
            id: '778',
            dt1: 'Nuhrat',
            dt2: '',
            ar1: 'ܢܘܗܪܬ',
            es3: '',
            bedeutung: 'männl., “du bist mein Licht”',
            es2: 'ܢܘܗܪܬ',
            es1: 'ܢܘܗܪܬ',
            ar2: 'ܢܽܘܗܪܰܬ',
            dt3: '',
            ar3: ''
        },
        {
            ar2: 'ܐܺܝܪܦܰܢ',
            es2: 'ܐܝܪܦܢ',
            dt3: '',
            es3: '',
            ar3: '',
            bedeutung: '',
            ar1: 'ܐܝܪܦܢ',
            dt1: 'Irfan',
            id: '1691',
            es1: 'ܐܝܪܦܢ',
            dt2: ''
        },
        {
            es2: 'ܒܟܝܪ',
            dt2: '',
            dt3: '',
            ar2: 'ܒܰܟܺܝܪ',
            ar3: '',
            bedeutung: 'männl., “Herde, Rudel”. In der Geschichte hat es mehrere Könige von Edessa unter diesem Namen gegeben; weitere Formen: Bakro, Baqra, Baqro',
            dt1: 'Bakir',
            ar1: 'ܒܟܝܪ',
            es3: '',
            id: '1325',
            es1: 'ܒܟܝܪ'
        },
        {
            ar3: '',
            bedeutung: 'siehe Ramil',
            dt2: '',
            dt1: 'Rami',
            id: '841',
            es3: '',
            ar1: 'ܪܡܝ',
            dt3: '',
            es2: 'ܪܡܝ',
            ar2: 'ܪܰܡܺܝ',
            es1: 'ܪܡܝ'
        },
        {
            bedeutung: 'männl., Arbuba ist ein kranker Mann, der von Mor Schemun d-Zayte (†734) geheilt wurde',
            ar2: 'ܐܰܪܰܣ',
            es1: 'ܐܪܣ',
            id: '1274',
            dt1: 'Aras',
            dt3: '',
            dt2: '',
            es2: 'ܐܪܣ',
            es3: '',
            ar1: 'ܐܪܣ',
            ar3: ''
        },
        {
            dt1: 'Malkiram',
            es1: 'ܡܠܟܝܪܡ',
            dt3: '',
            id: '1840',
            ar1: 'ܡܠܟܝܪܡ',
            ar2: 'ܡܰܠܟܺܝܪܰܡ',
            es3: '',
            es2: 'ܡܠܟܝܪܡ',
            ar3: '',
            dt2: '',
            bedeutung: 'männl., syrischen Ursprungs, „mar“ Heiliger und ”Awge/Awgin“. Der Name eines Heiligen, der so heißt: Mar Awgin (Avgin). Weitere Formen: Maravge, Mar Awgin, Mar Avgin, Mor Awgin, Mor Avgin'
        },
        {
            dt3: '',
            bedeutung: 'männl., akkadischen Ursprungs; “Nabu, schütze meinen Sohn!”. Nabochadnasar (II.) war aramäisch-chaldäischer König, Sohn Nabopolassars, des Gründers des neubabylonischen Reiches (605-562. v. Chr.). Weitere Formen: Nebukadnezzar',
            dt1: 'Nabochadnasar',
            es1: 'ܢܒܘܟ݂ܕܢܨܪ',
            ar3: '',
            es3: '',
            id: '729',
            ar1: 'ܢܒܘܟ݂ܕܢܨܪ',
            dt2: 'Nebukadnezzar',
            es2: 'ܢܒܘܟ݂ܕܢܨܪ',
            ar2: 'ܢܰܒܽܘܟ݂ܰܕܢܰܨܰܪ'
        },
        {
            bedeutung: 'männl., “wahr, echt, zuverlässig”; weitere Formen: Scharo, Scharro',
            es2: 'ܫܪܝܪ',
            es1: 'ܫܪܝܪ',
            dt1: 'Scharir',
            ar2: 'ܫܰܪܺܝܪ',
            dt2: '',
            id: '948',
            ar1: 'ܫܪܝܪ',
            ar3: '',
            es3: '',
            dt3: ''
        },
        {
            ar3: '',
            dt1: 'Nisane',
            dt2: 'Nissane',
            es2: 'ܢܝܣܢܐ',
            dt3: '',
            ar1: 'ܢܝܣܐܢܐ',
            ar2: 'ܢܺܝܣܰܢܶܐ',
            es3: '',
            id: '1964',
            es1: 'ܢܝܣܢܐ',
            bedeutung: 'männl., “Fisch”'
        },
        {
            es1: 'ܕܝܡܬܐ',
            dt1: 'Dimta',
            id: '1457',
            es3: '',
            ar2: 'ܕܺܝܡܬܰܐ',
            es2: 'ܕܝܡܬܐ',
            bedeutung: 'weibl., Domita war eine Stadt in Alt-Syrien',
            ar3: '',
            dt3: '',
            dt2: 'Dimtha',
            ar1: 'ܕܝܡܬܐ'
        },
        {
            dt2: '',
            es3: '',
            dt1: 'Gewargis',
            es1: 'ܓܘܪܓܝܣ',
            id: '1567',
            dt3: '',
            bedeutung: 'weibl., “vollkommene(s) [Frau/Mädchen]”',
            ar2: 'ܓܶܘܰܪܓܺܝܣ',
            es2: 'ܓܘܪܓܝܣ',
            ar1: 'ܓܘܪܓܝܣ',
            ar3: ''
        },
        {
            ar2: 'ܥܝܣܐܒܠ',
            es3: 'ܐܝܙܒܠ',
            es2: 'ܐܝܙܒܠ',
            ar1: 'ܐܝܙܒܠ',
            dt1: 'Isabell',
            ar3: 'ܐܺܝܙܰܒܶܠ',
            dt2: '',
            id: '1693',
            es1: 'ܐܝܙܒܠ',
            bedeutung: 'männl., “es gibt (existiert) Gott”; weitere Formen: Ithalaha, Italaha',
            dt3: ''
        },
        {
            ar1: 'ܐܓܝܒܘܬܐ',
            es3: '',
            dt2: '',
            es1: 'ܐܓܝܒܘܬܐ',
            es2: 'ܐܓܝܒܘܬܐ',
            dt1: 'Agibuta',
            dt3: '',
            ar2: 'ܐܰܓܺܝܒܽܘܬܐ',
            ar3: '',
            id: '49',
            bedeutung: 'weibl., “Antwort”'
        },
        {
            bedeutung: 'männl., “heiligen”',
            dt2: '',
            dt3: '',
            ar2: 'ܦܰܘܠܰܐ',
            es1: 'ܦܘܠܐ',
            dt1: 'Paula',
            ar3: '',
            es2: 'ܦܘܠܐ',
            id: '1994',
            ar1: 'ܦܘܠܐ',
            es3: ''
        },
        {
            es1: 'ܦܠܪܘܬܐ',
            ar2: 'ܦܠܳܪܽܘܬܰܐ',
            bedeutung: 'weibl., “Hochmut, Stolz, Hoheit, Erhabenheit”; weitere Formen: Florutha',
            es2: 'ܦܠܪܘܬܐ',
            ar3: '',
            dt3: '',
            es3: '',
            dt2: '',
            ar1: 'ܦܠܪܘܬܐ',
            dt1: 'Floruta',
            id: '344'
        },
        {
            ar3: '',
            id: '2010',
            es1: 'ܩܘܩܝܐ',
            dt1: 'Quqojo',
            ar2: 'ܩܽܘܩܳܝܳܐ',
            es3: '',
            es2: 'ܩܘܩܝܐ',
            dt2: '',
            bedeutung: 'männl., siehe Rabi und Rabil',
            dt3: '',
            ar1: 'ܩܘܩܝܐ'
        },
        {
            es3: '',
            dt1: 'Barhadbschabo',
            ar3: '',
            dt2: '',
            ar2: 'ܒܰܪܚܰܕܒܫܰܒܳܐ',
            es1: 'ܒܪܚܕܒܫܒܐ',
            bedeutung: 'männl, “Sohn des Sonntags”; weitere Formen: Barhadbschabba',
            ar1: 'ܒܪܚܕܒܫܒܐ',
            id: '163',
            es2: 'ܒܪܚܕܒܫܒܐ',
            dt3: ''
        },
        {
            ar3: '',
            dt3: 'Joseph',
            ar2: 'ܝܰܘܣܶܦ',
            dt1: 'Josef',
            ar1: 'ܝܘܣܦ',
            dt2: 'Yausef',
            es3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott möge vermehren” oder “Gott möge hinzufügen, Zufüger”. Im AT ist Joseph der elfte Sohn von Jakob und Rahel, geboren in Obermesopotamien. Im NT ist Joseph Verlobter bzw. Ehemann von Maria, der Mutter Jesu, Sohn eines Jakob aus dem Stamm Davids. Joseph von Arimathäa, Mitglied des Hohen Rats, Jünger Jesu, bestattet Jesus in seinem eigenen Grab. Weitere Formen: Josef, Jussuf, Yusuf, Yawsef',
            es2: 'ܝܘܣܦ',
            id: '561',
            es1: 'ܝܘܣܦ'
        },
        {
            id: '1059',
            es3: '',
            ar1: 'ܛܝܡܬܐܘܣ',
            ar2: 'ܛܺܝܡܳܬܶܐܘܳܣ',
            dt3: '',
            ar3: '',
            dt1: 'Timotheos',
            es2: 'ܛܝܡܬܐܘܣ',
            dt2: 'Timotheus',
            es1: 'ܛܝܡܬܐܘܣ',
            bedeutung: 'männl., aus dem griechischen “timan”: schätzen, ehren und “theós”: Gott = “Gottesverehrer”, “Fürchtegott”. Im NT ist Timotheos ein Schüler und Begleiter des Apostels Paulus; viele Patriarchen und Bischöfe tragen diesen Namen; einer der Beinamen für die syrischen Bischöfe'
        },
        {
            bedeutung: 'weibl., “die Reine”',
            es2: 'ܣܐܘܝܪܐ',
            dt3: 'Severos',
            ar2: 'ܣܶܐܘܺܝܪܶܐ',
            es3: 'ܣܐܘܪܝܘܣ',
            dt2: 'Severios',
            dt1: 'Sevire',
            id: '2182',
            es1: 'ܣܐܘܝܪܐ',
            ar1: 'ܣܐܘܝܪܐ',
            ar3: 'ܣܶܐܘܺܪܝܳܘܣ'
        },
        {
            ar3: '',
            es1: 'ܟܫܝܪ',
            ar1: 'ܟܫܝܪ',
            bedeutung: 'männl., “ﬂeißig, eifrig”',
            dt3: '',
            ar2: 'ܟܰܫܺܝܪ',
            es2: 'ܟܫܝܪ',
            id: '584',
            es3: '',
            dt1: 'Kaschir',
            dt2: ''
        },
        {
            ar1: 'ܐܡܠ',
            es3: '',
            ar2: 'ܐܰܡܶܠ',
            id: '1254',
            ar3: '',
            es1: 'ܐܡܠ',
            dt1: 'Amel',
            dt2: 'Amal ',
            dt3: '',
            es2: 'ܐܡܠ',
            bedeutung: 'männl., “Fürst, Prinz”'
        },
        {
            dt1: 'Elschamasch',
            dt3: '',
            dt2: '',
            bedeutung: '',
            ar2: 'ܐܶܠܫܰܡܰܫ',
            es2: 'ܐܠܫܡܫ',
            es1: 'ܐܠܫܡܫ',
            ar3: '',
            ar1: 'ܐܠܫܡܫ',
            es3: '',
            id: '1495'
        },
        {
            ar2: 'ܫܽܘܟܳܢܳܐ',
            es3: '',
            dt1: 'Schukono',
            id: '2169',
            es2: 'ܫܘܟܢܐ',
            ar1: 'ܫܘܟܢܐ',
            es1: 'ܫܘܟܢܐ',
            dt3: '',
            bedeutung: 'männl., “Glanz, Strahl”',
            ar3: '',
            dt2: ''
        },
        {
            es1: 'ܛܒܐ',
            ar3: '',
            id: '1067',
            es2: 'ܛܒܐ',
            es3: '',
            bedeutung: 'männl., “der Gute”',
            dt3: '',
            ar1: 'ܛܒܐ',
            dt2: '',
            dt1: 'Tobo',
            ar2: 'ܛܳܒܳܐ'
        },
        {
            dt1: 'Sislo',
            es2: 'ܨܝܨܠܐ',
            ar1: 'ܨܝܨܠܐ',
            ar3: '',
            dt2: '',
            es1: 'ܨܝܨܠܐ',
            bedeutung: 'weibl., aus dem griechischen “Sophia Weisheit”',
            id: '2189',
            ar2: 'ܨܺܝܨܠܳܐ',
            es3: '',
            dt3: ''
        },
        {
            ar2: 'ܐܰܕܺܝܒ',
            dt3: 'Adib',
            ar3: 'ܐܶܕܺܝܒ',
            ar1: 'ܐܕܝܒ',
            es1: 'ܐܕܝܒ',
            dt2: 'Adip',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “mein Gott ist Vater”',
            es3: 'ܐܕܝܒ',
            id: '1475',
            es2: 'ܐܕܝܒ',
            dt1: 'Edip'
        },
        {
            dt3: '',
            ar1: 'ܐܢܘܫ',
            dt2: 'Anusch',
            dt1: 'Anosch',
            id: '89',
            ar2: 'ܐܰܢܳܘܫ',
            es1: 'ܐܢܘܫ',
            es2: 'ܐܢܘܫ',
            ar3: '',
            es3: '',
            bedeutung: ''
        },
        {
            dt3: '',
            dt1: 'Yiqar',
            bedeutung: 'männl., “sich verlangsamen, geschätzt, geehrt”; weitere Formen: Jiqar',
            dt2: 'Jiqar',
            id: '1136',
            es2: 'ܝܝܩܪ',
            es3: '',
            es1: 'ܝܝܩܪ',
            ar3: '',
            ar1: 'ܝܝܩܪ',
            ar2: 'ܝܺܝܩܰܪ'
        },
        {
            id: '251',
            bedeutung: 'männl., “rein, sauber”',
            ar2: 'ܕܰܟܝܰܐ',
            ar1: 'ܕܟܝܐ',
            es1: 'ܕܟܝܐ',
            dt3: '',
            ar3: '',
            dt2: '',
            es2: 'ܕܟܝܐ',
            es3: '',
            dt1: 'Dakya'
        },
        {
            es3: '',
            es1: 'ܟܠܬܐ',
            ar1: 'ܟܠܬܐ',
            dt3: '',
            dt2: '',
            es2: 'ܟܠܬܐ',
            ar3: '',
            dt1: 'Kaltha',
            bedeutung: 'männl., syrischen Ursprungs; “bleiben, stabil, fest, dauerhaft”',
            ar2: 'ܟܰܠܬܰܐ',
            id: '1765'
        },
        {
            ar2: 'ܐܽܘܪܗܺܝܢ',
            id: '2281',
            es2: 'ܐܘܪܗܝܢ',
            dt1: 'Urhin',
            ar1: 'ܐܘܪܗܝܢ',
            es3: '',
            es1: 'ܐܘܪܗܝܢ',
            dt3: '',
            dt2: '',
            bedeutung: 'weibl., “Land des Friedens”; weitere Formen: Urschayna',
            ar3: ''
        },
        {
            ar2: 'ܥܺܝܣܰܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gottesstreiter, Fechter Gottes” oder “möge Gott kämpfen”. Im AT ist Israel die Umbenennung des Namens Jakob bei seinem Kampf mit Gott. Weitere Namen: Isroel',
            es1: 'ܥܝܣܐ',
            ar3: '',
            es2: 'ܥܝܣܐ',
            id: '1692',
            dt1: 'Isa',
            ar1: 'ܥܝܣܐ',
            dt3: '',
            dt2: '',
            es3: ''
        },
        {
            ar3: 'ܪܰܒܽܘܠܰܐ',
            dt3: '',
            ar2: 'ܪܰܒܒܽܘܠܰܐ',
            dt2: '',
            bedeutung: 'weibl., “Würde, Hoheit, Pracht, Größe”',
            dt1: 'Rabbula',
            id: '2015',
            es2: 'ܪܒܒܘܠܐ',
            es3: 'ܪܒܘܠܐ',
            ar1: 'ܪܒܒܘܠܐ',
            es1: 'ܪܒܒܘܠܐ'
        },
        {
            ar3: 'ܢܽܘܪܗܰܕܰܕ',
            dt2: 'Nurhadad',
            bedeutung: 'männl., aramäischer Senator von Tadmor/Palmyra (255 - 267 n.Chr.), Ehemann von Zenobia. Weitere Formen: Odenathus, Odi',
            dt3: '',
            ar1: 'ܢܘܪܕܕ',
            es3: 'ܢܘܪܗܕܕ',
            dt1: 'Nuradad',
            ar2: 'ܢܽܘܪܚܰܕܰܕ',
            id: '1974',
            es1: 'ܢܘܪܕܕ',
            es2: 'ܢܘܪܕܕ'
        },
        {
            es2: 'ܝܘܢܢ',
            bedeutung: '',
            es3: '',
            id: '1139',
            dt2: 'Jonan',
            ar1: 'ܝܘܢܢ',
            dt1: 'Yonan',
            dt3: '',
            ar3: '',
            ar2: 'ܝܳܘܢܳܢ',
            es1: 'ܝܘܢܢ'
        },
        {
            es1: 'ܬܓܬ',
            ar3: '',
            dt3: '',
            id: '1069',
            dt2: '',
            bedeutung: 'männl., “du bist meine Krone”',
            es3: '',
            dt1: 'Togat',
            ar1: 'ܬܓܬ',
            ar2: 'ܬܳܓܰܬ',
            es2: 'ܬܓܬ'
        },
        {
            es2: 'ܢܫܪܝܬܐ',
            es1: 'ܢܫܪܝܬܐ',
            ar3: '',
            id: '1956',
            es3: '',
            dt1: 'Neschrayta',
            ar1: 'ܢܫܪܝܬܐ',
            bedeutung: 'männl., “Zeichen, Symbol, Flagge”',
            dt2: '',
            dt3: '',
            ar2: 'ܢܶܫܪܰܝܬܰܐ'
        },
        {
            bedeutung: 'männl., “der Herr hat gegeben”, weitere Formen: Marjab',
            ar1: 'ܡܪܝܗܒ',
            ar2: 'ܡܳܪܝܰܗܒ',
            dt2: '',
            es3: 'ܡܪܝܗܒ',
            dt1: 'Morjab',
            es2: 'ܡܪܝܗܒ',
            ar3: 'ܡܰܪܝܰܗܒ',
            id: '718',
            dt3: '',
            es1: 'ܡܪܝܗܒ'
        },
        {
            dt3: '',
            dt1: 'Jariba',
            es2: 'ܝܪܝܒܐ',
            dt2: '',
            es1: 'ܝܪܝܒܐ',
            ar2: 'ܝܰܪܺܝܒܰܐ',
            ar3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “möge er von Mitleid ergriffen werden”; weitere Formen: Jeruham',
            id: '1715',
            ar1: 'ܝܪܝܒܐ',
            es3: ''
        },
        {
            es2: 'ܚܘܕܬܐ',
            dt2: '',
            bedeutung: 'weibl., “Erneuerung”; weitere Formen: Hudata',
            dt3: '',
            ar2: 'ܚܽܘܕܰܬ݂ܰܐ',
            es3: '',
            ar3: '',
            es1: 'ܚܘܕܬܐ',
            ar1: 'ܚܘܕܬ݂ܐ',
            id: '489',
            dt1: 'Hudatha'
        },
        {
            id: '498',
            es1: 'ܐܝܠܗܒ',
            ar2: 'ܐܺܝܠܗܰܒ',
            es3: 'ܐܠܗܒ',
            bedeutung: 'männl., syrischen Ursprungs; “gib Gott!”; weitere Formen: Elhab',
            dt2: '',
            dt3: '',
            dt1: 'Ilhab',
            ar3: 'ܐܶܠܗܰܒ',
            ar1: 'ܐܝܠܗܒ',
            es2: 'ܐܝܠܗܒ'
        },
        {
            dt1: 'Hasaja',
            ar3: 'ܚܰܙܳܝܳܐ',
            dt2: '',
            bedeutung: 'männl., “Jahwe hat gesehen”; weitere Formen: Hasaya, Hazoyo, Hasojo, Hasoyo',
            es3: 'ܚܙܝܐ',
            es2: 'ܚܙܝܐ',
            es1: 'ܚܙܝܐ',
            ar2: 'ܚܰܙܰܝܰܐ',
            ar1: 'ܚܙܝܐ',
            dt3: '',
            id: '448'
        },
        {
            bedeutung: 'männl., “aufwecken, anregen, bewegen”',
            id: '2224',
            es3: '',
            es2: 'ܛܠܐ',
            ar2: 'ܛܰܠܳܐ',
            dt3: '',
            ar1: 'ܛܠܐ',
            es1: 'ܛܠܐ',
            dt2: '',
            ar3: '',
            dt1: 'Talo'
        },
        {
            es2: 'ܝܘܚܝܠ',
            id: '530',
            ar2: 'ܝܽܘܚܳܝܶܠ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott soll leben!”; weitere Formen: Juhoel',
            es1: 'ܝܘܚܝܠ',
            dt3: '',
            ar3: '',
            dt2: 'Juhoel',
            es3: '',
            ar1: 'ܝܘܚܝܠ',
            dt1: 'Jehiel'
        },
        {
            bedeutung: 'weibl., “Platane”',
            ar1: 'ܕܘܠܒܐ',
            ar2: 'ܕܽܘܠܒܰܐ',
            es3: '',
            es2: 'ܕܘܠܒܐ',
            dt3: '',
            dt1: 'Dulba',
            dt2: '',
            id: '285',
            es1: 'ܕܘܠܒܐ',
            ar3: ''
        },
        {
            id: '1345',
            dt3: '',
            es3: '',
            ar3: '',
            dt1: 'Barholo',
            es2: 'ܒܪܚܠܐ',
            bedeutung: 'männl., “Sohn des Festes”',
            dt2: '',
            es1: 'ܒܪܚܠܐ',
            ar2: 'ܒܰܪܚܳܠܳܐ',
            ar1: 'ܒܪܚܠܐ'
        },
        {
            es2: 'ܢܥܡܢ',
            id: '1972',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Knecht Jahwes”. Im AT ist Obadja ein Prophet (6. Jh. v. Chr.) und Verfasser eines Buches. Weitere Formen: Obadjo, Ubadjo',
            ar3: '',
            es1: 'ܢܥܡܢ',
            dt3: 'Nouman',
            dt2: 'Neman',
            dt1: 'Numan',
            es3: '',
            ar1: 'ܢܥܡܢ',
            ar2: 'ܢܳܥܡܰܢ'
        },
        {
            es3: '',
            dt3: '',
            id: '471',
            dt1: 'Hesaqjo',
            dt2: '',
            ar2: 'ܚܶܙܰܩܝܳܐ',
            bedeutung: 'männl., siehe Hiskija',
            es2: 'ܚܙܩܝܐ',
            ar3: '',
            ar1: 'ܚܙܩܝܐ',
            es1: 'ܚܙܩܝܐ'
        },
        {
            ar1: 'ܡܬܝܐ',
            es3: '',
            es1: 'ܡܬܝܐ',
            es2: 'ܡܬܝܐ',
            id: '690',
            ar3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gabe, Geschenk Jahwes”. Im NT wird Matia anstelle des Judas Iskariot in den Kreis der 12 Apostel gewählt. Weitere Formen: Matthias',
            ar2: 'ܡܰܬܺܝܰܐ',
            dt3: '',
            dt1: 'Matia',
            dt2: ''
        },
        {
            es1: 'ܫܡܪܒ',
            bedeutung: 'männl., “mein Name ist groß, erhaben”',
            ar1: 'ܫܡܪܒ',
            ar3: '',
            es3: '',
            dt2: '',
            id: '955',
            dt1: 'Schemrab',
            dt3: '',
            ar2: 'ܫܶܡܪܰܒ',
            es2: 'ܫܡܪܒ'
        },
        {
            bedeutung: 'männl., “groß, riesig”',
            es1: 'ܪܒܐܝܠ',
            es2: 'ܪܒܐܝܠ',
            id: '828',
            ar2: 'ܪܰܒܳܐ',
            dt1: 'Rabo',
            ar3: '',
            dt3: '',
            es3: '',
            dt2: '',
            ar1: 'ܪܒܐ'
        },
        {
            dt2: '',
            ar1: 'ܩܝܬܪܐ',
            ar3: '',
            es2: 'ܩܝܬܪܐ',
            bedeutung: 'weibl., “Harfe, Leier, Lyra, Zither”; weitere Formen: Qithora',
            id: '816',
            dt3: '',
            es1: 'ܩܝܬܪܐ',
            dt1: 'Qitora',
            ar2: 'ܩܺܝܬܳܪܰܐ',
            es3: ''
        },
        {
            ar3: 'ܪܺܝܡܰܐ',
            dt3: '',
            ar2: 'ܪܰܝܡܰܐ',
            id: '845',
            ar1: 'ܪܝܡܐ',
            dt2: '',
            dt1: 'Rayma',
            es1: 'ܪܝܡܐ',
            es3: 'ܪܝܡܐ',
            bedeutung: 'weibl., ursprünglich männlich, “Antilope, Reh”; weitere Formen: Raima, Rima',
            es2: 'ܪܝܡܐ'
        },
        {
            dt3: '',
            es3: 'ܨܒܪܝ',
            ar3: 'ܨܰܒܪܺܝ',
            id: '2072',
            dt2: '',
            bedeutung: '',
            ar1: 'ܣܒܪܝ',
            es1: 'ܣܒܪܝ',
            es2: 'ܣܒܪܝ',
            dt1: 'Sabri',
            ar2: 'ܣܰܒܪܺܝ'
        },
        {
            id: '1438',
            ar1: 'ܕܢܝܐܝܠ',
            es2: 'ܕܢܝܐܝܠ',
            dt1: 'Daniel',
            dt3: 'Doniel',
            dt2: 'Danyel',
            ar2: 'ܕܳܢܺܝܐܝܶܠ',
            es1: 'ܕܢܝܐܝܠ',
            ar3: 'ܕܰܢܺܝܐܝܶܠ',
            bedeutung: 'männl., “Topf, Kanne, Fass, Gefäß, Krug”',
            es3: 'ܕܢܝܐܝܠ'
        },
        {
            dt2: '',
            ar3: '',
            bedeutung: 'weibl. Form zu Habib; weitere Formen: Habibto',
            dt1: 'Gregorius',
            dt3: '',
            es2: 'ܓܪܝܓܘܪܝܘܣ',
            es3: '',
            id: '1582',
            ar1: 'ܓܪܝܓܘܪܝܘܣ',
            ar2: 'ܓܪܶܝܓܳܘܪܺܝܳܘܣ',
            es1: 'ܓܪܝܓܘܪܝܘܣ'
        },
        {
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe ist Hilfe, Jahwe ist großzügig”. Im AT ist Josua Schüler und Nachfolger des Mose (13. oder 11. Jh. v. Chr.). Weitere Formen: Jeschu (Bar-Nun), Jeschua, Jehoschuah',
            ar1: 'ܝܘܐܝܠ',
            dt3: '',
            es2: 'ܝܘܐܝܠ',
            dt2: 'Youil',
            id: '1741',
            dt1: 'Joel',
            ar3: '',
            es3: '',
            es1: 'ܝܘܐܝܠ',
            ar2: 'ܝܽܘܐܺܝܠ'
        },
        {
            ar1: 'ܐܓܝ',
            dt2: '',
            dt1: 'Agai',
            ar2: 'ܐܰܓܰܝ',
            ar3: '',
            es3: '',
            bedeutung: 'männl., Agai ist einer der 70/72 Missionare, Schüler des Adai und Missionar in Syrien und ganz AramBeth-Nahrin (= Mesopotamien). Weitere Formen: Agay',
            id: '47',
            dt3: '',
            es2: 'ܐܓܝ',
            es1: 'ܐܓܝ'
        },
        {
            ar3: '',
            es1: 'ܓܘܪܓܝܣ',
            ar2: 'ܓܳܘܪܓܺܝܣ',
            ar1: 'ܓܘܪܓܝܣ',
            dt1: 'Gorgis',
            id: '392',
            es2: 'ܓܘܪܓܝܣ',
            dt2: '',
            bedeutung: 'männl., siehe Gewargis',
            es3: '',
            dt3: ''
        },
        {
            id: '2212',
            dt3: '',
            dt2: 'Schusche',
            es3: 'ܣܘܣܝ',
            dt1: 'Susi',
            es1: 'ܫܘܫܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs. Tahan ist nach dem AT Sohn Efraims, Ahnherr Josuas. Weitere Formen: Tohon',
            ar2: 'ܫܽܘܫܶܐ',
            ar1: 'ܫܘܫܐ',
            es2: 'ܫܘܫܐ',
            ar3: 'ܣܽܘܣܺܝ'
        },
        {
            dt3: '',
            ar2: 'ܚܰܒܰܩܽܘܩ',
            id: '1586',
            es2: 'ܚܒܩܘܩ',
            dt2: '',
            es3: 'ܚܒܩܘܩ',
            es1: 'ܚܒܩܘܩ',
            ar1: 'ܚܒܩܘܩ',
            bedeutung: 'weibl., syrischen Ursprungs; “Freundschaft”; weitere Formen: Habrutha',
            ar3: 'ܚܰܒܩܽܘܩ',
            dt1: 'Habakuk'
        },
        {
            ar2: 'ܫܽܘܫܰܢ',
            ar3: '',
            es1: 'ܫܘܫܐ',
            dt2: '',
            dt1: 'Susan',
            ar1: 'ܫܘܫܢ',
            id: '1019',
            es2: 'ܫܘܫܐ',
            bedeutung: '',
            es3: '',
            dt3: ''
        },
        {
            bedeutung: '',
            es2: 'ܥܙܝܙ',
            es1: 'ܥܙܝܙ',
            dt3: '',
            dt1: 'Aziz',
            ar1: 'ܥܙܝܙ',
            ar2: 'ܥܰܙܺܝܙ',
            ar3: '',
            id: '138',
            es3: '',
            dt2: 'Asis'
        },
        {
            dt1: 'Michaela',
            id: '1890',
            ar3: '',
            ar1: 'ܡܝܟܐܝܠܐ',
            dt3: '',
            es3: '',
            es1: 'ܡܝܟܐܝܠܐ',
            bedeutung: '',
            es2: 'ܡܝܟܐܝܠܐ',
            ar2: 'ܡܺܝܟ݂ܰܐܝܶܠܰܐ',
            dt2: ''
        },
        {
            ar1: 'ܓܘܠܝܕ',
            ar3: '',
            es1: 'ܓܘܠܝܕ',
            bedeutung: 'männl., “junger Löwe”; metaphorischer Beiname für Christus',
            es3: '',
            dt1: 'Goliat',
            dt2: 'Goliath',
            es2: 'ܓܘܠܝܕ',
            ar2: 'ܓܽܘܠܝܰܕ',
            id: '1577',
            dt3: 'Guljad'
        },
        {
            dt3: '',
            ar3: '',
            dt2: '',
            bedeutung: 'weibl., syrischen Ursprungs; “die Süße, die Charmante, eine charmante Dame, ein liebliches, süßes, charmantes Mädchen”; weitere Formen: Hlitha',
            es1: 'ܚܙܩܝܐܝܠ',
            ar1: 'ܚܙܩܝܐܝܠ',
            ar2: 'ܚܙܩܝܐܝܠ',
            es3: '',
            es2: 'ܚܙܩܝܐܝܠ',
            id: '1662',
            dt1: 'Hezekiel'
        },
        {
            dt1: 'Marutha',
            dt3: '',
            ar1: 'ܡܪܘܬܐ',
            es1: 'ܡܪܘܬܐ',
            es2: 'ܡܪܘܬܐ',
            es3: '',
            id: '680',
            bedeutung: 'männl., syrischen Ursprungs weibl. Stammes; “Herrschat, Macht, Autorität, Souveränität, Unabhängigkeit”. Marutha, Bischof von Maipharqat (†421) war auf dem Konzil von Konstantinopel (381) anwesend; Marutha, Katholikos von Tagrit im Osten (†649), hat das Ninivefasten in der syrisch-orthodoxen Kirche des Ostens, d.h. in der syrischen Kirche in dem damaligen persischen Reich, angeordnet',
            dt2: '',
            ar2: 'ܡܰܪܘܬܰܐ',
            ar3: ''
        },
        {
            ar3: '',
            dt1: 'Zenobio',
            dt2: '',
            dt3: '',
            es3: '',
            ar2: 'ܙܶܢܳܘܒܺܝܳܐ',
            id: '2360',
            es2: 'ܙܢܘܒܝܐ',
            es1: 'ܙܢܘܒܝܐ',
            bedeutung: '',
            ar1: 'ܙܢܘܒܝܐ'
        },
        {
            ar1: 'ܫܟ݂ܢܝܐ',
            bedeutung: 'weibl., “Fundament, Grund, Boden”',
            dt2: '',
            id: '2143',
            es3: 'ܫܟ݂ܢܝܐ',
            ar3: 'ܫܟ݂ܰܢܝܳܐ',
            dt3: '',
            dt1: 'Schechanja',
            ar2: 'ܫܶܟ݂ܰܢܝܰܐ',
            es1: 'ܫܟ݂ܢܝܐ',
            es2: 'ܫܟ݂ܢܝܐ'
        },
        {
            dt1: 'Ahudemeh',
            es1: 'ܐܚܘܕܡܗ',
            bedeutung: 'männl., “Bruder seiner Schwester”. Ahudemeh, ein Heiliger der syrisch-orthodoxen Kirche, war Metropolit und Katholikos (Maphiryono) des Ostens (†575), Missionar unter den Arabern; Festtag: 18. September',
            es3: '',
            dt3: '',
            es2: 'ܐܚܘܕܡܗ',
            ar3: '',
            ar2: 'ܐܰܚܽܘܕܶܡܶܗ',
            id: '1240',
            dt2: '',
            ar1: 'ܐܚܘܕܡܗ'
        },
        {
            dt2: '',
            ar2: 'ܬܽܘܠܡܰܝ',
            es3: '',
            es2: 'ܬܘܠܡܝ',
            dt1: 'Tulmai',
            id: '2270',
            bedeutung: 'weibl., “Weg, Straße”',
            dt3: '',
            ar1: 'ܬܘܠܡܝ',
            ar3: '',
            es1: 'ܬܘܠܡܝ'
        },
        {
            dt3: '',
            ar1: 'ܐܘܪܒ',
            dt2: '',
            es3: '',
            bedeutung: 'männl., “wie”',
            es1: 'ܐܘܪܒ',
            ar3: '',
            ar2: 'ܐܰܘܪܶܒ',
            es2: 'ܐܘܪܒ',
            id: '1312',
            dt1: 'Awreb'
        },
        {
            es2: 'ܐܢܗܪ',
            dt3: '',
            ar2: 'ܐܰܢܗܰܪ',
            ar3: '',
            es1: 'ܐܢܗܪ',
            es3: '',
            dt2: '',
            bedeutung: '',
            ar1: 'ܐܢܗܪ',
            dt1: 'Anhar',
            id: '1266'
        },
        {
            es2: 'ܫܝܦܘܪܐ',
            bedeutung: 'männl., syrischen Ursprungs; “Diamant”. Schomir ist in der Geschichte des Klosters Mor Gabriel in Turabdin Bruder des Mor Samuels (†409). Weitere Formen: Schumir',
            id: '2154',
            es1: 'ܫܝܦܘܪܐ',
            ar2: 'ܫܺܝܦܽܘܪܰܐ',
            ar1: 'ܫܝܦܘܪܐ',
            dt1: 'Schipura',
            ar3: '',
            es3: '',
            dt3: '',
            dt2: 'Schifura'
        },
        {
            ar2: 'ܓܰܠܳܐ',
            id: '363',
            bedeutung: 'männl., siehe Gabriel',
            es1: 'ܓܠܐ',
            dt3: '',
            dt1: 'Gallo',
            ar3: 'ܓܰܠܠܳܐ',
            dt2: '',
            es3: 'ܓܠܠܐ',
            es2: 'ܓܠܐ',
            ar1: 'ܓܠܐ'
        },
        {
            bedeutung: 'männl., “der Vater ist lebendig, der Vater lebt”. Abhai ist ein Heiliger in der syrisch-orthodoxen Kirche; Festtag: 15. Juli',
            es2: 'ܐܒܚܝ',
            es3: '',
            dt3: '',
            id: '1192',
            dt2: '',
            ar2: 'ܐܰܒܚܰܝ',
            es1: 'ܐܒܚܝ',
            dt1: 'Abhai',
            ar1: 'ܐܒܚܝ',
            ar3: ''
        },
        {
            id: '1319',
            es3: '',
            dt1: 'Babai',
            es2: 'ܒܒܝ',
            bedeutung: 'männl., von bhiro: “Sachkundiger, Fachmann, Experte, Geprüfter”. Bahira war ein Mönch, der nach einer Geschichte/Tradition den “Propheten” Mohammed unterwiesen haben soll',
            ar2: 'ܒܰܒܰܝ',
            ar3: '',
            dt2: 'Babay',
            es1: 'ܒܒܝ',
            ar1: 'ܒܒܝ',
            dt3: ''
        },
        {
            ar3: '',
            id: '2157',
            dt3: '',
            dt2: '',
            bedeutung: 'männl., “Lob sei Jesus”. Schubho l`jeschu (37 5-406) war Metropolit von Arbela. Weitere Formen: Schubhalischo',
            dt1: 'Schlita',
            ar1: 'ܫܠܝܬܐ',
            es3: '',
            es1: 'ܫܠܝܬܐ',
            ar2: 'ܫܠܺܝܬܰܐ',
            es2: 'ܫܠܝܬܐ'
        },
        {
            es2: 'ܐܘܪܚܘܒܐ',
            es3: '',
            id: '1091',
            ar1: 'ܐܘܪܚܘܒܐ',
            ar2: 'ܐܽܘܪܚܽܘܒܰܐ',
            dt1: 'Urhuba',
            dt2: '',
            bedeutung: 'weibl., “Land, Ort der Liebe”',
            es1: 'ܐܘܪܚܘܒܐ',
            ar3: '',
            dt3: ''
        },
        {
            dt3: '',
            dt2: '',
            bedeutung: 'männl., “keusch, zölibatär, asketisch, (ver)meiden, ausweichen”',
            es2: 'ܢܪܣܝ',
            ar2: 'ܢܰܪܣܰܝ',
            id: '1939',
            es1: 'ܢܪܣܝ',
            ar3: '',
            ar1: 'ܢܪܣܝ',
            es3: '',
            dt1: 'Narsai'
        },
        {
            ar1: 'ܪܒܪܬ',
            dt3: '',
            es2: 'ܪܒܪܬ',
            es1: 'ܪܒܪܬ',
            es3: '',
            bedeutung: '',
            dt2: '',
            ar2: 'ܪܰܒܶܪܬ',
            ar3: '',
            dt1: 'Robert',
            id: '862'
        },
        {
            es1: 'ܐܬܐܢܐܣܝܘܣ',
            ar1: 'ܐܬܐܢܐܣܝܘܣ',
            es3: '',
            es2: 'ܐܬܐܢܐܣܝܘܣ',
            dt3: 'Athanasius',
            bedeutung: 'männl., aus dem Griechischen übernommener Vorname; “der Unsterbliche”. Viele Heilige und Kirchenväter hießen Athanasius, einer der Beinamen ﬁir syrisch-orthodoxe Bischöfe',
            dt2: 'Atanatius',
            dt1: 'Athanatius',
            ar3: '',
            ar2: 'ܐܰܬܰܐܢܰܣܺܝܽܘܣ',
            id: '118'
        },
        {
            es1: 'ܙܗܝܘܬܐ',
            dt1: 'Zahyuta',
            es3: '',
            ar3: '',
            bedeutung: 'weibl., “Schönheit, Pracht, Großartigkeit, Glühen, Leuchten, Glanz, Exzellenz, Ehre, Tugend”; weitere Formen: Zahyutha',
            es2: 'ܙܗܝܘܬܐ',
            ar1: 'ܙܗܝܘܬܐ',
            id: '1154',
            dt2: '',
            ar2: 'ܙܰܗܝܽܘܬܰܐ',
            dt3: ''
        },
        {
            dt2: '',
            dt3: '',
            id: '618',
            es1: 'ܠܡܟ݂',
            dt1: 'Lamech',
            es2: 'ܠܡܟ݂',
            ar2: 'ܠܰܡܶܟ݂',
            ar1: 'ܠܡܟ݂',
            ar3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs. Im AT ist Lamech Sohn des Metuschael und Nachkomme Kains; nach dem NT ist Lamech ein Ahnherr Jesu. Weitere Formen: Lamek',
            es3: ''
        },
        {
            ar1: 'ܝܕܥܐܝܠ',
            id: '1709',
            ar2: 'ܝܰܕܥܳܐܝܶܠ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott soll leben!”; weitere Formen: Juhoel',
            dt2: '',
            ar3: 'ܝܰܕܥܺܝܶܠ',
            es1: 'ܝܕܥܐܝܠ',
            dt3: '',
            dt1: 'Jadiael',
            es2: 'ܝܕܥܐܝܠ',
            es3: 'ܝܕܥܝܠ'
        },
        {
            bedeutung: 'weibl., siehe Layla',
            dt2: '',
            es2: 'ܠܒܝܒܬܐ',
            id: '1811',
            es1: 'ܠܒܝܒܬܐ',
            ar2: 'ܠܒܺܝܒܬܰܐ',
            ar3: '',
            dt1: 'Lbibta',
            ar1: 'ܠܒܝܒܬܐ',
            es3: '',
            dt3: ''
        },
        {
            ar1: 'ܐܝܠܚܘܒܐ',
            ar2: 'ܐܺܝܠܚܽܘܒܰܐ',
            bedeutung: 'männl., “Gott der Liebe”, “Gott ist die Liebe”',
            dt2: '',
            ar3: '',
            dt3: '',
            es2: 'ܐܝܠܚܘܒܐ',
            dt1: 'Ilhuba',
            es1: 'ܐܝܠܚܘܒܐ',
            id: '499',
            es3: ''
        },
        {
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “er gebe weiten Raum”. Im AT ist Jafet Sohn Noahs, Bruder Sems. Die Bewohner Kleinasiens und der Ägäis werden laut AT auf Jafet zuıückgeﬁJhrt. Weitere Formen: Jaft',
            ar1: 'ܐܝܙܠܐ',
            ar3: '',
            es3: '',
            dt1: 'Izala',
            dt3: '',
            es2: 'ܐܝܙܠܐ',
            dt2: '',
            id: '1703',
            ar2: 'ܐܺܝܙܰܠܰܐ',
            es1: 'ܐܝܙܠܐ'
        },
        {
            es3: '',
            ar3: '',
            dt2: 'Mazra',
            dt1: 'Masra',
            ar2: 'ܡܰܙܪܰܐ',
            bedeutung: '',
            es2: 'ܡܙܪܐ',
            ar1: 'ܡܙܪܐ',
            id: '1874',
            es1: 'ܡܙܪܐ',
            dt3: ''
        },
        {
            bedeutung: '',
            ar1: 'ܝܘܝܕܥ',
            id: '1744',
            es2: 'ܝܘܝܕܥ',
            es1: 'ܝܘܝܕܥ',
            ar2: 'ܝܽܘܝܳܕܳܥ',
            ar3: 'ܝܽܘܝܰܕܰܥ',
            dt1: 'Jojada',
            dt3: '',
            dt2: '',
            es3: 'ܝܘܝܕܥ'
        },
        {
            dt1: 'Jehija',
            ar1: 'ܐܚܝܐ',
            id: '531',
            es3: 'ܝܚܝܐ',
            es2: 'ܐܚܝܐ',
            es1: 'ܐܚܝܐ',
            ar2: 'ܐܰܚܺܝܰܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe soll leben!”; weitere Formen: Jahija, Jahja, Ahija',
            dt2: '',
            dt3: '',
            ar3: 'ܝܰܚܝܰܐ'
        },
        {
            dt3: '',
            es3: '',
            es2: 'ܐܕܘܢܝܩܡ',
            ar1: 'ܐܕܘܢܝܩܡ',
            id: '1217',
            dt1: 'Adonikam',
            ar2: 'ܐܰܕܳܘܢܺܝܩܰܡ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “mein Herr steht aufrecht”; weitere Formen: Adoniqam',
            es1: 'ܐܕܘܢܝܩܡ',
            dt2: '',
            ar3: ''
        },
        {
            ar1: 'ܓܒܝܐ',
            dt2: '',
            ar3: '',
            id: '1545',
            dt3: '',
            es3: '',
            ar2: 'ܓܰܒܝܳܐ',
            dt1: 'Gabyo',
            es1: 'ܓܒܝܐ',
            bedeutung: 'männl., siehe Gabriel',
            es2: 'ܓܒܝܐ'
        },
        {
            es2: 'ܢܫܪܬ',
            dt2: '',
            id: '1955',
            es1: 'ܢܫܪܬ',
            es3: '',
            ar3: '',
            dt1: 'Neschrat',
            ar2: 'ܢܶܫܪܰܬ',
            bedeutung: 'weibl., “April” oder/und “Frühling”',
            ar1: 'ܢܫܪܬ',
            dt3: ''
        },
        {
            bedeutung: 'männl., “Ausrutscher, Patzer”. Scharia war Bischof von Arbela (291-316). Weitere Formen: Schria, Schrio',
            id: '947',
            ar2: 'ܫܰܪܺܝܥܰܐ',
            dt1: 'Scharia',
            es2: 'ܫܪܝܥܐ',
            ar3: 'ܫܪܺܝܥܰܐ',
            es3: 'ܫܪܝܥܐ',
            dt2: '',
            dt3: '',
            ar1: 'ܫܪܝܥܐ',
            es1: 'ܫܪܝܥܐ'
        },
        {
            ar1: 'ܡܬܝ',
            dt2: 'Matai',
            es2: 'ܡܬܝ',
            dt1: 'Matay',
            id: '689',
            es3: '',
            dt3: '',
            ar2: 'ܡܰܬܰܝ',
            ar3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Geschenk, Gabe Jahwes”. Im NT ist Matai einer der 12 Jünger Jesu Christi. Matai ist der Verfasser des ersten Evangeliums. Weitere Formen: Mattai, Matthäus, Mattäus, Matay',
            es1: 'ܡܬܝ'
        },
        {
            es3: '',
            ar2: 'ܣܰܝܒܰܪ',
            ar3: '',
            dt1: 'Saibar',
            es2: 'ܣܝܒܪ',
            dt2: '',
            dt3: '',
            es1: 'ܣܝܒܪ',
            ar1: 'ܣܝܒܪ',
            bedeutung: 'männl., “er hat ausgehalten, ertragen”; weitere Formen: Saybar',
            id: '891'
        },
        {
            ar3: '',
            dt1: 'Safar',
            ar2: 'ܣܰܦܰܪ',
            es3: '',
            bedeutung: 'männl., “Kreuz”',
            es1: 'ܣܦܪ',
            id: '2077',
            dt2: '',
            dt3: '',
            ar1: 'ܣܦܪ',
            es2: 'ܣܦܪ'
        },
        {
            ar3: '',
            ar2: 'ܣܰܒܰܪ',
            bedeutung: 'männl., von Sedro “Ordnung, Klasse, Reihe, Kategorie”',
            id: '2067',
            dt1: 'Sabar',
            dt2: '',
            es3: '',
            es2: 'ܣܒܪ',
            dt3: '',
            es1: 'ܣܒܪ',
            ar1: 'ܣܒܪ'
        },
        {
            ar3: '',
            es1: 'ܪܒܪܬܐ',
            bedeutung: '',
            ar2: 'ܪܳܒܶܪܬܰܐ',
            dt2: '',
            es2: 'ܪܒܪܬܐ',
            dt3: '',
            dt1: 'Roberta',
            es3: '',
            id: '863',
            ar1: 'ܪܒܪܬܐ'
        },
        {
            dt3: 'Assur',
            ar2: 'ܐܰܫܽܘܪ',
            es3: '',
            ar1: 'ܐܫܘܪ',
            dt2: 'Ashur',
            es1: 'ܐܫܘܪ',
            id: '1291',
            es2: 'ܐܫܘܪ',
            bedeutung: 'männl., Asuna war Schüler des Hl. Ephräm, Verfasser vieler Madrosche und Memre',
            ar3: '',
            dt1: 'Aschur'
        },
        {
            ar1: 'ܐܒܝܐ',
            es2: 'ܐܒܝܐ',
            es3: '',
            ar2: 'ܐܰܒܺܝܰܐ',
            dt3: '',
            dt1: 'Abija',
            dt2: '',
            ar3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe ist Vater” oder “(mein) Vater ist Jahwe”. Im AT gilt Abija als Sohn Rehabeams und König von Juda (910-908 v.Chr.); viele Personen tragen diesen Namen; im NT wird Zacharias, der Vater Johannes des Täufers, zur Priesterklasse Abija gerechnet. Weitere Formen: Abia',
            es1: 'ܐܒܝܐ',
            id: '1193'
        },
        {
            es3: '',
            ar1: 'ܪܝܚܢܐ',
            ar3: '',
            ar2: 'ܪܺܝܚܰܢܶܐ',
            dt2: '',
            es1: 'ܪܝܚܢܐ',
            dt1: 'Rihane',
            dt3: '',
            bedeutung: 'weibl., siehe Rihan',
            es2: 'ܪܝܚܢܐ',
            id: '859'
        },
        {
            dt2: '',
            ar2: 'ܐܺܝܒܠܰܐ',
            dt3: '',
            ar3: '',
            ar1: 'ܐܝܒܠܐ',
            id: '495',
            es1: 'ܐܝܒܠܐ',
            es2: 'ܐܝܒܠܐ',
            es3: '',
            dt1: 'Ibla',
            bedeutung: ''
        },
        {
            ar1: 'ܓܒܪܝܐܝܠ ܝܘܣܦ',
            dt3: '',
            es1: 'ܓܒܪܐܝܠ ܝܘܣܦ',
            dt1: 'Gabriel Joseph',
            es3: '',
            id: '1543',
            es2: 'ܓܒܪܐܝܠ ܝܘܣܦ',
            ar2: 'ܓܰܒܪܺܐܝܶܠ ܝܽܘܣܶܦ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott ist meine Vergeltung”. Im NT ist Gamaliel ein angesehener Pharisäer, Gesetzeslehrer zur Zeit der Apostel und Lehrer des Apostels Paulus',
            dt2: '',
            ar3: ''
        },
        {
            ar3: 'ܒܶܪܺܝܬܰܐ',
            dt1: 'Brita',
            es1: 'ܒܪܝܬܐ',
            id: '236',
            bedeutung: 'weibl., “die Geschaffene”, “die Welt”; weitere Formen: Britha, Berita',
            dt3: '',
            es2: 'ܒܪܝܬܐ',
            ar1: 'ܒܪܝܬܐ',
            es3: 'ܒܪܝܬܐ',
            ar2: 'ܒܪܺܝܬܰܐ',
            dt2: ''
        },
        {
            es3: '',
            ar2: 'ܐܳܕܺܝ',
            ar1: 'ܐܕܝ',
            ar3: '',
            id: '1984',
            dt2: '',
            dt3: '',
            dt1: 'Odi',
            es1: 'ܐܕܝ',
            bedeutung: 'männl., mehrere Könige der Aramäer von Samal-Jadi (8. Jh. v. Chr.). Weitere Formen: Panamua',
            es2: 'ܐܕܝ'
        },
        {
            ar3: '',
            bedeutung: '',
            dt1: 'Theodor',
            es1: 'ܬܐܘܕܘܪ',
            ar2: 'ܬܶܐܘܳܕܳܘܪ',
            dt3: '',
            dt2: '',
            id: '2239',
            es3: '',
            ar1: 'ܬܐܘܕܘܪ',
            es2: 'ܬܐܘܕܘܪ'
        },
        {
            id: '2231',
            dt3: 'Terzo',
            ar3: '',
            es3: '',
            dt2: 'Tarzo',
            es2: 'ܬܪܙܐ',
            ar2: 'ܬܰܪܙܳܐ',
            ar1: 'ܬܪܙܐ',
            bedeutung: 'weibl., zu Theodor(os)',
            es1: 'ܬܪܙܐ',
            dt1: 'Tarso'
        },
        {
            es3: '',
            bedeutung: 'weibl. Form zu Brikho',
            ar1: 'ܒܘܠܘܢܐ',
            es2: 'ܒܘܠܘܢܐ',
            dt1: 'Bolona',
            dt3: '',
            ar2: 'ܒܳܘܠܳܘܢܰܐ',
            id: '1413',
            ar3: '',
            es1: 'ܒܘܠܘܢܐ',
            dt2: ''
        },
        {
            ar2: 'ܐܰܢܛܽܘܢ',
            dt2: '',
            es2: 'ܐܢܛܘܢ',
            dt3: '',
            ar3: '',
            dt1: 'Anton',
            ar1: 'ܐܢܛܘܢ',
            es3: '',
            es1: 'ܐܢܛܘܢ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “hoch, der Erhabene?”. Im AT ist Aram der jüngste Sohn von Sem. Die Aramäer leiten sich nach dem AT von Aram ab. Die Söhne Arams sind Uz, Hul, Geter und Masch. Nach dem NT ist Aram ein Ahnherr Jesu. Die Aramäer haben vor Christus viele Stadtstaaten in Aram Beth-Nahrin (=Mesopotamien) und Syrien gegründet; sie heißen heute auch Syrer oder Suryoye',
            id: '1271'
        },
        {
            es1: 'ܐܕܝ',
            ar1: 'ܐܕܝ',
            ar2: 'ܐܰܕܰܝ',
            dt2: 'Adday',
            id: '1210',
            es3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vomame aramäisch-hebräischen Ursprungs; “der Mutige”. Adai ist einer der 70 bzw. 72 Missionare und gilt in der Traditon als der Patron der Aramäer bzw. Christusverkündiger unter den Aramäem Aram Beth-Nahrins (= Mesopotamien). Weitere Formen: Addai, Taddai, Thaddäus',
            dt1: 'Adai',
            es2: 'ܐܕܝ',
            dt3: 'Addai',
            ar3: ''
        },
        {
            es2: 'ܣܦܪܘܬܐ',
            es1: 'ܣܦܪܘܬܐ',
            es3: '',
            bedeutung: 'männl., siehe Schleymun; weitere Formen: Salomon',
            id: '2079',
            dt1: 'Safruta',
            ar1: 'ܣܦܪܘܬܐ',
            dt2: '',
            ar2: 'ܣܰܦܪܽܘܬܰܐ',
            dt3: '',
            ar3: ''
        },
        {
            es3: '',
            es1: 'ܪܚܘܒ',
            dt1: 'Rohub',
            es2: 'ܪܚܘܒ',
            ar3: '',
            dt2: '',
            dt3: '',
            id: '2059',
            bedeutung: 'männl., “der Jesus-Verkünder”',
            ar1: 'ܪܚܘܒ',
            ar2: 'ܪܳܚܽܘܒ'
        },
        {
            bedeutung: 'männl., aramäischen Ursprungs; “Gott weiß“. In der Geschichte war Eljada Vater des aramäischen Königs Reson von Aram-Damaskus (10. Jh. v.Chr.)',
            es1: 'ܐܠܝܐ',
            ar1: 'ܐܠܝܐ',
            ar2: 'ܐܶܠܺܝܳܐ',
            id: '1484',
            ar3: '',
            dt2: 'Elias',
            dt1: 'Elijo',
            es2: 'ܐܠܝܐ',
            dt3: 'Elija',
            es3: ''
        },
        {
            dt1: 'Sahdo',
            ar2: 'ܣܰܥܕܳܐ',
            dt3: '',
            es3: '',
            es1: 'ܣܥܕܐ',
            es2: 'ܣܥܕܐ',
            bedeutung: '',
            ar1: 'ܣܥܕܐ',
            dt2: 'Sado',
            ar3: '',
            id: '2081'
        },
        {
            ar2: 'ܚܶܙܝܳܐܢ',
            ar1: 'ܚܙܝܢ',
            es1: 'ܚܙܝܢ',
            es3: 'ܚܙܝܘܢ',
            bedeutung: 'weibl., syrischen Ursprungs; “Freiheit, Unabhängigkeit”; weitere Formen: Hirutha',
            id: '1659',
            dt2: '',
            dt3: '',
            dt1: 'Hesjon',
            es2: 'ܚܙܝܢ',
            ar3: 'ܚܶܙܝܽܘܢ'
        },
        {
            ar2: 'ܡܰܠܟܺܝܙܰܕܶܩ',
            es1: 'ܡܠܟܝܙܕܩ',
            ar1: 'ܡܠܟܝܙܕܩ',
            id: '653',
            es3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “mein König ist Gerechtigkeit”. Im AT ist Melkisadek ein Priesterkönig von Salem (Jerusalem?), Vorbild Davids und Bild des Messias als König und Priester. Weitere Formen: Malkisedek, Melchisedek, Malkisadeq, Malkisadiqo, Malkosadiqo',
            dt1: 'Malkisadek',
            ar3: '',
            dt2: '',
            es2: 'ܡܠܟܝܙܕܩ',
            dt3: ''
        },
        {
            ar3: '',
            es3: '',
            es2: 'ܓܕܥܘܢ',
            ar1: 'ܓܕܥܘܢ',
            dt2: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “der Baumfäller, der Krieger”; weitere Formen: Gedeon, Ged`un, Gidon',
            dt1: 'Gideon',
            ar2: 'ܓܶܕܥܽܘܢ',
            id: '383',
            dt3: '',
            es1: 'ܓܕܥܘܢ'
        },
        {
            ar1: 'ܘܠܝܬ݂ܐ',
            es3: '',
            ar2: 'ܘܳܠܺܝܬ݂ܰܐ',
            id: '1108',
            bedeutung: 'weibl., “Aufgabe, Dienst, Pﬂicht”; weitere Formen: Wolita',
            dt1: 'Wolitha',
            es2: 'ܘܠܝܬ݂ܐ',
            ar3: '',
            es1: 'ܘܠܝܬ݂ܐ',
            dt3: '',
            dt2: ''
        },
        {
            dt1: 'Atro',
            dt2: '',
            es1: 'ܐܬܪܐ',
            bedeutung: 'männl., “Heimat, Ort, Gegend',
            id: '125',
            es2: 'ܐܬܪܐ',
            ar2: 'ܐܰܬܪܳܐ',
            ar1: 'ܐܬܪܐ',
            dt3: '',
            es3: '',
            ar3: ''
        },
        {
            es3: '',
            dt3: '',
            es2: 'ܢܫܪܬ',
            id: '763',
            es1: 'ܢܫܪܬ',
            dt2: '',
            ar2: 'ܢܶܫܪܰܬ',
            bedeutung: 'männl., “du bist mein Adler”',
            ar1: 'ܢܫܪܬ',
            dt1: 'Neschrat',
            ar3: ''
        },
        {
            es2: 'ܝܕܥ',
            es3: '',
            ar2: 'ܝܰܕܰܥ',
            es1: 'ܝܕܥ',
            dt3: '',
            ar1: 'ܝܕܥ',
            ar3: '',
            dt1: 'Jada',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “er weiß”',
            id: '521',
            dt2: ''
        },
        {
            id: '1449',
            ar1: 'ܕܝܨܐ',
            dt3: '',
            dt2: '',
            es2: 'ܕܝܨܐ',
            es3: '',
            es1: 'ܕܝܨܐ',
            ar2: 'ܕܰܝܨܳܐ',
            dt1: 'Dayso',
            ar3: '',
            bedeutung: ''
        },
        {
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “der Herr ist gnädig” oder “Jahwe hat Gnade erwiesen“. Im NT existieren zwei Personen unter diesem Namen, Johannes der Täufer und Johannes, einer der 12 Apostel Jesu Christi. Weitere Formen: Yuhannon, Yuhannun, Johannun, Johannon, Johanan, Hamıa, Hane, Hanne, Hanno',
            ar2: 'ܝܽܘܚܰܢܳܢ',
            id: '555',
            dt2: 'Yohanen',
            es1: 'ܝܘܚܢܢ',
            es2: 'ܝܘܚܢܢ',
            dt1: 'Johannes',
            es3: 'ܝܘܚܢܘܢ',
            dt3: 'Johanen',
            ar1: 'ܝܘܚܢܢ',
            ar3: 'ܝܽܘܚܰܢܽܘܢ'
        },
        {
            ar3: '',
            dt1: 'Hailo',
            es2: 'ܚܝܠܐ',
            es1: 'ܚܝܠܐ',
            ar1: 'ܚܝܠܐ',
            dt2: '',
            id: '423',
            es3: '',
            ar2: 'ܚܰܝܠܳܐ',
            dt3: '',
            bedeutung: 'männl., syrischen Ursprungs; “Macht Kraft, Energie, Kapazität”; weitere Formen: Haylo'
        },
        {
            dt1: 'Alef',
            id: '69',
            bedeutung: 'männl., “Alef”, erster Buchstabe des syrisch-aramäischen Alphabets',
            dt2: 'Alaf',
            es2: 'ܐܠܦ',
            ar2: 'ܐܰܠܶܦ',
            dt3: 'Olaf',
            ar1: 'ܐܠܦ',
            es1: 'ܐܠܦ',
            ar3: '',
            es3: ''
        },
        {
            es3: '',
            es2: 'ܒܪܡܬ݂ܐ',
            es1: 'ܒܪܡܬ݂ܐ',
            dt3: '',
            id: '173',
            dt2: '',
            dt1: 'Barmotho',
            ar3: '',
            ar1: 'ܒܪܡܬ݂ܐ',
            ar2: 'ܒܰܪܡܳܬ݂ܳܐ',
            bedeutung: 'männl., “Sohn der Nation”'
        },
        {
            es2: 'ܚܝܪܢ',
            bedeutung: 'männl.; Hiran (217-250) von Beth Aramäa (Irak) war Bischof von Arbela. Weitere Formen: Hairan',
            dt3: '',
            es1: 'ܚܝܪܢ',
            ar3: 'ܚܰܝܪܰܢ',
            id: '478',
            ar2: 'ܚܺܝܪܰܢ',
            ar1: 'ܚܝܪܢ',
            dt2: '',
            es3: 'ܚܝܪܢ',
            dt1: 'Hiran'
        },
        {
            es3: '',
            ar2: 'ܢܺܝܫܳܐ',
            dt3: '',
            dt2: '',
            ar1: 'ܢܝܫܐ',
            ar3: '',
            es1: 'ܢܝܫܐ',
            dt1: 'Nischo',
            es2: 'ܢܝܫܐ',
            id: '773',
            bedeutung: 'männl., “Zeichen, Symbol, Flagge”'
        },
        {
            ar3: '',
            es1: 'ܒܪܢܗܪܐ',
            bedeutung: 'männl., “Sohn des Flusses”',
            dt3: '',
            dt1: 'Barnahro',
            es2: 'ܒܪܢܗܪܐ',
            dt2: '',
            id: '175',
            ar1: 'ܒܪܢܗܪܐ',
            es3: '',
            ar2: 'ܒܰܪܢܰܗܪܰܐ'
        },
        {
            dt1: 'Henyono',
            es1: 'ܗܢܝܢܐ',
            es3: '',
            ar1: 'ܗܢܝܢܐ',
            dt3: '',
            bedeutung: 'männl., syrischen Ursprungs; “Wohlbehagen, Belang, Genuß, Gewinn, Nutzen”',
            es2: 'ܗܢܝܢܐ',
            ar3: '',
            id: '469',
            dt2: '',
            ar2: 'ܗܶܢܝܳܢܳܐ'
        },
        {
            id: '762',
            es1: 'ܢܪܝܓܠܝܣܪ',
            ar2: 'ܢܶܪܺܝܓܠܺܝܣܰܪ',
            es3: '',
            es2: 'ܢܪܝܓܠܝܣܪ',
            dt3: '',
            ar1: 'ܢܪܝܓܠܝܣܪ',
            ar3: '',
            dt2: '',
            bedeutung: 'männl., chaldäisch-aramäischer König von Babylonien (559-556 v.Chr.); weitere Formen: Niriglassar',
            dt1: 'Neriglissar'
        },
        {
            ar2: 'ܝܽܗܘܕܺܝܬ',
            dt1: 'Judith',
            es3: 'ܝܗܘܕܝܬ',
            dt3: '',
            es1: 'ܝܗܘܕܝܬ',
            dt2: '',
            id: '1752',
            ar3: 'ܝܶܗܽܘܕܺܝܬ',
            bedeutung: 'weibl., “Bienenwabe”; Weitere Formen: Kakoritha',
            es2: 'ܝܗܘܕܝܬ',
            ar1: 'ܝܗܘܕܝܬ'
        },
        {
            ar3: '',
            dt1: 'Mor Afrem',
            es2: 'ܡܪܝ ܐܦܪܝܡ',
            bedeutung: '',
            es1: 'ܡܪܝ ܐܦܪܝܡ',
            ar2: 'ܡܳܪ ܐܰܦܪܶܝܡ',
            ar1: 'ܡܪܝ ܐܦܪܝܡ',
            dt3: '',
            dt2: 'St. Efrim',
            es3: '',
            id: '1896'
        },
        {
            id: '2005',
            es3: '',
            ar1: 'ܩܘܡܐ',
            es1: 'ܩܘܡܐ',
            ar3: '',
            es2: 'ܩܘܡܐ',
            dt1: 'Qaumo',
            dt2: '',
            ar2: 'ܩܰܘܡܳܐ',
            bedeutung: 'männl., “Hausherr”; weitere Formen: Rabbayto',
            dt3: ''
        },
        {
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gefangener”. Im AT gilt Assir als Nachkomme Levis und Ahnherr Samuels',
            ar1: 'ܐܣܝܪ',
            ar2: 'ܐܰܣܺܝܪ',
            es2: 'ܐܣܝܪ',
            es1: 'ܐܣܝܪ',
            dt3: '',
            ar3: '',
            dt1: 'Assir',
            dt2: '',
            es3: '',
            id: '112'
        },
        {
            dt1: 'Abilo',
            es1: 'ܐܒܝܠܐ',
            es2: 'ܐܒܝܠܐ',
            ar2: 'ܐܰܒܺܝܠܳܐ',
            ar1: 'ܐܒܝܠܐ',
            ar3: '',
            dt2: '',
            id: '1195',
            bedeutung: 'männl., “jemand, der traurig ist, Trauriger”; eine Mönchsrichtung, die sog. “Abile” (die Traurigen, “die Einsiedler”)',
            es3: '',
            dt3: ''
        },
        {
            dt2: 'Noah',
            ar2: 'ܢܽܘܚ',
            id: '776',
            ar1: 'ܢܘܚ',
            dt3: 'Noach',
            dt1: 'Nuh',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Ruhebringer, Tröster”. Nuh ist im AT ein Prophet, der die Sintﬂut in seiner Arche überstand und zum Gründer neuer Volksstämme wurde. Weitere Formen: Noah, Noach',
            es3: '',
            es2: 'ܢܘܚ',
            ar3: '',
            es1: 'ܢܘܚ'
        },
        {
            id: '595',
            dt2: '',
            bedeutung: 'weibl., Killita ist ein Dorf in Turabdin; weitere Formen: Qillita, Killit',
            es1: 'ܩܝܠܠܝܬܐ',
            es2: 'ܩܝܠܠܝܬܐ',
            ar3: '',
            es3: '',
            dt3: '',
            dt1: 'Killita',
            ar2: 'ܩܺܝܠܠܺܝܬܰܐ',
            ar1: 'ܩܝܠܠܝܬܐ'
        },
        {
            es1: 'ܣܡܝܪ',
            dt1: 'Samir',
            ar3: '',
            id: '898',
            dt2: '',
            ar2: 'ܣܰܡܺܝܪ',
            bedeutung: '',
            es2: 'ܣܡܝܪ',
            es3: '',
            dt3: '',
            ar1: 'ܣܡܝܪ'
        },
        {
            dt1: 'Ninos',
            ar3: '',
            bedeutung: '',
            ar2: 'ܢܺܝܢܳܘܣ',
            ar1: 'ܢܝܢܘܣ',
            es3: '',
            id: '769',
            es2: 'ܢܝܢܘܣ',
            dt3: '',
            es1: 'ܢܝܢܘܣ',
            dt2: ''
        },
        {
            bedeutung: 'männl., “Seliger, Guter”; weitere Formen: Tubana',
            ar2: 'ܛܽܘܒܰܢ',
            es1: 'ܛܘܒܢܐ',
            ar1: 'ܛܘܒܢ',
            ar3: '',
            es3: '',
            id: '1075',
            dt1: 'Tuban',
            dt3: '',
            es2: 'ܛܘܒܢܐ',
            dt2: ''
        },
        {
            dt3: '',
            dt1: 'Gregor',
            ar1: 'ܓܪܝܓܘܪ',
            es2: 'ܓܪܝܓܘܪ',
            es1: 'ܓܪܝܓܘܪ',
            bedeutung: '',
            id: '394',
            ar2: 'ܓܪܺܝܓܳܘܪ',
            es3: '',
            ar3: '',
            dt2: 'Grigor'
        },
        {
            ar2: 'ܠܰܒܶܒ',
            es2: 'ܠܒܒ',
            ar1: 'ܠܒܒ',
            id: '1802',
            dt2: '',
            es1: 'ܠܒܒ',
            dt3: '',
            ar3: '',
            es3: '',
            bedeutung: 'weibl., syrischen Ursprungs; “die Mutige, die Tapfere, eine tapfere [Frau]”',
            dt1: 'Labeb'
        },
        {
            es1: 'ܒܠܝ',
            ar2: 'ܒܰܠܰܝ',
            dt2: '',
            es2: 'ܒܠܝ',
            bedeutung: 'männl., wahrscheinlich Schüler des Mor Ephräm, Chorbischof von Aleppound von Bolosch(†432), Dichter, Verfasser vieler Madrosche (Gedichte); weitere Formen: Balay',
            id: '148',
            dt3: '',
            ar1: 'ܒܠܝ',
            es3: '',
            ar3: '',
            dt1: 'Balai'
        },
        {
            es1: 'ܐܠܝܟܝܡ',
            ar2: 'ܐܶܠܝܰܟܺܝܡ',
            es2: 'ܐܠܝܟܝܡ',
            ar1: 'ܐܠܝܟܝܡ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott stellt aufrecht”. Weitere Formen: Eljaqim',
            es3: 'ܐܠܝܩܝܡ',
            id: '307',
            dt1: 'Eljakim',
            dt3: '',
            dt2: 'Eljaqim',
            ar3: 'ܐܶܠܝܰܩܺܝܡ'
        },
        {
            ar1: 'ܥܘܒܕܝܐ',
            dt2: '',
            id: '1981',
            es2: 'ܥܘܒܕܝܐ',
            es1: 'ܥܘܒܕܝܐ',
            dt1: 'Obadja',
            ar3: '',
            es3: '',
            bedeutung: 'männl., westsyr. Form zu Aram',
            dt3: '',
            ar2: 'ܥܽܘܒܰܕܝܳܐ'
        },
        {
            bedeutung: 'weibl., “Venus, Morgenstern” oder “Kupfer”; weitere Formen: Bilati',
            es3: 'ܒܗܢܐܢ',
            es1: 'ܒܗܢܡ',
            ar1: 'ܒܗܢܡ',
            id: '1394',
            es2: 'ܒܗܢܡ',
            dt3: '',
            dt1: 'Behnam',
            ar3: 'ܒܶܗܢܐܰܢ',
            ar2: 'ܒܶܗܢܐܰܡ',
            dt2: ''
        },
        {
            dt1: 'Baril',
            dt3: '',
            ar3: '',
            ar1: 'ܒܪܐܝܠ',
            es1: 'ܒܪܐܝܠ',
            id: '1348',
            ar2: 'ܒܰܪܐܺܝܠ',
            es3: '',
            bedeutung: 'männl., “Sohn des Gerechten”',
            dt2: 'Bar-Il',
            es2: 'ܒܪܐܝܠ'
        },
        {
            dt1: 'Dimta',
            es2: 'ܕܝܡܬܐ',
            bedeutung: 'weibl., “Nebel, Trübung, Dunst”; weitere Formen: Dimtha',
            dt2: 'Dimtha',
            dt3: '',
            id: '273',
            ar1: 'ܕܝܡܬܐ',
            ar2: 'ܕܺܝܡܬܰܐ',
            es1: 'ܕܝܡܬܐ',
            ar3: '',
            es3: ''
        },
        {
            es3: 'ܐܙܪܐ',
            dt3: 'Asra',
            ar1: 'ܥܙܪܐ',
            ar3: 'ܐܰܙܪܰܐ',
            dt1: 'Esra',
            ar2: 'ܥܶܙܪܰܐ',
            bedeutung: 'männl., “Panther, Leopard”',
            id: '1507',
            dt2: 'Esro',
            es1: 'ܥܙܪܐ',
            es2: 'ܥܙܪܐ'
        },
        {
            dt1: 'Tamimta',
            ar1: 'ܬܡܝܡܬܐ',
            ar3: '',
            dt2: '',
            id: '1036',
            es2: 'ܬܡܝܡܬܐ',
            ar2: 'ܬܰܡܺܝܡܬܰܐ',
            es3: '',
            bedeutung: 'weibl., “die Unschuldige, die Vollkommene”',
            dt3: '',
            es1: 'ܬܡܝܡܬܐ'
        },
        {
            ar2: 'ܣܰܒܪܰܬ',
            es2: 'ܣܒܪܬ',
            dt1: 'Sabrat',
            bedeutung: 'weibl., “Reinheit, Keuschheit”',
            id: '2071',
            ar1: 'ܣܒܪܬ',
            dt2: '',
            es1: 'ܣܒܪܬ',
            es3: '',
            ar3: '',
            dt3: ''
        },
        {
            dt3: 'Ibrahim',
            dt1: 'Abrohom',
            es2: 'ܐܒܪܗܡ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “der Vater liebt, Vater der Völker, Vater der Gläubigen”. Im AT gilt Abrohom als Stammvater der Israeliten, der ursprünglich Abram hieß (zw. 2000 und 1400 v.Chr.); Abrohom war ein Aramäer aus Aram-Naharaim (= Mesopotamien). Weitere Formen: Abraham, Ibrahim, Brahim',
            ar3: '',
            es1: 'ܐܒܪܗܡ',
            ar2: 'ܐܰܒܪܳܗܳܡ',
            dt2: 'Abraham',
            id: '29',
            ar1: 'ܐܒܪܗܡ',
            es3: ''
        },
        {
            dt3: '',
            bedeutung: 'männl., “Gott ist erhaben”; weitere Formen: Romil',
            ar1: 'ܪܡܐܝܠ',
            es1: 'ܪܡܐܝܠ',
            es2: 'ܪܡܐܝܠ',
            ar2: 'ܪܰܡܺܐܝܠ',
            dt2: '',
            dt1: 'Ramil',
            id: '842',
            es3: 'ܪܡܐܝܠ',
            ar3: 'ܪܳܡܺܐܝܠ'
        },
        {
            ar3: '',
            ar1: 'ܓܒܪܐܝܠܐ',
            dt1: 'Gabriela',
            es3: '',
            dt2: '',
            bedeutung: 'weibl. Form von Gabriel',
            es1: 'ܓܒܪܐܝܠܐ',
            es2: 'ܓܒܪܐܝܠܐ',
            id: '357',
            ar2: 'ܓܰܒܪܺܐܝܶܠܰܐ',
            dt3: ''
        },
        {
            ar1: 'ܬܪܚ',
            es1: 'ܬܪܚ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs. Im AT ist Tarah Nachkomme Sems und Vater Abrahams aus Paddan-Aram; im NT ist Tarah Ahnherr Jesu. Weitere Formen: Toroh, Terach, Tarach',
            es2: 'ܬܪܚ',
            dt3: '',
            dt1: 'Tarah',
            ar2: 'ܬܰܪܰܚ',
            es3: 'ܬܪܚ',
            id: '1037',
            dt2: 'Tara',
            ar3: 'ܬܳܪܳܚ'
        },
        {
            dt3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Träger, Jahwe hat dich getragen”. Im AT ist Amos ein Prophet (um 750 v.Chr.), im NT Ahnherr Jesu',
            ar2: 'ܥܰܡܳܘܢܝܰܐ',
            es2: 'ܥܡܘܢܝܐ',
            ar3: '',
            id: '1261',
            ar1: 'ܥܡܘܢܝܐ',
            dt1: 'Ammonja',
            dt2: '',
            es1: 'ܥܡܘܢܝܐ',
            es3: ''
        },
        {
            ar2: 'ܙܰܗܪܳܐ',
            ar1: 'ܙܗܪܐ',
            es2: 'ܙܗܪܐ',
            dt3: '',
            es3: '',
            id: '1152',
            dt2: '',
            es1: 'ܙܗܪܐ',
            ar3: '',
            dt1: 'Zahro',
            bedeutung: 'männl., “Licht, Strahl, Leuchte, Pracht, Glanz”'
        },
        {
            dt3: '',
            es3: '',
            es1: 'ܐܫܪ',
            id: '107',
            ar2: 'ܐܰܫܰܪ',
            dt1: 'Aschar',
            bedeutung: 'männl., “glauben, vertrauen”',
            dt2: '',
            ar3: '',
            ar1: 'ܐܫܪ',
            es2: 'ܐܫܪ'
        },
        {
            dt3: '',
            es1: 'ܓܒܝܬܐ',
            es3: '',
            ar1: 'ܓܒܝܬܐ',
            ar3: '',
            ar2: 'ܓܒܺܝܬܰܐ',
            bedeutung: 'weibl., Geschtiyana war Schwester des Gottes Tammus/ Adonis',
            es2: 'ܓܒܝܬܐ',
            dt1: 'Gbita',
            dt2: '',
            id: '1557'
        },
        {
            es2: 'ܝܠܐ',
            es1: 'ܝܠܐ',
            ar2: 'ܝܰܠܳܐ',
            es3: '',
            dt3: '',
            ar3: '',
            dt2: '',
            id: '2315',
            ar1: 'ܝܠܐ',
            bedeutung: 'männl., siehe Joseph',
            dt1: 'Yalo'
        },
        {
            dt3: '',
            dt1: 'Kinoro',
            es2: 'ܟܢܪܐ',
            ar3: 'ܟܺܝܢܳܪܳܐ',
            dt2: '',
            es3: 'ܟܝܢܪܐ',
            bedeutung: 'weibl., syrischen Ursprungs; “Rührkuchen”; weitere Formen: Kukalta',
            es1: 'ܟܢܪܐ',
            id: '1786',
            ar1: 'ܟܢܪܐ',
            ar2: 'ܟܶܢܳܪܳܐ'
        },
        {
            dt1: 'Kalonita',
            dt2: '',
            es2: 'ܟܠܢܝܬܐ',
            ar3: 'ܟܰܠܽܘܢܺܝܬܰܐ',
            ar2: 'ܟܰܠܳܢܺܝܬܰܐ',
            es1: 'ܟܠܢܝܬܐ',
            bedeutung: 'weibl., “wilde Blume”; weitere Formen: Kalunita, Kalonitha, Kalunitha',
            id: '576',
            es3: 'ܟܠܘܢܝܬܐ',
            ar1: 'ܟܠܢܝܬܐ',
            dt3: ''
        },
        {
            dt3: '',
            dt1: 'Barkipho',
            ar2: 'ܒܰܪܟܺܐܦܳܐ',
            es2: 'ܒܪܟܐܦܐ',
            id: '171',
            ar3: '',
            es3: '',
            ar1: 'ܒܪܟܐܦܐ',
            dt2: 'Barkepha ',
            bedeutung: 'männl., “Sohn des Felses, Sohn des Steines”. Barkipho/Barkepha ist der Beiname bzw. Familienname des Bischofs und Liturgikers Musche (Moses) Barkipho (†903)',
            es1: 'ܒܪܟܐܦܐ'
        },
        {
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäischen Ursprungs; “Sohn des Vaters”. Im NT ist Barabba ein Verbrecher in römischer Gefangenschaft, dessen Freilassung die Juden gegen Jesus verlangten. Weitere Formen: Barabbas',
            ar3: '',
            ar2: 'ܒܰܪܐܰܒܰܐ',
            id: '156',
            es3: '',
            ar1: 'ܒܪܐܒܐ',
            dt2: '',
            dt3: '',
            dt1: 'Barabba',
            es2: 'ܒܪܐܒܐ',
            es1: 'ܒܪܐܒܐ'
        },
        {
            es1: 'ܦܝܪܝܐܢ',
            ar2: 'ܦܺܝܪܝܰܐܢ',
            es2: 'ܦܝܪܝܐܢ',
            dt1: 'Firian',
            dt3: '',
            ar3: 'ܦܶܝܪܝܰܐܢ',
            dt2: '',
            id: '343',
            bedeutung: 'männl., “unsere Fruchtbarkeit”; weitere Formen: Ferian, Feryan',
            ar1: 'ܦܝܪܝܐܢ',
            es3: 'ܦܝܪܝܐܢ'
        },
        {
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott ist Hilfe“. Im AT ist Esra Priester und Hauptgestalt des nach ihm benannten alttestamentlichen Buches. Weitere Formen: Esro, Ezra, Asra',
            es2: 'ܥܢܢܝܫܘܥ',
            ar3: '',
            ar1: 'ܥܢܢܝܫܘܥ',
            dt3: '',
            ar2: 'ܥܢܳܢܝܶܫܽܘܥ',
            dt1: 'Enonjeschu',
            es1: 'ܥܢܢܝܫܘܥ',
            dt2: 'Hnonjeschu',
            es3: '',
            id: '1500'
        },
        {
            dt3: '',
            ar3: '',
            ar2: 'ܢܽܘܪܡܰܬܰܐ',
            dt2: '',
            ar1: 'ܢܘܪܡܬܐ',
            dt1: 'Nurmata',
            bedeutung: 'weibl., “Feuer, Licht der Heimat, der Nation”',
            es1: 'ܢܘܪܡܬܐ',
            es3: '',
            es2: 'ܢܘܪܡܬܐ',
            id: '786'
        },
        {
            ar1: 'ܓܠܓܐܡܝܫ',
            es2: 'ܓܠܓܐܡܝܫ',
            dt1: 'Gelgamesch',
            bedeutung: '',
            dt2: 'Gilgamesch',
            id: '373',
            es3: '',
            es1: 'ܓܠܓܐܡܝܫ',
            ar3: '',
            dt3: '',
            ar2: 'ܓܠܓܐܡܝܫ'
        },
        {
            es3: '',
            bedeutung: 'weibl., “zerreißen, aufreissen, platzen, bersten” oder “Tiefe, Strom, Strombett, Gewässer”. Euphrat ist ein Strom/Fluss in Aram Beth-Nahrin (= Mesopotamien). Weitere Formen: Eufrat, Froth',
            es2: 'ܐܪܡܝܐ',
            ar3: '',
            dt2: '',
            es1: 'ܐܪܡܝܐ',
            dt1: 'Eramja',
            ar1: 'ܐܪܡܝܐ',
            dt3: '',
            id: '1502',
            ar2: 'ܐܶܪܰܡܝܰܐ'
        },
        {
            es1: 'ܒܪܚܝܠܐ',
            ar2: 'ܒܰܪܚܰܝܠܳܐ',
            dt3: 'Barhajlo',
            ar1: 'ܒܪܚܝܠܐ',
            dt1: 'Barhailo',
            bedeutung: 'männl., “Sohn der Liebe”',
            ar3: '',
            id: '1344',
            dt2: 'Barhaylo',
            es3: '',
            es2: 'ܒܪܚܝܠܐ'
        },
        {
            bedeutung: 'männl., Nebenform zu Julius',
            es1: 'ܝܘܠܝܐܢ',
            id: '566',
            es2: 'ܝܘܠܝܐܢ',
            ar1: 'ܝܘܠܝܐܢ',
            dt1: 'Julian',
            ar3: '',
            dt3: '',
            dt2: '',
            es3: '',
            ar2: 'ܝܽܘܠܝܰܐܢ'
        },
        {
            es2: 'ܫܠܡ',
            dt1: 'Schalem',
            ar3: '',
            ar2: 'ܫܰܠܶܡ',
            es1: 'ܫܠܡ',
            ar1: 'ܫܠܡ',
            es3: '',
            id: '2121',
            dt2: '',
            dt3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs. Im, AT ist Schamir ein Levit, eine Ortschaft im Bergland Juda, eine Stadt im Gebirge Efraim. Weitere Formen: Schomir'
        },
        {
            ar1: 'ܕܟܝܘܬܐ',
            es1: 'ܕܟܝܘܬܐ',
            dt3: '',
            id: '1432',
            ar3: '',
            es2: 'ܕܟܝܘܬܐ',
            dt1: 'Dakyouta',
            es3: '',
            dt2: '',
            bedeutung: 'weibl., “dünne(s), feine(s), liebe(s), kostbare(s) [Frau/Mädchen]”',
            ar2: 'ܕܰܟܝܽܘܬܰܐ'
        },
        {
            dt1: 'Nisane',
            ar1: 'ܢܝܣܐܢܐ',
            es2: 'ܢܝܣܢܐ',
            bedeutung: 'weibl., “April” oder/und “Frühling”',
            ar2: 'ܢܺܝܣܰܢܶܐ',
            dt2: 'Nissane',
            es1: 'ܢܝܣܢܐ',
            ar3: '',
            id: '772',
            es3: '',
            dt3: ''
        },
        {
            id: '199',
            ar1: 'ܒܣܝܡܐ',
            dt1: 'Basimo',
            dt3: '',
            ar2: 'ܒܰܣܺܝܡܳܐ',
            bedeutung: 'männl., siehe Basim',
            es2: 'ܒܣܝܡܐ',
            dt2: '',
            ar3: '',
            es1: 'ܒܣܝܡܐ',
            es3: ''
        },
        {
            ar3: '',
            es2: 'ܬܗܪܬ',
            dt3: '',
            bedeutung: 'männl., “du bist ein Wunder”',
            dt2: '',
            ar1: 'ܬܗܪܬ',
            es3: '',
            id: '1044',
            dt1: 'Tehrat',
            es1: 'ܬܗܪܬ',
            ar2: 'ܬܶܗܪܰܬ'
        },
        {
            ar3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “rechte [Hand] = gute Seite“, “Glück”, “Süden, Teman, Taymno”',
            dt3: '',
            dt2: '',
            ar2: 'ܝܰܡܺܝܢ',
            ar1: 'ܝܡܝܢ',
            es3: '',
            dt1: 'Jamin',
            es2: 'ܝܡܝܢ',
            id: '527',
            es1: 'ܝܡܝܢ'
        },
        {
            dt3: '',
            es1: 'ܐܢܟܝܕܐ',
            ar3: '',
            id: '1267',
            dt1: 'Ankido',
            es2: 'ܐܢܟܝܕܐ',
            es3: '',
            dt2: '',
            ar1: 'ܐܢܟܝܕܐ',
            bedeutung: '',
            ar2: 'ܐܰܢܟܺܕܳܐ'
        },
        {
            ar2: 'ܫܶܡܫܽܘܢ',
            es3: 'ܣܡܣܘܢ',
            es1: 'ܫܡܫܘܢ',
            bedeutung: 'männl., siehe Schemschun',
            ar1: 'ܫܡܫܘܢ',
            dt2: '',
            ar3: 'ܣܰܡܣܳܘܢ',
            es2: 'ܫܡܫܘܢ',
            id: '902',
            dt1: 'Samson',
            dt3: ''
        },
        {
            dt2: '',
            dt1: 'Schafyat',
            dt3: '',
            ar1: 'ܫܦܝܬ',
            es1: 'ܫܦܝܬ',
            id: '2115',
            ar3: '',
            es3: '',
            es2: 'ܫܦܝܬ',
            bedeutung: 'weibl., “du bist meine Vollendung”. Die Tochter der Meherdat, die Frau des Abgar Ukkomo, Königin von Edessa',
            ar2: 'ܫܰܦܝܰܬ'
        },
        {
            es2: 'ܥܬܬܐ',
            dt3: '',
            ar3: '',
            ar1: 'ܥܬܬܐ',
            es1: 'ܥܬܬܐ',
            bedeutung: 'männl, Heiliger der syrisch-orthodoxen Kirche (†363); Festtag: 20. April; weitere Formen: Augin, Avgin, Marawge, Maravge',
            dt2: '',
            dt1: 'Atto',
            ar2: 'ܥܰܬܬܳܐ',
            es3: '',
            id: '1306'
        },
        {
            es2: 'ܝܘܣܦܝܢܐ',
            ar3: 'ܝܳܙܶܦܺܝܢܰܐ',
            ar1: 'ܝܘܣܦܝܢܐ',
            dt3: '',
            dt2: '',
            bedeutung: '',
            es1: 'ܝܘܣܦܝܢܐ',
            ar2: 'ܝܰܘܣܶܦܺܝܢܰܐ',
            id: '1749',
            dt1: 'Josefina',
            es3: 'ܝܙܦܝܢܐ'
        },
        {
            ar2: 'ܚܰܢܢܰܐ',
            dt2: 'Hanne',
            ar3: 'ܚܰܢܢܶܐ',
            dt1: 'Hanna',
            es1: 'ܚܢܢܐ',
            bedeutung: 'männl.; ursprünglich: weibl., Kurzform zu Johanna; heute Kurzform zu Johannes/Johannun, aramäisch-hebräischen Ursprungs. Im AT begegnet Hanna alsdie Mutter des Samuel und als die Frau des Tobias. Weitere Formen: Johannes, Johamıun, Johannon; Yuhamun, Yuhannon, Hane, Hanne, Hanno',
            ar1: 'ܚܢܢܐ',
            es2: 'ܚܢܢܐ',
            dt3: '',
            es3: 'ܚܢܢܐ',
            id: '439'
        },
        {
            es1: 'ܣܛܝܦܐ',
            ar3: '',
            es2: 'ܣܛܝܦܐ',
            dt3: '',
            bedeutung: 'männl., Syros oder Kyros (Cyros) war König der Achämeniden (559 - 529 v. Chr.), der das persische Reich gründete. Er half den Juden, die in der babylonischen Gefangenschaft waren, nach Judäa zurückzukehren und den Tempel von Jerusalem wiederaufzubauen. Nach einer Theorie sollen sich die Aramäer nach seinem Namen in Syrer umbenannt haben',
            es3: '',
            dt2: 'Steyfo',
            dt1: 'Stayfo',
            ar2: 'ܣܛܰܝܦܳܐ',
            ar1: 'ܣܛܝܦܐ',
            id: '2200'
        },
        {
            dt2: '',
            ar1: 'ܢܐܪܒܐ',
            es3: '',
            ar2: 'ܢܺܐܪܒܳܐ',
            bedeutung: 'männl., “Gipfel, Spitze, Höhepunkt, Schirm, Tal, Abgrund”',
            es2: 'ܢܐܪܒܐ',
            id: '771',
            dt3: '',
            es1: 'ܢܐܪܒܐ',
            ar3: '',
            dt1: 'Nirbo'
        },
        {
            es3: '',
            es2: 'ܓܐܢ ܐܝܫܠܪ',
            dt1: 'Can Isler',
            es1: 'ܓܐܢ ܐܝܫܠܪ',
            bedeutung: '',
            ar3: '',
            ar2: 'ܓܰܐܢ ܐܺܝܫܠܶܪ',
            dt3: '',
            ar1: 'ܓܐܢ ܐܝܫܠܪ',
            dt2: '',
            id: '242'
        },
        {
            ar3: '',
            es2: 'ܢܒܘܨܪܕܢ',
            dt1: 'Nabosaradan',
            es3: '',
            es1: 'ܢܒܘܨܪܕܢ',
            dt3: '',
            ar1: 'ܢܒܘܨܪܕܢ',
            ar2: 'ܢܰܒܳܘܨܰܪܰܕܰܢ',
            id: '1922',
            dt2: '',
            bedeutung: 'männl., syrischen Ursprungs; “Jäger, Kämpfer”'
        },
        {
            es1: 'ܐܕܪ',
            ar3: '',
            dt1: 'Odar',
            dt2: '',
            ar1: 'ܐܕܪ',
            bedeutung: '',
            ar2: 'ܐܳܕܰܪ',
            dt3: '',
            id: '1982',
            es2: 'ܐܕܪ',
            es3: ''
        },
        {
            ar2: 'ܚܰܘܳܐ',
            es2: 'ܚܘܐ',
            ar1: 'ܚܘܐ',
            dt2: 'Hawo',
            id: '1510',
            bedeutung: 'weibl., griechischen Ursprungs; “Buch, Schreibtafel, Täfelchen, Briet”; weitere Formen: Panqita, Panqitha',
            es3: '',
            es1: 'ܚܘܐ',
            ar3: '',
            dt1: 'Eva',
            dt3: ''
        },
        {
            ar1: 'ܚܘܒܐ',
            dt3: '',
            es1: 'ܚܘܒܐ',
            ar2: 'ܚܽܘܒܳܐ',
            dt1: 'Hubo',
            dt2: '',
            es2: 'ܚܘܒܐ',
            id: '486',
            es3: 'ܚܘܒܐ',
            bedeutung: 'männl., ”Liebe“, weitere Formen: Huba',
            ar3: 'ܚܽܘܒܰܐ'
        },
        {
            ar2: 'ܫܳܡܺܝܪ',
            es1: 'ܫܡܝܪ',
            es3: '',
            id: '2163',
            es2: 'ܫܡܝܪ',
            ar3: '',
            ar1: 'ܫܡܝܪ',
            dt1: 'Schomir',
            bedeutung: 'weibl., “Wunde, Schwellung”; weitere Formen: Schumtha',
            dt2: '',
            dt3: ''
        },
        {
            ar1: 'ܢܘܪܝ',
            ar3: '',
            id: '1976',
            bedeutung: 'männl., westsyr. Form zu Adam',
            ar2: 'ܢܽܘܪܺܝ',
            dt1: 'Nuri',
            dt2: '',
            es2: 'ܢܘܪܝ',
            es3: '',
            es1: 'ܢܘܪܝ',
            dt3: ''
        },
        {
            dt3: '',
            es2: 'ܕܪܝܐ',
            ar3: '',
            dt2: '',
            es3: '',
            es1: 'ܕܪܝܐ',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs, “die Biene”. Im AT ist Debora Prophetin und Richterin (um 1200 v.Chr.), Mutter des Hananel, Ahnfrau des Tobit und Tobias',
            dt1: 'Dario',
            ar2: 'ܕܰܪܝܳܐ',
            ar1: 'ܕܪܝܐ',
            id: '1444'
        },
        {
            es2: 'ܣܒܝܚܐ',
            dt2: '',
            ar1: 'ܣܒܝܚܐ',
            bedeutung: 'weibl. Form zu Safar',
            dt3: '',
            ar3: '',
            dt1: 'Sabiha',
            es3: '',
            id: '2069',
            ar2: 'ܣܰܒܺܝܚܰܐ',
            es1: 'ܣܒܝܚܐ'
        },
        {
            dt3: '',
            ar2: 'ܟܶܣܶܕ',
            id: '1793',
            dt2: '',
            ar3: 'ܟܳܣܳܪ',
            dt1: 'Kosod',
            es1: 'ܟܣܕ',
            bedeutung: 'männl., “trösten, anregen, bemutigen, anmutigen”',
            es3: 'ܟܣܪ',
            es2: 'ܟܣܕ',
            ar1: 'ܟܣܕ'
        },
        {
            es1: 'ܙܝܠܘܝܐ',
            es3: 'ܣܝܠܘܝܐ',
            dt3: '',
            id: '2186',
            dt1: 'Silvia',
            es2: 'ܙܝܠܘܝܐ',
            ar3: 'ܣܝܠܘܝܐ',
            ar1: 'ܙܝܠܘܝܐ',
            bedeutung: 'männl., “Märtyrer, Zeuge”',
            ar2: 'ܙܝܠܘܐܝܐ',
            dt2: ''
        },
        {
            ar3: 'ܢܽܘܪܗܰܕܰܕ',
            es3: 'ܢܘܪܗܕܕ',
            dt2: 'Nurhadad',
            es2: 'ܢܘܪܕܕ',
            es1: 'ܢܘܪܕܕ',
            id: '782',
            dt1: 'Nuradad',
            ar1: 'ܢܘܪܕܕ',
            dt3: '',
            ar2: 'ܢܽܘܪܚܰܕܰܕ',
            bedeutung: 'männl., “Hadad ist Feuer, Licht“. Nuradad war ein aramäischer König von Turabdin (9. Jh. v.Chr.). Weitere Formen: Nurhadad'
        },
        {
            dt1: 'Tabita',
            ar2: 'ܛܰܒܺܝܬܰܐ',
            es3: '',
            ar1: 'ܛܒܝܬܐ',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Tabja: Gazelle, Hirsch, Reh, Antilope”. Tabita war nach der Apostelgeschichte eine Jüngerin, die von Petrus vom Tode auferweckt wurde. Weitere Formen: Tabea, Tabitha, Tabyo, Tabia, Tabiya',
            id: '1025',
            ar3: '',
            es1: 'ܛܒܝܬܐ',
            dt3: '',
            dt2: '',
            es2: 'ܛܒܝܬܐ'
        },
        {
            ar3: 'ܕܰܐܢܳܐ',
            dt3: '',
            dt2: '',
            bedeutung: 'männl., “Streit, Debatte, Kampf, Zwist”',
            ar2: 'ܢܰܢܳܐ',
            id: '1440',
            es1: 'ܕܢܐ',
            ar1: 'ܕܢܐ',
            es2: 'ܕܢܐ',
            dt1: 'Dano',
            es3: 'ܕܐܢܐ'
        },
        {
            ar2: 'ܚܰܙܰܐܝܶܠ',
            es1: 'ܚܙܐܝܠ',
            dt1: 'Hasael',
            ar1: 'ܚܙܐܝܠ',
            dt2: '',
            dt3: '',
            id: '447',
            es2: 'ܚܙܐܝܠ',
            ar3: '',
            es3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott hat gesehen”; Hasael war König von Aram (Aram-Damaskus) (843-797 v.Chr.). Weitere Formen: Hasayel, Hasail, Hassail, Hazael, Hazayel, Hasaja'
        },
        {
            es2: 'ܣܦܪܐ',
            id: '886',
            ar2: 'ܣܰܦܪܶܐ',
            ar3: '',
            bedeutung: 'weibl. Form zu Safar',
            es3: '',
            dt1: 'Safre',
            dt3: '',
            es1: 'ܣܦܪܐ',
            dt2: '',
            ar1: 'ܣܦܪܐ'
        },
        {
            dt3: '',
            ar3: 'ܓܰܐܒܰܪ',
            es2: 'ܓܒܪ',
            bedeutung: 'männl., “offenbart, verklärt”',
            es3: 'ܓܐܒܪ',
            dt2: '',
            dt1: 'Gabor',
            ar2: 'ܓܰܒܳܪ',
            ar1: 'ܓܒܪ',
            id: '1541',
            es1: 'ܓܒܪ'
        },
        {
            es1: 'ܛܘܪܝܬܐ',
            id: '1080',
            dt1: 'Turayta',
            dt2: '',
            ar3: '',
            es3: '',
            ar1: 'ܛܘܪܝܬܐ',
            es2: 'ܛܘܪܝܬܐ',
            bedeutung: 'weibl., “Bewohnerin aus Turabdin, aus den Bergen”; weitere Formen: Turaita, Turajta',
            ar2: 'ܛܽܘܪܰܝܬܰܐ',
            dt3: ''
        },
        {
            bedeutung: 'weibl., Nonne, Märtyrerin (†3./4. Jh.); weitere Formen: Fabro, Febro',
            ar3: '',
            id: '1506',
            es3: '',
            ar1: 'ܐܣܪܬܐ',
            dt1: 'Esorta',
            es1: 'ܐܣܪܬܐ',
            es2: 'ܐܣܪܬܐ',
            dt2: '',
            ar2: 'ܐܶܣܳܪܬܰܐ',
            dt3: ''
        },
        {
            es3: '',
            ar1: 'ܟܐܢܐ',
            dt2: '',
            ar2: 'ܟܺܐܢܳܐ',
            id: '1785',
            bedeutung: '',
            es2: 'ܟܐܢܐ',
            dt1: 'Kino',
            ar3: '',
            dt3: '',
            es1: 'ܟܐܢܐ'
        },
        {
            ar2: 'ܢܶܡܪܰܬ',
            bedeutung: '',
            dt1: 'Nemrat',
            id: '1951',
            ar3: '',
            es2: 'ܢܡܪܬ',
            dt3: '',
            es1: 'ܢܡܪܬ',
            ar1: 'ܢܡܪܬ',
            es3: '',
            dt2: ''
        },
        {
            id: '492',
            es1: 'ܚܘܫܡ',
            ar3: '',
            dt3: '',
            dt2: '',
            es3: '',
            ar1: 'ܚܘܫܡ',
            dt1: 'Huscham',
            ar2: 'ܚܽܘܫܰܡ',
            es2: 'ܚܘܫܡ',
            bedeutung: 'männl., “er hat zu Abend gegessen”'
        },
        {
            ar3: '',
            dt3: '',
            bedeutung: 'männl., aus dem Griechischen übernommener Vorname; “philos“: Freund und ”hippos“: Pferd, “Pferdeliebhaber”. Im NT ist Philippos einer der 12 Jünger Jesu Christi. Weitere Formen: Filippos, Philippus',
            ar1: 'ܐܪܡ ܓܒܪܐܝܠ',
            es1: 'ܐܪܡ ܓܒܪܐܝܠ',
            dt2: 'Aram Gabriel',
            es3: '',
            ar2: 'ܐܳܪܳܡ ܓܰܒܪܺܐܝܶܠ',
            dt1: 'Orom Gabriel',
            es2: 'ܐܪܡ ܓܒܪܐܝܠ',
            id: '1991'
        },
        {
            dt1: 'Gad',
            es3: 'ܓܕܐ',
            dt3: '',
            es2: 'ܓܕ',
            es1: 'ܓܕ',
            ar3: 'ܓܰܕܳܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Glück”. Im AT begegnet Gad als aramäischer Gott des Glücks, als Sohn Jakobs und der Silpa und als ein Prophet. Weitere Formen: Gado',
            ar2: 'ܓܰܕ',
            id: '359',
            ar1: 'ܓܕ',
            dt2: ''
        },
        {
            dt1: 'Buhrana',
            bedeutung: 'männl., Bus ist im AT Sohn Nahors, Bruder des Us/Uz, Kemuel, Kesed, Haso, Pildasch, Jidlaf und Betuel. Weitere Formen: Buz',
            ar3: '',
            dt2: '',
            id: '1418',
            es3: '',
            ar2: 'ܒܽܘܚܪܰܢܰܐ',
            ar1: 'ܒܘܚܪܢܐ',
            es1: 'ܒܘܚܪܢܐ',
            es2: 'ܒܘܚܪܢܐ',
            dt3: ''
        },
        {
            dt3: '',
            es1: 'ܥܙܪܐ',
            ar3: '',
            dt2: '',
            id: '327',
            ar2: 'ܥܶܙܪܰܐ',
            ar1: 'ܥܙܪܐ',
            es2: 'ܥܙܪܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Usprungs; “Hilfe”. Im AT ﬁIhrt der Priester Ezra die Juden aus der babylonischen Gefangenschaft. Weitere Formen: Esra',
            es3: '',
            dt1: 'Ezra'
        },
        {
            bedeutung: '',
            dt2: '',
            es2: 'ܡܪܕܘܟ',
            id: '1851',
            es3: '',
            ar2: 'ܡܪܕܘܟ',
            dt1: 'Marduk',
            ar3: '',
            ar1: 'ܡܪܕܘܟ',
            dt3: '',
            es1: 'ܡܪܕܘܟ'
        },
        {
            es1: 'ܡܪܝܢܐ',
            dt1: 'Marina',
            ar2: 'ܡܰܪܺܝܢܰܐ',
            dt2: '',
            id: '671',
            es3: '',
            bedeutung: '',
            dt3: '',
            es2: 'ܡܪܝܢܐ',
            ar1: 'ܡܪܝܢܐ',
            ar3: ''
        },
        {
            id: '2301',
            es3: '',
            bedeutung: 'männl., aramäisch-chaldäischer Stamm/Staat; weitere Formen: Yaqin, Iaqin, Jakin',
            dt1: 'Yabeb',
            ar3: '',
            ar2: 'ܝܰܒܶܒ',
            es2: 'ܝܒܒ',
            es1: 'ܝܒܒ',
            dt2: '',
            dt3: '',
            ar1: 'ܝܒܒ'
        },
        {
            ar2: 'ܡܽܘܣܰܐ',
            ar1: 'ܡܘܣܐ',
            es1: 'ܡܘܣܐ',
            id: '1914',
            ar3: '',
            dt1: 'Musa',
            bedeutung: '',
            dt3: '',
            es3: '',
            dt2: 'Mousa',
            es2: 'ܡܘܣܐ'
        },
        {
            es1: 'ܡܪܝ ܐܝܘܢܢܝܣ ܐܦܪܝܡ ܒܝܠܓܝܓ',
            ar1: 'ܡܪܝ ܐܝܘܢܢܝܣ ܐܦܪܝܡ ܒܝܠܓܝܓ',
            ar3: '',
            dt1: 'Mor Iyawanis Afrem Bilgic',
            dt2: '',
            ar2: 'ܡܪܝ ܐܝܘܢܢܝܣ ܐܦܪܝܡ ܒܝܠܓܝܓ',
            id: '1900',
            es3: '',
            es2: 'ܡܪܝ ܐܝܘܢܢܝܣ ܐܦܪܝܡ ܒܝܠܓܝܓ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Herauszieher”. Im AT ist Musche die führende Gestalt beim Auszug des Volkes Israel aus Ägypten. Weitere Formen: Moses, Moscheh, Musa',
            dt3: ''
        },
        {
            es2: 'ܗܕܕܥܙܪ',
            es3: 'ܚܕܕܥܙܪ',
            es1: 'ܗܕܕܥܙܪ',
            ar3: 'ܚܰܕܰܕܥܰܙܰܪ',
            bedeutung: 'weibl., syrischen Ursprungs; “Freude, Wohlgefallen”, “Braut” oder “Säule”; weitere Formen: Hadutha',
            ar2: 'ܗܰܕܰܕܥܰܕܰܪ',
            dt2: '',
            ar1: 'ܗܕܕܥܙܪ',
            dt3: '',
            dt1: 'Hadadeser',
            id: '1599'
        },
        {
            ar2: 'ܒܰܪܝܰܡܺܝܢ',
            es2: 'ܒܪܝܡܝܢ',
            dt1: 'Baryamin',
            bedeutung: 'weibl., ”Haus, Ort, Land der Hoffnung“. Basibrina ist ein Dorf in Turabdin. Weitere Formen: Bethsibirina, Betsbirina',
            es1: 'ܒܪܝܡܝܢ',
            dt3: '',
            ar3: '',
            dt2: '',
            id: '1374',
            ar1: 'ܒܪܝܡܝܢ',
            es3: ''
        },
        {
            es2: 'ܟܪܝܣܬܝܢܐ',
            ar2: 'ܟܪܺܝܣܬܺܝܢܰܐ',
            dt2: 'Christina',
            dt3: '',
            es1: 'ܟܪܝܣܬܝܢܐ',
            es3: 'ܟܪܝܣܛܝܢܐ',
            ar1: 'ܟܪܝܣܬܝܢܐ',
            ar3: 'ܟܪܺܝܣܛܺܝܢܰܐ',
            bedeutung: '',
            dt1: 'Kristina',
            id: '606'
        },
        {
            id: '366',
            es3: 'ܓܘܪܝܐ',
            bedeutung: 'männl., siehe Gabriel',
            dt3: '',
            ar2: 'ܓܰܘܪܺܝܶܗ',
            dt2: 'Gewriye',
            ar3: 'ܓܰܘܪܺܝܶܐ',
            es2: 'ܓܘܪܝܗ',
            ar1: 'ܓܘܪܝܗ',
            es1: 'ܓܘܪܝܗ',
            dt1: 'Gawriye'
        },
        {
            es1: 'ܥܕܢ',
            dt3: '',
            ar2: 'ܥܶܕܳܢ',
            id: '1477',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “er ist mein Gott”. Im AT begegnet der Name Elihu als Ahnherr Samuels und als Bruder Davids',
            dt2: '',
            ar3: '',
            ar1: 'ܥܕܢ',
            es3: '',
            es2: 'ܥܕܢ',
            dt1: 'Edon'
        },
        {
            dt3: '',
            dt2: '',
            ar3: '',
            es2: 'ܝܫܘܥ',
            es3: '',
            es1: 'ܝܫܘܥ',
            id: '509',
            bedeutung: 'männl., siehe Jeschu',
            dt1: 'Ischo',
            ar1: 'ܝܫܘܥ',
            ar2: 'ܝܶܫܽܘܥ'
        },
        {
            ar1: 'ܝܡܢܗܪ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Taube”. Im AT ist Yawno(n) ein Prophet (zw. 780 und 750 v.Chr.) und Verfasser eines Buches. Weitere Formen: Jonas, Jona, Yawnon',
            dt1: 'Yam Nahar',
            es1: 'ܝܡܢܗܪ',
            id: '2316',
            dt2: '',
            es2: 'ܝܡܢܗܪ',
            es3: '',
            ar3: '',
            dt3: '',
            ar2: 'ܝܰܡܢܰܗܰܪ'
        },
        {
            ar3: 'ܩܰܕܡܺܐܝܶܠ',
            id: '1757',
            es1: 'ܩܕܡܝܐܝܠ',
            bedeutung: 'männl., ”beschließen“. Karar war König von Guzana (9. Jh. v. Chr.). Weitere Formen: Qarar',
            dt1: 'Kadmiel',
            es3: 'ܩܕܡܐܝܠ',
            es2: 'ܩܕܡܝܐܝܠ',
            ar2: 'ܩܰܕܡܺܝܐܝܶܠ',
            dt3: '',
            dt2: '',
            ar1: 'ܩܕܡܝܐܝܠ'
        },
        {
            bedeutung: 'siehe Ramil',
            dt3: '',
            ar1: 'ܪܒܘܬܐ',
            es3: '',
            ar3: '',
            es1: 'ܪܒܘܬܐ',
            dt2: '',
            dt1: 'Rabuta',
            es2: 'ܪܒܘܬܐ',
            ar2: 'ܪܰܒܽܘܬܰܐ',
            id: '2024'
        },
        {
            dt3: '',
            es3: '',
            es2: 'ܒܪܚܕܒܫܒܐ',
            es1: 'ܒܪܚܕܒܫܒܐ',
            ar3: '',
            ar1: 'ܒܪܚܕܒܫܒܐ',
            dt1: 'Barhadbschabo',
            bedeutung: 'männl., “Sohn des Onkels”',
            ar2: 'ܒܰܪܚܰܕܒܫܰܒܳܐ',
            dt2: '',
            id: '1343'
        },
        {
            ar3: 'ܡܰܥܟܳܐ',
            ar2: 'ܡܰܥܟܰܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs. Im AT ist Maacha Sohn Nahors, des Bruders Abrahams; nach dem AT sind die Maachiter ein aramäisches Volk, sie lebten östlich des Oberlaufs des Jordans an der Grenze zu Israel',
            dt1: 'Maacha',
            es3: 'ܡܥܟܐ',
            dt2: '',
            id: '642',
            dt3: '',
            es1: 'ܡܥܟܐ',
            ar1: 'ܡܥܟܐ',
            es2: 'ܡܥܟܐ'
        },
        {
            dt1: 'Murat',
            es2: 'ܡܘܪܐܕ',
            dt2: '',
            ar2: 'ܡܽܘܪܰܐܕ',
            ar1: 'ܡܘܪܐܕ',
            dt3: '',
            id: '723',
            es3: '',
            ar3: '',
            es1: 'ܡܘܪܐܕ',
            bedeutung: ''
        },
        {
            es1: 'ܐܝܠܣܒܪܐ',
            bedeutung: 'männl., “Gott der Hoffnung”; weitere Formen: Il-sabra',
            dt2: '',
            es2: 'ܐܝܠܣܒܪܐ',
            id: '502',
            dt1: 'Ilsabro',
            ar1: 'ܐܝܠܣܒܪܐ',
            ar2: 'ܐܺܝܠܣܰܒܪܳܐ',
            ar3: '',
            dt3: '',
            es3: ''
        },
        {
            es1: 'ܐܢܘܫ',
            dt2: '',
            es2: 'ܐܢܘܫ',
            ar1: 'ܐܢܘܫ',
            id: '316',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischer Vorname; “Mensch, Mann”. Im AT ist Enosch Sohn Sets, Enkel Adams, der als erster den Namen Jahwe angerufen haben soll; im NT ist Enosch Ahnherr Jesu',
            dt3: '',
            dt1: 'Ensosch',
            es3: '',
            ar2: 'ܐܶܢܳܘܫ',
            ar3: ''
        },
        {
            es1: 'ܓܡܪܝܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “der Baumfäller, der Krieger”; weitere Formen: Gedeon, Ged`un, Gidon',
            dt1: 'Gemarja',
            dt2: '',
            ar3: '',
            es3: '',
            id: '1562',
            ar2: 'ܓܶܡܰܪܝܰܐ',
            ar1: 'ܓܡܪܝܐ',
            es2: 'ܓܡܪܝܐ',
            dt3: ''
        },
        {
            dt3: '',
            es2: 'ܙܠܓܐ',
            bedeutung: '',
            ar2: 'ܙܰܠܓܳܐ',
            id: '2352',
            es1: 'ܙܠܓܐ',
            dt2: '',
            ar1: 'ܙܠܓܐ',
            dt1: 'Zalgo',
            ar3: '',
            es3: ''
        },
        {
            es2: 'ܐܚܙܝܐ',
            es3: '',
            dt3: '',
            es1: 'ܐܚܙܝܐ',
            dt1: 'Ahasja',
            ar3: '',
            dt2: '',
            ar2: 'ܐܰܚܰܙܝܰܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe hält fest”, Im AT begegnen Ahasja, Sohn des Ahab und der Isebel, König von Israel (852/51 v.Chr.), und Ahasja, Sohn des Joram und der Atalja, König von Juda (845 v.Chr.). Im Streit mit dem aramäischen König Hasael von Aram-Damaskus wird der letztere verwundet und getötet',
            ar1: 'ܐܚܙܝܐ',
            id: '52'
        },
        {
            bedeutung: 'weibl., Jariba war eine Stadt in Alt-Syrien; weitere Formen: Yariba',
            es2: 'ܝܒܪܘܕܝܐ',
            ar1: 'ܝܒܪܘܕܝܐ',
            dt1: 'Jabrudia',
            dt2: '',
            dt3: '',
            ar2: 'ܝܰܒܪܽܘܕܺܝܰܐ',
            id: '1707',
            ar3: '',
            es1: 'ܝܒܪܘܕܝܐ',
            es3: ''
        },
        {
            dt1: 'Hul',
            ar2: 'ܚܽܘܠ',
            id: '490',
            es3: '',
            ar1: 'ܚܘܠ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Sand“. Im AT ist Hul der zweite Sohn Arams. Weitere Formen: Chul',
            dt3: '',
            dt2: '',
            ar3: '',
            es2: 'ܚܘܠ',
            es1: 'ܚܘܠ'
        },
        {
            dt3: '',
            es1: 'ܟܗܝܢܬܐ',
            ar3: '',
            ar1: 'ܟܗܝܢܬܐ',
            es3: '',
            id: '571',
            es2: 'ܟܗܝܢܬܐ',
            dt2: '',
            bedeutung: 'weibl., “reiche, fruchtbare Frau”',
            ar2: 'ܟܰܗܺܝܢܬܰܐ',
            dt1: 'Kahinta'
        },
        {
            id: '740',
            es3: '',
            ar3: '',
            es1: 'ܢܗܪܝܐ',
            dt1: 'Nahroyo',
            dt3: '',
            bedeutung: 'männl., syrischen Ursprungs; “Mesopotamier, der zwischen Flüssen wohnt”',
            dt2: '',
            ar1: 'ܢܗܪܝܐ',
            ar2: 'ܢܰܗܪܳܝܳܐ',
            es2: 'ܢܗܪܝܐ'
        },
        {
            es1: 'ܩܕܫ',
            dt3: '',
            ar3: '',
            dt1: 'Qadesch',
            id: '2003',
            es3: '',
            ar1: 'ܩܕܫ',
            es2: 'ܩܕܫ',
            bedeutung: 'weibl., “Wahrheit, Ehrlichkeit, Rechtschaffenheit”',
            dt2: 'Kadesch',
            ar2: 'ܩܰܕܶܫ'
        },
        {
            ar2: 'ܡܰܪܢܺܝܬܰܐ',
            dt3: '',
            id: '675',
            es3: '',
            dt2: '',
            dt1: 'Marnita',
            bedeutung: 'weibl., “Lanze, Sorge, Idee, Meinung”; weitere Formen: Marnitha',
            ar3: '',
            ar1: 'ܡܪܢܝܬܐ',
            es1: 'ܡܪܢܝܬܐ',
            es2: 'ܡܪܢܝܬܐ'
        },
        {
            id: '1018',
            dt1: 'Suroyo',
            es2: 'ܣܘܪܝܐ',
            dt3: '',
            ar2: 'ܣܽܘܪܳܝܳܐ',
            dt2: '',
            es1: 'ܣܘܪܝܐ',
            ar3: '',
            es3: '',
            bedeutung: 'männl., ”Syrer, Christ“',
            ar1: 'ܣܘܪܝܐ'
        },
        {
            dt1: 'Eva',
            ar2: 'ܚܰܘܳܐ',
            ar1: 'ܚܘܐ',
            dt3: '',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “die Lebensspendende, Leben weckend oder Schlange“. Im AT gilt Eva als die Stammmutter der Men schen. Weitere Namen: Hawo, Hawa',
            es1: 'ܚܘܐ',
            dt2: 'Hawo',
            id: '325',
            es3: '',
            es2: 'ܚܘܐ',
            ar3: ''
        },
        {
            es2: 'ܠܘܩܐ',
            ar1: 'ܠܘܩܐܣ',
            ar2: 'ܠܽܘܩܰܐ',
            dt3: '',
            es1: 'ܠܘܩܐܣ',
            es3: 'ܠܘܩܐ',
            id: '637',
            dt2: '',
            dt1: 'Lukas',
            ar3: 'ܠܽܘܩܳܐ',
            bedeutung: 'männl., aus dem Lateinischen übernommener Vorname; “der aus Lucania Stammende”; Mitarbeiter des Apostels Paulus, gilt als der Verfasser des dritten Evangeliums und der Apostelgeschichte. Weitere Formen: Luko, Lukos, Luka'
        },
        {
            ar3: '',
            dt2: '',
            es2: 'ܝܘܪܡ',
            dt1: 'Joram',
            es1: 'ܝܘܪܡ',
            dt3: '',
            id: '560',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe ist erhaben”',
            es3: '',
            ar2: 'ܝܽܘܪܳܡ',
            ar1: 'ܝܘܪܡ'
        },
        {
            dt3: '',
            ar3: '',
            es3: '',
            dt2: '',
            es1: 'ܪܒܪܬܐ',
            bedeutung: 'männl., “Begeisterung, Exaltation, Lob, Erhöhung, Verherrlichung”',
            ar1: 'ܪܒܪܬܐ',
            ar2: 'ܪܳܒܶܪܬܰܐ',
            dt1: 'Roberta',
            es2: 'ܪܒܪܬܐ',
            id: '2055'
        },
        {
            bedeutung: 'männl., “Frieden”',
            ar1: 'ܫܡܥܘܢ',
            ar3: '',
            es2: 'ܫܡܥܘܢ',
            dt1: 'Schemun',
            es3: '',
            ar2: 'ܫܶܡܥܽܘܢ',
            es1: 'ܫܡܥܘܢ',
            dt2: '',
            dt3: '',
            id: '2150'
        },
        {
            es2: 'ܙܗܝܐ',
            ar2: 'ܙܰܗܝܳܐ',
            dt3: '',
            es1: 'ܙܗܝܐ',
            ar3: '',
            dt1: 'Zahyo',
            dt2: '',
            bedeutung: 'männl., “Binse, Ried, Schilf“, weitere Formen: Zolo',
            id: '2344',
            es3: '',
            ar1: 'ܙܗܝܐ'
        },
        {
            ar2: 'ܕܽܠܰܒܰܐܢܺܝ',
            ar3: '',
            dt3: '',
            es3: '',
            dt2: 'Dolabani',
            id: '284',
            es2: 'ܕܘܠܒܐܢܝ',
            bedeutung: '',
            ar1: 'ܕܘܠܒܐܢܝ',
            dt1: 'Dulabani',
            es1: 'ܕܘܠܒܐܢܝ'
        },
        {
            dt1: 'Scherschat',
            ar3: '',
            ar2: 'ܫܶܪܫܰܬ',
            id: '2151',
            es3: '',
            dt3: '',
            bedeutung: 'männl, aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “von Gott erhöht”, “Namen Gottes” oder “sein Name ist El“. Im AT ist Schmuel Prophet und letzter Richter Israels. Er salbt David zum König (ca. 11. Jh. v.Chr.). Weitere Formen: Samuel, Schmuyel, Schemuel, Schemil; bei den Aramäern beliebt',
            es1: 'ܫܪܫܬ',
            dt2: '',
            es2: 'ܫܪܫܬ',
            ar1: 'ܫܪܫܬ'
        },
        {
            es3: 'ܡܬܝܢ',
            dt1: 'Martin',
            ar3: 'ܡܶܬܺܝܢ',
            dt2: 'Metin',
            id: '1865',
            ar2: 'ܡܰܬܺܝܢ',
            bedeutung: 'männl., “der Kräftige, der Dicke”. Mazra war Bischof von Beth-Zabhdai (Hazakh-Idil, Anfang 2. Jh.). Weitere Formen: Mozro, Masra',
            ar1: 'ܡܬܝܢ',
            es2: 'ܡܬܝܢ',
            dt3: '',
            es1: 'ܡܬܝܢ'
        },
        {
            id: '2333',
            dt3: '',
            ar1: 'ܝܘܚܢܢ ܕܘܠܒܐܢܝ',
            dt1: 'Yuhanon Dolabani',
            es2: 'ܝܘܚܢܢ ܕܘܠܒܐܢܝ',
            dt2: 'Johannes Dolabani',
            ar3: '',
            es3: '',
            bedeutung: 'männl., “Blitzlicht, Strahl”',
            ar2: 'ܝܘܚܢܢ ܕܘܠܒܐܢܝ',
            es1: 'ܝܘܚܢܢ ܕܘܠܒܐܢܝ'
        },
        {
            dt2: '',
            bedeutung: 'weibl., “Tochter des Herzens”; weitere Formen: Bathleba, Bathlebi',
            es2: 'ܒܪܬܚܘܒܐ',
            dt1: 'Bathhoubo',
            ar3: '',
            es1: 'ܒܪܬܚܘܒܐ',
            id: '1385',
            ar2: 'ܒܰܪ̱ܬܚܽܘܒܰܐ',
            dt3: '',
            es3: '',
            ar1: 'ܒܪ̱ܬܚܘܒܐ'
        },
        {
            es1: 'ܒܪܛܝܡܝ',
            es2: 'ܒܪܛܝܡܝ',
            dt3: '',
            ar3: '',
            ar2: 'ܒܰܪܛܺܝܡܰܝ',
            id: '191',
            es3: '',
            dt1: 'Bartimai',
            dt2: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäischen Ursprungs; “Sohn des Timai”. Im NT ist Bartimai ein blinder Bettler, der von Jesus beim Auszug aus Jericho geheilt wird. Weitere Formen: Bartimäus',
            ar1: 'ܒܪܛܝܡܝ'
        },
        {
            es2: 'ܛܪܝܡܐ',
            ar2: 'ܛܪܺܝܡܰܐ',
            dt2: '',
            es1: 'ܛܪܝܡܐ',
            id: '2265',
            ar1: 'ܛܪܝܡܐ',
            dt3: '',
            dt1: 'Trima',
            bedeutung: 'männl., “Berg”',
            es3: '',
            ar3: ''
        },
        {
            dt1: 'Ahitob',
            ar2: 'ܐܰܚܺܝܛܳܒ',
            dt3: '',
            id: '60',
            dt2: 'Ahitub',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “mein Bruder ist gut“. Im AT ist Ahitob Enkel des Priesters Eli; Vater des Ahimelech I., Großvater des Abjatar. Weitere Formen: Ahitub',
            es2: 'ܐܚܝܛܒ',
            es3: '',
            es1: 'ܐܚܝܛܒ',
            ar3: '',
            ar1: 'ܐܚܝܛܒ'
        },
        {
            ar1: 'ܩܘܪܝܠܠܘܣ',
            es2: 'ܩܘܪܝܠܠܘܣ',
            dt1: 'Cyrillus',
            bedeutung: 'männl., aus dem Griechischen übernommener Vorname; “der zum Herrn Gehörende”. In der Kirchengeschichte war Cyrillus Kirchenlehrer und Bischof von Jerusalem (4. Jh.). Zu nennen sind weiter Cyrillus von Alexandrien (5. Jh.) und Cyrillonas (4. Jh.). Weitere Formen: Kyrillus, Kyrillos',
            ar3: '',
            id: '247',
            es1: 'ܩܘܪܝܠܠܘܣ',
            dt2: '',
            es3: '',
            dt3: '',
            ar2: 'ܩܽܘܪܺܝܠܠܳܘܣ'
        },
        {
            ar3: '',
            es1: 'ܬܓܬ',
            id: '2261',
            dt2: '',
            dt1: 'Togat',
            dt3: '',
            es3: '',
            ar1: 'ܬܓܬ',
            ar2: 'ܬܳܓܰܬ',
            es2: 'ܬܓܬ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs. Im AT begegnet Tulmai als Sohn des Anak, ferner trägt der Sohn des Ammihud, König von Geschur, Schwiegervater Davids und Großvater Abschaloms, diesen Namen. Weitere Formen: Tolmai, Talmai'
        },
        {
            es2: 'ܒܢܝܡܝܢ',
            id: '220',
            dt1: 'Benjamin',
            dt3: 'Benyomin',
            ar3: 'ܒܶܢܝܳܡܶܝܢ',
            dt2: 'Benyamin',
            ar2: 'ܒܶܢܝܰܡܺܝܢ',
            es1: 'ܒܢܝܡܝܢ',
            es3: 'ܒܢܝܡܝܢ',
            ar1: 'ܒܢܝܐܡܢ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Sohn der rechten Hand, Glückskind”. Im AT ist Benjamin der jüngste Sohn von Jakob und Rahel. Weitere Formen: Benjomen, Baryamin'
        },
        {
            es3: '',
            es1: 'ܠܒܝܒܘܬܐ',
            dt3: '',
            ar3: '',
            id: '624',
            ar2: 'ܠܒܺܝܒܽܘܬܰܐ',
            dt2: '',
            dt1: 'Lbibuta',
            es2: 'ܠܒܝܒܘܬܐ',
            ar1: 'ܠܒܝܒܘܬܐ',
            bedeutung: 'weibl., “Mut, Tapferkeit”'
        },
        {
            ar1: 'ܓܡܝܪܬܐ',
            ar2: 'ܓܡܺܝܪܬܰܐ',
            es2: 'ܓܡܝܪܬܐ',
            dt1: 'Gmirta',
            bedeutung: 'weibl., “Gemeinschaft, Gruppe, Team, Band, Chor”',
            es3: '',
            dt3: '',
            es1: 'ܓܡܝܪܬܐ',
            ar3: '',
            id: '1575',
            dt2: ''
        },
        {
            ar2: 'ܡܰܢܺܝܫܟܰܐ',
            ar1: 'ܡܢܝܫܟܐ',
            es3: 'ܡܢܝܫܟܐ',
            es1: 'ܡܢܝܫܟܐ',
            es2: 'ܡܢܝܫܟܐ',
            dt1: 'Manischka',
            dt3: 'Mnischko',
            dt2: 'Manischko',
            ar3: 'ܡܰܢܺܝܫܟܳܐ',
            bedeutung: 'männl., syrischen Ursprungs; “Veilchen”; weitere Formen: Manischka',
            id: '657'
        },
        {
            es1: 'ܡܠܟܘܬܐ',
            dt1: 'Malkuta',
            ar3: '',
            bedeutung: 'weibl., syrischen Ursprungs; “Staat, Obrigkeit, Behörde, Regierung, Paradies, Herrschaft, Königreich”; weitere Formen: Malkutha',
            ar1: 'ܡܠܟܘܬܐ',
            dt3: '',
            ar2: 'ܡܰܠܟܽܘܬܰܐ',
            id: '655',
            dt2: '',
            es2: 'ܡܠܟܘܬܐ',
            es3: ''
        },
        {
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “mein Onkel ist freigebig”. Im AT ist Amminadab ein Judäer, Nachkomme des Perez und Ahnherr Davids. Im NT ist Amminadab Ahnherr Davids und Jesu',
            es2: 'ܥܡܝܢܕܒ',
            ar2: 'ܥܰܡܺܝܢܰܕܰܒ',
            es3: '',
            id: '79',
            ar3: '',
            dt3: '',
            ar1: 'ܥܡܝܢܕܒ',
            es1: 'ܥܡܝܢܕܒ',
            dt1: 'Amminadab',
            dt2: 'Amminodob'
        },
        {
            dt2: '',
            id: '930',
            ar3: '',
            dt1: 'Schalheb',
            es1: 'ܫܠܗܒ',
            ar1: 'ܫܠܗܒ',
            es3: '',
            dt3: '',
            es2: 'ܫܠܗܒ',
            ar2: 'ܫܰܠܗܶܒ',
            bedeutung: 'männl., “ﬂammen, entﬂammen, feuern, leuchten, scheinen, liebevoll sorgen für”'
        },
        {
            dt2: '',
            dt3: '',
            ar2: 'ܚܽܘܬ݂ܪܳܐ',
            es2: 'ܚܘܬܪܐ',
            ar1: 'ܚܘܬ݂ܪܐ',
            bedeutung: 'männl., “Gott der Hoffnung”; weitere Formen: Il-sabra',
            ar3: '',
            es3: '',
            id: '1681',
            es1: 'ܚܘܬܪܐ',
            dt1: 'Huthro'
        },
        {
            es2: 'ܒܪܢܐܒܐ',
            es3: 'ܒܪܢܒܐ',
            ar2: 'ܒܰܪܢܰܐܒܰܐ',
            bedeutung: 'männl., “Sohn der Leoparden, der Tiger”. Barnemre war ein Hauptgott der Aramäer von Haran. Weitere Formen: Barnemro',
            dt3: '',
            ar1: 'ܒܪܢܐܒܐ',
            es1: 'ܒܪܢܐܒܐ',
            dt1: 'Barnaba',
            dt2: 'Barnabas',
            id: '1354',
            ar3: 'ܒܰܪܢܰܒܰܐ'
        },
        {
            es1: 'ܣܘܪܝܐ',
            es2: 'ܣܘܪܝܐ',
            dt3: '',
            ar2: 'ܣܽܘܪܳܝܳܐ',
            es3: '',
            bedeutung: 'männl., siehe Adai',
            dt1: 'Suroyo',
            dt2: '',
            id: '2210',
            ar1: 'ܣܘܪܝܐ',
            ar3: ''
        },
        {
            dt3: '',
            dt2: 'Schifura',
            dt1: 'Schipura',
            ar3: '',
            ar1: 'ܫܝܦܘܪܐ',
            es2: 'ܫܝܦܘܪܐ',
            es1: 'ܫܝܦܘܪܐ',
            bedeutung: 'weibl., “Trompete”; weitere Formen: Schifura',
            ar2: 'ܫܺܝܦܽܘܪܰܐ',
            es3: '',
            id: '962'
        },
        {
            bedeutung: 'männl., Syros oder Kyros (Cyros) war König der Achämeniden (559 - 529 v. Chr.), der das persische Reich gründete. Er half den Juden, die in der babylonischen Gefangenschaft waren, nach Judäa zurückzukehren und den Tempel von Jerusalem wiederaufzubauen. Nach einer Theorie sollen sich die Aramäer nach seinem Namen in Syrer umbenannt haben',
            dt2: '',
            ar3: '',
            ar2: 'ܣܽܘܪܳܘܣ',
            dt3: '',
            es1: 'ܣܘܪܘܣ',
            id: '1017',
            es3: '',
            ar1: 'ܣܘܪܘܣ',
            es2: 'ܣܘܪܘܣ',
            dt1: 'Suros'
        },
        {
            ar3: '',
            es3: '',
            dt2: '',
            id: '1270',
            dt3: '',
            dt1: 'Antar',
            ar2: 'ܥܰܢܬܰܪ',
            bedeutung: 'männl., Aphrahat (†346) ist ein Gelehrter der syrischen Kirche und hat viele Schriften hinterlassen',
            es2: 'ܥܢܬܪ',
            es1: 'ܥܢܬܪ',
            ar1: 'ܥܢܬܪ'
        },
        {
            dt1: 'Sebyon',
            es1: 'ܨܒܝܘܢ',
            id: '2175',
            ar2: 'ܨܶܒܝܳܘܢ',
            dt3: 'Sebyono',
            ar3: 'ܨܶܒܝܳܢܳܐ',
            dt2: 'Sebjon',
            ar1: 'ܨܒܝܘܢ',
            bedeutung: 'weibl., “die Durstige”',
            es2: 'ܨܒܝܘܢ',
            es3: 'ܨܒܝܢܐ'
        },
        {
            es1: 'ܝܘܙܕܩ',
            ar3: 'ܝܽܘܙܳܕܳܩ',
            dt2: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe ist gerecht“. Weitere Formen: Juzodok, Juzodoq, Juzadak, Jusodoq',
            ar1: 'ܝܘܙܕܩ',
            es3: 'ܝܘܙܕܩ',
            id: '563',
            dt1: 'Jozadak',
            ar2: 'ܝܺܘܙܰܕܰܩ',
            dt3: '',
            es2: 'ܝܘܙܕܩ'
        },
        {
            dt2: '',
            es3: '',
            es2: 'ܡܬܐܝܠܘ',
            ar3: '',
            ar1: 'ܡܬܐܝܠܘ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “wer ist wie Gott?”. Michael gilt für die Christen als Erzengel und Engelfürst. Seinen Namen trägt Patriarch Michael der Große von Antiochien (†1 199). Weitere Formen: Michoel, Mikael',
            es1: 'ܡܬܐܝܠܘ',
            id: '1880',
            ar2: 'ܡܰܬܐܺܝܠܽܘ',
            dt1: 'Matilu',
            dt3: ''
        },
        {
            es2: 'ܐܬܐܢܐܣܝܘܣ',
            dt2: 'Atanatius',
            es3: '',
            dt3: 'Athanasius',
            ar3: '',
            ar2: 'ܐܰܬܰܐܢܰܣܺܝܽܘܣ',
            bedeutung: '',
            es1: 'ܐܬܐܢܐܣܝܘܣ',
            ar1: 'ܐܬܐܢܐܣܝܘܣ',
            dt1: 'Athanatius',
            id: '1298'
        },
        {
            dt1: 'Abedmschiho',
            ar3: '',
            es3: '',
            id: '1190',
            dt2: '',
            ar1: 'ܥܒܕܡܫܝܚܐ',
            es2: 'ܥܒܕܡܫܝܚܐ',
            dt3: '',
            bedeutung: 'männl, “Knecht/Diener des Messias Christus”. Abedmschiho war Bischof von Arbela (190-217). Weitere Formen: Ebedmschiho, Ebedmschiha, Abduhnesih',
            es1: 'ܥܒܕܡܫܝܚܐ',
            ar2: 'ܥܒܶܕܡܫܺܝܚܐ'
        },
        {
            dt3: '',
            ar2: 'ܠܳܒܳܢ',
            ar1: 'ܠܒܢ',
            dt1: 'Lobon',
            dt2: '',
            es3: '',
            es1: 'ܠܒܢ',
            es2: 'ܠܒܢ',
            id: '1823',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “die aus Magdala Stammende”, ”Turm“. Im NT ist Maria Magdalena eine der treuesten Anhängerinnen Jesu. Weitere Formen: Magedlayta, Magedloyto',
            ar3: ''
        },
        {
            dt2: '',
            ar3: '',
            es1: 'ܐܝܠܐ',
            bedeutung: 'männl., “Verwickler, Verweber”; König von Jadi (8.Jh. v.Chr.); weitere Formen: Azira',
            ar2: 'ܐܰܝܠܰܐ',
            id: '1315',
            ar1: 'ܐܝܠܐ',
            es2: 'ܐܝܠܐ',
            dt1: 'Ayla',
            es3: '',
            dt3: ''
        },
        {
            dt3: '',
            es3: '',
            es2: 'ܠܪܐ',
            ar2: 'ܠܰܪܰܐ',
            bedeutung: 'weibl., aus dem Persischen übernommener Vorname, der aramäisch-hebräischen Ursprungs ist, aus lelyo, lelya; “Nacht, Dunkelheit“, weitere Formen: Leyla, Lilya',
            es1: 'ܠܪܐ',
            ar3: '',
            dt2: '',
            ar1: 'ܠܪܐ',
            id: '1810',
            dt1: 'Lara'
        },
        {
            dt2: '',
            ar1: 'ܕܕܝܫܘܥ',
            ar3: '',
            dt3: '',
            id: '1461',
            dt1: 'Dodjeschu',
            bedeutung: 'weibl., “Beschluss, Befehl, Ziel”',
            es2: 'ܕܕܝܫܘܥ',
            ar2: 'ܕܳܕܝܶܫܽܘܥ',
            es3: '',
            es1: 'ܕܕܝܫܘܥ'
        },
        {
            es3: '',
            es1: 'ܙܒܝ',
            es2: 'ܙܒܝ',
            ar1: 'ܙܒܝ',
            ar3: '',
            dt2: '',
            bedeutung: 'weibl., weitere Formen: Zabbai, Zabay, Zabaj',
            dt1: 'Zabai',
            ar2: 'ܙܰܒܰܝ',
            dt3: '',
            id: '1144'
        },
        {
            es1: 'ܡܝܟܐܝܠܐ',
            dt2: '',
            es3: '',
            bedeutung: '',
            es2: 'ܡܝܟܐܝܠܐ',
            ar2: 'ܡܺܝܟ݂ܰܐܝܶܠܰܐ',
            ar1: 'ܡܝܟܐܝܠܐ',
            dt1: 'Michaela',
            ar3: '',
            dt3: '',
            id: '701'
        },
        {
            dt1: 'Babel',
            es2: 'ܒܒܝܠ',
            ar3: '',
            es3: '',
            bedeutung: 'weibl., “veıwirrt, durcheinander gebracht” oder “Tor, Pforte Gottes”; weitere Formen: Bobel',
            es1: 'ܒܒܝܠ',
            ar2: 'ܒܰܒܶܝܠ',
            id: '140',
            dt2: '',
            ar1: 'ܒܒܝܠ',
            dt3: ''
        },
        {
            es3: '',
            dt1: 'Abai',
            ar2: 'ܐܰܒܰܝ',
            es1: 'ܐܒܝ',
            bedeutung: 'männl., Abai († 1. Jh.) war Schüler des Mor Addai. Sein Festtag: 1. Oktober',
            es2: 'ܐܒܝ',
            dt3: '',
            ar1: 'ܐܒܝ',
            ar3: '',
            dt2: 'Abay',
            id: '1180'
        },
        {
            dt1: 'Guryo',
            dt3: '',
            es2: 'ܓܘܪܝܐ',
            ar1: 'ܓܘܪܝܐ',
            ar2: 'ܓܽܘܪܝܳܐ',
            bedeutung: 'männl., “junger Löwe”; metaphorischer Beiname für Christus',
            es1: 'ܓܘܪܝܐ',
            ar3: '',
            id: '398',
            es3: '',
            dt2: ''
        },
        {
            dt3: '',
            ar1: 'ܓܠܝܟ',
            ar2: 'ܓܶܠܺܝܟ',
            es3: '',
            ar3: '',
            es1: 'ܓܠܝܟ',
            id: '1423',
            dt1: 'Celik',
            es2: 'ܓܠܝܟ',
            bedeutung: '',
            dt2: ''
        },
        {
            bedeutung: 'weibl., “Hostie, Stück”; weitere Formen: Bruktha männl., “Prüfung, Untersuchung, Inspektion”; weitere Formen: Buhrono',
            ar1: 'ܒܘܚܪܢܐ',
            id: '238',
            dt3: '',
            ar2: 'ܒܽܘܚܪܰܢܰܐ',
            es2: 'ܒܘܚܪܢܐ',
            es3: '',
            dt1: 'Buhrana',
            dt2: '',
            ar3: '',
            es1: 'ܒܘܚܪܢܐ'
        },
        {
            ar3: '',
            es3: '',
            id: '792',
            dt1: 'Odi',
            ar1: 'ܐܕܝ',
            ar2: 'ܐܳܕܺܝ',
            dt3: '',
            bedeutung: 'männl., siehe Odenata',
            es1: 'ܐܕܝ',
            es2: 'ܐܕܝ',
            dt2: ''
        },
        {
            ar2: 'ܕܰܢܺܝܐܝܶܠܰܐ',
            dt1: 'Daniela',
            es2: 'ܕܢܝܐܝܠܐ',
            ar1: 'ܕܢܝܐܝܠܐ',
            id: '1439',
            ar3: 'ܕܰܐܢܺܝܐܝܶܠܰܗ',
            dt3: '',
            es1: 'ܕܢܝܐܝܠܐ',
            dt2: '',
            bedeutung: 'weibl., “Höhle”',
            es3: 'ܕܢܝܐܝܠܗ'
        },
        {
            dt1: 'Fikri',
            dt3: '',
            es3: '',
            es2: 'ܦܟܪܝ',
            id: '342',
            ar2: 'ܦܶܟܪܺܝ',
            ar1: 'ܦܝܟܪܝ',
            bedeutung: 'männl., “festbinden, an sich binden”',
            es1: 'ܦܟܪܝ',
            dt2: '',
            ar3: ''
        },
        {
            es2: 'ܡܠܟܚܢܢܐ',
            es3: '',
            ar3: '',
            dt2: '',
            dt1: 'Malakhanna',
            es1: 'ܡܠܟܚܢܢܐ',
            bedeutung: 'männl., Vater des Königs Abgar “Ukkomo”',
            dt3: '',
            id: '1837',
            ar2: 'ܡܰܠܰܟܚܰܢܢܰܐ',
            ar1: 'ܡܠܟܚܢܢܐ'
        },
        {
            es2: 'ܚܝܕܐܪܝ',
            id: '477',
            ar1: 'ܚܝܕܐܪܝ',
            es3: '',
            ar3: '',
            dt3: '',
            bedeutung: '',
            dt1: 'Hidari',
            dt2: '',
            ar2: 'ܚܺܝܕܰܐܪܺܝ',
            es1: 'ܚܝܕܐܪܝ'
        },
        {
            dt3: '',
            es3: '',
            ar2: 'ܐܰܣܰܦ',
            dt1: 'Asaf',
            id: '1286',
            dt2: 'Asaph',
            es1: 'ܐܣܦ',
            ar3: '',
            ar1: 'ܐܣܦ',
            bedeutung: 'männl., “verschönern, er hat verschönert”',
            es2: 'ܐܣܦ'
        },
        {
            ar3: '',
            dt2: '',
            dt1: 'Fayuta',
            ar2: 'ܦܰܐܝܽܘܬܰܐ',
            dt3: '',
            ar1: 'ܦܐܝܘܬܐ',
            id: '1522',
            es3: '',
            es1: 'ܦܐܝܘܬܐ',
            es2: 'ܦܐܝܘܬܐ',
            bedeutung: 'weibl., “Hochmut, Stolz, Hoheit, Erhabenheit”; weitere Formen: Florutha'
        },
        {
            dt3: '',
            es1: 'ܢܦܛܝܪ',
            dt2: 'Naphtir',
            id: '1925',
            es2: 'ܢܦܛܝܪ',
            dt1: 'Naftir',
            bedeutung: 'weibl., syrischen Ursprungs; “Bescheidenheit, Keuschheit, Reinheit, Diskretion, Ehre, Enthaltsamkeit, Schamhaftigkeit”; weitere Formen: Nakfutha',
            ar2: 'ܢܰܦܛܺܝܪ',
            es3: '',
            ar1: 'ܢܦܛܝܪ',
            ar3: ''
        },
        {
            dt1: 'Amir',
            ar1: 'ܐܡܝܪ',
            es1: 'ܐܡܝܪ',
            dt2: '',
            ar2: 'ܐܰܡܺܝܪ',
            es2: 'ܐܡܝܪ',
            es3: '',
            ar3: '',
            bedeutung: 'weibl. Form zu Amir; “Fürstin, Prinzessin',
            dt3: '',
            id: '1255'
        },
        {
            dt2: '',
            ar3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott gebe Stärke, Gott möge stärken“. Im AT ist Haskiel Priester, Prophet (6. Jh. v.Chr.) im babylonischen Exil und Verfasser eines Buches. Weitere Formen: Ezechiel, Hesekiel, Hasqiel',
            es3: '',
            dt1: 'Hanono',
            es1: 'ܚܢܢܐ',
            ar1: 'ܚܢܢܐ',
            es2: 'ܚܢܢܐ',
            id: '1630',
            ar2: 'ܚܰܢܳܢܳܐ',
            dt3: ''
        },
        {
            dt3: '',
            ar3: '',
            ar1: 'ܟܠܬܐ',
            id: '577',
            es1: 'ܟܠܬܐ',
            bedeutung: 'weibl., syrischen Ursprungs; “Braut”; weitere Formen: Kalta',
            dt1: 'Kaltha',
            ar2: 'ܟܰܠܬܰܐ',
            es2: 'ܟܠܬܐ',
            dt2: '',
            es3: ''
        },
        {
            dt3: '',
            es1: 'ܐܝܫܡܥܝܐ',
            ar1: 'ܐܝܫܡܥܝܐ',
            dt1: 'Jischmaja',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe hört”',
            es2: 'ܐܝܫܡܥܝܐ',
            es3: '',
            ar3: '',
            dt2: '',
            ar2: 'ܐܝܫܡܥܝܐ',
            id: '549'
        },
        {
            es1: 'ܠܘܩܐ',
            ar2: 'ܠܽܘܟܰܣ',
            bedeutung: 'weibl., “Beispiel, Spiegel”; weitere Formen: Mahsita ',
            id: '1826',
            es3: '',
            es2: 'ܠܘܟܣ',
            dt1: 'Lukas',
            dt2: '',
            ar3: '',
            dt3: '',
            ar1: 'ܠܘܩܐ'
        },
        {
            dt2: 'Adwar',
            ar1: 'ܐܕܘܪ',
            dt3: '',
            es3: '',
            ar2: 'ܐܰܕܘܰܪ',
            es2: 'ܐܕܘܪ',
            ar3: '',
            id: '1219',
            dt1: 'Advar',
            es1: 'ܐܕܘܪ',
            bedeutung: ''
        },
        {
            dt3: '',
            dt2: '',
            es3: '',
            es1: 'ܐܠܝܗܘ',
            id: '1483',
            dt1: 'Elihu',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott hat gehört”; weitere Formen: Elischama',
            ar1: 'ܐܠܝܗܘ',
            es2: 'ܐܠܝܗܘ',
            ar2: 'ܐܶܠܺܝܗܽܘ',
            ar3: ''
        },
        {
            ar2: 'ܫܠܳܡܰܬ',
            dt2: '',
            dt1: 'Schlomat',
            dt3: '',
            es3: '',
            ar1: 'ܫܠܡܬ',
            ar3: '',
            es2: 'ܫܠܡܬ',
            bedeutung: 'weibl., “Turteltaube, Taube”; weitere Formen: Schufnina, Schufninta',
            id: '2158',
            es1: 'ܫܠܡܬ'
        },
        {
            ar3: '',
            es1: 'ܥܒܕܝܫܘܥ',
            es3: '',
            dt3: '',
            dt2: '',
            ar2: 'ܥܒܶܕܝܶܫܘܥ',
            dt1: 'Abedjeschu',
            bedeutung: 'männl., “Knecht/Diener des Jesus”; weitere Formen: Ebedjeschu',
            ar1: 'ܥܒܕܝܫܘܥ',
            id: '12',
            es2: 'ܥܒܕܝܫܘܥ'
        },
        {
            dt1: 'Endo',
            es1: 'ܥܢܕܐ',
            bedeutung: 'männl., siehe Jesaja',
            es3: '',
            es2: 'ܥܢܕܐ',
            ar1: 'ܥܢܕܐ',
            ar2: 'ܥܶܢܕܳܐ',
            ar3: '',
            dt3: '',
            dt2: '',
            id: '1498'
        },
        {
            es1: 'ܣܦܪܐ',
            es3: '',
            ar3: '',
            ar2: 'ܣܳܦܪܳܐ',
            dt3: '',
            dt1: 'Sofro',
            dt2: '',
            id: '1002',
            ar1: 'ܣܦܪܐ',
            es2: 'ܣܦܪܐ',
            bedeutung: 'männl., “Schriftgelehrter, Schreiber, Schriftführer, Notar, Anwalt, Lehrer”'
        },
        {
            ar3: '',
            dt1: 'Yaqar',
            ar2: 'ܝܰܩܰܪ',
            dt3: '',
            bedeutung: 'männl., “wachsen, aufwachsen, groß werden”; weitere Formen: Jirab',
            es1: 'ܝܩܪ',
            es2: 'ܝܩܪ',
            ar1: 'ܝܩܪ',
            dt2: '',
            es3: '',
            id: '2320'
        },
        {
            es3: '',
            dt3: '',
            id: '1941',
            dt2: 'Nasiho',
            ar3: '',
            es2: 'ܢܨܝܚ',
            ar1: 'ܢܨܝܚ',
            es1: 'ܢܨܝܚ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott hat getröstet, Jahwe tröstet”. Im AT gilt Nehemia als Verfasser eines nach ihm benannten Geschichts-buches (um 450 v. Chr.). Weitere Formen: Nhamio, Nhamia, Nehemja',
            ar2: 'ܢܰܨܝܺܚ',
            dt1: 'Nasih'
        },
        {
            bedeutung: 'weibl. Form zu Farid',
            ar3: '',
            id: '1512',
            es3: '',
            dt2: '',
            es1: 'ܥܙܪܐ',
            ar2: 'ܥܶܙܪܰܐ',
            dt3: '',
            es2: 'ܥܙܪܐ',
            dt1: 'Ezra',
            ar1: 'ܥܙܪܐ'
        },
        {
            es1: 'ܓܡܝܪܬܐ',
            es3: '',
            ar2: 'ܓܡܺܝܪܬܰܐ',
            es2: 'ܓܡܝܪܬܐ',
            dt3: '',
            dt2: '',
            dt1: 'Gmirta',
            ar1: 'ܓܡܝܪܬܐ',
            ar3: '',
            id: '388',
            bedeutung: 'weibl., “vollkommene(s) [Frau/Mädchen]”'
        },
        {
            dt2: '',
            es2: 'ܓܐܢ',
            dt3: '',
            bedeutung: '',
            es3: '',
            ar2: 'ܓܰܐܢ',
            es1: 'ܓܐܢ',
            dt1: 'Can',
            id: '1421',
            ar3: '',
            ar1: 'ܓܐܢ'
        },
        {
            es2: 'ܨܒܝܘܢ',
            id: '983',
            dt3: 'Sebyono',
            dt2: 'Sebjon',
            ar2: 'ܨܶܒܝܳܘܢ',
            ar1: 'ܨܒܝܘܢ',
            es1: 'ܨܒܝܘܢ',
            ar3: 'ܨܶܒܝܳܢܳܐ',
            bedeutung: 'männl., “Wille, Wunsch”',
            dt1: 'Sebyon',
            es3: 'ܨܒܝܢܐ'
        },
        {
            dt3: '',
            es2: 'ܥܒܕܝܫܘܥ',
            ar2: 'ܥܶܒܶܕܝܶܫܽܘܥ',
            dt2: '',
            dt1: 'Ebedjeschu',
            ar3: '',
            bedeutung: 'männl., siehe Abedjeschu',
            id: '288',
            ar1: 'ܥܒܕܝܫܘܥ',
            es1: 'ܥܒܕܝܫܘܥ',
            es3: ''
        },
        {
            ar3: '',
            es1: 'ܪܡܙܐ',
            ar1: 'ܪܡܙܐ',
            es3: '',
            es2: 'ܪܡܙܐ',
            id: '852',
            bedeutung: 'männl., “Zeichen, Signal, Motto, Anweisung”; weitere Formen: Remso',
            ar2: 'ܪܶܡܙܳܐ',
            dt2: '',
            dt3: '',
            dt1: 'Remzo'
        },
        {
            es1: 'ܬܡܪܐ',
            dt3: '',
            dt1: 'Tamara',
            bedeutung: 'männl., “du bist ein Wunder”',
            es3: 'ܬܡܪ',
            id: '2227',
            es2: 'ܬܡܪܐ',
            ar1: 'ܬܡܪܐ',
            ar2: 'ܬܰܡܰܪܰܐ',
            ar3: 'ܬܰܡܰܪ',
            dt2: ''
        },
        {
            es1: 'ܚܪܝܐܬܐ',
            es3: 'ܚܪܝܬܐ',
            es2: 'ܚܪܝܐܬܐ',
            ar2: 'ܚܰܪܝܰܐܬܰܐ',
            id: '1633',
            dt2: '',
            ar3: 'ܚܰܪܰܝܬܰܐ',
            dt1: 'Hariata',
            ar1: 'ܚܪܝܐܬܐ',
            bedeutung: '',
            dt3: ''
        },
        {
            dt1: 'Magdalena',
            ar3: '',
            id: '645',
            dt3: '',
            ar2: 'ܡܓܕܠܝܬܐ',
            dt2: '',
            ar1: 'ܡܓܕܠܝܬܐ',
            es3: '',
            es2: 'ܡܓܕܠܢܐ',
            es1: 'ܡܓܕܠܢܐ',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “die aus Magdala Stammende”, ”Turm“. Im NT ist Maria Magdalena eine der treuesten Anhängerinnen Jesu. Weitere Formen: Magedlayta, Magedloyto'
        },
        {
            dt2: 'Marjam',
            es2: 'ܡܪܝܡ',
            bedeutung: 'männl., ”Land Gottes“. Matilu war aramäischer König von Beth-Agusi (740 v. Chr.).',
            dt3: 'Mirjam',
            es3: 'ܡܝܪܝܡ',
            ar2: 'ܡܰܪܝܰܡ',
            id: '1871',
            ar3: 'ܡܺܝܪܝܰܡ',
            es1: 'ܡܪܝܡ',
            dt1: 'Maryam',
            ar1: 'ܡܪܝܡ'
        },
        {
            es2: 'ܓܘܕܐ',
            dt3: '',
            ar1: 'ܓܘܕܐ',
            ar3: '',
            dt1: 'Guda',
            bedeutung: 'männl., “Gib o Gott”',
            es3: '',
            ar2: 'ܓܽܘܕܰܐ',
            dt2: '',
            id: '1583',
            es1: 'ܓܘܕܐ'
        },
        {
            dt1: 'Yadido',
            es1: 'ܝܕܝܕܐ',
            ar3: '',
            bedeutung: 'männl., “Ausgebildeter, Intellektueller, Kenner, Experte”; weitere Formen: Jalif',
            dt2: '',
            es3: '',
            dt3: '',
            ar1: 'ܝܕܝܕܐ',
            es2: 'ܝܕܝܕܐ',
            id: '2305',
            ar2: 'ܝܰܕܺܝܕܳܐ'
        },
        {
            ar2: 'ܛܰܠܺܝܬܰܐ',
            ar3: '',
            bedeutung: 'männl., “Dank sei Gott” oder “Gott sei Dank”; weitere Formen: Tawdil',
            dt2: '',
            es1: 'ܛܠܝܬܐ',
            dt3: '',
            es2: 'ܛܠܝܬܐ',
            ar1: 'ܛܠܝܬܐ',
            es3: '',
            dt1: 'Talita',
            id: '2223'
        },
        {
            id: '194',
            es1: 'ܒܪܝܡܝܢ',
            dt2: '',
            es3: '',
            ar3: '',
            ar2: 'ܒܰܪܝܰܡܺܝܢ',
            es2: 'ܒܪܝܡܝܢ',
            ar1: 'ܒܪܝܡܝܢ',
            dt3: '',
            dt1: 'Baryamin',
            bedeutung: 'männl., siehe Benjamin'
        },
        {
            ar1: 'ܐܝܪܚܡܠ',
            ar3: '',
            ar2: 'ܐܺܝܪܰܚܡܶܠ',
            dt3: '',
            es3: '',
            dt1: 'Jerachmeel',
            id: '533',
            dt2: '',
            es2: 'ܐܝܪܚܡܠ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott habe Barmherzigkeit”; weitere Formen: Irahmel',
            es1: 'ܐܝܪܚܡܠ'
        },
        {
            es2: 'ܘܪܕܐ',
            es1: 'ܘܪܕܐ',
            ar3: '',
            dt1: 'Wardo',
            id: '2299',
            dt3: '',
            es3: '',
            dt2: '',
            bedeutung: 'männl., “Gott hat gegeben, weitere Formen: Yabil, Jahbil, Jabil',
            ar1: 'ܘܪܕܐ',
            ar2: 'ܘܰܪܕܳܐ'
        },
        {
            ar1: 'ܪܒܝ',
            bedeutung: 'männl., “mein Herr, Meister, Lehrer, Rabbi”; weitere Formen: Rabbi',
            es3: 'ܪܒܒܝ',
            ar3: 'ܪܰܒܒܺܝ',
            ar2: 'ܪܰܒܺܝ',
            es1: 'ܪܒܝ',
            dt2: '',
            es2: 'ܪܒܝ',
            dt1: 'Rabi',
            dt3: '',
            id: '824'
        },
        {
            es3: '',
            dt3: '',
            ar2: 'ܥܽܘܡܪܺܝ',
            ar1: 'ܥܘܡܪܝ',
            es1: 'ܥܘܡܪܝ',
            dt1: 'Umri',
            dt2: '',
            bedeutung: 'männl., “mein Leben”',
            id: '1084',
            ar3: '',
            es2: 'ܥܘܡܪܝ'
        },
        {
            dt3: '',
            ar3: '',
            bedeutung: 'männl., “getröstet”',
            es2: 'ܒܝܐ',
            dt2: '',
            ar1: 'ܒܝܐ',
            es3: '',
            dt1: 'Baya',
            ar2: 'ܒܰܝܰܐ',
            id: '212',
            es1: 'ܒܝܐ'
        },
        {
            es1: 'ܐܬܪܐ',
            ar2: 'ܐܰܬܪܰܐ',
            ar1: 'ܐܬܪܐ',
            ar3: '',
            es3: '',
            id: '124',
            bedeutung: 'weibl., “Heimat, Ort, Gegend',
            dt3: '',
            dt1: 'Atra',
            dt2: '',
            es2: 'ܐܬܪܐ'
        },
        {
            ar1: 'ܪܝܡܐ',
            id: '860',
            es2: 'ܪܝܡܐ',
            dt1: 'Rima',
            dt2: '',
            dt3: '',
            ar2: 'ܪܺܝܡܰܐ',
            es1: 'ܪܝܡܐ',
            es3: '',
            ar3: '',
            bedeutung: 'weibl., siehe Rayma'
        },
        {
            ar3: 'ܐܶܠܺܝܫܡܰܥ',
            es2: 'ܐܠܝܫܡܥ',
            es1: 'ܐܠܝܫܡܥ',
            id: '1489',
            ar2: 'ܐܶܠܺܝܫܰܡܰܥ',
            dt1: 'Elischamah',
            dt3: '',
            bedeutung: 'männl., “Gott ist die Sonne”',
            ar1: 'ܐܠܝܫܡܥ',
            dt2: 'Elischama',
            es3: 'ܐܠܝܫܡܥ'
        },
        {
            es1: 'ܙܠܓܐ',
            dt1: 'Zalgo',
            id: '1161',
            es3: '',
            bedeutung: 'männl., “Licht, Strahl, Glanz”',
            dt3: '',
            es2: 'ܙܠܓܐ',
            ar3: '',
            ar2: 'ܙܰܠܓܳܐ',
            ar1: 'ܙܠܓܐ',
            dt2: ''
        },
        {
            dt2: '',
            dt1: 'Mhadyono',
            id: '1887',
            ar3: '',
            es2: 'ܡܗܕܝܢܐ',
            bedeutung: '',
            ar2: 'ܡܗܰܕܝܳܢܳܐ',
            ar1: 'ܡܗܕܝܢܐ',
            es1: 'ܡܗܕܝܢܐ',
            es3: '',
            dt3: ''
        },
        {
            dt2: '',
            ar2: 'ܝܰܒܪܽܘܕܺܝܰܐ',
            es2: 'ܝܒܪܘܕܝܐ',
            id: '520',
            ar3: '',
            es3: '',
            bedeutung: 'weibl., Jabrudia war eine Stadt in Alt-Syrien',
            dt3: '',
            dt1: 'Jabrudia',
            ar1: 'ܝܒܪܘܕܝܐ',
            es1: 'ܝܒܪܘܕܝܐ'
        },
        {
            es1: 'ܐܓܝ',
            bedeutung: 'männl., Agai ist einer der 70/72 Missionare, Schüler des Adai und Missionar in Syrien und ganz AramBeth-Nahrin (= Mesopotamien). Weitere Formen: Agay',
            dt2: '',
            es3: '',
            ar2: 'ܐܰܓܰܝ',
            es2: 'ܐܓܝ',
            ar3: '',
            ar1: 'ܐܓܝ',
            dt3: '',
            dt1: 'Agai',
            id: '1224'
        },
        {
            ar1: 'ܠܘܝܬܐ',
            ar2: 'ܠܶܘܺܝܬܰܐ',
            es1: 'ܠܘܝܬܐ',
            dt3: '',
            id: '1818',
            es2: 'ܠܘܝܬܐ',
            dt2: '',
            bedeutung: 'weibl., “Speerspitze, Lanzenspitze”',
            ar3: '',
            dt1: 'Levita',
            es3: ''
        },
        {
            ar2: 'ܐܰܣܺܝܪ',
            ar1: 'ܐܣܝܪ',
            dt1: 'Assir',
            bedeutung: 'weibl., “Flagge, Symbol, Zeichen”',
            es2: 'ܐܣܝܪ',
            id: '1292',
            dt2: '',
            es3: '',
            dt3: '',
            es1: 'ܐܣܝܪ',
            ar3: ''
        },
        {
            es2: 'ܗܘܕܐ',
            ar2: 'ܗܽܘܕܰܐ',
            dt2: '',
            ar3: 'ܚܳܘܕܰܐ',
            id: '1675',
            es1: 'ܗܘܕܐ',
            ar1: 'ܗܘܕܐ',
            es3: 'ܚܘܕܐ',
            bedeutung: 'männl., lateinischen Ursprungs; “das Feuer, das Feurige”. Ignatius (†107) war der zweite bzw. Dritte Bischof/Patriarch der syrisch-orthodoxen Kirche von Antiochien; seit 1293 gilt “Ignatius” als Beiname ﬁir die syrisch-orthodoxen Patriarchen',
            dt3: '',
            dt1: 'Huda'
        },
        {
            ar1: 'ܙܘܙܝ',
            bedeutung: '',
            es3: '',
            dt1: 'Susi',
            es2: 'ܙܘܙܝ',
            ar3: '',
            es1: 'ܙܘܙܝ',
            id: '1021',
            dt2: '',
            ar2: 'ܙܽܘܙܺܝ',
            dt3: ''
        },
        {
            dt2: '',
            ar2: 'ܠܒܽܘܢܬܰܐ',
            es1: 'ܠܒܘܢܬܐ',
            es3: '',
            bedeutung: 'männl., “[er] hat mich bedient”',
            dt3: '',
            id: '1813',
            ar3: '',
            es2: 'ܠܒܘܢܬܐ',
            ar1: 'ܠܒܘܢܬܐ',
            dt1: 'Lbunta'
        },
        {
            ar3: '',
            es3: '',
            bedeutung: 'männl., “Eingeborener, Einziggeborener, eigenartig”',
            es1: 'ܚܙܩܝܐܝܠ',
            ar2: 'ܚܙܩܝܐܠ',
            es2: 'ܚܙܩܝܐܝܠ',
            id: '1511',
            ar1: 'ܚܙܩܝܐܝܠ',
            dt3: '',
            dt2: 'Hesekiel',
            dt1: 'Ezekiel'
        },
        {
            ar2: 'ܐܰܘܟܺܝܬ',
            dt3: '',
            es3: '',
            id: '130',
            ar1: 'ܐܘܟܝܬ',
            bedeutung: 'männl., siehe Okit',
            es2: 'ܐܘܟܝܬ',
            dt2: '',
            es1: 'ܐܘܟܝܬ',
            dt1: 'Aukit',
            ar3: ''
        },
        {
            id: '2264',
            ar3: '',
            ar1: 'ܬܢܝ',
            es2: 'ܬܢܝ',
            dt3: '',
            es3: '',
            bedeutung: 'männl., “mein Berg, meine Heimat Turabdin”',
            es1: 'ܬܢܝ',
            dt2: 'Tony',
            dt1: 'Toni',
            ar2: 'ܬܳܢܺܝ'
        },
        {
            es2: 'ܫܒܗܪ',
            ar2: 'ܫܰܒܗܰܪ',
            id: '918',
            dt1: 'Schabhar',
            es1: 'ܫܒܗܪ',
            ar3: '',
            dt3: '',
            es3: '',
            dt2: '',
            bedeutung: 'männl., “verherrlichen, lobpreisen”',
            ar1: 'ܫܒܗܪ'
        },
        {
            dt2: '',
            es3: 'ܡܫܒܠܢ',
            ar3: 'ܡܫܰܒܠܳܢ',
            id: '720',
            es2: 'ܡܫܒܠܢܐ',
            dt3: '',
            dt1: 'Mschablono',
            es1: 'ܡܫܒܠܢܐ',
            ar2: 'ܡܫܰܒܠܳܢܳܐ',
            ar1: 'ܡܫܒܠܢܐ',
            bedeutung: 'männl., syrischen Ursprungs; °“Wegweiser, Führer, Direktor”'
        },
        {
            ar3: '',
            dt3: '',
            bedeutung: '',
            ar2: 'ܦܩܺܝܕܳܐ',
            dt1: 'Fqido',
            dt2: '',
            es1: 'ܦܩܝܕܐ',
            es2: 'ܦܩܝܕܐ',
            id: '1531',
            ar1: 'ܦܩܝܕܐ',
            es3: ''
        },
        {
            ar2: 'ܙܟܪܝܐܣ',
            dt2: 'Zakaria',
            ar3: '',
            dt1: 'Zakariya',
            dt3: 'Sacharja',
            id: '2347',
            ar1: 'ܙܟܪܝܝܐ',
            bedeutung: 'weibl., Zazabuha (oder Zaz) ist ein Dorf in Turabdin; weitere Formen: Zaz',
            es1: 'ܙܟܪܝܝܐ',
            es3: '',
            es2: 'ܙܟܪܝܝܐ'
        },
        {
            ar1: 'ܙܕܩܝܐ',
            id: '1166',
            dt3: '',
            ar2: 'ܙܶܕܰܩܝܰܐ',
            dt2: '',
            es1: 'ܙܕܩܝܐ',
            es3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe ist meine Gerechtigkeit”. Im AT ist Zidkija der letzte König des Südreiches Juda (597-587 v. Chr.). Weitere Formen: Sidkija, Sedakio, Sedakia, Sedaqio, Sedaqia, Zidkija',
            ar3: '',
            es2: 'ܙܕܩܝܐ',
            dt1: 'Zedakija'
        },
        {
            es2: 'ܟܐܡܘܢܐ',
            ar1: 'ܟܐܡܘܢܐ',
            dt1: 'Kimuno',
            bedeutung: 'männl., “Wassersturm”',
            es1: 'ܟܐܡܘܢܐ',
            id: '596',
            es3: '',
            ar2: 'ܟܺܐܡܽܘܢܳܐ',
            dt2: '',
            ar3: '',
            dt3: ''
        },
        {
            dt2: '',
            id: '709',
            es3: '',
            dt1: 'Mor Ignatius',
            es2: ' ܡܪܝ ܐܝܓܢܐܛܝܘܣ',
            dt3: '',
            bedeutung: '',
            ar3: '',
            es1: ' ܡܪܝ ܐܝܓܢܐܛܝܘܣ',
            ar2: 'ܡܪܝ ܐܝܓܢܐܛܝܘܣ',
            ar1: 'ܡܪܝ ܐܝܓܢܐܛܝܘܣ'
        },
        {
            ar1: 'ܫܘܒܟ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs. Im AT ist Schobach Oberbefehlshaber des aramäischen Königs von Aram-Damaskus Hadad-Eser (11. Jh. v.Chr.). Weitere Formen: Schuboch, Schbuch,',
            ar2: 'ܫܽܘܡܰܟ',
            id: '970',
            dt2: '',
            es1: 'ܫܘܒܟ',
            es2: 'ܫܘܒܟ',
            dt1: 'Schobach',
            es3: 'ܫܡܘܟ',
            ar3: 'ܫܡܽܘܟ',
            dt3: ''
        },
        {
            es2: 'ܝܪܒ',
            ar3: '',
            bedeutung: 'männl., “wachsen, aufwachsen, groß werden”; weitere Formen: Jirab',
            es1: 'ܝܪܒ',
            dt2: 'Jirab',
            dt1: 'Yirab',
            dt3: '',
            id: '1137',
            ar2: 'ܝܺܪܰܒ',
            es3: '',
            ar1: 'ܝܪܒ'
        },
        {
            bedeutung: 'männl., aramäischer Vorname; “Sohn des Hadad”; Ben-Hadad I. (oder Bar Hadad) war Sohn des Tabrimmon, des Sohnes des Hesjon, König von Aram-Damaskus (um 900 v.Chr.). Ben-Hadad II., König von Aram-Damaskus, war Sohn und Nachfolger des Ben-Hadad I, (um 875 v.Chr.), Ben-Hadad III. Sohn des Hasael, König von Aram-Damaskus (um 797-773)',
            id: '217',
            es3: 'ܒܢܚܕܕ',
            dt3: '',
            ar2: 'ܒܶܢܗܰܕܰܕ',
            dt1: 'Ben Hadad',
            es2: 'ܒܢܗܕܕ',
            dt2: '',
            ar3: 'ܒܶܢܚܰܕܰܕ',
            es1: 'ܒܢܗܕܕ',
            ar1: 'ܒܢܗܕܕ'
        },
        {
            ar2: 'ܚܢܽܘܟ݂',
            ar1: 'ܚܢܘܟ݂',
            es3: '',
            id: '1654',
            ar3: '',
            dt3: '',
            bedeutung: '',
            dt1: 'Henoch',
            es1: 'ܚܢܘܟ݂',
            es2: 'ܚܢܘܟ݂',
            dt2: ''
        },
        {
            bedeutung: 'weibl., Nebenform zu Rahel',
            es2: 'ܪܒܝ',
            dt3: '',
            ar3: 'ܪܰܒܒܺܝ',
            ar1: 'ܪܒܝ',
            dt2: '',
            es3: 'ܪܒܒܝ',
            ar2: 'ܪܰܒܺܝ',
            dt1: 'Rabi',
            id: '2016',
            es1: 'ܪܒܝ'
        },
        {
            id: '1883',
            dt2: 'Malak',
            ar2: 'ܡܶܠܶܟ',
            es3: '',
            ar1: 'ܡܠܟ',
            ar3: '',
            bedeutung: 'weibl., Milidja ist eine frühere aramäische Stadt, sie heißt heute Malatya und liegt in der Türkei. Weitere Formen: Milidya, Militina, Melita',
            dt1: 'Melek',
            dt3: '',
            es2: 'ܡܠܟ',
            es1: 'ܡܠܟ'
        },
        {
            ar1: 'ܟܘܙܐ',
            dt2: '',
            dt3: '',
            ar3: 'ܟܽܘܙܳܐ',
            es3: '',
            es2: 'ܟܘܙܐ',
            es1: 'ܟܘܙܐ',
            id: '1798',
            dt1: 'Kuza',
            bedeutung: 'männl., “zusammenbringen, koppeln, zusammenfügen, sammeln, versammeln”',
            ar2: 'ܟܳܘܙܰܐ'
        },
        {
            es1: 'ܪܒܐܝܠ',
            ar2: 'ܪܰܒܳܐ',
            es2: 'ܪܒܐܝܠ',
            dt2: '',
            es3: '',
            dt3: '',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Mutterschaft”. Lm AT ist Rahel die jüngere und schöne Tochter des Aramäers Laban, die zweite Frau Jakobs (neben Lea, Ihrer älteren Schwester) und Mutter von Joseph und Benjamin, Hirtin; die Aramäerinnen Lea und Rahel gelten als Stammmütter Israels (der Israeliten). Weitere Formen: Rachel, Rohel; sehr beliebter Vorname bei den Aramäern',
            id: '2020',
            ar3: '',
            ar1: 'ܪܒܐ',
            dt1: 'Rabo'
        },
        {
            dt3: '',
            dt2: '',
            ar3: '',
            dt1: 'Hakimuta',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott hat Gnade gewirkt”; weitere Formen: Hananayel',
            ar1: 'ܚܟܝܡܘܬܐ',
            es2: 'ܚܟܝܡܘܬܐ',
            es1: 'ܚܟܝܡܘܬܐ',
            ar2: 'ܚܰܟܺܝܡܽܘܬܰܐ',
            es3: '',
            id: '1612'
        },
        {
            ar3: '',
            es2: 'ܐܝܙܠܐ',
            id: '516',
            ar1: 'ܐܝܙܠܐ',
            dt1: 'Izala',
            bedeutung: 'weibl., Izala ist eine Landschaﬁ in Aram Beth-Nahrin (= Mesopotamien), Teil von Turabdin. Weitere Formen: Izla',
            es3: '',
            ar2: 'ܐܺܝܙܰܠܰܐ',
            es1: 'ܐܝܙܠܐ',
            dt3: '',
            dt2: ''
        },
        {
            id: '1614',
            ar3: '',
            bedeutung: 'männl., syrischen Ursprungs; “Gnade Jesu” oder “Jesus hat Gnade”; weitere Formen: Hananischo, Hnonjeschu',
            dt1: 'Hammurabi',
            es3: '',
            dt3: '',
            ar1: 'ܚܡܘܪܒܝ',
            ar2: 'ܚܰܡܽܘܪܰܒܺܝ',
            es1: 'ܚܡܘܪܒܝ',
            dt2: '',
            es2: 'ܚܡܘܪܒܝ'
        },
        {
            es1: 'ܡܕܝܢܬܐ',
            ar2: 'ܡܶܕܺܝܢ̱ܬܰܐ',
            dt3: '',
            dt1: 'Medita',
            es2: 'ܡܕܝܢܬܐ',
            es3: '',
            ar1: 'ܡܕܝܢ̱ܬܐ',
            ar3: '',
            bedeutung: 'weibl., “Stadt, Land, Provinz”; weitere Formen: Mdita',
            id: '693',
            dt2: ''
        },
        {
            es3: 'ܟܪܝܣܛܝܐܢ',
            es1: 'ܟܪܝܣܬܝܢ',
            dt2: 'Kristian',
            bedeutung: 'männl., aus dem Griechischen übernommener Vorname; “der zum Herrn Gehörende”. In der Kirchengeschichte war Cyrillus Kirchenlehrer und Bischof von Jerusalem (4. Jh.). Zu nennen sind weiter Cyrillus von Alexandrien (5. Jh.) und Cyrillonas (4. Jh.). Weitere Formen: Kyrillus, Kyrillos',
            dt1: 'Christian',
            es2: 'ܟܪܝܣܬܝܢ',
            ar2: 'ܟܪܺܝܣܬܝܰܢ',
            dt3: '',
            id: '1425',
            ar1: 'ܟܪܝܣܬܝܢ',
            ar3: 'ܟܪܺܝܣܛܝܰܐܢ'
        },
        {
            es1: 'ܒܝܬܢܗܪܝܢ',
            ar2: 'ܒܶܝܬܢܰܗܪܺܝܢ',
            ar1: 'ܒܝܬܢܗܪܝܢ',
            es2: 'ܒܝܬܢܗܪܝܢ',
            ar3: 'ܒܶܝܬܢܰܗܪܰܐ',
            id: '1406',
            dt1: 'Bethnahrin',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Haus Gottes”. Betuèl ist im AT ein Aramäer aus Paddan-Aram, Sohn Nahors, Neffe Abrohoms, Vater Labans und der Rebekka, Großvater -mütterlicherseits- des Jakob',
            dt2: '',
            dt3: '',
            es3: ''
        },
        {
            es1: 'ܒܪܥܐܕܐ',
            ar1: 'ܒܪܥܐܕܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott segnet”; weitere Formen: Barakhel',
            dt1: 'Bar Ido',
            dt3: '',
            ar2: 'ܒܰܪܥܺܐܕܳܐ',
            ar3: '',
            es3: '',
            id: '1335',
            es2: 'ܒܪܥܐܕܐ',
            dt2: ''
        },
        {
            dt3: '',
            ar1: 'ܢܝܨܝܒܢܐ',
            bedeutung: 'weibl., wohl “Gründerin, Errichterin”. Nisibin/Nisibena ist eine Stadt in Aram-Beth-Nahrin (=Mesopotamien)',
            ar2: 'ܢܺܝܨܺܝܒܶܢܰܐ',
            id: '774',
            dt1: 'Nisibena',
            es3: '',
            dt2: '',
            es2: 'ܢܝܨܝܒܢܐ',
            es1: 'ܢܝܨܝܒܢܐ',
            ar3: ''
        },
        {
            es1: 'ܐܘܪܝܬܐ',
            ar2: 'ܐܽܘܪܺܝܬܰܐ',
            dt3: '',
            bedeutung: 'männl., König von Hatra (155-165 n.Chr.); weitere Formen: Volgoses, Walagasch',
            es3: '',
            ar1: 'ܐܘܪܝܬܐ',
            es2: 'ܐܘܪܝܬܐ',
            dt1: 'Urita',
            id: '2285',
            ar3: '',
            dt2: ''
        },
        {
            bedeutung: ' männl., “fest, steif, hart,massiv, dicht, geschlossen”',
            es3: '',
            ar2: 'ܐܰܛܺܝܡ',
            dt1: 'Atim',
            ar1: 'ܐܛܝܡ',
            dt2: '',
            ar3: '',
            es2: 'ܐܛܝܡ',
            dt3: '',
            id: '121',
            es1: 'ܐܛܝܡ'
        },
        {
            id: '2274',
            ar3: '',
            es1: 'ܛܘܪܐ',
            dt3: '',
            es3: '',
            ar2: 'ܛܽܘܪܳܐ',
            es2: 'ܛܘܪܐ',
            dt2: '',
            dt1: 'Turo',
            ar1: 'ܛܘܪܐ',
            bedeutung: 'weibl., “Land, Ort der Liebe”'
        },
        {
            dt2: '',
            bedeutung: 'männl., “Zypergras, Schilfgras”; weitere Formen: Saado, Seedo',
            es1: 'ܣܥܝܕ',
            id: '892',
            dt3: '',
            es3: '',
            ar1: 'ܣܥܝܕ',
            ar2: 'ܣܰܥܺܝܕ',
            es2: 'ܣܥܝܕ',
            dt1: 'Said',
            ar3: ''
        },
        {
            bedeutung: 'männl., “Eidechse, Echse, Krokodil”',
            dt3: '',
            ar3: '',
            dt2: '',
            ar1: 'ܝܕܝܕܬܐ',
            dt1: 'Yadidta',
            id: '2306',
            ar2: 'ܝܰܕܺܝܕܬܰܐ',
            es2: 'ܝܕܝܕܬܐ',
            es1: 'ܝܕܝܕܬܐ',
            es3: ''
        },
        {
            es2: 'ܝܘܠܝܘܣ',
            ar3: '',
            es3: '',
            es1: 'ܝܘܠܝܘܣ',
            bedeutung: 'weibl., syrischen Ursprungs; “Braut”; weitere Formen: Kalta',
            dt2: '',
            dt3: '',
            ar2: 'ܝܽܘܠܺܝܳܘܣ',
            id: '1756',
            ar1: 'ܝܘܠܝܘܣ',
            dt1: 'Julius'
        },
        {
            ar2: 'ܝܘܠܝܰܢܰܐ',
            dt1: 'Juliana',
            bedeutung: '',
            id: '567',
            dt2: 'Yulyana',
            ar1: 'ܝܘܠܝܢܐ',
            es2: 'ܝܘܠܝܢܐ',
            es3: '',
            dt3: 'Julyana',
            es1: 'ܝܘܠܝܢܐ',
            ar3: ''
        },
        {
            es1: 'ܓܝܕܘܬܐ',
            ar1: 'ܓܝܕܘܬܐ',
            ar2: 'ܓܺܝܕܽܘܬܰܐ',
            es2: 'ܓܝܕܘܬܐ',
            dt1: 'Giduta',
            id: '384',
            ar3: '',
            es3: '',
            bedeutung: 'weibl., “Flamme”; weitere Formen: Gidutha',
            dt3: '',
            dt2: ''
        },
        {
            ar1: 'ܗܘܫܥ',
            es3: '',
            ar2: 'ܗܽܘܫܳܥ',
            dt2: '',
            es2: 'ܗܘܫܥ',
            dt3: '',
            ar3: '',
            es1: 'ܗܘܫܥ',
            dt1: 'Huschoh',
            id: '1680',
            bedeutung: 'weibl., “Baum”'
        },
        {
            es1: 'ܩܘܪܠܘܢܐ',
            bedeutung: 'männl., “groß, riesig”',
            dt1: 'Qurlona',
            id: '2011',
            ar3: '',
            dt3: '',
            es3: '',
            dt2: '',
            ar2: 'ܩܽܘܪܠܽܘܢܰܐ',
            ar1: 'ܩܘܪܠܘܢܐ',
            es2: 'ܩܘܪܠܘܢܐ'
        },
        {
            id: '1764',
            dt2: '',
            ar2: 'ܟܰܠܳܢܺܝܬܰܐ',
            es1: 'ܟܠܢܝܬܐ',
            es3: 'ܟܠܘܢܝܬܐ',
            ar3: 'ܟܰܠܽܘܢܺܝܬܰܐ',
            dt1: 'Kalonita',
            bedeutung: 'weibl., syrischen Ursprungs; “die Fleißige, ein(e) ﬂeißige(s) Frau [Mädchen]”',
            es2: 'ܟܠܢܝܬܐ',
            dt3: '',
            ar1: 'ܟܠܢܝܬܐ'
        },
        {
            dt1: 'Abrahat',
            id: '27',
            es2: 'ܐܒܪܗܛ',
            ar1: 'ܐܒܪܗܛ',
            bedeutung: 'männl., Abrahat war Sohn des Ma`nu und König von Edessa (240-242); weitere Formen: Frahad, Frahat',
            ar2: 'ܐܰܒܪܰܗܰܛ',
            dt3: '',
            es1: 'ܐܒܪܗܛ',
            ar3: '',
            dt2: '',
            es3: ''
        },
        {
            dt3: '',
            dt1: 'Telonita',
            ar3: '',
            es1: 'ܛܠܢܝܬܐ',
            bedeutung: 'weibl., “Schatten, Phantasie, Illusion”; weitere Formen: Talonita',
            ar2: 'ܛܶܠܳܢܺܝܬܰܐ',
            es3: '',
            id: '1046',
            ar1: 'ܛܠܢܝܬܐ',
            es2: 'ܛܠܢܝܬܐ',
            dt2: ''
        },
        {
            es2: 'ܐܪܡ',
            bedeutung: 'männl., aus dem Griechischen übernommener Vorname, der wiederum eine Übertragung des aramäischen Namens Kepha ist; “Stein, Felsblock”. Im NT ist Simon mit dem Beinamen Petrus ein Apostel Jesu Christi und Vorsprecher der Jünger; Petrus gilt als der Gründer und erste Bischof bzw. Patriarch der syrisch-orthodoxen Kirche von Antiochien. Weitere Formen: Fetrus, Bedros, Bedrus',
            es1: 'ܐܪܡ',
            dt2: '',
            ar2: 'ܐܳܪܳܡ',
            ar3: '',
            dt3: '',
            ar1: 'ܐܪܡ',
            es3: '',
            dt1: 'Orom',
            id: '1990'
        },
        {
            bedeutung: 'männl., Arout war Schüler des HL. Ephräm',
            es1: 'ܐܪܢܐܣܝܐ',
            ar2: 'ܐܰܪܢܰܐܣܺܝܰܐ',
            ar1: 'ܐܪܢܐܣܝܐ',
            id: '1282',
            dt1: 'Arnasia',
            dt3: '',
            ar3: '',
            es3: '',
            dt2: 'Arnasja',
            es2: 'ܐܪܢܐܣܝܐ'
        },
        {
            dt1: 'Akifa',
            es2: 'ܐܟܝܦܐ',
            ar2: 'ܐܰܟܺܝܦܰܐ',
            dt2: 'Akif',
            es1: 'ܐܟܝܦܐ',
            ar1: 'ܐܟܝܦܐ',
            ar3: '',
            id: '1244',
            es3: '',
            dt3: '',
            bedeutung: 'männl., “ﬂeißig, eifrig”'
        },
        {
            es3: '',
            es2: 'ܐܘܪܚܐ',
            dt1: 'Urha',
            es1: 'ܐܘܪܚܐ',
            bedeutung: 'weibl., “Weg, Straße”',
            dt3: '',
            ar2: 'ܐܽܘܪܚܰܐ',
            ar3: '',
            ar1: 'ܐܘܪܚܐ',
            dt2: '',
            id: '1087'
        },
        {
            dt1: 'Mor Yuhanon Abrohom',
            id: '716',
            es2: 'ܡܪܝ ܝܘܚܢܢ ܐܒܪܗܡ',
            ar2: 'ܡܪܝ ܝܘܚܢܢ ܐܒܪܗܡ',
            ar1: 'ܡܪܝ ܝܘܚܢܢ ܐܒܪܗܡ',
            ar3: '',
            dt2: '',
            dt3: '',
            bedeutung: '',
            es1: 'ܡܪܝ ܝܘܚܢܢ ܐܒܪܗܡ',
            es3: ''
        },
        {
            dt2: 'Layla',
            es2: 'ܠܝܠܐ',
            id: '631',
            dt1: '̱Leyla',
            es1: 'ܠܝܠܐ',
            es3: 'ܠܝܠܗ',
            ar3: 'ܠܰܝܠܰܗ',
            dt3: '',
            ar2: 'ܠܰܝܠܰܐ',
            ar1: 'ܠܝܠܐ',
            bedeutung: 'weibl., aus dem Persischen übernommener Vorname, der aramäisch-hebräischen Ursprungs ist, aus lelyo, lelya; “Nacht, Dunkelheit“, weitere Formen: Leyla, Lilya'
        },
        {
            ar3: '',
            ar2: 'ܚܺܐܪܽܘܬܰܐ',
            dt2: '',
            dt1: 'Hiruta',
            es3: '',
            es1: 'ܚܐܪܘܬܐ',
            ar1: 'ܚܐܪܘܬܐ',
            id: '1667',
            dt3: '',
            es2: 'ܚܐܪܘܬܐ',
            bedeutung: 'weibl., Huda war Ehefrau von Abrahat, König von Edessa'
        },
        {
            es2: 'ܒܢܝ',
            ar1: 'ܒܢܝ',
            ar3: '',
            bedeutung: 'männl., “er hat gegründet”',
            ar2: 'ܒܰܢܺܝ',
            id: '150',
            es3: '',
            dt3: '',
            dt2: '',
            dt1: 'Bani',
            es1: 'ܒܢܝ'
        },
        {
            es3: '',
            dt2: '',
            bedeutung: 'männl., “Becken, Beckenteller, Kastagnette”',
            dt1: 'Sislo',
            ar2: 'ܨܺܝܨܠܳܐ',
            dt3: '',
            es1: 'ܨܝܨܠܐ',
            id: '997',
            es2: 'ܨܝܨܠܐ',
            ar1: 'ܨܝܨܠܐ',
            ar3: ''
        },
        {
            ar2: 'ܢܳܥܡܰܢ',
            id: '780',
            dt1: 'Numan',
            es3: '',
            es1: 'ܢܥܡܢ',
            ar1: 'ܢܥܡܢ',
            ar3: '',
            bedeutung: 'siehe Naaman',
            dt2: 'Neman',
            dt3: 'Nouman',
            es2: 'ܢܥܡܢ'
        },
        {
            ar2: 'ܬܰܡܰܪܰܐ',
            dt3: '',
            dt1: 'Tamara',
            es1: 'ܬܡܪܐ',
            ar1: 'ܬܡܪܐ',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Dattelpalme, Palmenbaum”. Im AT ist Tamar(a) die Schwester Abschaloms, Tochter Davids und der Maacha. Weitere Formen: Tomor',
            dt2: '',
            es3: 'ܬܡܪ',
            ar3: 'ܬܰܡܰܪ',
            es2: 'ܬܡܪܐ',
            id: '1035'
        },
        {
            dt3: '',
            es1: 'ܩܡܘܐܝܠ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs. Im AT ist Kemuel Sohn Nahors, des Bruders Abrahams, und Vater des Aram. Weitere Formen: Qemuel, Qemuyel',
            es3: 'ܩܡܘܐܝܠ',
            ar2: 'ܩܰܡܽܘܐܝܶܠ',
            ar1: 'ܩܡܘܐܝܠ',
            dt1: 'Kemuel',
            ar3: 'ܩܡܽܘܐܝܶܠ',
            es2: 'ܩܡܘܐܝܠ',
            dt2: '',
            id: '590'
        },
        {
            es3: 'ܒܣܢܐ',
            bedeutung: 'weibl., wohl abgeleitet von Basma/Basmo “Weihrauch”; weitere Formen: Bassna',
            es1: 'ܒܣܢܐ',
            ar3: 'ܒܶܣܢܰܐ',
            ar1: 'ܒܣܢܐ',
            dt3: '',
            id: '201',
            ar2: 'ܒܰܣܢܰܐ',
            dt1: 'Basna',
            dt2: 'Besna',
            es2: 'ܒܣܢܐ'
        },
        {
            ar1: 'ܝܘܠܝܘܣ',
            es3: '',
            es2: 'ܝܘܠܝܘܣ',
            es1: 'ܝܘܠܝܘܣ',
            dt3: '',
            ar3: '',
            ar2: 'ܝܽܘܠܺܝܳܘܣ',
            dt2: '',
            id: '568',
            bedeutung: 'männl., aus dem Lateinischen übernommener Vorname; “der aus dem Geschlecht der Julier”; ein weiterer Beiname der syrischen Bischöfe. Weitere Formen: Yulius, Julios, Julian',
            dt1: 'Julius'
        },
        {
            bedeutung: 'männl., ein aramäischer Stamm, der im 12. Jh. v. Chr. am Oberlauf des Jabbok auﬁauchte und einen Staat gründete',
            ar2: 'ܥܰܡܳܘܢ',
            es3: '',
            es2: 'ܥܡܘܢ',
            id: '81',
            dt1: 'Ammon',
            ar1: 'ܥܡܘܢ',
            dt3: '',
            ar3: '',
            dt2: '',
            es1: 'ܥܡܘܢ'
        },
        {
            ar3: '',
            dt1: 'Atash',
            dt2: '',
            ar1: 'ܐܛܫ',
            es3: '',
            id: '116',
            bedeutung: '',
            es2: 'ܐܛܫ',
            ar2: 'ܐܰܛܰܫ',
            es1: 'ܐܛܫ',
            dt3: ''
        },
        {
            dt1: 'Jesus',
            es3: '',
            ar2: 'ܝܶܫܽܘܥ',
            ar3: '',
            es2: 'ܝܫܘܥ',
            dt3: '',
            id: '546',
            bedeutung: '',
            ar1: 'ܝܫܘܥ',
            dt2: '',
            es1: 'ܝܫܘܥ'
        },
        {
            ar2: 'ܐܰܚܺܝܝܰܐ',
            dt1: 'Ahija',
            es2: 'ܐܚܝܝܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “mein Bruder ist Jahwe”. Im AT begegnen Ahija, Sohn des Ahitub, ein Priester, Nachkomme Elis, und Ahija, Sohn des Schischa, Sekretär des Königs Salomo, ferner ein Prophet und viele andere Personen dieses Namens',
            es3: '',
            es1: 'ܐܚܝܝܐ',
            ar3: '',
            ar1: 'ܐܚܝܝܐ',
            id: '1231',
            dt3: '',
            dt2: ''
        },
        {
            ar1: 'ܦܩܝܕܐ',
            ar3: '',
            dt3: '',
            id: '346',
            ar2: 'ܦܩܺܝܕܳܐ',
            es3: '',
            dt1: 'Fqido',
            es1: 'ܦܩܝܕܐ',
            bedeutung: 'männl., “der Befehlsempfangende”. Fqido (104-114) war Schüler des Apostels Mor Addai und ostsyrischer Bischof von Arbela. Weitere Formen: Pqida',
            dt2: '',
            es2: 'ܦܩܝܕܐ'
        },
        {
            dt3: '',
            ar3: '',
            ar2: 'ܡܰܥܰܢܽܘ',
            es3: '',
            es1: 'ܡܥܢܘ',
            dt1: 'Manu',
            es2: 'ܡܥܢܘ',
            dt2: '',
            id: '1846',
            bedeutung: 'männl., „mein Herr”. Mari/Mariah (“mein Gott ist Mari”) war einer von den heidnischen Aramäern verehrte Gott und ist wohl der Beiname des aramäischen Königs Bar-Hadad III. in den assyrischen Inschriften',
            ar1: 'ܡܥܢܘ'
        },
        {
            es2: 'ܐܣܬܪ',
            bedeutung: 'weibl., arabischen Ursprungs; “verständnisvolle, kluge Frau”; weitere Formen: Fehima',
            ar3: '',
            ar1: 'ܐܣܬܪ',
            dt3: '',
            ar2: 'ܐܶܣܬܶܪ',
            es3: '',
            dt1: 'Esther',
            es1: 'ܐܣܬܪ',
            id: '1508',
            dt2: 'Ester'
        },
        {
            ar1: 'ܢܘܪܐܝܠ',
            ar2: 'ܢܽܘܪܺܐܝܠ',
            es2: 'ܢܘܪܐܝܠ',
            id: '785',
            ar3: '',
            bedeutung: 'männl., “Feuer, Licht Gottes”',
            es1: 'ܢܘܪܐܝܠ',
            dt3: '',
            dt2: '',
            es3: '',
            dt1: 'Nuril'
        },
        {
            es3: '',
            dt2: '',
            es2: 'ܗܢܝ',
            bedeutung: 'männl., weitere Formen: Haphsay, Hafsai, Hafsay',
            ar2: 'ܗܰܢܺܝ',
            id: '1624',
            ar3: '',
            ar1: 'ܗܢܝ',
            es1: 'ܗܢܝ',
            dt3: '',
            dt1: 'Hani'
        },
        {
            dt1: 'Rabuni',
            bedeutung: 'männl., “du bist erhaben, kostbar”; weitere Formen: Romat',
            dt3: '',
            ar2: 'ܪܰܒܽܘܢܺܝ',
            es2: 'ܪܒܘܢܝ',
            es3: '',
            ar1: 'ܪܒܘܢܝ',
            ar3: '',
            id: '2023',
            dt2: '',
            es1: 'ܪܒܘܢܝ'
        },
        {
            es1: 'ܣܝܡܬܐ',
            dt3: '',
            bedeutung: 'weibl., aus dem griechischen “Sophia Weisheit”',
            dt2: '',
            ar2: 'ܣܺܝܡܬܰܐ',
            es2: 'ܣܝܡܬܐ',
            dt1: 'Simta',
            ar1: 'ܣܝܡܬܐ',
            ar3: '',
            es3: '',
            id: '2188'
        },
        {
            id: '1963',
            ar3: '',
            es3: '',
            ar2: 'ܢܺܐܪܒܳܐ',
            ar1: 'ܢܐܪܒܐ',
            es2: 'ܢܐܪܒܐ',
            bedeutung: 'siehe Naaman',
            dt3: '',
            es1: 'ܢܐܪܒܐ',
            dt1: 'Nirbo',
            dt2: ''
        },
        {
            ar3: 'ܚܰܒܺܝܒܳܐ',
            ar1: 'ܚܒܝܒ',
            dt1: 'Habib',
            ar2: 'ܚܰܒܺܝܒ',
            id: '402',
            es1: 'ܚܒܝܒ',
            bedeutung: 'männl., syrischen Ursprungs; “Liebling, Geliebter”; weitere Formen: Habibo',
            dt2: 'Habibo',
            dt3: '',
            es2: 'ܚܒܝܒ',
            es3: 'ܚܒܝܒܐ'
        },
        {
            ar1: 'ܐܠܝܣܝܐ',
            es2: 'ܐܠܝܣܝܐ',
            ar2: 'ܐܰܠܝܣܝܰܐ',
            dt2: 'Alicja',
            dt3: '',
            ar3: '',
            id: '1249',
            es3: '',
            es1: 'ܐܠܝܣܝܐ',
            bedeutung: '',
            dt1: 'Alicia'
        },
        {
            ar3: '',
            es3: '',
            bedeutung: 'männl., “liebt, (er hat) geliebt”',
            dt3: '',
            id: '1230',
            es1: 'ܐܚܒ',
            dt1: 'Aheb',
            ar1: 'ܐܚܒ',
            es2: 'ܐܚܒ',
            ar2: 'ܐܰܚܶܒ',
            dt2: ''
        },
        {
            bedeutung: 'männl., “du bist meine Geschichte, du bist mein Märchen, Leben”',
            ar3: '',
            ar1: 'ܫܠܝܘܬܐ',
            dt1: 'Schalyuta',
            ar2: 'ܫܰܠܝܽܘܬܰܐ',
            id: '2126',
            dt2: '',
            es3: '',
            es2: 'ܫܠܝܘܬܐ',
            dt3: '',
            es1: 'ܫܠܝܘܬܐ'
        },
        {
            dt1: 'Bartolmai',
            es2: 'ܒܪܬܘܠܡܝ',
            dt2: '',
            ar1: 'ܒܪܬܘܠܡܝ',
            ar2: 'ܒܰܪܬܳܘܠܡܰܝ',
            es1: 'ܒܪܬܘܠܡܝ',
            ar3: '',
            dt3: '',
            id: '1372',
            es3: '',
            bedeutung: 'männl., siehe Benjamin'
        },
        {
            ar1: 'ܙܕܝܩܐ',
            ar3: '',
            dt3: '',
            es3: '',
            dt1: 'Zadiqa',
            dt2: '',
            es2: 'ܙܕܝܩܐ',
            ar2: 'ܙܰܕܺܝܩܰܐ',
            id: '2338',
            es1: 'ܙܕܝܩܐ',
            bedeutung: ''
        },
        {
            id: '1899',
            es2: ' ܡܪܝ ܐܝܓܢܐܛܝܘܣ ܙܟܝ ܩܕܡܝܐ',
            ar1: 'ܡܪܝ ܐܝܓܢܐܛܝܘܣ ܙܟܝ ܩܕܡܝܐ',
            dt1: 'Mor Ignatius Zakay qadmoyo',
            ar3: '',
            dt3: '',
            es3: '',
            es1: ' ܡܪܝ ܐܝܓܢܐܛܝܘܣ ܙܟܝ ܩܕܡܝܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs. Im AT ist Masch einer der vier Söhne Arams. Ein aramäischer Stamm, der vielen Orientalisten zufolge im heutigen Turabdin, dem alten Gebirge Masch/Masius gelebt haben soll; demnach wären die heutigen Suryoye/Syrer/Aramäer von Turabdin die Nachfahren dieses aramäischen Stammes. Weitere Formen: Mosch, Mas, Mascha',
            ar2: 'ܡܪܝ ܐܝܓܢܐܛܝܘܣ ܙܟܝ ܩܕܡܝܐ',
            dt2: ''
        },
        {
            ar2: 'ܒܰܪ̱ܬܚܽܘܒܰܐ',
            bedeutung: 'weibl. Form Barhoubo; “Tochter der Liebe”; weitere Formen: Bathhouba',
            dt2: '',
            id: '205',
            es3: '',
            es2: 'ܒܪܬܚܘܒܐ',
            dt3: '',
            dt1: 'Bathhoubo',
            ar3: '',
            es1: 'ܒܪܬܚܘܒܐ',
            ar1: 'ܒܪ̱ܬܚܘܒܐ'
        },
        {
            es3: '',
            ar3: '',
            es2: 'ܢܘܪܫܝܢܐ',
            dt3: '',
            dt1: 'Nurschayno',
            bedeutung: 'männl., “das heißt, also”; weitere Formen: Awkith, Awkit',
            ar1: 'ܢܘܪܫܝܢܐ',
            id: '1979',
            dt2: '',
            es1: 'ܢܘܪܫܝܢܐ',
            ar2: 'ܢܽܘܪܫܰܝܢܳܐ'
        },
        {
            dt1: 'Helena',
            es2: 'ܗܠܢܐ',
            dt3: '',
            bedeutung: 'weibl., siehe Heleni',
            es3: '',
            ar3: '',
            es1: 'ܗܠܢܐ',
            ar2: 'ܗܶܠܶܢܰܐ',
            ar1: 'ܗܠܢܐ',
            dt2: '',
            id: '464'
        },
        {
            es3: '',
            ar3: '',
            id: '827',
            dt2: '',
            ar2: 'ܪܰܒܺܝܢ',
            es1: 'ܪܒܝܢ',
            dt3: '',
            dt1: 'Rabin',
            ar1: 'ܪܒܝܢ',
            es2: 'ܪܒܝܢ',
            bedeutung: 'männl., siehe Rabi und Rabil'
        },
        {
            dt2: 'Ninwa',
            ar3: 'ܢܺܝܢܘܶܐ',
            ar2: 'ܢܺܝܢܘܰܐ',
            es2: 'ܢܝܢܘܐ',
            dt3: 'Ninve',
            bedeutung: 'männl., “Licht, Glanz”',
            id: '1962',
            ar1: 'ܢܝܢܘܐ',
            es1: 'ܢܝܢܘܐ',
            es3: 'ܢܝܢܘܐ',
            dt1: 'Ninva'
        },
        {
            dt2: 'Elimelech',
            es1: 'ܠܠܝܡܠܟܐ',
            dt3: 'Elimelekh',
            es3: '',
            es2: 'ܠܠܝܡܠܟܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott stellt aufrecht”. Weitere Formen: Eljaqim',
            ar3: '',
            dt1: 'Elimalko',
            ar2: 'ܐܶܠܺܝܡܰܠܟܳܐ',
            ar1: 'ܐܠܝܡܠܟܐ',
            id: '1485'
        },
        {
            es3: '',
            id: '1120',
            ar3: '',
            bedeutung: 'männl., “Kind, Sohn, Geborener”; weitere Formen: Yaldo, Jalda, Jaldo',
            es1: 'ܝܠܕܐ',
            ar1: 'ܝܠܕܐ',
            dt1: 'Yalda',
            dt2: '',
            es2: 'ܝܠܕܐ',
            dt3: '',
            ar2: 'ܝܰܠܕܰܐ'
        },
        {
            dt3: '',
            dt2: '',
            ar2: 'ܐܰܟܰܪ',
            bedeutung: 'männl., “pﬂügen, anbauen”',
            es2: 'ܐܟܪ',
            id: '1242',
            ar3: '',
            es3: '',
            dt1: 'Akar',
            es1: 'ܐܟܪ',
            ar1: 'ܐܟܪ'
        },
        {
            es3: '',
            es1: 'ܙܠܓܬ',
            es2: 'ܙܠܓܬ',
            id: '1160',
            dt3: '',
            bedeutung: 'männl., “du bist mein Licht, Strahl, Glanz”',
            ar3: '',
            ar2: 'ܙܰܠܓܰܬ',
            ar1: 'ܙܠܓܬ',
            dt2: '',
            dt1: 'Zalgat'
        },
        {
            ar1: 'ܝܒܢܐ',
            ar2: 'ܝܰܒܢܳܐ',
            es3: '',
            bedeutung: 'männl., “Bussard”; weitere Formen: Jabno, Yabna, Jabna',
            dt2: '',
            dt3: '',
            dt1: 'Yabno',
            id: '1112',
            es2: 'ܝܒܢܐ',
            es1: 'ܝܒܢܐ',
            ar3: ''
        },
        {
            id: '1434',
            es1: 'ܕܠܝܠܬܐ',
            es2: 'ܕܠܝܠܬܐ',
            es3: '',
            ar3: '',
            dt1: 'Dalilta',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “richten”. Im AT ist Dan Sohn Jakobs und der Rahelmagd Bilha',
            ar1: 'ܕܠܝܠܬܐ',
            ar2: 'ܕܰܠܺܝܠܬܰܐ',
            dt3: '',
            dt2: ''
        },
        {
            dt2: 'Esav',
            id: '319',
            ar1: 'ܥܝܣܘ',
            es1: 'ܥܝܣܘ',
            ar3: '',
            es3: '',
            es2: 'ܥܝܣܘ',
            ar2: 'ܥܺܝܣܽܘ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “behaart, struppig”. Im AT ist Esau älterer Sohn Isaaks und der Rebekka und Zwillingsbruder Jakobs. Weitere Formen: Isu, Esav',
            dt1: 'Esau',
            dt3: 'Isu'
        },
        {
            ar2: 'ܫܰܢܬܰܐܠ',
            ar3: 'ܫܰܢܬܰܠ',
            dt1: 'Chantal',
            dt2: '',
            es1: 'ܫܢܬܠ',
            id: '244',
            es3: 'ܫܢܬܠ',
            es2: 'ܫܢܬܠ',
            dt3: '',
            bedeutung: '',
            ar1: 'ܫܢܬܠ'
        },
        {
            ar3: '',
            id: '574',
            ar1: 'ܟܠܡܘ',
            ar2: 'ܟܰܠܰܡܽܘ',
            bedeutung: 'männl., Kalamu war ein aramäischer König von Samal (ca. 840/835 - 815/10 v. Chr.). Weitere Formen: Kilamwa, Kilamuwa',
            dt1: 'Kalamu',
            dt2: '',
            dt3: '',
            es2: 'ܟܠܡܘ',
            es1: 'ܟܠܡܘ',
            es3: ''
        },
        {
            es2: 'ܦܝܪܝܐܢ',
            es1: 'ܦܝܪܝܐܢ',
            dt3: '',
            ar2: 'ܦܺܝܪܝܰܐܢ',
            dt1: 'Firian',
            ar3: 'ܦܶܝܪܝܰܐܢ',
            bedeutung: 'männl., siehe Gabor',
            dt2: '',
            ar1: 'ܦܝܪܝܐܢ',
            es3: 'ܦܝܪܝܐܢ',
            id: '1528'
        },
        {
            ar2: 'ܛܳܘܒܺܝܐܝܶܠ',
            ar3: '',
            dt2: '',
            es1: 'ܛܘܒܝܐܝܠ',
            ar1: 'ܛܘܒܝܐܝܠ',
            id: '2257',
            es2: 'ܛܘܒܝܐܝܠ',
            dt3: '',
            es3: '',
            dt1: 'Tobiel',
            bedeutung: 'weibl., “die Korrekte, Fehlerfreie, Ordentliche”'
        },
        {
            id: '2311',
            ar1: 'ܝܥܩܘܒ ܒܟܝܪ',
            bedeutung: 'männl., “ehren, hochachten, bewundern, huldigen”; weitere Formen: Jaqar',
            es3: '',
            dt2: '',
            ar3: '',
            es2: 'ܝܥܩܘܒ ܒܟܝܪ',
            ar2: 'ܝܰܥܩܺܘܒ ܒܰܟܺܝܪ',
            dt1: 'Yakub Bakir',
            dt3: '',
            es1: 'ܝܥܩܘܒ ܒܟܝܪ'
        },
        {
            ar2: 'ܐܰܒܺܝܡܰܠܟܳܐ',
            es2: 'ܐܒܝܡܠܟܐ',
            id: '1197',
            ar1: 'ܐܒܝܡܠܟܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs;  “mein Vater ist König”. Im AT begegnen Abimalko, König von Gerar zwischen Palästina und Ägypten (um 2000 v.Chr.), und Abimalko, Sohn Gideons (ca. 13. Jh. v.Chr.). Weitere Formen: Abimelek, Abimelech, Abimalak',
            dt2: '',
            ar3: '',
            dt1: 'Abimalko',
            es3: '',
            es1: 'ܐܒܝܡܠܟܐ',
            dt3: ''
        },
        {
            bedeutung: 'männl., “Greis, alter Mann, Opa, Senior”',
            dt3: '',
            es3: '',
            es2: 'ܣܒܐ',
            es1: 'ܣܒܐ',
            ar3: '',
            id: '1001',
            dt1: 'Sobo',
            ar2: 'ܣܳܒܳܐ',
            dt2: '',
            ar1: 'ܣܒܐ'
        },
        {
            dt1: 'Abdelmassih',
            bedeutung: '',
            es1: 'ܥܒܕܠܡܣܝܚ',
            ar2: 'ܥܰܒܕܶܠܡܰܣܺܝܚ',
            ar3: '',
            dt3: '',
            id: '8',
            es3: '',
            es2: 'ܥܒܕܠܡܣܝܚ',
            dt2: 'Abdelmassieh',
            ar1: 'ܥܒܕܠܡܣܝܚ'
        },
        {
            es2: 'ܪܝܚܐ',
            dt2: '',
            dt1: 'Recha',
            id: '847',
            es1: 'ܪܝܚܐ',
            ar1: 'ܪܝܚܐ',
            bedeutung: 'weibl., siehe Rihan',
            ar3: '',
            es3: '',
            dt3: '',
            ar2: 'ܪܺܝܚܰܐ'
        },
        {
            id: '825',
            bedeutung: 'weibl., aus dem Aramäischen übernommener Vorname; “Frühling”; weitere Formen: Rabea',
            es2: 'ܪܒܝܥܐ',
            dt3: '',
            dt1: 'Rabia',
            es3: '',
            ar1: 'ܪܒܝܥܐ',
            dt2: '',
            es1: 'ܪܒܝܥܐ',
            ar3: '',
            ar2: 'ܪܰܒܺܝܥܰܐ'
        },
        {
            es2: 'ܣܦܪ',
            dt2: '',
            ar2: 'ܣܰܦܰܪ',
            dt1: 'Safar',
            id: '885',
            es1: 'ܣܦܪ',
            ar1: 'ܣܦܪ',
            dt3: '',
            es3: '',
            bedeutung: 'männl., “Friseur, Schneider”; weitere Formen: Saforo',
            ar3: ''
        },
        {
            id: '870',
            bedeutung: 'männl., “Jesus ist erhaben”',
            ar1: 'ܪܡܝܫܘܥ',
            es2: 'ܪܡܝܫܘܥ',
            dt1: 'Romjeschu',
            es1: 'ܪܡܝܫܘܥ',
            ar2: 'ܪܳܡܝܶܫܽܘܥ',
            es3: '',
            dt2: '',
            ar3: '',
            dt3: ''
        },
        {
            ar1: 'ܕܐܢ',
            dt3: '',
            ar2: 'ܕܰܐܢ',
            dt1: 'Dan',
            es2: 'ܕܐܢ',
            dt2: 'Don',
            es3: 'ܕܐܢ',
            es1: 'ܕܐܢ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott ist mein Richter”. Im AT ist Daniel ein Prophet (7./6. v. Chr.)',
            ar3: 'ܕܳܐܢ',
            id: '1436'
        },
        {
            es1: 'ܝܗܘܫܥ',
            es3: 'ܝܗܘܫܥ',
            ar2: 'ܝܶܗܽܘܫܳܥ',
            dt1: 'Jehuschuah',
            dt3: '',
            id: '1719',
            es2: 'ܝܗܘܫܥ',
            bedeutung: 'männl., “Jesus ist mein Onkel”',
            ar1: 'ܝܗܘܫܥ',
            dt2: '',
            ar3: 'ܝܶܗܽܘܫܰܥ'
        },
        {
            bedeutung: 'männl., syrischen Ursprungs; “Gerechter, der Gerechte, ein gerechter Mann [Heiliger]”',
            dt1: 'Katja',
            ar3: '',
            dt3: '',
            ar1: 'ܟܬܝܐ',
            es1: 'ܟܬܝܐ',
            id: '1776',
            ar2: 'ܟܰܬܝܰܐ',
            es2: 'ܟܬܝܐ',
            es3: '',
            dt2: ''
        },
        {
            es2: 'ܛܘܪܐ',
            dt1: 'Turo',
            es3: '',
            ar3: '',
            ar2: 'ܛܽܘܪܳܐ',
            bedeutung: 'männl., “Berg”',
            ar1: 'ܛܘܪܐ',
            es1: 'ܛܘܪܐ',
            dt2: '',
            dt3: '',
            id: '1082'
        },
        {
            dt1: 'Barkino',
            dt2: '',
            ar2: 'ܒܰܪܟܺܐܢܳܐ',
            id: '1350',
            ar1: 'ܒܪܟܐܢܐ',
            bedeutung: 'männl., “Sohn, Freund des Herzens”',
            es1: 'ܒܪܟܐܢܐ',
            dt3: '',
            es3: '',
            ar3: '',
            es2: 'ܒܪܟܐܢܐ'
        },
        {
            dt3: '',
            ar1: 'ܣܠܝܡܐܢ',
            ar3: '',
            bedeutung: '',
            es1: 'ܣܠܝܡܐܢ',
            dt2: 'Sleman',
            id: '1013',
            ar2: 'ܣܠܶܝܡܐܰܢ',
            es2: 'ܣܠܝܡܐܢ',
            es3: '',
            dt1: 'Suleyman'
        },
        {
            ar2: 'ܢܽܘܪܺܝ',
            ar1: 'ܢܘܪܝ',
            id: '784',
            bedeutung: 'männl., “du bist mein Feuer, Licht”',
            dt1: 'Nuri',
            es1: 'ܢܘܪܝ',
            dt3: '',
            es3: '',
            es2: 'ܢܘܪܝ',
            ar3: '',
            dt2: ''
        },
        {
            ar1: 'ܫܠܐܬܝܠ',
            es2: 'ܫܠܐܬܝܠ',
            dt2: 'Schelatiel',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott hat gehört“. Im AT ist Schemun Sohn Jakobs; Im NT ist Schemun (Petros) ein Jünger Jesu und Vorsprecher der 12 Apostel. Schemun gilt als der Gründer der syrisch-orthodoxen Kirche von Antiochien. Mor Schemun d-zayte (†734); Festtag: 1. Juni; Mor Schemun destune “des Styliten” (†459). Weitere Formen: Simeon, Simon, Schimon, Schamun; bei den Aramäern sehr beliebt',
            ar3: '',
            id: '2141',
            es3: '',
            dt1: 'Schealtiel',
            es1: 'ܫܠܐܬܝܠ',
            dt3: '',
            ar2: 'ܫܶܠܰܐܬܺܝܶܠ'
        },
        {
            es3: 'ܒܫܪܐ',
            id: '1405',
            es1: 'ܒܫܪܝܢ',
            ar2: 'ܒܶܫܪܺܝܢ',
            dt1: 'Beschrin',
            bedeutung: 'weibl., Bethzabdai bezeichnet sowohl eine Stadt als auch eine Landschaft in Aram Beth-Nahrin (= Mesopotamien). Weitere Formen: Bethzabday',
            dt3: '',
            ar3: 'ܒܶܫܪܰܐ',
            es2: 'ܒܫܪܝܢ',
            ar1: 'ܒܫܪܝܢ',
            dt2: 'Beschra'
        },
        {
            id: '2155',
            ar2: 'ܫܟ݂ܺܝܢܬܰܐ',
            dt2: '',
            es3: '',
            ar1: 'ܫܟ݂ܝܢܬܐ',
            dt3: '',
            es2: 'ܫܟ݂ܝܢܬܐ',
            dt1: 'Schkinta',
            bedeutung: 'männl., siehe Schaol',
            ar3: '',
            es1: 'ܫܟ݂ܝܢܬܐ'
        },
        {
            ar3: '',
            ar2: 'ܝܰܗܠܳܐ',
            bedeutung: 'männl., “Gemeinschaft, Verbundenheit, Gruppe, Menge”; weitere Formen: Yahla, Jahlo, Jahla',
            id: '1117',
            dt2: '',
            dt3: '',
            ar1: 'ܝܗܠܐ',
            es1: 'ܝܗܠܐ',
            dt1: 'Yahlo',
            es3: '',
            es2: 'ܝܗܠܐ'
        },
        {
            ar3: '',
            es2: 'ܨܒܬܐ',
            ar2: 'ܨܶܒܬܳܐ',
            ar1: 'ܨܒܬܐ',
            dt3: '',
            id: '2174',
            es1: 'ܨܒܬܐ',
            bedeutung: 'weibl., “reine, nette [Frau]”',
            dt2: '',
            es3: '',
            dt1: 'Sebto'
        },
        {
            es1: 'ܐܫܥܝܐ',
            id: '1505',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Usprungs; “Hilfe”. Im AT ﬁIhrt der Priester Ezra die Juden aus der babylonischen Gefangenschaft. Weitere Formen: Esra',
            ar1: 'ܐܫܥܝܐ',
            dt3: '',
            ar3: '',
            es2: 'ܐܫܥܝܐ',
            es3: '',
            dt2: '',
            dt1: 'Eschahjo',
            ar2: 'ܐܶܫܰܥܝܳܐ'
        },
        {
            es3: '',
            id: '166',
            ar1: 'ܒܪܚܘܒܐ',
            dt1: 'Barhubo',
            dt2: '',
            ar3: '',
            es2: 'ܒܪܚܘܒܐ',
            dt3: '',
            es1: 'ܒܪܚܘܒܐ',
            ar2: 'ܒܰܪܚܽܘܒܳܐ',
            bedeutung: 'männl., “Sohn der Liebe”'
        },
        {
            ar3: 'ܚܰܙܽܘ',
            es3: 'ܚܙܘ',
            id: '452',
            dt3: '',
            bedeutung: 'männl. aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs. Haso ist nach dem AT Bruder Keseds, Kemuels und Sohn Nahors. Weitere Formen: Hazu',
            dt1: 'Haso',
            dt2: '',
            es1: 'ܚܙܐ',
            es2: 'ܚܙܐ',
            ar1: 'ܚܙܐ',
            ar2: 'ܚܰܙܳܐ'
        },
        {
            es3: 'ܝܩܝܢ',
            dt3: '',
            id: '1118',
            dt1: 'Yakin',
            ar1: 'ܝܟܝܢ',
            dt2: '',
            es1: 'ܝܟܝܢ',
            bedeutung: 'männl., aramäisch-chaldäischer Stamm/Staat; weitere Formen: Yaqin, Iaqin, Jakin',
            es2: 'ܝܟܝܢ',
            ar3: 'ܝܰܩܺܝܢ',
            ar2: 'ܝܰܟܺܝܢ'
        },
        {
            ar2: 'ܣܰܒܪܺܝ',
            es2: 'ܣܒܪܝ',
            dt1: 'Sabri',
            dt3: '',
            id: '880',
            ar1: 'ܣܒܪܝ',
            ar3: 'ܨܰܒܪܺܝ',
            dt2: '',
            es1: 'ܣܒܪܝ',
            bedeutung: 'männl., “meine Hoffnung”',
            es3: 'ܨܒܪܝ'
        },
        {
            id: '746',
            dt1: 'Naqduta',
            ar2: 'ܢܰܩܕܽܘܬܰܐ',
            es3: '',
            es2: 'ܢܩܕܘܬܐ',
            ar3: '',
            dt3: '',
            ar1: 'ܢܩܕܘܬܐ',
            dt2: '',
            bedeutung: 'weibl., “Reinheit, Reinlichkeit, Frische, Sauberkeit, Anständigkeit, Ehrlichkeit”',
            es1: 'ܢܩܕܘܬܐ'
        },
        {
            dt1: 'Michael',
            es3: 'ܡܝܟ݂ܐܝܠ',
            dt2: '',
            dt3: '',
            id: '700',
            ar3: 'ܡܺܝܟ݂ܳܐܝܶܠ',
            ar1: 'ܡܝܟܐܝܠ',
            ar2: 'ܡܺܝܟ݂ܰܐܝܶܠ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “wer ist wie Gott?”. Michael gilt für die Christen als Erzengel und Engelfürst. Seinen Namen trägt Patriarch Michael der Große von Antiochien (†1 199). Weitere Formen: Michoel, Mikael',
            es2: 'ܡܝܟܐܝܠ',
            es1: 'ܡܝܟܐܝܠ'
        },
        {
            ar1: 'ܓܒܪ',
            es1: 'ܓܒܪ',
            id: '350',
            ar3: 'ܓܰܒܳܪ',
            es2: 'ܓܒܪ',
            dt1: 'Gabar',
            dt3: '',
            dt2: '',
            es3: 'ܓܒܪ',
            ar2: 'ܓܰܒܰܪ',
            bedeutung: 'männl., siehe Gabor'
        },
        {
            id: '1965',
            es3: '',
            es1: 'ܢܝܫܐ',
            ar2: 'ܢܺܝܫܳܐ',
            dt1: 'Nischo',
            ar3: '',
            dt2: '',
            bedeutung: 'männl., “Hadad ist Feuer, Licht“. Nuradad war ein aramäischer König von Turabdin (9. Jh. v.Chr.). Weitere Formen: Nurhadad',
            dt3: '',
            es2: 'ܢܝܫܐ',
            ar1: 'ܢܝܫܐ'
        },
        {
            ar1: 'ܓܪܝܓܘܪܝܘܣ',
            es3: '',
            es1: 'ܓܪܝܓܘܪܝܘܣ',
            bedeutung: 'weibl., “Flamme”; weitere Formen: Gidutha',
            dt1: 'Georgius',
            dt3: '',
            id: '1563',
            es2: 'ܓܪܝܓܘܪܝܘܣ',
            ar2: 'ܓܪܝܓܘܪܝܘܣ',
            ar3: '',
            dt2: ''
        },
        {
            es2: 'ܚܢܢܝܐ',
            dt3: '',
            dt1: 'Hananja',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe hat Gnade gewirkt”. Im NT ist Hanon/Hannas der Schwiegervater des Kajaphas zu Beginn des 1. Jh.; Hanon oder Hnana (510-544) war ostsyrischer Metropolit von Arbela. Weitere Formen: Hannas',
            es3: '',
            ar3: '',
            dt2: 'Hananayel',
            id: '1621',
            es1: 'ܚܢܢܝܐ',
            ar2: 'ܚܰܢܰܢܝܰܐ',
            ar1: 'ܚܢܢܝܐ'
        },
        {
            es1: 'ܒܣܝܒܪܝܢܐ',
            bedeutung: 'weibl. Form zu Basim',
            ar2: 'ܒܶܝܬܣܒܺܝܪܺܝܢܰܐ',
            ar1: 'ܒܣܝܒܪܝܢܐ',
            dt1: 'Basibrina',
            es3: '',
            dt3: '',
            es2: 'ܒܣܝܒܪܝܢܐ',
            dt2: '',
            id: '1376',
            ar3: ''
        },
        {
            dt1: 'Sabarjeschu',
            ar3: '',
            ar1: 'ܣܒܪܝܫܘܥ',
            dt2: '',
            es2: 'ܣܒܪܝܫܘܥ',
            es1: 'ܣܒܪܝܫܘܥ',
            ar2: 'ܣܰܒܰܪܝܶܫܽܘܥ',
            dt3: '',
            es3: '',
            bedeutung: 'männl., “Friseur, Schneider”; weitere Formen: Saforo',
            id: '2068'
        },
        {
            es1: 'ܒܪܘܟܬܐ',
            ar1: 'ܒܪܘܟܬܐ',
            id: '237',
            dt2: '',
            ar2: 'ܒܪܳܘܟܬܰܐ',
            es3: '',
            ar3: '',
            dt1: 'Brukta',
            es2: 'ܒܪܘܟܬܐ',
            bedeutung: '',
            dt3: ''
        },
        {
            ar1: 'ܐܒܠܚܕ',
            dt3: '',
            es2: 'ܐܒܠܚܕ',
            es1: 'ܐܒܠܚܕ',
            bedeutung: 'männl., siehe Abdullahad',
            dt2: '',
            es3: '',
            ar3: '',
            dt1: 'Ablahad',
            ar2: 'ܐܰܒܠܰܚܰܕ',
            id: '1201'
        },
        {
            ar3: 'ܪܰܟܽܘܒ',
            dt2: '',
            ar1: 'ܪܟܒ',
            es1: 'ܪܟܒ',
            dt1: 'Rekob',
            es2: 'ܪܟܒ',
            dt3: '',
            ar2: 'ܪܶܟܳܒ',
            es3: 'ܪܟܘܒ',
            id: '851',
            bedeutung: 'männl., “Reiter”; aramäischer König von Samal (8. Jh. v. Chr.). Weitere Formen: Rakub, Rakobo'
        },
        {
            bedeutung: 'männl., “mein Name ist groß, erhaben” ',
            es1: 'ܪܒܫܡ',
            dt2: '',
            ar2: 'ܪܰܒܫܶܡ',
            dt1: 'Rabschem',
            dt3: '',
            ar3: '',
            es2: 'ܪܒܫܡ',
            id: '829',
            es3: '',
            ar1: 'ܪܒܫܡ'
        },
        {
            dt1: 'Kawkbo',
            ar2: 'ܟܰܘܟܒܳܐ',
            es1: 'ܟܘܟܒܐ',
            es2: 'ܟܘܟܒܐ',
            id: '1777',
            ar3: '',
            ar1: 'ܟܘܟܒܐ',
            dt3: '',
            es3: '',
            bedeutung: 'männl., “Harfe, Leier, Zither”',
            dt2: ''
        },
        {
            dt2: '',
            ar3: 'ܦܺܝܠܺܝܦܽܘܣ',
            dt1: 'Phillippos',
            id: '808',
            es1: 'ܦܝܠܝܦܘܣ',
            es2: 'ܦܝܠܝܦܘܣ',
            bedeutung: 'männl., aus dem Griechischen übernommener Vorname; “philos“: Freund und ”hippos“: Pferd, “Pferdeliebhaber”. Im NT ist Philippos einer der 12 Jünger Jesu Christi. Weitere Formen: Filippos, Philippus',
            dt3: '',
            ar1: 'ܦܝܠܝܦܘܣ',
            ar2: 'ܦܺܝܠܺܝܦܳܘܣ',
            es3: 'ܦܝܠܝܦܘܣ'
        },
        {
            ar1: 'ܐܘܪܝܬܐ',
            es1: 'ܐܘܪܝܬܐ',
            id: '1093',
            es2: 'ܐܘܪܝܬܐ',
            es3: '',
            ar3: '',
            bedeutung: 'weibl., “Garten”; weitere Formen: Uritha',
            dt3: '',
            dt1: 'Urita',
            ar2: 'ܐܽܘܪܺܝܬܰܐ',
            dt2: ''
        },
        {
            dt2: '',
            id: '351',
            es1: 'ܓܒܝ',
            dt3: '',
            bedeutung: 'männl., “Auserwählter”; weitere Formen: Gabai, Gabbai',
            ar1: 'ܓܒܝ',
            es2: 'ܓܒܝ',
            es3: 'ܓܐܒܝ',
            dt1: 'Gabay',
            ar3: 'ܓܰܐܒܰܝ',
            ar2: 'ܓܰܒܰܝ'
        },
        {
            dt2: '',
            es3: '',
            id: '1241',
            es2: 'ܐܚܘܢܝ',
            ar1: 'ܐܚܘܢܝ',
            ar2: 'ܐܰܚܽܘܢܺܝ',
            ar3: '',
            bedeutung: 'männl., “mein Bruder”',
            dt1: 'Ahuni',
            es1: 'ܐܚܘܢܝ',
            dt3: ''
        },
        {
            es1: 'ܢܐܬܢ',
            dt2: 'Nathan',
            dt1: 'Natan',
            dt3: '',
            ar2: 'ܢܰܐܬܰܢ',
            ar1: 'ܢܐܬܢ',
            es2: 'ܢܐܬܢ',
            es3: '',
            ar3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe/Gott hat gegeben”. Im AT ist Nathan ein Prophet zur Zeit des Königs David. Weitere Formen: Natan, Nathanael, Natanael, Jonathan, Jonatan, Noton, Nothon, Jonothon',
            id: '753'
        },
        {
            dt3: '',
            dt2: '',
            es2: 'ܒܪܚܠܐ',
            dt1: 'Barholo',
            es3: '',
            ar2: 'ܒܰܪܚܳܠܳܐ',
            es1: 'ܒܪܚܠܐ',
            bedeutung: 'männl., “Sohn des Onkels”',
            ar1: 'ܒܪܚܠܐ',
            ar3: '',
            id: '165'
        },
        {
            ar1: 'ܚܝܐ',
            ar2: 'ܚܰܝܰܐ',
            es3: '',
            es1: 'ܚܝܐ',
            dt2: '',
            dt1: 'Haja',
            es2: 'ܚܝܐ',
            bedeutung: 'männl., “das Leben”. Haja(n) war ein König der Aramäer von Samal-Jadi (ab ca. 870/860 v.Chr.). Weitere Formen: Hajo, Haya, Hayo, Hayya',
            id: '424',
            ar3: '',
            dt3: ''
        },
        {
            dt2: '',
            id: '1331',
            es2: 'ܒܢܝܒܐܠ',
            dt3: '',
            bedeutung: 'männl., “Sohn des Hadad”. Bar Hadad (I.-III), aramäische Könige von Aram-Damaskus (9. Jh. v.Chr.);weitere Formen: Barhadad, Barhodod',
            es1: 'ܒܢܝܒܐܠ',
            ar3: '',
            dt1: 'Banibal',
            ar2: 'ܒܰܢܺܝܒܰܐܠ',
            es3: '',
            ar1: 'ܒܢܝܒܐܠ'
        },
        {
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Trennung”. Im AT ist Peleg ein Nachkomme Sems, Sohn Ebers und Ahnherr Abrahams; im NT ist Peleg ein Ahnherr Jesu. Weitere Formen: Polog',
            es1: 'ܦܠܓ',
            id: '805',
            dt1: 'Peleg',
            ar1: 'ܦܠܓ',
            ar2: 'ܦܶܠܶܓ',
            dt3: '',
            es3: '',
            es2: 'ܦܠܓ',
            ar3: '',
            dt2: ''
        },
        {
            es1: 'ܗܕܝ',
            ar3: '',
            ar1: 'ܗܕܝ',
            dt2: '',
            id: '416',
            ar2: 'ܗܰܕܺܝ',
            dt3: '',
            dt1: 'Hadi',
            es3: '',
            es2: 'ܗܕܝ',
            bedeutung: 'männl., syrischen Ursprungs; “leiten, führen, wegweisen, unterweisen”'
        },
        {
            ar1: 'ܛܠܢܝܬܐ',
            id: '2238',
            ar3: '',
            dt1: 'Telonita',
            dt3: '',
            es1: 'ܛܠܢܝܬܐ',
            es3: '',
            ar2: 'ܛܶܠܳܢܺܝܬܰܐ',
            bedeutung: 'weibl., “Welt, Erde, Universum”',
            dt2: '',
            es2: 'ܛܠܢܝܬܐ'
        },
        {
            es1: 'ܓܠܦܢܐ',
            es3: '',
            dt1: 'Gelfono',
            dt3: '',
            ar2: 'ܓܶܠܦܳܢܳܐ',
            id: '372',
            ar3: '',
            es2: 'ܓܠܦܢܐ',
            dt2: '',
            ar1: 'ܓܠܦܢܐ',
            bedeutung: 'männl., “Flügel”, “großer Adler”'
        },
        {
            ar3: '',
            dt2: '',
            id: '1388',
            es2: 'ܒܪܬܡܝܐ',
            bedeutung: 'weibl. Form zu Barnahro; “Tochter des Flusses”; weitere Formen: Batnahra',
            dt3: '',
            es3: '',
            ar2: 'ܒܰܪ̱ܬܡܰܝܳܐ',
            es1: 'ܒܪܬܡܝܐ',
            dt1: 'Bathmayo',
            ar1: 'ܒܪ̱ܬܡܝܐ'
        },
        {
            dt1: 'Safruta',
            bedeutung: 'weibl., “Gelehrsamkeit, Gelehrtheit, Wissenschaft”; weitere Formen: Sofruta',
            ar2: 'ܣܰܦܪܽܘܬܰܐ',
            es3: '',
            dt2: '',
            ar1: 'ܣܦܪܘܬܐ',
            id: '887',
            es2: 'ܣܦܪܘܬܐ',
            es1: 'ܣܦܪܘܬܐ',
            dt3: '',
            ar3: ''
        },
        {
            id: '137',
            ar3: '',
            es1: 'ܥܙܝܪܐ',
            dt3: '',
            dt2: '',
            bedeutung: 'männl., “Verwickler, Verweber”; König von Jadi (8.Jh. v.Chr.); weitere Formen: Azira',
            ar2: 'ܥܰܙܺܝܪܳܐ',
            dt1: 'Aziro',
            es2: 'ܥܙܝܪܐ',
            es3: '',
            ar1: 'ܥܙܝܪܐ'
        },
        {
            dt3: '',
            ar1: 'ܥܡܝܫܕܝ',
            es3: '',
            id: '1259',
            bedeutung: 'männl., ein aramäischer Stamm, der im 12. Jh. v. Chr. am Oberlauf des Jabbok auﬁauchte und einen Staat gründete',
            dt1: 'Ammischaddai',
            es2: 'ܥܡܝܫܕܝ',
            dt2: '',
            es1: 'ܥܡܝܫܕܝ',
            ar3: '',
            ar2: 'ܥܰܡܺܝܫܰܕܰܝ'
        },
        {
            dt3: '',
            es3: '',
            es1: 'ܡܪܒܝܬܐ',
            ar1: 'ܡܪܒܝܬܐ',
            es2: 'ܡܪܒܝܬܐ',
            ar3: '',
            bedeutung: 'weibl., syrischen Ursprungs; “Wachstum, Wuchs, Zuwachs, Entwicklung”',
            dt2: '',
            id: '662',
            dt1: 'Marbita',
            ar2: 'ܡܰܪܒܺܝܬܰܐ'
        },
        {
            es1: 'ܝܘܪܡ',
            id: '1747',
            ar1: 'ܝܘܪܡ',
            es3: '',
            ar3: '',
            es2: 'ܝܘܪܡ',
            dt1: 'Joram',
            ar2: 'ܝܽܘܪܳܡ',
            bedeutung: 'männl., aus dem Lateinischen übernommener Vorname; “der aus dem Geschlecht der Julier”; ein weiterer Beiname der syrischen Bischöfe. Weitere Formen: Yulius, Julios, Julian',
            dt2: '',
            dt3: ''
        },
        {
            ar1: 'ܓܒܪܐ',
            es3: '',
            ar2: 'ܓܰܒܪܳܐ',
            bedeutung: 'männl., aus dem Griechischen übernommener Vorname; “der Landsmann/Landwirt”. Mor Gewargis gilt in der Geschichte als Drachentöter und Schutzpatron der Waffenschmiede, Krieger und Landleute, einer der 14 Nothelfer. Weitere Formen: Gewargi, Georg, George, Corc, Circis',
            id: '1558',
            ar3: '',
            dt2: 'Gabro',
            dt1: 'Gebro',
            es2: 'ܓܒܪܐ',
            dt3: '',
            es1: 'ܓܒܪܐ'
        },
        {
            es1: 'ܛܝܛܘܣ',
            es3: 'ܛܝܛܘܣ',
            dt2: '',
            ar1: 'ܛܝܛܘܣ',
            dt3: '',
            bedeutung: 'männl., aus dem lateinischen “titulusi Ruhm, Ansehen, Verdienst”; Titus, ein Heidenchrist aus dem syrischen Antiochia am Orontes, gilt im NT als ein Mitarbeiter des Apostels Paulus. Paulus adressierte an ihn einen neutestamentl. Brief, den “Titusbrief”',
            dt1: 'Titus',
            id: '1062',
            es2: 'ܛܝܛܘܣ',
            ar3: 'ܛܺܝܛܳܘܣ',
            ar2: 'ܛܺܝܛܽܘܣ'
        },
        {
            dt1: 'Said',
            es1: 'ܣܥܝܕ',
            id: '2084',
            ar1: 'ܣܥܝܕ',
            es2: 'ܣܥܝܕ',
            es3: '',
            ar2: 'ܣܰܥܺܝܕ',
            bedeutung: 'männl., “der Blinde”. König «von Hatra (192 n.Chr.). Weitere Formen: Samjo',
            ar3: '',
            dt2: '',
            dt3: ''
        },
        {
            ar1: 'ܚܠܦܝ',
            dt2: '',
            es2: 'ܚܠܦܝ',
            dt3: '',
            es1: 'ܚܠܦܝ',
            es3: '',
            ar3: '',
            id: '426',
            ar2: 'ܚܰܠܦܰܝ',
            dt1: 'Halfai',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott hat ersetzt”. Im NT begegnet Halfai als Vater des Apostels Jakobus sowie als Vater des Apostels Levi oder Matthäus. Weitere Formen: Halphai, Halfay, Alphäus, Alfaus'
        },
        {
            es3: '',
            ar3: '',
            ar1: 'ܡܪܝܢܟܐ',
            es1: 'ܡܪܝܢܟܐ',
            id: '672',
            ar2: 'ܡܰܪܺܝܢܟܳܐ               ',
            dt3: '',
            dt1: 'Marinko',
            dt2: '',
            es2: 'ܡܪܝܢܟܐ',
            bedeutung: ''
        },
        {
            es3: '',
            bedeutung: 'männl., “schön, schöner Mann”. Im AT ist Schaﬁr eine Ortschaft',
            ar1: 'ܣܪܐ',
            es1: 'ܣܪܐ',
            id: '2104',
            ar2: 'ܣܰܪܳܐ',
            ar3: '',
            es2: 'ܣܪܐ',
            dt1: 'Saro',
            dt2: '',
            dt3: ''
        },
        {
            dt2: '',
            dt3: '',
            id: '2040',
            dt1: 'Regta',
            ar2: 'ܪܶܓܬܰܐ',
            ar1: 'ܪܓܬܐ',
            es2: 'ܪܓܬܐ',
            ar3: '',
            es3: '',
            es1: 'ܪܓܬܐ',
            bedeutung: 'männl., “der Geliebte, Liebling”. Rhimo (430-450) war ostsyrischer Metropolit von Arbela. Weitere Formen: Rhima'
        },
        {
            es2: 'ܓܕ',
            es1: 'ܓܕ',
            ar3: 'ܓܰܕܳܐ',
            ar2: 'ܓܰܕ',
            es3: 'ܓܕܐ',
            dt1: 'Gad',
            dt2: '',
            ar1: 'ܓܕ',
            id: '1546',
            dt3: '',
            bedeutung: 'männl., “herrlich, prächtig, schön, rein, nett”'
        },
        {
            ar3: '',
            bedeutung: 'männl., im NT ist Timai oder Timäus ein Blinder, den Jesus geheilt hat.',
            dt2: '',
            ar1: 'ܬܐܘܕܘܣܝܘܣ',
            es1: 'ܬܐܘܕܘܣܝܘܣ',
            dt1: 'Theodosius',
            es2: 'ܬܐܘܕܘܣܝܘܣ',
            id: '2241',
            dt3: '',
            ar2: 'ܬܶܐܘܳܕܳܘܣܺܝܳܘܣ',
            es3: ''
        },
        {
            id: '82',
            ar2: 'ܥܰܡܳܘܢܝܰܐ',
            ar1: 'ܥܡܘܢܝܐ',
            dt2: '',
            ar3: '',
            bedeutung: 'männl., ein aramäischer Stamm, der im 12. Jh. v. Chr. am Oberlauf des Jabbok auﬁauchte und einen Staat gründete',
            dt3: '',
            es2: 'ܥܡܘܢܝܐ',
            es3: '',
            dt1: 'Ammonja',
            es1: 'ܥܡܘܢܝܐ'
        },
        {
            ar3: '',
            dt1: 'Rohmo',
            es1: 'ܪܚܡܐ',
            es3: '',
            id: '2058',
            es2: 'ܪܚܡܐ',
            ar1: 'ܪܚܡܐ',
            ar2: 'ܪܳܚܡܳܐ',
            bedeutung: 'männl., “verkünden”',
            dt2: '',
            dt3: ''
        },
        {
            ar3: 'ܝܽܘܚܰܢܽܘܢ',
            ar1: 'ܝܘܚܢܢ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe ist gerecht“. Weitere Formen: Juzodok, Juzodoq, Juzadak, Jusodoq',
            es1: 'ܝܘܚܢܢ',
            id: '1742',
            ar2: 'ܝܽܘܚܰܢܳܢ',
            dt2: 'Yohanen',
            dt3: 'Johanen',
            es3: 'ܝܘܚܢܘܢ',
            dt1: 'Johannes',
            es2: 'ܝܘܚܢܢ'
        },
        {
            dt1: 'Yaqirta',
            es3: '',
            ar1: 'ܝܩܝܪܬܐ',
            dt3: '',
            es2: 'ܝܩܝܪܬܐ',
            ar3: '',
            bedeutung: 'weibl., “die Kostbare, die Ehrwürdige, Würdevolle, Geliebte”; weitere Formen: Jaqirta',
            es1: 'ܝܩܝܪܬܐ',
            ar2: 'ܝܰܩܺܝܪܬܰܐ',
            dt2: '',
            id: '1129'
        },
        {
            es3: '',
            dt3: 'Quryakos',
            dt1: 'Kuriakos',
            dt2: 'Kuryakos',
            ar2: 'ܩܽܘܪܝܰܩܳܘܣ',
            ar1: 'ܩܘܪܝܩܘܣ',
            es1: 'ܩܘܪܝܩܘܣ',
            es2: 'ܩܘܪܝܩܘܣ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs. Im AT ist Lamech Sohn des Metuschael und Nachkomme Kains; nach dem NT ist Lamech ein Ahnherr Jesu. Weitere Formen: Lamek',
            ar3: '',
            id: '1797'
        },
        {
            bedeutung: 'weibl., “Beschluss, Befehl, Ziel”',
            ar2: 'ܕܳܬܰܐ',
            id: '283',
            ar1: 'ܕܬܐ',
            dt2: '',
            dt3: '',
            es1: 'ܕܬܐ',
            dt1: 'Dota',
            es3: '',
            ar3: '',
            es2: 'ܕܬܐ'
        },
        {
            es1: 'ܡܐܡܪܐ',
            dt1: 'Mimro',
            bedeutung: '',
            dt2: '',
            es3: '',
            es2: 'ܡܐܡܪܐ',
            ar2: 'ܡܺܐܡܪܳܐ',
            dt3: '',
            ar3: '',
            id: '1893',
            ar1: 'ܡܐܡܪܐ'
        },
        {
            ar3: '',
            id: '639',
            ar1: 'ܠܘܠܝܬܐ',
            dt3: '',
            bedeutung: 'weibl., “Speerspitze, Lanzenspitze”',
            es3: '',
            dt1: 'Lulita',
            dt2: '',
            es1: 'ܠܘܠܝܬܐ',
            ar2: 'ܠܽܘܠܺܝܬܰܐ',
            es2: 'ܠܘܠܝܬܐ'
        },
        {
            es2: 'ܒܒܬܐ',
            dt3: '',
            es1: 'ܒܒܬܐ',
            ar2: 'ܒܳܒܬܰܐ',
            dt2: '',
            ar3: '',
            bedeutung: 'männl., “Gesegneter”; weitere Formen: Brikha, Bricho',
            ar1: 'ܒܒܬܐ',
            es3: '',
            id: '1412',
            dt1: 'Bobta'
        },
        {
            es2: 'ܕܪܝܝܘܣ',
            dt1: 'Darijos',
            es1: 'ܕܪܝܝܘܣ',
            ar2: 'ܕܰܪܺܝܝܳܘܣ',
            dt3: '',
            bedeutung: 'männl., “wilde Ziege, wilde Geiß”',
            ar3: 'ܕܰܪܺܝܐܝܳܘܣ',
            es3: 'ܕܪܝܐܝܘܣ',
            dt2: 'Darijus',
            ar1: 'ܕܪܝܝܘܣ',
            id: '1443'
        },
        {
            ar2: 'ܫܶܬܶܐܣܬܰܐ',
            id: '960',
            es3: '',
            es1: 'ܫܬܐܣܬܐ',
            ar3: '',
            dt3: '',
            bedeutung: 'weibl., “Fundament, Grund, Boden”',
            dt2: '',
            dt1: 'Schetesta',
            ar1: 'ܫܬܐܣܬܐ',
            es2: 'ܫܬܐܣܬܐ'
        },
        {
            es1: 'ܥܒܕܐܠܬ',
            ar2: 'ܥܰܒܶܕܰܐܠܰܬ',
            id: '1188',
            es2: 'ܥܒܕܐܠܬ',
            dt3: '',
            ar1: 'ܥܒܕܐܠܬ',
            ar3: '',
            es3: '',
            dt2: '',
            dt1: 'Abedallat',
            bedeutung: 'männl., wohl “Knecht Gottes”; weitere Formen: Abdallat'
        },
        {
            es1: 'ܙܟܝ ܝܘܣܦ',
            es3: '',
            ar3: '',
            dt3: '',
            dt2: '',
            bedeutung: '',
            dt1: 'Zeki Joseph',
            ar1: 'ܙܟܝ ܝܘܣܦ',
            es2: 'ܙܟܝ ܝܘܣܦ',
            id: '1167',
            ar2: 'ܙܰܟܺܝ ܝܰܘܣܶܦ'
        },
        {
            id: '160',
            es1: 'ܒܪܒܪܐ',
            es3: '',
            bedeutung: 'weibl., aus dem Griechischen übemommener Vorname; ”die Fremde”. Die Hl. Barbara (†303) gilt als eine der 14 Nothelfer und als Patronin der Bergleute, Glöckner und Architekten; Festtag: 4. Dezember',
            dt2: '',
            es2: 'ܒܪܒܪܐ',
            dt1: 'Barbara',
            ar3: '',
            ar1: 'ܒܪܒܪܐ',
            ar2: 'ܒܰܪܒܰܪܰܐ',
            dt3: ''
        },
        {
            ar1: 'ܒܪܫܢܐ',
            es3: '',
            dt3: '',
            ar3: '',
            id: '1367',
            ar2: 'ܒܰܪܫܶܢܳܐ',
            dt2: '',
            es1: 'ܒܪܫܢܐ',
            dt1: 'Barscheno',
            es2: 'ܒܪܫܢܐ',
            bedeutung: 'männl., “Sohn des Mondes”. Barsin war König von Ur'
        },
        {
            dt1: 'Nabil',
            ar1: 'ܢܒܝܠ',
            es3: '',
            dt3: '',
            bedeutung: '',
            es2: 'ܢܒܝܠ',
            es1: 'ܢܒܝܠ',
            id: '727',
            dt2: '',
            ar2: 'ܢܰܒܺܝܠ',
            ar3: ''
        },
        {
            bedeutung: 'weibl., griechischen Ursprungs; “Buch, Schreibtafel, Täfelchen, Briet”; weitere Formen: Panqita, Panqitha',
            dt2: '',
            dt3: '',
            id: '332',
            es1: 'ܦܢܩܝܬܐ',
            ar1: 'ܦܢܩܝܬܐ',
            es2: 'ܦܢܩܝܬܐ',
            ar2: 'ܦܰܢܩܺܝܬܰܐ',
            ar3: '',
            dt1: 'Fanqita',
            es3: ''
        },
        {
            bedeutung: '',
            id: '1573',
            es3: '',
            ar3: '',
            ar2: 'ܓܺܝܽܘܠܰܐ',
            dt1: 'Giyula',
            es1: 'ܓܝܘܠܐ',
            ar1: 'ܓܝܘܠܐ',
            dt2: '',
            es2: 'ܓܝܘܠܐ',
            dt3: ''
        },
        {
            dt2: '',
            ar3: '',
            id: '1081',
            bedeutung: 'männl., “mein Berg, meine Heimat Turabdin”',
            ar2: 'ܛܽܘܪܺܝ',
            es3: '',
            dt3: '',
            dt1: 'Turi',
            es2: 'ܛܘܪܝ',
            ar1: 'ܛܘܪܝ',
            es1: 'ܛܘܪܝ'
        },
        {
            ar3: '',
            dt2: '',
            es3: '',
            dt3: '',
            id: '1094',
            ar2: 'ܐܽܘܪܡܰܬܰܐ',
            es1: 'ܐܘܪܡܬܐ',
            dt1: 'Urmata',
            ar1: 'ܐܘܪܡܬܐ',
            bedeutung: 'weibl., “Land oder Stadt der Nation, der Heimat”; weitere Formen: Urmatha',
            es2: 'ܐܘܪܡܬܐ'
        },
        {
            dt3: '',
            dt1: 'Hailo',
            ar1: 'ܚܝܠܐ',
            ar2: 'ܚܰܝܠܳܐ',
            ar3: '',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gnade, die Gnadenreiche”. Im AT ist Hana die Mutter Samuels. Weitere Formen: Hano; siehe auch: Hanna, Johannes, Johannun',
            es3: '',
            dt2: '',
            id: '1610',
            es2: 'ܚܝܠܐ',
            es1: 'ܚܝܠܐ'
        },
        {
            es3: '',
            ar1: 'ܐܒܝܫܐ',
            es1: 'ܐܒܝܫܐ',
            ar2: 'ܐܰܒܺܝܫܳܐ',
            dt3: '',
            es2: 'ܐܒܝܫܐ',
            ar3: '',
            dt2: '',
            id: '1198',
            dt1: 'Abischo',
            bedeutung: ''
        },
        {
            dt2: 'Hebräus',
            ar2: 'ܐܒܪܐܣ',
            dt1: 'Hebaeus',
            dt3: '',
            es1: 'ܐܒܪܐܣ',
            bedeutung: '',
            ar1: 'ܐܒܪܐܣ',
            ar3: 'ܒܪ ܐܒܪܐܣ',
            id: '460',
            es2: 'ܐܒܪܐܣ',
            es3: 'ܒܪ ܐܒܪܐܣ'
        },
        {
            dt2: '',
            ar3: '',
            dt3: '',
            ar1: 'ܠܪܐ',
            id: '622',
            dt1: 'Lara',
            ar2: 'ܠܰܪܰܐ',
            es2: 'ܠܪܐ',
            es3: '',
            es1: 'ܠܪܐ',
            bedeutung: ''
        },
        {
            es2: 'ܡܝܟܐܝܠ',
            ar1: 'ܡܝܟܐܝܠ',
            ar2: 'ܡܺܝܟ݂ܰܐܝܶܠ',
            id: '1889',
            ar3: 'ܡܺܝܟ݂ܳܐܝܶܠ',
            dt1: 'Michael',
            dt2: '',
            bedeutung: '',
            es3: 'ܡܝܟ݂ܐܝܠ',
            es1: 'ܡܝܟܐܝܠ',
            dt3: ''
        },
        {
            es2: 'ܡܐܪܝܐ',
            dt1: 'Maria',
            bedeutung: '',
            dt3: '',
            ar3: '',
            dt2: 'Mariya',
            es1: 'ܡܐܪܝܐ',
            id: '668',
            ar1: 'ܡܐܪܝܐ',
            ar2: 'ܡܰܪܐܝܰܐ',
            es3: ''
        },
        {
            dt2: '',
            es2: 'ܡܪܝܢ',
            id: '1858',
            bedeutung: 'männl. Bedeutung unklar, vielleicht unser Herr“. Heiliger und Stifter der syrisch-maronitischen Kirche (†nach 410). Weitere Formen: Morun',
            ar3: '',
            ar2: 'ܡܰܪܺܝܢ',
            ar1: 'ܡܪܝܢ',
            es3: '',
            dt3: '',
            es1: 'ܡܪܝܢ',
            dt1: 'Marin'
        },
        {
            es2: 'ܠܘܕܝܐ',
            es1: 'ܠܘܕܝܐ',
            ar2: 'ܠܽܘܕܝܰܐ',
            es3: '',
            ar3: '',
            dt2: 'Lüdja',
            dt3: '',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “die aus Magdala Stammende”, ”Turm“. Im NT ist Maria Magdalena eine der treuesten Anhängerinnen Jesu. Weitere Formen: Magedlayta, Magedloyto',
            dt1: 'Lüdya',
            ar1: 'ܠܘܕܝܐ',
            id: '1824'
        },
        {
            dt1: 'Aschin',
            es3: '',
            id: '1290',
            dt3: '',
            dt2: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gefangener”. Im AT gilt Assir als Nachkomme Levis und Ahnherr Samuels',
            es1: 'ܐܫܝܢ',
            ar2: 'ܐܰܫܺܝܢ',
            es2: 'ܐܫܝܢ',
            ar1: 'ܐܫܝܢ',
            ar3: ''
        },
        {
            dt3: '',
            es3: '',
            ar2: 'ܥܰܡܪܰܡ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “der Onkel ist erhaben”',
            dt1: 'Amram',
            es2: 'ܥܡܪܡ',
            ar1: 'ܥܡܪܡ',
            es1: 'ܥܡܪܡ',
            ar3: '',
            id: '84',
            dt2: ''
        },
        {
            bedeutung: '',
            dt3: '',
            dt2: '',
            ar1: 'ܬܝܓܠܬ',
            dt1: 'Tiglat',
            es2: 'ܬܝܓܠܬ',
            ar3: '',
            es1: 'ܬܝܓܠܬ',
            id: '1056',
            ar2: 'ܬܺܝܓܠܰܬ',
            es3: ''
        },
        {
            es3: '',
            ar2: 'ܦܪܰܢܟ',
            dt3: '',
            ar1: 'ܦܪܢܟ',
            es1: 'ܦܪܢܟ',
            ar3: '',
            bedeutung: 'männl., “Seite/Partei Gottes” oder “die Nähe zu Gott”',
            dt2: '',
            id: '1532',
            es2: 'ܦܪܢܟ',
            dt1: 'Frank'
        },
        {
            es2: 'ܡܪܝܕܬܐ',
            es3: '',
            id: '1857',
            ar1: 'ܡܪܝܕܬܐ',
            dt1: 'Maridta',
            es1: 'ܡܪܝܕܬܐ',
            ar3: '',
            ar2: 'ܡܰܪܺܝܕܬܰܐ',
            dt3: '',
            dt2: '',
            bedeutung: ''
        },
        {
            ar1: 'ܪܡܡܬܐ',
            es2: 'ܪܡܡܬܐ',
            dt1: 'Rommata',
            ar3: '',
            ar2: 'ܪܳܡܡܰܬܰܐ',
            id: '871',
            dt2: '',
            bedeutung: 'weibl., “die Heimat sei erhaben”',
            es1: 'ܪܡܡܬܐ',
            es3: '',
            dt3: ''
        },
        {
            es1: 'ܢܐܬܠܝܐ',
            es3: '',
            ar1: 'ܢܐܬܠܝܐ',
            ar2: 'ܢܰܐܬܰܠܝܰܐ',
            ar3: '',
            id: '1944',
            dt2: 'Natalya',
            bedeutung: 'weibl., “Narzisse”; weitere Formen: Nargis',
            dt1: 'Natalia',
            es2: 'ܢܐܬܠܝܐ',
            dt3: ''
        },
        {
            bedeutung: 'weibl., “frohe Botschaft, gute Nachricht”; weitere Formen: Beschra',
            id: '225',
            dt2: 'Beschra',
            dt1: 'Beschrin',
            ar3: 'ܒܶܫܪܰܐ',
            es2: 'ܒܫܪܝܢ',
            ar1: 'ܒܫܪܝܢ',
            es3: 'ܒܫܪܐ',
            dt3: '',
            ar2: 'ܒܶܫܪܺܝܢ',
            es1: 'ܒܫܪܝܢ'
        },
        {
            bedeutung: 'männl., “du bist mein Herz”',
            dt2: '',
            es2: 'ܠܒܬ',
            id: '627',
            ar2: 'ܠܶܒܰܬ',
            dt1: 'Lebat',
            dt3: '',
            ar3: 'ܠܶܒܐܰܬ',
            es1: 'ܠܒܬ',
            ar1: 'ܠܒܬ',
            es3: 'ܠܒܐܬ'
        },
        {
            es1: 'ܟܐܢܘܬܐ',
            es3: '',
            id: '1788',
            ar1: 'ܟܐܢܘܬܐ',
            ar3: '',
            dt2: '',
            bedeutung: 'männl., griechischen Ursprungs; “dem Herrn gehörig”. Kuriakos (†301) ist Heiliger und wird zusammen mit seiner Mutter Yuliti/Juliti verehrt; Festtag: 15. Juli. Weitere Formen: Kuryakos, Quryaqos, Quriaqos',
            dt1: 'Kinutha',
            es2: 'ܟܐܢܘܬܐ',
            ar2: 'ܟܺܐܢܽܘܬܳܐ',
            dt3: ''
        },
        {
            dt1: 'Schalito',
            ar2: 'ܫܰܠܺܝܛܳܐ',
            es1: 'ܫܠܝܛܐ',
            dt3: '',
            ar1: 'ܫܠܝܛܐ',
            id: '931',
            ar3: '',
            bedeutung: 'männl., “Herrscher, Machthaber, Kommandant, Leiter, Gebieter, Minister”; weitere Formen: Schalita',
            dt2: '',
            es3: '',
            es2: 'ܫܠܝܛܐ'
        },
        {
            id: '1643',
            es2: 'ܚܘܫܐ',
            dt1: 'Hawsho',
            ar2: 'ܚܰܘܫܳܐ',
            es1: 'ܚܘܫܐ',
            ar1: 'ܚܘܫܐ',
            dt3: '',
            bedeutung: 'weibl., siehe Heleni',
            dt2: '',
            ar3: '',
            es3: ''
        },
        {
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäischen Ursprungs; “Stein, Fels”. Im NT ist Kipho/Kepha (grch. Petros) Beiname des Apostels Simon; Festtag: 29. Juni; weitere Formen: Kepha',
            dt3: 'Kifo',
            ar2: 'ܟܺܐܦܳܐ',
            dt2: 'Kipho',
            dt1: 'Kepha',
            es3: 'ܟܐܦܐ',
            ar3: 'ܟܶܐܦܰܐ',
            id: '592',
            es2: 'ܟܐܦܐ',
            es1: 'ܟܐܦܐ',
            ar1: 'ܟܐܦܐ'
        },
        {
            dt1: 'Schabo',
            es1: 'ܫܒܐ',
            es2: 'ܫܒܐ',
            ar2: 'ܫܰܒܳܐ',
            bedeutung: 'männl., “Frieden”; weitere Formen: Schayno, Schajno',
            dt3: '',
            ar1: 'ܫܒܐ',
            id: '2111',
            ar3: 'ܫܰܒܳܘ',
            es3: 'ܫܒܘ',
            dt2: ''
        },
        {
            ar3: '',
            es2: 'ܟܟܪܝܬܐ',
            ar1: 'ܟܟܪܝܬܐ',
            id: '573',
            es3: '',
            ar2: 'ܟܰܟܳܪܺܝܬܰܐ',
            es1: 'ܟܟܪܝܬܐ',
            bedeutung: 'weibl., “Bienenwabe”; Weitere Formen: Kakoritha',
            dt2: '',
            dt1: 'Kakorita',
            dt3: ''
        },
        {
            ar3: '',
            es3: '',
            dt1: 'Tobia',
            dt3: '',
            es2: 'ܛܘܒܝܐ',
            bedeutung: '',
            es1: 'ܛܘܒܝܐ',
            id: '2255',
            ar1: 'ܛܘܒܝܐ',
            dt2: '',
            ar2: 'ܛܳܘܒܺܝܰܐ'
        },
        {
            dt2: '',
            es2: 'ܪܒܘܬܐ',
            ar2: 'ܪܰܒܽܘܬܰܐ',
            id: '832',
            dt3: '',
            ar3: '',
            ar1: 'ܪܒܘܬܐ',
            es1: 'ܪܒܘܬܐ',
            bedeutung: 'weibl., “Würde, Hoheit, Pracht, Größe”',
            dt1: 'Rabuta',
            es3: ''
        },
        {
            es1: 'ܙܟ݂ܐ',
            id: '2348',
            dt3: '',
            dt1: 'Zakho',
            ar3: '',
            dt2: '',
            ar2: 'ܙܰܟ݂ܳܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe ist meine Gerechtigkeit”. Im AT ist Zidkija der letzte König des Südreiches Juda (597-587 v. Chr.). Weitere Formen: Sidkija, Sedakio, Sedakia, Sedaqio, Sedaqia, Zidkija',
            es3: '',
            ar1: 'ܙܟ݂ܐ',
            es2: 'ܙܟ݂ܐ'
        },
        {
            es2: 'ܢܝܢܘܣ',
            es3: '',
            id: '1961',
            dt3: '',
            dt1: 'Ninos',
            ar3: '',
            es1: 'ܢܝܢܘܣ',
            bedeutung: 'männl., “du bist mein Licht”',
            ar2: 'ܢܺܝܢܳܘܣ',
            ar1: 'ܢܝܢܘܣ',
            dt2: ''
        },
        {
            id: '664',
            es1: 'ܡܪܕܘܬܐ',
            dt3: '',
            dt1: 'Marduta',
            bedeutung: 'weibl., syrischen Ursprungs; “Kultur, Zivilisation, Erziehung”; weitere Formen: Mardutha',
            ar3: '',
            ar2: 'ܡܰܪܕܽܘܬܰܐ',
            es3: '',
            ar1: 'ܡܪܕܘܬܐ',
            es2: 'ܡܪܕܘܬܐ',
            dt2: ''
        },
        {
            es1: 'ܐܠܦ',
            es2: 'ܐܠܦ',
            dt2: 'Alaf',
            es3: '',
            dt1: 'Alef',
            ar3: '',
            bedeutung: 'männl., “Alef”, erster Buchstabe des syrisch-aramäischen Alphabets',
            ar1: 'ܐܠܦ',
            dt3: 'Olaf',
            id: '1247',
            ar2: 'ܐܰܠܶܦ'
        },
        {
            ar1: 'ܦܗܝܡܐ',
            es3: '',
            dt3: '',
            dt2: '',
            bedeutung: 'weibl., arabischen Ursprungs; “verständnisvolle, kluge Frau”; weitere Formen: Fehima',
            ar2: 'ܦܰܗܺܝܡܰܐ',
            es1: 'ܦܗܝܡܐ',
            id: '330',
            ar3: '',
            es2: 'ܦܗܝܡܐ',
            dt1: 'Fahima'
        },
        {
            es2: 'ܐܕܪ',
            es3: '',
            es1: 'ܐܕܪ',
            dt3: '',
            ar1: 'ܐܕܪ',
            ar3: '',
            ar2: 'ܐܳܕܰܪ',
            dt2: '',
            id: '790',
            dt1: 'Odar',
            bedeutung: 'männl., “Steineiche, Immergrüneiche”; weitere Formen: Odro'
        },
        {
            ar1: 'ܒܪܫܢܐ',
            ar3: '',
            id: '187',
            ar2: 'ܒܰܪܫܶܢܳܐ',
            dt1: 'Barscheno',
            dt2: '',
            es2: 'ܒܪܫܢܐ',
            es3: '',
            es1: 'ܒܪܫܢܐ',
            bedeutung: 'männl., “Sohn des Felses”',
            dt3: ''
        },
        {
            es2: 'ܐܢܬܐܢܝܘܣ',
            ar1: 'ܐܢܬܐܢܝܘܣ',
            bedeutung: '',
            ar3: '',
            id: '1269',
            dt3: '',
            ar2: 'ܐܰܢܬܰܐܢܝܽܘܣ',
            es3: '',
            dt1: 'Antanijos ',
            es1: 'ܐܢܬܐܢܝܘܣ',
            dt2: ''
        },
        {
            ar3: 'ܐܺܝܣܚܳܩ',
            es1: 'ܐܝܣܚܩ',
            id: '1698',
            bedeutung: 'männl., “Gott hat gegeben”. Jhabalaha war Patriarch-Katholikos der ostsyrischen Kirche von Seleukia-Ktesiphon (415-420). Weitere Formen: Jabalaha, Jaballaha, Yabaloho, Yaballaha',
            ar2: 'ܐܺܝܣܚܰܩ',
            es2: 'ܐܝܣܚܩ',
            dt1: 'Ishak',
            dt2: 'Ishok',
            es3: 'ܐܝܣܚܩ',
            dt3: 'Isaak',
            ar1: 'ܐܝܣܚܩ'
        },
        {
            es3: '',
            dt2: 'Sleymun',
            ar2: 'ܫܠܶܝܡܽܘܢ',
            id: '2156',
            es1: 'ܫܠܝܡܘܢ',
            bedeutung: 'männl., “Lob, Preis, Glorie, Pracht”',
            ar1: 'ܫܠܝܡܘܢ',
            es2: 'ܫܠܝܡܘܢ',
            ar3: '',
            dt3: '',
            dt1: 'Schleymun'
        },
        {
            dt1: 'Dulba',
            es1: 'ܕܘܠܒܐ',
            es2: 'ܕܘܠܒܐ',
            ar2: 'ܕܽܘܠܒܰܐ',
            bedeutung: 'männl.; arabischen Ursprungs; “Erziehung”',
            dt3: '',
            id: '1469',
            dt2: '',
            ar3: '',
            ar1: 'ܕܘܠܒܐ',
            es3: ''
        },
        {
            ar2: 'ܚܰܘܰܐ',
            es3: '',
            dt3: '',
            es2: 'ܚܘܐ',
            ar3: '',
            dt2: '',
            ar1: 'ܚܘܐ',
            id: '1642',
            es1: 'ܚܘܐ',
            bedeutung: 'weibl., siehe Heleni',
            dt1: 'Hawa'
        },
        {
            id: '2001',
            dt2: '',
            ar2: 'ܦܺܝܠܰܕܫ',
            dt3: '',
            es1: 'ܦܝܠܕܫ',
            bedeutung: 'männl., “Töpfer”; weitere Formen: Quqoyo',
            es3: '',
            dt1: 'Pildasch',
            es2: 'ܦܝܠܕܫ',
            ar3: '',
            ar1: 'ܦܝܠܕܫ'
        },
        {
            id: '2009',
            ar1: 'ܩܡܝܫܘܥ',
            dt3: '',
            es1: 'ܩܡܝܫܘܥ',
            bedeutung: 'männl., “mein Gott ist groß, erhaben”',
            ar2: 'ܩܳܡܝܶܫܽܘܥ',
            es3: '',
            dt2: '',
            dt1: 'Qomjeschu',
            ar3: '',
            es2: 'ܩܡܝܫܘܥ'
        },
        {
            id: '1569',
            ar2: 'ܓܺܝܕܰܪܝܰܐ',
            es2: 'ܓܝܕܪܝܐ',
            dt1: 'Gidarja',
            es1: 'ܓܝܕܪܝܐ',
            ar3: 'ܓܺܝܕܰܪܰܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname. Im AT ist Goliat ein Riese, gewaltiger Krieger und Philister aus Gat, der von David besiegt wird. Weitere Formen: Guljad, Goljad',
            es3: 'ܓܝܕܪܐ',
            dt2: '',
            dt3: '',
            ar1: 'ܓܝܕܪܝܐ'
        },
        {
            es1: 'ܬܚܢ',
            dt2: '',
            dt3: '',
            ar1: 'ܬܚܢ',
            ar3: 'ܬܳܚܳܢ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs. Tahan ist nach dem AT Sohn Efraims, Ahnherr Josuas. Weitere Formen: Tohon',
            id: '1029',
            es3: 'ܬܚܢ',
            dt1: 'Tahan',
            ar2: 'ܬܰܚܰܢ',
            es2: 'ܬܚܢ'
        },
        {
            es1: 'ܫܘܦܪܐ',
            id: '2168',
            ar1: 'ܫܘܦܪܐ',
            ar3: '',
            dt3: '',
            dt2: '',
            dt1: 'Schufro',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe hat unterstützt”',
            es3: '',
            es2: 'ܫܘܦܪܐ',
            ar2: 'ܫܽܘܦܪܳܐ'
        },
        {
            ar2: 'ܒܰܣܶܠܠܺܝܽܘܣ',
            es3: '',
            dt1: 'Baselius',
            es1: 'ܒܣܝܠܝܘܣ',
            ar1: 'ܒܣܝܠܝܘܣ',
            bedeutung: 'männl., aus dem Griechischen übenommener Vorname; “der Königliche, der Kaiserliche”. In der Kirchengeschichte war Basilius Kirchenlehrer und Bischof von Cäsarea (†379); Festtag: 1. Januar',
            dt3: 'Basilius',
            id: '195',
            ar3: '',
            es2: 'ܒܣܝܠܝܘܣ',
            dt2: 'Basselius'
        },
        {
            dt2: '',
            ar1: 'ܚܡܘܪܒܝ',
            dt1: 'Hammurabi',
            ar3: '',
            es3: '',
            bedeutung: 'männl., Hammurabi war ein Semite aus dem Stamm der Amoriter, König der 1. Dynastie von Babylon und gilt als der erste Gesetzgeber der Welt (“Codex Hammurabi”)',
            id: '427',
            dt3: '',
            ar2: 'ܚܰܡܽܘܪܰܒܺܝ',
            es1: 'ܚܡܘܪܒܝ',
            es2: 'ܚܡܘܪܒܝ'
        },
        {
            bedeutung: 'männl., “Sonnenzerschneider” oder “der die Sonne zerschneidet, zerteilt”; einer der zwei Boten, die Abgar zu Jesus gesendet hat. Weitere Formen: Schemaschgram',
            ar1: 'ܫܡܫܓܪܡ',
            es3: 'ܫܡܫܓܪܡ',
            dt3: '',
            id: '941',
            dt2: '',
            dt1: 'Schamschagram',
            ar2: 'ܫܰܡܫܰܓܪܰܡ',
            es1: 'ܫܡܫܓܪܡ',
            es2: 'ܫܡܫܓܪܡ',
            ar3: 'ܫܰܡܶܫܓܪܰܡ'
        },
        {
            ar1: 'ܒܪܟ݂ܬܐ',
            ar3: '',
            es3: '',
            id: '1415',
            bedeutung: '',
            dt1: 'Brikhta',
            dt3: '',
            dt2: 'Brichto',
            es2: 'ܒܪܝܟ݂ܬܐ',
            es1: 'ܒܪܝܟ݂ܬܐ',
            ar2: 'ܒܪܺܟ݂ܬܰܐ'
        },
        {
            dt3: '',
            bedeutung: 'weibl., “Güte, Gütigkeit, Freundlichkeit, Liebenswürdigkeit”; weitere Formen: Tabuta',
            es3: '',
            ar1: 'ܛܒܘܬܐ',
            es2: 'ܛܒܘܬܐ',
            id: '1068',
            ar2: 'ܛܳܒܽܘܬܰܐ',
            ar3: '',
            dt1: 'Tobuta',
            es1: 'ܛܒܘܬܐ',
            dt2: ''
        },
        {
            ar2: 'ܬܰܕܐܳܐ',
            dt3: '',
            id: '2220',
            dt2: 'Tadwo',
            dt1: 'Tadoo',
            ar1: 'ܬܕܐܐ',
            es1: 'ܬܕܐܐ',
            es2: 'ܬܕܐܐ',
            ar3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs. Im AT ist Tarah Nachkomme Sems und Vater Abrahams aus Paddan-Aram; im NT ist Tarah Ahnherr Jesu. Weitere Formen: Toroh, Terach, Tarach',
            es3: ''
        },
        {
            ar1: 'ܚܙܡܐ',
            es3: '',
            es1: 'ܚܙܡܐ',
            dt2: 'Hasme',
            es2: 'ܚܙܡܐ',
            dt1: 'Hazme',
            id: '2367',
            bedeutung: '',
            ar3: '',
            dt3: '',
            ar2: 'ܚܰܙܡܶܐ'
        },
        {
            dt3: '',
            es3: 'ܣܡܣܘܢ',
            es2: 'ܫܡܫܘܢ',
            ar1: 'ܫܡܫܘܢ',
            ar2: 'ܫܶܡܫܽܘܢ',
            dt1: 'Samson',
            bedeutung: 'männl., “sich beeilen”',
            ar3: 'ܣܰܡܣܳܘܢ',
            es1: 'ܫܡܫܘܢ',
            id: '2094',
            dt2: ''
        },
        {
            dt2: '',
            es2: 'ܒܪܡܬ݂ܐ',
            dt1: 'Barmotho',
            ar1: 'ܒܪܡܬ݂ܐ',
            bedeutung: 'männl., “Sohn des Flusses”',
            es1: 'ܒܪܡܬ݂ܐ',
            ar2: 'ܒܰܪܡܳܬ݂ܳܐ',
            ar3: '',
            dt3: '',
            es3: '',
            id: '1353'
        },
        {
            dt2: '',
            ar2: 'ܨܰܦܝܽܘܬܰܐ',
            dt1: 'Safyuta',
            ar1: 'ܨܦܝܘܬܐ',
            id: '888',
            es2: 'ܨܦܝܘܬܐ',
            ar3: '',
            es1: 'ܨܦܝܘܬܐ',
            bedeutung: 'weibl., “Reinheit, Keuschheit”',
            dt3: '',
            es3: ''
        },
        {
            dt2: '',
            ar2: 'ܒܰܟܳܘܣ',
            dt3: '',
            ar1: 'ܒܟܘܣ',
            es3: '',
            es2: 'ܒܟܘܣ',
            bedeutung: 'männl., wahrscheinlich Schüler des Mor Ephräm, Chorbischof von Aleppound von Bolosch(†432), Dichter, Verfasser vieler Madrosche (Gedichte); weitere Formen: Balay',
            es1: 'ܒܟܘܣ',
            dt1: 'Bakos',
            id: '1326',
            ar3: ''
        },
        {
            es2: 'ܝܘܠܝܐ',
            dt1: 'Julia',
            bedeutung: 'männl., Kalamu war ein aramäischer König von Samal (ca. 840/835 - 815/10 v. Chr.). Weitere Formen: Kilamwa, Kilamuwa',
            ar3: '',
            id: '1753',
            es1: 'ܝܘܠܝܐ',
            ar2: 'ܝܽܘܠܝܰܐ',
            dt2: '',
            ar1: 'ܝܘܠܝܐ',
            dt3: '',
            es3: ''
        },
        {
            es3: 'ܙܟ݂ܪܝܐ',
            es1: 'ܙܟ݂ܪܝܐ',
            ar1: 'ܙܟ݂ܪܝܐ',
            bedeutung: 'männl., Nebenform zu Zacharia',
            dt2: 'Sacharija',
            es2: 'ܙܟ݂ܪܝܐ',
            dt3: '',
            id: '883',
            ar2: 'ܙܟ݂ܰܪܝܰܐ',
            ar3: 'ܙܰܟ݂ܰܪܝܰܐ',
            dt1: 'Sacharia'
        },
        {
            ar2: 'ܐܰܚܺܝܡܰܠܟܐ',
            dt2: 'Ahimelech',
            dt1: 'Ahimalko',
            es2: 'ܐܚܝܡܠܟܐ',
            id: '57',
            es3: '',
            es1: 'ܐܚܝܡܠܟܐ',
            ar3: '',
            dt3: 'Ahimelek',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “mein Bruder ist König. Im AT begegnen Ahimalko, Sohn des Ahitub, Priester, Abkömmling Elis, und Ahimalko, Sohn des Abjatar, Priester unter David. Weitere Formen: Ahimelek, Ahimalak, Ahimelech',
            ar1: 'ܐܚܝܡܠܟܐ'
        },
        {
            ar1: 'ܚܠܡܐ',
            id: '1653',
            es3: '',
            ar2: 'ܚܶܠܡܳܐ',
            es2: 'ܚܠܡܐ',
            dt1: 'Helmo',
            ar3: '',
            bedeutung: 'weibl., syrischen Ursprungs; “Rahm, Sahne”',
            dt2: '',
            dt3: '',
            es1: 'ܚܠܡܐ'
        },
        {
            es1: 'ܥܒܕܝܫܘܥ',
            ar1: 'ܥܒܕܝܫܘܥ',
            id: '1472',
            ar3: '',
            ar2: 'ܥܶܒܶܕܝܶܫܽܘܥ',
            es2: 'ܥܒܕܝܫܘܥ',
            dt1: 'Ebedjeschu',
            dt3: '',
            dt2: '',
            es3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “doppelt fruchtbar, fruchtbares Land”. In der Bibel ist Afrem (Ephräm) der zweite Sohn von Joseph; in der Kirchengeschichte ist Ephräm (†373) Kirchenlehrer und Heiliger der syrischen Kirche, Verfasser vieler Hynmen. Weitere Formen: Ephraim, Efraim, Aphrem, Afram, Ephram'
        },
        {
            es1: 'ܡܪܝ ܦܝܠܠܘܟܣܝܢܘܣ ܝܘܚܢܢ ܕܘܠܒܐܢܝ',
            dt1: 'Mor Philoksinos Hanna Dolabani',
            bedeutung: 'männl. “Christus hat gesiegt. Mschihozkho (†6.Jh.) war ein ostsyrisch-nestorianischer Kirchenhistoriker Weitere Formen: Mschijazkha',
            dt3: '',
            ar1: 'ܡܪܝ ܦܝܠܠܘܟܣܝܢܘܣ ܝܘܚܢܢ ܕܘܠܒܐܢܝ',
            es2: 'ܡܪܝ ܦܝܠܠܘܟܣܝܢܘܣ ܝܘܚܢܢ ܕܘܠܒܐܢܝ',
            dt2: '',
            ar2: 'ܡܪܝ ܦܝܠܠܘܟܣܝܢܘܣ ܝܘܚܢܢ ܕܘܠܒܐܢܝ',
            id: '1903',
            es3: '',
            ar3: ''
        },
        {
            es1: 'ܐܚܙܝܐ',
            ar1: 'ܐܚܙܝܐ',
            dt1: 'Ahasja',
            ar2: 'ܐܰܚܰܙܝܰܐ',
            es2: 'ܐܚܙܝܐ',
            dt2: '',
            id: '1229',
            dt3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe hält fest”, Im AT begegnen Ahasja, Sohn des Ahab und der Isebel, König von Israel (852/51 v.Chr.), und Ahasja, Sohn des Joram und der Atalja, König von Juda (845 v.Chr.). Im Streit mit dem aramäischen König Hasael von Aram-Damaskus wird der letztere verwundet und getötet',
            ar3: '',
            es3: ''
        },
        {
            id: '1817',
            ar1: 'ܠܘܝ',
            dt2: '',
            bedeutung: 'männl., aus dem Lateinischen übernommener Vorname; “der aus Lucania Stammende”; Mitarbeiter des Apostels Paulus, gilt als der Verfasser des dritten Evangeliums und der Apostelgeschichte. Weitere Formen: Luko, Lukos, Luka',
            ar2: 'ܠܶܘܺܝ',
            dt1: 'Levi',
            ar3: '',
            es1: 'ܠܘܝ',
            dt3: '',
            es3: '',
            es2: 'ܠܘܝ'
        },
        {
            es3: 'ܪܡܐܝܠ',
            id: '2034',
            ar2: 'ܪܰܡܺܐܝܠ',
            dt1: 'Ramil',
            es1: 'ܪܡܐܝܠ',
            dt2: '',
            ar3: 'ܪܳܡܺܐܝܠ',
            bedeutung: 'männl., “Reiter”; aramäischer König von Samal (8. Jh. v. Chr.). Weitere Formen: Rakub, Rakobo',
            dt3: '',
            ar1: 'ܪܡܐܝܠ',
            es2: 'ܪܡܐܝܠ'
        },
        {
            es1: 'ܙܘܙܝ',
            id: '2213',
            dt3: '',
            dt1: 'Susi',
            ar3: '',
            ar2: 'ܙܽܘܙܺܝ',
            es3: '',
            ar1: 'ܙܘܙܝ',
            bedeutung: 'weibl., “Güte, Almosen, Liebenswürdigkeit, Anmut, Barmherzigkeit, Grazie”; weitere Formen: Taybuta, Taybutha, Tajbuta',
            es2: 'ܙܘܙܝ',
            dt2: ''
        },
        {
            es3: '',
            dt3: '',
            es2: 'ܫܘܡܬܐ',
            id: '980',
            es1: 'ܫܘܡܬܐ',
            ar3: '',
            dt2: '',
            bedeutung: 'weibl., “Wunde, Schwellung”; weitere Formen: Schumtha',
            ar1: 'ܫܘܡܬܐ',
            ar2: 'ܫܽܘܡܬܰܐ',
            dt1: 'Schumta'
        },
        {
            bedeutung: 'männl., “Jesus hat Mitleid mit mir”',
            ar1: 'ܚܢܢܝܫܘܥ',
            dt3: '',
            id: '468',
            es2: 'ܚܢܢܝܫܘܥ',
            ar3: '',
            dt1: 'Henonjeschu',
            es1: 'ܚܢܢܝܫܘܥ',
            dt2: '',
            es3: '',
            ar2: 'ܚܢܳܢܝܶܫܽܘܥ'
        },
        {
            dt1: 'Benjamin',
            id: '1400',
            ar3: 'ܒܶܢܝܳܡܶܝܢ',
            bedeutung: 'weibl., Berota ist eine aramäische Stadt, die von David geplündert wurde. Weitere Formen: Bruti, Berotai',
            dt2: 'Benyamin',
            dt3: 'Benyomin',
            es1: 'ܒܢܝܡܝܢ',
            es2: 'ܒܢܝܡܝܢ',
            es3: 'ܒܢܝܡܝܢ',
            ar2: 'ܒܶܢܝܰܡܺܝܢ',
            ar1: 'ܒܢܝܐܡܢ'
        },
        {
            dt3: '',
            es2: 'ܫܘܒܟ',
            ar2: 'ܫܽܘܡܰܟ',
            es1: 'ܫܘܒܟ',
            dt1: 'Schobach',
            bedeutung: 'weibl., “Duft”',
            ar1: 'ܫܘܒܟ',
            id: '2162',
            ar3: 'ܫܡܽܘܟ',
            es3: 'ܫܡܘܟ',
            dt2: ''
        },
        {
            dt3: '',
            ar3: '',
            bedeutung: 'männl., “Bussard”; weitere Formen: Jabno, Yabna, Jabna',
            id: '2295',
            es3: '',
            es1: 'ܘܐܠ',
            ar2: 'ܘܰܐܶܠ',
            dt1: 'Wael',
            ar1: 'ܘܐܠ',
            es2: 'ܘܐܠ',
            dt2: ''
        },
        {
            es3: 'ܐܣܟܐ',
            es2: 'ܝܣܝܟܐ',
            dt3: '',
            dt2: '',
            es1: 'ܝܣܝܟܐ',
            ar2: 'ܝܰܣܺܝܟܰܐ',
            dt1: 'Jessica',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe hat eingesetzt oder befestigt”. Im AT ist Jochachin Sohn von Joachim und König von Juda (598 - 597 v. Chr.). Weitere Formen: Jojachin, Jujochin',
            id: '1732',
            ar1: 'ܝܣܝܟܐ',
            ar3: 'ܐܰܣܶܟܰܐ'
        },
        {
            es1: 'ܡܚܙܝܬܐ',
            bedeutung: 'weibl., syrischen Ursprungs; “Fülle, Überﬂuss, Vollkommenheit, Vollständigkeit”; weitere Formen: Malyutha',
            dt3: '',
            ar1: 'ܡܚܙܝܬܐ',
            dt1: 'Mahzita',
            es3: '',
            ar2: 'ܡܰܚܙܺܝܬܰܐ',
            id: '1835',
            es2: 'ܡܚܙܝܬܐ',
            dt2: '',
            ar3: ''
        },
        {
            es2: 'ܓܪܓܝܣ',
            ar2: 'ܓܺܝܪܓܺܝܣ',
            bedeutung: 'männl., aus dem Griechischen übernommener Vorname; “der Landmann/Landwirt”. Mor Gewargis gilt in der Geschichte als Drachentöter und Schutzpatron der Waffenschmiede, Krieger und Landleute, einer der 14 Nothelfer. Weitere Formen: Gewargi, Georg, George, Corc, Circis',
            dt3: '',
            es3: '',
            es1: 'ܓܪܓܝܣ',
            ar1: 'ܓܪܓܝܣ',
            ar3: '',
            dt1: 'Girgis',
            dt2: '',
            id: '385'
        },
        {
            es2: 'ܦܢܡܘܐ',
            dt3: '',
            ar1: 'ܦܢܡܘܐ',
            bedeutung: 'männl., siehe Peleg',
            dt2: '',
            ar2: 'ܦܰܢܰܡܘܰܐ',
            id: '1993',
            es3: '',
            ar3: '',
            es1: 'ܦܢܡܘܐ',
            dt1: 'Panamwa'
        },
        {
            ar1: 'ܠܒܢ',
            es2: 'ܠܒܢ',
            ar2: 'ܠܰܒܰܢ',
            dt1: 'Laban',
            dt3: '',
            dt2: '',
            ar3: '',
            id: '613',
            es1: 'ܠܒܢ',
            bedeutung: 'männl., siehe Lobon',
            es3: ''
        },
        {
            ar2: 'ܣܰܒܪܰܬ',
            dt2: '',
            ar1: 'ܣܒܪܬ',
            dt1: 'Sabrat',
            es1: 'ܣܒܪܬ',
            ar3: '',
            bedeutung: 'männl., “du bist meine Hoffnung”',
            id: '879',
            dt3: '',
            es2: 'ܣܒܪܬ',
            es3: ''
        },
        {
            ar2: 'ܣܳܒܳܐ',
            dt3: '',
            ar3: '',
            dt2: '',
            bedeutung: 'männl., aus dem griechischen “stéphanosi Krone, Kranz”. Nach dem NT gilt der Hl. Stephanus als der erste Märtyrer der Christenheit, einer der “Sieben [Diakone]”. Weitere Formen: Stephan, Stefan, Stayfo',
            ar1: 'ܣܒܐ',
            dt1: 'Sobo',
            es3: '',
            id: '2193',
            es1: 'ܣܒܐ',
            es2: 'ܣܒܐ'
        },
        {
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Mann Jahwes”. Im AT ist Ischai Vater des David, im NT Ahnherr des Messias Jesus. Weitere Formen: Isai, Jesse',
            ar1: 'ܐܝܫܝ',
            ar3: '',
            dt1: 'Ischai',
            ar2: 'ܐܺܝܫܰܝ',
            dt2: '',
            id: '507',
            dt3: '',
            es3: '',
            es2: 'ܐܝܫܝ',
            es1: 'ܐܝܫܝ'
        },
        {
            ar1: 'ܦܛܪܐ',
            dt3: '',
            es3: '',
            ar3: '',
            bedeutung: 'weibl,. Form von Petrus',
            id: '806',
            es2: 'ܦܛܪܐ',
            es1: 'ܦܛܪܐ',
            dt2: '',
            dt1: 'Petra',
            ar2: 'ܦܶܛܪܰܐ'
        },
        {
            bedeutung: 'weibl., Suri ist die keilinschriftliche Bezeichnung für Syrien (griech. Suroi)',
            es1: 'ܙܦܝܐ',
            ar3: 'ܣܦܐܝܐ',
            dt2: 'Sofia',
            dt1: 'Sophia',
            id: '2198',
            ar2: 'ܣܦܝܐ',
            dt3: '',
            es3: 'ܣܦܐܝܐ',
            es2: 'ܙܦܝܐ',
            ar1: 'ܙܦܝܐ'
        },
        {
            dt2: '',
            ar1: 'ܬܡܢܝܐ',
            dt3: '',
            id: '1033',
            ar2: 'ܬܰܡܰܢܝܰܐ',
            es2: 'ܬܡܢܝܐ',
            bedeutung: 'weibl., “acht”, “Süden”. Eine der Bezeichnungen von Turabdin vor Christus, ein altes Siedlungsgebiet der Aramäer. Weitere Formen: Tamanja, Tamanya, Tamanita, Taimna',
            es1: 'ܬܡܢܝܐ',
            es3: '',
            dt1: 'Tamania',
            ar3: ''
        },
        {
            ar2: 'ܗܶܠܶܢܺܝ',
            dt2: '',
            es2: 'ܗܠܢܝܝ',
            ar1: 'ܗܠܢܝ',
            es1: 'ܗܠܢܝܝ',
            bedeutung: 'männl., “weiß, weiße Farbe”',
            id: '1652',
            dt3: '',
            ar3: '',
            es3: '',
            dt1: 'Heleni'
        },
        {
            es3: '',
            dt3: '',
            es2: 'ܥܛܝܗ',
            es1: 'ܥܛܝܗ',
            bedeutung: 'weibl., arabischen Ursprungs; “Gabe, Geschenk”',
            ar1: 'ܥܛܝܗ',
            dt2: '',
            dt1: 'Atiyah',
            ar2: 'ܥܰܛܺܝܰܗ',
            ar3: '',
            id: '123'
        },
        {
            es3: 'ܐܘܪܡܝ',
            bedeutung: 'männl., siehe Volgesa',
            id: '2287',
            ar1: 'ܐܘܪܡܝܐ',
            ar3: 'ܐܽܘܪܡܺܝ',
            es2: 'ܐܘܪܡܝܐ',
            es1: 'ܐܘܪܡܝܐ',
            dt3: '',
            ar2: 'ܐܽܘܪܡܺܝܰܐ',
            dt2: '',
            dt1: 'Urmiya'
        },
        {
            bedeutung: 'männl., “mein Brot”',
            dt3: '',
            ar2: 'ܟܽܘܟܺܝܬܰܐ',
            dt2: '',
            ar1: 'ܟܘܟܝܬܐ',
            ar3: '',
            es1: 'ܟܘܟܝܬܐ',
            dt1: 'Kukita',
            id: '1796',
            es2: 'ܟܘܟܝܬܐ',
            es3: ''
        },
        {
            es2: 'ܐܕܪܐ',
            dt1: 'Odro',
            ar2: 'ܐܳܕܪܳܐ',
            dt3: '',
            ar1: 'ܐܕܪܐ',
            id: '1986',
            es3: '',
            ar3: '',
            es1: 'ܐܕܪܐ',
            bedeutung: 'männl., aus dem Lateinischen übernommener Vorname; “der Kleine”. Im NT ist Paulus aus Tarsus nach seiner Bekehrung zum Apostel Christi geworden, “Apostel der Heiden”; Paul(us) hieß eigentlich Saul(us) (“der Erbetene”): “Vom Saulus zum Paulus werden”. Weitere Formen: Favlus, Paulos, Bulus',
            dt2: ''
        },
        {
            id: '2294',
            ar1: 'ܦܘܠܓܣܐ',
            es1: 'ܦܘܠܓܣܐ',
            bedeutung: 'männl., “Strom, Bach”; weitere Formen: Jablo',
            dt2: '',
            ar2: 'ܦܳܘܠܓܶܣܰܐ',
            dt3: '',
            es2: 'ܦܘܠܓܣܐ',
            ar3: '',
            es3: '',
            dt1: 'Volgesa'
        },
        {
            bedeutung: '',
            es1: 'ܣܒܪܬܐ',
            ar1: 'ܣܒܪܬܐ',
            es2: 'ܣܒܪܬܐ',
            es3: '',
            id: '2108',
            dt1: 'Sbartha',
            ar3: '',
            dt3: '',
            ar2: 'ܣܒܰܪܬܰܐ',
            dt2: ''
        },
        {
            dt1: 'Ilsabro',
            ar3: '',
            es3: '',
            es1: 'ܐܝܠܣܒܪܐ',
            dt2: '',
            id: '1689',
            es2: 'ܐܝܠܣܒܪܐ',
            ar1: 'ܐܝܠܣܒܪܐ',
            ar2: 'ܐܺܝܠܣܰܒܪܳܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “er wird lachen” oder “daß Gott lache” oder “das Kind des Lachens”. Im AT ist Ishaq  2000 und 1400 v. Chr.) der Sohn von Abraham und Sara, Halbbruder von Ismael. Abraham sollte ursprünglich Ishaq für Gott opfern, was allerdings dann durch Eingreifen des Engels verhindert wird. Ishaq heiratet Rebekka, die Tochter des Aramäers Betuel aus Haran in Aram Beth-Nahrin (= Mesopotamien), ist Vater der Zwillinge Jakob und Esau. Viele syrische Heilige tragen den Namen Ishaq. Weitere Formen: Isaak, Ishoq',
            dt3: ''
        },
        {
            es1: 'ܗܢܝ',
            id: '437',
            bedeutung: 'männl., syrischen Ursprungs; “genießen, nutzen”',
            es2: 'ܗܢܝ',
            dt1: 'Hani',
            dt3: '',
            ar3: '',
            ar1: 'ܗܢܝ',
            es3: '',
            dt2: '',
            ar2: 'ܗܰܢܺܝ'
        },
        {
            es2: 'ܕܢܚܐ',
            ar2: 'ܕܰܢܚܳܐ',
            dt1: 'Danho',
            ar1: 'ܕܢܚܐ',
            ar3: '',
            dt2: '',
            es3: '',
            bedeutung: 'weibl. Form von Daniel',
            dt3: '',
            id: '1437',
            es1: 'ܕܢܚܐ'
        },
        {
            es3: 'ܦܪܘܩܐ',
            ar2: 'ܦܳܪܘܽܩ',
            id: '1530',
            dt3: '',
            ar3: 'ܦܳܪܽܘܩܳܐ',
            es2: 'ܦܪܘܩ',
            dt1: 'Foruq',
            bedeutung: 'männl., “der Tapfere, der Mannhafte, Herold, Held”. Gabbara oder Gabbar war ein aramäischer König (9. Jh. v.Chr.) von Guzana (=Tell Halaf). Weitere Formen: Gabara, Kappara',
            ar1: 'ܦܪܘܩ',
            es1: 'ܦܪܘܩ',
            dt2: 'Farruq'
        },
        {
            ar2: 'ܐܰܒܺܝܰܐ',
            es2: 'ܐܒܝܐ',
            dt1: 'Abija',
            dt3: '',
            dt2: '',
            ar3: '',
            ar1: 'ܐܒܝܐ',
            id: '16',
            es1: 'ܐܒܝܐ',
            es3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe ist Vater” oder “(mein) Vater ist Jahwe”. Im AT gilt Abija als Sohn Rehabeams und König von Juda (910-908 v.Chr.); viele Personen tragen diesen Namen; im NT wird Zacharias, der Vater Johannes des Täufers, zur Priesterklasse Abija gerechnet. Weitere Formen: Abia'
        },
        {
            ar1: 'ܒܩܪܐ',
            dt3: '',
            dt2: '',
            ar2: 'ܒܰܩܪܰܐ',
            es2: 'ܒܩܪܐ',
            ar3: '',
            es1: 'ܒܩܪܐ',
            es3: '',
            dt1: 'Bakra',
            id: '1327',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott schütze sein Leben!” Einer der Hl. Drei Könige wird in der Tradition Balthasar genannt. Im AT wird dem Propheten Daniel der Name Balthasar oder Beltschazzar am babylonischen Hof beigegeben'
        },
        {
            es3: '',
            dt3: '',
            id: '846',
            dt2: '',
            ar3: '',
            bedeutung: 'männl., “Mysterium, Geheimnis”; weitere Formen: Rozo, Rasa',
            es2: 'ܪܐܙܐ',
            es1: 'ܪܐܙܐ',
            ar2: 'ܪܰܐܙܰܐ',
            ar1: 'ܪܐܙܐ',
            dt1: 'Raza'
        },
        {
            es2: 'ܡܪܒܝܬܐ',
            dt3: '',
            ar2: 'ܡܰܪܒܺܝܬܰܐ',
            id: '1850',
            ar1: 'ܡܪܒܝܬܐ',
            dt1: 'Marbita',
            es3: '',
            ar3: '',
            es1: 'ܡܪܒܝܬܐ',
            bedeutung: '',
            dt2: ''
        },
        {
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Schmied”. Im AT ist Kain der erste Sohn von Adam und Eva. Weitere Formen: Koen, Koyen, Qoyen',
            ar2: 'ܝܺܘܙܰܕܰܩ',
            ar1: 'ܝܘܙܕܩ',
            dt1: 'Jozadak',
            dt2: '',
            ar3: 'ܝܽܘܙܳܕܳܩ',
            id: '1751',
            dt3: '',
            es2: 'ܝܘܙܕܩ',
            es1: 'ܝܘܙܕܩ',
            es3: 'ܝܘܙܕܩ'
        },
        {
            ar1: 'ܓܡܝܪܘܬܐ',
            id: '1576',
            bedeutung: '',
            ar2: 'ܓܡܺܝܪܽܘܬܰܐ',
            es3: '',
            dt2: '',
            es2: 'ܓܡܝܪܘܬܐ',
            dt1: 'Gmiruta',
            ar3: '',
            dt3: '',
            es1: 'ܓܡܝܪܘܬܐ'
        },
        {
            bedeutung: 'weibl., Arnasia ist ein Dorf in Turabdin',
            id: '1280',
            es1: 'ܐܪܝܡ',
            dt1: 'Arim',
            ar3: '',
            dt2: '',
            dt3: '',
            ar2: 'ܐܰܪܺܝܡ',
            ar1: 'ܐܪܝܡ',
            es2: 'ܐܪܝܡ',
            es3: ''
        },
        {
            ar2: 'ܟܽܘܟܺܝܬܰܐ',
            dt1: 'Kukita',
            es2: 'ܟܘܟܝܬܐ',
            bedeutung: 'weibl., syrischen Ursprungs; “0rkan, Sturm, Wirbelsturm”; weitere Formen: Kukitha',
            ar1: 'ܟܘܟܝܬܐ',
            es3: '',
            id: '608',
            dt3: '',
            dt2: '',
            es1: 'ܟܘܟܝܬܐ',
            ar3: ''
        },
        {
            ar1: 'ܩܕܫ',
            id: '811',
            es3: '',
            es2: 'ܩܕܫ',
            dt1: 'Qadesch',
            ar2: 'ܩܰܕܶܫ',
            bedeutung: 'männl., “heiligen”',
            es1: 'ܩܕܫ',
            ar3: '',
            dt2: 'Kadesch',
            dt3: ''
        },
        {
            bedeutung: '',
            dt1: 'Mata',
            es2: 'ܡܬܐ',
            id: '1875',
            dt2: '',
            ar1: 'ܡܬܐ',
            ar3: 'ܡܳܬܳܐ',
            es1: 'ܡܬܐ',
            ar2: 'ܡܰܬܰܐ',
            dt3: '',
            es3: 'ܡܬܐ'
        },
        {
            es2: 'ܕܪܐ',
            ar2: 'ܕܰܪܳܐ',
            dt2: '',
            bedeutung: '',
            ar3: '',
            es1: 'ܕܪܐ',
            id: '1446',
            ar1: 'ܕܪܐ',
            es3: '',
            dt3: '',
            dt1: 'Daro'
        },
        {
            ar3: '',
            id: '1003',
            bedeutung: 'männl., “Märtyrer, Zeuge”',
            es3: '',
            ar1: 'ܣܗܕܐ',
            dt2: '',
            es2: 'ܣܗܕܐ',
            dt1: 'Sohdo',
            dt3: '',
            es1: 'ܣܗܕܐ',
            ar2: 'ܣܳܗܕܳܐ'
        },
        {
            dt1: 'Zenobio',
            es3: '',
            dt3: '',
            dt2: '',
            es2: 'ܙܢܘܒܝܐ',
            ar2: 'ܙܶܢܳܘܒܺܝܳܐ',
            bedeutung: 'männl. Form zu Zenobia',
            ar3: '',
            ar1: 'ܙܢܘܒܝܐ',
            id: '1169',
            es1: 'ܙܢܘܒܝܐ'
        },
        {
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “[Markt]platz”. Im AT ist Rehob König von Aram-Soba (Libanon), Vater von Hadad-Eser (11. Jh. v. Chr.). Weitere Formen: Rohob, Rohub',
            es1: 'ܪܚܒ',
            es2: 'ܪܚܒ',
            dt2: '',
            ar3: 'ܪܳܚܽܘܒ',
            ar2: 'ܪܳܚܳܒ',
            ar1: 'ܪܚܒ',
            id: '850',
            dt3: '',
            es3: 'ܪܚܘܒ',
            dt1: 'Rehob'
        },
        {
            ar2: 'ܙܰܟܺܝܪ',
            bedeutung: 'männl., König von Hamath (8. Jh. v.Chr.); weitere Formen: Zakkur, Sakir',
            ar3: '',
            ar1: 'ܙܟܝܪ',
            dt2: '',
            dt3: '',
            dt1: 'Zakir',
            es3: '',
            es1: 'ܙܟܝܪ',
            id: '1159',
            es2: 'ܙܟܝܪ'
        },
        {
            es2: 'ܐܠܝܥܙܪ',
            dt2: 'Eleasar',
            es1: 'ܐܠܝܥܙܪ',
            ar1: 'ܐܠܝܥܙܪ',
            dt1: 'Eliozor',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott hat hinzugefügt”',
            ar2: 'ܐܶܠܺܝܥܰܙܰܪ',
            ar3: '',
            es3: '',
            dt3: '',
            id: '1486'
        },
        {
            id: '461',
            bedeutung: 'männl., syrischen Ursprungs; “Glorie, Herrlichkeit, Glanz, Pracht”',
            dt2: '',
            dt3: '',
            es2: 'ܗܕܪܐ',
            ar2: 'ܗܶܕܪܳܐ',
            es1: 'ܗܕܪܐ',
            ar3: '',
            dt1: 'Hedro',
            ar1: 'ܗܕܪܐ',
            es3: ''
        },
        {
            dt2: '',
            ar2: 'ܝܰܕܺܝܕܬܰܐ',
            bedeutung: 'weibl. Form zu Yadido',
            es2: 'ܝܕܝܕܬܐ',
            ar1: 'ܝܕܝܕܬܐ',
            dt1: 'Yadidta',
            es1: 'ܝܕܝܕܬܐ',
            es3: '',
            dt3: '',
            id: '1114',
            ar3: ''
        },
        {
            dt1: 'Aukit',
            ar1: 'ܐܘܟܝܬ',
            dt2: '',
            es2: 'ܐܘܟܝܬ',
            id: '1310',
            es3: '',
            es1: 'ܐܘܟܝܬ',
            bedeutung: 'männl., “er hat vermehrt, erhöht”; weitere Forınen: Aureb',
            ar3: '',
            ar2: 'ܐܰܘܟܺܝܬ',
            dt3: ''
        },
        {
            id: '32',
            dt1: 'Abschalom',
            es1: 'ܐܒܫܠܘܡ',
            es3: '',
            ar2: 'ܐܰܒܫܰܠܳܡ',
            dt2: '',
            ar1: 'ܐܒܫܠܘܡ',
            dt3: '',
            es2: 'ܐܒܫܠܘܡ',
            ar3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Vater des Friedens, Vater ist Friede”; Abschalom ist im AT ein Sohn des Königs David. Weitere Formen: Absalon, Absalom'
        },
        {
            ar1: 'ܡܠܟܝܙܕܩ',
            ar2: 'ܡܰܠܟܺܝܙܰܕܶܩ',
            es2: 'ܡܠܟܝܙܕܩ',
            dt2: '',
            dt1: 'Malkisadek',
            dt3: '',
            id: '1841',
            es1: 'ܡܠܟܝܙܕܩ',
            ar3: '',
            es3: '',
            bedeutung: 'weibl., syrischen Ursprungs; “Wachstum, Wuchs, Zuwachs, Entwicklung”'
        },
        {
            es3: '',
            id: '1644',
            dt3: '',
            dt1: 'Hazne',
            ar2: 'ܚܰܙܢܶܐ',
            dt2: '',
            es2: 'ܚܙܢܐ',
            ar1: 'ܚܙܢܐ',
            ar3: '',
            es1: 'ܚܙܢܐ',
            bedeutung: 'weibl., aus dem Griechischen übernommener Vorname; “die Sonnenhafte”. Heleni war die Mutter des Kaisers Konstantin des Großen (4. Jh.). Weitere Formen: Helena, Helene'
        },
        {
            dt1: 'Malktha',
            es2: 'ܡܠܟܬܐ',
            es1: 'ܡܠܟܬܐ',
            es3: '',
            dt2: '',
            ar1: 'ܡܠܟܬܐ',
            bedeutung: 'weibl., “Königin”; weitere Formen: Malktho, Malkta',
            ar3: '',
            id: '654',
            dt3: '',
            ar2: 'ܡܰܠܟܬܰܐ'
        },
        {
            dt1: 'Dodo',
            id: '278',
            es1: 'ܕܕܐ',
            es2: 'ܕܕܐ',
            es3: '',
            dt3: '',
            ar3: '',
            dt2: '',
            ar2: 'ܕܳܕܳܐ',
            bedeutung: 'männl., “Onkel (väterlicherseits)”. Dodo (†609) ist ein Heiliger in der syrisch- orthodoxen Kirche; Festtag: 20. Mai',
            ar1: 'ܕܕܐ'
        },
        {
            ar2: 'ܪܶܫܶܦ',
            dt2: '',
            id: '853',
            es1: 'ܪܫܦ',
            ar3: '',
            ar1: 'ܪܫܦ',
            es2: 'ܪܫܦ',
            bedeutung: 'männl., “Flamme”. Reschef war der Gott des Blitzes (“Blitzgott”) in der aramäischen Mythologie',
            dt1: 'Reschef',
            es3: '',
            dt3: ''
        },
        {
            id: '1146',
            es2: 'ܙܟ݂ܪܝܐ',
            dt1: 'Zacharia',
            dt2: '',
            ar2: 'ܙܟ݂ܰܪܝܰܐ',
            es1: 'ܙܟ݂ܪܝܐ',
            es3: 'ܙܟ݂ܪܝܐ',
            ar3: 'ܙܟ݂ܰܪܝܳܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “der Herr hat sich meiner erinnert” oder “Jahwe war eingedenk”. Im AT ist Zacharia ein Prophet in Juda nach der Zeit des babylonischen Exils (um 520 v.Chr.); im NT dagegen gilt Zacharia als Vater von Johannes dem Täufer. Weitere Formen: Sacharia, Sacharja, Zcharjo, Zchario, Zekeriye, Zakai',
            dt3: '',
            ar1: 'ܙܟ݂ܪܝܐ'
        },
        {
            dt2: '',
            ar2: 'ܓܽܘܕܰܐ',
            es3: '',
            bedeutung: 'weibl., “Gemeinschaft, Gruppe, Team, Band, Chor”',
            ar3: '',
            id: '396',
            es1: 'ܓܘܕܐ',
            es2: 'ܓܘܕܐ',
            ar1: 'ܓܘܕܐ',
            dt1: 'Guda',
            dt3: ''
        },
        {
            dt2: '',
            bedeutung: 'männl., “Erwählter, Auserwählter, Guter”',
            es1: 'ܓܒܝ',
            ar3: 'ܓܰܐܒܰܝ',
            es2: 'ܓܒܝ',
            id: '1537',
            dt3: '',
            ar2: 'ܓܰܒܰܝ',
            ar1: 'ܓܒܝ',
            es3: 'ܓܐܒܝ',
            dt1: 'Gabay'
        },
        {
            ar3: '',
            es2: 'ܠܡܥܐ',
            es1: 'ܠܡܥܐ',
            dt3: '',
            bedeutung: 'weibl., syrischen Ursprungs; “Freundschaft”; Weitere Formen: Levitha, Lvita, Lvitha',
            ar1: 'ܠܡܥܐ',
            ar2: 'ܠܰܡܥܰܐ',
            es3: '',
            id: '1809',
            dt2: '',
            dt1: 'Lamha'
        },
        {
            id: '1225',
            bedeutung: 'männl., “antworte!”',
            ar1: 'ܐܓܝܒ',
            dt1: 'Agib',
            es1: 'ܐܓܝܒ',
            dt3: '',
            es3: '',
            ar3: '',
            es2: 'ܐܓܝܒ',
            dt2: '',
            ar2: 'ܐܰܓܺܝܒ'
        },
        {
            dt1: 'Dodjeschu',
            es1: 'ܕܕܝܫܘܥ',
            dt2: '',
            es2: 'ܕܕܝܫܘܥ',
            ar2: 'ܕܳܕܝܶܫܽܘܥ',
            ar3: '',
            es3: '',
            id: '277',
            dt3: '',
            bedeutung: 'männl., “Jesus ist mein Onkel”',
            ar1: 'ܕܕܝܫܘܥ'
        },
        {
            dt1: 'Zabai',
            dt2: '',
            es1: 'ܙܒܝ',
            id: '2335',
            bedeutung: 'männl., “glänzend, ausgezeichnet, schön, Edelmann“',
            ar2: 'ܙܰܒܰܝ',
            ar3: '',
            es3: '',
            dt3: '',
            ar1: 'ܙܒܝ',
            es2: 'ܙܒܝ'
        },
        {
            ar2: 'ܢܽܘܗܪܳܐ',
            bedeutung: 'männl., “Licht, Glanz”',
            es1: 'ܢܗܪܐ',
            es2: 'ܢܗܪܐ',
            dt2: '',
            id: '779',
            ar3: '',
            dt3: '',
            dt1: 'Nuhro',
            ar1: 'ܢܘܗܪܐ',
            es3: ''
        },
        {
            es2: 'ܐܠܝܐ',
            dt1: 'Elyo',
            bedeutung: '',
            dt2: 'Eliyo',
            ar3: '',
            es1: 'ܐܠܝܐ',
            dt3: 'Eliya',
            es3: '',
            ar2: 'ܐܶܠܺܝܳܐ',
            id: '1496',
            ar1: 'ܐܠܝܐ'
        },
        {
            ar3: '',
            es3: '',
            dt3: '',
            dt2: 'Mirsa',
            ar1: 'ܡܝܪܙܐ',
            ar2: 'ܡܺܝܪܙܰܐ',
            bedeutung: '',
            es1: 'ܡܝܪܙܐ',
            es2: 'ܡܝܪܙܐ',
            dt1: 'Mirza',
            id: '1894'
        },
        {
            id: '865',
            bedeutung: 'männl., “du bist mein Liebling, mein Schatz”',
            es1: 'ܪܚܡܬ',
            ar2: 'ܪܳܚܡܰܬ',
            ar1: 'ܪܚܡܬ',
            dt2: '',
            ar3: '',
            dt1: 'Rohmat',
            es2: 'ܪܚܡܬ',
            es3: '',
            dt3: ''
        },
        {
            dt2: '',
            es2: 'ܩܘܫܬܐ',
            ar3: '',
            ar2: 'ܩܽܘܫܬܰܐ',
            ar1: 'ܩܘܫܬܐ',
            es1: 'ܩܘܫܬܐ',
            dt1: 'Quschta',
            bedeutung: 'weibl., “Wahrheit, Ehrlichkeit, Rechtschaffenheit”',
            dt3: '',
            es3: '',
            id: '820'
        },
        {
            es2: 'ܩܘܩܝܐ',
            ar1: 'ܩܘܩܝܐ',
            dt1: 'Quqojo',
            dt2: '',
            ar3: '',
            ar2: 'ܩܽܘܩܳܝܳܐ',
            bedeutung: 'männl., “Töpfer”; weitere Formen: Quqoyo',
            dt3: '',
            es3: '',
            id: '818',
            es1: 'ܩܘܩܝܐ'
        },
        {
            ar1: 'ܐܬܪܐ',
            dt2: '',
            es2: 'ܐܬܪܐ',
            dt3: '',
            es3: '',
            es1: 'ܐܬܪܐ',
            dt1: 'Athra',
            ar2: 'ܐܰܬ݂ܪܰܐ',
            ar3: '',
            id: '1300',
            bedeutung: 'männl., “reich, reichlich”'
        },
        {
            dt3: 'Barhajlo',
            ar2: 'ܒܰܪܚܰܝܠܳܐ',
            id: '164',
            dt1: 'Barhailo',
            dt2: 'Barhaylo',
            es1: 'ܒܪܚܝܠܐ',
            es3: '',
            ar1: 'ܒܪܚܝܠܐ',
            es2: 'ܒܪܚܝܠܐ',
            ar3: '',
            bedeutung: 'männl., “Sohn der Kraft, Sohn der Macht”'
        },
        {
            dt3: '',
            es2: 'ܙܠܐ',
            dt2: '',
            id: '1162',
            ar3: '',
            ar2: 'ܙܰܠܳܐ',
            bedeutung: 'männl., “Binse, Ried, Schilf“, weitere Formen: Zolo',
            dt1: 'Zalo',
            es1: 'ܙܠܐ',
            es3: '',
            ar1: 'ܙܠܐ'
        },
        {
            ar1: 'ܚܘܠܡܢܐ',
            dt3: '',
            ar2: 'ܚܽܘܠܡܳܢܳܐ',
            es3: 'ܚܘܠܡܢܐ',
            id: '1678',
            dt2: '',
            es2: 'ܚܘܠܡܢܐ',
            es1: 'ܚܘܠܡܢܐ',
            ar3: 'ܚܽܘܠܡܰܢܰܐ',
            dt1: 'Hulmono',
            bedeutung: 'männl., “Gott der Liebe”, “Gott ist die Liebe”'
        },
        {
            dt1: 'Zakho',
            es2: 'ܙܟ݂ܐ',
            ar1: 'ܙܟ݂ܐ',
            es3: '',
            ar3: '',
            es1: 'ܙܟ݂ܐ',
            bedeutung: 'männl., “der Sieger”; weitere Formen: Zako ',
            dt2: '',
            dt3: '',
            ar2: 'ܙܰܟ݂ܳܐ',
            id: '1157'
        },
        {
            ar2: 'ܫܶܟ݂ܰܢܝܰܐ',
            dt3: '',
            es3: 'ܫܟ݂ܢܝܐ',
            ar1: 'ܫܟ݂ܢܝܐ',
            id: '951',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe hat seine Wohnung eingerichtet”',
            es2: 'ܫܟ݂ܢܝܐ',
            dt1: 'Schechanja',
            es1: 'ܫܟ݂ܢܝܐ',
            ar3: 'ܫܟ݂ܰܢܝܳܐ',
            dt2: ''
        },
        {
            id: '1533',
            es1: 'ܦܪܢܣܝܣܟܐ',
            es2: 'ܦܪܢܣܝܣܟܐ',
            dt1: 'Franziska',
            dt3: '',
            ar3: '',
            es3: '',
            ar1: 'ܦܪܢܣܝܣܟܐ',
            ar2: 'ܦܪܰܢܣܺܝܣܟܰܐ',
            bedeutung: '',
            dt2: ''
        },
        {
            es1: 'ܐܒܫܠܘܡ',
            id: '1209',
            ar1: 'ܐܒܫܠܘܡ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Vater des Friedens, Vater ist Friede”; Abschalom ist im AT ein Sohn des Königs David. Weitere Formen: Absalon, Absalom',
            dt3: '',
            dt1: 'Abschalom',
            ar2: 'ܐܰܒܫܰܠܳܡ',
            es3: '',
            es2: 'ܐܒܫܠܘܡ',
            dt2: '',
            ar3: ''
        },
        {
            dt2: '',
            ar3: '',
            es3: '',
            dt3: '',
            ar2: 'ܒܰܪܒܰܪܰܐ',
            dt1: 'Barbara',
            es1: 'ܒܪܒܪܐ',
            es2: 'ܒܪܒܪܐ',
            bedeutung: 'männl., “Sohn des Gerichtes, Sohn des Urteils”',
            ar1: 'ܒܪܒܪܐ',
            id: '1340'
        },
        {
            es2: 'ܐܘܪܝܠ',
            es1: 'ܐܘܪܝܠ',
            dt1: 'Uriel',
            es3: '',
            dt2: '',
            id: '1092',
            ar1: 'ܐܘܪܝܠ',
            ar3: '',
            ar2: 'ܐܽܘܪܺܝܶܠ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott ist mein Licht”; Uriel gilt im AT als ein Erzengel',
            dt3: ''
        },
        {
            dt3: '',
            ar1: 'ܐܝܕܐܝܢ',
            es3: 'ܐܝܕܝܢ',
            es2: 'ܐܝܕܐܝܢ',
            id: '133',
            es1: 'ܐܝܕܐܝܢ',
            ar2: 'ܐܰܝܕܐܺܝܢ',
            dt1: 'Aydin',
            dt2: '',
            ar3: 'ܐܰܝܕܺܝܢ',
            bedeutung: ''
        },
        {
            ar2: 'ܩܺܝܠܠܺܝܬܰܐ',
            ar3: '',
            bedeutung: 'männl., aramäisch-hebräischer Ursprung; “Opfer”; weitere Formen: Qorban, Qurban, Qurbana, Kurbana, Kurbono, Qurbono',
            es3: '',
            dt3: '',
            ar1: 'ܩܝܠܠܝܬܐ',
            dt2: '',
            id: '1783',
            dt1: 'Killita',
            es1: 'ܩܝܠܠܝܬܐ',
            es2: 'ܩܝܠܠܝܬܐ'
        },
        {
            es1: 'ܩܡܝܫܘܥ',
            ar1: 'ܩܡܝܫܘܥ',
            dt2: '',
            ar3: '',
            dt1: 'Qomjeschu',
            bedeutung: 'männl., “Jesus ist auferstanden”',
            ar2: 'ܩܳܡܝܶܫܽܘܥ',
            es2: 'ܩܡܝܫܘܥ',
            id: '817',
            dt3: '',
            es3: ''
        },
        {
            dt3: '',
            dt2: '',
            es3: '',
            es2: 'ܦܪܝܕܐ',
            dt1: 'Farida',
            bedeutung: 'weibl., “Hochzeitsgeschenk Geschenk, das der Bräutigam für die Braut kaut”; weitere Formen: Fernitha, Farnita',
            id: '1519',
            ar3: '',
            ar1: 'ܦܪܝܕܐ',
            ar2: 'ܦܰܪܺܝܕܰܐ',
            es1: 'ܦܪܝܕܐ'
        },
        {
            es3: '',
            dt1: 'Reuma',
            ar1: 'ܪܘܡܐ',
            dt3: 'Ruma',
            es2: 'ܪܘܡܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs. Im AT ist sie Nebenfrau Nahors, des Bruders Abrahams und Mutter von vier Söhnen, u.a. des Maacha, Urvaters eines aramäisches Volkes (siehe: Maacha). Weitere Formen: Roma, Ruma',
            dt2: 'Rume',
            ar3: '',
            ar2: 'ܪܽܘܡܰܐ',
            id: '856',
            es1: 'ܪܘܡܐ'
        },
        {
            es1: 'ܐܘܪܗܝ',
            dt3: '',
            dt1: 'Urhoy',
            es2: 'ܐܘܪܗܝ',
            bedeutung: '',
            ar1: 'ܐܘܪܗܝ',
            dt2: '',
            es3: '',
            ar2: 'ܐܽܘܪܗܳܝ',
            ar3: '',
            id: '1090'
        },
        {
            id: '1305',
            dt3: '',
            bedeutung: 'männl., “bekennen”',
            es1: 'ܐܬܪܐ',
            ar1: 'ܐܬܪܐ',
            ar3: '',
            dt2: '',
            dt1: 'Atro',
            es3: '',
            ar2: 'ܐܰܬܪܳܐ',
            es2: 'ܐܬܪܐ'
        },
        {
            es1: 'ܐܪܝܐܝܠ',
            bedeutung: 'männl., “Löwe”. Arjo war ein aramäischer König von Edessa (132-127 v.Chr.) und Begründer der edessenischen Dynastie',
            id: '1279',
            ar1: 'ܐܪܝܐܝܠ',
            es2: 'ܐܪܝܐܝܠ',
            ar2: 'ܐܰܪܺܝܐܝܶܠ',
            dt3: '',
            es3: '',
            ar3: '',
            dt2: '',
            dt1: 'Ariel'
        },
        {
            dt2: '',
            es2: 'ܥܒܘܫܬܐ',
            es3: '',
            ar3: '',
            es1: 'ܥܒܘܫܬܐ',
            bedeutung: 'männl., “Schecke”. Abbuschta (450-498) war ostsyrischer Metropolit von Arbela. Weitere Formen: Abbuschto',
            ar2: 'ܥܰܒܽܘܫܬܰܐ',
            ar1: 'ܥܒܘܫܬܐ',
            dt1: 'Abbuschta',
            id: '1182',
            dt3: ''
        },
        {
            dt1: 'Zaki',
            dt2: '',
            dt3: '',
            ar1: 'ܙܟܝ',
            es3: '',
            id: '2349',
            ar2: 'ܙܰܟܺܝ',
            es1: 'ܙܟܝ',
            ar3: '',
            es2: 'ܙܟܝ',
            bedeutung: ''
        },
        {
            id: '1570',
            es2: 'ܓܕܥܘܢ',
            dt3: '',
            dt2: '',
            ar3: '',
            bedeutung: 'weibl., “Auswanderung, Emigration, Exil”; weitere Formen: Golutha',
            es3: '',
            es1: 'ܓܕܥܘܢ',
            ar2: 'ܓܶܕܥܽܘܢ',
            ar1: 'ܓܕܥܘܢ',
            dt1: 'Gideon'
        },
        {
            ar3: '',
            id: '177',
            ar1: 'ܒܪܢܡܪܐ',
            dt2: '',
            es2: 'ܒܪܢܡܪܐ',
            es3: '',
            bedeutung: 'männl., “Sohn der Leoparden, der Tiger”. Barnemre war ein Hauptgott der Aramäer von Haran. Weitere Formen: Barnemro',
            dt3: '',
            es1: 'ܒܪܢܡܪܐ',
            ar2: 'ܒܰܪܢܶܡܪܳܐ',
            dt1: 'Barnemro'
        },
        {
            ar2: 'ܪܺܝܡܰܐ',
            ar3: '',
            dt3: '',
            id: '2052',
            es3: '',
            dt1: 'Rima',
            ar1: 'ܪܝܡܐ',
            es2: 'ܪܝܡܐ',
            es1: 'ܪܝܡܐ',
            dt2: '',
            bedeutung: 'männl., “Gott ist erhaben”'
        },
        {
            ar3: '',
            dt3: '',
            es1: 'ܢܪܣܝ',
            id: '749',
            ar1: 'ܢܪܣܝ',
            dt2: '',
            ar2: 'ܢܰܪܣܰܝ',
            bedeutung: 'männl., wohl “Wasserspritzer”. Ein Heiliger; weitere Formen: Narsay, Narsaj',
            dt1: 'Narsai',
            es3: '',
            es2: 'ܢܪܣܝ'
        },
        {
            id: '619',
            dt2: '',
            ar1: 'ܠܡܕ',
            dt1: 'Lamed',
            ar2: 'ܠܰܡܶܕ',
            es3: '',
            ar3: '',
            bedeutung: 'männl., “zusammenbringen, koppeln, zusammenfügen, sammeln, versammeln”',
            dt3: '',
            es2: 'ܠܡܕ',
            es1: 'ܠܡܕ'
        },
        {
            bedeutung: '',
            dt1: 'Stephanus',
            es3: '',
            es2: 'ܣܛܝܦܢܘܣ',
            id: '2202',
            dt2: '',
            es1: 'ܣܛܝܦܢܘܣ',
            ar2: 'ܣܛܶܝܦܰܢܽܘܣ',
            dt3: '',
            ar3: '',
            ar1: 'ܣܛܝܦܢܘܣ'
        },
        {
            es1: 'ܩܘܪܒܢܐ',
            ar2: 'ܩܽܘܪܒܰܢܰܐ',
            es2: 'ܩܘܪܒܢܐ',
            ar1: 'ܩܘܪܒܢܐ',
            bedeutung: 'männl., siehe Lobon',
            dt1: 'Korbana',
            id: '1792',
            dt3: '',
            dt2: '',
            es3: '',
            ar3: ''
        },
        {
            es2: 'ܕܬܐ',
            ar2: 'ܕܳܬܰܐ',
            bedeutung: 'männl., “Kummer, Trauer, Trübsal, Traurigkeit”',
            ar3: '',
            ar1: 'ܕܬܐ',
            dt3: '',
            id: '1467',
            dt2: '',
            es1: 'ܕܬܐ',
            dt1: 'Dota',
            es3: ''
        },
        {
            es2: 'ܪܝܚܢܐ',
            ar1: 'ܪܝܚܢܐ',
            es3: '',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gefährtin, Freundin”. Ruth ist die Hauptgestalt des nach Ihr benannten alttestamentlichen Buches und zugleich eine der Stammmütter des jüdischen Königshauses, nach dem NT eine Ahnfrau Jesu. Weitere Formen: Rut, Rohut',
            dt1: 'Rihane',
            es1: 'ܪܝܚܢܐ',
            ar3: '',
            dt2: '',
            dt3: '',
            id: '2051',
            ar2: 'ܪܺܝܚܰܢܶܐ'
        },
        {
            dt1: 'Suros',
            bedeutung: 'männl., “[Der Gott] Rimmon ist gut”. Tabrimmon war ein König von Aram-Damaskus (10. Jh.v.Chr.), Sohn des Königs Hezjon, Vater des Bar-Hadad I. Weitere Formen: Tbaramun',
            dt2: '',
            dt3: '',
            es3: '',
            ar3: '',
            ar1: 'ܣܘܪܘܣ',
            ar2: 'ܣܽܘܪܳܘܣ',
            es1: 'ܣܘܪܘܣ',
            id: '2209',
            es2: 'ܣܘܪܘܣ'
        },
        {
            dt1: 'Ischai',
            ar1: 'ܐܝܫܝ',
            dt2: '',
            dt3: '',
            es2: 'ܐܝܫܝ',
            ar2: 'ܐܺܝܫܰܝ',
            es3: '',
            es1: 'ܐܝܫܝ',
            id: '1694',
            ar3: '',
            bedeutung: 'weibl., “Wesen, Existenz, Dasein”'
        },
        {
            dt2: 'Paul',
            ar3: 'ܦܰܘܠܳܘܣ',
            dt3: '',
            ar2: 'ܦܰܘܠܽܘܣ',
            dt1: 'Paulus',
            es2: 'ܦܘܠܘܣ',
            es3: 'ܦܘܠܘܣ',
            id: '1995',
            bedeutung: 'weibl., Kurzform zu Qyomo oder Qyomto “Aufstand, Auferstehung”; weitere Formen: Qaymi, Qajmi',
            es1: 'ܦܘܠܘܣ',
            ar1: 'ܦܘܠܘܣ'
        },
        {
            bedeutung: 'männl., “Fasten”; weitere Formen: Saume',
            es2: 'ܨܘܡܐ',
            es1: 'ܨܘܡܐ',
            es3: '',
            dt1: 'Saume',
            dt3: '',
            ar3: '',
            ar1: 'ܨܘܡܐ',
            dt2: '',
            id: '915',
            ar2: 'ܨܰܘܡܶܐ'
        },
        {
            dt2: '',
            dt1: 'Schemil',
            ar1: 'ܫܡܐܝܠ',
            ar3: '',
            es1: 'ܫܡܐܝܠ',
            id: '2146',
            es3: '',
            es2: 'ܫܡܐܝܠ',
            bedeutung: 'weibl., “Wohnstätte, Behausung, Rastplatz, Ruhestätte”; weitere Formen: Schkhinta',
            dt3: '',
            ar2: 'ܫܶܡܺܐܝܠ'
        },
        {
            ar1: 'ܫܒܗܪ',
            dt3: '',
            es2: 'ܫܒܗܪ',
            dt2: '',
            es3: '',
            id: '2110',
            ar3: '',
            ar2: 'ܫܰܒܗܰܪ',
            dt1: 'Schabhar',
            es1: 'ܫܒܗܪ',
            bedeutung: 'männl., “du bist (mein) Frieden”; weitere Formen: Schaynat, Schajnat'
        },
        {
            es3: '',
            id: '1404',
            ar3: '',
            ar1: 'ܒܪܘܬܐ',
            ar2: 'ܒܶܪܽܘܬܰܐ',
            dt1: 'Beruta',
            dt2: '',
            es1: 'ܒܪܘܬܐ',
            es2: 'ܒܪܘܬܐ',
            dt3: '',
            bedeutung: 'weibl., “zwischen den Flüssen, Mesopotamien”; weitere Formen: Bethnahro, Bethnahra'
        },
        {
            ar2: 'ܐܶܠܢܰܬܰܢ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott hat gegeben”',
            dt3: '',
            es2: 'ܐܠܢܬܢ',
            dt1: 'Elnatan',
            ar3: '',
            dt2: '',
            es1: 'ܐܠܢܬܢ',
            ar1: 'ܐܠܢܬܢ',
            es3: '',
            id: '309'
        },
        {
            dt1: 'Hadoyuta',
            bedeutung: 'weibl., syrischen Ursprungs; “Wegweisung, Richtung, Leitung, Führung”; weitere Formen: Hadoyutha',
            dt2: '',
            ar1: 'ܗܕܝܘܬܐ',
            id: '419',
            ar2: 'ܗܰܕܝܽܘܬܰܐ',
            dt3: '',
            es1: 'ܗܕܝܘܬܐ',
            ar3: '',
            es3: '',
            es2: 'ܗܕܝܘܬܐ'
        },
        {
            dt3: '',
            ar2: 'ܐܰܪܺܝܡ',
            es3: '',
            es2: 'ܐܪܝܡ',
            dt1: 'Arim',
            ar3: '',
            bedeutung: 'männl., “erhöht, erhaben, verherrlicht”',
            dt2: '',
            id: '100',
            ar1: 'ܐܪܝܡ',
            es1: 'ܐܪܝܡ'
        },
        {
            ar1: 'ܦܩܘܕ',
            dt1: 'Pekod',
            es2: 'ܦܩܘܕ',
            bedeutung: 'männl., “Erhebung, Aufstand, Revolution, Auferstehung, Steigung, Anstieg”; weitere Formen: Qawmo',
            es3: '',
            ar3: '',
            id: '1996',
            dt3: '',
            dt2: '',
            ar2: 'ܦܶܩܳܘܕ',
            es1: 'ܦܩܘܕ'
        },
        {
            es2: 'ܪܟܝܒܐܝܠ',
            es1: 'ܪܟܝܒܐܝܠ',
            ar1: 'ܪܟܝܒܐܝܠ',
            es3: '',
            ar3: '',
            ar2: 'ܪܰܟܺܝܒܺܐܝܠ',
            bedeutung: 'weibl., siehe Rihan',
            dt3: '',
            id: '2030',
            dt1: 'Rakibel',
            dt2: ''
        },
        {
            bedeutung: 'weibl. Form zu Zenobio, Zeno; “Geschenk des Zeus”; die aramäische Königin Zenobia machte Palmyra/Tadmor in Syrien im 3. Jh. (267-272) zu einem Zentrum der aramäischen Kultur. Weitere Formen: Zenab, Zeyneb(e)',
            es3: '',
            id: '2350',
            es1: 'ܙܟܝܪ',
            dt3: '',
            es2: 'ܙܟܝܪ',
            dt2: '',
            dt1: 'Zakir',
            ar2: 'ܙܰܟܺܝܪ',
            ar3: '',
            ar1: 'ܙܟܝܪ'
        },
        {
            es2: 'ܚܒܘܫܬܐ',
            ar2: 'ܚܰܒܽܘܫܬܰܐ',
            dt1: 'Habuschta',
            ar3: '',
            es1: 'ܚܒܘܫܬܐ',
            es3: '',
            dt3: '',
            id: '410',
            ar1: 'ܚܒܘܫܬܐ',
            bedeutung: 'weibl., von habuscha: „Apfel“',
            dt2: ''
        },
        {
            bedeutung: 'männl., “Frühling”; weitere Formen: Tadwo',
            ar2: 'ܬܰܕܐܳܐ',
            dt1: 'Tadoo',
            es1: 'ܬܕܐܐ',
            dt3: '',
            dt2: 'Tadwo',
            es3: '',
            ar1: 'ܬܕܐܐ',
            id: '1028',
            es2: 'ܬܕܐܐ',
            ar3: ''
        },
        {
            ar3: '',
            es1: 'ܢܝܩܠܐܘܤ ܐܛܫ ܒܝܬ ܒܫܫܐ',
            dt1: 'Nikolas Atash beth bshosho',
            dt3: '',
            dt2: '',
            bedeutung: '',
            id: '1177',
            es2: 'ܢܝܩܠܐܘܤ ܐܛܫ ܒܝܬ ܒܫܫܐ',
            ar1: 'ܢܝܩܠܐܘܤ ܐܛܫ ܒܝܬ ܒܫܫܐ',
            ar2: 'ܢܺܩܳܠܰܐܘܣ ܐܰܛܰܫ ܒܶܝܬ݂ ܒܫܳܫܳܐ',
            es3: ''
        },
        {
            ar2: 'ܐܶܠܺܝܳܐ',
            ar1: 'ܐܠܝܐ',
            dt3: 'Elija',
            ar3: '',
            dt1: 'Elijo',
            es2: 'ܐܠܝܐ',
            es1: 'ܐܠܝܐ',
            id: '300',
            dt2: 'Elias',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “mein Gott ist Jahwe”. Im AT ist Elijo ein Prophet (um 850 v.Chr.). Weitere Formen: Elija, Elias, Ilijo, Elia',
            es3: ''
        },
        {
            dt3: '',
            ar1: 'ܪܥܘܬ',
            es3: '',
            dt1: 'Ruth',
            ar3: '',
            bedeutung: 'männl., “Hoffnung”, auch “Aloe”; weitere Formen: Saabro',
            dt2: '',
            ar2: 'ܪܳܥܽܘܬ',
            es1: 'ܪܥܘܬ',
            id: '2065',
            es2: 'ܪܥܘܬ'
        },
        {
            es1: 'ܐܕܘܢܝܐ',
            id: '1216',
            dt3: '',
            es2: 'ܐܕܘܢܝܐ',
            ar3: '',
            dt1: 'Adonija',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Herr ist Jahwe”. Im AT ist Adonija der vierte Sohn Davids und seiner Nebenfrau Haggit',
            ar2: 'ܐܰܕܳܘܢܝܰܐ',
            es3: '',
            ar1: 'ܐܕܘܢܝܐ',
            dt2: ''
        },
        {
            dt3: '',
            dt1: 'Ferjal',
            es3: '',
            ar2: 'ܦܶܪܝܰܐܠ',
            es1: 'ܦܪܝܐܠ',
            dt2: 'Feryal',
            es2: 'ܦܪܝܐܠ',
            ar3: '',
            ar1: 'ܦܪܝܐܠ',
            bedeutung: '',
            id: '1525'
        },
        {
            dt2: '',
            es3: '',
            ar1: 'ܫܡܠܝ',
            ar2: 'ܫܰܡܠܺܝ',
            ar3: '',
            es1: 'ܫܡܠܝ',
            id: '939',
            dt1: 'Schamli',
            bedeutung: 'männl., “beenden, vollenden”',
            es2: 'ܫܡܠܝ',
            dt3: ''
        },
        {
            dt2: '',
            ar2: 'ܪܚܺܝܡܳܐ',
            id: '857',
            dt1: 'Rhimo',
            ar3: '',
            es3: '',
            bedeutung: 'männl., “der Geliebte, Liebling”. Rhimo (430-450) war ostsyrischer Metropolit von Arbela. Weitere Formen: Rhima',
            dt3: '',
            es2: 'ܪܚܝܡܐ',
            ar1: 'ܪܚܝܡܐ',
            es1: 'ܪܚܝܡܐ'
        },
        {
            dt1: 'Baselius',
            id: '1375',
            es2: 'ܒܣܝܠܝܘܣ',
            es3: '',
            ar3: '',
            dt3: 'Basilius',
            ar1: 'ܒܣܝܠܝܘܣ',
            dt2: 'Basselius',
            es1: 'ܒܣܝܠܝܘܣ',
            bedeutung: 'männl., “lecker, süß, schmackhaft, nett”',
            ar2: 'ܒܰܣܶܠܠܺܝܽܘܣ'
        },
        {
            ar2: 'ܒܰܚܺܝܪܰܐ',
            dt1: 'Bahira',
            es1: 'ܒܚܝܪܐ',
            es2: 'ܒܚܝܪܐ',
            dt2: '',
            bedeutung: 'männl., von bhiro: “Sachkundiger, Fachmann, Experte, Geprüfter”. Bahira war ein Mönch, der nach einer Geschichte/Tradition den “Propheten” Mohammed unterwiesen haben soll',
            dt3: '',
            es3: 'ܒܗܝܪܐ',
            ar1: 'ܒܚܝܪܐ',
            id: '141',
            ar3: 'ܒܰܗܺܝܪܰܐ'
        },
        {
            ar2: 'ܚܬܘܢ',
            ar1: 'ܗܬܘܢ',
            id: '1641',
            es2: 'ܗܬܘܢ',
            bedeutung: 'männl., griechischen Ursprungs; “ein Musikinstrument, Wasserorgel”',
            es3: 'ܚܬܘܢ',
            dt1: 'Hatun',
            ar3: 'ܚܰܬܽܘܢ',
            dt3: '',
            es1: 'ܗܬܘܢ',
            dt2: ''
        },
        {
            id: '722',
            es2: 'ܡܫܝܚܙܟ݂ܐ',
            es1: 'ܡܫܝܚܙܟ݂ܐ',
            ar1: 'ܡܫܝܚܙܟ݂ܐ',
            dt3: '',
            ar3: 'ܡܫܺܝܚܳܐ ܙܟ݂ܳܐ',
            bedeutung: 'männl. “Christus hat gesiegt. Mschihozkho (†6.Jh.) war ein ostsyrisch-nestorianischer Kirchenhistoriker Weitere Formen: Mschijazkha',
            ar2: 'ܡܫܺܝܚܳܙܟ݂ܳܐ',
            dt2: '',
            es3: 'ܡܫܝܚܐ ܙܟ݂ܐ',
            dt1: 'Mschihozkho'
        },
        {
            es3: '',
            id: '1422',
            dt3: '',
            es1: 'ܓܐܢ ܐܝܫܠܪ',
            es2: 'ܓܐܢ ܐܝܫܠܪ',
            dt1: 'Can Isler',
            bedeutung: '',
            dt2: '',
            ar3: '',
            ar2: 'ܓܰܐܢ ܐܺܝܫܠܶܪ',
            ar1: 'ܓܐܢ ܐܝܫܠܪ'
        },
        {
            es1: 'ܒܪܐܝܠ',
            es3: '',
            dt3: '',
            dt1: 'Baril',
            dt2: 'Bar-Il',
            id: '168',
            es2: 'ܒܪܐܝܠ',
            ar1: 'ܒܪܐܝܠ',
            ar3: '',
            bedeutung: 'männl., “Sohn des Gottes, Gottessohn”',
            ar2: 'ܒܰܪܐܺܝܠ'
        },
        {
            id: '456',
            dt2: '',
            ar1: 'ܚܘܫܐ',
            es2: 'ܚܘܫܐ',
            es1: 'ܚܘܫܐ',
            es3: '',
            bedeutung: '',
            dt3: '',
            ar3: '',
            dt1: 'Hawsho',
            ar2: 'ܚܰܘܫܳܐ'
        },
        {
            ar2: 'ܩܶܢܢܶܫܪܺܝܢ',
            id: '2006',
            bedeutung: 'männl., “Schafhirte, Hirte, Schäfer oder mein Herr, mein Lehrer”; weitere Formen: Rabula, Rabuli',
            es2: 'ܩܢܢܫܪܝܢ',
            es3: '',
            ar3: '',
            dt2: '',
            ar1: 'ܩܢܢܫܪܝܢ',
            dt3: '',
            es1: 'ܩܢܢܫܪܝܢ',
            dt1: 'Qenneschrin'
        },
        {
            es2: 'ܛܘܒܝܐܝܠ',
            dt2: '',
            ar2: 'ܛܳܘܒܺܝܐܝܶܠ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott ist gut”. Im AT ist Tobiel ein Naftaliter, Vater des Tobit und Großvater des Tobia',
            ar3: '',
            dt1: 'Tobiel',
            id: '1065',
            es3: '',
            ar1: 'ܛܘܒܝܐܝܠ',
            es1: 'ܛܘܒܝܐܝܠ',
            dt3: ''
        },
        {
            ar2: 'ܣܛܶܦܰܐܢ',
            id: '2201',
            dt3: '',
            ar1: 'ܣܛܦܐܢ',
            ar3: 'ܣܛܶܝܦܰܐܢ',
            bedeutung: 'männl., ”Syrer, Christ“',
            es1: 'ܣܛܦܐܢ',
            dt2: 'Stephan',
            dt1: 'Stefan',
            es2: 'ܣܛܦܐܢ',
            es3: 'ܣܛܝܦܐܢ'
        },
        {
            ar3: '',
            es3: '',
            dt2: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe ist Vater”. Im AT ist Joab Sohn Zerujas, Bruder des Abischai und des Asael, Neffe Davids. Weitere Formen: Juob',
            es1: 'ܝܫܘܥܣܒܪܢ',
            ar1: 'ܝܫܘܥܣܒܪܢ',
            id: '1729',
            es2: 'ܝܫܘܥܣܒܪܢ',
            ar2: 'ܝܶܫܽܘܥܣܰܒܪܰܢ',
            dt3: '',
            dt1: 'Jeschusabran'
        },
        {
            ar2: 'ܐܰܟܺܝܦܰܐ',
            id: '66',
            dt3: '',
            ar1: 'ܐܟܝܦܐ',
            es1: 'ܐܟܝܦܐ',
            dt2: 'Akif',
            es2: 'ܐܟܝܦܐ',
            dt1: 'Akifa',
            ar3: '',
            bedeutung: 'männl., “ﬂeißig, eifrig”',
            es3: ''
        },
        {
            ar3: 'ܐܶܫܰܥܝܰܐ',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott sieht dich an“; weitere Formen: Aseka, Eseka, Eseca',
            es3: 'ܐܫܥܝܐ',
            dt2: '',
            ar2: 'ܐܶܫܰܥܝܳܐ',
            id: '1724',
            es2: 'ܐܫܥܐ',
            dt1: 'Jesaja',
            es1: 'ܐܫܥܐ',
            ar1: 'ܐܫܥܝܐ',
            dt3: ''
        },
        {
            es1: 'ܚܒܩܘܩ',
            dt1: 'Habakuk',
            id: '399',
            ar3: 'ܚܰܒܩܽܘܩ',
            dt3: '',
            es2: 'ܚܒܩܘܩ',
            ar2: 'ܚܰܒܰܩܽܘܩ',
            es3: 'ܚܒܩܘܩ',
            dt2: '',
            ar1: 'ܚܒܩܘܩ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Minze, Basilikum”. Im AT ist Habakuk ein Prophet (um 600 v. Chr.) und Verfasser eines alttestamentlichen Buches. Weitere Formen: Habkuk, Habquq'
        },
        {
            es1: 'ܣܝܕܐ',
            bedeutung: 'weibl., ursprünglich männl. von saido “Kalk, Gips, Pﬂaster”; weitere Formen: Saido, Saydo, Sayde ',
            es2: 'ܣܝܕܐ',
            dt1: 'Saide',
            dt3: '',
            dt2: 'Sayde',
            ar2: 'ܣܰܝܕܶܐ',
            ar3: '',
            id: '893',
            ar1: 'ܣܝܕܐ',
            es3: ''
        },
        {
            bedeutung: 'männl., “Jesus ist unsere Hoffnung”',
            dt2: '',
            ar3: '',
            es1: 'ܝܫܘܥܣܒܪܢ',
            dt1: 'Jeschusabran',
            id: '542',
            dt3: '',
            ar2: 'ܝܶܫܽܘܥܣܰܒܪܰܢ',
            es3: '',
            es2: 'ܝܫܘܥܣܒܪܢ',
            ar1: 'ܝܫܘܥܣܒܪܢ'
        },
        {
            dt1: 'Gallo',
            dt2: '',
            es3: 'ܓܠܠܐ',
            dt3: '',
            ar2: 'ܓܰܠܳܐ',
            id: '1550',
            es2: 'ܓܠܐ',
            bedeutung: 'Nebenform zu Gabriel',
            ar1: 'ܓܠܐ',
            ar3: 'ܓܰܠܠܳܐ',
            es1: 'ܓܠܐ'
        },
        {
            ar1: 'ܣܡ',
            dt3: '',
            id: '2089',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Fürstin, Prinzessin, Herrin“. Im AT ist Sara die Frau Abrahams und Mutter Isaaks. Weitere Formen: Sarah, Sarai, Sare, Saro; sehr beliebt bei den Aramäern',
            ar3: 'ܫܺܝܡ',
            es1: 'ܣܡ',
            dt2: '',
            es2: 'ܣܡ',
            ar2: 'ܫܶܡ',
            dt1: 'Sam',
            es3: 'ܫܝܡ'
        },
        {
            bedeutung: 'männl., “mein Leben”',
            ar1: 'ܛܘܒܢ',
            dt3: '',
            ar3: '',
            id: '2267',
            dt2: '',
            dt1: 'Tuban',
            es1: 'ܛܘܒܢܐ',
            es2: 'ܛܘܒܢܐ',
            ar2: 'ܛܽܘܒܰܢ',
            es3: ''
        },
        {
            es1: 'ܬܗܪܬ',
            dt1: 'Tehrat',
            es3: '',
            ar2: 'ܬܶܗܪܰܬ',
            ar3: '',
            id: '2236',
            es2: 'ܬܗܪܬ',
            dt2: '',
            ar1: 'ܬܗܪܬ',
            dt3: '',
            bedeutung: 'männl., siehe Thomas'
        },
        {
            bedeutung: 'männl., “Sohn des Flusses Daisan”. Bardaisan (†222) war Astrologe und Hymnendichter. Später wurden seine Hymnen von Ephram bekämpft. Weitere Formen: Bardaison, Bardaysan, Bardayson',
            es1: 'ܒܪܐܬ',
            es2: 'ܒܪܐܬ',
            dt2: '',
            dt3: '',
            dt1: 'Barat',
            ar2: 'ܒܰܪܐܰܬ',
            ar1: 'ܒܪܐܬ',
            es3: '',
            ar3: '',
            id: '1339'
        },
        {
            ar3: '',
            es1: 'ܒܢܚܐܝܠ',
            ar2: 'ܒܶܢܚܰܐܝܺܠ',
            id: '218',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Sohn des Hajil” oder “Sohn der Macht, der Tapfere”; weitere Formen: Ben-Hajlo, Ben-Hajla, Bar-Hajlo, Bar-Hajla',
            es2: 'ܒܢܚܐܝܠ',
            ar1: 'ܒܢܚܐܝܠ',
            dt3: '',
            es3: '',
            dt2: '',
            dt1: 'Ben Hajil'
        },
        {
            bedeutung: 'männl., ”beschließen“. Karar war König von Guzana (9. Jh. v. Chr.). Weitere Formen: Qarar',
            ar2: 'ܩܰܪܰܪ',
            dt2: '',
            ar1: 'ܩܪܪ',
            id: '578',
            dt1: 'Karar',
            es2: 'ܩܪܪ',
            es1: 'ܩܪܪ',
            ar3: 'ܟܰܪܰܪ',
            dt3: '',
            es3: 'ܟܪܪ'
        },
        {
            dt1: 'Nahir',
            es1: 'ܢܗܝܪ',
            dt3: '',
            ar3: '',
            ar1: 'ܢܗܝܪ',
            dt2: 'Nahiro',
            bedeutung: 'männl., syrischen Ursprungs; “strahlend, leuchtend”; weitere Formen: Nahiro',
            es2: 'ܢܗܝܪ',
            ar2: 'ܢܰܗܺܝܪ',
            es3: '',
            id: '736'
        },
        {
            es1: 'ܛܝܡܘܬܐ',
            ar2: 'ܛܰܝܒܽܘܬܰܐ',
            dt3: '',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “die Anmutige” oder “Gefallen”; weitere Formen, Terzo, Tarso, Theresa, Tirzah',
            es3: '',
            id: '2222',
            es2: 'ܛܝܡܘܬܐ',
            dt1: 'Taibuta',
            ar3: '',
            dt2: '',
            ar1: 'ܛܝܒܘܬܐ'
        },
        {
            dt2: '',
            dt3: '',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “marah: bitter, betrübt“, ”Gebieterin, Herrin”. Im NT ist Martha die Schwester der Maria und des Lazarus von Betanien und wurde die Patronin der Hausfrauen. Weitere Formen: Marta, Morto; sehr beliebter Vorname bei den Aramäern',
            ar1: 'ܡܪܝ',
            id: '1855',
            dt1: 'Mari',
            es1: 'ܡܪܝ',
            es3: '',
            ar2: 'ܡܰܪܺܝ',
            es2: 'ܡܪܝ',
            ar3: ''
        },
        {
            dt1: 'Schalyo',
            id: '2125',
            ar3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäischen Ursprungs; “der Erbetene”. Im AT ist Saul Sohn von Kisch und wird von Samuel zum ersten König von Israel (1030 - 1010 v. Chr.) gesalbt. Weitere Formen: Schowol, Schaul, Schawol, Saul',
            es1: 'ܫܠܝܐ',
            dt2: '',
            ar1: 'ܫܠܝܐ',
            es3: '',
            ar2: 'ܫܰܠܝܳܐ',
            dt3: '',
            es2: 'ܫܠܝܐ'
        },
        {
            es2: 'ܟܐܢܬܐ',
            ar2: 'ܟܺܐܢܬܰܐ',
            id: '599',
            dt3: '',
            es1: 'ܟܐܢܬܐ',
            bedeutung: 'weibl. Form zu Kino',
            ar3: '',
            es3: '',
            dt1: 'Kinta',
            ar1: 'ܟܐܢܬܐ',
            dt2: ''
        },
        {
            bedeutung: 'männl., “Sohn des Jahres”',
            ar2: 'ܒܰܪܨܰܠܺܝܒܺܝ',
            dt2: '',
            id: '1364',
            dt1: 'Barsalibi',
            ar1: 'ܒܪܨܠܝܒܝ',
            ar3: 'ܒܰܪܨܠܺܝܒܳܐ',
            dt3: '',
            es1: 'ܒܪܨܠܝܒܝ',
            es2: 'ܒܪܨܠܝܒܝ',
            es3: 'ܒܪܨܠܝܒܐ'
        },
        {
            dt1: 'Thoma',
            bedeutung: 'männl., aus dem lateinischen “titulusi Ruhm, Ansehen, Verdienst”; Titus, ein Heidenchrist aus dem syrischen Antiochia am Orontes, gilt im NT als ein Mitarbeiter des Apostels Paulus. Paulus adressierte an ihn einen neutestamentl. Brief, den “Titusbrief”',
            es1: 'ܬܐܘܡܐ',
            ar2: 'ܬܳܐܘܡܰܐ',
            es3: '',
            ar1: 'ܬܐܘܡܐ',
            es2: 'ܬܐܘܡܐ',
            dt3: '',
            ar3: '',
            dt2: '',
            id: '2245'
        },
        {
            id: '942',
            ar2: 'ܫܰܐܘܳܠ',
            es3: 'ܫܐܘܠ',
            es1: 'ܫܐܘܠ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäischen Ursprungs; “der Erbetene”. Im AT ist Saul Sohn von Kisch und wird von Samuel zum ersten König von Israel (1030 - 1010 v. Chr.) gesalbt. Weitere Formen: Schowol, Schaul, Schawol, Saul',
            es2: 'ܫܐܘܠ',
            dt2: 'Saul',
            dt3: '',
            ar1: 'ܫܐܘܠ',
            dt1: 'Schaol',
            ar3: 'ܫܰܐܘܽܠ'
        },
        {
            ar3: '',
            id: '882',
            es2: 'ܣܒܪܐ',
            es1: 'ܣܒܪܐ',
            es3: '',
            dt3: '',
            ar1: 'ܣܒܪܐ',
            ar2: 'ܣܰܒܪܳܐ',
            bedeutung: 'männl., “Hoffnung”, auch “Aloe”; weitere Formen: Saabro',
            dt2: '',
            dt1: 'Sabro'
        },
        {
            ar3: '',
            es1: 'ܒܪܘܬܐ',
            dt3: '',
            bedeutung: 'weibl., “Zypresse”; weitere Formen: Brutho',
            id: '224',
            ar1: 'ܒܪܘܬܐ',
            dt2: '',
            dt1: 'Beruta',
            es2: 'ܒܪܘܬܐ',
            es3: '',
            ar2: 'ܒܶܪܽܘܬܰܐ'
        },
        {
            ar3: '',
            dt1: 'Yause',
            bedeutung: 'männl., siehe Joseph',
            ar1: 'ܝܘܣܐ',
            es1: 'ܝܘܣܐ',
            id: '1132',
            dt3: '',
            dt2: '',
            es2: 'ܝܘܣܐ',
            es3: '',
            ar2: 'ܝܰܘܣܶܐ'
        },
        {
            es3: 'ܣܛܝܦܐܢ',
            id: '1009',
            dt2: 'Stephan',
            es2: 'ܣܛܦܐܢ',
            ar2: 'ܣܛܶܦܰܐܢ',
            ar1: 'ܣܛܦܐܢ',
            dt1: 'Stefan',
            es1: 'ܣܛܦܐܢ',
            ar3: 'ܣܛܶܝܦܰܐܢ',
            dt3: '',
            bedeutung: 'männl., Nebenform zu Stephanus'
        },
        {
            ar1: 'ܗܒܐܝܠ',
            id: '1591',
            dt1: 'Habil',
            dt2: '',
            es1: 'ܗܒܐܝܠ',
            dt3: '',
            es2: 'ܗܒܐܝܠ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Hadad ist Hilfe”, “Hadad hilft (hat geholfen)”. Hadadeser (11. Jh. V. Chr.) war Sohn des Rehob, des aramäischen Königs von Aram-Zoba (Libanon). Weitere Formen: Hadadezer',
            es3: '',
            ar2: 'ܗܰܒܐܝܺܠ',
            ar3: ''
        },
        {
            ar3: '',
            es2: 'ܦܝܟܬܪܐܝܐ',
            dt2: 'Viktoria',
            dt3: '',
            dt1: 'Victoria',
            ar1: 'ܦܝܟܬܪܝܐ',
            es1: 'ܦܝܟܬܪܐܝܐ',
            ar2: 'ܦܺܝܟܬܳܪܝܰܐ',
            es3: '',
            id: '1101',
            bedeutung: ''
        },
        {
            dt2: '',
            ar1: 'ܝܡܬ',
            dt1: 'Yamat',
            es2: 'ܝܡܬ',
            es1: 'ܝܡܬ',
            ar2: 'ܝܰܡܰܬ',
            id: '2317',
            es3: 'ܝܡܐܬ',
            ar3: 'ܝܰܡܐܰܬ',
            dt3: '',
            bedeutung: 'weibl., “Schönheit, Anmut, Grazie, Würde, Wohlgestalt, Schicklichkeit”; weitere Formen: Yoyuta, Yo-yutha, Yayutha'
        },
        {
            es2: 'ܥܒܕܐܠܚܕ',
            id: '10',
            dt3: '',
            es1: 'ܥܒܕܐܠܚܕ',
            ar3: '',
            dt2: '',
            bedeutung: 'männl., syrisch-arabischen Ursprungs; “Knecht des Sonntags”;  weitere Formen: Ablahad',
            ar2: 'ܥܰܒܶܕܰܐܠܰܚܰܕ',
            es3: '',
            dt1: 'Abdullahad',
            ar1: 'ܥܒܕܐܠܚܕ'
        },
        {
            ar3: '',
            id: '936',
            es3: '',
            dt2: '',
            es1: 'ܫܡܫ',
            dt3: '',
            ar1: 'ܫܡܫ',
            es2: 'ܫܡܫ',
            dt1: 'Schamasch',
            ar2: 'ܫܰܡܰܫ',
            bedeutung: 'männl., “Sonne”. Schamasch war ein von den heidnischen Aramäern verehrter Sonnengott (und Gott des Rechts)'
        },
        {
            ar1: 'ܬܘܥ',
            es3: 'ܬܥܝ',
            es1: 'ܬܘܥ',
            id: '1071',
            bedeutung: 'männl., König von Hamat (11. Jh. v.Chr.); Vater von Hadoram (Adoniram). Weitere Formen: Tou, Tohi, Tohu',
            dt1: 'Toi',
            dt2: '',
            ar3: 'ܬܳܥܺܝ',
            ar2: 'ܬܳܥܽܘ',
            es2: 'ܬܘܥ',
            dt3: ''
        },
        {
            ar1: 'ܓܒܪ',
            ar3: 'ܓܰܐܒܰܪ',
            es1: 'ܓܒܪ',
            bedeutung: '',
            es3: 'ܓܐܒܪ',
            dt1: 'Gabor',
            dt2: '',
            id: '355',
            ar2: 'ܓܰܒܳܪ',
            es2: 'ܓܒܪ',
            dt3: ''
        },
        {
            ar1: 'ܒܝܠܥܐ',
            dt1: 'Bilha',
            es3: 'ܒܝܠܥܐܢ',
            id: '230',
            ar3: 'ܒܺܝܠܥܰܐܢ',
            dt2: 'Bilhan',
            dt3: '',
            bedeutung: 'weibl., “Schluck, Trunk”, aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs. Im AT ist Bilha Magd des aramäischen Laban und seiner Tochter Rahel, Nebenfrau Jakobs. Weitere Formen: Bilhan',
            ar2: 'ܒܺܝܠܥܰܐ',
            es2: 'ܒܝܠܥܐ',
            es1: 'ܒܝܠܥܐ'
        },
        {
            es1: 'ܒܗܪܬ',
            ar3: '',
            dt3: '',
            ar2: 'ܒܰܗܪܰܬ',
            ar1: 'ܒܗܪܬ',
            id: '1323',
            dt1: 'Bahrat',
            es3: '',
            bedeutung: '',
            es2: 'ܒܗܪܬ',
            dt2: ''
        },
        {
            ar3: '',
            dt2: '',
            bedeutung: 'männl., “Sohn des Gerichtes, Sohn des Urteils”',
            ar1: 'ܒܪܕܝܢܐ',
            es3: '',
            dt1: 'Bardino',
            es1: 'ܒܪܕܝܢܐ',
            ar2: 'ܒܰܪܕܝܺܢܳܐ',
            id: '162',
            es2: 'ܒܪܕܝܢܐ',
            dt3: ''
        },
        {
            es3: '',
            es2: 'ܣܪܘܓ',
            es1: 'ܣܪܘܓ',
            dt1: 'Sarug',
            bedeutung: 'weibl., “schön, schöne Frau, schönes Mädchen”',
            ar3: '',
            id: '2105',
            ar2: 'ܣܰܪܽܘܓ',
            dt3: '',
            ar1: 'ܣܪܘܓ',
            dt2: ''
        },
        {
            ar1: 'ܒܪܥܐܕܐ',
            ar3: '',
            id: '167',
            dt1: 'Bar-Ido',
            ar2: 'ܒܰܪܥܺܐܕܳܐ',
            dt3: '',
            es1: 'ܒܪܥܐܕܐ',
            bedeutung: 'männl., “Sohn des Festes”',
            es3: '',
            es2: 'ܒܪܥܐܕܐ',
            dt2: 'Barido'
        },
        {
            dt2: '',
            bedeutung: 'männl., “Sohn des Fisches”',
            dt3: '',
            dt1: 'Barnun',
            ar1: 'ܒܪܢܘܢ',
            es1: 'ܒܪܢܘܢ',
            es3: '',
            id: '179',
            es2: 'ܒܪܢܘܢ',
            ar2: 'ܒܰܪܢܽܘܢ',
            ar3: ''
        },
        {
            id: '143',
            ar2: 'ܒܰܗܪܰܬ',
            dt3: '',
            bedeutung: 'männl., “du bist mein Licht”',
            dt1: 'Bahrat',
            es3: '',
            es2: 'ܒܗܪܬ',
            dt2: '',
            es1: 'ܒܗܪܬ',
            ar1: 'ܒܗܪܬ',
            ar3: ''
        },
        {
            ar3: '',
            dt3: '',
            bedeutung: 'männl., “der Herr hat gegeben” oder “gib, o Herr“. Einer von zwei Boten, die Abgar zu Jesus gesendet hat. Weitere Formen: Marjahb, Marjab, Maryab, Marihab',
            ar1: 'ܡܪܝܢܟܐ',
            id: '1860',
            dt1: 'Marinko',
            es1: 'ܡܪܝܢܟܐ',
            dt2: '',
            es2: 'ܡܪܝܢܟܐ',
            es3: '',
            ar2: 'ܡܰܪܺܝܢܟܳܐ               '
        },
        {
            dt2: '',
            es1: 'ܠܒܒ',
            ar3: '',
            ar1: 'ܠܒܒ',
            dt3: '',
            id: '614',
            bedeutung: 'männl., “trösten, anregen, bemutigen, anmutigen”',
            es2: 'ܠܒܒ',
            ar2: 'ܠܰܒܶܒ',
            dt1: 'Labeb',
            es3: ''
        },
        {
            es3: '',
            ar3: '',
            es1: 'ܢܥܝܡܐ',
            dt2: 'Nayma',
            es2: 'ܢܥܝܡܐ',
            ar2: 'ܢܰܥܺܝܡܰܐ',
            id: '1933',
            dt1: 'Naima',
            bedeutung: 'weibl., abgeleitet von Neschmo - Neschma “Atem, Atmung”',
            dt3: '',
            ar1: 'ܢܥܝܡܐ'
        },
        {
            ar2: 'ܐܺܝܪܰܕ',
            dt2: '',
            id: '503',
            es3: '',
            es2: 'ܐܝܪܕ',
            dt1: 'Irad',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs. Im AT ist Irad Sohn Henochs',
            dt3: '',
            ar3: '',
            es1: 'ܐܝܪܕ',
            ar1: 'ܐܝܪܕ'
        },
        {
            es1: 'ܠܝܐ',
            ar2: 'ܠܶܝܰܐ',
            es2: 'ܠܝܐ',
            ar1: 'ܠܝܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “der Weiße”. Im AT ist Lobon Sohn des Betuèl, des Sohnes Nahors, des Aramäers, Bruder der Rebekka, Onkel des Jakob. Lobon ist zugleıch Vater von Lea und Rahel, den beiden Frauen Jakobs. Weitere Formen: Laban',
            dt1: 'Lea',
            dt2: '',
            id: '1814',
            ar3: '',
            es3: '',
            dt3: ''
        },
        {
            bedeutung: 'weibl., syrischen Ursprungs; “Gerechtigkeit, Rechtschaffenheit”; weitere Formen: Kinuta',
            dt3: '',
            ar3: '',
            ar1: 'ܟܢܥܢ',
            es3: '',
            es2: 'ܟܢܥܢ',
            dt2: '',
            es1: 'ܟܢܥܢ',
            dt1: 'Kenan',
            id: '1779',
            ar2: 'ܟܶܢܥܰܢ'
        },
        {
            es2: 'ܐܠܝܫܒܥ',
            id: '303',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gottesverehrerin, mein Gott ist Fü11e, Vollkommenheit”. Im AT ist Elischbah u.a. Frau Aarons; im NT ist Elischbah Frau des Priesters Zacharias und Mutter von Johannes dem Täufer. Weitere Formen: Elisabeth, Elisabet',
            dt2: 'Elishbah',
            es3: '',
            ar2: 'ܐܶܠܺܝܫܒܰܥ',
            dt3: '',
            ar3: '',
            es1: 'ܐܠܝܫܒܥ',
            ar1: 'ܐܠܝܫܒܥ',
            dt1: 'Elisabeth'
        },
        {
            dt1: 'Mosch',
            es1: 'ܡܫ',
            dt3: '',
            es2: 'ܡܫ',
            es3: '',
            ar3: '',
            dt2: '',
            ar2: 'ܡܳܫ',
            bedeutung: '',
            id: '1908',
            ar1: 'ܡܫ'
        },
        {
            bedeutung: 'siehe Schamiram',
            es2: 'ܫܠܝܛܐ',
            dt1: 'Schalito',
            es3: '',
            es1: 'ܫܠܝܛܐ',
            ar2: 'ܫܰܠܺܝܛܳܐ',
            dt2: '',
            ar1: 'ܫܠܝܛܐ',
            dt3: '',
            ar3: '',
            id: '2123'
        },
        {
            dt3: '',
            es1: 'ܡܬܝܢܐ',
            ar1: 'ܡܬܝܢܐ',
            ar3: 'ܡܰܬܺܝܢܰܐ',
            dt1: 'Martina',
            ar2: 'ܡܪܬܝܢܐ',
            es2: 'ܡܬܝܢܐ',
            id: '678',
            es3: 'ܡܬܝܢܐ',
            bedeutung: '',
            dt2: 'Matina'
        },
        {
            dt3: '',
            ar2: 'ܕܰܪܳܐ',
            es3: '',
            id: '262',
            bedeutung: 'männl., “Streit, Debatte, Kampf, Zwist”',
            dt2: '',
            ar1: 'ܕܪܐ',
            dt1: 'Daro',
            es2: 'ܕܪܐ',
            ar3: '',
            es1: 'ܕܪܐ'
        },
        {
            es1: 'ܙܝܠܐ',
            es2: 'ܙܝܠܐ',
            ar2: 'ܙܺܝܠܰܐ',
            bedeutung: '',
            ar1: 'ܙܝܠܐ',
            dt1: 'Zilla',
            ar3: '',
            es3: '',
            dt2: '',
            id: '2362',
            dt3: ''
        },
        {
            ar1: 'ܢܗܪܐ',
            id: '739',
            ar3: '',
            ar2: 'ܢܰܗܪܳܐ',
            dt2: '',
            bedeutung: 'männl., syrischen Ursprungs; “Fluss, Strom”',
            es3: '',
            es1: 'ܢܗܪܐ',
            dt1: 'Nahro',
            dt3: '',
            es2: 'ܢܗܪܐ'
        },
        {
            dt1: 'Fuad',
            es1: 'ܦܘܐܕ',
            es2: 'ܦܘܐܕ',
            es3: '',
            id: '1535',
            dt3: '',
            dt2: 'Fuat',
            ar1: 'ܦܘܐܕ',
            ar2: 'ܦܽܘܐܰܕ',
            ar3: '',
            bedeutung: ''
        },
        {
            bedeutung: '',
            dt3: '',
            es2: 'ܡܫ',
            ar2: 'ܡܰܫ',
            ar3: '',
            es1: 'ܡܫ',
            es3: '',
            id: '1872',
            ar1: 'ܡܫ',
            dt1: 'Masch',
            dt2: ''
        },
        {
            dt1: 'Sugita',
            dt3: '',
            es3: '',
            ar3: '',
            es2: 'ܣܘܓܝܬܐ',
            bedeutung: 'weibl., “Lied, Lobgesang”',
            es1: 'ܣܘܓܝܬܐ',
            ar1: 'ܣܘܓܝܬܐ',
            ar2: 'ܣܽܘܓܺܝܬܰܐ',
            dt2: '',
            id: '1012'
        },
        {
            ar1: 'ܓܐܪܐ',
            dt1: 'Gero',
            dt2: '',
            ar2: 'ܓܶܪܳܐ',
            bedeutung: 'männl., aus dem Griechischen übernommener Vorname; “der Landmann/Landwirt”. Mor Gewargis gilt in der Geschichte als Drachentöter und Schutzpatron der Waffenschmiede, Krieger und Landleute, einer der 14 Nothelfer. Weitere Formen: Gewargi, Georg, George, Corc, Circis',
            dt3: '',
            es2: 'ܓܐܪܐ',
            id: '1564',
            ar3: '',
            es1: 'ܓܐܪܐ',
            es3: ''
        },
        {
            ar1: 'ܨܒܬܐ',
            es1: 'ܨܒܬܐ',
            ar2: 'ܨܶܒܬܳܐ',
            bedeutung: 'männl., “Ornament, Dekoration, Schönheit”; weitere Formen: Sebtho',
            dt3: '',
            es2: 'ܨܒܬܐ',
            dt2: '',
            id: '982',
            ar3: '',
            dt1: 'Sebto',
            es3: ''
        },
        {
            es2: 'ܟܪܝܢܐ',
            ar3: '',
            ar2: 'ܟܰܪܺܝܢܰܐ',
            ar1: 'ܟܪܝܢܐ',
            dt3: '',
            es3: '',
            dt2: '',
            bedeutung: '',
            dt1: 'Karina',
            es1: 'ܟܪܝܢܐ',
            id: '581'
        },
        {
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Leyla: die sich vergeblich bemüht” oder “Kuh”. Im AT ist Lea die erste Frau Jakobs, Tochter des Aramäers Laban/Lobon und ältere Schwester Rahels, bei den Aramäern ein weitverbreiteter Vorname',
            id: '626',
            dt3: '',
            ar3: '',
            ar1: 'ܠܝܐ',
            es2: 'ܠܝܐ',
            dt1: 'Lea',
            es1: 'ܠܝܐ',
            ar2: 'ܠܶܝܰܐ',
            es3: '',
            dt2: ''
        },
        {
            ar3: '',
            ar1: 'ܟܗܝܢܬܐ',
            id: '1759',
            es1: 'ܟܗܝܢܬܐ',
            dt1: 'Kahinta',
            dt3: '',
            dt2: '',
            ar2: 'ܟܰܗܺܝܢܬܰܐ',
            es2: 'ܟܗܝܢܬܐ',
            bedeutung: '',
            es3: ''
        },
        {
            dt2: 'Barnabas',
            es1: 'ܒܪܢܐܒܐ',
            dt1: 'Barnaba',
            dt3: '',
            es3: 'ܒܪܢܒܐ',
            ar1: 'ܒܪܢܐܒܐ',
            ar3: 'ܒܰܪܢܰܒܰܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäischen Ursprungs; “Sohn des Trostes”, “Sohn der tröstlichen Weissagung”. Im NT ist Barnaba der Beiname des Leviten Joseph. Dieser ist in der Tra-dition einer der 70 bzw. 72 Missionare',
            id: '174',
            ar2: 'ܒܰܪܢܰܐܒܰܐ',
            es2: 'ܒܪܢܐܒܐ'
        },
        {
            ar1: 'ܢܣܡܐ',
            es2: 'ܢܣܡܐ',
            dt3: '',
            id: '1942',
            dt1: 'Nasme',
            es3: '',
            ar2: 'ܢܰܣܡܶܐ',
            es1: 'ܢܣܡܐ',
            dt2: '',
            ar3: '',
            bedeutung: 'männl., “du bist mein Tiger”'
        },
        {
            dt1: 'Auyutha',
            es1: 'ܐܘܝܘܬܐ',
            dt3: '',
            id: '1311',
            ar2: 'ܐܰܘܝܽܘܬܰܐ',
            es3: '',
            ar1: 'ܐܘܝܘܬܐ',
            bedeutung: '',
            es2: 'ܐܘܝܘܬܐ',
            dt2: '',
            ar3: ''
        },
        {
            es2: 'ܚܐܪܬܐ',
            ar3: '',
            dt2: '',
            bedeutung: 'männl., syrischen Ursprungs; “Umarmung, Hobby, Interesse, Liebhaberei”',
            ar2: 'ܚܺܐܪܬܰܐ',
            dt3: '',
            es1: 'ܚܐܪܬܐ',
            id: '1666',
            ar1: 'ܚܐܪܬܐ',
            es3: '',
            dt1: 'Hirta'
        },
        {
            id: '417',
            dt3: '',
            bedeutung: '',
            ar3: '',
            es1: 'ܗܕܕܐ',
            dt2: '',
            ar2: 'ܗܰܕܳܕܳܐ',
            es3: '',
            dt1: 'Hadodo',
            ar1: 'ܗܕܕܐ',
            es2: 'ܗܕܕܐ'
        },
        {
            es2: 'ܕܢܝܐܝܠ',
            id: '258',
            ar3: 'ܕܰܢܺܝܐܝܶܠ',
            ar2: 'ܕܳܢܺܝܐܝܶܠ',
            ar1: 'ܕܢܝܐܝܠ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott ist mein Richter”. Im AT ist Daniel ein Prophet (7./6. v. Chr.)',
            dt2: 'Danyel',
            dt1: 'Daniel',
            es1: 'ܕܢܝܐܝܠ',
            es3: 'ܕܢܝܐܝܠ',
            dt3: 'Doniel'
        },
        {
            id: '1351',
            ar1: 'ܒܪܟܐܦܐ',
            dt2: 'Barkepha ',
            ar3: '',
            bedeutung: 'männl., “Sohn der Nation”',
            es3: '',
            es1: 'ܒܪܟܐܦܐ',
            dt3: '',
            ar2: 'ܒܰܪܟܺܐܦܳܐ',
            es2: 'ܒܪܟܐܦܐ',
            dt1: 'Barkipho'
        },
        {
            es1: 'ܛܝܡܬܐܘܣ',
            es3: '',
            dt2: 'Timotheus',
            bedeutung: 'weibl., “Güte, Gütigkeit, Freundlichkeit, Liebenswürdigkeit”; weitere Formen: Tabuta',
            es2: 'ܛܝܡܬܐܘܣ',
            ar2: 'ܛܺܝܡܳܬܶܐܘܳܣ',
            ar3: '',
            dt3: '',
            id: '2251',
            dt1: 'Timotheos',
            ar1: 'ܛܝܡܬܐܘܣ'
        },
        {
            es3: '',
            bedeutung: '',
            es1: 'ܢܒܘܢܐܝܕ',
            es2: 'ܢܒܘܢܐܝܕ',
            ar2: 'ܢܰܒܽܘܢܰܐܝܺܕ',
            dt3: '',
            ar1: 'ܢܒܘܢܐܝܕ',
            id: '1949',
            dt1: 'Nebunaid',
            ar3: '',
            dt2: ''
        },
        {
            ar3: '',
            ar2: 'ܦܳܠܳܓ',
            es1: 'ܦܠܓ',
            id: '810',
            es3: '',
            es2: 'ܦܠܓ',
            dt3: '',
            bedeutung: 'männl., siehe Peleg',
            dt1: 'Polog',
            ar1: 'ܦܠܓ',
            dt2: ''
        },
        {
            dt2: '',
            ar3: '',
            bedeutung: 'männl., “er hat gegründet”',
            es1: 'ܒܠܝ',
            ar2: 'ܒܰܠܰܝ',
            dt1: 'Balai',
            es2: 'ܒܠܝ',
            ar1: 'ܒܠܝ',
            id: '1328',
            dt3: '',
            es3: ''
        },
        {
            dt2: '',
            dt3: '',
            bedeutung: 'männl., “Topf, Kanne, Fass, Gefäß, Krug”',
            ar1: 'ܕܢܐ',
            id: '260',
            es3: 'ܕܐܢܐ',
            dt1: 'Dano',
            es1: 'ܕܢܐ',
            ar3: 'ܕܰܐܢܳܐ',
            es2: 'ܕܢܐ',
            ar2: 'ܢܰܢܳܐ'
        },
        {
            id: '972',
            ar2: 'ܫܳܐܘܳܠ',
            es1: 'ܫܐܘܠ',
            dt1: 'Schowol',
            es2: 'ܫܐܘܠ',
            ar1: 'ܫܐܘܠ',
            dt2: '',
            ar3: '',
            dt3: '',
            es3: '',
            bedeutung: 'männl., siehe Schaol'
        },
        {
            es3: 'ܬܐܒܠܝܐ',
            bedeutung: '',
            es1: 'ܬܐܒܝܠܝܐ',
            es2: 'ܬܐܒܝܠܝܐ',
            dt2: 'Tibelya',
            dt3: 'Tibelja',
            ar3: 'ܬܺܐܒܶܠܝܰܐ',
            ar1: 'ܬܐܒܝܠܝܐ',
            id: '2247',
            dt1: 'Tibelia',
            ar2: 'ܬܺܐܒܺܝܠܝܰܐ'
        },
        {
            ar3: '',
            dt3: '',
            bedeutung: 'männl., griechischen Ursprungs; “ein Musikinstrument, Wasserorgel”',
            id: '462',
            es3: '',
            dt1: 'Hedrulo',
            es2: 'ܗܕܪܘܠܐ',
            ar2: 'ܗܶܕܪܽܘܠܳܐ',
            es1: 'ܗܕܪܘܠܐ',
            ar1: 'ܗܕܪܘܠܐ',
            dt2: ''
        },
        {
            dt1: 'Zephanja',
            es2: 'ܨܦܢܝܐ',
            bedeutung: '',
            es1: 'ܨܦܢܝܐ',
            id: '1170',
            dt3: 'Zephanya',
            es3: '',
            ar2: 'ܨܶܦܰܢܝܰܐ',
            ar3: '',
            dt2: 'Sephanja',
            ar1: 'ܨܦܢܝܐ'
        },
        {
            dt1: 'Sfita',
            ar3: '',
            id: '2183',
            bedeutung: 'männl., Kommandant von Hadadeser (11. Jh. v. Chr.); weitere Formen: Schobach',
            es1: 'ܨܦܝܬܐ',
            dt2: '',
            dt3: '',
            es3: '',
            ar2: 'ܨܦܝܺܬܰܐ',
            es2: 'ܨܦܝܬܐ',
            ar1: 'ܨܦܝܬܐ'
        },
        {
            bedeutung: '',
            dt3: '',
            ar1: 'ܐܚܙܝܐ',
            es2: 'ܐܚܙܝܐ',
            ar3: '',
            id: '4',
            es1: 'ܐܚܙܝܐ',
            dt2: '',
            ar2: 'ܐܰܚܰܙܝܰܐ',
            dt1: 'Abasja',
            es3: ''
        },
        {
            ar2: 'ܦܶܛܪܽܘܣ',
            dt2: 'Petrus',
            es2: 'ܦܛܪܘܣ',
            dt3: 'Fetrus',
            ar1: 'ܦܛܪܘܣ',
            dt1: 'Petros',
            es3: '',
            es1: 'ܦܛܪܘܣ',
            id: '1999',
            ar3: '',
            bedeutung: 'weibl., “Harfe, Leier, Lyra, Zither”; weitere Formen: Qithora'
        },
        {
            dt2: '',
            dt3: '',
            es3: '',
            ar1: 'ܐܢܘܫ',
            es2: 'ܐܢܘܫ',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Myrte oder die Verborgene” (akkadisch Ischtar). Im AT ist Esther die Gattin von Xerxes I. Sie verhindert die Ausrottung der Juden in Persien, ist Hauptgestalt des nach Ihr benannten alttestamentlichen Buches',
            es1: 'ܐܢܘܫ',
            id: '1501',
            dt1: 'Ensosch',
            ar2: 'ܐܶܢܳܘܫ',
            ar3: ''
        },
        {
            ar3: '',
            dt3: 'Ahisamek',
            id: '59',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “mein Bruder ist meine Unterstützung”; weitere Formen: Ahisemech, Ahisamek',
            ar2: 'ܐܰܚܝܣܰܡܟܳܐ',
            ar1: 'ܐܚܝܣܡܟܐ',
            dt1: 'Ahisamko',
            dt2: 'Ahisemech',
            es1: 'ܐܚܝܣܡܟܐ',
            es2: 'ܐܚܝܣܡܟܐ',
            es3: ''
        },
        {
            dt1: 'Margo',
            ar1: 'ܡܪܓܐ',
            id: '665',
            dt3: '',
            dt2: '',
            es3: '',
            es2: 'ܡܪܓܐ',
            es1: 'ܡܪܓܐ',
            ar2: 'ܡܰܪܓܳܐ',
            bedeutung: 'männl., “Wiese, Weide”',
            ar3: ''
        },
        {
            ar2: 'ܡܰܪܩܽܘܣ',
            es2: 'ܡܪܩܘܣ',
            ar1: 'ܡܪܩܘܣ',
            dt2: '',
            ar3: 'ܡܰܪܩܳܘܣ',
            id: '1861',
            es3: 'ܡܪܩܘܣ',
            dt3: '',
            es1: 'ܡܪܩܘܣ',
            dt1: 'Markus',
            bedeutung: 'männl., “Herr Gott”'
        },
        {
            es3: 'ܛܒܝܐ',
            es2: 'ܛܒܝܐ',
            dt2: 'Tabea',
            bedeutung: 'männl. Form zu Tabita; weitere Formen: Tabiya, Tabea',
            id: '1024',
            dt1: 'Tabia',
            ar1: 'ܛܒܝܐ',
            ar2: 'ܛܰܒܺܝܰܐ',
            es1: 'ܛܒܝܐ',
            dt3: '',
            ar3: 'ܛܰܒܝܰܐ'
        },
        {
            dt2: '',
            ar2: 'ܒܰܪܫܰܒܐ',
            ar1: 'ܒܪܣܒܐ',
            bedeutung: 'männl., “Sohn des Kreuzes”. Barsalibi ist der Name des syrischen Liturgikers Bischof Dionysius (†1171) von Amid (Diyarbakir). Weitere Formen: Barslibo',
            dt3: '',
            ar3: 'ܒܰܪܫܰܒܬܳܐ',
            dt1: 'Barsaba',
            id: '1362',
            es2: 'ܒܪܣܒܐ',
            es3: 'ܒܪܫܒܬܐ',
            es1: 'ܒܪܣܒܐ'
        },
        {
            id: '1174',
            dt3: '',
            ar2: 'ܙܟܺܝܬܰܐ',
            dt1: 'Zkita',
            ar3: '',
            ar1: 'ܙܟܝܬܐ',
            es3: '',
            es2: 'ܙܟܝܬܐ',
            dt2: '',
            bedeutung: 'weibl., “Siegerin, Bezwingerin, Eroberin”; weitere Formen: Zkhita',
            es1: 'ܙܟܝܬܐ'
        },
        {
            id: '1150',
            es2: 'ܙܗܪܐ',
            dt1: 'Zahra',
            es1: 'ܙܗܪܐ',
            ar3: '',
            ar1: 'ܙܗܪܐ',
            dt2: 'Sahre',
            es3: '',
            ar2: 'ܙܰܗܪܰܐ',
            bedeutung: 'weibl., arabischen Ursprungs; “Blume”, weitere Formen: Zahre, Sahre',
            dt3: ''
        },
        {
            bedeutung: 'männl., “Fackel, Laterne”;',
            dt2: '',
            ar2: 'ܢܰܥܡܳܢ',
            es2: 'ܢܥܡܢ',
            es1: 'ܢܥܡܢ',
            id: '1916',
            es3: '',
            dt1: 'Naamon',
            dt3: '',
            ar1: 'ܢܥܡܢ',
            ar3: ''
        },
        {
            es3: '',
            ar1: 'ܥܦܪܘܢ',
            dt3: '',
            es1: 'ܥܦܪܘܢ',
            ar2: 'ܥܰܦܪܳܘܢ',
            es2: 'ܥܦܪܘܢ',
            id: '45',
            dt2: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs. Im AT ist Afron Sohn des Zohar, des Hethiters. Weitere Formen: Afrun',
            dt1: 'Afron',
            ar3: ''
        },
        {
            dt1: 'Lulita',
            ar3: '',
            es2: 'ܠܘܠܝܬܐ',
            ar1: 'ܠܘܠܝܬܐ',
            dt2: '',
            es3: '',
            id: '1827',
            ar2: 'ܠܽܘܠܺܝܬܰܐ',
            bedeutung: 'männl., “Tribut, Steuer, Zoll”',
            dt3: '',
            es1: 'ܠܘܠܝܬܐ'
        },
        {
            es3: '',
            dt2: '',
            dt1: 'Banibal',
            es2: 'ܒܢܝܒܐܠ',
            es1: 'ܒܢܝܒܐܠ',
            bedeutung: '',
            ar1: 'ܒܢܝܒܐܠ',
            dt3: '',
            ar2: 'ܒܰܢܺܝܒܰܐܠ',
            id: '151',
            ar3: ''
        },
        {
            id: '1863',
            dt1: 'Marnita',
            dt2: '',
            es2: 'ܡܪܢܝܬܐ',
            es1: 'ܡܪܢܝܬܐ',
            ar2: 'ܡܰܪܢܺܝܬܰܐ',
            ar1: 'ܡܪܢܝܬܐ',
            ar3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs. Im AT ist Masch einer der vier Söhne Arams. Ein aramäischer Stamm, der vielen Orientalisten zufolge im heutigen Turabdin, dem alten Gebirge Masch/Masius gelebt haben soll; demnach wären die heutigen Suryoye/Syrer/Aramäer von Turabdin die Nachfahren dieses aramäischen Stammes. Weitere Formen: Mosch, Mas, Mascha',
            es3: '',
            dt3: ''
        },
        {
            ar1: 'ܐܠܝܐ',
            es3: '',
            ar3: '',
            id: '312',
            dt1: 'Elyo',
            es2: 'ܐܠܝܐ',
            es1: 'ܐܠܝܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “mein Gott ist Jahwe”. Im AT ist Elijo ein Prophet (um 850 v.Chr.). Weitere Formen: Elija, Elias, Ilijo, Elia',
            dt2: 'Eliyo',
            dt3: 'Eliya',
            ar2: 'ܐܶܠܺܝܳܐ'
        },
        {
            es3: '',
            bedeutung: '',
            es2: 'ܣܡܝܐ',
            ar3: '',
            dt1: 'Samja',
            ar1: 'ܣܡܝܐ',
            dt2: '',
            es1: 'ܣܡܝܐ',
            dt3: '',
            id: '2093',
            ar2: 'ܣܰܡܝܰܐ'
        },
        {
            es3: '',
            ar2: 'ܦܰܒܪܳܐ',
            id: '1523',
            dt3: '',
            dt1: 'Febro',
            dt2: 'Fabro',
            ar1: 'ܦܒܪܐ',
            ar3: '',
            bedeutung: 'männl., “Erlöser, Retter, Heiland”; weitere Formen: Faruk, Faruq, Foruqo',
            es2: 'ܦܒܪܐ',
            es1: 'ܦܒܪܐ'
        },
        {
            es3: 'ܚܙܝܐ',
            es1: 'ܚܙܝܐ',
            id: '1635',
            ar3: 'ܚܰܙܳܝܳܐ',
            bedeutung: '',
            dt1: 'Hasaja',
            dt2: '',
            ar2: 'ܚܰܙܰܝܰܐ',
            ar1: 'ܚܙܝܐ',
            es2: 'ܚܙܝܐ',
            dt3: ''
        },
        {
            bedeutung: 'männl., aus dem Lateinischen übernommener Vorname; “standhaft”. Nach dem bekannten christlichen Kaiser Konstantin dem Großen (4. Jh.) bei den Aramäern beliebter Vorname. Weitere Formen: Constantin, Kustantin, Qustantin',
            dt2: '',
            dt1: 'Kilamwa',
            es2: 'ܟܝܠܡܘܐ',
            dt3: '',
            ar1: 'ܟܝܠܡܘܐ',
            ar3: 'ܟܺܝܠܰܡܽܘܰܐ',
            id: '1782',
            ar2: 'ܟܺܝܠܰܡܘܰܐ',
            es1: 'ܟܝܠܡܘܐ',
            es3: 'ܟܝܠܡܘܐ'
        },
        {
            ar1: 'ܫܚܠܘܦܐ',
            es1: 'ܫܚܠܘܦܐ',
            dt3: '',
            dt2: '',
            es2: 'ܫܚܠܘܦܐ',
            bedeutung: 'männl., “senden, schicken, entlassen”',
            es3: 'ܫܟ݂ܠܘܦܐ',
            ar3: 'ܫܰܟ݂ܠܽܘܦܰܐ',
            dt1: 'Schahlupa',
            id: '2118',
            ar2: 'ܫܰܚܠܽܘܦܰܐ'
        },
        {
            id: '1380',
            ar1: 'ܒܣܡܐ',
            es3: '',
            dt2: '',
            ar3: '',
            es2: 'ܒܣܡܐ',
            dt3: '',
            dt1: 'Basma',
            ar2: 'ܒܰܣܡܰܐ',
            bedeutung: 'weibl., “Paradies”',
            es1: 'ܒܣܡܐ'
        },
        {
            ar2: 'ܥܰܒܕܶܠܡܰܣܺܝܚ',
            id: '1185',
            ar1: 'ܥܒܕܠܡܣܝܚ',
            dt1: 'Abdelmassih',
            ar3: '',
            es3: '',
            dt2: 'Abdelmassieh',
            es2: 'ܥܒܕܠܡܣܝܚ',
            dt3: '',
            es1: 'ܥܒܕܠܡܣܝܚ',
            bedeutung: ''
        },
        {
            dt3: '',
            ar3: '',
            dt1: 'Hedro',
            es2: 'ܗܕܪܐ',
            es1: 'ܗܕܪܐ',
            id: '1648',
            bedeutung: 'männl., syrischen Ursprungs; “Wohlbehagen, Belang, Genuß, Gewinn, Nutzen”',
            es3: '',
            ar1: 'ܗܕܪܐ',
            ar2: 'ܗܶܕܪܳܐ',
            dt2: ''
        },
        {
            dt1: 'Ithaloho',
            ar2: 'ܐܺܝܬܐܰܠܳܗܳܐ',
            es3: '',
            es1: 'ܐܝܬܐܠܗܐ',
            es2: 'ܐܝܬܐܠܗܐ',
            dt3: '',
            ar3: 'ܐܺܝܬܐܰܠܰܗܰܐ',
            ar1: 'ܐܝܬܐܠܗܐ',
            id: '514',
            bedeutung: 'männl., “es gibt (existiert) Gott”; weitere Formen: Ithalaha, Italaha',
            dt2: ''
        },
        {
            ar3: '',
            bedeutung: 'männl., “Sohn des Fastens”. Barsawmo ist ein Heiliger (†457); Festtag: 3. Februar. Weitere Formen: Barsawma, Barsaumo, Barsauma',
            dt2: '',
            ar2: 'ܒܰܪܨܰܒܽܘܢܺܝ',
            id: '1363',
            es2: 'ܒܪܨܒܘܢܝ',
            dt3: '',
            es1: 'ܒܪܨܒܘܢܝ',
            es3: '',
            dt1: 'Barsabuni',
            ar1: 'ܒܪܨܒܘܢܝ'
        },
        {
            dt1: 'Nahor',
            dt3: '',
            id: '1927',
            es3: '',
            dt2: '',
            ar3: '',
            ar1: 'ܢܚܘܪ',
            bedeutung: 'weibl., “Reinheit, Reinlichkeit, Frische, Sauberkeit, Anständigkeit, Ehrlichkeit”',
            es1: 'ܢܚܘܪ',
            es2: 'ܢܚܘܪ',
            ar2: 'ܢܰܚܽܘܪ'
        },
        {
            dt2: '',
            ar3: '',
            bedeutung: 'männl., siehe Jeschu',
            ar2: 'ܐܺܝܠܳܢܰܐ',
            es2: 'ܐܝܠܢܐ',
            es1: 'ܐܝܠܢܐ',
            ar1: 'ܐܝܠܢܐ',
            es3: '',
            dt1: 'Ilona',
            dt3: '',
            id: '1688'
        },
        {
            id: '714',
            dt3: '',
            dt1: 'Mor Philoksinos Hanna Dolabani',
            ar1: 'ܡܪܝ ܦܝܠܠܘܟܣܝܢܘܣ ܝܘܚܢܢ ܕܘܠܒܐܢܝ',
            es2: 'ܡܪܝ ܦܝܠܠܘܟܣܝܢܘܣ ܝܘܚܢܢ ܕܘܠܒܐܢܝ',
            bedeutung: '',
            ar3: '',
            es1: 'ܡܪܝ ܦܝܠܠܘܟܣܝܢܘܣ ܝܘܚܢܢ ܕܘܠܒܐܢܝ',
            es3: '',
            dt2: '',
            ar2: 'ܡܪܝ ܦܝܠܠܘܟܣܝܢܘܣ ܝܘܚܢܢ ܕܘܠܒܐܢܝ'
        },
        {
            es2: 'ܓܒܪܐܝܠ',
            es1: 'ܓܒܪܐܝܠ',
            dt3: '',
            dt2: '',
            es3: '',
            ar2: 'ܓܰܒܪܺܐܝܶܠ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Mann Gottes” oder “Gott ist stark”. Im AT ist Gabriel der Erzengel Gottes; im NT ist er der Engel, der dem Zacharias die Geburt Johannes des Taufers ankündigt und Maria die Geburt Jesu. Als Vorname des Hl. Gabriel (†668) von Beth-Qustan bei Hah im Tur Abdin, der auch Reorganisator des Klosters “Mor Gabriel” ist, ist dieser Name bei den Aramäern sehr beliebt; Festtag: 31. August. Weitere Formen: Gabro, Gawriye, Gawro, Djabrail, Cebrail',
            ar3: '',
            ar1: 'ܓܒܪܐܝܠ',
            id: '356',
            dt1: 'Gabriel'
        },
        {
            dt2: '',
            bedeutung: 'männl., syrischen Ursprungs; “Stolz”',
            es2: 'ܚܘܬܪܐ',
            ar1: 'ܚܘܬ݂ܪܐ',
            es3: '',
            ar2: 'ܚܽܘܬ݂ܪܳܐ',
            dt1: 'Huthro',
            ar3: '',
            dt3: '',
            id: '494',
            es1: 'ܚܘܬܪܐ'
        },
        {
            es3: '',
            dt2: '',
            es2: 'ܥܬܝܪ',
            id: '122',
            es1: 'ܥܬܝܪ',
            ar1: 'ܥܬܝܪ',
            dt1: 'Atir',
            dt3: '',
            ar2: 'ܥܰܬܺܝܪ',
            ar3: '',
            bedeutung: 'männl., “reich, reichlich”'
        },
        {
            bedeutung: 'männl., “Jesus hat gesiegt”',
            ar3: 'ܝܽܘܪܰܒܥܰܡ',
            es3: 'ܝܘܪܒܥܡ',
            dt3: '',
            dt1: 'Jerobeam',
            ar1: 'ܝܘܪܒܥܡ',
            ar2: 'ܝܽܘܪܒܰܥܰܡ',
            es2: 'ܝܘܪܒܥܡ',
            es1: 'ܝܘܪܒܥܡ',
            id: '1722',
            dt2: ''
        },
        {
            es3: '',
            es1: 'ܣܒܪܝܫܘܥ',
            id: '876',
            ar2: 'ܣܰܒܰܪܝܶܫܽܘܥ',
            ar3: '',
            dt2: '',
            dt1: 'Sabarjeschu',
            bedeutung: 'männl., “der Jesus-Verkünder”',
            es2: 'ܣܒܪܝܫܘܥ',
            dt3: '',
            ar1: 'ܣܒܪܝܫܘܥ'
        },
        {
            es2: 'ܐܝܫܡܥܝܠ',
            ar3: 'ܫܡܳܥܺܝܠ',
            dt3: '',
            es3: 'ܫܡܥܝܠ',
            ar2: 'ܐܺܝܫܡܳܥܺܝܠ',
            dt1: 'Ischmoel',
            ar1: 'ܐܝܫܡܥܝܠ',
            dt2: '',
            bedeutung: 'weibl., Izala ist eine Landschaﬁ in Aram Beth-Nahrin (= Mesopotamien), Teil von Turabdin. Weitere Formen: Izla',
            id: '1695',
            es1: 'ܐܝܫܡܥܝܠ'
        },
        {
            id: '1623',
            dt2: '',
            es2: 'ܚܢܢܝܐ',
            ar3: '',
            ar2: 'ܚܰܢܰܢܝܰܐ',
            ar1: 'ܚܢܢܝܐ',
            dt3: '',
            dt1: 'Hananya',
            es3: '',
            es1: 'ܚܢܢܝܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Günstling, Jahwe hat Gnade gewirkt”; weitere Formen: Hanuno'
        },
        {
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Eingeweihter, Einweihung”. Im AT ist Henoch Sohn des Kain, im NT Ahnherr Jesu. Weitere Formen: Hnuch',
            ar2: 'ܚܢܽܘܟ݂',
            dt3: '',
            ar3: '',
            es2: 'ܚܢܘܟ݂',
            dt2: '',
            ar1: 'ܚܢܘܟ݂',
            dt1: 'Henoch',
            id: '467',
            es3: '',
            es1: 'ܚܢܘܟ݂'
        },
        {
            ar1: 'ܪܒܝܐܠ',
            ar3: 'ܪܳܘܒܺܝܠ',
            dt1: 'Robil',
            es3: 'ܪܘܒܝܠ',
            dt3: '',
            ar2: 'ܪܒܝܠ',
            id: '864',
            es1: 'ܪܒܝܐܠ',
            es2: 'ܪܒܝܐܠ',
            dt2: 'Rubil',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “[Jahwe] hat mein Elend gesehen”. Im AT ist Rubil (syrische Form von Ruben) der älteste Sohn des Jakob/Israel und der Lea. Weitere Formen: Robil, Rubel, Ruben, Rubin'
        },
        {
            dt3: '',
            es2: 'ܢܘܪܒܐ',
            dt2: '',
            ar3: '',
            dt1: 'Nurbo',
            es3: '',
            es1: 'ܢܘܪܒܐ',
            bedeutung: 'männl., “Saugnapf, Lutscher, Trieb, Schößling”',
            ar2: 'ܢܽܘܪܒܳܐ',
            ar1: 'ܢܘܪܒܐ',
            id: '783'
        },
        {
            dt2: '',
            ar3: '',
            dt1: 'Betuel',
            ar2: 'ܒܶܬܽܘܐܝܺܠ',
            id: '228',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Haus Gottes”. Betuèl ist im AT ein Aramäer aus Paddan-Aram, Sohn Nahors, Neffe Abrohoms, Vater Labans und der Rebekka, Großvater -mütterlicherseits- des Jakob',
            es3: '',
            es2: 'ܒܬܘܐܝܠ',
            es1: 'ܒܬܘܐܝܠ',
            ar1: 'ܒܬܘܐܝܠ',
            dt3: ''
        },
        {
            es1: 'ܢܝܗܬ',
            es3: 'ܢܐܗܬ',
            dt3: '',
            bedeutung: 'weibl., “Atem, Atmung”; weitere Formen: Nschamtha',
            ar1: 'ܢܝܗܬ',
            ar3: 'ܢܺܐܗܰܬ',
            dt1: 'Nihat',
            id: '1958',
            dt2: '',
            ar2: 'ܢܺܝܗܰܬ',
            es2: 'ܢܝܗܬ'
        },
        {
            ar3: '',
            es3: '',
            dt3: '',
            es2: 'ܕܩܠܬ݂',
            ar2: 'ܕܶܩܠܰܬ݂',
            bedeutung: '',
            dt2: 'Deqlat',
            ar1: 'ܕܩܠܬ݂',
            es1: 'ܕܩܠܬ݂',
            dt1: 'Deqlath',
            id: '1453'
        },
        {
            ar3: 'ܝܰܗܒܐܰܠܰܗܰܐ',
            ar1: 'ܝܗܒܐܠܗܐ',
            id: '1706',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “rechte [Hand] = gute Seite“, “Glück”, “Süden, Teman, Taymno”',
            dt3: '',
            es3: 'ܝܗܒܐܠܗܐ',
            dt1: 'Jabaloho',
            es2: 'ܝܗܒܐܠܗܐ',
            ar2: 'ܝܰܗܒܐܰܠܳܗܳܐ',
            dt2: '',
            es1: 'ܝܗܒܐܠܗܐ'
        },
        {
            dt3: '',
            ar3: 'ܠܽܘܙܶܐ',
            ar1: 'ܠܘܣܐ',
            dt1: 'Luse',
            dt2: '',
            bedeutung: 'weibl., „Mandeln“; weitere Formen: Lusia, Luze',
            ar2: 'ܠܽܘܣܶܐ',
            es1: 'ܠܘܣܐ',
            id: '640',
            es2: 'ܠܘܣܐ',
            es3: 'ܠܘܙܐ'
        },
        {
            es2: 'ܢܫܡܬܐ',
            dt3: '',
            bedeutung: 'männl., “du bist mein Feuer, Licht”',
            ar1: 'ܢܫܡܬܐ',
            id: '1967',
            dt1: 'Nschamta',
            es3: '',
            es1: 'ܢܫܡܬܐ',
            ar2: 'ܢܫܰܡܬܰܐ',
            dt2: '',
            ar3: ''
        },
        {
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe rettet”, ”Jahwe ist Retter“. Jeschu (Christus) von Nazaret ist nach dem NT Retter und Erlöser der Menschheit. Weitere Formen: Jeschua, Jesus, Isa, Issa',
            dt1: 'Jehiel',
            dt3: '',
            ar1: 'ܝܘܚܝܠ',
            es2: 'ܝܘܚܝܠ',
            dt2: 'Juhoel',
            ar2: 'ܝܽܘܚܳܝܶܠ',
            es1: 'ܝܘܚܝܠ',
            id: '1717',
            es3: '',
            ar3: ''
        },
        {
            es2: 'ܛܘܒܝܐܣ',
            ar3: '',
            ar1: 'ܛܘܒܝܐܣ',
            dt3: '',
            ar2: 'ܛܳܘܒܺܝܰܐܣ',
            es3: '',
            es1: 'ܛܘܒܝܐܣ',
            dt1: 'Tobias',
            id: '2256',
            dt2: '',
            bedeutung: 'weibl., “Bime”, “Feuer, Hitze, Wärme”'
        },
        {
            es3: '',
            bedeutung: 'männl., syrischen Ursprungs; “Mesopotamier, der zwischen Flüssen wohnt”',
            id: '1921',
            es1: 'ܢܒܘܒܠܘܨܪ',
            ar1: 'ܢܒܘܒܠܘܨܪ',
            es2: 'ܢܒܘܒܠܘܨܪ',
            ar2: 'ܢܰܒܳܘܒܳܠܳܘܨܰܪ',
            dt3: '',
            dt1: 'Nabopolassar',
            ar3: '',
            dt2: ''
        },
        {
            ar1: 'ܐܫܦܪ',
            dt2: '',
            bedeutung: 'männl., “kräftig, mächtig, stark”',
            es2: 'ܐܫܦܪ',
            dt3: '',
            dt1: 'Aschfar',
            ar3: '',
            ar2: 'ܐܰܫܦܰܪ',
            es3: '',
            id: '1288',
            es1: 'ܐܫܦܪ'
        },
        {
            es2: 'ܬܐܘܡܐܣ',
            ar2: 'ܬܳܐܘܡܰܐܣ',
            ar1: 'ܬܐܘܡܐܣ',
            dt2: 'Tomas',
            id: '1054',
            dt3: '',
            dt1: 'Thomas',
            es3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäischen Ursprungs; “Zwilling(sbruder)”. Im NT ist der Hl. Thomas einer von 12 Jüngern Jesu Christi. Weitere Formen: Thoma, Toma',
            es1: 'ܬܐܘܡܐܣ',
            ar3: ''
        },
        {
            es2: 'ܐܝܠܐ',
            es1: 'ܐܝܠܐ',
            bedeutung: 'männl., arab. Form zu Jesus',
            dt2: 'El',
            es3: 'ܐܠ',
            dt3: '',
            ar1: 'ܐܝܠ',
            ar2: 'ܐܺܝܠ',
            id: '1684',
            dt1: 'Il',
            ar3: 'ܐܶܠ'
        },
        {
            ar3: '',
            dt1: 'Amir',
            es1: 'ܐܡܝܪ',
            bedeutung: 'männl., “Fürst, Prinz”',
            es3: '',
            ar2: 'ܐܰܡܺܝܪ',
            id: '76',
            ar1: 'ܐܡܝܪ',
            dt2: '',
            es2: 'ܐܡܝܪ',
            dt3: ''
        },
        {
            bedeutung: 'männl., “Gott hat gegeben”. Jhabalaha war Patriarch-Katholikos der ostsyrischen Kirche von Seleukia-Ktesiphon (415-420). Weitere Formen: Jabalaha, Jaballaha, Yabaloho, Yaballaha',
            es3: 'ܝܗܒܐܠܗܐ',
            es1: 'ܝܗܒܐܠܗܐ',
            ar1: 'ܝܗܒܐܠܗܐ',
            dt1: 'Jabaloho',
            ar3: 'ܝܰܗܒܐܰܠܰܗܰܐ',
            ar2: 'ܝܰܗܒܐܰܠܳܗܳܐ',
            id: '519',
            dt2: '',
            es2: 'ܝܗܒܐܠܗܐ',
            dt3: ''
        },
        {
            bedeutung: 'männl., syrischen Ursprungs; “Liebling, Geliebter”; weitere Formen: Habibo',
            dt2: 'Grigor',
            es1: 'ܓܪܝܓܘܪ',
            dt1: 'Gregor',
            ar2: 'ܓܪܺܝܓܳܘܪ',
            dt3: '',
            es3: '',
            es2: 'ܓܪܝܓܘܪ',
            ar3: '',
            ar1: 'ܓܪܝܓܘܪ',
            id: '1581'
        },
        {
            id: '1338',
            es2: 'ܒܪܥܡܐ',
            ar1: 'ܒܪܥܡܐ',
            ar2: 'ܒܰܪܥܰܡܳܐ',
            ar3: '',
            es1: 'ܒܪܥܡܐ',
            dt2: '',
            bedeutung: 'weibl., aus dem Griechischen übemommener Vorname; ”die Fremde”. Die Hl. Barbara (†303) gilt als eine der 14 Nothelfer und als Patronin der Bergleute, Glöckner und Architekten; Festtag: 4. Dezember',
            dt1: 'Baramo',
            es3: '',
            dt3: ''
        },
        {
            dt3: '',
            dt2: '',
            es3: '',
            bedeutung: 'männl., “Greis, alter Mann, Opa, Senior”',
            ar2: 'ܨܗܺܝܬܰܐ',
            es1: 'ܨܗܝܬܐ',
            ar3: '',
            dt1: 'Shita',
            id: '2184',
            ar1: 'ܨܗܝܬܐ',
            es2: 'ܨܗܝܬܐ'
        },
        {
            ar2: 'ܐܰܒܺܝܫܳܐ',
            es2: 'ܐܒܝܫܐ',
            ar3: '',
            dt3: '',
            es3: '',
            dt2: '',
            bedeutung: '',
            id: '21',
            es1: 'ܐܒܝܫܐ',
            dt1: 'Abischo',
            ar1: 'ܐܒܝܫܐ'
        },
        {
            ar1: 'ܟܪܝܢ',
            dt3: '',
            bedeutung: 'männl., syrischen Ursprungs; “Stern, Planet”',
            ar2: 'ܟܰܪܺܝܢ',
            es1: 'ܟܪܝܢ',
            dt1: 'Karin',
            ar3: '',
            es3: '',
            dt2: '',
            id: '1768',
            es2: 'ܟܪܝܢ'
        },
        {
            id: '1381',
            dt1: 'Basna',
            es3: 'ܒܣܢܐ',
            ar2: 'ܒܰܣܢܰܐ',
            ar1: 'ܒܣܢܐ',
            bedeutung: 'weibl., Bath Zabbai, die auch Zenobia (Zeyneb/e) heißt (267-272 n.Chr.), war eine aramäische Schönheitskönigin von Tadmor (Palmyra) in Syrien. Weitere Formen: Zenob',
            ar3: 'ܒܶܣܢܰܐ',
            dt2: 'Besna',
            dt3: '',
            es2: 'ܒܣܢܐ',
            es1: 'ܒܣܢܐ'
        },
        {
            es2: 'ܒܘܠܘܬ',
            dt2: '',
            es3: '',
            bedeutung: '',
            ar3: '',
            ar1: 'ܒܘܠܘܬ',
            id: '239',
            es1: 'ܒܘܠܘܬ',
            ar2: 'ܒܽܘܠܽܘܬ',
            dt3: '',
            dt1: 'Bulut'
        },
        {
            dt3: '',
            es1: 'ܦܗܝܡܐ',
            bedeutung: 'weibl., “Schönheit, Eleganz, Pracht”; weitere Formen: Payuta, Payutha',
            dt2: '',
            ar1: 'ܦܗܝܡܐ',
            dt1: 'Fahima',
            es2: 'ܦܗܝܡܐ',
            es3: '',
            id: '1515',
            ar3: '',
            ar2: 'ܦܰܗܺܝܡܰܐ'
        },
        {
            id: '1881',
            dt1: 'Matina',
            es1: 'ܡܬܝܢܐ',
            dt2: '',
            bedeutung: '',
            ar3: '',
            es3: '',
            dt3: '',
            ar2: 'ܡܰܬܺܝܢܰܐ',
            ar1: 'ܡܬܝܢܐ',
            es2: 'ܡܬܝܢܐ'
        },
        {
            dt3: '',
            id: '528',
            ar2: 'ܝܰܪܺܝܒܰܐ',
            ar3: '',
            dt1: 'Jariba',
            es1: 'ܝܪܝܒܐ',
            es3: '',
            es2: 'ܝܪܝܒܐ',
            dt2: '',
            ar1: 'ܝܪܝܒܐ',
            bedeutung: 'weibl., Jariba war eine Stadt in Alt-Syrien; weitere Formen: Yariba'
        },
        {
            ar3: '',
            ar1: 'ܬܝܪ',
            bedeutung: 'männl., “aufwecken, anregen, bewegen”',
            dt2: '',
            es1: 'ܬܝܪ',
            dt3: '',
            es3: '',
            ar2: 'ܬܰܝܰܪ',
            es2: 'ܬܝܪ',
            id: '1041',
            dt1: 'Tayar'
        },
        {
            dt3: '',
            bedeutung: 'weibl., syrischen Ursprungs; “Weihrauch”',
            dt1: 'Lahhoud',
            es2: 'ܠܚܘܕ',
            es3: '',
            dt2: '',
            ar1: 'ܠܚܘܕ',
            es1: 'ܠܚܘܕ',
            ar3: '',
            id: '1804',
            ar2: 'ܠܚܘܕ'
        },
        {
            ar3: '',
            dt3: '',
            bedeutung: 'weibl., Athe war eine von den heidnischen Syrern (Aramäern) verehrte Gottheit',
            es1: 'ܥܬܗ',
            ar1: 'ܥܬܗ',
            es3: '',
            es2: 'ܥܬܗ',
            id: '119',
            dt2: '',
            ar2: 'ܥܰܬܶܗ',
            dt1: 'Athe'
        },
        {
            ar1: 'ܣܬܐ',
            dt3: '',
            es3: '',
            ar3: '',
            es1: 'ܣܬܐ',
            dt1: 'Setto',
            dt2: 'Sitto',
            bedeutung: '',
            id: '989',
            es2: 'ܣܬܐ',
            ar2: 'ܣܬܐ'
        },
        {
            dt2: 'Amal ',
            dt1: 'Amel',
            es3: '',
            ar2: 'ܐܰܡܶܠ',
            es1: 'ܐܡܠ',
            es2: 'ܐܡܠ',
            dt3: '',
            bedeutung: 'weibl., arabischen Ursprungs; “Hoffnung”',
            ar3: '',
            id: '75',
            ar1: 'ܐܡܠ'
        },
        {
            id: '349',
            es1: 'ܦܘܐܕ',
            dt1: 'Fuad',
            es3: '',
            ar3: '',
            bedeutung: '',
            ar2: 'ܦܽܘܐܰܕ',
            ar1: 'ܦܘܐܕ',
            dt3: '',
            dt2: 'Fuat',
            es2: 'ܦܘܐܕ'
        },
        {
            dt2: 'Abel',
            ar3: '',
            es1: 'ܗܒܝܠ',
            dt1: 'Hobel',
            ar1: 'ܗܒܝܠ',
            bedeutung: 'männl., “er hat zu Abend gegessen”',
            dt3: '',
            es2: 'ܗܒܝܠ',
            es3: '',
            ar2: 'ܗܳܒܶܝܠ',
            id: '1671'
        },
        {
            es3: '',
            dt1: 'Nurta',
            ar3: '',
            es1: 'ܢܘܪܬܐ',
            ar2: 'ܢܽܘܪܬܰܐ',
            dt3: '',
            bedeutung: 'weibl., “Heu, Blume, Blüte, Ranunkel, Arsen, Arsenik”',
            ar1: 'ܢܘܪܬܐ',
            es2: 'ܢܘܪܬܐ',
            dt2: '',
            id: '788'
        },
        {
            dt3: '',
            dt2: '',
            id: '1390',
            ar2: 'ܒܰܪ̱ܬܢܰܗܪܳܐ',
            ar3: '',
            es3: '',
            ar1: 'ܒܪ̱ܬܢܗܪܐ',
            dt1: 'Bathnahro',
            es1: 'ܒܪܬܢܗܪܐ',
            es2: 'ܒܪܬܢܗܪܐ',
            bedeutung: 'männl., “getröstet”'
        },
        {
            ar1: 'ܫܠܡܐ',
            dt3: '',
            ar2: 'ܫܠܳܡܳܐ',
            dt2: '',
            bedeutung: 'männl., “Schönheit, Eleganz”',
            es2: 'ܫܠܡܐ',
            es1: 'ܫܠܡܐ',
            ar3: '',
            dt1: 'Schlomo',
            es3: '',
            id: '2159'
        },
        {
            es2: 'ܫܪܒܠܝܐ',
            es3: '',
            ar3: '',
            ar2: 'ܫܰܪܒܶܠܝܰܐ',
            id: '946',
            es1: 'ܫܪܒܠܝܐ',
            bedeutung: 'weibl. Form von Scharbel',
            dt1: 'Scharbelya',
            ar1: 'ܫܪܒܠܝܐ',
            dt2: 'Scharbelia',
            dt3: ''
        },
        {
            dt1: 'Yamtha',
            es1: 'ܝܡܬܐ',
            ar3: '',
            ar2: 'ܝܰܡܬ݂ܰܐ',
            es3: '',
            id: '2319',
            bedeutung: 'männl., “sich verlangsamen, geschätzt, geehrt”; weitere Formen: Jiqar',
            ar1: 'ܝܡܬ݂ܐ',
            dt2: '',
            dt3: '',
            es2: 'ܝܡܬܐ'
        },
        {
            id: '667',
            dt3: '',
            es3: '',
            dt2: '',
            dt1: 'Mari',
            ar2: 'ܡܰܪܺܝ',
            ar1: 'ܡܪܝ',
            es1: 'ܡܪܝ',
            ar3: '',
            es2: 'ܡܪܝ',
            bedeutung: 'männl., „mein Herr”. Mari/Mariah (“mein Gott ist Mari”) war einer von den heidnischen Aramäern verehrte Gott und ist wohl der Beiname des aramäischen Königs Bar-Hadad III. in den assyrischen Inschriften'
        },
        {
            dt1: 'Zokuta',
            es2: 'ܙܟܘܬܐ',
            es3: '',
            bedeutung: 'weibl., Sieg; weitere Formen: Zokhuta, Sokuta',
            ar3: '',
            es1: 'ܙܟܘܬܐ',
            dt3: '',
            dt2: '',
            id: '1175',
            ar1: 'ܙܟܘܬܐ',
            ar2: 'ܙܳܟܽܘܬܰܐ'
        },
        {
            ar1: 'ܡܟܣܝ',
            dt2: '',
            ar3: '',
            es1: 'ܡܟܣܝ',
            dt1: '݂݂݂Maksi',
            dt3: '',
            bedeutung: '',
            ar2: 'ܡܰܟܣܺܝ',
            id: '2369',
            es3: '',
            es2: 'ܡܟܣܝ'
        },
        {
            es2: 'ܓܐܝܘܬܐ',
            dt2: '',
            ar3: '',
            dt3: '',
            dt1: 'Gayuta',
            es1: 'ܓܐܝܘܬܐ',
            ar2: 'ܓܰܐܝܽܘܬܐ',
            es3: '',
            id: '1555',
            bedeutung: '',
            ar1: 'ܓܐܝܘܬܐ'
        },
        {
            es2: 'ܐܣܪܬܐ',
            id: '321',
            es3: '',
            dt3: '',
            es1: 'ܐܣܪܬܐ',
            ar3: '',
            dt2: '',
            ar1: 'ܐܣܪܬܐ',
            ar2: 'ܐܶܣܳܪܬܰܐ',
            bedeutung: 'weibl., “Verband, Bündnis, Vereinigung, Bund”',
            dt1: 'Esorta'
        },
        {
            id: '796',
            dt3: '',
            ar1: 'ܐܟܝܬ',
            ar2: 'ܐܳܟܺܝܬ',
            bedeutung: 'männl., “das heißt, also”; weitere Formen: Awkith, Awkit',
            es2: 'ܐܟܝܬ',
            ar3: '',
            es1: 'ܐܟܝܬ',
            es3: '',
            dt1: 'Okit',
            dt2: ''
        },
        {
            ar3: '',
            es2: 'ܡܪܝ ܐܦܪܝܡ',
            es3: '',
            dt1: 'Mor Afrem',
            id: '707',
            dt3: '',
            bedeutung: '',
            dt2: 'St. Efrim',
            ar1: 'ܡܪܝ ܐܦܪܝܡ',
            ar2: 'ܡܳܪ ܐܰܦܪܶܝܡ',
            es1: 'ܡܪܝ ܐܦܪܝܡ'
        },
        {
            dt3: '',
            es3: '',
            id: '1737',
            ar3: '',
            ar1: 'ܝܘܐܒ',
            ar2: 'ܝܽܘܐܳܒ',
            dt2: '',
            es1: 'ܝܘܐܒ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe hat gegeben” oder “Gottesgabe”. Im AT existieren zwei Personen unter diesem Namen: Jonathan, Sohn von Saul, und Jonathan, Sohn des Matthias. Im NT (im Johannesevangelium) trägt ein Jünger Jesu diesen Namen in der Form Nathanael. Weitere Formen: Jonatan, Junothon, Yunothon, Nathan, Natan, Nathanael, Natanael',
            dt1: 'Joab',
            es2: 'ܝܘܐܒ'
        },
        {
            es3: '',
            dt1: 'Schmuel',
            id: '968',
            es2: 'ܫܡܘܐܝܠ',
            es1: 'ܫܡܘܐܝܠ',
            ar1: 'ܫܡܘܐܝܠ',
            bedeutung: 'männl, aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “von Gott erhöht”, “Namen Gottes” oder “sein Name ist El“. Im AT ist Schmuel Prophet und letzter Richter Israels. Er salbt David zum König (ca. 11. Jh. v.Chr.). Weitere Formen: Samuel, Schmuyel, Schemuel, Schemil; bei den Aramäern beliebt',
            dt2: '',
            ar3: '',
            ar2: 'ܫܡܽܘܐܝܶܠ',
            dt3: ''
        },
        {
            dt2: '',
            es2: 'ܪܝܚܢ',
            id: '858',
            bedeutung: 'weibl., aus dem Aramäischen übernommener Vorname; “Duft”. Weitere Formen: Recha, Riho, Rihane',
            dt3: '',
            ar1: 'ܪܝܚܢ',
            es1: 'ܪܝܚܢ',
            ar2: 'ܪܺܝܚܰܢ',
            dt1: 'Rihan',
            ar3: 'ܪܺܝܚܐܰܢ',
            es3: 'ܪܝܚܐܢ'
        },
        {
            dt3: '',
            es2: 'ܛܘܒܝܐܣ',
            bedeutung: '',
            es3: '',
            es1: 'ܛܘܒܝܐܣ',
            dt2: '',
            ar3: '',
            ar2: 'ܛܳܘܒܺܝܰܐܣ',
            id: '1064',
            dt1: 'Tobias',
            ar1: 'ܛܘܒܝܐܣ'
        },
        {
            ar1: 'ܝܗܠܐ',
            dt3: '',
            dt1: 'Yahlo',
            ar2: 'ܝܰܗܠܳܐ',
            bedeutung: 'männl., “Meer”; weitere Formen: Yam, Jamo, Jam',
            id: '2309',
            ar3: '',
            es2: 'ܝܗܠܐ',
            es1: 'ܝܗܠܐ',
            es3: '',
            dt2: ''
        },
        {
            bedeutung: 'weibl., “die Reine”',
            ar2: 'ܨܠܝܺܠܬܰܐ',
            es1: 'ܨܠܝܠܬܐ',
            id: '999',
            es3: '',
            es2: 'ܨܠܝܠܬܐ',
            dt2: '',
            ar1: 'ܨܠܝܠܬܐ',
            dt3: '',
            ar3: '',
            dt1: 'Slita'
        },
        {
            dt3: '',
            bedeutung: 'männl., “Steineiche, Immergrüneiche”; weitere Formen: Odro',
            es2: 'ܢܘܢܐ',
            id: '1973',
            es1: 'ܢܘܢܐ',
            ar1: 'ܢܘܢܐ',
            dt1: 'Nuno',
            ar2: 'ܢܽܘܢܳܐ',
            es3: '',
            ar3: '',
            dt2: ''
        },
        {
            dt3: '',
            dt2: '',
            id: '449',
            ar2: 'ܚܰܫܰܒܝܰܐ',
            es2: 'ܚܫܒܝܐ',
            es1: 'ܚܫܒܝܐ',
            ar1: 'ܚܫܒܝܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe hat Rechnung getragen”',
            ar3: '',
            es3: '',
            dt1: 'Haschabja'
        },
        {
            es2: 'ܙܗܪܝܪܐ',
            id: '2342',
            es3: '',
            ar3: '',
            dt2: '',
            es1: 'ܙܗܪܝܪܐ',
            bedeutung: 'männl., “du bist mein Licht, Strahl, Glanz”',
            ar1: 'ܙܗܪܝܪܐ',
            dt3: '',
            ar2: 'ܙܰܗܪܺܝܪܳܐ',
            dt1: 'Zahriro'
        },
        {
            ar1: 'ܫܡܫܐ',
            ar2: 'ܫܶܡܫܳܐ',
            id: '2148',
            es3: '',
            bedeutung: 'weibl., “die Ruhige, die Stille, Glückshaube”',
            es2: 'ܫܡܫܐ',
            dt3: '',
            dt2: '',
            dt1: 'Schemscho',
            es1: 'ܫܡܫܐ',
            ar3: ''
        },
        {
            ar2: 'ܠܰܡܶܟ݂',
            bedeutung: 'männl., “du bist mein Herz”',
            id: '1806',
            ar3: '',
            dt2: '',
            es3: '',
            es1: 'ܠܡܟ݂',
            es2: 'ܠܡܟ݂',
            ar1: 'ܠܡܟ݂',
            dt1: 'Lamech',
            dt3: ''
        },
        {
            id: '1760',
            ar1: 'ܩܐܝܢ',
            dt3: '',
            es2: 'ܩܐܝܢ',
            dt2: '',
            es3: 'ܩܐܝܢ',
            dt1: 'Kain',
            ar2: 'ܩܳܐܝܶܢ',
            bedeutung: '',
            ar3: 'ܩܰܐܝܺܢ',
            es1: 'ܩܐܝܢ'
        },
        {
            id: '822',
            dt1: 'Rabbaito',
            ar1: 'ܪܒܒܝܬܐ',
            bedeutung: 'männl., “Hausherr”; weitere Formen: Rabbayto',
            es2: 'ܪܒܒܝܬܐ',
            ar2: 'ܪܰܒܒܰܝܬܳܐ',
            dt3: '',
            es1: 'ܪܒܒܝܬܐ',
            ar3: '',
            dt2: '',
            es3: ''
        },
        {
            es3: '',
            ar1: 'ܒܪ̱ܬܕܝܢܐ',
            ar3: '',
            ar2: 'ܒܰܪ̱ܬܕܺܝܢܰܐ',
            es2: 'ܒܪܬܕܝܢܐ',
            es1: 'ܒܪܬܕܝܢܐ',
            dt1: 'Bathdina',
            dt2: '',
            dt3: '',
            id: '1384',
            bedeutung: 'weibl. Form zu Bar-Il; “Tochter Gottes, Gottestochter”'
        },
        {
            dt1: 'Sabiha',
            es1: 'ܣܒܝܚܐ',
            dt3: '',
            ar2: 'ܣܰܒܺܝܚܰܐ',
            ar3: '',
            id: '877',
            es3: '',
            es2: 'ܣܒܝܚܐ',
            bedeutung: '',
            ar1: 'ܣܒܝܚܐ',
            dt2: ''
        },
        {
            dt2: '',
            es3: '',
            ar1: 'ܡܕܢܚܐ',
            id: '643',
            bedeutung: 'männl., “Sonnenaufgang, Ost, Osten, Morgenland, Orient”',
            ar3: '',
            dt1: 'Madenho',
            es1: 'ܡܕܢܚܐ',
            ar2: 'ܡܰܕܶܢܚܳܐ',
            es2: 'ܡܕܢܚܐ',
            dt3: ''
        },
        {
            ar2: 'ܥܰܕܰܪ',
            dt3: '',
            dt2: '',
            es1: 'ܥܕܪ',
            id: '35',
            ar1: 'ܥܕܪ',
            ar3: '',
            bedeutung: 'männl., “helfen, unterstützen”',
            dt1: 'Adar',
            es2: 'ܥܕܪ',
            es3: ''
        },
        {
            dt1: 'Hubobo',
            es2: 'ܚܘܒܒܐ',
            bedeutung: 'männl., syrischen Ursprungs; “Umarmung, Hobby, Interesse, Liebhaberei”',
            es3: '',
            dt3: '',
            ar2: 'ܚܽܘܒܳܒܳܐ',
            ar3: '',
            id: '487',
            ar1: 'ܚܘܒܒܐ',
            es1: 'ܚܘܒܒܐ',
            dt2: ''
        },
        {
            es2: 'ܥܡܘܢ',
            id: '1260',
            dt1: 'Ammon',
            ar1: 'ܥܡܘܢ',
            es3: '',
            dt3: '',
            es1: 'ܥܡܘܢ',
            bedeutung: 'männl., ein aramäischer Stamm, der im 12. Jh. v. Chr. am Oberlauf des Jabbok auﬁauchte und einen Staat gründete',
            ar3: '',
            dt2: '',
            ar2: 'ܥܰܡܳܘܢ'
        },
        {
            bedeutung: 'männl., “Geschenk, Gabe”',
            es2: 'ܕܫܢܐ',
            dt2: '',
            dt1: 'Doschno',
            ar2: 'ܕܳܫܢܳܐ',
            es1: 'ܕܫܢܐ',
            es3: '',
            ar3: '',
            dt3: '',
            id: '281',
            ar1: 'ܕܫܢܐ'
        },
        {
            es2: 'ܢܝܢܘܪܬܐ',
            bedeutung: '',
            ar3: 'ܢܺܝܢܽܘܪܬܰܐ',
            ar1: 'ܢܝܢܘܪܬܐ',
            dt3: '',
            dt1: 'Ninorta',
            es3: 'ܢܝܢܘܪܬܐ',
            id: '768',
            es1: 'ܢܝܢܘܪܬܐ',
            dt2: '',
            ar2: 'ܢܺܝܢܳܘܪܬܰܐ'
        },
        {
            id: '1239',
            dt2: 'Ahadabuhi',
            dt3: '',
            dt1: 'Ahodabuy',
            ar2: 'ܐܰܚܳܐܕܰܐܒܽܘܗܝ',
            es3: '',
            es2: 'ܐܚܐܕܐܒܘܗܝ',
            es1: 'ܐܚܐܕܐܒܘܗܝ',
            bedeutung: 'männl., “Bruder seines Vaters“. Ahodabuy (273-291) war Bischof von Arbela. Weitere Formen: Ahadabuhi',
            ar1: 'ܐܚܐܕܐܒܘܗܝ',
            ar3: ''
        },
        {
            ar1: 'ܛܝܒܘܬܐ',
            ar2: 'ܛܰܝܒܽܘܬܰܐ',
            bedeutung: 'weibl., “Güte, Almosen, Liebenswürdigkeit, Anmut, Barmherzigkeit, Grazie”; weitere Formen: Taybuta, Taybutha, Tajbuta',
            ar3: '',
            es3: '',
            dt3: '',
            dt1: 'Taibuta',
            es1: 'ܛܝܡܘܬܐ',
            es2: 'ܛܝܡܘܬܐ',
            id: '1030',
            dt2: ''
        },
        {
            dt1: 'Fahdo',
            ar2: 'ܦܰܗܕܳܐ',
            ar1: 'ܦܗܕܐ',
            es2: 'ܦܗܕܐ',
            dt3: '',
            id: '1514',
            bedeutung: 'weibl., “Fruchtbarkeit”; weitere Formen: Faryutha',
            es3: '',
            es1: 'ܦܗܕܐ',
            dt2: '',
            ar3: ''
        },
        {
            dt1: 'Ahikam',
            es3: '',
            ar1: 'ܐܚܝܩܡ',
            es2: 'ܐܚܝܩܡ',
            es1: 'ܐܚܝܩܡ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “mein Bruder steht aufrecht”; weitere Formen: Ahiqam',
            dt3: '',
            ar2: 'ܐܰܚܺܝܩܰܡ',
            id: '1232',
            dt2: 'Ahiqam',
            ar3: ''
        },
        {
            ar1: 'ܐܬܐ',
            ar2: 'ܐܰܬܰܐ',
            ar3: '',
            es1: 'ܐܬܐ',
            bedeutung: 'weibl., “Flagge, Symbol, Zeichen”',
            dt1: 'Ata',
            dt2: '',
            dt3: '',
            es2: 'ܐܬܐ',
            es3: '',
            id: '114'
        },
        {
            es1: 'ܝܒܠ',
            es2: 'ܝܒܠ',
            dt1: 'Yabel',
            ar2: 'ܝܰܒܶܠ',
            es3: '',
            id: '1110',
            bedeutung: 'männl., „überbringen, übermitteln, bestellen, überliefern”; weitere Formen: Jabel',
            dt2: '',
            dt3: '',
            ar1: 'ܝܒܠ',
            ar3: ''
        },
        {
            ar1: 'ܐܘܪܗܝܢ',
            es1: 'ܐܘܪܗܝܢ',
            id: '1089',
            es2: 'ܐܘܪܗܝܢ',
            es3: '',
            ar2: 'ܐܽܘܪܗܺܝܢ',
            ar3: '',
            dt1: 'Urhin',
            dt3: '',
            bedeutung: 'weibl., “unser Urhoy-Edessa”',
            dt2: ''
        },
        {
            dt3: '',
            id: '1360',
            ar2: 'ܒܰܪܩܳܐ',
            ar1: 'ܒܪܩܐ',
            ar3: '',
            es1: 'ܒܪܩܐ',
            dt2: '',
            es3: '',
            dt1: 'Barqo',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäischen Ursprungs; “Sohn des Sabbats” oder “Sohn des Erhabenen”; weitere Formen: Barsabbas, Barschabo, Barschabtha',
            es2: 'ܒܪܩܐ'
        },
        {
            es2: 'ܓܝܘܠܐ',
            es1: 'ܓܝܘܠܐ',
            ar2: 'ܓܺܝܽܘܠܰܐ',
            dt1: 'Giyula',
            dt3: '',
            id: '386',
            ar1: 'ܓܝܘܠܐ',
            es3: '',
            dt2: '',
            bedeutung: 'weibl., “Elster”; weitere Formen: Giyola',
            ar3: ''
        },
        {
            dt2: '',
            es3: '',
            ar1: 'ܗܢܝܢܐ',
            dt1: 'Henyono',
            ar3: '',
            id: '1656',
            ar2: 'ܗܶܢܝܳܢܳܐ',
            dt3: '',
            es1: 'ܗܢܝܢܐ',
            es2: 'ܗܢܝܢܐ',
            bedeutung: ''
        },
        {
            ar3: '',
            ar1: 'ܟܐܢܬܐ',
            es1: 'ܟܐܢܬܐ',
            es3: '',
            bedeutung: 'weibl., syrischen Ursprungs; “0rkan, Sturm, Wirbelsturm”; weitere Formen: Kukitha',
            dt3: '',
            dt2: '',
            id: '1787',
            ar2: 'ܟܺܐܢܬܰܐ',
            dt1: 'Kinta',
            es2: 'ܟܐܢܬܐ'
        },
        {
            ar1: 'ܣܘܠܩܐ',
            ar2: 'ܣܽܘܠܳܩܳܐ',
            id: '2206',
            dt1: 'Suloqo',
            es3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe/Gott ist gut”',
            dt2: '',
            ar3: '',
            es2: 'ܣܘܠܩܐ',
            dt3: '',
            es1: 'ܣܘܠܩܐ'
        },
        {
            dt2: '',
            ar3: '',
            dt1: 'Zenobia',
            bedeutung: 'weibl. Form zu Zenobio, Zeno; “Geschenk des Zeus”; die aramäische Königin Zenobia machte Palmyra/Tadmor in Syrien im 3. Jh. (267-272) zu einem Zentrum der aramäischen Kultur. Weitere Formen: Zenab, Zeyneb(e)',
            id: '1168',
            es2: 'ܙܢܘܒܝܐ',
            es1: 'ܙܢܘܒܝܐ',
            dt3: '',
            es3: '',
            ar2: 'ܙܶܢܳܘܒܺܝܰܐ',
            ar1: 'ܙܢܘܒܝܐ'
        },
        {
            dt1: 'Tehro',
            dt3: '',
            es2: 'ܬܗܪܐ',
            es3: '',
            es1: 'ܬܗܪܐ',
            ar1: 'ܬܗܪܐ',
            ar2: 'ܬܶܗܪܳܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäischen Ursprungs; “Zwilling(sbruder)”. Im NT ist der Hl. Thomas einer von 12 Jüngern Jesu Christi. Weitere Formen: Thoma, Toma',
            id: '2237',
            dt2: '',
            ar3: ''
        },
        {
            ar3: '',
            es1: 'ܡܟܣܐ',
            id: '648',
            dt1: 'Makso',
            dt2: '',
            ar1: 'ܡܟܣܐ',
            ar2: 'ܡܰܟܣܳܐ',
            dt3: '',
            es2: 'ܡܟܣܐ',
            es3: '',
            bedeutung: 'männl., “Tribut, Steuer, Zoll”'
        },
        {
            es1: 'ܐܒܝܡܠܟܐ',
            es2: 'ܐܒܝܡܠܟܐ',
            ar2: 'ܐܰܒܺܝܡܰܠܟܳܐ',
            ar1: 'ܐܒܝܡܠܟܐ',
            ar3: '',
            es3: '',
            id: '20',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs;  “mein Vater ist König”. Im AT begegnen Abimalko, König von Gerar zwischen Palästina und Ägypten (um 2000 v.Chr.), und Abimalko, Sohn Gideons (ca. 13. Jh. v.Chr.). Weitere Formen: Abimelek, Abimelech, Abimalak',
            dt2: '',
            dt3: '',
            dt1: 'Abimalko'
        },
        {
            es3: '',
            id: '2047',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “[Jahwe] hat mein Elend gesehen”. Im AT ist Rubil (syrische Form von Ruben) der älteste Sohn des Jakob/Israel und der Lea. Weitere Formen: Robil, Rubel, Ruben, Rubin',
            dt2: '',
            es2: 'ܪܨܢ',
            es1: 'ܪܨܢ',
            dt3: '',
            ar3: '',
            dt1: 'Reson',
            ar2: 'ܪܶܨܳܢ',
            ar1: 'ܪܨܢ'
        },
        {
            ar3: '',
            dt3: '',
            es1: 'ܒܗܝܐ',
            bedeutung: 'männl., syıischen Ursprungs; “Licht, Leuchte”',
            id: '1322',
            es3: '',
            dt2: '',
            es2: 'ܒܗܝܐ',
            ar2: 'ܒܰܗܺܝܰܐ',
            ar1: 'ܒܗܝܐ',
            dt1: 'Bahiya'
        },
        {
            ar3: '',
            bedeutung: 'männl., siehe Hosea',
            es2: 'ܗܘܫܥ',
            dt2: '',
            es1: 'ܗܘܫܥ',
            ar1: 'ܗܘܫܥ',
            dt1: 'Huschoh',
            id: '493',
            dt3: '',
            es3: '',
            ar2: 'ܗܽܘܫܳܥ'
        },
        {
            es2: 'ܓܢܬܐ',
            ar1: 'ܓܢܬܐ',
            ar3: '',
            es1: 'ܓܢܬܐ',
            dt1: 'Ganta',
            es3: '',
            bedeutung: 'weibl., “Garten, Paradies”, weitere Formen: Gantha',
            dt3: '',
            id: '365',
            ar2: 'ܓܰܢܬܰܐ',
            dt2: ''
        },
        {
            dt1: 'Joach',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe ist Bruder”',
            dt2: '',
            ar3: '',
            id: '551',
            es1: 'ܝܘܐܚ',
            ar2: 'ܝܽܘܐܳܚ',
            es3: '',
            ar1: 'ܝܘܐܚ',
            es2: 'ܝܘܐܚ',
            dt3: ''
        },
        {
            es2: 'ܝܠܐ',
            es1: 'ܝܠܐ',
            ar1: 'ܝܠܐ',
            id: '1123',
            dt1: 'Yalo',
            dt3: '',
            bedeutung: 'männl., “Eidechse, Echse, Krokodil”',
            dt2: '',
            ar2: 'ܝܰܠܳܐ',
            es3: '',
            ar3: ''
        },
        {
            id: '142',
            ar2: 'ܒܰܗܺܝܰܐ',
            dt1: 'Bahiya',
            ar3: '',
            bedeutung: 'weibl., “die sich schämt, schamhafte(s) Frau/Mädchen”',
            es3: '',
            dt3: '',
            ar1: 'ܒܗܝܐ',
            es1: 'ܒܗܝܐ',
            es2: 'ܒܗܝܐ',
            dt2: ''
        },
        {
            dt2: '',
            dt1: 'Sbita',
            ar1: 'ܨܒܝܬܐ',
            es2: 'ܨܒܝܬܐ',
            bedeutung: 'weibl., “die Begehrte” oder “sie ist erwünscht”; weitere Formen: Sbitha',
            dt3: '',
            es1: 'ܨܒܝܬܐ',
            ar2: 'ܨܒܺܝܬܰܐ',
            es3: '',
            ar3: '',
            id: '917'
        },
        {
            ar2: 'ܝܰܟܺܝܢ',
            bedeutung: 'weibl., “See, Teich”; weitere Formen: Jamtha, Yamta',
            ar1: 'ܝܟܝܢ',
            es1: 'ܝܟܝܢ',
            dt1: 'Yakin',
            es3: 'ܝܩܝܢ',
            dt2: '',
            id: '2310',
            es2: 'ܝܟܝܢ',
            dt3: '',
            ar3: 'ܝܰܩܺܝܢ'
        },
        {
            es3: '',
            bedeutung: 'männl., viele syrische Bischöfe, Gelehrte, Philosophen und Theologen tragen diesen Namen',
            ar2: 'ܒܰܪܪܰܟܺܝܒ',
            es2: 'ܒܪܪܟܝܒ',
            ar1: 'ܒܪܪܟܝܒ',
            id: '1361',
            ar3: '',
            dt2: '',
            dt3: '',
            dt1: 'Barrakib',
            es1: 'ܒܪܪܟܝܒ'
        },
        {
            es1: 'ܐܝܓ݂ܢܐܛܝܘܣ',
            dt1: 'Ignatius',
            es3: '',
            es2: 'ܐܝܓ݂ܢܐܛܝܘܣ',
            bedeutung: '',
            ar2: 'ܐܺܝܓ݂ܢܰܐܛܺܝܽܘܣ',
            id: '1683',
            ar1: 'ܐܝܓܢܐܛܝܘܣ',
            ar3: '',
            dt2: '',
            dt3: ''
        },
        {
            ar3: '',
            es1: 'ܪܒܫܡ',
            ar2: 'ܪܰܒܫܶܡ',
            es2: 'ܪܒܫܡ',
            dt2: '',
            dt1: 'Rabschem',
            ar1: 'ܪܒܫܡ',
            id: '2021',
            es3: '',
            dt3: '',
            bedeutung: 'männl., “Fahrer, Reiter Gottes”. Rakibel war ein Schutzgott der aramäischen Dynastie von Samal-Jau` di (=links, d.h. Norden: Nordstaat eines aramäischen Stammes)'
        },
        {
            dt1: 'Jeremia',
            ar2: 'ܐܶܪܰܡܝܳܐ',
            bedeutung: 'männl., “Jesus ist unsere Hoffnung”',
            es1: 'ܐܪܡܝܐ',
            es3: 'ܐܪܡܝܐ',
            es2: 'ܐܪܡܝܐ',
            ar1: 'ܐܪܡܝܐ',
            dt3: '',
            ar3: 'ܐܶܪܰܡܝܰܐ',
            id: '1721',
            dt2: ''
        },
        {
            dt3: '',
            ar2: 'ܐܰܡܺܝܪܬܰܐ',
            es2: 'ܐܡܝܪܬܐ',
            bedeutung: 'männl., “mein Onkel ist gerecht” oder “mein Volk ist gerecht”',
            ar1: 'ܐܡܝܪܬܐ',
            dt1: 'Amirta',
            ar3: '',
            id: '1256',
            es3: '',
            dt2: '',
            es1: 'ܐܡܝܪܬܐ'
        },
        {
            es1: 'ܚܢܢܝܠ',
            dt1: 'Hanniel',
            dt2: '',
            bedeutung: 'männl., “Jahwe hat gesehen”; weitere Formen: Hasaya, Hazoyo, Hasojo, Hasoyo',
            ar3: '',
            es3: '',
            id: '1627',
            ar2: 'ܚܰܢܢܺܝܶܠ',
            es2: 'ܚܢܢܝܠ',
            ar1: 'ܚܢܢܝܠ',
            dt3: ''
        },
        {
            es3: '',
            ar1: 'ܣܘܥܕ',
            bedeutung: '',
            dt2: '',
            dt1: 'Suad',
            es1: 'ܣܘܥܕ',
            ar2: 'ܣܽܘܥܰܕ',
            ar3: '',
            dt3: '',
            es2: 'ܣܘܥܕ',
            id: '2203'
        },
        {
            ar1: 'ܫܪܒܬ',
            es1: 'ܫܪܒܬ',
            ar3: '',
            dt1: 'Scharbat',
            id: '2135',
            bedeutung: 'weibl., “Bitte, Bestellung, Wunsch, Ersuchen, Gesuch, Konsultation, Beratung”; weitere Formen: Scheletha',
            es2: 'ܫܪܒܬ',
            ar2: 'ܫܰܪܒܰܬ',
            dt2: '',
            dt3: '',
            es3: ''
        },
        {
            ar3: 'ܣܰܠܳܡܳܐ',
            es3: 'ܣܠܡܐ',
            dt2: '',
            id: '896',
            es1: 'ܣܠܡܐ',
            ar2: 'ܫܠܶܝܡܽܘܢ',
            dt1: 'Salomo',
            dt3: '',
            es2: 'ܣܠܡܐ',
            bedeutung: 'männl., siehe Schleymun; weitere Formen: Salomon',
            ar1: 'ܫܠܝܡܘܢ'
        },
        {
            dt3: '',
            ar2: 'ܐܰܕܺܝܢܰܐ',
            es2: 'ܐܕܝܢܐ',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “weich, zart, schlank”',
            dt1: 'Adina',
            ar1: 'ܐܕܝܢܐ',
            ar3: '',
            es3: '',
            id: '1214',
            dt2: '',
            es1: 'ܐܕܝܢܐ'
        },
        {
            ar3: '',
            dt2: '',
            es1: 'ܝܒܠ',
            ar2: 'ܝܰܒܶܠ',
            id: '2302',
            dt1: 'Yabel',
            bedeutung: '',
            es2: 'ܝܒܠ',
            dt3: '',
            es3: '',
            ar1: 'ܝܒܠ'
        },
        {
            dt2: '',
            es3: '',
            es1: 'ܝܫܘܥ',
            es2: 'ܝܫܘܥ',
            dt1: 'Jeschu',
            ar1: 'ܝܫܘܥ',
            ar2: 'ܝܶܫܽܘܥ',
            dt3: '',
            ar3: '',
            id: '1725',
            bedeutung: ''
        },
        {
            bedeutung: 'männl., akkadischen Ursprungs; eventuell: “Gott/Macht”. Il oder El wird als höchster Gott von den Phöniziern und den heidnischen Aramäern verehrt. In der Bibel bzw. in der Patriarchenzeit wird der Gott Abrahams, Isaaks und Jakobs mit dem Hochgott Il/El gleichgesetzt. Weitere Formen: El',
            id: '1676',
            ar3: '',
            dt2: '',
            dt1: 'Hudatha',
            ar1: 'ܚܘܕܬ݂ܐ',
            es2: 'ܚܘܕܬܐ',
            ar2: 'ܚܽܘܕܰܬ݂ܰܐ',
            es3: '',
            es1: 'ܚܘܕܬܐ',
            dt3: ''
        },
        {
            dt1: 'Zokuta',
            ar1: 'ܙܟܘܬܐ',
            ar3: '',
            bedeutung: '',
            es3: '',
            dt2: '',
            es1: 'ܙܟܘܬܐ',
            dt3: '',
            id: '2366',
            ar2: 'ܙܳܟܽܘܬܰܐ',
            es2: 'ܙܟܘܬܐ'
        },
        {
            dt1: 'Berula',
            ar3: '',
            es1: 'ܒܪܘܠܐ',
            dt3: '',
            es2: 'ܒܪܘܠܐ',
            es3: '',
            bedeutung: 'weibl., “Perle, Edelstein”',
            dt2: '',
            id: '223',
            ar1: 'ܒܪܘܠܐ',
            ar2: 'ܒܶܪܽܘܠܰܐ'
        },
        {
            es3: 'ܢܚܡܝܐ',
            dt1: 'Nehemia',
            dt3: '',
            es2: 'ܢܚܡܝܐ',
            dt2: 'Nehemya',
            ar1: 'ܢܚܡܝܐ',
            ar3: 'ܢܚܰܡܝܳܐ',
            es1: 'ܢܚܡܝܐ',
            ar2: 'ܢܶܚܰܡܝܰܐ',
            id: '758',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott hat getröstet, Jahwe tröstet”. Im AT gilt Nehemia als Verfasser eines nach ihm benannten Geschichts-buches (um 450 v. Chr.). Weitere Formen: Nhamio, Nhamia, Nehemja'
        },
        {
            ar2: 'ܢܺܝܗܰܬ',
            ar1: 'ܢܝܗܬ',
            es1: 'ܢܝܗܬ',
            dt1: 'Nihat',
            id: '766',
            es3: 'ܢܐܗܬ',
            bedeutung: '',
            es2: 'ܢܝܗܬ',
            dt3: '',
            dt2: '',
            ar3: 'ܢܺܐܗܰܬ'
        },
        {
            es3: '',
            es1: 'ܢܩܝܝܐ',
            ar3: '',
            ar2: 'ܢܰܩܺܝܝܳܐ',
            id: '1937',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott hat gegeben” oder “von Gott gegeben”. Im NT wird Nathanael als einer der ersten Jünger Jesu Christi gerechnet, dessen Name allerdings in den ntl. Apostellisten nicht vorkommt, nur im Johannesevangelium. Manchmal wird er auch mit Bartolomäus identiﬁziert',
            dt2: '',
            dt1: 'Naqija',
            dt3: '',
            es2: 'ܢܩܝܝܐ',
            ar1: 'ܢܩܝܝܐ'
        },
        {
            id: '1066',
            es1: 'ܛܘܒܝܛ',
            dt3: '',
            ar1: 'ܛܘܒܝܛ',
            dt1: 'Tobit',
            es3: '',
            es2: 'ܛܘܒܝܛ',
            dt2: '',
            ar2: 'ܛܳܘܒܺܝܛ',
            bedeutung: 'männl., Naftaliter, Vater des Tobia; Verfasser eines alttestamentlichen Buches; siehe Tobia',
            ar3: ''
        },
        {
            bedeutung: 'weibl., “Beispiel, Spiegel”; weitere Formen: Mahsita ',
            es2: 'ܡܚܙܝܬܐ',
            id: '647',
            es3: '',
            es1: 'ܡܚܙܝܬܐ',
            dt2: '',
            ar3: '',
            dt3: '',
            ar2: 'ܡܰܚܙܺܝܬܰܐ',
            dt1: 'Mahzita',
            ar1: 'ܡܚܙܝܬܐ'
        },
        {
            dt1: 'Tobia',
            ar2: 'ܛܳܘܒܺܝܰܐ',
            es2: 'ܛܘܒܝܐ',
            id: '1063',
            dt3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott ist gnädig, Gott ist gut”. Im AT ist Tobia der fromme Sohn, der seinen Vater geheilt hat; nach ihm ist ein alttestamentliches Buch (2. Jh. v.Chr.) benannt. Weitere Formen: Tobit, Tubit, Tobias, Tobijah, Tobijahu',
            ar1: 'ܛܘܒܝܐ',
            dt2: '',
            ar3: '',
            es1: 'ܛܘܒܝܐ',
            es3: ''
        },
        {
            es1: 'ܐܠܚܢܢ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott schafft Gnade”; weitere Formen: Ilhanan',
            es2: 'ܐܠܚܢܢ',
            dt2: 'Ilhanan',
            id: '296',
            dt1: 'Elhanan',
            es3: '',
            ar1: 'ܐܠܚܢܢ',
            ar3: '',
            ar2: 'ܐܶܠܰܚܢܰܢ',
            dt3: ''
        },
        {
            es2: 'ܫܚܠܘܦܐ',
            es3: 'ܫܟ݂ܠܘܦܐ',
            es1: 'ܫܚܠܘܦܐ',
            ar2: 'ܫܰܚܠܽܘܦܰܐ',
            dt3: '',
            ar3: 'ܫܰܟ݂ܠܽܘܦܰܐ',
            bedeutung: 'männl., “Wechsel, Austausch, Unterschied”. Schahlupa (258 - 273) war Bischof von Arbela. Weitere Formen: Schuhlofo, Schuhlapa',
            id: '926',
            dt1: 'Schahlupa',
            dt2: '',
            ar1: 'ܫܚܠܘܦܐ'
        },
        {
            ar3: 'ܒܶܠܛܫܰܨܰܪ',
            ar1: 'ܒܠܬܨܪ',
            id: '1329',
            ar2: 'ܒܰܠܬܰܨܰܪ',
            dt2: '',
            es2: 'ܒܠܬܨܪ',
            es3: 'ܒܠܛܫܨܪ',
            bedeutung: '',
            es1: 'ܒܠܬܨܪ',
            dt1: 'Balthasar',
            dt3: ''
        },
        {
            ar2: 'ܐܽܘܪܗܰܝ',
            ar3: '',
            dt2: 'Urhai',
            es3: '',
            dt3: '',
            id: '2280',
            dt1: 'Urhay',
            es2: 'ܐܘܪܗܝ',
            bedeutung: 'weibl., “Land der Hoffnung”',
            es1: 'ܐܘܪܗܝ',
            ar1: 'ܐܘܪܗܝ'
        },
        {
            ar3: '',
            ar1: 'ܐܒܪܐܣ',
            dt1: 'Bar Hebraeus',
            bedeutung: '',
            dt3: 'Äbreyos',
            es3: '',
            id: '154',
            dt2: 'Bar Hebräus',
            es1: 'ܐܒܪܐܣ',
            es2: 'ܐܒܪܐܣ',
            ar2: 'ܒܪ ܐܒܪܐܣ'
        },
        {
            bedeutung: 'weibl., “heilen, gesund machen”; weitere Formen: Susi',
            dt3: '',
            es2: 'ܨܠܝܒܙܟ݂ܐ',
            id: '2190',
            es3: 'ܨܠܝܒܐ ܙܟ݂ܐ',
            dt1: 'Slibozkho',
            ar1: 'ܨܠܝܒܙܟ݂ܐ',
            ar3: 'ܨܠܺܝܒܳܐ ܙܟ݂ܳܐ',
            es1: 'ܨܠܝܒܙܟ݂ܐ',
            ar2: 'ܨܠܺܝܒܳܙܟ݂ܳܐ',
            dt2: ''
        },
        {
            ar1: 'ܡܠܝܛܐ',
            dt1: 'Melita',
            dt3: '',
            ar3: '',
            es1: 'ܡܠܝܛܐ',
            id: '1884',
            ar2: 'ܡܶܠܺܝܛܰܐ',
            es2: 'ܡܠܝܛܐ',
            es3: '',
            bedeutung: 'männl., syrischen Ursprungs, “Gedicht, Ansprache, Rede, Predigt, Satz”; weitere Formen: Memra',
            dt2: ''
        },
        {
            dt2: '',
            es2: 'ܗܕܕܡܠܟܐ',
            dt1: 'Hadadmalko',
            id: '1600',
            ar1: 'ܗܕܕܡܠܟܐ',
            ar3: 'ܚܰܕܰܕܡܰܠܟܳܐ',
            es1: 'ܗܕܕܡܠܟܐ',
            es3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “[geboren an einem] Festtag“. Haggai ist im AT ein Prophet (um 520 v.Chr.) und Zeitgenosse des Sacharja. Weitere Formen: Hagay',
            dt3: '',
            ar2: 'ܗܰܕܰܕܡܰܠܟܳܐ'
        },
        {
            es2: 'ܠܘܝܬܐ',
            dt1: 'Levita',
            ar3: '',
            dt2: '',
            es1: 'ܠܘܝܬܐ',
            ar1: 'ܠܘܝܬܐ',
            bedeutung: 'weibl., syrischen Ursprungs; “Freundschaft”; Weitere Formen: Levitha, Lvita, Lvitha',
            ar2: 'ܠܶܘܺܝܬܰܐ',
            id: '630',
            es3: '',
            dt3: ''
        },
        {
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott ist mein Glück”',
            es2: 'ܓܒܐܝܠ',
            dt3: '',
            ar3: '',
            es1: 'ܓܒܐܝܠ',
            ar1: 'ܓܒܐܝܠ',
            dt1: 'Gabil',
            ar2: 'ܓܰܒܐܺܝܠ',
            dt2: '',
            es3: '',
            id: '1540'
        },
        {
            ar2: 'ܡܺܐܡܪܳܐ',
            dt3: '',
            dt1: 'Mimro',
            ar1: 'ܡܐܡܪܐ',
            es3: '',
            dt2: '',
            id: '704',
            es1: 'ܡܐܡܪܐ',
            es2: 'ܡܐܡܪܐ',
            bedeutung: 'männl., syrischen Ursprungs, “Gedicht, Ansprache, Rede, Predigt, Satz”; weitere Formen: Memra',
            ar3: ''
        },
        {
            ar3: 'ܐܳܪܳܡ',
            ar1: 'ܐܪܡ',
            dt2: 'Orom',
            dt3: '',
            id: '1273',
            bedeutung: 'weibl., Arbil(a) ist eine ehemals aramäische Stadt im heutigen Irak',
            dt1: 'Aram',
            es1: 'ܐܪܡ',
            es2: 'ܐܪܡ',
            es3: 'ܐܪܡ',
            ar2: 'ܐܰܪܰܡ'
        },
        {
            ar3: '',
            bedeutung: 'männl., Bus ist im AT Sohn Nahors, Bruder des Us/Uz, Kemuel, Kesed, Haso, Pildasch, Jidlaf und Betuel. Weitere Formen: Buz',
            ar1: 'ܒܘܙ',
            es1: 'ܒܘܙ',
            ar2: 'ܒܽܘܙ',
            id: '240',
            es3: '',
            es2: 'ܒܘܙ',
            dt3: '',
            dt1: 'Buz',
            dt2: 'Bus'
        },
        {
            ar1: 'ܙܟܝ',
            es1: 'ܙܟܝ',
            es3: '',
            id: '2346',
            ar2: 'ܙܰܟܰܝ',
            es2: 'ܙܟܝ',
            dt2: 'Zakay',
            bedeutung: 'männl., “kleiner Olivenbaum”',
            ar3: '',
            dt1: 'Zakai',
            dt3: 'Sakai'
        },
        {
            es1: 'ܚܙܢܐ',
            ar2: 'ܚܰܙܢܶܐ',
            id: '457',
            es2: 'ܚܙܢܐ',
            dt1: 'Hazne',
            ar1: 'ܚܙܢܐ',
            ar3: '',
            es3: '',
            dt2: '',
            bedeutung: 'weibl., kurdischen Urspıungs; “Trauer, Kummer, die Traurige”',
            dt3: ''
        },
        {
            dt1: 'Hebaeus',
            es3: 'ܒܪ ܐܒܪܐܣ',
            ar1: 'ܐܒܪܐܣ',
            dt3: '',
            es1: 'ܐܒܪܐܣ',
            ar3: 'ܒܪ ܐܒܪܐܣ',
            dt2: 'Hebräus',
            bedeutung: 'männl., “Jesus hat Mitleid mit mir”',
            id: '1647',
            ar2: 'ܐܒܪܐܣ',
            es2: 'ܐܒܪܐܣ'
        },
        {
            ar3: '',
            es1: 'ܒܪܫܢܬܐ',
            es3: '',
            dt1: 'Barschato',
            ar1: 'ܒܪܫܢ̱ܬܐ',
            bedeutung: 'männl., “Sohn des Friedens”; weitere Formen: Barschloma',
            ar2: 'ܒܰܪܫܰܢ̱ܬܳܐ',
            dt2: '',
            es2: 'ܒܪܫܢܬܐ',
            dt3: '',
            id: '1366'
        },
        {
            id: '2204',
            es2: 'ܣܘܓܝܬܐ',
            bedeutung: '',
            es3: '',
            dt1: 'Sugita',
            ar1: 'ܣܘܓܝܬܐ',
            ar3: '',
            ar2: 'ܣܽܘܓܺܝܬܰܐ',
            es1: 'ܣܘܓܝܬܐ',
            dt2: '',
            dt3: ''
        },
        {
            es2: 'ܡܥܢܘ',
            bedeutung: 'männl., Vater des Königs Abgar “Ukkomo”',
            dt3: '',
            dt2: '',
            id: '658',
            ar3: '',
            dt1: 'Manu',
            ar2: 'ܡܰܥܰܢܽܘ',
            es1: 'ܡܥܢܘ',
            es3: '',
            ar1: 'ܡܥܢܘ'
        },
        {
            dt1: 'Antar',
            dt2: '',
            es3: '',
            ar1: 'ܥܢܬܪ',
            es1: 'ܥܢܬܪ',
            id: '90',
            ar3: '',
            bedeutung: 'männl., arabischen Ursprungs; “tapfer, Held”',
            dt3: '',
            ar2: 'ܥܰܢܬܰܪ',
            es2: 'ܥܢܬܪ'
        },
        {
            dt2: '',
            ar1: 'ܪܒܝܢ',
            es2: 'ܪܒܝܢ',
            id: '2019',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “wild, stürmisch”. Im AT ist Rahab eine Dime aus Jericho, die die Kundschafter Josuas (Bar-Nun) rettet, indem sie sie in Ihrem Haus versteckt; das NT lobt die Tat von Rahab wegen Ihres Glaubens und Ihrer Gastfreundschaft, bezeichnet sie als eine Ahnfrau Jesu. Weitere Formen: Rohob',
            ar3: '',
            dt3: '',
            ar2: 'ܪܰܒܺܝܢ',
            es3: '',
            dt1: 'Rabin',
            es1: 'ܪܒܝܢ'
        },
        {
            ar3: '',
            bedeutung: '',
            dt1: 'Dodo',
            ar1: 'ܕܕܐ',
            dt2: '',
            ar2: 'ܕܳܕܳܐ',
            id: '1462',
            es2: 'ܕܕܐ',
            es1: 'ܕܕܐ',
            dt3: '',
            es3: ''
        },
        {
            ar2: 'ܒܰܪܛܺܝܡܰܝ',
            ar3: '',
            id: '1371',
            dt3: '',
            es3: '',
            ar1: 'ܒܪܛܝܡܝ',
            dt2: '',
            dt1: 'Bartimai',
            es2: 'ܒܪܛܝܡܝ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gesegneter”. Im AT ist Baruch, Sohn des Nerija, Sekretär des Propheten Jeremia, Verfasser eines alttestamentlichen Buches. Weitere Formen: Barukh',
            es1: 'ܒܪܛܝܡܝ'
        },
        {
            es2: 'ܐܚܐ',
            ar1: 'ܐܚܐ',
            ar3: '',
            es1: 'ܐܚܐ',
            dt3: '',
            dt2: '',
            bedeutung: 'männl., “Bruder”. In der syrisch-orthodoxen Kirche von Antiochien ist Aho ein Heiliger (420-525); Festtag: 5. Mai.',
            ar2: 'ܐܰܚܳܐ',
            id: '61',
            dt1: 'Aho',
            es3: ''
        },
        {
            id: '1474',
            ar2: 'ܐܶܒܪܳܐ',
            es2: 'ܐܒܪܐ',
            ar3: '',
            es3: '',
            ar1: 'ܐܒܪܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott schafft Gnade”; weitere Formen: Ilhanan',
            es1: 'ܐܒܪܐ',
            dt3: '',
            dt2: '',
            dt1: 'Ebro'
        },
        {
            ar2: 'ܛܒܺܝܒܬܰܐ',
            dt3: '',
            dt1: 'Tbibta',
            bedeutung: 'weibl., siehe Theophoros',
            ar3: '',
            id: '2234',
            ar1: 'ܛܒܝܒܬܐ',
            dt2: '',
            es1: 'ܛܒܝܒܬܐ',
            es3: '',
            es2: 'ܛܒܝܒܬܐ'
        },
        {
            es1: 'ܣܝܕܐ',
            dt3: '',
            bedeutung: 'männl., siehe Schemschun',
            dt2: 'Sayde',
            es2: 'ܣܝܕܐ',
            ar1: 'ܣܝܕܐ',
            id: '2085',
            ar3: '',
            dt1: 'Saide',
            es3: '',
            ar2: 'ܣܰܝܕܶܐ'
        },
        {
            dt2: 'Afrem',
            ar3: '',
            es1: 'ܐܦܪܝܡ',
            id: '1478',
            es2: 'ܐܦܪܝܡ',
            dt3: 'Ephram',
            ar2: 'ܐܰܦܪܶܝܡ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “mein Gott ist Jahwe”. Im AT ist Elijo ein Prophet (um 850 v.Chr.). Weitere Formen: Elija, Elias, Ilijo, Elia',
            ar1: 'ܐܦܪܝܡ',
            dt1: 'Efrim',
            es3: ''
        },
        {
            bedeutung: 'männl., “Sonne”. Schamasch war ein von den heidnischen Aramäern verehrter Sonnengott (und Gott des Rechts)',
            dt1: 'Schainat',
            es3: '',
            ar3: '',
            ar1: 'ܫܝܢܬ',
            dt2: '',
            ar2: 'ܫܰܝܢܰܬ',
            id: '2119',
            es2: 'ܫܝܢܬ',
            dt3: '',
            es1: 'ܫܝܢܬ'
        },
        {
            es2: 'ܒܪܒܗܠܘܠ',
            dt1: 'Bar Bahlul',
            ar1: 'ܒܪܒܗܠܘܠ',
            es1: 'ܒܪܒܗܠܘܠ',
            ar2: 'ܒܰܪܒܰܗܠܽܘܠ',
            id: '152',
            ar3: '',
            dt2: '',
            es3: '',
            bedeutung: 'männl., “Sohn des Bahlul”, Lexikograph (um 1000 n.Chr.)',
            dt3: ''
        },
        {
            dt1: 'Diana',
            bedeutung: '',
            es3: 'ܕܝܢܐ',
            es1: 'ܕܐܝܢܐ',
            dt3: '',
            ar1: 'ܕܐܝܢܐ',
            id: '271',
            ar2: 'ܕܺܐܝܰܢܳܐ',
            dt2: '',
            es2: 'ܕܐܝܢܐ',
            ar3: 'ܕܝܰܢܰܐ'
        },
        {
            dt2: '',
            dt1: 'Duschro',
            id: '287',
            ar1: 'ܕܘܫܪܐ',
            es2: 'ܕܘܫܪܐ',
            es3: '',
            dt3: '',
            es1: 'ܕܘܫܪܐ',
            bedeutung: 'männl., “Unkraut”',
            ar3: '',
            ar2: 'ܕܽܘܫܪܳܐ'
        },
        {
            es1: 'ܚܙܢܝ',
            dt3: '',
            ar2: 'ܚܰܙܢܺܝ',
            dt1: 'Hazni',
            es2: 'ܚܙܢܝ',
            bedeutung: 'männl., aramäischen Ursprungs; “Traum”',
            dt2: '',
            ar3: '',
            ar1: 'ܚܙܢܝ',
            id: '1645',
            es3: ''
        },
        {
            es3: '',
            dt2: '',
            dt1: 'Dabar',
            es2: 'ܕܒܪ',
            es1: 'ܕܒܪ',
            bedeutung: 'männl., “gereinigt geputzt, gesäubert”',
            ar1: 'ܕܒܪ',
            ar2: 'ܕܰܒܰܪ',
            id: '1428',
            dt3: '',
            ar3: ''
        },
        {
            es1: 'ܪܚܒ',
            ar1: 'ܪܚܒ',
            ar2: 'ܪܰܚܰܒ',
            ar3: 'ܪܳܚܳܒ',
            bedeutung: 'weibl., ursprünglich männlich, “Antilope, Reh”; weitere Formen: Raima, Rima',
            id: '2028',
            dt1: 'Rahab',
            es3: 'ܪܚܒ',
            dt3: '',
            es2: 'ܪܚܒ',
            dt2: ''
        },
        {
            es3: '',
            es1: 'ܓܡܝܪܘܬܐ',
            dt3: '',
            ar2: 'ܓܡܺܝܪܽܘܬܰܐ',
            es2: 'ܓܡܝܪܘܬܐ',
            ar3: '',
            dt2: '',
            dt1: 'Gmiruta',
            ar1: 'ܓܡܝܪܘܬܐ',
            bedeutung: 'weibl., “Vollkommenheit, Vollendung”; weitere Formen: Gmirutha',
            id: '389'
        },
        {
            ar2: 'ܥܰܒܕܳܐ',
            id: '1186',
            es3: '',
            es1: 'ܥܒܕܐ',
            dt2: '',
            ar3: '',
            dt3: '',
            ar1: 'ܥܒܕܐ',
            dt1: 'Abdo',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Knecht, Diener”. Abdo/Abdu Sohn von Mazur war König von Edessa (127-120 v.Chr.). Weitere Formen: Ebed, Abud, Abdu',
            es2: 'ܥܒܕܐ'
        },
        {
            ar1: 'ܕܘܠܒܐܢܝ',
            bedeutung: 'männl., “Feder, Flügel”',
            dt3: '',
            es2: 'ܕܘܠܒܐܢܝ',
            dt1: 'Dulabani',
            ar3: '',
            es3: '',
            es1: 'ܕܘܠܒܐܢܝ',
            id: '1468',
            ar2: 'ܕܽܠܰܒܰܐܢܺܝ',
            dt2: 'Dolabani'
        },
        {
            es1: 'ܐܝܘܒ',
            bedeutung: 'männl, aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs. Bedeutung unklar, vielleicht “den man als Feind behandelt” oder “der sich gegen Gott wendet” oder “Wo ist der Vater?”. Im AT ist Ayyub ein Prophet und zugleich Verfasser eines Buches. Weitere Formen: Hiob, Eyup, Eyüp, Job, Ijob',
            ar3: 'ܐܰܝܽܘܒ',
            id: '136',
            ar1: 'ܐܝܘܒ',
            dt3: '',
            es3: 'ܐܝܘܒ',
            dt1: 'Ayub',
            es2: 'ܐܝܘܒ',
            ar2: 'ܐܺܝܽܘܒ',
            dt2: 'Ayyub'
        },
        {
            dt1: 'Hana',
            es2: 'ܚܢܐ',
            es1: 'ܚܢܐ',
            ar2: 'ܚܰܢܰܐ',
            ar3: 'ܚܰܐܢܰܐ',
            dt2: '',
            ar1: 'ܚܢܐ',
            bedeutung: 'männl.; ursprünglich: weibl., Kurzform zu Johanna; heute Kurzform zu Johannes/Johannun, aramäisch-hebräischen Ursprungs. Im AT begegnet Hanna alsdie Mutter des Samuel und als die Frau des Tobias. Weitere Formen: Johannes, Johamıun, Johannon; Yuhamun, Yuhannon, Hane, Hanne, Hanno',
            dt3: '',
            es3: 'ܚܐܢܐ',
            id: '1618'
        },
        {
            dt1: 'Hul',
            ar1: 'ܚܘܠ',
            dt2: '',
            ar2: 'ܚܽܘܠ',
            ar3: '',
            es3: '',
            es2: 'ܚܘܠ',
            id: '1677',
            dt3: '',
            es1: 'ܚܘܠ',
            bedeutung: 'männl., syrischen Ursprungs; “gib Gott!”; weitere Formen: Elhab'
        },
        {
            dt1: 'Schamaschnuri',
            bedeutung: 'männl., “Nuri, der Diener”. König der Aramäer von Beth Bakhjani (9. Jh. v. Chr.), Vater von Hadajisi, König von Guzan. Weitere Formen: Schamasch-Nui',
            ar3: '',
            dt3: '',
            ar2: 'ܫܰܡܰܫܢܽܘܪܺܝ',
            es3: '',
            id: '937',
            ar1: 'ܫܡܫܢܘܪܝ',
            es1: 'ܫܡܫܢܘܪܝ',
            es2: 'ܫܡܫܢܘܪܝ',
            dt2: ''
        },
        {
            ar1: 'ܝܫܘܥ',
            dt3: '',
            id: '1733',
            es1: 'ܝܫܘܥ',
            es3: '',
            ar3: '',
            ar2: 'ܝܶܫܽܘܥ',
            dt2: '',
            es2: 'ܝܫܘܥ',
            dt1: 'Jesus',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe ist Gott”. Im AT ist Joel ein Prophet in Jerusalem (4. Jh. v. Chr.), Verfasser eines Buches. Weitere Formen: Youil'
        },
        {
            es3: '',
            ar1: 'ܫܘܒܚܐ',
            es2: 'ܫܘܡܚܐ',
            dt2: '',
            dt1: 'Schubho',
            dt3: '',
            es1: 'ܫܘܡܚܐ',
            ar2: 'ܫܽܘܒܚܳܐ',
            ar3: '',
            id: '973',
            bedeutung: 'männl., “Lob, Preis, Glorie, Pracht”'
        },
        {
            bedeutung: '',
            ar1: 'ܟܪܚܐ',
            es3: 'ܟܘܪܚܐ',
            dt3: '',
            id: '1781',
            dt2: '',
            dt1: 'Kerho',
            es1: 'ܟܪܚܐ',
            es2: 'ܟܪܚܐ',
            ar3: 'ܟܽܘܪܚܳܐ',
            ar2: 'ܟܶܪܚܳܐ'
        },
        {
            es2: 'ܐܕܡ',
            es1: 'ܐܕܡ',
            dt2: '',
            bedeutung: 'männl., aus der Bibel übernommener Vomame aramäisch-hebräischen Ursprungs; “Mann aus roter Erde”. Im AT ist Adam der Stammvater der Menschheit. Weitere Formen: Odom, Adamo',
            dt1: 'Adam',
            ar2: 'ܐܰܕܰܡ',
            ar3: 'ܐܳܕܳܡ',
            es3: 'ܐܕܡ',
            id: '1211',
            dt3: '',
            ar1: 'ܐܕܡ'
        },
        {
            id: '1038',
            es1: 'ܛܪܩܐ',
            ar1: 'ܛܪܩܐ',
            dt3: '',
            dt1: 'Tarqo',
            dt2: '',
            ar3: '',
            es3: '',
            bedeutung: 'männl., “Leiter, Führer, Haupt, Kräftiger, Erhabener”',
            ar2: 'ܛܰܪܩܳܐ',
            es2: 'ܛܪܩܐ'
        },
        {
            ar2: 'ܫܰܢܬܰܐܠ',
            id: '1424',
            dt2: '',
            ar1: 'ܫܢܬܠ',
            ar3: 'ܫܰܢܬܰܠ',
            es2: 'ܫܢܬܠ',
            es1: 'ܫܢܬܠ',
            es3: 'ܫܢܬܠ',
            dt3: '',
            dt1: 'Chantal',
            bedeutung: 'männl., heißt Christusträger und  ist ein frühchristlicher Märtyrer vermutlich des 3. oder beginnenden 4. Jahrhunderts. Er wird in der katholischen und der orthodoxen Kirche als Heiliger verehrt'
        },
        {
            dt1: 'Aaron',
            es3: '',
            ar2: 'ܐܰܗܪܽܘܢ',
            dt2: 'Ahrun',
            ar3: '',
            id: '1',
            ar1: 'ܐܗܪܘܢ',
            dt3: 'Aron',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Bergbewohner, Erleuchteter”. Im AT ist Ahrun der ältere Bruder von Moses aus dem Stamm Levi und Ahnherr der Priesterklasse. Weitere Formen: Aaron, Aron',
            es2: 'ܐܗܪܘܢ',
            es1: 'ܐܗܪܘܢ'
        },
        {
            es1: 'ܢܐܬܠܝ',
            ar2: 'ܢܰܐܬܰܠܺܝ',
            es3: '',
            bedeutung: 'männl., “Tiger”',
            ar3: '',
            ar1: 'ܢܐܬܠܝ',
            id: '1943',
            dt3: '',
            dt2: '',
            dt1: 'Natali',
            es2: 'ܢܐܬܠܝ'
        },
        {
            bedeutung: 'weibl., aus dem Griechischen übernommener Vorname; “die Sonnenhafte”. Heleni war die Mutter des Kaisers Konstantin des Großen (4. Jh.). Weitere Formen: Helena, Helene',
            ar1: 'ܗܠܢܝ',
            ar2: 'ܗܶܠܶܢܺܝ',
            dt3: '',
            es1: 'ܗܠܢܝܝ',
            es2: 'ܗܠܢܝܝ',
            es3: '',
            dt2: '',
            dt1: 'Heleni',
            id: '465',
            ar3: ''
        },
        {
            dt3: '',
            ar2: 'ܫܶܡܫܽܘܢ',
            es3: 'ܫܡܥܘܢ',
            es1: 'ܫܡܫܘܢ',
            es2: 'ܫܡܫܘܢ',
            dt1: 'Schemschun',
            id: '2149',
            dt2: 'Schamun',
            bedeutung: 'männl., “du bist mein Frieden”',
            ar1: 'ܫܡܫܘܢ',
            ar3: 'ܫܰܡܥܽܘܢ'
        },
        {
            ar1: 'ܪܒܐܝܠ',
            dt1: 'Rabil',
            id: '826',
            ar3: '',
            es1: 'ܪܒܐܝܠ',
            bedeutung: 'männl., “mein Gott ist groß, erhaben”',
            dt3: '',
            es2: 'ܪܒܐܝܠ',
            es3: '',
            dt2: '',
            ar2: 'ܪܰܒܐܺܝܠ'
        },
        {
            ar1: 'ܫܪܒܠܐ',
            dt3: '',
            es1: 'ܫܪܒܠܐ',
            dt2: '',
            dt1: 'Scharbela',
            es2: 'ܫܪܒܠܐ',
            id: '2137',
            es3: '',
            ar3: '',
            bedeutung: 'männl., “Name Gottes”',
            ar2: 'ܫܰܪܒܶܠܰܐ'
        },
        {
            id: '570',
            bedeutung: '',
            dt2: '',
            dt3: '',
            es2: 'ܟܦܪܐ',
            dt1: 'Kafro ',
            es1: 'ܟܦܪܐ',
            ar1: 'ܟܦܪܐ',
            es3: '',
            ar3: '',
            ar2: 'ܟܰܦܪܳܐ'
        },
        {
            ar2: 'ܓܰܙܳܐ',
            dt2: '',
            es3: 'ܓܐܙܐ',
            es1: 'ܓܙܐ',
            dt1: 'Gazo',
            es2: 'ܓܙܐ',
            ar1: 'ܓܙܐ',
            bedeutung: 'männl., “Pfeil”',
            id: '1556',
            ar3: 'ܓܰܐܙܳܐ',
            dt3: ''
        },
        {
            dt3: '',
            es1: 'ܙܗܝܬܐ',
            dt2: '',
            id: '2340',
            dt1: 'Zahita',
            bedeutung: '',
            es2: 'ܙܗܝܬܐ',
            ar2: 'ܙܰܗܺܝܬܰܐ',
            ar1: 'ܙܗܝܬܐ',
            ar3: '',
            es3: ''
        },
        {
            id: '408',
            es2: 'ܚܒܨܘܢܐ',
            es1: 'ܚܒܨܘܢܐ',
            dt2: 'Habzuno',
            ar3: 'ܚܰܒܣܽܘܢܳܐ',
            ar2: 'ܚܰܒܨܽܘܢܳܐ',
            es3: 'ܚܒܣܘܢܐ',
            dt1: 'Habsuno',
            ar1: 'ܚܒܨܘܢܐ',
            dt3: '',
            bedeutung: ''
        },
        {
            dt2: '',
            ar2: 'ܫܽܘܡܬܰܐ',
            dt3: '',
            dt1: 'Schumta',
            ar1: 'ܫܘܡܬܐ',
            es1: 'ܫܘܡܬܐ',
            id: '2172',
            es2: 'ܫܘܡܬܐ',
            bedeutung: '',
            ar3: '',
            es3: ''
        },
        {
            bedeutung: 'männl., syrischen Ursprungs;  “Knecht Gottes, Knecht Jahwes”',
            ar2: 'ܥܰܒܕܰܐܠܠܰܗ',
            es1: 'ܥܒܕܐܠܠܗ',
            es2: 'ܥܒܕܐܠܠܗ',
            dt1: 'Abdallah',
            id: '7',
            ar3: '',
            dt3: '',
            dt2: '',
            es3: '',
            ar1: 'ܥܒܕܐܠܠܗ'
        },
        {
            es3: '',
            es1: 'ܡܪܝ ܡܠܟܐ',
            ar2: 'ܡܪܝ ܡܠܟܐ',
            dt3: '',
            ar1: 'ܡܪܝ ܡܠܟܐ',
            id: '713',
            dt2: '',
            ar3: '',
            dt1: 'Mor Malke',
            es2: 'ܡܪܝ ܡܠܟܐ',
            bedeutung: ''
        },
        {
            es3: '',
            ar3: '',
            dt1: 'Bath Zabbai',
            dt2: '',
            dt3: '',
            id: '203',
            bedeutung: 'weibl., Bath Zabbai, die auch Zenobia (Zeyneb/e) heißt (267-272 n.Chr.), war eine aramäische Schönheitskönigin von Tadmor (Palmyra) in Syrien. Weitere Formen: Zenob',
            es1: 'ܒܪܬܙܒܝ',
            ar1: 'ܒܪ̱ܬܙܒܝ',
            ar2: 'ܒܰܪ̱ܬܙܰܒܒܰܝ',
            es2: 'ܒܪܬܙܒܝ'
        },
        {
            id: '1885',
            es1: 'ܡܢܫܐ',
            dt3: '',
            dt1: 'Menasche',
            bedeutung: '',
            es2: 'ܡܢܫܐ',
            es3: '',
            dt2: '',
            ar2: 'ܡܶܢܰܫܶܐ',
            ar3: '',
            ar1: 'ܡܢܫܐ'
        },
        {
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe ist Hilfe, Jahwe ist großzügig”. Im AT ist Josua Schüler und Nachfolger des Mose (13. oder 11. Jh. v. Chr.). Weitere Formen: Jeschu (Bar-Nun), Jeschua, Jehoschuah',
            es3: 'ܝܗܘܫܥ',
            ar3: 'ܝܳܗܽܘܫܳܥ',
            dt1: 'Josua',
            id: '562',
            es1: 'ܗܘܫܥ',
            es2: 'ܗܘܫܥ',
            dt2: 'Joshua',
            ar2: 'ܝܶܫܽܘܥ',
            dt3: 'Yeshua',
            ar1: 'ܗܘܫܥ'
        },
        {
            dt2: '',
            dt3: '',
            es2: 'ܣܦܪܐ',
            bedeutung: 'weibl., “erfolgreiche, glückliche Frau” oder “eine Frau, die zerreißt, zersplittert”. Saliha oder Salah ist ein Dorf in Turabdin. Weitere Formen: Salah',
            es3: '',
            ar3: '',
            ar1: 'ܣܦܪܐ',
            id: '2078',
            es1: 'ܣܦܪܐ',
            ar2: 'ܣܰܦܪܶܐ',
            dt1: 'Safre'
        },
        {
            ar1: 'ܡܬܝܢ',
            es1: 'ܡܬܝܢ',
            es2: 'ܡܬܝܢ',
            bedeutung: '',
            dt3: '',
            ar2: 'ܡܰܬܺܝܢ',
            ar3: 'ܡܶܬܺܝܢ',
            es3: 'ܡܬܝܢ',
            dt1: 'Martin',
            dt2: 'Metin',
            id: '677'
        },
        {
            ar3: '',
            ar2: 'ܝܰܒܰܠ',
            dt1: 'Jabal',
            es2: 'ܝܒܠ',
            dt2: '',
            es3: '',
            es1: 'ܝܒܠ',
            id: '518',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “leiten, führen”',
            ar1: 'ܝܒܠ',
            dt3: ''
        },
        {
            id: '2286',
            es2: 'ܐܘܪܡܬܐ',
            ar1: 'ܐܘܪܡܬܐ',
            dt3: '',
            ar3: '',
            es1: 'ܐܘܪܡܬܐ',
            dt1: 'Urmata',
            dt2: '',
            bedeutung: 'männl., König von Edessa (162-164 n.Chr.), Sohn von Sahru; weitere Formen: Wayel',
            es3: '',
            ar2: 'ܐܽܘܪܡܰܬܰܐ'
        },
        {
            es3: 'ܫܡ',
            dt1: 'Schim',
            es2: 'ܫܝܡ',
            id: '961',
            ar2: 'ܫܺܝܡ',
            es1: 'ܫܝܡ',
            ar3: 'ܫܶܡ',
            dt3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Name, Ansehen”. Schim ist im AT Sohn Noachs, einer der Ahnherren der nachsintﬂutlichen Menschheit, Urvater der Semiten. Weitere Formen: Schem, Sem',
            dt2: 'Sem',
            ar1: 'ܫܝܡ'
        },
        {
            id: '1203',
            dt3: '',
            bedeutung: 'männl., siehe Abdo',
            dt2: 'Abud',
            es1: 'ܥܒܘܕ',
            ar3: '',
            dt1: 'Aboud',
            ar2: 'ܥܰܒܽܘܕ',
            es2: 'ܥܒܘܕ',
            es3: '',
            ar1: 'ܥܒܘܕ'
        },
        {
            es2: 'ܣܡܝܪܐ',
            es1: 'ܣܡܝܪܐ',
            ar2: 'ܣܰܡܺܝܪܰܐ',
            dt2: '',
            id: '2091',
            ar3: '',
            dt1: 'Samira',
            es3: '',
            ar1: 'ܣܡܝܪܐ',
            bedeutung: 'weibl., Nebenform zu Sara',
            dt3: ''
        },
        {
            es2: 'ܛܘܪܝ',
            dt2: '',
            dt3: '',
            es3: '',
            ar1: 'ܛܘܪܝ',
            ar3: '',
            id: '2273',
            bedeutung: '',
            es1: 'ܛܘܪܝ',
            ar2: 'ܛܽܘܪܺܝ',
            dt1: 'Turi'
        },
        {
            dt1: 'Schubho',
            dt2: '',
            ar3: '',
            dt3: '',
            es2: 'ܫܘܡܚܐ',
            bedeutung: 'männl., “Ornament, Dekoration, Schönheit”; weitere Formen: Sebtho',
            id: '2165',
            es1: 'ܫܘܡܚܐ',
            ar2: 'ܫܽܘܒܚܳܐ',
            es3: '',
            ar1: 'ܫܘܒܚܐ'
        },
        {
            dt1: 'Nathanael',
            es2: 'ܢܬܢܐܝܠ',
            ar1: 'ܢܬܢܐܝܠ',
            ar3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott hat gegeben” oder “von Gott gegeben”. Im NT wird Nathanael als einer der ersten Jünger Jesu Christi gerechnet, dessen Name allerdings in den ntl. Apostellisten nicht vorkommt, nur im Johannesevangelium. Manchmal wird er auch mit Bartolomäus identiﬁziert',
            id: '754',
            es3: '',
            ar2: 'ܢܰܬܰܢܰܐܝܶܠ',
            es1: 'ܢܬܢܐܝܠ',
            dt2: '',
            dt3: ''
        },
        {
            dt3: 'Yeshua',
            es2: 'ܗܘܫܥ',
            bedeutung: 'weibl., “reiche, fruchtbare Frau”',
            es3: 'ܝܗܘܫܥ',
            id: '1750',
            es1: 'ܗܘܫܥ',
            dt1: 'Josua',
            ar2: 'ܝܶܫܽܘܥ',
            ar3: 'ܝܳܗܽܘܫܳܥ',
            dt2: 'Joshua',
            ar1: 'ܗܘܫܥ'
        },
        {
            dt3: '',
            ar2: 'ܪܺܝܚܰܢ',
            es2: 'ܪܝܚܢ',
            es1: 'ܪܝܚܢ',
            ar3: 'ܪܺܝܚܐܰܢ',
            dt2: '',
            bedeutung: 'männl., siehe Rehob',
            id: '2050',
            dt1: 'Rihan',
            es3: 'ܪܝܚܐܢ',
            ar1: 'ܪܝܚܢ'
        },
        {
            es2: 'ܝܦܬ',
            bedeutung: 'männl., siehe Josua',
            ar2: 'ܝܰܦܶܬ',
            es3: 'ܝܦܬ',
            es1: 'ܝܦܬ',
            ar3: 'ܝܰܦܬ',
            ar1: 'ܝܦܬ',
            dt3: '',
            dt2: '',
            id: '1711',
            dt1: 'Jafet'
        },
        {
            dt3: '',
            id: '1605',
            ar3: 'ܗܰܕܽܘܪܰܡ',
            ar1: 'ܗܕܘܪܡ',
            dt2: '',
            es3: 'ܗܕܘܪܡ',
            ar2: 'ܗܰܕܳܘܪܰܡ',
            es1: 'ܗܕܘܪܡ',
            dt1: 'Hadoram',
            es2: 'ܗܕܘܪܡ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott hat ersetzt”. Im NT begegnet Halfai als Vater des Apostels Jakobus sowie als Vater des Apostels Levi oder Matthäus. Weitere Formen: Halphai, Halfay, Alphäus, Alfaus'
        },
        {
            es2: 'ܣܝܒܪ',
            ar2: 'ܣܰܝܒܰܪ',
            id: '2083',
            ar1: 'ܣܝܒܪ',
            ar3: '',
            bedeutung: 'weibl., “mein Name ist erhaben”; weitere Formen: Schamo',
            es1: 'ܣܝܒܪ',
            dt3: '',
            dt1: 'Saibar',
            dt2: '',
            es3: ''
        },
        {
            dt1: 'Rafqa',
            ar3: '',
            es3: '',
            ar1: 'ܪܦܩܐ',
            es1: 'ܪܦܩܐ',
            ar2: 'ܪܰܦܩܰܐ',
            dt2: '',
            dt3: '',
            id: '2027',
            es2: 'ܪܦܩܐ',
            bedeutung: 'weibl., “Betrunkenheit, Rausch”; weitere Formen: Rauyutha, Rawyuta'
        },
        {
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Hauch, Rauch, Vergänglichkeit, Nichtigkeit”, ”Fluß, Aue“. Im AT ist Hobel der zweite Sohn von Adam und Eva, in der Kirchengeschichte ein Bischof von Arbela (183 - 190). Weitere Formen: Habel, Abel',
            es2: 'ܗܒܝܠ',
            es1: 'ܗܒܝܠ',
            dt1: 'Hobel',
            ar3: '',
            es3: '',
            dt2: 'Abel',
            ar1: 'ܗܒܝܠ',
            id: '484',
            ar2: 'ܗܳܒܶܝܠ',
            dt3: ''
        },
        {
            es3: '',
            ar1: 'ܙܗܪܝܪܐ',
            ar2: 'ܙܰܗܪܺܝܪܳܐ',
            es1: 'ܙܗܪܝܪܐ',
            bedeutung: 'männl., “Blitzlicht, Strahl”',
            es2: 'ܙܗܪܝܪܐ',
            id: '1151',
            dt2: '',
            dt1: 'Zahriro',
            dt3: '',
            ar3: ''
        },
        {
            id: '1844',
            dt1: 'Malyuta',
            ar3: '',
            dt3: '',
            es1: 'ܡܠܝܘܬܐ',
            dt2: '',
            ar1: 'ܡܠܝܘܬܐ',
            bedeutung: 'männl., “Wiese, Weide”',
            es3: '',
            ar2: 'ܡܰܠܝܽܘܬܰܐ',
            es2: 'ܡܠܝܘܬܐ'
        },
        {
            es2: 'ܝܘܝܩܝܡ',
            ar2: 'ܝܽܘܝܰܩܺܝܡ',
            ar1: 'ܝܘܝܩܝܡ',
            ar3: '',
            dt3: '',
            dt1: 'Joachim',
            dt2: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “den Gott aufrichtet” oder “Jahwe stellt aufrecht”. Im AT ist Joachim König von Juda (609 - 598 v.Chr.). Im NT ist Jojachim der nicht genannte Vater Marias, der Mutter Jesu. Weitere Formen: Joakim, Jojakim, Jojaqim, Juachim',
            id: '552',
            es3: '',
            es1: 'ܝܘܝܩܝܡ'
        },
        {
            dt2: '',
            es2: 'ܝܕܥ',
            es3: '',
            ar2: 'ܝܰܕܰܥ',
            dt3: '',
            dt1: 'Jada',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäischen Ursprungs; “Steinhaufen-Zeuge”',
            es1: 'ܝܕܥ',
            id: '1708',
            ar3: '',
            ar1: 'ܝܕܥ'
        },
        {
            ar3: '',
            dt1: 'Nisibena',
            id: '1966',
            ar1: 'ܢܝܨܝܒܢܐ',
            bedeutung: 'männl., “Saugnapf, Lutscher, Trieb, Schößling”',
            dt3: '',
            es3: '',
            ar2: 'ܢܺܝܨܺܝܒܶܢܰܐ',
            es2: 'ܢܝܨܝܒܢܐ',
            es1: 'ܢܝܨܝܒܢܐ',
            dt2: ''
        },
        {
            dt1: 'Mahalalel',
            es1: 'ܡܗܠܠܐܝܠ',
            ar1: 'ܡܗܠܠܐܝܠ',
            dt2: '',
            bedeutung: 'weibl., syrischen Ursprungs; “Staat, Obrigkeit, Behörde, Regierung, Paradies, Herrschaft, Königreich”; weitere Formen: Malkutha',
            id: '1834',
            es2: 'ܡܗܠܠܐܝܠ',
            dt3: '',
            ar3: 'ܡܰܗܠܰܠܰܐܝܶܠ',
            ar2: 'ܡܰܗܠܳܠܳܐܝܶܠ',
            es3: 'ܡܗܠܠܐܝܠ'
        },
        {
            dt1: 'Rohmat',
            ar3: '',
            dt2: '',
            id: '2057',
            dt3: '',
            es2: 'ܪܚܡܬ',
            ar1: 'ܪܚܡܬ',
            es1: 'ܪܚܡܬ',
            es3: '',
            ar2: 'ܪܳܚܡܰܬ',
            bedeutung: 'männl., siehe Said'
        },
        {
            es2: 'ܐܪܓܘܢ',
            dt2: 'Ergun',
            es1: 'ܐܪܓܘܢ',
            ar1: 'ܐܪܓܘܢ',
            dt3: '',
            ar3: '',
            ar2: 'ܐܶܪܓܽܘܢ',
            dt1: 'Ergün',
            id: '318',
            es3: '',
            bedeutung: ''
        },
        {
            id: '98',
            dt2: '',
            ar2: 'ܐܰܪܺܝܒ',
            es3: '',
            es2: 'ܐܪܝܒ',
            dt1: 'Arib',
            ar1: 'ܐܪܝܒ',
            es1: 'ܐܪܝܒ',
            bedeutung: 'männl., “vergrößert, erhöht”',
            ar3: '',
            dt3: ''
        },
        {
            es1: 'ܐܘܪܒ',
            dt2: '',
            es2: 'ܐܘܪܒ',
            dt3: '',
            dt1: 'Awreb',
            ar3: '',
            id: '132',
            bedeutung: 'männl., “er hat vermehrt, erhöht”; weitere Forınen: Aureb',
            ar2: 'ܐܰܘܪܶܒ',
            ar1: 'ܐܘܪܒ',
            es3: ''
        },
        {
            id: '666',
            es3: '',
            ar1: 'ܡܪܓܢܝܬܐ',
            bedeutung: 'weibl., wohl griechischen Ursprungs; “Edelstein, Perle, Juwel”; weitere Formen: Margonitho, Marganita',
            es1: 'ܡܪܓܢܝܬܐ',
            es2: 'ܡܪܓܢܝܬܐ',
            dt2: '',
            dt3: '',
            ar2: 'ܡܰܪܓܳܢܺܝܬܰܐ',
            ar3: '',
            dt1: 'Margonita'
        },
        {
            es1: 'ܢܗܪܐ',
            bedeutung: 'weibl., “Heu, Blume, Blüte, Ranunkel, Arsen, Arsenik”',
            dt2: '',
            dt1: 'Nuhro',
            es3: '',
            ar2: 'ܢܽܘܗܪܳܐ',
            es2: 'ܢܗܪܐ',
            ar1: 'ܢܘܗܪܐ',
            id: '1971',
            dt3: '',
            ar3: ''
        },
        {
            es3: '',
            ar2: 'ܟܰܪܡܳܐ',
            es2: 'ܟܪܡܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Purpur(land)”. Im AT ist Kenan Sohn des Cham/Ham. Die Kenanäer sind ein Stammvolk in Kanaan (alter Name für Israel).',
            ar3: '',
            dt3: '',
            dt1: 'Karmo',
            id: '1770',
            es1: 'ܟܪܡܐ',
            dt2: '',
            ar1: 'ܟܪܡܐ'
        },
        {
            id: '979',
            es1: 'ܫܘܡܪܝܢ',
            dt2: '',
            ar3: '',
            ar1: 'ܫܘܡܪܝܢ',
            ar2: 'ܫܽܘܡܪܺܝܢ',
            es2: 'ܫܘܡܪܝܢ',
            dt3: '',
            es3: '',
            dt1: 'Schumrin',
            bedeutung: 'weibl., “Duft”'
        },
        {
            dt1: 'Arjo',
            ar3: '',
            bedeutung: 'männl., “Löwe”. Arjo war ein aramäischer König von Edessa (132-127 v.Chr.) und Begründer der edessenischen Dynastie',
            dt3: 'Ario',
            es3: '',
            dt2: 'Aryo',
            es1: 'ܐܪܝܐ',
            ar1: 'ܐܪܝܐ',
            es2: 'ܐܪܝܐ',
            id: '101',
            ar2: 'ܐܰܪܝܳܐ'
        },
        {
            id: '759',
            ar2: 'ܢܶܡܪܰܬ',
            ar1: 'ܢܡܪܬ',
            es1: 'ܢܡܪܬ',
            dt3: '',
            es2: 'ܢܡܪܬ',
            dt1: 'Nemrat',
            bedeutung: 'männl., “du bist mein Tiger”',
            es3: '',
            ar3: '',
            dt2: ''
        },
        {
            ar2: 'ܡܰܢܺܝܫܟܰܐ',
            es1: 'ܡܢܝܫܟܐ',
            es2: 'ܡܢܝܫܟܐ',
            ar1: 'ܡܢܝܫܟܐ',
            es3: 'ܡܢܝܫܟܐ',
            dt1: 'Manischka',
            dt3: 'Mnischko',
            id: '1845',
            ar3: 'ܡܰܢܺܝܫܟܳܐ',
            dt2: 'Manischko',
            bedeutung: 'weibl., wohl griechischen Ursprungs; “Edelstein, Perle, Juwel”; weitere Formen: Margonitho, Marganita'
        },
        {
            es3: '',
            bedeutung: 'weibl., siehe Eva',
            ar2: 'ܚܰܘܰܐ',
            ar3: '',
            dt3: '',
            id: '455',
            ar1: 'ܚܘܐ',
            es2: 'ܚܘܐ',
            dt2: '',
            es1: 'ܚܘܐ',
            dt1: 'Hawa'
        },
        {
            es1: 'ܐܝܠܐ',
            id: '135',
            bedeutung: 'weibl., “Gazelle, Hirsch, Reh”',
            ar2: 'ܐܰܝܠܰܐ',
            dt1: 'Ayla',
            dt2: '',
            es2: 'ܐܝܠܐ',
            dt3: '',
            es3: '',
            ar1: 'ܐܝܠܐ',
            ar3: ''
        },
        {
            dt3: 'Emilja',
            id: '1497',
            ar3: '',
            es1: 'ܐܡܝܠܝܐ',
            dt1: 'Emilia',
            dt2: 'Emilya',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “behaart, struppig”. Im AT ist Esau älterer Sohn Isaaks und der Rebekka und Zwillingsbruder Jakobs. Weitere Formen: Isu, Esav',
            es3: '',
            es2: 'ܐܡܝܠܝܐ',
            ar1: 'ܐܡܝܠܝܐ',
            ar2: 'ܐܶܡܺܝܠܝܰܐ'
        },
        {
            ar1: 'ܠܡܦܐܕܐ',
            dt3: '',
            bedeutung: 'männl., syrischen Ursprungs; “Lampe, Laterne, Leuchter”',
            dt1: 'Lamfido',
            es1: 'ܠܡܦܐܕܐ',
            dt2: '',
            es2: 'ܠܡܦܐܕܐ',
            es3: '',
            ar3: '',
            id: '620',
            ar2: 'ܠܰܡܦܺܐܕܳܐ'
        },
        {
            ar2: 'ܢܰܪܘܶܙ',
            dt3: '',
            es2: 'ܢܪܘܙ',
            dt2: '',
            ar3: '',
            dt1: 'Narwez',
            bedeutung: 'männl., aramäisch-chaldäischer König von Babylonien (555 - 539 v. Chr.); weitere Formen: Nabunaid',
            es1: 'ܢܪܘܙ',
            ar1: 'ܢܪܘܙ',
            id: '1940',
            es3: ''
        },
        {
            es1: 'ܫܦܝܐ',
            ar2: 'ܫܰܦܝܳܐ',
            id: '924',
            ar1: 'ܫܦܝܐ',
            bedeutung: 'männl., “rein, fromm”',
            es2: 'ܫܦܝܐ',
            dt2: '',
            ar3: '',
            dt1: 'Schafyo',
            dt3: '',
            es3: ''
        },
        {
            es1: 'ܚܙܩܝܐ',
            ar1: 'ܚܙܩܝܐ',
            dt1: 'Hesaqjo',
            dt2: '',
            ar2: 'ܚܶܙܰܩܝܳܐ',
            ar3: '',
            id: '1658',
            es3: '',
            dt3: '',
            es2: 'ܚܙܩܝܐ',
            bedeutung: 'weibl., syrischen Ursprungs; “die Freie, ein(e) freie(s) [Frau/Mädchen]”'
        },
        {
            es1: 'ܐܪܓܘܢ',
            es3: '',
            id: '1503',
            ar2: 'ܐܶܪܓܽܘܢ',
            es2: 'ܐܪܓܘܢ',
            dt3: '',
            dt1: 'Ergün',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “die Lebensspendende, Leben weckend oder Schlange“. Im AT gilt Eva als die Stammmutter der Men schen. Weitere Namen: Hawo, Hawa',
            ar1: 'ܐܪܓܘܢ',
            dt2: 'Ergun',
            ar3: ''
        },
        {
            bedeutung: 'weibl., siehe Heleni',
            es1: 'ܗܠܢ',
            es2: 'ܗܠܢ',
            dt3: '',
            ar1: 'ܗܠܢ',
            dt2: '',
            id: '463',
            ar2: 'ܗܶܠܶܢ',
            dt1: 'Helen',
            ar3: '',
            es3: ''
        },
        {
            es3: '',
            id: '1086',
            ar1: 'ܐܘܡܬ݂ܝܢ',
            es1: 'ܐܘܡܬ݂ܝܢ',
            dt3: '',
            dt2: '',
            dt1: 'Umthin',
            ar2: 'ܐܽܘܡܬ݂ܺܝܢ',
            es2: 'ܐܘܡܬ݂ܝܢ',
            bedeutung: 'weibl., “unsere Nation, unsere Heimat”',
            ar3: ''
        },
        {
            ar2: 'ܢܰܦܬܰܠܺܝ',
            ar1: 'ܢܦܬܠܝ',
            dt1: 'Naftali',
            es1: 'ܢܦܬܠܝ',
            es2: 'ܢܦܬܠܝ',
            dt2: '',
            dt3: '',
            id: '1924',
            ar3: '',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “die Liebliche”. Im AT ist Naima die Schwiegermutter der Ruth. Weitere Formen: Naemi, Naema, Noomi, Naomi',
            es3: ''
        },
        {
            dt2: '',
            es3: '',
            es2: 'ܚܘܪܐ',
            ar1: 'ܚܘܪܐ',
            id: '473',
            dt1: 'Heworo',
            dt3: '',
            ar2: 'ܚܶܘܳܪܳܐ',
            ar3: '',
            bedeutung: 'männl., “weiß, weiße Farbe”',
            es1: 'ܚܘܪܐ'
        },
        {
            dt3: '',
            ar1: 'ܝܘܢܘܢ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe hat gegeben” oder “Gottesgabe”. Im AT existieren zwei Personen unter diesem Namen: Jonathan, Sohn von Saul, und Jonathan, Sohn des Matthias. Im NT (im Johannesevangelium) trägt ein Jünger Jesu diesen Namen in der Form Nathanael. Weitere Formen: Jonatan, Junothon, Yunothon, Nathan, Natan, Nathanael, Natanael',
            ar2: 'ܝܳܘܢܰܬܰܢ',
            es2: 'ܝܘܢܘܢ',
            es1: 'ܝܘܢܘܢ',
            es3: 'ܝܘܢܬܢ',
            dt2: 'Yonathan',
            dt1: 'Jonathan',
            id: '558',
            ar3: 'ܝܽܘܢܳܬܳܢ'
        },
        {
            dt2: '',
            es1: 'ܕܘܪܐ',
            es3: 'ܕܪܐ',
            dt3: '',
            id: '280',
            es2: 'ܕܘܪܐ',
            dt1: 'Dora',
            ar2: 'ܕܰܘܪܰܐ',
            bedeutung: 'weibl., Kurzform zu Theodora',
            ar1: 'ܕܘܪܐ',
            ar3: 'ܕܳܪܰܐ'
        },
        {
            bedeutung: 'männl., babylonischer Weisheitsgott; weitere Formen: Nebu, Nebo, Nabu',
            es3: 'ܢܒܘ',
            es2: 'ܢܒܐ',
            id: '728',
            es1: 'ܢܒܐ',
            ar1: 'ܢܒܐ',
            ar3: 'ܢܰܒܽܘ',
            dt1: 'Nabo',
            dt2: '',
            ar2: 'ܢܰܒܳܐ',
            dt3: ''
        },
        {
            es3: '',
            id: '1296',
            dt1: 'Atash',
            bedeutung: 'männl., aus dem Griechischen übernommener Vorname; “der Unsterbliche”. Viele Heilige und Kirchenväter hießen Athanasius, einer der Beinamen ﬁir syrisch-orthodoxe Bischöfe',
            ar2: 'ܐܰܛܰܫ',
            dt3: '',
            es2: 'ܐܛܫ',
            ar3: '',
            es1: 'ܐܛܫ',
            dt2: '',
            ar1: 'ܐܛܫ'
        },
        {
            es1: 'ܝܐܝܠ',
            ar3: '',
            dt3: '',
            ar2: 'ܝܰܐܝܶܠ',
            es3: '',
            id: '1710',
            dt1: 'Jael',
            es2: 'ܝܐܝܠ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe soll leben!”; weitere Formen: Jahija, Jahja, Ahija',
            ar1: 'ܝܐܝܠ',
            dt2: ''
        },
        {
            es2: 'ܥܝܣܘ',
            es1: 'ܥܝܣܘ',
            bedeutung: '',
            dt1: 'Esau',
            dt3: 'Isu',
            dt2: 'Esav',
            id: '1504',
            ar1: 'ܥܝܣܘ',
            es3: '',
            ar3: '',
            ar2: 'ܥܺܝܣܽܘ'
        },
        {
            dt2: '',
            dt3: '',
            es2: 'ܣܠܡܐ',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Saphir, Edelstein”; weitere Formen: Saﬁra, Schaphira, Schaﬁra',
            ar2: 'ܫܠܶܝܡܽܘܢ',
            es3: 'ܣܠܡܐ',
            ar3: 'ܣܰܠܳܡܳܐ',
            es1: 'ܣܠܡܐ',
            id: '2088',
            ar1: 'ܫܠܝܡܘܢ',
            dt1: 'Salomo'
        },
        {
            dt3: '',
            dt2: '',
            bedeutung: 'männl., “0live, Olivenbaum, Ölbaum”',
            es2: 'ܙܗܝܘܬܐ',
            ar3: '',
            es1: 'ܙܗܝܘܬܐ',
            dt1: 'Zahyuta',
            id: '2345',
            ar1: 'ܙܗܝܘܬܐ',
            ar2: 'ܙܰܗܝܽܘܬܰܐ',
            es3: ''
        },
        {
            ar3: '',
            dt3: '',
            es1: 'ܪܦܐܝܠ',
            es3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott heilt, Gott hat geheilt”. Im AT ist Rafael einer der sieben Erzengel. Weitere Formen: Raphael, Raffael, Rafael',
            id: '834',
            dt2: 'Raphael',
            ar2: 'ܪܰܦܰܐܝܶܠ',
            dt1: 'Rafael',
            ar1: 'ܪܦܐܝܠ',
            es2: 'ܪܦܐܝܠ'
        },
        {
            ar3: '',
            es3: '',
            es2: 'ܐܕܝܢܐ',
            dt3: '',
            ar2: 'ܐܰܕܺܝܢܰܐ',
            id: '37',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “weich, zart, schlank”',
            es1: 'ܐܕܝܢܐ',
            dt2: '',
            ar1: 'ܐܕܝܢܐ',
            dt1: 'Adina'
        },
        {
            es2: 'ܙܐܙܒܘܚܐ',
            ar3: '',
            es3: '',
            es1: 'ܙܐܙܒܘܚܐ',
            dt1: 'Zazabuha',
            dt3: '',
            bedeutung: 'weibl., Zazabuha (oder Zaz) ist ein Dorf in Turabdin; weitere Formen: Zaz',
            id: '1165',
            ar2: 'ܙܰܐܙܰܒܽܘܚܰܐ',
            ar1: 'ܙܐܙܒܘܚܐ',
            dt2: ''
        },
        {
            es2: 'ܛܦܪܐ',
            id: '2235',
            bedeutung: 'männl., aus dem griechischen “theós” Gott und “phoros”: tragend = “Gottesträger”; weitere Formen: Theophor',
            dt1: 'Tefra',
            es3: '',
            ar2: 'ܛܶܦܪܰܐ',
            ar1: 'ܛܦܪܐ',
            dt3: '',
            ar3: '',
            dt2: '',
            es1: 'ܛܦܪܐ'
        },
        {
            dt3: '',
            bedeutung: 'weibl., “Zypresse”; weitere Formen: Brutho',
            ar3: 'ܒܪܽܘܬܺܝ',
            dt1: 'Berota',
            ar2: 'ܒܶܪܳܘܬܰܐ',
            es3: 'ܒܪܘܬܝ',
            ar1: 'ܒܪܘܬܐ',
            es1: 'ܒܪܘܬܐ',
            dt2: '',
            id: '1402',
            es2: 'ܒܪܘܬܐ'
        },
        {
            bedeutung: 'männl., “verherrlichen, lobpreisen”',
            es2: 'ܣܪܟܝܣ',
            es3: 'ܣܪܟܝܣ',
            dt2: 'Sarkis',
            dt3: 'Sergius',
            ar2: 'ܣܰܪܓܺܝܣ',
            ar3: 'ܣܰܪܟܺܝܣ',
            id: '2101',
            dt1: 'Sargis',
            ar1: 'ܣܪܟܝܣ',
            es1: 'ܣܪܟܝܣ'
        },
        {
            bedeutung: 'männl., “Sohn des Jahres”',
            es1: 'ܒܪܫܢܬܐ',
            dt2: '',
            ar2: 'ܒܰܪܫܰܢ̱ܬܳܐ',
            dt1: 'Barschato',
            dt3: '',
            ar3: '',
            id: '186',
            es3: '',
            ar1: 'ܒܪܫܢ̱ܬܐ',
            es2: 'ܒܪܫܢܬܐ'
        },
        {
            es2: 'ܚܢܘܢ',
            bedeutung: 'männl. aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs. Haso ist nach dem AT Bruder Keseds, Kemuels und Sohn Nahors. Weitere Formen: Hazu',
            ar2: 'ܚܰܢܽܘܢ',
            dt2: 'Hanun',
            es3: 'ܚܢܘܢܐ',
            ar3: 'ܚܰܢܳܘܢܳܐ',
            dt1: 'Hanoun',
            dt3: 'Hanen',
            ar1: 'ܚܢܘܢ',
            es1: 'ܚܢܘܢ',
            id: '1631'
        },
        {
            es3: '',
            dt2: '',
            ar2: 'ܛܽܘܒܰܢܰܐ',
            id: '1076',
            es2: 'ܛܘܒܢܐ',
            ar1: 'ܛܘܒܢܐ',
            dt1: 'Tubana',
            bedeutung: 'männl., siehe Tuban',
            ar3: '',
            dt3: '',
            es1: 'ܛܘܒܢܐ'
        },
        {
            ar2: 'ܐܰܬ݂ܪܰܐ',
            es1: 'ܐܬܪܐ',
            ar3: '',
            dt1: 'Athra',
            ar1: 'ܐܬܪܐ',
            es2: 'ܐܬܪܐ',
            dt3: '',
            es3: '',
            id: '120',
            dt2: '',
            bedeutung: ''
        },
        {
            dt1: 'Sara',
            es1: 'ܣܐܪܐ',
            ar2: 'ܣܰܪܰܐ',
            dt2: 'Sarah',
            bedeutung: 'männl., “Fasten”; weitere Formen: Saume',
            ar3: 'ܣܰܐܪܰܐ',
            ar1: 'ܣܐܪܐ',
            id: '2098',
            es2: 'ܣܐܪܐ',
            dt3: '',
            es3: 'ܣܐܪܐ'
        },
        {
            ar3: '',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Leyla: die sich vergeblich bemüht” oder “Kuh”. Im AT ist Lea die erste Frau Jakobs, Tochter des Aramäers Laban/Lobon und ältere Schwester Rahels, bei den Aramäern ein weitverbreiteter Vorname',
            dt2: '',
            ar2: 'ܠܰܚܡܺܝ',
            es2: 'ܠܚܡܝ',
            es3: '',
            id: '1805',
            es1: 'ܠܚܡܝ',
            dt1: 'Lahmi',
            dt3: '',
            ar1: 'ܠܚܡܝ'
        },
        {
            dt1: 'Kinoro',
            es2: 'ܟܢܪܐ',
            ar1: 'ܟܢܪܐ',
            dt3: '',
            ar3: 'ܟܺܝܢܳܪܳܐ',
            bedeutung: 'männl., “Harfe, Leier, Zither”',
            ar2: 'ܟܶܢܳܪܳܐ',
            es3: 'ܟܝܢܪܐ',
            es1: 'ܟܢܪܐ',
            dt2: '',
            id: '598'
        },
        {
            es1: 'ܐܟܬܢ',
            id: '1246',
            ar3: '',
            dt2: '',
            ar2: 'ܐܰܟܬܰܢ',
            es2: 'ܐܟܬܢ',
            dt3: '',
            ar1: 'ܐܟܬܢ',
            bedeutung: '',
            es3: '',
            dt1: 'Aktan'
        },
        {
            ar2: 'ܗܺܝܒܰܐ',
            dt2: '',
            id: '1663',
            dt3: '',
            es1: 'ܗܝܒܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Hauch, Rauch, Vergänglichkeit, Nichtigkeit”, ”Fluß, Aue“. Im AT ist Hobel der zweite Sohn von Adam und Eva, in der Kirchengeschichte ein Bischof von Arbela (183 - 190). Weitere Formen: Habel, Abel',
            es2: 'ܗܝܒܐ',
            es3: '',
            dt1: 'Hiba',
            ar1: 'ܗܝܒܐ',
            ar3: ''
        },
        {
            ar2: 'ܐܰܚܺܝܩܰܪ',
            dt3: 'Ahiqar',
            es1: 'ܐܚܝܩܪ',
            es2: 'ܐܚܝܩܪ',
            ar1: 'ܐܚܝܩܪ',
            dt2: 'Achikar',
            ar3: '',
            es3: '',
            id: '1233',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “mein Bruder ist kostbar”. Im AT ist Ahikar ein Verwandter von Tobias/Tobit, Minister der assyrischen Könige Sanherib(754-681 v.Chr.) und Asarhaddon (680-669 v.Chr.). Der Ahikar-Roman ist u.a. in syrisch-aramäischer Sprache überliefert. Weitere Formen: Ahiqar, Achikar',
            dt1: 'Ahikar'
        },
        {
            bedeutung: 'männl., “Name Gottes”',
            es2: 'ܫܡܐܝܠ',
            ar3: '',
            dt2: '',
            es3: '',
            dt3: '',
            dt1: 'Schemil',
            ar1: 'ܫܡܐܝܠ',
            ar2: 'ܫܶܡܺܐܝܠ',
            es1: 'ܫܡܐܝܠ',
            id: '954'
        },
        {
            es2: 'ܦܝܠܕܫ',
            bedeutung: 'männl., Pildasch ist nach dem AT Bruder Keseds, Kemuels und Betuels, Sohn Nahors. Weitere Formen: Palrasch, Pilrasch',
            id: '809',
            dt3: '',
            ar2: 'ܦܺܝܠܰܕܫ',
            dt1: 'Pildasch',
            es1: 'ܦܝܠܕܫ',
            ar1: 'ܦܝܠܕܫ',
            ar3: '',
            dt2: '',
            es3: ''
        },
        {
            es1: 'ܝܠܝܦ',
            ar2: 'ܝܰܠܺܝܦ',
            es2: 'ܝܠܝܦ',
            dt1: 'Yalif',
            ar3: '',
            id: '1122',
            dt3: '',
            bedeutung: 'männl., “Ausgebildeter, Intellektueller, Kenner, Experte”; weitere Formen: Jalif',
            dt2: '',
            ar1: 'ܝܠܝܦ',
            es3: ''
        },
        {
            es3: '',
            es2: 'ܐܦܬܘܢܝܐ',
            ar1: 'ܐܦܬܘܢܝܐ',
            ar3: '',
            es1: 'ܐܦܬܘܢܝܐ',
            dt3: '',
            dt2: '',
            bedeutung: 'weibl., Aftunya war die Mutter des gelehrten syrisch-orthodoxen Mönchs Yuhannun/Johannes mit dem Beinamen “Bar Aftunya” (†538) aus Edessa.',
            dt1: 'Aftunya',
            ar2: 'ܐܰܦܬܽܘܢܝܰܐ',
            id: '1223'
        },
        {
            bedeutung: 'weibl., “mein Name ist erhaben”; weitere Formen: Schamo',
            dt2: 'Schamiram',
            dt1: 'Samiram',
            es2: 'ܫܡܝܪܡ',
            ar3: 'ܫܰܡܺܝܪܰܡ',
            es1: 'ܫܡܝܪܡ',
            dt3: '',
            es3: 'ܫܡܝܪܡ',
            ar2: 'ܫܰܡܺܝܪܰܐܡ',
            id: '900',
            ar1: 'ܫܡܝܪܐܡ'
        },
        {
            dt1: 'Schubholjeschu',
            ar3: '',
            es3: '',
            ar1: 'ܫܘܒܚܐܠܝܫܘܥ',
            dt3: '',
            ar2: 'ܫܽܘܒܚܳܐܠܝܶܫܽܘܥ',
            dt2: '',
            es2: 'ܫܘܒܚܐܠܝܫܘܥ',
            bedeutung: 'männl., “Lob sei Jesus”. Schubho l`jeschu (37 5-406) war Metropolit von Arbela. Weitere Formen: Schubhalischo',
            id: '974',
            es1: 'ܫܘܒܚܐܠܝܫܘܥ'
        },
        {
            ar3: 'ܚܰܢܰܢ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gnade Gottes”',
            ar2: 'ܚܰܐܢܳܢ',
            dt3: '',
            dt1: 'Hanan',
            dt2: '',
            ar1: 'ܚܐܢܢ',
            id: '1619',
            es1: 'ܚܐܢܢ',
            es3: 'ܚܢܢ',
            es2: 'ܚܐܢܢ'
        },
        {
            dt3: '',
            es3: '',
            dt2: '',
            id: '1731',
            ar2: 'ܐܺܝܙܰܪܥܶܠ',
            dt1: 'Jesreel',
            es1: 'ܐܝܙܪܥܠ',
            ar1: 'ܐܝܙܪܥܠ',
            es2: 'ܐܝܙܪܥܠ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “den Gott aufrichtet” oder “Jahwe stellt aufrecht”. Im AT ist Joachim König von Juda (609 - 598 v.Chr.). Im NT ist Jojachim der nicht genannte Vater Marias, der Mutter Jesu. Weitere Formen: Joakim, Jojakim, Jojaqim, Juachim',
            ar3: ''
        },
        {
            ar2: 'ܣܰܡܺܝܪ',
            ar1: 'ܣܡܝܪ',
            dt3: '',
            es1: 'ܣܡܝܪ',
            es3: '',
            ar3: '',
            dt1: 'Samir',
            dt2: '',
            bedeutung: 'männl., “Seraph, Seraphim, Engelschar”',
            es2: 'ܣܡܝܪ',
            id: '2090'
        },
        {
            dt2: 'Barjona',
            ar2: 'ܒܰܪܝܰܘܢܳܐ',
            dt3: '',
            dt1: 'Barjawno',
            bedeutung: 'männl., “Sohn des Felses, Sohn des Steines”. Barkipho/Barkepha ist der Beiname bzw. Familienname des Bischofs und Liturgikers Musche (Moses) Barkipho (†903)',
            ar1: 'ܒܪܝܘܢܐ',
            id: '1349',
            es1: 'ܒܪܝܘܢܐ',
            es3: '',
            es2: 'ܒܪܝܘܢܐ',
            ar3: ''
        },
        {
            ar2: 'ܘܰܪܕܶܐ',
            dt2: '',
            dt3: '',
            es2: 'ܘܪܕܐ',
            ar3: '',
            dt1: 'Warde',
            bedeutung: 'männl., “Gott hat gegeben, weitere Formen: Yabalaha, Jahbalaha, Jabalaha',
            es1: 'ܘܪܕܐ',
            id: '2298',
            ar1: 'ܘܪܕܐ',
            es3: ''
        },
        {
            ar3: '',
            ar1: 'ܟܫܝܪܬܐ',
            dt2: '',
            ar2: 'ܟܰܫܺܝܪܬܰܐ',
            dt1: 'Kaschirta',
            es1: 'ܟܫܝܪܬܐ',
            id: '585',
            bedeutung: 'weibl., syrischen Ursprungs; “die Fleißige, ein(e) ﬂeißige(s) Frau [Mädchen]”',
            es2: 'ܟܫܝܪܬܐ',
            es3: '',
            dt3: ''
        },
        {
            dt3: '',
            es3: '',
            bedeutung: 'weibl., syrischen Ursprungs; “tapfere(s), starke(s), mächtige(s) [Frau/Mädchen]”',
            dt2: '',
            dt1: 'Hanon',
            es2: 'ܚܢܢ',
            ar2: 'ܚܰܢܳܢ',
            id: '1629',
            ar1: 'ܚܢܢ',
            es1: 'ܚܢܢ',
            ar3: ''
        },
        {
            id: '2031',
            dt3: '',
            dt1: 'Ralf',
            bedeutung: 'weibl., “Wunsch, Sehnsucht, Begehren, Verlangen, Appetit, Bedürfnis, Lust”; weitere Formen: Regtha',
            es3: 'ܪܐܠܦ',
            ar2: 'ܪܰܠܦ',
            dt2: 'Ralph',
            es2: 'ܪܠܦ',
            ar1: 'ܪܠܦ',
            es1: 'ܪܠܦ',
            ar3: 'ܪܰܐܠܦ'
        },
        {
            dt2: '',
            dt3: '',
            es3: '',
            es1: 'ܚܡܫܝܢ',
            ar2: 'ܚܰܡܫܺܝܢ',
            ar1: 'ܚܡܫܝܢ',
            dt1: 'Hamschin',
            bedeutung: 'männl., “fünfzig”',
            es2: 'ܚܡܫܝܢ',
            ar3: '',
            id: '428'
        },
        {
            es1: 'ܒܪܗܕܕ',
            bedeutung: '',
            ar2: 'ܒܰܪܗܰܕܰܕ',
            ar1: 'ܒܪܗܕܕ',
            es3: 'ܒܪܚܕܕ',
            dt2: '',
            id: '1333',
            es2: 'ܒܪܗܕܕ',
            dt1: 'Bar Hadad',
            ar3: 'ܒܰܪܚܰܕܰܕ',
            dt3: ''
        },
        {
            dt3: '',
            ar1: 'ܐܘܡܬ݂ܝܢ',
            dt1: 'Umthin',
            ar2: 'ܐܽܘܡܬ݂ܺܝܢ',
            id: '2278',
            es2: 'ܐܘܡܬ݂ܝܢ',
            es1: 'ܐܘܡܬ݂ܝܢ',
            es3: '',
            dt2: '',
            bedeutung: 'weibl., “Stadt des Wassers”. Urmiya oder Urmi ist eine Stadt in Aram Beth-Nahrin (= Mesopotamien, heute im Iran). Weitere Formen: Urmia, Urmi',
            ar3: ''
        },
        {
            ar3: '',
            dt1: 'Nuhrat',
            id: '1970',
            bedeutung: 'männl., “Licht des Friedens”',
            dt3: '',
            ar1: 'ܢܘܗܪܬ',
            dt2: '',
            es3: '',
            ar2: 'ܢܽܘܗܪܰܬ',
            es2: 'ܢܘܗܪܬ',
            es1: 'ܢܘܗܪܬ'
        },
        {
            ar3: '',
            ar1: 'ܣܘܪܝܐ',
            es3: '',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Tabja: Gazelle, Hirsch, Reh, Antilope”. Tabita war nach der Apostelgeschichte eine Jüngerin, die von Petrus vom Tode auferweckt wurde. Weitere Formen: Tabea, Tabitha, Tabyo, Tabia, Tabiya',
            dt3: '',
            ar2: 'ܣܽܘܪܺܝܰܐ',
            dt2: '',
            dt1: 'Suriya',
            id: '2208',
            es1: 'ܣܘܪܝܐ',
            es2: 'ܣܘܪܝܐ'
        },
        {
            dt1: 'Abedmschiho',
            dt2: '',
            dt3: '',
            es3: '',
            es1: 'ܥܒܕܡܫܝܚܐ',
            bedeutung: 'männl, “Knecht/Diener des Messias Christus”. Abedmschiho war Bischof von Arbela (190-217). Weitere Formen: Ebedmschiho, Ebedmschiha, Abduhnesih',
            id: '13',
            ar2: 'ܥܒܶܕܡܫܺܝܚܐ',
            ar3: '',
            ar1: 'ܥܒܕܡܫܝܚܐ',
            es2: 'ܥܒܕܡܫܝܚܐ'
        },
        {
            es3: 'ܓܘܪܝܐ',
            es1: 'ܓܘܪܝܗ',
            dt1: 'Gawriye',
            dt2: 'Gewriye',
            ar3: 'ܓܰܘܪܺܝܶܐ',
            bedeutung: 'männl., “Offenbarung”',
            ar2: 'ܓܰܘܪܺܝܶܗ',
            ar1: 'ܓܘܪܝܗ',
            id: '1553',
            es2: 'ܓܘܪܝܗ',
            dt3: 'Gevriye'
        },
        {
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “mein Herr”. Adonai wurde von den heidnischen Aramäern in Syrien als Gott verehrt; er hieß auch Tammus (Juli) und galt als “Liebling der Frauen“',
            ar2: 'ܐܰܕܳܘܢܰܝ',
            ar1: 'ܐܕܘܢܝ',
            dt1: 'Adonai',
            es1: 'ܐܕܘܢܝ',
            es3: '',
            ar3: '',
            es2: 'ܐܕܘܢܝ',
            id: '1215',
            dt3: '',
            dt2: ''
        },
        {
            dt1: 'Adonikam',
            es1: 'ܐܕܘܢܝܩܡ',
            id: '40',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “mein Herr steht aufrecht”; weitere Formen: Adoniqam',
            dt3: '',
            ar2: 'ܐܰܕܳܘܢܺܝܩܰܡ',
            ar3: '',
            ar1: 'ܐܕܘܢܝܩܡ',
            es2: 'ܐܕܘܢܝܩܡ',
            dt2: '',
            es3: ''
        },
        {
            ar2: 'ܒܰܪܽܘܟ݂',
            es1: 'ܒܪܘܟ݂',
            dt3: '',
            dt2: '',
            es3: '',
            dt1: 'Baruch',
            ar1: 'ܒܪܘܟ݂',
            es2: 'ܒܪܘܟ݂',
            ar3: '',
            id: '1373',
            bedeutung: 'männl., aus dem Griechischen übenommener Vorname; “der Königliche, der Kaiserliche”. In der Kirchengeschichte war Basilius Kirchenlehrer und Bischof von Cäsarea (†379); Festtag: 1. Januar'
        },
        {
            ar1: 'ܐܒܠܐ',
            es2: 'ܐܒܠܐ',
            ar2: 'ܐܶܒܠܳܐ',
            id: '289',
            ar3: '',
            es3: '',
            dt2: '',
            dt1: 'Eblo',
            es1: 'ܐܒܠܐ',
            bedeutung: 'männl., “Kummer, Trauer, Trübsal, Traurigkeit”',
            dt3: ''
        },
        {
            es2: 'ܓܦܬܐ',
            es3: 'ܓܦܐܬܐ',
            dt2: '',
            dt3: '',
            ar3: 'ܓܦܶܐܬܰܐ',
            id: '381',
            ar2: 'ܓܦܶܬܰܐ',
            es1: 'ܓܦܬܐ',
            dt1: 'Gfeta',
            bedeutung: 'weibl., “Weinstock”',
            ar1: 'ܓܦܬܐ'
        },
        {
            ar1: 'ܐܡܕܝܐ',
            ar3: '',
            es3: '',
            dt1: 'Amedya',
            ar2: 'ܐܰܡܶܕܝܰܐ',
            dt2: '',
            dt3: '',
            bedeutung: 'weibl., arabischen Ursprungs; “Hoffnung”',
            es2: 'ܐܡܕܝܐ',
            es1: 'ܐܡܕܝܐ',
            id: '1253'
        },
        {
            es2: 'ܗܒܒܬܐ',
            es3: '',
            bedeutung: 'weibl. Form zu Habobo; weitere Formen: Habobto',
            dt2: '',
            ar1: 'ܗܒܒܬܐ',
            es1: 'ܗܒܒܬܐ',
            ar2: 'ܗܰܒܳܒܬܰܐ',
            dt1: 'Habobta',
            dt3: '',
            ar3: '',
            id: '406'
        },
        {
            es1: 'ܐܪܘܛ',
            id: '104',
            dt3: '',
            dt1: 'Arout',
            es2: 'ܐܪܘܛ',
            ar3: '',
            bedeutung: 'männl., Arout war Schüler des HL. Ephräm',
            ar1: 'ܐܪܘܛ',
            dt2: '',
            ar2: 'ܐܰܪܽܘܛ',
            es3: ''
        },
        {
            es2: 'ܥܒܕܝܫܘܥ',
            ar2: 'ܥܒܶܕܝܶܫܘܥ',
            ar1: 'ܥܒܕܝܫܘܥ',
            id: '1189',
            dt1: 'Abedjeschu',
            bedeutung: 'männl., “Knecht/Diener des Jesus”; weitere Formen: Ebedjeschu',
            dt3: '',
            es3: '',
            ar3: '',
            dt2: '',
            es1: 'ܥܒܕܝܫܘܥ'
        },
        {
            ar3: '',
            ar2: 'ܕܰܠܺܝܠܬܰܐ',
            bedeutung: 'weibl., “dünne(s), feine(s), liebe(s), kostbare(s) [Frau/Mädchen]”',
            es1: 'ܕܠܝܠܬܐ',
            dt2: '',
            dt3: '',
            es3: '',
            ar1: 'ܕܠܝܠܬܐ',
            id: '254',
            es2: 'ܕܠܝܠܬܐ',
            dt1: 'Dalilta'
        },
        {
            dt1: 'Atim',
            id: '1301',
            es2: 'ܐܛܝܡ',
            bedeutung: 'weibl., arabischen Ursprungs; “Gabe, Geschenk”',
            ar2: 'ܐܰܛܺܝܡ',
            es3: '',
            ar3: '',
            dt2: '',
            dt3: '',
            es1: 'ܐܛܝܡ',
            ar1: 'ܐܛܝܡ'
        },
        {
            id: '949',
            dt3: '',
            ar3: '',
            dt1: 'Schealtiel',
            es2: 'ܫܠܐܬܝܠ',
            es3: '',
            es1: 'ܫܠܐܬܝܠ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “ich habe [ihn] erbeten von Gott” Im AT ist er Sohn des gefangenen Königs Jojachin und Onkel des Serubbabel; nach dem NT ist er Sohn des Jojachin oder Sohn des Neri, Vater des Serubbabel und ein Ahnherr Jesu. Weitere Formen: Schelathiel',
            dt2: 'Schelatiel',
            ar1: 'ܫܠܐܬܝܠ',
            ar2: 'ܫܶܠܰܐܬܺܝܶܠ'
        },
        {
            ar1: 'ܡܘܣܐ',
            es2: 'ܡܘܣܐ',
            dt3: '',
            es3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Herauszieher”. Im AT ist Musche die führende Gestalt beim Auszug des Volkes Israel aus Ägypten. Weitere Formen: Moses, Moscheh, Musa',
            id: '724',
            es1: 'ܡܘܣܐ',
            ar3: '',
            ar2: 'ܡܽܘܣܰܐ',
            dt2: 'Mousa',
            dt1: 'Musa'
        },
        {
            bedeutung: 'männl., aus dem griechischen “timan”: schätzen, ehren und “theós”: Gott = “Gottesverehrer”, “Fürchtegott”. Im NT ist Timotheos ein Schüler und Begleiter des Apostels Paulus; viele Patriarchen und Bischöfe tragen diesen Namen; einer der Beinamen für die syrischen Bischöfe',
            es1: 'ܬܐܘܦܝܠܘܣ',
            dt3: '',
            id: '2242',
            ar2: 'ܬܶܐܳܘܦܺܝܠܳܘܣ',
            dt1: 'Theophilos',
            es2: 'ܬܐܘܦܝܠܘܣ',
            es3: '',
            ar3: '',
            dt2: '',
            ar1: 'ܬܐܘܦܝܠܘܣ'
        },
        {
            es1: 'ܓܪܝܓܘܪܝܘܣ',
            dt1: 'Georgius',
            ar2: 'ܓܪܝܓܘܪܝܘܣ',
            es2: 'ܓܪܝܓܘܪܝܘܣ',
            id: '376',
            ar3: '',
            es3: '',
            dt3: '',
            dt2: '',
            bedeutung: '',
            ar1: 'ܓܪܝܓܘܪܝܘܣ'
        },
        {
            dt2: '',
            id: '2290',
            es2: 'ܐܘܪܫܝܢܐ',
            dt1: 'Urschaina',
            dt3: '',
            ar2: 'ܐܽܘܪܫܰܝܢܳܐ',
            bedeutung: 'weibl., “Blumen”',
            ar3: '',
            es3: '',
            ar1: 'ܐܘܪܫܝܢܐ',
            es1: 'ܐܘܪܫܝܢܐ'
        },
        {
            ar1: 'ܟܢܥܢ',
            dt1: 'Kenan',
            dt3: '',
            ar3: '',
            dt2: '',
            ar2: 'ܟܶܢܥܰܢ',
            id: '591',
            es1: 'ܟܢܥܢ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Purpur(land)”. Im AT ist Kenan Sohn des Cham/Ham. Die Kenanäer sind ein Stammvolk in Kanaan (alter Name für Israel).',
            es3: '',
            es2: 'ܟܢܥܢ'
        },
        {
            dt2: '',
            es3: '',
            es2: 'ܘܦܐ',
            id: '1105',
            ar3: '',
            ar1: 'ܘܦܐ',
            es1: 'ܘܦܐ',
            ar2: 'ܘܰܦܰܐ',
            dt1: 'Wapa',
            dt3: '',
            bedeutung: 'männl., Wapa war ein aramäischer Philosoph und Schriftteller der vorchristlichen Zeit.'
        },
        {
            ar3: '',
            ar2: 'ܐܰܛܶܒ',
            es1: 'ܐܛܒ',
            es2: 'ܐܛܒ',
            dt1: 'Ateb',
            bedeutung: 'weibl., Athe war eine von den heidnischen Syrern (Aramäern) verehrte Gottheit',
            dt2: '',
            id: '1297',
            es3: '',
            dt3: '',
            ar1: 'ܐܛܒ'
        },
        {
            dt1: 'Tobit',
            ar1: 'ܛܘܒܝܛ',
            id: '2258',
            ar3: '',
            dt3: '',
            es1: 'ܛܘܒܝܛ',
            es3: '',
            bedeutung: 'männl., “Seliger, Guter”; weitere Formen: Tubana',
            es2: 'ܛܘܒܝܛ',
            dt2: '',
            ar2: 'ܛܳܘܒܺܝܛ'
        },
        {
            dt2: '',
            ar1: 'ܐܚܙ',
            es2: 'ܐܚܙ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe hält fest”. Im AT ist Ahas Sohn des Jotam und Vater des Hiskia, König von Juda (741-725 v.Chr.), Zeitgenosse des aramäischen Königs von Damaskus Rezin. Weitere Formen: Ahaz',
            es1: 'ܐܚܙ',
            es3: '',
            ar2: 'ܐܳܚܳܙ',
            dt1: 'Ahas',
            id: '1228',
            dt3: '',
            ar3: ''
        },
        {
            bedeutung: '',
            dt1: 'Ilhab',
            ar3: 'ܐܶܠܗܰܒ',
            es3: 'ܐܠܗܒ',
            id: '1685',
            es1: 'ܐܝܠܗܒ',
            ar1: 'ܐܝܠܗܒ',
            es2: 'ܐܝܠܗܒ',
            dt2: '',
            dt3: '',
            ar2: 'ܐܺܝܠܗܰܒ'
        },
        {
            es1: 'ܣܪܘܢ',
            bedeutung: 'männl., aus der Bibel übernommener aramäisch-hebräischen Ursprungs. Im AT ist Seron Befehlshaber des syrisch-aramäischen Heeres von Aram-Damaskus',
            es2: 'ܣܪܘܢ',
            es3: '',
            ar2: 'ܣܶܪܳܘܢ',
            id: '988',
            dt2: '',
            ar1: 'ܣܪܘܢ',
            ar3: '',
            dt1: 'Seron',
            dt3: ''
        },
        {
            ar2: 'ܙܰܠܳܐ',
            dt1: 'Zalo',
            ar1: 'ܙܠܐ',
            es2: 'ܙܠܐ',
            id: '2353',
            ar3: '',
            dt2: '',
            dt3: '',
            es3: '',
            es1: 'ܙܠܐ',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Schatten, Schutz”'
        },
        {
            es1: 'ܛܒܐܝܠ',
            dt1: 'Tabael',
            ar1: 'ܛܒܐܝܠ',
            id: '2215',
            bedeutung: 'männl., “Frost, Tau”',
            es2: 'ܛܒܐܝܠ',
            es3: '',
            dt3: '',
            ar2: 'ܛܰܒܰܐܶܝܠ',
            dt2: '',
            ar3: ''
        },
        {
            ar3: '',
            bedeutung: 'männl., “Baum”',
            dt3: '',
            dt2: '',
            ar2: 'ܚܽܘܫܰܡ',
            dt1: 'Huscham',
            es2: 'ܚܘܫܡ',
            id: '1679',
            es1: 'ܚܘܫܡ',
            es3: '',
            ar1: 'ܚܘܫܡ'
        },
        {
            dt2: 'Anusch',
            dt1: 'Anosch',
            ar3: '',
            es3: '',
            ar1: 'ܐܢܘܫ',
            bedeutung: 'männl., arabischen Ursprungs; “tapfer, Held”',
            dt3: '',
            es2: 'ܐܢܘܫ',
            ar2: 'ܐܰܢܳܘܫ',
            es1: 'ܐܢܘܫ',
            id: '1268'
        },
        {
            es1: 'ܦܪܬ',
            dt2: '',
            ar1: 'ܦܪܬ݂',
            dt1: 'Froth',
            ar3: '',
            id: '348',
            bedeutung: 'weibl., siehe Euphrat',
            ar2: 'ܦܪܳܬ݂',
            dt3: '',
            es2: 'ܦܪܬ',
            es3: ''
        },
        {
            ar1: 'ܒܪܘܟܬܐ',
            ar2: 'ܒܪܳܘܟܬܰܐ',
            es3: '',
            es1: 'ܒܪܘܟܬܐ',
            id: '1417',
            ar3: '',
            dt1: 'Brukta',
            dt3: '',
            bedeutung: '',
            es2: 'ܒܪܘܟܬܐ',
            dt2: ''
        },
        {
            dt2: '',
            ar1: 'ܐܚܙ',
            ar2: 'ܐܳܚܳܙ',
            id: '51',
            dt3: '',
            es2: 'ܐܚܙ',
            es1: 'ܐܚܙ',
            ar3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe hält fest”. Im AT ist Ahas Sohn des Jotam und Vater des Hiskia, König von Juda (741-725 v.Chr.), Zeitgenosse des aramäischen Königs von Damaskus Rezin. Weitere Formen: Ahaz',
            es3: '',
            dt1: 'Ahas'
        },
        {
            id: '317',
            es3: '',
            dt3: '',
            dt2: '',
            dt1: 'Eramja',
            es2: 'ܐܪܡܝܐ',
            ar2: 'ܐܶܪܰܡܝܰܐ',
            ar3: '',
            bedeutung: '',
            ar1: 'ܐܪܡܝܐ',
            es1: 'ܐܪܡܝܐ'
        },
        {
            es2: 'ܡܪܝ ܛܝܡܬܐܘܣ ܫܡܘܐܝܠ ܐܩܛܫ',
            ar2: 'ܡܪܝ ܛܝܡܬܐܘܣ ܫܡܘܐܝܠ ܐܩܛܫ',
            bedeutung: '',
            dt3: '',
            id: '1904',
            es1: 'ܡܪܝ ܛܝܡܬܐܘܣ ܫܡܘܐܝܠ ܐܩܛܫ',
            es3: '',
            ar1: 'ܡܪܝ ܛܝܡܬܐܘܣ ܫܡܘܐܝܠ ܐܩܛܫ',
            ar3: '',
            dt2: '',
            dt1: 'Mor Timotheos Samuel Aktas'
        },
        {
            es2: 'ܩܢܢܫܪܝܢ',
            dt2: '',
            bedeutung: 'weibl., “Adlerhorst”. Eine syrisch-aramäische Stadt in Aram Beth-Nahrin (= Mesopotamien)',
            ar1: 'ܩܢܢܫܪܝܢ',
            dt1: 'Qenneschrin',
            ar3: '',
            es1: 'ܩܢܢܫܪܝܢ',
            es3: '',
            id: '814',
            ar2: 'ܩܶܢܢܶܫܪܺܝܢ',
            dt3: ''
        },
        {
            es2: 'ܟܠܡܝܕܐ',
            dt1: 'Klamida',
            ar1: 'ܟܠܡܝܕܐ',
            dt2: '',
            bedeutung: 'männl., “Gießkanne, Krug”; weitere Formen: Kuzo',
            ar2: 'ܟܠܰܡܺܝܕܰܐ',
            ar3: '',
            es1: 'ܟܠܡܝܕܐ',
            dt3: '',
            id: '1789',
            es3: ''
        },
        {
            ar1: 'ܐܓܝܒܘܬܐ',
            ar3: '',
            dt2: '',
            id: '1226',
            dt3: '',
            es3: '',
            dt1: 'Agibuta',
            es1: 'ܐܓܝܒܘܬܐ',
            bedeutung: 'weibl., “Antwort”',
            es2: 'ܐܓܝܒܘܬܐ',
            ar2: 'ܐܰܓܺܝܒܽܘܬܐ'
        },
        {
            dt3: '',
            es2: 'ܙܝܬܘܢ',
            id: '2355',
            ar3: '',
            es1: 'ܙܝܬܘܢ',
            ar1: 'ܙܝܬܘܢ',
            dt2: '',
            es3: '',
            bedeutung: 'männl., “Jesus hat gesiegt”',
            ar2: 'ܙܰܝܬܽܘܢ',
            dt1: 'Zaytun'
        },
        {
            ar3: '',
            bedeutung: 'männl., “Sohn des Onkels” oder “Sohn des Volkes”',
            id: '1336',
            dt1: 'Barabba',
            es2: 'ܒܪܐܒܐ',
            dt3: '',
            es3: '',
            dt2: '',
            es1: 'ܒܪܐܒܐ',
            ar1: 'ܒܪܐܒܐ',
            ar2: 'ܒܰܪܐܰܒܰܐ'
        },
        {
            bedeutung: 'weibl., Hattana war eine Stadt in Alt- Syrien',
            ar3: '',
            dt1: 'Hattana',
            ar2: 'ܚܰܬܬܰܐܢܰܐ',
            ar1: 'ܚܬܬܐܢܐ',
            es1: 'ܚܬܬܐܢܐ',
            es2: 'ܚܬܬܐܢܐ',
            es3: '',
            id: '453',
            dt3: '',
            dt2: ''
        },
        {
            dt2: '',
            es3: '',
            id: '1905',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Herauszieher”. Im AT ist Musche die führende Gestalt beim Auszug des Volkes Israel aus Ägypten. Weitere Formen: Moses, Moscheh, Musa',
            ar1: 'ܡܪܝ ܝܘܚܢܢ ܐܒܪܗܡ',
            ar2: 'ܡܪܝ ܝܘܚܢܢ ܐܒܪܗܡ',
            dt3: '',
            es2: 'ܡܪܝ ܝܘܚܢܢ ܐܒܪܗܡ',
            es1: 'ܡܪܝ ܝܘܚܢܢ ܐܒܪܗܡ',
            ar3: '',
            dt1: 'Mor Yuhanon Abrohom'
        },
        {
            id: '210',
            dt2: '',
            bedeutung: 'weibl. Form zu Barnahro; “Tochter des Flusses”; weitere Formen: Batnahra',
            ar1: 'ܒܪ̱ܬܢܗܪܐ',
            dt1: 'Bathnahro',
            ar3: '',
            es2: 'ܒܪܬܢܗܪܐ',
            ar2: 'ܒܰܪ̱ܬܢܰܗܪܳܐ',
            es3: '',
            es1: 'ܒܪܬܢܗܪܐ',
            dt3: ''
        },
        {
            dt1: 'Nayla',
            es3: 'ܢܐܝܠܐ',
            id: '755',
            ar3: 'ܢܰܐܝܠܰܐ',
            es2: 'ܢܝܠܐ',
            bedeutung: '',
            dt3: '',
            ar1: 'ܢܝܠܐ',
            ar2: 'ܢܐܝܠܐ',
            es1: 'ܢܝܠܐ',
            dt2: ''
        },
        {
            dt1: 'Abram',
            es1: 'ܐܒܪܡ',
            ar1: 'ܐܒܪܡ',
            es3: '',
            es2: 'ܐܒܪܡ',
            dt2: '',
            id: '28',
            ar3: '',
            ar2: 'ܐܰܒܪܰܡ',
            bedeutung: 'männl., ursprünglicher Name Abrohoms; “Vater ist erhaben“, weitere Formen: Abiram, Abiramu',
            dt3: ''
        },
        {
            bedeutung: 'männl., siehe Abdo',
            es1: 'ܥܒܘܕ',
            ar1: 'ܥܒܘܕ',
            ar3: '',
            es2: 'ܥܒܘܕ',
            dt2: 'Abud',
            id: '26',
            dt3: '',
            es3: '',
            dt1: 'Aboud',
            ar2: 'ܥܰܒܽܘܕ'
        },
        {
            es2: 'ܙܒܝܢܐ',
            ar3: '',
            id: '878',
            ar2: 'ܙܰܒܺܝܢܰܐ',
            es3: '',
            bedeutung: 'eigentlich männl., aber in der Geschichte weiblich belegt, “Lösegeld, Freilassung, Erlösung, Ankauf, Erwerb, Abfindungssumme”; weitere Formen: Sabina',
            dt3: 'Zabina',
            dt2: 'Sabina',
            dt1: 'Sabine',
            ar1: 'ܙܒܝܢܐ',
            es1: 'ܙܒܝܢܐ'
        },
        {
            ar2: 'ܝܳܗܽܘܫܰܥ',
            ar1: 'ܝܗܘܫܥ',
            bedeutung: 'männl., siehe Josua',
            es2: 'ܝܗܘܫܥ',
            es3: '',
            dt3: '',
            dt2: '',
            es1: 'ܝܗܘܫܥ',
            dt1: 'Johuschuah',
            ar3: '',
            id: '556'
        },
        {
            dt1: 'Rafqa',
            dt2: '',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “die Bestrickende?” oder Kuh”. Im AT ist Rafqa Tochter des Betuel, des Aramäers aus Haran in Paddan-Aram, Schwester des Aramäers Laban, Frau Isaaks und Mutter von Esau und Jakob. Weitere Formen: Rebekka, Rebecca; bei den Aramäern beliebt',
            es2: 'ܪܦܩܐ',
            dt3: '',
            es3: '',
            ar2: 'ܪܰܦܩܰܐ',
            ar1: 'ܪܦܩܐ',
            ar3: '',
            id: '835',
            es1: 'ܪܦܩܐ'
        },
        {
            es1: 'ܐܝܣܚܩ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “er wird lachen” oder “daß Gott lache” oder “das Kind des Lachens”. Im AT ist Ishaq  2000 und 1400 v. Chr.) der Sohn von Abraham und Sara, Halbbruder von Ismael. Abraham sollte ursprünglich Ishaq für Gott opfern, was allerdings dann durch Eingreifen des Engels verhindert wird. Ishaq heiratet Rebekka, die Tochter des Aramäers Betuel aus Haran in Aram Beth-Nahrin (= Mesopotamien), ist Vater der Zwillinge Jakob und Esau. Viele syrische Heilige tragen den Namen Ishaq. Weitere Formen: Isaak, Ishoq',
            ar3: 'ܐܺܝܣܚܳܩ',
            es2: 'ܐܝܣܚܩ',
            id: '510',
            dt3: '',
            dt2: '',
            ar1: 'ܐܝܣܚܩ',
            dt1: 'Ishaak',
            es3: 'ܐܝܣܚܩ',
            ar2: 'ܐܺܝܣܚܰܩ'
        },
        {
            ar3: 'ܐܰܝܽܘܒ',
            ar2: 'ܐܺܝܽܘܒ',
            dt1: 'Ayub',
            id: '1316',
            es2: 'ܐܝܘܒ',
            dt2: 'Ayyub',
            bedeutung: '',
            dt3: '',
            es3: 'ܐܝܘܒ',
            es1: 'ܐܝܘܒ',
            ar1: 'ܐܝܘܒ'
        },
        {
            es2: 'ܒܝܐ',
            ar3: '',
            ar1: 'ܒܝܐ',
            dt3: '',
            dt1: 'Baya',
            id: '1392',
            es3: '',
            bedeutung: 'männl., Heiliger (4. Jh.) der syrisch-orthodoxen Kirche; Festtag: 4. Dezember; weitere Formen: Behnan',
            es1: 'ܒܝܐ',
            dt2: '',
            ar2: 'ܒܰܝܰܐ'
        },
        {
            es2: 'ܕܒܪ',
            id: '248',
            dt3: '',
            dt1: 'Dabar',
            ar1: 'ܕܒܪ',
            ar3: '',
            es1: 'ܕܒܪ',
            es3: '',
            ar2: 'ܕܰܒܰܪ',
            bedeutung: 'männl., “führen, leiten, verwalten”',
            dt2: ''
        },
        {
            id: '889',
            es2: 'ܣܥܕܐ',
            ar2: 'ܣܰܥܕܳܐ',
            dt1: 'Sahdo',
            es1: 'ܣܥܕܐ',
            bedeutung: '',
            ar3: '',
            ar1: 'ܣܥܕܐ',
            dt3: '',
            dt2: 'Sado',
            es3: ''
        },
        {
            dt3: '',
            es3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott ist mein Glück”',
            dt2: '',
            ar3: '',
            id: '361',
            es2: 'ܓܕܐܝܠ',
            dt1: 'Gaddiel',
            ar1: 'ܓܕܐܝܠ',
            es1: 'ܓܕܐܝܠ',
            ar2: 'ܓܰܕܕܺܐܝܶܠ'
        },
        {
            bedeutung: 'männl., “mein Bruder”',
            ar2: 'ܐܰܚܽܘܢܺܝ',
            es3: '',
            dt1: 'Ahuni',
            es2: 'ܐܚܘܢܝ',
            ar3: '',
            dt3: '',
            dt2: '',
            ar1: 'ܐܚܘܢܝ',
            id: '64',
            es1: 'ܐܚܘܢܝ'
        },
        {
            bedeutung: '',
            ar3: '',
            es2: 'ܒܪܒܗܠܘܠ',
            es3: '',
            dt2: '',
            ar2: 'ܒܰܪܒܰܗܠܽܘܠ',
            id: '1332',
            dt1: 'Bar Bahlul',
            ar1: 'ܒܪܒܗܠܘܠ',
            dt3: '',
            es1: 'ܒܪܒܗܠܘܠ'
        },
        {
            bedeutung: 'männl., Schüler des Hl. Ephräm',
            dt2: '',
            dt1: 'Polog',
            es2: 'ܦܠܓ',
            ar2: 'ܦܳܠܳܓ',
            id: '2002',
            ar3: '',
            dt3: '',
            es3: '',
            es1: 'ܦܠܓ',
            ar1: 'ܦܠܓ'
        },
        {
            dt3: '',
            ar1: 'ܒܝܬܙܒܕܝ',
            bedeutung: '',
            id: '1407',
            es2: 'ܒܝܬܙܒܕܝ',
            es3: '',
            ar2: 'ܒܶܝܬܙܰܒܕܰܝ',
            dt1: 'Bethzabdai',
            dt2: '',
            es1: 'ܒܝܬܙܒܕܝ',
            ar3: ''
        },
        {
            ar3: '',
            ar2: 'ܝܰܩܽܘܢܬܳܐ',
            dt2: '',
            id: '2322',
            bedeutung: '',
            es1: 'ܝܘܘܢܬܐ',
            es3: '',
            dt1: 'Yaqunto',
            dt3: '',
            es2: 'ܝܘܘܢܬܐ',
            ar1: 'ܝܩܘܢܬܐ'
        },
        {
            dt1: 'Nahrin',
            es3: '',
            bedeutung: 'weibl., “Flüsse, Ströme”',
            ar3: '',
            es2: 'ܢܗܪܝܢ',
            ar1: 'ܢܗܪܝܢ',
            dt2: '',
            id: '738',
            dt3: '',
            ar2: 'ܢܰܗܪܺܝܢ',
            es1: 'ܢܗܪܝܢ'
        },
        {
            es1: 'ܢܙܪ',
            ar1: 'ܢܙܪ',
            bedeutung: 'männl., “Adler”',
            id: '1948',
            ar3: '',
            dt1: 'Nazar',
            es3: '',
            dt3: '',
            dt2: 'Nassar',
            es2: 'ܢܙܪ',
            ar2: 'ܢܰܙܰܪ'
        },
        {
            es3: '',
            ar1: 'ܝܫܘܥ',
            dt2: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe rettet”, ”Jahwe ist Retter“. Jeschu (Christus) von Nazaret ist nach dem NT Retter und Erlöser der Menschheit. Weitere Formen: Jeschua, Jesus, Isa, Issa',
            ar2: 'ܝܶܫܽܘܥ',
            dt1: 'Jeschu',
            ar3: '',
            id: '538',
            es1: 'ܝܫܘܥ',
            es2: 'ܝܫܘܥ',
            dt3: ''
        },
        {
            id: '1293',
            dt2: '',
            bedeutung: 'weibl., aramäische Form des griechischen Atargatis, aus den beiden Götternamen Athtar und Anat zusammengesetzt. Atarateh war als “Dea Syria” eine syrisch-aramäische Hochgöttin der hellenistischen Zeit, wurde mit der Astarte/Ischtar gleichgesetzt',
            dt3: '',
            ar2: 'ܐܰܣܽܘܢܰܐ',
            es3: '',
            ar1: 'ܐܣܘܢܐ',
            es2: 'ܐܣܘܢܐ',
            ar3: '',
            dt1: 'Asuna',
            es1: 'ܐܣܘܢܐ'
        },
        {
            dt2: 'Bema',
            ar3: 'ܒܺܝܡܰܐ',
            es2: 'ܒܐܡܐ',
            es3: 'ܒܝܡܐ',
            dt3: '',
            bedeutung: 'männl., Schüler Ephräms',
            dt1: 'Bima',
            es1: 'ܒܐܡܐ',
            ar1: 'ܒܐܡܐ',
            id: '1411',
            ar2: 'ܒܺܐܡܰܐ'
        },
        {
            es2: 'ܚܢܢܝܫܘܥ',
            ar3: 'ܚܢܳܢܝܶܫܽܘܥ',
            dt3: '',
            id: '435',
            dt1: 'Hananjeschu',
            es3: 'ܚܢܢܝܫܘܥ',
            dt2: '',
            es1: 'ܚܢܢܝܫܘܥ',
            ar2: 'ܚܰܢܰܢܝܶܫܽܘܥ',
            ar1: 'ܚܢܢܝܫܘܥ',
            bedeutung: 'männl., syrischen Ursprungs; “Gnade Jesu” oder “Jesus hat Gnade”; weitere Formen: Hananischo, Hnonjeschu'
        },
        {
            ar3: '',
            bedeutung: '',
            dt3: '',
            es2: 'ܝܘܚܢܢ ܕܘܠܒܐܢܝ',
            dt1: 'Yuhanon Dolabani',
            es1: 'ܝܘܚܢܢ ܕܘܠܒܐܢܝ',
            es3: '',
            dt2: 'Johannes Dolabani',
            ar1: 'ܝܘܚܢܢ ܕܘܠܒܐܢܝ',
            id: '1141',
            ar2: 'ܝܘܚܢܢ ܕܘܠܒܐܢܝ'
        },
        {
            es1: 'ܢܝܢܝܒ',
            ar1: 'ܢܝܢܝܒ',
            dt1: 'Ninib',
            dt2: '',
            ar2: 'ܢܺܝܢܺܝܒ',
            ar3: '',
            id: '767',
            bedeutung: '',
            es2: 'ܢܝܢܝܒ',
            es3: '',
            dt3: ''
        },
        {
            bedeutung: 'weibl., “Eintracht, Verbundenheit, Vereinigung, Union, Vertrag“',
            es2: 'ܐܘܝܘܬܐ',
            dt2: '',
            id: '131',
            ar2: 'ܐܰܘܝܽܘܬܰܐ',
            ar3: '',
            es3: '',
            dt3: '',
            ar1: 'ܐܘܝܘܬܐ',
            es1: 'ܐܘܝܘܬܐ',
            dt1: 'Auyutha'
        },
        {
            ar2: 'ܐܰܒܺܝܠܬܰܐ',
            es1: 'ܐܒܝܠܬܐ',
            bedeutung: 'weibl. Form zu Abilo',
            ar1: 'ܐܒܝܠܬܐ',
            id: '19',
            dt1: 'Abilta',
            es3: '',
            dt3: '',
            es2: 'ܐܒܝܠܬܐ',
            dt2: '',
            ar3: ''
        },
        {
            dt3: '',
            ar2: 'ܓܰܡܰܠܺܐܝܶܠ',
            es2: 'ܓܡܠܐܝܠ',
            es1: 'ܓܡܠܐܝܠ',
            ar3: '',
            id: '364',
            dt2: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott ist meine Vergeltung”. Im NT ist Gamaliel ein angesehener Pharisäer, Gesetzeslehrer zur Zeit der Apostel und Lehrer des Apostels Paulus',
            ar1: 'ܓܡܠܐܝܠ',
            dt1: 'Gamaliel',
            es3: ''
        },
        {
            bedeutung: 'weibl., “See, Teich”; weitere Formen: Jamtha, Yamta',
            dt3: '',
            ar2: 'ܝܰܡܬ݂ܰܐ',
            ar1: 'ܝܡܬ݂ܐ',
            id: '1127',
            dt1: 'Yamtha',
            es2: 'ܝܡܬܐ',
            dt2: '',
            ar3: '',
            es3: '',
            es1: 'ܝܡܬܐ'
        },
        {
            ar1: 'ܡܪܝܗܒ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gabe, Geschenk Jahwes”. Im NT wird Matia anstelle des Judas Iskariot in den Kreis der 12 Apostel gewählt. Weitere Formen: Matthias',
            ar2: 'ܡܰܪܝܰܗܒ',
            es2: 'ܡܪܝܗܒ',
            dt3: '',
            es3: '',
            es1: 'ܡܪܝܗܒ',
            ar3: '',
            dt1: 'Maryahb',
            dt2: '',
            id: '1869'
        },
        {
            dt2: '',
            es2: 'ܗܢܝܐܘܬܐ',
            id: '438',
            bedeutung: 'weibl., syrischen Ursprungs; “Süße, Genuß, Vergnügung, Gefallen”; weitere Formen: Haniyutha',
            ar1: 'ܗܢܝܐܘܬܐ',
            es3: '',
            es1: 'ܗܢܝܐܘܬܐ',
            ar2: 'ܗܰܢܺܝܐܽܘܬܰܐ',
            ar3: '',
            dt3: '',
            dt1: 'Haniyuta'
        },
        {
            bedeutung: 'männl., “Sohn des Sur”. König von Jadi-Samal-Zincirli, Vater von Panammu II. (743-732)',
            dt3: '',
            ar1: 'ܒܪܨܘܪ',
            dt2: '',
            es2: 'ܒܪܨܘܪ',
            ar3: '',
            ar2: 'ܒܰܪܨܽܘܪ',
            dt1: 'Barsur',
            es3: '',
            es1: 'ܒܪܨܘܪ',
            id: '190'
        },
        {
            ar3: '',
            ar2: 'ܣܰܥܺܝܕ',
            bedeutung: '',
            ar1: 'ܣܥܝܕ',
            dt1: 'Sahid',
            id: '890',
            es3: '',
            dt3: '',
            dt2: '',
            es1: 'ܣܥܝܕ',
            es2: 'ܣܥܝܕ'
        },
        {
            es3: 'ܫܒܘ',
            es1: 'ܫܒܐ',
            ar1: 'ܫܒܐ',
            ar3: 'ܫܰܒܳܘ',
            id: '919',
            bedeutung: 'männl., “Sonntag, Woche”; weitere Formen: Sabo, Schaba',
            dt3: '',
            ar2: 'ܫܰܒܳܐ',
            dt1: 'Schabo',
            dt2: '',
            es2: 'ܫܒܐ'
        },
        {
            dt3: 'Elimelekh',
            es2: 'ܠܠܝܡܠܟܐ',
            es1: 'ܠܠܝܡܠܟܐ',
            es3: '',
            id: '301',
            dt2: 'Elimelech',
            ar3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “mein Gott ist König”; weitere Formen: Elimelech, Elimelekh',
            ar2: 'ܐܶܠܺܝܡܰܠܟܳܐ',
            ar1: 'ܐܠܝܡܠܟܐ',
            dt1: 'Elimalko'
        },
        {
            es2: 'ܟܐܦܐ',
            id: '1780',
            bedeutung: 'weibl., griechischen Ursprungs; “0berkleid, Mantel, Kriegsmantel, Amtskleid, königliche Tracht”',
            ar3: 'ܟܶܐܦܰܐ',
            es1: 'ܟܐܦܐ',
            es3: 'ܟܐܦܐ',
            ar2: 'ܟܺܐܦܳܐ',
            dt2: 'Kipho',
            ar1: 'ܟܐܦܐ',
            dt3: 'Kifo',
            dt1: 'Kepha'
        },
        {
            es2: 'ܢܘܚܡܐ',
            es1: 'ܢܘܚܡܐ',
            bedeutung: 'weibl., “Feuer, Licht der Heimat, der Nation”',
            dt3: '',
            es3: '',
            dt1: 'Nuhomo',
            ar2: 'ܢܽܘܚܳܡܳܐ',
            id: '1969',
            dt2: '',
            ar1: 'ܢܘܚܡܐ',
            ar3: ''
        },
        {
            es1: 'ܕܫܢܐ',
            ar1: 'ܕܫܢܐ',
            es3: '',
            dt1: 'Doschno',
            ar3: '',
            es2: 'ܕܫܢܐ',
            id: '1465',
            ar2: 'ܕܳܫܢܳܐ',
            bedeutung: 'männl., “Unkraut”',
            dt3: '',
            dt2: ''
        },
        {
            dt2: '',
            id: '1053',
            es3: '',
            bedeutung: 'männl., siehe Thomas',
            es2: 'ܬܐܘܡܐ',
            dt1: 'Thoma',
            dt3: '',
            ar1: 'ܬܐܘܡܐ',
            es1: 'ܬܐܘܡܐ',
            ar2: 'ܬܳܐܘܡܰܐ',
            ar3: ''
        },
        {
            id: '2221',
            es1: 'ܬܚܢ',
            ar2: 'ܬܰܚܰܢ',
            dt1: 'Tahan',
            es3: 'ܬܚܢ',
            ar1: 'ܬܚܢ',
            dt3: '',
            es2: 'ܬܚܢ',
            dt2: '',
            bedeutung: 'männl., “Leiter, Führer, Haupt, Kräftiger, Erhabener”',
            ar3: 'ܬܳܚܳܢ'
        },
        {
            dt3: '',
            es2: 'ܫܡܐ',
            bedeutung: 'siehe Schamiram',
            es3: '',
            ar3: '',
            ar1: 'ܫܡܐ',
            dt1: 'Schamo',
            id: '940',
            ar2: 'ܫܰܡܳܐ',
            dt2: '',
            es1: 'ܫܡܐ'
        },
        {
            es1: 'ܪܐܙܐ',
            es2: 'ܪܐܙܐ',
            es3: '',
            ar3: '',
            dt3: '',
            ar1: 'ܪܐܙܐ',
            ar2: 'ܪܰܐܙܰܐ',
            dt1: 'Raza',
            dt2: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs. Reson ist im AT Sohn des Eljada, Diener des aramäischen Königs Hadad-Eservon Soba, König von Aram-Damaskus (10. Jh. v. Chr.), Gegner Salomos. Weitere Formen: Rezon',
            id: '2038'
        },
        {
            ar1: 'ܬܐܘܦܝܠܘܣ',
            dt1: 'Theophilos',
            dt3: '',
            ar3: '',
            es2: 'ܬܐܘܦܝܠܘܣ',
            es3: '',
            bedeutung: 'männl., aus dem griechischen “theós”: Gott und “philos”: freundlich, lieb, Freund = “Gottesfreund, -freudiger”; Patriarch der syrisch-orthodoxen Kirche von Antiochien (2. Jh.). Weitere Formen: Theophil',
            id: '1050',
            ar2: 'ܬܶܐܳܘܦܺܝܠܳܘܣ',
            es1: 'ܬܐܘܦܝܠܘܣ',
            dt2: ''
        },
        {
            es1: 'ܝܘܣܦ',
            ar1: 'ܝܘܣܦ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott ist vor mir”; Weitere Formen: Qadmiel',
            es2: 'ܝܘܣܦ',
            id: '1748',
            ar3: '',
            dt1: 'Josef',
            ar2: 'ܝܰܘܣܶܦ',
            es3: '',
            dt2: 'Yausef',
            dt3: 'Joseph'
        },
        {
            ar1: 'ܪܚܘܒ',
            dt1: 'Rohut',
            dt3: '',
            es3: '',
            ar3: '',
            es1: 'ܪܚܘܒ',
            id: '868',
            es2: 'ܪܚܘܒ',
            ar2: 'ܪܳܚܽܘܒ',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gefährtin, Freundin”. Ruth ist die Hauptgestalt des nach Ihr benannten alttestamentlichen Buches und zugleich eine der Stammmütter des jüdischen Königshauses, nach dem NT eine Ahnfrau Jesu. Weitere Formen: Rut, Rohut',
            dt2: ''
        },
        {
            id: '1585',
            es3: '',
            dt3: '',
            es2: 'ܓܘܪܝܐ',
            bedeutung: 'weibl. Form zu Habobo; weitere Formen: Habobto',
            ar2: 'ܓܽܘܪܝܳܐ',
            dt2: '',
            es1: 'ܓܘܪܝܐ',
            ar1: 'ܓܘܪܝܐ',
            dt1: 'Guryo',
            ar3: ''
        },
        {
            dt1: 'Haggai',
            id: '1608',
            dt3: '',
            es3: 'ܚܐܓܝ',
            es2: 'ܚܓܝ',
            ar2: 'ܚܰܓܰܝ',
            ar3: 'ܚܰܐܓܰܝ',
            es1: 'ܚܓܝ',
            dt2: 'Haggay',
            ar1: 'ܚܓܝ',
            bedeutung: 'männl., “fünf”'
        },
        {
            dt3: '',
            ar3: '',
            es2: 'ܟܦܪܐ',
            ar1: 'ܟܦܪܐ',
            dt1: 'Kafro ',
            dt2: '',
            es3: '',
            ar2: 'ܟܰܦܪܳܐ',
            es1: 'ܟܦܪܐ',
            id: '1758',
            bedeutung: ''
        },
        {
            dt2: '',
            id: '1882',
            es2: 'ܡܕܝܢܬܐ',
            dt3: '',
            ar1: 'ܡܕܝܢ̱ܬܐ',
            es3: '',
            dt1: 'Medita',
            bedeutung: 'männl., „Geburt“',
            ar3: '',
            ar2: 'ܡܶܕܺܝܢ̱ܬܰܐ',
            es1: 'ܡܕܝܢܬܐ'
        },
        {
            bedeutung: 'männl. Form zu Hazne',
            dt1: 'Hasinta',
            ar1: 'ܚܣܝܢܬܐ',
            dt2: '',
            es2: 'ܚܣܝܢܬܐ',
            dt3: '',
            ar3: '',
            ar2: 'ܚܰܣܺܝܢܬܰܐ',
            id: '1637',
            es3: '',
            es1: 'ܚܣܝܢܬܐ'
        },
        {
            id: '710',
            ar2: 'ܡܪܝ ܐܝܓܢܐܛܝܘܣ ܙܟܝ ܩܕܡܝܐ',
            es2: ' ܡܪܝ ܐܝܓܢܐܛܝܘܣ ܙܟܝ ܩܕܡܝܐ',
            dt1: 'Mor Ignatius Zakay qadmoyo',
            es3: '',
            ar1: 'ܡܪܝ ܐܝܓܢܐܛܝܘܣ ܙܟܝ ܩܕܡܝܐ',
            ar3: '',
            bedeutung: '',
            dt2: '',
            dt3: '',
            es1: ' ܡܪܝ ܐܝܓܢܐܛܝܘܣ ܙܟܝ ܩܕܡܝܐ'
        },
        {
            ar1: 'ܒܪܝܬܐ',
            dt3: '',
            es2: 'ܒܪܝܬܐ',
            es3: 'ܒܪܝܬܐ',
            dt2: '',
            id: '1416',
            bedeutung: 'weibl., “Hostie, Stück”; weitere Formen: Bruktha männl., “Prüfung, Untersuchung, Inspektion”; weitere Formen: Buhrono',
            dt1: 'Brita',
            ar3: 'ܒܶܪܺܝܬܰܐ',
            es1: 'ܒܪܝܬܐ',
            ar2: 'ܒܪܺܝܬܰܐ'
        },
        {
            es1: 'ܗܕܕܐ',
            ar3: '',
            ar2: 'ܗܰܕܳܕܳܐ',
            ar1: 'ܗܕܕܐ',
            id: '1604',
            dt2: '',
            es3: '',
            dt3: '',
            es2: 'ܗܕܕܐ',
            bedeutung: 'weibl., syrischen Ursprungs; “Weisheit, Genialität”; weitere Formen: Hakimutha',
            dt1: 'Hadodo'
        },
        {
            es1: 'ܒܗܢܡ',
            ar2: 'ܒܶܗܢܐܰܡ',
            dt2: '',
            bedeutung: 'männl., Heiliger (4. Jh.) der syrisch-orthodoxen Kirche; Festtag: 4. Dezember; weitere Formen: Behnan',
            es3: 'ܒܗܢܐܢ',
            dt1: 'Behnam',
            ar1: 'ܒܗܢܡ',
            dt3: '',
            ar3: 'ܒܶܗܢܐܰܢ',
            es2: 'ܒܗܢܡ',
            id: '214'
        },
        {
            ar2: 'ܨܗܺܝܬܰܐ',
            es3: '',
            dt2: '',
            id: '992',
            es1: 'ܨܗܝܬܐ',
            ar1: 'ܨܗܝܬܐ',
            ar3: '',
            bedeutung: 'weibl., “die Durstige”',
            dt3: '',
            dt1: 'Shita',
            es2: 'ܨܗܝܬܐ'
        },
        {
            dt1: 'Dayso',
            es3: '',
            es2: 'ܕܝܨܐ',
            ar1: 'ܕܝܨܐ',
            dt2: '',
            id: '265',
            es1: 'ܕܝܨܐ',
            dt3: '',
            bedeutung: 'männl., “wilde Ziege, wilde Geiß”',
            ar3: '',
            ar2: 'ܕܰܝܨܳܐ'
        },
        {
            id: '1839',
            ar1: 'ܡܠܟܐ',
            ar3: '',
            es2: 'ܡܠܟܐ',
            dt2: '',
            es1: 'ܡܠܟܐ',
            dt3: '',
            ar2: 'ܡܰܠܟܶܐ',
            dt1: 'Malke',
            bedeutung: '1) männl., “Herr, Besitzer, Meister”; weitere Formen: Moro 2) weibl, aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “die Bittere”. Im AT ist Mara die Umbenennung der Person Naima/Noomi',
            es3: ''
        },
        {
            es1: 'ܦܪܬ',
            es2: 'ܦܪܬ',
            ar1: 'ܦܪܬ݂',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Mann Gottes” oder “Gott ist stark”. Im AT ist Gabriel der Erzengel Gottes; im NT ist er der Engel, der dem Zacharias die Geburt Johannes des Taufers ankündigt und Maria die Geburt Jesu. Als Vorname des Hl. Gabriel (†668) von Beth-Qustan bei Hah im Tur Abdin, der auch Reorganisator des Klosters “Mor Gabriel” ist, ist dieser Name bei den Aramäern sehr beliebt; Festtag: 31. August. Weitere Formen: Gabro, Gawriye, Gawro, Djabrail, Cebrail',
            ar2: 'ܦܪܳܬ݂',
            ar3: '',
            dt3: '',
            dt2: '',
            id: '1534',
            dt1: 'Froth',
            es3: ''
        },
        {
            es3: 'ܝܐܠܓܝܢ',
            ar3: 'ܝܰܐܠܓܺܝܢ',
            ar2: 'ܝܰܐܠܓܺܢ',
            ar1: 'ܝܐܠܓܢ',
            bedeutung: '',
            dt1: 'Yalgin',
            dt2: '',
            dt3: '',
            es1: 'ܝܐܠܓܢ',
            id: '1121',
            es2: 'ܝܐܠܓܢ'
        },
        {
            es2: 'ܒܪܟ݂ܐ',
            dt2: 'Bricho',
            ar2: 'ܒܪܺܟ݂ܳܐ',
            ar3: '',
            ar1: 'ܒܪܟ݂ܐ',
            bedeutung: 'weibl., “die Geschaffene”, “die Welt”; weitere Formen: Britha, Berita',
            es1: 'ܒܪܟ݂ܐ',
            dt3: '',
            id: '1414',
            dt1: 'Brikho',
            es3: ''
        },
        {
            dt2: '',
            ar1: 'ܛܘܪܝܐ',
            es2: 'ܛܘܪܝܐ',
            dt1: 'Turoyo',
            es1: 'ܛܘܪܝܐ',
            ar3: '',
            es3: '',
            id: '1083',
            dt3: '',
            ar2: 'ܛܽܘܪܳܝܳܐ',
            bedeutung: 'männl., “Einer aus den Bergen bzw. aus der Region des Gebirges Turabdin: Turabdiner”. “Turo” ist die Kurzbezeichnung für das mesopotamische Gebiet Turabdin'
        },
        {
            es1: 'ܥܡܝܙܕܝܩܐ',
            dt1: 'Amisadiko',
            es3: '',
            id: '78',
            dt2: 'Amisadok ',
            dt3: '',
            es2: 'ܥܡܝܙܕܝܩܐ',
            bedeutung: 'männl., “mein Onkel ist gerecht” oder “mein Volk ist gerecht”',
            ar2: 'ܥܰܡܺܝܙܰܕܝܺܩܳܐ',
            ar1: 'ܥܡܝܙܕܝܩܐ',
            ar3: ''
        },
        {
            dt1: 'Reuma',
            ar1: 'ܪܘܡܐ',
            ar2: 'ܪܽܘܡܰܐ',
            dt2: 'Rume',
            ar3: '',
            bedeutung: 'männl., “du bist mein Liebling, mein Schatz”',
            es1: 'ܪܘܡܐ',
            es3: '',
            id: '2048',
            es2: 'ܪܘܡܐ',
            dt3: 'Ruma'
        },
        {
            dt2: 'Debura',
            id: '266',
            dt1: 'Debora',
            ar3: 'ܕܶܒܳܘܪܰܐ',
            ar2: 'ܕܶܒܽܘܪܰܐ',
            es3: 'ܕܒܘܪܐ',
            dt3: '',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs, “die Biene”. Im AT ist Debora Prophetin und Richterin (um 1200 v.Chr.), Mutter des Hananel, Ahnfrau des Tobit und Tobias',
            ar1: 'ܕܒܘܪܐ',
            es2: 'ܕܒܘܪܐ',
            es1: 'ܕܒܘܪܐ'
        },
        {
            ar1: 'ܠܒܝܒܬܐ',
            bedeutung: 'weibl., syrischen Ursprungs; “die Mutige, die Tapfere, eine tapfere [Frau]”',
            ar2: 'ܠܒܺܝܒܬܰܐ',
            es2: 'ܠܒܝܒܬܐ',
            es1: 'ܠܒܝܒܬܐ',
            es3: '',
            dt3: '',
            dt2: '',
            ar3: '',
            dt1: 'Lbibta',
            id: '623'
        },
        {
            id: '1632',
            dt2: '',
            es2: 'ܚܦܣܝ',
            es1: 'ܚܦܣܝ',
            ar3: '',
            dt3: '',
            bedeutung: 'weibl., Hattana war eine Stadt in Alt- Syrien',
            ar2: 'ܚܰܦܣܰܝ',
            es3: '',
            dt1: 'Haphsai',
            ar1: 'ܚܦܣܝ'
        },
        {
            dt2: 'Mesut',
            es3: '',
            ar1: 'ܡܣܥܘܕ',
            dt3: '',
            es1: 'ܡܣܥܘܕ',
            dt1: 'Mashud',
            bedeutung: 'weibl., “Stadt, Land, Provinz”; weitere Formen: Mdita',
            es2: 'ܡܣܥܘܕ',
            ar2: 'ܡܶܣܥܽܘܕ',
            ar3: '',
            id: '1873'
        },
        {
            ar3: '',
            ar2: 'ܥܰܒܶܕܰܐܠܰܬ',
            es3: '',
            ar1: 'ܥܒܕܐܠܬ',
            id: '11',
            es2: 'ܥܒܕܐܠܬ',
            es1: 'ܥܒܕܐܠܬ',
            dt2: '',
            dt1: 'Abedallat',
            bedeutung: 'männl., wohl “Knecht Gottes”; weitere Formen: Abdallat',
            dt3: ''
        },
        {
            dt1: 'Zkhojeschu',
            dt3: '',
            es2: 'ܙܟ݂ܐܝܫܘܥ',
            es1: 'ܙܟ݂ܐܝܫܘܥ',
            ar3: '',
            bedeutung: 'männl., “Jesus hat gesiegt”',
            es3: '',
            ar2: 'ܙܟ݂ܳܐܝܶܫܽܘܥ',
            ar1: 'ܙܟ݂ܐܝܫܘܥ',
            id: '1173',
            dt2: ''
        },
        {
            es3: '',
            es1: 'ܣܒܪܐ',
            dt3: '',
            ar3: '',
            dt1: 'Sabro',
            es2: 'ܣܒܪܐ',
            id: '2074',
            dt2: '',
            ar1: 'ܣܒܪܐ',
            bedeutung: 'männl., “er hat ausgehalten, ertragen”; weitere Formen: Saybar',
            ar2: 'ܣܰܒܪܳܐ'
        },
        {
            dt2: '',
            ar2: 'ܕܰܟܺܝ',
            es2: 'ܕܟܝ',
            bedeutung: 'männl., “gereinigt geputzt, gesäubert”',
            ar3: '',
            es1: 'ܕܟܝ',
            dt1: 'Daki',
            ar1: 'ܕܟܝ',
            es3: '',
            id: '250',
            dt3: ''
        },
        {
            es3: '',
            dt3: '',
            bedeutung: '1) männl., “Herr, Besitzer, Meister”; weitere Formen: Moro 2) weibl, aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “die Bittere”. Im AT ist Mara die Umbenennung der Person Naima/Noomi',
            ar3: '',
            ar1: 'ܡܪܐ',
            dt1: 'Mara',
            ar2: 'ܡܰܪܰܐ',
            es1: 'ܡܪܐ',
            dt2: '',
            id: '660',
            es2: 'ܡܪܐ'
        },
        {
            ar3: '',
            es2: 'ܓܝܕܘܬܐ',
            es3: '',
            ar1: 'ܓܝܕܘܬܐ',
            ar2: 'ܓܺܝܕܽܘܬܰܐ',
            dt3: '',
            id: '1571',
            es1: 'ܓܝܕܘܬܐ',
            dt1: 'Giduta',
            dt2: '',
            bedeutung: 'männl., siehe Gewargis'
        },
        {
            dt1: 'Amanuel',
            es3: 'ܐܡܢܘܐܝܠ',
            dt2: 'Immanuel',
            ar3: 'ܐܡܢܘܐܝܠ',
            id: '1251',
            bedeutung: 'weibl., “ﬂüchten, entﬂiehen, entkommen, gerettet werden, erreichen, ausreichen, fähig sein”. Amed ist der syrisch-aramäische Name der heutigen Stadt “Diyarbakir”',
            es1: 'ܥܡܢܘܐܝܠ',
            dt3: 'Manuel',
            ar1: 'ܥܡܢܘܐܝܠ',
            ar2: 'ܥܰܡܰܢܽܘܐܝܶܠ',
            es2: 'ܥܡܢܘܐܝܠ'
        },
        {
            ar1: 'ܓܘܪܓܝܣ',
            es3: '',
            dt3: '',
            dt1: 'Gewargis',
            es2: 'ܓܘܪܓܝܣ',
            id: '380',
            dt2: '',
            bedeutung: 'männl., aus dem Griechischen übernommener Vorname; “der Landmann/Landwirt”. Mor Gewargis gilt in der Geschichte als Drachentöter und Schutzpatron der Waffenschmiede, Krieger und Landleute, einer der 14 Nothelfer. Weitere Formen: Gewargi, Georg, George, Corc, Circis',
            ar2: 'ܓܶܘܰܪܓܺܝܣ',
            es1: 'ܓܘܪܓܝܣ',
            ar3: ''
        },
        {
            es2: 'ܢܡܪܐ',
            dt2: '',
            ar2: 'ܢܶܡܪܳܐ',
            id: '1952',
            es1: 'ܢܡܪܐ',
            es3: '',
            ar1: 'ܢܡܪܐ',
            dt3: '',
            bedeutung: '',
            ar3: '',
            dt1: 'Nemro'
        },
        {
            ar1: 'ܐܕܘܪ',
            dt2: 'Adwar',
            ar3: '',
            es3: '',
            es2: 'ܐܕܘܪ',
            dt3: '',
            ar2: 'ܐܰܕܘܰܪ',
            id: '42',
            bedeutung: '',
            dt1: 'Advar',
            es1: 'ܐܕܘܪ'
        },
        {
            dt2: '',
            dt1: 'Schamschagram',
            bedeutung: 'männl., “Fülle” oder “Schwur” oder “Sieben”. Enkel von Arphachschad und Eber. Weitere Formen: Scheba',
            es3: 'ܫܡܫܓܪܡ',
            dt3: '',
            ar2: 'ܫܰܡܫܰܓܪܰܡ',
            ar3: 'ܫܰܡܶܫܓܪܰܡ',
            es1: 'ܫܡܫܓܪܡ',
            ar1: 'ܫܡܫܓܪܡ',
            es2: 'ܫܡܫܓܪܡ',
            id: '2133'
        },
        {
            dt3: 'Debscho',
            es3: 'ܕܒܫܐ',
            ar3: 'ܕܶܒܫܳܐ',
            ar1: 'ܕܘܫܐ',
            dt1: 'Dauscho',
            ar2: 'ܕܰܘܫܳܐ',
            es2: 'ܕܘܫܐ',
            bedeutung: 'männl., ”Honig“, weitere Formen: Dawscho, Doscho, Debscho',
            dt2: 'Debscho',
            id: '263',
            es1: 'ܕܘܫܐ'
        },
        {
            dt3: '',
            dt2: '',
            ar3: '',
            bedeutung: '',
            id: '1980',
            es2: 'ܢܘܪܬܐ',
            es1: 'ܢܘܪܬܐ',
            es3: '',
            ar2: 'ܢܽܘܪܬܰܐ',
            dt1: 'Nurta',
            ar1: 'ܢܘܪܬܐ'
        },
        {
            dt1: 'Kinutha',
            ar3: '',
            id: '600',
            dt3: '',
            es2: 'ܟܐܢܘܬܐ',
            ar2: 'ܟܺܐܢܽܘܬܳܐ',
            es1: 'ܟܐܢܘܬܐ',
            es3: '',
            ar1: 'ܟܐܢܘܬܐ',
            dt2: '',
            bedeutung: 'weibl., syrischen Ursprungs; “Gerechtigkeit, Rechtschaffenheit”; weitere Formen: Kinuta'
        },
        {
            id: '1482',
            dt2: '',
            dt3: '',
            es3: '',
            ar1: 'ܐܠܝܐܬܐ',
            es2: 'ܐܠܝܐܬܐ',
            ar3: '',
            es1: 'ܐܠܝܐܬܐ',
            ar2: 'ܐܶܠܺܝܐܰܬܰܐ',
            dt1: 'Eliata',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott hat geholfen”. Im AT ist Elischa ein Prophet (um 850 v.Chr.). Weitere Formen: Elischa, Elischo, Elischoh, Elisa'
        },
        {
            ar1: 'ܟܪܝܘܬܐ',
            dt3: '',
            id: '583',
            dt1: 'Karyuta',
            dt2: '',
            ar3: '',
            es1: 'ܟܪܝܘܬܐ',
            es2: 'ܟܪܝܘܬܐ',
            bedeutung: 'weibl., syrischen Ursprungs; “Traurigkeit, Trübsal”; weitere Formen: Karyutha',
            es3: '',
            ar2: 'ܟܰܪܝܽܘܬܰܐ'
        },
        {
            id: '873',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gefährtin, Freundin”. Ruth ist die Hauptgestalt des nach Ihr benannten alttestamentlichen Buches und zugleich eine der Stammmütter des jüdischen Königshauses, nach dem NT eine Ahnfrau Jesu. Weitere Formen: Rut, Rohut',
            es3: '',
            es1: 'ܪܥܘܬ',
            ar1: 'ܪܥܘܬ',
            es2: 'ܪܥܘܬ',
            dt2: '',
            dt1: 'Ruth',
            ar2: 'ܪܳܥܽܘܬ',
            ar3: '',
            dt3: ''
        },
        {
            bedeutung: 'weibl., syrischen Ursprungs; “Identität, Zustand, Essenz, Wesen, Bild”; weitere Formen: Hiyutha',
            dt1: 'Hewtha',
            es2: 'ܚܘܬܐ',
            ar2: 'ܚܶܘܬܰܐ',
            dt3: '',
            es3: 'ܚܐܘܬܐ',
            es1: 'ܚܘܬܐ',
            ar1: 'ܚܘܬܐ',
            dt2: '',
            ar3: 'ܚܶܐܘܬܰܐ',
            id: '1661'
        },
        {
            es1: 'ܫܦܝ',
            ar2: 'ܫܰܦܺܝ',
            bedeutung: 'männl., “reinigen, säubem, putzen”',
            ar3: 'ܫܰܐܦܺܝ',
            dt3: '',
            es2: 'ܫܦܝ',
            dt2: '',
            ar1: 'ܫܦܝ',
            dt1: 'Schafi',
            es3: 'ܫܐܦܝ',
            id: '920'
        },
        {
            id: '1544',
            es1: 'ܓܒܪܐܝܠܐ',
            dt3: '',
            es3: '',
            dt2: '',
            dt1: 'Gabriela',
            ar2: 'ܓܰܒܪܺܐܝܶܠܰܐ',
            ar3: '',
            bedeutung: 'weibl., “Garten, Paradies”, weitere Formen: Gantha',
            ar1: 'ܓܒܪܐܝܠܐ',
            es2: 'ܓܒܪܐܝܠܐ'
        },
        {
            dt2: '',
            dt3: '',
            es2: 'ܬܡܢܝܬܐ',
            es3: '',
            ar3: '',
            es1: 'ܬܡܢܝܬܐ',
            id: '1034',
            ar1: 'ܬܡܢܝܬܐ',
            bedeutung: 'weibl., siehe Tamania',
            ar2: 'ܬܰܡܰܢܺܝܬܰܐ',
            dt1: 'Tamanita'
        },
        {
            es2: 'ܪܒܐܝܠ',
            dt2: '',
            dt3: '',
            id: '2018',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “die Bestrickende?” oder Kuh”. Im AT ist Rafqa Tochter des Betuel, des Aramäers aus Haran in Paddan-Aram, Schwester des Aramäers Laban, Frau Isaaks und Mutter von Esau und Jakob. Weitere Formen: Rebekka, Rebecca; bei den Aramäern beliebt',
            dt1: 'Rabil',
            ar1: 'ܪܒܐܝܠ',
            es1: 'ܪܒܐܝܠ',
            es3: '',
            ar3: '',
            ar2: 'ܪܰܒܐܺܝܠ'
        },
        {
            dt2: '',
            id: '1617',
            es1: 'ܚܡܘܛܠ',
            dt1: 'Hamutal',
            bedeutung: 'weibl., syrischen Ursprungs; “Süße, Genuß, Vergnügung, Gefallen”; weitere Formen: Haniyutha',
            ar3: '',
            es2: 'ܚܡܘܛܠ',
            es3: '',
            dt3: '',
            ar1: 'ܚܡܘܛܠ',
            ar2: 'ܚܰܡܽܘܛܰܠ'
        },
        {
            es2: 'ܓܕܝ',
            ar3: 'ܓܰܐܕܺܝ',
            es3: 'ܓܐܕܝ',
            ar1: 'ܓܕܝ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “mein Glück”; weitere Formen: Gadi',
            dt3: '',
            es1: 'ܓܕܝ',
            id: '360',
            ar2: 'ܓܰܕܺܝ',
            dt2: '',
            dt1: 'Gaddi'
        },
        {
            dt2: '',
            ar1: 'ܐܟܪ',
            es2: 'ܐܟܪ',
            ar3: '',
            dt3: '',
            dt1: 'Akar',
            es3: '',
            id: '65',
            es1: 'ܐܟܪ',
            bedeutung: 'männl., “pﬂügen, anbauen”',
            ar2: 'ܐܰܟܰܪ'
        },
        {
            dt2: '',
            dt3: '',
            ar2: 'ܐܰܒܠܰܚܰܕ',
            ar1: 'ܐܒܠܚܕ',
            es1: 'ܐܒܠܚܕ',
            ar3: '',
            es2: 'ܐܒܠܚܕ',
            bedeutung: 'männl., siehe Abdullahad',
            es3: '',
            id: '24',
            dt1: 'Ablahad'
        },
        {
            dt1: 'Schomir',
            ar2: 'ܫܳܡܺܝܪ',
            id: '971',
            dt3: '',
            es1: 'ܫܡܝܪ',
            es3: '',
            es2: 'ܫܡܝܪ',
            dt2: '',
            ar3: '',
            bedeutung: 'männl., syrischen Ursprungs; “Diamant”. Schomir ist in der Geschichte des Klosters Mor Gabriel in Turabdin Bruder des Mor Samuels (†409). Weitere Formen: Schumir',
            ar1: 'ܫܡܝܪ'
        },
        {
            ar3: '',
            es3: '',
            es2: 'ܒܒܬܐ',
            dt1: 'Bobta',
            id: '232',
            dt2: '',
            ar2: 'ܒܳܒܬܰܐ',
            dt3: '',
            es1: 'ܒܒܬܐ',
            ar1: 'ܒܒܬܐ',
            bedeutung: 'weibl., “Augenpupille”; weitere Formen: Bobto'
        },
        {
            bedeutung: 'weibl., Nebenform zu Sara',
            dt3: '',
            es1: 'ܨܡܘܐܠ',
            ar3: 'ܫܡܘܐܝܠ',
            ar2: 'ܨܰܡܽܘܐܝܶܠ',
            id: '2095',
            es3: 'ܨܡܘܐܝܠ',
            dt1: 'Samuel',
            dt2: '',
            es2: 'ܨܡܘܐܠ',
            ar1: 'ܨܡܘܐܝܠ'
        },
        {
            dt3: '',
            es1: 'ܝܕܠܦ',
            bedeutung: 'männl., Jidlaf ist nach dem AT Bruder Kesed und Betuels, Sohn Nahors. Weitere Formen: Jarlaf, Jirlaf',
            es2: 'ܝܕܠܦ',
            id: '547',
            ar3: 'ܝܰܪܠܰܦ',
            dt2: '',
            ar2: 'ܝܺܕܠܰܦ',
            es3: 'ܝܪܠܦ',
            dt1: 'Jidlaf',
            ar1: 'ܝܕܠܦ'
        },
        {
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “marah: bitter, betrübt“, ”Gebieterin, Herrin”. Im NT ist Martha die Schwester der Maria und des Lazarus von Betanien und wurde die Patronin der Hausfrauen. Weitere Formen: Marta, Morto; sehr beliebter Vorname bei den Aramäern',
            ar1: 'ܡܪܬܐ',
            dt1: 'Marta',
            es2: 'ܡܪܬܐ',
            dt3: '',
            es3: 'ܡܪܬܐ',
            ar3: 'ܡܳܪܬܳܐ',
            id: '676',
            dt2: 'Martha',
            es1: 'ܡܪܬܐ',
            ar2: 'ܡܰܪܬܰܐ'
        },
        {
            ar2: 'ܢܶܫܪܰܝܬܰܐ',
            ar1: 'ܢܫܪܝܬܐ',
            es3: '',
            dt2: '',
            es2: 'ܢܫܪܝܬܐ',
            bedeutung: 'weibl., “Adlersstein”; weitere Formen: Neschrajta',
            id: '764',
            dt3: '',
            ar3: '',
            dt1: 'Neschrayta',
            es1: 'ܢܫܪܝܬܐ'
        },
        {
            ar2: 'ܐܰܪܢܺܝ',
            es1: 'ܐܪܢܝ',
            dt1: 'Arni',
            dt2: '',
            id: '103',
            es2: 'ܐܪܢܝ',
            ar1: 'ܐܪܢܝ',
            bedeutung: 'männl., “er hat gedacht”',
            dt3: '',
            es3: '',
            ar3: ''
        },
        {
            ar3: '',
            id: '420',
            es1: 'ܚܕܘܬܐ',
            ar1: 'ܚܕܘܬܐ',
            dt2: 'Hadutha',
            es2: 'ܚܕܘܬܐ',
            dt1: 'Haduta',
            es3: '',
            dt3: '',
            bedeutung: 'weibl., syrischen Ursprungs; “Freude, Wohlgefallen”, “Braut” oder “Säule”; weitere Formen: Hadutha',
            ar2: 'ܚܰܕܽܘܬܰܐ'
        },
        {
            ar3: '',
            dt3: '',
            dt1: 'Herumo',
            ar2: 'ܗܶܪܽܘܡܐ',
            ar1: 'ܗܪܘܡܐ',
            id: '1657',
            es2: 'ܗܪܘܡܐ',
            bedeutung: 'männl.; Hiran (217-250) von Beth Aramäa (Irak) war Bischof von Arbela. Weitere Formen: Hairan',
            es3: '',
            es1: 'ܗܪܘܡܐ',
            dt2: ''
        },
        {
            dt2: 'Andreos',
            dt3: 'Andrawos',
            ar1: 'ܐܢܕܪܐܘܣ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname griechischen Ursprungs; “der Mannhafte, der Männliche, der Tapfere”. Im NT ist Andreas einer der zwölf Apostel Jesu Christi und zugleich der Bruder des Apostels Petrus',
            ar3: '',
            es3: '',
            es1: 'ܐܢܕܪܐܘܣ',
            id: '86',
            dt1: 'Andreas',
            es2: 'ܐܢܕܪܐܘܣ',
            ar2: 'ܐܰܢܕܪܶܐܘܳܣ'
        },
        {
            dt1: 'Endo',
            es2: 'ܥܢܕܐ',
            ar1: 'ܥܢܕܐ',
            es3: '',
            dt3: '',
            ar3: '',
            bedeutung: 'männl., “Nachtigall”',
            ar2: 'ܥܶܢܕܳܐ',
            id: '313',
            es1: 'ܥܢܕܐ',
            dt2: ''
        },
        {
            es3: '',
            id: '157',
            ar3: '',
            dt3: '',
            dt2: '',
            dt1: 'Barachel',
            ar2: 'ܒܰܪܰܐܟ݂ܶܝܠ',
            ar1: 'ܒܪܐܟ݂ܝܠ',
            es2: 'ܒܪܐܟ݂ܝܠ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott segnet”; weitere Formen: Barakhel',
            es1: 'ܒܪܐܟ݂ܝܠ'
        },
        {
            ar1: 'ܒܪܫܠܡܐ',
            ar2: 'ܒܰܪܫܠܳܡܳܐ',
            es2: 'ܒܪܫܠܡܐ',
            es1: 'ܒܪܫܠܡܐ',
            dt1: 'Barschlomo',
            es3: '',
            id: '188',
            dt3: '',
            bedeutung: 'männl., “Sohn des Friedens”; weitere Formen: Barschloma',
            dt2: '',
            ar3: ''
        },
        {
            ar2: 'ܐܰܗܪܽܘܢ',
            id: '1178',
            ar3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Bergbewohner, Erleuchteter”. Im AT ist Ahrun der ältere Bruder von Moses aus dem Stamm Levi und Ahnherr der Priesterklasse. Weitere Formen: Aaron, Aron',
            es1: 'ܐܗܪܘܢ',
            dt3: 'Aron',
            dt2: 'Ahrun',
            es2: 'ܐܗܪܘܢ',
            dt1: 'Aaron',
            ar1: 'ܐܗܪܘܢ',
            es3: ''
        },
        {
            es1: 'ܟܟܪܝܬܐ',
            dt1: 'Kakorita',
            dt2: '',
            es3: '',
            es2: 'ܟܟܪܝܬܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Weinberg”. Im AT ist Karmo/Karmi vierter und letzter Sohn Rubens (Rubel). Weitere Formen: Karmi',
            dt3: '',
            id: '1761',
            ar2: 'ܟܰܟܳܪܺܝܬܰܐ',
            ar1: 'ܟܟܪܝܬܐ',
            ar3: ''
        },
        {
            es3: '',
            es1: 'ܐܡܬܐ',
            es2: 'ܐܡܬܐ',
            dt3: '',
            dt1: 'Amta',
            ar2: 'ܐܰܡܬܰܐ',
            ar3: '',
            ar1: 'ܐܡܬܐ',
            bedeutung: 'weibl., “Dienstmädchen”',
            dt2: '',
            id: '85'
        },
        {
            dt2: 'Schusche',
            ar3: 'ܣܽܘܣܺܝ',
            es1: 'ܫܘܫܐ',
            bedeutung: '',
            dt1: 'Susi',
            id: '1020',
            dt3: '',
            ar2: 'ܫܽܘܫܶܐ',
            es2: 'ܫܘܫܐ',
            es3: 'ܣܘܣܝ',
            ar1: 'ܫܘܫܐ'
        },
        {
            es1: 'ܣܛܝܦܐ',
            ar2: 'ܣܛܰܝܦܳܐ',
            dt3: '',
            bedeutung: 'männl., Nebenform Stephanus',
            ar1: 'ܣܛܝܦܐ',
            es2: 'ܣܛܝܦܐ',
            dt1: 'Stayfo',
            dt2: 'Steyfo',
            es3: '',
            id: '1008',
            ar3: ''
        },
        {
            bedeutung: '',
            ar2: 'ܡܺܝܠܺܝܕܝܰܐ',
            es2: 'ܡܝܠܝܕܝܐ',
            id: '1892',
            es3: '',
            ar3: '',
            es1: 'ܡܝܠܝܕܝܐ',
            ar1: 'ܡܝܠܝܕܝܐ',
            dt3: '',
            dt2: '',
            dt1: 'Milidja'
        },
        {
            dt2: '',
            es1: 'ܛܒܐ',
            es3: '',
            dt3: '',
            ar3: '',
            ar2: 'ܛܳܒܳܐ',
            id: '2259',
            ar1: 'ܛܒܐ',
            bedeutung: 'männl., siehe Tuban',
            dt1: 'Tobo',
            es2: 'ܛܒܐ'
        },
        {
            es3: '',
            es2: 'ܦܩܘܕ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs. Nach dem AT aramäischer Nomadenstamm im Osten Babylons. Weitere Formen: Peqod',
            ar1: 'ܦܩܘܕ',
            dt3: '',
            ar3: '',
            ar2: 'ܦܶܩܳܘܕ',
            dt1: 'Pekod',
            dt2: '',
            id: '804',
            es1: 'ܦܩܘܕ'
        },
        {
            dt2: '',
            id: '2271',
            es1: 'ܬܘܡܐ',
            es3: '',
            dt1: 'Tuma',
            bedeutung: 'weibl./männl., “Stadt des Lebens”. Urhoy ist eine Stadt in Aram Beth-Nahrin (= Mesopotamien). Urhoy oder Urhay heißt auf Griechisch Edessa, heute Urfa. Nach einer anderen Theorie soll Urhay oder Arhoy eine männliche Person gewesen sein, nach der die Stadt Urhay/Arhoy genannt wird, insofern kann es auch männlich verwendet werden. Weitere Formen: Urhoy, Urhai',
            ar1: 'ܬܘܡܐ',
            dt3: '',
            es2: 'ܬܘܡܐ',
            ar3: '',
            ar2: 'ܬܽܘܡܰܐ'
        },
        {
            ar2: 'ܬܶܐܘܳܕܳܘܣܺܝܳܘܣ',
            ar3: '',
            dt3: '',
            ar1: 'ܬܐܘܕܘܣܝܘܣ',
            id: '1049',
            es1: 'ܬܐܘܕܘܣܝܘܣ',
            es2: 'ܬܐܘܕܘܣܝܘܣ',
            es3: '',
            dt2: '',
            bedeutung: 'männl., aus dem Griechischen übernommener Vorname; “Gottesgabe, -geschenk”; weitere Formen: Theodosios',
            dt1: 'Theodosius'
        },
        {
            es1: 'ܐܓܘܣܛܘܣ',
            es3: '',
            es2: 'ܐܓܘܣܛܘܣ',
            ar3: '',
            ar1: 'ܐܓܘܣܛܘܣ',
            ar2: 'ܐܰܘܓܳܘܣܛܳܘܣ',
            dt2: '',
            bedeutung: 'weibl., “Eintracht, Verbundenheit, Vereinigung, Union, Vertrag“',
            id: '1309',
            dt3: '',
            dt1: 'Augustos'
        },
        {
            es3: '',
            bedeutung: '',
            dt2: '',
            id: '229',
            es1: 'ܒܝܠܢ',
            ar2: 'ܒܺܝܠܶܢ',
            dt3: '',
            ar3: '',
            es2: 'ܒܝܠܢ',
            dt1: 'Bilen',
            ar1: 'ܒܝܠܢ'
        },
        {
            ar3: '',
            dt1: 'Farid',
            dt3: '',
            es3: '',
            es2: 'ܦܪܝܕ',
            bedeutung: 'männl., “Eingeborener, Einziggeborener, eigenartig”',
            dt2: '',
            es1: 'ܦܪܝܕ',
            ar2: 'ܦܰܪܺܝܕ',
            id: '333',
            ar1: 'ܦܪܝܕ'
        },
        {
            ar1: 'ܐܝܪܕ',
            dt2: '',
            dt3: '',
            ar3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “er wird lachen” oder “daß Gott lache” oder “das Kind des Lachens”. Im AT ist Ishaq  2000 und 1400 v. Chr.) der Sohn von Abraham und Sara, Halbbruder von Ismael. Abraham sollte ursprünglich Ishaq für Gott opfern, was allerdings dann durch Eingreifen des Engels verhindert wird. Ishaq heiratet Rebekka, die Tochter des Aramäers Betuel aus Haran in Aram Beth-Nahrin (= Mesopotamien), ist Vater der Zwillinge Jakob und Esau. Viele syrische Heilige tragen den Namen Ishaq. Weitere Formen: Isaak, Ishoq',
            es2: 'ܐܝܪܕ',
            id: '1690',
            es3: '',
            ar2: 'ܐܺܝܪܰܕ',
            dt1: 'Irad',
            es1: 'ܐܝܪܕ'
        },
        {
            ar2: 'ܪܰܟܺܝܒܺܐܝܠ',
            es3: '',
            id: '838',
            dt2: '',
            bedeutung: 'männl., “Fahrer, Reiter Gottes”. Rakibel war ein Schutzgott der aramäischen Dynastie von Samal-Jau` di (=links, d.h. Norden: Nordstaat eines aramäischen Stammes)',
            es1: 'ܪܟܝܒܐܝܠ',
            ar3: '',
            dt1: 'Rakibel',
            dt3: '',
            ar1: 'ܪܟܝܒܐܝܠ',
            es2: 'ܪܟܝܒܐܝܠ'
        },
        {
            bedeutung: 'weibl., “Narzisse”; weitere Formen: Nargis',
            es3: '',
            dt1: 'Nergiz',
            ar1: 'ܢܪܓܝܣ',
            dt2: 'Nergis',
            dt3: '',
            ar2: 'ܢܰܪܓܝܣ',
            es2: 'ܢܪܓܝܣ',
            id: '761',
            ar3: '',
            es1: 'ܢܪܓܝܣ'
        },
        {
            ar3: '',
            es2: 'ܐܒܣܡܝܐ',
            es1: 'ܐܒܣܡܝܐ',
            ar2: 'ܐܰܒܣܰܡܝܰܐ',
            bedeutung: 'männl., Absamja war Schwestersohn des Mor Ephräm; weitere Formen: Absamjo, Absamyo, Absamya',
            es3: '',
            dt3: '',
            id: '31',
            dt1: 'Absamja',
            dt2: '',
            ar1: 'ܐܒܣܡܝܐ'
        },
        {
            ar3: '',
            bedeutung: 'weibl. Form zu Abilo',
            dt2: '',
            es2: 'ܐܒܝܠܬܐ',
            id: '1196',
            es3: '',
            dt1: 'Abilta',
            ar1: 'ܐܒܝܠܬܐ',
            ar2: 'ܐܰܒܺܝܠܬܰܐ',
            es1: 'ܐܒܝܠܬܐ',
            dt3: ''
        },
        {
            dt1: 'Arib',
            es2: 'ܐܪܝܒ',
            bedeutung: 'männl., “erhöht, erhaben, verherrlicht”',
            dt3: '',
            es1: 'ܐܪܝܒ',
            dt2: '',
            ar3: '',
            ar2: 'ܐܰܪܺܝܒ',
            ar1: 'ܐܪܝܒ',
            es3: '',
            id: '1278'
        },
        {
            dt1: 'Nuno',
            dt3: '',
            ar2: 'ܢܽܘܢܳܐ',
            es2: 'ܢܘܢܐ',
            es3: '',
            bedeutung: 'männl., “Fisch”',
            ar3: '',
            es1: 'ܢܘܢܐ',
            ar1: 'ܢܘܢܐ',
            dt2: '',
            id: '781'
        },
        {
            es2: 'ܚܒܝܒܬܐ',
            es1: 'ܚܒܝܒܬܐ',
            dt2: 'Habibto',
            ar2: 'ܚܰܒܺܝܒܬܰܐ',
            bedeutung: 'weibl. Form zu Habib; weitere Formen: Habibto',
            es3: '',
            id: '403',
            ar3: '',
            ar1: 'ܚܒܝܒܬܐ',
            dt3: '',
            dt1: 'Habibta'
        },
        {
            ar2: 'ܙܰܕܺܝܩܰܐ',
            id: '1147',
            ar1: 'ܙܕܝܩܐ',
            dt3: '',
            es1: 'ܙܕܝܩܐ',
            es2: 'ܙܕܝܩܐ',
            dt1: 'Zadiqa',
            dt2: '',
            es3: '',
            ar3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “der Gerechte”; weitere Formen: Zadiqo, Zadok'
        },
        {
            ar2: 'ܚܰܒܪܽܘܬܰܐ',
            es1: 'ܚܒܪܘܬܐ',
            ar3: '',
            es3: '',
            ar1: 'ܚܒܪܘܬܐ',
            dt2: 'Habrutha',
            dt3: '',
            dt1: 'Habruta',
            es2: 'ܚܒܪܘܬܐ',
            id: '407',
            bedeutung: 'weibl., syrischen Ursprungs; “Freundschaft”; weitere Formen: Habrutha'
        },
        {
            ar2: 'ܠܰܡܦܺܐܕܳܐ',
            es2: 'ܠܡܦܐܕܐ',
            ar1: 'ܠܡܦܐܕܐ',
            dt2: '',
            dt1: 'Lamfido',
            dt3: '',
            es1: 'ܠܡܦܐܕܐ',
            ar3: '',
            es3: '',
            id: '1808',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; anhänglich, Anhang, dem Bunde zugetan, verbunden mit, bezogen auf“. Im AT ist Levi der dritte Sohn Jakobs und der Lea; im NT tragen zwei Ahnherren Jesu diesen Namen'
        },
        {
            dt1: 'Yitar',
            es2: 'ܝܝܬܪ',
            dt3: '',
            ar2: 'ܝܺܝܬܰܪ',
            es1: 'ܝܝܬܪ',
            id: '1138',
            bedeutung: 'männl., “gewinnen, profitieren, nutzen, fruchten, verdienen, zunehmen, vermehren, erhöhen”; weitere Formen: Jitar',
            ar3: '',
            es3: '',
            dt2: 'Jitar',
            ar1: 'ܝܝܬܪ'
        },
        {
            ar3: '',
            es1: 'ܒܪܬܠܒܐ',
            ar1: 'ܒܪ̱ܬܠܒܐ',
            dt1: 'Bathlebo',
            id: '1387',
            dt2: '',
            bedeutung: 'weibl. Form zu Barmotho; “Tochter der Heimat”; weitere Formen: Batmata',
            es3: '',
            es2: 'ܒܪܬܠܒܐ',
            dt3: '',
            ar2: 'ܒܰܪ̱ܬܠܶܒܰܐ'
        },
        {
            dt2: '',
            ar2: 'ܣܳܦܪܳܐ',
            es2: 'ܣܦܪܐ',
            bedeutung: 'weibl., arabischen Ursprungs; “Selige”',
            ar1: 'ܣܦܪܐ',
            es1: 'ܣܦܪܐ',
            es3: '',
            dt3: '',
            id: '2194',
            dt1: 'Sofro',
            ar3: ''
        },
        {
            es3: '',
            id: '1606',
            dt3: '',
            dt2: '',
            es2: 'ܗܕܝܘܬܐ',
            ar3: '',
            bedeutung: 'männl., Hammurabi war ein Semite aus dem Stamm der Amoriter, König der 1. Dynastie von Babylon und gilt als der erste Gesetzgeber der Welt (“Codex Hammurabi”)',
            ar1: 'ܗܕܝܘܬܐ',
            ar2: 'ܗܰܕܝܽܘܬܰܐ',
            dt1: 'Hadoyuta',
            es1: 'ܗܕܝܘܬܐ'
        },
        {
            ar1: 'ܣܥܝܕ',
            bedeutung: '',
            ar3: '',
            es2: 'ܣܥܝܕ',
            es1: 'ܣܥܝܕ',
            dt2: '',
            id: '2082',
            ar2: 'ܣܰܥܺܝܕ',
            dt1: 'Sahid',
            es3: '',
            dt3: ''
        },
        {
            es2: 'ܨܝܦܘܪܐ',
            dt2: 'Zippora',
            ar3: '',
            dt3: 'Sipura',
            id: '2185',
            dt1: 'Sifura',
            bedeutung: 'männl., “Schriftgelehrter, Schreiber, Schriftführer, Notar, Anwalt, Lehrer”',
            ar1: 'ܨܝܦܘܪܐ',
            ar2: 'ܨܺܝܦܽܘܪܰܐ',
            es3: '',
            es1: 'ܨܝܦܘܪܐ'
        },
        {
            es3: '',
            ar3: '',
            es1: 'ܢܦܛܝܪ',
            id: '735',
            ar1: 'ܢܦܛܝܪ',
            dt1: 'Naftir',
            bedeutung: 'männl., “Fackel, Laterne”;',
            ar2: 'ܢܰܦܛܺܝܪ',
            es2: 'ܢܦܛܝܪ',
            dt2: 'Naphtir',
            dt3: ''
        },
        {
            dt2: '',
            es3: '',
            ar3: '',
            ar1: 'ܐܫܝܡܐ',
            es1: 'ܐܫܝܡܐ',
            dt3: '',
            ar2: 'ܐܰܫܺܝܡܰܐ',
            dt1: 'Aschima',
            bedeutung: '',
            es2: 'ܐܫܝܡܐ',
            id: '1289'
        },
        {
            id: '943',
            ar1: 'ܫܪܒܬ',
            dt1: 'Scharbat',
            ar3: '',
            es3: '',
            es2: 'ܫܪܒܬ',
            es1: 'ܫܪܒܬ',
            ar2: 'ܫܰܪܒܰܬ',
            bedeutung: 'männl., “du bist meine Geschichte, du bist mein Märchen, Leben”',
            dt3: '',
            dt2: ''
        },
        {
            dt1: 'Matilu',
            es2: 'ܡܬܐܝܠܘ',
            bedeutung: 'männl., ”Land Gottes“. Matilu war aramäischer König von Beth-Agusi (740 v. Chr.).',
            id: '692',
            es1: 'ܡܬܐܝܠܘ',
            ar2: 'ܡܰܬܐܺܝܠܽܘ',
            ar3: '',
            dt2: '',
            ar1: 'ܡܬܐܝܠܘ',
            dt3: '',
            es3: ''
        },
        {
            dt1: 'Tehro',
            es3: '',
            ar1: 'ܬܗܪܐ',
            es1: 'ܬܗܪܐ',
            dt2: '',
            ar2: 'ܬܶܗܪܳܐ',
            bedeutung: 'männl., “Wunder”',
            dt3: '',
            es2: 'ܬܗܪܐ',
            ar3: '',
            id: '1045'
        },
        {
            ar3: '',
            dt3: '',
            dt1: 'Herumo',
            ar2: 'ܗܶܪܽܘܡܐ',
            bedeutung: 'männl., griechischen Ursprungs; “Gewürz”',
            es1: 'ܗܪܘܡܐ',
            dt2: '',
            id: '470',
            es3: '',
            es2: 'ܗܪܘܡܐ',
            ar1: 'ܗܪܘܡܐ'
        },
        {
            dt3: '',
            es2: 'ܓܡܝܪܢ',
            dt1: 'Gmiron',
            es3: '',
            ar2: 'ܓܡܺܝܪܳܢ',
            ar3: '',
            id: '387',
            es1: 'ܓܡܝܪܢ',
            dt2: '',
            ar1: 'ܓܡܝܪܢ',
            bedeutung: ''
        },
        {
            es1: 'ܪܨܢ',
            dt2: 'Roson',
            ar2: 'ܪܳܨܳܢ',
            es2: 'ܪܨܢ',
            id: '854',
            es3: '',
            dt1: 'Resin',
            ar3: '',
            ar1: 'ܪܨܢ',
            dt3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs. Im AT ist Resin wohl der letzte König der Aramäer von Aram-Damaskus (730 v. Chr.). Weitere Formen: Rezin, Roson'
        },
        {
            id: '275',
            es3: '',
            es2: 'ܕܝܘܣܩܘܪܘܣ',
            es1: 'ܕܝܘܣܩܘܪܘܣ',
            dt1: 'Dioskouros',
            dt2: 'Dioskorus',
            dt3: '',
            ar1: 'ܕܝܘܣܩܘܪܘܣ',
            bedeutung: '',
            ar3: '',
            ar2: 'ܕܺܝܽܘܣܩܳܘܪܳܘܣ'
        },
        {
            es1: 'ܪܨܢ',
            ar2: 'ܪܳܨܳܢ',
            ar3: '',
            dt2: 'Roson',
            es2: 'ܪܨܢ',
            bedeutung: '',
            id: '2046',
            es3: '',
            ar1: 'ܪܨܢ',
            dt1: 'Resin',
            dt3: ''
        },
        {
            dt1: 'Faro',
            ar3: '',
            es2: 'ܦܪܐ',
            dt3: '',
            es1: 'ܦܪܐ',
            id: '1520',
            ar1: 'ܦܪܐ',
            bedeutung: 'männl., “festbinden, an sich binden”',
            dt2: '',
            es3: '',
            ar2: 'ܦܰܪܳܐ'
        },
        {
            dt3: 'Davud',
            id: '264',
            dt1: 'David',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “der Geliebte, Vielgeliebter, Befehlshaber”, Im AT ist König David der Besieger des Goliath und Gründer des jüdischen Staates (ca. 1004-03-965/64 v.Chr.) ',
            es2: 'ܕܘܝܕ',
            es1: 'ܕܘܝܕ',
            ar2: 'ܕܰܘܺܝܕ',
            es3: '',
            dt2: 'Davut',
            ar3: '',
            ar1: 'ܕܘܝܕ'
        },
        {
            es3: '',
            dt2: '',
            bedeutung: 'männl., syrischen Ursprungs; “Rauch, Dampf ”',
            ar1: 'ܚܒܚܝ',
            ar3: '',
            es1: 'ܚܒܚܝ',
            dt1: 'Habhi',
            es2: 'ܚܒܚܝ',
            dt3: '',
            ar2: 'ܚܰܒܚܺܝ',
            id: '1588'
        },
        {
            dt1: 'Adar',
            dt2: '',
            dt3: '',
            es3: '',
            ar2: 'ܥܰܕܰܪ',
            bedeutung: 'männl., “helfen, unterstützen”',
            id: '1212',
            ar1: 'ܥܕܪ',
            ar3: '',
            es1: 'ܥܕܪ',
            es2: 'ܥܕܪ'
        },
        {
            ar3: 'ܡܰܓ݂ܶܕܠܰܐ',
            dt2: '',
            dt3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “mein König ist Gerechtigkeit”. Im AT ist Melkisadek ein Priesterkönig von Salem (Jerusalem?), Vorbild Davids und Bild des Messias als König und Priester. Weitere Formen: Malkisedek, Melchisedek, Malkisadeq, Malkisadiqo, Malkosadiqo',
            ar1: 'ܡܓ݂ܕܠܢܐ',
            dt1: 'Magdalena',
            id: '1832',
            es3: 'ܡܓܕܠܐ',
            ar2: 'ܡܰܓ݂ܕܰܠܰܐ',
            es1: 'ܡܓܕܠܢܐ',
            es2: 'ܡܓܕܠܢܐ'
        },
        {
            dt2: 'Marauwge',
            es3: '',
            ar3: '',
            bedeutung: 'männl., syrischen Ursprungs, „mar“ Heiliger und ”Awge/Awgin“. Der Name eines Heiligen, der so heißt: Mar Awgin (Avgin). Weitere Formen: Maravge, Mar Awgin, Mar Avgin, Mor Awgin, Mor Avgin',
            dt1: 'Marauge',
            id: '661',
            dt3: '',
            es2: 'ܡܪܐܘܓܐ',
            ar1: 'ܡܪܐܘܓܐ',
            es1: 'ܡܪܐܘܓܐ',
            ar2: 'ܡܰܪܰܐܘܓܶܐ'
        },
        {
            es2: 'ܕܟܝ',
            ar3: '',
            id: '1430',
            bedeutung: 'weibl., “Reinheit, Sauberkeit”; weitere Formen: Dakhyuta',
            dt2: '',
            ar2: 'ܕܰܟܺܝ',
            dt3: '',
            ar1: 'ܕܟܝ',
            es1: 'ܕܟܝ',
            dt1: 'Daki',
            es3: ''
        },
        {
            bedeutung: '',
            es3: '',
            id: '2318',
            dt1: 'Yamo',
            ar2: 'ܝܰܡܳܐ',
            es2: 'ܝܡܐ',
            dt3: '',
            es1: 'ܝܡܐ',
            ar1: 'ܝܡܐ',
            dt2: 'Jamo',
            ar3: ''
        },
        {
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Bruder des Vaters”. Im AT ist Ahab König des Nordreiches Israel (871-852 v.Chr.). Weitere Formen: Ohob',
            es2: 'ܐܚܒ',
            dt2: '',
            ar1: 'ܐܚܒ',
            es3: 'ܐܚܒ',
            es1: 'ܐܚܒ',
            ar2: 'ܐܰܚܰܒ',
            ar3: 'ܐܳܚܳܒ',
            dt3: '',
            dt1: 'Ahab',
            id: '50'
        },
        {
            dt2: 'Hanun',
            id: '444',
            dt1: 'Hanoun',
            es2: 'ܚܢܘܢ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Günstling, Jahwe hat Gnade gewirkt”; weitere Formen: Hanuno',
            dt3: 'Hanen',
            ar3: 'ܚܰܢܳܘܢܳܐ',
            es3: 'ܚܢܘܢܐ',
            es1: 'ܚܢܘܢ',
            ar2: 'ܚܰܢܽܘܢ',
            ar1: 'ܚܢܘܢ'
        },
        {
            dt2: '',
            id: '1812',
            ar2: 'ܠܒܺܝܒܽܘܬܰܐ',
            es3: '',
            ar1: 'ܠܒܝܒܘܬܐ',
            es1: 'ܠܒܝܒܘܬܐ',
            dt3: '',
            es2: 'ܠܒܝܒܘܬܐ',
            dt1: 'Lbibuta',
            ar3: '',
            bedeutung: 'weibl., “[sie] hat auf mich gehört”'
        },
        {
            ar2: 'ܦܶܠܶܓ',
            id: '1997',
            dt3: '',
            dt1: 'Peleg',
            bedeutung: 'weibl., “Adlerhorst”. Eine syrisch-aramäische Stadt in Aram Beth-Nahrin (= Mesopotamien)',
            es2: 'ܦܠܓ',
            es1: 'ܦܠܓ',
            dt2: '',
            ar3: '',
            es3: '',
            ar1: 'ܦܠܓ'
        },
        {
            ar1: 'ܝܘܝܟ݂ܝܢ',
            es3: '',
            dt2: '',
            dt3: '',
            ar2: 'ܝܽܘܝܳܟ݂ܺܝܢ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott möge vermehren” oder “Gott möge hinzufügen, Zufüger”. Im AT ist Joseph der elfte Sohn von Jakob und Rahel, geboren in Obermesopotamien. Im NT ist Joseph Verlobter bzw. Ehemann von Maria, der Mutter Jesu, Sohn eines Jakob aus dem Stamm Davids. Joseph von Arimathäa, Mitglied des Hohen Rats, Jünger Jesu, bestattet Jesus in seinem eigenen Grab. Weitere Formen: Josef, Jussuf, Yusuf, Yawsef',
            es2: 'ܝܘܝܟ݂ܝܢ',
            dt1: 'Joachin',
            ar3: '',
            id: '1740',
            es1: 'ܝܘܝܟ݂ܝܢ'
        },
        {
            ar2: 'ܥܰܡܺܝܫܰܕܰܝ',
            ar3: '',
            es2: 'ܥܡܝܫܕܝ',
            dt3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “mein Onkel ist schaddai = allmächtig”',
            dt2: '',
            dt1: 'Ammischaddai',
            ar1: 'ܥܡܝܫܕܝ',
            id: '80',
            es3: '',
            es1: 'ܥܡܝܫܕܝ'
        },
        {
            ar3: 'ܢܺܝܢܘܶܐ',
            es2: 'ܢܝܢܘܐ',
            ar2: 'ܢܺܝܢܘܰܐ',
            bedeutung: 'weibl., Ninve ist eine Stadt in Aram Beth-Nahrin (=Mesopotamien); weitere Formen: Ninova, Ninwe, Ninive',
            ar1: 'ܢܝܢܘܐ',
            es3: 'ܢܝܢܘܐ',
            dt3: 'Ninve',
            dt1: 'Ninva',
            dt2: 'Ninwa',
            id: '770',
            es1: 'ܢܝܢܘܐ'
        },
        {
            ar1: 'ܒܪܠܒܐ',
            es2: 'ܒܪܠܒܐ',
            ar2: 'ܒܰܪܠܶܒܳܐ',
            bedeutung: 'männl., “Sohn, Freund des Herzens”',
            dt2: '',
            es3: '',
            ar3: '',
            es1: 'ܒܪܠܒܐ',
            dt3: '',
            dt1: 'Barlebo',
            id: '172'
        },
        {
            bedeutung: 'männl., “Lamm”',
            es2: 'ܦܒܪܘܢܝܐ',
            ar3: '',
            dt1: 'Fabronya',
            ar1: 'ܦܒܪܘܢܝܐ',
            es3: '',
            dt2: '',
            ar2: 'ܦܰܒܪܽܘܢܝܰܐ',
            es1: 'ܦܒܪܘܢܝܐ',
            dt3: '',
            id: '1513'
        },
        {
            dt2: '',
            es1: 'ܝܪܘܚܡ',
            dt1: 'Jeroham',
            ar3: 'ܝܰܪܚܽܘܡ',
            es3: 'ܝܪܚܘܡ',
            ar1: 'ܝܪܘܚܡ',
            dt3: '',
            id: '1723',
            es2: 'ܝܪܘܚܡ',
            ar2: 'ܝܰܪܽܘܚܰܡ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott sät”; weitere Formen: Isarel'
        },
        {
            es2: 'ܡܟܣܐ',
            es1: 'ܡܟܣܐ',
            bedeutung: 'männl., “Zöllner, Kassierer, Steuereinnehmer”',
            ar1: 'ܡܟܣܐ',
            dt3: '',
            id: '706',
            dt1: 'Mokso',
            ar2: 'ܡܳܟܣܳܐ',
            es3: '',
            dt2: '',
            ar3: ''
        },
        {
            id: '545',
            ar1: 'ܝܣܝܟܐ',
            es2: 'ܝܣܝܟܐ',
            dt1: 'Jessica',
            dt3: '',
            es3: 'ܐܣܟܐ',
            ar3: 'ܐܰܣܶܟܰܐ',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott sieht dich an“; weitere Formen: Aseka, Eseka, Eseca',
            es1: 'ܝܣܝܟܐ',
            dt2: '',
            ar2: 'ܝܰܣܺܝܟܰܐ'
        },
        {
            dt3: '',
            es1: 'ܗܒܐܠܗܐ',
            es2: 'ܗܒܐܠܗܐ',
            id: '400',
            dt2: '',
            bedeutung: 'männl., syrischen Ursprungs; “Gott hat gegeben“, “Gib o Gott”; Weitere Formen: Habaloho',
            ar3: '',
            dt1: 'Habalaha',
            ar1: 'ܗܒܐܠܗܐ',
            es3: '',
            ar2: 'ܗܰܒܰܐܠܰܗܰܐ'
        },
        {
            ar2: 'ܙܺܝܠܰܐ',
            dt2: '',
            es3: '',
            es1: 'ܙܝܠܐ',
            id: '1171',
            dt3: '',
            es2: 'ܙܝܠܐ',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Schatten, Schutz”',
            dt1: 'Zilla',
            ar1: 'ܙܝܠܐ',
            ar3: ''
        },
        {
            es1: 'ܒܪܬܡܝܐ',
            ar1: 'ܒܪ̱ܬܡܝܐ',
            es2: 'ܒܪܬܡܝܐ',
            dt1: 'Bathmayo',
            es3: '',
            ar3: '',
            id: '208',
            dt2: '',
            bedeutung: 'weibl., “Tochter des Wassers”',
            ar2: 'ܒܰܪ̱ܬܡܰܝܳܐ',
            dt3: ''
        },
        {
            ar2: 'ܣܛܶܝܦܰܢܽܘܣ',
            es1: 'ܣܛܝܦܢܘܣ',
            es3: '',
            dt3: '',
            ar3: '',
            ar1: 'ܣܛܝܦܢܘܣ',
            bedeutung: 'männl., aus dem griechischen “stéphanosi Krone, Kranz”. Nach dem NT gilt der Hl. Stephanus als der erste Märtyrer der Christenheit, einer der “Sieben [Diakone]”. Weitere Formen: Stephan, Stefan, Stayfo',
            es2: 'ܣܛܝܦܢܘܣ',
            dt2: '',
            dt1: 'Stephanus',
            id: '1010'
        },
        {
            ar1: 'ܟܫܝܪܬܐ',
            dt3: '',
            dt2: '',
            es3: '',
            ar2: 'ܟܰܫܺܝܪܬܰܐ',
            bedeutung: 'männl., König von Samal-Jadi (um 780 v. Chr.); Weitere Formen: Kilamua, Kalamu',
            es1: 'ܟܫܝܪܬܐ',
            es2: 'ܟܫܝܪܬܐ',
            dt1: 'Kaschirta',
            ar3: '',
            id: '1773'
        },
        {
            ar2: 'ܒܰܒܶܝܠ',
            ar1: 'ܒܒܝܠ',
            dt1: 'Babel',
            ar3: '',
            dt3: '',
            es2: 'ܒܒܝܠ',
            bedeutung: 'weibl., “die sich schämt, schamhafte(s) Frau/Mädchen”',
            id: '1320',
            es1: 'ܒܒܝܠ',
            dt2: '',
            es3: ''
        },
        {
            bedeutung: '',
            ar2: 'ܠܽܘܕܝܰܐ',
            ar1: 'ܠܘܕܝܐ',
            dt3: '',
            es2: 'ܠܘܕܝܐ',
            dt1: 'Lüdya',
            ar3: '',
            id: '636',
            es1: 'ܠܘܕܝܐ',
            dt2: 'Lüdja',
            es3: ''
        },
        {
            ar3: '',
            dt3: '',
            ar2: 'ܪܶܨܳܢ',
            es3: '',
            dt1: 'Reson',
            ar1: 'ܪܨܢ',
            id: '855',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs. Reson ist im AT Sohn des Eljada, Diener des aramäischen Königs Hadad-Eservon Soba, König von Aram-Damaskus (10. Jh. v. Chr.), Gegner Salomos. Weitere Formen: Rezon',
            es1: 'ܪܨܢ',
            dt2: '',
            es2: 'ܪܨܢ'
        },
        {
            ar2: 'ܚܺܝܪܰܢ',
            dt2: '',
            dt3: '',
            id: '1665',
            dt1: 'Hiran',
            es2: 'ܚܝܪܢ',
            ar1: 'ܚܝܪܢ',
            ar3: 'ܚܰܝܪܰܢ',
            bedeutung: 'männl., ”Liebe“, weitere Formen: Huba',
            es1: 'ܚܝܪܢ',
            es3: 'ܚܝܪܢ'
        },
        {
            es1: 'ܙܒܕܝ',
            es2: 'ܙܒܕܝ',
            dt1: 'Zabdai',
            ar3: '',
            dt2: '',
            bedeutung: 'weibl., “Schönheit, Pracht, Großartigkeit, Glühen, Leuchten, Glanz, Exzellenz, Ehre, Tugend”; weitere Formen: Zahyutha',
            dt3: '',
            ar2: 'ܙܰܒܕܰܝ',
            es3: '',
            id: '2336',
            ar1: 'ܙܒܕܝ'
        },
        {
            id: '867',
            es2: 'ܪܚܘܒ',
            bedeutung: 'männl., siehe Rehob',
            dt1: 'Rohub',
            es3: '',
            ar3: '',
            dt2: '',
            ar2: 'ܪܳܚܽܘܒ',
            ar1: 'ܪܚܘܒ',
            dt3: '',
            es1: 'ܪܚܘܒ'
        },
        {
            es3: '',
            dt2: 'Hosea',
            ar3: '',
            id: '485',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “der Herr ist Hilfe und Rettung, Jahwe rettet”. Im AT ist Hoschea ein Prophet (8. Jh. v. Chr.). Weitere Formen: Huscho, Hosea',
            es1: 'ܗܘܫܥ',
            es2: 'ܗܘܫܥ',
            dt3: '',
            dt1: 'Hoschea',
            ar1: 'ܗܘܫܥ',
            ar2: 'ܗܽܘܫܳܥ'
        },
        {
            dt3: '',
            bedeutung: '',
            es3: 'ܙܢܝܐ',
            id: '2196',
            es1: 'ܣܢܝܐ',
            ar1: 'ܣܢܝܐ',
            ar2: 'ܣܢܝܐ',
            dt2: '',
            es2: 'ܣܢܝܐ',
            dt1: 'Sonja',
            ar3: 'ܙܢܝܐ'
        },
        {
            id: '1700',
            dt2: 'Isroel',
            dt3: '',
            ar1: 'ܐܝܣܪܐܝܠ',
            es1: 'ܐܝܣܪܐܝܠ',
            dt1: 'Israel',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “er weiß”',
            ar3: 'ܐܺܝܣܪܰܐܝܶܠ',
            ar2: 'ܐܺܝܣܪܳܐܝܶܠ',
            es3: '',
            es2: 'ܐܝܣܪܐܝܠ'
        },
        {
            dt3: '',
            es3: '',
            bedeutung: 'weibl., syrischen Ursprungs; “die Süße, die Charmante, eine charmante Dame, ein liebliches, süßes, charmantes Mädchen”; weitere Formen: Hlitha',
            ar1: 'ܚܠܝܬܐ',
            es2: 'ܚܠܝܬܐ',
            dt2: '',
            ar2: 'ܚܠܺܝܬܰܐ',
            es1: 'ܚܠܝܬܐ',
            ar3: '',
            dt1: 'Hlita',
            id: '483'
        },
        {
            es2: 'ܫܦܝܐ',
            bedeutung: 'männl., “ruhig, still, gelassen, gefasst, unbesorgt, getrost“, weitere Formen: Schaljo',
            dt3: '',
            dt1: 'Schafyo',
            dt2: '',
            es3: '',
            id: '2116',
            ar1: 'ܫܦܝܐ',
            ar3: '',
            ar2: 'ܫܰܦܝܳܐ',
            es1: 'ܫܦܝܐ'
        },
        {
            dt3: '',
            dt2: '',
            es3: '',
            es2: 'ܢܐܕܝܢ',
            ar1: 'ܢܐܕܝܢ',
            ar3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Tröster, Jahwe hat getröstet”. Im AT ist Nahum ein Prophet, der kurz vor 612 den Untergang Ninives ankündigte. Weitere Formen: Nohum',
            dt1: 'Nadin',
            ar2: 'ܢܰܐܕܺܝܢ',
            es1: 'ܢܐܕܝܢ',
            id: '1923'
        },
        {
            es1: 'ܐܒܝܫܘܥ',
            dt3: '',
            ar1: 'ܐܒܝܫܘܥ',
            bedeutung: 'männl., syrischen Ursprungs; “Jesus ist mein Vater”; weitere Formen: Abischua (ostsyr), Abyeschu, Objeschu',
            ar3: '',
            id: '23',
            ar2: 'ܐܳܒܝܶܫܽܘܥ',
            dt2: '',
            dt1: 'Abjeschu',
            es3: '',
            es2: 'ܐܒܝܫܘܥ'
        },
        {
            es3: '',
            dt3: '',
            ar1: 'ܝܘܐܚ',
            id: '1738',
            es2: 'ܝܘܐܚ',
            dt1: 'Joach',
            es1: 'ܝܘܐܚ',
            bedeutung: 'männl., amerikanische Variante zu Johannes',
            ar3: '',
            dt2: '',
            ar2: 'ܝܽܘܐܳܚ'
        },
        {
            es2: 'ܐܕܡ',
            bedeutung: 'männl., aus der Bibel übernommener Vomame aramäisch-hebräischen Ursprungs; “Mann aus roter Erde”. Im AT ist Adam der Stammvater der Menschheit. Weitere Formen: Odom, Adamo',
            dt2: '',
            ar1: 'ܐܕܡ',
            es3: 'ܐܕܡ',
            dt1: 'Adam',
            ar3: 'ܐܳܕܳܡ',
            id: '34',
            es1: 'ܐܕܡ',
            ar2: 'ܐܰܕܰܡ',
            dt3: ''
        },
        {
            ar1: 'ܢܘܪܫܝܢܐ',
            dt2: '',
            id: '787',
            es1: 'ܢܘܪܫܝܢܐ',
            es3: '',
            es2: 'ܢܘܪܫܝܢܐ',
            ar2: 'ܢܽܘܪܫܰܝܢܳܐ',
            dt3: '',
            dt1: 'Nurschayno',
            bedeutung: 'männl., “Licht des Friedens”',
            ar3: ''
        },
        {
            dt1: 'Sbartha',
            id: '916',
            ar1: 'ܣܒܪܬܐ',
            es1: 'ܣܒܪܬܐ',
            ar2: 'ܣܒܰܪܬܰܐ',
            es2: 'ܣܒܪܬܐ',
            bedeutung: 'weibl., “Botschat, Verkündigung”; weitere Formen: Sbarta',
            dt2: '',
            ar3: '',
            dt3: '',
            es3: ''
        },
        {
            ar2: 'ܕܺܝܰܠܰܐ',
            dt2: '',
            es1: 'ܕܝܠܐ',
            id: '1460',
            es2: 'ܕܝܠܐ',
            es3: 'ܕܝܐܠܐ',
            bedeutung: 'männl., ”Honig“; weitere Formen: Dawscho, Dauscho',
            dt1: 'Diyala',
            ar1: 'ܕܝܠܐ',
            dt3: '',
            ar3: 'ܕܺܝܰܐܠܰܐ'
        },
        {
            ar2: 'ܚܰܢܳܢܳܐ',
            es2: 'ܚܢܢܐ',
            dt3: '',
            bedeutung: 'männl., syrischen Ursprungs; “Gnädiger, Barmherziger”',
            dt2: '',
            id: '443',
            ar3: '',
            ar1: 'ܚܢܢܐ',
            es3: '',
            es1: 'ܚܢܢܐ',
            dt1: 'Hanono'
        },
        {
            bedeutung: 'weibl., Domita war eine Stadt in Alt-Syrien',
            dt2: '',
            es2: 'ܕܡܝܬܐ',
            dt3: '',
            dt1: 'Domita',
            ar1: 'ܕܡܝܬܐ',
            es3: '',
            es1: 'ܕܡܝܬܐ',
            ar2: 'ܕܳܡܺܝܬܰܐ',
            id: '279',
            ar3: ''
        },
        {
            dt1: 'Duma',
            es2: 'ܕܘܡܐ',
            id: '286',
            es1: 'ܕܘܡܐ',
            es3: '',
            bedeutung: 'männl., “Talent, Gabe” auch “Dach” ',
            dt2: '',
            ar3: '',
            ar1: 'ܕܘܡܐ',
            ar2: 'ܕܽܘܡܰܐ',
            dt3: ''
        },
        {
            es2: 'ܪܘܝܘܬܐ',
            id: '2036',
            dt1: 'Rauyuta',
            ar3: '',
            es1: 'ܪܘܝܘܬܐ',
            dt3: '',
            es3: '',
            ar1: 'ܪܘܝܘܬܐ',
            dt2: '',
            bedeutung: 'männl., “Flamme”. Reschef war der Gott des Blitzes (“Blitzgott”) in der aramäischen Mythologie',
            ar2: 'ܪܰܘܝܽܘܬܰܐ'
        },
        {
            es1: 'ܐܘܪܣܒܪܐ',
            ar3: '',
            dt3: '',
            id: '1097',
            dt1: 'Ursabro',
            ar2: 'ܐܽܘܪܣܰܒܪܳܐ',
            bedeutung: 'weibl., “Land der Hoffnung”',
            es3: '',
            dt2: '',
            ar1: 'ܐܘܪܣܒܪܐ',
            es2: 'ܐܘܪܣܒܪܐ'
        },
        {
            es1: 'ܦܘܠܓܣܐ',
            id: '1102',
            ar2: 'ܦܳܘܠܓܶܣܰܐ',
            dt1: 'Volgesa',
            es2: 'ܦܘܠܓܣܐ',
            dt2: '',
            es3: '',
            ar3: '',
            dt3: '',
            ar1: 'ܦܘܠܓܣܐ',
            bedeutung: 'männl., König von Hatra (155-165 n.Chr.); weitere Formen: Volgoses, Walagasch'
        },
        {
            es3: '',
            bedeutung: '',
            dt1: 'Habalaha',
            es2: 'ܗܒܐܠܗܐ',
            ar3: '',
            es1: 'ܗܒܐܠܗܐ',
            dt2: '',
            ar2: 'ܗܰܒܰܐܠܰܗܰܐ',
            id: '1587',
            ar1: 'ܗܒܐܠܗܐ',
            dt3: ''
        },
        {
            ar1: 'ܗܘܫܥ',
            bedeutung: 'männl., siehe Hosea',
            dt2: 'Hosea',
            ar2: 'ܗܽܘܫܳܥ',
            es2: 'ܗܘܫܥ',
            dt3: '',
            es3: '',
            ar3: '',
            es1: 'ܗܘܫܥ',
            id: '1672',
            dt1: 'Hoschea'
        },
        {
            ar3: '',
            ar2: 'ܟܽܘܟܰܠܬܰܐ',
            dt1: 'Kukaltha',
            es1: 'ܟܘܟܠܬܐ',
            es2: 'ܟܘܟܠܬܐ',
            id: '1795',
            dt3: '',
            es3: '',
            bedeutung: '',
            dt2: '',
            ar1: 'ܟܘܟܠܬܐ'
        },
        {
            ar2: 'ܚܰܒܽܘܪܳܐ',
            dt1: 'Haburo',
            id: '1596',
            es2: 'ܚܒܘܪܐ',
            dt3: '',
            es1: 'ܚܒܘܪܐ',
            ar3: '',
            dt2: '',
            bedeutung: '',
            ar1: 'ܚܒܘܪܐ',
            es3: ''
        },
        {
            ar1: 'ܝܘܢܢ',
            ar2: 'ܝܳܘܢܳܢ',
            es3: '',
            bedeutung: 'weibl., “Glanz, Pracht, Leuchte, Glorie, Ruhm, Ehre, Hoheit, Tugend”, “die Reine“, weitere Formen: Zhita, Sahita',
            dt1: 'Yonan',
            es2: 'ܝܘܢܢ',
            id: '2331',
            es1: 'ܝܘܢܢ',
            dt2: 'Jonan',
            ar3: '',
            dt3: ''
        },
        {
            ar3: '',
            dt1: 'Swata',
            bedeutung: 'weibl., “Dampf, Dunst, Geruch, Geschmack”; weitere Formen: Swatha',
            dt2: '',
            es1: 'ܣܘܬܐ',
            es2: 'ܣܘܬܐ',
            dt3: '',
            ar2: 'ܣܘܰܬܰܐ',
            ar1: 'ܣܘܬܐ',
            es3: '',
            id: '1022'
        },
        {
            ar1: 'ܦܪܝܕ',
            dt2: '',
            es1: 'ܦܪܝܕ',
            dt3: '',
            es2: 'ܦܪܝܕ',
            ar2: 'ܦܰܪܺܝܕ',
            es3: '',
            id: '1518',
            bedeutung: 'weibl., vielleicht: “Frau, die fruchtbar ist”',
            dt1: 'Farid',
            ar3: ''
        },
        {
            ar2: 'ܐܶܕܢܰܐ',
            id: '292',
            ar1: 'ܐܕܢܐ',
            es3: '',
            dt3: '',
            dt1: 'Edna',
            es2: 'ܐܕܢܐ',
            es1: 'ܐܕܢܐ',
            ar3: '',
            bedeutung: 'weibl., “Ohr”',
            dt2: 'Ednha'
        },
        {
            dt3: '',
            id: '2253',
            es3: '',
            bedeutung: 'männl., “Krone”',
            ar1: 'ܬܝܪܙܐ',
            dt2: '',
            es1: 'ܬܝܪܙܐ',
            es2: 'ܬܝܪܙܐ',
            ar3: '',
            dt1: 'Tirza',
            ar2: 'ܬܺܝܪܙܰܐ'
        },
        {
            es1: 'ܡܪܩܘܣ',
            ar1: 'ܡܪܩܘܣ',
            dt1: 'Markus',
            ar2: 'ܡܰܪܩܽܘܣ',
            bedeutung: 'männl., aus dem Lateinischen übernommener Vorname; “marinus zum Meer gehörend; Mars: Name des Kriegsgottes” oder “Hammer”. Im NT ist Markus Beiname eines Judenchristen Johannes, Vetter des Barnabas, Mitarbeiter und Gefährte des Paulus. Markus ist der Verfasser des zweiten und ältesten Evangeliums. Weitere Formen: Marqus, Marqos',
            dt2: '',
            dt3: '',
            ar3: 'ܡܰܪܩܳܘܣ',
            es3: 'ܡܪܩܘܣ',
            es2: 'ܡܪܩܘܣ',
            id: '673'
        },
        {
            ar3: '',
            dt2: '',
            es3: '',
            bedeutung: 'männl., Bakos und Sarkis sind zwei Heilige (279-336), die in der syrisch-orthodoxen Kirche gemeinsam verehıt werden; Festtag: 7. Oktober. Weitere Formen: Bakus',
            ar1: 'ܒܗܪܐ',
            dt1: 'Bahro',
            es2: 'ܒܗܪܐ',
            es1: 'ܒܗܪܐ',
            id: '1324',
            dt3: '',
            ar2: 'ܒܰܗܪܳܐ'
        },
        {
            ar1: 'ܨܠܝܒܐ',
            bedeutung: 'männl., “Kreuz”',
            dt1: 'Saliba',
            ar2: 'ܨܰܠܺܝܒܰܐ',
            dt3: '',
            id: '894',
            ar3: '',
            dt2: '',
            es3: '',
            es1: 'ܨܠܝܒܐ',
            es2: 'ܨܠܝܒܐ'
        },
        {
            es1: 'ܓܐܝܐ',
            id: '1554',
            ar2: 'ܓܰܐܝܳܐ',
            dt2: '',
            dt3: '',
            es2: 'ܓܐܝܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe hat zum Ziel geführt/ erfüllt”',
            ar3: '',
            es3: '',
            ar1: 'ܓܐܝܐ',
            dt1: 'Gayo'
        },
        {
            ar3: 'ܒܶܐܪܰܝܰܐ',
            es2: 'ܒܪܝܐ',
            dt3: '',
            ar2: 'ܒܶܪܰܝܰܐ',
            id: '221',
            es3: 'ܒܐܪܝܐ',
            es1: 'ܒܪܝܐ',
            dt2: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe hat geschaffen”',
            dt1: 'Beraja',
            ar1: 'ܒܪܝܐ'
        },
        {
            es3: '',
            bedeutung: 'männl., siehe Yaqunto',
            es1: 'ܝܘܩܢܬܐ',
            es2: 'ܝܘܩܢܬܐ',
            ar2: 'ܝܽܘܩܰܢܬܳܐ',
            ar3: '',
            dt3: '',
            id: '1142',
            ar1: 'ܝܘܩܢܬܐ',
            dt1: 'Yuqanto',
            dt2: ''
        },
        {
            bedeutung: 'weibl., “Bitte, Bestellung, Wunsch, Ersuchen, Gesuch, Konsultation, Beratung”; weitere Formen: Scheletha',
            dt1: 'Schelata',
            ar3: '',
            dt3: '',
            id: '952',
            ar2: 'ܫܶܐܠܶܬܰܐ',
            es1: 'ܫܐܠܬܐ',
            es2: 'ܫܐܠܬܐ',
            ar1: 'ܫܐܠܬܐ',
            es3: '',
            dt2: ''
        },
        {
            es1: 'ܢܪܓܝܠ',
            es2: 'ܢܪܓܝܠ',
            dt1: 'Nargil',
            ar3: '',
            dt3: '',
            es3: '',
            ar2: 'ܢܰܪܓܺܝܠ',
            ar1: 'ܢܪܓܝܠ',
            dt2: 'Nargilo',
            id: '748',
            bedeutung: 'männl., “Kokosnuss”; weitere Formen: Nargilo'
        },
        {
            dt1: 'Tabrimmon',
            es3: 'ܛܒܪܐܡܘܢ',
            ar1: 'ܛܒܪܝܡܘܢ',
            dt2: '',
            ar2: 'ܛܰܒܪܺܝܡܳܘܢ',
            id: '1026',
            ar3: 'ܛܒܰܪܰܐܡܽܘܢ',
            bedeutung: 'männl., “[Der Gott] Rimmon ist gut”. Tabrimmon war ein König von Aram-Damaskus (10. Jh.v.Chr.), Sohn des Königs Hezjon, Vater des Bar-Hadad I. Weitere Formen: Tbaramun',
            dt3: '',
            es2: 'ܛܒܪܝܡܘܢ',
            es1: 'ܛܒܪܝܡܘܢ'
        },
        {
            ar3: 'ܒܶܠܛܫܰܨܰܪ',
            es3: 'ܒܠܛܫܨܪ',
            dt1: 'Balthasar',
            dt2: '',
            dt3: '',
            es1: 'ܒܠܬܨܪ',
            ar2: 'ܒܰܠܬܰܨܰܪ',
            es2: 'ܒܠܬܨܪ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott schütze sein Leben!” Einer der Hl. Drei Könige wird in der Tradition Balthasar genannt. Im AT wird dem Propheten Daniel der Name Balthasar oder Beltschazzar am babylonischen Hof beigegeben',
            id: '149',
            ar1: 'ܒܠܬܨܪ'
        },
        {
            dt3: '',
            es1: 'ܩܘܪܠܘܢܐ',
            bedeutung: 'männl., Schüler des Hl. Ephräm',
            dt1: 'Qurlona',
            es3: '',
            id: '819',
            ar1: 'ܩܘܪܠܘܢܐ',
            ar2: 'ܩܽܘܪܠܽܘܢܰܐ',
            dt2: '',
            es2: 'ܩܘܪܠܘܢܐ',
            ar3: ''
        },
        {
            dt1: 'Barsaba',
            es1: 'ܒܪܣܒܐ',
            id: '182',
            es3: 'ܒܪܫܒܬܐ',
            dt2: '',
            dt3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäischen Ursprungs; “Sohn des Sabbats” oder “Sohn des Erhabenen”; weitere Formen: Barsabbas, Barschabo, Barschabtha',
            ar3: 'ܒܰܪܫܰܒܬܳܐ',
            es2: 'ܒܪܣܒܐ',
            ar2: 'ܒܰܪܫܰܒܐ',
            ar1: 'ܒܪܣܒܐ'
        },
        {
            ar1: 'ܙܟܝ',
            ar3: '',
            es2: 'ܙܟܝ',
            dt2: '',
            id: '1158',
            ar2: 'ܙܰܟܺܝ',
            dt3: '',
            es3: '',
            bedeutung: '',
            dt1: 'Zaki',
            es1: 'ܙܟܝ'
        },
        {
            id: '418',
            dt1: 'Hadoram',
            es3: 'ܗܕܘܪܡ',
            es2: 'ܗܕܘܪܡ',
            ar2: 'ܗܰܕܳܘܪܰܡ',
            bedeutung: 'männl., “Hadad ist erhaben”. Er war Sohn des Tol, des Königs von Hamat. Weitere Formen: Hadurom',
            es1: 'ܗܕܘܪܡ',
            dt3: '',
            ar3: 'ܗܰܕܽܘܪܰܡ',
            dt2: '',
            ar1: 'ܗܕܘܪܡ'
        },
        {
            ar3: '',
            es1: 'ܐܪܣ',
            dt1: 'Aras',
            id: '94',
            dt2: '',
            ar1: 'ܐܪܣ',
            bedeutung: '',
            dt3: '',
            ar2: 'ܐܰܪܰܣ',
            es2: 'ܐܪܣ',
            es3: ''
        },
        {
            dt1: 'Kadmiel',
            es2: 'ܩܕܡܝܐܝܠ',
            dt3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott ist vor mir”; Weitere Formen: Qadmiel',
            ar2: 'ܩܰܕܡܺܝܐܝܶܠ',
            ar1: 'ܩܕܡܝܐܝܠ',
            es3: 'ܩܕܡܐܝܠ',
            dt2: '',
            id: '569',
            ar3: 'ܩܰܕܡܺܐܝܶܠ',
            es1: 'ܩܕܡܝܐܝܠ'
        },
        {
            id: '404',
            es1: 'ܗܒܐܝܠ',
            es3: '',
            dt3: '',
            ar1: 'ܗܒܐܝܠ',
            es2: 'ܗܒܐܝܠ',
            dt1: 'Habil',
            ar3: '',
            bedeutung: 'männl., “Gib o Gott”',
            ar2: 'ܗܰܒܐܝܺܠ',
            dt2: ''
        },
        {
            ar3: '',
            dt1: 'Seron',
            dt3: '',
            id: '2180',
            es2: 'ܣܪܘܢ',
            ar1: 'ܣܪܘܢ',
            dt2: '',
            bedeutung: 'männl., “Becken, Beckenteller, Kastagnette”',
            ar2: 'ܣܶܪܳܘܢ',
            es1: 'ܣܪܘܢ',
            es3: ''
        },
        {
            bedeutung: 'weibl., Gidarja war eine Stadt in Alt-Syrien; weitere Formen: Gidarya, Gidara',
            dt3: '',
            ar3: '',
            es3: '',
            dt2: '',
            es1: 'ܓܠܝܢܐ',
            dt1: 'Gelyono',
            es2: 'ܓܠܝܢܐ',
            id: '1561',
            ar1: 'ܓܠܝܢܐ',
            ar2: 'ܓܶܠܝܳܢܳܐ'
        },
        {
            dt2: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott hat Gnade gewirkt”; weitere Formen: Hananayel',
            es1: 'ܚܢܢܐܝܠ',
            dt3: '',
            es3: '',
            es2: 'ܚܢܢܐܝܠ',
            dt1: 'Hananel',
            ar1: 'ܚܢܢܐܝܠ',
            id: '433',
            ar3: '',
            ar2: 'ܚܰܢܰܢܐܝܶܠ'
        },
        {
            dt2: '',
            es1: 'ܫܠܡ',
            dt3: '',
            dt1: 'Schalem',
            bedeutung: 'männl., “beenden”',
            ar1: 'ܫܠܡ',
            ar2: 'ܫܰܠܶܡ',
            es2: 'ܫܠܡ',
            ar3: '',
            es3: '',
            id: '929'
        },
        {
            dt2: '',
            es2: 'ܒܪܝܐ',
            es1: 'ܒܪܝܐ',
            es3: 'ܒܐܪܝܐ',
            ar1: 'ܒܪܝܐ',
            ar2: 'ܒܶܪܰܝܰܐ',
            id: '1401',
            bedeutung: 'weibl., “Perle, Edelstein”',
            dt1: 'Beraja',
            ar3: 'ܒܶܐܪܰܝܰܐ',
            dt3: ''
        },
        {
            es1: 'ܪܡܘܬܐ',
            ar1: 'ܪܡܘܬܐ',
            dt3: '',
            id: '843',
            es2: 'ܪܡܘܬܐ',
            ar2: 'ܪܰܡܽܘܬܰܐ',
            es3: '',
            bedeutung: 'weibl., “Höhe, Hoheit, Hochmut, Stolz”; weitere Formen: Ramutha',
            ar3: '',
            dt2: '',
            dt1: 'Ramuta'
        },
        {
            es1: 'ܪܓܬܐ',
            ar3: '',
            bedeutung: 'weibl., “Wunsch, Sehnsucht, Begehren, Verlangen, Appetit, Bedürfnis, Lust”; weitere Formen: Regtha',
            es3: '',
            dt3: '',
            dt2: '',
            dt1: 'Regta',
            id: '848',
            ar1: 'ܪܓܬܐ',
            ar2: 'ܪܶܓܬܰܐ',
            es2: 'ܪܓܬܐ'
        },
        {
            ar2: 'ܢܰܪܓܝܣ',
            bedeutung: 'weibl., Ninve ist eine Stadt in Aram Beth-Nahrin (=Mesopotamien); weitere Formen: Ninova, Ninwe, Ninive',
            dt2: 'Nergis',
            dt1: 'Nergiz',
            es1: 'ܢܪܓܝܣ',
            es3: '',
            ar1: 'ܢܪܓܝܣ',
            id: '1953',
            dt3: '',
            es2: 'ܢܪܓܝܣ',
            ar3: ''
        },
        {
            es2: 'ܒܗܪܐ',
            es1: 'ܒܗܪܐ',
            ar2: 'ܒܰܗܪܳܐ',
            es3: '',
            ar3: '',
            bedeutung: 'männl., syıischen Ursprungs; “Licht, Leuchte”',
            dt1: 'Bahro',
            dt2: '',
            dt3: '',
            id: '144',
            ar1: 'ܒܗܪܐ'
        },
        {
            ar2: 'ܐܝܫܠܪ',
            dt3: '',
            dt2: '',
            es1: 'ܐܝܫܠܪ',
            es3: '',
            dt1: 'Isler',
            id: '1699',
            bedeutung: 'weibl., Jabrudia war eine Stadt in Alt-Syrien',
            ar3: '',
            es2: 'ܐܝܫܠܪ',
            ar1: 'ܐܝܫܠܪ'
        },
        {
            dt1: 'Augustos',
            ar1: 'ܐܓܘܣܛܘܣ',
            dt2: '',
            ar2: 'ܐܰܘܓܳܘܣܛܳܘܣ',
            es3: '',
            bedeutung: '',
            es2: 'ܐܓܘܣܛܘܣ',
            dt3: '',
            id: '129',
            es1: 'ܐܓܘܣܛܘܣ',
            ar3: ''
        },
        {
            es2: 'ܡܝܠܕ',
            bedeutung: '',
            ar1: 'ܡܝܠܕ',
            dt2: '',
            dt3: '',
            id: '1891',
            ar3: '',
            es1: 'ܡܝܠܕ',
            ar2: 'ܡܺܝܠܰܕ',
            dt1: 'Milad',
            es3: ''
        },
        {
            id: '682',
            dt2: '',
            es1: 'ܡܪܝܐܠܗܐ',
            dt1: 'Maryalaha',
            ar2: 'ܡܰܪܝܰܐܠܰܗܰܐ',
            es2: 'ܡܪܝܐܠܗܐ',
            bedeutung: 'männl., “Herr Gott”',
            es3: '',
            ar1: 'ܡܪܝܐܠܗܐ',
            dt3: '',
            ar3: ''
        },
        {
            ar2: 'ܬܽܘܟ݂ܠܳܢ',
            ar1: 'ܬܘܟ݂ܠܢ',
            ar3: '',
            es3: '',
            dt1: 'Tuchlon',
            bedeutung: 'weibl., “unsere Nation, unsere Heimat”',
            dt3: '',
            id: '2269',
            es2: 'ܬܘܟ݂ܠܢ',
            dt2: '',
            es1: 'ܬܘܟ݂ܠܢ'
        },
        {
            bedeutung: 'männl., “meine Hoffnung”',
            id: '2063',
            dt3: '',
            es3: '',
            ar1: 'ܪܡܡܬܐ',
            ar2: 'ܪܳܡܡܰܬܰܐ',
            dt2: '',
            es2: 'ܪܡܡܬܐ',
            ar3: '',
            es1: 'ܪܡܡܬܐ',
            dt1: 'Rommata'
        },
        {
            dt3: '',
            ar2: 'ܐܶܠܺܝܗܰܒ',
            ar3: '',
            es3: '',
            es1: 'ܐܠܝܗܒ',
            ar1: 'ܐܠܝܗܒ',
            dt1: 'Eliab',
            id: '1481',
            dt2: 'Elihab',
            es2: 'ܐܠܝܗܒ',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gottesverehrerin, mein Gott ist Fü11e, Vollkommenheit”. Im AT ist Elischbah u.a. Frau Aarons; im NT ist Elischbah Frau des Priesters Zacharias und Mutter von Johannes dem Täufer. Weitere Formen: Elisabeth, Elisabet'
        },
        {
            dt1: 'Mor Julius Yeshu Cicek',
            dt2: '',
            ar2: 'ܡܪܝ ܝܘܠܝܘܣ ܝܫܘܥ ܓܝܓܟ',
            ar3: '',
            dt3: '',
            id: '712',
            es1: 'ܡܪܝ ܝܘܠܝܘܣ ܝܫܘܥ ܓܝܓܟ',
            bedeutung: '',
            es3: '',
            ar1: 'ܡܪܝ ܝܘܠܝܘܣ ܝܫܘܥ ܓܝܓܟ',
            es2: 'ܡܪܝ ܝܘܠܝܘܣ ܝܫܘܥ ܓܝܓܟ'
        },
        {
            dt1: 'Tuma',
            es2: 'ܬܘܡܐ',
            bedeutung: '',
            ar1: 'ܬܘܡܐ',
            es3: '',
            es1: 'ܬܘܡܐ',
            dt3: '',
            ar3: '',
            id: '1079',
            ar2: 'ܬܽܘܡܰܐ',
            dt2: ''
        },
        {
            ar3: '',
            dt1: 'Barnahro',
            dt3: '',
            es1: 'ܒܪܢܗܪܐ',
            es3: '',
            ar1: 'ܒܪܢܗܪܐ',
            es2: 'ܒܪܢܗܪܐ',
            ar2: 'ܒܰܪܢܰܗܪܰܐ',
            id: '1355',
            dt2: '',
            bedeutung: 'männl., “Sohn der Leoparden, der Tiger”. Barnemre war ein Hauptgott der Aramäer von Haran. Weitere Formen: Barnemro'
        },
        {
            dt3: '',
            dt2: '',
            ar2: 'ܣܘܰܬܰܐ',
            es3: '',
            dt1: 'Swata',
            id: '2214',
            ar1: 'ܣܘܬܐ',
            bedeutung: 'weibl., “Mädchen”',
            ar3: '',
            es1: 'ܣܘܬܐ',
            es2: 'ܣܘܬܐ'
        },
        {
            id: '1134',
            ar2: 'ܝܰܐܝܽܘܬܰܐ',
            es3: '',
            ar3: '',
            bedeutung: 'weibl., “Schönheit, Anmut, Grazie, Würde, Wohlgestalt, Schicklichkeit”; weitere Formen: Yoyuta, Yo-yutha, Yayutha',
            dt1: 'Yayuta',
            es1: 'ܝܐܝܘܬܐ',
            dt2: '',
            dt3: '',
            es2: 'ܝܐܝܘܬܐ',
            ar1: 'ܝܐܝܘܬܐ'
        },
        {
            id: '2197',
            dt1: 'Sophia',
            dt2: 'Sofia',
            es1: 'ܣܘܦܝܐ',
            ar2: 'ܣܳܘܦܺܝܰܐ',
            ar1: 'ܣܘܦܝܐ',
            dt3: '',
            es2: 'ܣܘܦܝܐ',
            es3: '',
            ar3: '',
            bedeutung: 'männl., “Himmelfahrt, Aufstieg”'
        },
        {
            id: '744',
            es3: '',
            bedeutung: 'weibl., syrischen Ursprungs; “Bescheidenheit, Keuschheit, Reinheit, Diskretion, Ehre, Enthaltsamkeit, Schamhaftigkeit”; weitere Formen: Nakfutha',
            dt3: '',
            dt1: 'Nakfuta',
            es2: 'ܢܟܦܘܬܐ',
            ar2: 'ܢܰܟܦܽܘܬܰܐ',
            es1: 'ܢܟܦܘܬܐ',
            ar1: 'ܢܟܦܘܬܐ',
            dt2: '',
            ar3: ''
        },
        {
            es2: 'ܙܝܬܐ',
            id: '1163',
            dt3: '',
            es3: '',
            ar2: 'ܙܰܝܬܳܐ',
            bedeutung: 'männl., “0live, Olivenbaum, Ölbaum”',
            dt2: '',
            es1: 'ܙܝܬܐ',
            ar3: '',
            dt1: 'Zayto',
            ar1: 'ܙܝܬܐ'
        },
        {
            es1: 'ܐܦܪܝ',
            bedeutung: 'männl., “wurde fruchtbar”',
            dt3: '',
            es2: 'ܐܦܪܝ',
            ar1: 'ܐܦܪܝ',
            ar3: '',
            ar2: 'ܐܰܦܪܺܝ',
            es3: '',
            id: '1220',
            dt2: '',
            dt1: 'Afri'
        },
        {
            dt1: 'Ariel',
            es2: 'ܐܪܝܐܝܠ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Löwe Gottes” oder “Herd Gottes”',
            ar3: '',
            es1: 'ܐܪܝܐܝܠ',
            id: '99',
            dt3: '',
            ar1: 'ܐܪܝܐܝܠ',
            ar2: 'ܐܰܪܺܝܐܝܶܠ',
            dt2: '',
            es3: ''
        },
        {
            dt3: '',
            es3: '',
            es2: 'ܒܬܘܐܝܠ',
            es1: 'ܒܬܘܐܝܠ',
            dt2: '',
            ar2: 'ܒܶܬܽܘܐܝܺܠ',
            bedeutung: 'weibl., “Schluck, Trunk”, aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs. Im AT ist Bilha Magd des aramäischen Laban und seiner Tochter Rahel, Nebenfrau Jakobs. Weitere Formen: Bilhan',
            ar1: 'ܒܬܘܐܝܠ',
            dt1: 'Betuel',
            ar3: '',
            id: '1408'
        },
        {
            ar1: 'ܩܡܘܐܝܠ',
            es3: 'ܩܡܘܐܝܠ',
            bedeutung: 'weibl. Form zu Kino',
            dt1: 'Kemuel',
            ar2: 'ܩܰܡܽܘܐܝܶܠ',
            dt2: '',
            ar3: 'ܩܡܽܘܐܝܶܠ',
            dt3: '',
            es2: 'ܩܡܘܐܝܠ',
            id: '1778',
            es1: 'ܩܡܘܐܝܠ'
        },
        {
            bedeutung: 'männl., “Erwählter, Auserwählter, Guter”',
            dt3: '',
            es3: '',
            es1: 'ܓܒܝܐ',
            es2: 'ܓܒܝܐ',
            dt2: '',
            ar2: 'ܓܰܒܝܳܐ',
            dt1: 'Gabyo',
            id: '358',
            ar3: '',
            ar1: 'ܓܒܝܐ'
        },
        {
            id: '1716',
            es2: 'ܝܓܪܣܗܕܘܬܐ',
            ar3: '',
            ar2: 'ܝܰܓܰܪܣܰܗܰܕܽܘܬܰܐ',
            es3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott ist Heil, Gott ist Rettung”. Im AT ist Jesaja Sohn des Amoz, Prophet in Juda (765-701 v. Chr.). Weitere Formen: Jeschaja, Eschaja, Eschajo, Eschahjo',
            es1: 'ܝܓܪܣܗܕܘܬܐ',
            dt3: '',
            dt1: 'Jegar Sahaduta',
            ar1: 'ܝܓܪܣܗܕܘܬܐ',
            dt2: ''
        },
        {
            ar2: 'ܐܽܘܡܬ݂ܰܐ',
            dt2: '',
            dt1: 'Umtha',
            id: '2277',
            es1: 'ܐܘܡܬ݂ܐ',
            es3: '',
            ar1: 'ܐܘܡܬ݂ܐ',
            dt3: '',
            es2: 'ܐܘܡܬ݂ܐ',
            ar3: '',
            bedeutung: 'weibl., “Land oder Stadt der Nation, der Heimat”; weitere Formen: Urmatha'
        },
        {
            bedeutung: 'männl., im NT ist Timai oder Timäus ein Blinder, den Jesus geheilt hat.',
            dt1: 'Timai',
            dt3: '',
            es2: 'ܛܝܡܝ',
            ar2: 'ܛܺܝܡܰܝ',
            ar1: 'ܛܝܡܝ',
            id: '1058',
            dt2: '',
            ar3: '',
            es3: '',
            es1: 'ܛܝܡܝ'
        },
        {
            dt2: '',
            ar3: '',
            ar1: 'ܐܒܝܫܘܥ',
            es2: 'ܐܒܝܫܘܥ',
            id: '1200',
            es3: '',
            bedeutung: 'männl., syrischen Ursprungs; “Jesus ist mein Vater”; weitere Formen: Abischua (ostsyr), Abyeschu, Objeschu',
            ar2: 'ܐܳܒܝܶܫܽܘܥ',
            dt1: 'Abjeschu',
            dt3: '',
            es1: 'ܐܒܝܫܘܥ'
        },
        {
            dt1: 'Ishaak',
            dt2: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “leiten, führen”',
            es3: 'ܐܝܣܚܩ',
            es2: 'ܐܝܣܚܩ',
            id: '1697',
            dt3: '',
            ar2: 'ܐܺܝܣܚܰܩ',
            ar1: 'ܐܝܣܚܩ',
            ar3: 'ܐܺܝܣܚܳܩ',
            es1: 'ܐܝܣܚܩ'
        },
        {
            dt2: '',
            dt1: 'Elnatan',
            es2: 'ܐܠܢܬܢ',
            bedeutung: 'männl., “Jesus hat mir geantwortet”; weitere Formen: Hnonjeschu',
            ar1: 'ܐܠܢܬܢ',
            es3: '',
            ar2: 'ܐܶܠܢܰܬܰܢ',
            dt3: '',
            ar3: '',
            es1: 'ܐܠܢܬܢ',
            id: '1493'
        },
        {
            ar2: 'ܐܰܣܰܦ',
            es2: 'ܐܣܦ',
            id: '106',
            dt1: 'Asaf',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott mehrt oder sammelt”',
            es3: '',
            ar1: 'ܐܣܦ',
            dt3: '',
            dt2: 'Asaph',
            es1: 'ܐܣܦ',
            ar3: ''
        },
        {
            es2: 'ܝܫܘܥ',
            dt2: 'Joschua',
            ar1: 'ܝܫܘܥ',
            es3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Geschenk Jahwes”. Im NT ist Zabdai Vater des Jakobus und des Johannes, der Jünger Jesu. Weitere Formen: Zebedäus',
            ar2: 'ܝܶܫܽܘܥ',
            dt1: 'Yeschua',
            ar3: '',
            dt3: 'Jeschua',
            id: '2327',
            es1: 'ܝܫܘܥ'
        },
        {
            dt1: 'Marduk',
            dt2: '',
            id: '663',
            dt3: '',
            ar3: '',
            es2: 'ܡܪܕܘܟ',
            ar2: 'ܡܪܕܘܟ',
            es1: 'ܡܪܕܘܟ',
            ar1: 'ܡܪܕܘܟ',
            es3: '',
            bedeutung: ''
        },
        {
            es2: 'ܢܨܝܚ',
            dt1: 'Nasih',
            id: '751',
            es1: 'ܢܨܝܚ',
            dt3: '',
            dt2: 'Nasiho',
            bedeutung: 'männl., “siegreich, ausgezeıchnet”; weitere Formen: Nasiho',
            ar1: 'ܢܨܝܚ',
            ar3: '',
            ar2: 'ܢܰܨܝܺܚ',
            es3: ''
        },
        {
            dt2: '',
            es3: '',
            ar2: 'ܣܰܥܕܳܐ',
            dt3: '',
            dt1: 'Saado',
            es1: 'ܣܥܕܐ',
            bedeutung: 'männl., Nebenform zu Zacharia',
            id: '2066',
            ar1: 'ܣܥܕܐ',
            ar3: '',
            es2: 'ܣܥܕܐ'
        },
        {
            dt3: '',
            dt2: 'Bricho',
            id: '234',
            es3: '',
            dt1: 'Brikho',
            ar2: 'ܒܪܺܟ݂ܳܐ',
            es1: 'ܒܪܟ݂ܐ',
            es2: 'ܒܪܟ݂ܐ',
            ar1: 'ܒܪܟ݂ܐ',
            bedeutung: 'männl., “Gesegneter”; weitere Formen: Brikha, Bricho',
            ar3: ''
        },
        {
            es3: '',
            es2: 'ܐܒܪܐ',
            ar3: '',
            dt2: '',
            bedeutung: 'männl., “Feder, Flügel”',
            es1: 'ܐܒܪܐ',
            ar2: 'ܐܶܒܪܳܐ',
            id: '290',
            dt3: '',
            ar1: 'ܐܒܪܐ',
            dt1: 'Ebro'
        },
        {
            dt2: '',
            ar1: 'ܥܬܪܬܗ',
            dt1: 'Atarateh',
            dt3: '',
            es1: 'ܥܬܪܬܗ',
            es2: 'ܥܬܪܬܗ',
            ar2: 'ܥܰܬܰܪܰܬܶܗ',
            bedeutung: 'männl., “er hat berichtet”',
            es3: '',
            ar3: '',
            id: '1295'
        },
        {
            id: '1131',
            es2: 'ܝܬܪܐ',
            bedeutung: 'männl., “Sehne, Muskel, Balken, Band”; weitere Formen: Jatro',
            dt1: 'Yatro',
            ar1: 'ܝܬܪܐ',
            es1: 'ܝܬܪܐ',
            ar3: '',
            dt2: '',
            dt3: '',
            ar2: 'ܝܰܬܪܳܐ',
            es3: ''
        },
        {
            dt3: '',
            dt1: 'Aschin',
            es1: 'ܐܫܝܢ',
            es2: 'ܐܫܝܢ',
            ar3: '',
            es3: '',
            bedeutung: 'männl., “kräftig, mächtig, stark”',
            ar2: 'ܐܰܫܺܝܢ',
            dt2: '',
            ar1: 'ܐܫܝܢ',
            id: '110'
        },
        {
            bedeutung: 'weibl., Katina war eine Stadt in Alt-Syrien',
            ar2: 'ܩܰܪܰܪ',
            es3: 'ܟܪܪ',
            dt1: 'Karar',
            es2: 'ܩܪܪ',
            ar1: 'ܩܪܪ',
            dt2: '',
            id: '1766',
            ar3: 'ܟܰܪܰܪ',
            dt3: '',
            es1: 'ܩܪܪ'
        },
        {
            dt1: 'Febro',
            id: '338',
            es2: 'ܦܒܪܐ',
            bedeutung: 'weibl., Kurzform zu Fabronya',
            ar2: 'ܦܰܒܪܳܐ',
            dt3: '',
            ar3: '',
            es3: '',
            ar1: 'ܦܒܪܐ',
            dt2: 'Fabro',
            es1: 'ܦܒܪܐ'
        },
        {
            es2: 'ܩܝܬܪܐ',
            ar3: '',
            dt2: '',
            dt3: '',
            ar1: 'ܩܝܬܪܐ',
            es1: 'ܩܝܬܪܐ',
            es3: '',
            id: '2008',
            dt1: 'Qitora',
            bedeutung: 'weibl., aus dem Aramäischen übernommener Vorname; “Frühling”; weitere Formen: Rabea',
            ar2: 'ܩܺܝܬܳܪܰܐ'
        },
        {
            ar3: '',
            es2: 'ܗܒܒܐ',
            es3: '',
            es1: 'ܗܒܒܐ',
            dt2: '',
            id: '1592',
            ar2: 'ܗܰܒܳܒܳܐ',
            dt1: 'Habobo',
            ar1: 'ܗܒܒܐ',
            bedeutung: 'männl., ”Hadad ist König“. Hadadmalko war ein von den Aramäern verehrter ”Wettergott“',
            dt3: ''
        },
        {
            es1: 'ܡܬܐ',
            dt1: 'Mata',
            es3: 'ܡܬܐ',
            ar1: 'ܡܬܐ',
            es2: 'ܡܬܐ',
            bedeutung: 'weibl., syrischen Ursprungs; “Heimat, Vaterland, Mutterland”; weitere Formen: Matha, Motho',
            ar3: 'ܡܳܬܳܐ',
            dt2: '',
            dt3: '',
            id: '687',
            ar2: 'ܡܰܬܰܐ'
        },
        {
            dt3: '',
            es1: 'ܬܝܓܪܝܣ',
            ar3: '',
            dt2: '',
            es3: '',
            dt1: 'Tigris',
            es2: 'ܬܝܓܪܝܣ',
            ar2: 'ܬܺܝܓܪܺܝܣ',
            id: '1057',
            ar1: 'ܬܝܓܪܝܣ',
            bedeutung: 'weibl., siehe Deqlath'
        },
        {
            ar2: 'ܡܶܣܥܽܘܕ',
            dt2: 'Mesut',
            es1: 'ܡܣܥܘܕ',
            id: '685',
            ar3: '',
            ar1: 'ܡܣܥܘܕ',
            dt3: '',
            dt1: 'Mashud',
            es2: 'ܡܣܥܘܕ',
            bedeutung: '',
            es3: ''
        },
        {
            ar2: 'ܡܰܬܰܝ',
            bedeutung: 'weibl., Merdina/Merdin oder Merdo eine bedeutende aramäische Stadt in Aram Beth-Nahrin (= Mesopotamien)',
            dt3: '',
            dt1: 'Matay',
            dt2: 'Matai',
            ar1: 'ܡܬܝ',
            es2: 'ܡܬܝ',
            es3: '',
            id: '1877',
            es1: 'ܡܬܝ',
            ar3: ''
        },
        {
            ar2: 'ܦܰܘܠܰܐ',
            dt2: '',
            dt3: '',
            es2: 'ܦܘܠܐ',
            ar1: 'ܦܘܠܐ',
            id: '802',
            ar3: '',
            bedeutung: 'weibl. Form von Paulus',
            es1: 'ܦܘܠܐ',
            es3: '',
            dt1: 'Paula'
        },
        {
            dt3: '',
            ar3: '',
            ar1: 'ܐܪܒܝܠܐ',
            es3: '',
            id: '95',
            dt2: 'Arbela',
            dt1: 'Arbila',
            bedeutung: 'weibl., Arbil(a) ist eine ehemals aramäische Stadt im heutigen Irak',
            ar2: 'ܐܰܪܒܺܝܠܰܐ',
            es1: 'ܐܪܒܝܠܐ',
            es2: 'ܐܪܒܝܠܐ'
        },
        {
            dt3: '',
            ar3: '',
            id: '2230',
            es1: 'ܛܪܩܐ',
            ar2: 'ܛܰܪܩܳܐ',
            ar1: 'ܛܪܩܐ',
            dt1: 'Tarqo',
            es2: 'ܛܪܩܐ',
            es3: '',
            bedeutung: 'männl., aus dem griechischen “theós”: Gott und “dóron”: Geschenk, Gabe = “Geschenk/Gabe Gottes”. In der Kirchengeschichte hat es viele Heilige unter diesem Namen gegeben. Weitere Formen: Theodoros',
            dt2: ''
        },
        {
            ar3: '',
            dt3: '',
            dt2: '',
            ar1: 'ܫܪܒܝܠ',
            es3: '',
            es1: 'ܫܪܒܝܠ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe hat gehört”',
            dt1: 'Scharbel',
            id: '2136',
            ar2: 'ܫܰܪܒܶܝܠ',
            es2: 'ܫܪܒܝܠ'
        },
        {
            es2: 'ܟܘܙܐ',
            dt2: '',
            dt1: 'Kuza',
            ar3: 'ܟܽܘܙܳܐ',
            bedeutung: 'männl., “Gießkanne, Krug”; weitere Formen: Kuzo',
            es3: '',
            es1: 'ܟܘܙܐ',
            dt3: '',
            ar1: 'ܟܘܙܐ',
            id: '610',
            ar2: 'ܟܳܘܙܰܐ'
        },
        {
            ar3: '',
            ar1: 'ܦܝܟܪܝ',
            bedeutung: '',
            dt3: '',
            id: '1527',
            ar2: 'ܦܶܟܪܺܝ',
            dt1: 'Fikri',
            dt2: '',
            es3: '',
            es2: 'ܦܟܪܝ',
            es1: 'ܦܟܪܝ'
        },
        {
            es3: '',
            es2: 'ܐܢܟܝܕܐ',
            dt3: '',
            dt2: '',
            ar2: 'ܐܰܢܟܺܕܳܐ',
            id: '88',
            es1: 'ܐܢܟܝܕܐ',
            ar3: '',
            dt1: 'Ankido',
            ar1: 'ܐܢܟܝܕܐ',
            bedeutung: ''
        },
        {
            id: '215',
            es3: '',
            dt1: 'Belschassar',
            ar2: 'ܒܶܠܫܰܨܰܪ',
            ar1: 'ܒܠܫܨܪ',
            dt2: '',
            es2: 'ܒܠܫܨܪ',
            bedeutung: '',
            dt3: '',
            ar3: '',
            es1: 'ܒܠܫܨܪ'
        },
        {
            dt2: 'Sephanja',
            dt1: 'Zephanja',
            es3: '',
            es2: 'ܨܦܢܝܐ',
            ar3: '',
            dt3: 'Zephanya',
            es1: 'ܨܦܢܝܐ',
            bedeutung: '',
            ar2: 'ܨܶܦܰܢܝܰܐ',
            ar1: 'ܨܦܢܝܐ',
            id: '2361'
        },
        {
            ar2: 'ܒܶܠܬܺܝ',
            ar3: '',
            ar1: 'ܒܠܬܝ',
            dt3: '',
            dt2: '',
            es2: 'ܒܠܬܝ',
            id: '1396',
            es1: 'ܒܠܬܝ',
            es3: '',
            dt1: 'Belti',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Sohn des Hajil” oder “Sohn der Macht, der Tapfere”; weitere Formen: Ben-Hajlo, Ben-Hajla, Bar-Hajlo, Bar-Hajla'
        },
        {
            ar3: 'ܐܺܝܣܪܰܐܝܶܠ',
            es1: 'ܐܝܣܪܐܝܠ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gottesstreiter, Fechter Gottes” oder “möge Gott kämpfen”. Im AT ist Israel die Umbenennung des Namens Jakob bei seinem Kampf mit Gott. Weitere Namen: Isroel',
            es2: 'ܐܝܣܪܐܝܠ',
            es3: '',
            dt1: 'Israel',
            ar1: 'ܐܝܣܪܐܝܠ',
            dt3: '',
            id: '513',
            ar2: 'ܐܺܝܣܪܳܐܝܶܠ',
            dt2: 'Isroel'
        },
        {
            es2: 'ܐܝܪܦܢ',
            dt2: '',
            dt3: '',
            bedeutung: '',
            ar3: '',
            ar2: 'ܐܺܝܪܦܰܢ',
            ar1: 'ܐܝܪܦܢ',
            es1: 'ܐܝܪܦܢ',
            es3: '',
            id: '504',
            dt1: 'Irfan'
        },
        {
            ar2: 'ܒܰܪܨܽܘܪ',
            dt2: '',
            es3: '',
            ar3: '',
            id: '1370',
            es2: 'ܒܪܨܘܪ',
            ar1: 'ܒܪܨܘܪ',
            es1: 'ܒܪܨܘܪ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäischen Ursprungs; “Sohn des Tolmai”. Im NT ist Bartolmai einer der 12 Apostel Jesu Christi. Weitere Formen: Bartholomäus, Bartulmai, Bartulmay, Bar Tolmai',
            dt1: 'Barsur',
            dt3: ''
        },
        {
            dt1: 'Lilya',
            ar2: 'ܠܺܝܠܝܰܐ',
            es3: '',
            es1: 'ܠܝܠܝܐ',
            dt3: '',
            bedeutung: 'weibl., aus dem Griechischen übernommener Vorname; “die aus Lydien Stammende”. Die heilige Lydia war eine Purpur-händlerin und wurde von Paulus in Philippi getauft Weitere Formen: Ludia, Lidia, Lidya',
            id: '1820',
            es2: 'ܠܝܠܝܐ',
            ar3: '',
            dt2: '',
            ar1: 'ܠܝܠܝܐ'
        },
        {
            ar1: 'ܕܘܫܪܐ',
            ar3: '',
            dt1: 'Duschro',
            dt3: '',
            es1: 'ܕܘܫܪܐ',
            dt2: '',
            es3: '',
            id: '1471',
            bedeutung: 'männl., “Zeit, Moment”',
            es2: 'ܕܘܫܪܐ',
            ar2: 'ܕܽܘܫܪܳܐ'
        },
        {
            ar1: 'ܪܡܬ',
            bedeutung: 'männl., “du bist erhaben, kostbar”; weitere Formen: Romat',
            dt1: 'Ramat',
            es1: 'ܪܡܬ',
            dt2: '',
            id: '840',
            dt3: '',
            es2: 'ܪܡܬ',
            es3: 'ܪܡܬ',
            ar3: 'ܪܳܡܰܬ',
            ar2: 'ܪܰܡܰܐܬ'
        },
        {
            es1: 'ܒܪܬܡܬܐ',
            es2: 'ܒܪܬܡܬܐ',
            es3: '',
            dt3: '',
            bedeutung: 'weibl. Form zu Barschato; “Tochter des Jahres“, weitere Formen: Batschata',
            dt1: 'Bathmotho',
            id: '1389',
            ar3: '',
            ar2: 'ܒܰܪ̱ܬܡܳܬ݂ܳܐ',
            ar1: 'ܒܪ̱ܬܡܬ݂ܐ',
            dt2: ''
        },
        {
            id: '1060',
            es2: 'ܬܝܢܐ',
            dt2: '',
            es3: '',
            es1: 'ܬܝܢܐ',
            ar2: 'ܬܺܝܢܰܐ',
            bedeutung: '',
            dt1: 'Tina',
            ar1: 'ܬܝܢܐ',
            dt3: '',
            ar3: ''
        },
        {
            id: '1536',
            es1: 'ܓܒܪ',
            dt2: '',
            es3: 'ܓܒܪ',
            dt1: 'Gabar',
            ar1: 'ܓܒܪ',
            es2: 'ܓܒܪ',
            ar2: 'ܓܰܒܰܪ',
            ar3: 'ܓܰܒܳܪ',
            bedeutung: 'weibl. Form von Gabriel',
            dt3: ''
        },
        {
            dt2: '',
            ar3: 'ܢܰܐܽܘܡܺܝ',
            bedeutung: '',
            id: '1935',
            es3: 'ܢܐܘܡܝ',
            ar2: 'ܢܰܐܳܘܡܺܝ',
            dt3: '',
            es1: 'ܢܐܘܡܝ',
            dt1: 'Naomi',
            ar1: 'ܢܐܘܡܝ',
            es2: 'ܢܐܘܡܝ'
        },
        {
            ar2: 'ܥܰܒܕܳܐ',
            es1: 'ܥܒܕܐ',
            es3: '',
            dt1: 'Abdo',
            dt2: '',
            id: '9',
            ar3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Knecht, Diener”. Abdo/Abdu Sohn von Mazur war König von Edessa (127-120 v.Chr.). Weitere Formen: Ebed, Abud, Abdu',
            ar1: 'ܥܒܕܐ',
            es2: 'ܥܒܕܐ',
            dt3: ''
        },
        {
            dt2: '',
            es1: 'ܫܟ݂ܝܢܬܐ',
            bedeutung: 'weibl., “Wohnstätte, Behausung, Rastplatz, Ruhestätte”; weitere Formen: Schkhinta',
            dt1: 'Schkinta',
            ar2: 'ܫܟ݂ܺܝܢܬܰܐ',
            es2: 'ܫܟ݂ܝܢܬܐ',
            ar1: 'ܫܟ݂ܝܢܬܐ',
            ar3: '',
            es3: '',
            dt3: '',
            id: '963'
        },
        {
            ar2: 'ܐܶܠܝܰܣܰܦ',
            ar1: 'ܐܠܝܣܦ',
            ar3: '',
            dt1: 'Eljasaf',
            es1: 'ܐܠܝܣܦ',
            es3: '',
            bedeutung: '',
            es2: 'ܐܠܝܣܦ',
            dt2: '',
            id: '1492',
            dt3: ''
        },
        {
            es2: 'ܕܡܝܪ',
            es1: 'ܕܡܝܪ',
            ar2: 'ܕܶܡܺܝܪ',
            dt2: '',
            ar3: '',
            ar1: 'ܕܡܝܪ',
            dt3: '',
            id: '267',
            dt1: 'Demir',
            bedeutung: '',
            es3: ''
        },
        {
            dt3: '',
            dt1: 'Victoria',
            ar3: '',
            ar2: 'ܦܺܝܟܬܳܪܝܰܐ',
            bedeutung: 'männl., „überbringen, übermitteln, bestellen, überliefern”; weitere Formen: Jabel',
            ar1: 'ܦܝܟܬܪܝܐ',
            es1: 'ܦܝܟܬܪܐܝܐ',
            es3: '',
            dt2: 'Viktoria',
            es2: 'ܦܝܟܬܪܐܝܐ',
            id: '2293'
        },
        {
            ar1: 'ܢܝܢܝܒ',
            ar3: '',
            es3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Ruhebringer, Tröster”. Nuh ist im AT ein Prophet, der die Sintﬂut in seiner Arche überstand und zum Gründer neuer Volksstämme wurde. Weitere Formen: Noah, Noach',
            es1: 'ܢܝܢܝܒ',
            dt1: 'Ninib',
            dt2: '',
            es2: 'ܢܝܢܝܒ',
            ar2: 'ܢܺܝܢܺܝܒ',
            dt3: '',
            id: '1959'
        },
        {
            id: '2037',
            ar2: 'ܪܰܝܡܰܐ',
            ar3: 'ܪܺܝܡܰܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs. Im AT ist Resin wohl der letzte König der Aramäer von Aram-Damaskus (730 v. Chr.). Weitere Formen: Rezin, Roson',
            ar1: 'ܪܝܡܐ',
            es3: 'ܪܝܡܐ',
            dt1: 'Rayma',
            es2: 'ܪܝܡܐ',
            es1: 'ܪܝܡܐ',
            dt3: '',
            dt2: ''
        },
        {
            dt2: 'Yakub',
            es3: '',
            dt1: 'Jakob',
            ar3: '',
            id: '526',
            ar2: 'ܝܰܥܩܽܘܒ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Fersenhalter”. Im AT ist Jakob der jüngere Sohn Isaaks und Rebekkas, Zwillingsbıuder von Esau. Er lebte in Paddan Aram, wo elf seiner Söhne geboren wurden. Im NT tragen viele Personen (u.a. Apostel) diesen Namen. Ebenfalls gibt es viele syrische Heilige und Kirchenlehrer des gleichen Namens: Jakob von Nisibis (†338), Festtag: 11. Mai; Jakob Burd`ono (Baradäus †578), Festtag: 31. Juli; Jakob Hbischoyo von Salah in Tur Abdin (330-422), Festtag: 18. September; Jakob von Samg (451-521), Festtag: 29. November; Jakob von Edessa (†708). Weitere Formen: Yaqub, Jacub, Jakobus',
            dt3: '',
            ar1: 'ܝܥܩܘܒ',
            es1: 'ܝܥܩܘܒ',
            es2: 'ܝܥܩܘܒ'
        },
        {
            id: '515',
            bedeutung: 'weibl., “Wesen, Existenz, Dasein”',
            es3: '',
            ar3: '',
            ar1: 'ܐܝܬܘܬܐ',
            dt2: '',
            es1: 'ܐܝܬܘܬܐ',
            es2: 'ܐܝܬܘܬܐ',
            dt1: 'Ithutha',
            dt3: '',
            ar2: 'ܐܺܝܬܽܘܬܳܐ'
        },
        {
            id: '1954',
            ar1: 'ܢܪܝܓܠܝܣܪ',
            ar3: '',
            bedeutung: 'männl., “Gipfel, Spitze, Höhepunkt, Schirm, Tal, Abgrund”',
            es1: 'ܢܪܝܓܠܝܣܪ',
            dt1: 'Neriglissar',
            ar2: 'ܢܶܪܺܝܓܠܺܝܣܰܪ',
            dt3: '',
            es2: 'ܢܪܝܓܠܝܣܪ',
            es3: '',
            dt2: ''
        },
        {
            ar1: 'ܢܫܪܐ',
            es2: 'ܢܫܪܐ',
            es1: 'ܢܫܪܐ',
            bedeutung: 'männl., “Adler”',
            dt2: '',
            es3: '',
            id: '765',
            ar3: '',
            dt1: 'Neschro',
            dt3: '',
            ar2: 'ܢܶܫܪܳܐ'
        },
        {
            dt2: '',
            dt1: 'Julian',
            dt3: '',
            es2: 'ܝܘܠܝܐܢ',
            ar2: 'ܝܽܘܠܝܰܐܢ',
            es1: 'ܝܘܠܝܐܢ',
            ar1: 'ܝܘܠܝܐܢ',
            id: '1754',
            es3: '',
            bedeutung: 'weibl., Kalitmara ist ein Dorf in Turabdin',
            ar3: ''
        },
        {
            es3: '',
            es1: 'ܒܣܝܡܐ',
            dt2: '',
            id: '1379',
            es2: 'ܒܣܝܡܐ',
            ar2: 'ܒܰܣܺܝܡܳܐ',
            ar1: 'ܒܣܝܡܐ',
            dt3: '',
            dt1: 'Basimo',
            bedeutung: 'weibl., wohl abgeleitet von Basma/Basmo “Weihrauch”; weitere Formen: Bassna',
            ar3: ''
        },
        {
            dt1: 'Nahir',
            es3: '',
            ar3: '',
            dt2: 'Nahiro',
            ar1: 'ܢܗܝܪ',
            ar2: 'ܢܰܗܺܝܪ',
            dt3: '',
            es1: 'ܢܗܝܪ',
            es2: 'ܢܗܝܪ',
            bedeutung: 'siehe Naima',
            id: '1926'
        },
        {
            ar3: 'ܪܳܚܽܘܒ',
            dt1: 'Rehob',
            dt3: '',
            ar1: 'ܪܚܒ',
            ar2: 'ܪܳܚܳܒ',
            es1: 'ܪܚܒ',
            dt2: '',
            es2: 'ܪܚܒ',
            bedeutung: 'weibl., siehe Rihan',
            id: '2042',
            es3: 'ܪܚܘܒ'
        },
        {
            es2: 'ܐܪܘܛ',
            dt3: '',
            dt1: 'Arout',
            es1: 'ܐܪܘܛ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott mehrt oder sammelt”',
            ar3: '',
            id: '1284',
            es3: '',
            ar1: 'ܐܪܘܛ',
            ar2: 'ܐܰܪܽܘܛ',
            dt2: ''
        },
        {
            dt3: '',
            ar2: 'ܠܽܘܩܰܐ',
            ar1: 'ܠܘܩܐܣ',
            es3: 'ܠܘܩܐ',
            ar3: 'ܠܽܘܩܳܐ',
            id: '1825',
            dt1: 'Lukas',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Lob Gottes“. Im AT ist Mahalalel Sohn des Kenan und Vater des Jered; im NT ist Mahalalel ein Ahnherr Jesu. Weitere Formen: Mahloloel',
            es1: 'ܠܘܩܐܣ',
            dt2: '',
            es2: 'ܠܘܩܐ'
        },
        {
            ar1: 'ܕܡܝܬܐ',
            es3: '',
            bedeutung: 'weibl., “Platane”',
            es1: 'ܕܡܝܬܐ',
            dt3: '',
            es2: 'ܕܡܝܬܐ',
            dt1: 'Domita',
            id: '1463',
            dt2: '',
            ar2: 'ܕܳܡܺܝܬܰܐ',
            ar3: ''
        },
        {
            dt3: '',
            ar2: 'ܫܠܶܝܡܽܘܢ',
            ar3: '',
            dt2: 'Sleymun',
            es1: 'ܫܠܝܡܘܢ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “der Friedliche, der Friedfertige” oder “Friede, Wohlergehen“. Im AT ist Schleymun Sohn Davids und König von Israel (970 - 931). Weitere Formen: Saalomo, Salomon, Schelomoh, Suleyman; bei den Aramäern beliebt',
            es2: 'ܫܠܝܡܘܢ',
            es3: '',
            dt1: 'Schleymun',
            ar1: 'ܫܠܝܡܘܢ',
            id: '964'
        },
        {
            ar2: 'ܩܽܘܣܛܰܢܛܺܝܢ',
            ar1: 'ܩܘܣܛܢܛܝܢ',
            ar3: '',
            dt2: 'Constantin',
            dt1: 'Konstantin',
            es3: '',
            id: '603',
            es2: 'ܩܘܣܛܢܛܝܢ',
            bedeutung: 'männl., aus dem Lateinischen übernommener Vorname; “standhaft”. Nach dem bekannten christlichen Kaiser Konstantin dem Großen (4. Jh.) bei den Aramäern beliebter Vorname. Weitere Formen: Constantin, Kustantin, Qustantin',
            dt3: '',
            es1: 'ܩܘܣܛܢܛܝܢ'
        },
        {
            dt3: '',
            ar2: 'ܕܰܢܺܝܐܝܶܠܰܐ',
            id: '259',
            bedeutung: 'weibl. Form von Daniel',
            es1: 'ܕܢܝܐܝܠܐ',
            es2: 'ܕܢܝܐܝܠܐ',
            dt2: '',
            dt1: 'Daniela',
            es3: 'ܕܢܝܐܝܠܗ',
            ar1: 'ܕܢܝܐܝܠܐ',
            ar3: 'ܕܰܐܢܺܝܐܝܶܠܰܗ'
        },
        {
            ar1: 'ܐܠܝܟܝܡ',
            es2: 'ܐܠܝܟܝܡ',
            bedeutung: 'männl., “Nachtigall”',
            id: '1491',
            es3: 'ܐܠܝܩܝܡ',
            ar2: 'ܐܶܠܝܰܟܺܝܡ',
            dt3: '',
            dt1: 'Eljakim',
            dt2: 'Eljaqim',
            es1: 'ܐܠܝܟܝܡ',
            ar3: 'ܐܶܠܝܰܩܺܝܡ'
        },
        {
            ar3: '',
            dt3: '',
            es1: 'ܦܛܪܐ',
            bedeutung: 'weibl., “Melodie, Gesang, Lied, Hymne, Ton, Note”',
            es2: 'ܦܛܪܐ',
            ar1: 'ܦܛܪܐ',
            dt1: 'Petra',
            es3: '',
            dt2: '',
            ar2: 'ܦܶܛܪܰܐ',
            id: '1998'
        },
        {
            dt1: 'Barnun',
            es2: 'ܒܪܢܘܢ',
            ar2: 'ܒܰܪܢܽܘܢ',
            id: '1359',
            es3: '',
            es1: 'ܒܪܢܘܢ',
            ar3: '',
            ar1: 'ܒܪܢܘܢ',
            bedeutung: 'männl., “Sohn des Reiters”. Barrakib war ein König der Aramäer von Sam`al-Jadi (732-720 V. Chr.), Sohn von Panammu II. (743-732)',
            dt3: '',
            dt2: ''
        },
        {
            dt3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe/Gott hat gegeben”. Im AT ist Nathan ein Prophet zur Zeit des Königs David. Weitere Formen: Natan, Nathanael, Natanael, Jonathan, Jonatan, Noton, Nothon, Jonothon',
            dt2: '',
            ar2: 'ܢܰܩܕܽܘܬܰܐ',
            dt1: 'Naqduta',
            es1: 'ܢܩܕܘܬܐ',
            es3: '',
            es2: 'ܢܩܕܘܬܐ',
            ar3: '',
            id: '1936',
            ar1: 'ܢܩܕܘܬܐ'
        },
        {
            es2: 'ܒܝܬܢܗܪܝܢ',
            dt1: 'Bethnahrin',
            dt3: '',
            es1: 'ܒܝܬܢܗܪܝܢ',
            id: '226',
            bedeutung: 'weibl., “zwischen den Flüssen, Mesopotamien”; weitere Formen: Bethnahro, Bethnahra',
            dt2: '',
            es3: '',
            ar1: 'ܒܝܬܢܗܪܝܢ',
            ar3: 'ܒܶܝܬܢܰܗܪܰܐ',
            ar2: 'ܒܶܝܬܢܰܗܪܺܝܢ'
        },
        {
            ar2: 'ܝܶܫܽܘܥܝܰܗܒ',
            es1: 'ܝܫܘܥܝܗܒ',
            dt3: '',
            ar3: '',
            es3: '',
            id: '1728',
            dt2: '',
            dt1: 'Jeschujahb',
            es2: 'ܝܫܘܥܝܗܒ',
            ar1: 'ܝܫܘܥܝܗܒ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe hört”'
        },
        {
            dt3: '',
            bedeutung: 'männl., siehe Volgesa',
            es3: '',
            id: '1104',
            es1: 'ܘܠܓܫ',
            ar1: 'ܘܠܓܫ',
            es2: 'ܘܠܓܫ',
            ar2: 'ܘܰܠܰܓܰܫ',
            ar3: '',
            dt2: '',
            dt1: 'Walagasch'
        },
        {
            ar2: 'ܗܰܒܳܒܬܰܐ',
            dt3: '',
            es3: '',
            dt1: 'Habobta',
            dt2: '',
            es1: 'ܗܒܒܬܐ',
            ar1: 'ܗܒܒܬܐ',
            es2: 'ܗܒܒܬܐ',
            id: '1593',
            ar3: '',
            bedeutung: 'männl., König von Guzan/Tell-Halaf, Sohn des Schamasch-Nuri, des Königs von Guzan.'
        },
        {
            dt1: 'Schemschun',
            es2: 'ܫܡܫܘܢ',
            id: '957',
            es3: 'ܫܡܥܘܢ',
            ar2: 'ܫܶܡܫܽܘܢ',
            ar3: 'ܫܰܡܥܽܘܢ',
            dt3: '',
            dt2: 'Schamun',
            es1: 'ܫܡܫܘܢ',
            ar1: 'ܫܡܫܘܢ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “kleine Sonne”. Im AT war Schemschun eine tapfere und mächtige Persönlichkeit, Ehemann von Dalila, in der Kirchengeschichte ein Bischof von Arbela (120 - 123). Weitere Formen: Samson, Simson, Schimschon, Schemschon'
        },
        {
            es2: 'ܐܠܫܡܫ',
            dt1: 'Elschamasch',
            bedeutung: 'männl., “Gott ist die Sonne”',
            ar3: '',
            dt3: '',
            es3: '',
            ar2: 'ܐܶܠܫܰܡܰܫ',
            dt2: '',
            es1: 'ܐܠܫܡܫ',
            ar1: 'ܐܠܫܡܫ',
            id: '311'
        },
        {
            dt3: '',
            ar1: 'ܥܡܢ',
            dt2: '',
            dt1: 'Aman',
            es1: 'ܥܡܢ',
            ar2: 'ܥܰܡܰܢ',
            id: '71',
            ar3: '',
            es3: '',
            es2: 'ܥܡܢ',
            bedeutung: ''
        },
        {
            dt1: 'Hirta',
            dt2: '',
            es3: '',
            ar2: 'ܚܺܐܪܬܰܐ',
            es2: 'ܚܐܪܬܐ',
            ar1: 'ܚܐܪܬܐ',
            bedeutung: 'weibl., syrischen Ursprungs; “die Freie, ein(e) freie(s) [Frau/Mädchen]”',
            ar3: '',
            es1: 'ܚܐܪܬܐ',
            id: '479',
            dt3: ''
        },
        {
            id: '2207',
            es1: 'ܣܘܪܝ',
            dt3: '',
            bedeutung: 'männl. Form zu Tabita; weitere Formen: Tabiya, Tabea',
            ar2: 'ܣܽܘܪܺܝ',
            es3: '',
            dt1: 'Suri',
            ar3: '',
            es2: 'ܣܘܪܝ',
            dt2: '',
            ar1: 'ܣܘܪܝ'
        },
        {
            dt1: 'Helen',
            ar1: 'ܗܠܢ',
            bedeutung: 'männl., siehe Hiskija',
            es3: '',
            id: '1650',
            es2: 'ܗܠܢ',
            ar3: '',
            ar2: 'ܗܶܠܶܢ',
            dt2: '',
            dt3: '',
            es1: 'ܗܠܢ'
        },
        {
            bedeutung: 'männl, aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs. Bedeutung unklar, vielleicht “den man als Feind behandelt” oder “der sich gegen Gott wendet” oder “Wo ist der Vater?”. Im AT ist Ayyub ein Prophet und zugleich Verfasser eines Buches. Weitere Formen: Hiob, Eyup, Eyüp, Job, Ijob',
            ar3: '',
            es2: 'ܐܝܟܢ',
            id: '1314',
            ar2: 'ܐܰܝܟܰܢ',
            dt2: '',
            es1: 'ܐܝܟܢ',
            dt3: '',
            ar1: 'ܐܝܟܢ',
            es3: '',
            dt1: 'Aykan'
        },
        {
            ar1: 'ܐܘܕܝ',
            ar2: 'ܐܰܘܕܺܝ',
            es2: 'ܐܘܕܝ',
            bedeutung: '',
            id: '1307',
            dt3: '',
            ar3: '',
            es3: '',
            es1: 'ܐܘܕܝ',
            dt2: 'Awdi',
            dt1: 'Audi'
        },
        {
            dt1: 'Basima',
            ar1: 'ܒܣܣܝܡܐ',
            es1: 'ܒܣܣܝܡܐ',
            es3: '',
            dt3: '',
            ar3: '',
            dt2: '',
            ar2: 'ܒܰܣܣܺܝܡܰܐ',
            id: '1378',
            bedeutung: '',
            es2: 'ܒܣܣܝܡܐ'
        },
        {
            ar3: '',
            es1: 'ܩܘܪܝܩܘܣ',
            ar2: 'ܩܽܘܪܝܰܩܳܘܣ',
            dt2: 'Kuryakos',
            ar1: 'ܩܘܪܝܩܘܣ',
            dt3: 'Quryakos',
            es2: 'ܩܘܪܝܩܘܣ',
            es3: '',
            bedeutung: 'männl., griechischen Ursprungs; “dem Herrn gehörig”. Kuriakos (†301) ist Heiliger und wird zusammen mit seiner Mutter Yuliti/Juliti verehrt; Festtag: 15. Juli. Weitere Formen: Kuryakos, Quryaqos, Quriaqos',
            id: '609',
            dt1: 'Kuriakos'
        },
        {
            dt3: '',
            ar3: '',
            ar1: 'ܩܠܬܡܖܐ',
            bedeutung: 'männl., “ﬂeißig, eifrig”',
            es1: 'ܩܠܬܡܪܐ',
            es3: '',
            es2: 'ܩܠܬܡܪܐ',
            id: '1763',
            dt2: '',
            ar2: 'ܩܶܠܶܬܡܰܖܰܐ',
            dt1: 'Kalitmara'
        },
        {
            es2: 'ܓܬܪ',
            id: '393',
            dt1: 'Gotor',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäischen Ursprungs. Im AT ist Gotor Sohn Arams; ein aramäischer Stamm. Weitere Formen: Geter',
            ar1: 'ܓܬܪ',
            ar2: 'ܓܳܬܳܪ',
            es3: '',
            dt2: '',
            ar3: '',
            es1: 'ܓܬܪ',
            dt3: ''
        },
        {
            es1: 'ܐܕܢܬܐ',
            ar1: 'ܐܕܢܬܐ',
            dt1: 'Odenata',
            dt2: '',
            es2: 'ܐܕܢܬܐ',
            ar3: '',
            id: '1983',
            bedeutung: 'männl., “Arzt, Doktor, Heiler”',
            ar2: 'ܐܳܕܶܢܰܬܰܐ',
            dt3: '',
            es3: ''
        },
        {
            id: '134',
            ar3: '',
            dt2: '',
            dt1: 'Aykan',
            ar1: 'ܐܝܟܢ',
            ar2: 'ܐܰܝܟܰܢ',
            dt3: '',
            es3: '',
            es2: 'ܐܝܟܢ',
            bedeutung: 'männl., “wie”',
            es1: 'ܐܝܟܢ'
        },
        {
            es1: 'ܝܒܠܐ',
            ar3: '',
            id: '2303',
            bedeutung: 'männl., “Kind, Sohn, Geborener”; weitere Formen: Yaldo, Jalda, Jaldo',
            dt3: '',
            ar2: 'ܝܰܒܠܳܐ',
            ar1: 'ܝܒܠܐ',
            dt1: 'Yablo',
            es3: '',
            es2: 'ܝܒܠܐ',
            dt2: ''
        },
        {
            dt3: '',
            es3: '',
            es2: 'ܟܡܘܪܓܘ',
            id: '1790',
            ar1: 'ܟܡܘܪܓܘ',
            ar2: 'ܟܳܡܽܘܪܓܽܘ',
            bedeutung: '',
            dt2: '',
            es1: 'ܟܡܘܪܓܘ',
            dt1: 'Komurcu',
            ar3: ''
        },
        {
            id: '1807',
            ar2: 'ܠܰܡܶܕ',
            dt1: 'Lamed',
            ar1: 'ܠܡܕ',
            bedeutung: 'männl., “Herz”',
            es1: 'ܠܡܕ',
            dt2: '',
            dt3: '',
            es3: '',
            es2: 'ܠܡܕ',
            ar3: ''
        },
        {
            ar3: '',
            es2: 'ܡܪܝܐܠܗܐ',
            es1: 'ܡܪܝܐܠܗܐ',
            dt2: '',
            dt1: 'Maryalaha',
            bedeutung: 'weibl., “Land, Heimat“. Matiate ist eine Stadt in Turabdin. Weitere Formen: Matiyate, Medyad',
            ar1: 'ܡܪܝܐܠܗܐ',
            dt3: '',
            es3: '',
            id: '1870',
            ar2: 'ܡܰܪܝܰܐܠܰܗܰܐ'
        },
        {
            dt3: '',
            dt1: 'Hiskija',
            ar2: 'ܚܶܙܰܩܝܳܐ',
            ar3: 'ܚܺܙܩܺܝܰܐ',
            es1: 'ܚܙܩܝܐ',
            dt2: '',
            ar1: 'ܚܙܩܝܐ',
            es3: 'ܚܙܩܝܐ',
            id: '1668',
            bedeutung: 'weibl., “Erneuerung”; weitere Formen: Hudata',
            es2: 'ܚܙܩܝܐ'
        },
        {
            es1: 'ܪܡܝܣܬܐ',
            dt2: '',
            ar2: 'ܪܡܺܝܣܬܰܐ',
            id: '2053',
            dt1: 'Rmista',
            ar1: 'ܪܡܝܣܬܐ',
            es3: '',
            dt3: '',
            es2: 'ܪܡܝܣܬܐ',
            bedeutung: 'männl., “Jesus ist erhaben”',
            ar3: ''
        },
        {
            dt3: 'Froth',
            bedeutung: 'weibl., “zerreißen, aufreissen, platzen, bersten” oder “Tiefe, Strom, Strombett, Gewässer”. Euphrat ist ein Strom/Fluss in Aram Beth-Nahrin (= Mesopotamien). Weitere Formen: Eufrat, Froth',
            es3: '',
            ar1: 'ܐܦܪܬ',
            ar3: '',
            ar2: 'ܐܳܦܪܰܬ',
            dt2: 'Eufrat',
            es2: 'ܐܦܪܬ',
            dt1: 'Euphrat',
            es1: 'ܐܦܪܬ',
            id: '324'
        },
        {
            ar2: 'ܠܺܝܕܺܝܰܐ',
            ar3: '',
            dt3: '',
            es1: 'ܠܝܕܝܐ',
            es3: '',
            bedeutung: 'weibl., aus dem Griechischen übernommener Vorname; “die aus Lydien Stammende”. Die heilige Lydia war eine Purpur-händlerin und wurde von Paulus in Philippi getauft Weitere Formen: Ludia, Lidia, Lidya',
            dt2: '',
            id: '641',
            ar1: 'ܠܝܕܝܐ',
            dt1: 'Lydia',
            es2: 'ܠܝܕܝܐ'
        },
        {
            es3: '',
            ar1: 'ܠܒܐ',
            es2: 'ܠܒܐ',
            dt2: '',
            ar3: '',
            dt1: 'Lebo',
            id: '628',
            ar2: 'ܠܶܒܳܐ',
            bedeutung: 'männl., “Herz”',
            es1: 'ܠܒܐ',
            dt3: ''
        },
        {
            dt3: '',
            id: '1615',
            es3: '',
            ar3: '',
            es2: 'ܚܡܫܝܢ',
            bedeutung: '',
            ar2: 'ܚܰܡܫܺܝܢ',
            dt1: 'Hamschin',
            dt2: '',
            es1: 'ܚܡܫܝܢ',
            ar1: 'ܚܡܫܝܢ'
        },
        {
            es1: 'ܝܪܒ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “der Gerechte”; weitere Formen: Zadiqo, Zadok',
            ar2: 'ܝܺܪܰܒ',
            dt3: '',
            es2: 'ܝܪܒ',
            ar3: '',
            id: '2329',
            es3: '',
            ar1: 'ܝܪܒ',
            dt1: 'Yirab',
            dt2: 'Jirab'
        },
        {
            es2: 'ܐܢܕܪܐܘܣ',
            dt2: 'Andreos',
            ar3: '',
            ar2: 'ܐܰܢܕܪܶܐܘܳܣ',
            dt3: 'Andrawos',
            id: '1265',
            ar1: 'ܐܢܕܪܐܘܣ',
            es3: '',
            dt1: 'Andreas',
            es1: 'ܐܢܕܪܐܘܣ',
            bedeutung: 'männl., “Erleuchteter”'
        },
        {
            dt1: 'Schalmat',
            ar1: 'ܫܠܡܬ',
            es3: '',
            ar3: '',
            es2: 'ܫܠܡܬ',
            dt2: '',
            dt3: '',
            id: '932',
            es1: 'ܫܠܡܬ',
            bedeutung: 'weibl., “du bist meine Vollendung”. Die Tochter der Meherdat, die Frau des Abgar Ukkomo, Königin von Edessa',
            ar2: 'ܫܰܠܡܰܬ'
        },
        {
            id: '1509',
            dt2: 'Eufrat',
            bedeutung: '',
            dt3: 'Froth',
            es2: 'ܐܦܪܬ',
            ar2: 'ܐܳܦܪܰܬ',
            es3: '',
            ar3: '',
            ar1: 'ܐܦܪܬ',
            dt1: 'Euphrat',
            es1: 'ܐܦܪܬ'
        },
        {
            dt2: 'Yakub',
            ar3: '',
            es2: 'ܝܥܩܘܒ',
            dt3: '',
            dt1: 'Jakob',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe erhöht”. Im AT ist Jeremia ein großer Prophet (2. Hälfte des 7. Jh. v. Chr.) und Verfasser eines Buches. Weitere Formen: Eramia, Eramio',
            es1: 'ܝܥܩܘܒ',
            ar1: 'ܝܥܩܘܒ',
            ar2: 'ܝܰܥܩܽܘܒ',
            es3: '',
            id: '1713'
        },
        {
            ar2: 'ܚܰܙܽܘܪܬܰܐ',
            es1: 'ܚܙܘܪܬܐ',
            dt3: '',
            es2: 'ܚܙܘܪܬܐ',
            es3: '',
            dt2: '',
            id: '459',
            bedeutung: 'weibl., syrischen Ursprungs; “Apfel”',
            ar3: '',
            ar1: 'ܚܙܘܪܬܐ',
            dt1: 'Hazurta'
        },
        {
            es2: 'ܐܡܝܪܬܐ',
            es1: 'ܐܡܝܪܬܐ',
            dt1: 'Amirta',
            dt3: '',
            es3: '',
            ar2: 'ܐܰܡܺܝܪܬܰܐ',
            bedeutung: 'weibl. Form zu Amir; “Fürstin, Prinzessin',
            dt2: '',
            id: '77',
            ar1: 'ܐܡܝܪܬܐ',
            ar3: ''
        },
        {
            id: '2365',
            es3: '',
            ar3: '',
            es2: 'ܙܟܝܬܐ',
            dt2: '',
            dt3: '',
            dt1: 'Zkita',
            es1: 'ܙܟܝܬܐ',
            ar1: 'ܙܟܝܬܐ',
            ar2: 'ܙܟܺܝܬܰܐ',
            bedeutung: ''
        },
        {
            ar3: '',
            id: '5',
            ar1: 'ܥܒܘܫܬܐ',
            dt3: '',
            bedeutung: 'männl., “Schecke”. Abbuschta (450-498) war ostsyrischer Metropolit von Arbela. Weitere Formen: Abbuschto',
            es2: 'ܥܒܘܫܬܐ',
            es1: 'ܥܒܘܫܬܐ',
            ar2: 'ܥܰܒܽܘܫܬܰܐ',
            dt2: '',
            dt1: 'Abbuschta',
            es3: ''
        },
        {
            ar3: '',
            bedeutung: 'männl., “Sonnenzerschneider” oder “der die Sonne zerschneidet, zerteilt”; einer der zwei Boten, die Abgar zu Jesus gesendet hat. Weitere Formen: Schemaschgram',
            id: '2124',
            dt1: 'Schalmat',
            es3: '',
            es2: 'ܫܠܡܬ',
            es1: 'ܫܠܡܬ',
            ar2: 'ܫܰܠܡܰܬ',
            dt3: '',
            dt2: '',
            ar1: 'ܫܠܡܬ'
        },
        {
            es3: 'ܟܘܪܚܐ',
            ar2: 'ܟܶܪܚܳܐ',
            es2: 'ܟܪܚܐ',
            dt3: '',
            ar1: 'ܟܪܚܐ',
            es1: 'ܟܪܚܐ',
            ar3: 'ܟܽܘܪܚܳܐ',
            bedeutung: 'männl., syrischen Ursprungs; wohl von Kurho: “Zelle, Kabine”; weitere Formen: Kurho',
            dt2: '',
            dt1: 'Kerho',
            id: '593'
        },
        {
            id: '2025',
            es1: 'ܪܚܝܠ',
            bedeutung: 'männl., “Gott ist erhaben”; weitere Formen: Romil',
            dt2: '',
            es3: '',
            ar1: 'ܪܚܝܠ',
            es2: 'ܪܚܝܠ',
            ar3: '',
            dt3: '',
            dt1: 'Rachel',
            ar2: 'ܪܰܚܶܝܠ'
        },
        {
            dt1: 'Yaqunto',
            es1: 'ܝܘܘܢܬܐ',
            ar3: '',
            ar2: 'ܝܰܩܽܘܢܬܳܐ',
            dt3: '',
            ar1: 'ܝܩܘܢܬܐ',
            es3: '',
            id: '1130',
            bedeutung: 'männl., “Rubin”; weitere Formen: Yoqunto, Yaqundo, Yoqundo, Yuqando, Yuqanto',
            dt2: '',
            es2: 'ܝܘܘܢܬܐ'
        },
        {
            dt3: '',
            dt1: 'Barniro',
            ar3: '',
            id: '178',
            dt2: '',
            ar1: 'ܒܪܢܝܪܐ',
            ar2: 'ܒܰܪܢܺܝܪܳܐ',
            es3: '',
            es1: 'ܒܪܢܝܪܐ',
            bedeutung: 'männl., “Sohn des Joches”',
            es2: 'ܒܪܢܝܪܐ'
        },
        {
            dt3: '',
            id: '794',
            es1: 'ܐܕܪܐ',
            ar1: 'ܐܕܪܐ',
            dt2: '',
            ar3: '',
            ar2: 'ܐܳܕܪܳܐ',
            es3: '',
            dt1: 'Odro',
            es2: 'ܐܕܪܐ',
            bedeutung: 'männl., siehe Odar'
        },
        {
            ar2: 'ܓܺܝܪܓܺܝܣ',
            es2: 'ܓܪܓܝܣ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäischen Ursprungs. Im AT ist Gotor Sohn Arams; ein aramäischer Stamm. Weitere Formen: Geter',
            id: '1572',
            ar3: '',
            es1: 'ܓܪܓܝܣ',
            dt1: 'Girgis',
            es3: '',
            ar1: 'ܓܪܓܝܣ',
            dt2: '',
            dt3: ''
        },
        {
            id: '1455',
            ar1: 'ܕܐܝܢܐ',
            ar2: 'ܕܺܐܝܰܢܳܐ',
            bedeutung: 'männl., “Jesus ist mein Onkel”',
            dt1: 'Diana',
            dt2: '',
            es2: 'ܕܐܝܢܐ',
            es1: 'ܕܐܝܢܐ',
            ar3: 'ܕܝܰܢܰܐ',
            dt3: '',
            es3: 'ܕܝܢܐ'
        },
        {
            dt3: '',
            es1: 'ܬܘܥ',
            id: '2263',
            ar1: 'ܬܘܥ',
            ar2: 'ܬܳܥܽܘ',
            dt2: '',
            dt1: 'Toi',
            es2: 'ܬܘܥ',
            es3: 'ܬܥܝ',
            bedeutung: 'weibl., “Bewohnerin aus Turabdin, aus den Bergen”; weitere Formen: Turaita, Turajta',
            ar3: 'ܬܳܥܺܝ'
        },
        {
            ar1: 'ܫܡܠܝ',
            dt1: 'Schamli',
            dt2: '',
            ar3: '',
            bedeutung: 'männl., “wahr, echt, zuverlässig”; weitere Formen: Scharo, Scharro',
            es1: 'ܫܡܠܝ',
            dt3: '',
            es3: '',
            es2: 'ܫܡܠܝ',
            ar2: 'ܫܰܡܠܺܝ',
            id: '2131'
        },
        {
            bedeutung: 'weibl., „Mandeln“; weitere Formen: Lusia, Luze',
            id: '1819',
            es2: 'ܠܝܠܐ',
            es3: 'ܠܝܠܗ',
            es1: 'ܠܝܠܐ',
            dt2: 'Layla',
            ar1: 'ܠܝܠܐ',
            ar3: 'ܠܰܝܠܰܗ',
            dt1: '̱Leyla',
            ar2: 'ܠܰܝܠܰܐ',
            dt3: ''
        },
        {
            ar1: 'ܝܫܘܥܕܢܚܐ',
            dt1: 'Jeschudenho',
            dt3: '',
            ar3: '',
            es2: 'ܝܫܘܥܕܢܚܐ',
            dt2: '',
            bedeutung: 'männl., Jidlaf ist nach dem AT Bruder Kesed und Betuels, Sohn Nahors. Weitere Formen: Jarlaf, Jirlaf',
            es3: '',
            ar2: 'ܝܶܫܽܘܥܕܶܢܚܳܐ',
            id: '1726',
            es1: 'ܝܫܘܥܕܢܚܐ'
        },
        {
            dt3: 'Ahiqar',
            es1: 'ܐܚܝܩܪ',
            es2: 'ܐܚܝܩܪ',
            ar3: '',
            es3: '',
            ar1: 'ܐܚܝܩܪ',
            dt2: 'Achikar',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “mein Bruder ist kostbar”. Im AT ist Ahikar ein Verwandter von Tobias/Tobit, Minister der assyrischen Könige Sanherib(754-681 v.Chr.) und Asarhaddon (680-669 v.Chr.). Der Ahikar-Roman ist u.a. in syrisch-aramäischer Sprache überliefert. Weitere Formen: Ahiqar, Achikar',
            ar2: 'ܐܰܚܺܝܩܰܪ',
            id: '56',
            dt1: 'Ahikar'
        },
        {
            es1: 'ܡܫܝܚܪܚܡܐ',
            bedeutung: 'männl., “Christus ist barmherzig”. Mschihorahme (5.Jh.) war ein ostsyrischer Mönch in Arbela. Weitere Formen: Mschiharahme',
            es3: 'ܡܫܝܚܐ ܪܚܡܐ',
            dt1: 'Mschihorahme',
            id: '721',
            dt2: '',
            ar2: 'ܡܫܺܝܚܳܪܰܚܡܶܐ',
            es2: 'ܡܫܝܚܪܚܡܐ',
            ar1: 'ܡܫܝܚܪܚܡܐ',
            dt3: '',
            ar3: 'ܡܫܺܝܚܳܐ ܪܰܚܡܶܐ'
        },
        {
            id: '1398',
            es2: 'ܒܢܚܐܝܠ',
            ar3: '',
            dt2: '',
            dt3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Sohn der rechten Hand, Glückskind”. Im AT ist Benjamin der jüngste Sohn von Jakob und Rahel. Weitere Formen: Benjomen, Baryamin',
            es1: 'ܒܢܚܐܝܠ',
            es3: '',
            ar1: 'ܒܢܚܐܝܠ',
            dt1: 'Ben Hajil',
            ar2: 'ܒܶܢܚܰܐܝܺܠ'
        },
        {
            id: '222',
            ar1: 'ܒܪܘܬܐ',
            es2: 'ܒܪܘܬܐ',
            es1: 'ܒܪܘܬܐ',
            dt2: '',
            dt1: 'Berota',
            dt3: '',
            bedeutung: 'weibl., Berota ist eine aramäische Stadt, die von David geplündert wurde. Weitere Formen: Bruti, Berotai',
            es3: 'ܒܪܘܬܝ',
            ar2: 'ܒܶܪܳܘܬܰܐ',
            ar3: 'ܒܪܽܘܬܺܝ'
        },
        {
            dt3: '',
            dt2: '',
            dt1: 'Hadbschabo',
            es2: 'ܚܕܒܫܒܐ',
            ar1: 'ܚܕܒܫܒܐ',
            ar2: 'ܚܰܕܒܫܰܒܳܐ',
            es1: 'ܚܕܒܫܒܐ',
            id: '415',
            bedeutung: 'männl., syrischen Ursprungs; “Sonntag, ein Tag nach dem Schabtho/Sabbat, erster Tag in der Woche”. Hadbschabo ist ein Heiliger (4. Jh.); Festtag: 20. Juli. Weitere Formen: Hadbschaba, Huschabo, Huschaba, Hawschab',
            es3: '',
            ar3: 'ܚܽܘܫܰܒܳܐ'
        },
        {
            dt1: 'Luse',
            es3: 'ܠܘܙܐ',
            dt2: '',
            ar2: 'ܠܽܘܣܶܐ',
            ar3: 'ܠܽܘܙܶܐ',
            dt3: '',
            es1: 'ܠܘܣܐ',
            id: '1828',
            ar1: 'ܠܘܣܐ',
            es2: 'ܠܘܣܐ',
            bedeutung: 'männl., „der König ist barmherzig“. Malakhanna war ein König der Aramäer von Hah in Turabdin'
        },
        {
            es3: '',
            bedeutung: 'weibl., “Höhle”',
            es2: 'ܕܩܠܝܬܐ',
            ar3: '',
            dt1: 'Daqlita',
            ar1: 'ܕܩܠܝܬܐ',
            es1: 'ܕܩܠܝܬܐ',
            ar2: 'ܕܰܩܠܺܝܬܰܐ',
            id: '261',
            dt3: '',
            dt2: ''
        },
        {
            es1: 'ܦܢܩܝܬܐ',
            dt3: '',
            es2: 'ܦܢܩܝܬܐ',
            ar3: '',
            id: '1517',
            dt1: 'Fanqita',
            es3: '',
            bedeutung: 'weibl., “Parabel, Allegorie, Rätsel, Gleichnis“, weitere Formen: Peleta, Peletha',
            ar2: 'ܦܰܢܩܺܝܬܰܐ',
            dt2: '',
            ar1: 'ܦܢܩܝܬܐ'
        },
        {
            dt3: '',
            dt1: 'Akifuta',
            bedeutung: 'weibl., “Eifer, Fleiß, Fürsorge”',
            ar2: 'ܐܰܟܺܝܦܽܘܬܰܐ',
            dt2: '',
            ar1: 'ܐܟܝܦܘܬܐ',
            es3: '',
            es1: 'ܐܟܝܦܘܬܐ',
            id: '67',
            ar3: '',
            es2: 'ܐܟܝܦܘܬܐ'
        },
        {
            es3: '',
            dt3: '',
            ar1: 'ܐܡܕܐ',
            es2: 'ܐܡܕܐ',
            bedeutung: 'weibl., “ﬂüchten, entﬂiehen, entkommen, gerettet werden, erreichen, ausreichen, fähig sein”. Amed ist der syrisch-aramäische Name der heutigen Stadt “Diyarbakir”',
            ar3: '',
            es1: 'ܐܡܕܐ',
            dt1: 'Ameda',
            ar2: 'ܐܰܡܶܕܰܐ',
            dt2: '',
            id: '73'
        },
        {
            dt1: 'Gayo',
            bedeutung: 'männl., “herrlich, prächtig, schön, rein, nett”',
            es2: 'ܓܐܝܐ',
            dt3: '',
            dt2: '',
            es3: '',
            es1: 'ܓܐܝܐ',
            ar3: '',
            id: '367',
            ar1: 'ܓܐܝܐ',
            ar2: 'ܓܰܐܝܳܐ'
        },
        {
            dt3: '',
            bedeutung: 'weibl. Form von Paulus',
            id: '1985',
            ar3: '',
            dt1: 'Odom',
            es3: '',
            ar2: 'ܐܳܕܳܡ',
            ar1: 'ܐܕܡ',
            es2: 'ܐܕܡ',
            es1: 'ܐܕܡ',
            dt2: ''
        },
        {
            ar1: 'ܒܢܝܐ',
            id: '219',
            dt3: '',
            dt2: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe baut auf”',
            es1: 'ܒܢܝܐ',
            es2: 'ܒܢܝܐ',
            ar2: 'ܒܶܢܰܝܰܐ',
            dt1: 'Benaja',
            ar3: '',
            es3: ''
        },
        {
            dt3: '',
            dt2: '',
            es3: '',
            es1: 'ܙܕܩܝܐ',
            bedeutung: 'weibl., Sieg; weitere Formen: Zokhuta, Sokuta',
            dt1: 'Zedakija',
            ar1: 'ܙܕܩܝܐ',
            id: '2357',
            ar3: '',
            es2: 'ܙܕܩܝܐ',
            ar2: 'ܙܶܕܰܩܝܰܐ'
        },
        {
            ar3: 'ܝܰܐܺܝܪܽܘܣ',
            dt1: 'Jairus',
            dt2: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott habe Barmherzigkeit”; weitere Formen: Irahmel',
            id: '1712',
            ar1: 'ܝܘܐܪܫ',
            dt3: '',
            es2: 'ܝܘܐܪܫ',
            es3: 'ܝܐܝܪܘܣ',
            es1: 'ܝܘܐܪܫ',
            ar2: 'ܝܽܘܐܳܪܳܫ'
        },
        {
            ar3: '',
            es3: '',
            dt2: '',
            es1: 'ܒܟܘܣ',
            es2: 'ܒܟܘܣ',
            bedeutung: 'männl., Bakos und Sarkis sind zwei Heilige (279-336), die in der syrisch-orthodoxen Kirche gemeinsam verehıt werden; Festtag: 7. Oktober. Weitere Formen: Bakus',
            dt3: '',
            dt1: 'Bakos',
            id: '146',
            ar2: 'ܒܰܟܳܘܣ',
            ar1: 'ܒܟܘܣ'
        },
        {
            dt2: '',
            es3: 'ܚܘܠܡܢܐ',
            ar2: 'ܚܽܘܠܡܳܢܳܐ',
            ar3: 'ܚܽܘܠܡܰܢܰܐ',
            ar1: 'ܚܘܠܡܢܐ',
            bedeutung: 'männl., “Gesundheit, Genesung”; weitere Formen: Hulmon, Hulmana',
            id: '491',
            dt1: 'Hulmono',
            es1: 'ܚܘܠܡܢܐ',
            dt3: '',
            es2: 'ܚܘܠܡܢܐ'
        },
        {
            bedeutung: 'weibl., “Botschat, Verkündigung”; weitere Formen: Sbarta',
            es3: '',
            ar2: 'ܣܰܪܦܺܝܳܘܢ',
            dt3: '',
            es2: 'ܣܪܦܝܘܢ',
            es1: 'ܣܪܦܝܘܢ',
            ar1: 'ܣܪܦܝܘܢ',
            dt2: '',
            ar3: '',
            id: '2099',
            dt1: 'Sarafiyon'
        },
        {
            dt3: 'Davud',
            es1: 'ܕܘܝܕ',
            bedeutung: 'männl., “Palme”',
            dt2: 'Davut',
            ar1: 'ܕܘܝܕ',
            id: '1448',
            es3: '',
            ar2: 'ܕܰܘܺܝܕ',
            dt1: 'David',
            es2: 'ܕܘܝܕ',
            ar3: ''
        },
        {
            id: '895',
            ar3: '',
            es3: '',
            bedeutung: 'weibl., “erfolgreiche, glückliche Frau” oder “eine Frau, die zerreißt, zersplittert”. Saliha oder Salah ist ein Dorf in Turabdin. Weitere Formen: Salah',
            dt3: '',
            dt1: 'Saliha',
            ar1: 'ܨܠܝܚܐ',
            ar2: 'ܨܰܠܺܝܚܰܐ',
            dt2: '',
            es2: 'ܨܠܝܚܐ',
            es1: 'ܨܠܝܚܐ'
        },
        {
            es2: 'ܒܪܬܐܝܠ',
            ar1: 'ܒܪ̱ܬܐܝܠ',
            ar3: '',
            dt2: '',
            ar2: 'ܒܰܪ̱ܬܐܝܺܠ',
            bedeutung: 'weibl., “Tochter des Wassers”',
            es1: 'ܒܪܬܐܝܠ',
            es3: '',
            dt3: '',
            dt1: 'Bathil',
            id: '1386'
        },
        {
            es2: 'ܦܪܢܝܬܐ',
            bedeutung: 'weibl., siehe Euphrat',
            dt2: '',
            dt1: 'Fernita',
            dt3: '',
            ar2: 'ܦܶܪܢܺܝܬܰܐ',
            es1: 'ܦܪܢܝܬܐ',
            id: '1526',
            ar1: 'ܦܪܢܝܬܐ',
            es3: '',
            ar3: ''
        },
        {
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott hat geholfen” Im AT ist Eliozor Sohn Aarons und Priester. Weitere Formen: Eleasar',
            dt2: 'Ilhanan',
            es3: '',
            es2: 'ܐܠܚܢܢ',
            es1: 'ܐܠܚܢܢ',
            ar2: 'ܐܶܠܰܚܢܰܢ',
            dt1: 'Elhanan',
            dt3: '',
            ar1: 'ܐܠܚܢܢ',
            ar3: '',
            id: '1480'
        },
        {
            dt3: '',
            ar2: 'ܝܽܘܪܒܰܥܰܡ',
            ar1: 'ܝܘܪܒܥܡ',
            es1: 'ܝܘܪܒܥܡ',
            es3: 'ܝܘܪܒܥܡ',
            es2: 'ܝܘܪܒܥܡ',
            dt1: 'Jerobeam',
            ar3: 'ܝܽܘܪܰܒܥܰܡ',
            id: '535',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “das Volk möge zunehmen”. Im AT ist Jerobeam Sohn des Nebat aus dem Stamm Ephraim/Efraim als Jerobeam I. König (926 - 907 v.Chr.) des unabhängigen Nordreiches Israel. Weitere Formen: Jurabam',
            dt2: ''
        },
        {
            ar2: 'ܨܶܡܚܳܐ',
            dt3: '',
            bedeutung: '',
            ar3: '',
            dt1: 'Semho',
            ar1: 'ܨܡܚܐ',
            id: '2178',
            dt2: '',
            es1: 'ܨܡܚܐ',
            es2: 'ܨܡܚܐ',
            es3: ''
        },
        {
            ar2: 'ܝܰܓܰܪܣܰܗܰܕܽܘܬܰܐ',
            dt1: 'Jegar Sahaduta',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäischen Ursprungs; “Steinhaufen-Zeuge”',
            ar1: 'ܝܓܪܣܗܕܘܬܐ',
            es3: '',
            es1: 'ܝܓܪܣܗܕܘܬܐ',
            dt2: '',
            dt3: '',
            id: '529',
            es2: 'ܝܓܪܣܗܕܘܬܐ',
            ar3: ''
        },
        {
            id: '1538',
            ar1: 'ܓܒܒܪܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Glück”. Im AT begegnet Gad als aramäischer Gott des Glücks, als Sohn Jakobs und der Silpa und als ein Prophet. Weitere Formen: Gado',
            dt1: 'Gabbara',
            dt2: '',
            es3: 'ܟܦܦܪܐ',
            es1: 'ܓܒܒܪܐ',
            dt3: '',
            es2: 'ܓܒܒܪܐ',
            ar3: 'ܟܰܦܦܰܪܰܐ',
            ar2: 'ܓܰܒܒܰܪܰܐ'
        },
        {
            es2: 'ܗܕܕܥܙܪ',
            es1: 'ܗܕܕܥܙܪ',
            dt1: 'Hadadeser',
            ar1: 'ܗܕܕܥܙܪ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Hadad ist Hilfe”, “Hadad hilft (hat geholfen)”. Hadadeser (11. Jh. V. Chr.) war Sohn des Rehob, des aramäischen Königs von Aram-Zoba (Libanon). Weitere Formen: Hadadezer',
            dt3: '',
            ar3: 'ܚܰܕܰܕܥܰܙܰܪ',
            ar2: 'ܗܰܕܰܕܥܰܕܰܪ',
            dt2: '',
            es3: 'ܚܕܕܥܙܪ',
            id: '412'
        },
        {
            dt1: 'Thomas',
            dt3: '',
            es2: 'ܬܐܘܡܐܣ',
            ar1: 'ܬܐܘܡܐܣ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott ist gnädig, Gott ist gut”. Im AT ist Tobia der fromme Sohn, der seinen Vater geheilt hat; nach ihm ist ein alttestamentliches Buch (2. Jh. v.Chr.) benannt. Weitere Formen: Tobit, Tubit, Tobias, Tobijah, Tobijahu',
            dt2: 'Tomas',
            ar3: '',
            es3: '',
            es1: 'ܬܐܘܡܐܣ',
            ar2: 'ܬܳܐܘܡܰܐܣ',
            id: '2246'
        },
        {
            dt3: '',
            ar2: 'ܫܰܡܰܫܢܽܘܪܺܝ',
            es2: 'ܫܡܫܢܘܪܝ',
            dt1: 'Schamaschnuri',
            bedeutung: 'weibl. Form von Scharbel',
            ar3: '',
            es3: '',
            ar1: 'ܫܡܫܢܘܪܝ',
            es1: 'ܫܡܫܢܘܪܝ',
            dt2: '',
            id: '2129'
        },
        {
            ar1: 'ܣܪܗܒ',
            ar2: 'ܣܰܪܗܶܒ',
            id: '2103',
            dt1: 'Sarheb',
            ar3: '',
            dt2: '',
            dt3: '',
            es1: 'ܣܪܗܒ',
            es2: 'ܣܪܗܒ',
            bedeutung: 'männl., “reinigen, säubem, putzen”',
            es3: ''
        },
        {
            dt2: '',
            es1: 'ܝܕܠܦ',
            dt3: '',
            ar1: 'ܝܕܠܦ',
            id: '1734',
            es2: 'ܝܕܠܦ',
            ar2: 'ܝܺܕܠܰܦ',
            dt1: 'Jidlaf',
            ar3: 'ܝܰܪܠܰܦ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “der Herr ist gnädig” oder “Jahwe hat Gnade erwiesen“. Im NT existieren zwei Personen unter diesem Namen, Johannes der Täufer und Johannes, einer der 12 Apostel Jesu Christi. Weitere Formen: Yuhannon, Yuhannun, Johannun, Johannon, Johanan, Hamıa, Hane, Hanne, Hanno',
            es3: 'ܝܪܠܦ'
        },
        {
            dt2: 'Habibto',
            ar1: 'ܚܒܝܒܬܐ',
            ar3: '',
            ar2: 'ܚܰܒܺܝܒܬܰܐ',
            dt3: '',
            dt1: 'Habibta',
            bedeutung: 'männl., “der Donnerer“. Hadad mit dem Beinamen Rammon galt bei den vorchristlichen Aramäern von Aram-Naharaim als “Wetter- (Gewitter-) und Sturmgott”. Weitere Formen: Hodod, Adad, Hadodo',
            id: '1590',
            es1: 'ܚܒܝܒܬܐ',
            es2: 'ܚܒܝܒܬܐ',
            es3: ''
        },
        {
            es2: 'ܝܫܘܥܝܗܒ',
            es1: 'ܝܫܘܥܝܗܒ',
            bedeutung: 'männl., “Jesus hat gegeben”; weitere Formen: Jeschuyahb, Jeschujab, Jeschuyab',
            id: '541',
            ar2: 'ܝܶܫܽܘܥܝܰܗܒ',
            ar1: 'ܝܫܘܥܝܗܒ',
            dt2: '',
            ar3: '',
            es3: '',
            dt3: '',
            dt1: 'Jeschujahb'
        },
        {
            es3: '',
            es2: 'ܝܬܪܘܬܐ',
            dt3: '',
            ar1: 'ܝܪܬܘܬܐ',
            ar3: '',
            dt1: 'Yortuta',
            ar2: 'ܝܳܪܬܽܘܬܰܐ',
            dt2: '',
            es1: 'ܝܬܪܘܬܐ',
            id: '2332',
            bedeutung: 'weibl., arabischen Ursprungs; “Blume”, weitere Formen: Zahre, Sahre'
        },
        {
            dt1: 'Hariata',
            bedeutung: 'weibl., Hariata war eine Stadt in Alt-Syrien',
            id: '446',
            dt3: '',
            ar1: 'ܚܪܝܐܬܐ',
            es2: 'ܚܪܝܐܬܐ',
            es1: 'ܚܪܝܐܬܐ',
            dt2: '',
            es3: 'ܚܪܝܬܐ',
            ar2: 'ܚܰܪܝܰܐܬܰܐ',
            ar3: 'ܚܰܪܰܝܬܰܐ'
        },
        {
            ar1: 'ܠܝܫܡܥ',
            es3: '',
            dt1: 'Lischmah',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs. Im AT ist Maacha Sohn Nahors, des Bruders Abrahams; nach dem AT sind die Maachiter ein aramäisches Volk, sie lebten östlich des Oberlaufs des Jordans an der Grenze zu Israel',
            es2: 'ܠܝܫܡܥ',
            dt3: '',
            ar2: 'ܠܺܝܫܡܰܥ',
            es1: 'ܠܝܫܡܥ',
            dt2: '',
            id: '1821',
            ar3: ''
        },
        {
            dt1: 'Naaman',
            dt3: '',
            ar2: 'ܢܰܥܡܰܢ',
            es3: '',
            id: '725',
            ar3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “anmutig”. Im AT ist Naaman Sohn Benjamins; nach dem AT und NT ist Naaman ein Aramäer oder Syrer, Heerführer des Königs von Aram-Damaskus, der unter vielen Aussätzigen ausgewählt und durch den Propheten Elischa geheilt wird. Weitere Formen: Naamon, Naamo, Naim',
            es1: 'ܢܥܡܢ',
            es2: 'ܢܥܡܢ',
            dt2: '',
            ar1: 'ܢܥܡܢ'
        },
        {
            ar3: '',
            bedeutung: 'männl., “Vorbau, Vordach, Vorhalle, Portal” oder “ﬂechten, geﬂochtenes Haar”. Stadt in Aram Beth-Nahrin (= Mesopotamien)',
            dt3: '',
            dt1: 'Sarug',
            es2: 'ܣܪܘܓ',
            ar1: 'ܣܪܘܓ',
            ar2: 'ܣܰܪܽܘܓ',
            es3: '',
            dt2: '',
            es1: 'ܣܪܘܓ',
            id: '913'
        },
        {
            dt2: 'Brichto',
            es3: '',
            es1: 'ܒܪܝܟ݂ܬܐ',
            ar1: 'ܒܪܟ݂ܬܐ',
            dt1: 'Brikhta',
            es2: 'ܒܪܝܟ݂ܬܐ',
            id: '235',
            bedeutung: 'weibl. Form zu Brikho',
            ar3: '',
            ar2: 'ܒܪܺܟ݂ܬܰܐ',
            dt3: ''
        },
        {
            ar2: 'ܟܠܰܡܺܝܕܰܐ',
            es1: 'ܟܠܡܝܕܐ',
            dt3: '',
            es3: '',
            bedeutung: 'weibl., griechischen Ursprungs; “0berkleid, Mantel, Kriegsmantel, Amtskleid, königliche Tracht”',
            ar3: '',
            ar1: 'ܟܠܡܝܕܐ',
            id: '601',
            dt2: '',
            dt1: 'Klamida',
            es2: 'ܟܠܡܝܕܐ'
        },
        {
            id: '750',
            es2: 'ܢܪܘܙ',
            es3: '',
            ar1: 'ܢܪܘܙ',
            dt2: '',
            es1: 'ܢܪܘܙ',
            ar2: 'ܢܰܪܘܶܙ',
            ar3: '',
            bedeutung: 'männl., “freuen wir uns”',
            dt1: 'Narwez',
            dt3: ''
        },
        {
            es2: 'ܒܕܪܘܣ',
            bedeutung: '',
            dt2: 'Budrus',
            id: '1393',
            es1: 'ܒܕܪܘܣ',
            ar1: 'ܒܕܪܘܣ',
            dt3: '',
            ar3: '',
            ar2: 'ܒܶܕܪܳܘܣ',
            dt1: 'Bedrus',
            es3: ''
        },
        {
            bedeutung: 'weibl., “Lanze, Sorge, Idee, Meinung”; weitere Formen: Marnitha',
            es2: 'ܡܪܓܢܝܬܐ',
            dt3: '',
            id: '1854',
            ar1: 'ܡܪܓܢܝܬܐ',
            dt1: 'Margonita',
            es3: '',
            es1: 'ܡܪܓܢܝܬܐ',
            dt2: '',
            ar3: '',
            ar2: 'ܡܰܪܓܳܢܺܝܬܰܐ'
        },
        {
            id: '1888',
            es2: 'ܡܝܟ݂ܐ',
            dt2: '',
            es1: 'ܡܝܟ݂ܐ',
            dt1: 'Micha',
            ar2: 'ܡܺܝܟ݂ܳܐ',
            ar3: 'ܡܺܝܟ݂ܰܐ',
            es3: 'ܡܝܟ݂ܐ',
            dt3: '',
            bedeutung: '',
            ar1: 'ܡܝܟ݂ܐ'
        },
        {
            id: '1607',
            dt1: 'Haduta',
            dt3: '',
            bedeutung: 'männl., “fünfzig”',
            ar1: 'ܚܕܘܬܐ',
            ar2: 'ܚܰܕܽܘܬܰܐ',
            ar3: '',
            es1: 'ܚܕܘܬܐ',
            es2: 'ܚܕܘܬܐ',
            es3: '',
            dt2: 'Hadutha'
        },
        {
            es2: 'ܕܡܝ',
            dt2: '',
            ar2: 'ܕܰܡܺܝ',
            dt1: 'Dami',
            bedeutung: 'männl., “ähnlich sein, vergleichen, symbolisieren”',
            ar1: 'ܕܡܝ',
            ar3: '',
            es1: 'ܕܡܝ',
            id: '255',
            dt3: '',
            es3: ''
        },
        {
            dt2: '',
            es2: 'ܐܒܪܗܛ',
            ar2: 'ܐܰܒܪܰܗܰܛ',
            dt3: '',
            es1: 'ܐܒܪܗܛ',
            ar1: 'ܐܒܪܗܛ',
            bedeutung: 'männl., Abrahat war Sohn des Ma`nu und König von Edessa (240-242); weitere Formen: Frahad, Frahat',
            es3: '',
            ar3: '',
            dt1: 'Abrahat',
            id: '1204'
        },
        {
            ar3: '',
            dt2: 'Malak',
            dt1: 'Melek',
            ar1: 'ܡܠܟ',
            es2: 'ܡܠܟ',
            id: '694',
            ar2: 'ܡܶܠܶܟ',
            bedeutung: '',
            dt3: '',
            es3: '',
            es1: 'ܡܠܟ'
        },
        {
            es2: 'ܟܪܝܘܬܐ',
            ar2: 'ܟܰܪܝܽܘܬܰܐ',
            ar3: '',
            es3: '',
            es1: 'ܟܪܝܘܬܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäischen Ursprungs; “Stein, Fels”. Im NT ist Kipho/Kepha (grch. Petros) Beiname des Apostels Simon; Festtag: 29. Juni; weitere Formen: Kepha',
            ar1: 'ܟܪܝܘܬܐ',
            dt1: 'Karyuta',
            id: '1771',
            dt3: '',
            dt2: ''
        },
        {
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “der Leuchtende” Im AT sind die Seraphim (im Plural) eine Engelstufe',
            dt3: '',
            es3: '',
            ar2: 'ܣܶܪܰܦ',
            es1: 'ܣܪܦ',
            ar1: 'ܣܪܦ',
            dt1: 'Seraph',
            ar3: '',
            es2: 'ܣܪܦ',
            dt2: '',
            id: '987'
        },
        {
            es1: 'ܪܒܢ',
            dt1: 'Raban',
            ar3: '',
            es2: 'ܪܒܢ',
            es3: '',
            id: '2013',
            dt3: '',
            bedeutung: 'männl., “unser Herr, Meister, Lehrer, Rabbi”; weitere Formen: Rabbuna, Rabuno',
            ar2: 'ܪܰܒܰܢ',
            ar1: 'ܪܒܢ',
            dt2: ''
        },
        {
            es1: 'ܥܬܗ',
            dt1: 'Athe',
            dt2: '',
            es3: '',
            bedeutung: ' männl., “fest, steif, hart,massiv, dicht, geschlossen”',
            es2: 'ܥܬܗ',
            ar3: '',
            id: '1299',
            dt3: '',
            ar2: 'ܥܰܬܶܗ',
            ar1: 'ܥܬܗ'
        },
        {
            ar1: 'ܙܗܪܐ',
            es3: '',
            dt1: 'Zahra',
            dt2: 'Sahre',
            ar3: '',
            es2: 'ܙܗܪܐ',
            dt3: '',
            es1: 'ܙܗܪܐ',
            id: '2341',
            ar2: 'ܙܰܗܪܰܐ',
            bedeutung: 'männl., König von Hamath (8. Jh. v.Chr.); weitere Formen: Zakkur, Sakir'
        },
        {
            es1: 'ܢܩܝܝܐ',
            ar1: 'ܢܩܝܝܐ',
            dt1: 'Naqija',
            es2: 'ܢܩܝܝܐ',
            ar3: '',
            es3: '',
            dt2: '',
            id: '747',
            ar2: 'ܢܰܩܺܝܝܳܐ',
            bedeutung: 'weibl., aramäische Prinzessin in Syrien (7. Jh. v. Chr.); weitere Formen: Nakija',
            dt3: ''
        },
        {
            id: '395',
            ar2: 'ܓܪܶܝܓܳܘܪܺܝܳܘܣ',
            dt2: '',
            ar3: '',
            ar1: 'ܓܪܝܓܘܪܝܘܣ',
            dt1: 'Gregorius',
            es1: 'ܓܪܝܓܘܪܝܘܣ',
            bedeutung: 'männl., aus dem Griechischen übernommener Vorname; “der Wachsame”; einer der Beinamen für syrische Bischöfe. Weitere Formen: Gregor',
            dt3: '',
            es2: 'ܓܪܝܓܘܪܝܘܣ',
            es3: ''
        },
        {
            dt3: '',
            es3: '',
            ar3: '',
            dt1: 'Hiba',
            id: '476',
            ar2: 'ܗܺܝܒܰܐ',
            ar1: 'ܗܝܒܐ',
            bedeutung: 'männl., “der Gegebene”. Hiba (†457), ein Anhänger des syrisch-nestorianischen Glaubens, war Bischof von Edessa (Urhoy) und Nachfolger des Rabula. Weitere Formen: Iba, Hibai, Hibo, Yihibo, Yihiba',
            dt2: '',
            es1: 'ܗܝܒܐ',
            es2: 'ܗܝܒܐ'
        },
        {
            dt3: '',
            bedeutung: 'männl., “das Leben”. Haja(n) war ein König der Aramäer von Samal-Jadi (ab ca. 870/860 v.Chr.). Weitere Formen: Hajo, Haya, Hayo, Hayya',
            dt1: 'Hadi',
            ar1: 'ܗܕܝ',
            ar3: '',
            id: '1603',
            dt2: '',
            es2: 'ܗܕܝ',
            es1: 'ܗܕܝ',
            ar2: 'ܗܰܕܺܝ',
            es3: ''
        },
        {
            ar2: 'ܐܰܒܰܐ',
            ar1: 'ܐܒܐ',
            id: '1179',
            ar3: '',
            dt1: 'Aba',
            es3: '',
            es1: 'ܐܒܐ',
            dt2: '',
            bedeutung: 'männl., “Vater”;  weitere Formen:  Abo',
            es2: 'ܐܒܐ',
            dt3: ''
        },
        {
            ar1: 'ܫܘܦܪܐ',
            dt2: '',
            dt1: 'Schufro',
            ar3: '',
            ar2: 'ܫܽܘܦܪܳܐ',
            dt3: '',
            id: '976',
            bedeutung: 'männl., “Schönheit, Eleganz”',
            es2: 'ܫܘܦܪܐ',
            es3: '',
            es1: 'ܫܘܦܪܐ'
        },
        {
            es1: 'ܢܣܡܐ',
            ar3: '',
            id: '752',
            dt1: 'Nasme',
            es3: '',
            bedeutung: 'weibl., abgeleitet von Neschmo - Neschma “Atem, Atmung”',
            dt3: '',
            es2: 'ܢܣܡܐ',
            dt2: '',
            ar2: 'ܢܰܣܡܶܐ',
            ar1: 'ܢܣܡܐ'
        },
        {
            ar2: 'ܐܰܕܳܘܢܺܝܪܰܡ',
            dt2: '',
            es2: 'ܐܕܘܢܝܪܡ',
            es1: 'ܐܕܘܢܝܪܡ',
            id: '41',
            dt1: 'Adoniram',
            ar3: '',
            ar1: 'ܐܕܘܢܝܪܡ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “mein Herr ist erhaben”. Im AT begegnen Adoniram, Sohn des Abda, und Adoniram, Sohn des Toi, des Königs von Hamat (11. Jh.v.Clır.). Weitere Formen: Hadoram',
            es3: '',
            dt3: ''
        },
        {
            ar3: '',
            es2: 'ܓܫܬܝܢܐ',
            dt2: '',
            es3: '',
            id: '1565',
            ar2: 'ܓܶܫܬܺܝܰܢܰܐ',
            es1: 'ܓܫܬܝܢܐ',
            bedeutung: 'weibl., “Elster”; weitere Formen: Giyola',
            dt3: '',
            dt1: 'Geschtiyana',
            ar1: 'ܓܫܬܝܢܐ'
        },
        {
            dt2: '',
            ar2: 'ܛܪܺܝܡܰܐ',
            ar1: 'ܛܪܝܡܐ',
            dt3: '',
            bedeutung: 'weibl., “Bime”, “Feuer, Hitze, Wärme”',
            es3: '',
            es2: 'ܛܪܝܡܐ',
            dt1: 'Trima',
            es1: 'ܛܪܝܡܐ',
            ar3: '',
            id: '1073'
        },
        {
            ar3: '',
            ar2: 'ܬܰܘܕܐܝܺܠ',
            id: '2232',
            es2: 'ܬܘܕܐܝܠ',
            dt1: 'Taudil',
            es1: 'ܬܘܕܐܝܠ',
            dt2: '',
            ar1: 'ܬܘܕܐܝܠ',
            bedeutung: 'männl., aus dem Griechischen übernommener Vorname; “Gottesgabe, -geschenk”; weitere Formen: Theodosios',
            es3: '',
            dt3: ''
        },
        {
            ar2: 'ܣܰܪܶܐ',
            id: '2100',
            ar3: '',
            es3: '',
            bedeutung: 'weibl., “die Begehrte” oder “sie ist erwünscht”; weitere Formen: Sbitha',
            dt1: 'Sare',
            es2: 'ܣܪܐ',
            ar1: 'ܣܪܐ',
            dt3: '',
            es1: 'ܣܪܐ',
            dt2: ''
        },
        {
            ar2: 'ܢܰܪܓܺܝܠ',
            dt1: 'Nargil',
            dt3: '',
            ar3: '',
            ar1: 'ܢܪܓܝܠ',
            es3: '',
            es2: 'ܢܪܓܝܠ',
            id: '1938',
            dt2: 'Nargilo',
            es1: 'ܢܪܓܝܠ',
            bedeutung: ''
        },
        {
            dt2: '',
            es2: 'ܟܡܘܪܓܘ',
            dt3: '',
            dt1: 'Komurcu',
            ar2: 'ܟܳܡܽܘܪܓܽܘ',
            es1: 'ܟܡܘܪܓܘ',
            id: '602',
            bedeutung: '',
            ar3: '',
            es3: '',
            ar1: 'ܟܡܘܪܓܘ'
        },
        {
            dt1: 'Daisan',
            id: '249',
            bedeutung: 'männl., Daisan (gr. Skirtos) war ein Fluß in Edessa. Weitere Formen: Daison, Dayson, Daysan',
            ar2: 'ܕܰܝܨܳܢ',
            es1: 'ܕܝܨܢ',
            dt3: '',
            es2: 'ܕܝܨܢ',
            dt2: 'Daysan',
            ar1: 'ܕܝܨܢ',
            ar3: '',
            es3: ''
        },
        {
            dt3: '',
            dt2: '',
            es3: '',
            es1: 'ܫܘܦܢܝܢ',
            id: '2167',
            dt1: 'Schufnin',
            ar2: 'ܫܽܘܦܢܺܝܢ',
            ar1: 'ܫܘܦܢܝܢ',
            ar3: '',
            bedeutung: 'weibl., “Matte, Sorge, Besorgtheit, Ornament, Interesse”; weitere Formen: Seftha',
            es2: 'ܫܘܦܢܝܢ'
        },
        {
            es3: '',
            bedeutung: 'männl., “Zeit, Moment”',
            es2: 'ܥܕܢ',
            dt1: 'Edon',
            dt2: '',
            ar3: '',
            es1: 'ܥܕܢ',
            ar1: 'ܥܕܢ',
            id: '293',
            dt3: '',
            ar2: 'ܥܶܕܳܢ'
        },
        {
            dt1: 'Barlebo',
            dt2: '',
            ar2: 'ܒܰܪܠܶܒܳܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäischen Ursprungs; “Sohn des Trostes”, “Sohn der tröstlichen Weissagung”. Im NT ist Barnaba der Beiname des Leviten Joseph. Dieser ist in der Tra-dition einer der 70 bzw. 72 Missionare',
            es3: '',
            es1: 'ܒܪܠܒܐ',
            ar1: 'ܒܪܠܒܐ',
            ar3: '',
            es2: 'ܒܪܠܒܐ',
            dt3: '',
            id: '1352'
        },
        {
            es3: 'ܕܒܘܪܐ',
            ar2: 'ܕܶܒܽܘܪܰܐ',
            dt2: 'Debura',
            es1: 'ܕܒܘܪܐ',
            ar1: 'ܕܒܘܪܐ',
            id: '1450',
            ar3: 'ܕܶܒܳܘܪܰܐ',
            dt1: 'Debora',
            es2: 'ܕܒܘܪܐ',
            bedeutung: 'männl., aus dem Griechischen (Demetrius) übernommener Vorname; “Sohn der Erdgöttin Demeter” Dimet oder Demetrius I. ist in der Geschichte König von Syrien (161-150 v. Chr.), sein Sohn Demetrius II. Herrschte dort 145-138 und 129-125 v.Chr.. Dimet gilt in der syrischen Kirche als Heiliger (†408); Festtag: 24. September',
            dt3: ''
        },
        {
            dt3: '',
            es1: 'ܕܝܠܐ',
            es2: 'ܕܝܠܐ',
            dt2: '',
            ar1: 'ܕܝܠܐ',
            es3: 'ܕܝܐܠܐ',
            dt1: 'Diyala',
            bedeutung: 'weibl., Fluss in Aram Beth-Nahrin (= Mesopotamien)',
            id: '276',
            ar2: 'ܕܺܝܰܠܰܐ',
            ar3: 'ܕܺܝܰܐܠܰܐ'
        },
        {
            dt2: '',
            dt3: '',
            id: '934',
            dt1: 'Schalyuta',
            bedeutung: 'weibl., “die Ruhe, Stille, Fassung, Gelassenheit, Rast, Pause”; weitere Formen: Schalyutha, Schaljutha, Schaljuta',
            ar1: 'ܫܠܝܘܬܐ',
            es2: 'ܫܠܝܘܬܐ',
            es3: '',
            es1: 'ܫܠܝܘܬܐ',
            ar2: 'ܫܰܠܝܽܘܬܰܐ',
            ar3: ''
        },
        {
            id: '1930',
            ar3: '',
            ar1: 'ܢܗܪܝܐ',
            bedeutung: 'männl., wohl “Wasserspritzer”. Ein Heiliger; weitere Formen: Narsay, Narsaj',
            es3: '',
            ar2: 'ܢܰܗܪܳܝܳܐ',
            dt1: 'Nahroyo',
            es1: 'ܢܗܪܝܐ',
            dt3: '',
            dt2: '',
            es2: 'ܢܗܪܝܐ'
        },
        {
            es1: 'ܚܙܩܐܝܠ',
            es2: 'ܚܙܩܐܝܠ',
            ar3: '',
            dt2: '',
            id: '1638',
            ar2: 'ܚܰܙܩܺܐܝܶܠ',
            dt1: 'Haskiel',
            ar1: 'ܚܙܩܐܝܠ',
            dt3: '',
            bedeutung: 'weibl., syrischen Ursprungs; “Apfel”',
            es3: ''
        },
        {
            ar2: 'ܐܰܚܶܒ',
            ar3: '',
            dt3: '',
            ar1: 'ܐܚܒ',
            dt1: 'Aheb',
            dt2: '',
            es3: '',
            id: '53',
            es2: 'ܐܚܒ',
            es1: 'ܐܚܒ',
            bedeutung: 'männl., “liebt, (er hat) geliebt”'
        },
        {
            bedeutung: 'männl., “der Herr hat gegeben” oder “gib, o Herr“. Einer von zwei Boten, die Abgar zu Jesus gesendet hat. Weitere Formen: Marjahb, Marjab, Maryab, Marihab',
            ar3: '',
            dt3: '',
            ar2: 'ܡܰܪܝܰܗܒ',
            es2: 'ܡܪܝܗܒ',
            ar1: 'ܡܪܝܗܒ',
            dt1: 'Maryahb',
            id: '681',
            es3: '',
            es1: 'ܡܪܝܗܒ',
            dt2: ''
        },
        {
            es2: 'ܐܠܝܐܬܐ',
            es1: 'ܐܠܝܐܬܐ',
            dt2: '',
            ar1: 'ܐܠܝܐܬܐ',
            ar3: '',
            ar2: 'ܐܶܠܺܝܐܰܬܰܐ',
            id: '298',
            dt3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “du bist mein Gott”',
            es3: '',
            dt1: 'Eliata'
        },
        {
            ar2: 'ܥܢܳܢܝܶܫܽܘܥ',
            bedeutung: 'männl., “Jesus hat mir geantwortet”; weitere Formen: Hnonjeschu',
            dt3: '',
            ar3: '',
            es1: 'ܥܢܢܝܫܘܥ',
            es3: '',
            id: '315',
            dt1: 'Enonjeschu',
            dt2: 'Hnonjeschu',
            ar1: 'ܥܢܢܝܫܘܥ',
            es2: 'ܥܢܢܝܫܘܥ'
        },
        {
            dt2: 'Iskender',
            ar3: 'ܐܶܣܟܰܢܕܰܪ',
            es1: 'ܣܟܢܕܪ',
            es3: 'ܐܣܟܢܕܪ',
            id: '70',
            es2: 'ܣܟܢܕܪ',
            bedeutung: 'männl., aus dem Griechischen übernommener Vorname; “der Männerabwehrende, der Schützer”. In der Geschichte ist Alexander der Große, Sohn des Philipp, König von Mazedonien (336-323). Weitere Formen: Alexander, Skender',
            dt3: 'Skandar',
            dt1: 'Alexander',
            ar2: 'ܣܟܰܢܕܰܪ',
            ar1: 'ܣܟܢܕܪ'
        },
        {
            es2: 'ܐܚܐܕܐܒܘܗܝ',
            ar2: 'ܐܰܚܳܐܕܰܐܒܽܘܗܝ',
            es3: '',
            dt3: '',
            dt1: 'Ahodabuy',
            dt2: 'Ahadabuhi',
            id: '62',
            ar1: 'ܐܚܐܕܐܒܘܗܝ',
            es1: 'ܐܚܐܕܐܒܘܗܝ',
            ar3: '',
            bedeutung: 'männl., “Bruder seines Vaters“. Ahodabuy (273-291) war Bischof von Arbela. Weitere Formen: Ahadabuhi'
        },
        {
            dt3: '',
            ar1: 'ܡܠܟܐ',
            id: '651',
            dt2: '',
            es1: 'ܡܠܟܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Könige”. Im NT sind Malki/Malke zwei Ahnherren Jesu. In der syrisch-orthodoxen Kirche von Antiochien ist Malke ein Heiliger (223-315); Festtag: 1. September',
            es2: 'ܡܠܟܐ',
            ar3: '',
            ar2: 'ܡܰܠܟܶܐ',
            es3: '',
            dt1: 'Malke'
        },
        {
            es1: 'ܐܝܠܐ',
            dt3: '',
            ar3: 'ܐܶܠ',
            dt2: 'El',
            ar1: 'ܐܝܠ',
            dt1: 'Il',
            bedeutung: 'männl., akkadischen Ursprungs; eventuell: “Gott/Macht”. Il oder El wird als höchster Gott von den Phöniziern und den heidnischen Aramäern verehrt. In der Bibel bzw. in der Patriarchenzeit wird der Gott Abrahams, Isaaks und Jakobs mit dem Hochgott Il/El gleichgesetzt. Weitere Formen: El',
            es2: 'ܐܝܠܐ',
            ar2: 'ܐܺܝܠ',
            es3: 'ܐܠ',
            id: '497'
        },
        {
            dt1: 'Robil',
            ar1: 'ܪܒܝܐܠ',
            dt2: 'Rubil',
            id: '2056',
            es3: 'ܪܘܒܝܠ',
            dt3: '',
            es1: 'ܪܒܝܐܠ',
            ar3: 'ܪܳܘܒܺܝܠ',
            ar2: 'ܪܒܝܠ',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gefährtin, Freundin”. Ruth ist die Hauptgestalt des nach Ihr benannten alttestamentlichen Buches und zugleich eine der Stammmütter des jüdischen Königshauses, nach dem NT eine Ahnfrau Jesu. Weitere Formen: Rut, Rohut',
            es2: 'ܪܒܝܐܠ'
        },
        {
            dt1: 'Afro',
            ar2: 'ܥܰܦܪܳܐ',
            ar3: '',
            dt2: '',
            es1: 'ܥܦܪܐ',
            dt3: '',
            ar1: 'ܥܦܪܐ',
            bedeutung: 'männl., “Staub, Erde, Boden”',
            es2: 'ܥܦܪܐ',
            es3: '',
            id: '44'
        },
        {
            dt2: '',
            es1: 'ܠܝܫܡܫ',
            ar1: 'ܠܝܫܡܫ',
            bedeutung: 'männl., “Sonnenaufgang, Ost, Osten, Morgenland, Orient”',
            ar2: 'ܠܺܝܫܡܰܫ',
            es3: '',
            id: '1822',
            dt1: 'Lischmasch',
            es2: 'ܠܝܫܡܫ',
            ar3: '',
            dt3: ''
        },
        {
            ar2: 'ܦܰܪܝܽܘܬܰܐ',
            bedeutung: 'männl., “unsere Fruchtbarkeit”; weitere Formen: Ferian, Feryan',
            dt1: 'Faryuta',
            es1: 'ܦܪܝܘܬܐ',
            dt3: '',
            es3: '',
            es2: 'ܦܪܝܘܬܐ',
            id: '1521',
            ar1: 'ܦܪܝܘܬܐ',
            ar3: '',
            dt2: ''
        },
        {
            dt1: 'Ofarta',
            ar3: '',
            ar2: 'ܐܳܦܰܪܬܰܐ',
            dt3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs. Nach dem AT aramäischer Nomadenstamm im Osten Babylons. Weitere Formen: Peqod',
            es3: '',
            ar1: 'ܐܦܪܬܐ',
            es1: 'ܐܦܪܬܐ',
            id: '1987',
            es2: 'ܐܦܪܬܐ',
            dt2: ''
        },
        {
            es3: '',
            dt2: '',
            es2: 'ܓܐܢ',
            id: '241',
            ar1: 'ܓܐܢ',
            dt3: '',
            es1: 'ܓܐܢ',
            ar3: '',
            bedeutung: '',
            dt1: 'Can',
            ar2: 'ܓܰܐܢ'
        },
        {
            id: '197',
            es1: 'ܒܣܝܡ',
            es3: 'ܒܣܣܝܡ',
            ar2: 'ܒܰܣܺܝܡ',
            es2: 'ܒܣܝܡ',
            ar1: 'ܒܣܝܡ',
            dt2: 'Bassim',
            dt1: 'Basim',
            dt3: '',
            bedeutung: 'männl., “lecker, süß, schmackhaft, nett”',
            ar3: 'ܒܰܣܣܺܝܡ'
        },
        {
            bedeutung: 'weibl., “[sie] hat auf mich gehört”',
            es1: 'ܠܝܫܡܥ',
            id: '633',
            es2: 'ܠܝܫܡܥ',
            ar2: 'ܠܺܝܫܡܰܥ',
            ar3: '',
            dt3: '',
            dt2: '',
            es3: '',
            ar1: 'ܠܝܫܡܥ',
            dt1: 'Lischmah'
        },
        {
            es3: 'ܬܪܚ',
            ar1: 'ܬܪܚ',
            ar2: 'ܬܰܪܰܚ',
            es2: 'ܬܪܚ',
            es1: 'ܬܪܚ',
            ar3: 'ܬܳܪܳܚ',
            id: '2229',
            dt3: '',
            dt1: 'Tarah',
            dt2: 'Tara',
            bedeutung: 'weibl., “Schatten, Phantasie, Illusion”; weitere Formen: Talonita'
        },
        {
            ar3: '',
            dt3: '',
            dt2: '',
            es3: '',
            es1: 'ܝܫܘܥܕܢܚܐ',
            ar1: 'ܝܫܘܥܕܢܚܐ',
            ar2: 'ܝܶܫܽܘܥܕܶܢܚܳܐ',
            dt1: 'Jeschudenho',
            es2: 'ܝܫܘܥܕܢܚܐ',
            bedeutung: 'männl., “Jesus ist der Aufgang”',
            id: '539'
        },
        {
            ar1: 'ܓܐܠܝ',
            ar2: 'ܓܰܐܠܺܝ',
            es2: 'ܓܐܠܝ',
            ar3: '',
            bedeutung: 'männl., “offenbart, verklärt”',
            id: '362',
            dt2: '',
            es1: 'ܓܐܠܝ',
            dt1: 'Gali',
            dt3: '',
            es3: ''
        },
        {
            es3: '',
            ar1: 'ܒܪܕܝܢܐ',
            dt1: 'Bardino',
            ar2: 'ܒܰܪܕܝܺܢܳܐ',
            dt2: '',
            dt3: '',
            ar3: '',
            bedeutung: 'männl., “Sohn der Kraft, Sohn der Macht”',
            id: '1342',
            es1: 'ܒܪܕܝܢܐ',
            es2: 'ܒܪܕܝܢܐ'
        },
        {
            ar3: '',
            es1: 'ܕܟܝܘܬܐ',
            dt1: 'Dakyouta',
            dt2: '',
            dt3: '',
            es3: '',
            ar1: 'ܕܟܝܘܬܐ',
            es2: 'ܕܟܝܘܬܐ',
            id: '252',
            ar2: 'ܕܰܟܝܽܘܬܰܐ',
            bedeutung: 'weibl., “Reinheit, Sauberkeit”; weitere Formen: Dakhyuta'
        },
        {
            es2: 'ܦܘܠܘܣ',
            dt1: 'Paulus',
            ar2: 'ܦܰܘܠܽܘܣ',
            ar1: 'ܦܘܠܘܣ',
            es3: 'ܦܘܠܘܣ',
            id: '803',
            dt3: '',
            ar3: 'ܦܰܘܠܳܘܣ',
            dt2: 'Paul',
            bedeutung: 'männl., aus dem Lateinischen übernommener Vorname; “der Kleine”. Im NT ist Paulus aus Tarsus nach seiner Bekehrung zum Apostel Christi geworden, “Apostel der Heiden”; Paul(us) hieß eigentlich Saul(us) (“der Erbetene”): “Vom Saulus zum Paulus werden”. Weitere Formen: Favlus, Paulos, Bulus',
            es1: 'ܦܘܠܘܣ'
        },
        {
            dt3: '',
            id: '1133',
            es3: 'ܝܘܢܢ',
            es2: 'ܝܘܢܐ',
            dt2: '',
            ar3: 'ܝܰܘܢܳܢ',
            ar2: 'ܝܰܘܢܳܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Taube”. Im AT ist Yawno(n) ein Prophet (zw. 780 und 750 v.Chr.) und Verfasser eines Buches. Weitere Formen: Jonas, Jona, Yawnon',
            ar1: 'ܝܘܢܐ',
            es1: 'ܝܘܢܐ',
            dt1: 'Yawno'
        },
        {
            es2: 'ܢܚܘܪ',
            es3: '',
            es1: 'ܢܚܘܪ',
            dt1: 'Nahor',
            dt3: '',
            ar3: '',
            dt2: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Schnarcher”. Im AT ist Nahor Erzvater nach der Sintﬂut, Sohn des Serug, Vater des Terach und Ahnherr Abrahams. Ferner trägt ein Bruder Abrahams, Sohn des Terach, diesen Namen. Nach Gen 22,20-24 ist der erstgenannte Nahor der Ahnherr der aramäischen Stämme. Nahor war auch eine Stadt in der Nähe von Haran in Paddan-Aram (Obermesopotamien), wo Abraham eine Frau (Rebekka) für seinen Sohn Isaak aussuchen ließ. Weitere Formen: Nohur',
            id: '737',
            ar2: 'ܢܰܚܽܘܪ',
            ar1: 'ܢܚܘܪ'
        },
        {
            id: '1321',
            ar1: 'ܒܚܝܪܐ',
            bedeutung: 'männl., “du bist mein Licht”',
            ar3: 'ܒܰܗܺܝܪܰܐ',
            ar2: 'ܒܰܚܺܝܪܰܐ',
            es2: 'ܒܚܝܪܐ',
            es3: 'ܒܗܝܪܐ',
            dt2: '',
            dt1: 'Bahira',
            es1: 'ܒܚܝܪܐ',
            dt3: ''
        },
        {
            id: '652',
            ar2: 'ܡܰܠܟܺܝܪܰܡ',
            dt3: '',
            dt2: '',
            es1: 'ܡܠܟܝܪܡ',
            ar3: '',
            es3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “mein König ist erhaben”. Im AT ist Malkiram Sohn des Königs Jojachin',
            ar1: 'ܡܠܟܝܪܡ',
            dt1: 'Malkiram',
            es2: 'ܡܠܟܝܪܡ'
        },
        {
            ar2: 'ܐܰܒܳܐ',
            es2: 'ܐܒܐ',
            ar1: 'ܐܒܐ',
            ar3: '',
            dt3: '',
            es3: '',
            dt1: 'Abo',
            dt2: '',
            id: '25',
            bedeutung: '',
            es1: 'ܐܒܐ'
        },
        {
            ar3: '',
            bedeutung: '',
            id: '579',
            dt2: '',
            es3: '',
            ar2: 'ܟܰܪܺܝܡ',
            es1: 'ܟܪܝܡ',
            dt3: '',
            dt1: 'Karim',
            es2: 'ܟܪܝܡ',
            ar1: 'ܟܪܝܡ'
        },
        {
            es1: 'ܦܪܝܐܠ',
            es2: 'ܦܪܝܐܠ',
            ar1: 'ܦܪܝܐܠ',
            dt1: 'Ferjal',
            bedeutung: 'weibl., vielleicht: “Frau, die fruchtbar ist”',
            dt3: '',
            dt2: 'Feryal',
            id: '340',
            ar3: '',
            es3: '',
            ar2: 'ܦܶܪܝܰܐܠ'
        },
        {
            id: '1548',
            dt2: '',
            ar2: 'ܓܰܕܕܺܐܝܶܠ',
            es2: 'ܓܕܐܝܠ',
            dt3: '',
            es3: '',
            es1: 'ܓܕܐܝܠ',
            ar1: 'ܓܕܐܝܠ',
            ar3: '',
            bedeutung: 'männl., “Schatz, Fiskus”',
            dt1: 'Gaddiel'
        },
        {
            es2: 'ܚܙܐܝܠ',
            ar3: '',
            es1: 'ܚܙܐܝܠ',
            es3: '',
            dt1: 'Hasael',
            dt2: '',
            ar2: 'ܚܰܙܰܐܝܶܠ',
            bedeutung: 'weibl., siehe Eva',
            dt3: '',
            id: '1634',
            ar1: 'ܚܙܐܝܠ'
        },
        {
            dt3: '',
            bedeutung: 'weibl., “Augenpupille”; weitere Formen: Bobto',
            es2: 'ܒܝܠܥܐ',
            dt1: 'Bilha',
            ar1: 'ܒܝܠܥܐ',
            id: '1410',
            dt2: 'Bilhan',
            ar2: 'ܒܺܝܠܥܰܐ',
            es1: 'ܒܝܠܥܐ',
            es3: 'ܒܝܠܥܐܢ',
            ar3: 'ܒܺܝܠܥܰܐܢ'
        },
        {
            es1: 'ܣܢܬܪܟ',
            es2: 'ܣܢܬܪܟ',
            ar2: 'ܣܰܢܰܬܪܰܟ',
            id: '904',
            ar1: 'ܣܢܬܪܟ',
            ar3: 'ܨܰܢܰܬܪܰܟ',
            es3: 'ܨܢܬܪܟ',
            dt3: '',
            dt2: '',
            bedeutung: 'männl., König von Hatra (300 - 340 n.Chr.); weitere Formen: Sanatrok',
            dt1: 'Sanatrak'
        },
        {
            ar2: 'ܐܽܘܪܚܰܐ',
            ar1: 'ܐܘܪܚܐ',
            es1: 'ܐܘܪܚܐ',
            ar3: '',
            dt1: 'Urha',
            bedeutung: 'weibl., “Land der Flüsse, der Ströme”',
            es2: 'ܐܘܪܚܐ',
            dt2: '',
            dt3: '',
            es3: '',
            id: '2279'
        },
        {
            es3: '',
            es1: 'ܝܬܪܐ',
            dt2: '',
            es2: 'ܝܬܪܐ',
            id: '2323',
            ar1: 'ܝܬܪܐ',
            ar2: 'ܝܰܬܪܳܐ',
            dt1: 'Yatro',
            bedeutung: 'weibl., “Erbe, Erbschaft, Anteil, Besitz”; weitere Formen: Yortutha, Jortuta, Jortutha',
            dt3: '',
            ar3: ''
        },
        {
            dt1: 'Bartolmai',
            id: '192',
            es2: 'ܒܪܬܘܠܡܝ',
            es1: 'ܒܪܬܘܠܡܝ',
            es3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäischen Ursprungs; “Sohn des Tolmai”. Im NT ist Bartolmai einer der 12 Apostel Jesu Christi. Weitere Formen: Bartholomäus, Bartulmai, Bartulmay, Bar Tolmai',
            ar2: 'ܒܰܪܬܳܘܠܡܰܝ',
            ar3: '',
            dt2: '',
            dt3: '',
            ar1: 'ܒܪܬܘܠܡܝ'
        },
        {
            dt3: '',
            dt1: 'Sophia',
            es2: 'ܣܘܦܝܐ',
            ar1: 'ܣܘܦܝܐ',
            ar3: '',
            bedeutung: 'weibl., aus dem griechischen “Sophia Weisheit”',
            es3: '',
            id: '1005',
            ar2: 'ܣܳܘܦܺܝܰܐ',
            dt2: 'Sofia',
            es1: 'ܣܘܦܝܐ'
        },
        {
            id: '341',
            es3: '',
            dt2: '',
            dt3: '',
            ar3: '',
            es1: 'ܦܪܢܝܬܐ',
            ar1: 'ܦܪܢܝܬܐ',
            bedeutung: 'weibl., “Hochzeitsgeschenk Geschenk, das der Bräutigam für die Braut kaut”; weitere Formen: Fernitha, Farnita',
            es2: 'ܦܪܢܝܬܐ',
            ar2: 'ܦܶܪܢܺܝܬܰܐ',
            dt1: 'Fernita'
        },
        {
            dt2: 'Hesekiel',
            es2: 'ܚܙܩܝܐܝܠ',
            dt3: '',
            es3: '',
            ar1: 'ܚܙܩܝܐܝܠ',
            bedeutung: '',
            ar3: '',
            dt1: 'Ezekiel',
            id: '326',
            es1: 'ܚܙܩܝܐܝܠ',
            ar2: 'ܚܙܩܝܐܠ'
        },
        {
            es3: '',
            dt1: 'Uschano',
            ar2: 'ܐܽܘܫܰܥܢܳܐ',
            dt2: '',
            ar3: '',
            id: '1100',
            bedeutung: 'männl., “Freude, Erlösung”, “Palmsonntag”; weitere Formen: Uschana',
            dt3: '',
            ar1: 'ܐܘܫܥܢܐ',
            es1: 'ܐܘܫܥܢܐ',
            es2: 'ܐܘܫܥܢܐ'
        },
        {
            dt3: '',
            es1: 'ܟܪܡܐ',
            es2: 'ܟܪܡܐ',
            dt2: '',
            es3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Weinberg”. Im AT ist Karmo/Karmi vierter und letzter Sohn Rubens (Rubel). Weitere Formen: Karmi',
            ar2: 'ܟܰܪܡܳܐ',
            dt1: 'Karmo',
            ar1: 'ܟܪܡܐ',
            ar3: '',
            id: '582'
        },
        {
            id: '253',
            es3: 'ܕܠܝܠܐ',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “die Wellenlockige, Verführerin”. Im AT ist Dalila (um 1200 v.Chr.) die Geliebte von Simson',
            dt1: 'Dalila',
            ar3: 'ܕܶܠܺܝܠܰܐ',
            dt3: '',
            ar2: 'ܕܰܠܺܝܠܰܐ',
            es2: 'ܕܠܝܠܐ',
            dt2: 'Delila',
            es1: 'ܕܠܝܠܐ',
            ar1: 'ܕܠܝܠܐ'
        },
        {
            ar3: '',
            es3: '',
            ar1: 'ܨܡܚܐ',
            dt1: 'Semho',
            es2: 'ܨܡܚܐ',
            es1: 'ܨܡܚܐ',
            id: '986',
            bedeutung: 'männl., “Glanz, Strahl”',
            dt2: '',
            dt3: '',
            ar2: 'ܨܶܡܚܳܐ'
        },
        {
            es3: '',
            ar3: '',
            dt2: 'Ahisemech',
            es1: 'ܐܚܝܣܡܟܐ',
            ar1: 'ܐܚܝܣܡܟܐ',
            es2: 'ܐܚܝܣܡܟܐ',
            ar2: 'ܐܰܚܝܣܰܡܟܳܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “mein Bruder ist meine Unterstützung”; weitere Formen: Ahisemech, Ahisamek',
            id: '1236',
            dt3: 'Ahisamek',
            dt1: 'Ahisamko'
        },
        {
            dt1: 'Mara',
            id: '1848',
            bedeutung: 'weibl., syrischen Ursprungs; “Rebellin, Revolutionärin”',
            ar3: '',
            es1: 'ܡܪܐ',
            es3: '',
            dt2: '',
            ar2: 'ܡܰܪܰܐ',
            ar1: 'ܡܪܐ',
            dt3: '',
            es2: 'ܡܪܐ'
        },
        {
            ar1: 'ܝܩܝܪܬܐ',
            es1: 'ܝܩܝܪܬܐ',
            dt3: '',
            es3: '',
            bedeutung: 'männl., “gewinnen, profitieren, nutzen, fruchten, verdienen, zunehmen, vermehren, erhöhen”; weitere Formen: Jitar',
            es2: 'ܝܩܝܪܬܐ',
            dt2: '',
            dt1: 'Yaqirta',
            ar2: 'ܝܰܩܺܝܪܬܰܐ',
            ar3: '',
            id: '2321'
        },
        {
            bedeutung: 'männl., syrischen Ursprungs; “Macht Kraft, Energie, Kapazität”; weitere Formen: Haylo',
            dt3: '',
            es2: 'ܚܕܒܫܒܐ',
            es1: 'ܚܕܒܫܒܐ',
            ar2: 'ܚܰܕܒܫܰܒܳܐ',
            ar3: 'ܚܽܘܫܰܒܳܐ',
            id: '1602',
            dt2: '',
            ar1: 'ܚܕܒܫܒܐ',
            es3: '',
            dt1: 'Hadbschabo'
        },
        {
            dt2: '',
            bedeutung: 'weibl., “Auswanderung, Emigration, Exil”; weitere Formen: Golutha',
            ar1: 'ܓܠܘܬܐ',
            id: '391',
            es1: 'ܓܠܘܬܐ',
            dt3: '',
            ar2: 'ܓܳܠܽܘܬܰܐ',
            dt1: 'Goluta',
            es3: '',
            ar3: '',
            es2: 'ܓܠܘܬܐ'
        },
        {
            dt3: '',
            bedeutung: 'weibl., “Erbe, Erbschaft, Anteil, Besitz”; weitere Formen: Yortutha, Jortuta, Jortutha',
            ar2: 'ܝܳܪܬܽܘܬܰܐ',
            dt2: '',
            es2: 'ܝܬܪܘܬܐ',
            dt1: 'Yortuta',
            id: '1140',
            ar1: 'ܝܪܬܘܬܐ',
            es3: '',
            es1: 'ܝܬܪܘܬܐ',
            ar3: ''
        },
        {
            dt3: '',
            es3: '',
            es2: 'ܬܐܘܕܘܪ',
            ar1: 'ܬܐܘܕܘܪ',
            ar3: '',
            dt1: 'Theodor',
            dt2: '',
            ar2: 'ܬܶܐܘܳܕܳܘܪ',
            bedeutung: 'männl., aus dem griechischen “theós”: Gott und “dóron”: Geschenk, Gabe = “Geschenk/Gabe Gottes”. In der Kirchengeschichte hat es viele Heilige unter diesem Namen gegeben. Weitere Formen: Theodoros',
            es1: 'ܬܐܘܕܘܪ',
            id: '1047'
        },
        {
            es1: 'ܐܫܦܪ',
            ar2: 'ܐܰܫܦܰܪ',
            bedeutung: 'männl., “verschönern, er hat verschönert”',
            id: '108',
            ar3: '',
            dt2: '',
            es2: 'ܐܫܦܪ',
            es3: '',
            ar1: 'ܐܫܦܪ',
            dt1: 'Aschfar',
            dt3: ''
        },
        {
            es2: 'ܪܡܘܬܐ',
            es3: '',
            dt1: 'Ramuta',
            ar1: 'ܪܡܘܬܐ',
            dt3: '',
            es1: 'ܪܡܘܬܐ',
            ar3: '',
            dt2: '',
            bedeutung: 'männl., “Zeichen, Signal, Motto, Anweisung”; weitere Formen: Remso',
            ar2: 'ܪܰܡܽܘܬܰܐ',
            id: '2035'
        },
        {
            dt2: '',
            dt3: '',
            ar3: '',
            id: '1670',
            dt1: 'Hlita',
            bedeutung: 'männl., “Gesundheit, Genesung”; weitere Formen: Hulmon, Hulmana',
            es3: '',
            es1: 'ܚܠܝܬܐ',
            ar2: 'ܚܠܺܝܬܰܐ',
            es2: 'ܚܠܝܬܐ',
            ar1: 'ܚܠܝܬܐ'
        },
        {
            ar2: 'ܥܽܘܡܪܺܝ',
            dt1: 'Umri',
            es3: '',
            id: '2276',
            ar3: '',
            ar1: 'ܥܘܡܪܝ',
            dt2: '',
            es2: 'ܥܘܡܪܝ',
            es1: 'ܥܘܡܪܝ',
            bedeutung: 'weibl., “Garten”; weitere Formen: Uritha',
            dt3: ''
        },
        {
            dt1: 'Zazabuha',
            dt3: '',
            dt2: '',
            ar2: 'ܙܰܐܙܰܒܽܘܚܰܐ',
            es2: 'ܙܐܙܒܘܚܐ',
            bedeutung: 'weibl., “Siegerin, Bezwingerin, Eroberin”; weitere Formen: Zkhita',
            ar3: '',
            es1: 'ܙܐܙܒܘܚܐ',
            id: '2356',
            ar1: 'ܙܐܙܒܘܚܐ',
            es3: ''
        },
        {
            ar1: 'ܪܚܝܠ',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Mutterschaft”. Lm AT ist Rahel die jüngere und schöne Tochter des Aramäers Laban, die zweite Frau Jakobs (neben Lea, Ihrer älteren Schwester) und Mutter von Joseph und Benjamin, Hirtin; die Aramäerinnen Lea und Rahel gelten als Stammmütter Israels (der Israeliten). Weitere Formen: Rachel, Rohel; sehr beliebter Vorname bei den Aramäern',
            ar3: 'ܪܳܚܶܝܠ',
            dt1: 'Rahel',
            es3: 'ܪܚܝܠ',
            es1: 'ܪܚܝܠ',
            dt2: 'Rohel',
            dt3: '',
            ar2: 'ܪܰܚܶܝܠ',
            id: '837',
            es2: 'ܪܚܝܠ'
        },
        {
            dt1: 'Hamutal',
            ar1: 'ܚܡܘܛܠ',
            dt3: '',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “mein Schwager ist Tau”',
            dt2: '',
            es3: '',
            id: '430',
            es1: 'ܚܡܘܛܠ',
            es2: 'ܚܡܘܛܠ',
            ar3: '',
            ar2: 'ܚܰܡܽܘܛܰܠ'
        },
        {
            dt2: 'Barsawmo',
            id: '185',
            es2: 'ܒܪܨܘܡܐ',
            dt3: '',
            es3: '',
            ar3: '',
            ar1: 'ܒܪܨܘܡܐ',
            dt1: 'Barsaumo',
            es1: 'ܒܪܨܘܡܐ',
            bedeutung: 'männl., “Sohn des Fastens”. Barsawmo ist ein Heiliger (†457); Festtag: 3. Februar. Weitere Formen: Barsawma, Barsaumo, Barsauma',
            ar2: 'ܒܰܪܨܰܘܡܳܐ'
        },
        {
            es3: '',
            ar3: '',
            dt2: '',
            id: '638',
            ar2: 'ܠܽܘܟܰܣ',
            bedeutung: 'männl., aus dem Lateinischen übernommener Vorname; “der aus Lucania Stammende”; Mitarbeiter des Apostels Paulus, gilt als der Verfasser des dritten Evangeliums und der Apostelgeschichte. Weitere Formen: Luko, Lukos, Luka',
            ar1: 'ܠܘܩܐ',
            es2: 'ܠܘܟܣ',
            es1: 'ܠܘܩܐ',
            dt3: '',
            dt1: 'Lukas'
        },
        {
            es1: 'ܝܗܒܐܠܗܐ',
            dt1: 'Yahbalaha',
            bedeutung: 'männl., “Gott hat gegeben, weitere Formen: Yabalaha, Jahbalaha, Jabalaha',
            ar3: '',
            ar2: 'ܝܰܗܒܰܐܠܰܗܰܐ',
            es3: '',
            dt3: '',
            id: '1115',
            dt2: '',
            es2: 'ܝܗܒܐܠܗܐ',
            ar1: 'ܝܗܒܐܠܗܐ'
        },
        {
            id: '1148',
            ar3: '',
            dt2: '',
            es1: 'ܙܗܝ',
            bedeutung: 'männl., “reinigen, schmücken, zieren, verzieren”',
            dt1: 'Zahi',
            es2: 'ܙܗܝ',
            ar1: 'ܙܗܝ',
            es3: '',
            dt3: '',
            ar2: 'ܙܰܗܺܝ'
        },
        {
            ar3: '',
            id: '927',
            dt1: 'Schainat',
            es3: '',
            es1: 'ܫܝܢܬ',
            bedeutung: 'männl., “du bist (mein) Frieden”; weitere Formen: Schaynat, Schajnat',
            es2: 'ܫܝܢܬ',
            dt2: '',
            dt3: '',
            ar2: 'ܫܰܝܢܰܬ',
            ar1: 'ܫܝܢܬ'
        },
        {
            ar1: 'ܐܒܝܠ',
            es2: 'ܐܒܝܠ',
            ar2: 'ܐܰܒܺܝܠ',
            dt2: '',
            id: '1194',
            dt3: '',
            bedeutung: '',
            ar3: '',
            es1: 'ܐܒܝܠ',
            es3: '',
            dt1: 'Abil'
        },
        {
            ar3: 'ܫܶܒܰܥ',
            dt3: '',
            es3: 'ܫܒܐ',
            dt1: 'Schebo',
            id: '2142',
            ar1: 'ܫܒܐ',
            ar2: 'ܫܶܒܳܐ',
            es1: 'ܫܒܐ',
            bedeutung: 'männl., “du bist meine Wurzel, Fundament”',
            es2: 'ܫܒܐ',
            dt2: 'Scheba'
        },
        {
            es1: 'ܗܝܝܘܬܐ',
            id: '482',
            ar3: '',
            ar1: 'ܗܝܝܘܬܐ',
            dt1: 'Hiyuta',
            dt3: '',
            es3: '',
            es2: 'ܗܝܝܘܬܐ',
            bedeutung: 'weibl., syrischen Ursprungs; “Identität, Zustand, Essenz, Wesen, Bild”; weitere Formen: Hiyutha',
            dt2: '',
            ar2: 'ܗܺܝܝܽܘܬܰܐ'
        },
        {
            id: '2029',
            es1: 'ܪܚܝܠ',
            ar1: 'ܪܚܝܠ',
            ar2: 'ܪܰܚܶܝܠ',
            dt1: 'Rahel',
            ar3: 'ܪܳܚܶܝܠ',
            es3: 'ܪܚܝܠ',
            dt2: 'Rohel',
            bedeutung: 'männl., “Mysterium, Geheimnis”; weitere Formen: Rozo, Rasa',
            dt3: '',
            es2: 'ܪܚܝܠ'
        },
        {
            es2: 'ܕܘܪܐ',
            dt3: '',
            ar2: 'ܕܰܘܪܰܐ',
            dt2: '',
            es3: 'ܕܪܐ',
            dt1: 'Dora',
            ar3: 'ܕܳܪܰܐ',
            bedeutung: 'männl., “Talent, Gabe” auch “Dach” ',
            ar1: 'ܕܘܪܐ',
            id: '1464',
            es1: 'ܕܘܪܐ'
        },
        {
            ar2: 'ܝܶܫܽܘܥܕܳܢ',
            dt1: 'Jeschudod',
            ar1: 'ܝܫܘܥܕܢ',
            id: '540',
            dt2: '',
            bedeutung: 'männl., “Jesus ist mein Onkel”',
            es3: '',
            ar3: '',
            es1: 'ܝܫܘܥܕܢ',
            dt3: '',
            es2: 'ܝܫܘܥܕܢ'
        },
        {
            dt1: 'Karim',
            dt3: '',
            id: '1767',
            ar3: '',
            bedeutung: '',
            es1: 'ܟܪܝܡ',
            ar2: 'ܟܰܪܺܝܡ',
            es3: '',
            dt2: '',
            es2: 'ܟܪܝܡ',
            ar1: 'ܟܪܝܡ'
        },
        {
            ar2: 'ܐܽܘܪܚܽܘܒܰܐ',
            es3: '',
            id: '2283',
            es2: 'ܐܘܪܚܘܒܐ',
            dt3: '',
            bedeutung: 'männl., “Freude, Erlösung”, “Palmsonntag”; weitere Formen: Uschana',
            dt2: '',
            ar3: '',
            dt1: 'Urhuba',
            ar1: 'ܐܘܪܚܘܒܐ',
            es1: 'ܐܘܪܚܘܒܐ'
        },
        {
            dt3: '',
            ar1: 'ܫܝܢܐ',
            es3: '',
            id: '2120',
            ar3: '',
            dt1: 'Schaino',
            ar2: 'ܫܰܝܢܳܐ',
            bedeutung: 'männl., “Nuri, der Diener”. König der Aramäer von Beth Bakhjani (9. Jh. v. Chr.), Vater von Hadajisi, König von Guzan. Weitere Formen: Schamasch-Nui',
            es2: 'ܫܝܢܐ',
            es1: 'ܫܝܢܐ',
            dt2: ''
        },
        {
            es2: 'ܡܬܝܬܐ',
            ar2: 'ܡܰܬܺܝܰܬܶܗ',
            es1: 'ܡܬܝܬܐ',
            dt2: 'Medyad',
            ar1: 'ܡܬܝܬܐ',
            es3: 'ܡܕܝܕ',
            id: '1879',
            ar3: 'ܡܶܕܝܰܕ',
            dt1: 'Matiate',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; abgekürzt aus Michaja(hu): “Wer ist wie Gott?”. Im AT sind mehrere Personen unter diesem Namen bekannt, u.a. ein Prophet, Verfasser eines Buches. Weitere Formen: Micho',
            dt3: ''
        },
        {
            es1: 'ܠܝܕܝܐ',
            dt3: '',
            id: '1829',
            ar2: 'ܠܺܝܕܺܝܰܐ',
            dt1: 'Lydia',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “mein Bote, mein Engel”. Im AT ist Maleachi ein Prophet (Mitte des 5. Jh. v. Chr.) und Verfasser eines Buches. Weitere Formen: Malachi, Malaki',
            ar3: '',
            dt2: '',
            ar1: 'ܠܝܕܝܐ',
            es2: 'ܠܝܕܝܐ',
            es3: ''
        },
        {
            dt2: '',
            id: '2171',
            dt1: 'Schumrin',
            ar2: 'ܫܽܘܡܪܺܝܢ',
            bedeutung: 'männl., aus der Bibel übernommener aramäisch-hebräischen Ursprungs. Im AT ist Seron Befehlshaber des syrisch-aramäischen Heeres von Aram-Damaskus',
            es2: 'ܫܘܡܪܝܢ',
            es3: '',
            ar1: 'ܫܘܡܪܝܢ',
            ar3: '',
            dt3: '',
            es1: 'ܫܘܡܪܝܢ'
        },
        {
            ar1: 'ܐܚܝܝܐ',
            es1: 'ܐܚܝܝܐ',
            es3: '',
            ar3: '',
            es2: 'ܐܚܝܝܐ',
            ar2: 'ܐܰܚܺܝܝܰܐ',
            dt2: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “mein Bruder ist Jahwe”. Im AT begegnen Ahija, Sohn des Ahitub, ein Priester, Nachkomme Elis, und Ahija, Sohn des Schischa, Sekretär des Königs Salomo, ferner ein Prophet und viele andere Personen dieses Namens',
            dt3: '',
            dt1: 'Ahija',
            id: '54'
        },
        {
            dt2: '',
            ar3: '',
            ar2: 'ܣܰܒܰܪ',
            bedeutung: 'männl., “verkünden”',
            es2: 'ܣܒܪ',
            id: '875',
            es1: 'ܣܒܪ',
            ar1: 'ܣܒܪ',
            dt3: '',
            dt1: 'Sabar',
            es3: ''
        },
        {
            dt1: 'Lilya',
            ar3: '',
            dt2: '',
            dt3: '',
            es1: 'ܠܝܠܝܐ',
            ar2: 'ܠܺܝܠܝܰܐ',
            bedeutung: 'weibl., siehe Layla',
            ar1: 'ܠܝܠܝܐ',
            es2: 'ܠܝܠܝܐ',
            es3: '',
            id: '632'
        },
        {
            ar2: 'ܐܺܝܒܠܰܐ',
            es1: 'ܐܝܒܠܐ',
            dt1: 'Ibla',
            ar1: 'ܐܝܒܠܐ',
            ar3: '',
            dt2: '',
            es2: 'ܐܝܒܠܐ',
            id: '1682',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs. Im AT ist Irad Sohn Henochs',
            es3: '',
            dt3: ''
        },
        {
            dt3: '',
            bedeutung: '',
            es1: 'ܡܪܐܘܓܐ',
            dt2: 'Marauwge',
            es3: '',
            dt1: 'Marauge',
            ar1: 'ܡܪܐܘܓܐ',
            ar2: 'ܡܰܪܰܐܘܓܶܐ',
            es2: 'ܡܪܐܘܓܐ',
            ar3: '',
            id: '1849'
        },
        {
            dt3: '',
            es1: 'ܐܟܝܦܘܬܐ',
            ar3: '',
            es3: '',
            ar2: 'ܐܰܟܺܝܦܽܘܬܰܐ',
            dt1: 'Akifuta',
            ar1: 'ܐܟܝܦܘܬܐ',
            dt2: '',
            es2: 'ܐܟܝܦܘܬܐ',
            id: '1245',
            bedeutung: 'weibl., “Eifer, Fleiß, Fürsorge”'
        },
        {
            es2: 'ܬܐܒܝܠܝܐ',
            es1: 'ܬܐܒܝܠܝܐ',
            dt1: 'Tibelia',
            ar1: 'ܬܐܒܝܠܝܐ',
            ar3: 'ܬܺܐܒܶܠܝܰܐ',
            ar2: 'ܬܺܐܒܺܝܠܝܰܐ',
            id: '1055',
            dt2: 'Tibelya',
            es3: 'ܬܐܒܠܝܐ',
            dt3: 'Tibelja',
            bedeutung: 'weibl., “Welt, Erde, Universum”'
        },
        {
            es1: 'ܐܝܣܚܩ',
            es2: 'ܐܝܣܚܩ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “er wird lachen” oder “daß Gott lache” oder “das Kind des Lachens”. Im AT ist Ishaq  2000 und 1400 v. Chr.) der Sohn von Abraham und Sara, Halbbruder von Ismael. Abraham sollte ursprünglich Ishaq für Gott opfern, was allerdings dann durch Eingreifen des Engels verhindert wird. Ishaq heiratet Rebekka, die Tochter des Aramäers Betuel aus Haran in Aram Beth-Nahrin (= Mesopotamien), ist Vater der Zwillinge Jakob und Esau. Viele syrische Heilige tragen den Namen Ishaq. Weitere Formen: Isaak, Ishoq',
            dt1: 'Ishak',
            es3: 'ܐܝܣܚܩ',
            ar2: 'ܐܺܝܣܚܰܩ',
            ar3: 'ܐܺܝܣܚܳܩ',
            dt2: 'Ishok',
            ar1: 'ܐܝܣܚܩ',
            id: '511',
            dt3: 'Isaak'
        },
        {
            id: '227',
            ar2: 'ܒܶܝܬܙܰܒܕܰܝ',
            es3: '',
            es2: 'ܒܝܬܙܒܕܝ',
            dt1: 'Bethzabdai',
            dt3: '',
            ar3: '',
            es1: 'ܒܝܬܙܒܕܝ',
            dt2: '',
            bedeutung: 'weibl., Bethzabdai bezeichnet sowohl eine Stadt als auch eine Landschaft in Aram Beth-Nahrin (= Mesopotamien). Weitere Formen: Bethzabday',
            ar1: 'ܒܝܬܙܒܕܝ'
        },
        {
            ar1: 'ܠܘܝ',
            es1: 'ܠܘܝ',
            ar3: '',
            ar2: 'ܠܶܘܺܝ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; anhänglich, Anhang, dem Bunde zugetan, verbunden mit, bezogen auf“. Im AT ist Levi der dritte Sohn Jakobs und der Lea; im NT tragen zwei Ahnherren Jesu diesen Namen',
            dt3: '',
            es3: '',
            es2: 'ܠܘܝ',
            dt1: 'Levi',
            dt2: '',
            id: '629'
        },
        {
            ar1: 'ܡܪܝܕܬܐ',
            dt2: '',
            es3: '',
            es2: 'ܡܪܝܕܬܐ',
            ar2: 'ܡܰܪܺܝܕܬܰܐ',
            ar3: '',
            es1: 'ܡܪܝܕܬܐ',
            dt3: '',
            id: '669',
            bedeutung: 'weibl., syrischen Ursprungs; “Rebellin, Revolutionärin”',
            dt1: 'Maridta'
        },
        {
            ar3: '',
            es2: 'ܝܥܩܘܒ ܒܟܝܪ',
            dt3: '',
            ar1: 'ܝܥܩܘܒ ܒܟܝܪ',
            dt1: 'Yakub Bakir',
            id: '1119',
            es3: '',
            es1: 'ܝܥܩܘܒ ܒܟܝܪ',
            ar2: 'ܝܰܥܩܺܘܒ ܒܰܟܺܝܪ',
            dt2: '',
            bedeutung: ''
        },
        {
            id: '1040',
            ar2: 'ܬܰܘܕܐܝܺܠ',
            es3: '',
            dt2: '',
            ar1: 'ܬܘܕܐܝܠ',
            es1: 'ܬܘܕܐܝܠ',
            dt1: 'Taudil',
            dt3: '',
            ar3: '',
            es2: 'ܬܘܕܐܝܠ',
            bedeutung: 'männl., “Dank sei Gott” oder “Gott sei Dank”; weitere Formen: Tawdil'
        },
        {
            dt1: 'Barsin',
            ar2: 'ܒܰܪܣܺܝܢ',
            es1: 'ܒܪܣܝܢ',
            dt3: '',
            ar1: 'ܒܪܣܝܢ',
            ar3: '',
            dt2: '',
            es3: '',
            bedeutung: 'männl., “Sohn des Mondes”. Barsin war König von Ur',
            id: '189',
            es2: 'ܒܪܣܝܢ'
        },
        {
            dt1: 'Suleyman',
            es1: 'ܣܠܝܡܐܢ',
            id: '2205',
            ar2: 'ܣܠܶܝܡܐܰܢ',
            ar3: '',
            dt3: '',
            ar1: 'ܣܠܝܡܐܢ',
            dt2: 'Sleman',
            es2: 'ܣܠܝܡܐܢ',
            es3: '',
            bedeutung: 'weibl., “Dampf, Dunst, Geruch, Geschmack”; weitere Formen: Swatha'
        },
        {
            dt2: 'Youil',
            ar1: 'ܝܘܐܝܠ',
            es1: 'ܝܘܐܝܠ',
            dt3: '',
            es3: '',
            ar2: 'ܝܽܘܐܺܝܠ',
            id: '554',
            ar3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe ist Gott”. Im AT ist Joel ein Prophet in Jerusalem (4. Jh. v. Chr.), Verfasser eines Buches. Weitere Formen: Youil',
            dt1: 'Joel',
            es2: 'ܝܘܐܝܠ'
        },
        {
            es2: 'ܬܘܠܡܝ',
            ar1: 'ܬܘܠܡܝ',
            dt2: '',
            ar2: 'ܬܽܘܠܡܰܝ',
            ar3: '',
            dt3: '',
            dt1: 'Tulmai',
            id: '1078',
            es1: 'ܬܘܠܡܝ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs. Im AT begegnet Tulmai als Sohn des Anak, ferner trägt der Sohn des Ammihud, König von Geschur, Schwiegervater Davids und Großvater Abschaloms, diesen Namen. Weitere Formen: Tolmai, Talmai',
            es3: ''
        },
        {
            es2: 'ܐܘܪܗܝ',
            ar1: 'ܐܘܪܗܝ',
            dt3: '',
            es3: '',
            dt2: '',
            dt1: 'Urhoy',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs. Im AT ist Us der älteste Sohn Arams; ein Stamm der Aramäer. Weitere Formen: Uz',
            ar3: '',
            ar2: 'ܐܽܘܪܗܳܝ',
            es1: 'ܐܘܪܗܝ',
            id: '2282'
        },
        {
            dt1: 'Jischmaja',
            es3: '',
            ar1: 'ܐܝܫܡܥܝܐ',
            ar3: '',
            id: '1736',
            es1: 'ܐܝܫܡܥܝܐ',
            dt3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe weiß”',
            dt2: '',
            ar2: 'ܐܝܫܡܥܝܐ',
            es2: 'ܐܝܫܡܥܝܐ'
        },
        {
            dt1: 'Mahalalel',
            es2: 'ܡܗܠܠܐܝܠ',
            ar2: 'ܡܰܗܠܳܠܳܐܝܶܠ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Lob Gottes“. Im AT ist Mahalalel Sohn des Kenan und Vater des Jered; im NT ist Mahalalel ein Ahnherr Jesu. Weitere Formen: Mahloloel',
            es3: 'ܡܗܠܠܐܝܠ',
            ar3: 'ܡܰܗܠܰܠܰܐܝܶܠ',
            id: '646',
            dt3: '',
            dt2: '',
            ar1: 'ܡܗܠܠܐܝܠ',
            es1: 'ܡܗܠܠܐܝܠ'
        },
        {
            dt3: '',
            bedeutung: 'männl., “unser Herr”',
            dt2: '',
            es1: 'ܪܒܢ',
            ar3: '',
            ar2: 'ܪܰܒܰܢ',
            es3: '',
            es2: 'ܪܒܢ',
            id: '821',
            dt1: 'Raban',
            ar1: 'ܪܒܢ'
        },
        {
            dt1: 'Ilona',
            es3: '',
            dt2: '',
            ar2: 'ܐܺܝܠܳܢܰܐ',
            ar3: '',
            bedeutung: 'weibl., “Baum”',
            id: '501',
            es1: 'ܐܝܠܢܐ',
            dt3: '',
            ar1: 'ܐܝܠܢܐ',
            es2: 'ܐܝܠܢܐ'
        },
        {
            ar3: 'ܡܫܰܒܠܳܢ',
            id: '1910',
            dt3: '',
            es2: 'ܡܫܒܠܢܐ',
            dt1: 'Mschablono',
            es1: 'ܡܫܒܠܢܐ',
            es3: 'ܡܫܒܠܢ',
            bedeutung: 'männl., akkadischen Ursprungs; “Nabu, schütze meinen Sohn!”. Nabochadnasar (II.) war aramäisch-chaldäischer König, Sohn Nabopolassars, des Gründers des neubabylonischen Reiches (605-562. v. Chr.). Weitere Formen: Nebukadnezzar',
            dt2: '',
            ar2: 'ܡܫܰܒܠܳܢܳܐ',
            ar1: 'ܡܫܒܠܢܐ'
        },
        {
            ar3: '',
            dt1: 'Schemun',
            es3: '',
            ar2: 'ܫܶܡܥܽܘܢ',
            id: '958',
            dt2: '',
            ar1: 'ܫܡܥܘܢ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott hat gehört“. Im AT ist Schemun Sohn Jakobs; Im NT ist Schemun (Petros) ein Jünger Jesu und Vorsprecher der 12 Apostel. Schemun gilt als der Gründer der syrisch-orthodoxen Kirche von Antiochien. Mor Schemun d-zayte (†734); Festtag: 1. Juni; Mor Schemun destune “des Styliten” (†459). Weitere Formen: Simeon, Simon, Schimon, Schamun; bei den Aramäern sehr beliebt',
            dt3: '',
            es1: 'ܫܡܥܘܢ',
            es2: 'ܫܡܥܘܢ'
        },
        {
            dt1: 'Sophia',
            ar3: 'ܣܦܐܝܐ',
            es3: 'ܣܦܐܝܐ',
            id: '1006',
            dt2: 'Sofia',
            es1: 'ܙܦܝܐ',
            bedeutung: 'weibl., aus dem griechischen “Sophia Weisheit”',
            ar2: 'ܣܦܝܐ',
            dt3: '',
            es2: 'ܙܦܝܐ',
            ar1: 'ܙܦܝܐ'
        },
        {
            dt1: 'Arbila',
            ar3: '',
            dt2: 'Arbela',
            dt3: '',
            bedeutung: '',
            es3: '',
            ar2: 'ܐܰܪܒܺܝܠܰܐ',
            es2: 'ܐܪܒܝܠܐ',
            id: '1275',
            ar1: 'ܐܪܒܝܠܐ',
            es1: 'ܐܪܒܝܠܐ'
        },
        {
            es2: 'ܐܕܝܒ',
            bedeutung: 'männl.; arabischen Ursprungs; “Erziehung”',
            ar3: 'ܐܶܕܺܝܒ',
            id: '291',
            ar2: 'ܐܰܕܺܝܒ',
            dt2: 'Adip',
            dt1: 'Edip',
            dt3: 'Adib',
            es3: 'ܐܕܝܒ',
            es1: 'ܐܕܝܒ',
            ar1: 'ܐܕܝܒ'
        },
        {
            bedeutung: 'männl., syrischen Ursprungs; “Gerechter, der Gerechte, ein gerechter Mann [Heiliger]”',
            es2: 'ܟܐܢܐ',
            dt1: 'Kino',
            ar1: 'ܟܐܢܐ',
            es3: '',
            ar2: 'ܟܺܐܢܳܐ',
            ar3: '',
            id: '597',
            es1: 'ܟܐܢܐ',
            dt3: '',
            dt2: ''
        },
        {
            dt1: 'Romjeschu',
            ar3: '',
            dt3: '',
            bedeutung: 'männl., “du bist meine Hoffnung”',
            es3: '',
            id: '2062',
            es2: 'ܪܡܝܫܘܥ',
            dt2: '',
            es1: 'ܪܡܝܫܘܥ',
            ar1: 'ܪܡܝܫܘܥ',
            ar2: 'ܪܳܡܝܶܫܽܘܥ'
        },
        {
            ar3: '',
            es1: 'ܫܘܟܢܐ',
            ar2: 'ܫܽܘܟܳܢܳܐ',
            ar1: 'ܫܘܟܢܐ',
            es3: '',
            dt2: '',
            dt1: 'Schukono',
            es2: 'ܫܘܟܢܐ',
            id: '977',
            dt3: '',
            bedeutung: 'männl., “Geschenk, Gabe, Belohnung”'
        },
        {
            ar2: 'ܐܰܪܕܰܫ',
            ar3: '',
            dt3: '',
            bedeutung: '',
            dt2: '',
            es1: 'ܐܪܕܫ',
            es3: '',
            ar1: 'ܐܪܕܫ',
            dt1: 'Ardash',
            es2: 'ܐܪܕܫ',
            id: '97'
        },
        {
            es2: 'ܥܝܣܐ',
            ar1: 'ܥܝܣܐ',
            dt3: '',
            dt2: '',
            ar2: 'ܥܺܝܣܰܐ',
            ar3: '',
            bedeutung: 'männl., arab. Form zu Jesus',
            id: '505',
            es3: '',
            dt1: 'Isa',
            es1: 'ܥܝܣܐ'
        },
        {
            es1: 'ܫܐܠܬܐ',
            ar2: 'ܫܶܐܠܶܬܰܐ',
            ar1: 'ܫܐܠܬܐ',
            dt2: '',
            ar3: '',
            dt3: '',
            es2: 'ܫܐܠܬܐ',
            dt1: 'Schelata',
            id: '2144',
            es3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Name, Ansehen”. Schim ist im AT Sohn Noachs, einer der Ahnherren der nachsintﬂutlichen Menschheit, Urvater der Semiten. Weitere Formen: Schem, Sem'
        },
        {
            dt2: 'Tabea',
            dt3: '',
            bedeutung: 'weibl., “acht”, “Süden”. Eine der Bezeichnungen von Turabdin vor Christus, ein altes Siedlungsgebiet der Aramäer. Weitere Formen: Tamanja, Tamanya, Tamanita, Taimna',
            ar1: 'ܛܒܝܐ',
            es2: 'ܛܒܝܐ',
            es3: 'ܛܒܝܐ',
            ar2: 'ܛܰܒܺܝܰܐ',
            id: '2216',
            es1: 'ܛܒܝܐ',
            dt1: 'Tabia',
            ar3: 'ܛܰܒܝܰܐ'
        },
        {
            es3: '',
            ar3: '',
            bedeutung: 'männl., “Pfeil”',
            es2: 'ܓܐܪܐ',
            dt2: '',
            dt1: 'Gero',
            ar1: 'ܓܐܪܐ',
            es1: 'ܓܐܪܐ',
            dt3: '',
            ar2: 'ܓܶܪܳܐ',
            id: '377'
        },
        {
            es1: 'ܢܐܕܝܢ',
            dt1: 'Nadin',
            dt2: '',
            ar3: '',
            bedeutung: '',
            dt3: '',
            es2: 'ܢܐܕܝܢ',
            es3: '',
            id: '733',
            ar2: 'ܢܰܐܕܺܝܢ',
            ar1: 'ܢܐܕܝܢ'
        },
        {
            dt1: 'Aftunya',
            id: '46',
            bedeutung: 'weibl., Aftunya war die Mutter des gelehrten syrisch-orthodoxen Mönchs Yuhannun/Johannes mit dem Beinamen “Bar Aftunya” (†538) aus Edessa.',
            ar1: 'ܐܦܬܘܢܝܐ',
            es1: 'ܐܦܬܘܢܝܐ',
            dt3: '',
            ar2: 'ܐܰܦܬܽܘܢܝܰܐ',
            es3: '',
            dt2: '',
            es2: 'ܐܦܬܘܢܝܐ',
            ar3: ''
        },
        {
            es3: '',
            dt3: '',
            dt1: 'Rabuna',
            es1: 'ܪܒܘܢܐ',
            bedeutung: 'männl., “unser Herr, Meister, Lehrer, Rabbi”; weitere Formen: Rabbuna, Rabuno',
            es2: 'ܪܒܘܢܐ',
            ar3: '',
            ar2: 'ܪܰܒܽܘܢܰܐ',
            ar1: 'ܪܒܘܢܐ',
            dt2: '',
            id: '830'
        },
        {
            bedeutung: 'männl., “Sohn des Fisches”',
            ar2: 'ܒܰܪܢܶܡܪܳܐ',
            dt1: 'Barnemro',
            ar3: '',
            es1: 'ܒܪܢܡܪܐ',
            dt3: '',
            id: '1357',
            ar1: 'ܒܪܢܡܪܐ',
            es2: 'ܒܪܢܡܪܐ',
            es3: '',
            dt2: ''
        },
        {
            dt3: '',
            ar2: 'ܚܰܢܢܺܝܶܠ',
            es2: 'ܚܢܢܝܠ',
            dt1: 'Hanniel',
            ar1: 'ܚܢܢܝܠ',
            id: '440',
            es3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gnade Gottes”',
            es1: 'ܚܢܢܝܠ',
            dt2: '',
            ar3: ''
        },
        {
            ar1: 'ܚܢܢܐ',
            id: '1626',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott hat gesehen”; Hasael war König von Aram (Aram-Damaskus) (843-797 v.Chr.). Weitere Formen: Hasayel, Hasail, Hassail, Hazael, Hazayel, Hasaja',
            ar3: 'ܚܰܢܢܶܐ',
            es3: 'ܚܢܢܐ',
            es2: 'ܚܢܢܐ',
            dt1: 'Hanna',
            dt2: 'Hanne',
            dt3: '',
            es1: 'ܚܢܢܐ',
            ar2: 'ܚܰܢܢܰܐ'
        },
        {
            dt2: '',
            es2: 'ܓܐܝܘܬܐ',
            id: '368',
            es3: '',
            dt1: 'Gayuta',
            ar2: 'ܓܰܐܝܽܘܬܐ',
            bedeutung: 'weibl., “Herrlichkeit, Reinheit, Pracht, Schönheit”; weitere Formen: Gayutha',
            ar3: '',
            ar1: 'ܓܐܝܘܬܐ',
            dt3: '',
            es1: 'ܓܐܝܘܬܐ'
        },
        {
            ar1: 'ܕܢܚܐ',
            bedeutung: 'männl., “Aufgang, Sonnenaufgang, Erscheinung”. Das Fest der “Denho/Danho” (gr. “Epiphanie”) am 6. Januar: Erscheinung bzw. Taufe Jesu Christi am Jordan',
            ar2: 'ܕܰܢܚܳܐ',
            ar3: '',
            dt3: '',
            dt2: '',
            es3: '',
            dt1: 'Danho',
            id: '257',
            es1: 'ܕܢܚܐ',
            es2: 'ܕܢܚܐ'
        },
        {
            es2: 'ܕܝܘܢܢܘܣܝܘܣ',
            dt2: 'Dionosyius',
            bedeutung: 'männl., aus dem Griechischen übernommener Vorname; “der dem Gott Dionysos Geweihte”. In der Kirchengeschichte war Dionysius Bischof von Athen. Viele syrische Bischöfe tragen bis heute den Beinamen Dionysius',
            es1: 'ܕܝܘܢܢܘܣܝܘܣ',
            ar3: '',
            dt3: 'Dionysius',
            dt1: 'Dionysos',
            id: '274',
            ar1: 'ܕܝܘܢܢܘܣܝܘܣ',
            es3: '',
            ar2: 'ܕܺܝܳܘܢܢܽܘܣܺܝܽܘܣ'
        },
        {
            bedeutung: 'männl., ”Honig“, weitere Formen: Dawscho, Doscho, Debscho',
            dt1: 'Daqlita',
            dt3: '',
            dt2: '',
            ar2: 'ܕܰܩܠܺܝܬܰܐ',
            ar1: 'ܕܩܠܝܬܐ',
            es3: '',
            es1: 'ܕܩܠܝܬܐ',
            es2: 'ܕܩܠܝܬܐ',
            ar3: '',
            id: '1441'
        },
        {
            es1: 'ܐܕܘܢܝ',
            es2: 'ܐܕܘܢܝ',
            dt1: 'Adonai',
            dt2: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “mein Herr”. Adonai wurde von den heidnischen Aramäern in Syrien als Gott verehrt; er hieß auch Tammus (Juli) und galt als “Liebling der Frauen“',
            dt3: '',
            ar2: 'ܐܰܕܳܘܢܰܝ',
            id: '38',
            ar1: 'ܐܕܘܢܝ',
            ar3: '',
            es3: ''
        },
        {
            dt1: 'Nabonid',
            ar3: '',
            bedeutung: 'männl., syrischen Ursprungs; “Fluss, Strom”',
            dt3: '',
            dt2: '',
            ar1: 'ܢܒܘܢܝܕ',
            es3: '',
            es1: 'ܢܒܘܢܝܕ',
            es2: 'ܢܒܘܢܝܕ',
            id: '1920',
            ar2: 'ܢܰܒܽܘܢܺܝܕ'
        },
        {
            ar3: '',
            es1: 'ܫܡܘܐܝܠ',
            ar2: 'ܫܡܽܘܐܝܶܠ',
            dt3: '',
            bedeutung: 'männl., “Geschenk, Gabe, Belohnung”',
            es3: '',
            dt1: 'Schmuel',
            es2: 'ܫܡܘܐܝܠ',
            dt2: '',
            ar1: 'ܫܡܘܐܝܠ',
            id: '2160'
        },
        {
            ar3: '',
            dt2: '',
            es1: 'ܚܙܩܝܐܝܠ',
            ar2: 'ܚܙܩܝܐܝܠ',
            ar1: 'ܚܙܩܝܐܝܠ',
            es3: '',
            es2: 'ܚܙܩܝܐܝܠ',
            bedeutung: '',
            dt3: '',
            dt1: 'Hezekiel',
            id: '475'
        },
        {
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäischen Ursprungs; syrisch-aramäische Göttin, deren Kult aus Hamat nach Samarien eingeführt wurde',
            ar2: 'ܐܰܫܺܝܡܰܐ',
            dt3: '',
            dt1: 'Aschima',
            es2: 'ܐܫܝܡܐ',
            es3: '',
            id: '109',
            ar1: 'ܐܫܝܡܐ',
            es1: 'ܐܫܝܡܐ',
            ar3: '',
            dt2: ''
        },
        {
            ar2: 'ܪܳܚܽܘܒ',
            ar3: '',
            dt2: '',
            dt3: '',
            dt1: 'Rohut',
            es3: '',
            es1: 'ܪܚܘܒ',
            bedeutung: '',
            ar1: 'ܪܚܘܒ',
            es2: 'ܪܚܘܒ',
            id: '2060'
        },
        {
            dt3: '',
            dt2: '',
            ar2: 'ܡܳܪܰܢܙܟ݂ܳܐ',
            ar1: 'ܡܪܢܙܟ݂ܐ',
            id: '1906',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “anmutig”. Im AT ist Naaman Sohn Benjamins; nach dem AT und NT ist Naaman ein Aramäer oder Syrer, Heerführer des Königs von Aram-Damaskus, der unter vielen Aussätzigen ausgewählt und durch den Propheten Elischa geheilt wird. Weitere Formen: Naamon, Naamo, Naim',
            ar3: 'ܡܳܪܰܢ ܙܟ݂ܳܐ',
            es2: 'ܡܪܢܙܟ݂ܐ',
            dt1: 'Moranzkho',
            es3: 'ܡܪܢ ܙܟ݂ܐ',
            es1: 'ܡܪܢܙܟ݂ܐ'
        },
        {
            id: '1687',
            ar3: '',
            es3: '',
            dt1: 'Ilon',
            ar2: 'ܐܺܝܠܳܢ',
            es1: 'ܐܝܠܢ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott hört”. Im AT ist Ischmoel der Sohn von Abraham und Hagar. Weitere Formen: Ismael, Schmoil',
            es2: 'ܐܝܠܢ',
            dt3: '',
            dt2: '',
            ar1: 'ܐܝܠܢ'
        },
        {
            ar1: 'ܝܫܘܥ',
            ar2: 'ܝܶܫܽܘܥ',
            bedeutung: '',
            dt2: 'Joschua',
            es1: 'ܝܫܘܥ',
            ar3: '',
            id: '1135',
            dt1: 'Yeschua',
            es2: 'ܝܫܘܥ',
            dt3: 'Jeschua',
            es3: ''
        },
        {
            ar3: '',
            dt2: 'Elroi',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott, der nach mir schaut”',
            ar2: 'ܐܶܠܪܳܝ',
            id: '310',
            es1: 'ܐܠܪܝ',
            ar1: 'ܐܠܪܝ',
            dt3: '',
            es3: '',
            es2: 'ܐܠܪܝ',
            dt1: 'Elroy'
        },
        {
            dt2: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “mein Gott ist Jahwe”. Im AT ist Elijo ein Prophet (um 850 v.Chr.). Weitere Formen: Elija, Elias, Ilijo, Elia',
            es1: 'ܐܠܝܕܥ',
            dt1: 'Eljada',
            es2: 'ܐܠܝܕܥ',
            ar1: 'ܐܠܝܕܥ',
            id: '1490',
            ar3: '',
            dt3: '',
            es3: '',
            ar2: 'ܐܶܠܝܰܕܰܥ'
        },
        {
            es3: '',
            id: '588',
            dt1: 'Katja',
            es2: 'ܟܬܝܐ',
            ar1: 'ܟܬܝܐ',
            es1: 'ܟܬܝܐ',
            bedeutung: '',
            ar2: 'ܟܰܬܝܰܐ',
            dt3: '',
            ar3: '',
            dt2: ''
        },
        {
            es2: 'ܒܪܨܠܝܒܝ',
            es1: 'ܒܪܨܠܝܒܝ',
            id: '184',
            bedeutung: 'männl., “Sohn des Kreuzes”. Barsalibi ist der Name des syrischen Liturgikers Bischof Dionysius (†1171) von Amid (Diyarbakir). Weitere Formen: Barslibo',
            ar1: 'ܒܪܨܠܝܒܝ',
            dt2: '',
            dt3: '',
            ar3: 'ܒܰܪܨܠܺܝܒܳܐ',
            es3: 'ܒܪܨܠܝܒܐ',
            ar2: 'ܒܰܪܨܰܠܺܝܒܺܝ',
            dt1: 'Barsalibi'
        },
        {
            es1: 'ܒܪܗܕܕ',
            dt1: 'Bar Hadad',
            ar1: 'ܒܪܗܕܕ',
            bedeutung: 'männl., “Sohn des Hadad”. Bar Hadad (I.-III), aramäische Könige von Aram-Damaskus (9. Jh. v.Chr.);weitere Formen: Barhadad, Barhodod',
            id: '153',
            ar3: 'ܒܰܪܚܰܕܰܕ',
            dt3: '',
            dt2: '',
            es2: 'ܒܪܗܕܕ',
            ar2: 'ܒܰܪܗܰܕܰܕ',
            es3: 'ܒܪܚܕܕ'
        },
        {
            es1: 'ܢܘܪܒܐ',
            dt2: '',
            es2: 'ܢܘܪܒܐ',
            ar3: '',
            id: '1975',
            ar2: 'ܢܽܘܪܒܳܐ',
            ar1: 'ܢܘܪܒܐ',
            es3: '',
            dt1: 'Nurbo',
            bedeutung: 'männl., siehe Odenata',
            dt3: ''
        },
        {
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “die Anmutige” oder “Gefallen”; weitere Formen, Terzo, Tarso, Theresa, Tirzah',
            id: '2244',
            es2: 'ܬܐܘܦܘܪܘܣ',
            es1: 'ܬܐܘܦܘܪܘܣ',
            ar1: 'ܬܐܘܦܘܪܘܣ',
            ar3: '',
            es3: '',
            dt1: 'Theophoros',
            ar2: 'ܬܶܐܘܳܦܳܘܪܳܘܣ',
            dt2: '',
            dt3: ''
        },
        {
            es3: '',
            ar2: 'ܐܰܘܕܺܝ',
            es2: 'ܐܘܕܝ',
            es1: 'ܐܘܕܝ',
            dt2: 'Awdi',
            dt3: '',
            id: '127',
            ar1: 'ܐܘܕܝ',
            bedeutung: 'männl., “bekennen”',
            ar3: '',
            dt1: 'Audi'
        },
        {
            dt3: '',
            ar2: 'ܐܰܦܪܰܗܰܛ',
            id: '1272',
            dt2: 'Afrahat',
            ar1: 'ܐܦܪܗܛ',
            es1: 'ܐܦܪܗܛ',
            es2: 'ܐܦܪܗܛ',
            bedeutung: '',
            dt1: 'Aphrahat',
            es3: '',
            ar3: ''
        },
        {
            ar3: '',
            ar2: 'ܢܰܒܳܘܒܳܠܳܘܨܰܪ',
            es3: '',
            id: '731',
            es1: 'ܢܒܘܒܠܘܨܪ',
            ar1: 'ܢܒܘܒܠܘܨܪ',
            bedeutung: 'männl., Nabopolassar war aramäisch-chaldäischer König von Babylon (626 - 605 v. Chr.), Vater des Nabochadnassar II. Er, Gründer des neubabylonischen Reiches, befreite Babylonien von der assyrischen Vorherrschaft und vernichtete 612 v.Chr. im Bündnis mit den Medern das Assyrerreich. Weitere Formen: Nabopolesar, Nabobolossar',
            dt1: 'Nabopolassar',
            dt3: '',
            es2: 'ܢܒܘܒܠܘܨܪ',
            dt2: ''
        },
        {
            id: '981',
            ar2: 'ܫܽܘܫܰܢܰܐ',
            es2: 'ܫܘܫܢܐ',
            ar1: 'ܫܘܫܢܐ',
            dt2: '',
            es1: 'ܫܘܫܢܐ',
            ar3: '',
            dt1: 'Schuschane',
            es3: '',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Lilie”. Im AT ist Schuschane Tochter des Hilkija, eine Frau, die mit ihrem wohlhabenden Ehemann Jojakim zur Zeit des Exils in Babylon lebt. Weitere Formen: Schuschan, Susanne, Susanna, Susan; bei den Aramäern beliebt',
            dt3: ''
        },
        {
            ar2: 'ܛܽܘܒܰܢܰܐ',
            es2: 'ܛܘܒܢܐ',
            es1: 'ܛܘܒܢܐ',
            id: '2268',
            dt2: '',
            bedeutung: 'weibl., “Nation, Heimat”; weitere Formen: Umta',
            ar3: '',
            ar1: 'ܛܘܒܢܐ',
            dt3: '',
            dt1: 'Tubana',
            es3: ''
        },
        {
            dt2: '',
            ar1: 'ܡܪܝܢܐ',
            dt1: 'Marina',
            es2: 'ܡܪܝܢܐ',
            bedeutung: 'männl., syrischen Ursprungs weibl. Stammes; “Herrschat, Macht, Autorität, Souveränität, Unabhängigkeit”. Marutha, Bischof von Maipharqat (†421) war auf dem Konzil von Konstantinopel (381) anwesend; Marutha, Katholikos von Tagrit im Osten (†649), hat das Ninivefasten in der syrisch-orthodoxen Kirche des Ostens, d.h. in der syrischen Kirche in dem damaligen persischen Reich, angeordnet',
            es1: 'ܡܪܝܢܐ',
            dt3: '',
            ar2: 'ܡܰܪܺܝܢܰܐ',
            id: '1859',
            ar3: '',
            es3: ''
        },
        {
            ar2: 'ܢܰܚܫܺܝܪܬܳܢܳܐ',
            es2: 'ܢܚܫܝܪܬܢܐ',
            dt3: '',
            es3: '',
            dt2: '',
            ar1: 'ܢܚܫܝܪܬܢܐ',
            ar3: '',
            bedeutung: 'männl., syrischen Ursprungs; “Jäger, Kämpfer”',
            es1: 'ܢܚܫܝܪܬܢܐ',
            id: '741',
            dt1: 'Nahschirtono'
        },
        {
            ar1: 'ܥܘܨ',
            ar3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs. Im AT ist Us der älteste Sohn Arams; ein Stamm der Aramäer. Weitere Formen: Uz',
            dt2: '',
            es2: 'ܥܘܨ',
            ar2: 'ܥܽܘܨ',
            id: '1099',
            dt3: '',
            dt1: 'Us',
            es3: '',
            es1: 'ܥܘܨ'
        },
        {
            es2: 'ܪܫܦ',
            es3: '',
            ar2: 'ܪܶܫܶܦ',
            dt1: 'Reschef',
            dt3: '',
            ar3: '',
            bedeutung: '',
            es1: 'ܪܫܦ',
            dt2: '',
            id: '2045',
            ar1: 'ܪܫܦ'
        },
        {
            dt3: '',
            ar2: 'ܚܶܠܡܳܐ',
            dt1: 'Helmo',
            id: '466',
            es3: '',
            bedeutung: 'männl., aramäischen Ursprungs; “Traum”',
            es2: 'ܚܠܡܐ',
            es1: 'ܚܠܡܐ',
            ar3: '',
            ar1: 'ܚܠܡܐ',
            dt2: ''
        },
        {
            dt1: 'Elisabeth',
            es1: 'ܐܠܝܫܒܥ',
            dt3: '',
            es2: 'ܐܠܝܫܒܥ',
            es3: '',
            id: '1487',
            ar1: 'ܐܠܝܫܒܥ',
            ar2: 'ܐܶܠܺܝܫܒܰܥ',
            dt2: 'Elishbah',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott hat gegeben”',
            ar3: ''
        },
        {
            bedeutung: '',
            es3: 'ܡܪܬܐ',
            dt2: 'Martha',
            ar2: 'ܡܰܪܬܰܐ',
            ar3: 'ܡܳܪܬܳܐ',
            es1: 'ܡܪܬܐ',
            ar1: 'ܡܪܬܐ',
            dt1: 'Marta',
            es2: 'ܡܪܬܐ',
            id: '1864',
            dt3: ''
        },
        {
            ar2: 'ܢܰܗܪܳܐ',
            es1: 'ܢܗܪܐ',
            es2: 'ܢܗܪܐ',
            id: '1929',
            ar1: 'ܢܗܪܐ',
            dt2: '',
            dt1: 'Nahro',
            bedeutung: 'männl., “Kokosnuss”; weitere Formen: Nargilo',
            es3: '',
            dt3: '',
            ar3: ''
        },
        {
            ar3: 'ܐܺܝܬܐܰܠܰܗܰܐ',
            dt1: 'Ithaloho',
            ar2: 'ܐܺܝܬܐܰܠܳܗܳܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott weıß”',
            ar1: 'ܐܝܬܐܠܗܐ',
            id: '1701',
            dt2: '',
            es3: '',
            es2: 'ܐܝܬܐܠܗܐ',
            dt3: '',
            es1: 'ܐܝܬܐܠܗܐ'
        },
        {
            bedeutung: 'weibl., Katina war eine Stadt in Alt-Syrien',
            ar3: '',
            ar2: 'ܟܰܬܺܝܢܰܐ',
            es1: 'ܟܬܝܢܐ',
            dt1: 'Katina',
            dt3: '',
            dt2: '',
            id: '587',
            es3: '',
            es2: 'ܟܬܝܢܐ',
            ar1: 'ܟܬܝܢܐ'
        },
        {
            dt3: '',
            dt1: 'Kain',
            ar3: 'ܩܰܐܝܺܢ',
            ar1: 'ܩܐܝܢ',
            es2: 'ܩܐܝܢ',
            dt2: '',
            es3: 'ܩܐܝܢ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Schmied”. Im AT ist Kain der erste Sohn von Adam und Eva. Weitere Formen: Koen, Koyen, Qoyen',
            es1: 'ܩܐܝܢ',
            ar2: 'ܩܳܐܝܶܢ',
            id: '572'
        },
        {
            bedeutung: 'männl., König von Guzan/Tell-Halaf, Sohn des Schamasch-Nuri, des Königs von Guzan.',
            ar3: 'ܚܰܕܰܐܺܝܙܺܝ',
            ar1: 'ܗܕܐܝܙܝ',
            ar2: 'ܗܰܕܰܐܺܝܙܺܝ',
            dt2: '',
            dt1: 'Hadajisi',
            es2: 'ܗܕܐܝܙܝ',
            es3: '',
            id: '414',
            dt3: '',
            es1: 'ܗܕܐܝܙܝ'
        },
        {
            dt3: '',
            ar3: '',
            ar2: 'ܐܰܦܪܰܗܰܛ',
            dt1: 'Aphrahat',
            es1: 'ܐܦܪܗܛ',
            bedeutung: 'männl., Aphrahat (†346) ist ein Gelehrter der syrischen Kirche und hat viele Schriften hinterlassen',
            dt2: 'Afrahat',
            id: '92',
            es3: '',
            ar1: 'ܐܦܪܗܛ',
            es2: 'ܐܦܪܗܛ'
        },
        {
            ar1: 'ܒܪܕܝܨܢ',
            ar3: '',
            id: '161',
            es1: 'ܒܪܕܝܨܢ',
            es2: 'ܒܪܕܝܨܢ',
            bedeutung: 'männl., “Sohn des Flusses Daisan”. Bardaisan (†222) war Astrologe und Hymnendichter. Später wurden seine Hymnen von Ephram bekämpft. Weitere Formen: Bardaison, Bardaysan, Bardayson',
            dt2: '',
            dt1: 'Bardaisan',
            es3: '',
            ar2: 'ܒܰܪܕܰܝܨܰܢ',
            dt3: ''
        },
        {
            ar1: 'ܝܗܘܕܝܬ',
            dt2: '',
            dt1: 'Judith',
            es3: 'ܝܗܘܕܝܬ',
            ar2: 'ܝܽܗܘܕܺܝܬ',
            es2: 'ܝܗܘܕܝܬ',
            ar3: 'ܝܶܗܽܘܕܺܝܬ',
            id: '564',
            dt3: '',
            es1: 'ܝܗܘܕܝܬ',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gepriesene, Frau aus Jehud, Jüdin”. Judith ist die Hauptgestalt des nach Ihr benannten alttestamentlichen Buches. Weitere Formen: Judit, Jehudit'
        },
        {
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Schnarcher”. Im AT ist Nahor Erzvater nach der Sintﬂut, Sohn des Serug, Vater des Terach und Ahnherr Abrahams. Ferner trägt ein Bruder Abrahams, Sohn des Terach, diesen Namen. Nach Gen 22,20-24 ist der erstgenannte Nahor der Ahnherr der aramäischen Stämme. Nahor war auch eine Stadt in der Nähe von Haran in Paddan-Aram (Obermesopotamien), wo Abraham eine Frau (Rebekka) für seinen Sohn Isaak aussuchen ließ. Weitere Formen: Nohur',
            dt2: '',
            ar1: 'ܢܒܐ',
            dt1: 'Nabo',
            es3: 'ܢܒܘ',
            id: '1918',
            ar2: 'ܢܰܒܳܐ',
            dt3: '',
            ar3: 'ܢܰܒܽܘ',
            es2: 'ܢܒܐ',
            es1: 'ܢܒܐ'
        },
        {
            bedeutung: 'männl., “Herde, Rudel”. In der Geschichte hat es mehrere Könige von Edessa unter diesem Namen gegeben; weitere Formen: Bakro, Baqra, Baqro',
            es2: 'ܒܩܪܐ',
            ar1: 'ܒܩܪܐ',
            dt1: 'Bakra',
            dt2: '',
            id: '147',
            es3: '',
            ar2: 'ܒܰܩܪܰܐ',
            es1: 'ܒܩܪܐ',
            ar3: '',
            dt3: ''
        },
        {
            ar1: 'ܒܢܝ',
            dt1: 'Bani',
            id: '1330',
            dt2: '',
            ar2: 'ܒܰܢܺܝ',
            es1: 'ܒܢܝ',
            dt3: '',
            es3: '',
            es2: 'ܒܢܝ',
            bedeutung: 'männl., “Sohn des Bahlul”, Lexikograph (um 1000 n.Chr.)',
            ar3: ''
        },
        {
            bedeutung: 'männl., arabischen Ursprungs; “Sonntag”',
            ar3: 'ܟܪܺܝܣܛܺܝܢܰܐ',
            dt3: '',
            dt2: 'Christina',
            es2: 'ܟܪܝܣܬܝܢܐ',
            ar2: 'ܟܪܺܝܣܬܺܝܢܰܐ',
            id: '1794',
            ar1: 'ܟܪܝܣܬܝܢܐ',
            es3: 'ܟܪܝܣܛܝܢܐ',
            dt1: 'Kristina',
            es1: 'ܟܪܝܣܬܝܢܐ'
        },
        {
            es3: '',
            dt2: '',
            bedeutung: 'männl., Nebenform von schumtha “Wunde”',
            ar1: 'ܫܘܡܐ',
            dt1: 'Schuma',
            es2: 'ܫܘܡܐ',
            id: '978',
            ar2: 'ܫܽܘܡܰܐ',
            ar3: '',
            es1: 'ܫܘܡܐ',
            dt3: ''
        },
        {
            es2: 'ܥܙܪܐ',
            ar3: 'ܐܰܙܪܰܐ',
            ar1: 'ܥܙܪܐ',
            es3: 'ܐܙܪܐ',
            ar2: 'ܥܶܙܪܰܐ',
            dt1: 'Esra',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott ist Hilfe“. Im AT ist Esra Priester und Hauptgestalt des nach ihm benannten alttestamentlichen Buches. Weitere Formen: Esro, Ezra, Asra',
            id: '322',
            dt3: 'Asra',
            dt2: 'Esro',
            es1: 'ܥܙܪܐ'
        },
        {
            es2: 'ܣܘܥܕ',
            id: '1011',
            ar2: 'ܣܽܘܥܰܕ',
            ar3: '',
            dt1: 'Suad',
            dt2: '',
            es1: 'ܣܘܥܕ',
            dt3: '',
            ar1: 'ܣܘܥܕ',
            es3: '',
            bedeutung: 'weibl., arabischen Ursprungs; “Selige”'
        },
        {
            es1: 'ܨܠܝܒܙܟ݂ܐ',
            dt2: '',
            ar2: 'ܨܠܺܝܒܳܙܟ݂ܳܐ',
            dt3: '',
            dt1: 'Slibozkho',
            id: '998',
            bedeutung: 'männl., “das Kreuz hat gesiegt”',
            es2: 'ܨܠܝܒܙܟ݂ܐ',
            es3: 'ܨܠܝܒܐ ܙܟ݂ܐ',
            ar1: 'ܨܠܝܒܙܟ݂ܐ',
            ar3: 'ܨܠܺܝܒܳܐ ܙܟ݂ܳܐ'
        },
        {
            es3: '',
            id: '1755',
            bedeutung: 'weibl., “wilde Blume”; weitere Formen: Kalunita, Kalonitha, Kalunitha',
            ar2: 'ܝܘܠܝܰܢܰܐ',
            ar1: 'ܝܘܠܝܢܐ',
            dt1: 'Juliana',
            dt2: 'Yulyana',
            ar3: '',
            es1: 'ܝܘܠܝܢܐ',
            es2: 'ܝܘܠܝܢܐ',
            dt3: 'Julyana'
        },
        {
            dt3: '',
            bedeutung: 'weibl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Lilie”. Im AT ist Schuschane Tochter des Hilkija, eine Frau, die mit ihrem wohlhabenden Ehemann Jojakim zur Zeit des Exils in Babylon lebt. Weitere Formen: Schuschan, Susanne, Susanna, Susan; bei den Aramäern beliebt',
            id: '2164',
            es3: '',
            dt2: '',
            ar2: 'ܫܳܐܘܳܠ',
            dt1: 'Schowol',
            es2: 'ܫܐܘܠ',
            ar1: 'ܫܐܘܠ',
            ar3: '',
            es1: 'ܫܐܘܠ'
        },
        {
            bedeutung: 'weibl., syrischen Ursprungs; “Heimat, Vaterland, Mutterland”; weitere Formen: Matha, Motho',
            dt2: '',
            es1: 'ܡܪܬܝܢܐ',
            ar1: 'ܡܪܬܝܢܐ',
            ar3: '',
            id: '1866',
            es3: '',
            dt1: 'Martina',
            dt3: '',
            es2: 'ܡܪܬܝܢܐ',
            ar2: 'ܡܰܪܬܺܝܢܰܐ'
        },
        {
            id: '1898',
            es1: ' ܡܪܝ ܐܝܓܢܐܛܝܘܣ',
            ar3: '',
            dt1: 'Mor Ignatius',
            es2: ' ܡܪܝ ܐܝܓܢܐܛܝܘܣ',
            dt2: '',
            bedeutung: 'männl., “der Herr hat gegeben”, weitere Formen: Marjab',
            dt3: '',
            es3: '',
            ar2: 'ܡܪܝ ܐܝܓܢܐܛܝܘܣ',
            ar1: 'ܡܪܝ ܐܝܓܢܐܛܝܘܣ'
        },
        {
            ar3: 'ܡܰܪܝܰܗܒ',
            dt1: 'Morjab',
            ar2: 'ܡܳܪܝܰܗܒ',
            es2: 'ܡܪܝܗܒ',
            ar1: 'ܡܪܝܗܒ',
            es3: 'ܡܪܝܗܒ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “anmutig”. Im AT ist Naaman Sohn Benjamins; nach dem AT und NT ist Naaman ein Aramäer oder Syrer, Heerführer des Königs von Aram-Damaskus, der unter vielen Aussätzigen ausgewählt und durch den Propheten Elischa geheilt wird. Weitere Formen: Naamon, Naamo, Naim',
            dt3: '',
            id: '1907',
            es1: 'ܡܪܝܗܒ',
            dt2: ''
        },
        {
            es1: 'ܪܚܡܐ',
            es2: 'ܪܚܡܐ',
            ar1: 'ܪܚܡܐ',
            bedeutung: 'männl., “Liebling, Schatz”',
            ar3: '',
            dt1: 'Rohmo',
            id: '866',
            dt2: '',
            ar2: 'ܪܳܚܡܳܐ',
            dt3: '',
            es3: ''
        },
        {
            dt1: 'Saume',
            es1: 'ܨܘܡܐ',
            es3: '',
            id: '2107',
            es2: 'ܨܘܡܐ',
            ar3: '',
            bedeutung: 'männl., “rein, fromm”',
            dt2: '',
            dt3: '',
            ar2: 'ܨܰܘܡܶܐ',
            ar1: 'ܨܘܡܐ'
        },
        {
            es2: 'ܝܡܐ',
            id: '1126',
            ar1: 'ܝܡܐ',
            bedeutung: 'männl., “Meer”; weitere Formen: Yam, Jamo, Jam',
            dt1: 'Yamo',
            ar2: 'ܝܰܡܳܐ',
            ar3: '',
            dt3: '',
            es1: 'ܝܡܐ',
            dt2: 'Jamo',
            es3: ''
        },
        {
            es2: 'ܗܠܢܐ',
            bedeutung: 'männl., “Vision”. Ahnherr des aramäischen Königs Ben-Hadad I. von Aram-Damaskus (10. Jh.v.Chr.). Weitere Formen: Hezjon, Hesjun',
            dt3: '',
            dt2: '',
            ar3: '',
            ar2: 'ܗܶܠܶܢܰܐ',
            es1: 'ܗܠܢܐ',
            dt1: 'Helena',
            es3: '',
            id: '1651',
            ar1: 'ܗܠܢܐ'
        },
        {
            ar1: 'ܬܝܓܪܝܣ',
            es1: 'ܬܝܓܪܝܣ',
            es3: '',
            id: '2249',
            dt2: '',
            es2: 'ܬܝܓܪܝܣ',
            dt3: '',
            bedeutung: 'männl., Naftaliter, Vater des Tobia; Verfasser eines alttestamentlichen Buches; siehe Tobia',
            ar2: 'ܬܺܝܓܪܺܝܣ',
            dt1: 'Tigris',
            ar3: ''
        },
        {
            es2: 'ܝܗܒܐܠܗܐ',
            ar3: '',
            ar1: 'ܝܗܒܐܠܗܐ',
            ar2: 'ܝܰܗܒܰܐܠܰܗܰܐ',
            dt2: '',
            dt1: 'Yahbalaha',
            bedeutung: 'männl., “Meer-Fluss”. Yam Nahar ist der von den vorchristlichen Aramäern verehrte Meeresgott. Weitere Formen: Yam Nahrin, Yanmahar, Jamnahar, Yamnahar',
            es3: '',
            es1: 'ܝܗܒܐܠܗܐ',
            id: '2307',
            dt3: ''
        },
        {
            id: '557',
            es3: 'ܝܘܝܕܥ',
            es2: 'ܝܘܝܕܥ',
            dt2: '',
            es1: 'ܝܘܝܕܥ',
            ar2: 'ܝܽܘܝܳܕܳܥ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe weiß”',
            ar3: 'ܝܽܘܝܰܕܰܥ',
            ar1: 'ܝܘܝܕܥ',
            dt1: 'Jojada',
            dt3: ''
        },
        {
            dt1: 'Hananja',
            ar2: 'ܚܰܢܰܢܝܰܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe hat Gnade gewirkt”. Im NT begegnet Hananja/Hananias als ein Judenchrist in Damaskus, der in einem Traum die Weisung erhält, dem blinden Saulus (Paulus) durch Auflegen der Hände das Augenlicht zurückzugeben. Weitere Formen: Hananjo, Hananias',
            dt2: 'Hananayel',
            es2: 'ܚܢܢܝܐ',
            ar3: '',
            es1: 'ܚܢܢܝܐ',
            id: '434',
            ar1: 'ܚܢܢܝܐ',
            es3: '',
            dt3: ''
        },
        {
            ar1: 'ܢܒܘܨܪܕܢ',
            dt2: '',
            dt1: 'Nabosaradan',
            es3: '',
            ar2: 'ܢܰܒܳܘܨܰܪܰܕܰܢ',
            id: '732',
            es2: 'ܢܒܘܨܪܕܢ',
            bedeutung: 'männl., akkadischen Ursprungs; “Nabu hat mir Nachkommenschaft gegeben”. Nabusaradan war Kommandant Nabuchadnassars II., der 587/586 v. Chr. Jerusalem eroberte und die Juden deportierte. Weitere Formen: Nebusaradan',
            es1: 'ܢܒܘܨܪܕܢ',
            dt3: '',
            ar3: ''
        },
        {
            ar2: 'ܡܳܟܣܳܐ',
            id: '1895',
            ar3: '',
            dt2: '',
            es3: '',
            es1: 'ܡܟܣܐ',
            bedeutung: '',
            dt1: 'Mokso',
            ar1: 'ܡܟܣܐ',
            es2: 'ܡܟܣܐ',
            dt3: ''
        },
        {
            id: '2070',
            bedeutung: 'weibl., “Gelehrsamkeit, Gelehrtheit, Wissenschaft”; weitere Formen: Sofruta',
            es1: 'ܙܒܝܢܐ',
            dt1: 'Sabine',
            es2: 'ܙܒܝܢܐ',
            dt2: 'Sabina',
            ar3: '',
            ar2: 'ܙܰܒܺܝܢܰܐ',
            dt3: 'Zabina',
            es3: '',
            ar1: 'ܙܒܝܢܐ'
        },
        {
            es2: 'ܪܚܝܡܐ',
            ar1: 'ܪܚܝܡܐ',
            dt3: '',
            ar2: 'ܪܚܺܝܡܳܐ',
            es3: '',
            ar3: '',
            es1: 'ܪܚܝܡܐ',
            dt2: '',
            bedeutung: 'männl., “Liebling, Schatz”',
            dt1: 'Rhimo',
            id: '2049'
        },
        {
            ar3: '',
            dt3: '',
            id: '2243',
            ar1: 'ܬܐܘܦܘܪܐ',
            dt1: 'Theophora',
            es1: 'ܬܐܘܦܘܪܐ',
            bedeutung: '',
            ar2: 'ܬܶܐܘܳܦܳܘܪܰܐ',
            es2: 'ܬܐܘܦܘܪܐ',
            dt2: '',
            es3: ''
        },
        {
            es3: '',
            ar1: 'ܚܘܒܒܐ',
            ar3: '',
            es1: 'ܚܘܒܒܐ',
            id: '1674',
            dt2: '',
            bedeutung: '',
            dt1: 'Hubobo',
            dt3: '',
            es2: 'ܚܘܒܒܐ',
            ar2: 'ܚܽܘܒܳܒܳܐ'
        },
        {
            dt2: '',
            ar3: '',
            es3: '',
            es1: 'ܬܘܟ݂ܠܢ',
            dt3: '',
            ar2: 'ܬܽܘܟ݂ܠܳܢ',
            id: '1077',
            bedeutung: 'männl., “du bist meine Hoffnung / Vertrauen”; weitere Formen: Tuchlono',
            dt1: 'Tuchlon',
            es2: 'ܬܘܟ݂ܠܢ',
            ar1: 'ܬܘܟ݂ܠܢ'
        },
        {
            es1: 'ܢܒܘܢܐܝܕ',
            ar1: 'ܢܒܘܢܐܝܕ',
            ar2: 'ܢܰܒܽܘܢܰܐܝܺܕ',
            dt3: '',
            dt2: '',
            dt1: 'Nebunaid',
            bedeutung: 'männl., aramäisch-chaldäischer König von Babylonien (555 - 539 v. Chr.); weitere Formen: Nabunaid',
            es2: 'ܢܒܘܢܐܝܕ',
            ar3: '',
            id: '757',
            es3: ''
        },
        {
            es1: 'ܒܪܩܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Blitz”. Im AT ist Barak/ Barqo Sohn des Abinoamaus Kadesch (um 1200 v. Chr.). Weitere Formen: Baraqa, Barqa, Barak, Baraka',
            dt1: 'Barqo',
            es2: 'ܒܪܩܐ',
            dt3: '',
            es3: '',
            id: '180',
            ar2: 'ܒܰܪܩܳܐ',
            ar1: 'ܒܪܩܐ',
            ar3: '',
            dt2: ''
        },
        {
            ar1: 'ܣܕܪ',
            dt2: '',
            es2: 'ܣܕܪ',
            es1: 'ܣܕܪ',
            id: '884',
            ar2: 'ܣܰܕܰܪ',
            bedeutung: 'männl., von Sedro “Ordnung, Klasse, Reihe, Kategorie”',
            dt3: '',
            es3: '',
            ar3: '',
            dt1: 'Sadar'
        },
        {
            es1: 'ܢܙܪ',
            bedeutung: 'männl., “keusch, zölibatär, asketisch, (ver)meiden, ausweichen”',
            ar2: 'ܢܰܙܰܪ',
            dt2: 'Nassar',
            es2: 'ܢܙܪ',
            ar3: '',
            ar1: 'ܢܙܪ',
            dt3: '',
            id: '756',
            es3: '',
            dt1: 'Nazar'
        },
        {
            ar3: '',
            es1: 'ܐܚܝܪܡ',
            ar1: 'ܐܚܝܪܡ',
            es2: 'ܐܚܝܪܡ',
            dt1: 'Ahiram',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “mein Bruder ist erhaben”. Im AT ist Ahiram der dritte Sohn Benjamins',
            dt2: '',
            ar2: 'ܐܰܚܺܝܪܰܡ',
            es3: '',
            dt3: '',
            id: '58'
        },
        {
            dt1: 'Qaimi',
            dt2: '',
            es2: 'ܩܝܡܝ',
            id: '812',
            es3: '',
            bedeutung: 'weibl., Kurzform zu Qyomo oder Qyomto “Aufstand, Auferstehung”; weitere Formen: Qaymi, Qajmi',
            dt3: '',
            ar3: '',
            es1: 'ܩܝܡܝ',
            ar2: 'ܩܰܝܡܺܝ',
            ar1: 'ܩܝܡܝ'
        },
        {
            dt2: 'Ahimelech',
            dt1: 'Ahimalko',
            ar2: 'ܐܰܚܺܝܡܰܠܟܐ',
            dt3: 'Ahimelek',
            es2: 'ܐܚܝܡܠܟܐ',
            id: '1234',
            es3: '',
            es1: 'ܐܚܝܡܠܟܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “mein Bruder ist König. Im AT begegnen Ahimalko, Sohn des Ahitub, Priester, Abkömmling Elis, und Ahimalko, Sohn des Abjatar, Priester unter David. Weitere Formen: Ahimelek, Ahimalak, Ahimelech',
            ar1: 'ܐܚܝܡܠܟܐ',
            ar3: ''
        },
        {
            ar3: '',
            id: '183',
            dt1: 'Barsabuni',
            dt2: '',
            ar2: 'ܒܰܪܨܰܒܽܘܢܺܝ',
            ar1: 'ܒܪܨܒܘܢܝ',
            es1: 'ܒܪܨܒܘܢܝ',
            es2: 'ܒܪܨܒܘܢܝ',
            bedeutung: 'männl., viele syrische Bischöfe, Gelehrte, Philosophen und Theologen tragen diesen Namen',
            es3: '',
            dt3: ''
        },
        {
            dt1: 'Farida',
            dt3: '',
            ar2: 'ܦܰܪܺܝܕܰܐ',
            dt2: '',
            id: '334',
            bedeutung: 'weibl. Form zu Farid',
            es3: '',
            es2: 'ܦܪܝܕܐ',
            ar3: '',
            es1: 'ܦܪܝܕܐ',
            ar1: 'ܦܪܝܕܐ'
        },
        {
            dt2: '',
            ar3: '',
            dt1: 'Hamscho',
            id: '429',
            bedeutung: 'männl., “fünf”',
            dt3: '',
            es3: '',
            es1: 'ܚܡܫܐ',
            es2: 'ܚܡܫܐ',
            ar1: 'ܚܡܫܐ',
            ar2: 'ܚܰܡܫܳܐ'
        },
        {
            ar3: '',
            ar2: 'ܒܰܪܢܶܡܪܶܐ',
            dt1: 'Barnemre',
            es1: 'ܒܪܢܡܪܐ',
            dt3: '',
            dt2: '',
            es2: 'ܒܪܢܡܪܐ',
            ar1: 'ܒܪܢܡܪܐ',
            id: '1356',
            es3: '',
            bedeutung: 'männl., “Sohn des Joches”'
        },
        {
            dt2: 'Immanuel',
            ar3: 'ܐܡܢܘܐܝܠ',
            es3: 'ܐܡܢܘܐܝܠ',
            ar2: 'ܥܰܡܰܢܽܘܐܝܶܠ',
            ar1: 'ܥܡܢܘܐܝܠ',
            id: '72',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott ist mit uns”',
            es2: 'ܥܡܢܘܐܝܠ',
            dt1: 'Amanuel',
            dt3: 'Manuel',
            es1: 'ܥܡܢܘܐܝܠ'
        },
        {
            bedeutung: 'weibl. Form zu Barschato; “Tochter des Jahres“, weitere Formen: Batschata',
            dt3: '',
            es2: 'ܒܪܬܫܢܬܐ',
            ar3: '',
            dt2: '',
            dt1: 'Bathschato',
            ar2: 'ܒܰܪ̱ܬܫܰܢ̱ܬܳܐ',
            es1: 'ܒܪܬܫܢܬܐ',
            ar1: 'ܒܪ̱ܬܫܢ̱ܬܐ',
            id: '211',
            es3: ''
        },
        {
            es2: 'ܣܘܣܝ',
            bedeutung: 'weibl., “heilen, gesund machen”; weitere Formen: Susi',
            es1: 'ܣܘܣܝ',
            dt1: 'Sosi',
            dt2: 'Susi',
            ar3: 'ܙܽܘܙܺܝ',
            dt3: '',
            ar1: 'ܣܘܣܝ',
            id: '1007',
            es3: 'ܙܘܙܝ',
            ar2: 'ܣܳܘܣܺܝ'
        },
        {
            es2: 'ܐܒܝܬܪ',
            ar3: '',
            dt2: '',
            es3: '',
            dt3: '',
            es1: 'ܐܒܝܬܪ',
            dt1: 'Abjatar',
            ar2: 'ܐܰܒܝܰܬܰܖ',
            id: '22',
            ar1: 'ܐܒܝܬܖ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “der Vater hat Überﬂuß”. Im AT ist Abjatar ein Priester, Sohn des Ahimelech; weitere Formen: Abyatar'
        },
        {
            es1: 'ܦܗܡܝ',
            id: '331',
            es2: 'ܦܗܡܝ',
            dt1: 'Fahmi',
            dt2: '',
            es3: '',
            ar2: 'ܦܰܗܡܺܝ',
            dt3: '',
            bedeutung: '',
            ar1: 'ܦܗܡܝ',
            ar3: ''
        },
        {
            es3: '',
            id: '2240',
            dt1: 'Theodora',
            dt3: '',
            ar1: 'ܬܐܘܕܘܪܐ',
            es1: 'ܬܐܘܕܘܪܐ',
            ar3: '',
            bedeutung: 'weibl., siehe Deqlath',
            es2: 'ܬܐܘܕܘܪܐ',
            dt2: 'Teodora',
            ar2: 'ܬܶܐܘܳܕܳܘܪܰܐ'
        },
        {
            es2: 'ܫܘܡܐ',
            es3: '',
            ar1: 'ܫܘܡܐ',
            dt2: '',
            ar2: 'ܫܽܘܡܰܐ',
            ar3: '',
            dt3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “der Leuchtende” Im AT sind die Seraphim (im Plural) eine Engelstufe',
            es1: 'ܫܘܡܐ',
            id: '2170',
            dt1: 'Schuma'
        },
        {
            dt2: '',
            es1: 'ܫܘܦܢܝܢ',
            id: '975',
            es3: '',
            ar1: 'ܫܘܦܢܝܢ',
            ar2: 'ܫܽܘܦܢܺܝܢ',
            es2: 'ܫܘܦܢܝܢ',
            dt3: '',
            ar3: '',
            bedeutung: 'weibl., “Turteltaube, Taube”; weitere Formen: Schufnina, Schufninta',
            dt1: 'Schufnin'
        },
        {
            es2: 'ܐܠܪܝ',
            ar3: '',
            ar2: 'ܐܶܠܪܳܝ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischer Vorname; “Mensch, Mann”. Im AT ist Enosch Sohn Sets, Enkel Adams, der als erster den Namen Jahwe angerufen haben soll; im NT ist Enosch Ahnherr Jesu',
            id: '1494',
            es3: '',
            es1: 'ܐܠܪܝ',
            dt1: 'Elroy',
            dt2: 'Elroi',
            ar1: 'ܐܠܪܝ',
            dt3: ''
        },
        {
            id: '1248',
            es1: 'ܣܟܢܕܪ',
            ar1: 'ܣܟܢܕܪ',
            ar2: 'ܣܟܰܢܕܰܪ',
            dt2: 'Iskender',
            es3: 'ܐܣܟܢܕܪ',
            ar3: 'ܐܶܣܟܰܢܕܰܪ',
            bedeutung: 'männl., aus dem Griechischen übernommener Vorname; “der Männerabwehrende, der Schützer”. In der Geschichte ist Alexander der Große, Sohn des Philipp, König von Mazedonien (336-323). Weitere Formen: Alexander, Skender',
            dt3: 'Skandar',
            dt1: 'Alexander',
            es2: 'ܣܟܢܕܪ'
        },
        {
            dt1: 'Schamir',
            bedeutung: 'männl., “Ausrutscher, Patzer”. Scharia war Bischof von Arbela (291-316). Weitere Formen: Schria, Schrio',
            es2: 'ܫܡܝܪ',
            ar2: 'ܫܰܡܺܝܪ',
            es3: '',
            es1: 'ܫܡܝܪ',
            ar1: 'ܫܡܝܪ',
            id: '2130',
            ar3: '',
            dt3: '',
            dt2: ''
        },
        {
            id: '202',
            ar3: '',
            dt2: '',
            dt3: '',
            bedeutung: 'weibl., “Paradies”',
            es1: 'ܒܣܠܝܐ',
            dt1: 'Basselya',
            ar2: 'ܒܰܣܶܠܝܰܐ',
            es3: '',
            ar1: 'ܒܣܠܝܐ',
            es2: 'ܒܣܠܝܐ'
        },
        {
            bedeutung: 'männl., “der Blinde”. König «von Hatra (192 n.Chr.). Weitere Formen: Samjo',
            ar3: '',
            es2: 'ܣܡܝܐ',
            es3: '',
            ar2: 'ܣܰܡܝܰܐ',
            es1: 'ܣܡܝܐ',
            dt1: 'Samja',
            dt2: '',
            ar1: 'ܣܡܝܐ',
            id: '901',
            dt3: ''
        },
        {
            dt1: 'Schah',
            ar2: 'ܫܰܐܗ',
            es3: '',
            ar3: '',
            ar1: 'ܫܐܗ',
            dt3: '',
            es2: 'shah',
            dt2: '',
            id: '2117',
            es1: 'shah',
            bedeutung: 'weibl., “die Ruhe, Stille, Fassung, Gelassenheit, Rast, Pause”; weitere Formen: Schalyutha, Schaljutha, Schaljuta'
        },
        {
            ar2: 'ܥܝܣܐܒܠ',
            id: '506',
            dt2: '',
            dt1: 'Isabell',
            ar1: 'ܐܝܙܒܠ',
            es2: 'ܐܝܙܒܠ',
            es1: 'ܐܝܙܒܠ',
            ar3: 'ܐܺܝܙܰܒܶܠ',
            bedeutung: '',
            dt3: '',
            es3: 'ܐܝܙܒܠ'
        },
        {
            dt2: 'Bar Hebräus',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäischen Ursprungs; “Sohn des Vaters”. Im NT ist Barabba ein Verbrecher in römischer Gefangenschaft, dessen Freilassung die Juden gegen Jesus verlangten. Weitere Formen: Barabbas',
            ar1: 'ܐܒܪܐܣ',
            ar3: '',
            id: '1334',
            es1: 'ܐܒܪܐܣ',
            es2: 'ܐܒܪܐܣ',
            es3: '',
            dt1: 'Bar Hebraeus',
            dt3: 'Äbreyos',
            ar2: 'ܒܪ ܐܒܪܐܣ'
        },
        {
            es1: 'ܢܝܩܠܐܘܤ',
            ar1: 'ܢܝܩܠܐܘܤ',
            ar3: '',
            dt3: '',
            id: '1176',
            ar2: 'ܢܺܩܳܠܰܐܘܣ',
            es2: 'ܢܝܩܠܐܘܤ',
            dt2: 'Nikolaus',
            dt1: 'Nikolas',
            bedeutung: '',
            es3: ''
        },
        {
            bedeutung: 'männl., Asuna war Schüler des Hl. Ephräm, Verfasser vieler Madrosche und Memre',
            ar2: 'ܐܰܣܽܘܢܰܐ',
            dt2: '',
            es1: 'ܐܣܘܢܐ',
            es2: 'ܐܣܘܢܐ',
            id: '113',
            dt3: '',
            es3: '',
            dt1: 'Asuna',
            ar3: '',
            ar1: 'ܐܣܘܢܐ'
        },
        {
            dt3: '',
            dt2: '',
            dt1: 'Mschihozkho',
            ar1: 'ܡܫܝܚܙܟ݂ܐ',
            id: '1912',
            es2: 'ܡܫܝܚܙܟ݂ܐ',
            es3: 'ܡܫܝܚܐ ܙܟ݂ܐ',
            ar3: 'ܡܫܺܝܚܳܐ ܙܟ݂ܳܐ',
            es1: 'ܡܫܝܚܙܟ݂ܐ',
            bedeutung: 'männl., Nabopolassar war aramäisch-chaldäischer König von Babylon (626 - 605 v. Chr.), Vater des Nabochadnassar II. Er, Gründer des neubabylonischen Reiches, befreite Babylonien von der assyrischen Vorherrschaft und vernichtete 612 v.Chr. im Bündnis mit den Medern das Assyrerreich. Weitere Formen: Nabopolesar, Nabobolossar',
            ar2: 'ܡܫܺܝܚܳܙܟ݂ܳܐ'
        },
        {
            bedeutung: 'männl., “Sonntag, Woche”; weitere Formen: Sabo, Schaba',
            es1: 'ܣܪܓܢ',
            ar3: '',
            ar1: 'ܣܪܓܢ',
            ar2: 'ܣܰܪܓܳܢ',
            es3: '',
            dt2: '',
            id: '2102',
            es2: 'ܣܪܓܢ',
            dt3: '',
            dt1: 'Sargon'
        },
        {
            dt1: 'Dimat',
            dt2: 'Dimet',
            es3: 'ܕܝܡܬ',
            ar3: 'ܕܺܝܡܶܬ',
            bedeutung: 'männl., aus dem Griechischen (Demetrius) übernommener Vorname; “Sohn der Erdgöttin Demeter” Dimet oder Demetrius I. ist in der Geschichte König von Syrien (161-150 v. Chr.), sein Sohn Demetrius II. Herrschte dort 145-138 und 129-125 v.Chr.. Dimet gilt in der syrischen Kirche als Heiliger (†408); Festtag: 24. September',
            es2: 'ܕܝܡܛ',
            ar1: 'ܕܝܡܛ',
            id: '272',
            ar2: 'ܕܺܝܡܶܛ',
            dt3: '',
            es1: 'ܕܝܡܛ'
        },
        {
            ar3: 'ܚܳܘܕܰܐ',
            dt2: '',
            es3: 'ܚܘܕܐ',
            dt1: 'Huda',
            es1: 'ܗܘܕܐ',
            ar1: 'ܗܘܕܐ',
            ar2: 'ܗܽܘܕܰܐ',
            es2: 'ܗܘܕܐ',
            dt3: '',
            id: '488',
            bedeutung: 'weibl., Huda war Ehefrau von Abrahat, König von Edessa'
        },
        {
            es3: '',
            ar3: '',
            ar1: 'ܝܠܕܐ',
            es1: 'ܝܠܕܐ',
            dt3: '',
            dt1: 'Yalda',
            id: '2312',
            dt2: '',
            ar2: 'ܝܰܠܕܰܐ',
            bedeutung: 'weibl., “die Kostbare, die Ehrwürdige, Würdevolle, Geliebte”; weitere Formen: Jaqirta',
            es2: 'ܝܠܕܐ'
        },
        {
            es3: 'ܨܢܬܪܟ',
            id: '2096',
            dt2: '',
            ar1: 'ܣܢܬܪܟ',
            dt3: '',
            bedeutung: 'männl., “Vorbau, Vordach, Vorhalle, Portal” oder “ﬂechten, geﬂochtenes Haar”. Stadt in Aram Beth-Nahrin (= Mesopotamien)',
            es2: 'ܣܢܬܪܟ',
            dt1: 'Sanatrak',
            ar2: 'ܣܰܢܰܬܪܰܟ',
            ar3: 'ܨܰܢܰܬܪܰܟ',
            es1: 'ܣܢܬܪܟ'
        },
        {
            es3: 'ܝܐܠܓܝܢ',
            dt1: 'Yalgin',
            es1: 'ܝܐܠܓܢ',
            ar2: 'ܝܰܐܠܓܺܢ',
            dt2: '',
            es2: 'ܝܐܠܓܢ',
            ar3: 'ܝܰܐܠܓܺܝܢ',
            ar1: 'ܝܐܠܓܢ',
            bedeutung: 'männl., “Rubin”; weitere Formen: Yoqunto, Yaqundo, Yoqundo, Yuqando, Yuqanto',
            id: '2313',
            dt3: ''
        },
        {
            dt1: 'Hatun',
            ar1: 'ܗܬܘܢ',
            ar2: 'ܚܬܘܢ',
            id: '454',
            bedeutung: '',
            ar3: 'ܚܰܬܽܘܢ',
            dt2: '',
            es3: 'ܚܬܘܢ',
            dt3: '',
            es1: 'ܗܬܘܢ',
            es2: 'ܗܬܘܢ'
        },
        {
            es3: '',
            es1: 'ܓܘܪܒܘܙ',
            id: '1584',
            es2: 'ܓܘܪܒܘܙ',
            dt3: '',
            bedeutung: 'männl., syrischen Ursprungs; “Blume, Pﬂanze, Blüte”',
            ar2: 'ܓܽܘܪܒܽܘܙ',
            ar1: 'ܓܘܪܒܘܙ',
            dt2: '',
            dt1: 'Gürbüz',
            ar3: ''
        },
        {
            ar2: 'ܫܰܪܒܶܠܰܐ',
            ar1: 'ܫܪܒܠܐ',
            es3: '',
            es1: 'ܫܪܒܠܐ',
            bedeutung: 'weibl. Form von Scharbel',
            dt1: 'Scharbela',
            id: '945',
            dt2: '',
            ar3: '',
            es2: 'ܫܪܒܠܐ',
            dt3: ''
        },
        {
            ar1: 'ܕܢܝܣ',
            es2: 'ܕܢܝܣ',
            es3: '',
            id: '268',
            bedeutung: '',
            es1: 'ܕܢܝܣ',
            ar3: '',
            dt3: '',
            ar2: 'ܕܶܢܺܝܣ',
            dt1: 'Dennis',
            dt2: 'Denis'
        },
        {
            es2: 'ܣܒܪܝܢܐ',
            ar1: 'ܣܒܪܝܢܐ',
            bedeutung: '',
            dt2: '',
            ar3: 'ܙܰܒܪܺܝܢܰܐ',
            ar2: 'ܣܰܒܪܺܝܢܰܐ',
            es3: 'ܙܒܪܝܢܐ',
            dt3: '',
            es1: 'ܣܒܪܝܢܐ',
            dt1: 'Sabrina',
            id: '881'
        },
        {
            id: '159',
            dt3: '',
            es1: 'ܒܪܐܬ',
            es3: '',
            bedeutung: 'männl., “du bist mein Sohn”',
            dt2: '',
            es2: 'ܒܪܐܬ',
            dt1: 'Barat',
            ar2: 'ܒܰܪܐܰܬ',
            ar3: '',
            ar1: 'ܒܪܐܬ'
        },
        {
            es1: 'ܐܡܕܐ',
            es3: '',
            ar2: 'ܐܰܡܶܕܰܐ',
            ar3: '',
            dt3: '',
            id: '1252',
            es2: 'ܐܡܕܐ',
            ar1: 'ܐܡܕܐ',
            dt1: 'Ameda',
            dt2: '',
            bedeutung: 'weibl., siehe Amed'
        },
        {
            bedeutung: 'männl., “Herrscher, Machthaber, Kommandant, Leiter, Gebieter, Minister”; weitere Formen: Schalita',
            dt2: '',
            es2: 'ܫܦܝܪܬܐ',
            es3: '',
            ar2: 'ܫܰܦܺܝܪܬܰܐ',
            id: '2114',
            ar1: 'ܫܦܝܪܬܐ',
            es1: 'ܫܦܝܪܬܐ',
            ar3: '',
            dt3: '',
            dt1: 'Schafirta'
        },
        {
            dt1: 'Abhai',
            es3: '',
            es2: 'ܐܒܚܝ',
            es1: 'ܐܒܚܝ',
            bedeutung: 'männl., “der Vater ist lebendig, der Vater lebt”. Abhai ist ein Heiliger in der syrisch-orthodoxen Kirche; Festtag: 15. Juli',
            ar3: '',
            dt2: '',
            ar1: 'ܐܒܚܝ',
            ar2: 'ܐܰܒܚܰܝ',
            id: '15',
            dt3: ''
        },
        {
            dt1: 'Anton',
            dt3: '',
            es3: '',
            ar1: 'ܐܢܛܘܢ',
            ar2: 'ܐܰܢܛܽܘܢ',
            ar3: '',
            dt2: '',
            bedeutung: '',
            id: '91',
            es1: 'ܐܢܛܘܢ',
            es2: 'ܐܢܛܘܢ'
        },
        {
            ar1: 'ܚܘܪܐ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebrâischen Ursprungs; “meine Kraft ist Jahwe”. Im AT ist Hiskija Sohn und Nachfolger des Ahas als König von Juda (725/15 - 697/87 v. Chr.). Weitere Formen: Hesakjo, Hesakja, Hesaqjo, Hesaqja',
            id: '1660',
            ar2: 'ܚܶܘܳܪܳܐ',
            es3: '',
            es1: 'ܚܘܪܐ',
            dt2: '',
            dt3: '',
            ar3: '',
            dt1: 'Heworo',
            es2: 'ܚܘܪܐ'
        },
        {
            es2: 'ܢܘܪܐܝܠ',
            ar2: 'ܢܽܘܪܺܐܝܠ',
            ar3: '',
            id: '1977',
            ar1: 'ܢܘܪܐܝܠ',
            dt3: '',
            dt2: '',
            es1: 'ܢܘܪܐܝܠ',
            es3: '',
            bedeutung: 'männl., siehe Odar',
            dt1: 'Nuril'
        },
        {
            ar2: 'ܬܶܐܘܳܦܳܘܪܳܘܣ',
            dt2: '',
            es1: 'ܬܐܘܦܘܪܘܣ',
            es3: '',
            ar1: 'ܬܐܘܦܘܪܘܣ',
            es2: 'ܬܐܘܦܘܪܘܣ',
            bedeutung: 'männl., aus dem griechischen “theós” Gott und “phoros”: tragend = “Gottesträger”; weitere Formen: Theophor',
            dt1: 'Theophoros',
            ar3: '',
            id: '1052',
            dt3: ''
        },
        {
            dt1: 'Hedrulo',
            es3: '',
            ar1: 'ܗܕܪܘܠܐ',
            dt3: '',
            es1: 'ܗܕܪܘܠܐ',
            ar2: 'ܗܶܕܪܽܘܠܳܐ',
            id: '1649',
            es2: 'ܗܕܪܘܠܐ',
            ar3: '',
            bedeutung: 'männl., griechischen Ursprungs; “Gewürz”',
            dt2: ''
        },
        {
            es3: '',
            ar2: 'ܨܰܦܝܽܘܬܰܐ',
            dt3: '',
            es2: 'ܨܦܝܘܬܐ',
            ar3: '',
            bedeutung: 'männl., arabisierte Form zu Sem, Schem, Schim',
            dt2: '',
            ar1: 'ܨܦܝܘܬܐ',
            es1: 'ܨܦܝܘܬܐ',
            dt1: 'Safyuta',
            id: '2080'
        },
        {
            es1: 'ܡܪܝ ܡܠܟܐ',
            bedeutung: 'männl., “Christus ist barmherzig”. Mschihorahme (5.Jh.) war ein ostsyrischer Mönch in Arbela. Weitere Formen: Mschiharahme',
            dt1: 'Mor Malke',
            ar2: 'ܡܪܝ ܡܠܟܐ',
            es3: '',
            ar3: '',
            dt3: '',
            ar1: 'ܡܪܝ ܡܠܟܐ',
            dt2: '',
            id: '1902',
            es2: 'ܡܪܝ ܡܠܟܐ'
        },
        {
            dt2: '',
            ar2: 'ܐܰܒܓܰܪ',
            id: '14',
            ar1: 'ܐܒܓܪ',
            es1: 'ܐܒܓܪ',
            es2: 'ܐܒܓܪ',
            ar3: '',
            dt3: '',
            dt1: 'Abgar',
            es3: '',
            bedeutung: 'männl., wohl armenisch-syrischen Ursprungs; “derLahme, Invalide, Körperbehinderte” oder “Festhalter, Anhalter, Aufhalter, Verhüter”. Abgar mit dem Beinamen Ukkama/ Ukkomo “der Schwarze”, der Aramäer, war König von Edessa/ Urhoi in Aram Beth-Nahrin (4v.-50 n. Chr.). In der Kirchengeschichte geht die Christianisienıng der Stadt Edessa und ihrer Umgebung auf seine Initiative und Einladung zurück; Festtag: 2. April'
        },
        {
            dt1: 'Ben Hadad',
            dt3: '',
            dt2: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe baut auf”',
            ar2: 'ܒܶܢܗܰܕܰܕ',
            es1: 'ܒܢܗܕܕ',
            es2: 'ܒܢܗܕܕ',
            ar1: 'ܒܢܗܕܕ',
            ar3: 'ܒܶܢܚܰܕܰܕ',
            id: '1397',
            es3: 'ܒܢܚܕܕ'
        },
        {
            bedeutung: 'männl., aus dem Griechischen übernommener Vorname; “der Landsmann/Landwirt”. Mor Gewargis gilt in der Geschichte als Drachentöter und Schutzpatron der Waffenschmiede, Krieger und Landleute, einer der 14 Nothelfer. Weitere Formen: Gewargi, Georg, George, Corc, Circis',
            dt2: '',
            id: '379',
            es1: 'ܓܘܪܓܝ',
            dt1: 'Gewargi',
            ar2: 'ܓܶܘܪܓܺܝ',
            dt3: '',
            ar3: '',
            es2: 'ܓܘܪܓܝ',
            ar1: 'ܓܘܪܓܝ',
            es3: ''
        },
        {
            id: '670',
            dt3: '',
            dt2: '',
            bedeutung: '',
            ar2: 'ܡܰܪܺܝܢ',
            ar3: '',
            es1: 'ܡܪܝܢ',
            ar1: 'ܡܪܝܢ',
            dt1: 'Marin',
            es2: 'ܡܪܝܢ',
            es3: ''
        },
        {
            es1: 'ܢܚܘܡ',
            ar3: 'ܢܳܚܽܘܡ',
            ar1: 'ܢܚܘܡ',
            ar2: 'ܢܰܚܳܘܡ',
            id: '742',
            es2: 'ܢܚܘܡ',
            dt2: '',
            es3: 'ܢܚܘܡ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Tröster, Jahwe hat getröstet”. Im AT ist Nahum ein Prophet, der kurz vor 612 den Untergang Ninives ankündigte. Weitere Formen: Nohum',
            dt1: 'Nahum',
            dt3: ''
        },
        {
            es1: 'ܬܝܓܠܬ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Gott ist gut”. Im AT ist Tobiel ein Naftaliter, Vater des Tobit und Großvater des Tobia',
            dt2: '',
            ar2: 'ܬܺܝܓܠܰܬ',
            ar1: 'ܬܝܓܠܬ',
            es2: 'ܬܝܓܠܬ',
            dt1: 'Tiglat',
            ar3: '',
            es3: '',
            id: '2248',
            dt3: ''
        },
        {
            ar1: 'ܚܙܘܪܬܐ',
            ar2: 'ܚܰܙܽܘܪܬܰܐ',
            es1: 'ܚܙܘܪܬܐ',
            dt3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Eingeweihter, Einweihung”. Im AT ist Henoch Sohn des Kain, im NT Ahnherr Jesu. Weitere Formen: Hnuch',
            ar3: '',
            es2: 'ܚܙܘܪܬܐ',
            dt1: 'Hazurta',
            es3: '',
            dt2: '',
            id: '1646'
        },
        {
            dt3: '',
            ar2: 'ܝܽܘܝܰܩܺܝܡ',
            ar3: '',
            dt2: '',
            es3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “Jahwe ist erhaben”',
            es1: 'ܝܘܝܩܝܡ',
            es2: 'ܝܘܝܩܝܡ',
            id: '1739',
            ar1: 'ܝܘܝܩܝܡ',
            dt1: 'Joachim'
        },
        {
            es3: 'ܝܢܢܝ',
            es1: 'ܝܘܚܢܢ',
            dt2: '',
            bedeutung: 'männl., amerikanische Variante zu Johannes',
            id: '559',
            es2: 'ܝܘܚܢܢ',
            ar2: 'ܝܢܢܝ',
            dt3: '',
            dt1: 'Jonny',
            ar1: 'ܝܘܚܢܢ',
            ar3: 'ܝܳܢܢܺܝ'
        },
        {
            dt1: 'Dan',
            es1: 'ܕܐܢ',
            es3: 'ܕܐܢ',
            es2: 'ܕܐܢ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “richten”. Im AT ist Dan Sohn Jakobs und der Rahelmagd Bilha',
            dt3: '',
            ar3: 'ܕܳܐܢ',
            ar2: 'ܕܰܐܢ',
            ar1: 'ܕܐܢ',
            id: '256',
            dt2: 'Don'
        },
        {
            es2: 'ܕܩܠܐ',
            dt3: '',
            bedeutung: 'weibl., Fluss in Aram Beth-Nahrin (= Mesopotamien)',
            es3: '',
            es1: 'ܕܩܠܐ',
            ar2: 'ܕܶܩܠܳܐ',
            ar1: 'ܕܩܠܐ',
            dt2: '',
            id: '1454',
            dt1: 'Deqlo',
            ar3: ''
        },
        {
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “der Friedliche, der Friedfertige” oder “Friede, Wohlergehen“. Im AT ist Schleymun Sohn Davids und König von Israel (970 - 931). Weitere Formen: Saalomo, Salomon, Schelomoh, Suleyman; bei den Aramäern beliebt',
            es2: 'ܫܡܪܒ',
            es1: 'ܫܡܪܒ',
            ar3: '',
            dt3: '',
            es3: '',
            ar2: 'ܫܶܡܪܰܒ',
            id: '2147',
            dt1: 'Schemrab',
            ar1: 'ܫܡܪܒ',
            dt2: ''
        },
        {
            es2: 'ܘܪܕܐ',
            dt3: '',
            ar1: 'ܘܪܕܐ',
            dt2: '',
            dt1: 'Wardo',
            ar3: '',
            es3: '',
            id: '1107',
            es1: 'ܘܪܕܐ',
            ar2: 'ܘܰܪܕܳܐ',
            bedeutung: 'weibl., “Blumen”'
        },
        {
            es2: 'ܠܒܘܢܬܐ',
            ar2: 'ܠܒܽܘܢܬܰܐ',
            bedeutung: 'weibl., syrischen Ursprungs; “Weihrauch”',
            ar3: '',
            ar1: 'ܠܒܘܢܬܐ',
            dt3: '',
            dt1: 'Lbunta',
            id: '625',
            dt2: '',
            es1: 'ܠܒܘܢܬܐ',
            es3: ''
        },
        {
            bedeutung: 'männl., “Schatz, Fiskus”',
            es3: 'ܓܐܙܐ',
            ar3: 'ܓܰܐܙܳܐ',
            dt3: '',
            es2: 'ܓܙܐ',
            es1: 'ܓܙܐ',
            ar2: 'ܓܰܙܳܐ',
            dt1: 'Gazo',
            id: '369',
            dt2: '',
            ar1: 'ܓܙܐ'
        },
        {
            dt2: '',
            es3: 'ܗܕܕ',
            dt3: '',
            es1: 'ܗܕܕ',
            ar3: 'ܗܳܕܳܕ',
            bedeutung: 'männl., “der Donnerer“. Hadad mit dem Beinamen Rammon galt bei den vorchristlichen Aramäern von Aram-Naharaim als “Wetter- (Gewitter-) und Sturmgott”. Weitere Formen: Hodod, Adad, Hadodo',
            ar2: 'ܚܰܕܰܕ',
            id: '411',
            ar1: 'ܗܕܕ',
            es2: 'ܗܕܕ',
            dt1: 'Hadad'
        },
        {
            dt2: '',
            ar3: 'ܝܰܚܝܰܐ',
            ar1: 'ܐܚܝܐ',
            es1: 'ܐܚܝܐ',
            es3: 'ܝܚܝܐ',
            dt3: '',
            dt1: 'Jehija',
            ar2: 'ܐܰܚܺܝܰܐ',
            bedeutung: 'männl., “Jesus ist der Aufgang”',
            id: '1718',
            es2: 'ܐܚܝܐ'
        },
        {
            es1: 'ܫܡܥܝܐ',
            bedeutung: 'weibl., “Trompete”; weitere Formen: Schifura',
            es3: '',
            ar2: 'ܫܡܰܥܝܳܐ',
            es2: 'ܫܡܥܝܐ',
            ar3: '',
            dt2: '',
            id: '2145',
            dt3: '',
            dt1: 'Schemaja',
            ar1: 'ܫܡܥܝܐ'
        },
        {
            es2: 'ܢܘܚܡܐ',
            dt2: '',
            es1: 'ܢܘܚܡܐ',
            ar2: 'ܢܽܘܚܳܡܳܐ',
            ar3: '',
            dt3: '',
            ar1: 'ܢܘܚܡܐ',
            id: '777',
            es3: '',
            dt1: 'Nuhomo',
            bedeutung: 'männl., syrischen Ursprungs; “Auferstehung, Aufstieg”'
        },
        {
            id: '328',
            es3: '',
            ar2: 'ܦܰܒܪܽܘܢܝܰܐ',
            ar3: '',
            es2: 'ܦܒܪܘܢܝܐ',
            ar1: 'ܦܒܪܘܢܝܐ',
            bedeutung: 'weibl., Nonne, Märtyrerin (†3./4. Jh.); weitere Formen: Fabro, Febro',
            es1: 'ܦܒܪܘܢܝܐ',
            dt3: '',
            dt1: 'Fabronya',
            dt2: ''
        },
        {
            dt1: 'Ziwo',
            es2: 'ܙܝܘܐ',
            es1: 'ܙܝܘܐ',
            ar2: 'ܙܺܝܘܳܐ',
            ar1: 'ܙܝܘܐ',
            dt2: '',
            dt3: '',
            id: '1172',
            ar3: '',
            es3: '',
            bedeutung: 'männl., “Leuchte, Helligkeit, Leuchten, Glanz”, manchmal auch weiblich'
        },
        {
            ar3: '',
            ar2: 'ܥܰܒܕܰܐܠܠܰܗ',
            dt3: '',
            bedeutung: 'männl., syrischen Ursprungs;  “Knecht Gottes, Knecht Jahwes”',
            dt2: '',
            es2: 'ܥܒܕܐܠܠܗ',
            es1: 'ܥܒܕܐܠܠܗ',
            id: '1184',
            ar1: 'ܥܒܕܐܠܠܗ',
            es3: '',
            dt1: 'Abdallah'
        },
        {
            ar2: 'ܚܺܐܪܽܘܬܰܐ',
            bedeutung: 'weibl., syrischen Ursprungs; “Freiheit, Unabhängigkeit”; weitere Formen: Hirutha',
            ar3: '',
            dt1: 'Hiruta',
            dt2: '',
            es2: 'ܚܐܪܘܬܐ',
            es3: '',
            es1: 'ܚܐܪܘܬܐ',
            dt3: '',
            id: '480',
            ar1: 'ܚܐܪܘܬܐ'
        },
        {
            ar3: '',
            id: '441',
            dt1: 'Hanno',
            ar2: 'ܚܰܢܢܳܐ',
            bedeutung: 'männl., siehe Hanna',
            es3: '',
            dt3: '',
            es1: 'ܚܢܢܐ',
            dt2: '',
            es2: 'ܚܢܢܐ',
            ar1: 'ܚܢܢܐ'
        },
        {
            dt1: 'Amram',
            es3: '',
            ar1: 'ܥܡܪܡ',
            ar3: '',
            bedeutung: 'weibl., “Dienstmädchen”',
            es2: 'ܥܡܪܡ',
            dt3: '',
            dt2: '',
            es1: 'ܥܡܪܡ',
            id: '1263',
            ar2: 'ܥܰܡܪܰܡ'
        },
        {
            bedeutung: '',
            id: '111',
            ar2: 'ܐܰܫܽܘܪ',
            ar3: '',
            ar1: 'ܐܫܘܪ',
            es2: 'ܐܫܘܪ',
            dt1: 'Aschur',
            dt3: 'Assur',
            es3: '',
            dt2: 'Ashur',
            es1: 'ܐܫܘܪ'
        },
        {
            es3: '',
            es2: 'ܐܘܪܝܠ',
            id: '2284',
            bedeutung: '',
            es1: 'ܐܘܪܝܠ',
            dt1: 'Uriel',
            ar3: '',
            ar2: 'ܐܽܘܪܺܝܶܠ',
            ar1: 'ܐܘܪܝܠ',
            dt3: '',
            dt2: ''
        },
        {
            dt1: 'Gelgamesch',
            ar1: 'ܓܠܓܐܡܝܫ',
            bedeutung: 'weibl., “Weinstock”',
            dt3: '',
            es2: 'ܓܠܓܐܡܝܫ',
            id: '1560',
            ar2: 'ܓܠܓܐܡܝܫ',
            ar3: '',
            es3: '',
            dt2: 'Gilgamesch',
            es1: 'ܓܠܓܐܡܝܫ'
        },
        {
            es1: 'ܣܪܐ',
            es2: 'ܣܪܐ',
            dt2: '',
            dt3: '',
            id: '908',
            bedeutung: 'weibl., Nebenform zu Sara',
            es3: '',
            ar2: 'ܣܰܪܶܐ',
            ar1: 'ܣܪܐ',
            dt1: 'Sare',
            ar3: ''
        },
        {
            dt3: '',
            bedeutung: 'männl., aus dem griechischen “theós”: Gott und “philos”: freundlich, lieb, Freund = “Gottesfreund, -freudiger”; Patriarch der syrisch-orthodoxen Kirche von Antiochien (2. Jh.). Weitere Formen: Theophil',
            ar3: '',
            ar2: 'ܬܰܝܰܪ',
            es2: 'ܬܝܪ',
            id: '2233',
            dt2: '',
            dt1: 'Tayar',
            es3: '',
            es1: 'ܬܝܪ',
            ar1: 'ܬܝܪ'
        },
        {
            es3: '',
            dt2: '',
            id: '1205',
            es1: 'ܐܒܪܡ',
            ar1: 'ܐܒܪܡ',
            dt3: '',
            bedeutung: 'männl., ursprünglicher Name Abrohoms; “Vater ist erhaben“, weitere Formen: Abiram, Abiramu',
            ar2: 'ܐܰܒܪܰܡ',
            dt1: 'Abram',
            es2: 'ܐܒܪܡ',
            ar3: ''
        },
        {
            bedeutung: 'männl., “Bruder seiner Schwester”. Ahudemeh, ein Heiliger der syrisch-orthodoxen Kirche, war Metropolit und Katholikos (Maphiryono) des Ostens (†575), Missionar unter den Arabern; Festtag: 18. September',
            es2: 'ܐܚܘܕܡܗ',
            dt1: 'Ahudemeh',
            dt3: '',
            ar2: 'ܐܰܚܽܘܕܶܡܶܗ',
            id: '63',
            es3: '',
            ar3: '',
            es1: 'ܐܚܘܕܡܗ',
            ar1: 'ܐܚܘܕܡܗ',
            dt2: ''
        },
        {
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “der Onkel ist erhaben”',
            ar2: 'ܥܰܡܳܘܣ',
            dt1: 'Amos',
            ar3: '',
            es3: '',
            ar1: 'ܥܡܘܣ',
            es1: 'ܥܡܘܣ',
            dt3: '',
            id: '1262',
            es2: 'ܥܡܘܣ',
            dt2: ''
        },
        {
            dt2: '',
            es1: 'ܝܗܒܐܝܠ',
            ar2: 'ܝܰܗܒܐܺܝܠ',
            dt1: 'Yahbil',
            bedeutung: 'männl., “du bist mein Meer” oder “du bist meine Rechte”',
            ar1: 'ܝܗܒܐܝܠ',
            id: '2308',
            es3: '',
            es2: 'ܝܗܒܐܝܠ',
            dt3: '',
            ar3: ''
        },
        {
            ar3: '',
            ar2: 'ܐܰܪܢܺܝ',
            es3: '',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs. Im AT ist Arpachschad Sohn des Sem und Bruder des Aram; im NT ist er Ahnherr Jesu. Die Arpachschäer, die auch Chaldäer heißen, gehören zum aramäischen Stamm',
            ar1: 'ܐܪܢܝ',
            dt2: '',
            id: '1283',
            es2: 'ܐܪܢܝ',
            dt3: '',
            dt1: 'Arni',
            es1: 'ܐܪܢܝ'
        },
        {
            ar2: 'ܢܰܥܡܰܢ',
            dt3: '',
            dt1: 'Naaman',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs; “ich habe gekämpt”, Im AT ist Naﬂali Sohn Jakobs und der Bilha, der Magd Rahels. Weitere Formen: Naﬁoli, Naphtali',
            es1: 'ܢܥܡܢ',
            es3: '',
            ar3: '',
            dt2: '',
            id: '1915',
            es2: 'ܢܥܡܢ',
            ar1: 'ܢܥܡܢ'
        },
        {
            es1: 'ܡܫ',
            bedeutung: 'männl., aus der Bibel übernommener Vorname aramäisch-hebräischen Ursprungs. Im AT ist Masch einer der vier Söhne Arams. Ein aramäischer Stamm, der vielen Orientalisten zufolge im heutigen Turabdin, dem alten Gebirge Masch/Masius gelebt haben soll; demnach wären die heutigen Suryoye/Syrer/Aramäer von Turabdin die Nachfahren dieses aramäischen Stammes. Weitere Formen: Mosch, Mas, Mascha',
            dt2: 'Mosch',
            es3: 'ܡܫ',
            ar3: 'ܡܳܫ',
            dt1: 'Masch',
            id: '684',
            ar1: 'ܡܫ',
            dt3: '',
            es2: 'ܡܫ',
            ar2: 'ܡܰܫ'
        },
        {
            dt2: '',
            id: '2211',
            dt3: '',
            ar2: 'ܫܽܘܫܰܢ',
            dt1: 'Susan',
            ar1: 'ܫܘܫܢ',
            es3: '',
            es1: 'ܫܘܫܐ',
            es2: 'ܫܘܫܐ',
            ar3: '',
            bedeutung: 'männl., “Frühling”; weitere Formen: Tadwo'
        },
        {
            ar2: 'ܦܶܠܶܐܬܰܐ',
            es3: '',
            ar1: 'ܦܠܐܬܐ',
            dt1: 'Feleta',
            dt3: '',
            es2: 'ܦܠܐܬܐ',
            es1: 'ܦܠܐܬܐ',
            bedeutung: 'weibl., “Parabel, Allegorie, Rätsel, Gleichnis“, weitere Formen: Peleta, Peletha',
            ar3: '',
            id: '339',
            dt2: ''
        },
        {
            dt2: '',
            ar1: 'ܛܝܡܝ',
            es3: '',
            es1: 'ܛܝܡܝ',
            id: '2250',
            dt3: '',
            ar3: '',
            dt1: 'Timai',
            bedeutung: 'männl., “der Gute”',
            es2: 'ܛܝܡܝ',
            ar2: 'ܛܺܝܡܰܝ'
        },
        {
            es2: 'ܡܛܪܬܐ',
            dt1: 'Matarta',
            bedeutung: 'weibl., syrischen Ursprungs; “Wache, Obhut, Schutz, Station”',
            dt3: '',
            ar3: '',
            es3: '',
            ar2: 'ܡܰܛܰܪܬܰܐ',
            es1: 'ܡܛܪܬܐ',
            id: '688',
            ar1: 'ܡܛܪܬܐ',
            dt2: ''
        },
        {
            ar3: 'ܒܺܝܡܰܐ',
            ar1: 'ܒܐܡܐ',
            es3: 'ܒܝܡܐ',
            dt2: 'Bema',
            id: '231',
            ar2: 'ܒܺܐܡܰܐ',
            dt3: '',
            es2: 'ܒܐܡܐ',
            dt1: 'Bima',
            es1: 'ܒܐܡܐ',
            bedeutung: 'weibl., griechischen Ursprungs; “Bühne, Tnibunal, Kanzel, Stufe, Podium”; weitere Formen: Bema'
        },
        {
            es2: 'ܙܝܠܘܝܐ',
            id: '994',
            ar1: 'ܙܝܠܘܝܐ',
            ar2: 'ܙܝܠܘܐܝܐ',
            es1: 'ܙܝܠܘܝܐ',
            es3: 'ܣܝܠܘܝܐ',
            ar3: 'ܣܝܠܘܝܐ',
            bedeutung: '',
            dt2: '',
            dt3: '',
            dt1: 'Silvia'
        },
        {
            ar2: 'ܫܠܳܡܰܬ',
            es3: '',
            bedeutung: 'männl., “du bist mein Frieden”',
            dt3: '',
            ar3: '',
            id: '966',
            dt1: 'Schlomat',
            dt2: '',
            ar1: 'ܫܠܡܬ',
            es1: 'ܫܠܡܬ',
            es2: 'ܫܠܡܬ'
        },
        {
            ar2: 'ܗܰܢܺܝܐܽܘܬܰܐ',
            id: '1625',
            ar3: '',
            es3: '',
            ar1: 'ܗܢܝܐܘܬܐ',
            es1: 'ܗܢܝܐܘܬܐ',
            dt2: '',
            bedeutung: 'weibl., Hariata war eine Stadt in Alt-Syrien',
            es2: 'ܗܢܝܐܘܬܐ',
            dt1: 'Haniyuta',
            dt3: ''
        },
        {
            id: '2351',
            dt3: '',
            dt1: 'Zalgat',
            dt2: '',
            es2: 'ܙܠܓܬ',
            bedeutung: 'männl. Form zu Zenobia',
            ar2: 'ܙܰܠܓܰܬ',
            es3: '',
            ar3: '',
            ar1: 'ܙܠܓܬ',
            es1: 'ܙܠܓܬ'
        }
];
