import {Injectable} from '@angular/core';
import {ToastController} from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class ToastService {


    constructor(private toastCtrl: ToastController) {
    }

    async toast(msg: string, myCss: string, duration = 1500) {
        const toast = await this.toastCtrl.create({
            message: msg,
            duration,
            cssClass: myCss
        });
        toast.present();
    }
}
