import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
  @Input() disableSubmitButton: boolean;
  @Input() color: string;
  @Input() buttonText: string;
  @Input() size: string; // small | large | default
  @Input() expand: string; // block | full
  @Input() fill: string; // clear | outline | solid
  @Input() shape: string; // round | undefined
  @Input() strong: boolean; // round - If true, activates a button with a heavier font weight.

  constructor() { }

  ngOnInit() {}

}
