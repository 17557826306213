import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SettingComponent} from '../../popovers/setting/setting.component';
import {PopoverService} from '../../services/popover.service';
import {GLOBALS} from '../../data/global';

@Component({
selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
    GLOBALS = GLOBALS;
    @Output() myEmitter: EventEmitter<any> = new EventEmitter();
    @Input() tageNochZuTesten: number;
    @Input() showTestDauerBanner: boolean;

    constructor(
        private popover: PopoverService
    ) {
    }

    async showPopoverSettings(ev: any) {
        this.popover.showPopover(ev, SettingComponent);
    }

    activateKeyboard() {
        this.GLOBALS.isKeyboardActive = !this.GLOBALS.isKeyboardActive;
        this.myEmitter.emit();
    }
}
