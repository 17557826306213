import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-headereasy',
    templateUrl: './headereasy.component.html',
    styleUrls: ['./headereasy.component.scss'],
})
export class HeadereasyComponent {
    @Input() title = '';
    @Output() myEmitter = new EventEmitter();

    dismiss() {
        this.myEmitter.emit('START GAME');
    }
}
