import {Component, Input} from '@angular/core';
import {CommonService} from '../../services/common.service';
import * as _ from 'lodash-es';

@Component({
    selector: 'app-cardwithimage',
    templateUrl: './cardwithimage.component.html',
    styleUrls: ['./cardwithimage.component.scss'],
})
export class CardwithimageComponent {
    @Input() items: any = [];
    @Input() path = '';

    constructor(
        public common: CommonService
    ) {
    }
}
