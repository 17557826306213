export const GLOBALS = {
    SFARMELE: [],
    NAMEN: [],
    SFARMELEDOCID: [],
    isWordSearchedPerSpeach: false,
    isKeyboardActive: false,
    konfigurationen: [],
    alphabet: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'Y', 'Z'],
    spalten: ['dt1', 'dt2', 'dt3', 'sy4', 'sy5', 'sy6', 'sy7', 'sy8', 'sy9', 'lt10', 'lt11', 'lt12'],
    olafbeth: ['ܐ', 'ܒ', 'ܓ', 'ܕ', 'ܗ', 'ܘ', 'ܙ', 'ܚ', 'ܛ', 'ܝ', 'ܟ', 'ܠ', 'ܡ', 'ܢ', 'ܣ', 'ܥ', 'ܦ', 'ܨ', 'ܩ', 'ܪ', 'ܫ', 'ܬ'],
    olafbethAll: ['̈ ', '̱', '݂', 'ܰ', 'ܳ', 'ܶ', 'ܺ', 'ܽ', 'ܟ݂', 'ܓ̣', 'ܦ̣', 'ܬ̣', 'ܬ', 'ܫ', 'ܪ', 'ܩ', 'ܨ', 'ܦ', 'ܥ', 'ܣ', 'ܢ', 'ܡ', 'ܠ', 'ܟ', 'ܝ', 'ܛ', 'ܚ', 'ܙ', 'ܘ', 'ܗ', 'ܕ', 'ܓ', 'ܒ', 'ܐ'],
    olafbethI: ['olaf', 'beth', 'gomal', 'dolath', 'he', 'wauw', 'zay', 'heth', 'teth', 'yuth', 'kof', 'lomad', 'mim', 'nun', 'semkath', 'ce', 'fe', 'sode', 'qof', 'rish', 'shin', 'tauw'],
    olafbethIAll: ['olaf', 'beth', 'gomal', 'dolath', 'he', 'wauw', 'zay', 'heth', 'teth', 'yuth', 'kof', 'lomad', 'mim', 'nun', 'semkath', 'ce', 'fe', 'sode', 'qof', 'rish', 'shin', 'tauw', 'ftoho', 'zqofo', 'rboso', 'hboso', 'csoso'],
    sonderzeichen: ['ĉ', 'ğ', 'ĥ', 'ŝ', 'ț', 'đ', 'ç'],
    sonderzeichenOhneD: ['ĉ', 'ğ', 'ĥ', 'ŝ', 'ț', 'ç'],
    sonderzeichenTooltip: ['tarĉo - Tür', 'darğo - Treppe', 'ĥubo - Liebe', 'ŝebyono - Wille', 'țablo - Trommel', 'çiçek - ...'],
    schriftarten: ['batnan', 'urhoy', 'estrangelo', 'midyat', 'nisibin', 'neshrin', 'ctesiphon', 'antioch'],
    mapping: [
        ['.', 'ܐ'], [',', '̱'], [':', '̈ '],
        ['a', 'ܰ'], ['b', 'ܒ'], ['c', 'ܟ'], ['d', 'ܕ'], ['đ', 'ܕ'], ['e', 'ܶ'],
        ['f', 'ܦ'], ['g', 'ܓ'], ['ğ', 'ܓ݂'], ['h', 'ܗ'], ['ĥ', 'ܚ'], ['i', 'ܺܝ'], ['j', 'ܝ'],
        ['k', 'ܟ'], ['l', 'ܠ'], ['m', 'ܡ'], ['n', 'ܢ'], ['o', 'ܳ'], ['p','ܦ݁'], ['q', 'ܩ'], ['s', 'ܙ'], ['ŝ', 'ܨ'], ['ç', 'ܔ'],
        ['ß', 'ܣ'], ['r', 'ܪ'], ['ț', 'ܛ'], ['t', 'ܬ'], ['u', 'ܽܘ'], ['v', 'ܘ'], ['w', 'ܘ'], ['y', 'ܝ'], ['z', 'ܙ'], ['ĉ', 'ܥ']
    ]
};

export const SETTINGS = {
    audio: false
};

export const USER = {
    typ: '',
    email: '',
};

export const Collection = {
    Apotheker: 'Apotheker',
    doc: {
        formularueberschriften: 'formularueberschriften',
        texte: 'texte'
    },
    sub: {
        formelements: 'formelements',
    }
};
