import {Component, Input, OnInit} from '@angular/core';
import {CommonService} from '../../services/common.service';
import {DatabaseService} from '../../services/database.service';
import * as _ from 'lodash-es';
import {SocialService} from '../../services/social.service';
import {ToastService} from '../../services/toast.service';

@Component({
    selector: 'app-bibel-lesezeichen',
    templateUrl: './bibel-lesezeichen.component.html',
    styleUrls: ['./bibel-lesezeichen.component.scss'],
})
export class BibelLesezeichenComponent implements OnInit {
    @Input() version: string;
    @Input() buch: string;
    @Input() kapitel: string;
    @Input() versNummer: string;
    @Input() versContent: string;
    alleLesezeichen;

    constructor(
        public common: CommonService,
        private database: DatabaseService,
        private social: SocialService,
        private toast: ToastService
    ) {
    }

    async ngOnInit() {
        const lesezeichen: any = await this.database.getDataFromStorage('LESEZEICHEN');
        this.alleLesezeichen = _.orderBy(lesezeichen, ['version', 'buch', 'kapitel', 'versNummer']);
    }

    removeLesezeichen(book) {
        const newArray = _.reject(this.alleLesezeichen, {
            version: book.version,
            buch: book.buch,
            kapitel: book.kapitel,
            versNummer: book.versNummer
        });
        this.database.setDataToStorage('LESEZEICHEN', newArray).then(async(res) => {
            if (res === 'SUCCESS') {
                this.alleLesezeichen = await this.database.getDataFromStorage('LESEZEICHEN');
                this.toast.toast('Lesezeichen gelöscht', 'neutral');
            }
        });
    }

    clipboard() {
        this.toast.toast('Text kopiert', 'neutral');
    }

    socialText(book) {
        const reihe1 = book.buch + ' ' + book.kapitel + ':' + book.versNummer + '\n';
        const reihe2 = book.versContent + '\n';
        const reihe3 = 'www.sfarmele.de ' + '\n';
        const msg = reihe1 + reihe2 + reihe3;
        this.social.sendMsg(msg);
    }

}
