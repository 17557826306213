import {Component, OnInit} from '@angular/core';
import {PopoverController} from '@ionic/angular';
import {SETTINGS} from '../../data/global';
import {Storage} from '@ionic/storage';

@Component({
    selector: 'app-setting',
    templateUrl: './setting.component.html',
    styleUrls: ['./setting.component.scss'],
})
export class SettingComponent implements OnInit {
    SETTINGS = SETTINGS;

    constructor(
        private popover: PopoverController,
        private storage: Storage
    ) {
        this.storage.get('AUDIO').then((res: boolean) => {
            this.SETTINGS.audio = (res === null) ? true : res;
        });
    }

    ngOnInit() {
        this.SETTINGS.audio = true;
    }

    sound(ev) {
        this.SETTINGS.audio = ev.detail.checked;
        this.storage.set('AUDIO', ev.detail.checked);
    }

    dismiss() {
        this.popover.dismiss();
    }
}
