import {Injectable} from '@angular/core';
import {ActionSheetController, Platform} from '@ionic/angular';
import {SocialSharing} from '@ionic-native/social-sharing/ngx';
import {CommonService} from './common.service';
import {ToastService} from './toast.service';

@Injectable({
    providedIn: 'root'
})
export class SocialService {

    constructor(
        public common: CommonService,
        private platform: Platform,
        private socialSharing: SocialSharing,
        private toast: ToastService,
        private actionSheetCtrl: ActionSheetController
    ) {
    }

    async presentActionSheet(msg) {
        const actionSheet = await this.actionSheetCtrl.create({
            header: 'Share your Faloho',
            buttons: [
                {
                    text: 'WhatsApp',
                    role: 'destructive',
                    icon: 'logo-whatsapp',
                    handler: () => {
                        window.location.href = 'https://api.whatsapp.com/send?text=' + msg;
                    }
                }
            ]
        });

        await actionSheet.present();
    }

    showSocialApps(word: [string, string, string, string]) {
        const reihe1 = 'Übersetzung von sfarmele.de ' + '\n';
        const reihe2 = word.slice(0, -1).join(' | ') + '\n';
        const reihe3 = '' + '\n';
        const msg = reihe1 + reihe2 + reihe3;
        this.sendMsg(msg);
    }

    shareText(msg) {
        this.sendMsg(msg);
    }

    sendMsg(msg) {
        if (msg) {
            // encodeURIComponent sorgt für die Erkennung von new lines \n
            if (this.common.isNativePlatform()) {
                this.socialSharing.share(msg, null, null);
            } else {
                this.presentActionSheet(encodeURIComponent(msg));
            }
        } else {
            this.toast.toast('Bitte einen Text eingeben zum Teilen', 'neutral');
        }
    }
}
