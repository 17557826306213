import {Component, Input} from '@angular/core';
import {ModalController} from "@ionic/angular";
interface ISpieldaten {
    art: string,
    spaltenanzahl: string,
    geschlecht: string,
    bilderanzahl: string,
    lebenanzahl: string,
    sound: string,
}
@Component({
    selector: 'app-aykoyomodal',
    templateUrl: './aykoyomodal.component.html',
    styleUrls: ['./aykoyomodal.component.scss'],
})
export class AykoyomodalComponent {
    @Input() spielarten: [];
    @Input() anzahlbilder: [];
    @Input() spieldaten: ISpieldaten;

    constructor(
        private modal: ModalController) {
    }

    spielart(event) {
        this.spieldaten.art = event.detail.value;
    }

    spaltenAnzahl(event) {
        this.spieldaten.spaltenanzahl = event.detail.value;
    }

    geschlecht(event) {
        this.spieldaten.geschlecht = event.detail.value;
    }

    bilderanzahl(event) {
        this.spieldaten.bilderanzahl = event.detail.value;
    }

    lebenAnzahl(event) {
        this.spieldaten.lebenanzahl = event.detail.value;
    }

    sound(event) {
        this.spieldaten.sound = event.detail.value;
    }

    dismiss(arg) {
        let data = arg === 'START GAME' ? this.spieldaten: arg ;
        this.modal.dismiss(data);
    }
}
