import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {GLOBALS, SETTINGS} from '../../data/global';
import {SocialService} from '../../services/social.service';
import {CommonService} from '../../services/common.service';
import {Storage} from '@ionic/storage';
import {ToastService} from '../../services/toast.service';
import { jsPDF } from 'jspdf';
import * as htmlToImage from 'html-to-image';

@Component({
    selector: 'app-word-display',
    templateUrl: './word-display.component.html',
    styleUrls: ['./word-display.component.scss'],
})
export class WordDisplayComponent implements OnChanges {
    @ViewChild('audioPlayer', {static: true}) audioPlayer: any;
    @Input() result;
    @Input() showTitle;
    @Input() showTrash: boolean;
    @Output() myEmitter: EventEmitter<any> = new EventEmitter<any>();

    audioMap = new Map<number, boolean>();
    SETTINGS = SETTINGS;
    GLOBALS = GLOBALS;
    resultData: any;
    image = '';
    isPDF = false;
    setSchriftartCss = 'urhoy';
    scaleIcon = [false, false, false];

    constructor(
        public common: CommonService,
        public social: SocialService,
        private storage: Storage,
        private toast: ToastService
    ) {
        this.storage.get('AUDIO').then((res: boolean) => {
            this.SETTINGS.audio = (res === null) ? true : res;
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        this.resetScaleAudioIcon();
        if(changes && changes.result) {
            this.prepareAudioMap(changes.result.currentValue);
            if (this.SETTINGS.audio && this.GLOBALS.isWordSearchedPerSpeach) {
                window.setTimeout(() => this.common.audio('spieleMusik_0'), 500);
                window.setTimeout(() => this.common.audio('spieleMusik_1'), 2000);
                window.setTimeout(() => this.common.audio('spieleMusik_2'), 3500);
            }
        }
    }
//
    prepareAudioMap(data) {
        // Initial werden alle auf true gesetzt
        if (data && data.length) {
            this.resultData = data;
            this.resultData.forEach((item, index) => {
                this.audioMap.set(index, true);
            });
        } else {
            this.resultData = [];
        }
    }

    scaleAudioIcon(index) {
        this.scaleIcon = [false, false, false];
        this.scaleIcon[index] = true;
        setTimeout(()=> this.resetScaleAudioIcon(), 800);
    }

    resetScaleAudioIcon() {
        this.scaleIcon = [false, false, false];
    }

    hideAudio(index) {
        this.audioMap.set(index, false);
    }

    replaceSonderzeichen(audioWord) {
        return audioWord.replace('ĥ', 'h').replace('ț', 't').replace('đ', 'd').replace('ĉ', 'c').replace('ğ', 'g').replace('ŝ', 's');
    }

    removeWord(wordId: string) {
        this.myEmitter.emit(wordId);
    }

    clipboard() {
        this.toast.toast('Text kopiert', 'neutral');
    }

    createPdf() {
        this.isPDF = true;
        setTimeout(() => {
            const node = document.getElementById('element-to-print');

            htmlToImage.toPng(node).then((dataUrl) => {
                const img = new Image()
                img.src = dataUrl;
                this.downloadPDF(img);
            });
        })
    }

    downloadPDF(img) {
        const doc = new jsPDF('p');
        doc.addImage(img, 'JPEG', 0, 0, 0, 0);
        doc.setDisplayMode(2);
        doc.save('new.pdf');
        this.isPDF = false;
    }

    setSchriftartClass(schrift: string) {
        this.setSchriftartCss = schrift;
    }
}
